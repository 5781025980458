import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import styles from "./ProviderClinicLookup.scss";
import debounce from "lodash/debounce";
import map from "lodash/map";
import * as MappingApi from "api/MappingApi";
import Button from "components/common/Button";
import ResultsPerPageDropdown from "components/coupons/widgets/ResultsPerPageDropdown";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import { buildAddressFromData } from "utils/geographyServices";
import logger from "utils/logger";
import {handleErrorResponse} from "utils/request";

function ProviderClinicLookup(props) {
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);
    const [sortBy, setSortBy] = useState("");
    const [sortDir, setSortDir] = useState("");
    const [search, setSearch] = useState("");
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const currentPage = ((offset / limit) + 1);
    const totalPages = Math.ceil(totalCount / limit);
    const filters = {
        search,
        limit,
        offset,
        orderBy: sortBy,
        orderDir: sortDir
    }

    const loadData = useCallback(debounce((filters) => {
        MappingApi.getProviderClinicLocationList(props.providerId, filters)
            .then((res) => {
                setData(res.body.data);
                setTotalCount(res.body.totalRecordCount);
                setLoading(false);
            })
            .catch((error) => {
                handleErrorResponse("loading clinic locations", error);
                setLoading(false);
            });
    }, 1000), []);

    useEffect(() => {
        setLoading(true);
        loadData(filters);
    }, [offset, sortBy, sortDir]);

    useEffect(() => {
        setLoading(true);
        if (!offset) {
            const newFilters = {
                ...filters,
                offset: 0,
            }
            loadData(newFilters);
        } else {
            setOffset(0);
        }
    }, [props.providerId, search, limit])

    const COLUMNS = [{
        name: "Clinic Name",
        selector: "name",
        key: "name",
        searchable: true,
        sortable: true,
    }, {
        name: "Address",
        selector: "address",
        key: "address",
        searchable: true,
    }, {
        name: "City",
        selector: "city",
        key: "city",
        searchable: true,
    }, {
        name: "State",
        selector: "state",
        key: "state",
        searchable: true,
        sortable: true,
    }, {
        name: "Location Id",
        selector: "locationId",
        key: "locationId",
    }, {
        name: "Territory",
        selector: "territory",
        key: "territory",
        searchable: true,
        sortable: true,
        format: row => {
            return (
                <span>{row.territory.contactFirstName} {row.territory.contactLastName} ({row.territory.code})</span>
            );
        }
    }, {
        name: "Status",
        selector: "isActive",
        key: "isActive",
        sortable: true,
        format: row => {
            return (
                <span>{row.isActive ? "Active" : "Not Active"}</span>
            );
        },
    }, {
        name: "Mapped To",
        selector: "mappings",
        key: "mappings",
        searchable: true,
        format: row => {
            return map(row.mappings, mapping => (<div>({mapping.clinicId}) {mapping.clinicName}</div>));
        },
    }];

    if(!data && data.length) {
        logger.log("NO PROVIDER LOCATIONS");
        return null;
    }

    const handleRowClick = (row) => {
        if(props.onRowClick) {
            const data = {
                location: row,
                providerId: props.providerId,
                clinicId: props.clinicId || null,
            }

            props.onRowClick(data);
        }
    }

    const handleCancel = () => {
        if(props.onCancel) {
            props.onCancel();
        }
    }

    const renderHeader = () => {
        if(props.lookupOnly) {
            return (
                <div className={styles.header}>
                    <div className={styles.instructions}>Search for a clinic to verify</div>
                    <div className="flex-none">
                        <Button
                            type="primary"
                            onClick={handleCancel}
                        >
                            Back to Providers
                        </Button>
                    </div>
                </div>
            )
        }

        return (
            <div className={styles.header}>
                {!!props.clinicDetails && (
                    <div className={styles.clinicInfo}>
                        <h2 className={styles.clinicName}>{props.clinicDetails.name}</h2>
                        <div className={styles.clinicAddress}>
                            {buildAddressFromData(props.clinicDetails)}
                        </div>
                    </div>
                )}
                <div className={styles.instructions}>Once you find the clinic, click the row to automatically populate the Location ID to the Clinic</div>
            </div>
        );
    }

    const handlePrev = () => {
        setOffset(offset - limit)
    }
    const handleNext = () => {
        setOffset(offset + limit)
    }

    const handleSort = (value) => {
        if (value === sortBy) {
            if (sortDir === "asc") {
                setSortDir("desc");
            } else {
                setSortDir("asc")
            }
        } else {
            setSortBy(value);
            setSortDir("asc");
        }
    }

    return (
        <div className={styles.root}>
            {renderHeader()}
            <div className="flex">
                <div className="flex-1">
                    <TextBox
                        name="search"
                        value={search}
                        placeholder="Search"
                        onChange={({value}) => setSearch(value)}
                    />
                </div>
                <div className="flex-1"/>
                <div>
                    <ResultsPerPageDropdown
                        onChange={({value}) => setLimit(value)}
                        value={limit}
                    />
                </div>
            </div>
            <SortableDataTable
                onSort={(data) => handleSort(data)}
                columns={COLUMNS}
                rawData={data}
                striped
                green
                onClick={props.lookupOnly ? null : handleRowClick}
                noPagination
            />
            <div className="flex full-width flex-centered margin-top-x-sm margin-bottom-x-sm padding-left-sm padding-right-sm">
                <div className="flex-none">
                    <Button
                        disabled={!(offset > 0)}
                        onClick={handlePrev}
                        icon
                    >
                        <i className="fa fa-chevron-left" /><span> Previous</span>
                    </Button>
                </div>

                <div className="flex-1 text-center">
                    Page {currentPage} of {totalPages}
                </div>
                <div className="flex-none">
                    <Button
                        disabled={currentPage >= totalPages}
                        onClick={handleNext}
                    >
                        <span>Next </span><i className="margin-left-x-sm fa fa-chevron-right" />
                    </Button>
                </div>
            </div>
            <SpinnerTakeover show={loading}/>
        </div>
    );
}

ProviderClinicLookup.propTypes = {
    providerId: PropTypes.number.isRequired,
    clinicId: PropTypes.number,
    onRowClick: PropTypes.func,
    onCancel: PropTypes.func,
    lookupOnly: PropTypes.bool,
    shortList: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const { clinicId, providerId } = ownProps;
        return {
            clinicDetails: clinicId ? state.entities.clinicDetails[clinicId] : null,
            provider: state.entities.providers[providerId],
        }
    }
)(ProviderClinicLookup);
