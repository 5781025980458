// style-loader: Adds some css to the DOM by adding a <style> tag

// load the styles
var content = require("!!../../../../../../../node_modules/css-loader/index.js?sourceMap&-colormin&root=.&modules&localIdentName=[name]__[local]!../../../../../../../node_modules/postcss-loader/lib/index.js!../../../../../../../node_modules/sass-loader/dist/cjs.js!./GoldenSummerGamesPodium.scss");
if(typeof content === 'string') content = [[module.id, content, '']];
// Prepare cssTransformation
var transform;

var options = {"hmr":true}
options.transform = transform
// add the styles to the DOM
var update = require("!../../../../../../../node_modules/style-loader/lib/addStyles.js")(content, options);
if(content.locals) module.exports = content.locals;
// Hot Module Replacement
if(module.hot) {
	// When the styles change, update the <style> tags
	if(!content.locals) {
		module.hot.accept("!!../../../../../../../node_modules/css-loader/index.js?sourceMap&-colormin&root=.&modules&localIdentName=[name]__[local]!../../../../../../../node_modules/postcss-loader/lib/index.js!../../../../../../../node_modules/sass-loader/dist/cjs.js!./GoldenSummerGamesPodium.scss", function() {
			var newContent = require("!!../../../../../../../node_modules/css-loader/index.js?sourceMap&-colormin&root=.&modules&localIdentName=[name]__[local]!../../../../../../../node_modules/postcss-loader/lib/index.js!../../../../../../../node_modules/sass-loader/dist/cjs.js!./GoldenSummerGamesPodium.scss");
			if(typeof newContent === 'string') newContent = [[module.id, newContent, '']];
			update(newContent);
		});
	}
	// When the module is disposed, remove the <style> tags
	module.hot.dispose(function() { update(); });
}