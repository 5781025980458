import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import * as PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Sidebar.scss";
import { windowSizeContext } from "AppRoot";
import Button from "components/common/Button";
import SidebarClinicInfo from "./SidebarClinicInfo";
import SidebarLinks from "./SidebarLinks";
import * as ClinicData from "utils/ClinicData";
import { getNodeId } from "utils/AdminData";
const watermark = require("components/common/branding/watermark_background.jpg");

export function Sidebar(props) {
    const [collapsed, setCollapsed] = useState(false);
    const [basePath, setBasePath] = useState("");
    const location = useLocation();
    const context = useContext(windowSizeContext) || {};
    const collapsible = props.collapsible || context.isPhone;

    useEffect(() => {
        if (collapsible) {
            setCollapsed(true);
        }
    }, [collapsible]);

    useEffect(() => {
        if (props.clinicId && location.pathname.indexOf("/admin/") > -1) {
            setBasePath(`/admin/clinic/${props.clinicId}`);
        }
        if (props.clinicId && location.pathname.indexOf("/provider/") > -1) {
            setBasePath(`/provider/location/${props.nodeId}/clinic/${props.clinicId}`);
        }
    }, [props.clinicId]);

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    }

    const collapseBtnIcons = collapsed ? "fas fa-chevron-circle-right" : "fas fa-chevron-circle-left";
    return (
        <nav
            data-testid="sidebar_component"
            className={classnames(styles.root, {
                [styles.collapsible]: collapsible,
                "test-collapsible": collapsible,
            })}
        >
            <div
                data-testid="sidebar_bar"
                className={classnames(styles.menuBar, {
                    [styles.collapsed]: (collapsed && collapsible),
                    "test-collapsed": (collapsed && collapsible),
                })}
            >
                {collapsible && (
                    <Button
                        data-testid="sidebar_collapse_btn"
                        className={styles.collapseButton}
                        onClick={toggleCollapsed}
                        text
                    >
                        <i className={collapseBtnIcons} />
                    </Button>
                )}
                <SidebarLinks
                    clinicId={props.clinicId}
                    basePath={basePath}
                    collapsed={collapsed}
                />
                <SidebarClinicInfo
                    clinicId={props.clinicId}
                    basePath={basePath}
                    collapsed={collapsed}
                />
                <div className="flex-1"/>
                <div className={styles.bgLogo}>
                    <img src={watermark} alt="Greenline Watermark" />
                </div>
            </div>
        </nav>
    );
}

Sidebar.propTypes = {
    clinicId: PropTypes.number,
    nodeId: PropTypes.number,
    collapsible: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const clinicInfo = ClinicData.getClinicInfo(state, ownProps.clinicId);
        return {
            clinicId: clinicInfo ? clinicInfo.clinicId : ownProps.clinicId,
            nodeId: getNodeId(state, ownProps),
        };
    }
)(Sidebar);
