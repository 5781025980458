import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import * as ProviderActions from "actions/ProviderActions";
import ManageInvitesTable from "./ManageInvitesTable";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ManageInvitesTableWrapper(props) {
    const { pathname } = useLocation();
    const params = useParams();
    const nodeId = Number(params?.nodeId);
    const isDemo = includes(pathname, "/demo") && props.canViewClinicBIGamesDashboard;
    //I'm not sure if/when this number should change...
    const[daysCurrent, setDaysCurrent] = useState(30);

    useEffect(() => {
        if (isDemo) {
            props.getDemoGameInvites();
        } else if (props.userId && (props.canViewBiGamesClinicInvites || props.canViewBiGamesDrillDown)) {
            props.getGameInvites(daysCurrent, props.userId);
        }
    }, [props.userId, props.canViewBiGamesClinicInvites, props.canViewBiGamesDrillDown]);

    return (
        <ManageInvitesTable nodeId={nodeId} />
    )
}

ManageInvitesTableWrapper.propTypes = {
    userId: PropTypes.number.isRequired,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        // Permissions
        const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewGames = canAdminProviderBiGames || canViewProviderBiGames;
        const canViewBiGamesDrillDown = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_DRILLDOWN, userProfile);
        const canViewBiGamesClinicInvites = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_CLINIC_INVITES, userProfile);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        return {
            userProfile,

            // Permissions
            canViewGames,
            canViewBiGamesDrillDown,
            canViewBiGamesClinicInvites,
            canViewClinicBIGamesDashboard,
        }
    },
    (dispatch) => ({
        getGameInvites: (daysCurrent, userId) => dispatch(ProviderActions.getGameInvites(daysCurrent, userId)),
        getDemoGameInvites: () => dispatch(ProviderActions.getDemoGameInvites()),
    })
) (ManageInvitesTableWrapper);