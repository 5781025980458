import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { Link } from "react-router-dom";
import styles from "./ClinicProgramSetup.scss";
import classnames from "classnames";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import map from "lodash/map";
import * as ClinicActions from "actions/ClinicActions";
import * as CouponActions from "actions/CouponActions";
import AdminBase from "components/layout/AdminBase";
import Modal from "components/common/Modal";
import Button from "components/common/Button";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import ProviderClinicLookupModal from "components/admin/widgets/ProviderClinicLookupModal";
import ProviderTiles from "components/admin/elements/ProviderTiles";
import {getClinicInfo} from "utils/ClinicData";
import {getProgramProviders} from "utils/AdminData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as UserPermissions from "constants/UserPermissions";
import * as SearchTypes from "constants/SearchTypes";
import { PROGRAM_SETUP } from "constants/AdminControls"
import {getLinkLocation} from "utils/ClinicAdminLinks";
import InformationTile from "components/common/InformationTile";
import moment from "moment";

function ClinicProgramSelection(props) {
    const history = useHistory();
    const locationIdRef = useRef(null);
    const [showLoader, setShowLoader] = useState(false);
    const [showProviders, setShowProviders] = useState(false);
    const [selectedProviderId, setSelectedProviderId] = useState(null);
    const [selectedProviderLookup, setSelectedProviderLookup] = useState(null);
    const [showAllProviders, setShowAllProviders] = useState(false);
    const [ruSure, setRuSure] = useState(null);
    const [clinicTagList, setClinicTagList] = useState({});

    useEffect(() => {
        props.getProviderList();
        props.getProviderLocations(props.clinicId, false);
    }, [props.clinicId]);

    const loadClinic = (clinicId) => {
        if (props.canViewClinicManagement) {
            props.getClinicById(clinicId);
        }
    }

    useEffect(() => {
        loadClinic(props.clinicId);
    }, [props.clinicId]);

    useEffect(() => {
        if(props.clinicInfo) {
            const cTags = {};
            forEach(props.clinicInfo.tags, tag => {
                cTags[tag] = tag;
            });
            setClinicTagList({
                ...clinicTagList,
                ...cTags,
            })
        }
    }, [props.clinicInfo])

    const toggleProvidersList = () => {
        setShowProviders(!showProviders);
    }
    const toggleShowAllProviders = () => {
        setShowAllProviders(!showAllProviders);
    }

    const handleProviderClick = (provider) => {
        setSelectedProviderId(provider?.id);
    }

    const handleProviderLookup = (providerId) => {
        setSelectedProviderLookup(providerId);
    }

    const handleRemoveProviderLocation = (providerId) => {
        if(props.canEditClinicProgramSetup) {
            setRuSure({
                question: (<div className="flex flex-column"><h2>Are you sure you want to remove this location id from the clinic?</h2></div>),
                data: {
                    clinicId: props.clinicId,
                    providerId: providerId,
                    location: null,
                },
                handler: (data) => handleSetProviderLocation(data)
            });
        }
    }

    const catchSetProviderLocation = (tableRowData) => {
        if(props.canEditClinicProgramSetup) {
            const location = tableRowData.location;
            setRuSure({
                question: (
                    <div className="flex flex-column">
                        <h3 className="margin-bottom-sm">Are you sure you want to apply this location
                            to {location.name} in {location.city}, {location.state}?</h3>
                        <h1 className="text-dark-green">Location ID: {location.locationId}</h1>
                    </div>
                ),
                data: {...location, clinicId: props.clinicInfo.clinicId, providerId: tableRowData.providerId}, // Ugly hack is what this is
                handler: (data) => handleSetProviderLocation(data),
            });
        }
    }

    const handleSetProviderLocation = (data) => {
        if(props.canEditClinicProgramSetup) {
            props.setClinicProviderLocation(data);
            setShowLoader(true);
            setRuSure(null);
            setSelectedProviderLookup(null);
            setTimeout(() => setShowLoader(false), 1000);
            loadClinic(data.clinicId);
            props.getClinicById(data.clinicId); // Force reload the clinic data to make sure it's up to date (can eliminate this if we have the API call return result - see entities.js ActionTypes.CLINIC_PROVIDER_LOCATION_UPDATED)
        }
    }

    const renderProgramTiles = () => {
        if (!props.clinicInfo?.programs?.Coupons?.enabled || props.clinicInfo?.programs?.Coupons?.providers?.length <= 0) {
            return null;
        }

        return map(props.providers, provider => {
            if (!includes(props.clinicInfo?.programs?.Coupons?.providers, provider?.id)) {
                return null;
            }

            const providerDetails = props.providerLocations?.[provider.id] || {};

            const footer = (
                <div>
                    <div>Coupon Activation Date: {new moment(providerDetails.goGreenDate).format("MM/DD/YYYY")}</div>
                    <div>Go Green Date: {new moment(providerDetails.lastGoGreenDate).format("MM/DD/YYYY")}</div>
                </div>
            );

            return (
                <Link
                    to={getLinkLocation("providerSetup", props.clinicId, provider.id)}
                    className={styles.programSetupTile}
                >
                    <InformationTile
                        title={provider.name}
                        key={`providerId_${provider.id}`}
                        name={provider.id}
                        showEnrollmentInfo
                        footer={footer}
                    >
                        <div className={styles.logo}>
                            <img
                                src={provider.mediumImageUri}
                                title={provider.name}
                            />
                        </div>
                    </InformationTile>
                </Link>
            );
        });
    }

    const renderProgramArea = () => {
        if(props.clinicInfo?.programs?.Coupons?.enabled) {
            return (
                <div className={styles.gridContainer}>
                    <div className={styles.grid}>
                        {renderProgramTiles()}
                    </div>
                </div>
            );
        }


        return (
            <div className={styles.gridContainer}>
                <div className={styles.noPrograms}>
                    <h2>No Programs Available To Setup</h2>
                    <h4>Add a program using a voucher code to get started</h4>
                </div>
            </div>
        );
    }

    const renderProviderForm = (providerId) => {
        const currentProvider = props.providers ? props.providers[providerId] : null;
        const providerLocation = props.providerLocations ? props.providerLocations[providerId] : null;

        const handleCopyClicked = () => {
            const element = locationIdRef.current;
            if (element) {
                element.select();
                element.setSelectionRange(0, 99999); // For mobile devices (says w3schools)
                if (document.execCommand("copy")) {
                    toast.success("Location ID copied to clipboard");
                }
            }
        }
        return (
            <div key={`providerLocationForm_${providerId}`}
                 className={classnames(styles.providerIdForm, {
                     [styles.inShowAll] : showAllProviders,
                 })}
            >
                <div className={styles.providerLogo}><img src={currentProvider.mediumImageUri} alt={`${currentProvider.name} logo`} /></div>
                <div className={styles.locationInfo}>
                    <label>Location Id:</label>
                    <input
                        className={classnames(styles.locationId, {
                            [styles.noLocation]: !(providerLocation && providerLocation.locationId),
                        })}
                        ref={locationIdRef}
                        onClick={handleCopyClicked}
                        type="text"
                        value={(providerLocation && providerLocation.locationId) ? providerLocation.locationId : "No location set" }
                        readOnly
                    />

                    {(providerLocation && providerLocation.locationId) && (
                        <div className={styles.locationName}>
                            <span>{ providerLocation ? providerLocation.locationName : ''}</span>
                            {providerLocation && providerLocation.status !== "Inactive" && <i className="fas fa-check-circle" />}
                        </div>
                    )}
                </div>
                <div className={styles.buttonWrap}>
                    <Button onClick={() => handleProviderLookup(providerId)}>
                        Browse Clinics
                    </Button>
                    <Button
                        onClick={() => handleRemoveProviderLocation(providerId)}
                        disabled={!props.canEditClinicProgramSetup}
                    >
                        Clear
                    </Button>
                    {!showAllProviders && (
                        <div className={styles.closeX}
                             onClick={() => setSelectedProviderId(null)}
                        >
                            <i className="fas fa-times"/>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const renderProviderForms = () => {
        if(showAllProviders) {
            return (
                <div className={styles.providerLocationForms}>
                    {map(props.providers, provider => renderProviderForm(provider.id))}
                </div>
            )
        }

        if(!!selectedProviderId) {
            return (
                <div className={styles.providerLocationForms}>
                    {renderProviderForm(selectedProviderId)}
                </div>
            )
        }

        return null;
    }

    return (
        <AdminBase
            searchConfig={SearchTypes.VOUCHER_CODE}
            clinicId={props.clinicId}
            collapsible
            pageTitle="Provider Setup"
            controls={PROGRAM_SETUP}
        >
            {!props.clinicInfo ? (
                <SpinnerTakeover show/>
            ) : (
                <div className={styles.root}>
                    <div className="topLine">
                        <div className="flex-1 text-right">
                            <Button
                                type={showProviders ? "gray" : null}
                                reverseHover={showProviders}
                                onClick={toggleProvidersList}
                            >
                                Provider Setup
                            </Button>
                        </div>
                    </div>
                    {!!showProviders && (
                        <div className={styles.providerList}>
                            <div className={styles.sectionHeader}>
                                <h2>Provider Setup</h2>
                                <Button
                                    className="margin-right-sm"
                                    type={showAllProviders ? "outline" : null}
                                    onClick={toggleShowAllProviders}
                                >
                                    {showAllProviders ? "Hide" : "Show"} All
                                </Button>
                                <div
                                    className={styles.closeX}
                                    onClick={toggleProvidersList}
                                >
                                    <i className="fas fa-times" />
                                </div>
                            </div>
                            {!showAllProviders && (
                                <ProviderTiles
                                    clinicId={props.clinicId}
                                    onSelect={handleProviderClick}
                                    providerId={selectedProviderId}
                                />
                            )}
                            {renderProviderForms()}
                        </div>
                    )}
                    <div className={styles.programs}>
                        {renderProgramArea()}
                    </div>
                </div>
            )}
            <SpinnerTakeover show={showLoader}/>
            {!!(selectedProviderLookup && props.canEditClinicProgramSetup) && (
                <ProviderClinicLookupModal
                    onRowClick={catchSetProviderLocation}
                    clinicId={props.clinicId}
                    providerId={selectedProviderLookup}
                    providerName={props.providers[selectedProviderLookup].name}
                    onClose={() => setSelectedProviderLookup(null)}
                    canEditClinicProgramSetup={props.canEditClinicProgramSetup}
                />
            )}
            {!!ruSure && (
                <Modal
                    show={!!ruSure}
                    onClose={() => setRuSure(null)}
                    small
                >
                    <div className="flex-none text-center">{ruSure.question}</div>
                    <div className="flex flex-centered margin-top-md">
                        <Button wide className="margin-right-sm" large onClick={() => ruSure.handler(ruSure.data)} >Yes</Button>
                        <Button wide large onClick={() => setRuSure(null)} type="grey" >No</Button>
                    </div>
                </Modal>
            )}
        </AdminBase>
    )
}

const connector = connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, clinicId);
        const userProfile = state.user.userProfile;
        // Permissions
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicProgramSetup = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_PROGRAM_SETUP, userProfile);
        return {
            clinicId,
            clinicInfo,
            providerLocations: state.entities.clinicProviderLocations[clinicId],
            providers: getProgramProviders(state),
            // Permissions
            canViewClinicManagement,
            canEditClinicManagement,
            canEditClinicProgramSetup
        }
    },

    (dispatch) => ({
        getClinicById: (clinicId) => dispatch(ClinicActions.getClinicById(clinicId)),
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        getProviderLocations: (clinicId, showOnlyEnrolled) => dispatch(ClinicActions.getProviderLocations(clinicId, showOnlyEnrolled)),
        setClinicProviderLocation: (data) => dispatch(ClinicActions.setClinicProviderLocation(data)),
    })
);

export default compose(
    withRouter,
    connector
)(ClinicProgramSelection);
