import React, {useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import debounce from "lodash/debounce";
import find from "lodash/find";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as VetCheckApi from "api/VetCheckApi";
import Button from "components/common/Button";
import DropdownSearch from "components/common/DropdownSearch";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {handleErrorResponse} from "utils/request";
import toast from "utils/toast";


export default function AddOptOutForm(props) {
    const [selectedOwner, setSelectedOwner] = useState(null);
    const [petOwners, setPetOwners] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        handleSearchPetOwners();
    }, []);

    const handleSearchPetOwners = useCallback(debounce((search) => {
        setLoading(true);
        VetCheckApi.getPetOwners(props.clinicId, {limit: 15, search: search})
            .then((res) => {
                setPetOwners(res.body?.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                handleErrorResponse("loading clinic pet owners", error);
            });
    }, 500), []);

    const petOwnerOptions = map(orderBy(petOwners, ["petOwnerFirstName", "petOwnerLastName", "optedOut"], ["asc", "asc", "desc"]), owner => {
        return {
            dropdownName: (
                <div title={owner.optOut ? "Opted out" : "Opted in"}>
                    <i className={classnames("fa margin-right-x-sm", {
                        "fa-user text-success": !owner.optOut,
                        "fa-user-slash text-danger": owner.optOut
                    })}/> {owner.petOwnerFirstName} {owner.petOwnerLastName} {!!owner.petOwnerEmail ? `(${owner.petOwnerEmail})` : ""}
                </div>
            ),
            name: `${owner.petOwnerFirstName} ${owner.petOwnerLastName} ${!!owner.petOwnerEmail ? `(${owner.petOwnerEmail})` : ""}`,
            value: owner.petOwnerId,
        }
    });

    const selectPetOwner = ({value}) => {
        const owner = find(petOwners, {petOwnerId: value});
        setSelectedOwner(owner);
    }

    const handleOptInOut = async () => {
        if(!selectedOwner?.optOut) {
            // If the user is NOT opted out (is opted in) then opt out
            setLoading(true);
            try {
                await VetCheckApi.vetCheckOptOut(props.clinicId, selectedOwner?.petOwnerId);
                setLoading(false);
                toast.success(`${selectedOwner.petOwnerFirstName} ${selectedOwner.petOwnerLastName} opted out successfully`);
                props.onClose();
            } catch (error) {
                setLoading(false);
                handleErrorResponse("opting out", error);
            }
        } else {
            try {
                await VetCheckApi.vetCheckOptIn(props.clinicId, selectedOwner?.petOwnerId);
                setLoading(false);
                toast.success(`${selectedOwner.petOwnerFirstName} ${selectedOwner.petOwnerLastName} opted in successfully`);
                props.onClose();
            } catch (error) {
                setLoading(false);
                handleErrorResponse("opting in", error);
            }
        }
    }

    return (
        <div>
            <div className="flex spaced-content">
                <div className="flex-1">
                    <div className="margin-left-x-sm" style={{marginBottom: ".25em"}}>Pet Owner</div>
                    <DropdownSearch
                        onChange={selectPetOwner}
                        onSearchChange={handleSearchPetOwners}
                        name="selectedOwner"
                        value={selectedOwner?.petOwnerId}
                        options={petOwnerOptions}
                        // label="Pet Owner"
                        placeholder="Search Pet Owners"
                        statusIcon={!!selectedOwner ? (
                            <i className={classnames("fa", {
                                    "fa-user text-success": !selectedOwner?.optOut,
                                    "fa-user-slash text-danger": selectedOwner?.optOut,
                                })}
                            />
                        ) : null}
                    />
                </div>
            </div>
            <div className="border-top flex spaced-content justify-flex-end">
                <Button
                    type="gray"
                    onClick={props.onClose}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleOptInOut}
                    type={selectedOwner?.optOut ? "success" : "danger"}
                    disabled={!selectedOwner?.petOwnerId}
                >
                    {selectedOwner?.optOut ? "Opt-In" : "Opt-Out"}
                </Button>
            </div>
            <SpinnerTakeover show={loading}/>
        </div>
    );
}

AddOptOutForm.propTypes = {
    clinicId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired
};
