import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import moment from "moment";
import * as Yup from "yup";
import includes from "lodash/includes";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import reject from "lodash/reject";
import startCase from "lodash/startCase";
import * as styles from "./ConfigurePIMS.scss";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import DateInput from "components/common/DateInput";
import TextBox from "components/common/TextBox";
import useFormHandlers from "utils/useFormHandlers";

const DEFAULT_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";

export default function PIMSForm(props) {
    const configSchema = Yup.object().shape(
        mapValues(
          keyBy(props.configurationProperties, "name"),
          (property) => {
            let dataType = property.dataType;
            let validationSchema = Yup[dataType]();


            if (property.isRequired && property.isEditable) {
              validationSchema = validationSchema.required().default(property.defaultValue || null);
            } else {
                validationSchema = validationSchema.nullable().default(property.defaultValue || null);
            }

            return validationSchema;
          }
        )
      );
	const schema = useMemo(() => {
        return Yup.object().shape({
	        acknowledge: Yup.bool().default(false).oneOf([true], 'Field must be checked'),
            addDaysToLastQueryDate: Yup.number()
                .nullable(true)
                .min(-props.maxDaysToAddToLastQueryDate)
                .max(props.maxDaysToAddToLastQueryDate)
                .transform((curr, orig) => orig === "" ? null : curr),
            applicationProperties: Yup.array(),
            applicationPropertyProfile: Yup.object().default(null).nullable(true),
            clinicId: Yup.number().required(true),
            config: configSchema,
            deleteExternalFilesAfterTransfer: Yup.bool().default(false),
            forceSync: Yup.bool().default(props.defaultForceSync),
            installedPims: Yup.string().required(false).nullable(true),
            installedPimsVersion: Yup.string()
                .default(null)
                .required(false)
                .nullable(true)
                .transform((curr, orig) => orig === "" ? null : curr),
            invoiceChunkSize: Yup.string()
                .default(null)
                .required(false)
                .nullable(true)
                .transform((curr, orig) => orig === "" ? null : curr),
            isCloudPims: Yup.bool().default(false),
            lastQueryDate: Yup.date().min(props.maxLastQueryDate),
            lastSubmittedDate: Yup.date().nullable(true),
            lastSubmittedForCloudProcessing: Yup.date().nullable(true),
            pullProductList: Yup.bool().default(false),
            remoteClinicID: Yup.string()
                .default(null)
                .required(props.requiresRemoteClinicID)
                .nullable(!props.requiresRemoteClinicID)
                .transform((curr, orig) => orig === "" ? null : curr),
            maxInvoiceChunkSize: Yup.number(),
            softwareVendorId: Yup.number().required(true),
            softwareVendorName: Yup.string(),
            syncIsActive: Yup.bool().default(false),
        });
    }, [props.configurationProperties, props.defaultForceSync]);

	const { formData, handleUpdate, isValid, invalidFields } = useFormHandlers(props.initialData, schema);
    const [sensitiveButVisible, setSensitiveButVisible] = useState([]);

    const handleShowProperty = (propertyName) => {
        let newSensitiveButVisible = [...sensitiveButVisible];
        if (includes(sensitiveButVisible, propertyName)) {
            newSensitiveButVisible = reject(sensitiveButVisible, s => s === propertyName);
        } else {
            newSensitiveButVisible.push(propertyName);
        }
        setSensitiveButVisible(newSensitiveButVisible)
    }

    const configRows = map(props.configurationProperties, (property, index) => {
        const propertyName = startCase(property.name);
        return (
            <tr key={index}>
                <td>{propertyName}</td>
                <td>
                    <div className={styles.inputWithIcon}>
                        <TextBox
                            name={`config.${property.name}`}
                            value={formData.config?.[property.name] || null}
                            onChange={handleUpdate}
                            disabled={!property.isEditable}
                            inputType={(property.isSensitive && !includes(sensitiveButVisible, property.name)) ? "password" : property.dataType === "int" ? "number" : "text"}
                            placeholder={`Enter ${propertyName}`}
                            required={property.isRequired}
                            hasError={includes(invalidFields, `config.${property.name}`)}
                        />
                        {(property.isViewable && property.isSensitive) && (
                            <div className={styles.eyeIcon}>
                                <Button
                                    text
                                    iconOnly
                                    type="primary"
                                    onClick={() => handleShowProperty(property.name)}
                                >
                                    <i className="fa fa-eye" aria-hidden="true"/>
                                </Button>
                            </div>
                        )}
                    </div>
                </td>
            </tr>
        );
    });

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onSubmit(formData);
    };

    return (
        <form onSubmit={handleSubmit}>
            <h2 className="text-success text-center margin-bottom-sm">
                {props.softwareVendorName} Configuration
            </h2>
            {(props.supportsConfigurationProperties || props.requiresRemoteClinicID) && (
                <table className="full-width table margin-bottom-lg">
                    <thead>
                        <tr className="text-left">
                            <th>Property</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        <>
                            {props.requiresRemoteClinicID && (
                                <tr>
                                    <td>Remote Clinic ID</td>
                                    <td>
                                        <TextBox
                                            name="remoteClinicID"
                                            value={formData.remoteClinicID}
                                            onChange={handleUpdate}
                                            invalid={includes(invalidFields, "remoteClinicID")}
                                            placeholder="Enter Remote Clinic ID"

                                        />
                                    </td>
                                </tr>
                            )}
                            {configRows}
                        </>
                    </tbody>
                </table>
            )}
            <div className="flex flex-wrap spaced-content margin-bottom-sm">
                {props.isCloudPims && (
                    <CheckboxInput
                        name="syncIsActive"
                        checked={formData.syncIsActive}
                        label="Is active Cloud Sync"
                        onChange={handleUpdate}
                    />
                )}
                <CheckboxInput
                    name="forceSync"
                    checked={formData.forceSync}
                    label="Force Sync"
                    onChange={handleUpdate}
                />
                <CheckboxInput
                    name="pullProductList"
                    checked={formData.pullProductList}
                    label="Pull Product List"
                    onChange={handleUpdate}
                />
                {props.isCloudPims && (
                    <CheckboxInput
                        name="deleteExternalFilesAfterTransfer"
                        checked={formData.deleteExternalFilesAfterTransfer}
                        label="Remove Files After Transfer"
                        onChange={handleUpdate}
                    />
                )}
            </div>
            <div className="flex flex-wrap spaced-content align-bottom">
                <div className="flex align-bottom flex-1">
                    <DateInput
                        value={formData.lastQueryDate}
                        name="lastQueryDate"
                        dateFormat={DEFAULT_DATE_FORMAT}
                        onChange={handleUpdate}
                        placeholderText="--Select Date--"
                        selectsStart
                        startDate={formData.lastQueryDate}
                        minDate={props.maxLastQueryDate}
                        maxDate={moment()}
                        showDropdowns
                        label="Last Query Date"
                        icon={<i className="fa fa-calendar-alt text-primary"/>}
                        hasError={includes(invalidFields, "lastQueryDate")}
                    />
                </div>
                {(props.maxLastQueryDateMonths > 2) && (
                    <div className="nowrap margin-bottom-x-sm flex-none">
                        <Button
                            type='primary'
                            onClick={() => {
                                handleUpdate({
                                    name:"lastQueryDate",
                                    value: moment().subtract(3, 'months').format(DEFAULT_DATE_FORMAT)
                                })
                            }}
                            small
                        >
                            3 MO
                        </Button>
                    </div>
                )}
                {(props.maxLastQueryDateMonths > 5) && (
                    <div className="nowrap margin-bottom-x-sm flex-none">
                        <Button
                            type='primary'
                            onClick={() => {
                                handleUpdate({
                                    name: "lastQueryDate",
                                    value: moment().subtract(6, 'months').format(DEFAULT_DATE_FORMAT)
                                })
                            }}
                            small
                        >
                            6 MO
                        </Button>
                    </div>
                )}
                {(props.maxLastQueryDateMonths > 11) && (
                    <div className="nowrap margin-bottom-x-sm flex-none">
                        <Button
                            type='primary'
                            onClick={() => {
                                handleUpdate({
                                    name: "lastQueryDate",
                                    value: moment().subtract(12, 'months').format(DEFAULT_DATE_FORMAT)
                                })
                            }}
                            small
                        >
                            1 YR
                        </Button>
                    </div>
                )}
                {(props.maxLastQueryDateMonths > 23) && (
                    <div className="nowrap margin-bottom-x-sm flex-none">
                        <Button
                            type='primary'
                            onClick={() => {
                                handleUpdate({
                                    name: "lastQueryDate",
                                    value: moment().subtract(24, 'months').format(DEFAULT_DATE_FORMAT)
                                })
                            }}
                            small
                        >
                            2 YR
                        </Button>
                    </div>
                )}
            </div>
            <TextBox
                name="addDaysToLastQueryDate"
                value={formData.addDaysToLastQueryDate}
                label="Days to Always Add to Last Query Date"
                onChange={handleUpdate}
                disabled={!props.isDaysToAddToLastQueryDateEditable}
                inputType="number"
                min={`-${props.maxDaysToAddToLastQueryDate}`}
                max={props.maxDaysToAddToLastQueryDate}
                hasError={includes(invalidFields, "addDaysToLastQueryDate")}
            />
            {!!props.isInvoiceChunkSizeEditable && (
                <TextBox
                    name="invoiceChunkSize"
                    value={formData.invoiceChunkSize}
                    label="Cloud PIMS - Invoice Chunk Size"
                    onChange={handleUpdate}
                    disabled={!props.isInvoiceChunkSizeEditable}
                    inputType="number"
                    min={0}
                    max={formData.maxInvoiceChunkSize}
                />
            )}
            {!!(props.isDaysToAddToLastQueryDateEditable || props.initialData.defaultDaysToAddToLastQueryDate) && (
                <>
                    {props.isCloudPims ? (
                        <TextBox
                            name="lastSubmittedForCloudProcessing"
                            value={formData.lastSubmittedForCloudProcessing || "not set"}
                            label="Last Submitted for Processing"
                            onChange={() => {}}
                            disabled
                        />
                    ) : (
                        <TextBox
                            name="lastSubmittedDate"
                            value={formData.lastSubmittedDate || "not set"}
                            label="Last Submitted for Processing"
                            onChange={() => {}}
                            disabled
                        />
                    )}
                </>
            )}
            {(props.vendorChanged) && (
                <div className="text-lg text-center text-danger margin-top-md margin-bottom-md">
                    * NOTE: Changing the PIMS will Clear the PIMS Install Path, PIMS Version, Agent Install Date and Agent Install Version
                </div>
            )}
            <div className={styles.submit}>
                <div className="text-lg">
                    <CheckboxInput
                        name="acknowledge"
                        checked={formData.acknowledge}
                        label={(formData && props.vendorChanged) ? (
                            "I acknowledge the PIMS Software is changing, and the server agent has been properly UNINSTALLED or RECONFIGURED as appropriate for the clinic"
                        ) : (
                            "I acknowledge the changes I have made may impact the sync process"
                        )}
                        onChange={handleUpdate}
                    />
                </div>
                <div className="flex spaced-content justify-flex-end margin-top-sm">
                    <Button
                        onClick={props.onCancel}
                        type="gray"
                    >
                        Cancel
                    </Button>
                    <Button
                        buttonType="submit"
                        wide
                        disabled={!(isValid && props.canEditClinicManagement)}
                    >
                        Apply Changes
                    </Button>
                </div>
            </div>
        </form>
    )
}

PIMSForm.defaultProps = {
    canEditClinicManagement: false,
    clinic: PropTypes.shape({
        vendorId: null,
    }),
    isCloudPims: false,
    isDaysToAddToLastQueryDateEditable: false,
    isInvoiceChunkSizeEditable: false,
    requiresRemoteClinicID: false,
    supportsConfigurationProperties: false,
    vendorChanged: false,
};

PIMSForm.propTypes = {
    canEditClinicManagement: PropTypes.bool,
    clinic: PropTypes.shape({
        vendorId: PropTypes.number,
    }),
    config: PropTypes.object,
    configurationProperties: PropTypes.arrayOf(PropTypes.shape({
        dataType: PropTypes.string,
        defaultValue: PropTypes.string,
        isEditable: PropTypes.bool,
        isRequired: PropTypes.bool,
        isSensitive: PropTypes.bool,
        isUnique: PropTypes.bool,
        isViewable: PropTypes.bool,
        name: PropTypes.string,
    })),
    defaultForceSync: PropTypes.bool,
    initialData: PropTypes.shape({
        applicationProperties: PropTypes.array,
        applicationPropertyProfile: PropTypes.any,
        clinicId: PropTypes.number.isRequired,
        configurationNotes: PropTypes.string,
        defaultDaysToAddToLastQueryDate: PropTypes.number,
        defaultInvoiceChunkSize: PropTypes.number,
        defaultLastQueryDateMonths: PropTypes.number,
        defaultSetPullProductList: PropTypes.bool,
        maxInvoiceChunkSize: PropTypes.number,
        remoteClinicID: PropTypes.string,
    }),
    isCloudPims: PropTypes.bool,
    isDaysToAddToLastQueryDateEditable: PropTypes.bool,
    isInvoiceChunkSizeEditable: PropTypes.bool,
    maxDaysToAddToLastQueryDate: PropTypes.number,
    maxLastQueryDate: PropTypes.string,
    maxLastQueryDateMonths: PropTypes.number,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    requiresRemoteClinicID: PropTypes.bool,
    softwareVendorName: PropTypes.string,
    supportsConfigurationProperties: PropTypes.bool,
    vendorChanged: PropTypes.bool,
};
