import React from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import classnames from "classnames";
import moment from "moment/moment";
import * as styles from "./ProviderClinicTable.scss"
import get from "lodash/get";
import find from "lodash/find";
import map from "lodash/map";
import toInteger from "lodash/toInteger";
import Button from "components/common/Button";
import Image from "components/common/Image";
import SortableDataTable from "components/common/SortableDataTable";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import { formatUSD } from "utils/numeric";
import * as ProviderLinks from "utils/ProviderLinks";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as OrgNodeTypes from "constants/OrgNodeTypes";
import { BOEHRINGER_INGELHEIM, PREMIER_PET_CARE_PLAN } from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";

function ProviderClinicTable(props) {
    const history = useHistory();

    const handleClick = (e, data) => {
        e.stopPropagation();
        e.preventDefault();
        props.onRedemptionsIconClicked(data);
    }
    const handleGoToClinicGames = (e, clinicId, providerId) => {
        e.stopPropagation();
        e.preventDefault();
        if ((props.canViewClinicInfo || props.canViewCorporateGroupClinic) && props.canViewClinicBIGamesDashboard) {
            if (props.isAdmin) {
                history.push(ClinicAdminLinks.getLinkLocation("games", clinicId, providerId));
            } else {
                history.push(ClinicProviderLinks.getLinkLocation("games", props.nodeId, clinicId, providerId));
            }
        } else if (props.canViewClinicBIGamesDashboard) {
            history.push(ProviderLinks.getLinkLocation("clinic-games", {
                providerId,
                nodeId: props.nodeId,
                isAdmin: props.isAdmin,
                clinicId
            }));
        } else {
            toast.error("You do not have the correct permissions to view clinic games.");
        }
    }
    const handleGoToCoupons = (e, clinicId, providerId) => {
        e.stopPropagation();
        e.preventDefault();
        if(props.canViewClinicInfo || props.canViewCorporateGroupClinic) {
            if (props.isAdmin) {
                history.push(ClinicAdminLinks.getLinkLocation("libraryProgram", clinicId, providerId));
            } else {
                history.push(ClinicProviderLinks.getLinkLocation("libraryProgram", props.nodeId, clinicId, providerId));
            }
        } else {
            history.push(ProviderLinks.getLinkLocation("clinic-coupon-library", {
                providerId,
                nodeId: props.nodeId,
                isAdmin: props.isAdmin,
                clinicId
            }));
        }
    }

    const getManufacturerColumn = (provider) => {
        return {
            topHeader: (
                <div className="text-center">
                    <Image style={{borderRadius: "5px"}} src={provider.smallImageUri} alt={provider.name} title={provider.name}/>
                </div>
            ),
            name: (<div className="text-center">{provider.name}</div>),
            selector: `providerClinicEnrollments-${provider.id}`,
            key: `providerClinicEnrollments-${provider.id}`,
            sortable: true,
            sortValue: row => {
                const p = find(row.providerClinicEnrollments, {providerId: provider.id});
                if (!!p) {
                    if (!!p.couponDeactivationDate) {
                        return 2;
                    } else if (!!p.couponActivationDate) {
                        return 0;
                    } else if (!!p.enrollmentDate) {
                        return 1;
                    }
                }
                return 3
            },
            format: row => {
                const p = find(row.providerClinicEnrollments, {providerId: provider.id});
                if (!!p) {
                    if (!!p.couponDeactivationDate) {
                        return (
                            <div
                                className="text-center text-warn"
                                title={`Coupon Deactivation: ${moment(p.couponDeactivationDate).format("MM/DD/YYYY LTS")}`}
                            >
                                Ended
                            </div>
                        );
                    } else if (!!p.couponActivationDate) {
                        return (
                            <div
                                className="text-center"
                                title={`Coupon Activation: ${moment(p.couponActivationDate).format("MM/DD/YYYY LTS")}`}
                            >
                                <i className="fa fa-check-circle fa-2x text-success"/>
                            </div>
                        );
                    } else if (!!p.enrollmentDate) {
                        return (
                            <div
                                className="text-center"
                                title={`Enrollment: ${moment(p.enrollmentDate).format("MM/DD/YYYY LTS")}`}
                            >
                                Registered
                            </div>
                        );
                    }
                }
                return <div/>;
            },
            total: true,
            totalClasses: "text-center",
            rowTotal: row => {
                const p = find(row.providerClinicEnrollments, {providerId: provider.id});
                if (!!p) {
                    if (!!p.couponDeactivationDate) {
                        return 0;
                    } else if (!!p.couponActivationDate) {
                        return 1;
                    }
                }
                return 0;
            }
        }
    }

    const getIRManufacturerColumn = (provider) => {
        return {
            topHeader: (<div/>),
            name: (<div className="text-center">{provider.shortName} Instant Rebates</div>),
            selector: `providerClinicEnrollments-${provider.id}-InstantRebates`,
            key: `providerClinicEnrollments-${provider.id}-InstantRebates`,
            sortable: true,
            sortValue: row => {
                // Special ordering for the instant rebate column
                const p = find(row.providerClinicEnrollments, {providerId: provider.id});
                return !p.isIr;
            },
            format: row => {
                // Special checkbox for the instant rebate column
                const p = find(row.providerClinicEnrollments, {providerId: provider.id});
                if (p.isIr) {
                    return (
                        <div
                            className="text-center"
                            title={`Instant Rebate Activation: ${!!p.irDate ? moment(p.irDate).format("MM/DD/YYYY LTS") : "Unknown"}`}
                        >
                            <i className="fa fa-check-circle fa-2x text-success"/>
                        </div>
                    );
                }
                return <div/>;
            },
            total: true,
            totalClasses: "text-center",
            rowTotal: row => {
                // Special checkbox for the instant rebate column
                const p = find(row.providerClinicEnrollments, {providerId: provider.id});
                if(p.isIr) {
                    return 1;
                }
                return 0;
            }
        }
    }

    const LINKS = [{
        name: "Links",
        selector: "links",
        key: "links",
        format: row => (
            <div className="flex">
                {props.canViewRedemptionSummary && (
                    <Button
                        type="success"
                        iconOnly
                        text
                        onClick={(e) => handleClick(e, row)}
                        title="Clinic Redemption Summary"
                    >
                       <i className="fa fa-ticket margin-right-sm"/>
                    </Button>
                )}
                {props.canViewClinicBIGamesDashboard && (
                    <Button
                        type="success"
                        iconOnly
                        text
                        onClick={(e) => handleGoToClinicGames(e, row.clinicId, (props.providerId || row.territory.providerId))}
                        title="Clinic Games"
                    >
                       <i className="fa fa-trophy-alt margin-right-sm text-success" />
                    </Button>
                )}
                {(props.columns?.showCouponLibraryLink && props.canViewCouponLibrary) && (
                    <Button
                        type="success"
                        iconOnly
                        text
                        onClick={(e) => handleGoToCoupons(e, row.clinicId, (props.providerId || row.territory.providerId))}
                        title="Clinic Coupon Library"
                    >
                       <i className="fa fa-scissors text-success" />
                    </Button>
                )}
            </div>
        ),
    }];

    const MINIMAL_COLUMNS = [
        ...props.canViewRedemptionSummary || props.canViewClinicBIGamesDashboard || (props.columns?.showCouponLibraryLink && props.canViewCouponLibrary) ? LINKS : [], {
        
        name: "Clinic",
        selector: "clinicName",
        key: "clinicName",
        sortable: true,
        searchable: true,
        format: row => (
            <div>
                <div className={classnames({
                    "text-primary": (props.canViewClinicInfo || props.canViewCorporateGroupClinic)
                })}>
                    {row.clinicName}
                </div>
                <div style={{ fontSize: "0.7em" }}>{row.city}, {row.state}</div>
            </div>
        ),
    }];

    const ACCOUNT_COLUMN = [{
        name: props.columns?.accountNumberHeader,
        selector: "accountNumber",
        key: "accountNumber",
        sortable: true,
        sortValue: row => toInteger(row.accountNumber),
        searchable: true,
    }];

    const LOCATION_COLUMN = [{
        name: props.columns?.locationIdHeader,
        selector: "locationId",
        key: "locationId",
        sortable: true,
        sortValue: row => toInteger(row.locationId),
        searchable: true,
    }];

    const handleGoToTerritory = (e, territoryNodeId) => {
        e.stopPropagation();
        e.preventDefault();
        const link = ProviderLinks.getLinkLocation("dashboard", {
            providerId: props.providerId,
            nodeId: territoryNodeId,
            isAdmin: props.isAdmin
        });
        history.push(link);
    };

    const TERRITORY_MANAGER_COLUMN = [{
        name: props.columns?.territoryManagerHeader,
        selector: "territoryManager",
        key: "territoryManager",
        info: "Territory Manager",
        sortable: true,
        searchable: true,
        format: row => {
            const name = get(row, "territory.name", "");
            const territoryNodeId = get(row, "territory.nodeId", null);

            if (props.isAdmin && props.providerId === BOEHRINGER_INGELHEIM && territoryNodeId && territoryNodeId !== props.nodeId) {
                return (
                    <div
                        className="text-primary pointer"
                        onClick={(e) => handleGoToTerritory(e, territoryNodeId)}
                        title={`Go to ${name}`}
                    >
                        {name}
                    </div>
                )
            }

            return (
                <div>{name}</div>
            )
        },
    }];
    const MANUFACTURER_COLUMNS = [];
    if (props.providerBIEnrollments) {
        MANUFACTURER_COLUMNS.push(getIRManufacturerColumn(props.providerBIEnrollments));
        MANUFACTURER_COLUMNS.push(getManufacturerColumn(props.providerBIEnrollments));
    }

    const OTHER_ENROLLED_PROVIDERS_COLUMN = [{
        name: props.columns?.otherEnrolledProvidersHeader,
        selector: "otherEnrolledProviderIds",
        key: "otherEnrolledProviderIds",
        sortable: true,
        searchable: true,
        format: row => (
            <div className="flex flex-wrap spaced-content">
                {map(row.otherEnrolledProviderIds, id => (
                    <div className={classnames(styles.providers, "flex-1")}>
                        <Image
                            src={props.providers[id].smallImageUri}
                            fallbackSrc={props.providers[id].mediumImageUri}
                            alt={props.providers[id].name}
                        />
                    </div>
                ))}
            </div>
        )
    }];

    const ALL_COLUMNS = [
        ...MINIMAL_COLUMNS,
        ...(props.columns?.showAccountNumber ? ACCOUNT_COLUMN : []),
        ...(props.columns?.showLocationId ? LOCATION_COLUMN : []),
        ...(props.columns?.showTerritoryManager ? TERRITORY_MANAGER_COLUMN : []),
        ...(props.nodeType === OrgNodeTypes.NHO ? MANUFACTURER_COLUMNS : []),
        ...(!props.wellnessOnly ? map(props.columns?.additionalColumns, col => {
            switch(col.id) {
                case "dollarAmountPending":
                    return {
                        name: col.header,
                        selector: col.id,
                        key: col.id,
                        sortable: true,
                        searchable: true,
                        total: true,
                        rowTotal: row => row?.data?.dollarAmountPending || 0,
                        dollarTotal: true,
                        format: row => {
                            const value = row?.[col.id] || 0;
                            return formatUSD(value);
                        }
                    };    
                case "ytdRedemptionTotal":
                    return {
                        name: col.header,
                        selector: col.id,
                        key: col.id,
                        sortable: true,
                        searchable: true,
                        total: true,
                        rowTotal: row => row?.data?.ytdRedemptionTotal || 0,
                        dollarTotal: true,
                        format: row => {
                            const value = row?.[col.id] || 0;
                            return formatUSD(value);
                        }
                    };     
                case "isOptedIn":
                    return {
                        name: col.header,
                        selector: col.id,
                        key: col.id,
                        sortable: true,
                        searchable: true,
                        total: false,
                        format: row => row?.[col.id] && <i className="fa fa-2x fa-check-circle text-success"/>,
                    }
                default:
                    return {
                        name: col.header,
                        selector: col.id,
                        key: col.id,
                        sortable: true,
                        searchable: true,
                        total: true,
                        format: row => row?.[col.id]
                    }
            }
        }) : []),
        ...(props.columns?.showOtherEnrolledProviders ? OTHER_ENROLLED_PROVIDERS_COLUMN : [])
    ];

    return (
        <SortableDataTable
            striped
            green
            title={props.title}
            resultsPerPage={props.resultsPerPage || 10}
            // downloadPDF={props.downloadPDF}
            columns={props.showAllColumns ? ALL_COLUMNS : MINIMAL_COLUMNS}
            rawData={props.rawData}
            onClick={props.onRowClick}
            onHover={props.onRowHover}
            minimalPagination={!props.showAllColumns}
            allowSearch
            hideSearchBar
            search={props.search}
            showTotals={!props.wellnessOnly}
        />
    );
}

ProviderClinicTable.propTypes = {
    columns: PropTypes.object.isRequired,
    rawData: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
    ]),
    resultsPerPage: PropTypes.number,
    showAllColumns: PropTypes.bool,
    onRedemptionsIconClicked: PropTypes.func,
    providerId: PropTypes.number,
    nodeId: PropTypes.number,
    nodeType: PropTypes.string,
    hasGames: PropTypes.bool,
    isAdmin: PropTypes.bool,
    onRowClick: PropTypes.func,
    onRowHoverStart: PropTypes.func,
    onRowHoverEnd: PropTypes.func
}

ProviderClinicTable.defaultProps = {
    showAllColumns: true,
}

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;

        //Permissions
        const canViewCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewRedemptionSummary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_REDEMPTION_SUMMARY, userProfile);
        const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewGames = ownProps.hasGames && (canAdminProviderBiGames || canViewProviderBiGames);

        const node = state.entities.nodes[ownProps.nodeId];
        const wellnessOnly = (node?.providerId === PREMIER_PET_CARE_PLAN);
        
        const canViewClinicInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_INFO, userProfile);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewClinicWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canViewWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canEditCouponReprocessing = userHasPermission(PermissionTypes.EDIT, UserPermissions.COUPON_REPROCESSING, userProfile);

        // If User has one of the Wellness Permissions then give them the settings permission
        const canViewWellnessSettings =  canViewClinicWellnessAudit || canViewClinicWellnessPlans || canViewClinicWellnessPetPlans || canViewWellnessDvms || canEditCouponReprocessing;

        return {
            userProfile,
            providers: state.entities.providers,
            providerBIEnrollments: state.entities.providers?.[BOEHRINGER_INGELHEIM],
            search: state.entities.genericSearch,
            //Permissions
            canViewClinicInfo,
            canViewClinicBIGamesDashboard,
            canViewCorporateGroupClinic,
            canViewCouponLibrary,
            canViewRedemptionSummary,
            canViewGames,
            wellnessOnly,
            canViewWellnessSettings,
        };
    },
);

export default compose(
    withRouter,
    connector
) (ProviderClinicTable);
