import React, {useEffect, useState, useRef, useCallback} from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import classnames from "classnames";
import styles from "./Dropdown.scss";
import {map, find, includes} from "lodash";
import TextBox from "components/common/TextBox";
import Tooltip from "components/common/Tooltip";
import * as Position from "constants/TooltipPositionIds";

const DOWN = "down";
const UP = "up";

export default function Dropdown(props) {
	const { dropdownDirection = DOWN } = props;
	const [dropdownId] = useState(uuidv4());
	const dropdownRef = useRef(null);
	const [showOptions, setShowOptions] = useState(false);
	const [optionListStyle, setOptionListStyle] = useState({});
	const selectedOption = (!!(props.value && props.options) && find(props.options, {value: props.value})) || null;
	const selectedValueName = !!selectedOption ? selectedOption.name : null;

	const getOptionListStyle = () => {
		if (showOptions) {
			const boundingBox = !!dropdownRef?.current ? dropdownRef.current.getBoundingClientRect() : null;
			if (boundingBox) {
				if (props.insideScrollingTable) {
					if ((boundingBox.right + boundingBox.width) > (window.innerWidth - 200)) {
						setOptionListStyle({
							top: boundingBox.bottom + 5,
							right: window.innerWidth - boundingBox.right,
						});
					} else {
						setOptionListStyle({
							top: boundingBox.bottom + 5,
							left: boundingBox.left,
						});
					}
				} else {
					if (dropdownDirection === UP) {
						setOptionListStyle({
							bottom: boundingBox.height + 5,
							left: 0,
						});
					} else {
						setOptionListStyle({
							top: boundingBox.height + 5,
							left: 0,
						});
					}
				}
			}
		}
	}

	useEffect(() => {
		const dropdown = document.createElement("div");
		dropdown.id = dropdownId;
		document.getElementById("dropdown-root").appendChild(dropdown);
	}, []);

    // Attach the scroll listener to the div
    useEffect(() => {
	    if (showOptions) {
	        getOptionListStyle();
        }
    }, [showOptions]);

	// Attach the scroll listener to the div
    useEffect(() => {
		// Get base content
		let element = document.getElementsByClassName("ClinicBase__content")[0];
		if (!element) {
			element = document.getElementsByClassName("AdminBase__content")[0];
		}
		if (!element) {
			element = document.getElementsByClassName("ProviderBase__content")[0];
		}
		if (!element) {
			element = document.getElementsByClassName("SupportBase__content")[0];
		}

		// Use base content for positioning the dropdown
		if (props.insideScrollingTable && showOptions && element) {
			const subscription = element.addEventListener("scroll", getOptionListStyle);
			return () => {
				if (subscription) {
					subscription.remove();
				}
			}
		}
    }, [props.insideScrollingTable, showOptions]);

	const handleShowOptions = () => {
		setShowOptions(true);
	};

	const handleCloseOptions = () => {
		setShowOptions(false);
	};

	const handleDropdownClick = (e) => {
		// Keeps the dropdown open correctly
		//  Also, fixes the double clicking issue... I'm not exactly sure why though
		if (!showOptions) {
			handleShowOptions();
			return;
		}

		e.preventDefault();
		e.stopPropagation();
	}

	const handleTogglerClick = () => {
		// Opens and Closes the dropdown when clicking the "input"
		setShowOptions(!showOptions);
	}

	const onChooseOption = (e, name, value) => {
		e.stopPropagation();
		e.preventDefault();
		props.onChange({
			name: props.name,
			value: value,
		});
		handleCloseOptions();
	};

	const renderDropdown = () => {
		const options = map(props.options, (option) => {
			const isEnabled = !includes(props.disabledOptions, option.value);
			return (
				<div
					data-testid={`dropdown_option_${option.value}`}
					key={option.value}
					className={classnames(styles.option, {
						[styles.selected]: (props.value && (props.value === option.value)),
						[styles.disabled]: props.disabled || !isEnabled,
					})}
					onClick={(e) => ( isEnabled && !props.disabled) ? onChooseOption(e, option.name, option.value) : {}}
				>
					{option.name}
				</div>
			);
		});

		if (!!props.onAddNew) {
			options.push(
				<div
					key="addNew"
					data-testid="dropdown_add_new_button"
					className={classnames(styles.option, styles.addNewBtn)}
					onClick={() => props.onAddNew()}
				>
					<i className="fas fa-plus" />Add New
				</div>
			)
		}

		const container = (
			<div
				data-testid="dropdown_options_container"
				className={styles.options}
				style={optionListStyle}
			>
				{(props.placeholder && !props.hidePlaceholder) && (
					<div
						className={classnames(styles.option, {
							[styles.selected]: (!props.value),
						})}
						onClick={(e) => onChooseOption(e, props.placeholder, null)}
					>
						{props.placeholder}
					</div>
				)}
				{props.showClear && (
					<div
						className={classnames(styles.option, styles.clear)}
						onClick={(e) => onChooseOption(e, null, null)}
					>
						Clear Choice
					</div>
				)}
				{options}
			</div>
		);

		if (props.insideScrollingTable) {
			return ReactDOM.createPortal(
				container,
				document.getElementById(dropdownId)
			);
		}
		return(container);
	}

	return (
		<div
			data-testid="dropdown_root"
			ref={dropdownRef}
			className={classnames(styles.root, {
				[styles.xSmall] : props.xSmall,
				[styles.small] : props.small,
				[styles.fullWidth] : props.fullWidth,
				[styles.wide] : props.wide,
			})}
			tabIndex={-1}
			onFocus={(data) => props.disabled ? {} : handleShowOptions(data)}
			onBlur={handleCloseOptions}
			onMouseDown={handleDropdownClick}
		>
			{props.typeAhead ? (
				<>
					<TextBox
						data-testid="dropdown_type_ahead_input"
						placeholder={props.placeholder}
						name={props.name}
						value={selectedValueName}
						searchDropdown
						label={props.label}
						invalid={props.invalid}
						toolTip={props.toolTip}
						onChange={() => {}}
						required={props.required}
						disabled={props.disabled}
						loading={props.loading}
						information={props.information}
						informtionPosition={props.informationPosition}
						hasError={props.hasError}
						alignedLeft={props.alignedLeft}
						alignedSplit={props.alignedSplit}
					/>
					<div className={styles.caretArrow}><i className="fas fa-caret-down" /></div>
				</>
			) : (
				<div className={classnames("form-group", {
					"aligned left": props.alignedLeft,
					"aligned split": props.alignedSplit,
				})}>
					{props.label && (
						<label
							data-testid="dropdown_label"
							className="flex"
						>
							{!!props.information && (
								<div className="flex-none">
									<Tooltip position={props.informationPosition || Position.RIGHT} tip={props.information}>
										<i className="fa fa-info-circle margin-right-x-sm"/>
									</Tooltip>
								</div>
							)}
							<div className="flex-1">{props.label}{props.required && "*"}</div>
						</label>
					)}
					<div
						data-testid="dropdown_select_box"
						className={classnames(styles.selectBox, {
							[styles.disabled] : props.disabled,
							[styles.hasError] : props.hasError
						})}
						onMouseDown={handleTogglerClick}
					>
						<input
							data-testid="dropdown_input"
							autoComplete="off"
							className={styles.hidden}
							name={props.name}
							value={props.value === undefined || props.value === null ? "" : props.value}
							disabled={props.disabled}
							required={props.required}
							readOnly
						/>
						{selectedValueName && <span>{selectedValueName}</span>}
						{!selectedValueName && props.placeholder && <span className={styles.placeholder}>{props.placeholder}</span> }
						<div className={styles.caretArrow}>
							<i className={classnames("fas", {
								"fa-caret-down" : dropdownDirection === DOWN,
								"fa-caret-up" : dropdownDirection === UP
							})}
							/>
						</div>
					</div>
				</div>
			)}
			{(!!showOptions && !props.disabled) && renderDropdown()}
		</div>
	);
}

Dropdown.propTypes = {
	options: PropTypes.arrayOf(PropTypes.shape({
		name: PropTypes.string,
		value: PropTypes.any,
	})),
	value: PropTypes.any,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onAddNew: PropTypes.func,
	placeholder: PropTypes.string,
	className: PropTypes.string,
	invalid: PropTypes.bool,
	toolTip: PropTypes.func,
	hasError: PropTypes.bool,
	alignedLeft: PropTypes.bool,
	alignedSplit: PropTypes.bool,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	loading: PropTypes.bool,
	typeAhead: PropTypes.bool,
	showClear: PropTypes.bool,
	onClear: PropTypes.func,
	small: PropTypes.bool,
	xSmall: PropTypes.bool,
	wide: PropTypes.bool,
	fullWidth: PropTypes.bool,
	hidePlaceholder: PropTypes.bool,
	insideScrollingTable: PropTypes.bool,
	information: PropTypes.string,
	dropdownDirection: PropTypes.oneOf([UP, DOWN]),
	informationPosition: PropTypes.oneOf([Position.LEFT, Position.RIGHT, Position.TOP, Position.BOTTOM]),
	disabledOptions: PropTypes.array,
};
