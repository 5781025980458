import React, { useState } from "react";
import LeafPartyImportantSafetyInformation from "components/common/ImportantSafetyInformation/LeafPartyImportantSafetyInformation";
import Modal from "components/common/Modal";
import styles from "./LeafPartyLegalWording.scss";

export function LeafPartyLeaderboardLegalWording(props) {
	return (
		<div className={styles.instructions}>
            <span>
                The number of doses shown above are doses of HEARTGARD® Plus (ivermectin/pyrantel), <em>NexGard</em>® (afoxolaner), <em>NexGard</em>® PLUS (afoxolaner, moxidectin, and pyrantel chewable tablets) sold so far in this game.
                <br/><br/>
                The percentage compares doses sold so far in this game versus doses sold in this timeframe the previous sixty days in 2023 (August and September).
                <br/>
                "VLSD" stands for Versus Last Sixty Days.
                <br/><br/>
                <a
                    className="text-primary"
                    href="https://cdn.greenlinepet.com/games/assets/leafpartytermsandconditions/ParasFallTermsAndConditions.pdf"
                    target="_blank"
                >
                    View terms and conditions.
                </a>
            </span>
        </div>
	)
}

export default function LeafPartyLegalWording(props) {
	const [showSafetyInfo, setShowSafetyInfo] = useState(false);
	const handleShowSafetyInformation = () => {
        setShowSafetyInfo(true);
    }
	return (
		<div className={styles.root}>
            <div className={styles.tagLine}>
                <span className={styles.keepTogether}>Dispense HEARTGARD® Plus (ivermectin/pyrantel)</span>
                {", "}
                <span className={styles.keepTogether}><em>NexGard®</em> (afoxolaner)</span>
                {", and "}
                <span className={styles.keepTogether}>
                    <em>NexGard®</em> PLUS (afoxolaner, moxidectin and pyrantel chewable tablets)
                </span>
                {", win points!"}
            </div>
            <div className={styles.viewSafetyLink} onClick={handleShowSafetyInformation}>
                {"Click here to view the Important Safety Information for "}
                <span className={styles.keepTogether}>HEARTGARD® Plus (ivermectin/pyrantel)</span>
                {", "}
                <span className={styles.keepTogether}><em>NexGard®</em> (afoxolaner)</span>
                {", and "}
                <span className={styles.keepTogether}><em>NexGard®</em> PLUS (afoxolaner, moxidectin and pyrantel chewable tablets)</span>
            </div>
			<Modal
                modalTitle="Important Safety Information"
                show={showSafetyInfo}
                onClose={() => setShowSafetyInfo(false)}
            >
                <LeafPartyImportantSafetyInformation />
            </Modal>
        </div>
	)
}