import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Loader from "components/common/Loader";
import logger from "utils/logger";
import toast from "utils/toast";

export default function VetCheckRegisterRedirectCallback(props) {
    const history = useHistory();
    useEffect(() => {
        logger.log(props, "PROPS");
        // const clinicId = props.match.params.clinicId;
        const search = new URLSearchParams(props.location.search);
        const isSuccessful = search.get("success");
        const message = search.get("message");
        // const clinicId = search.get("clinicId");

        if(isSuccessful === "true") {
            toast.success("Registered VetCheck Successfully");
        } else {
            toast.error(`Error Registering VetCheck: ${message}`);
        }
        // if(clinicId) {
        //     history.replace(`/admin/clinic/${clinicId}/vetcheck`);
        // } else {
        //     history.replace(`/vetcheck`)
        // }
    }, []);

    return (
        <div>
            <Loader text="VetCheck Register (redirect)" />
        </div>
    );
}
