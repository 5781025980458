import React from "react";
import {
	MAIN_COLOR,
	SECONDARY_COLOR,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./PuppyPoleVault.scss";


export default function PuppyPoleVault(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Pole_Vault_-_Puppy_-_Run"
			data-name="Pole Vault - Puppy - Run"
			// xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1600 300"
		>
			<g id="Puppy_Vault_Run04">
				<g id="Puppy_Vault_Run04_Lower">
					<path className={styles.cls6}
					      d="M1248.18,286.62c0,4.63,28.34,8.38,63.31,8.38,34.97,0,63.31-3.75,63.31-8.38,0-4.63-28.34-8.38-63.31-8.38-34.97,0-63.31,3.75-63.31,8.38Z"/>
					<path className={styles.cls10}
					      d="M1345.31,215.52c11.05,12.27,12.19,36.45-1.97,43.17-14.12,6.7-20.68,3.72-28.38,4.76,7.53,12.28-9.7,19.22-12.61,14.03-5.19-9.25-7.66-27.17,2.46-24.75,9.37,2.25,20.28-4.66,14.74-12.9-10.75-15.98,18.16-32.75,25.77-24.31Z"/>
					<path className={styles.cls10}
					      d="M1320.18,170.53c24.89,10.39,60.81-11.53,52.68-22.35-7.64-10.16-26.05,15.06-48.77,6.52-7.74-2.91-14.03,11.61-3.91,15.84Z"/>
					<g>
						<path className={styles.cls9}
						      d="M1276.87,225.16c2.2.22,4.42.32,6.67.21,2.69-.13,17.75-5.06,16.72.89-.16.9-.72,1.72-1.47,2.27-2.53,1.88-5.15,3.74-8.01,4.86-4.71,1.85-9.58,1.54-14.13.23-.57-1.15-.91-2.41-.95-3.69-.06-1.66.37-3.33,1.19-4.78Z"/>
						<path className={styles.cls8}
						      d="M1264.68,224.6c.27-.26.66-.51,1.2-.72,2.5-.99,5.83.58,8.27.95.9.13,1.81.24,2.73.33-.82,1.45-1.25,3.12-1.19,4.78.04,1.28.38,2.54.95,3.69-2.02-.58-3.97-1.36-5.82-2.24-1.29-.61-8.44-4.5-6.14-6.79Z"/>
					</g>
					<path className={styles.cls8}
					      d="M1316.28,242.06c-33.42,6.52-27.89-30.04-20.85-43.7,4.7-9.11-3.67-30.69,1.85-43.22,5.31-12.04,32.47-9.24,35.54-7.21,15.39,10.18,21.91,35.8,20.43,57.06-1.22,17.45-12.19,32.23-36.98,37.06Z"/>
					<path className={styles.cls9}
					      d="M1290.08,217.29c.44-5.58,9.07,1.55,8.76,9.37-.33,8.34,1.73,15.05-.69,13.37-6.43-4.45-9.06-10.28-8.07-22.75Z"/>
				</g>
				<path
					id="Shirt"
					className={styles.cls2}
					d="M1349.67,171.68c-.93-2.45-2.87-6.58-5.1-10.57,1.43,3.18,1.81,5.28-.52,6.16-3.23,1.23-6.94-3.32-9.56-7.11-4.04-5.83-28.87-18.84-35.99-11.71-7.34,7.34-1.73,33.68-1.73,41.53,0,6.12-2.45,10.71-4.05,14.1,15.69,9.56,39.82,8.8,60.88-.8,1.35-9.82-1.66-25.65-3.93-31.61ZM1316.19,179.72c-7.4,0-13.4-5.93-13.4-13.24s6-13.24,13.4-13.24,13.4,5.93,13.4,13.24-6,13.24-13.4,13.24Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes">
					<path
						className={styles.cls11}
						d="M1341.11,199.17c-6.07,1.74-12.22,2.78-18.26,3.08-9.73.48-19.3-1.03-27.47-4.35.46-1.28.85-3.02,1.02-3.88,7.77,3.24,16.92,4.81,26.26,4.35,10.28-.51,20.91-3.3,30.87-8.07.09.62.32,3.25.32,4.14-4.16,1.91-8.43,3.5-12.74,4.73Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M1316.11,152.26c-7.92,0-14.37,6.38-14.37,14.21s6.45,14.21,14.37,14.21,14.37-6.38,14.37-14.21-6.45-14.21-14.37-14.21ZM1316.11,178.75c-6.85,0-12.43-5.5-12.43-12.27s5.58-12.27,12.43-12.27,12.43,5.5,12.43,12.27-5.58,12.27-12.43,12.27Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M1344.7,168.68c-4.62,0-9.45-4.82-11.1-6.98-.36-.48-.76-1.03-1.21-1.65-2.95-4.11-7.9-10.99-14.87-11.72l.2-1.93c7.84.82,13.1,8.14,16.25,12.52.43.6.82,1.14,1.17,1.61,1.71,2.25,5.85,6.06,9.38,6.19,1.68.06,1.16-2.93.12-5.46,3.82,4.22,2.2,7.45.06,7.43Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Vault_Run04_Mid">
					<path className={styles.cls8}
					      d="M1341.33,230.51c-1.13,25.5-19.06,39.33-38.68,43.98,7.01,1.02,9.82,7.37,6.89,11.01-1.23,1.52-18.28,1.44-19.86.1-5.26-4.43-7.57-16.8-5.36-17.99,15.06-8.04,15.94-26.87,16.74-33.72,1.45-12.46,40.86-16.72,40.27-3.38Z"/>
					<path className={styles.cls4}
					      d="M1298.33,149.53c5.59,4.2,30.28,14.39,44.48,3.77,3.78-2.83,6.61-6.25,7.24-11.44,1.67-13.65-10-16.72-19.96-18.7-9.38-1.86-23.77-3.19-30.82,5.55-1.52,1.88-5.75,17.21-.94,20.83Z"/>
					<g>
						<path className={styles.cls9}
						      d="M1351.73,59.64c3.74,3.59,12.69,21.65,8.07,25.16-7.26,5.5-38.23,2.04-41.27,1.97-3.81-.09-7.64-1.58-9.21-4.19-2.55-4.25,1.6-11.08,4.93-14.43,12.95-12.99,25.67-19.85,37.49-8.51Z"/>
						<g>
							<path className={styles.cls8}
							      d="M1338.51,147.99c-2.2-4.33-2.92-9.39-2.01-14.16.93-4.92,3.54-9.64,3-14.62-.73-6.78-6.9-11.5-12.72-15.04-8.83-5.39-18.11-10.05-27.69-13.94-2.45-.99-4.99-1.94-7.57-2.55,4.37-3.36,9.29-6.06,14.54-8.26.61.35,1.26.64,1.89.87,4.06,1.52,8.27,2.69,12.1,4.76,8.02,4.32,13.68,12.13,20.11,18.45,3.01,2.96,6.72,5.68,10.88,5.71,4.14.02,7.74-2.61,11.64-3.97,2.44-.85,5.05-1.14,7.64-.95,3.39,2.06,6.43,4.53,7.72,7.59,3.23,7.7,2.71,18.07-1.49,23.69-7.1,9.5-14.67,12.87-23.35,16.3-3.72,1.47-7.5,2.39-11.29,2.89-.76-2.31-2.28-4.57-3.4-6.78Z"/>
							<path className={styles.cls5}
							      d="M1286.5,135.99c-3.12-3.7-5.93-7.71-8.3-11.92-4.12-7.31-3.28-13.74.39-21,3.18-6.3,7.64-11.33,12.93-15.39,2.58.61,5.12,1.56,7.57,2.55,9.59,3.89,18.86,8.56,27.69,13.94,5.82,3.55,11.99,8.27,12.72,15.04.54,4.98-2.07,9.7-3,14.62-.91,4.77-.19,9.83,2.01,14.16,1.12,2.21,2.64,4.47,3.4,6.78-24.99,3.3-50.01-12.36-55.41-18.78Z"/>
							<path className={styles.cls5}
							      d="M1351.04,109.21c-4.16-.02-7.87-2.74-10.88-5.71-6.43-6.32-12.09-14.13-20.11-18.45-3.83-2.06-8.04-3.24-12.1-4.76-.63-.23-1.28-.52-1.89-.87.76-.32,1.53-.63,2.3-.93,9.38-3.63,17.96-5.69,27.68-.62,4.87,2.54,8.71,8.24,12.16,12.38,4.04,4.84,7,6.12,12.72,9.11,2.65,1.38,6.18,2.97,9.4,4.93-2.59-.19-5.19.1-7.64.95-3.9,1.36-7.49,3.99-11.64,3.97Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls3}
								      d="M1317.04,118.25c3.31,4.12,7.86,5.96,10.17,4.1,2.31-1.86,1.5-6.71-1.81-10.83-3.31-4.12-7.86-5.96-10.17-4.1-2.31,1.86-1.5,6.71,1.81,10.83Z"/>
								<path className={styles.cls3}
								      d="M1343.72,101.14c3.61,3.57,7.94,5.04,9.65,3.29,1.72-1.75.18-6.07-3.44-9.63-3.62-3.57-7.94-5.04-9.65-3.29-1.72,1.75-.18,6.06,3.44,9.63Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls3}
									      d="M1357.23,117.65c.62-1.6,2.32-3.39,4.41-4.81h0s0,0,0,0c0,0,0,0,0,0h0c2.05-1.47,4.31-2.45,6.01-2.47,8.13-.09,6.74,9.1,1.52,13.41h0s0,0,0,0c0,0,0,0,0,0h0c-5.84,3.41-14.87,1.5-11.94-6.14Z"/>
									<path className={styles.cls3}
									      d="M1377.36,126.81c-.14-.05-.3-.08-.48-.06-.59.06-.76.65-1.04,1.08-.4.6-.91,1.18-1.46,1.63-1.77,1.44-3.56,1.74-5.67,1.69l-.04-.08s-.04.05-.05.08c-.03,0-.06,0-.1,0l.04.08c-1.14,1.77-2.39,3.09-4.58,3.74-1.51.45-4.95.46-6.43-.46-.52-.32-1.19-.16-1.5.36-.31.52-.13,1.21.38,1.53,2.28,1.42,6.49,1.22,8.2.71.66-.2,1.26-.44,1.8-.73,1.48-.78,2.51-1.86,3.35-3.02,1.43-.05,2.9-.29,4.38-1.08.54-.28,1.08-.64,1.61-1.08.77-.63,1.84-1.64,2.18-2.6.23-.64.04-1.55-.6-1.79Z"/>
								</g>
								<path className={styles.cls3}
								      d="M1369.58,133.43c-.27.16-.6.22-.93.12-.63-.18-1.01-.84-.85-1.48.02-.07.76-3.3-.88-8.95-.18-.64.17-1.3.8-1.47.63-.17,1.28.2,1.47.84,1.85,6.4.94,10.08.9,10.23-.08.31-.27.55-.52.7Z"/>
							</g>
							<path className={styles.cls8}
							      d="M1371.42,112.99c.23,1.24-1.36,2.57-3.54,2.98-2.18.4-4.14-.27-4.37-1.51-.23-1.24,1.36-2.57,3.54-2.98s4.14.27,4.37,1.51Z"/>
							<path className={styles.cls8}
							      d="M1347.82,95.21c-.37.7-1.7.73-2.99.07s-2.03-1.78-1.66-2.48c.37-.7,1.7-.73,2.99-.07,1.28.67,2.03,1.78,1.66,2.48Z"/>
							<path className={styles.cls8}
							      d="M1322.92,111.93c-.39.95-2.1,1.15-3.81.44-1.71-.71-2.77-2.05-2.38-3,.39-.95,2.1-1.15,3.81-.44,1.71.71,2.77,2.05,2.38,3Z"/>
						</g>
					</g>
				</g>
				<g id="Stripe">
					<path
						className={styles.cls11}
						d="M1283.19,130.48c13.49-22.75,40.54-41.62,62.69-43.89,1.7-.17-6.1-10.4-11.74-10.44-14.57-.09-53.53,24.82-56.8,44.18-.49,2.88,4.58,12.29,5.85,10.15Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Vault_Run04_Upper">
					<path className={styles.cls9}
					      d="M1251.09,118.75c1.73,5.29,14.61,22.55,19.82,19.88,8.17-4.19,18.15-35.2,19.37-38.11,1.54-3.65,1.79-8,.05-10.76-2.85-4.49-10.92-3.88-15.43-2.35-17.51,5.93-29.26,14.64-23.81,31.34Z"/>
					<g>
						<path className={styles.cls1}
						      d="M1277.79,217.24c-1.48,0-2.94-.71-3.83-2.04-1.43-2.11-.87-4.98,1.25-6.41L1544.62,27.13c2.11-1.42,4.98-.87,6.41,1.25,1.43,2.11.87,4.98-1.25,6.41l-269.41,181.67c-.79.53-1.69.79-2.58.79Z"/>
						<rect className={styles.cls11} x="1306.03" y="182.97" width="17.78" height="9.23"
						      transform="translate(119.83 767.2) rotate(-33.99)"/>
						<rect className={styles.cls7} x="1376.98" y="132.22" width="26.42" height="9.23"
						      transform="translate(161.05 800.58) rotate(-33.99)"/>
					</g>
					<path className={styles.cls8}
					      d="M1308.79,161.54c-13.04,2.12-28.05,13.65-28.52,21.42-.48,7.96,10.67,28.14,19.59,23.42,10.81-5.72-.77-15.31.23-18.7,1.02-3.47,8.75-7.56,12.63-9.03,7.73-2.93,6.89-18.86-3.93-17.1Z"/>
				</g>
			</g>
			<g id="Puppy_Vault_Run03">
				<g id="Puppy_Vault_Run03_Lower">
					<path className={styles.cls6}
					      d="M866.94,287.8c0,3.98,24.36,7.2,54.41,7.2,30.05,0,54.41-3.22,54.41-7.2,0-3.98-24.36-7.2-54.41-7.2-30.05,0-54.41,3.22-54.41,7.2Z"/>
					<path className={styles.cls10}
					      d="M924.3,150.6c24.89,10.39,60.81-11.53,52.68-22.35-7.64-10.16-26.05,15.06-48.77,6.52-7.74-2.91-14.03,11.61-3.91,15.84Z"/>
					<path className={styles.cls10}
					      d="M924.18,224.04c-32.54,10.22-48.87-6.41-59.68-17.22-6.39,5.98-17.96,2.01-20.36-3.07-1.6-3.39,21.48-21.06,25.6-14.17,10.88,18.23,29.81,6.41,37.07.66,9.83-7.79,30.1,29.8,17.36,33.8Z"/>
					<g>
						<path className={styles.cls9}
						      d="M887.06,201.5c1.94,1.05,3.96,1.99,6.07,2.75,2.53.91,18.33,2.14,15.1,7.24-.49.77-1.33,1.31-2.23,1.53-3.06.77-6.19,1.48-9.27,1.41-5.06-.1-9.44-2.26-13.14-5.21-.09-1.28.09-2.57.54-3.77.58-1.56,1.62-2.93,2.93-3.95Z"/>
						<path className={styles.cls8}
						      d="M876.01,196.3c.35-.14.8-.21,1.38-.2,2.69.05,5.16,2.78,7.28,4.05.78.47,1.58.92,2.39,1.35-1.31,1.02-2.35,2.4-2.93,3.95-.45,1.2-.62,2.49-.54,3.77-1.64-1.31-3.15-2.78-4.52-4.3-.96-1.06-6.06-7.4-3.06-8.62Z"/>
					</g>
					<path className={styles.cls8}
					      d="M925.71,224.58c-33.17,7.71-28.95-29.02-22.4-42.92,4.37-9.27-4.77-30.54.3-43.26s32.12-10.4,35.26-8.48c15.75,9.62,23.19,34.99,22.47,56.29-.59,17.49-11.03,32.65-35.62,38.37Z"/>
					<path className={styles.cls9}
					      d="M898.63,200.77c.24-5.59,9.12,1.23,9.09,9.05-.03,8.35,2.27,14.98-.21,13.39-6.59-4.22-9.43-9.95-8.88-22.44Z"/>
				</g>
				<path
					id="Shirt-2"
					data-name="Shirt"
					className={styles.cls2}
					d="M956.54,153.05c-2.35-5.56-10.36-19.86-14.4-20.81,2.45,1.97,12.67,14.4,6.18,18.05-5.99,3.37-10.35-1.1-13.07-4.82-7.45-10.17-23.81-21.18-30.68-13.8-7.07,7.59-.52,33.72-.24,41.57.22,6.12-2.07,10.79-3.54,14.24,16.02,8.99,40.11,7.36,60.81-2.98.99-9.86-2.58-25.57-5.06-31.45ZM917.11,162.51c-7.4.27-13.6-5.44-13.87-12.75-.26-7.31,5.52-13.45,12.92-13.71,7.4-.27,13.6,5.44,13.87,12.75s-5.52,13.45-12.92,13.71Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-2" data-name="Stripes">
					<path
						className={styles.cls11}
						d="M948.99,180.83c-6.01,1.95-12.11,3.22-18.13,3.74-9.7.83-19.32-.33-27.61-3.36.42-1.3.74-3.05.88-3.92,7.88,2.96,17.08,4.2,26.4,3.4,10.25-.88,20.78-4.04,30.56-9.17.11.62.43,3.24.47,4.13-4.09,2.06-8.3,3.8-12.57,5.18Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M916.13,135.07c-7.92.28-14.13,6.89-13.85,14.72.28,7.83,6.95,13.97,14.87,13.69,7.92-.28,14.13-6.89,13.85-14.72-.28-7.83-6.95-13.97-14.87-13.69ZM917.08,161.54c-6.85.25-12.62-5.05-12.86-11.82-.24-6.76,5.13-12.46,11.98-12.71,6.85-.25,12.62,5.05,12.86,11.82.24,6.76-5.13,12.46-11.98,12.71Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M945.47,152.29c-4.62.17-9.62-4.47-11.34-6.58-.38-.46-.8-1.01-1.26-1.61-3.1-4-8.29-10.7-15.28-11.18l.13-1.94c7.86.54,13.39,7.67,16.69,11.93.45.58.86,1.11,1.23,1.56,2.24,2.74,8.41,7.82,12.1,5.04,1.07-.8,1.64-1.87,1.71-3.17.13-2.45-1.47-7.01-9.84-14.8l1.32-1.42c7.16,6.66,10.67,12.15,10.45,16.32-.1,1.88-.96,3.48-2.49,4.62-1.08.81-2.24,1.17-3.43,1.21Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Vault_Run03_Mid">
					<path className={styles.cls10}
					      d="M923.47,200.8c9.89-8.63,25.88-12.29,36.58-5.32-.25,1.13-.79,2.84-1.31,3.44-2.89,3.34-34.13,5.8-35.28,1.88Z"/>
					<path className={styles.cls8}
					      d="M919.93,202.54c3.67-4.45,23.82-13.18,36.72-7.44,11.05,4.92,24.69,20.24,28.63,32.81,6.93-9.33,15.18-3.66,15.95.1,1.42,6.84-9.91,17.22-19.62,17.04-5.66-7.07-38.73-24.27-56.27-20.39-17.88,3.96-11.28-15-5.41-22.13Z"/>
					<path className={styles.cls4}
					      d="M906.22,129.7c5.59,3.64,30.28,12.44,44.48,3.26,3.78-2.45,6.61-5.4,7.24-9.9,1.67-11.8-10-14.46-19.96-16.17-9.38-1.61-23.77-2.76-30.82,4.8-1.52,1.63-5.75,14.88-.94,18.01Z"/>
					<g>
						<path className={styles.cls9}
						      d="M956.45,40.86c3.67,3.67,12.27,21.9,7.57,25.32-7.36,5.36-38.26,1.29-41.3,1.16-3.81-.16-7.61-1.73-9.13-4.37-2.47-4.3,1.81-11.05,5.22-14.33,13.2-12.74,26.05-19.34,37.64-7.77Z"/>
						<g>
							<path className={styles.cls8}
							      d="M952.91,125.36c-2.81-3.96-4.26-8.86-4.06-13.71.21-5,2.09-10.06.84-14.9-1.71-6.6-8.51-10.36-14.78-13.02-9.53-4.04-19.38-7.3-29.43-9.75-2.57-.62-5.22-1.19-7.86-1.42,3.83-3.96,8.31-7.35,13.17-10.29.65.26,1.34.44,2,.58,4.24.91,8.58,1.46,12.67,2.94,8.57,3.11,15.31,10.01,22.59,15.32,3.41,2.49,7.48,4.64,11.6,4.05,4.1-.58,7.28-3.71,10.93-5.63,2.29-1.2,4.83-1.86,7.42-2.05,3.66,1.54,7.02,3.54,8.74,6.38,4.32,7.15,5.32,17.48,1.99,23.65-5.63,10.44-12.63,14.87-20.72,19.54-3.46,2-7.07,3.46-10.74,4.51-1.08-2.17-2.92-4.19-4.35-6.21Z"/>
							<path className={styles.cls5}
							      d="M899.7,121.08c-3.63-3.21-6.99-6.76-9.95-10.58-5.14-6.63-5.25-13.11-2.68-20.84,2.23-6.7,5.91-12.32,10.54-17.11,2.64.23,5.29.79,7.86,1.42,10.05,2.45,19.91,5.71,29.43,9.75,6.27,2.66,13.07,6.43,14.78,13.02,1.26,4.85-.63,9.9-.84,14.9-.2,4.85,1.25,9.75,4.06,13.71,1.43,2.02,3.27,4.03,4.35,6.21-24.23,6.92-51.28-4.93-57.56-10.48Z"/>
							<path className={styles.cls5}
							      d="M959.64,85.16c-4.12.58-8.18-1.57-11.6-4.05-7.28-5.31-14.02-12.21-22.59-15.32-4.09-1.48-8.43-2.03-12.67-2.94-.65-.14-1.34-.33-2-.58.71-.43,1.42-.84,2.14-1.25,8.75-4.96,16.94-8.26,27.29-4.66,5.18,1.8,9.82,6.88,13.84,10.47,4.71,4.2,7.81,5.04,13.91,7.15,2.83.98,6.55,2.04,10.02,3.5-2.59.19-5.13.85-7.42,2.05-3.66,1.92-6.83,5.04-10.93,5.63Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls3}
								      d="M927.32,99.07c3.88,3.6,8.65,4.75,10.66,2.57,2.01-2.18.5-6.86-3.37-10.45-3.88-3.6-8.65-4.74-10.66-2.57-2.01,2.18-.5,6.86,3.37,10.45Z"/>
								<path className={styles.cls3}
								      d="M951.22,78.24c4.1,3,8.59,3.83,10.03,1.84,1.44-1.98-.71-6.03-4.81-9.03-4.1-3-8.59-3.82-10.03-1.84-1.44,1.98.71,6.03,4.81,9.03Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls3}
									      d="M967,92.61c.38-1.67,1.8-3.69,3.66-5.4h0s0,0,0,0c0,0,0,0,0,0h0c1.82-1.75,3.9-3.05,5.59-3.32,8.03-1.27,7.99,8.02,3.46,13.05h0s0,0,0,0c0,0,0,0,0,0h0c-5.28,4.22-14.49,3.65-12.71-4.33Z"/>
									<path className={styles.cls3}
									      d="M988.25,98.73c-.15-.03-.31-.03-.48.01-.58.15-.66.76-.87,1.22-.3.65-.72,1.3-1.2,1.82-1.54,1.69-3.27,2.24-5.36,2.5l-.05-.07s-.03.06-.04.09c-.03,0-.06,0-.09.01l.05.07c-.87,1.92-1.92,3.4-3.99,4.37-1.43.67-4.83,1.18-6.43.48-.56-.24-1.2.01-1.43.57-.23.56.04,1.21.6,1.46,2.46,1.07,6.6.26,8.22-.5.63-.29,1.18-.62,1.67-.98,1.35-.99,2.21-2.21,2.87-3.47,1.41-.26,2.82-.71,4.17-1.71.49-.36.97-.79,1.44-1.3.67-.74,1.58-1.89,1.78-2.9.14-.67-.19-1.54-.85-1.69Z"/>
								</g>
								<path className={styles.cls3}
								      d="M981.52,106.41c-.24.2-.56.3-.9.26-.65-.09-1.12-.69-1.05-1.34,0-.07.27-3.37-2.18-8.72-.27-.61-.02-1.31.57-1.58.6-.26,1.3.01,1.58.62,2.76,6.06,2.4,9.83,2.38,9.99-.03.32-.19.59-.41.77Z"/>
							</g>
							<path className={styles.cls8}
							      d="M980.35,85.93c.41,1.19-.97,2.74-3.07,3.46-2.1.72-4.14.33-4.54-.86-.41-1.19.97-2.74,3.07-3.46s4.14-.33,4.54.86Z"/>
							<path className={styles.cls8}
							      d="M954.41,71.78c-.26.75-1.58.97-2.95.5s-2.27-1.46-2.01-2.21c.26-.75,1.58-.97,2.95-.5,1.37.47,2.27,1.46,2.01,2.21Z"/>
							<path className={styles.cls8}
							      d="M932.22,91.96c-.25,1-1.91,1.44-3.7.99-1.79-.45-3.04-1.62-2.79-2.62.25-1,1.91-1.44,3.7-.99,1.79.45,3.04,1.62,2.79,2.62Z"/>
						</g>
					</g>
				</g>
				<g id="Stripe-2" data-name="Stripe">
					<path
						className={styles.cls11}
						d="M895.62,116.12c10.02-24.48,34.02-47.09,55.61-52.58,1.65-.42-7.55-9.4-13.14-8.61-14.42,2.04-49.33,32.38-49.74,52-.06,2.92,6.33,11.48,7.27,9.19Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Vault_Run03_Upper">
					<path className={styles.cls9}
					      d="M863.62,115.64c2.98,4.7,19.74,18.24,24.12,14.36,6.88-6.08,8.88-38.59,9.35-41.72.59-3.92-.24-8.2-2.62-10.44-3.87-3.64-11.54-1.06-15.54,1.54-15.5,10.08-24.73,21.42-15.31,36.26Z"/>
					<g>
						<path className={styles.cls1}
						      d="M881.91,197.31c-1.48,0-2.94-.71-3.83-2.04-1.43-2.11-.87-4.98,1.25-6.41L1148.74,7.2c2.11-1.42,4.98-.87,6.41,1.25,1.43,2.11.87,4.98-1.25,6.41l-269.41,181.67c-.79.53-1.69.79-2.58.79Z"/>
						<rect className={styles.cls11} x="910.15" y="163.04" width="17.78" height="9.23"
						      transform="translate(63.32 542.47) rotate(-33.99)"/>
						<rect className={styles.cls7} x="981.1" y="112.29" width="26.42" height="9.23"
						      transform="translate(104.55 575.86) rotate(-33.99)"/>
					</g>
					<path className={styles.cls8}
					      d="M912.91,141.61c-13.04,2.12-28.05,13.65-28.52,21.42-.48,7.96,10.67,28.14,19.59,23.42,10.81-5.72-.77-15.31.23-18.7,1.02-3.47,8.75-7.56,12.63-9.03,7.73-2.93,6.89-18.86-3.93-17.1Z"/>
				</g>
			</g>
			<g id="Puppy_Vault_Run02">
				<g id="Puppy_Vault_Run02_Lower">
					<path className={styles.cls6}
					      d="M450.17,286.62c0,4.63,28.34,8.38,63.31,8.38,34.97,0,63.31-3.75,63.31-8.38,0-4.63-28.34-8.38-63.31-8.38-34.97,0-63.31,3.75-63.31,8.38Z"/>
					<path className={styles.cls10}
					      d="M518.19,168.92c24.89,10.39,60.81-11.53,52.68-22.35-7.64-10.16-26.05,15.06-48.77,6.52-7.74-2.91-14.03,11.61-3.91,15.84Z"/>
					<path className={styles.cls10}
					      d="M537.87,229.24c0,25.57-4.89,39.54-33.79,45.25,7.01,1.02,9.82,7.37,6.89,11.01-1.23,1.52-18.28,1.44-19.86.1-5.26-4.43-7.57-16.8-5.36-17.99,15.06-8.04,17.62-28.97,18.41-35.82,1.45-12.46,33.71-15.9,33.71-2.55Z"/>
					<g>
						<path className={styles.cls9}
						      d="M477.91,223.55c2.2.22,4.42.32,6.67.21,2.69-.13,17.75-5.06,16.72.89-.16.9-.72,1.72-1.47,2.27-2.53,1.88-5.15,3.74-8.01,4.86-4.71,1.85-9.58,1.54-14.13.23-.57-1.15-.91-2.41-.95-3.69-.06-1.66.37-3.33,1.19-4.78Z"/>
						<path className={styles.cls8}
						      d="M465.72,222.99c.27-.26.66-.51,1.2-.72,2.5-.99,5.83.58,8.27.95.9.13,1.81.24,2.73.33-.82,1.45-1.25,3.12-1.19,4.78.04,1.28.38,2.54.95,3.69-2.02-.58-3.97-1.36-5.82-2.24-1.29-.61-8.44-4.5-6.14-6.79Z"/>
					</g>
					<path className={styles.cls8}
					      d="M517.32,240.45c-33.42,6.52-27.89-30.04-20.85-43.7,4.7-9.11-3.67-30.69,1.85-43.22,5.31-12.04,32.47-9.24,35.54-7.21,15.39,10.18,21.91,35.8,20.43,57.06-1.22,17.45-12.19,32.23-36.98,37.06Z"/>
					<path className={styles.cls9}
					      d="M491.12,215.68c.44-5.58,9.07,1.55,8.76,9.37-.33,8.34,1.73,15.05-.69,13.37-6.43-4.45-9.06-10.28-8.07-22.75Z"/>
				</g>
				<path
					id="Shirt-3"
					data-name="Shirt"
					className={styles.cls2}
					d="M550.71,170.07c-2.15-5.64-9.64-20.22-13.65-21.31,2.38,2.05,12.15,14.85,5.52,18.26-6.1,3.15-10.26-1.5-12.89-5.29-4.04-5.83-23.04-22.02-30.16-14.89s-1.73,33.68-1.73,41.53c0,6.12-2.45,10.71-4.05,14.1,15.69,9.56,39.82,8.8,60.88-.8,1.35-9.82-1.66-25.65-3.93-31.61ZM510.96,178.1c-7.4,0-13.4-5.93-13.4-13.24s6-13.24,13.4-13.24,13.4,5.93,13.4,13.24-6,13.24-13.4,13.24Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-3" data-name="Stripes">
					<path
						className={styles.cls11}
						d="M542.16,197.56c-6.07,1.74-12.22,2.78-18.26,3.08-9.73.48-19.3-1.03-27.47-4.35.46-1.28.85-3.02,1.02-3.88,7.77,3.24,16.92,4.81,26.26,4.35,10.28-.51,20.91-3.3,30.87-8.07.09.62.32,3.25.32,4.14-4.16,1.91-8.43,3.5-12.74,4.73Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M510.96,150.65c-7.92,0-14.37,6.38-14.37,14.21s6.45,14.21,14.37,14.21,14.37-6.38,14.37-14.21-6.45-14.21-14.37-14.21ZM510.96,177.13c-6.85,0-12.43-5.5-12.43-12.27s5.58-12.27,12.43-12.27,12.43,5.5,12.43,12.27-5.58,12.27-12.43,12.27Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M539.67,168.91c-4.62,0-9.45-4.82-11.1-6.98-.36-.48-.76-1.03-1.21-1.65-2.95-4.11-7.9-10.99-14.87-11.72l.2-1.93c7.84.82,13.1,8.14,16.25,12.52.43.6.82,1.14,1.17,1.61,2.14,2.82,8.12,8.11,11.91,5.47,1.1-.76,1.71-1.81,1.83-3.11.22-2.45-1.22-7.06-9.3-15.14l1.37-1.37c6.91,6.91,10.23,12.53,9.86,16.69-.17,1.87-1.08,3.44-2.65,4.53-1.1.77-2.28,1.09-3.47,1.09Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Vault_Run02_Mid">
					<path className={styles.cls10}
					      d="M545.81,225.34c-.52.65-1.36,1.54-2.52,2.71-2.71-.54-7.08-9.2-7.47-11.57,5.49,1.1,9.44,7.61,9.99,8.86Z"/>
					<path className={styles.cls8}
					      d="M537.7,217.65c12.89,12.04,13.19,38.3-1.06,44.84-14.2,6.52-22.18,1.76-29.89,2.7,7.37,12.37-9.94,19.1-12.79,13.87-5.07-9.32-7.31-27.27,2.77-24.72,9.34,2.37,20.81-3.64,11.24-13.21-13.62-13.62,21.43-31.25,29.73-23.49Z"/>
					<path className={styles.cls4}
					      d="M499.38,147.92c5.59,4.2,30.28,14.39,44.48,3.77,3.78-2.83,6.61-6.25,7.24-11.44,1.67-13.65-10-16.72-19.96-18.7-9.38-1.86-23.77-3.19-30.82,5.55-1.52,1.88-5.75,17.21-.94,20.83Z"/>
					<g>
						<path className={styles.cls9}
						      d="M544.98,53.28c4.35,2.82,16.56,18.87,12.67,23.18-6.09,6.77-37.16,9.23-40.16,9.73-3.76.63-7.81-.1-9.84-2.38-3.31-3.7-.53-11.18,2.12-15.1,10.26-15.2,21.46-24.34,35.2-15.44Z"/>
						<g>
							<path className={styles.cls8}
							      d="M548.69,142.53c-2.97-3.83-4.64-8.67-4.65-13.52-.01-5.01,1.65-10.14.19-14.92-2-6.52-8.95-9.98-15.34-12.37-9.69-3.62-19.68-6.45-29.83-8.46-2.59-.51-5.26-.96-7.91-1.07,3.65-4.12,7.98-7.71,12.71-10.86.66.23,1.36.38,2.02.5,4.28.73,8.63,1.08,12.78,2.39,8.69,2.73,15.73,9.33,23.23,14.32,3.52,2.34,7.67,4.31,11.76,3.55,4.07-.76,7.11-4.02,10.68-6.1,2.24-1.3,4.74-2.07,7.32-2.37,3.72,1.38,7.17,3.23,9.01,6,4.63,6.95,6.07,17.23,3.01,23.54-5.17,10.67-11.97,15.41-19.85,20.42-3.37,2.15-6.91,3.77-10.54,4.97-1.18-2.12-3.1-4.06-4.62-6.01Z"/>
							<path className={styles.cls5}
							      d="M495.35,140.58c-3.76-3.05-7.28-6.45-10.4-10.14-5.42-6.4-5.81-12.87-3.59-20.7,1.93-6.79,5.37-12.57,9.79-17.55,2.65.11,5.32.56,7.91,1.07,10.15,2.01,20.14,4.84,29.83,8.46,6.38,2.38,13.34,5.85,15.34,12.37,1.47,4.79-.2,9.92-.19,14.92.01,4.85,1.67,9.69,4.65,13.52,1.52,1.96,3.44,3.89,4.62,6.01-23.91,7.96-51.44-2.69-57.96-7.97Z"/>
							<path className={styles.cls5}
							      d="M553.67,102.08c-4.09.76-8.24-1.21-11.76-3.55-7.51-4.99-14.54-11.59-23.23-14.32-4.15-1.3-8.51-1.66-12.78-2.39-.66-.11-1.36-.27-2.02-.5.69-.46,1.38-.9,2.08-1.34,8.53-5.33,16.56-8.99,27.06-5.84,5.26,1.58,10.11,6.45,14.28,9.86,4.89,3.99,8.03,4.69,14.21,6.54,2.87.86,6.63,1.75,10.16,3.06-2.58.3-5.08,1.08-7.32,2.37-3.57,2.07-6.6,5.34-10.68,6.1Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls3}
								      d="M521.98,117.38c4.03,3.42,8.85,4.36,10.76,2.1,1.92-2.26.2-6.87-3.83-10.3-4.03-3.42-8.85-4.36-10.76-2.1-1.92,2.26-.2,6.87,3.83,10.3Z"/>
								<path className={styles.cls3}
								      d="M544.95,95.54c4.22,2.82,8.75,3.45,10.1,1.4,1.35-2.05-.97-5.99-5.2-8.81-4.22-2.82-8.75-3.45-10.1-1.4-1.35,2.05.97,5.99,5.2,8.81Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls3}
									      d="M561.34,109.2c.3-1.69,1.64-3.76,3.42-5.55h0s0,0,0,0c0,0,0,0,0,0h0c1.74-1.83,3.77-3.22,5.44-3.56,7.97-1.62,8.34,7.66,4.03,12.88h0s0,0,0,0c0,0,0,0,0,0h0c-5.09,4.45-14.32,4.28-12.88-3.77Z"/>
									<path className={styles.cls3}
									      d="M582.84,114.39c-.15-.03-.31-.02-.48.03-.57.18-.62.79-.82,1.26-.28.66-.67,1.33-1.12,1.87-1.47,1.75-3.17,2.38-5.25,2.73l-.06-.07s-.03.06-.04.09c-.03,0-.06.01-.09.02l.06.07c-.79,1.95-1.77,3.48-3.79,4.54-1.4.73-4.78,1.39-6.4.76-.57-.22-1.2.07-1.4.63-.2.57.1,1.21.67,1.43,2.51.96,6.6-.03,8.19-.86.62-.32,1.15-.67,1.63-1.06,1.3-1.05,2.11-2.3,2.72-3.59,1.39-.32,2.79-.84,4.09-1.88.48-.38.94-.83,1.38-1.36.64-.77,1.49-1.96,1.65-2.97.11-.67-.26-1.53-.93-1.65Z"/>
								</g>
								<path className={styles.cls3}
								      d="M576.45,122.36c-.23.21-.55.33-.89.29-.65-.06-1.15-.64-1.11-1.29,0-.07.12-3.38-2.55-8.62-.3-.59-.07-1.31.51-1.6.58-.29,1.3-.04,1.6.55,3.03,5.93,2.83,9.72,2.82,9.88-.02.32-.16.59-.38.79Z"/>
							</g>
							<path className={styles.cls8}
							      d="M574.39,101.94c.46,1.17-.85,2.78-2.92,3.59-2.07.81-4.12.51-4.58-.66-.46-1.17.85-2.78,2.92-3.59s4.12-.51,4.58.66Z"/>
							<path className={styles.cls8}
							      d="M547.86,88.94c-.23.76-1.53,1.04-2.92.63s-2.33-1.36-2.1-2.12c.23-.76,1.53-1.04,2.92-.63,1.39.41,2.33,1.36,2.1,2.12Z"/>
							<path className={styles.cls8}
							      d="M526.57,110.07c-.21,1.01-1.85,1.52-3.66,1.15-1.81-.37-3.11-1.49-2.9-2.5.21-1.01,1.85-1.52,3.66-1.15,1.81.37,3.11,1.49,2.9,2.5Z"/>
						</g>
					</g>
				</g>
				<g id="Stripe-3" data-name="Stripe">
					<path
						className={styles.cls11}
						d="M491.05,135.79c8.95-24.89,31.94-48.53,53.27-54.95,1.63-.49-7.95-9.06-13.5-8.03-14.32,2.67-47.88,34.49-47.43,54.12.07,2.92,6.82,11.2,7.66,8.86Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Vault_Run02_Upper">
					<path className={styles.cls9}
					      d="M457.32,130.33c2.7,4.87,18.61,19.39,23.22,15.78,7.23-5.66,11.17-37.99,11.82-41.08.82-3.88.25-8.2-1.99-10.58-3.64-3.87-11.46-1.75-15.6.61-16.08,9.14-25.97,19.9-17.45,35.28Z"/>
					<g>
						<path className={styles.cls1}
						      d="M475.8,215.63c-1.48,0-2.94-.71-3.83-2.04-1.43-2.11-.87-4.98,1.25-6.41L742.63,25.51c2.11-1.42,4.98-.87,6.41,1.25,1.43,2.11.87,4.98-1.25,6.41l-269.41,181.67c-.79.53-1.69.79-2.58.79Z"/>
						<rect className={styles.cls11} x="504.04" y="181.36" width="17.78" height="9.23"
						      transform="translate(-16.32 318.55) rotate(-33.99)"/>
						<rect className={styles.cls7} x="574.99" y="130.6" width="26.42" height="9.23"
						      transform="translate(24.91 351.94) rotate(-33.99)"/>
					</g>
					<path className={styles.cls8}
					      d="M506.8,159.93c-13.04,2.12-28.05,13.65-28.52,21.42-.48,7.96,10.67,28.14,19.59,23.42,10.81-5.72-.77-15.31.23-18.7,1.02-3.47,8.75-7.56,12.63-9.03,7.73-2.93,6.89-18.86-3.93-17.1Z"/>
				</g>
			</g>
			<g id="Puppy_Vault_Run01">
				<g id="Puppy_Vault_Run01_Lower">
					<path className={styles.cls6}
					      d="M51.27,287.8c0,3.98,24.36,7.2,54.41,7.2,30.05,0,54.41-3.22,54.41-7.2,0-3.98-24.36-7.2-54.41-7.2-30.05,0-54.41,3.22-54.41,7.2Z"/>
					<path className={styles.cls10}
					      d="M117.98,199.37c4.34-3.8,26.16-8.99,37.94-1.21,12.56,8.3,20.25,24.66,23.06,31.9,8.36-8.07,13.7-4.04,14.92-.11,1.34,4.31-13.02,19.81-19.96,19.02-4.67-9.78-29.29-25.51-47.08-25.51-20.32,0-15.84-18.02-8.88-24.1Z"/>
					<path className={styles.cls10}
					      d="M129.73,150.37c24.89,10.39,60.81-11.53,52.68-22.35-7.64-10.16-26.05,15.06-48.77,6.52-7.74-2.91-14.03,11.61-3.91,15.84Z"/>
					<g>
						<path className={styles.cls9}
						      d="M81.55,192.6c2.14.57,4.32,1.01,6.55,1.26,2.67.3,18.33-2.19,16.37,3.53-.3.86-.99,1.58-1.81,2.01-2.79,1.46-5.68,2.88-8.68,3.53-4.95,1.08-9.71,0-13.99-2.01-.38-1.22-.52-2.52-.36-3.79.21-1.65.89-3.23,1.93-4.53Z"/>
						<path className={styles.cls8}
						      d="M69.6,190.12c.3-.22.73-.4,1.29-.52,2.63-.58,5.67,1.5,8.02,2.24.87.28,1.75.53,2.64.76-1.04,1.3-1.72,2.88-1.93,4.53-.16,1.27-.02,2.57.36,3.79-1.9-.89-3.71-1.97-5.39-3.13-1.18-.81-7.62-5.78-4.99-7.67Z"/>
					</g>
					<path className={styles.cls8}
					      d="M118.99,224.71c-33.42,6.52-27.89-30.04-20.85-43.7,4.7-9.11-3.67-30.69,1.85-43.22,5.31-12.04,32.47-9.24,35.54-7.21,15.39,10.18,21.91,35.8,20.43,57.06-1.22,17.45-12.19,32.23-36.98,37.06Z"/>
					<path className={styles.cls9}
					      d="M92.79,199.94c.44-5.58,9.07,1.55,8.76,9.37-.33,8.34,1.73,15.05-.69,13.37-6.43-4.45-9.06-10.28-8.07-22.75Z"/>
				</g>
				<path
					id="Shirt-4"
					data-name="Shirt"
					className={styles.cls2}
					d="M152.38,154.33c-.55-1.44-1.45-3.48-2.56-5.72-2.69,0-6.23-1.56-11.77-8.95-11.48-15.29-29.72-15.68-36.84-8.55-7.34,7.34-1.73,33.68-1.73,41.53,0,6.12-2.45,10.71-4.05,14.1,15.69,9.56,39.82,8.8,60.88-.8,1.35-9.82-1.66-25.65-3.93-31.61ZM124.19,162.36c-7.4,0-13.4-5.93-13.4-13.24s6-13.24,13.4-13.24,13.4,5.93,13.4,13.24-6,13.24-13.4,13.24Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-4" data-name="Stripes">
					<path
						className={styles.cls11}
						d="M143.83,181.82c-6.07,1.74-12.22,2.78-18.26,3.08-9.73.48-19.3-1.03-27.47-4.35.46-1.28.85-3.02,1.02-3.88,7.77,3.24,16.92,4.81,26.26,4.35,10.28-.51,20.91-3.3,30.87-8.07.09.62.32,3.25.32,4.14-4.16,1.91-8.43,3.5-12.74,4.73Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M124.5,135.06c-7.92,0-14.37,6.38-14.37,14.21s6.45,14.21,14.37,14.21,14.37-6.38,14.37-14.21-6.45-14.21-14.37-14.21ZM124.5,161.55c-6.85,0-12.43-5.5-12.43-12.27s5.58-12.27,12.43-12.27,12.43,5.5,12.43,12.27-5.58,12.27-12.43,12.27Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M150.57,150.15c-4.62,0-9.6-4.84-11.24-7-.36-.48-.76-1.03-1.21-1.65-2.95-4.11-7.9-10.99-14.87-11.72l.2-1.93c7.84.82,13.1,8.14,16.25,12.52.43.6.82,1.14,1.17,1.61,1.71,2.25,5.21,6.07,8.74,6.2.35.59.85,1.75.96,1.98Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Vault_Run01_Mid">
					<path className={styles.cls8}
					      d="M118.75,224.42c-32.54,10.22-48.87-6.41-59.68-17.22-6.39,5.98-17.96,2.01-20.36-3.07-1.6-3.39,21.48-21.06,25.6-14.17,10.88,18.23,28.83,7.63,36.1,1.87,9.83-7.79,31.08,28.59,18.33,32.59Z"/>
					<path className={styles.cls4}
					      d="M100.79,132.62c5.59,4.2,30.28,14.39,44.48,3.77,3.78-2.83,6.61-6.25,7.24-11.44,1.67-13.65-10-16.72-19.96-18.7-9.38-1.86-23.77-3.19-30.82,5.55-1.52,1.88-5.75,17.21-.94,20.83Z"/>
					<g>
						<path className={styles.cls9}
						      d="M154.85,43.54c3.68,3.66,12.31,21.88,7.62,25.3-7.35,5.37-38.26,1.36-41.3,1.23-3.81-.16-7.62-1.71-9.14-4.36-2.48-4.3,1.79-11.05,5.19-14.34,13.18-12.76,26.02-19.39,37.63-7.84Z"/>
						<g>
							<path className={styles.cls8}
							      d="M151.46,128.05c-2.81-3.96-4.27-8.85-4.08-13.7.2-5,2.07-10.06.81-14.9-1.72-6.59-8.53-10.35-14.81-13-9.53-4.02-19.39-7.27-29.45-9.69-2.57-.62-5.22-1.18-7.86-1.4,3.82-3.97,8.3-7.37,13.16-10.32.65.25,1.34.44,2,.58,4.24.9,8.58,1.44,12.67,2.92,8.57,3.09,15.32,9.98,22.62,15.27,3.42,2.48,7.49,4.63,11.61,4.03,4.1-.59,7.27-3.72,10.92-5.65,2.29-1.2,4.83-1.87,7.41-2.07,3.66,1.54,7.03,3.53,8.75,6.37,4.33,7.14,5.35,17.47,2.03,23.65-5.61,10.45-12.6,14.9-20.68,19.58-3.46,2-7.06,3.48-10.73,4.53-1.09-2.17-2.93-4.18-4.36-6.2Z"/>
							<path className={styles.cls5}
							      d="M98.25,123.87c-3.63-3.2-7-6.75-9.97-10.56-5.15-6.62-5.27-13.1-2.72-20.83,2.21-6.7,5.88-12.33,10.51-17.13,2.64.22,5.29.78,7.86,1.4,10.06,2.43,19.92,5.67,29.45,9.69,6.28,2.65,13.09,6.4,14.81,13,1.27,4.84-.61,9.9-.81,14.9-.19,4.85,1.27,9.75,4.08,13.7,1.43,2.02,3.28,4.03,4.36,6.2-24.22,6.96-51.29-4.83-57.58-10.38Z"/>
							<path className={styles.cls5}
							      d="M158.12,87.84c-4.12.59-8.19-1.55-11.61-4.03-7.29-5.3-14.05-12.18-22.62-15.27-4.09-1.48-8.43-2.01-12.67-2.92-.65-.14-1.34-.33-2-.58.7-.43,1.42-.85,2.14-1.26,8.74-4.97,16.92-8.29,27.28-4.71,5.19,1.79,9.83,6.86,13.86,10.45,4.72,4.19,7.82,5.02,13.93,7.12,2.83.97,6.56,2.03,10.03,3.49-2.59.19-5.12.86-7.41,2.07-3.65,1.92-6.82,5.06-10.92,5.65Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls3}
								      d="M125.83,101.81c3.88,3.59,8.66,4.73,10.67,2.55,2.01-2.18.49-6.86-3.39-10.45-3.88-3.59-8.66-4.73-10.67-2.55-2.01,2.18-.49,6.86,3.39,10.45Z"/>
								<path className={styles.cls3}
								      d="M149.69,80.94c4.1,2.99,8.59,3.81,10.03,1.82,1.44-1.99-.72-6.02-4.82-9.02-4.1-2.99-8.59-3.81-10.03-1.82-1.44,1.99.72,6.02,4.82,9.02Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls3}
									      d="M165.49,95.27c.37-1.68,1.79-3.69,3.65-5.4h0s0,0,0,0c0,0,0,0,0,0h0c1.81-1.76,3.9-3.06,5.58-3.33,8.03-1.29,8.01,8,3.48,13.04h0s0,0,0,0c0,0,0,0,0,0h0c-5.27,4.23-14.49,3.68-12.72-4.31Z"/>
									<path className={styles.cls3}
									      d="M186.75,101.36c-.15-.03-.31-.03-.48.01-.58.15-.66.76-.87,1.23-.3.65-.72,1.3-1.2,1.82-1.54,1.69-3.27,2.25-5.36,2.51l-.05-.07s-.03.06-.04.09c-.03,0-.06,0-.09.01l.05.07c-.87,1.92-1.91,3.41-3.98,4.38-1.43.67-4.83,1.19-6.43.49-.56-.24-1.2.02-1.43.57-.23.56.04,1.21.61,1.46,2.47,1.07,6.6.25,8.22-.51.63-.29,1.18-.63,1.67-.99,1.35-.99,2.2-2.21,2.87-3.48,1.4-.26,2.82-.72,4.17-1.71.49-.36.97-.79,1.44-1.3.67-.74,1.57-1.9,1.78-2.9.14-.67-.19-1.54-.86-1.68Z"/>
								</g>
								<path className={styles.cls3}
								      d="M180.04,109.05c-.24.2-.56.3-.9.26-.65-.09-1.12-.68-1.06-1.34,0-.07.26-3.37-2.19-8.72-.28-.61-.02-1.31.57-1.58.59-.27,1.3.01,1.58.62,2.78,6.05,2.42,9.83,2.4,9.99-.03.32-.19.59-.41.77Z"/>
							</g>
							<path className={styles.cls8}
							      d="M178.84,88.57c.41,1.19-.96,2.75-3.06,3.47-2.1.72-4.14.34-4.54-.85-.41-1.19.96-2.75,3.06-3.47,2.1-.72,4.14-.34,4.54.85Z"/>
							<path className={styles.cls8}
							      d="M152.87,74.47c-.26.75-1.58.98-2.95.51s-2.27-1.46-2.01-2.21c.26-.75,1.58-.98,2.95-.51,1.37.47,2.27,1.46,2.01,2.21Z"/>
							<path className={styles.cls8}
							      d="M130.71,94.69c-.25,1-1.91,1.44-3.7,1-1.79-.45-3.05-1.62-2.8-2.62.25-1,1.91-1.44,3.7-1,1.79.45,3.05,1.62,2.8,2.62Z"/>
						</g>
					</g>
				</g>
				<g id="Stripe-4" data-name="Stripe">
					<path
						className={styles.cls11}
						d="M94.16,118.91c9.98-24.5,33.94-47.16,55.51-52.68,1.65-.42-7.57-9.39-13.15-8.59-14.42,2.07-49.27,32.47-49.65,52.09-.06,2.92,6.35,11.47,7.28,9.18Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Vault_Run01_Upper">
					<path className={styles.cls9}
					      d="M62.16,118.49c2.99,4.69,19.77,18.2,24.15,14.32,6.87-6.09,8.8-38.61,9.27-41.73.58-3.92-.26-8.2-2.64-10.43-3.87-3.64-11.54-1.04-15.53,1.57-15.48,10.11-24.69,21.47-15.25,36.29Z"/>
					<g>
						<path className={styles.cls1}
						      d="M87.34,197.08c-1.48,0-2.94-.71-3.83-2.04-1.43-2.11-.87-4.98,1.25-6.41L354.17,6.97c2.11-1.42,4.98-.87,6.41,1.25,1.43,2.11.87,4.98-1.25,6.41L89.92,196.29c-.79.53-1.69.79-2.58.79Z"/>
						<rect className={styles.cls11} x="115.58" y="162.81" width="17.78" height="9.23"
						      transform="translate(-72.34 98.2) rotate(-33.99)"/>
						<rect className={styles.cls7} x="186.53" y="112.06" width="26.42" height="9.23"
						      transform="translate(-31.1 131.6) rotate(-33.99)"/>
					</g>
					<path className={styles.cls8}
					      d="M118.34,141.38c-13.04,2.12-28.05,13.65-28.52,21.42-.48,7.96,10.67,28.14,19.59,23.42,10.81-5.72-.77-15.31.23-18.7,1.02-3.47,8.75-7.56,12.63-9.03,7.73-2.93,6.89-18.86-3.93-17.1Z"/>
				</g>
			</g>
		</svg>
	);
};