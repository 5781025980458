import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import { produce } from "immer";
import styles from "./UserPayments.scss";
import map from "lodash/map";
import { getPaymentOptions } from "api/StripeApi";
import * as UserActions from "actions/UserActions";
import AccessDenied from "components/common/AccessDenied";
import ClinicBase from "components/layout/ClinicBase";
import PaymentsHeader from 'components/user/widgets/PaymentsHeader';
import PaymentOption from 'components/user/widgets/PaymentOption';
import SpinnerTakeover from 'components/common/SpinnerTakeover';
import VetCheckStripe from "components/vetcheck/widgets/VetCheckStripe";
import {getClinicInfo} from "utils/ClinicData";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {CLINIC_TAGS} from "constants/AdminControls";
import * as UserPermissions from "constants/UserPermissions";

function UserPayments(props) {
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if(props.isLoggedIn && !props.userProfile) {
            props.loadCurrentUser();
        }
    });

    useEffect(() => {
        if(props.clinicId) {
            async function fetchCards() {
                await getCards();
            }

            fetchCards();
        }
    }, [props.clinicId]);

    const updateDefaultCard = (cardId) => {
        const update = produce(paymentOptions, (updatedOptions) => {
            return map(updatedOptions, opt => ({
                ...opt,
                isDefault: opt.id === cardId,
            }));
        });
        setPaymentOptions(update);
    }

    const getCards = async () => {
        setIsLoading(true);

        try {
            const res = await getPaymentOptions(props.clinicId);

            setPaymentOptions(res.body);
            setIsLoading(false);
        } catch (error) {
            handleErrorResponse("retrieving payment options", error);
            setIsLoading(false);
        }
    }

    const paymentElements = paymentOptions.map((c) => {
        return (
            <PaymentOption
                clinicId={props.clinicId}
                key={c.id}
                card={c}
                getCards={getCards}
                canEditPaymentSettings={props.canEditPaymentSettings}
            />
        );
    });

    return (
        <ClinicBase
            pageTitle="Payment Settings"
            docTitle="Payment Settings"
            adminControls={CLINIC_TAGS}
            clinicId={props.clinicId}
        >
            {!props.canViewPaymentSettings ? (
                <AccessDenied data-testid="AccessDenied"/>
            ) : (
                <>
                    <VetCheckStripe>
                        <PaymentsHeader
                            clinicId={props.clinicId}
                            cards={paymentOptions}
                            getCards={getCards}
                            setIsLoading={(isLoading) => setIsLoading(isLoading)}
                            onDefaultCardUpdated={(cardId) => updateDefaultCard(cardId)}
                            canEditPaymentSettings={props.canEditPaymentSettings}
                        />
                        <div className={styles.paymentOptions}>
                            { paymentElements }
                        </div>
                    </VetCheckStripe>
                    <SpinnerTakeover show={isLoading} />
                </>
            )}

        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        // Permissions
        const canViewPaymentSettings = userHasPermission(PermissionTypes.VIEW, UserPermissions.PAYMENT_SETTINGS, userProfile);
        const canEditPaymentSettings = userHasPermission(PermissionTypes.EDIT, UserPermissions.PAYMENT_SETTINGS, userProfile);
        return {
            userProfile,
            clinicId,
            isLoggedIn: state.user.isLoggedIn,
            // Permissions
            canViewPaymentSettings,
            canEditPaymentSettings,
        };
    },
    (dispatch) => ({
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
    }),
)(UserPayments);
