exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PuppyPodiumCenter__cls1{fill:#e3bf0e;stroke:#a88c00;stroke-miterlimit:10}.PuppyPodiumCenter__cls2{fill:#ffc200}.PuppyPodiumCenter__cls2,.PuppyPodiumCenter__cls3,.PuppyPodiumCenter__cls4,.PuppyPodiumCenter__cls5,.PuppyPodiumCenter__cls6,.PuppyPodiumCenter__cls7,.PuppyPodiumCenter__cls8,.PuppyPodiumCenter__cls9,.PuppyPodiumCenter__cls10,.PuppyPodiumCenter__cls11,.PuppyPodiumCenter__cls12,.PuppyPodiumCenter__cls13{stroke-width:0}.PuppyPodiumCenter__cls3{fill:#a88c00}.PuppyPodiumCenter__cls4{fill:#b66c00}.PuppyPodiumCenter__cls5{fill:#0b0808}.PuppyPodiumCenter__cls6{fill:#1b75bb}.PuppyPodiumCenter__cls7{fill:#9f0a2b}.PuppyPodiumCenter__cls8{fill:#4a8bbd}.PuppyPodiumCenter__cls9{fill:#fff}.PuppyPodiumCenter__cls10{fill:#cbc6c5}.PuppyPodiumCenter__cls11{fill:#231f20}.PuppyPodiumCenter__cls12{fill:#ba1a55}.PuppyPodiumCenter__cls13{fill:#04456f}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/GoldenSummerGames/PuppyPodiumCenter.scss"],"names":[],"mappings":"AAAA,yBAAM,aAAa,eAAe,oBAAoB,CAAC,yBAAM,YAAY,CAAC,gTAA4E,cAAc,CAAC,yBAAM,YAAY,CAAC,yBAAM,YAAY,CAAC,yBAAM,YAAY,CAAC,yBAAM,YAAY,CAAC,yBAAM,YAAY,CAAC,yBAAM,YAAY,CAAC,yBAAM,SAAS,CAAC,0BAAO,YAAY,CAAC,0BAAO,YAAY,CAAC,0BAAO,YAAY,CAAC,0BAAO,YAAY,CAAC","file":"PuppyPodiumCenter.scss","sourcesContent":[".cls1{fill:#e3bf0e;stroke:#a88c00;stroke-miterlimit:10}.cls2{fill:#ffc200}.cls2,.cls3,.cls4,.cls5,.cls6,.cls7,.cls8,.cls9,.cls10,.cls11,.cls12,.cls13{stroke-width:0}.cls3{fill:#a88c00}.cls4{fill:#b66c00}.cls5{fill:#0b0808}.cls6{fill:#1b75bb}.cls7{fill:#9f0a2b}.cls8{fill:#4a8bbd}.cls9{fill:#fff}.cls10{fill:#cbc6c5}.cls11{fill:#231f20}.cls12{fill:#ba1a55}.cls13{fill:#04456f}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cls1": "PuppyPodiumCenter__cls1",
	"cls2": "PuppyPodiumCenter__cls2",
	"cls3": "PuppyPodiumCenter__cls3",
	"cls4": "PuppyPodiumCenter__cls4",
	"cls5": "PuppyPodiumCenter__cls5",
	"cls6": "PuppyPodiumCenter__cls6",
	"cls7": "PuppyPodiumCenter__cls7",
	"cls8": "PuppyPodiumCenter__cls8",
	"cls9": "PuppyPodiumCenter__cls9",
	"cls10": "PuppyPodiumCenter__cls10",
	"cls11": "PuppyPodiumCenter__cls11",
	"cls12": "PuppyPodiumCenter__cls12",
	"cls13": "PuppyPodiumCenter__cls13"
};