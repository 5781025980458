import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import camelCase from "lodash/camelCase";
import forEach from "lodash/forEach";
import map from "lodash/map";
import split from "lodash/split";
import AccessDenied from "components/common/AccessDenied";

function VetCheckPlugIn(props) {
    if (window.addEventListener) {
      window.addEventListener("message", handleVetCheckMessage);
    } else {
      window.attachEvent("onmessage", handleVetCheckMessage);
    }

    function handleVetCheckMessage(event) {
        if(!!props.shareLinkOnly) {
            if (event.origin === "https://plugin.vetcheck.it") {
                let dataFromVetCheckIframe = event.data;
                const data = map(split(dataFromVetCheckIframe, "&"), d => {
                    const values = split(d, "=");
                    return {
                        id: camelCase(values[0]),
                        value: values[1]
                    }
                });

                let formattedData;
                forEach(data, d => {
                    formattedData = {
                        ...formattedData,
                        [d.id]: d.value
                    }
                })
                props.onSelect(formattedData);
            }
        }
    }

    if(!props.vetCheckAuthCode) {
        return (
            <AccessDenied customMessage={(
                <div>
                    <div>Clinic does not have access to the VetCheck plugin.</div>
                    <div>Please contact Greenline for assistance in resolving this issue.</div>
                </div>
            )}/>
        )
    }

    const shareLink = props.shareLinkOnly ? "&sharelinkonly=true" : "";

    return (
        <div className="flex-1 height-100-perc">
            <iframe
                src={`https://plugin.vetcheck.it/${props.url}?clinic_guid=${props.vetCheckAuthCode}${shareLink}`}
                className="vetcheck-box full-height full-width"
                frameBorder="0"
            />
        </div>
    );
}

VetCheckPlugIn.propTypes = {
    clinicId: PropTypes.number.isRequired,
    shareLinkOnly: PropTypes.bool,
    onSelect: PropTypes.func,
    url: PropTypes.string
};

VetCheckPlugIn.defaultProps = {
    clinicId: null,
    shareLinkOnly: false,
    url: "home.php"
};

export default connect(
    (state, ownProps) => ({
        vetCheckAuthCode: state.entities.clinicVetCheckStatus[ownProps.clinicId]?.authCode || null
    })
)(VetCheckPlugIn);
