import React from "react";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import TextBox from "components/common/TextBox";

export default function ClientContactFields(props) {
    const checkIncludes = (value) => {
        return includes(props.errors, `${props.namePrefix}${value}`);
    };

    return (
        <>
            <TextBox
                disabled={props.readOnly}
                hasError={checkIncludes("petOwnerEmail")}
                inputType="email"
                label="Email"
                name="petOwnerEmail"
                onChange={props.onChange}
                required
                value={props.initialData?.petOwnerEmail}
            />
            <TextBox
                disabled={props.readOnly}
                hasError={checkIncludes("phone")}
                label="Phone Number"
                name="phone"
                onChange={props.onChange}
                required
                value={props.initialData?.phone}
            />
        </>
    );
}

ClientContactFields.defaultProps = {
    errors: [],
    initialData: {},
    namePrefix: "",
    readOnly: false,
}

ClientContactFields.propTypes = {
    errors: PropTypes.array,
    initialData: PropTypes.object,
    namePrefix: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
}
