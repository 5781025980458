import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProviderBase from "components/layout/ProviderBase";
import ProviderPayoutReportTable from "../widgets/ProviderPayoutReportTable";
import * as ProviderApi from "api/ProviderApi";
import getProviderId from "utils/getProviderId";
import { getNodeId } from "utils/AdminData";
import { flatMap, filter, includes } from "lodash";
import {
  PermissionTypes,
  userHasPermission,
} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import Dropdown from "components/common/Dropdown";
import Button from "components/common/Button";
import TextBox from "components/common/TextBox";
import { NHO } from "constants/OrgNodeTypes";

function ProviderPayoutReport(props) {
  const [checkSummary, setCheckSummary] = useState(null);
  const [dropDownValue, setDropDownValue] = useState(1);
  const [checkNumber, setCheckNumber] = useState(null);
  const [downloadParameters, setDownloadParameters] = useState({});

  const dropDownOptions = [
    {
      name: "Boehringer Ingelheim",
      value: 1,
    },
    {
      name: "Merck Animal Health",
      value: 8,
    },
    {
      name: "Elanco",
      value: 6,
    },
  ];

  const checkNumberInputStyle = {
    marginTop: "0.5rem",
  };

  const loadPayoutReport =  (model) => {

    if(props.node?.nodeType === NHO) {
      ProviderApi.getNHOProviderCheckSummary(checkNumber)
      .then((res) => {
        setCheckSummary(res.body);
      })
      .catch((error) => {
        handleErrorResponse("loading payout report", error);
      })
    } else {
      ProviderApi.getProviderCheckSummary(model)
      .then((res) => {
        setCheckSummary(res.body);
      })
      .catch((error) => {
        handleErrorResponse("loading payout report", error);
      })
    };
  };

  const handleStatusFilterChanged = (option) => {
    setDropDownValue(option.value);
  };

  const handleCheckInput = (number) => {
    setCheckNumber(number.value);
  };

  const handleSubmit = () => {
    let search = ({
      manufacturerId: dropDownValue,
      checkNumber: checkNumber,
      corporateGroupProviderId: props.providerId,
    });
    loadPayoutReport(search);
  };

  useEffect(() => {
    if(props.node?.nodeType === NHO) {
      setDownloadParameters(checkNumber)
    } else {
      setDownloadParameters({
        "manufacturerId": dropDownValue,
        "checkNumber": checkNumber,
        "corporateGroupProviderId": props.providerId,
      })
    }
  }, [dropDownValue, checkNumber, props.providerId])

  return (
    <ProviderBase
      location={props.location}
      nodeId={props.nodeId}
      providerId={props.providerId}
      docTitle={"Payout Report"}
      pageTitle={"Reports"}
      collapsible
    >
      <div>
        <div className="flex flex-wrap">
          <div className="flex-none flex flex-centered spaced-content margin-sm">
            <Dropdown
              wide
              placeHolder={"Choose a Manufacturer"}
              value={dropDownValue}
              onChange={handleStatusFilterChanged}
              options={dropDownOptions}
              disabled={props.node?.nodeType === NHO}
            />
          </div>
          <div className="flex-none flex flex-centered spaced-content margin-sm">
            <TextBox
              name="clinicAlias"
              placeholder="Enter Check Number..."
              style={checkNumberInputStyle}
              value={checkNumber}
              onChange={(data) => handleCheckInput(data)}
              input={true}
            />
          </div>
          <div className="flex-none flex flex-centered spaced-content margin-sm">
            <Button
              className="margin-right-x-sm"
              onClick={() => handleSubmit()}
              disabled={!checkNumber}
            >
              Search
            </Button>
          </div>
        </div>

          <ProviderPayoutReportTable
            checkData={checkSummary}
            downloadParameters={downloadParameters}
            isNHO={props.node?.nodeType === NHO}
          ></ProviderPayoutReportTable>
        
      </div>
    </ProviderBase>
  );
}

export default connect(
  (state, ownProps) => {
    const params = ownProps?.match?.params || {};
    const userProfile = state.user.userProfile;
    const providerId = getProviderId(params, userProfile);
    const nodeId = getNodeId(state, ownProps);
    const node = state.entities.nodes?.[nodeId];
    const corporateGroupProviderIds = flatMap(filter(state.entities.providers, { isCorporateGroup: true }), "id");
    const isCorporateGroup = !!includes(corporateGroupProviderIds, node?.providerId);
    //Permissions
    const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);

    return {
      userProfile,
      providerId,
      nodeId,
      node,
      isCorporateGroup,
      isAdmin: !!(
        ownProps?.location?.pathname?.includes("/admin") &&
        providerId &&
        nodeId
      ),
      territoryId: params.territoryId,
      districtId: params.districtId,
      regionId: params.regionId,
      isLoggedIn: state.user.isLoggedIn,

      // Permissions
      canViewCorporateGroupClinic,
    };
  },
  (dispatch) => ({
    loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
  })
)(ProviderPayoutReport);
