export const CLINIC_ADMIN = "ClinicAdmin";
export const CLINIC_STAFF = "ClinicUser";
export const CLINIC_USER = "ClinicUser";
export const CLINIC_OWNER = "ClinicOwner";
export const CLINIC_GUEST = "ClinicGuest";
export const CLINIC_DEMO = "ClinicDemo";
export const SITE_ADMIN = "superadmin";
export const GL_ADMIN = "GLAdmin";
export const PROVIDER_ADMIN = "ProviderAdmin"; // HQ
export const PROVIDER_MANAGER = "ProviderManager"; // Corporate group manager
export const PROVIDER_RD = "ProviderRegionalDirector";
export const PROVIDER_DM = "ProviderDistrictManager";
export const PROVIDER_USER = "ProviderUser"; // Territory Manager (aka Sales Rep)
