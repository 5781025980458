exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ProductAnalysis__root .ProductAnalysis__otherTable{max-height:150px;overflow:hidden}.ProductAnalysis__root .ProductAnalysis__otherTable.ProductAnalysis__show{overflow:visible;max-height:none}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/admin/widgets/ProductAnalysis.scss"],"names":[],"mappings":"AAAA,oDAAkB,iBAAiB,eAAe,CAAC,0EAAuB,iBAAiB,eAAe,CAAC","file":"ProductAnalysis.scss","sourcesContent":[".root .otherTable{max-height:150px;overflow:hidden}.root .otherTable.show{overflow:visible;max-height:none}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "ProductAnalysis__root",
	"otherTable": "ProductAnalysis__otherTable",
	"show": "ProductAnalysis__show"
};