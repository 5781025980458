import React, { useEffect, useMemo, useState } from "react";
import styles from "./GetHelp.scss";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";

import Button from "components/common/Button";
import TextBox from "components/common/TextBox";
import Loader from "components/common/Loader";
import Modal from "components/common/Modal";

import { sendStrayCouponGetHelp } from "api/CouponsApi";
import { isEmailValid } from "utils/helperUtils";
import { handleErrorResponse } from "utils/request";
import FakeLiveChat from "components/clinic/elements/FakeLiveChat";

const DEFAULT_GET_HELP_DATA = {
    contactName: "",
    contactEmail: "",
    reason: "",
}

export default function GetHelp(props) {
    const [letsDoGetHelp, setLetsDoGetHelp] = useState(false);
    const [showLiveChatModal, setShowLiveChatModal] = useState(false);
    const [formBusy, setFormBusy] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formDataGetHelp, setFormDataGetHelp] = useState(DEFAULT_GET_HELP_DATA)
    const [formErrors, setFormErrors] = useState(null);
    const [isChatAvailable, setIsChatAvailable] = useState(false);

    useEffect(() => { 
        checkLiveChatOnline();
    })

    const allStrays = useMemo(() => {
        return {
            ...props.strayCoupons.unresolved,
            ...props.strayCoupons.ineligible,
            ...props.strayCoupons.resolved,
        }
    }, [props.strayCoupons]);

    const getSelectedInvoiceNumbers = () => {
        const invoiceNumbers = map(props.selectedRowIds, id => {
            const invoice = allStrays[id];
            return invoice?.invoiceNumber || null;
        });

        return invoiceNumbers.join(", ");
    }

    const checkFormForErrors = () => {
        const hasContact = !!formDataGetHelp.contactName;
        const hasEmail = !!formDataGetHelp.contactEmail && isEmailValid(formDataGetHelp.contactEmail);
        const hasReason = !!formDataGetHelp.reason;

        const isValid = hasContact && hasEmail && hasReason;
        const errors = isValid ? null : {
            contactEmail: !hasEmail,
            contactName: !hasContact,
            reason: !hasReason,
        };

        setFormErrors(errors);
        return !!errors;
    }

    const handleFormUpdate = ({ name, value }) => {
        setFormDataGetHelp({
            ...formDataGetHelp,
            [name]: value,
        });
    }

    const checkLiveChatOnline = () => {
        if(document.getElementById("islpronto_text")?.text === "Live Chat") {
            setIsChatAvailable(true);
        }
    }

    const handleGetHelpSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (!checkFormForErrors()) {
            setFormBusy(true);
            const dataToSend = {
                ...formDataGetHelp,
                clinicId: Number(props.clinicId),
                invoiceIds: props.selectedRowIds,
            }
            sendStrayCouponGetHelp(dataToSend)
                .then((res) => {
                    console.log("sendStrayCouponGetHelp response: ", res.statusCode);
                    handleGetHelpComplete();
                })
                .catch((error) => {
                    setFormBusy(false);
                    handleErrorResponse("submitting request", error);
                })
        }
    }

    const handleGetHelpComplete = () => {
        setFormSubmitted(true);
        // setSelectedRowIds([]);
        setFormDataGetHelp({
            ...formDataGetHelp,
            reason: "",
        });
        setFormBusy(false);
        props.onComplete();
    }

    const handleGetHelp = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if (!props.selectedRowIds.length) {
            alert("Please select invoices from the list below to request help from Greenline's Customer Experience Team");
        } else {
            setLetsDoGetHelp(true);
        }
    }

    const handleGetHelpClose = () => {
        if (formSubmitted) {
            setFormSubmitted(false);
            setLetsDoGetHelp(false);
        } else {

            setLetsDoGetHelp(false);
        }
    }

    const handleLiveChatModal = () => {
        setShowLiveChatModal(true)
    }

    const handleLiveChatModalClose = () => {
        setShowLiveChatModal(false);
    }

    return (
        <>
            <div className={classnames(styles.root, "flex spaced-content align-center margin-bottom-sm column-to-row-sm")}>
                <Button
                    onClick={isChatAvailable ? handleLiveChatModal : handleGetHelp}
                >
                    Contact Greenline
                </Button>
                <div className={"disclaimer"}>
                    <i className={"far fa-info-circle"} /> Greenline's Customer Experience Team will assist you in resolving outstanding Stray Coupons
                </div>
            </div>
            <Modal
                modalTitle="Contact Greenline"
                small
                show={letsDoGetHelp}
                onClose={handleGetHelpClose}
            >
                {formSubmitted ? (
                    <div className={"flex flex-column flex-centered padding-lg"}>
                        <h2 className="margin-top-sm text-center margin-bottom-md">Your request has been submitted</h2>
                        <Button
                            onClick={handleGetHelpClose}
                            large
                        >
                            Close
                        </Button>
                    </div>
                ): (
                    <form onSubmit={handleGetHelpSubmit} >
                        <TextBox
                            required
                            label="Coupon Contact Name"
                            name="contactName"
                            value={formDataGetHelp.contactName}
                            onChange={handleFormUpdate}
                            hasError={formErrors?.contactName}
                        />
                        <TextBox
                            required
                            label="Email"
                            inputType="email"
                            name="contactEmail"
                            value={formDataGetHelp.contactEmail}
                            onChange={handleFormUpdate}
                            hasError={formErrors?.contactEmail}
                        />
                        <TextBox
                            label="Invoice Numbers"
                            disabled
                            textArea
                            name="invoices"
                            value={getSelectedInvoiceNumbers()}
                            onChange={handleFormUpdate}
                        />
                        <TextBox
                            required
                            label="Reason"
                            textArea
                            placeholder="Enter a reason these rebates weren't accepted"
                            value={formDataGetHelp.reason}
                            name="reason"
                            onChange={handleFormUpdate}
                            hasError={formErrors?.reason}
                        />
                        <div className="padding-left-sm padding-right-sm padding-bottom-lg"><small>If you know the reason the rebate(s) were not accepted, enter it above, otherwise enter "I don't know"</small></div>
                        <div className="flex flex-centered">
                            <Button
                                buttonType="submit"
                                onClick={handleGetHelpSubmit}
                                large
                            >
                                Submit Request
                            </Button>
                        </div>
                        <div className={"margin-top-md margin-bottom-sm"}>*Greenline's Customer Experience Team will contact you within 48 business hours to evaluate your request.</div>
                    </form>
                )}
                {formBusy && (
                    <div className={styles.loaderScreen}>
                        <Loader text="Submitting Request" />
                    </div>
                )}
            </Modal>
            <Modal 
                modalTitle="Contact Greenline"
                small
                show={showLiveChatModal}
                onClose={handleLiveChatModalClose}
            >
            <h2 className="padding-left-sm padding-right-sm padding-bottom-lg">Please click the Live Chat button below to connect to a Greenline Specialist to resolve your stray coupons.</h2>
                <FakeLiveChat />
            </Modal>
        </>
    )
}

GetHelp.propTypes = {
    selectedRowIds: PropTypes.array,
    onComplete: PropTypes.func,
    strayCoupons: PropTypes.object,
    clinicId: PropTypes.number.isRequired,
}
