import React, { useState } from "react";
import PaymentsModal from "components/greenlineWellness/widgets/PaymentsModal";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {Form} from "informed";
import PropTypes from "prop-types";

export default function UpdatePaymentForm(props) {
    const [loading, setLoading] = useState(false);

    return (
        <>
            <Form>
                <div>Current Payment Method</div>
                <PaymentsModal
                    setIsLoading={setLoading}
                    canEditPaymentSettings
                    clinicId={props.clinicId}
                    planId={props.planId}
                    plans={props.plans}
                    selectedPlan={props.selectedPlan}
                />
            </Form>
            <SpinnerTakeover show={loading} onTop/>
        </>
    );
}

UpdatePaymentForm.propType = {
    clinicId: PropTypes.number.isRequired,
    planId: PropTypes.number.isRequired,
    plans: PropTypes.array.isRequired,
    selectedPlan: PropTypes.object.isRequired
}
