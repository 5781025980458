import React from "react";
import PropTypes from "prop-types";
import ProviderVoucherCode from "components/provider/widgets/ProviderVoucherCode";

export default function ProviderTitleBar(props) {
    return (
        <div className="full-width flex justify-between align-center column-to-row-sm">
            <h3>{props.title}</h3>
            {props.voucherCode &&
                <ProviderVoucherCode value={props.voucherCode} />
            }
        </div>
    );
}

ProviderTitleBar.propTypes = {
    title: PropTypes.string,
    voucherCode: PropTypes.string,
};
