import React from "react";
import PropTypes from "prop-types";
import styles from "./ProviderClinicLookupModal.scss";
import Modal from "components/common/Modal";
import * as ModalTypes from "constants/ModalTypes"
import ProviderClinicLookup from "components/admin/elements/ProviderClinicLookup";
import AccessDenied from "components/common/AccessDenied";

export default function ProviderClinicLookupModal(props) {

    const handleRowClick = (data) => {
        if(props.canEditClinicProgramSetup) {
            // Pass it back
            if (props.onRowClick) {
                props.onRowClick(data);
            }
        }
    }

    return (
        <Modal
            show
            modalTitle={`${props.providerName} Clinic Search`}
            onClose={props.onClose}
            modalType={ModalTypes.OVERLAY}
        >
            {props.canEditClinicProgramSetup ? (
                <div className={styles.root}>
                    <ProviderClinicLookup
                        onRowClick={handleRowClick}
                        clinicId={props.clinicId}
                        providerId={props.providerId}
                    />
                </div>
            ) : (
                <AccessDenied/>
            )}
        </Modal>
    );
}

ProviderClinicLookupModal.propTypes = {
    onRowClick: PropTypes.func,
    providerId: PropTypes.number.isRequired,
    providerName: PropTypes.string.isRequired,
    clinicId: PropTypes.number.isRequired,
    onClose: PropTypes.func,
    canEditClinicProgramSetup: PropTypes.bool,
}
