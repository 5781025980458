import React from "react";
import { connect } from "react-redux";
import ClinicBase from "components/layout/ClinicBase";
import VetCheckPlugIn from "components/vetcheck/widgets/VetCheckPlugIn";
import { getClinicInfo } from "utils/ClinicData";
import styles from "./VetCheckSendHistory.scss";
import VetCheckHeader from "components/vetcheck/widgets/VetCheckHeader";

function VetCheckSendHistory(props) {

    return (
        <ClinicBase
            clinicId={props.clinicId}
            docTitle={"VetCheck - Send History"}
        >
            <div className={styles.root}>
                <div className="margin-bottom-sm">
                    <VetCheckHeader
                        clinicId={props.clinicId}
                        location={props.location}
                    />
                </div>
                <VetCheckPlugIn
                    clinicId={props.clinicId}
                    url="history.php"
                />
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        return {
            clinicId,
            clinicInfo: clinic,
        }
    }
)(VetCheckSendHistory);
