import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import SpinnerTakeover from "../common/SpinnerTakeover";

export default function DynamicRoute(props) {
    const [Component, setComponent] = useState(null);

    useEffect(() => {
        setComponent(React.lazy(props.loadComponent));
    }, []);

    return(
        <React.Suspense fallback={<SpinnerTakeover show />} >
            {Component && <Component data-testid="dynamic_route_component" {...props} />}
        </React.Suspense>
    );
}

DynamicRoute.propTypes = {
    loadComponent: PropTypes.func.isRequired,
};