import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useHistory, withRouter } from "react-router";
import classnames from "classnames";
import * as styles from "./AdminProviderSelection.scss";
import filter from "lodash/filter";
import includes from "lodash/includes";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import mergeWith from "lodash/mergeWith";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import * as AdminActions from "actions/AdminActions";
import AccessDenied from "components/common/AccessDenied";
import * as CouponActions from "actions/CouponActions";
import * as ProviderActions from "actions/ProviderActions";
import AdminBase from "components/layout/AdminBase";
import Button from "components/common/Button";
import Image from "components/common/Image";
import Modal from "components/common/Modal";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import ToggleSwitch from "components/common/ToggleSwitch";
import {getProvidersWithDashboardInfo} from "utils/AdminData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function AdminProviderSelection(props) {
    const history = useHistory();
    const [selectedProviderId, setSelectedProviderId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const selectedHierarchies = !!selectedProviderId ? props.hierarchies?.[selectedProviderId] : null;
    const topLevelNodes = filter(selectedHierarchies, h => !h.parentNodeId);
    const combinedData = {...props.corporateGroupProviders};

    const custom = (objValue, srcValue) => {
      if (_.isArray(objValue)) {
          return srcValue;
      }
    };
    mergeWith(combinedData, formData, custom);

    useEffect(() => {
        props.getProviderList();
    }, []);

    useEffect(() => {
        if(selectedProviderId && selectedHierarchies) {
            if (topLevelNodes?.length) {
                if (topLevelNodes?.length === 1) {
                    handleGoTo(selectedProviderId, topLevelNodes[0]);
                } else {
                    if (loading) {
                        setLoading(false);
                    }
                }
            } else {
                setLoading(false);
            }
        }
    }, [topLevelNodes]);

    const handleClick = (id) => {
        props.setFromUserManagement();
        setSelectedProviderId(id);
        const hierarchy = props.hierarchies ? props.hierarchies[id] : null;
        if(!hierarchy) {
            setLoading(true);
            props.getProviderHierarchies(id);
        }
    }

    const handleGoTo = (providerId, node) => {
        history.push(`/admin/provider/${providerId}/location/${node?.nodeId}`);
    }

    const renderProviderTiles = (providers, small=false) => {
        return map(orderBy(providers, "displayOrder"), provider => {
            const loaded = props.hierarchies ? !!props.hierarchies?.[provider.id] : false;
            const disabled = (loaded && !Object.values(props.hierarchies?.[provider.id]).length);

            return (
                <div
                    key={provider.id}
                    onClick={() => disabled ? {} : handleClick(provider.id)}
                    className={classnames(styles.tile, {
                        [styles.disabled]: disabled,
                        [styles.small]: small,
                    })}
                    title={disabled ? "There is no node for this organization yet" : ""}
                >
                    <div className={classnames(styles.tileInner, "full-height", {
                         [styles.disabled]: disabled
                    })}>
                        <div className="flex align-center justify-flex-center">
                            <Image
                                src={provider.mediumImageUri}
                                title={provider.name}
                                alt={provider.name}
                                fallbackIcon={<i className="far fa-8x fa-city"/>}
                            />
                        </div>
                        {props.canViewUserManagement && (
                            <div className={classnames(styles.icons, "flex flex-none")}>
                                <div className="flex-1">
                                    <Button type="primary" text onClick={(e) => handleGoToUsers(e, provider)}>
                                        <i className="fa fa-users"/>
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    }

    const handleSelectManufacturers = (corporateGroup, manufacturerId) => {
        let manufacturerParticipationList;
        let isVisible;
        if(includes(corporateGroup.manufacturerParticipationList, manufacturerId)) {
            manufacturerParticipationList = reject(corporateGroup.manufacturerParticipationList, m => {return m === manufacturerId});
            isVisible = false;
        } else {
            manufacturerParticipationList = [...corporateGroup.manufacturerParticipationList]
            manufacturerParticipationList.push(manufacturerId);
            isVisible = true;
        }
        setFormData({
            ...formData,
            [corporateGroup.id]: {
                manufacturerParticipationList
            }
        });
        props.updateCorporateGroupVisibility({
            corporateGroupId: corporateGroup.id,
            providerId: manufacturerId,
            isVisible
        })
    };

    const MANUFACTURER_COLUMNS = map(props.visibleInCorporateGroup, (p, indx) => {
        return {
            name: (
                <div className="text-center">
                    <Image
                        src={p.smallImageUri}
                        title={p.name}
                    />
                </div>
            ),
            selector: "id",
            key: `id_${indx}`,
            sortable: true,
            sortValue: row => row.manufacturerParticipationList,
            format: (row) => {
                const participating = !!includes(row.manufacturerParticipationList, p.id);
                return (
                    <div
                        className="flex flex-centered"
                        title={`${row.name} is ${participating ? "" :  "not "}participating in ${p.name}`}
                    >
                        <ToggleSwitch
                            key={row.manufacturerParticipationList}
                            id="manufacturerParticipationList"
                            updateState={() => handleSelectManufacturers(row, p.id)}
                            force={participating}
                            labels={[]}
                        />
                    </div>
                );
            }
        }
    });

    const handleGoToUsers = (e, row) => {
        e.stopPropagation();
        e.preventDefault();
        history.push(`users?providerId=${row.id}`)
    };

    const COLUMNS = [{
        name: "Name",
        selector: "name",
        key: "name",
        sortable: true,

    },
        ...MANUFACTURER_COLUMNS,
    {
        name: "",
        selector: "icons",
        key: "icons",
        format: row => {
            return (
                <div className="flex spaced-content">
                    <div className="flex">
                        <Button type="primary" text onClick={(e) => handleGoToUsers(e, row)}>
                            <i className="fa fa-users"/>
                        </Button>
                    </div>
                </div>
            );
        }
    }];

    return (
        <AdminBase pageTitle="Providers">
            <div className={styles.root}>
                {props.canViewProviderInfo ? (
                    <>
                        {!props.providers ? (
                            <SpinnerTakeover show />
                        ) : (
                            <div className="margin-bottom-lg">
                                <div className="text-lg margin-bottom-sm">Manufacturers</div>
                                <div className={styles.manufacturers}>
                                    {renderProviderTiles(props.couponProviders)}
                                </div>
                                <div className={styles.manufacturers}>
                                    {renderProviderTiles(props.otherProviders, true)}
                                </div>
                                <div className="margin-top-sm text-lg margin-bottom-sm">Corporate Groups</div>
                                <SortableDataTable
                                    columns={COLUMNS}
                                    rawData={orderBy(combinedData, "name")}
                                    noPagination
                                    striped
                                    green={false}
                                    onClick={(row) => handleClick(row.id)}
                                />
                                <SpinnerTakeover show={loading} />
                            </div>
                        )}
                        <Modal show={!!(selectedProviderId && topLevelNodes?.length)}
                               modalTitle="Select a Hierarchy"
                               onClose={() => setSelectedProviderId(null)}
                               small
                        >
                            <div className="flex flex-wrap flex-centered">
                                {map(topLevelNodes, node => (
                                    <div key={node.nodeId}
                                         className={styles.subTile}
                                         onClick={() => handleGoTo(selectedProviderId, node)}
                                    >
                                        {node.nodeType}
                                    </div>
                                ))}
                            </div>
                        </Modal>
                    </>
                ) : (
                    <AccessDenied/>
                )}
            </div>
        </AdminBase>
    );
}

const connector = connect(
    (state) => {
        const userProfile = state.user?.userProfile;
        const canViewProviderInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_INFO, userProfile);
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);
        return {
            canViewProviderInfo,
            canViewUserManagement,
            providers: getProvidersWithDashboardInfo(state),
            couponProviders: filter(state.entities.providers, {isCouponProvider: true, isCorporateGroup: false}),
            visibleInCorporateGroup: filter(state.entities.providers, "visibleInCorporateDashboard"),
            otherProviders: filter(state.entities.providers, {isWellnessProvider: true, isCouponProvider: false, isCorporateGroup: false, isReportingProvider: false}),
            corporateGroupProviders: keyBy(filter(state.entities.providers, p => {
                return (p.isCorporateGroup && !p.isReportingProvider);
            }), "id"),
            hierarchies: state.entities.providerHierarchies,
        }
    },
    (dispatch) => ({
        setFromUserManagement: () => dispatch(AdminActions.setFromUserManagement(false)),
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        getProviderHierarchies: (providerId) => dispatch(ProviderActions.getProviderHierarchies(providerId)),
        updateCorporateGroupVisibility: (visibility) => dispatch(ProviderActions.updateCorporateGroupVisibility(visibility)),
    })
);

export default compose(
    withRouter,
    connector
)(AdminProviderSelection);
