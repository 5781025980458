import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./AdminBase.scss";
import AdminSidebar from "components/layout/AdminSidebar";
import AdminHeader from "components/layout/AdminHeader";
import * as UserActions from "actions/UserActions";
import Toolbar from "components/common/Toolbar/Toolbar";
import { setPageTitle } from "utils/windowUtils";

/*
This should be the root container for all Admin Pages. It should handle any init() functions or other startup triggers
so that they can be managed in one place.
 */

function AdminBase(props) {

    const fetchData = () => {
        if (!props.userProfile) {
            props.loadCurrentUser();
        }
    }

    useEffect(() => {
        if(props.pageTitle || props.docTitle) {
            const title = props.docTitle || props.pageTitle;
            setPageTitle(title);
        }
        fetchData();
    }, []);

    return (
        <div className={styles.root} style={props.style}>
            <AdminHeader
                pageTitle={props.pageTitle}
                collapsed={props.collapsible}
            />
            <main className={styles.main}>
                <div className="flex flex-1">
                    {!props.hideSidebar && (
                        <AdminSidebar collapsible={!!props.collapsible} />
                    )}
                    <div className={classnames(styles.content, {
                        [styles.overflowHidden]: props.overflowHidden
                    })}>
                        <div className={styles.children}>
                            {props.children}
                        </div>
                    </div>
                </div>
                {(props.searchConfig || props.controls) && (
                    <div className="flex-none">
                        <Toolbar
                            clinicId={props.clinicId}
                            searchConfig={props.searchConfig}
                            controls={props.controls}
                            searchDisabled={props.searchDisabled}
                            isAdmin
                        />
                    </div>
                )}
            </main>
        </div>
    )
}

AdminBase.propTypes = {
    collapsible: PropTypes.bool,
    searchConfig: PropTypes.object,
    searchDisabled: PropTypes.bool,
    pageTitle: PropTypes.any,
    docTitle: PropTypes.string,
    hideSidebar: PropTypes.bool,
    clinicId: PropTypes.number,
    controls: PropTypes.string,
    style: PropTypes.object,
    overflowHidden:PropTypes.bool
};

export default connect(
    (state) => ({
        userProfile: state.user.userProfile,
    }),
    (dispatch) => ({
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
    }),
)(AdminBase);
