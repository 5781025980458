exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".BuildVersion__root{position:fixed;z-index:9999;top:0;left:0;background-color:#000000;padding:1px 3px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.BuildVersion__root a{display:block;color:#FFFFFF;font-size:11px;font-weight:500;margin-left:0.5em}.BuildVersion__root a:hover{color:#FFFFFF;text-decoration:underline}.BuildVersion__root .BuildVersion__error{display:block;color:#FF0000;font-size:11px;font-weight:500;margin-left:0.5em}.BuildVersion__root i{color:#FFFFFF;cursor:pointer}.BuildVersion__root i:hover{color:#CC0000}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/layout/elements/BuildVersion/BuildVersion.scss"],"names":[],"mappings":"AAAA,oBAAM,eAAe,aAAa,MAAM,OAAO,yBAAyB,gBAAgB,oBAAoB,aAAa,uBAAuB,mBAAmB,sBAAsB,kBAAkB,CAAC,sBAAQ,cAAc,cAAc,eAAe,gBAAgB,iBAAiB,CAAC,4BAAc,cAAc,yBAAyB,CAAC,yCAAa,cAAc,cAAc,eAAe,gBAAgB,iBAAiB,CAAC,sBAAQ,cAAc,cAAc,CAAC,4BAAc,aAAa,CAAC","file":"BuildVersion.scss","sourcesContent":[".root{position:fixed;z-index:9999;top:0;left:0;background-color:#000000;padding:1px 3px;display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}.root a{display:block;color:#FFFFFF;font-size:11px;font-weight:500;margin-left:0.5em}.root a:hover{color:#FFFFFF;text-decoration:underline}.root .error{display:block;color:#FF0000;font-size:11px;font-weight:500;margin-left:0.5em}.root i{color:#FFFFFF;cursor:pointer}.root i:hover{color:#CC0000}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "BuildVersion__root",
	"error": "BuildVersion__error"
};