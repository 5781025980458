import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { InfoWindow } from "@react-google-maps/api";
import PropTypes from "prop-types";
import classnames from "classnames";
import filter from "lodash/filter";
import find from "lodash/find";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import styles from "./ProviderProductTagging.scss";
import * as ProviderActions from "actions/ProviderActions";
import ClinicMarkerInfoWindow from "components/provider/widgets/ClinicMarkerInfoWindow";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import { getUnreadNotificationCount } from "utils/ClinicData";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import * as ProviderLinks from "utils/ProviderLinks";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { PENDING } from "constants/Notifications";
import { CLINIC, DISTRICT, HQ, REGION, TERRITORY } from "constants/OrgNodeTypes";
import { PREMIER_PET_CARE_PLAN } from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";
import * as UserRoles from "constants/UserRoles";

function HeadquartersProductTagging(props) {
    const history = useHistory();
    const [showAllColumns, setShowAllColumns] = useState(false);
    const [clinicDetailsId, setClinicDetailsId] = useState(null);
    const [infoWindowMarkerId, setInfoWindowMarkerId] = useState(null);
    const [selectedNotificationId, setSelectedNotificationId] = useState(null);
    const [hovering, setHovering] = useState(null);

    const WELLNESS_TILES = [
        {
            id: "activeClinics",
            title: "Total Active Clinics",
            value: get(props.node, "stats.clinics.goGreen", 0),
            link: "clinics",
        }
    ]


    const TILES = [
        ...WELLNESS_TILES,
        {
            id: "totalPendingCoupons",
            title: "Total Pending Coupons",
            value: get(props.node, "stats.coupons.pending", 0),
        }, {
            id: "couponsOnly",
            title: "Coupons Only",
            value: get(props.node, "stats.clinics.couponsOnly", 0) || get(props.node, "stats.clinics.goGreen", 0),
        }, {
            id: "generatedMtd",
            title: "Generated MTD",
            value: get(props.node, "stats.coupons.mtd.created", 0),
        }, {
            id: "generatedYtd",
            title: "Generated YTD",
            value: get(props.node, "stats.coupons.ytd.created", 0),
        },
    ];

    useEffect(() => {
        if(!!props.nodeOverview) {
            //Load the clinics if needed
            if (props.useClinics) {
                if (!props.clinics) {
                    props.getNodeClinics(props.nodeId);
                }
            } else {
                //Only load the child nodeIds if there are child nodes
                if (!!props.nodeOverview?.childNodeIds?.length) {
                    if (!props.nodeChildren) {
                        props.getChildNodes(props.nodeId);
                    }
                }
            }
        }
    }, [props.nodeOverview]);

    useEffect(() => {
        if(props.canViewNotifications && !props.isAdmin) {
            props.getProviderDashboard();
        }
    }, [props.providerId]);

    useEffect(() => {
        const alerts = filter(props.notifications, n => (n.isAlert && !n.dismissed && n.enrollmentState === PENDING));
        if(!!alerts.length) {
            setSelectedNotificationId(alerts[0].notificationId);
        }
    }, [props.notifications]);

    const getMapMarkerDataSource = () => {
        if(props.useClinics) {
            return props.clinics;
        }
        return props.nodeChildren;
    }

    const getPageTitle = () => {
        if (!props.node) {
            return "";
        }

        switch (props.node?.nodeType) {
            case HQ:
            case REGION:
            case DISTRICT:
            case TERRITORY:
                return props.node?.name;
            default:
                return `${props.userProfile ? props.userProfile.name : ""} | ${upperFirst(props.node?.nodeType)}`
        }
    };

    const goToNode = (nodeId) => {
        let link;
        if (nodeId === props.userProfile?.nodeId) {
            // Send user to "/" instead of long url if it is their own node
            link = ProviderLinks.getLinkLocation("dashboard", {
                providerId: props.providerId,
                isAdmin: props.isAdmin
            });
        } else {
            link = ProviderLinks.getLinkLocation("dashboard", {
                providerId: props.providerId,
                nodeId,
                isAdmin: props.isAdmin
            });
        }
        history.push(link);
    };

    const goToClinic = (clinicId) => {
        // if (props.canDrillDownClinics) {
        if (props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("dashboard", clinicId));
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("dashboard", props.nodeId, clinicId));
        }
        // }
    };

    const handleRedemptionsIconClicked = (row) => {
        setClinicDetailsId(row.clinicId); // Shows clinic redemption summary popup
        if (props.childNodeType === CLINIC) {
            if (props.canViewWellnessSettings) {
                if (props.isAdmin) {
                    props.history.push(ClinicAdminLinks.getLinkLocation("programsDashboard", row.clinicId));
                } else {
                    props.history.push(ClinicProviderLinks.getLinkLocation("settings", props.nodeId, row.clinicId));
                }
            } else if (props.canViewRedemptionSummary) {
                handleRedemptionsIconClicked(row)
            }
        } else {
            goToNode(row.nodeId);
        }
    }
    const handleTableRowClick = row => {
        if(!!row.nodeId) {
            goToNode(row.nodeId);
        } else if(!!row.clinicId){
            if(props.canViewClinicInfo || props.canViewCorporateGroupClinic) {
                goToClinic(row.clinicId);
            }
        }
    };

    const handleHoverStart = (row) => {
        // For now, don't show hover for clinics
        if(!row.clinicId) {
            setHovering(row.id);
        }
    }

    const handleHoverEnd = () => {
        setHovering(null);
    }

    const handleMarkerClick = marker => {
        if(props.useClinics) {
            setInfoWindowMarkerId(marker.clinicId);
        } else {
            goToNode(marker.nodeId);
        }
    };

    const handleGoUp = () => {
        goToNode(props.parentNode?.nodeId)
    };

    // This is what's rendered (if anything) when you click a marker
    const renderMarkerChildren = marker => {
        if (props.useClinics && marker.clinicId === infoWindowMarkerId) {
            const clinic = find(props.clinics, clinic => clinic.clinicId === marker.clinicId) || null;
            return (
                <InfoWindow>
                    <ClinicMarkerInfoWindow
                        providerId={props.providerId}
                        nodeId={props.nodeId}
                        showCouponLibraryLink={props.columns.showCouponLibraryLink || false}
                        showAccountNumber={props.columns.showAccountNumber || false}
                        clinic={clinic}
                        onRedemptionsClick={() => setClinicDetailsId(marker.clinicId)} // Show clinic redemptions popup
                        isAdmin={props.isAdmin}
                    />
                </InfoWindow>
            );
        }

        return null;
    };

    const handleNotificationClicked = (id) => {
        setSelectedNotificationId(id);
    }

    const handleNotificationModalClose = () => {
        setSelectedNotificationId(null);
    }

    const pageTitle = getPageTitle(props.node);
    const mapMarkers = getMapMarkerDataSource();
    const selectedNotification = selectedNotificationId ? find(props.notifications, {notificationId: selectedNotificationId}) : null;
    const showGamesColumns = !!filter(props.nodeHierarchy[props.providerId], {hasGames: true, parentNodeId: props.nodeId})?.length; // Shouw the games columns if one of the child nodes has games

    return (
        <>
            <div className={classnames("flex-1 flex flex-column", styles.root, styles.corporateGroup)} style={{minWidth: "500px"}}>
                <h3>Nothing to See Here</h3>
            </div>
        </>
    );
}

HeadquartersProductTagging.propTypes = {
    location: PropTypes.object.isRequired,
    userRole: PropTypes.oneOf([UserRoles.PROVIDER_ADMIN, UserRoles.PROVIDER_MANAGER, UserRoles.PROVIDER_RD, UserRoles.PROVIDER_DM, UserRoles.PROVIDER_USER]),
    providerId: PropTypes.number,
    nodeId: PropTypes.number
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const node = state.entities.nodes[ownProps.nodeId] || null;
        const nodeOverview = state.entities.providerHierarchies?.[ownProps.providerId]?.[ownProps.nodeId];
        const useClinics = !!nodeOverview?.hasClinics;
        const parentNode = !!nodeOverview?.parentNodeId ? state.entities.providerHierarchies?.[ownProps.providerId]?.[nodeOverview?.parentNodeId] : {};
        const childNodeType = !!nodeOverview?.childNodeIds?.length ? state.entities.providerHierarchies?.[ownProps.providerId]?.[nodeOverview?.childNodeIds[0]]?.nodeType : null;

        // Check User for Wellness Permissions
        const canViewProviderInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_INFO, userProfile);
        const canViewCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canDrillDownClinics = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINICS_DRILLDOWN, userProfile);
        const canViewRedemptionSummary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_REDEMPTION_SUMMARY, userProfile);
        const wellnessOnly = (node?.providerId === PREMIER_PET_CARE_PLAN);
        const canViewClinicInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_INFO, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canViewNotifications = userHasPermission(PermissionTypes.VIEW, UserPermissions.NOTIFICATIONS, userProfile);
        const canAcceptNotifications = userHasPermission(PermissionTypes.ACCEPT, UserPermissions.NOTIFICATIONS, userProfile);
        const canDeclineNotifications = userHasPermission(PermissionTypes.DECLINE, UserPermissions.NOTIFICATIONS, userProfile);
        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewClinicWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canViewWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canEditCouponReprocessing = userHasPermission(PermissionTypes.EDIT, UserPermissions.COUPON_REPROCESSING, userProfile);
        // If User has one of the Wellness Permissions then give them the settings permission
        const canViewWellnessSettings =  canViewClinicWellnessAudit || canViewClinicWellnessPlans || canViewClinicWellnessPetPlans || canViewWellnessDvms || canEditCouponReprocessing;
        return {
            userProfile,
            wellnessOnly,
            canViewClinicInfo,
            canViewCorporateGroupClinic,
            nodeOverview,
            childNodeType,
            useClinics,
            node,
            parentNode,
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && ownProps.providerId && ownProps.nodeId),
            nodeChildren: state.entities.nodeChildren[ownProps.nodeId] || null,
            nodeHierarchy: state.entities.providerHierarchies,
            clinics: state.entities.nodeClinics[ownProps.nodeId] || null,
            columns: state.entities.nodeClinicColumns[ownProps.nodeId] || {},
            notifications: state.entities.notifications,
            notificationTotal: getUnreadNotificationCount(state),
            loading: state.entities?.nodes.loading || state.entities.nodeChildren?.loading,
            //    Permissions
            canViewNotifications,
            canAcceptNotifications,
            canDeclineNotifications,
            canViewProviderInfo,
            canViewCouponLibrary,
            canDrillDownClinics,
            canViewRedemptionSummary,
            canViewWellnessSettings,
        };
    },

    (dispatch) => ({
        getProviderDashboard: () => dispatch(ProviderActions.getProviderDashboard()),
        getChildNodes: (nodeId) => dispatch(ProviderActions.getChildNodes(nodeId)),
        getNodeClinics: (nodeId) => dispatch(ProviderActions.getNodeClinics(nodeId)),
    }),
);

export default compose(
    withRouter,
    connector
)(HeadquartersProductTagging);
