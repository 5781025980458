import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import filter from "lodash/filter";
import isString from "lodash/isString";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import styles from "./GenericAnimation.scss";
import Image from "components/common/Image";

export default function GenericAnimation(props) {
	const [currentFrame, setCurrentFrame] = useState(0);

	const frameSpeeds = useMemo(() => {
		const customFrameSpeeds = sumBy(props.frames, f => f?.duration || 0);
		const framesWithoutDuration = filter(props.frames, f => !f?.duration)?.length;
		if (customFrameSpeeds > props.duration) {
			console.error("The total of the frame durations are longer than the whole animation", customFrameSpeeds, props.duration);
		}
		const defaultFrameSpeed = (props.duration - customFrameSpeeds) / framesWithoutDuration;
		return map(props.frames, frame => {
			if (frame.duration < props.duration) {
				return frame.duration;
			} else if (frame.duration > props.duration) {
				console.error("frame Duration is longer than the whole animation", frame.duration, props.duration);
			}
			return defaultFrameSpeed;
		});
	}, [props.frames]);



    const playAnimationCycle = (durationCounter=0, animationFrame=0) => {
        setTimeout(() => {
			props.onBeforeAnimationFrame(animationFrame);
			setCurrentFrame(animationFrame);
            if (props.isAnimated && (!props.animationDuration || durationCounter < props.animationDuration)) {
                const nextAnimationFrame = (animationFrame + 1) === props.frames.length ? 0 : animationFrame + 1;
                playAnimationCycle(durationCounter + frameSpeeds[animationFrame], nextAnimationFrame);
            } else if (props.lastFrame) {
                // Animation is done
	            setCurrentFrame(props.lastFrame);
				if (!!props.onAnimationComplete) {
					props.onAnimationComplete();
				}
            } else {
				if (!!props.onAnimationComplete) {
					props.onAnimationComplete();
				}
            }
        }, frameSpeeds[animationFrame]);
    };

    useEffect(() => {
        if (props.isAnimated) {
            playAnimationCycle(); // Start the animation loop
			props.onAnimationStarted();
        } else {
			if (props.lastFrame) {
				setCurrentFrame(props.lastFrame);
			} else {
				setCurrentFrame(0);
			}
        }
    }, [props.isAnimated]);

	const frame = props.frames[currentFrame];

	return (
        <div
	        className={classnames(styles.root, {
	            [styles.animating]: props.isAnimated,
	            [styles.transparent]: props.isTransparent,
	        })}
	        style={{
				width: props.width,
				height: props.height,
		        left: props.left,
		        top: props.top,
	        }}
        >
	        {isString(props.image) ? (
				<div
					className={styles.svgContainer}
	                style={{
						top: frame?.top || "auto",
						right: frame?.right || "auto",
						bottom: frame?.bottom || "auto",
						left: frame?.left || "auto",
						height: frame?.height || "100%",
			        }}
	            >
					<Image src={props.image} />
				</div>
	        ) : (
				<div
					className={styles.svgContainer}
	                style={{
						top: frame?.top || "auto",
						right: frame?.right || "auto",
						bottom: frame?.bottom || "auto",
						left: frame?.left || "auto",
						height: frame?.height || "100%",
			        }}
	            >
					{props.image}
				</div>
	        )}
        </div>
    );
}

GenericAnimation.defaultProps = {
	animationDuration: null,
	isAnimated: false,
	isTransparent: false,
	lastFrame: null,
	left: "100%",
	onAnimationStarted: () => {},
	onBeforeAnimationFrame: () => {},
};

GenericAnimation.propTypes = {
	altText: PropTypes.string.isRequired,
	animationDuration: PropTypes.number, // How long should the whole animation play (Repeat animation for X seconds)
	duration: PropTypes.number.isRequired, // How long does it take for the animation to play once
	frames: PropTypes.arrayOf(PropTypes.shape({
		image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // If image is different then the default
		duration: PropTypes.number, // If duration should not be an equal part of the animation
		top: PropTypes.string,
		right: PropTypes.string,
		bottom: PropTypes.string,
		left: PropTypes.string,
	})).isRequired,
	height: PropTypes.string.isRequired,
	image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	isAnimated: PropTypes.bool,
	isTransparent: PropTypes.bool,
	lastFrame: PropTypes.number,
	left: PropTypes.string,
	onAnimationComplete: PropTypes.func,
	onAnimationStarted: PropTypes.func,
	onBeforeAnimationFrame: PropTypes.func,
	top: PropTypes.string,
	width: PropTypes.string.isRequired,
};