import React from "react";
import styles from "./VetCheck.scss";
import ClinicBase from "components/layout/ClinicBase";
import VetCheckOfferForm from "components/notifications/forms/VetCheckOfferForm";
import logger from "utils/logger";

const VETCHECK_AUTOMATE_LOGO = require("../../../../../img/vetcheck_automate_logo.png");
const VETCHECK_FLOWCHART = require("../../../../../img/vetcheck_flowchart.jpg");

export default function VetCheckOfferPage(props) {
    const handleSubmit = formData => {
        logger.log("qqq OBEY! (need URL/endpoint to submit 'accept' data)", formData);
    };

    const handleDecline = () => {
        logger.log("qqq (need URL/endpoint to submit decline)");
    };

    return (
        <ClinicBase docTitle={"VetCheck Offer"}>
            <div className={styles.root}>
                <div className="full-width flex flex-column align-center">
                    <div style={{ width: "70%" }}>
                        <img
                            src={VETCHECK_AUTOMATE_LOGO}
                            alt="VetCheck Automate logo"
                            style={{ width: "100%" }}
                        />
                        <div>VetCheck Automate is the automation platform teams use to save time, better educate clients, and provide high-value healthcare.</div>
                        <div className="margin-top-sm">
                            <ul>
                                <li>Improve practice workflow efficiency and data collection with digital forms with e-signature</li>
                                <li>Enhance pet health outcomes and client understanding with 100’s of home care videos</li>
                                <li>Increase compliance & the uptake of the vet recommendations with over 2000 up-to-date, visual client education templates</li>
                                <li>Automate communication across the entire patient care journey and start building stronger client relationships.</li>
                            </ul>
                        </div>
                        <img
                            src={VETCHECK_FLOWCHART}
                            alt="VetCheck flowchart"
                            className="margin-top-sm"
                            style={{ width: "100%" }}
                        />
                        <div className="margin-top-sm">
                            <p>How does it work? Our team will work with you to set up your practice workflows so that you can automatically start sharing client communications at the right time with no effort at all.</p>
                            <p>Why VetCheck? Client communication is challenging. Inadequate client communication is the #1 cause for complaint, dealing with clients is the #2 cause for burnout in practice, and a recent survey revealed that 2 out of 3 technicians found client communication the hardest part of their job.</p>
                            <p>VetCheck is your safety net and makes life easier for the whole veterinary team.</p>
                            <p><b>Click here to <a href="https://www.vetcheck.it/automate">visit Vetcheck Automate</a> site.</b></p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <div style={{ width: "70%" }}>
                        <hr />
                        <VetCheckOfferForm
                            onSubmit={handleSubmit}
                            onDecline={handleDecline}
                        />
                    </div>
                </div>
            </div>
        </ClinicBase>
    );
}
