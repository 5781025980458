import request  from "utils/request";
import getApiRoot from "./getApiRoot";

export function getProductsForGameType(gameType, userId, productCategoryId=null) {
    return request()
        .query({
            gameType,
            productCategoryId,
            userId,
        })
        .get(`${getApiRoot()}/v1/services/bigames/brands/productbrandsforgametype`)
        .send();
}

export function getProductsForGameTypeAndPeriod(gameType, period, productCategoryId, userId) {
    return request()
        .query({
            gameType,
            period,
            productCategoryId,
            userId,
        })
        .get(`${getApiRoot()}/v1/services/bigames/brands/productbrandsforgametypeandperiod`)
        .send();
}