import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./TextBox.scss";
import toLower from "lodash/toLower";
import Tooltip from "components/common/Tooltip";
import * as Position from "constants/TooltipPositionIds";
import toast from "utils/toast";

export default function TextBox(props) {
    const {
        autoComplete = "off",
        inputType = "text",
    } = props;

    // Temporary place to store the value typed until onBlur
    const [tempValue, setTempValue] = useState(props.value || "");
    const value = props.changeOnBlur ? tempValue : (props.value === undefined || props.value === null) ? "" : props.value;

    const handleChange = (e) => {
        if (inputType === "number") {
            const parsed = parseInt(e.target.value);
            if (props.max && parsed > props.max) {
                return;
            }

            if (props.min && parsed < props.min) {
                return;
            }
        }
        // Using onBlur to make the change instead of onChange
        if(props.changeOnBlur) {
            setTempValue(e.target.value);
        } else {
            props.onChange({
                name: props.name,
                value: e.target.value,
            });
        }
    };

    const handleBlur = (e) => {
        if(props.onBlur) {
            e.stopPropagation();
            e.preventDefault();
            if(props.changeOnBlur) {
                // If onBLur is being used for changes then do that here
                props.onBlur({
                    name: props.name,
                    value,
                });
            } else {
                // Otherwise, it's a simple onBlur action
                props.onBlur(e);
            }
        }
    }

    const handleKeyDown = (e) => {
        if (e.keyCode === 13 || e.key === "Enter") {
            if (!!props.onEnter) {
                e.stopPropagation();
                e.preventDefault();
                props.onEnter();
            } else {
                e.stopPropagation();
                e.preventDefault();
                e.target.blur();
            }
        } else if (e.keyCode === 32 || e.key === " ") {
            if (!!props.onSpaceBar) {
                e.stopPropagation();
                e.preventDefault();
                props.onSpaceBar();
            }
        }
    };

    const handleClear = () => {
        if(props.onClear) {
            props.onClear();
        }
    }

    const inputRef = React.createRef();

    useEffect(() => {
        if (props.focused) {
            inputRef.current.focus();
        }
    }, [props.focused]);

    useEffect(() => {
        if(props.changeOnBlur) {
            setTempValue(props.value);
        }
    }, [props.value]);

    const handleCopyClicked = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const element = inputRef.current;
        if (element) {
            element.focus();
            element.select();
            element.setSelectionRange(0, 99999); // For mobile devices (says w3schools)
            if (!!navigator.clipboard.writeText(element.value)) {
                toast.success(`${props.label} Copied to Clipboard`);
            } else if (!!document.execCommand("copy")) {
                toast.success(`${props.label} Copied to Clipboard`);
            }
        }
    }

    return (
        <div
            data-testid={`${toLower(props.name)}-form-group`}
            className={classnames(styles.root, "form-group", {
                "frameless": props.frameless,
                "searchDropdown": props.searchDropdown,
                "has-value": props.value !== undefined && props.value !== null && props.value !== "",
                "force-active-label": props.forceActiveLabel,
                "disappearing-label": props.disappearingLabel,
                "invalid": props.invalid,
                "light": props.light,
                "no-label": !props.label,
                "no-background": props.noBackground,
                "no-padding": props.noPadding,
                "has-error": props.hasError,
                "aligned left": props.alignedLeft,
                "aligned right": props.alignedRight,
                "aligned split": props.alignedSplit,
                "is-currency": props.currency,
                "has-unit": !!props.unit,
                "cursor-copy": props.canCopy,
            })}
            onKeyPress={handleKeyDown}
            onClick={(e) => props.canCopy ? handleCopyClicked(e) : {}}
        >
            {!props.disappearingLabel && (props.label || props.information) && (
                <label className="flex" style={props.labelStyle}>
                    <div
                        data-testid={`${toLower(props.name)}-label`}
                        className="flex-none"
                    >
                        {props.label}{props.required && "*"}
                    </div>
                    {!!props.information && (
                        <div
                            data-testid={`${toLower(props.name)}-tooltip`}
                            className="flex-1 margin-left-x-sm"
                        >
                            <Tooltip position={props.informationPosition || Position.RIGHT} tip={props.information}>
                                <i className="far fa-info-circle margin-right-x-sm" />
                            </Tooltip>
                        </div>
                    )}
                </label>
            )}
            {props.isSearch && (<span className={classnames("search-icon", {
                ["has-label"]: !!props.label,
            })}><i className="fa fa-search"/></span>)}
            {props.textArea ?
                <textarea
                    data-testid={props["data-testid"]}
                    className={classnames({"padding-right-lg": !!props.canCopy})}
                    style={props.style}
                    ref={inputRef}
                    onChange={handleChange}
                    value={value}
                    disabled={props.disabled || props.canCopy}
                    placeholder={props.placeholder}
                    autoComplete={autoComplete}
                    autoFocus={props.autoFocus}
                    required={props.required}
                    maxLength={props.maxLength}
                    minLength={props.minLength}
                    rows={props.large ? 8 : 4}
                    cols={props.large ? 200 : 100}
                    title={props.title}
                    onBlur={handleBlur}
                />
                :
                <input
                    data-testid={props["data-testid"]}
                    className={classnames({
                        "padding-right-lg": !!props.canCopy,
                        [styles.isSearch]: !!props.isSearch
                    })}
                    style={props.style}
                    ref={inputRef}
                    type={inputType}
                    onChange={handleChange}
                    value={value}
                    disabled={props.disabled || props.canCopy}
                    placeholder={props.placeholder}
                    autoComplete={autoComplete}
                    autoFocus={props.autoFocus}
                    required={props.required}
                    maxLength={props.maxLength}
                    minLength={props.minLength}
                    pattern={props.pattern}
                    max={props.max}
                    min={props.min}
                    step={props.step}
                    title={props.title}
                    onBlur={handleBlur}
                />}
            {!!props.disappearingLabel && props.label && (<label style={props.labelStyle}>{props.label}{props.required && "*"}</label>)}
            {props.statusIcon && (
                <span
                    data-testid={`${props.name}-status-icon`}
                    className="status-icon"
                >
                    {props.statusIcon}
                </span>
            )}
            {props.canCopy && (
                <div
                    data-testid={`${props.name}-copy-icon`}
                    className={classnames("text-primary status-icon pointer", {
                        "has-label": props.label
                    })}
                >
                    <i className="far fa-copy" />
                </div>
            )}
            {props.loading && (
                <span
                    data-testid={`${props.name}-loading-icon`}
                    className={classnames("text-success status-icon", {
                        "has-label": props.label
                    })}
                >
                    <i className="fad fa-spinner-third fa-spin" />
                </span>
            )}
            {props.showWarning && (
                <span
                    data-testid={`${props.name}-warning-icon`}
                    className="status-icon text-warn"
                >
                    <i className="fa fa-exclamation-triangle" />
                </span>
            )}
            {props.currency && (
                <span
                    data-testid={`${props.name}-currency`}
                    className="currency"
                >
                    $
                </span>
            )}
            {props.unit && (
                <span
                    data-testid={`${props.name}-unit`}
                    className="unit-label"
                >
                    {props.unit}
                </span>
            )}
        </div>
    );
}

TextBox.propTypes = {
    ["data-testid"]: PropTypes.string,
    autoComplete: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.any,
    label: PropTypes.string,
    inputType: PropTypes.oneOf(["text", "number", "email", "password", "search"]),
    disabled: PropTypes.bool,
    onEnter: PropTypes.func,
    onSpaceBar: PropTypes.func,
    frameless: PropTypes.bool,
    searchDropdown: PropTypes.bool,
    isSearch: PropTypes.bool,
    focused: PropTypes.bool,
    required: PropTypes.bool,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    min: PropTypes.number,
    currency: PropTypes.bool,
    statusIcon: PropTypes.node,
    forceActiveLabel: PropTypes.bool,
    disappearingLabel: PropTypes.bool,
    showWarning: PropTypes.bool,
    noBackground: PropTypes.bool,
    noPadding: PropTypes.bool,
    hasError: PropTypes.bool,
    alignedLeft: PropTypes.bool,
    alignedRight: PropTypes.bool,
    alignedSplit: PropTypes.bool,
    large: PropTypes.bool,
    textArea: PropTypes.bool,
    autoFocus: PropTypes.bool,
    loading: PropTypes.bool,
    pattern: PropTypes.string,
    unit: PropTypes.string,
    title: PropTypes.string,
    information: PropTypes.string,
    informationPosition: PropTypes.oneOf([Position.LEFT, Position.RIGHT, Position.TOP, Position.BOTTOM]),
    invalid: PropTypes.bool,
    //If we show the copy button then the text field cannot be changed
    placeholder: PropTypes.string,
    canCopy: PropTypes.bool,
    style: PropTypes.object,
    labelStyle: PropTypes.object,
    // Don't handle changes until onBlur is called.
    onBlur: PropTypes.func,
    changeOnBlur: PropTypes.bool,
};
