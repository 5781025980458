import React, { Fragment, useEffect, useCallback, useState } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import keys from "lodash/keys";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import merge from "lodash/merge";
import reject from "lodash/reject";
import set from "lodash/set";
import * as ClinicApi from "api/ClinicApi";
import * as CouponsApi from "api/CouponsApi";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import styles from "./AddReprocessingJobForm.scss";
import DataTable from "components/common/datatable/DataTable";
import DateInput from "components/common/DateInput";
import Dropdown from "components/common/Dropdown";
import DropdownSearch from "components/common/DropdownSearch";
import OpenInvoicePDFLinkNewTab from "components/common/OpenInvoicePDFLinkNewTab";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import ToggleSwitch from "components/common/ToggleSwitch";
import getLocalTimezone from "utils/getLocalTimezone";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import {USStates} from "constants/USStates";
import {mapInvoicesFromServerToApp, mapProgramOffersFromServerToApp} from "data/serverMapping";

function AddReprocessingJobForm(props) {
    const [formData, setFormData] = useState({});
    const [loadingClinics, setLoadingClinics] = useState(false);
    const [loadingInvoices, setLoadingInvoices] = useState(false);
    const [loadingOffers, setLoadingOffers] = useState(false);
    const [clinics, setClinics] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [offers, setOffers] = useState([]);
    const [search, setSearch] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [searchType, setSearchType] = useState("number");
    const [searchPeriod, setSearchPeriod] = useState("bot");
    const [confirmed, setConfirmed] = useState(false);

    const clinicId = formData.clinicId
    const clinic = find(clinics, {clinicId: clinicId}) || null;

    const unUsedInvoices = reject(invoices, invoice => {
        return !!includes(map(formData.invoices, "id"), invoice.id);
    });

    const pagination = 25;

    const getProgramOffers = (programId) => {
        if(!!clinicId) {
            setLoadingOffers(true);
            CouponsApi.getProgramOffers(programId, clinicId)
                .then((res) => {
                    setOffers({
                        ...offers,
                        [programId]: mapProgramOffersFromServerToApp(res.body)
                    });
                    setLoadingOffers(false);
                })
                .catch((error) => {
                    handleErrorResponse("loading program offers", error);
                    setLoadingOffers(false);
                });
        }
    }

    useEffect(() => {
        if (props.canViewClinicCouponLibrary) {
            forEach(formData.manufacturers, (providerId) => {
                if (!offers[providerId]) {
                    getProgramOffers(providerId, clinicId);
                }
            })
        }
    }, [formData.manufacturers?.length, props.canViewClinicCouponLibrary]);

    const searchInvoices = (search) => {
        CouponsApi.searchInvoices(search)
            .then((res) => {
                setInvoices(mapInvoicesFromServerToApp(res.body));
                setLoadingInvoices(false);
            })
            .catch((error) => {
                handleErrorResponse("loading invoices", error);
                setInvoices([]);
                setLoadingInvoices(false);
            });
    }

    const handleSearchInvoices = () => {
        if(props.canEditCouponReprocessing) {
            setLoadingInvoices(true);
            let type = searchType;
            let searchValue = search;
            if (searchType === "dateRange") {
                const start = new moment(startDate).format("MM/DD/YYYY");
                const end = new moment(endDate).format("MM/DD/YYYY");
                searchValue = `${start}-`;
                if (endDate) {
                    searchValue += end;
                }
                //Set the type to date so we search correctly
                type = "date";
            } else if (searchType === "date") {
                searchValue = new moment(startDate).format("MM/DD/YYYY");
            }
            searchInvoices({clinicId, searchType: type, searchValue, "period": searchPeriod})
        }
    }

    const handleUpdateSearch = ({value}) => {
        setSearch(value);
    }
    const handleUpdateSearchType = ({value}) => {
        setSearchType(value);
        if(!(value === "date" || value === "dateRange")) {
            setSearch("");
        }
    }

    const SEARCH_TYPE_OPTIONS = [
        {name: "Invoice Number", value: "number"},
        {name: "Invoice Date", value: "date"},
        {name: "Invoice Date (Range)", value: "dateRange"},
        {name: "Pet or Client Name", value: "name"},
        {name: "Invoice ID", value: "id"},
    ]

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        const data = {
            ...formData,
            invoices: map(formData.invoices, (invoice,key) => Number(key))
        }
        props.onSubmit(data);
        if (props.onCancel) {
            props.onCancel();
        }
    };

    const searchClinics = (searchTerm) => {
        setLoadingClinics(true);
        ClinicApi.searchClinics({search:searchTerm, offset:0, limit:100})
            .then((res) => {
                if (res.body) {
                    setClinics(res.body.data);
                    setLoadingClinics(false);
                }
            })
            .catch((error) => {
                handleErrorResponse("searching clinics", error);
                setClinics([]);
                setLoadingClinics(false);
            });
    }

    const handleClinicSearch = useCallback(debounce((value) => {
        if (props.canViewClinicManagement) {
            setLoadingClinics(true);
            searchClinics(value);
        }
    }, 500), []);

    const handleClinicChange = ({name, value}) => {
        setFormData({[name]: value});
        setConfirmed(false);
        setSearch("");
        setInvoices([]);
        setOffers([]);
    };

    const handleProviders = ({name, value}) => {
        let manufacturers = formData.manufacturers || []
        if(includes(formData.manufacturers, value)) {
            manufacturers = reject(manufacturers, (pro) => {return pro === value});
        } else {
            manufacturers.push(value);
        }
        handleFormChange({name: "manufacturers", value: manufacturers})
    };

    const handleOffers = (value) => {
        let selectedOffers = formData.offers || []
        if(includes(formData.offers, value)) {
            selectedOffers = reject(selectedOffers, (pro) => {return pro === value});
        } else {
            selectedOffers.push(value);
        }
        handleFormChange({name: "offers", value: selectedOffers})
    };

    const handleFormChange = ({name, value}) => {
        const newData = {
            ...formData,
        };
        set(newData, name, value);
        setFormData(newData);
    };

    const handleAddInvoices = (invoiceIds) => {
        const selectedInvoices = keyBy(map(invoiceIds, id => {
            return {
                ...find(invoices, {"invoiceId": id})
            }
        }), "invoiceId");

        const newFormDataInvoices = {...formData.invoices}

        merge(newFormDataInvoices, selectedInvoices);
        handleFormChange({name: "invoices", value: newFormDataInvoices})
    };

    const handleRemoveInvoices = (invoiceIds) => {
        const newFormDataInvoices = {...formData.invoices};

        forEach(invoiceIds, id => {
            delete newFormDataInvoices[id];
        })

        handleFormChange({name: "invoices", value: newFormDataInvoices})
    };

    const combinedData = {
        ...props.initialData,
    };

    merge(combinedData, formData);

    const CLINIC_OPTIONS = map(clinics, (clinic) => {
        return {name: `(${clinic.clinicId}) ${clinic.clinicName} - ${clinic.city}, ${USStates[clinic.state.toUpperCase()]}`, value: clinic.clinicId};
    });

    const COLUMNS = [{
        name: "Invoice Id",
        selector: "id",
        key: "id",
        format: row => (
            <OpenInvoicePDFLinkNewTab
                className="text-primary"
                invoiceId={row.invoiceId}
                invoiceNumber={row.invoiceNumber}
                groupOfferId={row.isGroupOffer ? row.actualGroupRedemptionId : null}
                showInvoiceId
            />
        )
    },{
        name: "Invoice #",
        selector: "invoiceNumber",
        key: "invoiceNumber",
    }, {
        name: "Invoice Date",
        selector: "invoiceDate",
        key: "invoiceDate",
        format: row => (row.invoiceDate ? getLocalTimezone(row.invoiceDate) : "-"),
    }, {
        name: "Client",
        selector: "petOwnerFirstName",
        key: "petOwnerFirstName",
        format: row => `${row.petOwnerFirstName} ${row.petOwnerLastName}`
    }, {
        name: "Redem.",
        selector: "numberOfRedemptions",
        key: "numberOfRedemptions",
    }, {
        name: "Ignore",
        selector: "ignore",
        key: "ignore",
        format: row => (
            row.ignore ? (
                <i className="far fa-check-circle text-success"/>
            ) : (
                <i className="far fa-times-circle text-danger"/>
            )
        )
    }, {
        name: "PA in Token",
        selector: "tokenIdOfPendingRedemption",
        key: "tokenIdOfPendingRedemption",
    }];

    const getSearchBox = (clinicId) => {
        const TODAY = new Date();
        if (searchType === "date" || searchType === "dateRange") {
            return(
                <>
                    <div className="flex-1">
                        <DateInput
                            onChange={({value}) => setStartDate(value)}
                            name="startDate"
                            value={startDate}
                            maxDate={TODAY}
                            label={searchType === "date" ? "Date" : "Start Date"}
                            showDropdowns
                            disabled={!clinicId}
                        />
                    </div>
                    {searchType === "dateRange" && (
                        <div className="flex-1">
                            <DateInput
                                onChange={({value}) => setEndDate(value)}
                                name="endDate"
                                value={endDate}
                                minDate={startDate}
                                maxDate={TODAY}
                                label="End Date (optional)"
                                showDropdowns
                                disabled={!(clinicId && startDate)}
                            />
                        </div>
                    )}
                </>
            )
        } else {
            return (
                <TextBox
                    name="search"
                    value={search}
                    label={searchType === "name" ? "Search Pet or Owner Name" : "Search Invoices"}
                    onChange={handleUpdateSearch}
                    disabled={!clinicId}
                />
            )
        }
    }

    const canConfirm = formData.invoices && keys(formData.invoices).length > 0 && formData.manufacturers && formData.manufacturers.length > 0;

    if (!props.canEditCouponReprocessing) {
        return <AccessDenied/>;
    }

    return (
        <div className={styles.root}>
            <div className="flex margin-top-sm">
                <div className="flex-1">
                    <DropdownSearch
                        name="clinicId"
                        onChange={handleClinicChange}
                        value={clinicId}
                        options={CLINIC_OPTIONS}
                        label="Search Clinics"
                        onSearchChange={handleClinicSearch}
                        loading={loadingClinics}
                    />
                </div>
            </div>
            <hr className="margin-top-lg margin-bottom-md"/>
            <div className="flex spaced-content align-bottom">
                <div className="flex-2 flex spaced-content">
                    {getSearchBox(clinicId)}
                </div>
                <div className="flex-1 margin-bottom-x-sm">
                    <Dropdown
                        name="searchType"
                        onChange={handleUpdateSearchType}
                        value={searchType}
                        options={SEARCH_TYPE_OPTIONS}
                        label="Search Type"
                        disabled={!clinicId}
                        loading={!SEARCH_TYPE_OPTIONS.length}
                    />
                </div>
                <div className="flex-none">
                    <div className="margin-bottom-x-sm">
                        <Button
                            onClick={handleSearchInvoices}
                            type="success"
                            disabled={!(searchType && (!!(searchType === "date" || searchType === "dateRange") ? startDate : search) && clinicId)}
                        >
                            Search
                        </Button>
                    </div>
                </div>
            </div>
            <div className="margin-top-sm margin-bottom-lg">
                {invoices && (
                    <DataTable
                        onRowSelectChange={handleAddInvoices}
                        title="Invoices"
                        columns={COLUMNS}
                        data={unUsedInvoices}
                        selectableRows
                        paginationPerPage={pagination}
                        canSelectAll
                    />
                )}
            </div>
            <div className="margin-top-md margin-bottom-lg">
                {formData.invoices && (
                    <>
                        <DataTable
                            onRowSelectChange={handleRemoveInvoices}
                            title="Selected Invoices"
                            columns={COLUMNS}
                            data={Object.values(formData.invoices)}
                            selectableRows
                            striped
                            green
                            paginationPerPage={pagination}
                            canSelectAll
                        />
                    </>
                )}
            </div>
            <hr/>
            <div className="margin-top-md margin-bottom-md">
                <h3>Coupon Manufacturer List</h3>
                {clinic ? (
                    map(filter(clinic.activations, a => {return !a.deactivationDate}),(provider) => (
                        <Fragment
                            key={provider.providerId}
                        >
                            <div className="text-lg">
                                <CheckboxInput
                                    name="providers"
                                    checked={includes(formData.manufacturers, provider.providerId)}
                                    onChange={() => handleProviders({name: "manufacturerId", value: provider.providerId})}
                                    label={provider.providerName}
                                />
                            </div>
                            {(offers[provider.providerId] && includes(formData.manufacturers, provider.providerId)) && (
                                map(offers[provider.providerId], (offer) => (
                                    map(offer.offers, (off) => (
                                        <div
                                            key={off.offerId}
                                            className="flex align-center text-sm margin-left-md"
                                        >
                                            <ToggleSwitch
                                                id="offers"
                                                updateState={() => handleOffers(off.offerId)}
                                                force={formData.offers && includes(formData.offers.offerId)}
                                                useNotSet
                                            />
                                            <label dangerouslySetInnerHTML={{ __html: off.name }}/>
                                        </div>
                                    ))
                                ))
                            )}
                        </Fragment>
                    ))
                ) : (
                    <div className="text-sm">Select a Clinic to see the list of Manufacturers</div>
                )}
            </div>
            <hr/>
            <div className="margin-top-md margin-bottom-md">
                <CheckboxInput
                    name="confirmed"
                    onChange={() => {setConfirmed(!confirmed)}}
                    label={"Confirm the reprocessing of the selected invoices? I want you to consider your next action very carefully, as any mistakes will upset a lot of people, and require some of your friends to clean up after you. It won't be pretty. Now, as I was saying, are you really sure you want to do this? Thank you, and have a nice day!"}
                    checked={confirmed}
                    disabled={!canConfirm}
                    required
                />
            </div>
            <div className="flex">
                <div className="flex-1" />
                <div className="flex-none spaced-content">
                    <Button
                        type="gray"
                        onClick={props.onCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        buttonType="submit"
                        disabled={!confirmed || !canConfirm}
                        onClick={handleSubmit}
                    >
                        Reprocess Selected
                    </Button>
                </div>
            </div>
            <SpinnerTakeover show={loadingInvoices || loadingOffers}/>
        </div>
    );
}

AddReprocessingJobForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditCouponReprocessing = userHasPermission(PermissionTypes.EDIT, UserPermissions.COUPON_REPROCESSING, userProfile);
        return {
            canViewClinicCouponLibrary,
            canViewClinicManagement,
            canEditCouponReprocessing,
        }
    }
)(AddReprocessingJobForm);
