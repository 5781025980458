import {combineReducers} from "redux";
import * as ActionTypes from "constants/ActionTypes";


function isLoggedIn(state=false, action) {
    switch (action.type) {
        case ActionTypes.USER_LOGGED_IN:
            return true;
        case ActionTypes.LOG_OUT_USER:
            return false;
        default:
            return state;
    }

}

function isImpersonated(state=false, action) {
    switch (action.type) {
        case ActionTypes.IMPERSONATE_USER:
            return true;
        case ActionTypes.REVERT_TO_SELF:
            return false;
        default:
            return state;
    }

}

function demoUser(state = null, action) {
    switch (action.type) {
        case ActionTypes.DEMO_USER_LOGGED_IN:
            return action;
        default:
            return state;
    }
}

function userProfile(state=null, action) {
    switch (action.type) {
        case ActionTypes.CURRENT_USER_UNKNOWN:
            // User when impersonating to prevent loading endpoints without permission
            return null;
        case ActionTypes.CURRENT_USER_LOADED:
            return action.user;
        case ActionTypes.CURRENT_USER_NODE_ID:
            return {
                ...state,
                nodeId: action.nodeId
            }
        case ActionTypes.LOG_OUT_USER:
            return null;
        case ActionTypes.CURRENT_USER_UPDATED:
            return {
                ...state,
                ...action.userUpdate,
            };
        case ActionTypes.USER_NODE_LOADED:
            return {
                ...state,
                nodeId: action.nodeId
            }
        default:
            return state;
    }

}

function currentClinicId(state=null, action) {
    switch (action.type) {
        case ActionTypes.USER_LOCATION_CHANGED:
            return action.clinicId;
        default:
            return state;
    }
}

function passwordReset(state = {}, action) {
    switch (action.type) {
        case ActionTypes.USER_RESET_REQUESTED:
            return {
                processing: true,
                ...action.message
            };
        case ActionTypes.USER_VERIFY_COMPLETE:
            return {
                ...state,
                processing: true,
                ...action.data,

        }
        case ActionTypes.USER_RESET_COMPLETE:
            return {
                ...state,
                processing: false,
                ...action.message
            }
        default:
            return state;
    }
}

function passwordResetClicked(state = false, action) {
    switch (action.type) {
        case ActionTypes.PASSWORD_RESET_CLICKED:
            return true
        default:
            return state;
    }
}


export default combineReducers({
    isLoggedIn,
    userProfile,
    passwordReset,
    passwordResetClicked,
    currentClinicId,
    isImpersonated,
    demoUser,
});
