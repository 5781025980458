import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import get from "lodash/get";
import map from "lodash/map";
import styles from "./EligibilityTrackerEligibleClientsPage.scss";
import * as ClinicApi from "api/ClinicApi";
import ClinicBase from "components/layout/ClinicBase";
import OpenInvoicePDFLinkNewTab from "components/common/OpenInvoicePDFLinkNewTab/OpenInvoicePDFLinkNewTab";
import SearchEligibleClients from "components/eligibilityTracker/SearchEligibleClients";
import SortableDataTable from "components/common/SortableDataTable";
import { getClinicInfo } from "utils/ClinicData";
import { handleErrorResponse } from "utils/request";
import { BOEHRINGER_INGELHEIM } from "constants/ProviderIds";

function EligibilityTrackerEligibleClientsPage(props) {
    const [selectedClientData, setSelectedClientData] = useState(null);
    const [isLoadingLastClientInvoice, setIsLoadingLastClientInvoice] = useState(true);
    const [lastInvoiceData, setLastInvoiceData] = useState(null);
    const selectedClientId = get(selectedClientData, "clientId", null);

    useEffect(() => {
        if (!!props.clinicId && !!selectedClientId) {
            handleLoadLastClientInvoice();
        }
    }, [props.clinicId, selectedClientId]);

    const handleLoadLastClientInvoice = () => {
        setIsLoadingLastClientInvoice(true);
        ClinicApi.loadLastInvoice(props.clinicId, BOEHRINGER_INGELHEIM, selectedClientId)
            .then((res) => {
                const invoiceData = get(res, "body[0]");
                setLastInvoiceData(invoiceData);
                setIsLoadingLastClientInvoice(false);
            })
            .catch((error) => {
                setIsLoadingLastClientInvoice(false);
                handleErrorResponse("loading last client invoice", error);
            });
    };

    const renderEligibleOffersBlock = (patientData) => {
        const hasEligibleOffers = patientData.eligibleOffers.length > 0;
        if (hasEligibleOffers) {
            return (
                <div>
                    {map(patientData?.eligibleOffers, eligibleOfferData => (
                        <div>
                            {eligibleOfferData.shortDescription}
                        </div>
                    ))}
                </div>
            );
        }

        return (
            <div>
                <div>* Patient is only eligible for National Offers</div>
                <Link
                    className="pointer text-primary margin-left-md"
                    to={props.urlToCouponLibrary}
                >
                    View Coupon Library
                </Link>
            </div>
        );
    };

    const renderPatientsTable = (row) => {
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Patient Name</th>
                        <th>Available Offers</th>
                    </tr>
                </thead>
                <tbody>
                    {row.eligiblePatients.map(patientData => (
                        <tr key={patientData.patientId}>
                            <td>{patientData.patientName}</td>
                            <td>{renderEligibleOffersBlock(patientData)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        );
    };

    const EXPANDED = {
        selector: "patients",
        key: "patients",
        format: (row) => renderPatientsTable(row)
    };

    const COLUMNS = [
        {
            name: "Client Name",
            selector: "clientName",
            key: "clientName",
            format: (row) => (
                <span>{row.petOwnerFirstName} {row.petOwnerLastName}</span>
            ),
            sortValue: (row) => `${row.petOwnerFirstName} ${row.petOwnerLastName}`,
        },
        {
            name: "Client ID",
            selector: "remoteClientDisplayId",
            key: "remoteClientDisplayId",
        },
        {
            name: "Last Client Invoice with BI Product",
            selector: "lastClientInvoiceId",
            key: "lastClientInvoiceId",
            format: () => {
                if (isLoadingLastClientInvoice) {
                    return <span>Loading...</span>;
                }

                const lastInvoiceNumber = get(lastInvoiceData, "lastInvoiceNumber");
                const lastInvoiceId = get(lastInvoiceData, "lastInvoiceId");
                const lastExtendedInvoiceNumber = get(lastInvoiceData, "lastExtendedInvoiceNumber");
                if (lastInvoiceId && lastExtendedInvoiceNumber) {
                    return (
                        <OpenInvoicePDFLinkNewTab
                            className="text-primary"
                            invoiceNumber={lastInvoiceNumber}
                            invoiceId={lastInvoiceId}
                            extendedInvoiceNumber={lastExtendedInvoiceNumber}
                        />
                    );
                }

                return <span>No Invoices Found</span>;
            },
        }
    ];

    return (
        <ClinicBase
            clinicId={props.clinicId}
            docTitle="Eligibility Tracker Eligible Clients"
            pageTitle="Eligibility Tracker - Eligible Clients"
        >
            <div className={styles.root}>
                <div className={styles.searchContainer}>
                    <SearchEligibleClients
                        clinicId={props.clinicId}
                        onChange={(data, value) => setSelectedClientData(value)}
                        name="client"
                        value={selectedClientId}
                    />
                </div>

                {!!selectedClientData && (
                    <SortableDataTable
                        columns={COLUMNS}
                        expandedData={EXPANDED}
                        rawData={[selectedClientData]}
                        striped
                        green
                    />
                )}
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;

        const urlToCouponLibrary = ownProps.match.url.replace("/eligibility-tracker/eligible-clients", "/coupons/library");

        return {
            clinicId,
            urlToCouponLibrary
        }
    }
)(EligibilityTrackerEligibleClientsPage);
