exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PuppyTrack__cls1{fill:#ffc200}.PuppyTrack__cls1,.PuppyTrack__cls2,.PuppyTrack__cls3,.PuppyTrack__cls4,.PuppyTrack__cls5,.PuppyTrack__cls6,.PuppyTrack__cls7,.PuppyTrack__cls8,.PuppyTrack__cls9{stroke-width:0}.PuppyTrack__cls2{fill:#0b0808}.PuppyTrack__cls3{fill:#1b75bc}.PuppyTrack__cls4{fill:#b76c00}.PuppyTrack__cls5{opacity:.3}.PuppyTrack__cls5,.PuppyTrack__cls7{fill:#231f20}.PuppyTrack__cls6{fill:#fff}.PuppyTrack__cls8{fill:#ccc7c6}.PuppyTrack__cls9{fill:#04456f}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/GoldenSummerGames/PuppyTrack.scss"],"names":[],"mappings":"AAAA,kBAAM,YAAY,CAAC,kKAAsD,cAAc,CAAC,kBAAM,YAAY,CAAC,kBAAM,YAAY,CAAC,kBAAM,YAAY,CAAC,kBAAM,UAAU,CAAC,oCAAY,YAAY,CAAC,kBAAM,SAAS,CAAC,kBAAM,YAAY,CAAC,kBAAM,YAAY,CAAC","file":"PuppyTrack.scss","sourcesContent":[".cls1{fill:#ffc200}.cls1,.cls2,.cls3,.cls4,.cls5,.cls6,.cls7,.cls8,.cls9{stroke-width:0}.cls2{fill:#0b0808}.cls3{fill:#1b75bc}.cls4{fill:#b76c00}.cls5{opacity:.3}.cls5,.cls7{fill:#231f20}.cls6{fill:#fff}.cls8{fill:#ccc7c6}.cls9{fill:#04456f}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cls1": "PuppyTrack__cls1",
	"cls2": "PuppyTrack__cls2",
	"cls3": "PuppyTrack__cls3",
	"cls4": "PuppyTrack__cls4",
	"cls5": "PuppyTrack__cls5",
	"cls6": "PuppyTrack__cls6",
	"cls7": "PuppyTrack__cls7",
	"cls8": "PuppyTrack__cls8",
	"cls9": "PuppyTrack__cls9"
};