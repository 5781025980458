import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import find from "lodash/find";
import forEach from "lodash/forEach";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import { EQUALITIES, FILTERS } from "constants/ClinicFilterConfigs";
import * as FilterTypes from "constants/FilterTypes";
import styles from "./ClinicFilterShort.scss";

export function ClinicFilterShort(props) {
    const {
        fil = {},
        providerReports = {},
    } = props;
    const PROVIDER_OPTIONS = map(orderBy(props.providers, "name"), p => {return {name: p?.name, value: p.id}});
    const ACTIVE_OFFERS_OPTIONS = map(props.activeOffers, offer => {return {name: offer.display, value: offer.value}});
    const PROVIDER_REPORT_OPTIONS = map(providerReports, report => {return {name: report.display, value: report.value}});
    const PIMS_SOFTWARE_OPTIONS = map(props.softwareVendors, s => {return {name: s.name, value: s.softwareVendorId}});

    const getLevel1Options = (id) => {
        switch (id) {
            case FilterTypes.CLOO_ID:
                return ACTIVE_OFFERS_OPTIONS
            case FilterTypes.PROVIDER_REPORT_ID:
                return PROVIDER_REPORT_OPTIONS
            case FilterTypes.PIMS:
                return PIMS_SOFTWARE_OPTIONS;
            default:
                return FILTERS[id].level1.options
        }
    }

    const getValueName = (list, options) => {
        if (list.length === options.length) {
            return `All (${list.length})`;
        } else if (list.length > 3) {
            return `Selected (${list.length})`;
        } else {
            let str = "";
            let first = true;
            forEach(list, (li) => {
                const name = find(options, o => {return o.value.toString() === li.value.toString()})?.name;
                if (first) {
                    str = name;
                } else {
                    str = str + ", " + name;
                }
                first = false;
            })
            return str;
        }
    }

    return (
        <div
            data-testid="clinic_filter_short_component"
            className={classnames(styles.filter, "margin-top-x-sm margin-right-sm flex align-center spaced-content flex-1", {
                [styles.incomplete]: !fil.isComplete && !props.showFullFilter,
            })}
        >
            <div data-testid="clinic_filter_short_label">
                {!!fil.id && FILTERS[fil.id].label}
            </div>
            {(fil.id === FilterTypes.PROVIDER_ID && fil.level1Values) && (
                <div data-testid="clinic_filter_short_provider_name">
                    {getValueName(fil.level1Values, PROVIDER_OPTIONS)}
                </div>
            )}
            <div data-testid="clinic_filter_short_equality">
                {fil.equality && find(EQUALITIES, {value: fil.equality}).name}
            </div>
            <div
                data-testid="clinic_filter_short_value"
                className={styles.selected}
            >
                {fil.stringValue && fil.stringValue}
                {fil.numberValue && fil.numberValue}
                {fil.dateValue && fil.dateValue}
                {fil.id === FilterTypes.PROVIDER_ID ? (
                    fil.level2Values && getValueName(fil.level2Values, FILTERS[fil.id].level2.options)
                ) : (
                    fil.level1Values && getValueName(fil.level1Values, getLevel1Options(fil.id))
                )}
            </div>
        </div>
    )
}

ClinicFilterShort.propTypes = {
    activeOffers: PropTypes.object.isRequired,
    fil: PropTypes.object,
    providerReports: PropTypes.object,
    providers: PropTypes.object.isRequired,
    showFullFilter: PropTypes.bool,
    softwareVendors: PropTypes.object,
};

export default connect(
    (state) => ({
        providers: state.entities.providers,
        activeOffers: state.entities.offers,
        providerReports: state.entities.providerReports,
        softwareVendors: state.entities.softwareVendors?.data,
    }),
)(ClinicFilterShort);
