import * as PlanStatusIds from "../constants/PlanStatusIds";

export const CHEWY = 4;
export const OUR_PRICE = null;
export const PET_MEDS = 3;

export const SELLERS = [
    CHEWY,
    PET_MEDS,
    // OUR_PRICE
];

export function getCompetitorName(competitorId) {
    switch(competitorId) {
        case 4:
            return "Chewy";
        case 3:
            return "PetMed";
        default:
            return "Our Price";
    }
}