import React from "react";
import PropTypes from "prop-types";
import styles from "./SearchResults.scss";
import includes from "lodash/includes";
import map from "lodash/map";
import classnames from "classnames";

export default function SearchResults(props) {
    return (
        <div
            data-testid="search_results_component"
            className={classnames(styles.root,{
                [styles.positionTop]: props.positionTop,
                "test-position-top": props.positionTop,
            })}
        >
            <div
                data-testid="search_results_clear_results_button"
                className={styles.closeBtn}
                onClick={props.clearResults}
            >
                <i className="far fa-times-circle" />
            </div>
            <table className="table">
                <thead>
                    <tr>
                        {props.isWellness  && (
                            <>
                                <th>Patient</th>
                                <th>Owner</th>
                            </>
                        )}
                        {props.isClinics && (
                            <>
                                <th>Clinic Name</th>
                                <th className="text-center">ID</th>
                            </>
                        )}
                        {!props.isClinics && !props.isWellness && (
                            <>
                                <th className="text-center">ID</th>
                                <th>Name</th>
                            </>
                        )}
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    {renderResults(props)}
                </tbody>
            </table>
        </div>
    )
}

const renderResults = ({ results, clickHandler, isWellness, isClinics, loading, disabled }) => {
    const resultItems = map(results, (result, index) => {
        const resData = {
            id: isClinics ? result.clinicId : result.id,
            name: isClinics ? result.clinicName : result.name,
            patient: result.patient,
        }
        return (
            <tr
                data-testid={`resultItem_${index}`}
                key={`resultItem_${index}`}
                className="clickable"
                onClick={() => clickHandler(resData.id)}
            >
                {!isClinics && (
                    <td className="flex-1">
                        {isWellness ? resData.patient : resData.id}
                    </td>
                )}
                <td>
                    {resData.name}
                </td>
                {isClinics && (
                    <td className="flex-1">
                        {resData.id}
                    </td>
                )}
                <td>
                    {(loading === resData.id) && (
                        <i className="fad fa-spinner-third fa-spin text-dark-green"/>
                    )}
                </td>
            </tr>
        )
    });
    if(!resultItems.length) {
        resultItems.push(
            <tr
                data-testid="no_search_results"
                key="no-results"
            >
                <td colSpan={5}>
                    No results
                </td>
            </tr>
        );
    }
    return resultItems;
}

SearchResults.propTypes = {
    results: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    clickHandler: PropTypes.func.isRequired,
    clearResults: PropTypes.func.isRequired,
    isWellness: PropTypes.bool,
    isClinics: PropTypes.bool,
    positionTop: PropTypes.bool,
    loading: PropTypes.number,
    disabled: PropTypes.arrayOf(PropTypes.number),
}
