exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ActivityGrid__root{display:-ms-grid;display:grid;gap:1em;-ms-grid-columns:(minmax(150px, 180px))[auto-fit];grid-template-columns:repeat(auto-fit, minmax(150px, 180px));-ms-flex:1;flex:1;width:100%;position:relative}@media (min-width: 600px){.ActivityGrid__root{-ms-grid-columns:(minmax(250px, 300px))[auto-fit];grid-template-columns:repeat(auto-fit, minmax(250px, 300px))}}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/dashboard/ActivityGrid.scss"],"names":[],"mappings":"AAAA,oBAAM,iBAAiB,aAAa,QAAQ,kDAAkD,6DAA6D,WAAW,OAAO,WAAW,iBAAiB,CAAC,0BAA0B,oBAAM,kDAAkD,4DAA4D,CAAC,CAAC","file":"ActivityGrid.scss","sourcesContent":[".root{display:-ms-grid;display:grid;gap:1em;-ms-grid-columns:(minmax(150px, 180px))[auto-fit];grid-template-columns:repeat(auto-fit, minmax(150px, 180px));-ms-flex:1;flex:1;width:100%;position:relative}@media (min-width: 600px){.root{-ms-grid-columns:(minmax(250px, 300px))[auto-fit];grid-template-columns:repeat(auto-fit, minmax(250px, 300px))}}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "ActivityGrid__root"
};