import React, {useState} from "react";
import styles from "./ProgramTile.scss";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import * as CouponsApi from "api/CouponsApi";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import InformationTile from "components/common/InformationTile";
import Modal from "components/common/Modal";
import ProgramPreferences from "components/coupons/elements/ProgramPreferences";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {handleErrorResponse} from "utils/request";

export default function ProgramTile(props) {
    const { programInfo } = props;
    const [showSettings, setShowSettings] = useState(false);
    const [loading, setLoading] = useState(false);
    const badge = programInfo.new ? `${programInfo.new} new offers` : null;
    const activated = programInfo.isCurrentlyEnrolled ? programInfo.activationDate ? `Activated ${moment(programInfo.activationDate).format("MM/DD/YYYY")}` : "Pending" : "Not Enrolled";
    const style = programInfo.goGreen ? null : "inactive";

    const handleShowSettings = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowSettings(true);

    }

    const handleSubmit = (formData) => {
        setLoading(true);
        CouponsApi.updateProviderPreferences(formData)
            .then(res => {
                setShowSettings(false);
                setLoading(false);
            })
            .catch((error) => {
                handleErrorResponse("updating provider preferences", error);
                setLoading(false);
            });
    }

    //If is an admin then append some string to beginning of URL
    const linkBase = props.adminView ? `/admin/clinic/${props.clinicId}` : props.providerView ? `/provider/location/${props.nodeId}/clinic/${props.clinicId}` : '';

    //If is a provider view then change the link completely
    const link = `${linkBase}/coupons/library/${programInfo.programId}`

    return (
        <>
            <Link to={link} className={styles.root}>
                <InformationTile
                    title={`${programInfo.optedIn || 0} / ${programInfo.totalOffers || 0} Opted-In`}
                    badge={badge}
                    footer={activated}
                    style={style}
                    iconButton={props.canViewClinicEnrollment ? (
                        <Button
                            type="gray"
                            onClick={handleShowSettings}
                            iconOnly
                        >
                            <i className="fa fa-cog"/>
                        </Button>
                    ) : null }
                >
                    <div className={styles.logo}>
                        <img
                            src={programInfo.imageUrl}
                            title={programInfo.providerName}
                        />
                    </div>
                </InformationTile>
            </Link>
            <Modal
                show={showSettings}
                onClose={() => setShowSettings(false)}
                mediumSmall
                modalTitle={`${programInfo.providerName} Data Preferences`}
            >
                {props.canViewClinicEnrollment ? (
                    <ProgramPreferences
                        canEditClinicEnrollment={props.canEditClinicEnrollment}
                        clinicId={props.clinicId}
                        providerId={programInfo.providerId}
                        onCancel={() => setShowSettings(false)}
                        onSubmit={handleSubmit}
                    />
                ) : (
                  <AccessDenied/>
                )}
            </Modal>
            <SpinnerTakeover show={loading}/>
        </>
    );
}

ProgramTile.propTypes = {
    programInfo: PropTypes.object.isRequired,
    adminView: PropTypes.bool,
    providerView: PropTypes.bool,
    clinicId: PropTypes.number,
    nodeId: PropTypes.number,
    canViewClinicEnrollment: PropTypes.bool,
    canEditClinicEnrollment: PropTypes.bool
};
