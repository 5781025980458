import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./OnGardSetup.scss";
import orderBy from "lodash/orderBy";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import logger from "utils/logger";

export default function PuppyBowlSetup(props) {
    
    const tasks = useMemo(() => {
        return keyBy(props.data?.config?.Tasks||[], "id");
    }, [props.data.config]);
    
    const addOns = useMemo(() => {
        return keyBy(props.data?.addOnConfig||[], "id");
    }, [props.data.addOnConfig]);

    /*
        configuration
            columnHeader
            points
            doses
            firingOrder
        description
        displayOrder
        id
        puppyBowlTaskId
     */
    return (
        <div>
            <h3>How to earn points</h3>
            <div className={classnames(styles.root, "flex flex-wrap")}>
                {map(orderBy(addOns, ["displayOrder"]), configItem => (
                    <div className={classnames(styles.points, styles.borders, "flex flex-column flex-1")}>
                        <div className={styles.sectionHeader}>
                            <div className={styles.banner}>
                                {configItem.configuration.columnHeader}
                            </div>
                            <div className={styles.extraInfo}><span>Halftime March 13-17</span></div>
                        </div>
                        <div className={styles.scoring}>
                            <h4>Scoring:</h4>
                            <ul className={styles.challenges}>
                                <li>Selling {configItem.configuration.doses} doses of NexGard® Chews = {configItem.configuration.points} points</li>
                            </ul>
                            <h4>Halftime:</h4>
                            <ul className={styles.challenges}>
                                <li>Selling {configItem.configuration.doses} doses of NexGard® Chews = {configItem.configuration.points*2} points</li>
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

PuppyBowlSetup.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}
