
// 100s
export const HTTP_STATUS_100 = 100; // Continue
export const HTTP_STATUS_101 = 101; // Switching Protocol
export const HTTP_STATUS_102 = 102; // Processing
export const HTTP_STATUS_103 = 103; // Early Hints

// 200s
export const HTTP_STATUS_200 = 200; // OK
export const HTTP_STATUS_201 = 201; // Created
export const HTTP_STATUS_202 = 202; // Accepted
export const HTTP_STATUS_203 = 203; // Non-Authoritative Information
export const HTTP_STATUS_204 = 204; // No Content
export const HTTP_STATUS_205 = 205; // Reset Content
export const HTTP_STATUS_206 = 206; // Partial Content
export const HTTP_STATUS_207 = 207; // Multi-Status
export const HTTP_STATUS_208 = 208; // Already Reported
export const HTTP_STATUS_226 = 226; // IM Used

// 300s
export const HTTP_STATUS_300 = 300; // Multiple Choice
export const HTTP_STATUS_301 = 301; // Moved Permanently
export const HTTP_STATUS_302 = 302; // Found
export const HTTP_STATUS_303 = 303; // See Other
export const HTTP_STATUS_304 = 304; // Not Modified
export const HTTP_STATUS_305 = 305; // Use Proxy
export const HTTP_STATUS_306 = 306; // Unused
export const HTTP_STATUS_307 = 307; // Temporary Redirect
export const HTTP_STATUS_308 = 308; // Permanent Redirect

// 400s
export const HTTP_STATUS_400 = 400; // Bad Request
export const HTTP_STATUS_401 = 401; // Unauthorized
export const HTTP_STATUS_402 = 402; // Payment Required
export const HTTP_STATUS_403 = 403; // Forbidden
export const HTTP_STATUS_404 = 404; // Not Found
export const HTTP_STATUS_405 = 405; // Method Not Allowed
export const HTTP_STATUS_406 = 406; // Not Acceptable
export const HTTP_STATUS_407 = 407; // Proxy Authentication Required
export const HTTP_STATUS_408 = 408; // Request Timed Out
export const HTTP_STATUS_409 = 409; // Conflict
export const HTTP_STATUS_410 = 410; // Gone
export const HTTP_STATUS_411 = 411; // Length Required
export const HTTP_STATUS_412 = 412; // Precondition Fail
export const HTTP_STATUS_413 = 413; // Payload Too Large
export const HTTP_STATUS_414 = 414; // URI Too Long
export const HTTP_STATUS_415 = 415; // Unsupported Media Type
export const HTTP_STATUS_416 = 416; // Range Not Satisfiable
export const HTTP_STATUS_417 = 417; // Exception Failed
export const HTTP_STATUS_418 = 418; // I'm a teapot
export const HTTP_STATUS_421 = 421; // Misdirected Request
export const HTTP_STATUS_422 = 422; // Unprocessable Entity
export const HTTP_STATUS_423 = 423; // Locked
export const HTTP_STATUS_424 = 424; // Failed Dependency
export const HTTP_STATUS_425 = 425; // Too Early
export const HTTP_STATUS_426 = 426; // Upgrade Required
export const HTTP_STATUS_428 = 428; // Preconditioned Requirement
export const HTTP_STATUS_429 = 429; // Too Many Requests
export const HTTP_STATUS_431 = 431; // Request Header Fields Too Long
export const HTTP_STATUS_451 = 451; // Unavailable for Legal Reasons

// 500s
export const HTTP_STATUS_500 = 500; // Internal Server Error
export const HTTP_STATUS_501 = 501; // Not Implemented
export const HTTP_STATUS_502 = 502; // Bad Gateway
export const HTTP_STATUS_503 = 503; // Service Unavailable
export const HTTP_STATUS_504 = 504; // Gateway Timeout
export const HTTP_STATUS_505 = 505; // HTTP Version not Supported
export const HTTP_STATUS_506 = 506; // Variant also Negotiates
export const HTTP_STATUS_507 = 507; // Insufficient Storage
export const HTTP_STATUS_508 = 508; // Loop Detected
export const HTTP_STATUS_510 = 510; // Not Extended
export const HTTP_STATUS_511 = 511; // Network Authentication Required
