import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import GreenlinePlanSetupForm from "./GreenlinePlanSetupForm";
import Button from "components/common/Button";
import * as WellnessApi from "api/WellnessApi";
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import first from "lodash/first";
import flatMap from "lodash/flatMap";
import groupBy from "lodash/groupBy";
import keys from "lodash/keys";
import map from "lodash/map";
import { handleErrorResponse } from "utils/request";
import toast from "utils/toast";

function GreenlinePlanSetupFormContainer(props) {
    const [planTemplateOptions, setPlanTemplateOptions] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [planTemplates, setPlanTemplates] = useState({});
    const [hasChanges, setHasChanges] = useState(false);

    const handleChange = ({name, value, service= null, enrollmentMapping = null}) => {
        setHasChanges(true);
        // Selected Plan Template Changed
        if (name === 'clinicPlanId') {
            setSelectedTemplate({...find(planTemplates, {'clinicPlanId':+value})})
        }
        // Service Properties Changed
        else if (service) {
            const serviceIdx = findIndex(selectedTemplate.services, (svc) => {
                return service.planServiceTypeId === svc.planServiceTypeId
            })

            const newServices = [
                ...selectedTemplate.services
            ]
            newServices[serviceIdx] = service

            setSelectedTemplate({
                ...selectedTemplate,
                services: newServices
            })
            return(selectedTemplate.services)
        }
        // Enrollment Mappings Changed
        else if (!!enrollmentMapping) {
            let mappingKey;
            if (enrollmentMapping.isNew) {
                if (!!enrollmentMapping?.id) {
                    mappingKey = enrollmentMapping.id;
                } else {
                    mappingKey = keys(selectedTemplate?.enrollmentMappings).length || 0;
                }
            } else {
                mappingKey = keys(selectedTemplate.enrollmentMappings).find(key => selectedTemplate.enrollmentMappings[key].id === enrollmentMapping.id);
            }

            const newEnrollmentMappings = {
                ...selectedTemplate.enrollmentMappings,
                [mappingKey]: {
                    ...enrollmentMapping
                }
            };
            setSelectedTemplate({
                ...selectedTemplate,
                enrollmentMappings: newEnrollmentMappings,
            });
        }
        // Core Plan Properties Changed
        else {
            const newInstance = {
                ...selectedTemplate,
                [name]: value,
            }
            setSelectedTemplate(newInstance);
        }
    };

    useEffect(() => {
        getClinicPlanTemplates(props.clinicId)
    }, [props.clinicId])

    useEffect(() => {
        if(props.selectedPlan) {
            setSelectedTemplate(props.selectedPlan)
        }
    }, [planTemplateOptions])

    const getClinicPlanTemplates = async () => {
        try {
            const resp = await WellnessApi.getClinicPlanTemplates(props.clinicId)
            if(resp.body) {
                const groupings = groupBy(resp.body, 'clinicPlanId');
                setPlanTemplates(map(groupings, flattenedTemplate => {
                    let base = first(flattenedTemplate)
                    let services = map(flattenedTemplate, svc => {
                        return svc
                    })

                    return {
                        ...base,
                        services: services
                    }
                }));
                setPlanTemplateOptions(map(Object.keys(groupings), templateId => { return {name: first(groupings[templateId]).planName, value: templateId}}));
            }
        }
        catch(error) {
            handleErrorResponse('getting plan templates', error)
        }
    }

    const handleSave = (e) => {
        e.preventDefault();
        if(props.addingPlan) {
            WellnessApi.addClinicWellnessPlan(props.clinicId, selectedTemplate, props.clinicPriceScheduleId)
                .then((res) => {
                    props.onAfterSave();
                })
                .catch((error) => {
                    if (error?.response?.body === 'Clinic Plan Instance already exists for Clinic Plan Id 2297') {
                        toast.error(`You already have a ${selectedTemplate.planName} plan.`)
                    } else {
                        handleErrorResponse("updating plan", error);
                    }
                })
                .finally(() => {
                    setHasChanges(false);
                })
        } else {
            WellnessApi.updateClinicWellnessPlan(props.clinicId, selectedTemplate, props.wellnessStartDate, props.houseAccountClinicDvmId)
                .then((res) => {
                    props.onAfterSave();
                })
                .catch((error) => {
                    if (error?.response?.body === 'Clinic Plan Instance already exists for Clinic Plan Id 2297') {
                        toast.error(`You already have a ${selectedTemplate.planName} plan.`)
                    } else {
                        handleErrorResponse("updating plan", error);
                    }
                })
                .finally(() => {
                    setHasChanges(false);
                })
        }
    }

    const isValid = true
    const usedPims = useMemo(() => (
        flatMap(planTemplates, f => {
            //Service Enrollment Mappings
            const enrollmentMappings = map(f.services, s => s.enrollmentMappings);
            //Plan Enrollment Mappings
            enrollmentMappings.push(f.enrollmentMappings);
            //Flatten the List of Mappings
            return flatMap(flatMap(enrollmentMappings, e => filter(e, m => m.id)), "clinicProductId");
        })
    ), [planTemplates]);

    return (
        <div className="padding-lg">
            <GreenlinePlanSetupForm
                onChange={handleChange}
                plan={selectedTemplate}
                templateOptions={planTemplateOptions}
                readOnly={props.readOnly}
                clinicId={props.clinicId}
                usedPims={usedPims}
                groupOptions={props.groupOptions}
                addingPlan={props.addingPlan}
            />
            <div>
                <div className="flex spaced-content justify-flex-end">
                    <Button
                        type="gray"
                        onClick={props.onAfterSave}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                        disabled={!isValid || !hasChanges}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </div>
    )
}

GreenlinePlanSetupFormContainer.defaultPropTypes = {
    readOnly: false,
    groupOptions: [],
}

GreenlinePlanSetupFormContainer.propTypes = {
    onAfterSave: PropTypes.func.isRequired,
    clinicId: PropTypes.number.isRequired,
    clinicPriceScheduleId: PropTypes.number.isRequired,
    selectedPlan: PropTypes.object,
    readOnly: PropTypes.bool,
    groupOptions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
    })),
    addingPlan: PropTypes.bool,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canEditClinicWellnessPlans = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewProductMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);

        return {
            canEditClinicWellnessPlans,
            canViewProductMapping,
        }
    }
)(GreenlinePlanSetupFormContainer);
