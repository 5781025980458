import request from "utils/request";
import getApiRoot from "./getApiRoot";

export function createPaymentOption(paymentOption) {
    return request()
        .post(`${getApiRoot()}/v1/billingpayment/cards`)
        .send(paymentOption)
}

export function getPaymentOptions(clinicId) {
    return request()
        .query({clinicId})
        .get(`${getApiRoot()}/v1/billingpayment/cards`)
        .send()
}

export function updateDefaultPaymentOption(clinicId, cardId) {
    return request()
        .query({clinicId})
        .put(`${getApiRoot()}/v1/billingpayment/cards/defaultcard/${cardId}`)
        .send()
}

export function deletePaymentOption(clinicId, cardId) {
    return request()
        .query({clinicId})
        .delete(`${getApiRoot()}/v1/billingpayment/cards/${cardId}`)
        .send()
}

export function getProducts() {
    return request()
        .get(`${getApiRoot()}/v1/admin/billing/products`)
        .send()
}

export function getPriceOptions(subscriptionId) {
    return request()
        .get(`${getApiRoot()}/v1/admin/billing/price/${subscriptionId}`)
        .send()
}

export function createClinicSubscription(clinicId, providerId, data) {
    return request()
        .post(`${getApiRoot()}/v1/clinicbilling/subscribe/${clinicId}/provider/${providerId}`)
        .send(data)
}

export function getClinicSubscriptions(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/clinicbilling/subscriptions/${clinicId}`)
        .send()
}
