exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".FrequentlyAskedQuestions__root ol{margin-bottom:2em;padding-left:1em}.FrequentlyAskedQuestions__root ol li{margin-bottom:1.25em}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/layout/elements/FrequentlyAskedQuestions/FrequentlyAskedQuestions.scss"],"names":[],"mappings":"AAAA,mCAAS,kBAAkB,gBAAgB,CAAC,sCAAY,oBAAoB,CAAC","file":"FrequentlyAskedQuestions.scss","sourcesContent":[".root ol{margin-bottom:2em;padding-left:1em}.root ol li{margin-bottom:1.25em}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "FrequentlyAskedQuestions__root"
};