import React, {useEffect, useState} from "react";
import AdminBase from "components/layout/AdminBase";
import * as SearchTypes from "constants/SearchTypes";
import * as ClinicActions from "actions/ClinicActions";
import * as CouponActions from "actions/CouponActions";
import * as MappingActions from "actions/MappingActions";
import * as MappingApi from "api/MappingApi";
import Mapping from "components/admin/widgets/Mapping";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {connect} from "react-redux";
import {MAPPING} from "constants/AdminControls";
import {getClinicInfo} from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import {Link} from "react-router-dom";
import classnames from "classnames";
import { includes } from "lodash";
import {handleErrorResponse} from "utils/request";


function ClinicMapping(props) {
    const basePath = `/admin/clinic/${props.clinicId}/mapping`;
    const [loading, setLoading] = useState(false);
    const [enrolledInWellness, setEnrolledInWellness] = useState(false);
    const [enrolledInVetCheck, setEnrolledInVetCheck] = useState(false);

    useEffect(() => {
        if(props.canViewClinicWellnessPlans || props.canViewMapping) {
            props.getPIMs(props.clinicId);
        }

        if(!Object.keys(props.providers).length) {
            props.getProviderList();
        }
        if(props.canViewProductMapping && !props.brands.length) {
            props.getProductBrands({});
        }
    }, []);

    useEffect(() => {
        if(props.clinicId) {
            setLoading(true);
            MappingApi.getClinicWellnessEnrollment(props.clinicId)
                .then((res) => {
                    setEnrolledInWellness(res.body.enrolled);
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("checking clinic wellness enrollment", error);
                    setLoading(false);
                });

            // Check for VETCHECK
            MappingApi.getClinicVetCheckEnrollment(props.clinicId)
                .then((res) => {
                    setEnrolledInVetCheck(res.body.enrolled);
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("checking VetCheck enrollment", error);
                    setEnrolledInVetCheck(false);
                    setLoading(false);
                });
        }
    }, [props.clinicId]);

    return (
        <AdminBase
            collapsible
            searchConfig={SearchTypes.MAPPING_SEARCH}
            clinicId={props.clinicId}
            controls={MAPPING}
            docTitle={"Product Mapping"}
            overflowHidden
        >
            {props.canViewMapping ? (
                <div className="margin-bottom-lg flex flex-column height-100-perc padding-top-sm">
                    {/*TODO: Need to style this better*/}
                    <div className="flex flex-centered spaced-content flex-none">
                        {props.canViewProductMapping && (
                            <>
                                <Link
                                    className={classnames("btn btn-full btn-color-blue btn-sm text-center", {
                                        "hover" : !!includes(props.location.pathname, "pharmacy")
                                    })}
                                    to={`${basePath}/pharmacy`}
                                >
                                    Pharmacy Mapping
                                </Link>
                                <Link
                                    className={classnames("btn btn-full btn-color-blue btn-sm text-center", {
                                        "hover" : !!includes(props.location.pathname, "food")
                                    })}
                                    to={`${basePath}/food`}
                                >
                                    Food Mapping
                                </Link>
                            </>
                        )}
                        {props.canViewVaccineMapping && (
                            <Link
                                className={classnames("btn btn-full btn-color-blue btn-sm text-center", {
                                    "hover" : includes(props.location.pathname, "vaccines")
                                })}
                                to={`${basePath}/vaccines`}
                            >
                                Vaccine Mapping
                            </Link>
                        )}
                        {props.canViewExamServiceMapping && (
                            <Link
                                className={classnames("btn btn-full btn-color-blue btn-sm text-center", {
                                    "hover" : includes(props.location.pathname, "services")
                                })}
                                to={`${basePath}/services`}
                            >
                                Service Mapping
                            </Link>
                        )}
                        {(props.canViewVetCheckMapping && enrolledInVetCheck) && (
                            <Link
                                className={classnames("btn btn-full btn-color-blue btn-sm text-center", {
                                    "hover" : includes(props.location.pathname, "vetcheck")
                                })}
                                to={`${basePath}/vetcheck`}
                            >
                                VetCheck Content
                            </Link>
                        )}
                        {(props.canViewWellnessMapping && enrolledInWellness) && (
                            <Link
                                className={classnames("btn btn-full btn-color-blue btn-sm text-center", {
                                    "hover" : includes(props.location.pathname, "wellness")
                                })}
                                to={`${basePath}/wellness`}
                            >
                                Wellness Mapping
                            </Link>
                        )}

                    </div>
                    <hr className="flex-none full-width"/>
                    {(!loading) ? (
                        <Mapping
                            clinicId={props.clinicId}
                            map={props.map}
                            enrolledInVetCheck={enrolledInVetCheck}
                            enrolledInWellness={enrolledInWellness}
                        />
                    ) : (
                        <SpinnerTakeover show/>
                    )}
                </div>
            ) : (
                <AccessDenied/>
            )}
        </AdminBase>
    )
}
export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const map = ownProps.match.params.pageName;
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;

        // Permissions
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewProductMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);
        const canViewVaccineMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VACCINE_MAPPING, userProfile);
        const canViewExamServiceMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.EXAM_SERVICE_MAPPING, userProfile);
        const canViewWellnessMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.WELLNESS_MAPPING, userProfile);
        const canViewVetCheckMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);
        const canViewMapping = canViewProductMapping || canViewVaccineMapping || canViewExamServiceMapping || canViewWellnessMapping || canViewVetCheckMapping;
        return {
            clinicId,
            map,
            clinic: clinicInfo,
            providers: state.entities.providers,
            brands: state.entities.brands,
            search: state.entities.genericSearch,
            // Permissions
            canViewClinicWellnessPlans,
            canViewProductMapping,
            canViewVaccineMapping,
            canViewExamServiceMapping,
            canViewWellnessMapping,
            canViewVetCheckMapping,
            canViewMapping,
        }
    },
    (dispatch) => ({
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        getProductBrands: (query) => dispatch(ClinicActions.getProductBrands(query)),
        getPIMs: (clinicId) => dispatch(MappingActions.getPIMs(clinicId)),
    }),
)(ClinicMapping);
