import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import ProviderBase from "components/layout/ProviderBase";
import InvoiceLineItems from "components/admin/widgets/InvoiceLineItems";
import Button from "components/common/Button";
import {getNodeId} from "utils/AdminData";
import getProviderId from "utils/getProviderId";
import * as ProviderLinks from "utils/ProviderLinks";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";

function AdminInvoiceLineItemsPage(props) {
    const goToWellnessSettings = () => {
        props.history.push(ProviderLinks.getLinkLocation("wellness-settings", {
            providerId: props.providerId,
            nodeId: props.nodeId,
            isAdmin: props.isAdmin,
            clinicId: props.clinicId,
        }));
    }

    return (
        <ProviderBase
            pageTitle="Clinic Invoice Line Items"
            location={props.location}
            // searchConfig={SearchTypes.INVOICE_LINE_ITEM}
            clinicId={props.clinicId}
            collapsible
            nodeId={props.nodeId}
            providerId={props.providerId}
        >
            <div className="topLine flex spaced-content">
                <div className="flex-1 flex">
                    <Button
                        small
                        icon
                        className="margin-right-x-sm"
                        type="primary"
                        onClick={goToWellnessSettings}
                    >
                        <i className="fa fa-reply"/> Back To Clinic Menu
                    </Button>
                </div>
            </div>
            {props.canViewClinicManagement ? (
                <InvoiceLineItems clinicId={props.clinicId}/>
            ) : (
                <AccessDenied/>
            )}
        </ProviderBase>
    );
}

const connector = connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const clinicId = Number(params?.clinicId) || null;
        const nodeId = getNodeId(state, ownProps);
        const providerId = getProviderId(params, userProfile);

        // Permissions
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);

        return {
            nodeId,
            providerId,
            clinicId,

            // Permissions
            canViewClinicManagement
        }
    }
);

export default compose(
    withRouter,
    connector
)(AdminInvoiceLineItemsPage);
