import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./ProviderReports.scss";

import InformationTile from "components/common/InformationTile";
import Modal from "components/common/Modal";
import SpinnerTakeover from "components/common/SpinnerTakeover";

import * as ProviderApi from 'api/ProviderApi';

import * as ModalTypes from "constants/ModalTypes";
import * as UserPermissions from "constants/UserPermissions";
import {NHO} from "constants/OrgNodeTypes";

import {getNodeId} from "utils/AdminData";
import {getProviderBasePath} from "utils/routingUtils";
import {handleErrorResponse} from "utils/request";
import getProviderId from "utils/getProviderId";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";


const ManufacturerReports = (props) => {
    const [providerReports, setProviderReports] = useState([]);
    const [dashboard, setDashboard] = useState(null);

    const basePath = getProviderBasePath(props.path, props.providerId, props.nodeId);

    useEffect(() => {
        ProviderApi.getProviderReports(props.providerId)
            .then((res) => {
                setProviderReports(res.body.dashboards);
            })
            .catch((error) => {
                handleErrorResponse("getting provider reports", error);
            });
    }, [dashboard]);

    const handleTileClick = (dashboardId) => {
        setDashboard(providerReports.find(report => report.dashboardId === dashboardId))
    }

    const renderReportTiles = () => {
        const tiles = providerReports.map(dashboard => {
            return (
                <div
                    key={`dashboard_${dashboard.dashboardId}`}
                    onClick={() => handleTileClick(dashboard.dashboardId)}
                >
                        <InformationTile
                            title={dashboard.reportName}
                        >
                        <div className={styles.tileInfo}>
                            <div className={styles.tileIcon}>
                                <i className={dashboard.iconUrl}/>
                            </div>
                            <div className={styles.description}>
                                {dashboard.description}
                            </div>
                        </div>
                        </InformationTile>
                </div>
            );
        });

        if(props.canViewProviderPayoutReport) {
            tiles.unshift(
                <div key="providerCheckSummary">
                    <Link to={`${basePath}/reports/payout-report`}>
                        <InformationTile
                            title="Payout Report"
                        >
                            <div className={styles.tileInfo}>
                                <div className={styles.tileIcon}>
                                    <i className={"fa-solid fa-money-check-pen"}/>
                                </div>
                                <div className={styles.description}>
                                    Manufacturer Check Totals By Clinic.
                                </div>
                            </div>
                        </InformationTile>
                    </Link>
                </div>
            );
        }

        return tiles;
    }

    return (
        <div className={styles.root}>
            <div className={styles.gridContainer}>
                <div className={styles.grid}>
                    {renderReportTiles()}
                </div>
            </div>
            <Modal
                show={!!dashboard}
                onClose={() => setDashboard(null)}
                modalTitle={`${dashboard?.reportName || ''}`}
                modalType={ModalTypes.FULLSCREEN}
                flexBody
            >
                <iframe
                    src={dashboard?.dashboardUrl || ''}
                    className={styles.manufacturerReport}
                />
            </Modal>
            <SpinnerTakeover show={!providerReports} />
        </div>
    );
}

const {number} = PropTypes;
ManufacturerReports.propTypes = {
    providerId: number.isRequired,
};

export default connect((state, ownProps)=> {
    const params = ownProps?.match?.params || {};
    const userProfile = state.user.userProfile;
    const providerId = getProviderId(params, userProfile);
    const nodeId = getNodeId(state, ownProps);
    const node = state.entities.nodes?.[nodeId];
    const canViewProviderPayoutReport = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_PAYOUT_REPORT, userProfile);
    return {
        canViewProviderPayoutReport,
        node,
        nodeId,
        providerId
    }
}, null)(ManufacturerReports);
