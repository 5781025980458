import map from "lodash/map";

export const ACTIVE = "Active";
export const PAST_DUE = "Past Due";
export const EXPIRED = "Expired";
export const CANCELLED = "Cancelled";

export const STATUSES = [ACTIVE, PAST_DUE, EXPIRED, CANCELLED];

export function createPlanOptionsObject(arrayOfStatusIds) {
    return map(arrayOfStatusIds, status => {
        return {
            name: status,
            value: status,
        };
    });
}
