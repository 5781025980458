exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ClinicUsers__root{position:relative;-ms-flex:1;flex:1}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/clinic/pages/ClinicUsers.scss"],"names":[],"mappings":"AAAA,mBAAM,kBAAkB,WAAW,MAAM,CAAC","file":"ClinicUsers.scss","sourcesContent":[".root{position:relative;-ms-flex:1;flex:1}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "ClinicUsers__root"
};