import * as Sentry from "@sentry/react";
import * as LogLevels from "constants/LogLevels";

const getLogLevel = () => {
    return window.GL_ENV?.logLevel || LogLevels.INFO;
}
const logger = {
    log: (message, extra="", useSentry = false) => {
        const logLevel = getLogLevel();
        if(LogLevels.checkLevel(logLevel, LogLevels.DEBUG)) {
            console.log(green(message), extra);
            if (useSentry) Sentry.captureMessage(message);
        }
    },
    info: (message, extra="", useSentry = false) => {
        const logLevel = getLogLevel();
        if (LogLevels.checkLevel(logLevel, LogLevels.INFO)) {
            console.info(green(message), extra);
            if (useSentry) Sentry.captureMessage(message);
        }
    },
    warn: (message, extra="", useSentry = false) => {
        const logLevel = getLogLevel();
        if(LogLevels.checkLevel(logLevel, LogLevels.WARN)) {
            console.warn(yellow(message), extra);
            if (useSentry) Sentry.captureMessage(message);
        }
    },
    error: (message, error, useSentry = false) => {
        const logLevel = getLogLevel();
        if(LogLevels.checkLevel(logLevel, LogLevels.ERROR)) {
            console.error(red(message), error);
            if (useSentry) Sentry.captureException(error);
        }
    }
}

export function green(text) {
    return `\x1b[32m${text}\x1b[0m`;
}

export function yellow(text) {
    return `\x1b[93m${text}\x1b[0m`;
}

export function red(text) {
    return `\x1b[91m${text}\x1b[0m`;
}

export const chalk = { green, yellow, red };

export default logger;
