exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PlanStatusFilterDropdown__root{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/wellness/widgets/PlanStatusFilterDropdown.scss"],"names":[],"mappings":"AAAA,gCAAM,oBAAoB,aAAa,uBAAuB,mBAAmB,sBAAsB,kBAAkB,CAAC","file":"PlanStatusFilterDropdown.scss","sourcesContent":[".root{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "PlanStatusFilterDropdown__root"
};