import { combineReducers } from "redux";
import applicationState from "./applicationState";
import demo from "./demo";
import entities from "./entities";
import user from "./user";
import wellness from "./wellness";

export default combineReducers({
    applicationState,
    demo,
    entities,
    user,
    wellness,
});
