import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import filter from "lodash/filter";
import styles from "./EligibilityTrackerQRCode.scss";
import { getEligibilityTrackerQRCode } from "api/ClinicApi";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import Image from "components/common/Image";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import logger from "utils/logger";
import { downloadFile, imageFile } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import classnames from "classnames";
import { ENROLLED } from "constants/ProgramStatuses";

function EligibilityTrackerQRCode(props) {
    const [qrCodeRes, setQRCodeRes] = useState(null);
    const [qrCode, setQRCode] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleLoadEligibilityTrackerQRCode = () => {
        setLoading(true);
        getEligibilityTrackerQRCode(props.clinicId)
            .then((res) => {
                setQRCodeRes(res);
                const url = imageFile(res, "jpg", true);
                setQRCode(url);
            })
            .catch((err) => {
                logger.error("Error loading Eligibility Tracker QR Code:", err);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if (props.hasEligibilityTracker) {
            handleLoadEligibilityTrackerQRCode();
        }
    }, []);

    if (!props.hasEligibilityTracker) {
        return <AccessDenied />;
    }
    const handleDownload = () => {
        downloadFile(qrCodeRes, "jpeg");
    };

    if (loading) {
        return <SpinnerTakeover show />
    }

    return (
        <div className={classnames("flex flex-column flex-centered", styles.root, {[styles.inline]: props.displayInline})}>
            <div className={styles.downloadPrompt}>
                <Button
                    className={styles.downloadButton}
                    onClick={handleDownload}
                    iconOnly
                    title="Download Eligibility Tracker QR code"
                >
                    <i className="fa fa-download" />
                </Button>
                <div className="flex-1">Click here to download the Eligibility Tracker QR <br/> code to put in your clinic!</div>
            </div>

            {!!qrCode && (
                <Image
                    className={styles.qrCodeImage}
                    src={qrCode}
                    alt="Clinic Eligibility Tracker QR code"
                />
            )}
        </div>
    )
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;

        return {
            userProfile,
            hasEligibilityTracker: !!filter(state.entities.clinicPrograms?.[ownProps.clinicId], { programId: 7, status: ENROLLED })?.length || false,
        };
    },
)(EligibilityTrackerQRCode);

EligibilityTrackerQRCode.propTypes = {
    displayInline: PropTypes.bool,
};
