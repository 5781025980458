import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import includes from "lodash/includes";
import styles from "./WellnessHistory.scss";
import * as ClinicActions from "actions/ClinicActions";
import AccessDenied from "components/common/AccessDenied";
import ClinicBase from "components/layout/ClinicBase";
import JoyRide from "components/tour/widgets/JoyRide";
import ProcessedWellnessVisits from "components/wellness/widgets/ProcessedWellnessVisits";
import {
    TOUR_PET_PLAN_TRACKING_PART_1,
    TOUR_PET_PLAN_TRACKING_TRANSITION,
    TOUR_WELLNESS_HISTORY_PART_2
} from "utils/demoTourHelpers";
import { clinicHasWellness, getClinicInfo } from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { CLINIC_TAGS } from "constants/AdminControls";
import * as DemoTourStates from "constants/DemoTourStates";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

function WellnessHistory(props) {
    // TOUR ONLY
    const [tourSection, setTourSection] = useState(null);
    const getTourSection = () => {
        // Don't set the tourSection if the tour is complete
        let currentSection = null;
        if (props.tourStarted) {
            if (!includes(props.completedTourSections, TOUR_WELLNESS_HISTORY_PART_2)) {
                currentSection = TOUR_WELLNESS_HISTORY_PART_2;
            } else if (!includes(props.completedTourSections, TOUR_PET_PLAN_TRACKING_TRANSITION)) {
                currentSection = TOUR_PET_PLAN_TRACKING_TRANSITION;
            } else if (!includes(props.completedTourSections, TOUR_PET_PLAN_TRACKING_PART_1)) {
                currentSection = TOUR_PET_PLAN_TRACKING_PART_1;
            }
        }
        setTourSection(currentSection);
    }
    useEffect(() => {
        getTourSection();
    }, [props.completedTourSections]);
    // END

    useEffect(() => {
        props.getProviderLocations(props.clinicId);
    }, [props.clinicId]);

    return (
        <ClinicBase
            searchConfig={SearchTypes.WELLNESS_VISITS}
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle="Wellness History"
        >
            <div className={styles.root}>
                {props.canViewClinicWellnessAudit && props.hasWellness ? (
                    <ProcessedWellnessVisits
                        clinicId={props.clinicId}
                        canGLWellness={props.canGLWellness}
                    />
                ) : (
                    <AccessDenied />
                )}
                <JoyRide
                    key={tourSection}
                    tourSection={tourSection}
                />
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canGLWellness = clinicInfo?.programs?.Wellness?.providers.includes(69);
        return {
            clinicId,
            canViewClinicWellnessAudit,
            completedTourSections: state.demo.completedTourSections,
            hasWellness: clinicHasWellness(state, clinicId),
            tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),
            canGLWellness,
        }
    }, (dispatch) => ({
        getProviderLocations: (clinicId) => dispatch(ClinicActions.getProviderLocations(clinicId)),
    })
)(WellnessHistory);
