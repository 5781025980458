import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./LeafPartyLeaderboard.scss";
import find from "lodash/find";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import { windowSizeContext } from "AppRoot";
import { addCommasToNumber } from "utils/numeric";

const ANIMATION_DURATION = 2500; // Make sure this matches up with animation duration in css file
const ANIMATION_CYCLE_SPEED = 250; // How fast the animation changes frames

export default function LeafPartyLeaderboard(props) {
    const context = useContext(windowSizeContext) || {};
    const [showPoints, setShowPoints] = useState(false);
    const [isFrame2, setIsFrame2] = useState(false);
    const sortedPuppyData = sortBy(props.puppyData, "Place");
    const topTenPuppyData = sortedPuppyData.slice(0, 10);
    const currentClinicPuppyData = !!props.currentClinicId ? find(sortedPuppyData, { clinicId: props.currentClinicId }) : null;
    const currentClinicAfter10 = !!currentClinicPuppyData && currentClinicPuppyData.Place > 10;

    const fieldRef = useRef();
    const grassSize = useMemo(() => {
        if (fieldRef.current) {
            const fieldWidth = fieldRef.current.getBoundingClientRect().width;
            const grass = Math.round(.699 * fieldWidth); // This seems to yield the closest results;
            const offset = Math.floor(grass/200);
            return { width: `${grass}px`, offset: `${offset}px` };
        }
        return "50vw";
    }, [fieldRef.current, context.windowWidth]);

    const showTeamPoints = () => {
        setShowPoints(true);
    };

    const setAnimationFrame = (animationFrame) => {
        if (animationFrame === 0) {
            setIsFrame2(false);
        } else {
            setIsFrame2(true);
        }
    };

    const playAnimationCycle = (durationCounter=0, animationFrame=0) => {
        setTimeout(() => {
            setAnimationFrame(animationFrame);

            if (durationCounter < ANIMATION_DURATION) {
                const nextAnimationFrame = animationFrame === 0 ? 1 : 0;
                playAnimationCycle(durationCounter + ANIMATION_CYCLE_SPEED, nextAnimationFrame);
            } else {
                // Animation is done
                showTeamPoints();
            }
        }, ANIMATION_CYCLE_SPEED);
    };

    useEffect(() => {
        if (props.isAnimated) {
            playAnimationCycle(); // Start the animation loop
        } else {
            setAnimationFrame(0);
            showTeamPoints();
        }
    }, [props.isAnimated]);

    if (!props.puppyData.length) return null;

    const renderTeamDetails = (pup, rowWidthPercentage, showDoses=false) => {
        let percentGrowthString = "0%";
        if (pup.PercentGrowth !== null && !isNaN(pup.PercentGrowth)) {
          percentGrowthString = `${Math.round(100 + pup.PercentGrowth).toFixed(0)}%`;
        }

        return (
            <div className={styles.puppyRowContent}>
                {showDoses ? (
                    <>
                        <div className="flex align-center">
                            <div className={classnames(styles.puppyRanking, "flex-row")}>
                                <span className={styles.poundSign}>#</span>
                                {pup.Place}
                            </div>
                            <div className={styles.teamName}>
                                {pup.name}
                            </div>
                        </div>
                        <div
                            className={styles.teamPoints}
                            style={{
                                ...(rowWidthPercentage < 30) ? {
                                    position: "absolute",
                                    left: "calc(40px + 100%)",
                                    top: "-7px",
                                } : {},
                                display: showPoints ? "initial" : "none",
                            }}
                        >
                            {addCommasToNumber(pup.Doses) || 0} doses, {percentGrowthString} VLSD
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex align-center">
                            <div className={classnames(styles.puppyRanking, "flex-row")}>
                                <span className={styles.poundSign}>#</span>
                                {pup.Place}
                            </div>
                            <div className={styles.teamName}>
                                {pup.ClinicAlias}
                            </div>
                        </div>
                        <div className={styles.teamPoints}>
                            {percentGrowthString} VLSD
                        </div>
                    </>
                )}
            </div>
        )

    };

    const renderPuppyRow = (pup) => {
        const isCurrentTeam = pup.ClinicId === props.currentClinicId;
        const pointsVisible = isCurrentTeam || props.showAllPoints;
        let rowWidthPercentage = 50 + pup.PercentGrowth / 2;
        let isWinning = false;
        let hasMadeItToTheEndZone = false;
        if (rowWidthPercentage >= 100) {
          isWinning = true;
          rowWidthPercentage = 100;
          hasMadeItToTheEndZone = true;
          console.log("Has made it to the ned zone");
        }

        if (pup.Place <= 10) {
            // TODO:
            // puppySprite = applyCustomPuppyColors(puppySprite, ranking);
        }

        return (
            <div
                id={`puppy_content_${pup.Place}`}
                className={classnames(styles.puppyRowAnimationContainer, "flex-align-center", {
                    [styles.animated]: props.isAnimated,
                    [styles.puppyRowCurrentTeam]: isCurrentTeam,
                })}
                style={{maxWidth: `${rowWidthPercentage}%`}}
            >
                {renderTeamDetails(pup, rowWidthPercentage, pointsVisible)}
                <div
                    className={classnames(styles.progressBarGrass, {
                        [styles.fieldStripe2]: pup.Place === 2 || pup.Place === 6 || pup.Place === 10,
                        [styles.fieldStripe3]: pup.Place === 3 || pup.Place === 7 || pup.Place === 11,
                        [styles.fieldStripe4]: pup.Place === 4 || pup.Place === 8 || pup.Place === 12,
                    })}
                    style={{ backgroundSize: grassSize.width, backgroundPosition: `${grassSize.offset} 0` }}

                />
                <div className={styles.progressBar} />
                <div className={classnames(styles.puppySprite, {
                    [styles.animating]: props.isAnimated,
                    [styles.winning]: isWinning,
                    [styles.transparent]: rowWidthPercentage < 60,
                    [styles.frame2]: isFrame2,
                })}>
                    {hasMadeItToTheEndZone && showPoints ? (
                        <img
                            src="../../../../../../plugins/puppy_leaf_party_leaderboard/assets/puppy_sprite_winning.svg"
                            alt={pup.ClinicAlias}
                        />
                    ) : (
                        <img
                            src="../../../../../../plugins/puppy_leaf_party_leaderboard/assets/puppy_sprite_raking.svg"
                            alt={pup.ClinicAlias}
                        />
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className={styles.fieldContainer} id="field-container" ref={fieldRef}>
            <div className={styles.fieldSizer}>
                <div className={styles.fieldImageSizer} >
                    <img
                        className={styles.fieldBackgroundImage}
                        src="../../../../../../plugins/puppy_leaf_party_leaderboard/assets/field_leaderboard_bg.jpg"
                        alt="Field"
                    />

                    <div className={styles.fieldContent} id="field-content">
                        <div className={styles.puppies}>
                            {map(topTenPuppyData, (pup) => (
                                <div
                                    key={pup.clinicId}
                                    id={`puppy_${pup.Place}`}
                                    className={classnames(styles.puppyRow, {
                                        [styles.puppyRowSmaller]: currentClinicAfter10,
                                    })}
                                >
                                    {renderPuppyRow(pup)}
                                </div>
                            ))}
                            {/* Conditionally shown if the current clinic is not in the top 10 */}
                            {currentClinicAfter10 && (
                                <div
                                    id="current_clinic_rank_above_10"
                                    className={classnames(styles.puppyRow, {
                                        [styles.rankAbove10Hidden]: !currentClinicAfter10,
                                        [styles.printing]: props.printing,
                                    })}
                                >
                                    {renderPuppyRow(currentClinicPuppyData)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.scoreContainer}>
                <div className={classnames(styles.leafScores)}>
                    <span>20%</span>
                    <span>40%</span>
                    <span>60%</span>
                    <span>80%</span>
                    <span>100%</span>
                    <span>120%</span>
                    <span>140%</span>
                    <span>160%</span>
                    <span>180%</span>
                    <span>200%</span>
                </div>
            </div>
        </div>
    );
}

LeafPartyLeaderboard.defaultProps = {
    currentClinicId: undefined,
    isAnimated: false,
    printing: false,
    puppyData: [],
    showAllPoints: false,
};

LeafPartyLeaderboard.propTypes = {
    currentClinicId: PropTypes.number,
    isAnimated: PropTypes.bool,
    printing: PropTypes.bool,
    puppyData: PropTypes.array,
    showAllPoints: PropTypes.bool,
};
