exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".UserPayments__paymentOptions{-ms-flex:1;flex:1}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/user/pages/UserPayments/UserPayments.scss"],"names":[],"mappings":"AAAA,8BAAgB,WAAW,MAAM,CAAC","file":"UserPayments.scss","sourcesContent":[".paymentOptions{-ms-flex:1;flex:1}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"paymentOptions": "UserPayments__paymentOptions"
};