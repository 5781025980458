import React from "react";
import {
	MAIN_COLOR,
	SECONDARY_COLOR,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./PuppyCycling.scss";


export default function PuppyCycling(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Cycle_-_Puppy"
			data-name="Cycle - Puppy"
			// xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 800 300"
		>
			<g id="Puppy_Cycling02">
				<g id="Puppy_Cycling02_Lower">
					<path className={styles.cls10}
					      d="M89.15,278.13c0,6.55,49.71,11.87,111.03,11.87,61.32,0,111.03-5.31,111.03-11.87,0-6.56-49.71-11.87-111.03-11.87-61.32,0-111.03,5.31-111.03,11.87Z"/>
					<path className={styles.cls13}
					      d="M156.12,177.11c6.93,11.62,18.85,49.42,10.24,68.76-3.99,8.97,5.23,15.61,11.53,12.49,6.3-3.11-4.57-8.53.76-15.12,21.34-24.58,19.97-55.32,13.99-70.88-8.74-22.75-43.56-7.05-36.53,4.74Z"/>
					<path className={styles.cls13}
					      d="M247.05,113.54c11,12.07-4.21,37.44,18.14,40.47,7.7,1.04,8.08,15.1-9.7,12.69-24.98-3.39-29.66-20.07-27.88-43.32.74-9.61,15.37-14.31,19.43-9.84Z"/>
					<g>
						<path className={styles.cls8}
						      d="M123.06,278.07c-23.14,1.26-43-16.53-44.26-39.68-1.26-23.14,16.53-43,39.68-44.26,23.14-1.26,43,16.53,44.26,39.68s-16.53,43-39.68,44.26ZM118.75,199.07c-20.42,1.12-36.12,18.64-35.01,39.06,1.12,20.42,18.64,36.12,39.06,35.01s36.12-18.64,35.01-39.06-18.64-36.12-39.06-35.01Z"/>
						<path className={styles.cls8}
						      d="M270.12,277.46c-23.14,1.26-43-16.53-44.26-39.68-1.26-23.14,16.53-43,39.68-44.26s43,16.53,44.26,39.68-16.53,43-39.68,44.26ZM265.8,198.46c-20.42,1.12-36.12,18.64-35.01,39.06,1.12,20.42,18.64,36.12,39.06,35.01s36.12-18.64,35.01-39.06c-1.12-20.42-18.64-36.12-39.06-35.01Z"/>
						<circle className={styles.cls3} cx="267.82" cy="235.49" r="33.98"/>
						<circle className={styles.cls3} cx="120.77" cy="236.1" r="33.98"/>
						<path className={styles.cls2}
						      d="M241.03,177.8l.08,1.44-63.57,9.98c-.5.08-.98.29-1.38.6l-58,45.88c-.93.74-1.33,1.96-1.02,3.1s1.28,2,2.45,2.16l61.08,8.56c.19.03.38.03.57.02.67-.04,1.32-.3,1.83-.76l58.92-53.42.1,1.91,9.38-.51-1.06-19.48-9.38.51ZM175.85,197.63l2.66,45.6-51.23-7.18,48.57-38.42ZM184.25,239.7l-2.63-45.12,59.82-9.39.14,2.55-57.33,51.97Z"/>
						<path className={styles.cls2}
						      d="M266.92,237.06c-.78.04-1.58-.18-2.26-.68-9.7-7.23-19.32-26.17-21.27-37.01l7.72-.53c1.69,9.39,9.73,26.06,17.69,31.99,1.53,1.14,1.85,3.31.71,4.84-.64.86-1.6,1.33-2.59,1.39Z"/>
						<g>
							<path className={styles.cls9}
							      d="M182.45,242.44c.3.04.6.16.86.35.81.61.98,1.76.37,2.57l-10.74,14.33-2.94-2.2,10.74-14.33c.41-.55,1.07-.8,1.71-.72Z"/>
							<rect className={styles.cls9} x="167.98" y="254.8" width="4.13" height="11.24" rx=".96" ry=".96"
							      transform="translate(-140.23 238.93) rotate(-52.89)"/>
						</g>
						<polygon className={styles.cls8}
						         points="163.42 190.59 193.52 183.14 193.18 176.86 162.76 178.52 163.42 190.59"/>
						<circle className={styles.cls4} cx="181.62" cy="245.26" r="11.85"/>
						<path className={styles.cls9}
						      d="M241.86,175.62l-.95-17.4,28.58-1.56.53,2.2c1.96,8.08.04,12.24-1.91,14.31-1.66,1.76-4.85,3.63-10.66,2.57-1.48-.27-2.46-1.68-2.19-3.16.27-1.48,1.69-2.46,3.16-2.19,1.88.34,4.4.45,5.73-.96,1.18-1.25,1.53-3.78,1.02-7.1l-16.28.89.65,11.97-7.68.42Z"/>
						<g>
							<path className={styles.cls9}
							      d="M181.31,247.07c-.3-.04-.6-.16-.86-.35-.81-.61-.98-1.76-.37-2.57l10.74-14.33,2.94,2.2-10.74,14.33c-.41.55-1.07.8-1.71.72Z"/>
							<rect className={styles.cls9} x="191.64" y="223.47" width="4.13" height="11.24" rx=".96" ry=".96"
							      transform="translate(-105.86 245.37) rotate(-52.89)"/>
						</g>
					</g>
					<g>
						<path className={styles.cls12}
						      d="M138.31,143.21c1.89,1.21,3.87,2.32,5.96,3.25,2.5,1.12,18.44,3.56,14.77,8.49-.56.75-1.45,1.23-2.38,1.39-3.16.55-6.4,1.03-9.51.73-5.13-.49-9.41-3.01-12.94-6.29.01-1.3.28-2.6.83-3.79.71-1.54,1.87-2.86,3.27-3.79Z"/>
						<path className={styles.cls11}
						      d="M127.5,137.09c.36-.12.83-.16,1.42-.1,2.73.25,5.03,3.21,7.08,4.66.76.54,1.54,1.05,2.32,1.56-1.41.94-2.56,2.26-3.27,3.79-.55,1.18-.82,2.48-.83,3.79-1.56-1.46-2.98-3.06-4.26-4.71-.89-1.15-5.59-7.97-2.45-8.98Z"/>
					</g>
					<path className={styles.cls11}
					      d="M156.06,175.77c16.77,30.33,50.03-2,57.95-15.49,2.97-5.05,29.58-14.97,36.63-26.98,6.78-11.55-14.08-33.24-17.58-34.56-17.56-6.65-42.59,3.21-59.27,17.09-13.68,11.39-30.87,36.17-17.73,59.94Z"/>
					<path className={styles.cls12}
					      d="M155.76,139.12c5.65-.69,9.99,9.49,6.14,16.46-4.1,7.43-6.28,16.47-9.21,11.97-4.99-10.38.2-24,3.07-28.43Z"/>
					<path className={styles.cls11}
					      d="M221.81,124.25c-.01,10.98,1.26,32.61,15.07,39.99,20.03,10.7,33.7,4.25,33.9-.78.46-11.51-7.35.41-17.45-11.47-5.52-6.49-4.33-14.84-7.13-25.73-2.33-9.05-24.38-6.19-24.38-2.01Z"/>
				</g>
				<path
					id="Shirt"
					className={styles.cls2}
					d="M233.1,90.61c17.42,1.28,21.61,26.65,19.96,36.78-.54,3.34-2.3,5.97-3.86,8-8.32-19.34-34.31-1.44-24.1,17.26-5.05,3.64-9.47,8.71-13.76,12.5-17.23-7.26-31.73-27.52-36.34-50.62,10.55-9.34,38.37-25.37,58.1-23.93Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes">
					<path
						className={styles.cls14}
						d="M185.83,122.39c2.18,6.05,4.98,11.74,8.32,16.9,5.39,8.32,15.52,15.52,23.12,20.29,1.11-1.12,2.4-2.32,3.01-2.87-7.3-4.48-17.63-11.59-22.81-19.58-5.69-8.79-9.73-19.23-11.71-30.29-.57.3-2.87,1.67-3.61,2.2.9,4.58,2.13,9.04,3.68,13.34Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls14}
						d="M224.27,153.28l1.38-.93c-2.99-4.43-3.24-10.12-.31-15.46,3.1-5.66,8.94-9.49,13.9-9.11,4.06.31,7.22,3.29,9.15,8.6,0,0,1.07-1.23,1.4-1.85-2.83-6.74-7.17-8.48-10.4-8.73-5.78-.44-12.27,3.72-15.78,10.13-3.31,6.05-3.07,12.37.67,17.34Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Cycling02_Mid">
					<path className={styles.cls13}
					      d="M184.52,156.3c4.75-5.75,22.06,5.89,25.44,9.74-1.42,1.86-1.55,2.77-4.67,4.49-10-2.72-25.17-8.9-20.76-14.23Z"/>
					<path className={styles.cls11}
					      d="M183.74,153.92c12.25-.71,29.56,14.59,32.59,24.88,3.97,13.53-.81,24.13-17.16,36.07,13.58,4.98,10.14,16.32.04,15.5-1.22-.1-7.91-5.53-10.07-7.51-4.05-3.28-2.42-3.61-1.24-10,4.66-25.3-26.68-18.74-29.11-36.87-1.66-12.32,16.19-21.57,24.95-22.07Z"/>
					<path className={styles.cls6}
					      d="M252.7,127.38c4.77-.6,8.99-2.19,12.17-6.47,8.36-11.22-.41-19.82-8.22-26.59-7.35-6.37-20.37-16.72-30.05-10.64-23.94,15.04,8.18,45.93,26.09,43.69Z"/>
					<g>
						<path className={styles.cls12}
						      d="M310.67,52.64c1.9,4.93,2.36,25.43-3.42,26.67-9.06,1.94-36.12-14.61-38.89-15.99-3.48-1.73-6.37-4.75-6.68-7.84-.52-5.02,6.25-9.53,10.77-11.17,17.54-6.39,32.22-7.23,38.22,8.33Z"/>
						<g>
							<path className={styles.cls11}
							      d="M272.46,129.76c-.97-4.84-.3-10,1.89-14.43,2.26-4.57,6.1-8.49,6.94-13.51,1.13-6.84-3.63-13.15-8.37-18.22-7.19-7.69-15.01-14.8-23.35-21.23-2.13-1.64-4.36-3.26-6.72-4.56,5.2-2.1,10.76-3.4,16.51-4.13.5.51,1.07.97,1.62,1.37,3.57,2.6,7.38,4.9,10.57,7.97,6.68,6.43,10.1,15.63,14.68,23.58,2.15,3.73,5.04,7.41,9.11,8.56,4.06,1.15,8.3-.45,12.49-.72,2.63-.17,5.26.26,7.75,1.15,2.76,2.95,5.07,6.19,5.49,9.55,1.06,8.43-2.28,18.45-7.93,22.82-9.55,7.38-17.89,8.61-27.34,9.61-4.04.43-8.01.3-11.85-.24-.11-2.47-.99-5.1-1.48-7.57Z"/>
							<path className={styles.cls7}
							      d="M224.75,103.8c-2.05-4.48-3.7-9.18-4.88-13.95-2.04-8.29.54-14.37,6.12-20.49,4.84-5.31,10.58-9.02,16.87-11.56,2.36,1.3,4.6,2.92,6.72,4.56,8.34,6.43,16.15,13.54,23.35,21.23,4.74,5.07,9.5,11.38,8.37,18.22-.83,5.03-4.68,8.95-6.94,13.51-2.19,4.43-2.87,9.59-1.89,14.43.5,2.47,1.37,5.1,1.48,7.57-25.4-3.58-45.65-25.77-49.2-33.53Z"/>
							<path className={styles.cls7}
							      d="M295.33,95.16c-4.07-1.16-6.96-4.84-9.11-8.56-4.58-7.95-8-17.15-14.68-23.58-3.19-3.07-7-5.37-10.57-7.97-.55-.4-1.11-.86-1.62-1.37.83-.11,1.67-.2,2.51-.28,10.19-1,19.16-.68,27.3,6.94,4.08,3.82,6.29,10.45,8.55,15.46,2.64,5.85,5.19,7.91,9.99,12.4,2.22,2.08,5.25,4.6,7.87,7.4-2.48-.89-5.12-1.32-7.75-1.15-4.19.27-8.43,1.87-12.49.72Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls5}
								      d="M259.53,94.74c2.12,4.95,6.08,7.99,8.86,6.79,2.77-1.19,3.3-6.17,1.18-11.12-2.12-4.95-6.08-7.99-8.86-6.79-2.77,1.19-3.3,6.17-1.18,11.12Z"/>
								<path className={styles.cls5}
								      d="M290.36,85.25c2.57,4.48,6.41,7.11,8.57,5.86,2.16-1.25,1.83-5.9-.74-10.38-2.57-4.48-6.41-7.1-8.57-5.86-2.16,1.25-1.83,5.9.74,10.38Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls5}
									      d="M299.1,105.13c1.04-1.4,3.2-2.69,5.63-3.51h0s0,0,0,0c0,0,0,0,.01,0h0c2.41-.88,4.89-1.23,6.57-.78,8,2.13,4.12,10.76-2.17,13.56h0s0,0,0,0c0,0,0,0-.01,0h0c-6.66,1.74-14.99-2.59-10.03-9.28Z"/>
									<path className={styles.cls5}
									      d="M316.33,119.6c-.12-.09-.27-.16-.45-.19-.6-.1-.93.43-1.32.78-.55.48-1.21.91-1.87,1.2-2.13.93-3.97.73-6.02.11l-.02-.09s-.05.04-.07.06c-.03,0-.06-.02-.09-.03l.02.09c-1.6,1.42-3.19,2.37-5.51,2.42-1.61.03-4.98-.9-6.18-2.21-.42-.46-1.12-.48-1.57-.06-.44.43-.46,1.14-.04,1.6,1.85,2.02,6.03,2.97,7.85,2.93.71-.01,1.36-.09,1.96-.22,1.66-.36,2.96-1.14,4.11-2.04,1.41.34,2.92.5,4.58.14.61-.13,1.23-.33,1.88-.62.93-.41,2.25-1.11,2.85-1.96.4-.57.46-1.51-.1-1.92Z"/>
								</g>
								<path className={styles.cls5}
								      d="M306.9,123.97c-.31.09-.64.05-.94-.13-.57-.35-.76-1.1-.43-1.68.04-.06,1.64-3.03,1.58-9.01,0-.68.53-1.23,1.19-1.23.66,0,1.2.55,1.21,1.23.07,6.78-1.83,10.13-1.91,10.28-.16.28-.42.47-.7.55Z"/>
							</g>
							<path className={styles.cls11}
							      d="M314.28,104.43c-.12,1.28-2.03,2.15-4.29,1.95-2.25-.2-3.98-1.4-3.87-2.68.12-1.28,2.03-2.15,4.29-1.95s3.98,1.4,3.87,2.68Z"/>
							<path className={styles.cls11}
							      d="M296,80.56c-.55.59-1.87.25-2.95-.75s-1.5-2.3-.95-2.89c.55-.59,1.87-.25,2.95.75,1.08,1,1.5,2.3.95,2.89Z"/>
							<path className={styles.cls11}
							      d="M267.02,90.16c-.65.82-2.37.55-3.85-.61-1.48-1.16-2.16-2.77-1.51-3.59.65-.82,2.37-.55,3.85.61,1.48,1.16,2.16,2.77,1.51,3.59Z"/>
						</g>
					</g>
					<path className={styles.cls1}
					      d="M240.02,93.73c5.14,7.69,17.51,11.08,29.68,9.77,10.05-1.08,29.94-10.34,37.23-17.22-4-3.58-7.29-7.86-9.03-13.3-15.92-6.71-52.39,14.63-57.88,20.75Z"/>
				</g>
				<path
					id="Helmet"
					className={styles.cls2}
					d="M219.18,84.91c-.61,5.62,3,24.67,7.32,20.4,30.94-30.62,63.9-36.6,71.45-32.17-1.55-10.69-12.4-30.24-46.97-20.29-21.99,6.33-30.82,23.01-31.8,32.07Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<path
					id="Stripe"
					className={styles.cls14}
					d="M268.36,76.97c2.8-1.03,7.59-2.73,10.18-3.3-3.5-7.89-17.74-20.37-24.67-21.61-3.77.83-9.15,2.97-10.81,4.06,5.94,2.5,23.16,17.2,25.3,20.85Z"
					style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
				/>
				<path id="Puppy_Cycling02_Upper" className={styles.cls12}
				      d="M193.44,83.83c.83,5.6,10.82,25.12,16.5,23.32,8.91-2.81,24.2-32.23,25.93-34.94,2.17-3.4,3.16-7.73,1.88-10.79-2.09-4.99-10.29-5.76-15.08-4.99-18.58,2.97-31.85,9.7-29.22,27.39Z"/>
			</g>
			<g id="Puppy_Cycling01">
				<g id="Puppy_Cycling01_Lower">
					<path className={styles.cls10}
					      d="M489.15,278.13c0,6.55,49.71,11.87,111.03,11.87,61.32,0,111.03-5.31,111.03-11.87,0-6.56-49.71-11.87-111.03-11.87-61.32,0-111.03,5.31-111.03,11.87Z"/>
					<path className={styles.cls13}
					      d="M585.18,156.76c14.49-.79,29,13.11,33.25,24.24,5.77,15.1-7.7,25.92-20.57,32.02,8.96,4.14,8.53,15.46.55,16.19-1.25.12-13.22-6.69-15.44-8.73-4.16-3.36-2.22-3.66-1.27-10.26,3.33-23.31-11.97-14.38-19.53-30.17-5.51-11.51,14.01-22.8,23.01-23.29Z"/>
					<path className={styles.cls13}
					      d="M647.05,113.54c11,12.07-4.21,37.44,18.14,40.47,7.7,1.04,8.08,15.1-9.7,12.69-24.98-3.39-29.66-20.07-27.88-43.32.74-9.61,15.37-14.31,19.43-9.84Z"/>
					<g>
						<path className={styles.cls8}
						      d="M523.06,278.07c-23.14,1.26-43-16.53-44.26-39.68s16.53-43,39.68-44.26c23.14-1.26,43,16.53,44.26,39.68,1.26,23.14-16.53,43-39.68,44.26ZM518.75,199.07c-20.42,1.12-36.12,18.64-35.01,39.06,1.12,20.42,18.64,36.12,39.06,35.01s36.12-18.64,35.01-39.06c-1.12-20.42-18.64-36.12-39.06-35.01Z"/>
						<path className={styles.cls8}
						      d="M670.12,277.46c-23.14,1.26-43-16.53-44.26-39.68-1.26-23.14,16.53-43,39.68-44.26s43,16.53,44.26,39.68c1.26,23.14-16.53,43-39.68,44.26ZM665.8,198.46c-20.42,1.12-36.12,18.64-35.01,39.06,1.12,20.42,18.64,36.12,39.06,35.01s36.12-18.64,35.01-39.06-18.64-36.12-39.06-35.01Z"/>
						<circle className={styles.cls3} cx="667.82" cy="235.49" r="33.98"/>
						<g>
							<path className={styles.cls9}
							      d="M581.08,247c-.3-.07-.58-.22-.82-.44-.75-.69-.79-1.85-.11-2.59l12.13-13.18,2.7,2.49-12.13,13.18c-.47.51-1.15.69-1.77.54Z"/>
							<rect className={styles.cls9} x="593.15" y="224.74" width="4.13" height="11.24" rx=".96" ry=".96"
							      transform="translate(21.43 509.85) rotate(-47.13)"/>
						</g>
						<circle className={styles.cls3} cx="520.77" cy="236.1" r="33.98"/>
						<path className={styles.cls2}
						      d="M641.03,177.8l.08,1.44-63.57,9.98c-.5.08-.98.29-1.38.6l-58,45.88c-.93.74-1.33,1.96-1.02,3.1s1.28,2,2.45,2.16l61.08,8.56c.19.03.38.03.57.02.67-.04,1.32-.3,1.83-.76l58.92-53.42.1,1.91,9.38-.51-1.06-19.48-9.38.51ZM575.85,197.63l2.66,45.6-51.23-7.18,48.57-38.42ZM584.25,239.7l-2.63-45.12,59.82-9.39.14,2.55-57.33,51.97Z"/>
						<path className={styles.cls2}
						      d="M666.92,237.06c-.78.04-1.58-.18-2.26-.68-9.7-7.23-19.32-26.17-21.27-37.01l7.72-.53c1.69,9.39,9.73,26.06,17.69,31.99,1.53,1.14,1.85,3.31.71,4.84-.64.86-1.6,1.33-2.59,1.39Z"/>
						<polygon className={styles.cls8}
						         points="563.42 190.59 593.52 183.14 593.18 176.86 562.76 178.52 563.42 190.59"/>
						<circle className={styles.cls4} cx="581.62" cy="245.26" r="11.85"/>
						<path className={styles.cls9}
						      d="M641.86,175.62l-.95-17.4,28.58-1.56.53,2.2c1.96,8.08.04,12.24-1.91,14.31-1.66,1.76-4.85,3.63-10.66,2.57-1.48-.27-2.46-1.68-2.19-3.16.27-1.48,1.69-2.46,3.16-2.19,1.88.34,4.4.45,5.73-.96,1.18-1.25,1.53-3.78,1.02-7.1l-16.28.89.65,11.97-7.68.42Z"/>
						<g>
							<path className={styles.cls9}
							      d="M582.68,242.51c.3.07.58.22.82.44.75.69.79,1.85.11,2.59l-12.13,13.18-2.7-2.49,12.13-13.18c.47-.51,1.15-.69,1.77-.54Z"/>
							<rect className={styles.cls9} x="566.47" y="253.53" width="4.13" height="11.24" rx=".96" ry=".96"
							      transform="translate(-8.21 499.49) rotate(-47.13)"/>
						</g>
					</g>
					<path className={styles.cls11}
					      d="M556.06,175.77c16.77,30.33,50.03-2,57.95-15.49,2.97-5.05,29.58-14.97,36.63-26.98s-14.08-33.24-17.58-34.56c-17.56-6.65-42.59,3.21-59.27,17.09-13.68,11.39-30.87,36.17-17.73,59.94Z"/>
					<path className={styles.cls12}
					      d="M555.76,139.12c5.65-.69,9.99,9.49,6.14,16.46-4.1,7.43-6.28,16.47-9.21,11.97-4.99-10.38.2-24,3.07-28.43Z"/>
					<path className={styles.cls11}
					      d="M621.81,124.25c-.01,10.98,1.26,32.61,15.07,39.99,20.03,10.7,33.7,4.25,33.9-.78.46-11.51-7.35.41-17.45-11.47-5.52-6.49-4.33-14.84-7.13-25.73-2.33-9.05-24.38-6.19-24.38-2.01Z"/>
					<path className={styles.cls13}
					      d="M587.41,163.11c5.7,1.87,11.94,9.42,11.83,13.88-1.58,1.48-3.1,2.73-4.6,3.07-5.8-6.77-12.84-18.79-7.24-16.95Z"/>
				</g>
				<path
					id="Shirt-2"
					data-name="Shirt"
					className={styles.cls2}
					d="M633.1,90.61c17.42,1.28,21.61,26.65,19.96,36.78-.54,3.34-2.3,5.97-3.86,8-8.32-19.34-34.31-1.44-24.1,17.26-5.05,3.64-9.47,8.71-13.76,12.5-17.23-7.26-31.73-27.52-36.34-50.62,10.55-9.34,38.37-25.37,58.1-23.93Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-2" data-name="Stripes">
					<path
						className={styles.cls14}
						d="M585.83,122.39c2.18,6.05,4.98,11.74,8.32,16.9,5.39,8.32,15.52,15.52,23.12,20.29,1.11-1.12,2.4-2.32,3.01-2.87-7.3-4.48-17.63-11.59-22.81-19.58-5.69-8.79-9.73-19.23-11.71-30.29-.57.3-2.87,1.67-3.61,2.2.9,4.58,2.13,9.04,3.68,13.34Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls14}
						d="M624.27,153.28l1.38-.93c-2.99-4.43-3.24-10.12-.31-15.46,3.1-5.66,8.94-9.49,13.9-9.11,4.06.31,7.22,3.29,9.15,8.6,0,0,1.07-1.23,1.4-1.85-2.83-6.74-7.17-8.48-10.4-8.73-5.78-.44-12.27,3.72-15.78,10.13-3.31,6.05-3.07,12.37.67,17.34Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Cycling02_Mid-2" data-name="Puppy_Cycling02_Mid">
					<path className={styles.cls11}
					      d="M556.93,176.99c7.11,11.93,19.69,48.45,9.3,67.54-2.97,5.45,5.68,17.32,8.5,15.71,9.07-5.2,4.38-11.25,2.39-15.17,30.49-29.08,23.44-56.97,17.3-72.94-8.97-23.35-44.71-7.23-37.49,4.87Z"/>
					<g>
						<path className={styles.cls12}
						      d="M538.31,143.21c1.89,1.21,3.87,2.32,5.96,3.25,2.5,1.12,18.44,3.56,14.77,8.49-.56.75-1.45,1.23-2.38,1.39-3.16.55-6.4,1.03-9.51.73-5.13-.49-9.41-3.01-12.94-6.29.01-1.3.28-2.6.83-3.79.71-1.54,1.87-2.86,3.27-3.79Z"/>
						<path className={styles.cls11}
						      d="M527.5,137.09c.36-.12.83-.16,1.42-.1,2.73.25,5.03,3.21,7.08,4.66.76.54,1.54,1.05,2.32,1.56-1.41.94-2.56,2.26-3.27,3.79-.55,1.18-.82,2.48-.83,3.79-1.56-1.46-2.98-3.06-4.26-4.71-.89-1.15-5.59-7.97-2.45-8.98Z"/>
					</g>
					<path className={styles.cls6}
					      d="M652.7,127.38c4.77-.6,8.99-2.19,12.17-6.47,8.36-11.22-.41-19.82-8.22-26.59-7.35-6.37-20.37-16.72-30.05-10.64-23.94,15.04,8.18,45.93,26.09,43.69Z"/>
					<g>
						<path className={styles.cls12}
						      d="M710.67,52.64c1.9,4.93,2.36,25.43-3.42,26.67-9.06,1.94-36.12-14.61-38.89-15.99-3.48-1.73-6.37-4.75-6.68-7.84-.52-5.02,6.25-9.53,10.77-11.17,17.54-6.39,32.22-7.23,38.22,8.33Z"/>
						<g>
							<path className={styles.cls11}
							      d="M672.46,129.76c-.97-4.84-.3-10,1.89-14.43,2.26-4.57,6.1-8.49,6.94-13.51,1.13-6.84-3.63-13.15-8.37-18.22-7.19-7.69-15.01-14.8-23.35-21.23-2.13-1.64-4.36-3.26-6.72-4.56,5.2-2.1,10.76-3.4,16.51-4.13.5.51,1.07.97,1.62,1.37,3.57,2.6,7.38,4.9,10.57,7.97,6.68,6.43,10.1,15.63,14.68,23.58,2.15,3.73,5.04,7.41,9.11,8.56,4.06,1.15,8.3-.45,12.49-.72,2.63-.17,5.26.26,7.75,1.15,2.76,2.95,5.07,6.19,5.49,9.55,1.06,8.43-2.28,18.45-7.93,22.82-9.55,7.38-17.89,8.61-27.34,9.61-4.04.43-8.01.3-11.85-.24-.11-2.47-.99-5.1-1.48-7.57Z"/>
							<path className={styles.cls7}
							      d="M624.75,103.8c-2.05-4.48-3.7-9.18-4.88-13.95-2.04-8.29.54-14.37,6.12-20.49,4.84-5.31,10.58-9.02,16.87-11.56,2.36,1.3,4.6,2.92,6.72,4.56,8.34,6.43,16.15,13.54,23.35,21.23,4.74,5.07,9.5,11.38,8.37,18.22-.83,5.03-4.68,8.95-6.94,13.51-2.19,4.43-2.87,9.59-1.89,14.43.5,2.47,1.37,5.1,1.48,7.57-25.4-3.58-45.65-25.77-49.2-33.53Z"/>
							<path className={styles.cls7}
							      d="M695.33,95.16c-4.07-1.16-6.96-4.84-9.11-8.56-4.58-7.95-8-17.15-14.68-23.58-3.19-3.07-7-5.37-10.57-7.97-.55-.4-1.11-.86-1.62-1.37.83-.11,1.67-.2,2.51-.28,10.19-1,19.16-.68,27.3,6.94,4.08,3.82,6.29,10.45,8.55,15.46,2.64,5.85,5.19,7.91,9.99,12.4,2.22,2.08,5.25,4.6,7.87,7.4-2.48-.89-5.12-1.32-7.75-1.15-4.19.27-8.43,1.87-12.49.72Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls5}
								      d="M659.53,94.74c2.12,4.95,6.08,7.99,8.86,6.79,2.77-1.19,3.3-6.17,1.18-11.12-2.12-4.95-6.08-7.99-8.86-6.79-2.77,1.19-3.3,6.17-1.18,11.12Z"/>
								<path className={styles.cls5}
								      d="M690.36,85.25c2.57,4.48,6.41,7.11,8.57,5.86,2.16-1.25,1.83-5.9-.74-10.38-2.57-4.48-6.41-7.1-8.57-5.86-2.16,1.25-1.83,5.9.74,10.38Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls5}
									      d="M699.1,105.13c1.04-1.4,3.2-2.69,5.63-3.51h0s0,0,0,0c0,0,0,0,.01,0h0c2.41-.88,4.89-1.23,6.57-.78,8,2.13,4.12,10.76-2.17,13.56h0s0,0,0,0c0,0,0,0-.01,0h0c-6.66,1.74-14.99-2.59-10.03-9.28Z"/>
									<path className={styles.cls5}
									      d="M716.33,119.6c-.12-.09-.27-.16-.45-.19-.6-.1-.93.43-1.32.78-.55.48-1.21.91-1.87,1.2-2.13.93-3.97.73-6.02.11l-.02-.09s-.05.04-.07.06c-.03,0-.06-.02-.09-.03l.02.09c-1.6,1.42-3.19,2.37-5.51,2.42-1.61.03-4.98-.9-6.18-2.21-.42-.46-1.12-.48-1.57-.06-.44.43-.46,1.14-.04,1.6,1.85,2.02,6.03,2.97,7.85,2.93.71-.01,1.36-.09,1.96-.22,1.66-.36,2.96-1.14,4.11-2.04,1.41.34,2.92.5,4.58.14.61-.13,1.23-.33,1.88-.62.93-.41,2.25-1.11,2.85-1.96.4-.57.46-1.51-.1-1.92Z"/>
								</g>
								<path className={styles.cls5}
								      d="M706.9,123.97c-.31.09-.64.05-.94-.13-.57-.35-.76-1.1-.43-1.68.04-.06,1.64-3.03,1.58-9.01,0-.68.53-1.23,1.19-1.23.66,0,1.2.55,1.21,1.23.07,6.78-1.83,10.13-1.91,10.28-.16.28-.42.47-.7.55Z"/>
							</g>
							<path className={styles.cls11}
							      d="M714.28,104.43c-.12,1.28-2.03,2.15-4.29,1.95-2.25-.2-3.98-1.4-3.87-2.68.12-1.28,2.03-2.15,4.29-1.95s3.98,1.4,3.87,2.68Z"/>
							<path className={styles.cls11}
							      d="M696,80.56c-.55.59-1.87.25-2.95-.75-1.08-1-1.5-2.3-.95-2.89.55-.59,1.87-.25,2.95.75s1.5,2.3.95,2.89Z"/>
							<path className={styles.cls11}
							      d="M667.02,90.16c-.65.82-2.37.55-3.85-.61-1.48-1.16-2.16-2.77-1.51-3.59.65-.82,2.37-.55,3.85.61,1.48,1.16,2.16,2.77,1.51,3.59Z"/>
						</g>
					</g>
					<path className={styles.cls1}
					      d="M640.02,93.73c5.14,7.69,17.51,11.08,29.68,9.77,10.05-1.08,29.94-10.34,37.23-17.22-4-3.58-7.29-7.86-9.03-13.3-15.92-6.71-52.39,14.63-57.88,20.75Z"/>
				</g>
				<path
					id="Helmet-2"
					data-name="Helmet"
					className={styles.cls2}
					d="M619.18,84.91c-.61,5.62,3,24.67,7.32,20.4,30.94-30.62,63.9-36.6,71.45-32.17-1.55-10.69-12.4-30.24-46.97-20.29-21.99,6.33-30.82,23.01-31.8,32.07Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<path
					id="Stripe-2"
					data-name="Stripe"
					className={styles.cls14}
					d="M668.36,76.97c2.8-1.03,7.59-2.73,10.18-3.3-3.5-7.89-17.74-20.37-24.67-21.61-3.77.83-9.15,2.97-10.81,4.06,5.94,2.5,23.16,17.2,25.3,20.85Z"
					style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
				/>
				<path id="Puppy_Cycling02_Upper-2" data-name="Puppy_Cycling02_Upper" className={styles.cls12}
				      d="M593.44,83.83c.83,5.6,10.82,25.12,16.5,23.32,8.91-2.81,24.2-32.23,25.93-34.94,2.17-3.4,3.16-7.73,1.88-10.79-2.09-4.99-10.29-5.76-15.08-4.99-18.58,2.97-31.85,9.7-29.22,27.39Z"/>
			</g>
		</svg>
	);
};