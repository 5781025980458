import defaults from "superagent-defaults";
import * as UserActions from "actions/UserActions";
import getStore from "utils/getStore";
import LocalData from "utils/LocalData";
import logger from "utils/logger";
import toast from "utils/toast";
import * as status from "constants/HttpResponseCodes";
import * as Sentry from "@sentry/react";

export default function request() {
    const superagent = defaults();

    let defaultRequest = superagent
        .set("Content-Type", "application/json")
        .set("Accept", "application/json")
        .retry(2)
        .use((request) => {
            request.on("error", (error) => {
                if (error.status === 401) {
                    logger.log("IN PROGRESS: ", LocalData.isAuthRequestInProgress());
                    if (!LocalData.isAuthRequestInProgress()) {
                        logger.log("Start auth request");
                        LocalData.startAuthRequest();
                        getStore().dispatch(UserActions.loginUserPopup(() => {
                            LocalData.finishAuthRequest();
                        }));
                    }
                }
            });
        });
        //.set("X-CSRFTOKEN", Cookies.get("csrftoken"));

    if (LocalData.getToken()) {
        // logger.log(LocalData.getToken());
        defaultRequest = defaultRequest.set("Authorization", `Bearer ${LocalData.getToken()}`)
    }
    return defaultRequest;
}

export function anonymousRequest() {
    const superagent = defaults();

    let defaultRequest = superagent;

    return defaultRequest;
}

export function getContentDisposition(headers) {
    // EXPECTED HEADER: content-disposition: attachment; filename=WellnessAuditHistory20210308070914.xlsx; filename*=UTF-8''WellnessAuditHistory20210308070914.xlsx
    const disposition = {};
    if(headers["content-disposition"]) {
        const pieces = headers["content-disposition"].split("; ");
        for(let i=0;i<pieces.length;i++){
            const propItem = pieces[i].split("=");
            disposition[propItem[0]] = propItem[1] || true; // Attachment has no value
        }
    }
    return disposition;
}

export function getContentType(headers) {
    return headers["content-type"];
}

export function downloadFile(res, fileExtension, isNewTab=false) {
    const contentDisposition = getContentDisposition(res.headers);
    const contentType = getContentType(res.headers);

    const bytes = res.body;
    const filename = contentDisposition.filename || `Greenline_Data_Export.${fileExtension}`;
    const blob = new Blob([bytes], { type: contentType });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;

    if (isNewTab) {
        link.target = '_blank';
    } else {
        link.download = filename;
    }

    link.click();

    if (!isNewTab) {
        window.URL.revokeObjectURL(url);
    }
}

export function imageFile(res) {
    return URL.createObjectURL(res.body);
}

export function handleErrorResponse(customMessage, error, isAdmin=false) {
    let errorMessage = error.message;
    if (error.status === status.HTTP_STATUS_400) {
        errorMessage = error?.response?.body?.Detail || error.message;
    }
    let defaultMessage = `We're sorry! There was an error ${customMessage}:`;
    if(!isAdmin) {
        // If the user is not an Admin them show a specific message based on the error type.
        switch (error.status) {
            // case status.HTTP_STATUS_400:
            //     errorMessage = "";
            //     break;
            case status.HTTP_STATUS_425:
                defaultMessage = "Sorry, it's too early to perform this action. Please wait a few minutes and try again.";
                errorMessage = ""
                break;
            case status.HTTP_STATUS_500:
                errorMessage = "The issue has been reported.";
                break;
        // Defaults to errorMessage = error.message
        }
    }
    toast.error(`${defaultMessage} ${errorMessage}`);
    logger.error(customMessage, error, true);
}

export function handleSuccessResponse(customMessage) {
    toast.success(customMessage)
}

// FOR SETTING UP PLACEHOLDER ENDPOINTS
export function placeholderRequest(rejectMessage = "") {
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            if (rejectMessage) {
                reject(`Placeholder API call error message: ${rejectMessage}`);
            } else {
                resolve(true);
            }
        }, 1000);
    });
}

