import React, {useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as GreenlineWellnessActions from "actions/GreenlineWellnessActions";
import GreenlineWellnessPetPlanEditForm from "components/greenlineWellness/widgets/GL_PetPlanTracking/GreenlineWellnessPetPlanEditForm";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function GL_PetPlanEditFormContainer(props) {
    const [loading, setLoading] = useState(false);

    const handleSave = async (ppgPatientPlanEnrollmentId) => {
        if (props.canEditClinicWellnessPetPlans) {
            setLoading(true);
            await props.updatePetPlan (
                props.clinicId,
                props.plan.petId,
                props.plan.patientPlanEnrollmentId,
                ppgPatientPlanEnrollmentId,
            );
            setLoading(false);
            props.onClose();
        }
    };

    return (
        <div className="full-width full-height">
            <GreenlineWellnessPetPlanEditForm
                clinicId={props.clinicId}
                plan={props.plan}
                onCancel={props.onClose}
                onSave={handleSave}
            />
            <SpinnerTakeover show={loading}/>
        </div>
    );
}

GL_PetPlanEditFormContainer.propTypes = {
    clinicId: PropTypes.number.isRequired,
    plan: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canEditClinicWellnessPetPlans = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        return {
            canEditClinicWellnessPetPlans,
        }
    },
    (dispatch) => ({
        updatePetPlan: (clinicId, petId, patientPlanEnrollmentId, ppgPatientPlanEnrollmentId) => dispatch(GreenlineWellnessActions.updatePetPlan(clinicId, petId, patientPlanEnrollmentId, ppgPatientPlanEnrollmentId)),
    }),
)(GL_PetPlanEditFormContainer);