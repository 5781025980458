import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import styles from "./ProviderGamesManagementPage.scss";
import BiProductDisclosures from "components/common/BiProductDisclosures";
import Button from "components/common/Button";
import GameTemplateSelector from "components/provider/widgets/GameTemplateSelector";
import Modal from "components/common/Modal";
import ProviderGamesAccordionList from "components/provider/widgets/ProviderGamesAccordionList";
import * as  ProviderLinks from "utils/ProviderLinks";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ProviderGamesManagementPage(props) {
    const {
        canEditGames = false,
        canViewBiGamesClinicInvites = false,
        canViewClinicBIGamesDashboard = false,
        canViewBiGamesDrillDown = false,
        games,
        nodeInfo,
        userId,
    } = props;
    const history = useHistory();
    const { pathname } = useLocation();
    const params = useParams();
    const nodeId = Number(params?.nodeId) || null;
    const providerId = Number(params?.providerId) || null;
    const isAdmin = !!(includes(pathname, "/admin") && providerId && nodeId);
    const isDemo = !!includes(pathname, "/demo") && canViewClinicBIGamesDashboard;
    const [showTemplateSelector, setShowTemplateSelector] = useState(false);

    const handleManageInvitesClicked = () => {
        if((canViewBiGamesClinicInvites || canViewBiGamesDrillDown)) {
            let link;
            if (!!userId) {
                link = ProviderLinks.getLinkLocation("game-invites", {
                    providerId: providerId,
                    nodeId: nodeId,
                    isAdmin: isAdmin,
                    isDemo: isDemo,
                }, `?userId=${userId}`);
            } else {
                link = ProviderLinks.getLinkLocation("game-invites", {
                    providerId: providerId,
                    nodeId: nodeId,
                    isAdmin: isAdmin,
                    isDemo: isDemo,
                });
            }
            history.push(link);
        }
    };

    const handleAddGameClicked = () => {
        setShowTemplateSelector(true);
    };

    return (
        <div className={styles.root}>
            <div className={styles.mainSection}>
                <div className="full-width full-height flex flex-column">
                    {(nodeInfo && !(nodeInfo.users && nodeInfo.users.length)) ? (
                        <h2 className="flex full-width full-height justify-flex-center align-center text-gray text-italic">
                            There is no active Rep for this Territory
                        </h2>
                    ) : (
                        <>
                            <div>
                                <div className="full-width flex column-to-row-sm justify-around margin-bottom-sm sm-border-bottom">
                                    <Button
                                        onClick={handleManageInvitesClicked}
                                        disabled={!(canViewBiGamesClinicInvites || canViewBiGamesDrillDown)}
                                    >
                                        Manage Invites
                                    </Button>
                                    <Button
                                        onClick={handleAddGameClicked}
                                        disabled={!canEditGames}
                                    >
                                        Add New Game
                                    </Button>
                                </div>
                            </div>
                            <div className="full-width flex">
                                <ProviderGamesAccordionList
                                    games={games}
                                    nodeId={nodeId}
                                    providerId={providerId}
                                    isAdmin={isAdmin}
                                    userId={userId}
                                />
                            </div>
                        </>
                    )}
                    <div className="full-width margin-top-sm">
                        <hr
                            style={{ border: "solid 1px #dadada" }}
                            className="hidden-lt-sm margin-bottom-sm"
                        />
                        <BiProductDisclosures />
                    </div>
                </div>
            </div>
            <Modal
                show={showTemplateSelector}
                modalTitle="Select a Game Template"
                onClose={() => setShowTemplateSelector(false)}
            >
                <GameTemplateSelector
                    games={games}
                    nodeId={nodeId}
                    userId={userId}
                    isAdmin={isAdmin}
                />
            </Modal>
        </div>
    );
}

ProviderGamesManagementPage.propTypes = {
    nodeInfo: PropTypes.object.isRequired,
    userId: PropTypes.number,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const currentOrgNode = state.entities.orgNode?.current;
        // Permissions
        const canViewBiGamesDrillDown = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_DRILLDOWN, userProfile);
        const canViewBiGamesClinicInvites = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_CLINIC_INVITES, userProfile);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewGames = canAdminProviderBiGames || canViewProviderBiGames || canViewBiGamesDrillDown;
        const canEditGames = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        return {
            games: state.entities.providerGames,
            // Permissions
            canViewBiGamesDrillDown,
            canViewBiGamesClinicInvites,
            canViewClinicBIGamesDashboard,
            canViewGames,
            canEditGames,
        };
    },
)(ProviderGamesManagementPage);