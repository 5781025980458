import React, { useRef } from "react";
import toast from "utils/toast";
import styles from "./ProviderVoucherCode.scss";

export default function ProviderVoucherCode(props) {
    const voucherCodeRef = useRef(null);

    const handleCopyClicked = () => {
        const element = voucherCodeRef.current;
        if (element) {
            element.select();
            element.setSelectionRange(0, 99999); // For mobile devices (says w3schools)
            if (document.execCommand("copy")) {
                toast.success("Voucher code copied to clipboard");
            }
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.left}>Your Voucher Code</div>
            <div
                className={styles.right}
                onClick={handleCopyClicked}
            >
                <div className={styles.code}>
                    <span>{props.value}</span>
                </div>
                <div className={styles.copyButton}>
                    <i
                        className="far fa-copy"
                        title="Copy to clipboard"
                    />
                </div>
            </div>
            <input
                ref={voucherCodeRef}
                style={{ position: "absolute", zIndex: -1000 }} // We want this input element hidden
                type="input"
                value={props.value}
                readOnly
            />
        </div>
    );
}
