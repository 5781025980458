import React from "react";

export default function ImportantSafetyInformation(props) {
    return (
        <div data-testid="important_safety_information_component">
            <div className="flex spaced-content text-lg margin-bottom-sm">
                <div>
                    <b>IMPORTANT SAFETY INFORMATION</b>: Following the use of <br/> HEARTGARD® Plus (ivermectin/pyrantel), digestive and neurologic side 
                    effects have been reported All dogs should be tested for heartworm infection before starting a preventive program. For more 
                    information, see full prescribing information or visit <a className="pointer text-primary" href="https://www.heartgardclinic.com" target="_blank">HEARTGARDClinic.com</a>.
                </div>
                <div>
                    <b>IMPORTANT SAFETY INFORMATION</b>: <i>NexGard</i>® (afoxolaner) is for use in dogs only. Use with caution in dogs with a history of 
                    seizures or neurologic disorders. For more information, see full prescribing information or visit <a className="pointer text-primary" href="https://www.nexgardclinic.com" target="_blank">NexGardClinic.com</a>.
                </div>
            </div>
            <div className="full-width margin-top-sm">
                    HEARTGARD® and the Dog &amp; Hand logo® are registered trademarks of Boehringer Ingelheim Animal Health USA Inc.
                    <i> NexGard</i>® is a registered trademark of Boehringer Ingelheim Animal Health France, used under license.
                    ©2023 Boehringer Ingelheim Animal Health USA Inc., Duluth, GA.
                    All rights reserved.
                    US-PET-0198-2023
            </div>
        </div>
    );
}