import React, {useEffect, useState} from "react";
import PropTypes from 'prop-types';
import classnames from "classnames";
import styles from "./ImageCarousel.scss";
import map from "lodash/map";
import Image from "components/common/Image";
import logger from "utils/logger";

export default function ImageCarousel(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [carouselInterval, setCarouselInterval] = useState(0);
    // Change images every 5 seconds
    const imageIntervalTime = 1000 * 5;

    useEffect(() => {
        if(props.images?.length > 1) {
            if (carouselInterval) {
                clearInterval(carouselInterval);
            }

            const imageIntervalId = setInterval(handleRefresh, imageIntervalTime);
            setCarouselInterval(imageIntervalId);

            return () => {
                clearInterval(imageIntervalId);
            }
        }
    }, [currentImage, props.images]);

    if (!props.images?.length) {
        return null;
    }

    const getPrevious = () => {
        if(currentImage <= 0){
            return props.images.length - 1;
        } else {
            return currentImage - 1;
        }
    }

    const goPrevious = () => {
        setCurrentImage(getPrevious());
    }

    const getNext = () => {
        if(currentImage >= (props.images.length - 1)){
            return 0
        } else {
            return currentImage + 1;
        }
    }

    const goNext = () => {
        setCurrentImage(getNext());
    }

    const handleRefresh = () => {
        goNext();
    };

    const handlePause = () => {
        logger.log("Pausing Carousel");
        clearInterval(carouselInterval);
    }

    const handleStart = () => {
        logger.log("Starting Carousel");
        const imageIntervalId = setInterval(handleRefresh, imageIntervalTime);
        setCarouselInterval(imageIntervalId);
    }

    const handleImageClicked = (index) => {
        if(!!props.onClickImage) {
            props.onClickImage(index)
        }
    };


    return (
        <div
            data-testid="image_carousel_component"
            className={classnames(styles.root, "flex spaced-content")}
            onMouseEnter={handlePause}
            onMouseLeave={handleStart}
        >
            {(props.images?.length > 1) && (
                <div
                    data-testid="image_carousel_previous_btn"
                    className={classnames(styles.changeImage, "flex flex-centered pointer")}
                    onClick={goPrevious}
                >
                    <i className="fa fa-2x fa-caret-left"/>
                </div>
            )}
            <div className="flex-1">
                <div className={classnames(styles.carousel, "flex")}>
                    {map(props.images, (image, index) => (
                        <div
                            data-testid={`image_carousel_image_${index}`}
                            key={index}
                            className={classnames(styles.imageContainer, "full-width flex flex-centered", {
                                [styles.current]: (index === currentImage),
                                "test-current-image": (index === currentImage),
                            })}
                             onClick={() => handleImageClicked(index)}
                        >
                            <Image src={image}/>
                        </div>
                    ))}
                </div>
                {(props.images?.length > 1) && (
                    <div
                        data-testid="image_carousel_dot_container"
                        className={classnames(styles.dotsContainer, "flex flex-centered spaced-content")}
                    >
                        {map(props.images, (image, index) => (
                            <div
                                data-testid={`image_carousel_dot_container_${index}`}
                                key={index}
                                className={classnames(styles.dot, "flex-none flex flex-centered pointer")}
                                onClick={() => setCurrentImage(index)}
                            >
                                <i
                                    data-testid={`image_carousel_dot_${index}`}
                                    className={classnames("fa fa-circle text-gray", {
                                        [styles.active] : (index === currentImage),
                                        "test-current-dot": (index === currentImage),
                                    })}
                                />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            {(props.images?.length > 1) && (
                <div
                    data-testid="image_carousel_next_btn"
                    className={classnames(styles.changeImage, "flex flex-centered pointer")}
                    onClick={() => goNext()}
                >
                    <i className="fa fa-2x fa-caret-right"/>
                </div>
            )}
        </div>
    );
}

ImageCarousel.propTypes = {
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    onClickImage: PropTypes.func
}