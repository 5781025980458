import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useLocation, useHistory, useParams, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import moment from "moment";
import orderBy from "lodash/orderBy";
import * as ProviderApi from "api/ProviderApi";
import * as ProviderActions from "actions/ProviderActions";
import Button from "components/common/Button";
import GreenlineGameSnapshot from "components/games/widgets/GreenlineGameSnapshot";
import Modal from "components/common/Modal";
import ProviderGamesAccordionList from "components/provider/widgets/ProviderGamesAccordionList";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import getNameForGameType from "utils/getNameForGameType";
import logger from "utils/logger";
import { addCommasToNumber } from "utils/numeric";
import pluralizeWord from "utils/pluralizeWord";
import * as ProviderLinks from "utils/ProviderLinks";
import toast from "utils/toast";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import { LEAF_PARTY, PUPPY_BOWL, PUPPY_POOL_PARTY } from "constants/GameTypes";

function ProviderGameInfoTable(props) {
    const { pathname } = useLocation();
    const params = useParams();
    const nodeId = Number(params.nodeId);
    const providerId = Number(params.providerId);
    const isDemo = !!includes(pathname, "/demo") && props.canViewClinicBIGamesDashboard;
    const isAdmin = !!(includes(pathname, "/admin") && nodeId && providerId);
    const history = useHistory();
    const [showModal, setShowModal] = useState(false);
    const [selectedGame, setSelectedGame] = useState(null);
    const [createTemplate, setCreateTemplate] = useState(null);
    const [loading, setLoading] = useState(false);

    let TODAY = new moment();
    let TOMORROW = TODAY.add(1, "day").format("MM/DD/YYYY");

    const handleClose = () => {
        setShowModal(false);
        setSelectedGame(null);
    }

    const handleOpen = (row, e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowModal(true);
        setSelectedGame(row);
    }

    const handleCreateTemplate = () => {
        if(createTemplate?.greenlineGameId){
            setLoading(true);
            //Get a copy of the game
            ProviderApi.copyGameById(createTemplate.greenlineGameId)
                .then(results => {
                    const copyData = {
                        ...results.body,
                        startDate: TOMORROW,
                        canUseAsTemplate: true,
                        copiedFromGreenlineGameId: createTemplate.greenlineGameId,
                        originalStartDate: TODAY.format("MM/DD/YYYY")
                    };
                    //Save the game copy as a template
                    ProviderApi.saveGame(copyData)
                        .then(res => {
                            toast.success(`Template Created Successfully`);
                            setCreateTemplate(null);
                            setLoading(false);
                            props.getProviderGames(props.userId);
                        })
                        .catch(error => {
                            toast.error(`Error creating template: ${error.message}`);
                            logger.error(error.message, error, true);
                            setLoading(false);
                        });
                })
                .catch(error => {
                    logger.error("Error loading Game Copy", error, true);
                    toast.error(`Error loading Game Copy: ${error.message}`);
                    setLoading(false);
                });
        }
    }

    const handleRowClick = (row) => {
        let link;
        if (props.canViewGames) {
            if(props.isTemplates) {
                // Handle Template Clicks
                if (props.userId) {
                    link = ProviderLinks.getLinkLocation("game-user-template", {
                        providerId: providerId,
                        nodeId: nodeId,
                        isAdmin: isAdmin,
                        templateId: row.greenlineGameId,
                        isDemo
                    }, `?userId=${props.userId}`);
                } else {
                    link = ProviderLinks.getLinkLocation("game-user-template", {
                        providerId: providerId,
                        nodeId: nodeId,
                        isAdmin: isAdmin,
                        templateId: row.greenlineGameId,
                        isDemo
                    });
                }
            } else {
                // Handle Games Clicks
                if (props.userId) {
                    link = ProviderLinks.getLinkLocation("game", {
                        providerId: providerId,
                        nodeId: nodeId,
                        isAdmin: isAdmin,
                        gameId: row.greenlineGameId,
                        isDemo
                    }, `?userId=${props.userId}`);
                } else {
                    link = ProviderLinks.getLinkLocation("game", {
                        providerId: providerId,
                        nodeId: nodeId,
                        isAdmin: isAdmin,
                        gameId: row.greenlineGameId,
                        isDemo
                    });
                }
            }
        }
        history.push(link);
    }

    const handleRowRightClicked = (row) => {
        if (!(row.gameType === PUPPY_BOWL || row.gameType === PUPPY_POOL_PARTY || row.gameType === LEAF_PARTY)) {
            setCreateTemplate(row);
        }
    }

    const COLUMNS = [{
        name: "Name",
        selector: "name",
        key: "name",
        sortable: true,
    }, {
        name: "Type",
        selector: "gameType",
        key: "gameType",
        sortable: true,
        format: row => <span>{getNameForGameType(row.gameType)} - {row.productType}</span>,
    }, {
        name: "Clinic(s)",
        selector: "clinicName",
        key: "clinicName",
        format: row => !!row.clinicName ? (
            <span>{row.clinicName}</span>
        ) : (
            <span>{addCommasToNumber(row.clinicCount)} {pluralizeWord(row.clinicCount, "clinic")}</span>
        ),
    }, {
        name: "Dates",
        selector: "startDate",
        key: "startDate",
        sortable: true,
        format: row => <span>{row.startDate} - {row.endDate}</span>,
    }, {
        name: "Duration",
        selector: "description",
        key: "description",
        sortable: true,
    }, {
        name: "Product(s)",
        sortable: true,
        selector: "productBrands",
        key: "productBrands",
        format: row => <span>{row.productBrands.join(", ")}</span>
    }, {
        name: "",
        selector: "action",
        key: "action",
        format: row => (props.canViewGames && row.mostRecentGameSnapshotId)  ? (
            <Button
                title="View Game Progress"
                onClick={(e) => handleOpen(row, e)}
                iconOnly
                text
                type="primary"
            >
                <i style={{ fontSize: "1.75em" }} className="fas fa-chart-line"/>
            </Button>
        ) : "",
    }];

    return (
        <>
            <SortableDataTable
                green
                striped
                columns={COLUMNS}
                rawData={orderBy(props.games, ["greenlineGameId"], ["desc"])}
                onClick={(data) => props.canViewGames ? handleRowClick(data) : {}}
                // Templates don't get a right click action
                onRightClick={(data) => !props.isTemplates ? handleRowRightClicked(data) : {}}
            />
            {!!selectedGame && (
                <GreenlineGameSnapshot
                    show={showModal}
                    game={selectedGame}
                    handleClose={handleClose}
                    nodeId={nodeId}
                    userId={props.userId}
                />
            )}
            <SpinnerTakeover show={loading}/>
            <Modal
                show={!!createTemplate}
                onClose={() => setCreateTemplate(null)}
                modalTitle={createTemplate?.name}
                small
            >
                <div className="flex flex-centered">
                    <Button
                        type="warn"
                        onClick={handleCreateTemplate}
                    >
                        Create Template From Game
                    </Button>
                </div>
            </Modal>
        </>
    );
}

ProviderGamesAccordionList.propTypes = {
    games: PropTypes.array,
    isTemplates: PropTypes.bool,
};

const connector = connect(
    (state) => {
        const userProfile = state.user.userProfile;
        //Permissions
        const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewGamesAdmin = userHasPermission(PermissionTypes.VIEW, UserPermissions.GAMES_ADMIN, userProfile);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canViewGames = (canAdminProviderBiGames && canViewGamesAdmin) || canViewProviderBiGames;

        return {
            // Permissions
            canViewGames,
            canViewClinicBIGamesDashboard,
        }
    },
    (dispatch) => ({
        getProviderGames: (userId) => dispatch(ProviderActions.getProviderGames(userId)),
    })
);

export default compose(
    withRouter,
    connector
)(ProviderGameInfoTable);
