exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PuppyRunning__cls5{fill:#232021}.PuppyRunning__cls5,.PuppyRunning__cls6,.PuppyRunning__cls7,.PuppyRunning__cls8,.PuppyRunning__cls9,.PuppyRunning__cls10,.PuppyRunning__cls11,.PuppyRunning__cls12,.PuppyRunning__cls13,.PuppyRunning__cls14,.PuppyRunning__cls15,.PuppyRunning__cls16{stroke-width:0}.PuppyRunning__cls6{fill:#141313}.PuppyRunning__cls7{fill:#c6c0bf}.PuppyRunning__cls8{fill:#d6d4d4}.PuppyRunning__cls9{fill:#dd9e29}.PuppyRunning__cls10{fill:#b2611d}.PuppyRunning__cls11{fill:#fff}.PuppyRunning__cls12{fill:#ffb830}.PuppyRunning__cls13{fill:#fefefe}.PuppyRunning__cls14{fill:#003f63}.PuppyRunning__cls15{fill:#0070b5}.PuppyRunning__cls16{fill:#006db0}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/PuppyBowl2/PuppyRunning.scss"],"names":[],"mappings":"AAAA,oBAAM,YAAY,CAAC,uPAA+E,cAAc,CAAC,oBAAM,YAAY,CAAC,oBAAM,YAAY,CAAC,oBAAM,YAAY,CAAC,oBAAM,YAAY,CAAC,qBAAO,YAAY,CAAC,qBAAO,SAAS,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC","file":"PuppyRunning.scss","sourcesContent":[".cls5{fill:#232021}.cls5,.cls6,.cls7,.cls8,.cls9,.cls10,.cls11,.cls12,.cls13,.cls14,.cls15,.cls16{stroke-width:0}.cls6{fill:#141313}.cls7{fill:#c6c0bf}.cls8{fill:#d6d4d4}.cls9{fill:#dd9e29}.cls10{fill:#b2611d}.cls11{fill:#fff}.cls12{fill:#ffb830}.cls13{fill:#fefefe}.cls14{fill:#003f63}.cls15{fill:#0070b5}.cls16{fill:#006db0}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cls5": "PuppyRunning__cls5",
	"cls6": "PuppyRunning__cls6",
	"cls7": "PuppyRunning__cls7",
	"cls8": "PuppyRunning__cls8",
	"cls9": "PuppyRunning__cls9",
	"cls10": "PuppyRunning__cls10",
	"cls11": "PuppyRunning__cls11",
	"cls12": "PuppyRunning__cls12",
	"cls13": "PuppyRunning__cls13",
	"cls14": "PuppyRunning__cls14",
	"cls15": "PuppyRunning__cls15",
	"cls16": "PuppyRunning__cls16"
};