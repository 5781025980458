import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as ClinicActions from "actions/ClinicActions";
import Dropdown from "components/common/Dropdown";

function SoftwareVendorDropdown(props) {
	useEffect(() => {
		if (!keys(props.pims)?.length) {
			props.loadDefaultPIMS();
		}
	}, []);

	const pimsOptions = map(orderBy(props.pims, ["softwareVendorName"]), (pim) => ({
		name: pim.softwareVendorName,
        value: pim.softwareVendorId
	}));

	const handleChange = ({ name, value }) => {
		const selectedSoftwareVendor = props.pims[value] || {};
		props.onChange({ name, value }, selectedSoftwareVendor)
	}

    return (
        <Dropdown
            name={props.name}
            value={props.value}
            label={props.label}
            onChange={handleChange}
            options={pimsOptions}
            placeholder={props.placeholder}
        />
    )
}

SoftwareVendorDropdown.defaultProps = {
	label: "Software Vendor",
	name: "softwareVendorId",
	placeholder: "--Select Software Vendor--",
	value: null,
}

SoftwareVendorDropdown.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
	value: PropTypes.number,
};

export default connect(
    (state, ownProps) => ({
        pims: state.entities.pims?.config || {},
    }),
    (dispatch) => ({
        loadDefaultPIMS: () => dispatch(ClinicActions.getDefaultPIMSConfigs()),
    })
)(SoftwareVendorDropdown);