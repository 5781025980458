exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SupportHome__root{overflow-y:auto;position:relative;-ms-flex:1;flex:1}.SupportHome__root .SupportHome__premierLogo{-ms-flex:none;flex:none;width:150px;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;margin-left:1em}.SupportHome__root .SupportHome__premierLogo img{width:100%}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/support/pages/SupportHome.scss"],"names":[],"mappings":"AAAA,mBAAM,gBAAgB,kBAAkB,WAAW,MAAM,CAAC,6CAAmB,cAAc,UAAU,YAAY,oBAAoB,aAAa,qBAAqB,uBAAuB,sBAAsB,mBAAmB,eAAe,CAAC,iDAAuB,UAAU,CAAC","file":"SupportHome.scss","sourcesContent":[".root{overflow-y:auto;position:relative;-ms-flex:1;flex:1}.root .premierLogo{-ms-flex:none;flex:none;width:150px;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;margin-left:1em}.root .premierLogo img{width:100%}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "SupportHome__root",
	"premierLogo": "SupportHome__premierLogo"
};