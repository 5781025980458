import React, {useEffect, useMemo, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import keyBy from "lodash/keyBy";
import keys from "lodash/keys";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import * as styles from "./ConfigurePIMS.scss";
import * as ClinicActions from "actions/ClinicActions";
import PIMSForm from "components/admin/forms/PIMSForm";
import SoftwareVendorDropdown from "components/admin/elements/SoftwareVendorDropdown";
import TextBox from "components/common/TextBox";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

const DEFAULT_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";

function PIMSFormContainer(props) {
    const [softwareVendorId, setSoftwareVendorId] = useState(props.clinicPims?.softwareVendorId || null);

    const selectedVendor = useMemo(() => {
        if (keys(props.pims)?.length) {
            return props.pims[softwareVendorId];
        } else return null;
    }, [softwareVendorId, props.pims]);

    const config = useMemo(() => {
        if (selectedVendor?.configurationProperties) {
            const configList = map(selectedVendor.configurationProperties, property => {
                const clinicProperty = props.clinicPims.config[property.name];
                return {
                    name: property.name,
                    value: clinicProperty || property.defaultValue,
                }
            });
            return mapValues(keyBy(configList, "name"), v => v.value);
        } else return {};
    }, [selectedVendor]);

    useEffect(() => {
        if (props.canViewClinicManagement) {
            props.loadDefaultPIMS();
        }
    }, [props.canViewClinicManagement]);

    useEffect(() => {
        if (props.canViewClinicManagement) {
            props.loadClinicPIMS(props.clinicId);
        }
    }, [props.clinicId, props.canViewClinicManagement]);

    useEffect(() => {
        if (props.clinicPims) {
            setSoftwareVendorId(props.clinicPims.softwareVendorId);
        }
    }, [props.clinicPims]);

    const handleChangeSoftwareVendor = ({ value }) => {
        setSoftwareVendorId(value);
    }

    const softwareVendorMatch = props.clinicPims?.softwareVendorId === selectedVendor?.softwareVendorId;

    return (
        <div className={styles.root}>
            <TextBox
                name="installedPims"
                value={props.clinicPims?.installedPims || "None Installed"}
                label="Installed PIMS"
                disabled
                onChange={() => {}}
            />
            <SoftwareVendorDropdown
                label="PIMS"
                name="softwareVendorId"
                onChange={handleChangeSoftwareVendor}
                placeholder="--Not Set--"
                value={selectedVendor?.softwareVendorId || null}
            />
            <hr className="margin-top-md margin-bottom-sm"/>
            {selectedVendor && (
                <PIMSForm
                    key={softwareVendorId}
                    canEditClinicManagement={props.canEditClinicManagement}
                    clinic={props.clinic}
                    configurationProperties={selectedVendor.configurationProperties}
                    defaultForceSync={selectedVendor.defaultForceSync}
                    initialData={{
                        addDaysToLastQueryDate: selectedVendor.defaultDaysToAddToLastQueryDate,
                        deleteExternalFilesAfterTransfer: false,
                        forceSync: selectedVendor.defaultForceSync,
                        invoiceChunkSize: selectedVendor.defaultInvoiceChunkSize,
                        installedPims: null,
                        installedPimsVersion: null,
                        isCloudPims: selectedVendor.isCloudPims,
                        lastSubmittedForCloudProcessing: null,
                        lastQueryDate: moment().subtract(selectedVendor.maxLastQueryDateMonths, "months").format(DEFAULT_DATE_FORMAT),
                        pullProductList: selectedVendor.defaultSetPullProductList,
                        remoteClinicID: null,
                        softwareVendorId: selectedVendor.softwareVendorId,
                        syncIsActive: false,
                        ...(softwareVendorMatch ? props.clinicPims : {}),
                        config: config,
                        clinicId: props.clinicId,
                    }}
                    isCloudPims={selectedVendor.isCloudPims}
                    isDaysToAddToLastQueryDateEditable={selectedVendor.isDaysToAddToLastQueryDateEditable}
                    isInvoiceChunkSizeEditable={selectedVendor.isInvoiceChunkSizeEditable}
                    maxDaysToAddToLastQueryDate={selectedVendor.maxDaysToAddToLastQueryDate}
                    maxLastQueryDateMonths={selectedVendor.maxLastQueryDateMonths}
                    maxLastQueryDate={moment().subtract(selectedVendor.maxLastQueryDateMonths, "months").format(DEFAULT_DATE_FORMAT)}
                    onCancel={props.onCancel}
                    onSubmit={props.onSubmit}
                    requiresRemoteClinicID={selectedVendor.requiresRemoteClinicID}
                    softwareVendorName={selectedVendor.softwareVendorName}
                    supportsConfigurationProperties={selectedVendor.supportsConfigurationProperties}
                    vendorChanged={!softwareVendorMatch}
                />
            )}
        </div>
    );
}

PIMSFormContainer.propTypes = {
	clinicId: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            canEditClinicManagement,
            clinic: state.entities.clinicDetails[ownProps.clinicId],
            clinicPims: state.entities.clinicPims[ownProps.clinicId],
            pims: state.entities.pims?.config,
        }
    },
    (dispatch) => ({
        loadClinicPIMS: (clinicId) => dispatch(ClinicActions.getPIMSForClinic(clinicId)),
        loadDefaultPIMS: () => dispatch(ClinicActions.getDefaultPIMSConfigs()),
    })
)(PIMSFormContainer);