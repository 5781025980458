import React from "react";
import { connect } from "react-redux";
import styles from "./VetCheckDashboard.scss";
import ClinicBase from "components/layout/ClinicBase";
import VetCheckPlugIn from "components/vetcheck/widgets/VetCheckPlugIn";
import VetCheckHeader from "components/vetcheck/widgets/VetCheckHeader";
import {getClinicInfo} from "utils/ClinicData";
import {CLINIC_TAGS} from "constants/AdminControls";

function VetCheckDashboard(props) {

    return (
        <ClinicBase
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle={"VetCheck - Dashboard"}
            overflowHidden
        >
            <div className={styles.root}>
                <VetCheckHeader
                    clinicId={props.clinicId}
                    location={props.location}
                />
                <VetCheckPlugIn
                    clinicId={props.clinicId}
                />
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        return {
            clinicId,
        }
    }
)(VetCheckDashboard);
