import React from "react";
import PropTypes from "prop-types";
import {autobind} from "core-decorators";
import classnames from "classnames";
import styles from "./BasicGridTiles.scss";
import { map } from "lodash";
import GridTile from "./GridTile";

class BasicGridTiles extends React.Component {

    state = {
        currentRange: { name: "Last 30 days", value: "days30" },
    }

    @autobind
    renderTiles() {
        if(this.props.tilesData) {
            return map(this.props.tilesData, (tile, indx) => (this.renderTile(tile, indx)));
        }

        return this.props.tiles;
    }

    renderTile(tile, indx) {

        return (
            <GridTile
                key={`tile_${indx}`}
                {...tile}
            />
        );
    }

    render(){
        return (
            <div className={classnames(styles.root, {
                [styles.medium]: this.props.medium,
                [styles.large]: this.props.large,
            })}>
                {this.renderTiles()}
            </div>
        );
    }
}

BasicGridTiles.propTypes = {
    tiles: PropTypes.array,
    maxTileWidth: PropTypes.string,
    minTileWidth: PropTypes.string,
    tilesData: PropTypes.array,
    medium: PropTypes.bool,
    large: PropTypes.bool,
    full: PropTypes.bool,
};

export default BasicGridTiles;

