exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SafetyInformation__root{padding:5rem 10rem}.SafetyInformation__root .SafetyInformation__body{font-size:2rem}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/SafetyInformation.scss"],"names":[],"mappings":"AAAA,yBAAM,kBAAkB,CAAC,kDAAY,cAAc,CAAC","file":"SafetyInformation.scss","sourcesContent":[".root{padding:5rem 10rem}.root .body{font-size:2rem}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "SafetyInformation__root",
	"body": "SafetyInformation__body"
};