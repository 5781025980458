import React from "react";
import { useHistory, withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import * as QueryString from "query-string";
import styles from "./ProviderWellnessPlans.scss";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import ClinicWellnessPlans from "components/wellness/widgets/ClinicWellnessPlans";
import GreenlineWellnessPlans from "components/greenlineWellness/GreenlineWellnessPlans";
import ProviderBase from "components/layout/ProviderBase";
import { getNodeId } from "utils/AdminData";
import { getClinicInfo } from "utils/ClinicData";
import getProviderId from "utils/getProviderId";
import * as ProviderLinks from "utils/ProviderLinks";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { GREENLINE_WELLNESS, PREMIER_PET_CARE_PLAN } from "constants/ProviderIds";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

function ProviderWellnessPlans(props)  {
    const history = useHistory();

    const goToWellnessSettings = () => {
        history.push(ProviderLinks.getLinkLocation("wellness-settings", {
            providerId: props.providerId,
            nodeId: props.nodeId,
            isAdmin: props.isAdmin,
            clinicId: props.clinicId
        }));
    }

    const handleSelectProvider = (providerId) => {
        history.push(`plans?providerId=${providerId}`)
    };

    return (
        <ProviderBase
            location={props.location}
            clinicId={props.clinicId}
            pageTitle="Wellness Plan Setup"
            collapsible
            searchConfig={SearchTypes.TOOLBAR_CLINIC_SEARCH}
            nodeId={props.nodeId}
            providerId={props.providerId}
        >
            <div className={styles.root}>
                {props.canViewClinicWellnessPlans ? (
                    <>
                        <div className="topLine flex spaced-content">
                            <div className="flex-1 flex">
                                <Button
                                    small
                                    icon
                                    className="margin-right-x-sm"
                                    type="primary"
                                    onClick={goToWellnessSettings}
                                >
                                    <i className="fa fa-reply"/> Back To Clinic Menu
                                </Button>
                            </div>
                        </div>
                        {(props.selectedProvider === PREMIER_PET_CARE_PLAN) ? (
                            <ClinicWellnessPlans clinicId={props.clinicId} />
                        ) : props.selectedProvider === GREENLINE_WELLNESS ? (
                            <GreenlineWellnessPlans clinicId={props.clinicId} />
                        ) : (
                            <div className="flex flex-1 flex-centered height-100-perc full-width">
                                <div>
                                    <h2 className="text-center padding-bottom-md">Select Wellness Provider</h2>
                                    <div className="flex height-100-perc full-width spaced-content flex-centered">
                                        <div
                                            onClick={() => handleSelectProvider(GREENLINE_WELLNESS)}
                                            className={styles.tile}
                                        >
                                            Greenline Wellness
                                        </div>
                                        <div
                                            onClick={() => handleSelectProvider(PREMIER_PET_CARE_PLAN)}
                                            className={styles.tile}
                                        >
                                            Premier Wellness
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                   <AccessDenied/>
                )}
            </div>
        </ProviderBase>
    )
}


const connector = connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const search = QueryString.parse(ownProps.location.search);
        const clinicId = Number(params?.clinicId) || null;
        const adminClinicId = Number(params?.clinicId) || null;
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const userProfile = state.user.userProfile;
        const nodeId = getNodeId(state, ownProps);
        const providerId = getProviderId(params, userProfile);
        const wellnessProviders = clinicInfo?.programs?.Wellness?.providers || [];
        let selectedProvider = parseInt(search?.providerId) || null;
        if (wellnessProviders.length === 1) {
            selectedProvider = wellnessProviders[0];
        }

        // Permissions
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);

        return {
            clinicId,
            nodeId,
            providerId,
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && providerId && nodeId),

            // Permissions
            canViewClinicWellnessPlans,
            selectedProvider,
        }
    }
);

export default compose(
    withRouter,
    connector
)(ProviderWellnessPlans);
