import filter from "lodash/filter";
import forEach from "lodash/forEach";
import keyBy from "lodash/keyBy";
import keys from "lodash/keys";
import logger from "utils/logger";

export function getParInvoicesByProvider(state) {
    const {entities: { preAuditReview }} = state;
    if (!keys(preAuditReview).length) {
        return null;
    }

    const providers = {}
    forEach(preAuditReview, issue => {
        providers[issue.providerId] = {
            ...providers[issue.providerId],
            [issue.invoiceId]: issue,
        }
    });

    return providers;
}

export function getProgramProviders(state) {
    const { entities: { providers }} = state;
    if (keys(providers).length === 0) {
        return null;
    }
    const filteredProviders = filter(providers, { visibleInProviderSetup: true });
    return keyBy(filteredProviders, "id");
}

export function getProvidersWithDashboardInfo(state) {
    const { entities: { providers }} = state;
    if (keys(providers).length === 0) {
        return null;
    }
    const filteredProviders = filter(providers, provider => {
        return !!(provider?.isCouponProvider || provider?.isWellnessProvider || provider?.isReportingProvider);
    });

    return keyBy(filteredProviders, "id");
}

export function getProgramOffersByProvider(state, providerId) {
    const { entities: { programs }} = state;
    if(!keys(programs).length ||
        !programs[providerId] ||
        !programs[providerId].offers) {
        return null;
    }

    return programs[providerId].offers;
}

export function getClinicProductOverrides(state, clinicId, productId) {
    const { entities: { productOverrides }} = state;
    if (keys(productOverrides).length === 0 || productOverrides.loading) {
        return null;
    }

    return productOverrides[clinicId] ? productOverrides[clinicId][productId] : null;
}

export function getClinicOverrides(state, clinicId) {
    const { entities: { productOverrides }} = state;
    if (keys(productOverrides).length === 0 || productOverrides.loading) {
        return null;
    }

    return productOverrides[clinicId];
    // let filteredProductOverrides = {...productOverrides};
    // filteredProductOverrides[clinicId] = reject(productOverrides[clinicId], product => {logger.log(product); return !product.clinicProducts.length});
    // return filteredProductOverrides[clinicId];
}

export function getVouchersGenerated(state, planId) {
    const { entities: { vouchers }} = state;
    if (!planId || !keys(vouchers).length || !vouchers[planId]) {
        return 0;
    }

    return vouchers[planId].generated;
}

export function getNodeId(state, ownProps) {
    if (!state?.user?.userProfile) {
        return null;
    }
    const params = !!ownProps?.match?.params ? ownProps.match.params : {};

    if(!!params?.nodeId) {
        //Get the nodeId from the URL
        return Number(params.nodeId);
    } else if(ownProps.nodeId) {
        return ownProps.nodeId;
    } else if (!!state?.user?.userProfile?.nodeId) {
        //Get the nodeId from the UserProfile
        return state.user.userProfile.nodeId;
    } else {
        // logger.log("Couldn't find nodeId");
    }

    return null;
}
