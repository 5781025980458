import React, {useCallback, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import map from "lodash/map";
import reject from "lodash/reject";
import * as ClinicApi from "api/ClinicApi";
import * as CouponActions from "actions/CouponActions";
import * as ProviderActions from "actions/ProviderActions";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import Dropdown from "components/common/Dropdown";
import DropdownSearch from "components/common/DropdownSearch";
import TextBox from "components/common/TextBox";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {ROLES} from "constants/RoleTypes";
import * as UserPermissions from "constants/UserPermissions";
import {CLINIC, CORPORATE_GROUP, GREENLINE_STAFF, MFR, WELLNESS, USER_TYPE_NAMES} from "constants/UserTypes";

function UserCreateForm(props) {
    const [formData, setFormData] = useState({"sendWelcomeMessage": true});
    const [password, setPassword] = useState({});
    const [userType, setUserType] = useState(null);
    const [clinicLoading, setClinicsLoading] = useState(null);
    const [clinics, setClinics] = useState(null);
    const [search, setSearch] = useState(null);

    useEffect(() => {
        props.getProviderList();
    }, []);

    useEffect(() => {
        if(!!formData.providerId && !props.hierarchies[formData.providerId]) {
            props.getProviderHierarchies(formData.providerId);
        }
    }, [formData.providerId]);

    const loadClinics = (search) => {
        if(props.canViewClinicManagement) {
            const searchValues = {
                search: search,
                offset: 0,
                limit: 9999,
                orderBy: "clinicName",
                orderDir: "desc",
                filters: [{
                    id: "Active",
                    equality: "==",
                    level1Values: [{
                        value: "Active"
                    }]
                }]

            };

            setClinicsLoading(true);
            ClinicApi.searchClinics(searchValues)
                .then((res) => {
                    setClinics(res.body.data);
                    setClinicsLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("loading clinics", error);
                    setClinicsLoading(false);
                });
        }
    }

    const searchClinics = useCallback(debounce((search) => {
        loadClinics(search);
    }, 500), []);

     useEffect(() => {
         if (!!(search && search.length)) {
             searchClinics(search);
         }
    }, [search]);


    const handleFormChange = ({name, value}) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const checkErrors = useCallback(debounce((password) => {
        if(password.passwordOne && password.passwordTwo && (password.passwordOne !== password.passwordTwo)) {
            setPassword({
                ...password,
                hasError: true,
            });
        } else {
            setPassword({
                ...password,
                hasError: false,
            });
        }
    }, 1000), []);

    const handlePasswordChange = ({name, value}) => {
        const tempPassword = {
            ...password,
            [name]: value,
        };
        setPassword(tempPassword);

        if(tempPassword.passwordOne === tempPassword.passwordTwo) {
            handleFormChange({name:"password", value});
        } else {
            handleFormChange({name:"password", value: null});
        }

        checkErrors(tempPassword);
    };

    const handleChangeUserType = ({name, value}) => {
        setUserType(value);
        setFormData({
            ...formData,
            providerId: null,
            role: null,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData);
    };

    const USER_TYPE_OPTIONS = map(USER_TYPE_NAMES, (role, index) => {
        return {name: role, value: index};
    });

    const ROLE_OPTIONS = filter(ROLES, role => {
        if (role.userType === userType) {
            if(role.userType === CLINIC && !formData.clinicId) {
                if (role.value === "ClinicGuest") {
                    return {name: role.shortName, value: role.value};
                }
            } else {
                return {name: role.shortName, value: role.value};
            }
        }
    });

    const CLINIC_OPTIONS = map(clinics, (clinic) => {
        return { name: `${clinic.clinicName} - ${clinic.city}, ${clinic.state} (${clinic.clinicId})`, value: clinic.clinicId };
    });

    const PROVIDER_OPTIONS = map(props.providers, (provider) => {
        return {name: provider.name, value: provider.id};
    });

    const NODE_OPTIONS = useMemo(() => {
        return map(reject(props.hierarchies?.[formData.providerId], {nodeType: "Clinic"}), (node) => {
            return {name: `(${node.nodeId}) ${node.displayName} (${node.sourceRefCode}) - ${node.nodeType}`, value: node.nodeId}
        });
    }, [formData.providerId, props.hierarchies]);


    return (
        <form onSubmit={handleSubmit}>
            <Dropdown
                label="User Type"
                name="userType"
                value={userType}
                onChange={handleChangeUserType}
                options={USER_TYPE_OPTIONS}
                placeholder="--Select User Type--"
                hidePlaceholder
            />
            {CLINIC === userType && (
                <DropdownSearch
                    options={CLINIC_OPTIONS}
                    name="clinicId"
                    value={formData.clinicId}
                    label="Clinic"
                    placeholder="--Search Clinic--"
                    onChange={handleFormChange}
                    onSearchChange={(value) => setSearch(value)}
                    loading={clinicLoading}
                />
            )}
            {(CORPORATE_GROUP === userType || WELLNESS === userType || MFR === userType ) && (
                <Dropdown
                    options={PROVIDER_OPTIONS}
                    label={MFR === userType ? "Manufacturer" : "Provider"}
                    name="providerId"
                    value={formData.providerId}
                    placeholder={MFR === userType ? "--Select Manufacturer--" : "--Select Provider--"}
                    onChange={handleFormChange}
                />
            )}
            {(!!NODE_OPTIONS?.length && (CORPORATE_GROUP === userType || WELLNESS === userType || MFR === userType)) && (
                <DropdownSearch
                    options={NODE_OPTIONS}
                    label="Node"
                    name="hierarchyNodeId"
                    value={formData.hierarchyNodeId}
                    placeholder="--Select Node--"
                    onChange={handleFormChange}
                    disabled={!formData.providerId || !NODE_OPTIONS?.length}
                />
            )}
            {GREENLINE_STAFF === userType && (
                <TextBox
                    name="location"
                    value="Greenline Support"
                    label="Location"
                    onChange={() => {}}
                    disabled
                />
            )}
            <hr className="margin-top-md margin-bottom-sm"/>
            <TextBox
                name="userName"
                value={formData.userName}
                label="Username"
                onChange={handleFormChange}
                required
                minLength={6}
                pattern="[A-Za-z][A-Za-z0-9-_.@]+"
                title="Minimum 6 character length. Must start with a letter and can contain dashes (-), underscores (_), periods (.), letters (A-Z), or digits (0-9)"
                information="Will be used to Login - Minimum 6 character length. Must start with a letter and can contain dashes (-), underscores (_), periods (.), letters (A-Z), or digits (0-9)"
            />
            <TextBox
                onChange={handleFormChange}
                name="firstName"
                value={formData.firstName}
                label="First Name"
                required
            />
            <TextBox
                onChange={handleFormChange}
                name="lastName"
                value={formData.lastName}
                label="Last Name"
                required
            />
            <TextBox
                onChange={handleFormChange}
                name="email"
                inputType="email"
                value={formData.email}
                label="Email"
                required
            />
            <TextBox
                inputType="password"
                name="passwordOne"
                value={password.passwordOne}
                label="Password"
                onChange={handlePasswordChange}
                hasError={password.hasError}
                required
                minLength={6}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).*$"
                information="Minimum 6 character length. Password must include 1 Uppercase Letter (A-Z), 1 Lowercase Letter (a-z), 1 number (0-9), and 1 special character (!, @, #, $, %, &, *, etc.) "
                title="Minimum 6 character length. Password must include 1 Uppercase Letter (A-Z), 1 Lowercase Letter (a-z), 1 number (0-9), and 1 special character (!, @, #, $, %, &, *, etc.) "
            />
            <TextBox
                inputType="password"
                name="passwordTwo"
                value={password.passwordTwo}
                label="Reenter Password"
                disabled={!password.passwordOne}
                onChange={handlePasswordChange}
                hasError={password.hasError}
                required
                minLength={6}
                pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.*\s).*$"
                information="Minimum 6 character length. Password must include 1 Uppercase Letter (A-Z), 1 Lowercase Letter (a-z), 1 number (0-9), and 1 special character (!, @, #, $, %, &, *, etc.) "
                title="Minimum 6 character length. Password must include 1 Uppercase Letter (A-Z), 1 Lowercase Letter (a-z), 1 number (0-9), and 1 special character (!, @, #, $, %, &, *, etc.) "
            />
            <Dropdown
                onChange={handleFormChange}
                name="role"
                value={formData.role}
                label="Role"
                placeholder="--Select Role--"
                information={!!(userType === CLINIC && !formData.clinicId) && "Select a Clinic to see all Role options"}
                options={ROLE_OPTIONS}
                disabled={!userType}
                dropdownDirection="up"
            />
            {!(CLINIC === userType || GREENLINE_STAFF === userType) && (
                <div className="margin-top-sm">
                    <CheckboxInput
                        checked={formData.sendWelcomeMessage}
                        name="sendWelcomeMessage"
                        onChange={handleFormChange}
                        label="Send Welcome Message to Email Provided?"
                        leftLabel
                    />
                </div>
            )}
            <div className="flex justify-flex-end spaced-content margin-top-sm">
                <Button onClick={props.onCancel} type="gray" buttonType="button">
                    Cancel
                </Button>
                <Button
                    buttonType="submit"
                    disabled={!Object.values(formData).length || !formData.password}
                >
                    Send Invitation
                </Button>
            </div>
        </form>
    )
}

UserCreateForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            providers: state.entities.providers,
            hierarchies: state.entities?.providerHierarchies
        };
    },
    (dispatch) => ({
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        getProviderHierarchies: (providerId) => dispatch(ProviderActions.getProviderHierarchies(providerId)),
    })
)(UserCreateForm);
