import React from "react";
import {
	HELMET_STRIPE,
	HELMET_HOLE,
	HELMET_BG,
	JERSEY_STRIPE,
	JERSEY_SLEEVE,
	JERSEY_BG,
	TEAM_COLORS
} from "constants/Games/PetsBowl/UniformColors";
import styles from "./KittySleeping.scss";


export default function KittySleeping(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		<svg
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1009.14 319.74"
		>
		    <g className={styles.cls2}>
		        <g className={styles.cls7}>
		            <g>
		                <g className={styles.cls4}>
		                    <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_1_0_Layer2_0_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  1 0 Layer2 0 FILL">
		                        <path className={styles.cls11} d="m989.01,281.29c0-5.23-4.93-10.23-14.8-15-9.53-4.6-23-8.68-40.4-12.25-36.8-7.5-81.23-11.25-133.3-11.25s-96.53,3.75-133.3,11.25c-17.4,3.57-30.87,7.65-40.4,12.25-9.87,4.77-14.8,9.77-14.8,15s4.93,10.22,14.8,14.95c9.57,4.63,23.03,8.72,40.4,12.25,36.8,7.5,81.23,11.25,133.3,11.25s96.5-3.75,133.3-11.25c17.37-3.53,30.83-7.62,40.4-12.25,9.87-4.73,14.8-9.72,14.8-14.95Z"/>
		                    </g>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_1_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 1 FILL">
		                    <path className={styles.cls17} d="m954.21,229.44c2.73-.9,4.5-2.63,5.3-5.2.73-2.5.38-4.98-1.05-7.45-1.5-2.63-4.08-4.45-7.75-5.45-1.33-.37-4.62-.83-9.85-1.4-3.27-.4-6.97-1-11.1-1.8-1.13-.23-4.8-1.02-11-2.35-2.33-.5-5.2-1.73-8.6-3.7-3.57-2.03-6.32-3.32-8.25-3.85-4.87-1.33-8.2-.78-10,1.65-1.8,2.4-1.2,5.48,1.8,9.25,1.97,2.43,4.98,4.73,9.05,6.9,1.8.93,5.25,2.47,10.35,4.6,8.93,3.7,15.7,6.2,20.3,7.5,8.93,2.5,15.87,2.93,20.8,1.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_2_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 2 FILL">
		                    <path className={styles.cls13} d="m937.21,229.09c7.1,1.67,12.77,1.78,17,.35,2.73-.9,4.5-2.63,5.3-5.2.73-2.5.38-4.98-1.05-7.45-1.5-2.63-4.08-4.45-7.75-5.45-1.33-.37-4.62-.83-9.85-1.4l-.25-.05c1.13,5.13,1.02,9.6-.35,13.4-.43,1.27-1.45,3.2-3.05,5.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_3_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 3 FILL">
		                    <path className={styles.cls12} d="m686.81,238.34c-3.5.77-6.28,1.67-8.35,2.7-3.47,1.8-5.58,4.18-6.35,7.15-.47,1.83-.08,4.18,1.15,7.05.4.97,1.57,3.2,3.5,6.7,1.63,3.03,5.03,5.12,10.2,6.25,3.43.73,7.2,1,11.3.8,7.03-.3,12.3-.62,15.8-.95,5.83-.53,11.03-1.37,15.6-2.5l1.9-.5c12.13-3.7,16.67-12.42,13.6-26.15-1.47-6.4-2.5-10.05-3.1-10.95-.8-1.13-2.77-1.53-5.9-1.2-3.23.37-9.1,1.45-17.6,3.25-7.9,1.67-13.75,3.02-17.55,4.05-1.23.33-3.58,1.08-7.05,2.25-2.93.97-5.32,1.65-7.15,2.05Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_4_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 4 FILL">
		                    <path className={styles.cls12} d="m819.41,226.89c-1.97,3.63-2.67,8.05-2.1,13.25.63,5.47,2.53,9.42,5.7,11.85.9.7,2.33,1.47,4.3,2.3,2.2.93,3.68,1.63,4.45,2.1,3.23,2.03,6.25,3.57,9.05,4.6,3.23,1.17,6.55,1.8,9.95,1.9,2.83.1,7.07.03,12.7-.2,5-.03,9.18.47,12.55,1.5,1.7.5,3.95,1.27,6.75,2.3,3.33,1.27,5.57,2.08,6.7,2.45,6.53,2.17,12.6,3.23,18.2,3.2,8.77-.03,12.28-3.43,10.55-10.2-1.03-4.13-5.1-7.67-12.2-10.6-3.93-1.5-6.83-2.6-8.7-3.3-3.23-1.27-5.62-2.45-7.15-3.55-2.2-1.57-4.05-4.12-5.55-7.65-1.47-4.1-2.65-7.1-3.55-9-3.13-6.7-8.02-11.23-14.65-13.6-4.6-1.6-8.58-2.6-11.95-3-4.17-.47-8.27-.15-12.3.95-4.83,1.3-9.1,3.03-12.8,5.2-4.67,2.73-7.98,5.9-9.95,9.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_5_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 5 FILL">
		                    <path className={styles.cls18} d="m779.21,191.94c-2.4-.1-4.18-.15-5.35-.15-8.3-.03-17,.78-26.1,2.45-1.57.3-4.28.55-8.15.75-3.53.2-6.23.53-8.1,1-6.07,1.53-10.15,6.28-12.25,14.25-1.5,5.67-1.92,12.58-1.25,20.75l.15,2.05c.4,3.63,1.83,6.62,4.3,8.95,1.8,1.63,4.73,3.37,8.8,5.2,3.97,1.77,6.32,2.8,7.05,3.1,2.7,1.07,5.13,1.8,7.3,2.2,1.53.3,3.68.45,6.45.45,3.13.03,5.3.12,6.5.25l6.95.65c4.97,6.4,12.17,10.03,21.6,10.9,5.5.47,14,.28,25.5-.55,6.63-.07,14.63-.55,24-1.45,5.37-.53,13.37-1.37,24-2.5,10.83-1.03,18.15-2.28,21.95-3.75,7.1-2.73,12.38-7.95,15.85-15.65,1.83-4.1,2.98-8.7,3.45-13.8.43-4.93.2-9.95-.7-15.05-1.47-8.27-2.3-12.77-2.5-13.5-1.17-3.9-3.32-7.3-6.45-10.2-6.7-6.17-14.62-10.7-23.75-13.6-9.37-2.97-18.17-3.58-26.4-1.85-14.47,2.47-25.32,4.55-32.55,6.25-12.63,3.03-22.73,7.32-30.3,12.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_6_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 6 FILL">
		                    <path className={styles.cls12} d="m793.56,244.94c-2.37-.13-7.3-.55-14.8-1.25-1.57-.17-8.22-.53-19.95-1.1-8.2-.4-14.8-1.15-19.8-2.25-2.93-.63-6.38-1.78-10.35-3.45-4.53-1.93-8.08-3.9-10.65-5.9l.15,2.05c.4,3.63,1.83,6.62,4.3,8.95,1.8,1.63,4.73,3.37,8.8,5.2,3.97,1.77,6.32,2.8,7.05,3.1,2.7,1.07,5.13,1.8,7.3,2.2,1.53.3,3.68.45,6.45.45,3.13.03,5.3.12,6.5.25l6.95.65c4.97,6.4,12.17,10.03,21.6,10.9,5.5.47,14,.28,25.5-.55,6.63-.07,14.63-.55,24-1.45,5.37-.53,13.37-1.37,24-2.5,10.83-1.03,18.15-2.28,21.95-3.75,7.1-2.73,12.38-7.95,15.85-15.65,1.83-4.1,2.98-8.7,3.45-13.8.43-4.93.2-9.95-.7-15.05-1,.33-4.43,1.55-10.3,3.65-4.57,1.67-12.9,5.5-25,11.5-10.37,5.1-18.82,8.52-25.35,10.25-7,2.03-12.25,3.53-15.75,4.5-6.23,1.7-11.6,2.72-16.1,3.05-4.43.33-9.47.33-15.1,0Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_7_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 7 FILL">
		                    <path className={styles.cls18} d="m921.51,255.09c-1.9-1.73-4.05-2.95-6.45-3.65-2.8-.87-5.47-.8-8,.2-2.7,1.13-4.47,3.27-5.3,6.4-.63,2.5-.62,5.03.05,7.6.6,2.27,1.53,4.05,2.8,5.35,1.53,1.63,3.72,2.77,6.55,3.4,3.2.73,6.05.68,8.55-.15,2.6-.87,4.28-2.42,5.05-4.65.47-1.37.68-2.95.65-4.75,0-1.67-.2-3.3-.6-4.9-.4-1.6-1.5-3.22-3.3-4.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_8_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 8 FILL">
		                    <path className={styles.cls18} d="m692.71,245.09c-3.6-.97-7.25-1.22-10.95-.75-3.3.43-5.93,1.28-7.9,2.55-2.43,1.53-4.18,3.82-5.25,6.85-1.2,3.47-1.25,6.62-.15,9.45,1.1,3,3.25,5.02,6.45,6.05,1.97.63,4.27,1.03,6.9,1.2,2.37.17,4.72.12,7.05-.15,2.33-.3,4.73-1.37,7.2-3.2,2.6-1.93,4.47-4.17,5.6-6.7,1.37-3.1,1.37-6.05,0-8.85-1.47-3.1-4.45-5.25-8.95-6.45Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_9_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 9 FILL">
		                    <path className={styles.cls18} d="m943.71,264.99c3.9-5.3,1.77-10.98-6.4-17.05-2.1-1.57-5.58-3.8-10.45-6.7-4.2-2.5-6.87-4.28-8-5.35-2.1-2-3.7-4.98-4.8-8.95-1.03-4.6-1.9-7.98-2.6-10.15-1.57-4.93-3.4-9.33-5.5-13.2-2.87-5.2-5.8-8.57-8.8-10.1-3.37-1.73-7.23-2.17-11.6-1.3-5.2,1.5-9.33,2.48-12.4,2.95-9.5,1.53-17.58,5.53-24.25,12-7.9,7.63-9.65,15.85-5.25,24.65,3.1,6.13,8.73,11.37,16.9,15.7,7.37,3.87,14.73,6.03,22.1,6.5.57.03,3.97.02,10.2-.05,4-.07,7.35.32,10.05,1.15,3.23,1,6.97,2.47,11.2,4.4,1.43.67,5.05,2.43,10.85,5.3,3.87,1.9,7.1,3.03,9.7,3.4,4.03.6,7.05-.47,9.05-3.2Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_10_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 10 FILL">
		                    <path className={styles.cls18} d="m951.21,258.39c-1.23-2.67-2.97-5-5.2-7-2.7-2.43-5.65-3.9-8.85-4.4-3.47-.57-6.43.32-8.9,2.65-1.93,1.87-3.17,4.13-3.7,6.8-.47,2.37-.35,4.5.35,6.4.87,2.37,2.68,4.65,5.45,6.85,3.13,2.5,6.27,4.1,9.4,4.8,3.27.73,5.87.32,7.8-1.25,2.37-1.9,3.98-4.75,4.85-8.55.37-1.7-.03-3.8-1.2-6.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_11_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 11 FILL">
		                    <path className={styles.cls17} d="m842.06,172.84c-10.97,1.97-19.27,3.52-24.9,4.65-9.93,1.97-18.12,4.3-24.55,7-2.53,2.97-4.65,6.43-6.35,10.4-2.8,6.53-4.08,13.17-3.85,19.9.3,8.6,3.5,13.97,9.6,16.1,5.53,1.97,11.85,2.08,18.95.35,6.13-1.53,11.95-4.13,17.45-7.8,6.1-4.03,11.6-6.65,16.5-7.85,2.17-.53,5.35-.98,9.55-1.35,4.47-.37,7.68-.77,9.65-1.2,6.83-1.43,12.5-4.93,17-10.5,4.9-6.03,6.22-12.28,3.95-18.75l-.6-1.5c-6.43-4.23-13.37-7.2-20.8-8.9-7.7-1.8-14.9-1.98-21.6-.55Z"/>
		                </g>
		                <g className={styles.cls3}>
		                    <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_2_0_Layer2_0_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  2 0 Layer2 0 FILL">
		                        <path className={styles.cls11} d="m809.06,210.99c4.03-2.07,7.77-4.92,11.2-8.55-6.13-3.83-14.68-6.43-25.65-7.8-4.33-.33-7.6-.57-9.8-.7-3.87-.3-7.08-.45-9.65-.45-11.43-.1-20.73.13-27.9.7-11,.9-18.52,2.6-22.55,5.1-1.87,1.2-3.82,4.68-5.85,10.45-2.2,6.27-2.77,11.08-1.7,14.45.7.23,1.65,2.28,2.85,6.15,1.07,3.4,1.85,4.78,2.35,4.15,3.03-3.87,7.58-7.03,13.65-9.5,3.47-1.4,8.67-3.1,15.6-5.1h.1l.1.05c10.97.87,20.67.75,29.1-.35,10.23-1.37,19.62-4.23,28.15-8.6Z"/>
		                    </g>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_13_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 13 FILL">
		                    <path className={styles.cls8} d="m951.46,288.84c10.23-8.77,17.02-20.12,20.35-34.05-7.7-12.77-17.77-21.72-30.2-26.85-11.3-4.67-23.17-5.7-35.6-3.1-12.07,2.5-22.47,7.9-31.2,16.2-9.17,8.7-14.8,19.07-16.9,31.1,5.73,11.63,14.35,20.23,25.85,25.8,10.8,5.2,22.4,7,34.8,5.4,12.53-1.63,23.5-6.47,32.9-14.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_14_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 14 FILL">
		                    <path className={styles.cls23} d="m939.31,250.14l.6-4.95c-6.87-.83-16.18-.23-27.95,1.8-11.3,1.97-19.77,4.3-25.4,7l2.2,4.5c5.17-2.53,13.18-4.73,24.05-6.6,11.27-1.93,20.1-2.52,26.5-1.75Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_15_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 15 FILL">
		                    <path className={styles.cls23} d="m934.56,256.04c.43-.53.62-1.13.55-1.8l-.45-5.3c-.3-4.4-.55-7.38-.75-8.95-.1-.67-.42-1.22-.95-1.65-.57-.43-1.18-.6-1.85-.5-.67.07-1.22.38-1.65.95-.43.53-.62,1.13-.55,1.8.27,2.13.53,5.03.8,8.7.23,3.1.38,4.92.45,5.45.07.53.27,1,.6,1.4.33.37.75.62,1.25.75.23.1.52.12.85.05.7-.03,1.27-.33,1.7-.9Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_16_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 16 FILL">
		                    <path className={styles.cls23} d="m921.01,240.79c-.13-.67-.5-1.18-1.1-1.55-.57-.37-1.18-.48-1.85-.35-.67.17-1.18.53-1.55,1.1-.37.6-.48,1.23-.35,1.9.07.33.6,3.18,1.6,8.55l.95,5.4c.2.97.82,1.6,1.85,1.9.3.1.67.12,1.1.05.67-.17,1.2-.53,1.6-1.1.37-.57.48-1.18.35-1.85l-.95-5.25c-.6-3.7-1.15-6.63-1.65-8.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_17_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 17 FILL">
		                    <path className={styles.cls23} d="m908.61,242.94c-.13-.67-.5-1.18-1.1-1.55-.57-.37-1.18-.48-1.85-.35-.67.17-1.2.53-1.6,1.1-.37.57-.48,1.18-.35,1.85.37,1.73.88,4.6,1.55,8.6l.95,5.35c.2,1,.82,1.65,1.85,1.95.3.1.67.12,1.1.05.67-.13,1.2-.48,1.6-1.05.4-.6.52-1.23.35-1.9l-.9-5.2c-.53-3.33-1.07-6.28-1.6-8.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_18_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 18 FILL">
		                    <path className={styles.cls23} d="m894.41,245.04c-.63-.33-1.28-.4-1.95-.2-.67.17-1.17.55-1.5,1.15-.33.63-.4,1.27-.2,1.9.53,1.93,1.22,4.77,2.05,8.5l1.25,5.3c.27.93.87,1.53,1.8,1.8.43.1.85.1,1.25,0,.67-.17,1.18-.55,1.55-1.15.33-.6.42-1.23.25-1.9-.33-1.27-.73-3-1.2-5.2-1.03-4.4-1.75-7.3-2.15-8.7-.17-.67-.55-1.17-1.15-1.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_19_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 19 FILL">
		                    <path
			                    className={styles.cls20}
			                    d="m818.06,176.29c-16.33,3.5-29.58,7.98-39.75,13.45-1.27,6.57-2.4,11.28-3.4,14.15-1.63,4.83-3.88,8.68-6.75,11.55-7,7-19.63,9.62-37.9,7.85-.3-.03-1.62,2.12-3.95,6.45-2.3,4.27-3.62,6.35-3.95,6.25-.67-.2-1.48-3.1-2.45-8.7-.97-5.6-1.82-8.53-2.55-8.8-.87,3.4.32,12.8,3.55,28.2.97,4.6,8.43,9.33,22.4,14.2,12.97,4.47,23.52,6.63,31.65,6.5,9.93-.2,22.18-.82,36.75-1.85,15.73-1.1,26.58-1.75,32.55-1.95,9.53-14.43,15.28-28.98,17.25-43.65,1.93-14.73.22-30.82-5.15-48.25-10.97-.07-23.73,1.47-38.3,4.6Z"
			                    style={{ fill: teamColors[JERSEY_BG] }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_20_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 20 FILL">
		                    <path
			                    className={styles.cls15}
			                    d="m825.11,175.39c-3.2,1.03-5.73,2.07-7.6,3.1-3.27,2.53-8.93,10.05-17,22.55-8.1,12.5-12.3,20.27-12.6,23.3-.33,3.53.85,11.38,3.55,23.55,2.77,12.53,4.87,18.17,6.3,16.9,6.67-4.27,9.32-10.58,7.95-18.95-.57-3.37-2.13-7.63-4.7-12.8,12.6-6.4,21.73-17.28,27.4-32.65,2.07-5.57,3.43-11.12,4.1-16.65.57-4.73.55-8.12-.05-10.15-2.03.3-4.48.9-7.35,1.8Z"
			                    style={{ fill: teamColors[JERSEY_STRIPE] }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_21_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 21 FILL">
		                    <path className={styles.cls18} d="m798.71,237.69c-1-.97-3.58-1.17-7.75-.6-4.17.57-11.97,2.02-23.4,4.35-10.03,2.07-17.8,3.75-23.3,5.05-1.67.4-4.8,1.27-9.4,2.6-3.9,1.1-7.07,1.9-9.5,2.4-3.9.83-6.82,1.73-8.75,2.7-3.07,1.5-5.17,3.62-6.3,6.35-.83,2.07-1.2,4.25-1.1,6.55.13,2.53.82,4.55,2.05,6.05,2.07,2.53,6.5,4.07,13.3,4.6,4.5.37,9.47.3,14.9-.2,9.97-.97,16.93-1.7,20.9-2.2,7.8-.97,14.72-2.15,20.75-3.55l2.5-.6c16.2-4.3,22.47-12.37,18.8-24.2-1.7-5.47-2.93-8.57-3.7-9.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_22_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 22 FILL">
		                    <path className={styles.cls18} d="m733.66,277.59c3-2.03,5.22-4.45,6.65-7.25,1.73-3.33,1.93-6.67.6-10-1.43-3.6-4.63-6.23-9.6-7.9-3.93-1.33-7.97-1.87-12.1-1.6-3.67.23-6.67,1-9,2.3-2.77,1.57-4.83,4.03-6.2,7.4-1.53,3.83-1.8,7.4-.8,10.7,1.07,3.43,3.33,5.85,6.8,7.25,2.13.87,4.67,1.48,7.6,1.85,2.6.33,5.22.43,7.85.3s5.37-1.15,8.2-3.05Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_23_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 23 FILL">
		                    <path
			                    className={styles.cls22}
			                    d="m799.06,237.99c-2.3-5.17-7.55-9.92-15.75-14.25-6.63-3.53-12.03-5.5-16.2-5.9-4-.37-8.88.75-14.65,3.35-6.27,2.87-9.62,6.02-10.05,9.45-.4,3.37.37,6.48,2.3,9.35,2,3,4.57,4.57,7.7,4.7,24.7-5.77,40.25-8,46.65-6.7Z"
			                    style={{ fill: teamColors[HELMET_STRIPE] }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_24_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 24 FILL">
		                    <path
			                    className={styles.cls21}
			                    d="m782.81,189.79l-6-.25c-.6,13.77-6.62,22.97-18.05,27.6-4.07,1.67-9.18,2.88-15.35,3.65-6.8.6-11.63,1.05-14.5,1.35l-1.45.15-.8,1.25c-2.1,3.5-3.45,6.63-4.05,9.4-.7-3.23-.9-6.22-.6-8.95.53-2.9.93-5.1,1.2-6.6l-6.45.5c-1.07,7.93-.65,15.4,1.25,22.4.97,3.5,2.03,6.22,3.2,8.15.93-2.63,2.05-5.47,3.35-8.5,2.57-6.07,4.78-10.08,6.65-12.05,3.37-.47,8.8-1.22,16.3-2.25,6.67-1.13,12.15-2.72,16.45-4.75,11.9-5.57,18.18-15.93,18.85-31.1Z"
			                    style={{ fill: teamColors[JERSEY_STRIPE] }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_25_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 25 FILL">
		                    <path
			                    className={styles.cls21}
			                    d="m843.86,172.29l-6.05.65c5.4,14.3,6.58,30.77,3.55,49.4-2.87,17.37-8.35,31.47-16.45,42.3l7.65-.5c8.5-12.53,13.87-27.37,16.1-44.5,2.23-17.5.63-33.28-4.8-47.35Z"
			                    style={{ fill: teamColors[JERSEY_STRIPE] }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_26_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 26 FILL">
		                    <path className={styles.cls17} d="m610.31,192.49c-.47.1-.65.38-.55.85.07.5.33.68.8.55,10.6-2.33,20.42-3.33,29.45-3,10.67.43,19.82,2.75,27.45,6.95.4.23.75.13,1.05-.3.3-.43.23-.77-.2-1-7.8-4.3-17.13-6.67-28-7.1-9.2-.37-19.2.65-30,3.05Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_27_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 27 FILL">
		                    <path className={styles.cls17} d="m611.41,219.29c-.47.17-.67.48-.6.95.1.47.38.62.85.45,20.33-7.67,39.48-11.37,57.45-11.1.47.03.73-.2.8-.7.03-.5-.2-.75-.7-.75-18.3-.27-37.57,3.45-57.8,11.15Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_28_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 28 FILL">
		                    <path className={styles.cls17} d="m619.76,236.04c-.43.27-.55.6-.35,1,.23.43.57.52,1,.25,7-4.23,14.87-8.05,23.6-11.45,6.97-2.7,15.23-5.4,24.8-8.1.47-.13.67-.43.6-.9-.1-.47-.38-.63-.85-.5-9.53,2.67-17.87,5.38-25,8.15-8.8,3.43-16.73,7.28-23.8,11.55Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_29_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 29 FILL">
		                    <path className={styles.cls13} d="m723.96,47.54c-4.3,2.37-9.22,6.78-14.75,13.25-2.47,2.9-6.58,8.23-12.35,16-4.77,6.43-9.87,15.4-15.3,26.9-5.53,11.73-9.43,21.92-11.7,30.55,5.13,4.1,18.07,3.67,38.8-1.3,8.07-1.93,15.28-4.12,21.65-6.55,6.4-2.47,9.92-4.42,10.55-5.85.87-2.03,1.32-3.48,1.35-4.35.03-.4-.17-1.92-.6-4.55-.4-2.53-2.75-11.9-7.05-28.1-6.47-24.3-10-36.3-10.6-36Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_30_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 30 FILL">
		                    <path className={styles.cls13} d="m723.96,47.54c-4.3,2.37-9.22,6.78-14.75,13.25-2.47,2.9-6.58,8.23-12.35,16-4.77,6.43-9.87,15.4-15.3,26.9-5.53,11.73-9.43,21.92-11.7,30.55,5.13,4.1,18.07,3.67,38.8-1.3,8.07-1.93,15.28-4.12,21.65-6.55,6.4-2.47,9.92-4.42,10.55-5.85.87-2.03,1.32-3.48,1.35-4.35.03-.4-.17-1.92-.6-4.55-.4-2.53-2.75-11.9-7.05-28.1-6.47-24.3-10-36.3-10.6-36Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_31_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 31 FILL">
		                    <path className={styles.cls19} d="m831.91,150.79l-.2-2.95c-7.5.87-15.85,1.1-25.05.7l-11.55-.85c-34.93-7.13-53.27-17.07-55-29.8-1.17-8.27,4.43-16.98,16.8-26.15-6.63-.1-13.15.25-19.55,1.05-6.7,8.8-14.9,16.82-24.6,24.05-15.47,11.57-32.48,19.18-51.05,22.85-1.8,5.13-3.18,11.33-4.15,18.6-.3,2.4-.93,8.8-1.9,19.2-.23,2.67-.9,5.88-2,9.65-1.27,4.27-2.03,7.23-2.3,8.9-1.2,7.3.68,15.88,5.65,25.75,6.03,12,14.62,19.93,25.75,23.8,4.03,1.4,8.77,2.38,14.2,2.95,3.1.3,7.47.6,13.1.9,8.97.67,18.9.17,29.8-1.5,9.53-1.5,19.32-3.8,29.35-6.9,6.5-2.03,13.88-5.4,22.15-10.1,8.83-5.1,15.4-9.93,19.7-14.5,8.5-9,14.4-19.82,17.7-32.45,2.53-9.73,3.58-20.8,3.15-33.2Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_32_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 32 FILL">
		                    <path className={styles.cls17} d="m797.61,95.59c-5.87-4.13-11.37-6.72-16.5-7.75-7.57-1.5-15.63-.2-24.2,3.9-12.37,9.17-17.97,17.88-16.8,26.15,1.73,12.73,20.07,22.67,55,29.8l11.55.85c9.2.4,17.55.17,25.05-.7-.93-11.5-4.68-21.78-11.25-30.85-5.23-7.23-12.85-14.37-22.85-21.4Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_33_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 33 FILL">
		                    <path className={styles.cls17} d="m714.21,97.69c-7.3,2.27-13.57,4.98-18.8,8.15-7.67,4.73-13.97,9.25-18.9,13.55-6.97,6.1-11.62,12.13-13.95,18.1l-.85,2.2c18.57-3.67,35.58-11.28,51.05-22.85,9.7-7.23,17.9-15.25,24.6-24.05-8.2,1-15.92,2.63-23.15,4.9Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_34_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 34 FILL">
		                    <path className={styles.cls17} d="m783.36,193.99c.07-.43-.13-.68-.6-.75-10.8-1.33-20.75-1.45-29.85-.35-10.77,1.3-19.92,4.27-27.45,8.9-.4.27-.45.58-.15.95.3.4.65.47,1.05.2,7.37-4.53,16.33-7.43,26.9-8.7,8.9-1.1,18.65-1,29.25.3.5.07.78-.12.85-.55Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_35_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 35 FILL">
		                    <path className={styles.cls17} d="m783.36,218.99c.07-.43-.13-.72-.6-.85-20.37-5.47-39.62-7.32-57.75-5.55-.47.07-.67.33-.6.8.07.47.33.67.8.6,17.9-1.77,37.02.07,57.35,5.5.47.13.73-.03.8-.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_36_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 36 FILL">
		                    <path className={styles.cls17} d="m775.56,235.29c.2-.43.08-.75-.35-.95-7.13-3.37-15.15-6.27-24.05-8.7-7.07-1.93-15.43-3.77-25.1-5.5-.47-.07-.75.12-.85.55-.07.47.15.73.65.8,9.5,1.67,17.78,3.48,24.85,5.45,8.8,2.4,16.77,5.28,23.9,8.65.43.2.75.1.95-.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_37_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 37 FILL">
		                    <path className={styles.cls9} d="m672.01,217.34c-1.2,3.37-2.78,6.47-4.75,9.3.63,1.97,1.88,3.92,3.75,5.85,1.83,1.9,3.65,3.17,5.45,3.8,2,.67,3.43.32,4.3-1.05,1.03-1.57,1.12-4.42.25-8.55-1.37.1-3.05-.73-5.05-2.5-2.23-2.03-3.55-4.32-3.95-6.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_38_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 38 FILL">
		                    <path className={styles.cls17} d="m673.11,216.44c-.33-1.5-.57-2.65-.7-3.45-.23-1.37-.3-2.52-.2-3.45.23-.77.33-1.32.3-1.65-.03-.3-.28-.63-.75-1-.53-.37-.95-.48-1.25-.35-.83.33-1.12,1.48-.85,3.45.17,1.1.28,2.68.35,4.75.03,2.4,0,4.03-.1,4.9-.23,1.87-.82,3.33-1.75,4.4-.83.93-1.8,1.47-2.9,1.6-1.13.17-2.15-.17-3.05-1-.67-.63-1.18-1.7-1.55-3.2-.1-.57-.23-1.05-.4-1.45-.27-.77-.55-1.12-.85-1.05-.53.13-.72.7-.55,1.7.03.27.23.93.6,2,.57,1.8,1.5,3.23,2.8,4.3,1.53,1.27,3.12,1.52,4.75.75,1.27-.6,2.38-1.75,3.35-3.45.53-.9.92-1.6,1.15-2.1.37-.83.48-1.58.35-2.25,1.3,3.8,3.88,6.35,7.75,7.65,1.7.57,3.5.77,5.4.6,4.57-.4,7.97-2.5,10.2-6.3.63-1.03,1.18-2.38,1.65-4.05.6-2.17.38-3.32-.65-3.45-.4-.03-.78.55-1.15,1.75l-.5,2.2c-.7,2.3-1.63,3.98-2.8,5.05-1.43,1.27-3.25,2.13-5.45,2.6-2.2.43-4.23.33-6.1-.3-3.9-1.33-6.27-4.4-7.1-9.2Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_39_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 39 FILL">
		                    <path className={styles.cls11} d="m692.26,165.14c12.87,11.53,24.72,13.43,35.55,5.7,5.43-3.83,9.57-8.83,12.4-15-7.73,7.93-18.05,11.87-30.95,11.8-4-.03-8.03-.45-12.1-1.25l-4.9-1.25Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_40_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 40 FILL">
		                    <path className={styles.cls11} d="m672.41,173.19c2.77-1.8,5.17-4.75,7.2-8.85-3.63,2.57-7.4,3.83-11.3,3.8-4.63-.03-7.95-2.18-9.95-6.45-.47,3.33-.03,6.22,1.3,8.65,1.27,2.3,3.07,3.75,5.4,4.35,2.43.6,4.88.1,7.35-1.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_41_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 41 FILL">
		                    <path className={styles.cls16} d="m665.41,193.59c-1.67.07-3.1.57-4.3,1.5-2.43,1.9-3.35,4.28-2.75,7.15.77,3.83,3.4,5.97,7.9,6.4,3.93.4,7.52-.4,10.75-2.4,2.93-1.8,4.98-3.8,6.15-6,1.4-2.67.7-4.6-2.1-5.8-1.03-.43-2.7-.62-5-.55-3.27.13-5.03.18-5.3.15-2.43-.33-4.22-.48-5.35-.45Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_42_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 42 FILL">
		                    <path
			                    className={styles.cls20}
			                    d="m797.06,139.14c-5.73-2.6-11.27-5.97-16.6-10.1-12.17-9.4-18.45-18.2-18.85-26.4-.47-9.73,8.58-15.33,27.15-16.8-12.6-5.93-25.62-9.38-39.05-10.35-20.2-1.47-39.12,3.02-56.75,13.45-10.93,6.5-18.95,14.72-24.05,24.65-4.13,8.03-6.72,18.13-7.75,30.3,9.3-3.73,20.15-6.25,32.55-7.55,12.3-1.27,24.28-1.12,35.95.45,12.33,1.63,22.6,4.67,30.8,9.1,9.1,4.87,14.85,11.03,17.25,18.5,2.03,5.23,3.83,11.7,5.4,19.4.9,4.37,2.03,10.6,3.4,18.7,1.23,6.6,2.5,11.38,3.8,14.35,1.73,3.87,3.98,5.97,6.75,6.3,3.13.37,6.55-.4,10.25-2.3,3.97-2.07,7.65-5.15,11.05-9.25,8.3-10.07,13.03-23.55,14.2-40.45,1.23-18.43-.5-33.83-5.2-46.2,1.73,13.07-3.02,19.3-14.25,18.7-4.7-.23-10.05-1.73-16.05-4.5Z"
			                    style={{ fill: teamColors[HELMET_BG] }}
		                    />
		                </g>
		                <path
			                id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_43_1_STROKES"
			                data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 43 1 STROKES"
	                        className={styles.cls1}
			                d="m808.71,97.99c-8.47-6.37-16.97-10.07-25.5-11.1-8.53-1.07-14.62.82-18.25,5.65-3.63,4.83-3.73,11.2-.3,19.1,3.4,7.87,9.33,14.98,17.8,21.35,8.47,6.33,16.95,10.03,25.45,11.1,8.53,1.07,14.62-.82,18.25-5.65,3.63-4.87,3.75-11.23.35-19.1-3.43-7.9-9.37-15.02-17.8-21.35Z"
			                style={{ stroke: teamColors[HELMET_HOLE] }}
		                />
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_44_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 44 FILL">
		                    <path
			                    className={styles.cls22}
			                    d="m746.76,75.44c-3.57-.3-7.4-.33-11.5-.1-5.4.33-9.75,1.1-13.05,2.3-8.63,5.2-14.42,13.72-17.35,25.55-2.17,8.9-2.85,20.73-2.05,35.5l15.25-1.8c1.23-12.67,4.67-24.83,10.3-36.5,5.3-10.97,11.43-19.28,18.4-24.95Z"
			                    style={{ fill: teamColors[HELMET_STRIPE] }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_45_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 45 FILL">
		                    <path className={styles.cls14} d="m791.36,172.84c-.87-.57-1.82-.83-2.85-.8-1.07.07-1.98.45-2.75,1.15l-.55.5c-7.3-19.2-14.58-30.88-21.85-35.05-9.5-5.47-23.57-8.68-42.2-9.65-21.93-1.17-43.13,1.65-63.6,8.45-1.3.43-2.27,1.27-2.9,2.5-.6,1.23-.68,2.5-.25,3.8.43,1.3,1.27,2.27,2.5,2.9,1.23.6,2.5.68,3.8.25,18.5-6.13,37.88-8.82,58.15-8.05,17.7.67,30.88,3.48,39.55,8.45,2.17,1.27,4.55,3.95,7.15,8.05,3.73,5.93,7.6,14.38,11.6,25.35-10.6,8.93-20.82,15.7-30.65,20.3-13.93,6.6-27.9,9.42-41.9,8.45-12.9-9.57-25.77-14.32-38.6-14.25-12.87.03-25.57,4.92-38.1,14.65-1,.77-1.58,1.77-1.75,3-.17,1.23.13,2.35.9,3.35.77.97,1.77,1.53,3,1.7s2.35-.13,3.35-.9l.2-.15c6.43,11.73,11.57,24.9,15.4,39.5-1.3.17-2.33.8-3.1,1.9-.7,1.03-.93,2.15-.7,3.35.2,1.23.82,2.2,1.85,2.9,5.37,3.67,12.03,6.2,20,7.6,8,1.37,16.75,1.48,26.25.35,6.7-.8,14.2-2.13,22.5-4,11.73-2.6,20.58-5.43,26.55-8.5,7.73-3.97,16.62-11.37,26.65-22.2,10.03-10.83,20.07-23.85,30.1-39.05.43-.7.68-1.48.75-2.35.27-11.5-2.57-19.33-8.5-23.5m-2.9,10c1.6,2.7,2.4,6.7,2.4,12-8.47,12.73-16.98,23.93-25.55,33.6-8.53,9.6-16.18,16.63-22.95,21.1l-8.05-34.3c10.47-2.93,20.95-7.83,31.45-14.7,6.8-4.47,14.37-10.37,22.7-17.7m-149.1,29.95c9.33-5.93,18.68-8.78,28.05-8.55,9.33.27,18.8,3.62,28.4,10.05-6.4,15.93-8.32,32.4-5.75,49.4-13.37,1.33-24.35.05-32.95-3.85-4.3-17.8-10.22-33.48-17.75-47.05m62.45,5.4l.75.15c8.23.7,16.55.22,24.95-1.45l8.5,36.15c-4.73,2.1-11.2,4.12-19.4,6.05-6.37,1.47-12.9,2.72-19.6,3.75-2.3-15.4-.7-30.28,4.8-44.65Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_46_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 46 FILL">
		                    <path className={styles.cls17} d="m852.01,69.54c-4.83-.83-11.45-.38-19.85,1.35-3.77.77-10.32,2.45-19.65,5.05-13.6,3.83-22.15,6.38-25.65,7.65-10.23,3.7-17.73,7.93-22.5,12.7,1.53,6.83,10.52,16.37,26.95,28.6,6.6,4.9,12.63,8.83,18.1,11.8,5.67,3.1,9.18,4.27,10.55,3.5,1.97-1.1,3.25-2.02,3.85-2.75.27-.3,1.03-1.67,2.3-4.1,1.37-2.53,5.33-11.72,11.9-27.55,10-24.07,14.67-36.15,14-36.25Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_47_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 47 FILL">
		                    <path className={styles.cls16} d="m787.66,101.89l-5.1,3,35.2,25.15,21.85-48.35c-11.23,1.8-24.12,6.2-38.65,13.2-4.5,2.2-8.93,4.53-13.3,7Z"/>
		                </g>
		            </g>
		        </g>
		    </g>
		    <g>
		        <g className={styles.cls6}>
		            <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_1_0_Layer2_0_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  1 0 Layer2 0 FILL">
		                <path className={styles.cls11} d="m484.33,281.29c0-5.23-4.93-10.23-14.8-15-9.53-4.6-23-8.68-40.4-12.25-36.77-7.5-81.2-11.25-133.3-11.25s-96.5,3.75-133.3,11.25c-17.37,3.57-30.83,7.65-40.4,12.25-9.87,4.77-14.8,9.77-14.8,15s4.93,10.22,14.8,14.95c9.57,4.63,23.03,8.72,40.4,12.25,36.8,7.5,81.23,11.25,133.3,11.25s96.5-3.75,133.3-11.25c17.4-3.53,30.87-7.62,40.4-12.25,9.87-4.73,14.8-9.72,14.8-14.95Z"/>
		            </g>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_1_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 1 FILL">
		            <path className={styles.cls17} d="m405.53,202.09c-3.57-2.03-6.33-3.32-8.3-3.85-4.87-1.33-8.2-.78-10,1.65-1.77,2.4-1.15,5.48,1.85,9.25,1.93,2.43,4.95,4.73,9.05,6.9,1.77.9,5.22,2.43,10.35,4.6,8.83,3.67,15.58,6.17,20.25,7.5,8.93,2.5,15.87,2.93,20.8,1.3,2.73-.9,4.5-2.63,5.3-5.2.73-2.5.38-4.98-1.05-7.45-1.5-2.63-4.08-4.45-7.75-5.45-1.3-.33-4.57-.8-9.8-1.4-3.3-.4-7.02-1-11.15-1.8-1.17-.23-4.83-1.02-11-2.35-2.33-.5-5.18-1.73-8.55-3.7Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_2_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 2 FILL">
		            <path className={styles.cls13} d="m435.63,223.29c-.33,1-1.35,2.93-3.05,5.8,7.1,1.67,12.75,1.78,16.95.35,2.73-.9,4.5-2.63,5.3-5.2.73-2.5.38-4.98-1.05-7.45-1.5-2.63-4.08-4.45-7.75-5.45-1.3-.33-4.57-.8-9.8-1.4l-.25-.05c1.1,5.17.98,9.63-.35,13.4Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_3_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 3 FILL">
		            <path className={styles.cls12} d="m182.18,238.34c-3.53.77-6.32,1.67-8.35,2.7-3.5,1.8-5.63,4.18-6.4,7.15-.43,1.83-.05,4.18,1.15,7.05.33.77,1.5,3,3.5,6.7,1.63,3.03,5.03,5.12,10.2,6.25,3.43.73,7.2,1,11.3.8,7.03-.3,12.3-.62,15.8-.95,5.87-.53,11.08-1.37,15.65-2.5l1.85-.5c12.2-3.73,16.73-12.45,13.6-26.15-1.43-6.37-2.47-10.02-3.1-10.95-.77-1.13-2.72-1.53-5.85-1.2-3.27.37-9.15,1.45-17.65,3.25-7.9,1.67-13.73,3.02-17.5,4.05-1.3.33-3.67,1.08-7.1,2.25-2.9.97-5.27,1.65-7.1,2.05Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_4_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 4 FILL">
		            <path className={styles.cls12} d="m349.78,211.24c-4.17-.47-8.27-.15-12.3.95-4.8,1.3-9.07,3.03-12.8,5.2-4.67,2.73-7.98,5.9-9.95,9.5-1.97,3.63-2.65,8.05-2.05,13.25.63,5.47,2.52,9.42,5.65,11.85.93.7,2.37,1.47,4.3,2.3,2.2.93,3.68,1.63,4.45,2.1,3.27,2.07,6.3,3.6,9.1,4.6,3.23,1.17,6.53,1.8,9.9,1.9,2.83.1,7.07.03,12.7-.2,5-.03,9.2.47,12.6,1.5,1.67.5,3.9,1.27,6.7,2.3,3.27,1.23,5.5,2.05,6.7,2.45,6.53,2.17,12.62,3.23,18.25,3.2,8.73-.03,12.25-3.43,10.55-10.2-1.03-4.13-5.1-7.67-12.2-10.6-3.93-1.5-6.83-2.6-8.7-3.3-3.23-1.27-5.62-2.45-7.15-3.55-2.2-1.6-4.05-4.15-5.55-7.65-1.47-4.1-2.65-7.1-3.55-9-3.17-6.7-8.05-11.23-14.65-13.6-4.6-1.6-8.6-2.6-12-3Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_5_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 5 FILL">
		            <path className={styles.cls18} d="m363.78,174.69c-9.33-2.97-18.12-3.58-26.35-1.85-14.47,2.47-25.32,4.55-32.55,6.25-12.67,3.03-22.77,7.32-30.3,12.85l-5.4-.15c-8.27-.03-16.95.78-26.05,2.45-1.57.3-4.28.55-8.15.75-3.53.2-6.23.53-8.1,1-6.1,1.53-10.2,6.28-12.3,14.25-1.5,5.67-1.92,12.58-1.25,20.75l.2,2.05c.37,3.63,1.8,6.62,4.3,8.95,1.77,1.63,4.7,3.37,8.8,5.2,3.97,1.77,6.3,2.8,7,3.1,2.73,1.07,5.17,1.8,7.3,2.2,1.53.3,3.68.45,6.45.45,3.13.03,5.32.12,6.55.25l6.95.65c4.97,6.4,12.17,10.03,21.6,10.9,5.5.47,14,.28,25.5-.55,6.63-.07,14.63-.55,24-1.45,5.33-.53,13.32-1.37,23.95-2.5,10.83-1.03,18.17-2.28,22-3.75,7.07-2.73,12.33-7.95,15.8-15.65,1.87-4.1,3.02-8.7,3.45-13.8.43-4.9.2-9.92-.7-15.05-1.43-8.17-2.27-12.67-2.5-13.5-1.13-3.9-3.28-7.3-6.45-10.2-6.7-6.17-14.62-10.7-23.75-13.6Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_6_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 6 FILL">
		            <path className={styles.cls12} d="m361.18,227.14c-10.33,5.1-18.77,8.52-25.3,10.25-7,2.03-12.27,3.53-15.8,4.5-6.23,1.7-11.6,2.72-16.1,3.05-4.4.33-9.42.33-15.05,0-2.33-.13-7.28-.55-14.85-1.25-1.57-.17-8.2-.53-19.9-1.1-8.2-.4-14.8-1.15-19.8-2.25-2.93-.63-6.38-1.78-10.35-3.45-4.57-1.93-8.13-3.9-10.7-5.9l.2,2.05c.37,3.63,1.8,6.62,4.3,8.95,1.77,1.63,4.7,3.37,8.8,5.2,3.97,1.77,6.3,2.8,7,3.1,2.73,1.07,5.17,1.8,7.3,2.2,1.53.3,3.68.45,6.45.45,3.13.03,5.32.12,6.55.25l6.95.65c4.97,6.4,12.17,10.03,21.6,10.9,5.5.47,14,.28,25.5-.55,6.63-.07,14.63-.55,24-1.45,5.33-.53,13.32-1.37,23.95-2.5,10.83-1.03,18.17-2.28,22-3.75,7.07-2.73,12.33-7.95,15.8-15.65,1.87-4.1,3.02-8.7,3.45-13.8.43-4.9.2-9.92-.7-15.05-1,.33-4.43,1.55-10.3,3.65-4.57,1.67-12.9,5.5-25,11.5Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_7_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 7 FILL">
		            <path className={styles.cls18} d="m416.88,255.09c-1.93-1.73-4.08-2.95-6.45-3.65-2.8-.87-5.47-.8-8,.2-2.7,1.13-4.47,3.27-5.3,6.4-.63,2.5-.63,5.03,0,7.6.6,2.27,1.53,4.05,2.8,5.35,1.53,1.63,3.72,2.77,6.55,3.4,3.2.73,6.05.68,8.55-.15,2.63-.87,4.32-2.42,5.05-4.65.93-2.67.97-5.88.1-9.65-.4-1.6-1.5-3.22-3.3-4.85Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_8_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 8 FILL">
		            <path className={styles.cls18} d="m163.98,253.74c-1.2,3.47-1.25,6.62-.15,9.45,1.1,3,3.25,5.02,6.45,6.05,1.97.63,4.25,1.03,6.85,1.2,2.4.17,4.75.12,7.05-.15,2.37-.3,4.78-1.37,7.25-3.2,2.57-1.93,4.42-4.17,5.55-6.7,1.4-3.07,1.42-6.02.05-8.85-1.47-3.1-4.47-5.25-9-6.45-3.57-.97-7.22-1.22-10.95-.75-3.27.43-5.9,1.28-7.9,2.55-2.43,1.53-4.17,3.82-5.2,6.85Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_9_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 9 FILL">
		            <path className={styles.cls18} d="m344.23,207.14c-7.9,7.63-9.65,15.85-5.25,24.65,3.1,6.13,8.73,11.37,16.9,15.7,7.37,3.87,14.73,6.03,22.1,6.5.57.03,3.97.02,10.2-.05,4.03-.07,7.38.32,10.05,1.15,3.23,1,6.97,2.47,11.2,4.4,1.43.67,5.05,2.43,10.85,5.3,3.83,1.9,7.07,3.03,9.7,3.4,4.07.6,7.1-.47,9.1-3.2,3.9-5.3,1.75-10.98-6.45-17.05-2.07-1.57-5.55-3.8-10.45-6.7-4.2-2.5-6.87-4.28-8-5.35-2.07-2-3.67-4.98-4.8-8.95-1-4.6-1.85-7.98-2.55-10.15-1.63-5-3.48-9.4-5.55-13.2-2.83-5.2-5.77-8.57-8.8-10.1-3.37-1.73-7.23-2.17-11.6-1.3-5.17,1.5-9.28,2.48-12.35,2.95-9.53,1.53-17.63,5.53-24.3,12Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_10_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 10 FILL">
		            <path className={styles.cls18} d="m446.58,258.39c-1.23-2.67-2.98-5-5.25-7-2.7-2.43-5.63-3.9-8.8-4.4-3.5-.57-6.47.32-8.9,2.65-1.93,1.87-3.17,4.13-3.7,6.8-.5,2.33-.38,4.47.35,6.4.87,2.37,2.68,4.65,5.45,6.85,3.13,2.5,6.27,4.1,9.4,4.8,3.27.73,5.87.32,7.8-1.25,1.17-.93,2.18-2.22,3.05-3.85.8-1.47,1.38-3.03,1.75-4.7.4-1.67.02-3.77-1.15-6.3Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_11_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 11 FILL">
		            <path className={styles.cls17} d="m380.38,183.79l-.55-1.5c-6.47-4.23-13.4-7.2-20.8-8.9-7.7-1.8-14.9-1.98-21.6-.55-11,1.97-19.32,3.52-24.95,4.65-9.93,1.97-18.1,4.3-24.5,7-2.6,3.07-4.73,6.53-6.4,10.4-2.8,6.53-4.07,13.17-3.8,19.9.3,8.6,3.5,13.97,9.6,16.1,5.5,1.97,11.8,2.08,18.9.35,6.13-1.5,11.97-4.1,17.5-7.8,6.03-4.03,11.52-6.65,16.45-7.85,2.2-.53,5.4-.98,9.6-1.35,4.47-.37,7.68-.77,9.65-1.2,6.83-1.43,12.5-4.93,17-10.5,4.9-6.03,6.2-12.28,3.9-18.75Z"/>
		        </g>
		        <g className={styles.cls5}>
		            <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_2_0_Layer2_0_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  2 0 Layer2 0 FILL">
		                <path className={styles.cls11} d="m304.38,210.99c3.97-2,7.72-4.85,11.25-8.55-6.13-3.83-14.7-6.43-25.7-7.8-4.33-.33-7.58-.57-9.75-.7-3.9-.3-7.12-.45-9.65-.45-11.47-.1-20.78.13-27.95.7-11,.9-18.52,2.6-22.55,5.1-1.9,1.2-3.85,4.68-5.85,10.45-2.2,6.27-2.75,11.08-1.65,14.45.67.23,1.6,2.28,2.8,6.15,1.1,3.4,1.9,4.78,2.4,4.15,3.03-3.87,7.58-7.03,13.65-9.5,3.47-1.4,8.67-3.1,15.6-5.1h.1l.05.05c10.97.87,20.68.75,29.15-.35,10.2-1.37,19.57-4.23,28.1-8.6Z"/>
		            </g>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_13_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 13 FILL">
		            <path className={styles.cls8} d="m401.33,224.84c-12.03,2.5-22.43,7.9-31.2,16.2-9.17,8.7-14.8,19.07-16.9,31.1,5.73,11.63,14.35,20.23,25.85,25.8,10.8,5.2,22.4,7,34.8,5.4,12.57-1.63,23.55-6.47,32.95-14.5,10.23-8.77,17-20.12,20.3-34.05-7.67-12.77-17.72-21.72-30.15-26.85-11.3-4.67-23.18-5.7-35.65-3.1Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_14_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 14 FILL">
		            <path className={styles.cls23} d="m408.18,251.89c11.27-1.93,20.1-2.52,26.5-1.75l.6-4.95c-6.87-.83-16.2-.23-28,1.8-11.3,1.97-19.75,4.3-25.35,7l2.2,4.5c5.17-2.53,13.18-4.73,24.05-6.6Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_15_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 15 FILL">
		            <path className={styles.cls23} d="m429.23,239.99c-.07-.67-.37-1.22-.9-1.65-.57-.43-1.18-.6-1.85-.5-.7.07-1.27.38-1.7.95-.4.53-.57,1.13-.5,1.8.2,1.5.45,4.4.75,8.7l.45,5.45c.07.53.27,1,.6,1.4.33.37.75.62,1.25.75.27.1.57.12.9.05.67-.03,1.22-.33,1.65-.9.47-.53.67-1.13.6-1.8-.07-.5-.22-2.27-.45-5.3-.27-3.7-.53-6.68-.8-8.95Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_16_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 16 FILL">
		            <path className={styles.cls23} d="m416.38,240.79c-.13-.67-.5-1.18-1.1-1.55-.6-.37-1.23-.48-1.9-.35-.67.17-1.18.53-1.55,1.1-.37.6-.48,1.23-.35,1.9.5,2.27,1.03,5.12,1.6,8.55.5,2.87.83,4.67,1,5.4.2,1,.8,1.63,1.8,1.9.3.1.68.12,1.15.05.67-.13,1.2-.5,1.6-1.1.37-.57.48-1.18.35-1.85l-.95-5.25c-.87-4.7-1.42-7.63-1.65-8.8Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_17_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 17 FILL">
		            <path className={styles.cls23} d="m402.88,241.39c-.6-.37-1.23-.48-1.9-.35-.67.17-1.18.53-1.55,1.1-.37.57-.48,1.18-.35,1.85.37,1.73.88,4.6,1.55,8.6l.95,5.35c.2,1.03.8,1.68,1.8,1.95.33.1.7.12,1.1.05.7-.13,1.23-.48,1.6-1.05.4-.57.53-1.2.4-1.9l-.9-5.2c-.87-4.87-1.42-7.82-1.65-8.85-.13-.67-.48-1.18-1.05-1.55Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_18_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 18 FILL">
		            <path className={styles.cls23} d="m390.88,246.54c-.17-.67-.55-1.17-1.15-1.5-.6-.33-1.23-.4-1.9-.2-.67.17-1.17.55-1.5,1.15-.33.6-.42,1.23-.25,1.9.27.97.97,3.8,2.1,8.5.3,1.5.72,3.27,1.25,5.3.2.9.78,1.5,1.75,1.8.4.1.83.1,1.3,0,.63-.17,1.13-.55,1.5-1.15.37-.6.47-1.23.3-1.9-.2-.73-.62-2.47-1.25-5.2-1.03-4.4-1.75-7.3-2.15-8.7Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_19_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 19 FILL">
		            <path
			            className={styles.cls20}
			            d="m356.83,219.94c1.93-14.73.23-30.82-5.1-48.25-10.97-.07-23.73,1.47-38.3,4.6-16.33,3.5-29.58,7.98-39.75,13.45-1.27,6.53-2.4,11.25-3.4,14.15-1.63,4.83-3.88,8.68-6.75,11.55-7.03,7-19.67,9.62-37.9,7.85-.33-.03-1.65,2.12-3.95,6.45-2.3,4.27-3.62,6.35-3.95,6.25-.67-.2-1.48-3.1-2.45-8.7-.97-5.6-1.82-8.53-2.55-8.8-.87,3.23.3,12.63,3.5,28.2.97,4.63,8.43,9.37,22.4,14.2,13,4.47,23.55,6.63,31.65,6.5,9.93-.2,22.18-.82,36.75-1.85,15.73-1.1,26.6-1.75,32.6-1.95,9.5-14.43,15.23-28.98,17.2-43.65Z"
			            style={{ fill: teamColors[JERSEY_BG] }}
		            />
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_20_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 20 FILL">
		            <path
			            className={styles.cls15}
			            d="m327.88,183.74c.57-4.73.55-8.12-.05-10.15-2.03.3-4.5.9-7.4,1.8-3.2,1-5.73,2.03-7.6,3.1-3.27,2.57-8.93,10.08-17,22.55-8.1,12.5-12.28,20.27-12.55,23.3-.33,3.5.83,11.35,3.5,23.55,2.77,12.53,4.87,18.17,6.3,16.9,6.67-4.27,9.32-10.58,7.95-18.95-.57-3.37-2.13-7.63-4.7-12.8,12.6-6.4,21.75-17.28,27.45-32.65,2.03-5.57,3.4-11.12,4.1-16.65Z"
			            style={{ fill: teamColors[JERSEY_STRIPE] }}
		            />
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_21_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 21 FILL">
		            <path className={styles.cls18} d="m286.33,237.09c-4.2.57-12,2.02-23.4,4.35-10,2.07-17.77,3.75-23.3,5.05-1.73.4-4.88,1.27-9.45,2.6-3.9,1.1-7.07,1.9-9.5,2.4-3.9.83-6.82,1.73-8.75,2.7-3.07,1.5-5.15,3.62-6.25,6.35-.83,2.07-1.2,4.25-1.1,6.55.1,2.53.77,4.55,2,6.05,2.07,2.53,6.5,4.07,13.3,4.6,4.5.37,9.47.3,14.9-.2,9.9-.97,16.87-1.7,20.9-2.2,7.8-.97,14.72-2.15,20.75-3.55.23-.03,1.07-.23,2.5-.6,16.2-4.3,22.48-12.37,18.85-24.2-1.7-5.47-2.95-8.57-3.75-9.3-1-.97-3.57-1.17-7.7-.6Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_22_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 22 FILL">
		            <path className={styles.cls18} d="m229.03,277.59c3-2.03,5.22-4.45,6.65-7.25,1.7-3.4,1.88-6.73.55-10-1.43-3.6-4.62-6.23-9.55-7.9-3.93-1.33-7.98-1.87-12.15-1.6-3.67.23-6.65,1-8.95,2.3-2.8,1.57-4.88,4.03-6.25,7.4-1.53,3.83-1.8,7.4-.8,10.7,1.07,3.43,3.35,5.85,6.85,7.25,2.1.87,4.62,1.48,7.55,1.85,2.6.33,5.22.43,7.85.3s5.38-1.15,8.25-3.05Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_23_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 23 FILL">
		            <path
			            className={styles.cls22}
			            d="m237.78,230.64c-.4,3.33.37,6.45,2.3,9.35,2,3,4.57,4.57,7.7,4.7,24.67-5.77,40.22-8,46.65-6.7-2.3-5.17-7.55-9.92-15.75-14.25-6.63-3.53-12.03-5.5-16.2-5.9-4-.37-8.88.75-14.65,3.35-6.27,2.87-9.62,6.02-10.05,9.45Z"
			            style={{ fill: teamColors[HELMET_STRIPE] }}
		            />
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_24_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 24 FILL">
		            <path
			            className={styles.cls21}
			            d="m212.08,217.89c-1.07,7.93-.65,15.4,1.25,22.4.97,3.5,2.03,6.22,3.2,8.15.93-2.63,2.05-5.47,3.35-8.5,2.6-6.07,4.82-10.08,6.65-12.05,3.37-.47,8.82-1.22,16.35-2.25,6.63-1.13,12.1-2.72,16.4-4.75,11.9-5.57,18.2-15.93,18.9-31.1l-6-.25c-.63,13.77-6.67,22.97-18.1,27.6-4.07,1.67-9.17,2.88-15.3,3.65-6.8.6-11.63,1.05-14.5,1.35l-1.5.15-.75,1.25c-2.1,3.37-3.47,6.5-4.1,9.4-.7-3.23-.9-6.22-.6-8.95.57-2.9.97-5.1,1.2-6.6l-6.45.5Z"
			            style={{ fill: teamColors[JERSEY_STRIPE] }}
		            />
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_25_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 25 FILL">
		            <path
			            className={styles.cls21}
			            d="m339.23,172.29l-6.05.65c5.37,14.3,6.53,30.77,3.5,49.4-2.87,17.37-8.35,31.47-16.45,42.3,4.7-.3,7.27-.47,7.7-.5,8.5-12.53,13.85-27.37,16.05-44.5,2.23-17.47.65-33.25-4.75-47.35Z"
			            style={{ fill: teamColors[JERSEY_STRIPE] }}
		            />
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_26_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 26 FILL">
		            <path className={styles.cls17} d="m130.38,174.04c10.53,1.93,19.28,5.53,26.25,10.8.37.27.72.22,1.05-.15.33-.4.32-.73-.05-1-7.1-5.37-16-9.05-26.7-11.05-9.07-1.67-19.12-2.08-30.15-1.25-.5.03-.73.28-.7.75.03.5.28.73.75.7,10.87-.8,20.72-.4,29.55,1.2Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_27_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 27 FILL">
		            <path className={styles.cls17} d="m98.03,198.04c-.47.13-.7.43-.7.9.03.5.3.68.8.55,21.17-4.7,40.63-5.62,58.4-2.75.5.07.8-.13.9-.6.1-.5-.08-.78-.55-.85-18.07-2.9-37.68-1.98-58.85,2.75Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_28_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 28 FILL">
		            <path className={styles.cls17} d="m103.93,215.84c-.47.2-.62.53-.45,1,.17.43.48.55.95.35,7.47-3.2,15.8-5.85,25-7.95,7.2-1.67,15.77-3.17,25.7-4.5.47-.07.7-.35.7-.85-.03-.47-.3-.67-.8-.6-9.97,1.33-18.6,2.83-25.9,4.5-9.23,2.13-17.63,4.82-25.2,8.05Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_29_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 29 FILL">
		            <path className={styles.cls13} d="m233.98,44.14c-4.6,1.73-10.1,5.42-16.5,11.05-3.07,2.7-7.9,7.38-14.5,14.05-5.63,5.7-11.97,13.85-19,24.45-7.17,10.8-12.47,20.32-15.9,28.55,4.47,4.77,17.32,6.18,38.55,4.25,8.27-.73,15.72-1.85,22.35-3.35,6.7-1.53,10.47-2.97,11.3-4.3,1.17-1.9,1.83-3.28,2-4.15.07-.4.08-1.93.05-4.6-.07-2.5-1.07-12.1-3-28.8-2.93-25-4.72-37.38-5.35-37.15Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_30_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 30 FILL">
		            <path className={styles.cls13} d="m233.98,44.14c-4.6,1.73-10.1,5.42-16.5,11.05-3.07,2.7-7.9,7.38-14.5,14.05-5.63,5.7-11.97,13.85-19,24.45-7.17,10.8-12.47,20.32-15.9,28.55,4.47,4.77,17.32,6.18,38.55,4.25,8.27-.73,15.72-1.85,22.35-3.35,6.7-1.53,10.47-2.97,11.3-4.3,1.17-1.9,1.83-3.28,2-4.15.07-.4.08-1.93.05-4.6-.07-2.5-1.07-12.1-3-28.8-2.93-25-4.72-37.38-5.35-37.15Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_31_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 31 FILL">
		            <path className={styles.cls19} d="m240.78,90.84c-7.87,7.73-17.12,14.5-27.75,20.3-16.97,9.23-34.9,14.35-53.8,15.35-2.53,4.83-4.8,10.78-6.8,17.85-.73,2.6-2.27,8.83-4.6,18.7-.6,2.6-1.72,5.68-3.35,9.25-1.87,4.07-3.05,6.9-3.55,8.5-2.2,7.07-1.55,15.83,1.95,26.3,4.23,12.73,11.58,21.8,22.05,27.2,3.8,1.93,8.35,3.57,13.65,4.9,3,.77,7.27,1.7,12.8,2.8,8.77,1.97,18.68,2.88,29.75,2.75,9.6-.1,19.6-.97,30-2.6,6.7-1.07,14.48-3.35,23.35-6.85,9.47-3.77,16.67-7.6,21.6-11.5,9.67-7.7,17.05-17.57,22.15-29.6,3.93-9.3,6.55-20.1,7.85-32.4l.25-3c-7.57-.2-15.88-1.15-24.95-2.85l-11.3-2.5c-33.53-12.03-50.25-24.48-50.15-37.35.03-8.33,6.82-16.15,20.35-23.45-6.5-1.03-13-1.63-19.5-1.8Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_32_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 32 FILL">
		            <path className={styles.cls17} d="m284.83,92.24c-7.3-2.57-15.48-2.43-24.55.4-13.53,7.3-20.32,15.12-20.35,23.45-.1,12.87,16.62,25.32,50.15,37.35l11.3,2.5c9.07,1.7,17.38,2.65,24.95,2.85.7-11.5-1.55-22.2-6.75-32.1-4.13-7.97-10.65-16.13-19.55-24.5-5.23-4.9-10.3-8.22-15.2-9.95Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_33_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 33 FILL">
		            <path className={styles.cls17} d="m240.78,90.84c-8.23-.17-16.1.35-23.6,1.55-7.53,1.2-14.12,3-19.75,5.4-8.3,3.57-15.18,7.13-20.65,10.7-7.77,5.03-13.25,10.33-16.45,15.9l-1.1,2.1c18.9-1,36.83-6.12,53.8-15.35,10.63-5.8,19.88-12.57,27.75-20.3Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_34_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 34 FILL">
		            <path className={styles.cls17} d="m214.13,198.29c7.97-3.43,17.27-5.03,27.9-4.8,9,.23,18.65,1.75,28.95,4.55.47.13.77-.02.9-.45.13-.43-.03-.72-.5-.85-10.5-2.87-20.33-4.4-29.5-4.6-10.83-.27-20.32,1.37-28.45,4.9-.43.17-.53.47-.3.9.23.4.57.52,1,.35Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_35_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 35 FILL">
		            <path className={styles.cls17} d="m210.73,208.34c0,.47.25.72.75.75,17.93.8,36.58,5.33,55.95,13.6.47.2.77.08.9-.35.13-.43-.03-.75-.5-.95-19.37-8.3-38.15-12.87-56.35-13.7-.47-.03-.72.18-.75.65Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_36_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 36 FILL">
		            <path className={styles.cls17} d="m210.98,216.59c9.27,3.07,17.23,6.05,23.9,8.95,8.4,3.67,15.87,7.65,22.4,11.95.4.27.73.22,1-.15.23-.4.15-.73-.25-1-6.6-4.33-14.12-8.35-22.55-12.05-6.8-2.97-14.82-5.97-24.05-9-.47-.13-.77.02-.9.45-.17.4-.02.68.45.85Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_37_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 37 FILL">
		            <path className={styles.cls9} d="m158.28,204.79c-1.6,3.13-3.58,5.97-5.95,8.5.17,1.53,1.75,2.47,4.75,2.8,3,.3,4.93-.37,5.8-2-1-1.53-1.78-2.82-2.35-3.85-1.1-1.9-1.85-3.72-2.25-5.45Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_38_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 38 FILL">
		            <path className={styles.cls17} d="m182.73,205.29c-.43-.13-.9.38-1.4,1.55-.23.5-.5,1.22-.8,2.15-.97,2.1-2.13,3.62-3.5,4.55-1.6,1.07-3.52,1.67-5.75,1.8-2.23.1-4.23-.28-6-1.15-3.73-1.9-5.65-5.27-5.75-10.1-.1-1.53-.15-2.7-.15-3.5-.07-1.43.02-2.6.25-3.5.33-.73.5-1.25.5-1.55.03-.33-.17-.7-.6-1.1-.43-.43-.82-.62-1.15-.55-.6.13-.98.58-1.15,1.35-.1.4-.15,1.05-.15,1.95,0,1.23-.12,2.82-.35,4.75-.33,2.4-.6,4-.8,4.8-.5,1.87-1.3,3.25-2.4,4.15-.93.8-1.95,1.2-3.05,1.2-1.17-.03-2.13-.52-2.9-1.45-.53-.67-.9-1.8-1.1-3.4,0-.47-.05-.95-.15-1.45-.17-.83-.42-1.23-.75-1.2-.53.07-.78.6-.75,1.6.03.53.13,1.22.3,2.05.33,1.87,1.07,3.43,2.2,4.7,1.33,1.47,2.85,1.93,4.55,1.4,1.37-.43,2.63-1.42,3.8-2.95.67-.8,1.15-1.43,1.45-1.9.5-.77.73-1.5.7-2.2.7,3.9,2.88,6.8,6.55,8.7,1.6.8,3.37,1.25,5.3,1.35,4.57.27,8.23-1.33,11-4.8.8-1,1.52-2.25,2.15-3.75.93-2.07.9-3.23-.1-3.5Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_39_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 39 FILL">
		            <path className={styles.cls11} d="m220.23,166.74c5.9-3.03,10.7-7.4,14.4-13.1-8.8,6.77-19.58,9.2-32.35,7.3-3.97-.6-7.92-1.6-11.85-3l-4.65-1.9c11.13,13.23,22.62,16.8,34.45,10.7Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_40_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 40 FILL">
		            <path className={styles.cls11} d="m165.03,161.19c3-1.37,5.8-3.95,8.4-7.75-4,2.03-7.92,2.75-11.75,2.15-4.63-.7-7.62-3.3-8.95-7.8-.93,3.2-.9,6.1.1,8.7.93,2.5,2.5,4.2,4.7,5.1,2.3.93,4.8.8,7.5-.4Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_41_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 41 FILL">
		            <path className={styles.cls16} d="m171.78,189.44c1.77-2.43,1.35-4.45-1.25-6.05-.93-.57-2.55-.97-4.85-1.2-3.17-.37-4.92-.58-5.25-.65-2.4-.67-4.15-1.07-5.25-1.2-1.67-.17-3.17.13-4.5.9-2.67,1.5-3.92,3.72-3.75,6.65.23,3.9,2.55,6.4,6.95,7.5,3.8.93,7.45.65,10.95-.85,3.17-1.37,5.48-3.07,6.95-5.1Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_42_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 42 FILL">
		            <path
			            className={styles.cls20}
			            d="m278.23,132.89c-10.67-11.07-15.62-20.68-14.85-28.85.93-9.7,10.7-13.95,29.3-12.75-11.7-7.67-24.1-12.93-37.2-15.8-19.8-4.37-39.17-2.63-58.1,5.2-11.73,4.87-20.83,11.85-27.3,20.95-5.23,7.37-9.23,17-12,28.9,9.73-2.37,20.82-3.3,33.25-2.8,12.4.5,24.25,2.35,35.55,5.55,11.97,3.37,21.7,7.83,29.2,13.4,8.3,6.13,13.1,13.07,14.4,20.8,1.27,5.47,2.13,12.12,2.6,19.95.27,4.47.5,10.8.7,19,.27,6.73.85,11.67,1.75,14.8,1.13,4.07,3.05,6.47,5.75,7.2,3.03.8,6.52.52,10.45-.85,4.2-1.47,8.28-4,12.25-7.6,9.67-8.8,16.28-21.47,19.85-38,3.87-18,4.35-33.5,1.45-46.5-.17,13.17-5.77,18.65-16.8,16.45-4.6-.9-9.68-3.13-15.25-6.7-5.3-3.4-10.3-7.52-15-12.35Z"
			            style={{ fill: teamColors[HELMET_BG] }}
		            />
		        </g>
		        <path
			        id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_43_1_STROKES"
                    data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 43 1 STROKES"
                    className={styles.cls1}
                    d="m310.63,106.19c-7.47-7.47-15.35-12.35-23.65-14.65-8.3-2.27-14.58-1.27-18.85,3-4.27,4.27-5.28,10.55-3.05,18.85,2.27,8.3,7.13,16.2,14.6,23.7,7.47,7.47,15.35,12.33,23.65,14.6,8.3,2.27,14.58,1.27,18.85-3,4.27-4.27,5.28-10.55,3.05-18.85-2.27-8.3-7.13-16.18-14.6-23.65Z"
			        style={{ stroke: teamColors[HELMET_HOLE] }}
		        />
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_44_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 44 FILL">
		            <path
			            className={styles.cls22}
			            d="m241.18,73.29c-5.37-.43-9.78-.3-13.25.4-9.27,3.9-16.18,11.5-20.75,22.8-3.47,8.5-5.85,20.13-7.15,34.9l15.35.35c3.03-12.37,8.17-23.92,15.4-34.65,6.83-10.13,14.1-17.48,21.8-22.05-3.43-.8-7.23-1.38-11.4-1.75Z"
			            style={{ fill: teamColors[HELMET_STRIPE] }}
		            />
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_45_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 45 FILL">
		            <path className={styles.cls14} d="m234.38,199.29c-14.77,4.5-29,5.28-42.7,2.35-11.37-11.33-23.42-17.88-36.15-19.65-12.7-1.77-25.97,1.27-39.8,9.1-1.07.63-1.77,1.53-2.1,2.7-.37,1.2-.23,2.35.4,3.45.6,1.07,1.5,1.77,2.7,2.1,1.2.33,2.35.2,3.45-.4l.2-.15c4.67,12.4,7.87,26.18,9.6,41.35-1.3-.07-2.42.4-3.35,1.4-.83.93-1.22,2.02-1.15,3.25.03,1.23.52,2.27,1.45,3.1,4.77,4.43,11,7.9,18.7,10.4,7.67,2.47,16.3,3.82,25.9,4.05,6.6.2,14.22-.03,22.85-.7,11.97-.9,21.13-2.43,27.5-4.6,8.23-2.87,18.08-8.93,29.55-18.2,11.53-9.3,23.32-20.75,35.35-34.35.57-.63.92-1.38,1.05-2.25,1.9-11.33.22-19.48-5.05-24.45-.77-.7-1.67-1.1-2.7-1.2-1.03-.07-1.98.18-2.85.75l-.65.4c-4.47-20.03-10-32.63-16.6-37.8-8.67-6.77-22.13-11.97-40.4-15.6-21.57-4.27-42.95-4.5-64.15-.7-1.37.27-2.43.97-3.2,2.1-.8,1.13-1.08,2.37-.85,3.7.27,1.33.97,2.4,2.1,3.2,1.1.8,2.33,1.08,3.7.85,19.2-3.47,38.77-3.37,58.7.3,17.4,3.2,30.05,7.87,37.95,14,1.97,1.57,3.95,4.57,5.95,9,2.83,6.4,5.45,15.32,7.85,26.75-11.83,7.3-22.92,12.55-33.25,15.75m-46.7,10.6l.75.25c8.03,1.87,16.32,2.58,24.85,2.15l3.25,36.95c-5,1.4-11.68,2.47-20.05,3.2-6.63.6-13.27.92-19.9.95-.1-15.47,3.6-29.97,11.1-43.5m91.5-10.4c-10.2,11.4-20.23,21.27-30.1,29.6-9.83,8.3-18.42,14.17-25.75,17.6l-3.05-35.05c10.8-1.43,21.87-4.8,33.2-10.1,7.4-3.47,15.75-8.23,25.05-14.3,1.17,2.83,1.38,6.92.65,12.25m-152.55-3.85c10.13-4.53,19.8-6.02,29-4.45,9.23,1.57,18.13,6.23,26.7,14-8.6,14.9-12.85,30.93-12.75,48.1-13.5-.67-24.2-3.53-32.1-8.6-1.63-17.97-5.25-34.32-10.85-49.05Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_46_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 46 FILL">
		            <path className={styles.cls17} d="m357.58,84.19c-4.67-1.5-11.28-2-19.85-1.5-3.77.23-10.48.97-20.15,2.2-14.23,1.9-23.07,3.2-26.5,3.9-10.63,2.23-18.65,5.37-24.05,9.4.53,6.97,8.07,17.68,22.6,32.15,5.8,5.8,11.2,10.55,16.2,14.25,5.17,3.87,8.48,5.52,9.95,4.95,2.1-.8,3.48-1.52,4.15-2.15.3-.27,1.27-1.52,2.9-3.75,1.53-2.03,6.77-10.55,15.7-25.55,13.37-22.37,19.72-33.67,19.05-33.9Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_47_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 47 FILL">
		            <path className={styles.cls16} d="m303.43,102.04c-4.8,1.5-9.52,3.17-14.15,5l-5.5,2.25,31.25,29.95,28.55-44.8c-11.4.17-24.78,2.7-40.15,7.6Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_48_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 48 FILL">
		            <path className={styles.cls10} d="m49.93,186.24l-1.7,4.3,23.95,9.55,2.65-6.7-13.1-5.2.05-.2,20.35-13.45,1.85-4.6-22.5-9-2.65,6.7,12.05,4.8-.1.15-20.85,13.65Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_49_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 49 FILL">
		            <path className={styles.cls10} d="m109.98,189.94l-18.65-3.55-1.05,5.55,10,1.85-.05.15-14,13.65-.65,3.55,19.9,3.8,1-5.55-10.9-2.05.05-.15,13.65-13.45.7-3.8Z"/>
		        </g>
		        <g id="FlashAICB_Assets_7_Layer_1_Clip_Group_0_0_Layer0_50_FILL" data-name="FlashAICB Assets 7 Layer 1  Clip Group  0 0 Layer0 50 FILL">
		            <path className={styles.cls10} d="m32.33,109.74l-6.15,9,16.3,11.1-.15.25-35.5,13.35-3.95,5.8,32.4,22.1,6.15-9-17.75-12.1.15-.25,34.65-13.25,4.25-6.25-30.4-20.75Z"/>
		        </g>
		    </g>
		</svg>
	);
};