import { values, reject } from "lodash";
import React from "react";

export function getProcessedWellnessVisits(state) {
    const { entities: { wellness } } = state;
    return wellness.visits ? values(reject(wellness.visits, {processed: null})) : null;
}

export function getUserCurrentClinic(state) {
    const { user: { userProfile, currentClinicId } } = state;
    if (currentClinicId) {
        return currentClinicId;
    }

    return userProfile.clinics && userProfile.clinics.length ? userProfile.clinics[0].id : null;
}
