import React, {useEffect, useState, useMemo} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import filter from "lodash/filter";
import find from "lodash/find";
import map from "lodash/map";
import reject from "lodash/reject";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import DropdownSearch from "components/common/DropdownSearch";
import CheckboxInput from "components/common/CheckboxInput";
import TextBox from "components/common/TextBox";
import * as ProviderActions from "actions/ProviderActions";
import {ROLES} from "constants/RoleTypes";
import * as UserApi from 'api/UserApi';
import toast from "utils/toast";
import {handleErrorResponse} from "utils/request";
import SpinnerTakeover from "components/common/SpinnerTakeover/SpinnerTakeover";

function UserForm(props) {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(!!props.initialData?.providerId) {
            props.getProviderHierarchies(props.initialData.providerId);
        }
    }, [props.initialData]);

    const handleFormChange = ({name, value}) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(combinedData);
    };

    const combinedData = {
        ...props.initialData,
        ...formData,
    };

    const originalRole = find(ROLES, {value: props.initialData.role});

    const ROLE_OPTIONS = filter(ROLES, role => {
        if (originalRole.userType) {
            if (role.userType === originalRole.userType) {
                if (role.value !== "ClinicGuest") {
                    return role;
                }
            }
        } else {
            return role;
        }
    });

    const handleResendEmail = async () => {
        setLoading(true);
        try {
            let res = await UserApi.resendEmailConfirmation(props.initialData.userId);
            if (res) toast.success(`User ${props.initialData.userId}: A confirmation email has been sent to the email address on file.`);
        } catch (error) {
            handleErrorResponse('resending confirmation email', error);
        }
        setLoading(false);
    }

    const NODE_OPTIONS = useMemo(() => {
        return map(props.hierarchy, (node) => {
            return {name: `(${node.nodeId}) ${node.displayName} (${node.sourceRefCode}) - ${node.nodeType}`, value: node.nodeId}
        });
    }, [props.hierarchy]);

    return (
        <form onSubmit={handleSubmit}>
            <TextBox
                name="userName"
                value={combinedData.userName}
                label="Username"
                onChange={handleFormChange}
                required={!props.initialData}
                minLength={6}
                pattern="[A-Za-z][A-Za-z0-9-_.@]+"
                title="Minimum 6 character length. Must start with a letter and can contain dashes (-), underscores (_), periods (.), at symbol (@), letters (A-Z), or digits (0-9)"
                information="Minimum 6 character length. Must start with a letter and can contain dashes (-), underscores (_), periods (.), at symbol (@), letters (A-Z), or digits (0-9)"
                disabled={!props.initialData.canEdit}
            />
            <TextBox
                onChange={handleFormChange}
                name="firstName"
                value={combinedData.firstName}
                label="First Name"
                required
            />
            {/*TODO: Does this message show to all users?*/}
            <div className="text-sm margin-left-sm margin-bottom-sm">This Account is linked to your PIMS and First Name must be updated in your PIMS</div>
            <TextBox
                onChange={handleFormChange}
                name="lastName"
                value={combinedData.lastName}
                label="Last Name"
                required
            />
            {/*TODO: Does this message show to all users?*/}
            <div className="text-sm margin-left-sm margin-bottom-sm">This Account is linked to your PIMS and Last Name must be updated in your PIMS</div>
            <TextBox
                onChange={handleFormChange}
                name="email"
                inputType="email"
                value={combinedData.email}
                label="Email"
                required
            />
            <div className="align-center">
                <CheckboxInput
                    name="emailConfirmed"
                    onChange={handleFormChange}
                    label="Email Confirmed"
                    leftLabel
                    checked={combinedData.emailConfirmed}
                    neverEmpty
                    information="Email address has been confirmed"
                />
                <Button
                    type="default"
                    small
                    onClick={handleResendEmail}
                    className={"margin-right-sm"}
                >
                    Resend Email
                </Button>
                <SpinnerTakeover show={loading} />
            </div>
            <Dropdown
                onChange={handleFormChange}
                name="role"
                value={combinedData.role}
                label="Role"
                options={ROLE_OPTIONS}
            />
            {(!!NODE_OPTIONS?.length) && (
                <DropdownSearch
                    options={NODE_OPTIONS}
                    label="Node"
                    name="nodeId"
                    value={combinedData.nodeId}
                    placeholder="--Select Node--"
                    onChange={handleFormChange}
                    disabled={!combinedData.providerId || !NODE_OPTIONS?.length}
                />
            )}
            {!!props.initialData && (
                <>
                    <TextBox
                        onChange={handleFormChange}
                        name="uuid"
                        value={combinedData.uuid}
                        label="Remote Clinic User ID"
                    />
                    <CheckboxInput
                        name="isActive"
                        onChange={handleFormChange}
                        label="Is Active"
                        leftLabel
                        checked={combinedData.isActive}
                        neverEmpty
                        information="Can Login to portal"
                    />
                </>
            )}
            <div className="flex justify-flex-end spaced-content margin-top-sm">
                <Button onClick={props.onCancel} type="gray" buttonType="button">
                    Cancel
                </Button>
                <Button
                    buttonType="submit"
                    disabled={!Object.values(formData).length}
                >
                    Save Changes
                </Button>
            </div>
        </form>
    )
}

UserForm.propTypes = {
    initialData: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default connect(
    (state, ownProps) => ({
        hierarchy: reject(state.entities?.providerHierarchies?.[ownProps.initialData?.providerId], {nodeType: "Clinic"})
    }),
    (dispatch) => ({
        getProviderHierarchies: (providerId) => dispatch(ProviderActions.getProviderHierarchies(providerId)),
    })
)(UserForm);
