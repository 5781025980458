import React, { useContext, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./CouponOptInOffers.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import includes from "lodash/includes";
import keys from "lodash/keys";
import * as CouponActions from "actions/CouponActions";
import { windowSizeContext } from "AppRoot";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import CouponChangeHistoryTable from "components/admin/widgets/CouponChangeHistoryTable";
import InvoiceDetails from "components/common/InvoiceDetails";
import JoyRide from "components/tour/widgets/JoyRide";
import Modal from "components/common/Modal";
import OfferGroupAccordion from "components/coupons/elements/OfferGroupAccordion";
import SelectedOffer from "components/coupons/elements/SelectedOffer";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import {
    TOUR_COUPON_LIBRARY_PART_3,
    TOUR_GAMES_TRANSITION,
    TOUR_GAMES_PART_1,
    TOUR_PROGRAM_OPT_IN_TRANSITION,
    TOUR_PROGRAM_OPT_IN_PART_1,
    TOUR_PROGRAM_OPT_IN_PART_4,
    TOUR_RESOURCES_TRANSITION,
    TOUR_RESOURCES
} from "utils/demoTourHelpers";
import * as DemoTourStates from "constants/DemoTourStates";
import { MERCK } from  "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";

function CouponOptInOffers(props) {
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [selectedOfferId, setSelectedOfferId] = useState(null);
    const [showHistory, setShowHistory] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    const [tourSection, setTourSection] = useState(null);
    const [sampleInvoices, setSampleInvoices] = useState([]);
    const [showSampleInvoices, setShowSampleInvoices] = useState(false);
    const [forceOpenGroup, setForceOpenGroup] = useState(null); // Only used for Demo

    const context = useContext(windowSizeContext) || {};

    const fetchData = () => {
        if (props.canViewClinicCouponLibrary && !(props.program?.provider)) {
            props.getPrograms(props.clinicId);
        }
    }

    const getTourSection = () => {
        let newSection = null;
        if(!includes(props.completedTourSections, TOUR_COUPON_LIBRARY_PART_3)) {
            newSection = TOUR_COUPON_LIBRARY_PART_3;
        } else if(props.canViewClinicBiGamesDashboard && !includes(props.completedTourSections, TOUR_GAMES_TRANSITION)) {
            newSection = TOUR_GAMES_TRANSITION;
        } else if(props.canViewClinicBiGamesDashboard && !includes(props.completedTourSections, TOUR_GAMES_PART_1)) {
            newSection = TOUR_GAMES_PART_1;
        } else if((props.userProfile.demoProvider === MERCK) && !includes(props.completedTourSections, TOUR_PROGRAM_OPT_IN_TRANSITION)) {
            newSection = TOUR_PROGRAM_OPT_IN_TRANSITION;
        } else if((props.userProfile.demoProvider === MERCK) && !includes(props.completedTourSections, TOUR_PROGRAM_OPT_IN_PART_1)) {
            newSection = TOUR_PROGRAM_OPT_IN_PART_1;
        } else if((props.userProfile.demoProvider === MERCK) && !includes(props.completedTourSections, TOUR_PROGRAM_OPT_IN_PART_4)) {
            newSection = TOUR_PROGRAM_OPT_IN_PART_4;
            setSelectedGroupId(1107);
            if (!selectedOfferId) {
                let offerGroup = find(props.offerGroups, {groupId: 1107});
                if(offerGroup) {
                    const firstOffer = offerGroup.offers[0];
                    setForceOpenGroup(offerGroup.groupId);
                    handleOfferChanged({...firstOffer, groupId: offerGroup.groupId});
                }
            }
        } else if((props.userProfile.demoProvider === MERCK) && !includes(props.completedTourSections, TOUR_RESOURCES_TRANSITION)) {
            newSection = TOUR_RESOURCES_TRANSITION;
        } else if((props.userProfile.demoProvider === MERCK) && !includes(props.completedTourSections, TOUR_RESOURCES)) {
            newSection = TOUR_RESOURCES;
        }
        setTourSection(newSection);
    }
    console.log(props.offerGroups, "props.offerGroups");

    useEffect(() => {
        fetchData();
    }, [props.clinicId]);

    useEffect(() => {
        getTourSection();
    }, [props.completedTourSections]);

    if(!props.canViewClinicCouponLibrary) {
        return <AccessDenied/>;
    }

    const loadCouponHistory = () => {
        if (!props.offerHistory) {
            props.getProgramOfferHistory(props.clinicId, props.programId);
        }
        setShowHistory(!showHistory);
    }

    const handleOfferChanged = (offer) => {
        if(offer) {
            setSelectedOfferId(offer.offerId);
            setSelectedGroupId(offer.groupId);
        } else {
            setSelectedOfferId(null);
            setSelectedGroupId(null);
        }
    }

    const handleHeaderClicked = (groupId, isOpen) => {
        setSelectedGroupId(isOpen ? groupId : null);
        if(isOpen && (selectedGroupId !== null) && (groupId !== selectedGroupId)) {
            // Only clear the offer if the groupId has Changed or the offer has closed
            setSelectedOfferId(null);
        }

        if (props.onSelect) {
            props.onSelect(groupId);
        }
    }

    const handleTourStepComplete = (tourSection, index) => {
        if(tourSection === TOUR_COUPON_LIBRARY_PART_3) {
            if (index === 0) {
                // If there is not a selected offer yet then select the first offer
                if (!selectedOfferId) {
                    let offerGroup = props.offerGroups[0];
                    if (!!selectedGroupId) {
                        offerGroup = find(props.offerGroups, {groupId: selectedGroupId});
                    }
                    const firstOffer = offerGroup.offers[0];
                    setForceOpenGroup(offerGroup.groupId);
                    handleOfferChanged({...firstOffer, groupId: offerGroup.groupId})
                }
            }
        } else if(tourSection === TOUR_RESOURCES) {
        }
    }

    const handleCloseSampleInvoiceDetails = () => {
        setSampleInvoices([]);
        setShowSampleInvoices(false);
    }

    const selectedOfferGroup = selectedGroupId ? find(props.offerGroups, {groupId: selectedGroupId}) : null;
    const offer = (selectedOfferGroup && selectedOfferId) ? find(selectedOfferGroup.offers, {offerId: selectedOfferId}) : null;
    const offerGroupCount = keys(props.offerGroups).length;

    return (
        <div className={styles.root}>
            <div className="flex spaced-content">
                <div id="CouponOptInOffersList">
                    <OfferGroupAccordion
                        offerId={selectedOfferId}
                        programId={props.programId}
                        headerClicked={handleHeaderClicked}
                        offerChanged={handleOfferChanged}
                        isReadOnlyView={props.isReadOnlyView}
                        searchQuery={props.searchQuery}
                    />
                </div>
                <div className={classnames(styles.offerDetails, {
                    [styles.selected]: !!selectedOfferId
                })}>
                    {context.isPhone && (
                        <div className="flex-none flex flex-end">
                            <Button
                                onClick={() => handleOfferChanged(null)}
                                icon
                                type="primary"
                            >
                                <i className="fa fa-reply"/> Back to Coupon Selection
                            </Button>
                        </div>
                    )}
                    {!!props.canViewClinicCouponLibraryHistory &&
                        <div className="flex-none flex spaced-content">
                            <Button
                                onClick={loadCouponHistory}
                                icon
                                type="primary"
                            >
                                <i className="fa fa-history" />See {(props.program && props.program.provider) && props.program.provider.name} Coupon Change History
                            </Button>
                        </div>
                    }
                    {selectedOfferId ? (
                        <SelectedOffer
                            programId={props.programId}
                            offerId={selectedOfferId}
                            offerGroupId={selectedGroupId}
                        />
                    ) : (
                        <div className={styles.offerHeader}>
                            {offerGroupCount === 0 && (
                                <div>No offers available</div>
                            )}
                            {offerGroupCount > 0 && (
                                <div>Click an offer to view it</div>
                            )}
                        </div>
                    )}
                </div>
            </div>
            <JoyRide
                key={tourSection}
                tourSection={tourSection}
                onStepComplete={handleTourStepComplete}
            />
            <Modal
                show={showHistory}
                onClose={() => setShowHistory(false)}
                modalTitle={`${(props.program && props.program.provider) ? props.program.provider.name : ""} Coupon Change History`}
                icon="fa-history"
                medium
            >
                {props.canViewClinicCouponLibraryHistory ? (
                    <CouponChangeHistoryTable
                        clinicId={props.clinicId}
                        providerId={props.programId}
                    />
                ) : <AccessDenied/>}
            </Modal>
            <Modal
                show={showTerms}
                modalTitle="Special Instructions"
                onClose={() => setShowTerms(false)}
            >
                {offer && offer.terms && <span dangerouslySetInnerHTML={{ __html: offer.terms }} />}
            </Modal>
            <Modal
                show={showSampleInvoices}
                onClose={handleCloseSampleInvoiceDetails}
                modalTitle="Sample Invoices"
                icon="fa-file-invoice-dollar"
                mediumSmall
            >
                <InvoiceDetails
                    sampleInvoices={sampleInvoices}
                    handleClose={handleCloseSampleInvoiceDetails}
                />
            </Modal>
                <Modal
                    show={showHistory}
                    onClose={() => setShowHistory(false)}
                    modalTitle={`${(props.program && props.program.provider) ? props.program.provider.name : ""} Coupon Change History`}
                    icon="fa-history"
                    medium
                >
                    {props.canViewClinicCouponLibraryHistory ? (
                        <CouponChangeHistoryTable
                            clinicId={props.clinicId}
                            providerId={props.programId}
                        />
                    ) : <AccessDenied/>}
                </Modal>
                <Modal
                    show={showTerms}
                    modalTitle="Special Instructions"
                    onClose={() => setShowTerms(false)}
                >
                    {offer && offer.terms && <span dangerouslySetInnerHTML={{ __html: offer.terms }} />}
                </Modal>
        </div>
    );
}

CouponOptInOffers.propTypes = {
    programId: PropTypes.number.isRequired,
    clinicId: PropTypes.number.isRequired,
    isReadOnlyView: PropTypes.bool, // Disable interaction with opt-in elements
}

const connector = connect (
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const offerGroups = state.entities.programOffers[ownProps.programId]?.offers || null;
        //Permissions
        const canViewClinicBiGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewClinicCouponLibraryHistory = userHasPermission(PermissionTypes.HISTORY, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canOptInCouponLibrary = userHasPermission(PermissionTypes.OPT_IN, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const clinicCouponOfferHistories = state.entities.couponOfferHistory[ownProps.clinicId] ? state.entities.couponOfferHistory[ownProps.clinicId] : {};
        return {
            userProfile,
            // Permissions
            canViewClinicBiGamesDashboard,
            canViewClinicCouponLibrary,
            canOptInCouponLibrary,
            canViewClinicCouponLibraryHistory,
            program: state.entities.programs[ownProps.programId],
            //Filter the offerGroups that don't have any "isVisibleToClinic" offers
            offerGroups:  !!offerGroups ? filter(offerGroups, offerGroup => !!filter(offerGroup.offers, {isVisibleToClinic: true})?.length) : null,
            offerHistory: (clinicCouponOfferHistories && clinicCouponOfferHistories[ownProps.programId]) ? clinicCouponOfferHistories[ownProps.programId] : null,
            searchQuery: state.entities.genericSearch,
            completedTourSections: state.demo.completedTourSections,
            tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),
        }
    },
    (dispatch) => ({
        getPrograms: (clinicId) => dispatch(CouponActions.getPrograms(clinicId)),
        getProgramOfferHistory: (clinicId, providerId) => dispatch(CouponActions.getCouponOfferHistory(clinicId, providerId))
    }),
);

export default compose(
    withRouter,
    connector
)(CouponOptInOffers);
