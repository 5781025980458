import React, { useEffect, useState } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import styles from "./ManageClinicTaxForm.scss";
import cloneDeep from "lodash/cloneDeep";
import find from "lodash/find";
import findLast from "lodash/findLast";
import map from "lodash/map";
import merge from "lodash/merge";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import Button from "components/common/Button";
import DateInput from "components/common/DateInput";
import TextBox from "components/common/TextBox";
import getLocalTimezone from "utils/getLocalTimezone";

function ManageClinicTaxForm(props) {
    const [formData, setFormData] = useState({});

    useEffect(() => {
        setFormData({});
    }, []);

    const combinedData = cloneDeep(props.taxRates);

    merge(combinedData, formData);

    const handleSave = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const tempSubmitData = reject(combinedData, (data) => {
            if(data.isDeleted) {
                //If it is deleted and new then just remove it
                return !!data.isNew
            }
        });
        const newData = {
            clinicId: props.clinicId,
            taxHistory: map(tempSubmitData, t => {
                return {
                    ...t,
                    clinicTaxId: t.isNew ? null : t.clinicTaxId
                }
            })
        }
        props.onSubmit(newData);
    }

    const handleChange = ({name, value}, rate=null) => {
        if(!!rate) {
            // UPDATE/DELETE
            setFormData({
                ...formData,
                [rate.clinicTaxId]: {
                    ...formData[rate.clinicTaxId],
                    clinicTaxId: rate.clinicTaxId,
                    [name]: value,
                }
            })
        } else {
            // CREATE
            const nextIndex = Object.keys(combinedData).length ? (orderBy(combinedData, ["clinicTaxId"], ["desc"])[0].clinicTaxId + 1) : 0;
            setFormData({
                ...formData,
                [nextIndex]: {
                    ...formData[nextIndex],
                    clinicTaxId: nextIndex,
                    isNew: true,
                    servicesTaxRate: null,
                    productsTaxRate: null,
                    isLocked: false,
                    isDeleted: false,
                    [name]: value,
                }
            })
        }
    };

    const rows = map(reject(combinedData, "isDeleted"), (rate, index) => {
        const last = findLast(reject(combinedData,"isDeleted"), d => {return d.clinicTaxId < rate?.clinicTaxId}, rate?.clinicTaxId);
        const next = find(reject(combinedData, "isDeleted"), d => {return d.clinicTaxId > rate?.clinicTaxId});

        return(
            <tr key={index}>
                <td className={classnames({
                    [styles.error]: !rate?.startDate,
                    [styles.overlap]: (!!last?.endDate && (last.endDate > rate?.startDate)),
                    [styles.startAfterEnd]: rate?.startDate > rate?.endDate,
                })}>
                    <DateInput
                        value={rate?.startDate}
                        name="startDate"
                        onChange={(data) => handleChange(data, rate)}
                        placeholderText="--Select Start Date--"
                        minDate={!!last?.endDate ? new moment(last.endDate).add(1, "day") : null}
                        maxDate={!!rate?.endDate ? getLocalTimezone(rate.endDate) : null}
                        selectsStart
                        showDropdowns
                        required
                    />
                </td>
                <td className={classnames({
                    [styles.error]: (!rate?.endDate && !!next),
                    [styles.overlap]: (!!next?.startDate && (next.startDate < rate?.endDate)),
                    [styles.startAfterEnd]: rate?.startDate > rate?.endDate,
                })}>
                    <DateInput
                        value={rate?.endDate}
                        name="endDate"
                        onChange={(data) => handleChange(data, rate)}
                        placeholderText="--Select End Date--"
                        selectsEnd
                        minDate={!!rate?.startDate ? getLocalTimezone(rate.startDate) : new moment(last?.endDate).add(1, "day") || null}
                        maxDate={!!next?.startDate ? new moment(next.startDate).subtract(1, "day") : null}
                        showDropdowns
                        required={!!next}
                    />
                </td>
                <td>
                    <TextBox
                        inputType="number"
                        value={rate?.productsTaxRate}
                        name="productsTaxRate"
                        min={0}
                        step={.0001}
                        onChange={(date) => handleChange(date, rate)}
                    />
                </td>
                <td>
                    <TextBox
                        inputType="number"
                        value={rate?.servicesTaxRate}
                        name="servicesTaxRate"
                        min={0}
                        step={.0001}
                        onChange={(date) => handleChange(date, rate)}
                    />
                </td>
                <td>
                    <Button
                        type="danger"
                        iconOnly
                        onClick={() => handleChange({name:"isDeleted", value: true}, rate, index)}
                    >
                        <i className="fas fa-trash-alt"/>
                    </Button>
                </td>
            </tr>
        );
    })

    return (
        <form onSubmit={handleSave} className={styles.root}>
            <hr className="margin-bottom-md"/>
            <table className="table">
                <thead>
                    <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Products Tax Rate</th>
                        <th>Services Tax Rate</th>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                    {rows}
                </tbody>
            </table>
            <div className="margin-top-x-sm">
                <Button
                    small
                    type="primary"
                    onClick={() => handleChange({name: "clinicId", value: props.clinicId})}
                >
                     <i className="text-sm fa fa-plus margin-right-x-sm"/> Add
                </Button>
            </div>
            <hr className="margin-top-md"/>
            <div className="flex justify-flex-end spaced-content margin-top-md">
                {!!props.onCancel && (
                    <Button onClick={props.onCancel} type="gray">
                        Cancel
                    </Button>
                )}
                <Button
                    large
                    wide
                    buttonType="submit"
                >
                    Save Changes
                </Button>
            </div>
        </form>
    )
}

ManageClinicTaxForm.propTypes = {
    clinicId: PropTypes.number.isRequired,
    onSubmit:PropTypes.func.isRequired,
    onCancel:PropTypes.func,
};

export default connect(
    (state, ownProps) => ({
        taxRates: state.entities.taxRates[ownProps.clinicId],
    })
)(ManageClinicTaxForm);