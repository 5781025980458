import request from "utils/request";
import getApiRoot from "./getApiRoot";


export function getNotifications(clinicId = null) {
    return request()
        .query({clinicId})
        .get(`${getApiRoot()}/v1/clinicdashboard/notifications`)
        .send();
}

export function getNotificationById(notificationId, clinicId = null) {
    return request()
        .query({clinicId})
        .get(`${getApiRoot()}/v1/clinicdashboard/notifications/${notificationId}`)
        .send();
}

export function setNotificationState(id, clinicId, { newState, userInitials = null, formFieldsJson = null }) {
    return request()
        .query({ clinicId })
        .put(`${getApiRoot()}/v1/clinicdashboard/notifications/${id}`)
        .send({
            newState,
            userInitials,
            formFieldsJson,
        });
}

export function getProviderNotificationById(notificationId, providerId = null) {
    return request()
        .get(`${getApiRoot()}/v1/providerdashboard/notifications/${notificationId}`)
        .send();
}

export function setProviderNotificationState(id, providerId, { newState, userInitials = null, formFieldsJson = null }) {
    return request()
        .put(`${getApiRoot()}/v1/providerdashboard/notifications/${id}`)
        .send({
            newState,
            userInitials,
            formFieldsJson,
        });
}

export function getAllNotifications() {
    return request()
        .get(`${getApiRoot()}/v1/services/notifications/partnerprograms`)
        .send();
}

export function createNotification(notification) {
    return request()
        .post(`${getApiRoot()}/v1/services/notifications/partnerprogram/create`)
        .send(notification);
}

export function editNotification(notification) {
    return request()
        .put(`${getApiRoot()}/v1/services/notifications/partnerprogram/edit`)
        .send(notification);
}

export function notifyGreenline(notification) {
    return request()
        .post(`${getApiRoot()}/v1/services/notifications/notifygreenline/`)
        .send(notification);
}
