exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".VetCheckDashboard__root{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;height:100%;-ms-flex:1;flex:1}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/vetcheck/pages/VetCheckDashboard.scss"],"names":[],"mappings":"AAAA,yBAAM,oBAAoB,aAAa,0BAA0B,sBAAsB,YAAY,WAAW,MAAM,CAAC","file":"VetCheckDashboard.scss","sourcesContent":[".root{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;height:100%;-ms-flex:1;flex:1}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "VetCheckDashboard__root"
};