import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import classnames from "classnames";
import * as styles from "./TourCompletePage.scss";
import * as UserActions from "actions/UserActions";
import ArrowIcon from "components/tour/elements/ArrowIcon";
import Button from "components/common/Button";
import Image from "components/common/Image";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {sendEvent} from "utils/Analytics";

const GreenlineLogo = require("components/common/branding/greenline-logo-footer.png");

function TourCompletePage(props) {
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(20);
    const [showRedirectMessage, setShowRedirectMessage] = useState(false);

    useEffect(() => {
        if(props.isLoggedIn && !props.userProfile) {
            props.loadCurrentUser();
        }
    }, [props.isLoggedIn]);

    useEffect(() => {
        if(!props.isStart && (timer > -5)) {
            setTimeout(() => {
                setTimer(timer - 1);
            }, 1000);
        }
    });

    useEffect(() => {
        if(!props.isStart) {
            if(timer === 0) {
                handleRegister();
            } else if(timer === -5) {
                setShowRedirectMessage(true);
            }
        }
    }, [timer]);

    if(!props.userProfile) {
        return <SpinnerTakeover show/>
    }

    const handleRegister = () => {
        setLoading(true);
        sendEvent("click", { value: "click_register_button" });
        window.location = "https://identity.greenlinepet.com/Registration/Form";
    }

    return (
        <div className={styles.root}>
            <div className={styles.title}>
                <div className="full-width">
                    <svg viewBox="0 0 369 19">
                        <path id="curve" d="M.5,18.5c0-9.94,82.38-18,184-18s184,8.06,184,18"/>
                        <text>
                            <textPath xlinkHref="#curve">
                                Congratulations!
                            </textPath>
                        </text>
                    </svg>
                </div>
            </div>
            <div className="flex-1 flex spaced-content margin-sm">
                <div className="flex-1 flex flex-centered">
                    <div>
                        <div className={classnames(styles.circles)}>
                            <div className={styles.TourComplete}>You have completed the Greenline website demo.</div>
                            <div className={styles.excitedToWork}>We are excited to work with your clinic!</div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 flex flex-centered">
                    <div className={styles.right}>
                        <div className={styles.arrow}>
                            <ArrowIcon/>
                        </div>
                        <div className={styles.fakeScreen}>
                            <div className="flex-5 flex flex-column">
                                <div>
                                    <Image src={GreenlineLogo}/>
                                </div>
                                <div className="full-width full-height flex flex-centered">
                                    <div className="text-lg text-center padding-md margin-auto" style={{maxWidth:"450px"}}>
                                        <div>You will now be redirected to the registration page.</div>
                                        <div className="text-x-sm text-default margin-top-x-sm" onClick={handleRegister}>
                                            {showRedirectMessage ? (
                                                <div className="text-center">
                                                    Redirect Blocked. Click here to <Button onClick={handleRegister} text type={"primary"}>go to registration</Button>
                                                </div>
                                            ) : (
                                                <div className=" text-center">({(timer > 0) ? `${timer} seconds` : "...redirecting"}) </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classnames(styles.fakeForm, "spaced-content")}>
                                <div className="full-width full-height flex flex-column space-between">
                                    <div>
                                        <div className="flex spaced-content align-center">
                                            <div className={styles.solidCircle}/>
                                            <div className={styles.oval}/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex spaced-content align-center">
                                            <div className={styles.oval}/>
                                            <div className={styles.hollowCircle}/>
                                        </div>
                                        <div className="flex spaced-content align-center" style={{marginBottom: "2.5px"}}>
                                            <div className={styles.oval}/>
                                            <div style={{width: "25px"}}/>
                                        </div>
                                        <div className="flex spaced-content align-center">
                                            <div className={styles.oval}/>
                                            <div style={{width: "25px"}}/>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="flex spaced-content align-center">
                                            <div className={styles.oval}/>
                                            <div className={styles.solidCircle}/>
                                        </div>
                                        <div className="flex spaced-content align-center">
                                            <div className={styles.oval}/>
                                            <div style={{width: "25px"}}/>
                                        </div>
                                    </div>
                                    <div className="margin-bottom-sm">
                                        <div className="flex spaced-content align-center">
                                            <div className={styles.hollowCircle}/>
                                            <div className={styles.oval}/>
                                        </div>
                                        <div className="flex spaced-content align-center">
                                            <div style={{width: "25px"}}/>
                                            <div className={styles.oval}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SpinnerTakeover show={loading}/>
        </div>
    );
}


export default connect(
    (state) => ({
        isLoggedIn: state.user.isLoggedIn,
        userProfile: state.user.userProfile,
    }),
    (dispatch) => ({
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
    }),
)(TourCompletePage);
