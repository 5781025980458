import React from "react";
import PropTypes from "prop-types";
import TextBox from "components/common/TextBox";

export default function SignatureBox(props) {
	const {
	    autoFocus = false,
	    disabled = false,
		hasError = false,
		label = "Please Sign Here",
		maxLength,
	    minLength = 0,
		required = false,
		value = "",
	} = props;

    return (
        <TextBox
	        {...props}
	        autoFocus={autoFocus}
	        disabled={disabled}
			hasError={hasError}
			label={label}
			maxLength={maxLength}
		    minLength={minLength}
			required={required}
			value={value}
	        frameless
	        alignedLeft
	        inputType="text"
	        style={{
				borderBottom: "1px solid rgba(169, 169, 169, 0.5)",
		        borderRadius: 0,
		        fontFamily: "Sacramento, cursive",
		        fontSize: "2em",
		        padding: 0,
	        }}
        />
    );
}

SignatureBox.defaultProps = {
    label: PropTypes.string,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    hasError: PropTypes.bool,
    maxLength: PropTypes.number,
    minLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    value: PropTypes.string,
};

