import React, {useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./RegistrationEnterVoucher.scss";
import * as ClinicApi from "api/ClinicApi";
import * as ClinicActions from "actions/ClinicActions";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import logger from "utils/logger";
import * as Sentry from "@sentry/react";

function RegistrationEnterVoucher(props){
    const [voucherCode, setVoucherCode] = useState("");
    const [codeChanged, setCodeChanged] = useState(false);
    const [showInfo, setShowInfo] = useState(false);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");

    const disableSubmit = props.verifying || !voucherCode || (voucherCode && !codeChanged);
    const hasError =  props.voucherError && !codeChanged;

    const verifyCode = () => {
        if(!disableSubmit) {
            setLoading(true);
            setCodeChanged(false);
            props.submittingVoucher();
            ClinicApi.verifyVoucherCode(voucherCode, props.clinicId)
                .then((res) => {
                    if (res.body.isValid) {
                        props.validVoucher(res.body);
                        props.onSubmit(res.body, 2);
                        setLoading(false);
                    } else {
                        setMessage(res.body.status);
                        setLoading(false);
                        props.invalidVoucher();
                    }
                })
                .catch((error) => {
                    logger.error("Error verifying voucher code ", error, true);
                });
        }
    };

    const handleChange = ({value}) => {
        setCodeChanged(true);
        setVoucherCode(value);
    };

    return (
        <div className={styles.formContainer} style={!props.clinicId ? {marginTop: "100px"} : {}}>
            <div className={styles.verifyCodeForm}>
                <form>
                    <label>
                        <div className={classnames(styles.label, "text-lg", {
                            [styles.invalid]: hasError,
                        })}>
                            Voucher Code
                            <i onClick={() => setShowInfo(true)} className="fal fa-info-circle pointer"/>
                        </div>
                        <TextBox
                            placeholder="Enter a valid code"
                            value={voucherCode}
                            name="voucherCode"
                            onChange={handleChange}
                            invalid={hasError}
                            customInvalidMessage="Invalid voucher code - see information above for details"
                            onEnter={verifyCode}
                        />
                        <p>{message}</p>
                    </label>
                    <div className={styles.button}>
                        <Button
                            onClick={verifyCode}
                            superwide
                            large
                            disabled={disableSubmit}
                        >
                            Submit
                        </Button>
                    </div>
                </form>
            </div>
            <Modal
                show={showInfo}
                onClose={() => setShowInfo(false)}
                small
                modalTitle={(
                    <div>
                        Voucher Code <i className="fal fa-info-circle"/>
                    </div>
                )}
            >
                <div className={classnames(styles.modalContent, "flex padding-lg")}>
                    The manufacturer representative who presented Greenline to you will have your personalized voucher code.
                    Please contact your representative to obtain this information.
                </div>
            </Modal>
            <SpinnerTakeover show={loading}/>
        </div>
    );
}

export default connect(
    (state) => ({
        verifying: state.entities.registration.verifying || false,
        voucherError: state.entities.registration.voucherError || false,
        voucherCode: state.entities.registration.voucherCode,
        provider: state.entities.registration.provider || null,
    }),
    (dispatch) => ({
        submittingVoucher: (data) => dispatch(ClinicActions.submittingVoucher(data)),
        validVoucher: (data) => dispatch(ClinicActions.validVoucher(data)),
        invalidVoucher: () => dispatch(ClinicActions.invalidVoucher())
    })
) (RegistrationEnterVoucher);

RegistrationEnterVoucher.propTypes = {
    clinicId: PropTypes.number,
    currentCode: PropTypes.string,
    currentPage: PropTypes.number,
    onSubmit: PropTypes.func,
};
