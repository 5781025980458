import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./PuppyLeafPartySnapshot.scss";
import PuppyLeafParty from "components/games/pages/ClinicGamePage/PuppyLeafParty";
import PrintContentContainer from "components/common/PrintContentContainer/PrintContentContainer";

export default function PuppyLeafPartySnapshot(props) {
    const [isPrinting, setIsPrinting] = useState(false);
    const gameKey = `game_${props.game.greenlineGameId}_day_${props.game.daysInto}`;

    return (
        <PrintContentContainer onSetIsPrinting={setIsPrinting}>
            <div className={styles.root}>
                <PuppyLeafParty
                    clinicId={props.clinicId}
                    game={props.game}
                    isProvider={props.isProvider}
                    snapshot
                    animate={props.animate && !isPrinting}
                    printing={isPrinting}
                    key={gameKey}
                />
            </div>
        </PrintContentContainer>
    );
}

PuppyLeafPartySnapshot.defaultProps = {
    animate: false,
    clinicId: null,
    dashboard: false,
    isProvider: false,
    nodeId: null,
    snapshot: false,
    userId: null,
};

PuppyLeafPartySnapshot.propTypes = {
    animate: PropTypes.bool,
    clinicId: PropTypes.number,
    dashboard: PropTypes.bool,
    game: PropTypes.object.isRequired,
    isProvider: PropTypes.bool,
    nodeId: PropTypes.number,
    snapshot: PropTypes.bool,
    userId: PropTypes.number,
};
