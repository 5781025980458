import React, {useMemo} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import map from "lodash/map";
import * as ClinicActions from "actions/ClinicActions";
import Button from "components/common/Button";
import {formatUSD} from "utils/numeric";
import {CHEWY, PET_MEDS, OUR_PRICE, SELLERS} from "utils/PriceWatchUtils";

function ProductsByPriceTableRow(props) {

    if(!(props.brandId && props.productId && props.product)) {
        return null;
    }

    const getBlankRow = (s, q) => {
        return (
            <td key={`td_${s}_${q}`}
                className="text-center"
            >
                -
            </td>
        );
    };

    return (
        <tr>
            <td>
                {props.product.productName}
            </td>
            {map(SELLERS, s => {
                return map(props.product.prices, quantity => {
                    const price = quantity?.providers?.[s];

                    if(!!price) {
                        return (
                            <td
                                key={`td_${s}_${quantity.quantity}`}
                                title={!!price?.modDate ? `Last Updated: ${moment(price.modDate).format("MM/DD/YYYY")}` : ""}
                                className="text-center"
                            >
                                {!!price?.productPriceURL ? (
                                    <a href={price.productPriceURL}
                                        target="_blank"
                                        className="text-primary"
                                    >
                                        {formatUSD(price?.externalPrice)}
                                    </a>
                                ) : (
                                    formatUSD(price?.externalPrice)
                                )}
                            </td>
                        );
                    } else return getBlankRow(s,quantity.quantity);
                });
            })}
        </tr>
    );
}

ProductsByPriceTableRow.propTypes = {
    brandId: PropTypes.number.isRequired,
    productId: PropTypes.number.isRequired,
};

export default connect(
    (state, ownProps) => {
        return {
            product: state.entities.comparablePrices?.[ownProps.brandId]?.products?.[ownProps.productId] || null,
            quantities: state.entities.comparablePrices?.[ownProps.brandId]?.quantities || [],
        }
    }
)(ProductsByPriceTableRow);
