// Pre-fill values
// {
//     autoRegisterOnLogIn: bool,
//     autoRegistrationProcArgsJson: JSON,
//     autoRegistrationProcName: null,
//     bannerImageUri: string,
//     cancelled: {
//         displayText: string,
//         supported: bool
//     },
//     controlParams: null,
//     createDate: string,
//     createUserId: number,
//     declined: {
//         buttonText: string,
//         displayText: string,
//         isCustom: bool,
//         supported: bool
//     },
//     displayOrder: number,
//     enrolled: {
//         buttonText: string,
//         displayText: string,
//         isCustom: bool,
//         supported: bool
//     },
//     isAlert: bool,
//     isVisibleInNotifications: bool,
//     longDescription: string,
//     modDate: string,
//     modUserId: number,
//     moreInfoShowCurrentStatus: bool,
//     moreInfoTargetWindow: string,
//     moreInfoViewOverride: string,
//     notificationType: string,
//     optinExpirationDate: string,
//     optinStartDate: string,
//     optinStatesJson: JSON,
//     partnerProgramIdentifier: string,
//     partnerProgramName: string,
//     pending: {
//         displayText: string,
//         supported: bool
//     },
//     programExpirationDate: string,
//     programStartDate: string,
//     providerId: number,
//     requiresInitials: bool,
//     shortDescription: string,
//     moreInfoShowCurrentStatus: bool
// }

import { CLINIC_INFORMATION_UPDATE } from "./Notifications";

// Enroll Options
export const ACKNOWLEDGE = "Acknowledge";
export const ACKNOWLEDGE_AND_CONTINUE = "Acknowledge and continue";
export const ACCEPT_TERMS = "Accept Terms";
export const ACCEPT_PROGRAM_TERMS_AND_OPT_IN = "Accept Program Terms and Opt-In";
export const ACCEPT_PROGRAM_TERMS_CODES_CREATED_OPT_IN = "Accept Program Terms, Codes Created, Opt-In";
export const CODES_CREATED = "Codes Created";
export const CODES_CREATED_OPT_IN = "Codes Created, Opt-In";
export const OPT_IN = "Opt-In";

// Decline Options
export const CANCEL = "Cancel";
export const DECLINE = "Decline";

export const CUSTOM = "Custom"; // For enroll and decline

export const DEFAULT_YES_OPTIONS = [{
    name: ACKNOWLEDGE,
    value: ACKNOWLEDGE
}, {
    name: ACKNOWLEDGE_AND_CONTINUE,
    value: ACKNOWLEDGE_AND_CONTINUE
}, {
    name: ACCEPT_TERMS,
    value: ACCEPT_TERMS
}, {
    name: ACCEPT_PROGRAM_TERMS_AND_OPT_IN,
    value: ACCEPT_PROGRAM_TERMS_AND_OPT_IN
}, {
    name: ACCEPT_PROGRAM_TERMS_CODES_CREATED_OPT_IN,
    value: ACCEPT_PROGRAM_TERMS_CODES_CREATED_OPT_IN
}, {
    name: CODES_CREATED,
    value: CODES_CREATED
}, {
    name: CODES_CREATED_OPT_IN,
    value: CODES_CREATED_OPT_IN
}, {
    name: OPT_IN,
    value: OPT_IN
}, {
    name: CUSTOM,
    value: CUSTOM
}];

export const DEFAULT_NO_OPTIONS = [{
    name: CANCEL,
    value: CANCEL
}, {
    name: DECLINE,
    value: DECLINE
}, {
    name: CUSTOM,
    value: CUSTOM
}];


export const GENERAL_UPDATE_TEMPLATE = {
    enrollOptions: [{
        name: ACKNOWLEDGE,
        value: ACKNOWLEDGE
    }, {
        name: ACCEPT_TERMS,
        value: ACCEPT_TERMS
    }, {
        name: CUSTOM,
        value: CUSTOM
    }],
    declineOptions: [{
        name: CANCEL,
        value: CANCEL
    }, {
        name: CUSTOM,
        value: CUSTOM
    }],
    preFillData: {
        declined: {
            buttonText: CANCEL,
            displayText: "Offer Declined",
            supported: true
        },
        enrolled: {
            supported: true,
            displayText: "Offer Accepted",
        },
        cancelled: {
            supported: false,
            buttonText: null,
            displayText: null
        },
        pending: {
            supported: false,
            buttonText: null,
            displayText: null
        },
        liveChat: {
            supported: false,
            buttonText: null,
            displayText: null
        }
    }
};

export const NEW_OFFER_TEMPLATE = {
    enrollOptions: [{
        name: OPT_IN,
        value: OPT_IN
    }, {
        name: ACCEPT_PROGRAM_TERMS_AND_OPT_IN,
        value: ACCEPT_PROGRAM_TERMS_AND_OPT_IN
    }, {
        name: CODES_CREATED,
        value: CODES_CREATED
    }, {
        name: CODES_CREATED_OPT_IN,
        value: CODES_CREATED_OPT_IN
    }, {
        name: CUSTOM,
        value: CUSTOM
    }],
    declineOptions: [{
        name: CANCEL,
        value: CANCEL
    }, {
        name: DECLINE,
        value: DECLINE
    }, {
        name: CUSTOM,
        value: CUSTOM
    }],
    preFillData: {
        declined: {
            supported: true,
            displayText: "Offer Declined",
        },
        enrolled: {
            supported: true,
            displayText: "Offer Accepted",
        },
        cancelled: {
            supported: false,
            buttonText: null,
            displayText: null
        },
        pending: {
            supported: false,
            buttonText: null,
            displayText: null
        },
        liveChat: {
            supported: false,
            buttonText: null,
            displayText: null
        }
    }
};

export const OFFER_CHANGE_OVER_TEMPLATE = {
    enrollOptions: [{
        name: OPT_IN,
        value: OPT_IN
    }, {
        name: ACCEPT_PROGRAM_TERMS_AND_OPT_IN,
        value: ACCEPT_PROGRAM_TERMS_AND_OPT_IN
    }, {
        name: CODES_CREATED,
        value: CODES_CREATED
    }, {
        name: CODES_CREATED_OPT_IN,
        value: CODES_CREATED_OPT_IN
    }, {
        name: CUSTOM,
        value: CUSTOM
    }],
    declineOptions: [{
        name: CANCEL,
        value: CANCEL
    }, {
        name: DECLINE,
        value: DECLINE
    }, {
        name: CUSTOM,
        value: CUSTOM
    }],
    preFillData: {
        declined: {
            supported: true,
            displayText: "Offer Declined",
        },
        enrolled: {
            supported: true,
            displayText: "Offer Accepted",
        },
        liveChat: {
            supported: false,
            buttonText: null,
            displayText: null
        }
    }
};

export const OFFER_EXPIRATION_TEMPLATE = {
    enrollOptions: [{
        name: ACKNOWLEDGE,
        value: ACKNOWLEDGE
    }, {
        name: ACKNOWLEDGE_AND_CONTINUE,
        value: ACKNOWLEDGE_AND_CONTINUE
    }, {
        name: CUSTOM,
        value: CUSTOM
    }],
    preFillData: {
        declined: {
            supported: false,
        },
        enrolled: {
            supported: true,
            displayText: "Offer Accepted",
        },
        cancelled: {
            supported: false,
            buttonText: null,
            displayText: null
        },
        pending: {
            supported: false,
            buttonText: null,
            displayText: null
        },
        liveChat: {
            supported: false,
            buttonText: null,
            displayText: null
        }
    }
};

export const PROGRAM_OPT_IN_TEMPLATE = {
    enrollOptions: [{
        name: ACCEPT_PROGRAM_TERMS_AND_OPT_IN,
        value: ACCEPT_PROGRAM_TERMS_AND_OPT_IN
    }, {
        name: CODES_CREATED_OPT_IN,
        value: CODES_CREATED_OPT_IN
    }, {
        name: ACCEPT_PROGRAM_TERMS_CODES_CREATED_OPT_IN,
        value: ACCEPT_PROGRAM_TERMS_CODES_CREATED_OPT_IN
    }, {
        name: CUSTOM,
        value: CUSTOM
    }],
    declineOptions: [{
        name: CANCEL,
        value: CANCEL
    }, {
        name: DECLINE,
        value: DECLINE
    }, {
        name: CUSTOM,
        value: CUSTOM
    }],
    preFillData: {
        declined: {
            supported: true,
            displayText: "Offer Declined",
        },
        enrolled: {
            supported: true,
            displayText: "Offer Accepted",
        },
        cancelled: {
            supported: false,
            buttonText: null,
            displayText: null
        },
        pending: {
            supported: false,
            buttonText: null,
            displayText: null
        },
        liveChat: {
            supported: false,
            buttonText: null,
            displayText: null
        }
    }
};

export const CONTACT_UPDATE_NOTIFICATION = {
    preFillData: {
        partnerProgramName: "Clinic Information Update",
        shortDescription: "Clinic Information Update",
        notificationType: CLINIC_INFORMATION_UPDATE,
        longDescription: "<p>Hello valued Greenline clinic!</p><p>Happy New Year! As we move into 2023, we want to ensure we have all of your correct clinic information. Please complete the form below so that we can ensure the accuracy of your account information and provide you with fantastic support in the future.<p>",
        enrolled: {
            supported: true,
            displayText: "Save and Confirm",
        },
    }
}

export const CUSTOM_NOTIFICATION_TEMPLATE = {
    preFillData: {
        declined: {
            supported: false,
            displayText: null,
        },
        enrolled: {
            supported: false,
            displayText: null,
        },
        cancelled: {
            supported: false,
            buttonText: null,
            displayText: null
        },
        pending: {
            supported: false,
            buttonText: null,
            displayText: null
        },
        liveChat: {
            supported: false,
            buttonText: null,
            displayText: null
        }
    }
}
