import { getPageName } from "constants/SiteSettings";

export const getSearchParam = (searchString, param) => {
    const search = new URLSearchParams(searchString);
    return search.get(param);
};

export const scrollToBottom = () => {
    window.scrollTo({
        top: document.body.scrollHeight,
        left: 0,
        behavior: 'smooth'
    });
}
export const setPageTitle = (pageTitle, isAdmin = false) => {
    const pageName = getPageName(pageTitle, isAdmin);
    document.title = pageName;
}
