import React from "react";
import * as styles from "./Beacon.scss";

export default function Beacon(props) {
    return (
        <span className={styles.customJoyRideBeacon} onClick={props.onClick} onMouseEnter={props.onMouseEnter} title={"Click here to Resume the Tour"}>
            Click to
            <br/>Continue
        </span>
    );
}