exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ManageInvites__root{overflow-x:scroll;height:100%;position:relative;-ms-flex:1;flex:1;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/provider/ManageInvites/ManageInvites.scss"],"names":[],"mappings":"AAAA,qBAAM,kBAAkB,YAAY,kBAAkB,WAAW,OAAO,oBAAoB,aAAa,0BAA0B,qBAAqB,CAAC","file":"ManageInvites.scss","sourcesContent":[".root{overflow-x:scroll;height:100%;position:relative;-ms-flex:1;flex:1;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "ManageInvites__root"
};