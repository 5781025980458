import request from "utils/request";
import getApiRoot from "./getApiRoot";
import { clinicTestDashboard } from "utils/DashboardTestData";
import createFakeApiResponse from "utils/createFakeApiResponse";
import * as ProviderIds from "constants/ProviderIds"

import { mapCustomClinicViewFromAppToServer } from "data/serverMapping";

export function getClinicInfo(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/clinics/${clinicId}/`)
        .send();
}

export function getClinicById(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/admin/clinics/${clinicId}/`)
        .send();
}

export function registerNewClinic(data) {
    return request()
        .post(`${getApiRoot()}/v1/registration/clinics/`)
        .send(data);
}

export function enrollClinic(clinicId, data) {
    return request()
        .post(`${getApiRoot()}/v1/registration/clinics/${clinicId}/planenrollments`)
        .send(data);
}

export function updateNewClinic(data) {
    return request()
        .put(`${getApiRoot()}/v1/registration/clinics/${data.clinicId}`)
        .send(data);
}

export function loadClinicForRegistration() {
    return request()
        .get(`${getApiRoot()}/v1/registration/clinics/`)
        .send();
}

export function verifyVoucherCode(code, clinicId=null) {
    return request()
        .query({clinicId})
        .get(`${getApiRoot()}/v1/registration/vouchercodes/${code}`)
        .send();
}

export function enrollClinicInProgram(clinicId, data) {
    /* {
          "planId": 6,
          "providerId": 0,
          "voucherCode": "string",
          "acceptsGreenlineTerms": true,
          "acceptsProviderTerms": true,
          "agreesToClinicDataShare": true,
          "agreesToStaffDataShare": true
    } */

    return request()
        .post(`${getApiRoot()}/v1/registration/clinics/${clinicId}/planenrollments`)
        .send(data);
}

export function getClinicTiles(clinicId = null, period="-30d") {
    return request()
        .query({
            period,
            clinicId
        })
        .get(`${getApiRoot()}/v1/clinicdashboard/tiles`)
        .send();
}

// This is the "updated" stats.
export function getClinicStats(clinicId = null, period="-90d") {
    return request()
        .query({
            period,
            clinicId
        })
        .get(`${getApiRoot()}/v1/clinicdashboard/stats`)
        .send();
}

export function getAverageClinicSales(clinicId = null) {
    return request()
        .get(`${getApiRoot()}/v1/admin/clinics/${clinicId}/calculators/opportunity`)
        .send();
}

export function getAverageClinicProfitabilitySales(clinicId = null) {
    return request()
        .get(`${getApiRoot()}/v1/admin/clinics/${clinicId}/calculators/profitability`)
        .send();
}

// This is the "full" stats.
export function getClinicStatsFull(clinicId = null, period = "-90d") {
    return request()
        .query({
            period,
            clinicId
        })
        .get(`${getApiRoot()}/v1/clinicdashboard`)
        .send();
}

export function searchClinics(options) {
    return request()
        .post(`${getApiRoot()}/v1/admin/clinics/search`)
        .send(options);
}

export function downloadClinicList(search, type) {
    const exportType = type ? type : "xlsx";
    return request()
        .query({exportType})
        .post(`${getApiRoot()}/v1/admin/clinics/export`)
        .responseType("blob")
        .send(search);
}

export function getReps(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/admin/clinics/${clinicId}/reps`)
        .send();
}

export function getHospitals(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/admin/clinics/${clinicId}/hospitals`)
        .send();
}

export function loadTags() {
    return request()
        .get(`${getApiRoot()}/v1/clinictags`)
        .send();
}

export function getAdminMappedClinics() {
    return request()
        .post(`${getApiRoot()}/v1/admin/cliniccoordinates`)
        .send({});
}

export function getProductBrands({limit=1000}) {
    return request()
        .query({
            limit
        })
        .get(`${getApiRoot()}/v1/admin/productbrands`)
        .send();
}

export function getClinicDvmCommissions(clinicId, limit = 9999) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinicdvms/search`)
        .send({
            clinicId,
            offset: 0,
            limit,
        });
}

export function getOnGardGameTasks(clinicId, gameId) {
    return request()
        .get(`${getApiRoot()}/v1/services/bigames/clinics/${clinicId}/ongard/${gameId}/tasks`)
        .send();
}

export function updateOnGardGameTasks(clinicId, gameId, data) {
    return request()
        .post(`${getApiRoot()}/v1/services/bigames/clinics/${clinicId}/ongard/${gameId}/tasks`)
        .send(data);
}

export function getGreenlineGames(clinicId) {
    return request()
        .query({clinicId})
        .get(`${getApiRoot()}/v1/services/bigames/clinicgames`)
        .send();
}

export function getGreenlineGameEndStats(clinicId, gameId) {
    return request()
        .query({clinicId})
        .get(`${getApiRoot()}/v1/services/bigames/clinicgames/endstats/${gameId}`)
        .send();
}

export function updateClinicSettings(clinicSettings) {
    return request()
        .put(`${getApiRoot()}/v1/clinics/${clinicSettings.id}`)
        .send(clinicSettings);
}

export function adminUpdateClinicSettings(clinicSettings) {
    return request()
        .put(`${getApiRoot()}/v1/admin/clinics/${clinicSettings.id}`)
        .send(clinicSettings);
}

export function deleteClinic(clinicId) {
    return request()
        .delete(`${getApiRoot()}/v1/admin/clinics/${clinicId}`)
        .send();
}

export function getTileDataByType(tileTypeId, timeRange) {
    // return request()
    //     .get(`${getApiRoot()}/v1/`) // ??
    //     .send();

    return createFakeApiResponse(clinicTestDashboard(tileTypeId, timeRange));
}

export function getClinicUsers(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/clinic/${clinicId}/users`)
        .send();
}

export function updateClinicUser(clinicId, clinicUserUpdate) {
    return request()
        .put(`${getApiRoot()}/v1/clinic/${clinicId}/users/${clinicUserUpdate.userId}`)
        .send(clinicUserUpdate);

    /*
    500 error:
    {
        "Type": null,
        "Title": "An unhandled exception was thrown by service",
        "Status": 500,
        "Detail": "No authentication handler is registered for the scheme 'Cannot create a user with this role'.
        The registered schemes are: BearerIdentityServerAuthenticationJwt, BearerIdentityServerAuthenticationIntrospection, Bearer.
         Did you forget to call AddAuthentication().Add[SomeAuthHandler](\"Cannot create a user with this role\",...)?",
        "Instance": null,
        "Extensions": {}
    }
    */
}

export function createClinicUser(clinicId, clinicUser) {
    return request()
        .post(`${getApiRoot()}/v1/clinic/${clinicId}/users`)
        .send(clinicUser);
}


export function assignHospital (data) {
    return request()
        .put(`${getApiRoot()}/v1/admin/hospitals/${data.hospitalId}`)
        .send(data);
}

export function createClinicTag(tag) {
    return request()
        .post(`${getApiRoot()}/v1/clinictags`)
        .send(tag);
}

export function updateClinicTags(clinicId, tags) {
    return request()
        .put(`${getApiRoot()}/v1/clinictags/${clinicId}/update`)
        .send(tags);
}

export function loadPIMSForClinic(clinicId) {
     return request()
        .get(`${getApiRoot()}/v1/admin/pimsconfigs/clinics/${clinicId}`)
        .send();

}

export function updatePIMSForClinic(data) {
     return request()
        .put(`${getApiRoot()}/v1/admin/pimsconfigs/clinics/${data.clinicId}`)
        .send(data);
}

export function generateInstallCode(clinicId) {
     return request()
        .post(`${getApiRoot()}/v1/admin/pimsconfigs/clinics/${clinicId}/generateinstallcode`)
        .send();
}

export function loadDefaultPIMSConfigurations() {
    return request()
        .get(`${getApiRoot()}/v1/admin/pimsconfigs`)
        .send();
}

export function getProviderLocations(clinicId, showOnlyEnrolled = true) {
    return request()
        .query({
            onlyShowEnrolledProviders: showOnlyEnrolled
        })
        .get(`${getApiRoot()}/v1/clinics/${clinicId}/providers`)
        .send();
}

export function setClinicProviderLocation(clinicId, providerId, locationId) {
    return request()
        .put(`${getApiRoot()}/v1/clinics/${clinicId}/providers/${providerId}/locations`)
        .send({
            locationId: locationId || "",
        });
}

export function loadClinicPIMSUsers(clinicId) {
    /*{
        "clinicPimsUsers": [],
        "staffTypeNameIds": [
            {
                "id": 1,
                "name": "DVM"
            },
            {
                "id": 2,
                "name": "Technician"
            },
            {
                "id": 3,
                "name": "Receptionist"
            }
        ],
        "clinicName": "Elanco"
    }*/
    return request()
        .get(`${getApiRoot()}/v1/admin/pimsusers/${clinicId}`)
        .send();
}

export function updateClinicPIMSUser({ pimsUserId, staffTypeId, clinicId }) {
    return request()
        .put(`${getApiRoot()}/v1/admin/pimsusers/${pimsUserId}`)
        .send({
            clinicId,
            staffTypeId,
        });
}

export function getClinicProductTags(clinicId) {
    return request()
        .query({clinicId})
        .get(`${getApiRoot()}/v1/pimsdata/productswithtags`)
        .send();
}

export function updateClinicProductTags(clinicProductId, productTagIds=[]) {
    return request()
        .put(`${getApiRoot()}/v1/pimsdata/productswithtags/${clinicProductId}`)
        .send({productTagIds: productTagIds});
}

export function loadTaxRates(clinicId) {
    return request()
        .query({clinicId})
        .get(`${getApiRoot()}/v1/services/wellness/clinictaxes`)
        .send();
}

export function batchUpdateClinicTax(data) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinictaxes/batchupdate`)
        .send(data);
}

export function updateClinicDvmCommission(data) {
    return request()
        .put(`${getApiRoot()}/v1/services/wellness/clinicdvms/${data.clinicDvmId}`)
        .send(data);
}

export function addClinicDvmCommission(data) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinicdvms`)
        .send(data);
}

export function deleteClinicDvm(dvmId) {
    return request()
        .delete(`${getApiRoot()}/v1/services/wellness/clinicdvms/${dvmId}`)
        .send();
}

export function getClinicDvmCommissionHistory(clinicDvmId) {
    return request()
        .get(`${getApiRoot()}/v1/services/wellness/clinicdvms/${clinicDvmId}/histories`)
        .send();
}

export function batchUpdateCommissionHistory(clinicDvmId, data) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinicdvms/${clinicDvmId}/histories/batchupdate`)
        .send(data);
}

export function getCustomClinicViews() {
    return request()
        .get(`${getApiRoot()}/v1/admin/userpreferences/clinicmanagement`)
        .send();
}

export function createCustomClinicView(data) {
    return request()
        .post(`${getApiRoot()}/v1/admin/userpreferences/clinicmanagement`)
        .send(mapCustomClinicViewFromAppToServer(data));
}

export function updateCustomClinicView(data) {
    return request()
        .put(`${getApiRoot()}/v1/admin/userpreferences/clinicmanagement/${data.userPreferenceId}`)
        .send(mapCustomClinicViewFromAppToServer(data));
}

export function deleteCustomClinicView(userPreferenceId) {
    return request()
        .delete(`${getApiRoot()}/v1/admin/userpreferences/clinicmanagement/${userPreferenceId}`)
        .send();
}

export function downloadInvoicePdf(invoiceId) {
    return request()
        .get(`${getApiRoot()}/v1/invoices/${invoiceId}/export`)
        .responseType("blob")
        .send();
}

export function downloadGroupOfferInvoicePdf(invoiceId) {
    return request()
        .get(`${getApiRoot()}/v1/invoices/groupredemption/${invoiceId}/export`)
        .responseType("blob")
        .send();
}

export function loadClinicWellnessPlans(clinicId, includeInactive=true) {
    return request()
        .query({
            includeInactive
        })
        .get(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/plans`)
        .send();
}

export function loadClinicWellnessPlanInstances(clinicId, providerId) {
    return request()
        .get(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/plans/clinicplans/${providerId}/`)
        .send();
}

export function updateClinicWellnessPlans(clinicId, data) {
    return request()
        .put(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/plans/${data.wellnessClinicPlanInstance?.clinicPlanInstanceId}`)
        .send(data);
}

export function updateHouseAccount(clinicId, houseAccountClinicDvmId, providerId) {
    return request()
        .put(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/plans/houseaccount/${houseAccountClinicDvmId}/provider/${providerId}/`)
        .send();
}

export function updateWellnessStartDate(clinicId, providerId, data) {
    return request()
        .put(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/plans/clinicprovider/${providerId}/`)
        .send(data);
}

export function batchUpdateClinicWellnessPlans(clinicId, data) {
    return request()
        .post(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/plans/batchupdate`)
        .send(data);
}

export function loadClinicPriceSchedules(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/priceschedules`)
        .send();
}

export function batchUpdateClinicPriceSchedules(clinicId, data) {
    return request()
        .put(`${getApiRoot()}/v1/services/wellness/clinics/${clinicId}/priceschedules/batchupdate`)
        .send(data);
}

export function getClinicSupportFiles() {
    return request()
        .get(`${getApiRoot()}/v1/support/downloadfiles`)
        .send();
}

export function downloadClinicSupportFile(fileId) {
    return request()
        .get(`${getApiRoot()}/v1/support/downloadfiles/${fileId}`)
        .responseType("blob")
        .send();
}

export function loadSoftwareVendors() {
    return request()
        .get(`${getApiRoot()}/v1/softwarevendors`)
        .send();
}

export function getAvailableReports(clinicId, providerId) {
    return request()
        .query({
            providerId
        })
        .get(`${getApiRoot()}/v1/services/reporting/clinics/${clinicId}/availablereports`)
        .send();
}

export function updateAvailableReports(data) {
    return request()
        .query({
            isActivated: data.isActivated
        })
        .put(`${getApiRoot()}/v1/services/reporting/clinics/${data.clinicId}/availablereports/${data.providerReportId}`)
        .send(data);
}

export function getExternalPrices() {
    return request()
        .get(`${getApiRoot()}/v1/services/pricewatch/externalprices`)
        .send();
}

export function resetAdminPassword(clinicId, userId, password) {
   return request()
        .post(`${getApiRoot()}/v1/clinic/${clinicId}/users/${userId}/SetPassword`)
        .send({ password })
}

export function extedAdminPasswordExpiration(clinicId, userId, expirationDate) {
    return request()
        .post(`${getApiRoot()}/v1/clinic/${clinicId}/users/${userId}/UpdatePasswordExpirationDate`)
        .send({ expirationDate });
}

export function getClinicLookerDashboards(clinicId) {
    return request()
        .query(clinicId)
        .get(`${getApiRoot()}/v1/services/reporting/clinicreports/looker/embed`)
        .send();
}

export function getEligibilityTrackerQRCode(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/clinics/${clinicId}/resources/qrcode`)
        .responseType("blob")
        .send();
}

export function getEligibleClients(clinicId, options={}) {
    const allOptions = {
        searchString: "",
        ...options
    };

    return request()
        .post(`${getApiRoot()}/v1/services/eligibilitytracker/clinics/${clinicId}/clients`)
        .send(allOptions);
}

export function loadLastInvoice(clinicId, providerId, clientId) {
    return request()
        .post(`${getApiRoot()}/v1/services/eligibilitytracker/clinics/${clinicId}/client/lastinvoices`)
        .send({
            manufacturerIds: [providerId],
            clientId,
        });
}

export function loadLookerReports(clinicId) {
    return request()
        .query({
            clinicId
        })
        .get(`${getApiRoot()}/v1/services/reporting/clinicreports/looker/embed`)
        .send();
}
