import React from "react";
import styles from "./WellnessHistory.scss";
import ClinicBase from "components/layout/ClinicBase";
import DvmCommissionTable from "components/wellness/widgets/DvmCommissionTable";
import {connect} from "react-redux";
import {clinicHasWellness, getClinicInfo} from "utils/ClinicData";
import AccessDenied from "components/common/AccessDenied";
import {PermissionTypes, userHasPermission} from "../../../utils/permissions/rolesPermissions";
import * as UserPermissions from "../../../constants/UserPermissions";
import {CLINIC_TAGS} from "constants/AdminControls";

class WellnessDvmCommission extends React.Component {
    render() {
        return (
            <ClinicBase
                pageTitle="DVM Commissions Setup"
                clinicId={this.props.clinicId}
                adminControls={CLINIC_TAGS}
            >
                <div className={styles.root}>
                    {!this.props.canViewClinicWellnessDvms ? (
                        <AccessDenied/>
                    ) : !this.props.clinicHasWellness ? (
                        <AccessDenied customMessage="This clinic does not have access to DVM Commissions Setup"/>
                    ) : (
                        <DvmCommissionTable clinicId={this.props.clinicId}/>
                    )}
                </div>
            </ClinicBase>
        )
    }
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        return {
            clinicId,
            clinicHasWellness: clinicHasWellness(state, clinicId),
            canViewClinicWellnessDvms
        }
    }
)(WellnessDvmCommission);
