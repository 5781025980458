import React from "react";
import PropTypes from "prop-types";
import styles from "./Loader.scss";
import greenline_logo_png from "components/common/branding/greenline-logo-circle.png";

export default function Loader(props) {
    return (
        <div
            data-testid="loader_component"
            className={styles.root}
        >
            <div className={styles.inner}>
                {props.text && (
                    <h2
                        data-testid="loader_title"
                        className={styles.title}
                    >
                        {props.text}
                    </h2>
                )}

                <img
                    className={styles.image}
                    src={greenline_logo_png}
                    alt="Greenline Logo"
                />

            </div>
        </div>
    )
}

Loader.propTypes = {
    test: PropTypes.string,
}