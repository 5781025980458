import React from "react";
import ClinicBase from "components/layout/ClinicBase";
import {connect} from "react-redux";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import * as styles from "./CumulativeOfferTrackingPage.scss"
import {getClinicInfo} from "utils/ClinicData";
import CumulativeOfferTrackingReport from "components/reports/widgets/CumulativeOfferTrackingReport";
import AccessDenied from "components/common/AccessDenied";
import {CLINIC_TAGS} from "constants/AdminControls";

function CouponAudit(props) {
    return (
        <ClinicBase
            pageTitle="Cumulative Offer Tracking"
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
        >
            <div className={styles.root}>
                {props.canViewCouponAudit ? (
                    <CumulativeOfferTrackingReport
                        clinicId={props.clinicId}
                    />
                ) : (
                     <AccessDenied/>
                )}
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        return {
            clinicId,
            canViewCouponAudit,
        }
    }
)(CouponAudit);
