import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {filter, map, orderBy, reject} from "lodash";
import DataTable from "components/common/datatable/DataTable";
import TextBox from "components/common/TextBox";
import CheckboxInput from "components/common/CheckboxInput";
import Button from "components/common/Button";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import PlanEnrollmentMapping from "components/admin/elements/PlanEnrollmentMapping";
import * as SERVICE_TYPES from "constants/ServiceTypes";

function ClinicPlanServices(props) {
    const serviceCategories = [SERVICE_TYPES.SERVICES, SERVICE_TYPES.PRODUCTS, SERVICE_TYPES.ADD_ON, SERVICE_TYPES.SPECIAL];

    const handleChange = ({ name, value, enrollmentMapping=null }, row) => {
        let newData;
        if (!!enrollmentMapping) {
            newData = {
                ...props.services[row.clinicPlanServiceTypeId],
                enrollmentMappings: {
                    ...props.services[row.clinicPlanServiceTypeId].enrollmentMappings,
                    [enrollmentMapping.id]: {
                        ...enrollmentMapping
                    }
                }
            }
        } else {
            newData = {
                ...props.services[row.clinicPlanServiceTypeId],
                [name]: value
            }
        }
        props.handleChange({service: newData});
    }

    const AddRow = (row) => {
        const lastMapping = Object.keys(row.enrollmentMappings).length ? orderBy(row.enrollmentMappings, ["id"], ["desc"])[0] : {};
        const nextId = lastMapping ? lastMapping.id + 1 : 0;

        const tempData = {
            ...row.enrollmentMappings,
            [nextId] : {
                id: nextId,
                isNew: true,
                clinicProductId: null
            }
        }
        handleChange({ name: "enrollmentMappings", value: tempData }, row);
    }

    const SERVICE_COLUMNS = [{
        name: "Service",
        selector: "serviceTypeName",
        key: "serviceTypeName",
    }, {
        name: "Is Unlimited?",
        selector: "isUnlimited",
        key: "isUnlimited",
        format: row => (
            <CheckboxInput
                name="isUnlimited"
                checked={row.isUnlimited}
                onChange={(data) => handleChange(data, row)}
                disabled={props.readOnly}
            />
        )
    }, {
        name: "Visits/Uses",
        selector: "maxQuantityAllowedInTerm",
        key: "maxQuantityAllowedInTerm",
        format: row => (
            <TextBox
                name="maxQuantityAllowedInTerm"
                value={row.isUnlimited ? "" : row.maxQuantityAllowedInTerm}
                onChange={(data) => handleChange(data, row)}
                inputType="number"
                min={0}
                disabled={row.isUnlimited || props.readOnly}
            />
        )
    }];

    const PRODUCTS_COLUMNS = [{
        name: "Product",
        selector: "serviceTypeName",
        key: "serviceTypeName",
    }, {
        name: "Is Unlimited?",
        selector: "isUnlimited",
        key: "isUnlimited",
        format: row => (
            <CheckboxInput
                name="isUnlimited"
                checked={row.isUnlimited}
                onChange={(data) => handleChange(data, row)}
                disabled={props.readOnly}
            />
        )
    }, {
        name: "Months Supply",
        selector: "maxQuantityAllowedInTerm",
        key: "maxQuantityAllowedInTerm",
        format: row => (
            <TextBox
                name="maxQuantityAllowedInTerm"
                value={row.isUnlimited ? null : row.maxQuantityAllowedInTerm}
                onChange={(data) => handleChange(data, row)}
                inputType="number"
                min={0}
                disabled={row.isUnlimited || props.readOnly}
            />
        )
    }];

    const ADD_ON_COLUMNS = [{
        name: "Add-on",
        selector: "serviceTypeName",
        key: "serviceTypeName",
    }, {
        name: "Is Unlimited?",
        selector: "isUnlimited",
        key: "isUnlimited",
        format: row => (
            <CheckboxInput
                name="isUnlimited"
                checked={row.isUnlimited}
                onChange={(data) => handleChange(data, row)}
                disabled={props.readOnly}
            />
        )
    }, {
        name: "Visits/Uses",
        selector: "maxQuantityAllowedInTerm",
        key: "maxQuantityAllowedInTerm",
        format: row => (
            <TextBox
                name="maxQuantityAllowedInTerm"
                value={row.isUnlimited ? "" : row.maxQuantityAllowedInTerm}
                onChange={(data) => handleChange(data, row)}
                inputType="number"
                min={0}
                disabled={row.isUnlimited || props.readOnly}
            />
        )
    }, {
        name: "Monthly Add-On Cost to Client",
        selector: "monthlyAddOnCostToClient",
        key: "monthlyAddOnCostToClient",
        format: row => (
            <div className="flex flex-end flex-centered">
                <div className="flex-none margin-bottom-x-sm margin-right-x-sm">$</div>
                <div className="flex-1">
                    <TextBox
                        name="monthlyAddOnCostToClient"
                        value={row.monthlyAddOnCostToClient}
                        onChange={(data) => handleChange(data, row)}
                        inputType="number"
                        min={0}
                        disabled={props.readOnly}
                    />
                </div>
            </div>
        )
    }, {
        name: "Clinic Product that triggers enrollment",
        selector: "enrollmentMappings",
        key: "enrollmentMappings",
        format: row => {
            const hasBlankEnrollmentMapping = !!reject(row.enrollmentMappings, em => !!em.clinicProductId).length;
            return (
                <div className="flex spaced-content">
                    <div className="flex-1">
                        {displayEnrollmentMappings(row)}
                    </div>
                    {props.canEditClinicWellnessPlans &&
                        <Button
                            iconOnly
                            type="success"
                            text
                            onClick={() => AddRow(row)}
                            disabled={hasBlankEnrollmentMapping || !Object.keys(row.enrollmentMappings).length || props.readOnly}
                        >
                            <i className="fa-2x fa fa-plus-square" />
                        </Button>
                    }
                </div>
            )
        },
    }];

    const getColumns = (category) => {
        switch (category) {
            case SERVICE_TYPES.PRODUCTS:
                return PRODUCTS_COLUMNS
            case SERVICE_TYPES.ADD_ON:
                return ADD_ON_COLUMNS;
            case SERVICE_TYPES.SERVICES:
            case SERVICE_TYPES.SPECIAL:
            default:
                return SERVICE_COLUMNS;
        }
    }

    const handleDeleteMapping = (enrollmentMapping, row) => {
        if(enrollmentMapping.isNew) {
            const newEnrollmentMappings = props.services[row.clinicPlanServiceTypeId].enrollmentMappings;
            delete newEnrollmentMappings[enrollmentMapping.id];
            const newData = {
                ...props.services[row.clinicPlanServiceTypeId],
                enrollmentMappings: newEnrollmentMappings
            }
            props.handleChange({service: newData});
        } else {
            const newEnrollmentMapping = {
                ...enrollmentMapping,
                isDeleted: true
            }
            handleChange({enrollmentMapping: newEnrollmentMapping}, row);
        }
    }

    const displayEnrollmentMappings = (row) => {
        //Remove the deleted enrollment mappings
        const enrollmentMappings = reject(row.enrollmentMappings, "isDeleted");
        const lastMapping = enrollmentMappings.length ? orderBy(enrollmentMappings, ["id"], ["desc"])[0] : {};
        const nextId = lastMapping ? lastMapping.id + 1 : 0;

        if (enrollmentMappings.length) {
            return (
                map(enrollmentMappings, (PEM) => (
                    <PlanEnrollmentMapping
                        key={PEM.id}
                        planEnrollmentMapping={PEM}
                        handleChange={(data) => handleChange(data, row)}
                        handleDeleteMapping={() => handleDeleteMapping(PEM, row)}
                        readOnly={props.readOnly}
                    />
                ))
            );
        } else {
            return (
                <PlanEnrollmentMapping
                    planEnrollmentMapping={{}}
                    nextId={nextId}
                    handleChange={(data) => handleChange(data, row)}
                    readOnly={props.readOnly}
                />
            )
        }
    }

    return map(serviceCategories, (category, index) => (
        <div key={index}
            className="margin-bottom-md"
        >
            <DataTable
                title={category}
                columns={getColumns(category)}
                data={orderBy(filter(props.services, { serviceCategoryName: category }), "serviceTypeName")}
                pagination={false}
            />
        </div>
    ));
}

ClinicPlanServices.propTypes = {
    services: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    // usedPims: PropTypes.arrayOf(PropTypes.number)
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canEditClinicWellnessPlans = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewProductMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);
        return {
            canEditClinicWellnessPlans,
            canViewProductMapping,
        }
    }
)(ClinicPlanServices);
