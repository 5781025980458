import React from "react";
import { formatUSD, setFixed } from "../../../utils/numeric";
import AdminBase from "components/layout/AdminBase";
import {CLINIC_TAGS} from "constants/AdminControls";
import AccessDenied from "components/common/AccessDenied";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import Dropdown from "components/common/Dropdown";
import { useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import * as ClinicApi from "api/ClinicApi";
import TextBox from "components/common/TextBox";
import * as RoleTypes from "constants/RoleTypes";
import { currentUserLoaded } from "actions/UserActions";

function ProfitabilityCalculator(props) {
    const defaultGlSurcharge = 10;
    const vetPackSurcharge = 5;

    const [isBusy, setIsBusy] = useState(true);
    const [productOptions, setProductOptions] = useState([]);
    const [bandOptions, setBandOptions] = useState([]);

    const [formData, setFormData] = useState({
        glSurcharge: defaultGlSurcharge
    });

    const currencyFormat = {
        useCents: true,
        useCommas: true,
        decimals: 0
    }

    const fixedFormat = {
        useCommas: true,
        decimals: 0
    }

    useEffect(() => {
        if(props.clinicId) {
            ClinicApi.getAverageClinicProfitabilitySales(props.clinicId)
                .then((res) => {
                    const products = res.body.map(x => { return { 
                        name: x.productName, value: x 
                    }});
                    setProductOptions(products);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    setIsBusy(false);
                });
        }
    }, [props.clinicId])

    const handleProductChanged = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });

        if(name === "selectedProduct") {
            console.log('Chaning products -- we should set up bands.')

            if(!value) {
                console.error.log('value is not populated.')
                setBandOptions([])
            }

            const bands = value.bands.map(p => { return {
                name: `${p.start}lb - ${p.end}lb`,
                value: p
            }})

            setBandOptions(bands)
        }
    };

    function getYearPrice() {
        return formData?.selectedBand?.annualPrice || 0
    }

    function formatYearPrice() {
        return formatUSD(getYearPrice(), currencyFormat)
    }

    function annualAndSurcharge() {
        return +getYearPrice() * +(1 + (formData?.glSurcharge || defaultGlSurcharge)/100)
    }

    function formattedAnnualAndSurcharge() {
        return formatUSD(annualAndSurcharge(), currencyFormat)
    }

    function amountPerMonth() {
        return annualAndSurcharge()/12 + 
            ((formData.shippingRebate || 0) / 12)
    }

    function formattedMonthlyAmount() {
        return formatUSD(amountPerMonth(), currencyFormat)
    }

    function monthlyVetPackCost() {
        return amountPerMonth() * (vetPackSurcharge / 100)
    }

    function formattedVetPackCost() {
        return formatUSD(monthlyVetPackCost(), currencyFormat)
    }

    function monthlyProfit() {
        return formatUSD((formData.suggestedMonthly || amountPerMonth()) -
            (monthlyVetPackCost() || 0) -
            (formData.productCost || 0) +
            ((formData.shippingRebate || 0) / 12), currencyFormat)
    }

    return (
        <AdminBase
            clinicId={props.clinicId}
            collapsible
            pageTitle="Profitability Calculator"
            controls={CLINIC_TAGS}
        >
            {props.canViewCalculators ? (
                <div className="margin-bottom-lg">
                    <div className={"height-100-perc flex flex-column"}>
                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">
                                <Dropdown
                                    options={productOptions}
                                    label={"Product"}
                                    name="productId"
                                    value={formData.selectedProduct}
                                    placeholder={"--Select Product--"}
                                    onChange={(field) => handleProductChanged("selectedProduct", field.value)}
                                />
                            </div>
                            <div className="flex-5">
                                <Dropdown
                                    options={bandOptions}
                                    label={"Band"}
                                    name="bandName"
                                    value={formData.selectedBand}
                                    placeholder={"--Select Weight Band--"}
                                    onChange={(field) => handleProductChanged("selectedBand", field.value)}
                                />
                            </div>
                            <div className="flex-5">
                                <TextBox
                                    label="Surcharge (%)"
                                    inputType="number"
                                    value={formData.glSurcharge}
                                    onChange={(field) => handleProductChanged("glSurcharge", field.value)}
                                />
                            </div>
                        </div>
                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">
                                Annual Price (12 Doses): {formatYearPrice()}</div>
                        </div>

                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">Annual + Surcharge ({formData.glSurcharge}%): {formattedAnnualAndSurcharge()}</div>
                        </div>

                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">
                                <TextBox
                                    label="Annual Clinic Shipping Rebate (Paid up Front)"
                                    inputType="number"
                                    value={formData.shippingRebate}
                                    onChange={(field) => handleProductChanged("shippingRebate", field.value)}
                                />
                            </div>
                        </div>

                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">Total Monthly: ~{formattedMonthlyAmount()}</div>
                        </div>

                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">
                                <TextBox
                                    label="Suggested Monthly: $"
                                    inputType="number"
                                    value={formData.suggestedMonthly}
                                    onChange={(field) => handleProductChanged("suggestedMonthly", field.value)}
                                />
                            </div>
                        </div>

                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">
                                <TextBox
                                    label="Product Cost: $"
                                    inputType="number"
                                    value={formData.productCost}
                                    onChange={(field) => handleProductChanged("productCost", field.value)}
                                />
                            </div>
                        </div>
                        
                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">VetPack Cost: {formattedVetPackCost()}</div>
                        </div>
                        
                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">Monthly Profit: {monthlyProfit()}</div>
                        </div>
                    </div>
                </div>
            ) : <AccessDenied/>}
            <SpinnerTakeover show={isBusy}/>
        </AdminBase>
    );
}

export default connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const userProfile = state.user.userProfile;
        
        const rolesToAllow = RoleTypes.ROLES.filter(x => x.userType === "GL").map(x => x.value);
        const allowedUserPermissions = userProfile.roles.filter(userGroup => {
            return rolesToAllow.some(allowedGroup => allowedGroup === userGroup);
         });
        const canViewCalculators = allowedUserPermissions.length > 0;

        return {
            clinicId,
            canViewCalculators: canViewCalculators,
        }
    }
)(ProfitabilityCalculator)
