import React from "react";
import {connect} from "react-redux";
import styles from "./VetCheckManageAutomations.scss";
import ClinicBase from "components/layout/ClinicBase";
import {getClinicInfo} from "utils/ClinicData";
import VetCheckHeader from "components/vetcheck/widgets/VetCheckHeader";
import ManageAutomations from "components/vetcheck/widgets/ManageAutomations";
import AccessDenied from "components/common/AccessDenied";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function VetCheckManageAutomations(props) {
    return (
        <ClinicBase
            clinicId={props.clinicId}
            docTitle={"VetCheck"}
        >
            <div className={styles.root}>
                <VetCheckHeader
                    clinicId={props.clinicId}
                    location={props.location}
                />
                {!props.canViewVetCheckMapping ? (
                    <AccessDenied/>
                ) : (
                    <ManageAutomations
                        location={props.location}
                        clinicId={props.clinicId}
                    />
                )}
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        const userProfile = state.user.userProfile;
        // Permissions
        const canViewVetCheckMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);
        return {
            clinicId,
            // Permissions
            canViewVetCheckMapping,
        }
    }
)(VetCheckManageAutomations);
