import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import * as AdminActions from "actions/AdminActions";
import Chart from "components/common/Chart";
import { formatUSD } from "utils/numeric";

function PriceHistoryPopup(props) {

    useEffect(() => {
        if (!props.priceHistory) {
            props.getPriceHistory(props.clinicId, props.clinicProductId);
        }
    }, []);


    return (
        <div className="spaced-content">
            {props.priceHistory ? (
                <>
                    <div className="margin-bottom-md margin-left-sm">
                        <div className="flex spaced-content">
                            <div className="flex-1">Samples in the last month: <b>{props.priceHistory.sampleCount}</b></div>
                            <div className="flex-1">Max Price: <b>{formatUSD(props.priceHistory.max)}</b></div>
                        </div>
                        <div className="flex spaced-content">
                            <div className="flex-1">Average price per package: <b>{formatUSD(props.priceHistory.average)}</b></div>
                            <div className="flex-1">Min Price: <b>{formatUSD(props.priceHistory.min)}</b></div>
                        </div>
                    </div>
                    <div>
                        <Chart
                            config={{
                                series: [{
                                    name: props.clinicProductId,
                                    data: props.priceHistory.prices
                                }],
                                yAxis: {
                                    title: {
                                        text: "Price"
                                    }
                                },
                                xAxis: {
                                    tickLength: 0,
                                    labels: {
                                        enabled: false,
                                    }
                                },
                                legend: {
                                    enabled: false
                                },
                                tooltip: {
                                    formatter: function () {
                                        const price = !!this.y ? formatUSD(this.y) : "$0"
                                        const quantity = props.priceHistory.quantities[this.x];
                                        const sold = !!this.y ? `${quantity}pk${quantity.length > 1 ? "s" : ""}` : "0"
                                        return `<span><b>Price: ${price}</b><br/>Sold: ${sold}</span>`;
                                    }
                                }
                            }}
                            // title={{text: `#${props.clinicProductId} - 6 Month Price History`}}
                        />
                    </div>
                </>
            ) : (
                <div>No Data</div>
            )}
        </div>
    )
}

PriceHistoryPopup.propTypes = {
    clinicId: PropTypes.number.isRequired,
    clinicProductId: PropTypes.number.isRequired,
};

const connector = connect(
    (state, ownProps) => ({
        priceHistory: state.entities.priceHistories[ownProps.clinicProductId]
    }),
    (dispatch) => ({
        getPriceHistory: (clinicId, clinicProductId) => dispatch(AdminActions.getPriceHistory(clinicId, clinicProductId)),
    })
);

export default compose(
    withRouter,
    connector
)(PriceHistoryPopup);
