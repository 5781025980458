import React, {useMemo, useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "./MappingTable.scss";
import * as MappingActions from "actions/MappingActions";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import union from "lodash/union";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import TextBox from "components/common/TextBox";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import VetCheckTableSection from "components/admin/widgets/VetCheckTableSection";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import {HANDOUT, CLIENT_FORM, contentTypeTitles} from "constants/VetCheckContent";

function VetCheckMappingTable(props) {
    if(!props.canViewVetCheckMapping) {
        return <AccessDenied/>;
    }
    const [contentType, setContentType] = useState(null);
    const [contentId, setContentId] = useState(null);

    useEffect(() => {
        if(props.canViewVetCheckMapping) {
            props.getVetCheckContent(props.clinicId);
            props.getVetCheckMappings(props.clinicId);
        }
    }, [props.clinicId]);

    const PIMS_OPTIONS = useMemo(() => {
        return map(props.pims, (pim) => {
            return {name: `(${pim.remoteClinicProductDisplayID}) ${pim.name}`, value: pim.clinicProductId};
        })
    }, [props.pims]);

    const orderedPimsOptions = useMemo(() => {
        return orderBy(PIMS_OPTIONS, po => {return includes(props.disabledPimsOptions, po.value)});
    }, [PIMS_OPTIONS, props.disabledPimsOptions]);

    const filteredContent = useMemo(() => {
        if(!!props.search) {
            return filter(props.content, c => {
                const searchTokens = props.search.toLowerCase().split(" ");
                let keep = true
                for (let i = 0; i < searchTokens.length; i++) {
                    const token = searchTokens[i];
                    if (!c.name || !c.name.toString().toLowerCase().includes(token)) {
                        keep = false;
                    }
                }
                return keep;
            });
        } else return props.content;
    }, [props.content, props.search]);

    if (props.working || !filteredContent) {
        return <SpinnerTakeover show/>;
    }

    const handleAddCustomContent = () => {
        props.getVetCheckContentById(props.clinicId, contentId, contentType);
        setContentId(null);
    }

    const sections = map(orderBy(filteredContent, ["title"]), (c) => {
        return (
            <VetCheckTableSection
                key={c.clinicHandoutId}
                typeId={props.typeId}
                clinicId={props.clinicId}
                clinicHandoutId={c.clinicHandoutId}
                pimsOptions={orderedPimsOptions}
                disabledPimsOptions={props.disabledPimsOptions}
                boldPimsOptions={props.boldPimsOptions}
            />
        )
    });
    return (
        <div className="flex flex-column height-100-perc">
            <div className={classnames(styles.mappingHeader, "flex spaced-content align-center flex-none")}>
                <div className="flex flex-wrap spaced-content align-center full-width">
                    <div className="flex-1 flex spaced-content align-center flex-wrap">
                        <h2 className="nowrap">VetCheck Mappings</h2>
                        <h3 className="nowrap">({Object.keys(props.content).length} products / {props.totalMappings} mappings)</h3>
                    </div>
                    {props.canEditVetCheckMapping && (
                        <div className="flex-none flex spaced-content align-center justify-flex-end">
                            <div>
                                <TextBox
                                    placeholder={`Enter Content Id`}
                                    name="contentId"
                                    value={contentId}
                                    onChange={({value}) => setContentId(value)}
                                    inputType="number"
                                    min={0}
                                />
                            </div>
                            <div>
                                <Dropdown
                                    name="contentType"
                                    value={contentType}
                                    onChange={({value}) => setContentType(value)}
                                    options={[
                                        {name: contentTypeTitles[HANDOUT], value: HANDOUT},
                                        {name: contentTypeTitles[CLIENT_FORM], value: CLIENT_FORM},
                                    ]}
                                    placeholder="Select Content Type"
                                    disabled={!contentId}
                                />
                            </div>
                            <div className="margin-bottom-x-sm">
                                <Button
                                    onClick={handleAddCustomContent}
                                    disabled={!(contentId && contentType)}
                                >
                                    Add Custom {contentTypeTitles[contentType]}
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <hr className="full-width"/>
            <div className="flex-1">
                <div className={classnames(styles.overflow, "height-100-perc overflow-x-scroll full-width")}>
                    <table className="table">
                        <thead>
                            <tr className="spaced-content">
                                <th>ID</th>
                                <th>Title</th>
                                <th>Keywords</th>
                                <th/>
                                <th>Clinic Product</th>
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {sections}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

VetCheckMappingTable.propTypes = {
    clinicId: PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const vetCheck = state.entities.vetCheckProducts;
        const content = vetCheck ? orderBy(vetCheck[ownProps.clinicId], ["title"]) : null;
        const totalMappings = state.entities.mappingCounts?.[ownProps.clinicId]?.totalVetCheckMappings;
        const vetCheckMappings = (ownProps.clinicId && state.entities.vetCheckMappings[ownProps.clinicId]) ? state.entities.vetCheckMappings[ownProps.clinicId] : {};
        const disabledPimsOptions = union(...map(vetCheckMappings, product => map(product.mappings, "clinicProductId"))) || [];
        const pims = state.entities.pims?.products || {}
        const boldPimsOptions = flatMap(filter(pims, "productUsedRowCount"), "clinicProductId");

        // Permissions
        const canViewVetCheckMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);
        const canEditVetCheckMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.VETCHECK_MAPPING, userProfile);

        return {
            content,
            totalMappings,
            disabledPimsOptions,
            pims,
            boldPimsOptions,
            search: state.entities.genericSearch,
            working: !!state.applicationState.working,
            // Permissions
            canViewVetCheckMapping,
            canEditVetCheckMapping
        }
    },
    (dispatch) => ({
        getVetCheckContent: (clinicId) => dispatch(MappingActions.getVetCheckContent(clinicId)),
        getVetCheckMappings: (clinicId) => dispatch(MappingActions.getVetCheckMappings(clinicId)),
        getVetCheckContentById: (clinicId, contentId, contentType) => dispatch(MappingActions.getVetCheckContentById(clinicId, contentId, contentType)),
    }),
) (VetCheckMappingTable);
