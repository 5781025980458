import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

function ClinicTagsList(props) {
    if (!props.clinic?.tags?.length) {
        return null;
    }

    return (
        <div className="flex full-width text-left padding-right-md">
            <b className="text-bold margin-right-x-sm">TAGS:</b>{props.clinic.tags.join(", ")}
        </div>
    )
}
ClinicTagsList.propTypes = {
    clinicId: PropTypes.number.isRequired
};

export default connect(
    (state, ownProps) => ({
        clinic: state.entities.clinicDetails[ownProps.clinicId],
    })
)(ClinicTagsList);
