import React from "react";
import PropTypes from "prop-types";
import styles from "./PuppyPoolPartySnapshot.scss";
import PuppyPoolParty from "components/games/pages/ClinicGamePage/PuppyPoolParty";

export default function PuppyPoolPartySnapshot(props) {
    const gameKey = `game_${props.game.greenlineGameId}_day_${props.game.daysInto}`;
    return (
        <div className={styles.root}>
            <PuppyPoolParty
                clinicId={props.clinicId}
                game={props.game}
                snapshot
                animate={props.animate}
                key={gameKey}
            />
        </div>
    );
}

PuppyPoolPartySnapshot.propTypes = {
    game: PropTypes.object,
    userId: PropTypes.number,
    dashboard: PropTypes.bool,
    clinicId: PropTypes.number,
    nodeId: PropTypes.number,
    animate: PropTypes.bool,
};
