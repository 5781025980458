import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import filter from "lodash/filter";
import get from "lodash/get";
import includes from "lodash/includes";
import upperFirst from "lodash/upperFirst";
import * as ProviderActions from "actions/ProviderActions";
import * as ProviderApi from "api/ProviderApi";
import Button from "components/common/Button";
import CorporateGroupFilterDropdown from "components/provider/widgets/CorporateGroupFilterDropdown";
import ProviderBase from "components/layout/ProviderBase";
import ProviderClinicTable from "components/provider/widgets/ProviderClinicTable";
import ProviderTileGrid from "components/provider/widgets/ProviderTileGrid";
import ResultsPerPageDropdown from "components/coupons/widgets/ResultsPerPageDropdown";
import { downloadFile } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as OrgNodeTypes from "constants/OrgNodeTypes";
import * as UserPermissions from "constants/UserPermissions";
import * as UserRoles from "constants/UserRoles";

function NHODashboard(props) {
    const [corporateGroup, setCorporateGroup] = useState(null);
    const [resultsPerPage, setResultsPerPage] = useState(10);
	const { pathname } = useLocation();
    const isAdmin = !!includes(pathname, "/admin");

	useEffect(() => {
		if (props.canViewNotifications && !isAdmin) {
			props.getProviderDashboard();
		}
	}, []);

    useEffect(() => {
        props.getClinicsForNode(props.nodeId);
    }, [props.nodeId]);

    const filteredClinics = useMemo(() => {
        if (corporateGroup) {
            if (corporateGroup === "UNKNOWN") {
                return filter(props.clinics, { accountNumber: null });
            }
            return filter(props.clinics, { accountNumber: corporateGroup });
        } else {
            return props.clinics;
        }
    }, [props.clinics, corporateGroup])

    const getPageTitle = () => {
        if (!props.node) {
            return "";
        }

        const displayName = props.providerName || props.node?.name;

        switch (props.node?.nodeType) {
            case OrgNodeTypes.HQ:
                return `${displayName} | HQ`;
            default:
                return `${displayName} | ${upperFirst(props.node?.nodeType)}`;
        }
    };

    const pageTitle = getPageTitle(props.node);

    const TILES = [{
        id: "activeClinics",
        title: "Total Active Clinics",
        value: get(props.node, "stats.clinics.goGreen", 0),
        link: "clinics",
    }, {
        id: "couponsOnly",
        title: "Coupons Only",
        value: get(props.node, "stats.clinics.couponsOnly", 0) || get(props.node, "stats.clinics.goGreen", 0),
    }, {
        id: "totalPendingCoupons",
        title: "Total Pending Coupons",
        value: get(props.node, "stats.coupons.pending", 0),
    }, {
        id: "generatedMtd",
        title: "Generated MTD",
        value: get(props.node, "stats.coupons.mtd.created", 0),
    }, {
        id: "generatedYtd",
        title: "Generated YTD",
        value: get(props.node, "stats.coupons.ytd.created", 0),
    }];

    const handleDownload = async () => {
        toast.success("Generating Export...");
        const res = await ProviderApi.exportProviderClinics(props.nodeId, "xlsx");
        downloadFile(res, "xlsx");
    }

    return (
        <ProviderBase
            match={props.match}
            pageTitle={pageTitle}
            providerId={props.providerId}
            nodeId={props.nodeId}
            // collapsible={props.canViewNotifications}
            searchConfig={{ placeholder: "Search Clinics"}}
        >
            <h2>{pageTitle}</h2>
            <ProviderTileGrid
                isAdmin={isAdmin}
                nodeId={props.nodeId}
                providerId={props.providerId}
                tiles={TILES}
            />
            <div className="flex spaced-content">
                <div className="flex-1">
                    <CorporateGroupFilterDropdown
                        clinics={props.clinics}
                        name="corporateGroupId"
                        onChange={({ value }) => setCorporateGroup(value)}
                        value={corporateGroup}
                    />
                </div>
                <div className="flex-none padding-top-xs">
                    <ResultsPerPageDropdown
                        onChange={({ value }) => setResultsPerPage(value)}
                        value={resultsPerPage}
                    />
                </div>
                {props.canViewProviderInfo && (
                    <div className="flex-none">
                        <div>
                            <Button
                                iconOnly
                                onClick={handleDownload}
                            >
                                <i className="fa fa-download"/>
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <ProviderClinicTable
                rawData={filteredClinics}
                columns={props.columns}
                // onRowClick={handleRowClicked}
                // onRedemptionsIconClicked={handleRedemptionsIconClicked}
                canViewCouponLibrary={props.canViewCouponLibrary}
                resultsPerPage={resultsPerPage}
                showAllColumns
                providerId={props.providerId}
                nodeId={props.nodeId}
                hasGames={props.nodeOverview?.hasGames}
                isAdmin={isAdmin}
            />
        </ProviderBase>
    );
}

NHODashboard.propTypes = {
    userRole: PropTypes.oneOf([UserRoles.PROVIDER_ADMIN, UserRoles.PROVIDER_MANAGER, UserRoles.PROVIDER_RD, UserRoles.PROVIDER_DM, UserRoles.PROVIDER_USER]),
    providerId: PropTypes.number,
    nodeId: PropTypes.number
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const provider = get(state.entities.providers, ownProps.providerId, null);

        //Permissions
        const canViewNotifications = userHasPermission(PermissionTypes.VIEW, UserPermissions.NOTIFICATIONS, userProfile);
        const canViewProviderInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_INFO, userProfile);

        return {
            providerName: provider?.name,
            node: state.entities.nodes?.[ownProps.nodeId],
            clinics: state.entities.nodeClinics?.[ownProps.nodeId],
            columns: state.entities.nodeClinicColumns?.[ownProps.nodeId],

            // Permissions
            canViewNotifications,
            canViewProviderInfo,
        };
    },

    (dispatch) => ({
        getProviderDashboard: () => dispatch(ProviderActions.getProviderDashboard()),
        getClinicsForNode: (nodeId) => dispatch(ProviderActions.getNodeClinics(nodeId))
    }),
);

export default compose(
    withRouter,
    connector
)(NHODashboard);
