import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./PuppyPoolPartyCard.scss";
import find from "lodash/find";
import includes from "lodash/includes";
import { getLast2Snapshots } from "actions/ProviderActions";
import Image from "components/common/Image";
import Loader from "components/common/Loader";
import { PoolPartyLegalWording } from "components/games/elements/LegalWording";
import * as ProviderLinks from "utils/ProviderLinks";
import PuppyPoolPartyClinicScore from "components/games/widgets/PuppyPoolPartyClinicScore";
import { PermissionTypes, userHasPermission, userPrimaryLocationType } from "utils/permissions/rolesPermissions";
import * as LocationTypes from "constants/LocationTypes";
import * as UserPermissions from "constants/UserPermissions";
import smallImage from "./SmallImage.png";

function PuppyPoolPartyCard(props) {
    const history = useHistory();
	const params = useParams();
	const { pathname } = useLocation();
    const nodeId = Number(params.nodeId);
	const providerId = Number(params.providerId);
	const clinicId = Number(params.clinicId);
	const isAdmin = !!includes(pathname, "/admin");
    const [gameLoaded, setGameLoaded] = useState(false);
    const { game } = props;


    useEffect(() => {
        if (props.game?.greenlineGameId && props.game?.daysFromStart !== 0) {
            props.getSnapshots(props.game.greenlineGameId, props.game.ownerUserId, props.clinicId, false);
        }
    }, [props.game]);

    const handleDashboardClick = () => {
        if (props.isAdmin) {
            history.push(`/admin/clinic/${props.clinicId}/games/${game.greenlineGameId}`);
        } else if (props.isProvider) {
            if (props.canViewClinicInfo || props.canViewCorporateGroupClinic) {
                history.push(ProviderLinks.getLinkLocation("clinic-games", {
                    providerId,
                    nodeId,
                    isAdmin,
                    clinicId
                }, `/${props.gameId}`));
            } else history.push(`/provider/location/${nodeId}/clinic/${props.clinicId}/games/${game.greenlineGameId}`);
        } else {
            history.push(`/games/${game.greenlineGameId}`);
        }
    }

    const gameData = useMemo(() => {
        if (props.game?.daysFromStart === 0) {
            // Return kickoff
            return {
                totalDoses: 0,
                newDoses: 0,
                bonus: 0,
                firstDay: true,
            }
        }
        if (props.game?.data.Clinics && props.clinicId && !!props.gameSnapshotComparison) {
            if (!props.gameSnapshotComparison.length) {
                // Unlikely, but just in case
                return {
                    totalDoses: 0,
                    newDoses: 0,
                    bonus: 0,
                    firstDay: false,
                }
            }

            let today;
            let yesterday;
            if(props.gameSnapshotComparison.length === 1) {
                // Handle when it's the first day, all 0s for Yesterday
                yesterday = props.gameSnapshotComparison[0];
                today = find(props.game.data.Clinics, clinic => clinic.ClinicId === props.clinicId);
            } else {
                yesterday = find(props.gameSnapshotComparison[0].data.Clinics, clinic => clinic.ClinicId === props.clinicId);
                today = find(props.gameSnapshotComparison[1].data.Clinics, clinic => clinic.ClinicId === props.clinicId);
            }

            const newDoses = (today?.PoolPartySummary?.CurrentPeriod?.TotalDoses) || 0 - (yesterday?.PoolPartySummary?.CurrentPeriod?.TotalDoses || 0);
            const bonus = (today?.PoolPartySummary?.CurrentPeriod?.ExtraDoses) || 0 - (yesterday?.PoolPartySummary?.CurrentPeriod?.ExtraDoses || 0);

            return {
                totalDoses: (today?.PoolPartySummary?.CurrentPeriod?.TotalDoses || 0),
                newDoses: newDoses,
                bonus: bonus,
                firstDay: false,
            };
        }
        return null;
    }, [props.game, props.clinicId, props.gameSnapshotComparison]);

    useEffect(() => {
        if (props.game?.details && (props.gameSnapshotComparison || props.game?.daysFromStart === 0) && !!gameData) {
            setGameLoaded(true);
        }
    }, [props.game, props.gameSnapshotComparison, gameData]);

    const gameAnimation = useMemo(() => {
        if (gameLoaded) {
            return (
                <PuppyPoolPartyClinicScore
                    pathToSrcFiles="/plugins/puppy_pool_party_points_gained/"
                    data={gameData}
                />
            );
        } else {
            return <Loader />;
        }
    }, [gameLoaded]);

    return (
        <div
            key={`game_${game.greenlineGameId}`}
            className={classnames(styles.root, {
                [styles.clickable]: props.dashboard,
                [styles.historic]: !game.isCurrent,
            })}
        >
            <div id={`game_${props.game.greenlineGameId}`} className={styles.gameContainer}>
                <div className={styles.banner}>
                    <Image
                        src={smallImage}
                        alt="NexGard® advertisement"
                    />
                </div>
                <div className={styles.animation}>
                     {gameAnimation}
                    {/*<img src={image} />*/}
                </div>
                <div className={styles.dates}>
                    {game.startDate} - {game.endDate}
                </div>
                <PoolPartyLegalWording />
                <div className={styles.viewLeaderboardLink} onClick={handleDashboardClick}>
                    View the Leaderboard <i className={"fa-solid fa-chevron-right"} />
                </div>
            </div>
        </div>
    );
}

PuppyPoolPartyCard.propTypes = {
    clinicId: PropTypes.number,
    game: PropTypes.object,
    dashboard: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const isAdmin = userPrimaryLocationType(userProfile, [LocationTypes.NULL]);
        const isProvider = userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canEditClinicBiGamesDashboard = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            isAdmin,
            isProvider,
            canViewClinicBIGamesDashboard,
            canEditClinicBiGamesDashboard,
            gameSnapshotComparison: state.entities.gameSnapshotComparison[ownProps.game?.greenlineGameId],
        }
    },
    (dispatch) => ({
        getSnapshots: (gameId, userId, clinicId, isDemo) => dispatch(getLast2Snapshots(gameId, userId, clinicId, isDemo)),
    })
)(PuppyPoolPartyCard);
