import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./DvmCommissionTable.scss";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import SortableDataTable from "components/common/SortableDataTable";
import * as ClinicActions from "actions/ClinicActions";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import DvmHistoryForm from "components/wellness/widgets/DvmHistoryForm";
import DvmCommissionForm from "components/wellness/widgets/DvmCommissionForm";

function getFullName(dvmCommission) {
    if (!dvmCommission) {
        return "";
    }
    return `${dvmCommission.firstName} ${dvmCommission.lastName}`;
}

function DvmCommissionTable(props) {
    const [showAddDvmCommission, setShowAddDvmCommission] = useState(false);
    const [selectedDvmId, setSelectedDvmId] = useState(null);
    const [showHistory, setShowHistory] = useState(false);
    const [showCommissions, setShowCommissions] = useState(false);
    const selectedDvm = (props.commissions && selectedDvmId) ? props.commissions[selectedDvmId] : {};

    const COLUMNS = [{
        name: "Doctor",
        selector: "firstName",
        key: "firstName",
        sortable: true,
        // searchable: true,
        searchValue: row => `${row.firstName} ${row.lastName}`,
        format: row => <div>{row.firstName} {row.lastName}</div>,
    }, {
        name: "PIMS ID",
        selector: "pimsUserRemoteDisplayId",
        key: "pimsUserRemoteDisplayId",
        sortable: true,
        format: row => <div className="text-center">{!!row.pimsUserRemoteDisplayId ? row.pimsUserRemoteDisplayId : "-"}</div>
    }, {
        name: "Status",
        selector: "isActive",
        key: "isActive",
        sortable: true,
        format: row => <div className="text-center">{row.isActive ? "Active" : "Inactive"}</div>,
    }, {
        name: "Products Commission %",
        selector: "productsCommissionPercentage",
        key: "productsCommissionPercentage",
        sortable: true,
        format: row => <div className="text-center">{!!row.productsCommissionPercentage ? `${row.productsCommissionPercentage}%` : "-"}</div>
    }, {
        name: "Services Commission %",
        selector: "servicesCommissionPercentage",
        key: "servicesCommissionPercentage",
        sortable: true,
        format: row => <div className="text-center">{!!row.servicesCommissionPercentage ? `${row.servicesCommissionPercentage}%` : "-"}</div>
    }, {
        name: "Employee ID",
        selector: "providerDvmIdentifier",
        key: "providerDvmIdentifier",
        sortable: true,
        // searchable: true,
        format: row => <div className="text-center">{!!row.providerDvmIdentifier ? row.providerDvmIdentifier : "-"}</div>
    }, {
        name: "Initials",
        selector: "mappingInitials",
        key: "mappingInitials",
        sortable: true,
        // searchable: true,
        format: row => <div className="text-center">{!!row.mappingInitials ? row.mappingInitials : "-"}</div>
    }, {
        name: "",
        selector: "id",
        key: "buttons",
        format: (row) => (
            <div className="text-right">
                <Button
                    iconOnly
                    small
                    onClick={(e) => handleHistoryClick(e, row)}
                    disabled={!props.canViewClinicWellnessDvms}
                >
                    <i className="fas fa-history" />
                </Button>
            </div>
        )
    }];

    useEffect(() => {
        if (props.canViewClinicWellnessDvms) {
            if(props.canViewClinicManagement) {
                props.getPimsUsers(props.clinicId);
            }
            props.getClinicDvmCommissions(props.clinicId);
        }
    }, [props.clinicId, props.canViewClinicWellnessDvms, props.canViewClinicManagement]);

    const handleRowClicked = (row) => {
        setSelectedDvmId(row.clinicDvmId);
        setShowCommissions(true);
    }

    const handleHistoryClick = (e, row) => {
        if(props.canViewClinicWellnessDvms) {
            e.stopPropagation();
            e.preventDefault();

            props.getClinicDvmCommissionHistory(row.clinicDvmId);

            setSelectedDvmId(row.clinicDvmId);
            setShowHistory(true);
        }
    }

    const handleCancel = () => {
        setSelectedDvmId(null);
        setShowHistory(false);
        setShowCommissions(false);
        setShowAddDvmCommission(false);
    }

    const handleDelete = () => {
        if(props.canDeleteClinicWellnessDvms) {
            props.deleteClinicDvm(selectedDvmId);
            handleCancel();
        }
    }

    if(!props.canViewClinicWellnessDvms) {
        return <AccessDenied/>;
    }

    return (
        <div className={styles.root}>
            {props.canEditClinicWellnessDvms &&
                <>
                    <div>
                        <span style={{ marginLeft: "5%" }}>
                            <Button icon onClick={() => setShowAddDvmCommission(true)}>
                                <i className="fas fa-plus" /> Add New
                            </Button>
                        </span>
                    </div>
                    <div
                        className="margin-top-sm margin-bottom-sm"
                        style={{ borderTop: "solid 1px #ddd", width: "100%" }}
                    />
                </>
            }
            <SortableDataTable
                green
                striped
                columns={COLUMNS}
                rawData={props.commissions}
                onClick={ (data) => props.canEditClinicWellnessDvms ? handleRowClicked(data) : {}}
                // allowSearch
                // hideSearchBar
            />

            <Modal
                show={showCommissions}
                onClose={handleCancel}
                modalTitle={props.canEditClinicWellnessDvms ? "Edit DVM" : "Commission"}
                modalSubTitle={props.canEditClinicWellnessDvms ? `Please review and modify commission rates for ${getFullName(selectedDvm)}.` : `Commission rates for ${getFullName(selectedDvm)}.`}
                small
            >
                {!props.canEditClinicWellnessDvms ? (
                    <AccessDenied/>
                ) : (
                    <DvmCommissionForm
                        selectedDvmId={selectedDvmId}
                        onClose={handleCancel}
                        onDelete={handleDelete}
                    />
                )}
            </Modal>

            <Modal
                show={showHistory}
                onClose={handleCancel}
                modalTitle={props.canEditClinicWellnessDvms ? "Edit Commission History" : "Commission History"}
                modalSubTitle={props.canEditClinicWellnessDvms ? `Please modify the DVM Commission history for ${getFullName(selectedDvm)}.` : `DVM commission history for ${getFullName(selectedDvm)}.`}
                medium
            >
                {props.canViewClinicWellnessDvms ? (
                    <DvmHistoryForm
                        clinicDvmId={selectedDvmId}
                        onClose={handleCancel}
                    />
                ) : (
                    <AccessDenied/>
                )}
            </Modal>

            <Modal
                show={showAddDvmCommission}
                onClose={() => setShowAddDvmCommission(false)}
                modalTitle="ADD COMMISSION"
                small
            >
                {!props.canCreateClinicWellnessDvms ? (
                    <AccessDenied/>
                ) : (
                    <DvmCommissionForm
                        creating
                        clinicId={props.clinicId}
                        onClose={handleCancel}
                    />
                )}
            </Modal>
        </div>
    );
}


DvmCommissionTable.propTypes = {
    clinicId: PropTypes.number.isRequired,
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canEditClinicWellnessDvms = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canCreateClinicWellnessDvms = userHasPermission(PermissionTypes.CREATE, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canDeleteClinicWellnessDvms = userHasPermission(PermissionTypes.DELETE, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicWellnessDvms,
            canEditClinicWellnessDvms,
            canCreateClinicWellnessDvms,
            canDeleteClinicWellnessDvms,
            canViewClinicManagement,
            commissions: state.entities.wellnessCommissions,
        };
    },
    (dispatch) => ({
        getPimsUsers: (clinicId) => dispatch(ClinicActions.loadClinicPIMSUsers(clinicId)),
        getClinicDvmCommissions: (clinicId) => dispatch(ClinicActions.getClinicDvmCommissions(clinicId)),
        deleteClinicDvm: (dvmId) => dispatch(ClinicActions.deleteClinicDvm(dvmId)),

        // History id comes from the DVM commission data (clinicDvmHistoryId)
        getClinicDvmCommissionHistory: (dvmId) => dispatch(ClinicActions.getClinicDvmCommissionHistory(dvmId)),
    })
)(DvmCommissionTable);
