export const COMPLETE = "Complete";
export const ERROR = "Error";

export const STATUSES = [COMPLETE, ERROR];
export const NAMES = {
    [COMPLETE]: "Complete",
    [ERROR]: "Error"
};

export const ICONS = {
    [COMPLETE]: "fa fa-check-circle text-success",
    [ERROR]: "fa fa-exclamation-triangle text-danger",
}
