import React from "react";
import {connect} from "react-redux";
import * as styles from "./CumulativeOfferTrackingPage.scss"
import ClinicBase from "components/layout/ClinicBase";
import PriceWatchIntro from "components/reports/widgets/PriceWatchIntro";
import PriceWatchDashboard from "components/reports/widgets/PriceWatchDashboard";
import VetCheckStripe from "components/vetcheck/widgets/VetCheckStripe";
import {getClinicInfo} from "utils/ClinicData";
import {clinicSubscriptions, SubscriptionStatuses} from "utils/SubscriptionStatus";
import {PRICE_WATCH} from "constants/ProviderIds";

function PriceWatch(props) {
    return (
        <ClinicBase
            pageTitle="Price Watch"
            clinicId={props.clinicId}
        >
            <div className={styles.root}>
                {(props.hasPriceWatch && props.hasSubscription) ? (
                    <PriceWatchDashboard
                        clinicId={props.clinicId}
                    />
                    // <PriceWatchTable
                    //     clinicId={props.clinicId}
                    // />
                ) : (!props.hasPriceWatch && !props.hasSubscription && props.hasExpiredSubscription) ? (
                    <div className='text-center full-height flex flex-centered text-lg'>
                        {/*TODO: Change this to be more useful - Allow them to change their payment here or start a new subscription if needed*/}
                        Your  clinic's subscription to Price Watch has expired. <br/>To regain access to Price Watch, please update your default payment method in Payment Settings.
                    </div>
                ) : (!props.hasPriceWatch && props.hasSubscription) ? (
                    <div className='text-center full-height flex flex-centered text-lg'>
                        Your clinic has an active Price Watch subscription but we're having trouble accessing it. <br/>Please contact Greenline for help resolving this issue.
                    </div>
                ) : (
                    <VetCheckStripe>
                        <PriceWatchIntro
                            clinicId={props.clinicId}
                            isAdmin={props.isAdmin}
                            isProvider={props.isProvider}
                        />
                    </VetCheckStripe>
                )}
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const isAdmin = !!ownProps.location.pathname.includes("/admin");
        const isProvider = !!ownProps.location.pathname.includes("/provider");
        // Permissions
        // TODO: fix permissions
        const hasActiveSubscription = !!clinicSubscriptions(state, clinicId, PRICE_WATCH, SubscriptionStatuses.ACTIVE)?.length;
        const hasPaidSubscription = !!clinicSubscriptions(state, clinicId, PRICE_WATCH, SubscriptionStatuses.PAID)?.length;
        const hasSubscription = hasActiveSubscription || hasPaidSubscription;
        const hasExpiredSubscription = !!clinicSubscriptions(state, clinicId, PRICE_WATCH, SubscriptionStatuses.EXPIRED)?.length;
        return {
            clinicId,
            isAdmin,
            isProvider,
            // Permissions
            // TODO: Uncomment this once the clinic permission is ready
            // hasPriceWatch: ClinicData.clinicHasPriceWatch(state, clinicId),
            hasPriceWatch: (hasPaidSubscription || hasActiveSubscription),
            hasSubscription,
            hasExpiredSubscription
        }
    }
)(PriceWatch);
