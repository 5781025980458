import React from "react";
import ClinicBase from "components/layout/ClinicBase";
import ClinicUsersTable from "components/clinic/widgets/ClinicUsersTable";
import * as SearchTypes from "constants/SearchTypes";
import {connect} from "react-redux";
import * as styles from "components/clinic/pages/ClinicUsers.scss";
import {getClinicInfo} from "utils/ClinicData";
import {CLINIC_TAGS} from "constants/AdminControls";


function ClinicUsers(props) {
    return (
        <ClinicBase
            clinicId={props.clinicId}
            searchConfig={SearchTypes.USER_SEARCH}
            adminControls={CLINIC_TAGS}
            docTitle="Clinic Users"
        >
            <div className={styles.root}>
                <ClinicUsersTable
                    clinicId={props.clinicId}
                />
            </div>
        </ClinicBase>
    )
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        return {
            clinicId,
        }
    }
)(ClinicUsers);
