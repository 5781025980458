import React, {useState, useMemo} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import ProductsByPriceTable from "components/reports/elements/ProductsByPriceTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";

function PriceWatchTable(props) {
    const [search, setSearch] = useState("");

    if(!props.hasPriceWatch) {
        return <SpinnerTakeover show/>;
    }

    const filteredFavorites = useMemo(() => {
        if(!!search?.length) {
            return filter(props.favoriteBrands, b => {
                const searchTokens = search.toLowerCase().split(" ");
                let keep = true
                for (let i = 0; i < searchTokens.length; i++) {
                    const token = searchTokens[i];
                    if (!b.name || !b.name.toString().toLowerCase().includes(token)) {
                        keep = false;
                    }
                }
                return keep;
            });
        } else return props.favoriteBrands;
    }, [props.favoriteBrands, search]);

    const filteredOthers = useMemo(() => {
        if(!!search?.length) {
            return filter(props.otherBrands, b => {
                const searchTokens = search.toLowerCase().split(" ");
                let keep = true
                for (let i = 0; i < searchTokens.length; i++) {
                    const token = searchTokens[i];
                    if (!b.name || !b.name.toString().toLowerCase().includes(token)) {
                        keep = false;
                    }
                }
                return keep;
            });
        } else return props.otherBrands;
    }, [props.otherBrands, search]);

    return (
        <div>
            <div className="flex spaced-content">
                <div className="flex-2 flex align-center">
                    <i className="fa fa-search margin-right-x-sm"/>
                    <TextBox
                        placeholder="Search Products..."
                        inputType="search"
                        onChange={({value}) => setSearch(value)}
                        name="search"
                        value={search}
                    />
                </div>
                <div className="flex-3"/>
            </div>
            {map(filteredFavorites, brand => (
                <ProductsByPriceTable
                    key={brand.productFamilyId}
                    brandId={brand.productFamilyId}
                    isFavorite
                />
            ))}
            {map(filteredOthers, brand => (
                <ProductsByPriceTable
                    key={brand.productFamilyId}
                    brandId={brand.productFamilyId}
                />
            ))}
        </div>
    );
}

PriceWatchTable.propTypes = {
    clinicId: PropTypes.number.isRequired,
};

export default connect(
    (state) => {
        const favoriteBrandIds = state.entities.favoriteBrands;
        return {
            favoriteBrands: orderBy(filter(state.entities.comparablePrices, b => {return !!includes(favoriteBrandIds, b.productFamilyId)}), "name"),
            otherBrands: orderBy(filter(state.entities.comparablePrices, b => {return !includes(favoriteBrandIds, b.productFamilyId)}), "name"),
            comparablePrices: state.entities.comparablePrices,
            // Permissions
            // TODO: uncomment once the clinic permission is ready
            // hasPriceWatch: ClinicData.clinicHasPriceWatch(state, ownProps.clinicId),
            hasPriceWatch: true
        }
    }
)(PriceWatchTable);
