import React from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import PropTypes from "prop-types";
import styles from "./RunningTotals.scss";
import { formatUSD } from "utils/numeric";
import CouponStatsTile from "components/coupons/elements/CouponStatsTile";

const DEFAULT_SUMMARY = {
    clinicId: null,
    clientSavings: 0,
    reimbursementValue: 0,
    redemptionsApproved: 0,
    hoursSaved: 0,
    freeDoses: 0,
}

class RunningTotals extends React.Component {

    render() {
        const isEmpty = !this.props.approvalSummary;
        const forCurrentClinic = (this.props.approvalSummary?.clinicId === this.props.clinicId);
        const approvalSummary = (!isEmpty && forCurrentClinic) ? {
            ...this.props.approvalSummary,
        } : DEFAULT_SUMMARY;

        return (
            <div className={classnames(styles.root, {
                [styles.empty]: isEmpty || !forCurrentClinic,
            })}>
                <h4>Approval Summary</h4>
                <div className={styles.gridWrapper}>
                    <div className={styles.grid}>
                        <CouponStatsTile label="Clients Saved" largeValue={formatUSD(approvalSummary.clientSavings)} />
                        <CouponStatsTile label="Reimbursement Value" largeValue={formatUSD(approvalSummary.reimbursementValue)} />
                        <CouponStatsTile label={"Coupons Submitted"} largeValue={`${approvalSummary.redemptionsApproved}`} />
                        <CouponStatsTile label={"Free Doses"} largeValue={`${approvalSummary.freeDoses}`} />
                        <CouponStatsTile label={"Hours Saved"} largeValue={`${approvalSummary.hoursSaved}`} />
                    </div>
                </div>
            </div>
        );
    }
}

RunningTotals.propTypes = {
    clinicId: PropTypes.number.isRequired,
};

export default connect(
    (state) => ({
        approvalSummary: state.entities.couponApprovalSummary,
    }),
    null
)(RunningTotals);
