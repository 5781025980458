import React, {useCallback, useState} from "react";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import debounce from "lodash/debounce";
import includes from "lodash/includes";
import keys from "lodash/keys";
import merge from "lodash/merge";
import orderBy from "lodash/orderBy";
import * as ProviderActions from "actions/ProviderActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import DataTable from "components/common/datatable/DataTable";
import Modal from "components/common/Modal";
import TextBox from "components/common/TextBox";
import Tooltip from "components/common/Tooltip";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as InviteTypes from "constants/InviteTypes";
import * as UserPermissions from "constants/UserPermissions";

function ManageInvitesTable(props) {
    const location = useLocation();
    const isDemo = includes(location?.pathname, "/demo") && props.canViewClinicBIGamesDashboard;
    const [showSendInviteModal, setShowSendInviteModal] = useState(false);
    const [selectedClinic, setSelectedClinic] = useState(null);
    const [formData, setFormData] = useState({});
    const thisYear = new moment().format("YYYY");
    const nextYear = Number(thisYear) + 1;
    const lateInYear = Number(new moment().format("MM")) > 9;
    const combinedData = {
        ...props.gameInvites,
    };

    merge(combinedData, formData);

    const handleCancel = () => {
        setShowSendInviteModal(false);
        setSelectedClinic(null);
    }

    const getExpirationYear = (row) => {
        const expirationYear = moment(row.expirationDate).format("YYYY");
        return Number(expirationYear);
    }

    const handleSendInvite = () => {
        let inviteYear = thisYear;
        if((combinedData[selectedClinic.clinicId]?.optInState === InviteTypes.ACCEPTED && lateInYear) || (getExpirationYear(selectedClinic) === nextYear)) {
            //If the Clinic has accepted the invitation for this year and it is late in the year send next years invite.
            inviteYear = nextYear;
        }

        props.sendInvite({
            ...selectedClinic,
            inviteYear,
        });
        handleCancel();
    }

    const handleOpenSendInviteModal = (row) => {
        setShowSendInviteModal(true);
        setSelectedClinic(row);
    }

    const save = useCallback(debounce((data) => {
        props.updateClinicAlias(data, props.nodeId);
    }, 500), []);

    const handleChange = ({name, value}, row) => {
        if (props.canEditBiGamesClinicInvites) {
            const tempData = {
                ...formData,
                [row.clinicId]: {
                    ...combinedData[row.clinicId],
                    ...formData[row.clinicId],
                    [name]: value,
                }
            };
            setFormData(tempData);
            save(tempData[row.clinicId]);
        }
    }

    const getText = (row) => {
        switch (row.optInState) {
            case InviteTypes.DECLINED:
            case InviteTypes.PENDING:
                return "Resend Invite";
            case InviteTypes.ACCEPTED:
                if(lateInYear && (getExpirationYear(row) !== nextYear)) {
                    return `Send Invite for ${nextYear}`;
                } else return null;
            default:
                return `Send Invite for ${thisYear}`;
        }
    }

    const getClinicAlias = (row) => {
        return formData[row.clinicId] ? formData[row.clinicId].clinicAlias : row.clinicAlias;
    }

    const COLUMNS = [{
        name: "Clinic",
        selector: "clinicId",
        key: "clinicId",
        searchable: true,
        searchValue: row => `${row.clinicName} (${row.clinicId}) ${row.city}, ${row.state} ${row.postalCode}`,
        format: row => (
            <div className="flex-column">
                <div>{row.clinicName} <span className="text-sm">({row.clinicId})</span></div>
                <div className="margin-left-sm">{row.city}, {row.state} {row.postalCode}</div>
            </div>
        ),
    }, {
        name: "Clinic Alias",
        selector: "clinicAlias",
        key: "clinicAlias",
        info: "Alias will be used for challenge match games",
        searchable: true,
        format: row => (
            <div className="text-center">
                <TextBox
                    name="clinicAlias"
                    value={getClinicAlias(row)}
                    onChange={(data) => handleChange(data, row)}
                    disabled={isDemo || !props.canEditBiGamesClinicInvites || row.optInState !== InviteTypes.ACCEPTED}
                />
            </div>
        ),
    }, {
        name: "Status",
        selector: "optInState",
        key: "optInState",
        format: row => {
            const firstOptInDate = !!row?.firstOptInDate ? moment(row.firstOptInDate).format("MM/DD/YYYY") : "Unknown";
            return (
                <div className={classnames({
                    "text-success": row.optInState === InviteTypes.ACCEPTED,
                    "text-warn": row.optInState === InviteTypes.PENDING,
                    "text-danger": row.optInState === InviteTypes.DECLINED
                })}
                    title={`First opt-in date: ${firstOptInDate}`}
                >
                    <Tooltip position="top" tip={`First opt-in date: ${firstOptInDate}`}>
                        <div>{row.optInState}</div>
                    </Tooltip>
                </div>
            );
        },
    }, {
        name: "Expires",
        selector: "expirationDate",
        key: "expirationDate",
        searchable: true,
    }, {
        name: "",
        selector: "actions",
        key: "actions",
        format: row => (
            <div className="flex flex-wrap spaced-content">
                {((row.optInState !== InviteTypes.ACCEPTED) || (lateInYear && (getExpirationYear(row) !== nextYear))) && (
                    <Button
                        type="primary"
                        icon
                        onClick={() => handleOpenSendInviteModal(row)}
                        disabled={!props.canEditBiGamesClinicInvites || isDemo}
                    >
                        <i className="fa fa-paper-plane margin-right-x-sm"/> {getText(row)}
                    </Button>
                )}
            </div>
        )
    }];

    if (!(props.canViewBiGamesClinicInvites || props.canViewBiGamesDrillDown)) {
        return (
            <div className="height-100-perc">
                <AccessDenied/>
            </div>
        );
    }

    return (
        <div>
            {keys(combinedData).length ? (
                <DataTable
                    columns={COLUMNS}
                    data={orderBy(combinedData, "clinicName")}
                    pagination={false}
                    striped
                    green
                    allowSearch
                    search={props.search}
                    hideSearchBar
                />

            ) : null}

            {!!selectedClinic && (
                <Modal
                    modalTitle="Send Clinic Invite"
                    // modalSubTitle="Please Verify/Enter Email Address"
                    show={showSendInviteModal}
                    onClose={handleCancel}
                    mediumSmall
                >
                    {props.canEditBiGamesClinicInvites ? (
                        <div>
                            {/*TODO: What is with the modalTitle above? When/Why do they need to verify/enter the email address?*/}
                            <div className="flex spaced-content margin-bottom-md">
                                <div>Clinic:</div>
                                <div className="flex-1 flex-column">
                                    <div>{selectedClinic.clinicName} <span className="text-sm">({selectedClinic.clinicId})</span></div>
                                    <div>{selectedClinic.city}, {selectedClinic.state} {selectedClinic.zipCode}</div>
                                </div>
                            </div>
                            <div className="text-sm">
                                *NOTE: The clinic will receive a notification on their dashboard.
                            </div>
                            <hr/>
                            <div className="flex spaced-content justify-flex-end">
                                <Button
                                    type="gray"
                                    onClick={handleCancel}
                                    disabled={isDemo}
                                >
                                    Close
                                </Button>
                                <Button
                                    disabled={isDemo || !props.canEditBiGamesClinicInvites}
                                    onClick={handleSendInvite}
                                >
                                    Send Invite to Participate
                                </Button>
                            </div>
                        </div>
                    ) : (
                        <AccessDenied/>
                    )}
                </Modal>
            )}
        </div>
    );
}

ManageInvitesTable.propTypes = {
    nodeId: PropTypes.number,
    demoGameInvites: PropTypes.array,
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;

        // Permissions
        const canViewBiGamesClinicInvites = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_CLINIC_INVITES, userProfile);
        const canViewBiGamesDrillDown = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_DRILLDOWN, userProfile);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canEditBiGamesClinicInvites = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROVIDER_BI_GAMES_CLINIC_INVITES, userProfile);
        return {
            gameInvites: state.entities.gameInvites.results,
            search: state.entities.genericSearch,
            // Permissions
            canViewBiGamesClinicInvites,
            canViewBiGamesDrillDown,
            canViewClinicBIGamesDashboard,
            canEditBiGamesClinicInvites,
        }
    },
    (dispatch) => ({
        sendInvite: (inviteData) => dispatch(ProviderActions.sendInvite(inviteData)),
        updateClinicAlias: (inviteData, nodeId) => dispatch(ProviderActions.updateClinicAlias(inviteData, nodeId)),
    })
)

export default compose(
    withRouter,
    connector
)(ManageInvitesTable);
