import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import produce from "immer";
import cloneDeep from "lodash/cloneDeep";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import merge from "lodash/merge";
import orderBy from "lodash/orderBy";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import PropTypes from "prop-types";
import Dropdown from "components/common/Dropdown";
import Button from "components/common/Button";
import * as AdminApi from "api/AdminApi";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import ToggleSwitch from "components/common/ToggleSwitch";
import * as CssStyleIds from "constants/CssStyleIds";
import { handleErrorResponse } from "utils/request";

function RolePermissions(props) {
    const [providerId, setProviderId] = useState(null);
    const [locationId, setLocationId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [role, setRole] = useState(null);
    const [formData, setFormData] = useState({});

    const roleId = `${props.roleId}${providerId ? `:${providerId}${locationId ? `:${locationId}` : ""}` : ""}`;

    const combinedData = cloneDeep(role?.permissions);
    merge(combinedData, formData);

    const PROVIDER_OPTIONS = orderBy(map(props.providers, provider => {
        return  {
            name: provider.name,
            value: provider.id,
        }
    }), "name");
    const LOCATION_OPTIONS = !!providerId ? orderBy(map(props.providers[providerId].orgLevels, l => {
        return  {
            name: l,
            value: l,
        }
    }), "name") : [];

    useEffect(() => {
        if(!props.showProviders) {
            loadPermissions();
        }
    }, [props.roleId, props.displayOrder, props.showProviders]);


    if(!props.roleId){
        return null;
    }

    if (!props.canViewRoleManagement) {
        return <AccessDenied/>;
    }

    const loadPermissions = () => {
        setLoading(true);
        setFormData({});

        AdminApi.getRolePermissions(roleId)
            .then((res) => {
                setRole({
                    ...res.body,
                    permissions: keyBy(
                        map(res.body.permissions, p => {
                            return {
                                ...p,
                                grants: keyBy(p.grants, "id")
                            }
                        })
                    , "permissionId"),
                });
                setLoading(false);
            })
            .catch((error) => {
                handleErrorResponse("loading role permissions", error);
                setRole(null)
                setLoading(false);
            });
    }

    const handleLoadPermissions = () => {
        if (Object.keys(formData)?.length) {
            if (confirm(`You have Unsaved Changes. You will lose your changes if you continue! Continue anyway?`)) {
                loadPermissions();
            }
        } else {
            loadPermissions();
        }
    }

    const handleChangeProvider = ({value}) => {
        setProviderId(value);
        setLocationId(null);
    }

    const handleSave = () => {
        setLoading(true);

        const newData = {
            ...role,
            permissions: map(combinedData, cd => {
                return {
                    ...cd,
                    grants: map(cd.grants)
                }
            })
        }

        AdminApi.updateRolePermissions(newData)
            .then((res) => {
                setLoading(false);
                setFormData({});
                props.handleCancel();
            })
            .catch((error) => {
                handleErrorResponse("updating roles", error);
                setLoading(false);
            });
    }

    const handleChange = (permissionId, grantId, value) => {
        const newFormData  = produce(combinedData, newState => {
            newState[permissionId].grants[grantId].selected = value;
        });

        setFormData(newFormData);
    }

    const searchChanged = (roleId !== role?.id);

    return (
        <div>
            {props.showProviders && (
                <div className="flex spaced-content border-bottom align-bottom">
                    <div className="flex-1">
                        <Dropdown
                            options={PROVIDER_OPTIONS}
                            name="provider"
                            value={providerId}
                            label="Provider"
                            onChange={handleChangeProvider}
                            placeholder="All Providers"
                        />
                    </div>
                    <div className="flex-1">
                        <Dropdown
                            options={LOCATION_OPTIONS}
                            name="location"
                            value={locationId}
                            label="Location"
                            onChange={({value}) => setLocationId(value)}
                            placeholder="All Locations"
                            disabled={!providerId}
                        />
                    </div>
                    <div className="flex-1">
                        <Button
                            onClick={handleLoadPermissions}
                            disabled={!searchChanged}
                        >
                            Go
                        </Button>
                    </div>
                </div>
            )}
            <div className="overflow-x-auto">
                {combinedData && (
                    <table className="table full-width less-padding">
                        {map(groupBy(combinedData, "section"), (p, index) => (
                            <Fragment key={index}>
                                <thead>
                                    <tr>
                                        <th colSpan={10}>{index === "null" ? "" : index}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {map(p, (permission) => (
                                        <tr key={permission.permissionId}>
                                            <td className="no-wrap">
                                                {permission.name || permission.permissionId}
                                            </td>
                                            {map(permission.grants, g => (
                                                <td key={g.id}>
                                                    <ToggleSwitch
                                                        id={g.id}
                                                        updateState={(id, value) => handleChange(permission.permissionId, id, value)}
                                                        force={g.selected}
                                                        disabled={!props.canEditRoleManagement}
                                                        labels={["", `${g.name}`]}
                                                        tooltip={g.tooltip}
                                                    />
                                                </td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Fragment>
                        ))}
                    </table>
                )}
            </div>
            {role?.permissions && (
                <div className="flex spaced-content justify-flex-end margin-top-md border-top">
                    <Button
                        type={CssStyleIds.GRAY}
                        onClick={props.handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSave}
                    >
                        Submit Changes
                    </Button>
                </div>
            )}
            <SpinnerTakeover show={loading}/>
        </div>
    );
}

RolePermissions.propTypes = {
    roleId: PropTypes.string.isRequired,
    displayOrder: PropTypes.number.isRequired,
    handleCancel : PropTypes.func,
    showProviders: PropTypes.bool
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewRoleManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.ROLE_MANAGEMENT, userProfile);
        const canEditRoleManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.ROLE_MANAGEMENT, userProfile);
        return {
            canViewRoleManagement,
            canEditRoleManagement,
            providers: state.entities.roles.providers,
            search: state.entities.genericSearch,
        };
    }
)(RolePermissions);
