import React, {useMemo} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import * as ClinicActions from "actions/ClinicActions";
import Button from "components/common/Button";
import ProductByPriceTableRow from "components/reports/elements/ProductByPriceTableRow";
import {getCompetitorName, SELLERS} from "utils/PriceWatchUtils";

function ProductsByPriceTable(props) {
    if(!props.brand) {
        return null;
    }

    const handleSetFavorite = () => {
        if(props.isFavorite){
            props.removeFavoriteBrand(props.brandId);
        } else {
            props.addFavoriteBrand(props.brandId);
        }
    }

    const sellerHeaders = useMemo(() => {
        return map(SELLERS, s => (
            <th
                key={s}
                className="text-center"
                colSpan={props.brand.quantities?.length}
            >
                {getCompetitorName(s)}
            </th>
        ));
    }, [SELLERS, props.brand.quantities?.length]);

    const sellerQuantityHeaders = useMemo(() => {
        return map(SELLERS, s => {
            return map(props.brand.quantities, q => (
                <th
                    key={`th_${s}_${q}`}
                    className="text-center"
                >
                    {q}
                </th>
            ));
        });
    }, [SELLERS, props.brand.quantities]);

    return (
        <div className="margin-bottom-md">
            <div className="flex text-lg margin-bottom-x-sm">
                <div className="flex-1">{props.brand.productFamilyName}</div>
                <div className="flex-none margin-right-x-sm">
                    <Button
                        onClick={() => handleSetFavorite(props.brand.productFamilyId, props.isFavorite)}
                        title={props.isFavorite ? "Remove from favorites" : "Add to favorites"}
                        text
                        iconOnly
                    >
                        <i className={classnames("fa-star text-yellow", {
                                "fal": !props.isFavorite,
                                "fa": props.isFavorite
                            })}
                        />
                    </Button>
                </div>
            </div>
            <table className="table table-green full-width striped">
                <thead>
                    <tr>
                        <th/>
                        {sellerHeaders}
                    </tr>
                    <tr>
                        <th/>
                        {sellerQuantityHeaders}
                    </tr>
                </thead>
                <tbody>
                    {map(props.brand.products, (product) => (
                        <ProductByPriceTableRow
                            key={product.productId}
                            productId={product.productId}
                            brandId={props.brandId}
                        />
                    ))}
                </tbody>
            </table>
        </div>
    );
}

ProductsByPriceTable.propTypes = {
    brandId: PropTypes.number.isRequired,
    isFavorite: PropTypes.bool
};

export default connect(
    (state, ownProps) => {
        return {
            brand: state.entities.comparablePrices?.[ownProps.brandId] || {},
        }
    },
    (dispatch) => ({
        addFavoriteBrand: (brandId) => dispatch(ClinicActions.addFavoriteBrand(brandId)),
        removeFavoriteBrand: (brandId) => dispatch(ClinicActions.removeFavoriteBrand(brandId)),
    })
)(ProductsByPriceTable);
