import getApiRoot from "api/getApiRoot";
import request from "utils/request";

export function loadClinicPrograms(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/clinicprograms/${clinicId}/`)
        .send();
}

export function loadClinicProgramDashboard(clinicId) {
    return request()
        .get(`${getApiRoot()}/v1/clinicprograms/${clinicId}/dashboard`)
        .send();
}

export function loadClinicProgramOptInDetails(clinicId, programId) {
    return request()
        .get(`${getApiRoot()}/v1/clinicprograms/${clinicId}/program/${programId}/optin/details`)
        .send();
}

export function loadClinicProgramDetails(clinicId, programId) {
    return request()
        .get(`${getApiRoot()}/v1/clinicprograms/${clinicId}/program/${programId}/landing/details`)
        .send();
}

export function updateClinicProgramStatus(program) {
    return request()
        .post(`${getApiRoot()}/v1/clinicprograms/${program.clinicId}/program/${program.programId}/status`)
        .send(program);
}

export function loadClinicProgramServices(clinicId, programId) {
    return request()
        .get(`${getApiRoot()}/v1/clinicprograms/${clinicId}/program/${programId}/`)
        .send();
}

export function loadClinicProgramServiceOptInDetails(clinicId, programServiceId) {
    return request()
        .get(`${getApiRoot()}/v1/clinicprograms/${clinicId}/programservice/${programServiceId}/optin/details`)
        .send();
}

export function loadClinicProgramServiceDetails(clinicId, programServiceId) {
    return request()
        .get(`${getApiRoot()}/v1/clinicprograms/${clinicId}/programservice/${programServiceId}/landing/details`)
        .send();
}

export function updateClinicProgramServiceStatus(programService) {
    return request()
        .post(`${getApiRoot()}/v1/clinicprograms/${programService.clinicId}/programService/${programService.programServiceId}/status`)
        .send(programService);
}
