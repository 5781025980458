import React, {useState} from "react";
import PropTypes from "prop-types";
import Button from "components/common/Button";
import DataTable from "components/common/datatable/DataTable";
import moment from "moment";
import classnames from "classnames";
import * as styles from "./InvoiceTable.scss";
import OpenInvoicePDFLinkNewTab from "components/common/OpenInvoicePDFLinkNewTab";
import { formatUSD } from "utils/numeric";

export default function InvoiceTable(props) {
    const [completedActions, setCompletedActions] = useState({});

    const handleUpdate = (row, action) => {
        if(props.canEditCouponReprocessing) {
            const tempCompletedActions = {
                ...completedActions,
                [row.redemptionId]: {action}
            }
            // Commented out to allow it to switch state upon click
            // setCompletedActions(tempCompletedActions);
            props.onRedemptionUpdate({ tokenRedemptionId: row.tokenRedemptionId, action });
        }
    }

    const COLUMNS = [{
        name: `${props.list ? "Invoice #" : ""}`,
        selector: "invoiceId",
        key: "invoiceId",
        format: row => (
            <OpenInvoicePDFLinkNewTab
                className="text-primary"
                invoiceId={row.invoiceId}
                invoiceNumber={row.invoiceNumber}
            />
        )
    }, (props.list ? {
        name: "Invoice Date",
        selector: "invoiceDate",
        key: "invoiceDate",
        format: row => <div className="nowrap">{moment(row.createDate).format('MM/DD/YYYY')}</div>
    } : {
        name: "Status",
        selector: "processStateLongDescription",
        key: "processStateLongDescription",
        format: row => <div>{row.processStateLongDescription}</div>
    }), {
        name: "Client",
        selector: "client",
        key: "client",
        format: row => <div className="nowrap">{row.clientName}</div>
    }, {
        name: "Pet",
        selector: "patientName",
        key: "patientName",
        format: row => <div className="nowrap">{row.patientName}</div>
    }, {
        name: "Product",
        selector: "product",
        key: "product",
    }, {
        name: "Price",
        selector: "price",
        key: "price",
        format: row => <div className="text-center">{row.price ? formatUSD(row.price) : "-"}</div>
    }, {
        name: "Coupon",
        selector: "offerDescription",
        key: "offerDescription",
    }, {
        name: "DCN",
        selector: "dcn",
        key: "dcn",
    }, {
        name: "Created",
        selector: "createDate",
        key: "createDate",
        format: row => <div className="nowrap">{moment(row.createDate).format('MM/DD/YYYY, LTS')}</div>

    }, {
        name: "",
        selector: "actions",
        key: "actions",
        format: row => <div className="flex spaced-content align-center">
            {(props.canEditCouponReprocessing && row.canApprove) && (
                <Button
                    className={classnames(styles.button, {
                        [styles.successThumb]: (completedActions[row.redemptionId])
                    })}
                    iconOnly
                    type="success"
                    onClick={() => handleUpdate(row, "approve")}
                    small
                    disabled={completedActions[row.redemptionId]}
                >
                    <i className={classnames("fas fa-thumbs-up", {
                        ["text-mid-gray"]: (completedActions[row.redemptionId]),
                        ["text-success"]: (completedActions[row.redemptionId] && completedActions[row.redemptionId].action === "approve"),
                    })}/>
                </Button>
            )}
            {(props.canEditCouponReprocessing && row.canReject) && (
                <Button
                    className={classnames(styles.button, {
                        [styles.successThumb]: completedActions[row.redemptionId],
                    })}
                    iconOnly
                    type="danger"
                    onClick={() => handleUpdate(row, "reject")}
                    small
                    disabled={completedActions[row.redemptionId]}
                >
                    <i className={classnames("fas fa-thumbs-down", {
                        ["text-mid-gray"]: (completedActions[row.redemptionId]),
                        ["text-danger"]: (completedActions[row.redemptionId] && completedActions[row.redemptionId].action === "reject"),
                    })}/>
                </Button>
            )}
            {(props.canEditCouponReprocessing && row.canReset) && (
                <Button
                    className={classnames(styles.button, "text-sm")}
                    iconOnly
                    text
                    type="primary"
                    onClick={() => handleUpdate(row, "reset")}
                    disabled={completedActions[row.redemptionId]}
                >
                    Reset
                    {(completedActions[row.redemptionId] && completedActions[row.redemptionId].action === "reset") && (
                        <i className={classnames(styles.success, "fa fa-check text-success")}/>
                    )}
                </Button>
            )}
        </div>

    }];

    const getRowClassName = (row) => {
        switch (row.processStateShortDescription) {
            case "new":
                return styles.new;
            case "error":
            case "bad":
                return styles.bad;
            case "matched":
                return styles.matched;
            case "matched-original":
                return styles.matchedOriginal;
            case "deleted":
                return styles.deleted;
            default:
                return;
        }
    };

    return (
        <div className={styles.root}>
            <DataTable
                columns={COLUMNS}
                data={props.redemptions}
                highlightOnHover
                pagination={false}
                getRowClassName={getRowClassName}
            />
        </div>
    )
}

InvoiceTable.propTypes = {
    redemptions: PropTypes.arrayOf(Object).isRequired,
    list: PropTypes.bool,
    onRedemptionUpdate: PropTypes.func,
    canEditCouponReprocessing: PropTypes.bool,
};

InvoiceTable.defaultProps = {
    list: false,
};
