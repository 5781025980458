import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import map from "lodash/map";
import * as AdminActions from "actions/AdminActions";
import * as ClinicActions from "actions/ClinicActions";
import * as MappingActions from "actions/MappingActions";
import AccessDenied from "components/common/AccessDenied";
import AdminBase from "components/layout/AdminBase";
import Button from "components/common/Button";
import DropdownSearch from "components/common/DropdownSearch";
import ClinicProductOverrideTable from "components/admin/widgets/ClinicProductOverrideTable";
import Modal from "components/common/Modal";
import PriceOverridesForm from "components/admin/forms/PriceOverridesForm";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {PRODUCT_OVERRIDES} from "constants/AdminControls";
import * as ButtonTypes from "constants/ButtonTypes";
import * as UserPermissions from "constants/UserPermissions";
import * as SearchTypes from "constants/SearchTypes";

function AdminClinicProductOverride(props) {
    const [showModal, setShowModal] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);

    const PRODUCT_OPTIONS = map(props.products, p => {return {name: p.name, value: p.productId}})

    useEffect(() => {
        if(props.canViewProductMapping) {
            props.searchProducts({});
        }
    }, []);

    useEffect(() => {
        if(props.clinicId && !props.clinicDetails && (props.canViewClinicInfo || props.canViewCorporateGroupClinic)) {
            props.getClinicById(props.clinicId);
        }
    }, [props.clinicId]);

    const handleRowClicked = (productId) => {
        setSelectedProductId(productId);
        setShowModal(true);
    }

    const handleCancel = () => {
        setSelectedProductId(null);
        setShowModal(false);
    }

    const handleClose = () => {
        setSelectedProductId(null);
        props.hideAddForm();
    }

    const handleProductChosen = () => {
        props.hideAddForm();
        setShowModal(true);
    }

    return (
        <AdminBase
            clinicId={props.clinicId}
            pageTitle="Product Overrides"
            collapsible
            controls={PRODUCT_OVERRIDES}
            searchConfig={SearchTypes.CLINIC_PRODUCT_OVERRIDES}
        >
            <div className="full-width full-height margin-bottom-lg">
                {props.canViewPreAuditReview ? (
                    <>
                        <ClinicProductOverrideTable
                            clinicId={props.clinicId}
                            handleRowClicked={handleRowClicked}
                        />
                        <Modal
                            show={showModal}
                            modalTitle="Adjust Min/Max Pricing for PAR (Experienced Users Only!)"
                            onClose={handleCancel}
                            mediumSmall
                        >
                            {props.canEditPreAuditReview ? (
                                <PriceOverridesForm
                                    clinicName={props.clinicDetails?.name || "Unknown"}
                                    clinicId={props.clinicId}
                                    productId={selectedProductId}
                                    onCancel={handleCancel}
                                    onChangeProductId={handleRowClicked}
                                />
                            ) : (
                                <AccessDenied/>
                            )}
                        </Modal>
                        <Modal
                            show={props.addNewForm}
                            modalTitle="Choose a Product"
                            small
                            onClose={() => props.hideAddForm()}
                        >
                            {(props.canEditPreAuditReview && props.canViewProductMapping) ? (
                                <div>
                                    <DropdownSearch
                                        options={PRODUCT_OPTIONS}
                                        value={selectedProductId}
                                        name="clinicProductId"
                                        onChange={({value}) => setSelectedProductId(value)}
                                        placeholder={!PRODUCT_OPTIONS.length ? "No Products Available" : "Search Products"}
                                    />
                                    <div className="margin-top-md flex justify-flex-end full-width spaced-content">
                                        <Button
                                            type={ButtonTypes.GRAY}
                                            onClick={handleClose}
                                        >
                                            Cancel
                                        </Button>
                                        <Button
                                            onClick={handleProductChosen}
                                            disabled={!selectedProductId}
                                        >
                                            Select
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <AccessDenied/>
                            )}
                        </Modal>
                    </>
                ) : (
                    <AccessDenied/>
                )}
                <SpinnerTakeover show={props.working}/>
            </div>
        </AdminBase>
    )
}
export default connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const userProfile = state.user.userProfile;
        const canViewClinicInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_INFO, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canViewProductMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);
        const canViewPreAuditReview = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRE_AUDIT_REVIEW, userProfile);
        const canEditPreAuditReview = userHasPermission(PermissionTypes.EDIT, UserPermissions.PRE_AUDIT_REVIEW, userProfile);
        return {
            clinicId,
            clinicDetails: state.entities.clinicDetails[clinicId],
            addNewForm: state.entities.adminTools.addNewForm || false,
            products: state.entities.products,
            working: !!state.applicationState.working,
            // Permissions
            canViewClinicInfo,
            canViewCorporateGroupClinic,
            canViewProductMapping,
            canViewPreAuditReview,
            canEditPreAuditReview,
        }
    },
    (dispatch) => ({
        getClinicById: (clinicId) => dispatch(ClinicActions.getClinicById(clinicId)),
        searchProducts: (query) => dispatch(MappingActions.searchProducts(query)),
        hideAddForm: () => dispatch(AdminActions.hideAddForm()),
    }),
)(AdminClinicProductOverride);
