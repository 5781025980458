import React, {useEffect} from "react";
import { connect } from "react-redux";
import * as CouponActions from "actions/CouponActions";
import AccessDenied from "components/common/AccessDenied";
import CouponOptInOffers from "components/coupons/widgets/CouponOptInOffers";
import ProviderBase from "components/layout/ProviderBase";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import getProviderId from "utils/getProviderId";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

function ProviderCouponOptInPage(props) {

    useEffect(() => {
        if(props.canViewClinicCouponLibrary && props.providerId && props.clinicId) {
            props.getProgramOffers(props.providerId, props.clinicId);
        }
    }, [props.clinicId, props.providerId]);

    return (
        <ProviderBase
            location={props.location}
            clinicId={props.clinicId}
            searchConfig={SearchTypes.LIBRARY_OFFERS}
            collapsible
            nodeId={props.nodeId}
            providerId={props.providerId}
            docTitle="Clinic Coupon Library"
        >
            {props.canViewClinicCouponLibrary ? (
                <>
                    {props.providerId && (
                        <CouponOptInOffers
                            programId={props.providerId}
                            clinicId={props.clinicId}
                            isReadOnlyView
                        />
                    )}
                    <SpinnerTakeover show={!props.providerId} />
                </>
            ) : (
                <AccessDenied/>
            )}
        </ProviderBase>
    );
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const params = ownProps?.match?.params || {};
        const providerId = Number(params?.libraryProviderId || getProviderId(params, userProfile));
        const clinicId = Number(params?.clinicId);
        const nodeId = Number(params?.nodeId) || null;
        return {
            canViewClinicCouponLibrary,
            clinicId,
            providerId,
            nodeId
        }
    },
    (dispatch) => ({
        getProgramOffers: (programId, clinicId) => dispatch(CouponActions.getProgramOffers(programId, clinicId)),
    })
)(ProviderCouponOptInPage);
