import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { AS_OBJECT } from "constants/TimeFormatOptions";

export default function CountdownTimer({ useWeeks, endDate }) {
    const start = new moment()
    const end = new moment(endDate, "M/D/YY");
    const duration = moment.duration(end.diff(start))

    const months = duration.months();
    const weeks = Math.floor(duration.days()/7);
    const days = duration.days();
    const days4Weeks = days%7;
    const hours = duration.hours();
    const minutes = duration.minutes();
    const message = [];

    if(!!months) {
        message.push(<span key={`months`}><strong>{months}</strong> month{months !== 1 && "s"}</span>);
    }
    if(useWeeks) {
        if(!!weeks) {
            message.push(<span key={`weeks`}> <strong>{weeks}</strong> week{weeks !== 1 && "s"}</span>);
        } else if(!!months) {
            message.push(<span key={`weeks`}> <strong>0</strong> weeks</span>)
        }

        if(!!days4Weeks) {
            message.push(<span key={`days`}> <strong>{days4Weeks}</strong> day{days4Weeks !== 1 && "s"}</span>);
        } else if(!!weeks) {
            message.push(<span key={`days`}> <strong>0</strong> days</span>)
        }
    } else {
        if(!!days) {
            message.push(<span key={`days`}> <strong>{days}</strong> day{days !== 1 && "s"}</span>);
        } else if(!!weeks) {
            message.push(<span key={`days`}> <strong>0</strong> days</span>)
        }
    }


    if(!!hours) {
        message.push(<span key={`hours`}> <strong>{hours}</strong> hour{hours !== 1 && "s"}</span>);
    } else if(!!days) {
        message.push(<span key={`hours`}> <strong>0</strong> hours</span>)
    }

    if(!!minutes) {
        message.push(<span key={`minutes`}> <strong>{minutes}</strong> minute{hours !== 1 && "s"}</span>);
    }

    return (
        <>{message}</>
    )
}

CountdownTimer.propTypes = {
    endDate: PropTypes.string.isRequired,
    useWeeks: PropTypes.bool,
};





