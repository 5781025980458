import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import styles from './PaymentOption.scss';
import AccessDenied from "components/common/AccessDenied";
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import getCardIcon from "utils/getCardIcon";
import toast from "utils/toast";
import { handleErrorResponse } from 'utils/request';
import * as WellnessApi from "api/WellnessApi"


export default function PaymentOption(props) {
    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);

    const confirmDelete = async () => {
        setBtnDisabled(true);

        // TODO: Don't know if we have a need for setIsLoading here.
        //props.setIsLoading(true);
        WellnessApi.deletePaymentOption(props.card.id, props.clinicId, props.customerId)
        .then((res) => {
            toast.success('Card Deleted.');
            props.paymentMethodsChanged();
        })
        .catch((error) => {
            handleErrorResponse("deleting payment method", error);
        })
        .finally(() => {
            // TODO: Don't know if we have a need for setIsLoading here.
            //props.setIsLoading(false);
        });

        setDeleteIsOpen(false);
        setBtnDisabled(false);
    }

    const cardIcon = getCardIcon(props.card.brand);

    return (
        <div className={classnames(styles.root, styles.card)}>
            <div className={styles.cardType}>
                {cardIcon}
            </div>
            <div className={styles.cardName}>
                <div>{props.card.name}</div>
                {!!props.card.isDefault && <div className={styles.isDefault}>Default</div>}
            </div>
            <div><span>Last 4</span> {props.card.last4}</div>
            <div><span>Exp.</span> {props.card.expMonth}/{props.card.expYear}</div>
            <div className={styles.removeBtn}>
                <Button
                    // disabled={!props.canEditPaymentSettings}
                    iconOnly
                    onClick={() => setDeleteIsOpen(true)}
                    small
                    type="danger"
                    // Button disabled for the demo site
                    disabled
                >
                    <i className="fas fa-trash-alt" />
                </Button>
            </div>

            <Modal
                show={deleteIsOpen}
                onClose={() => setDeleteIsOpen(false)}
                small
                modalTitle="Are you sure you want to delete this card?"
            >
                {!props.canEditPaymentSettings ? (
                    <AccessDenied
                        customMessage="You do not have the correct permissions to delete a card."
                    />
                ) : (
                    <div>
                        <div className={classnames("flex align-center", styles.card)}>
                            <div className={styles.cardType}>
                                {cardIcon}
                            </div>
                            <div className={styles.cardName}>
                                <div>{props.card.name}</div>
                                {!!props.card.isDefault && <div className={styles.isDefault}>Default</div>}
                            </div>
                            <div><span>Last 4</span> {props.card.last4}</div>
                            <div><span>Exp.</span> {props.card.expMonth}/{props.card.expYear}</div>
                        </div>
                        <div className="flex spaced-content justify-flex-end border-top">
                            <Button
                                large
                                onClick={() => setDeleteIsOpen(false)}
                                disabled={btnDisabled}
                                type="gray"
                            >
                                Cancel
                            </Button>
                            <Button
                                large
                                onClick={confirmDelete}
                                disabled={btnDisabled}
                                type="danger"
                            >
                                Delete Card
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    )
}

PaymentOption.propTypes = {
    clinicId: PropTypes.number.isRequired,
    customerId: PropTypes.string.isRequired,
    card: PropTypes.object.isRequired,
    canEditPaymentSettings: PropTypes.bool,
    paymentMethodsChanged: PropTypes.func.isRequired
}
