import React, { useEffect } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import * as styles from "./ClinicPrograms.scss";
import * as ProgramActions from "actions/ProgramActions";
import ClinicBase from "components/layout/ClinicBase";
import ProgramTile from "components/programs/ProgramTile";
import { getClinicInfo } from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import {CANCELED, DECLINED, ELIGIBLE, ENROLLED, INELIGIBLE} from "constants/ProgramStatuses";
import * as UserPermissions from "constants/UserPermissions";
import AccessDeniedPage from "./AccessDeniedPage";

function ClinicPrograms(props) {
    useEffect(() => {
        props.getClinicPrograms(props.clinicId);
    }, [props.clinicId]);

    if (!props.canViewProgramEnrollmentClinic) {
        return <AccessDeniedPage />;
    }

    return (
        <ClinicBase
            clinicId={props.clinicId}
            docTitle="Programs"
            pageTitle="Programs"
        >
            <div className={styles.root}>
                <div className="flex spaced-content">
                    <div>
                        {map(orderBy(props.programs, [p => {
                            if (p.status === ELIGIBLE) return 0;
                            else if (p.status === ENROLLED) return 1;
                            else if (p.status === DECLINED) return 2;
                            else if (p.status === CANCELED) return 3;
                            else if (p.status === INELIGIBLE) return 4;
                            else return 5;
                        }, "programName"]), program => (
                            <ProgramTile
                                key={program.programId}
                                canEditProgramEnrollmentClinic={props.canEditProgramEnrollmentClinic}
                                clinicId={props.clinicId}
                                imageStyle={{
                                    display: "flex",
                                    margin: "auto",
                                    maxWidth: "100%",
                                    width: "auto",
                                }}
                                style={{ width: "216px" }}
                                tile={{
                                    ...program,
                                    id: program.programId,
                                    name: program.programName,
                                    image: program.programImage,
                                    startDate: program.programStartDate,
                                    endDate: program.programEndDate,
                                }}
                            />
                        ))}
                    </div>
                </div>
                {(!keys(props.programs)?.length || !reject(props.programs, { status: INELIGIBLE})?.length) && (
                    <div className={styles.noPrograms}>
                        <h2 className="text-center">No Available Programs At This Time</h2>
                    </div>
                )}
            </div>
        </ClinicBase>
    )
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canEditProgramEnrollmentClinic = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);
        const canViewProgramEnrollmentClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);
        return {
            clinicId,
            programs: get(state.entities.clinicPrograms, clinicId, {}),
            canEditProgramEnrollmentClinic,
            canViewProgramEnrollmentClinic,
        }
    },
	(dispatch) => ({
		getClinicPrograms: (clinicId) => dispatch(ProgramActions.loadClinicPrograms(clinicId)),
	})
)(ClinicPrograms);
