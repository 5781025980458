exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".KittyPodiumRight__cls1{fill:#90999e}.KittyPodiumRight__cls1,.KittyPodiumRight__cls2,.KittyPodiumRight__cls3,.KittyPodiumRight__cls4,.KittyPodiumRight__cls5,.KittyPodiumRight__cls6,.KittyPodiumRight__cls7,.KittyPodiumRight__cls8,.KittyPodiumRight__cls9,.KittyPodiumRight__cls10,.KittyPodiumRight__cls11,.KittyPodiumRight__cls12,.KittyPodiumRight__cls13{stroke-width:0}.KittyPodiumRight__cls2{fill:#ffc200}.KittyPodiumRight__cls3{fill:#232021}.KittyPodiumRight__cls4{fill:#fffaff}.KittyPodiumRight__cls5{fill:#e5d11a}.KittyPodiumRight__cls6{fill:#c47258}.KittyPodiumRight__cls7{fill:#e2ae1a}.KittyPodiumRight__cls8{fill:#343031}.KittyPodiumRight__cls9{fill:#fff}.KittyPodiumRight__cls10{fill:#cbc6c5}.KittyPodiumRight__cls11{fill:#231f20}.KittyPodiumRight__cls12{fill:#ba1a55}.KittyPodiumRight__cls14{fill:#bfced6;stroke:#90999e;stroke-miterlimit:10}.KittyPodiumRight__cls13{fill:#04456f}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/GoldenSummerGames/KittyPodiumRight.scss"],"names":[],"mappings":"AAAA,wBAAM,YAAY,CAAC,4TAAkF,cAAc,CAAC,wBAAM,YAAY,CAAC,wBAAM,YAAY,CAAC,wBAAM,YAAY,CAAC,wBAAM,YAAY,CAAC,wBAAM,YAAY,CAAC,wBAAM,YAAY,CAAC,wBAAM,YAAY,CAAC,wBAAM,SAAS,CAAC,yBAAO,YAAY,CAAC,yBAAO,YAAY,CAAC,yBAAO,YAAY,CAAC,yBAAO,aAAa,eAAe,oBAAoB,CAAC,yBAAO,YAAY,CAAC","file":"KittyPodiumRight.scss","sourcesContent":[".cls1{fill:#90999e}.cls1,.cls2,.cls3,.cls4,.cls5,.cls6,.cls7,.cls8,.cls9,.cls10,.cls11,.cls12,.cls13{stroke-width:0}.cls2{fill:#ffc200}.cls3{fill:#232021}.cls4{fill:#fffaff}.cls5{fill:#e5d11a}.cls6{fill:#c47258}.cls7{fill:#e2ae1a}.cls8{fill:#343031}.cls9{fill:#fff}.cls10{fill:#cbc6c5}.cls11{fill:#231f20}.cls12{fill:#ba1a55}.cls14{fill:#bfced6;stroke:#90999e;stroke-miterlimit:10}.cls13{fill:#04456f}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cls1": "KittyPodiumRight__cls1",
	"cls2": "KittyPodiumRight__cls2",
	"cls3": "KittyPodiumRight__cls3",
	"cls4": "KittyPodiumRight__cls4",
	"cls5": "KittyPodiumRight__cls5",
	"cls6": "KittyPodiumRight__cls6",
	"cls7": "KittyPodiumRight__cls7",
	"cls8": "KittyPodiumRight__cls8",
	"cls9": "KittyPodiumRight__cls9",
	"cls10": "KittyPodiumRight__cls10",
	"cls11": "KittyPodiumRight__cls11",
	"cls12": "KittyPodiumRight__cls12",
	"cls13": "KittyPodiumRight__cls13",
	"cls14": "KittyPodiumRight__cls14"
};