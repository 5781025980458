import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./ProviderDashboard.scss";
import * as ProviderActions from "actions/ProviderActions";
import * as UserRoles from "constants/UserRoles";
import ProviderBase from "components/layout/ProviderBase";
import ProviderTableAndMapContainer from "components/provider/widgets/ProviderTableAndMapContainer";
import { find, upperFirst } from "lodash";
import * as OrgNodeTypes from "constants/OrgNodeTypes";
import ProviderClinicDetailsModal from "components/provider/widgets/ProviderClinicDetailsModal";
import getProviderId from "utils/getProviderId";
import { InfoWindow } from "@react-google-maps/api";
import ClinicMarkerInfoWindow from "components/provider/widgets/ClinicMarkerInfoWindow";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import * as ProviderLinks from "utils/ProviderLinks";
import {compose} from "redux";
import {withRouter} from "react-router";
import * as CouponActions from "actions/CouponActions";
import SortableDataTable from "components/common/SortableDataTable";
import AccessDenied from "components/common/AccessDenied";
import {getNodeId} from "utils/AdminData";
import {getLinkLocation} from "../../../utils/ClinicProviderLinks";
import * as ClinicAdminLinks from "../../../utils/ClinicAdminLinks";
import * as ClinicProviderLinks from "../../../utils/ClinicProviderLinks";

function ProviderMap(props) {
    const [clinicDetailsId, setClinicDetailsId] = useState(null);
    const [infoWindowMarkerId, setInfoWindowMarkerId] = useState(null);

    useEffect(() => {
        if (!!props.nodeId && !props.clinics) {
            props.getClinicsForNode(props.nodeId);
        }
    }, [props.nodeId]);

    const getMapMarkerDataSource = () => {
        return props.clinics;
    }

    const getPageTitle = () => {
        if (!props.node) {
            return "";
        }

        switch (props.node?.nodeType) {
            case OrgNodeTypes.HQ:
                return `${props.node?.name} | HQ`;
            default:
                return `${props.userProfile ? props.userProfile.name : ""} | ${upperFirst(props.node?.nodeType)}`;
        }
    };
    const handleTableRowClick = row => {
        if(props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("dashboard", row.clinicId));
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("dashboard", props.nodeId, row.clinicId));
        }
    };

    const handleMarkerClick = marker => {
        setInfoWindowMarkerId(marker.clinicId);
    };

    // This is what's rendered (if anything) when you click a marker
    const renderMarkerChildren = marker => {
        if (marker.clinicId === infoWindowMarkerId) {
            const clinic = find(props.clinics, clinic => clinic.clinicId === marker.clinicId) || null;
            return (
                <InfoWindow>
                    <ClinicMarkerInfoWindow
                        providerId={props.providerId}
                        nodeId={props.nodeId}
                        showCouponLibraryLink={props.columns.showCouponLibraryLink || false}
                        showAccountNumber={props.columns.showAccountNumber || false}
                        clinic={clinic}
                        onRedemptionsClick={() => setClinicDetailsId(marker.clinicId)} // Show clinic redemptions popup
                        isAdmin={props.isAdmin}
                    />
                </InfoWindow>
            );
        }

        return null;
    };

    const viewRole = UserRoles.PROVIDER_ADMIN;
    const pageTitle = getPageTitle();
    const mapMarkers = getMapMarkerDataSource();

    const COLUMNS = [
        {
            name: "Clinic",
            selector: "clinicName",
            key: "clinicName",
            sortable: true,
            searchable: true,
            format: row => (
                <div>
                    <div className="text-primary">{row.clinicName}</div>
                    <div style={{ fontSize: "0.7em" }}>{row.city}, {row.state}</div>
                </div>
            ),
        }
    ];

    return (
        <ProviderBase
            match={props.match}
            location={props.location}
            // searchConfig={SearchTypes.CLINIC_SEARCH} // TODO: Could vary based on current org node type
            pageTitle={pageTitle}
            nodeId={props.nodeId}
            providerId={props.providerId}
        >
            <div className={styles.root}>
                {props.canViewCorporateGroupClinic ? (
                    <>
                        <div className={styles.mainSection}>
                            <div className="flex">
                                <div className="flex-1 flex-column margin-right-sm">
                                    <div className={styles.providerMap}>
                                        <ProviderTableAndMapContainer
                                            mapMarkers={mapMarkers}
                                            markerChildren={renderMarkerChildren}
                                            viewRole={viewRole} // TODO: Replace with orgNodeType (see ProviderClinicMap.jsx)
                                            onMarkerClick={handleMarkerClick}
                                            canExpand={false}
                                        >
                                            <SortableDataTable
                                                striped
                                                green
                                                resultsPerPage={10}
                                                columns={COLUMNS}
                                                rawData={props.clinics}
                                                onClick={handleTableRowClick}
                                                minimalPagination
                                            />
                                        </ProviderTableAndMapContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ProviderClinicDetailsModal
                            show={!!clinicDetailsId}
                            providerId={Number(props.providerId)}
                            clinicId={clinicDetailsId || 0}
                            onClose={() => setClinicDetailsId(null)}
                        />
                    </>
                ) : (
                    <AccessDenied/>
                )}
            </div>
        </ProviderBase>
    );
}

const connector = connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const providerId = getProviderId(params, userProfile);
        const nodeId = getNodeId(state, ownProps);

        //Permissions
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);

        return {
            nodeId,
            userProfile,
            providerId,
            node: state.entities.nodes?.[nodeId],
            clinics: state.entities.nodeClinics[nodeId] || null,
            columns: state.entities.nodeClinicColumns[nodeId] || [],
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && providerId && nodeId),

            // Permissions
            canViewCorporateGroupClinic,
        };
    },

    (dispatch) => ({
        getClinicsForNode: (nodeId) => dispatch(ProviderActions.getNodeClinics(nodeId)),
    }),
);

export default compose(
    withRouter,
    connector
)(ProviderMap);
