import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import find from "lodash/find";
import includes from "lodash/includes";
import HistoricGamesList from "./HistoricGamesList";
import ProviderBase from "components/layout/ProviderBase";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import * as ProviderApi from "api/ProviderApi";
import {handleErrorResponse} from "utils/request";
import { getLinkLocation } from "utils/ProviderLinks";
import { mapClinicGamesFromServerToApp } from "data/serverMapping";

function ClinicHistoricGamesDemo(props) {
    const history = useHistory();
    const location = useLocation()
    const params = useParams();
    const nodeId = Number(params?.nodeId) || props.userProfile?.nodeId || null;

    const providerId = Number(params?.providerId) || props.primaryProviderId || null;
    const isAdmin = includes(location.pathname, "/admin");
    const [demoGames, setDemoGames] = useState([]);

    useEffect(() => {
        if(props.canViewClinicBIGamesDashboard) {
            ProviderApi.getClinicGameDemos()
                .then(res => {
                    setDemoGames(mapClinicGamesFromServerToApp(res.body));
                })
                .catch(error => {
                    handleErrorResponse('getting clinic games', error);
                });
        }
    }, [props.clinicId]);

    const goToCurrentGames = () => {
        history.push(getLinkLocation("demo-clinic-games", {
            providerId,
            nodeId,
            isAdmin,
            isDemo: true,
        }));
    };

    return (
        <ProviderBase
            pageTitle="Demo Clinic Games"
            nodeId={nodeId}
            match={props.match}
        >
            <HistoricGamesList
                games={demoGames}
                onClickCurrent={goToCurrentGames}
            />
        </ProviderBase>
    );
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const providerData = find(userProfile?.providers, { isPrimaryLocation: true });
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            userProfile,
            primaryProviderId: Number(providerData?.id) || null,
            canViewClinicBIGamesDashboard,
        }
    }
)(ClinicHistoricGamesDemo);
