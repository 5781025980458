import React, { useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styles from "./ClinicDashboard.scss";
import moment from "moment";
import filter from "lodash/filter";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import reject from "lodash/reject";
import * as ClinicActions from "actions/ClinicActions";
import * as CouponActions from "actions/CouponActions";
import * as NotificationActions from "actions/NotificationsActions";
import * as TourActions from "actions/TourActions";
import * as WellnessActions from "actions/WellnessActions";
import { loadClinicProgramDashboard } from "api/ProgramApi";
import { windowSizeContext } from "AppRoot";
import ActivityGrid from "components/dashboard/ActivityGrid";
import AvailableProgramsDashboardWidget from "components/programs/AvailableProgramsDashboardWidget";
import Button from "components/common/Button";
import ClinicBase from "components/layout/ClinicBase";
import DashboardTile from "components/dashboard/widgets/DashboardTile";
import GamesDashboardWidget from "components/dashboard/widgets/GamesDashboardWidget";
import ImageCarousel from "components/common/ImageCarousel";
import JoyRide from "components/tour/widgets/JoyRide";
import NotificationModal from "components/notifications/widgets/NotificationModal";
import NotificationsDashboardWidget from "components/dashboard/widgets/NotificationsDashboardWidget";
import PendingCouponsModal from "components/coupons/widgets/PendingCouponsModal";
import PendingVisitsModal from "components/wellness/widgets/PendingVisitsModal";
import RebateGrid from "components/dashboard/RebateGrid";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {
    TOUR_DASHBOARD,
    TOUR_GAMES_PART_2,
    TOUR_NOTIFICATIONS_PART_1,
    TOUR_NOTIFICATION_TRANSITION,
    TOUR_PROGRAM_OPT_IN_PART_2
} from "utils/demoTourHelpers";
import { getNodeId } from "utils/AdminData";
import EligibilityTrackerQRCode from "components/clinic/widgets/EligibilityTrackerQRCode";
import Modal from "components/common/Modal";
import {
    getClinicInfo,
    getUnreadNotificationCount,
    getPendingCouponCount,
    getPendingVisitCount,
    getDashboardTiles,
    getCurrentGames,
    clinicHasGames,
    clinicHasWellness,
    clinicHasCoupons
} from "utils/ClinicData";
import * as DemoTourStates from "constants/DemoTourStates";
import { getCouponCount, getWellnessCount } from "utils/getPending";
import { PermissionTypes, userHasPermission, userPrimaryLocationType } from "utils/permissions/rolesPermissions";
import { getClinicBasePath } from "utils/routingUtils";
import { CLINIC_TAGS } from "constants/AdminControls";
import * as LocationTypes from "constants/LocationTypes";
import { PENDING } from "constants/Notifications";
import { ENROLLED } from "constants/ProgramStatuses";
import * as RoleTypes from "constants/RoleTypes";
import * as UserPermissions from "constants/UserPermissions";
import { GREENLINE_WELLNESS, MERCK } from "constants/ProviderIds";
import {handleErrorResponse} from "utils/request";

export const ClinicUserContext = React.createContext({ isAdmin: false, isProvider: false });

function ClinicDashboard(props) {
    const [loading, setLoading] = useState(false);
    const [clinicProgramsDashboard, setClinicProgramsDashboard] = useState(null);
    const [showCouponModal, setShowCouponModal] = useState(false);
    const [showWellnessModal, setShowWellnessModal] = useState(false);
    const [showQRCode, setShowQRCode] = useState(false);
    const [selectedNotificationId, setSelectedNotificationId] = useState(null);
    const [tourSection, setTourSection] = useState(null);

    const TODAY = new moment();
    const context = useContext(windowSizeContext) || {};

    const eligibilityTrackerService = find(clinicProgramsDashboard?.programServiceStatuses, { programServiceId: 10 }) || {};
    const hasEligibilityTracker = eligibilityTrackerService?.programServiceStatus === ENROLLED;

    const visibleNotifications = useMemo(() => {
        return reject(props.notifications, n => n.notificationType === "Reminder" || n.isVisibleInNotifications === false);
    }, [props.notifications]);

    useEffect(() => {
        if (props.clinicId) {
            fetchData();
        }
    }, [props.clinicId, props.hasGames, props.canViewClinicBIGamesDashboard]);


    useEffect(() => {
        const alerts = filter(props.notifications, n => n.isAlert && !n.dismissed && n.enrollmentState === PENDING && !props.dismissedAlerts.includes(n.notificationId));
        const forceSelectedNotificationId = alerts.length ? alerts[0].notificationId : null;
        setSelectedNotificationId(forceSelectedNotificationId);
    }, [props.notifications, props.dismissedAlerts]);

    useEffect(() => {
        getTourSection();
    }, [props.completedTourSections]);

    const fetchData = async () => {
        setLoading(true);
        const clinicId = props.clinicId; // Optional (for admin view only)

        if (props.canViewClinicCouponAudit && (props.isAdmin || !props.coupons)) {
            props.getUnprocessedCoupons(clinicId);
        }

        if (props.canViewWellnessAudit &&  (props.isAdmin || !props.visits)) {
            props.getUnprocessedWellnessVisits(clinicId);
        }

        if (props.userProfile.canClinic && props.canViewNotifications) {
            props.loadNotifications(clinicId);
        }

        if (props.canViewClinicBIGamesDashboard && props.hasGames) {
            props.getClinicGreenlineGames(clinicId);
        }

        try {
            const res = await loadClinicProgramDashboard(clinicId);
            setClinicProgramsDashboard(res.body);
        } catch (e) {
            handleErrorResponse("loading clinic program dashboard", e);
        } finally {
            setLoading(false);
        }
    }

    const getTourSection = () => {
        // Don't set the tourSection if the tour is complete
        let currentSection = null;
        if ((props.userProfile.demoProvider !== GREENLINE_WELLNESS) && !includes(props.completedTourSections, TOUR_DASHBOARD)) {
            // Just the default dashboard tiles and such
            currentSection = TOUR_DASHBOARD;
        } else if (props.canViewNotifications && !includes(props.completedTourSections, TOUR_NOTIFICATION_TRANSITION)) {
            // View the transition between dashboard and notifications
            currentSection = TOUR_NOTIFICATION_TRANSITION;
        } else if (props.canViewNotifications && !includes(props.completedTourSections, TOUR_NOTIFICATIONS_PART_1)) {
            // Just view the notifications part of the dashboard and continue from there
            currentSection = TOUR_NOTIFICATIONS_PART_1;
        } else if (props.canViewClinicBIGamesDashboard && !includes(props.completedTourSections, TOUR_GAMES_PART_2)) {
            currentSection = TOUR_GAMES_PART_2
        } else if ((props.userProfile.demoProvider === MERCK) && !includes(props.completedTourSections, TOUR_PROGRAM_OPT_IN_PART_2)) {
            currentSection = TOUR_PROGRAM_OPT_IN_PART_2
        }
        setTourSection(currentSection);
    }

    const handlePendingCouponButtonClicked = () => {
        setShowCouponModal(true);
    }

    const handlePendingVisitsButtonClicked = () => {
        setShowWellnessModal(true);
    }

    const handleNotificationClicked = (id) => {
        setSelectedNotificationId(id);
    }

    const handleProgramClicked = (id) => {
    //     TODO: go to program
    }

    const handleNotificationModalClose = () => {
        if (selectedNotificationId) {
            props.storeDismissedAlerts(selectedNotificationId);
            setSelectedNotificationId(null);
        }
    }

    const showSpinner = () => {
        let shouldShow = false;

        if(props.statsLoading) {
            shouldShow = true;
        }

        if(!props.tiles) {
            shouldShow = true;
        }

        if(!props.notifications && props.canViewNotifications) {
            shouldShow = true;
        }

        if((!props.coupons && props.coupons !== 0) && props.canViewClinicCouponAudit) {
            shouldShow = true;
        }

        if(!props.visits && props.visits !== 0) {
            shouldShow = true;
        }

        return shouldShow;
    }

    const renderProcessingButtons = () => {
        if ((!props.coupons && props.coupons !== 0) || (!props.visits && props.visits !== 0) ) {
            return null;
        }

        const couponsPending = getCouponCount(props.stats);
        const wellnessPending = getWellnessCount(props.stats);
        const useCouponModal = ((couponsPending <= 15) && (couponsPending > 0));
        const useWellnessModal = ((wellnessPending <= 15) && (wellnessPending > 0));
        const basePath = getClinicBasePath(props.location.pathname, props.clinicId, props.nodeId);

        return (
            <div className={styles.processButtons}>
                {(props.canViewClinicCouponAudit && props.hasCoupons) && (
                    <>
                        {useCouponModal ?
                            <Button
                                onClick={handlePendingCouponButtonClicked}
                                action
                                title={!couponsPending ? "No current pending coupons" : ""}
                                icon
                            >
                                <i className={"icon far fa-ticket"} /> Process Coupons
                            </Button>
                            :
                            <Link className={styles.routerLink} to={`${basePath}/coupons`}>
                                <Button action icon >
                                    <i className="icon far fa-ticket" /> Process Coupons
                                </Button>
                            </Link>
                        }
                    </>
                )}

                {props.useWellness && (useWellnessModal ? (
                    <Button
                        onClick={handlePendingVisitsButtonClicked}
                        action
                        title={!wellnessPending ? "No pending wellness visits" : ""}
                        icon
                    >
                        <i className={"icon fas fa-user-md"} /> Approve Wellness
                    </Button>
                ) : (
                    <Link className="router-link" to={`${basePath}/wellness`}>
                        <Button action icon>
                            <i className={"icon fas fa-user-md"} /> Approve Wellness
                        </Button>
                    </Link>
                ))}
                {(hasEligibilityTracker) && (
                    <Button
                        onClick={() => setShowQRCode(true)}
                        action
                        icon
                        type="eligibilityTracker"
                    >
                        <i className="fa fa-qrcode" /> Eligibility Tracker QR Code
                    </Button>
                )}
            </div>
        );
    }

    const renderDashboardActivityTiles = () =>  {
        if (!props.tiles) {
            return null;
        }

        const tiles = [...props.tiles.items];

        return (
            <div className={styles.section}>
                <ActivityGrid
                    title="Activity"
                    stats={!props.stats ? [] : props.stats.items}
                    tiles={tiles}
                    // handlePeriodChanged={this.onPeriodChanged}
                    hasWellness={props.useWellness}
                />
            </div>
        )
    }

    const renderDashboardRebateTiles = () =>  {
        if (!props.rebateTiles?.length) {
            return null;
        }

        return (
            <div className={styles.section}>
                <RebateGrid
                    title="Rebate Counter"
                    tiles={props.rebateTiles}
                />
            </div>
        )
    }

    const renderCalculators = () => {
        return null;

        if (!props.canViewCalculators) {
            // Doesn't have permissions. Simply return.
            // Should be available only to Greenline groups.
            return;
        }

        return (<>
            { props.canViewCalculators && <Link to={`/admin/clinic/${props.clinicId}/calculator/profitability`}>
                <DashboardTile
                    isButton
                    largeIcon="far fa-chart-bar"
                    title="Profitability Calculator"
                />
            </Link> }
            { props.canViewCalculators && <Link to={`/admin/clinic/${props.clinicId}/calculator/opportunity`}>
                <DashboardTile
                    isButton
                    largeIcon="far fa-chart-line"
                    title="Opportunity Calculator"
                />
            </Link> }
        </>)
    }


    const renderRightSection = () => {
        const basePath = getClinicBasePath(props.location.pathname, props.clinicId, props.nodeId);
        const activeGames = (props.hasGames && props.canViewClinicBIGamesDashboard && props.games) ? filter(props.games, game => {
            return moment(game.endDate).add(7, "day").isSameOrAfter(TODAY);
        }) : null;
        const notificationLimit = 6;

        if (!(props.canViewNotifications || (props.hasGames && props.canViewClinicBIGamesDashboard))) {
            return null
        }

        return (
            <div className={styles.rightSection}>
                <div className={styles.section}>
                    {props.canViewNotifications && (
                        <NotificationsDashboardWidget
                            basePath={basePath}
                            limit={notificationLimit}
                            notifications={visibleNotifications || null}
                            unread={props.notificationTotal}
                            onClick={handleNotificationClicked}
                        />
                    )}
                </div>
                <div className={styles.section}>
                    {props.canViewNotifications && (
                        <AvailableProgramsDashboardWidget basePath={basePath} />
                    )}
                </div>
                {(props.hasGames && props.canViewClinicBIGamesDashboard) &&
                    <div className={styles.section}>
                        <GamesDashboardWidget
                            key={`dashboard_games_${props.clinicId||"x"}_${props.nodeId}`}
                            games={activeGames}
                            basePath={basePath}
                            clinicId={props.clinicId}
                            nodeId={props.nodeId}
                            isAdminView={props.isAdmin}
                            isProviderView={props.isProvider}
                        />
                    </div>
                }
            </div>
        );
    }

    const renderDisclaimer = () => {
        if (!props.tiles) {
            return null;
        }

        const lastUpdate = new Date(props.tiles.lastUpdate).toLocaleDateString();

        return (
            <div
                className="text-italic margin-top-sm"
                style={{ color: "#999", fontSize: "0.8em" }}
            >
                * Items above are estimates only - Last Update {lastUpdate}.
            </div>
        );
    }

    const renderReminders = () => {
        if(!props.reminders?.length){
            return null;
        }

        return(
            <div className={styles.bottomSection}>
                <h3>Reminders</h3>
                <div className={styles.reminders}>
                    <ImageCarousel
                        images={flatMap(props.reminders, "bannerImageUri")}
                    />
                </div>
            </div>
        );
    }

    const selectedNotification = selectedNotificationId ? find(props.notifications, {notificationId: selectedNotificationId}) : null;
    // TODO: Dashboard search will vary based on user permissions
    // const searchConfig = props.useWellness ? SearchTypes.WELLNESS_PLANS : SearchTypes.COUPONS;
    return (
        <ClinicUserContext.Provider value={{
            isAdmin: props.isAdmin,
            isProvider: props.isProvider,
            nodeId: props.nodeId,
            clinicId: props.clinicId,
        }}>
            <ClinicBase
                clinicId={props.clinicId}
                adminControls={CLINIC_TAGS}
                docTitle={!!((props.isAdmin || props.isProvider) && props.clinicInfo?.name) ? `${props.clinicInfo.name} Dashboard` : "Dashboard"}
                collapsible={context.isTabletPortrait}
            >
                <div className={styles.root}>
                    <div className={styles.top}>
                        <div className={styles.mainSection}>
                            {!context.isPhone && renderProcessingButtons()}
                            {renderDashboardActivityTiles()}
                            {renderDisclaimer()}
                            {renderDashboardRebateTiles()}
                            {renderCalculators()}
                        </div>
                        {renderRightSection()}
                    </div>
                    {(props.tourStarted && !!tourSection) && (
                        <JoyRide
                            key={tourSection}
                            tourSection={tourSection}
                        />
                    )}
                    {renderReminders()}
                    <SpinnerTakeover show={showSpinner()}/>
                </div>
                <PendingCouponsModal
                    show={showCouponModal}
                    onClose={() => setShowCouponModal(false)}
                    clinicId={props.clinicId}
                />
                <PendingVisitsModal
                    show={showWellnessModal}
                    onClose={() => setShowWellnessModal(false)}
                    clinicId={props.clinicId}
                />
                <NotificationModal
                    clinicId={props.clinicId}
                    notification={selectedNotification}
                    show={!!selectedNotification}
                    canAcceptNotifications={props.canAcceptNotifications}
                    canDeclineNotifications={props.canDeclineNotifications}
                    onClose={handleNotificationModalClose}
                    isDemo
                />
                {hasEligibilityTracker && (
                    <Modal
                        show={showQRCode}
                        onClose={() => setShowQRCode(false)}
                        small
                        modalTitle="Eligibility Tracker QR Code"
                    >
                        <EligibilityTrackerQRCode
                            clinicId={props.clinicId}
                        />
                    </Modal>
                )}
                <SpinnerTakeover show={props.working || loading}/>
            </ClinicBase>
        </ClinicUserContext.Provider>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const nodeId = getNodeId(state, ownProps);
        const userProfile = state.user.userProfile;
        //Use Location rather than role to determine if the user is an Admin or provider
        const isAdmin = userPrimaryLocationType(userProfile, [LocationTypes.NULL]);
        const isProvider = userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]);
        const canViewClinicCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canViewWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewNotifications = userHasPermission(PermissionTypes.VIEW, UserPermissions.NOTIFICATIONS, userProfile);
        const canAcceptNotifications = userHasPermission(PermissionTypes.ACCEPT, UserPermissions.NOTIFICATIONS, userProfile);
        const canDeclineNotifications = userHasPermission(PermissionTypes.DECLINE, UserPermissions.NOTIFICATIONS, userProfile);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const clinicWellness = !!clinicHasWellness(state, clinicId);

        const rolesToAllow = RoleTypes.ROLES.filter(x => x.userType === "GL").map(x => x.value);
        const allowedUserPermissions = userProfile.roles.filter(userGroup => {
            return rolesToAllow.some(allowedGroup => allowedGroup === userGroup);
         });
        const canViewCalculators = allowedUserPermissions.length > 0;

        return {
            userProfile,
            isAdmin,
            isProvider,
            clinicId,
            nodeId,
            clinicInfo,
            canViewClinicCouponAudit,
            canViewWellnessAudit,
            canViewNotifications,
            canViewCalculators,
            canAcceptNotifications,
            canDeclineNotifications,
            canViewClinicBIGamesDashboard,
            coupons: state.entities.unprocessedCoupons,
            visits: getPendingVisitCount(state),
            couponCount: getPendingCouponCount(state),
            useWellness: (clinicWellness && canViewWellnessAudit),
            hasGames: clinicHasGames(state, clinicId),
            hasCoupons: clinicHasCoupons(state, clinicId),
            reminders: Object.values(state.entities.reminders),
            notifications: state.entities.notifications,
            notificationTotal: getUnreadNotificationCount(state),
            rebateTiles: state.entities.rebateTiles,
            tiles: getDashboardTiles(state),
            games: getCurrentGames(state),
            stats: state.entities.stats,
            statsLoading: state.entities.stats.statsLoading,
            completedTourSections: state.demo.completedTourSections,
            tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),
            working: !!state.applicationState.working,
            dismissedAlerts: state.entities.dismissedAlerts,
        };
    },
    (dispatch) => ({
        getClinicGreenlineGames: (clinicId) => dispatch(ClinicActions.getGreenlineGames(clinicId)),
        getUnprocessedCoupons: (clinicId) => dispatch(CouponActions.getUnprocessedCoupons(clinicId)),
        getUnprocessedWellnessVisits: (clinicId) => dispatch(WellnessActions.getUnprocessedWellnessVisits(clinicId)),
        loadNotifications: (clinicId) => dispatch(NotificationActions.loadNotifications(clinicId)),
        dismissNotification: (id) => dispatch(NotificationActions.dismissNotification(id)),
        markTourSectionAsComplete: (tourSection) => dispatch(TourActions.markTourSectionAsComplete(tourSection)),
        storeDismissedAlerts: (dismissedAlerts) => dispatch(NotificationActions.storeDismissedAlerts(dismissedAlerts)),
    }),
)(ClinicDashboard);
