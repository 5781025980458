import React from "react";
import {
	MAIN_COLOR,
	SECONDARY_COLOR,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./KittyPodiumLeft.scss";


export default function KittyPodiumLeft(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Podium_-_Left_-_Kitty"
			data-name="Podium - Left - Kitty"
			// xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 800 300"
		>
			<g id="Kitty_Winner_Bronze">
				<g id="Kitty_Winner_Bronze_Lower">
					<path className={styles.cls11}
					      d="M163.09,267.59c-.11-.72-.17-1.44-.16-2.17.05-3.66,1.51-7.26,3.45-10.6,4.42-7.62,12.09-15.53,21.61-18.83,8.62-2.99,26.81-4.47,32.91,2.1,1.05,1.13,1.71,2.43,2.19,3.74,3.65,9.78-2.26,21.03-14.08,26.81,4.64,1.17,10.22,2.67,10.22,8.55,0,8.17-28.11,7.91-28.83.22-.3-3.23-.17-3.01.31-5.97-2.6,3.67-4.33,3.71-9.05,5.2s-10.3,1.09-14.03-1.55c-2.6-1.84-4.1-4.6-4.56-7.51Z"/>
					<path className={styles.cls11}
					      d="M240.71,281.14c6.26-15.14-11.85-14.87-12.67-16.95-3.85-9.76,8.04-37.11,1.4-77.8-.47-2.85-39.81-5.51-32.19,24.56,5.32,21,13.6,38.91,18.75,63.8,2.02,9.76,23.77,8.67,24.71,6.4Z"/>
					<g>
						<path className={styles.cls9}
						      d="M96.33,263.32c6.23,6.81,22.03,8.44,30.91,9.45,5.13.59,12.24,1.63,17.33-.46,6.83-2.8,6.16-8.57-1.41-10.4-4.82-1.17-9.14.31-14.16-1.22-5.6-1.7-10.99-3.27-16.4-5.52-4.49-1.86-9.78-4.71-14.4-1.96-3.66,2.18-4.95,6.73-1.87,10.11Z"/>
						<path className={styles.cls8}
						      d="M96.33,263.32c-3.08-3.38-1.79-7.93,1.87-10.11,4.61-2.75,9.91.1,14.4,1.96.06.02.12.05.18.07-2.35,2.66-4.22,5.8-4.8,9.14-.27,1.54-.18,3.29-.12,5.06-4.71-1.37-8.95-3.3-11.54-6.13Z"/>
					</g>
					<path className={styles.cls4}
					      d="M201.29,261.57c7.91-34.39,35.79-37.55,34.21-67.27-2.02-38.02-51.61-36.45-72.78-11.45-16.13,19.05-34.45,43.28-19.53,87.55,7.76,23.03,53.29,12.06,58.1-8.83Z"/>
					<path className={styles.cls9}
					      d="M138.05,247.28c-.44-3.39-.51-7.51-.25-9.93,1-9.5,32.7,5.03,30.55,9.58-4.26,9.01-20.71,6.28-30.3.35Z"/>
				</g>
				<path
					id="Shirt"
					className={styles.cls2}
					d="M234.87,209.82c4-10.07,1.28-26-6.63-35.64l-3.16,1.6c3.94,8.43,7.01,22.03-2.87,24.24-9.1,2.03-13.36-6.16-15.45-12.06-5.71-16.14-21.39-21.3-33.24-14.44-19.12,11.07-37.57,45.09-37.57,62.5,17.31,17.96,41.28,16.46,72.24,11.11,6.57-16.57,22.09-25.74,26.68-37.31ZM177.44,215.75c-9.72-2.55-15.56-12.4-13.04-22.01,2.52-9.6,12.44-15.32,22.16-12.77,9.72,2.55,15.56,12.4,13.04,22.01-2.52,9.6-12.44,15.32-22.16,12.77Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes">
					<path
						className={styles.cls14}
						d="M219.91,201.71c-7.23-.52-11.31-7.75-15.99-16.04-5.19-9.19-11.07-19.6-22.64-23.47l.86-2.58c12.57,4.21,18.72,15.1,24.15,24.72,4.73,8.37,8.46,14.98,14.93,14.67,3.45-.17,5.04-.73,6.25-4.26,2.58-7.53-1.59-16.58-5.87-23l2.59-.81c3.53,6.4,9.22,16.76,5.51,25.37-1.57,3.65-4.06,5.21-8.35,5.42-.5.02-.98.02-1.45-.02Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls14}
						d="M193.76,248.71c-8.55-.6-16.89-2.11-24.73-4.53-12.64-3.89-22.78-9.9-31.67-17.93.3-1.52,1.24-4.23,1.7-5.61,5.96,4.85,19.38,14.76,31.52,18.5,13.35,4.11,24.96,6.02,39.88,4.54-.17.84-1.65,3.53-2.07,4.67-6.2.51-8.57.79-14.64.36Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls14}
						d="M183.39,179.14c-10.71-1-20.24,6.8-21.23,17.39-.99,10.59,6.91,20.03,17.63,21.04,10.71,1,20.24-6.8,21.23-17.39.99-10.59-6.91-20.03-17.63-21.04ZM180.03,214.94c-9.26-.87-16.1-9.02-15.25-18.16.86-9.15,9.09-15.88,18.36-15.01s16.1,9.02,15.25,18.16c-.86,9.15-9.09,15.88-18.36,15.01Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Winner_Bronze_Mid">
					<path className={styles.cls4}
					      d="M235.57,283.25c6.84-14.88-16.93-15.67-18-17.63-5.01-9.22-3.19-43.7-20.33-75.22-6.62-12.18-39.98-5.51-26.17,22.27,9.68,19.49,27.67,46.92,36.05,67.28,3.38,8.21,27.15,6.13,28.45,3.31Z"/>
					<g>
						<path className={styles.cls13}
						      d="M185.82,174.02c4.99,9.94,31.54,20.21,41.69,17.59,4.34-4.9,3.39-12.62,2.33-16.15-.68-2.29-1.71-1.28-2.84-.65,1.81,6.03,2.49,12.09.24,15.26-9.5.57-30.78-16.14-39.46-18.04-1.76-.39-1.14,1.06-1.96,1.98Z"/>
						<g>
							<circle className={styles.cls5} cx="229.14" cy="207.16" r="12.56"/>
							<ellipse className={styles.cls1} cx="227.14" cy="192.14" rx="1.51" ry="2.92"
							         transform="translate(-23.44 31.77) rotate(-7.61)"/>
							<circle className={styles.cls1} cx="230.19" cy="205.68" r="12.56"/>
						</g>
					</g>
					<path className={styles.cls11}
					      d="M181.01,231.03c6.32-2.11,19.9-3.11,25.93,4.13,6.96,8.36,5.21,23.18-6.75,32.92-11.21,9.13-18.19-35.32-18.19-35.32-.34-.55-.99-1.62-.99-1.74Z"/>
					<path className={styles.cls4}
					      d="M152.36,259.06c3.51-9.16,10.02-16.68,19.22-22.62,7.69-4.96,25.42-7.99,32.21-.62,1.17,1.27,1.96,2.75,2.59,4.26,4.69,11.25.02,24.69-11.12,32.05,4.75,1.13,10.93,2.79,11.1,9.48.28,11.32-25.49,9.95-29.06,3.1-1.7-3.25-.34-6.35.67-9.43-1.87,2.98-5.2,3.91-9.73,5.89-4.54,1.98-12.31.34-15.15-1.78-4.57-3.42-5.42-8.05-.72-20.33Z"/>
					<g>
						<g>
							<path className={styles.cls9}
							      d="M295.76,97.13c-12.89,4-26.1,10.86-32.86,21.96-.33.54.61,1.02.94.48,6.61-10.86,19.61-17.59,32.23-21.51.64-.2.33-1.13-.32-.93Z"/>
							<path className={styles.cls9}
							      d="M304.14,113.3c-13.58,2.15-26.38,6.13-37.87,12.93-.57.34,0,1.17.58.83,11.4-6.76,24.12-10.7,37.6-12.83.67-.11.35-1.04-.31-.93Z"/>
							<path className={styles.cls9}
							      d="M304.86,126c-12.12-.31-23.9,1.95-35.42,4.84-.66.16-.34,1.1.31.94,11.43-2.86,23.11-5.11,35.14-4.8.69.02.66-.95-.03-.97Z"/>
						</g>
						<path className={styles.cls3}
						      d="M185.73,74.8c2.42,9.08,4.77,17.51,5.31,18.94,1.12,2.97.85,3.06,3.46,5.02,3.42,2.58,42.86-6.77,46.48-15.88-8.61-8.81-25.49-20.46-35.29-24.77-7.03-3.09-18.15-8.24-25.84-8.11-.51,0,2.74,12.96,5.89,24.8Z"/>
						<g>
							<path className={styles.cls4}
							      d="M171.67,133.06c41.8-36.75,16.66-44.32,3.65-45.87,3.83-2.28,7.84-4.29,11.88-5.99,7.94,3.36,32.19,11.64,60.42,2.14,6.47,5.59,11.42,13.22,16.33,20.27,5.62,8.07,13.14,8.35,14.13,26.58,1.14,21.06-12.89,28.04-21.94,34.17-10.76,7.29-24.94,12.16-37.7,15.01-8.51,1.9-24.45,2.22-33-.38-17.65-5.37-27.89-19.2-34.43-31.68-.32-.61-.61-1.22-.9-1.82,11.33-4.84,21.55-12.42,21.55-12.42Z"/>
							<path className={styles.cls8}
							      d="M152.6,103.17c3.15-6.71,8.27-14.93,22.72-15.97,13.01,1.55,38.15,9.12-3.65,45.87,0,0-10.22,7.58-21.55,12.42-7.19-15.36-3.69-29.15,2.49-42.31Z"/>
							<path className={styles.cls9}
							      d="M187.2,81.2c10.28-4.33,20.77-6.69,29.13-6.44,9.41.28,22.45,1.47,30.05,7.55.42.33.83.68,1.23,1.03-28.23,9.5-52.48,1.22-60.42-2.14Z"/>
						</g>
						<path className={styles.cls6}
						      d="M221.67,116.25c4.61,7.15,1.29,16.64-7.4,21.18-8.7,4.54-19.49,2.43-24.09-4.73-4.61-7.15-1.29-16.64,7.4-21.18,8.7-4.54,19.48-2.43,24.09,4.72Z"/>
						<path className={styles.cls6}
						      d="M254.17,98.62c4.11,5.43,4.12,11.56.03,13.68-4.09,2.12-10.73-.56-14.84-6-4.11-5.43-4.12-11.55-.03-13.68,4.09-2.12,10.73.57,14.84,6Z"/>
						<g>
							<path className={styles.cls9}
							      d="M194.31,155.73c10.63-7.95,23.78-14.68,36.69-14.27.63.02.48,1-.15.97-12.64-.4-25.55,6.24-35.96,14.02-.53.4-1.11-.32-.58-.72Z"/>
							<path className={styles.cls9}
							      d="M202.69,170.43c9.52-9.41,20.08-17.3,32.2-22.73.6-.27.95.6.35.87-12.03,5.39-22.51,13.23-31.96,22.58-.47.47-1.05-.25-.58-.72Z"/>
							<path className={styles.cls9}
							      d="M212.62,177.44c6.68-9.53,15.26-17.49,24.2-24.95.51-.43,1.09.29.58.72-8.87,7.4-17.38,15.29-24.01,24.75-.38.54-1.15.02-.77-.52Z"/>
						</g>
						<g>
							<path className={styles.cls12}
							      d="M218.35,117.58c4.18,6.4,4.26,13.8.18,16.54-4.09,2.74-10.79-.22-14.98-6.62-4.19-6.39-4.26-13.8-.18-16.54,4.08-2.74,10.79.22,14.98,6.61Z"/>
							<path className={styles.cls10}
							      d="M208.28,120.39c2.1,3.2,5.43,4.71,7.44,3.36,2.01-1.35,1.94-5.04-.16-8.25-2.1-3.21-5.43-4.71-7.44-3.36-2.01,1.35-1.94,5.04.16,8.25Z"/>
						</g>
						<g>
							<path className={styles.cls12}
							      d="M252.73,99.36c3.44,5.38,4.23,11.11,1.77,12.81-2.47,1.7-7.26-1.29-10.7-6.67-3.44-5.38-4.23-11.11-1.77-12.81,2.47-1.7,7.26,1.29,10.7,6.66Z"/>
							<path className={styles.cls10}
							      d="M246.26,100.49c1.72,2.7,4.11,4.21,5.32,3.37,1.22-.84.8-3.7-.92-6.4-1.73-2.7-4.11-4.21-5.32-3.37-1.21.84-.8,3.7.93,6.39Z"/>
						</g>
						<path className={styles.cls9}
						      d="M253.72,139.65c.46.45.89.98,1.04,1.13.94.91,2.05,1.74,3.35,2.02,2.55.55,5.81-.53,7.58-2.53,2.1-2.37,2.14-4.97,1.1-7.82-.51-1.41-.92-3.18-1.8-4.4-.2-.27-.58-.55-.72-.85-.18-.38.25-1.39.71-1.47.93-.16,1.39,1.07,1.65,1.74.41,1.05.88,1.93,1.42,2.92.98,1.81,2.14,4.15,4.24,4.86,1.53.52,3.19-.02,3.7-1.64.26-.83.08-1.62-.16-2.42-.07-.23-.6-1.75-.09-1.89.86-.23,1.09,1.68,1.2,2.19.46,2.13-.08,5.31-2.75,5.52-1.1.09-2.21-.36-3.14-.91-.86-.51-2-1.03-2.36-2.04.52,2.68-.16,5.06-1.98,7.11-.81.91-1.85,1.65-3,2.18-2.72,1.26-5.57,1.21-8.14-.27-.71-.41-4.41-3.09-3.12-4.08.28-.21.8.18,1.28.65Z"/>
						<path className={styles.cls7}
						      d="M263.64,116.37c-1.02.54-1.95,1.35-3.03,2.07-1.72,1.14-4.77,2.18-5.92,3.71-2.75,3.67,2.56,5.48,6.38,5.59,4.32.14,11.47-3.11,9.65-8.64-.62-1.87-2.09-3.01-4.03-3.29-1.19-.17-2.15.1-3.04.57Z"/>
					</g>
					<g>
						<path className={styles.cls8}
						      d="M134.81,118.66c7.71,5.94,14.95,11.39,16.29,12.23,2.78,1.74,2.63,1.98,5.92,1.96,4.33-.03,20.98-34.89,17.98-44.63-10.23-2.79-24.78,1.62-35.23,4.24-7.49,1.88-19.51,4.61-25.46,9.54-.4.33,10.44,8.92,20.49,16.67Z"/>
						<path className={styles.cls7} d="M167.21,99.42s-24.11-1.02-41.5,5.56l29.22,21.15,12.28-26.71Z"/>
					</g>
				</g>
				<g id="Stripe">
					<path
						className={styles.cls14}
						d="M157.8,156.03c16.6-33.05,60.33-65.6,91.01-70.93,2.35-.41-11.83-12.85-19.7-12.35-20.33,1.3-77.78,42.38-80.45,69.69-.4,4.06,7.59,16.69,9.14,13.59Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
			</g>
			<g id="Kitty_Winner_Bronze-2" data-name="Kitty_Winner_Bronze">
				<g id="Kitty_Winner_Bronze_Lower-2" data-name="Kitty_Winner_Bronze_Lower">
					<path className={styles.cls11}
					      d="M563.09,267.59c-.11-.72-.17-1.44-.16-2.17.05-3.66,1.51-7.26,3.45-10.6,4.42-7.62,12.09-15.53,21.61-18.83,8.62-2.99,26.81-4.47,32.91,2.1,1.05,1.13,1.71,2.43,2.19,3.74,3.65,9.78-2.26,21.03-14.08,26.81,4.64,1.17,10.22,2.67,10.22,8.55,0,8.17-28.11,7.91-28.83.22-.3-3.23-.17-3.01.31-5.97-2.6,3.67-4.33,3.71-9.05,5.2s-10.3,1.09-14.03-1.55c-2.6-1.84-4.1-4.6-4.56-7.51Z"/>
					<path className={styles.cls11}
					      d="M640.71,281.14c6.26-15.14-11.85-14.87-12.67-16.95-3.85-9.76,8.04-37.11,1.4-77.8-.47-2.85-39.81-5.51-32.19,24.56,5.32,21,13.6,38.91,18.75,63.8,2.02,9.76,23.77,8.67,24.71,6.4Z"/>
					<g>
						<path className={styles.cls9}
						      d="M496.33,263.32c6.23,6.81,22.03,8.44,30.91,9.45,5.13.59,12.24,1.63,17.33-.46,6.83-2.8,6.16-8.57-1.41-10.4-4.82-1.17-9.14.31-14.16-1.22-5.6-1.7-10.99-3.27-16.4-5.52-4.49-1.86-9.78-4.71-14.4-1.96-3.66,2.18-4.95,6.73-1.87,10.11Z"/>
						<path className={styles.cls8}
						      d="M496.33,263.32c-3.08-3.38-1.79-7.93,1.87-10.11,4.61-2.75,9.91.1,14.4,1.96.06.02.12.05.18.07-2.35,2.66-4.22,5.8-4.8,9.14-.27,1.54-.18,3.29-.12,5.06-4.71-1.37-8.95-3.3-11.54-6.13Z"/>
					</g>
					<path className={styles.cls4}
					      d="M601.29,261.57c7.91-34.39,35.79-37.55,34.21-67.27-2.02-38.02-51.61-36.45-72.78-11.45-16.13,19.05-34.45,43.28-19.53,87.55,7.76,23.03,53.29,12.06,58.1-8.83Z"/>
					<path className={styles.cls9}
					      d="M538.05,247.28c-.44-3.39-.51-7.51-.25-9.93,1-9.5,32.7,5.03,30.55,9.58-4.26,9.01-20.71,6.28-30.3.35Z"/>
				</g>
				<path
					id="Shirt-2"
					data-name="Shirt"
					className={styles.cls2}
					d="M634.87,209.82c4-10.07,1.28-26-6.63-35.64l-3.16,1.6c3.94,8.43,7.01,22.03-2.87,24.24-9.1,2.03-13.36-6.16-15.45-12.06-5.71-16.14-21.39-21.3-33.24-14.44-19.12,11.07-37.57,45.09-37.57,62.5,17.31,17.96,41.28,16.46,72.24,11.11,6.57-16.57,22.09-25.74,26.68-37.31ZM577.44,215.75c-9.72-2.55-15.56-12.4-13.04-22.01,2.52-9.6,12.44-15.32,22.16-12.77,9.72,2.55,15.56,12.4,13.04,22.01-2.52,9.6-12.44,15.32-22.16,12.77Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-2" data-name="Stripes">
					<path
						className={styles.cls14}
						d="M619.91,201.71c-7.23-.52-11.31-7.75-15.99-16.04-5.19-9.19-11.07-19.6-22.64-23.47l.86-2.58c12.57,4.21,18.72,15.1,24.15,24.72,4.73,8.37,8.46,14.98,14.93,14.67,3.45-.17,5.04-.73,6.25-4.26,2.58-7.53-1.59-16.58-5.87-23l2.59-.81c3.53,6.4,9.22,16.76,5.51,25.37-1.57,3.65-4.06,5.21-8.35,5.42-.5.02-.98.02-1.45-.02Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls14}
						d="M593.76,248.71c-8.55-.6-16.89-2.11-24.73-4.53-12.64-3.89-22.78-9.9-31.67-17.93.3-1.52,1.24-4.23,1.7-5.61,5.96,4.85,19.38,14.76,31.52,18.5,13.35,4.11,24.96,6.02,39.88,4.54-.17.84-1.65,3.53-2.07,4.67-6.2.51-8.57.79-14.64.36Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls14}
						d="M583.39,179.14c-10.71-1-20.24,6.8-21.23,17.39-.99,10.59,6.91,20.03,17.63,21.04s20.24-6.8,21.23-17.39c.99-10.59-6.91-20.03-17.63-21.04ZM580.03,214.94c-9.26-.87-16.1-9.02-15.25-18.16.86-9.15,9.09-15.88,18.36-15.01s16.1,9.02,15.25,18.16c-.86,9.15-9.09,15.88-18.36,15.01Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Winner_Bronze_Mid-2" data-name="Kitty_Winner_Bronze_Mid">
					<path className={styles.cls4}
					      d="M635.57,283.25c6.84-14.88-16.93-15.67-18-17.63-5.01-9.22-3.19-43.7-20.33-75.22-6.62-12.18-39.98-5.51-26.17,22.27,9.68,19.49,27.67,46.92,36.05,67.28,3.38,8.21,27.15,6.13,28.45,3.31Z"/>
					<g>
						<path className={styles.cls13}
						      d="M585.82,174.02c4.99,9.94,31.54,20.21,41.69,17.59,4.34-4.9,3.39-12.62,2.33-16.15-.68-2.29-1.71-1.28-2.84-.65,1.81,6.03,2.49,12.09.24,15.26-9.5.57-30.78-16.14-39.46-18.04-1.76-.39-1.14,1.06-1.96,1.98Z"/>
						<g>
							<circle className={styles.cls5} cx="629.14" cy="207.16" r="12.56"/>
							<ellipse className={styles.cls1} cx="627.14" cy="192.14" rx="1.51" ry="2.92"
							         transform="translate(-19.92 84.75) rotate(-7.61)"/>
							<circle className={styles.cls1} cx="630.19" cy="205.68" r="12.56"/>
						</g>
					</g>
					<path className={styles.cls11}
					      d="M581.01,231.03c6.32-2.11,19.9-3.11,25.93,4.13,6.96,8.36,5.21,23.18-6.75,32.92-11.21,9.13-18.19-35.32-18.19-35.32-.34-.55-.99-1.62-.99-1.74Z"/>
					<path className={styles.cls4}
					      d="M552.36,259.06c3.51-9.16,10.02-16.68,19.22-22.62,7.69-4.96,25.42-7.99,32.21-.62,1.17,1.27,1.96,2.75,2.59,4.26,4.69,11.25.02,24.69-11.12,32.05,4.75,1.13,10.93,2.79,11.1,9.48.28,11.32-25.49,9.95-29.06,3.1-1.7-3.25-.34-6.35.67-9.43-1.87,2.98-5.2,3.91-9.73,5.89-4.54,1.98-12.31.34-15.15-1.78-4.57-3.42-5.42-8.05-.72-20.33Z"/>
					<g>
						<g>
							<path className={styles.cls9}
							      d="M695.76,97.13c-12.89,4-26.1,10.86-32.86,21.96-.33.54.61,1.02.94.48,6.61-10.86,19.61-17.59,32.23-21.51.64-.2.33-1.13-.32-.93Z"/>
							<path className={styles.cls9}
							      d="M704.14,113.3c-13.58,2.15-26.38,6.13-37.87,12.93-.57.34,0,1.17.58.83,11.4-6.76,24.12-10.7,37.6-12.83.67-.11.35-1.04-.31-.93Z"/>
							<path className={styles.cls9}
							      d="M704.86,126c-12.12-.31-23.9,1.95-35.42,4.84-.66.16-.34,1.1.31.94,11.43-2.86,23.11-5.11,35.14-4.8.69.02.66-.95-.03-.97Z"/>
						</g>
						<path className={styles.cls3}
						      d="M585.73,74.8c2.42,9.08,4.77,17.51,5.31,18.94,1.12,2.97.85,3.06,3.46,5.02,3.42,2.58,42.86-6.77,46.48-15.88-8.61-8.81-25.49-20.46-35.29-24.77-7.03-3.09-18.15-8.24-25.84-8.11-.51,0,2.74,12.96,5.89,24.8Z"/>
						<g>
							<path className={styles.cls4}
							      d="M571.67,133.06c41.8-36.75,16.66-44.32,3.65-45.87,3.83-2.28,7.84-4.29,11.88-5.99,7.94,3.36,32.19,11.64,60.42,2.14,6.47,5.59,11.42,13.22,16.33,20.27,5.62,8.07,13.14,8.35,14.13,26.58,1.14,21.06-12.89,28.04-21.94,34.17-10.76,7.29-24.94,12.16-37.7,15.01-8.51,1.9-24.45,2.22-33-.38-17.65-5.37-27.89-19.2-34.43-31.68-.32-.61-.61-1.22-.9-1.82,11.33-4.84,21.55-12.42,21.55-12.42Z"/>
							<path className={styles.cls8}
							      d="M552.6,103.17c3.15-6.71,8.27-14.93,22.72-15.97,13.01,1.55,38.15,9.12-3.65,45.87,0,0-10.22,7.58-21.55,12.42-7.19-15.36-3.69-29.15,2.49-42.31Z"/>
							<path className={styles.cls9}
							      d="M587.2,81.2c10.28-4.33,20.77-6.69,29.13-6.44,9.41.28,22.45,1.47,30.05,7.55.42.33.83.68,1.23,1.03-28.23,9.5-52.48,1.22-60.42-2.14Z"/>
						</g>
						<path className={styles.cls6}
						      d="M621.67,116.25c4.61,7.15,1.29,16.64-7.4,21.18-8.7,4.54-19.49,2.43-24.09-4.73-4.61-7.15-1.29-16.64,7.4-21.18,8.7-4.54,19.48-2.43,24.09,4.72Z"/>
						<path className={styles.cls6}
						      d="M654.17,98.62c4.11,5.43,4.12,11.56.03,13.68-4.09,2.12-10.73-.56-14.84-6-4.11-5.43-4.12-11.55-.03-13.68,4.09-2.12,10.73.57,14.84,6Z"/>
						<g>
							<path className={styles.cls9}
							      d="M594.31,155.73c10.63-7.95,23.78-14.68,36.69-14.27.63.02.48,1-.15.97-12.64-.4-25.55,6.24-35.96,14.02-.53.4-1.11-.32-.58-.72Z"/>
							<path className={styles.cls9}
							      d="M602.69,170.43c9.52-9.41,20.08-17.3,32.2-22.73.6-.27.95.6.35.87-12.03,5.39-22.51,13.23-31.96,22.58-.47.47-1.05-.25-.58-.72Z"/>
							<path className={styles.cls9}
							      d="M612.62,177.44c6.68-9.53,15.26-17.49,24.2-24.95.51-.43,1.09.29.58.72-8.87,7.4-17.38,15.29-24.01,24.75-.38.54-1.15.02-.77-.52Z"/>
						</g>
						<g>
							<path className={styles.cls12}
							      d="M618.35,117.58c4.18,6.4,4.26,13.8.18,16.54-4.09,2.74-10.79-.22-14.98-6.62-4.19-6.39-4.26-13.8-.18-16.54,4.08-2.74,10.79.22,14.98,6.61Z"/>
							<path className={styles.cls10}
							      d="M608.28,120.39c2.1,3.2,5.43,4.71,7.44,3.36,2.01-1.35,1.94-5.04-.16-8.25-2.1-3.21-5.43-4.71-7.44-3.36-2.01,1.35-1.94,5.04.16,8.25Z"/>
						</g>
						<g>
							<path className={styles.cls12}
							      d="M652.73,99.36c3.44,5.38,4.23,11.11,1.77,12.81-2.47,1.7-7.26-1.29-10.7-6.67-3.44-5.38-4.23-11.11-1.77-12.81,2.47-1.7,7.26,1.29,10.7,6.66Z"/>
							<path className={styles.cls10}
							      d="M646.26,100.49c1.72,2.7,4.11,4.21,5.32,3.37,1.22-.84.8-3.7-.92-6.4-1.73-2.7-4.11-4.21-5.32-3.37-1.21.84-.8,3.7.93,6.39Z"/>
						</g>
						<path className={styles.cls9}
						      d="M653.72,139.65c.46.45.89.98,1.04,1.13.94.91,2.05,1.74,3.35,2.02,2.55.55,5.81-.53,7.58-2.53,2.1-2.37,2.14-4.97,1.1-7.82-.51-1.41-.92-3.18-1.8-4.4-.2-.27-.58-.55-.72-.85-.18-.38.25-1.39.71-1.47.93-.16,1.39,1.07,1.65,1.74.41,1.05.88,1.93,1.42,2.92.98,1.81,2.14,4.15,4.24,4.86,1.53.52,3.19-.02,3.7-1.64.26-.83.08-1.62-.16-2.42-.07-.23-.6-1.75-.09-1.89.86-.23,1.09,1.68,1.2,2.19.46,2.13-.08,5.31-2.75,5.52-1.1.09-2.21-.36-3.14-.91-.86-.51-2-1.03-2.36-2.04.52,2.68-.16,5.06-1.98,7.11-.81.91-1.85,1.65-3,2.18-2.72,1.26-5.57,1.21-8.14-.27-.71-.41-4.41-3.09-3.12-4.08.28-.21.8.18,1.28.65Z"/>
						<path className={styles.cls7}
						      d="M663.64,116.37c-1.02.54-1.95,1.35-3.03,2.07-1.72,1.14-4.77,2.18-5.92,3.71-2.75,3.67,2.56,5.48,6.38,5.59,4.32.14,11.47-3.11,9.65-8.64-.62-1.87-2.09-3.01-4.03-3.29-1.19-.17-2.15.1-3.04.57Z"/>
					</g>
					<g>
						<path className={styles.cls8}
						      d="M534.81,118.66c7.71,5.94,14.95,11.39,16.29,12.23,2.78,1.74,2.63,1.98,5.92,1.96,4.33-.03,20.98-34.89,17.98-44.63-10.23-2.79-24.78,1.62-35.23,4.24-7.49,1.88-19.51,4.61-25.46,9.54-.4.33,10.44,8.92,20.49,16.67Z"/>
						<path className={styles.cls7} d="M567.21,99.42s-24.11-1.02-41.5,5.56l29.22,21.15,12.28-26.71Z"/>
					</g>
				</g>
				<g id="Stripe-2" data-name="Stripe">
					<path
						className={styles.cls14}
						d="M557.8,156.03c16.6-33.05,60.33-65.6,91.01-70.93,2.35-.41-11.83-12.85-19.7-12.35-20.33,1.3-77.78,42.38-80.45,69.69-.4,4.06,7.59,16.69,9.14,13.59Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
			</g>
		</svg>
	);
};