import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import produce from "immer";
import classnames from "classnames";
import styles from "./OnGardSetup.scss";
import concat from "lodash/concat";
import findIndex from "lodash/findIndex";
import forEach from "lodash/forEach";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import split from "lodash/split";
import TextBox from "components/common/TextBox";
import logger from "utils/logger";

const blankTask = {
    IsEnabled: true,
}

const inputsTask = {
    IsEnabled: true,
    MaxPerGame: "",
    RepInputAmount: "",
    RepInputAmount1: "",
    RepInputAmount2: ""
}

export default function OnGardSetup(props) {
    const [hasUnsetProps, setHasUnsetProps] = useState(false);
    useEffect(() => {
        // Check for unset defaults
        if (props.data.addOnConfig?.length > props.data.config?.Tasks.length ) {
            logger.log("OnGard - Task Differences");
            setHasUnsetProps(true);
        } else {
            setHasUnsetProps(false);
        }
    }, [props.data, props.data.config.Tasks.length]);

    useEffect(() => {
        if(hasUnsetProps) {
            setAllDefaults();
        }
    }, [hasUnsetProps]);

    const setAllDefaults = () => {
        if (props.data.addOnConfig?.length > props.data.config?.Tasks.length ) {
            const taskList = map(props.data.config.Tasks, (task) => {
                return {
                    ...task,
                }
            });
            forEach(props.data.addOnConfig, challenge => {
                const { task, index } = getOrCreateTask(challenge.onGardTaskId, !!challenge.configuration);
                if (challenge.configuration) {
                    for (let x = 0; x < challenge.configuration.repInputs.length; x++) {
                        const repInput = challenge.configuration.repInputs[x];
                        const n = getRepInputName(repInput.id);
                        if (!index) {
                            task[n] = repInput.defaultValue;
                        } else if (!task[n]) {
                            task[n] = repInput.defaultValue;
                        }
                    }
                }
                // Add it or update it
                if(index < 0) {
                    taskList.push(task);
                } else {
                    taskList[index] = task;
                }
            });
            const newData = produce(props.data, (data) => {
                data.config.Tasks = [
                    ...taskList
                ];
                return data
            });

            // push it all up to the game
            props.onChange(newData);
        }
    }

    const getTaskIndex = (onGardTaskId) => {
        return findIndex(props.data.config.Tasks, {OnGardTaskId : onGardTaskId})
    }

    const getOrCreateTask = (onGardTaskId, hasConfig) => {

        const index = getTaskIndex(onGardTaskId);
        let task = {
            ...blankTask,
            OnGardTaskId: onGardTaskId,
        };

        if(index > -1) {
            task = {
                ...props.data.config.Tasks[index]
            }
        } else if (hasConfig) {
            task = {
                ...inputsTask,
                OnGardTaskId: onGardTaskId
            }
        }

        return { task, index }
    }

    const getRepInputName = (repInputId) => {
        switch(repInputId) {
            case "input1":
                return "RepInputAmount1";
            case "input2":
                return "RepInputAmount2";
            default:
                return "MaxPerGame";
        }
    }

    const handleChange = ({name, value}, onGardTaskId, repInputId) => {
        let index = getTaskIndex(onGardTaskId);
        const n = getRepInputName(name);

        if (index === -1) {
            index = props.data.config.Tasks.length;
        }

        const newData = produce(props.data, (data) => {
            data.config.Tasks[index] = {
                ...blankTask,
                ...data.config.Tasks[index],
                OnGardTaskId: onGardTaskId,
                [n]: value,
            }
            return data
        });
        props.onChange(newData);
    }

    const renderChallenge = (challenge) => {
        let descriptionArray = [challenge.description];
        if (challenge.configuration) {
            for (let x = 0; x < challenge.configuration.repInputs.length; x++) {
                const lastDescription = descriptionArray.pop();
                const repInput = challenge.configuration.repInputs[x];
                const taskIndex = getTaskIndex(challenge.onGardTaskId);
                const n = getRepInputName(repInput.id)
                const arrayEnd = split(lastDescription, `{${repInput.id}}`);
                const input = (
                    <div className={styles.inputs}>
                        <TextBox
                            inputType="number"
                            maxLength={repInput.maxLength}
                            pattern={repInput.pattern}
                            min={repInput.minValue || 1}
                            max={repInput.maxValue || 999}
                            required
                            autoComplete="off"
                            name={repInput.id}
                            value={taskIndex >= 0 ? props.data.config.Tasks[taskIndex][n] : null }
                            disabled={props.data.hasStarted}
                            onChange={(data) => handleChange(data, challenge.onGardTaskId)}
                        />
                    </div>
                );
                arrayEnd.splice(1, 0, input);
                descriptionArray = concat(descriptionArray, arrayEnd);
            }
        }
        return(
            <li className={classnames({
                [styles.darkGreen]: challenge.displayGroup === "Greenline",
            })}>
                {map(descriptionArray, d => {
                    if(!(d.type && (d.type === "div"))){
                        return <span dangerouslySetInnerHTML={{ __html: d }} />
                    } else {
                        return d;
                    }
                })}
                <span className="text-sm margin-left-x-sm">[{challenge.displayGroup}]</span>
            </li>
        );
    }

    return (
        <div className={classnames(styles.root, "flex flex-wrap")}>
            {map(groupBy(props.data.addOnConfig, "pointValue"), con => (
                <div className={classnames(styles.points, "flex-1")}>
                    <div className={styles.banner}>Worth {con[0].pointValue} Points</div>
                    <ul className={styles.challenges}>
                        {map(con, c => renderChallenge(c))}
                    </ul>
                </div>
            ))}
            <div className="full-width flex spaced-content text-x-sm margin-bottom-md">
                <div className="flex-1 text-center">
                    [Clinic] - Clinic must acknowledge completion through their Greenline Portal
                </div>
                <div className={classnames(styles.darkGreen, "flex-1 text-center")}>
                    [Greenline] - Greenline will compute the total # of points applied for each task, for each day during the period of the game
                </div>
            </div>
        </div>
    );
}

OnGardSetup.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}
