import React, { useEffect, useRef } from "react";
import {connect} from "react-redux";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import * as UserActions from "actions/UserActions";
import CorporateGroupDashboard from "components/provider/pages/CorporateGroupDashboard";
import NHODashboard from "components/provider/pages/NHODashboard";
import ProviderDashboard from "components/provider/pages/ProviderDashboard";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {getNodeId} from "utils/AdminData";
import getProviderId from "utils/getProviderId";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import { NHO, NHO_HQ } from "constants/OrgNodeTypes";

function ProviderHomepage(props) {
    const wasLoggedIn = useRef(props.isLoggedIn);

    useEffect(() => {
        const shouldLoadProfile = props.isLoggedIn && !props.userProfile;
        const didReLogin = !props.isLoggedIn && wasLoggedIn;

        if (shouldLoadProfile || didReLogin) {
            props.loadCurrentUser();
            wasLoggedIn.current = true;
        }
    }, [props.isLoggedIn]);

    if(!props.nodeId) {
        return <SpinnerTakeover show/>;
    }

    return (
        <>
            {props.isCorporateGroup ? (
                <CorporateGroupDashboard
                    location={props.location}
                    providerId={props.providerId}
                    nodeId={props.nodeId}
                    match={props.match}
                />
            ) : (props.node && (props.node.nodeType === NHO || props.node.nodeType === NHO_HQ)) ? (
                <NHODashboard
                    providerId={props.providerId}
                    nodeId={props.nodeId}
                    match={props.match}
                />
            ) : (
                <ProviderDashboard
                    location={props.location}
                    providerId={props.providerId}
                    nodeId={props.nodeId}
                    match={props.match}
                />
            )}
            <SpinnerTakeover show={!props.userProfile || !props.node} />
        </>
    );
}

export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const providerId = getProviderId(params, userProfile);
        const nodeId = getNodeId(state, ownProps);
        const node = state.entities.nodes?.[nodeId];
        const corporateGroupProviderIds = flatMap(filter(state.entities.providers, {isCorporateGroup: true}), "id");
        const isCorporateGroup = !!includes(corporateGroupProviderIds, node?.providerId);
        //Permissions
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);

        return {
            userProfile,
            providerId,
            nodeId,
            node,
            isCorporateGroup,
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && providerId && nodeId),
            territoryId: params.territoryId,
            districtId: params.districtId,
            regionId: params.regionId,
            isLoggedIn: state.user.isLoggedIn,


            // Permissions
            canViewCorporateGroupClinic
        };
    },
    (dispatch) => ({
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
    }),
)(ProviderHomepage);
