import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router";
import styles from "./AdminWellnessPlans.scss";
import * as QueryString from "query-string";
import AccessDenied from "components/common/AccessDenied";
import ClinicBase from "components/layout/ClinicBase";
import ClinicWellnessPlans from "components/wellness/widgets/ClinicWellnessPlans";
import GreenlineWellnessPlans from "components/greenlineWellness/GreenlineWellnessPlans";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {clinicHasWellness, getClinicInfo} from "utils/ClinicData";
import { GREENLINE_WELLNESS, PREMIER_PET_CARE_PLAN } from "constants/ProviderIds";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

function AdminWellnessPlans(props)  {

    const handleSelectProvider = (providerId) => {
        props.history.push(`plans?providerId=${providerId}`)
    };

    return (
        <ClinicBase
            clinicId={props.clinicId}
            pageTitle="Wellness Plan Setup"
            collapsible
            searchConfig={SearchTypes.TOOLBAR_CLINIC_SEARCH}
        >
            <div className={styles.root}>
                {!(props.clinicHasWellness && props.canViewClinicWellnessPlans) ? (
                    <AccessDenied/>
                ) : (props.selectedProvider === PREMIER_PET_CARE_PLAN) ? (
                    <ClinicWellnessPlans clinicId={props.clinicId} />
                ) : props.selectedProvider === GREENLINE_WELLNESS ? (
                    <GreenlineWellnessPlans clinicId={props.clinicId} />
                ) : (
                    <div className="flex flex-centered height-100-perc full-width">
                        <div>
                            <h2 className="text-center padding-bottom-md">Select Wellness Provider</h2>
                            <div className="flex height-100-perc full-width spaced-content flex-centered">
                                <div
                                    onClick={() => handleSelectProvider(GREENLINE_WELLNESS)}
                                    className={styles.tile}
                                >
                                    Greenline Wellness
                                </div>
                                <div
                                    onClick={() => handleSelectProvider(PREMIER_PET_CARE_PLAN)}
                                    className={styles.tile}
                                >
                                    Premier Wellness
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </ClinicBase>
    )
}

const connector = connect(
    (state, ownProps) => {
        const search = QueryString.parse(ownProps.location.search);
        const providerId = parseInt(search?.providerId) || null;

        const clinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, clinicId);
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const wellnessProviders = clinicInfo?.programs?.Wellness?.providers || [];
        let selectedProvider = providerId;
        if (wellnessProviders.length === 1) {
            selectedProvider = wellnessProviders[0];
        }
        return {
            clinicId,
            canViewClinicWellnessPlans,
            clinicHasWellness: clinicHasWellness(state, clinicId),
            selectedProvider,
        }
    }
);

export default compose(
    connector,
    withRouter,
)(AdminWellnessPlans);
