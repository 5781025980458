// Statuses sent to the API
export const CANCEL = "Cancel";
export const DECLINE = "Decline";
export const ENROLL = "Enroll";

// Statuses received from the API
export const CANCELED = "Canceled";
export const DECLINED = "Declined";
export const ELIGIBLE = "Eligible";
export const ENROLLED = "Enrolled";
export const INELIGIBLE = "Ineligible";

// I'm not sure why they don't match.
