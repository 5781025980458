exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".OnGardTaskCountForm__taskCount{font-size:1.5em;font-weight:600;text-align:center}.OnGardTaskCountForm__taskCount .CheckboxInput__root{display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;width:100%}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/widgets/OnGardTaskCountForm.scss"],"names":[],"mappings":"AAAA,gCAAW,gBAAgB,gBAAgB,iBAAiB,CAAC,qDAAyC,oBAAoB,aAAa,qBAAqB,uBAAuB,UAAU,CAAC","file":"OnGardTaskCountForm.scss","sourcesContent":[".taskCount{font-size:1.5em;font-weight:600;text-align:center}.taskCount :global(.CheckboxInput__root){display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;width:100%}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"taskCount": "OnGardTaskCountForm__taskCount"
};