import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import classnames from "classnames";
import * as styles from "./ChooseClinics.scss";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import DropdownSearch from "components/common/DropdownSearch";
import TextBox from "components/common/TextBox";
import ToggleSwitch from "components/common/ToggleSwitch";
import {Link} from "react-router-dom";
function ChooseClinics(props) {
    const {data, clinics} = props;
    const selectedClinicIds = flatMap(data.clinics, "clinicId");
    const CLINIC_OPTIONS = map(clinics, c => ({ name: c.clinicName, value: c.clinicId }));
    const handleChange = ({ name, value }, isSelected=false, id=null) => {
        let clinicId = Number(id);
        if (name === "clinicId") {
            clinicId = Number(value);
        }
        let newClinics = data.clinics ? cloneDeep(data.clinics) : [];
        const index = findIndex(newClinics, {clinicId: clinicId});
        let newClinic;
        if (name === "select" || name === "clinicId") {
            if (isSelected) {
                newClinics.splice(index, 1);
            } else {
                const clinic = clinics[clinicId];
                const clinicEmail = clinic.clinicEmail ? clinic.clinicEmail : "";
                const clinicAlias = clinic.clinicAlias || "";
                newClinic = {
                    ...newClinics[clinicId],
                    clinicId: clinic.clinicId,
                    clinicName: clinic.clinicName,
                    emailList: [clinicEmail],
                    clinicAlias: (newClinics[clinicId] && newClinics[clinicId].clinicAlias) ? newClinics[clinicId].clinicAlias : clinicAlias,
                }
                newClinics.push(newClinic);
            }
        } else if ("emailList" === name) {
            const emailList = value.split(",");
            newClinic = {
                ...newClinics[index],
                [name]: emailList
            }
            newClinics.splice(index, 1, newClinic);
        } else {
            newClinic = {
                ...newClinics[index],
                [name]: value
            }
            newClinics.splice(index, 1, newClinic);
        }
        props.onChange({name: "clinics", value: newClinics});
    };
    const getClinicAlias = (formClinicData, apiClinicData) => {
        return formClinicData && formClinicData.clinicAlias ? formClinicData.clinicAlias : apiClinicData.clinicAlias;
    }
    const NoClinicsMessage = "There are currently no clinics opted into Greenline Games™, please view your Manage Invites page to invite a clinic.";
    const SearchClinicsMessage = "Add a clinic using the search above";
    const renderFullTable = (visibleClinics) => {
        const noAvailableClinics = isEmpty(clinics);
        const hasClinics = !isEmpty(visibleClinics);
        return (
            <table className={classnames("table full-width", styles.fullTable)}>
                <thead>
                <tr>
                    <th/>
                    <th>Clinic</th>
                    <th>Alias</th>
                    <th>Email*</th>
                </tr>
                </thead>
                <tbody>
                {hasClinics ?
                    map(orderBy(visibleClinics, "clinicName"), (clinic) => {
                        const isSelected = includes(selectedClinicIds, clinic.clinicId)
                        const selectedClinic = find(data.clinics, {clinicId: clinic.clinicId});
                        return (
                            <tr key={clinic.clinicId} className={classnames({
                                [styles.deselected] : !isSelected
                            })}>
                                <td className={styles.toggle}>
                                    <ToggleSwitch
                                        id="select"
                                        updateState={(name, value) => handleChange({name, value}, isSelected, clinic.clinicId)}
                                        force={isSelected}
                                        disabled={props.readOnly}
                                        noMargin
                                    />
                                </td>
                                <td className={styles.clinicName}>
                                    {props.isAdmin ? (
                                    <Link to={`/admin/clinic/${clinic.clinicId}`} className="text-primary flex-1 text-right">{clinic.clinicName}</Link>
                                    ) : clinic.clinicName}
                                    <br />
                                    {(clinic?.city && clinic?.state) && (<span className="text-sm">({clinic?.city}, {clinic?.state})</span>)}
                                </td>
                                <td style={{minWidth: "130px", maxWidth: "130px"}}>
                                    <TextBox
                                        onChange={(data) => handleChange(data, isSelected, clinic.clinicId)}
                                        name="clinicAlias"
                                        value={getClinicAlias(selectedClinic, clinic)}
                                        disabled={!isSelected || props.readOnly}
                                    />
                                </td>
                                <td>
                                    <TextBox
                                        onChange={(data) => handleChange(data, isSelected, clinic.clinicId)}
                                        name="emailList"
                                        value={isSelected ? selectedClinic.emailList : clinic.clinicEmail}
                                        disabled={!isSelected || props.readOnly}
                                    />
                                </td>
                            </tr>
                        );
                    })
                    :
                    (<tr className={styles.noClinics}>
                        <td colSpan="4">{noAvailableClinics ? NoClinicsMessage : SearchClinicsMessage}</td>
                    </tr>)
                }
                </tbody>
            </table>
        )
    }
    const renderMobileView = (visibleClinics) => {
        const hasClinics = !isEmpty(visibleClinics);
        return (
            <table className={classnames("table full-width", styles.mobileView)}>
                <thead>
                <tr>
                    <th>Clinics</th>
                </tr>
                </thead>
                <tbody>
                {hasClinics ?
                    map(orderBy(visibleClinics, "clinicName"), (clinic) => {
                        const isSelected = includes(selectedClinicIds, clinic.clinicId)
                        const selectedClinic = find(data.clinics, {clinicId: clinic.clinicId});
                        return (
                            <tr key={clinic.clinicId} className={classnames({
                                [styles.deselected] : !isSelected
                            })}>
                                <td>
                                    <div className="flex margin-bottom-sm">
                                        <div className="margin-right-x-sm flex-none">
                                            <ToggleSwitch
                                                id="select"
                                                updateState={(name, value) => handleChange({name, value}, isSelected, clinic.clinicId)}
                                                force={isSelected}
                                                disabled={props.readOnly}
                                                noMargin
                                            />
                                        </div>
                                        <div>
                                            {clinic.clinicName}<br />
                                            {(clinic?.city && clinic?.state) && (<span className="text-sm">({clinic?.city}, {clinic?.state})</span>)}
                                        </div>
                                    </div>
                                    <TextBox
                                        onChange={(data) => handleChange(data, isSelected, clinic.clinicId)}
                                        name="clinicAlias"
                                        value={getClinicAlias(selectedClinic, clinic)}
                                        disabled={!isSelected || props.readOnly}
                                        label="Alias"
                                        alignedLeft
                                    />
                                    <TextBox
                                        onChange={(data) => handleChange(data, isSelected, clinic.clinicId)}
                                        name="emailList"
                                        value={isSelected ? selectedClinic.emailList : clinic.clinicEmail}
                                        disabled={!isSelected || props.readOnly}
                                        label="Email"
                                        alignedLeft
                                    />
                                </td>
                            </tr>
                        );
                    })
                    :
                    (<tr className={styles.noClinics}><td colSpan="2">Add a clinic using the search above</td></tr>)
                }
                </tbody>
            </table>
        )
    }

    const renderClinicsTable = () => {
        let visibleClinics = {...clinics};
        if (props.readOnly && !!selectedClinicIds?.length) {
            // Get the clinic info from the loaded clinics if possible
            const numberOfVisibleClinics = keys(visibleClinics)?.length;
            if (!!numberOfVisibleClinics) {
                visibleClinics = filter(clinics, clinic => includes(selectedClinicIds, clinic.clinicId));
            }
            // Get the clinic info from game if the clinic info is incomplete
            if (numberOfVisibleClinics !== selectedClinicIds?.length) {
                visibleClinics = data.clinics;
            }
        }
        return(
            <>
                {renderFullTable(visibleClinics)}
                {renderMobileView(visibleClinics)}
                <div className="text-sm margin-top-x-sm">* NOTE: If you would like the weekly & progress emails sent to multiple recipients, please separate each email with a comma.</div>
            </>
        );
    }
    return (
        <div className={classnames(styles.root, "full-width")}>
            <div className="margin-bottom-x-sm">Choose Clinics:</div>
            {/* Here is where you update number of clinics that triggers search/add feature */}
            {!!keys(clinics).length > 10 ? (
                <>
                    <DropdownSearch
                        name="clinicId"
                        value={null}
                        placeholder="Search Clinics"
                        options={orderBy(CLINIC_OPTIONS, c => {return includes(selectedClinicIds, c.value)})}
                        disabledOptions={selectedClinicIds}
                        onChange={(data) => handleChange(data, false )}
                        disabled={props.readOnly}
                    />
                    <div className="margin-top-md">
                        <div className="margin-bottom-x-sm">Selected Clinics:</div>
                        {renderClinicsTable(selectedClinicIds)}
                    </div>
                </>
            ) : (
                renderClinicsTable()
            )}
        </div>
    );
}
ChooseClinics.propTypes = {
    data: PropTypes.object,
    onChange: PropTypes.func.isRequired, // Callback which receives current form data
    readOnly: PropTypes.bool,
}
export default connect(
    (state, ownProps) => {
        return {
            isAdmin: !!ownProps?.location?.pathname?.includes("/admin"),
            clinics: state.entities.providerGamesClinics,
        }
    },
)(ChooseClinics);