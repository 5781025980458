exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".InvoiceDetails__invoicePreview{margin:20px}.InvoiceDetails__invoicePreview img{border:2px solid #d5d5d5;width:100%}.InvoiceDetails__directions{font-size:1.2em}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/common/InvoiceDetails/InvoiceDetails.scss"],"names":[],"mappings":"AAAA,gCAAgB,WAAW,CAAC,oCAAoB,yBAAyB,UAAU,CAAC,4BAAY,eAAe,CAAC","file":"InvoiceDetails.scss","sourcesContent":[".invoicePreview{margin:20px}.invoicePreview img{border:2px solid #d5d5d5;width:100%}.directions{font-size:1.2em}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"invoicePreview": "InvoiceDetails__invoicePreview",
	"directions": "InvoiceDetails__directions"
};