exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".RichText__root.RichText__disabled .quill{background-color:rgba(207,207,207,0.5);cursor:not-allowed}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/common/RichText/RichText.scss"],"names":[],"mappings":"AAAA,0CAA+B,uCAAuC,kBAAkB,CAAC","file":"RichText.scss","sourcesContent":[".root.disabled :global(.quill){background-color:rgba(207,207,207,0.5);cursor:not-allowed}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "RichText__root",
	"disabled": "RichText__disabled"
};