import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import find from "lodash/find";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as WellnessApi from "api/WellnessApi";
import DropdownSearch from "components/common/DropdownSearch";
import { handleErrorResponse } from "utils/request";

export default function SearchPetOwners(props) {
    const [petOwners, setPetOwners] = useState([]);
    const [loadingPetOwners, setLoadingPetOwners] = useState(false);

    const handleSelectOwner = ({ name, value }) => {
        const selectedPetOwner = find(petOwners, {petOwnerId: value});
        props.onChange({ name, value }, selectedPetOwner);
    }

    const handleSearchPetOwners = useCallback(debounce((search) => {
        setLoadingPetOwners(true);
        WellnessApi.getPetOwners(props.clinicId, {limit: 15, search: search})
            .then((res) => {
                setPetOwners(res.body?.data);
                setLoadingPetOwners(false);
            })
            .catch((error) => {
                setLoadingPetOwners(false);
                handleErrorResponse("loading clients", error);
            });
    }, 500), []);

    const petOwnerOptions = map(orderBy(petOwners, ["petOwnerFirstName", "petOwnerLastName"], ["asc", "asc"]), owner => {
        return {
            dropdownName: (
                <>
                    {owner.petOwnerFirstName} {owner.petOwnerLastName} {!!owner.petOwnerEmail ? `(${owner.petOwnerEmail})` : ""}
                </>
            ),
            name: `${owner.petOwnerFirstName} ${owner.petOwnerLastName} ${!!owner.petOwnerEmail ? `(${owner.petOwnerEmail})` : ""}`,
            value: owner.petOwnerId,
        }
    });

    return (
        <DropdownSearch
            boldPimsOptions={[null]}
            defaultSearch={props.defaultSearch}
            disabledOptions={[props.value || null]}
            firstOption="Select a Client"
            forceFocus={props.autofocus}
            loading={loadingPetOwners}
            name={props.name}
            onChange={handleSelectOwner}
            onSearchChange={handleSearchPetOwners}
            options={petOwnerOptions}
            placeholder="Search Clients"
            value={props.value}
        />
    );
}

SearchPetOwners.defaultProps = {
    autofocus: false,
    boldPimsOptions: [null],
    defaultSearch: undefined,
    disabled: false,
    disabledOptions: [null],
    value: undefined,
};

SearchPetOwners.propTypes = {
    autofocus: PropTypes.bool,
    boldPimsOptions: PropTypes.array,
    clinicId: PropTypes.number.isRequired,
    defaultSearch: PropTypes.string,
    disabled: PropTypes.bool,
    disabledOptions: PropTypes.array,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
};
