
export const CLINIC_MANAGEMENT = "CLINIC_MANAGEMENT";
export const CLINIC_TAGS = "CLINIC_TAGS";
export const DVM_COMMISSIONS = "DVM_COMMISSIONS";
export const INVOICE_LINE_ITEMS = "INVOICE_LINE_ITEMS";
export const MAPPING = "MAPPING";
export const MIN_REPROCESSING_JOBS_CONTROLS = "MIN_REPROCESSING_JOBS_CONTROLS";
export const NOTIFICATION_MANAGEMENT = "NOTIFICATION_MANAGEMENT";
export const PAR = "PAR";
export const PIMS_USERS = "PIMS_USERS";
export const PRODUCT_OVERRIDES = "PRODUCT_OVERRIDES";
export const PRODUCT_TAGS = "PRODUCT_TAGS";
export const PROGRAM_SETUP = "PROGRAM_SETUP";
export const REPROCESSING_JOBS = "REPROCESSING_JOBS";
export const ROLE_MANAGEMENT = "ROLE_MANAGEMENT";
export const USER_MANAGEMENT = "USER_MANAGEMENT";
export const VOUCHER_MANAGEMENT = "VOUCHER_MANAGEMENT";
