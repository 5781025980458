import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import * as Sentry from "@sentry/react";
import moment from "moment";
import classnames from "classnames";
import styles from "./ClinicTable.scss";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import includes from "lodash/includes";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as AdminApi from "api/AdminApi";
import * as ClinicApi from "api/ClinicApi";
import * as AdminActions from "actions/AdminActions";
import * as ClinicActions from "actions/ClinicActions";
import * as CouponActions from "actions/CouponActions";
import * as UserActions from "actions/UserActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import ClinicFilters from "components/admin/widgets/ClinicFilters";
import DataTablePagination from "components/common/datatable/DataTablePagination";
import GoGreenForm from "components/admin/forms/GoGreenForm";
import Image from "components/common/Image";
import Modal from "components/common/Modal";
import BI_PREMIER_LOGO_SMALL from "components/common/branding/premier_bi_logo_small.png";
import ResultsPerPageDropdown from "components/coupons/widgets/ResultsPerPageDropdown";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import Tooltip from "components/common/Tooltip";
import { getLinkLocation } from "utils/ClinicAdminLinks";
import LocalData from "utils/LocalData";
import logger from "utils/logger";
import { downloadFile, handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission, userCanViewMappingPage } from "utils/permissions/rolesPermissions";
import { stylizeTimeString } from "utils/timeFormatter";
import toast from "utils/toast";
import { parseQueryString, setQueryStringParam, setQueryStringParams } from "utils/urls";
import * as UserPermissions from "constants/UserPermissions";

function ClinicsTable(props) {
    const didMountRef = useRef(false);
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [showGoGreenModal, setShowGoGreenModal] = useState(false);
    const [selectedProviderId, setSelectedProviderId] = useState(null);
    const [selectedClinicId, setSelectedClinicId] = useState(null);

    const parsedFilters = parseQueryString(location.search);
    const filters = !!parsedFilters?.filters ? JSON.parse(parsedFilters.filters) : [];
    const completeFilters = filter(filters, fil => fil.isComplete);
    const limit = Number(parsedFilters?.limit) || 25;
    const offset = parsedFilters?.offset || 0;
    const orderDir = parsedFilters?.orderDir || "desc";
    const sortBy = parsedFilters?.orderBy || "id";
    const search = parsedFilters?.search || "";
    const page = (offset / limit) + 1;
    const totalPages = props.totalRecordCount ? Math.ceil(props.totalRecordCount / limit) : 0;
    const canGoNext = (page < totalPages);
    const canGoPrevious = (page > 1);
    
    const [tableKey, setTableKey] = useState();
    
    useEffect(() => {
        props.getProviderList();
        setTableKey(`table_${new Date().getTime()}`);
    }, []);

    const load = useCallback(debounce((data) => {
        if (props.canViewClinicManagement) {
            // Handle Search and Filters update
            const {filters, search, limit, offset, orderBy, orderDir} = data;
            const completeFilters = filter(filters, fil => fil.isComplete);
            // Only search if there was a change
            if(
                !isEqual(props.lastSearch.filters, completeFilters) ||
                props.lastSearch.search !== search ||
                props.lastSearch.limit !== limit ||
                props.lastSearch.offset !== offset ||
                props.lastSearch.orderBy !== orderBy ||
                props.lastSearch.orderDir !== orderDir
            ) {
                logger.log("NOT A MATCH -- Reload clinics")
                const searchValues = {
                    limit,
                    offset,
                    orderBy,
                    orderDir,
                    filters: [...completeFilters],
                    search,
                }

                props.loadClinicsList(searchValues);
            }
        }
    }, 1500), [props.lastSearch]);

    useEffect(() => {
        // Only update the search URL after the first rendering
        if(didMountRef.current) {
            if ((parsedFilters?.search || props.search) && parsedFilters?.search !== props.search) {
                let newQs;
                if ((props.lastSearch.search || props.search) && props.lastSearch.search !== props.search) {
                    // When the search is changed, update the url and offset
                    newQs = setQueryStringParams(location.search, {
                        search: props.search,
                        offset: 0,
                    });
                    props.history.replace(`${location.pathname}?${newQs}`);
                } else {
                    // Update URL "search" value
                    newQs = setQueryStringParam(location.search, "search", props.search);
                    props.history.replace(`${location.pathname}?${newQs}`);
                }
            }
        } else {
            didMountRef.current = true;
        }
    }, [props.search]);

    const parseUrlAndLoad = () => {
        if(parsedFilters?.search !== props.search) {
            props.setForceSearchValue(search);
        }
        load({filters, search, limit, offset, orderBy: sortBy, orderDir});
    }

    useEffect(() => {
        // If the URL query params are changed then reload the page
        parseUrlAndLoad();
    }, [location.search]);

    const handleReload = () => {
        load({filters, search, limit, offset, orderBy: sortBy, orderDir});
    }

    const goToLink = (row, link) => {
        props.history.push(getLinkLocation(link, row.clinicId));
    }
    const handleGpToUsers = (row) => {
        if(props.canViewUserManagement) {
            props.history.push(`users?clinicId=${row.clinicId}`);
        }
    };

    const impersonateClinic = (row) => {
        setLoading(true);
        props.setRecentUser({
            id: row.clinicAdminUserId,
            username: row.clinicAdminUsername,
            fullName: row.clinicAdminFullName,
            clinicId: row.clinicId,
            locationType: null,
            territoryCode: null,
            timeStamp: new Date()
        });
        Sentry.setContext("impersonating", {
            originalUserId: props.userProfile.id,
            originalUserRoles: props.userProfile.roles,
        });
        props.setUserUnknown(); // This clears the current user from state.
        // Sentry.captureMessage("Impersonation Initiated");
        AdminApi.impersonateUser(row.clinicAdminUsername)
            .then(res => {
                LocalData.setToken(res.body.token);
                props.impersonateUser(res.body.token);
                props.history.push("/");
            })
            .catch(error => {
                handleErrorResponse("attempting to impersonate", error);
                setLoading(false);
            });
    }

    const handleRowClicked = (row) => {
        props.setFromUserManagement();
        props.setRecentClinic({
            id: row.clinicId,
            name: row.clinicName,
            image: row.imageUrl,
            timeStamp: new Date()
        });
        // props.history.push(getLinkLocation("settings", row.clinicId));
        goToLink(row, "dashboard");
    };

    const handleParClick = (evt, clinicId) => {
        evt.stopPropagation();
        props.history.push(getLinkLocation("par", clinicId));
    }

    const handleStrayCouponClick = (evt, clinicId) => {
        evt.stopPropagation();
        props.history.push(getLinkLocation("strayCoupons", clinicId));
    }

    const handleExportExcel = async () => {
        if(props.canViewClinicManagement) {
            toast.success("Generating Export...");
            const searchData = {
                ...props.view,
                filters: [...completeFilters],
                search,
                offset: 0,
                limit: 99999,
                orderBy: sortBy,
                orderDir,
            }

            const res = await ClinicApi.downloadClinicList(searchData, "xlsx");
            downloadFile(res, "xlsx");
        }
    }

    const handleGoNext = () => {
        // Update the URL
        const newOffset = Number(offset) + limit;
        const newQs = setQueryStringParam(location.search, "offset", newOffset);
        props.history.replace(`${location.pathname}?${newQs}`);
    }

    const handleGoPrevious = () => {
        // Update the URL
        const newOffset = offset - limit;
        const newQs = setQueryStringParam(location.search, "offset", newOffset);
        props.history.replace(`${location.pathname}?${newQs}`);
    }

    const handleSort = (sort, direction) => {
        // Update the URL
        let newQs;
        newQs = setQueryStringParam(location.search, "offset", 0);
        props.history.replace(`${location.pathname}?${newQs}`);
        newQs = setQueryStringParam(location.search, "orderBy", sort);
        props.history.replace(`${location.pathname}?${newQs}`);
        newQs = setQueryStringParam(location.search, "orderDir", direction);
        props.history.replace(`${location.pathname}?${newQs}`);
    }

    const handleLimitChanged = ({value}) => {
        // Update the results per page
        let newQs;
        newQs = setQueryStringParam(location.search, "limit", value);
        props.history.replace(`${location.pathname}?${newQs}`);
    }

    const handleMapData = (e, row) => {
        e.stopPropagation();
        e.preventDefault();
        props.history.push(`/admin/clinic/${row.clinicId}/mapping/pharmacy`)
    }

    const handleGoGreenClicked = (clinicId, providerId) => {
        if (props.canViewClinicProgramSetup) {
            setShowGoGreenModal(true);
            setSelectedProviderId(providerId)
            setSelectedClinicId(clinicId);
            // Have to reload to make sure the selected rebate type hasn't changed
            props.getGoGreenRequirements(clinicId, providerId);
        }
    }

    const handleCancel = () => {
        setShowGoGreenModal(false);
        setSelectedProviderId(null)
        setSelectedClinicId(null);
    }

    const handleGoBrown = () => {
        setShowGoGreenModal(false);
        props.goBrown(selectedClinicId, selectedProviderId);
        setSelectedProviderId(null);
        setSelectedClinicId(null);
    }

    const handleGoGreen = (startDate, clooEnrollmentTag=null) => {
        setShowGoGreenModal(false);
        props.goGreen(selectedClinicId, selectedProviderId, startDate, clooEnrollmentTag);
        setSelectedProviderId(null);
        setSelectedClinicId(null);
    }

    const filterByGateway = (clinicId) => {
        logger.log("GATEWAY: ", clinicId);
    }

    const getClinicGatewayStatus = (clinic) => {
        if(!clinic.isGatewayClinic) {
            return (
                <div
                    className={styles.gateway}
                    onClick={() => filterByGateway(clinic.gatewayClinicId)}
                    title="Child Clinic"
                >
                    <i className="fas fa-clinic-medical" />
                </div>
            )
        }

        if(clinic?.gatewayChildren?.length) {
            return (
                <div
                    className={styles.gateway}
                    onClick={() => filterByGateway(clinic.clinicId)}
                >
                    <i className="fas fa-hospitals" />
                </div>
            )
        }

        return null
    }

    const handleGoToPrioritySupportPage = (clinicId, providerId) => {
        props.history.push(getLinkLocation("premier", clinicId, providerId));
    }

    const getClinicPremierStatus = (clinic) => {
        if(!!includes(clinic?.prioritySupport, 1)) {
            return (
                <div className={styles.biPremierLogoSmall} onClick={() => handleGoToPrioritySupportPage(clinic.id, 1)}>
                    <Image src={BI_PREMIER_LOGO_SMALL} alt="Premier by Boehringer Ingelheim logo" title="Clinic has Premier Support Access"/>
                </div>
            )
        } else return null
    }

    const renderGoGreenModal = () => {
        const providerName = props.providers[selectedProviderId]?.name;
        const clinicName = props.clinics[selectedClinicId]?.name;
        return (
            <Modal
                show={!!(props.goGreenRequirements !== null && !!props.goGreenRequirements && selectedClinicId && selectedProviderId)}
                modalTitle={`${providerName} Go Green - ${clinicName} (${selectedClinicId})`}
                modalSubTitle="The following criteria must all be met before being allowed to Go Green"
                onClose={() => {setShowGoGreenModal(false)}}
                mediumSmall
            >
                {!props.canViewClinicProgramSetup ? (
                    <AccessDenied/>
                ) : (
                    <GoGreenForm
                        clinicId={selectedClinicId}
                        providerId={selectedProviderId}
                        onGoGreen={handleGoGreen}
                        onUndoGoGreen={handleGoBrown}
                        onCancel={handleCancel}
                        canEditClinicProgramSetup={props.canEditClinicProgramSetup}
                        canViewClinicManagement={props.canViewClinicManagement}
                        canViewVetCheckMapping={props.canViewVetCheckMapping}
                        canViewProductMapping={props.canViewProductMapping}
                        canViewClinicWellnessPlans={props.canViewClinicWellnessPlans}
                        canViewVaccineMapping={props.canViewVaccineMapping}
                        canViewWellnessMapping={props.canViewWellnessMapping}
                        canViewWellnessDvms={props.canViewWellnessDvms}
                    />
                )}
            </Modal>
        );
    }


    const COLUMNS = [{
        name: "ID",
        selector: "clinicId",
        key: "clinicId",
        sortable: true,
        searchable: true,
        format: (row) => (<Button type="primary" text onClick={() => handleRowClicked(row)}>{row.id}</Button>)
    }, {
        name: "Name",
        selector: "clinicName",
        key: "clinicName",
        sortable: true,
        searchable: true,
        format: row => (
            <div className={classnames(styles.clinicName, "flex")}>
                <div className="flex flex-1 flex-row spaced-content flex-centered">
                    {!row.isActive && (
                        <div className="text-sm">
                            <div className={styles.inactive}>
                                Inactive
                            </div>
                        </div>
                    )}
                    <div className="flex flex-1 flex-column">
                        <div className="text-wrap text-primary pointer" onClick={() => handleRowClicked(row)}>
                            {row.clinicName}
                        </div>
                        <div className="margin-right-sm">
                            <div>{row.city}, {row.state} {row.countryCode}</div>
                            <div className={classnames(styles.tags, "margin-top-x-sm flex spaced-content flex-wrap")}>
                                {map(row.tags, (tag, index) => (
                                    <div key={index} className={styles.tag}>{tag}</div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                {getClinicGatewayStatus(row)}
                {getClinicPremierStatus(row)}
            </div>
        ),
    }, {
        name: "Last Update",
        selector: "lastInvoiceDateDisplay",
        key: "lastInvSyncGroup",
        sortable: true,
        sortValue: row => {
            if(row.lastInvoiceDateDisplay === "not yet") {
                return "";
            }
            return moment(row.lastInvoiceDateDisplay).format("YYYY/MM/DD");
        },
        format: row => (
            <div className="flex-column">
                <div className="flex align-center margin-bottom-xx-sm">
                    <span className={styles.itemLabel}>Invoice:</span> <span className={classnames({
                    [styles.maroon]: row.lastInvoiceDateCss === "maroon",
                    [styles.red]: row.lastInvoiceDateCss === "red",
                    [styles.orange]: row.lastInvoiceDateCss === "orange",
                    [styles.green]: row.lastInvoiceDateCss === "green"
                })}
                >
                        {stylizeTimeString(row.lastInvoiceDateDisplay)}
                    </span>
                </div>
                <div className="flex align-center">
                    <span className={styles.itemLabel}>Sync: </span><span className={classnames({
                    [styles.maroon]: row.lastProductPullDateCss === "maroon",
                    [styles.red]: row.lastProductPullDateCss === "red",
                    [styles.orange]: row.lastProductPullDateCss === "orange",
                    [styles.green]: row.lastProductPullDateCss === "green"
                })}
                >
                        {stylizeTimeString(row.lastProductPullDateDisplay)}
                    </span>
                </div>
            </div>
        ),
        groupedItems: [
            {
                name: (<div>Last <br/>Invoice</div>),
                selector: "lastInvoiceDateDisplay",
                key: "lastInvoiceDateDisplay",
                sortable: true,
                format: row => (
                    <div className="flex-column text-center">
                        <div className={classnames({
                            [styles.maroon]: row.lastInvoiceDateCss === "maroon",
                            [styles.red]: row.lastInvoiceDateCss === "red",
                            [styles.orange]: row.lastInvoiceDateCss === "orange",
                            [styles.green]: row.lastInvoiceDateCss === "green"
                        })}
                        >
                            {stylizeTimeString(row.lastInvoiceDateDisplay)}
                        </div>
                        <div className={styles.secondaryItem}>{stylizeTimeString(row.latestCouponProcessingDateSince)}</div>
                    </div>
                ),
            },
            {
                name: (<div>Product <br/>Sync</div>),
                selector: "lastProductPullDateDisplay",
                key: "lastProductPullDateDisplay",
                sortable: true,
                format: row => (
                    <div className="flex-column text-center">
                        <div className={classnames({
                            [styles.maroon]: row.lastProductPullDateCss === "maroon",
                            [styles.red]: row.lastProductPullDateCss === "red",
                            [styles.orange]: row.lastProductPullDateCss === "orange",
                            [styles.green]: row.lastProductPullDateCss === "green"
                        })}
                        >
                            {stylizeTimeString(row.lastProductPullDateDisplay)}
                        </div>
                        <div className={styles.secondaryItem}>{stylizeTimeString(row.lastProductPullDateLocalDisplay)}</div>
                    </div>
                )
            },
        ],
    }, {
        name: (<div>Pending<br/>Approval</div>),
        selector: "pendingRedemptions",
        key: "pendingRedemptions",
        sortable: true,
        format: row => (
            <div className="flex-column text-center">
                <div className="text-center">{row.pendingRedemptions}</div>
                <div className={styles.secondaryItem}>{stylizeTimeString(row.oldestPendingRedemptionDisplay)}</div>
            </div>
        ),
    }, {
        name: "PAR",
        selector: "pendingPreAuditReviewCount",
        key: "pendingPreAuditReviewCount",
        sortable: true,
        format: row => (
            <div className="text-center">
                {props.canViewPreAuditReview ? (
                    <Button
                        type="primary"
                        text
                        onClick={(e) => handleParClick(e, row.clinicId)}
                    >
                        {row.pendingPreAuditReviewCount}
                    </Button>
                ) : row.pendingPreAuditReviewCount }
            </div>
        ),
    },{
        name: "Coupon Issues",
        selector: "couponissues",
        key: "couponIssuesGroup",
        sortable: true,
        format: row => (
            <div className="flex-column">
                <div className="flex align-center">
                    <span className={styles.itemLabel}>Dirty Redemptions:</span>
                    <span>
                        {row.dirtyRedemptions}
                    </span>
                </div>
                <div className="flex align-center">
                    <span className={styles.itemLabel}>Stray Coupons: </span>
                    <Button
                        type="primary"
                        text
                        onClick={(e) => handleStrayCouponClick(e, row.clinicId)}
                    >
                        {row.irFailedCount}
                    </Button>
                </div>
            </div>
        ),
        groupedItems: [
            {
                name: (<div>Dirty<br />Redemptions</div>),
                selector: "dirtyredemptions",
                key: "dirtyRedemptionCountDisplay",
                sortable: true,
                format: row => (
                    <div className="flex-column text-center">
                        <span>
                            {row.dirtyRedemptions}
                        </span>
                    </div>
                ),
            },
            {
                name: (<div>Stray<br/>Coupons</div>),
                selector: "irfailedcount",
                key: "strayCouponCountDisplay",
                sortable: true,
                format: row => (
                    <div className="flex-column text-center">
                        <Button
                            type="primary"
                            text
                            onClick={(e) => handleStrayCouponClick(e, row.clinicId)}
                        >
                            {row.irFailedCount}
                        </Button>
                    </div>
                )
            },
        ],
    },
    {
        name: (<div>Mapped <br/>Products</div>),
        selector: "mappedProductCount",
        key: "mappedProductCount",
        sortable: true,
        format: row => (
            <div className="text-center">
                {props.canViewMappingPage ? (
                    <Button
                        type="primary"
                        text
                        onClick={(e) => handleMapData(e, row)}
                    >
                        {row.mappedProductCount}
                    </Button>
                ) : row.mappedProductCount }
            </div>
        ),
    }, {
        name: "Go Green",
        selector: "activations",
        key: "activations",
        format: row => (
            <div className={styles.programs} key={`clinic_${row.clinicId}_activations_${tableKey}`}>
                {row.activations && map(row.activations, (provider) => (
                    <Tooltip
                        key={`provider_${provider.providerId}_clinic_${row.clinicId}_${tableKey}`}
                        position="left"
                        tip={
                            <div>
                                <div className="text-center border-bottom padding-bottom-xs margin-bottom-xx-sm">{provider.providerName}</div>
                                {!!provider.deactivationDate && (
                                    <div>
                                        Deactivated: {new moment(provider.deactivationDate).format("MM/DD/YYYY")}
                                    </div>
                                )}
                                {!!provider.activationDate && (
                                    <div>
                                        Activated: {new moment(provider.activationDate).format("MM/DD/YYYY")}
                                    </div>
                                )}
                                {!!provider.enrollmentDate && (
                                    <div>
                                        Enrolled: {new moment(provider.enrollmentDate).format("MM/DD/YYYY")}
                                    </div>
                                )}
                                {!!provider.lastGoGreenDate && (
                                    <div>
                                        Go Green: {new moment(provider.lastGoGreenDate).format("MM/DD/YYYY")}
                                    </div>
                                )}
                            </div>
                        }
                    >
                        <div
                            onClick={() => handleGoGreenClicked(row.clinicId, provider.providerId)}
                            key={provider.providerId}
                            className={classnames("margin-x-sm text-center", {
                                [styles.notGreen]: !provider.activationDate,
                                "pointer": props.canViewClinicProgramSetup,
                            })}
                        >
                            {(Object.keys(props.providers).length && provider.providerId && props.providers[provider.providerId]) ? (
                                <img src={props.providers[provider.providerId].smallImageUri} alt={provider.providerName}/>
                            ) : (
                                <i className="fa fa-city"/>
                            )}
                            {!!provider.deactivationDate && (
                                <div className={styles.goBrown}/>
                            )}
                        </div>
                    </Tooltip>
                ))}
            </div>
        )
    }, {
        name: "Actions/Status",
        selector: "actions",
        key: "actions",
        format: row => (
            <div className={styles.actions}>
                <Button title="Settings" type="primary" text iconOnly onClick={() => goToLink(row, "settings")}><i className="fas fa-edit"/></Button>
                {props.canImpersonateAccount && row.clinicAdminUsername && (
                    <Button
                        title="Impersonate Clinic Admin"
                        type="primary"
                        text
                        iconOnly
                        onClick={() => impersonateClinic(row)}
                    >
                        <i className="fas fa-eye"/>
                    </Button>
                )}
                <Button title="Provider Setup" type="primary" text iconOnly onClick={() => goToLink(row, "providerSetup")}><i className="fas fa-cog"/></Button>
                <Button title="Program Dashboard" type="primary" text iconOnly onClick={() => goToLink(row, "programsDashboard")}><i className="fas fa-user-md"/></Button>
                <Button title="Product Tags" type="primary" text iconOnly onClick={() => goToLink(row, "productTags")}><i className="fas fa-tags"/></Button>
                <Button title="PIMS Users" type="primary" text iconOnly onClick={() => goToLink(row, "pimsUsers")}><i className="fas fa-user-circle"/></Button>
                <Button title="Invoice Line Items" type="primary" text iconOnly onClick={() => goToLink(row, "invoiceLineItems")}><i className="fas fa-file-invoice-dollar"/></Button>
                {props.canViewUserManagement && (
                    <Button title="Clinic Users" type="primary" text iconOnly onClick={() => handleGpToUsers(row)}><i className="fas fa-users"/></Button>
                )}
            </div>
        )
    }];

    return (
        <div className={styles.root}>
            <div className="margin-bottom-md flex">
                <ClinicFilters
                    triggerLoad={handleReload}
                    location={location}
                />
            </div>
            <div className={styles.resultsPerPage}>
                <div className="flex-1">
                    <Button
                        onClick={handleExportExcel}
                        disabled={!props.canViewClinicManagement}
                        iconOnly
                    >
                        <i className="fa fa-download"/>
                    </Button>
                </div>
                <div className="flex flex-centered spaced-content">
                    <div className="flex-none mobile-hide">Viewing</div>
                    <div className="flex-none">
                        <ResultsPerPageDropdown
                            value={limit}
                            onChange={handleLimitChanged}
                            // max={props.lastSearch?.totalRecordCount}
                        />
                    </div>
                    <div className="flex-none">of {props.lastSearch?.totalRecordCount} <span className="mobile-hide">Results</span></div>
                </div>
            </div>
            {props.clinics ? (
                <Fragment key={`frag_${tableKey}`}>
                    <SortableDataTable
                        key={`table_${tableKey}`}
                        columns={COLUMNS}
                        rawData={orderBy(props.clinics, ["order"])}
                        noPagination
                        striped
                        green
                        hideSearchBar
                        onSort={handleSort}
                    />
                    <div>
                        {(totalPages > 1) && (
                            <DataTablePagination
                                onPrevious={handleGoPrevious}
                                onNext={handleGoNext}
                                canGoNext={canGoNext}
                                canGoPrevious={canGoPrevious}
                                page={page}
                                totalPages={totalPages}
                            />
                        )}
                    </div>
                    {showGoGreenModal && renderGoGreenModal()}
                    <SpinnerTakeover show={loading}/>
                </Fragment>
            ) : <SpinnerTakeover show/> }
        </div>
    );
}

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const goGreenRequirements = state.entities.clinicsGoGreenRequirements;
        const lastSearch = state.entities.lastClinicsSearchParams;
        // Permissions
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canViewPreAuditReview = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRE_AUDIT_REVIEW, userProfile);
        const canViewClinicProgramSetup = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_PROGRAM_SETUP, userProfile);
        const canEditClinicProgramSetup = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_PROGRAM_SETUP, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewProductMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);
        const canViewVetCheckMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);
        const canViewVaccineMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VACCINE_MAPPING, userProfile);
        const canViewWellnessMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.WELLNESS_MAPPING, userProfile);
        const canViewWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canViewMappingPage = userCanViewMappingPage(userProfile);
        const canImpersonateAccount = userHasPermission(PermissionTypes.IMPERSONATE, UserPermissions.ACCOUNT, userProfile);

        return {
            userProfile,
            goGreenRequirements,
            lastSearch,
            totalRecordCount: lastSearch.totalRecordCount,
            search: state.entities.searchValues?.clinicManagement || null,
            clinics: state.entities.clinics,
            providers: state.entities.providers,
            // Permissions
            canViewClinicManagement,
            canViewPreAuditReview,
            canViewUserManagement,
            canViewClinicProgramSetup,
            canEditClinicProgramSetup,
            canViewVetCheckMapping,
            canViewProductMapping,
            canViewClinicWellnessPlans,
            canViewVaccineMapping,
            canViewWellnessMapping,
            canViewWellnessDvms,
            canViewMappingPage,
            canImpersonateAccount,
        }
    },
    (dispatch) => ({
        setUserUnknown: () => dispatch(UserActions.setUserUnknown()),
        setFromUserManagement: () => dispatch(AdminActions.setFromUserManagement(false)),
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        setRecentClinic: (clinicInfo) => dispatch(ClinicActions.setRecentClinic(clinicInfo)),
        setRecentUser: (userInfo) => dispatch(UserActions.setRecentUser(userInfo)),
        loadClinicsList: (searchParams) => dispatch(ClinicActions.loadClinicsList(searchParams)),
        clearClinicTableIds: () => dispatch(ClinicActions.clearClinicsTableIds()),
        getGoGreenRequirements: (clinicId, providerId) => dispatch(AdminActions.getClinicGoGreenRequirements(clinicId, providerId)),
        goGreen: (clinicId, providerId, startDate, clooEnrollmentTag) => dispatch(AdminActions.goGreen(clinicId, providerId, startDate, clooEnrollmentTag)),
        goBrown: (clinicId, providerId) => dispatch(AdminActions.goBrown(clinicId, providerId)),
        impersonateUser: (token) => dispatch(AdminActions.impersonateUser(token)),
        setForceSearchValue: (value) => dispatch(AdminActions.setForceSearchValue(value)),
    })
)

export default compose(
    withRouter,
    connector
)(ClinicsTable);

