import React, { useState } from "react";
import PuppyKittyBowlImportantSafetyInformation from "components/common/ImportantSafetyInformation/PuppyKittyBowlImportantSafetyInformation";
import Modal from "components/common/Modal";
import styles from "./PuppyAndKittyBowlLegalWording.scss";

export function PuppyAndKittyBowlLeaderboardLegalWording(props) {
	return (
        <span>
	        The number of doses shown above are doses of <i>NexGard</i>® <span className="text-uppercase">COMBO</span> (esafoxolaner, eprinomectin, and praziquantel topical solution) and <i>NexGard</i>® <span className="text-uppercase">PLUS</span> (afoxolaner, moxidectin, and pyrantel chewable tablets) sold so far in this game.
            <br/><br/>
            The percentage compares doses sold so far in this game timeframe versus the previous sixty days (December 2023 and January 2024).
            <br/>
            <span className="text-uppercase">VLSD</span> stands for Versus Last Sixty Days.
            <br/><br/>
            <a
                className="text-primary"
                href="https://cdn.greenlinepet.com/games/assets/petsbowltermsandconditions/Greenline_Games_PetsBowl_Terms_And_Condtions.pdf"
                target="_blank"
            >
                View terms and conditions.
            </a>
            <div className={styles.fontWeight400}><i>NexGard®</i> is a registered trademark and <i>NexGard COMBO™</i> is a trademark of Boehringer Ingelheim Animal Health France, used under license. ©2024 Boehringer Ingelheim Animal Health USA Inc., Duluth, GA. All rights reserved. US-PET-0851-2023</div>
        </span>
	)
}

export default function PuppyAndKittyBowlLegalWording(props) {
	const [showSafetyInfo, setShowSafetyInfo] = useState(false);
	const handleShowSafetyInformation = () => {
        setShowSafetyInfo(true);
    }
	return (
		<div className={styles.root}>
            <div className={styles.meds}>
	            Dispense doses to win prizes!
            </div>
            <div className={styles.safetyInfoLink} onClick={handleShowSafetyInformation}>
	            Click here to view the Important Safety Information for
	            <br/>
	            <i>NexGard</i>® <span className="text-uppercase">COMBO</span> (esafoxolaner, eprinomectin, and praziquantel topical solution) for cats, and <i>NexGard</i>® <span className="text-uppercase">PLUS</span>(afoxolaner, moxidectin, and pyrantel chewable tablets) for dogs.
            </div>
			<Modal
                modalTitle="Important Safety Information"
                show={showSafetyInfo}
                onClose={() => setShowSafetyInfo(false)}
                small
            >
                <PuppyKittyBowlImportantSafetyInformation />
            </Modal>
        </div>
	)
}