import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import map from "lodash/map";
import snakeCase from "lodash/snakeCase";
import * as VetCheckActions from "actions/VetCheckActions";
import * as VetCheckApi from "api/VetCheckApi";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import DropdownSearch from "components/common/DropdownSearch";
import Modal from "components/common/Modal";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import VetCheckPlugIn from "components/vetcheck/widgets/VetCheckPlugIn";
import {getClinicInfo} from "utils/ClinicData";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as status from "constants/HttpResponseCodes";
import * as ModalTypes from "constants/ModalTypes";
import * as UserPermissions from "constants/UserPermissions";
import {CHART, CLIENT_FORM, contentTypes, contentTypeTitles, HANDOUT} from "constants/VetCheckContent";

function SendContentForm(props) {
    if(!props.canEditVetCheckMapping || !props.contentType) {
        return null;
    }

    const [showContentForm, setShowContentForm] = useState(false);
    const [showVetCheckContent, setShowVetCheckContent] = useState(false);
    const [formData, setFormData] = useState({});
    const [vetCheckContentId, setVetCheckContentId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingByID, setLoadingByID] = useState(false);
    const [searching, setSearching] = useState(false);
    const [petOwners, setPetOwners] = useState([]);
    const [disabledPetOwners, setDisabledPetOwners] = useState([]);
    const [showDoesntExistError, setShowDoesntExistError] = useState(false);
    const [selectedContentType, setSelectedContentType] = useState(null);
    const [showInvalidContentType, setShowInvalidContentType] = useState(false);

    const contentType = selectedContentType || props.contentType;
    const content = filter(props.vetCheckContent, c => {return snakeCase(c.contentType) === snakeCase(contentType)});

    const petOwnerOptions = map(filter(petOwners, {optOut: false}), (owner) => {
        return {
            name: `${owner.petOwnerFirstName} ${owner.petOwnerLastName} (${!!owner.petOwnerEmail ? owner.petOwnerEmail : "UNKNOWN"})`,
            value: owner.petOwnerId,
        }
    });

    const selectedPetOwner = find(petOwners, {petOwnerId: formData?.clientId});
    const petOptions = map(selectedPetOwner?.patients, (pet) => {
        return {
            name: pet?.patientName,
            value: pet?.patientId
        }
    })

    useEffect(() => {
        if(props.clinicId && showContentForm) {
            props.getVetCheckContent(props.clinicId);
        }
    }, [props.clinicId && showContentForm]);

    useEffect(() => {
        if(showContentForm) {
            handleSearchPetOwners();
        }
    }, [showContentForm]);

    const handleClose = () => {
        setShowVetCheckContent(false);
        setShowContentForm(false);
    }

    const handleSelectForm = (data) => {
        if (!!data) {
            if(!!includes(contentTypes, snakeCase(data.documentType))){
                setVetCheckContentId(data.documentId);
                setSelectedContentType(data.documentType);
                handleGetContentById(data.documentId, data.documentType);
            } else {
                setShowInvalidContentType(true);
            }
            setShowVetCheckContent(false);
        }
    }

    const handleSearchPetOwners = useCallback(debounce((search) => {
        setSearching(true);
        VetCheckApi.getPetOwners(props.clinicId, {limit: 15, search: search})
            .then((res) => {
                setPetOwners(res.body?.data);
                // Disable Pet owners who have no pets
                setDisabledPetOwners(flatMap(filter(res.body?.data, p => {return !p.patients?.length}), "petOwnerId"));
                setSearching(false);
            })
            .catch((error) => {
                setSearching(false);
                handleErrorResponse("loading clinic pet owners", error);
            });
    }, 500), []);

    const handleChange = ({name, value}) => {
        if (name === "clientId") {
            const petOwner = find(petOwners, {petOwnerId: value}) || {};
            let pet = {};
            if(petOwner?.patients?.length === 1){
                pet = petOwner?.patients?.[0];
            }
            setFormData({
                ...formData,
                [name]: value,
                clientId: value,
                firstName: petOwner?.petOwnerFirstName,
                lastName: petOwner?.petOwnerLastName,
                emailAddress: petOwner?.petOwnerEmail,
                patientId: pet?.patientId,
                patientName: pet?.patientName,
            })
        } else if (name === "patientId") {
            if (!!formData?.clientId) {
                const petOwner = find(petOwners, {petOwnerId: formData?.clientId});
                const pet = find(petOwner?.patients, {patientId: value});
                setFormData({
                    ...formData,
                    [name]: value,
                    patientId: value,
                    patientName: pet?.patientName
                })
            }
        } else {
            if (name === "clinicHandoutId"){
                setShowInvalidContentType(false);
            }

            setFormData({
                ...formData,
                [name]: value,
            })
        }
    }

    const handleSend = () => {
        // TODO: which fields can I fill out and which one's do I need to fill out?
        const selectedContent = find(content, {clinicHandoutId: formData.clinicHandoutId});
        const notification = {
            remoteClientId: formData?.clientId,
            // clientId: formData?.clientId,
            clinicId: props.clinic?.clinicId,
            clinicName: props.clinic?.name,
            firstName: formData?.firstName,
            lastName: formData?.lastName,
            emailAddress: formData?.emailAddress,
            // appointmentId: "",
            // appointmentDate: "",
            patients: [
                {
                    clinicHandoutId: selectedContent?.clinicHandoutId,
                    shareLink: selectedContent?.shareLink,
                    shareLinkTitle: selectedContent?.title,
                    remotePatientId: formData?.patientId,
                    // patientId: formData?.patientId,
                    patientName: formData?.patientName,
                    reason: selectedContent?.title
                }
            ],
        }
        VetCheckApi.sendVetCheckContent(notification)
            .then((res) => {
                setVetCheckContentId("");
                setLoading(false);
                setFormData({})
                toast.success(`${contentType} Sent`);
                handleClose();
            })
            .catch((error) => {
                setLoading(false);
                handleErrorResponse(`sending ${contentTypeTitles[contentType]}`, error);
            });

    }

    const handleGetContentById = (contentId=vetCheckContentId, selectedContentType=contentType) => {
        setLoadingByID(true);
        VetCheckApi.getVetCheckContentById(props.clinicId, contentId, selectedContentType )
            .then((res) => {
                props.vetCheckSingleContentLoaded(res.body, props.clinicId);
                handleChange({name: "clinicHandoutId", value: res.body?.clinicHandoutId});
                setVetCheckContentId("");
                setLoadingByID(false);
                toast.success(`${contentTypeTitles[selectedContentType]} Selected`)
            })
            .catch((error) => {
                setLoadingByID(false);
                if(error.status === status.HTTP_STATUS_500) {
                    // A 500 error is returned if the content doesn't exist
                    setShowDoesntExistError(true);
                } else {
                    handleErrorResponse("loading content by ID", error);
                }
            });
    };

    const handleGetContent = () => {
        setLoadingByID(true);
        setShowDoesntExistError(false);
        handleGetContentById();
    }

    const getIcon = () => {
        switch(contentType){
            case HANDOUT:
                return "fa fa-envelope-open-text";
            case CLIENT_FORM:
                return "fa fa-memo-pad";
            case CHART:
                return "fa fa-clipboard-medical";
            default:
                return "fa fa-file";
        }
    }

    const CONTENT_OPTIONS = map(content, c => {
        return {
            name: `(${c.vetCheckHandoutId}) ${c.title}`,
            value: c.clinicHandoutId,
        }
    });

    // Disable the content options that do not have a share link
    const DISABLED_CONTENT = flatMap(filter(content, c => {return !c.shareLink}), "clinicHandoutId");

    const form = (
        <div>
            <div>
                <div className="flex">
                    <div className="flex-1">
                        <Dropdown
                            label={contentTypeTitles[contentType]}
                            onChange={handleChange}
                            name="clinicHandoutId"
                            value={formData.clinicHandoutId}
                            options={CONTENT_OPTIONS}
                            disabledOptions={DISABLED_CONTENT}
                            placeholder={`Select ${contentTypeTitles[contentType]}`}
                        />
                    </div>
                    <div className="flex-1 border-left">
                        <div className="flex spaced-content align-bottom">
                            <TextBox
                                label={`VetCheck ${contentTypeTitles[contentType]} Id`}
                                onChange={({value}) => setVetCheckContentId(value)}
                                name="vetCheckContentId"
                                value={vetCheckContentId}
                                placeholder={`Enter VetCheck ${contentTypeTitles[contentType]} ID`}
                                onEnter={handleGetContent}
                                loading={loadingByID}
                            />
                            <div className="margin-bottom-x-sm">
                                <Button
                                    onClick={handleGetContent}
                                    type="success"
                                    iconOnly
                                    disabled={!vetCheckContentId || loadingByID}
                                >
                                    <i className="fa fa-search"/>
                                </Button>
                            </div>
                        </div>
                        {showDoesntExistError && (
                            <div className="text-danger margin-bottom-x-sm margin-left-sm">No {contentTypeTitles[contentType]} with this ID exists.</div>
                        )}
                    </div>
                </div>
                {showInvalidContentType && (
                    <div className="text-warn">
                        The selected document is not a supported type. Document must be of type Handout, Client Form, or Chart.
                    </div>
                )}
                <div className="flex">
                    <div className="margin-top-x-sm flex-2">
                        <Button
                            onClick={() => setShowVetCheckContent(true)}
                            large
                            superwide
                            disabled={!props.vetCheckAuthCode}
                        >
                            Browse VetCheck Content
                        </Button>
                    </div>
                    <div className="flex-1"/>
                </div>
            </div>
            <div className="border-top">
                <div>
                    <DropdownSearch
                        onChange={handleChange}
                        onSearchChange={handleSearchPetOwners}
                        name="clientId"
                        value={formData.clientId}
                        options={petOwnerOptions}
                        // lastOption={{
                        //     name: (<div><i className="fal fa-plus margin-right-x-sm"/> Enter Pet Owner Manually</div>),
                        //     value: "other"
                        // }}
                        // Disable Pet Owners who do not want to receive vetcheck content
                        disabledOptions={disabledPetOwners}
                        label="Pet Owner"
                        placeholder="Search Pet Owners"
                        loading={searching}
                    />
                </div>
                {(formData?.clientId) && (
                    <>
                        <div className="flex spaced-content">
                            <div className="flex-1">
                                <TextBox
                                    onChange={handleChange}
                                    label="First Name"
                                    name="firstName"
                                    placeholder="Enter Pet Owner First Name"
                                    value={formData.firstName}
                                />
                            </div>
                            <div className="flex-1">
                                <TextBox
                                    onChange={handleChange}
                                    label="Last Name"
                                    name="lastName"
                                    placeholder="Enter Pet Owner First Last"
                                    value={formData.lastName}
                                />
                            </div>
                        </div>
                        <div>
                            <TextBox
                                onChange={handleChange}
                                label="Email"
                                name="emailAddress"
                                placeholder="Enter Pet Owner Email"
                                value={formData.emailAddress}
                            />
                        </div>
                    </>
                )}
                <div>
                    <Dropdown
                        onChange={handleChange}
                        name="patientId"
                        value={formData.patientId}
                        options={petOptions}
                        label="Pet"
                        placeholder="Select Pet"
                        disabled={!formData?.clientId}
                    />
                </div>
                <div className="border-top flex justify-flex-end spaced-content">
                    <div>
                        <Button
                            onClick={handleClose}
                            type="gray"
                        >
                            Cancel
                        </Button>
                    </div>
                    <div>
                        <Button
                            onClick={handleSend}
                            type="success"
                            disabled={!(
                                formData?.clinicHandoutId &&
                                formData?.clientId &&
                                formData?.firstName &&
                                formData?.lastName &&
                                formData?.emailAddress &&
                                formData?.patientId &&
                                formData?.patientName
                            )}
                        >
                            Send {contentType}
                        </Button>
                    </div>
                </div>

            </div>
            <SpinnerTakeover show={loading}/>
        </div>
    );

    return (
        <>
            <div>
                <Button
                    onClick={() => setShowContentForm(true)}
                    type="primary"
                    icon
                    small
                >
                    <i className={getIcon()}/> Send {contentTypeTitles[contentType]}
                </Button>
            </div>
            <Modal
                show={showContentForm && !showVetCheckContent}
                modalTitle={`Send ${contentTypeTitles[contentType]}`}
                icon={getIcon()}
                iconFontSize={2}
                onClose={() => setShowContentForm(false)}
                mediumSmall
            >
                {form}
            </Modal>
            <Modal
                show={showContentForm && showVetCheckContent}
                modalTitle={`Select ${contentTypeTitles[contentType]}`}
                onClose={() => setShowVetCheckContent(false)}
                modalType={ModalTypes.FULLSCREEN}
                tall
                flexBody
            >
                <VetCheckPlugIn
                    clinicId={props.clinicId}
                    shareLinkOnly
                    onSelect={handleSelectForm}
                />
            </Modal>
        </>
    );
}

SendContentForm.propTypes = {
    clinicId: PropTypes.number.isRequired,
    contentType: PropTypes.oneOf(contentTypes),
};

export default connect (
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        // Permissions
        const canEditVetCheckMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.VETCHECK_MAPPING, userProfile);
        return {
            vetCheckAutomations: state.entities.vetCheckAutomations,
            clinic: getClinicInfo(state, ownProps.clinicId),
            vetCheckAuthCode: state.entities.clinicVetCheckStatus[ownProps.clinicId]?.authCode || null,
            vetCheckContent: state.entities.vetCheckContent,
            // Permissions
            canEditVetCheckMapping
        }
    },
    (dispatch) => ({
        getVetCheckContent: (clinicId) => dispatch(VetCheckActions.getVetCheckContent(clinicId)),
        vetCheckSingleContentLoaded: (content, clinicId) => dispatch(VetCheckActions.vetCheckSingleContentLoaded(content, clinicId)),
    })
)(SendContentForm);
