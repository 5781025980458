import React from "react";
import styles from "./ProviderSetupTile.scss";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";

export default function ProviderSetupTile(props) {

    const handleClick = () => {
        if(props.onClick) {
            props.onClick(props.name);
        }
    }

    return (
        <div
            data-testid="provider_setup_tile_component"
            onClick={handleClick} className={classnames(styles.root, {
                [styles.selected]: props.selected,
                "test-selected": props.selected,
            })}
        >
            {props.title &&
                <header
                    data-testid="test_title"
                    className={styles.header}
                >
                    <div className={styles.headerInner}>
                        <div>{props.title}</div>
                    </div>
                </header>
            }
            <div className={styles.content}>
                <div className={styles.logo}>
                    <img
                        src={props.imageUrl}
                        title={props.imageTitle || props.name}
                    />
                </div>
                {props.showEnrollmentInfo && (
                    <div
                        data-testid="test_show_enrollment_info"
                        className={classnames(styles.registerInfo, "flex spaced-content flex-centered text-success")}
                    >
                        {!!props.enrollmentDate && (
                            <div title={`Registered (${moment(props.enrollmentDate).format("MM/DD/YYYY")})`}>
                                <i className="fa fa-user-plus text-sm"/>
                            </div>
                        )}
                        {!!props.isValidLocation && (
                            <div title="Location ID is Valid">
                                <i className="fa fa-map-marker-alt text-sm"/>
                            </div>
                        )}
                        {!!props.goGreenDate && (
                            <div title={`Is Go Green (${moment(props.goGreenDate).format("MM/DD/YYYY")})`}>
                                <i className="fa fa-check-circle text-sm"/>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

ProviderSetupTile.propTypes = {
    title: PropTypes.string,
    imageUrl: PropTypes.string.isRequired,
    imageTitle: PropTypes.string,
    name: PropTypes.any.isRequired,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    enrollmentDate: PropTypes.string,
    isValidLocation: PropTypes.bool,
    goGreenDate: PropTypes.string,
    showEnrollmentInfo: PropTypes.bool,
};
