exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PuppyPoleVault__cls1{fill:#ebebeb}.PuppyPoleVault__cls1,.PuppyPoleVault__cls2,.PuppyPoleVault__cls3,.PuppyPoleVault__cls4,.PuppyPoleVault__cls5,.PuppyPoleVault__cls6,.PuppyPoleVault__cls7,.PuppyPoleVault__cls8,.PuppyPoleVault__cls9,.PuppyPoleVault__cls10,.PuppyPoleVault__cls11{stroke-width:0}.PuppyPoleVault__cls2{fill:#ffc200}.PuppyPoleVault__cls3{fill:#0b0808}.PuppyPoleVault__cls4{fill:#1b75bc}.PuppyPoleVault__cls5{fill:#b76c00}.PuppyPoleVault__cls6{opacity:.3}.PuppyPoleVault__cls6,.PuppyPoleVault__cls9{fill:#231f20}.PuppyPoleVault__cls7{fill:#70043a}.PuppyPoleVault__cls8{fill:#fff}.PuppyPoleVault__cls10{fill:#ccc7c6}.PuppyPoleVault__cls11{fill:#04456f}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/GoldenSummerGames/PuppyPoleVault.scss"],"names":[],"mappings":"AAAA,sBAAM,YAAY,CAAC,oPAAoE,cAAc,CAAC,sBAAM,YAAY,CAAC,sBAAM,YAAY,CAAC,sBAAM,YAAY,CAAC,sBAAM,YAAY,CAAC,sBAAM,UAAU,CAAC,4CAAY,YAAY,CAAC,sBAAM,YAAY,CAAC,sBAAM,SAAS,CAAC,uBAAO,YAAY,CAAC,uBAAO,YAAY,CAAC","file":"PuppyPoleVault.scss","sourcesContent":[".cls1{fill:#ebebeb}.cls1,.cls2,.cls3,.cls4,.cls5,.cls6,.cls7,.cls8,.cls9,.cls10,.cls11{stroke-width:0}.cls2{fill:#ffc200}.cls3{fill:#0b0808}.cls4{fill:#1b75bc}.cls5{fill:#b76c00}.cls6{opacity:.3}.cls6,.cls9{fill:#231f20}.cls7{fill:#70043a}.cls8{fill:#fff}.cls10{fill:#ccc7c6}.cls11{fill:#04456f}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cls1": "PuppyPoleVault__cls1",
	"cls2": "PuppyPoleVault__cls2",
	"cls3": "PuppyPoleVault__cls3",
	"cls4": "PuppyPoleVault__cls4",
	"cls5": "PuppyPoleVault__cls5",
	"cls6": "PuppyPoleVault__cls6",
	"cls7": "PuppyPoleVault__cls7",
	"cls8": "PuppyPoleVault__cls8",
	"cls9": "PuppyPoleVault__cls9",
	"cls10": "PuppyPoleVault__cls10",
	"cls11": "PuppyPoleVault__cls11"
};