export const CONFIRM = "CONFIRM";
export const FULLSCREEN = "FULLSCREEN";
export const OVERLAY = "OVERLAY";
export const OPTIONS = "OPTIONS";
export const WARNING = "WARNING";
export const SUCCESS = "SUCCESS";
export const TOTAL_SUCCESS = "TOTAL_SUCCESS";
export const TOOL = "TOOL";
export const FIXED = "FIXED";
export const DEFAULT = "DEFAULT";
export const NOTIFICATION = "NOTIFICATION";
export const VET_CHECK = "VET_CHECK";
