import React, {useState } from "react";
import {connect} from "react-redux";
import classnames from "classnames";
import * as styles from "./VoucherManagementTools.scss"
import * as AdminActions from "actions/AdminActions";
import Button from "components/common/Button";
import TextBox from "components/common/TextBox";
import { getVouchersGenerated } from "utils/AdminData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function VoucherManagementTools(props) {
    const [createCount, setCreateCount] = useState("");

    const handleGenerateVouchers = () => {
        if(props.canEditVoucherManagement) {
            props.generateVouchers({planId: props.planId, createCount});
            setCreateCount(0);
        }
    }

    return (
        <div className={classnames(styles.root, "spaced-content")}>
            {!!(props.plan && props.canEditVoucherManagement) && (
                <>
                    <div className="flex-1 flex align-center spaced-content">
                        <div className="text-lg flex-none mobile-hide">Generate</div>
                        <div className={styles.voucherCount}>
                            <TextBox
                                name="numAddVouchers"
                                value={createCount}
                                inputType="number"
                                min={0}
                                placeholder="Number of New Codes"
                                onChange={({value}) => setCreateCount(value)}
                            />
                        </div>
                        <div className="nowrap text-lg flex-none mobile-hide">
                            <span className="text-bold">{props.plan.defaultVoucherCodePrefix} </span> Voucher Codes
                        </div>
                    </div>
                    <div className="flex-none">
                        <Button
                            onClick={handleGenerateVouchers}
                            disabled={!createCount}
                        >
                            Generate <span className="mobile-hide"> Codes</span>
                        </Button>
                    </div>
                    {/* TODO: I don't think this actually works*/}
                    {/*<div className="flex-none margin-left-x-sm">*/}
                    {/*    <Button*/}
                    {/*        onClick={() => WindowUtils.scrollToBottom()}*/}
                    {/*        disabled={!generatedNew}*/}
                    {/*    >*/}
                    {/*        View Codes*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </>
            )}
        </div>
    )
}

export default connect(
    (state) => {
        const planId = state.entities.adminTools.voucherPlan;
        const userProfile = state.user.userProfile;
        const canEditVoucherManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.VOUCHER_MANAGEMENT, userProfile);
        return {
            planId,
            canEditVoucherManagement,
            plan: state.entities.plans?.[planId],
            generated: getVouchersGenerated(state, planId),
        }
    },
    (dispatch) => ({
        generateVouchers: (data) => dispatch(AdminActions.generateVouchers(data)),
    })
)(VoucherManagementTools);
