import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import classnames from "classnames";
import moment from "moment";
import get from "lodash/get";
import includes from "lodash/includes";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as styles from "./ProgramLandingPage.scss";
import * as ProgramActions from "actions/ProgramActions";
import AccessDeniedPage from "components/clinic/pages/AccessDeniedPage";
import Button from "components/common/Button";
import CancelProgramEnrollmentForm from "components/programs/CancelProgramEnrollmentForm";
import ClinicBase from "components/layout/ClinicBase";
import Image from "components/common/Image";
import Modal from "components/common/Modal";
import ProgramOptInDetailsModal from "components/clinic/elements/ProgramOptInDetailsModal";
import ProgramTile from "components/programs/ProgramTile";
import { getClinicInfo } from "utils/ClinicData";
import {CANCELED, DECLINED, ELIGIBLE, ENROLLED, INELIGIBLE} from "constants/ProgramStatuses";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import Accordion from "../common/Accordion";
import AccordionSection from "../common/AccordionSection";

function ProgramLandingPage(props) {
    const params = useParams();
    const { pathname } = useLocation();
	const clinicId = Number(params.clinicId);
	const nodeId = Number(params.nodeId);
    const programId  = Number(get(params, "programId", null)) || null;
    const isAdmin = clinicId && includes(pathname, "/admin");
    const isProvider = nodeId && includes(pathname, "/provider");
    // If is an admin then append some string to beginning of URL
	const linkBase = isAdmin ? `/admin/clinic/${clinicId}` : isProvider ? `/provider/location/${nodeId}/clinic/${clinicId}` : '';
    const [showManageEnrollment, setShowManageEnrollment] = useState(false);
    const [emailSectionOpen, setEmailSectionOpen] = useState(false);

    useEffect(() => {
		if (props.canViewProgramEnrollmentClinic) {
			props.getClinicPrograms(props.clinicId);
		}
    }, [props.clinicId]);

    useEffect(() => {
		if (props.canViewProgramEnrollmentClinic) {
			props.getClinicProgramDetails(props.clinicId, programId);
			props.getClinicProgramServices(props.clinicId, programId);
		}
    }, [props.clinicId, params]);

	if (!props.canViewProgramEnrollmentClinic) {
		return <AccessDeniedPage />;
	}

	const handleIframeLoaded = () => {
		const iFrameID = document.getElementById(`program_iframe_${props.programDetails.programId}`);
        if (iFrameID) {
            // here you can make the height, I delete it first, then I make it again
            iFrameID.height = "";
            iFrameID.height = iFrameID.contentWindow.document.body.scrollHeight + 40 +"px";
        }
	};

	const handleOpen = () => {
		setEmailSectionOpen(!emailSectionOpen);
	}

    return (
        <ClinicBase
            clinicId={props.clinicId}
            docTitle={props.program.programName || "Program"}
            pageTitle={(
                <div className="flex align-center spaced-content">
                    <div>
	                    <Image
                            src={props.program.programImage}
                            title={props.program.programName}
                            alt={props.program.programName}
                            style={{
                                width: "40px",
                            }}
                        />
                    </div>
                    <div>{props.program.programName}</div>
                </div>
            )}
        >
            <div className={classnames(styles.root, {
                "flex spaced-content": props.programDetails?.body,
            })}>
                <div
	                className={classnames("flex flex-direction-column", {
						"spaced-content": !props.programDetails,
						"spaced-content-vertical": props.programDetails,
	                })}
	                style={{ maxWidth: props.programDetails ? "216px" : "100%"}}
                >
					<div className="padding-top-md padding-bottom-md">
						<Button
							onClick={() => props.history.push(`/admin/clinic/${clinicId}/programs`)}
						>
							<i className="fa fa-arrow-left fa-fw"></i>
							Programs
						</Button>
					</div>
                    {map(orderBy(props.programServices, [p => {
                        if (p.status === ELIGIBLE) return 0;
                        else if (p.status === ENROLLED) return 1;
                        else if (p.status === DECLINED) return 2;
                        else if (p.status === CANCELED) return 3;
						else if (p.status === INELIGIBLE) return 4;
                        else return 5;
                    }, "programServiceName"]), service => (
                        <ProgramTile
                            key={service.programServiceId}
                            canEditProgramEnrollmentClinic={props.canEditProgramEnrollmentClinic}
                            clinicId={props.clinicId}
                            imageStyle={{
	                            display: "flex",
	                            margin: "auto",
								maxWidth: "100%",
	                            width: "auto",
							}}
                            parentProgram={programId}
                            style={{ width: "216px" }}
                            tile={{
                                ...service,
                                id: service.programServiceId,
                                image: service.programServiceImage,
                                name: service.programServiceName,
                                startDate: service.programServiceStartDate,
                                endDate: service.programServiceEndDate,
                            }}
                        />
                    ))}
                </div>
                {!!props.programDetails && (
                    <div className={classnames(styles.programDetails, {
						[styles.showBorder]: keys(props.programServices)?.length,
                    })}>
                        <h2 className="text-center">{props.programDetails?.title}</h2>
                        <div className={styles.status}>{props.program?.status}</div>
                        {/*<div dangerouslySetInnerHTML={{ __html: props.programDetails?.body }}/>*/}
	                    {!!props.programDetails?.body && (
		                    <iframe
								id={`program_iframe_${props.programDetails.programId}`}
								style={{
									width: "100%",
									flex: "none",
									border: "none",

								}}
								scrolling="no"
						        srcDoc={props.programDetails.body}
								onLoad={handleIframeLoaded}
							/>
	                    )}
	                    {props.programDetails?.expandableContent && (
			                <Accordion >
				                <AccordionSection
					                id="email_example"
					                onClick={handleOpen}
					                header={props.programDetails?.expandableContentName ? (
										<div dangerouslySetInnerHTML={{ __html: props.programDetails.expandableContentName }}/>
					                ) : ""}
				                >
					                <div className="flex">
					                    <div className={styles.emailExample}>
							                {props.programDetails?.expandableContentHeader && (
												<div
													className="border-bottom"
													dangerouslySetInnerHTML={{ __html: props.programDetails.expandableContentHeader }}
												/>
							                )}
						                    <div className="flex flex-centered">
												<iframe
													id={`program_expandable_iframe_${props.programDetails.programId}`}
													style={{
														width: "100%",
														flex: "none",
														border: "none",
													}}
													scrolling="no"
											        srcDoc={props.programDetails.expandableContent}
													onLoad={(e) => handleIframeLoaded(e, `program_expandable_iframe_${props.programDetails.programId}`)}
												/>
						                    </div>
						                </div>
					                </div>
				                </AccordionSection>
			                </Accordion>
		                )}
                        <div className="text-center">Activated on {moment(props.program.userActionDate).format("MM/DD/YYYY")} by {props.program.signature}</div>
	                    {props.canEditProgramEnrollmentClinic && (
	                        <div className="flex flex-centered padding-top-md">
	                            <Button 
		                            onClick={() => setShowManageEnrollment(true)}
		                            disabled={props.program?.status !== ENROLLED}
	                            >
		                            Manage Enrollment
								</Button>
	                        </div>
	                    )}
                    </div>
                )}
            </div>
            <ProgramOptInDetailsModal
                key={`Program_service_${props.clinicId}_${programId}`}
                clinicId={props.clinicId}
                onClose={() => setShowManageEnrollment(false)}
                program={props.program}
                programId={programId}
                show={showManageEnrollment}
				linkBase={linkBase}
            />
        </ClinicBase>
    )
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        let programId = get(ownProps.match.params, "programId", null);
        programId = programId ? Number(programId) : null;
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        const clinicPrograms = get(state.entities.clinicPrograms, clinicId, {});
        const clinicProgramDetails = get(state.entities.clinicProgramDetails, clinicId, {});
        const programs = get(state.entities.clinicProgramServices, clinicId, {});
		const userProfile = state.user.userProfile;
        const canEditProgramEnrollmentClinic = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);
        const canViewProgramEnrollmentClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);
        return {
			clinic,
            clinicId,
	        programDetails: get(clinicProgramDetails, programId, null),
            program: get(clinicPrograms, programId, {}),
	        programServices: get(programs, programId, {}),
	        canEditProgramEnrollmentClinic,
	        canViewProgramEnrollmentClinic,
        };
    },
	(dispatch) => ({
		getClinicPrograms: (clinicId) => dispatch(ProgramActions.loadClinicPrograms(clinicId)),
		getClinicProgramDetails: (clinicId, programId) => dispatch(ProgramActions.loadClinicProgramDetails(clinicId, programId)),
		getClinicProgramServices: (clinicId, programId) => dispatch(ProgramActions.loadClinicProgramServices(clinicId, programId)),
	})
)(ProgramLandingPage);
