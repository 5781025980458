import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import PropTypes from "prop-types";
import values from "lodash/values";
import * as ClinicApi from "api/ClinicApi";
import * as CouponActions from "actions/CouponActions";
import * as AdminActions from "actions/AdminActions";
import * as ClinicActions from "actions/ClinicActions";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { parseQueryString, setQueryStringParams } from "utils/urls";
import * as UserPermissions from "constants/UserPermissions";
import SaveCurrentViewForm from "./SaveCurrentViewForm";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import getIsClinicFilterDataLoaded from "utils/getIsClinicFilterDataLoaded";

const DEFAULT_CLINIC_FILTERS = "filters=%5B%7B%22order%22%3A0%2C%22id%22%3A%22Active%22%2C%22isComplete%22%3Atrue%2C%22equality%22%3A%22%3D%3D%22%2C%22level1Values%22%3A%5B%7B%22value%22%3A%22Active%22%7D%5D%7D%5D&orderBy=id&orderDir=desc";

function SaveCurrentViewFormContainer(props) {
    const location = useLocation();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const parsedFilters = parseQueryString(location.search);

    useEffect(() => {
        if (!props.isClinicFilterDataLoaded) {
            props.getProviderList();
            props.getActiveOffers();
            props.getProviderReports();
            props.getSoftwareVendors();
        }
    }, [props.isClinicFilterDataLoaded]);

    const handleChangeView = (viewId) => {
        const view = props.clinicViews[viewId];
        if(!!view?.value) {
            const filters = JSON.stringify(view.value.filters || [])
            const newQs = setQueryStringParams(location.search, {
                viewId,
                filters,
                orderBy: view.value.orderBy || "id",
                orderDir: view.value.orderDir || "desc",
                offset: view.value.offset || 0,
                limit: view.value.limit || 25,
                search: view.value.search || "",

            });
            history.replace(`${location.pathname}?${newQs}`);
        } else {
            history.replace(`${location.pathname}?${DEFAULT_CLINIC_FILTERS}`);
        }
    }

    const handleSubmit = (e, formData) => {
        e.preventDefault();
        handleSave(formData);
    };

    const create = (data) => {
        if(props.canCreateClinicManagement) {
            setLoading(true);
            ClinicApi.createCustomClinicView(data)
                .then((res) => {
                    props.customClinicViewCreated(res.body);
                    handleChangeView(res.body.userPreferenceId);
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("creating clinics view", error);
                    setLoading(false);
                });
        }
    }

    const handleSave = (formData) => {
        const view = {
            userPreferenceId: formData.id,
            value: {
                search: formData?.search || "",
                limit: formData?.limit || "",
                offset: 0,
                orderBy: formData?.orderBy || "id",
                orderDir: formData?.orderDir || "desc",
                filters: JSON.parse(parsedFilters?.filters || "[]"),
            },
            isFavorite: formData.id ? props.clinicViews[formData.id].isFavorite : false,
            isPinned: formData.id ? props.clinicViews[formData.id].isPinned : false,
            title: formData.id ? props.clinicViews[formData.id].title : formData.name,
        }
        if (formData.id && props.canEditClinicManagement) {
            props.updateCustomClinicView(view);
        } else {
            if (props.canCreateClinicManagement) {
                create(view);
            }
        }
    }

    return (
        <>
            <SaveCurrentViewForm
                initialData={{
                    ...parsedFilters,
                    filters: !!parsedFilters?.filters ? values(JSON.parse(parsedFilters.filters)) : [],
                }}
                onSubmit={handleSubmit}
                onCancel={props.onClose}
            />
            <SpinnerTakeover show={loading} />
        </>
    );
}

SaveCurrentViewFormContainer.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canCreateClinicManagement = userHasPermission(PermissionTypes.CREATE, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);

        return {
            canCreateClinicManagement,
            canEditClinicManagement,
            clinicViews: state.entities.clinicViews,
            isClinicFilterDataLoaded: getIsClinicFilterDataLoaded(state),
        }
    },
    (dispatch) => ({
        customClinicViewCreated: (view) => dispatch(ClinicActions.customClinicViewCreated(view)),
        updateCustomClinicView: (view) => dispatch(ClinicActions.updateCustomClinicView(view)),
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        getActiveOffers: () => dispatch(CouponActions.getLibraryOffers()),
        getProviderReports: () => dispatch(AdminActions.getProviderReports()),
        getSoftwareVendors: () => dispatch(ClinicActions.loadSoftwareVendors()),
    })
)(SaveCurrentViewFormContainer);

