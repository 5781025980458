import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ClinicTableDropdown.scss";
import map from "lodash/map";
import keys from "lodash/keys";
import * as ClinicApi from "api/ClinicApi";
import * as ClinicActions from "actions/ClinicActions";
import AccessDenied from "components/common/AccessDenied";
import EditCustomViewForm from "components/admin/forms/EditCustomViewsForm";
import Modal from "components/common/Modal";
import SaveCurrentViewFormContainer from "components/admin/forms/SaveCurrentViewFormContainer";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {parseQueryString, setQueryStringParams} from "utils/urls";
import * as UserPermissions from "constants/UserPermissions";

const DEFAULT_CLINIC_FILTERS = "filters=%5B%7B%22order%22%3A0%2C%22id%22%3A%22Active%22%2C%22isComplete%22%3Atrue%2C%22equality%22%3A%22%3D%3D%22%2C%22level1Values%22%3A%5B%7B%22value%22%3A%22Active%22%7D%5D%7D%5D&orderBy=id&orderDir=desc";

export function ClinicTableDropdown(props) {
    const { clinicViews = {} } = props;
    const dropdownRef = React.useRef();
    const options = React.useRef();
    const [showOptions, setShowOptions] = useState(false);
    const [showSave, setShowSave] = useState(false);
    const [newViewName, setNewViewName] = useState("");
    const [showCustomViewsModal, setShowCustomViewsModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const parsedFilters = parseQueryString(location.search);
    const selectedViewId = parsedFilters?.viewId || null;

    if (!props.canViewClinicManagement) {
        return <AccessDenied />;
    }

    const handleShowOptions = () => {
        setShowOptions(true);
    };

    const handleCloseOptions = () => {
        setShowOptions(false);
    };

    const handleDropdownClick = (e) => {
        // Keeps the dropdown open correctly
        //  Also, fixes the double-clicking issue... I'm not exactly sure why though
        if (!showOptions) {
          handleShowOptions();
          return;
        }

        e.preventDefault();
        e.stopPropagation();
  }

    const handleChangeView = (viewId) => {
        setShowOptions(false);
        const view = clinicViews[viewId];
        if(!!view?.value) {
            const filters = JSON.stringify(view.value.filters || [])
            const newQs = setQueryStringParams(location.search, {
                viewId,
                filters,
                orderBy: view.value.orderBy || "id",
                orderDir: view.value.orderDir || "desc",
                offset: view.value.offset || 0,
                limit: view.value.limit || 25,
                search: view.value.search || "",

            });
            history.replace(`${location.pathname}?${newQs}`);
        } else {
            history.replace(`${location.pathname}?${DEFAULT_CLINIC_FILTERS}`);
        }
    }

    const handleShowSave = (e) => {
        if(!selectedViewId) {
            handleShowSaveAs(e)
        } else {
            e.stopPropagation();
            e.preventDefault();
            handleSave(selectedViewId);
            setShowOptions(false);
        }
    }
    const handleShowSaveAs = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowOptions(false);
        setShowSave(true);
    }

    const handleManageViews = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setShowOptions(false);
        setShowCustomViewsModal(true);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        handleSave();
    };

    const create = (data) => {
        if(props.canCreateClinicManagement) {
            setLoading(true);
            ClinicApi.createCustomClinicView(data)
                .then((res) => {
                    props.customClinicViewCreated(res.body);
                    handleChangeView(res.body.userPreferenceId);
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("creating clinics view", error);
                    setLoading(false);
                });
        }
    }

    const handleSave = (viewId=null) => {
        setShowSave(false);

        const view = {
            userPreferenceId: viewId,
            value: {
                search: parsedFilters?.search || "",
                limit: parsedFilters?.limit || "",
                offset: 0,
                orderBy: parsedFilters?.orderBy || "id",
                orderDir: parsedFilters?.orderDir || "desc",
                filters: JSON.parse(parsedFilters?.filters || "[]")
            },
            isFavorite: viewId ? clinicViews[viewId].isFavorite : false,
            isPinned: viewId ? clinicViews[viewId].isPinned : false,
            title: viewId ? clinicViews[viewId].title : newViewName,
        }
        if (viewId && props.canEditClinicManagement) {
            props.updateCustomClinicView(view);
        } else {
            if (props.canCreateClinicManagement) {
                create(view);
            }
        }
        setNewViewName("");
    }

    return (
        <div
            data-testid="clinic_table_dropdown_component"
            // ref={dropdownRef}
            className={styles.root}
            tabIndex={-1}
            onFocus={(data) => props.disabled ? {} : handleShowOptions(data)}
            onBlur={handleCloseOptions}
            onMouseDown={handleDropdownClick}
        >
            <div
                data-testid="clinic_table_dropdown_inner_component"
                ref={dropdownRef}
                className={classnames({
                    [styles.active]: showOptions,
                    "test-show-options": showOptions,
                })}
            >
                <a className={styles.clinicDropdown}>
                    <span><i className="fa fa-cog"/> Manage Views</span>
                    <span className={styles.arrow}>
                        <i className={classnames("fas", {
                            ["fa-chevron-up"]: showOptions,
                            ["fa-chevron-down"]: !showOptions,
                        })} />
                    </span>
                </a>
                {showOptions && (
                    <div
                        data-testid="clinic_table_dropdown_options"
                        ref={options}
                        className={styles.optionsBox}
                    >
                        {(props.canCreateClinicManagement || props.canEditClinicManagement) && (
                            <div
                                data-testid="clinic_table_dropdown_save"
                                className={styles.option}
                                onClick={handleShowSave}
                            >
                                <span className={styles.icon}><i className="far fa-save" /></span>
                                <span className={styles.linkText}>Save View</span>
                            </div>
                        )}
                        {props.canCreateClinicManagement && (
                            <div
                                data-testid="clinic_table_dropdown_save_as"
                                className={styles.option}
                                onClick={handleShowSaveAs}
                            >
                                <span className={styles.icon}><i className="far fa-save" /></span>
                                <span className={styles.linkText}>Save View As...</span>
                            </div>
                        )}
                        <hr/>
                        <div
                            data-testid="clinic_table_dropdown_default"
                            className={styles.option}
                            onClick={() => handleChangeView(null)}
                        >
                            <span className={styles.linkText}>Default</span>
                        </div>
                        {map(clinicViews, view => (
                            <div
                                data-testid={`clinic_table_dropdown_option_${view.userPreferenceId}`}
                                key={view.userPreferenceId}
                                className={styles.option}
                                onClick={() => handleChangeView(view.userPreferenceId)}
                            >
                                <span className={styles.linkText}>{view.title}</span>
                                {view.isPinned && (
                                    <i
                                        data-testid={`clinic_table_dropdown_pinned_${view.userPreferenceId}`}
                                        className="fa fa-thumb-tack text-primary margin-right-x-sm"
                                    />
                                )}
                                {view.isFavorite && (
                                    <i
                                        data-testid={`clinic_table_dropdown_favorite_${view.userPreferenceId}`}
                                        className="fa fa-star text-yellow margin-right-x-sm"
                                    />
                                )}
                            </div>
                        ))}
                        {!!keys(clinicViews).length && (
                            <>
                                <hr/>
                                <div
                                    data-testid="clinic_table_dropdown_manage_views"
                                    className={styles.option}
                                    onClick={handleManageViews}
                                >
                                    <span className={styles.linkText}><i className="fa fa-cogs"/> Browse Views</span>
                                </div>
                            </>
                        )}
                    </div>
                )}
                <SpinnerTakeover show={loading}/>
            </div>
            <Modal
                data-testid="clinic_table_dropdown_save_current_view"
                modalTitle="Save Current View"
                small
                show={showSave}
                onClose={() => setShowSave(false)}
            >
                <SaveCurrentViewFormContainer onClose={() => setShowSave(false)} />
            </Modal>
            <Modal
                data-testid="clinic_table_dropdown_saved_views"
                modalTitle="Saved Views"
                icon="fa fa-cogs"
                small
                show={showCustomViewsModal}
                onClose={() => setShowCustomViewsModal(false)}
            >
                <EditCustomViewForm
                    onSelectView={handleChangeView}
                    onCancel={() => {}}
                />
            </Modal>
        </div>
    );
}

ClinicTableDropdown.propTypes = {
    canViewClinicManagement: PropTypes.bool,
    canCreateClinicManagement: PropTypes.bool,
    canEditClinicManagement: PropTypes.bool,
    customClinicViewCreated: PropTypes.func.isRequired,
    updateCustomClinicView: PropTypes.func.isRequired,
    clinicViews: PropTypes.shape({
        title: PropTypes.string,
        userPreferenceId: PropTypes.number,
        isPinned: PropTypes.bool,
        isFavorite: PropTypes.bool,
        value: PropTypes.shape({
            orderBy: PropTypes.string,
            orderDir: PropTypes.string,
            offset: PropTypes.number,
            limit: PropTypes.string,
            search: PropTypes.string,
            filters: PropTypes.shape({
                id: PropTypes.string,
                equality: PropTypes.string,
                order: PropTypes.number,
                isComplete: PropTypes.bool,
                level1Values: PropTypes.arrayOf(PropTypes.shape({
                    value: PropTypes.string,
                })),
                level2Values: PropTypes.arrayOf(PropTypes.shape({
                    value: PropTypes.string,
                })),
            }),
        }),
    }),
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canCreateClinicManagement = userHasPermission(PermissionTypes.CREATE, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);

        return {
            canViewClinicManagement,
            canCreateClinicManagement,
            canEditClinicManagement,
            clinicViews: state.entities.clinicViews,
        }
    },
    (dispatch) => ({
        customClinicViewCreated: (view) => dispatch(ClinicActions.customClinicViewCreated(view)),
        updateCustomClinicView: (view) => dispatch(ClinicActions.updateCustomClinicView(view)),
    })
) (ClinicTableDropdown);
