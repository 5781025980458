import moment from "moment";
import capitalize from "lodash/capitalize";
import concat from "lodash/concat";
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import flatMap from "lodash/flatMap";
import forEach from "lodash/forEach";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import includes from "lodash/includes";
import isObject from "lodash/isObject";
import isString from "lodash/isString";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import mapKeys from "lodash/mapKeys";
import mapValues from "lodash/mapValues";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import replace from "lodash/replace";
import round from "lodash/round";
import sortBy from "lodash/sortBy";
import split from "lodash/split";
import sumBy from "lodash/sumBy";
import uniq from "lodash/uniq";
import uniqBy from "lodash/uniqBy";
import words from "lodash/words";
import produce from "immer";
import {getBasicUserPermissions} from "utils/permissions/rolesPermissions";
import { PAR_ITEM_PENDING } from "constants/ParItemRedemptionStates";
import {GAME_TYPES, LEAF_PARTY, PUPPY_POOL_PARTY} from "constants/GameTypes";
import * as NodeIcons from "constants/NodeIcons";
import * as OrgNodeTypes from "constants/OrgNodeTypes";
import * as ProviderGameStateIds from "constants/ProviderGameStateIds";
import {BOEHRINGER_INGELHEIM} from "../constants/ProviderIds";
import {generateRandomNumber} from "utils/TestUtils";

const custom = (objValue, srcValue) => {
  if (_.isArray(objValue)) {
      return srcValue;
  }
}

export function mapUserProfileFromServerToApp(userProfile) {
    const permissions = keyBy(userProfile.security.permissions, "id");
    const basicPermissions = getBasicUserPermissions({ permissions });

    return {
        ...userProfile.account,
        ...userProfile.security,
        permissions,
        ...basicPermissions,
        clinics: filter(userProfile.location.items, { type: "Clinic" }),
        providers: filter(userProfile.location.items, { type: "Provider" }),
        primaryClinic: find(userProfile.location.items, item => (item.type === "Clinic" && item.isPrimaryLocation)),
        primaryLocation: find(userProfile.location.items, "isPrimaryLocation"),
        name: `${userProfile.account.firstName} ${userProfile.account.lastName}`,
        imageUrl: userProfile.account.imageUri,
    };
}

const FIRST_NAME = "firstName";
const LAST_NAME = "lastName";
const EMAIL = "email";
const ADDRESS_1 = "address1";
const ADDRESS_2 = "address2";
const CITY = "city";
const STATE = "state";
const POSTAL_CODE = "postalCode";
const PET_NAME = "petName";
const PET_DOB = "petDOB";

const couponFieldsServerToApp = {
    petOwnerFirstName: FIRST_NAME,
    petOwnerLastName: LAST_NAME,
    productName: "product",
    quantity: {
        name: "quantity",
        toValue: (quantity) => String(quantity),
        fromValue: (quantity) => String(quantity),
    },
    redemptionState: "status",
    externalRedemptionId: "id",
    providerId: "vendorId",
    petOwnerStreet1: ADDRESS_1,
    petOwnerStreet2: ADDRESS_2,
    petOwnerCity: CITY,
    petOwnerState: STATE,
    petOwnerZipCode: POSTAL_CODE,
    petDateOfBirth: PET_DOB,
    invoiceNumber: {
        name: "invoiceNumber",
        toValue: (invoiceNumber) => Number(invoiceNumber),
        fromValue: (invoiceNumber) => Number(invoiceNumber),
    }
};


const couponFieldsAppToServer = {
    invoiceNumber: {
        name: "invoiceNumber",
        toValue: (invoiceNumber) => String(invoiceNumber),
        fromValue: (invoiceNumber) => String(invoiceNumber),
    }
};

export const serverFieldIds = {
    1: ADDRESS_1,
    2: ADDRESS_2,
    3: CITY,
    4: EMAIL,
    5: FIRST_NAME,
    6: LAST_NAME,
    8: POSTAL_CODE,
    10: PET_NAME,
    12: PET_DOB,
    15: STATE,
};

forEach(couponFieldsServerToApp, (appFieldConfig, serverKey) => {
    if (isString(appFieldConfig)) {
        couponFieldsAppToServer[appFieldConfig] = serverKey;
    } else {
        couponFieldsAppToServer[appFieldConfig.name] = {
            toValue: appFieldConfig.fromValue,
            name: serverKey,
            fromValue: appFieldConfig.toValue,
        };
    }
});

function applyConfigToObject(config, sourceData) {
    const obj = {};
    forEach(config, (targetConfig, sourceKey) => {
        const value = sourceData[sourceKey];
        if (isString(targetConfig)) {
            obj[targetConfig] = value;
        } else {
            if (targetConfig.toValue) {
                obj[targetConfig.name] = targetConfig.toValue(value);
            } else {
                obj[targetConfig.name] = value;
            }
        }
    });
    return obj;
}

export function mapSingleCouponFromServerToApp(coupon) {
    const emailField = find(coupon.fields, { validationSourceFieldId: 4 })
    return {
        ...coupon,
        ...applyConfigToObject(couponFieldsServerToApp, coupon),
        email: emailField?.originalText || "",
        emailRequired: emailField?.couponHeaderValidations !== 0,
        errors: flatMap(reject(coupon.fields, "isResolved"), f => serverFieldIds[f.validationSourceFieldId]),
    };
}

export function mapCouponSearchFromServerToApp(couponSearch) {
    return map(couponSearch.data, (coupon) => mapSingleCouponFromServerToApp(coupon));
}

export function mapSingleCouponFromAppToServer(coupon) {
    return {
        ...coupon,
        ...applyConfigToObject(couponFieldsAppToServer, coupon),
    };
}

export function mapSingleWellnessVisitFromServerToApp(wellnessVisit) {
    return {
        initials: wellnessVisit.userInitials,
        ...wellnessVisit,
        client: `${wellnessVisit.petOwnerFirstName} ${wellnessVisit.petOwnerLastName}`,
        id: wellnessVisit.visitId,
        pet: wellnessVisit.petName,
        productName: wellnessVisit.product,

    };
}

export function mapWellnessVisitSearchFromServerToApp(wellnessVisitSearch) {
    return map(wellnessVisitSearch.data, (wellnessVisitDvm) => {
        return {
            ...mapSingleWellnessVisitFromServerToApp(wellnessVisitDvm),
            dvm: `${wellnessVisitDvm.firstName} ${wellnessVisitDvm.lastName}`,
        };
        // return map(wellnessVisitDvm.visits, (wellnessVisit) => ({
        //     ...mapSingleWellnessVisitFromServerToApp(wellnessVisit),
        //     dvm: `${wellnessVisitDvm.firstName} ${wellnessVisitDvm.lastName}`,
        // }))
    })
}

export function mapSingleClinicFromAppToServer(clinic) {
    // Only include the special addresses if they contain an address (fixes issues where the form wasn't completed)
    const clinicSpecialAddresses = [];
    if(!!clinic.billingAddress.street1 && clinic.billingAddress.city && clinic.billingAddress.stateProvince && clinic.billingAddress.postalCode && clinic.billingAddress.countryCode) {
        clinicSpecialAddresses.push(clinic.billingAddress)
    }
    if(!!clinic.shippingAddress.street1 && clinic.shippingAddress.city && clinic.shippingAddress.stateProvince && clinic.shippingAddress.postalCode && clinic.shippingAddress.countryCode) {
        clinicSpecialAddresses.push(clinic.shippingAddress)
    }
    return {
        ...clinic,
        clinicSpecialAddresses: clinicSpecialAddresses
    }
}

export function mapSingleClinicFromServerToApp(clinic) {
    return {
        ...clinic,
        id: clinic.clinicId,
        name: clinic.clinicName ? clinic.clinicName : clinic.name,
        approvableProviders: flatMap(clinic.approvableProviders, "providerId"),
        prioritySupport: !!clinic?.activations ? flatMap(filter(clinic.activations, "isPrioritySupport"), "providerId") : flatMap(filter(clinic.prioritySupport, "isPrioritySupport"), "providerId"),
        billingAddress: find(clinic.clinicSpecialAddresses, {addressTypeId: 1, isActive: true}) || { addressTypeId: 1, street2: "", createDate: new Date() },
        shippingAddress: find(clinic.clinicSpecialAddresses, {addressTypeId: 2, isActive: true}) || { addressTypeId: 2, street2: "", createDate: new Date() }
    }
}

export function mapClinicsListFromServerToApp(clinicsResponse) {
    return map(clinicsResponse, (clinic) => mapSingleClinicFromServerToApp(clinic));
}

export function mapClinicsCoordinatesFromServerToApp(clinicsCoordinatesResponse) {
    return map(clinicsCoordinatesResponse, (clinic) => {
        return {
            ...clinic,
            lng: Number(clinic.long),
            lat: Number(clinic.lat),
            title: clinic.name,
        }
    });
}

//UPDATED
function getCenterOfMapBox(NW, SE) {

    const centerLat = (Number(NW.lat) - Number(SE.lat))/2 + Number(SE.lat);
    const centerLng = (Number(NW.long) - Number(SE.long))/2 + Number(SE.long);

    return {
        centerLat,
        centerLng,
    }
}

function getLatLngForMapEntity(clinic) {
    let lat = null;
    let lng = null;

    if (clinic.stats && clinic.stats.map) {
        const box = getCenterOfMapBox(clinic.stats.map.nw, clinic.stats.map.se);
        lat = box.centerLat;
        lng = box.centerLng;
    }

    return { lat, lng };
}

export function mapProviderClinicsFromServerToApp(clinicNode) {
    return map(clinicNode, (clinic) => {
        const { lat, long } = clinic.coordinates;

        return {
            ...clinic,
            lat,
            lng: long,
            title: clinic.clinicName,
            territoryCode: clinic.territory.code,
            territoryManager: clinic.territory.name,
            id: clinic.clinicId,
            name: clinic.clinicName,
            ...clinic.data,
        };
    });
}

export function mapProviderColumnsFromServerToApp(columns) {
    return {
        ...columns,
    };
}

const getMapIcon = (node) => {
    // TODO: SET THE MAP MARKERS
    if (node.nodetype === OrgNodeTypes.CLINIC) {
        return null;
    } else {
        return NodeIcons.CIRCLE
    }
};

export function mapProviderChildNodesFromServerToApp(territoryNodes) {
    return map(territoryNodes, node => {
        const { lat, lng } = getLatLngForMapEntity(node);

        return {
            ...node,
            lat,
            lng,
            title: node.name,
            id: node.nodeId,
            name: node.name,
            icon: getMapIcon(node)
        }
    });
}

export function mapProcessingCouponListFromAppToServer(initials, couponList, redemptionState, clinicId) {
    return {
        externalRedemptionIds: couponList,
        userInitials: initials,
        redemptionState,
        clinicId
    };
}

export function mapSingleProgramFromServerToApp(program) {
    return {
        ...program,
        new: program.newOfferCount,
        optedIn: program.activatedOfferCount,
        providerId: program.provider.id,
        programId: program.provider.id,
        id: program.provider.id,
        totalOffers: program.offerCount,
    };
}


export function mapProgramFromServerToApp(programResponse) {
    return map(programResponse, (program) => mapSingleProgramFromServerToApp(program));
}

export function mapSingleProgramOfferFromServerToApp(programOfferGroup) {
    let formatted;
    //Had to remove the extra stuff that was sent back with the tags ([] and "").
    //Combined the offers and groupOffers since the data was in the same format and should be displayed the same way
    if (programOfferGroup.clog && programOfferGroup.clog.cloo) {
        formatted = {
            ...programOfferGroup,
            id: programOfferGroup.ccloId,
            clog: {
                ...programOfferGroup.clog,
                cloo: {
                    ...programOfferGroup.clog.cloo,
                    offers: map({
                        ...programOfferGroup.clog.cloo.offers,
                        ...programOfferGroup.clog.cloo.groupOffers
                    }, offer => {
                        return {
                            ...offer,
                            tags: map(split(offer.tags, ','), tag => (
                                replace(replace(replace(tag, '[', ''), ']', ''), /"/g, '')
                            )),
                        }
                    }),
                    groupOffers: [],
                }
            }
        };
    } else {
        formatted = {
            ...programOfferGroup,
            groupId: programOfferGroup.clogId,
            offers: map({
                ...programOfferGroup.offers,
                ...programOfferGroup.groupOffers,
            }, (offer) => ({
                ...offer,
                name: offer.libraryTitle,
                offerId: offer.ccloId,
                pdfUrl: offer.couponPdfUri,
                imageUrl: offer.imageUri,
                description: offer.longDescription,
            })),
        };
    }
    return formatted;
}

export function mapProgramOffersFromServerToApp(programOffers) {
    return map(programOffers, (programOfferGroup) => mapSingleProgramOfferFromServerToApp(programOfferGroup));
}

export function mapSingleWellnessPlanFromServerToApp(wellnessPlan) {
    return {
        ...wellnessPlan,
        id: wellnessPlan.petId,
        lastPayment: wellnessPlan.lastPaymentDate,
        patient: wellnessPlan.petName,
        name: `${wellnessPlan.petOwnerFirstName} ${wellnessPlan.petOwnerLastName}`,
        plan: wellnessPlan.planName,
        planStart: wellnessPlan.planStartDate,
        planEnd: wellnessPlan.planEndDate,
        planDuration: moment(wellnessPlan.planEndDate).diff(moment(wellnessPlan.planStartDate), "month"),
        statusName: wellnessPlan.paymentStatusDescription,
    };
}

export function mapWellnessPlansFromServerToApp(wellnessPlans) {
    return map(wellnessPlans, mapSingleWellnessPlanFromServerToApp);
}

export function mapWellnessPlanDetailsFromServerToApp(plan) {
    // Not mapping anything... yet.
    return {
        ...plan,
    };
}

export function mapSingleTokenFromServerToApp(token) {
    return {
        ...token,
        invoices: map(token.invoices, (invoice) => ({
            ...invoice,
            redemptions: map(invoice.redemptions, (redemption) => ({
                ...redemption,
                "clientName": `${invoice.clientFirstName} ${invoice.clientLastName}`,
                "invoiceNumber": invoice.invoiceNumber,
                "invoiceId": invoice.invoiceId,
            })),
        })),

    }
}

export function mapInvoicesFromServerToApp(serverData) {
    return map(serverData, sd => {
        return {
            ...sd,
            id: sd.invoiceId,
        }
    })
}

export function mapMappingsFromServerToApp(mappings) {
    return map(mappings, (mapping) => mapSingleMappingFromServerToApp(mapping));
}

export function mapSingleMappingFromServerToApp(mapping) {
    return {
        ...mapping,
        myProductName: mapping.name,
        name: null,
    };
}

/**
 * Map from a server response body to our "local" data structure. We ignore superfluous server response properties to
 * minimize confusion from having multiple similar properties in our local data.
 */
export function mapParIssuesFromServerToApp(serverParIssues) {

    function mapParIssueReasons(serverData) {
        return map(serverData, reason => {
            const reasonInfo = reason.info.split(" / ") || [];
            const reasonLabel = reason.reason.split(": ") || [];
            const countInfo = reasonInfo?.[1] ? reasonInfo[1].split(" * ") : [];

            return {
                ...reason,
                label: reasonLabel?.[0] || "",
                clinicProduct: reasonLabel?.[1] || "",
                description: countInfo?.[0] || "",
                count: countInfo?.[1] || 0,
                descriptor: reasonInfo?.[3] || "",
                clinicProductId: reason.clinicProductID || null,
            };
        });
    }

    function mapParIssueRedemptions(serverData) {
        return map(serverData, redemption => {
            const mappedRedemption = {
                ...redemption,
                redemptionId: !!redemption.redemptionId ? redemption.redemptionId : redemption.groupRedemptionId,
                canApproveOrDecline: redemption.redemptionStateId === PAR_ITEM_PENDING
            }

            return {
                ...mappedRedemption,
                product: redemption.lineItemDescription,
                offerCode: redemption.offerDescriptionShort,
                pet: redemption.petName,
            };
        });
    }

    return map(serverParIssues, issue => {
        // Not returning the whole object because most of it is mapped above
        return {
            id: issue.invoiceId,
            invoiceNumber: issue.invoiceNumber,
            invoiceId: issue.invoiceId,
            extendedInvoiceNumber: issue.extendedInvoiceNumber,
            providerId: issue.providerId,
            invoiceDate: issue.invoiceDate,
            reasons: mapParIssueReasons(issue.reasons),
            redemptions: mapParIssueRedemptions(issue.redemptions),
        };
    });
}

export function mapClinicTilesFromServerToApp(serverData) {
    if (!serverData) {
        return {};
    }

    return {
        period: serverData.period,
        lastUpdate: serverData.lastUpdate,
        items: serverData.items,
    };
}

function mapClinicPIMSUserDataFromServerToApp(clinicPimsUsers) {
    return map(clinicPimsUsers, pimsUser => ({
        ...pimsUser,
        id: pimsUser.pimsUserId,
    }));
}

export function mapOnGardReviewDataFromServerToApp(serverData) {
    const updatedData = map(serverData.data, item => ({
        ...item,
        id: item.onGardTaskId,
    }));

    return {
        ...serverData,
        data: updatedData,
    };
}

function getTaskIdByGameType(gameType) {
    switch (gameType) {
        case GAME_TYPES.ON_GARD:
            return { task: "OnGardTaskId", addOnFieldId: "onGardTaskId"};
        case GAME_TYPES.PUPPY_BOWL:
            return { taskIdField: "PuppyBowlTaskId", addOnFieldId: "puppyBowlTaskId"};
        case GAME_TYPES.PUPPY_POOL_PARTY:
            return { taskIdField: "PuppyPoolPartyTaskId", addOnFieldId: "puppyPoolPartyTaskId"}
        case GAME_TYPES.LEAF_PARTY:
            return { taskIdField: "LeafPartyTaskId", addOnFieldId: "puppyLeafPartyTaskId"}
        default:
            return { taskIdField: "taskId", addOnFieldId: "taskId"};
    }
}
export function mapGameDataFromServer(serverData) {
    const {taskIdField, addOnFieldId} = getTaskIdByGameType(serverData.gameType);
    const updatedAddOnConfig = map(serverData.addOnConfig, configItem => ({ // An array of all add on config objects with a add on field id attached to each object
        ...configItem,
        id: configItem[addOnFieldId],
    }));

    const updatedConfigTasks = map(serverData.config?.Tasks, task => ({ // An array of all config task objects with a task id attached to each object
        ...task,
        id: task[taskIdField],
    }));

    const updatedConfig = { // Creates a variable to store the updated config
        ...serverData.config,
    }
    if (updatedConfigTasks.length) { // assigns the tasks section of the updated config variable to the updated config array
        updatedConfig.Tasks = updatedConfigTasks;
    }

    return { // returns the server data but overwrites addOnConfig with the updated addOnConfig array, and config with the updated config array
        ...serverData,
        addOnConfig: updatedAddOnConfig,
        config: updatedConfig,
    }
}

export function mapGameTemplateDataFromServer(serverData) {
    return mapGameDataFromServer(serverData);
}

export function mapRoiDataFromServerToApp(serverData, clinicList = []) {
    // Merge the home dose and regular product data
    const brands = {};
    const updatedData = produce(serverData, newServerData => {
        // Set a default homeDose value for each regular product
        forEach(newServerData?.gamesReviewDataAggregateVM?.data, (d) => {
            d.homeCDoses = 0;
            d.homeChangeDoses = 0;
            d.homePDoses = 0;

            brands[d.brandName] = {
                name: d.brandName,
                brandId: d.productBrandId,
            };
        });

        // THIS IS IMPORTANT!!! AND DIFFERENT THAN THE forEach ABOVE -- GRANT
        // Update the regular products to have the correct values for homeDoses (merge homeDose and regular data)
        forEach(newServerData?.gamesHomeSalesDataAggregateVM?.data, (homeData) => {
            // Find the regular product that matches the home dose product
            const index = findIndex(
                (serverData.gamesReviewDataAggregateVM?.data||[]),
                {
                    clinicId: homeData.clinicId,
                    manufacturerId: homeData.manufacturerId,
                    productBrandId: homeData.productBrandId,
                    brandName: homeData.brandName
                }
            );

            if(index >= 0) {
                // Match home dose product to a normal product
                newServerData.gamesReviewDataAggregateVM.data[index].homeCDoses = homeData?.cDoses || 0;
                newServerData.gamesReviewDataAggregateVM.data[index].homeChangeDoses = homeData?.changeDoses || 0;
                newServerData.gamesReviewDataAggregateVM.data[index].homePDoses = homeData?.pDoses || 0;
            } else {
                // Add a product to the normal review data if there isn't a match
                newServerData.gamesReviewDataAggregateVM.data.push({
                    ...homeData,
                    cDoses: 0,
                    cPaidDoses: 0,
                    changeDoses: 0,
                    pDoses: 0,
                    pPaidDoses: 0,
                    homeCDoses: homeData?.cDoses || 0,
                    homeChangeDoses: homeData?.changeDoses || 0,
                    homePDoses: homeData?.pDoses || 0
                });
            }
        });
    });

    const newData = [
        ...updatedData?.gamesReviewDataAggregateVM?.data,
    ];

    forEach(clinicList, clinic => {

        const matches = filter(newData, row => row.clinicId === clinic.clinicId);
        if(!matches.length||matches.length !== Object.keys(brands).length) {
            forEach(brands, brand => {
                if (!find(matches, match => match.productBrandId === brand.brandId)) {

                    newData.push({
                        brandName: brand.name,
                        cDoses: -1, // Not sure why all these are set to -1, changing them to 0
                        cPaidDoses: -1,
                        changeDoses: -1,
                        clinicId: clinic.clinicId,
                        clinicName: clinic.clinicName,
                        manufacturerId: 1,
                        manufacturerName: "Boehringer Ingelheim",
                        pDoses: -1,
                        pPaidDoses: -1,
                        preferredPackageSize: 6,
                        preferredPackageUnit: "pk",
                        productBrandId: brand.brandId,
                    });
                }
            });
        }
    })
    return {
        ...updatedData?.gamesReviewDataAggregateVM,
        data: newData,
    };
}

// map for Provider Game Details (and individual Clinic Games)
export function mapSingleGameFromServerToApp(game) {
    if (
        game.gameType === GAME_TYPES.GROWTH ||
        game.gameType === GAME_TYPES.GROWTH_NGP
    ) {
        return {
            ...game,
            id: game.greenlineGameId,
            type: game.gameType,
            previousDoses: get(game, "details.Clinics[0].PreviousDoses"),
            previousDosesPerDay: get(game, "details.Clinics[0].PreviousDosesPerDay"),
            clinicId: get(game, "details.Clinics[0].ClinicId"),
            totalDoses: get(game, "details.Clinics[0].CurrentPeriod.TotalReportDoses"),
            homeDeliveryDoses: get(game, "details.Clinics[0].CurrentPeriod.TotalDeliverySalesDoses", 0) || 0,
            includeHomeDeliveryDoses: game.includeHomeDeliveryDoses || game.homeDeliveryDosesIncluded,
            lastInvoiceDate: get(game, "details.LastReportingInvoiceDate"),
        }
    }

    if (
        game.gameType === GAME_TYPES.DEATH_MATCH ||
        game.gameType === GAME_TYPES.DEATH_MATCH_NGP ||
        game.gameType === LEAF_PARTY
    ) {
        const teams = map(game.data.data, team => {
            const clinic = find(game.details.Clinics, {ClinicId: team.clinicid});
            const doses = clinic?.CurrentPeriod?.TotalReportDoses || 0;
            const previousDoses = clinic?.PreviousPeriod?.TotalReportDoses || 0;
            return {
                name: clinic?.ClinicName || null,
                alias: team.alias,
                growth: !!(doses && previousDoses) ? Math.round((doses/previousDoses) * 100) : 0,
                doses: Math.round(doses),
                previous: Math.round(previousDoses),
                clinicId: team.clinicid,
                id: team.id,
            }
        });

        return {
            ...game,
            id: game.greenlineGameId,
            type: game.gameType,
            teams,
            includeHomeDeliveryDoses: game.includeHomeDeliveryDoses || game.homeDeliveryDosesIncluded,
            lastInvoiceDate: game.details.LastReportingInvoiceDate,
        }
    }

    if (game.gameType === GAME_TYPES.PUPPY_BOWL) {
        /*
        * game.details = Snapshot data
        * game.data.Clinics
        * */
        const teams = map(game.details, team => {
            const {ClinicId, ...restOfTeam} = team;
            const clinic = find(game.data.Clinics, {ClinicId: ClinicId});
            const previousDoses = clinic?.PreviousPeriod.TotalReportDoses || 0;

            if (clinic) {
                const {
                    TotalPuppyBowlFieldGoals,
                    TotalPuppyBowlMVPs,
                    TotalPuppyBowlPoints,
                    TotalPuppyBowlTouchdowns,
                    TotalReportDoses
                } = clinic?.CurrentPeriod || {};

                return {
                    ...restOfTeam,
                    clinicId: ClinicId,
                    name: clinic?.ClinicName || null,
                    alias: team.ClinicAlias,
                    fieldGoals: TotalPuppyBowlFieldGoals,
                    touchdowns: TotalPuppyBowlTouchdowns,
                    mvps: TotalPuppyBowlMVPs,
                    points: TotalPuppyBowlPoints,
                    totalDoses: TotalReportDoses,
                    previousDoses,
                }
            }

            return {
                ...restOfTeam,
                clinicId: ClinicId,
                name: clinic?.ClinicName || null,
                alias: team.ClinicAlias,
                fieldGoals: 0,
                touchdowns: 0,
                mvps: 0,
                points: 0,
                PercentGrowth: 0,
                totalDoses: 0,
                previousDoses,
            }
        });

        return {
            ...game,
            id: game.greenlineGameId,
            type: game.gameType,
            teams,
            includeHomeDeliveryDoses: game.includeHomeDeliveryDoses || game.homeDeliveryDosesIncluded,
            lastInvoiceDate: game.details.LastReportingInvoiceDate,
        }
    }

    if (game.gameType === PUPPY_POOL_PARTY) {
        /*
        * game.details = Snapshot data
        * game.data.Clinics
        * */
        const teams = map(game.details, team => {
            const {ClinicId, ...restOfTeam} = team;
            const clinic = find(game.data.Clinics, {ClinicId: ClinicId});
            const previousDoses = clinic?.PoolPartySummary?.PreviousPeriod?.TotalDoses || 0;

            if (clinic) {
                const {
                    TotalDoses
                } = clinic?.PoolPartySummary?.CurrentPeriod || {};

                return {
                    ...restOfTeam,
                    clinicId: ClinicId,
                    name: clinic?.ClinicName || null,
                    alias: team.ClinicAlias,
                    totalDoses: TotalDoses,
                    previousDoses,
                }
            }

            return {
                ...restOfTeam,
                clinicId: ClinicId,
                name: clinic?.ClinicName || null,
                alias: team.ClinicAlias,
                totalDoses: 0,
                previousDoses,
            }
        });

        return {
            ...game,
            id: game.greenlineGameId,
            type: game.gameType,
            teams,
            includeHomeDeliveryDoses: game.includeHomeDeliveryDoses || game.homeDeliveryDosesIncluded,
            lastInvoiceDate: game.details.LastReportingInvoiceDate,
        }
    }

    return {
        ...game,
        id: game.greenlineGameId,
        type: game.gameType,
    };
}

// Mapping for clinic (and provider) games
export function mapClinicGamesFromServerToApp(clinicGames) {
    return map(filter(clinicGames, (game) => !!game?.data), (game) => mapSingleGameFromServerToApp(game));
}

export function mapClinicPIMSUsersFromServerToApp(serverData) {
    if(!serverData) {
        return {};
    }

    const { clinicPimsUsers, staffTypeNameIds, clinicName } = serverData;

    return {
        staffTypes: map(staffTypeNameIds, sType => ({ name: sType.name, value: sType.id })),
        clinicName: clinicName,
        users: mapClinicPIMSUserDataFromServerToApp(clinicPimsUsers)
    }
}

export function mapClinicDvmCommissionsFromServerToApp(serverData) {
    /*
    Example response body:
    {
    "totalRecordCount": 8,
    "recordCount": 8,
    "limit": 9999,
    "offset": 0,
    "data": [
        {
        "clinicDvmId": 753,
        "clinicDvmHistoryId": 754,
        "pimsUserId": 625110,
        "firstName": "Dr Brown Refills",
        "lastName": "",
        "startDate": "0001-01-01T00:00:00",
        "endDate": null,
        "isActive": true,
        "lastApprovalDate": null,
        "productsCommissionPercentage": 0,
        "servicesCommissionPercentage": 0,
        "providerDvmIdentifier": "25484",
        "mappingInitials": null,
        "isDeleted": false
        },
    ]}
    */

    if (!serverData) {
        return {};
    }

    return {
        ...serverData,
        id: serverData.clinicDvmId,
    };
}


export function mapClinicDvmsFromServerToApp(data) {
    return map(data, (dvm) => ({
        ...dvm,
        id: dvm.clinicDvmId,
    }));
}

export function mapRedemptionsFromServerToApp(data, year) {
    return {
        clinicId: data.clinicId,
        year: data.year || year,
        clinicName: get(data, "clinicData.clinicName", null),
        results: get(data, "clinicData.results", []),
        clinicTotal: get(data, "clinicData.clinicTotal", {}),
        grandTotal: get(data, "grandTotal", {}) || {},
    }
}

export function mapCustomClinicViewFromServerToApp(data) {
    const value = data.value ? JSON.parse(data.value) : {};
    return {
        ...data,
        value: {
            ...value,
            filters: map(value.filters, filter => {
                return {
                    ...filter,
                    isComplete: true,
                }
            })
        },
    }

}

export function mapCustomClinicViewsFromServerToApp(data) {
    return map(data, d => {
        return {
            ...mapCustomClinicViewFromServerToApp(d),
        }
    })

}

export function mapCustomClinicViewFromAppToServer(data) {
    const value = data.value ? JSON.stringify(data.value) : "{}";
    return {
        ...data,
        value: value
    }
}

export function mapClinicPimsFromServerToApp(data) {
    const config = data.config ? JSON.parse(data.config) : [];
    return {
        ...data,
        config
    }
}

export function mapClinicDashboardFromServerToApp(data) {
    //Add the number of unread notifications to stats.
    const stats = [...data?.stats?.items];
    const unreadNotificationsLength = data?.notifications?.items?.length || 0;
    stats.push({
        id: 'UnreadNotifications',
        lastUpdate: new Date(),
        value: unreadNotificationsLength,
    })

    return {
        ...data,
        stats: {
            ...data.stats,
            items: stats,
        }
    }
}

// OLD SITE (here for reference during reverse engineering - delete when no longer useful)
// const DEFAULT_PROVIDER_GAME_DATA_GLQA = {
//     "greenlineGameId": 0,
//     "name": null,
//     "startDate": null, //"4/17/2021",
//     "originalStartDate": null,
//     "endDate": null,
//     "greenlineGameTemplateId": 0,
//     "copiedFromGreenlineGameId": null,
//     "gameState": null, //"Draft",
//     "newGameState": null,
//     "gameType": null, //"GrowthDoses",
//     "gameTypeVersion": null,
//     "durationDays": 0,
//     "rounds": null,
//     "comparisonPeriod": null, //"YearOverYear",
//     "productType": null, //"Parasiticide",
//     "products": [
//         // { "productBrandId": 61 },
//         // { "productBrandId": 53 },
//         // { "productBrandId": 5 },
//         // { "productBrandId": 50 },
//         // { "productBrandId": 7 },
//         // { "productBrandId": 8 },
//         // { "productBrandId": 93 }
//     ],
//     "clinics": [],
//     "reportEmailAddress": null,
//     "providerId": 0,
//     "config": {
//         "MaxClinics": 10,
//         "IncreasePercentageLocked": false,
//         "PrizeAmountLocked": false,
//         "EstROILocked": true,
//         "Levels": [
//             { "IncreasePercentage": null, "PrizeAmount": 50, "EstROI": 10 },
//             { "IncreasePercentage": null, "PrizeAmount": 100, "EstROI": 10 },
//             { "IncreasePercentage": null, "PrizeAmount": 150, "EstROI": 10 }
//         ],
//     },
//     "gameSettings": null,
//     "addOnConfig": null,
//     "canUseAsTemplate": true,
//     "isTemplate": false,
//     "description": null,
//     "globalId": null,
//     "ownerUserId": 0,
//     "excludeFreeDoses": false,
//     "hasStarted": false,
//     "hasCompleted": false,
// };

export function mapProviderGameFromServerToApp(data) {
    // Based on response from https://gl-webapi-dev.azurewebsites.net/v1/services/bigames/games
    const DEFAULT_PROVIDER_GAME_DATA = {
        "greenlineGameId": 0, //5174,
        "name": null, //"Win a Free Breakfast or Lunch",
        "startDate": null, //"3/1/21",
        "endDate": null, //"3/30/21",
        "clinicCount": 0,
        "gameType": null, //"GrowthDoses",
        "gameTypeVersion": null,
        "gameState": ProviderGameStateIds.DRAFT,
        "durationDays": 0, //30,
        "rounds": null,
        "clinicName": null, //"Ambassador Animal Hospital",
        "clinicLocation": null, //"Columbia, SC",
        "clinicId": 0, //11229,
        "comparisonPeriod": null, ///"YearOverYear",
        "productType": null, //"Parasiticide",
        "isTemplate": false,
        "canUseAsTemplate": false,
        "description": null, //"Year-over-Year / 30 days",
        "title": null, //"Growth (Doses) - Parasiticides",
        "config": {
            "MaxClinics": 10,
            "IncreasePercentageLocked": false,
            "PrizeAmountLocked": false,
            "EstROILocked": false,
            "Levels": [
                { "IncreasePercentage": null, "PrizeAmount": 50, "EstROI": 10 },
                { "IncreasePercentage": null, "PrizeAmount": 100, "EstROI": 10 },
                { "IncreasePercentage": null, "PrizeAmount": 150, "EstROI": 10 }
            ],
        },
        "modDate": null, //"2021-02-27T13:46:10.767",
        "mostRecentGameSnapshotId": null,
        "mostRecentGameSnapshotImagePath": null,
        "excludeFreeDoses": false,
        "mostRecentGameSnapshotDate": null,
        "snapshotDaysInto": null,
        "leaderboardLabel": null,
        "productBrands": [ // Ehhh.. should be id numbers, not strings
            // "Centragard",
            // "Frontline Gold",
            // "Heartgard Plus",
            // "Nexgard"
        ],
        "isComplete": false,
    };

    const mappedData = {
        ...DEFAULT_PROVIDER_GAME_DATA,
        ...data,
    };

    // TODO: Handle special cases

    return mappedData;
}

export function mapProviderGameTemplateFromServerToApp(data) {
    // Based on response from https://gl-webapi-dev.azurewebsites.net/v1/services/bigames/templates?userId=1
    const DEFAULT_PROVIDER_GAME_TEMPLATE = {
        "greenlineGameTemplateId": 0, //6
        "name": null, //"30 day single clinic parasiticide",
        "gameType": null, //"GrowthDoses",
        "gameTypeVersion": null,
        "startDate": null,
        "durationDays": 0,
        "rounds": null,
        "comparisonPeriod": null, //"YearOverYear",
        "productType": null, //"Parasiticide",
        "productBrands": [], //"[61,53,5,50,7,8,93]",
        "canClone": false,
        "isGlobal": true,
        "configJson": "{\"MaxClinics\":10,\"IncreasePercentageLocked\":false,\"PrizeAmountLocked\":false,\"EstROILocked\":true,\"Levels\":[{\"IncreasePercentage\":null,\"PrizeAmount\":50.00,\"EstROI\":10.0},{\"IncreasePercentage\":null,\"PrizeAmount\":100.00,\"EstROI\":10.0},{\"IncreasePercentage\":null,\"PrizeAmount\":150.00,\"EstROI\":10.0}]}",
        "displayTitle": null, //"30 Day Single Clinic Parasiticide",
        "description": null, //"Year-over-Year / 30 days / 7 product(s)",
        "title": null, //"Growth (Doses) - Parasiticides",
        "excludeFreeDoses": false,
        "canUseAsTemplate": true,
        "visibilityStartDate": null,
        "visibilityEndDate": null,
    };

    return {
        ...DEFAULT_PROVIDER_GAME_TEMPLATE,
        ...data,
    };
}


export function mapProductOverridesFromServerToApp(serverData) {
    const flatQuantityList = flatMap(serverData.productConfig.dosePricing, "quantity");
    const otherDosePrices = reject(serverData.clinicConfig.dosePricing, cdp => { return (includes(flatQuantityList, cdp.quantity) || cdp.isDeleted) });
    const defaultConfigs = map(serverData.productConfig.dosePricing, (c) => {
        const clinicConfig = find(serverData.clinicConfig.dosePricing, {quantity: c.quantity});
        return {
            quantity: c.quantity,
            masterProductMinPrice: c.productMinPrice,
            masterProductMaxPrice: c.productMaxPrice,
            productMinPrice: clinicConfig?.productMinPrice || null,
            productMaxPrice: clinicConfig?.productMaxPrice || null,
            canDelete: false
        }
    });
    const clinicConfigs = map(otherDosePrices, (c) => {
        return {
            quantity: c.quantity,
            masterProductMinPrice: null,
            masterProductMaxPrice: null,
            productMinPrice: c.productMinPrice,
            productMaxPrice: c.productMaxPrice,
            canDelete: true
        }
    });
    return {
        ...serverData,
        configs: map(concat(defaultConfigs, clinicConfigs), (d, index) => {
            //Add the Order to the dosePricing so I can distinguish which one is being updated...
            return {
                order: index + 1,
                ...d
            }
        })
    }
}

export function mapVetCheckContentFromServerToApp(serverData) {
    return map(serverData, data => {
        return mapVetCheckSingleContentFromServerToApp(data);
    })

}

export function mapVetCheckSingleContentFromServerToApp(data) {
    // TODO: update to be generic for all content types
    return{
        ...data,
        keywords: words(data.keywords, /[^,]+/g),
    };
}


export function mapPharmacyProductsFromServerToApp(serverData) {
    return {
        pharmacy: serverData,
        pharmacyProductTypes: uniqBy(map(serverData, sd => {
            return {name: sd.productTypeName, value: sd.productTypeId}
        }), "value"),
    };
}

export function mapFoodProductsFromServerToApp(serverData) {
    return {
        food: serverData,
        foodProductTypes: uniqBy(map(serverData, sd => {
            return {name: sd.productTypeName, value: sd.productTypeId}
        }), "value"),
    };
}

export function mapVaccinesFromServerToApp(serverData) {
    return{
        vaccines: serverData,
        vaccineTypes: uniq(flatMap(serverData, sd => {return sd.tags})),
    };
}

export function mapProductMappingsFromServerToApp(serverData) {
    const getProducts = (data) => {
        data = map(data, (value, index) => ({ ...value, order: index }));
        data = groupBy(data, "productId")
        data = map(data, (value, key) => ({
            productId: key,
            mappings: keyBy(value, "clinicProductId")
        }));
        return data;
    }
    return {
        products: getProducts(serverData),
        totalMappings: serverData.length
    };
}

export function mapVaccineMappingsFromServerToApp(serverData) {
    const getVaccines = (data) => {
        data = map(data, (value, index) => ({ ...value, order: index }));
        data = groupBy(data, "vaccineId")
        data = map(data, (value, key) => ({
            vaccineId: key,
            mappings: keyBy(value, "clinicProductMappingId")
        }));
        return data;
    }
    return {
        vaccines: getVaccines(serverData),
        totalMappings: serverData.length
    };
}

export function mapServiceMappingsFromServerToApp(serverData) {
    const getServices = (data) => {
        data = map(data, (value, index) => ({ ...value, order: index }));
        data = groupBy(data, "examServiceId")
        data = map(data, (value, key) => ({
            examServiceId: key,
            mappings: keyBy(value, "mappingId")
        }));
        return data;
    };
    return {
        services: getServices(serverData),
        totalMappings: serverData.length
    };
}

export function mapVetCheckHandoutMappingsFromServerToApp(serverData) {
    const getVetCheckContent = (data) => {
        data = map(data, (value, index) => ({ ...value, order: index }));
        data = groupBy(data, "clinicHandoutId")
        data = map(data, (value, key) => ({
            clinicHandoutId: key,
            mappings: keyBy(value, "mappingId")
        }));
        return data;
    };
    return {
        vetCheckContent: getVetCheckContent(serverData),
        totalMappings: serverData.length
    };
}

export function mapWellnessMappingsFromServerToApp(serverData) {
    const getServiceType = (data) => {
        data = groupBy(data, "serviceTypeId")
        data = map(data, (value, key) => ({
            serviceTypeId: Number(key),
            mappings: keyBy(value, "mappingInstanceId")
        }));
        return data;
    };

    const getWellness = (data) => {
        data = groupBy(data, "clinicPriceScheduleId")
        data = map(data, (value, key) => ({
            clinicPriceScheduleId: Number(key),
            totalMappings: value.length,
            serviceTypes: keyBy(getServiceType(value), "serviceTypeId")
        }));
        return data;
    };

    return getWellness(serverData);
}

export function mapPriceHistoryAnalysisFromServerToApp(serverData) {
    //Combine Quantity and Price Data
    const data = map(serverData.quantities, (q, index) => {
        return {
            sortOrder: index,
            quantity: q,
            price: serverData.prices[index]
        }
    })

    const quantityList = sortBy(flatMap(uniqBy(data, "quantity"), "quantity"));

    return map(quantityList, q => {
        const relatedData = filter(data, {quantity: q});

        const sold = relatedData.length;
        const mean = round((sumBy(relatedData, "price") / sold), 2);
        const min = orderBy(relatedData, ["price"], ["asc"])[0].price;
        const max = orderBy(relatedData, ["price"], ["desc"])[0].price;

        return {
            quantity: q,
            sold,
            mean,
            min,
            max
        }

    })
}

export function mapSingleNotificationFromServerToApp(serverData) {
    const states = serverData?.states || [];

    if (
        (
            serverData?.notificationType === "LapsedUserOptIn" ||
            serverData?.notificationType === "PuppyGraduationOptIn" ||
            serverData?.notificationType === "MerckAdobeOnly"
        ) && serverData?.enrollmentState === 'declined'
    ) states.declined.buttonText = 'Cancel';

    return {
        ...serverData,
        formFieldsJson: !!serverData?.formFieldsJson ? JSON.parse(serverData.formFieldsJson) : {},
        controlParams: !!serverData?.controlParams ? JSON.parse(serverData.controlParams) : {},
        ...mapValues(states, i => { return {
            supported: (i.supported !== false),
            displayText: i.displayText || "",
            buttonText: i.buttonText || ""
        }})
    };
}

export function mapProviderDashboardFromServerToApp(serverData) {
    return {
        ...serverData,
        notifications: map(serverData.notifications.items, notification => {
            return mapSingleProviderNotificationFromServerToApp(notification);
        })
    };
}

export function mapSingleProviderNotificationFromServerToApp(notification) {
    return {
        ...notification,
        enrollmentState: notification.userActionState
    };
}

export function mapProductTagsFromServerToApp(productTags) {
    return map(productTags, productTag => {
        return mapSingleProductTagsFromServerToApp(productTag);
    })
}

export function mapSingleProductTagsFromServerToApp(productTag) {
    // TODO: Remove this string replace after the data is fixed
    return {
        ...productTag,
        productName: replace(productTag.productName, "DECLINED: ", "")
    }
}


export function mapVetCheckAutomationsFromServerToApp(mappings, products) {
    return map(mappings, mapping => {
        return mapSingleVetCheckAutomationFromServerToApp(mapping, products);
    })
}

export function mapSingleVetCheckAutomationFromServerToApp(mapping, products) {
    const product = find(products, {
        clinicHandoutId: mapping.clinicHandoutId,
        contentType: mapping.contentType
    }) || {};
    return {
        ...mapping,
        ...product,
        keywords: words(product.keywords, /[^,]+/g),
    }
}

export function mapSingleStripeSubscriptionPriceFromServerToApp(price) {
    let recurrence = price.recurrance.interval;
    if(price.recurrance.interval === "month" && price.recurrance.intervalCount === 3) {
        recurrence = "quarter";
    }

    return {
        ...price,
        recurrence: capitalize(recurrence)
    }
}

export function mapStripeSubscriptionPricesFromServerToApp(serverData) {
    return map(serverData, price => {
        return mapSingleStripeSubscriptionPriceFromServerToApp(price);
    })
}


export function mapExternalPricesFromServerToApp(serverData) {
    // const test = keyBy(map(groupBy(map(serverData, price => {
    //     // Returns the prices grouped by quantity
    //     const pricesByQuantity = groupBy(price.externalPrices, "quantity");
    //
    //     // Returns the rows as they are needed for the small price table
    //     const oneRow = map(pricesByQuantity, (p, index) => {
    //         const chewy = find(p, {externalPriceProviderId: 4});
    //         const petMed = find(p, {externalPriceProviderId: 3});
    //         const ourPrice = find(p, {externalPriceProviderId: null});
    //         return {
    //             quantity: Number(index),
    //             chewyPrice: chewy?.price || 0,
    //             chewyPriceUrl: chewy?.productPriceUrl || 0,
    //             chewyModDate: chewy?.modDate || null,
    //             petMedPrice: petMed?.price || 0,
    //             petMedPriceUrl: petMed?.productPriceUrl || 0,
    //             petMedModDate: chewy?.modDate || null,
    //             ourPrice: ourPrice?.price || 0,
    //         }
    //     });
    //     return {
    //         ...price,
    //         quantities: map(price.externalPrices, "quantity"),
    //         externalPrices: keyBy(oneRow, "quantity")
    //     }
    // }), "brandId"), (p, index) => {
    //
    //     return {
    //         brandId: index,
    //         quantities: orderBy(uniq(flatMap(p, "quantities")), q => q),
    //         // Change to be productId,
    //         products: keyBy(p, "externalProductId")
    //     };
    // }), "brandId");
    // // logger.log(test, "TEST");
    // return test;
    // mapValues(serverData, price => {
    //     return {
    //         ...price,
    //         products: mapValues(price.products, product => {
    //             return {
    //                 ...product,
    //                 prices: map(product.prices, p => {
    //                     logger.log(p, "P");
    //                     return p;
    //                 })
    //             }
    //         })
    //     }
    // })
    return serverData;
}

export function mapProvidersFromServerToApp(serverData) {
    return map(serverData, provider => {
        let newShortName = null;
        let hasInstantRebates = false;
        // Only include the short name if it is not the same as the "name" value
        if(!!provider.shortName && (provider.shortName !== provider.name)) {
            newShortName = provider.shortName
        }
        // Show that BI has instant rebates
        if(provider.id === BOEHRINGER_INGELHEIM) {
            hasInstantRebates = true;
        }

        return {
            ...provider,
            providerId: provider.id,
            providerName: provider.name,
            mediumImageURI: provider.mediumImageUri,
            shortName: newShortName,
            manufacturerParticipationList: flatMap(filter(provider.providerVisibility, "isVisible"), "providerId"),
            hasInstantRebates
        }
    })
}

export function mapStrayCouponsFromServerToApp(serverData) {
    // Add the InvoiceId as ID
    const mappedData = map(serverData, (sd, index) => ({
        ...sd,
        id: sd.invoiceId,
        defaultOrder: index, // Api returns the data ordered by invoiceDate, and we need to translate that over into a field since it is keyed by invoiceId in redux
    }));

    // Separate the resolved from the unresolved
    const ineligible = filter(mappedData, s => s.isIneligible && !s.resolvedDate);
    const resolved = filter(mappedData, s => !!s.resolvedDate);
    const unresolved = filter(mappedData, s => (!s.resolvedDate && !s.isIneligible && !s.isExpired));
    const expired = filter(mappedData, { isExpired: true, resolvedDate: null });

    return {
        resolved,
        unresolved,
        ineligible,
        expired,
    };
}

export function mapGoGreenRequirementsFromServerToApp(serverData) {
    return {
        ...serverData,
        currentOptInDropDownState: JSON.parse(serverData.currentOptInDropDownState),
        clooOptInDropDownItems: map(serverData.clooOptInDropDownItems, i => {
            return {
                ...i,
                name: i.text
            }
        })
    }
}

export function mapNotificationFromServerToApp(serverData) {
    let optinStatesJson;
    try {
        optinStatesJson = JSON.parse(serverData.optinStatesJson)
    } catch (e) {
        optinStatesJson = {}
    }
    return {
        ...serverData,
        ...mapValues(optinStatesJson, i => { return {
            supported: (i.supported !== false),
            displayText: i.displayText || "",
            buttonText: i.buttonText || ""
        }})
    }
}

export function mapAllNotificationsFromServerToApp(serverData) {
    return map(serverData, notification => {return mapNotificationFromServerToApp(notification)});
}

export function mapNotificationFromAppToServer(serverData) {
    let optinStates = {
        cancelled: serverData.cancelled,
        declined: serverData.declined,
        enrolled: serverData.enrolled,
        pending: serverData.pending,
        liveChat: serverData.liveChat
    }
    return {
        ...serverData,
        optinStatesJson: JSON.stringify(optinStates)
    };
}

export function mapWellnessDashboardTilesFromServerToApp(serverData) {
    const tiles = map(serverData.dashboardTiles, (sd, index) => {

        let tileType = sd.valueType;

        if (sd.valueType == "money") {
            tileType = "Currency";
        } else if (sd.valueType == "integer") {
            tileType = "Number";
        }

        return {
            id: `wellTile_${index}`,
            ...sd,
            title: sd.tileName,
            value: sd.tileValue.toString(),
            type: tileType,

        }
    })
    return {
        ...serverData,
        tiles,
    }
}
