import React from "react";
import {
	MAIN_COLOR,
	SECONDARY_COLOR,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./PuppyPodiumCenter.scss";


export default function PuppyPodiumCenter(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg id="Winning_-_Puppy" data-name="Winning - Puppy" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 300">
			<g id="Puppy_Winner_Gold01">
				<g id="Puppy_Winner_Gold01_Lower">
					<g>
						<path className={styles.cls11}
						      d="M141.47,252.89c1.88,2.66,3.92,5.22,6.2,7.61,2.74,2.86,22.76,14.85,15.15,19.45-1.15.7-2.61.85-3.95.56-4.53-.99-9.13-2.12-13.14-4.21-6.61-3.45-10.96-9.17-13.89-15.49.73-1.74,1.8-3.33,3.18-4.62,1.79-1.67,4.06-2.81,6.46-3.29Z"/>
						<path className={styles.cls9}
						      d="M130.32,238.79c.55.04,1.2.24,1.95.64,3.51,1.83,4.98,7.05,6.93,10.11.72,1.13,1.48,2.25,2.26,3.35-2.4.48-4.67,1.62-6.46,3.29-1.38,1.29-2.45,2.88-3.18,4.62-1.3-2.8-2.32-5.73-3.13-8.63-.57-2.03-3.14-13.73,1.62-13.38Z"/>
					</g>
					<path className={styles.cls10}
					      d="M185.65,266.24c-.12-.75-.18-1.52-.17-2.28.05-3.85,1.59-7.64,3.63-11.16,4.66-8.02,12.73-16.35,22.76-19.83,9.08-3.15,28.23-4.7,34.66,2.21,1.11,1.19,1.8,2.56,2.31,3.94,3.84,10.3-2.38,22.14-14.83,28.24,4.89,1.23,10.76,2.81,10.76,9.01,0,8.6-29.6,8.33-30.36.24-.32-3.4-.18-3.17.33-6.29-2.74,3.86-4.56,3.9-9.53,5.47s-10.85,1.14-14.77-1.63c-2.74-1.94-4.32-4.85-4.81-7.91Z"/>
					<path className={styles.cls10}
					      d="M290.52,63.54c10.41,13.76-16.08,26.26-17.41,28.19-6.29,9.08-4.47,40.36-28.37,74.89-1.73,2.5-32.79,1.29-17.78-27.72,10.49-20.26,23.13-36.7,34.5-60.93,6.99-14.88,26.26-18.13,29.06-14.43Z"/>
					<g>
						<path className={styles.cls9}
						      d="M201.73,263.13c10.45-.36,46.4-36.03,39.23-88.73-1.29-9.5-1.86-20.17-5.7-30.85-6.41-3.38-19.96-3.82-29.68-5.25-6.61-.97-21.51-3.72-27.34,1.15-5.53,4.63-13.39,19.04-13.39,28.75s2.11,19.2.41,27.81c-4.59,23.27-13.41,41.51-15.84,49.7-8.31,28.06,44.64,17.69,52.31,17.43Z"/>
						<path className={styles.cls10}
						      d="M172.22,239.98c-1.61-17.54,30.15-23.41,37.67-10.04,18.46-12.69,26.16-35.39,26.48-59.45.04-3.31-.25-18.54-2-22.94-9.87,7.55-52.06,7.81-49.14-1.25,1.25-3.87,46.99-6.02,50.24-4.04,23.08,43.52-8.96,123.33-31.38,122.95-6.27-.11-31.01-15.75-31.88-25.24Z"/>
					</g>
				</g>
				<path
					id="Shirt"
					className={styles.cls2}
					d="M242.74,179.42c-.77-9-.87-36.79-8.36-41.74l-3.19,2.24c5.53,8.09,7.44,30.63-2.44,34.61-9.11,3.67-14.47-3.47-17.64-9.25-8.69-15.79-29.85-34.43-40.95-25.23-11.42,9.46-6.96,47.71-8.01,58.9-.82,8.71-4.92,14.91-7.65,19.53,21.06,15.71,50.87,17.85,82.13,6.99,5.64-16.66,6.89-36.96,6.12-46.04ZM182.24,186.08c-10.54-.99-18.29-10.23-17.31-20.64.98-10.41,10.31-18.05,20.84-17.06,10.54.99,18.29,10.23,17.31,20.64-.98,10.41-10.31,18.05-20.84,17.06Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes">
					<path
						className={styles.cls13}
						d="M186.19,147c-11.28-1.06-21.31,7.16-22.36,18.31-1.05,11.16,7.28,21.09,18.56,22.15,11.28,1.06,21.31-7.16,22.36-18.31,1.05-11.16-7.28-21.09-18.56-22.15ZM182.65,184.7c-9.76-.92-16.96-9.5-16.06-19.13.9-9.63,9.58-16.72,19.33-15.81s16.96,9.5,16.05,19.13c-.9,9.63-9.58,16.72-19.33,15.81Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls13}
						d="M224.06,217.94c-8.88,1.66-17.77,2.33-26.4,1.95-13.91-.61-27.34-4.04-38.53-9.85.83-1.76,1.62-4.19,1.96-5.39,10.63,5.65,23.44,9.11,36.81,9.69,14.7.64,28.25-1.17,43.06-6.64.05.9-.71,4.49-.82,5.76-6.18,2.17-9.78,3.3-16.08,4.48Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls13}
						d="M223.55,177.19c-7.63,0-12.46-7.28-18.01-15.63-6.15-9.25-13.11-19.74-25.57-22.93l.71-2.77c13.52,3.47,20.81,14.44,27.24,24.12,5.6,8.43,10.02,15.09,16.8,14.28,3.61-.44,6.23-2.1,8-5.07,4.29-7.2,2.31-20.31-.46-27.33l2.66-1.05c2.78,7.04,5.31,21.36.26,29.84-2.22,3.74-5.63,5.91-10.12,6.45-.52.06-1.02.09-1.52.09Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Winner_Gold01_Mid">
					<g>
						<path className={styles.cls11}
						      d="M158.17,255.11c3.1,5.53,4.61,12,3.94,18.31-.28,2.62-1.04,5.34-2.44,7.54-.52-.12-1.09-.12-1.59-.3-15.06-2.35-15.45-19.49-7.57-38.53,2.98,2.68,5.69,9.49,7.65,12.99Z"/>
						<path className={styles.cls9}
						      d="M152.45,254.92c3.7-9.64,10.55-17.57,20.24-23.82,8.1-5.22,26.77-8.41,33.92-.65,1.23,1.34,2.06,2.9,2.73,4.49,4.94,11.84.03,26-11.71,33.75,5,1.19,11.51,2.94,11.69,9.98.3,11.92-26.84,10.48-30.61,3.26-1.79-3.42-.36-6.69.7-9.93-1.97,3.14-4.76,5.91-9.53,7.99-4.78,2.08-10.68,1.89-14.88-1.16-2.94-2.13-7.5-10.98-2.54-23.91Z"/>
					</g>
					<path className={styles.cls6}
					      d="M169.95,122.93c-2.18,2.72-.65,9.88.39,13.17.99,3.13,4.7,10.26,23.03,14.85,18.32,4.58,39.6-3.19,41.41-8.42,4.73-13.72-8.52-19.59-19.96-23.94-10.77-4.09-27.61-8.8-37.75-1.4-2.18,1.59-5.43,3.62-7.12,5.73Z"/>
					<path className={styles.cls12}
					      d="M181.21,145.43c4.5,7.61,36.2,14.32,46.88,11.55,6.41-4.62,11.61-13.91,11.36-18.6-.13-2.51-.47-3.85-3.82-4.28.04,7.16-5.45,17.92-7.82,21.26-10.01.6-29.31-10.31-38.6-11.41-2.13-.25-5.23-.8-8,1.47Z"/>
					<circle className={styles.cls3} cx="229.82" cy="173.36" r="13.22"/>
					<ellipse className={styles.cls1} cx="227.7" cy="157.54" rx="1.59" ry="3.07"
					         transform="translate(-18.86 31.54) rotate(-7.61)"/>
					<circle className={styles.cls1} cx="230.91" cy="171.79" r="13.22"/>
					<g>
						<path className={styles.cls11}
						      d="M241.98,30.11c3.82,5.44,4.82,11.28-1.3,15.62-9.6,6.81-47.84,7.4-51.34,7.36-4.41-.04-9.04-.22-9.71-6.11-.75-6.67,5.35-14.73,8.99-19.87,15.47-21.87,38.77-17.81,53.37,3Z"/>
						<path className={styles.cls9}
						      d="M230.3,131.6c-3.55-5.42-5.26-12.01-4.79-18.45.48-6.64,3.19-13.25,1.73-19.76-1.99-8.86-10.83-14.25-19.02-18.13-12.44-5.9-25.34-10.78-38.54-14.59-3.37-.97-6.85-1.87-10.34-2.32,5.23-5.05,11.3-9.31,17.87-12.94.85.38,1.76.66,2.62.89,5.57,1.45,11.29,2.41,16.64,4.61,11.21,4.6,19.83,14.15,29.25,21.61,4.41,3.5,9.7,6.58,15.18,6.03,5.46-.55,9.78-4.53,14.7-6.87,3.08-1.47,6.47-2.21,9.9-2.32,4.77,2.26,9.15,5.09,11.3,8.97,5.42,9.74,6.3,23.53,1.63,31.54-7.89,13.56-17.34,19.06-28.23,24.81-4.66,2.46-9.5,4.21-14.41,5.4-1.34-2.95-3.69-5.73-5.5-8.49Z"/>
						<path className={styles.cls4}
						      d="M160.08,122.96c-4.67-4.46-8.96-9.38-12.72-14.61-6.52-9.09-6.39-17.72-2.67-27.84,3.23-8.78,8.34-16.04,14.67-22.15,3.48.45,6.97,1.35,10.34,2.32,13.2,3.81,26.1,8.69,38.54,14.59,8.19,3.88,17.03,9.27,19.02,18.13,1.46,6.51-1.25,13.12-1.73,19.76-.47,6.43,1.25,13.03,4.79,18.45,1.81,2.76,4.16,5.54,5.5,8.49-32.36,7.84-67.65-9.4-75.73-17.13Z"/>
						<path className={styles.cls4}
						      d="M240.9,78.55c-5.48.55-10.76-2.54-15.18-6.03-9.41-7.46-18.04-17-29.25-21.61-5.35-2.2-11.07-3.17-16.64-4.61-.86-.22-1.77-.51-2.62-.89.95-.53,1.91-1.04,2.88-1.54,11.79-6.1,22.76-10.03,36.31-4.67,6.78,2.68,12.7,9.69,17.88,14.68,6.05,5.84,10.13,7.13,18.11,10.28,3.7,1.46,8.58,3.07,13.11,5.21-3.43.11-6.82.85-9.9,2.32-4.92,2.34-9.25,6.32-14.7,6.87Z"/>
						<path className={styles.cls7}
						      d="M271.88,109.84c-1,3.43-2.49,6.9-5.29,9.11-2.8,2.21-7.22,2.7-9.82.24-2.23-2.1-2.46-5.51-2.55-8.57,2.6,0,5.24-.27,7.64-1.28,2.4-1.01,4.53-2.81,5.52-5.22.15-.37.29-.77.59-1.04.41-.37,1.01-.39,1.55-.44,2.14-.21,3.65-1.25,5.32-2.46-.43.31-.54,1.56-.68,2.03-.24.83-.49,1.66-.75,2.5-.52,1.71-1.05,3.42-1.55,5.13Z"/>
						<path className={styles.cls5}
						      d="M249.07,85.5c.75-2.12,2.92-4.51,5.61-6.43h0s0,0,0,0c0,0,0,0,.01,0h0c2.64-1.99,5.56-3.34,7.79-3.42,10.64-.37,9.13,11.71,2.44,17.52h0s-.01,0-.01,0c0,0,0,0-.01.01h0c-7.53,4.64-19.42,2.43-15.83-7.67Z"/>
						<path className={styles.cls5}
						      d="M277.09,94.49c-.19-.04-.4-.03-.63.03-.75.21-.84,1.01-1.11,1.63-.38.85-.91,1.72-1.52,2.42-1.97,2.25-4.22,3.02-6.95,3.42l-.07-.09s-.03.07-.05.11c-.04,0-.08.01-.12.02l.07.09c-1.09,2.54-2.41,4.51-5.09,5.83-1.85.91-6.29,1.67-8.4.8-.74-.31-1.57.05-1.86.79-.28.74.09,1.59.83,1.89,3.26,1.34,8.64.16,10.74-.87.81-.4,1.53-.85,2.16-1.33,1.74-1.33,2.82-2.95,3.66-4.62,1.83-.38,3.68-1.01,5.41-2.34.63-.49,1.25-1.06,1.84-1.74.86-.98,2.01-2.52,2.25-3.84.16-.88-.29-2.01-1.17-2.18Z"/>
						<path className={styles.cls5}
						      d="M268.5,104.73c-.31.27-.72.41-1.17.36-.85-.1-1.49-.87-1.42-1.72,0-.09.25-4.42-3.1-11.36-.38-.79-.06-1.72.71-2.08.77-.36,1.7-.02,2.08.77,3.8,7.86,3.43,12.81,3.41,13.02-.04.41-.23.77-.51,1.02Z"/>
					</g>
				</g>
				<g id="Stripe">
					<path
						className={styles.cls13}
						d="M161.61,124.28c14.72-36.05,39.2-63.97,70.97-72.08,2.43-.62-9.24-14.66-17.46-13.49-21.23,3.03-63.84,45.16-64.41,74.05.8,3.72,7.48,11.99,10.89,11.51Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Winner_Gold01_Upper">
					<path className={styles.cls11}
					      d="M109.88,120.3c3.88,7.22,27.03,28.89,33.89,23.66,10.75-8.2,17.15-55.72,18.17-60.26,1.28-5.7.52-12.07-2.73-15.61-5.29-5.76-16.83-2.79-22.98.6-23.84,13.15-38.6,28.82-26.35,51.61Z"/>
					<path className={styles.cls5}
					      d="M198.85,100.21c-.27.33-.61.57-1.02.66-4.56.9-1.22-10.27-.58-11.62,2.32-4.87,8.44-8.2,13.77-4.64,1.47.98,3.7,3.34.91,3.94-1.64.35-3.59-.55-5.22-.4-4.33.4-6.4,4.66-6.96,9.12-.1.77-.2,1.55-.48,2.23-.11.26-.25.5-.42.71Z"/>
					<path className={styles.cls5}
					      d="M228.85,71.68c-.21.27-.48.46-.81.53-3.66.69-1.12-8.46-.62-9.55,1.81-3.97,6.69-6.63,11.02-3.64,1.19.82,3.02,2.79.78,3.25-1.31.27-2.9-.5-4.21-.39-3.48.27-5.09,3.75-5.49,7.4-.07.63-.14,1.27-.36,1.82-.08.21-.19.41-.33.58Z"/>
					<path className={styles.cls9}
					      d="M129.01,71.63c-4.82,6.7,0,18.21,14.66,29,9.99,23.21,6.42,69.96,34.29,78.55,7.48,2.31,17.12-21.21,11.29-27.51-11.36-12.26-24.15-35.46-32.58-56.94-3.77-19.68-22.29-30.56-27.66-23.1Z"/>
				</g>
			</g>
			<g id="Puppy_Winner_Gold02">
				<g id="Puppy_Winner_Gold02_Lower">
					<g>
						<g>
							<path className={styles.cls11}
							      d="M534.67,252.72c1.86,2.63,3.88,5.17,6.14,7.54,2.71,2.83,22.54,14.7,15,19.26-1.14.69-2.58.84-3.91.55-4.49-.98-9.04-2.1-13.01-4.17-6.54-3.42-10.86-9.08-13.76-15.34.72-1.72,1.78-3.3,3.15-4.58,1.77-1.65,4.02-2.78,6.4-3.26Z"/>
							<path className={styles.cls9}
							      d="M523.63,238.76c.54.04,1.18.24,1.93.63,3.48,1.81,4.93,6.98,6.87,10.01.72,1.12,1.47,2.23,2.24,3.32-2.37.48-4.62,1.61-6.4,3.26-1.37,1.27-2.43,2.85-3.15,4.58-1.29-2.78-2.3-5.67-3.1-8.55-.56-2.01-3.11-13.59,1.61-13.25Z"/>
						</g>
						<path className={styles.cls10}
						      d="M578.43,265.95c-.12-.75-.17-1.5-.17-2.26.05-3.81,1.57-7.57,3.6-11.05,4.61-7.95,12.61-16.2,22.54-19.64,8.99-3.12,27.96-4.66,34.32,2.19,1.1,1.18,1.78,2.53,2.29,3.9,3.81,10.2-2.35,21.93-14.68,27.96,4.84,1.22,10.65,2.78,10.66,8.92,0,8.52-29.32,8.25-30.07.23-.32-3.37-.18-3.14.33-6.23-2.72,3.82-4.51,3.87-9.43,5.42-4.92,1.55-10.75,1.13-14.63-1.62-2.72-1.92-4.27-4.8-4.76-7.83Z"/>
					</g>
					<path className={styles.cls10}
					      d="M707.66,90.56c4.88,16.37-24.02,18.79-25.93,20.12-8.98,6.24-18.14,35.9-52.29,59.64-2.47,1.72-30.86-10.18-6.88-31.88,16.76-15.16,34.18-26.03,53.14-44.56,11.64-11.38,30.65-7.71,31.96-3.31Z"/>
					<g>
						<path className={styles.cls9}
						      d="M594.25,262.86c10.34-.35,45.96-35.68,38.85-87.88-1.28-9.41-1.84-19.98-5.64-30.55-6.35-3.34-19.77-3.79-29.4-5.2-6.55-.96-21.3-3.69-27.07,1.14-5.48,4.59-10.06,19.03-10.06,28.65,0,35.68-15.18,65.46-18.47,76.58-8.23,27.79,44.21,17.52,51.8,17.26Z"/>
						<path className={styles.cls10}
						      d="M565.02,239.94c-1.59-17.37,29.86-23.18,37.31-9.94,18.28-12.57,25.91-35.05,26.23-58.87.04-3.28-.25-18.36-1.98-22.72-9.78,7.48-51.56,7.74-48.67-1.23,1.23-3.83,46.54-5.97,49.76-4,22.86,43.1-8.88,122.14-31.07,121.76-6.21-.11-30.71-15.59-31.57-24.99Z"/>
					</g>
				</g>
				<path
					id="Shirt-2"
					data-name="Shirt"
					className={styles.cls2}
					d="M634.86,179.96c-.76-8.92-.86-36.44-8.28-41.34l-3.16,2.22c5.48,8.01,7.37,30.33-2.42,34.27-9.02,3.63-14.33-3.44-17.47-9.16-8.6-15.64-29.57-34.1-40.56-24.99-11.31,9.37-6.89,47.25-7.93,58.33-.81,8.63-4.88,14.77-7.57,19.34,20.85,15.56,50.38,17.67,81.34,6.92,5.59-16.5,6.83-36.6,6.06-45.6ZM574.95,186.56c-10.43-.98-18.11-10.13-17.14-20.44.97-10.31,10.21-17.88,20.64-16.9,10.43.98,18.11,10.13,17.14,20.44-.97,10.31-10.21,17.88-20.64,16.9Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-2" data-name="Stripes">
					<path
						className={styles.cls13}
						d="M615.58,177.75c-7.56,0-12.34-7.21-17.84-15.48-6.09-9.16-12.99-19.55-25.32-22.71l.7-2.75c13.39,3.43,20.61,14.3,26.98,23.89,5.55,8.35,9.93,14.94,16.64,14.14,3.58-.43,6.17-2.08,7.93-5.02,4.24-7.13,2.29-20.12-.46-27.06l2.64-1.04c2.75,6.97,5.26,21.15.26,29.56-2.2,3.7-5.57,5.85-10.02,6.39-.51.06-1.01.09-1.5.09Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls13}
						d="M616.36,218.11c-8.79,1.65-17.6,2.31-26.15,1.93-13.78-.6-27.08-4-38.16-9.76.82-1.75,1.6-4.15,1.95-5.34,10.52,5.6,23.22,9.02,36.46,9.6,14.56.64,27.98-1.16,42.65-6.58.05.89-.7,4.45-.82,5.71-6.12,2.15-9.69,3.27-15.93,4.44Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls13}
						d="M578.58,147.85c-11.17-1.05-21.11,7.09-22.14,18.14-1.04,11.05,7.21,20.89,18.38,21.94,11.17,1.05,21.11-7.09,22.14-18.14,1.04-11.05-7.21-20.89-18.38-21.94ZM575.08,185.19c-9.66-.91-16.79-9.4-15.9-18.94.89-9.54,9.48-16.56,19.15-15.66,9.66.91,16.79,9.4,15.9,18.94-.89,9.54-9.48,16.56-19.15,15.65Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Winner_Gold02_Mid">
					<path className={styles.cls6}
					      d="M563.38,124.01c-2.16,2.69-.64,9.79.39,13.05.98,3.1,4.66,10.17,22.8,14.71,18.15,4.54,39.22-3.16,41.01-8.33,4.69-13.58-8.44-19.41-19.76-23.71-10.67-4.05-27.34-8.71-37.38-1.38-2.16,1.58-5.38,3.59-7.05,5.67Z"/>
					<g>
						<path className={styles.cls12}
						      d="M561.13,142.21c4.46,7.54,49.25,19.49,59.83,16.75,6.35-4.58,11.49-13.78,11.25-18.42-.13-2.49-.47-3.81-3.78-4.23.04,7.09-5.4,17.75-7.75,21.06-9.91.59-43.14-12.91-51.63-16.62-5.53-2.42-5.18-.79-7.92,1.46Z"/>
						<g>
							<circle className={styles.cls3} cx="622.67" cy="175.18" r="13.1"/>
							<ellipse className={styles.cls1} cx="620.58" cy="159.51" rx="1.57" ry="3.04"
							         transform="translate(-15.66 83.59) rotate(-7.61)"/>
							<circle className={styles.cls1} cx="623.76" cy="173.63" r="13.1"/>
						</g>
					</g>
					<path className={styles.cls11}
					      d="M551.21,254.93c3.07,5.48,4.57,11.88,3.9,18.13-.27,2.6-1.03,5.29-2.41,7.47-.51-.12-1.08-.12-1.57-.3-14.92-2.33-15.3-19.31-7.49-38.16,2.95,2.65,5.64,9.4,7.58,12.86Z"/>
					<path className={styles.cls9}
					      d="M545.54,254.74c3.66-9.55,10.45-17.4,20.05-23.59,8.02-5.17,26.51-8.33,33.59-.65,1.22,1.32,2.04,2.87,2.7,4.45,4.89,11.73.03,25.75-11.6,33.43,4.95,1.18,11.4,2.91,11.57,9.89.3,11.81-26.58,10.38-30.31,3.23-1.77-3.39-.35-6.62.7-9.83-1.95,3.11-4.71,5.85-9.44,7.91-4.73,2.06-10.58,1.87-14.74-1.15-2.91-2.11-7.42-10.88-2.52-23.68Z"/>
					<g>
						<path className={styles.cls11}
						      d="M618.41,26.39c4.52,4.78,6.35,10.36.97,15.49-8.43,8.05-45.81,14.13-49.25,14.6-4.33.59-8.89,1.09-10.39-4.59-1.7-6.43,3.12-15.2,5.95-20.77,12.01-23.66,35.43-23.03,52.72-4.74Z"/>
						<path className={styles.cls9}
						      d="M621.57,127.52c-4.25-4.8-6.88-11.02-7.35-17.39-.48-6.57,1.22-13.44-1.14-19.61-3.22-8.4-12.66-12.4-21.24-15.03-13.03-3.99-26.38-6.92-39.86-8.75-3.44-.47-6.99-.84-10.46-.78,4.4-5.7,9.74-10.75,15.64-15.26.89.25,1.82.4,2.69.49,5.67.62,11.41.74,16.97,2.12,11.64,2.9,21.47,11.01,31.77,16.96,4.83,2.79,10.45,5.05,15.74,3.73,5.27-1.32,8.94-5.84,13.42-8.85,2.81-1.88,6.02-3.1,9.37-3.7,5,1.52,9.7,3.68,12.36,7.16,6.71,8.76,9.56,22.14,6.14,30.67-5.78,14.42-14.24,21.17-24.1,28.38-4.22,3.08-8.7,5.49-13.34,7.36-1.74-2.7-4.44-5.08-6.61-7.53Z"/>
						<path className={styles.cls4}
						      d="M551.51,129.16c-5.21-3.7-10.13-7.9-14.57-12.49-7.7-7.97-8.81-16.44-6.62-26.89,1.9-9.06,5.86-16.92,11.19-23.82,3.48-.06,7.02.32,10.46.78,13.48,1.83,26.83,4.76,39.86,8.75,8.59,2.63,18.02,6.63,21.24,15.03,2.37,6.17.66,13.04,1.14,19.61.47,6.37,3.1,12.59,7.35,17.39,2.17,2.45,4.87,4.83,6.61,7.53-30.58,12.34-67.64.52-76.67-5.89Z"/>
						<path className={styles.cls4}
						      d="M624.32,74.02c-5.29,1.32-10.91-.94-15.74-3.73-10.3-5.96-20.13-14.07-31.77-16.96-5.56-1.38-11.3-1.51-16.97-2.12-.87-.09-1.8-.25-2.69-.49.86-.65,1.72-1.3,2.6-1.93,10.67-7.67,20.86-13.1,34.9-9.8,7.03,1.65,13.84,7.67,19.63,11.82,6.77,4.85,10.95,5.53,19.23,7.46,3.83.9,8.85,1.78,13.6,3.22-3.35.6-6.56,1.81-9.37,3.7-4.48,3-8.15,7.53-13.42,8.85Z"/>
						<path className={styles.cls7}
						      d="M659.17,100.21c-.49,3.5-1.44,7.12-3.87,9.68-2.43,2.57-6.69,3.68-9.58,1.65-2.48-1.74-3.2-5.04-3.73-8.03,2.55-.37,5.1-1.02,7.3-2.35,2.2-1.33,4.04-3.41,4.66-5.91.1-.39.17-.8.43-1.1.35-.42.93-.53,1.46-.66,2.06-.52,3.4-1.75,4.86-3.17-.38.37-.3,1.6-.37,2.09-.12.85-.24,1.7-.37,2.55-.26,1.75-.54,3.5-.78,5.25Z"/>
						<path className={styles.cls5}
						      d="M633.32,79.65c.43-2.18,2.21-4.84,4.57-7.11h0s0,0,0,0c0,0,0,0,0,0h0c2.3-2.33,4.96-4.07,7.14-4.47,10.37-1.89,10.63,10.16,4.91,16.81h0s0,0,0,0c0,0,0,0-.01.01h0c-6.71,5.64-18.68,5.18-16.62-5.23Z"/>
						<path className={styles.cls5}
						      d="M662.07,84.43c-.19-.01-.4.02-.61.12-.71.32-.68,1.11-.85,1.75-.25.89-.64,1.81-1.14,2.59-1.61,2.49-3.7,3.57-6.32,4.35l-.09-.08s-.02.08-.03.12c-.04.01-.08.02-.12.04l.09.08c-.7,2.64-1.71,4.77-4.15,6.45-1.68,1.16-5.93,2.54-8.12,2-.77-.19-1.53.27-1.71,1.04-.17.77.32,1.54,1.09,1.73,3.39.84,8.49-1.08,10.4-2.4.74-.51,1.38-1.05,1.93-1.62,1.51-1.56,2.34-3.3,2.92-5.06,1.74-.63,3.46-1.52,4.97-3.08.55-.57,1.07-1.22,1.56-1.97.7-1.09,1.61-2.76,1.65-4.09.03-.88-.58-1.92-1.46-1.97Z"/>
						<path className={styles.cls5}
						      d="M655.13,95.69c-.26.31-.65.51-1.09.52-.85.03-1.58-.64-1.64-1.48,0-.09-.39-4.37-4.67-10.69-.48-.72-.31-1.67.39-2.14.7-.47,1.67-.26,2.15.45,4.85,7.15,5.2,12.06,5.21,12.26.03.41-.11.79-.36,1.07Z"/>
					</g>
				</g>
				<g id="Stripe-2" data-name="Stripe">
					<path
						className={styles.cls13}
						d="M553.2,130.23c9.24-37.44,29.2-68.33,59.17-80.84,2.3-.96-11.16-13.04-19.05-10.7-20.37,6.02-56.05,53.44-52.46,81.83,1.32,3.53,9.05,10.67,12.33,9.72Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Winner_Gold02_Upper">
					<g id="Puppy_Winner_Gold01_Upper-2" data-name="Puppy_Winner_Gold01_Upper">
						<path className={styles.cls11}
						      d="M501.94,133.78c4.84,6.52,30.65,24.42,36.61,18.31,9.35-9.58,8.78-57.07,9.13-61.66.44-5.77-1.23-11.9-4.92-14.9-6.01-4.88-16.89-.31-22.43,3.89-21.46,16.31-33.68,33.79-18.4,54.36Z"/>
						<path className={styles.cls5}
						      d="M586.23,101.29c-.21.36-.51.65-.9.79-4.34,1.54-2.68-9.89-2.24-11.3,1.58-5.11,7.09-9.25,12.83-6.53,1.58.75,4.1,2.74,1.46,3.73-1.56.58-3.6-.02-5.18.36-4.19,1.01-5.6,5.49-5.51,9.94.02.77.03,1.55-.15,2.25-.07.27-.17.53-.31.76Z"/>
						<path className={styles.cls5}
						      d="M611.52,69.02c-.17.29-.41.52-.72.63-3.49,1.2-2.31-8.13-1.98-9.27,1.2-4.15,5.6-7.46,10.28-5.15,1.29.63,3.36,2.3,1.23,3.08-1.25.45-2.91-.07-4.18.22-3.37.77-4.45,4.41-4.31,8.04.02.63.05,1.27-.09,1.84-.05.22-.13.43-.24.62Z"/>
					</g>
					<g>
						<polygon className={styles.cls8}
						         points="675.09 94.52 677.55 87.07 702.49 89.17 698.04 102.68 675.09 94.52"/>
						<polygon className={styles.cls8} points="673.09 79 669.44 72.32 689.81 57.35 696.43 69.46 673.09 79"/>
						<polygon className={styles.cls8}
						         points="663.81 64.07 656.55 60.88 666.41 38.94 679.56 44.72 663.81 64.07"/>
					</g>
					<path className={styles.cls9}
					      d="M492,101.66c-1.76,7.98,7.13,16.56,24.7,20.63,18.18,17.2,33.26,61.11,61.97,58.01,7.71-.83,7.26-26-.52-29.44-15.14-6.7-35.86-22.79-51.95-39.02-11.14-16.42-32.25-19.06-34.21-10.17Z"/>
				</g>
			</g>
		</svg>
	);
};