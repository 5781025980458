import React, { useContext } from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Header.scss";
import {windowSizeContext} from "AppRoot";
import UserDropdown from "components/user/elements/UserDropdown";
import NavSearchBox from "./widgets/NavSearchBox";

const GreenlineLogo = require("components/common/branding/greenline-logo-footer.png");
const GreenlineLogoSm = require("components/common/branding/greenline-logo-circle.png");

function ProviderHeader(props) {
    const context = useContext(windowSizeContext) || {};
    if(!props.userProfile) {
        return <div />
    }

    const config = props.searchConfig;
    const hasSearch = !!config;

    return(
        <header className={classnames(styles.root, {
            [styles.collapsed]: props.collapsed,
            [styles.isQa]: context.isQa,
            [styles.isDev]: context.isDev,
            [styles.isLocal]: context.isLocal,
        })}>
            <div className={styles.logo}>
                {context.isPhone ? (
                    <Link to="/" >
                        <img src={GreenlineLogoSm} alt="Greenline Pet Logo" />
                    </Link>
                ) : (
                    <Link to="/" >
                        <img src={GreenlineLogo} alt="Greenline Pet Logo" />
                    </Link>
                )}
            </div>
            <div className={styles.seperator}/>
            <div className={styles.searchContainer}>
                {!hasSearch && !!props.pageTitle && (
                    <h2>{props.pageTitle}</h2>
                )}
                {hasSearch && (<NavSearchBox searchConfig={props.searchConfig} clinicId={props.clinicId} />)}
            </div>
            <div className={styles.userDropdownContainer}>
                <UserDropdown user={props.userProfile}/>
            </div>
        </header>
    );
}

ProviderHeader.propTypes = {
    pageTitle: PropTypes.string,
    searchConfig: PropTypes.object,
    clinicId: PropTypes.number,
    collapsed: PropTypes.bool
}

export default connect(
    (state) => ({
        userProfile: state.user.userProfile,
    })
)(ProviderHeader);
