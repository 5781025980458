import React, { useContext, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./UserDropdown.scss";
import classnames from "classnames";
import { redirectToLogout } from "utils/login";
import LocalData from "utils/LocalData";
import {windowSizeContext} from "AppRoot";
import Modal from "components/common/Modal";
import UserHomepage from "components/user/pages/UserHomepage";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import * as UserActions from "actions/UserActions";
import * as AdminActions from "actions/AdminActions";
import Image from "components/common/Image";
import {PermissionTypes, userHasPermission, userPrimaryLocationType} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import * as LocationTypes from "constants/LocationTypes";
import Button from "components/common/Button";
import env from "utils/environment";
import {handleErrorResponse} from "utils/request";

const USER_PROFILE_FALLBACK = require("components/common/branding/user-profile-default.png");

function UserDropdown(props) {
    const userDropdownRef = React.createRef();
    const context = useContext(windowSizeContext) || {};
    const [showOptions, setShowOptions] = useState(false);
    const [showAccountSettings, setShowAccountSettings] = useState(false);

    const handleShowOptions = () => {
        setShowOptions(true);
    };

    const handleCloseOptions = () => {
        setShowOptions(false);
    };

    const handleLogout = () => {
        try {
            LocalData.clearToken();
            redirectToLogout();
        } catch (error) {
            handleErrorResponse("logging out", error);
        }
    }

    const goToLink = (link) => {
        props.history.push(link);
    }

    const handleRevertToSelf = () => {
        if(props.canImpersonateAccount) {
            props.history.push("/");
            props.revertToSelf();
        }
    }

    const handleAccountSettingsClick = () => {
        setShowAccountSettings(true);
    }

    const handleAccountSettingsClose = () => {
        setShowAccountSettings(false);
    }

    const handleSubmitUserEdit = (newData) => {
        if (props.canEditUserManagement) {
            setShowAccountSettings(false);
            props.updateUserSelf(newData);
        }
    }

    const renderChangePassword = () => {
        const handleChangePassword = () => {
            props.passwordChangeClicked();
            window.open(`${env.getValue("IDENTITY_ROOT")}/account/changepassword/`, "_blank");
        }

        if (!props.user?.forcePasswordChange || props.passwordResetClicked) {
            return null;
        }

        return (
            <div className="flex-none">
                <Button
                    type="danger"
                    icon
                    onClick={handleChangePassword}
                    title="For security purposes, Greenline requires your password to be reset every 6 months."
                >
                    <i className="fas fa-exclamation-circle"/> <span className="no-wrap">Require Password Change</span>
                </Button>
            </div>
        );
    }

    const showPayments = !!props.user?.clinics?.length && props.canViewPaymentSettings;

    return (
        <>
            {!context.isTabletPortrait && renderChangePassword()}

            <div
                ref={userDropdownRef}
                className={classnames(styles.root, {
                    [styles.active]: showOptions
                })}
                tabIndex={-1}
                onFocus={handleShowOptions}
                onBlur={handleCloseOptions}
            >
                <div className={styles.userDropdown}>
                    {!context.isPhone && props.isImpersonated && (<i className="fa fa-eye margin-right-x-sm"/> )}
                    {!context.isPhone && (
                        <span className="no-wrap">{props.user.name}</span>
                    )}
                    <span className={styles.avatar}>
                        {!!props.user?.imageUrl ? (
                            <span className={styles.image}>
                                <Image
                                    src={props.user.imageUrl}
                                    fallbackSrc={USER_PROFILE_FALLBACK}
                                    alt={`${props.user.name} profile photo`}
                                />
                            </span>
                        ) : (
                            <i className={classnames("fa fa-2x fa-user-circle", {
                                "text-danger" : (context.isTabletPortrait && props.user?.forcePasswordChange),
                                "text-mid-gray" : !(context.isTabletPortrait && props.user?.forcePasswordChange)
                            })}/>
                        )}
                    </span>
                    <span className={styles.arrow}>
                        <i className="fas fa-chevron-down"/>
                    </span>
                </div>
                {showOptions && (
                    <div className={styles.optionsBox}>
                        {context.isTabletPortrait && (
                            <>
                                <div className={styles.userInfo}>
                                    {renderChangePassword()}
                                    <div className={styles.option}>
                                        {props.isImpersonated ? (
                                            <i className="fa fa-eye"/>
                                        ) : (
                                            <i className="fas fa-user-circle" />
                                        )}
                                        {props.user.name}
                                    </div>
                                </div>
                            </>
                        )}
                        {(props.canViewAccount && !props.logoutOnly) && (
                            <div
                                className={styles.option}
                                onClick={handleAccountSettingsClick}
                            >
                                <span className={styles.icon}><i className="fas fa-user-gear" /></span>
                                <span className={styles.linkText}>Account Settings</span>
                            </div>
                        )}
                        {(showPayments && !props.logoutOnly) && (
                            <div className={styles.option} onClick={(e) => goToLink("/setup/payments")} >
                                <span className={styles.icon}><i className="fal fa-credit-card" /></span>
                                <span className={styles.linkText}>Payment Settings</span>
                            </div>
                        )}
                        {(props.canViewSupport && !props.logoutOnly) && (
                            <div className={styles.option} onClick={(e) => goToLink("/support/")} >
                                <span className={styles.icon}><i className="fas fa-question-circle" /></span>
                                <span className={styles.linkText}>Support</span>
                            </div>
                        )}
                        <div
                            onClick={handleLogout}
                            className={styles.option}
                        >
                            <span className={styles.icon}><i className="fas fa-power-off" /></span>
                            <span className={styles.linkText}>Log Out</span>
                        </div>
                        {(props.canImpersonateAccount && props.isImpersonated) && (
                            <div onClick={handleRevertToSelf} className={styles.option}>
                                <span className={styles.icon}><i className="fas fa-eye-slash" /></span>
                                <span className={styles.linkText}>Revert To Self</span>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <Modal
                show={showAccountSettings}
                modalTitle="User Account Settings"
                small
                onClose={handleAccountSettingsClose}
            >
                <UserHomepage
                    onSubmit={handleSubmitUserEdit}
                    onClose={() => setShowAccountSettings(false)}
                />
            </Modal>
            <SpinnerTakeover show={props.isUserUpdating} />
        </>
    );
}

UserDropdown.propTypes = {
    user: PropTypes.object.isRequired,
    logoutOnly: PropTypes.bool,
};

const connector = connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewAccount = userHasPermission(PermissionTypes.VIEW, UserPermissions.ACCOUNT, userProfile);
        //TODO: The permission that is needed for the API call is a UserManagement permission... Should rely on Account.Edit permission
        const canEditUserManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.USER_MANAGEMENT, userProfile);
        // const canEditAccount = userHasPermission(PermissionTypes.EDIT, UserPermissions.ACCOUNT, userProfile);
        const canViewPaymentSettings = userHasPermission(PermissionTypes.VIEW, UserPermissions.PAYMENT_SETTINGS, userProfile);
        const primaryLocationIsNull = userPrimaryLocationType(userProfile, [LocationTypes.NULL])
        const canViewSupport = userHasPermission(PermissionTypes.VIEW, UserPermissions.SUPPORT, userProfile) && !primaryLocationIsNull;
        const canImpersonateAccount = userHasPermission(PermissionTypes.IMPERSONATE, UserPermissions.ACCOUNT, userProfile);
        return {
            userProfile,
            canViewAccount,
            canEditUserManagement,
            // canEditAccount,
            canViewPaymentSettings,
            canViewSupport,
            canImpersonateAccount,
            isImpersonated: state.user.isImpersonated || false,
            isUserUpdating: state.entities.users.isUserUpdating || false,
            passwordResetClicked: state.user.passwordResetClicked,
        }
    },
    (dispatch) => ({
        updateUserSelf: (data) => dispatch(UserActions.updateUserSelf(data)),
        revertToSelf: () => dispatch(AdminActions.revertToSelf()),
        passwordChangeClicked: () => dispatch(UserActions.passwordChangeClicked()),
    })
);

export default compose(
    withRouter,
    connector
)(UserDropdown);
