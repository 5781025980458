import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Loader from "components/common/Loader";
import toast from "utils/toast";

export default function VetCheckLoginRedirectCallback(props) {
    const history = useHistory();

    useEffect(() => {
        const clinicId = Number(props.match.params.clinicId);
        if (props.location.search) {
            const search = new URLSearchParams(props.location.search);
            const isSuccessful = search.get("success");
            const message = search.get("message");

            if(isSuccessful === "true") {
                toast.success("Logged into VetCheck Successfully");
            } else {
                toast.error(`Error logging into VetCheck: ${message}`);
            }
        }
        if(clinicId) {
            history.replace(`/admin/clinic/${clinicId}/vetcheck`);
        } else {
            history.replace(`/vetcheck`)
        }
    }, []);

    return (
        <div>
            <Loader text="VetCheck Signing In (redirect)" />
        </div>
    );
}
