import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./RadioButtons.scss";
import map from "lodash/map";
import Tooltip from "components/common/Tooltip";
import * as Position from "constants/TooltipPositionIds";

export default function RadioButtons(props) {
    const handleChanged = (value) => {
        if (!props.disabled) {
            if (props.onChange) {
                props.onChange({
                    name: props.name,
                    value
                });
            }
        }
    };

    const iconWeight = props.solidIcons ? "fas" : "far";
    const radioClass = (optionValue) => {
        return (props.value === optionValue) ? `${iconWeight} fa-dot-circle` : ` ${iconWeight} fa-circle`;
    }

    return (
        <div
            data-testid="radio_buttons_component"
            className={classnames(styles.root, {
                [styles.disabled]: props.disabled,
                "text-sm": props.small,
            })}
        >
            {map(props.options, (option, index) => (
                <label
                    data-testid={`radio_button_option_${option.value}`}
                    key={index}
                    className="flex margin-bottom-x-sm"
                    onClick={() => handleChanged(option.value)}
                >
                    <span className={classnames(styles.radio, {
                        [styles.selected]: (props.value === option.value),
                        "test_selected": (props.value === option.value),
                    })}
                        onClick={() => handleChanged(option.value)}
                    >
                        <i className={radioClass(option.value)}/>
                    </span>
                    <span className={classnames(styles.label, "flex")}>
                        {!!props.information && (
                            <div className={classnames("flex-none", styles.infoTip)}>
                                <Tooltip position={props.informationPosition || Position.RIGHT} tip={props.information}>
                                    <i className="fas fa-info-circle margin-right-x-sm" title={props.information}/>
                                </Tooltip>
                            </div>
                        )}
                        <div className="flex-1">{option.label}</div>
                    </span>
                </label>
            ))}
        </div>
    );
}

RadioButtons.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    value: PropTypes.any.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.any,
        value: PropTypes.any,
    })).isRequired,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
    solidIcons: PropTypes.bool,
    information: PropTypes.string,
};
