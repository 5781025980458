import React from "react";
import {connect} from "react-redux";
import { useHistory } from "react-router";
import styles from "./ProviderWellnessDvmCommission.scss";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import DvmCommissionTable from "components/wellness/widgets/DvmCommissionTable";
import ProviderBase from "components/layout/ProviderBase";
import { getNodeId } from "utils/AdminData";
import getProviderId from "utils/getProviderId";
import * as ProviderLinks from "utils/ProviderLinks";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ProviderWellnessDvmCommission(props)  {
    const history = useHistory();

    const goToWellnessSettings = () => {
        history.push(ProviderLinks.getLinkLocation("wellness-settings", {
            providerId: props.providerId,
            nodeId: props.nodeId,
            isAdmin: props.isAdmin,
            clinicId: props.clinicId,
        }));
    }

    return (
        <ProviderBase
            location={props.location}
            pageTitle="DVM Commissions Setup"
            clinicId={props.clinicId}
            nodeId={props.nodeId}
            providerId={props.providerId}
        >
            <div className={styles.root}>
                <div className="topLine flex spaced-content">
                    <div className="flex-1 flex">
                        <Button
                            small
                            icon
                            className="margin-right-x-sm"
                            type="primary"
                            onClick={goToWellnessSettings}
                        >
                            <i className="fa fa-reply"/> Back To Clinic Menu
                        </Button>
                    </div>
                </div>
                {props.canViewClinicWellnessDvms ? (
                    <DvmCommissionTable clinicId={props.clinicId}/>
                ) : (
                    <AccessDenied/>
                )}
            </div>
        </ProviderBase>
    )
}

export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const clinicId = Number(params?.clinicId) || null;
        const userProfile = state.user.userProfile;
        const nodeId = getNodeId(state, ownProps);
        const providerId = getProviderId(params, userProfile);

        // Permissions
        const canViewClinicWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        return {
            clinicId,
            nodeId,
            providerId,

            //Permissions
            canViewClinicWellnessDvms
        }
    }
)(ProviderWellnessDvmCommission);
