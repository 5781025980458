import React, {useEffect, useState} from "react"
import {connect} from "react-redux";
import PropTypes from "prop-types";
import AccessDenied from "components/common/AccessDenied";
import NotificationButtons from "components/notifications/elements/NotificationButtons";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import { ENROLLED, PENDING, ADOBE_SIGN_BASE_PATH } from "constants/Notifications";
import * as UserPermissions from "constants/UserPermissions";

function AdobeOnly(props) {
    if(!props.canViewClinicCouponLibrary) {
        return (
            <AccessDenied
                customMessage="You do not have the correct permissions to access this information. Please contact Greenline for help resolving this issue."
            />
        );
    }

    const [loading, setLoading] = useState(false);
    const [signed, setSigned] = useState(false);
    const isEnrolled = props.enrollmentState === ENROLLED;
    const isPending = props.enrollmentState === PENDING;

    useEffect(() => {
        if(props.enrollmentState === PENDING && !props.isPreview) {
            setLoading(true);
        }
    }, [props.enrollmentState]);

    const handleDecline = async () => {
        // No event included
        props.onDecline();
    };

    const handleSubmit = async (e) => {
        // Form event included
        e.stopPropagation();
        e.preventDefault();
        props.onSubmit(null, null);
    };

    const eventHandler = (e) => {
        if(e.origin === ADOBE_SIGN_BASE_PATH){
            const type = JSON.parse(e.data)?.type;
            //Show the spinner until the document has loaded
            if(loading && (type === "PAGE_LOAD")){
                setLoading(false);
            }
            if(type === "ESIGN") {
                setSigned(true);
            }
        }
    }

    if(!window.addEventListener){
        window.attachEvent('onmessage', eventHandler);
    } else{
        window.addEventListener('message', eventHandler, false);
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className="full-width full-height">
                <div>
                    {isPending && (
                        <div id="adobeSign" className="full-width">
                            {props.isPreview ? (
                                <div
                                    width="100%"
                                    height="100%"
                                    style={{border: 0, overflow: "hidden", minHeight: "800px", backgroundColor: "#AAAAAA"}}
                                    className={"flex flex-centered"}
                                >
                                    <h2>Adobe Sign Goes Here</h2>
                                </div>
                            ):(
                                <iframe
                                    src={props.notificationDetails?.controlParams?.embed}
                                    width="100%"
                                    height="100%"
                                    border="0"
                                    style={{border: 0, overflow: "hidden", minHeight: "800px"}}
                                />
                            )}

                        </div>
                    )}
                    <div className="margin-md">
                        <div className="margin-top-md">
                            <div dangerouslySetInnerHTML={{ __html: props.notificationDetails.longDescription }} />
                        </div>
                    </div>
                </div>
                <hr/>
                {isEnrolled && <div className="text-center full-width">Opted in.</div>}
                {isPending && (
                    <div className="full-width margin-top-md">
                        <div className="flex justify-center spaced-content margin-top-sm">
                            <NotificationButtons
                                notificationDetails={props.notificationDetails}
                                onDecline={handleDecline}
                                onDismiss={props.onDismiss}
                                enrollDisabled={!signed}
                                isPreview={props.isPreview}
                            />
                        </div>
                    </div>
                )}
            </div>
            <SpinnerTakeover show={loading}/>
        </form>
    );
}

AdobeOnly.propTypes = {
    clinicId: PropTypes.number.isRequired,
    notification: PropTypes.object.isRequired,
    notificationDetails: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    onDismiss: PropTypes.func,
    enrollmentState: PropTypes.string.isRequired,
    isPreview: PropTypes.bool
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;

        //Permissions
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);

        return {
            canViewClinicCouponLibrary,
        }
    }
)(AdobeOnly);
