exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".BasicGridTiles__root{display:-ms-grid;display:grid;gap:1em;-ms-grid-columns:(minmax(200px, 300px))[auto-fit];grid-template-columns:repeat(auto-fit, minmax(200px, 300px));-ms-flex-pack:start;justify-content:flex-start;-ms-flex:1;flex:1;width:100%;position:relative}.BasicGridTiles__root.BasicGridTiles__medium{-ms-grid-columns:(minmax(400px, 500px))[auto-fit];grid-template-columns:repeat(auto-fit, minmax(400px, 500px))}.BasicGridTiles__root.BasicGridTiles__large{-ms-grid-columns:(minmax(450px, 550px))[auto-fit];grid-template-columns:repeat(auto-fit, minmax(450px, 550px))}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/common/BasicGridTiles.scss"],"names":[],"mappings":"AAAA,sBAAM,iBAAiB,aAAa,QAAQ,kDAAkD,6DAA6D,oBAAoB,2BAA2B,WAAW,OAAO,WAAW,iBAAiB,CAAC,6CAAa,kDAAkD,4DAA4D,CAAC,4CAAY,kDAAkD,4DAA4D,CAAC","file":"BasicGridTiles.scss","sourcesContent":[".root{display:-ms-grid;display:grid;gap:1em;-ms-grid-columns:(minmax(200px, 300px))[auto-fit];grid-template-columns:repeat(auto-fit, minmax(200px, 300px));-ms-flex-pack:start;justify-content:flex-start;-ms-flex:1;flex:1;width:100%;position:relative}.root.medium{-ms-grid-columns:(minmax(400px, 500px))[auto-fit];grid-template-columns:repeat(auto-fit, minmax(400px, 500px))}.root.large{-ms-grid-columns:(minmax(450px, 550px))[auto-fit];grid-template-columns:repeat(auto-fit, minmax(450px, 550px))}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "BasicGridTiles__root",
	"medium": "BasicGridTiles__medium",
	"large": "BasicGridTiles__large"
};