import React, {useState, useEffect} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "./VetCheckHeader.scss";
import * as VetCheckApi from "api/VetCheckApi";
import * as VetCheckActions from "actions/VetCheckActions";
import AccessDenied from "components/common/AccessDenied";
import AddOptOutForm from "components/vetcheck/forms/AddOptOutForm";
import Button from "components/common/Button";
import Image from "components/common/Image";
import Modal from "components/common/Modal";
import SendContentForm from "components/vetcheck/forms/SendContentForm";
import SignupForVetCheck from "components/vetcheck/forms/SignupForVetCheck";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import GREENLINE_VETCHECK_AUTOMATE_LOGO from "components/common/branding/vetcheck-automate-powered-by-greenline-logo.png";
import VetCheckStripe from "components/vetcheck/widgets/VetCheckStripe";
import {getLinkLocation} from "utils/ClinicAdminLinks";
import * as ClinicData from "utils/ClinicData";
import logger from "utils/logger";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {clinicSubscriptions, SubscriptionStatuses,} from "utils/SubscriptionStatus";
import * as ModalTypes from "constants/ModalTypes";
import {VETCHECK} from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";
import {ACTIVE, PENDING, REGISTERED} from "constants/VetCheckActivationStates";
import {HANDOUT, CLIENT_FORM, CHART} from "constants/VetCheckContent";

function VetCheckHeader(props) {
    const [showAddOptOut, setShowAddOptOut] = useState(false);
    const [vetCheckStatus, setVetCheckStatus] = useState({});
    const [showRegister, setShowRegister] = useState(false);
    const [loading, setLoading] = useState(false);
    const loginCallbackUri = `${props.location.origin}/vetcheck/loggedin/${props.isAdmin ? props.clinicId : null}`;
    const registerCallbackUri = `${props.location.origin}/vetcheck/registered`;

    const hasPositiveStatus = (
        props.vetCheckStatus?.activationState &&
        (
            props.vetCheckStatus.activationState === ACTIVE ||
            props.vetCheckStatus.activationState === REGISTERED ||
            props.vetCheckStatus.activationState === PENDING
        )
    );

    const getVetCheckStatus = () => {
        if(props.canViewClinicVetCheck || props.canViewVetCheckMapping) {
            setLoading(true);
            VetCheckApi.getClinicVetCheckStatus(props.clinicId, loginCallbackUri, registerCallbackUri)
                .then((res) => {
                    setVetCheckStatus(res.body);
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("loading VetCheck status", error);
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        if(!!props.clinicId && hasPositiveStatus) {
            getVetCheckStatus();
        }
    }, [props.clinicId, props.vetCheckStatus, props.hasSubscription]);

    const openLogin = (newWindow=false) => {
        if(!!vetCheckStatus?.loginUrl) {
            props.setShowChangeVetCheckPassword(false);
            if (newWindow){
                window.open(vetCheckStatus.loginUrl, "_blank");
            } else {
                window.location.href = vetCheckStatus.loginUrl;
            }
        } else {
            logger.error("The Login URL is missing.")
        }
    }

    const handleGoToSubscriptionManagement = () => {
        if(props.canViewPaymentSettings && !(props.isAdmin || props.isProvider)) {
            props.history.push(getLinkLocation("payment", props.clinicId));
        }
    }

    const signUpButton = (
        <div className="flex flex-1 spaced-content justify-flex-end margin-right-x-sm align-center">
            <div>
                <Button
                    onClick={() => setShowRegister(true)}
                    type="primary"
                    small
                    disabled={props.isProvider || !(props.canViewPaymentSettings && props.canEditClinicVetCheck)}
                    title={
                        props.isProvider ? "Provider users cannot complete the VetCheck signup process for their users" :
                        !(props.canViewPaymentSettings && props.canEditClinicVetCheck) ? "You do not have the correct permissions to sign up for VetCheck. Please contact Greenline for help." :
                        "Signup for VetCheck"
                    }
                >
                    {(!props.hasSubscription  && hasPositiveStatus) && "Complete"} VetCheck Signup
                </Button>
            </div>
        </div>
    );

    const loginButton = (
        <div className="flex flex-1 spaced-content justify-flex-end margin-right-x-sm align-center">
            <Button
                onClick={openLogin}
                type="primary"
                small
                disabled={!props.vetCheckStatus?.activationState}
            >
                VetCheck Login
            </Button>
        </div>
    );

    const getButtons = () => {
        switch(props.vetCheckStatus?.activationState) {
            case ACTIVE:
            case REGISTERED:
                if(!props.canViewClinicVetCheck) {
                    return null;
                } else if(!vetCheckStatus.isAuthenticated) {
                    // User needs to login in again (Vetcheck authentication has expired)
                    return loginButton
                }
                return (
                    <>
                        <div className="flex flex-1 spaced-content justify-flex-end margin-right-x-sm align-center">
                            <div>
                                <SendContentForm
                                    clinicId={props.clinicId}
                                    contentType={HANDOUT}
                                />
                            </div>
                            <div>
                                <SendContentForm
                                    clinicId={props.clinicId}
                                    contentType={CLIENT_FORM}
                                />
                            </div>
                            {/*<div>*/}
                            {/*     <SendContentForm*/}
                            {/*        clinicId={props.clinicId}*/}
                            {/*        contentType={CHART}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {props.canEditClinicVetCheck && (
                                <div>
                                    <Button
                                        onClick={() => setShowAddOptOut(true)}
                                        type="primary"
                                        icon
                                        small
                                    >
                                        <i className="fa fa-user-slash"/> New Opt-Out
                                    </Button>
                                </div>
                            )}
                        </div>
                        <Modal
                            show={showAddOptOut}
                            modalTitle="New Opt-Out"
                            icon="fa fa-user-slash"
                            iconFontSize={2}
                            onClose={() => setShowAddOptOut(false)}
                            small
                        >
                            {props.canEditClinicVetCheck ? (
                                <AddOptOutForm
                                    clinicId={props.clinicId}
                                    onClose={() => setShowAddOptOut(false)}
                                />
                            ) : (
                                <AccessDenied/>
                            )}
                        </Modal>
                    </>
                );
            case PENDING:
                if (!props.hasSubscription) {
                    if(props.hasExpiredSubscription) {
                        return (
                            <div className="flex flex-1 spaced-content justify-flex-end margin-right-x-sm align-center">
                                <Button
                                    onClick={handleGoToSubscriptionManagement}
                                    type="primary"
                                    small
                                    disabled={!props.canViewPaymentSettings || props.isAdmin || props.isProvider}
                                    title={
                                        props.isAdmin ? "You must impersonate the clinic admin to manage payment settings." :
                                        props.isProvider ? "You cannot mange payment methods fro a clinic." :
                                        "Mange payment methods for the clinic"
                                    }
                                >
                                    Update Payment Method
                                </Button>
                            </div>
                        )
                    } else return signUpButton;
                }
                return loginButton
            default:
                return signUpButton;
        }
    }

    const handleSignupComplete = () => {
        props.setShowChangeVetCheckPassword(true);
        setShowRegister(false);
    }

    return (
        <div className={classnames(styles.root, "flex")}>
            <div className="margin-left-x-sm flex-none flex align-center">
                <Image
                    src={GREENLINE_VETCHECK_AUTOMATE_LOGO}
                    title="VetCheck Automate - Powered by Greenline"
                    alt="VetCheck Automate - Powered by Greenline"
                    style={{maxWidth: "100%", maxHeight: "60px"}}
                />
            </div>
            {getButtons()}
            <Modal
                show={showRegister}
                onClose={() => setShowRegister(false)}
                tall
                mediumSmall
                modalStyle={ModalTypes.VET_CHECK}
            >
                {props.isProvider ? (
                    <AccessDenied customMessage="User must be in a clinic to signup for VetCheck"/>
                ) : !(props.canViewPaymentSettings && props.canEditClinicVetCheck) ? (
                    <AccessDenied/>
                ) : (
                    <VetCheckStripe>
                        <SignupForVetCheck
                            clinic={props.clinic}
                            requireClinic={!(hasPositiveStatus)}
                            requireCard={!props.isAdmin}
                            onClose={() => setShowRegister(false)}
                            onSubmit={handleSignupComplete}
                        />
                    </VetCheckStripe>
                )}
            </Modal>
            <SpinnerTakeover show={loading}/>
        </div>
    );
}

VetCheckHeader.propTypes = {
    clinicId: PropTypes.number.isRequired,
    location: PropTypes.any,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const isAdmin = !!ownProps.location.pathname.includes("/admin");
        const isProvider = !!ownProps.location.pathname.includes("/provider");
        const hasActiveSubscription = !!clinicSubscriptions(state, ownProps.clinicId, VETCHECK, SubscriptionStatuses.ACTIVE)?.length;
        const hasPaidSubscription = !!clinicSubscriptions(state, ownProps.clinicId, VETCHECK, SubscriptionStatuses.PAID)?.length;
        const hasSubscription = (hasActiveSubscription || hasPaidSubscription);
        const hasExpiredSubscription = !!clinicSubscriptions(state, ownProps.clinicId, VETCHECK, SubscriptionStatuses.EXPIRED)?.length;
        // PERMISSIONS
        const canViewClinicVetCheck = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_VETCHECK, userProfile);
        const canEditClinicVetCheck = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_VETCHECK, userProfile);
        const canViewPaymentSettings = userHasPermission(PermissionTypes.VIEW, UserPermissions.PAYMENT_SETTINGS, userProfile);
        const canViewVetCheckMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);
        return {
            isAdmin,
            isProvider,
            hasSubscription,
            hasExpiredSubscription,
            vetCheckStatus: state.entities.clinicVetCheckStatus[ownProps.clinicId],
            clinic: ClinicData.getClinicInfo(state, ownProps.clinicId),
            // PERMISSIONS
            canViewClinicVetCheck,
            canEditClinicVetCheck,
            canViewPaymentSettings,
            canViewVetCheckMapping
        }
    },
    (dispatch) => ({
        setShowChangeVetCheckPassword: (show) => dispatch(VetCheckActions.setShowChangeVetCheckPassword(show)),
    })
)(VetCheckHeader);
