import moment from "moment";

// Returns how many days ago the provided date string was. Optionally provide a Moment instance for "now".
// Assuming dates are UTC, but Moment will convert to local time for us here. Sending anything to the
// server might require converting back to UTC (not sure).
export default function getDaysAgo(thenDateString, nowDateString = null) {
    if (!thenDateString) {
        return 0;
    }

    let nowDate;
    const thenDate = moment(thenDateString);
    
    if (!nowDateString) {
        nowDate = moment();
    } else {
        nowDate = moment(nowDateString);
    }

    return Math.abs(nowDate.diff(thenDate, "days"));
}
