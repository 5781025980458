import React, { useState } from "react";
import PropTypes from "prop-types";
import { map } from "lodash";
import classnames from "classnames";
import styles from "./GreenlineGameCard.scss";
import Button from "components/common/Button";
import CountdownTimer from "components/games/elements/CountdownTimer";
import GamesThermometer from "components/games/elements/GamesThermometer";
import moment from "moment";
import { addCommasToNumber } from "utils/numeric";
import PrintContentContainer from "components/common/PrintContentContainer";

export default function GamesGrowthDosesCard(props) {
    const { game } = props;
    const [showDetails, setShowDetails] = useState(false);

    const getTierDetails = () => {
        const { tiers } = game.data.options;
        const CurrentDailyRate = game.totalDoses > 0 ? Math.ceil(game.totalDoses / game.daysInto) : 0;
        return map(tiers, (tier, index) => {
            const toGo = (game.totalDoses < tier.value) ? tier.value - game.totalDoses : 0;
            const perDay = !!toGo && !!game.daysRemaining ? Math.ceil(toGo/game.daysRemaining) : "--";
            const completedIcon = !toGo ? <i className="fas fa-check-circle" /> : "";
            return (
                <tr key={index} className={classnames({ [styles.completed]: !toGo })}>
                    <td className={styles.tierLabel}>{completedIcon}{tier.label}</td>
                    <td className="center">{addCommasToNumber(CurrentDailyRate)}</td>
                    <td className={classnames("center", styles.toGo)}>{addCommasToNumber(toGo)}</td>
                    <td className="center">{addCommasToNumber(perDay)}</td>
                    <td className="right"><b>{tier.displayValue}</b></td>
                </tr>
            )
        })
    }

    const renderTime = () => {
        if(props.dashboard) {
            return (
                <div className={styles.timeRemaining}>
                    <CountdownTimer endDate={game.endDate}/>
                </div>
            );
        } else {

            return (
                <div className={styles.timeRemaining}>
                    <span>{game.startDate} - {game.endDate}</span>
                </div>
            );
        }
    }

    const renderSummary = () => {
        if(props.dashboard) {
            return null;
        }
        let latestInvoiceDate;
        if(game.lastInvoiceDate) {
            latestInvoiceDate = game.lastInvoiceDate;
        } else if (game.details.LatestLastReportingInvoiceDate) {
            latestInvoiceDate = game.details.LatestLastReportingInvoiceDate;
        }

        return (
            <div className="flex spaced-content flex-wrap margin-bottom-x-sm">
                <span className="margin-bottom-x-sm">Your clinic has sold {game.totalDoses} doses towards this game.</span>
                {props.game?.includeHomeDeliveryDoses && (
                    <span className="margin-bottom-x-sm">
                        Your clinic has sold approximately {game.homeDeliveryDoses} Home Delivery doses toward this game this week.
                    </span>
                )}
                <span className="margin-bottom-x-sm">There are {game.daysRemaining} days remaining in this game.</span>
                <span>Latest Invoice Used: {latestInvoiceDate ? moment(latestInvoiceDate).format("MM/DD/YY") : "NONE"}</span>
            </div>
        )
    }

    const renderDetails = () => {
        if(props.dashboard && !showDetails) {
            return null;
        }

        return (
            <div className={styles.detailsInner}>
                <table className="table striped">
                    <thead>
                    <tr>
                        <th className={styles.tierLabel}>Tier</th>
                        <th className="center">Daily Rate</th>
                        <th className="center">To Go</th>
                        <th className="center">x Day</th>
                        <th className="right">Goal</th>
                    </tr>
                    </thead>
                    <tbody>
                        {getTierDetails(game)}
                    </tbody>
                </table>
            </div>
        )
    }

    const renderFooterDetails = () => {
        if(props.dashboard) {
            return null
        }

        return (
            <div className={styles.footerDetails}>
                <span>Report Date: {moment(game.reportDate).format("MM/DD/YY")}</span>
            </div>
        )

    }

    return (
        <div
            key={`game_${game.greenlineGameId}`}
            className={classnames(styles.root, {
                [styles.historic]: !game.isCurrent,
            })}
        >
            <PrintContentContainer allowPrint={!props.dashboard}>
                <div className={styles.gameContainer}>
                    <h4>{game.name}</h4>
                    {renderTime()}
                    <div className={styles.thermoWrapper}>
                        <GamesThermometer game={game} />
                    </div>
                    <div className={classnames(styles.details, styles.left)}>
                        {props.dashboard && (
                            <Button
                                onClick={() => setShowDetails(!showDetails)}
                                type={"outline"}
                                text
                            >
                                <span>Details</span><i className={classnames("fas", {
                                    "fa-chevron-down": !showDetails,
                                    "fa-chevron-up": showDetails,
                                })}/>
                            </Button>
                        )}
                        {renderSummary()}
                        {renderDetails()}
                        {renderFooterDetails()}
                    </div>
                </div>
            </PrintContentContainer>
        </div>
    )
}

GamesGrowthDosesCard.propTypes = {
    game: PropTypes.object,
    dashboard: PropTypes.bool,
};
