exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".TourCompletion__root ol,.TourCompletion__root ul{list-style:none;margin:0;font-size:1.25em}.TourCompletion__root ol li,.TourCompletion__root ul li{margin:0 0 .5em 0}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/tour/widgets/TourCompletion.scss"],"names":[],"mappings":"AAAA,kDAAkB,gBAAgB,SAAS,gBAAgB,CAAC,wDAAwB,iBAAiB,CAAC","file":"TourCompletion.scss","sourcesContent":[".root ol,.root ul{list-style:none;margin:0;font-size:1.25em}.root ol li,.root ul li{margin:0 0 .5em 0}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "TourCompletion__root"
};