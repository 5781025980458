import React from "react";
import { connect } from "react-redux";
import ProviderBase from "components/layout/ProviderBase";
import EditGame from "components/provider/widgets/EditGame";
import AccessDenied from "components/common/AccessDenied";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import {getNodeId} from "utils/AdminData";
import getProviderId from "utils/getProviderId";

function GamePage(props) {
    return (
        <ProviderBase
            location={props.location}
            pageTitle="Greenline Games™"
            collapsible
            nodeId={props.nodeId}
            providerId={props.providerId}
        >
            {props.canViewGames ? (
                <EditGame
                    isCopy
                    id={props.copyId}
                    nodeId={props.nodeId}
                    providerId={props.providerId}
                    isAdmin={props.isAdmin}
                />
            ) : (
                <AccessDenied/>
            )}
        </ProviderBase>
    );
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const params = ownProps?.match?.params || {};
        const copyId = Number(params?.copyId);
        const nodeId = getNodeId(state, ownProps);
        const providerId = getProviderId(params, userProfile);

        // Permissions
        const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewGamesAdmin = userHasPermission(PermissionTypes.VIEW, UserPermissions.GAMES_ADMIN, userProfile);
        const canViewGames = (canAdminProviderBiGames && canViewGamesAdmin) || canViewProviderBiGames;

        return {
            copyId,
            nodeId,
            providerId,
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && providerId && nodeId),

            // Permissions
            canViewGames,
        }
    }
)(GamePage);
