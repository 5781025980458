exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".VoucherManagementTools__root{width:100%;margin:0;-ms-flex:none;flex:none;display:-ms-flexbox;display:flex}@media (max-width: 600px){.VoucherManagementTools__root .VoucherManagementTools__voucherCount{width:100%;margin-right:0}}.VoucherManagementTools__root .VoucherManagementTools__voucherCount>div{padding-bottom:0;margin-right:0}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/admin/widgets/VoucherManagementTools.scss"],"names":[],"mappings":"AAAA,8BAAM,WAAW,SAAS,cAAc,UAAU,oBAAoB,YAAY,CAAC,0BAA0B,oEAAoB,WAAW,cAAc,CAAC,CAAC,wEAAwB,iBAAiB,cAAc,CAAC","file":"VoucherManagementTools.scss","sourcesContent":[".root{width:100%;margin:0;-ms-flex:none;flex:none;display:-ms-flexbox;display:flex}@media (max-width: 600px){.root .voucherCount{width:100%;margin-right:0}}.root .voucherCount>div{padding-bottom:0;margin-right:0}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "VoucherManagementTools__root",
	"voucherCount": "VoucherManagementTools__voucherCount"
};