import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import styles from "components/provider/GamesManagement/ProviderGamesManagementPage.scss";
import map from "lodash/map";
import includes from "lodash/includes";
import * as ProviderApi from "api/ProviderApi";
import AccessDenied from "components/common/AccessDenied";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function SelectNodeUserWrapper(props) {
    const location = useLocation();
    const params = useParams();
    const nodeId = Number(params?.nodeId) || null;
    const isDemo = includes(location?.pathname, "/demo") && props.canViewClinicBIGamesDashboard;
    const [loading, setLoading] = useState(false);
    const [nodeInfo, setNodeInfo] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showSelectUser, setShowSelectUser] = useState(false);
    const userId = isDemo ? 0 : (selectedUser ? selectedUser.userId : nodeId ? props.currentUser?.userId : null);

    const fetchOrgNode = async (nodeId) => {
        setSelectedUser(null);
        setLoading(true);

        await ProviderApi.getNode(nodeId)
            .then((res) => {
                setNodeInfo(res.body);
                if (res.body.users.length) {
                    if (res.body.users.length > 1) {
                        setShowSelectUser(true);
                    } else {
                        setSelectedUser(res.body.users[0]);
                    }
                }
            })
            .catch((error) => {
                handleErrorResponse("loading org node", error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if (!isDemo && props.canViewProviderInfo && nodeId) {
            fetchOrgNode(nodeId);
        }
    }, [props.canViewProviderInfo, nodeId]);

    if (!props.canViewProviderInfo) {
        return (
            <AccessDenied />
        )
    }

    const handleSelectUser = (user) => {
        setSelectedUser(user);
        setShowSelectUser(false);
    }

    const childrenWithProps = React.Children.map(props.children, child => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { userId });
        }
        return child;
    });

    return (
        <div>
            {showSelectUser ? (
                <div className="margin-md">
                    <h2 className="text-center">Select a User</h2>
                    <div className="flex justify-flex-center flex-wrap">
                        {map(nodeInfo.users, user => (
                            <div
                                className={classnames(styles.userTiles, "flex-none flex justify-flex-center align-center margin-x-sm")}
                                onClick={() => handleSelectUser(user)}
                            >
                                {user.firstName} {user.lastName}
                            </div>
                        ))}
                    </div>
                </div>
            ) : childrenWithProps}
            <SpinnerTakeover show={loading} />
        </div>
    );
}
export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const currentOrgNode = state.entities.orgNode?.current;
        const currentUser = currentOrgNode?.users[0] ? currentOrgNode?.users[0] : userProfile;
        const canViewProviderInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            currentUser,
            // Permissions
            canViewProviderInfo,
            canViewClinicBIGamesDashboard,
        };
    }
) (SelectNodeUserWrapper);