import * as Oidc from "oidc-client";
import env from "utils/environment";
import LocalData from "./LocalData";
import logger from "utils/logger";

// Oidc.Log.logger = logger;
// Oidc.Log.level = Oidc.Log.DEBUG;

let mgr;
export const getManagerConfig = () => {
    const idpRoot = env.getValue("IDENTITY_ROOT");
    const clientId = env.getValue("IDENTITY_CLIENT_ID");
    const clientRoot = window.location.origin;

    return {
        authority: idpRoot,
        client_id: clientId,
        popup_redirect_uri: clientRoot + "/popup_callback.html",
        redirect_uri: clientRoot + "/callback.html",
        response_type: "code",
        response_mode: "query",
        scope: "glwebapi.read openid profile email offline_access role",
        post_logout_redirect_uri: clientRoot + "/index.html",
        clockSkew: 7200,
        silentRequestTimeout: 30000,
        automaticSilentRenew: true,
    };
};

export const getManager = () => {
    if (!env.isLoaded) {
        throw Error("Trying to load manager without environment loaded");
    }
    if (!mgr) {
        const config = getManagerConfig();
        mgr = new Oidc.UserManager(config);
        mgr.events.addUserLoaded((user) => {
            // logger.log("****** USER LOADED: ", user); // Test log of the auth credentials from server
            LocalData.setToken(user.access_token);
        });
        mgr.events.addUserUnloaded(() => logger.log("********USER UNLOADED"));
        mgr.events.addAccessTokenExpiring(() => logger.log("*********ACCESS TOKEN EXPIRING"));
        mgr.events.addAccessTokenExpired(() => {
            logger.log("******* ACCESS TOKEN EXPIRED");
            LocalData.clearToken();
        });
        mgr.events.addSilentRenewError((error) => logger.log("**********SILENT RENEW ERROR", error));
        // mgr.events.addUserSignedIn((evt) => logger.log("", evt));
        mgr.events.addUserSignedOut((e) => {
            logger.log("*********USER SIGNED OUT");
            //LocalData.clearToken(); // We are getting "Signed out" when the token is still valid for some reason
        });
        mgr.events.addUserSessionChanged(() => {
            logger.log("**********USER SESSION CHANGED");
            // TODO: Trigger a reload of UserProfile
        });
    }
    return mgr;
};

export async function redirectToLogin() {
    try {
        const response = await getManager().signinRedirect();
        return response;
    } catch (e) {
        logger.error(e);
    }
}

export async function popupToLogin() {
    try {
        const response = await getManager().signinPopup();
        return response;
    } catch (e) {
        logger.error(e);
    }
}

export function getUser() {
    return getManager().getUser();
}

export function redirectToLogout() {
    getManager().signoutRedirect();
}

window.redirectToLogout = redirectToLogout;
