import Feline from "components/common/branding/mapping/feline.png";
import Canine from "components/common/branding/mapping/canine.png"

export default function getSpeciesIcon(species) {
    switch(species) {
        case "Feline":
            return Feline;
        case "Canine":
            return Canine;
        default:
            return null

    }
}