import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Leaderboard.scss";
import find from "lodash/find";
import map from "lodash/map";
import sortBy from "lodash/sortBy";

const ANIMATION_DURATION = 2500; // Make sure this matches up with animation duration in css file
const ANIMATION_CYCLE_SPEED = 250; // How fast the animation changes frames

export default function Leaderboard(props) {
    const [showPoints, setShowPoints] = useState(false);
    const [isFrame2, setIsFrame2] = useState(false);
    const sortedPuppyData = sortBy(props.puppyData, "Place");
    const topTenPuppyData = sortedPuppyData.slice(0, 10);
    const currentClinicPuppyData = !!props.currentClinicId ? find(sortedPuppyData, { clinicId: props.currentClinicId }) : null;
    const currentClinicAfter10 = !!currentClinicPuppyData && currentClinicPuppyData.Place > 10;

    const showTeamPoints = () => {
        setShowPoints(true);
    };

    const setAnimationFrame = (animationFrame) => {
        if (animationFrame === 0) {
            setIsFrame2(false);
        } else {
            setIsFrame2(true);
        }
    };

    const playAnimationCycle = (durationCounter=0, animationFrame=0) => {
        setTimeout(() => {
            setAnimationFrame(animationFrame);

            if (durationCounter < ANIMATION_DURATION) {
                const nextAnimationFrame = animationFrame === 0 ? 1 : 0;
                playAnimationCycle(durationCounter + ANIMATION_CYCLE_SPEED, nextAnimationFrame);
            } else {
                // Animation is done
                showTeamPoints();
            }
        }, ANIMATION_CYCLE_SPEED);
    };

    useEffect(() => {
        if (props.isAnimated) {
            playAnimationCycle(); // Start the animation loop
        } else {
            setAnimationFrame(0);
            showTeamPoints();
        }
    }, [props.isAnimated]);

    if (!props.puppyData.length) return null;

    const renderTeamDetails = (pup, rowWidthPercentage, showDoses=false) => {
        let percentGrowthString = "0%";
        if (pup.PercentGrowth !== null && !isNaN(pup.PercentGrowth)) {
          percentGrowthString = `${Math.round(100 + pup.PercentGrowth).toFixed(0)}%`;
        }

        return (
            <div className={styles.puppyRowContent}>
                {showDoses ? (
                    <>
                        <div className="flex align-center">
                            <div className={classnames(styles.puppyRanking, "flex-row")}>
                                <span className={styles.poundSign}>#</span>
                                {pup.Place}
                            </div>
                            <div className={styles.teamName}>
                                {pup.ClinicAlias}
                            </div>
                        </div>
                        <div
                            className={styles.teamPoints}
                            style={{
                                ...(rowWidthPercentage < 30) ? {
                                    position: "absolute",
                                    left: "calc(40px + 100%)",
                                    top: "-7px",
                                } : {},
                                display: showPoints ? "initial" : "none",
                            }}
                        >
                            {pup.totalDoses || 0} doses, {percentGrowthString} VLY
                        </div>
                    </>
                ) : (
                    <>
                        <div className="flex align-center">
                            <div className={classnames(styles.puppyRanking, "flex-row")}>
                                <span className={styles.poundSign}>#</span>
                                {pup.Place}
                            </div>
                            <div className={styles.teamName}>
                                {pup.ClinicAlias}
                            </div>
                        </div>
                        <div className={styles.teamPoints}>
                            {percentGrowthString} VLY
                        </div>
                    </>
                )}
            </div>
        )

    };

    const renderPuppyRow = (pup) => {
        const isCurrentTeam = pup.clinicId === props.currentClinicId;
        const pointsVisible = isCurrentTeam || props.showAllPoints;
        let rowWidthPercentage = 50 + pup.PercentGrowth / 2;
        let isWinning = false;
        let hasMadeItToTheEndZone = false;
        if (rowWidthPercentage >= 100) {
          isWinning = true;
          rowWidthPercentage = 100;
          hasMadeItToTheEndZone = true;
          console.log("Has made it to the end zone");
        }

        if (pup.Place <= 10) {
            // TODO:
            // puppySprite = applyCustomPuppyColors(puppySprite, ranking);
        }

        return (
            <div
                id={`puppy_content_${pup.Place}`}
                className={classnames(styles.puppyRowAnimationContainer, "flex-align-center", {
                    [styles.animated]: props.isAnimated,
                    [styles.puppyRowCurrentTeam]: isCurrentTeam,
                })}
                style={{maxWidth: `${rowWidthPercentage}%`}}
            >
                <div className={classnames(styles.puppySprite, {
                    [styles.animating]: props.isAnimated,
                    [styles.transparent]: rowWidthPercentage < 40,
                    [styles.frame2]: isFrame2,
                })}>
                    {hasMadeItToTheEndZone && showPoints ? (
                        <img
                            src="../../../../../../plugins/puppy_pool_party_leaderboard/assets/puppy_sprite_winning.svg"
                            alt={pup.ClinicAlias}
                        />
                    ) : (
                        <img
                            src="../../../../../../plugins/puppy_pool_party_leaderboard/assets/puppy_sprite_swimming.svg"
                            alt={pup.ClinicAlias}
                        />
                    )}
                </div>
                {renderTeamDetails(pup, rowWidthPercentage, pointsVisible)}
                <div className={styles.progressBar}>

                </div>
            </div>
        );
    };

    return (
        <div className={styles.fieldContainer} id="field-container">
            <div className={styles.fieldSizer}>
                <div className={styles.fieldImageSizer}>
                    <img
                        className={styles.fieldBackgroundImage}
                        src="../../../../../../plugins/puppy_pool_party_leaderboard/assets/pool_leaderboard_bg.jpg"
                        alt="Swimming Pool"
                    />

                    <div className={styles.fieldContent} id="field-content">
                        <div className={styles.puppies}>
                            {map(topTenPuppyData, (pup) => (
                                <div
                                    key={pup.clinicId}
                                    id={`puppy_${pup.Place}`}
                                    className={classnames(styles.puppyRow, {
                                        [styles.puppyRowSmaller]: currentClinicAfter10,
                                    })}
                                >
                                    {renderPuppyRow(pup)}
                                </div>
                            ))}
                            {currentClinicAfter10 && (
                                <div
                                    id="current_clinic_rank_above_10"
                                    className={classnames(styles.puppyRow, {
                                        [styles.rankAbove10Hidden]: !currentClinicAfter10,
                                    })}
                                >
                                    {renderPuppyRow(currentClinicPuppyData)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.scoreContainer}>
                <div className={styles.scores}>
                    <span>20%</span>
                    <span>40%</span>
                    <span>60%</span>
                    <span>80%</span>
                    <span>100%</span>
                    <span>120%</span>
                    <span>140%</span>
                    <span>160%</span>
                    <span>180%</span>
                    <span>200%</span>
                </div>
            </div>
        </div>
    );
}

Leaderboard.defaultProps = {
    puppyData: [],
    currentClinicId: undefined,
    isAnimated: false,
    showAllPoints: false,
};

Leaderboard.propTypes = {
    puppyData: PropTypes.array,
    currentClinicId: PropTypes.number,
    isAnimated: PropTypes.bool,
    showAllPoints: PropTypes.bool,
};
