import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import * as Yup from "yup";
import Button from "components/common/Button";
import ClinicFilterShort from "components/admin/elements/ClinicFilter/ClinicFilterShort";
import TextBox from "components/common/TextBox";
import useFormHandlers from "utils/useFormHandlers";

const schema = Yup.object().shape({
    search: Yup.string().nullable(true),
    name: Yup.string().required(),
    limit: Yup.number().nullable(true),
    orderBy: Yup.string().nullable(true),
    orderDir: Yup.string().nullable(true),
    offset: Yup.number().nullable(true),
    isFavorite: Yup.bool().nullable(true),
    isPinned: Yup.bool().nullable(true),
    filters: Yup.array().of(
        Yup.object().shape({
            id: Yup.string(),
            equality: Yup.string(),
            order: Yup.number(),
            isComplete: Yup.bool(),
            level1Values: Yup.array().of(
                Yup.object().shape({
                    value: Yup.string(),
                }).nullable(true),
            ).nullable(true),
            level2Values: Yup.array().of(
                Yup.object().shape({
                    value: Yup.string(),
                }).nullable(true),
            ).nullable(true),
        }).nullable(true),
    ).nullable(true),
});

export default function SaveCurrentViewForm(props) {
    const { formData, isValid, handleUpdate, invalidFields } = useFormHandlers(
        props.initialData,
        schema
    );

    return (
        <form onSubmit={(e) => props.onSubmit(e, formData)}>
            {!!formData?.search && (
                <div>Search: {formData.search}</div>
            )}
            {formData?.limit && (
                <TextBox
                    label="Results Per Page"
                    inputType="number"
                    name="limit"
                    value={formData.limit || 0}
                    onChange={() => {}}
                    disabled
                />
            )}
            {!!formData.filters?.length && (
                <div>
                    <div className="margin-left-sm">Filters</div>
                    <div className="flex flex-wrap">
                        {map(formData.filters, fil => (
                            <div key={fil.order} className="flex-none margin-bottom-x-sm">
                                <ClinicFilterShort
                                    key={fil.order}
                                    fil={fil}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            )}
            <hr className="margin-top-sm"/>
            <TextBox
                required
                label="Name"
                placeholder="Enter Name for View"
                value={formData.name}
                name="name"
                onChange={handleUpdate}
            />
            <div className="flex spaced-content justify-flex-end">
                <Button
                    type="gray"
                    onClick={props.onCancel}
                >
                    Cancel
                </Button>
                <Button
                    buttonType="submit"
                    disabled={!isValid}
                >
                    Save
                </Button>
            </div>
        </form>
    );
}

SaveCurrentViewForm.propTypes = {
    initialData: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
