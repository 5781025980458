import React from "react";
import PropTypes from "prop-types";
import styles from "./PlanStatusFilterDropdown.scss";
import Dropdown from "components/common/Dropdown";
import * as ProviderId from "constants/ProviderIds";

const WELLNESS_OPTIONS = Object.freeze([
    { name: "Greenline Wellness", value: ProviderId.GREENLINE_WELLNESS },
    { name: "Premier Pet Care Plan", value: ProviderId.PREMIER_PET_CARE_PLAN },
]);

export default function WellnessProvidersFilterDropdown(props) {
    const handleChanged = (value) => {
        if (props.onChange) {
            props.onChange(value);
        }
    };

    return (
        <div className={`${styles.root} margin-right-sm`}>
            <Dropdown
                label={props.label}
                name={props.name}
                value={props.value}
                options={WELLNESS_OPTIONS}
                onChange={handleChanged}
                placeholder={props.placeholder}
                small={props.small}
                wide={props.wide}
                fullWidth={props.fullWidth}
            />
        </div>
    );
}

WellnessProvidersFilterDropdown.defaultProps = {
    fullWidth: false,
    onChange: undefined,
    placeholder: "--Select Manufacturer--",
    small: false,
    value: null,
    wide: false,
}

WellnessProvidersFilterDropdown.propTypes = {
    fullWidth: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    small: PropTypes.bool,
    value: PropTypes.number,
    wide: PropTypes.bool,
}
