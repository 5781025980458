import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import styles from "./ClinicTable.scss";
import * as CouponsApi from "api/CouponsApi";
import * as AdminActions from "actions/AdminActions";
import * as CouponActions from "actions/CouponActions";
import AccessDenied from "components/common/AccessDenied";
import AddReprocessingJobForm from "components/admin/forms/AddReprocessingJobForm";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TokenViewer from "components/admin/widgets/TokenViewer";
import getLocalTimezone from "utils/getLocalTimezone";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as ReprocessCouponStatus from "constants/ReprocessCouponStatus";
import * as UserPermissions from "constants/UserPermissions";
import DataTablePagination from "../../common/datatable/DataTablePagination";

function ReprocessCouponsTable(props) {
    const [coupons, setCoupons] = useState({});
    const [offset, setOffset] = useState(0);
    const [orderBy, setOrderBy] = useState("tokenId");
    const [orderDir, setOrderDir] = useState("desc");
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [canGoNext, setCanGoNext] = useState(false);
    const [canGoPrevious, setCanGoPrevious] = useState(false);
    const [loading, setLoading] = useState(false);
    const limit = 20;
    const [tokenId, setTokenId] = useState(null);
    const [showTokenViewer, setShowTokenViewer] = useState(false);

    useEffect(() => {
        setLoading(true);
        loadCoupons({search: props.search, limit, offset, fromAllUsers:true, orderBy:orderBy, orderDir:orderDir}, page);
    }, [props.search, orderBy, orderDir]);

    const handleGoNext = () => {
        setLoading(true);
        const newOffset = offset + limit;
        setOffset(newOffset);
        const tempPage = page + 1;
        setPage(tempPage);
        loadCoupons({search: props.search, limit, offset:newOffset, fromAllUsers:true, orderBy:orderBy, orderDir:orderDir}, tempPage);
    }

    const handleGoPrevious = () => {
        setLoading(true);
        const newOffset = offset - limit;
        setOffset(newOffset);
        const tempPage = page - 1;
        setPage(tempPage);
        loadCoupons({search: props.search, limit, offset:newOffset, fromAllUsers:true, orderBy:orderBy, orderDir:orderDir}, tempPage);
    }

    const loadCoupons = (searchData = {search:props.search, limit:15, offset:0, fromAllUsers: true, orderBy: orderBy, orderDir: orderDir}, page) => {
        if (props.canViewCouponReprocessing) {
            setLoading(true);
            CouponsApi.searchCoupons(searchData)
                .then((res) => {
                    setCoupons(res.body.data);
                    const tempTotalPages = Math.ceil(res.body.totalRecordCount / limit);
                    setTotalPages(tempTotalPages);
                    setCanGoNext(page < tempTotalPages);
                    setCanGoPrevious(page > 1);
                    setLoading(false);
                })
                .catch((error) => {});
        }
    };

    const handleRowClicked = (row) => {
        if(props.canEditCouponReprocessing) {
            setShowTokenViewer(true);
            setTokenId(row.tokenId);
        }
    }

    const handleSort = (sortField, direction) => {
        setOrderBy(sortField);
        setOrderDir(direction);
        setPage(1);
        setOffset(0);
    }

    const handleAddJob = (data) => {
        if (props.canEditCouponReprocessing) {
            props.createReprocessingJob(data);
            props.hideAddForm();
        }
    }

    const COLUMNS = [{
        name: "Token",
        selector: "tokenId",
        key: "tokenId",
        sortable: true,
        searchable: true,
    },{
        name: "Clinic",
        selector: "clinicName",
        key: "clinicName",
        sortable: true,
        searchable: true,
        format: row => <div className="nowrap">{row.clinicName} ({row.clinicId})</div>
    }, {
        name: "Inv.",
        selector: "invoiceCount",
        key: "invoiceCount",
        // sortable: true,
    }, {
        name: "Redem.",
        selector: "redemptionCount",
        key: "redemptionCount",
        // sortable: true,
    }, {
        name: "Pend.",
        selector: "pendingApprovalCount",
        key: "pendingApprovalCount",
        sortable: true,
    }, {
        name: "Created",
        selector: "createDate",
        key: "createDate",
        sortable: true,
        format: row => <div className="nowrap">{row.createDate ? getLocalTimezone(row.createDate, 'MM/DD/YYYY LTS') : null}</div>,
    }, {
        name: "Created By",
        selector: "createUserFullname",
        key: "createUserFullname",
        // sortable: true,
        searchable: true,
        format: row => <div className="nowrap">{row.createUserFullname}</div>
    }, {
        name: "Status",
        selector: "status",
        key: "status",
        sortable: true,
        format: row => <div className="text-center"><i className={ReprocessCouponStatus.ICONS[row.status]}/></div>,
    }];

    return (
        <div className={styles.root}>
            {props.canViewCouponReprocessing ? (
                <>
                    <SortableDataTable
                        allowSearch
                        columns={COLUMNS}
                        rawData={coupons}
                        onClick={handleRowClicked}
                        hideSearchBar
                        onSort={handleSort}
                    />
                    {/*<*/}
                    {/*<div>*/}
                    {/*    {(totalPages > 1) && (*/}
                    {/*        <div className="flex full-width flex-centered">*/}
                    {/*            <div className="flex-none">*/}
                    {/*                <Button*/}
                    {/*                    disabled={!canGoPrevious}*/}
                    {/*                    onClick={handleGoPrevious}*/}
                    {/*                >*/}
                    {/*                    <i className="fa fa-chevron-left margin-right-sm" /> Previous*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}

                    {/*            <div className="flex-1 text-center">*/}
                    {/*                Page {page} of {totalPages}*/}
                    {/*            </div>*/}
                    {/*            <div className="flex-none">*/}
                    {/*                <Button*/}
                    {/*                    disabled={!canGoNext}*/}
                    {/*                    onClick={handleGoNext}*/}
                    {/*                >*/}
                    {/*                    Next <i className="fa fa-chevron-right margin-left-sm" />*/}
                    {/*                </Button>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    )}*/}
                    {/*</div>*/}
                    {totalPages > 1 && (
                        <DataTablePagination
                            canGoNext={canGoNext}
                            onNext={handleGoNext}
                            canGoPrevious={canGoPrevious}
                            onPrevious={handleGoPrevious}
                            page={page}
                            totalPages={totalPages}
                            minimal={props.minimalPagination}
                        />
                    )}
                    <SpinnerTakeover show={loading}/>
                </>
            ) : (
                <AccessDenied/>
            )}

            <Modal
                show={showTokenViewer}
                onClose={() => setShowTokenViewer(false)}
                modalTitle={`Token - ${tokenId}`}
            >
                {props.canEditCouponReprocessing ? (
                    <TokenViewer tokenId={tokenId}/>
                ) : (
                    <AccessDenied/>
                )}
            </Modal>

            <Modal
                show={props.addNewForm}
                onClose={() => props.hideAddForm()}
                modalTitle="Create Reprocessing Job"
                medium
            >
                {props.canEditCouponReprocessing ? (
                    <AddReprocessingJobForm
                        onSubmit={handleAddJob}
                        onCancel={() => props.hideAddForm()}
                    />
                ) : (
                    <AccessDenied/>
                )}
            </Modal>
        </div>
    );
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewCouponReprocessing = userHasPermission(PermissionTypes.VIEW, UserPermissions.COUPON_REPROCESSING, userProfile);
        const canEditCouponReprocessing = userHasPermission(PermissionTypes.EDIT, UserPermissions.COUPON_REPROCESSING, userProfile);
        return {
            canViewCouponReprocessing,
            canEditCouponReprocessing,
            search: state.entities.reprocessingJobs.query,
            addNewForm: state.entities.adminTools.addNewForm || false,
        }
    },
    (dispatch) => ({
        createReprocessingJob: (data) => dispatch(CouponActions.createReprocessingJob(data)),
        hideAddForm: () => dispatch(AdminActions.hideAddForm()),
    })
)(ReprocessCouponsTable);
