import React from "react";
import {
	HELMET_STRIPE,
	HELMET_HOLE,
	HELMET_BG,
	JERSEY_STRIPE,
	JERSEY_SLEEVE,
	JERSEY_BG,
	TEAM_COLORS
} from "constants/Games/PetsBowl/UniformColors";
import styles from "./PuppyRunning.scss";


export default function PuppyRunning(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		<svg
			id={`teamNumber-${props.teamNumber}`}
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			// xmlns:xlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 1094.33 381.78"
		>
		    <g>
		        <g>
		            <g>
		                <g id="FlashAICB_Assets_Layer_1_Clip_Group_0_0_Layer2_0_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  0 0 Layer2 0 FILL">
		                    <path className={styles.cls5} d="m905.24,363.26c0-5.07-12.15-9.4-36.45-13-24.3-3.57-53.65-5.35-88.05-5.35s-62.52,1.78-85.35,5.35c-22.53,3.53-33.8,7.87-33.8,13s11.27,9.47,33.8,13c22.83,3.57,51.28,5.35,85.35,5.35s63.75-1.78,88.05-5.35c24.3-3.6,36.45-7.93,36.45-13Z"/>
		                </g>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_1_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 1 FILL">
		                <path className={styles.cls7} d="m756.84,287.01c-.07-6.07-.22-10.63-.45-13.7-.4-5.3-1.37-9.92-2.9-13.85-4-10.23-7.55-17.85-10.65-22.85-5.67-9.2-11.78-14.28-18.35-15.25-9-1.33-16.33.48-22,5.45-4.93,4.3-8.33,10.7-10.2,19.2-1.67,7.5-1.98,15.63-.95,24.4.97,8.27,2.87,15.25,5.7,20.95,2.17,4.37,6.6,8.88,13.3,13.55,8.67,6.07,13.82,9.93,15.45,11.6,1.07,1.1,3.72,3.92,7.95,8.45,5.53,5.83,10.22,10.22,14.05,13.15,6.1,4.6,12.2,7.45,18.3,8.55,4.77.87,8.55.62,11.35-.75,3.37-1.67,5.2-4.92,5.5-9.75.27-4.43-1.42-8.5-5.05-12.2-4.77-3.9-8.22-6.87-10.35-8.9-.7-.67-1.3-1.28-1.8-1.85-5.5-6.3-8.47-15.03-8.9-26.2Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_2_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 2 FILL">
		                <path className={styles.cls7} d="m868.44,246.16c-1.7-7-6.17-10.77-13.4-11.3-6.47-.47-12.57,1.47-18.3,5.8-2.9,2.23-5.45,5.67-7.65,10.3-3.6,7.67-5.43,11.57-5.5,11.7-6.8,9.23-13.13,18.88-19,28.95-11.63,19.93-16.25,33.82-13.85,41.65,2.77,8.87,6.5,12.98,11.2,12.35,3.97-.53,9.1-4.58,15.4-12.15,2.73-3.27,8.97-11.65,18.7-25.15,8.57-11.83,15.62-20.72,21.15-26.65,4.2-4.5,7.42-9.95,9.65-16.35,2.47-7.03,3-13.42,1.6-19.15Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_3_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 3 FILL">
		                <path className={styles.cls13} d="m791.79,170.01c-2.17,2.07-3.53,3.32-4.1,3.75-1.8,1.37-4.3,2.77-7.5,4.2-3.63,1.57-6.28,2.7-7.95,3.4-3.27,1.43-5.7,2.47-7.3,3.1-2.83,1.03-5.38,1.75-7.65,2.15-5.43,1.2-9.3,2-11.6,2.4-3.77.7-7.65.83-11.65.4-5.33-.6-13.37-.77-24.1-.5-13.93.3-22.6,1.55-26,3.75-7.6,4.87-13.67,12.9-18.2,24.1-4.67,11.53-5.58,21.52-2.75,29.95,3.33,9.93,8.42,17.88,15.25,23.85,6.93,6.03,15.23,9.65,24.9,10.85,3.93.47,9.83-.02,17.7-1.45,8.47-1.53,14.37-2.23,17.7-2.1,4.57.17,8.93,1.15,13.1,2.95,6.1,2.6,10.35,4.22,12.75,4.85,17.57,4.7,31.23,7.3,41,7.8,15.53.8,28.92-2.25,40.15-9.15,9.63-5.97,16.9-13.43,21.8-22.4,4.67-8.53,6.88-17.78,6.65-27.75,2-7.53,3.18-13.83,3.55-18.9.23-2.93.55-5.38.95-7.35-.19.83-5.81,1.22-16.85,1.15-8.23-.07-15.88-.97-22.95-2.7-9.17-2.27-17.23-5.82-24.2-10.65-8.43-5.87-13.7-12.73-15.8-20.6-.33-2-.6-3.48-.8-4.45-.33-1.73-.92-3.03-1.75-3.9-1.3.57-2.75,1.65-4.35,3.25Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_4_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 4 FILL">
		                <path className={styles.cls7} d="m870.14,256.01c-1.77.4-3.15.7-4.15.9-1.83.4-3.42.52-4.75.35-1.97-.23-4.95-1.23-8.95-3-3.73-1.63-7-2.5-9.8-2.6-3.73-.13-7.22.1-10.45.7-2.7.5-5.98,1.42-9.85,2.75-8.2,2.8-15.58,5.02-22.15,6.65-8.17,2.03-15.75,3.35-22.75,3.95-5.77.5-13.13-.77-22.1-3.8-10.73-3.63-18-5.67-21.8-6.1-5.83-.67-10.55-.7-14.15-.1-4.8.77-9.05,2.72-12.75,5.85-3.03,2.57-5.03,5.6-6,9.1-.87,3.2-.87,6.65,0,10.35l2.65.4c3.93.47,9.83-.02,17.7-1.45,8.47-1.53,14.37-2.23,17.7-2.1,4.57.17,8.93,1.15,13.1,2.95,6.1,2.6,10.35,4.22,12.75,4.85,17.57,4.7,31.23,7.3,41,7.8,15.53.8,28.92-2.25,40.15-9.15,11.73-7.27,19.93-16.7,24.6-28.3Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_5_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 5 FILL">
		                <path className={styles.cls5} d="m660.04,188.16c-1.5,3.03-2.17,5.85-2,8.45l.1.5c4.8,5.23,9.73,9.67,14.8,13.3,5.5,3.97,10.65,6.6,15.45,7.9,3.1.77,5.87.72,8.3-.15,3.23-1.17,4.5-3.68,3.8-7.55-.53-2.8-2.55-6.02-6.05-9.65-2.9-3-5.6-5.2-8.1-6.6-.67-.37-3.6-1.67-8.8-3.9-3.1-1.33-5.77-3.1-8-5.3l-3.65-3.7c-2.33,1.4-4.28,3.63-5.85,6.7Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_6_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 6 FILL">
		                <path className={styles.cls13} d="m654.59,170.06l-2-1.95c-3.97-3.77-7.1-4.77-9.4-3-1.33,1.03-1.9,2.68-1.7,4.95.13,1.83.7,3.8,1.7,5.9l.5.9c2.03,4.03,4.72,8.25,8.05,12.65,2.17,2.8,4.3,5.33,6.4,7.6l-.1-.5c-.17-2.6.5-5.42,2-8.45,1.57-3.07,3.52-5.3,5.85-6.7-5-5.1-8.77-8.9-11.3-11.4Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_7_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 7 FILL">
		                <path className={styles.cls7} d="m822.59,349.96c1.67-4.43,1.08-9.2-1.75-14.3-2.8-5.07-7.03-8.98-12.7-11.75-5.67-2.73-11.08-3.5-16.25-2.3-5.17,1.23-8.58,4.07-10.25,8.5-1.67,4.47-1.1,9.23,1.7,14.3,2.83,5.07,7.07,8.97,12.7,11.7,5.67,2.73,11.1,3.5,16.3,2.3,5.17-1.2,8.58-4.02,10.25-8.45Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_8_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 8 FILL">
		                <path className={styles.cls13} d="m845.69,254.76c2.6-8.1,3.23-14.43,1.9-19-1.77-5.93-6.22-8.4-13.35-7.4-5.37.77-11.12,3.15-17.25,7.15-9.87,6.4-19.48,16.42-28.85,30.05-7.23,10.5-14.03,22.67-20.4,36.5-1.3,2.87-3.42,6.65-6.35,11.35-3.3,5.3-5.52,9.03-6.65,11.2-2.93,5.7-4.8,10.28-5.6,13.75-1.37,5.7-.75,10.33,1.85,13.9,3.6,4.9,7.95,8.37,13.05,10.4,5.6,2.23,11.03,2.2,16.3-.1,1.67-.73,3.02-1.7,4.05-2.9,1.17-1.4,1.62-2.85,1.35-4.35-.07-.43-.42-1.37-1.05-2.8-2.33-5.57-.03-12.38,6.9-20.45,3.8-4.4,9.38-10,16.75-16.8,11.97-13.37,21.8-27.47,29.5-42.3,3.43-6.63,6.05-12.7,7.85-18.2Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_9_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 9 FILL">
		                <path className={styles.cls13} d="m758.09,353.46c1.37-4.77.32-9.7-3.15-14.8-4.73-5.6-8.15-9.83-10.25-12.7-.9-1.3-1.47-2.13-1.7-2.5-3.07-5.13-4.85-10.5-5.35-16.1-.4-4.17-.07-9.23,1-15.2,1.33-6.67,2.23-11.72,2.7-15.15.77-5.9.7-11.23-.2-16-2.33-12.33-4.77-21.7-7.3-28.1-4.6-11.67-10.7-18.98-18.3-21.95-10.53-4.1-19.93-5.38-28.2-3.85-7.37,1.33-13.42,4.77-18.15,10.3-4.33,5.07-7.12,11.28-8.35,18.65-1.23,7.13-.85,14.17,1.15,21.1,1.8,6.2,6.73,16.2,14.8,30,5.97,10.27,11.88,19.75,17.75,28.45.67.97,3.17,4.82,7.5,11.55,5.17,7.93,9.72,14.07,13.65,18.4,6.13,6.73,12.73,11.6,19.8,14.6,5.47,2.33,10.03,3.15,13.7,2.45,4.47-.87,7.43-3.92,8.9-9.15Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_10_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 10 FILL">
		                <path className={styles.cls11} d="m786.89,339.61c-4.47-3.7-9.83-5.78-16.1-6.25-6.27-.47-11.6.8-16,3.8-4.37,3.03-6.52,6.92-6.45,11.65.1,4.77,2.38,8.98,6.85,12.65,4.5,3.7,9.88,5.78,16.15,6.25,6.27.47,11.58-.8,15.95-3.8,4.37-3.03,6.52-6.92,6.45-11.65-.07-4.73-2.35-8.95-6.85-12.65Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_11_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 11 FILL">
		                <path className={styles.cls5} d="m700.04,189.01c-8.67.43-14.23,1.43-16.7,3-5.63,3.63-10.58,9.53-14.85,17.7-4.23,8.03-6.63,16.03-7.2,24,2.3,1,4.53,1.7,6.7,2.1,4.67.9,10.27.32,16.8-1.75,6.23-1.97,12.3-4.97,18.2-9,6.13-4.17,11.02-8.73,14.65-13.7,3.97-5.43,5.93-10.7,5.9-15.8,0-2.1-.37-4.33-1.1-6.7-9.4-.17-16.87-.12-22.4.15Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_12_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 12 FILL">
		                <path className={styles.cls5} d="m791.79,170.01c-2.17,2.07-3.53,3.32-4.1,3.75-1.8,1.37-4.3,2.77-7.5,4.2-3.63,1.57-6.28,2.7-7.95,3.4-3.27,1.43-5.7,2.47-7.3,3.1-2.83,1.03-5.38,1.75-7.65,2.15-5.07,1.1-8.65,1.85-10.75,2.25-3.57.67-7.02.9-10.35.7,1.77,6.47,5.1,12.2,10,17.2,4.9,5,10.57,8.42,17,10.25,11.57,3.3,18.53,5.55,20.9,6.75,4.7,2.33,10.18,7.07,16.45,14.2.8.9,1.87,2.87,3.2,5.9,1.2,2.73,2.53,4.73,4,6,2.13,1.83,4.78,2.9,7.95,3.2,2.9.3,5.43-.05,7.6-1.05,2.5-1.17,4.35-3.33,5.55-6.5.93-2.57,1.32-5.28,1.15-8.15-.17-2.93-1.13-7.77-2.9-14.5-1.57-5.9-2.22-10.9-1.95-15l.35-6.05c-6.67-3-12.23-6.57-16.7-10.7-5.2-4.83-8.57-10.17-10.1-16-.33-2-.6-3.48-.8-4.45-.33-1.73-.92-3.03-1.75-3.9-1.3.57-2.75,1.65-4.35,3.25Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_13_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 13 FILL">
		                <path
			                className={styles.cls16}
			                d="m817.34,147.36c-3.4.77-6.07,1.63-8,2.6-2.97,1.47-5.87,3.92-8.7,7.35-2.87,3.47-4.67,6.77-5.4,9.9-1.23,5.13-.95,11.33.85,18.6,1.5,5.97,4.1,11.73,7.8,17.3,3.33,5.07,7.42,9.77,12.25,14.1,8.4,7.57,15.55,13.02,21.45,16.35,8.6,4.83,17.23,7.13,25.9,6.9,5.7-.13,10.32-.7,13.85-1.7,4.7-1.37,8.8-3.73,12.3-7.1,8.9-8.63,11.75-18.27,8.55-28.9-2.07-6.87-7.57-15.62-16.5-26.25-8.3-9.93-16.43-17.53-24.4-22.8-11.23-7.47-21.82-10.13-31.75-8-3.53.67-6.27,1.22-8.2,1.65Z"
			                style={{ fill: `${teamColors[JERSEY_BG]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_14_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 14 FILL">
		                <path
			                className={styles.cls15}
			                d="m856.09,266.71c-5.67,3.03-11.6,2.22-17.8-2.45-6.5,4.27-12.7,9.05-18.6,14.35-8.27,7.43-12.67,13.25-13.2,17.45,5.47,3.37,10.62,5.88,15.45,7.55,7.5,2.53,14.68,3.3,21.55,2.3.77-.73,2.55-2.82,5.35-6.25,3.77-4.67,7.23-9.35,10.4-14.05,9.83-14.6,14.75-26.32,14.75-35.15-8.67,9.07-14.63,14.48-17.9,16.25Z"
			                style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_15_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 15 FILL">
		                <path
			                className={styles.cls14}
			                d="m841.09,303.81c1.43,0,2.92-.05,4.45-.15,1.8-2.17,2.68-3.25,2.65-3.25-7.77.77-14.83.32-21.2-1.35-6.37-1.7-12.52-4.75-18.45-9.15l-1.8,2.4c5.3,3.93,10.72,6.83,16.25,8.7,5.57,1.87,11.6,2.8,18.1,2.8Z"
			                style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_16_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 16 FILL">
		                <path
			                className={styles.cls14}
			                d="m844.34,297.26c1.9,0,4.1-.08,6.6-.25l2.5-3.25c-17.17,1.73-30.77-1.3-40.8-9.1l-1.85,2.35c8.83,6.83,20.02,10.25,33.55,10.25Z"
			                style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_17_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 17 FILL">
		                <path
			                className={styles.cls12}
			                d="m793.09,167.76c-6.87,5.97-15.12,10.73-24.75,14.3-9.57,3.5-20.08,5.68-31.55,6.55-2.67,19.67-2.85,38.45-.55,56.35,2.47,19,7.37,33.47,14.7,43.4,3.4.03,11.02.22,22.85.55,12.17.2,22.95.02,32.35-.55,28.6-1.73,48.2-7.15,58.8-16.25,3.67-3.13,6.47-7.92,8.4-14.35,2.07-6.9,2.4-13.55,1-19.95-.63.63-1.32,1.07-2.05,1.3-.67.1-1.08.17-1.25.2-1.3,4.23-3.63,8.83-7,13.8-3.7,5-6.17,8.47-7.4,10.4,1.23-6.67-.58-13.75-5.45-21.25-4.27-6.57-8.78-10.85-13.55-12.85-11.27-4.83-21.08-13.48-29.45-25.95-7.33-10.97-12.37-22.87-15.1-35.7Z"
			                style={{ fill: `${teamColors[JERSEY_BG]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_18_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 18 FILL">
		                <path
			                className={styles.cls15}
			                d="m845.19,262.36c1.47-5.03.73-9.28-2.2-12.75-2.63-3.1-6.67-5.28-12.1-6.55-5.13-1.17-10.53-1.37-16.2-.6-5.77.8-10.55,2.45-14.35,4.95-3.7,4.37-8.63,11.25-14.8,20.65-7.47,11.47-12.23,20.15-14.3,26.05,2.9,5.97,9.17,10.77,18.8,14.4,8.3,3.17,16.72,4.75,25.25,4.75,1.57-2.8,6.53-11.17,14.9-25.1,7.63-12.77,12.63-21.37,15-25.8Z"
			                style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_19_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 19 FILL">
		                <path
			                className={styles.cls14}
			                d="m757.14,185.41c-.6.23-2.03.57-4.3,1-2.43.47-3.67.7-3.7.7-3.37,17.4-4.2,34.8-2.5,52.2,1.7,17.4,5.83,33.82,12.4,49.25l8.85.25c-6.97-15.33-11.38-32.02-13.25-50.05-1.87-18-1.03-35.78,2.5-53.35Z"
			                style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_20_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 20 FILL">
		                <path
			                className={styles.cls14}
			                d="m773.89,287.96c-.1.13-.35.63-.75,1.5-.4.87-.62,1.37-.65,1.5,4.1,4.83,9.18,8.83,15.25,12,8.5,4.37,18.32,6.7,29.45,7l1.75-2.95c-7.1-.1-13.93-1.13-20.5-3.1-10.6-3.2-18.78-8.52-24.55-15.95Z"
			                style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_21_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 21 FILL">
		                <path
			                className={styles.cls14}
			                d="m777.59,281.01c-1,1.67-1.52,2.62-1.55,2.85,3.9,4.93,8.83,9.05,14.8,12.35,8.53,4.73,18.5,7.3,29.9,7.7l1.8-2.95c-7.37-.17-14.37-1.33-21-3.5-10.4-3.37-18.38-8.85-23.95-16.45Z"
			                style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_22_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 22 FILL">
		                <path
			                className={styles.cls14}
			                d="m811.89,202.96c-9.53-12.47-14.98-24.95-16.35-37.45-3.13,2.77-4.9,4.28-5.3,4.55,2.23,12.9,8.23,25.55,18,37.95,9.77,12.4,21.48,22.2,35.15,29.4,2.77,2.63,5.5,6.85,8.2,12.65,2.4,5.2,3.65,8.88,3.75,11.05l.2,6.05,4.65-3.8c2.73-2.2,5.45-5.62,8.15-10.25,2.93-5.07,4.97-10.18,6.1-15.35-.9.33-1.77.18-2.6-.45-.9-.77-1.47-1.13-1.7-1.1-.93,2.9-2.52,6.32-4.75,10.25-1.9,3.37-3.7,6.18-5.4,8.45-1.3-3.93-3.1-7.95-5.4-12.05-2.53-4.53-5.02-7.92-7.45-10.15l-.3-.25-.35-.15c-13.57-7.07-25.1-16.85-34.6-29.35Z"
			                style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_23_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 23 FILL">
		                <path className={styles.cls5} d="m987.29,97.31c.27-5.63.08-9.78-.55-12.45-3.7-15.3-12.5-24.88-26.4-28.75-11.6-3.2-27.62-2.53-48.05,2-5.77,1.3-11,3.57-15.7,6.8-5.77,3.9-8.7,8.17-8.8,12.8-.1,3.03.95,6.18,3.15,9.45,2.07,3.07,4.78,5.73,8.15,8,.87.6,4.57,3.37,11.1,8.3,7.6,5.67,14.52,10.58,20.75,14.75,20.17,13.47,34.15,19.88,41.95,19.25,5.33-.43,9.45-8.02,12.35-22.75,1.1-5.73,1.78-11.53,2.05-17.4Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_24_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 24 FILL">
		                <path className={styles.cls11} d="m924.49,145.46c-3.77-11.97-6.68-20.93-8.75-26.9-3.67-10.53-7.78-18.92-12.35-25.15-2.17-2.97-5.08-6.32-8.75-10.05-4.27-4.17-7.43-7.33-9.5-9.5-1.07-1.07-1.98-2.15-2.75-3.25-12.47-.6-23.92.23-34.35,2.5,3.33,2.67,7.28,6.47,11.85,11.4,14.6,15.97,27.65,32.77,39.15,50.4,4.7,7.2,7.97,13.53,9.8,19,2.47,7.5,2.65,14.23.55,20.2-1.33,3.83-4.08,8.1-8.25,12.8-6.4,7.2-9.82,11.1-10.25,11.7-2.93,4.03-5.17,8.47-6.7,13.3-1.5,4.87-2.18,9.8-2.05,14.8.03,1.7.18,4.32.45,7.85.17,3.2.12,5.78-.15,7.75,7.97,2.53,15.82,4.07,23.55,4.6,11.87.77,21.2.73,28-.1,10.13-1.27,20.07-4.47,29.8-9.6,6.33-3.33,11.78-9.15,16.35-17.45,4.5-8.2,6.95-16.62,7.35-25.25.27-5.8-2.28-12.78-7.65-20.95-4.83-2.77-9.83-4.42-15-4.95-5.73-.17-10.02-.3-12.85-.4-5.13-.2-9.23-1.03-12.3-2.5-6.33-3.07-11.4-9.82-15.2-20.25Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_25_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 25 FILL">
		                <path className={styles.cls10} d="m859.89,84.51c-4.57-4.93-8.52-8.73-11.85-11.4-14.27,3.03-26.8,8.75-37.6,17.15-7,5.43-12.02,11.02-15.05,16.75-3.4,6.4-4.82,13.72-4.25,21.95.67,9.7,2.3,19.53,4.9,29.5,1.23,4.63,4.03,10.6,8.4,17.9,5.03,8.33,11.02,16.43,17.95,24.3,18.27,20.73,38.28,34.62,60.05,41.65.27-1.97.32-4.55.15-7.75-.27-3.53-.42-6.15-.45-7.85-.13-5,.55-9.93,2.05-14.8,1.53-4.83,3.77-9.27,6.7-13.3.43-.6,3.85-4.5,10.25-11.7,4.17-4.7,6.92-8.97,8.25-12.8,2.1-5.97,1.92-12.7-.55-20.2-1.83-5.47-5.1-11.8-9.8-19-11.5-17.63-24.55-34.43-39.15-50.4Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_26_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 26 FILL">
		                <path className={styles.cls10} d="m946.79,110.41c-2.03-7-4.48-12.42-7.35-16.25-6.2-8.27-13.98-14.2-23.35-17.8-7.07-2.73-16.62-4.55-28.65-5.45-1.7-.17-3.38-.27-5.05-.3.77,1.1,1.68,2.18,2.75,3.25,2.07,2.17,5.23,5.33,9.5,9.5,3.67,3.73,6.58,7.08,8.75,10.05,4.57,6.23,8.68,14.62,12.35,25.15,2.07,5.97,4.98,14.93,8.75,26.9,3.8,10.43,8.87,17.18,15.2,20.25,3.07,1.47,7.17,2.3,12.3,2.5,2.83.1,7.12.23,12.85.4,5.17.53,10.17,2.18,15,4.95-1.73-2.63-3.98-5.73-6.75-9.3-3-3.8-5.12-6.52-6.35-8.15-5.1-6.77-8.3-11.22-9.6-13.35-2.53-4.23-4.53-9.17-6-14.8-1.73-7.7-3.18-13.55-4.35-17.55Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_27_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 27 FILL">
		                <path className={styles.cls6} d="m889.54,147.16c-1.27-5.27-3.4-9.52-6.4-12.75-3-3.23-5.98-4.48-8.95-3.75s-5.05,3.22-6.25,7.45c-1.17,4.27-1.12,9.05.15,14.35,1.3,5.3,3.45,9.55,6.45,12.75,3,3.23,5.98,4.48,8.95,3.75,2.97-.73,5.03-3.22,6.2-7.45,1.2-4.27,1.15-9.05-.15-14.35Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_28_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 28 FILL">
		                <path className={styles.cls6} d="m930.59,130.36c-.4,4.07.32,8.55,2.15,13.45,1.8,4.9,4.18,8.78,7.15,11.65,2.93,2.83,5.58,3.82,7.95,2.95,2.37-.9,3.75-3.4,4.15-7.5.37-4.07-.35-8.57-2.15-13.5-1.8-4.9-4.18-8.77-7.15-11.6-2.93-2.83-5.58-3.82-7.95-2.95-2.37.9-3.75,3.4-4.15,7.5Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_29_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 29 FILL">
		                <path className={styles.cls6} d="m943.49,186.41c-2.87,2.77-4.33,5.62-4.4,8.55-.07,2.6.97,5.02,3.1,7.25,2.03,2.1,4.7,3.72,8,4.85,3.37,1.13,6.77,1.55,10.2,1.25h.05v-.05c3.33-.87,6.4-2.4,9.2-4.6,2.77-2.17,4.77-4.58,6-7.25,1.3-2.83,1.5-5.47.6-7.9-1-2.7-3.32-4.88-6.95-6.55-1.57-.7-3.55-1.1-5.95-1.2-2.33-.1-4.8.08-7.4.55h-.05c-2.6.4-4.98,1.05-7.15,1.95-2.23.9-3.98,1.95-5.25,3.15Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_30_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 30 FILL">
		                <path className={styles.cls6} d="m970.59,220.76c-.6.43-1.05.73-1.35.9-1.37.83-2.73,1.42-4.1,1.75-2.13.53-4.22.62-6.25.25-1.73-.3-3.67-.98-5.8-2.05v-.2l-.2.1-.15-.05v.2c-2,1.27-3.83,2.13-5.5,2.6-2,.57-4.1.68-6.3.35-1.77-.23-3.85-.98-6.25-2.25-2.63-1.4-4.4-2.8-5.3-4.2-.33-.53-.82-.87-1.45-1-.6-.13-1.15-.02-1.65.35-.53.33-.85.82-.95,1.45-.13.6-.03,1.18.3,1.75,1.37,2.07,3.73,3.98,7.1,5.75,2.83,1.47,5.35,2.37,7.55,2.7,1.6.23,2.93.3,4,.2,2.93-.13,5.9-1.03,8.9-2.7,3.2,1.37,6.22,1.98,9.05,1.85,1.4-.1,2.72-.32,3.95-.65,3-.77,5.12-1.73,6.35-2.9.53-.53.85-1.18.95-1.95.13-.83-.03-1.48-.5-1.95-.23-.23-.52-.4-.85-.5-.5-.17-1.02-.1-1.55.2Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_31_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 31 FILL">
		                <path className={styles.cls6} d="m961.54,205.91c.13-.67,0-1.28-.4-1.85-.4-.57-.93-.9-1.6-1-.63-.13-1.23.02-1.8.45-.53.4-.85.93-.95,1.6-.8,5-2.05,9.38-3.75,13.15-.87,1.9-1.68,3.33-2.45,4.3-.43.5-.62,1.1-.55,1.8.07.67.35,1.22.85,1.65.5.47,1.12.67,1.85.6.63-.07,1.15-.35,1.55-.85.77-.77,1.75-2.42,2.95-4.95,2-4.27,3.43-9.23,4.3-14.9Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_32_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 32 FILL">
		                <path className={styles.cls11} d="m973.99,190.11c.33-1.23-.17-2.5-1.5-3.8-1.33-1.3-3.1-2.25-5.3-2.85-2.23-.57-4.23-.62-6-.15-1.8.5-2.87,1.38-3.2,2.65-.33,1.23.17,2.5,1.5,3.8s3.1,2.23,5.3,2.8c2.2.57,4.2.62,6,.15,1.8-.47,2.87-1.33,3.2-2.6Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_33_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 33 FILL">
		                <path className={styles.cls11} d="m945.54,136.36c.67-.5.9-1.33.7-2.5-.17-1.2-.72-2.38-1.65-3.55-.9-1.2-1.9-2.03-3-2.5-1.07-.5-1.93-.5-2.6,0-.63.5-.87,1.33-.7,2.5.17,1.2.7,2.38,1.6,3.55.93,1.2,1.95,2.05,3.05,2.55,1.1.47,1.97.45,2.6-.05Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_34_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 34 FILL">
		                <path className={styles.cls11} d="m884.59,145.81c.77-.73.98-1.83.65-3.3-.37-1.5-1.2-2.97-2.5-4.4-1.27-1.4-2.63-2.33-4.1-2.8-1.47-.5-2.58-.4-3.35.3-.8.73-1.02,1.85-.65,3.35.37,1.47,1.18,2.92,2.45,4.35,1.27,1.4,2.63,2.35,4.1,2.85,1.47.47,2.6.35,3.4-.35Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_35_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 35 FILL">
		                <path
			                className={styles.cls12}
			                d="m943.84,86.91c-6.17-8.57-15.02-15.98-26.55-22.25-10.97-6-25.87-8.48-44.7-7.45-16.23.87-28.98,3.53-38.25,8l-2.85,1.45c2.23-.6,4.47-.75,6.7-.45,2.27.3,4.38,1.05,6.35,2.25,3.33,2.03,5.15,5.38,5.45,10.05.3,4.23-.65,8.72-2.85,13.45-2.2,4.7-5.02,8.4-8.45,11.1-3.77,2.93-7.5,3.93-11.2,3-3.27-.83-5.97-3.08-8.1-6.75-2.5-4.2-3.35-9.15-2.55-14.85.63-4.53,2.3-8.43,5-11.7-12.97,9.7-22.48,22.5-28.55,38.4-6.4,16.77-7.82,34.23-4.25,52.4,3.23,16.6,9.6,29.38,19.1,38.35,3.87,3.67,7.9,6.27,12.1,7.8,3.9,1.47,7.38,1.82,10.45,1.05,2.7-.67,4.67-3.03,5.9-7.1.93-3.1,1.6-8,2-14.7.37-8.2.72-14.53,1.05-19,.6-7.83,1.58-14.47,2.95-19.9,1.47-7.7,6.4-14.55,14.8-20.55,7.6-5.4,17.42-9.68,29.45-12.85,11.37-2.97,23.25-4.6,35.65-4.9,12.43-.27,23.5.88,33.2,3.45-2.37-11.23-6.32-20.67-11.85-28.3Z"
			                style={{ fill: `${teamColors[HELMET_BG]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_36_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 36 FILL">
		                <path
			                className={styles.cls9}
			                d="m844.14,65.61c-2.03-1.07-4.23-1.57-6.6-1.5-2.37.07-4.75.68-7.15,1.85-4.8,2.33-8.6,6.18-11.4,11.55-2.77,5.33-3.73,10.65-2.9,15.95.87,5.47,3.38,9.28,7.55,11.45,1.97,1,4.07,1.5,6.3,1.5,2.4,0,4.88-.62,7.45-1.85,4.8-2.33,8.6-6.18,11.4-11.55,2.8-5.4,3.77-10.72,2.9-15.95-.4-2.63-1.25-4.93-2.55-6.9-1.3-1.97-2.97-3.48-5-4.55m-1.4,2.65c3.27,1.7,5.27,4.78,6,9.25.73,4.67-.13,9.37-2.6,14.1-2.43,4.73-5.78,8.15-10.05,10.25-4.07,1.97-7.75,2.1-11.05.4-3.27-1.7-5.27-4.78-6-9.25-.73-4.63.15-9.33,2.65-14.1,2.47-4.77,5.8-8.18,10-10.25,2.17-1.03,4.22-1.55,6.15-1.55,1.83,0,3.47.38,4.9,1.15Z"
			                style={{ fill: `${teamColors[HELMET_HOLE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_37_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 37 FILL">
		                <path
			                className={styles.cls15}
			                d="m873.64,56.36c-4.13.3-7.93.82-11.4,1.55,7.6,4.7,14.72,12.18,21.35,22.45,7.07,10.87,11.98,22.52,14.75,34.95l15.4-.1c-1.03-14.77-3.2-26.43-6.5-35-4.37-11.37-11.15-19.1-20.35-23.2-3.47-.77-7.88-.98-13.25-.65Z"
			                style={{ fill: `${teamColors[HELMET_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_38_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 38 FILL">
		                <path className={styles.cls8} d="m961.99,114.46c-.8-1.17-1.87-1.88-3.2-2.15-21.17-4.2-42.55-4.37-64.15-.5-18.37,3.3-31.93,8.25-40.7,14.85-6.67,5.03-12.43,17.53-17.3,37.5l-.6-.4c-.87-.6-1.82-.87-2.85-.8-1.07.07-1.98.45-2.75,1.15-5.37,4.87-7.2,12.98-5.5,24.35.17.87.52,1.62,1.05,2.25,11.83,13.83,23.38,25.5,34.65,35,11.3,9.5,21.05,15.75,29.25,18.75,6.27,2.3,15.4,4.02,27.4,5.15,8.4.77,16.02,1.13,22.85,1.1,9.57-.03,18.22-1.23,25.95-3.6,7.73-2.33,14.03-5.67,18.9-10,.93-.83,1.43-1.87,1.5-3.1.1-1.23-.27-2.32-1.1-3.25-.9-1-2.02-1.5-3.35-1.5,2.03-14.97,5.5-28.68,10.4-41.15l.2.15c1.07.63,2.2.8,3.4.5,1.23-.33,2.17-1.03,2.8-2.1.63-1.07.8-2.2.5-3.4-.33-1.2-1.03-2.12-2.1-2.75-13.67-8.1-26.88-11.38-39.65-9.85-12.73,1.53-24.9,7.87-36.5,19-13.77,2.67-27.98,1.6-42.65-3.2-10.27-3.33-21.23-8.77-32.9-16.3,2.6-11.43,5.38-20.32,8.35-26.65,2.07-4.4,4.1-7.35,6.1-8.85,7.97-6,20.68-10.43,38.15-13.3,20.03-3.3,39.6-3.05,58.7.75,1.37.27,2.62.02,3.75-.75,1.17-.73,1.88-1.78,2.15-3.15.27-1.37.02-2.62-.75-3.75m-37.8,83.75l.75-.25c7.27,13.63,10.7,28.2,10.3,43.7-6.57-.17-13.2-.6-19.9-1.3-8.33-.9-15-2.08-20-3.55l3.95-36.95c8.47.63,16.77.08,24.9-1.65m33.15-18.35c9.23-1.43,18.87.22,28.9,4.95-5.83,14.47-9.75,30.77-11.75,48.9-8.07,4.93-18.82,7.58-32.25,7.95.4-17.23-3.55-33.33-11.85-48.3,8.73-7.6,17.72-12.1,26.95-13.5m-122.8-6.2c9.13,6.2,17.4,11.12,24.8,14.75,11.23,5.5,22.23,9.05,33,10.65l-3.75,35.05c-7.27-3.57-15.73-9.58-25.4-18.05-9.73-8.53-19.57-18.6-29.5-30.2-.67-5.27-.38-9.33.85-12.2Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_1_0_Layer2_39_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  1 0 Layer2 39 FILL">
		                <path className={styles.cls5} d="m847.89,73.91c-.9-4.87-4.53-8.87-10.9-12-5.2-2.57-10.78-4.02-16.75-4.35-21.1-1.17-37.13.98-48.1,6.45-13.17,6.53-20.32,18.23-21.45,35.1-.2,2.93.32,7.37,1.55,13.3,1.23,6.13,2.88,12.13,4.95,18,5.33,15.1,10.7,22.4,16.1,21.9,7.83-.73,20.65-10.03,38.45-27.9,9.1-9.1,18.37-19.1,27.8-30,2.97-3,5.23-6.32,6.8-9.95,1.6-3.83,2.12-7.35,1.55-10.55Z"/>
		            </g>
		        </g>
		    </g>
		    <g>
		        <g>
		            <g>
		                <g id="FlashAICB_Assets_Layer_1_Clip_Group_3_0_Layer2_0_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  3 0 Layer2 0 FILL">
		                    <path className={styles.cls5} d="m400.76,363.43c0-5.07-13.75-9.4-41.25-13-27.53-3.57-60.75-5.35-99.65-5.35s-70.72,1.78-96.55,5.35c-25.5,3.53-38.25,7.87-38.25,13s12.75,9.47,38.25,13c25.83,3.57,58.02,5.35,96.55,5.35s72.12-1.78,99.65-5.35c27.5-3.6,41.25-7.93,41.25-13Z"/>
		                </g>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_1_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 1 FILL">
		                <path className={styles.cls7} d="m416.41,292.08c2.27.73,5.13.75,8.6.05,3.43-.7,6.77-1.93,10-3.7,7.87-4.37,11.73-9.65,11.6-15.85-.07-3.6-2.03-5.43-5.9-5.5-4.73.57-7.98.72-9.75.45-1.83-.3-3.22-.57-4.15-.8-1.5-.4-2.85-.92-4.05-1.55-1.57-.83-3.47-2.08-5.7-3.75-2.5-1.87-4.35-3.23-5.55-4.1-12.9-9-22.82-16.3-29.75-21.9-3.43-2.77-7.53-6.58-12.3-11.45-7.27-7.37-11.22-11.37-11.85-12-9.6-9.23-18.87-15.02-27.8-17.35-6.87-1.8-12.68-.55-17.45,3.75-4.63,4.23-6.92,10.07-6.85,17.5.1,5.6,2.8,11,8.1,16.2,2.77,2.67,6.33,5.33,10.7,8,2.57,1.6,6.12,3.57,10.65,5.9,2.73,1.4,15.45,9,38.15,22.8,22.8,13.57,37.23,21.33,43.3,23.3Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_2_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 2 FILL">
		                <path className={styles.cls7} d="m415.71,265.13c-4.8,4.17-7.67,8.92-8.6,14.25-.93,5.3.33,9.62,3.8,12.95,3.5,3.33,8.18,4.68,14.05,4.05,5.87-.6,11.2-3,16-7.2,4.8-4.17,7.67-8.92,8.6-14.25.93-5.3-.35-9.62-3.85-12.95-3.47-3.33-8.13-4.68-14-4.05-5.87.6-11.2,3-16,7.2Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_3_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 3 FILL">
		                <path className={styles.cls5} d="m150.01,163.98c-1.93,2.67-3.02,5.35-3.25,8.05l-.05.5c3.97,5.93,8.17,11.08,12.6,15.45,4.83,4.77,9.53,8.15,14.1,10.15,2.93,1.23,5.67,1.62,8.2,1.15,3.37-.67,5-2.97,4.9-6.9-.1-2.83-1.6-6.32-4.5-10.45-2.43-3.47-4.77-6.05-7-7.75-.6-.47-3.3-2.22-8.1-5.25-2.87-1.8-5.23-3.95-7.1-6.45l-3-4.2c-2.57,1.03-4.83,2.93-6.8,5.7Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_4_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 4 FILL">
		                <path className={styles.cls13} d="m136.91,138.63c-1.47.83-2.3,2.37-2.5,4.6-.13,1.87.13,3.9.8,6.1l.35.95c1.37,4.27,3.38,8.85,6.05,13.75,1.6,2.93,3.3,5.77,5.1,8.5l.05-.5c.23-2.7,1.32-5.38,3.25-8.05,1.97-2.77,4.23-4.67,6.8-5.7-4.17-5.83-7.3-10.17-9.4-13l-1.65-2.25c-3.4-4.33-6.35-5.8-8.85-4.4Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_5_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 5 FILL">
		                <path className={styles.cls7} d="m158.91,305.63c.7-3.2,1.8-5.3,3.3-6.3,2.37-1.6,6.88-4.13,13.55-7.6,6.7-3.47,11.18-5.58,13.45-6.35,4.13-1.4,8.33-2.43,12.6-3.1,2.7-.4,7.37-.95,14-1.65,10.37-1.27,18.48-5.17,24.35-11.7,6.3-7,9.45-16.5,9.45-28.5-.03-15.63-3.03-25.97-9-31-5.47-4.6-14.18-5.27-26.15-2-6.83,1.87-12.62,5.3-17.35,10.3-1.67,1.77-6.02,7.58-13.05,17.45-3.37,4.7-10.22,12.55-20.55,23.55-8.93,9.5-15.47,17.83-19.6,25-3.33,5.8-5.65,10.17-6.95,13.1-2.6,5.93-3.63,10.78-3.1,14.55.97,6.83,4.42,10.03,10.35,9.6,2.4-.17,4.75-.97,7.05-2.4,2.3-1.43,4.03-3.13,5.2-5.1.7-1.2,1.52-3.82,2.45-7.85Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_6_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 6 FILL">
		                <path className={styles.cls13} d="m284.31,155.28c-.33-2-.6-3.48-.8-4.45-.33-1.73-.92-3.03-1.75-3.9-1.3.57-2.75,1.65-4.35,3.25-2.17,2.07-3.53,3.32-4.1,3.75-1.8,1.37-4.28,2.77-7.45,4.2-3.67,1.57-6.33,2.7-8,3.4-3.27,1.43-5.7,2.47-7.3,3.1-2.83,1.03-5.38,1.75-7.65,2.15-5.43,1.2-9.28,2-11.55,2.4-3.8.7-7.7.83-11.7.4-5.33-.6-13.37-.77-24.1-.5-13.93.3-22.6,1.55-26,3.75-7.6,4.87-13.67,12.88-18.2,24.05-4.67,11.53-5.58,21.52-2.75,29.95,3.33,9.97,8.42,17.93,15.25,23.9,6.93,6.03,15.25,9.65,24.95,10.85,3.9.47,9.78-.02,17.65-1.45,8.43-1.53,14.33-2.23,17.7-2.1,4.57.17,8.95,1.15,13.15,2.95,6.07,2.6,10.3,4.22,12.7,4.85,17.57,4.7,31.23,7.3,41,7.8,15.53.8,28.92-2.25,40.15-9.15,9.67-5.97,16.95-13.43,21.85-22.4,4.63-8.5,6.85-17.75,6.65-27.75,1.97-7.37,3.13-13.67,3.5-18.9.23-2.93.55-5.38.95-7.35-.19.83-5.79,1.22-16.8,1.15-8.27-.07-15.93-.97-23-2.7-9.17-2.27-17.23-5.82-24.2-10.65-8.43-5.9-13.7-12.77-15.8-20.6Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_7_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 7 FILL">
		                <path className={styles.cls7} d="m219.01,235.98c-5.83-.67-10.55-.7-14.15-.1-4.8.77-9.05,2.72-12.75,5.85-3.03,2.57-5.03,5.6-6,9.1-.87,3.17-.87,6.62,0,10.35l2.65.4c3.93.47,9.83-.02,17.7-1.45,8.47-1.53,14.37-2.23,17.7-2.1,4.57.17,8.95,1.15,13.15,2.95,6.07,2.6,10.3,4.22,12.7,4.85,17.57,4.7,31.23,7.3,41,7.8,15.53.8,28.92-2.25,40.15-9.15,11.8-7.27,20-16.7,24.6-28.3-1.73.4-3.1.7-4.1.9-1.83.4-3.43.52-4.8.35-1.97-.23-4.95-1.23-8.95-3-3.73-1.63-7-2.5-9.8-2.6-3.73-.13-7.2.1-10.4.7-2.73.5-6.02,1.42-9.85,2.75-8.27,2.8-15.65,5.02-22.15,6.65-8.2,2.03-15.8,3.35-22.8,3.95-5.77.5-13.13-.77-22.1-3.8-10.73-3.63-18-5.67-21.8-6.1Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_8_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 8 FILL">
		                <path className={styles.cls11} d="m305.56,204.08c-4.6-2.83-8.9-3.6-12.9-2.3-3.6,1.17-6.55,3.87-8.85,8.1-2.23,4.1-3.47,8.85-3.7,14.25-.2,5.67.73,11.02,2.8,16.05,4.47,10.83,11.88,21.57,22.25,32.2,1.7,1.73,6.18,6.08,13.45,13.05,5.57,5.33,9.97,9.85,13.2,13.55,9.77,11.07,14.77,16.7,15,16.9,6.13,6.13,11.95,9.93,17.45,11.4,1.87.5,4.33.62,7.4.35,3.33-.33,6.42-1.07,9.25-2.2,7.47-3.03,10.63-7.73,9.5-14.1-.37-2.13-1.68-3.43-3.95-3.9-2.6-.23-4.37-.6-5.3-1.1-3.5-1.93-10.12-9.8-19.85-23.6-8.13-11.53-14.1-20.7-17.9-27.5-9-16.07-14.52-25.63-16.55-28.7-6.9-10.43-14-17.92-21.3-22.45Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_9_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 9 FILL">
		                <path className={styles.cls11} d="m393.26,297.93c-3.23-3.57-7.8-5.25-13.7-5.05-5.9.17-11.4,2.17-16.5,6-5.07,3.83-8.27,8.37-9.6,13.6-1.33,5.23-.38,9.62,2.85,13.15,3.27,3.57,7.83,5.27,13.7,5.1,5.9-.17,11.4-2.17,16.5-6,5.1-3.83,8.3-8.37,9.6-13.6,1.33-5.23.38-9.63-2.85-13.2Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_10_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 10 FILL">
		                <path className={styles.cls13} d="m211.61,194.13c-8.17-1.1-21.58.57-40.25,5-8.03,1.93-14.82,5.47-20.35,10.6-1.97,1.83-7.05,7.8-15.25,17.9-6.27,7.7-13.83,16.13-22.7,25.3-5.23,5.4-13.37,13.58-24.4,24.55-3.1,3.13-5.9,7.95-8.4,14.45-2.67,6.9-3.65,12.5-2.95,16.8,1.13,6.93,4.63,9.72,10.5,8.35,5.17-1.2,9.4-4.27,12.7-9.2,1.87-2.8,3.4-4.85,4.6-6.15,1.6-1.77,3.48-3.28,5.65-4.55,6.13-3.83,11.33-7.05,15.6-9.65,8.1-4.93,13.25-7.73,15.45-8.4,2.57-.77,9.25-3.95,20.05-9.55,9.43-4.87,16.67-7.58,21.7-8.15,12.83-1.37,23.77-5.58,32.8-12.65,10.27-8,15.4-17.97,15.4-29.9-.03-14.7-6.75-22.95-20.15-24.75Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_11_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 11 FILL">
		                <path className={styles.cls11} d="m108.16,300.08c1.77-6.1,1.7-11.55-.2-16.35-1.9-4.8-5.08-7.55-9.55-8.25-4.5-.67-8.97.9-13.4,4.7-4.43,3.83-7.53,8.78-9.3,14.85-1.77,6.1-1.68,11.55.25,16.35,1.9,4.8,5.08,7.55,9.55,8.25,4.47.7,8.92-.87,13.35-4.7,4.43-3.83,7.53-8.78,9.3-14.85Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_12_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 12 FILL">
		                <path className={styles.cls5} d="m284.31,155.28c-.33-2-.6-3.48-.8-4.45-.33-1.73-.92-3.03-1.75-3.9-1.3.57-2.75,1.65-4.35,3.25-2.17,2.07-3.53,3.32-4.1,3.75-1.8,1.37-4.28,2.77-7.45,4.2-3.67,1.57-6.33,2.7-8,3.4-3.27,1.43-5.7,2.47-7.3,3.1-2.83,1.03-5.38,1.75-7.65,2.15-5.07,1.1-8.65,1.85-10.75,2.25-3.53.67-6.97.9-10.3.7,1.77,6.47,5.1,12.2,10,17.2,4.9,5,10.57,8.42,17,10.25,10.53,3,17.72,5.58,21.55,7.75,4.9,2.77,10.6,7.83,17.1,15.2,1.93,2.2,3.43,3.82,4.5,4.85,1.8,1.7,3.55,3.03,5.25,4,2.07,1.13,4.17,1.78,6.3,1.95,2.4.17,4.5-.27,6.3-1.3,1.97-1.1,3.47-2.82,4.5-5.15.97-2.17,1.38-4.48,1.25-6.95-.17-2.93-1.13-7.77-2.9-14.5-1.57-5.9-2.22-10.9-1.95-15l.35-6.05c-6.63-2.97-12.18-6.53-16.65-10.7-5.2-4.8-8.58-10.13-10.15-16Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_13_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 13 FILL">
		                <path
			                className={styles.cls16}
			                d="m294.26,124.28c-3.23,1.47-5.7,2.87-7.4,4.2-2.67,2.07-5.05,5.1-7.15,9.1-2.13,4.03-3.25,7.67-3.35,10.9-.2,5.37,1.3,11.45,4.5,18.25,2.67,5.6,6.38,10.78,11.15,15.55,4.27,4.33,9.23,8.17,14.9,11.5,9.77,5.77,17.92,9.7,24.45,11.8,9.43,3.03,18.42,3.55,26.95,1.55,5.63-1.33,10.07-2.85,13.3-4.55,4.4-2.27,7.98-5.43,10.75-9.5,7.1-10.37,8.02-20.5,2.75-30.4-3.4-6.4-10.57-13.95-21.5-22.65-10.17-8.13-19.7-14-28.6-17.6-12.57-5.1-23.57-5.58-33-1.45-3.37,1.4-5.95,2.5-7.75,3.3Z"
			                style={{ fill: `${teamColors[JERSEY_BG]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_14_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 14 FILL">
		                <path className={styles.cls5} d="m185.71,169.18c-8.7.43-14.27,1.43-16.7,3-5.63,3.6-10.55,9.52-14.75,17.75-4.07,8-6.4,16.02-7,24.05,2.2.97,4.32,1.63,6.35,2,4.7.9,10.3.32,16.8-1.75,6.27-1.97,12.35-4.97,18.25-9,6.1-4.17,10.97-8.73,14.6-13.7,3.97-5.43,5.93-10.7,5.9-15.8,0-2.1-.37-4.33-1.1-6.7-9.4-.17-16.85-.12-22.35.15Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_15_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 15 FILL">
		                <path
			                className={styles.cls15}
			                d="m366.01,247.58c6.8-6.17,10.57-12.52,11.3-19.05-.17-.7-1.83-2.57-5-5.6-7.27-6.93-11.27-10.77-12-11.5-1.73,14.7-5.27,22.6-10.6,23.7-2.03.43-4.57-.05-7.6-1.45-3.57-1.9-6.23-3.32-8-4.25.33,4.87,2.08,10.02,5.25,15.45,3.07,5.23,6.62,9.32,10.65,12.25,4.87-1.13,10.2-4.32,16-9.55Z"
			                style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_16_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 16 FILL">
		                <path
			                className={styles.cls14}
			                d="m365.06,242.83c4.73-4.9,8.1-10.58,10.1-17.05-.13-.17-.53-.58-1.2-1.25-.63-.6-1.03-.98-1.2-1.15-1.73,6.47-4.87,12.1-9.4,16.9-4.57,4.8-10.72,9.02-18.45,12.65l1.25,2.7c7.87-3.67,14.17-7.93,18.9-12.8Z"
			                style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_17_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 17 FILL">
		                <path
			                className={styles.cls14}
			                d="m360.01,238.23c4.77-4.97,8.13-10.75,10.1-17.35l-2.35-2.25c-1.7,6.57-4.83,12.27-9.4,17.1-4.53,4.8-10.77,9.05-18.7,12.75l1.25,2.75c7.97-3.73,14.33-8.07,19.1-13Z"
			                style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_18_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 18 FILL">
		                <path
			                className={styles.cls12}
			                d="m276.56,149.13c-5.53,6.07-13.13,10.72-22.8,13.95-9.1,3.07-19.43,4.68-31,4.85-2.57,18.83-1.98,38.82,1.75,59.95,3.7,21.07,9.35,36.75,16.95,47.05,1.07,0,7.97.33,20.7,1,10.77.53,19.97.67,27.6.4,24.07-.83,41.4-5.73,52-14.7,5.8-4.93,10.93-13.75,15.4-26.45,4.67-13.23,5.6-22.8,2.8-28.7-.8,4.77-1.9,9.07-3.3,12.9-1.43,3.87-3.28,7.58-5.55,11.15,0-7.07-.97-12.58-2.9-16.55-2.17-4.43-5.73-7.5-10.7-9.2-12.93-4.4-25.52-12.02-37.75-22.85-12.9-11.4-20.63-22.33-23.2-32.8Z"
			                style={{ fill: `${teamColors[JERSEY_BG]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_19_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 19 FILL">
		                <path
			                className={styles.cls15}
			                d="m341.86,247.58c-3.97-8.13-7.22-14.23-9.75-18.3-4.13-4.83-9.35-7.77-15.65-8.8-5.87-1-11.63-.22-17.3,2.35-5.77,2.6-10.17,6.47-13.2,11.6-3.33,5.67-4.42,11.97-3.25,18.9,1.37,3.83,4.55,9.58,9.55,17.25,4.93,7.53,9.72,14.02,14.35,19.45,7.03.53,15.15-1.78,24.35-6.95,9-5.07,15.72-10.95,20.15-17.65-1.9-3.23-4.98-9.18-9.25-17.85Z"
			                style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_20_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 20 FILL">
		                <path
			                className={styles.cls14}
			                d="m252.11,275.38l8.85.45c-8.23-18-13.92-36.63-17.05-55.9-3.07-18.97-3.4-36.97-1-54l-8.15,1.25c-2.2,17.17-1.87,34.92,1,53.25,2.93,18.77,8.38,37.08,16.35,54.95Z"
		                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_21_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 21 FILL">
		                <path
			                className={styles.cls14}
			                d="m276.51,149.13l-4.5,4.15c5.47,14.53,13.97,26.42,25.5,35.65,10.83,8.67,24.73,15.3,41.7,19.9,3.67,3.07,6.18,7.05,7.55,11.95.8,2.77,1.23,6.22,1.3,10.35l.25,6.75,4.65-3.75c3.27-2.63,5.85-7.2,7.75-13.7,1.63-5.57,2.45-11.18,2.45-16.85-.77-.8-1.57-1.35-2.4-1.65s-1.85-.38-3.05-.25c.13,2.63-.25,6.32-1.15,11.05-.93,4.67-1.97,8.23-3.1,10.7-1.3-8.33-4.97-14.88-11-19.65l-.5-.4-.6-.15c-31.53-8.37-53.15-26.4-64.85-54.1Z"
			                style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_22_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 22 FILL">
		                <path
			                className={styles.cls14}
			                d="m328.41,280.13c7.4-4.17,14.33-10.17,20.8-18l-1.5-2.9c-6.63,8.23-13.67,14.4-21.1,18.5-7.43,4.13-15.62,6.37-24.55,6.7l2.25,2.85c8.63-.63,16.67-3.02,24.1-7.15Z"
			                style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_23_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 23 FILL">
		                <path
			                className={styles.cls14}
			                d="m346.16,256.28c-.8-1.6-1.28-2.58-1.45-2.95-13.27,16.4-28.85,24.93-46.75,25.6l2.15,2.9c17.6-1.2,32.95-9.72,46.05-25.55Z"
			                style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_24_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 24 FILL">
		                <path className={styles.cls5} d="m464.86,49.78c-.33-5.67-.95-9.78-1.85-12.35-5.3-14.83-15.05-23.43-29.25-25.8-11.9-2-27.77.33-47.6,7-5.6,1.87-10.58,4.67-14.95,8.4-5.3,4.5-7.77,9.05-7.4,13.65.23,3.03,1.62,6.07,4.15,9.1,2.37,2.83,5.35,5.2,8.95,7.1.93.5,4.9,2.85,11.9,7.05,8.17,4.83,15.57,9,22.2,12.5,21.47,11.27,36.03,16.18,43.7,14.75,5.27-1,8.57-8.98,9.9-23.95.5-5.8.58-11.62.25-17.45Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_25_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 25 FILL">
		                <path className={styles.cls11} d="m463.41,157.78c.37-5.77-2.05-12.78-7.25-21.05-4.73-2.87-9.7-4.62-14.9-5.25-5.73-.3-10.02-.52-12.85-.65-5.13-.3-9.22-1.22-12.25-2.75-6.23-3.17-11.17-10-14.8-20.5-3.53-12.03-6.27-21.05-8.2-27.05-3.43-10.63-7.38-19.1-11.85-25.4-2.1-3-4.97-6.42-8.6-10.25-4.17-4.23-7.27-7.45-9.3-9.65-1.03-1.1-1.93-2.22-2.7-3.35-12.43-.8-23.88-.18-34.35,1.85,3.53,2.97,7.4,6.85,11.6,11.65,14.1,16,26.82,33.03,38.15,51.1,4.6,7.33,7.75,13.75,9.45,19.25,2.3,7.5,2.35,14.22.15,20.15-1.43,3.83-4.27,8.03-8.5,12.6-6.47,7.07-9.95,10.92-10.45,11.55-3.03,3.97-5.35,8.37-6.95,13.2-1.6,4.83-2.38,9.75-2.35,14.75,0,1.7.1,4.32.3,7.85.1,3.2,0,5.78-.3,7.75,7.73,2.63,15.55,4.32,23.45,5.05,11.8,1.03,21.13,1.18,28,.45,10.17-1.07,20.17-4.08,30-9.05,6.37-3.2,11.92-8.9,16.65-17.1,4.67-8.13,7.28-16.52,7.85-25.15Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_26_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 26 FILL">
		                <path className={styles.cls10} d="m337.96,45.38c-4.2-4.8-8.07-8.68-11.6-11.65-14.3,2.77-26.95,8.25-37.95,16.45-7.1,5.27-12.23,10.75-15.4,16.45-3.5,6.33-5.05,13.62-4.65,21.85.5,9.8,1.95,19.67,4.35,29.6,1.13,4.67,3.82,10.68,8.05,18.05,4.87,8.4,10.68,16.62,17.45,24.65,17.9,21.07,37.65,35.33,59.25,42.8.3-1.97.4-4.55.3-7.75-.2-3.53-.3-6.15-.3-7.85-.03-5,.75-9.92,2.35-14.75,1.6-4.83,3.92-9.23,6.95-13.2.5-.63,3.98-4.48,10.45-11.55,4.23-4.57,7.07-8.77,8.5-12.6,2.2-5.93,2.15-12.65-.15-20.15-1.7-5.5-4.85-11.92-9.45-19.25-11.33-18.07-24.05-35.1-38.15-51.1Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_27_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 27 FILL">
		                <path className={styles.cls10} d="m434.11,105.48c-2.5-4.3-4.42-9.28-5.75-14.95-1.6-7.73-2.93-13.6-4-17.6-1.9-7.03-4.25-12.48-7.05-16.35-6.07-8.37-13.72-14.45-22.95-18.25-7.07-2.9-16.58-4.92-28.55-6.05l-5.1-.4c.77,1.13,1.67,2.25,2.7,3.35,2.03,2.2,5.13,5.42,9.3,9.65,3.63,3.83,6.5,7.25,8.6,10.25,4.47,6.3,8.42,14.77,11.85,25.4,1.93,6,4.67,15.02,8.2,27.05,3.63,10.5,8.57,17.33,14.8,20.5,3.03,1.53,7.12,2.45,12.25,2.75,2.83.13,7.12.35,12.85.65,5.2.63,10.17,2.38,14.9,5.25-1.67-2.67-3.85-5.82-6.55-9.45-2.93-3.83-5-6.58-6.2-8.25-4.97-6.83-8.07-11.35-9.3-13.55Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_28_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 28 FILL">
		                <path className={styles.cls6} d="m351.86,91.83c-2.97.67-5.1,3.1-6.4,7.3-1.3,4.27-1.37,9.05-.2,14.35,1.17,5.3,3.2,9.6,6.1,12.9,2.93,3.3,5.88,4.62,8.85,3.95,2.97-.63,5.1-3.07,6.4-7.3,1.3-4.2,1.37-8.97.2-14.3-1.13-5.33-3.17-9.65-6.1-12.95-2.93-3.27-5.88-4.58-8.85-3.95Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_29_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 29 FILL">
		                <path className={styles.cls6} d="m408.31,92.93c-.5,4.1.1,8.62,1.8,13.55,1.67,4.93,3.95,8.87,6.85,11.8,2.87,2.9,5.5,3.93,7.9,3.1,2.37-.83,3.8-3.28,4.3-7.35.5-4.1-.1-8.62-1.8-13.55-1.67-4.93-3.95-8.85-6.85-11.75-2.87-2.93-5.5-3.98-7.9-3.15-2.37.83-3.8,3.28-4.3,7.35Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_30_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 30 FILL">
		                <path className={styles.cls6} d="m452.46,152.18c-.93-2.73-3.18-4.97-6.75-6.7-1.53-.77-3.5-1.23-5.9-1.4-2.33-.13-4.82,0-7.45.4-2.63.37-5.05.97-7.25,1.8-2.27.83-4.03,1.83-5.3,3-2.97,2.7-4.52,5.5-4.65,8.4-.13,2.63.85,5.08,2.95,7.35,1.97,2.17,4.6,3.85,7.9,5.05,3.33,1.23,6.72,1.73,10.15,1.5h.05c3.37-.77,6.47-2.23,9.3-4.4,2.83-2.1,4.9-4.47,6.2-7.1,1.37-2.8,1.62-5.43.75-7.9Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_31_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 31 FILL">
		                <path className={styles.cls6} d="m400.81,181.38c-.13.6-.03,1.17.3,1.7,1.33,2.17,3.65,4.17,6.95,6,2.8,1.53,5.3,2.48,7.5,2.85,1.4.27,2.72.37,3.95.3,2.9-.03,5.88-.85,8.95-2.45,3.17,1.4,6.18,2.08,9.05,2.05,1.37-.07,2.68-.23,3.95-.5,3.03-.67,5.18-1.6,6.45-2.8.53-.53.87-1.17,1-1.9.13-.8-.02-1.45-.45-1.95-.2-.2-.48-.38-.85-.55-.5-.2-1.02-.15-1.55.15-.6.43-1.05.73-1.35.9-1.43.77-2.83,1.32-4.2,1.65-2.17.47-4.27.5-6.3.1-1.67-.37-3.57-1.1-5.7-2.2v-.2l-.15.1-.2-.1v.2c-2.03,1.2-3.9,2.03-5.6,2.5-2,.5-4.08.57-6.25.2-1.77-.33-3.83-1.17-6.2-2.5-2.6-1.43-4.35-2.87-5.25-4.3-.33-.53-.78-.87-1.35-1-.6-.13-1.17-.03-1.7.3-.53.37-.87.85-1,1.45Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_32_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 32 FILL">
		                <path className={styles.cls6} d="m432.66,184.03c2.1-4.23,3.67-9.17,4.7-14.8.13-.67.02-1.28-.35-1.85-.37-.57-.88-.92-1.55-1.05-.67-.13-1.27,0-1.8.4-.57.37-.92.88-1.05,1.55-.9,4.97-2.25,9.33-4.05,13.1-.93,1.87-1.78,3.27-2.55,4.2-.43.5-.63,1.1-.6,1.8.03.7.3,1.27.8,1.7.6.5,1.2.7,1.8.6.7-.07,1.23-.33,1.6-.8.83-.8,1.85-2.42,3.05-4.85Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_33_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 33 FILL">
		                <path className={styles.cls11} d="m450.21,153.78c.37-1.27-.1-2.55-1.4-3.85-1.3-1.33-3.05-2.32-5.25-2.95-2.2-.63-4.2-.73-6-.3-1.8.4-2.88,1.23-3.25,2.5-.37,1.23.1,2.52,1.4,3.85,1.3,1.33,3.05,2.32,5.25,2.95,2.2.63,4.2.73,6,.3,1.83-.43,2.92-1.27,3.25-2.5Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_34_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 34 FILL">
		                <path className={styles.cls11} d="m423.11,99.33c.67-.47.92-1.3.75-2.5-.17-1.2-.68-2.4-1.55-3.6-.87-1.2-1.85-2.05-2.95-2.55-1.1-.53-1.97-.55-2.6-.05-.67.47-.93,1.3-.8,2.5.17,1.17.68,2.37,1.55,3.6.87,1.2,1.85,2.05,2.95,2.55s1.98.52,2.65.05Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_35_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 35 FILL">
		                <path className={styles.cls11} d="m356.21,96.58c-1.43-.53-2.55-.45-3.35.25s-1.05,1.8-.75,3.3c.33,1.5,1.12,2.97,2.35,4.4,1.27,1.47,2.62,2.45,4.05,2.95,1.43.53,2.55.45,3.35-.25.8-.7,1.05-1.8.75-3.3-.33-1.5-1.12-2.97-2.35-4.4-1.23-1.43-2.58-2.42-4.05-2.95Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_36_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 36 FILL">
		                <path
			                className={styles.cls12}
			                d="m399.16,74.58c12.43.17,23.45,1.7,33.05,4.6-1.97-11.27-5.57-20.83-10.8-28.7-5.87-8.8-14.45-16.53-25.75-23.2-10.73-6.37-25.53-9.38-44.4-9.05-16.3.3-29.15,2.52-38.55,6.65l-2.85,1.3c2.23-.5,4.47-.57,6.7-.2,2.27.4,4.37,1.23,6.3,2.5,3.27,2.17,4.97,5.58,5.1,10.25.1,4.23-1.02,8.67-3.35,13.3-2.37,4.63-5.32,8.23-8.85,10.8-3.87,2.8-7.63,3.67-11.3,2.6-3.23-.93-5.87-3.27-7.9-7-2.33-4.3-3-9.28-2-14.95.83-4.6,2.65-8.43,5.45-11.5-13.3,9.23-23.28,21.68-29.95,37.35-7,16.5-9.05,33.9-6.15,52.2,2.67,16.73,8.58,29.75,17.75,39.05,3.77,3.8,7.7,6.53,11.8,8.2,3.87,1.6,7.35,2.07,10.45,1.4,2.7-.57,4.75-2.85,6.15-6.85,1.03-3.1,1.87-7.98,2.5-14.65.67-8.2,1.23-14.52,1.7-18.95.9-7.8,2.13-14.4,3.7-19.8,1.73-7.67,6.9-14.33,15.5-20,7.8-5.13,17.78-9.07,29.95-11.8,11.43-2.57,23.35-3.75,35.75-3.55Z"
			                style={{ fill: `${teamColors[HELMET_BG]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_37_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 37 FILL">
		                <path
			                className={styles.cls9}
			                d="m326.16,54.18c2.97-5.2,4.13-10.48,3.5-15.85-.33-2.67-1.12-5-2.35-7-1.23-2-2.85-3.57-4.85-4.7-1.97-1.13-4.13-1.72-6.5-1.75-2.37,0-4.77.53-7.2,1.6-4.93,2.17-8.87,5.88-11.8,11.15-3,5.23-4.17,10.5-3.5,15.8.33,2.67,1.12,5,2.35,7,1.23,2.03,2.83,3.62,4.8,4.75,1.97,1.1,4.05,1.67,6.25,1.7,2.4.1,4.9-.43,7.5-1.6,4.83-2.13,8.77-5.83,11.8-11.1m-5.15-24.95c1.57.87,2.83,2.12,3.8,3.75,1,1.67,1.62,3.57,1.85,5.7.57,4.67-.47,9.33-3.1,14-2.7,4.7-6.17,8-10.4,9.9-4.13,1.8-7.82,1.8-11.05,0-3.23-1.83-5.13-5-5.7-9.5-.53-4.67.52-9.33,3.15-14,2.63-4.63,6.1-7.92,10.4-9.85,2.1-.93,4.17-1.38,6.2-1.35,1.8.07,3.42.52,4.85,1.35Z"
			                style={{ fill: `${teamColors[HELMET_HOLE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_38_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 38 FILL">
		                <path
			                className={styles.cls15}
			                d="m385.06,42.53c-3.93-11.53-10.43-19.52-19.5-23.95-3.43-.9-7.83-1.27-13.2-1.1-4.1.13-7.92.5-11.45,1.1,7.43,5,14.28,12.75,20.55,23.25,6.63,11.13,11.12,22.95,13.45,35.45l15.35.45c-.47-14.77-2.2-26.5-5.2-35.2Z"
			                style={{ fill: `${teamColors[HELMET_STRIPE]}` }}
		                />
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_39_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 39 FILL">
		                <path className={styles.cls8} d="m438.36,74.68c-.73-1.17-1.77-1.92-3.1-2.25-20.97-4.97-42.33-5.9-64.1-2.8-18.43,2.63-32.17,7.08-41.2,13.35-6.87,4.8-13.08,17.1-18.65,36.9l-.6-.45c-.87-.63-1.82-.93-2.85-.9-1.03.03-1.95.38-2.75,1.05-5.53,4.67-7.65,12.7-6.35,24.1.1.9.42,1.67.95,2.3,11.3,14.27,22.43,26.35,33.4,36.25,10.93,9.9,20.43,16.48,28.5,19.75,6.23,2.53,15.3,4.58,27.2,6.15,8.47,1.1,16.07,1.75,22.8,1.95,9.6.27,18.3-.63,26.1-2.7,7.8-2.07,14.22-5.17,19.25-9.3.93-.8,1.47-1.82,1.6-3.05.1-1.23-.23-2.33-1-3.3-.87-1-1.95-1.53-3.25-1.6,2.53-15,6.48-28.58,11.85-40.75l.2.15c1.03.67,2.17.87,3.4.6,1.2-.27,2.13-.92,2.8-1.95.67-1.03.87-2.17.6-3.4-.23-1.23-.88-2.18-1.95-2.85-13.37-8.57-26.45-12.32-39.25-11.25-12.8,1.07-25.2,6.95-37.2,17.65-13.83,2.2-28,.65-42.5-4.65-10.17-3.73-20.93-9.58-32.3-17.55,3-11.3,6.1-20.07,9.3-26.3,2.2-4.3,4.33-7.18,6.4-8.65,8.2-5.7,21.08-9.67,38.65-11.9,20.13-2.57,39.68-1.62,58.65,2.85,1.33.33,2.58.13,3.75-.6,1.17-.73,1.92-1.77,2.25-3.1.33-1.33.13-2.58-.6-3.75m-34.4,77.7c8.97-7.27,18.1-11.45,27.4-12.55,9.3-1.07,18.87.95,28.7,6.05-6.37,14.3-10.87,30.43-13.5,48.4-8.3,4.7-19.13,6.97-32.5,6.8,1-17.13-2.37-33.37-10.1-48.7m-96.4-10.95c-.47-5.37-.03-9.43,1.3-12.2,8.93,6.57,17.02,11.78,24.25,15.65,11.03,5.9,21.9,9.85,32.6,11.85l-5,34.9c-7.1-3.83-15.33-10.17-24.7-19-9.4-8.87-18.88-19.27-28.45-31.2m90.05,15.55c.13,0,.37-.07.7-.2,6.8,13.93,9.72,28.62,8.75,44.05-6.67-.4-13.28-1.07-19.85-2-8.3-1.2-14.92-2.63-19.85-4.3l5.25-36.75c8.5.9,16.83.63,25-.8Z"/>
		            </g>
		            <g id="FlashAICB_Assets_Layer_1_Clip_Group_2_0_Layer2_40_FILL" data-name="FlashAICB Assets Layer 1  Clip Group  2 0 Layer2 40 FILL">
		                <path className={styles.cls5} d="m323.46,48.18c1.93-3.7,2.73-7.17,2.4-10.4-.5-4.93-3.8-9.22-9.9-12.85-5-2.97-10.45-4.87-16.35-5.7-20.93-2.87-37.08-2.02-48.45,2.55-13.67,5.5-21.73,16.6-24.2,33.3-.43,2.9-.28,7.35.45,13.35.73,6.23,1.9,12.35,3.5,18.35,4.13,15.47,8.9,23.17,14.3,23.1,7.87-.07,21.38-8.28,40.55-24.65,9.8-8.37,19.83-17.6,30.1-27.7,3.23-2.77,5.77-5.88,7.6-9.35Z"/>
		            </g>
		        </g>
		    </g>
		</svg>
	);
};