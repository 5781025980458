import Modal from "components/common/Modal";
import ProviderClinicDetails from "components/provider/widgets/ProviderClinicDetails";
import React from "react";

export default function ProviderClinicDetailsModal(props) {
    return (
        <Modal
            medium
            show={props.show}
            icon="fa fa-2x fa-ticket"
            modalTitle={props.modalTitle || "Clinic Redemption Summary"}
            onClose={props.onClose}
        >
            <ProviderClinicDetails
                providerId={Number(props.providerId)}
                clinicId={Number(props.clinicId) || 0}
                onClose={props.onClose}
            />
        </Modal>
    );
}
