import React, {useState, useCallback} from "react";
import { connect } from "react-redux";
import * as AdminActions from "actions/AdminActions";
import SortableDataTable from "components/common/SortableDataTable";

import {forEach, find, mergeWith, cloneDeep, debounce} from "lodash";
import styles from "./VoucherTable.scss";
import classnames from "classnames";
import CheckboxInput from "components/common/CheckboxInput";
import TextBox from "components/common/TextBox";
import PropTypes from "prop-types";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";

function VoucherTable(props) {
    const [formData, setFormData] = useState({});
    const [original, setOriginal] = useState({...props.vouchers.data});
    const [generateAdditionalVouchers, setGenerateAdditionalVouchers] = useState(false);
    const [createCount, setCreateCount] = useState(0);

    const readOnly = !props.canEditVoucherManagement;

    const combinedData = cloneDeep(props.vouchers.data);

    const custom = (objValue, srcValue) => {
      if (_.isArray(objValue)) {
          return srcValue;
      }
    }

    mergeWith(combinedData, formData, custom);

    const saveVoucher = useCallback(debounce((formData) => {
        forEach(formData, voucher => {
            props.updateVoucher(voucher, props.planId);
        })
    }, 1000), []);

    const handleChange = ({name, value}, row) => {
        if(props.canEditVoucherManagement) {
            let tempData = {
                ...formData,
            };
            if (name === "allowed") {
                const remaining = tempData.allowed - combinedData[row.voucherCode].used;
                tempData = {
                    ...tempData,
                    [row.voucherCode]: {
                        ...combinedData[row.voucherCode],
                        "remaining": remaining,
                        [name]: value,
                    },
                };
            } else {
                tempData = {
                    ...tempData,
                    [row.voucherCode]: {
                        ...combinedData[row.voucherCode],
                        [name]: value,
                    },
                };
            }
            setFormData(tempData);
            saveVoucher(tempData);
        }
    }

    const getTotal = (data, type) => {
        let total = 0;
        forEach(data, voucher => {
            const num = parseInt(voucher[type]);
            if(num){
                total += num;
            }
        })
        return total;
    }

    const getVoucherCode = (voucherCode) => {
        return [voucherCode.substring(0, 3), voucherCode.substring(3, 6), voucherCode.substring(6, voucherCode.length)].join("-");
    }

    const COLUMNS = [{
        name: "Voucher Code",
        selector: "voucherCode",
        key: "voucherCode",
        sortable: true,
        searchable: true,
        searchValue: (row) => getVoucherCode(row.voucherCode),
        format: (row) => (
            <div>{getVoucherCode(row.voucherCode)}</div>
        )
    }, {
        name: "Sales Rep",
        selector: "salesRepName",
        key: "salesRepName",
        searchable: true,
        sortable: true,
        format: (row) => (
            <div style={{minWidth: "250px"}}>
                <TextBox
                    key={`${row.voucherCode}_${row.salesRepName}`}
                    name="salesRepName"
                    value={row.salesRepName}
                    disabled={readOnly}
                    changeOnBlur
                    onChange={() => {}}
                    onBlur={(data) => handleChange(data, row)}
                />
            </div>
        )
    }, {
        name: "Email",
        selector: "salesRepEmail",
        key: "salesRepEmail",
        searchable: true,
        sortable: true,
        format: (row) => (
            <div style={{minWidth: "400px"}}>
                <TextBox
                    key={`${row.voucherCode}_${row.salesRepEmail}`}
                    name="salesRepEmail"
                    value={row.salesRepEmail}
                    changeOnBlur
                    onChange={() => {}}
                    onBlur={(data) => handleChange(data, row)}
                    disabled={readOnly}
                />
            </div>
        )
    }, {
        name: "Territory",
        selector: "territoryCode",
        key: "territoryCode",
        sortable: true,
        searchable: true,
        format: (row) => (
            <div style={{minWidth: "150px"}}>
                <TextBox
                    key={`${row.voucherCode}_${row.territoryCode}`}
                    name="territoryCode"
                    value={row.territoryCode}
                    onChange={() => {}}
                    changeOnBlur
                    onBlur={(data) => handleChange(data, row)}
                    disabled={readOnly}
                />
            </div>
        )
    }, {
        name: (<div className="text-center">Unlimited?</div>),
        selector: "isUnlimited",
        key: "isUnlimited",
        sortable: true,
        format: (row) => (
            <div className="text-center">
                <CheckboxInput
                    name="isUnlimited"
                    checked={row.isUnlimited}
                    onChange={(data) => handleChange(data, row)}
                    disabled={readOnly}
                />
            </div>
        )
    }, {
        name: (<div className="text-center">Allowed</div>),
        selector: "allowed",
        key: "allowed",
        sortable: true,
        format: (row) => (
            !row.isUnlimited && (
                <div className="text-center">
                    <TextBox
                        name="allowed"
                        value={row.allowed}
                        inputType="number"
                        min={row.used || 0}
                        onChange={(data) => handleChange(data, row)}
                        disabled={readOnly}
                    />
                </div>
            )
        )
    }, {
        name: (<div className="text-center">Remaining</div>),
        selector: "remaining",
        key: "remaining",
        sortable: true,
        format: (row) => {
            const remaining = (row.remaining || (row.allowed - row.used));
            if (!row.isUnlimited) {
                return (
                    <div className="text-center">
                        <div className={classnames({
                            "text-danger": (row.used > row.allowed),
                        })}>
                            {remaining}
                        </div>
                    </div>
                )
            } else {
                return null;
            }
        }
    }, {
        name: (<div className="text-center">Used</div>),
        selector: "used",
        key: "used",
        sortable: true,
        format: (row) => (
            <div className="text-center">{row.used}</div>
        )
    }, {
        name: (<div className="text-center">Original</div>),
        selector: "original",
        key: "original",
        sortable: true,
        format: (row) => {
            const org = find(original, {"voucherCode": row.voucherCode});
            if(!row.isUnlimited && !!org) {
                return (
                    <div className="text-center">{org.allowed}</div>
                );
            } else {
                return null;
            }
        }
    }];

    if(!props.canViewVoucherManagement) {
        return <AccessDenied/>
    }

    return (
        <div className={styles.root}>
            <SortableDataTable
                columns={COLUMNS}
                rawData={combinedData}
                noPagination
                striped
                green
                hideSearchBar
                allowSearch
                search={props.search}
            />

            <div className="flex flex-wrap spaced-content margin-top-md">
                <div className="flex-none align-center">
                    <span className="flex-none">Total Uses Allowed:</span>
                    <span className="flex-none text-bold margin-left-x-sm">{getTotal(combinedData, "allowed")}</span>
                    <span className="flex-none text-sm margin-left-x-sm">(original: {getTotal(original, "allowed")})</span>
                </div>
                <div className="flex-none flex-1 nowrap">Used: <span className="text-bold">{getTotal(combinedData, "used")}</span></div>
                <div className="flex-none flex-1 nowrap">Remaining: <span className="text-bold">{getTotal(combinedData, "remaining") || (getTotal(combinedData, "allowed") - getTotal(combinedData, "used"))}</span></div>
            </div>
        </div>
    );
}

VoucherTable.propTypes = {
    planId: PropTypes.number.isRequired,
    search: PropTypes.string
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewVoucherManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.VOUCHER_MANAGEMENT, userProfile);
        const canEditVoucherManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.VOUCHER_MANAGEMENT, userProfile);
        return {
            canViewVoucherManagement,
            canEditVoucherManagement,
            plan: state.entities.plans?.[ownProps.planId],
            vouchers: state.entities.vouchers?.[ownProps.planId],
        }
    },
    (dispatch) => ({
        updateVoucher: (voucher, planId) => dispatch(AdminActions.updateVoucher(voucher, planId)),
    })
)(VoucherTable);
