import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import includes from "lodash/includes";
import ProviderBase from "components/layout/ProviderBase";
import EditGame from "components/provider/widgets/EditGame";
import AccessDenied from "components/common/AccessDenied";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function GamePage(props) {
    const location = useLocation();
    const params = useParams();
    const gameId = Number(params.gameId);
    const nodeId = Number(params.nodeId);
    const providerId = Number(params.providerId);
    const isAdmin = includes(location.pathname, "/admin");

    return (
        <ProviderBase
            location={location}
            pageTitle="Greenline Games™"
            collapsible
            nodeId={nodeId}
            providerId={providerId}
        >
            {props.canViewGames ? (
                <EditGame
                    isGame
                    id={gameId}
                    nodeId={nodeId}
                    providerId={providerId}
                    isAdmin={isAdmin}
                />
            ) : (
                <AccessDenied/>
            )}
        </ProviderBase>
    );
}
export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        //Permissions
        const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewGamesAdmin = userHasPermission(PermissionTypes.VIEW, UserPermissions.GAMES_ADMIN, userProfile);
        const canViewGames = (canAdminProviderBiGames && canViewGamesAdmin) || canViewProviderBiGames;

        return {
            // Permissions
            canViewGames,
        }
    }
)(GamePage);