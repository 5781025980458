import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./PuppyLeafPartyCard.scss";
import find from "lodash/find";
import get from "lodash/get";
import { getLast2Snapshots } from "actions/ProviderActions";
import Image from "components/common/Image";
import { LeafPartyLegalWording } from "components/games/elements/LegalWording";
import Loader from "components/common/Loader";
import PuppyLeafPartyClinicScore from "components/games/widgets/PuppyLeafPartyClinicScore";
import { PermissionTypes, userHasPermission, userPrimaryLocationType } from "utils/permissions/rolesPermissions";
import * as LocationTypes from "constants/LocationTypes";
import * as UserPermissions from "constants/UserPermissions";
import smallImage from "../../../../../../img/games/leaf_party/leaf_party_banner_sm.jpg";

function PuppyLeafPartyCard(props) {
    const history = useHistory();
    const params = useParams();
    const [gameLoaded, setGameLoaded] = useState(false);
    const { nodeId } = params;

    useEffect(() => {
        if (props.game?.greenlineGameId && props.game?.daysFromStart !== 0) {
            props.getSnapshots(props.game.greenlineGameId, props.game.ownerUserId, props.clinicId, false);
        }
    }, [props.game]);

    const handleDashboardClick = () => {
        if (props.isAdmin) {
            history.push(`/admin/clinic/${props.clinicId}/games/${props.game.greenlineGameId}`);
        } else if(props.isProvider) {
            history.push(`/provider/location/${nodeId}/clinic/${props.clinicId}/games/${props.game.greenlineGameId}`);
        } else {
            history.push(`/games/${props.game.greenlineGameId}`);
        }
    }

    const gameData = useMemo(() => {
        if (props.game?.daysFromStart === 0) {
            // Return kickoff
            return {
                totalDoses: 0,
                newDoses: 0,
                bonus: 0,
                firstDay: true,
            }
        }
        if (props.game?.data.Clinics && props.clinicId && !!props.gameSnapshotComparison) {
            if (!props.gameSnapshotComparison.length) {
                // Unlikely, but just in case
                return {
                    totalDoses: 0,
                    newDoses: 0,
                    bonus: 0,
                    firstDay: false,
                }
            }

            let today;
            let yesterday;
            if (props.gameSnapshotComparison.length === 1) {
                // Handle when it's the first day, all 0s for Yesterday
                yesterday = props.gameSnapshotComparison[0];
                today = find(props.game.data.Clinics, clinic => clinic.ClinicId === props.clinicId);
            } else {
                yesterday = find(props.gameSnapshotComparison[0].data.Clinics, clinic => clinic.ClinicId === props.clinicId);
                today = find(props.gameSnapshotComparison[1].data.Clinics, clinic => clinic.ClinicId === props.clinicId);
            }

            const todayTotalDoses = get(today, "CurrentPeriod.TotalReportDoses", 0);
            const yesterdayTotalDoses = get(yesterday, "CurrentPeriod.TotalReportDoses", 0);
            const newDoses = todayTotalDoses - yesterdayTotalDoses;

            const todayExtraDoses = get(today, "CurrentPeriod.ExtraDoses", 0);
            const yesterdayExtraDoses = get(yesterday, "CurrentPeriod.ExtraDoses", 0);
            const bonus = todayExtraDoses - yesterdayExtraDoses;

            return {
                totalDoses: todayTotalDoses,
                newDoses: newDoses,
                bonus: bonus,
                firstDay: false,
            };
        }
        return null;
    }, [props.game, props.clinicId, props.gameSnapshotComparison]);

    useEffect(() => {
        if (props.game?.details && (props.gameSnapshotComparison || props.game?.daysFromStart === 0) && !!gameData) {
            setGameLoaded(true);
        }
    }, [props.game, props.gameSnapshotComparison, gameData]);

    const gameAnimation = useMemo(() => {
        if (gameLoaded) {
            return (
                <PuppyLeafPartyClinicScore data={gameData} />
            );
        } else {
            return <Loader />;
        }
    }, [gameLoaded]);

    return (
        <div
            key={`game_${props.game.greenlineGameId}`}
            className={classnames(styles.root, {
                [styles.clickable]: props.dashboard,
                [styles.historic]: !props.game.isCurrent,
            })}
        >
            <div id={`game_${props.game.greenlineGameId}`} className={styles.gameContainer}>
                <div className={styles.banner}>
                    <Image
                        src={smallImage}
                        alt="NexGard® advertisement"
                    />
                </div>
                <div className={styles.animation}>
                     {gameAnimation}
                </div>
                <div className={styles.dates}>
                    {props.game.startDate} - {props.game.endDate}
                </div>
                <LeafPartyLegalWording />
                <div className={styles.viewLeaderboardLink} onClick={handleDashboardClick}>
                    View the Leaderboard <i className={"fa-solid fa-chevron-right"} />
                </div>
            </div>
        </div>
    );
}

PuppyLeafPartyCard.propTypes = {
    clinicId: PropTypes.number,
    game: PropTypes.object,
    dashboard: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const isAdmin = userPrimaryLocationType(userProfile, [LocationTypes.NULL]);
        const isProvider = userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canEditClinicBiGamesDashboard = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            isAdmin,
            isProvider,
            canViewClinicBIGamesDashboard,
            canEditClinicBiGamesDashboard,
            gameSnapshotComparison: state.entities.gameSnapshotComparison[ownProps.game?.greenlineGameId],
        }
    },
    (dispatch) => ({
        getSnapshots: (gameId, userId, clinicId, isDemo) => dispatch(getLast2Snapshots(gameId, userId, clinicId, isDemo)),
    })
)(PuppyLeafPartyCard);
