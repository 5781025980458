import React from "react";
import PropTypes from "prop-types";
import styles from "./NotificationList.scss";
import isArray from "lodash/isArray";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import partition from "lodash/partition";
import NotificationTile from "components/notifications/elements/NotificationTile";
import { PENDING } from "constants/Notifications";

export default function NotificationList(props) {
    const renderNotification = (notification, index) => {
        return (
            <NotificationTile
                key={notification.notificationId}
                id={notification.notificationId}
                type={notification.type}
                label={notification.title}
                buttonLabel={notification.buttonLabel || "View"}
                description={notification.shortDescription}
                onButtonClick={props.clickHandler}
                dismissed={notification.enrollmentState !== PENDING}
                isFirst={(index === 0)}
            />
        );
    }

    let viewableNotifications = isArray(props.notifications) ? props.notifications : map(props.notifications);
    const partitioned = partition(orderBy(viewableNotifications, "displayOrder"), n => n.enrollmentState === PENDING);

    if (props.showDismissed) {
        viewableNotifications = [...partitioned[0], ...partitioned[1]];
    } else {
        viewableNotifications = partitioned[0];
    }

    if (props.limit) {
        viewableNotifications = viewableNotifications.slice(0, props.limit);
    }

    return (
        <div className={styles.root}>
            {!viewableNotifications?.length ? (
                <div className={styles.noNotifications}>No new notifications.</div>
            ) : (
                map(viewableNotifications, (n, index) => renderNotification(n, index))
            )}
        </div>
    );
}

NotificationList.propTypes = {
    notifications: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    clickHandler: PropTypes.func,
    dashboard: PropTypes.bool,
    limit: PropTypes.number,
    showDismissed: PropTypes.bool,
};
