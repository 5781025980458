import React, { Fragment, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import produce from "immer";
import moment from "moment";
import classnames from "classnames";
import styles from "./PreAuditReview.scss";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import omit from "lodash/omit";
import reject from "lodash/reject";
import * as ClinicActions from "actions/ClinicActions";
import * as CouponActions from "actions/CouponActions";
import AccessDenied from "components/common/AccessDenied";
import AdminBase from "components/layout/AdminBase";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import Modal from "components/common/Modal";
import OpenInvoicePDFLinkNewTab from "components/common/OpenInvoicePDFLinkNewTab";
import PriceOverridesForm from "components/admin/forms/PriceOverridesForm";
import ProductAnalysis from "components/admin/widgets/ProductAnalysis";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import { PermissionTypes, userCanViewMappingPage, userHasPermission } from "utils/permissions/rolesPermissions";
import { PAR } from "constants/AdminControls";
import * as CssStyleIds from "constants/CssStyleIds";
import { PAR_ITEM_APPROVED } from "constants/ParItemRedemptionStates";
import { EXCEEDED_MAX_RED_COUNT } from "constants/ReasonCodeTypes";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

function PreAuditReview(props) {
    const [editOverride, setEditOverride] = useState(null);
    const [showLoader, setShowLoader] = useState(false);
    const [selectedItems, setSelectedItems] = useState({});
    const [approveDeclineReason, setApproveDeclineReason] = useState(null);
    const [productAnalysis, setProductAnalysis] = useState({});
    const [filteredInvoices, setFilteredInvoices] = useState([]);

    useEffect(() => {
        props.getProviderList();
    }, []);

    useEffect(() => {
        if(props.canViewClinicManagement) {
            props.getClinicById(props.clinicId);
        }
        if (props.canViewPreAuditReview) {
            props.loadClinicPar(props.clinicId);
        }
    }, [props.clinicId, props.canViewClinicManagement, props.canViewPreAuditReview]);

    const handleAnalysis = (reason) => {
        setProductAnalysis(reason);
    }

    const reprocessInvoice = (invoice) => {
        if(props.canEditCouponReprocessing) {
            if (confirm(`Are you sure you want to reprocess Invoice (${invoice.invoiceNumber})?`)) {
                const {invoiceId, providerId} = invoice;
                setShowLoader(true);
                const data = {
                    clinicId: props.clinicId,
                    invoices: [
                        invoiceId
                    ],
                    manufacturers: [
                        providerId
                    ]
                };

                props.createReprocessingJob(data)
                setTimeout(() => {
                    props.history.push('/admin/coupons/reprocess');
                }, 1000);
            }
        }
    }

    const handleEditOverrides = (reason) => {
        setEditOverride(reason);
    }

    const handleUpdateReason = ({ value }) => {
        setApproveDeclineReason(value);
    }

    const handleApproveClick = () => {
        if(props.canEditPreAuditReview) {
            const itemIds = flatMap(selectedItems, item => item);
            props.approveDeclineParItems(selectedItems, itemIds, approveDeclineReason, true);
            clearAllSelectedItems();
        }
    }

    const handleDeclineClick = () => {
        if(props.canEditPreAuditReview) {
            const itemIds = flatMap(selectedItems, item => item);
            props.approveDeclineParItems(selectedItems, itemIds, approveDeclineReason);
            clearAllSelectedItems();
        }
    }

    const renderParTable = () => {
        if (!props.invoices || props.invoices.length === 0) {
            return (
                <div className={styles.providerSection}>
                    <div className={styles.providerInfo}>
                        <span>No PAR data available right now.</span>
                    </div>
                </div>
            );
        }

        return (
            <div className={styles.providerSection}>
                <table className="table table-green less-padding borders">
                    <thead>
                    <tr className="top-aligned">
                        <th>Inv #</th>
                        <th>Date</th>
                        <th colSpan="6">Reason</th>
                    </tr>
                    </thead>
                    <tbody>
                        {renderRows()}
                    </tbody>
                </table>
            </div>
        )
    }

    const handleCheckedItem = (invoiceId, {name, value}) => {
        let newState = produce(selectedItems, items => items);
        if(!value) {
            if (newState[invoiceId].length > 1) {
                //Need to keep the invoiceId if there is more than one redemption
               newState = {
                    ...newState,
                    [invoiceId]: reject(newState[invoiceId], i => {return i === name})
                }
            } else {
                //Need to remove the invoiceId if there is only the one redemption
                newState = omit(newState, invoiceId);
            }
        } else {
            //Need to add the redemption and invoice to the selected list.
            newState = {
                ...newState,
                [invoiceId]: newState[invoiceId] ? newState[invoiceId].concat([name]) : [name]
            }
        }
        setSelectedItems(newState);
    }

    const clearAllSelectedItems = () => {
        setSelectedItems({});
        setApproveDeclineReason(null);
    }

    //Simple Search
    const searchFields = ["invoiceNumber"];
    const redemptionSearchFields = ["redemptionId", "petName", "offerDescriptionShort", "lineItemDescription"];
    const searchTokens = props.search?.toLowerCase().split(" ");

    const checkFieldAllMatch = (fieldValue) => {
        if(fieldValue) {
            let allMatch = true;
            for (let j = 0; j < searchTokens.length; j++) {
                const token = searchTokens[j];
                if (!fieldValue.toString().toLowerCase().includes(token)) {
                    allMatch = false;
                }
            }
            return allMatch;
        }
        return false
    }

    useEffect(() => {
        if(!!props.search) {
            setFilteredInvoices(getFilteredInvoices());
        } else {
            setFilteredInvoices(props.invoices);
        }
    }, [props.search, props.invoices]);

    const getFilteredRedemptionMatches = (invoice) => {
        const redemptionMatches = map(invoice.redemptions, redemption => {
            const matchingFields = [];
            forEach(redemptionSearchFields, field =>  {
                const redemptionFieldValue = redemption[field];
                if(!!checkFieldAllMatch(redemptionFieldValue)){
                    matchingFields.push(field)
                }
            });
            return {
                redemptionId: redemption.redemptionId,
                matchingFields
            };
        })
        return keyBy(filter(redemptionMatches, t => {return !!t?.matchingFields?.length}), "redemptionId");
    }

    const getFilteredInvoices = () => {
        return filter(props.invoices, invoice => {
            for (let i = 0; i < searchFields.length; i++) {
                const fieldValue = invoice[searchFields[i]];
                if(!!checkFieldAllMatch(fieldValue)){
                    return true;
                }

                //Filter visible invoices based on the redemptionSearchFields
                const filteredRedemptions =  filter(invoice.redemptions, redemption => {
                    for (let i = 0; i < redemptionSearchFields.length; i++) {
                        const redemptionFieldValue = redemption[redemptionSearchFields[i]];
                        if(!!checkFieldAllMatch(redemptionFieldValue)){
                            return true;
                        }
                    }
                })
                if(!!filteredRedemptions?.length){
                    return true;
                }
            }
            return false;
        })
    };

    const renderRows = () => {
        if (!Object.keys(props.invoices)?.length) {
            return null;
        } else if(!!props.search && !filteredInvoices?.length){
            return (
                <tr>
                    <td colSpan={10}>No results. Change your search to see more results.</td>
                </tr>
            )
        }

        return map(filteredInvoices, (invoice) => {
            const filteredRedemptions = !!props.search ? getFilteredRedemptionMatches(invoice) : {};

            return (
                <Fragment key={invoice.invoiceId}>
                    {map(invoice.reasons, (reason, index) => (
                        <tr key={index} className={classnames(styles.reason, "invoice-item", {
                            [styles.first]: index === 0,
                            [styles.last]: index === (invoice.reasons?.length - 1),
                        })}>
                            <td>
                                {(index === 0) && (
                                    <OpenInvoicePDFLinkNewTab
                                        className="text-primary"
                                        invoiceId={invoice.invoiceId}
                                        extendedInvoiceNumber={invoice.extendedInvoiceNumber}
                                    />
                                )}
                            </td>
                            <td>
                                {(index === 0) && (
                                    <div className="padding-xs">
                                        {moment(invoice.invoiceDate).format("MM/DD/YYYY")}
                                    </div>
                                )}
                            </td>
                            <td>
                                <Button
                                    small
                                    type="primary"
                                    onClick={() => handleAnalysis(reason)}
                                >
                                    Analysis
                                </Button>
                            </td>
                            <td>
                                <div className={styles.details}>
                                    <div className={styles.descriptor}>
                                        <span className={styles.label}>{reason.label}</span>
                                        {(reason.reasonCode === EXCEEDED_MAX_RED_COUNT) && (<br/>)}
                                        <span className={styles.qualifier}>{reason.descriptor}</span>
                                    </div>
                                    <div className={styles.product}>{reason.clinicProduct}</div>
                                </div>
                            </td>
                            <td colSpan={2}>
                                <div className={styles.itemDescription}>[{reason.info} {reason.descriptor}]</div>
                            </td>
                            <td className="text-center">
                                {(reason.reasonCode !== EXCEEDED_MAX_RED_COUNT) && (
                                    <Button noWrap onClick={() => handleEditOverrides(reason)}>Edit Overrides</Button>
                                )}
                                {reason.overrideApplied && (<div className="text-sm text-bold">Override Applied</div>)}
                            </td>
                            <td className="text-center">
                                {(index === 0) && (
                                    <div className="padding-top-xs">
                                        <Button
                                            tall
                                            type={"primary"}
                                            onClick={() => reprocessInvoice(invoice)}
                                            disabled={!props.canEditCouponReprocessing}
                                        >
                                            Reprocess
                                        </Button>
                                    </div>
                                )}
                            </td>
                        </tr>
                    ))}
                    {map(Object.values(invoice.redemptions), (item, index) => {
                        const redemptionHasMatches = !!filteredRedemptions[item.redemptionId];
                        const matchingFields = redemptionHasMatches ? filteredRedemptions[item.redemptionId]?.matchingFields : [];
                        return (
                            <tr key={item.redemptionId} className={classnames(styles.lineItem, {
                                [styles.showLabels]: index === 0,
                                [styles.rowMatch]: redemptionHasMatches
                            })}>
                                <td>
                                    <div
                                        className="padding-default text-right"
                                        title={item.canApproveOrDecline ? "Select to approve or decline this item" : `Has been ${item.redemptionStateId === PAR_ITEM_APPROVED ? "approved" : "declined"}`}
                                    >
                                        <CheckboxInput
                                            name={item.externalRedemptionIdentifierId}
                                            checked={!!selectedItems[invoice.invoiceId] ? includes(selectedItems[invoice.invoiceId], item.externalRedemptionIdentifierId) : false}
                                            onChange={({name, value}) => handleCheckedItem(invoice.invoiceId, {name, value})}
                                            disabled={!(item.canApproveOrDecline && props.canEditPreAuditReview)}
                                        />
                                    </div>
                                </td>
                                <td title="Redemption ID"
                                    className={classnames(styles.redemptionDetails, {
                                    [styles.cellMatch]: redemptionHasMatches ? !!includes(matchingFields, "redemptionId") : false,
                                })}>
                                    <label>R. ID</label>
                                    <span>{item.redemptionId}</span>
                                </td>
                                <td colSpan={2}
                                    title="Product Name"
                                    className={classnames(styles.redemptionDetails, {
                                        [styles.cellMatch]: redemptionHasMatches ? !!includes(matchingFields, "lineItemDescription") : false,
                                    })}
                                >
                                    <label>Product Name</label>
                                    <span>{item.product}</span>
                                </td>
                                <td title="Pet Name"
                                    className={classnames(styles.redemptionDetails, {
                                        [styles.cellMatch]: redemptionHasMatches ? !!includes(matchingFields, "petName") : false,
                                    })}
                                >
                                    <label>Pet Name</label>
                                    <span>{item.pet}</span>
                                </td>
                                <td className={classnames(styles.redemptionDetails, "text-center")} title="Quantity">
                                    <label>Qty</label>
                                    <span>{item.quantity}</span>
                                </td>
                                <td colSpan={2}
                                    title="Offer Code"
                                    className={classnames(styles.redemptionDetails, {
                                        [styles.cellMatch]: redemptionHasMatches ? !!includes(matchingFields, "offerDescriptionShort") : false,
                                    })}
                                >
                                    <label>Offer Code</label>
                                    <span>{item.offerCode}</span>
                                </td>
                            </tr>
                        );
                    })}
                </Fragment>
            );
        })
    }

    return (
        <AdminBase
            searchConfig={SearchTypes.PRE_AUDIT_REVIEW}
            collapsible
            docTitle="Pre-Audit Review"
            clinicId={props.clinicId}
            pageTitle="Pre-Audit Review"
            controls={PAR}
        >
            <div className={classnames(styles.root, {
                    [styles.selectedItems]: !!Object.keys(selectedItems).length,
                })}>
                {props.canViewPreAuditReview ? (
                    <>
                        {props.clinicDetails && renderParTable()}
                        {!!(Object.keys(selectedItems).length && props.canEditPreAuditReview) && (
                            <div className={styles.approvalBox}>
                                <div className={styles.reasonText}>
                                    <TextBox
                                        name={"reason"}
                                        onChange={handleUpdateReason}
                                        textArea
                                        value={approveDeclineReason}
                                        placeholder={"REQUIRED - Please enter your reason for accepting or declining"}
                                        disabled={!props.canEditPreAuditReview}
                                    />
                                </div>
                                <div className={styles.buttonHolder}>
                                    <Button
                                        type={CssStyleIds.SUCCESS}
                                        wide
                                        className={styles.approveBtn}
                                        onClick={handleApproveClick}
                                        disabled={!(approveDeclineReason && props.canEditPreAuditReview)}
                                    >
                                        Approve
                                    </Button>
                                    <Button
                                        type={CssStyleIds.NOTIFY}
                                        wide
                                        className={styles.approveBtn}
                                        onClick={handleDeclineClick}
                                        disabled={!(approveDeclineReason && props.canEditPreAuditReview)}
                                    >
                                        Decline
                                    </Button>
                                    <Button
                                        type={CssStyleIds.DARK}
                                        onClick={clearAllSelectedItems}
                                    >
                                        Clear Selected
                                    </Button>
                                </div>
                            </div>
                        )}
                        {!!showLoader && <SpinnerTakeover show/>}
                    </>
                ) : (
                    <AccessDenied/>
                )}
            </div>

            {editOverride && <Modal
                show={!!editOverride}
                modalTitle="Adjust Min/Max Pricing for PAR (Experienced Users Only!)"
                onClose={() => setEditOverride(null)}
                mediumSmall
            >
                <PriceOverridesForm
                    isPAR
                    clinicName={props.clinicDetails.name}
                    clinicId={props.clinicId}
                    productId={editOverride.productId}
                    reason={editOverride}
                    onClose={() => setEditOverride(null)}
                />
            </Modal>}
            <Modal
                show={!!productAnalysis.clinicProductId}
                modalTitle="Clinic Product Analysis"
                onClose={() => setProductAnalysis({})}
            >
                <ProductAnalysis
                    clinicId={props.clinicId}
                    reason={productAnalysis}
                />
            </Modal>
        </AdminBase>
    )
}

const connector = connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const userProfile = state.user.userProfile;
        //Permissions
        const canViewPreAuditReview = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRE_AUDIT_REVIEW, userProfile);
        const canEditPreAuditReview = userHasPermission(PermissionTypes.EDIT, UserPermissions.PRE_AUDIT_REVIEW, userProfile);
        const canViewMappingPage = userCanViewMappingPage(userProfile);
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditCouponReprocessing = userHasPermission(PermissionTypes.EDIT, UserPermissions.COUPON_REPROCESSING, userProfile);
        return {
            clinicId,
            canViewPreAuditReview,
            canEditPreAuditReview,
            canViewMappingPage,
            canViewClinicManagement,
            canEditCouponReprocessing,
            clinicDetails: state.entities.clinicDetails[clinicId],
            invoices: state.entities.preAuditReview,
            providers: state.entities.providers,
            search: state.entities.genericSearch || "",
        }
    },
    (dispatch) => ({
        loadClinicPar: (clinicId, providerId) => dispatch(CouponActions.loadClinicPar(clinicId, providerId)),
        approveDeclineParItems: (data, items, comments, approve) => dispatch(CouponActions.approveDeclineParItems(data, items, comments, approve)),
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        getClinicById: (clinicId) => dispatch(ClinicActions.getClinicById(clinicId)),
        createReprocessingJob: (data) => dispatch(CouponActions.createReprocessingJob(data)),
    })
);

export default compose(
    withRouter,
    connector
)(PreAuditReview);
