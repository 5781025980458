/* USAGE
*
*   Dropdown component options (array of name:value pairs)
*       usStatesDropdownList = 50 States + DC
*       usStatesAndTerritoriesDropdownList = 50 states + DC + Territories
*
* *   Elsewhere:
*       usStates : Object of US States names keyed by Postal Abbreviation
*       usStatesByName : Object of US States postal abbreviations keyed by state name
*       usStatesPostal : Array of US States postal abbreviations
*       usStatesNames : Array of US State names
*
*       usTerritories : Object of US Territory names keyed by Postal Abbreviation
*       usTerritoriesByName : Object of US Territory postal abbreviations keyed by territory name
*
*       usStatesAndTerritories : Object of US States and Territory names keyed by Postal Abbreviation
*       usStatesAndTerritoriesByName : Object of US State and Territory postal abbreviations keyed by state name
*       usStatesAndTerritoriesPostal : Array of US State and Territory postal abbreviations
*       usStatesAndTerritoriesNames :  Array of US State and Territory names
*
* */
import { invert, keys, map, values } from "lodash";

export const CAProvinces = {
    "AB": "Alberta",
    "BC": "British Columbia",
    "MB": "Manitoba",
    "NB": "New Brunswick",
    "NL": "Newfoundland and Labrador",
    "NT": "Northwest Territories",
    "NS": "Nova Scotia",
    "NU": "Nunavut",
    "ON": "Ontario",
    "PE": "Prince Edward Island",
    "QC": "Quebec",
    "SK": "Saskatchewan",
    "YT": "Yukon",
}

export const caProvincesByName = invert(CAProvinces);
export const caProvincesPostal = keys(CAProvinces);
export const caProvincesNames = values(CAProvinces);



export const caProvincesList = [
    {
        "name": "Alberta",
        "abbr": "AB"
    },
    {
        "name": "British Columbia",
        "abbr": "BC"
    },
    {
        "name": "Manitoba",
        "abbr": "MB"
    },
    {
        "name": "New Brunswick",
        "abbr": "NB"
    },
    {
        "name": "Newfoundland and Labrador",
        "abbr": "NL"
    },
    {
        "name": "Northwest Territories",
        "abbr": "NT"
    },
    {
        "name": "Nova Scotia",
        "abbr": "NS"
    },
    {
        "name": "Nunavut",
        "abbr": "NU"
    },
    {
        "name": "Ontario",
        "abbr": "ON"
    },
    {
        "name": "Prince Edward Island",
        "abbr": "PE"
    },
    {
        "name": "Quebec",
        "abbr": "QC"
    },
    {
        "name": "Saskatchewan",
        "abbr": "SK"
    },
    {
        "name": "Yukon",
        "abbr": "YT"
    }
];

export const caProvincesDropdownList = map(caProvincesList, province => (
    { name: province.name, value: province.abbr }
));

