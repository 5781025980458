exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PuppyBowlSnapshot__root{height:100%;width:100%;overflow-y:auto}.PuppyBowlSnapshot__root .Modal__fullscreenModal .Modal__modalBody .Modal__modalBodyContainer{-ms-flex:none;flex:none}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/widgets/PuppyBowlSnapshot/PuppyBowlSnapshot.scss"],"names":[],"mappings":"AAAA,yBAAM,YAAY,WAAW,eAAe,CAAC,8FAAsG,cAAc,SAAS,CAAC","file":"PuppyBowlSnapshot.scss","sourcesContent":[".root{height:100%;width:100%;overflow-y:auto}.root :global(.Modal__fullscreenModal) :global(.Modal__modalBody) :global(.Modal__modalBodyContainer){-ms-flex:none;flex:none}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "PuppyBowlSnapshot__root"
};