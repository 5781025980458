import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as CouponActions from "actions/CouponActions";
import ClinicBase from "components/layout/ClinicBase";
import * as SearchTypes from "constants/SearchTypes";
import CouponOptInOffers from "components/coupons/widgets/CouponOptInOffers";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import { getClinicInfo, getProviderImage } from "utils/ClinicData";
import { CLINIC_TAGS } from "constants/AdminControls";

function CouponOptIn(props) {
    useEffect(() => {
        if(props.canViewClinicCouponLibrary) {
            props.getProgramOffers(props.programId, props.clinicId);
        }
        if (props.canViewClinicCouponLibrary && !(props.program && props.program?.provider)) {
            props.getPrograms(props.clinicId);
        }
    }, [props.clinicId]);

    if (!props.program?.provider) {
        return null;
    }

    return (
        <ClinicBase
            collapsible={true}
            searchConfig={{
                ...SearchTypes.LIBRARY_OFFERS,
                preBoxJsx: props.program.provider ? (
                    <img
                        alt={`${props.program.provider.name} logo`}
                        src={getProviderImage(props.program.provider)}
                        style={{ maxHeight: "50px" }}
                    />
                ) : null,
            }}
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle={`${props.program.provider.name} Coupon Library`}
            pageTitle={`${props.program.provider.name} Coupon Library`}
            overflowHidden
        >
            {props.canViewClinicCouponLibrary ? (
                props.program ? (
                    <CouponOptInOffers
                        programId={props.programId}
                        clinicId={props.clinicId}
                    />
                ) : (
                    <div className="full-height">
                        <AccessDenied
                            customMessage="Clinic does not have access to this Coupon Library"
                            customSubMessage="Contact Greenline for more information"
                        />
                    </div>
                )
            ) : (
                <div className="full-height">
                    <AccessDenied customMessage="You do not have permission to view the Clinic Library"/>
                </div>
            )}
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const programId = Number(ownProps.match.params.programId);
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        return {
            programId,
            clinicId,
            canViewClinicCouponLibrary,
            program: state.entities.programs[programId],
        }
    },
    (dispatch) => ({
        getPrograms: (clinicId) => dispatch(CouponActions.getPrograms(clinicId)),
        getProgramOffers: (programId, clinicId) => dispatch(CouponActions.getProgramOffers(programId, clinicId)),
    }),
)(CouponOptIn);
