import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import AdminBase from "components/layout/AdminBase";
import * as CouponActions from "actions/CouponActions";
import * as AdminActions from "actions/AdminActions";
import * as styles from "./PlanSetup.scss";
import Dropdown from "components/common/Dropdown";
import Button from "components/common/Button";
import {map, cloneDeep, merge} from "lodash";
import PlanSetupForm from "components/admin/forms/PlanSetupForm";

function PlanSetup(props) {
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [formData, setFormData] = useState({});

    const combinedData = cloneDeep(props.providerPlans);
    merge(combinedData, formData);

    const PLAN_OPTIONS = !!combinedData ? map(combinedData, (plan) => {
        return {name: plan.name, value: plan.planId};
    }) : [];

    useEffect(() => {
        props.getProviderPlans(props.providerId);

        if(!props.provider) {
            props.getProviderById(props.providerId);
        }
    }, []);

    const handleSelectPlan = ({value}) => {
        if(Object.keys(formData).length) {
            if (confirm(`Are you sure you want to switch pages? Any unsaved changes will be lost.`)) {
                setSelectedPlanId(value);
                setFormData({});
            }
        } else {
            setSelectedPlanId(value);
            setFormData({});
        }
    }

    const handleChange = (data) => {
        const newFormData = {
            ...formData,
            [data.planId]: data,
        }
        setFormData(newFormData);
    }

    const handleSubmit = () => {
        const plan = {
            ...combinedData[selectedPlanId],
            termLengthMonths: combinedData[selectedPlanId].termMonths,
            serviceTypes: map(combinedData[selectedPlanId].serviceTypes)
        };
        props.updateProviderPlan(props.providerId, plan);
        setFormData({});
    }

    return (
        <AdminBase docTitle={"Plan Setup"}>
            <div className={styles.root}>
                <div className="flex  align-center margin-bottom-md">
                    <div className="flex-1 flex-column">
                        {!!props.provider && (
                            <h2>Available Plans for {props.provider.name}</h2>
                        )}
                        <div>Review/modify the global wellness plans</div>
                    </div>
                </div>
                <hr className="margin-bottom-md"/>
                <div className="margin-bottom-md flex">
                    <div className="flex-2">
                        <Dropdown
                            options={PLAN_OPTIONS}
                            name="selectedPlanId"
                            onChange={handleSelectPlan}
                            value={selectedPlanId}
                            placeholder="--Please Select a Plan--"
                        />
                    </div>
                    <div className="flex-3"/>
                </div>
                {!!selectedPlanId && (
                    <div className="margin-bottom-x-lg">
                        <PlanSetupForm
                            plan={combinedData[selectedPlanId]}
                            providerId={props.providerId}
                            handleChange={handleChange}
                        />
                        <div className="text-right margin-right-lg">
                            <Button onClick={handleSubmit}>
                                Apply Changes
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </AdminBase>
    )
}


export default connect(
    (state, ownProps) => {
        const providerId = Number(ownProps.match.params.programId);
        return {
            providerId,
            provider: state.entities.providers[providerId],
            providerPlans: state.entities.providerPlans[providerId],
        }
    },
    (dispatch) => ({
        getProviderById: (providerId) => dispatch(CouponActions.getProviderById(providerId)),
        getProviderPlans: (providerId) => dispatch(AdminActions.getProviderPlans(providerId)),
        updateProviderPlan: (providerId, plan) => dispatch(AdminActions.updateProviderPlan(providerId, plan)),
    })
)(PlanSetup);
