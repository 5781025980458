import { GAME_PRODUCT_TYPES } from "constants/GameProductTypes";

export function getCategoryNameForGameType(productType) {
    switch (productType) {
        case GAME_PRODUCT_TYPES.THERAPEUTIC:
            return "Other"
        case GAME_PRODUCT_TYPES.PARASITICIDE:
            return "Parasiticide Products";
        case GAME_PRODUCT_TYPES.DENTAL:
            return "Dental Products";
        case GAME_PRODUCT_TYPES.NSAID:
            return "NSAIDs";
    }

    return productType;
}

export function getCategoryIdForGameProductType(productType) {
    switch (productType) {
        case GAME_PRODUCT_TYPES.PARASITICIDE:
            return 30;
        case GAME_PRODUCT_TYPES.DENTAL:
            return 60;
        case GAME_PRODUCT_TYPES.NSAID:
            return 20;
        case GAME_PRODUCT_TYPES.NUTRACEUTICAL:
            return 70;
        case GAME_PRODUCT_TYPES.SERVICE:
            return 50;
        case GAME_PRODUCT_TYPES.THERAPEUTIC:
            return 40;
    }
    return productType;
}