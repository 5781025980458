import React, { useMemo } from "react";
import * as styles from "./BuildVersion.scss";
import PropTypes from "prop-types";
export default function BuildVersion(props) {

    const prError = useMemo(() => {
        if (props.prNumber) {
            return isNaN(Number(props.prNumber));
        }
        return false;
    }, [props.prNumber]);

    if (!props.prNumber) {
        return null;
    }

    const handleRemovePR = () => {
        localStorage.removeItem("prNumber");
        localStorage.removeItem("versionHash");
        window.location = "/";
    }

    // const handleReload = () => {
    //     window.location = `${window.location}&reload=true`;
    // }

    const handleInfoClick = () => {
        alert(`GitHub Commit Hash:
${props.versionHash}

Azure Build:
https://gluiassests.blob.core.windows.net/gl-assets/${props.versionHash}/app.bundle.js`);
    }

    return (
        <div
            data-testid="build_version_component"
            className={styles.root}
        >
            <div
                onClick={handleRemovePR}
            ><i className="fas fa-times" /></div>
            {prError ? (
                <div className={styles.error}>
                    {props.prNumber}
                </div>
            ):(
                <>
                    <a
                        title={props.versionHash}
                        onClick={handleInfoClick}
                    >
                        <i className="fas fa-info-circle" />
                    </a>
                    {/*<div*/}
                    {/*    onClick={handleReload}*/}
                    {/*><i className="fas fa-rotate-right" /></div>*/}
                    <a
                        href={`https://github.com/Greenline-Pet/greenline-web/pull/${props.prNumber}`}
                        target="_blank"
                        title="PR Link"
                    >
                        PR {props.prNumber}
                    </a>
                </>
            )}

        </div>
    );
};

BuildVersion.proptypes = {
    prNumber: PropTypes.string,
    versionHash: PropTypes.string,
}
