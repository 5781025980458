import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ProviderTileGrid.scss";
import DashboardTile from "components/dashboard/widgets/DashboardTile";
import { map } from "lodash";
import { Link } from "react-router-dom";
import {getLinkLocation} from "utils/ProviderLinks";

export default function ProviderTileGrid(props) {

    const getTileLink = (tileLink) => {
        const {providerId, nodeId, isAdmin} = props;
        return getLinkLocation(tileLink, {
            providerId,
            nodeId,
            isAdmin,
        });
    }

    const renderTile = tile => {
        const tileJsx = (
            <DashboardTile
                isButton={!!tile.link}
                key={tile.id}
                title={tile.title}
                largeValue={tile.value}
                type={tile.type}
            />
        );

        if (tile?.link) {
            return (
                <Link to={getTileLink(tile.link)}
                      key={`link_${tile.id}`}
                >
                    {tileJsx}
                </Link>
            );
        } else {
            return tileJsx;
        }
    };

    return (
        <div className="full-width flex justify-center margin-top-sm margin-bottom-sm">
            <div className={classnames(styles.root, {
                [styles.corporateGroupTiles]: props.isCorporateGroup
            })}>
                {map(props.tiles, tile => renderTile(tile))}
            </div>
        </div>
    );
}

ProviderTileGrid.propTypes = {
    tiles: PropTypes.array.isRequired,
    providerId: PropTypes.number,
    nodeId: PropTypes.number,
    isAdmin: PropTypes.bool,
    isCorporateGroup: PropTypes.bool
};