import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useHistory, withRouter } from "react-router";
import styles from "./ProviderDashboard.scss";
import * as ProviderApi from "api/ProviderApi";
import * as ProviderActions from "actions/ProviderActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import ProviderBase from "components/layout/ProviderBase";
import ProviderClinicDetails from "components/provider/widgets/ProviderClinicDetails";
import ProviderClinicTable from "components/provider/widgets/ProviderClinicTable";
import ProviderTitleBar from "components/provider/widgets/ProviderTitleBar";
import ResultsPerPageDropdown from "components/coupons/widgets/ResultsPerPageDropdown";
import {getNodeId} from "utils/AdminData";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import getProviderId from "utils/getProviderId";
import * as ProviderLinks from "utils/ProviderLinks";
import {downloadFile} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import {PREMIER_PET_CARE_PLAN} from "constants/ProviderIds";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

// Provider clinic table and Coupon Redemptions Summary modal
function ProviderClinicsPage(props) {
    const history = useHistory();
    const [showClinicDetails, setShowClinicDetails] = useState(false);
    const [selectedClinicId, setSelectedClinicId] = useState(null);
    const [resultsPerPage, setResultsPerPage] = useState(10);

    useEffect(() => {
        if (props.userProfile && props.canViewProviderInfo && props.nodeId) {
            props.getNodeClinics(props.nodeId);
        }
    }, [props.nodeId]);

    const handleRedemptionsIconClicked = (row) => {
        setShowClinicDetails(true)
        setSelectedClinicId(row.clinicId);
    }

    const handleRowClicked = (row) => {
        if (props.canViewCorporateGroupClinic) {
            if(props.isAdmin) {
                props.history.push(ClinicAdminLinks.getLinkLocation("dashboard", row.clinicId));
            } else {
                props.history.push(ClinicProviderLinks.getLinkLocation("dashboard", props.nodeId, row.clinicId));
            }
        } else if(props.canViewWellnessSettings) {
            let link;
            if(props.isAdmin) {
                link = ClinicAdminLinks.getLinkLocation("programsDashboard", row.clinicId);
            } else {
                link = ProviderLinks.getLinkLocation("clinic-settings", {
                    providerId: props.providerId,
                    nodeId: props.nodeId,
                    clinicId: row.clinicId,
                });
            }
            history.push(link);
        } else {
            handleRedemptionsIconClicked(row);
        }
    }
    const handleClose = () => {
        setShowClinicDetails(false)
        setSelectedClinicId(null);
    }

    const handleDownload = async () => {
        toast.success("Generating Export...");
        const res = await ProviderApi.exportProviderClinics(props.nodeId, "xlsx");
        downloadFile(res, "xlsx");
    }

    return (
        <ProviderBase
            providerId={props.providerId}
            location={props.location}
            searchConfig={SearchTypes.PROVIDER_CLINICS_SEARCH}
            pageTitle="Clinics Overview"
            collapsible
            nodeId={props.nodeId}
        >
            {!props.canViewProviderInfo ? (
                <AccessDenied/>
            ) : (
                <div className={styles.root}>
                    <div className={styles.mainSection}>
                        <div className="full-width flex justify-center">
                            <ProviderTitleBar
                                title={props.provider ? props.provider?.name : ""}
                            />
                        </div>
                        <div className="flex justify-flex-end spaced-content margin-bottom-sm">
                            <div>
                                <ResultsPerPageDropdown
                                    onChange={({value}) => setResultsPerPage(value)}
                                    value={resultsPerPage}
                                />
                            </div>
                            <div>
                                <Button
                                    iconOnly
                                    onClick={handleDownload}
                                >
                                    <i className="fa fa-download"/>
                                </Button>
                            </div>
                        </div>
                        <div className="full-width flex">
                            {(props.clinics && props.columns) && (
                                <ProviderClinicTable
                                    rawData={props.clinics}
                                    columns={props.columns}
                                    onRowClick={handleRowClicked}
                                    onRedemptionsIconClicked={handleRedemptionsIconClicked}
                                    canViewCouponLibrary={props.canViewCouponLibrary}
                                    resultsPerPage={resultsPerPage}
                                    showAllColumns={!props.wellnessOnly}
                                    providerId={props.providerId}
                                    nodeId={props.nodeId}
                                    hasGames={props.nodeOverview?.hasGames}
                                    isAdmin={props.isAdmin}
                                />
                            )}
                        </div>
                    </div>
                    <Modal
                        medium
                        show={showClinicDetails}
                        icon="fa fa-2x fa-ticket"
                        modalTitle="Clinic Redemption Summary"
                        onClose={handleClose}
                    >
                        <ProviderClinicDetails
                            providerId={Number(props.providerId)}
                            clinicId={Number(selectedClinicId)}
                            onClose={handleClose}
                        />
                    </Modal>
                </div>
            )}
        </ProviderBase>
    );
}

const connector = connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const canViewCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewProviderInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_INFO, userProfile);
        const providerId = getProviderId(params, userProfile);
        const nodeId = getNodeId(state, ownProps);
        const nodeOverview = state.entities.providerHierarchies?.[providerId]?.[nodeId];

        const node = state.entities.nodes[nodeId];
        const wellnessOnly = (node?.providerId === PREMIER_PET_CARE_PLAN);

        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewClinicWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canViewWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canEditCouponReprocessing = userHasPermission(PermissionTypes.EDIT, UserPermissions.COUPON_REPROCESSING, userProfile);

        const canViewCorporateGroupClinic =  userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);

        // If User has one of the Wellness Permissions then give them the settings permission
        const canViewWellnessSettings =  canViewClinicWellnessAudit || canViewClinicWellnessPlans || canViewClinicWellnessPetPlans || canViewWellnessDvms || canEditCouponReprocessing;

        return {
            nodeId,
            userProfile,
            canViewCouponLibrary,
            canViewProviderInfo,
            providerId,
            wellnessOnly,
            nodeOverview,
            provider: state.entities.providers[providerId],
            node: state.entities.nodes[nodeId] || null,
            clinics: state.entities.nodeClinics[nodeId] || null,
            columns: state.entities.nodeClinicColumns[nodeId] || {},
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && providerId && nodeId),

            // Permissions
            canViewCorporateGroupClinic,
            canViewWellnessSettings,
        };
    },
    (dispatch) => ({
        getNodeClinics: (nodeId) => dispatch(ProviderActions.getNodeClinics(nodeId)),
    }),
);

export default compose(
    withRouter,
    connector
)(ProviderClinicsPage);
