import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import filter from "lodash/filter";
import map from "lodash/map";
import reject from "lodash/reject";
import round from "lodash/round";
import * as styles from "./GameParasiticideProductsTable.scss";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import Tooltip from "components/common/Tooltip";
import getProviderId from "utils/getProviderId";
import { DEATH_MATCH_NGP, GROWTH_NGP } from "constants/GameTypes";

function GameParasiticideProductTable(props) {
    const {reviewData, data} = props;
    const [show, setShow] = useState(null);
    const products = reviewData ? filter(reviewData.data, {weightClassName: null}) :  [];
    const {clinics} = data;

    if (clinics?.length && data?.productType && data?.startDate && !reviewData?.data?.length) {
        return null;
    }

    const handleToggleRows = (productBrandId, clickable=false) => {
        if (clickable) {
            if (show === productBrandId) {
                setShow(null);
            } else {
                setShow(productBrandId);
            }
        }
    }

    const renderBrandName = (brandName, doses) => {
        const specialNexGardPlusMessage = (data.gameType === GROWTH_NGP || data.gameType === DEATH_MATCH_NGP) && brandName.toLowerCase() === "nexgard plus";
        let tipMessage = null;
        if (doses === -1 || !doses) {
            tipMessage = `Clinic did not sell ${brandName} during this time frame`;
            if (specialNexGardPlusMessage) {
                tipMessage = "Clinic did not dispense either Heartgard or NexGard during this timeframe."
            }
        } else if (specialNexGardPlusMessage) {
            tipMessage = "This is a combination of Heartgard/NexGard dispensed during this timeframe"
        }

        if (tipMessage) {
            return (
                <div className={styles.extraInfo}>
                    <Tooltip
                        key={`brand_${brandName}`}
                        position="top"
                        tip={
                            <div>
                                {tipMessage}
                            </div>
                        }
                    >
                        <div>
                            <i className={"fas fa-eye"} />
                        </div>
                    </Tooltip>
                    <span>{brandName}</span>
                </div>
            )
        }

        return brandName;
    }


    const ROW = (row, index, isFirstRow=false) => {
        const paidDosesChange = round((row.cPaidDoses - row.pPaidDoses),2);
        return (
            <tr
                key={index}
                className={classnames("spaced-content", {
                    [styles.firstRow]: isFirstRow,
                })}
            >
                {isFirstRow ? (
                    <>
                        <td>
                            {show === row.productBrandId ? (
                                <i className="fa fa-caret-up btn btn-link btn-color-primary"
                                   onClick={() => handleToggleRows(row.productBrandId, isFirstRow)}
                                />
                            ) : (
                                <i className="fa fa-caret-down btn btn-link btn-color-primary"
                                   onClick={() => handleToggleRows(row.productBrandId, isFirstRow)}
                                />
                            )}
                        </td>
                        <td className="nowrap">{row.manufacturerName}</td>
                        <td className="nowrap">
                            {renderBrandName(row.brandName, row.pDoses)}
                        </td>
                    </>
                ) : (
                    <>
                        <td/>
                        <td colSpan={2}>{row.weightClassName || row.brandName}</td>
                    </>
                )}
                <td className="text-center">{row.pDoses || "-"}</td>
                <td className="text-center">{row.pPaidDoses || "-"}</td>
                {props.data.hasStarted && (
                    <>
                        <td className="text-center">{row.cDoses || "-"}</td>
                        <td className="text-center">{row.cPaidDoses || "-"}</td>
                        <td>
                            <div className="flex spaced-content flex-centered">
                                {(row.changeDoses >  0) && (<i className="fa fa-arrow-up text-success margin-right-x-sm"/>)}
                                {(row.changeDoses < 0) && (<i className="fa fa-arrow-down text-danger margin-right-x-sm"/>)}
                                {row.changeDoses || "-"}
                            </div>
                        </td>
                        <td>
                            <div className="flex spaced-content flex-centered">
                                {(paidDosesChange >  0) && (<i className="fa fa-arrow-up text-success margin-right-x-sm"/>)}
                                {(paidDosesChange < 0) && (<i className="fa fa-arrow-down text-danger margin-right-x-sm"/>)}
                                {paidDosesChange || "-"}
                            </div>
                        </td>
                    </>
                )}
            </tr>
        )
    };

    const renderSubRows = (product) => {
        if (product.brandName.toLowerCase() === "nexgard plus") {
            const subRows = [];

            const ngCount = {
                brandName: "NexGard",
                pDoses: product.ngBaseDoseCount || 0,
                pPaidDoses: product.ngBasePaidDoseCount || 0,
                homePDoses: 0
            }
            subRows.push(ROW(ngCount, 0));
            const hgCount = {
                brandName: "Heartgard",
                pDoses: product.hgBaseDoseCount || 0,
                pPaidDoses: product.hgBasePaidDoseCount || 0,
                homePDoses: 0
            }
            subRows.push(ROW(hgCount, 1));
            return subRows;
        }

        const weightClasses = reject(filter(reviewData.data, {productBrandId: product.productBrandId}), {weightClassName: null});
        return map(weightClasses, (weight, i) => ROW(weight, i));
    }

    const SECTIONS = map(products, (product, index) => {


        return (
            <>
                {ROW(product, index, true)}
                {(show === product.productBrandId) && renderSubRows(product)}
            </>
        );
    });

    const MOBILE_VIEW_ROW = (row, index, isFirstRow=false) => {
        const paidDosesChange = round((row.cPaidDoses - row.pPaidDoses),2);

        return (
            <tr
                key={index}
                className={classnames({
                    [styles.firstRow]: isFirstRow,
                    [styles.subRow]: !isFirstRow
                })}
            >
                <td>
                    <div>
                        {/* col 2-3 */}
                        {isFirstRow ? (
                            <div className={"flex spaced-content"}>
                                {show === row.productBrandId ? (
                                    <i className="flex-none fa fa-caret-up btn btn-link btn-color-primary"
                                       onClick={() => handleToggleRows(row.productBrandId, isFirstRow)}
                                    />
                                ) : (
                                    <i className="flex-none fa fa-caret-down btn btn-link btn-color-primary"
                                       onClick={() => handleToggleRows(row.productBrandId, isFirstRow)}
                                    />
                                )}
                                <div className={styles.sectionLabel}>
                                    <div>{row.manufacturerName}</div>
                                    <div className={styles.sectionBrand}>{row.brandName}</div>
                                </div>
                            </div>
                        ) : (
                            <div className={styles.sectionLabel}>{row.weightClassName || row.brandName}</div>
                        )}

                        {/* col 4-5 */}
                        <div className={styles.subSection}>
                            {data.hasStarted && (
                                <div className={styles.subSectionLabel}>
                                    <b>Previous Period:</b> {reviewData.dates?.startDateLastPeriod} - {reviewData.dates?.endDateLastPeriod}
                                </div>
                            )}
                            <div className={styles.reportNumbers}>
                                <label>TOTAL MARKET SHARE:</label> <span>{row.pDoses || "-"}</span>
                            </div>
                            <div className={styles.reportNumbers}>
                                <label>PAID DOSES:</label> <span>{row.pPaidDoses || "-"}</span>
                            </div>
                        </div>

                        {/* col 6-9 */}
                        {data.hasStarted && (
                            <>
                                <div className={styles.subSection}>
                                    <div className={styles.subSectionLabel}>
                                        <b>Current Period:</b> {reviewData.dates.startDate} - {reviewData.dates.endDate}
                                    </div>
                                    <div className={styles.reportNumbers}>
                                        <label>Total Market Share:</label> <span>{row.cDoses || "-"}</span>
                                    </div>
                                    <div className={styles.reportNumbers}>
                                        <label>Paid Doses:</label> <span>{row.cPaidDoses || "-"}</span>
                                    </div>
                                </div>
                                <div className={styles.subSection}>
                                    <div className={styles.subSectionLabel}><b>Change</b></div>
                                    <div className={styles.reportNumbers}>
                                        <label>Total Market Share</label> <span>{(row.changeDoses >  0) && (<i className="fa fa-arrow-up text-success margin-right-x-sm"/>)}
                                            {(row.changeDoses < 0) && (<i className="fa fa-arrow-down text-danger margin-right-x-sm"/>)}
                                            {row.changeDoses || "-"}</span>
                                    </div>
                                    <div className={styles.reportNumbers}>
                                        <label>Paid Doses</label> <span>{(paidDosesChange >  0) && (<i className="fa fa-arrow-up text-success margin-right-x-sm"/>)}
                                            {(paidDosesChange < 0) && (<i className="fa fa-arrow-down text-danger margin-right-x-sm"/>)}
                                        {paidDosesChange || "-"}</span>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </td>
            </tr>
        )
    };

    const MOBILE_SECTIONS = map(products, (product, index) => {
        const weightClasses = reject(filter(reviewData.data, {productBrandId: product.productBrandId}), {weightClassName: null});

        return (
            <>
                {MOBILE_VIEW_ROW(product, index, true)}
                {(show === product.productBrandId) && map(weightClasses, (weight, i) => MOBILE_VIEW_ROW(weight, i))}
            </>
        );
    });

    const renderTable = () => (
        <table className={classnames(styles.fullTable, "table margin-top-x-sm")}>
            <thead>
            {data.hasStarted && (
                <tr>
                    <th className="text-center" colSpan="3"/>
                    <th className="text-center" colSpan="2">
                        <div>Previous Period</div>
                        <div className="text-sm">{reviewData.dates?.startDateLastPeriod} - {reviewData.dates?.endDateLastPeriod}</div>
                    </th>
                    <th className="text-center" colSpan="2">
                        <div>Current Period</div>
                        <div className="text-sm">{reviewData.dates.startDate} - {reviewData.dates.endDate}</div>
                    </th>
                    <th className="text-center" colSpan="2">Change</th>
                </tr>
            )}
            <tr className="spaced-content">
                <th/>
                <th>Mfr</th>
                <th>Product</th>
                <th className="text-center">Total<br/>Market<br/>Share</th>
                <th className="text-center">Paid<br/>Doses</th>
                {data.hasStarted && (
                    <>
                        <th className="text-center">Total<br/>Market<br/>Share</th>
                        <th className="text-center">Paid<br/>Doses</th>
                        <th className="text-center">Total<br/>Market<br/>Share</th>
                        <th className="text-center">Paid<br/>Doses</th>
                    </>
                )}
            </tr>
            </thead>
            <tbody>
            {!!SECTIONS ? SECTIONS : (
                <tr>
                    <td>
                        <SpinnerTakeover show/>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    );

    const renderMobileView = () => (
        <table className={classnames(styles.mobileView, "table margin-top-x-sm")}>
            <thead>
                <colgroup>
                    <col width="20px" />
                    <col />
                </colgroup>
            </thead>
            <tbody>
            {!!MOBILE_SECTIONS ? MOBILE_SECTIONS : (
                <tr>
                    <td>
                        <SpinnerTakeover show/>
                    </td>
                </tr>
            )}
            </tbody>
        </table>
    )


    return (
        <div className="full-width full-height margin-top-sm">
            <div>
                <div className="flex spaced-content">
                    <h2 className="flex-1">{data.productType} Products</h2>
                </div>
                {reviewData &&
                    <span>({reviewData.dates?.startDateLastPeriod} - {reviewData.dates?.endDateLastPeriod})</span>
                }
            </div>
            {(!reviewData && !((clinics && clinics.length) && data.startDate)) &&
                <div className="flex justify-center margin-top-sm" style={{ color: "#aaa", minWidth: "200px" }}>
                    <h3>Select {!(clinics && !!clinics.length) && "a Clinic"} {!((clinics && !!clinics.length) || data.startDate) && "and"} {!data.startDate && "the Start Date"} to View Data</h3>
                </div>
            }
            {reviewData &&
                <div className={classnames(styles.root, "overflow-x-scroll full-width")}>
                    {renderTable()}
                    {renderMobileView()}
                </div>
            }
        </div>
    );
}

GameParasiticideProductTable.propTypes = {
    data: PropTypes.object,
    reviewData: PropTypes.object,
    readOnly: PropTypes.bool,
}

export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const providerId = getProviderId(params, userProfile);
        return {
            providerId,
        };
    }
)(GameParasiticideProductTable);
