import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import * as ClinicActions from "actions/ClinicActions";
import Map from "components/maps/Map";
import { values } from 'lodash';
import PropTypes from "prop-types";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";

function AdminMap(props) {

    useEffect(() => {
        if (props.canViewClinicManagement) {
            props.getAdminMappedClinics();
        }
    }, [props.canViewClinicManagement]);

    if (!props.canViewClinicManagement) {
        return <AccessDenied/>;
    }

    return (
        <div className="full-width full-height">
            <Map
                markers={props.clinics}
                center={props.center}
                zoom={5}
                markerChildren={props.markerChildren}
                onMarkerClick={props.onMarkerClick}
                onClusterClick={props.onClusterClick}
                onBoundsChange={props.onBoundsChange}
            />
        </div>
    );
}

AdminMap.propTypes = {
    clinics: PropTypes.array.isRequired,
    onMarkerClick: PropTypes.func,
    onBoundsChange: PropTypes.func
};

export default connect((state) => {
    const userProfile = state.user.userProfile;
    const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            mappedClinics: values(state.entities.mappedClinics), // Convert to array
        }
    },
    (dispatch) => ({
        getAdminMappedClinics: () => dispatch(ClinicActions.getAdminMappedClinics()),
    })
)(AdminMap);
