import React from "react";
import PropTypes from "prop-types";
import { downloadGroupOfferInvoicePdf, downloadInvoicePdf } from "api/ClinicApi";
import { downloadFile, handleErrorResponse } from "utils/request";

export default function OpenInvoicePDFLinkNewTab(props) {
    const handleClick = async e => {
        e.preventDefault();
        e.stopPropagation();

        try {
            let pdf;

            if (props.groupOfferId) {
                pdf = await downloadGroupOfferInvoicePdf(props.groupOfferId);
            } else {
                pdf = await downloadInvoicePdf(props.invoiceId);
            }

            if (pdf) {
                downloadFile(pdf, "pdf", true);
            }
        } catch (error) {
            handleErrorResponse("download invoice PDF", error);
        }
    };

    const showInvoiceId = props.showInvoiceId && !!props.invoiceId;
    const showExtendedInvoiceNumber = !!props.extendedInvoiceNumber;
    let invoiceValueToShow = showInvoiceId ? props.invoiceId : showExtendedInvoiceNumber ? props.extendedInvoiceNumber : props.invoiceNumber;

    return (
        <a
            data-testid="open_invoice_pdf_link_new_tab_component"
            className={props.className}
            style={props.style}
            href="#"
            onClick={handleClick}
            id={props.id}
        >
            {invoiceValueToShow}
        </a>
    );
};

OpenInvoicePDFLinkNewTab.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    showInvoiceId: PropTypes.bool,
    groupOfferId: PropTypes.number,
    invoiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    invoiceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    extendedInvoiceNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    style: PropTypes.object,
};
