import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TextBox from "components/common/TextBox";
import { snakeCase } from "lodash";

export default function GoGreenRequirementRow(props) {
    const req = props.requirement;

    if (!req) {
        return null;
    }

    return (
        <tr title={req.tooltip}>
            <td>
                {req.isValid ? <i className="fa-2x fa fa-check text-success" /> : <i className="fa-2x fa fa-exclamation-triangle text-danger" />}
            </td>

            {props.linkUrl &&
                <td>
                    <Link to={props.linkUrl}>{req.name}</Link>
                </td>
            }

            {!props.linkUrl &&
                <td>{req.name}:</td>
            }

            <td>
                <TextBox
                    name={props.name || snakeCase(req.name)}
                    value={req.value}
                    inputType={props.inputType}
                    disabled={props.readOnly}
                    onChange={props.readOnly ? () => { } : props.onChange}
                />
            </td>
        </tr>
    );
}

GoGreenRequirementRow.propTypes = {
    requirement: PropTypes.object.isRequired,
    clinicId: PropTypes.number.isRequired,
    readOnly: PropTypes.bool,
    linkUrl: PropTypes.string, // If the label of the row should be a link
    onChange: PropTypes.func, // Required only if the row isn't `readOnly`
    name: PropTypes.string, // Name of the value for onChange()
    inputType: PropTypes.string,
}
