import React from "react";
import styles from "./StatusFilterDropdown.scss";
import Dropdown from "components/common/Dropdown";
import PropTypes from "prop-types";
import { reject, find } from "lodash";

const OPTIONS = Object.freeze([
    { name: "10", value: 10 },
    { name: "25", value: 25 },
    { name: "50", value: 50},
    { name: "100", value: 100},
    { name: "250", value: 250},
    { name: "500", value: 500},
]);

export default function ResultsPerPageDropdown(props) {
    const handleChanged = data => {
        if (props.onChange) {
            props.onChange(data);
        }
    };

    //Hide Options that aren't needed (e.g. If there are only 23 results we don't need the options for 40, 50, etc...)
    const filteredOptions = props.max ? reject(OPTIONS, (o, index) => {
        const prevOptionValue = OPTIONS[index-1] ? OPTIONS[index-1].value : 0;
        return (o.value > props.max && prevOptionValue > props.max)
    }) : OPTIONS;

    //If the value that is selected is larger than the visible values then we need to show the last option as selected
    const selectedValue = props.value ? (find(filteredOptions, {value: props.value}) ? props.value : filteredOptions[filteredOptions.length - 1].value) : null;

    return (
        <div className={styles.root}>
            {(filteredOptions.length > 1) && (
                <Dropdown
                    label={props.label}
                    name="resultsPerPage"
                    value={selectedValue}
                    options={filteredOptions}
                    onChange={handleChanged}
                    small
                    fullWidth
                />
            )}
        </div>
    );
}

ResultsPerPageDropdown.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number.isRequired,
    label: PropTypes.string,
    max: PropTypes.number,
};
