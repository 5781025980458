import React, { useEffect } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ClinicFilters.scss";
import flatMap from "lodash/flatMap";
import keyBy from "lodash/keyBy";
import last from "lodash/last";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import Button from "components/common/Button";
import ClinicFilter from "components/admin/elements/ClinicFilter";
import ClinicTableDropdown from "components/admin/elements/ClinicTableDropdown";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { parseQueryString, setQueryStringParam } from "utils/urls";
import * as UserPermissions from "constants/UserPermissions";
import * as CouponActions from "actions/CouponActions";
import * as AdminActions from "actions/AdminActions";
import * as ClinicActions from "actions/ClinicActions";
import getIsClinicFilterDataLoaded from "utils/getIsClinicFilterDataLoaded";

function ClinicFilters(props) {

    useEffect(() => {
        if (!props.isClinicFilterDataLoaded) {
            props.getProviderList();
            props.getActiveOffers();
            props.getProviderReports();
            props.getSoftwareVendors();
        }
    }, [props.isClinicFilterDataLoaded]);

    const currentFilters = orderBy(JSON.parse(props.parsedFilters?.filters || "[]"), "order");

    const updateFilters = (updatedFilters) => {
        const stringFilters = JSON.stringify(updatedFilters);
        const newQs = setQueryStringParam(props.location.search, "filters", stringFilters);
        props.history.replace(`${props.location.pathname}?${newQs}`);
    }

    const handleChangeFilters = (fil) => {
        const newFilters = {
            ...keyBy(currentFilters, "order"),
            [fil.order]: fil
        };
        updateFilters(newFilters);
    }

    const handleDeleteFilter = (e, filOrder) => {
        e.stopPropagation();
        e.preventDefault();
        const newFilters = reject(currentFilters, ["order", filOrder]);
        updateFilters(newFilters);
    }

    const handleAddFilter = () => {
        const usedNumbers = flatMap(currentFilters, "order");
        const nextOrder = usedNumbers?.length ? (Number(last(usedNumbers)) + 1) : 0;
        const tempFilters = {
            ...currentFilters,
            [nextOrder]: {
                order: nextOrder,
                id: null,
                isComplete: false,
            }
        };
        updateFilters(tempFilters);
    }

    const handleRefreshClick = () => {
        props.triggerLoad();
    }

    return (
        <div className={styles.root}>
            <div className={classnames(styles.topLine, "flex spaced-content margin-bottom-sm flex-wrap")}>
                <div className="flex-none flex spaced-content">
                    <h3 className="flex-none flex flex-centered">Filters</h3>
                    <div className="flex-none">
                        <Button
                            small
                            onClick={handleAddFilter}
                            iconOnly
                        >
                            <i className="fa fa-plus text-x-sm margin-right-x-sm"/>
                            <i className="fa fa-filter margin-right-x-sm"/>
                        </Button>
                    </div>
                    <div className="flex-none">
                        <Button
                            small
                            onClick={handleRefreshClick}
                            iconOnly
                        >
                            <i className="fa fa-refresh" />
                        </Button>
                    </div>
                </div>
                <div className="flex-1"/>
                {props.canViewClinicManagement && (
                    <div className="flex-none flex align-center">
                        <ClinicTableDropdown />
                    </div>
                )}
            </div>
            <div className="flex flex-wrap text-sm align-center margin-left-sm">
                {map(orderBy(currentFilters, ["order"]), (filter) => (
                    <div key={filter.order} className={styles.filter}>
                        <ClinicFilter
                            fil={filter}
                            handleChangeFilters={handleChangeFilters}
                            isNew={!filter.id}
                        />
                        <Button
                            className={styles.deleteFilter}
                            type="danger"
                            small
                            text
                            iconOnly
                            onClick={(e) => handleDeleteFilter(e, filter.order)}
                        >
                            <i className="fa fa-times"/>
                        </Button>
                    </div>
                ))}
            </div>
        </div>
    );
}

ClinicFilters.propTypes = {
    location: PropTypes.any.isRequired,
    triggerLoad: PropTypes.func,
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const parsedFilters = parseQueryString(ownProps.location.search);
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            parsedFilters,
            canViewClinicManagement,
            clinicView: state.entities.clinicViews?.[parsedFilters?.viewId] || {},
            isClinicFilterDataLoaded: getIsClinicFilterDataLoaded(state),
        }
    },
    (dispatch) => ({
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        getActiveOffers: () => dispatch(CouponActions.getLibraryOffers()),
        getProviderReports: () => dispatch(AdminActions.getProviderReports()),
        getSoftwareVendors: () => dispatch(ClinicActions.loadSoftwareVendors()),
    })
)

export default compose(
    withRouter,
    connector
)(ClinicFilters);

