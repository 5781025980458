import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import styles from "./PendingVisitsModal.scss";
import Modal from "components/common/Modal";
import UnprocessedWellnessVisits from "./UnprocessedWellnessVisits";
import * as ModalTypes from "constants/ModalTypes";
import AccessDenied from "components/common/AccessDenied";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

class PendingVisitsModal extends React.Component {

    render() {
        return (
            <Modal
                show={this.props.show}
                onClose={this.props.onClose}
                modalType={ModalTypes.FULLSCREEN}
            >
                <div className={styles.root}>
                    <div className={styles.header}>
                        <span><i className="fas fa-ticket" /> Pending Visits</span>
                        <Link
                            className={classnames("btn btn-color-primary btn-link btn-lg", styles.viewAll)}
                            to={"/wellness/audit"}
                        >
                            View All
                        </Link>
                    </div>
                    {this.props.canViewClinicWellnessAudit ? (
                        <UnprocessedWellnessVisits
                            onClose={this.props.onClose}
                            clinicId={this.props.clinicId}
                        />
                    ) : (
                        <AccessDenied/>
                    )}

                </div>
            </Modal>
        );
    }
}

PendingVisitsModal.propTypes = {
    onClose: PropTypes.func,
    clinicId: PropTypes.number,
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        return {
            canViewClinicWellnessAudit,
        }
    }
)(PendingVisitsModal);
