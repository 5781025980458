import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import * as styles from "./WellnessTable.scss";
import filter from "lodash/filter";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as ClinicActions from "actions/ClinicActions";
import * as MappingActions from "actions/MappingActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import ManageClinicPriceSchedule from "components/admin/forms/ManageClinicPriceSchedule";
import ManageClinicTaxForm from "components/admin/forms/ManageClinicTaxForm";
import Modal from "components/common/Modal";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import WellnessTable from "components/admin/widgets/WellnessTable";
import getLocalTimezone from "utils/getLocalTimezone";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function WellnessMapping(props) {
    if (!props.canViewClinicWellnessPriceSchedules) {
        return <AccessDenied/>
    }

    const [selectedPricingScheduleId, setSelectedPricingScheduleId] = useState(defaultPricingSchedule);
    const [showManageClinicTax, setShowManageClinicTax] = useState(false);
    const [showManagePriceSchedule, setShowManagePriceSchedule] = useState(false);
    const [selectedClinicTaxId, setSelectedClinicTaxId] = useState(null);
    const TODAY = new moment();

    const defaultPricingSchedule = filter(props.pricingSchedules, schedule => {
        const startDate = moment(schedule.startDate);
        const endDate = schedule.endDate ? moment(schedule.endDate) : null;

        if (!endDate || TODAY.isBetween(startDate, endDate)) {
            return schedule;
        }
    })[0] || {};


    const defaultClinicTax = filter(props.taxRates, tax => {
        const startDate = moment(tax.startDate);
        const endDate = tax.endDate ? moment(tax.endDate) : null;

        if (!endDate || TODAY.isBetween(startDate, endDate)) {
            return tax;
        }
    })[0] || {};

    const CLINIC_TAX_OPTIONS = props.taxRates ?  map(props.taxRates, (tax) => {
        // return {name: `Product Tax Rate: ${tax.productsTaxRate}%; Service Tax Rate: ${tax.servicesTaxRate}% (${tax.startDate ? getLocalTimezone(tax.startDate) : ""} - ${tax.endDate ? getLocalTimezone(tax.endDate) : ""}) ${defaultClinicTax.clinicTaxId === tax.clinicTaxId ? "[CURRENT]" : ""}`, value: tax.clinicTaxId};
        return {name: `${tax.startDate ? getLocalTimezone(tax.startDate) : ""} - ${tax.endDate ? getLocalTimezone(tax.endDate) : ""} ${defaultClinicTax.clinicTaxId === tax.clinicTaxId ? "[CURRENT]" : ""}`, value: tax.clinicTaxId};
    }) : [];

    const CLINIC_PRICING_OPTIONS = props.pricingSchedules ? map( props.pricingSchedules, (price) => {
        return {name: `${price.name} (${getLocalTimezone(price.startDate)} - ${getLocalTimezone(price.endDate)}) ${defaultPricingSchedule.clinicPriceScheduleId === price.clinicPriceScheduleId ? "[CURRENT]" : ""}`, value: price.clinicPriceScheduleId};
    }) : [];

    useEffect(() => {
        props.loadTaxRates(props.clinicId);
        if(props.canViewClinicWellnessPriceSchedules) {
            props.loadPricingSchedule(props.clinicId);
        }
        if (!(props.products?.length)) {
            props.loadWellness();
        }
    }, [props.clinicId]);

    useEffect(() => {
        if(Object.keys(props.pricingSchedules)?.length) {
            props.loadWellnessMappings(props.clinicId);
        }
    }, [Object.keys(props.pricingSchedules)?.length]);

    if(props.working) {
        return <SpinnerTakeover show/>;
    }

    const handleChangePricingSchedule = ({name, value}) => {
        setSelectedPricingScheduleId(value);
    }

    const handleSubmitClinicTaxForm = (data) => {
        props.batchUpdateClinicTax(data);
        setShowManageClinicTax(false);
    }

    return (
        <div className="flex flex-column height-100-perc">
            <div className="flex-none flex spaced-content">
                <div className={classnames(styles.dropdowns, "flex-1 flex spaced-content")}>
                    <div className="align-center">
                        <Button
                            small
                            text
                            type="primary"
                            onClick={() => setShowManageClinicTax(true)}
                        >
                            Manage
                        </Button>
                    </div>
                    <div className="flex flex-1 text-sm">
                        <Dropdown
                            options={CLINIC_TAX_OPTIONS}
                            label="Clinic Tax"
                            value={selectedClinicTaxId ? selectedClinicTaxId : defaultClinicTax.clinicTaxId || null}
                            name="id"
                            onChange={({value}) => setSelectedClinicTaxId(value)}
                            placeholder="--Select Clinic Tax--"
                            fullWidth
                            hidePlaceholder
                        />
                    </div>
                </div>
                {props.canViewClinicWellnessPriceSchedules && (
                    <div className={classnames(styles.dropdowns, "flex-1 flex justify-flex-end spaced-content")}>
                        <div className="align-center">
                            {props.canEditClinicWellnessPriceSchedules && (
                                <Button
                                    small
                                    text
                                    type="primary"
                                    onClick={() => setShowManagePriceSchedule(true)}
                                >
                                    Manage
                                </Button>
                            )}
                        </div>

                        <div className="flex flex-1 text-sm">
                            <Dropdown
                                options={CLINIC_PRICING_OPTIONS}
                                label="Pricing Schedule"
                                value={selectedPricingScheduleId || defaultPricingSchedule.clinicPriceScheduleId || null}
                                name="clinicPriceScheduleId"
                                onChange={handleChangePricingSchedule}
                                placeholder="--Select Pricing Schedule--"
                                fullWidth
                                hidePlaceholder
                            />
                        </div>
                    </div>
                )}
            </div>
            <WellnessTable
                clinicId={props.clinicId}
                typeId={props.typeId}
                pricingScheduleId={selectedPricingScheduleId || defaultPricingSchedule.clinicPriceScheduleId || null}
                clinicTaxId={selectedClinicTaxId ? selectedClinicTaxId : defaultClinicTax.clinicTaxId || null}
            />
            <Modal
                show={showManageClinicTax}
                onClose={() => setShowManageClinicTax(false)}
                mediumSmall
                modalTitle="Edit Clinic Tax History"
            >
                <ManageClinicTaxForm
                    clinicId={props.clinicId}
                    onSubmit={handleSubmitClinicTaxForm}
                    onCancel={() => setShowManageClinicTax(false)}
                />
            </Modal>
            <Modal
                show={showManagePriceSchedule}
                onClose={() => setShowManagePriceSchedule(false)}
                mediumSmall
                modalTitle="Edit Clinic Pricing Schedules"
            >
                <ManageClinicPriceSchedule
                    clinicId={props.clinicId}
                    // onSubmit={handleSubmitPriceScheduleForm}
                    onCancel={() => setShowManagePriceSchedule(false)}
                />
            </Modal>
        </div>
    )
}

WellnessMapping.propTypes = {
    clinicId:PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        // Permissions
        const canViewClinicWellnessPriceSchedules = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_PRICE_SCHEDULES, userProfile);
        const canEditClinicWellnessPriceSchedules = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_PRICE_SCHEDULES, userProfile);

        return {
            products: orderBy(state.entities.wellnessProducts, ["serviceTypeName"]),
            taxRates: state.entities.taxRates[ownProps.clinicId],
            pricingSchedules: state.entities.pricingSchedules[ownProps.clinicId] || {},
            working: !!state.applicationState.working,
            // Permissions
            canViewClinicWellnessPriceSchedules,
            canEditClinicWellnessPriceSchedules
        }
    },
    (dispatch) => ({
        loadWellness: () => dispatch(MappingActions.loadWellness()),
        loadWellnessMappings: (clinicId) => dispatch(MappingActions.loadWellnessMappings(clinicId)),
        loadTaxRates: (clinicId) => dispatch(ClinicActions.loadTaxRates(clinicId)),
        loadPricingSchedule: (clinicId) => dispatch(ClinicActions.loadPricingSchedule(clinicId)),
        batchUpdateClinicTax: (data) => dispatch(ClinicActions.batchUpdateClinicTax(data)),
    }),
)(WellnessMapping);
