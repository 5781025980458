
export function getCouponCount(stats) {
    return stats?.CouponsPending?.value || 0;
}

export function getNewOfferCount(stats) {
    return stats?.NewLibraryOffers?.value || 0;
}

export function getStrayCouponCount(stats) {
    return stats?.StrayCoupons?.value || 0;
}

export function getWellnessCount(stats) {
    return stats?.WellnessPending?.value || 0;
}

export function getUnreadNotificationCount(stats) {
    return stats?.UnreadNotifications?.value || 0;
}