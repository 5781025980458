import React  from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import styles from "./ToolbarClinicLinks.scss"
import map from "lodash/map";
import * as AdminApi from "api/AdminApi";
import * as AdminActions from "actions/AdminActions";
import * as UserActions from "actions/UserActions";
import Button from "components/common/Button";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import LocalData from "utils/LocalData";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ToolbarClinicLinks(props) {
    const ADMIN_ICON_BUTTONS = [{
        link: "settings",
        icon: "edit",
        description: "Edit Clinic Settings"
    }, {
        link: "impersonate",
        icon: "eye",
        description: "Impersonate Clinic Admin"
    }, {
        link: "providerSelection",
        icon: "cog",
        description: "Provider Setup"
    }, {
        link: "pharmacyMapping",
        icon: "barcode",
        description: "Mapping"
    }, {
        link: "programsDashboard",
        icon: "user-md",
        description: "Programs Dashboard"
    }, {
        link: "productTags",
        icon: "tags",
        description: "Clinic Product Tags"
    }, {
        link: "pimsUsers",
        icon: "user-circle",
        description: "Manage PIMS Users"
    }, {
        link: "invoiceLineItems",
        icon: "file-invoice-dollar",
        description: "Clinic Invoice Line Items"
    }, {
        link: "par",
        icon: "circle-exclamation-check",
        description: "(PAR) Pre-Audit Review"
    }];

    const PROVIDER_ICON_BUTTONS = [{
        link: "couponHistory",
        icon: "ticket",
        description: "Coupon History"
    }, {
        link: "couponLibrary",
        icon: "cut",
        description: "Coupon Library"
    }];

    const shouldDisable = (link) => {
        const clinic = props.clinic;
        if (link === "impersonate"){
            //If there is a clinic Admin then the impersonate button is enabled
            return props.canImpersonateAccount && !(clinic && clinic.clinicAdminUserName && clinic.clinicAdminUserId && ((clinic.clinicAdminFirstName && clinic.clinicAdminLastName) || clinic.clinicAdminFullName))
        } else {
            let disabled = false;
            if(link === "couponHistory") {
                disabled = !props.canViewCouponAudit
            } else if(link === "par") {
                disabled = !props.canViewPreAuditReview
            }
            if(props.isAdmin) {
                return disabled || ClinicAdminLinks.getLocationLinkMatch(link, props.clinicId, props.location.pathname);
            } else {
                return disabled || ClinicProviderLinks.getLocationLinkMatch(link, props.nodeId, props.clinicId, props.location.pathname);
            }

        }

    }

    const goToLink = (link) => {
        if (link === "impersonate" && props.clinic) {
            if(props.canImpersonateAccount) {
                props.setRecentUser({
                    id: props.clinic.clinicAdminUserId,
                    username: props.clinic.clinicAdminUserName,
                    fullName: `${props.clinic.clinicAdminFirstName} ${props.clinic.clinicAdminLastName}`,
                    clinicId: props.clinic.clinicId,
                    locationType: null,
                    territoryCode: null,
                    timeStamp: new Date()
                });
                Sentry.setContext("impersonating", {
                    originalUserId: props.userProfile.id,
                    originalUserRoles: props.userProfile.roles,
                });
                props.setUnknownUser(); // This clears the current user from state.
                // Sentry.captureMessage("Impersonation Initiated");
                AdminApi.impersonateUser(props.clinic.clinicAdminUserName)
                    .then(res => {
                        LocalData.setToken(res.body.token);
                        props.impersonateUser(res.body.token);
                        props.history.push("/");
                    })
                    .catch(error => {
                        handleErrorResponse("attempting to impersonate", error);
                    });
            }
        } else {
            if(props.isAdmin) {
                props.history.push(ClinicAdminLinks.getLinkLocation(link, props.clinicId));
            } else {
                props.history.push(ClinicProviderLinks.getLinkLocation(link, props.nodeId, props.clinicId));
            }
        }
    }

    const renderIconButton = ({link, icon, description=""}) => (
        <Button
            key={link}
            type="primary"
            text
            iconOnly
            disabled={shouldDisable(link)}
            onClick={() => goToLink(link)}
            title={description}
        >
            <i className={`fas fa-${icon}`}/>
        </Button>
    );

    return (
        <div className={styles.root}>
            {props.isAdmin ? (
                <div className={styles.iconButtons}>
                    {map(ADMIN_ICON_BUTTONS, button => renderIconButton(button))}
                </div>
            ) : (
                <div className={styles.iconButtons}>
                    {map(PROVIDER_ICON_BUTTONS, button => renderIconButton(button))}
                </div>
            )}
        </div>
    );
}

ToolbarClinicLinks.propTypes = {
    clinicId: PropTypes.number,
    nodeId: PropTypes.number,
    isAdmin: PropTypes.bool,
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        // Permissions
        const canImpersonateAccount = userHasPermission(PermissionTypes.IMPERSONATE, UserPermissions.ACCOUNT, userProfile);
        const canViewCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canViewPreAuditReview = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRE_AUDIT_REVIEW, userProfile);
        return {
            userProfile,
            canViewCouponAudit: canViewCouponAudit || canViewCorporateGroupClinic,
            clinic: state.entities.clinicDetails[ownProps.clinicId],
            // Permissions
            canImpersonateAccount,
            canViewPreAuditReview,
        }
    },
    (dispatch) => ({
        setUnknownUser: () => dispatch(UserActions.setUserUnknown()),
        impersonateUser: (token) => dispatch(AdminActions.impersonateUser(token)),
        setRecentUser: (userInfo) => dispatch(UserActions.setRecentUser(userInfo)),
    })
);

export default compose(
    withRouter,
    connector
)(ToolbarClinicLinks);
