import React, { useCallback, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./NavSearchBox.scss";
import debounce from "lodash/debounce";
import * as AdminActions from "actions/AdminActions";
import * as WellnessActions from "actions/WellnessActions";
import * as CouponActions from "actions/CouponActions";
import * as UserActions from "actions/UserActions";
import * as ClinicActions from "actions/ClinicActions";
import * as VetCheckActions from "actions/VetCheckActions";
import Button from "components/common/Button";
import SearchResults from "components/layout/widgets/SearchResults";
import TextBox from "components/common/TextBox";
import { getClinicWellnessStatus, getClinicInfo } from "utils/ClinicData";
import logger from "utils/logger";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

export function NavSearchBox(props) {
    const {
        inputType = "search",
        autoFocus = true,
    } = props;
    const [searchInput, setSearchInput] = useState("");

    useEffect(() => {
        props.clearAllSearches();
    }, [props.searchConfig]);

    const clear = useCallback(debounce((value) => {
        clearSearchQuery();
    }, 500), []);

    const updateSearchValue = ({ value }) => {
        if (!value || value === "") {
            setSearchInput(null);
            clear();
        } else {
            setSearchInput(value);
            if (!props.searchConfig.submitButtonLabel) {
                search(value);
            }
        }
    };

    useEffect(() => {
        if (props.forceSearch !== null) {
            setSearchInput(props.forceSearch);
            updateSearchValue({value: props.forceSearch});
            props.clearForceSearch(null);
        }
    }, [props.forceSearch])

    if(!props.searchConfig){
        return null;
    }

    const search = useCallback(debounce((value) => {
        performSearch(value);
    }, 500), []);

    const performSearch = (searchQuery) => {
        switch (props.searchConfig.type) {
            case SearchTypes.COUPONS.type:
                props.searchCoupons(searchQuery);
                return;
            case SearchTypes.WELLNESS_VISITS.type:
            case SearchTypes.WELLNESS_AUDIT.type:
                props.searchWellnessHistory(searchQuery);
                return;
            case SearchTypes.WELLNESS_PLANS.type:
            case SearchTypes.WELLNESS_PLANS_PAGE.type:
            case SearchTypes.WELLNESS_REPROCESSING_SEARCH.type:
                if(props.canViewClinicWellnessPetPlans) {
                    props.searchWellnessPlansByName(searchQuery, props.clinicId);
                }
                return;
            case SearchTypes.VOUCHER_CODE.type:
                // Check the validity of the code
                if (searchQuery !== "" && searchQuery.length > 4) {
                    props.verifyVoucherCode(searchQuery, props.clinicId);
                }
                return;
            case SearchTypes.DASHBOARD_CLINIC_SEARCH.type:
            case SearchTypes.TOOLBAR_CLINIC_SEARCH.type:
                if (props.canViewClinicManagement) {
                    props.searchClinicsByName(searchQuery);
                }
                return;
            case SearchTypes.REPROCESSING_JOBS_SEARCH.type:
                props.searchCouponsForReprocessing(searchQuery);
                return;
            case SearchTypes.CLINIC_PRODUCT_TAGS_SEARCH.type:
                props.searchClinicProductTags(searchQuery);
                return;
            case SearchTypes.VETCHECK_HANDOUTS_SENT.type:
                props.searchVetCheckHandouts(searchQuery);
                return;
            case SearchTypes.VETCHECK_PET_OWNERS.type:
                props.searchVetCheckPetOwners(searchQuery);
                return;
            case SearchTypes.CLINIC_SEARCH.type:
                props.searchClinicManagement(searchQuery);
                return;
            case SearchTypes.USER_SEARCH.type:
                props.searchUserManagement(searchQuery);
                return;
            case SearchTypes.INVOICE_LINE_ITEM.type:
            case SearchTypes.PROVIDER_CLINICS_SEARCH.type:
            case SearchTypes.PIMS_USER_SEARCH.type:
            case SearchTypes.PRE_AUDIT_REVIEW.type:
            case SearchTypes.VETCHECK_CLIENT_FORMS_SENT.type:
            case SearchTypes.VETCHECK_CHARTS_SENT.type:
            case SearchTypes.WELLENSS_PET_PLAN_TRACKING.type:
            default:
                props.performGenericSearch(searchQuery);
                break;
        }
    };

    const clearSearchQuery = () => {
        switch (props.searchConfig.type) {
            case SearchTypes.COUPONS.type:
                props.clearCouponsSearch();
                break;
            case SearchTypes.WELLNESS_VISITS.type:
            case SearchTypes.WELLNESS_AUDIT.type:
                props.clearWellnessHistorySearch();
                break;
            case SearchTypes.WELLNESS_PLANS.type:
            case SearchTypes.WELLNESS_PLANS_PAGE.type:
                props.clearWellnessPlansSearch();
                break;
            case SearchTypes.VOUCHER_CODE.type:
                // Do nothing
                break;
            case SearchTypes.DASHBOARD_CLINIC_SEARCH.type:
            case SearchTypes.TOOLBAR_CLINIC_SEARCH.type:
                props.clearClinicsSearch();
                break;
            case SearchTypes.REPROCESSING_JOBS_SEARCH.type:
                props.clearCouponsForReprocessingSearch();
                break;
            case SearchTypes.CLINIC_PRODUCT_TAGS_SEARCH.type:
                props.clearClinicProductTagsSearch();
                return;
            case SearchTypes.VETCHECK_PET_OWNERS.type:
                props.clearVetCheckPetaOwnersSearch();
                return;
            case SearchTypes.VETCHECK_HANDOUTS_SENT.type:
                props.clearVetCheckHandoutsSearch();
                return;
            case SearchTypes.CLINIC_SEARCH.type:
                props.clearClinicManagementSearch();
                return;
            case SearchTypes.USER_SEARCH.type:
                props.clearUserManagementSearch();
                return;
            case SearchTypes.PIMS_USER_SEARCH.type:
            case SearchTypes.PRE_AUDIT_REVIEW.type:
            case SearchTypes.VETCHECK_CLIENT_FORMS_SENT.type:
            case SearchTypes.VETCHECK_CHARTS_SENT.type:
            default:
                props.clearGenericSearch();
                break;
        }
        setSearchInput("");
    };

    const clearSearch = () => {
        clearSearchQuery();
        setSearchInput("");
        search("");
    };

    const handleSearchSubmitClick = () => {
        performSearch(searchInput); // Don't need no stinkin' debounce here
    };

    const changeClinicLocation = (value) => {
        if(props.onResultsClick) {
            props.onResultsClick(value);
        }
    }

    const clickHandler = (value) => {
        switch (props.searchConfig.type) {
            case SearchTypes.WELLNESS_PLANS.type:
                return props.selectWellnessPlan(value);
            case SearchTypes.DASHBOARD_CLINIC_SEARCH.type:
            case SearchTypes.TOOLBAR_CLINIC_SEARCH.type:
                return changeClinicLocation(value);
            default:
                return logger.error("Unhandled click: ", value);
        }
    };

    const shouldShowResults = () =>  {
        if(!props.searchConfig.results) {
            return false;
        }

        if(props.searchConfig.type === SearchTypes.WELLNESS_PLANS.type) {
            return !props.selectedPlan && !!searchInput;
        } else {
            return !!searchInput;
        }
    };

    const shouldShowDetails = () => {
        return (props.searchConfig.type === SearchTypes.WELLNESS_PLANS.type ||
            props.searchConfig.type === SearchTypes.WELLNESS_PLANS_PAGE.type) && !!props.selectedPlan;
    };

    // if(!props.clinicInfo) {
    //     return null;
    // }

    const { placeholder } = props.searchConfig;
    const showDetails = shouldShowDetails();
    const showResults = shouldShowResults();

    return (
        <div
            data-testid="nav_search_box_component"
            className={classnames(styles.root, {
                [styles.collapsable] : props.collapsable,
                [styles.adminToolbar] : props.toolbar,
            })}
        >
            {!!props.searchConfig.submitButtonLabel &&
            <Button
                data-testid="submit_button"
                className="margin-left-x-sm"
                onClick={handleSearchSubmitClick}
                small
            >
                {props.searchConfig.submitButtonLabel}
            </Button>
            }
            <TextBox
                name="searchValue"
                value={searchInput}
                inputType={inputType}
                onChange={updateSearchValue}
                placeholder={placeholder}
                frameless
                disabled={props.searchDisabled}
                autoFocus={autoFocus}
                isSearch
            />
            {props.loading && <div className={styles.loader}><span>LOADING</span><i className="fas fa-spinner fa-spin" /></div>}
            {!!showResults &&
            <SearchResults
                results={props.results}
                clickHandler={clickHandler}
                clearResults={clearSearch}
                isWellness={props.searchConfig.type === SearchTypes.WELLNESS_PLANS.type}
                isClinics={props.searchConfig.type === SearchTypes.TOOLBAR_CLINIC_SEARCH.type}
                positionTop={props.resultsTop}
            />
            }
        </div>
    );
}

NavSearchBox.propTypes = {
    searchConfig: PropTypes.shape({
        icon: PropTypes.string,
        type: PropTypes.string.isRequired,
        placeholder: PropTypes.string.isRequired,
        results: PropTypes.bool,
        submitButtonLabel: PropTypes.string,
    }), // If this object has a "submitButtonLabel" property, we will show a submit button and not debounce input
    clinicId: PropTypes.number,
    resultsTop: PropTypes.bool,
    onResultsClick: PropTypes.func,
    toolbar: PropTypes.bool,
    collapsable: PropTypes.bool,
    searchDisabled: PropTypes.bool,
    inputType: PropTypes.string,
    autoFocus: PropTypes.bool
};

const connector = connect(
    (state, ownProps) => {
        const searchingFor = (searchConfig) => {
            if(searchConfig) {
                switch (searchConfig.type) {
                    case SearchTypes.WELLNESS_PLANS.type:
                        return state.entities.wellness?.searchResults || [];
                    case SearchTypes.TOOLBAR_CLINIC_SEARCH.type:
                    case SearchTypes.DASHBOARD_CLINIC_SEARCH.type:
                        return state.entities.searchClinics;
                    default:
                        return null;
                }
            } else return null;
        };
        const results = searchingFor(ownProps.searchConfig);
        const loading = state.entities.wellness?.loading || false;
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewClinicWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);

        return {
            clinicInfo: getClinicInfo(state, ownProps.clinicId),
            useWellness: getClinicWellnessStatus(state),
            results,
            loading,
            canViewClinicManagement,
            canViewClinicCouponLibrary,
            canViewClinicWellnessPetPlans,
            isImpersonated: state.user.isImpersonated || false,
            selectedPlan: state.entities.wellness.selectedId,
            voucher: state.entities.registration.data,
            providers: state.entities.providers,
            primaryClinic : state.user.userProfile.primaryClinic,
            reprocessingJobsSearch: state.entities.reprocessingJobs.query,
            forceSearch: state.entities.forceSearch,
        }
    },
    (dispatch) => ({
        currentUserLoaded: (user) => dispatch(UserActions.currentUserLoaded(user)),
        vetCheckStatusLoaded: (status) => dispatch(VetCheckActions.vetCheckStatusLoaded(status)),
        selectWellnessPlan: (id) => dispatch(WellnessActions.selectWellnessPlan(id)),
        clearSelectedWellnessPlan: () => dispatch(WellnessActions.clearSelectedWellnessPlan()),
        searchWellnessPlansByName: (query, clinicId) => dispatch(WellnessActions.searchWellnessPlansByName(query, clinicId)),
        searchWellnessHistory: (query) => dispatch(WellnessActions.searchWellnessHistory(query)),
        clearWellnessHistorySearch: () => dispatch(WellnessActions.clearWellnessHistorySearch()),
        clearWellnessPlansSearch: () => dispatch(WellnessActions.clearWellnessPlansSearch()),
        searchCoupons: (query) => dispatch(CouponActions.searchCoupons(query)),
        clearCouponsSearch: () => dispatch(CouponActions.clearCouponsSearch()),
        searchUsers: (query) => dispatch(UserActions.searchUsers(query)),
        clearUsersSearch: () => dispatch(UserActions.clearUsersSearch()),
        searchClinicsByName: (query) => dispatch(ClinicActions.searchClinicsByName(query)),
        clearClinicsSearch: () => dispatch(ClinicActions.clearClinicsSearch()),
        verifyVoucherCode: (code, clinicId) => dispatch(ClinicActions.verifyVoucherCode(code, clinicId)),
        programAddedToClinic: () => dispatch(ClinicActions.programAddedToClinic()),
        programsLoaded: (programs) => dispatch(ClinicActions.programsLoaded(programs)),
        clinicDetailsLoaded: (clinic) => dispatch(ClinicActions.clinicDetailsLoaded(clinic)),
        searchCouponsForReprocessing: (query) => dispatch(CouponActions.searchCouponsForReprocessing(query)),
        clearCouponsForReprocessingSearch: (query) => dispatch(CouponActions.clearCouponsForReprocessingSearch(query)),
        clearVoucherSearch: () => dispatch(ClinicActions.clearVoucherCode()),
        performGenericSearch: (searchTerms) => dispatch(AdminActions.performGenericSearch(searchTerms)),
        clearGenericSearch: () => dispatch(AdminActions.clearGenericSearch()),
        searchClinicProductTags: (query) => dispatch(ClinicActions.searchClinicProductTags(query)),
        clearClinicProductTagsSearch: () => dispatch(ClinicActions.clearClinicProductTagsSearch()),
        searchVetCheckPetOwners: (query) => dispatch(VetCheckActions.searchVetCheckPetOwners(query)),
        clearVetCheckPetaOwnersSearch: () => dispatch(VetCheckActions.clearVetCheckPetaOwnersSearch()),
        searchClinicManagement: (query) => dispatch(ClinicActions.searchClinicManagement(query)),
        clearClinicManagementSearch: () => dispatch(ClinicActions.searchClinicManagement(null)),
        searchUserManagement: (query) => dispatch(UserActions.searchUserManagement(query)),
        clearUserManagementSearch: () => dispatch(UserActions.searchUserManagement(null)),
        searchVetCheckHandouts: (query) => dispatch(VetCheckActions.searchVetCheckHandouts(query)),
        clearVetCheckHandoutsSearch: () => dispatch(VetCheckActions.clearVetCheckHandoutsSearch()),
        clearForceSearch: (value) => dispatch(AdminActions.setForceSearchValue(value)),
        clearAllSearches: () => dispatch(UserActions.clearAllSearches()),
    })
);

export default compose(
    withRouter,
    connector
)(NavSearchBox);
