import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import {useHistory, withRouter} from "react-router";
import PropTypes from "prop-types";
import find from "lodash/find";
import get from "lodash/get";
import Button from "components/common/Button";
import SortableDataTable from "components/common/SortableDataTable";
import * as ProviderLinks from "utils/ProviderLinks";
import * as OrgNodeTypes from "constants/OrgNodeTypes";

    const TERRITORY_GAMES_COLUMNS = [
        {
            name: (<div className="text-center">Challenge Match Games</div>),
            format: row => (
                <div className="text-center">{get(row, "stats.games.deathmatch.current", "-")}</div>
            ),
        }, {
            name: (<div className="text-center">Growth Dose Games</div>),
            format: row => (
                <div className="text-center">{get(row, "stats.games.growthdoses.current", "-")}</div>
            ),
        }, {
            name: (<div className="text-center">On Gard Games</div>),
            format: row => (
                <div className="text-center">{get(row, "stats.games.ongard.current", "-")}</div>
            ),
        }, {
            name: (<div className="text-center">Games Ending in 30 Days</div>),
            selector: "gamesEndingIn30Days",
            key: "gamesEndingIn30Days",
            format: row => (
                <div className="text-center">{get(row, "stats.games.endIn30", "-")}</div>
            ),
        }
    ]

function ProviderLocationTable(props) {
    const history = useHistory();

    if (!props.locations?.length){
        return (
            <div>
                No Child Locations
            </div>
        );
    }

    const nodeType = props.locations[0]?.nodeType;

    const handleGoToTerritoryGames = (e, providerId, nodeId) => {
        e.stopPropagation();
        e.preventDefault();
        history.push(ProviderLinks.getLinkLocation("games", {
            providerId,
            nodeId,
            isAdmin: props.isAdmin
        }));
    }

    const USER_ID_COLUMN = [{
        name: "",
        selector: "userId",
        key: "userId",
        format: row => {
            if(row.providerId && row.nodeId) {
                const hasGames = find(props.nodeHierarchy[row.providerId], {nodeId: row.nodeId})?.hasGames;
                return (
                    <div>
                        {hasGames && (
                            <Button
                                type="success"
                                iconOnly
                                text
                                onClick={(e) => handleGoToTerritoryGames(e, row.providerId, row.nodeId)}
                            >
                                <i className="fa fa-trophy-alt margin-right-sm text-success"/>
                            </Button>
                        )}
                    </div>
                );
            } else return null;
        },
    }];

    const getNameColumnName = () => {
        if(nodeType === OrgNodeTypes.REGION){
            return "Director";
        } else if(nodeType === OrgNodeTypes.DISTRICT) {
            return "Manager";
        } else {
            return "Rep";
        }
    }

    const NAME_COLUMN = [{
        name: getNameColumnName(),
        selector: "name",
        key: "name",
    }];

    const CODE_COLUMN = [{
        name: `${nodeType}`,
        selector: "code",
        key: "code",
    }];

    const MINIMAL_COLUMNS = [
        ...(nodeType === OrgNodeTypes.TERRITORY) ? USER_ID_COLUMN : [],
        ...NAME_COLUMN,
        ...CODE_COLUMN,
        {
            name: "Total Clinics",
            selector: "totalActiveClinics",
            key: "totalActiveClinics",
            format: row => get(row, "stats.clinics.goGreen", "-"),
        }
    ];

    const GAMES_COLUMNS = [
        ...(nodeType === OrgNodeTypes.TERRITORY) ? TERRITORY_GAMES_COLUMNS : [],
        {
            name: (<div className="text-center">Total Games</div>),
            selector: "totalGames",
            key: "totalGames",
            //Should this be current + historic?
            format: row => (
                <div className="text-center">
                    {get(row, "stats.games.current", "-")}
                </div>
            ),
        }
    ];

    const ALL_COLUMNS = [
        ...MINIMAL_COLUMNS,
        ...(props.showGamesColumns ? GAMES_COLUMNS : []),
    ];

    return (
        <SortableDataTable
            striped
            green
            hideSearchBar
            title={props.title}
            resultsPerPage={props.resultsPerPage || 10}
            downloadPDF={props.downloadPDF}
            columns={props.showAllColumns ? ALL_COLUMNS : MINIMAL_COLUMNS}
            rawData={props.locations}
            onClick={props.onRowClick}
            onHoverStart={props.onRowHoverStart}
            onHoverEnd={props.onRowHoverEnd}
            forceHover={[props.hovering]}
        />
    );
}

ProviderLocationTable.propTypes = {
    onRowClick:PropTypes.func.isRequired,
    onRowHoverStart:PropTypes.func,
    onRowHoverEnd:PropTypes.func,
    locations: PropTypes.array,
    resultsPerPage: PropTypes.number,
    showAllColumns: PropTypes.bool,
    showGamesColumns: PropTypes.bool,
    isAdmin: PropTypes.bool,
    hovering: PropTypes.number
}

ProviderLocationTable.defaultProps = {
    showAllColumns: true,
}

const connector = connect(
    (state) => ({
        nodeHierarchy: state.entities.providerHierarchies,
    }),
);

export default compose(
    withRouter,
    connector
)(ProviderLocationTable);
