import React from "react";
import * as styles from "./Maintenance.scss";
import Image from "components/common/Image";

const GREENLINE_TOP_LOGO = require("../../../../img/greenline-top-logo.png");

export default function Maintenance(props) {
    return (
        <div
            data-testid="maintenance-component"
            className={styles.root}
        >
            <div className="flex-1 flex-centered">
                <Image src={GREENLINE_TOP_LOGO} alt="Greenline Logo" style={{maxWidth: "100%"}}/>
            </div>
            <div className={styles.message}>We are currently down for planned maintenance, please try back later.</div>
            <div className="flex-2 full-width full-height"/>
        </div>
    );
}
