import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ClinicFilter.scss";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import Dropdown from "components/common/Dropdown";
import TextBox from "components/common/TextBox";
import DateInput from "components/common/DateInput";
import MultiSelectDropdown from "components/common/Dropdown/MultiSelectDropdown";
import { FILTERS, FILTERS_OPTIONS } from "constants/ClinicFilterConfigs";
import ClinicFilterShort from "components/admin/elements/ClinicFilter/ClinicFilterShort";
import * as FilterTypes from "constants/FilterTypes";

function ClinicFilter(props) {
    const filterRef = React.useRef();
    const [showFullFilter, setShowFullFilter] = useState(props.isNew || false);
    const [formData, setFormData] = useState(props.fil);

    const PROVIDER_OPTIONS = map(orderBy(props.providers, "name"), p => {return {name: `${p.name}${!!p.shortName ? ` (${p.shortName})` : ""}`, value: p.id}});
    const ACTIVE_OFFERS_OPTIONS = map(orderBy(props.activeOffers, "display"), offer => {return {name: offer.display, value: offer.value}});
    const PROVIDER_REPORT_OPTIONS = map(orderBy(props.providerReports, "display"), report => {return {name: report.display, value: report.value}});
    const PIMS_SOFTWARE_OPTIONS = map(orderBy(props.softwareVendors, "name"), s => {return {name: s.name, value: s.softwareVendorId}});

    useEffect(() => {
        setFormData(props.fil);
    }, [props.fil]);

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClick);

        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

    const handleClick = (e) => {
        if (filterRef.current.contains(e.target)) {
            // inside click
            setShowFullFilter(true);
        } else {
            setShowFullFilter(false);
        }
    };

    const handleChangeFilter = ({name, value}) => {
        let tempFilter = {...formData};
        if(name === "id") {
            tempFilter = {
                "order": formData.order,
                [name]: value,
            }
        } else if (name === "level1Values" || name === "level2Values") {
            if (Array.isArray(value)) {
                tempFilter = {
                    ...tempFilter,
                    [name]: map(value, v => {
                        return {value: v}
                    }),
                }
            } else {
                tempFilter = {
                    ...tempFilter,
                    [name]: [{value}],
                }
            }
        } else {
            tempFilter = {
                ...tempFilter,
                [name]: value,
            }
        }

        const isComplete = !(!tempFilter.id || !(tempFilter.stringValue || tempFilter.numberValue || tempFilter.dateValue || (tempFilter.level1Values && tempFilter.level1Values.length)) || !tempFilter.equality || (tempFilter.id === FilterTypes.PROVIDER_ID && !(tempFilter.level2Values && tempFilter.level2Values.length)));
        tempFilter = {
            ...tempFilter,
            isComplete,
        }
        setFormData(tempFilter);
        if(tempFilter.isComplete) {
            props.handleChangeFilters(tempFilter);
        }
    }

     const getValues = (list) => {
        return map(list, (li) => {
            return li.value;
        });
    }

    const getValueType = () => {
        switch(FILTERS[formData.id].type) {
            case "string":
                return(
                    <TextBox
                        name="stringValue"
                        value={formData.stringValue}
                        onChange={handleChangeFilter}
                    />
                );
            case "number":
                return(
                    <TextBox
                        name="numberValue"
                        value={formData.numberValue}
                        inputType="number"
                        onChange={handleChangeFilter}
                    />
                );
            case "doubleSelect":
                return (
                    <Dropdown
                        placeholder={`--Select Status--`}
                        options={FILTERS[formData.id].level2.options}
                        name="level2Values"
                        value={formData.level2Values ? formData.level2Values[0].value : null}
                        hidePlaceholder
                        onChange={handleChangeFilter}
                    />
                );
            case "select":
                //Get the Options for the Selected Filter
                let OPTIONS = [];
                switch (formData.id) {
                    case FilterTypes.CLOO_ID:
                        OPTIONS = ACTIVE_OFFERS_OPTIONS;
                        break;
                    case FilterTypes.PROVIDER_REPORT_ID:
                        OPTIONS = PROVIDER_REPORT_OPTIONS;
                        break;
                    case FilterTypes.PIMS:
                        OPTIONS = PIMS_SOFTWARE_OPTIONS;
                        break;
                    default:
                        OPTIONS = FILTERS[formData.id].level1.options
                        break;
                }
                // Display the correct kind of dropdown
                switch(FILTERS[formData.id].level1.type) {
                    case "dropdown":
                        return (
                            <Dropdown
                                placeholder={`--Select--`}
                                options={OPTIONS}
                                name="level1Values"
                                value={formData.level1Values ? formData.level1Values[0].value : null}
                                hidePlaceholder
                                onChange={handleChangeFilter}
                            />
                        );
                    case "multiSelect":
                        return (
                            <MultiSelectDropdown
                                placeholder={`--Select--`}
                                options={OPTIONS}
                                name="level1Values"
                                value={getValues(formData.level1Values)}

                                onChange={handleChangeFilter}
                            />
                        );
                    default:
                        return (
                            <TextBox
                                name="stringValue"
                                value={formData.stringValue}
                                disabled={true}
                                onChange={() => {}}
                            />
                        );
                }
            case "date":
                return (
                    <DateInput
                        value={formData.dateValue}
                        name="dateValue"
                        dateFormat={"MM/DD/yyyy"}
                        onChange={handleChangeFilter}
                        showDropdowns
                    />
                );
            default:
                return (
                    <TextBox
                        name="stringValue"
                        value={formData.stringValue}
                        disabled={true}
                        onChange={() => {}}
                    />
                );

        }
    }

    return (
        <div ref={filterRef} className={styles.root}>
            {showFullFilter ? (
                <div className={classnames(styles.filter, "margin-sm flex flex-center spaced-content flex-1")}>
                    <div className={classnames(styles.filterBox, "flex-1")}>
                        <Dropdown
                            placeholder="--Select--"
                            options={FILTERS_OPTIONS || []}
                            name="id"
                            value={formData.id}
                            onChange={handleChangeFilter}
                            hidePlaceholder
                        />
                    </div>
                    {formData.id === FilterTypes.PROVIDER_ID && (
                        <div className={classnames(styles.filterBox, "flex-1")}>
                            <MultiSelectDropdown
                                options={PROVIDER_OPTIONS}
                                name="level1Values"
                                placeholder="--Select Providers--"
                                value={formData.level1Values ? getValues(formData.level1Values) : null}
                                onChange={handleChangeFilter}
                            />
                        </div>
                    )}
                    <div className={classnames(styles.filterBox, "flex-1")}>
                        <Dropdown
                            placeholder="--Select--"
                            options={!!formData.id && FILTERS[formData.id].equalityOptions || []}
                            name="equality"
                            value={formData.equality}
                            onChange={handleChangeFilter}
                            disabled={!formData.id}
                            hidePlaceholder
                        />
                    </div>
                    <div className={classnames(styles.filterBox, "flex-1")}>
                        {!formData.id ? (
                            <TextBox
                                name=""
                                value=""
                                disabled
                                onChange={() => {}}
                            />
                        ) : getValueType()}
                    </div>
                </div>
            ) : (
                <ClinicFilterShort
                    fil={formData}
                    showFullFilter={showFullFilter}
                    providers={props.providers}
                />
            )}
        </div>
    );
}

ClinicFilter.propTypes = {
    fil: PropTypes.object.isRequired,
    handleChangeFilters: PropTypes.func.isRequired,
    isNew: PropTypes.bool,
};

export default connect(
    (state) => ({
        providers: state.entities.providers,
        activeOffers: state.entities.offers,
        providerReports: state.entities.providerReports,
        softwareVendors: state.entities.softwareVendors?.data || {},
    })
)(ClinicFilter);