import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import moment from "moment";
import classnames from "classnames";
import styles from "./PlanTrackingModal.scss";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import * as GreenlineWellnessActions from "actions/GreenlineWellnessActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import InvoiceDetails from "components/common/InvoiceDetails";
import Modal from "components/common/Modal";
import OpenInvoicePDFLinkNewTab from "components/common/OpenInvoicePDFLinkNewTab";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import {DANGER} from "constants/ButtonTypes";
import * as UserPermissions from "constants/UserPermissions";
import * as ServiceType from "constants/WellnessPlanServiceTypeIds";
import CancelPlanForm from "./CancelPlanForm";
import UpdatePaymentForm from "./UpdatePaymentForm";
import * as WellnessApi from "api/WellnessApi";
import PropTypes from "prop-types";
import {handleErrorResponse} from "utils/request";

function PlanTrackingModal(props) {
    const [loading, setLoading] = useState(false);
    const [invoiceId, setInvoiceId] = useState(null);
    const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);

    const [showPaymentUpdate, setShowPaymentUpdate] = useState(false);
    const [showCancel, setShowCancel] = useState(false);

    const [detailedPlan, setDetailedPlan] = useState(null);

    const isExpiring = useMemo(() => {
        if (detailedPlan) {
            const endDate = moment(detailedPlan.planEndDate);
            return endDate.diff(new moment(),"days") <= 30;
        }
        return false;

    }, [detailedPlan]);

    useEffect(() => {
        if (props.canViewClinicWellnessPetPlans && props.selectedId) {
            WellnessApi.getWellnessPlanDetails(props.selectedId)
                .then((res) => {
                    setDetailedPlan(res.body);
                })
                .catch((error) => {
                    handleErrorResponse("loading wellness plan details", error);
                });
        }
    }, [props.selectedId]);

    const renderPlanInfo = (plan) => {
        const petOwnerPhone = plan.petOwnerPhone;
        const phoneNumber = petOwnerPhone ? petOwnerPhone.replace(/[- )(]/g,'') : '';
        const phoneNumberRegular = `tel:+1${phoneNumber}`;
        const email = `mailto:${plan.petOwnerEmail}`;
        return (
            <div className="flex spaced-content flex-wrap align-top padding-bottom-md">
                <div className="flex-1">
                    <div>
                        <span className={styles.label}>Owner:</span>
                        <span className="text-lg">{plan.petOwnerFirstName} {plan.petOwnerLastName}</span>
                    </div>
                    <div className="flex">
                        <div className={styles.label}>Address:</div>
                        <div>
                            <div>{plan.petOwnerStreet}</div>
                            <div>{plan.petOwnerCity}, {plan.petOwnerState} {plan.petOwnerPostalCode}</div>
                        </div>
                    </div>
                    <div>
                        <span className={styles.label}>Phone:</span>
                        <a
                            href={phoneNumberRegular}
                            title="Patient phone number"
                            className="text-primary"
                        >
                            {plan.petOwnerPhone}
                        </a>
                    </div>
                    <div>
                        <span className={styles.label}>Email:</span>
                        <a
                            href={email}
                            title="Patient email"
                            className="text-primary"
                        >
                            {plan.petOwnerEmail}
                        </a>
                    </div>
                </div>
                <div className="flex-1">
                    <div>
                        <span className={styles.label}>Patient:</span>
                        <span className="text-lg">{plan.petName} (ID: {plan.petId})</span>
                    </div>
                    <div>
                        <span className={styles.label}>Plan:</span>
                        {plan.planName}
                    </div>
                </div>
            </div>
        )
    }

    const renderPlanUsage = (details) => {
        if (!details || !details.services) {
            return null;
        }

        const services = groupBy(details.services, "serviceType");

        const row  = (list) => {
            return map(list, data => (
                <tr key={`usage_${data.serviceName}`}>
                    <td>
                        {data.serviceName}
                    </td>
                    <td className="text-center">
                        {data.isUnlimited ? (<i className="far fa-infinity"/>) : data.usesRemaining === 0 ? "None" : data.usesRemaining}
                    </td>
                    <td className="text-center">
                        {data.lastInvoiceDate ? moment(data.lastInvoiceDate).format("MM/DD/YYYY") : ""}
                    </td>
                    <td className="text-center">
                        <OpenInvoicePDFLinkNewTab
                            className="text-primary"
                            invoiceNumber={data.invoiceNumber}
                            invoiceId={data.lastInvoiceId}
                            extendedInvoiceNumber={data.lastExtendedInvoiceNumber}
                        />
                    </td>
                    <td>
                        {data.lastPurchasedProductName}
                    </td>
                    <td>
                        {!!data.nextDispenseDate && moment(data.nextDispenseDate).format("MM/DD/YYYY")}
                    </td>
                </tr>
            ));
        }

        const getServiceIcon = (type) => {
            switch (type) {
                case ServiceType.SERVICES:
                    return <i className="fas fa-user-md"/>;
                case ServiceType.PRODUCTS:
                    return <i className="fas fa-box-open"/>;
                case ServiceType.ADD_ONS:
                    return <i className="fas fa-cart-plus"/>;
                case ServiceType.SPECIAL:
                    return <i className="fas fa-sparkles"/>;
                default:
                    return <i/>;

            }
        };

        const customSort = [ServiceType.SERVICES, ServiceType.PRODUCTS, ServiceType.ADD_ONS, ServiceType.SPECIAL]
        return (
            <div>
                <table>
                    {map(customSort, type => (
                        <>
                            <thead key={`${type}_thead`}>
                                <tr>
                                    <th className={styles.firstTH}>
                                        {getServiceIcon(type)}
                                        {type}
                                    </th>
                                    <th className="text-center">Uses Remaining</th>
                                    <th className="text-center">Last Invoice Date</th>
                                    <th className="text-center">Last Invoice</th>
                                    <th>Last Item Purchased</th>
                                    <th>Next Date</th>
                                </tr>
                            </thead>
                            <tbody key={`${type}_tbody`}>
                                {row(services[type])}
                            </tbody>
                        </>
                    ))}
                </table>
            </div>
        )
    }

    const handleRenewPlan = () => {
        setLoading(true);

        WellnessApi.renewSubscription(props.clinicId, detailedPlan.planInstanceId)
        .then((response) => {
            toast.success("Plan Renewed Successfully");
        })
        .catch((error) => {
            handleErrorResponse("renewing subscription", error);
        })
        .finally(() => {
            setLoading(false);
        });
    };

    const renderPlanDetails = () => {
        const selectedId = props.selectedId;

        if (!selectedId || !props.plans) {
            return <>Loading Details...</>
        }

        if (!detailedPlan) {
            return <>Loading Details...</>
        }

        return (
            <>
                {renderPlanInfo(detailedPlan)}
                <div className={classnames(styles.planStatus, {
                    [styles.grey]: detailedPlan.paymentStatusCode === 'Expired',
                    [styles.active]: detailedPlan.paymentStatusCode === 'Active',
                    [styles.danger]: detailedPlan.paymentStatusCode === 'Past Due',
                })}>
                    <div className={styles.status}>
                        <span className="text-uppercase">Status:</span> {detailedPlan.paymentStatusCode}
                    </div>
                    <div>
                        <span>Last Payment</span> {moment(detailedPlan.lastPaymentDate).format("MM/DD/YYYY")}
                    </div>
                </div>
                <div className={styles.usage}>
                    {renderPlanUsage(detailedPlan)}
                </div>
            </>
        );
    }

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    }

    const handleCloseDetails = () => {
        setInvoiceId(null);
        setShowInvoiceDetails(false);
    }

    return (
        <div>
            <Modal
                show={!showInvoiceDetails}
                onClose={handleClose}
                mediumSmall
                modalTitle={(
                    <div className="flex-none"><i className="fa fa-notes-medical" /> Wellness Plan Details</div>
                )}
            >
                <div id="petPlanTrackingModal" className={styles.root}>
                    <div className={styles.plan}>
                        {!props.canViewClinicWellnessPetPlans ? (
                            <div>
                                <div className="text-xlg">Wellness Plan Details</div>
                                <AccessDenied/>
                            </div>
                        ) : (
                            renderPlanDetails()
                        )}
                    </div>
                    <div className="flex">
                        <div className="flex flex-1 spaced-content">
                            {props.canGLWellness && detailedPlan && !detailedPlan.isCancelled && (
                                <>
                                    <Button
                                        icon
                                        onClick={() => setShowPaymentUpdate(true)}
                                    >
                                        <i className="far fa-credit-card" /> Update Payment
                                    </Button>
                                    {isExpiring && (
                                        <Button
                                            icon
                                            onClick={handleRenewPlan}
                                        >
                                            <i className="far fa-siren-on" /> Renew Plan
                                        </Button>
                                    )}
                                    <Button
                                        icon
                                        type={DANGER}
                                        onClick={() => setShowCancel(true)}
                                    >
                                        <i className="far fa-ban" /> Cancel Plan
                                    </Button>
                                </>
                            )}
                        </div>
                        <Button
                            className="flex-none"
                            type="gray"
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                    </div>
                </div>
            </Modal>
            <Modal
                show={showInvoiceDetails}
                onClose={handleCloseDetails}
                modalTitle="Invoice"
                icon="fa-file-invoice-dollar"
                mediumSmall
            >
                <InvoiceDetails
                    invoiceId={invoiceId}
                    handleClose={handleCloseDetails}
                />
            </Modal>
            <Modal
                show={showCancel}
                onClose={() => setShowCancel(false)}
                modalTitle="Cancel Plan"
                small
            >
                <div className="padding-lg">
                    <CancelPlanForm
                        clinicId={props.clinicId}
                        onAfterSave={() => setShowCancel(false)}
                        subscription={detailedPlan}
                    />
                </div>
            </Modal>
            <Modal
                show={showPaymentUpdate}
                onClose={() => setShowPaymentUpdate(false)}
                modalTitle="Update Payment"
                small
            >
                <div className="padding-lg">
                    <UpdatePaymentForm
                        clinicId={props.clinicId}
                        selectedPlan={detailedPlan}
                        onAfterSave={() => setShowPaymentUpdate(false)}
                    />
                </div>
            </Modal>
            <SpinnerTakeover show={loading} onTop/>
        </div>
    );
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);

        return {
            canViewClinicWellnessPetPlans,
            plans: state.wellness.plans,
        }
    },

    (dispatch) => ({
        getWellnessPlans: (clinicId) => dispatch(GreenlineWellnessActions.getWellnessPlans(clinicId)),
    }),
)(PlanTrackingModal);

PlanTrackingModal.propType = {
    clinicId: PropTypes.number.isRequired,
    canGLWellness: PropTypes.bool,
}
