import React, { useEffect, useMemo, useState } from "react";
import classnames from "classnames";
import styles from "./PuppyBowlGame.scss";
import orderBy from "lodash/orderBy";
import PropTypes from "prop-types";
import DataTable from "components/common/datatable/DataTable";
import Image from "components/common/Image";
import Loader from "components/common/Loader";
import PuppyBowlLeaderboard from "components/games/widgets/PuppyBowlLeaderboard";
import { addCommasToNumber } from "utils/numeric";

const NEXGARD_LARGE_BANNER_AD = require("../../../../../../img/games/nexgard_large_banner_ad.jpg");

export default function PuppyBowlGame(props) {
    const {
        animate = false,
        clinicId,
        game,
        pointValues = {
            fieldGoal: {
                packSize: 3,
                points: 3,
            },
            touchdown: {
                packSize: 6,
                points: 6,
            },
            mvp: {
                packSize: 12,
                points: 14,
            },
        },
        snapshot = false,
    } = props;

    const [gameLoaded, setGameLoaded] = useState(false);

    const clinicsList = useMemo(() => {
        if (game) {
            return orderBy(game.teams, "Place");
        }
        return [];
    }, [game]);

    const isHalftime = useMemo(() => {
        return game?.data?.PointsMultiplier > 1;
    }, [game]);

    useEffect(() => {
        if (game?.teams && clinicsList.length) {
            setGameLoaded(true);
        }
    }, [game, clinicsList]);

    const gameAnimation = useMemo(() => {
        if (gameLoaded) {
            const gameKey = clinicId ? `game_animation_${game.greenlineGameId}_clinic_${clinicId}` :
                `game_animation_${game.greenlineGameId}_day_${game.daysInto}`;
            return (
                <PuppyBowlLeaderboard
                    key={gameKey}
                    currentClinicId={clinicId}
                    data={game.teams}
                    pathToSrcFiles="/plugins/puppy_bowl_leaderboard/"
                    snapshot={snapshot}
                    animate={animate}
                />
            );
        } else {
            return <Loader />;
        }
    }, [gameLoaded]);

    const getConditionalRowProps = (row) => {
        if (!snapshot && (row.clinicId === clinicId || row.ClinicId === clinicId)) {
            return {
                style: {
                    backgroundColor: 'rgb(26, 85, 173)',
                    color: '#74FCF6',
                }
            };
        }
        return {};
    };

    const COLUMNS = [{
        name: "Rank",
        selector: "Place",
        key: "rank",
        sortable: false,
        searchable: false,
        colSettings: { width: "25px"},
    }, {
        name: "Clinic Alias",
        selector: "ClinicAlias",
        key: "clinicAlias",
        sortable: false,
        searchable: false,
        format: row => (
            <span style={{ display: "inline-block", width: "100%", textAlign: "left"}}>{(snapshot && row.name) ? row.name : row.ClinicAlias}</span>
        ),
    }, {
        name: (<div className="text-center">Field Goals</div>),
        selector: "fieldGoals",
        key: "fieldGoals",
        sortable: false,
        searchable: false,
        colSettings: { width: "65px"},
    }, {
        name: (<div className="text-center">Touchdowns</div>),
        selector: "touchdowns",
        key: "touchdowns",
        sortable: false,
        searchable: false,
        colSettings: { width: "65px"},
    }, {
        name: (<div className="text-center">MVPs</div>),
        selector: "mvps",
        key: "mvps",
        sortable: false,
        searchable: false,
        colSettings: { width: "30px"},
    }, {
        name: "Points",
        selector: "points",
        key: "points",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px"},
        format: row => (
            addCommasToNumber(row.points)
        )
    }, {
        name: "Current Doses",
        selector: "totalDoses",
        key: "currentDoses",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px"},
        format: row => (
            addCommasToNumber(row.totalDoses)
        )
    }, {
        name: "Previous Doses",
        selector: "previousDoses",
        key: "previousDoses",
        sortable: false,
        searchable: false,
        colSettings: { width: "30px"},
        format: row => (
            addCommasToNumber(row.previousDoses)
        )
    }, {
        name: "To Go",
        selector: "PercentGrowth",
        key: "packsToGo",
        sortable: false,
        searchable: false,
        colSettings: { width: "200px"},
        format: row => {
            if (row.PercentGrowth < 0 && row.previousDoses > 0) {
                return (
                    <div style={{ textAlign: "left", whiteSpace: "nowrap"}}>
                        <span style={{fontSize: "0.9em"}}>{Math.ceil((row.previousDoses - row.totalDoses) / 3)}</span>
                        <small> (3pk)</small>
                        <span style={{fontWeight: "500", display: "inline-block", margin: "0 0.25em"}}>/</span>
                        <span style={{fontSize: "0.9em"}}>{Math.ceil((row.previousDoses - row.totalDoses) / 6)}</span>
                        <small> (6pk)</small>
                        <span style={{fontWeight: "500", display: "inline-block", margin: "0 0.25em"}}>/</span>
                        <span style={{fontSize: "0.9em"}}>{Math.ceil((row.previousDoses - row.totalDoses) / 12)}</span>
                        <small> (12pk)</small>
                    </div>
                );
            } else {
                return (
                    <span>--</span>
                );
            }
        }
    }, {
        name: "% Growth",
        selector: "PercentGrowth",
        key: "growthPercentage",
        sortable: false,
        searchable: false,
        colSettings: {width: "30px"},
        format: row => {
            let fontColor = "red";
            const roundedPercentGrowth = 100 + Math.round(row.PercentGrowth);
            if (row.PercentGrowth > 0) {
                fontColor = "green";
            }

            if (row.ClinicId === clinicId) {
                fontColor = "#FFFFFF";
            }

            if (isNaN(roundedPercentGrowth)) {
                return (
                    <span style={{ color: fontColor, display: "inline-block", width: "100%", textAlign: "left"}}>0%</span>
                )
            }

            return (
                <span style={{ color: fontColor, display: "inline-block", width: "100%", textAlign: "left"}}>{roundedPercentGrowth}%</span>
            )

        }
    }];

    return (
        <div className={styles.root}>
            <div className={styles.bannerAd}>
                <Image
                    src={NEXGARD_LARGE_BANNER_AD}
                    alt="NexGard® advertisement"
                />
            </div>
            <div className={styles.animation}>
                {gameAnimation}
            </div>
            <div className={styles.pointsLegend}>
                <h3>Points Legend</h3>
                <div className={styles.legendItems}>
                    <div className={styles.legendItem}>
                        <label>Field Goal</label>
                        <span>{isHalftime && <span className={styles.strikeThrough}>{pointValues.fieldGoal.points}</span>}<span className={classnames({ [styles.halfTime]: isHalftime})}>{pointValues.fieldGoal.points * game.data.PointsMultiplier}</span> points for {pointValues.fieldGoal.packSize} <em>NexGard®</em> Chews</span>
                    </div>
                    <div className={styles.legendItem}>
                        <label>Touchdown</label>
                        <span>{isHalftime && <span className={styles.strikeThrough}>{pointValues.touchdown.points}</span>}<span className={classnames({ [styles.halfTime]: isHalftime})}>{pointValues.touchdown.points * game.data.PointsMultiplier}</span> points for {pointValues.touchdown.packSize} <em>NexGard®</em> Chews</span>
                    </div>
                    <div className={styles.legendItem}>
                        <label>MVP</label>
                        <span>{isHalftime && <span className={styles.strikeThrough}>{pointValues.mvp.points}</span>}<span className={classnames({ [styles.halfTime]: isHalftime})}>{pointValues.mvp.points * game.data.PointsMultiplier}</span> points for {pointValues.mvp.packSize} <em>NexGard®</em> Chews</span>
                    </div>
                </div>
            </div>
            <div className={styles.rankingsTable}>
                <DataTable
                    columns={COLUMNS}
                    data={clinicsList}
                    pagination={false}
                    green
                    useColgroup
                    conditionalRowProps={getConditionalRowProps}
                />
            </div>
        </div>
    );
}

PuppyBowlGame.propTypes = {
    game: PropTypes.object.isRequired,
    clinicId: PropTypes.number,
    animate: PropTypes.bool,
    pointValues: PropTypes.object,
    snapshot: PropTypes.bool,
}
