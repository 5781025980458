import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import * as styles from "./NotificationEdit.scss";
import find from "lodash/find";
import keys from "lodash/keys";
import forEach from "lodash/forEach";
import map from "lodash/map";
import merge from "lodash/merge";
import set from "lodash/set";
import * as NotificationsApi from "api/NotificationsApi";
import * as CouponActions from "actions/CouponActions";
import * as NotificationsActions from "actions/NotificationsActions";
import AdminBase from "components/layout/AdminBase";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import DateInput from "components/common/DateInput";
import Dropdown from "components/common/Dropdown";
import Image from "components/common/Image";
import Notification from "components/notifications/widgets/Notification";
import NotificationTile from "components/notifications/elements/NotificationTile";
import RichText from "components/common/RichText";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import ToggleSwitch from "components/common/ToggleSwitch";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import {
    ENROLLED,
    DECLINED,
    CANCELED,
    PENDING,
    BI_GAMES,
    GREENLINE_GIVING,
    LAPSED_USER_OPT_IN,
    MERCK_ADOBE_ONLY,
    AVAILABLE_NOTIFICATION_TYPES
} from "constants/Notifications";

import * as NotificationTemplates from "constants/NotificationTemplates";
import * as UserPermissions from "constants/UserPermissions";
import { mapNotificationFromAppToServer } from "data/serverMapping";

function NotificationEdit(props) {
    const [formData, setFormData] = useState({});
    const [yesOptions, setYesOptions] = useState(NotificationTemplates.DEFAULT_YES_OPTIONS);
    const [noOptions, setNoOptions] = useState(NotificationTemplates.DEFAULT_NO_OPTIONS);
    const [loading, setLoading] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(PENDING);

    useEffect(() => {
        if(!keys(props.providers)?.length) {
            props.getProviderList()
        }
        props.getAllNotifications();
    }, []);

    useEffect(() => {
        if(NotificationTemplates[props.templateId]) {
            setFormData(NotificationTemplates[props.templateId].preFillData);
            setYesOptions(NotificationTemplates[props.templateId].enrollOptions);
            setNoOptions(NotificationTemplates[props.templateId].declineOptions);
        } else {
            setFormData({});
            setYesOptions(NotificationTemplates.DEFAULT_YES_OPTIONS);
            setNoOptions(NotificationTemplates.DEFAULT_NO_OPTIONS);
        }
    }, [props.templateId]);

    const combinedData = {
        ...props.notification,
    };

    merge(combinedData, formData);

    const requiredFields = (combinedData?.notificationType === LAPSED_USER_OPT_IN || combinedData?.notificationType === MERCK_ADOBE_ONLY) ?
        ["partnerProgramIdentifier", "partnerProgramName", "providerId", "shortDescription"] :
        ["partnerProgramIdentifier", "longDescription", "partnerProgramName", "shortDescription", "providerId"];

    const isCustomNotificationTemplate = (combinedData?.notificationType === LAPSED_USER_OPT_IN || combinedData?.notificationType === MERCK_ADOBE_ONLY);

    const getCurrentState = () => {
        return combinedData?.[currentStatus]?.displayText || "";
    }

    const NOTIFICATION_TYPES = map(AVAILABLE_NOTIFICATION_TYPES, (f) => {
        return {
            name: f,
            value: f
        }
    });

    const PROVIDER_OPTIONS = map(props.providers, p => {
        return {
            name: p.name,
            value: p.id
        }
    })

    const handleChangeDropdownValue = ({name, value}, customName) => {
        const newFormData = {...formData};
        if(value === "Custom") {
            set(newFormData, name, "");
            set(newFormData, customName, true);
        } else {
            set(newFormData, name, value);
            set(newFormData, customName, false);

        }
        setFormData(newFormData);
    };

    const handleChange = ({name, value}) => {
        const newFormData = {...formData};
        set(newFormData, name, value);
        setFormData(newFormData);
    }

    const handleCancel = () => {
        props.history.push("/admin/notifications");
    };

    const formIsValid = () => {
        let valid = true;
        forEach(requiredFields, r => {
            if(!combinedData[r]) {
                toast.error(`${r} is a required field`);
                valid = false;
            }
        })
        return valid;
    }

    const handleCreate = () => {
        if(formIsValid()) {
            setLoading(true);
            NotificationsApi.createNotification(mapNotificationFromAppToServer(combinedData))
                .then(() => {
                    setLoading(false);
                    props.history.push("/admin/notifications");
                })
                .catch((error) => {
                    handleErrorResponse("creating notification", error);
                    setLoading(false);
                });
        }
    };

    const handleEdit = () => {
        setLoading(true);
        NotificationsApi.editNotification(mapNotificationFromAppToServer(combinedData))
            .then((res) => {
                setLoading(false);
                props.history.push("/admin/notifications");
            })
            .catch((error) => {
                handleErrorResponse("editing notification", error);
                setLoading(false);
            });
    };

    const notificationTitle = () => {
        if(combinedData?.notificationType) {
            if(combinedData.notificationType === BI_GAMES) {
                return "Enroll in Greenline Games for Boehringer Ingelheim";
            } else if(combinedData?.notificationType === GREENLINE_GIVING) {
                return(
                    <div className="flex spaced-content">
                        <div className="flex-none" style={{maxWidth: "200px", maxHeight: "50px", overflow: "hidden"}}>
                            <Image
                                style={{width: "100%", marginTop: "-25px"}}
                                src={`https://glcdn.azureedge.net/images/partnerprograms/GreenlineGiving/VHF_Logo_Horizontal_Logo.jpg`}
                            />
                        </div>
                        <div className="flex-1 flex align-center">{combinedData.partnerProgramName}</div>
                    </div>
                );
            }
            else {
                return combinedData.partnerProgramName;
            }
        } else {
            return "";
        }
    }

    const enrolledButtonTextField = () => {
        if(combinedData.enrolled?.supported) {
            // Check if the "Custom" option should be selected
            const enrolledIsCustom = combinedData.enrolled.isCustom || (combinedData.enrolled.buttonText && !find(yesOptions, {value: combinedData.enrolled.buttonText}));
            return (
                <div className="flex spaced-content">
                    <div className="flex-1">
                        <Dropdown
                            onChange={(data) => handleChangeDropdownValue(data, "enrolled.isCustom")}
                            name="enrolled.buttonText"
                            value={enrolledIsCustom ? "Custom" : combinedData.enrolled.buttonText}
                            disabled={false} // Probably can't change once notification has been created...
                            options={yesOptions}
                            placeholder="Select option"
                            label="Yes Button Text"
                        />
                    </div>
                    {enrolledIsCustom && (
                        <div className="flex-1">
                            <TextBox
                                onChange={handleChange}
                                name="enrolled.buttonText"
                                label="Custom Yes Button Text"
                                value={combinedData.enrolled.buttonText}
                            />
                        </div>
                    )}
                </div>
            );
        } else return null;
    };

    const declinedButtonTextField = () => {
        if(combinedData.declined?.supported) {
            // Check if the "Custom" option should be selected
            const declinedIsCustom = combinedData.declined.isCustom || (combinedData.declined.buttonText && !find(noOptions, {value: combinedData.declined.buttonText}));
            return (
                <div className="flex spaced-content">
                    <div className="flex-1">
                        <Dropdown
                            onChange={(data) => handleChangeDropdownValue(data, "declined.isCustom")}
                            name="declined.buttonText"
                            value={declinedIsCustom ? "Custom" : combinedData.declined.buttonText}
                            disabled={false} // Probably can't change once notification has been created...
                            options={noOptions}
                            placeholder="Select option"
                            label="No Button Text"
                        />
                    </div>
                    {declinedIsCustom && (
                        <div className="flex-1">
                            <TextBox
                                onChange={handleChange}
                                name="declined.buttonText"
                                label="Custom No Button Text"
                                value={combinedData.declined.buttonText}
                            />
                        </div>
                    )}
                </div>
            );
        } else return null;
    };

    const showButtons = combinedData.enrolled?.supported || combinedData.declined?.supported || combinedData.liveChat?.supported;
    const showCustomMessages = showButtons || combinedData.cancelled?.supported || combinedData.pending?.supported;

    return (
        <AdminBase
            // searchConfig={SearchTypes.USER_SEARCH}
            collapsible
            // controls={USER_MANAGEMENT}
            pageTitle={`${props.templateId ? "Create" : "Edit"} Notification`}
            overflowHidden
        >
            <div className={classnames(styles.root)}>
                <div className={styles.notification}>
                    <div className={classnames(styles.form, "flex-1")}>
                        <div className="flex spaced-content">
                            <div className="flex-1">
                                <TextBox
                                    onChange={handleChange}
                                    name="displayOrder"
                                    label="Display Order"
                                    value={combinedData.displayOrder}
                                />
                            </div>
                            <div className="flex-2">
                                <TextBox
                                    onChange={handleChange}
                                    name="partnerProgramIdentifier"
                                    label="Notification ID"
                                    value={combinedData.partnerProgramIdentifier}
                                    disabled={!props.templateId}
                                    placeholder="BI-2020_Regular_Offers_With_15"
                                    required
                                />
                            </div>
                        </div>
                        <TextBox
                            onChange={handleChange}
                            name="partnerProgramName"
                            label="Title"
                            value={combinedData.partnerProgramName}
                            maxLength={100}
                            information="100 Character Max"
                            required
                        />
                        <div className="flex spaced-content">
                            <div className="flex-2">
                                <Dropdown
                                    onChange={handleChange}
                                    name="notificationType"
                                    label="Type"
                                    value={combinedData.notificationType}
                                    options={NOTIFICATION_TYPES}
                                    placeholder="Select notification type"
                                />
                            </div>
                            <div className="flex-1">
                                <Dropdown
                                    onChange={handleChange}
                                    name="providerId"
                                    label="Provider"
                                    value={combinedData.providerId}
                                    options={PROVIDER_OPTIONS}
                                    placeholder="Select provider"
                                />
                            </div>
                        </div>
                        <TextBox
                            onChange={handleChange}
                            name="shortDescription"
                            label="Short Description"
                            required
                            value={combinedData.shortDescription}
                        />
                        {/*Long Description - The way the notification will be displayed*/}
                        <RichText
                            label="Full Description"
                            name="longDescription"
                            onChange={handleChange}
                            value={combinedData.longDescription}
                            required
                            disabled={isCustomNotificationTemplate}
                        />
                    {/*/!*    TODO: a way to attach images*!/*/}
                    {/*    <div>IMAGES</div>*/}
                    {/*    <Image src={combinedData.bannerImageUri}/>*/}
                        <div>
                            <h3>Supported States</h3>
                            <div className="flex spaced-content flex-wrap">
                                <CheckboxInput
                                    name="enrolled.supported"
                                    checked={combinedData.enrolled?.supported}
                                    label="Enrolled"
                                    onChange={handleChange}
                                />
                                <CheckboxInput
                                    name="declined.supported"
                                    checked={combinedData.declined?.supported}
                                    label="Declined"
                                    onChange={handleChange}
                                />
                                <CheckboxInput
                                    name="pending.supported"
                                    label="Pending"
                                    checked={combinedData.pending?.supported}
                                    onChange={handleChange}
                                />
                                <CheckboxInput
                                    name="cancelled.supported"
                                    label="Cancelled"
                                    checked={combinedData.cancelled?.supported}
                                    onChange={handleChange}
                                />
                                <CheckboxInput
                                    name="liveChat.supported"
                                    label="Live Chat"
                                    checked={combinedData.liveChat?.supported}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div>
                            {showButtons && (
                                <>
                                    <h3>Button Text</h3>
                                    {enrolledButtonTextField()}
                                    {declinedButtonTextField()}
                                </>
                            )}
                            {showCustomMessages && (
                                <>
                                    <h3>Custom Messages</h3>
                                    {combinedData.enrolled?.supported && (
                                        <div>
                                            <TextBox
                                                onChange={handleChange}
                                                name="enrolled.displayText"
                                                label="Enrolled Message"
                                                value={combinedData.enrolled.displayText}
                                            />
                                        </div>
                                    )}
                                    {combinedData.declined?.supported && (
                                        <div>
                                            <TextBox
                                                onChange={handleChange}
                                                name="declined.displayText"
                                                label="Declined Message"
                                                value={combinedData.declined.displayText}
                                            />
                                        </div>
                                    )}
                                    {combinedData.pending?.supported && (
                                        <div>
                                            <TextBox
                                                onChange={handleChange}
                                                name="pending.displayText"
                                                label="Pending Message"
                                                value={combinedData.pending.displayText}
                                            />
                                        </div>
                                    )}
                                    {combinedData.cancelled?.supported && (
                                        <div>
                                            <TextBox
                                                onChange={handleChange}
                                                name="cancelled.displayText"
                                                label="Cancelled Message"
                                                value={combinedData.cancelled.displayText}
                                            />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>

                        <hr/>
                        <div className="flex flex-wrap">
                            <div className="flex-none">
                                <ToggleSwitch
                                    id="autoRegisterOnLogIn"
                                    name="autoRegisterOnLogIn"
                                    updateState={(name, value) => handleChange({name, value})}
                                    force={combinedData.autoRegisterOnLogIn}
                                    labels={["", "Auto register on login"]}
                                />
                            </div>
                            <div className="flex-none">
                                <ToggleSwitch
                                    id="requiresInitials"
                                    name="requiresInitials"
                                    updateState={(name, value) => handleChange({name, value})}
                                    force={combinedData.requiresInitials}
                                    labels={["", "Requires Initials"]}
                                />
                            </div>
                            <div className="flex-none">
                                <ToggleSwitch
                                    id="isAlert"
                                    name="isAlert"
                                    updateState={(name, value) => handleChange({name, value})}
                                    force={combinedData.isAlert}
                                    labels={["", "Is Alert"]}
                                />
                            </div>
                            <div className="flex-none">
                                <ToggleSwitch
                                    id="isVisibleInNotifications"
                                    name="isVisibleInNotifications"
                                    updateState={(name, value) => handleChange({name, value})}
                                    force={combinedData.isVisibleInNotifications}
                                    labels={["", "Visible in Notifications"]}
                                />
                            </div>
                            <div className="flex-none">
                                <ToggleSwitch
                                    id="moreInfoShowCurrentStatus"
                                    name="moreInfoShowCurrentStatus"
                                    updateState={(name, value) => handleChange({name, value})}
                                    force={combinedData.moreInfoShowCurrentStatus}
                                    labels={["", "Show Current Status"]}
                                />
                            </div>
                        </div>
                        <hr/>
                        <div className="flex spaced-content">
                            <div className="flex-1">
                                <h3>Notification Dates</h3>
                                {/*<div className="flex spaced-content">*/}
                                    <DateInput
                                        placeholderText="Notification Start Date"
                                        onChange={handleChange}
                                        name="optinStartDate"
                                        value={combinedData.optinStartDate}
                                        label="Start Date"
                                        maxDate={combinedData.optinExpirationDate}
                                        showDropdowns
                                        aligned
                                    />
                                    <DateInput
                                        placeholderText="Notification End Date"
                                        onChange={handleChange}
                                        name="optinExpirationDate"
                                        value={combinedData.optinExpirationDate}
                                        label="End Date"
                                        minDate={combinedData.optinStartDate}
                                        showDropdowns
                                        aligned
                                    />
                                {/*</div>*/}
                            </div>
                            <div className="flex-1 border-left">
                                <h3>Program Dates</h3>
                                {/*<div className="flex spaced-content">*/}
                                    <DateInput
                                        onChange={handleChange}
                                        name="programStartDate"
                                        value={combinedData.programStartDate}
                                        label="Start Date"
                                        placeholderText="Program Start Date"
                                        maxDate={combinedData.programExpirationDate}
                                        showDropdowns
                                        aligned
                                    />
                                    <DateInput
                                        onChange={handleChange}
                                        name="programExpirationDate"
                                        value={combinedData.programExpirationDate}
                                        label="End Date"
                                        placeholderText="Program End Date"
                                        minDate={combinedData.programStartDate}
                                        showDropdowns
                                        aligned
                                    />
                                {/*</div>*/}
                            </div>
                        </div>
                        <hr/>
                        <div>
                            <h3>More Info</h3>
                            {/* TODO: This is supposed to be JSON...*/}
                            {/*Need to re-enable*/}
                            <TextBox
                                onChange={handleChange}
                                name="controlParams"
                                label="MFR/Client"
                                value={combinedData.controlParams}
                                disabled
                            />
                            <div className="flex spaced-content">
                                <TextBox
                                    onChange={handleChange}
                                    name="moreInfoTargetWindow"
                                    label="Target Window"
                                    value={combinedData.moreInfoTargetWindow}
                                />
                                <TextBox
                                    onChange={handleChange}
                                    name="moreInfoViewOverride"
                                    label="View Override"
                                    value={combinedData.moreInfoViewOverride}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={styles.previewContainer}>
                        <div>
                            <div className="flex spaced-content">
                                {combinedData.enrolled?.supported && (
                                    <div className="flex-none">
                                        <Button
                                            className={classnames({
                                                "hover": currentStatus === ENROLLED
                                            })}
                                            onClick={() => {setCurrentStatus(ENROLLED)}}
                                            small
                                            type="blue"
                                        >
                                            Enrolled
                                        </Button>
                                    </div>
                                )}
                                {combinedData.canceled?.supported && (
                                    <div className="flex-none">
                                        <Button
                                            className={classnames({
                                                "hover": currentStatus === CANCELED
                                            })}
                                            onClick={() => {setCurrentStatus(CANCELED)}}
                                            small
                                            type="blue"
                                        >
                                            Canceled
                                        </Button>
                                    </div>
                                )}
                                {combinedData.declined?.supported && (
                                    <div className="flex-none">
                                        <Button
                                            className={classnames({
                                                "hover": currentStatus === DECLINED
                                            })}
                                            onClick={() => {setCurrentStatus(DECLINED)}}
                                            small
                                            type="blue"
                                        >
                                            Declined
                                        </Button>
                                    </div>
                                )}
                                {combinedData.pending?.supported && (
                                    <div className="flex-none">
                                        <Button
                                            className={classnames({
                                                "hover": currentStatus === PENDING
                                            })}
                                            onClick={() => {setCurrentStatus(PENDING)}}
                                            small
                                            type="blue"
                                        >
                                            Pending
                                        </Button>
                                    </div>
                                )}
                            </div>
                            <div>
                                <h3>
                                    Notification Tile Preview
                                </h3>
                                <div style={{width: "400px"}}>
                                    <NotificationTile
                                        id={combinedData.partnerProgramIdentifier}
                                        type={combinedData.notificationType}
                                        label={combinedData.partnerProgramName}
                                        buttonLabel="View"
                                        description={combinedData.shortDescription}
                                        onButtonClick={() => {}}
                                        dismissed={currentStatus !== PENDING}
                                    />
                                </div>
                            </div>
                            <div>
                                <h3 className="flex-1">
                                    Notification Preview
                                </h3>
                                <div className={styles.notificationPreview}>
                                    <div className={styles.closeBtn}>
                                        <Button
                                            onClick={() => {}}
                                            type="gray"
                                            text
                                        >
                                            <i className="far fa-times-circle"/>
                                        </Button>
                                    </div>
                                    {!!combinedData?.notificationType && (
                                        <div className={styles.title}>
                                            <h3>{notificationTitle()}</h3>
                                        </div>
                                    )}
                                    <Notification
                                        actionPerformed={props.actionPerformed}
                                        clinicId={props.clinicId}
                                        isPreview
                                        notification={{
                                            ...combinedData,
                                            currentState: getCurrentState(),
                                            enrollmentState: currentStatus
                                        }}
                                        performAction={props.performAction}
                                        providerId={props.providerId}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classnames(styles.buttons, "flex-none flex spaced-content justify-flex-end")}>
                    <Button
                        onClick={handleCancel}
                        type="gray"
                    >
                        Cancel
                    </Button>
                    {!!props.templateId ? (
                        <Button
                            onClick={handleCreate}
                            disabled={loading}
                        >
                             Create Notification
                        </Button>
                    ) : (
                        <Button
                            onClick={handleEdit}
                            disabled={loading}
                        >
                            Edit Notification
                        </Button>
                    )}
                </div>
            </div>
            <SpinnerTakeover show={loading}/>
        </AdminBase>
    )
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewNotificationManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.NOTIFICATION_MANAGEMENT, userProfile);
        const templateId = ownProps.match.params.template || null;
        const notificationId = ownProps.match.params.notificationId || null;
        return {
            templateId,
            notificationId,
            canViewNotificationManagement,
            notification: !!notificationId ? find(state.entities.allNotifications, {partnerProgramIdentifier: notificationId}) : {},
            notifications: state.entities.allNotifications,
            providers: state.entities.providers
        }
    },
    (dispatch) => ({
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        getAllNotifications: () => dispatch(NotificationsActions.getAllNotifications()),
        createNotification: (notification) => dispatch(NotificationsActions.createNotification(notification))
    })
)(NotificationEdit);
