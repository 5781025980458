import React from "react";
import AdminBase from "components/layout/AdminBase";
import UsersTable from "components/admin/widgets/UsersTable";
import * as SearchTypes from "constants/SearchTypes";
import {USER_MANAGEMENT} from "constants/AdminControls";
import {connect} from "react-redux";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";

function Users(props) {
    return (
        <AdminBase
            searchConfig={SearchTypes.USER_SEARCH}
            collapsible
            controls={USER_MANAGEMENT}
            pageTitle="User Management"
        >
            <div className="margin-bottom-lg">
                {props.canViewUserManagement ? (
                    <UsersTable/>
                ) : (
                    <AccessDenied/>
                )}
            </div>
        </AdminBase>
    )
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);
        return {
            canViewUserManagement,
        }
    }
)(Users);
