import React, { useCallback, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as CouponActions from "actions/CouponActions";
import * as CouponsApi from "api/CouponsApi";
import styles from "./CouponHistory.scss";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import upperFirst from "lodash/upperFirst";
import values from "lodash/values";
import AccessDenied from "components/common/AccessDenied";
import ApprovalButtons from "components/common/ApprovalButtons";
import ApproveDeclineSingleCoupon from "components/coupons/widgets/ApproveDeclineSingleCoupon/ApproveDeclineSingleCoupon";
import Button from "components/common/Button";
import ClinicBase from "components/layout/ClinicBase";
import CouponHistoryTable from "components/coupons/widgets/CouponHistoryTable";
import FixCouponErrorForm from "components/coupons/widgets/FixCouponErrorForm";
import JoyRide from "components/tour/widgets/JoyRide";
import Modal from "components/common/Modal";
import ResultsPerPageDropdown from "components/coupons/widgets/ResultsPerPageDropdown";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import StatusFilterDropdown from "components/coupons/widgets/StatusFilterDropdown";
import TimeFilterDropdown from "components/coupons/widgets/TimeFilterDropdown";
import {
    TOUR_COUPON_HISTORY_PART_2,
    TOUR_COUPON_LIBRARY_PART_1,
    TOUR_COUPON_LIBRARY_TRANSITION
} from "utils/demoTourHelpers";
import { getClinicInfo } from "utils/ClinicData";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { getUserCurrentClinic } from "utils/WellnessData";
import { CLINIC_TAGS } from "constants/AdminControls";
import * as DemoTourStates from "constants/DemoTourStates";
import * as CouponStatuses from "constants/CouponStatusIds";
import * as TimeFilterId from "constants/TimeFilterIds";
import * as UserPermissions from "constants/UserPermissions";
import { mapCouponSearchFromServerToApp } from "data/serverMapping";

const DEFAULT_PAGINATION = {
    offset: 0,
    page: 1,
    totalRecordCount: 0,
    totalPages: 0,
};

function CouponHistory (props) {
    const [statusFilter, setStatusFilter] = useState({name: "All", value: "null"});
    const [period, setPeriod] = useState("-90d");
    const [orderBy, setOrderBy] = useState("UserActionDate");
    const [orderDir, setOrderDir] = useState("desc");
    const [resultsPerPage, setResultsPerPage] = useState(10);
    const [dateRange, setDateRange] = useState(null);
    const [selectedRows, setSelectedRows] = useState({});
    const [currentVendor, setCurrentVendor] = useState(null);
    const [isApprovalLocked, setIsApprovalLocked] = useState(true);
    const [currentEdit, setCurrentEdit] = useState(null);
    const [currentApprove, setCurrentApprove] = useState(null);
    const [pagination, setPagination] = useState({...DEFAULT_PAGINATION});
    const [allCoupons, setAllCoupons] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tourSection, setTourSection] = useState(null);

    const fetchData = useCallback(debounce((search) => {
        if (props.canViewClinicCouponAudit) {
            clearSelectedRows();
            loadCoupons(search);
        }
    }), 500, []);

    useEffect(() => {
        if (props.location.search) {
            let search = new URLSearchParams(props.location.search);
            let status = search.get("status")
            status = upperFirst(status);
            if ([...CouponStatuses.STATUSES].includes(status)) {
                setStatusFilter({
                    name: CouponStatuses.NAMES[status],
                    value: status
                })
            }
        }
    }, []);

    const getTourSection = () => {
        if(!includes(props.completedTourSections, TOUR_COUPON_HISTORY_PART_2)) {
            setTourSection(TOUR_COUPON_HISTORY_PART_2);
        } else if(!includes(props.completedTourSections, TOUR_COUPON_LIBRARY_TRANSITION)) {
            setTourSection(TOUR_COUPON_LIBRARY_TRANSITION);
        } else if(!includes(props.completedTourSections, TOUR_COUPON_LIBRARY_PART_1)) {
            setTourSection(TOUR_COUPON_LIBRARY_PART_1);
        } else {
            setTourSection(null);
        }
    }

    useEffect(() => {
        getTourSection();
    }, [props.completedTourSections]);

    useEffect(() => {
        if (props.canViewClinicCouponLibrary) {
            props.getPrograms(props.clinicId);
        }
    }, [props.clinicId, props.canViewClinicCouponLibrary]);

    useEffect(() => {
        const filterOptions = getCurrentFilterOptions();
        const search = {
            clinicId: props.clinicId,
            offset: pagination.offset,
            limit: resultsPerPage,
            startDate: null,
            endDate: null,
            period,
            ...filterOptions,
            orderBy: orderBy,
            orderDir: orderDir,
        };
        fetchData(search);
    }, [resultsPerPage, statusFilter, period, orderBy, orderDir, dateRange]);

    // Always wrap this in a startLoad() to keep the loading spinner ref count balanced.
    const loadCoupons = (search = {}, page=null) => {
        if(props.canViewClinicCouponAudit) {
            setLoading(true);

            CouponsApi.getProcessedCoupons(search)
                .then(res => {
                    const newTotalPages = Math.ceil(res.body.totalRecordCount / search.limit);
                    setPagination({
                        ...pagination,
                        page: page ? page : pagination.page,
                        totalPages: newTotalPages,
                        totalRecordCount: res.body.totalRecordCount,
                    });
                    setAllCoupons(mapCouponSearchFromServerToApp(res.body));
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("loading processed coupons", error);
                    setLoading(false);
                });
        }
    };

    const getSelectedRows = () => {
        if (!currentVendor) {
            return [];
        }

        // return only the coupons for the selected Manufacturer
        return selectedRows[currentVendor];
    }

    const selectRows = (vendorId, selectedCouponIds) => {
        const selected = {
            ...selectedRows,
            [vendorId]: selectedCouponIds,
        };

        setSelectedRows(selected);
        setCurrentVendor(vendorId);
        setIsApprovalLocked((selected[vendorId].length === 0));
    }

    const isDeclined = (providerId, couponId) => {
        const coupon = find(pagination[providerId].coupons, c => c.id === couponId);
        return coupon ? coupon.status === CouponStatuses.DECLINED : false;
    }

    const clearSelectedRows = () => {
        selectRows(currentVendor, []);
    }

    const getStatusFilterStates = () => {
        if (!statusFilter.value || statusFilter.name === "All") {
            return `${CouponStatuses.APPROVED}, ${CouponStatuses.DECLINED}`
        } else {
            return statusFilter.value;
        }
    }

    const getCurrentFilterOptions = () => {
        if (period=== TimeFilterId.CUSTOM) {
            return {
                period: TimeFilterId.CUSTOM,
                clinicId: props.clinicId || props.userClinicId,
                startDate: dateRange ? dateRange.startDate : new Date(),
                endDate: dateRange ? dateRange.endDate : new Date(),
                redemptionStates: getStatusFilterStates()
            }
        }

        return {
            period,
            clinicId: props.clinicId || props.userClinicId,
            redemptionStates: getStatusFilterStates()
        }
    }

    const handleStatusFilterChanged = (option) => {
        setStatusFilter(option);
        setPagination({...DEFAULT_PAGINATION});
    }

    const handleTimeFilterRangeChanged = (range) => {
        setDateRange(range);
        setPagination({...DEFAULT_PAGINATION});
    }

    const handleResultsPerPageChanged = ({ name, value }) => {
        setResultsPerPage(value);
        setPagination({...DEFAULT_PAGINATION});
    }

    const handleTimeFilterChanged = ({value}) => {
        setPeriod(value);
        setPagination({...DEFAULT_PAGINATION});
    }

    const handleRowSelectChanged = (vendorId, selectedCouponIds) => {
        const onlyDeclinedCouponIds = filter(selectedCouponIds, id => isDeclined(vendorId, id));
        selectRows(vendorId, onlyDeclinedCouponIds);
    }

    const handleApprove = (initials, coupons) => {
        if(props.canEditClinicCouponAudit) {
            const couponList = coupons.length ? coupons : getSelectedRows();
            props.processCoupons(initials, couponList, props.clinicId);
            clearSelectedRows();
            setCurrentApprove(null);
        }
    }

    const handleNextPageClicked = () => {
        if (pagination.page < pagination.totalPages) {
            const nextPage = pagination.page + 1;

            const filterOptions = getCurrentFilterOptions();
            const search = {
                period,
                clinicId: props.clinicId,
                offset: (nextPage - 1) * resultsPerPage,
                limit: resultsPerPage,
                startDate: null,
                endDate: null,
                ...filterOptions,
                orderBy: orderBy,
                orderDir: orderDir,
            };

            loadCoupons(search, nextPage);
        }
    }

    const handlePreviousPageClicked = ()  => {
        if (pagination.page > 1) {
            const lastPage = pagination.page - 1;
            setPagination({
                page: lastPage,
                offset: (lastPage - 1) * resultsPerPage,
            })

            const filterOptions = getCurrentFilterOptions();
            const search = {
                period,
                clinicId: props.clinicId,
                offset: (lastPage - 1) * resultsPerPage,
                limit: resultsPerPage,
                startDate: null,
                endDate: null,
                ...filterOptions,
                orderBy: orderBy,
                orderDir: orderDir,
            };

            loadCoupons(search, lastPage);
        }
    }

    const handleSort = (sort, direction) => {
        if (sort === "offerName") {
            sort = "Offer"; // The server data for this field doesn't match the name of the field this specific API call wants, so now we do this.
        }

        setOrderBy(upperFirst(sort));
        setOrderDir(direction ? direction.toUpperCase() : null)
        // loadCoupons();
    }

    const handleEditCouponRedemption = (id) => {
        if(props.canViewClinicCouponAudit) {
            setCurrentEdit(id);
        }
    }

    const handleAfterSaveCouponEdit = () => {
        setCurrentEdit(null);

        // Reload the current page of results
        const filterOptions = getCurrentFilterOptions();
        const search = {
            period,
            clinicId: props.clinicId,
            offset: (pagination.page - 1) * resultsPerPage,
            limit: resultsPerPage,
            startDate: null,
            endDate: null,
            ...filterOptions,
            orderBy: orderBy,
            orderDir: orderDir,
        };
        loadCoupons(search, pagination.page);
    }

    const renderCouponList = () => {
        let couponList = [...allCoupons];
        if (couponList && couponList.length > 0) {
            // if (this.props.search) {
            //     couponList = filterByText(couponList);
            // }
        }

        return (
            <CouponHistoryTable
                providers={props.providers}
                coupons={couponList}
                showOptionsMenu
                showStatusIcon
                showVendorLogos
                onRowSelectChange={handleRowSelectChanged}
                onEditClick={handleEditCouponRedemption}
                onApproveClick={(couponId) => setCurrentApprove(couponId)}
                historical
                canUndoDeclined
                selectedRowIds={values(selectedRows)}
                downloadOptions={getCurrentFilterOptions()}
                resultsPerPage={resultsPerPage}
                canSelect={false}
                canViewClinicCouponAudit={props.canViewClinicCouponAudit}
                canEditClinicCouponAudit={props.canEditClinicCouponAudit}
                handleSort={handleSort}
            />
        );
    }

    return (
        <ClinicBase
            clinicId={props.clinicId}
            pageTitle="Coupon History"
            adminControls={CLINIC_TAGS}
        >
            <div className={styles.root}>
                {props.canViewClinicCouponAudit ? (
                    <>
                        <div className={styles.topLine}>
                            <div className="flex flex-wrap">
                                <div className="flex-none flex flex-centered spaced-content margin-sm">
                                    <label>Status</label>
                                    <StatusFilterDropdown
                                        value={statusFilter.value}
                                        onChange={handleStatusFilterChanged}
                                    />
                                </div>
                                <div className="flex-none flex flex-centered spaced-content margin-sm">
                                    <label>Processed Date</label>
                                    <TimeFilterDropdown
                                        name="period"
                                        value={period}
                                        onChange={handleTimeFilterChanged}
                                        onRangeChange={handleTimeFilterRangeChanged}
                                    />
                                </div>
                                <div className="flex-none flex flex-centered spaced-content margin-sm">
                                    <label>Results Per Page</label>
                                    <ResultsPerPageDropdown
                                        onChange={handleResultsPerPageChanged}
                                        value={resultsPerPage}
                                    />
                                </div>
                            </div>
                        </div>
                        {props.canEditClinicCouponAudit && (
                            <ApprovalButtons
                                onApprove={handleApprove}
                                locked={isApprovalLocked}
                                cannotDecline
                            />
                        )}
                        {renderCouponList()}
                        <div className="full-width flex align-center justify-around">
                            <Button
                                onClick={handlePreviousPageClicked}
                                disabled={pagination.page <= 1}
                            >
                                PREV
                            </Button>
                            <span>Page {pagination.page} / {pagination.totalPages}</span>
                            <Button
                                onClick={handleNextPageClicked}
                                disabled={pagination.page >= pagination.totalPages}
                            >
                                NEXT
                            </Button>
                        </div>
                        <JoyRide
                            key={tourSection}
                            tourSection={tourSection}
                        />
                    </>
                ) : (
                    <AccessDenied customMessage="You don't have the correct permissions needed to view the Coupon History Page"/>
                )}
            </div>
            <SpinnerTakeover show={loading} />

            <Modal
                show={!!currentEdit}
                onClose={() => setCurrentEdit(null)}
                modalTitle="Edit Coupon TEST"
                mediumSmall
                clickOutsideToClose={false}
            >
                {props.canEditClinicCouponAudit ? (
                    <div>
                        {!!currentEdit && (
                            <FixCouponErrorForm
                                couponId={currentEdit}
                                onAfterSave={handleAfterSaveCouponEdit}
                                onClose={() => setCurrentEdit(null)}
                            />
                        )}
                    </div>
                ) : (
                    <AccessDenied/>
                )}
            </Modal>

            {/*Modal for Approving a single coupon*/}
            <Modal
                show={!!currentApprove}
                onClose={() => setCurrentApprove(null)}
                modalTitle={"Approve Coupon"}
                mediumSmall
                clickOutsideToClose={false}
            >
                {!!currentApprove && (
                    !props.canEditClinicCouponAudit ? (
                        <AccessDenied/>
                    ) : (
                        <ApproveDeclineSingleCoupon
                            couponId={currentApprove}
                            coupon={find(allCoupons, {id: currentApprove})}
                            onClose={() => setCurrentApprove(null)}
                            onSubmit={handleApprove}
                            isApprove
                        />
                    )
                )}
            </Modal>
        </ClinicBase>
    );
}

const connector = connect (
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? Number(clinicInfo.clinicId) : null;
        const userProfile = state.user.userProfile;
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewClinicCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canEditClinicCouponAudit = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            clinicId,
            canViewClinicCouponAudit: canViewClinicCouponAudit || canViewCorporateGroupClinic,
            canViewClinicCouponLibrary,
            canEditClinicCouponAudit,
            canEditClinicManagement,
            clinicInfo,
            coupons: state.entities.coupons.all,
            providers: flatMap(values(state.entities.programs), "provider"),
            search: state.entities.coupons.query || null,
            userClinicId: getUserCurrentClinic(state),
            completedTourSections: state.demo.completedTourSections,
            tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),
        }
    },
    (dispatch) => ({
        getPrograms: (clinicId) => dispatch(CouponActions.getPrograms(clinicId)),
        getCouponDetails: (id) => dispatch(CouponActions.getCouponDetails(id)),
        fixCoupon: (fixData) => dispatch(CouponActions.fixCoupon(fixData)),
        processCoupons: (initials, coupons, clinicId) => dispatch(CouponActions.processCoupons(initials, coupons, clinicId)),
    }),
);

export default compose(
    withRouter,
    connector
)(CouponHistory);
