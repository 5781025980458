exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".StatusFilterDropdown__root{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;min-width:75px}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/coupons/widgets/StatusFilterDropdown.scss"],"names":[],"mappings":"AAAA,4BAAM,oBAAoB,aAAa,uBAAuB,mBAAmB,sBAAsB,mBAAmB,cAAc,CAAC","file":"StatusFilterDropdown.scss","sourcesContent":[".root{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;min-width:75px}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "StatusFilterDropdown__root"
};