import React from "react";
import PropTypes from "prop-types";
import styles from "./Chart.scss";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import SpinnerTakeover from "components/common/SpinnerTakeover";

export default function Chart(props) {
    const generateChartConfig = () => {
        const { series, type, yAxis, legend, xAxis, tooltip} = props.config;
        return {
            title: props.title,
            chart: {
                renderTo: "container",
                type: type || "line",
                width: 550,
                height: 325,
                style: {
                    fontFamily: 'SF Pro Display'
                }
            },
            responsive: {
                rules: [{
                    condition: {
                        maxWidth: "100%"
                    }
                }]
            },
            legend: legend ? legend : {},
            tooltip : tooltip ? tooltip : {},
            xAxis,
            yAxis,
            series,
        };
    }

    if (!props.config) {
        return <SpinnerTakeover show />
    }
    const chartOptions = generateChartConfig();
    return (
        <div className={styles.root}>
            <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
            />
        </div>
    );
}

Chart.defaultProps = {
    title: "",
};

Chart.propTypes = {
    config: PropTypes.object.isRequired,
    title: PropTypes.string,
};
