import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Joyride, { ACTIONS, LIFECYCLE, STATUS } from "react-joyride";
import * as TourActions from "actions/TourActions";
import { getFreeTrialTourSteps } from "components/tour/tourConfiguration";
import TourStep from "components/tour/widgets/TourStep";
import { STARTED } from "constants/DemoTourStates";
import Beacon from "components/tour/elements/Beacon";
import { goToNextSection } from "utils/demoTourHelpers";

function JoyRide(props) {
    const history = useHistory();
    if (!props.tourStarted ){
        return null;
    }
    const [running, setRunning] = useState(true);
    const [tourSteps, setTourSteps] = useState([]);

    useEffect(() => {
        const steps = getFreeTrialTourSteps(props.tourSection, props.userProfile.demoProvider) || [];
        setTourSteps(steps)
    }, [props.tourSection]);

    const delay = (time) => {
        return new Promise(res => setTimeout(res, time));
    }

    const stepCompleted = async (values) => {
        if(values.lifecycle === LIFECYCLE.COMPLETE) {
            if (values.action === ACTIONS.CLOSE) {
                setRunning(false);
                // Things that should be completed after the step is closed
                if (!!props.onStepComplete) {
                    await props.onStepComplete(props.tourSection, values.index);
                }
                if (!!values.step.waitTimeBeforeContinue) {
                    await delay(values.step.waitTimeBeforeContinue);
                }
                props.markTourStepAsComplete(props.tourSection, values.index);
                setRunning(true);
            }

            if (values.status === STATUS.FINISHED) {
                //Update redux
                props.markTourSectionAsComplete(props.tourSection);

                if (!!props.onSectionComplete) {
                    props.onSectionComplete(props.tourSection);
                }

                // Handle Changing pages.
                const goTo = goToNextSection(props.tourSection, props.completedTourSections, props.userProfile);
                if (!!goTo) {
                    // Go to the URL of the next section if it is different
                    history.push(goTo);
                }
            }

            if (values.action === ACTIONS.NEXT) {
                props.markTourStepAsComplete(props.tourSection, values.index);
            }
            if (values.action === ACTIONS.SKIP) {
                props.markTourAsComplete();
            }
        }
    }

    return (
        <Joyride
            run={running}
            tooltipComponent={({index, primaryProps, step, tooltipProps}) => (
                <TourStep
                    key={`${props.tourSection}-index`}
                    index={index}
                    step={step}
                    primaryProps={primaryProps}
                    tooltipProps={tooltipProps}
                    tourSection={props.tourSection}
                    onStepComplete={props.onStepComplete}
                />
            )}
            beaconComponent={({onClick, title, onMouseEnter}) => (
                <Beacon
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    title={title}
                />
            )}
            scrollOffset={props.scrollOffset}
            scrollDuration={props.scrollDuration}
            steps={tourSteps}
            continuous={props.continuous}
            debug={props.consoleLogDebug}
            disableCloseOnEsc={props.disableCloseOnEsc}
            disableOverlayClose={props.disableOverlayClose}
            hideCloseButton={props.hideCloseButton}
            spotlightClicks={props.spotlightClicks}
            hideBackButton={props.hideBackButton}
            callback={stepCompleted}
            disableScrolling={props.disableScrolling}
            styles={{
                overlay: {
                    maxHeight: "100vh",
                    height: "100vh",
                },
                options: {
                    primaryColor: "#a6ce43",
                    zIndex: 3,
                },
            }}
        />
    );
}

JoyRide.propTypes = {
    styles: PropTypes.any,
    tourSection: PropTypes.string,
    onStepComplete: PropTypes.func,
    onSectionComplete: PropTypes.func,
    continuous: PropTypes.bool,
    consoleLogDebug: PropTypes.bool,
    disableCloseOnEsc: PropTypes.bool,
    disableOverlayClose: PropTypes.bool,
    spotlightClicks: PropTypes.bool,
    hideBackButton: PropTypes.bool,
    toolTipWidth: PropTypes.string
};

JoyRide.defaultProps = {
    continuous: true,
    consoleLogDebug: false,
    disableCloseOnEsc: true,
    disableOverlayClose: true,
    spotlightClicks: false,
    hideBackButton: true,
    disableScrolling: true,
    scrollDuration: 0,
    scrollOffset: 0
};


export default connect(
    (state) => ({
        userProfile: state.user.userProfile,
        tourStarted: (state.demo.demoTourState === STARTED),
        completedTourSections: state.demo.completedTourSections,
    }),
    (dispatch) => ({
        markTourSectionAsComplete: (tourSection) => dispatch(TourActions.markTourSectionAsComplete(tourSection)),
        markTourStepAsComplete: (tourSection, stepIndex) => dispatch(TourActions.markTourStepAsComplete(tourSection, stepIndex)),
        markTourAsComplete: () => dispatch(TourActions.markTourAsComplete()),
    })
)(JoyRide);
