import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import * as styles from "./ManageAutomations.scss";
import * as VetCheckApi from "api/VetCheckApi";
import * as MappingActions from "actions/MappingActions";
import * as VetCheckActions from "actions/VetCheckActions";
import AddVetCheckAutomation from "components/vetcheck/forms/AddVetCheckAutomation";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import logger from "utils/logger";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ManageAutomations(props) {
    const [showNewAutomation, setShowNewAutomation] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [search, setSearch] = useState("");
    const [vetCheckStatus, setVetCheckStatus] = useState({});
    const [loading, setLoading] = useState(false);

    const loginCallbackUri = `${props.location.origin}/vetcheck/loggedin/${props.isAdmin ? props.clinicId : null}`;
    const registerCallbackUri = `${props.location.origin}/vetcheck/registered`;

    const getVetCheckStatus = () => {
        if(props.canViewClinicVetCheck || props.canViewVetCheckMapping) {
            setLoading(true);
            VetCheckApi.getClinicVetCheckStatus(props.clinicId, loginCallbackUri, registerCallbackUri)
                .then((res) => {
                    setVetCheckStatus(res.body);
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("loading VetCheck status", error);
                    setLoading(false);
                })
        }
    }

    useEffect(() => {
        if(!!props.clinicId) {
            props.loadVetCheckManagement(props.clinicId);
            props.getPIMs(props.clinicId);

            if(!props.keywords) {
                props.getKeywords(props.clinicId);
            }
            getVetCheckStatus();
        }
    }, [props.clinicId]);

    if(loading) {
        return <SpinnerTakeover show/>;
    }

    const handleAddHandoutMapping = () => {
        setShowNewAutomation(true);
    }

    const handleDelete = (row) => {
        if(props.canEditVetCheckMapping){
            props.deleteVetCheckAutomation(row.mappingId, row.contentType);
        }
    }

    const COLUMNS = [{
        name: "ID",
        selector: "vetCheckHandoutId",
        key: "vetCheckHandoutId",
        sortable: true,
    }, {
        name: "VetCheck Content",
        selector: "title",
        key: "title",
        sortable: true,
        searchable: true,
        format: row => (
            <div>
                {!!row?.shareLink ? (
                    <a className="text-primary" href={row.shareLink} target="_blank">
                        {row.title}
                    </a>
                ) : row.title}
            </div>
        )
    }, {
        name: "Type",
        selector: "contentType",
        key: "contentType",
        sortable: true,
    }, {
        name: "PIMS Code",
        selector: "clinicProductId",
        key: "clinicProductId",
        searchable: true,
        sortable: true,
        sortValue: row => {
            const product = find(props.pims, {clinicProductId: row.clinicProductId});
            return product?.remoteClinicProductDisplayID || null;
        },
        format: row => {
            const product = find(props.pims, {clinicProductId: row.clinicProductId});
            return product?.remoteClinicProductDisplayID || null;
        }

    }, {
        name: "Keyword",
        selector: "keyword",
        key: "keyword",
        sortable: true,
        sortValue: row => {
            const keyword = find(props.keywords, {clinicKeywordId: row.clinicKeywordId});
            return keyword?.keyword || null;
        },
        format: row => {
            const keyword = find(props.keywords, {clinicKeywordId: row.clinicKeywordId});
            return keyword?.keyword || null;
        }
    }, {
        name: "",
        selector: "id",
        key: "id",
        format: row => (
            <div className="text-center">
                {props.canEditVetCheckMapping && (
                    <Button onClick={() => handleDelete(row)}
                        iconOnly
                        text
                        type="danger"
                    >
                        <i className="fa fa-trash-alt"/>
                    </Button>
                )}
            </div>
        ),
    }];

    const openLogin = (newWindow=false) => {
        if(!!vetCheckStatus?.loginUrl) {
            props.setShowChangeVetCheckPassword(false);
            if (newWindow){
                window.open(vetCheckStatus.loginUrl, "_blank");
            } else {
                window.location.href = vetCheckStatus.loginUrl;
            }
        } else {
            logger.error("The Login URL is missing.")
        }
    }

    return (
        <div className={styles.root}>
            <div className="flex spaced-content margin-top-sm margin-bottom-x-sm">
                <h2 className="flex-1">Manage Automations</h2>
                <div className="flex-1">
                    <TextBox
                        onChange={({value}) => setSearch(value)}
                        name="search"
                        value={search}
                        placeholder="Search..."
                    />
                </div>
                {(props.canEditVetCheckMapping && vetCheckStatus.isAuthenticated) && (
                    <div className="flex-none">
                        <Button
                            onClick={handleAddHandoutMapping}
                            icon
                            // disabled={!props.hasPims}
                            title={props.hasPims ? "Add new automation" : "Cannot add automation because clinic has no PIMS"}
                        >
                            <i className="fa fa-plus" /> New Automation
                        </Button>
                    </div>
                )}
            </div>
            <div>
                <SortableDataTable
                    columns={COLUMNS}
                    rawData={orderBy(props.vetCheckAutomations, ["title", "clinicHandoutId", "clinicProductId"], ["asc", "asc", "asc"])}
                    noPagination
                    striped
                    green
                    allowSearch
                    hideSearchBar
                    search={search}
                />
            </div>
            <AddVetCheckAutomation
                clinicId={props.clinicId}
                showNewAutomation={showNewAutomation}
                handleClose={() => setShowNewAutomation(false)}
            />
            <Modal
                show={!!selectedItem}
                modalTitle="TODO: Change modal title"
                onClose={() => setSelectedItem(null)}
            >
                {!!selectedItem && (
                    <div>
                        TODO: Show mapping in modal
                    </div>
                )}
            </Modal>
        </div>
    );
}

ManageAutomations.propTypes = {
    clinicId: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        // Permissions
        const canViewClinicVetCheck = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_VETCHECK, userProfile);
        const canViewVetCheckMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);
        const canEditVetCheckMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.VETCHECK_MAPPING, userProfile);
        return {
            vetCheckAutomations: state.entities.vetCheckAutomations,
            hasPims: !!state.entities?.pims?.products?.length,
            pims: state.entities?.pims?.products,
            keywords: state.entities.keywords?.[ownProps.clinicId],
            // Permissions
            canViewClinicVetCheck,
            canViewVetCheckMapping,
            canEditVetCheckMapping
        }
    },
    (dispatch) => ({
        loadVetCheckManagement: (clinicId) => dispatch(VetCheckActions.loadVetCheckManagement(clinicId)),
        getPIMs: (clinicId) => dispatch(MappingActions.getPIMs(clinicId)),
        getKeywords: (clinicId) => dispatch(VetCheckActions.getKeywords(clinicId)),
        deleteVetCheckAutomation: (mappingId, contentType) => dispatch(VetCheckActions.deleteVetCheckAutomation(mappingId, contentType)),
        setShowChangeVetCheckPassword: (show) => dispatch(VetCheckActions.setShowChangeVetCheckPassword(show)),
    })
)(ManageAutomations);
