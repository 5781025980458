import React from "react";
import {compose} from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./CorporateGroupClinicTable.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import orderBy from "lodash/orderBy";
import * as ProviderApi from "api/ProviderApi";
import Button from "components/common/Button";
import Image from "components/common/Image";
import SortableDataTable from "components/common/SortableDataTable";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import { formatUSD } from "utils/numeric";
import { getLinkLocation } from "utils/ProviderLinks";
import { downloadFile } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as UserPermissions from "constants/UserPermissions";

function CorporateGroupClinicTable(props) {

    const handleGoToCouponLibrary = (e, clinicId) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("couponLibrary", clinicId));
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("couponLibrary", props.nodeId, clinicId));
        }
    }

    const handleGoToCouponHistory = (e, clinicId) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("couponHistory", clinicId));
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("couponHistory", props.nodeId, clinicId));
        }
    }

    const handleDownloadExcel = async () => {
        toast.success("Generating Export...");
        const res = await ProviderApi.exportCorporateGroupDashboard(props.nodeId, "xlsx");
        downloadFile(res, "xlsx");
    }

    const getManufacturerColumn = (provider) => {
        const {providerId, nodeId, isAdmin } = props;
        return {
            topHeader: (
                <div className="text-center">
                    {/* Cannot view the corporate group drill down pages without the corporate group clinic view permission */}
                    {props.canViewCorporateGroupClinic ? (
                        <Link to={getLinkLocation("corporate-group", {
                            providerId,
                            nodeId,
                            isAdmin
                        }, `/${provider.id}`)} >

                            <Image style={{borderRadius: "5px"}} src={provider.smallImageUri} alt={provider.name} title={provider.name}/>
                        </Link>
                    ) : (
                        <Image style={{borderRadius: "5px"}} src={provider.smallImageUri} alt={provider.name} title={provider.name}/>
                    )}
                </div>
            ),
            name: (<div className="text-center">{provider.name}</div>),
            selector: `providerClinicEnrollments-${provider.id}`,
            key: `providerClinicEnrollments-${provider.id}`,
            sortable: true,
            sortValue: row => {
                const p = find(row.providerClinicEnrollments, {providerId: provider.id});
                if (!!p) {
                    if (!!p.couponDeactivationDate) {
                        return 2;
                    } else if (!!p.couponActivationDate) {
                        return 0;
                    } else if (!!p.enrollmentDate) {
                        return 1;
                    }
                }
                return 3
            },
            format: row => {
                const p = find(row.providerClinicEnrollments, {providerId: provider.id});
                if (!!p) {
                    if (!!p.couponDeactivationDate) {
                        return (
                            <div
                                className="text-center text-warn"
                                title={`Coupon Deactivation: ${moment(p.couponDeactivationDate).format("MM/DD/YYYY LTS")}`}
                            >
                                Ended
                            </div>
                        );
                    } else if (!!p.couponActivationDate) {
                        return (
                            <div
                                className="text-center"
                                title={`Coupon Activation: ${moment(p.couponActivationDate).format("MM/DD/YYYY LTS")}`}
                            >
                                <i className="fa fa-check-circle fa-2x text-success"/>
                            </div>
                        );
                    } else if (!!p.enrollmentDate) {
                        return (
                            <div
                                className="text-center"
                                title={`Enrollment: ${moment(p.enrollmentDate).format("MM/DD/YYYY LTS")}`}
                            >
                                Registered
                            </div>
                        );
                    }
                }
                return <div/>;
            },
            total: true,
            totalClasses: "text-center",
            rowTotal: row => {
                const p = find(row.providerClinicEnrollments, {providerId: provider.id});
                if (!!p) {
                    if (!!p.couponDeactivationDate) {
                        return 0;
                    } else if (!!p.couponActivationDate) {
                        return 1;
                    }
                }
                return 0;
            }
        }
    }

    const getIRManufacturerColumn = (provider) => {
        return {
            topHeader: (<div/>),
            name: (<div className="text-center">{provider.shortName} Instant Rebates</div>),
            selector: `providerClinicEnrollments-${provider.id}-InstantRebates`,
            key: `providerClinicEnrollments-${provider.id}-InstantRebates`,
            sortable: true,
            sortValue: row => {
                // Special ordering for the instant rebate column
                const p = find(row.providerClinicEnrollments, {providerId: provider.id});
                return !p.isIr;
            },
            format: row => {
                // Special checkbox for the instant rebate column
                const p = find(row.providerClinicEnrollments, {providerId: provider.id});
                if (p.isIr) {
                    return (
                        <div
                            className="text-center"
                            title={`Instant Rebate Activation: ${!!p.irDate ? moment(p.irDate).format("MM/DD/YYYY LTS") : "Unknown"}`}
                        >
                            <i className="fa fa-check-circle fa-2x text-success"/>
                        </div>
                    );
                }
                return <div/>;
            },
            total: true,
            totalClasses: "text-center",
            rowTotal: row => {
                // Special checkbox for the instant rebate column
                const p = find(row.providerClinicEnrollments, {providerId: provider.id});
                if(p.isIr) {
                    return 1;
                }
                return 0;
            }
        }
    }

    const MANUFACTURER_COLUMNS = [];
    forEach(props.manufacturers, provider => {
        MANUFACTURER_COLUMNS.push(getManufacturerColumn(provider));
        if(provider.hasInstantRebates) {
            MANUFACTURER_COLUMNS.push(getIRManufacturerColumn(provider));
        }
    })

    const COLUMNS = [{
        name: "Links",
        selector: "clinicId",
        key: "clinicId",
        format: row => (
            <div className="flex">
                {(props.canViewCouponAudit) && (
                    <Button
                        type="success"
                        iconOnly
                        text
                        onClick={(e) => handleGoToCouponHistory(e, row.clinicId)}
                        title="Coupon History"
                    >
                        <i className="fa fa-ticket margin-right-sm"/>
                    </Button>
                )}
                {(props.canViewCouponLibrary) && (
                    <Button
                        type="success"
                        iconOnly
                        text
                        onClick={(e) => handleGoToCouponLibrary(e, row.clinicId)}
                        title="Coupon Library"
                    >
                        <i className="fa fa-scissors"/>
                    </Button>
                )}
            </div>
        ),
    },{
        name: "Clinic",
        selector: "clinicName",
        key: "clinicName",
        sortable: true,
        searchable: true,
        format: row => (
            <div>
                <div className="text-primary">{row.clinicName}</div>
                <div style={{ fontSize: "0.7em" }}>{row.city}, {row.state}</div>
            </div>
        ),
    }, {
        name: (<div className="text-center">Pending Coupons</div>),
        selector: "data.couponsPending",
        key: "data.couponsPending",
        sortable: true,
        sortValue: row => row.data?.couponsPending || 0,
        format: row => (
            <div className="text-center">
                {row?.data?.couponsPending}
            </div>
        ),
        total: true,
        totalClasses: "text-center",
        rowTotal: row => row?.data?.couponsPending
    }, {
        name: (<div className="text-center">Pending Stray Coupons</div>),
        selector: "data.strayCouponsPending",
        key: "data.strayCouponsPending",
        sortable: true,
        sortValue: row => row.data?.strayCouponsPending || 0,
        format: row => (
            <div className="text-center">
                {row?.data?.strayCouponsPending}
            </div>
        ),
        total: true,
        totalClasses: "text-center",
        rowTotal: row => row?.data?.strayCouponsPending
    }, {
        name: (<div className="text-center">Dollar Amount Pending</div>),
        selector: "data.dollarAmountPending",
        key: "data.dollarAmountPending",
        sortable: true,
        sortValue: row => row.data?.dollarAmountPending || 0,
        format: row => (
            <div className="text-center">
                {formatUSD(row?.data?.dollarAmountPending || 0)}
            </div>
        ),
        total: true,
        totalClasses: "text-center",
        rowTotal: row => row?.data?.dollarAmountPending || 0,
        dollarTotal: true
    },
    ...MANUFACTURER_COLUMNS
    ];

    return (
        <div className={classnames(styles.root, "height-100-perc", {
            [styles.hasManufacturerList]: !!props.manufacturers?.length
        })}>
            <SortableDataTable
                changePagination
                striped
                green
                reverse
                header={<div/>}
                resultsPerPage={25}
                columns={COLUMNS}
                rawData={props.clinics}
                onClick={props.onRowClick}
                allowSearch
                showTotals
                downloadExcel={handleDownloadExcel}
            />
        </div>
    );
}

CorporateGroupClinicTable.propTypes = {
    providerId: PropTypes.number.isRequired,
    onRowClick: PropTypes.func,
    nodeId: PropTypes.number,
    isAdmin: PropTypes.bool,
}

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const corporateGroup = state.entities.providers[ownProps.providerId];
        let manufacturers = filter(state.entities.providers, p => {return p.visibleInCorporateDashboard && includes(corporateGroup.manufacturerParticipationList, p.id)});
        // Permissions
        const canViewCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canViewCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);

        return {
            userProfile,
            manufacturers,
            clinics: orderBy(state.entities.nodeClinics[ownProps.nodeId], ["name", "clinicId"], ["asc", "asc"]),
            search: state.entities.genericSearch,
            // Permissions
            canViewCouponAudit: canViewCouponAudit || canViewCorporateGroupClinic,
            canViewCorporateGroupClinic,
            canViewCouponLibrary,
        };
    }
);

export default compose(
    withRouter,
    connector
)(CorporateGroupClinicTable);
