import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";
import { sumBy, forEach } from "lodash"

export default function generatePdfFromComponent (id, fileName="unnamed") {
    const element = document.getElementById(id);

    //Use "addToPrintHeight" to correct the height of the view area for the canvas.
    const hiddenElements = element.getElementsByClassName("addToPrintHeight");
    const hiddenElementsHeight = hiddenElements.length ? sumBy(hiddenElements, e => e.offsetHeight) : 0;

    //Subtract the height of any "subtractFromPrintHeight" items
    const visibleElements = element.getElementsByClassName("subtractFromPrintHeight");
    const visibleElementsHeight = visibleElements.length ? sumBy(visibleElements, e => e.offsetHeight) : 0;

    // +40 is just to add a little extra space (Needed for DeathMatch Game Snapshot)
    const height = hiddenElementsHeight + element.offsetHeight - visibleElementsHeight + 40;

    html2canvas(element, {
        height: height,
        onclone: function(document) {
            //Fix the max-height for DeathMatch Game Snapshot.
            const top10 = document.querySelector(".GreenlineGameCard__top10");
            if(top10) {
                top10.style.maxHeight = "none";
            }

            //Add page breaks at the end of each page
            const pageBreaks = document.getElementsByClassName("pageBreak");
            if(!!pageBreaks.length) {
                forEach(pageBreaks, pageBreak => {
                    //For <td/> (found in DeathMatch Game Snapshot)
                    const tds = pageBreak.getElementsByTagName("td");
                    if(!!tds.length) {
                        forEach(tds, td => {
                            td.style.paddingTop = "40px";
                        })
                    }
                })
            }
        },
        //This fixes the position of a fixed element so scrolling doesn't return a blank screen
        scrollY: 0,
        useCORS: true,
    }).then(canvas => {
        const image = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm');
        const imgProps = pdf.getImageProperties(image);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        const pageHeight = 295;
        let heightLeft = pdfHeight;
        let position = 0;

        pdf.addImage(image, 'PNG', 0, position, pdfWidth, pdfHeight);
        heightLeft -= pageHeight;

        while (heightLeft > 0) {
          position = heightLeft - pdfHeight;
          pdf.addPage();
          pdf.addImage(image, 'PNG', 0, position, pdfWidth, pdfHeight);
          heightLeft -= pageHeight;
        }

        //Download the PDF
        pdf.save(`${fileName}.pdf`);

        //Open the PDF in a new tab
        // const blobPDF =  new Blob([ pdf.output('blob') ], { type : 'application/pdf'});
        // const blobUrl = URL.createObjectURL(blobPDF);
        // window.open(blobUrl);
    })
}
