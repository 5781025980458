import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as styles from "./SelectVoucherProvider.scss"
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as AdminActions from "actions/AdminActions";
import AccessDenied from "components/common/AccessDenied";
import AdminBase from "components/layout/AdminBase";
import Dropdown from "components/common/Dropdown";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function SelectVoucherProvider(props) {

    useEffect(() => {
        if(props.canViewVoucherManagement) {
            props.getPlans();
        }
    }, []);

    const PLAN_OPTIONS = map(orderBy(props.plans, ["planName"]), (plan) => {
        return {name: plan.planName, value: plan.planId};
    });

    const handleSelectProvider = ({value}) => {
        props.history.push(`/admin/vouchers/${value}/`);
    }

    return (
        <AdminBase pageTitle={"Voucher Management"}>
            {props.canViewVoucherManagement ? (
                <div className={styles.root}>
                    <div className={styles.startMessage}>
                        <div className={styles.startText}>
                            <h3>Select a Program to View Vouchers</h3>
                        </div>
                        <div className={styles.startDropdown}>
                            <Dropdown
                                options={PLAN_OPTIONS}
                                name="planId"
                                fullWidth
                                onChange={handleSelectProvider}
                                value={null}
                                placeholder="--Select a Program--"

                            />
                        </div>
                    </div>
                </div>
            ) : (
                <AccessDenied/>
            )}
        </AdminBase>
    )
}


export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        // PERMISSIONS
        const canViewVoucherManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.VOUCHER_MANAGEMENT, userProfile);
        return {
            plans: state.entities.plans,
            // PERMISSIONS
            canViewVoucherManagement,
        }
    },
    (dispatch) => ({
        getPlans: () => dispatch(AdminActions.getPlans()),
    })
)(SelectVoucherProvider);
