export const PROVIDER = "Provider";
export const CLINIC = "Clinic";
export const GREENLINE = "Greenline";
export const SYSTEM = "System";

//TODO: Will need to be updated with all possible roles
export const ROLES = [
    {name: "GL: Admin", value:"GLAdmin", userType:"GL", shortName: "Admin"},
    {name: "GL: NAM", value:"GLNAM", userType:"GL", shortName: "NAM"},
    {name: "GL: GBC", value:"GLGBC", userType:"GL", shortName: "GBC"},
    {name: "GL: IS Training", value:"GLISTraining", userType:"GL", shortName: "Implementation Specialist Trainer"},
    {name: "GL: IS (Level 1)", value:"GLIS1", userType:"GL", shortName: "Implementation Specialist Level 1"},
    {name: "GL: IS (Level 2)", value:"GLIS2", userType:"GL", shortName: "Implementation Specialist Level 2"},
    {name: "GL: IS (Level 3)", value:"GLIS3", userType:"GL", shortName: "Implementation Specialist Level 3"},
    {name: "GL: Lead (Level 1)", value:"GLLead1", userType:"GL", shortName: "Lead Level 1"},
    {name: "GL: Lead (Level 2)", value:"GLLead2", userType:"GL", shortName: "Lead Level 2"},
    {name: "GL: NAM", value:"ProviderGLNAM", userType:"MFR" , shortName: "GL Natl Account Mgr"},
    {name: "Clinic: Owner", value:"ClinicOwner", userType:"Clinic", shortName: "Owner"},
    {name: "Clinic: Admin", value:"ClinicAdmin", userType:"Clinic", shortName: "Admin"},
    {name: "Clinic: User", value:"ClinicUser", userType:"Clinic", shortName: "User"},
    {name: "Clinic: Demo", value:"ClinicDemo", userType:"Clinic", shortName: "Demo Account"},
    {name: "Clinic: Guest", value:"ClinicGuest", userType:"Clinic", shortName: "Guest Account"},
    {name: "MFR: Admin", value:"ProviderAdmin", userType:"MFR", shortName: "Admin"},
    {name: "MFR: Manager", value:"ProviderManager", userType:"MFR", shortName: "Manager"},
    {name: "MFR: User", value:"ProviderUser", userType:"MFR", shortName: "User"},
    {name: "MFR: Wellness Trainer", value: "ProviderWellnessTrainer", userType:"MFR", shortName: "Wellness Trainer"},
    {name: "Corporate Group Admin", value:"ProviderCorpGroupAdmin", userType:"CorporateGroup", shortName: "Admin"},
    {name: "Corporate Group Manager", value:"ProviderCorpGroupManager", userType:"CorporateGroup", shortName: "Manager"},
    {name: "Wellness Admin", value:"ProviderWellnessAdmin", userType:"Wellness", shortName: "Admin"},
];