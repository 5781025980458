import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import includes from "lodash/includes";
import DateInput from "components/common/DateInput";
import Dropdown from "components/common/Dropdown";
import TextBox from "components/common/TextBox";
import { ALL_SPECIES } from "constants/MappingTypes";

export default function PatientFields(props) {
    const TODAY = new moment();
    const MAX_DATE = moment(TODAY).format("MM/DD/YYYY");

    return (
        <>
            <TextBox
                value={props.initialData.patientName}
                onChange={props.onChange}
                disabled={props.readOnly}
                name="patientName"
                label="Name"
                required
                hasError={includes(props.errors, `[${props.index}].patientName`)}
            />
            {/*<TextBox*/}
            {/*    value={props.initialData.patientId}*/}
            {/*    onChange={props.onChange}*/}
            {/*    disabled={props.readOnly}*/}
            {/*    name="patientId"*/}
            {/*    label="Patient ID"*/}
            {/*/>*/}
            <div className="flex spaced-content">
                <div className="flex-1">
                    <Dropdown
                        value={props.initialData.species}
                        onChange={props.onChange}
                        disabled={props.readOnly}
                        name="species"
                        placeholder="Select a species"
                        label="Species"
                        options={ALL_SPECIES}
                        required
                        hasError={includes(props.errors, `[${props.index}].species`)}
                    />
                </div>
                <div className="flex-1">
                    <TextBox
                        value={props.initialData.weightInPounds}
                        onChange={props.onChange}
                        disabled={props.readOnly}
                        name="weightInPounds"
                        label="Weight:"
                        inputType="number"
                        min={0}
                        hasError={includes(props.errors, `[${props.index}].weightInPounds`)}
                    />
                </div>
            </div>
            <DateInput
                name="dob"
                value={props.initialData.dob}
                onChange={props.onChange}
                disabled={props.readOnly}
                label="Date of birth:"
                dateFormat={"MM/DD/YYYY"}
                maxDate={MAX_DATE}
                showDropdowns
                hasError={includes(props.errors, `[${props.index}].dob`)}
            />
            <TextBox
                value={props.initialData.remoteId}
                disabled={props.readOnly}
                onChange={props.onChange}
                name="remoteId"
                label="Patient ID"
                required
                hasError={includes(props.errors, `[${props.index}].remoteId`)}
            />
        </>
    );
}

PatientFields.defaultProps = {
    initialData: {},
    readOnly: false,
    index: 0,
    errors: [],
}

PatientFields.propTypes = {
    initialData: PropTypes.object,
    index: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
}
