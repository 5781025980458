import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./OnGardSetup.scss";
import orderBy from "lodash/orderBy";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import logger from "utils/logger";

export default function GameTaskSetup(props) {

    const tasks = useMemo(() => {
        return keyBy(props.data?.config?.Tasks||[], "id");
    }, [props.data.config]);

    const addOns = useMemo(() => {
        return keyBy(props.data?.addOnConfig||[], "id");
    }, [props.data.addOnConfig]);

    return (
        <div className={classnames(styles.root, "flex flex-wrap")}>
            {map(addOns, configItem => (
                <div className={classnames(styles.points, styles.borders, "flex-1")}>
                    <div>{configItem.description}</div>
                </div>
            ))}
        </div>
    );
}

GameTaskSetup.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
}
