import React, { useContext, useEffect, useState } from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./UnprocessedCoupons.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import values from "lodash/values";
import { windowSizeContext } from "AppRoot";
import * as CouponActions from "actions/CouponActions";
import AccessDenied from "components/common/AccessDenied";
import ApprovalButtons from "components/common/ApprovalButtons";
import ApproveDeclineSingleCoupon from "components/coupons/widgets/ApproveDeclineSingleCoupon/ApproveDeclineSingleCoupon";
import CouponAccordionList from "components/coupons/widgets/CouponAccordionList";
import FixCouponErrorForm from "components/coupons/widgets/FixCouponErrorForm";
import Image from "components/common/Image";
import JoyRide from "components/tour/widgets/JoyRide";
import Modal from "components/common/Modal";
import RunningTotals from "components/coupons/widgets/RunningTotals";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {
    TOUR_COUPON_HISTORY_PART_1,
    TOUR_COUPON_HISTORY_TRANSITION,
    TOUR_EDIT_COUPON_MODAL,
    TOUR_PENDING_COUPONS_PART_2,
    TOUR_PENDING_COUPONS_PART_3,
} from "utils/demoTourHelpers";
import { getPendingCouponCount, getUnprocessedCoupons} from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as DemoTourStates from "constants/DemoTourStates";
import { BOEHRINGER_INGELHEIM, ELANCO, MERCK } from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";

const BI_EXAMPLE_INVOICE = "https://gldevcdn.blob.core.windows.net/images/demo/boehringer_ingelheim_example_invoice.jpg";
const ELANCO_EXAMPLE_INVOICE = "https://gldevcdn.blob.core.windows.net/images/demo/elanco_example_invoice.jpg";
const MERCK_EXAMPLE_INVOICE = "https://gldevcdn.blob.core.windows.net/images/demo/merck_example_invoice.jpg";

function UnprocessedCoupons(props) {
    const [selectedRows, setSelectedRows] = useState({});
    const [currentVendor, setCurrentVendor] = useState(null);
    const [currentEdit, setCurrentEdit] = useState(null);
    const [currentDecline, setCurrentDecline] = useState(null);
    const [currentApprove, setCurrentApprove] = useState(null);

    // FOR DEMO ONLY
    const [tourSection, setTourSection] = useState(null);
    const [tourShowApprove, setTourShowApprove] = useState(false);
    const [tourShowDecline, setTourShowDecline] = useState(false);
    const [showInvoiceExample, setShowInvoiceExample] = useState(false);
    const [useFakeInitials, setUseFakeInitials] = useState(false);
    const [exampleInvoice, setExampleInvoice] = useState(null);
    const [tourCouponToEdit, setTourCouponToEdit] = useState(null);

    const context = useContext(windowSizeContext) || {};

    useEffect(() => {
        setTourDefaults()
    }, []);

    const setTourDefaults = () => {
        let newExampleInvoice = null;
        let newTourCouponToEdit = null;

        switch(props.userProfile.demoProvider) {
            case BOEHRINGER_INGELHEIM:
                newExampleInvoice = BI_EXAMPLE_INVOICE;
                newTourCouponToEdit = 20846058;
                break;
            case ELANCO:
                newExampleInvoice = ELANCO_EXAMPLE_INVOICE;
                newTourCouponToEdit = 13216538;
                break;
            case MERCK:
                newExampleInvoice = MERCK_EXAMPLE_INVOICE;
                newTourCouponToEdit = 14130646;
                break;
        }

        setExampleInvoice(newExampleInvoice);
        setTourCouponToEdit(newTourCouponToEdit);
    };

    useEffect(() => {
        if (props.canViewClinicCouponAudit) {
            props.getUnprocessedCoupons(props.clinicId);
        }
    }, [props.clinicId, props.canViewClinicCouponAudit]);

     useEffect(() => {
        if (props.canViewClinicCouponLibrary) {
            props.getPrograms(props.clinicId);
        }
    }, [props.clinicId, props.canViewClinicCouponLibrary]);

    const getTourSection = () => {
        let currentSection = null;
        if (!includes(props.completedTourSections, TOUR_PENDING_COUPONS_PART_2)) {
            currentSection = TOUR_PENDING_COUPONS_PART_2;
            // We need to re-fetch the data here if this section of the tour is restarted
            if (props.canViewClinicCouponAudit) {
                props.getUnprocessedCoupons(props.clinicId);
            }
        } else if (includes(props.completedTourSections, TOUR_EDIT_COUPON_MODAL)) {
            if (!includes(props.completedTourSections, TOUR_PENDING_COUPONS_PART_3)) {
                currentSection = TOUR_PENDING_COUPONS_PART_3
            } else if (!includes(props.completedTourSections, TOUR_COUPON_HISTORY_TRANSITION)) {
                currentSection = TOUR_COUPON_HISTORY_TRANSITION
            } else if (!includes(props.completedTourSections, TOUR_COUPON_HISTORY_PART_1)) {
                currentSection = TOUR_COUPON_HISTORY_PART_1
            }
        }

        setTourSection(currentSection);
    };

    useEffect(() => {
        //Show the correct part of the tour
        getTourSection();
    }, [props.completedTourSections]);


    const onStepComplete = async (finishedTourStep, index) => {
        if (finishedTourStep === TOUR_PENDING_COUPONS_PART_2) {
            switch (index) {
                case 1:  // Step 2 of 22
                    setShowInvoiceExample(true);
                    break;
                case 2:  // Step 3 of 22
                    setShowInvoiceExample(false);
                    break;
            }
        } else if (finishedTourStep === TOUR_PENDING_COUPONS_PART_3) {
            const vendorId = props.userProfile.demoProvider;
            const allCouponIds = flatMap(filter(props.coupons, {vendorId: vendorId}), "externalRedemptionId");

            switch (index) {
                case 0: // Step 9 of 22
                    //Select Row to Decline
                    handleRowSelectChanged(vendorId, [tourCouponToEdit]);
                    break;
                case 1: // Step 10 of 22
                    //Decline Button
                    setTourShowDecline(true);
                    break;
                case 2: // Step 12 of 22
                    //Enter Initials Button
                    setUseFakeInitials(true);
                    break;
                case 3: // Step 13 of 22
                    //Submit Button (close decline and clear use of fake initials)
                    handleDeclineCouponsClicked("GLN", [tourCouponToEdit]);
                    setTourShowDecline(false);
                    setUseFakeInitials(false);
                    break;
                case 8: // Step 18 of 22
                    //Select all other coupons
                    handleRowSelectChanged(vendorId, allCouponIds);
                    break;
                case 9: // Step 19 of 22
                    //Click Approve
                    setTourShowApprove(true);
                    break;
                case 10: // Step 20 of 22
                    //Enter Initials Button
                    setUseFakeInitials(true);
                    break;
                case 11: // Step 21 of 22
                    //Submit and Close Approve
                    handleApproveCouponsClicked("GLN", allCouponIds);
                    setTourShowApprove(false);
                    setUseFakeInitials(false);
                    break;
                default:
                    break;
            }
        }
    }

    const onSectionComplete = (finishedTourSection) => {
        if(finishedTourSection === TOUR_PENDING_COUPONS_PART_2) {
            // Open Edit Coupon Modal
            handleEditCouponRedemption(tourCouponToEdit);
        }
    }

    // Dirt simple text filtering
    const filterByText = (coupons) => {
        if (!props.search) {
            return coupons;
        }

        const text = props.search.toLowerCase();
        return filter(coupons, coupon => {
            return (
                includes(coupon?.invoiceNumber?.toString(), text) ||
                includes(coupon?.lastName?.toLowerCase(), text) ||
                includes(coupon?.petName?.toLowerCase(), text) ||
                includes(coupon?.invoiceStaffMember?.toLowerCase(), text) ||
                includes(coupon?.product?.toLowerCase(), text) ||
                includes(coupon?.offerCode?.toLowerCase(), text)
            );
        });
    }

    const getSelectedCoupons = () => {
        if(!currentVendor) {
            return [];
        }

        // return only the coupons for the selected Manufacturer
        return selectedRows[currentVendor];
    }

    const handleSelectionChanged = (id, isOpen, closedId) => {
        const currentId = isOpen ? id : null;
        setCurrentVendor(currentId);
        // may want to do something with closedId one day... for now we have it.
    }

    const getApprovalLock = () => {
        // Must have a vendor open, must have selected rows
        return !currentVendor || !selectedRows[currentVendor] || !selectedRows[currentVendor].length;
    }

    const handleRowSelectChanged = (vendorId, rows) => {
        const selected = {
            ...selectedRows,
            [vendorId]: rows,
        };

        setSelectedRows(selected);
    }

    const handleApproveCouponsClicked = (initials, coupons=[]) => {
        if (props.canEditClinicCouponAudit) {
            const couponList = coupons.length ? coupons : getSelectedCoupons();
            const remainingVendorCoupons = filter(props.coupons, c => {
                return (
                    (currentVendor === c.vendorId) &&
                    !includes(couponList, c.externalRedemptionId)
                )
            });
            props.processCoupons(initials, couponList, props.clinicId);
            clearSelectedCoupons();
            if(!remainingVendorCoupons?.length) {
                setCurrentApprove(null);
                setCurrentVendor(null);
            } else {
                setCurrentApprove(null);
            }
        }
    }

    const handleDeclineCouponsClicked = (initials, coupons=[]) => {
        if(props.canEditClinicCouponAudit) {
            const couponList = coupons.length ? coupons : getSelectedCoupons();
            const remainingVendorCoupons = filter(props.coupons, c => {return (currentVendor === c.vendorId) && !includes(couponList, c.externalRedemptionId)});
            props.declineCoupons(initials, couponList, props.clinicId);
            clearSelectedCoupons();

            if(!remainingVendorCoupons?.length) {
                setCurrentDecline(null);
                setCurrentVendor(null);
            } else {
                setCurrentDecline(null);
            }
        }
    }

    const clearSelectedCoupons = () => {
        const selected = {
            ...selectedRows,
            [currentVendor]: null
        };

        setSelectedRows(selected);
    }

    const handleEditCouponRedemption = (id) => {
        if (props.canViewClinicCouponAudit) {
            setCurrentEdit(id);
        }
    }

    const renderCouponList = () => {
        let couponList = values(props.coupons);
        if (couponList && couponList.length > 0) {
            if (props.search) {
                couponList = filterByText(couponList);
            }
        }
        if (!couponList.length) {
            return(
                <div className={styles.noCoupons}>
                    <span>No Coupons to show! Try changing your search.</span>
                </div>
            );
        }
        const providerList = flatMap(values(props.programs), "provider");

        return (
            <CouponAccordionList
                clinicId={props.clinicId}
                showStatusIcon={false}
                coupons={couponList}
                selectedRows={selectedRows}
                onSelect={props.canEditClinicCouponAudit ? handleSelectionChanged : undefined}
                onRowSelectChange={props.canEditClinicCouponAudit ? handleRowSelectChanged : undefined}
                onEditClick={handleEditCouponRedemption}
                onApproveClick={(couponId) => setCurrentApprove(couponId)}
                onDeclineClick={(couponId) => setCurrentDecline(couponId)}
                providerList={providerList}
                canViewClinicCouponAudit={props.canViewClinicCouponAudit}
                canEditClinicCouponAudit={props.canEditClinicCouponAudit}
            />
        );
    }

    if (!props.canViewClinicCouponAudit) {
        return <AccessDenied customMessage="You do not have permission to view Pending Coupons"/>;
    } else if(!props.coupons) {
        return <SpinnerTakeover show/>;
    }

    const approvalLocked = getApprovalLock();

    const handleAfterSaveCouponEdit = () => {
        setCurrentEdit(null);
        props.getUnprocessedCoupons(props.clinicId);
    };

    return (
        <>
            <div className={styles.root}>
                <div className={styles.topLine}>
                    {!context.isPhone && (
                        <h2><i className="fas fa-ticket" /> Pending Coupons</h2>
                    )}
                    {(props.canEditClinicCouponAudit) && (
                        <div style={!(props.couponCount || props.canEditClinicCouponAudit) ? {display: "none"} : {}}>
                            <ApprovalButtons
                                onApprove={handleApproveCouponsClicked}
                                onDecline={handleDeclineCouponsClicked}
                                locked={approvalLocked}
                            />
                        </div>
                    )}
                    {!!props.isModal && (
                        <Link
                            className={classnames("btn btn-color-outline btn-wide btn-full", styles.viewAll)}
                            to={"/coupons"}
                        >
                            View All
                        </Link>
                    )}

                </div>
                <RunningTotals
                    clinicId={props.clinicId}
                />
                {renderCouponList()}
                {props.canEditClinicCouponAudit && (
                    <div
                        id="tour_bottom_approval_buttons"
                        className="flex"
                    >
                        <ApprovalButtons
                            onApprove={handleApproveCouponsClicked}
                            onDecline={handleDeclineCouponsClicked}
                            locked={approvalLocked}
                            showApproveByDefault={tourShowApprove}
                            showDeclineByDefault={tourShowDecline}
                            useFakeInitials={useFakeInitials}
                        />
                    </div>
                )}
            </div>
            {!!tourSection && (
                <JoyRide
                    key={tourSection}
                    tourSection={tourSection}
                    onStepComplete={onStepComplete}
                    onSectionComplete={onSectionComplete}
                    disableScrolling={false}
                />
            )}
            <Modal
                show={!!currentEdit}
                onClose={() => setCurrentEdit(null)}
                modalTitle="Edit Coupon"
                mediumSmall
                tall
                clickOutsideToClose={false}
                modalId="demoEditCouponModal"
            >
                {!!currentEdit && (
                    <div className="padding-lg">
                        <FixCouponErrorForm
                            couponId={currentEdit}
                            onDecline={(couponId) => setCurrentDecline(couponId)}
                            onAfterSave={handleAfterSaveCouponEdit}
                            onClose={() => setCurrentEdit(null)}
                            completedTourSections={props.completedTourSections}
                            tourStarted={props.tourStarted}
                            canEditClinicCouponAudit={props.canEditClinicCouponAudit}
                            canEditClinicManagement={props.canEditClinicManagement}
                        />
                    </div>
                )}
            </Modal>
            {/*Modal for Declining a Single Coupon*/}
            <Modal
                show={!!currentDecline}
                onClose={() => setCurrentDecline(null)}
                modalTitle={"Decline Coupon"}
                mediumSmall
                tall
                clickOutsideToClose={false}
            >
                {!!currentDecline && (
                    !props.canEditClinicCouponAudit ? (
                        <AccessDenied/>
                    ) : (
                        <ApproveDeclineSingleCoupon
                            couponId={currentDecline}
                            coupon={find(props.coupons, {id: currentDecline})}
                            onClose={() => setCurrentDecline(null)}
                            onSubmit={handleDeclineCouponsClicked}
                        />
                    )
                )}
            </Modal>
            {/*Modal for Approving a single coupon*/}
            <Modal
                show={!!currentApprove}
                onClose={() => setCurrentApprove(null)}
                modalTitle={"Approve Coupon"}
                mediumSmall
                tall
                clickOutsideToClose={false}
            >
                {!!currentApprove && (
                    !props.canEditClinicCouponAudit ? (
                        <AccessDenied/>
                    ) : (
                        <ApproveDeclineSingleCoupon
                            couponId={currentApprove}
                            coupon={find(props.coupons, {id: currentApprove})}
                            onClose={() => setCurrentApprove(null)}
                            onSubmit={handleApproveCouponsClicked}
                            isApprove
                        />
                    )
                )}
            </Modal>
            {/*For DEMO only*/}
            <Modal
                show={!!showInvoiceExample}
                onClose={() => setShowInvoiceExample(false)}
                modalTitle={"Example Invoice"}
                mediumSmall
                tall
                clickOutsideToClose={true}
            >
                <div id="exampleInvoice">
                    {!!exampleInvoice && (
                        <Image
                            src={exampleInvoice}
                            alt="Picture of an example invoice"
                            style={{maxWidth: "100%"}}
                        />
                    )}
                </div>
            </Modal>
        </>
    );
}

UnprocessedCoupons.propTypes = {
    isModal: PropTypes.bool,
    clinicId: PropTypes.number,
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        //  Permissions
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewClinicCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canEditClinicCouponAudit = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            userProfile,
            coupons: getUnprocessedCoupons(state),
            // couponLibrary: getCouponLibrary(ownProps.clinicId),
            couponCount: getPendingCouponCount(state),
            programs: state.entities.programs,
            search: state.entities.coupons.query,
            completedTourSections: state.demo.completedTourSections,
            tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),
            clinic: state.entities.clinics[ownProps.clinicId],
            //  Permissions
            canEditClinicCouponAudit,
            canEditClinicManagement,
            canViewClinicCouponAudit: canViewClinicCouponAudit || canViewCorporateGroupClinic,
            canViewClinicCouponLibrary,
        }
    },
    (dispatch) => ({
        getUnprocessedCoupons: (clinicId) => dispatch(CouponActions.getUnprocessedCoupons(clinicId)),
        // getCouponDetails: (id) => dispatch(CouponActions.getUnprocessedCouponDetails(id)),
        getPrograms: (clinicId) => dispatch(CouponActions.getPrograms(clinicId)),
        processCoupons: (initials, coupons, clinicId) => dispatch(CouponActions.processCoupons(initials, coupons, clinicId)),
        declineCoupons: (initials, coupons, clinicId) => dispatch(CouponActions.declineCoupons(initials, coupons, clinicId)),
    }),
);

export default compose(
    withRouter,
    connector
)(UnprocessedCoupons);
