import React  from "react";
import PropTypes from "prop-types";
import styles from "./ProviderGamesAccordionList.scss";
import map from "lodash/map";
import Accordion from "components/common/Accordion";
import AccordionSection from "components/common/AccordionSection";
import ProviderGameInfoTable from "components/provider/widgets/ProviderGameInfoTable";
import pluralizeWord from "utils/pluralizeWord";
import * as CATEGORY from "constants/ProviderGameCategoryIds";

const SECTIONS = [
    {
        id: CATEGORY.PENDING, // AKA "draft"
        label: "Pending Games",
    }, {
        id: CATEGORY.CURRENT,
        label: "Current Games",
    }, {
        id: CATEGORY.COMPLETED,
        label: "Completed Games",
    }, {
        id: CATEGORY.HISTORICAL,
        label: "Historic Games",
    }, {
        id: CATEGORY.TEMPLATES,
        label: "Templates",
    },
]

export default function ProviderGamesAccordionList(props) {
    const renderSection = (section, categories) => {
        const games = categories[section.id];
        const hasGames = !!games;
        const gameCount = hasGames ? games.length : 0;

        const renderSubText = (
            <div className="flex-none no-wrap text-mid-gray text-sm">
                {section.id === CATEGORY.TEMPLATES ? (
                    !!gameCount ? `${gameCount} ${pluralizeWord(gameCount, "template")}` : "none"
                ) : (
                    !!gameCount ? `${gameCount} ${pluralizeWord(gameCount, "game")}` : "none"
                )}
            </div>
        )

        return (
            <AccordionSection
                key={section.id}
                id={section.id}
                header={section.label}
                headerSubtext={renderSubText}
            >
                {!hasGames && <div>No data available</div>}
                {hasGames && (
                    <ProviderGameInfoTable
                        games={games}
                        nodeId={props.nodeId}
                        providerId={props.providerId}
                        userId={props.userId}
                        isTemplates={(section?.id === CATEGORY.TEMPLATES)}
                    />
                )}
            </AccordionSection>
        );
    };

    const {games} = props;
    const hasGames = !!(games && ((games.pendingGames && games.pendingGames.length) || (games.currentGames && games.currentGames.length) || (games.historicalGames && games.historicalGames.length) || (games.completedGames && games.completedGames?.length)));

    return (
        <div className="full-width full-height">
            <Accordion
                theme={{
                    header: styles.header,
                    collapse: styles.collapse,
                }}
            >
                {hasGames ? (
                    map(SECTIONS, section => renderSection(section, props.games || []))
                ) : (
                    <h2 className="flex justify-flex-center align-center margin-lg text-italic text-gray">No Games for this User</h2>
                )}
            </Accordion>
        </div>
    );
}

ProviderGamesAccordionList.propTypes = {
    games: PropTypes.object,
    providerId: PropTypes.number,
    nodeId: PropTypes.number,
    userId: PropTypes.number,
    isAdmin: PropTypes.bool,
};
