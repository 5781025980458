exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ClinicSettingsForm__root .form-group label{width:160px}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/clinic/widgets/ClinicSettingsForm/ClinicSettingsForm.scss"],"names":[],"mappings":"AAAA,4CAA0C,WAAW,CAAC","file":"ClinicSettingsForm.scss","sourcesContent":[".root :global(.form-group) :global(label){width:160px}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "ClinicSettingsForm__root"
};