import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./GameTemplateSelector.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import includes from "lodash/includes";
import map from "lodash/map";
import * as ProviderActions from "actions/ProviderActions";
import GameTemplateSelectorButton from "components/provider/widgets/GameTemplateSelectorButton";
import * as ProviderLinks from "utils/ProviderLinks";
import { GAME_TYPES } from "constants/GameTypes";
import { mapProviderGameTemplateFromServerToApp } from "data/serverMapping";

// This is the display for selecting a type of provider game template to edit/add
function GameTemplateSelector(props) {
    const params = useParams();
    const { pathname } = useLocation();
    const providerId = Number(params?.providerId) || props.primaryProviderId || null;
    const isDemo = includes(pathname, "demo");
    const emptyTemplateLink = "game-template";
    const preBuiltTemplateLink = "game-template";
    const savedTemplateLink = "game-copy";

    useEffect(() => {
        if (providerId) {
            if (!isDemo && !props.gameTemplates) {
                props.getGameTemplates(props.userId);
            } else {
                //Have to reload previous games every time this is opened to check for new templates without refreshing
                props.getPreviousGames(props.userId, isDemo);

            }
        }
    }, [providerId, props.gameTemplates]);

    // Hard coding "special" templates (seems to be the case for the GLQA site, too, since there are no API calls to get them that I saw)
    const empty = [
        mapProviderGameTemplateFromServerToApp({
            greenlineGameTemplateId: "growth-doses",
            displayTitle: "Growth Doses",
            title: "Single Clinic",
            gameType: GAME_TYPES.GROWTH,
            canUseAsTemplate: true,
            isTemplate: true,
        }),
        mapProviderGameTemplateFromServerToApp({
            greenlineGameTemplateId: "challenge-match",
            displayTitle: "Challenge Match",
            title: "2 or More Clinics",
            gameType: GAME_TYPES.DEATH_MATCH,
            canUseAsTemplate: true,
            isTemplate: true,
        }),
        // mapProviderGameTemplateFromServerToApp({
        //     greenlineGameTemplateId: "on-gard",
        //     displayTitle: "Operation On Gard",
        //     title: "Single Clinic",
        //     gameType: GAME_TYPES.ON_GARD,
        //     canUseAsTemplate: true,
        //     isTemplate: true,
        // }),
    ];

    const filteredTemplates = filter(props.gameTemplates, t => !t.isDeleted); // Server shouldn't send us "deleted" templates
    const filteredPreviousGames = filter(props.previousGames, g => g.canUseAsTemplate);
    const prebuilt = filteredTemplates;
    const saved = filteredPreviousGames;

    const getGameLink = (link, templateId) => {
        if (props.userId) {
            return ProviderLinks.getLinkLocation(link, {
                providerId: providerId,
                nodeId: props.nodeId,
                isAdmin: props.isAdmin,
                isDemo,
                templateId,
            }, `?userId=${props.userId}`)
        } else {
            return ProviderLinks.getLinkLocation(link, {
                providerId: providerId,
                nodeId: props.nodeId,
                isAdmin: props.isAdmin,
                isDemo,
                templateId
            });
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.section}>
                <h2>Empty Templates</h2>
                <div className={styles.grid}>
                    {map(empty, (template) => (
                        <GameTemplateSelectorButton
                            key={`empty${template.greenlineGameTemplateId}`}
                            title={template.displayTitle}
                            desc1={template.title}
                            linkTo={getGameLink(emptyTemplateLink, template.greenlineGameTemplateId)}
                        />
                    ))}
                </div>
            </div>

            {!isDemo && (
                <div className={styles.section}>
                    <h2>Pre-Built Templates</h2>
                    <div className={styles.grid}>
                        {map(prebuilt, (template) => (
                            <GameTemplateSelectorButton
                                key={`prebuilt${template.greenlineGameTemplateId}`}
                                title={template.displayTitle}
                                desc1={template.title}
                                desc2={template.description}
                                linkTo={getGameLink(preBuiltTemplateLink, template.greenlineGameTemplateId)}
                            />
                        ))}
                    </div>
                </div>
            )}

            <div className={styles.section}>
                <h2>Your Saved Templates</h2>
                <div className={styles.grid}>
                    {map(saved, (template) => (
                        <GameTemplateSelectorButton
                            key={`saved${template.greenlineGameId}`}
                            title={template.name}
                            desc1={template.title}
                            desc2={template.description}
                            linkTo={getGameLink(savedTemplateLink, template.greenlineGameId)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}

GameTemplateSelector.propTypes = {
    userId: PropTypes.number.isRequired,
    nodeId: PropTypes.number,
    isAdmin: PropTypes.bool,
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const providerData = find(userProfile?.providers, { isPrimaryLocation: true });

        return {
            userProfile,
            primaryProviderId: providerData ? Number(providerData.id) : null,
            gameTemplates: state.entities.providerGames?.gameTemplates,
            previousGames: state.entities.providerGames?.previousGames,
        };
    },

    (dispatch) => ({
        getGameTemplates: (userId) => dispatch(ProviderActions.getGameTemplates(userId)),
        getPreviousGames: (userId, isDemo) => dispatch(ProviderActions.getPreviousGames(userId, isDemo)),
    }),
)(GameTemplateSelector);
