import * as ActionTypes from "constants/ActionTypes";

export function markTourAsStarted(tourSection) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.TOUR_STARTED,
        });
    };
}

export function markTourStepAsComplete(tourSection, stepIndex) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.TOUR_STEP_COMPLETE,
            section:tourSection,
            step: stepIndex
        });
    };
}

export function markTourSectionAsComplete(tourSection) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.TOUR_SECTION_COMPLETED,
            tourSection
        });
    };
}

export function markTourAsComplete() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.TOUR_COMPLETE,
        });
    };
}
export function markTourAsClosed() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.TOUR_CLOSED,
        });
    };
}

export function restartSections(sections) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.TOUR_SECTIONS_RE_STARTED,
            sections
        });
    };
}

export function restartTour() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.TOUR_RE_STARTED,
        });
    };
}