import React, { useContext, useEffect, useState } from "react";
import {connect} from "react-redux";
import { useLocation } from "react-router";
import { Link, NavLink } from "react-router-dom";
import classnames from "classnames";
import styles from "./AdminSidebar.scss";
import find from "lodash/find";
import map from "lodash/map";
import { windowSizeContext } from "AppRoot";
import Button from "components/common/Button";
import ClinicSettingsButton from "components/clinic/widgets/ClinicSettingsButton";
import Image from "components/common/Image";
import InformationBadge from "components/common/InformationBadge";
import * as ClinicData from "utils/ClinicData";
import { setQueryStringParams } from "utils/urls";

const watermark = require("components/common/branding/watermark_background.jpg");
const CLINIC_LOGO_FALLBACK = require("components/common/branding/greenline-logo-circle.png");

const DEFAULT_CLINIC_FILTERS = "filters=%5B%7B%22order%22%3A0%2C%22id%22%3A%22Active%22%2C%22isComplete%22%3Atrue%2C%22equality%22%3A%22%3D%3D%22%2C%22level1Values%22%3A%5B%7B%22value%22%3A%22Active%22%7D%5D%7D%5D&orderBy=id&orderDir=desc";

const LINKS = [
    {
        icon: "far fa-tachometer-fast",
        id: "dashboard",
        location: "/",
        text: "Dashboard",
    }, {
        icon: "far fa-users",
        id: "users",
        location: "/admin/users",
        text: "User Management",
    }, {
        icon: "far fa-building",
        id: "clinics",
        location: `/admin/clinics`,
        text: "Clinic Management",
    }, {
        icon: "far fa-city",
        id: "providers",
        location: `/admin/providers`,
        text: "Providers",
    }, {
        icon: "far fa-ticket",
        id: "coupons",
        location: "/admin/coupons/reprocess",
        text: "Coupon Reprocessing",
    }, {
        icon: "far fa-bell",
        id: "notifications",
        location: "/admin/notifications",
        text: "Notification Management",
    },
];

function AdminSidebar(props) {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const collapseBtnIcons = collapsed ? "fas fa-chevron-circle-left" : "fas fa-chevron-circle-right";

    const context = useContext(windowSizeContext) || {};
    const collapsible = props.collapsible || context.isPhone;

    useEffect(() => {
        if(collapsible) {
            setCollapsed(true);
        }
    }, [collapsible]);


    const getExactMatch = (link) => {
        if (!location ) {
            return {};
        }

        const exact = location.pathname === link;
        const partial = location.pathname.indexOf(link) > -1;
        return {
            partial,
            exact
        }
    }

    const getPendingCount = (linkId) => {
        switch (linkId) {
            default:
                return null;
        }
    }

    const getBadgeInfo = (linkId, location) => {
        return null;
    }

    const renderNavItems = () => {
        const navItems = map(LINKS, link => {
            let { partial, exact } = getExactMatch(link.location);
            const pendingCount = getPendingCount(link.id);
            let showNavBadge = pendingCount > 0;
            let linkLocation = link.location;

            if(link.id === "clinics") {
                // Go to the favorite clinic view if there is one
                if(!!props.favoriteClinicView?.value) {
                    const filters = JSON.stringify(props.favoriteClinicView.value.filters);
                    const newQs = setQueryStringParams("", {...props.favoriteClinicView.value, filters: filters, viewId: props.favoriteClinicView.userPreferenceId});
                    linkLocation = `${link.location}?${newQs}`;
                } else {
                    // This sets the filter activeStatus === "Active", orderBy = "id", orderDir = "desc"
                    linkLocation = `${link.location}?${DEFAULT_CLINIC_FILTERS}`;
                }
            }

            if(!link.subItems) {
                return (
                    <NavLink className={classnames(styles.navItem, {
                        [styles.selected]: exact,
                    })}
                        key={`navItem_${link.id}`}
                        to={linkLocation}
                    >
                        <span className={styles.icon}><i className={link.icon} /></span>
                        <span className={styles.text}>{link.text}</span>
                        {showNavBadge && <InformationBadge message={`${pendingCount}`} />}
                    </NavLink>
                )
            }

            // Has SubLinks
            const showSubs = partial || exact;
            showNavBadge = pendingCount > 0 && !showSubs;

            const subLinks = map(link.subItems, (item,index) => {
                let { exact: subExact } = getExactMatch(item.location);
                return (
                    <NavLink className={classnames(styles.navItem, {
                        [styles.selected]: subExact,
                    })}
                        key={`navItem_${link.id}_sub_${index}`}
                        to={item.location}
                    >
                        <span className={styles.icon}><i className="fas fa-circle"/></span>
                        <span className={styles.text}>{item.text}</span>
                        {getBadgeInfo(link.id, item.location)}
                    </NavLink>
                )

            })

            return (
                <div className={styles.subNavList} key={`navItem_${link.id}`} >
                    <NavLink to={link.location} className={classnames(styles.navItem, {
                        [styles.selected]: partial || exact,
                    })} >
                        <span className={styles.icon}><i className={link.icon} /></span>
                        <span className={styles.text}>{link.text}</span>
                        {showNavBadge && <InformationBadge message={`${pendingCount}`} />}
                    </NavLink>
                    {showSubs && <div className={styles.subLinks}>
                        {subLinks}
                    </div>}
                </div>
            );
        });

        return navItems;
    }

    const renderClinicInfo = () => {
        if(!props.clinicInfo) {
            return null;
        }

        return (
            <>
                <div className={styles.clinicBrand}>
                    <div className={styles.logo}>
                        <Image
                            src={props.clinicInfo.imageUrl}
                            fallbackSrc={CLINIC_LOGO_FALLBACK}
                            alt={`${props.clinicInfo.name} Logo`}
                        />
                    </div>
                    <div className={styles.clinic}>
                        <span>
                            {props.clinicInfo.name}
                        </span>
                    </div>
                </div>
                <div className={styles.clinicLinks}>
                    <Link to={"/admin/users"}>
                        {collapsed ?
                            <i className="fas fa-users" />
                            :
                            <span className={styles.text}>Users</span>
                        }

                    </Link>
                    <ClinicSettingsButton collapsed={collapsed} className={styles.text} />
                </div>
            </>
        )

    }

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    }

    return(
        <nav className={classnames(styles.root, {
            [styles.collapsible] : collapsible,
        })}>
            <div className={classnames(styles.menuBar, {
                [styles.collapsed]: collapsed && collapsible
            })}>
                {collapsible && <Button
                    text
                    className={styles.collapseButton}
                    onClick={toggleCollapsed}
                >
                    <i className={collapseBtnIcons} />
                </Button>}
                <div className={styles.navItems}>
                    {renderNavItems()}
                </div>
                <div className={styles.clinicInfo}>
                    {renderClinicInfo()}
                </div>
                <div className={styles.bgLogo}>
                    <img src={watermark} alt="Greenline Watermark" />
                </div>
            </div>
        </nav>
    );
}

export default connect(
    (state) => ({
        clinicInfo: ClinicData.getClinicInfo(state),
        favoriteClinicView: find(state.entities.clinicViews, "isFavorite"),
    })
)(AdminSidebar);
