export const LAST_WEEK = "-7d";
export const MTD = "mtd";
export const LAST_30_DAYS = "-30d";
export const LAST_90_DAYS = "-90d";
export const YTD = "ytd";
export const ALL = "bot"
export const CUSTOM = "daterange";

export const DESCRIPTIONS = {
    [LAST_WEEK]: "in the last week",
    [MTD]: "so far this month",
    [LAST_30_DAYS]: "in the last 30 days",
    [LAST_90_DAYS]: "in the last 90 days",
    [YTD]: "so far this year",
    [ALL]: "",
    [CUSTOM]: "in the range you selected",
}

export const NOT_YET = "not yet";
export const NEVER = "never";
