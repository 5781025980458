exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".GamesDashboardWidget__root{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.GamesDashboardWidget__root .GamesDashboardWidget__gameCards{margin-bottom:0.5em;-ms-flex-pack:justify;justify-content:space-between;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/dashboard/widgets/GamesDashboardWidget.scss"],"names":[],"mappings":"AAAA,4BAAM,oBAAoB,aAAa,0BAA0B,qBAAqB,CAAC,6DAAiB,oBAAoB,sBAAsB,8BAA8B,oBAAoB,aAAa,0BAA0B,qBAAqB,CAAC","file":"GamesDashboardWidget.scss","sourcesContent":[".root{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.root .gameCards{margin-bottom:0.5em;-ms-flex-pack:justify;justify-content:space-between;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "GamesDashboardWidget__root",
	"gameCards": "GamesDashboardWidget__gameCards"
};