import React, {useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {cloneDeep, filter, map, merge, orderBy} from "lodash";
import DataTable from "components/common/datatable/DataTable";
import TextBox from "components/common/TextBox";
import CheckboxInput from "components/common/CheckboxInput";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import * as SERVICE_TYPES from "constants/ServiceTypes";

function PlanServices(props) {
    const [formData, setFormData] = useState({});

    const combinedData = cloneDeep(props.serviceTypes);
    merge(combinedData, formData);

    const handleChange = ({name, value}, row) => {
        let newData;
        newData = {
            ...combinedData[row.planServiceTypeId],
            [name]: value
        }
        const newFormData = {
            ...formData,
            [row.planServiceTypeId]: {
                ...newData
            }
        }
        setFormData(newFormData);
        props.handleChange({serviceType: newData});
    }

    const SERVICE_COLUMNS = [{
        name: "Service",
        selector: "serviceTypeName",
        key: "serviceTypeName",
    }, {
        name: "Is Unlimited?",
        selector: "isUnlimited",
        key: "isUnlimited",
        format: row => (
            <CheckboxInput
                name="isUnlimited"
                checked={row.isUnlimited}
                onChange={(data) => handleChange(data, row)}
                disabled={props.readOnly}
            />
        )
    }, {
        name: "Visits/Uses",
        selector: "maxQuantityAllowedInTerm",
        key: "maxQuantityAllowedInTerm",
        format: row => (
            <TextBox
                name="maxQuantityAllowedInTerm"
                value={row.isUnlimited ? "" : row.maxQuantityAllowedInTerm}
                onChange={(data) => handleChange(data, row)}
                inputType="number"
                min={0}
                disabled={row.isUnlimited || props.readOnly}
            />
        )
    }];

    const PRODUCTS_COLUMNS = [{
        name: "Product",
        selector: "serviceTypeName",
        key: "serviceTypeName",
    }, {
        name: "Is Unlimited?",
        selector: "isUnlimited",
        key: "isUnlimited",
        format: row => (
            <CheckboxInput
                name="isUnlimited"
                checked={row.isUnlimited}
                onChange={(data) => handleChange(data, row)}
                disabled={props.readOnly}
            />
        )
    }, {
        name: "Months Supply",
        selector: "maxQuantityAllowedInTerm",
        key: "maxQuantityAllowedInTerm",
        format: row => (
            <TextBox
                name="maxQuantityAllowedInTerm"
                value={row.isUnlimited ? null : row.maxQuantityAllowedInTerm}
                onChange={(data) => handleChange(data, row)}
                inputType="number"
                min={0}
                disabled={row.isUnlimited || props.readOnly}
            />
        )
    }];

    const ADD_ON_COLUMNS = [{
        name: "Add-on",
        selector: "serviceTypeName",
        key: "serviceTypeName",
    }, {
        name: "Is Unlimited?",
        selector: "isUnlimited",
        key: "isUnlimited",
        format: row => (
            <CheckboxInput
                name="isUnlimited"
                checked={row.isUnlimited}
                onChange={(data) => handleChange(data, row)}
                disabled={props.readOnly}
            />
        )
    }, {
        name: "Visits/Uses",
        selector: "maxQuantityAllowedInTerm",
        key: "maxQuantityAllowedInTerm",
        format: row => (
            <TextBox
                name="maxQuantityAllowedInTerm"
                value={row.isUnlimited ? "" : row.maxQuantityAllowedInTerm}
                onChange={(data) => handleChange(data, row)}
                inputType="number"
                min={0}
                disabled={row.isUnlimited || props.readOnly}
            />
        )
    }, {
        name: <div>Requires<br/>Enrollment<br/>Trigger?</div>,
        selector: "requiresEnrollmentTrigger",
        key: "requiresEnrollmentTrigger",
        format: row => (
            <CheckboxInput
                name="requiresEnrollmentTrigger"
                checked={row.requiresEnrollmentTrigger}
                onChange={(data) => handleChange(data, row)}
                disabled={props.readOnly}
            />
        )
    }, {
        name: "Term Length",
        selector: "termLength",
        key: "termLength",
        format: row => "Duration of Plan"
    }];

    const getColumns = (category) => {
        switch (category) {
            case SERVICE_TYPES.PRODUCTS:
                return PRODUCTS_COLUMNS
            case SERVICE_TYPES.ADD_ON:
                return ADD_ON_COLUMNS;
            case SERVICE_TYPES.SERVICES:
            case SERVICE_TYPES.SPECIAL:
            default:
                return SERVICE_COLUMNS;
        }
    }

    return map(props.serviceCategories, category => (
        <div className="margin-bottom-md">
            <DataTable
                title={category}
                columns={getColumns(category)}
                data={orderBy(filter(combinedData, { categoryName: category }), "serviceTypeName")}
                pagination={false}
            />
        </div>
    ));
}

PlanServices.propTypes = {
    serviceTypes: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewProductMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);
        return {
            canViewProductMapping,
        }
    }
)(PlanServices);
