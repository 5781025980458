import Button from "components/common/Button";
import React from "react";
import { Link } from "react-router-dom";

export default function AdminClinicMarkerInfoWindow(props) {
    const { clinic } = props;

    if (!clinic) {
        return null;
    }

    return (
        <div className="full-width full-height">
            <div className="text-bold">{clinic.name}</div>
            <div className="full-width" style={{ fontSize: "0.9em" }}>
                <div>{clinic.address || "12345 Street St."}</div>
                <div>{clinic.address2 || "Suite #1234"}</div>
                <div>{`${clinic.city || "City Name"}, ${clinic.state || "ST"} ${clinic.zipCode || "12345"}`}</div>
            </div>
            <div className="full-width flex align-center justify-between margin-top-sm">
                <div className="flex margin-right-md" style={{ fontSize: "0.8em" }}>
                    <div>Clinic ID: {clinic.id || "123456"}</div>
                </div>
                <div>
                    <Link to={`/admin/clinic/${clinic.id}`}>
                        <Button small>Clinic Info</Button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
