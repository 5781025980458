import React, { useState } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./GamesOnGardCard.scss";
import { map } from "lodash";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import CountdownTimer from "components/games/elements/CountdownTimer";
import OnGardThermometer from "components/games/elements/OnGardThermometer";
import OnGardTaskCountForm from "components/games/widgets/OnGardTaskCountForm";
import OnGardGameStamps from "components/games/widgets/OnGardGameStamps";
import { ON_GARD_TIERS } from "constants/OnGardGameTierData";
import * as ClinicApi from "api/ClinicApi";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import generatePdfFromComponent from "utils/generatePdfFromComponent";
import {handleErrorResponse} from "utils/request";

function GamesOnGardCard(props) {
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showStampsModal, setShowStampsModal] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const { game } = props;

    const handleUpdateClicked = () => {
        setShowUpdateModal(true);
    };

    const handleViewProgressClicked = () => {
        setShowStampsModal(true);
    };

    const handleSubmitUpdate = async taskData => {
        if(props.canEditClinicBiGamesDashboard) {
            setShowUpdateModal(false);
            setShowSpinner(true);

            try {
                setShowSpinner(true);
                await ClinicApi.updateOnGardGameTasks(props.clinicId, props.game.greenlineGameId, taskData);
                setShowSpinner(false);
                setShowStampsModal(true);
            } catch (error) {
                setShowSpinner(false);
                handleErrorResponse("updating onGard tasks", error);
            }
        }
    };

    const handleDashboardClick = () => {
        if (props.onCardClick) {
            props.onCardClick(game);
        }
    };

    const renderTime = () => {
        if (props.dashboard) {
            return (
                <div className={styles.timeRemaining}>
                    <CountdownTimer endDate={game.endDate} />
                </div>
            );
        }

        return (
            <div className={styles.timeRemaining}>
                <span>{game.startDate} - {game.endDate}</span>
            </div>
        );
    }

    return (
        <div
            key={`game_${game.greenlineGameId}`}
            className={classnames(styles.root, {
                [styles.clickable]: props.dashboard,
                [styles.historic]: !game.isCurrent,
            })}
            onClick={handleDashboardClick}
        >
            <div id={`game_${props.game.greenlineGameId}`} className={styles.gameContainer}>
                <h4>{game.name}</h4>
                {renderTime()}
                <div className="margin-top-md margin-bottom-lg margin-right-sm">
                    <OnGardThermometer currentPoints={game.details.TotalPoints} />
                </div>
                <div className={styles.statusLabel}>{game.details.Status}</div>

                {!props.dashboard &&
                    <>
                        <div className={styles.tierTableHeader}>- Gard Goals Achievement Levels -</div>
                        <div>
                            <table className="table striped">
                                <thead>
                                    <tr>
                                        <th>Level</th>
                                        <th>Status</th>
                                        <th>Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {map(ON_GARD_TIERS, (tier, i) => (
                                        <tr key={`tier_${i}`}>
                                            <td>
                                                {`${i + 1}`}
                                            </td>
                                            <td>
                                                {`${tier.name}`}
                                            </td>
                                            <td>
                                                {`${tier.points}`}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="margin-top-md flex justify-center spaced-content margin-bottom-md">
                            <Button
                                type="ongardLight"
                                onClick={handleUpdateClicked}
                                disabled={!props.canEditClinicBiGamesDashboard}
                            >
                                Update Status
                            </Button>
                            <Button
                                type="ongardDark"
                                onClick={handleViewProgressClicked}
                            >
                                View Progress
                            </Button>
                        </div>
                        {/*<div className="text-x-sm border-top margin-bottom-sm">*/}
                        {/*    <BiProductDisclosures/>*/}
                        {/*</div>*/}
                    </>
                }
            </div>
            <div className="flex flex-centered margin-bottom-sm">
                <Button
                    small
                    onClick={() => generatePdfFromComponent(`game_${props.game.greenlineGameId}`, props.game.name)}
                >
                    PRINT
                </Button>
            </div>

            <SpinnerTakeover show={showSpinner}/>

            <Modal
                style={{ maxHeight: "90vh", overflowY: "auto" }}
                show={showUpdateModal}
                clickOutsideToClose={false}
                onClose={() => setShowUpdateModal(false)}
                modalTitle="OPERATION: ON GARD Clinic Sign-off"
                modalSubTitle="Please acknowledge completion of the following tasks"
            >
                {props.canViewClinicBIGamesDashboard ? (
                    <OnGardTaskCountForm
                        clinicId={props.clinicId}
                        game={game}
                        onCancel={() => setShowUpdateModal(false)}
                        canViewClinicBIGamesDashboard={props.canViewClinicBIGamesDashboard}
                        canEditClinicBiGamesDashboard={props.canEditClinicBiGamesDashboard}
                        onSubmit={handleSubmitUpdate}
                    />
                ) : (
                    <AccessDenied/>
                )}
            </Modal>
            <Modal
                style={{ maxHeight: "90vh", overflowY: "auto" }}
                show={showStampsModal}
                onClose={() => setShowStampsModal(false)}
            >
                <OnGardGameStamps game={game} />
            </Modal>
        </div>
    );
}

GamesOnGardCard.propTypes = {
    clinicId: PropTypes.number,
    game: PropTypes.object,
    dashboard: PropTypes.bool,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canEditClinicBiGamesDashboard = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        return {
            canViewClinicBIGamesDashboard,
            canEditClinicBiGamesDashboard,
        }
    }
)(GamesOnGardCard);
