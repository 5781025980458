import React, { useMemo } from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import classnames from "classnames";
import styles from "./TourProgressBar.scss";
import filter from "lodash/filter";
import findLastKey from "lodash/findLastKey";
import flatMap from "lodash/flatMap";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import indexOf from "lodash/indexOf";
import keys from "lodash/keys";
import map from "lodash/map";
import reverse from "lodash/reverse";
import * as TourActions from "actions/TourActions";
import { getFreeTrialTourSteps } from "components/tour/tourConfiguration";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as DemoTourStates from "constants/DemoTourStates";
import {GREENLINE_WELLNESS, MERCK} from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";
import {
    COMPLETE_TOUR_SECTION,
    COUPON_HISTORY_SECTION,
    COUPON_LIBRARY_SECTION,
    COUPON_PROCESSING_SECTION,
    DASHBOARD_SECTION,
    GAME_SECTION,
    NOTIFICATION_SECTION,
    PET_PLAN_TRACKING_SECTION,
    PLAN_ENROLLMENT_SECTION,
    PROGRAM_OPT_IN_SECTION,
    REPORTS_SECTION,
    RESOURCES_SECTION,
    WELLNESS_AUDIT_SECTION,
    WELLNESS_DASHBOARD_SECTION,
    WELLNESS_HISTORY_SECTION
} from "utils/demoTourHelpers";

function TourProgressBar(props) {
    const CHECKPOINTS = useMemo(() => {
        return {
            DASHBOARD: {
                icon: "fa-tachometer-fast",
                minorCheckpoints: {
                    DASHBOARD_TOUR: [...DASHBOARD_SECTION],
                }
            },
            NOTIFICATIONS: {
                icon: "fa-bell",
                minorCheckpoints: {
                    NOTIFICATION_TOUR: [...NOTIFICATION_SECTION,]
                }
            },
            COUPONS: {
                icon: "fa-ticket",
                minorCheckpoints: {
                    COUPON_PROCESSING: [...COUPON_PROCESSING_SECTION],
                    COUPON_HISTORY: [...COUPON_HISTORY_SECTION],
                    COUPON_LIBRARY: [...COUPON_LIBRARY_SECTION],
                },
            },
            WELLNESS_DASHBOARD: {
                icon: "fa-tachometer-fast",
                minorCheckpoints: {
                    WELLNESS_DASHBOARD_TOUR: [...WELLNESS_DASHBOARD_SECTION],
                }
            },
            PLAN_ENROLLMENT: {
                icon: "fa-user-plus",
                minorCheckpoints: {
                    PLAN_ENROLMENT_TOUR: [...PLAN_ENROLLMENT_SECTION],
                }
            },
            WELLNESS: {
                icon: "fa-paw-alt",
                minorCheckpoints: {
                    WELLNESS_AUDIT: [...WELLNESS_AUDIT_SECTION],
                    WELLNESS_HISTORY: [...WELLNESS_HISTORY_SECTION],
                    PET_PLAN_TRACKING: [...PET_PLAN_TRACKING_SECTION],
                }
            },
            GAMES: {
                icon: "fa-trophy-alt",
                minorCheckpoints: {
                    GAME_TOUR: [...GAME_SECTION],
                }
            },
            PROGRAM_OPT_IN: {
                icon: "fa-envelope",
                minorCheckpoints: {
                    PROGRAM_OPT_IN_TOUR: [...PROGRAM_OPT_IN_SECTION],
                }
            },
            REPORTS: {
                icon: "fa-chart-line",
                minorCheckpoints: {
                    REPORTS_TOUR: [...REPORTS_SECTION],
                }
            },
            RESOURCES: {
                icon: "fa-books",
                minorCheckpoints: {
                    RESOURCE_TOUR: [...RESOURCES_SECTION],
                }
            },
            COMPLETE: {
                icon: "fa-check-circle",
                minorCheckpoints: {
                    COMPLETE_TOUR: [...COMPLETE_TOUR_SECTION],
                }
            }
        }
    }, []);

    // Filter checkpoints by permissions and such
    const majorCheckPoints = filter(CHECKPOINTS, (point, index) => {
        switch(index) {
            case "DASHBOARD":
                if (props.canViewClinicInfo && props.userProfile.demoProvider !== GREENLINE_WELLNESS) {
                    return true
                }
                break;
            case "NOTIFICATIONS":
                if (props.canViewNotifications) {
                    return true;
                }
                break;
            case "COUPONS":
                if (props.canViewCouponAudit) {
                    return true;
                }
                break;
            case "WELLNESS_DASHBOARD":
            case "PLAN_ENROLLMENT":
            case "WELLNESS":
                if (props.canViewWellnessAudit) {
                    return true;
                }
                break;
            case "GAMES":
                if (props.canViewClinicBiGamesDashboard) {
                    return true;
                }
                break;
            case "PROGRAM_OPT_IN":
                if (props.userProfile.demoProvider === MERCK) {
                    return true;
                }
                break;
            case "REPORTS":
                if (props.userProfile.demoProvider === GREENLINE_WELLNESS) {
                    return true;
                }
                break;
            default:
                return true;
        }
        return false;
    });

    const allCheckpoints = flatMap(majorCheckPoints, cp => {
        return flatMap(cp.minorCheckpoints, (mcp, key) => {
            return(key)
        })
    });
    const totalCheckpoints = allCheckpoints.length;

    const generateDataPoints = () => {
        const points = map(majorCheckPoints, (cp, k) => {
            return map(cp.minorCheckpoints, (sections, key) => {
                const position = indexOf(allCheckpoints, key);
                const index = indexOf(keys(cp.minorCheckpoints), key);

                const isLast = (position === (totalCheckpoints - 1));
                const xPerc = (position * (100 / (totalCheckpoints - 1)));

                //Get the last section (if it is the first minor check point then it needs to wait to change colors until the last is complete).
                const lastKey = findLastKey(cp.minorCheckpoints);
                const nextSection = Object.values(cp.minorCheckpoints)[index - 1]; // When there are more than one minorCheckpoint we need the highlighted circle to be the next one and the last one to be the first... See compon
                const lastSection = (index === 0) ? reverse(cp.minorCheckpoints[lastKey])[0] : (keys(cp.minorCheckpoints).length > 1 && nextSection) ? reverse(nextSection)[0] : reverse(sections)[0];

                return (
                    <React.Fragment key={`${k}-${key}`}>
                        <div
                            className={classnames(styles.point, {
                                [styles.isMet]: !!includes(props.completedTourSections, lastSection),
                                [styles.end]: isLast,
                            })}
                            style={{left: `${xPerc}%`}}
                        >
                            <div className={classnames(styles.pointInfo)}>
                                <div className={styles.inner} />
                                {/*Only show icon for the first minorCheckPoint*/}
                                {(index === 0) && (
                                    <i className={`far ${cp.icon} fa-2x`}/>
                                )}
                            </div>
                        </div>
                        {/*A Progress bar for each checkpoint to make sure the progress shows in the correct place*/}
                        <div className={styles.progress}
                             style={{ left: `${xPerc}%`, width: `${progressWidth(k, key)}%` }}
                        />
                    </React.Fragment>
                );
            })
        });

        return [points];
    }

    const progressWidth = (majorCheckPoint, mCheckPoint=null) => {
        let progress = 0;
        const checkpointSize = 100 / totalCheckpoints;
        const checkpointSections = majorCheckPoints[majorCheckPoint]?.minorCheckpoints[mCheckPoint] || [];
        const checkpointSteps = flatMap(checkpointSections, section => getFreeTrialTourSteps(section, props.userProfile.demoProvider));
        const stepSize =  !!checkpointSteps?.length ? (checkpointSize / checkpointSteps.length) : 0;

        forEach(checkpointSections, section => {
            //Move forward for each tour step that has been completed.
            progress += ((props.completedTourSteps[section]?.length || 0) * stepSize);
        });

        return progress;
    }

    return (
        <div className={classnames(styles.root, {
            [styles.tourNotRunning]: !props.tourStarted
        })}>
            <div className={styles.progressBar}>
                <div className={styles.bar} />
                <div className={styles.points}>
                    {generateDataPoints()}
                </div>
            </div>
        </div>
    )
}

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        // Permissions
        const canViewClinicBiGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canViewClinicInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_INFO, userProfile);
        const canViewCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canViewNotifications = userHasPermission(PermissionTypes.VIEW, UserPermissions.NOTIFICATIONS, userProfile);
        const canViewWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        return {
            userProfile,
            clinic: state.entities.clinics[ownProps.clinicId],
            completedTourSections: state.demo.completedTourSections,
            completedTourSteps: state.demo.completedTourSteps,
            tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),
            // Permissions
            canViewClinicBiGamesDashboard,
            canViewClinicInfo,
            canViewCouponAudit,
            canViewNotifications,
            canViewWellnessAudit,
        }
    },
    (dispatch) => ({
        markTourAsClosed: () => dispatch(TourActions.markTourAsClosed()),
    })
);

export default compose(
    withRouter,
    connector
)(TourProgressBar);
