import React, {useState, useEffect} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import produce from "immer";
import styles from "./PriceOverridesForm.scss";
import cloneDeep from "lodash/cloneDeep";
import map from "lodash/map";
import mergeWith from "lodash/mergeWith";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import toNumber from "lodash/toNumber";
import uniqBy from "lodash/uniqBy";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import DropdownSearch from "components/common/DropdownSearch";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import Tooltip from "components/common/Tooltip";
import * as MappingActions from "actions/MappingActions";
import { formatUSD } from "utils/numeric";
import { getClinicProductOverrides } from "utils/AdminData";
import getLocalTimezone from "utils/getLocalTimezone";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function PriceOverridesForm(props) {
    const [formData, setFormData] = useState({configs : {}});

    const combinedData = cloneDeep(props.productOverrideData);

    const custom = (objValue, srcValue) => {
      if (_.isArray(objValue)) {
          return srcValue;
      }
    }
    mergeWith(combinedData, formData, custom());

    useEffect(() => {
        if (props.canViewPreAuditReview && props.productId) {
            props.getProductOverrides({ clinicId: props.clinicId, productId: props.productId });
        }
    }, [props.productId]);

    const handleFormChange = ({name, value}) => {
        if(props.canEditPreAuditReview) {
            const newFormData = produce(formData, (draft) => {
                draft.clinicConfig = formData.clinicConfig || {};
                draft.clinicConfig[name] = value;
            });
            setFormData(newFormData);
        }
    };

    const handleDeleteRow = (order) => {
        const newFormData = produce(formData, (draft) => {
            draft.configs = formData.configs || {};
            draft.configs[order] = formData.configs[order] ? formData.configs[order] : {};
            draft.configs[order].isDeleted = true;
        });
        setFormData(newFormData);
    };

    const handleAddRow = () => {
        const lastInList = orderBy(combinedData.configs, ["order"], ["desc"])[0];
        const newOrder = lastInList?.order ? (lastInList.order + 1) : 1;

        const newFormData = produce(formData, (draft) => {
            draft.configs = formData.configs || {};
            draft.configs[newOrder] = {};
            draft.configs[newOrder].order = newOrder;
            draft.configs[newOrder].canDelete = true;
        });

        setFormData(newFormData)
    }

    const handleMappingUpdate = ({ name, value }, product={}, order=null) => {
        const newFormData = produce(formData, (draft) => {
            draft.configs = formData.configs || {};
            draft.configs[order] = formData.configs[order] ? formData.configs[order] : {};
            draft.configs[order][name] = value;
        });
        setFormData(newFormData);
    }

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(props.canEditPreAuditReview) {
            let clinicDosePricing = reject(combinedData.configs, cdp => {
                return (cdp.isDeleted || !cdp.quantity)
            });
            clinicDosePricing = uniqBy(orderBy(clinicDosePricing, ["order"], ["desc"]), cdp => toNumber(cdp.quantity));

            const newData = {
                ...combinedData.clinicConfig,
                clinicId: combinedData.clinicId,
                productId: combinedData.productId,
                terms: combinedData.clinicConfig.matchingTerms || null,
                matchCount: combinedData.clinicConfig.matchingTermCount || 0,
                dosePricing: map(clinicDosePricing, dp => {
                    return {
                        quantity: toNumber(dp.quantity),
                        productMinPrice: toNumber(dp.productMinPrice) || null,
                        productMaxPrice: toNumber(dp.productMaxPrice) || null,
                    }
                }),

                //I believe this is a depreciated option...
                ignoreNPack: false,
            }

            props.updateProductOverrides(newData, props.reason.reasonCode);

            setTimeout(() => {
                if (props.onClose) {
                    props.onClose();
                }
            }, 500);
        }
    }

    const handleCancelClick = () => {
        if (props.onClose) props.onClose();
        if (props.onCancel) props.onCancel();
    }

    const renderClinicProducts = () => {
        return map(props.productOverrideData.clinicProducts, product => (
            <li key={product.clinicProductId}>{product.description} <span>(ID: {product.clinicProductId})</span></li>
        ))
    }

    if(props.isLoading || (props.productId && !combinedData)) {
        return <SpinnerTakeover show/>;
    }

    const PRODUCT_OPTIONS = map(props.products, (product) => {
        return {name: product.name, value: product.productId};
    });

    const renderRows = () => {
        return map(reject(combinedData.configs, {isDeleted: true}), (product) => {
            return (
                <tr key={product.order}>
                    <td className={styles.shortInput}>
                        <TextBox
                            onChange={(update) => handleMappingUpdate(update, product, product.order)}
                            name="quantity"
                            placeholder="Qty"
                            value={product.quantity || product.quantity}
                            inputType="number"
                            disabled={!product.canDelete}
                        />
                    </td>
                    <td className="text-center">
                        {!!product.masterProductMinPrice && formatUSD(product.masterProductMinPrice)}
                    </td>
                    <td className={styles.shortInput}>
                        <TextBox
                            onChange={(update) => handleMappingUpdate(update, product, product.order)}
                            name="productMinPrice"
                            placeholder="Min"
                            value={product.productMinPrice}
                            currency
                            inputType="number"
                        />
                    </td>

                    <td className="text-center">
                        {!!product.masterProductMaxPrice && formatUSD(product.masterProductMaxPrice)}
                    </td>
                    <td className={styles.shortInput}>
                        <TextBox
                            onChange={(update) => handleMappingUpdate(update, product, product.order)}
                            name="productMaxPrice"
                            placeholder="Max"
                            value={product.productMaxPrice}
                            currency
                            inputType="number"
                        />
                    </td>
                    <td>
                        {product.canDelete && (
                            <Button
                                iconOnly
                                type="danger"
                                onClick={() => handleDeleteRow(product.order)}
                            >
                                <i className="fa fa-trash-alt"/>
                            </Button>
                        )}
                    </td>
                </tr>
            );
        })
    }

    if (!(props.canEditPreAuditReview && props.canViewPreAuditReview)) {
        return (
            <AccessDenied/>
        );
    }

    return (
        <form onSubmit={handleSubmit} className={styles.root}>
            <div className={styles.clinicInfo}>
                <div className="margin-left-sm">
                    <label>Clinic:</label>
                    <span>{props.clinicName} ({props.clinicId})</span>
                </div>
                {(props.isPAR || !props.canViewProductMapping) ? (
                    <div>
                        <label>Clinic Product(s):</label>
                        {!!(props.productId && props.productOverrideData) && (
                            <ul>
                                {renderClinicProducts()}
                            </ul>
                        )}
                    </div>
                ) : (
                    <DropdownSearch
                        options={PRODUCT_OPTIONS}
                        label="Clinic Product:"
                        name="productId"
                        value={props.productId}
                        onChange={({value}) => props.onChangeProductId(value)}
                    />
                )}
                <div>
                    <label>Mapped Product(s):</label>
                    {!!(props.productId && props.productOverrideData) && (
                        <span>{props.productOverrideData.product.description} {props.productOverrideData.product.skuQuantity}{props.productOverrideData.product.skuUnits}</span>
                    )}
                </div>
            </div>
            {props.isPAR && (
                <div className={styles.recommendation}>
                    <div className="flex align-center">
                        <label className="text-bold margin-right-x-sm">{props.reason.label}</label>
                        <span>{props.reason.value}</span>
                    </div>
                </div>
            )}
            {!!(props.productId && props.productOverrideData) && (
                <>
                    <div className={styles.productSettings}>
                        <table className="table table-green">
                            <thead>
                                <tr className="super-header">
                                    <th colSpan="2" className="text-center">Max Doses</th>
                                    <th colSpan="2" className="text-center">Matching Terms</th>
                                    <th colSpan="2" className="text-center">Match Count</th>
                                </tr>
                                <tr>
                                    <th className="text-center">Master</th>
                                    <th className="text-center">Clinic</th>
                                    <th>Master</th>
                                    <th>Clinic</th>
                                    <th className="text-center">Master</th>
                                    <th className="text-center">Clinic</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="text-center">
                                        {combinedData.productConfig.maxDoses}
                                    </td>
                                    <td className={styles.shortInput}>
                                        <TextBox
                                            onChange={handleFormChange}
                                            disabled={!props.canEditPreAuditReview}
                                            name="maxDoses"
                                            placeholder="Max"
                                            value={combinedData.clinicConfig.maxDoses}
                                            maxLength={7}
                                            max={9999999}
                                            inputType="number"
                                        />
                                    </td>
                                    <td>
                                        {combinedData.productConfig.matchingTerms}
                                    </td>
                                    <td>
                                        <TextBox
                                            onChange={handleFormChange}
                                            disabled={!props.canEditPreAuditReview}
                                            name="matchingTerms"
                                            value={combinedData.clinicConfig.matchingTerms}
                                            maxLength={50}
                                            placeholder="Enter Terms"
                                        />
                                    </td>
                                    <td className="text-center">
                                        {combinedData.productConfig.matchingTermCount}
                                    </td>
                                    <td className={styles.shortInput}>
                                        <TextBox
                                            onChange={handleFormChange}
                                            disabled={!props.canEditPreAuditReview}
                                            name="matchingTermCount"
                                            value={combinedData.clinicConfig.matchingTermCount}
                                            maxLength={2}
                                            max={99}
                                            inputType="number"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className={styles.ignoreOptions}>
                        <label>Ignore:</label>
                        <div className={styles.inlineChecks}>
                            <CheckboxInput
                                name="ignoreMinPrice"
                                label="Min Price"
                                checked={combinedData.clinicConfig.ignoreMinPrice}
                                disabled={!props.canEditPreAuditReview}
                                onChange={handleFormChange}
                            />
                            <CheckboxInput
                                name="ignoreMaxPrice"
                                label="Max Price"
                                checked={combinedData.clinicConfig.ignoreMaxPrice}
                                disabled={!props.canEditPreAuditReview}
                                onChange={handleFormChange}
                            />
                            <CheckboxInput
                                name="ignoreMaxDose"
                                label="Max Dose"
                                checked={combinedData.clinicConfig.ignoreMaxDose}
                                disabled={!props.canEditPreAuditReview}
                                onChange={handleFormChange}
                            />
                        </div>
                    </div>

                    <div className={styles.prices}>
                        <table className="table table-green">
                            <thead>
                                <tr className="super-header">
                                    <th/>
                                    <th colSpan="2" className="text-center">Min Price Per Dose</th>
                                    <th colSpan="2" className="text-center">Max Price Per Dose</th>
                                    <th/>
                                </tr>
                                <tr>
                                    <th className="text-center">Qty</th>
                                    <th className="text-center">Master</th>
                                    <th className="text-center">Clinic <Tooltip position="bottom" tip="Matching Terms are additive"><i className="fa fa-info-circle"/></Tooltip></th>
                                    <th className="text-center">Master</th>
                                    <th className="text-center">Clinic <Tooltip position="bottom" tip="Matching Terms are additive"><i className="fa fa-info-circle"/></Tooltip></th>
                                    <th/>
                                </tr>
                            </thead>
                            <tbody>
                                {renderRows()}
                            </tbody>
                        </table>
                    </div>
                    <div>
                        <Button
                            icon
                            small
                            type="primary"
                            onClick={handleAddRow}
                            disabled={!props.canEditPreAuditReview}
                            // disabled={!!find(configData(), q => {return !q.quantity})}
                        >
                            <i className="fas fa-plus" /> Add New
                        </Button>
                    </div>
                </>
            )}
            {!!props.productOverrideData.modDate && (
                <div className="text-italic text-sm text-center">Last Override applied on {getLocalTimezone(props.productOverrideData.modDate, "MM/DD/YYYY LTS")} {!!props.productOverrideData.modUser && `(${props.productOverrideData.modUser})`}</div>
            )}
            <div className="flex flex-centered margin-top-sm">
                <div className="flex-1">
                    <Button onClick={handleCancelClick} type="gray" buttonType="button">
                        Cancel
                    </Button>
                </div>
                <div className="flex-none">
                    <Button
                        buttonType="submit"
                        disabled={!props.canEditPreAuditReview}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </form>
    )
}

PriceOverridesForm.propTypes = {
    clinicName: PropTypes.string,
    clinicId: PropTypes.number.isRequired,
    productId: PropTypes.number,
    onCancel: PropTypes.func,
    onChangeProductId: PropTypes.func,
    isPAR: PropTypes.bool,
    reason: PropTypes.object,
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewPreAuditReview = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRE_AUDIT_REVIEW, userProfile);
        const canEditPreAuditReview = userHasPermission(PermissionTypes.EDIT, UserPermissions.PRE_AUDIT_REVIEW, userProfile);
        const canViewProductMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);
        return {
            canViewPreAuditReview,
            canEditPreAuditReview,
            canViewProductMapping,
            productOverrideData: getClinicProductOverrides(state, ownProps.clinicId, ownProps.productId),
            isLoading: state.entities.productOverrides && state.entities.productOverrides.loading,
            products: state.entities.products,
        }
    },

    (dispatch) => ({
        getProductOverrides: (data) => dispatch(MappingActions.getProductOverrides(data)),
        updateProductOverrides: (update, reasonCode) => dispatch(MappingActions.updateProductOverrides(update, reasonCode)),
    })
);

export default compose(
    connector
)(PriceOverridesForm);
