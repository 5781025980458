import React, {useState} from "react";
import PropTypes from "prop-types";
import CheckboxInput from "components/common/CheckboxInput";
import NotificationButtons from "components/notifications/elements/NotificationButtons";
import UserInitials from "components/notifications/elements/UserInitials";
import { DECLINED, ENROLLED, STATUS_COLOR_CLASS } from "constants/Notifications";

export default function BiGamesForm(props) {
    const [initials, setInitials] = useState("");
    const [isAuthorized, setIsAuthorized] = useState(false);

    const handleSubmit = () => {
        props.onEnroll(initials);
    }

    const handleDecline = () => {
        props.onDecline(initials);
    }

    const isEnrolled = props.enrollmentState === ENROLLED;
    const isDeclined = props.enrollmentState === DECLINED;

    return (
        <form onSubmit={handleSubmit}>
            {isEnrolled && <div className={`${STATUS_COLOR_CLASS[props.enrollmentState]||""} text-center text-lg`}>Your clinic has opted in.</div>}
            {isDeclined && <div className={`${STATUS_COLOR_CLASS[props.enrollmentState]||""} text-center text-lg`}>Your clinic has opted out.</div>}
            {(isEnrolled || isDeclined) && <hr className={"margin-bottom-md margin-top-sm"}/>}
            <>
                <div className="border-bottom">
                    <div className="margin-bottom-md">Your clinic has been invited by your Boehringer Ingelheim representative to participate in dispensing programs powered by Greenline Games. By opting in you are agreeing to the following statements.</div>
                    <ol>
                        <li>You are authorized to make this decision on behalf of the clinic.</li>
                        <li>You understand that historical clinic wide parasitiside, NSAID, and/or dental product data will be used to measure the dispensing programs.</li>
                        <li>Boehringer Ingelheim will have access to this data to measure the success of the dispensing programs.</li>
                        <li>This information does NOT include any client specific data.</li>
                    </ol>
                    <div className="margin-top-md"> If you understand these terms, please enter your initials below and click accept. Once you have enrolled for 2022, you will be automatically enrolled in Greenline Games for the following calendar year; no further action will be needed.</div>
                </div>
                <div className="flex spaced-content flex-centered border-bottom">
                    <div className="flex-1">
                        <UserInitials
                            onChange={({value}) => setInitials(value)}
                            value={initials}
                        />
                    </div>
                    <div className="flex-2">
                        <CheckboxInput
                            name="isAuthorized"
                            checked={isAuthorized}
                            onChange={() => setIsAuthorized(!isAuthorized)}
                            required
                            label="I authorize this service and agree to the terms above"
                        />
                    </div>
                </div>
                <div className="flex spaced-content flex-centered margin-top-md">
                    <NotificationButtons
                        notificationDetails={props.notificationDetails}
                        onCancel={props.onCancel}
                        onDecline={handleDecline}
                        onDismiss={props.onDismiss}
                        declineDisabled={!initials}
                        enrollDisabled={!initials || !isAuthorized}
                        isPreview={props.isPreview}
                    />
                </div>
            </>
        </form>
    )
}

BiGamesForm.propTypes = {
    notificationDetails: PropTypes.object,
    enrollmentState: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onEnroll: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    onDismiss: PropTypes.func,
    isPreview: PropTypes.bool
};
