import React from "react";
import styles from "./Error404.scss";
import { Link } from "react-router-dom";

export default function Error404 (props) {
    return (
        <div data-testid="error_404"
             className={styles.root}
        >
            <img src="../../../img/greenline-top-logo.png" />
            <h2>404: ERROR - Page Not Found</h2>
            <Link to={'/'}>
                <h3>Click here to return to the homepage</h3>
            </Link>
        </div>
    );
}
