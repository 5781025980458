import React from "react";
import {
	MAIN_COLOR,
	MAIN_COLOR_SHADOW,
	SECONDARY_COLOR,
	SECONDARY_COLOR_SHADOW,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./KittySwimming.scss";


export default function KittySwimming(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Swim_-_Kitty"
	        data-name="Swim - Kitty"
	        xmlns="http://www.w3.org/2000/svg"
	        // xmlns:xlink="http://www.w3.org/1999/xlink"
	        viewBox="0 0 800 300"
		>
			<g id="Kitty_Swimming02">
				<g className={styles.cls17}>
					<g>
						<path className={styles.cls14}
						      d="M539.2,232.91c18.06,7.13,42.62,18.48,26.78,35.62-15.94,17.25-54.38,8.91-73.36-3.38-8.93-5.78-3.19-23.83-3.18-28.33,0-4-1.06-12.52-4.59-14.78-14.27-9.12,1.53-19.94,16.37-7.1,10.97,9.49-4.94,25.45,10.71,21.02,12.27-3.48,15.03-7.88,27.27-3.05Z"/>
						<path className={styles.cls14}
						      d="M470.05,219.64c-.61-6.33,6.1-11.22,15-10.91,8.89.3,16.61,5.68,17.23,12.02.61,6.32-6.1,11.21-15,10.91-8.9-.31-16.62-5.68-17.23-12.01Z"/>
						<g>
							<path className={styles.cls11}
							      d="M483.97,203.08c.72,9.5,12.53,20.89,19.1,27.38,3.8,3.75,8.91,9.12,14.39,10.67,7.34,2.07,10.47-3.04,5.48-9.35-3.17-4.02-7.63-5.58-10.74-10.01-3.47-4.95-6.85-9.66-9.82-14.93-2.46-4.37-4.95-10.06-10.45-10.77-4.37-.56-8.31,2.32-7.96,7.02Z"/>
							<path className={styles.cls9}
							      d="M483.97,203.08c-.36-4.71,3.59-7.59,7.96-7.02,5.5.71,7.99,6.4,10.45,10.77.04.06.07.12.1.18-3.6.67-7.12,2.03-9.72,4.37-1.2,1.08-2.24,2.56-3.32,4.04-2.96-4.11-5.17-8.39-5.47-12.33Z"/>
						</g>
						<ellipse
							className={styles.cls1}
							cx="608.08"
							cy="208.78"
							rx="68.33"
							ry="103"
							transform="translate(-32.39 264.87) rotate(-23.95)"
							style={{ fill: `${teamColors[MAIN_COLOR]}` }}
						/>
						<ellipse
							className={styles.cls7}
							cx="608.08"
							cy="208.78"
							rx="42.51"
							ry="64.08"
							transform="translate(-32.39 264.87) rotate(-23.95)"
							style={{ fill: `${teamColors[MAIN_COLOR_SHADOW]}` }}
						/>
						<path className={styles.cls14}
						      d="M639.87,237.99c-12.4-9.63-14.67-30.44,1.23-29.88,13.95.49,21.86,6.46,34.19,15.26,6.88,4.91,13.31-9,19.15-11.56,7.71-3.38,14.36.68,13.88,5.75-1.46,15.35-14.28,34.48-28.07,33-17.86-1.92-24.34-.13-40.37-12.57Z"/>
						<path className={styles.cls14}
						      d="M721.48,219.25c.58,6.73-6.19,12.15-15.13,12.11-8.94-.04-16.66-5.54-17.25-12.27-.58-6.72,6.19-12.15,15.13-12.1,8.94.05,16.67,5.54,17.26,12.27Z"/>
						<path className={styles.cls5}
						      d="M521.35,229.26c4.62-4.75,29.89-9.76,33.72-10.24,6.7-.82,9.25-2.96,15.43-5.6,3.39-1.45,6.45-3.81,9.39-6,3.04-2.27,6.93-4.74,9.34-7.64,1.65-1.98,2.58-4.58,4.63-6.15,1.75,1.12,2.12,3.46,3.1,5.3,5.83,10.95,20.91,14.81,32.32,14.94,5.42.06,10.84-.62,16.12-1.83.97-.22,11.35-2.56,11.32-3.45.06,1.76,4.07,4.29,4.74,5.86,12.56,29.51-1,41.47-12.6,52.99-14.74,14.65-38.5,13.09-57.68,12.51-8.27-.25-10.68-2.82-18.9-1.22-7.63,1.48-15.87,7.25-23.61,8.08-13.72,1.49-25.33-4.88-33-17.29-6.73-10.89-2.88-31.46,5.68-40.27Z"/>
						<path className={styles.cls14}
						      d="M537.07,272.91c6.5-3.67,21.15-7.59,28.16-8.41,10.48-1.23,47.75-5.1,58.06-8.48-6.99-6.28-12-17.07-6.61-24.98,22.81-13.76,35.4-2.45,38.92,7.28,1.62,4.37,3.08,10.69,7.35,7.98,10.29-6.52-10.79,14.02-16.55,19.75-14.74,14.65-36.04,14.49-55.22,13.91-8.27-.25-10.68-2.82-18.9-1.22-7.63,1.48-19.88,9.94-29.22,8.3-17.75-3.12-11.57-10.97-5.99-14.12Z"/>
						<path className={styles.cls11}
						      d="M570.49,213.43c3.39-1.45,6.45-3.81,9.39-6,3.04-2.27,6.93-4.74,9.34-7.64,4.89-.86,15.77,12.43,16.59,19.47,1.03,8.79-3.95,24.29-10.36,23.74-22.77-1.95-28.36-1.07-44.47-2.75-9.36-.98-14.57-7.25-16.34-16.88,5.56-1.32,30.16-7.51,35.85-9.93Z"/>
						<path className={styles.cls5}
						      d="M534.5,239.14c17.47-5.41,39.71,1.67,33.32,24.11-6.43,22.59-34.97,31.11-57.46,28.72-10.57-1.12-13.61-19.82-15.63-23.83-1.8-3.57-6.61-10.69-10.77-11.11-16.85-1.7-7.64-18.48,11.4-13.73,14.07,3.51,7.09,24.94,19.04,13.91,9.37-8.65,7.52-14.18,20.09-18.07Z"/>
						<path className={styles.cls5}
						      d="M470.1,252.04c-1.4-6.21,4.66-11.89,13.53-12.7,8.86-.81,17.19,3.57,18.59,9.78,1.4,6.2-4.66,11.88-13.52,12.69-8.87.81-17.19-3.57-18.59-9.77Z"/>
						<path
							id="Tube"
							className={styles.cls1}
							d="M545.63,236.53c-23.1-51.99-13.86-106.55,20.63-121.87,19.36-8.6,35.89,15.5,39.09,34.9-16.28-4.51-27.56,16.9-12.34,61.71,12.99,38.24,40.51,56.93,60.61,40.8,5.36,15.78,17.83,41.27-3.72,50.85-34.49,15.32-81.17-14.4-104.27-66.39Z"
							style={{ fill: `${teamColors[MAIN_COLOR]}` }}
						/>
						<path className={styles.cls5}
						      d="M618.9,247.73c-8.53-13.18-7.54-27.4,8.32-28.59s24.38,8.35,29.93,26.48c2.48,8.08,15.54-4.1,21.89-4.59,8.39-.64,13.33,5.38,11.2,10.01-6.44,14.01-24.86,27.83-37.39,21.88-16.23-7.71-22.93-8.16-33.96-25.19Z"/>
						<path className={styles.cls5}
						      d="M699.19,250.74c1.52,6.59-4.43,12.9-13.29,14.1-8.86,1.2-17.27-3.15-18.8-9.74-1.52-6.58,4.43-12.89,13.29-14.1,8.86-1.2,17.28,3.15,18.8,9.74Z"/>
						<path className={styles.cls14}
						      d="M595.42,192.06c-1.08.74-2.87,2.65-3.78,4.19,17.8,25.85,40.45,26.55,65.47,23.3,6.93-.9,7.25-4.09,1.8-6.23-5.44-2.14-62.26-20.87-63.49-21.26Z"/>
					</g>
				</g>
				<g>
					<g>
						<g>
							<path className={styles.cls11}
							      d="M738.38,144.21c-13.73,2.43-28.15,7.77-36.5,18.27-.41.51.5,1.13.91.62,8.18-10.28,22.36-15.5,35.8-17.89.68-.12.48-1.12-.21-1Z"/>
							<path className={styles.cls11}
							      d="M744.89,161.85c-14.19.46-27.82,2.88-40.47,8.38-.62.27-.14,1.2.49.93,12.56-5.46,26.1-7.85,40.18-8.31.7-.02.5-1.02-.2-1Z"/>
							<path className={styles.cls11}
							      d="M744,174.96c-12.38-1.88-24.74-1.07-36.91.4-.7.08-.49,1.08.2,1,12.09-1.46,24.34-2.26,36.62-.4.71.11.8-.89.09-1Z"/>
						</g>
						<path className={styles.cls4}
						      d="M628.51,107.16c1.31,9.62,2.64,18.56,3,20.09.76,3.19.48,3.25,2.9,5.59,3.17,3.08,44.8-1.42,49.67-10.29-7.69-10.13-23.48-24.25-32.98-29.93-6.81-4.07-17.53-10.78-25.43-11.64-.53-.06,1.14,13.63,2.84,26.17Z"/>
						<g>
							<path className={styles.cls5}
							      d="M606.6,165.05c47.56-32.28,22.78-43.27,9.65-46.53,4.22-1.84,8.59-3.38,12.94-4.61,7.7,4.46,31.49,16.07,61.64,9.97,5.91,6.56,10,15.01,14.12,22.87,4.72,8.99,12.39,10.25,11.06,29.06-1.54,21.72-16.81,27.08-26.88,32.19-11.96,6.08-27.12,9.25-40.57,10.53-8.96.85-25.34-.88-33.76-4.64-17.39-7.77-26.11-23.26-31.2-36.9-.25-.66-.47-1.33-.69-1.99,12.23-3.5,23.69-9.95,23.69-9.95Z"/>
							<path className={styles.cls9}
							      d="M590.91,131.96c4.1-6.47,10.39-14.24,25.33-13.44,13.13,3.27,37.92,14.26-9.65,46.53,0,0-11.45,6.45-23.69,9.95-5.39-16.67-.03-30.34,8-43.04Z"/>
							<path className={styles.cls11}
							      d="M629.19,113.91c11.09-3.12,22.14-4.18,30.68-2.85,9.61,1.5,22.82,4.4,29.82,11.61.39.4.76.8,1.13,1.21-30.15,6.1-53.93-5.51-61.64-9.97Z"/>
						</g>
						<path className={styles.cls6}
						      d="M660,154.26c3.8,7.92-.82,17.22-10.31,20.75-9.5,3.54-20.28-.02-24.08-7.94-3.8-7.92.82-17.21,10.31-20.75,9.5-3.54,20.28.02,24.08,7.94Z"/>
						<path className={styles.cls6}
						      d="M695.58,140.38c3.51,6.1,2.73,12.37-1.73,14.02-4.47,1.65-10.93-1.96-14.44-8.06-3.51-6.09-2.73-12.37,1.73-14.02,4.46-1.65,10.92,1.96,14.43,8.06Z"/>
						<g>
							<path className={styles.cls11}
							      d="M626.88,191.2c11.92-6.77,26.26-11.98,39.43-9.9.64.1.36,1.08-.28.98-12.9-2.03-26.99,3.1-38.65,9.73-.59.34-1.1-.47-.5-.81Z"/>
							<path className={styles.cls11}
							      d="M633.58,207.34c10.96-8.42,22.81-15.14,35.92-19.15.65-.2.9.74.25.94-13.02,3.98-24.77,10.66-35.66,19.02-.54.42-1.04-.4-.51-.81Z"/>
							<path className={styles.cls11}
							      d="M642.85,215.8c8.07-8.91,17.89-15.96,28.01-22.45.58-.37,1.08.44.5.81-10.04,6.44-19.78,13.43-27.79,22.27-.46.51-1.18-.13-.72-.63Z"/>
						</g>
						<g>
							<path className={styles.cls13}
							      d="M656.43,155.2c3.46,7.09,2.59,14.69-1.95,16.97-4.54,2.28-11.03-1.62-14.49-8.71-3.47-7.09-2.59-14.69,1.94-16.97,4.54-2.28,11.03,1.62,14.5,8.71Z"/>
							<path className={styles.cls12}
							      d="M645.75,156.77c1.74,3.55,4.96,5.53,7.19,4.4,2.24-1.12,2.64-4.92.9-8.47-1.74-3.56-4.96-5.53-7.19-4.4-2.23,1.12-2.64,4.92-.9,8.47Z"/>
						</g>
						<g>
							<path className={styles.cls13}
							      d="M694.01,140.95c2.83,5.96,2.91,11.93.16,13.36-2.75,1.42-7.27-2.25-10.1-8.21-2.83-5.95-2.91-11.93-.16-13.35,2.75-1.42,7.27,2.25,10.11,8.21Z"/>
							<path className={styles.cls12}
							      d="M687.23,141.27c1.42,2.98,3.67,4.84,5.02,4.14,1.35-.7,1.29-3.69-.12-6.67-1.42-2.98-3.67-4.84-5.02-4.14-1.35.7-1.29,3.69.13,6.67Z"/>
						</g>
						<path className={styles.cls11}
						      d="M689.84,182.37c.42.52.78,1.12.92,1.29.85,1.06,1.88,2.05,3.18,2.51,2.55.89,6.02.2,8.09-1.61,2.46-2.16,2.84-4.82,2.14-7.87-.34-1.51-.54-3.38-1.28-4.75-.17-.31-.53-.64-.63-.96-.13-.42.44-1.39.91-1.41.97-.04,1.29,1.27,1.47,1.99.28,1.13.65,2.09,1.07,3.18.77,1.98,1.66,4.53,3.71,5.53,1.5.73,3.27.39,4-1.2.37-.81.29-1.65.14-2.5-.04-.24-.39-1.87.15-1.95.91-.13.9,1.86.95,2.4.2,2.25-.76,5.44-3.53,5.31-1.14-.05-2.21-.65-3.1-1.34-.81-.64-1.92-1.31-2.16-2.39.19,2.81-.81,5.16-2.94,7.03-.95.83-2.11,1.45-3.36,1.85-2.95.94-5.86.53-8.31-1.33-.68-.51-4.12-3.73-2.67-4.58.31-.18.79.28,1.22.83Z"/>
						<path className={styles.cls8}
						      d="M702.99,159.79c-1.12.42-2.18,1.13-3.37,1.73-1.91.95-5.17,1.62-6.55,3.04-3.29,3.4,1.92,5.94,5.82,6.55,4.41.7,12.15-1.71,11-7.61-.39-2-1.76-3.36-3.71-3.89-1.2-.33-2.21-.18-3.18.19Z"/>
					</g>
					<path
						id="Cap"
						className={styles.cls16}
						d="M591.34,193.19c13.74-22.84,31.39-45.22,50.57-55.01,18.05-9.22,41.08-9.84,53.01-9.75-4.48-16.97-37.58-28.02-69.34-15.13-58.46,23.73-49.06,62.55-34.24,79.89Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<ellipse
						className={styles.cls15}
						cx="604.46"
						cy="142.5"
						rx="26.75"
						ry="10.26"
						transform="translate(198.08 612.11) rotate(-62.39)"
						style={{ fill: `${teamColors[SECONDARY_COLOR_SHADOW]}` }}
					/>
					<g>
						<path className={styles.cls9}
						      d="M570.69,145.55c7.14,7.08,13.86,13.6,15.11,14.63,2.62,2.14,2.55,1.83,5.82,2.77,6.77,1.95,30.64-30.95,24.18-43.42-10.13-4.18-25.61-1.53-36.65-.19-7.92.96-20.58,2.21-27.31,6.5-.45.29,9.55,10.49,18.85,19.72Z"/>
						<path className={styles.cls8} d="M606.36,130s-24.57-4.15-43.24.35l27.22,25.44,16.02-25.79Z"/>
					</g>
				</g>
				<g>
					<path className={styles.cls3}
					      d="M466.43,251.7c6.26,8.19,20.95,11.28,30.04,11.14,15.18,12.37,30.73,13.31,51.91,9.82-14.02,7.32-35.53,9.34-53.48-6.91-10.5-.64-26.24-4.66-28.47-14.05Z"/>
					<path className={styles.cls3}
					      d="M562.2,268.8c13.36,5.18,51.05,9.98,72.63-4.43,11.92,4.62,29.05,3.12,43.99-5.28-10.14,11.91-32.98,13.66-45.44,9.43-21.31,15.87-58.93,11.19-71.18.28Z"/>
					<path className={styles.cls3}
					      d="M631,278.21c9.93,1.26,30.67-.51,42.33-6.49-8.76,11.2-36.19,12.63-42.33,6.49Z"/>
					<path className={styles.cls3}
					      d="M572.02,282.73c9.46,3.34,19.51,6.07,32.49,3.44-9.83,6.62-28.27,4.15-32.49-3.44Z"/>
					<path className={styles.cls3}
					      d="M686.04,239.26c-1.97-6.9-1.53-20.16,3.08-19.52,5.94.82-2.33,14.37-3.08,19.52Z"/>
					<path className={styles.cls3}
					      d="M480.7,222.89c.41-7.21-7.02-18-9.73-14.22-3.15,4.39,7.69,9.44,9.73,14.22Z"/>
					<path className={styles.cls3}
					      d="M475.54,240.94c.37-6.87-7.63-17.21-10.49-13.63-3.32,4.16,8.27,9.06,10.49,13.63Z"/>
					<path className={styles.cls3}
					      d="M454.53,232.66c-2.86-3.91-11.26-8.18-12-5.55-.83,2.94,8.02,4.94,12,5.55Z"/>
					<path className={styles.cls3}
					      d="M481.75,199.2c-.3-4.83-5.07-12.96-7.11-11.14-2.28,2.03,4.09,8.48,7.11,11.14Z"/>
					<path className={styles.cls3}
					      d="M462.8,221.94c-1.72-4.28-5.58-10.94-7.85-8.93-2.53,2.24,4.13,6.17,7.85,8.93Z"/>
					<path className={styles.cls3}
					      d="M702.06,231.53c1.2-6.32,6.91-13.61,8.68-11.4,2.31,2.91-5.02,7.11-8.68,11.4Z"/>
					<path className={styles.cls3}
					      d="M693.03,257.82c16.95-6.58,18.78-13.64,13.45-13.64,1.19-2.64,0-2.59-5.38-.91,7.3-3.44,9.85-2.48,6.04.76,9.12-2.85,6.1,9.33-14.11,13.79Z"/>
					<path className={styles.cls3}
					      d="M717.21,251.71c10.43-5.04,11.24-10.3,5.91-10.3,1.19-2.64.43-4.12-4.51-3.72,4.92-1.31,8.97.33,5.16,3.57,7.11-1.57,6.56,7.56-6.56,10.45Z"/>
					<path className={styles.cls3} d="M462.62,249.9c-8.52-3.41-9.73-8-1.54-7.3-5.1,1.08-2.04,4.51,1.54,7.3Z"/>
					<path className={styles.cls3}
					      d="M713.66,233.34c4.96-7.44,15.44-9.64,15.69-5.96.36,5.28-8.78,2.03-15.69,5.96Z"/>
					<path className={styles.cls3}
					      d="M707.45,212.44c.73-5.59,3.17-11.9,5.77-10.78,2.68,1.15-3.11,6.77-5.77,10.78Z"/>
					<path className={styles.cls3}
					      d="M628.6,260.2c-15.72,15.72-59.76,10.93-71.64-.95-20.88,10.1-35.67,10.86-52.7-6.18-35.68,5.52-41.22-6.24-32.35-8.18-5.38,5.38,13.09,9,32.6,7.44,19.2,10.88,30.9,13.08,52.48,5.89,17.91,11.58,54.63,10.72,71.4,1.16,18.92,4.24,72.04-9.02,69.47-13.32,11.66,2.18-28.83,19.56-69.24,14.14Z"/>
					<path className={styles.cls3}
					      d="M480.7,222.89c.41-7.21-7.02-18-9.73-14.22-3.15,4.39,7.69,9.44,9.73,14.22Z"/>
					<path className={styles.cls3}
					      d="M475.54,240.94c.37-6.87-7.63-17.21-10.49-13.63-3.32,4.16,8.27,9.06,10.49,13.63Z"/>
					<path className={styles.cls3}
					      d="M454.53,232.66c-2.86-3.91-11.26-8.18-12-5.55-.83,2.94,8.02,4.94,12,5.55Z"/>
					<path className={styles.cls3}
					      d="M481.75,199.2c-.3-4.83-5.07-12.96-7.11-11.14-2.28,2.03,4.09,8.48,7.11,11.14Z"/>
					<path className={styles.cls3}
					      d="M462.8,221.94c-1.72-4.28-5.58-10.94-7.85-8.93-2.53,2.24,4.13,6.17,7.85,8.93Z"/>
				</g>
			</g>
			<g id="Kitty_Swimming01">
				<g className={styles.cls18}>
					<g>
						<path className={styles.cls14}
						      d="M138.54,247.28c19.41-.28,46.45.87,38.32,22.75-8.18,22.01-46.9,28.93-69.13,24.79-10.45-1.95-12.01-20.82-13.72-24.98-1.52-3.7-5.75-11.18-9.86-11.92-16.67-3.01-6.17-19.02,12.44-12.8,13.75,4.6,5.12,25.42,17.9,15.36,10.02-7.89,10.9-13.01,24.06-13.2Z"/>
						<path className={styles.cls14}
						      d="M72.29,247.01c1.28-6.23,9.13-8.92,17.55-6.01,8.41,2.91,14.2,10.32,12.92,16.56-1.28,6.22-9.13,8.92-17.55,6.01-8.42-2.91-14.21-10.32-12.93-16.56Z"/>
						<g>
							<path className={styles.cls11}
							      d="M96.96,195.53c-1.89,9.34,6.37,23.51,10.93,31.54,2.63,4.64,6.09,11.2,10.94,14.18,6.5,3.99,10.9-.07,7.81-7.51-1.96-4.73-5.82-7.44-7.61-12.56-1.99-5.71-3.96-11.16-5.38-17.04-1.18-4.88-2.03-11.03-7.12-13.21-4.05-1.73-8.63-.03-9.57,4.59Z"/>
							<path className={styles.cls9}
							      d="M96.96,195.53c.94-4.63,5.52-6.32,9.57-4.59,5.1,2.18,5.95,8.33,7.12,13.21.02.07.03.13.05.2-3.65-.34-7.4.01-10.54,1.56-1.45.72-2.85,1.86-4.3,2.99-1.72-4.76-2.69-9.48-1.91-13.36Z"/>
						</g>
						<ellipse
							className={styles.cls1}
							cx="210.95"
							cy="208.78"
							rx="68.33"
							ry="103"
							transform="translate(-66.6 103.63) rotate(-23.95)"
							style={{ fill: `${teamColors[MAIN_COLOR]}` }}
						/>
						<ellipse
							className={styles.cls7}
							cx="210.95"
							cy="208.78"
							rx="42.51"
							ry="64.08"
							transform="translate(-66.6 103.63) rotate(-23.95)"
							style={{ fill: `${teamColors[MAIN_COLOR_SHADOW]}` }}
						/>
						<path className={styles.cls14}
						      d="M243.51,235.31c-5.93-14.54,2.51-33.69,16-25.27,11.84,7.4,15.71,16.52,21.98,30.31,3.5,7.69,16.03-1.15,22.37-.44,8.36.93,12.1,7.76,9.14,11.91-8.94,12.57-29.6,22.73-40.8,14.55-14.51-10.59-21.02-12.28-28.69-31.06Z"/>
						<path className={styles.cls14}
						      d="M325.32,255.48c-.97,6.69-8.8,10.42-17.5,8.33-8.69-2.09-14.96-9.2-13.99-15.89.97-6.68,8.8-10.41,17.49-8.33,8.7,2.09,14.96,9.2,14,15.89Z"/>
						<path className={styles.cls5}
						      d="M124.22,229.26c4.62-4.75,29.89-9.76,33.72-10.24,6.7-.82,9.25-2.96,15.43-5.6,3.39-1.45,6.45-3.81,9.39-6,3.04-2.27,6.93-4.74,9.34-7.64,1.65-1.98,2.58-4.58,4.63-6.15,1.75,1.12,2.12,3.46,3.1,5.3,5.83,10.95,20.91,14.81,32.32,14.94,5.42.06,10.84-.62,16.12-1.83.97-.22,11.35-2.56,11.32-3.45.06,1.76,4.07,4.29,4.74,5.86,12.56,29.51-1,41.47-12.6,52.99-14.74,14.65-38.5,13.09-57.68,12.51-8.27-.25-10.68-2.82-18.9-1.22-7.63,1.48-15.87,7.25-23.61,8.08-13.72,1.49-25.33-4.88-33-17.29-6.73-10.89-2.88-31.46,5.68-40.27Z"/>
						<path className={styles.cls14}
						      d="M139.94,272.91c6.5-3.67,21.15-7.59,28.16-8.41,10.48-1.23,47.75-5.1,58.06-8.48-6.99-6.28-17.27-15.66-11.87-23.57,22.81-13.76,35.21-10.85,42.78-.44,4.61,6.33,6.58,6.59,13.32-.44,8.43-8.79-15.35,28.75-21.11,34.48-14.74,14.65-36.04,14.49-55.22,13.91-8.27-.25-10.68-2.82-18.9-1.22-7.63,1.48-19.88,9.94-29.22,8.3-17.75-3.12-11.57-10.97-5.99-14.12Z"/>
						<path className={styles.cls11}
						      d="M173.37,213.43c3.39-1.45,6.45-3.81,9.39-6,3.04-2.27,6.93-4.74,9.34-7.64,4.89-.86,15.77,12.43,16.59,19.47,1.03,8.79-3.95,24.29-10.36,23.74-22.77-1.95-28.36-1.07-44.47-2.75-9.36-.98-14.57-7.25-16.34-16.88,5.56-1.32,30.16-7.51,35.85-9.93Z"/>
						<path className={styles.cls10}
						      d="M143.04,235.37c17.7,4.6,32.89,22.32,15.64,38.04-17.36,15.82-46.11,8.04-63.97-5.83-8.4-6.52-1.14-24.01-.75-28.49.35-3.99.01-12.57-3.3-15.11-13.44-10.31,3.23-19.73,16.92-5.67,10.12,10.39-7.1,24.94,8.87,21.86,12.52-2.42,13.85-8.09,26.59-4.79Z"/>
						<path className={styles.cls12}
						      d="M76.63,222.48c-.64-6.33,6.06-11.24,14.96-10.97,8.9.27,16.63,5.62,17.27,11.95.64,6.32-6.06,11.24-14.95,10.97-8.9-.27-16.64-5.62-17.28-11.95Z"/>
						<path
							id="Tube-2"
							data-name="Tube"
							className={styles.cls1}
					        d="M148.51,236.53c-23.1-51.99-13.86-106.55,20.63-121.87,19.36-8.6,35.89,15.5,39.09,34.9-16.28-4.51-27.56,16.9-12.34,61.71,12.99,38.24,40.51,56.93,60.61,40.8,5.36,15.78,17.83,41.27-3.72,50.85-34.49,15.32-81.17-14.4-104.27-66.39Z"
							style={{ fill: `${teamColors[MAIN_COLOR]}` }}
						/>
						<path
							className={styles.cls12}
							d="M223.89,251.57c-12.57-9.41-16.58-23.09-2.11-29.7,15.17-6.93,25.76-.62,37.26,14.46,5.13,6.72,13.15-9.24,18.94-11.9,7.65-3.51,14.37.42,13.97,5.5-1.19,15.38-13.67,34.72-27.48,33.49-17.89-1.6-24.34.3-40.59-11.85Z"
						/>
						<path
							className={styles.cls12}
							d="M302.1,235.36c.12,6.76-7.01,11.7-15.93,11.04-8.91-.66-16.24-6.67-16.37-13.43-.12-6.75,7.01-11.7,15.92-11.04,8.92.66,16.25,6.67,16.37,13.42Z"
						/>
					</g>
				</g>
				<path className={styles.cls14}
				      d="M198.3,192.06c-1.08.74-2.87,2.65-3.78,4.19,17.8,25.85,40.45,26.55,65.47,23.3,6.93-.9,7.25-4.09,1.8-6.23-5.44-2.14-62.26-20.87-63.49-21.26Z"/>
				<g>
					<g>
						<g>
							<path className={styles.cls11}
							      d="M338.47,126.96c-13,5.04-26.11,13.07-32.28,24.98-.3.58.71,1.01,1.01.43,6.03-11.67,18.94-19.53,31.66-24.47.64-.25.26-1.19-.4-.94Z"/>
							<path className={styles.cls11}
							      d="M348.27,143.02c-13.83,3.19-26.74,8.21-38.08,16.05-.56.39.09,1.21.66.82,11.26-7.78,24.08-12.75,37.82-15.92.69-.16.29-1.1-.39-.94Z"/>
							<path className={styles.cls11}
							      d="M349.93,156.05c-12.51.55-24.48,3.73-36.14,7.53-.67.22-.27,1.16.39.94,11.58-3.77,23.44-6.92,35.86-7.47.71-.03.61-1.03-.1-1Z"/>
						</g>
						<path className={styles.cls4}
						      d="M223.51,111.86c3.15,9.19,6.18,17.7,6.83,19.13,1.37,2.98,1.1,3.1,3.92,4.93,3.71,2.41,43.67-10.06,46.74-19.7-9.5-8.46-27.73-19.25-38.14-22.98-7.47-2.67-19.29-7.19-27.2-6.5-.53.05,3.75,13.15,7.85,25.13Z"/>
						<g>
							<path className={styles.cls5}
							      d="M213.21,172.9c40.42-40.87,13.98-46.86.46-47.52,3.79-2.62,7.77-4.98,11.81-7.03,8.42,2.89,34,9.68,62.4-2.14,7.06,5.3,12.71,12.8,18.28,19.71,6.37,7.91,14.14,7.66,16.47,26.37,2.69,21.61-11.26,29.82-20.15,36.78-10.56,8.28-24.82,14.32-37.77,18.17-8.63,2.57-25.03,4.04-34.02,1.98-18.57-4.26-30.11-17.77-37.75-30.17-.37-.6-.72-1.21-1.06-1.82,11.32-5.8,21.31-14.34,21.31-14.34Z"/>
							<path className={styles.cls9}
							      d="M191.42,143.47c2.77-7.14,7.44-15.98,22.26-18.09,13.52.66,39.96,6.65-.46,47.52,0,0-9.99,8.54-21.31,14.34-8.51-15.31-5.9-29.77-.48-43.77Z"/>
							<path className={styles.cls11}
							      d="M225.48,118.35c10.28-5.2,20.92-8.38,29.55-8.73,9.72-.39,23.24-.09,31.5,5.62.45.31.9.64,1.34.97-28.4,11.82-53.98,5.02-62.4,2.14Z"/>
						</g>
						<path className={styles.cls6}
						      d="M263.51,151.99c5.26,7.04,2.53,17.05-6.1,22.36-8.63,5.31-19.9,3.9-25.16-3.14-5.26-7.04-2.53-17.05,6.11-22.35,8.63-5.31,19.9-3.9,25.16,3.14Z"/>
						<path className={styles.cls6}
						      d="M295.73,131.49c4.62,5.3,5.08,11.61,1.02,14.09-4.06,2.48-11.1.19-15.72-5.11-4.62-5.3-5.08-11.61-1.01-14.09,4.06-2.48,11.1-.19,15.72,5.11Z"/>
						<g>
							<path className={styles.cls11}
							      d="M238.16,194.63c10.38-8.95,23.45-16.83,36.77-17.34.65-.03.57.99-.08,1.01-13.05.5-25.88,8.27-36.04,17.03-.52.44-1.17-.25-.65-.7Z"/>
							<path className={styles.cls11}
							      d="M247.85,209.17c9.13-10.38,19.45-19.27,31.54-25.73.6-.32,1.02.55.42.87-12.01,6.42-22.24,15.25-31.31,25.56-.45.52-1.1-.19-.65-.7Z"/>
							<path className={styles.cls11}
							      d="M258.59,215.68c6.19-10.3,14.46-19.12,23.14-27.44.49-.47,1.15.22.65.7-8.61,8.26-16.81,17-22.96,27.22-.35.59-1.18.11-.83-.48Z"/>
						</g>
						<g>
							<path className={styles.cls13}
							      d="M260.19,153.6c4.77,6.29,5.39,13.91,1.37,17.03-4.01,3.12-11.13.55-15.9-5.74-4.77-6.29-5.39-13.91-1.37-17.03,4.01-3.12,11.13-.55,15.91,5.74Z"/>
							<path className={styles.cls12}
							      d="M250.02,157.21c2.39,3.15,5.93,4.46,7.91,2.93,1.98-1.54,1.64-5.34-.76-8.49-2.39-3.15-5.93-4.47-7.91-2.93-1.97,1.53-1.64,5.33.76,8.49Z"/>
						</g>
						<g>
							<path className={styles.cls13}
							      d="M294.31,132.35c3.93,5.29,5.16,11.15,2.74,13.07-2.42,1.93-7.57-.81-11.5-6.1-3.93-5.29-5.16-11.15-2.74-13.07,2.42-1.93,7.57.8,11.5,6.1Z"/>
							<path className={styles.cls12}
							      d="M287.72,133.98c1.97,2.65,4.53,4.04,5.72,3.09,1.19-.95.56-3.87-1.41-6.52-1.97-2.65-4.53-4.04-5.73-3.09-1.19.95-.56,3.87,1.42,6.52Z"/>
						</g>
						<path className={styles.cls11}
						      d="M298.22,173.79c.51.43.99.95,1.15,1.09,1.04.87,2.24,1.65,3.6,1.84,2.67.38,5.95-.97,7.62-3.15,2-2.59,1.85-5.27.58-8.13-.63-1.41-1.18-3.21-2.18-4.41-.22-.27-.64-.53-.8-.82-.21-.38.16-1.45.62-1.56.94-.23,1.51,1,1.83,1.67.5,1.05,1.04,1.93,1.67,2.91,1.14,1.79,2.5,4.12,4.71,4.71,1.62.43,3.29-.25,3.69-1.96.21-.87-.04-1.67-.34-2.48-.09-.23-.74-1.76-.23-1.94.87-.3,1.24,1.65,1.4,2.17.63,2.17.3,5.48-2.43,5.89-1.13.17-2.3-.21-3.3-.72-.92-.47-2.13-.91-2.58-1.93.73,2.72.2,5.22-1.53,7.46-.77.99-1.79,1.83-2.94,2.46-2.71,1.49-5.65,1.65-8.41.3-.76-.37-4.76-2.87-3.5-3.98.27-.24.83.12,1.36.58Z"/>
						<path className={styles.cls8}
						      d="M306.76,149.1c-1.02.63-1.92,1.53-2.98,2.35-1.69,1.3-4.76,2.59-5.84,4.25-2.57,3.97,3.03,5.46,6.97,5.3,4.46-.17,11.59-4.03,9.32-9.6-.77-1.89-2.37-2.96-4.39-3.1-1.24-.09-2.21.25-3.09.8Z"/>
					</g>
					<path
						id="Cap-2"
						data-name="Cap"
						className={styles.cls16}
						d="M203.68,203.46c9.06-25.07,22.06-50.44,38.98-63.75,15.93-12.53,38.4-17.59,50.12-19.82-7.67-15.78-42.29-20.22-70.96-1.43-52.77,34.58-36.04,70.86-18.14,85.01Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<ellipse
						className={styles.cls15}
						cx="206.74"
						cy="151.19"
						rx="26.75"
						ry="10.26"
						transform="translate(3.18 306.64) rotate(-73.55)"
						style={{ fill: `${teamColors[SECONDARY_COLOR_SHADOW]}` }}
					/>
					<g>
						<path className={styles.cls9}
						      d="M174.2,160.71c8.37,5.57,16.23,10.66,17.66,11.43,2.99,1.59,2.86,1.3,6.24,1.59,7.02.6,24.08-36.29,15.32-47.28-10.74-2.14-25.42,3.45-35.99,6.9-7.58,2.47-19.77,6.15-25.54,11.66-.39.37,11.4,8.44,22.31,15.7Z"/>
						<path className={styles.cls8} d="M206.19,138.55s-24.91.68-42.36,8.71l31.63,19.69,10.73-28.4Z"/>
					</g>
				</g>
				<g>
					<path className={styles.cls3}
					      d="M235.81,259.54c-20.36,15.78-61.26,13.35-75.99-.29-20.88,10.1-51.71,8.39-68.5-8.41-17.11-1.65-22.55-7.82-16.95-8.13-2.32,2.8,8.66,6.26,17.2,7.39,17.03,13.72,46.7,15.3,68.28,8.12,17.91,11.58,58.97,10.06,75.74.5,15.25,3.42,27.27,1.86,46.38-4.55.28-.09.55-.22.83-.33,17.85-1.72,37.21-9.47,33.85-13.05,12.38.74-2.66,11.68-33.86,14.16-19.52,9.9-35.82,10.18-46.98,4.6Z"/>
					<path className={styles.cls3}
					      d="M68.02,244.8c7.1,7.1,14.61,11.32,23.7,11.63,14.55,13.11,34.01,15.9,55.34,13.46-14.37,6.61-39.93,6.48-57.05-10.64-10.46-1.16-18.31-5.5-21.99-14.46Z"/>
					<path className={styles.cls3}
					      d="M164.02,268.98c13.36,5.18,50.61,11.19,72.18-3.22,11.92,4.62,33.88.85,48.82-7.55-10.14,11.91-37.71,16.11-50.17,11.89-21.31,15.87-58.58,9.79-70.83-1.12Z"/>
					<path className={styles.cls3}
					      d="M231.47,276.81c9.93,1.26,30.67-.51,42.33-6.49-8.76,11.2-36.19,12.63-42.33,6.49Z"/>
					<path className={styles.cls3}
					      d="M174.89,282.73c9.46,3.34,19.51,6.07,32.49,3.44-9.83,6.62-28.27,4.15-32.49-3.44Z"/>
					<path className={styles.cls3}
					      d="M289.5,258.21c11.64,1.06,19.92-1.14,27.58-9.87,12.05-3.94,22.64-9.26,16.18-12.83,14.36-1.42,9.8,6.75-14.63,13.77-5.15,8.83-17.9,13.96-29.13,8.92Z"/>
					<path className={styles.cls3}
					      d="M308.75,235.35c-1.97-6.9-1.53-20.16,3.08-19.52,5.94.82-2.33,14.37-3.08,19.52Z"/>
					<path className={styles.cls3}
					      d="M78.61,204.19c.41-7.21-7.02-18-9.73-14.22-3.15,4.39,7.69,9.44,9.73,14.22Z"/>
					<path className={styles.cls3}
					      d="M71.92,235.88c.37-6.87-7.63-17.21-10.49-13.63-3.32,4.16,8.27,9.06,10.49,13.63Z"/>
					<path className={styles.cls3} d="M54.6,224.55c-2.86-3.91-11.26-8.18-12-5.55-.83,2.94,8.02,4.94,12,5.55Z"/>
					<path className={styles.cls3}
					      d="M91.5,206.09c1.24-4.68-.74-13.9-3.24-12.82-2.8,1.21,1.22,9.34,3.24,12.82Z"/>
					<path className={styles.cls3}
					      d="M68.97,215.62c-1.72-4.28-5.58-10.94-7.85-8.93-2.53,2.24,4.13,6.17,7.85,8.93Z"/>
					<path className={styles.cls3}
					      d="M317.36,230.61c1.2-6.32,6.91-13.61,8.68-11.4,2.31,2.91-5.02,7.11-8.68,11.4Z"/>
					<path className={styles.cls3}
					      d="M344.15,245.69c9.84-5.55,10.24-6.84,6.4-9.82,14.49,0,3.18,9.82-6.4,9.82Z"/>
					<path className={styles.cls3}
					      d="M66.99,244.88c-6.66-2.77-7.58-6.57-1.16-6.06-4.01.93-1.64,3.76,1.16,6.06Z"/>
					<path className={styles.cls3}
					      d="M316.95,237.19c4.96-7.44,15.44-9.64,15.69-5.96.36,5.28-8.78,2.03-15.69,5.96Z"/>
					<path className={styles.cls3} d="M319.16,215.25c.73-5.59,3.17-11.9,5.77-10.78s-3.11,6.77-5.77,10.78Z"/>
				</g>
			</g>
		</svg>
	);
};