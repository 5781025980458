import React from "react";
import PropTypes from "prop-types";
import { autobind } from "core-decorators";
import {clone, difference, xor, includes} from "lodash";

export default class Accordion extends React.Component {
    static propTypes = {
        theme: PropTypes.object,
        multiOpen: PropTypes.bool,
        onChange: PropTypes.func, // When a section is selected, this callback will receive the section id.
        defaultOpen: PropTypes.array, // Send the ids of the accordions that should be open when loaded
    };

    static defaultProps = {
        theme: {},
        multiOpen: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            open: [],
            currentId: null,
        };
    }

    componentDidMount() {
        if(this.props.defaultOpen) {
            this.setState({open: this.props.defaultOpen});
            if(this.props.onChange) {
                this.props.onChange(this.props.defaultOpen[0], true, null);
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // Open all "defaultOpen" sections if there is an update to defaultOpen
        if(xor(prevProps.defaultOpen, this.props.defaultOpen).length) {
            this.setState({ open: this.props.defaultOpen});
            if(this.props.onChange) {
                this.props.onChange(this.props.defaultOpen[0], true, null);
            }
        }
    }

    @autobind
    handleSectionClicked(id) {
        const wasOpen = !!includes(this.state.open, id);
        const isOpen = !wasOpen;

        let closed = null;
        let openList = clone(this.state.open);

        if(this.props.multiOpen) {
            if(isOpen) {
                openList.push(id);
            } else {
                openList = difference(this.state.open, [id]);
            }
        } else {
            if(isOpen) {
                openList = [id];
            } else {
                openList = [];
            }
            closed = this.state.currentId;
        }

        //The last opened or the first in the list or null
        const currentId = isOpen ? id : openList.length ? openList[0] : null;

        this.setState({ open: openList, currentId });
        if (!!this.props.onChange) {
            this.props.onChange(currentId, isOpen, closed);
        }
    }

    render() {
        // Inject some props into each child AccordionSection.
        const children = React.Children.map(this.props.children, child => {
            if (child) {
                return React.cloneElement(child, {
                    isOpen: !!includes(this.state.open, child.props.id),
                    onClick: this.handleSectionClicked,
                    theme: this.props.theme,
                });
            }
            return null;
        });

        return children;
    }
}
