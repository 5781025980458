import React, {useCallback, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "./PIMSCodes.scss";
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import map from "lodash/map";
import trim from "lodash/trim";
import upperCase from "lodash/upperCase";
import TextBox from "components/common/TextBox";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function PIMSCodes(props) {
    const [text, setText] = useState("");
    const [inputFocused, setInputFocused] = useState(false);
    const [duplicateCode, setDuplicateCode] = useState(null);
    const [missingCode, setMissingCode] = useState(null);
    const [usedCode, setUsedCode] = useState(null);

    const handleSelectPims = () => {
        // Prevent blanks and spaces from being added to the array
        const updatedText = upperCase(trim(text));
        if(!!updatedText?.length) {
            if (!!includes(props.selectedPims, updatedText)){
                // Prevent duplicate values
                setDuplicateCode(updatedText);
            } else if(!includes(flatMap(props.pims, p => upperCase(p.remoteClinicProductDisplayID)), updatedText)) {
                // Code not in PIMS
                setMissingCode(text);
            } else if(!!includes(flatMap(filter(props.pims, p => {return p?.productUsedRowCount > 0}), "clinicProductId"), updatedText)) {
                // Code has already been used
                setUsedCode(text);
            } else {
                // Add new codes to the list
                const updatedPIMS = [...props.selectedPims];
                updatedPIMS.push(updatedText);
                props.onChange(updatedPIMS);
            }
        }
        setText("");
    }

    const handleRemove = (index) => {
        const updatedPIMS = [...props.selectedPims];
        updatedPIMS.splice(index, 1);
        props.onChange(updatedPIMS);
    }

    const notFocused = useCallback(debounce(() => {
        // Reset so the focus can be set again
        setInputFocused(false);
    }, 500), []);

    const handleFocus = () => {
        setInputFocused(true);
        notFocused();
    }

    const handleChange = ({value}) => {
        setText(value);
        setDuplicateCode(null);
        setMissingCode(null);
    }

    return (
        <div>
            <div className={classnames(styles.root, "flex flex-wrap flex-centered")}
                onClick={handleFocus}
            >
                {map(props.selectedPims, (pims, index) => (
                    <div key={index} className={styles.code}>
                        <div className="flex-none">{pims}</div>
                        <div className={styles.delete} onClick={() => handleRemove(index)}>
                            <i className="fal fa-times"/>
                        </div>
                    </div>
                ))}
                <div className="flex-1" style={{minWidth: "50px"}}>
                    <TextBox
                        focused={inputFocused}
                        name={props.name}
                        value={text}
                        onChange={handleChange}
                        onEnter={handleSelectPims}
                        onSpaceBar={handleSelectPims}
                        placeholder="Enter PIMS Code"
                    />
                </div>
            </div>
            {!!duplicateCode && (
                <div className="text-warn">
                    ({duplicateCode}) - Code has already been added.
                </div>
            )}
            {!!missingCode && (
                <div className="text-warn">
                    ({missingCode}) - Code does not exist in your PIMS.
                </div>
            )}
            {!!usedCode && (
                <div className="text-warn">
                    ({usedCode}) - Code has already been used.
                </div>
            )}
        </div>
    );
}

PIMSCodes.propTypes = {
    name: PropTypes.string.isRequired,
    selectedPims: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        // Permissions
        const canEditVetCheckMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.VETCHECK_MAPPING, userProfile);
        return {
            pims: state.entities?.pims?.products,
            // Permissions
            canEditVetCheckMapping
        }
    }
)(PIMSCodes);
