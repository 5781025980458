exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".KittyTrack__cls1{fill:#ffc200}.KittyTrack__cls1,.KittyTrack__cls2,.KittyTrack__cls3,.KittyTrack__cls4,.KittyTrack__cls5,.KittyTrack__cls6,.KittyTrack__cls7,.KittyTrack__cls8,.KittyTrack__cls9,.KittyTrack__cls10,.KittyTrack__cls11,.KittyTrack__cls12{stroke-width:0}.KittyTrack__cls2{fill:#232021}.KittyTrack__cls3{fill:#fffaff}.KittyTrack__cls4{fill:#e5d11a}.KittyTrack__cls5{fill:#c47258}.KittyTrack__cls6{opacity:.3}.KittyTrack__cls6,.KittyTrack__cls10{fill:#231f20}.KittyTrack__cls7{fill:#e2ae1a}.KittyTrack__cls8{fill:#343031}.KittyTrack__cls9{fill:#fff}.KittyTrack__cls11{fill:#ccc7c6}.KittyTrack__cls12{fill:#04456f}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/GoldenSummerGames/KittyTrack.scss"],"names":[],"mappings":"AAAA,kBAAM,YAAY,CAAC,2NAA2E,cAAc,CAAC,kBAAM,YAAY,CAAC,kBAAM,YAAY,CAAC,kBAAM,YAAY,CAAC,kBAAM,YAAY,CAAC,kBAAM,UAAU,CAAC,qCAAa,YAAY,CAAC,kBAAM,YAAY,CAAC,kBAAM,YAAY,CAAC,kBAAM,SAAS,CAAC,mBAAO,YAAY,CAAC,mBAAO,YAAY,CAAC","file":"KittyTrack.scss","sourcesContent":[".cls1{fill:#ffc200}.cls1,.cls2,.cls3,.cls4,.cls5,.cls6,.cls7,.cls8,.cls9,.cls10,.cls11,.cls12{stroke-width:0}.cls2{fill:#232021}.cls3{fill:#fffaff}.cls4{fill:#e5d11a}.cls5{fill:#c47258}.cls6{opacity:.3}.cls6,.cls10{fill:#231f20}.cls7{fill:#e2ae1a}.cls8{fill:#343031}.cls9{fill:#fff}.cls11{fill:#ccc7c6}.cls12{fill:#04456f}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cls1": "KittyTrack__cls1",
	"cls2": "KittyTrack__cls2",
	"cls3": "KittyTrack__cls3",
	"cls4": "KittyTrack__cls4",
	"cls5": "KittyTrack__cls5",
	"cls6": "KittyTrack__cls6",
	"cls7": "KittyTrack__cls7",
	"cls8": "KittyTrack__cls8",
	"cls9": "KittyTrack__cls9",
	"cls10": "KittyTrack__cls10",
	"cls11": "KittyTrack__cls11",
	"cls12": "KittyTrack__cls12"
};