import React from "react";
import { createRoot } from "react-dom/client";
import Toast from "components/common/Toast";

function error(message) {
    return toast(message, "danger");
}

function success(message) {
    return toast(message, "success");
}

function warn(message) {
    return toast(message, "warn");
}

function toast(message, toastStyle="default") {
    console.log(document, "DOCUMENT");
    const container = document.createElement("span");
    const root = createRoot(container);
    container.style.position = "fixed";
    container.style.top = "-100%";
    container.style.left = "-100%";

    document.body.appendChild(container);

    const cancel = () => {
        removeContainer();
    };

    const Component = class extends React.Component {

        constructor(props) {
            super(props);
            this.state = {
                confirmed: false,
            };
        }


        render() {
            return (
                <Toast
                    show={this.props.show}
                    onDismissed={cancel}
                    toastStyle={toastStyle}
                >
                    <div style={{maxWidth: "375px"}}>
                    {message}
                    </div>
                </Toast>
            );
        }

    };

    Component.displayName = "toast";
    const renderComponent = (domContainer, show = true) => {
        // root.render(<Component show={show} />, domContainer);
        root.render(<Component show={show} />);
    };

    renderComponent(container);
    const removeContainer = () => {
        renderComponent(container, false);
        setTimeout(() => {
            root.unmount();
            document.body.removeChild(container);
        }, 500);
    };

    setTimeout(() => {
        removeContainer();
    }, 5000);
}


export default {
    error,
    success,
    warn,
};
