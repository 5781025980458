import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "components/admin/widgets/MappingTable.scss";
import getSpeciesIcon from "utils/getSpeciesIcon";
import CheckboxInput from "components/common/CheckboxInput";
import TextBox from "components/common/TextBox";
import Button from "components/common/Button";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import {getProviderImage} from "utils/ClinicData";
import PriceHistoryPopup from "components/admin/widgets/PriceHistoryPopup";
import Modal from "components/common/Modal";
import * as MappingActions from "actions/MappingActions";
import MappingPimsDropdownSearch from "components/admin/elements/MappingPimsDropdownSearch";

function MappingTableRow(props) {
    const [deleting, setDeleting] = useState(false);
    const [showPricingHistory, setShowPricingHistory] = useState(false);
    const rowData = useMemo(() => {
        return {
            clinicId: props.clinicId,
            complianceDuration: props.product.baseComplianceDuration || 0,
            skuQuantity: props.product.baseSKUQuantity || 0,
            ...props.product,
            ...props.mapping,
        };
    }, [props.clinicId, props.clinicProductId])

    const [formData, setFormData] = useState({});

    const combinedData = {
        ...rowData,
        ...formData,
    }
    const saved = !!(rowData.clinicProductId || !(formData.clinicProductId || formData.complianceDuration));
    const provider = props.providers[combinedData.providerId];
    const isTrigger = (props.type && ("Offer Triggers" === props.type));
    const isDiscount = (props.type && ("Discounts" === props.type));
    const isFree = (props.type && ("Free Doses" === props.type));

    const handleChange = ({name, value}) => {
        if(props.canEditProductMapping) {
            const newFormData = {
                ...formData,
                [name]: value,
            }
            if (name === "skuQuantity") {
                if(props.product.baseComplianceDuration) {
                    newFormData.complianceDuration = (value * (props.product.baseComplianceDuration / props.product.baseSKUQuantity));
                } else {
                    newFormData.complianceDuration = 0;
                }
            }
            setFormData(newFormData);

            const newCombinedData = {
                ...combinedData,
                ...newFormData
            };

            if (combinedData.clinicProductId) {
                if(props.canEditProductMapping) {
                    const allData = {
                        ...combinedData,
                        ...newCombinedData
                    }
                    if(props.isFood) {
                        props.updateFoodMapping(allData, props.clinicId, combinedData.clinicProductId, combinedData.order);
                    } else {
                        props.updatePharmacyMapping(allData, props.clinicId, combinedData.clinicProductId, combinedData.order);
                    }
                }
            } else if (newFormData.clinicProductId) {
                if(!!props.index) {
                    props.handleOnCreate(props.index, newFormData.clinicProductId);
                }
                // CREATE
                if(props.canEditProductMapping) {
                    if(props.isFood) {
                        props.createFoodMapping(newCombinedData, props.clinicId, (props.highestFoodIndex + 1));
                    } else {
                        props.createPharmacyMapping(newCombinedData, props.clinicId, (props.highestPharmacyIndex + 1));
                    }
                }
            }
        }
    }

    const handleOnDelete = () => {
        //Show the deleting spinner icon
        setDeleting(true);
        if(props.clinicProductId) {
            //Delete any related "blank mappings" that may be lingering from a create.
            props.handleOnDelete(props.clinicProductId);
        }
    }

    return (
        <tr
            className={classnames(styles.root, "spaced-content", {
                [styles.trigger]: isTrigger,
                [styles.discount]: isDiscount,
                [styles.freeDose]: isFree,
                [styles.firstRow]: props.showProduct,
                [styles.deleting]: deleting,
            })}
        >
            <td>
                <div className={classnames(styles.icons, "nowrap")}>
                    {props.showProduct && provider && provider.smallImageUri && (
                        <img
                            src={getProviderImage(provider)}
                            alt={provider.name}
                        />
                    )}
                    {props.showProduct && (combinedData.speciesName && combinedData.speciesName !== "N/A") && (
                        <img src={getSpeciesIcon(combinedData.speciesName)} alt={combinedData.speciesName}/>
                    )}
                </div>
            </td>
            <td className={styles.colored}>
                {props.showProduct && (
                    <div className="no-wrap">
                        {combinedData.productName}
                    </div>
                )}
            </td>
            <td className={styles.colored}>
                <CheckboxInput
                    onChange={handleChange}
                    name="ignoreQuantity"
                    disabled={!props.canEditProductMapping || deleting}
                    checked={combinedData.ignoreQuantity}
                />
            </td>
            <td className={styles.colored}>
                <div className="flex align-center nowrap">
                    <div className={styles.smallTextBox}>
                        <TextBox
                            small
                            inputType="number"
                            value={combinedData.skuQuantity}
                            name="skuQuantity"
                            min={0}
                            // step={combinedData.skuQty}
                            onChange={handleChange}
                            disabled={!props.canEditProductMapping || deleting}
                        />
                    </div>
                    <div className="margin-left-x-sm">{combinedData.skuUnits}</div>
                </div>
            </td>
            <td className={styles.colored}>
                <TextBox
                    small
                    disabled
                    inputType="number"
                    value={combinedData.complianceDuration}
                    name="complianceDuration"
                    min={0}
                    onChange={() => {}}
                />
            </td>
            <td className={styles.colored}>
                {!(isDiscount || isFree) && (
                    <CheckboxInput
                        onChange={handleChange}
                        name="isFreeDose"
                        checked={combinedData.isFreeDose}
                        disabled={!props.canEditProductMapping || deleting}
                    />
                )}
            </td>
            <td className={styles.colored}>
                {!(isDiscount || isFree) && (
                    <CheckboxInput
                        onChange={handleChange}
                        name="isDiscount"
                        checked={combinedData.isDiscount}
                        disabled={!props.canEditProductMapping || deleting}
                    />
                )}
            </td>
            <td className={styles.colored}>
                <CheckboxInput
                    onChange={handleChange}
                    name="isExcludeCouponProcessing"
                    checked={combinedData.isExcludeCouponProcessing}
                    disabled={!props.canEditProductMapping || deleting}
                />
            </td>
            <td>
                {!!combinedData.clinicProductId && (
                    <Button
                        text
                        small
                        type="primary"
                        onClick={() => setShowPricingHistory(true)}
                        className="nowrap"
                        disabled={deleting}
                    >
                        Price History
                    </Button>
                )}
            </td>
            <td>
                <div className={styles.myProduct}>
                    <MappingPimsDropdownSearch
                        typeId={props.typeId}
                        isFood={props.isFood}
                        clinicProductId={combinedData.clinicProductId}
                        clinicId={props.clinicId}
                        productId={props.productId}
                        handleChange={handleChange}
                        handleDeleting={handleOnDelete}
                        pimsOptions={props.pimsOptions}
                        disabledPimsOptions={props.disabledPimsOptions}
                        boldPimsOptions={props.boldPimsOptions}
                        disabled={!props.canEditProductMapping || deleting}
                    />
                </div>
            </td>
            <td>
                {(saved && !deleting) ? (
                    <Button
                        iconOnly
                        text
                        type="success"
                        onClick={props.handleAddBlankMapping}
                        disabled={!props.canEditProductMapping || deleting}
                    >
                        <i className="fa fa-2x fa-plus-square"/>
                    </Button>
                ) : (
                    <div className="text-center">
                        <i className="text-success fa fa-spinner-third fa-spin"/>
                    </div>
                )}
                <Modal
                    mediumSmall
                    show={showPricingHistory}
                    onClose={() => setShowPricingHistory(false)}
                    modalTitle={`#${props.clinicProductId} - 6 Month Price History`}
                >
                    <PriceHistoryPopup
                        clinicId={props.clinicId}
                        clinicProductId={props.clinicProductId}
                    />
                </Modal>
            </td>
        </tr>
    )
}

MappingTableRow.propTypes = {
    clinicId: PropTypes.number.isRequired,
    productId: PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
    pimsOptions: PropTypes.array.isRequired,
    disabledPimsOptions: PropTypes.array.isRequired,
    boldPimsOptions: PropTypes.array.isRequired,
    handleOnCreate: PropTypes.func,
    handleOnDelete: PropTypes.func,
    clinicProductId: PropTypes.number,
    isFood: PropTypes.bool,
    index: PropTypes.number,
    showProduct: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canEditProductMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.PRODUCT_MAPPING, userProfile);
        const products = ownProps.isFood ? state.entities.foodProducts : state.entities.pharmacyProducts
        const mappings = (ownProps.isFood ? state.entities.foodMappings[ownProps.clinicId] : state.entities.pharmacyMappings?.[ownProps.clinicId]) || {};
        return {
            canEditProductMapping,
            providers: state.entities.providers,
            product: products[ownProps.productId],
            mapping: mappings?.[ownProps.productId]?.mappings?.[ownProps.clinicProductId] || {},
            highestPharmacyIndex: state.entities.mappingCounts?.[ownProps.clinicId]?.highestPharmacyIndex || 0,
            highestFoodIndex: state.entities.mappingCounts?.[ownProps.clinicId]?.highestFoodIndex || 0,
        }
    }, (dispatch) => ({
        //FOOD
        createFoodMapping: (data, clinicId, order) => dispatch(MappingActions.createFoodMapping(data, clinicId, order)),
        updateFoodMapping: (data, clinicId, oldClinicProductId, order) => dispatch(MappingActions.updateFoodMapping(data, clinicId, oldClinicProductId, order)),
        //Pharmacy
        createPharmacyMapping: (data, clinicId, order) => dispatch(MappingActions.createPharmacyMapping(data, clinicId, order)),
        updatePharmacyMapping: (data, clinicId, oldClinicProductId, order) => dispatch(MappingActions.updatePharmacyMapping(data, clinicId, oldClinicProductId, order)),
    }),
)(MappingTableRow);
