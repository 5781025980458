import { NOT_YET, NEVER } from "constants/TimeFilterIds";

export function stylizeTimeString(timeString) {
    if (timeString === NOT_YET) {
        return "Not Yet";
    }

    if (timeString === NEVER) {
        return "Never";
    }

    return timeString;
}
