import React from "react";
import styles from "./StatusFilterDropdown.scss";
import Dropdown from "components/common/Dropdown";
import * as CouponStatus from "constants/CouponStatusIds";

const OPTIONS = Object.freeze([
    // { name: "All", value: "" },
    { name: "Approved", value: CouponStatus.APPROVED },
    { name: "Declined", value: CouponStatus.DECLINED },
]);

export default function StatusFilterDropdown(props) {
    const handleChanged = value => {
        if (props.onChange) {
            props.onChange(value);
        }
    };

    return (
        <div className={styles.root}>
            <Dropdown
                name="statusFilter"
                value={props.value}
                options={OPTIONS}
                onChange={handleChanged}
                placeholder="All"
                small
                fullWidth
            />
        </div>
    );
}
