import React from "react";
import AdminBase from "components/layout/AdminBase";
import ReprocessWellnessTable from "components/admin/widgets/ReprocessWellnessTable";
import * as SearchTypes from "constants/SearchTypes";
import { REPROCESSING_JOBS, MIN_REPROCESSING_JOBS_CONTROLS } from "constants/AdminControls";
import AccessDenied from "components/common/AccessDenied";
import {connect} from "react-redux";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function WellnessReprocessing(props) {
    return (
        <AdminBase
            pageTitle="Wellness Reprocessing"
            collapsible
            searchConfig={SearchTypes.REPROCESSING_JOBS_SEARCH}
            controls={!props.canEditWellnessReprocessing ? MIN_REPROCESSING_JOBS_CONTROLS : REPROCESSING_JOBS}
        >
            <div className="margin-bottom-lg">
                {props.canViewWellnessReprocessing ? (
                    <ReprocessWellnessTable />
                ) : (
                    <AccessDenied/>
                )}
            </div>
        </AdminBase>
    )
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewWellnessReprocessing = userHasPermission(PermissionTypes.VIEW, UserPermissions.WELLNESS_REPROCESS, userProfile);
        const canEditWellnessReprocessing = userHasPermission(PermissionTypes.EDIT, UserPermissions.WELLNESS_REPROCESS, userProfile);
        return {
            canViewWellnessReprocessing,
            canEditWellnessReprocessing
        }
    }
)(WellnessReprocessing);
