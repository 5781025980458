import React, { useState } from "react";
import * as PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./WellnessReprocessingDetails.scss";
import * as WellnessApi from "api/WellnessApi";
import Button from "components/common/Button";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import getLocalTimezone from "utils/getLocalTimezone";
import { downloadFile } from "utils/request";

export default function WellnessReprocessingDetails(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [reprocessRequestId, setReprocessRequestId] = useState(null);
    const [downloadDisabled, setDownloadDisabled] = useState(false);

    const handleDownloadLog = async e => {
        e.preventDefault();
        e.stopPropagation();
        setDownloadDisabled(true);
        setIsLoading(true);
        WellnessApi.downloadWellnessReprocessingLog(reprocessRequestId)
	        .then((res) => {
                setReprocessRequestId(null);
                downloadFile(res, "txt");
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setIsLoading(false);
                setDownloadDisabled(false);
            })
    }

	if (!props.wellnessReprocessing) {
		return (
			<span>No Data to Show.</span>
		);
	}

    return (
        <div className={styles.root}>
            <div className={styles.details}>
                <div>
                    <span className={styles.label}>Request ID:</span>
                    {props.wellnessReprocessing.reprocessRequestId}
                </div>
                <div>
                    <span className={styles.label}>Clinic:</span>
                    {props.wellnessReprocessing.clinicName} ({props.wellnessReprocessing.clinicId})
                </div>
                <div>
                    <span className={styles.label}>Created:</span>
                    {getLocalTimezone(props.wellnessReprocessing.createDate, 'MM/DD/YYYY LTS')} <span className="text-sm">by {props.wellnessReprocessing.createUser}</span></div>
                <div>
                    <span className={styles.label}>Invoice Dates:</span>
                    {getLocalTimezone(props.wellnessReprocessing.invoiceStartDate, "MM/DD/YYYY LTS")} {!!props.wellnessReprocessing.invoiceEndDate && `- ${getLocalTimezone(props.wellnessReprocessing.invoiceEndDate, "MM/DD/YYYY LTS")}`}</div>
                <div>
                    <span className={styles.label}>Processed:</span>
                    {props.wellnessReprocessing.processDate ? getLocalTimezone(props.wellnessReprocessing.processDate, 'MM/DD/YYYY LTS') : "Queued"}</div>
                <div className="flex">
                    <div className={classnames(styles.label, "flex-none")}>Log:</div>
                    <div>{props.wellnessReprocessing.logFilePath}</div>
                </div>
            </div>
            <div className="border-top flex flex-centered">
                <Button
                    icon
                    onClick={handleDownloadLog}
                    disabled={!props.wellnessReprocessing.logFilePath || downloadDisabled}
                >
                    <i className="fa fa-download"/> Download Log
                </Button>
            </div>
	        <SpinnerTakeover show={isLoading} />
        </div>
    );
}

WellnessReprocessingDetails.propTypes = {
    wellnessReprocessing: PropTypes.object,
}
