import React from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AccessDenied from "components/common/AccessDenied";
import AdminBase from 'components/layout/AdminBase';
import Downloads from "components/support/widgets/Downloads";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

export function AdminDownloadsPage(props) {

    if (!props.canViewSupportDownloads) {
        return (
            <AdminBase
                data-testid="admin_downloads_page_component"
                pageTitle="Downloads"
            >
                <AccessDenied />
            </AdminBase>
        )
    }

    return (
        <AdminBase pageTitle="Downloads">
            <div>
                <div
                    data-testid="admin_downloads_page_component"
                    className="full-width full-height"
                >
                    <Downloads />
                </div>
                <SpinnerTakeover show={props.working} />
            </div>
        </AdminBase>
    );
}

AdminDownloadsPage.defaultProps = {
    working: false,
};

AdminDownloadsPage.propTypes = {
    working: PropTypes.bool,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewSupportDownloads = userHasPermission(PermissionTypes.DOWNLOADS, UserPermissions.SUPPORT, userProfile);
        return {
            canViewSupportDownloads,
            working: !!state.applicationState.working,
        }
    },
)(AdminDownloadsPage);