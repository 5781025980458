import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "components/admin/widgets/MappingTable.scss";
import map from "lodash/map";
import * as MappingActions from "actions/MappingActions";
import Button from "components/common/Button";
import MappingPimsDropdownSearch from "components/admin/elements/MappingPimsDropdownSearch";
import Modal from "components/common/Modal";
import PriceHistoryPopup from "components/admin/widgets/PriceHistoryPopup";
import Tooltip from "components/common/Tooltip";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function VetCheckTableRow(props) {
    const [deleting, setDeleting] = useState(false);
    const [showPricingHistory, setShowPricingHistory] = useState(false);

    const rowData = useMemo(() => {
        return {
            clinicId: props.clinicId,
            ...props.content,
            ...props.mapping,
        };
    }, [props.clinicId, props.mappingId])

    const [formData, setFormData] = useState({});

    const combinedData = {
        ...rowData,
        ...formData,
    }
    const saved = combinedData.clinicProductId ? !!(rowData.mappingId) : true;

    const handleChange = ({name, value}) => {
        if(props.canEditVetCheckMapping) {
            const newFormData = {
                ...formData,
                [name]: value,
            }
            const newCombinedData = {
                ...combinedData,
                ...newFormData,
            }
            setFormData(newFormData);

            if (props.mappingId) {
                if(props.canEditVetCheckMapping) {
                    const allData = {
                        ...combinedData,
                        ...newCombinedData
                    }
                    props.updateVetCheckMapping(allData, props.clinicId, combinedData.order);
                }
            } else if (newFormData.clinicProductId) {
                if(!!props.index) {
                    props.handleOnCreate(props.index, newFormData.clinicProductId);
                }
                // CREATE
                if(props.canEditVetCheckMapping) {
                    props.createVetCheckMapping(newCombinedData, props.clinicId, (props.highestVetCheckContentIndex + 1));
                }
            }
        }
    }

    const handleOnDelete = () => {
        //Show the deleting spinner icon
        setDeleting(true);
        if(props.mappingId) {
            //Delete any related "blank mappings" that may be lingering from a create.
            props.handleOnDelete(props.mappingId);
        }
    }

    return (
        <tr className={classnames(styles.root,"spaced-content", {
                [styles.firstRow]: props.showProduct,
                [styles.deleting]: deleting,
            })}
        >
            <td>
                {props.showProduct && (
                    <div>
                        {combinedData.vetCheckHandoutId}
                    </div>
                )}
            </td>
            <td>
                {props.showProduct && (
                    <div className="no-wrap flex spaced-content">
                        <div className="flex-none">{combinedData.title}</div>
                        {!!combinedData.title && (
                            <div>
                                {!combinedData.shareLink ? (
                                    <Tooltip position="bottom" tip="Missing ShareLink">
                                        <i className="fa fa-exclamation-triangle text-warn"/>
                                    </Tooltip>
                                ) : (
                                    <Tooltip position="bottom" tip="Has ShareLink">
                                        <i className="fa fa-check text-success"/>
                                    </Tooltip>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </td>
            <td>
                {props.showProduct && (
                    <div className={classnames(styles.keywords, "flex flex-wrap text-x-sm")}>
                        {map(combinedData.keywords, (word) => (
                            <div className={styles.keyword} key={word}>{word}</div>
                        ))}
                    </div>
                )}
            </td>
            <td>
                {!!combinedData.clinicProductId && (
                    <Button
                        text
                        small
                        type="primary"
                        onClick={() => setShowPricingHistory(true)}
                        className="nowrap"
                        disabled={deleting}
                    >
                        Price History
                    </Button>
                )}
            </td>
            <td>
                <div className={styles.myProduct}>
                    <MappingPimsDropdownSearch
                        typeId={props.typeId}
                        mappingId={props.mappingId}
                        contentType={combinedData.contentType}
                        clinicProductId={combinedData.clinicProductId}
                        clinicId={props.clinicId}
                        productId={props.clinicHandoutId}
                        handleChange={handleChange}
                        handleDeleting={handleOnDelete}
                        pimsOptions={props.pimsOptions}
                        disabledPimsOptions={props.disabledPimsOptions}
                        boldPimsOptions={props.boldPimsOptions}
                        disabled={!props.canEditVetCheckMapping || deleting}
                    />
                </div>
            </td>
            <td>
                {(saved && !deleting) ? (
                    <Button
                        iconOnly
                        text
                        type="success"
                        onClick={props.handleAddBlankMapping}
                        disabled={!props.canEditVetCheckMapping}
                    >
                        <i className="fa fa-2x fa-plus-square"/>
                    </Button>
                ) : (
                    <div className="text-center">
                        <i className="text-success fa fa-spinner-third fa-spin"/>
                    </div>
                )}
                <Modal
                    mediumSmall
                    show={showPricingHistory}
                    onClose={() => setShowPricingHistory(false)}
                    modalTitle={`#${props.mapping.clinicProductId} - 6 Month Price History`}
                >
                    <PriceHistoryPopup
                        clinicId={props.clinicId}
                        clinicProductId={props.mapping.clinicProductId}
                    />
                </Modal>
            </td>
        </tr>
    )
}

VetCheckTableRow.propTypes = {
    clinicId: PropTypes.number.isRequired,
    clinicHandoutId: PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
    pimsOptions: PropTypes.array.isRequired,
    disabledPimsOptions: PropTypes.array.isRequired,
    boldPimsOptions: PropTypes.array.isRequired,
    handleOnCreate: PropTypes.func,
    handleOnDelete: PropTypes.func,
    mappingId: PropTypes.number,
    index: PropTypes.number,
    showProduct: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const vetCheck = state.entities.vetCheckProducts;
        const vetCheckContent = vetCheck ? vetCheck[ownProps.clinicId] : null;
        const mappings = state.entities.vetCheckMappings[ownProps.clinicId];
        // Permissions
        const canEditVetCheckMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.VETCHECK_MAPPING, userProfile);

        return {
            content: vetCheckContent[ownProps.clinicHandoutId],
            mapping: !!ownProps.mappingId ? mappings[ownProps.clinicHandoutId].mappings[ownProps.mappingId] : {},
            highestVetCheckContentIndex: state.entities.mappingCounts?.[ownProps.clinicId]?.highestVetCheckContentIndex || 0,
            //Permissions
            canEditVetCheckMapping,
        }
    }, (dispatch) => ({
        createVetCheckMapping: (data, clinicId, order) => dispatch(MappingActions.createVetCheckMapping(data, clinicId, order)),
        updateVetCheckMapping: (data, clinicId, order) => dispatch(MappingActions.updateVetCheckMapping(data, clinicId, order)),
    }),
)(VetCheckTableRow);
