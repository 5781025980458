import * as WellnessApi from "api/WellnessApi";
import * as ActionTypes from "constants/ActionTypes";
import * as ClinicApi from "api/ClinicApi";
import toast from "utils/toast";
import pluralizeWord from "utils/pluralizeWord";
import {
    mapSingleWellnessVisitFromServerToApp,
    mapWellnessVisitSearchFromServerToApp,
    mapWellnessPlansFromServerToApp,
    mapWellnessPlanDetailsFromServerToApp,
    mapClinicDvmsFromServerToApp,
} from "data/serverMapping";
import isArray from "lodash/isArray";
import map from "lodash/map";
import {handleErrorResponse} from "utils/request";

export function getWellnessPlans(clinicId, status=null) {
    return (dispatch) => {
        WellnessApi.getWellnessPlans(clinicId, status)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.WELLNESS_PLANS_LOADED,
                        plans: mapWellnessPlansFromServerToApp(res.body.data),
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading wellness plans", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function selectWellnessPlan(planId) {
    return (dispatch) => (
        dispatch({
            type: ActionTypes.WELLNESS_PLAN_SELECTED,
            planId
        })
    )
}
export function clearSelectedWellnessPlan() {
    return (dispatch) => (
        dispatch({
            type: ActionTypes.WELLNESS_CLEAR_SELECTED_PLAN
        })
    )
}

export function getWellnessPlanDetails(petId) {
    return (dispatch) => {
        WellnessApi.getWellnessPlanDetails(petId)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.WELLNESS_PLAN_DETAILS_LOADED,
                        id: petId,
                        plan: mapWellnessPlanDetailsFromServerToApp(res.body),
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading wellness plan details", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function clearWellnessPlansSearch() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.WELLNESS_SEARCH_CLEARED,
            results: [],
        })
    }
}

export function searchWellnessPlansByName(searchTerm, clinicId=null) {
    return (dispatch) => {
        WellnessApi.searchWellnessPlans(searchTerm, clinicId)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.WELLNESS_SEARCH_RESULTS_LOADED,
                        query: searchTerm,
                        results: mapWellnessPlansFromServerToApp(res.body.data),
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading wellness plans", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function searchWellnessHistory(query) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.WELLNESS_HISTORY_SEARCH_FILTERED,
            query,
        })
    }
}
export function clearWellnessHistorySearch() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.WELLNESS_HISTORY_SEARCH_CLEARED,
        })
    }
}

export function getUnprocessedWellnessVisits(clinicId) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.WELLNESS_VISITS_LOADING,
        })

        WellnessApi.getUnprocessedWellnessVisits(clinicId)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.WELLNESS_VISITS_LOADED,
                        visits: mapWellnessVisitSearchFromServerToApp(res.body),
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading unprocessed wellness visits", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getProcessedWellnessVisits(options) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.WELLNESS_VISITS_LOADING });

        WellnessApi.getProcessedWellnessVisits(options)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.WELLNESS_VISITS_LOADED,
                        visits: mapWellnessVisitSearchFromServerToApp(res.body),
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading processed wellness visits", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

// AKA approve
export function processWellnessVisits(visitIds, userInitials) {
    const apiData = {
        visitIds,
        userInitials,
        actionType: "Approved",
    };

    return (dispatch) => {
        dispatch({ type: ActionTypes.WELLNESS_VISIT_UPDATING });

        WellnessApi.processWellnessVisits(apiData)
            .then((res) => {
                toast.success(`${visitIds.length} wellness ${pluralizeWord(visitIds.length, "visit")} approved successfully`);
                if (res.body) {
                    dispatch({
                        type: ActionTypes.WELLNESS_VISIT_UPDATED,
                        visits: res.body, // List of the visits that were processed
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading processed wellness visits", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function loadInvoiceDetails() {
    return
}

// Change a wellness visit's DVM assignment (also accepts an array of visits to change)
export function updateWellnessVisits(dvmId, visits, applyToAllInvoiceLineItems = false) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.WELLNESS_VISIT_UPDATING });

        // The visit(s) we send to the API will still exist. We get back new visits which are copies.
        // We delete removals in the reducer.
        WellnessApi.updateWellnessVisits(dvmId, visits, applyToAllInvoiceLineItems)
            .then((res) => {
                const updatedVisits = map(res.body, v => mapSingleWellnessVisitFromServerToApp(v));
                toast.success(`Wellness visit(s) updated`); // Server only returns updated visit if there was a single visit in our request, but why??
                // const count = updatedVisits.length;
                // toast.success(`${count} wellness ${pluralizeWord(count, "visit")} updated`);

                dispatch({
                    type: ActionTypes.WELLNESS_VISIT_UPDATED,
                    visits: updatedVisits,
                    removalIds: isArray(visits) ? visits : [visits], // These are now visit ids only, not the whole visit object
                });
            })
            .catch((error) => {
                handleErrorResponse("updating wellness visits", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function voidWellnessVisit(visitId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.WELLNESS_VISIT_UPDATING });

        // The visit(s) we send to the API will still exist. We get back new visits which are copies.
        WellnessApi.voidWellnessVisit(visitId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.WELLNESS_VISIT_VOIDED,
                    visitId: visitId
                });
            })
            .catch((error) => {
                handleErrorResponse("marking wellness visit as void", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function downloadPDF() {
    return (dispatch) => {
        toast.success("Downloading Wellness History PDF");
        dispatch({
            type: ActionTypes.WELLNESS_HISTORY_DOWNLOADED,
        })
    }
}

export function getDvmsForClinic(clinicId) {
    return async (dispatch) => {
        dispatch({
            type: ActionTypes.CLINIC_DVMS_LOADING,
        })
        try {
            const response = await ClinicApi.getClinicDvmCommissions(clinicId);
            dispatch({
                type: ActionTypes.CLINIC_DVMS_LOADED,
                dvms: mapClinicDvmsFromServerToApp(response.body.data),
            });
        } catch (error) {
            handleErrorResponse("loading DVMs for clinic", error);
        }
        // required to resolve the promise
        return "done";
    };
}

export function updatePremierPetPlan(clinicId, petId, patientPlanEnrollmentId, externalPetPlanReference) {
    return (dispatch) => {
        WellnessApi.updatePremierPetPlan(clinicId, petId, patientPlanEnrollmentId, externalPetPlanReference)
            .then(res => {
                toast.success(`Premier pet plan updated`);
                dispatch({
                    type: ActionTypes.WELLNESS_PREMIER_PET_PLAN_UPDATED,
                    petId,
                    externalPetPlanReference,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating premier pet plan", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}
