import React from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import styles from "./TourStep.scss";
import Button from "components/common/Button";
import classnames from "classnames";

class TourStep extends React.Component {

    constructor(props) {
        super(props);

        if (!this.constructor.el) {
            this.constructor.el = document.createElement("div");
            document.getElementById("react-joyride-portal")?.appendChild(this.constructor.el);
        }
    }

    render() {
        const {
            index,
            step,
            primaryProps,
            tooltipProps,
            tourSection,
        } = this.props;

        const delay = (time) => {
            return new Promise(res => setTimeout(res, time));
        }

        const handleNext = async (e) => {
            // Things that should be completed before the next step is opened
            if (!!this.props.onStepComplete) {
                await this.props.onStepComplete(tourSection, index);
            }
            if(!!step.waitTimeBeforeContinue) {
                await delay(step.waitTimeBeforeContinue);
            }

            primaryProps.onClick(e);
        }

        return (
            <div
                {...tooltipProps}
                className={styles.root}
                style={{
                    width: !!step?.toolTipWidth ? step?.toolTipWidth : "none",
                    minWidth: !!step?.toolTipWidth ? step?.toolTipWidth : "300px",
                    maxWidth: !!step?.toolTipWidth ? step?.toolTipWidth : "500px"
                }}
            >
                {!!(step.title || step.icon || step.stepText) && (
                    <div className={classnames(styles.stepHeader, "flex spaced-content flex-wrap align-center")}>
                        <div className="flex flex-1 spaced-content align-center text-lg no-wrap" style={{minWidth: "136px"}}>
                            {step.title ? (
                                <h4>{step.icon && (<i className={step.icon}/>)} {step.title}</h4>
                            ) : (
                                <h4>{step.icon && (<i className={step.icon}/>)}</h4>
                            )}
                        </div>
                        <div className="flex-none text-success text-bold text-right">
                            {step.stepText}
                        </div>
                    </div>
                )}

                <div>{step.content}</div>

                <div className={styles.tourButtons}>
                    <Button {...primaryProps} onClick={(e) => handleNext(e)}>
                        {step.nextButtonText || "Next"}
                    </Button>
                </div>
            </div>
        );
    }
}

// These props are passed from the react-joyride library
TourStep.propTypes = {
    index: PropTypes.number.isRequired,
    step: PropTypes.object.isRequired,
    primaryProps: PropTypes.object.isRequired,
    tooltipProps: PropTypes.object.isRequired,
    tourSection: PropTypes.string.isRequired,
    onStepComplete: PropTypes.func,
};

const connector = connect(
    (state) => ({
        completedTourSections: state.demo.completedTourSections,
    })
);

export default compose(
    withRouter,
    connector
)(TourStep);
