import React, { useState, useEffect } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import styles from "./ClinicTags.scss";
import filter from "lodash/filter";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import Button from "components/common/Button";
import EditCorporateId from "components/admin/elements/EditCorporateId";
import TextBox from "components/common/TextBox";
import * as ClinicActions from "actions/ClinicActions";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ClinicTags(props) {
    const [customTags, setCustomTags] = useState({});
    const [newTag, setNewTag] = useState({});

    useEffect(() => {
        if(props.canViewClinicManagement) {
            props.loadTags();
        }
    }, [props.canViewClinicManagement]);

    if(!props.canViewClinicManagement) {
        return null;
    }

    const handleTagClicked = (data) => {
        if(props.onTagClicked) {
            props.onTagClicked(data);
        }
    };

    const handleTagAdd = () => {
        if(props.canEditClinicManagement) {
            if (props.onTagAdd) {
                props.onTagAdd(newTag);
            }
            setCustomTags({
                ...customTags,
                [newTag.id]: newTag,
            });
            setNewTag({});
        }
    };

    const handleNewTag = ({ name, value }) => {
        setNewTag({
            ...newTag,
            [name]: value,
        });
    }

    return (
        <div className={styles.root}>
            <h3>Clinic Tags</h3>
            <h5>* Changes will not be saved until you click "Save Change"</h5>
            <div className={styles.tagList}>
                {map(sortBy(props.tags, "id"), (tag) => {
                    return (
                        <Button
                            title={tag.description}
                            small
                            key={tag.id}
                            onClick={() => handleTagClicked({name:"tags", value:tag})}
                            type={!props.clinicTagList[tag.id] ? "gray" : null}
                        >
                            {tag.id}
                        </Button>
                    );
                })}
            </div>
            <div className={styles.addTags}>
                <TextBox
                    onChange={handleNewTag}
                    name="id"
                    value={newTag.id}
                    placeholder="Tag ID"
                />
                <TextBox
                    onChange={handleNewTag}
                    name="description"
                    value={newTag.description}
                    placeholder="Description"
                />
                <Button
                    onClick={handleTagAdd}
                    className="flex-none margin-bottom-x-sm"
                    disabled={!(newTag.description && newTag.id && props.canEditClinicManagement)}
                >
                    Add New Tag
                </Button>
            </div>
            {map(props.greenCorporateGroups, corporateGroup => (
                <EditCorporateId
                    clinicId={props.clinicId}
                    providerId={corporateGroup.providerId}
                />
            ))}
        </div>
    )
}


ClinicTags.propTypes = {
    clinicId: PropTypes.number,
    onTagClicked: PropTypes.func,
    onTagAdd: PropTypes.func,
    clinicTagList: PropTypes.any,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            canEditClinicManagement,
            tags: state.entities.tags,
            greenCorporateGroups: filter(state.entities.clinicProviderLocations[ownProps.clinicId], cg => !!(cg?.enrollmentDate && cg?.goGreenDate && cg?.isCorporateGroup)),
        }
    },
    (dispatch) => ({
        loadTags: () => dispatch(ClinicActions.loadTags()),
    })
)(ClinicTags);

