import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./ProviderBase.scss";
import * as AdminActions from "actions/AdminActions";
import * as CouponActions from "actions/CouponActions";
import * as ProviderActions from "actions/ProviderActions";
import * as UserActions from "actions/UserActions";
import Footer from "components/layout/Footer";
import ProviderHeader from "components/layout/ProviderHeader";
import ProviderSidebar from "components/provider/widgets/ProviderSidebar";
import Toolbar from "components/common/Toolbar/Toolbar";
import getProviderId from "utils/getProviderId";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { setPageTitle } from "utils/windowUtils";
import * as UserPermissions from "constants/UserPermissions";

/*
This should be the root container for all Provider pages. It should handle any init() functions or other startup triggers
so that they can be managed in one place.
 */

function ProviderBase(props) {
    const { pathname } = useLocation();
    const params = useParams();
    const providerId = getProviderId(params, props.userProfile);
    const hierarchy = !!props.providerHierarchies?.data?.[providerId] || null;
    const isAdminView = !!(providerId && pathname?.includes("/admin"));
    const providerLocation = props.userProfile?.primaryLocation;

    const fetchData = () => {
        if (!props.userProfile){
            props.loadCurrentUser();
        }
        if (props.nodeId) {
            props.getNode(props.nodeId)
        }
        if (!hierarchy && !!providerId) {
            props.getProviderHierarchies(providerId)
        }
    }

    useEffect(() => {
        if (props.pageTitle || props.docTitle) {
            const title = props.docTitle || props.pageTitle;
            setPageTitle(title);
        }
        props.getProviderList();
        fetchData();
    }, []);

    useEffect(() => {
        const userId = props.node?.users[0]?.userId;
        if (userId && props.canViewUserManagement) {
            props.loadUserById(userId);
        }
    }, [props.node]);

    useEffect(() => {
        props.getNode(props.nodeId);
    }, [props.nodeId]);

    return (
        <div className={styles.root}>
            <ProviderHeader
                searchConfig={props.searchConfig}
                clinicId={props.clinicId}
                pageTitle={props.pageTitle}
                collapsed={props.collapsible}
            />
            <main className={styles.main}>
                <div className="flex flex-1">
                    <ProviderSidebar
                        nodeId={props.nodeId}
                        providerId={providerId}
                        collapsible={props.collapsible}
                    />
                    <div className={styles.content}>
                        <div className={styles.children}>
                            {props.children}
                        </div>
                        <Footer
                            showSocial={false}
                            providerLocation={providerLocation}
                        />
                    </div>
                </div>
                <div className="flex-none">
                    {isAdminView && (
                        <Toolbar
                            nodeId={props.nodeId}
                            controls={props.adminControls}
                            isProvider
                        />
                    )}
                </div>
            </main>
        </div>
    );
}

ProviderBase.propTypes = {
    collapsible: PropTypes.bool,
    searchConfig: PropTypes.object,
    pageTitle: PropTypes.string,
    nodeId: PropTypes.number,
    clinicId: PropTypes.number,
    docTitle: PropTypes.string,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);

        return {
            userProfile,
            canViewUserManagement,
            node: state.entities.nodes?.[ownProps.nodeId],
        }
    },
    (dispatch) => ({
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
        loadUserById: (userId) => dispatch(AdminActions.loadUserById(userId)),
        getNode: (nodeId) => dispatch(ProviderActions.getNode(nodeId)),
        getProviderHierarchies: (providerId) => dispatch(ProviderActions.getProviderHierarchies(providerId)),
        getProviderList: () => dispatch(CouponActions.getProviderList()),
    }),
)(ProviderBase);
