import React, { useEffect, useRef, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import styles from "./ClinicProgramSetup.scss";
import classnames from "classnames";
import moment from "moment";
import { produce } from "immer";
import find from "lodash/find";
import forEach from "lodash/forEach";
import * as ClinicApi from "api/ClinicApi";
import * as CouponsApi from "api/CouponsApi";
import * as AdminActions from "actions/AdminActions";
import * as ClinicActions from "actions/ClinicActions";
import * as CouponActions from "actions/CouponActions";
import AccessDenied from "components/common/AccessDenied";
import AdminBase from "components/layout/AdminBase";
import Modal from "components/common/Modal";
import Button from "components/common/Button";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import AccountHoldHistory from "components/admin/widgets/AccountHoldHistory";
import PlaceRemoveHold from "components/admin/forms/PlaceRemoveHold";
import ProviderClinicLookupModal from "components/admin/widgets/ProviderClinicLookupModal";
import ClinicTags from "components/admin/widgets/ClinicTags";
import AvailableCouponOffers from "components/admin/widgets/AvailableCouponOffers";
import AvailableReports from "components/admin/widgets/AvailableReports";
import { getClinicInfo } from "utils/ClinicData";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as UserPermissions from "constants/UserPermissions";
import * as SearchTypes from "constants/SearchTypes";
import { PROGRAM_SETUP } from "constants/AdminControls"
import { getLinkLocation } from "utils/ClinicAdminLinks";

//TODO:
//    lockdown the page so user's with the wrong permissions and not enrolled in the program will see the correct error
//    Make sure that all links to this page are fixed
//    The finish setting up the hold form to add and remove holds

function ClinicProgramSetup(props) {
    const history = useHistory();
    const locationIdRef = useRef(null);
    const [showLoader, setShowLoader] = useState(false);
    const [showProviderLookup, setShowProviderLookup] = useState(false);
    const [ruSure, setRuSure] = useState(null);
    const [clinicTagList, setClinicTagList] = useState({});
    const [loading, setLoading] = useState(false);
    const [couponOffersFormData, setCouponOffersFormData] = useState({});
    const [reportsFormData, setReportsFormData] = useState({});
    const [showHoldHistory, setShowHoldHistory] = useState(false);
    const [showAddHold, setShowAddHold] = useState(false);
    const [showRemoveHold, setShowRemoveHold] = useState(false);
    const [refreshProviderLocation, setRefreshProviderLocation] = useState(false);
    const providerHold = find(props.accountHolds, {manufacturerId: props.providerId});

    useEffect(() => {
        props.getProviderList();
        props.getProviderLocations(props.clinicId, false);
        // if (props.canViewManufacturerAccountHold) {
            props.getProviderAccountHolds(props.clinicId);
        // }
    }, [props.clinicId]);

    useEffect(() => {
        if (refreshProviderLocation) {
            props.getProviderLocations(props.clinicId, false);
            setRefreshProviderLocation(false);
        }
    }, [refreshProviderLocation]);

    const loadClinic = () => {
        if (props.canViewClinicManagement) {
            props.getClinicById(props.clinicId);
        }
    }

    useEffect(() => {
        if(props.clinicId) {
            loadClinic();
        }
    }, [props.clinicId]);

    useEffect(() => {
        if(props.clinicInfo) {
            const cTags = {};
            forEach(props.clinicInfo.tags, tag => {
                cTags[tag] = tag;
            });
            setClinicTagList({
                ...clinicTagList,
                ...cTags,
            })
        }
    }, [props.clinicInfo]);

    const handleRemoveProviderLocation = (providerId) => {
        if(props.canEditClinicProgramSetup) {
            setRuSure({
                question: (
                    <div className="flex flex-column">
                        <h2>Are you sure you want to remove this location id from the clinic?</h2>
                    </div>
                ),
                data: {
                    clinicId: props.clinicId,
                    providerId: providerId,
                    location: null,
                },
                handler: (data) => handleSetProviderLocation(data)
            });
        }
    }

    const catchSetProviderLocation = (tableRowData) => {
        if(props.canEditClinicProgramSetup) {
            const location = tableRowData.location;
            setRuSure({
                question: (
                    <div className="flex flex-column">
                        <h3 className="margin-bottom-sm">Are you sure you want to apply this location
                            to {location.name} in {location.city}, {location.state}?</h3>
                        <h1 className="text-dark-green">Location ID: {location.locationId}</h1>
                    </div>
                ),
                data: {...location, clinicId: props.clinicInfo.clinicId, providerId: tableRowData.providerId}, // Ugly hack is what this is
                handler: (data) => handleSetProviderLocation(data),
            });
        }
    }

    const handleSetProviderLocation = (data) => {
        if(props.canEditClinicProgramSetup) {
            props.setClinicProviderLocation(data);
            setShowLoader(true);
            setRuSure(null);
            setShowProviderLookup(false);

            // Reload the clinic data to make sure it's up to date
            // can eliminate this if we have the API call return result
            // see entities.js ActionTypes.CLINIC_PROVIDER_LOCATION_UPDATED
            window.setTimeout(() => setRefreshProviderLocation(true), 1000);
            // setRefreshProviderLocation(true);

            setTimeout(() => {
                setShowLoader(false);
            }, 1000);
        }
    }

    const handleTagClicked = ({ value }) => {
        if(clinicTagList[value.id]) {
            const newTagList = produce(clinicTagList, (tags) => {
                delete tags[value.id]
                return tags;
            });
            setClinicTagList(newTagList)
        } else {
            setClinicTagList({
                ...clinicTagList,
                [value.id]: value,
            });
        }
    }

    const handleAddTag = (newTag) => {
        if(props.canEditClinicManagement) {
            setLoading(true);
            ClinicApi.createClinicTag(newTag)
                .then((res) => {
                    props.tagCreated(res.body);
                    handleTagClicked({value: res.body});
                    handleTagClicked({name: "tags", value: res.body})
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("creating clinic tag", error);
                });
        }
    };

    const handleCancel = () => {
         history.push(getLinkLocation("providerSelection", props.clinicId))
    };

    const saveCouponOffers = (data) => {
        setLoading(true);
        CouponsApi.updateProgramOffersForProvider(data)
            .then((res) => {
                setLoading(false);
            })
            .catch((error) => {
                handleErrorResponse("updating program offers", error);
                setLoading(false);
            });
    }

    const handleSave = () => {
        if(props.canEditClinicManagement) {
            setLoading(true);
            //Save Clinic Tags
            const tags = Object.keys(clinicTagList) || [];
            props.updateClinicTags(props.clinicId, tags);
            // Save Changes to Coupon Offers
            if (Object.keys(couponOffersFormData).length) {
                forEach(couponOffersFormData, (c) => {
                    saveCouponOffers(c);
                });
            } else setLoading(false);

            setCouponOffersFormData({});
            //Go back to the program selection
            handleCancel();
        }
    }

    const handleCreateHold = (form) => {
        if(props.canCreateManufacturerAccountHold) {
            const holdForm = {
                clinicId: props.clinicId,
                manufacturerId: props.providerId,
                startDate: new moment(),
                information: {
                    holdInfo: form
                }
            }
            props.createAccountHold(holdForm);
            setShowAddHold(false);
        }
    }

    const handleRemoveHold = (holdForm) => {
        if(props.canReleaseManufacturerAccountHold) {
            props.releaseAccountHold(props.clinicId, props.providerId, holdForm);
            setShowRemoveHold(false);
        }
    }

    const renderSelectedProgram = () => {
        return (
            <div className={styles.programSetup}>
                {!!providerHold?.manufacturerId && (
                    <div className={classnames(styles.couponHold, "margin-bottom-lg flex align-center spaced-content")}>
                        <div className="flex-1 flex spaced-content">
                            <i className="fal fa-exclamation-triangle fa-3x text-danger"/>
                            <div className="text-lg text-bold flex flex-centered text-danger">Coupon Hold</div>
                            <div>
                                <div>{providerHold?.information?.holdInfo?.reason}</div>
                                <div className="flex spaced-content text-sm">
                                    <div>{providerHold?.information?.holdInfo?.userInitials}</div><div>{!!providerHold?.startDate ? moment(providerHold.startDate).format("MM/DD/YYYY") : "UNKNOWN"}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Button
                                type="danger"
                                onClick={() => setShowRemoveHold(true)}
                                disabled={!props.canReleaseManufacturerAccountHold}
                            >
                                Remove Hold
                            </Button>
                        </div>
                    </div>
                )}
                <div className={styles.providerAccountInfo}>
                    <div className={styles.providerLocationForms}>
                        {renderProviderForm()}
                    </div>
                </div>
                <div>
                    <ClinicTags
                        clinicId={props.clinicId}
                        clinicTagList={clinicTagList}
                        onTagAdd={handleAddTag}
                        onTagClicked={handleTagClicked}
                    />
                    {!!props.provider?.isCouponProvider && (
                        <AvailableCouponOffers
                            programId={props.providerId}
                            clinicId={props.clinicId}
                            formData={couponOffersFormData}
                            handleSetFormData={(data) => setCouponOffersFormData(data)}
                        />
                    )}
                    {!!props.provider?.isReportingProvider && (
                        <AvailableReports
                            programId={props.providerId}
                            clinicId={props.clinicId}
                            formData={reportsFormData}
                            handleSetFormData={(data) => setReportsFormData(data)}
                        />
                    )}
                    <div className="flex spaced-content justify-flex-end margin-top-sm padding-bottom-md">
                        <Button
                            type="gray"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={!props.canEditClinicManagement}
                            onClick={handleSave}
                        >
                            Save Changes
                        </Button>
                    </div>
                </div>
            </div>
        )
    }

    const renderProviderForm = () => {
        const handleCopyClicked = () => {
            const element = locationIdRef.current;
            if (element) {
                element.select();
                element.setSelectionRange(0, 99999); // For mobile devices (says w3schools)
                if (document.execCommand("copy")) {
                    toast.success("Location ID copied to clipboard");
                }
            }
        }
        return (
            <div key={`providerLocationForm_${props.providerId}`}
                className={styles.providerIdForm}
            >
                <div className={styles.providerLogo}>
                    <img src={props.provider?.mediumImageUri}
                         alt={`${props.provider?.name} logo`}
                    />
                </div>
                <div className={styles.locationInfo}>
                    <label>Location Id:</label>
                    <input
                        className={classnames(styles.locationId, {
                            [styles.noLocation]: !(props.providerLocation && props.providerLocation.locationId),
                        })}
                        ref={locationIdRef}
                        onClick={handleCopyClicked}
                        type="text"
                        value={(props.providerLocation && props.providerLocation.locationId) ? props.providerLocation.locationId : "No location set" }
                        readOnly
                    />

                    {(!!props.providerLocation?.locationId) && (
                        <div className={classnames(styles.locationName, {
                            [styles.warn]: !props.providerLocation.isValidLocation
                        })}>
                            <span>{ props.providerLocation ? props.providerLocation.locationName : ''}</span>
                            {props.providerLocation.isValidLocation ? (
                                <i className="fas fa-check-circle" title="Active" />
                            ) : (
                                <i className="fas fa-triangle-exclamation" title="Inactive" />
                            )}
                        </div>
                    )}
                </div>
                <div className={styles.buttonWrap}>
                    <Button
                        onClick={() => setShowProviderLookup(true)}>
                        Browse Clinics
                    </Button>
                    <Button
                        onClick={() => handleRemoveProviderLocation(props.providerId)}
                        disabled={!props.canEditClinicProgramSetup}
                    >
                        Clear
                    </Button>
                </div>
            </div>
        )
    }

    return (
        <AdminBase
            searchConfig={SearchTypes.VOUCHER_CODE}
            clinicId={props.clinicId}
            collapsible
            pageTitle="Provider Setup"
            controls={PROGRAM_SETUP}
        >
            {!props.clinicInfo ? (
                <SpinnerTakeover show/>
            ) : (
                <div className={styles.root}>
                    <div className="topLine">
                        <div className="flex-1 text-right">
                            <div className="flex spaced-content justify-flex-end">
                                {(!providerHold?.manufacturerId && props.canCreateManufacturerAccountHold) && (
                                    <Button
                                        onClick={() => setShowAddHold(true)}
                                        type="primary"
                                        disabled={!props.canCreateManufacturerAccountHold}
                                    >
                                        Hold Processing
                                    </Button>
                                )}
                                <Button
                                    onClick={() => setShowHoldHistory(true)}
                                    type="primary"
                                >
                                    View Hold History
                                </Button>
                                <Button onClick={handleCancel}>
                                    View All Programs
                                </Button>
                            </div>
                        </div>
                    </div>
                    {renderSelectedProgram()}
                </div>
            )}
            {!!showLoader && <SpinnerTakeover show/>}
            {!!(showProviderLookup && props.canEditClinicProgramSetup) && (
                <ProviderClinicLookupModal
                    onRowClick={catchSetProviderLocation}
                    clinicId={props.clinicId}
                    providerId={props.providerId}
                    providerName={props.provider?.name}
                    onClose={() => setShowProviderLookup(false)}
                    canEditClinicProgramSetup={props.canEditClinicProgramSetup}
                />
            )}
            {!!ruSure && (
                <Modal
                    show={!!ruSure}
                    onClose={() => setRuSure(null)}
                    small
                >
                    <div className="flex-none text-center">{ruSure.question}</div>
                    <div className="flex flex-centered margin-top-md">
                        <Button wide className="margin-right-sm" large onClick={() => ruSure.handler(ruSure.data)} >Yes</Button>
                        <Button wide large onClick={() => setRuSure(null)} type="grey" >No</Button>
                    </div>
                </Modal>
            )}
            <SpinnerTakeover show={loading}/>
            <Modal show={showHoldHistory}
                onClose={() => setShowHoldHistory(false)}
                modalTitle="Hold History"
                small
            >
                {props.canViewManufacturerAccountHold ? (
                    <AccountHoldHistory
                        clinicId={props.clinicId}
                        providerId={props.providerId}
                        onClose={() => setShowHoldHistory(false)}
                    />
                ) : (
                    <AccessDenied/>
                )}
            </Modal>
            <Modal show={showAddHold}
                onClose={() => setShowAddHold(false)}
                modalTitle="Place Coupon Processing Hold"
                small
            >
                {props.canReleaseManufacturerAccountHold ? (
                    <PlaceRemoveHold
                        onSubmit={handleCreateHold}
                        onClose={() => setShowAddHold(false)}
                        canReleaseManufacturerAccountHold={props.canReleaseManufacturerAccountHold}
                    />
                ) : (
                    <AccessDenied/>
                )}
            </Modal>
            <Modal show={showRemoveHold}
                onClose={() => setShowRemoveHold(false)}
                modalTitle="Remove Coupon Processing Hold"
                small
            >
                {props.canReleaseManufacturerAccountHold ? (
                    <PlaceRemoveHold
                        removeHold
                        onSubmit={handleRemoveHold}
                        onClose={() => setShowRemoveHold(false)}
                        canReleaseManufacturerAccountHold={props.canReleaseManufacturerAccountHold}
                    />
                ) : (
                    <AccessDenied/>
                )}
            </Modal>
        </AdminBase>
    )
}

const connector = connect(
    (state, ownProps) => {
        const params = ownProps.match?.params
        const providerId = Number(params?.providerId) || null;
        const clinicId = Number(params?.clinicId) || null;
        const clinicInfo = getClinicInfo(state, clinicId);
        const userProfile = state.user.userProfile;
        // Permissions
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicProgramSetup = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_PROGRAM_SETUP, userProfile);
        const canViewManufacturerAccountHold = userHasPermission(PermissionTypes.VIEW, UserPermissions.MANUFACTURER_ACCOUNT_HOLD, userProfile);
        const canCreateManufacturerAccountHold = userHasPermission(PermissionTypes.CREATE, UserPermissions.MANUFACTURER_ACCOUNT_HOLD, userProfile);
        const canReleaseManufacturerAccountHold = userHasPermission(PermissionTypes.RELEASE, UserPermissions.MANUFACTURER_ACCOUNT_HOLD, userProfile);
        return {
            providerId,
            clinicId,
            clinicInfo,
            providerLocation: state.entities.clinicProviderLocations[clinicId]?.[providerId],
            provider: state.entities.providers?.[providerId],
            accountHolds: state.entities.accountHolds[clinicId],
            // Permissions
            canViewClinicManagement,
            canEditClinicManagement,
            canEditClinicProgramSetup,
            canViewManufacturerAccountHold,
            canCreateManufacturerAccountHold,
            canReleaseManufacturerAccountHold,
        }
    },

    (dispatch) => ({
        getClinicById: (clinicId) => dispatch(ClinicActions.getClinicById(clinicId)),
        getProviderAccountHolds: (clinicId) => dispatch(AdminActions.getProviderAccountHolds(clinicId)),
        createAccountHold: (clinicId, manufacturerId, form) => dispatch(AdminActions.createAccountHold(clinicId, manufacturerId, form)),
        releaseAccountHold: (clinicId, manufacturerId, form) => dispatch(AdminActions.releaseAccountHold(clinicId, manufacturerId, form)),
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        getProviderLocations: (clinicId, showOnlyEnrolled) => dispatch(ClinicActions.getProviderLocations(clinicId, showOnlyEnrolled)),
        setClinicProviderLocation: (data) => dispatch(ClinicActions.setClinicProviderLocation(data)),
        tagCreated: (tag) => dispatch(ClinicActions.tagCreated(tag)),
        updateClinicTags: (clinicId, tags) => dispatch(ClinicActions.updateClinicTags(clinicId, tags)),
    })
);

export default compose(
    withRouter,
    connector
)(ClinicProgramSetup);
