import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import classnames from "classnames";
import styles from "./Toast.scss";

// TODO update this to be a class based component. The useEffect is a little strange and not quite as granular as I would like.
export default function Toast(props) {
    const { toastStyle = "default" } = props;

    const [toastId] = useState(uuidv4());
    const [elementExists, setElementExists] = useState(false);
    useEffect(() => {
        const container = document.createElement("div");
        container.id = toastId;
        const rootElement = document.getElementById("toast-root");
        const addElement = props.show;
        if (addElement) {
            rootElement.appendChild(container);
            setElementExists(true);
        }
        return () => {
            if (addElement) {
                setElementExists(false);
                rootElement.removeChild(container);
            }

        };
    }, [props.show]);

    if (props.show && elementExists) {
        return ReactDOM.createPortal((
            <div
                data-testid="toast-root"
                className={classnames(styles.root, styles[toastStyle])}
            >
                <div className={styles.icon}>
                    <i
                        className={classnames({
                            "fa fa-check fa-2x": toastStyle === "success",
                            "fa fa-exclamation-triangle fa-2x": toastStyle === "danger",
                        })}
                    />
                </div>
                <div
                    data-testid="toast-content"
                    className={styles.content}
                >
                    {props.children}
                </div>
            </div>
        ), document.getElementById(toastId));
    } else {
        return null;
    }

}

Toast.propTypes = {
    toastStyle: PropTypes.oneOf(["default", "warn", "danger", "success"]),
    show: PropTypes.bool.isRequired,
    onDismissed: PropTypes.func,
    autoDismiss: PropTypes.bool,
    autoDismissTimeout: PropTypes.number,
    children: PropTypes.node,
};
