import React, {useEffect, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import classnames from "classnames";
import styles from "./ClinicProductTags.scss";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import mergeWith from "lodash/mergeWith";
import toLower from "lodash/toLower";
import * as AdminApi from "api/AdminApi";
import * as ClinicActions from "actions/ClinicActions";
import AccessDenied from "components/common/AccessDenied";
import AdminBase from "components/layout/AdminBase";
import Dropdown from "components/common/Dropdown";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {getClinicInfo} from "utils/ClinicData";
import { addCommasToNumber } from "utils/numeric";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {PRODUCT_TAGS} from "constants/AdminControls";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";
import * as Sentry from "@sentry/react";

function ClinicProductTags(props) {
    const [showLoader, setShowLoader] = useState(false);
    const [formData, setFormData] = useState({});
    const [filters, setFilters] = useState({numResults: 25, tagFilter: null});
    const [tags, setTags] = useState({});
    const tagOptions = map(tags, tag => ({name:tag.name, value:tag.productTagId}));

    const combinedData = {
        ...props.productTags.data,
        ...formData
    };

    const custom = (objValue, srcValue) => {
      if (_.isArray(objValue)) {
          return srcValue;
      }
    }
    mergeWith(combinedData, formData, custom);

    const filteredCombinedData = !!filters.tagFilter ? filter(combinedData, product => {
        switch(filters.tagFilter) {
          case "tagged":
              return product.productTagIds.length;
          case "notTagged":
              return !product.productTagIds.length;
          case "multiTagged":
              return product.productTagIds.length > 1;
          case "hasRecommended":
              return product.recommendations && product.recommendations.length;
          default:
            return includes(product.productTagIds, filters.tagFilter);
        }
    }) : combinedData;

    useEffect(() => {
        if (props.canViewClinicManagement) {
            setShowLoader(true);
            props.getClinicById(props.clinicId);
            props.getClinicProductTags(props.clinicId);

            AdminApi.getProductTags()
                .then(res => {
                    setTags(res.body);
                    setShowLoader(false);
                })
                .catch(error => {
                Sentry.captureException(error);
                });
        }
    }, [props.clinicId, props.canViewClinicManagement]);

    const handleSave = (clinicProductId, productTagIds=[]) => {
        if(props.canEditClinicManagement) {
            props.updateClinicProductTags(clinicProductId, productTagIds);
        }
    }

    const handleTagClicked = (tag, row) => {
        if(props.canEditClinicManagement) {
            // Use if there can be more than one tag selected, will need to make changes to setFormData below
            //
            // let productTagIds = row.tags;
            // if(includes(row.tags, tag)) {
            //     productTagIds = reject(tempTags, t => {return t === tag});
            // } else {
            //     productTagIds.push(tag);
            // }

            const productTagIds = includes(row.productTagIds, tag) ? [] : [tag];

            setFormData({
                ...formData,
                [row.clinicProductId]: {
                    ...row,
                    "productTagIds": productTagIds,
                }
            })
            handleSave(row.clinicProductId, productTagIds)
        }
    };

    const NUM_RESULTS_PER_PAGE_OPTIONS = [
        {name:"10 Results per page", value:10},
        {name:"15 Results per page", value:15},
        {name:"20 Results per page", value:20},
        {name:"25 Results per page", value:25},
        {name:"50 Results per page", value:50},
    ];
    const TAG_OPTIONS_PLUS = [
        ...tagOptions,
        {name: "Tagged", value: "tagged"},
        {name: "Not Tagged", value: "notTagged"},
        {name: "Multi-tagged", value: "multiTagged"},
        //TODO: Currently will return nothing bacause we don't know what the recommendations are...
        {name: "Has Recommendations", value: "hasRecommended"},
    ];

    const handleChangeFilters = ({name, value}) => {
        setFilters({
            ...filters,
            [name]: value,
        })
    }

    const COLUMNS = [
        {
            name: "Product Id",
            selector: "remoteProductDisplayID",
            key: "remoteProductDisplayID",
            searchable: true,
            sortable: true,
            sortValue: row => toLower(row.remoteProductDisplayID) || 0,
        }, {
            name: "Product Name",
            selector: "productName",
            key: "productName",
            searchable: true,
            sortable: true,
            sortValue: row => toLower(row.productName) || 0,
        }, {
            name: "Qty Purchased (1YR)",
            selector: "quantityPurchased",
            key: "quantityPurchased",
            sortable: true,
            sortValue: row => Number(row.quantityPurchased) || 0,
            format: row => (
                <div className="text-center">
                    {row.quantityPurchased ? row.quantityPurchased : "-"}
                </div>
            ),
        }, {
            name: "Total Revenue (1YR)",
            selector: "totalRevenue",
            key: "totalRevenue",
            sortable: true,
            sortValue: row => Number(row.totalRevenue) || 0,
            format: row => (
                <div className="text-center">
                    {row.totalRevenue ? `$${addCommasToNumber(row.totalRevenue)}` : "-"}
                </div>
            ),
        }, {
            name: "Tags",
            selector: "tags",
            key: "tags",
            format: row => (
                <div className="flex spaced-content flex-wrap text-sm" style={{minWidth: "1000px"}}>
                    {map(tags, (tag, index) => (
                        <div
                            key={index}
                            onClick={() => props.canEditClinicManagement ? handleTagClicked(tag.productTagId, row) : {}}
                            className={classnames(styles.tags, {
                                [styles.selected]: includes(row.productTagIds, tag.productTagId),
                                [styles.disabled]: !props.canEditClinicManagement,
                            })}
                            title={tag.name}
                        >
                            {tag.displayName}
                        </div>
                    ))}
                </div>
            ),
        }
    ];

    return (
        <AdminBase
            clinicId={props.clinicId}
            searchConfig={SearchTypes.CLINIC_PRODUCT_TAGS_SEARCH}
            collapsible
            controls={PRODUCT_TAGS}
            pageTitle="Clinic Product Tags"
        >
            {props.canViewClinicManagement ? (
                <div className={styles.root}>
                    <div className="flex flex-wrap margin-bottom-md spaced-content">
                        <Dropdown
                            value={filters.numResults}
                            name="numResults"
                            onChange={handleChangeFilters}
                            options={NUM_RESULTS_PER_PAGE_OPTIONS}
                            label="Results Per Page"
                        />
                        <Dropdown
                            value={filters.tagFilter}
                            name="tagFilter"
                            onChange={handleChangeFilters}
                            options={TAG_OPTIONS_PLUS}
                            placeholder="--Select Tag--"
                            label="Filter"
                        />
                    </div>
                    <div className="flex">
                        <SortableDataTable
                            columns={COLUMNS}
                            rawData={filteredCombinedData}
                            striped
                            green
                            resultsPerPage={filters.numResults}
                            allowSearch
                            hideSearchBar
                            search={props.search}
                        />
                    </div>
                    <SpinnerTakeover show={showLoader || props.productTags.loading}/>
                </div>
            ) : (
                <AccessDenied/>
            )}
        </AdminBase>
    )
}

const connector = connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, clinicId);
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            clinicId,
            canViewClinicManagement,
            canEditClinicManagement,
            clinic: clinicInfo,
            productTags: state.entities.productTags,
            productAnalysis: state.entities.productAnalysis,
            search: state.entities.productTags.query,
        }
    },
    (dispatch) => ({
        getClinicById: (clinicId) => dispatch(ClinicActions.getClinicById(clinicId)),
        getClinicProductTags: (clinicId) => dispatch(ClinicActions.getClinicProductTags(clinicId)),
        updateClinicProductTags: (clinicProductId, productTagIds) => dispatch(ClinicActions.updateClinicProductTags(clinicProductId, productTagIds)),
    })
);

export default compose(
    withRouter,
    connector
)(ClinicProductTags);
