import React, { useEffect } from "react";
import {connect} from "react-redux";
import map from "lodash/map";
import styles from "./ProviderCouponLibrary.scss";
import * as CouponActions from "actions/CouponActions";
import AccessDenied from "components/common/AccessDenied";
import ProgramTile from "components/coupons/widgets/ProgramTile";
import ProviderBase from "components/layout/ProviderBase";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {getNodeId} from "utils/AdminData";
import {getClinicInfo} from "utils/ClinicData";
import getProviderId from "utils/getProviderId";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ProviderCouponLibrary(props) {

    const fetchData = () => {
        if (props.canViewCouponLibrary) {
            props.getPrograms(props.clinicId);
        }
    };

    useEffect(() => {
        fetchData();
    }, [props.clinicId]);

    const isProviderView = () => {
        return props.clinicId && props.location.pathname.indexOf("/provider/") > -1;
    };

    const renderProgramTiles = () => {
        if (Object.keys(props.providers).length && Object.keys(props.programs).length) {
            return map(props.programs, couponProgram => {
                const provider = props.providers[couponProgram.providerId] || {};

                if (couponProgram.isLibraryDisabled) {
                    return null;
                }

                const data = {
                    ...couponProgram,
                    imageUrl: provider.mediumImageUri,
                    providerName: provider.name,
                };

                return (
                    <ProgramTile
                        canViewClinicEnrollment={props.canViewClinicEnrollment}
                        canEditClinicEnrollment={props.canEditClinicEnrollment}
                        programInfo={data}
                        key={`programId_${couponProgram.id}`}
                        providerView={isProviderView()}
                        clinicId={props.clinicId}
                        nodeId={props.nodeId}
                    />
                )
            });
        }
        return (
            <div className={styles.noPrograms}>
                <span>No Programs to show!</span>
            </div>
        );
    };

    return (
        <ProviderBase
            location={props.location}
            clinicId={props.clinicId}
            docTitle="Coupon Library"
            providerId={props.providerId}
            nodeId={props.nodeId}
        >
            {!props.clinicInfo ? (
                <SpinnerTakeover show />
            ) : (
                <div className={styles.root}>
                    <div className={styles.gridContainer}>
                        <div className="flex-none flex">
                            <h2 className="flex-1">Programs</h2>
                        </div>
                        {!(props.canViewCorporateGroupClinic && props.canViewCouponLibrary) ? (
                            <AccessDenied/>
                        ) : (
                            <div className={styles.grid}>
                                {renderProgramTiles()}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </ProviderBase>
    );
}

export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const nodeId = getNodeId(state, ownProps);
        const providerId = getProviderId(params, userProfile);

        //Permissions
        const canViewCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canViewClinicEnrollment = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_ENROLLMENT, userProfile);
        const canEditClinicEnrollment = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_ENROLLMENT, userProfile);

        return {
            clinicId,
            clinicInfo,
            nodeId,
            providerId,
            providers: state.entities.providers,
            programs: state.entities.programs,

            //Permissions
            canViewCouponLibrary,
            canViewCorporateGroupClinic,
            canViewClinicEnrollment,
            canEditClinicEnrollment,
        }
    },
    (dispatch) => ({
        getPrograms: (clinicId) => dispatch(CouponActions.getPrograms(clinicId)),
    }),
)(ProviderCouponLibrary);
