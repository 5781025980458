exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".NotificationList__root{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.NotificationList__noNotifications{padding:0.5em 0 1.5em}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/notifications/widgets/NotificationList.scss"],"names":[],"mappings":"AAAA,wBAAM,oBAAoB,aAAa,0BAA0B,qBAAqB,CAAC,mCAAiB,qBAAqB,CAAC","file":"NotificationList.scss","sourcesContent":[".root{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.noNotifications{padding:0.5em 0 1.5em}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "NotificationList__root",
	"noNotifications": "NotificationList__noNotifications"
};