import React, {useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./NotificationTile.scss";
import {MESSAGE, OFFER, SUCCESS, WARN} from "constants/Notifications";
import GreenlineLogo from "components/common/branding/greenline-logo-circle.png";

const ICON_NAMES = {
    [SUCCESS]: "far fa-check-circle",
    [WARN]: "far fa-exclamation-triangle",
    [OFFER]: "far fa-check-circle",
}

export default function HeroMessage(props) {
    const [isDismissed, setIsDismissed] = useState(false);

    const handleDismissed = () => {
        if (!props.dismissible) {
            return;
        }

        if (props.onDismissed) {
            props.onDismissed(props.id);
        }
        setIsDismissed(true);
    }

    if (isDismissed) {
        return null;
    }

    return (
        <div
            className={classnames(styles.root, styles.hero, styles[props.type], {
                [styles.small]: props.small,
                [styles.medium]: props.medium,
                [styles.fullWidth]: props.fullWidth,
                [styles.dismissible]: props.dismissible,
            })}
            onClick={handleDismissed}
        >
            <div className={styles.icon}>
                {props.type === MESSAGE ?
                    <img src={GreenlineLogo} alt="Greenline Logo" />
                    :
                    <i className={ICON_NAMES[props.type]} />
                }
            </div>
            <div className={styles.content}>
                <h4>{props.label}</h4>
                <p>{props.description}</p>
                {props.dismissible && (
                    <div className={styles.closeBtn}>
                        <i className="far fa-times-circle" />
                    </div>
                )}
            </div>
        </div>
    );
}

HeroMessage.propTypes = {
    type: PropTypes.string.isRequired,
    label: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
    dismissible: PropTypes.bool,
    onDismissed: PropTypes.func,
    small: PropTypes.bool,
    medium: PropTypes.bool,
    fullWidth: PropTypes.bool,
};
