import React from "react";
import { formatUSD, setFixed } from "../../../utils/numeric";
import AdminBase from "components/layout/AdminBase";
import {CLINIC_TAGS} from "constants/AdminControls";
import AccessDenied from "components/common/AccessDenied";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import Dropdown from "components/common/Dropdown";
import { useState } from "react";
import { connect } from "react-redux";
import { useEffect } from "react";
import * as ClinicApi from "api/ClinicApi";
import TextBox from "components/common/TextBox";
import * as RoleTypes from "constants/RoleTypes";

function OpportunityCalculator(props) {
    const workingDaysInYear = 240;
    const monthsInYear = 12;
    const daysPerMonth = workingDaysInYear/monthsInYear;

    const [formData, setFormData] = useState({});
    const [clinicData, setClinicData] = useState({});
    const [isBusy, setIsBusy] = useState(true);
    const [productOptions, setProductOptions] = useState([]);

    const currencyFormat = {
        useCents: true,
        useCommas: true,
        decimals: 0
    }

    const fixedFormat = {
        useCommas: true,
        decimals: 0
    }

    useEffect(() => {
        if(props.clinicId) {
            ClinicApi.getAverageClinicSales(props.clinicId)
                .then((res) => {
                    const products = res.body.map(x => { 
                        return { name: x.productName, value: x.productName }
                    });
                    setProductOptions(products);

                    const salesData = res.body.map(x => {
                        return { product: x.productName, qty: x.dailyDosesSold }
                    });
                    setClinicData(salesData);
                })
                .catch(err => {
                    console.error(err);
                })
                .finally(() => {
                    setIsBusy(false);
                });
        }
    }, [props.clinicId])

    const handleProductChanged = (name, value) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    function getSingleDoseCount() {
        if(!formData || !formData.selectedProduct || !clinicData) {
            return 0;
        }

        const product = formData.selectedProduct;
        const productData = clinicData.filter(line => line.product === product)[0];

        return productData.qty > 0 ? setFixed(productData.qty, fixedFormat) : " < 1"
    }

    function formattedMissedRevenue() {
        return formatUSD(missedRevenue(), currencyFormat);
    }

    function missedRevenue() {
        return (formData?.profitPerDose || 0) * 
            (formData?.subscriptionsPerDay || 0) *
            workingDaysInYear;
    }

    function getMonthlySubscriptions() {
        return (formData?.subscriptionsPerDay || 0) * daysPerMonth
    }

    function formattedMonthlyRevenue() {
        return formatUSD(missedRevenue() / monthsInYear, currencyFormat)
    }

    return (
        <AdminBase
            clinicId={props.clinicId}
            collapsible
            pageTitle="Opportunity Calculator"
            controls={CLINIC_TAGS}
        >
            {props.canViewCalculators ? (
                <div className="margin-bottom-lg">
                    <div className={"height-100-perc flex flex-column"}>
                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">
                                <Dropdown
                                    options={productOptions}
                                    label={"Product"}
                                    name="productId"
                                    value={formData.selectedProduct}
                                    placeholder={"--Select Product--"}
                                    onChange={(field) => handleProductChanged("selectedProduct", field.value)}
                                />
                            </div>
                            <div className="flex-5">
                                <TextBox
                                    label="Profit Per Dose ($)"
                                    inputType="number"
                                    value={formData.profitPerDose}
                                    onChange={(field) => handleProductChanged("profitPerDose", field.value)}
                                />
                            </div>
                            <div className="flex-5">
                                <TextBox
                                    label="Subscriptions Per Day (Goal)"
                                    inputType="number"
                                    value={formData.subscriptionsPerDay}
                                    onChange={(field) => handleProductChanged("subscriptionsPerDay", field.value)}
                                />
                            </div>
                        </div>
                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">
                                Daily Average Single Dose Sales (Current): {getSingleDoseCount()}</div>
                        </div>

                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">Monthly Lost Revenue ({monthsInYear} months/year): {formattedMonthlyRevenue()}</div>
                        </div>

                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">Annual Lost Revenue ({workingDaysInYear} days/year): {formattedMissedRevenue()}</div>
                        </div>

                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">New Monthly Subscriptions ({daysPerMonth} days/month): {getMonthlySubscriptions()}</div>
                        </div>

                        <div className="flex justify-flex-end margin-bottom-sm">
                            <div className="flex-5">New Subscriptions (End of Year 1): {getMonthlySubscriptions() * monthsInYear}</div>
                        </div>
                    </div>
                </div>
            ) : <AccessDenied/>}
            <SpinnerTakeover show={isBusy}/>
        </AdminBase>
    );
}

export default connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const userProfile = state.user.userProfile;
        
        const rolesToAllow = RoleTypes.ROLES.filter(x => x.userType === "GL").map(x => x.value);
        const allowedUserPermissions = userProfile.roles.filter(userGroup => {
            return rolesToAllow.some(allowedGroup => allowedGroup === userGroup);
         });
        const canViewCalculators = allowedUserPermissions.length > 0;

        return {
            clinicId,
            canViewCalculators: canViewCalculators,
        }
    }
)(OpportunityCalculator)