import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ProgramOffer.scss";
import * as CouponOfferStatusIds from "constants/CouponOfferStatusIds";

export default function ProgramOffer(props) {
    const { sidebarTitle, activationDate } = props.offer;

    const CLAIMED_JSX = (
        <span>
            <i style={{ color: "green" }} className="fas fa-check-square" />&nbsp;
        </span>
    );
    const NOT_CLAIMED_JSX = (
        <span>
            <i style={{ color: props.selected ? "green" : "gray" }} className="far fa-square" />&nbsp;
        </span>
    );
    const NEW_JSX = (
        <span>
            <i style={{ color: "orange" }} className="fas fa-paw" />&nbsp;
        </span>
    );

    const STATUS_JSX_MAP = {
        [CouponOfferStatusIds.CLAIMED]: CLAIMED_JSX,
        [CouponOfferStatusIds.NOT_CLAIMED]: NOT_CLAIMED_JSX,
        [CouponOfferStatusIds.NEW]: NEW_JSX,
        optedIn: CLAIMED_JSX,
        notOptedIn : NOT_CLAIMED_JSX,
    };

    const handleClick = (e) => {
        // Remove if we do want it to go the coupon page when clicking the checkboxes
        if (props.optInChange && !props.offer.isReadOnly) {
            e.stopPropagation();
            e.preventDefault();
            // To here

            const offer = {
                ...props.offer,
                groupId: props.groupId,
            };
            props.optInChange(offer, !activationDate);
        }
    };

    const handleSelect = () => {
        const offer = {
            ...props.offer,
            groupId: props.groupId,
        };
        props.onClick(offer);
    };

    const checkIcon = !!activationDate ? STATUS_JSX_MAP.optedIn : STATUS_JSX_MAP.notOptedIn;
    return (
        <div
            className={classnames(styles.root, {
                [styles.selected]: props.selected,
                "pointer": !!props.optInChange,
                "not-allowed": !props.optInChange,
            })}
            onClick={handleSelect}
        >
            <span onClick={handleClick}>{checkIcon}</span>
            <span dangerouslySetInnerHTML={{ __html: sidebarTitle }} />
        </div>
    );
}

ProgramOffer.propTypes = {
    offer: PropTypes.object,
    onClick: PropTypes.func,
    selected: PropTypes.bool,
    optInChange: PropTypes.func,
    groupId: PropTypes.number,
};
