exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".AdminClinicSettingsForm__root{max-width:2000px;margin:auto}.AdminClinicSettingsForm__root hr{width:100%;border:none;border-bottom:1px solid #cfcfcf}.AdminClinicSettingsForm__root .AdminClinicSettingsForm__flex1{-ms-flex:1 1 0px;flex:1 1 0;min-width:300px}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/admin/forms/AdminClinicSettingsForm.scss"],"names":[],"mappings":"AAAA,+BAAM,iBAAiB,WAAW,CAAC,kCAAS,WAAW,YAAY,+BAA+B,CAAC,+DAAa,iBAAiB,WAAW,eAAe,CAAC","file":"AdminClinicSettingsForm.scss","sourcesContent":[".root{max-width:2000px;margin:auto}.root hr{width:100%;border:none;border-bottom:1px solid #cfcfcf}.root .flex1{-ms-flex:1 1 0px;flex:1 1 0;min-width:300px}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "AdminClinicSettingsForm__root",
	"flex1": "AdminClinicSettingsForm__flex1"
};