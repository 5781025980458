import React, {useCallback, useEffect, useState} from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import SortableDataTable from "components/common/SortableDataTable";
import PropTypes from "prop-types";
import DataTable from "components/common/datatable/DataTable";
import classnames from "classnames";
import getLocalTimezone from "utils/getLocalTimezone";
import * as styles from "./ProductAnalysis.scss";
import { formatUSD } from "utils/numeric";
import Button from "components/common/Button";
import * as AdminApi from "api/AdminApi";
import {debounce, orderBy} from "lodash";
import TextBox from "components/common/TextBox";
import ResultsPerPageDropdown from "components/coupons/widgets/ResultsPerPageDropdown";
import * as AdminActions from "actions/AdminActions";
import { mapPriceHistoryAnalysisFromServerToApp } from "data/serverMapping";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import {handleErrorResponse} from "utils/request";

function ProductAnalysis(props) {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invoices, setInvoices] = useState({});
    const [priceHistory, setPriceHistory] = useState([]);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState("");
    const [sortBy, setSortBy] = useState("invoiceDate");
    const [orderDir, setOrderDir] = useState("desc");
    const [totalCount, setTotalCount] = useState(0);
    const page = ((offset / limit) + 1);
    const totalPages = Math.ceil(totalCount / limit);

    const fetchData = useCallback(debounce((searchData) => {
        if (props.clinicId && props.reason.clinicProductId) {
            load(searchData);
        }
    }, 500), []);

    const load = (searchData) => {
        if(props.canViewClinicManagement) {
            setLoading(true);
            AdminApi.getInvoiceLineItems(searchData)
                .then(res => {
                    setInvoices(res.body.data);
                    setTotalCount(res.body.totalRecordCount);
                    setLoading(false);
                })
                .catch(error => {
                    handleErrorResponse("loading invoice line items", error);
                    setLoading(false);
                });
        }
    }

    const getPriceHistory = () => {
        AdminApi.getPriceHistory(props.clinicId, props.reason.clinicProductId)
            .then(res => {
                setPriceHistory(mapPriceHistoryAnalysisFromServerToApp(res.body));
            })
            .catch((error) => {
                handleErrorResponse("loading price history", error);
            });
    }

    useEffect(() => {
        const searchData = {
            invoiceStartDate: null,
            invoiceEndDate: null,
            clinicId: props.clinicId,
            clinicProductId: props.reason.clinicProductId,
            orderBy: sortBy,
            orderDir,
            search,
            limit,
            offset,
        };
        fetchData(searchData);
    }, [search, offset, sortBy, orderDir, limit]);

    useEffect(() => {
        if (offset !== 0) {
            setOffset(0);
        } else {
            const searchData = {
                invoiceStartDate: null,
                invoiceEndDate: null,
                clinicId: props.clinicId,
                clinicProductId: props.reason.clinicProductId,
                orderBy: sortBy,
                orderDir,
                search,
                limit,
                offset: 0,
            };
            fetchData(searchData);
        }

        getPriceHistory();
    }, [props.clinicId, props.reason.clinicProductId]);

    //TODO: Should the invoice numeber be clickable here? (similar to everywhere else...)
    const INVOICE_COLUMNS = [
        {
            name: "Inv. Date",
            selector: "invoiceDate",
            key: "invoiceDate",
            sortable: true,
            format: row => row.invoiceDate ? getLocalTimezone(row.invoiceDate, 'MM/DD/YYYY') : null,
        }, {
            name: "Inv. #",
            selector: "extendedInvoiceNumber",
            key: "extendedInvoiceNumber",
            searchable: true,
            sortable: true,
        }, {
            name: "Patient",
            selector: "petName",
            key: "petName",
            searchable: true,
            sortable: true,
        }, {
            name: "Pet Owner",
            selector: "petOwnerLastName",
            key: "petOwnerLastName",
            searchable: true,
            sortable: true,
            format: row => (
                <div>
                    {row.petOwnerLastName}, {row.petOwnerFirstName}
                </div>
            )
        }, {
            name: "Description",
            selector: "description",
            key: "description",
            searchable: true,
            sortable: true,
        }, {
            name: "Qty",
            selector: "quantity",
            key: "quantity",
            sortable: true,
        }, {
            name: "Price",
            selector: "price",
            key: "price",
            sortable: true,
            format: row => row.price ? formatUSD(row.price) : "Free",
        }
    ];

    const OTHER_COLUMNS = [
        {
            name: "Qty",
            selector: "quantity",
            key: "quantity",
        }, {
            name: "Sold",
            selector: "sold",
            key: "sold",
        }, {
            name: "Unit Price (mean/min/max)",
            selector: "mean",
            key: "mean",
            format: row => (
                <div>
                    ${row.mean} / ${row.min} / ${row.max}
                </div>
            )
        }
    ];

    const handlePreviousPageClicked = () => {
        const newOffset = (offset - limit);
        setOffset(newOffset);
    }

    const handleNextPageClicked = () => {
        const newOffset = (offset + limit);
        setOffset(newOffset);
    }

    const handleSort = (sort, direction) => {
        setSortBy(sort);
        setOrderDir(direction);
        setOffset(0);
    }

    const handleSearch = ({value}) => {
        setSearch(value)
        setOffset(0);
    }

    const handleLimit = ({value}) => {
        setLimit(value);
        setOffset(0);
    }

    return (
        <div className={styles.root}>
            <hr className="margin-top-sm margin-bottom-md"/>
            <div className="flex">
                <div className="flex-1 flex-column text-lg">
                    {props.reason.clinicProduct && (
                        <div>Clinic Product: {props.reason.clinicProduct}</div>
                    )}
                    {props.reason.description && (
                        <div>Mapped Product: {props.reason.description}</div>
                    )}
                </div>
                <div className="flex-none text-center flex-column">
                    <div className={classnames(styles.otherTable, "flex-none text-sm text-center margin-bottom-x-sm", {
                        [styles.show]: show,
                    })}>
                        <DataTable
                            columns={OTHER_COLUMNS}
                            data={((priceHistory.length > 5) && !show) ?  priceHistory.slice(0, 5) : priceHistory}
                            pagination={false}
                        />
                    </div>
                    {priceHistory.length > 5 && (
                        <Button
                            onClick={() => setShow(!show)}
                            text
                            type="primary"
                        >
                            {show ? "Hide" : "Show More"}
                        </Button>
                    )}
                </div>
            </div>
            <hr className="margin-top-md margin-bottom-md"/>
            <div className="flex spaced-content">
                <div className="flex-1">
                    <TextBox
                        name="search"
                        value={search}
                        onChange={handleSearch}
                        placeholder="Search Invoices"
                        // label="Search"
                    />
                </div>
                <div className="flex-1">
                    <ResultsPerPageDropdown
                        onChange={handleLimit}
                        value={limit}
                        max={totalCount}
                    />
                </div>
            </div>
            <SortableDataTable
                columns={INVOICE_COLUMNS}
                rawData={invoices}
                striped
                green
                resultsPerPage={limit}
                onSort={handleSort}
            />
            <SpinnerTakeover show={loading}/>
            {totalPages > 1 && (
                <div className="full-width flex align-center justify-around">
                    <Button
                        onClick={handlePreviousPageClicked}
                        disabled={page <= 1}
                    >
                        PREV
                    </Button>
                    <span>Page {page} / {totalPages}</span>
                    <Button
                        onClick={handleNextPageClicked}
                        disabled={page >= totalPages}
                    >
                        NEXT
                    </Button>
                </div>
            )}
        </div>
    )
}

ProductAnalysis.propTypes = {
    clinicId: PropTypes.number.isRequired,
    reason: PropTypes.object.isRequired,
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            priceHistory: state.entities.priceHistories[ownProps.reason.clinicProductId]
        }
    },
    (dispatch) => ({
        getPriceHistory: (clinicId, clinicProductId) => dispatch(AdminActions.getPriceHistory(clinicId, clinicProductId)),
    })
);

export default compose(
    withRouter,
    connector
)(ProductAnalysis);
