import React from "react";
import PropTypes from "prop-types";
import LeafPartyLeaderboard from "./LeafPartyLeaderboard";

export default function PuppyLeafPartyLeaderboard(props) {
    return (
        <LeafPartyLeaderboard
            puppyData={props.data}
            currentClinicId={props.currentClinicId}
            isAnimated={props.animate}
            showAllPoints={props.showAllPoints}
            printing={props.printing}
        />
    );
}

PuppyLeafPartyLeaderboard.propTypes = {
    animate: PropTypes.bool,
    currentClinicId: PropTypes.number,
    data: PropTypes.arrayOf(PropTypes.shape({
        ClinicId: PropTypes.number,
        ClinicAlias: PropTypes.string,
        TotalPuppyBowlPoints: PropTypes.number,
        Place: PropTypes.number,
        PercentGrowth: PropTypes.number,
    })),
    printing: PropTypes.bool,
    showAllPoints: PropTypes.bool,
};

PuppyLeafPartyLeaderboard.defaultProps = {
    animate: false,
    showAllPoints: false,
};
