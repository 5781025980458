import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import * as Yup from "yup";
import "yup-phone-lite";
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import styles from "./WellnessEnrollmentModal.scss";
import * as WellnessApi from "api/WellnessApi";
import ClientPatientForm from "./ClientPatientForm";
import JoyRide from "components/tour/widgets/JoyRide";
import Modal from "components/common/Modal";
import PaymentSummary from "./PaymentSummary";
import PlanOptions from "./PlanOptions";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { TOUR_PLAN_ENROLLMENT_PART_1, TOUR_PLAN_ENROLLMENT_PART_2 } from "utils/demoTourHelpers";
import { handleErrorResponse } from "utils/request";
import toast from "utils/toast";
import * as DemoTourStates from "constants/DemoTourStates";
import useFormHandlers from "utils/useFormHandlers";
import { AVIAN, CANINE, FELINE, MAMMAL, REPTILE } from "constants/MappingTypes";

const CLIENT_LOOKUP = "clientLookup";
const SELECT_PLAN = "selectPlan";
const SUMMARY_PAYMENT = "summaryPayment";
const ENROLLMENT_STEPS = [CLIENT_LOOKUP, SELECT_PLAN, SUMMARY_PAYMENT];

const patientsSchema = Yup.array().of(
	Yup.object().shape({
		dob: Yup.date(),
		patientId: Yup.number(),
		patientName: Yup.string().required(),
		remoteId: Yup.string().required(),
		species: Yup.string().required(),
		weightInPounds: Yup.string(),
	}),
).min(1, "Please select at least one patient from the list");

const ownerSchema = Yup.object().shape({
	petOwnerId: Yup.number().nullable(true),
	petOwnerFirstName: Yup.string()
		.when("petOwnerId", {
			is: (petOwnerId) => !petOwnerId,
			then: () => Yup.string().required(),
		}),
	petOwnerLastName: Yup.string()
		.when("petOwnerId", {
			is: (petOwnerId) => !petOwnerId,
			then: () => Yup.string().required(),
		}),
	petOwnerEmail: Yup.string().email().required(),
	petOwnerClientId: Yup.string()
		.when("petOwnerRemoteId", {
			is: (petOwnerRemoteId) => !petOwnerRemoteId?.length,
			then: () => Yup.string().required("Must include one of these fields: petOwnerClientId, petOwnerRemoteId"),
		}),
	petOwnerRemoteId: Yup.string()
		.when("petOwnerClientId", {
			is: (petOwnerClientId) => !petOwnerClientId?.length,
			then: () => Yup.string().required("Must include one of these fields: petOwnerRemoteId, petOwnerClientId"),
		}),
	phone: Yup.string().phone(["US", "CA"]).required(),
	street1: Yup.string().required(),
	street2: Yup.string(),
	city: Yup.string().required(),
	stateProvince: Yup.string().required(),
	postalCode: Yup.string().when("zipCode", {
		is: (petOwnerClientId) => !petOwnerClientId?.length,
		then: () => Yup.string().required("Must include one of these fields: postalCode, zipCode"),
	}),
	// zipCode: Yup.string().when("postalCode", {
	// 	is: (petOwnerClientId) => !petOwnerClientId?.length,
	// 	then: () => Yup.string().required("Must include one of these fields: zipCode, postalCode"),
	// }),
// }, [["petOwnerClientId", "petOwnerRemoteId", "postalCode", "zipCode"]]).required();
}, [["petOwnerClientId", "petOwnerRemoteId"]]).required();

const enrollmentMappingSchema = Yup.object().shape({
	clinicProductId: Yup.number().required(),
	id: Yup.number().required(),
});

function WellnessEnrollmentModal(props) {
	const {
		clinicId,
    completedTourSections,
		onClose,
		plans,
		show = false,
    tourStarted,
	} = props;
	const serviceSchema = Yup.object().shape({
		clinicPlanServiceTypeId: Yup.number().nullable(true),
		enrollmentMappings: Yup.lazy((obj) => Yup.object().shape(
			mapValues(obj, o => enrollmentMappingSchema),
		).default({})),
		isUnlimited: Yup.bool().default(false),
		maxQuantityAllowedInTerm: Yup.number().nullable(true),
		monthlyAddOnCostToClient: Yup.number().nullable(true),
		serviceCategoryId: Yup.number().nullable(true),
		serviceCategoryName: Yup.string().nullable(true),
		serviceTypeId: Yup.number().nullable(true),
		serviceTypeName: Yup.string().nullable(true),
		stripeMonthlyPriceId: Yup.string().nullable(true),
		stripeProductId: Yup.string().nullable(true),
		stripeYearlyPriceId: Yup.string().nullable(true),
		yearlyAddOnCostToClient: Yup.number().nullable(true),
	}).default({});

	const plansSchema = Yup.object().shape({
		clinicPlanDto: Yup.object().shape({
			alias: Yup.string().nullable(true),
			maxAgeInMonths: Yup.number().nullable(true),
			maxAgeInYears: Yup.number().nullable(true),
			maxWeightInPounds: Yup.number().nullable(true),
			minAgeInMonths: Yup.number().nullable(true),
			minAgeInYears: Yup.number().nullable(true),
			minWeightInPounds: Yup.number().nullable(true),
		}).default({}),
		clinicPlanInstanceId: Yup.number().required(),
		clinicPriceScheduleId: Yup.number().nullable(true),
		displayOrder: Yup.number().nullable(true),
		enrollmentMappings: Yup.lazy((obj) => Yup.object().shape(
			mapValues(obj, o => enrollmentMappingSchema),
		).default({})),
		groupName: Yup.string().nullable(true),
		isActive: Yup.bool().default(false),
		monthlyCostToClient: Yup.number().nullable(true),
		name: Yup.string().nullable(true),
		providerFee: Yup.number().nullable(true),
		providerId: Yup.number().nullable(true),
		services: Yup.lazy((obj) => Yup.object().shape(
			mapValues(obj, o => serviceSchema),
		).default({})),
		stripeMonthlyPriceId: Yup.string().nullable(true),
		stripeProductId: Yup.string().nullable(true),
		stripeYearlyPriceId: Yup.string().nullable(true),
		taxAmount: Yup.number().nullable(true),
		termMonths: Yup.number().nullable(true),
		yearlyCostToClient: Yup.number().nullable(true),
	});

	const schema = Yup.object().shape({
		addOns: serviceSchema,
		cardName: Yup.string().default("").nullable(),
		isAnnual: Yup.bool().default(false),
		owner: ownerSchema,
		patients: patientsSchema,
		plans: Yup.lazy((obj) => Yup.object().shape(
			mapValues(obj, o => plansSchema),
		).default({})),
	});

	const { formData, isValid, handleUpdate, invalidFields } = useFormHandlers(
		{
			addOns: {},
			cardName: "",
			isAnnual: false,
			owner: {},
			patients: [],
			plans: {},
		},
		schema
	);

	const getDefaultPlan = (patient) => {
		const availablePlans = getAvailablePlans(patient);
		return find(availablePlans, (plan) => plan.clinicPlanDto.minWeightInPounds <= patient.weightInPounds && plan.clinicPlanDto.maxWeightInPounds >= patient.weightInPounds);
	};

	const [currentStep, setCurrentStep] = useState(0);
	const [loading, setIsLoading] = useState(false);
	const [showAddClientForm, setShowAddClientForm] = useState(false);

	const MONTHLY_SUBSCRIPTION_TYPE = 1;
	const YEARLY_SUBSCRIPTION_TYPE = 2;

	const handleBack = () => {
		if (ENROLLMENT_STEPS[currentStep] === SELECT_PLAN) {
			handleUpdate([{
				name: "plans",
				value: {},
			}, {
				name: 'addOns',
				value: {},
			}]);
		} else if (ENROLLMENT_STEPS[currentStep] === SUMMARY_PAYMENT) {
			handleUpdate([{
				name: "isAnnual",
				value: false,
			}, {
				name: 'cardName',
				value: null,
			}, {
				name: 'paymentMethod',
				value: null,
			}]);
		}
		setCurrentStep(currentStep - 1);
	};

	// Review this to accommodate new and existing plan instances. It does not seem to be functioning for newly created plans.
	const getAvailablePlans = (patient) => {
		return filter(plans.plans, (plan) => {
			switch (patient.species) {
				case CANINE:
					return plan.groupName === "Dog" && plan.isActive;
				case FELINE:
					return plan.groupName === "Cat" && plan.isActive;
				case MAMMAL:
					//FIXME: I don't know how to check whether it's a ferret or some other kind of mammal
					return plan.name === "Small Mammal" && plan.isActive;
				case REPTILE:
					return plan.name === "Reptile" && plan.isActive;
				case AVIAN:
					return plan.name === "Avian" && plan.isActive;
			}
		});
	};

	const handleNext = () => {
		setCurrentStep(currentStep + 1);
		if (ENROLLMENT_STEPS[currentStep] === CLIENT_LOOKUP) {
			let newSelectedPlans = {};
			forEach(formData.patients, (patient) => {
				const plan = getDefaultPlan(patient);
				if (!!plan) {
					newSelectedPlans = {
						...newSelectedPlans,
						[patient.patientId || patient.remoteId]: plan,
					};
				}
			});
			handleUpdate({ name: "plans", value: newSelectedPlans });
		}
	};

	// TOUR ONLY
	const [tourSection, setTourSection] = useState(null);
	const getTourSection = () => {
		// Don't set the tourSection if the tour is complete
		let currentSection = null;
		if (includes(completedTourSections, TOUR_PLAN_ENROLLMENT_PART_1) && !includes(completedTourSections, TOUR_PLAN_ENROLLMENT_PART_2)) {
			currentSection = TOUR_PLAN_ENROLLMENT_PART_2;
		}
		setTourSection(currentSection);
	}
	useEffect(() => {
		getTourSection();
	}, [completedTourSections]);

	const handleTourStepComplete = (step, index) => {
		if (step === TOUR_PLAN_ENROLLMENT_PART_2) {
			switch (index) {
				case 0:
					const patient = {
						dob: moment().subtract(3, "years"),
						patientId: 2000,
						patientName: "Duke",
						remoteId: 2001,
						species: "Canine",
						weightInPounds: 50,
					};
					handleUpdate([{
						name: "patients",
						value: [patient],
					}, {
						name: "owner",
						value: {
							city: "Greenline",
							patients: [patient],
							petOwnerEmail: "courtney.overton@greenlinepet.com",
							petOwnerFirstName: "Nick",
							petOwnerId: 1000,
							petOwnerLastName: "Meurrier",
							petOwnerRemoteId: 1001,
							phone: "(615) 392-2452",
							postalCode: 37067,
							stateProvince: "TN",
							street1: "5559 Dogwood Way",
							street2: "",
						}
					}]);
					break;
				case 1:
					handleNext();
					break;
				case 2:
					handleNext();
					// TODO: Select card and plan
					break;
				case 3:
					handleOnClose();
					break;
			}
		}
	};
	// END

	const resetForm = () => {
		handleUpdate([{
			name: "owner",
			value: {},
		}, {
			name: "patients",
			value: [],
		}, {
			name: "isAnnual",
			value: false,
		}, {
			name: "plans",
			value: {},
		}, {
			name: "addOns",
			value: {},
		}, {
			name: "cardName",
			value: "",
		}]);
		setCurrentStep(0);
		setShowAddClientForm(false);
	};

	const handleOnClose = () => {
		resetForm();
		onClose();
	};

	const createSubscription = (customerSelections, newBodies) => {
		WellnessApi.createWellnessSubscription(
			clinicId,
			customerSelections.customerId,
			{ petPlans: newBodies }
		)
			.then((res) => {
				toast.success("Subscriptions created successfully");
				handleOnClose();
			})
			.catch((error) => {
				handleErrorResponse("creating subscription", error);
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const setNewSubscriptionDetails = (stripeCardId, customerSelections) => {
		const newBodies = map(customerSelections.subscriptionPetIdentifiers, (ppglId) => {
				const patient = find(formData.patients, { remoteId: ppglId.remotePatientId });
				const plan = formData.plans[patient.patientId]
					? formData.plans[patient.patientId]
					: formData.plans[patient.remoteId];
				const subscriptionType = formData?.isAnnual
					? YEARLY_SUBSCRIPTION_TYPE
					: MONTHLY_SUBSCRIPTION_TYPE;
				const patientAddOns = filter(
					formData.addOns?.[patient.patiendId]
					? formData.addOns?.[patient.patiendId]
					: formData.addOns?.[patient.remoteId],
					(svc) => svc.stripeMonthlyPriceId || svc.stripeYearlyPriceId
				);

				return {
					planPatientGreenlineBillingPeriodId: subscriptionType,
					customerId: customerSelections.customerId,
					clinicId,
					stripeCardId: stripeCardId,
					planInstanceRequestModel: {
						priceId:
							subscriptionType === YEARLY_SUBSCRIPTION_TYPE
								? plan?.stripeYearlyPriceId
								: plan?.stripeMonthlyPriceId,
						planPatientGreenlineId: ppglId.planPatientGreenlineId,
						clinicPlanInstanceId: plan.clinicPlanInstanceId,
					},
					planInstanceAddOnRequestModels: map(patientAddOns, (addOn) => {
						return {
							priceId:
								subscriptionType === YEARLY_SUBSCRIPTION_TYPE
									? addOn.stripeYearlyPriceId
									: addOn.stripeMonthlyPriceId,
							clinicPlanServiceTypeId: addOn.clinicPlanServiceTypeId,
						};
					}),
				};
			}
		);
		createSubscription(customerSelections, newBodies);
	};

	const createWellnessPaymentOption = (paymentToken, customerSelections) => {
		WellnessApi.createWellnessPaymentOption(
			clinicId,
			paymentToken.id,
			customerSelections.customerId
		)
			.then((res) => {
				setNewSubscriptionDetails(paymentToken.card.id, customerSelections);
			})
			.catch((error) => {
				setIsLoading(false);
				if (error?.response?.body?.Detail) {
					handleErrorResponse("processing payment", {
						message: error.response.body.Detail,
						status: error.status,
					});
				} else handleErrorResponse("processing payment", error);
			});
	};

	const submitCustomerSelection = (paymentToken, createPaymentOption = false) => {
		const petOwner = {
			...formData.owner,
			petOwnerPhoneNumber: formData.owner.phone || null,
			patients: formData.patients,
		};
		WellnessApi.createWellnessCustomerSelection(clinicId, petOwner)
			.then((res) => {
				if (createPaymentOption) {
					createWellnessPaymentOption(paymentToken, res.body);
				} else {
					setNewSubscriptionDetails(paymentToken.id, res.body);
				}
			})
			.catch((error) => {
				handleErrorResponse("selecting customer", error);
				setIsLoading(false);
			});
	};

	const handleSubmitForm = (paymentToken, createPaymentOption = false) => {
		setIsLoading(true);
		submitCustomerSelection(paymentToken, createPaymentOption);
	};

	const stepHeader = (
		<div className={classnames(styles.clientInfo, "margin-bottom-sm text-lg padding-md")}>
			<div>Client Name:</div>
			<div>
				{formData.owner?.petOwnerFirstName} {formData.owner?.petOwnerLastName}
			</div>

			<div>Address:</div>
			<div>
				<div>{formData.owner?.street1}</div>
				{!!formData.owner?.street2 && (
					<div>{formData.owner?.street2}</div>
				)}
				<div>{formData.owner?.city}, {formData.owner?.stateProvince} {formData.owner?.postalCode}</div>
			</div>

			<div>Email:</div>
			<div>{formData.owner?.petOwnerEmail}</div>

			<div>Phone Number:</div>
			<div>{formData.owner?.phone}</div>
		</div>
	);

	const getStep = () => {
		switch (ENROLLMENT_STEPS[currentStep]) {
			case CLIENT_LOOKUP:
				return (
					<ClientPatientForm
						clinicId={clinicId}
						forTour={tourStarted}
						invalidFields={invalidFields}
						namePrefix="owner."
						onNext={handleNext}
						onChange={handleUpdate}
						onSetShowAddClientForm={(value) => setShowAddClientForm(value)}
						owner={formData?.owner}
						patients={formData.patients}
						patientsSchema={patientsSchema}
						schema={ownerSchema}
						showAddClientForm={showAddClientForm}
					/>
				);
			case SELECT_PLAN:
				return (
					<>
						{stepHeader}
						<PlanOptions
							onBack={handleBack}
							onChange={handleUpdate}
							onNext={handleNext}
							allPlans={plans}
							patients={formData.patients}
							plans={formData?.plans}
							addOns={formData?.addOns}
							includeInvalid={false}
							isValid={isValid}
						/>
					</>
				);
			case SUMMARY_PAYMENT:
				return (
					<>
						{stepHeader}
						<PaymentSummary
							onBack={handleBack}
							addOns={formData?.addOns}
							clinicId={clinicId}
							isAnnual={formData?.isAnnual}
							onChange={handleUpdate}
							onSubmit={handleSubmitForm}
							patients={formData.patients}
							petOwnerId={formData.owner?.petOwnerId}
							plans={formData?.plans}
						/>
					</>
				);
			default:
				return null;
		}
	};

	return (
		<>
			<Modal
				show={show}
				small
				modalTitle="Plan Enrollment"
				clickOutsideToClose
				onClose={handleOnClose}
			>
				{getStep()}
			</Modal>
			<SpinnerTakeover show={loading} onTop />
			<JoyRide
				key={tourSection}
				tourSection={tourSection}
				onStepComplete={handleTourStepComplete}
			/>
		</>
	);
}

WellnessEnrollmentModal.propTypes = {
	clinicId: PropTypes.number.isRequired,
	onClose: PropTypes.func.isRequired,
	plans: PropTypes.object,
	show: PropTypes.bool,
};

export default connect(
	(state) => {
		return {
			completedTourSections: state.demo.completedTourSections,
			tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),
		}
	},
)(WellnessEnrollmentModal);
