import React from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import Button from "components/common/Button";
import GameConfigEditorRow from "components/provider/widgets/GameConfigEditorRow";
import logger from "utils/logger";
import styles from "./GameRoiGoalEditor.scss";

const MAX_PRIZES = 5;
const PRIZE_NAMES = ["1st", "2nd", "3rd", "4th", "5th"];

export default function GamePrizeEditor(props) {
    const prizeAmounts = props.config?.PrizeAmounts || [];
    const numberOfPrizes = prizeAmounts.length;
    const totalPWholesalePrice = sumBy(props.reviewData, "pWholesalePrice");

    const handleAddPrizeClicked = () => {
        if (numberOfPrizes < MAX_PRIZES) {
            const updatedPrizes = [...prizeAmounts];

            updatedPrizes.push({
                DisplayName: `${PRIZE_NAMES[numberOfPrizes]} Place`,
                PrizeAmount: null,
            });
            logger.log(updatedPrizes);
            props.onChange({ name: "PrizeAmounts", value: updatedPrizes });
        }
    };

    const handleRemovePrizeClicked = () => {
        if (numberOfPrizes > 1) {
            prizeAmounts.pop();
            props.onChange({ name: "PrizeAmounts", value: prizeAmounts });
        }
    };

    const handleRowChange = (newData, index) => {
        const updatedPrizes = [...prizeAmounts];
        updatedPrizes[index] = { ...newData };

        props.onChange({ name: "PrizeAmounts", value: updatedPrizes });
    };

    const renderTable = () => (
        <div className={styles.tableView}>
            <table className="full-width">
                <thead>
                <tr>
                    <th
                        style={{ borderBottom: "1px solid #E9E9EA" }}
                        className="text-lg"
                        colSpan={3}
                    >
                        Prizes
                    </th>
                </tr>
                <tr>
                    <th/>
                    <th>
                        <span>Name</span>
                        {props.arePrizesOptional && (
                            <>
                                &nbsp;
                                <span className="text-sm">(optional)</span>
                            </>
                        )}
                    </th>
                    <th>
                        <span>Prize Amount</span>
                        &nbsp;
                        <span className="text-sm">($)</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                    {prizeAmounts.length > 0 && (
                        map(prizeAmounts, (prize, index) => (
                            <GameConfigEditorRow
                                key={index}
                                products={props.products}
                                reviewData={props.reviewData}
                                excludeFreeDoses={props.excludeFreeDoses}
                                template={props.template}
                                totalPWholesalePrice={totalPWholesalePrice}
                                data={{ prize: `${PRIZE_NAMES[index]} Place`, ...prize }}
                                onChange={(data) => handleRowChange(data, index)}
                                readOnly={props.readOnly}
                                increasePercentageLocked={props.config.IncreasePercentageLocked}
                                prizeAmountLocked={props.config.PrizeAmountLocked}
                                estROILocked={props.config.EstROILocked}
                                isPrize
                            />
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );

    const renderMobileView = () => (
        <div className={styles.mobileView}>
            <table>
                <tbody>
                    {prizeAmounts.length > 0 && (
                        map(prizeAmounts, (prize, index) => (
                            <GameConfigEditorRow
                                key={index}
                                products={props.products}
                                reviewData={props.reviewData}
                                excludeFreeDoses={props.excludeFreeDoses}
                                template={props.template}
                                totalPWholesalePrice={totalPWholesalePrice}
                                data={{ prize: `${PRIZE_NAMES[index]} Place`, ...prize }}
                                onChange={(data) => handleRowChange(data, index)}
                                readOnly={props.readOnly}
                                increasePercentageLocked={props.config.IncreasePercentageLocked}
                                prizeAmountLocked={props.config.PrizeAmountLocked}
                                estROILocked={props.config.EstROILocked}
                                isPrize
                                isMobileView
                            />
                        ))
                    )}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="full-width flex flex-column">
            {renderTable()}
            {renderMobileView()}

            <div className="flex spaced-content margin-top-sm column-to-row-sm">
                <Button
                    disabled={numberOfPrizes === MAX_PRIZES || props.readOnly}
                    onClick={handleAddPrizeClicked}
                >
                    <i className="fas fa-plus margin-right-x-sm" /> Add Prize
                </Button>
                <Button
                    disabled={numberOfPrizes <= 1 || props.readOnly}
                    onClick={handleRemovePrizeClicked}
                >
                    <i className="fas fa-minus margin-right-x-sm" /> Remove Last Prize
                </Button>
            </div>
        </div>
    );
}

GamePrizeEditor.propTypes = {
    products: PropTypes.arrayOf(PropTypes.object),
    reviewData: PropTypes.arrayOf(PropTypes.object),
    config: PropTypes.object,
    excludeFreeDoses: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    arePrizesOptional: PropTypes.bool,
}
