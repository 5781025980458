import React from "react";
import {
	MAIN_COLOR,
	SECONDARY_COLOR,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./KittyCycling.scss";


export default function KittyCycling(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Cycle_-_Kitty"
			data-name="Cycle - Kitty"
			// xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 800 300"
		>
			<g id="Kitty_Cycling02">
				<g id="Kitty_Cycling02_Lower">
					<path className={styles.cls11}
					      d="M472.7,278.02c0,6.62,50.19,11.98,112.11,11.98,61.92,0,112.11-5.36,112.11-11.98,0-6.62-50.19-11.99-112.11-11.99-61.92,0-112.11,5.37-112.11,11.99Z"/>
					<path className={styles.cls17}
					      d="M549.61,178.53c7,11.73,22.67,50.04,13.98,69.57-4.03,9.06,4.78,16.01,11.14,12.86,6.36-3.14-4.04-8.81,1.27-15.51,18.08-22.82,16.53-56.01,10.49-71.71-8.82-22.97-43.98-7.12-36.88,4.79Z"/>
					<path className={styles.cls17}
					      d="M638.26,116.11c10.82,11.88-4.14,36.83,17.85,39.81,7.57,1.03,7.94,14.85-9.54,12.48-24.57-3.33-29.18-19.74-27.42-42.61.73-9.45,15.12-14.07,19.12-9.68Z"/>
					<g>
						<path className={styles.cls7}
						      d="M516.3,277.95c-22.76,1.24-42.3-16.26-43.54-39.03-1.24-22.76,16.26-42.3,39.03-43.54s42.3,16.26,43.54,39.03c1.24,22.76-16.26,42.3-39.03,43.54ZM512.05,200.24c-20.09,1.1-35.53,18.33-34.44,38.42,1.1,20.09,18.33,35.53,38.42,34.44,20.09-1.1,35.53-18.33,34.44-38.42-1.1-20.09-18.33-35.53-38.42-34.44Z"/>
						<path className={styles.cls7}
						      d="M660.95,277.36c-22.76,1.24-42.3-16.26-43.54-39.03-1.24-22.76,16.26-42.3,39.03-43.54,22.76-1.24,42.3,16.26,43.54,39.03,1.24,22.76-16.26,42.3-39.03,43.54ZM656.7,199.64c-20.09,1.1-35.53,18.33-34.44,38.42,1.1,20.09,18.33,35.53,38.42,34.44,20.09-1.1,35.53-18.33,34.44-38.42s-18.33-35.53-38.42-34.44Z"/>
						<circle className={styles.cls3} cx="658.7" cy="236.07" r="33.43"/>
						<circle className={styles.cls3} cx="514.04" cy="236.67" r="33.43"/>
						<g>
							<path className={styles.cls9}
							      d="M574.29,242.84c.3-.02.61.04.9.18.89.44,1.26,1.52.82,2.42l-7.8,15.8-3.24-1.6,7.8-15.8c.3-.61.89-.97,1.52-1Z"/>
							<rect className={styles.cls9} x="563.51" y="256.94" width="4.06" height="11.06" rx=".95" ry=".95"
							      transform="translate(78.06 651.18) rotate(-63.46)"/>
						</g>
						<path className={styles.cls2}
						      d="M632.34,179.32l.08,1.41-62.53,9.82c-.5.08-.96.28-1.36.59l-57.05,45.13c-.92.72-1.31,1.93-1.01,3.05s1.25,1.96,2.41,2.13l60.08,8.42c.19.03.38.03.56.02.66-.04,1.3-.3,1.8-.75l57.96-52.54.1,1.88,9.23-.5-1.05-19.16-9.23.5ZM568.22,198.83l2.62,44.86-50.39-7.07,47.78-37.79ZM576.48,240.21l-2.59-44.39,58.84-9.24.14,2.51-56.39,51.12Z"/>
						<path className={styles.cls2}
						      d="M657.8,237.62c-.77.04-1.55-.17-2.22-.67-9.54-7.11-19-25.74-20.93-36.4l7.59-.53c1.66,9.23,9.57,25.63,17.41,31.47,1.51,1.12,1.82,3.26.69,4.77-.63.84-1.57,1.31-2.55,1.36Z"/>
						<polygon className={styles.cls7}
						         points="555.99 191.9 585.6 184.57 585.27 178.4 555.34 180.03 555.99 191.9"/>
						<circle className={styles.cls5} cx="573.9" cy="245.68" r="11.66"/>
						<g>
							<path className={styles.cls9}
							      d="M574.02,247.53c-.3.02-.61-.04-.9-.18-.89-.44-1.26-1.52-.82-2.42l7.8-15.8,3.24,1.6-7.8,15.8c-.3.61-.89.97-1.52,1Z"/>
							<rect className={styles.cls9} x="580.73" y="222.38" width="4.06" height="11.06" rx=".95" ry=".95"
							      transform="translate(118.52 647.46) rotate(-63.46)"/>
						</g>
						<path
							className={styles.cls9}
				            d="M633.16,177.18l-.94-17.12,28.11-1.54.52,2.16c1.93,7.95.04,12.04-1.88,14.08-1.63,1.73-4.77,3.57-10.48,2.53-1.45-.26-2.42-1.66-2.15-3.11.26-1.45,1.66-2.42,3.11-2.15,1.85.34,4.33.45,5.63-.94,1.16-1.23,1.51-3.72,1.01-6.98l-16.01.88.64,11.78-7.56.41Z"
						/>
					</g>
					<g>
						<path
							className={styles.cls13}
					        d="M513.13,142.32c2.92,6.12,13.69,10.64,19.72,13.25,3.48,1.51,8.26,3.76,12.29,3.38,5.4-.51,6.16-4.71,1.23-7.61-3.14-1.85-6.49-1.74-9.7-3.88-3.58-2.39-7.03-4.65-10.36-7.38-2.76-2.27-5.87-5.4-9.7-4.46-3.04.75-4.92,3.67-3.47,6.71Z"
						/>
						<path
							className={styles.cls12}
					        d="M513.13,142.32c-1.45-3.03.43-5.96,3.47-6.71,3.83-.94,6.95,2.19,9.7,4.46.04.03.08.06.11.09-2.22,1.37-4.2,3.18-5.33,5.4-.52,1.03-.83,2.28-1.16,3.54-3.02-1.97-5.59-4.23-6.8-6.78Z"
						/>
					</g>
					<path
						className={styles.cls6}
						d="M548.76,177.32c16.49,29.83,49.21-1.97,57-15.24,2.92-4.97,29.09-14.72,36.04-26.54,6.67-11.36-13.85-32.69-17.3-34-17.27-6.54-41.9,3.16-58.3,16.81-13.46,11.21-30.37,35.58-17.44,58.96Z"
					/>
					<path className={styles.cls6}
					      d="M613.43,126.65c-.01,10.8,1.23,32.08,14.82,39.34,19.71,10.52,33.15,4.18,33.34-.77.45-11.32-7.23.4-17.17-11.28-5.43-6.38-4.26-14.6-7.02-25.31-2.29-8.9-23.98-6.09-23.98-1.98Z"/>
					<path className={styles.cls13}
					      d="M545.56,149.13c1.21-4.11,2.86-8.5,5.25-12.41,5.56-9.1,9.58-13.83,16.59-19.63,3.72-3.08,6.99-6.05,11.75-8.49,3.6,1.53,8.52,4.66,10.32,5.39,5.71,2.33,17.02,8.57,12.97,16.68-2.83,5.66-11.48,10.61-18.13,11.86-3.34.63-6.6,2.13-9.46,3.83-3.11,1.85-8.16,8.17-11.22,10.14-6.06,3.9-14.33,0-18.06-7.37Z"/>
					<path className={styles.cls17}
					      d="M578.28,157.51c6.83-1.94,22.08,4.98,24.86,9.37-1.43,1.88-3.24,3.01-6.39,4.74-10.09-2.75-25.18-12.21-18.47-14.12Z"/>
				</g>
				<path
					id="Shirt"
					className={styles.cls2}
					d="M624.54,93.55c17.13,1.26,21.25,26.22,19.64,36.18-.53,3.28-2.26,5.87-3.8,7.87-8.18-19.02-33.75-1.42-23.71,16.98-4.97,3.58-9.31,8.56-13.53,12.3-16.95-7.14-31.21-27.07-35.75-49.79,10.37-9.18,37.74-24.96,57.15-23.54Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes">
					<path
						className={styles.cls18}
						d="M578.04,124.81c2.14,5.95,4.89,11.55,8.18,16.63,5.3,8.18,15.26,15.27,22.74,19.96,1.09-1.1,2.36-2.28,2.96-2.82-7.18-4.41-17.34-11.4-22.43-19.26-5.6-8.64-9.57-18.91-11.52-29.8-.56.29-2.83,1.64-3.55,2.17.88,4.5,2.1,8.9,3.62,13.12Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls18}
						d="M615.85,155.2l1.36-.91c-2.94-4.36-3.18-9.95-.31-15.2,3.05-5.57,8.8-9.33,13.67-8.96,3.99.31,7.1,3.23,9,8.46,0,0,1.06-1.21,1.38-1.82-2.78-6.63-7.06-8.34-10.23-8.58-5.69-.44-12.07,3.66-15.53,9.97-3.26,5.95-3.02,12.17.66,17.05Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Cycling02_Mid">
					<path className={styles.cls6}
					      d="M574.39,157.37c14.25-.78,28.53,12.9,32.71,23.84,5.67,14.86-7.57,25.5-20.23,31.5,13.71,5.03,11.46,16.72,1.27,15.89-1.23-.1-13.74-6.54-15.92-8.55-4.09-3.31-2.18-3.6-1.25-10.1,3.28-22.93-11.78-14.15-19.21-29.68-5.42-11.33,13.78-22.43,22.63-22.91Z"/>
					<g>
						<g>
							<path className={styles.cls13}
							      d="M723.79,94.21c-9.9.56-20.55,3.11-27.34,9.82-.33.33.26.84.59.51,6.65-6.57,17.11-9.06,26.81-9.61.49-.03.44-.75-.06-.72Z"/>
							<path className={styles.cls13}
							      d="M726.9,107.23c-10.06-.88-19.9-.32-29.3,2.49-.46.14-.2.84.26.7,9.33-2.79,19.1-3.34,29.1-2.47.5.04.44-.68-.06-.72Z"/>
							<path className={styles.cls13}
							      d="M725.16,116.42c-8.59-2.38-17.39-2.85-26.12-2.85-.5,0-.44.72.06.72,8.66,0,17.39.46,25.91,2.82.49.13.64-.56.15-.7Z"/>
						</g>
						<path className={styles.cls4}
						      d="M649.3,58.73c.11,6.91.29,13.34.42,14.45.27,2.32.06,2.34,1.57,4.2,1.98,2.44,31.77,2.79,35.97-3.06-4.57-7.81-14.54-19.12-20.77-23.94-4.47-3.45-11.48-9.1-16.98-10.38-.37-.08-.35,9.73-.21,18.73Z"/>
						<g>
							<path className={styles.cls6}
							      d="M628.91,97.78c36.34-18.78,19.76-28.64,10.76-32.06,3.14-.94,6.35-1.66,9.54-2.16,5.06,3.8,20.89,14.02,42.71,12.26,3.62,5.14,5.79,11.45,8.04,17.36,2.57,6.75,7.89,8.29,5.36,21.47-2.93,15.22-14.17,17.71-21.72,20.47-8.97,3.28-19.95,4.24-29.56,4-6.4-.16-17.83-2.76-23.46-6.14-11.63-6.96-16.48-18.65-18.92-28.71-.12-.49-.22-.98-.32-1.46,8.94-1.44,17.58-5.02,17.58-5.02Z"/>
							<path className={styles.cls12}
							      d="M620.63,73.08c3.44-4.23,8.55-9.18,19.04-7.36,9,3.42,25.59,13.28-10.76,32.06,0,0-8.64,3.59-17.58,5.02-2.4-12.23,2.55-21.44,9.29-29.73Z"/>
							<path className={styles.cls13}
							      d="M649.21,63.56c8.1-1.26,16-1.08,21.92.59,6.66,1.87,15.75,5.04,20.09,10.73.24.31.47.63.7.95-21.82,1.76-37.64-8.46-42.71-12.26Z"/>
						</g>
						<path className={styles.cls8}
						      d="M667.56,94.68c2.01,5.92-2.03,12.1-9.04,13.79-7.01,1.7-14.33-1.73-16.34-7.65-2.02-5.92,2.03-12.09,9.05-13.79,7.01-1.7,14.33,1.73,16.34,7.65Z"/>
						<path className={styles.cls8}
						      d="M693.87,87.88c1.96,4.6.89,8.97-2.41,9.76-3.29.79-7.56-2.31-9.52-6.91-1.96-4.6-.89-8.97,2.41-9.76,3.29-.79,7.55,2.31,9.52,6.91Z"/>
						<g>
							<path className={styles.cls13}
							      d="M641.03,117.98c8.99-3.78,19.57-6.24,28.7-3.66.45.13.17.8-.28.67-8.94-2.53-19.33-.09-28.14,3.61-.45.19-.74-.43-.29-.62Z"/>
							<path className={styles.cls13}
							      d="M644.39,129.95c8.46-5.02,17.4-8.77,27-10.49.47-.08.57.6.1.68-9.54,1.71-18.41,5.44-26.81,10.42-.42.25-.7-.37-.29-.62Z"/>
							<path className={styles.cls13}
							      d="M650.23,136.71c6.46-5.61,13.99-9.76,21.69-13.49.44-.21.73.4.29.62-7.64,3.7-15.11,7.81-21.52,13.38-.37.32-.83-.19-.46-.51Z"/>
						</g>
						<g>
							<path className={styles.cls16}
							      d="M664.96,95.04c1.85,5.31.59,10.6-2.81,11.83-3.4,1.23-7.66-2.08-9.5-7.38-1.85-5.3-.59-10.6,2.81-11.83,3.4-1.23,7.66,2.08,9.51,7.38Z"/>
							<path className={styles.cls15}
							      d="M657.28,95.25c.93,2.66,3.03,4.32,4.71,3.72,1.68-.6,2.28-3.25,1.35-5.91-.93-2.66-3.03-4.33-4.71-3.72-1.67.6-2.28,3.25-1.35,5.91Z"/>
						</g>
						<g>
							<path className={styles.cls16}
							      d="M692.72,88.16c1.5,4.45,1.04,8.68-1.02,9.45-2.06.77-4.95-2.21-6.44-6.66-1.5-4.45-1.04-8.68,1.02-9.45,2.06-.77,4.95,2.21,6.45,6.65Z"/>
							<path className={styles.cls15}
							      d="M687.9,87.81c.75,2.23,2.18,3.73,3.19,3.35,1.02-.38,1.23-2.5.48-4.73-.75-2.23-2.18-3.73-3.2-3.35-1.01.38-1.23,2.5-.47,4.72Z"/>
						</g>
						<path className={styles.cls13}
						      d="M686.26,117.07c.25.4.46.86.54.99.51.82,1.15,1.61,2.03,2.04,1.72.85,4.24.65,5.85-.45,1.92-1.32,2.41-3.16,2.18-5.38-.11-1.1-.09-2.43-.5-3.46-.09-.23-.32-.5-.36-.73-.06-.3.43-.95.77-.92.69.05.8,1.01.87,1.53.11.82.28,1.53.49,2.34.38,1.46.79,3.34,2.16,4.22,1,.65,2.28.55,2.93-.51.33-.54.34-1.14.31-1.76,0-.17-.12-1.35.27-1.36.65-.01.48,1.39.47,1.77-.05,1.6-1,3.78-2.94,3.45-.8-.13-1.51-.65-2.07-1.21-.52-.52-1.24-1.09-1.32-1.87-.1,2-1.01,3.58-2.68,4.72-.74.5-1.61.85-2.53,1.02-2.16.41-4.19-.12-5.76-1.64-.44-.42-2.59-2.99-1.5-3.46.24-.1.54.27.79.69Z"/>
						<path className={styles.cls10}
						      d="M697.47,102.23c-.83.2-1.63.62-2.53.93-1.43.51-3.79.71-4.88,1.59-2.61,2.13.85,4.36,3.56,5.12,3.06.86,8.73-.18,8.41-4.45-.11-1.45-.96-2.52-2.29-3.06-.82-.33-1.55-.31-2.27-.14Z"/>
					</g>
					<path className={styles.cls1}
					      d="M630.04,96.13c4.73,8.63,17.52,13.38,30.54,13.13,10.75-.21,32.33-7.78,40.7-14.39-2.07-4.9-4.33-11.18-7.22-16.07-16.25-8.61-57.63,11.36-64.02,17.33Z"/>
				</g>
				<path
					id="Helmet"
					className={styles.cls2}
					d="M608.77,84.83c-1.17,5.9.87,26.43,5.85,22.3,35.66-29.56,72.63-32.34,80.22-26.94-3.81-11.54-16.5-32.5-49.37-26.37-23.93,4.46-34.82,21.51-36.7,31.01Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<path
					id="Stripe"
					className={styles.cls18}
					d="M663.99,81.02c2.98-.75,8.45-2.03,11.25-2.39-2.55-11.63-18.53-23.98-26.63-25.36-4.07.53-9.5,1.75-12.24,3.04,9.01,2.44,26.18,18.17,27.62,24.72Z"
					style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
				/>
				<g id="Kitty_Cycling02_Upper">
					<path className={styles.cls12}
					      d="M602.62,83.48c4.44,5.61,8.64,10.78,9.44,11.61,1.67,1.73,1.53,1.88,3.88,2.45,3.1.75,21.16-21.15,20.76-28.64-6.8-3.81-17.96-3.25-25.88-3.24-5.67,0-14.73-.18-19.85,2.28-.34.16,5.86,8.22,11.65,15.53Z"/>
					<path className={styles.cls10} d="M629.14,75.51s-17.01-5.01-30.59-3.41l17.08,20.28,13.5-16.87Z"/>
				</g>
			</g>
			<g id="Kitty_Cycling01">
				<g id="Kitty_Cycling01_Lower">
					<path className={styles.cls11}
					      d="M72.7,278.02c0,6.62,50.19,11.98,112.11,11.98,61.92,0,112.11-5.36,112.11-11.98,0-6.62-50.19-11.99-112.11-11.99-61.92,0-112.11,5.37-112.11,11.99Z"/>
					<path className={styles.cls17}
					      d="M177.17,157.9c14.63-.8,29.28,13.24,33.58,24.48,5.82,15.25-8.32,25.33-21.31,31.49,9.04,4.18,8.61,15.61.56,16.35-1.26.12-10.2-7.04-12.44-9.09-4.2-3.39-2.24-3.69-1.29-10.36,3.36-23.53-14.69-13.4-22.33-29.34-5.57-11.62,14.14-23.02,23.23-23.52Z"/>
					<path className={styles.cls17}
					      d="M238.26,116.11c10.82,11.88-4.14,36.83,17.85,39.81,7.57,1.03,7.94,14.85-9.54,12.48-24.57-3.33-29.18-19.74-27.42-42.61.73-9.45,15.12-14.07,19.12-9.68Z"/>
					<g>
						<path className={styles.cls7}
						      d="M116.3,277.95c-22.76,1.24-42.3-16.26-43.54-39.03-1.24-22.76,16.26-42.3,39.03-43.54,22.76-1.24,42.3,16.26,43.54,39.03,1.24,22.76-16.26,42.3-39.03,43.54ZM112.05,200.24c-20.09,1.1-35.53,18.33-34.44,38.42,1.1,20.09,18.33,35.53,38.42,34.44,20.09-1.1,35.53-18.33,34.44-38.42-1.1-20.09-18.33-35.53-38.42-34.44Z"/>
						<path className={styles.cls7}
						      d="M260.95,277.36c-22.76,1.24-42.3-16.26-43.54-39.03-1.24-22.76,16.26-42.3,39.03-43.54,22.76-1.24,42.3,16.26,43.54,39.03,1.24,22.76-16.26,42.3-39.03,43.54ZM256.7,199.64c-20.09,1.1-35.53,18.33-34.44,38.42,1.1,20.09,18.33,35.53,38.42,34.44,20.09-1.1,35.53-18.33,34.44-38.42s-18.33-35.53-38.42-34.44Z"/>
						<circle className={styles.cls3} cx="258.7" cy="236.07" r="33.43"/>
						<circle className={styles.cls3} cx="114.04" cy="236.67" r="33.43"/>
						<g>
							<path
								className={styles.cls9}
								d="M173.74,247.5c-.3-.02-.6-.11-.87-.29-.84-.54-1.07-1.66-.53-2.5l9.6-14.77,3.03,1.97-9.6,14.77c-.37.57-1,.86-1.63.82Z"
							/>
							<rect
								className={styles.cls9}
								x="182.7"
								y="223.51"
								width="4.06"
								height="11.06"
								rx=".95"
								ry=".95"
								transform="translate(-108.11 257.79) rotate(-56.72)"
							/>
						</g>
						<path
							className={styles.cls2}
							d="M232.34,179.32l.08,1.41-62.53,9.82c-.5.08-.96.28-1.36.59l-57.05,45.13c-.92.72-1.31,1.93-1.01,3.05s1.25,1.96,2.41,2.13l60.08,8.42c.19.03.38.03.56.02.66-.04,1.3-.3,1.8-.75l57.96-52.54.1,1.88,9.23-.5-1.05-19.16-9.23.5ZM168.22,198.83l2.62,44.86-50.39-7.07,47.78-37.79ZM176.48,240.21l-2.59-44.39,58.84-9.24.14,2.51-56.39,51.12Z"
						/>
						<path
							className={styles.cls2}
							d="M257.8,237.62c-.77.04-1.55-.17-2.22-.67-9.54-7.11-19-25.74-20.93-36.4l7.59-.53c1.66,9.23,9.57,25.63,17.41,31.47,1.51,1.12,1.82,3.26.69,4.77-.63.84-1.57,1.31-2.55,1.36Z"
						/>
						<polygon
							className={styles.cls7}
							points="155.99 191.9 185.6 184.57 185.27 178.4 155.34 180.03 155.99 191.9"
						/>
						<circle
							className={styles.cls5}
							cx="173.9"
							cy="245.68"
							r="11.66"
						/>
						<path className={styles.cls9}
						      d="M233.16,177.18l-.94-17.12,28.11-1.54.52,2.16c1.93,7.95.04,12.04-1.88,14.08-1.63,1.73-4.77,3.57-10.48,2.53-1.45-.26-2.42-1.66-2.15-3.11.26-1.45,1.66-2.42,3.11-2.15,1.85.34,4.33.45,5.63-.94,1.16-1.23,1.51-3.72,1.01-6.98l-16.01.88.64,11.78-7.56.41Z"/>
						<g>
							<path className={styles.cls9}
							      d="M174.56,242.88c.3.02.6.11.87.29.84.54,1.07,1.66.53,2.5l-9.6,14.77-3.03-1.97,9.6-14.77c.37-.57,1-.86,1.63-.82Z"/>
							<rect className={styles.cls9} x="161.54" y="255.81" width="4.06" height="11.06" rx=".95" ry=".95"
							      transform="translate(-144.66 254.68) rotate(-56.72)"/>
						</g>
					</g>
					<g>
						<path className={styles.cls13}
						      d="M113.13,142.32c2.92,6.12,13.69,10.64,19.72,13.25,3.48,1.51,8.26,3.76,12.29,3.38,5.4-.51,6.16-4.71,1.23-7.61-3.14-1.85-6.49-1.74-9.7-3.88-3.58-2.39-7.03-4.65-10.36-7.38-2.76-2.27-5.87-5.4-9.7-4.46-3.04.75-4.92,3.67-3.47,6.71Z"/>
						<path className={styles.cls12}
						      d="M113.13,142.32c-1.45-3.03.43-5.96,3.47-6.71,3.83-.94,6.95,2.19,9.7,4.46.04.03.08.06.11.09-2.22,1.37-4.2,3.18-5.33,5.4-.52,1.03-.83,2.28-1.16,3.54-3.02-1.97-5.59-4.23-6.8-6.78Z"/>
					</g>
					<path className={styles.cls6}
					      d="M148.76,177.32c16.49,29.83,49.21-1.97,57-15.24,2.92-4.97,29.09-14.72,36.04-26.54,6.67-11.36-13.85-32.69-17.3-34-17.27-6.54-41.9,3.16-58.3,16.81-13.46,11.21-30.37,35.58-17.44,58.96Z"/>
					<path className={styles.cls17}
					      d="M179.43,164.3c5.76,1.89,12.06,9.51,11.95,14.01-1.6,1.49-3.13,2.76-4.64,3.1-5.85-6.83-12.97-18.97-7.31-17.11Z"/>
					<path className={styles.cls6}
					      d="M213.43,126.65c-.01,10.8,1.23,32.08,14.82,39.34,19.71,10.52,33.15,4.18,33.34-.77.45-11.32-7.23.4-17.17-11.28-5.43-6.38-4.26-14.6-7.02-25.31-2.29-8.9-23.98-6.09-23.98-1.98Z"/>
					<path className={styles.cls6}
					      d="M148.65,178.32c7.18,12.04,19.88,49.63,9.39,68.9-2.99,5.5,7.31,15.37,10.16,13.74,9.16-5.25,4.42-11.07,2.42-15.03,30.78-29.36,22.09-56.4,15.89-72.53-9.05-23.58-45.15-7.3-37.86,4.92Z"/>
					<path className={styles.cls13}
					      d="M145.56,149.13c1.21-4.11,2.86-8.5,5.25-12.41,5.56-9.1,9.58-13.83,16.59-19.63,3.72-3.08,6.99-6.05,11.75-8.49,3.6,1.53,8.52,4.66,10.32,5.39,5.71,2.33,17.02,8.57,12.97,16.68-2.83,5.66-11.48,10.61-18.13,11.86-3.34.63-6.6,2.13-9.46,3.83-3.11,1.85-8.16,8.17-11.22,10.14-6.06,3.9-14.33,0-18.06-7.37Z"/>
				</g>
				<path
					id="Shirt-2"
					data-name="Shirt"
					className={styles.cls2}
					d="M224.54,93.55c17.13,1.26,21.25,26.22,19.64,36.18-.53,3.28-2.26,5.87-3.8,7.87-8.18-19.02-33.75-1.42-23.71,16.98-4.97,3.58-9.31,8.56-13.53,12.3-16.95-7.14-31.21-27.07-35.75-49.79,10.37-9.18,37.74-24.96,57.15-23.54Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-2" data-name="Stripes">
					<path
						className={styles.cls18}
						d="M178.04,124.81c2.14,5.95,4.89,11.55,8.18,16.63,5.3,8.18,15.26,15.27,22.74,19.96,1.09-1.1,2.36-2.28,2.96-2.82-7.18-4.41-17.34-11.4-22.43-19.26-5.6-8.64-9.57-18.91-11.52-29.8-.56.29-2.83,1.64-3.55,2.17.88,4.5,2.1,8.9,3.62,13.12Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls18}
						d="M215.85,155.2l1.36-.91c-2.94-4.36-3.18-9.95-.31-15.2,3.05-5.57,8.8-9.33,13.67-8.96,3.99.31,7.1,3.23,9,8.46,0,0,1.06-1.21,1.38-1.82-2.78-6.63-7.06-8.34-10.23-8.58-5.69-.44-12.07,3.66-15.53,9.97-3.26,5.95-3.02,12.17.66,17.05Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Cycling01_Mid">
					<g>
						<g>
							<path className={styles.cls13}
							      d="M323.79,94.21c-9.9.56-20.55,3.11-27.34,9.82-.33.33.26.84.59.51,6.65-6.57,17.11-9.06,26.81-9.61.49-.03.44-.75-.06-.72Z"/>
							<path className={styles.cls13}
							      d="M326.9,107.23c-10.06-.88-19.9-.32-29.3,2.49-.46.14-.2.84.26.7,9.33-2.79,19.1-3.34,29.1-2.47.5.04.44-.68-.06-.72Z"/>
							<path className={styles.cls13}
							      d="M325.16,116.42c-8.59-2.38-17.39-2.85-26.12-2.85-.5,0-.44.72.06.72,8.66,0,17.39.46,25.91,2.82.49.13.64-.56.15-.7Z"/>
						</g>
						<path className={styles.cls4}
						      d="M249.3,58.73c.11,6.91.29,13.34.42,14.45.27,2.32.06,2.34,1.57,4.2,1.98,2.44,31.77,2.79,35.97-3.06-4.57-7.81-14.54-19.12-20.77-23.94-4.47-3.45-11.48-9.1-16.98-10.38-.37-.08-.35,9.73-.21,18.73Z"/>
						<g>
							<path className={styles.cls6}
							      d="M228.91,97.78c36.34-18.78,19.76-28.64,10.76-32.06,3.14-.94,6.35-1.66,9.54-2.16,5.06,3.8,20.89,14.02,42.71,12.26,3.62,5.14,5.79,11.45,8.04,17.36,2.57,6.75,7.89,8.29,5.36,21.47-2.93,15.22-14.17,17.71-21.72,20.47-8.97,3.28-19.95,4.24-29.56,4-6.4-.16-17.83-2.76-23.46-6.14-11.63-6.96-16.48-18.65-18.92-28.71-.12-.49-.22-.98-.32-1.46,8.94-1.44,17.58-5.02,17.58-5.02Z"/>
							<path className={styles.cls12}
							      d="M220.63,73.08c3.44-4.23,8.55-9.18,19.04-7.36,9,3.42,25.59,13.28-10.76,32.06,0,0-8.64,3.59-17.58,5.02-2.4-12.23,2.55-21.44,9.29-29.73Z"/>
							<path className={styles.cls13}
							      d="M249.21,63.56c8.1-1.26,16-1.08,21.92.59,6.66,1.87,15.75,5.04,20.09,10.73.24.31.47.63.7.95-21.82,1.76-37.64-8.46-42.71-12.26Z"/>
						</g>
						<path className={styles.cls8}
						      d="M267.56,94.68c2.01,5.92-2.03,12.1-9.04,13.79-7.01,1.7-14.33-1.73-16.34-7.65-2.02-5.92,2.03-12.09,9.05-13.79,7.01-1.7,14.33,1.73,16.34,7.65Z"/>
						<path className={styles.cls8}
						      d="M293.87,87.88c1.96,4.6.89,8.97-2.41,9.76-3.29.79-7.56-2.31-9.52-6.91-1.96-4.6-.89-8.97,2.41-9.76,3.29-.79,7.55,2.31,9.52,6.91Z"/>
						<g>
							<path className={styles.cls13}
							      d="M241.03,117.98c8.99-3.78,19.57-6.24,28.7-3.66.45.13.17.8-.28.67-8.94-2.53-19.33-.09-28.14,3.61-.45.19-.74-.43-.29-.62Z"/>
							<path className={styles.cls13}
							      d="M244.39,129.95c8.46-5.02,17.4-8.77,27-10.49.47-.08.57.6.1.68-9.54,1.71-18.41,5.44-26.81,10.42-.42.25-.7-.37-.29-.62Z"/>
							<path className={styles.cls13}
							      d="M250.23,136.71c6.46-5.61,13.99-9.76,21.69-13.49.44-.21.73.4.29.62-7.64,3.7-15.11,7.81-21.52,13.38-.37.32-.83-.19-.46-.51Z"/>
						</g>
						<g>
							<path className={styles.cls16}
							      d="M264.96,95.04c1.85,5.31.59,10.6-2.81,11.83-3.4,1.23-7.66-2.08-9.5-7.38-1.85-5.3-.59-10.6,2.81-11.83,3.4-1.23,7.66,2.08,9.51,7.38Z"/>
							<path className={styles.cls15}
							      d="M257.28,95.25c.93,2.66,3.03,4.32,4.71,3.72,1.68-.6,2.28-3.25,1.35-5.91-.93-2.66-3.03-4.33-4.71-3.72-1.67.6-2.28,3.25-1.35,5.91Z"/>
						</g>
						<g>
							<path className={styles.cls16}
							      d="M292.72,88.16c1.5,4.45,1.04,8.68-1.02,9.45-2.06.77-4.95-2.21-6.44-6.66-1.5-4.45-1.04-8.68,1.02-9.45,2.06-.77,4.95,2.21,6.45,6.65Z"/>
							<path className={styles.cls15}
							      d="M287.9,87.81c.75,2.23,2.18,3.73,3.19,3.35,1.02-.38,1.23-2.5.48-4.73-.75-2.23-2.18-3.73-3.2-3.35-1.01.38-1.23,2.5-.47,4.72Z"/>
						</g>
						<path className={styles.cls13}
						      d="M286.26,117.07c.25.4.46.86.54.99.51.82,1.15,1.61,2.03,2.04,1.72.85,4.24.65,5.85-.45,1.92-1.32,2.41-3.16,2.18-5.38-.11-1.1-.09-2.43-.5-3.46-.09-.23-.32-.5-.36-.73-.06-.3.43-.95.77-.92.69.05.8,1.01.87,1.53.11.82.28,1.53.49,2.34.38,1.46.79,3.34,2.16,4.22,1,.65,2.28.55,2.93-.51.33-.54.34-1.14.31-1.76,0-.17-.12-1.35.27-1.36.65-.01.48,1.39.47,1.77-.05,1.6-1,3.78-2.94,3.45-.8-.13-1.51-.65-2.07-1.21-.52-.52-1.24-1.09-1.32-1.87-.1,2-1.01,3.58-2.68,4.72-.74.5-1.61.85-2.53,1.02-2.16.41-4.19-.12-5.76-1.64-.44-.42-2.59-2.99-1.5-3.46.24-.1.54.27.79.69Z"/>
						<path className={styles.cls10}
						      d="M297.47,102.23c-.83.2-1.63.62-2.53.93-1.43.51-3.79.71-4.88,1.59-2.61,2.13.85,4.36,3.56,5.12,3.06.86,8.73-.18,8.41-4.45-.11-1.45-.96-2.52-2.29-3.06-.82-.33-1.55-.31-2.27-.14Z"/>
					</g>
					<path className={styles.cls1}
					      d="M230.04,96.13c4.73,8.63,17.52,13.38,30.54,13.13,10.75-.21,32.33-7.78,40.7-14.39-2.07-4.9-4.33-11.18-7.22-16.07-16.25-8.61-57.63,11.36-64.02,17.33Z"/>
				</g>
				<path
					id="Helmet-2"
					data-name="Helmet"
					className={styles.cls2}
					d="M208.77,84.83c-1.17,5.9.87,26.43,5.85,22.3,35.66-29.56,72.63-32.34,80.22-26.94-3.81-11.54-16.5-32.5-49.37-26.37-23.93,4.46-34.82,21.51-36.7,31.01Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<path
					id="Stripe-2"
					data-name="Stripe"
					className={styles.cls14}
					d="M263.99,81.02c2.98-.75,8.45-2.03,11.25-2.39-2.55-11.63-18.53-23.98-26.63-25.36-4.07.53-9.5,1.75-12.24,3.04,9.01,2.44,26.18,18.17,27.62,24.72Z"
					style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
				/>
				<g id="Kitty_Cycling01_Upper">
					<path className={styles.cls12}
					      d="M202.62,83.48c4.44,5.61,8.64,10.78,9.44,11.61,1.67,1.73,1.53,1.88,3.88,2.45,3.1.75,21.16-21.15,20.76-28.64-6.8-3.81-17.96-3.25-25.88-3.24-5.67,0-14.73-.18-19.85,2.28-.34.16,5.86,8.22,11.65,15.53Z"/>
					<path className={styles.cls10} d="M229.14,75.51s-17.01-5.01-30.59-3.41l17.08,20.28,13.5-16.87Z"/>
				</g>
			</g>
		</svg>
	);
};