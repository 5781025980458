import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import round from "lodash/round";
import styles from "./PuppyLeafPartyClinicScore.scss";

const PUPPY_MOVING_ANIMATION_DURATION = 4000; // Make sure this matches up with animation duration in css file
const PUPPY_MOVING_ANIMATION_CYCLE_SPEED = 250; // How fast the animation changes frames
const PUPPY_SLEEPING_ANIMATION_CYCLE_SPEED = 750 // How fast the animation changes frames
const PUPPY_SLEEPING_ANIMATION_DURATION = 5000; // Make sure this matches up with animation duration in css file
const PUPPY_WINNING_ANIMATION_DURATION = 5500; // Make sure this matches up with animation duration in css file
const PUPPY_WINNING_ANIMATION_CYCLE_SPEED = 500; // How fast the animation changes frames

const MOVING = "moving";
const SLEEPING = "sleeping";
const WINNING = "winning";
const SLEEPING_END = "sleeping_end";

export default function PuppyLeafPartyClinicScore(props) {
    const [animationStep, setAnimationStep] = useState(null);
    const [animationFrame, setAnimationFrame] = useState(0);
    const [animated, setAnimated] = useState(false);
    const [bonus, setBonus] = useState("");
    const [showRestart, setShowRestart] = useState(false);

    const POINTS_WALKTHROUGH_DETAILS = [
        {
            scoreType: "bonus",
            label: "Bonus"
        }
    ];

    const playPuppyWinningAnimationCycle = (durationCounter=0, animationFrame=0, isLastWinnerCycle) => {
        setTimeout(() => {
            setAnimationFrame(animationFrame);

            if (durationCounter < PUPPY_WINNING_ANIMATION_DURATION) {
                const nextAnimationFrame = animationFrame === 0 ? 1 : 0;
                playPuppyWinningAnimationCycle(durationCounter + PUPPY_WINNING_ANIMATION_CYCLE_SPEED, nextAnimationFrame, isLastWinnerCycle);
            } else {
                if (isLastWinnerCycle) {
                    // Second animation is done
                    setAnimationFrame(1);
                    setShowRestart(true);
                    setAnimated(false);
                }
            }
        }, PUPPY_WINNING_ANIMATION_CYCLE_SPEED);
    };

    const startShowPointsGained = async () => {
        if (props.data.firstDay) {
            playPuppyWinningAnimationCycle(0, 0, true);
            setBonus("Ready, Set, Go!!")
            await delay(PUPPY_WINNING_ANIMATION_DURATION);
        } else {
            let hasScore = false;
            const pointsAddedForThisStep = props.data.newDoses;
            if (pointsAddedForThisStep > 0) {
                hasScore = true;
                playPuppyWinningAnimationCycle(0, 0, 0 === POINTS_WALKTHROUGH_DETAILS.length - 1);
                await delay(PUPPY_WINNING_ANIMATION_DURATION);
            }

            if (props.data.bonus > 0) {
                hasScore = true;
                playPuppyWinningAnimationCycle(0, 0, 0 === POINTS_WALKTHROUGH_DETAILS.length - 1);
                setBonus(`Bonus!! (${props.data.bonus})`)
                await delay(PUPPY_WINNING_ANIMATION_DURATION);
            }

            if (!hasScore) {
                setAnimated(true);
                setBonus(null);
                await delay(PUPPY_WINNING_ANIMATION_DURATION);
                setShowRestart(true);
                setAnimated(false);
            }
        }
    };

    const playPuppyMovingAnimationCycle = (durationCounter=0, animationFrame=0) => {
        setAnimationStep(MOVING);

        setTimeout(() => {
            setAnimationFrame(animationFrame);

            if (durationCounter < PUPPY_MOVING_ANIMATION_DURATION) {
                const nextAnimationFrame = animationFrame === 0 ? 1 : 0;
                playPuppyMovingAnimationCycle(durationCounter + PUPPY_MOVING_ANIMATION_CYCLE_SPEED, nextAnimationFrame);
            } else {
                // First animation is done
                setAnimationStep(WINNING);
                setAnimated(true);
                startShowPointsGained();
            }
        }, PUPPY_MOVING_ANIMATION_CYCLE_SPEED);
    };

    const playPuppySleepingAnimationCycle = (durationCounter=0, animationFrame=0) => {
        setTimeout(() => {
            setAnimationStep(SLEEPING);
            setAnimationFrame(animationFrame);

            if (durationCounter < PUPPY_SLEEPING_ANIMATION_DURATION) {
                const nextAnimationFrame = animationFrame === 0 ? 1 : 0;
                playPuppySleepingAnimationCycle(durationCounter + PUPPY_SLEEPING_ANIMATION_CYCLE_SPEED, nextAnimationFrame);
            } else {
                // First animation is done
                setAnimationStep(SLEEPING_END);
                startShowPointsGained();
            }
        }, PUPPY_SLEEPING_ANIMATION_CYCLE_SPEED);
    };


    const startPointsGainedAnimation = () => {
        setAnimationStep(null);
        setShowRestart(false);
        setAnimated(false);
        setBonus("");
        setAnimationFrame(0);

        if (props.data?.newDoses > 0) {
            playPuppyMovingAnimationCycle();
        } else {
            playPuppySleepingAnimationCycle();
        }
    };

    useEffect(() => {
        startPointsGainedAnimation();
    }, []);

    const handleRestartAnimation = () => {
        // Restart animation loop
        startPointsGainedAnimation();
    }

    const delay = (ms) => {
        return new Promise(res => setTimeout(res, ms));
    }

    return (
        <div>
            <div className={styles.root}>
                <div className={classnames(styles.fieldContainer, {
                    [styles.playAnimation]: animationStep === MOVING,
                    [styles.showLeaves]: (animationStep === SLEEPING || animationStep === SLEEPING_END),
                })}>
                    <div className={classnames(styles.cleanField, {
                        [styles.hidden]: animationStep !== MOVING,
                        [styles.visible]: animationStep === MOVING || animationStep === WINNING,
                    })}/>

                    <div className={classnames(styles.puppySprite, {
                        [styles.moving]: animationStep === MOVING,
                        [styles.sleeping]: animationStep === SLEEPING,
                        [styles.winning]: animationStep === WINNING,
                        [styles.sleepingEnd]: animationStep === SLEEPING_END,
                        [styles.frame1]: animationFrame === 1,
                    })}/>

                    <div className={classnames(styles.scoreTypeLabelContainer, {
                        [styles.hidden]: animationStep !== WINNING,
                    })}>
                        <div className={classnames(styles.scoreTypeLabel, styles.bg)}>
                            {bonus}
                        </div>
                        <div className={classnames(styles.scoreTypeLabel, styles.fg)}>
                            {bonus}
                        </div>
                    </div>

                    <div className={classnames(styles.pointsAddedContainer, {
                        [styles.hidden]: !(animationStep === WINNING || animationStep === SLEEPING_END),
                    })}>
                        <span className={classnames(styles.pointsAddedRow, styles.bg, {
                            [styles.animated]: animated,
                        })}>
                            + <span>{props.data.newDoses}</span> <span className={styles.pointsLabel}>Doses</span>
                        </span>
                        <span className={classnames(styles.pointsAddedRow, styles.fg, {
                            [styles.animated]: animated,
                        })}>
                            + <span>{round(props.data.newDoses)}</span> <span className={styles.pointsLabel}>Doses</span>
                        </span>
                    </div>

                    <div className={classnames(styles.scoreboard, {
                        [styles.hidden]: !(animationStep === WINNING || animationStep === SLEEPING_END),
                    })}>
                        <div className={styles.scoreboardHeader}>
                            <div className={styles.scoreLabel}>Doses <i className="fa fa-paw"/></div>
                        </div>
                        <div className={styles.finalScore}>
                            {round(props.data.totalDoses)}
                        </div>
                    </div>
                    {showRestart && (
                        <div
                            className={classnames(styles.restartAnimationContainer)}
                            onClick={handleRestartAnimation}
                        >
                            <i className="fa fa-arrow-rotate-left"/>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

PuppyLeafPartyClinicScore.propTypes = {
    data: PropTypes.shape({
        totalDoses: PropTypes.number,
        newDoses: PropTypes.number,
        bonus: PropTypes.number,
    }),
};
