import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "./VaccineTableRow.scss";
import map from "lodash/map";
import * as MappingActions from "actions/MappingActions";
import Button from "components/common/Button";
import MappingPimsDropdownSearch from "components/admin/elements/MappingPimsDropdownSearch";
import Modal from "components/common/Modal";
import PriceHistoryPopup from "components/admin/widgets/PriceHistoryPopup";
import TextBox from "components/common/TextBox";
import {getProviderImage} from "utils/ClinicData";
import getSpeciesIcon from "utils/getSpeciesIcon";
import logger from "utils/logger";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function VaccineTableRow(props) {
    const [deleting, setDeleting] = useState(false);
    const [showPricingHistory, setShowPricingHistory] = useState(false);

    const rowData = useMemo(() => {
        return {
            clinicId: props.clinicId,
            complianceDuration: props.vaccine.defaultComplianceDuration || 0,
            ...props.vaccine,
            ...props.mapping,
        };
    }, [props.clinicId, props.clinicProductMappingId])

    const [formData, setFormData] = useState({});

    const combinedData = {
        ...rowData,
        ...formData,
    }
    const saved = combinedData.clinicProductId ? !!rowData.clinicProductMappingId : true;
    const provider = props.providers[combinedData.providerId];

    const handleChange = ({name, value}) => {
        if(props.canEditVaccineMapping) {
            const newFormData = {
                ...formData,
                [name]: value,
            }
            const newCombinedData = {
                ...combinedData,
                ...newFormData,
            }
            setFormData(newFormData);
            if (props.clinicProductMappingId) {
                if(props.canEditVaccineMapping) {
                    const allData = {
                        ...combinedData,
                        ...newCombinedData
                    }
                    props.updateVaccineMapping(allData, props.clinicId, combinedData.order);
                }
            } else if (newFormData.clinicProductId) {
                if(!!props.index) {
                    props.handleOnCreate(props.index, newFormData.clinicProductId);
                }
                // CREATE
                if(props.canEditVaccineMapping) {
                    logger.log("creating vaccine mapping")
                    props.createVaccineMapping(newCombinedData, props.clinicId, (props.highestVaccineIndex + 1));
                }
            }
        }
    }

    const handleOnDelete = () => {
        //Show the deleting spinner icon
        setDeleting(true);
        if(props.clinicProductMappingId) {
            //Delete any related "blank mappings" that may be lingering from a create.
            props.handleOnDelete(props.clinicProductMappingId);
        }
    }

    return (
        <tr className={classnames(styles.root,"spaced-content", {
                [styles.firstRow]: props.showProduct,
                [styles.deleting]: deleting,
            })}
        >
            <td>
                {props.showProduct && (
                    <div className={classnames(styles.icons, "nowrap")}>
                        {provider && provider.smallImageUri && (
                            <img
                                src={getProviderImage(provider)}
                                alt={provider.name}
                            />
                        )}
                        {(combinedData.speciesName && combinedData.speciesName !== "N/A") && (
                            <img src={getSpeciesIcon(combinedData.speciesName)} alt={combinedData.speciesName}/>
                        )}
                    </div>
                )}
            </td>
            <td>
                {props.showProduct && (
                    <div className="no-wrap">
                        {combinedData.name}
                    </div>
                )}
            </td>
            <td>
                {props.showProduct && (
                    <div className="flex-1 text-sm flex flex-wrap spaced-content">
                        {map(combinedData.tags, (tag, index) => (
                            <div
                                key={index}
                                className={styles.vaccineTypes}
                            >
                                {tag}
                            </div>
                        ))}
                    </div>
                )}
            </td>
            <td>
                <TextBox
                    small
                    inputType="number"
                    value={combinedData.complianceDuration}
                    name="complianceDuration"
                    min={0}
                    onChange={handleChange}
                    disabled={!props.canEditVaccineMapping || deleting}
                />
            </td>
            <td>
                {!!combinedData.clinicProductId && (
                    <Button
                        text
                        small
                        type="primary"
                        onClick={() => setShowPricingHistory(true)}
                        className="nowrap"
                        disabled={deleting}
                    >
                        Price History
                    </Button>
                )}
            </td>
            <td>
                <div className={styles.myProduct}>
                    <MappingPimsDropdownSearch
                        typeId={props.typeId}
                        mappingId={props.clinicProductMappingId}
                        clinicProductId={combinedData.clinicProductId}
                        clinicId={props.clinicId}
                        productId={props.vaccineId}
                        handleChange={handleChange}
                        handleDeleting={handleOnDelete}
                        pimsOptions={props.pimsOptions}
                        disabledPimsOptions={props.disabledPimsOptions}
                        boldPimsOptions={props.boldPimsOptions}
                        disabled={!props.canEditVaccineMapping || deleting}
                    />
                </div>
            </td>
            <td>
                {(saved && !deleting) ? (
                    <Button
                        iconOnly
                        text
                        type="success"
                        onClick={props.handleAddBlankMapping}
                        disabled={!props.canEditVaccineMapping}
                    >
                        <i className="fa fa-2x fa-plus-square"/>
                    </Button>
                ) : (
                    <div className="text-center">
                        <i className="text-success fa fa-spinner-third fa-spin"/>
                    </div>
                )}
                <Modal
                    mediumSmall
                    show={showPricingHistory}
                    onClose={() => setShowPricingHistory(false)}
                    modalTitle={`#${props.mapping.clinicProductId} - 6 Month Price History`}
                >
                    <PriceHistoryPopup
                        clinicId={props.clinicId}
                        clinicProductId={props.mapping.clinicProductId}
                    />
                </Modal>
            </td>
        </tr>
    )
}

VaccineTableRow.propTypes = {
    clinicId: PropTypes.number.isRequired,
    vaccineId: PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
    pimsOptions: PropTypes.array.isRequired,
    disabledPimsOptions: PropTypes.array.isRequired,
    boldPimsOptions: PropTypes.array.isRequired,
    handleOnCreate: PropTypes.func,
    handleOnDelete: PropTypes.func,
    clinicProductMappingId: PropTypes.number,
    index: PropTypes.number,
    showProduct: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canEditVaccineMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.VACCINE_MAPPING, userProfile);
        return {
            canEditVaccineMapping,
            providers: state.entities.providers,
            vaccine: state.entities.vaccines?.[ownProps.vaccineId],
            mapping: state.entities.vaccineMappings[ownProps.clinicId]?.[ownProps.vaccineId]?.mappings?.[ownProps.clinicProductMappingId] || {},
            highestVaccineIndex: state.entities.mappingCounts?.[ownProps.clinicId]?.highestVaccineIndex || 0,
        }
    }, (dispatch) => ({
        createVaccineMapping: (data, clinicId, order) => dispatch(MappingActions.createVaccineMapping(data, clinicId, order)),
        updateVaccineMapping: (data, clinicId, order) => dispatch(MappingActions.updateVaccineMapping(data, clinicId, order)),
    }),
)(VaccineTableRow);
