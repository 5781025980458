import { find } from "lodash";

export default function getProviderId(params, userProfile) {
    if (params?.providerId) {
        return Number(params.providerId);
    } else if (!userProfile) {
        return null;
    }

    // See also serverMapping.js
    const providerData = find(userProfile.providers, {isPrimaryLocation: true});
    return providerData ? Number(providerData.id) : null;
}