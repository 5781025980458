import React, {useContext, useState} from "react";
import flatMap from "lodash/flatMap";
import filter from "lodash/filter";
import Modal from "components/common/Modal";
import ProgramWelcomeForm from "components/common/forms/ProgramWelcomeForm";
import {connect} from "react-redux";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import * as UserActions from "actions/UserActions";
import * as ClinicActions from "actions/ClinicActions";
import * as ClinicApi from "api/ClinicApi";
import toast from "utils/toast";
import includes from "lodash/includes";
import * as CouponsApi from "api/CouponsApi";
import {getLinkLocation} from "utils/ClinicAdminLinks";
import {handleErrorResponse} from "utils/request";
import {VETCHECK} from "constants/ProviderIds";
import * as UserApi from "api/UserApi";
import map from "lodash/map";
import * as VetCheckApi from "api/VetCheckApi";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {windowSizeContext} from "AppRoot";
import PropTypes from "prop-types";
import * as VetCheckActions from "../../../../actions/VetCheckActions";
import {compose} from "redux";
import {withRouter} from "react-router";
import { mapUserProfileFromServerToApp } from "data/serverMapping";

function ProgramWelcomeModal(props) {

    const [loading, setLoading] = useState(false);
    const context = useContext(windowSizeContext) || {};
    const couponProviderIds = flatMap(filter(props.providers, "isCouponProvider"), "id");

    const handleCloseVoucherForm = () => {
        props.clearVoucherSearch();
    }

    const getClinicId = () => {
        if(props.clinicId) {
            return props.clinicId;
        } else if(props.primaryClinic) {
            return props.primaryClinic.id;
        }

        return null;
    }

    const handleAcceptVoucher = (enrollData) => {
        if(props.canViewClinicCouponLibrary) {
            const clinicId = getClinicId();
            if(!clinicId || !enrollData) {
                return;
            }
            enrollClinicProgram(clinicId, enrollData);
            handleCloseVoucherForm();
        }
    }

    const enrollClinicProgram = (clinicId, data, isRegistration= false) => {
        setLoading(true);
        ClinicApi.enrollClinicInProgram(clinicId, data)
            .then(() => {
                toast.success("Successfully Enrolled in Program");
                if(isRegistration) {
                    props.programAddedToClinic();
                } else {

                    if(includes(couponProviderIds, data?.providerId)) {
                        CouponsApi.getPrograms(clinicId)
                            .then((res) => {
                                if (res.body) {
                                    props.programsLoaded(res.body);
                                    props.history.push(getLinkLocation("couponLibrary", clinicId));
                                }
                            })
                            .catch((error) => {
                                handleErrorResponse("loading programs", error);
                                setLoading(false);
                            });
                    }

                    ClinicApi.getClinicInfo(clinicId)
                        .then((results) => {
                            if (results.body) {
                                props.clinicDetailsLoaded(results.body);
                            }
                            // IF the plan is VetCheck and there is a clinicId then go to the vetcheck page
                            if(data?.providerId === VETCHECK && clinicId) {
                                if(props.canViewClinicManagement) {
                                    props.history.push(getLinkLocation("vetcheck", clinicId));
                                } else {
                                    UserApi.loadUserInfo()
                                        .then((res) => {
                                            if (res.body) {
                                                props.currentUserLoaded(res.body);
                                                const prof = mapUserProfileFromServerToApp(res.body);
                                                if(
                                                    (
                                                        userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, prof) ||
                                                        userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_VETCHECK, prof)
                                                    ) && !!res.body?.clinics?.length
                                                ){
                                                    map(res.body.clinics, clinic => {
                                                        VetCheckApi.getVetCheckStatus(clinic.id)
                                                            .then((results) => {
                                                                props.vetCheckStatusLoaded(results.body);
                                                                props.history.push("/vetcheck");
                                                            })
                                                            .catch((error) => {
                                                                handleErrorResponse("loading vetcheck status", error);
                                                                setLoading(false);
                                                            });
                                                    })
                                                } else {
                                                    setLoading(false);
                                                }
                                            }
                                        })
                                        .catch((error) => {
                                            handleErrorResponse("loading user details", error);
                                        });
                                }
                            }
                            setLoading(false);
                        })
                        .catch((error) => {
                            handleErrorResponse("loading clinic details", error);
                            setLoading(false);
                        });
                }
            })
            .catch((error) => {
                handleErrorResponse("enrolling clinic in program", error);
                setLoading(false);
            });
    }

    return (
        <>
            <Modal
                show={props.voucher && !!props.voucher.plan}
                onClose={handleCloseVoucherForm}
                medium
                modalTitle={props.voucher?.plan?.header || ""}
            >
                <div className="padding-lg">
                    <ProgramWelcomeForm
                        initialData={props.voucher ? props.voucher.plan : {}}
                        voucherCode={props.voucher ? props.voucher.voucherCode : ""}
                        onSubmit={handleAcceptVoucher}
                        onCancel={handleCloseVoucherForm}
                    />
                </div>
            </Modal>
            <SpinnerTakeover show={loading}/>
        </>
    );
}

ProgramWelcomeModal.propTypes = {
    clinicId: PropTypes.number,
}

const connector = connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);

        return {
            providers: state.entities.providers,
            voucher: state.entities.registration.data,
            canViewClinicCouponLibrary,
        }
    },
    (dispatch) => ({
        currentUserLoaded: (user) => dispatch(UserActions.currentUserLoaded(user)),
        clinicDetailsLoaded: (clinic) => dispatch(ClinicActions.clinicDetailsLoaded(clinic)),
        vetCheckStatusLoaded: (status) => dispatch(VetCheckActions.vetCheckStatusLoaded(status)),
        programsLoaded: (programs) => dispatch(ClinicActions.programsLoaded(programs)),
        programAddedToClinic: () => dispatch(ClinicActions.programAddedToClinic()),
        clearVoucherSearch: () => dispatch(ClinicActions.clearVoucherCode()),
        clearAllSearches: () => dispatch(UserActions.clearAllSearches()),
    })
);

export default compose(
    withRouter,
    connector
)(ProgramWelcomeModal);
