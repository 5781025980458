export function slugifyProviderName(location) {
    // Example: Ms.+Ann+M+Baca+(MWJ03)+(0)
    const clinicName = location.name;

    if (!clinicName) {
        return "";
    }

    const slug = clinicName.replace(/\s/g, '+')
    return `${slug}+(0)`;
}