import React, { useEffect } from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import SortableDataTable from "components/common/SortableDataTable";
import * as MappingActions from "actions/MappingActions";
import getLocalTimezone from "utils/getLocalTimezone";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";

function ClinicProductOverrideTable(props) {
    useEffect(() => {
        if(props.canViewPreAuditReview) {
            props.getClinicOverrides(props.clinicId);
        }
    }, []);

    const handleRowClicked = (row) => {
        props.handleRowClicked(row.productId);
    }

    const COLUMNS = [{
        name: "Clinic Product",
        selector: "productName",
        key: "productName",
        searchable: true,
        format: row => <div className="nowrap">{row.productName}</div>,
    }, {
        name: "Mapped Products",
        selector: "mappedProducts",
        key: "mappedProducts",
        searchable: true,
        format: row => <div>{row.mappedProducts.join(", ")}</div>,
    }, {
        name: "Last Updated",
        selector: "lastUpdated",
        key: "lastUpdated",
        searchable: true,
        searchValue: row => row.clinicProductId,
        format: row => !!row.lastUpdated && (<div className="nowrap">{getLocalTimezone(row.lastUpdated)}</div>),
    }, {
        name: "User",
        selector: "userNameId",
        key: "userNameId",
        searchable: true,
        searchValue: row => row.userNameId.name,
        format: row => <span>{row.userNameId.name}</span>,
    }];

    if (!props.products) {
        return <div>No data available</div>;
    }

    if(!props.canViewPreAuditReview) {
        return <AccessDenied/>;
    }

    return (
        <div>
            <SortableDataTable
                columns={COLUMNS}
                rawData={props.products}
                striped
                green
                onClick={handleRowClicked}
                search={props.search}
                allowSearch
                hideSearchBar
            />
        </div>
    )
}

ClinicProductOverrideTable.propTypes = {
    clinicId: PropTypes.number.isRequired,
    handleRowClicked: PropTypes.func,
    handleOpenCreateForm: PropTypes.func,
};

export default connect(
    (state, ownProps) => {
        const products = state.entities.productOverrides.products ? state.entities.productOverrides.products : {};
        const userProfile = state.user.userProfile;
        const canViewPreAuditReview = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRE_AUDIT_REVIEW, userProfile);
        return {
            canViewPreAuditReview,
            products: products[ownProps.clinicId],
            search: state.entities.genericSearch,
        };
    },
    (dispatch) => ({
        getClinicOverrides: clinicId => dispatch(MappingActions.getClinicOverrides(clinicId)),
    }),
)(ClinicProductOverrideTable);

