import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import includes from "lodash/includes";
import * as GreenlineWellnessActions from "actions/GreenlineWellnessActions";
import AccessDenied from "components/common/AccessDenied";
import ClinicBase from "components/layout/ClinicBase";
import PetPlanTracking from "components/greenlineWellness/widgets/GL_PetPlanTracking";
import JoyRide from "components/tour/widgets/JoyRide";
import PlanTrackingModal from "components/greenlineWellness/widgets/PlanTrackingModal";
import {
    TOUR_PET_PLAN_TRACKING_PART_2,
    TOUR_REPORTS_TRANSITION,
} from "utils/demoTourHelpers";
import { clinicHasWellness, getClinicInfo } from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { CLINIC_TAGS } from "constants/AdminControls";
import * as DemoTourStates from "constants/DemoTourStates";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

function PetPlanTrackingPage(props)  {
    // GREENLINE WELLNESS PET PLAN TRACKING

    // TOUR ONLY
    const [tourSection, setTourSection] = useState(null);
    const [showTourModal, setShowTourModal] = useState(null);
    const getTourSection = () => {
        // Don't set the tourSection if the tour is complete
        let currentSection = null;
        if (props.tourStarted) {
            if (!includes(props.completedTourSections, TOUR_PET_PLAN_TRACKING_PART_2)) {
                currentSection = TOUR_PET_PLAN_TRACKING_PART_2;
            } else if (!includes(props.completedTourSections, TOUR_REPORTS_TRANSITION)) {
                currentSection = TOUR_REPORTS_TRANSITION;
            }
        }
        setTourSection(currentSection);
    }
    useEffect(() => {
        getTourSection();
    }, [props.completedTourSections]);

    const handleTourStepComplete = (step, index) => {
        if (step === TOUR_PET_PLAN_TRACKING_PART_2) {
            if (index === 0) {
                setShowTourModal(200832476);
            }
        }
    };
    // END

    const clearSelectedPlan = () => {
        props.clearSelectedWellnessPlan();
    };
    
    return (
        <ClinicBase
            searchConfig={SearchTypes.WELLENSS_PET_PLAN_TRACKING}
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle={"Greenline: Pet Plan Tracking"}
        >
            {!props.canViewWellnessPetPlans ? (
                <div className="full-width full-height">
                    <h2>Pet Plan Tracking</h2>
                    <AccessDenied/>
                </div>
            ) : !props.clinicHasWellness ? (
                <div className="full-width full-height">
                    <h2>Pet Plan Tracking</h2>
                    <AccessDenied customMessage="This clinic does not have access to Pet Plan Tracking"/>
                </div>
            ) : (
                <PetPlanTracking
                    clinicId={props.clinicId}
                    canGLWellness={props.canGLWellness}
                />
            )}
            {(props.selectedId || showTourModal) &&
                <PlanTrackingModal
                    selectedId={props.selectedId || showTourModal}
                    onClose={clearSelectedPlan}
                    clinicId={props.clinicId || props.clinicInfo?.clinicId }
                    canGLWellness={props.canGLWellness}
                />
            }
            <JoyRide
                key={tourSection}
                tourSection={tourSection}
                onStepComplete={handleTourStepComplete}
            />
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;

        // Permissions
        const canViewWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canGLWellness = clinicInfo?.programs?.Wellness?.providers.includes(69);

        return {
            clinicId,
            clinicInfo,
            clinicHasWellness: clinicHasWellness(state, clinicId),
            completedTourSections: state.demo.completedTourSections,
            selectedId: state.wellness.selectedId || null,
            tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),

            // Permissions
            canGLWellness,
            canViewWellnessPetPlans,
        }
    },
    (dispatch) => ({
        clearSelectedWellnessPlan: () => dispatch(GreenlineWellnessActions.clearSelectedWellnessPlan()),
    })
)(PetPlanTrackingPage);
