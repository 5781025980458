exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".CumulativeOfferTrackingPage__root{overflow-x:auto;height:100%;-ms-flex:1;flex:1}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/reports/pages/CumulativeOfferTrackingPage.scss"],"names":[],"mappings":"AAAA,mCAAM,gBAAgB,YAAY,WAAW,MAAM,CAAC","file":"CumulativeOfferTrackingPage.scss","sourcesContent":[".root{overflow-x:auto;height:100%;-ms-flex:1;flex:1}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "CumulativeOfferTrackingPage__root"
};