import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styles from "./Tooltip.scss";
import {autobind} from "core-decorators";
import classnames from "classnames";
import * as Position from "constants/TooltipPositionIds";

export default class Tooltip extends React.Component {
    static propTypes = {
        tip: PropTypes.any,
        delay: PropTypes.number,
        manuallyShow: PropTypes.bool,
        position: PropTypes.oneOf([Position.LEFT, Position.RIGHT, Position.TOP, Position.BOTTOM]).isRequired,
        showing: PropTypes.bool,
        onMouseOver: PropTypes.func,
        onMouseOut: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.state = {
            showing: false,
        };
        this.childRef = React.createRef();

        if (!this.constructor.el) {
            this.constructor.el = document.createElement("div");
        }
    }

    componentDidMount() {
        document.getElementById("tooltip-root").appendChild(this.constructor.el);
    }

    componentWillUnmount() {
        this.cleanupTimers();
    }

    get showing() {
        return this.props.showing || this.state.showing;
    }

    cleanupTimers() {
        window.clearTimeout(this.mouseOverTimeout);
    }

    @autobind
    handleMouseOver() {
        if (this.props.manuallyShow) {
            return;
        }

        const doMouseOver = () => {
            this.setState({
                showing: true,
            });
        };

        if (this.props.delay) {
            this.mouseOverTimeout = setTimeout(doMouseOver, this.props.delay);
        } else {
            doMouseOver();
        }
    }

    @autobind
    handleMouseOut() {
        this.cleanupTimers();
        this.setState({
            showing: false,
        });
    }

    renderTooltip() {
        if (!this.showing) {
            return;
        }

        const boundingBox = this.childRef.current ? this.childRef.current.getBoundingClientRect() : null;
        let style;

        if (boundingBox) {
            style = {
                top: boundingBox.top + boundingBox.height / 2,
            };

            if (this.props.position === Position.LEFT) {
                style = {
                    ...style,
                    right: window.innerWidth - boundingBox.left + 15,
                };
            } else if (this.props.position === Position.RIGHT) {
                style = {
                    ...style,
                    left: boundingBox.right + 15,
                };
            } else if (this.props.position === Position.BOTTOM) {
                style = {
                    top: boundingBox.bottom + 5,
                    left: boundingBox.left + (boundingBox.width / 2),
                };
            } else if (this.props.position === Position.TOP) {
                style = {
                    top: boundingBox.top - 15,
                    left: boundingBox.left + (boundingBox.width / 2),
                };
            }
        }

        const component = (
            <span
                className={classnames(styles.tooltip, {
                    [styles.left]: this.props.position === Position.LEFT,
                    [styles.right]: this.props.position === Position.RIGHT,
                    [styles.bottom]: this.props.position === Position.BOTTOM,
                    [styles.top]: this.props.position === Position.TOP,
                })}
                style={style}
            >
                {this.props.tip}
            </span>
        );

        return ReactDOM.createPortal(
            component,
            this.constructor.el,
        );
    }

    render() {
        const self = this;
        const props = this.props;
        React.Children.only(props.children);
        const children = React.Children.map(props.children, (c) => {
            const onMouseOver = (e) => {
                self.handleMouseOver();
                if (c.props.onMouseOver) {
                    c.props.onMouseOver(e);
                }
            };

            const onMouseOut = (e) => {
                self.handleMouseOut();
                if (c.props.onMouseOut) {
                    c.props.onMouseOut(e);
                }
            };

            const newChild = React.cloneElement(c, {
                onMouseOver,
                onMouseOut,
                ref: self.childRef,
            }, (
                <>
                    {self.renderTooltip()}
                    {c.props.children}
                </>
            ));

            return (
                <>
                    {newChild}
                </>
            );
        });

        return children;
    }
}
