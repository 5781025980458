import React, {useEffect, useMemo, useRef, useState} from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import * as styles from "./CorporateGroupProviderDetails.scss";
import orderBy from "lodash/orderBy";
import * as ProviderApi from "api/ProviderApi";
import * as ProviderActions from "actions/ProviderActions";
import * as UserActions from "actions/UserActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import Image from "components/common/Image";
import ProviderBase from "components/layout/ProviderBase";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {getNodeId} from "utils/AdminData";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import getProviderId from "utils/getProviderId";
import {formatUSD} from "utils/numeric";
import * as ProviderLinks from "utils/ProviderLinks";
import {downloadFile} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as UserPermissions from "constants/UserPermissions";
import moment from "moment";
import Dropdown from "../../common/Dropdown";

function CorporateGroupProviderDetails(props) {
    const currentYear = moment().year();
    const [year, setYear] = useState(currentYear);
    const history = useHistory();

    useEffect(() => {
        if (!!props.nodeId) {
            props.getClinicAggregates(props.nodeId, year);
        }
    }, [props.nodeId, props.corporateGroupId, year]);

    const wasLoggedIn = useRef(props.isLoggedIn);

    useEffect(() => {
        const shouldLoadProfile = props.isLoggedIn && !props.userProfile;
        const didReLogin = !props.isLoggedIn && wasLoggedIn;

        if (shouldLoadProfile || didReLogin) {
            props.loadCurrentUser();
            wasLoggedIn.current = true;
        }
    }, [props.isLoggedIn]);

    if (!props.canViewCorporateGroupClinic) {
        return (
            <ProviderBase
                match={props.match}
                location={props.location}
                pageTitle={props.provider?.name ? `${props.provider?.name} Clinics` : ""}
                nodeId={props.nodeId}
                providerId={props.providerId}
            >
                    <AccessDenied/>
            </ProviderBase>
        );
    }

    const YEAR_OPTIONS = useMemo(() => {
        const options = [];
        for (let i=2023; i <= currentYear; i++ ) {
            options.push({
                name: `${i}`,
                value: i,
            });
        }
        return options;
    }, [currentYear]);

    const handleGoToCouponHistory = (e, clinicId) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("couponHistory", clinicId));
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("couponHistory", props.nodeId, clinicId));
        }
    }

    const handleGoToCouponLibrary = (e, clinicId) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("libraryProgram", clinicId, props.corporateGroupId));
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("libraryProgram", props.nodeId, clinicId, props.corporateGroupId));
        }
    }

    const COLUMNS = [{
        name: "Links",
        selector: "clinicId",
        key: "clinicId",
        format: row => (
            <div className="flex">
                {(props.canViewCouponAudit) && (
                    <Button
                        type="success"
                        iconOnly
                        text
                        onClick={(e) => handleGoToCouponHistory(e, row.clinicId)}
                        title="Coupon History"
                    >
                        <i className="fa fa-ticket margin-right-sm"/>
                    </Button>
                )}
                {(props.canViewCouponLibrary) && (
                    <Button
                        type="success"
                        iconOnly
                        text
                        onClick={(e) => handleGoToCouponLibrary(e, row.clinicId)}
                        title="Coupon Library"
                    >
                        <i className="fa fa-scissors"/>
                    </Button>
                )}
            </div>
        ),
    }, {
        name: "Clinic",
        selector: "clinicName",
        key: "clinicName",
        sortable: true,
        searchable: true,
    },{
        name: "Redemption Total",
        selector: "totalRebateCount",
        key: "totalRebateCount",
        sortable: true,
        total: true,
        totalClasses: "text-center",
        format: row => (<div className='text-center'>{!!row.totalRebateCount ? row.totalRebateCount : '-'}</div>)
    },{
        name: "Free Dose Total",
        selector: "totalFreeDoses",
        key: "totalFreeDoses",
        sortable: true,
        total: true,
        totalClasses: "text-center",
        format: row => (<div className="text-center">{!!row.totalFreeDoses ? row.totalFreeDoses : "-"}</div>)
    }, {
        name: "Rebate Dollars IR",
        selector: "totalIRDollars",
        key: "totalIRDollars",
        type: "currency",
        sortable: true,
        total: true,
        totalClasses: "text-center",
        format: row => (<div className="text-center">{!!row.totalIRDollars ? formatUSD(row.totalIRDollars) : "-"}</div>)
    }, {
        name: "Rebate Dollars Non-Instant",
        selector: "totalNonIRDollars",
        key: "totalNonIRDollars",
        type: "currency",
        sortable: true,
        total: true,
        totalClasses: "text-center",
        format: row => (<div className="text-center">{!!row.totalNonIRDollars ? formatUSD(row.totalNonIRDollars) : "-"}</div>),
    }];

    const handleDownloadExcel = async () => {
        toast.success("Generating Export...");
        const res = await ProviderApi.exportClinicAggregates(props.corporateGroupId, "xlsx", props.nodeId, year);
        downloadFile(res, "xlsx");
    }

    const handleGoBackToClinicsList = () => {
        let link;
        if (props.nodeId === props.userProfile?.nodeId) {
            link = ProviderLinks.getLinkLocation("dashboard", {
                providerId: props.providerId,
                isAdmin: props.isAdmin
            });
        } else {
            link = ProviderLinks.getLinkLocation("dashboard", {
                providerId: props.providerId,
                nodeId: props.nodeId,
                isAdmin: props.isAdmin
            });
        }
        history.push(link);
    }

    return (
        <ProviderBase
            match={props.match}
            location={props.location}
            pageTitle={props.provider?.name ? `${props.provider?.name} ${year} Totals` : ""}
            nodeId={props.nodeId}
            providerId={props.providerId}
        >
            <div className={styles.root}>
                {props.provider && (
                    <div className="flex spaced-content">
                        <div className="flex-1">
                            <Button
                                icon
                                onClick={handleGoBackToClinicsList}
                                type="primary"
                            >
                                <i className="fa fa-chevron-left"/> Back to dashboard
                            </Button>
                        </div>
                        <div className="flex-2 flex spaced-content align-center">
                            <Image
                                style={{
                                    maxWidth: "100%",
                                    marginTop: "-3em",
                                    marginBottom: "-4em",
                                }}
                                src={props.provider.mediumImageUri}
                                alt={props.provider.name}
                            />
                            <Dropdown
                                onChange={({ value }) => setYear(value)}
                                name="year"
                                value={year}
                                label="Year"
                                options={YEAR_OPTIONS}
                                required
                            />
                        </div>
                    </div>
                )}
                <div>
                    <SortableDataTable
                        striped
                        green
                        title={props.title}
                        resultsPerPage={15}
                        columns={COLUMNS}
                        rawData={props.manufacturerClinics}
                        allowSearch
                        showTotals
                        downloadExcel={handleDownloadExcel}
                    />
                </div>
                <SpinnerTakeover show={props.working}/>
            </div>
        </ProviderBase>
    );
}

export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const providerId = getProviderId(params, userProfile);
        const corporateGroupId = Number(params.groupId) || providerId;
        const nodeId = getNodeId(state, ownProps);
        const nodeClinics = state.entities.providerClinicAggregates?.[nodeId] || {};
        //Permissions
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canViewCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canViewCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);

        return {
            userProfile,
            corporateGroupId,
            providerId,
            nodeId,
            canViewCorporateGroupClinic,
            canViewCouponAudit,
            canViewCouponLibrary,
            isLoggedIn: state.user.isLoggedIn,
            provider: state.entities.providers[corporateGroupId],
            search: state.entities.genericSearch,
            manufacturerClinics: orderBy(nodeClinics?.[corporateGroupId], ["clinicName", "clinicId"], ["asc", "asc"]) || [],
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && providerId && nodeId),
            working: !!state.applicationState.working,
        };
    },
    (dispatch) => ({
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
        getClinicAggregates: (nodeId, year) => dispatch(ProviderActions.getClinicAggregates(nodeId, year)),
    }),
)(CorporateGroupProviderDetails);
