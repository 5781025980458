import React from "react";
import PropTypes from "prop-types";
import CheckboxInput from "components/common/CheckboxInput";
import Dropdown from "components/common/Dropdown";
import GreenlineWellnessEnrollmentMappings from "components/greenlineWellness/GreenlineWellnessEnrollmentMappings";
import GreenlineWellnessPlanServices from "components/greenlineWellness/GreenlineWellnessPlanServices";
import TextBox from "components/common/TextBox";

export default function GreenlinePlanSetupForm(props) {
    const editPlanDisabled = props.readOnly || (!props.addingPlan && props.plan?.isActive);

    return (
        <div>
            <div>
                <div className="flex spaced-content">
                    {props.addingPlan ? (
                        <div className="flex-2">
                            <Dropdown
                                label="Plan Type"
                                onChange={props.onChange}
                                name="clinicPlanId"
                                value={`${props.plan?.clinicPlanId}`}
                                disabled={editPlanDisabled}
                                placeholder="--Select Plan Type--"
                                options={props.templateOptions}
                                required
                            />
                        </div>
                    ) : (
                        <div className="flex-2">
                            <Dropdown
                                label="Plan Group"
                                onChange={props.onChange}
                                name="groupName"
                                value={`${props.plan?.groupName}`}
                                disabled={editPlanDisabled}
                                placeholder="--Select Group--"
                                options={props.groupOptions}
                                required
                            />
                        </div>
                    )}
                    <div className="flex-1">
                        <div className="padding-sm">
                            <label>Plan Weight Range (In lbs.)</label>
                        </div>
                        <div className="flex">
                            <TextBox
                                // label="Min Weight"
                                onChange={props.onChange}
                                name="minWeightInPounds"
                                value={props.plan?.minWeightInPounds}
                                placeholder="Min"
                                disabled={editPlanDisabled}
                                inputType="number"
                                min={0}
                                max={2000}
                            />
                            <div className="padding-right-sm"/>
                            <TextBox
                                // label="Max Weight"
                                onChange={props.onChange}
                                name="maxWeightInPounds"
                                value={props.plan?.maxWeightInPounds}
                                placeholder="Max"
                                disabled={editPlanDisabled}
                                inputType="number"
                                min={0}
                                max={2000}
                            />
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="padding-sm">
                            <label>Plan Age Range (In Years)</label>
                        </div>
                        <div className="flex">
                            <TextBox
                                // label="Min Age"
                                onChange={props.onChange}
                                name="minAgeInYears"
                                value={props.plan?.minAgeInYears}
                                placeholder="Min"
                                disabled={editPlanDisabled}
                                inputType="number"
                                min={0}
                                max={300}
                            />
                            <div className="padding-right-sm"/>
                            <TextBox
                                // label="Max Age"
                                onChange={props.onChange}
                                name="maxAgeInYears"
                                value={props.plan?.maxAgeInYears}
                                placeholder="Max"
                                disabled={editPlanDisabled}
                                inputType="number"
                                min={0}
                                max={300}
                            />
                        </div>
                    </div>
                </div>
                <GreenlineWellnessEnrollmentMappings
                    onChange={props.onChange}
                    enrollmentMappings={props.plan?.enrollmentMappings}
                    clinicId={props.clinicId}
                    readOnly={props.readOnly}
                />
                <div className="flex spaced-content align-bottom">
                    <TextBox
                        name="alias"
                        label="Plan Name"
                        placeholder={props.plan?.name}
                        value={props.plan?.alias}
                        onChange={props.onChange}
                        disabled={editPlanDisabled}
                        required
                    />
                    <div className="margin-bottom-x-sm text-lg flex-none">
                        <CheckboxInput
                            // leftLabel
                            label="Is Active?"
                            name="isActive"
                            checked={props.plan?.isActive}
                            onChange={props.onChange}
                            disabled={editPlanDisabled || !(props.plan.yearlyCostToClient > 0 || props.plan.monthlyCostToClient > 0)}
                        />
                    </div>
                </div>
                <div className="flex align-bottom">
                    <div className="margin-bottom-x-sm text-lg margin-right-x-sm">$</div>
                    <TextBox
                        min={0}
                        step={.01}
                        inputType="number"
                        name="yearlyCostToClient"
                        label="Yearly Cost to Client"
                        value={props.plan?.yearlyCostToClient}
                        onChange={props.onChange}
                        disabled={editPlanDisabled}
                    />
                </div>
                <div className="flex align-bottom">
                    <div className="margin-bottom-x-sm text-lg margin-right-x-sm">$</div>
                    <TextBox
                        min={0}
                        step={.01}
                        inputType="number"
                        name="monthlyCostToClient"
                        label="Monthly Cost to Client (Net)"
                        value={props.plan?.monthlyCostToClient}
                        onChange={props.onChange}
                        disabled={editPlanDisabled}
                    />
                </div>
                <div className="flex align-bottom">
                    <div className="margin-bottom-x-sm text-lg margin-right-x-sm">$</div>
                    <TextBox
                        min={0}
                        step={.01}
                        inputType="number"
                        name="taxAmount"
                        label="Tax Amount"
                        value={props.plan?.taxAmount}
                        onChange={props.onChange}
                        disabled={editPlanDisabled}
                    />
                </div>
                <div className="flex align-bottom">
                    <div className="margin-bottom-x-sm text-lg margin-right-x-sm">$</div>
                    <TextBox
                        min={0}
                        step={.01}
                        inputType="number"
                        name="feeToProvider"
                        label={`Fee to ${props.provider ? props.provider.name : "Provider"}`}
                        value={props.plan?.feeToProvider}
                        onChange={props.onChange}
                        disabled={editPlanDisabled}
                    />
                </div>
            </div>
            {props.plan && (
                <>
                    <hr className="margin-top-md margin-bottom-sm" />
                    <div>
                        <div className="margin-bottom-md">
                            <GreenlineWellnessPlanServices
                                services={props.plan?.services}
                                handleChange={props.onChange}
                                readOnly={props.readOnly}
                                editPlanDisabled={editPlanDisabled}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    )
}

GreenlinePlanSetupForm.defaultPropTypes = {
    readOnly: false,
    groupOptions: [],
    addingPlan: false,
}

GreenlinePlanSetupForm.propTypes = {
    readOnly: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    plan: PropTypes.object.isRequired,
    templateOptions: PropTypes.array.isRequired,
    groupOptions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
    })),
    clinicId: PropTypes.number.isRequired,
    usedPims: PropTypes.arrayOf(PropTypes.number),
    addingPlan: PropTypes.bool,
};
