import React from "react";

const NEXGARD_PLUS_URL = "https://bi-animalhealth.com/pets/canine/products/parasiticides/nexgard-plus";
const NEXGARD_URL = "https://www.nexgardclinic.com";

const NEXGARD_PLUS_INFORMATION_LINK = "https://cdn.greenlinepet.com/games/assets/prescribinginfo/NexGard_PLUS_PI_2023.pdf";
const NEXGARD_INFORMATION_LINK = "https://cdn.greenlinepet.com/games/assets/prescribinginfo/NexGard_PI_2020.pdf";
const HEARTGARD_PLUS_INFORMATION_LINK = "https://cdn.greenlinepet.com/games/assets/prescribinginfo/HGPlus_PI_2022.pdf";

export default function LeafPartyImportantSafetyInformation(props) {
    return (
        <div data-testid="important_safety_information_component">
            <div className="flex-column spaced-content-vertical text-lg margin-bottom-sm">
                <div>
                    <b>IMPORTANT SAFETY INFORMATION</b>: <em>NexGard</em>® PLUS (afoxolaner, moxidectin, and pyrantel chewable tables) is for use in dogs only. 
                    The most frequently reported adverse reactions include diarrhea, vomiting, lethargy, and itching. Use with caution in dogs with a history of 
                    seizures or neurologic disorders. Dogs should be tested for existing heartworm infection prior to starting a preventative. For more information 
                    or <a className="pointer text-primary" href={NEXGARD_PLUS_INFORMATION_LINK} target="_blank">full prescribing information</a>, speak with your vet, 
                    call 888-637-4251 or visit <a className="pointer text-primary" href={NEXGARD_PLUS_URL} target="_blank">NexGardClinic.com</a>.
                </div>
                <div>
                    <b>IMPORTANT SAFETY INFORMATION</b>: <em>NexGard</em>® (afoxolaner) is for use in dogs only. Use with caution in dogs with a history of 
                    seizures or neurologic disorders. For more information or <a className="pointer text-primary" href={NEXGARD_INFORMATION_LINK} target="_blank">full prescribing information</a>, speak with your vet, 
                    call 888-637-4251 or visit <a className="pointer text-primary" href={NEXGARD_URL} target="_blank">NexGardClinic.com</a>.
                </div>
                <div>
                    <b>IMPORTANT SAFETY INFORMATION</b>: Following the use of HEARTGARD® Plus (ivermectin/pyrantel), digestive and neurologic side 
                    effects have been reported All dogs should be tested for heartworm infection before starting a preventive program. For more 
                    information, see <a className="pointer text-primary" href={HEARTGARD_PLUS_INFORMATION_LINK} target="_blank">full prescribing information</a> or visit <a className="pointer text-primary" href="https://www.heartgardclinic.com" target="_blank">HEARTGARDClinic.com</a>.
                </div>
            </div>
            <div className="full-width margin-top-sm">
                    HEARTGARD® and the Dog &amp; Hand logo® are registered trademarks of Boehringer Ingelheim Animal Health USA Inc.
                    <em>NexGard</em>® is a registered trademark of Boehringer Ingelheim Animal Health France, used under license.
                    ©2023 Boehringer Ingelheim Animal Health USA Inc., Duluth, GA.
                    All rights reserved.
                    US-PET-0628-2023
            </div>
        </div>
    );
}