import React, {useState} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./PlaceRemoveHold.scss";
import Button from "components/common/Button";
import TextBox from "components/common/TextBox";
import replace from "lodash/replace";
import trim from "lodash/trim";

export default function PlaceRemoveHold(props) {
    const [formData, setFormData] = useState({});
    const [showInitials, setShowInitials] = useState(false);

    if(!props.canReleaseManufacturerAccountHold) {
        return null;
    }

    const handleChange = ({name, value}) => {
        setFormData({
            ...formData,
            [name]: value
        });
    }

    const changeUserInitials = ({name, value}) => {
        //Remove special characters (only letters allowed)
        const newValue = replace(value, /[^A-Z]/ig, "");
        handleChange({name, value: newValue});
    }

    const handleClearForm = () => {
        setFormData({});
        setShowInitials(false);
        props.onClose();
    }

    const handleSubmit = () => {
        // Remove the white space at the beginning and end of the "reason"
        const submitData = {
            ...formData,
            reason: trim(formData?.reason)
        }
        props.onSubmit(submitData);
    }

    return (
        <div data-testid="place_remove_hold_component">
            <TextBox
                data-testid="place_remove_hold_reason"
                onChange={handleChange}
                name="reason"
                value={formData.reason}
                textArea
                label="Reason"
                placeholder={`The reason for ${props.removeHold ? "removing" : "placing"} the hold`}
            />
            <div className="flex spaced-content justify-flex-end">
                <div className={classnames(styles.root, {
                    [styles.showingInitials]: showInitials,
                })}>
                    <div className={styles.cancelHolder}>
                        <Button
                            data-testid="place_remove_hold_cancel_button"
                            type="gray"
                            small
                            onClick={handleClearForm}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className={styles.buttonHolder}>
                        <Button
                            data-testid="place_remove_hold_button"
                            type="success"
                            superwide
                            className={styles.initialsBtn}
                            onClick={() => setShowInitials(true)}
                            disabled={!trim(formData?.reason)?.length}
                        >
                            {props.removeHold ? "Remove" : "Place"} Hold
                        </Button>
                        <div className={styles.initialsForm}>
                            <i className={classnames("fas fa-check-circle", {
                                [styles.valid]: !!formData?.userInitials?.length
                            })} />
                            <div className={styles.initials}>
                                <TextBox
                                    data-testid="place_remove_hold_user_initials"
                                    name="userInitials"
                                    value={formData?.userInitials}
                                    onChange={changeUserInitials}
                                    placeholder="Initials to certify"
                                />
                            </div>
                            <Button
                                data-testid="place_remove_hold_submit_button"
                                type="success"
                                onClick={handleSubmit}
                                disabled={!formData?.userInitials?.length}
                            >
                                {props.removeHold ? "Remove" : "Place"} Hold
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

PlaceRemoveHold.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    removeHold: PropTypes.bool,
    canReleaseManufacturerAccountHold: PropTypes.bool,
};
