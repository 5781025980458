import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import * as styles from "../wellness/widgets/ClinicWellnessPlans.scss";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import map from "lodash/map";
import merge from "lodash/merge";
import orderBy from "lodash/orderBy";
import * as ClinicActions from "actions/ClinicActions";
import * as MappingActions from "actions/MappingActions";
import AccessDenied from "components/common/AccessDenied";
import AddGreenlineWellnessPlanButton from "components/greenlineWellness/AddGreenlineWellnessPlanButton";
import Button from "components/common/Button";
import DateInput from "components/common/DateInput";
import Dropdown from "components/common/Dropdown";
import GreenlineWellnessPlanTable from "components/greenlineWellness/GreenlineWellnessPlanTable";
import ManageClinicPriceSchedule from "components/admin/forms/ManageClinicPriceSchedule";
import Modal from "components/common/Modal";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { getClinicInfo } from "utils/ClinicData";
import getLocalTimezone from "utils/getLocalTimezone";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import {GREENLINE_WELLNESS} from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";
import GreenlinePlanSetupFormContainer from "./GreenlinePlanSetupFormContainer";
import {updateWellnessStartDate} from "actions/ClinicActions";

function GreenlineWellnessPlans(props) {
    const [formData, setFormData] = useState({});
    const [showPlan, setShowPlan] = useState(null);
    const [showManagePriceSchedule, setShowManagePriceSchedule] = useState(false);
    const [selectedClinicPriceScheduleId, setSelectedClinicPriceScheduleId] = useState(null);
    const readOnly = !props.canEditClinicWellnessPlans;
    const TODAY = new moment();

    const groupOptions = useMemo(() => {
        return map((props.clinicWellnessPlans?.planGroups || []), pg => {
            return {name: pg.name, value: pg.name}
        })
    }, [props.clinicWellnessPlans.planGroups]);

    const defaultPricingSchedule = props.pricingSchedules ? (filter(props.pricingSchedules, schedule => {
        const startDate = moment(schedule.startDate);
        const endDate = moment(schedule.endDate);

        if (TODAY.isBetween(startDate, endDate)) {
            return schedule;
        }
    })[0] || {}) : {};

    const combinedData = cloneDeep(props.clinicWellnessPlans)

    merge(combinedData, formData);

    useEffect(() => {
        if (props.canViewClinicWellnessPlans) {
            props.loadClinicWellnessPlans(props.clinicId, GREENLINE_WELLNESS);
            props.getPIMs(props.clinicId);

            if (props.canViewClinicWellnessPriceSchedules && !props.pricingSchedules) {
                props.loadPricingSchedule(props.clinicId);
            }
        }
    }, [props.clinicId]);

    if(!props.canViewClinicWellnessPlans) {
        return <AccessDenied/>
    }

    const handleRowClick = (row) => {
        setShowPlan(row);
    };

    const handleChangeIsActive = (e, plan, {name, value}) => {
        e.stopPropagation();
        e.preventDefault();
        handleChange({name, value, plan});
    }

    const handleChange = ({name, value, plan=null}) => {
        let newData;
        if (!!plan) {
            newData = {
                ...formData,
                plans: {
                    ...formData.plans,
                    [plan.clinicPlanInstanceId]: {
                        ...plan,
                        hasBeenUpdated: true,
                        [name]: value,
                    },
                }
            }
        } else {
            newData = {
                ...formData,
                [name]: value,
            };
        }
        setFormData(newData);
    }

    const handleChangeWellnessStartDate = ({ name, value, plan=null }) => {
        if (props.canViewClinicWellnessPlans) {
            handleChange({name, value, plan});
            props.updateWellnessStartDate(props.clinicId, GREENLINE_WELLNESS, {
                wellnessStartDate: value,
                houseAccountClinicDvmId: combinedData.houseAccountClinicDvmId,
            });
        }
    };

    const handleChangeHouseAccount = ({ name, value, plan=null }) => {
        handleChange({name, value, plan});
        if (props.canEditClinicWellnessPlans) {
            props.updateHouseAccount(props.clinicId, value, GREENLINE_WELLNESS);
        }
    }

    const onPlanSaved = () => {
        props.loadClinicWellnessPlans(props.clinicId, GREENLINE_WELLNESS);
        setShowPlan(null);
    }

    const PRICE_SCHEDULE_OPTIONS = props.pricingSchedules ? map( props.pricingSchedules, (price) => {
        return {name: `${price.name} (${getLocalTimezone(price.startDate)} - ${getLocalTimezone(price.endDate)}) ${defaultPricingSchedule.clinicPriceScheduleId === price.clinicPriceScheduleId ? "[CURRENT]" : ""}`, value: price.clinicPriceScheduleId};
    }) : [];

    const HOUSE_ACCOUNT_OPTIONS = combinedData.houseAccounts ? orderBy(map(combinedData.houseAccounts, account => {
        return {
            name: `${account.firstName} ${account.lastName} [${account.displayId}] ${!account.isActive ? "(INACTIVE)" : ""}`,
            value: account.id,
            isActive: account.isActive,
            firstName: account.firstName
        };
    }), ["isActive", "firstName"], ["desc", "asc"]) : [];

    return (
        <div className={styles.root}>
            <DateInput
                name="wellnessStartDate"
                value={combinedData.wellnessStartDate ? moment(combinedData.wellnessStartDate) : null}
                label="Wellness Start Date"
                dateFormat={"MM/DD/YYYY"}
                information="This is the date the clinic started processing wellness. If not set, this will get set to the Go Green date."
                onChange={handleChangeWellnessStartDate}
                disabled={readOnly}
            />
            <Dropdown
                options={HOUSE_ACCOUNT_OPTIONS}
                name="houseAccountClinicDvmId"
                value={combinedData.houseAccountClinicDvmId}
                label="House Account"
                placeholder="--Select House Account--"
                onChange={handleChangeHouseAccount}
                disabled={readOnly}
                hidePlaceholder
            />
            {props.canViewClinicWellnessPriceSchedules && (
                <div className="flex spaced-content align-bottom">
                    <div className="flex-1">
                        <Dropdown
                            options={PRICE_SCHEDULE_OPTIONS}
                            name="clinicPriceScheduleId"
                            value={selectedClinicPriceScheduleId || defaultPricingSchedule.clinicPriceScheduleId}
                            label="Pricing Schedule"
                            onChange={({value}) => setSelectedClinicPriceScheduleId(value)}
                            placeholder="--Select Pricing Schedule--"
                        />
                    </div>
                    {props.canEditClinicWellnessPriceSchedules && (
                        <div>
                            <Button
                                type="primary"
                                onClick={() => setShowManagePriceSchedule(true)}
                            >
                                Manage
                            </Button>
                        </div>
                    )}
                </div>
            )}
            <hr className="margin-top-md margin-bottom-sm"/>
            {(selectedClinicPriceScheduleId || defaultPricingSchedule.clinicPriceScheduleId) && (
                <div className="padding-bottom-sm">
                    <AddGreenlineWellnessPlanButton
                        clinicId={props.clinicId}
                        onPlanSaved={onPlanSaved}
                        clinicPriceScheduleId={selectedClinicPriceScheduleId || defaultPricingSchedule.clinicPriceScheduleId}
                    />
                </div>
            )}
            <GreenlineWellnessPlanTable
                data={
                    orderBy(filter(combinedData?.plans, plan => {
                        return (selectedClinicPriceScheduleId ? (plan.clinicPriceScheduleId === selectedClinicPriceScheduleId) : (plan.clinicPriceScheduleId === defaultPricingSchedule.clinicPriceScheduleId));
                    }), ["displayOrder"])
                }
                onRowClick={handleRowClick}
                changeIsActive={handleChangeIsActive}
            />
            <Modal
                show={!!showPlan}
                onClose={() => setShowPlan(null)}
                mediumSmall
                modalTitle={`Edit ${!!(props.clinic && props.clinic.name) ? props.clinic.name : ""} Plan`}
            >
                <GreenlinePlanSetupFormContainer
                    selectedPlan={showPlan}
                    clinicId={props.clinicId}
                    onAfterSave={onPlanSaved}
                    readOnly={readOnly}
                    wellnessStartDate={combinedData.wellnessStartDate ? moment(combinedData.wellnessStartDate) : null}
                    houseAccountClinicDvmId={combinedData.houseAccountClinicDvmId}
                    groupOptions={groupOptions}
                />
            </Modal>
            <Modal
                show={showManagePriceSchedule}
                onClose={() => setShowManagePriceSchedule(false)}
                mediumSmall
                modalTitle={`Edit ${!!(props.clinic && props.clinic.name) ? props.clinic.name : ""} Pricing Schedules`}
            >
                {!props.canEditClinicWellnessPriceSchedules ? (
                    <AccessDenied/>
                ) : (
                    <ManageClinicPriceSchedule
                        clinicId={props.clinicId}
                        onCancel={() => setShowManagePriceSchedule(false)}
                    />
                )}
            </Modal>
            <SpinnerTakeover show={props.loading}/>
        </div>
    );
}

GreenlineWellnessPlans.propTypes = {
    clinicId: PropTypes.number.isRequired,
};

export default connect(
    (state, ownProps) => {
        const clinicInfo = getClinicInfo(state, ownProps.clinicId);
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canEditClinicWellnessPlans = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewProductMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);
        const canViewClinicWellnessPriceSchedules = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_PRICE_SCHEDULES, userProfile);
        const canEditClinicWellnessPriceSchedules = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_PRICE_SCHEDULES, userProfile);

        return {
            canViewClinicWellnessPlans,
            canEditClinicWellnessPlans,
            canViewProductMapping,
            canViewClinicWellnessPriceSchedules,
            canEditClinicWellnessPriceSchedules,
            clinic: clinicInfo,
            pricingSchedules: state.entities.pricingSchedules[ownProps.clinicId],
            clinicWellnessPlans: state.entities.clinicWellnessPlanInstances[ownProps.clinicId] || {},
            loading: state.entities.clinicWellnessPlanInstances.loading || false
        };
    },
    (dispatch) => ({
        getPIMs: (clinicId) => dispatch(MappingActions.getPIMs(clinicId)),
        loadPricingSchedule: (clinicId) => dispatch(ClinicActions.loadPricingSchedule(clinicId)),
        loadClinicWellnessPlans: (clinicId, providerId) => dispatch(ClinicActions.loadClinicWellnessPlanInstances(clinicId, providerId)),
        updateHouseAccount: (clinicId, houseAccountClinicDvmId, providerId) => dispatch(ClinicActions.updateHouseAccount(clinicId, houseAccountClinicDvmId, providerId)),
        updateWellnessStartDate: (clinicId, providerId, data) => dispatch(ClinicActions.updateWellnessStartDate(clinicId, providerId, data)),
        addedClinicWellnessPlan: (clinicId, plan) => dispatch(ClinicActions.addedClinicWellnessPlan(clinicId, plan))
    })
)(GreenlineWellnessPlans);
