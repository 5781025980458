import React from "react";
import PropTypes from "prop-types";
import {autobind} from "core-decorators";
import classnames from "classnames";
import styles from "./ClinicSelect.scss";
import map from "lodash/map";
import logger from "utils/logger";

class ClinicSelect extends React.Component {
    state = {
        open: false,
    }

    @autobind
    toggleMenu(e) {
        if(!this.state.open) {
            logger.log("ADD WINDOW LISTENER");
            window.addEventListener("click", this.toggleMenu);
        } else {
            logger.log("REMOVE WINDOW LISTENER");
            window.removeEventListener("click", this.toggleMenu);
        }
        e.stopPropagation();
        this.setState({open: !this.state.open});
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.toggleMenu);
    }

    render() {
        const {clinics, clinicInfo} = this.props;
        const hasMultipleClinics = clinics?.length > 1;
        return (
            <div
                data-testid="clinic_select"
                className={classnames(styles.root, "flex flex-centered", {
                    "pointer": hasMultipleClinics,
                    [styles.menuVisible]: this.state.open,
                    // Test styles only
                    "test-menu-visible": this.state.open,
                })}
            >
                <div
                    data-testid="show_clinic_select"
                    className="flex flex-centered"
                    onClick={(e) => hasMultipleClinics ? this.toggleMenu(e) : {}}
                >
                    <div
                        data-testid="clinic_name"
                        className="flex-1"
                    >
                        {clinicInfo.name}
                    </div>
                    {hasMultipleClinics && (
                        <div className={styles.icon}>
                            <i className="fa fa-caret-down" />
                        </div>
                    )}
                </div>
                {hasMultipleClinics && (
                    <div
                        data-testid="flyout_container"
                        className={styles.flyoutContainer}
                    >
                        <div className={styles.clinics}>
                            {map(this.props.clinics, clinic => (
                                <div
                                    data-testid={`clinic_${clinic.id}`}
                                    key={clinic.id}
                                    className={classnames("padding-sm", {
                                        [styles.selected]: (Number(clinic.id) === clinicInfo.id)
                                    })}
                                    onClick={() => this.props.onChangeClinic(clinic.id)}
                                >
                                    {clinic.name}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

ClinicSelect.propTypes = {
    clinicInfo: PropTypes.object.isRequired,
    onChangeClinic: PropTypes.func.isRequired,
    clinics: PropTypes.arrayOf(PropTypes.object),
};

export default ClinicSelect;
