export const HANDOUT = "handout";
export const CLIENT_FORM = "form";
export const CHART = "chart";


export const contentTypes = [HANDOUT, CLIENT_FORM, CHART];

export const contentTypeTitles = {
    [HANDOUT]: "Handout",
    [CLIENT_FORM]: " Client Form" ,
    [CHART]: "Chart"
}