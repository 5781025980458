import React from "react";

export const getLinkLocation = (link, { providerId, nodeId, isAdmin, clinicId, isDemo, gameId, templateId }, withLocation=null) => {
    const params = !!withLocation ? withLocation : "";

    let providerRoot = `/`;
    if (!!isAdmin) {
        providerRoot = `/admin/provider/${providerId}/location/${nodeId}${!!isDemo ? "/demo" : ""}`;
    } else if (nodeId) {
        providerRoot = `/provider/location/${nodeId}${!!isDemo ? "/demo" : ""}`;
    }

    switch (link) {
        case "dashboard":
            return `${providerRoot}${params}`;
        case "clinics":
            return `${providerRoot}/clinics${params}`;
        case "clinic-coupon-library":
            return `${providerRoot}/clinic/${clinicId}/library/${providerId}${params}`;
        case "clinic-settings":
            return `${providerRoot}/clinic/${clinicId}/wellness/settings${params}`;
        case "resources":
            return `${providerRoot}/resources${params}`;
        case "settings":
            return `${providerRoot}/settings${params}`;
        case "game":
            return `${providerRoot}/games/${gameId}${params}`;
        case "clinic-games":
            return `${providerRoot}/clinic/${clinicId}/games${params}`;
        case "clinic-games-history":
            return `${providerRoot}/clinic/${clinicId}/games/history${params}`;
        case "demo-clinic-games":
            return `${providerRoot}/games/clinic-games${params}`;
        case "demo-clinic-games-history":
            return `${providerRoot}/games/clinic-games/history${params}`;
        case "games":
            return `${providerRoot}/games${params}`;
        case "game-copy":
            return `${providerRoot}/games/copy/${templateId}${params}`;
        case "game-template":
            return `${providerRoot}/games/templates/${templateId}${params}`;
        case "game-user-template":
            return `${providerRoot}/games/user-templates/${templateId}${params}`;
        case "game-invites":
            return `${providerRoot}/games/invites${params}`;
        case "couponLibrary":
            return `${providerRoot}/coupons/library${params}`;
        case "libraryProgram":
            return `${providerRoot}/coupons/library/${providerId}${params}`;
        case "wellnessAudit":
            return `${providerRoot}/wellness/audit${params}`;
        case "wellnessHistory":
            return `${providerRoot}/wellness/history${params}`;
        case "petPlanTracking":
            return `${providerRoot}/wellness/patients${params}`;
        case "wellnessPlanSetup":
            return `${providerRoot}/wellness/plans${params}`;
        case "wellnessDVM":
            return `${providerRoot}/setup/wellness/commission/dvm${params}`;
        case "mapping":
        case "wellnessMapping":
            return `${providerRoot}/mapping${params}`;
        case "par":
            return `${providerRoot}/par${params}`;
        case "pimsUsers":
            return `${providerRoot}/pimsusers${params}`;
        case "programsDashboard":
            return `${providerRoot}/programs-dashboard${params}`;
        case "providerSetup":
            return `${providerRoot}/provider/setup${params}`;
        case "productTags":
            return `${providerRoot}/products/tags${params}`;
        case "productOverride":
            return `${providerRoot}/product/overrides${params}`;
        case "invoiceLineItems":
            return `${providerRoot}/reports/invoice-line-items${params}`;
        case "cumulativeOfferTracking":
            return `${providerRoot}/reports/cumulative-offer-tracking${params}`;
        case "corporate-group":
            return `${providerRoot}/corporate-group${params}`;
        case "wellness-settings":
            return `${providerRoot}/clinic/${params}/wellness/settings${params}`;
        default:
            return "";
    }
}

export const getClinicAdminLinkLocation = (link) => {
    switch (link) {
        case "wellnessPlanSetup":
            return "/setup/wellness/plans";
        case "wellnessDVM":
            return "/setup/wellness/commission/dvm";
        case "mapping":
        case "wellnessMapping":
            return "/setup/mapping";
        default:
            return "";
    }
}

export const getLocationLinkMatch = (link, clinic, path) => {
    return getLinkLocation(link, clinic) === path;
}
