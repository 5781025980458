import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as Yup from "yup";
import "yup-phone-lite";
import includes from "lodash/includes";
import flatMap from "lodash/flatMap";
import intersection from "lodash/intersection";
import keys from "lodash/keys";
import map from "lodash/map";
import useFormHandlers from "utils/useFormHandlers";
import * as ClinicActions from "actions/ClinicActions";
import * as NotificationsActions from "actions/NotificationsActions";
import * as UserActions from "actions/UserActions";
import Button from "components/common/Button";
import ClientAddressFields from "components/greenlineWellness/widgets/WellnessEnrollmentModal/ClientAddressFields";
import Dropdown from "components/common/Dropdown";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import * as ClinicData from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { PENDING } from "constants/Notifications";
import * as UserPermissions from "constants/UserPermissions";

const schema = Yup.object().shape({
    clinicId: Yup.number().required(),
    city: Yup.string().required(),
    clinicAdminUserId: Yup.number().nullable(true),
    contactPhone: Yup.string().phone(["US", "CA"]).required(),
    contactEmail: Yup.string().email().required(),
    state: Yup.string().required(),
    street1: Yup.string().required(),
    street2: Yup.string(),
    zipCode: Yup.string().required(),

    // These fields determine if the form can be submitted and which parts of the form are correct
    adminCorrect: Yup.bool().required(),
    phoneCorrect: Yup.bool().required(),
    emailCorrect: Yup.bool().required(),
    addressCorrect: Yup.bool().required(),
    providerCorrect: Yup.bool().required(),
});

function ClinicInformationUpdate(props) {
    const { changedData, formData, handleUpdate, isValid, invalidFields } = useFormHandlers(
        props.clinicInfo,
        schema
    );
    const [loading, setLoading] = useState(false);
    const readOnly = !props.canEditClinicInfo || props.notification.enrollmentState !== PENDING;
    const changedFields = keys(changedData);
    const canEditClinic = (props.canEditClinicManagement && props.canViewUserManagement);

    const btnEnabled = (
        isValid &&
        // Check Admin correct or has changes
        (formData.adminCorrect || includes(changedFields, "clinicAdminUserId")) || !canEditClinic &&
        // Check phone number correct or has changes
        (formData.phoneCorrect || includes(changedFields, "contactPhone")) &&
        // Check email correct or has changes
        (formData.emailCorrect || includes(changedFields, "contactEmail")) &&
        // Check address correct or has changes
        (
            formData.addressCorrect ||
            includes(changedFields, "street1") ||
            includes(changedFields, "street2") ||
            includes(changedFields, "city") ||
            includes(changedFields, "state") ||
            includes(changedFields, "zipCode")
        )
    );

    useEffect(() => {
        if (!readOnly && props.clinicId && props.canViewUserManagement) {
            props.loadClinicUsers(props.clinicId);
        }
    }, [props.clinicId, props.canViewUserManagement]);

    if (!props.canViewClinicInfo) {
        return (
            <>
                <div className="margin-bottom-md" dangerouslySetInnerHTML={{ __html: props.notificationDetails.longDescription }} />
                <div className="margin-bottom-md">You do not have sufficient permission to view clinic settings.</div>
            </>
        )
    }

    const CLINIC_ADMIN_OPTIONS = map(props.clinicUsers, u => {return {name: `${u.firstName} ${u.lastName} (${u.userName})`, value: u.userId}});

    const handleMarkCorrect = (name, relatedFields) => {
        const revertedFields = map(relatedFields, field => ({
            name: field,
            setToInitialState: true,
        }));
        revertedFields.push({ name, value: true});
        handleUpdate(revertedFields);
    };

    const getButtons = (name, relatedFields=[]) => (
        <div
            key={name}
            className="flex-none flex spaced-content margin-top-md"
        >
            <Button
                type="success"
                text
                onClick={() => handleMarkCorrect(name, relatedFields)}
                sleeping={formData?.[name] === false}
                disabled={readOnly}
            >
                <i className="far fa-2x fa-check-circle" />
            </Button>
            <Button
                type="danger"
                text
                onClick={() => handleUpdate({ name, value: false })}
                sleeping={!!formData?.[name]}
                disabled={readOnly}
            >
                <i className="far fa-2x fa-times-circle" />
            </Button>
        </div>
    );

    const BtnText = () => {
        if (formData.phoneCorrect === false || formData.emailCorrect === false || formData.addressCorrect === false) {
            if (formData.adminCorrect === false ||  formData.providerCorrect === false) {
                return "Update Clinic and Notify Greenline";
            }
            return "Update Clinic Info";
        } else if(formData.adminCorrect === false || formData.providerCorrect === false) {
            return "Notify Greenline";
        }
        return "Mark Information as Correct";
    };

    const notifyGreenline = () => {
        props.notifyGreenline({
            clinicId: props.clinicInfo.clinicId,
            adminUserId: formData.clinicAdminUserId,
            providerIds: flatMap(props.clinicInfo?.groupMemberShipFromTags, "providerId") || [],
        });
    };

    const saveChanges = () => {
        if (props.canEditClinicManagement) {
            props.adminUpdateClinicSettings(formData);
        } else {
            props.updateClinicSettings(formData);
        }
    };

    const handleSubmit = async (e) => {
        e.stopPropagation();
        e.preventDefault();
        setLoading(true);
        if (!(formData.adminCorrect && formData.providerCorrect)) {
            notifyGreenline();
        }
        if (!(formData.phoneCorrect && formData.emailCorrect && formData.addressCorrect)) {
            saveChanges();
        }
        props.onEnroll();
        setLoading(false);
    };

    const corporateStatusInfo = 'This field is populated with information previously stored on your account. To request changes, please click the "X" to notify Greenline.';

    const addressErrors = useMemo(() => {
        const addressFields = ["city", "state", "street1", "street2", "zipCode"];
        if (formData.addressCorrect === false) {
            const matchingFields = intersection(addressFields, changedFields);
            if (!matchingFields.length) {
                return [...invalidFields, ...addressFields];
            }
        }

        return invalidFields;
    }, [changedFields, formData, invalidFields]);

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className="border-bottom">
                    <div className="margin-bottom-md" dangerouslySetInnerHTML={{ __html: props.notificationDetails.longDescription }} />
                    <div className="margin-bottom-md">
                        Please review the form below and update accordingly.
                    </div>
                    <hr />
                    <div className="margin-bottom-sm">
                        <div className="margin-top-sm">
                            <div className="flex spaced-content align-center">
                                <div className="flex-1">
                                    {canEditClinic ? (
                                        <Dropdown
                                            disabled={readOnly || formData.adminCorrect !== false}
                                            hasError={includes(invalidFields, "clinicAdminUserId") || (formData.adminCorrect === false && !includes(changedFields, "clinicAdminUserId"))}
                                            label="(Designated) Clinic Admin"
                                            name="clinicAdminUserId"
                                            onChange={handleUpdate}
                                            options={CLINIC_ADMIN_OPTIONS}
                                            placeholder="- None - "
                                            value={formData.clinicAdminUserId}
                                        />
                                    ) : (
                                        <TextBox
                                            label="(Designated) Clinic Admin"
                                            onChange={() => {}}
                                            disabled
                                            name="clinicAdmin"
                                            value={props.clinicInfo?.clinicAdminFirstName || props.clinicInfo?.clinicAdminLastName ? `${props.clinicInfo?.clinicAdminFirstName} ${props.clinicInfo?.clinicAdminLastName}` : "None"}
                                            information={corporateStatusInfo}
                                        />
                                    )}
                                </div>
                                {!readOnly && getButtons("adminCorrect", ["clinicAdminUserId"])}
                            </div>
                            <div className="flex spaced-content">
                                <TextBox
                                    disabled={readOnly || formData.phoneCorrect !== false}
                                    hasError={includes(invalidFields, "contactPhone") || (formData.phoneCorrect === false && !includes(changedFields, "contactPhone"))}
                                    label="Clinic Phone Number"
                                    name="contactPhone"
                                    onChange={handleUpdate}
                                    value={formData.contactPhone}
                                />
                                {!readOnly && getButtons("phoneCorrect", ["contactPhone"])}
                            </div>
                            <div className="flex spaced-content">
                                <TextBox
                                    disabled={readOnly || formData.emailCorrect !== false}
                                    hasError={includes(invalidFields, "contactEmail") || (formData.emailCorrect === false && !includes(changedFields, "contactEmail"))}
                                    label="Clinic Email Address"
                                    name="contactEmail"
                                    onChange={handleUpdate}
                                    value={formData.contactEmail}
                                />
                                {!readOnly && getButtons("emailCorrect", ["contactEmail"])}
                            </div>
                            <div className="flex spaced-content">
                                <div className="flex-1">
                                    {!keys(props.clinicInfo?.groupMemberShipFromTags)?.length ? (
                                        <TextBox
                                            disabled
                                            information={corporateStatusInfo}
                                            label="Clinic Corporate Status"
                                            name="group"
                                            onChange={() => {}}
                                            value="None"
                                        />
                                    ) : map(props.clinicInfo.groupMemberShipFromTags, group => (
                                        <TextBox
                                            disabled
                                            information={corporateStatusInfo}
                                            label="Clinic Corporate Status"
                                            name="group"
                                            onChange={() => {}}
                                            value={`${group.providerAbbreviation} (${group.providerName})`}
                                        />
                                    ))}
                                </div>
                                {!readOnly && getButtons("providerCorrect")}
                            </div>
                            <div className="margin-top-md flex spaced-content">
                                <div className="flex-1">
                                    <ClientAddressFields
                                        initialData={formData}
                                        onChange={handleUpdate}
                                        // readOnly={}
                                        errors={addressErrors}
                                        useState
                                        useZipCode
                                        readOnly={readOnly || formData.addressCorrect !== false}
                                    />
                                </div>
                                {!readOnly && getButtons("addressCorrect", ["street1", "street2", "city", "state", "zipCode"])}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex margin-top-md margin-bottom-md justify-flex-end">
                    <Button
                        buttonType="submit"
                        onClick={() => {}}
                        disabled={!btnEnabled}
                    >
                        {BtnText()}
                    </Button>
                </div>
                <SpinnerTakeover show={loading} />
            </form>
        </>
    )
}

ClinicInformationUpdate.propTypes = {
    notification: PropTypes.object,
    onEnroll: PropTypes.func.isRequired,
    onDismiss: PropTypes.func,
    isPreview: PropTypes.bool,
    clinicId: PropTypes.number.isRequired
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const clinicInfo = ClinicData.getClinicInfo(state, ownProps.clinicId);

        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canViewClinicInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_INFO, userProfile);
        const canEditClinicInfo = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_INFO, userProfile);
        return {
            userProfile,
            clinicInfo,
            canEditClinicManagement,
            canViewUserManagement,
            canViewClinicInfo,
            canEditClinicInfo,
            clinicUsers: state.entities.clinicUsers,
        }
    },
    (dispatch) => ({
        loadClinicUsers: (clinicId) => dispatch(ClinicActions.getClinicUsers(clinicId)),
        adminUpdateClinicSettings: (clinicSettings) => dispatch(ClinicActions.adminUpdateClinicSettings(clinicSettings)),
        updateClinicSettings: (clinicSettings) => dispatch(ClinicActions.updateClinicSettings(clinicSettings)),
        updateUserSelf: (data) => dispatch(UserActions.updateUserSelf(data)),
        notifyGreenline: (data) => dispatch(NotificationsActions.notifyGreenline(data)),
        setNotificationState: (notificationId, clinicId, newState) => dispatch(NotificationsActions.setNotificationState(notificationId, clinicId, newState)),
    })
)(ClinicInformationUpdate);
