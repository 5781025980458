import { GAME_COMPARISON_PERIODS } from "constants/GameComparisonPeriods";

export default function getNameForGameComparisonPeriod(period) {
    switch (period) {
        case GAME_COMPARISON_PERIODS.YOY:
            return "Year-over-Year";
        case GAME_COMPARISON_PERIODS.ROLLING:
            return "Rolling Period";
    }

    return period;
}