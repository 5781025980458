import React, { useContext, useEffect, useMemo, useState } from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Toolbar.scss";
import * as ClinicActions from "actions/ClinicActions";
import * as UserActions from "actions/UserActions";
import {windowSizeContext} from "AppRoot";
import Button from "components/common/Button";
import ClinicTagsList from "components/admin/elements/ClinicTagsList";
import NavSearchBox from "components/layout/widgets/NavSearchBox";
import ToolbarAddTool from "components/common/Toolbar/ToolBarAddTool";
import ToolbarBackButtons from "components/common/Toolbar/ToolbarBackButtons";
import ToolbarClinicSearch from "components/common/Toolbar/ToolbarClinicSearch";
import ToolbarClinicLinks from "components/common/Toolbar/ToolbarClinicLinks";
import ToolbarProviderSearch from "components/common/Toolbar/ToolbarProviderSearch";
import VoucherManagementTools from "components/admin/widgets/VoucherManagementTools";
import ProgramWelcomeModal from "components/clinic/widgets/ProgramWelcomeModal";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as AdminControls from "constants/AdminControls";
import * as UserPermissions from "constants/UserPermissions";

function Toolbar(props) {
    const [showSearch, setShowSearch] = useState(false);
    const context = useContext(windowSizeContext) || {};

    const searchConfig = useMemo (() => {
        switch (props.controls) {
            case AdminControls.CLINIC_TAGS:
                // Don't show the search unless it is on a phone
                if(context.isPhone) {
                  return props.searchConfig;
                }
                return null;
            default:
                return props.searchConfig;
        }
    }, [props.controls, props.searchConfig, context.isPhone]);

    const collapsableMobileSearch = useMemo (() => {
        switch (props.controls) {
            case AdminControls.CLINIC_MANAGEMENT:
            case AdminControls.USER_MANAGEMENT:
            case AdminControls.REPROCESSING_JOBS:
            case AdminControls.NOTIFICATION_MANAGEMENT:
            case AdminControls.VOUCHER_MANAGEMENT:
            case AdminControls.ROLE_MANAGEMENT:
                return false;
            default:
                return true;
        }
    }, [props.controls, props.searchConfig]);

    const showAdminSearch = searchConfig && !props.clinicId;

    useEffect(() => {
        // Clear the search results if the controls or the clinicId changes, and the search bar is not shown.
        if(!showAdminSearch) {
            props.clearAllSearches();
        }
    }, [props.controls, props.clinicId]);

    useEffect(() => {
        if(props.clinicId && props.canViewClinicManagement && !(props.clinic)) {
            props.getClinicById(props.clinicId);
        }
    }, [props.canViewClinicManagement, props.clinicId]);

    const getAddButtonLabel = () => {
        switch (props.controls) {
            case AdminControls.REPROCESSING_JOBS:
                return "New Job";
            case AdminControls.USER_MANAGEMENT:
                return "User";
            default:
                return null;
        }
    }

    const adminControls = useMemo (() => {
        switch (props.controls) {
            case AdminControls.CLINIC_TAGS:
                if(!context.isPhone) {
                    return (<ClinicTagsList clinicId={props.clinicId}/>)
                } else return null;
            case AdminControls.VOUCHER_MANAGEMENT:
                if(props.canViewVoucherManagement) {
                    return <VoucherManagementTools/>
                } else return null;
            case AdminControls.PRODUCT_OVERRIDES:
            case AdminControls.USER_MANAGEMENT:
            case AdminControls.REPROCESSING_JOBS:
            case AdminControls.NOTIFICATION_MANAGEMENT:
                case AdminControls.ROLE_MANAGEMENT:
                return <ToolbarAddTool searchConfig={props.searchConfig} label={getAddButtonLabel()} />
            default:
                return null;
        }
    }, [props.controls, props.searchConfig, context.isPhone]);

    const toggleSearch = () => {
        setShowSearch(!showSearch);
    }

    return (
        <div className={styles.root}>
            <div className={styles.backButtons}>
                <ToolbarBackButtons
                    nodeId={props.nodeId}
                    hasClinicId={!!props.clinicId}
                    isAdmin={!!props.isAdmin}
                    isClinic={props.isClinic}
                    isProvider={props.isProvider}
                />
            </div>
            {(props.isClinic || props.clinicId) && (
                <div className={styles.clinicSearch}>
                    <ToolbarClinicSearch
                        clinicId={props.clinicId}
                        nodeId={props.nodeId}
                        isAdmin={!!props.clinicId}
                    />
                </div>
            )}
            {(props.isProvider || props.nodeId) && (
                <div className={styles.providerSearch}>
                    <ToolbarProviderSearch
                        nodeId={props.nodeId}
                        searchConfig={props.searchConfig}
                    />
                </div>
            )}
            {(searchConfig && collapsableMobileSearch) && (
                <div className={styles.searchToggle}>
                    <Button
                        onClick={toggleSearch}
                        type="circleIcon"
                        iconOnly
                    >
                        {showSearch ? <i className="fas fa-times" style={{padding: ".0 .2em"}}/> : <i className="fas fa-search" />}
                    </Button>
                </div>
            )}
            {(props.isClinic || props.clinicId) && (
                <ToolbarClinicLinks
                    clinicId={props.clinicId}
                    nodeId={props.nodeId}
                    isAdmin={!!props.clinicId}
                />
            )}
            {!!searchConfig && (
                <>
                    <div className={classnames(styles.searchBox, {
                        [styles.showingSearch]: showSearch,
                        [styles.collapsable]: collapsableMobileSearch,
                    })}>
                        <NavSearchBox
                            searchDisabled={props.searchDisabled}
                            searchConfig={searchConfig}
                            clinicId={props.clinicId}
                            inputType="text"
                            autoFocus={showSearch}
                            toolbar
                            collapsable={collapsableMobileSearch}
                        />
                        <ProgramWelcomeModal clinicId={props.clinicId} />
                    </div>
                </>
            )}
            {!!adminControls && (
                <div className={styles.controls}>
                    {adminControls}
                </div>
            )}
        </div>
    );
}

Toolbar.propTypes = {
    clinicId: PropTypes.number,
    nodeId: PropTypes.number,
    searchConfig: PropTypes.object,
    searchDisabled: PropTypes.bool,
    controls: PropTypes.string,
    isClinic: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isProvider: PropTypes.bool,
};

const connector = connect(
    (state) => {
        const userProfile = state.user.userProfile;
        // PERMISSIONS
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canViewVoucherManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.VOUCHER_MANAGEMENT, userProfile);
        return {
            voucherPlan: state.entities.adminTools.voucherPlan,
            // PERMISSIONS
            canViewClinicManagement,
            canViewVoucherManagement
        }
    },
    (dispatch) => ({
        //For Clearing all search bars
        clearAllSearches: () => dispatch(UserActions.clearAllSearches()),
        getClinicById: (clinicId) => dispatch(ClinicActions.getClinicById(clinicId)),
    })
);

export default compose(
    withRouter,
    connector
)(Toolbar);
