import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import produce from "immer";
import styles from "./PaymentSummary.scss";
import flatMap from "lodash/flatMap";
import map from "lodash/map";
import sum from "lodash/sum";
import reduce from "lodash/reduce";
import values from "lodash/values";
import * as WellnessApi from "api/WellnessApi";
import CheckboxInput from "components/common/CheckboxInput";
import PaymentsHeader from "components/greenlineWellness/widgets/PaymentsHeader";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { formatUSD } from "utils/numeric";
import { handleErrorResponse } from "utils/request";
import Button from "components/common/Button";

export default function PaymentSummary(props) {
    const [paymentOptions, setPaymentOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [stripeCustomerId, setStripeCustomerId] = useState("");

    const getCustomerCards = async (stripeCustomerId) => {
        setIsLoading(true);

        try {
            const res = await WellnessApi.getWellnessCards(props.clinicId, stripeCustomerId);

            setPaymentOptions(res.body);
            setIsLoading(false);
        } catch (error) {
            handleErrorResponse("retrieving payment options", error);
            setIsLoading(false);
        }
    }

    const getCustomerStripeId = async () => {
        try {
            const resp = await WellnessApi.getStripeCustomerId(props.clinicId, props.petOwnerId)
            if(resp.statusCode === 200) {
                setStripeCustomerId(resp.body)
                getCustomerCards(resp.body)
            }
        }
        catch(error) {
            handleErrorResponse('retrieving customer', error)
        }
    };

    useEffect(() => {
        if(props.clinicId && props.petOwnerId) {
            getCustomerStripeId();
        }
    }, [props.clinicId])

    const updateDefaultCard = (cardId) => {
        const update = produce(paymentOptions, (updatedOptions) => {
            return map(updatedOptions, opt => ({
                ...opt,
                isDefault: opt.id === cardId,
            }));
        });
        setPaymentOptions(update);
    }

    const calculateAddOns = (addOnField="monthlyAddOnCostToClient") => {
        let addOnTotal = 0;
        map(values(props.addOns), planAddOns => {
            addOnTotal += sum(flatMap(planAddOns, addOnField));
        });
        return addOnTotal;
    };


    const calculateMonthlyTotal = () => {
        const planTotals = sum(flatMap(props.plans, p => p.monthlyCostToClient || 0));
        const addOnTotal = calculateAddOns("monthlyAddOnCostToClient");
        return (planTotals + addOnTotal) || 0
    };

    const calculateYearlyTotal = () => {
        const planTotals = sum(flatMap(props.plans, p => p.yearlyCostToClient || 0));
        const addOnTotal = calculateAddOns("yearlyAddOnCostToClient");
        return (planTotals + addOnTotal) || 0;
    };

    const handleCreateSubscription = (token, createPaymentMethod=false) => {
        setIsLoading(true);
        props.onSubmit(token, createPaymentMethod);
    }

    const monthlyTotal = calculateMonthlyTotal();
    const yearlyTotal = calculateYearlyTotal();

    return (
        <div>
            <h2>Enrollment Summary</h2>
            <div className="flex flex-wrap spaced-content">
                {map(props.patients, (patient) => {
                    const id = patient.patientId || patient.remoteId;
                    const selectedPlan = props.plans[id];
                    const planName = selectedPlan?.clinicPlanDto?.alias || selectedPlan?.name || "Unknown";
                    return (
                        <div
                            key={id}
                            className={styles.selectedPatient}
                        >
                            <h3>{patient.patientName}</h3>
                            <div className="margin-left-x-sm">Plan Name - {planName}</div>
                            <div className="margin-left-x-sm">Plan Cost - {selectedPlan.monthlyCostToClient ? formatUSD(selectedPlan.monthlyCostToClient) : "Free"}</div>
                            <h4>Selected Add-Ons:</h4>
                            {map(props.addOns[id], (addOn) => (
                                <div
                                    key={`${id}-${addOn.clinicPlanServiceTypeId}`}
                                    className="margin-left-x-sm"
                                >
                                    {addOn.serviceTypeName} - {addOn.monthlyAddOnCostToClient ? formatUSD(addOn.monthlyAddOnCostToClient) : "Free"}
                                </div>
                            ))}
                        </div>
                    );
                })}

            </div>
            <h2>Payment Options</h2>
            <CheckboxInput
                checked={!props.isAnnual}
                circle
                label={`Monthly - ${monthlyTotal ? formatUSD(monthlyTotal) : "Free"}`}
                large
                name="isMonthly"
                onChange={() => props.onChange({ name: "isAnnual", value: false })}
            />
            <CheckboxInput
                checked={props.isAnnual}
                circle
                label={`Annually - ${yearlyTotal ? formatUSD(yearlyTotal) : "Free"}`}
                large
                name="isAnnually"
                onChange={() => props.onChange({ name: "isAnnual", value: true })}
            />
            <div className={styles.stripe}>
                <h2>Payment Information</h2>
                <PaymentsHeader
                    clinicId={props.clinicId}
                    cards={paymentOptions}
                    getCards={getCustomerCards}
                    setIsLoading={(isLoading) => setIsLoading(isLoading)}
                    onDefaultCardUpdated={(cardId) => updateDefaultCard(cardId)}
                    canEditPaymentSettings={true}
                    paymentMethodsChanged={() => {}}
                    userId={stripeCustomerId}
                    result
                    onCardCreated={(token) => handleCreateSubscription(token, true)}
                    showAddWhenNoCards
                    addCardBtnText="Add Card and Subscribe"
                    showConfirmSelection
                    onConfirmSelection={handleCreateSubscription}
                    confirmSelectionBtnText="Use Default Card and Subscribe"
                />
            </div>
            <div className="flex-1">
                <Button type="primary" onClick={props.onBack}>
                    Back
                </Button>
            </div>
            <SpinnerTakeover show={isLoading} />
        </div>
    );
};


PaymentSummary.defaultProps = {
    addOns: {},
    isAnnual: false,
    patients: [],
    plans: {},
};

PaymentSummary.propTypes = {
    addOns: PropTypes.object,
    clinicId: PropTypes.number.isRequired,
    isAnnual: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    patients: PropTypes.arrayOf(PropTypes.object),
    petOwnerId: PropTypes.number.isRequired,
    plans: PropTypes.object,
};
