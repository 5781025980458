exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ProgramWelcomeForm__root img{width:100%;max-width:200px}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/common/forms/ProgramWelcomeForm/ProgramWelcomeForm.scss"],"names":[],"mappings":"AAAA,8BAAU,WAAW,eAAe,CAAC","file":"ProgramWelcomeForm.scss","sourcesContent":[".root img{width:100%;max-width:200px}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "ProgramWelcomeForm__root"
};