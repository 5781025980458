import React, { useContext } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import PropTypes from "prop-types";
import { windowSizeContext } from "AppRoot";
import Button from "components/common/Button";
import * as ProviderLinks from "utils/ProviderLinks";
import { setQueryStringParams } from "utils/urls";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

const DEFAULT_CLINIC_FILTERS = "filters=%5B%7B%22order%22%3A0%2C%22id%22%3A%22Active%22%2C%22isComplete%22%3Atrue%2C%22equality%22%3A%22%3D%3D%22%2C%22level1Values%22%3A%5B%7B%22value%22%3A%22Active%22%7D%5D%7D%5D&orderBy=id&orderDir=desc";

function ToolbarBackButtons(props) {
    const history = useHistory();
    const context = useContext(windowSizeContext) || {};

    const goToClinicManagement = () => {
        if(props.canViewClinicManagement) {
            // Go back to the last clinics search
            if (props.lastClinicSearch) {
                const filters = JSON.stringify(props.lastClinicSearch.filters)
                const newQs = setQueryStringParams("", {
                    filters,
                    search: props.lastClinicSearch.search || null,
                    limit: props.lastClinicSearch.limit || 25,
                    offset: props.lastClinicSearch.offset || 0,
                    orderBy: props.lastClinicSearch.orderBy || "id",
                    orderDir: props.lastClinicSearch.orderDir || "desc"
                });
                props.history.push(`/admin/clinics?${newQs}`);
            } else {
                props.history.push(`/admin/clinics?${DEFAULT_CLINIC_FILTERS}`);
            }
        }
    }

    const goToUserManagement = () => {
        if(props.canViewUserManagement) {
            // Go back to the last clinics search
            if (props.lastUserSearch) {
                const roles = JSON.stringify(props.lastUserSearch.roles)
                const newQs = setQueryStringParams("", {
                    roles,
                    search: props.lastUserSearch.search || null,
                    limit: props.lastUserSearch.limit || 25,
                    providerId: props.lastUserSearch.providerId || null,
                    clinicId: Number(props.lastUserSearch.clinicId) || null,
                    status: props.lastUserSearch.status || "active",
                    offset: props.lastUserSearch.offset || 0,
                    orderBy: props.lastUserSearch.orderBy || "id",
                    orderDir: props.lastUserSearch.orderDir || "desc"
                });
                props.history.push(`/admin/users?${newQs}`);
            } else {
                props.history.push(`/admin/users`);
            }
        }
    }

    const goToCorporateGroupDashboard = () => {
        // Go back to the last clinics search
        let link;
        if (props.nodeId === props.userProfile?.nodeId) {
            link = ProviderLinks.getLinkLocation("dashboard", {
                providerId: props.providerId
            });
        } else {
            link = ProviderLinks.getLinkLocation("dashboard", {
                providerId: props.providerId,
                nodeId: props.nodeId
            });
        }
        history.push(link);
    }

    return (
        <div className="flex spaced-content">
            {/*Back button for user management from clinic (admin user)*/}
            {(props.hasClinicId && props.cameFromUserManagement && props.canViewUserManagement) && (
                <div className="padding-left-sm margin-right-x-sm">
                    <Button
                        small
                        type="primary"
                        onClick={goToUserManagement}
                        icon
                        iconOnly={context.isPhone}
                    >
                        {context.isPhone ? (
                            <span>
                                <i className="fa fa-chevron-left"/>
                                <i className="fa fa-users"/>
                            </span>
                        ) : (
                            <><i className="fas fa-chevron-left" /> User Management</>
                        )}
                    </Button>
                </div>
            )}
            {/*Back button for admin clinic search from clinic (admin user)*/}
            {((props.isClinic || props.isAdmin) && props.hasClinicId) && (
                <div className="padding-left-sm">
                    <Button
                        small
                        type="primary"
                        onClick={goToClinicManagement}
                        disabled={!props.canViewClinicManagement}
                        icon={context.isPhone || !props.cameFromUserManagement}
                        iconOnly={context.isPhone}
                    >
                        {!props.cameFromUserManagement && (
                            <i className="fas fa-chevron-left"/>
                        )}
                        {(context.isPhone && props.cameFromUserManagement) ? (
                            <span>
                                <i className="fa fa-chevron-left"/>
                                <i className="fa fa-building"/>
                            </span>
                        ) : (
                            <span className="mobile-hide"> Clinics</span>
                        )}
                    </Button>
                </div>
            )}

            {/*Back button for provider dashboard from clinic (provider user)*/}
            {(props.isClinic && !!props.nodeId) && (
                <div className="padding-left-sm margin-right-x-sm">
                    <Button
                        small
                        type="primary"
                        onClick={goToCorporateGroupDashboard}
                        icon={!props.cameFromUserManagement}
                        iconOnly={context.isPhone}
                        disabled={!props.canViewUserManagement}
                    >
                        <i className="fas fa-chevron-left" /><span className="mobile-hide"> Dashboard</span>
                    </Button>
                </div>
            )}

            {/*Back button for provider management from provider (admin user)*/}
            {props.isProvider && (
                <Button
                    small
                    className="margin-right-x-sm"
                    type="primary"
                    onClick={() => props.history.push(`/admin/providers`)}
                    icon={!props.cameFromUserManagement}
                >
                    {!props.cameFromUserManagement && (<i className="fas fa-chevron-left" />)} Providers
                </Button>
            )}
        </div>
    );
}

ToolbarBackButtons.propTypes = {
    nodeId: PropTypes.number,
    hasClinicId: PropTypes.bool,
    isAdmin: PropTypes.bool,
    isClinic: PropTypes.bool,
    isProvider: PropTypes.bool,
};

const connector = connect(
    (state) => {
        const userProfile = state.user.userProfile;
        // Permissions
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);
        return {
            userProfile,
            lastClinicSearch: state.entities.lastClinicsSearchParams,
            lastUserSearch: state.entities.lastUsersSearchParams,
            cameFromUserManagement: state.entities.cameFromUserManagement,
            // Permissions
            canViewClinicManagement,
            canViewUserManagement
        }
    }
);

export default compose(
    withRouter,
    connector
)(ToolbarBackButtons);
