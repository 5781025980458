export const ACTIVATION_REQUIRED = "activate";
export const DANGER = "danger";
export const DARK = "dark";
export const DEFAULT = "";
export const GRAY = "gray";
export const INACTIVE = "inactive";
export const PRIMARY = "primary";
export const NOTIFY = "notify";
export const SUCCESS = "success";
export const WARN = "warn";
