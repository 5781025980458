import React, { useEffect } from "react";
import * as Oidc from "oidc-client";
import Loader from "components/common/Loader";
import logger from "utils/logger";
import { getManagerConfig } from "utils/login";

export default function BiSsoLogin(props) {
    useEffect(() => {
        const mgrConfig = getManagerConfig();
        const mgr = new Oidc.UserManager({ ...mgrConfig, response_mode: "query" });

        const urlParams = new URLSearchParams(location.search);

        const params = {
            extraQueryParams: {
                glaccesstoken: urlParams.get("accesstoken"),
                gluid: urlParams.get("uid"),
                extsource: 'bisso',
            }
        };

        try {
            mgr.signinRedirect(params);
        } catch (error) {
            props.history.replace("/");
            logger.error("Error BI OIDC redirecting:", error);
        }
    }, []);

    return (
        <div data-testid="bi-sso-login-component">
            <Loader text="Processing BI SSO Login" />
        </div>
    );
}
