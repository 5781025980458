import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import cloneDeep from "lodash/cloneDeep";
import includes from "lodash/includes";
import map from "lodash/map";
import mergeWith from "lodash/mergeWith";
import remove from "lodash/remove";
import CheckboxInput from "components/common/CheckboxInput";
import NotificationButtons from "components/notifications/elements/NotificationButtons";
import UserInitials from "components/notifications/elements/UserInitials";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {PENDING, ORAVET_2022, STATUS_COLOR_CLASS} from "constants/Notifications";
import SignatureBox from "components/common/SignatureBox";
import { SYNC_TRIGGER_CLOO } from "constants/Notifications";

function BasicNotification(props) {
    const [initials, setInitials] = useState("");
    const [signature, setSignature] = useState("");
    const [checked, setChecked] = useState([]);
    const [checkedLTOCampaign, setCheckedLTOCampaign] = useState(true);
    const [formFieldsJson, setFormFieldsJson] = useState({includeLTOCampaign: checkedLTOCampaign, signature: signature});

    useEffect(() => {
        props.notificationDetails.formFieldsJson = {
            ...props.notificationDetails.formFieldsJson,
            includeLTOCampaign: checkedLTOCampaign,
            signature: signature,
        };
    }, [props.notificationDetails, checkedLTOCampaign, signature]);

    if (!props.notificationDetails) {
        return <SpinnerTakeover show />
    }

    const combinedData = cloneDeep(props.acceptedOffers);

    const custom = (objValue, srcValue) => {
      if (_.isArray(objValue)) {
          return srcValue;
      }
    }
    mergeWith(combinedData, checked, custom());

    const selectedOffers = props.canSelectOffers ? combinedData : map(props.offers, "ccloId");
    const isPending = props.enrollmentState === PENDING;

    const handleCheckedChange = ( clooId, value ) => {
        if(isPending) {
            let newChecked = [...combinedData];
            if (!!value) {
                newChecked.push(clooId);
            } else {
                remove(newChecked, c => c === clooId);
            }
            setChecked(newChecked);
        }
    };

    const handleIncludeLTO = (value) => {
        setCheckedLTOCampaign(value);
        props.notificationDetails.formFieldsJson = {
            ...props.notificationDetails.formFieldsJson,
            includeLTOCampaign: value,
        };
        if (!value) {
            setFormFieldsJson({...props.notificationDetails.formFieldsJson, includeLTOCampaign: value});
        } else {
            setFormFieldsJson({...props.notificationDetails.formFieldsJson, includeLTOCampaign: value});
        }
    };

    const handleSignature = (value) => {
        setSignature(value);
        props.notificationDetails.formFieldsJson = {
            ...props.notificationDetails.formFieldsJson,
            signature: value,
        
        };
        setFormFieldsJson({...props.notificationDetails.formFieldsJson, signature: value});
    };

    const getStatusColor = (status) => {
        return STATUS_COLOR_CLASS[status] || "";
    };

    return (
        <div>
            {props.notificationDetails.showCurrentStatus && (
                <div className={`${getStatusColor(props.enrollmentState)} margin-bottom-md text-center text-lg`}>- {props.notificationDetails.currentState} -</div>
            )}
            <div className="quillText" dangerouslySetInnerHTML={{ __html: props.notificationDetails.longDescription }} />
            {props.notificationType === SYNC_TRIGGER_CLOO && (
                <>
                <CheckboxInput
                    name="includeLTOCampaign"
                    label="Include Merck Spring 2024 Limited Time Campaign"
                    checked={checkedLTOCampaign}
                    onChange={({value}) => handleIncludeLTO(value)}
                    large
                />
                <CheckboxInput
                    name="acceptTermsAndConditions"
                    label="I accept the terms and conditions"
                    value="checkboxTermsConditions"
                    checked={checked.includes("checkboxTermsConditions")}
                    onChange={() => {
                        setChecked(prev => prev.includes("checkboxTermsConditions") 
                        ? prev.filter(v => v !== "checkboxTermsConditions")
                        : [...prev, "checkboxTermsConditions"])
                    }}
                    large
                />
                <SignatureBox 
                    onChange={({value}) => handleSignature(value)}
                    name="signature"
                    value={signature}
                    label="*Please Sign Here"
                    hasError={!!includes(props.invalidFields, "signature")}
                    autoFocus
                    required
                />
            </>

            )}
            <>
                {props.notificationDetails.requiresInitials && (
                    <div className="flex margin-top-md">
                        <div className="flex-1">
                            <UserInitials
                                onChange={({value}) => setInitials(value)}
                                value={initials}
                            />
                        </div>
                        <div className="flex-1" />
                    </div>
                )}
                {(props.canSelectOffers) && (
                    <>
                        <div>
                            <div className="text-lg text-center">Available Programs</div>
                            {isPending ? (
                                <div className="text-center">Please select the products you would like to participate with Below</div>
                            ) : (
                                <div className="text-center">Selected product participation</div>
                            )}
                        </div>
                        <div className="flex flex-centered">
                            <div className="margin-top-sm">
                                {map(props.offers, o => (
                                    <CheckboxInput
                                        key={o.ccloId}
                                        name="bsc"
                                        label={o.libraryTitle}
                                        checked={!!includes(selectedOffers, o.ccloId)}
                                        onChange={({value}) => handleCheckedChange(o.ccloId, value)}
                                        large
                                        disabled={!isPending}
                                    />
                                ))}
                            </div>
                        </div>
                    </>
                )}
                <hr className="margin-top-sm"/>
                <div className="margin-top-md">
                    <NotificationButtons
                        notificationDetails={props.notificationDetails}
                        onEnroll={props.onEnroll}
                        onDecline={props.onDecline}
                        buttonText={props.notificationType === SYNC_TRIGGER_CLOO && "Codes Created, Opt-In"}
                        onCancel={props.onCancel}
                        onDismiss={props.onDismiss}
                        enrollDisabled={
                            props.notificationType === SYNC_TRIGGER_CLOO
                              ? (
                                  signature.length == 0  ||
                                  !checked.includes("checkboxTermsConditions")
                                )
                              : (
                                  props.notificationDetails?.requiresInitials && !initials?.length
                                )
                          }
                        declineDisabled={props.notificationType === SYNC_TRIGGER_CLOO && !signature}
                        initials={initials}
                        formFieldsJson={formFieldsJson}
                        selectedOffers={selectedOffers}
                        cancelledEnrolls={(props.notificationDetails?.notificationType === ORAVET_2022)}
                        isPreview={props.isPreview}
                    />
                </div>
            </>
        </div>
    );
}

BasicNotification.propTypes = {
    clinicId: PropTypes.number,
    providerId: PropTypes.number,
    notificationDetails: PropTypes.object.isRequired,
    notificationType: PropTypes.string.isRequired,
    onEnroll: PropTypes.func.isRequired,
    onDecline: PropTypes.func,
    onCancel: PropTypes.func,
    onClose: PropTypes.func,
    onDismiss: PropTypes.func,
    offers: PropTypes.array,
    enrollmentState: PropTypes.string,
    acceptedOffers: PropTypes.array,
    canSelectOffers: PropTypes.bool,
    isPreview: PropTypes.bool
};

BasicNotification.defaultProps = {
    canSelectOffers: false
};

export default connect(
    null,
)(BasicNotification);
