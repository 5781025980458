import React, {useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./LiveChat.scss";
import {premierSlugifyClinicName, slugifyClinicName} from "utils/ClinicData";
import {slugifyProviderName} from "utils/ProviderData";
const OFFLINE_IMAGE = require("../../../../../../img/islpronto-message.jpg");


export function LiveChatWidget(props) {
    useEffect(() => {
        const liveChatDiv = document.getElementById("live-chat-script");
        if(props.clinic) {
            // Get the wrapper div
             if (liveChatDiv) {
                 // Clear it out
                 while (liveChatDiv.firstChild) {
                     liveChatDiv.removeChild(liveChatDiv.firstChild);
                 }

                 // Create the script tag
                 const script = document.createElement("script");
                 script.async = true;

                 // load the script
                 if (props.clinicHasPrioritySupport) {
                     // Premier Chat SRC
                     const clinicPrioritySlug = premierSlugifyClinicName(props.clinic);
                     script.src = `https://islpronto.islonline.net/live/islpronto/public/chat.js?d=greenlinepet&chat_width=450&chat_height=400&arg_clinicname=${clinicPrioritySlug}`;
                 } else {
                     // Regular Chat SRC
                     const clinicSlug = slugifyClinicName(props.clinic);
                     script.src = `https://islpronto.islonline.net/live/islpronto/public/chat.js?d=greenlinepet&chat_width=450&chat_height=400&arg_clinicname=${clinicSlug}`;
                 }
                 liveChatDiv.appendChild(script);

                 // Clear it out and replace if it doesn't match the current firstChild
                 if (liveChatDiv?.firstChild?.src !== script?.src) {
                     while (liveChatDiv.firstChild) {
                         liveChatDiv.removeChild(liveChatDiv.firstChild);
                     }
                     liveChatDiv.appendChild(script);
                 } else {
                     liveChatDiv.appendChild(script);
                 }
             }
        } else if (props.providerLocation) {
            // Get the wrapper div
            if(liveChatDiv) {
                // Clear it out
                while (liveChatDiv.firstChild) {
                    liveChatDiv.removeChild(liveChatDiv.firstChild);
                }

                // Create the Slug
                const providerSlug = slugifyProviderName(props.providerLocation);

                // Create the script tag
                const script = document.createElement("script");
                script.async = true;

                // load the script
                script.src = `https://islpronto.islonline.net/live/islpronto/public/chat.js?d=greenlinepet&chat_width=450&chat_height=400&arg_clinicname=${providerSlug}`;
                liveChatDiv.appendChild(script);
            }
        }
    }, []);

    let buttonClass = props.footer ? "" : "btn btn-color-default btn-full flex-1";
    const liveChat = useMemo(() => {
        return (
            <div
                data-testid="live_chat_component"
                className={classnames(styles.root, {
                    [styles.liveChatImage]: props.imageLink,
                    [styles.footer] : props.footer,

                    // test for jest test
                    "test-image-link": props.imageLink,
                    "test-footer": props.footer,
                })}
            >
                {props.imageLink ? (
                    <a
                        data-testid="live_chat_component_contact_greenline"
                        id="islpronto_link"
                        href="mailto:support@greenlinepet.com"
                    >
                        <img id="islpronto_image" alt="Contact Greenline" src={OFFLINE_IMAGE} />
                    </a>
                ) : (
                    <a
                        data-testid="live_chat_component_email_support"
                        id="islpronto_text"
                        className={buttonClass}
                        href="mailto:support@greenlinepet.com"
                    >
                        <span>{props.footer ? "Email Support" : "Submit Request"}</span>
                    </a>

                )}
                <div
                    data-testid="live_chat_component_button_text"
                    id="islpronto_text_"
                    className={styles.liveText}
                >
                    <span>{!!props.buttonText ? props.buttonText :  "Live Chat"}</span>
                </div>
            </div>
        )
    }, [props.clinic])
        

    return liveChat;
}

LiveChatWidget.propTypes = {
    clinic: PropTypes.object,
    imageLink: PropTypes.bool,
    buttonText: PropTypes.string,
    footer: PropTypes.bool,
    providerLocation: PropTypes.object,
    clinicHasPrioritySupport: PropTypes.bool
}
