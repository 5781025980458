import React, { useEffect, useState} from "react";
import { connect } from "react-redux";
import styles from "./WellnessLanding.scss";
import includes from "lodash/includes";
import ActivityGrid from "components/dashboard/ActivityGrid";
import ClinicBase from "components/layout/ClinicBase";
import * as GreenlineWellnessActions from "actions/GreenlineWellnessActions";
import JoyRide from "components/tour/widgets/JoyRide";
import NewPlanEnrollmentBtn from 'components/greenlineWellness/widgets/WellnessEnrollmentModal/NewPlanEnrollmentBtn';
import { clinicHasWellness, getClinicInfo } from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import {
    TOUR_PLAN_ENROLLMENT_PART_1, TOUR_PLAN_ENROLLMENT_PART_2,
    TOUR_PLAN_ENROLLMENT_TRANSITION, TOUR_WELLNESS_AUDIT_PART_1, TOUR_WELLNESS_AUDIT_TRANSITION,
    TOUR_WELLNESS_DASHBOARD
} from "utils/demoTourHelpers";
import { CLINIC_TAGS } from "constants/AdminControls";
import * as DemoTourStates from "constants/DemoTourStates";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

function WellnessLanding(props)  {
    const [loading, setLoading] = useState(false);

    // TOUR ONLY
    const [tourSection, setTourSection] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const getTourSection = () => {
        // Don't set the tourSection if the tour is complete
        let currentSection = null;
        if (props.tourStarted) {
            if (!includes(props.completedTourSections, TOUR_WELLNESS_DASHBOARD)) {
                currentSection = TOUR_WELLNESS_DASHBOARD;
            } else if (!includes(props.completedTourSections, TOUR_PLAN_ENROLLMENT_TRANSITION)) {
                currentSection = TOUR_PLAN_ENROLLMENT_TRANSITION;
            } else if (!includes(props.completedTourSections, TOUR_PLAN_ENROLLMENT_PART_1)) {
                currentSection = TOUR_PLAN_ENROLLMENT_PART_1;
            } else if (includes(props.completedTourSections, TOUR_PLAN_ENROLLMENT_PART_2)) {
                if (!includes(props.completedTourSections, TOUR_WELLNESS_AUDIT_TRANSITION)) {
                    currentSection = TOUR_WELLNESS_AUDIT_TRANSITION;
                } else if (!includes(props.completedTourSections, TOUR_WELLNESS_AUDIT_PART_1)) {
                    currentSection = TOUR_WELLNESS_AUDIT_PART_1;
                }
            }
        }
        setTourSection(currentSection);
    }
    useEffect(() => {
        getTourSection();
    }, [props.completedTourSections]);

    const handleTourStepComplete = (step, index) => {
        if (step === TOUR_PLAN_ENROLLMENT_PART_1) {
            setShowAddModal(true);
        }
    };
    // END

    useEffect(() => {
        if (props.clinicId) {
            setLoading(true);
            props.getWellnessDashboardTiles(props.clinicId);
        }
    }, [props.clinicId]);

    useEffect(() => {
        if (props.wellnessTiles && loading) {
            setLoading(false);
        }
    }, [props.wellnessTiles]);

    return (
        <ClinicBase
            searchConfig={SearchTypes.WELLNESS_PLANS_PAGE}
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle="Wellness Plans"
        >
            <div className={styles.root}>
                {props.canViewClinicWellnessPlans && (
                    <div className={styles.wellnessButtons}>
                        <NewPlanEnrollmentBtn
                            clinicId={props.clinicId}
                            forceShowModal={showAddModal}
                        />
                    </div>
                )}
                <div className={styles.wellnessTiles}>
                    <ActivityGrid
                        tiles={props.wellnessTiles}
                    />
                </div>
                <JoyRide
                    key={tourSection}
                    tourSection={tourSection}
                    onStepComplete={handleTourStepComplete}
                />
            </div>
        </ClinicBase>
    );
}

WellnessLanding.propTypes = {};

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);

        return {
            clinicId,
            clinicHasWellness: clinicHasWellness(state, clinicId),
            clinicWellnessPlans: state.wellness.clinicWellnessPlans[clinicId],
            completedTourSections: state.demo.completedTourSections,
            tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),
            //FIXME: wellnessTiles is also being stored in state.wellness so it should probably be removed from state.entities
            wellnessTiles: state.entities.wellnessDashboardTiles[clinicId],
            // Permissions
            canViewWellnessPetPlans,
            canViewClinicWellnessPlans,
        }
    },
    (dispatch) => ({
        getWellnessDashboardTiles: (clinicId) => dispatch(GreenlineWellnessActions.getWellnessDashboardTiles(clinicId,)),
    })
)(WellnessLanding);
