exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".TermsAndConditions__root a{color:#4eaae4}.TermsAndConditions__root ol{margin-bottom:2em;padding-left:1em}.TermsAndConditions__root ol li{margin-bottom:1.25em}.TermsAndConditions__root ol li>b{display:inline-block;margin-left:0.5em}.TermsAndConditions__root ol li>p>span{display:inline-block;margin-right:0.5em}.TermsAndConditions__root ol.inside{list-style-position:inside;padding-left:0}.TermsAndConditions__root ol.inside li{text-indent:1em}.TermsAndConditions__root ol.no-list{list-style:none}.TermsAndConditions__root ol>p>span{display:inline-block;margin-left:0.5em}.TermsAndConditions__root .TermsAndConditions__addressBlock{text-indent:0;padding-left:2em}.TermsAndConditions__root .TermsAndConditions__footerText{margin-top:3em;font-size:1.1em;font-weight:500;text-align:center}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/layout/elements/TermsAndConditions/TermsAndConditions.scss"],"names":[],"mappings":"AAAA,4BAAQ,aAAa,CAAC,6BAAS,kBAAkB,gBAAgB,CAAC,gCAAY,oBAAoB,CAAC,kCAAc,qBAAqB,iBAAiB,CAAC,uCAAmB,qBAAqB,kBAAkB,CAAC,oCAAyB,2BAA2B,cAAc,CAAC,uCAA4B,eAAe,CAAC,qCAA0B,eAAe,CAAC,oCAAgB,qBAAqB,iBAAiB,CAAC,4DAAoB,cAAc,gBAAgB,CAAC,0DAAkB,eAAe,gBAAgB,gBAAgB,iBAAiB,CAAC","file":"TermsAndConditions.scss","sourcesContent":[".root a{color:#4eaae4}.root ol{margin-bottom:2em;padding-left:1em}.root ol li{margin-bottom:1.25em}.root ol li>b{display:inline-block;margin-left:0.5em}.root ol li>p>span{display:inline-block;margin-right:0.5em}.root ol:global(.inside){list-style-position:inside;padding-left:0}.root ol:global(.inside) li{text-indent:1em}.root ol:global(.no-list){list-style:none}.root ol>p>span{display:inline-block;margin-left:0.5em}.root .addressBlock{text-indent:0;padding-left:2em}.root .footerText{margin-top:3em;font-size:1.1em;font-weight:500;text-align:center}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "TermsAndConditions__root",
	"addressBlock": "TermsAndConditions__addressBlock",
	"footerText": "TermsAndConditions__footerText"
};