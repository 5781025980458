import React from "react";
import PropTypes from "prop-types";
import { filter, map, forEach, reject, flatMap } from "lodash";
import InvoiceTable from "components/admin/widgets/InvoiceTable";
import moment from "moment";

export default function InvoiceList(props) {
    const list = ["New", "Deleted", "Bad", "Matched"];

    const invoiceIds = flatMap(props.invoices, "invoiceId");

    const sectionJsx = map(props.invoices, invoice => {
        const redemptions = props.current ? filter(invoice.redemptions, {isCurrent: true}) : invoice.redemptions;

        if (!redemptions.length) {
            return null;
        }

        const sectionHeader = (
            <div className="flex align-center margin-sm margin-top-md text-lg">
                Invoice #{invoice.invoiceNumber} -
                <span className="margin-left-x-sm text-sm">{moment(invoice.invoiceDate).format("MM/DD/YYYY")}</span>
                <span className="margin-left-x-sm text-x-sm">[{redemptions.length} redemption{redemptions.length !== 1 && "s"}]</span>
            </div>
        );

        return (
            <div>
                <div>{sectionHeader}</div>
                {redemptions.length ? (
                    <InvoiceTable
                        redemptions={redemptions}
                        onRedemptionUpdate={(data) => props.onRedemptionUpdate({...data, invoiceId: invoice.invoiceId})}
                        canEditCouponReprocessing={props.canEditCouponReprocessing}
                    />
                ) : null}
            </div>
        );
    });

    const listSectionJsx = map(list, (li, index) => {
        let redemptions = [];
        if(li === "New") {
            forEach(props.invoices, (invoice) => {
                 redemptions = redemptions.concat(filter(invoice.redemptions, {processStateShortDescription: "new"}));
            });
        } else if(li === "Deleted") {
            forEach(props.invoices, (invoice) => {
                 redemptions = redemptions.concat(filter(invoice.redemptions, {processStateShortDescription: "deleted"}));
            });
        } else if(li === "Bad") {
            forEach(props.invoices, (invoice) => {
                 redemptions = redemptions.concat(filter(invoice.redemptions, redemption => (
                     redemption.processStateShortDescription ==="error"
                     || redemption.processStateShortDescription === "bad"
                 )));
            });
        } else if(li === "Matched") {
            forEach(props.invoices, (invoice) => {
                 redemptions = redemptions.concat(filter(invoice.redemptions, redemption => {return (redemption.processStateShortDescription === "matched" || redemption.processStateShortDescription === "matched-original")}));
            });
        }

        const sectionHeader = (
            <div className="flex align-center text-lg margin-sm margin-top-md">
                {li}
                <span className="text-sm margin-left-x-sm">
                    [{redemptions.length}]
                </span>
            </div>
        );

        return (
            <div>
                <div>{sectionHeader}</div>
                {redemptions.length ? (
                    <InvoiceTable
                        redemptions={redemptions}
                        list
                        onRedemptionUpdate={props.onRedemptionUpdate}
                        canEditCouponReprocessing={props.canEditCouponReprocessing}
                    />
                ) : null}
            </div>
        );
    });

    if(!props.canEditCouponReprocessing) {
        return null;
    }

    return (
        <div>
            {props.list ? (
                listSectionJsx
            ) : ( props.current && !reject(sectionJsx, sec => !sec).length ) ? (
                <div>There are no Current Invoices to show</div>
            ) : (
                sectionJsx
            )}
        </div>
    );
}

InvoiceList.propTypes = {
    invoices: PropTypes.object,
    onSelect: PropTypes.func,
    list: PropTypes.bool,
    current: PropTypes.bool,
    onRedemptionUpdate: PropTypes.func.isRequired,
    canEditCouponReprocessing: PropTypes.bool,
};

InvoiceList.defaultProps = {
    list: false,
};
