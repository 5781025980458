import {combineReducers} from "redux";
import produce from "immer";
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import logger from "utils/logger";
import * as ActionTypes from "constants/ActionTypes";


function loading(state = false, action) {
    switch (action.type) {
        case ActionTypes.GLN_WELLNESS_SEARCH_LOADING:
        case ActionTypes.GLN_WELLNESS_VISITS_LOADING:
        case ActionTypes.GLN_WELLNESS_VISIT_UPDATING:
            return true;
        case ActionTypes.GLN_WELLNESS_SEARCH_RESULTS_LOADED:
        case ActionTypes.GLN_WELLNESS_VISITS_LOADED:
        case ActionTypes.GLN_WELLNESS_VISIT_UPDATED:
            return false;
        default:
            return state;
    }
}

function searchResults(state = null, action) {
    switch (action.type) {
        case ActionTypes.GLN_WELLNESS_SEARCH_RESULTS_LOADED:
            return action.results;
        case ActionTypes.CLEAR_ALL_SEARCHES:
        case ActionTypes.GLN_WELLNESS_SEARCH_CLEARED:
            return null;
        default:
            return state;
    }
}

function selectedId(state = null, action) {
    switch (action.type) {
        case ActionTypes.GLN_WELLNESS_PLAN_SELECTED:
            return action.planId;
        case ActionTypes.GLN_WELLNESS_CLEAR_SELECTED_PLAN:
            return null;
        default:
            return state;
    }
}

function query(state = null, action) {
    switch (action.type) {
        case ActionTypes.GLN_WELLNESS_HISTORY_SEARCH_FILTERED:
            return action.query;
        case ActionTypes.GLN_WELLNESS_HISTORY_SEARCH_CLEARED:
            return null;
        default:
            return state;
    }
}

function plans(state = {}, action) {
    switch (action.type) {
        case ActionTypes.GLN_WELLNESS_PLANS_LOADED:
            return keyBy(action.plans, "id");
        case ActionTypes.GLN_WELLNESS_PLAN_UPDATED:
            return produce(state, newState => {
                if (newState?.[action.petId]) {
                    newState[action.petId].ppgPatientPlanEnrollmentId = action.ppgPatientPlanEnrollmentId;
                    newState[action.petId].isEditable = false;
                }
            });
        case ActionTypes.WELLNESS_PREMIER_PET_PLAN_UPDATED:
            return produce(state, newState => {
                if (newState?.[action.petId]) {
                    newState[action.petId].externalPetPlanReference = action.externalPetPlanReference;
                }
            });
        case ActionTypes.GLN_WELLNESS_PLAN_DETAILS_LOADED:
            return produce(state, newState => {
                newState[action.id].details = action.plan;
            });
       default:
            return state;
    }
}

function processedVisits(state = {}, action) {
    switch (action.type) {
        case ActionTypes.GLN_WELLNESS_AUDIT_LOADED:
            return keyBy(action.visits, "visitId");
        default:
            return state;
    }
}
function visits(state = {}, action) {
    switch (action.type) {
        case ActionTypes.GLN_WELLNESS_VISITS_LOADED:
            return keyBy(action.visits, "visitId");
        case ActionTypes.GLN_WELLNESS_VISIT_UPDATED:
            // Wellness visit approve/disapprove
            // Wellness DVm assignment
            const updatedVisits = { ...state };

            // Remove first to ensure removing and adding the same visit (e.g., change DVM) works correctly
            forEach(action.removalIds, id => delete updatedVisits[id]);

            // Add or update
            forEach(action.visits, v => updatedVisits[v.visitId] = v);
            return updatedVisits;

        case ActionTypes.GLN_WELLNESS_VISIT_VOIDED:
            const visits = { ...state };
            delete visits[action.visitId];
            return visits;
        default:
            return state;
    }
}


function clinicWellnessPlans(state = {}, action) {
    switch (action.type) {
        case ActionTypes.GLN_CLINIC_WELLNESS_PLANS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case ActionTypes.GLN_CLINIC_WELLNESS_PLANS_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    ...action.wellnessPlans,
                    houseAccounts: keyBy(action.wellnessPlans.houseAccounts, "id"),
                    plans: {
                        ...keyBy(map(action.wellnessPlans.plans, plan => {
                            return {
                                ...plan,
                                enrollmentMappings: keyBy(plan.enrollmentMappings, "id"),
                                services: {
                                    ...keyBy(map(plan.services, service => {
                                        return {
                                            ...service,
                                            enrollmentMappings: keyBy(service.enrollmentMappings, "id"),
                                        }
                                    }), "clinicPlanServiceTypeId"),
                                }
                            }
                        }), "clinicPlanInstanceId"),
                    },
                    priceSchedules: keyBy(action.wellnessPlans.priceSchedules, "clinicPriceScheduleId")
                },
                loading: false,
            };
        default:
            return state;
    }
}

function dashboardTiles(state={}, action) {
    switch(action.type) {
        case ActionTypes.CLINIC_WELLNESS_DASHBOARD_TILES_LOADED:
            return {
                ...state,
                [action.clinicId]: action.data.tiles,
            };
        default:
            return state;
    }
}

export default combineReducers({
    clinicWellnessPlans,
    loading,
    plans,
    processedVisits,
    query,
    searchResults,
    selectedId,
    dashboardTiles,
    visits,
});
