import React from "react";
import { connect } from "react-redux";
import ProviderBase from "components/layout/ProviderBase";
import EditGame from "components/provider/widgets/EditGame";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import {getNodeId} from "utils/AdminData";
import getProviderId from "utils/getProviderId";

function TemplatePage(props) {
    return (
        <ProviderBase
            location={props.location}
            pageTitle="Greenline Games™"
            collapsible
            nodeId={props.nodeId}
            providerId={props.providerId}
        >
            {(props.canViewGames || props.canViewClinicBIGamesDashboard) ? (
                <EditGame
                    id={props.templateId}
                    nodeId={props.nodeId}
                    providerId={props.providerId}
                    isAdmin={props.isAdmin}
                />
            ) : (
                <AccessDenied/>
            )}
        </ProviderBase>
    );
}

export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const nodeId = getNodeId(state, ownProps);
        const providerId = getProviderId(params, userProfile);
        const templateId = params?.templateId || null;

        //Permissions
        // const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canAdminProviderBiGames = false;
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewGamesAdmin = userHasPermission(PermissionTypes.VIEW, UserPermissions.GAMES_ADMIN, userProfile);
        const canViewGames = (canAdminProviderBiGames && canViewGamesAdmin) || canViewProviderBiGames;
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            nodeId,
            providerId,
            templateId,
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && providerId && nodeId),

            // Permissions
            canViewGames,
            canViewClinicBIGamesDashboard
        }
    }
)(TemplatePage);
