import React from "react";
import {
	MAIN_COLOR,
	SECONDARY_COLOR,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./PuppyPodiumRight.scss";


export default function PuppyPodiumRight(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Podium_-_Right_-_Puppy"
			data-name="Podium - Right - Puppy"
			// xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 800 300"
		>
			<g id="Puppy_Winner_Silver">
				<g id="Puppy_Winner_Silver_Lower">
					<g>
						<path className={styles.cls9}
						      d="M650.8,259.86c-1.63,2.31-3.41,4.55-5.4,6.63-2.38,2.49-19.82,12.93-13.19,16.93,1,.61,2.27.74,3.44.49,3.95-.86,7.95-1.84,11.44-3.67,5.75-3.01,9.55-7.98,12.1-13.49-.63-1.51-1.57-2.9-2.77-4.02-1.56-1.45-3.53-2.44-5.62-2.86Z"/>
						<path className={styles.cls7}
						      d="M660.51,247.59c-.48.04-1.04.21-1.7.55-3.06,1.59-4.34,6.14-6.04,8.8-.63.99-1.29,1.96-1.97,2.92,2.09.42,4.06,1.41,5.62,2.86,1.2,1.12,2.14,2.51,2.77,4.02,1.13-2.44,2.02-4.99,2.73-7.52.49-1.77,2.73-11.95-1.41-11.65Z"/>
					</g>
					<path className={styles.cls8}
					      d="M612.33,271.49c.11-.66.15-1.32.15-1.99-.04-3.35-1.38-6.66-3.16-9.72-4.06-6.99-11.09-14.24-19.82-17.26-7.91-2.74-24.58-4.1-30.17,1.92-.96,1.04-1.56,2.23-2.01,3.43-3.35,8.97,2.07,19.28,12.91,24.58-4.26,1.07-9.37,2.45-9.37,7.84,0,7.49,25.77,7.25,26.43.21.28-2.96.16-2.76-.29-5.47,2.39,3.36,3.97,3.4,8.29,4.77s9.45,1,12.86-1.42c2.39-1.69,3.76-4.22,4.18-6.89Z"/>
					<path className={styles.cls8}
					      d="M539.41,210.55c1.03.55,2.3.93,3.87,1.11,2.17.25,4.37-.23,6.29-1.21,2.02-1.03,3.22-3.49,5.12-4.36,1.96-.89,4.61,1.47,6.35,2.45,2.39,1.34,4.8,2.68,7.43,3.56,6.05,2.02,13.33,3.04,16.82-2.97,4.55-7.83,5.57-19.5,6.93-28.16.39-2.48.73-5.02.23-7.49-2.25-11.06-15.5-4.4-18.81,2.3-2.29,4.63-4.1,18.99-4.6,19.23-.48.24-11.11-8.08-16.09-9.19-4.98-1.12-8.85.17-11.84,4.2-3.97,5.34-8.77,16.77-1.71,20.53Z"/>
					<g>
						<path className={styles.cls7}
						      d="M598.42,268.78c-9.09-.31-40.4-31.37-34.15-77.26,1.13-8.27,1.62-17.56,4.96-26.86,5.58-2.94,17.38-3.33,25.85-4.58,5.76-.85,18.73-3.24,23.8,1,4.82,4.03,12.27,15.74,12.27,24.19,0,5.88-2.96,12.57-2.17,18.02,3.39,23.59,12.64,42.36,14.99,50.31,7.23,24.43-38.86,15.4-45.54,15.18Z"/>
						<path className={styles.cls8}
						      d="M624.12,248.62c1.4-15.27-26.25-20.38-32.8-8.74-16.07-11.05-22.78-30.82-23.06-51.76-.04-2.88.65-17.66,2.17-21.5,5.27,8.78,32.44,8.57,42.36.44-1.09-3.37-40.91-5.24-43.75-3.52-20.1,37.89,7.8,107.38,27.32,107.05,5.46-.09,27-13.71,27.75-21.97Z"/>
					</g>
				</g>
				<path
					id="Shirt"
					className={styles.cls2}
					d="M562.71,195.9c.67-7.84.76-32.03,7.28-36.34l2.78,1.95c-4.82,7.04-6.48,26.67,2.13,30.13,7.93,3.19,12.6-3.02,15.36-8.05,7.56-13.75,25.99-29.98,35.66-21.97,9.95,8.24,6.06,41.54,6.97,51.28.71,7.59,4.29,12.98,6.66,17.01-18.33,13.68-44.29,15.54-71.51,6.09-4.91-14.51-6-32.18-5.33-40.09ZM615.39,201.69c9.17-.86,15.92-8.91,15.07-17.97-.85-9.06-8.98-15.72-18.15-14.86s-15.92,8.91-15.07,17.97c.85,9.06,8.98,15.72,18.15,14.86Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes">
					<path
						className={styles.cls11}
						d="M579.66,193.95c6.64,0,10.85-6.34,15.68-13.61,5.35-8.06,11.42-17.19,22.26-19.97l-.62-2.41c-11.77,3.02-18.12,12.57-23.72,21-4.88,7.34-8.73,13.14-14.63,12.43-3.15-.38-5.43-1.83-6.97-4.42-3.73-6.27-2.01-17.69.4-23.79l-2.32-.92c-2.42,6.13-4.62,18.6-.22,25.98,1.94,3.25,4.9,5.14,8.81,5.62.45.05.89.08,1.32.08Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M578.98,229.43c7.73,1.45,15.47,2.03,22.99,1.7,12.11-.53,23.8-3.51,33.55-8.58-.72-1.54-1.41-3.65-1.71-4.69-9.25,4.92-20.41,7.93-32.05,8.44-12.8.56-24.6-1.02-37.5-5.78-.04.78.62,3.91.72,5.02,5.38,1.89,8.52,2.88,14,3.9Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M612.2,167.66c9.82-.92,18.56,6.23,19.47,15.95.91,9.71-6.34,18.37-16.16,19.29s-18.56-6.23-19.47-15.95c-.91-9.71,6.34-18.37,16.16-19.29ZM615.28,200.49c8.49-.8,14.77-8.27,13.98-16.65-.79-8.39-8.34-14.56-16.83-13.76s-14.77,8.27-13.98,16.65c.79,8.39,8.34,14.56,16.83,13.76Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Winner_Silver_Mid">
					<path className={styles.cls5}
					      d="M625.56,146.7c1.9,2.37.57,8.6-.34,11.47-.86,2.73-4.09,8.94-20.05,12.93-15.95,3.99-34.48-2.78-36.05-7.33-4.12-11.94,7.42-17.06,17.38-20.84,9.38-3.56,24.04-7.66,32.87-1.21,1.9,1.39,4.73,3.15,6.2,4.99Z"/>
					<g>
						<path className={styles.cls10}
						      d="M625.92,161.61c-7.79,7.92-41.68,18.23-50.98,15.82-5.58-4.03-10.1-12.12-9.89-16.19.11-2.19.41-3.35,3.33-3.72-.03,6.24,4.74,15.6,6.81,18.51,25.06-3.22,46.6-18,49.37-17.38,1.31.29,1.63,2.1,1.36,2.97Z"/>
						<g>
							<circle className={styles.cls1} cx="573.43" cy="191.69" r="11.51"/>
							<ellipse className={styles.cls12} cx="575.27" cy="177.92" rx="2.68" ry="1.38"
							         transform="translate(322.74 724.56) rotate(-82.39)"/>
							<circle className={styles.cls12} cx="572.48" cy="190.33" r="11.51"/>
						</g>
					</g>
					<path className={styles.cls7}
					      d="M570.62,215.46c1.03.55,2.3.93,3.87,1.11,2.17.25,4.37-.23,6.29-1.21,2.02-1.03,3.22-3.49,5.12-4.36,1.96-.89,4.61,1.47,6.35,2.45,2.39,1.34,4.8,2.68,7.43,3.56,6.05,2.02,14.43,2.67,17.92-3.34,4.55-7.83,8.9-19.73,10.27-28.4,1.22-7.75-16.58-23.22-25.2-5.83-2.29,4.63-2.29,18.05-2.78,18.3-.48.24-10.74-5.9-15.72-7.01-4.98-1.12-8.85.17-11.84,4.2-3.97,5.34-8.77,16.77-1.71,20.53Z"/>
					<path className={styles.cls9}
					      d="M636.26,261.8c-2.7,4.82-4.01,10.45-3.43,15.94.24,2.28.9,4.65,2.12,6.56.45-.11.95-.1,1.38-.26,13.11-2.05,13.45-16.97,6.59-33.55-2.6,2.33-4.96,8.26-6.66,11.31Z"/>
					<g>
						<path className={styles.cls9}
						      d="M557.88,48.2c-4.56,4.84-14.86,28.54-8.71,32.73,9.64,6.58,49.08.16,52.96-.12,4.88-.36,9.69-2.51,11.53-5.96,2.99-5.61-2.75-14.08-7.24-18.16-17.41-15.8-34.14-23.77-48.54-8.49Z"/>
						<g>
							<path className={styles.cls7}
							      d="M565.7,156.33c3.44-5.18,5.11-11.52,4.66-17.73-.46-6.4-3.07-12.8-1.65-19.06,1.94-8.52,10.5-13.61,18.44-17.26,12.05-5.54,24.55-10.11,37.33-13.63,3.26-.9,6.64-1.72,10.01-2.12-5.06-4.92-10.93-9.1-17.28-12.68-.83.35-1.7.62-2.54.83-5.4,1.33-10.93,2.2-16.12,4.26-10.85,4.31-19.22,13.42-28.35,20.5-4.28,3.32-9.4,6.24-14.7,5.65-5.28-.59-9.47-4.47-14.23-6.78-2.98-1.45-6.26-2.2-9.58-2.34-4.62,2.12-8.86,4.81-10.95,8.52-5.26,9.32-6.13,22.6-1.63,30.38,7.62,13.16,16.76,18.56,27.3,24.23,4.51,2.42,9.19,4.16,13.94,5.36,1.31-2.83,3.58-5.48,5.34-8.12Z"/>
							<path className={styles.cls4}
							      d="M633.71,148.78c4.52-4.25,8.69-8.94,12.33-13.94,6.33-8.69,6.21-17.01,2.62-26.8-3.11-8.49-8.05-15.56-14.17-21.51-3.37.4-6.75,1.22-10.01,2.12-12.79,3.53-25.28,8.09-37.33,13.63-7.94,3.65-16.5,8.74-18.44,17.26-1.42,6.26,1.19,12.66,1.65,19.06.44,6.21-1.22,12.54-4.66,17.73-1.75,2.64-4.03,5.3-5.34,8.12,31.32,7.92,65.51-8.31,73.34-15.67Z"/>
							<path className={styles.cls4}
							      d="M555.51,105.09c5.3.59,10.43-2.32,14.7-5.65,9.12-7.09,17.49-16.19,28.35-20.5,5.18-2.06,10.72-2.93,16.12-4.26.83-.21,1.71-.47,2.54-.83-.92-.52-1.85-1.03-2.79-1.52-11.4-6.01-22.02-9.92-35.14-4.91-6.57,2.51-12.31,9.2-17.33,13.96-5.87,5.56-9.82,6.76-17.55,9.7-3.58,1.37-8.32,2.87-12.7,4.88,3.32.14,6.6.9,9.58,2.34,4.76,2.31,8.95,6.2,14.23,6.78Z"/>
						</g>
						<path className={styles.cls6}
						      d="M537.17,138.06c-5.31-1.81-7.16-3.82-8.53-6.34-1.9,0-3.7-.49-4.67-1.07-1.63,1.45,1.56,9.56,6.4,10.92,4.5,1.26,6.7-1.72,6.8-3.52Z"/>
						<g>
							<g>
								<path className={styles.cls3}
								      d="M597.46,121.65c-4.83,4.76-10.9,6.42-13.56,3.7-2.66-2.71-.91-8.77,3.92-13.52,4.83-4.76,10.9-6.42,13.56-3.7,2.66,2.71.91,8.77-3.92,13.52Z"/>
								<path className={styles.cls3}
								      d="M566.03,95.9c-5.13,4-10.85,5.24-12.78,2.75-1.92-2.49.68-7.75,5.81-11.75,5.13-4,10.85-5.23,12.78-2.75,1.93,2.49-.68,7.75-5.81,11.75Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls3}
									      d="M546.37,114.91c-.55-2.13-2.45-4.66-4.9-6.77h0s0,0,0,0c0,0,0,0-.01,0h0c-2.39-2.18-5.12-3.76-7.29-4.03-10.34-1.32-9.93,10.59-3.93,16.85h0s0,0,0,0c0,0,0,0,.01.01h0c6.93,5.21,18.71,4.12,16.11-6.04Z"/>
									<path className={styles.cls3}
									      d="M519.39,123.59c.18-.05.39-.05.62,0,.75.17.87.95,1.17,1.53.41.82.98,1.63,1.61,2.29,2.04,2.1,4.28,2.74,6.97,2.99l.07-.1s.04.07.06.11c.04,0,.08,0,.12.01l-.07.1c1.19,2.42,2.59,4.29,5.28,5.45,1.86.8,6.24,1.32,8.25.37.71-.34,1.54-.03,1.85.68.31.71,0,1.55-.72,1.89-3.12,1.47-8.44.59-10.55-.32-.82-.35-1.54-.75-2.18-1.2-1.77-1.22-2.91-2.75-3.82-4.34-1.81-.28-3.64-.8-5.41-2.02-.64-.44-1.27-.97-1.89-1.61-.89-.92-2.09-2.37-2.39-3.64-.2-.85.18-1.98,1.03-2.19Z"/>
								</g>
								<path className={styles.cls3}
								      d="M528.31,133.17c.32.25.73.36,1.16.29.83-.14,1.41-.92,1.3-1.75-.01-.09-.47-4.31,2.45-11.26.33-.79-.03-1.68-.8-2-.77-.32-1.67.07-2,.85-3.31,7.87-2.69,12.69-2.66,12.89.06.4.26.74.56.97Z"/>
							</g>
							<path className={styles.cls7}
							      d="M529,106.87c-.47,1.55,1.35,3.48,4.07,4.32,2.72.84,5.31.27,5.79-1.28.47-1.55-1.35-3.48-4.07-4.32-2.72-.84-5.31-.27-5.79,1.28Z"/>
							<path className={styles.cls7}
							      d="M561.69,87.74c.36.95,2.06,1.19,3.79.53s2.85-1.96,2.48-2.91c-.36-.95-2.06-1.19-3.79-.53-1.73.66-2.85,1.96-2.48,2.91Z"/>
							<path className={styles.cls7}
							      d="M590.91,112.73c.36,1.27,2.5,1.77,4.78,1.12,2.28-.65,3.84-2.2,3.48-3.47-.36-1.27-2.5-1.77-4.78-1.12-2.28.65-3.84,2.2-3.48,3.47Z"/>
						</g>
					</g>
				</g>
				<g id="Stripe">
					<path
						className={styles.cls11}
						d="M638.74,142.26c-13.79-30.97-45.43-59.02-73.3-65.21-2.14-.47,9.31-12.34,16.5-11.54,18.56,2.06,64.47,39.56,65.76,64.69.19,3.74-7.66,14.96-8.95,12.06Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Winner_Silver_Upper">
					<path className={styles.cls9}
					      d="M679.72,140.4c-3.64,6.14-24.58,24.14-30.35,19.34-9.05-7.52-12.87-49.1-13.6-53.09-.91-5,0-10.52,2.95-13.48,4.81-4.82,14.74-1.81,19.97,1.36,20.25,12.31,32.52,26.48,21.03,45.86Z"/>
					<path className={styles.cls7}
					      d="M641.24,261.63c-3.22-8.4-9.19-15.3-17.63-20.74-7.05-4.54-23.31-7.32-29.53-.57-1.07,1.16-1.8,2.52-2.37,3.91-4.3,10.31-.02,22.64,10.2,29.39-4.35,1.04-10.02,2.56-10.18,8.69-.26,10.38,23.37,9.12,26.65,2.84,1.56-2.98.31-5.82-.61-8.65,1.71,2.73,4.14,5.14,8.3,6.96,4.16,1.82,9.3,1.64,12.96-1.01,2.56-1.86,6.53-9.56,2.21-20.82Z"/>
				</g>
			</g>
			<g id="Puppy_Winner_Silver-2" data-name="Puppy_Winner_Silver">
				<g id="Puppy_Winner_Silver_Lower-2" data-name="Puppy_Winner_Silver_Lower">
					<g>
						<path className={styles.cls9}
						      d="M250.62,259.86c-1.63,2.31-3.41,4.55-5.4,6.63-2.38,2.49-19.82,12.93-13.19,16.93,1,.61,2.27.74,3.44.49,3.95-.86,7.95-1.84,11.44-3.67,5.75-3.01,9.55-7.98,12.1-13.49-.63-1.51-1.57-2.9-2.77-4.02-1.56-1.45-3.53-2.44-5.62-2.86Z"/>
						<path className={styles.cls7}
						      d="M260.33,247.59c-.48.04-1.04.21-1.7.55-3.06,1.59-4.34,6.14-6.04,8.8-.63.99-1.29,1.96-1.97,2.92,2.09.42,4.06,1.41,5.62,2.86,1.2,1.12,2.14,2.51,2.77,4.02,1.13-2.44,2.02-4.99,2.73-7.52.49-1.77,2.73-11.95-1.41-11.65Z"/>
					</g>
					<path className={styles.cls8}
					      d="M212.15,271.49c.11-.66.15-1.32.15-1.99-.04-3.35-1.38-6.66-3.16-9.72-4.06-6.99-11.09-14.24-19.82-17.26-7.91-2.74-24.58-4.1-30.17,1.92-.96,1.04-1.56,2.23-2.01,3.43-3.35,8.97,2.07,19.28,12.91,24.58-4.26,1.07-9.37,2.45-9.37,7.84,0,7.49,25.77,7.25,26.43.21.28-2.96.16-2.76-.29-5.47,2.39,3.36,3.97,3.4,8.29,4.77s9.45,1,12.86-1.42c2.39-1.69,3.76-4.22,4.18-6.89Z"/>
					<path className={styles.cls8}
					      d="M139.24,210.55c1.03.55,2.3.93,3.87,1.11,2.17.25,4.37-.23,6.29-1.21,2.02-1.03,3.22-3.49,5.12-4.36,1.96-.89,4.61,1.47,6.35,2.45,2.39,1.34,4.8,2.68,7.43,3.56,6.05,2.02,13.33,3.04,16.82-2.97,4.55-7.83,5.57-19.5,6.93-28.16.39-2.48.73-5.02.23-7.49-2.25-11.06-15.5-4.4-18.81,2.3-2.29,4.63-4.1,18.99-4.6,19.23-.48.24-11.11-8.08-16.09-9.19-4.98-1.12-8.85.17-11.84,4.2-3.97,5.34-8.77,16.77-1.71,20.53Z"/>
					<g>
						<path className={styles.cls7}
						      d="M198.24,268.78c-9.09-.31-40.4-31.37-34.15-77.26,1.13-8.27,1.62-17.56,4.96-26.86,5.58-2.94,17.38-3.33,25.85-4.58,5.76-.85,18.73-3.24,23.8,1,4.82,4.03,12.27,15.74,12.27,24.19,0,5.88-2.96,12.57-2.17,18.02,3.39,23.59,12.64,42.36,14.99,50.31,7.23,24.43-38.86,15.4-45.54,15.18Z"/>
						<path className={styles.cls8}
						      d="M223.94,248.62c1.4-15.27-26.25-20.38-32.8-8.74-16.07-11.05-22.78-30.82-23.06-51.76-.04-2.88.65-17.66,2.17-21.5,5.27,8.78,32.44,8.57,42.36.44-1.09-3.37-40.91-5.24-43.75-3.52-20.1,37.89,7.8,107.38,27.32,107.05,5.46-.09,27-13.71,27.75-21.97Z"/>
					</g>
				</g>
				<path
					id="Shirt-2"
					data-name="Shirt"
					className={styles.cls2}
					d="M162.53,195.9c.67-7.84.76-32.03,7.28-36.34l2.78,1.95c-4.82,7.04-6.48,26.67,2.13,30.13,7.93,3.19,12.6-3.02,15.36-8.05,7.56-13.75,25.99-29.98,35.66-21.97,9.95,8.24,6.06,41.54,6.97,51.28.71,7.59,4.29,12.98,6.66,17.01-18.33,13.68-44.29,15.54-71.51,6.09-4.91-14.51-6-32.18-5.33-40.09ZM215.21,201.69c9.17-.86,15.92-8.91,15.07-17.97-.85-9.06-8.98-15.72-18.15-14.86s-15.92,8.91-15.07,17.97c.85,9.06,8.98,15.72,18.15,14.86Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-2" data-name="Stripes">
					<path
						className={styles.cls11}
						d="M179.48,193.95c6.64,0,10.85-6.34,15.68-13.61,5.35-8.06,11.42-17.19,22.26-19.97l-.62-2.41c-11.77,3.02-18.12,12.57-23.72,21-4.88,7.34-8.73,13.14-14.63,12.43-3.15-.38-5.43-1.83-6.97-4.42-3.73-6.27-2.01-17.69.4-23.79l-2.32-.92c-2.42,6.13-4.62,18.6-.22,25.98,1.94,3.25,4.9,5.14,8.81,5.62.45.05.89.08,1.32.08Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M178.8,229.43c7.73,1.45,15.47,2.03,22.99,1.7,12.11-.53,23.8-3.51,33.55-8.58-.72-1.54-1.41-3.65-1.71-4.69-9.25,4.92-20.41,7.93-32.05,8.44-12.8.56-24.6-1.02-37.5-5.78-.04.78.62,3.91.72,5.02,5.38,1.89,8.52,2.88,14,3.9Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M212.02,167.66c9.82-.92,18.56,6.23,19.47,15.95.91,9.71-6.34,18.37-16.16,19.29s-18.56-6.23-19.47-15.95c-.91-9.71,6.34-18.37,16.16-19.29ZM215.1,200.49c8.49-.8,14.77-8.27,13.98-16.65-.79-8.39-8.34-14.56-16.83-13.76s-14.77,8.27-13.98,16.65c.79,8.39,8.34,14.56,16.83,13.76Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Winner_Silver_Mid-2" data-name="Puppy_Winner_Silver_Mid">
					<path className={styles.cls5}
					      d="M225.38,146.7c1.9,2.37.57,8.6-.34,11.47-.86,2.73-4.09,8.94-20.05,12.93-15.95,3.99-34.48-2.78-36.05-7.33-4.12-11.94,7.42-17.06,17.38-20.84,9.38-3.56,24.04-7.66,32.87-1.21,1.9,1.39,4.73,3.15,6.2,4.99Z"/>
					<g>
						<path className={styles.cls10}
						      d="M225.74,161.61c-7.79,7.92-41.68,18.23-50.98,15.82-5.58-4.03-10.1-12.12-9.89-16.19.11-2.19.41-3.35,3.33-3.72-.03,6.24,4.74,15.6,6.81,18.51,25.06-3.22,46.6-18,49.37-17.38,1.31.29,1.63,2.1,1.36,2.97Z"/>
						<g>
							<circle className={styles.cls1} cx="173.25" cy="191.69" r="11.51"/>
							<ellipse className={styles.cls12} cx="175.09" cy="177.92" rx="2.68" ry="1.38"
							         transform="translate(-24.45 327.91) rotate(-82.39)"/>
							<circle className={styles.cls12} cx="172.3" cy="190.33" r="11.51"/>
						</g>
					</g>
					<path className={styles.cls7}
					      d="M170.44,215.46c1.03.55,2.3.93,3.87,1.11,2.17.25,4.37-.23,6.29-1.21,2.02-1.03,3.22-3.49,5.12-4.36,1.96-.89,4.61,1.47,6.35,2.45,2.39,1.34,4.8,2.68,7.43,3.56,6.05,2.02,14.43,2.67,17.92-3.34,4.55-7.83,8.9-19.73,10.27-28.4,1.22-7.75-16.58-23.22-25.2-5.83-2.29,4.63-2.29,18.05-2.78,18.3-.48.24-10.74-5.9-15.72-7.01-4.98-1.12-8.85.17-11.84,4.2-3.97,5.34-8.77,16.77-1.71,20.53Z"/>
					<path className={styles.cls9}
					      d="M236.08,261.8c-2.7,4.82-4.01,10.45-3.43,15.94.24,2.28.9,4.65,2.12,6.56.45-.11.95-.1,1.38-.26,13.11-2.05,13.45-16.97,6.59-33.55-2.6,2.33-4.96,8.26-6.66,11.31Z"/>
					<g>
						<path className={styles.cls9}
						      d="M157.7,48.2c-4.56,4.84-14.86,28.54-8.71,32.73,9.64,6.58,49.08.16,52.96-.12,4.88-.36,9.69-2.51,11.53-5.96,2.99-5.61-2.75-14.08-7.24-18.16-17.41-15.8-34.14-23.77-48.54-8.49Z"/>
						<g>
							<path className={styles.cls7}
							      d="M165.52,156.33c3.44-5.18,5.11-11.52,4.66-17.73-.46-6.4-3.07-12.8-1.65-19.06,1.94-8.52,10.5-13.61,18.44-17.26,12.05-5.54,24.55-10.11,37.33-13.63,3.26-.9,6.64-1.72,10.01-2.12-5.06-4.92-10.93-9.1-17.28-12.68-.83.35-1.7.62-2.54.83-5.4,1.33-10.93,2.2-16.12,4.26-10.85,4.31-19.22,13.42-28.35,20.5-4.28,3.32-9.4,6.24-14.7,5.65-5.28-.59-9.47-4.47-14.23-6.78-2.98-1.45-6.26-2.2-9.58-2.34-4.62,2.12-8.86,4.81-10.95,8.52-5.26,9.32-6.13,22.6-1.63,30.38,7.62,13.16,16.76,18.56,27.3,24.23,4.51,2.42,9.19,4.16,13.94,5.36,1.31-2.83,3.58-5.48,5.34-8.12Z"/>
							<path className={styles.cls4}
							      d="M233.53,148.78c4.52-4.25,8.69-8.94,12.33-13.94,6.33-8.69,6.21-17.01,2.62-26.8-3.11-8.49-8.05-15.56-14.17-21.51-3.37.4-6.75,1.22-10.01,2.12-12.79,3.53-25.28,8.09-37.33,13.63-7.94,3.65-16.5,8.74-18.44,17.26-1.42,6.26,1.19,12.66,1.65,19.06.44,6.21-1.22,12.54-4.66,17.73-1.75,2.64-4.03,5.3-5.34,8.12,31.32,7.92,65.51-8.31,73.34-15.67Z"/>
							<path className={styles.cls4}
							      d="M155.33,105.09c5.3.59,10.43-2.32,14.7-5.65,9.12-7.09,17.49-16.19,28.35-20.5,5.18-2.06,10.72-2.93,16.12-4.26.83-.21,1.71-.47,2.54-.83-.92-.52-1.85-1.03-2.79-1.52-11.4-6.01-22.02-9.92-35.14-4.91-6.57,2.51-12.31,9.2-17.33,13.96-5.87,5.56-9.82,6.76-17.55,9.7-3.58,1.37-8.32,2.87-12.7,4.88,3.32.14,6.6.9,9.58,2.34,4.76,2.31,8.95,6.2,14.23,6.78Z"/>
						</g>
						<path className={styles.cls6}
						      d="M136.99,138.06c-5.31-1.81-7.16-3.82-8.53-6.34-1.9,0-3.7-.49-4.67-1.07-1.63,1.45,1.56,9.56,6.4,10.92,4.5,1.26,6.7-1.72,6.8-3.52Z"/>
						<g>
							<g>
								<path className={styles.cls3}
								      d="M197.28,121.65c-4.83,4.76-10.9,6.42-13.56,3.7-2.66-2.71-.91-8.77,3.92-13.52,4.83-4.76,10.9-6.42,13.56-3.7,2.66,2.71.91,8.77-3.92,13.52Z"/>
								<path className={styles.cls3}
								      d="M165.86,95.9c-5.13,4-10.85,5.24-12.78,2.75-1.92-2.49.68-7.75,5.81-11.75,5.13-4,10.85-5.23,12.78-2.75,1.93,2.49-.68,7.75-5.81,11.75Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls3}
									      d="M146.19,114.91c-.55-2.13-2.45-4.66-4.9-6.77h0s0,0,0,0c0,0,0,0-.01,0h0c-2.39-2.18-5.12-3.76-7.29-4.03-10.34-1.32-9.93,10.59-3.93,16.85h0s0,0,0,0c0,0,0,0,.01.01h0c6.93,5.21,18.71,4.12,16.11-6.04Z"/>
									<path className={styles.cls3}
									      d="M119.21,123.59c.18-.05.39-.05.62,0,.75.17.87.95,1.17,1.53.41.82.98,1.63,1.61,2.29,2.04,2.1,4.28,2.74,6.97,2.99l.07-.1s.04.07.06.11c.04,0,.08,0,.12.01l-.07.1c1.19,2.42,2.59,4.29,5.28,5.45,1.86.8,6.24,1.32,8.25.37.71-.34,1.54-.03,1.85.68.31.71,0,1.55-.72,1.89-3.12,1.47-8.44.59-10.55-.32-.82-.35-1.54-.75-2.18-1.2-1.77-1.22-2.91-2.75-3.82-4.34-1.81-.28-3.64-.8-5.41-2.02-.64-.44-1.27-.97-1.89-1.61-.89-.92-2.09-2.37-2.39-3.64-.2-.85.18-1.98,1.03-2.19Z"/>
								</g>
								<path className={styles.cls3}
								      d="M128.13,133.17c.32.25.73.36,1.16.29.83-.14,1.41-.92,1.3-1.75-.01-.09-.47-4.31,2.45-11.26.33-.79-.03-1.68-.8-2-.77-.32-1.67.07-2,.85-3.31,7.87-2.69,12.69-2.66,12.89.06.4.26.74.56.97Z"/>
							</g>
							<path className={styles.cls7}
							      d="M128.82,106.87c-.47,1.55,1.35,3.48,4.07,4.32,2.72.84,5.31.27,5.79-1.28.47-1.55-1.35-3.48-4.07-4.32-2.72-.84-5.31-.27-5.79,1.28Z"/>
							<path className={styles.cls7}
							      d="M161.51,87.74c.36.95,2.06,1.19,3.79.53s2.85-1.96,2.48-2.91c-.36-.95-2.06-1.19-3.79-.53-1.73.66-2.85,1.96-2.48,2.91Z"/>
							<path className={styles.cls7}
							      d="M190.73,112.73c.36,1.27,2.5,1.77,4.78,1.12,2.28-.65,3.84-2.2,3.48-3.47-.36-1.27-2.5-1.77-4.78-1.12-2.28.65-3.84,2.2-3.48,3.47Z"/>
						</g>
					</g>
				</g>
				<g id="Stripe-2" data-name="Stripe">
					<path
						className={styles.cls11}
						d="M238.56,142.26c-13.79-30.97-45.43-59.02-73.3-65.21-2.14-.47,9.31-12.34,16.5-11.54,18.56,2.06,64.47,39.56,65.76,64.69.19,3.74-7.66,14.96-8.95,12.06Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Winner_Silver_Upper-2" data-name="Puppy_Winner_Silver_Upper">
					<path className={styles.cls9}
					      d="M279.54,140.4c-3.64,6.14-24.58,24.14-30.35,19.34-9.05-7.52-12.87-49.1-13.6-53.09-.91-5,0-10.52,2.95-13.48,4.81-4.82,14.74-1.81,19.97,1.36,20.25,12.31,32.52,26.48,21.03,45.86Z"/>
					<path className={styles.cls7}
					      d="M241.06,261.63c-3.22-8.4-9.19-15.3-17.63-20.74-7.05-4.54-23.31-7.32-29.53-.57-1.07,1.16-1.8,2.52-2.37,3.91-4.3,10.31-.02,22.64,10.2,29.39-4.35,1.04-10.02,2.56-10.18,8.69-.26,10.38,23.37,9.12,26.65,2.84,1.56-2.98.31-5.82-.61-8.65,1.71,2.73,4.14,5.14,8.3,6.96,4.16,1.82,9.3,1.64,12.96-1.01,2.56-1.86,6.53-9.56,2.21-20.82Z"/>
				</g>
			</g>
		</svg>
	);
};