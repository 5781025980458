import React, {useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import styles from "./SelectedOffer.scss";
import find from "lodash/find";
import * as CouponActions from "actions/CouponActions";
import Button from "components/common/Button";
import InvoiceDetails from "components/common/InvoiceDetails";
import Modal from "components/common/Modal";
import ToggleSwitch from "components/common/ToggleSwitch";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function SelectedOffer(props) {
    const [sampleInvoices, setSampleInvoices] = useState([]);
    const [showSampleInvoice, setShowSampleInvoice] = useState(false);
    const [showTerms, setShowTerms] = useState(false);
    if(!(props.offerId && props.offerGroupId && props.offer)) {
        return(
            <div className={styles.offerHeader}>
                <div>Click an offer to view it</div>
            </div>
        );
    }

    const handleOptInChanged = (offer, optIn) => {
        if (props.canOptInCouponLibrary && !props.isReadOnlyView) {
            if (optIn) {
                props.optInForOffer(props.offer.offerId, props.programId);
            } else {
                props.optOutForOffer(props.offer.offerId, props.programId);
            }
        }
    }

    const handleSampleInvoiceClicked = (sampleInvoices) => {
        setSampleInvoices(sampleInvoices);
        setShowSampleInvoice(true);
    }
    
    const handleCloseSampleInvoiceDetails = () => {
        setSampleInvoices([]);
        setShowSampleInvoice(false);
    }

    const fixAdminLinks = (textBody) => {
        // TODO: Temporary solution to Galliprant MarketingTracking emails in special instructions breaking for Admins.
        let response = textBody;
        if(textBody.indexOf('href=\"/MarketingTracking\"') > 0 && !!props.clinicId) {
            response = textBody.replace('MarketingTracking', `admin/clinic/${props.clinicId}/MarketingTracking`);
        }

        return response;
    }

    const optedIn = !!(props.offer && props.offer.activationDate);

    return (
        <>
            <div className={styles.offerHeader}>
                <div dangerouslySetInnerHTML={{ __html: props.offer.name}} />
                <div dangerouslySetInnerHTML={{ __html: props.offerGroup.description }} />
            </div>
            <div className={styles.offerView}>
                <div className={styles.highlight}>
                    <div className={styles.imageContainer}>
                        <img src={props.offer.imageUrl} />
                        {props.offer.pdfUrl && (
                            <a href={props.offer.pdfUrl}
                                target="_blank"
                            >
                                <i className="fa fa-expand-alt" />
                            </a>
                        )}
                    </div>
                    <div className="flex">
                        <div className={styles.offerActions}>
                            <div className={classnames({ [styles.optedOut]: !optedIn })}>
                                <div className={styles.optIn}>Opt-Out</div>
                            </div>
                            <ToggleSwitch
                                id="opt_in"
                                updateState={(id, value) => handleOptInChanged(props.offer, value)}
                                force={optedIn}
                                useNotSet={false}
                                disabled={!props.canOptInCouponLibrary || !!props.offer.isReadOnly || !!props.isReadOnlyView}
                            />
                            <div className={classnames({ [styles.optedIn]: optedIn })}>
                                <div className={styles.optIn}>
                                    Opt-In
                                    {optedIn && <div className={styles.date}>{moment(props.offer.activationDate).format("MM/DD/YYYY")}</div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.description}>
                    {props.offer.description &&
                        <div className={styles.blurb}>
                            <span dangerouslySetInnerHTML={{ __html: props.offer.description }} />
                        </div>
                    }
                    {props.offer.specialInstructions &&
                        <div className="margin-top-lg padding-bottom-xlg">
                            <span dangerouslySetInnerHTML={{ __html: fixAdminLinks(props.offer.specialInstructions) }} />
                        </div>
                    }
                    {(props.offer.terms || props.offer.sampleInvoices) &&
                        <div className={styles.terms}>
                            {props.offer.terms &&
                                <Button
                                    type="default"
                                    onClick={() => {setShowTerms(true)}}
                                >
                                    View Special Instructions
                                </Button>
                            }
                            {props.offer.sampleInvoices &&
                                <div className="margin-top-sm">
                                    <Button
                                        type="default"
                                        onClick={() => handleSampleInvoiceClicked(props.offer.sampleInvoices)}
                                    >
                                        View Sample Invoices
                                    </Button>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
            <Modal
                show={showSampleInvoice}
                onClose={handleCloseSampleInvoiceDetails}
                modalTitle="Sample Invoices"
                icon="fa-file-invoice-dollar"
                mediumSmall
            >
                <InvoiceDetails
                    sampleInvoices={sampleInvoices}
                    handleClose={handleCloseSampleInvoiceDetails}
                />
            </Modal>
            <Modal
                show={showTerms}
                modalTitle="Special Instructions"
                onClose={() => setShowTerms(false)}
            >
                {!!props.offer.terms && <span dangerouslySetInnerHTML={{ __html: props.offer.terms }} />}
            </Modal>
        </>
    );

}

SelectedOffer.propTypes = {
    programId: PropTypes.number.isRequired,
    offerId: PropTypes.number.isRequired,
    offerGroupId: PropTypes.number.isRequired,
    isReadOnlyView: PropTypes.bool, // Disable interaction with opt-in elements
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const offerGroups = state.entities.programOffers[ownProps.programId]?.offers || null;
        const offerGroup = offerGroups[ownProps.offerGroupId];
        //Permissions
        const canOptInCouponLibrary = userHasPermission(PermissionTypes.OPT_IN, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        return {
            offerGroup,
            canOptInCouponLibrary,
            //Filter the offerGroups that don't have any "isVisibleToClinic" offers
            offer: find(offerGroup?.offers, {offerId: ownProps.offerId}) || null,
        }
    },
    (dispatch) => ({
        optInForOffer: (offerId, programId) => dispatch(CouponActions.optInForOffer(offerId, programId)),
        optOutForOffer: (offerId, programId) => dispatch(CouponActions.optOutForOffer(offerId, programId)),
    }),
)(SelectedOffer);
