import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./LeaderBoard.scss";
import includes from "lodash/includes"
import map from "lodash/map"
import * as ProviderApi from "api/ProviderApi";
import ProgressBar from "components/common/ProgressBar";
import { addCommasToNumber } from "utils/numeric";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as OrgNodeTypes from "constants/OrgNodeTypes";
import * as UserPermissions from "constants/UserPermissions";

function LeaderBoard(props) {
    const { pathname } = useLocation();
    const isDemo = !!includes(pathname, "/demo") && props.canViewClinicBIGamesDashboard;
    const { teams, max, labels, subLabels, showValues, numVisible } = props;
    let mappedTeams = [];
    // If it's a PoolParty or PuppyBowl game then map team to fit this file
    if (props.game.gameType === 'PoolParty' || props.game.gameType === 'PuppyBowl') {
        props.game.teams.forEach(team => {
            mappedTeams.push({
                ...team,
                growth:  100 + Math.round(team.PercentGrowth),
                doses: team.totalDoses,
                previous: team.previousDoses
            });
        });
    } else {
        mappedTeams = teams;
    }
    const columnLabels = labels || ["Team", "Progress"];
    const thresholds = [0, 50];
    const colors = ["#C9264D", "#317D3D"];

    const teamList = numVisible ? mappedTeams.slice(0, numVisible) : mappedTeams;

    const [currentOrgNode, setCurrentOrgNode] = useState({});

    useEffect(
        useCallback(() => {
            if (props.nodeId) {
                ProviderApi.getNode(props.nodeId)
                    .then(res => {
                        setCurrentOrgNode(res.body);
                    })
                    .catch(error => {
                        handleErrorResponse('loading node', error);
                    });
            }
    }), [])

    const userIsRep = ((currentOrgNode?.nodeType === OrgNodeTypes.TERRITORY) && (currentOrgNode?.nodeId === props.userNodeId));

    const getTeamRows = () => {
        const showDoseCountsAtAll = !props.progressOnly;
        const showAllDoseCounts = !!props.isAdminView;

        return map(teamList, (team, index) => {
            const showMyDoses = showDoseCountsAtAll ? showAllDoseCounts || props.showDosesOnlyForClinicId === team.clinicId : false;
            const highlight = !isDemo && (team.clinicId === props.clinicId && !userIsRep);
            const clinicName = props.clinics?.[team.clinicId]?.clinicName || team.name;
            const teamName = !!(clinicName && !props.clinicId && (userIsRep || props.isAdminView)) ? clinicName : team.alias;

            return (
                // The class on tr is needed for printing.
                <tr key={`team_${team.id}_${team.name}`} className={classnames({
                    "pageBreak": index === 21 || ((index - 21) % 26 === 0),
                })}>
                    <td
                        title={teamName}
                        className={classnames(styles.team, {
                            [styles.highlight]: highlight
                        })}
                    >
                        {teamName}
                    </td>
                    <td className={classnames(styles.progress, { [styles.highlight]: highlight })}>
                        <ProgressBar
                            progress={team.growth/2}
                            showLabel
                            customLabel={Math.round(team.doses) !== 0 ? `${team.growth}%` : '0%'}
                            thresholds={thresholds}
                            colors={colors}
                        />
                    </td>
                    {showDoseCountsAtAll &&
                        <>
                            <td className={classnames(styles.doses, { [styles.highlight]: highlight })}>
                                {showMyDoses ? (
                                    addCommasToNumber(team.doses)
                                ) : (
                                    '--'
                                )}
                            </td>
                            <td className={classnames(styles.doses, { [styles.highlight]: highlight })}>
                                {showMyDoses ? (
                                    addCommasToNumber(team.previous)
                                ) : (
                                    '--'
                                )}
                            </td>
                        </>
                    }
                </tr>
            );
        });
    }

    const renderLabels = () => {
        return map(columnLabels, (label, index) => {
            if (props.progressOnly && index > 1) {
                return;
            }
            return (
                <th key={`label_${index}`}>
                    <div>{label}</div>
                    {!!subLabels.length && (
                        <div className="text-x-sm" title={subLabels[index]}>{subLabels[index]}</div>
                    )}
                </th>
            );
        });
    }
    
    return (
        <div
            className={classnames(styles.root, props.className, { [styles.progress]: props.progressOnly})}
        >
            <table className="table striped">
                <thead>
                <tr>
                    {renderLabels()}
                </tr>
                </thead>
                <tbody>
                    {getTeamRows()}
                </tbody>
            </table>
        </div>
    )
}

LeaderBoard.propTypes = {
    nodeId: PropTypes.number,
    teams: PropTypes.array.isRequired,
    labels: PropTypes.array,
    subLabels: PropTypes.array,
    numVisible: PropTypes.number,
    progressOnly: PropTypes.bool, // If `true`, don't show dose counts at all
    clinicId: PropTypes.number,
    showDosesOnlyForClinicId: PropTypes.number, // If specified, show dose counts only for this clinic and highlight the row
    isAdminView: PropTypes.bool, // Trying to keep this simple, but there are a lot of combinations of details
    className: PropTypes.string,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;

        //Permissions
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        return {
            canViewClinicBIGamesDashboard,
            canViewProviderBiGames,
            clinics: state.entities.nodeClinics[ownProps.nodeId],
            userNodeId: userProfile.nodeId,
        }
    },
)(LeaderBoard);
