import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {map, filter, flatMap, orderBy} from "lodash";
import DropdownSearch from "components/common/DropdownSearch";
import Button from "components/common/Button";
import * as styles from "./PlanEnrollmentMapping.scss";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function PlanEnrollmentMapping(props) {
    const handleChange = ({name, value}) => {
        let id = props.planEnrollmentMapping.id;
        let isNew = props.planEnrollmentMapping.isNew || false;
        if (!props.planEnrollmentMapping.id) {
            id = props.nextId || 0;
            isNew = true
        }
        const newData = {
            ...props.planEnrollmentMapping,
            id,
            isNew,
            [name]: value
        }
        props.handleChange({enrollmentMapping: newData});
    }

    const PIMS_OPTIONS = map(orderBy(props.pims, "name"), (pim) => {
        return { name: `(${pim.remoteClinicProductDisplayID}) ${pim.name} [${pim.remoteClinicHospitalID}]`, value: pim.clinicProductId };
    });

    const HIGHLIGHTED_PIMS_ID = flatMap(filter(props.pims, pim => pim.productUsedRowCount > 0), "clinicProductId");

    return (
        <div className={styles.root}>
            <DropdownSearch
                options={PIMS_OPTIONS}
                name="clinicProductId"
                label={props.label}
                value={props.planEnrollmentMapping.clinicProductId || null}
                onChange={handleChange}
                highlightedOptions={HIGHLIGHTED_PIMS_ID}
                disabledOptions={props.usedPims}
                disabled={props.readOnly}
            />
            {(props.handleDeleteMapping && props.planEnrollmentMapping.clinicProductId && props.canEditClinicWellnessPlans) && (
                <Button
                    className={styles.delete}
                    onClick={props.handleDeleteMapping}
                    text
                    type="danger"
                    disabled={!PIMS_OPTIONS || props.readOnly}
                >
                    <i className="fa fa-times"/>
                </Button>
            )}
        </div>
    );
}

PlanEnrollmentMapping.defaultPropTypes = {
    readOnly: false
}

PlanEnrollmentMapping.propTypes = {
    handleChange: PropTypes.func.isRequired,
    planEnrollmentMapping: PropTypes.object.isRequired,
    nextId: PropTypes.number,
    usedPims: PropTypes.arrayOf(PropTypes.number),
    handleDeleteMapping: PropTypes.func,
    readOnly: PropTypes.bool,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canEditClinicWellnessPlans = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        return {
            pims: state.entities.pims?.products || {},
            canEditClinicWellnessPlans,
        }
    }
)(PlanEnrollmentMapping);