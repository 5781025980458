import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { produce } from "immer";
import styles from "./PaymentsModal.scss";
import map from "lodash/map";
import PaymentsHeader from 'components/greenlineWellness/widgets/PaymentsHeader';
import PaymentOption from 'components/greenlineWellness/widgets/PaymentOption';
import WellnessPaymentsStripe from "components/greenlineWellness/widgets/WellnessPaymentsStripe";
import {handleErrorResponse} from "utils/request";
import * as WellnessApi from "api/WellnessApi"

export default function PaymentsModal(props) {
    const [paymentOptions, setPaymentOptions] = useState([]);
    // TODO: isLoading does not actually do anything.
    const [isLoading, setIsLoading] = useState(false);
    const [stripeCustomerId, setStripeCustomerId] = useState('')

    useEffect(() => {
        if(props.clinicId && props.selectedPlan) {
            getCustomerStripeId()
        }
    }, [props.clinicId, props.selectedPlan]) 

    const getCustomerStripeId = async () => {
        try {
            const resp = await WellnessApi.getStripeCustomerId(props.clinicId, props.selectedPlan.petOwnerId)
            if(resp.statusCode === 200) {
                setStripeCustomerId(resp.body)
                getCustomerCards(resp.body)
            }
        }
        catch(error) {
            handleErrorResponse('retrieving customer', error)
        }
    }

    const getCustomerCards = (customerId=stripeCustomerId) => {
        setIsLoading(true);
        WellnessApi.getWellnessCards(props.clinicId, customerId || stripeCustomerId)
        .then((results) => {
            setPaymentOptions(results.body)
        })
        .catch((error) => {
            handleErrorResponse("getting customer cards", error);
        })
        .finally(() => {
            setIsLoading(false);
        });
    }

    useEffect(() => {
        if(props.isLoggedIn && !props.userProfile) {
            props.loadCurrentUser();
        }
    });

    const updateDefaultCard = async (cardId) => {
        await getCustomerCards()
        const update = produce(paymentOptions, (updatedOptions) => {
            return map(updatedOptions, opt => ({
                ...opt,
                isDefault: opt.id === cardId,
            }));
        });
        setPaymentOptions(update);
    }

    const paymentElements = map(paymentOptions, (c) => {
        return (
            <PaymentOption
                clinicId={props.clinicId}
                key={c.id}
                card={c}
                paymentMethodsChanged={getCustomerCards}
                customerId={stripeCustomerId}
                canEditPaymentSettings={props.canEditPaymentSettings}
            />
        );
    });

    return (
        <WellnessPaymentsStripe>
            <PaymentsHeader
                clinicId={props.clinicId}
                cards={paymentOptions}
                getCards={props.getCards}
                setIsLoading={(isLoading) => setIsLoading(isLoading)}
                onDefaultCardUpdated={(cardId) => updateDefaultCard(cardId)}
                canEditPaymentSettings={props.canEditPaymentSettings}
                userId={stripeCustomerId}
                paymentMethodsChanged={getCustomerCards}
            />
            <div className={styles.paymentOptions}>
                { paymentElements }
            </div>
        </WellnessPaymentsStripe>
    );
}

PaymentsModal.defaultProps = {
    canEditPaymentSettings: false,
}

PaymentsModal.propType = {
    clinicId: PropTypes.number.isRequired,
    planId: PropTypes.number.isRequired,
    canEditPaymentSettings: PropTypes.bool,
    plans: PropTypes.array.isRequired
}