import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import moment from "moment";
import styles from "./AccountHoldHistory.scss";
import Button from "components/common/Button";
import * as AdminApi from "api/AdminApi";
import {handleErrorResponse} from "utils/request";

export default function AccountHoldHistory(props) {
    const [holds, setHolds] = useState([]);

    const loadHoldHistory = () => {
        AdminApi.loadAccountHoldHistory(props.clinicId)
            .then((res) => {
                const manufacturerHolds = filter(res.body, {manufacturerId: props.providerId})
                setHolds(orderBy(manufacturerHolds, ["startDate"], ["desc"]));
            })
            .catch((error) => {
                handleErrorResponse("loading account hold history", error);
            });
    };

    useEffect(() => {
        if(props.clinicId && props.providerId) {
            loadHoldHistory();
        }
    }, [props.providerId, props.clinicId]);

    return (
        <div className={styles.root}>
            <table className="table full-width">
                <tbody>
                    {map(holds, hold => (
                        <>
                            <tr className={styles.noBorder}>
                                <td className="text-bold">Added</td>
                                <td>{!!hold?.startDate ? moment(hold?.startDate).format("MM/DD/YYYY") : "UNKNOWN"}</td>
                                <td>{hold?.information?.holdInfo?.userInitials}</td>
                            </tr>
                            <tr className={styles.noBorder}>
                                <td colSpan={3}>
                                    Reason: {hold?.information?.holdInfo?.reason}
                                </td>
                            </tr>
                            <tr className={styles.noBorder}>
                                <td className="text-bold">Removed</td>
                                <td>{!!hold?.endDate ? moment(hold?.endDate).format("MM/DD/YYYY") : "UNKNOWN"}</td>
                                <td>{hold?.information?.releaseInfo?.userInitials}</td>
                            </tr>
                            <tr >
                                <td colSpan={3}>
                                    Reason: {hold?.information?.releaseInfo?.reason}
                                </td>
                            </tr>
                         </>
                    ))}
                </tbody>
            </table>
            <div className="flex justify-flex-end border-top">
                <Button
                    type="gray"
                    onClick={props.onClose}
                >
                    Close
                </Button>
            </div>
        </div>
    )
}

AccountHoldHistory.propTypes = {
    clinicId: PropTypes.number,
    providerId: PropTypes.number,
    onClose: PropTypes.func.isRequired,
};