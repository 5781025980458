import React from 'react'
import PropTypes from "prop-types";
import classnames from 'classnames'
import * as ButtonTypes from "constants/ButtonTypes";

export default function Button(props) {
    const {
        buttonType = "button",
        className,
        disabled = false,
    } = props;

    const btnColor = props.type ? ButtonTypes.getTypeByColorString(props.type) : ButtonTypes.DEFAULT; // See ButtonTypes

    const buttonClasses = classnames('btn', btnColor, {
        "active": props.active,
        "btn-sm": props.small,
        "btn-wide": props.wide,
        "btn-full-width": props.superwide,
        "btn-icon": props.icon,
        "btn-icon-only": props.iconOnly,
        "btn-full": !props.text,
        "btn-link": props.text,
        "btn-tall": props.tall,
        "btn-reset": props.reset,
        "btn-lg": props.large,
        "action": props.action,
        "reverse-hover": props.reverseHover,
        "text-nowrap": props.noWrap,
        "btn-sleeping": props.sleeping,
        [className]: !!className,
    });

    return (
        <button
            data-testid={props["data-testid"]}
            style={props.style}
            type={buttonType}
            className={buttonClasses}
            onClick={props.onClick}
            disabled={disabled}
            title={props.title}
        >
            {props.children}
        </button>
    )
};

Button.propTypes = {
    ["data-testid"]: PropTypes.string,
    buttonType: PropTypes.string, // input type
    type: PropTypes.string, // ButtonTypes constants
    reset: PropTypes.bool,
    small: PropTypes.bool,
    wide: PropTypes.bool,
    large: PropTypes.bool,
    superwide: PropTypes.bool,
    tall: PropTypes.bool,
    icon: PropTypes.bool,
    iconOnly: PropTypes.bool,
    text: PropTypes.bool,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    reverseHover: PropTypes.bool,
    noWrap: PropTypes.bool,
    title: PropTypes.string,
    active: PropTypes.bool,
    sleeping: PropTypes.bool,
};
