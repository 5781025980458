exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PuppyPodiumLeft__cls1{fill:#bf9430;stroke:#8a6b22;stroke-miterlimit:10}.PuppyPodiumLeft__cls2{fill:#ffc200}.PuppyPodiumLeft__cls2,.PuppyPodiumLeft__cls3,.PuppyPodiumLeft__cls4,.PuppyPodiumLeft__cls5,.PuppyPodiumLeft__cls6,.PuppyPodiumLeft__cls7,.PuppyPodiumLeft__cls8,.PuppyPodiumLeft__cls9,.PuppyPodiumLeft__cls10,.PuppyPodiumLeft__cls11{stroke-width:0px}.PuppyPodiumLeft__cls3{fill:#0b0808}.PuppyPodiumLeft__cls4{fill:#b76c00}.PuppyPodiumLeft__cls5{fill:#8a6b22}.PuppyPodiumLeft__cls6{fill:#1b75bb}.PuppyPodiumLeft__cls7{fill:#fff}.PuppyPodiumLeft__cls8{fill:#cbc6c5}.PuppyPodiumLeft__cls9{fill:#231f20}.PuppyPodiumLeft__cls10{fill:#ba1a55}.PuppyPodiumLeft__cls11{fill:#04456f}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/GoldenSummerGames/PuppyPodiumLeft.scss"],"names":[],"mappings":"AAAA,uBAAM,aAAa,eAAe,oBAAoB,CAAC,uBAAM,YAAY,CAAC,wOAA8D,gBAAgB,CAAC,uBAAM,YAAY,CAAC,uBAAM,YAAY,CAAC,uBAAM,YAAY,CAAC,uBAAM,YAAY,CAAC,uBAAM,SAAS,CAAC,uBAAM,YAAY,CAAC,uBAAM,YAAY,CAAC,wBAAO,YAAY,CAAC,wBAAO,YAAY,CAAC","file":"PuppyPodiumLeft.scss","sourcesContent":[".cls1{fill:#bf9430;stroke:#8a6b22;stroke-miterlimit:10}.cls2{fill:#ffc200}.cls2,.cls3,.cls4,.cls5,.cls6,.cls7,.cls8,.cls9,.cls10,.cls11{stroke-width:0px}.cls3{fill:#0b0808}.cls4{fill:#b76c00}.cls5{fill:#8a6b22}.cls6{fill:#1b75bb}.cls7{fill:#fff}.cls8{fill:#cbc6c5}.cls9{fill:#231f20}.cls10{fill:#ba1a55}.cls11{fill:#04456f}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cls1": "PuppyPodiumLeft__cls1",
	"cls2": "PuppyPodiumLeft__cls2",
	"cls3": "PuppyPodiumLeft__cls3",
	"cls4": "PuppyPodiumLeft__cls4",
	"cls5": "PuppyPodiumLeft__cls5",
	"cls6": "PuppyPodiumLeft__cls6",
	"cls7": "PuppyPodiumLeft__cls7",
	"cls8": "PuppyPodiumLeft__cls8",
	"cls9": "PuppyPodiumLeft__cls9",
	"cls10": "PuppyPodiumLeft__cls10",
	"cls11": "PuppyPodiumLeft__cls11"
};