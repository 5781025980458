import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import styles from "./ClinicGames.scss";
import BasicGridTiles from "components/common/BasicGridTiles";
import ProviderBase from "components/layout/ProviderBase";
import Button from "components/common/Button";
import GreenlineGameCard from "components/games/elements/GreenlineGameCard";
import BiProductDisclosures from "components/common/BiProductDisclosures";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import { getNodeId } from "utils/AdminData";
import * as ProviderApi from "api/ProviderApi";
import { handleErrorResponse } from "utils/request";
import getProviderId from "utils/getProviderId";
import { getLinkLocation } from "utils/ProviderLinks";
import { mapClinicGamesFromServerToApp } from "data/serverMapping";

function ClinicGamesDemo(props) {
    const history = useHistory()
    const location = useLocation()
    const params = useParams();
    const nodeId = Number(params.nodeId);
    const providerId = Number(params.providerId);
    const isAdmin = includes(location.pathname, "/admin");
    const isHistoric = includes(location.pathname, "/history");
    const [gamesList, setGamesList] = useState([]);
    const [demoGames, setDemoGames] = useState([]);

    useEffect(() => {
        if(props.canViewClinicBIGamesDashboard) {
            ProviderApi.getClinicGameDemos()
                .then(res => {
                    setDemoGames(mapClinicGamesFromServerToApp(res.body));
                })
                .catch(error => {
                    handleErrorResponse('getting clinic games', error);
                });
        }
    }, [props.clinicId]);

    useEffect(() => {
        setGamesList(filter(demoGames, game => game.isCurrent === !isHistoric));
    }, [demoGames, isHistoric]);

    const changePage = (link) => {
        history.push(getLinkLocation(link, {
            providerId,
            nodeId,
            isAdmin,
            isDemo: true,
        }));
    }

    const getAllCards = () => {
        return map(gamesList, game => (
            <GreenlineGameCard
                censored
                game={game}
                key={`game_${game.greenlineGameId}`}
                nodeId={props.nodeId}
            />
        ));
    }

    return (
        <ProviderBase
            location={location}
            pageTitle="Demo Clinic Games"
            providerId={props.providerId}
            nodeId={props.nodeId}
            match={props.match}
        >
            <div className={classnames(styles.root, {
                [styles.historic]: isHistoric,
            })}>
                {props.canViewClinicBIGamesDashboard ? (
                    <>
                        <section className="flex-1 overflow-y-scroll">
                            <div className={"flex border-bottom"}>
                                <Button
                                    onClick={() => changePage("demo-clinic-games")}
                                    disabled={!isHistoric}
                                >
                                    Current Games
                                </Button>
                                <Button
                                    onClick={() => changePage("demo-clinic-games-history")}
                                    disabled={isHistoric}
                                >
                                    Historic Games
                                </Button>
                            </div>
                            <h3>{isHistoric ? "Historic Games" : "Current Games"}</h3>
                            {!!gamesList.length ? (
                                <BasicGridTiles tiles={getAllCards()} large />
                            ):(
                                <div>No {isHistoric ? "Historic" : "Current"} Games Available</div>
                            )}
                        </section>
                        <div className="text-x-sm border-top margin-bottom-sm">
                            <BiProductDisclosures/>
                        </div>
                    </>
                ) : (
                    <AccessDenied/>
                )}
            </div>
        </ProviderBase>
    );
}

export default connect(
    (state, ownProps) => {
        const nodeId = getNodeId(state, ownProps);
        const userProfile = state.user.userProfile;
        const params = ownProps?.match?.params || {};
        const providerId = getProviderId(params, userProfile);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            canViewClinicBIGamesDashboard,
            nodeId,
            providerId,
        };
    }
)(ClinicGamesDemo);
