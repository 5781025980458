import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import styles from "./VetCheck.scss";
import classnames from "classnames";
import toast from "utils/toast";
import find from "lodash/find";
import Button from "components/common/Button";
import ClinicBase from "components/layout/ClinicBase";
import PetOwnerOptOutTable from "components/vetcheck/widgets/PetOwnerOptOutTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import VetCheckHeader from "components/vetcheck/widgets/VetCheckHeader";
import * as VetCheckApi from "api/VetCheckApi";
import * as VetCheckActions from "actions/VetCheckActions";
import {handleErrorResponse} from "utils/request";
import { getClinicInfo } from "utils/ClinicData";
import {CLINIC_TAGS} from "constants/AdminControls";
import * as SearchTypes from "constants/SearchTypes";
import AccessDenied from "../../common/AccessDenied";
import {PermissionTypes, userHasPermission} from "../../../utils/permissions/rolesPermissions";
import * as UserPermissions from "../../../constants/UserPermissions";

function VetCheckOptOut(props) {
    const [options, setOptions] = useState({limit: 10, orderBy: "petOwnerLastName", orderDir: "asc"});
    const [offset, setOffset] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [petOwners, setPetOwners] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const totalPages = (totalRecords && options.limit) ? (Math.ceil(totalRecords / options.limit)) : 1;

    if(!props.canViewClinicVetCheck) {
        return (
            <ClinicBase
                searchConfig={SearchTypes.VETCHECK_PET_OWNERS}
                clinicId={props.clinicId}
                docTitle={"VetCheck - Opt out"}
            >
                <div className="full-height">
                    <AccessDenied/>
                </div>
            </ClinicBase>
        );
    }

    const fetchData = () => {
        setLoading(true);
        const newOptions = {
            ...options,
            search: props.search,
            offset,
        }
        VetCheckApi.getPetOwners(props.clinicId, newOptions)
            .then((res) => {
                setPetOwners(res.body.data);
                setTotalRecords(res.body.totalRecordCount);
                setOffset(res.body.offset);
                setLoading(false);
            })
            .catch((error) => {
                handleErrorResponse("loading pet owners", error);
                setLoading(false);
            });
    }

    useEffect(() => {
        if(props.canViewClinicVetCheck) {
            fetchData();
        }
    }, [props.clinicId, options, currentPage, props.canViewClinicVetCheck]);

    useEffect(() => {
        //When the search value is changed, set the page back to 1 which will trigger fetchData()
        if(currentPage !== 1) {
            setCurrentPage(1);
        } else {
            fetchData();
        }
    }, [props.search]);

    const handleOptIn = async (optIn, petOwnerId) => {
        const owner = find(petOwners, {petOwnerId: petOwnerId});
        if (optIn) {
            //OPTING IN
            try {
                await VetCheckApi.vetCheckOptIn(props.clinicInfo.clinicId, petOwnerId);
                toast.success(`${owner.petOwnerFirstName} ${owner.petOwnerLastName} opted in successfully`);
            } catch (error) {
                handleErrorResponse("opting in", error);
            }
        } else {
            //OPTING OUT
            try {
                await VetCheckApi.vetCheckOptOut(props.clinicInfo.clinicId, petOwnerId);
                toast.success(`${owner.petOwnerFirstName} ${owner.petOwnerLastName} opted out successfully`);
            } catch (error) {
                handleErrorResponse("opting out", error);
            }
        }
    }

    const handleSort = (sortField, direction) => {
        setOptions({
            ...options,
            orderBy: sortField,
            orderDir: direction
        })
    }

    const handleChangePage = (change) => {
        const newPage = currentPage + change
        setCurrentPage(newPage);
        setOffset(options.limit * (newPage - 1));
    }

    const renderOptOutList = () => {
        if(!petOwners.length) {
            return(
                <div className={styles.noResults}>
                    <div className={styles.innerMessage}>
                        <h3>No Pet Owners to Show</h3>
                        <p>Change your Search to find more</p>
                    </div>
                </div>
            )
        }

        return (
            <>
                <PetOwnerOptOutTable
                    petOwners={petOwners}
                    resultsPerPage={options.limit}
                    onOptIn={handleOptIn}
                    onSort={handleSort}
                />
                {totalPages > 1 && (
                    <div className="flex full-width flex-centered  margin-top-sm">
                        <div className="flex-none">
                            <Button disabled={!(currentPage > 1)} onClick={() => handleChangePage(-1)}>
                                <i className="fa fa-chevron-left margin-right-sm" /> Previous
                            </Button>
                        </div>

                        <div className="flex-1 text-center">
                            Page {currentPage} of {totalPages}
                        </div>
                        <div className="flex-none">
                            <Button disabled={(currentPage >= totalPages)} onClick={() => handleChangePage(1)}>
                                Next <i className="fa fa-chevron-right margin-left-sm" />
                            </Button>
                        </div>
                    </div>
                )}
            </>
        );
    }

    return (
        <ClinicBase
            searchConfig={SearchTypes.VETCHECK_PET_OWNERS}
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle={"VetCheck - Opt out"}
        >
            <div className={styles.root}>
                <VetCheckHeader
                    clinicId={props.clinicId}
                    location={props.location}
                />
                <div className={classnames(styles.topLine, "spaced-content")}>
                    <h2 className="flex-1">Pet Owner Opt-Outs</h2>
                </div>
                {renderOptOutList()}
            </div>
            <SpinnerTakeover show={loading} />
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        // Permissions
        const canViewClinicVetCheck = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_VETCHECK, userProfile);
        return {
            clinicId,
            clinicInfo: clinic,
            search: state.entities.petOwners.query || null,
            //Permissions
            canViewClinicVetCheck
        }
    },
    (dispatch) => ({
        getPetOwners: (clinicId, options) => dispatch(VetCheckActions.getPetOwners(clinicId, options)),
    }),
)(VetCheckOptOut);
