import React, {useEffect} from "react";
import Loader from "components/common/Loader";
import LocalData from "utils/LocalData";
import logger from "utils/logger";
import { getManager } from "utils/login";


export default function OidcPopupCallback(props) {
    useEffect(() => {
        const mgr = getManager();
        mgr.signinPopupCallback().then((user) => {
            if (user) {
                LocalData.setToken(user.access_token);
            } else {
                LocalData.clearToken();
            }
        }).catch((err) => {
            logger.error("Error OIDC popping up:", err);
        });
    }, []);
    return (
        <div>
            <Loader
                text="Signing In"
            />
        </div>
    );
}
