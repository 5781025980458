import React from "react";
import PropTypes from "prop-types";
import styles from "./PatientsList.scss";
import produce from "immer";
import capitalize from "lodash/capitalize";
import clone from "lodash/clone";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import map from "lodash/map";
import reject from "lodash/reject";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import PatientFields from "components/greenlineWellness/widgets/WellnessEnrollmentModal/PatientFields";
import * as ButtonTypes from "constants/ButtonTypes";

export default function PatientsList(props) {

    const handleRemovePatient = (index) => {
        const newPatients = clone(props.initialData);
        newPatients.splice(index, 1);

        const patients = filter(newPatients, p => {
            if(!p.patientId) {
                return true
            } else {
                return !!includes(flatMap(props.patients, "patientId"), p.patientId);
            }
        });

        props.onChange([{
            name: "owner.patients",
            value: newPatients,
        }, {
            name: "patients",
            value: patients,
        }]);
    };

    const handleChange = ({ name, value }, index) => {
        const newPatients = produce(props.initialData, (newState) => {
            newState[index] = {
                ...newState[index],
                [name]: value,
            };
            return newState;
        });

        const patients = filter(newPatients, p => {
            if(!p.patientId) {
                return true
            } else {
                return !!includes(flatMap(props.patients, "patientId"), p.patientId);
            }
        });

        props.onChange([{
            name: "owner.patients",
            value: newPatients,
        }, {
            name: "patients",
            value: patients,
        }]);
    };

    const handleSelectPatient = (patient, addPatient) => {
        if (!patient) return;
        let newPatients;
        if(addPatient) {
            newPatients = clone(props.patients);
            newPatients.push(patient);
        } else {
            newPatients = reject(props.patients, {patientId: patient.patientId});
        }

        props.onChange({ name: "patients", value: newPatients })
    };

    return map(props.initialData, (patient, index) => {
        if (patient.patientId) {
            return (
                <div key={patient.patientId} className={"text-lg"}>
                    <CheckboxInput
                        label={`${patient.patientName} - ${capitalize(patient.species)} ${patient.weightInPounds}lbs`}
                        name="patient"
                        checked={!!includes(flatMap(props.patients, "patientId"), patient.patientId)}
                        onChange={({value}) => handleSelectPatient(patient, value)}
                    />
                </div>
            );
        } else {
            return (
                <div
                    key={index}
                    className={styles.patient}
                >
                    <div className="flex spaced-content">
                        <h3 className="flex-1 margin-bottom-sm">Patient #{index + 1}</h3>
                        {(props.initialData?.length > 1) && (
                            <div className="flex-none">
                                <Button
                                    type={ButtonTypes.GRAY}
                                    onClick={() => handleRemovePatient(index)}
                                    title={"Remove Patient"}
                                    icon
                                >
                                    <i className="far fa-minus"/> Remove
                                </Button>
                            </div>
                        )}
                    </div>
                    <PatientFields
                        key={index}
                        initialData={patient}
                        index={index}
                        onChange={(data) => handleChange(data, index)}
                        onRemove={handleRemovePatient}
                        readOnly={props.readOnly}
                        errors={props.errors}
                    />
                </div>
            );
        }
    });
}

PatientsList.defaultProps = {
    initialData: [],
    readOnly: false,
    patients: [],
    errors: [],
}

PatientsList.propTypes = {
    onChange: PropTypes.func.isRequired,
    patients: PropTypes.arrayOf(PropTypes.object),
    initialData: PropTypes.arrayOf(PropTypes.object),
    readOnly: PropTypes.bool,
    errors: PropTypes.arrayOf(PropTypes.string),
}
