import React from "react";
import PropTypes from "prop-types";
import styles from "./ProgramDashboardTile.scss";
import Button from "components/common/Button";
import Image from "components/common/Image";

export default function ProgramServiceDashboardTile(props) {
    return (
        <div
            className={styles.root}
            onClick={() => props.onButtonClick(props.programService.programServiceId)}
        >
            <div className="flex spaced-content align-center">
                <div className={styles.icon}>
                    <Image
                        src={props.programService.programServiceImage}
                        alt={props.programService.programServiceName}
                        title={props.programService.programServiceName}
                    />
                </div>
                <h4 className="flex-1">{props.programService.programServiceName}</h4>
                <div className={styles.button}>
                    <Button small onClick={() => {}}>
                        View
                    </Button>
                </div>
            </div>
        </div>
    );
}

ProgramServiceDashboardTile.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    onButtonClick: PropTypes.func
};
