export const CATEGORIES = [
    {name: "Dental Hygiene", value: 60},
    {name: "Non-steroidal Anti-inflammatory drugs", value: 20},
    {name: "Nutraceutical", value: 70},
    {name: "Parasiticides", value: 30},
    {name: "Services", value: 50},
    {name: "Therapeutics", value: 40},
];


export const CANINE = "Canine";
export const FELINE = "Feline";
export const MAMMAL = "Mammal";
export const REPTILE = "Reptile";
export const AVIAN = "Avian";

export const SPECIES = [
    {name: CANINE, value: CANINE},
    {name: FELINE, value: FELINE},
];

export const ALL_SPECIES = [
    {name: CANINE, value: CANINE},
    {name: FELINE, value: FELINE},
    {name: MAMMAL, value: MAMMAL},
    {name: REPTILE, value: REPTILE},
    {name: AVIAN, value: AVIAN},
];

export const BRANDS = [
    {name: "Activyl", value: "Activyl"},
    {name: "Activyl Tick Plus", value: "Activyl Tick Plus"},
    {name: "Advantage II", value: "Advantage II"},
    {name: "Advantage Multi", value: "Advantage Multi"},
    {name: "Antinol", value: "Antinol"},
    {name: "Assurity", value: "Assurity"},
    {name: "Boehringer Ingelheim Trigger Items", value: "Boehringer Ingelheim Trigger Items"},
    {name: "Bravecto", value: "Bravecto"},
    {name: "Bravecto Monthly", value: "Bravecto Monthly"},
    {name: "Bravecto Plus", value: "Bravecto Plus"},
    {name: "Capstar", value: "Capstar"},
    {name: "Carprofen", value: "Carprofen"},
    {name: "Centragard", value: "Centragard"},
    {name: "Certifect", value: "Certifect"},
    {name: "Cheristin", value: "Cheristin"},
    {name: "Claro", value: "Claro"},
    {name: "Comfortis", value: "Comfortis"},
    {name: "Coraxis", value: "Coraxis"},
    {name: "Credelio", value: "Credelio"},
    {name: "Deramaxx", value: "Deramaxx"},
    {name: "Easyspot", value: "Easyspot"},
    {name: "Effitix", value: "Effitix"},
    {name: "Effitix Plus", value: "Effitix Plus"},
    {name: "Elanco Trigger Items", value: "Elanco Trigger Items"},
    {name: "Frontline Gold", value: "Frontline Gold"},
    {name: "Frontline Plus", value: "Frontline Plus"},
    {name: "Frontline Tritak", value: "Frontline Tritak"},
    {name: "Galliprant", value: "Galliprant"},
    {name: "Greenline Trigger Items", value: "Greenline Trigger Items"},
    {name: "Heartgard", value: "Heartgard"},
    {name: "Heartgard Plus", value: "Heartgard Plus"},
    {name: "Interceptor", value: "Interceptor"},
    {name: "Interceptor Plus", value: "Interceptor Plus"},
    {name: "IverhartMax", value: "IverhartMax"},
    {name: "IverhartPlus", value: "IverhartPlus"},
    {name: "K9 Advantix II", value: "K9 Advantix II"},
    {name: "Meloxicam", value: "Meloxicam"},
    {name: "Merck Trigger Items", value: "Merck Trigger Items"},
    {name: "Metoxicam", value: "Metoxicam"},
    {name: "MilbeGuard", value: "MilbeGuard"},
    {name: "Nexgard", value: "Nexgard"},
    {name: "Onsior", value: "Onsior"},
    {name: "OraVet", value: "OraVet"},
    {name: "ParaDefense", value: "ParaDefense"},
    {name: "Parastar", value: "Parastar"},
    {name: "ParastarPlus", value: "ParastarPlus"},
    {name: "Preventic", value: "Preventic"},
    {name: "Previcox", value: "Previcox"},
    {name: "Proheart 12", value: "Proheart 12"},
    {name: "Proheart 6", value: "Proheart 6"},
    {name: "Prozinc", value: "Prozinc"},
    {name: "Revolution", value: "Revolution"},
    {name: "Revolution Plus", value: "Revolution Plus"},
    {name: "Rimadyl", value: "Rimadyl"},
    {name: "Royal Canin Urinary", value: "Royal Canin Urinary"},
    {name: "Scalibor", value: "Scalibor"},
    {name: "Sentinel", value: "Sentinel"},
    {name: "Sentinel Spectrum", value: "Sentinel Spectrum"},
    {name: "Seresto Collar", value: "Seresto Collar"},
    {name: "Simparica", value: "Simparica"},
    {name: "Simparica Trio", value: "Trio"},
    {name: "Trifexis", value: "Trifexis"},
    {name: "TriheartPlus", value: "TriheartPlus"},
    {name: "Vectra", value: "Vectra"},
    {name: "Vetmedin", value: "Vetmedin"},
];

export const TYPES = [
    {name: "Products", value: 50},
    {name: "Discounts", value: 60},
    {name: "Free Doses", value: 70},
    {name: "Offer Triggers", value: 80},
];

export const VACCINE_TYPES = [
    {name: "Canine Core", value: "Canine Core"},
    {name: "Canine Flu", value: "Canine Flu"},
    {name: "Canine Lepto", value: "Canine Lepto"},
    {name: "Canine Lyme", value: "Canine Lyme"},
    {name: "Feline Core", value: "Feline Core"},
    {name: "Feline FELV", value: "Feline FELV"},
    {name: "Feline Other", value: "Feline Other"},
    {name: "Kennel Cough", value: "Kennel Cough"},
    {name: "Rabies", value: "Rabies"},
];


export const FECAL = "Fecal";
export const URINALYSIS = "Urinalysis";

export const SERVICE_TAGS = [
    {name: FECAL, value: FECAL},
    {name: URINALYSIS, value: URINALYSIS},
];


export const CHEMICAL = "Chem";
export const EXAMINATION = "Exam";
export const LABORATORY = "LAB";

export const SERVICE_CATEGORIES = [
    {name: "Chemical", value: CHEMICAL},
    {name: "Examination", value: EXAMINATION},
    {name: "Laboratory", value: LABORATORY},
];


export const SERVICES = "Services";
export const PRODUCTS = "Products";
export const ADD_ON_PLANS = "Add-On Plans";
export const SPECIAL = "Special";
export const CUSTOM_TRANSACTIONS = "Custom Transactions";

export const WELLNESS_CATEGORIES = [
    {name: SERVICES, value: SERVICES},
    {name: PRODUCTS, value: PRODUCTS},
    {name: ADD_ON_PLANS, value: ADD_ON_PLANS},
    {name: "Special Service Items", value: SPECIAL},
    {name: CUSTOM_TRANSACTIONS, value: CUSTOM_TRANSACTIONS},
];