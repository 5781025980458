import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {map} from "lodash";
import Button from "components/common/Button";
import * as ClinicActions from "actions/ClinicActions";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function EditCustomViewForm(props) {

    const handleDeleteView = (e, viewId) => {
        e.stopPropagation();
        e.preventDefault();

        if (props.canDeleteClinicManagement) {
            if (confirm(`Are you sure you want to Delete "${props.clinicViews[viewId].title}" view?`)) {
                props.deleteCustomClinicView(viewId);
            }
        }
    }

    return (
        <div>
            <table className="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th className="text-center">Favorite</th>
                        <th className="text-center">Pinned</th>
                        <th/>
                    </tr>
                </thead>
                <tbody>
                <tr className="clickable" onClick={() => props.onSelectView(null)}>
                    <td>Default</td>
                    <td/>
                    <td/>
                    <td/>
                </tr>
                {Object.keys(props.clinicViews).length ? (
                    map(props.clinicViews, view => (
                        <tr
                            className="clickable"
                            key={view.userPreferenceId}
                            onClick={() => props.onSelectView(view.userPreferenceId)}
                        >
                            <td>{view.title}</td>
                            <td className="text-center">
                                {view.isFavorite && <i className="fa fa-star text-yellow margin-right-x-sm"/>}
                            </td>
                            <td className="text-center">
                                {view.isPinned && <i className="fa fa-thumb-tack text-primary"/>}
                            </td>
                            <td className="flex spaced-content justify-flex-end">
                                {props.canDeleteClinicManagement && (
                                    <Button
                                        onClick={(e) => handleDeleteView(e, view.userPreferenceId)}
                                        text
                                        iconOnly
                                        type="danger"
                                    >
                                        <i className="fas fa-trash-alt"/>
                                    </Button>
                                )}
                            </td>
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td className="text-center text-bold" colSpan={2}>No Custom Views</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
}

EditCustomViewForm.propTypes = {
    onSelectView: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canDeleteClinicManagement = userHasPermission(PermissionTypes.DELETE, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canDeleteClinicManagement,
            clinicViews: state.entities.clinicViews,
        }
    },
    (dispatch) => ({
        deleteCustomClinicView: (viewId) => dispatch(ClinicActions.deleteCustomClinicView(viewId)),
    })
)(EditCustomViewForm);

