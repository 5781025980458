import React, { useState } from "react";
import {connect} from "react-redux";
import classnames from "classnames";
import styles from "./ResourcesPage.scss";
import flatMap from "lodash/flatMap"
import includes from "lodash/includes"
import map from "lodash/map"
import * as TourActions from "actions/TourActions";
import AccessDenied from "components/common/AccessDenied";
import AdminBase from "components/layout/AdminBase";
import Button from "components/common/Button";
import ClinicBase from "components/layout/ClinicBase";
import GreenlineSupportPhoneNumber from "components/support/elements/GreenlineSupportPhoneNumber";
import JoyRide from "components/tour/widgets/JoyRide";
import Modal from "components/common/Modal";
import ProviderBase from "components/layout/ProviderBase";
import { TOUR_COMPLETE_TRANSITION } from "utils/demoTourHelpers";
import {getNodeId} from "utils/AdminData";
import {getClinicInfo} from "utils/ClinicData";
import getProviderId from "utils/getProviderId";
import {userPrimaryLocationType} from "utils/permissions/rolesPermissions";
import {CLINIC_TAGS} from "constants/AdminControls";
import * as DemoTourStates from "constants/DemoTourStates";
import * as FileTypes from "constants/FileTypes";
import * as LocationTypes from "constants/LocationTypes";
import * as TileTypes from "constants/TileTypes";

// import Vimeo from "@u-wave/react-vimeo"; // https://github.com/u-wave/react-vimeo

const ContactUs = require("components/common/branding/clinics/how_to_contact_us.png");
const ProcessCoupons = require("components/common/branding/clinics/how_to_process_coupons.png");
const CompatiblePIMs = require("components/common/branding/clinics/compatible_pims.jpg");

function ResourcesPage(props) {
    const [videoUrl, setVideoUrl] = useState(null);

    const handleTourSectionComplete = (tourSection) => {
        props.markTourAsComplete();
        props.history.push("/tour-complete");
    };

    const isProviderView = (
        (userPrimaryLocationType(props.userProfile, [LocationTypes.PROVIDER]) && !props.clinicId) ||
        (userPrimaryLocationType(props.userProfile, [LocationTypes.NULL]) && props.providerId && props.nodeId)
    );
    const providerIds = flatMap(props.userProfile?.providers, p => Number(p.id));

    const TILES = [
        {
            type: TileTypes.DOWNLOAD,
            file_type: FileTypes.PDF,
            title: "How to Contact Us",
            description: "Please click here for more information on how to contact a Greenline specialist.",
            additionalDescription: (
                <div>
                    <div>As a Premier Clinic, the number below will direct you to a designated Greenline Customer Experience Specialist for any assistance you may need.</div>
                    <div className="margin-top-sm">
                        <GreenlineSupportPhoneNumber clinicId={props.clinicId}/>
                    </div>
                </div>
            ),
            imageSrc: ContactUs,
            url: "https://glcdn.azureedge.net/content/resources/NewUIHowToContactUs.pdf",
        }, {
            type: TileTypes.DOWNLOAD,
            file_type: FileTypes.PDF,
            title: "How to Process Coupons",
            description: "For more information on how to process your coupons for reimbursement for either your clinic or clients please click here.",
            imageSrc: ProcessCoupons,
            url: "https://glcdn.azureedge.net/content/resources/NewUIHowToProcessCoupons.pdf",
        }, {
            type: TileTypes.DOWNLOAD,
            file_type: FileTypes.PDF,
            title: "Compatible PIMS and Requirements",
            description: "For a complete list of the software we are currently compatible with please click here.",
            imageSrc: CompatiblePIMs,
            url: "https://glcdn.azureedge.net/content/resources/CompatiblePIMSwithRequirements.pdf",
        },
    ];

    const FLYERS = [
        {
            title: "Greenline Instant Rebates",
            url: "https://glcdn.azureedge.net/content/resources/Greenline_Instant_Rebates.pdf"
        }, {
            title: "Greenline/BI Games",
            url: "https://glcdn.azureedge.net/content/resources/BI_Greenline_Games.pdf"
        }, {
            title: "Greenline General",
            url: "https://glcdn.azureedge.net/content/resources/BI_General_Greenline.pdf"
        }
    ];

    const handleButtonClicked = (tile) => {
        if ((tile.type === TileTypes.VIDEO) && tile.url) {
            setVideoUrl(tile.url);
        }
    };

    const getButton = (tile) => {
        switch (tile.type) {
            case TileTypes.DOWNLOAD:
                if (tile.file_type === FileTypes.PDF) {
                    //Open PDFs in a new tab ready for printing or downloading
                    return(
                        <a className="btn btn-color-default btn-full btn-sm"
                           target="_blank"
                           href={tile.url}
                        >
                            DOWNLOAD {tile.file_type}
                        </a>
                    )
                } else {
                    return (
                        //Download DOCXs
                        <a className="btn btn-color-default btn-full btn-sm"
                           href={tile.url}
                           download
                        >
                            DOWNLOAD {tile.file_type}
                        </a>
                    )
                }
            case TileTypes.VIDEO:
                return (
                    <Button onClick={() => handleButtonClicked(tile)} small>
                        Watch Now
                    </Button>
                )
            default:
                return null;
        }
    };

    const renderTile = (tile, index) => {
        return(
            <div key={index} className="flex flex-wrap spaced-content border-bottom padding-md">
                <div className={styles.image} onClick={() => handleButtonClicked(tile)}>
                    <img src={tile.imageSrc} alt="Greenline Pet Logo" />
                    {tile.type === TileTypes.VIDEO && (
                        <>
                            <div className={styles.cover}>
                                <span className="fa fa-play-circle"/>
                            </div>
                        </>
                    )}
                </div>
                <div className={styles.text}>
                    <h3>{tile.title}</h3>
                    <div>{tile.description}</div>
                    <div className="margin-top-md">
                        {getButton(tile)}
                    </div>
                    {props.clinicHasPrioritySupport && (
                        <div className="margin-top-md">{tile.additionalDescription}</div>
                    )}
                </div>
            </div>
        )
    }

    const content = (
        <>
            <div className={styles.root}>
                {props.canViewResources ? (
                    <div className={styles.resourceTiles}>
                        {map(TILES, (tile, index) => {
                            return renderTile(tile, index);
                        })}
                        <JoyRide
                            tourSection={!includes(props.completedTourSections, TOUR_COMPLETE_TRANSITION) ? TOUR_COMPLETE_TRANSITION : null}
                            onSectionComplete={handleTourSectionComplete}
                        />
                    </div>
                ) : <AccessDenied/>}
                {/* Provider 1 === BI */}
                {/* Only show these flyers in Provider view to the BI reps */}
                {(isProviderView && !!includes(providerIds, 1)) && (
                    <div className={styles.specialSection}>
                        <h2 className="text-center margin-bottom-sm">Greenline Pet Detailers</h2>
                        <div className="flex align-top">
                            {map(FLYERS, (flyer, index) => (
                                <div key={index}
                                     title={flyer.title}
                                     className={classnames(styles.pdfHover, "flex flex-1 flex-column flex-centered margin-x-sm")}
                                >
                                    <div className={styles.pdfContainer}>
                                        <iframe
                                            src={`${flyer.url}#toolbar=0`}
                                            className={classnames(styles.pdf, "pdf")}
                                        />
                                        <div className={styles.pdfOverlay}>
                                            <a href={flyer.url} target="_blank" title={`Open ${flyer.title} in a new tab`}>
                                                <div className={styles.pdfOverlayInner}>
                                                    <i className="fa fa-expand"/>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                    <a href={flyer.url}
                                       target="_blank"
                                       title={`Open ${flyer.title} in a new tab`}
                                       className="text-primary full-width text-center"
                                    >
                                        <div className="padding-xs">{flyer.title}</div>
                                    </a>
                                </div>
                            ))}
                        </div>
                        <div className="text-center margin-top-md margin-bottom-sm">Please select a Greenline Detailer above to view more information!</div>
                    </div>
                )}
            </div>
            <Modal
                show={!!videoUrl}
                clickOutsideToClose
                onClose={() => setVideoUrl(null)}
            >
                <div className="flex justify-center align-center full-width full-height">
                    (Video player here)
                    {/* <Vimeo video={videoUrl} /> */}
                </div>
            </Modal>
        </>
    );

    if (userPrimaryLocationType(props.userProfile, [LocationTypes.NULL]) && !props.clinicId && !props.providerId) {
        return (
            <AdminBase pageTitle="Resources">
                {content}
            </AdminBase>
        );

    } else if (isProviderView) {
        return (
            <ProviderBase
                location={props.location}
                providerId={props.providerId}
                nodeId={props.nodeId}
                clinicId={props.clinicId}
                pageTitle="Resources"
            >
                {content}
            </ProviderBase>
        );
    } else {
        return (
            <ClinicBase
                clinicId={props.clinicId}
                pageTitle={props.clinicHasPrioritySupport ? "Premier Information Page" : "Resources"}
                adminControls={CLINIC_TAGS}
            >
                {content}
            </ClinicBase>
        );
    }
}

ResourcesPage.defaultProps = {
    canViewResources: true, // Until we decide to actually use permissions for this page
}

export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        const providerId = getProviderId(params, userProfile)
        const nodeId = getNodeId(state, ownProps)
        //Permissions
        const clinicHasPrioritySupport = !!clinic?.prioritySupport?.length;
        return {
            clinicId,
            nodeId,
            providerId,
            userProfile,
            completedTourSections: state.demo.completedTourSections,
            tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),
            // Permissions
            clinicHasPrioritySupport
        }
    },
    (dispatch) => ({
        markTourAsComplete: () => dispatch(TourActions.markTourAsComplete()),
    })
)(ResourcesPage);
