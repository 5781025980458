import React from "react";
import {
	MAIN_COLOR,
	SECONDARY_COLOR,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./KittyTrack.scss";


export default function KittyTrack(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg id="Run_-_Kitty" data-name="Run - Kitty" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1600 300">
			<g id="Kitty_Run04">
				<g id="Kitty_Run04_Lower">
					<path className={styles.cls6}
					      d="M1326.81,285.31c0,5.35,32.76,9.69,73.19,9.69,40.42,0,73.19-4.34,73.19-9.69,0-5.35-32.76-9.69-73.19-9.69-40.42,0-73.19,4.34-73.19,9.69Z"/>
					<path className={styles.cls11}
					      d="M1424.67,203.75c12.44,13.82,13.73,41.04-2.22,48.62s-23.29,4.19-31.96,5.36c8.47,13.83-10.92,21.65-14.2,15.8-5.84-10.42-8.62-30.6,2.77-27.87,10.55,2.53,22.84-5.25,16.6-14.53-12.11-17.99,20.45-36.89,29.02-27.37Z"/>
					<g>
						<path className={styles.cls8}
						      d="M1327.09,207.06c5.15,5.63,18.21,6.97,25.56,7.82,4.24.49,10.12,1.35,14.33-.38,5.65-2.32,5.1-7.08-1.17-8.6-3.98-.96-7.56.25-11.71-1.01-4.63-1.41-9.09-2.7-13.56-4.56-3.71-1.54-8.09-3.89-11.9-1.62-3.03,1.8-4.1,5.57-1.54,8.36Z"/>
						<path className={styles.cls7}
						      d="M1327.09,207.06c-2.55-2.79-1.48-6.55,1.54-8.36,3.81-2.27,8.19.09,11.9,1.62.05.02.1.04.15.06-1.94,2.2-3.49,4.79-3.97,7.55-.22,1.28-.15,2.72-.1,4.19-3.89-1.13-7.4-2.73-9.54-5.07Z"/>
					</g>
					<path className={styles.cls3}
					      d="M1391.98,233.64c-37.64,7.34-31.41-33.83-23.48-49.21,5.29-10.25-4.14-34.56,2.08-48.67s36.57-10.4,40.02-8.12c17.34,11.46,24.68,40.31,23.01,64.26-1.37,19.66-13.73,36.3-41.64,41.74Z"/>
					<path className={styles.cls8}
					      d="M1362.76,201.31c1.74-10.62,4.4-12.35,5.49-16.66,1.95-7.65,2.82-14.62.24-26.32,8.69,2.85,21.15,38.92,19.37,42.69-3.52,7.45-17.18,5.19-25.1.29Z"/>
				</g>
				<path
					id="Shirt"
					className={styles.cls1}
					d="M1429.58,154.38c-1.05-2.76-3.24-7.41-5.74-11.9,1.61,3.58,2.04,5.94-.58,6.94-3.64,1.38-7.8-3.75-10.77-8.01-8.13-11.64-32.51-21.22-40.54-13.19-8.26,8.26-1.95,37.92-1.95,46.77,0,6.89-2.76,12.06-4.56,15.88,17.67,10.77,44.85,9.91,68.56-.9,1.52-11.06-1.87-28.89-4.42-35.6ZM1391.88,163.43c-8.33,0-15.09-6.68-15.09-14.91s6.76-14.91,15.09-14.91,15.09,6.68,15.09,14.91-6.76,14.91-15.09,14.91Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes">
					<path
						className={styles.cls12}
						d="M1419.95,185.34c-6.84,1.96-13.76,3.13-20.56,3.47-10.95.54-21.74-1.15-30.94-4.89.52-1.45.96-3.4,1.14-4.37,8.75,3.65,19.05,5.42,29.58,4.89,11.57-.58,23.55-3.71,34.77-9.09.1.7.36,3.66.36,4.67-4.69,2.15-9.5,3.94-14.35,5.32Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls12}
						d="M1391.79,132.51c-8.92,0-16.19,7.18-16.19,16.01s7.26,16.01,16.19,16.01,16.19-7.18,16.19-16.01-7.26-16.01-16.19-16.01ZM1391.79,162.33c-7.72,0-14-6.2-14-13.82s6.28-13.82,14-13.82,14,6.2,14,13.82-6.28,13.82-14,13.82Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls12}
						d="M1423.99,151c-5.2,0-10.65-5.42-12.5-7.86-.41-.54-.86-1.16-1.36-1.86-3.33-4.63-8.9-12.37-16.75-13.2l.23-2.18c8.83.93,14.75,9.17,18.3,14.1.49.68.93,1.29,1.32,1.81,1.92,2.53,6.58,6.82,10.56,6.97,1.9.07,1.31-3.3.13-6.15,4.31,4.76,2.48,8.39.06,8.37Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Run04_Mid">
					<path className={styles.cls3}
					      d="M1384.78,149.66c-1.13,15.24,7.78,35.21,16.39,37.83,8.82,2.69,34.5-4.41,31.6-15.71-3.51-13.69-17.41-3.26-20.96-5.3-3.62-2.08-6.14-11.88-6.75-16.63-1.22-9.48-19.34-12.83-20.28-.19Z"/>
					<path className={styles.cls3}
					      d="M1420.19,220.63c-1.27,28.72-21.46,44.29-43.56,49.53,7.89,1.15,11.06,8.3,7.76,12.39-1.38,1.71-20.58,1.62-22.37.11-5.92-4.99-8.53-18.92-6.03-20.26,16.96-9.05,17.95-30.26,18.85-37.98,1.63-14.03,46.02-18.83,45.35-3.8Z"/>
					<g>
						<g>
							<path className={styles.cls8}
							      d="M1472.34,92.31c-11.13-.79-23.37.53-31.91,7.05-.42.32.17.97.58.65,8.36-6.38,20.39-7.67,31.29-6.89.55.04.59-.77.03-.81Z"/>
							<path className={styles.cls8}
							      d="M1473.95,107.28c-11.1-2.42-22.17-3.2-33.06-1.4-.54.09-.35.9.2.82,10.81-1.78,21.79-1,32.82,1.4.55.12.58-.7.04-.81Z"/>
							<path className={styles.cls8}
							      d="M1470.69,117.28c-9.24-3.88-19-5.67-28.74-6.91-.56-.07-.59.74-.04.82,9.67,1.23,19.34,3,28.51,6.85.53.22.79-.54.27-.76Z"/>
						</g>
						<path className={styles.cls2}
						      d="M1394.28,42.08c-.86,7.72-1.58,14.93-1.6,16.18-.03,2.63-.26,2.62,1.16,4.91,1.86,3.01,35.05,7.65,40.57,1.72-3.99-9.37-13.49-23.42-19.76-29.68-4.49-4.49-11.51-11.8-17.47-14.01-.4-.15-1.78,10.81-2.91,20.87Z"/>
						<g>
							<path className={styles.cls3}
							      d="M1365.96,82.74c43.23-15.77,26.14-29.14,16.58-34.24,3.64-.6,7.33-.95,10.95-1.05,5.11,4.97,21.31,18.63,45.9,19.78,3.3,6.25,4.83,13.61,6.49,20.52,1.91,7.9,7.62,10.38,2.91,24.72-5.44,16.56-18.34,17.74-27.16,19.74-10.47,2.38-22.86,1.88-33.55.25-7.12-1.09-19.5-5.63-25.31-10.2-11.99-9.43-15.72-23.16-17.01-34.74-.06-.56-.11-1.12-.14-1.68,10.18-.33,20.33-3.1,20.33-3.1Z"/>
							<path className={styles.cls7}
							      d="M1360.24,53.99c4.44-4.23,10.85-9.02,22.3-5.49,9.56,5.1,26.65,18.48-16.58,34.24,0,0-10.15,2.77-20.33,3.1-.93-13.99,5.9-23.56,14.62-31.85Z"/>
							<path className={styles.cls8}
							      d="M1393.49,47.45c9.22-.25,18.01,1.08,24.38,3.78,7.17,3.04,16.85,7.88,20.89,14.84.22.38.43.77.64,1.16-24.6-1.15-40.8-14.82-45.9-19.78Z"/>
						</g>
						<path className={styles.cls4}
						      d="M1409.52,84.8c1.4,6.89-4,13.21-12.06,14.1-8.06.89-15.74-3.98-17.14-10.87-1.4-6.89,4-13.2,12.06-14.1,8.06-.89,15.74,3.98,17.14,10.87Z"/>
						<path className={styles.cls4}
						      d="M1439.85,80.97c1.53,5.42-.29,10.14-4.08,10.55-3.79.41-8.1-3.66-9.63-9.07-1.53-5.42.29-10.14,4.08-10.55,3.79-.41,8.1,3.66,9.63,9.07Z"/>
						<g>
							<path className={styles.cls8}
							      d="M1376.59,107c10.58-2.93,22.73-4.17,32.55.02.48.2.07.91-.41.71-9.62-4.1-21.56-2.86-31.91,0-.53.15-.76-.58-.23-.73Z"/>
							<path className={styles.cls8}
							      d="M1378.64,120.84c10.16-4.39,20.66-7.3,31.63-7.85.54-.03.55.75,0,.78-10.89.54-21.32,3.44-31.4,7.8-.5.22-.73-.51-.23-.73Z"/>
							<path className={styles.cls8}
							      d="M1384.19,129.22c8.01-5.34,17.01-8.9,26.13-11.96.52-.17.75.55.23.73-9.05,3.03-17.98,6.56-25.92,11.86-.46.3-.89-.33-.44-.63Z"/>
						</g>
						<g>
							<path className={styles.cls10}
							      d="M1406.57,84.83c1.3,6.18-.86,11.91-4.83,12.8-3.97.88-8.25-3.41-9.55-9.59-1.31-6.18.86-11.91,4.83-12.8,3.97-.88,8.25,3.41,9.55,9.59Z"/>
							<path className={styles.cls9}
							      d="M1397.97,83.97c.65,3.1,2.77,5.26,4.72,4.82,1.96-.44,3.01-3.3,2.35-6.4-.65-3.1-2.77-5.26-4.72-4.82-1.95.44-3.01,3.3-2.35,6.4Z"/>
						</g>
						<g>
							<path className={styles.cls10}
							      d="M1438.53,81.11c1.04,5.18-.08,9.83-2.49,10.4-2.41.57-5.2-3.17-6.24-8.35-1.04-5.18.08-9.83,2.48-10.4,2.41-.57,5.2,3.17,6.24,8.35Z"/>
							<path className={styles.cls9}
							      d="M1433.2,80.03c.52,2.59,1.9,4.47,3.09,4.19,1.19-.28,1.73-2.61,1.21-5.2-.52-2.59-1.9-4.47-3.09-4.19-1.19.28-1.73,2.61-1.2,5.2Z"/>
						</g>
						<path className={styles.cls8}
						      d="M1427.19,112.45c.22.49.39,1.02.46,1.18.45.99,1.06,1.96,1.98,2.57,1.8,1.19,4.64,1.33,6.59.33,2.33-1.2,3.14-3.18,3.2-5.69.03-1.24.24-2.73-.07-3.93-.07-.27-.28-.6-.3-.87-.02-.35.61-1,.99-.92.76.16.75,1.24.75,1.83,0,.93.1,1.75.21,2.68.21,1.69.4,3.84,1.8,5.02,1.02.86,2.47.94,3.34-.15.45-.56.55-1.22.6-1.92.02-.2.06-1.53.5-1.48.73.08.34,1.62.27,2.05-.29,1.78-1.65,4.07-3.77,3.43-.88-.26-1.59-.94-2.14-1.65-.51-.65-1.23-1.39-1.21-2.28-.4,2.22-1.64,3.85-3.66,4.88-.9.46-1.92.71-2.97.78-2.47.15-4.65-.74-6.19-2.65-.43-.53-2.47-3.7-1.18-4.08.28-.08.56.37.79.88Z"/>
						<path className={styles.cls5}
						      d="M1441.82,97.49c-.95.11-1.91.45-2.96.68-1.67.36-4.33.25-5.68,1.08-3.22,2,.33,4.99,3.24,6.22,3.29,1.4,9.77,1.05,10.03-3.76.09-1.63-.71-2.95-2.12-3.75-.87-.49-1.68-.57-2.51-.48Z"/>
					</g>
					<g>
						<path className={styles.cls7}
						      d="M1341.89,60.58c4.16,6.89,8.1,13.26,8.88,14.31,1.62,2.17,1.43,2.31,3.98,3.29,3.35,1.28,26.64-20.58,27.25-28.99-7.05-5.22-19.58-6.19-28.41-7.31-6.33-.8-16.41-2.3-22.48-.29-.41.14,5.37,10.01,10.78,19Z"/>
						<path className={styles.cls5} d="M1372.62,55.48s-18.27-8.02-33.64-8.18l16.16,25.07,17.48-16.89Z"/>
					</g>
				</g>
				<path
					id="Stripe"
					className={styles.cls12}
					d="M1348.38,96.27c22.71-20.48,66.17-32.43,91.41-27.33,1.93.39-5.26-13.45-11.47-15.43-16.05-5.1-72.64,9.3-82.9,29.54-1.53,3.01.84,15.13,2.97,13.21Z"
					style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
				/>
			</g>
			<g id="Kitty_Run03">
				<g id="Kitty_Run03_Lower">
					<path className={styles.cls6}
					      d="M937.1,286.68c0,4.6,28.16,8.32,62.9,8.32,34.74,0,62.9-3.73,62.9-8.32,0-4.6-28.16-8.33-62.9-8.33-34.74,0-62.9,3.73-62.9,8.33Z"/>
					<path className={styles.cls11}
					      d="M1003.26,131.52c13.48,37.8,61.4,28.5,61.4,11.35,0-15.94-27.76,9.92-40.64-18.51-3.94-8.7-25.02-4.78-20.76,7.16Z"/>
					<path className={styles.cls11}
					      d="M1001.4,215.19c-36.64,11.51-55.03-7.22-67.21-19.4-7.2,6.73-20.22,2.26-22.93-3.46-1.8-3.82,24.19-23.72,28.83-15.95,12.26,20.53,33.57,7.22,41.75.74,11.07-8.77,33.9,33.56,19.55,38.07Z"/>
					<g>
						<path className={styles.cls8}
						      d="M939.68,172.81c3.24,6.91,15.31,12.08,22.07,15.08,3.9,1.73,9.26,4.3,13.8,3.9,6.08-.53,6.97-5.24,1.45-8.55-3.51-2.11-7.29-2.01-10.88-4.45-4-2.72-7.87-5.29-11.59-8.39-3.08-2.57-6.56-6.13-10.88-5.1-3.43.82-5.57,4.09-3.96,7.52Z"/>
						<path className={styles.cls7}
						      d="M939.68,172.81c-1.6-3.42.54-6.7,3.96-7.52,4.32-1.03,7.8,2.52,10.88,5.1.04.03.09.07.13.1-2.51,1.52-4.76,3.54-6.04,6.03-.59,1.15-.95,2.55-1.34,3.97-3.38-2.24-6.25-4.81-7.6-7.68Z"/>
					</g>
					<path className={styles.cls11}
					      d="M1001.53,131.11c13.84,38.8,63.02,29.25,63.02,11.65,0-16.36-28.49,10.18-41.71-19-4.05-8.93-25.68-4.91-21.31,7.35Z"/>
					<path className={styles.cls3}
					      d="M1001.38,217.63c-38.34,8.92-33.46-33.55-25.89-49.62,5.05-10.71-5.52-35.3.34-50.01,5.63-14.13,37.13-12.02,40.76-9.8,18.2,11.12,26.8,40.44,25.97,65.07-.68,20.21-12.75,37.74-41.18,44.35Z"/>
					<path className={styles.cls8}
					      d="M972.23,176.9c2-7.21,4.23-9.61,5.03-14.47,1.28-7.79.37-13.17-2.2-24.87,8.69,2.85,24.06,35.29,22.28,39.06-3.52,7.45-17.18,5.19-25.1.29Z"/>
				</g>
				<path
					id="Shirt-2"
					data-name="Shirt"
					className={styles.cls1}
					d="M1037.03,134.94c-2.72-6.42-11.97-22.96-16.65-24.05,2.83,2.27,14.65,16.65,7.14,20.87-6.92,3.89-11.96-1.27-15.11-5.57-8.61-11.75-27.53-24.48-35.46-15.96s-.6,38.98-.28,48.05c.25,7.07-2.39,12.47-4.09,16.46,18.52,10.4,46.37,8.51,70.3-3.45,1.15-11.4-2.98-29.56-5.85-36.35ZM991.44,145.88c-8.55.31-15.73-6.29-16.03-14.74-.3-8.45,6.38-15.55,14.93-15.85s15.73,6.29,16.03,14.74-6.38,15.55-14.93,15.85Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-2" data-name="Stripes">
					<path
						className={styles.cls12}
						d="M1028.29,167.06c-6.94,2.26-14,3.72-20.96,4.32-11.22.96-22.34-.38-31.92-3.88.48-1.5.86-3.53,1.01-4.53,9.11,3.42,19.74,4.85,30.52,3.93,11.85-1.02,24.02-4.68,35.33-10.61.13.72.5,3.74.54,4.77-4.73,2.38-9.6,4.39-14.53,5.99Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls12}
						d="M990.31,114.16c-9.15.33-16.34,7.96-16.01,17.02s8.04,16.15,17.19,15.82c9.15-.33,16.34-7.96,16.01-17.02s-8.04-16.15-17.19-15.82ZM991.4,144.75c-7.92.28-14.59-5.84-14.87-13.66s5.93-14.41,13.85-14.69c7.92-.28,14.59,5.84,14.87,13.66s-5.93,14.4-13.85,14.69Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls12}
						d="M1024.22,134.06c-5.34.19-11.12-5.17-13.11-7.6-.44-.53-.92-1.16-1.46-1.86-3.58-4.62-9.58-12.37-17.67-12.92l.15-2.24c9.09.62,15.47,8.86,19.29,13.79.52.67,1,1.29,1.42,1.81,2.59,3.17,9.72,9.03,13.99,5.83,1.23-.93,1.9-2.16,1.98-3.67.15-2.84-1.7-8.11-11.37-17.1l1.53-1.64c8.27,7.7,12.34,14.05,12.08,18.87-.11,2.17-1.11,4.02-2.87,5.34-1.24.93-2.59,1.35-3.97,1.4Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Run03_Mid">
					<path className={styles.cls11}
					      d="M998.79,190.14c11.43-9.97,29.91-14.21,42.29-6.15-.29,1.3-.91,3.29-1.51,3.98-3.34,3.87-39.46,6.71-40.78,2.18Z"/>
					<path className={styles.cls3}
					      d="M994.7,192.15c4.24-5.15,27.54-15.24,42.44-8.6,12.77,5.69,28.54,23.39,33.1,37.93,8.01-10.79,17.54-4.23,18.44.12,1.64,7.9-11.45,19.9-22.69,19.7-6.54-8.17-44.77-28.05-65.05-23.57-20.67,4.57-13.04-17.34-6.25-25.58Z"/>
					<path className={styles.cls3}
					      d="M992.57,122.38c-35.46-20.95-64.34,18.85-53.94,31.09,9.77,11.49,13.43-25.76,42.27-11.8,8.83,4.27,22.87-12.67,11.67-19.29Z"/>
					<g>
						<g>
							<path className={styles.cls8}
							      d="M1067.21,49.38c-10.79,2.85-21.95,8.05-27.92,16.98-.29.44.47.87.76.43,5.85-8.74,16.82-13.84,27.38-16.63.53-.14.31-.93-.23-.78Z"/>
							<path className={styles.cls8}
							      d="M1073.57,63.03c-11.29,1.3-22.01,4.13-31.74,9.35-.48.26-.04.97.45.71,9.66-5.18,20.3-7.99,31.51-9.28.56-.06.33-.85-.23-.78Z"/>
							<path className={styles.cls8}
							      d="M1073.71,73.54c-10-.68-19.81.77-29.43,2.75-.55.11-.32.9.23.78,9.55-1.96,19.27-3.41,29.19-2.73.57.04.58-.76,0-.8Z"/>
						</g>
						<path className={styles.cls2}
						      d="M977.11,27.06c1.68,7.59,3.33,14.64,3.72,15.83.82,2.49.6,2.56,2.68,4.27,2.73,2.25,35.65-4.08,38.95-11.48-6.8-7.58-20.34-17.8-28.28-21.71-5.7-2.8-14.7-7.45-21.06-7.61-.43-.01,1.81,10.8,3.99,20.69Z"/>
						<g>
							<path className={styles.cls3}
							      d="M963.44,74.69c35.82-28.89,15.33-36.02,4.63-37.76,3.25-1.75,6.63-3.27,10.02-4.53,6.44,3.05,26.18,10.75,49.83,3.89,5.14,4.85,8.96,11.32,12.77,17.32,4.36,6.87,10.56,7.36,10.74,22.45.2,17.43-11.63,22.71-19.33,27.46-9.14,5.64-21.03,9.17-31.67,11.07-7.09,1.27-20.27.97-27.24-1.48-14.39-5.05-22.36-16.84-27.32-27.39-.24-.51-.46-1.03-.68-1.54,9.53-3.6,18.24-9.5,18.24-9.5Z"/>
							<path className={styles.cls7}
							      d="M948.74,49.32c2.84-5.43,7.35-12.04,19.33-12.4,10.69,1.74,31.19,8.88-4.63,37.76,0,0-8.71,5.9-18.24,9.5-5.4-12.94-2.02-24.21,3.55-34.87Z"/>
							<path className={styles.cls8}
							      d="M978.09,32.4c8.65-3.22,17.39-4.79,24.29-4.29,7.77.56,18.49,2.01,24.56,7.3.33.29.66.59.98.89-23.65,6.86-43.4-.84-49.83-3.89Z"/>
						</g>
						<path className={styles.cls4}
						      d="M1005.33,62.56c3.55,6.07.48,13.79-6.86,17.24-7.34,3.45-16.18,1.32-19.73-4.75-3.55-6.07-.48-13.79,6.86-17.24,7.34-3.45,16.18-1.32,19.73,4.75Z"/>
						<path className={styles.cls4}
						      d="M1032.8,49.14c3.2,4.63,3,9.69-.45,11.3-3.45,1.61-8.85-.84-12.05-5.48-3.2-4.63-3-9.69.46-11.3,3.45-1.61,8.85.85,12.05,5.48Z"/>
						<g>
							<path className={styles.cls8}
							      d="M981.34,94.21c9.06-6.19,20.16-11.29,30.81-10.5.52.04.36.84-.16.8-10.43-.77-21.33,4.25-30.2,10.31-.45.31-.91-.3-.45-.61Z"/>
							<path className={styles.cls8}
							      d="M987.74,106.65c8.19-7.44,17.2-13.58,27.4-17.64.5-.2.77.53.26.73-10.13,4.03-19.06,10.14-27.2,17.52-.41.37-.86-.25-.46-.61Z"/>
							<path className={styles.cls8}
							      d="M995.71,112.79c5.85-7.64,13.22-13.91,20.87-19.76.44-.33.89.28.46.61-7.59,5.8-14.9,12.02-20.7,19.6-.33.43-.95-.02-.62-.46Z"/>
						</g>
						<g>
							<path className={styles.cls10}
							      d="M1002.55,63.55c3.23,5.43,3.04,11.55-.43,13.67-3.47,2.12-8.91-.56-12.14-5.99-3.23-5.43-3.04-11.55.43-13.67,3.47-2.12,8.91.56,12.14,5.99Z"/>
							<path className={styles.cls9}
							      d="M994.13,65.51c1.62,2.72,4.32,4.08,6.03,3.04,1.71-1.04,1.78-4.1.16-6.82-1.62-2.72-4.32-4.08-6.03-3.04-1.71,1.04-1.78,4.1-.16,6.82Z"/>
						</g>
						<g>
							<path className={styles.cls10}
							      d="M1031.59,49.7c2.65,4.56,3.1,9.33,1.01,10.65-2.1,1.31-5.95-1.32-8.6-5.88-2.65-4.56-3.11-9.33-1.01-10.64,2.1-1.32,5.95,1.32,8.6,5.88Z"/>
							<path className={styles.cls9}
							      d="M1026.2,50.4c1.33,2.29,3.25,3.62,4.28,2.97,1.03-.65.79-3.03-.54-5.32-1.33-2.29-3.24-3.62-4.28-2.97-1.03.65-.79,3.03.54,5.31Z"/>
						</g>
						<path className={styles.cls8}
						      d="M1030.99,83.02c.37.39.7.84.82.97.75.79,1.63,1.51,2.7,1.79,2.09.54,4.82-.24,6.35-1.82,1.82-1.89,1.95-4.03,1.19-6.42-.37-1.18-.65-2.66-1.34-3.7-.15-.23-.46-.48-.57-.73-.13-.32.26-1.14.64-1.19.77-.1,1.11.93,1.3,1.49.3.88.66,1.63,1.07,2.46.75,1.53,1.62,3.5,3.33,4.17,1.25.49,2.64.1,3.11-1.22.24-.67.12-1.33-.05-2.01-.05-.19-.43-1.47,0-1.56.72-.16.84,1.42.92,1.85.31,1.78-.25,4.39-2.46,4.47-.92.03-1.81-.37-2.56-.87-.69-.45-1.61-.92-1.88-1.77.34,2.23-.31,4.17-1.89,5.8-.7.72-1.58,1.3-2.56,1.69-2.29.94-4.64.81-6.72-.51-.57-.36-3.53-2.71-2.43-3.48.24-.17.65.17,1.03.58Z"/>
						<path className={styles.cls5}
						      d="M1040,64.14c-.86.41-1.66,1.05-2.58,1.6-1.46.88-4.02,1.63-5.02,2.85-2.4,2.93,1.92,4.61,5.07,4.84,3.57.26,9.58-2.16,8.27-6.8-.44-1.57-1.62-2.56-3.22-2.86-.98-.18-1.78,0-2.53.36Z"/>
					</g>
					<g>
						<path className={styles.cls7}
						      d="M933.5,61.49c6.16,5.18,11.95,9.94,13.02,10.67,2.23,1.54,2.1,1.73,4.83,1.82,3.58.13,18.56-28.08,16.43-36.24-8.36-2.66-20.53.47-29.25,2.26-6.25,1.29-16.28,3.12-21.37,6.99-.34.26,8.31,7.74,16.34,14.49Z"/>
						<path className={styles.cls5} d="M960.94,46.74s-19.88-1.69-34.48,3.13l23.4,18.5,11.08-21.63Z"/>
					</g>
				</g>
				<path
					id="Stripe-2"
					data-name="Stripe"
					className={styles.cls12}
			        d="M951.18,93.17c14.88-26.71,52.15-52.07,77.68-55.39,1.96-.25-9.32-11.03-15.84-10.89-16.84.36-65.74,32.27-68.91,54.73-.47,3.34,5.68,14.05,7.08,11.55Z"
					style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
				/>
			</g>
			<g id="Kitty_Run02">
				<g id="Kitty_Run02_Lower">
					<path className={styles.cls6}
					      d="M526.81,285.31c0,5.35,32.76,9.69,73.19,9.69,40.42,0,73.19-4.34,73.19-9.69,0-5.35-32.76-9.69-73.19-9.69-40.42,0-73.19,4.34-73.19,9.69Z"/>
					<path className={styles.cls11}
					      d="M614.02,221.02c0,28.79-5.51,44.53-38.05,50.96,7.89,1.15,11.06,8.3,7.76,12.39-1.38,1.71-20.58,1.62-22.37.11-5.92-4.99-8.53-18.92-6.03-20.26,16.96-9.05,19.84-32.62,20.74-40.34,1.63-14.03,37.96-17.91,37.96-2.87Z"/>
					<g>
						<path className={styles.cls8}
						      d="M525.92,207.06c5.15,5.63,18.21,6.97,25.56,7.82,4.24.49,10.12,1.35,14.33-.38,5.65-2.32,5.1-7.08-1.17-8.6-3.98-.96-7.56.25-11.71-1.01-4.63-1.41-9.09-2.7-13.56-4.56-3.71-1.54-8.09-3.89-11.9-1.62-3.03,1.8-4.1,5.57-1.54,8.36Z"/>
						<path className={styles.cls7}
						      d="M525.92,207.06c-2.55-2.79-1.48-6.55,1.54-8.36,3.81-2.27,8.19.09,11.9,1.62.05.02.1.04.15.06-1.94,2.2-3.49,4.79-3.97,7.55-.22,1.28-.15,2.72-.1,4.19-3.89-1.13-7.4-2.73-9.54-5.07Z"/>
					</g>
					<path className={styles.cls3}
					      d="M590.88,233.64c-37.64,7.34-31.41-33.83-23.48-49.21,5.29-10.25-4.14-34.56,2.08-48.67,5.98-13.56,36.57-10.4,40.02-8.12,17.34,11.46,24.68,40.31,23.01,64.26-1.37,19.66-13.73,36.3-41.64,41.74Z"/>
					<path className={styles.cls8}
					      d="M561.59,201.31c1.74-10.62,4.4-12.35,5.49-16.66,1.95-7.65,2.82-14.62.24-26.32,8.69,2.85,21.15,38.92,19.37,42.69-3.52,7.45-17.18,5.19-25.1.29Z"/>
				</g>
				<path
					id="Shirt-3"
					data-name="Shirt"
					className={styles.cls1}
					d="M628.47,154.38c-2.42-6.35-10.85-22.78-15.37-24,2.68,2.31,13.68,16.72,6.22,20.57-6.88,3.55-11.8-1.53-14.51-5.95-6.15-10.05-25.94-24.8-33.97-16.77s-1.95,37.92-1.95,46.77c0,6.89-2.76,12.06-4.56,15.88,17.67,10.77,44.85,9.91,68.56-.9,1.52-11.06-1.87-28.89-4.42-35.6ZM583.71,163.43c-8.33,0-15.09-6.68-15.09-14.91s6.76-14.91,15.09-14.91,15.09,6.68,15.09,14.91-6.76,14.91-15.09,14.91Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-3" data-name="Stripes">
					<path
						className={styles.cls12}
						d="M618.84,185.34c-6.84,1.96-13.76,3.13-20.56,3.47-10.95.54-21.74-1.15-30.94-4.89.52-1.45.96-3.4,1.14-4.37,8.75,3.65,19.05,5.42,29.58,4.89,11.57-.58,23.55-3.71,34.77-9.09.1.7.36,3.66.36,4.67-4.69,2.15-9.5,3.94-14.35,5.32Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls12}
						d="M583.71,132.51c-8.92,0-16.19,7.18-16.19,16.01s7.26,16.01,16.19,16.01,16.19-7.18,16.19-16.01-7.26-16.01-16.19-16.01ZM583.71,162.33c-7.72,0-14-6.2-14-13.82s6.28-13.82,14-13.82,14,6.2,14,13.82-6.28,13.82-14,13.82Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls12}
						d="M616.04,153.07c-5.2,0-10.65-5.42-12.5-7.86-.41-.54-.86-1.16-1.36-1.86-3.33-4.63-8.9-12.37-16.75-13.2l.23-2.18c8.83.93,14.75,9.17,18.3,14.1.49.68.93,1.29,1.32,1.81,2.42,3.18,9.15,9.14,13.42,6.16,1.23-.86,1.93-2.04,2.06-3.5.24-2.76-1.37-7.95-10.47-17.05l1.55-1.55c7.78,7.78,11.52,14.11,11.11,18.79-.19,2.11-1.22,3.87-2.98,5.1-1.24.87-2.57,1.23-3.91,1.23Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Run02_Mid">
					<path className={styles.cls11}
					      d="M622.95,216.62c-.59.74-1.53,1.74-2.83,3.05-3.05-.61-7.98-10.36-8.41-13.03,6.19,1.24,10.63,8.57,11.25,9.98Z"/>
					<path className={styles.cls3}
					      d="M574.49,140.45c-7.7,13.19-8.46,35.05-1.88,41.18,6.74,6.29,32.94,11.18,35.29-.25,2.85-13.84-14.22-10.57-16.51-13.96s-.31-13.37,1.23-17.91c3.07-9.05-11.75-20.02-18.14-9.07Z"/>
					<path className={styles.cls3}
					      d="M613.83,207.97c14.51,13.56,14.86,43.13-1.19,50.5-16,7.35-24.98,1.98-33.66,3.04,8.3,13.93-11.19,21.51-14.4,15.62-5.71-10.49-8.24-30.71,3.12-27.83,10.52,2.66,23.44-4.1,12.65-14.88-15.34-15.34,24.13-35.19,33.48-26.45Z"/>
					<g>
						<g>
							<path className={styles.cls8}
							      d="M673.51,93.82c-11.13-.79-23.37.53-31.91,7.05-.42.32.17.97.58.65,8.36-6.38,20.39-7.67,31.29-6.89.55.04.59-.77.03-.81Z"/>
							<path className={styles.cls8}
							      d="M675.12,108.79c-11.1-2.42-22.17-3.2-33.06-1.4-.54.09-.35.9.2.82,10.81-1.78,21.79-1,32.82,1.4.55.12.58-.7.04-.81Z"/>
							<path className={styles.cls8}
							      d="M671.86,118.79c-9.24-3.88-19-5.67-28.74-6.91-.56-.07-.59.74-.04.82,9.67,1.23,19.34,3,28.51,6.85.53.22.79-.54.27-.76Z"/>
						</g>
						<path className={styles.cls2}
						      d="M595.45,43.59c-.86,7.72-1.58,14.93-1.6,16.18-.03,2.63-.26,2.62,1.16,4.91,1.86,3.01,35.05,7.65,40.57,1.72-3.99-9.37-13.49-23.42-19.76-29.68-4.49-4.49-11.51-11.8-17.47-14.01-.4-.15-1.78,10.81-2.91,20.87Z"/>
						<g>
							<path className={styles.cls3}
							      d="M567.13,84.25c43.23-15.77,26.14-29.14,16.58-34.24,3.64-.6,7.33-.95,10.95-1.05,5.11,4.97,21.31,18.63,45.9,19.78,3.3,6.25,4.83,13.61,6.49,20.52,1.91,7.9,7.62,10.38,2.91,24.72-5.44,16.56-18.34,17.74-27.16,19.74-10.47,2.38-22.86,1.88-33.55.25-7.12-1.09-19.5-5.63-25.31-10.2-11.99-9.43-15.72-23.16-17.01-34.74-.06-.56-.11-1.12-.14-1.68,10.18-.33,20.33-3.1,20.33-3.1Z"/>
							<path className={styles.cls7}
							      d="M561.41,55.5c4.44-4.23,10.85-9.02,22.3-5.49,9.56,5.1,26.65,18.48-16.58,34.24,0,0-10.15,2.77-20.33,3.1-.93-13.99,5.9-23.56,14.62-31.85Z"/>
							<path className={styles.cls8}
							      d="M594.66,48.96c9.22-.25,18.01,1.08,24.38,3.78,7.17,3.04,16.85,7.88,20.89,14.84.22.38.43.77.64,1.16-24.6-1.15-40.8-14.82-45.9-19.78Z"/>
						</g>
						<path className={styles.cls4}
						      d="M610.69,86.31c1.4,6.89-4,13.21-12.06,14.1-8.06.89-15.74-3.98-17.14-10.87-1.4-6.89,4-13.2,12.06-14.1,8.06-.89,15.74,3.98,17.14,10.87Z"/>
						<path className={styles.cls4}
						      d="M641.03,82.48c1.53,5.42-.29,10.14-4.08,10.55-3.79.41-8.1-3.66-9.63-9.07-1.53-5.42.29-10.14,4.08-10.55,3.79-.41,8.1,3.66,9.63,9.07Z"/>
						<g>
							<path className={styles.cls8}
							      d="M577.76,108.51c10.58-2.93,22.73-4.17,32.55.02.48.2.07.91-.41.71-9.62-4.1-21.56-2.86-31.91,0-.53.15-.76-.58-.23-.73Z"/>
							<path className={styles.cls8}
							      d="M579.81,122.35c10.16-4.39,20.66-7.3,31.63-7.85.54-.03.55.75,0,.78-10.89.54-21.32,3.44-31.4,7.8-.5.22-.73-.51-.23-.73Z"/>
							<path className={styles.cls8}
							      d="M585.36,130.73c8.01-5.34,17.01-8.9,26.13-11.96.52-.17.75.55.23.73-9.05,3.03-17.98,6.56-25.92,11.86-.46.3-.89-.33-.44-.63Z"/>
						</g>
						<g>
							<path className={styles.cls10}
							      d="M607.74,86.34c1.3,6.18-.86,11.91-4.83,12.8-3.97.88-8.25-3.41-9.55-9.59-1.31-6.18.86-11.91,4.83-12.8,3.97-.88,8.25,3.41,9.55,9.59Z"/>
							<path className={styles.cls9}
							      d="M599.14,85.48c.65,3.1,2.77,5.26,4.72,4.82,1.96-.44,3.01-3.3,2.35-6.4-.65-3.1-2.77-5.26-4.72-4.82-1.95.44-3.01,3.3-2.35,6.4Z"/>
						</g>
						<g>
							<path className={styles.cls10}
							      d="M639.7,82.62c1.04,5.18-.08,9.83-2.49,10.4-2.41.57-5.2-3.17-6.24-8.35-1.04-5.18.08-9.83,2.48-10.4,2.41-.57,5.2,3.17,6.24,8.35Z"/>
							<path className={styles.cls9}
							      d="M634.37,81.55c.52,2.59,1.9,4.47,3.09,4.19,1.19-.28,1.73-2.61,1.21-5.2-.52-2.59-1.9-4.47-3.09-4.19-1.19.28-1.73,2.61-1.2,5.2Z"/>
						</g>
						<path className={styles.cls8}
						      d="M628.37,113.96c.22.49.39,1.02.46,1.18.45.99,1.06,1.96,1.98,2.57,1.8,1.19,4.64,1.33,6.59.33,2.33-1.2,3.14-3.18,3.2-5.69.03-1.24.24-2.73-.07-3.93-.07-.27-.28-.6-.3-.87-.02-.35.61-1,.99-.92.76.16.75,1.24.75,1.83,0,.93.1,1.75.21,2.68.21,1.69.4,3.84,1.8,5.02,1.02.86,2.47.94,3.34-.15.45-.56.55-1.22.6-1.92.02-.2.06-1.53.5-1.48.73.08.34,1.62.27,2.05-.29,1.78-1.65,4.07-3.77,3.43-.88-.26-1.59-.94-2.14-1.65-.51-.65-1.23-1.39-1.21-2.28-.4,2.22-1.64,3.85-3.66,4.88-.9.46-1.92.71-2.97.78-2.47.15-4.65-.74-6.19-2.65-.43-.53-2.47-3.7-1.18-4.08.28-.08.56.37.79.88Z"/>
						<path className={styles.cls5}
						      d="M642.99,99c-.95.11-1.91.45-2.96.68-1.67.36-4.33.25-5.68,1.08-3.22,2,.33,4.99,3.24,6.22,3.29,1.4,9.77,1.05,10.03-3.76.09-1.63-.71-2.95-2.12-3.75-.87-.49-1.68-.57-2.51-.48Z"/>
					</g>
					<g>
						<path className={styles.cls7}
						      d="M543.06,62.09c4.16,6.89,8.1,13.26,8.88,14.31,1.62,2.17,1.43,2.31,3.98,3.29,3.35,1.28,26.64-20.58,27.25-28.99-7.05-5.22-19.58-6.19-28.41-7.31-6.33-.8-16.41-2.3-22.48-.29-.41.14,5.37,10.01,10.78,19Z"/>
						<path className={styles.cls5} d="M573.79,56.99s-18.27-8.02-33.64-8.18l16.16,25.07,17.48-16.89Z"/>
					</g>
				</g>
				<path
					id="Stripe-3"
					data-name="Stripe"
					className={styles.cls12}
					d="M549.55,97.78c22.71-20.48,66.17-32.43,91.41-27.33,1.93.39-5.26-13.45-11.47-15.43-16.05-5.1-72.64,9.3-82.9,29.54-1.53,3.01.84,15.13,2.97,13.21Z"
					style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
				/>
			</g>
			<g id="Kitty_Run01">
				<g id="Kitty_Run01_Lower">
					<path className={styles.cls6}
					      d="M137.1,285.31c0,4.6,28.16,8.32,62.9,8.32,34.74,0,62.9-3.73,62.9-8.32,0-4.6-28.16-8.33-62.9-8.33-34.74,0-62.9,3.73-62.9,8.33Z"/>
					<path className={styles.cls11}
					      d="M201.88,186.05c4.89-4.27,29.46-10.13,42.73-1.36,14.15,9.35,22.81,27.77,25.97,35.93,9.42-9.09,15.43-4.55,16.81-.12,1.51,4.86-14.66,22.31-22.48,21.42-5.25-11.02-32.99-28.73-53.02-28.73-22.88,0-17.83-20.29-10-27.14Z"/>
					<path className={styles.cls11}
					      d="M193.98,117.4c-34.55-20.41-62.69,18.36-52.55,30.28,9.52,11.2,13.09-25.1,41.18-11.5,8.6,4.16,22.28-12.34,11.37-18.79Z"/>
					<g>
						<path className={styles.cls8}
						      d="M145.21,164.4c2.64,7.16,14.23,13.34,20.71,16.89,3.74,2.05,8.86,5.07,13.42,5.06,6.11-.01,7.39-4.63,2.17-8.4-3.32-2.4-7.09-2.62-10.46-5.36-3.76-3.05-7.39-5.93-10.84-9.35-2.85-2.83-6.02-6.66-10.41-6-3.48.53-5.89,3.61-4.59,7.15Z"/>
						<path className={styles.cls7}
						      d="M145.21,164.4c-1.31-3.55,1.1-6.63,4.59-7.15,4.39-.66,7.55,3.17,10.41,6,.04.04.08.08.12.11-2.63,1.3-5.04,3.12-6.53,5.5-.69,1.1-1.16,2.46-1.67,3.84-3.18-2.52-5.82-5.32-6.92-8.3Z"/>
					</g>
					<path className={styles.cls3}
					      d="M203.02,214.58c-37.64,7.34-31.41-33.83-23.48-49.21,5.29-10.25-4.14-34.56,2.08-48.67,5.98-13.56,36.57-10.4,40.02-8.12,17.34,11.46,24.68,40.31,23.01,64.26-1.37,19.66-13.73,36.3-41.64,41.74Z"/>
					<path className={styles.cls8}
					      d="M175.82,175.21c1.6-6.73,3.94-9.5,5.06-14.21,5.04-21.17-1.23-35.83-.22-41.62,14.19-2.34,18.73,49.16,16.96,52.86-3.49,7.31-13.87,7.83-21.8,2.98Z"/>
				</g>
				<path
					id="Shirt-4"
					data-name="Shirt"
					className={styles.cls1}
					d="M240.61,135.32c-.62-1.63-1.64-3.92-2.88-6.44-3.03,0-7.01-1.76-13.26-10.08-12.93-17.22-33.47-17.66-41.49-9.63s-1.95,37.92-1.95,46.77c0,6.89-2.76,12.06-4.56,15.88,17.67,10.77,44.85,9.91,68.56-.9,1.52-11.06-1.87-28.89-4.42-35.6ZM208.87,144.38c-8.33,0-15.09-6.68-15.09-14.91s6.76-14.91,15.09-14.91,15.09,6.68,15.09,14.91-6.76,14.91-15.09,14.91Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-4" data-name="Stripes">
					<path
						className={styles.cls12}
						d="M230.98,166.28c-6.84,1.96-13.76,3.13-20.56,3.47-10.95.54-21.74-1.15-30.94-4.89.52-1.45.96-3.4,1.14-4.37,8.75,3.65,19.05,5.42,29.58,4.89,11.57-.58,23.55-3.71,34.77-9.09.1.7.36,3.66.36,4.67-4.69,2.15-9.5,3.94-14.35,5.32Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls12}
						d="M209.22,113.63c-8.92,0-16.19,7.18-16.19,16.01s7.26,16.01,16.19,16.01,16.19-7.18,16.19-16.01-7.26-16.01-16.19-16.01ZM209.22,143.46c-7.72,0-14-6.2-14-13.82s6.28-13.82,14-13.82,14,6.2,14,13.82-6.28,13.82-14,13.82Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls12}
						d="M238.57,130.62c-5.2,0-10.81-5.45-12.66-7.89-.41-.54-.86-1.16-1.36-1.86-3.33-4.63-8.9-12.37-16.75-13.2l.23-2.18c8.83.93,14.75,9.17,18.3,14.1.49.68.93,1.29,1.32,1.81,1.92,2.53,5.87,6.84,9.84,6.98.39.67.96,1.97,1.08,2.23Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Run01_Mid">
					<path className={styles.cls3}
					      d="M202.74,214.27c-36.64,11.51-55.03-7.22-67.21-19.4-7.2,6.73-20.22,2.26-22.93-3.46-1.8-3.82,24.19-23.72,28.83-15.95,12.26,20.53,29.58,9.27,38.07,3.19,7.94-5.68,37.59,31.11,23.23,35.61Z"/>
					<path className={styles.cls3}
					      d="M201.53,134.69c14.71,37.33,62.3,26.47,61.74,9.34-.52-15.93-27.42,10.83-41.22-17.17-4.23-8.57-25.16-3.96-20.52,7.83Z"/>
					<g>
						<g>
							<path className={styles.cls8}
							      d="M277.63,45.73c-10.66,3.3-21.58,8.98-27.17,18.15-.28.45.51.85.78.4,5.47-8.98,16.21-14.55,26.65-17.78.53-.16.27-.94-.26-.77Z"/>
							<path className={styles.cls8}
							      d="M284.57,59.1c-11.22,1.78-21.81,5.07-31.31,10.69-.47.28,0,.97.48.69,9.43-5.59,19.94-8.84,31.09-10.61.56-.09.29-.86-.26-.77Z"/>
							<path className={styles.cls8}
							      d="M285.16,69.6c-10.02-.26-19.76,1.62-29.28,4-.54.14-.28.91.26.77,9.45-2.36,19.11-4.22,29.05-3.97.57.01.54-.79-.03-.8Z"/>
						</g>
						<path className={styles.cls2}
						      d="M186.66,27.27c2,7.51,3.95,14.48,4.39,15.66.92,2.46.71,2.53,2.86,4.15,2.83,2.13,35.44-5.6,38.43-13.13-7.12-7.28-21.07-16.92-29.18-20.48-5.81-2.55-15-6.82-21.36-6.71-.43,0,2.27,10.71,4.87,20.51Z"/>
						<g>
							<path className={styles.cls3}
							      d="M175.04,75.44c34.56-30.39,13.78-36.64,3.02-37.93,3.17-1.88,6.48-3.54,9.82-4.95,6.56,2.77,26.62,9.63,49.96,1.77,5.35,4.62,9.44,10.93,13.5,16.76,4.64,6.67,10.87,6.91,11.69,21.98.95,17.41-10.65,23.19-18.14,28.25-8.89,6.02-20.62,10.05-31.17,12.41-7.03,1.57-20.21,1.83-27.28-.32-14.59-4.44-23.06-15.87-28.47-26.2-.26-.5-.51-1.01-.74-1.51,9.37-4,17.82-10.27,17.82-10.27Z"/>
							<path className={styles.cls7}
							      d="M159.27,50.72c2.61-5.55,6.83-12.34,18.78-13.21,10.76,1.28,31.54,7.54-3.02,37.93,0,0-8.45,6.27-17.82,10.27-5.94-12.7-3.05-24.1,2.06-34.98Z"/>
							<path className={styles.cls8}
							      d="M187.88,32.56c8.5-3.58,17.17-5.53,24.09-5.32,7.78.23,18.56,1.22,24.85,6.24.35.28.68.56,1.02.85-23.34,7.86-43.39,1.01-49.96-1.77Z"/>
						</g>
						<path className={styles.cls4}
						      d="M216.38,61.54c3.81,5.91,1.07,13.76-6.12,17.51-7.19,3.76-16.11,2.01-19.92-3.91-3.81-5.91-1.07-13.75,6.12-17.51,7.19-3.76,16.11-2.01,19.92,3.91Z"/>
						<path className={styles.cls4}
						      d="M243.25,46.97c3.4,4.49,3.41,9.55.03,11.31-3.38,1.75-8.88-.47-12.27-4.96-3.39-4.49-3.41-9.55-.03-11.31,3.38-1.75,8.87.47,12.27,4.96Z"/>
						<g>
							<path className={styles.cls8}
							      d="M193.75,94.19c8.79-6.57,19.66-12.14,30.33-11.8.52.02.4.82-.12.81-10.45-.33-21.13,5.16-29.73,11.59-.44.33-.92-.26-.48-.59Z"/>
							<path className={styles.cls8}
							      d="M200.68,106.34c7.87-7.78,16.61-14.3,26.62-18.79.49-.22.79.5.29.72-9.95,4.46-18.61,10.94-26.43,18.67-.39.39-.87-.21-.48-.59Z"/>
							<path className={styles.cls8}
							      d="M208.9,112.13c5.52-7.88,12.62-14.47,20.01-20.63.42-.35.9.24.48.59-7.33,6.11-14.37,12.64-19.85,20.46-.32.45-.95.02-.64-.43Z"/>
						</g>
						<g>
							<path className={styles.cls10}
							      d="M213.64,62.64c3.46,5.29,3.53,11.41.15,13.68-3.38,2.27-8.92-.18-12.38-5.47-3.46-5.29-3.53-11.41-.15-13.67,3.38-2.27,8.92.18,12.38,5.47Z"/>
							<path className={styles.cls9}
							      d="M205.31,64.96c1.73,2.65,4.49,3.9,6.15,2.78,1.66-1.12,1.6-4.17-.13-6.82-1.74-2.65-4.49-3.9-6.15-2.78-1.66,1.12-1.6,4.17.13,6.82Z"/>
						</g>
						<g>
							<path className={styles.cls10}
							      d="M242.06,47.58c2.85,4.45,3.5,9.19,1.46,10.59-2.04,1.4-6-1.07-8.85-5.51-2.85-4.45-3.5-9.19-1.46-10.59,2.04-1.4,6,1.06,8.85,5.51Z"/>
							<path className={styles.cls9}
							      d="M236.71,48.51c1.43,2.23,3.4,3.48,4.4,2.79,1-.69.66-3.06-.76-5.29-1.43-2.23-3.4-3.48-4.4-2.79-1,.69-.66,3.06.77,5.29Z"/>
						</g>
						<path className={styles.cls8}
						      d="M242.88,80.89c.38.37.74.81.86.93.78.75,1.69,1.44,2.77,1.67,2.11.46,4.8-.44,6.26-2.09,1.74-1.96,1.77-4.11.91-6.46-.42-1.16-.76-2.63-1.49-3.64-.16-.23-.48-.46-.6-.7-.15-.32.21-1.15.58-1.22.77-.13,1.15.88,1.37,1.43.34.87.72,1.6,1.17,2.42.81,1.5,1.77,3.43,3.5,4.02,1.27.43,2.64-.02,3.06-1.36.21-.68.06-1.34-.14-2-.06-.19-.49-1.45-.07-1.56.71-.19.9,1.39.99,1.81.38,1.77-.06,4.39-2.27,4.57-.91.07-1.82-.3-2.59-.76-.71-.42-1.65-.85-1.95-1.68.43,2.21-.13,4.18-1.64,5.88-.67.75-1.53,1.36-2.48,1.8-2.25,1.04-4.6,1-6.73-.23-.59-.34-3.64-2.56-2.58-3.37.23-.18.66.15,1.06.54Z"/>
						<path className={styles.cls5}
						      d="M251.08,61.64c-.85.45-1.62,1.12-2.51,1.71-1.42.94-3.95,1.8-4.9,3.07-2.27,3.03,2.11,4.53,5.27,4.62,3.57.11,9.48-2.57,7.98-7.14-.51-1.55-1.73-2.49-3.34-2.72-.98-.14-1.78.08-2.51.47Z"/>
					</g>
					<g>
						<path className={styles.cls7}
						      d="M144.56,63.53c6.38,4.91,12.36,9.42,13.47,10.11,2.3,1.44,2.18,1.64,4.9,1.62,3.58-.03,17.35-28.85,14.87-36.91-8.46-2.31-20.49,1.34-29.13,3.5-6.19,1.55-16.13,3.81-21.05,7.89-.33.27,8.64,7.38,16.94,13.79Z"/>
						<path className={styles.cls5} d="M171.35,47.62s-19.93-.85-34.32,4.59l24.16,17.49,10.15-22.08Z"/>
					</g>
				</g>
				<path
					id="Stripe-4"
					data-name="Stripe"
					className={styles.cls12}
					d="M163.57,94.43c13.72-27.32,49.88-54.24,75.25-58.65,1.94-.34-9.78-10.63-16.29-10.21-16.81,1.07-64.31,35.04-66.52,57.62-.33,3.36,6.27,13.8,7.56,11.24Z"
					style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
				/>
			</g>
		</svg>
	);
};