import React, { Fragment, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import styles from "./SignupForVetCheck.scss";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import * as StripeApi from "api/StripeApi";
import * as VetCheckApi from "api/VetCheckApi";
import * as StripeActions from "actions/StripeActions";
import * as UserActions from "actions/UserActions";
import * as VetCheckActions from "actions/VetCheckActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import TextBox from "components/common/TextBox";
import Dropdown from "components/common/Dropdown";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import env from "utils/environment";
import getCardIcon from "utils/getCardIcon";
import logger from "utils/logger";
import { formatCurrencyString } from "utils/numeric";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import { caProvincesDropdownList } from "constants/CAProvinces";
import { countriesDropdownList } from "constants/Countries";
import * as status from "constants/HttpResponseCodes";
import { VETCHECK } from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";
import { usStatesDropdownList } from "constants/USStates";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

let productIds = ["prod_LYTcN6pp5AwZpB", "prod_LYTevc7z6v5Fnj", "prod_IEswVpbuZs7rwH"];

function SignupForVetCheck(props) {
    const clientId = useMemo(() => (env.getValue("IDENTITY_CLIENT_ID")), []);
    if(clientId === "oidcSpaClientProd") {
        productIds = ["prod_LYTjwl8S1bFoGM", "prod_LYTjhDfifW8qWO", "prod_IEW6U54mpGq23p"];
    }

    const [page, setPage] = useState(1);
    const [formData, setFormData] = useState({
        countryCode: "US",
    });
    const [loading, setLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [stripeError, setStripeError] = useState(null);
    const [showAddCard, setShowAddCard] = useState(false);
    const [cardName, setCardName] = useState("");
    const [cardList, setCardList] = useState([]);
    const [subscriptionErrors, setSubscriptionErrors] = useState([]);
    const [clinicErrors, setClinicErrors] = useState([]);

    if(!props.clinic?.clinicId) {
        return <SpinnerTakeover show/>;
    }

    const zipCodeLabel = formData?.countryCode === "CA" ? "Postal Code" :  formData?.countryCode === "US" ? "Zip Code" : "Zip/Postal Code";
    const stateLabel = formData?.countryCode === "CA" ? "Province" :  formData?.countryCode === "US" ? "State" : "State/Province";
    const locationDropdownList = formData?.countryCode === "CA" ? caProvincesDropdownList :  formData?.countryCode === "US" ? usStatesDropdownList : [];
    const CARD_OPTIONS = map(cardList, card => {
        const cardIcon = getCardIcon(card?.brand);
        return {
            name: (
                <div
                    key={card.id}
                    className="flex spaced-content no-wrap flex-centered"
                >
                    <div className="flex-none">{cardIcon}</div>
                    <div className="flex-1 no-wrap">
                        <div>{card.name}</div>
                        {card.isDefault && (
                            <div className="flex-1 text-x-sm">(Default)</div>
                        )}
                    </div>
                    <div className="flex-1 no-wrap"><span className="text-mid-gray">last 4:</span> {card.last4}</div>
                    <div className="flex-1 no-wrap"><span className="text-mid-gray">EXP:</span> {card.expMonth}/{card.expYear}</div>
                </div>
            ),
            value: card.id
        }
    });

    const stripe = useStripe();
    const elements = useElements();

    const isValidSubscription = () => {
        const formErrors = [];
        let requiredValues;
        if (props.requireCard){
            requiredValues = ["priceId", "cardId"]
        } else {
            requiredValues = []
        }

        forEach(requiredValues, value => {
            if(!formData[value]) {
                formErrors.push(value);
            }
        });
        setSubscriptionErrors(formErrors);
        logger.error(formErrors, "ERRORS");
        return !formErrors?.length;
    }

    const isValidClinic = () => {
        const formErrors = [];
        const requiredValues = ["clinicName", "email", "address1", "city", "state", "postalCode", "countryCode"];
        forEach(requiredValues, value => {
            if(!formData[value]) {
                formErrors.push(value);
            }
        })
        setClinicErrors(formErrors);
        return !formErrors?.length;
    }

    const btnEnabled = !(stripeError || btnDisabled);

    const USER_FORM = 1;
    const PAYMENT_FORM = 2;

    const getCards = () => {
        if(props.clinic.clinicId) {
            setLoading(true);
            if (props.requireCard) {
                StripeApi.getPaymentOptions(props.clinic.clinicId)
                    .then((res) => {
                        setCardList(res.body);
                        setLoading(false);
                    })
                    .catch((error) => {
                        setLoading(false);
                        if (error.status === status.HTTP_STATUS_400) {
                            toast.warn("User is not in a clinic.");
                        } else {
                            handleErrorResponse("loading clinic's cards", error);
                        }
                    });
            }
        }
    }

    const handleChange = ({name, value}) => {
        if (name === "countryCode" && (value !== formData.countryCode)) {
            //If the country code is changed then clear the state selection
            setFormData({
                ...formData,
                [name]: value,
                state: null
            })
        } else {
            setFormData({
                ...formData,
                [name]: value
            })
        }
        // Remove error if the value is changed
        if(!!includes(clinicErrors, name)){
            const newErrors = reject(clinicErrors, e => {return e === name});
            setClinicErrors(newErrors);
        }
        if(!!includes(subscriptionErrors, name)){
            const newErrors = reject(subscriptionErrors, e => {return e === name});
            setSubscriptionErrors(newErrors);
        }
    }

    // If the user is a greenline admin then we need to get the clinicAdmin's info for pre-population of the form
    useEffect(() => {
        // Set the default form info based on the clinic and clinic admin info
        if(!!props.clinic) {
            getCards();
            props.getProducts();
            forEach(productIds, productId => {
                props.getPriceOptions(productId);
            });
            setFormData({
                ...formData,
                clinicName: props.clinic.name,
                email: props.clinic.contactEmail,
                phoneNumber: props.clinic.contactPhone,
                address1: props.clinic.street1,
                address2: props.clinic.street2,
                city: props.clinic.city,
                state: props.clinic.state,
                postalCode: props.clinic.zipCode,
                countryCode: props.clinic.countryCode,
                customerId: props.clinic.stripeCustomerId
            });
        }
    }, [props.clinic]);

    useEffect(() => {
        // Set the default card after cards are loaded
        if(!!cardList?.length) {
            const defaultCard = find(cardList, {isDefault: true});
            handleChange({name: "cardId", value: defaultCard?.id});
        }
    }, [cardList]);

    useEffect(() => {
        if(props.requireClinic) {
            setPage(USER_FORM);
        } else {
            setPage(PAYMENT_FORM);
        }
    }, [props.requireClinic]);

    if (props.requireCard && !(cardList?.length || props.canEditPaymentSettings)){
        return (
            <AccessDenied/>
        )
    }

    const handleError = (event) => {
        if (event.error) {
            setStripeError(event.error.message);
        } else {
            setStripeError(null);
        }
    }

    const goNext = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(isValidClinic() || !props.requireClinic) {
            setPage(PAYMENT_FORM);
        }
    }

    const handleSubscribeClicked = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(!props.requireCard) {
            if (confirm("Are you sure you want to sign this clinic up for a manufacturer sponsored subscription?")) {
                handleSubscribe();
            }
        } else {
            handleSubscribe();
        }
    }

    const handleSubscribe = () => {
        if (!props.requireClinic) {
            if(isValidSubscription()) {
                if (!!props.clinic.clinicId) {
                    const subscription = {
                        customerId: props.clinic.stripeCustomerId,
                        priceId: formData?.priceId,
                        paymentMethodId: formData?.cardId,
                        promotionCode: null,
                        isManufacturerSponsored: !props.requireCard,
                        paymentDate: new Date()
                    }

                    // Set up the Clinic's subscription
                    StripeApi.createClinicSubscription(props.clinic.clinicId, VETCHECK, subscription)
                        .then((results) => {
                            toast.success("Subscription created successfully");
                            // Have to load in the subscriptions because different information is returned here than from the subscription creation
                            if(props.canViewPaymentSettings) {
                                props.getClinicSubscriptions(props.clinic.clinicId)
                            }
                        })
                        .catch((error) => {
                            handleErrorResponse("creating subscription", error);
                        });
                }
                props.onSubmit();
            }
        } else {
            if (isValidClinic() && isValidSubscription()) {
                if (!!props.clinic.clinicId && props.canEditClinicVetCheck) {
                    VetCheckApi.vetCheckRegister(props.clinic.clinicId, formData)
                        .then((res) => {
                            toast.success("Clinic registered for VetCheck");
                            const subscription = {
                                customerId: res.body?.stripeId || formData?.customerId,
                                priceId: formData?.priceId,
                                paymentMethodId: formData?.cardId,
                                promotionCode: null,
                                isManufacturerSponsored: !props.requireCard,
                                paymentDate: new Date()
                            }

                            // Set up the Clinic's subscription
                            StripeApi.createClinicSubscription(props.clinic.clinicId, VETCHECK, subscription)
                                .then((results) => {
                                    toast.success("Subscription created successfully");
                                    // Have to load in the subscriptions because different information is returned here than from the subscription creation
                                    if(props.canViewPaymentSettings) {
                                        props.getClinicSubscriptions(props.clinic.clinicId);
                                    }
                                })
                                .catch((error) => {
                                    handleErrorResponse("creating subscription", error);
                                });

                            props.loadCurrentUser();
                        })
                        .catch((error) => {
                            handleErrorResponse("registering for VetCheck", error);
                        });
                }
                props.onSubmit();
            }
        }
    }

    const handleAddPaymentMethod = async () => {
        setBtnDisabled(true);
        setLoading(true);

        const card = elements.getElement(CardElement);
        const stripeModel = { name: cardName }
        const result = await stripe.createToken(card, stripeModel);

        if (result.error) {
            setStripeError(result.error.message);
        } else {
            setStripeError(null);
            try {
                await StripeApi.createPaymentOption({clinicId: props.clinic.clinicId, tokenId: result.token.id});
                getCards();
                toast.success("Card has been added.");
                setShowAddCard(false);
                setBtnDisabled(false);
            } catch (error) {
                setLoading(false);
                handleErrorResponse("adding payment method", error);
                setBtnDisabled(false);
            }
        }
    }

    const userForm = (
        <form onSubmit={goNext}>
            <div className="text-lg">User Information</div>
            <div>
                <TextBox
                    onChange={handleChange}
                    name="clinicName"
                    value={formData?.clinicName}
                    placeholder="Enter clinic name"
                    label="Clinic name"
                    required
                    hasError={!!includes(clinicErrors, "clinicName")}
                    disabled={!props.requireClinic}
                />
                <TextBox
                    onChange={handleChange}
                    name="email"
                    value={formData?.email}
                    placeholder="Enter email"
                    label="Email"
                    type="email"
                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$"
                    required
                    hasError={!!includes(clinicErrors, "email")}
                    disabled={!props.requireClinic}
                />
                <TextBox
                    onChange={handleChange}
                    name="phoneNumber"
                    value={formData?.phoneNumber}
                    placeholder="Enter phone number"
                    label="Phone number"
                    required
                    hasError={!!includes(clinicErrors, "phoneNumber")}
                    disabled={!props.requireClinic}
                />
                <TextBox
                    onChange={handleChange}
                    name="address1"
                    value={formData?.address1}
                    placeholder="Enter address"
                    label="Address line 1"
                    required
                    hasError={!!includes(clinicErrors, "address1")}
                    disabled={!props.requireClinic}
                />

                <TextBox
                    onChange={handleChange}
                    name="address2"
                    value={formData?.address2}
                    placeholder="Enter address (optional)"
                    label="Address line 2"
                    disabled={!props.requireClinic}
                />
                <div className="flex spaced-content">
                    <div className="flex-2">
                        <TextBox
                            onChange={handleChange}
                            name="city"
                            value={formData?.city}
                            placeholder="Enter city"
                            label="City"
                            hasError={!!includes(clinicErrors, "city")}
                            disabled={!props.requireClinic}
                        />
                    </div>
                    <div className="flex-1">
                        <Dropdown
                            onChange={handleChange}
                            name="state"
                            value={formData?.state}
                            placeholder="Select state"
                            label={stateLabel}
                            options={locationDropdownList}
                            hasError={!!includes(clinicErrors, "state")}
                            disabled={!props.requireClinic}
                        />
                    </div>
                    <div className="flex-1">
                        <TextBox
                            onChange={handleChange}
                            name="postalCode"
                            value={formData?.postalCode}
                            placeholder="Enter postal code"
                            label={zipCodeLabel}
                            hasError={!!includes(clinicErrors, "postalCode")}
                            disabled={!props.requireClinic}
                        />
                    </div>
                </div>
                <Dropdown
                    onChange={handleChange}
                    name="countryCode"
                    value={formData?.countryCode}
                    placeholder="Select country"
                    label="Country or Region"
                    options={countriesDropdownList}
                    hasError={!!includes(clinicErrors, "countryCode")}
                    disabled={!props.requireClinic}
                />
                <div className="margin-top-md flex justify-flex-end">
                    <Button
                        type="vetcheck"
                        large
                        buttonType="submit"
                    >
                        Next <i className="margin-left-x-sm fa fa-angle-right"/>
                    </Button>
                </div>
            </div>
        </form>
    );

    const paymentForm = (
        <form onSubmit={handleSubscribeClicked}>
            {props.requireCard && (
                <>
                    <div className="text-lg margin-top-md">Payment Information</div>
                    <div>
                        <div className="margin-top-sm flex align-bottom spaced-content">
                            <div className="flex-1">
                                {!!cardList.length ? (
                                    <Dropdown
                                        label="Credit Card"
                                        options={CARD_OPTIONS}
                                        name="cardId"
                                        value={formData?.cardId}
                                        placeholder="Select Payment Method"
                                        onChange={handleChange}
                                        hasError={!!includes(subscriptionErrors, "cardId")}
                                    />
                                ) : (
                                    <div className={classnames("text-lg text-mid-gray", {
                                        ["text-danger"]: !!includes(subscriptionErrors, "cardId")
                                    })}>
                                        No Available Credit Cards
                                    </div>
                                )}
                            </div>
                            {!!(!showAddCard && cardList?.length) && (
                                <div className="flex-none">
                                    <Button
                                        onClick={() => setShowAddCard(true)}
                                        type="success"
                                        disabled={btnDisabled}
                                    >
                                        Add a New Card
                                    </Button>
                                </div>
                            )}
                        </div>
                        {(showAddCard || !cardList?.length) && (
                            <div className="border-top">
                                <div className="form-row">
                                    <TextBox
                                        id="name"
                                        label="Name on Card"
                                        name="cardName"
                                        type="text"
                                        value={cardName}
                                        onChange={({value}) => setCardName(value)}
                                        required
                                        pattern="[A-Za-z, ]+"
                                    />
                                </div>
                                <div className="form-row">
                                    <label htmlFor="card-element">
                                        Credit or debit card
                                    </label>
                                    <CardElement
                                        id="card-element"
                                        onChange={handleError}
                                        className={styles.cardEl}
                                    />
                                    <div className="card-errors" role="alert">{stripeError}</div>
                                </div>
                                <div className="flex justify-flex-end spaced-content margin-top-x-sm">
                                    {!!cardList?.length && (
                                        <div>
                                            <Button
                                                onClick={() => setShowAddCard(false)}
                                                type="gray"
                                                small
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    )}
                                    <div>
                                        <Button
                                            type="success"
                                            onClick={handleAddPaymentMethod}
                                            small
                                            disabled={btnDisabled || !cardName?.length}
                                        >
                                            Add
                                        </Button>
                                    </div>
                                </div>
                                <SpinnerTakeover show={loading}/>
                            </div>
                        )}
                    </div>
                </>
            )}
            <div>
                {props.requireCard ? (
                    <div className="margin-top-md">
                        <div className="flex">
                            <div className="text-lg margin-bottom-x-sm flex-1">Select your payment interval</div>
                            {!!includes(subscriptionErrors, "priceId") && (
                                <div className="text-danger">please select a plan</div>
                            )}
                        </div>
                        <div className={styles.products}>
                            {map(productIds, productId => {
                                const activePaymentOptions = filter(props.priceOptions[productId], {isActive: true});
                                let title;
                                switch(productId){
                                    case "prod_LYTcN6pp5AwZpB":
                                    case "prod_LYTjwl8S1bFoGM":
                                        title = "VetCheck + Charts + Social Media";
                                        break;
                                    case "prod_LYTevc7z6v5Fnj":
                                    case "prod_LYTjhDfifW8qWO":
                                        title = "VetCheck + Add-on (Charts or Social Media)"
                                        break;
                                    case "prod_IEswVpbuZs7rwH":
                                    case "prod_IEW6U54mpGq23p":
                                    default:
                                        title = "VetCheck"
                                }
                                return(
                                    <Fragment key={productId}>
                                        <div
                                            className="text-lg text-vetcheck text-center margin-right-sm margin-left-sm"
                                        >
                                            <div className="flex flex-centered full-height">{props.products?.[productId]?.productName || "VetCheck"}</div>
                                        </div>
                                        {map(orderBy(activePaymentOptions, ["recurrence"], ["asc"]), price => (
                                            <div
                                                key={price.priceId}
                                                className={classnames(styles.paymentOption, {
                                                    [styles.selected]: (formData.priceId === price.priceId)
                                                })}
                                                onClick={() => handleChange({name: "priceId", value: price.priceId})}
                                            >
                                                <div>
                                                    <div className="text-lg">
                                                        <span className="text-vetcheck">${formatCurrencyString(price?.unitAmount)}</span>/{price?.recurrence}
                                                    </div>
                                                    {price.priceNickName}
                                                </div>
                                            </div>
                                        ))}
                                    </Fragment>
                                );
                            })}
                        </div>
                    </div>
                ) : (
                    <div className="text-lg">You are signing up for a Manufacturer Sponsored subscription of VetCheck. If you would like to sign up for a paid subscription, please impersonate the Clinic Admin.</div>
                )}
                <div className="margin-top-md flex  flex-centered spaced-content">
                    {props.requireClinic && (
                        <div>
                            <Button
                                type="gray"
                                large
                                icon
                                onClick={() => setPage(USER_FORM)}
                            >
                                <i className="fa fa-angle-left"/> Previous
                            </Button>
                        </div>
                    )}
                    <div className="flex-1">
                        <Button
                            type="vetcheck"
                            buttonType="submit"
                            large
                            superwide
                            disabled={!btnEnabled}
                        >
                            Subscribe
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );

    return (
        <div>
            {props.requireCard ?  (
                <>
                    <div className="text-xlg">Sign Up for a VetCheck Subscription</div>
                    <div>Deliver your health messages better and faster to help manage client expectations, increase compliance and save time.</div>
                </>
            ) : (
                <>
                    <div className="text-xlg">Manufacturer Sponsored VetCheck Subscription</div>
                    <div className="text-success">To sign up for a paid subscription, you must impersonate a clinic admin.</div>
                </>
            )}
            <div className="margin-top-x-sm margin-bottom-md">
                <a href="https://vetcheck.it/automate"
                   className="btn btn-sm btn-full btn-color-vetcheck-hollow"
                   target="_blank"
                >
                    Learn More
                </a>
            </div>
            {page === USER_FORM ? (
                userForm
            ) : page === PAYMENT_FORM ? (
                paymentForm
            ) : null}
        </div>
    );
}

SignupForVetCheck.propTypes = {
    clinic: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    requireClinic: PropTypes.bool,
    requireCard: PropTypes.bool
};

SignupForVetCheck.defaultProps = {
    requireClinic: true,
    requireCard: true
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
            // Permissions
        const canEditClinicVetCheck = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_VETCHECK, userProfile);
        const canEditPaymentSettings = userHasPermission(PermissionTypes.EDIT, UserPermissions.PAYMENT_SETTINGS, userProfile);
        const canViewPaymentSettings = userHasPermission(PermissionTypes.VIEW, UserPermissions.PAYMENT_SETTINGS, userProfile);
        const canViewVetCheckMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);

        return {
            userProfile,
            priceOptions: state.entities.priceOptions,
            products: state.entities.greenlineProducts,
            //  Permissions
            canEditClinicVetCheck,
            canEditPaymentSettings,
            canViewPaymentSettings,
            canViewVetCheckMapping
        }
    },
    (dispatch) => ({
        vetCheckRegister: (clinicId, clinicInfo) => dispatch(VetCheckActions.vetCheckRegister(clinicId, clinicInfo)),
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
        getClinicSubscriptions: (clinicId) => dispatch(StripeActions.getClinicSubscriptions(clinicId)),
        getPriceOptions: (productId) => dispatch(StripeActions.getPriceOptions(productId)),
        getProducts: () => dispatch(StripeActions.getProducts())
    })
)(SignupForVetCheck);
