exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ProviderClinicTable__providers{max-width:30px;min-width:20px}.ProviderClinicTable__providers img{width:100%}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/provider/widgets/ProviderClinicTable.scss"],"names":[],"mappings":"AAAA,gCAAW,eAAe,cAAc,CAAC,oCAAe,UAAU,CAAC","file":"ProviderClinicTable.scss","sourcesContent":[".providers{max-width:30px;min-width:20px}.providers img{width:100%}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"providers": "ProviderClinicTable__providers"
};