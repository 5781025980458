import React from "react";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import Dropdown from "components/common/Dropdown";
import TextBox from "components/common/TextBox";
import { usStatesDropdownList } from "constants/USStates";

export default function ClientAddressFields(props) {
    return (
        <>
            <h3>Address</h3>
            <TextBox
                disabled={props.readOnly}
                hasError={includes(props.errors, "street1")}
                label="Street 1"
                name="street1"
                onChange={props.onChange}
                required
                value={props.initialData?.street1}
            />
            <TextBox
                disabled={props.readOnly}
                hasError={includes(props.errors, "street2")}
                label="Street 2"
                name="street2"
                onChange={props.onChange}
                value={props.initialData?.street2}
            />
            <TextBox
                disabled={props.readOnly}
                hasError={includes(props.errors, "city")}
                label="City"
                name="city"
                onChange={props.onChange}
                required
                value={props.initialData?.city}
            />
            <div className="flex spaced-content">
                <div className="flex-1">
                    {props.useState ? (
                        <Dropdown
                            disabled={props.readOnly}
                            hasError={includes(props.errors, "state")}
                            label="State"
                            name="state"
                            onChange={props.onChange}
                            options={usStatesDropdownList}
                            placeholder="Select a State"
                            required
                            value={props.initialData?.state}
                        />
                    ) : (
                        <Dropdown
                            disabled={props.readOnly}
                            hasError={includes(props.errors, "stateProvince")}
                            label="State"
                            name={"stateProvince"}
                            onChange={props.onChange}
                            options={usStatesDropdownList}
                            placeholder="Select a State"
                            required
                            value={props.initialData?.stateProvince}
                        />
                    )}
                </div>
                <div className="flex-none">
                    {props.useZipCode ? (
                        <TextBox
                            disabled={props.readOnly}
                            hasError={includes(props.errors, "zipCode")}
                            label="Postal Code"
                            name="zipCode"
                            onChange={props.onChange}
                            required
                            value={props.initialData?.zipCode}
                        />
                    ) : (
                        <TextBox
                            disabled={props.readOnly}
                            hasError={includes(props.errors, "postalCode")}
                            label="Postal Code"
                            name="postalCode"
                            onChange={props.onChange}
                            required
                            value={props.initialData?.postalCode}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

ClientAddressFields.defaultProps = {
    errors: [],
    initialData: {},
    readOnly: false,
    useState: false,
    useZipCode: false,
}

ClientAddressFields.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
    initialData: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
    useState: PropTypes.bool,
    useZipCode: PropTypes.bool,
}
