import React, {useState} from "react";
import {connect} from "react-redux";
import classnames from "classnames";
import styles from "./PriceWatchIntro.scss";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import round from "lodash/round";
import * as StripeApi from "api/StripeApi";
import * as StripeActions from "actions/StripeActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import Image from "components/common/Image";
import Modal from "components/common/Modal";
import PaymentInfo from "components/common/PaymentInfo";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {getClinicInfo} from "utils/ClinicData";
import logger from "utils/logger";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import {PRICE_WATCH} from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";

//TODO: Need to replace this image with an image of the complete table once all competitor prices are available.
import PRICE_WATCH_HEADER from "components/common/branding/price_watch.jpg";
import forEach from "lodash/forEach";

function PriceWatchIntro(props) {
    const [formData, setFormData] = useState({});
    const [showGetStarted, setShowGetStarted] = useState(false);
    const [subscriptionErrors, setSubscriptionErrors] = useState([]);

    if(!(props.clinicId && props.clinic)) {
        return <SpinnerTakeover show/>;
    }

    const activePaymentOptions = orderBy(filter(props.priceOptions, "isActive"), ["annualCost"], ["desc"]);
    const mostExpensive = activePaymentOptions?.[0] || {};

    const handleChange = ({name, value}) => {
        setFormData({
            ...formData,
            [name]: value
        })
        // Remove error if the value is changed
        if(!!includes(subscriptionErrors, name)){
            const newErrors = reject(subscriptionErrors, e => {return e === name});
            setSubscriptionErrors(newErrors);
        }
    }

    if (!(props.isAdmin || props.isProvider) && !props.canEditPaymentSettings){
        return (
            <AccessDenied/>
        )
    }

    const handleSubscribeClicked = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(props.isAdmin || props.isProvider) {
            if (confirm("Are you sure you want to sign this clinic up for a manufacturer sponsored subscription?")) {
                handleSubscribe();
            }
        } else {
            handleSubscribe();
        }
    }

    const isValidSubscription = () => {
        const formErrors = [];
        let requiredValues = [];
        if (!(props.isAdmin || props.isProvider)) {
            requiredValues = ["cardId"];
        }

        forEach(requiredValues, value => {
            if(!formData[value]) {
                formErrors.push(value);
            }
        });
        setSubscriptionErrors(formErrors);
        logger.error(formErrors, "ERRORS");
        return !formErrors?.length;
    }

    const handleSubscribe = () => {
        // TODO: Actually create a subscription
        if(isValidSubscription()) {
            if (!!props.clinicId) {
                const subscription = {
                    customerId: props.clinic.stripeCustomerId,
                    // TODO: update the priceId (might need to load by product and select the active price)
                    priceId: "price_1KtXevFMFs4b8egxfAEfem0D",
                    paymentMethodId: formData?.cardId,
                    promotionCode: null,
                    isManufacturerSponsored: (props.isAdmin || props.isProvider),
                    paymentDate: new Date()
                }

                // Set up the Clinic's subscription
                StripeApi.createClinicSubscription(props.clinicId, PRICE_WATCH, subscription)
                    .then((results) => {
                        toast.success("Subscription created successfully");
                        // Have to load in the subscriptions because different information is returned here than from the subscription creation
                        props.getClinicSubscriptions(props.clinicId)
                    })
                    .catch((error) => {
                        handleErrorResponse("creating subscription", error);
                    });
            }
        }
    }

    const paymentForm = (
        <>
            <PaymentInfo
                clinicId={props.clinicId}
                name="cardId"
                value={formData.cardId}
                error={!!includes(subscriptionErrors, "cardId")}
                onChange={handleChange}
            />
            <div>
                <div className="margin-top-md">
                    <div className="flex">
                        TODO: Add some text here about the subscription
                        {/*<div className="text-lg margin-bottom-x-sm flex-1">Select your payment interval</div>*/}
                        {!!includes(subscriptionErrors, "priceId") && (
                            <div className="text-danger">please select a plan</div>
                        )}
                    </div>
                    <div className={classnames(styles.paymentOptions, {
                        [styles.hasError]: !!includes(subscriptionErrors, "priceId")
                    })}>
                        {map(activePaymentOptions, p => {
                            const savings = round((Number(mostExpensive?.annualCost) - Number(p.annualCost)), 2);
                            return(
                                <div key={p.priceId}
                                    className={classnames(styles.paymentOption, {
                                    [styles.selected]: (formData.priceId === p.priceId)
                                })}
                                    onClick={() => handleChange({name: "priceId", value: p.priceId})}
                                >
                                    <div>
                                        <div className="text-vetcheck text-lg">{p.priceNickName}</div>
                                            <div className="text-xlg">
                                                <span className="text-vetcheck">${p?.monthlyCost}</span>
                                                /month
                                            </div>
                                            {!!savings ?(
                                                <div>Save ${savings} a year</div>
                                            ) : (
                                                <div>${p.annualCost} annually</div>
                                            )}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    );

    return (
        <div>
            <div>
                <div className="flex justify-center">
                    <Image
                        src={PRICE_WATCH_HEADER}
                        alt="Price Check Example Image"
                        style={{width: "100%", maxWidth: "2397px", maxHeight: "1186px", height: "90%"}}
                    />
                </div>
                <div className="margin-top-md margin-left-sm">
                    <Button
                        onClick={() => {setShowGetStarted(true)}}
                        large
                        disabled={!props.canEditPaymentSettings}
                        title={props.canEditPaymentSettings ? "Click to get started with Price Watch!" : "User does not have the correct permissions needed to join Price Watch"}
                    >
                        Get Started
                    </Button>
                </div>
                {/* TODO: Replace this copy with real text*/}
                <div className="margin-bottom-md margin-top-md">
                    REPLACE: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                </div>
            </div>
            <Modal
                show={showGetStarted}
                modalTitle="Sign up for Price Check"
                icon="fas fa-2x fa-scanner-gun"
                onClose={() => setShowGetStarted(false)}
                small
            >
                <div>
                    {!(props.isAdmin || props.isProvider) ?  (
                        <>
                            {/*TODO: Signup message*/}
                            <div>CHANGE: Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</div>
                        </>
                    ) : (
                        <>
                            <div className="text-success">To sign up for a paid subscription, you must impersonate a clinic admin.</div>
                        </>
                    )}
                    <form onSubmit={handleSubscribeClicked}>
                        {!(props.isAdmin || props.isProvider) && paymentForm}
                        <div className="margin-top-md flex  flex-centered spaced-content">
                            <div className="flex-1">
                                <Button
                                    buttonType="submit"
                                    large
                                    superwide
                                    disabled={(!( props.isAdmin || props.isProvider) && !formData.cardId)}
                                >
                                    Subscribe
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    );
}

PriceWatchIntro.propTypes = {
    clinicId: PropTypes.number.isRequired,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
            // Permissions
        const canEditPaymentSettings = userHasPermission(PermissionTypes.EDIT, UserPermissions.PAYMENT_SETTINGS, userProfile);

        return {
            userProfile,
            clinic: getClinicInfo(state, ownProps.clinicId),
            //  Permissions
            canEditPaymentSettings
        }
    },
    (dispatch) => ({
        getClinicSubscriptions: (clinicId) => dispatch(StripeActions.getClinicSubscriptions(clinicId))
    })
)(PriceWatchIntro);
