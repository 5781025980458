import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./GamesPuppyBowlCard.scss";
import find from "lodash/find";
import Image from "components/common/Image";
import Loader from "components/common/Loader";
import PuppyBowlClinicScore from "components/games/widgets/PuppyBowlClinicScore";
import { PermissionTypes, userHasPermission, userPrimaryLocationType } from "utils/permissions/rolesPermissions";
import * as LocationTypes from "constants/LocationTypes";
import * as UserPermissions from "constants/UserPermissions";
import { getLast2Snapshots } from "actions/ProviderActions";

const NEXGARD_SMALL_BANNER_AD = require("../../../../../../img/games/nexgard_small_banner_ad.jpg");

function GamesPuppyBowlCard(props) {
    const [gameLoaded, setGameLoaded] = useState(false);
    const { game } = props;
    const params = useParams();
    const nodeId = Number(params.nodeId);


    useEffect(() => {
        if (props.game?.greenlineGameId && props.game?.daysFromStart !== 0) {
            props.getSnapshots(props.game.greenlineGameId, props.game.ownerUserId, props.clinicId, false);
        }
    }, [props.game]);

    const handleDashboardClick = () => {
        if (props.isAdmin) {
            props.history.push(`/admin/clinic/${props.clinicId}/games/${game.greenlineGameId}`);
        } else if(props.isProvider) {
            props.history.push(`/provider/location/${nodeId}/clinic/${props.clinicId}/games/${game.greenlineGameId}`);
        } else {
            props.history.push(`/games/${game.greenlineGameId}`);
        }
    }

    const gameData = useMemo(() => {
        if (props.game?.daysFromStart === 0) {
            // Return kickoff
            return {
                totalPoints: 0,
                touchdowns: 0,
                fieldGoals: 0,
                mvps: 0,
                pointValues: 0,
                kickoff: true,
            }
        }
        if (props.game?.data.Clinics && props.clinicId && !!props.gameSnapshotComparison) {
            if (!props.gameSnapshotComparison.length) {
                // Unlikely, but just in case
                return {
                    totalPoints: 0,
                    touchdowns: 0,
                    fieldGoals: 0,
                    mvps: 0,
                    pointValues: 0,
                    kickoff: false,
                };
            }


            let today;
            let yesterday;
            if(props.gameSnapshotComparison.length === 1) {
                // Handle when it's the first day, all 0s for Yesterday
                yesterday = props.gameSnapshotComparison[0];
                today = find(props.game.data.Clinics, clinic => clinic.ClinicId === props.clinicId);
            } else {
                yesterday = find(props.gameSnapshotComparison[0].data.Clinics, clinic => clinic.ClinicId === props.clinicId);
                today = find(props.gameSnapshotComparison[1].data.Clinics, clinic => clinic.ClinicId === props.clinicId);
            }

            const clinicDetail = find(props.game.details, clinic => clinic.ClinicId === props.clinicId);
            const pointsMultiplier = props.game.data.PointsMultiplier;

            const mvps = today ? today.CurrentPeriod.TotalPuppyBowlMVPs - yesterday.CurrentPeriod.TotalPuppyBowlMVPs : 0;
            const fieldGoals = today ? today.CurrentPeriod.TotalPuppyBowlFieldGoals - yesterday.CurrentPeriod.TotalPuppyBowlFieldGoals : 0;
            const touchdowns = today ? today.CurrentPeriod.TotalPuppyBowlTouchdowns - yesterday.CurrentPeriod.TotalPuppyBowlTouchdowns : 0;
            const totalPoints = today ? today.CurrentPeriod.TotalPuppyBowlPoints || clinicDetail.TotalPuppyBowlPoints || 0 : 0;
            const pointValues = {
                mvp: Math.round(14 * pointsMultiplier),
                touchdown: Math.round(6 * pointsMultiplier),
                fieldGoal: Math.round(3 * pointsMultiplier),
            };
            return {
                totalPoints,
                touchdowns,
                fieldGoals,
                mvps,
                pointValues,
                kickoff: false,
            };
        }
        return null;
    }, [props.game, props.clinicId, props.gameSnapshotComparison]);

    useEffect(() => {
        if (props.game?.details && (props.gameSnapshotComparison || props.game?.daysFromStart === 0) && !!gameData) {
            setGameLoaded(true);
        }
    }, [props.game, props.gameSnapshotComparison, gameData]);

    const gameAnimation = useMemo(() => {
        if (gameLoaded) {
            return (
                <PuppyBowlClinicScore
                    pathToSrcFiles="/plugins/puppy_bowl_points_gained/"
                    data={gameData}
                />
            );
        } else {
            return <Loader />;
        }
    }, [gameLoaded]);

    return (
        <div
            key={`game_${game.greenlineGameId}`}
            className={classnames(styles.root, {
                [styles.clickable]: props.dashboard,
                [styles.historic]: !game.isCurrent,
            })}
            onClick={handleDashboardClick}
        >
            <div id={`game_${props.game.greenlineGameId}`} className={styles.gameContainer}>
                <div className={styles.banner}>
                    <Image
                        src={NEXGARD_SMALL_BANNER_AD}
                        alt="NexGard® advertisement"
                    />
                </div>
                <div className={styles.animation}>
                    {gameAnimation}
                </div>
                <div className={styles.dates}>
                    {game.startDate} - {game.endDate}
                </div>
                <div className={styles.tagLine}>
                    Dispense <em>NexGard®</em> Chews, Win Points.
                </div>
                <div className={styles.viewLeaderboardLink}>
                    View the Leaderboard <i className={"fa-solid fa-chevron-right"} />
                </div>
            </div>
        </div>
    );
}

GamesPuppyBowlCard.propTypes = {
    clinicId: PropTypes.number,
    game: PropTypes.object,
    dashboard: PropTypes.bool,
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const isAdmin = userPrimaryLocationType(userProfile, [LocationTypes.NULL]);
        const isProvider = userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canEditClinicBiGamesDashboard = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            isAdmin,
            isProvider,
            canViewClinicBIGamesDashboard,
            canEditClinicBiGamesDashboard,
            gameSnapshotComparison: state.entities.gameSnapshotComparison[ownProps.game?.greenlineGameId],
        }
    },
    (dispatch) => ({
        getSnapshots: (gameId, userId, clinicId, isDemo) => dispatch(getLast2Snapshots(gameId, userId, clinicId, isDemo)),
    })
);

export default compose(
    withRouter,
    connector
)(GamesPuppyBowlCard);
