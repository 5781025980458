import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {autobind} from "core-decorators";
import classnames from "classnames";
import styles from "./DashboardTileDetails.scss";
import * as ClinicActions from "actions/ClinicActions";
import { getDashboardTileData } from "utils/ClinicData";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import moment from "moment";


class DashboardTileDetails extends React.Component {

    componentDidUpdate(prevProps, prevState, snapshot) {
        if((this.props.expanded && !this.props.tileData) || (this.props.timeRange !== prevProps.timeRange)) {
            this.props.getTileDataByType(this.props.type, this.props.timeRange);
        }
    }

    @autobind
    handleOnClose() {
        if(this.props.onClose) {
            this.props.onClose();
        }
    }

    render() {
        const isExpanded = (this.props.tileData && this.props.expanded);
        return (
            <div className={classnames(styles.root,{
                [styles.expanded]: isExpanded,
            })}>
                <div className={styles.innerContent}>
                    {this.props.tileData ? (
                        <>
                            {/*<h3 className="margin-bottom-sm text-center">{this.props.title}</h3>*/}
                            <div
                                onClick={this.handleOnClose}
                                className={styles.closeBtn}
                            ><i className="far fa-times-circle" />
                            </div>
                            {/*<Chart*/}
                            {/*    config={{*/}
                            {/*        ...this.props.tileData,*/}
                            {/*        legend: {*/}
                            {/*            itemStyle: {*/}
                            {/*                fontSize: '13px',*/}
                            {/*                fontFamily: 'SF Pro Text',*/}
                            {/*                color: 'rgb(49, 125, 61)'*/}
                            {/*            },*/}
                            {/*            symbolRadius: 0,*/}
                            {/*        },*/}
                            {/*        xAxis: {*/}
                            {/*            categories: this.props.tileData.labels,*/}
                            {/*            min: 0,*/}
                            {/*            max: 30,*/}
                            {/*            scrollbar: {*/}
                            {/*                enabled: false*/}
                            {/*            },*/}
                            {/*        },*/}
                            {/*    }}*/}
                            {/*    title={this.props.title}*/}
                            {/*/>*/}
                            <div className="text-right margin-top-sm text-sm text-gray">
                                Last Updated: {moment(this.props.tileData.lastUpdated).format("MMMM DD, YYYY")}
                            </div>
                        </>
                    ) : (
                        <SpinnerTakeover show />
                    )}
                </div>
            </div>
        );
    }
}

DashboardTileDetails.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    expanded: PropTypes.bool,
    onClose: PropTypes.func,
};

export default connect(
    (state, ownProps) => ({
        tileData: getDashboardTileData(state, ownProps.type),
    }),
    (dispatch) => ({
        getTileDataByType: (typeId, timeRange) => dispatch(ClinicActions.getTileDataByType(typeId, timeRange)),
    }),
)(DashboardTileDetails);
