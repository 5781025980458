import * as ApplicationStateActions from "actions/ApplicationStateActions";
import {handleErrorResponse} from "utils/request";
import * as ActionTypes from "constants/ActionTypes";

export function getDefaultErrorHandler(dispatch, humanMessage) {
    return function defaultErrorHandler(error) {
        dispatch(ApplicationStateActions.endWorking());
        handleErrorResponse(humanMessage, error);
        dispatch({
            type: ActionTypes.REQUEST_ERROR,
            error,
        });
    }
}