import React, { useState } from 'react';
import { connect } from "react-redux";
import moment from "moment";
import find from "lodash/find";
import * as ReportApi from "api/ReportApi";
import ClinicBase from "components/layout/ClinicBase";
import DateInput from "components/common/DateInput";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import * as ClinicData from "utils/ClinicData";
import { formatUSD } from "utils/numeric";
import { downloadFile, handleErrorResponse } from "utils/request";
import toast from "utils/toast";

const ClinicWellnessPayoutReport = (props) => {
	const [paymentDate, setPaymentDate] = useState(null);
	const [report, setReport] = useState([]);
	const [loading, setLoading] = useState(false);
	const TODAY = new moment();

	const getReport = (date) => {
		if (!!date) {
			setLoading(true);
			ReportApi.loadWellnessPayoutReport(props.clinicId, date)
				.then((res) => {
					setReport(res.body);
				})
				.catch((error) => {
					handleErrorResponse('getting clinic wellness report', error);
				})
				.finally(() => {
					setLoading(false);
				})
		}
	};

	const handlePaymentDateChanged = ({ value }) => {
		setPaymentDate(value);
		getReport(value);
	}

	const handleDownloadExcel = async () => {
		if (paymentDate) {
			try {
				toast.success("Generating Export...");
				const res = await ReportApi.loadWellnessPayoutReportExport(props.clinicId, paymentDate);
				downloadFile(res, "xlsx");
			} catch (e) {
				handleErrorResponse("downloading payout report", e);
			}
		}
    };

	const COLUMNS = [{
		key: "clientName",
		name: "Client Name",
		rowTotal: row => {
			const first = find(report, { clientId: row.clientId });
			console.log(first, "FIRST");
			if (first.petName === row.petName) {
				return 1;
			} else {
				return 0;
			}
		},
		selector: "clientName",
		sortable: true,
		total: true,
		totalClasses: "text-center",
	}, {
		key: "petName",
		name: "Patient Name",
		rowTotal: _ => 1,
		selector: "petName",
		sortable: true,
		total: true,
		totalClasses: "text-center",
    }, {
		key: "planName",
		name: "Plan Name",
		selector: "planName",
		sortable: true,
    }, {
		format: row => (<div className="text-center">{!!row.grossTotal ? formatUSD(row.grossTotal) : "-"}</div>),
		key: "grossTotal",
		name: (<div className="text-center">Gross Total</div>),
		selector: "grossTotal",
		sortable: true,
		total: true,
		totalClasses: "text-center",
		type: "currency"
    }, {
		format: row => (<div className="text-center">{!!row.greenlineFee ? formatUSD(row.greenlineFee) : "-"}</div>),
		key: "greenlineFee",
		name: (<div className="text-center">Greenline Fee</div>),
		selector: "greenlineFee",
		sortable: true,
		total: true,
		totalClasses: "text-center",
		type: "currency"
    }, {
		format: row => (<div className="text-center">{!!row.netTotal ? formatUSD(row.netTotal) : "-"}</div>),
		key: "netTotal",
		name: (<div className="text-center">Net Total</div>),
		selector: "netTotal",
		sortable: true,
		total: true,
		totalClasses: "text-center",
		type: "currency"
    }];

	return (
		<ClinicBase
            clinicId={props.clinicId}
            docTitle="Wellness Payout Report"
            pageTitle="Wellness Payout Report"
        >
			<div className="full-width">
				<div className="flex flex-centered">
					<div>
						<DateInput
							aligned
			                label="Payment Date"
			                name="paymentDate"
			                placeholderText="Select payment date"
			                value={paymentDate}
			                onChange={handlePaymentDateChanged}
			                required
			                hasError={!paymentDate}
							minDate="01/01/2010"
							maxDate={TODAY}
							showDropdowns
			            />
					</div>
				</div>
				{paymentDate ? (
					<SortableDataTable
		                columns={COLUMNS}
		                rawData={report}
		                striped
		                green
						showTotals
		                noResultsText="No data for the selected date"
		                downloadExcel={handleDownloadExcel}
		            />
				) : (
					<div className="flex justify-center">
	                    <h2 className="margin-top-lg" style={{ color: "#aaa" }}>Please select a payment date to run the report</h2>
                    </div>
				)}
			</div>
			<SpinnerTakeover show={loading} />
		</ClinicBase>
	)
}

export default connect(
	(state, ownProps) => {
		const adminClinicId = Number(ownProps.match.params.clinicId);
		const clinicInfo = ClinicData.getClinicInfo(state, adminClinicId);
		const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;

		return {
			clinicId
		}
	}
)(ClinicWellnessPayoutReport);