import React, { useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { connect } from "react-redux";
import includes from "lodash/includes";
import * as ClinicActions from "actions/ClinicActions";
import ProviderBase from "components/layout/ProviderBase";
import HistoricGamesList from "components/games/pages/HistoricGamesList";
import { getClinicGames, getClinicInfo } from "utils/ClinicData";
import * as ProviderLinks from "utils/ProviderLinks";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { CLINIC_TAGS, PRODUCT_TAGS } from "constants/AdminControls";
import * as UserPermissions from "constants/UserPermissions";

function ProviderClinicHistoricGames(props) {
    const history = useHistory();
    const params = useParams();
    const { pathname } = useLocation();
    const nodeId = Number(params.nodeId);
    const providerId = Number(params.providerId);
    const clinicId = Number(params.clinicId);
    const isAdmin = !!includes(pathname, "/admin");

    useEffect(() => {
        if (props.canViewClinicBIGamesDashboard) {
            props.getClinicGreenlineGames(props.clinicId);
        }
    }, [props.clinicId]);

    const goToCurrentGames = () => {
        history.push(ProviderLinks.getLinkLocation("clinic-games", {
            providerId,
            nodeId,
            isAdmin,
            clinicId
        }));
    };

    return (
        <ProviderBase
            pageTitle="Greenline Games™"
            clinicId={clinicId}
            adminControls={CLINIC_TAGS}
            overflowHidden
            location={props.location}
            providerId={providerId}
            nodeId={nodeId}
            controls={PRODUCT_TAGS}
        >
            <HistoricGamesList
                games={props.games}
                onClickCurrent={goToCurrentGames}
            />
        </ProviderBase>
    );
}

export default connect (
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            clinicId,
            canViewClinicBIGamesDashboard,
            games: getClinicGames(state),
        }
    },
    (dispatch) => ({
        getClinicGreenlineGames: (clinicId) => dispatch(ClinicActions.getGreenlineGames(clinicId)),
    }),
)(ProviderClinicHistoricGames);
