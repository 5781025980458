import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ceil from "lodash/ceil";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as ClinicApi from "api/ClinicApi";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

export function ChooseHospital(props) {
    const [assignableClinics, setAssignableClinics] = useState([]);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState("");
    const [offset, setOffset] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const limit = 10;
    const page = (offset / limit) + 1;
    const canGoNext = totalPages > (page);
    const canGoPrevious = offset > 0;

    const searchValues = {
        search: search,
        offset: offset,
        limit: limit,
        orderBy: "clinicName",
        orderDir: "desc",
        filters: [{
            id: "GatewayStatus",
            equality: "==",
            level1Values: [{
                value: "Unknown"
            }]
        }, {
            id: "Active",
            equality: "==",
            level1Values: [{
                value: "Active"
            }]
        }]

    };

    const handleNext = () => {
        const newOffset = (page) * limit;
        setOffset(newOffset);
    }

    const handlePrev = () => {
        const newOffset = (page - 2) * limit;
        setOffset(newOffset);
    }


    const loadAssignableClinics = () => {
        if(props.canViewClinicManagement) {
            setLoading(true);

            ClinicApi.searchClinics(searchValues)
                .then((res) => {
                    setAssignableClinics(res.body.data);
                    setTotalPages(ceil(res.body.totalRecordCount / res.body.limit));
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("loading clinics", error);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        loadAssignableClinics();
    }, [search, offset]);

    const handleChangeSearch = ({value}) => {
        setSearch(value);
        setOffset(0);
    };

    const handleSelectClinic = (clinic) => {
        if(props.canAssignHospital) {
            props.onSelect({id: clinic.clinicId, name: clinic.clinicName});
        }
    }

    if(!props.canAssignHospital) {
        return <AccessDenied/>;
    }

    return (
        <div className={"full-width margin-top-md"}>
            <div className="flex">
                <div className="flex-1"/>
                <div className="flex-1 margin-bottom-x-sm">
                    <TextBox
                        data-testid="choose-hospital-search-box"
                        // label="Search"
                        name="search"
                        value={search}
                        placeholder="Search Clinics"
                        onChange={handleChangeSearch}
                        disabled={!props.canViewClinicManagement}
                    />
                </div>
            </div>
            <div>
                <table className="table full-width striped-green">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>City</th>
                            <th>State</th>
                        </tr>
                    </thead>
                    <tbody>
                        {map(orderBy(assignableClinics, "clinicName"), (clinic) => (
                            <tr key={clinic.clinicId} onClick={() => handleSelectClinic(clinic)}>
                                <td>{clinic.clinicId}</td>
                                <td>{clinic.clinicName}</td>
                                <td>{clinic.city}</td>
                                <td>{clinic.state}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {(totalPages > 1) && (
                    <div className="flex full-width flex-centered margin-top-x-sm">
                        <div className="flex-none">
                            <Button
                                disabled={!canGoPrevious}
                                onClick={handlePrev}
                            >
                                <i className="fa fa-chevron-left margin-right-sm" /> Previous
                            </Button>
                        </div>

                        <div className="flex-1 text-center">
                            Page {page} of {totalPages}
                        </div>
                        <div className="flex-none">
                            <Button
                                disabled={!canGoNext}
                                onClick={handleNext}
                            >
                                Next <i className="fa fa-chevron-right margin-left-sm" />
                            </Button>
                        </div>
                    </div>
                )}
            </div>
            <SpinnerTakeover show={loading}/>
        </div>
    );
}

ChooseHospital.propTypes = {
    onSelect: PropTypes.func.isRequired, // Callback which receives current form data
    canViewClinicManagement: PropTypes.bool,
    canAssignHospital: PropTypes.bool,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canAssignHospital = userHasPermission(PermissionTypes.ASSIGN_HOSPITAL, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            canAssignHospital
        };
    },
)(ChooseHospital);
