import React from "react";
import PropTypes from "prop-types";

export default function AccessDenied(props) {
    return (
        <div
            data-testid="access_denied_component"
            className="full-height flex flex-column flex-centered text-center text-lg margin-bottom-md"
        >
            <div>{props.customMessage ? props.customMessage : "You do not have permission to access this information"}</div>
            <div className="text-sm">{props.customSubMessage && props.customSubMessage}</div>
        </div>
    );
}

AccessDenied.propTypes = {
    customMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    customSubMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
