exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PuppyDancing__cls7{fill:#845521}.PuppyDancing__cls7,.PuppyDancing__cls8,.PuppyDancing__cls9,.PuppyDancing__cls10,.PuppyDancing__cls11,.PuppyDancing__cls12,.PuppyDancing__cls13,.PuppyDancing__cls14,.PuppyDancing__cls15,.PuppyDancing__cls16,.PuppyDancing__cls17,.PuppyDancing__cls18,.PuppyDancing__cls19,.PuppyDancing__cls20{strokewidth:0}.PuppyDancing__cls8{fill:#232021}.PuppyDancing__cls9{fill:#141313}.PuppyDancing__cls10{fill:#b1611d}.PuppyDancing__cls11{fill:#c5bfbe}.PuppyDancing__cls12{fill:#d6d4d4}.PuppyDancing__cls13{fill:#dd9e29}.PuppyDancing__cls14{fill:#98132a}.PuppyDancing__cls15{fill:#fff}.PuppyDancing__cls16{fill:#ffb830}.PuppyDancing__cls17{fill:#003f63}.PuppyDancing__cls18{fill:#0070b5}.PuppyDancing__cls19{fill:#006daf}.PuppyDancing__cls20{fill:#67441e}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/PuppyBowl2/PuppyDancing.scss"],"names":[],"mappings":"AAAA,oBAAM,YAAY,CAAC,mSAA+F,aAAa,CAAC,oBAAM,YAAY,CAAC,oBAAM,YAAY,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC,qBAAO,SAAS,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC","file":"PuppyDancing.scss","sourcesContent":[".cls7{fill:#845521}.cls7,.cls8,.cls9,.cls10,.cls11,.cls12,.cls13,.cls14,.cls15,.cls16,.cls17,.cls18,.cls19,.cls20{strokewidth:0}.cls8{fill:#232021}.cls9{fill:#141313}.cls10{fill:#b1611d}.cls11{fill:#c5bfbe}.cls12{fill:#d6d4d4}.cls13{fill:#dd9e29}.cls14{fill:#98132a}.cls15{fill:#fff}.cls16{fill:#ffb830}.cls17{fill:#003f63}.cls18{fill:#0070b5}.cls19{fill:#006daf}.cls20{fill:#67441e}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cls7": "PuppyDancing__cls7",
	"cls8": "PuppyDancing__cls8",
	"cls9": "PuppyDancing__cls9",
	"cls10": "PuppyDancing__cls10",
	"cls11": "PuppyDancing__cls11",
	"cls12": "PuppyDancing__cls12",
	"cls13": "PuppyDancing__cls13",
	"cls14": "PuppyDancing__cls14",
	"cls15": "PuppyDancing__cls15",
	"cls16": "PuppyDancing__cls16",
	"cls17": "PuppyDancing__cls17",
	"cls18": "PuppyDancing__cls18",
	"cls19": "PuppyDancing__cls19",
	"cls20": "PuppyDancing__cls20"
};