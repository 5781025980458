import React, {useEffect, useState} from "react";
import { compose } from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import * as UserActions from "actions/UserActions";

function WellnessCheck(props) {
    const [refreshInterval, setRefreshInterval] = useState(null);

    const handleWindowFocus = (evt) => {
        props.setWindowVisibility(true);
    }

    const handleWindowBlur = (evt) => {
        props.setWindowVisibility(false);
    }

    useEffect(() => {
        window.addEventListener("focus", handleWindowFocus);
        window.addEventListener("blur", handleWindowBlur);

        return () => {
            window.removeEventListener("focus", handleWindowFocus);
            window.removeEventListener("blur", handleWindowBlur);
        }
    }, []);

    useEffect(() => {
        if (refreshInterval) {
            clearInterval(refreshInterval);
        }

        // load the healthcheck when props.windowIsActive becomes true
        handleRefresh();

        // 1 minute refresh
        const refreshTime = 60 * 60 * 10;
        const refreshIntervalId = setInterval(handleRefresh, refreshTime);
        setRefreshInterval(refreshIntervalId);

        return () => {
            clearInterval(refreshIntervalId);
        }
    }, [props.windowIsActive]);

    const handleRefresh = () => {
        if (props.windowIsActive) {
            props.healthCheck();
        }
    };

    if (!props.apiUpAndRunning) {
        if (props.location.pathname !== "/maintenance/") {
            // Show the user the maintenance screen
            props.history.push("/maintenance/");
        }
    } else if(props.location.pathname === "/maintenance/") {
        // Return to the dashboard once the site is back online
        props.history.push("/");
    }


    return (
        <>{props.children}</>
    );
}

const connector = connect(
    (state) => ({
        windowIsActive: state.applicationState.windowIsActive,
        apiUpAndRunning: state.applicationState.apiUpAndRunning,
    }),
    (dispatch) => ({
        healthCheck: () => dispatch(UserActions.healthCheck()),
        setWindowVisibility: (inFocus) => dispatch(UserActions.setWindowVisibility(inFocus)),
    })
)

export default compose(
    withRouter,
    connector
)(WellnessCheck);
