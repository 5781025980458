import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as CouponsApi from "api/CouponsApi";
import * as CouponActions from "actions/CouponActions";
import Button from "components/common/Button";
import Tooltip from "components/common/Tooltip";
import toast from "utils/toast";

function ShowHideButton(props) {
    const [loading, setLoading] = useState(false);

    const handleMarkHidden = (e) => {
		console.log("CLICKED");
        e.stopPropagation();
        e.preventDefault();
		setLoading(true);
		CouponsApi.markIsHidden(props.strayCoupon.invoiceId, !props.strayCoupon.isHiddenFromClinic)
            .then(res => {
				props.markedHidden(props.strayCoupon.invoiceId, !props.strayCoupon.isHiddenFromClinic);
                toast.success(`Invoice Marked as ${props.strayCoupon.isHiddenFromClinic ? "Visible to" : "Hidden from"}  Clinic`);
            })
            .catch((error) => {
				toast.error(`We're sorry! There was an error marking stray coupon as ${props.strayCoupon.isHiddenFromClinic ? "visible to" : "hidden from"} clinic.`);
            })
			.finally(() => {
				setLoading(false);
			})
    }

	if (loading) {
		return <i className="fa-duotone fa-2x fa-spinner-third fa-spin text-dark-green" />;
	}

    return (
        <Tooltip
            position="left"
            tip={`Mark as ${props.strayCoupon.isHiddenFromClinic ? "visible to" : "hidden from"} clinics`}
        >
            <span>
		        <Button
		            onClick={(e) => handleMarkHidden(e)}
		            iconOnly
		            type='primary'
		            disabled={loading}
		        >
		            {props.strayCoupon.isHiddenFromClinic ? (
	                    <i className="fa fa-eye-slash" />
		            ) : (
	                    <i className="fa fa-eye" />
		            )}
		        </Button>
            </span>
        </Tooltip>
    );
}

ShowHideButton.defaultProps = {
};

ShowHideButton.propTypes = {
    strayCoupon: PropTypes.object,
};

export default connect(
    null,
    (dispatch) => ({
        markedHidden: (invoiceId, isHidden) => dispatch(CouponActions.markedHidden(invoiceId, isHidden)),
    })
)(ShowHideButton);
