import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import words from "lodash/words";
import * as styles from "components/admin/widgets/MappingTable.scss";
import Button from "components/common/Button";
import DropdownSearch from "components/common/DropdownSearch";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import * as MappingActions from "actions/MappingActions";
import {PRODUCT_TYPES} from "constants/ProductTypes";
import toast from "utils/toast";
import {CLIENT_FORM, HANDOUT} from "constants/VetCheckContent";

function MappingPimsDropdownSearch(props) {
    const hasPims = !!props.pimsOptions.length;

    const getFirstWord = (data) => {
        const wordArray = words(data);
        if(wordArray.length) {
            return wordArray[0];
        }
        return null;
    }

    const handleDeleteMapping = () => {
            props.handleDeleting();
            if (props.typeId === PRODUCT_TYPES.PHARMACY) {
                if (props.canEditProductMapping) {
                    props.deletePharmacyMapping(props.clinicProductId, props.clinicId, props.productId);
                }
            } else if (props.typeId === PRODUCT_TYPES.FOOD) {
                if (props.canEditProductMapping) {
                    props.deleteFoodMapping(props.clinicProductId, props.clinicId, props.productId);
                }
            } else if (props.typeId === PRODUCT_TYPES.VACCINES) {
                if (props.canEditVaccineMapping) {
                    props.deleteVaccineMapping(props.mappingId, props.clinicId, props.productId);
                }
            } else if (props.typeId === PRODUCT_TYPES.SERVICES) {
                if (props.canEditExamServiceMapping) {
                    props.deleteExamServiceMapping(props.mappingId, props.clinicId, props.productId);
                }
            } else if (props.typeId === PRODUCT_TYPES.VETCHECK) {
                if (props.canEditVetCheckMapping && props.mappingId && props.contentType) {
                    props.deleteVetCheckMapping(props.mappingId, props.contentType, props.clinicId, props.productId);
                }
            } else if (props.typeId === PRODUCT_TYPES.WELLNESS) {
                if (props.canEditWellnessMapping) {
                    props.deleteWellnessMapping(props.mappingId, props.clinicId, props.productId, props.clinicPricingScheduleId);
                }
            } else {
                toast.error("There is not 'typeId' which determines the API call to use. Thus, this mapping cannot be deleted.");
            }
    }

    const handleEnterPressed = () => {
        //TODO: Need to make sure that it only deletes when we want it to.
        if(!!props.clinicProductId) {
            handleDeleteMapping();
        }
    }

    return (
        <div className={styles.myProduct}>
            <DropdownSearch
                onEnter={handleEnterPressed}
                options={props.pimsOptions}
                disabledOptions={props.disabledPimsOptions}
                highlightedOptions={props.boldPimsOptions}
                value={props.clinicProductId}
                name="clinicProductId"
                onChange={props.handleChange}
                // defaultSearch={props.clinicProductId ? getFirstWord(props.pims[props.clinicProductId].name) : ""}
                disabled={!hasPims || props.disabled}
                placeholder={!hasPims ? "No PIMS Products Available" : null}
            />
            {(!props.disableDelete && props.clinicProductId) && (
                <Button
                    className={styles.delete}
                    onClick={handleDeleteMapping}
                    text
                    type="danger"
                    disabled={!hasPims || props.disabled}
                >
                    <i className="fa fa-times"/>
                </Button>
            )}
        </div>
    )
}

MappingPimsDropdownSearch.propTypes = {
    pimsOptions: PropTypes.array.isRequired,
    disabledPimsOptions: PropTypes.array.isRequired,
    boldPimsOptions: PropTypes.array.isRequired,
    clinicId: PropTypes.number.isRequired,
    productId: PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleDeleting: PropTypes.func.isRequired,
    clinicProductId: PropTypes.number,
    mappingId: PropTypes.number,
    contentType: PropTypes.oneOf([HANDOUT, CLIENT_FORM]),
    disabled: PropTypes.bool,
    disableDelete: PropTypes.bool,

    //Only for Wellness Mapping Page
    clinicPricingScheduleId: PropTypes.number,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;

        //Permissions
        const canEditProductMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.PRODUCT_MAPPING, userProfile);
        const canEditVaccineMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.VACCINE_MAPPING, userProfile);
        const canEditExamServiceMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.EXAM_SERVICE_MAPPING, userProfile);
        const canEditVetCheckMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.VETCHECK_MAPPING, userProfile);
        const canEditWellnessMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.WELLNESS_MAPPING, userProfile);
        return {
            canEditProductMapping,
            canEditVaccineMapping,
            canEditExamServiceMapping,
            canEditVetCheckMapping,
            canEditWellnessMapping
        }
    }, (dispatch) => ({
        // Pharmacy Mapping
        deletePharmacyMapping: (clinicProductId, clinicId, productId) => dispatch(MappingActions.deletePharmacyMapping(clinicProductId, clinicId, productId)),
        // Food Mapping
        deleteFoodMapping: (clinicProductId, clinicId, productId) => dispatch(MappingActions.deleteFoodMapping(clinicProductId, clinicId, productId)),
        // Vaccine Mapping
        deleteVaccineMapping: (clinicProductMappingId, clinicId, vaccineId) => dispatch(MappingActions.deleteVaccineMapping(clinicProductMappingId, clinicId, vaccineId)),
        // Exam Service Mapping
        deleteExamServiceMapping: (mappingId, clinicId, serviceId) => dispatch(MappingActions.deleteExamServiceMapping(mappingId, clinicId, serviceId)),
        // VetCheck Mapping
        deleteVetCheckMapping: (mappingId, contentType, clinicId, clinicHandoutId) => dispatch(MappingActions.deleteVetCheckMapping(mappingId,contentType, clinicId, clinicHandoutId)),
        // Wellness Mapping
        deleteWellnessMapping: (mappingInstanceId, clinicId, serviceTypeId, clinicPricingScheduleId) => dispatch(MappingActions.deleteWellnessMapping(mappingInstanceId, clinicId, serviceTypeId, clinicPricingScheduleId)),
    }),
)(MappingPimsDropdownSearch);
