import React, { useEffect } from "react";
import PropTypes from "prop-types";

function PuppyPoolPartyClinicScore(props) {

    const loadHtml = () => ({
        __html: `
            <iframe
                id="puppy-pool-party-points-gained-iframe"
                src="${props.pathToSrcFiles}index.html"
                width="100%"
                height="100%"
                style="border-width: 0; padding: 0;"
            />
        `
    });

    const waitForIframeHtmlToLoad = () => {
        const intervalId = setInterval(() => {
            if (!!window.PuppyPoolPartyPointsGained) {
                // Js has loaded
                new window.PuppyPoolPartyPointsGained(props.data);
                clearInterval(intervalId);
            }
        }, 200);
    };

    useEffect(() => {
        waitForIframeHtmlToLoad();
    }, []);

    return (
        <div dangerouslySetInnerHTML={loadHtml()} />
    );
}

PuppyPoolPartyClinicScore.propTypes = {
    data: PropTypes.shape({
        totalDoses: PropTypes.number,
        newDoses: PropTypes.number,
        bonus: PropTypes.number,
    }),
    pathToSrcFiles: PropTypes.string,
};

PuppyPoolPartyClinicScore.defaultProps = {
    pathToSrcFiles: "puppy_pool_party_leaderboard/",
};

export default PuppyPoolPartyClinicScore;
