import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import find from "lodash/find";
import keys from "lodash/keys";
import orderBy from "lodash/orderBy";
import { loadClinicProgramDashboard } from "api/ProgramApi";
import * as NotificationActions from "actions/NotificationsActions";
import ProgramsList from "components/programs/AvailableProgramsDashboardWidget/ProgramsList";
import ProgramOptInDetailsModal from "components/clinic/elements/ProgramOptInDetailsModal";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { getClinicDetails } from "utils/ClinicData";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function AvailableProgramsDashboardWidget(props) {
    const {
        basePath = null,
        canEditProgramEnrollmentClinic = false,
        canViewProgramEnrollmentClinic = false,
        clinicDetails,
        loadNotifications,
        notifications,
        user,
    } = props;
    const [selectedProgram, setSelectedProgram] = useState(null);
    const [loading, setLoading] = useState(false);
    const [clinicProgramsDashboard, setClinicProgramsDashboard] = useState([]);
    const params = useParams();
    const adminClinicId = params.clinicId ? Number(params.clinicId) : null;
    const clinic = getClinicDetails(clinicDetails, user, adminClinicId);
    const clinicId = clinic ? clinic.clinicId : undefined;

    const fetchData = async () => {
        setLoading(true);
        try {
            const res = await loadClinicProgramDashboard(clinicId);
            setClinicProgramsDashboard(res.body);
        } catch (e) {
            handleErrorResponse("loading clinic program dashboard", e);
        }
        setLoading(false);
    }

    useEffect(() => {
        // We need to reload this when a notification is updated so that we make sure we have the right list of programs.
        //      Some of the notifications will accept a program automatically.
        if (canViewProgramEnrollmentClinic && clinicId) {
            fetchData();
        }
    }, [clinicId, notifications]);

    if (!canViewProgramEnrollmentClinic || !keys(clinicProgramsDashboard)?.length) {
        return null;
    }

    const handleAfterSave = () => {
        fetchData();
        // There are potentially notifications that need to be removed if a clinic enrolls in programs directly.
        // This will refresh the notifications. That way, we know what is relevant.
        loadNotifications(clinicId);
    }

    const handleProgramClick = (programId) => {
        if (canEditProgramEnrollmentClinic) {
            const program = find(clinicProgramsDashboard.programs, { programId: programId });
            if (program) {
                setSelectedProgram({
                    ...program,
                    id: programId,
                    parentProgram: null,
                });
            }
        }
    };

    const handleProgramServiceClick = (programServiceId) => {
        if (canEditProgramEnrollmentClinic) {
            const programService = find(clinicProgramsDashboard.programServices, { programServiceId: programServiceId });
            if (programService) {
                setSelectedProgram({
                    ...programService,
                    id: programServiceId,
                    parentProgram: programService.programId,
                });
            }
        }
    };

    return (
        <>
            <div className="sectionHeader">
                <h3 className="flex-1">Available Programs</h3>
                {!!((basePath !== null) && (clinicProgramsDashboard.programs?.length || clinicProgramsDashboard.programServices?.length)) && (
                    <Link to={`${basePath}/programs`} className="text-primary flex-1 text-right">
                        View all <i className="fas fa-chevron-right" />
                    </Link>
                )}
            </div>
            <ProgramsList
                programClickHandler={handleProgramClick}
                programServiceClickHandler={handleProgramServiceClick}
                programs={orderBy(clinicProgramsDashboard.programs, "displaySortOrder")}
                programServices={orderBy(clinicProgramsDashboard.programServices, "displaySortOrder")}
            />
            <ProgramOptInDetailsModal
                show={!!selectedProgram}
                onAfterSave={handleAfterSave}
                onClose={() => setSelectedProgram(null)}
                program={selectedProgram}
                programId={selectedProgram?.id}
                clinicId={clinicId}
                parentProgram={selectedProgram?.parentProgram}
            />
            <SpinnerTakeover show={loading} />
        </>
    )
}

AvailableProgramsDashboardWidget.propTypes = {
    basePath: PropTypes.string.isRequired,
    canEditProgramEnrollmentClinic: PropTypes.bool,
    canViewProgramEnrollmentClinic: PropTypes.bool,
    clinicDetails: PropTypes.object,
    loadNotifications: PropTypes.func,
    notifications: PropTypes.object,
    user: PropTypes.object,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;

        //Permissions
        const canViewProgramEnrollmentClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);
        const canEditProgramEnrollmentClinic = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROGRAM_ENROLLMENT_CLINIC, userProfile);
        return {
            clinicDetails: state.entities.clinicDetails || {},
            notifications: state.entities.notifications,
            user: state.user || {},
            canViewProgramEnrollmentClinic,
            canEditProgramEnrollmentClinic,
        }
    },
    (dispatch) => ({
        loadNotifications: (clinicId) => dispatch(NotificationActions.loadNotifications(clinicId)),
    })
)(AvailableProgramsDashboardWidget);