import { filter } from "lodash";

export default function getClinicsInMapBounds(bounds, clinics) {
    if (!bounds) {
        return clinics;
    }

    if (!clinics || clinics.length === 0) {
        return [];
    }

    // See Map.jsx
    const swLat = bounds.southWest.lat;
    const neLat = bounds.northEast.lat;
    const swLng = bounds.southWest.lng;
    const neLng = bounds.northEast.lng;

    return filter(clinics, c => {
        // Fastest way to test point in rect, and we have thousands of clinics to test
        return !(c.lat < swLat || c.lat > neLat || c.lng < swLng || c.lng > neLng);
    });
}