import concat from "lodash/concat";
import difference from "lodash/difference";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { GREENLINE_WELLNESS, MERCK } from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";
// Tour Sections
// Tour Dashboard
export const TOUR_DASHBOARD = "TOUR_DASHBOARD"; // Use for viewing just the dashboard
export const TOUR_NOTIFICATION_TRANSITION = "TOUR_NOTIFICATION_TRANSITION";

// Tour Wellness Dashboard
export const TOUR_WELLNESS_DASHBOARD = "TOUR_WELLNESS_DASHBOARD";
export const TOUR_PLAN_ENROLLMENT_TRANSITION = "TOUR_PLAN_ENROLLMENT_TRANSITION";

// Tour Notifications
export const TOUR_NOTIFICATIONS_PART_1 = "TOUR_NOTIFICATIONS_PART_1"; // Use for viewing just the Notifications found on the dashboard
export const TOUR_NOTIFICATIONS_PART_2 = "TOUR_NOTIFICATIONS_PART_2";
export const TOUR_COUPON_PROCESSING_TRANSITION = "TOUR_COUPON_PROCESSING_TRANSITION";

// Tour Plan Enrollment
export const TOUR_PLAN_ENROLLMENT_PART_1 = "TOUR_PLAN_ENROLLMENT_PART_1";
export const TOUR_PLAN_ENROLLMENT_PART_2 = "TOUR_PLAN_ENROLLMENT_PART_2";
export const TOUR_WELLNESS_AUDIT_TRANSITION = "TOUR_WELLNESS_AUDIT_TRANSITION";

// Tour Coupons
//Coupon Processing
export const TOUR_PENDING_COUPONS_PART_1 = "TOUR_PENDING_COUPONS_PART_1"; // Change to Coupons Page
export const TOUR_PENDING_COUPONS_PART_2 = "TOUR_PENDING_COUPONS_PART_2"; // Overview of the process coupons page
export const TOUR_EDIT_COUPON_MODAL = "TOUR_EDIT_COUPON_MODAL"; // Edit Practice Coupon
export const TOUR_PENDING_COUPONS_PART_3 = "TOUR_PENDING_COUPONS_PART_3"; // Approve and Decline Practice Coupons
export const TOUR_COUPON_HISTORY_TRANSITION = "TOUR_COUPON_HISTORY_TRANSITION";
//Coupon History
export const TOUR_COUPON_HISTORY_PART_1 = "TOUR_COUPON_HISTORY_PART_1";
export const TOUR_COUPON_HISTORY_PART_2 = "TOUR_COUPON_HISTORY_PART_2";
export const TOUR_COUPON_LIBRARY_TRANSITION = "TOUR_COUPON_LIBRARY_TRANSITION";
//Coupon Library
export const TOUR_COUPON_LIBRARY_PART_1 = "TOUR_COUPON_LIBRARY_PART_1";
export const TOUR_COUPON_LIBRARY_PART_2 = "TOUR_COUPON_LIBRARY_PART_2";
export const TOUR_COUPON_LIBRARY_PART_3 = "TOUR_COUPON_LIBRARY_PART_3";
// transition to games or to program opt-in
export const TOUR_GAMES_TRANSITION = "TOUR_GAMES_TRANSITION";
export const TOUR_PROGRAM_OPT_IN_TRANSITION = "TOUR_PROGRAM_OPT_IN_TRANSITION";

// Tour Wellness
// Tour Wellness Audit
export const TOUR_WELLNESS_AUDIT_PART_1 = "TOUR_WELLNESS_AUDIT_PART_1";
export const TOUR_WELLNESS_AUDIT_PART_2 = "TOUR_WELLNESS_AUDIT_PART_2";
export const TOUR_WELLNESS_HISTORY_TRANSITION = "TOUR_WELLNESS_HISTORY_TRANSITION";
// Tour Wellness History
export const TOUR_WELLNESS_HISTORY_PART_1 = "TOUR_WELLNESS_HISTORY_PART_1";
export const TOUR_WELLNESS_HISTORY_PART_2 = "TOUR_WELLNESS_HISTORY_PART_2";
export const TOUR_PET_PLAN_TRACKING_TRANSITION = "TOUR_PET_PLAN_TRACKING_TRANSITION";
// Tour Wellness Pet Plan Tracking
export const TOUR_PET_PLAN_TRACKING_PART_1 = "TOUR_PET_PLAN_TRACKING_PART_1";
export const TOUR_PET_PLAN_TRACKING_PART_2 = "TOUR_PET_PLAN_TRACKING_PART_2";
export const TOUR_REPORTS_TRANSITION = "TOUR_REPORTS_TRANSITION";

// Tour Games
export const TOUR_GAMES_PART_1 = "TOUR_GAMES_PART_1"; // Change to Dashboard to start the games tour
export const TOUR_GAMES_PART_2 = "TOUR_GAMES_PART_2"; // Dashboard view of games
export const TOUR_GAMES_PART_3 = "TOUR_GAMES_PART_3";
// OR
// Tour Program Opt-In
export const TOUR_PROGRAM_OPT_IN_PART_1 = "TOUR_PROGRAM_OPT_IN_PART_1"; // Change to Dashboard to start Program Opt-In tour
export const TOUR_PROGRAM_OPT_IN_PART_2 = "TOUR_PROGRAM_OPT_IN_PART_2";
export const TOUR_PROGRAM_OPT_IN_PART_3 = "TOUR_PROGRAM_OPT_IN_PART_3";
export const TOUR_PROGRAM_OPT_IN_PART_4 = "TOUR_PROGRAM_OPT_IN_PART_4";

// Tour Reports
export const TOUR_REPORTS = "TOUR_REPORTS";
//then
export const TOUR_RESOURCES_TRANSITION = "TOUR_RESOURCES_TRANSITION"; // Talk about the resource page

// Tour Resources
export const TOUR_RESOURCES = "TOUR_RESOURCES";

//Tour Complete
export const TOUR_COMPLETE_TRANSITION = "TOUR_COMPLETE_TRANSITION"; // Tour Complete. Choose a section to review or register now.

// Tour Sections
export const DASHBOARD_SECTION = [TOUR_DASHBOARD];
export const COUPON_HISTORY_SECTION = [TOUR_COUPON_HISTORY_TRANSITION, TOUR_COUPON_HISTORY_PART_1, TOUR_COUPON_HISTORY_PART_2];
export const COUPON_LIBRARY_SECTION = [TOUR_COUPON_LIBRARY_TRANSITION, TOUR_COUPON_LIBRARY_PART_1, TOUR_COUPON_LIBRARY_PART_2, TOUR_COUPON_LIBRARY_PART_3];
export const COUPON_PROCESSING_SECTION = [TOUR_COUPON_PROCESSING_TRANSITION, TOUR_PENDING_COUPONS_PART_1, TOUR_PENDING_COUPONS_PART_2, TOUR_EDIT_COUPON_MODAL, TOUR_PENDING_COUPONS_PART_3];
export const COUPON_SECTION = concat(COUPON_PROCESSING_SECTION, COUPON_HISTORY_SECTION, COUPON_LIBRARY_SECTION);
export const COMPLETE_TOUR_SECTION = [TOUR_COMPLETE_TRANSITION];
export const GAME_SECTION = [TOUR_GAMES_TRANSITION, TOUR_GAMES_PART_1, TOUR_GAMES_PART_2, TOUR_GAMES_PART_3];
export const NOTIFICATION_SECTION = [TOUR_NOTIFICATION_TRANSITION, TOUR_NOTIFICATIONS_PART_1, TOUR_NOTIFICATIONS_PART_2];
export const PET_PLAN_TRACKING_SECTION = [TOUR_PET_PLAN_TRACKING_TRANSITION, TOUR_PET_PLAN_TRACKING_PART_1, TOUR_PET_PLAN_TRACKING_PART_2];
export const PLAN_ENROLLMENT_SECTION = [TOUR_PLAN_ENROLLMENT_TRANSITION, TOUR_PLAN_ENROLLMENT_PART_1, TOUR_PLAN_ENROLLMENT_PART_2];
export const PROGRAM_OPT_IN_SECTION = [TOUR_PROGRAM_OPT_IN_TRANSITION, TOUR_PROGRAM_OPT_IN_PART_1, TOUR_PROGRAM_OPT_IN_PART_2, TOUR_PROGRAM_OPT_IN_PART_3, TOUR_PROGRAM_OPT_IN_PART_4];
export const REPORTS_SECTION = [TOUR_REPORTS_TRANSITION, TOUR_REPORTS];
export const RESOURCES_SECTION = [TOUR_RESOURCES_TRANSITION, TOUR_RESOURCES];
export const WELLNESS_DASHBOARD_SECTION = [TOUR_WELLNESS_DASHBOARD];
export const WELLNESS_AUDIT_SECTION = [TOUR_WELLNESS_AUDIT_TRANSITION, TOUR_WELLNESS_AUDIT_PART_1, TOUR_WELLNESS_AUDIT_PART_2];
export const WELLNESS_HISTORY_SECTION = [TOUR_WELLNESS_HISTORY_TRANSITION, TOUR_WELLNESS_HISTORY_PART_1, TOUR_WELLNESS_HISTORY_PART_2];
export const WELLNESS_SECTION = concat(WELLNESS_AUDIT_SECTION, WELLNESS_HISTORY_SECTION, PET_PLAN_TRACKING_SECTION);

export function getFilteredTourSections(userProfile) {
    // Permissions
    const canViewClinicBiGamesDashboard =  userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
    const canViewClinicInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_INFO, userProfile);
    const canViewCouponAudit =  userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
    const canViewCouponLibrary =  userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
    const canViewNotifications =  userHasPermission(PermissionTypes.VIEW, UserPermissions.NOTIFICATIONS, userProfile);
    const canViewWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);

    let TOUR_SECTIONS_IN_ORDER = [];
    if (canViewClinicInfo) {
        if (userProfile.demoProvider !== GREENLINE_WELLNESS) {
            TOUR_SECTIONS_IN_ORDER = concat(TOUR_SECTIONS_IN_ORDER, DASHBOARD_SECTION);
        }
        if (canViewNotifications) {
            TOUR_SECTIONS_IN_ORDER = concat(TOUR_SECTIONS_IN_ORDER, NOTIFICATION_SECTION);
        }
        if (canViewCouponAudit && canViewCouponLibrary) {
            TOUR_SECTIONS_IN_ORDER = concat(TOUR_SECTIONS_IN_ORDER, COUPON_SECTION);
        }
        if (canViewWellnessAudit && userProfile.demoProvider === GREENLINE_WELLNESS) {
            TOUR_SECTIONS_IN_ORDER = concat(TOUR_SECTIONS_IN_ORDER, WELLNESS_DASHBOARD_SECTION);
        }
        if (canViewWellnessAudit) {
            TOUR_SECTIONS_IN_ORDER = concat(TOUR_SECTIONS_IN_ORDER, PLAN_ENROLLMENT_SECTION, WELLNESS_SECTION);
        }
        if (canViewClinicBiGamesDashboard) {
            TOUR_SECTIONS_IN_ORDER = concat(TOUR_SECTIONS_IN_ORDER, GAME_SECTION);
        }
        if (userProfile.demoProvider === GREENLINE_WELLNESS) {
            TOUR_SECTIONS_IN_ORDER = concat(TOUR_SECTIONS_IN_ORDER, REPORTS_SECTION);
        }

        TOUR_SECTIONS_IN_ORDER = concat(TOUR_SECTIONS_IN_ORDER, RESOURCES_SECTION, COMPLETE_TOUR_SECTION);
    }
    return TOUR_SECTIONS_IN_ORDER;
}


export const getNextTourSection = (currentSection, completedTourSections=[], userProfile={}) => {
    const removableSections = [
        currentSection,
        ...completedTourSections
    ];

    const TOUR_SECTIONS = getFilteredTourSections(userProfile);

    const remainingTourSections = difference(TOUR_SECTIONS, removableSections);
    if (remainingTourSections?.length) {
        return remainingTourSections[0];
    } else return null;
}

export const getUrlBySection = (section, userProfile) => {
    const canViewClinicBiGamesDashboard =  userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
    let goTo;
        switch(section) {
            case TOUR_DASHBOARD:
            case TOUR_NOTIFICATION_TRANSITION:
            case TOUR_NOTIFICATIONS_PART_1:
            case TOUR_GAMES_PART_2:
            case TOUR_PROGRAM_OPT_IN_PART_2:
                goTo =  "/";
                break;
            case TOUR_WELLNESS_DASHBOARD:
            case TOUR_PLAN_ENROLLMENT_TRANSITION:
            case TOUR_PLAN_ENROLLMENT_PART_1:
            case TOUR_PLAN_ENROLLMENT_PART_2:
            case TOUR_WELLNESS_AUDIT_TRANSITION:
            case TOUR_WELLNESS_AUDIT_PART_1:
                goTo = "/wellness";
                break;
            case TOUR_NOTIFICATIONS_PART_2:
            case TOUR_COUPON_PROCESSING_TRANSITION:
            case TOUR_PENDING_COUPONS_PART_1:
            case TOUR_PROGRAM_OPT_IN_PART_3:
                goTo = "/notifications";
                break;
            case TOUR_PENDING_COUPONS_PART_2:
            case TOUR_EDIT_COUPON_MODAL:
            case TOUR_PENDING_COUPONS_PART_3:
            case TOUR_COUPON_HISTORY_TRANSITION:
            case TOUR_COUPON_HISTORY_PART_1:
                goTo = "/coupons";
                break;
            case TOUR_COUPON_HISTORY_PART_2:
            case TOUR_COUPON_LIBRARY_TRANSITION:
            case TOUR_COUPON_LIBRARY_PART_1:
                goTo = "/coupons/history";
                break;
            case TOUR_COUPON_LIBRARY_PART_2:
                goTo = "/coupons/library";
                break;
            case TOUR_COUPON_LIBRARY_PART_3:
            case TOUR_GAMES_TRANSITION:
            case TOUR_GAMES_PART_1:
            case TOUR_PROGRAM_OPT_IN_TRANSITION:
            case TOUR_PROGRAM_OPT_IN_PART_1:
            case TOUR_PROGRAM_OPT_IN_PART_4:
                goTo = `/coupons/library/${userProfile.demoProvider}`;
                break;
            case TOUR_WELLNESS_AUDIT_PART_2:
            case TOUR_WELLNESS_HISTORY_TRANSITION:
            case TOUR_WELLNESS_HISTORY_PART_1:
                goTo = "/wellness/audit";
                break
            case TOUR_WELLNESS_HISTORY_PART_2:
            case TOUR_PET_PLAN_TRACKING_TRANSITION:
            case TOUR_PET_PLAN_TRACKING_PART_1:
                goTo = "/wellness/history";
                break
            case TOUR_PET_PLAN_TRACKING_PART_2:
            case TOUR_REPORTS_TRANSITION:
                goTo = "/wellness/patients";
                break;
            case TOUR_GAMES_PART_3:
                goTo = "/games";
                break;
            case TOUR_RESOURCES_TRANSITION:
            case TOUR_RESOURCES:
                 if (userProfile.demoProvider === GREENLINE_WELLNESS) {
                    goTo = `/reports`;
                } else if (canViewClinicBiGamesDashboard) {
                    goTo = "/games/history";
                } else if(userProfile.demoProvider === MERCK) {
                    goTo = `/coupons/library/${userProfile.demoProvider}`;
                }
                break;
            case TOUR_REPORTS:
                goTo = "/reports";
                break;
            case TOUR_COMPLETE_TRANSITION:
                goTo = "/resources";
                break;
            default:
                //Go to the Tour Complete page
                goTo = "/tour-complete";
        }

        return goTo;

}

export const goToNextSection = (currentSection, completedTourSections=[], userProfile={}) => {
    const nextTourSection = getNextTourSection(currentSection, completedTourSections, userProfile);
    const currentUrl = getUrlBySection(currentSection, userProfile);
    const nextUrl = getUrlBySection(nextTourSection, userProfile);

    if (currentUrl !== nextUrl) {
        return nextUrl;
    } else return null;

}
