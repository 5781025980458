exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".WellnessDvmCommission__root{overflow-y:auto;position:relative;-ms-flex:1;flex:1}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/wellness/pages/WellnessDvmCommission.scss"],"names":[],"mappings":"AAAA,6BAAM,gBAAgB,kBAAkB,WAAW,MAAM,CAAC","file":"WellnessDvmCommission.scss","sourcesContent":[".root{overflow-y:auto;position:relative;-ms-flex:1;flex:1}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "WellnessDvmCommission__root"
};