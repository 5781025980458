import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import map from "lodash/map";
import Dropdown from "components/common/Dropdown";
import * as Position from "constants/TooltipPositionIds";

function StrayCouponStatusDropdown(props) {
	const options = map(props.strayCouponStatuses, status => {
		return {
			name: status.displayText,
			value: status.statusId,
		}
	});

	const disabledOptions = flatMap(filter(props.strayCouponStatuses, "-inDropdown"), "statusId");

    return (
        <Dropdown
	        {...props}
	        options={options}
	        disabledOptions={disabledOptions}
        />
    );
}

StrayCouponStatusDropdown.defaultProps = {
	alignedLeft: false,
	alignedSplit: false,
	className: undefined,
	disabled: false,
	disabledOptions: [],
	dropdownDirection: "down",
	fullWidth: false,
	hasError: false,
	hidePlaceholder: false,
	information: undefined,
	informationPosition: Position.RIGHT,
	insideScrollingTable: false,
	invalid: false,
	label: "Status",
	loading: false,
	onClear: undefined,
	onAddNew: undefined,
	placeholder: "Select Status",
	required: false,
	showClear: false,
	small: false,
	toolTip: undefined,
	typeAhead: false,
	value: null,
	xSmall: false,
	wide: false,
};

StrayCouponStatusDropdown.propTypes = {
	alignedLeft: PropTypes.bool,
	alignedSplit: PropTypes.bool,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	disabledOptions: PropTypes.array,
	dropdownDirection: PropTypes.oneOf(["up", "down"]),
	fullWidth: PropTypes.bool,
	hasError: PropTypes.bool,
	hidePlaceholder: PropTypes.bool,
	insideScrollingTable: PropTypes.bool,
	information: PropTypes.string,
	informationPosition: PropTypes.oneOf([Position.LEFT, Position.RIGHT, Position.TOP, Position.BOTTOM]),
	invalid: PropTypes.bool,
	label: PropTypes.string,
	loading: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onAddNew: PropTypes.func,
	onClear: PropTypes.func,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	showClear: PropTypes.bool,
	small: PropTypes.bool,
	toolTip: PropTypes.func,
	typeAhead: PropTypes.bool,
	value: PropTypes.any,
	wide: PropTypes.bool,
	xSmall: PropTypes.bool,
};

export default connect(
    (state, ownProps) => ({
        strayCouponStatuses: state.entities.strayCouponStatuses,
    })
)(StrayCouponStatusDropdown);
