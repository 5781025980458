import React, { useContext } from "react";
import {connect} from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Header.scss";
import {windowSizeContext} from "AppRoot";
import NavSearchBox from "components/layout/widgets/NavSearchBox";
import UserDropdown from "components/user/elements/UserDropdown";
import ProgramWelcomeModal from "components/clinic/widgets/ProgramWelcomeModal";

const GreenlineLogoSm = require("components/common/branding/greenline-logo-circle.png");
const GreenlineLogo = require("components/common/branding/greenline-logo-footer.png");

function Header(props) {
    const context = useContext(windowSizeContext) || {};
    if(!props.userProfile) {
        return <div />
    }

    const config = props.searchConfig;
    const hasSearch = !!config;

    // See CouponOptIn.jsx for an example use.
    const preBoxJsx = (hasSearch && !!config.preBoxJsx) ?
        <div className="margin-left-sm margin-right-sm flex align-center">
            {config.preBoxJsx}
        </div>
    : null;

    return (
        <header className={classnames(styles.root, {
            [styles.collapsed]: props.collapsed,
            [styles.isQa]: context.isQa,
            [styles.isDev]: context.isDev,
            [styles.isLocal]: context.isLocal,
        })}>
            <div className={styles.logo}>
                {context.isPhone ? (
                    <Link to="/" >
                        <img src={GreenlineLogoSm} alt="Greenline Pet Logo" />
                    </Link>
                ) : (
                    <Link to="/" >
                        <img src={GreenlineLogo} alt="Greenline Pet Logo" />
                    </Link>
                )}
            </div>
            <div className={styles.seperator}/>
            {!context.isPhone ? (
                <div className={styles.searchContainer}>
                    {preBoxJsx}
                    {hasSearch && (<NavSearchBox searchConfig={props.searchConfig} clinicId={props.clinicId} />)}
                    {!hasSearch && !!props.pageTitle && <h2>{props.pageTitle}</h2>}
                </div>
            ) : (
                <div className={styles.searchContainer}>
                    {!!props.pageTitle && (
                        <h2>{props.pageTitle}</h2>
                    )}
                </div>
            )}
            <div className={styles.userDropdownContainer}>
                <UserDropdown user={props.userProfile} logoutOnly={props.logoutOnly} />
            </div>
            <ProgramWelcomeModal clinicId={props.clinicId} />
        </header>
    );
}

Header.propTypes = {
    pageTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    searchConfig: PropTypes.object,
    clinicId: PropTypes.number,
    logoutOnly: PropTypes.bool,
}


export default connect(
    (state) => ({
        userProfile: state.user.userProfile,
        voucher: state.entities.registration.data,
    })
)(Header);
