import request, {anonymousRequest} from "utils/request";
import getApiRoot from "./getApiRoot";
import * as ActionTypes from "../constants/ActionTypes";

export function searchUsers({search, offset, limit, orderBy= "userId", orderDir= "desc", clinicId=null, roles=[], providerId= null, status="all"}) {
    return request()
        .post(`${getApiRoot()}/v1/accounts/users/search`)
        .send({
            search,
            offset,
            limit,
            orderBy,
            orderDir,
            clinicId,
            roles,
            providerId,
            activeStatusFilter: status
        });
}

// This URL is actually an admin call, server will 401 anyone else
export function updateUser(data) {
    return request()
        .put(`${getApiRoot()}/v1/accounts/users/${data.userId}`)
        .send(data)
}

export function sendLogin(creds) {
    return request()
        .post("/api/v1/login/")
        .send(creds);
}

export function logOutUser() {
    return request()
        .get("/api/v1/logout")
        .send();
}

export function loadUserInfo(demoUser) {
    const includeUsername = !!demoUser ? `?user=${demoUser.name}` : "";

    return request()
        .get(`${getApiRoot()}/v1/userprofile/${includeUsername}`)
        .send();
}

export function impersonateUser(userId) {
    return request()
        .post("/api/v1/impersonate/")
        .send(userId)
}

export function resetPassword(emailAddress) {
    return request()
        .post("/api/v1/user/reset/")
        .send(emailAddress)
}

export function updatePassword(data) {
    return request()
        .post("/api/v1/password/")
        .send(data)
}

export function verifyUserByHashKey(hash) {
    return request()
        .post("/api/v1/user/verify/")
        .send({ hash: "12345"})
}

export function createUser(data) {
    return request()
        .post(`${getApiRoot()}/v1/accounts/users`)
        .send(data)
}

export function updateUserSelf(data) {
    return request()
        .put(`${getApiRoot()}/v1/userprofile`)
        .send(data)
}

export function getClinicUserRoleIds(mode = "Edit") {
    return request()
        .query({ mode })
        .get(`${getApiRoot()}/v1/accounts/userroles`)
        .send();
}

export function getServerTime() {
    return anonymousRequest()
        .get(`${getApiRoot()}/v1/currenttime`)
        .send();
}

export function healthCheck() {
    return anonymousRequest()
        .get(`${getApiRoot()}/v1/healthcheck`)
        .send();
}


// Use this endpoint to get the user's Public IP Address
export function getUserIpAddress() {
    return anonymousRequest()
        .get("https://api64.ipify.org?format=json")
        .send();
}

export function resendEmailConfirmation(userId) {
    return request()
        .post(`${getApiRoot()}/v1/accounts/users/${userId}/ResendEmailConfirmation`)
        .send();
}