import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import TextBox from "components/common/TextBox";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import { map, includes } from "lodash";
import CheckboxInput from "components/common/CheckboxInput";
import {connect} from "react-redux";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ClinicUserForm(props) {
    const [formData, setFormData] = useState({});

    const userProfile = props.userProfile;
    const canEditUsers = userHasPermission(PermissionTypes.EDIT, UserPermissions.USER_MANAGEMENT, userProfile);
    const canAddUsers = props.create && userHasPermission(PermissionTypes.CREATE, UserPermissions.USER_MANAGEMENT, userProfile);
    const canDeleteUsers = userHasPermission(PermissionTypes.DELETE, UserPermissions.USER_MANAGEMENT, userProfile);

    const handleFormChange = ({name, value}) => {
        const newFormData = {
            ...formData,
            [name]: value,
        };
        if (name === "roleId") {
            newFormData.roleIds = [value];
        }
        setFormData(newFormData);
    };

    useEffect(() => {
        if (!props.initialData) {
            return;
        }

        //On load, get the user's first role.
        if (props.initialData.roleIds && !formData.roleId) {
            setFormData({
                ...formData,
                roleId: props.initialData.roleIds[0],
            })
        }
    }, [props.initialData]);

    const handleSubmit = (e) => {
        e.preventDefault();
        props.onSubmit(formData);
    };

    const combinedData = {
        ...props.initialData,
        ...formData,
    };

    const roleIdOptions = map(props.roleIds, role => {
        return {
            name: role,
            value: role,
        };
    });

    return (
        <form onSubmit={handleSubmit}>
            <TextBox
                disabled={!(canEditUsers || canAddUsers)}
                onChange={handleFormChange}
                name="userName"
                value={combinedData.userName}
                label="Username"
                required
                minLength={6}
                pattern="[A-Za-z][A-Za-z0-9-_.@]+"
                title="Minimum 6 character length. Must start with a letter and can contain dashes (-), underscores (_), periods (.), at symbol (@), letters (A-Z), or digits (0-9)"
                information="Minimum 6 character length. Must start with a letter and can contain dashes (-), underscores (_), periods (.), at symbol (@), letters (A-Z), or digits (0-9)"
            />
            <TextBox
                disabled={!(canEditUsers || canAddUsers)}
                onChange={handleFormChange}
                name="firstName"
                value={combinedData.firstName}
                label="First Name"
                required
            />
            <TextBox
                disabled={!(canEditUsers || canAddUsers)}
                onChange={handleFormChange}
                name="lastName"
                value={combinedData.lastName}
                label="Last Name"
                required
            />
            <TextBox
                disabled={!(canEditUsers || canAddUsers)}
                onChange={handleFormChange}
                name="email"
                inputType="email"
                value={combinedData.email}
                label="Email"
                required
            />
            {!includes(combinedData.role, "ClinicOwner") && (
                <div className="flex flex-column full-width">
                    <div className="margin-left-sm margin-bottom-x-sm">Role</div>
                        <Dropdown
                            disabled={!(canEditUsers || canAddUsers)}
                            options={roleIdOptions}
                            name="role"
                            value={combinedData.role}
                            onChange={handleFormChange}
                        />
                </div>
            )}
            {!props.create && (
                <>
                    <TextBox
                        disabled={!(canEditUsers)}
                        onChange={handleFormChange}
                        name="remoteClinicUserId"
                        value={combinedData.remoteClinicUserId}
                        label="Remote Clinic User Id"
                    />
                    <div className="text-lg margin-top-sm">
                        <CheckboxInput
                            disabled={!(canEditUsers || canDeleteUsers)}
                            name="canLogin"
                            onChange={handleFormChange}
                            label="Can Login"
                            checked={combinedData.canLogin}
                            information="Can Login to Portal"
                            leftLabel
                        />
                    </div>
                </>
            )}
            <div className="flex flex-centered margin-top-md">
                <div className="flex-1">
                    <Button onClick={props.onCancel} type="gray" buttonType="button">
                        {(canEditUsers || canAddUsers) ? "Cancel" : "Close"}
                    </Button>
                </div>
                <div className="flex-none">
                    {(canEditUsers || canAddUsers) && (
                        <Button buttonType="submit">
                            {canAddUsers ? "Send Invitation" : "Save Changes"}
                        </Button>
                    )}
                </div>
            </div>
        </form>
    )
}

ClinicUserForm.propTypes = {
    create: PropTypes.bool, // If `true` then the form will show "add new user" fields, otherwise it's an "edit" form
    roleIds: PropTypes.arrayOf(PropTypes.string), // When creating a user, these are the roles that can be assigned
    initialData: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
};

export default connect(
    (state) => ({
        userProfile: state.user.userProfile,
    })
)(ClinicUserForm);
