import React from "react";
import * as styles from "./TermsAndConditions.scss";
const PDF = require("components/layout/files/greenline_llc_terms_and_conditions_01_01_2022.pdf");

export default function TermsAndConditions(props) {
    return (
        <div
            data-testid="terms_and_conditions_component"
            className={styles.root}
        >
            <div className="flex justify-flex-end">
                <a href={PDF}
                   className="btn btn-icon btn-color-default btn-full"
                   // Sets the download name and download the file
                   download="greenline_llc_terms_and_conditions_01_01_2022.pdf"
                >
                    <i className="fa fa-download"/> Download Terms and Conditions
                </a>
            </div>
            <h2 className="text-center">GREENLINE TERMS AND CONDITIONS</h2>
            <h3 className="text-center">Updated January 1, 2022</h3>

            <p>THESE TERMS AND CONDITIONS (the "Terms") shall apply to all agreements and transactions between Greenline, LLC, ("Greenline" or "Company") and your Veterinary Clinic(s) (each, a "Clinic" or "User") which have completed registration with or through the Greenline website ("Site").  By accessing the Site and accessing its services ("Services"), you, on behalf of your Clinic, accept and agree to be bound by these Terms.  If you do not wish to be bound by these Terms, you should disconnect from this Site immediately. By using the Site, you represent and warrant that you are of legal age and duly authorized on behalf of your Clinic to form a binding contract with Greenline. If you do not meet this requirement, you must not access or use the Site. Greenline may update these Terms at any time and without notice.</p>
            <ol>
                <li>
                    <p><b>DEFINITIONS</b></p>

                    <p>1.1 The "User" shall mean any Veterinary Clinic, Clinic, Veterinary Practice or Practice, or any Veterinarian or Veterinary Staff Member employed by the same who recommends pet products, offers Pet Owners Manufacturer's Incentive Programs, and/or submits Pet Owners' coupons, rebates, and/or Proof of Purchase information on their behalf through the Site.</p>
                    <p>1.2 "Pet Owner" shall mean any customer, consumer, or client of the Clinic who completes a purchase from the Clinic.</p>
                    <p>1.3 "Manufacturer" shall mean a manufacturer of pet health products in the U.S. and who sponsors the Greenline Software on behalf of Veterinary Clinics that recommends and sells their product and uses their Incentive Programs.</p>
                    <p>1.4 "Incentive Program" means the Manufacturer's marketing programs and consumer offers that follow a certain set of Rules (defined in 1.6) such as coupons, rebates, and offers.</p>
                    <p>1.5 "Proof of Purchase Data" means the data required by the Manufacturer to execute the Incentive Program for its products as well as the data utilized by Greenline to provide information on program effectiveness to Manufacturer with regard to its products or services promoted by the Clinic, which may include but is not limited to Clinic name, Manufacturer Clinic identifier, Clinic address, Clinic telephone number, Pet Owner name and address, Pet Owner telephone number, pet name, product and/or brand purchased, product sizes, and quantity purchased.</p>
                    <p>1.6 "Rules" means the applicable and current Rules and regulations related to Manufacturer's Incentive Programs, which may change from time to time.</p>
                    <p>1.7 "Software" shall mean the Greenline software program (U.S. Patent Pending Application, Serial No. 61/826,304) known as the integrated systems and methods for managing veterinary data, as well as, a method to electronically provide on-screen prompts which automatically appear to Users to encourage conversations with Pet Owners regarding needs of their pets aligned to the User's preferences and protocols; reminders are herein referred to as a "Prompt".</p>
                    <p>1.8 "Licensor" shall mean Greenline, LLC.</p>
                    <p>1.9 "Licensee" shall mean User.</p>
                </li>
                <li>
                    <p><b>CLIENT DATA SECURITY</b></p>
                    <section>
                        <span>2.1</span>   Data Privacy.
                        <p>Greenline is committed to the health of pets and to the success of your Veterinary Practice.  The Company understands the sensitivity regarding your individual Pet Owner (Client) information, including Proof of Purchase Data, and the importance of the Veterinarian/Pet Owner (Client) relationship. Storage and use of Pet Owner information is governed by Greenline's Privacy Policy which may be found on the Site and is incorporated by reference into these Terms (the "Greenline Privacy Policy”).</p>
                        <p>Greenline shall digitize the Incentive Program(s) process(es) for certain Manufacturers who choose to participate in same. Should a Clinic choose to participate in a Manufacturer's Incentive Program(s), the Clinic will provide, and Greenline will utilize, certain data to process the Incentive Program, populate related reports, execute incentives, and communicate with Pet Owners. You acknowledge and agree that such data may be provided to the Manufacturer and other Greenline related parties for Pet Owner transaction(s) and for participation in any Manufacturer's Incentive Program.  This information typically, but not exclusively, includes Clinic name, Manufacturer Clinic identifier, Clinic address and phone, Pet Owner name, address, and email, pet name, product or brand name, product size, pack size, quantity, Incentive Program code, price, and/or proof of purchase (copy of the invoice). These are typically the required fields that you or your Veterinary Staff currently supply through the paper couponing/rebating process and/or web-based portal processes.</p>
                    </section>
                    <section>
                        <span>2.2</span>   Non-disclosure of Pet Owner Data.
                        <p>Except as required by law, Greenline will not sell, share, or otherwise distribute User's individual Pet Owner data to unrelated third parties without the User's consent.  Manufacturers and other contractually-related entities to Greenline are considered Greenline's related parties. The Greenline Privacy Policy survives the Term and any subsequent agreement between Greenline and User and shall be maintained by any successor to Greenline's business and/or assets.</p>
                    </section>
                    <section>2.3 Additional Sharing and Use of Information.
                        <p>In addition, you may agree to share your Clinic-level information, including pet data, and individual Pet Owner (Client) information with Greenline and give Greenline permission to use such information and to share same with unrelated third parties for use.</p>
                    </section>
                </li>
                <li>
                    <p><b>TERM AND OBLIGATIONS</b></p>
                    <section>
                        <span>3.1</span> Term and Cost to Clinic.
                        <p>Users may opt-in to Manufacturer-sponsored Greenline services for a Term, via Manufacturer provided activation codes. In that case, services may be provided at no charge or at a reduced charge to the User for the Term of the applicable Manufacturer's Incentive Program(s). The Term will be for the period in which the User participates in the Incentive Program(s).  For services not sponsored by a Manufacturer, the User shall pay the fees as agreed upon in a separate Service Agreement between User and Greenline.</p>
                    </section>
                    <section>
                        <span>3.2</span> Clinic Opt-In Service.
                        <p>If User elects to share clinic-level data with Manufacturers, Greenline will provide information related to prescriptions, doses, and sales.  User may also choose to allow Greenline to share the same information at a doctor level (veterinarian prescribing information). Pet Owner data will be shared in accordance with sections 2.1 and 2.2. User may also agree to share information as described in section 2.3. Participation in a Manufacturer-sponsored Program via use of the Software may require a User to share certain data in return for Manufacturer sponsorship. Greenline will share data in accordance with these Terms and the Greenline Privacy Policy.</p>
                    </section>
                </li>
                <li>
                    <p><b>LICENSE GRANT</b></p>
                    <p>Licensor (Company) grants to Licensee (User) a limited, non-exclusive, non-assignable, non-transferable License (the "License") to utilize the Software (Patent App. No. 61/826,304).  The licensed "Software” means and includes the know-how, trade secrets, technical data, and inventions in the possession of Greenline which may be used in the manufacture, development, and/or integration of the patented or patent pending products or otherwise in the Services or products provided under this License. All rights, title, and ownership interests to any and all future developments, inventions, and/or improvements made by Greenline rest solely and exclusively in and with Greenline and are excluded from the license granted herein. The License shall commence on the Effective Date and shall continue through the Term whereupon the License shall terminate.</p>
                </li>
                <li>
                    <p><b>INTELLECTUAL PROPERTY</b></p>
                    <p>Nothing contained within or on the Site should be interpreted as the granting by Greenline to the User a license for use of any of the trademarks, service marks, or logos owned by Greenline or by any third party, including without limitation, any Manufacturer. Unless otherwise stated, the copyright and other intellectual property rights to and with regard to all material on the Site (including, without limitation, photographs and images) are owned by the Company or its licensors (related third parties, including Manufacturers). User is hereby granted a revocable, limited license, subject to the restrictions in these Terms, to view the material contained on the Site solely in connection with use of the Services. User and any User representative is expressly restricted from all of the following:</p>
                    <ol type="a">
                        <li>publishing any Site material in any media;</li>

                        <li>selling, sublicensing, and/or otherwise commercializing any Site material;</li>

                        <li>publicly performing and/or showing any Site material;</li>

                        <li>using this Site in any way that is, or may be, damaging to this Site;</li>

                        <li>using this Site in any way that impacts user access to this Site;</li>

                        <li>using this Site contrary to applicable laws and regulations or in a way that causes, or may cause, harm to the Site or to any person or business entity;</li>

                        <li>engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this Site or while using this Site; or</li>

                        <li>using this Site to engage in any advertising or marketing.</li>
                    </ol>
                    <p>If a User breaches any of the terms or conditions in these Terms, in addition to any other rights or causes of actions that Company may have or take, the limited license to view the material contained on the Site and utilize the Software automatically terminates, and User must immediately destroy any downloaded and/or extracts from the Site and may no longer access the Site in any way. Any rights not expressly granted in these Terms are reserved.</p>
                    <p>Unauthorized use of any material from or related to the Site or to Greenline may violate copyright laws, trademark laws, the laws of privacy and publicity, communications regulations and statutes, or other applicable laws, rules or regulations, and may subject you to civil liability and/or criminal penalties.</p>
                </li>
                <li>
                    <p><b>LINKS TO AND FROM OTHER SITES</b></p>
                    <p>Links to third-party sites from the Site are provided solely for your convenience. If you use these links, you leave the Site. The Company has not reviewed these third-party sites and does not control and is not responsible for these sites or their content or availability. The Company, therefore, does not endorse or make any representations about these sites or any material found therein or any results from using them. If User decides to access any of the third-party sites linked to by the Site, you do so entirely at your own risk, and Greenline shall have no liability with respect to your use of or access to any other site.</p>
                    <p>If you (Users) link to the Site (Greenline), you may only do so on the basis that you link to, but do not replicate, the content of the Site, and are subject to the following conditions:</p>
                    <ol type="a">
                        <li>you do not remove, distort, or otherwise alter or utilize the size or appearance of the Company logo;</li>
                        <li>you do not create a frame or any other browser or border environment around the Site;</li>
                        <li>you do not in any way imply that the Company is endorsing any products or services other than its own;</li>
                        <li>you do not misrepresent your relationship with the Company nor present any other false information about the Company;</li>
                        <li>you do not otherwise use any Company trademarks displayed on the Site without express written permission from the Company;</li>
                        <li>you do not link from a site that is not owned by you; and</li>
                        <li>your site does not contain content that is distasteful, offensive, or controversial, infringes upon any intellectual property rights or other rights of any other person, or otherwise does not comply with all applicable laws and regulations.</li>
                    </ol>
                </li>
                <li>
                    <p><b>DISCLAIMERS, WARRANTIES AND GENERAL TERMS</b></p>
                    <section>
                        <span>7.1</span> General Website Disclaimer.
                        <p>The Company assumes no responsibility for the accuracy of the information, which may contain technical or other inaccuracies, omissions, or typographical errors.  The Company may change any of the information or other materials on the Site at any time without notice.  Each User uses the Site at its sole risk.</p>
                        <p>THE MATERIAL ON THE SITE IS PROVIDED "AS IS” WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.  TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY DISCLAIMS ALL WARRANTIES OF ANY KIND, EXPRESSED OR IMPLIED INCLUDING, WITHOUT LIMITATION, WARRANTIES OF MERCHANTABILITY, FITNESS FOR PARTICULAR PURPOSE, NON-INFRINGEMENT OR ARISING FROM A COURSE OF DEALING, USAGE OR TRADE PRACTICE.  THE COMPANY MAKES NO REPRESENTATIONS OR WARRANTIES REGARDING THE USE, VALIDITY, ACCURACY, OR RELIABILITY OF INFORMATION OR MATERIAL ON THIS SITE.</p>
                        <p>THE COMPANY WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, OR OTHER DAMAGES WHATSOEVER, INCLUDING, WITHOUT LIMITATION, LOSS OF OR DAMAGE TO DATA, LOSS OF USE OF AND/OR DAMAGE TO COMPUTERS OR OTHER SYSTEMS, LOST OR DAMAGED PROGRAMS, COSTS OF REPLACEMENT GOODS AND/OR LOST PROFITS OR REVENUES ARISING OUT OF THE USE OR INABILITY TO USE THIS SITE OR ANY SERVICE PROVIDED HEREUNDER, EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU; IT IS YOUR (THE USER'S) RESPONSIBILITY TO NOTIFY THE COMPANY PRIOR TO USE OF ANY LIMITATIONS OR EXCLUSIONS THAT ARE APPLICABLE.</p>
                        <p>User represents and warrants to Greenline that User has full legal authority and has obtained all necessary consents from the Pet Owner(s) required to share the Pet Owner's information including without limitation, any Pet Owner(s) data submitted under these Terms, and User's delivery of all such information is in compliance with all applicable law(s). If a Pet Owner withdraws or otherwise revokes his or her consent, User must immediately notify Greenline. </p>
                        <p>The User hereby agrees to indemnify and hold harmless the Company, along with its officers, members, employees, agents, and any Greenline related third parties, for any claims or damages asserted by any party arising from User or User's representatives' use of this Site, the Software, or the Services, along with any claims resulting from User's violation of any term set forth herein.</p>
                        <p>As a condition of your use of this Site, you warrant to the Company that you will not use the Site for any purpose that is unlawful or prohibited by these terms and conditions.</p>
                        <p>These Terms are effective until terminated by the Company.  The Company may terminate these Terms and your access to the Site with respect to you or any third party without notice at any time for any reason.  Upon termination all rights granted in these Terms will immediately cease to exist, and you (the "User”) must promptly discontinue all use of the Site's materials and destroy all copies of the materials downloaded by you (the "User”) or your representatives from the Site.  In addition, in the event that we determine, in our sole discretion, that you have breached any of these terms of use, we reserve the right to terminate access to this Site and take any other action which we, in our sole discretion, deem to be appropriate.  All terms that by their nature survive termination of these Terms will survive.</p>
                    </section>
                    <section>
                        <span>7.2</span> Waiver.
                        <p>No term or provision of these Terms shall be deemed waived and no breach excused, unless such waiver or consent shall be in writing and signed by the Party claimed to have waived or consented.</p>
                    </section>
                    <section>
                        <span>7.3</span> Amendments.
                        <p>Greenline reserves the right to modify or terminate the Site, the Services and/or these Terms for any reason, without notice, and without liability to you or any third party. You are responsible for regularly reviewing these Terms so that you are apprised of any changes. Your continued use of the Site or the Services following the posting of revised Terms means that you accept and agree to the changes. </p>
                    </section>
                    <section>
                        <span>7.4</span> Notices.
                        <p>All notices and other communications required or permitted under these Terms shall be in writing and delivered by personal delivery, electronic mail, telecopy (confirmed by a mailed copy), or first-class mail, postage prepaid, to the address(es) noted in the registration documents for a Manufacturer's Incentive Program.  Please contact us at:</p>
                        <p className={styles.addressBlock}>
                            Greenline, LLC<br />
                            1350 Concourse Ave., Suite 469<br />
                            Memphis, TN 38104
                        </p>
                    </section>
                    <section>
                        <span>7.5</span> Entire Agreement, Binding Effect.
                        <p>These Terms, including all the Greenline Privacy Policy and any registration documents for a Manufacturer's Incentive Program, embody the entire understanding and agreement of the Parties.</p>
                    </section>
                    <section>
                        <span>7.6</span> Jurisdiction and Venue.
                        <p>This Site is controlled by the Company and in the State of Tennessee, USA.  User agrees that any legal action brought against the Company shall be governed by the laws of the State of Tennessee without regard to any conflict of law principles.  User agrees that the sole jurisdiction and venue for any litigation arising from your use of the Site shall be an appropriate federal or state court located in Shelby County, Tennessee.</p>
                    </section>
                </li>
            </ol>
            <p className={styles.footerText}>&copy; 2022 GREENLINE. ALL RIGHTS RESERVED</p>
        </div>
    )
}
