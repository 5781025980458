import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { windowSizeContext } from "AppRoot";
import Button from "components/common/Button";

export default function DataTablePagination(props) {
    const context = useContext(windowSizeContext) || {};

    return (
        <div
            data-testid="data_table_pagination_root"
            className="flex full-width flex-centered margin-top-x-sm margin-bottom-x-sm padding-left-sm padding-right-sm"
        >
            <div className="flex-none">
                <Button
                    data-testid="data_table_pagination_previous_button"
                    disabled={!props.canGoPrevious}
                    onClick={props.onPrevious}
                    icon
                    iconOnly={props.minimal || context.isPhone}
                >
                    <i className={classnames("fa fa-chevron-left", { ["margin-right-sm"]: !props.minimal})} />{!props.minimal && <span className="mobile-hide"> Previous</span> }
                </Button>
            </div>

            <div
                data-testid="data_table_pagination_page_display"
                className="flex-1 text-center"
            >
                Page {props.page} of {props.totalPages}
            </div>
            <div className="flex-none">
                <Button
                    data-testid="data_table_pagination_next_button"
                    disabled={!props.canGoNext}
                    onClick={props.onNext}
                    icon
                    iconOnly={props.minimal || context.isPhone}
                >
                    {!props.minimal && <span className="mobile-hide">Next </span>}<i className={classnames("fa fa-chevron-right", { ["margin-left-sm"]: !(props.minimal || context.isPhone)})} />
                </Button>
            </div>
        </div>
    )
}

DataTablePagination.propTypes = {
    onPrevious: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    canGoNext: PropTypes.bool.isRequired,
    canGoPrevious: PropTypes.bool.isRequired,
    page: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    minimal: PropTypes.bool,
}