

class Environment {
    constructor() {
        this.environment = {};
        this.isLoaded = false;
    }

    setEnvironment(env) {
        this.environment = env;
        this.isLoaded = true;
    }

    getValue(valueKey) {
        return this.environment[valueKey];
    }
}

export default new Environment();
