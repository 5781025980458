import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as Yup from "yup";
import includes from "lodash/includes";
import * as ProgramActions from "actions/ProgramActions";
import Button from "components/common/Button";
import SignatureBox from "components/common/SignatureBox";
import useFormHandlers from "utils/useFormHandlers";
import { DANGER, GRAY } from "constants/ButtonTypes";
import { CANCEL } from "constants/ProgramStatuses";

const schema = Yup.object().shape({
	formFieldsJson: Yup.string().default("{}"),
	signature: Yup.string().required(),
	status: Yup.string(),
});

function CancelProgramEnrollmentForm(props) {
	const { formData, handleUpdate, isValid, invalidFields } = useFormHandlers(
		{
			clinicId: props.clinicId,
			programId: props.programId,
			programServiceId: props.programServiceId,
			signature: "",
		},
        schema
    );

	const handleSubmit = (e) => {
		e.stopPropagation();
		e.preventDefault();

		const updatedProgram = {
			...formData,
			clinicId: props.clinicId,
			status: CANCEL,
		};
		if (!!props.programServiceId) {
			props.updateClinicProgramServiceStatus(updatedProgram);
		} else {
			props.updateClinicProgramStatus(updatedProgram);
		}
		props.onSave();
    };

    return (
        <form onSubmit={handleSubmit}>
	        <SignatureBox
		        onChange={handleUpdate}
		        name="signature"
		        value={formData.signature}
		        hasError={!!includes(invalidFields, "signature")}
		        label="Please Sign Here"
		        autoFocus
	        />
            <div className="flex spaced-content border-top">
	            <div className="flex-1">
		            <Button
			            type={GRAY}
			            onClick={props.onClose}
		            >
			            Close
					</Button>
	            </div>
	            <Button
		            buttonType="submit"
		            type={DANGER}
		            disabled={!isValid}
	            >
		            Cancel Enrollment
				</Button>
            </div>
        </form>
    );
}

CancelProgramEnrollmentForm.defaultProps = {
	programServiceId: undefined,
};

CancelProgramEnrollmentForm.propTypes = {
	clinicId: PropTypes.number.isRequired,
	programId: PropTypes.number.isRequired,
	programServiceId: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
	status: PropTypes.string.isRequired,
};


export default connect(
    null,
	(dispatch) => ({
		updateClinicProgramStatus: (program) => dispatch(ProgramActions.updateClinicProgramStatus(program)),
		updateClinicProgramServiceStatus: (programService) => dispatch(ProgramActions.updateClinicProgramServiceStatus(programService)),
	})
)(CancelProgramEnrollmentForm);
