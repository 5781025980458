import React from "react";
import styles from "./ApprovalStatusIcon.scss";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as StatusId from "constants/CouponStatusIds";

export default function ApprovalStatusIcon(props) {
    const status = props.status;
    let iconJsx = null;

    if (status === StatusId.APPROVED) {
        iconJsx = renderApproved();
    } else if (status === StatusId.DECLINED || status === StatusId.REJECTED) {
        iconJsx = renderDeclined();
    } else if (status === StatusId.PENDING) {
        iconJsx = renderPending();
    }

    return (
        <div
            data-testid="approval_status_icon_component"
            className={styles.root}
        >
            {iconJsx}
        </div>
    );
}

function renderPending() {
    return (
        <span
            data-testid="approval_status_icon_pending"
            className={classnames("fa-stack fa-lg", styles.pending)}
        >
            <i className="fas fa-circle fa-stack-1x" />
            <i className="far fa-ellipsis-h fa-stack-1x" />
        </span>
    );
}

function renderApproved() {
    return (
        <span
            data-testid="approval_status_icon_approved"
            className={classnames("fa-stack fa-lg", styles.approved)}
        >
            <i className="fal fa-check-circle fa-stack-1x" />
            <i className="fas fa-check-circle fa-stack-1x" />
        </span>
    );
}

function renderDeclined() {
    return (
        <span
            data-testid="approval_status_icon_declined"
            className={classnames("fa-stack fa-lg", styles.declined)}
        >
            <i className="fal fa-times-circle fa-stack-1x" />
            <i className="fas fa-times-circle fa-stack-1x" />
        </span>
    );
}

ApprovalStatusIcon.propTypes = {
    status: PropTypes.oneOf([StatusId.APPROVED, StatusId.DECLINED, StatusId.PENDING, StatusId.REJECTED]),
};
