import React, { useState, useEffect } from "react";
import ProviderBase from "components/layout/ProviderBase";
import styles from "./CorporateGroupAuditHistoryExportPage.scss";
import AccessDenied from "components/common/AccessDenied";
import { connect } from "react-redux";
import {
  PermissionTypes,
  userHasPermission,
} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import Button from "components/common/Button";
import DateInput from "components/common/DateInput";
import moment from "moment";
import logger from "utils/logger";
import toast from "utils/toast";
import Dropdown from "components/common/Dropdown";
import * as CouponsApi from "api/CouponsApi";
import { handleErrorResponse } from "utils/request";
import { downloadFile } from "utils/request";
import getProviderId from "utils/getProviderId";
import { getNodeId } from "utils/AdminData";
import flatMap from "lodash/flatMap";
import filter from "lodash/filter";
import includes from "lodash/includes";

const CorporateGroupAuditHistoryExportPage = (props) => {
  const MIN_DATE = "2021/01/01";
  const TODAY = new moment().format("YYYY/MM/DD");
  const [provider, setProvider] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [options, setOptions] = useState([]);
  const [providerData, setProviderData] = useState([]);

  const getallproviders = () => {
    CouponsApi.getProviderList()
      .then((res) => {
        setProviderData(
          res.body.map((p) => {
            return {
              name: p.name,
              value: p.id,
              providerVisibility: p.providerVisibility,
            };
          })
        );
      })
      .catch((error) => {
        handleErrorResponse("loading providers", error);
      });
  };

  useEffect(() => {
    getallproviders();
  }, []);

  const setAvailableProviders = () => {
    const myProvider = providerData.find((y) => y.value == props.providerId);

    setOptions(
      providerData.filter((f) =>
        myProvider?.providerVisibility.some(
          (item) => item.providerId === f.value && item.isVisible === true
        )
      )
    );
  };

  useEffect(() => {
    setAvailableProviders();
  }, [providerData]);

  const handleChangeProvider = ({ value }) => {
    setProvider(value || []);
  };

  const onSelectStartDate = ({ value }) => {
    const startDate = new moment(value);
    if (!!endDate) {
      if (Math.abs(startDate.diff(endDate, "days") > 30)) {
        setStartDate(value);
        setEndDate(null);
        toast.error("Date range cannot exceed 30 days");
        logger.info("Date range cannot exceed 30 days", "", true);
      } else {
        setStartDate(value);
      }
    } else {
      setStartDate(value);
    }
  };

  const onSelectEndDate = ({ value }) => {
    const endDate = new moment(value);
    if (!!startDate) {
      if (endDate.diff(startDate, "days") > 30) {
        setEndDate(null);
        toast.error("Date range cannot exceed 30 days");
        logger.info("Date range cannot exceed 30 days", "", true);
      } else {
        setEndDate(value);
      }
    } else {
      setEndDate(value);
    }
  };

  const handleDownload = async () => {
    toast.success("Generating export...");
    try {
      const res = await CouponsApi.corporateGroupAuditHistoryExport(
        props.providerId,
        provider,
        startDate,
        endDate
      );
      downloadFile(res, "xlsx");
    } catch (error) {
      handleErrorResponse("downloading export", error);
    }
  };

  return (
    <ProviderBase
      match={props.match}
      location={props.location}
      pageTitle="Corporate Audit History"
      providerId={props.providerId}
      nodeId={props.nodeId}
      collapsible={props.canViewCouponAudit}
    >
      {props.canViewCouponAudit ? (
        <div className="flex flex-column full-width full-height flex-centered">
          <p>Choose a 30 day date range and a manufacturer</p>
          <Dropdown
            placeholder={"--Select--"}
            options={options}
            name="provider"
            value={provider}
            onChange={handleChangeProvider}
          />
          <div className={styles.datePickers}>
            <div className={styles.datePicker}>
              <DateInput
                name="startDate"
                value={startDate}
                dateFormat={"YYYY-MM-DD"}
                onChange={onSelectStartDate}
                placeholderText="Start Date"
                dateRange
                selectsStart
                startDate={startDate}
                endDate={endDate}
                minDate={MIN_DATE}
                maxDate={TODAY}
                showDropdowns
              >
                Start Date
              </DateInput>
            </div>
            <div className={styles.datePicker}>
              <DateInput
                name="endDate"
                value={endDate}
                dateFormat={"YYYY-MM-DD"}
                onChange={onSelectEndDate}
                placeholderText="End Date"
                dateRange
                selectsEnd
                startDate={startDate}
                endDate={endDate}
                minDate={startDate || MIN_DATE}
                maxDate={TODAY}
                showDropdowns
              >
                Start Date
              </DateInput>
            </div>
          </div>
          <Button onClick={handleDownload}>Download</Button>
        </div>
      ) : (
        <div className="full-width full-height">
          <AccessDenied />
        </div>
      )}
    </ProviderBase>
  );
};

export default connect(
  (state, ownProps) => {
    const params = ownProps?.match?.params || {};
    const userProfile = state.user.userProfile;
    const providerId = getProviderId(params, userProfile);
    const nodeId = getNodeId(state, ownProps);
    const node = state.entities.nodes?.[nodeId];
    const corporateGroupProviderIds = flatMap(
      filter(state.entities.providers, { isCorporateGroup: true }),
      "id"
    );
    const isCorporateGroup = !!includes(
      corporateGroupProviderIds,
      node?.providerId
    );
    const canViewCouponAudit = userHasPermission(
      PermissionTypes.VIEW,
      UserPermissions.CLINIC_COUPON_AUDIT,
      userProfile
    );

    return {
      userProfile,
      providerId,
      nodeId,
      node,
      isAdmin: !!(
        ownProps?.location?.pathname?.includes("/admin") &&
        providerId &&
        nodeId
      ),
      territoryId: params.territoryId,
      districtId: params.districtId,
      regionId: params.regionId,
      isLoggedIn: state.user.isLoggedIn,
      corporateGroupProviderIds,
      isCorporateGroup,
      canViewCouponAudit,
    };
  },
  (dispatch) => ({
    loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
  })
)(CorporateGroupAuditHistoryExportPage);
