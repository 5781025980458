import React, { useEffect } from "react";
import {connect} from "react-redux";
import styles from "./AdminDashboard.scss";
import AdminBase from "components/layout/AdminBase";
import * as SearchTypes from "constants/SearchTypes";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {Link} from "react-router-dom";
import DashboardTile from "components/dashboard/widgets/DashboardTile";
import * as ClinicActions from "actions/ClinicActions";
import * as CouponActions from "actions/CouponActions";
import {compose} from "redux";
import {withRouter} from "react-router";
import { getLinkLocation } from "utils/ClinicAdminLinks"
import {clinicHasWellness, getClinicInfo} from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ClinicProgramsDashboard(props) {

    useEffect(() => {
        if (props.canViewClinicManagement) {
            props.getClinicById(props.clinicId);
        }
        if(props.canViewClinicCouponLibrary) {
            props.getPrograms(props.clinicId);
        }
    }, [props.clinicId, props.canViewClinicManagement, props.canViewClinicCouponLibrary]);

    return (
        <AdminBase
            searchConfig={SearchTypes.VOUCHER_CODE}
            clinicId={props.clinicId}
            collapsible
            docTitle={"Clinic Programs"}
        >
            {!props.clinicInfo && !props.programs ? (
                <SpinnerTakeover show />
            ) : (
                    <div className={styles.root}>
                        <div className={styles.mainSection}>
                            <div className={styles.section}>
                                {props.canViewClinicCouponLibrary && (
                                    <Link
                                        to={getLinkLocation("couponLibrary", props.clinicId)}
                                    >
                                        <DashboardTile
                                            isButton
                                            largeIcon="far fa-cut"
                                            title="Coupon Library"
                                        />
                                    </Link>
                                )}

                                {props.clinicHasWellness && (
                                    <>
                                        {props.canViewWellnessAudit && (
                                            <Link
                                                to={getLinkLocation("wellnessAudit", props.clinicId)}
                                            >
                                                <DashboardTile
                                                    isButton
                                                    largeIcon="far fa-clipboard-list-check"
                                                    title="Wellness Audit"
                                                />
                                            </Link>
                                        )}
                                        {props.canViewWellnessPetPlans && (
                                            <Link
                                                to={getLinkLocation("petPlanTracking", props.clinicId)}
                                            >
                                                <DashboardTile
                                                    isButton
                                                    largeIcon="far fa-ballot-check"
                                                    title="Pet Plan Tracking"
                                                />
                                            </Link>
                                        )}
                                        {props.canViewClinicWellnessPlans && (
                                            <Link
                                                to={getLinkLocation("wellnessPlanSetup", props.clinicId)}
                                            >
                                                <DashboardTile
                                                    isButton
                                                    largeIcon="far fa-book-medical"
                                                    title="Wellness Plan Setup"
                                                    label="View/Setup Available Plans"
                                                />
                                            </Link>
                                        )}
                                        {props.canViewWellnessDvms && (
                                            <Link
                                                to={getLinkLocation("wellnessDVM", props.clinicId)}
                                            >
                                                <DashboardTile
                                                    isButton
                                                    largeIcon="far fa-dollar-sign"
                                                    title="DVM Commission Setup"
                                                />
                                            </Link>
                                        )}
                                        {props.canViewWellnessMapping && (
                                            <Link
                                                to={`/admin/clinic/${props.clinicId}/mapping/wellness`}
                                            >
                                                <DashboardTile
                                                    isButton
                                                    largeIcon="far fa-barcode"
                                                    title="Wellness Mapping"
                                                />
                                            </Link>
                                        )}
                                    </>
                                )}
                                {props.canViewCouponAudit && (
                                    <Link to={getLinkLocation("cumulativeOfferTracking", props.clinicId)}>
                                        <DashboardTile
                                            isButton
                                            largeIcon="far fa-barcode"
                                            title="Cumulative Offer Tracking"
                                        />
                                    </Link>
                                )}
                                {props.canViewPreAuditReview && (
                                    <Link
                                        to={getLinkLocation("productOverride", props.clinicId)}
                                    >
                                        <DashboardTile
                                            isButton
                                            largeIcon="far fa-box-heart"
                                            title="Product Overrides"
                                        />
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                )}
        </AdminBase>
    );
}

const connector = connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, clinicId);
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canViewWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canViewWellnessMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.WELLNESS_MAPPING, userProfile);
        const canViewCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canViewPreAuditReview = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRE_AUDIT_REVIEW, userProfile);
        return {
            clinicId,
            clinicInfo,
            canViewClinicManagement,
            canViewClinicCouponLibrary,
            canViewWellnessAudit,
            canViewClinicWellnessPlans,
            canViewWellnessPetPlans,
            canViewWellnessDvms,
            canViewWellnessMapping,
            canViewCouponAudit,
            canViewPreAuditReview,
            clinicHasWellness: clinicHasWellness(state, clinicId),
            programs: state.entities.programs,
        }
    },
    (dispatch) => ({
        getClinicById: (clinicId) => dispatch(ClinicActions.getClinicById(clinicId)),
        getPrograms: (clinicId) => dispatch(CouponActions.getPrograms(clinicId)),
    })
);

export default compose(
    withRouter,
    connector
)(ClinicProgramsDashboard);
