
export const CAMPAIGN_TRACKING = { icon: "far fa-search", type: "campaignTrackingSearch", placeholder: "Search Pet Owner List",}
export const CLINIC_INFO_SEARCH = { type: "toolbarSearch", placeholder: "Search Clinics", results: true}
export const CLINIC_PRODUCT_OVERRIDES = { icon: "far fa-search", type: "clinicProductSearch", placeholder: "Search Product Overrides" }
export const CLINIC_PRODUCT_TAGS_SEARCH = { icon: "far fa-search", type: "clinicProductTags", placeholder: "Search Clinic Product Tags"}
export const CLINIC_SEARCH = { icon: "fas fa-search", type: "clinics", placeholder: "Search Clinics" };
export const COUPONS = { icon: "far fa-search", type: "coupon", placeholder: "Search Coupons" };
export const DASHBOARD_CLINIC_SEARCH = { icon: "fas fa-search", type: "dashboardClinics", placeholder: "Search Clinics" };
export const DVM_COMMISSION_SEARCH = { icon: "fas fa-search", type: "dvmCommissions", placeholder: "Search DVM Commissions" };
export const INVOICE_LINE_ITEM = { icon: "far fa-search", type: "invoiceLineItemsSearch", placeholder: "Search Invoice Line Items" }
export const LIBRARY_OFFERS = { icon: "far fa-search", type: "libraryOffers", placeholder: "Search Library Offers"}
export const MAPPING_SEARCH = { icon: "far fa-search", type: "mappingSearch", placeholder: "Search Mappings"}
export const NOTIFICATION_MANAGEMENT = { icon: "far fa-search", type: "notifications", placeholder: "Search Notifications"}
export const PIMS_USER_SEARCH = { icon: "fas fa-search", type: "pimsUsers", placeholder: "Search PIMS Users" };
export const PRE_AUDIT_REVIEW = { icon: "far fa-search", type: "preAuditReview", placeholder: "Search Invoices/Redemptions"}
export const PROVIDER_CLINICS_SEARCH = { icon: "far fa-search", type: "providerClinicsSearch", placeholder: "Search Clinics"}
export const REPROCESSING_JOBS_SEARCH = { icon: "far fa-search", type: "reprocessingJobs", placeholder: "Search Reprocessing Jobs"}
export const ROLE_SEARCH = { icon: "far fa-search", type: "role", placeholder: "Search Roles" };
export const TOOLBAR_CLINIC_SEARCH = { icon: "far fa-search", type: "toolbarSearch", placeholder: "Search Clinics", results: true}
export const USER_SEARCH = { icon: "fas fa-search", type: "users", placeholder: "Search Users" };
export const VETCHECK_CHARTS_SENT = { icon: "far fa-search", type: "vetCheckChartsSearch", placeholder: "Search Charts Sent" }
export const VETCHECK_CLIENT_FORMS_SENT = { icon: "far fa-search", type: "vetCheckClientFormsSearch", placeholder: "Search Client Forms Sent" }
export const VETCHECK_HANDOUTS_SENT = { icon: "far fa-search", type: "vetCheckHandoutSearch", placeholder: "Search Handouts Sent" }
export const VETCHECK_PET_OWNERS = { icon: "far fa-search", type: "vetCheckUserSearch", placeholder: "Search Pet Owners" }
export const VOUCHER_CODE = { icon: "fas fa-books-medical", type: "voucherCode", placeholder: "Enter Voucher Code", submitButtonLabel: "Verify" };
export const VOUCHER_MANAGEMENT = { icon: "fas fa-search", type: "voucherSearch", placeholder: "Search Voucher Fields" };
export const WELLNESS_AUDIT = { icon: "far fa-search", type: "auditVisits", placeholder: "Search Wellness Visits" };
export const WELLNESS_PLANS = { icon: "far fa-search", type: "wellness", placeholder: "Search Wellness Clients", results: true };
export const WELLNESS_PLANS_PAGE = { icon: "far fa-search", type: "wellnessPage", placeholder: "Search Wellness Clients" };
export const WELLNESS_REPROCESSING_SEARCH = { icon: "far fa-search", type: "wellnessReprocess", placeholder: "Search Wellness Clients"}
export const WELLNESS_VISITS = { icon: "far fa-search", type: "visits", placeholder: "Search Wellness History" };
export const WELLENSS_PET_PLAN_TRACKING = { icon: "far fa-search", type: "petPlanTracking", placeholder: "Search Clients and Patients" };