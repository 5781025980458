import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {keys, map, filter, includes, flatMap, orderBy } from "lodash";
import ServiceTableRow from "components/admin/elements/ServiceTableRow";

function ServiceTableSection(props) {
    const [emptyRows, setEmptyRows] = useState([])

    const mappings = useMemo(() => {
        return props.mappings
    }, [props.mappings]);

    //For filtering blank rows with the same clinicProductId
    const clinicProductIds = flatMap(mappings, "clinicProductId");

    const handleAddBlankMapping = () => {
        const order = 99999 + emptyRows.length + 1;
        setEmptyRows([
            ...emptyRows,
            {
                order,
                index: !!emptyRows?.length ? (emptyRows.length + 1) : 1,
                mappingId: null,
                clinicProductId: null,
            }
        ])
    }

    const handleCreateMapping = (index, clinicProductId) => {
        const newEmptyRows = map(emptyRows, e => {
            if(index === e.index) {
                return {
                    ...e,
                    clinicProductId: clinicProductId
                }
            } else {
                return e;
            }

        })
        setEmptyRows(newEmptyRows);
    }

    const handleDeleteMapping = (mappingId) => {
        const newEmptyRows = filter(emptyRows, {mappingId: mappingId});
        setEmptyRows(newEmptyRows);
    }

    const row = (mappingId, showProduct, index=null) => {
        return(
            <ServiceTableRow
                key={`${props.examServiceId}-${mappingId}-${index}`}
                clinicId={props.clinicId}
                showProduct={showProduct}
                examServiceId={props.examServiceId}
                mappingId={mappingId}
                typeId={props.typeId}
                pimsOptions={props.pimsOptions}
                disabledPimsOptions={props.disabledPimsOptions}
                boldPimsOptions={props.boldPimsOptions}
                handleAddBlankMapping={handleAddBlankMapping}
                handleOnCreate={handleCreateMapping}
                handleOnDelete={handleDeleteMapping}
                index={index}
            />
        );
    }

    let firstRow = true;

    const filteredEmptyRows = filter(emptyRows, e => {
        return !includes(clinicProductIds, e.clinicProductId);
    })

    return (
        <>
            {/*Rows with mappings*/}
            {!!keys(mappings).length ? (
                map(orderBy(mappings, ["order", "mappingId"]), (mapping) => {
                    const showProduct = firstRow;
                    firstRow = false;
                    return (row(mapping.mappingId, showProduct))
                })
            ) : (
                //Section with no mappings
                row(null, true)
            )}

            {/*Additional Empty Rows*/}
            {map(filteredEmptyRows, e => row(null, false, e.index))}
        </>
    )

}

ServiceTableSection.propTypes = {
    pimsOptions: PropTypes.array.isRequired,
    disabledPimsOptions: PropTypes.array.isRequired,
    boldPimsOptions: PropTypes.array.isRequired,
    clinicId: PropTypes.number.isRequired,
    examServiceId: PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
};

export default connect(
    (state, ownProps) => ({
        mappings: state.entities.serviceMappings[ownProps.clinicId]?.[ownProps.examServiceId]?.mappings || {},
    }),
) (ServiceTableSection);
