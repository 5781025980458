import React from "react";
import PropTypes from "prop-types";
import styles from "./PlanStatusFilterDropdown.scss";
import Dropdown from "components/common/Dropdown";
import * as PlanStatus from "constants/PlanStatusIds";

const DEFAULT_OPTIONS = Object.freeze([
    { name: "Active", value: PlanStatus.ACTIVE },
    { name: "Past Due", value: PlanStatus.PAST_DUE },
    { name: "Expired", value: PlanStatus.EXPIRED },
    { name: "Cancelled", value: PlanStatus.CANCELLED },
]);

export default function PlanStatusFilterDropdown(props) {
    const handleChanged = (value) => {
        if (props.onChange) {
            props.onChange(value);
        }
    };

    return (
        <div className={styles.root}>
            <Dropdown
                name={props.name}
                value={props.value}
                options={props.options || DEFAULT_OPTIONS}
                onChange={handleChanged}
                placeholder="All Plans"
                small
                wide
            />
        </div>
    );
}

PlanStatusFilterDropdown.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,

    // Optional array of statuses you want to allow. See constants/PlanStatusIds.js for possible values.
    // There's also a helper to build that array: createPlanOptionsObject().
    options: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string, value: PropTypes.string })),
}
