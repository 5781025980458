import React, {useState} from "react";
import PropTypes from "prop-types";
import {compose} from "redux";
import { connect } from "react-redux";
import {withRouter} from "react-router";
import replace from "lodash/replace";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import TextBox from "components/common/TextBox";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function GameStartNotification(props) {
    const [formData, setFormData] = useState({});

    const handleClose = () => {
        props.onClose();
    }

    const handleStartGame = () => {
        if(formData.HasAgreed && formData.Initials) {
            const newData = {
                ...props.additionalData,
                complianceJson: JSON.stringify(formData)
            }
            props.onSubmit(newData);
        }
    }

    const handleChange = ({name, value}) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }

    const changeUserInitials = ({name, value}) => {
        //Remove special characters (only letters allowed)
        const newValue = replace(value, /[^A-Z]/ig, "");
        handleChange({name, value: newValue});
    }

    if(!props.gameStartNotification) {
        return null;
    }

    return (
        <div>
            <div dangerouslySetInnerHTML={{ __html: props.gameStartNotification.longDescription}}/>
            <div className="border-top margin-bottom-sm">
                <CheckboxInput
                    name="HasAgreed"
                    onChange={handleChange}
                    label="I have read, acknowledged, and will abide by the interactions with Animal Health Stakeholders procedure together with Marketing Funds."
                    checked={formData.HasAgreed}
                />
            </div>
            <div className="flex spaced-content">
                <div className="flex-1">
                    <TextBox
                        onChange={changeUserInitials}
                        name="Initials"
                        value={formData.Initials}
                        placeholder="Rep Initials"
                    />
                </div>
                <div className="flex-none">
                    <Button
                        onClick={handleStartGame}
                        disabled={!(formData.Initials && formData.HasAgreed)}
                    >
                        Acknowledge and Start Game
                    </Button>
                </div>
                <div className="flex-none">
                    <Button
                        onClick={handleClose}
                        type="gray"
                    >
                        Cancel and Review Game
                    </Button>
                </div>
            </div>
        </div>
    );
}

GameStartNotification.propTypes = {
    additionalData: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    partnerProgramIdentifier: PropTypes.string.isRequired
}


const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;

        // Permissions
        const canEditProviderBiGames = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canEditGamesAdmin = userHasPermission(PermissionTypes.EDIT, UserPermissions.GAMES_ADMIN, userProfile);

        return {
            gameStartNotification: state.entities.allNotifications[ownProps.partnerProgramIdentifier],
            // Permissions
            canEditProviderBiGames,
            canAdminProviderBiGames,
            canEditGamesAdmin,
        };
    }
)

export default compose(
    withRouter,
    connector
)(GameStartNotification);
