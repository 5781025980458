import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./CheckboxInput.scss";
import Tooltip from "components/common/Tooltip";
import * as Position from "constants/TooltipPositionIds";

export default function CheckboxInput(props) {
    const {
        extraStyles = {},
    } = props;

    const handleChanged = (e) => {
        if (!props.disabled && props.onChange) {
            props.onChange({
                name: props.name,
                value: !props.checked,
            }, e);
        }
    };

    const iconWeight = props.solidIcons ? "fas" : "far";
    const shape = props.circle ? "circle" : "square";
    const checkboxClass = props.checked ? `${iconWeight} fa-${shape}-check` : props.neverEmpty ? `${iconWeight} fa-${shape}-xmark` : `far fa-${shape}`;

    return (
        <div
            className={classnames(styles.root, {
                [styles.link]: props.link,
                [styles.checked]: props.checked,
                "test-checked": props.checked,
                [styles.disabled]: props.disabled,
                "text-sm": props.small,
                "text-lg": props.large,
            })}
            data-testid={props["data-testid"]}
            onClick={handleChanged}
            style={{ ...extraStyles}}
        >
            {!props.leftLabel ? (
                <label className="flex">
                    <span className={styles.checkbox}><i className={checkboxClass} /></span>
                    <span className={classnames(styles.label, "flex")}>
                        {!!props.information && (
                            <div className={classnames("flex-none", styles.infoTip)}>
                                <Tooltip position={props.informationPosition || Position.RIGHT} tip={props.information}>
                                    <i className="fas fa-info-circle margin-right-x-sm" title={props.information}/>
                                </Tooltip>
                            </div>
                        )}
                        <div className="flex-1">{props.label}</div>
                    </span>
                </label>
            ) : (
                <label className={styles.left}>
                    <span className={classnames(styles.label, "flex align-center")}>
                        {!!props.information && (
                            <div className={classnames("flex-none", styles.infoTip)}>
                                <Tooltip position={props.informationPosition || Position.RIGHT} tip={props.information}>
                                    <i className="fas fa-info-circle margin-right-x-sm" title={props.information}/>
                                </Tooltip>
                            </div>
                        )}
                        <div className="flex-none margin-right-x-sm">{props.label}</div>
                        <span className={styles.checkbox}><i className={checkboxClass} /></span>
                    </span>
                </label>
            )}
        </div>
    );
}

CheckboxInput.propTypes = {
    ["data-testid"]: PropTypes.string,
    checked: PropTypes.bool,
    circle: PropTypes.bool,
    id: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    link: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    leftLabel: PropTypes.bool,
    neverEmpty: PropTypes.bool,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
    large: PropTypes.bool,
    solidIcons: PropTypes.bool,
    information: PropTypes.string,
    extraStyles: PropTypes.object,
};
