import React from "react";

export default function PuppyKittyBowlImportantSafetyInformation(props) {
    return (
        <div data-testid="important_safety_information_component">
	        <div>
		        <b>IMPORTANT SAFETY INFORMATION</b>: <em>NexGard</em>® Brand Products should be used with caution in dogs or cats with a history of seizures or neurologic disorders.
				See individual product labels for minimum age and weight limits.
	        </div>
        </div>
    );
}