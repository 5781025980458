import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {autobind} from "core-decorators";
import * as UserActions from "actions/UserActions";
import { canUserAccessPage } from "utils/permissions/rolesPermissions";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import WellnessCheck from "components/layout/WellnessCheck";
import BuildVersion from "./elements/BuildVersion";
/*
This should be the root container under the <Provider/> level for all
apps. It should handle any init() functions or other startup triggers
so that they can be managed in one place.
 */

class RootContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedPR: null,
        };
    }
    componentDidMount() {

        const prNumber = localStorage.getItem("prNumber");
        const versionHash = localStorage.getItem("versionHash");
        if( prNumber ) {
            this.setState({
                selectedPR: prNumber || null,
                versionHash: versionHash || ""
            });
        }

        if (!this.props.isLoggedIn) {
            this.props.checkForToken();
            return;
        }

        if (!this.props.userProfile) {
            // Required when we are using the demo site
            this.props.loadCurrentUser(this.props.demoUser);
        }


    }

    render() {
        if (!this.props.isLoggedIn) {
            return <SpinnerTakeover show/>;
        }

        if (!this.props.userProfile) {
            return <SpinnerTakeover show/>;
        }

        const canAccess = canUserAccessPage(this.props.userProfile, this.props.location.pathname);
        if (!canAccess) {
            window.location.href = `${document.location.origin}/no-access`;
            return null;
        }

        return (
            <WellnessCheck>
                {this.props.children}
                <BuildVersion prNumber={this.state.selectedPR} versionHash={this.state.versionHash} />
            </WellnessCheck>
        );
    }
}

RootContainer.propTypes = {
    location: PropTypes.object,
};

export default connect(
    (state) => ({
        userProfile: state.user.userProfile,
        isLoggedIn: state.user.isLoggedIn,
        hasError: state.applicationState.hasError,
        errorMessage: state.applicationState.errorMessage,
        demoUser: state.user.demoUser,
    }),
    (dispatch) => ({
        checkForToken: () => dispatch(UserActions.checkForToken()),
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
    }),
)(RootContainer);
