import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {compose} from "redux";
import {withRouter} from "react-router";
import classnames from "classnames";
import * as styles from "./ProviderProductTagging.scss";
import get from "lodash/get";
import * as ProviderApi from "api/ProviderApi";
import * as ProviderActions from "actions/ProviderActions";
import CorpGroupClinicTaggingTable from "./CorpGroupClinicTaggingTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import {getUnreadNotificationCount} from "utils/ClinicData";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserRoles from "constants/UserRoles";
import * as UserPermissions from "constants/UserPermissions";


function CorporateGroupProductTagging(props) {
    const [showSpinner, setShowSpinner] = useState(false);

    const handleGoToClinicDashboard = (row) => {
        if(props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("dashboard", row.clinicId));
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("dashboard", props.nodeId, row.clinicId));
        }
    }

    return (
        <>
            <div className={classnames("flex-1 flex flex-column", styles.root, styles.corporateGroup)} style={{minWidth: "500px"}}>
                <CorpGroupClinicTaggingTable
                    onRowClick={(row) => handleGoToClinicDashboard(row)}
                    nodeId={props.nodeId}
                    providerId={props.providerId}
                    isAdmin={props.isAdmin}
                />
            </div>
            <SpinnerTakeover show={showSpinner || props.clinicsAreLoading} />
        </>
    );
}

CorporateGroupProductTagging.propTypes = {
    location: PropTypes.object.isRequired,
    userRole: PropTypes.oneOf([UserRoles.PROVIDER_ADMIN, UserRoles.PROVIDER_MANAGER, UserRoles.PROVIDER_RD, UserRoles.PROVIDER_DM, UserRoles.PROVIDER_USER]),
    providerId: PropTypes.number,
    nodeId: PropTypes.number
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const provider = get(state.entities.providers, ownProps.providerId, null);

        //Permissions
        const canViewProviderInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_INFO, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canAdminProductTagging = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROVIDER_PRODUCT_TAGGING, userProfile);
        return {
            userProfile,
            providerName: provider?.name,
            node: state.entities.nodes?.[ownProps.nodeId],
            notifications: state.entities.notifications,
            notificationTotal: getUnreadNotificationCount(state),
            clinicsAreLoading: state.entities.providers.areClinicsLoading,
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && ownProps.providerId && ownProps.nodeId),

            // Permissions
            canViewProviderInfo,
            canViewCorporateGroupClinic,
            canAdminProductTagging,
        };
    },

    (dispatch) => ({
        getProviderDashboard: () => dispatch(ProviderActions.getProviderDashboard()),
        getClinicsForNode: (nodeId) => dispatch(ProviderActions.getNodeClinics(nodeId))
    }),
);

export default compose(
    withRouter,
    connector
)(CorporateGroupProductTagging);
