import React, { useEffect } from "react";
import { useHistory } from "react-router";
import LocalData from "utils/LocalData";
import logger from "utils/logger";
import { getManager } from "utils/login";

export default function OidcRedirectCallback(props) {
    const history = useHistory();

    useEffect(() => {
        const mgr = getManager();
        const isInIframe = window.parent !== window;

        const bundleVersion = localStorage.getItem("bundleVersion");
        const prNumber = localStorage.getItem("prNumber");

        let queryParams = "";
        if (prNumber) {
            queryParams = `?pr=${prNumber}`;
        } else if (bundleVersion) {
            queryParams = `?build=${bundleVersion}`;
        }

        if (!isInIframe) {
            mgr.signinRedirectCallback().then(() => {
                LocalData.clearToken();
                window.location.href = `/${queryParams}`;
            }).catch((err) => {
                window.location.href = `/${queryParams}`;
                logger.error("Error OIDC redirecting:", err, true);
            });
        } else {
            mgr.signinSilentCallback().then((user) => {
                logger.log("after silent", user);
            }).catch((err) => {
                logger.error("Error OIDC silent", err, true);
            });
        }

    }, []);

    return null;
}
