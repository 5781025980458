import React, {useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import merge from "lodash/merge";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import MappingPimsDropdownSearch from "components/admin/elements/MappingPimsDropdownSearch";
import * as styles from "components/admin/widgets/MappingTable.scss";
import TextBox from "components/common/TextBox";
import {getProviderImage} from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function WellnessTableRow(props) {
    const [deleting, setDeleting] = useState(false);
    const provider = props.providers[props.rowData.providerId];

    const handleChange = ({name, value}) => {
        const newRowData = {
            [name]: value,
            hasChanges: true
        }
        const combinedData = merge(props.rowData, newRowData);
        props.handleChange(combinedData);
    }

    const handleSaveChanges = () => {
        if(props.rowData.isNew) {
            props.handleCreateMapping(props.rowData);
        } else {
            props.handleUpdateMapping(props.rowData);
        }
        handleClearChange(props.rowData);
    }

    const handleClearChange = () => {
        props.onClearChanges(props.rowData);
    }

    const handleDeleteMapping = () => {
        setDeleting(true);
        props.onClearChanges(props.rowData);
    }

    return (
        <tr
            key={props.index}
            className={classnames(styles.root, "spaced-content", {
                [styles.firstRow]: !!props.isFirstRow,
                [styles.deleting]: deleting,
            })}
        >
            <td>
                {!!props.isFirstRow && (
                    <div className={classnames(styles.icons, "nowrap")}>
                        {provider && provider.smallImageUri && (
                            <img
                                src={getProviderImage(provider)}
                                alt={provider.name}
                            />
                        )}
                    </div>
                )}
            </td>
            <td>
                {!!props.isFirstRow && (
                    <div className="no-wrap">
                        {props.rowData.serviceTypeName}
                    </div>
                )}
            </td>
            <td>
                <TextBox
                    small
                    inputType="number"
                    value={props.rowData.commissionablePrice}
                    name="commissionablePrice"
                    min={0}
                    step={.01}
                    onChange={handleChange}
                    disabled={!props.canEditWellnessMapping || deleting}
                />
            </td>
            <td>
                {props.showComDur && (
                    <TextBox
                        small
                        inputType="number"
                        value={props.rowData.complianceDuration}
                        name="complianceDuration"
                        min={0}
                        onChange={handleChange}
                        disabled={!props.canEditWellnessMapping || deleting}
                    />
                )}
            </td>
            <td>
                <CheckboxInput
                    onChange={handleChange}
                    name="isTaxExempt"
                    checked={props.rowData.isTaxExempt}
                    disabled={!props.canEditWellnessMapping || deleting}
                />
            </td>
            <td>
                <div className={styles.myProduct}>
                    <MappingPimsDropdownSearch
                        typeId={props.typeId}
                        clinicId={props.clinicId}
                        mappingId={props.rowData.mappingInstanceId}
                        clinicProductId={props.rowData.clinicProductId}
                        productId={props.rowData.serviceTypeId}
                        handleChange={handleChange}
                        handleDeleting={handleDeleteMapping}
                        pimsOptions={props.pimsOptions}
                        disabledPimsOptions={props.disabledPimsOptions}
                        boldPimsOptions={props.boldPimsOptions}
                        disabled={!props.canEditWellnessMapping || deleting}
                        clinicPricingScheduleId={props.rowData.clinicPriceScheduleId}
                        disableDelete={props.rowData.hasChanges}
                    />
                </div>
            </td>
            <td>
                <div className="flex spaced-content">
                    <Button
                        title="Save"
                        text
                        iconOnly
                        type="primary"
                        onClick={handleSaveChanges}
                        disabled={!(props.rowData.hasChanges && props.rowData.clinicProductId) || deleting}
                    >
                        <i className="far fa-2x fa-save"/>
                    </Button>
                    <Button
                        title="Clear Changes"
                        text
                        iconOnly
                        type="danger"
                        onClick={handleClearChange}
                        disabled={!props.rowData.hasChanges || deleting}
                    >
                        <i className="fa fa-2x fa-ban"/>
                    </Button>
                    <Button
                        iconOnly
                        text
                        type="success"
                        onClick={() => {props.handleAddBlankMapping(props.rowData.serviceTypeId, props.rowData.isTaxExempt)}}
                        disabled={!props.canEditWellnessMapping || deleting}
                    >

                        <i className="fa fa-2x fa-plus-square"/>
                    </Button>
                </div>
            </td>
        </tr>
    )
}

WellnessTableRow.propTypes = {
    clinicId: PropTypes.number.isRequired,
    rowData: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    pimsOptions: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.any,
    })),
    onClearChanges: PropTypes.func.isRequired,
    typeId: PropTypes.string.isRequired,
    disabledPimsOptions: PropTypes.array,
    boldPimsOptions: PropTypes.array,
    showComDur: PropTypes.bool,
    isFirstRow: PropTypes.bool,

    handleChange: PropTypes.func.isRequired,
    handleAddBlankMapping: PropTypes.func.isRequired,
    handleUpdateMapping: PropTypes.func.isRequired,
    handleCreateMapping: PropTypes.func.isRequired,

};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canEditWellnessMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.WELLNESS_MAPPING, userProfile);
        return {
            canEditWellnessMapping,
            providers: state.entities.providers,
        }
    }
)(WellnessTableRow);
