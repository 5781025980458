import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import find from "lodash/find";
import invert from "lodash/invert";
import map from "lodash/map";
import * as CouponsApi from "api/CouponsApi";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import FixCouponErrorForm from "./FixCouponErrorForm";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import { mapSingleCouponFromServerToApp, serverFieldIds } from "data/serverMapping";
import toast from "utils/toast";

export function FixCouponErrorFormContainer(props) {
    const { submitLabel = "Submit" } = props;

    const [loading, setLoading] = useState(false);
    const [coupon, setCoupon] = useState(null);

    const handleLoadCoupon = () => {
        setLoading(true);
        CouponsApi.getCouponDetails(props.couponId)
            .then((res) => {
                setCoupon(mapSingleCouponFromServerToApp(res.body));
                setLoading(false);
            })
            .catch((err) => {
                handleErrorResponse("loading coupon details", err);
            });
    }

    useEffect(() => {
        if (props.canViewClinicCouponAudit) {
            handleLoadCoupon();
        }
    }, [props.couponId, props.canViewClinicCouponAudit]);

    if (!(props.canViewClinicCouponAudit && props.canEditClinicCouponAudit)) {
        return <AccessDenied/>;
    }

    if (loading) {
        return <SpinnerTakeover show />
    }

    if (!coupon) {
        return (
            <div data-testid="reload-coupon">
                Error loading the coupon Detail.
                <Button
                    text
                    onClick={handleLoadCoupon}

                >
                    Try loading again?
                </Button>
            </div>
        );
    }

    const handleSubmit = (formData, changedFields) => {
        const idOptions = invert(serverFieldIds);
        if (props.canEditClinicCouponAudit) {
            const editItems = map(changedFields, (value, name) => {
                const field = find(formData.fields, { validationSourceFieldId: Number(idOptions[name]) });
                if (name === "petDOB") {
                    return {
                        redemptionValidationErrorId: field?.redemptionValidationErrorId,
                        correctedDate: value,
                    };
                }
                return {
                    redemptionValidationErrorId: field?.redemptionValidationErrorId,
                    correctedText: value
                };
            });

            CouponsApi.fixCoupon({ externalRedemptionId: props.couponId, editItems })
                .then((res) => {
                    toast.success("Coupon updated successfully");
                    props.onAfterSave();
                })
                .catch((error) => {
                    handleErrorResponse("updating coupon", error);
                });
        }
    };

    const handleDecline = () => {
        props.onDecline(props.couponId);
        props.onClose();
    };

    return (
        <FixCouponErrorForm
            initialData={coupon}
            onSubmit={handleSubmit}
            onDecline={handleDecline}
            showDecline={!!props.onDecline}
            onClose={props.onClose}
            submitLabel={submitLabel}
        />
    )
}

FixCouponErrorFormContainer.propTypes = {
    couponId: PropTypes.number.isRequired,
    onDecline: PropTypes.func,
    onAfterSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    submitLabel: PropTypes.string,
    canViewClinicCouponAudit: PropTypes.bool,
    canEditClinicCouponAudit: PropTypes.bool,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;

        //  Permissions
        const canViewClinicCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canEditClinicCouponAudit = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        return {
            //  Permissions
            canViewClinicCouponAudit,
            canEditClinicCouponAudit,
        }
    },
)(FixCouponErrorFormContainer);