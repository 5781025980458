import React, { useState, useEffect } from "react";
import {connect} from "react-redux";
import {merge} from "lodash";
import PropTypes from "prop-types";
import styles from "./AvailableReports.scss";
import SortableDataTable from "components/common/SortableDataTable";
import * as ClinicActions from "actions/ClinicActions";
import moment from "moment";
import ToggleSwitch from "components/common/ToggleSwitch";

function AvailableCouponOffers(props) {
    useEffect(() => {
        if(props.clinicId && props.programId) {
            props.getAvailableReports(props.clinicId, props.programId);
        }
    }, [props.programId, props.clinicId]);

    const handleUpdate = ({name,  value}, id) => {
        const newData = {
            clinicId: props.clinicId,
            providerId: props.programId,
            providerReportId: id,
            activationDate: new Date(),
            activationUserId: props.userId,
            [name]: value
        }
        props.updateAvailableReports(newData);
    }

    const COLUMNS = [
        {
            name: "Activated",
            selector: "isActivated",
            key: "isActivated",
            format: row => (
                <ToggleSwitch
                    key={row.providerReportId}
                    id="isActivated"
                    updateState={(name, value) => handleUpdate({name, value}, row.providerReportId)}
                    force={row.isActivated}
                    labels={[]}
                />
            )
        },{
            name: "Key",
            selector: "reportKey",
            key: "reportKey",
        }, {
            name: "Name",
            selector: "reportName",
            key: "reportName",
        }, {
            name: "Activation Date",
            selector: "activationDate",
            key: "activationDate",
            format: row => (row.isActivated && row.activationDate) ? (
                <div>{new moment(row.activationDate).format("MM/DD/YYY LTS")}</div>
            ) : ""
        }
    ];

    return (
        <div className={styles.root}>
            <h3>Available Reports</h3>
            <h5>* Changes will be saved automatically</h5>
            <div className={styles.offersTable}>
                <SortableDataTable
                    columns={COLUMNS}
                    rawData={props.reports}
                    striped
                    green
                />
            </div>
        </div>
    )
}

AvailableCouponOffers.propTypes = {
    clinicId: PropTypes.number,
    programId: PropTypes.number,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        return {
            userId: userProfile.id,
            reports: state.entities.clinicReports[ownProps.clinicId] && state.entities.clinicReports[ownProps.clinicId][ownProps.programId] ? state.entities.clinicReports[ownProps.clinicId][ownProps.programId] : {},
        }
    },
    (dispatch) => ({
        getAvailableReports: (clinicId, providerId) => dispatch(ClinicActions.getAvailableReports(clinicId, providerId)),
        updateAvailableReports: (data) => dispatch(ClinicActions.updateAvailableReports(data)),
    })
)(AvailableCouponOffers);
