import React from "react";
import logger from "utils/logger";

export const getLinkLocation = (link, otherInfo=null) => {
    const withLocation = otherInfo ? otherInfo : '';
    switch (link) {
        case "dashboard":
            return `/`;
        case "games":
            return "/games";
        case "historic-games":
            return "/games/history";
        case "vetcheck":
            return `/vetcheck`;
        case "vetcheck_handouts_sent":
            return `/vetcheck/handouts-sent`;
        case "vetcheck_forms_sent":
            return `/vetcheck/forms-sent`;
        case "vetcheck_charts_sent":
            return `/vetcheck/charts-sent`;
        case "vetcheck_opt_out":
            return `/vetcheck/opt-out`;
        case "vetcheck_manage_automations":
            return `/vetcheck/manage-automations`;
        case "vetcheck_dashboard":
            return `/vetcheck/dashboard`;
        default:
            return "";
    }
}
