import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import find from "lodash/find";
import styles from "./CouponTable.scss";
import * as CouponsApi from "api/CouponsApi";
import ApprovalStatusIcon from "components/common/ApprovalStatusIcon";
import CouponOptionMenu from "components/coupons/widgets/CouponOptionMenu";
import OpenInvoicePDFLinkNewTab from "components/common/OpenInvoicePDFLinkNewTab";
import SortableDataTable from "components/common/SortableDataTable";
import { getProviderImage } from "utils/ClinicData";
import logger from "utils/logger";
import { downloadFile } from "utils/request";
import toast from "utils/toast";
import * as StatusId from "constants/CouponStatusIds";
import { APPROVED } from "constants/CouponStatusIds";

export default function CouponHistoryTable(props) {
    const COLUMNS = [
        {
            name: "Provider",
            selector: "providerId",
            key: "providerId",
            format: row => {
                const provider = find(props.providers , { id: row.providerId });
                if(provider) {
                    return (
                        <img
                            src={getProviderImage(provider)}
                            alt={provider.name}
                            style={{ maxHeight: "50px" }}
                        />
                    );
                } else {
                    return <div/>
                }

            }
        },
        {
            name: "Inv. #",
            selector: "invoiceNumber",
            key: "invoiceNumber",
            sortable: true,
            format: row => (
                <OpenInvoicePDFLinkNewTab
                    showInvoiceId={props.showInvoiceId}
                    className="text-primary"
                    invoiceNumber={row.invoiceNumber}
                    invoiceId={row.invoiceId}
                    extendedInvoiceNumber={row.extendedInvoiceNumber}
                />
            ),
        },
        {
            name: "Inv. Date",
            selector: "invoiceDate",
            key: "invoiceDate",
            sortable: true,
            format: row => moment(row.invoiceDate).format('MM/DD/YYYY'),
        },
        {
            name: "Last Name",
            selector: "lastName",
            key: "lastName",
            sortable: true,
        },
        {
            name: "Pet Name",
            selector: "petName",
            key: "petName",
            sortable: true,
        },
        {
            name: "Product",
            selector: "product",
            key: "product",
            sortable: true,
            format: row => (<div><span>{row.product} / {row.quantity}</span></div>),
        },
        {
            name: "Offer",
            selector: "offerName",
            key: "offerName",
            sortable: true,
        }
    ];

    if(props.historical) {
        COLUMNS.push({
            name: "Processed",
            selector: "userActionDate",
            key: "userActionDate",
            sortable: true,
            format: row => {
                const initials = row.userActionInitials;

                return (
                    <div className={styles.approvalBy}>
                        <span>{moment(row.userActionDate).format("M/DD/YY")}</span>
                        <span>By: {initials || "--"}</span>
                    </div>
                )
            },
        });
    }

    COLUMNS.push({
        name: props.showStatusIcon ? "Status" : "",
        selector: "status",
        key: "status",
        format: row => getStatusAndMenu(row, props),
    });



    const handleDownloadExcel = async () => {
        if(props.canViewClinicCouponAudit) {
            toast.success("Generating Export...");

            const res = await CouponsApi.downloadCouponHistory(props.downloadOptions);
            downloadFile(res, "xlsx");
        }
    }

    const canSelect = props.canSelect && props.canEditClinicCouponAudit && (!props.historical || props.canUndoDeclined);

    const getRowClassName = (row) => {
        if(row.actualRedemptionId === 13140976 || row.actualRedemptionId === 14002698 || row.actualRedemptionId === 13216538) {
            return "pendingCouponsRowRequiresValidation";
        } else return "";
    }

    return (
        <SortableDataTable
            getRowClassName={getRowClassName}
            columns={COLUMNS}
            rawData={props.coupons}
            striped
            selectable={canSelect}
            selectedRowIds={props.selectedRowIds}
            onRowSelectChange={props.onRowSelectChange}
            resultsPerPage={props.resultsPerPage}
            selectionDisabledIcon={(row) => {
                if (row.status === APPROVED) {
                    // Returns nothing if the status of the row is "Approved"
                    return null;
                } else {
                    // Returns a clickable warning icon if the row has errors
                    return(
                        <span
                            className={classnames("margin-right-sm fa-lg", {
                                "pointer": props.canEditClinicCouponAudit,
                            })}
                            onClick={() => props.canEditClinicCouponAudit ? props.onEditClick(row.id) : {}}
                        >
                            <i className="fas fa-exclamation-triangle text-danger"/>
                        </span>
                    );
                }
            }}
            downloadExcel={!!(props.historical && props.canViewClinicCouponAudit) ? handleDownloadExcel : null}
            onSort={props.handleSort}
        />
    );
}

function getStatusAndMenu({status, invoiceNumber, id, requiresValidation}, props) {
    return (
        <div className={styles.status}>
            {props.showStatusIcon && (
                <div className={classnames("flex align-center", styles.statusIcon)}>
                    <ApprovalStatusIcon
                        status={status}
                    />
                </div>
            )}
            {props.canEditClinicCouponAudit && props.showOptionsMenu && (
                <CouponOptionMenu
                    onEditClick={() => (props.canEditClinicCouponAudit && props.onEditClick) ? props.onEditClick(id) : logger.log("no handler")}
                    // onPdfClick={() => props.onPdfClick ? props.onPdfClick(invoiceNumber) : logger.log("no handler")}
                    // onShareClick={() => props.onShareClick ? props.onShareClick(id) : {}}
                    onApproveClick={() => props.onApproveClick ? props.onApproveClick(id) : {}}
                    onDeclineClick={() => props.onDeclineClick ? props.onDeclineClick(id) : {}}
                    canEdit={requiresValidation}
                    canApprove={!(requiresValidation || status === StatusId.APPROVED)}
                    canDecline={props.onDeclineClick && status !== StatusId.DECLINED}
                />
            )}
        </div>
    )
}

CouponHistoryTable.propTypes = {
    coupons: PropTypes.arrayOf(PropTypes.object).isRequired,
    historical: PropTypes.bool,
    resultsPerPage: PropTypes.number,
    downloadOptions: PropTypes.shape({
        period: PropTypes.string.isRequired,
        clinicId: PropTypes.number.isRequired,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }),

    // Row display options
    showOptionsMenu: PropTypes.bool,
    showStatusIcon: PropTypes.bool,
    showVendorLogos: PropTypes.bool,

    // Row menu click handlers
    onEditClick: PropTypes.func,
    // onPdfClick: PropTypes.func,
    onShareClick: PropTypes.func,
    onApproveClick: PropTypes.func,
    onDeclineClick: PropTypes.func,

    onInvoiceClick: PropTypes.func,
    onRowSelectChange: PropTypes.func,
    handleSort: PropTypes.func,
    selectedRowIds: PropTypes.array,

    canSelect: PropTypes.bool, // Disable row selection checkboxes entirely
    canEditClinicCouponAudit: PropTypes.bool,
    canViewClinicCouponAudit: PropTypes.bool,
    showInvoiceId: PropTypes.bool,
};

CouponHistoryTable.defaultProps = {
    showOptionsMenu: true,
    showStatusIcon: true,
    resultsPerPage: 10,
    canSelect: true,
    // showInvoiceId: true,
};
