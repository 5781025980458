exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".SpinnerTakeover__root{position:fixed;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;top:0;left:0;width:100vw;height:100vh;background-color:transparent;pointer-events:none;transition:background-color 0.15s linear;z-index:10}.SpinnerTakeover__root.SpinnerTakeover__show{background-color:rgba(255,255,255,0.7);pointer-events:all}.SpinnerTakeover__root.SpinnerTakeover__onTop{z-index:9999}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/common/SpinnerTakeover/SpinnerTakeover.scss"],"names":[],"mappings":"AAAA,uBAAM,eAAe,oBAAoB,aAAa,qBAAqB,uBAAuB,sBAAsB,mBAAmB,MAAM,OAAO,YAAY,aAAa,6BAA6B,oBAAoB,yCAAyC,UAAU,CAAC,6CAAW,uCAAuC,kBAAkB,CAAC,8CAAY,YAAY,CAAC","file":"SpinnerTakeover.scss","sourcesContent":[".root{position:fixed;display:-ms-flexbox;display:flex;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;top:0;left:0;width:100vw;height:100vh;background-color:transparent;pointer-events:none;transition:background-color 0.15s linear;z-index:10}.root.show{background-color:rgba(255,255,255,0.7);pointer-events:all}.root.onTop{z-index:9999}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "SpinnerTakeover__root",
	"show": "SpinnerTakeover__show",
	"onTop": "SpinnerTakeover__onTop"
};