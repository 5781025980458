import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./WellnessAudit.scss";
import includes from "lodash/includes";
import AccessDenied from "components/common/AccessDenied";
import ClinicBase from "components/layout/ClinicBase";
import JoyRide from "components/tour/widgets/JoyRide";
import NewPlanEnrollmentBtn from 'components/greenlineWellness/widgets/WellnessEnrollmentModal/NewPlanEnrollmentBtn';
import {
    TOUR_WELLNESS_AUDIT_PART_2,
    TOUR_WELLNESS_HISTORY_PART_1,
    TOUR_WELLNESS_HISTORY_TRANSITION
} from "utils/demoTourHelpers";
import UnprocessedWellnessVisits from "components/wellness/widgets/UnprocessedWellnessVisits";
import { getClinicInfo, clinicHasWellness } from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { CLINIC_TAGS } from "constants/AdminControls";
import * as DemoTourStates from "constants/DemoTourStates";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

function WellnessAudit(props) {
    // TOUR ONLY
    const [tourSection, setTourSection] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [showApprove, setShowApprove] = useState(false);
    const getTourSection = () => {
        // Don't set the tourSection if the tour is complete
        let currentSection = null;
        if (props.tourStarted) {
            if (!includes(props.completedTourSections, TOUR_WELLNESS_AUDIT_PART_2)) {
                currentSection = TOUR_WELLNESS_AUDIT_PART_2;
            } else if (!includes(props.completedTourSections, TOUR_WELLNESS_HISTORY_TRANSITION)) {
                currentSection = TOUR_WELLNESS_HISTORY_TRANSITION;
            } else if (!includes(props.completedTourSections, TOUR_WELLNESS_HISTORY_PART_1)) {
                currentSection = TOUR_WELLNESS_HISTORY_PART_1;
            }
        }
        setTourSection(currentSection);
    }
    useEffect(() => {
        getTourSection();
    }, [props.completedTourSections]);

    const handleTourStepComplete = (step, index) => {
        if (step === TOUR_WELLNESS_AUDIT_PART_2) {
            switch (index) {
                case 2:
                    setSelectedRows([953704, 953706, 953708, 953709, 953717]);
                    break;
                case 3:
                    setShowApprove(true);
                    break;
            }
        }
    };
    // END

    return (
        <ClinicBase
            searchConfig={SearchTypes.WELLNESS_AUDIT}
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle="Wellness Audit"
        >
            <div className={styles.root}>
                <div className={styles.topLine}>
                    <h2>Wellness Audit</h2>
                    {(props.canEnrollNew) && (
                        <NewPlanEnrollmentBtn clinicId={props.clinicId} />
                    )}
                </div>
                <div className="flex-1">
                    {!(props.canViewWellnessAudit && props.hasWellness) ? (
                        <AccessDenied/>
                    ) : (
                        <UnprocessedWellnessVisits
                            clinicId={props.clinicId}
                            isAdminView={!!props.isAdminView}
                            canGLWellness={props.canGLWellness}
                            wellnessProviders={props.wellnessProviders}
                            selectedRows={selectedRows}
                            showApproveByDefault={showApprove}
                        />
                    )}
                </div>
                <JoyRide
                    key={tourSection}
                    tourSection={tourSection}
                    onStepComplete={handleTourStepComplete}
                />
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canViewWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canGLWellness = clinicInfo?.programs?.Wellness?.providers.includes(69);
        const wellnessProviders = clinicInfo?.programs?.Wellness?.providers || [];
        const hasWellness = clinicHasWellness(state, clinicId);
        const canEnrollNew = canGLWellness && canViewWellnessAudit && canViewClinicWellnessPlans && hasWellness;

        return {
            clinicId,
            isAdminView: !!adminClinicId,
            hasWellness,
            wellnessProviders,
            completedTourSections: state.demo.completedTourSections,
            tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),
            // Permissions
            canViewClinicWellnessPlans,
            canViewWellnessAudit,
            canGLWellness,
            canEnrollNew,
        }
    }
)(WellnessAudit);
