import React from "react";
import PropTypes from "prop-types";
import clone from "lodash/clone";
import Button from "components/common/Button";
import ClientAddressFields from "./ClientAddressFields";
import ClientContactFields from "./ClientContactFields";
import PatientsList from "./PatientsList";
import TextBox from "components/common/TextBox";
import includes from "lodash/includes";

export default function AddClientFields(props) {
    const handleChange = ({ name, value }) => {
        props.onChange({
            name: `${props.namePrefix}${name}`,
            value
        });
    }

    const handleAddPatient = () => {
        // Update the list of available patients
        let newPatients;
        if (!props.owner?.patients) {
            newPatients = [{
                patientName: null
            }];
        } else {
            newPatients = clone(props.owner.patients);
            newPatients.push({ patientName: null });
        }

        // Update the list of selected patients
        let newSelectedPatients;
        if (!props.patients) {
            newSelectedPatients = [{}];
        } else {
            newSelectedPatients = clone(props.patients);
            newSelectedPatients.push({ patientName: null });
        }
        props.onChange([{
            name: `${props.namePrefix}patients`,
            value: newPatients,
        }, {
            name: "patients",
            value: newSelectedPatients,
        }]);
    };

    return (
        <>
            <TextBox
                disabled={props.readOnly}
                hasError={includes(props.errors, "petOwnerFirstName")}
                label="First name"
                name="petOwnerFirstName"
                onChange={handleChange}
                required
                value={props.owner.petOwnerFirstName}
            />
            <TextBox
                disabled={props.readOnly}
                hasError={includes(props.errors, "petOwnerLastName")}
                label="Last name"
                name="petOwnerLastName"
                onChange={handleChange}
                required
                value={props.owner.petOwnerLastName}
            />
            <ClientContactFields
                errors={props.errors}
                initialData={props.owner}
                onChange={handleChange}
                readOnly={props.readOnly}
                namePrefix={props.namePrefix}
            />
            <ClientAddressFields
                errors={props.errors}
                initialData={props.owner}
                onChange={handleChange}
                namePrefix={props.namePrefix}
                readOnly={props.readOnly}
            />
            <TextBox
                disabled={props.readOnly}
                hasError={includes(props.errors, "petOwnerRemoteId")}
                label="Client ID"
                name="petOwnerRemoteId"
                onChange={handleChange}
                required
                value={props.owner.petOwnerRemoteId}
            />
            <div className="flex spaced-content margin-top-md margin-bottom-x-sm">
                <h2 className="flex-1">Patient Information</h2>
                <Button icon onClick={handleAddPatient}>
                    <i className="fa fa-plus" /> Add Patient
                </Button>
            </div>
            <PatientsList
                onChange={props.onChange}
                initialData={props.owner?.patients}
                readOnly={props.readOnly}
                errors={props.errors}
            />
        </>
    );
}

AddClientFields.defaultProps = {
    errors: [],
    namePrefix: "",
    owner: {},
    patients: [],
    readOnly: false,
};

AddClientFields.propTypes = {
    errors: PropTypes.arrayOf(PropTypes.string),
    namePrefix: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    owner: PropTypes.object,
    patients: PropTypes.arrayOf(PropTypes.object),
    readOnly: PropTypes.bool,
};
