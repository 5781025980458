import React, { useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link, useParams } from "react-router-dom";
import styles from "./ProviderClinicSettings.scss";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import * as CouponActions from "actions/CouponActions";
import * as ProviderActions from "actions/ProviderActions";
import AccessDenied from "components/common/AccessDenied";
import DashboardTile from "components/dashboard/widgets/DashboardTile";
import ProviderBase from "components/layout/ProviderBase";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { getNodeId } from "utils/AdminData";
import { clinicHasWellness } from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ProviderClinicSettings(props) {
    useEffect(() => {
        if (props.nodeId) {
            props.getProviderClinics(props.nodeId);
        }
    }, [props.nodeId]);

    useEffect(() => {
        if(props.canViewClinicCouponLibrary) {
            props.getPrograms(props.clinicId);
        }
    }, [props.clinicId, props.canViewClinicCouponLibrary]);

    const gotToLink = (clinicLink, providerLink) => {
        if (props.userProfile?.canClinic) {
            if (props.isAdmin) {
                return ClinicAdminLinks.getLinkLocation(clinicLink, props.clinicId)
            } else {
                return ClinicProviderLinks.getLinkLocation(clinicLink, props.nodeId, props.clinicId)
            }
        } else {
            if (props.isAdmin) {
                return ClinicAdminLinks.getLinkLocation(providerLink, props.clinicId)
            } else {
                return ClinicProviderLinks.getLinkLocation(providerLink, props.nodeId, props.clinicId)
            }
        }
    }

    return (
        <ProviderBase
            location={props.location}
            // searchConfig={SearchTypes.VOUCHER_CODE}
            clinicId={props.clinicId}
            collapsible
            nodeId={props.nodeId}
            docTitle={"Clinic Settings"}
        >
            {/*TODO: Need to check that the clinic has these permissions before showing them*/}
            {/*     EXP: If clinic has wellness then show wellness links*/}
            {!props.clinicInfo && !props.programs ? (
                <SpinnerTakeover show />
            ) : (
                    <div className={styles.root}>
                        <div className="full-width full-height">
                            <div className={styles.mainSection}>
                                {props.clinicHasWellness && (
                                    <div className={styles.section}>
                                        {props.canViewClinicWellnessPlans && (
                                            <Link to={gotToLink("wellnessPlanSetup", "wellnessPlanSetup")}>
                                                <DashboardTile
                                                    isButton
                                                    largeIcon="far fa-book-medical"
                                                    title="Wellness Plan Setup"
                                                    label="View/Setup Available Plans"
                                                />
                                            </Link>
                                        )}
                                        {props.canViewClinicWellnessPetPlans && (
                                            <Link to={gotToLink("petPlanTracking", "providerPetPlanTracking")}>
                                                <DashboardTile
                                                    isButton
                                                    largeIcon="far fa-ballot-check"
                                                    title="Pet Plan Tracking"
                                                />
                                            </Link>
                                        )}
                                        {(props.canViewWellnessDvms) && (
                                            <Link to={gotToLink("wellnessDVM", "providerWellnessDVM")}>
                                                <DashboardTile
                                                    isButton
                                                    largeIcon="far fa-dollar-sign"
                                                    title="DVM Commission Setup"
                                                />
                                            </Link>
                                        )}

                                        {(props.canViewClinicWellnessAudit) && (
                                            <>
                                                <Link to={gotToLink("wellnessAudit", "providerWellnessAudit")}>
                                                    <DashboardTile
                                                        isButton
                                                        largeIcon="far fa-clipboard-list-check"
                                                        title="Wellness Processing"
                                                    />
                                                </Link>

                                                <Link to={gotToLink("wellnessHistory", "providerWellnessHistory")}>
                                                    <DashboardTile
                                                        isButton
                                                        largeIcon="far fa-clipboard-list-check"
                                                        title="Wellness History"
                                                    />
                                                </Link>
                                            </>
                                        )}
                                        {props.canEditCouponReprocessing && (
                                            <Link to={gotToLink("invoiceLineItems", "providerInvoiceLineItems")}>
                                                <DashboardTile
                                                    isButton
                                                    largeIcon="far fa-receipt"
                                                    title="Invoice Line Items"
                                                />
                                            </Link>
                                        )}
                                    </div>
                                )}
                            </div>
                            {!props.clinicHasWellness && (
                                <div className="full-height full-width flex flex-centered">
                                    <AccessDenied customMessage="This Clinic does not have access to wellness"/>
                                </div>
                            )}
                        </div>
                    </div>
                )}
        </ProviderBase>
    );
}

const connector = connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const userProfile = state.user.userProfile;
        const nodeId = getNodeId(state, ownProps);

        // Permissions
        const canViewClinicCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewClinicWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canViewWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canEditCouponReprocessing = userHasPermission(PermissionTypes.EDIT, UserPermissions.COUPON_REPROCESSING, userProfile);

        return {
            userProfile,
            clinicId,
            nodeId,
            programs: state.entities.programs,
            // TODO: we need a way to determine if a clinic has wellness before going to the clinic pages
            clinicHasWellness: !!state.entities.clinicDetails?.[clinicId] ? clinicHasWellness(state, clinicId) : true,

            // Permissions
            canViewClinicCouponLibrary,
            canViewClinicWellnessAudit,
            canViewClinicWellnessPlans,
            canViewClinicWellnessPetPlans,
            canViewWellnessDvms,
            canEditCouponReprocessing,
        }
    },
    (dispatch) => ({
        getProviderClinics: (nodeId) => dispatch(ProviderActions.getNodeClinics(nodeId)),
        getPrograms: (clinicId) => dispatch(CouponActions.getPrograms(clinicId)),
    })
);

export default compose(
    withRouter,
    connector
)(ProviderClinicSettings);
