import React, {useState} from "react";
import PropTypes from "prop-types";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import RegistrationEnterVoucher from "components/user/widgets/RegistrationEnterVoucher";
import { VOUCHER_GREEN } from "constants/ButtonTypes";

export default function AddProgramButton(props) {
    const [showVoucherModal, setShowVoucherModal] = useState(false);

    const handleOpenVoucherModal = () => {
        setShowVoucherModal(true);
    };

    const handleProgramAdded = (data) => {
        setShowVoucherModal(false);
    };

    const button = (
        <Button
            data-testid="add_program_button"
            onClick={handleOpenVoucherModal}
            iconOnly={props.collapsed}
            medium={!props.collapsed}
            type={VOUCHER_GREEN}
            text
        >
            {props.collapsed ? <i className="fas fa-books-medical margin-top-x-sm" /> : <strong>Enter a new voucher code</strong> }
        </Button>
    );

    return (
        <>
            {button}
            <Modal
                data-testid="add_program_button_modal"
                show={showVoucherModal}
                onClose={() => setShowVoucherModal(false)}
                small
                modalTitle="Sign up for a new program"
            >
                <RegistrationEnterVoucher
                    clinicId={props.clinicId}
                    onSubmit={handleProgramAdded}
                />
            </Modal>
        </>
    )
}

AddProgramButton.propTypes = {
    clinicId: PropTypes.number,
    collapsed: PropTypes.bool,
    className: PropTypes.string,
};
