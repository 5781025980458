import React, {useEffect} from "react";
import {connect} from "react-redux";
import classnames from "classnames";
import styles from "./Clinics.scss";
import find from "lodash/find";
import * as ClinicActions from "actions/ClinicActions";
import AccessDenied from "components/common/AccessDenied";
import AdminBase from "components/layout/AdminBase";
import ClinicsTable from "components/admin/widgets/ClinicsTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {parseQueryString} from "utils/urls";
import {CLINIC_MANAGEMENT} from "constants/AdminControls";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

function Clinics(props) {
    useEffect(() => {
        if (props.canViewClinicManagement) {
            props.getCustomClinicViews();
        }
    }, []);

    const handlePinnedToDashboard = () => {
        if (props.canEditClinicManagement) {
            props.updateCustomClinicView({
                ...clinicView,
                isPinned: !clinicView.isPinned,
            });
        }
    }

    const handleDefault = () => {
        if (props.canEditClinicManagement) {
            const defaultView = find(props.clinicViews, ["isFavorite", true]);
            if (!!defaultView && defaultView.userPreferenceId !== props.viewId) {
                props.updateCustomClinicView({
                    ...defaultView,
                    isFavorite: false,
                });
            }
            if (!defaultView || (defaultView.id !== props.viewId)) {
                props.updateCustomClinicView({
                    ...clinicView,
                    isFavorite: !clinicView.isFavorite,
                });
            }
        }
    }

    const clinicView = props.viewId ? props.clinicViews[props.viewId] : null;
    const pageTitle = <span>Clinic Management - <span className="text-italic text-sm" > {clinicView ? clinicView.title : "Default"}</span></span>;

    return (
        <AdminBase
            pageTitle={pageTitle}
            docTitle={"Clinic Management"}
            searchConfig={SearchTypes.CLINIC_SEARCH}
            controls={CLINIC_MANAGEMENT}
            collapsible
        >
            <div className={styles.root}>
                {!props.canViewClinicManagement ? (
                    <AccessDenied/>
                ) : (
                    <>
                        <div className="flex spaced-content">
                            {clinicView && (
                                <>
                                    <div
                                        title={(clinicView && clinicView.isPinned) ? "Click to Unpin from Dashboard" : "Click to Pin to Dashboard"}
                                        onClick={() => props.canEditClinicManagement ? handlePinnedToDashboard() : {}}
                                        className={classnames("flex-none text-large", styles.isPinnedToDashboard, {
                                            "pointer": props.canEditClinicManagement,
                                            "not-allowed": !props.canEditClinicManagement
                                        })}
                                    >
                                        {(clinicView && clinicView.isPinned) ? (
                                            <i className="fa fa-thumbtack fa-2x"/>
                                        ) : (
                                            <i className="far fa-thumbtack fa-2x"/>
                                        )}
                                    </div>
                                    <div
                                        title={(clinicView && clinicView.isFavorite) ? "Click to Unmark as Default" : "Click to mark as Default"}
                                        onClick={() => props.canEditClinicManagement ? handleDefault() : {}}
                                        className={classnames("flex-none text-large margin-bottom-md", styles.isDefault, {
                                            "pointer": props.canEditClinicManagement,
                                            "not-allowed": !props.canEditClinicManagement
                                        })}
                                    >
                                        {(clinicView && clinicView.isFavorite) ? (
                                            <i className="fa fa-star fa-2x"/>
                                        ) : (
                                            <i className="far fa-star fa-2x"/>
                                        )}
                                    </div>
                                </>
                            )}
                        </div>
                        <ClinicsTable/>
                    </>
                )}
                <SpinnerTakeover show={props.working}/>
            </div>
        </AdminBase>
    )
}

export default connect(
    (state, ownProps) => {
        const parsedFilters = parseQueryString(ownProps.location.search);
        const viewId = parsedFilters?.viewId || 0.
        const userProfile = state.user.userProfile;
        //Permissions
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            viewId,
            canViewClinicManagement,
            canEditClinicManagement,
            clinicViews: state.entities.clinicViews,
            working: !!state.applicationState.working,
        }
    },
    (dispatch) => ({
        getCustomClinicViews: () => dispatch(ClinicActions.getCustomClinicViews()),
        updateCustomClinicView: (view) => dispatch(ClinicActions.updateCustomClinicView(view)),
    })
)(Clinics);
