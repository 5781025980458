import React, { useState } from "react";
import { connect } from "react-redux";
import * as styles from "./EligibilityTrackerInstructionsPage.scss";
import classnames from "classnames";
import ClinicBase from "components/layout/ClinicBase";
import { getClinicInfo } from "utils/ClinicData";
import Accordion from "components/common/Accordion";
import AccordionSection from "components/common/AccordionSection";
import Image from "components/common/Image";
import { Link } from "react-router-dom";
import Modal from "components/common/Modal/Modal";
import EligibilityTrackerQRCode from "components/clinic/widgets/EligibilityTrackerQRCode";

// Accordion IDs
const ET_FLOW_ACCORDION_ID = "et_flow";
const WHERE_IS_CLIENT_ID_ACCORDION_ID = "where_is_client_id"

// Assets
const ET_FLOW_IMAGE_SRC = require("../../../../../img/eligibility_tracker/et_flow.png");
const CLIENT_ID_PIMS_IMAGE_SRC = require("../../../../../img/eligibility_tracker/client_id_pims.png");
const CLIENT_ID_INVOICE_IMAGE_SRC = require("../../../../../img/eligibility_tracker/client_id_invoice.png");

function EligibilityTrackerInstructionsPage(props) {
    const [showQRCodeModal, setShowQRCodeModal] = useState(false);

    return (
        <ClinicBase
            clinicId={props.clinicId}
            docTitle="Eligibility Tracker Instructions"
            pageTitle="Eligibility Tracker - Instructions"
        >
            <div className={styles.root}>
                <Accordion
                    multiOpen
                    defaultOpen={[ET_FLOW_ACCORDION_ID, WHERE_IS_CLIENT_ID_ACCORDION_ID]}
                >
                    <AccordionSection
                        key={ET_FLOW_ACCORDION_ID}
                        id={ET_FLOW_ACCORDION_ID}
                        header="How does Eligibility Tracker Work?"
                    >
                        <div className="text-dark-gray">
                            <div className={styles.subtext}>
                                When your client scans the QR code in your clinic, they will be prompted to input their clinic information, and be offered a specific rebate amount.
                                <br />
                                <div className={styles.smallScreenExtraSpacing} />
                                They will show this offer to you at the front desk, and you will update the instant rebate code to match the amount.
                            </div>

                            <Image
                                className={classnames(styles.instructionsImage, styles.etFlowImage)}
                                src={ET_FLOW_IMAGE_SRC}
                                title="Eligibility Tracker Flow Diagram"
                                alt="Eligibility Tracker Flow Diagram"
                            />
                        </div>
                    </AccordionSection>
                    <hr />
                    <AccordionSection
                        key={WHERE_IS_CLIENT_ID_ACCORDION_ID}
                        id={WHERE_IS_CLIENT_ID_ACCORDION_ID}
                        header="Where do I find client ID in my PIMS?"
                    >
                        <div className="text-dark-gray">
                            <div className={styles.row}>
                                <div className={styles.firstRowItem}>
                                    <div className={styles.fitContent}>
                                        <div className={styles.subtext}>
                                            In most practice management softwares, the client ID is found in the upper right or left hand corner.
                                        </div>

                                        <div className="text-center">
                                            <Image
                                                className={styles.instructionsImage}
                                                src={CLIENT_ID_PIMS_IMAGE_SRC}
                                                title="Where to Find Client ID in PIMS"
                                                alt="Where to Find Client ID in PIMS"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={styles.centered}>
                                    <div className={styles.subtext}>
                                        A client ID can also be found in their most recent invoice.
                                    </div>

                                    <div className={styles.centeredImage}>
                                        <Image
                                            className={styles.instructionsImage}
                                            src={CLIENT_ID_INVOICE_IMAGE_SRC}
                                            title="Where to Find Client ID in Invoice"
                                            alt="Where to Find Client ID in Invoice"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </AccordionSection>
                    <hr />
                </Accordion>

                <div className={classnames(styles.row, "margin-bottom-lg")}>
                    <div className={styles.firstRowItem}>
                        <div className={styles.fitContent}>
                            <div className="text-dark-gray margin-bottom-sm">View a list of your eligible clients here:</div>
                            <div className={styles.linkContainer}>
                                <Link
                                    className="pointer text-primary"
                                    to={`${props.basePath}eligible-clients`}
                                >
                                    View Eligible Clients
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className={styles.secondRowItem}>
                        <div className="text-dark-gray margin-bottom-sm">Download your Eligibility Tracker QR code here:</div>
                        <div className={styles.linkContainer}>
                            <div
                                className="pointer text-primary"
                                onClick={() => setShowQRCodeModal(true)}
                            >
                                Eligibility Tracker QR Code
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                small
                show={showQRCodeModal}
                onClose={() => setShowQRCodeModal(false)}
                modalTitle="Eligibility Tracker QR Code"
            >
                <EligibilityTrackerQRCode
                    clinicId={props.clinicId}
                    displayInline
                />
            </Modal>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        const basePath = ownProps.match.url.replace("/instructions", "/");
        return {
            clinicId,
            basePath,
        }
    }
)(EligibilityTrackerInstructionsPage);
