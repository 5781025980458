import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import styles from "./VetCheck.scss";
import {Link} from "react-router-dom";
import classnames from "classnames";
import map from "lodash/map";
import * as VetCheckApi from "api/VetCheckApi";
import * as VetCheckActions from "actions/VetCheckActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import ClinicBase from "components/layout/ClinicBase";
import DashboardTile from "components/dashboard/widgets/DashboardTile";
import Image from "components/common/Image";
import Modal from "components/common/Modal";
import SignupForVetCheck from "components/vetcheck/forms/SignupForVetCheck";
import VetCheckHeader from "components/vetcheck/widgets/VetCheckHeader";
import VetCheckStripe from "components/vetcheck/widgets/VetCheckStripe";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import * as ClinicLinks from "utils/ClinicLinks";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import {getClinicInfo} from "utils/ClinicData";
import env from "utils/environment";
import logger from "utils/logger";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {clinicSubscriptions, SubscriptionStatuses} from "utils/SubscriptionStatus";
import * as ModalTypes from "constants/ModalTypes";
import {VETCHECK} from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";
import {ACTIVE, EXPIRED, INACTIVE, PENDING, REGISTERED} from "constants/VetCheckActivationStates";

const BANNER = require("components/common/branding/vetcheck/vetcheck_banner.png");
const CHART = require("components/common/branding/vetcheck/vetcheck_chart.png");
const DOCUMENT = require("components/common/branding/vetcheck/vetcheck_document.png");
const FLOWCHART = require("components/common/branding/vetcheck/vetcheck_flowchart.jpg");
const HEART = require("components/common/branding/vetcheck/vetcheck_heart.png");
const TIME = require("components/common/branding/vetcheck/vetcheck_time.png");

function VetCheck(props) {
    const [showCreateVetCheckSubscription, setShowCreateVetCheckSubscription] = useState(false);
    const [loginStatus, setLoginStatus] = useState({});
    const loginCallbackUri = `${props.location.origin}/vetcheck/loggedin/${props.isAdmin ? props.clinicId : null}`;
    const registerCallbackUri = `${props.location.origin}/vetcheck/registered`;

    const hasPositiveStatus = (
        props.vetCheckStatus?.activationState &&
        (
            props.vetCheckStatus.activationState === ACTIVE ||
            props.vetCheckStatus.activationState === PENDING ||
            props.vetCheckStatus.activationState === REGISTERED
        )
    );

    const hasNegativeStatus = (
        props.vetCheckStatus?.activationState &&
        (
            props.vetCheckStatus.activationState === EXPIRED ||
            props.vetCheckStatus.activationState === INACTIVE
        )
    );



    const getVetCheckStatus = () => {
        if(props.canViewClinicVetCheck || props.canViewVetCheckMapping) {
            VetCheckApi.getClinicVetCheckStatus(props.clinicId, loginCallbackUri, registerCallbackUri)
                .then((res) => {
                    setLoginStatus(res.body);
                })
                .catch((error) => {
                    handleErrorResponse("loading VetCheck status", error);
                })
        }
    }

    useEffect(() => {
        if(!!props.clinicId && hasPositiveStatus) {
            getVetCheckStatus();
        }
    }, [props.clinicId, props.vetCheckStatus, props.hasSubscription]);

    const handleCreateAccount = () => {
        setShowCreateVetCheckSubscription(true);
    }

    //TODO: handle the links need to work for admins, clinics, and providers'

    const disableTiles = !(
        props.vetCheckStatus?.authCode &&
        (props.vetCheckStatus?.activationState !== ACTIVE || props.vetCheckStatus?.activationState !== REGISTERED)
    );

    const TILES = [{
        id: "handouts_sent",
        link: "vetcheck_handouts_sent",
        icon: "fa fa-envelope-open-text",
        title: "Handouts",
        label: "Handouts Sent",
        disabled: disableTiles,
    }, {
        id: "forms_sent",
        link: "vetcheck_forms_sent",
        icon: "fa fa-memo-pad",
        title: "Forms",
        label: "Client Forms Sent",
        disabled: disableTiles,
    // }, {
    //     id: "charts_sent",
    //     link: "vetcheck_charts_sent",
    //     icon: "fa fa-clipboard-medical",
    //     title: "Charts",
    //     label: "Charts Sent",
    //     disabled: disableTiles,
    }, {
        id: "opt_outs",
        link: "vetcheck_opt_out",
        icon: "fa fa-user-slash",
        title: "Opt-Outs",
        label: "Pet Owner Opt-Outs",
        hide: !(props.canViewProviderBiGamesClinicInvites && props.canViewProviderBiGamesDrilldown),
        disabled: disableTiles,
    }, {
        id: "manage_automations",
        link: "vetcheck_manage_automations",
        icon: "fa fa-envelopes-bulk",
        title: "Settings",
        label: "Manage Automations",
        hide: !props.canViewVetCheckMapping,
        disabled: disableTiles,
    }, {
        id: "dashboard",
        link: "vetcheck_dashboard",
        icon: "fa fa-display-medical",
        title: "Dashboard",
        label: "VetCheck Dashboard",
        disabled: !props.vetCheckStatus?.authCode,
    }]

    const goToLink = (tile) => {
        if(props.isAdmin) {
            return ClinicAdminLinks.getLinkLocation(tile.link, props.clinicId);
        } else if(props.isProvider) {
            return ClinicProviderLinks.getLinkLocation(tile.link, props.nodeId, props.clinicId)
        } else {
            return ClinicLinks.getLinkLocation(tile.link);
        }
    }

    const SHOW_TILES = (
        <div className={styles.tiles}>
            {map(TILES, tile => {
                if (tile.hide) {
                    return null;
                }
                const link = goToLink(tile);
                return (
                    <Link
                        key={tile.id}
                        to={link}
                    >
                        <DashboardTile
                            isButton
                            largeIcon={tile.icon}
                            title={tile.title}
                            label={tile.label}
                            labelBottom
                            disabled={tile.disabled}
                        />
                    </Link>
                );
            })}
        </div>
    );

    const handleSignupComplete = () => {
        props.setShowChangeVetCheckPassword(true);
        setShowCreateVetCheckSubscription(false);
    }

    const VETCHECK_OVERVIEW = (
        <>
            <div className={classnames(styles.section, styles.dark, "flex spaced-content")}>
                <div className="flex-1 flex flex-centered">
                    <div>
                        <div className={classnames(styles.heading, "text-bold margin-bottom-x-sm")}>
                            Veterinary automation platform.
                        </div>
                        <div>Improve pet health outcomes with better client engagement and compliance.</div>
                        <div className="margin-top-x-sm">
                            <Button
                                type="vetcheck"
                                onClick={handleCreateAccount}
                                disabled={props.isProvider || !props.canViewPaymentSettings}
                                title={
                                    props.isProvider ? "Provider users cannot complete the VetCheck signup process for their users" :
                                    !(props.canViewPaymentSettings && props.canEditClinicVetCheck) ? "You do not have the correct permissions to sign up for VetCheck. Please contact Greenline for help." :
                                    "Signup for VetCheck"
                                }
                            >
                                <span className="nowrap">{(!props.hasSubscription && props.hasPositiveStatus) ? "Complete Sign Up" : "Get Started"}</span>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="flex-2 flex flex-centered">
                    <div>
                        <Image
                            alt="VetCheck banner"
                            src={BANNER}
                            style={{width: "100%"}}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.section}>
                <div className="text-lg margin-bottom-x-sm">
                    VetCheck Automate is the automation platform teams use to save time, better educate clients, and provide high-value healthcare.
                </div>
                <div className={styles.border}>
                    <Image
                        alt="VetCheck flowchart"
                        src={FLOWCHART}
                        style={{width: "100%"}}
                    />
                </div>
            </div>
            <div className={classnames(styles.section, styles.dark)}>
                <div className="flex flex-wrap margin-top-lg margin-bottom-x-lg">
                    <div className={styles.point}>
                        <div className="flex flex-centered">
                            <Image
                                src={TIME}
                                style={{
                                    width: "30%",
                                    maxWidth: "130px",
                                    minWidth: "25px"
                                }}
                            />
                        </div>
                        <div className="text-lg margin-top-sm text-center">Save time.</div>
                        <div className="flex flex-centered">
                            <p>
                                Select from 1000’s of visual client communications and let automation share the right information at the right time with your clients.
                                Simplify workflows, deliver consistent quality of care, reduce client confusion and increase clinical performance.
                            </p>
                        </div>
                    </div>
                    <div className={styles.point}>
                        <div className="flex flex-centered">
                            <Image
                                src={HEART}
                                style={{
                                    width: "30%",
                                    maxWidth: "130px",
                                    minWidth: "25px"
                                }}
                            />
                        </div>
                        <div className="text-lg margin-top-sm text-center">Improve pet health outcomes.</div>
                        <div className="flex flex-centered">
                            <p>Engage pet owners like never before with targeted messages, highly visual instructions and home care programs that will build stronger client relationships and drive better pet health outcomes.</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-wrap margin-bottom-lg">
                    <div className={styles.point}>
                        <div className="flex flex-centered">
                            <Image
                                src={CHART}
                                style={{
                                    width: "30%",
                                    maxWidth: "130px",
                                    minWidth: "25px"
                                }}
                            />
                        </div>
                        <div className="text-lg margin-top-sm text-center">Deliver value without effort.</div>
                        <div className="flex flex-centered">
                            <p>Our experienced team will work with you on a comprehensive education and marketing strategy that integrates the right workflows, content and more, so you can focus on patient care.</p>
                        </div>
                    </div>
                    <div className={styles.point}>
                        <div className="flex flex-centered">
                            <Image
                                src={DOCUMENT}
                                style={{
                                    width: "30%",
                                    maxWidth: "130px",
                                    minWidth: "25px"
                                }}
                            />
                        </div>
                        <div className="text-lg margin-top-sm text-center">Increase data capture.</div>
                        <div className="flex flex-centered">
                            <p>
                                Select from VetCheck's practice form templates with e-sign and share to clients for easy data collection and secure e-signatures.
                                Designed specifically for the veterinary industry and integrates seamlessly into websites and vet practice management systems.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={showCreateVetCheckSubscription}
                onClose={() => setShowCreateVetCheckSubscription(false)}
                tall
                mediumSmall
                modalStyle={ModalTypes.VET_CHECK}
            >
                {props.isProvider ? (
                    <AccessDenied customMessage="User must be in a clinic to signup for VetCheck"/>
                ) : !(props.canViewPaymentSettings && props.canEditClinicVetCheck) ? (
                    <AccessDenied/>
                ) : (
                    <VetCheckStripe>
                        <SignupForVetCheck
                            clinic={props.clinic}
                            requireClinic={!(hasPositiveStatus)}
                            requireCard={!props.isAdmin}
                            onClose={() => setShowCreateVetCheckSubscription(false)}
                            onSubmit={handleSignupComplete}
                        />
                    </VetCheckStripe>
                )}
            </Modal>
        </>
    );

    const openLogin = (newWindow=false) => {
        if(!!loginStatus?.loginUrl) {
            props.setShowChangeVetCheckPassword(false);
            if (newWindow){
                window.open(loginStatus?.loginUrl, "_blank");
            } else {
                window.location.href = loginStatus?.loginUrl;
            }
        } else {
            logger.error("The Login URL is missing.")
        }
    }

    const handleGoToSubscriptionManagement = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(props.canViewPaymentSettings && !(props.isAdmin || props.isProvider)) {
            props.history.push("/setup/payments");
        }
    }

    const getDashboard = () => {
        // TODO: Show has expired subscription if the activationState is "expired" or something like that.
        if(hasNegativeStatus) {
            return (
                <div>
                    {SHOW_TILES}
                    <div className={styles.authorizationLockdown}>
                        <div className="full-width full-height flex align-center justify-center">
                            <div>
                                <div className="text-center text-lg">Your VetCheck subscription has expired.</div>
                                <div className="text-center margin-top-x-sm">
                                    <Button
                                        type="danger"
                                        onClick={handleGoToSubscriptionManagement}
                                        disabled={!props.canViewPaymentSettings || props.isAdmin || props.isProvider}
                                        title={
                                            props.isAdmin ? "You must impersonate the clinic admin to manage payment settings." :
                                            props.isProvider ? "You cannot mange payment methods fro a clinic." :
                                            "Mange payment methods for the clinic"
                                        }
                                    >
                                        Manage VetCheck Subscription
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        switch(props.vetCheckStatus?.activationState) {
            case ACTIVE:
            case REGISTERED:
                if (!!props.vetCheckStatus?.authCode) {
                    return SHOW_TILES;
                } else {
                    return (
                        <AccessDenied customMessage={(
                            <div>
                                <div>Clinic does not have access to the VetCheck plugin.</div>
                                <div>Please contact Greenline for assistance in resolving this issue.</div>
                            </div>
                        )}/>
                    );
                }
            case PENDING:
                // Show a reminder to change their password after registration is complete
                if(props.showChangeVetCheckPassword) {
                    return (
                        <div>
                            {SHOW_TILES}
                            <div className={styles.authorizationLockdown}>
                                <div className="full-width full-height flex align-center justify-center">
                                    <div className={styles.content}>
                                        <div className="text-center text-xlg margin-bottom-x-sm">Welcome to VetCheck Automate</div>
                                        <div className="text-lg">
                                            Next you'll need to set your password for VetCheck.
                                            In the new tab, use the "Don't remember you password?" link and enter the email address you provided during registration.
                                        </div>
                                        <div className="text-center margin-top-sm">
                                            <Button
                                                type="vetcheck"
                                                onClick={() => openLogin(true)}
                                                disabled={!loginStatus?.loginUrl}
                                                large
                                            >
                                                Start Password Reset
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                } else return (
                    // User must log in to VetCheck so Greenline has the correct data to make requests from the VetCheck API.
                    <div>
                        {SHOW_TILES}
                        <div className={styles.authorizationLockdown}>
                            <div className="full-width full-height flex align-center justify-center">
                                <div className={styles.content}>
                                    <div className="text-center text-xlg margin-bottom-x-sm">Connect your VetCheck Account</div>
                                    <div className="text-lg">
                                        You need to log in to VetCheck to complete the connection to Greenline Pet.
                                        Your login is the email address that you entered during registration.
                                    </div>
                                    <div className="text-lg margin-top-sm">
                                        If you don't remember your password, you can reset it on the next page.
                                    </div>
                                    <div className="text-center margin-top-sm">
                                        <Button
                                            type="vetcheck"
                                            onClick={openLogin}
                                            disabled={!loginStatus?.loginUrl}
                                            large
                                        >
                                            Log in
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            default:
                return VETCHECK_OVERVIEW;
        }
    }

    return (
        <ClinicBase
            clinicId={props.clinicId}
            docTitle={"VetCheck"}
            style={{padding: "0"}}
        >
            <div className={styles.root}>
                <VetCheckHeader
                    clinicId={props.clinicId}
                    location={props.location}
                />
                <div className={styles.dashboardContainer}>
                    {getDashboard()}
                </div>
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const isAdmin = !!ownProps.location.pathname.includes("/admin");
        const isProvider = !!ownProps.location.pathname.includes("/provider");
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const hasActiveSubscription = !!clinicSubscriptions(state, clinicId, VETCHECK, SubscriptionStatuses.ACTIVE)?.length;
        const hasPaidSubscription = !!clinicSubscriptions(state, clinicId, VETCHECK, SubscriptionStatuses.PAID)?.length;
        const hasSubscription = hasActiveSubscription || hasPaidSubscription;
        // Permissions
        const canViewClinicVetCheck = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_VETCHECK, userProfile);
        const canViewPaymentSettings = userHasPermission(PermissionTypes.VIEW, UserPermissions.PAYMENT_SETTINGS, userProfile);
        const canEditClinicVetCheck = userHasPermission(PermissionTypes.EDIT, UserPermissions.PAYMENT_SETTINGS, userProfile);
        const canViewProviderBiGamesClinicInvites = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canViewProviderBiGamesDrilldown = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canViewVetCheckMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);
        return {
            isAdmin,
            isProvider,
            clinic,
            clinicId,
            hasSubscription,
            vetCheckStatus: state.entities.clinicVetCheckStatus?.[clinicId],
            showChangeVetCheckPassword: state.entities.showChangeVetCheckPassword,
            // Permissions
            canViewClinicVetCheck,
            canViewPaymentSettings,
            canEditClinicVetCheck,
            canViewProviderBiGamesClinicInvites,
            canViewProviderBiGamesDrilldown,
            canViewVetCheckMapping
        }
    },
    (dispatch) => ({
        setShowChangeVetCheckPassword: (show) => dispatch(VetCheckActions.setShowChangeVetCheckPassword(show)),
    })
)(VetCheck);
