exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".GameDetailsForm__root .GameDetailsForm__email{position:relative}.GameDetailsForm__root .GameDetailsForm__email .GameDetailsForm__delete{position:absolute;top:20%;right:10px}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/provider/widgets/GameDetailsForm.scss"],"names":[],"mappings":"AAAA,+CAAa,iBAAiB,CAAC,wEAAqB,kBAAkB,QAAQ,UAAU,CAAC","file":"GameDetailsForm.scss","sourcesContent":[".root .email{position:relative}.root .email .delete{position:absolute;top:20%;right:10px}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "GameDetailsForm__root",
	"email": "GameDetailsForm__email",
	"delete": "GameDetailsForm__delete"
};