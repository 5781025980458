import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Dropdown.scss";
import find from "lodash/find";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import map from "lodash/map";
import reject from "lodash/reject";
import CheckboxInput from "components/common/CheckboxInput";
import Tooltip from "components/common/Tooltip";
import * as Position from "constants/TooltipPositionIds";

export default function MultiSelectDropdown(props) {
    const [showOptions, setShowOptions] = useState(false);

    const handleShowOptions = (e) => {
        e.stopPropagation();
        setShowOptions(true);
    }
    const handleCloseOptions = (e) => {
        e.stopPropagation();
        setShowOptions(false);
    }

    const onOptionClicked = (e, name, value) => {
        e.stopPropagation();
        e.preventDefault();

        let tempValues = props.value ? [...props.value] : [];
        if(value === "all") {
            if(props.value && props.options && (props.value.length === props.options.length)) {
                tempValues = [];
            } else {
                forEach(props.options, opt => {
                    if(!includes(props.value, opt.value)) {
                        tempValues.push(opt.value)
                    }
                })
            }
        } else {
            if(includes(props.value, value)) {
                tempValues = reject(props.value, v => { return v === value});
            } else {
                tempValues.push(value);
            }
        }

        props.onChange({
            name: props.name,
            value: tempValues,
        });
    }

    const options = map(props.options, (option) => {
        return (
            <div
                key={option.value}
                className={classnames(styles.option, {
                    [styles.selected]: includes(props.value, option.value),
                })}
                onClick={(e) => onOptionClicked(e, option.name, option.value)}
            >
                <CheckboxInput
                    small
                    name=""
                    checked={includes(props.value, option.value)}
                    onChange={() => {}}
                />
                {option.name}
            </div>
        );
    });

    const getString = () => {
        let first = true;
        let str = "";

        if(props.options.length) {

            forEach(props.value, val => {
                const name = find(props.options, o => {return o.value.toString() === val.toString()})?.name;
                if (first) {
                    str = name;
                } else {
                    str = str + ", " + name;
                }
                first = false;
            })
        }

        return str;
    }

    const selectedValueName = (
        !props.value || !props.value.length
    ) ? (
        `${props.placeholder || "None Selected"}`
    ) : (
        props.value.length > 3
    ) ? (
        `${props.value.length} Selected`
    ) : (
        getString()
    );

    return (
        <div className={classnames("form-group", {
            "aligned left": props.alignedLeft,
            "aligned split": props.alignedSplit,
        })}>
            {props.label && (
                <label className="flex">
                    {!!props.information && (
                        <div className="flex-none">
                            <Tooltip position={props.informationPosition || Position.RIGHT} tip={props.information}>
                                <i className="fa fa-info-circle margin-right-x-sm"/>
                            </Tooltip>
                        </div>
                    )}
                    <div className="flex-1">{props.label}</div>
                </label>
            )}
            <div
                className={classnames(styles.root, {
                    [styles.small] : props.small,
                    [styles.fullWidth] : props.fullWidth,
                    [styles.wide] : props.wide,
                })}
                tabIndex={-1}
                onFocus={(data) => props.disabled ? {} : handleShowOptions(data)}
                onBlur={handleCloseOptions}
            >
                <div className={classnames(styles.selectBox, {
                    [styles.disabled]: (!!props.disabled),
                })}>
                    {selectedValueName && (
                        <span className={classnames({
                            [styles.placeholder]: (!!props.placeholder && !props.value),
                        })}>
                            {selectedValueName}
                        </span>
                    )}
                    {!selectedValueName && props.placeholder && <span className={styles.placeholder}>{props.placeholder}</span> }
                </div>
                <div className={styles.caretArrow}><i className="fas fa-caret-down" /></div>
                {!!showOptions && !props.disabled && (
                    <div className={styles.options}>
                        <div
                            className={classnames(styles.option, styles.all, {
                                [styles.selected]: (props.value && props.options && (props.value.length === props.options.length)),
                            })}
                            onClick={(e) => onOptionClicked(e, "Select All", "all")}
                        >
                            <CheckboxInput
                                small
                                name=""
                                checked={props.value && props.options && (props.value.length === props.options.length)}
                                onChange={() => {}}
                                disabled={props.disabled}
                            />
                            Select All
                        </div>
                        {options}
                    </div>
                )}
            </div>
        </div>
    );
}

MultiSelectDropdown.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.any,
    })),
    value: PropTypes.any,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    invalid: PropTypes.bool,
    toolTip: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    small: PropTypes.bool,
    wide: PropTypes.bool,
    fullWidth: PropTypes.bool,
};
