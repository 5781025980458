import React from "react";
import styles from "./BIProductDisclosures.scss";
import classnames from "classnames";

export default function BiProductDisclosures(props) {
    return (
        <div
            data-testid="bi_product_disclosures_component"
            className={classnames(styles.root, "full-width full-height flex flex-column text-notice")}
        >
            <div className="flex full-width column-to-row-sm">
                <div className={styles.item}>
                    <b>IMPORTANT SAFETY INFORMATION</b>: HEARTGARD® Plus (ivermectin/pyrantel) is well tolerated.
                    All dogs should be tested for heartworm infection before starting a preventive program.
                    Following the use of HEARTGARD® Plus (ivermectin/pyrantel), digestive and neurologic side effects have rarely been reported.
                    For more information, please see <a
                        className="text-primary"
                        href="https://cdn.greenlinepet.com/games/assets/prescribinginfo/HGPlus_PI_2022.pdf"
                        target="_blank"
                    >
                        full prescribing information
                    </a> or visit <a
                        href="https://www.heartgardclinic.com/"
                        target="_blank"
                        className="text-primary"
                    >
                        www.HeartgardClinic.com
                    </a>.
                </div>

                <div className={styles.item}>
                    <b>IMPORTANT SAFETY INFORMATION</b>:
                    <i> NexGard</i>® COMBO (esafoxolaner, eprinomectin, and praziquantel topical solution) is for topical use only in cats.
                    Use with caution in cats with a history of seizures or neurologic disorders.
                    The most frequently reported adverse reactions include vomiting, application site reactions, lethargy, and anorexia.
                    If ingested, hypersalivation may occur.
                    Avoid direct contact with application site until visibly dry.
                    For more information, see <a
                        className="text-primary"
                        href="https://cdn.greenlinepet.com/games/assets/prescribinginfo/NexGard_COMBO_PI_2023.pdf"
                        target="_blank"
                    >
                        full prescribing information
                    </a> or visit <a
                        className="text-primary"
                        href="https://nexgardcomboclinic.com/"
                        target="_blank"
                    >
                        NexGardCOMBOClinic.com
                    </a>.
                </div>
                <div className={styles.item}>
                    <b className="text-uppercase">Important Safety Information</b>:
                    <i> NexGard®</i> PLUS (afoxolaner, moxidectin, and pyrantel chewable tablets) is for use in dogs only.
                    The most frequently reported adverse reactions include diarrhea, vomiting, lethargy, and itching.
                    Use with caution in dogs with a history of seizures or neurologic disorders.
                    Dogs should be tested for existing heartworm infection prior to starting a preventive.
                    For more information or <a
                        className="text-primary"
                        href="https://cdn.greenlinepet.com/games/assets/prescribinginfo/NexGard_PLUS_PI_2023.pdf"
                        target="_blank"
                    >
                        full prescribing information
                    </a>, speak with your vet, call <a
                        className="text-primary"
                        href="tel:+1-888-637-4251"
                    >
                        888-637-4251
                    </a> or visit <a
                        className="text-primary"
                        href="https://www.nexgardplus.com/"
                        target="_blank"
                    >
                        NexGardPLUS.com
                    </a>.
                </div>
                <div className={styles.item}>
                    <b>IMPORTANT SAFETY INFORMATION</b>:
                    <i> NexGard</i>® (afoxolaner), is for use in dogs only. Use with caution in
                    dogs with a history of seizures or neurologic disorders.
                    For more information or <a
                        className="text-primary"
                        href="https://cdn.greenlinepet.com/games/assets/prescribinginfo/NexGard_PI_2020.pdf"
                        target="_blank"
                    >
                        full prescribing information
                    </a>, speak with your vet, call 888-637-4251 or visit <a
                        className="text-primary"
                        href="https://nexgardclinic.com/"
                        target="_blank"
                    >
                        NexGardClinic.com
                    </a>.
                </div>
            </div>
            <div className="full-width margin-top-sm">
                <b>
                    HEARTGARD® Plus (ivermectin/pyrantel) and the Dog &amp; Hand logo® are registered trademarks of Boehringer Ingelheim Animal Health USA Inc.
                    <i> NexGard</i>® (afoxolaner) is a registered trademark of Boehringer Ingelheim Animal Health France, used under license.
                    All other trademarks are the property of their respective owner.
                    ©2023 Boehringer Ingelheim Animal Health USA Inc., Duluth, GA.
                    All rights reserved.
                    US-PET-0456-2023
                </b>
            </div>
        </div>
    );
}
