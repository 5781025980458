import React from "react";
import { useLocation, useParams } from "react-router-dom";
import includes from "lodash/includes";
import styles from "./ManageInvites.scss";
import ManageInvitesTableWraper from "./ManageInvitesTableWraper";
import ProviderBase from "components/layout/ProviderBase";
import SelectNodeUserWrapper from "components/provider/widgets/SelectNodeUserWrapper";
import * as SearchTypes from "constants/SearchTypes";

export default function ManageInvites(props) {
    const location = useLocation();
    const params = useParams();
    const nodeId = Number(params?.nodeId||props.nodeId);
    const providerId = 1; // Hardcoded to BI since it's BI Games
    const isDemo = includes(location?.pathname, "/demo");

    return (
        <ProviderBase
            location={location}
            searchConfig={SearchTypes.PROVIDER_CLINICS_SEARCH}
            pageTitle="Greenline Games™"
            collapsible
            nodeId={nodeId}
            providerId={providerId}
        >
            <div className={styles.root}>
                <h2 className="margin-bottom-x-sm">{isDemo && "Demo "}Manage Invites</h2>
                <div className="flex-1">
                    <SelectNodeUserWrapper>
                        <ManageInvitesTableWraper />
                    </SelectNodeUserWrapper>
                </div>
            </div>
        </ProviderBase>
    )
}