import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import Button from "components/common/Button";
import PropTypes from "prop-types";
import * as UserPermissions from "constants/UserPermissions";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import * as ProviderLinks from "utils/ProviderLinks";

function ClinicMarkerInfoWindow(props) {
    const history = useHistory();

    const { clinic } = props;

    if (!clinic) {
        return null;
    }

    const handleClick = () => {
        props.onRedemptionsClick(props.clinic);
    };

    const handleGoToGames = () => {
        history.push(ProviderLinks.getLinkLocation("games", {
            providerId: props.providerId,
            nodeId: props.nodeId,
            isAdmin: props.isAdmin,
        }));
    }

    const handleGoToCouponLibrary = () => {
        if (props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("libraryProgram", clinic?.clinicId, props.providerId));
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("libraryProgram", props.nodeId, clinic?.clinicId, props.providerId));
        }
    }

    return (
        <div className="full-width full-height">
            <div className="text-bold">{clinic?.name}</div>
            <div className="full-width flex justify-between" style={{ fontSize: "0.8em" }}>
                <div
                    className="margin-right-md"
                    style={{ whiteSpace: "nowrap" }}
                >
                    {`${clinic?.city || ""}, ${clinic?.state || ""} ${clinic?.zipCode || ""}`}
                </div>
                {(props.showAccountNumber && clinic?.accountNumber) && <div>Account# {clinic?.accountNumber}</div>}
            </div>
            {props.canViewGames && (
                <div className="full-width margin-bottom-sm margin-top-sm">
                    <div className="flex full-width padding-xs" style={{ backgroundColor: "#271", color: "white" }}>
                        <span className="flex-1 text-center">Active</span>
                        <span className="flex-1 text-center">Pending</span>
                        <span className="flex-1 text-center">Games</span>
                    </div>
                    <div className="flex full-width margin-top-x-sm">
                        <span className="flex-1 text-center">{clinic?.activeOffers || 0}</span>
                        <span className="flex-1 text-center">{clinic?.couponsPending || 0}</span>
                        <span className="flex-1 text-center">{clinic?.currentGames || 0}</span>
                    </div>
                </div>
            )}
            <div className="full-width flex align-center justify-between">
                <div className="flex spaced-content margin-right-md">
                    {(props.canViewGames && props.nodeOverview?.hasGames) && (
                        <Button
                            onClick={handleGoToGames}
                            type="primary"
                            text
                        >
                            <i className="fa fa-trophy-alt" />
                        </Button>
                    )}
                    {(props.showCouponLibraryLink && props.canViewCouponLibrary) && (
                        <Button
                            onClick={handleGoToCouponLibrary}
                            type="primary"
                            text
                        >
                            <i className="fa fa-scissors" />
                        </Button>
                    )}
                </div>
                {props.canViewRedemptions && (
                    <div>
                        <Button
                            small
                            onClick={handleClick}
                        >
                            Redemptions
                    </Button>
                    </div>
                )}
            </div>
        </div>
    );
}

ClinicMarkerInfoWindow.propTypes = {
    clinic: PropTypes.object.isRequired,
    onRedemptionsClick: PropTypes.func.isRequired,
    showCouponLibraryLink: PropTypes.bool,
    showAccountNumber: PropTypes.bool,
    providerId: PropTypes.number.isRequired,
    nodeId: PropTypes.number,
    isAdmin: PropTypes.bool,
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const nodeOverview = state.entities.providerHierarchies?.[ownProps.providerId]?.[ownProps.nodeId];

        // Permissions
        const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewGames = canAdminProviderBiGames || canViewProviderBiGames;
        const canViewCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewRedemptions = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_REDEMPTION_SUMMARY, userProfile);

        return {
            userProfile,
            nodeOverview,

            // Permissions
            canViewGames,
            canViewCouponLibrary,
            canViewRedemptions,
        };
    }
);

export default compose(
    withRouter,
    connector
)(ClinicMarkerInfoWindow);
