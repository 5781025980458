import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./OnGardThermometer.scss";
import { map } from "lodash";
import { ON_GARD_TIERS } from "constants/OnGardGameTierData";

const STAMP_IMAGE = require("../../../../../img/ongard-stamp.png");

export default function OnGardThermometer(props) {
    const tiers = ON_GARD_TIERS;
    const currentPoints = props.currentPoints || 0;
    const totalPoints = tiers[tiers.length - 1].points;
    const progressWidth = Math.min(Math.floor(currentPoints / totalPoints * 100), 100);

    const renderPoint = (tier, isLast) => {
        const xPerc = Math.floor(tier.points / totalPoints * 100);
        return (
            <div
                key={`point_${tier.points}`}
                className={classnames(styles.point, {
                    [styles.isMet]: currentPoints >= tier.points,
                    [styles.end]: isLast,
                })}
                style={{ left: `${xPerc}%` }}
            >
                <div className={styles.pointCircle}>
                    <div className={styles.inner} />
                </div>
                <div className={styles.pointInfo}>
                    {tier.points.toLocaleString()}
                </div>
                <div className={styles.pointFlyout}>
                    {tier.name}
                </div>
            </div>
        );
    };

    return (
        <div className={styles.root}>
            <div className={styles.stamp}>
                <img src={STAMP_IMAGE} />
            </div>
            <div className={styles.barContainer}>
                <div className={styles.bar} />
                <div className={styles.goal} />
                <div
                    className={styles.progress}
                    style={{ width: `${progressWidth}%` }}
                />
                <div className={styles.points}>
                    {map(tiers, (tier, index) => renderPoint(tier, index === tiers.length - 1))}
                </div>
            </div>
        </div>
    );
}

OnGardThermometer.propTypes = {
    currentPoints: PropTypes.number,
};
