import React, {useMemo} from "react";
import PropTypes from "prop-types";
import styles from "./WellnessVisitAccordionList.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import flatten from "lodash/flatten";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import reduce from "lodash/reduce";
import sumBy from "lodash/sumBy";
import WellnessVisitTable from "components/wellness/widgets/WellnessVisitTable";
import classnames from "classnames";
import {connect} from "react-redux";
import AccordionSection from "components/common/AccordionSection";
import Accordion from "components/common/Accordion";
import { GREENLINE_WELLNESS } from "constants/ProviderIds";

function WellnessVisitAccordionList(props) {

	const visitsByVet = useMemo(() => reduce(groupBy(props.visits, "dvmId"),(memo, visits, dvmId) => {
		memo[dvmId] = {
			dvmId: visits[0].dvmId,
			visits
		};
		return memo;
	}, {}), [props.visits]);
	const vets = map(props.dvms, (dvm) => ({name: `${dvm.firstName} ${dvm.lastName} (${dvm.pimsUserRemoteDisplayId})`, value: dvm.id}));

	const selectedVisitsByDvmId = reduce(props.selectedRows, (memo, rowId) => {
		const visit = find(props.visits, {id: rowId});
		if (visit) {
			memo[visit.dvmId] = [
				...(memo[visit.dvmId] || []),
				rowId,
			];
		}
		return memo;
	}, {});

	const handleRowSelectChange = (dvmId, rowIds) => {
		const newRowIds = {
			...selectedVisitsByDvmId,
			[dvmId]: rowIds,
		};
		props.onRowSelectChange(flatten(Object.values(newRowIds)));
	};

    const allProviders = props.providerList[props.clinicId];
    const biDemoProvider = filter(allProviders, p => p.id === 69); // Greenline Wellness
    const wellnessProviders = props.isBiWellnessDemo ? biDemoProvider : allProviders;

    const sectionJsx = map(wellnessProviders, (provider) => {
		const providerHeader = (
			<div className={styles.providerHeader}>
				{provider.providerName}
			</div>
		);

		const providerVisits = filter(props.visits, {providerId: provider.providerId});

		const sectionHeader = (
			<div className="flex flex-centered spaced-content">
				{wellnessProviders && (
					<div className="flex-1">
						<img src={provider.mediumImageURI} alt={provider.providerName} />
					</div>
				)}
			</div>
		);

		return (
			wellnessProviders && (
				<AccordionSection
					key={provider.providerId}
					id={provider.providerId}
					header={sectionHeader}
					headerSubtext={providerHeader}
				    onClick={() => {}}
				>
					{providerVisits.length && (
						<div>
							{map(orderBy(visitsByVet, ['dvmFirstName', 'dvmLastName'], ['asc', 'asc']), (vet, index) => {
									const tableHeader = !!vet.dvmId ? `${vet.visits[0].dvmFirstName} ${vet.visits[0].dvmLastName}` : "No D.V.M.";
									const addCommission = sumBy(vet.visits, "commissionAmount");
									const selectedRowIds = selectedVisitsByDvmId[vet.dvmId];
									const vetProviderVisits = filter(providerVisits, { dvmId: vet.dvmId });
									const total = new Intl.NumberFormat("en-US", {style: 'currency', currency: "USD"}).format(addCommission);
									return (
										<div key={index}
										     className={classnames({
											     [styles.noDVM]: !vet.dvmId,
											     [styles.historical]: !!props.historical,
										     })}
										>
											<h3 className="margin-left-sm">{tableHeader}</h3>
											<WellnessVisitTable
												clinicId={props.clinicId}
												visits={vetProviderVisits}
												selectedRowIds={selectedRowIds}
												onRowSelectChange={(rowIds) => handleRowSelectChange(vet.dvmId, rowIds)}
												onEditClick={props.onEditClick}
												noDVM={!vet.dvmId}
												onDVMChanged={props.onDVMChanged}
												vets={orderBy(vets, ["name"])}
												historical={props.historical}
											/>
											<div
												className="text-right text-uppercase margin-right-lg margin-top-sm margin-bottom-md"
											>
												Total Commission <span className="margin-left-sm">{total}</span>
											</div>
										</div>
									);
								}
							)}
						</div>
					)}
				</AccordionSection>
			)
		);
	});

	return (
		<>
			{wellnessProviders ? (
				<Accordion
					theme={{
						root: styles.root,
						header: styles.header,
						collapse: styles.collapse,
						content: styles.collapseContent,
					}}
					defaultOpen={[GREENLINE_WELLNESS]}
					onChange={props.onSelect ? props.onSelect : () => {}}
				>
					{sectionJsx}
				</Accordion>
			) : (
				<div className={styles.noResults}>
					No Results
				</div>
			)}
		</>
	);
}

WellnessVisitAccordionList.propTypes = {
	visits: PropTypes.arrayOf(PropTypes.object),
	onDVMChanged: PropTypes.func,
	historical: PropTypes.bool,
	clinicId: PropTypes.number.isRequired,
	providerList: PropTypes.arrayOf(
		PropTypes.shape({
			providerId: PropTypes.number,
			providerName: PropTypes.string,
			mediumImageURI: PropTypes.string,
		})
	),

	// Row menu click handlers
    onRowSelectChange: PropTypes.func,
    isBiWellnessDemo: PropTypes.bool,
};

export default connect(
	(state, ownProps) => ({
		state,
		dvms: filter(state.entities.dvms, dvm => (dvm.clinicId === ownProps.clinicId && dvm.isActive)),
	})
)(WellnessVisitAccordionList);
