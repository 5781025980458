import React from "react";
import {compose} from "redux";
import {withRouter} from "react-router";
import styles from "./WellnessAudit.scss";
import AdminBase from "components/layout/AdminBase";
import Button from "components/common/Button";
import ClinicInfoHeader from "components/admin/elements/ClinicInfoHeader";
import UnprocessedWellnessVisits from "../widgets/UnprocessedWellnessVisits";
import * as SearchTypes from "constants/SearchTypes";
import {getLinkLocation} from "utils/ClinicAdminLinks";
import AccessDenied from "components/common/AccessDenied";
import {connect} from "react-redux";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import ClinicBase from "../../layout/ClinicBase";

function AdminWellnessAudit(props)  {
    return (
        <AdminBase
            clinicId={props.clinicId}
            searchConfig={SearchTypes.WELLNESS_VISITS}
            docTitle={"Wellness Audit"}
        >
            <div className={styles.root}>
                <ClinicInfoHeader clinicId={props.clinicId} />
                <div className={styles.topLine}>
                    <h2>Wellness Audit</h2>
                    <div className={styles.filter}>
                        <Button onClick={() => props.history.push(getLinkLocation("wellnessHistory", props.clinicId))}>Wellness History</Button>
                    </div>
                </div>
                <div className="flex-1">
                    {props.canViewClinicWellnessAudit ? (
                        <UnprocessedWellnessVisits
                            clinicId={props.clinicId}
                        />
                    ) : (
                        <AccessDenied/>
                    )}
                </div>
            </div>
        </AdminBase>
    )
}

const connector = connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        return {
            clinicId,
            canViewClinicWellnessAudit
        }
    }
);

export default compose(
    withRouter,
    connector
)(AdminWellnessAudit);

