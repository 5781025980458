exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Loader__root{position:absolute;top:0;left:0;width:100%;height:100%}.Loader__root .Loader__inner{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.Loader__root .Loader__inner .Loader__image{margin:0 auto;display:block}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/common/Loader/Loader.scss"],"names":[],"mappings":"AAAA,cAAM,kBAAkB,MAAM,OAAO,WAAW,WAAW,CAAC,6BAAa,kBAAkB,QAAQ,SAAS,+BAA+B,CAAC,4CAAoB,cAAc,aAAa,CAAC","file":"Loader.scss","sourcesContent":[".root{position:absolute;top:0;left:0;width:100%;height:100%}.root .inner{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.root .inner .image{margin:0 auto;display:block}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "Loader__root",
	"inner": "Loader__inner",
	"image": "Loader__image"
};