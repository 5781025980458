exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PuppyCycling__cls1{fill:#4b5463}.PuppyCycling__cls1,.PuppyCycling__cls2,.PuppyCycling__cls3,.PuppyCycling__cls4,.PuppyCycling__cls5,.PuppyCycling__cls6,.PuppyCycling__cls7,.PuppyCycling__cls8,.PuppyCycling__cls9,.PuppyCycling__cls10,.PuppyCycling__cls11,.PuppyCycling__cls12,.PuppyCycling__cls13,.PuppyCycling__cls14{stroke-width:0}.PuppyCycling__cls2{fill:#ffc200}.PuppyCycling__cls3{fill:#00185e}.PuppyCycling__cls4{fill:#65676e}.PuppyCycling__cls5{fill:#0b0808}.PuppyCycling__cls6{fill:#1b75bc}.PuppyCycling__cls7{fill:#b76c00}.PuppyCycling__cls8{fill:#000}.PuppyCycling__cls9{fill:#42444a}.PuppyCycling__cls10{opacity:.3}.PuppyCycling__cls10,.PuppyCycling__cls12{fill:#231f20}.PuppyCycling__cls11{fill:#fff}.PuppyCycling__cls13{fill:#ccc7c6}.PuppyCycling__cls14{fill:#04456f}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/GoldenSummerGames/PuppyCycling.scss"],"names":[],"mappings":"AAAA,oBAAM,YAAY,CAAC,6RAAyF,cAAc,CAAC,oBAAM,YAAY,CAAC,oBAAM,YAAY,CAAC,oBAAM,YAAY,CAAC,oBAAM,YAAY,CAAC,oBAAM,YAAY,CAAC,oBAAM,YAAY,CAAC,oBAAM,SAAS,CAAC,oBAAM,YAAY,CAAC,qBAAO,UAAU,CAAC,0CAAc,YAAY,CAAC,qBAAO,SAAS,CAAC,qBAAO,YAAY,CAAC,qBAAO,YAAY,CAAC","file":"PuppyCycling.scss","sourcesContent":[".cls1{fill:#4b5463}.cls1,.cls2,.cls3,.cls4,.cls5,.cls6,.cls7,.cls8,.cls9,.cls10,.cls11,.cls12,.cls13,.cls14{stroke-width:0}.cls2{fill:#ffc200}.cls3{fill:#00185e}.cls4{fill:#65676e}.cls5{fill:#0b0808}.cls6{fill:#1b75bc}.cls7{fill:#b76c00}.cls8{fill:#000}.cls9{fill:#42444a}.cls10{opacity:.3}.cls10,.cls12{fill:#231f20}.cls11{fill:#fff}.cls13{fill:#ccc7c6}.cls14{fill:#04456f}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cls1": "PuppyCycling__cls1",
	"cls2": "PuppyCycling__cls2",
	"cls3": "PuppyCycling__cls3",
	"cls4": "PuppyCycling__cls4",
	"cls5": "PuppyCycling__cls5",
	"cls6": "PuppyCycling__cls6",
	"cls7": "PuppyCycling__cls7",
	"cls8": "PuppyCycling__cls8",
	"cls9": "PuppyCycling__cls9",
	"cls10": "PuppyCycling__cls10",
	"cls11": "PuppyCycling__cls11",
	"cls12": "PuppyCycling__cls12",
	"cls13": "PuppyCycling__cls13",
	"cls14": "PuppyCycling__cls14"
};