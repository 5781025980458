
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { filter, map } from "lodash";
import * as ClinicActions from "actions/ClinicActions";
import * as UserActions from "actions/UserActions";
import SortableDataTable from "components/common/SortableDataTable";
import Modal from "components/common/Modal";
import ClinicUserForm from "./ClinicUserForm";
import styles from "components/common/SortableDataTable.scss";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import PropTypes from "prop-types";
import AccessDenied from "components/common/AccessDenied";

const COLUMNS = [{
    name: "First Name",
    selector: "firstName",
    key: "firstName",
    sortable: true,
    searchable: true,
}, {
    name: "Last Name",
    selector: "lastName",
    key: "lastName",
    sortable: true,
    searchable: true,
}, {
    name: "Email",
    selector: "email",
    key: "email",
    sortable: true,
    searchable: true,
}, {
    name: "Roles",
    selector: "roles",
    key: "roles",
    sortable: true,
    format: row => (
        <div className="text-sm">
            {row.role ? row.role : "None"}
        </div>
    ),
}, {
    name: "Can Login",
    selector: "canLogin",
    key: "canLogin",
    sortable: true,
    format: row => (
        <div className="text-center">
            {row.canLogin ? (
                <span className="fa-lg text-success">
                    <i className="fas fa-check-circle" title="User can login to the portal" />
                </span>
            ) : (
                <span className="fa-lg text-danger">
                    <i className="fas fa-times-circle" title="User cannot login to the portal" />
                </span>
            )}
        </div>
    ),
}];

function ClinicUsersTable(props) {
    const [editingClinicUser, setEditingClinicUser] = useState(null);
    const [showCreateUser, setShowCreateUser] = useState(false);
    const [userList, setUserList] = useState([]);

    const clinicId = props.clinicId || props.userProfile.clinics[0].id;

    useEffect(() => {
        if (!props.roleIds && props.canViewUserManagement) {
            props.getClinicUserRoleIds();
        }
    }, []);

    useEffect(() => {
        if (clinicId && props.canViewUserManagement) {
            props.loadClinicUsers(clinicId);
        }
    }, [clinicId, props.canViewUserManagement]);

    useEffect(() => {
        setUserList(filterByText());
    }, [props.search, props.clinicUsers]);

    const handleRowClick = (row) => {
        if(props.canEditUserManagement || props.canViewUserManagement) {
            setEditingClinicUser(row);
        }
    };

    const filterByText = () => {
        if (!props.search) {
            return map(props.clinicUsers, user => user); // Convert to array
        }

        const search = props.search.toLowerCase();

        return filter(props.clinicUsers, user => user.userId.toString().includes(search) ||
                (user.firstName && user.firstName.toLowerCase().includes(search)) ||
                (user.lastName && user.lastName.toLowerCase().includes(search)) ||
                user.email.toLowerCase().includes(search)
        );
    }

    const handleUpdateUser = (userUpdates) => {
        const updatedUser = {
            ...editingClinicUser,
            ...userUpdates,
        };

        props.updateClinicUser(clinicId, updatedUser);
        setEditingClinicUser(null);
    };

    const handleCreateUser = (user) => {
        if (props.canCreateUserManagement) {
            props.createClinicUser(clinicId, user);
            setShowCreateUser(false);
        }
    };

    if (!clinicId) {
        return (
           <SpinnerTakeover show/>
        )
    }

    if (userList.length === 0) {
        return(
            <div className={styles.noUsers}>
                <span>No Users to show! Try changing your search.</span>
            </div>
        )
    }

    if(!props.canViewUserManagement) {
        return <AccessDenied/>;
    }

    return (
        <>
            <SortableDataTable
                title="Clinic Users"
                columns={COLUMNS}
                rawData={userList}
                onClick={(props.canEditUserManagement || props.canViewUserManagement) ? handleRowClick : null}
                allowSearch
                hideSearchBar
                resultsPerPage={15}
                onCreateClick={props.canCreateUserManagement ? () => setShowCreateUser(true) : null}
            />
            <Modal
                show={!!editingClinicUser}
                onClose={() => setEditingClinicUser(null)}
                small
            >
                <h2 className="margin-bottom-sm">Edit User</h2>
                <ClinicUserForm
                    initialData={editingClinicUser}
                    roleIds={props.roleIds}
                    onSubmit={handleUpdateUser}
                    onCancel={() => setEditingClinicUser(null)}
                />
            </Modal>
            <Modal
                show={showCreateUser}
                onClose={() => setShowCreateUser(false)}
                clickOutsideToClose={false}
                small
            >
                {props.canCreateUserManagement ? (
                    <>
                        <h2 className="margin-bottom-sm">Add New User</h2>
                        <ClinicUserForm
                            create
                            roleIds={props.roleIds}
                            onSubmit={handleCreateUser}
                            onCancel={() => setShowCreateUser(false)}
                        />
                    </>
                ) : <AccessDenied/>}
            </Modal>
            <SpinnerTakeover show={!props.roleIds} />
        </>
    );
}

ClinicUsersTable.propTypes = {
    clinicId: PropTypes.number,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);
        const canCreateUserManagement = userHasPermission(PermissionTypes.CREATE, UserPermissions.USER_MANAGEMENT, userProfile);
        const canEditUserManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.USER_MANAGEMENT, userProfile);
        const canDeleteUserManagement = userHasPermission(PermissionTypes.DELETE, UserPermissions.USER_MANAGEMENT, userProfile);
        return {
            userProfile,
            canViewUserManagement,
            canCreateUserManagement,
            canEditUserManagement,
            canDeleteUserManagement,
            clinicUsers: state.entities.clinicUsers,
            roleIds: state.entities.users.roleIds,
            search: state.entities.users.query,
        }
    },
    (dispatch) => ({
        loadClinicUsers: (clinicId) => dispatch(ClinicActions.getClinicUsers(clinicId)),
        updateClinicUser: (clinicId, clinicUserUpdate) => dispatch(ClinicActions.updateClinicUser(clinicId, clinicUserUpdate)),
        createClinicUser: (clinicId, clinicUser) => dispatch(ClinicActions.createClinicUser(clinicId, clinicUser)),
        getClinicUserRoleIds: () => dispatch(UserActions.getClinicUserRoleIds()),
    })
)(ClinicUsersTable);
