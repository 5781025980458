import React from "react";
import {
	HELMET_STRIPE,
	HELMET_HOLE,
	HELMET_BG,
	JERSEY_STRIPE,
	JERSEY_SLEEVE,
	JERSEY_BG,
	TEAM_COLORS
} from "constants/Games/PetsBowl/UniformColors";
import styles from "./PuppyDancing.scss";


export default function PuppyDancing(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			// xmlns:xlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 827.76 461.09"
		>
		    {/*<defs>*/}
		        {/*<mask id="mask" x="20.74" y="16.07" width="372.4" height="445.03" maskUnits="userSpaceOnUse">*/}
		        {/*    <g id="Mask_Mask_1" data-name="Mask Mask 1">*/}
		        {/*        <g id="Mask_2_MASK_0_FILL" data-name="Mask 2 MASK 0 FILL">*/}
		        {/*            <path className={styles.cls15} d="m20.74,16.09v445h372.4V16.09H20.74Z"/>*/}
		        {/*        </g>*/}
		        {/*    </g>*/}
		        {/*</mask>*/}
		        {/*<mask id="mask-1" x="20.74" y="16.07" width="372.4" height="445.03" maskUnits="userSpaceOnUse">*/}
		        {/*    <g id="Mask_Mask_2" data-name="Mask Mask 2">*/}
		        {/*        <g id="Mask_FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_2_MASK_0_FILL" data-name="Mask FlashAICB Assets 1 Layer 1  Clip Group  0 0 2 MASK 0 FILL">*/}
		        {/*            <path className={styles.cls15} d="m20.74,16.09v445h372.4V16.09H20.74Z"/>*/}
		        {/*        </g>*/}
		        {/*    </g>*/}
		        {/*</mask>*/}
		        {/*<mask id="mask-2" x="20.74" y="411.84" width="372.4" height="49.25" maskUnits="userSpaceOnUse">*/}
		        {/*    <g id="Mask_Mask_3" data-name="Mask Mask 3">*/}
		        {/*        <g id="Mask_FlashAICB_Assets_1_Layer_1_Clip_Group_1_0_2_MASK_0_FILL" data-name="Mask FlashAICB Assets 1 Layer 1  Clip Group  1 0 2 MASK 0 FILL">*/}
		        {/*            <path className={styles.cls15} d="m393.14,461.09v-49.25H20.74v49.25h372.4Z"/>*/}
		        {/*        </g>*/}
		        {/*    </g>*/}
		        {/*</mask>*/}
		        {/*<mask id="mask-3" x="434.62" y="4.84" width="372.4" height="456.25" maskUnits="userSpaceOnUse">*/}
		        {/*    <g id="Mask_Mask_1-2" data-name="Mask Mask 1">*/}
		        {/*        <g id="Mask_2_MASK_0_FILL-2" data-name="Mask 2 MASK 0 FILL">*/}
		        {/*            <path className={styles.cls15} d="m434.62,4.84v456.25h372.4V4.84h-372.4Z"/>*/}
		        {/*        </g>*/}
		        {/*    </g>*/}
		        {/*</mask>*/}
		        {/*<mask id="mask-4" x="434.62" y="4.84" width="372.4" height="456.25" maskUnits="userSpaceOnUse">*/}
		        {/*    <g id="Mask_Mask_2-2" data-name="Mask Mask 2">*/}
		        {/*        <g id="Mask_FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_2_MASK_0_FILL" data-name="Mask FlashAICB Assets 2 Layer 1  Clip Group  0 0 2 MASK 0 FILL">*/}
		        {/*            <path className={styles.cls15} d="m434.62,4.84v456.25h372.4V4.84h-372.4Z"/>*/}
		        {/*        </g>*/}
		        {/*    </g>*/}
		        {/*</mask>*/}
		        {/*<mask id="mask-5" x="434.62" y="411.84" width="372.4" height="49.25" maskUnits="userSpaceOnUse">*/}
		        {/*    <g id="Mask_Mask_3-2" data-name="Mask Mask 3">*/}
		        {/*        <g id="Mask_FlashAICB_Assets_2_Layer_1_Clip_Group_1_0_2_MASK_0_FILL" data-name="Mask FlashAICB Assets 2 Layer 1  Clip Group  1 0 2 MASK 0 FILL">*/}
		        {/*            <path className={styles.cls15} d="m807.02,461.09v-49.25h-372.4v49.25h372.4Z"/>*/}
		        {/*        </g>*/}
		        {/*    </g>*/}
		        {/*</mask>*/}
		    {/*</defs>*/}
			{/* TODO: update the styles. */}
		    <g className={styles.cls1}>
		        <g className={styles.cls6}>
		            <g>
		                <g className={styles.cls3}>
		                    <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_1_0_Layer2_0_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  1 0 Layer2 0 FILL">
		                        <path className={styles.cls8} d="m338.59,419.04c-36.3-4.8-80.18-7.2-131.65-7.2s-95.35,2.4-131.65,7.2c-36.37,4.8-54.55,10.62-54.55,17.45s18.18,12.6,54.55,17.4c36.33,4.8,80.22,7.2,131.65,7.2s95.32-2.4,131.65-7.2c36.37-4.8,54.55-10.6,54.55-17.4s-18.18-12.65-54.55-17.45Z"/>
		                    </g>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_1_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 1 FILL">
		                    <path className={styles.cls15} d="m181.99,299.79c-5.77-2.33-12.6-2.97-20.5-1.9-7.6,1.03-15,3.47-22.2,7.3-7.43,3.97-13.43,8.82-18,14.55-5,6.27-7.67,12.88-8,19.85-.6,12.93,3.72,25.32,12.95,37.15,2,2.6,5.58,6.67,10.75,12.2,3.93,4.23,6.4,7.35,7.4,9.35.77,1.5.88,4.2.35,8.1-.3,2.27-.87,5.93-1.7,11-1.23,9.37.75,14.47,5.95,15.3,12.83,2.07,21.28-3.15,25.35-15.65,1.47-4.6,2.1-9.52,1.9-14.75-.17-4.73-.92-8.2-2.25-10.4-2.2-3.63-3.25-7.62-3.15-11.95.1-4.13,1.22-7.92,3.35-11.35,2.2-3.53,5.12-6.1,8.75-7.7,4-1.73,8.37-2.05,13.1-.95,5.67,1.27,9.17-.75,10.5-6.05,1.2-4.7.63-11.12-1.7-19.25-2.23-7.8-5.43-15-9.6-21.6-4.47-7.07-8.88-11.48-13.25-13.25Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_2_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 2 FILL">
		                    <path className={styles.cls11} d="m167.94,297.19c-.73-.17-1.52-.18-2.35-.05-.63.1-1.67.35-3.1.75-6.73,16.97-6.8,31.83-.2,44.6,2.67,5.17,6.18,9.37,10.55,12.6,4.07,3.03,8.2,4.75,12.4,5.15.57-.33,1.73-.58,3.5-.75,2.37-.2,3.88-.37,4.55-.5.43-2.27-3.1-12.45-10.6-30.55-7.43-17.97-12.35-28.38-14.75-31.25Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_3_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 3 FILL">
		                    <path className={styles.cls15} d="m252.49,185.54c-5.07,1.1-11.02,7.8-17.85,20.1-5.2,9.37-7.83,14.1-7.9,14.2-2.93,4.93-5.57,8.33-7.9,10.2-9.13,7.3-15.68,12.7-19.65,16.2-8.83,7.9-16.15,15.67-21.95,23.3-16.37,21.57-21.45,42.85-15.25,63.85,2.73,9.27,8.7,16.23,17.9,20.9,7.87,4,17.68,6.15,29.45,6.45,10.1.23,20.47-.87,31.1-3.3,9.7-2.2,17.3-4.95,22.8-8.25,10.2-6.1,18.8-13.27,25.8-21.5,9.33-10.97,16.08-23.98,20.25-39.05,2.9-10.57,2.95-22.43.15-35.6-2.57-12.03-7.1-23.63-13.6-34.8-6.37-10.87-13.4-19.32-21.1-25.35-8.13-6.37-15.55-8.82-22.25-7.35Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_4_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 4 FILL">
		                    <path className={styles.cls15} d="m219.49,341.84c-7.83,5.87-9.52,11.27-5.05,16.2,7.13,7.87,11.75,19.9,13.85,36.1.17,1.4.75,7.5,1.75,18.3.6,6.6,1.38,11.38,2.35,14.35,1.73,5.4,4.53,9.18,8.4,11.35,3.63,2.03,7.57,2.27,11.8.7,4.33-1.63,8.1-4.88,11.3-9.75,3.53-5.37,5.85-12.03,6.95-20,.77-5.47,1.62-13.13,2.55-23,.83-8.93,1.6-15.57,2.3-19.9,2.2-13.93,6.68-26.58,13.45-37.95,2.6-4.37-.65-6.4-9.75-6.1-8.47.3-18.72,2.35-30.75,6.15-12.77,4.03-22.48,8.55-29.15,13.55Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_5_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 5 FILL">
		                    <path className={styles.cls15} d="m263.04,407.04c-7.73-1.5-14.68-.9-20.85,1.8-6.2,2.7-9.8,6.68-10.8,11.95-1.03,5.3.82,10.35,5.55,15.15,4.73,4.77,10.98,7.9,18.75,9.4,7.7,1.5,14.65.9,20.85-1.8,6.17-2.7,9.77-6.68,10.8-11.95,1.03-5.3-.83-10.33-5.6-15.1-4.73-4.8-10.97-7.95-18.7-9.45Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_6_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 6 FILL">
		                    <path className={styles.cls15} d="m165.54,260.44c-.43-5.4-1.47-9.78-3.1-13.15-1.87-3.8-4.2-5.42-7-4.85-16.07,3.37-24.8,5.17-26.2,5.4-13,2.2-24.42,2.97-34.25,2.3-4.17-.27-7.82.33-10.95,1.8-2.9,1.37-4.95,3.27-6.15,5.7-1.17,2.43-1.25,4.93-.25,7.5,1.07,2.8,3.28,5.25,6.65,7.35,7.83,4.93,19.57,8.2,35.2,9.8,14.5,1.47,27.97,1.17,40.4-.9,2.17-.33,3.77-2.68,4.8-7.05.97-4,1.25-8.63.85-13.9Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_7_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 7 FILL">
		                    <path
			                    className={styles.cls18}
			                    d="m176.39,237.89c-13.97,1.7-24.3,3.18-31,4.45,2.87,5.8,4.92,12.9,6.15,21.3,1.17,8,1.27,14.78.3,20.35,7.57-.63,15.47-2.15,23.7-4.55,9-2.63,16.07-5.68,21.2-9.15,2.87-3,4.68-8.03,5.45-15.1.43-8.67.9-15.22,1.4-19.65-3.27-.23-12.33.55-27.2,2.35Z"
			                    style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_8_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 8 FILL">
		                    <path
			                    className={styles.cls16}
			                    d="m173.24,274.64c-5.33,7.83-9.02,15.4-11.05,22.7,21.4-4.17,45.25-1.43,71.55,8.2,21.17,7.77,38.52,17.47,52.05,29.1,10.53-12.5,17.82-25.98,21.85-40.45,3.7-13.27,4.52-26.7,2.45-40.3-1.97-12.73-6.27-24.62-12.9-35.65-6.5-10.77-14.55-19.62-24.15-26.55-5.9,4.87-12.58,12.85-20.05,23.95-3.07,4.53-5.4,7.67-7,9.4-2.43,2.67-4.83,4.2-7.2,4.6-1.9,2.13-5.13,6.82-9.7,14.05-3.5,5.53-7.17,9.7-11,12.5.33-2.03,1.1-5.15,2.3-9.35.9-3.2,1.45-6.47,1.65-9.8-10.37.5-17.82,1.83-22.35,4-6.33,3-10.25,8.43-11.75,16.3-5.23,4.83-10.13,10.6-14.7,17.3Z"
			                    style={{ fill: `${teamColors[JERSEY_BG]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_9_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 9 FILL">
		                    <path
			                    className={styles.cls18}
			                    d="m270.69,199.69c-.03,3.57,1.38,6.73,4.25,9.5,2.03,1.97,8.87,3.57,20.5,4.8,6.3.67,14.87,1.52,25.7,2.55,1.9-1.37,4.4-2.65,7.5-3.85,3.73-1.47,5.9-2.35,6.5-2.65,4.03-2.17,5.15-6.28,3.35-12.35-1.4-2.17-3.88-4.42-7.45-6.75-3.97-2.6-8.38-4.67-13.25-6.2-12.67-4-24.58-3.4-35.75,1.8-7.53,3.53-11.32,7.92-11.35,13.15Z"
			                    style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_10_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 10 FILL">
		                    <path
			                    className={styles.cls17}
			                    d="m273.54,191.54c-3.17-2.67-4.98-4.17-5.45-4.5-3.3,5.6-8.43,12.97-15.4,22.1-7.83,10.3-13.08,16.12-15.75,17.45l-1.05.5-.5,1.05c-4.57,8.9-8.85,15.5-12.85,19.8.4-5.13.1-9.82-.9-14.05l-6.85,1.65c1.43,5.8,1.27,12.43-.5,19.9l-1.7,7.3,6.7-3.35c6.93-3.5,14.2-12.53,21.8-27.1,3.47-1.97,8.98-8,16.55-18.1,3.87-5.17,9.17-12.72,15.9-22.65Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_11_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 11 FILL">
		                    <path
			                    className={styles.cls17}
			                    d="m172.69,275.44c-.93,1.3-1.98,2.9-3.15,4.8-1.33,2.23-2.1,3.73-2.3,4.5,22.2-6.13,46.93-4.08,74.2,6.15,11.47,4.3,21.87,9.6,31.2,15.9,9.1,6.13,15.88,12.32,20.35,18.55,2.4-3.4,3.95-5.75,4.65-7.05-5.47-6.8-13.08-13.35-22.85-19.65-9.87-6.4-20.63-11.7-32.3-15.9-25-8.93-48.27-11.37-69.8-7.3Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_12_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 12 FILL">
		                    <path
			                    className={styles.cls17}
			                    d="m293.19,212.59c1.33-3.63,3.85-7.25,7.55-10.85,4.1-3.97,8.7-6.92,13.8-8.85,7-2.57,13.4-2.58,19.2-.05-.8-.93-3.4-2.55-7.8-4.85-4-.2-8.15.5-12.45,2.1-5.13,1.93-9.88,4.92-14.25,8.95-4.33,4.07-7.28,8.23-8.85,12.5l2.8,1.05Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_13_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 13 FILL">
		                    <path
			                    className={styles.cls17}
			                    d="m287.44,211.09c2.17-6.07,6.83-11.55,14-16.45,7.77-5.33,15.53-7.73,23.3-7.2-2.23-1.1-4.55-2-6.95-2.7-7.37,1.07-14.32,4.22-20.85,9.45-6.17,4.93-10.27,10.22-12.3,15.85l2.8,1.05Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_14_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 14 FILL">
		                    <path
			                    className={styles.cls17}
			                    d="m159.09,261.59c-1.13-8.3-3.13-15.15-6-20.55l-3.15.5c2.93,5.13,4.98,11.97,6.15,20.5,1.1,8.07,1.1,15.23,0,21.5l3.1-.45c1-6.43.97-13.6-.1-21.5Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_15_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 15 FILL">
		                    <path
			                    className={styles.cls17}
			                    d="m163.84,258.19c-1.6-9.23-4.2-16.27-7.8-21.1l-2.4,1.8c3.43,4.63,5.88,11.52,7.35,20.65,1.4,8.57,1.55,16.28.45,23.15.43-.03,1.47-.22,3.1-.55,1-7.33.77-15.32-.7-23.95Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_16_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 16 FILL">
		                    <path
			                    className={styles.cls19}
			                    d="m250.89,167.84c-5.6,1.1-13.23,4.7-22.9,10.8-9.03,5.7-16.25,11.42-21.65,17.15-7.67,8.1-11.55,16.05-11.65,23.85,0,1.27-.05,3.42-.15,6.45,0,2.67.18,4.77.55,6.3.53,2.1,2.62,4.27,6.25,6.5,2.7,1.67,5.63,3.05,8.8,4.15,3.43,1.23,7.4,1.3,11.9.2,7.1-1.73,15.12-6.22,24.05-13.45,9.4-7.63,16.73-17.78,22-30.45,2.13-5.17,3.52-9.88,4.15-14.15.6-4.17.35-7.07-.75-8.7-5.03-7.47-11.9-10.35-20.6-8.65Z"
			                    style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_17_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 17 FILL">
		                    <path className={styles.cls7} d="m351.19,275.94c2-12.83-.12-24.83-6.35-36-5.83-10.47-14.28-18.6-25.35-24.4-11.23-5.87-23.02-8.2-35.35-7-13.4,1.3-25.45,6.7-36.15,16.2-1.03,14.87,2.07,27.97,9.3,39.3,6.57,10.3,15.68,17.97,27.35,23,11.3,4.87,22.92,6.42,34.85,4.65,12.5-1.87,23.07-7.12,31.7-15.75Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_18_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 18 FILL">
		                    <path className={styles.cls20} d="m294.64,260.99c-10.37-4.87-17.95-9.45-22.75-13.75l-3.35,3.7c5.2,4.63,13.18,9.48,23.95,14.55,10.4,4.9,18.67,7.85,24.8,8.85l.8-4.95c-5.7-.9-13.52-3.7-23.45-8.4Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_19_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 19 FILL">
		                    <path className={styles.cls20} d="m281.79,245.89c-.13-.17-.37-.35-.7-.55-.6-.33-1.23-.42-1.9-.25-.67.2-1.17.6-1.5,1.2l-2.7,4.55c-2.17,3.67-3.65,6.27-4.45,7.8-.33.6-.4,1.23-.2,1.9.2.63.6,1.12,1.2,1.45.63.33,1.28.4,1.95.2.63-.2,1.12-.6,1.45-1.2.67-1.3,2.12-3.82,4.35-7.55l2.75-4.7c.27-.47.38-.97.35-1.5-.07-.5-.27-.95-.6-1.35Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_20_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 20 FILL">
		                    <path className={styles.cls20} d="m291.64,251.79c-.27-.33-.57-.57-.9-.7-.63-.27-1.27-.27-1.9,0-.67.23-1.13.67-1.4,1.3l-2.2,4.8c-1.5,3.07-2.73,5.78-3.7,8.15-.27.67-.27,1.32,0,1.95.27.63.72,1.08,1.35,1.35.63.27,1.27.27,1.9,0,.63-.27,1.08-.72,1.35-1.35,1.07-2.53,2.27-5.18,3.6-7.95l2.3-4.95c.43-.93.3-1.8-.4-2.6Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_21_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 21 FILL">
		                    <path className={styles.cls20} d="m303.09,257.19c-.17-.23-.45-.47-.85-.7-.63-.27-1.28-.27-1.95,0-.63.23-1.08.67-1.35,1.3l-2.25,4.8c-1.7,3.47-2.95,6.18-3.75,8.15-.27.63-.27,1.27,0,1.9.23.63.67,1.08,1.3,1.35s1.28.28,1.95.05c.63-.27,1.08-.72,1.35-1.35.83-1.93,2.05-4.58,3.65-7.95l2.3-4.95c.43-.93.3-1.8-.4-2.6Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_22_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 22 FILL">
		                    <path className={styles.cls20} d="m315.14,261.24c-.27-.33-.62-.57-1.05-.7-.63-.23-1.27-.22-1.9.05-.6.27-1.03.73-1.3,1.4l-1.95,4.95c-1.4,3.3-2.48,6.07-3.25,8.3-.23.67-.2,1.3.1,1.9.3.63.77,1.07,1.4,1.3.67.23,1.3.2,1.9-.1.63-.3,1.07-.77,1.3-1.4.73-2.07,1.78-4.78,3.15-8.15,1-2.37,1.68-4.05,2.05-5.05.3-.93.15-1.77-.45-2.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_23_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 23 FILL">
		                    <path className={styles.cls15} d="m340.49,205.29c.03-5.67-1.8-8.77-5.5-9.3-13.23-1.97-23.95,1.15-32.15,9.35-7.07,7.07-14.15,20.2-21.25,39.4-1.07,2.87-1.18,5.58-.35,8.15.77,2.47,2.33,4.45,4.7,5.95,5.47,3.4,12.57,3.28,21.3-.35,5.63-2.33,11.15-6.88,16.55-13.65,4.87-6.03,8.88-12.85,12.05-20.45,3.1-7.43,4.65-13.8,4.65-19.1Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_24_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 24 FILL">
		                    <path className={styles.cls15} d="m310.79,253.29c2.97-5.53,3.28-10.42.95-14.65-2.33-4.23-6.63-6.58-12.9-7.05-6.27-.43-12.67,1.15-19.2,4.75-6.53,3.57-11.3,8.12-14.3,13.65-2.97,5.53-3.28,10.43-.95,14.7,2.37,4.23,6.67,6.57,12.9,7,6.27.47,12.67-1.1,19.2-4.7,6.53-3.6,11.3-8.17,14.3-13.7Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_25_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 25 FILL">
		                    <path className={styles.cls8} d="m159.04,86.74c-3.4-2.23-8.37-3.28-14.9-3.15-3,.07-8.18.63-15.55,1.7-18.03,2.6-29.78,10.73-35.25,24.4-5.23,13.07-3.57,27.72,5,43.95,2.37,4.47,5.13,7.48,8.3,9.05,3.63,1.77,7.63,1.52,12-.75,6.17-3.23,14.68-13.52,25.55-30.85,6.7-10.7,12.12-20.17,16.25-28.4,1.83-3.67,2.8-6.43,2.9-8.3.2-3.07-1.23-5.62-4.3-7.65Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_26_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 26 FILL">
		                    <path className={styles.cls15} d="m198.84,181.59c-5.4-1.67-9.52-3.68-12.35-6.05-4.4-3.67-7.42-8.97-9.05-15.9-1.17-5.1-1.63-11.53-1.4-19.3.53-19.13,2.65-38.22,6.35-57.25,1.07-5.8,2.3-10.58,3.7-14.35-9.07,3.1-18.23,7.77-27.5,14-.03.93-.22,2.2-.55,3.8-.57,2.63-1.52,6.55-2.85,11.75-1.07,4.63-1.73,8.6-2,11.9-.57,6.97.23,15.38,2.4,25.25,1.23,5.57,3.2,13.87,5.9,24.9,2,9.93,1.28,17.52-2.15,22.75-1.67,2.6-4.42,5.15-8.25,7.65-2.13,1.4-5.35,3.5-9.65,6.3-3.7,2.87-6.75,6.48-9.15,10.85-.3,8.8,1.02,15.38,3.95,19.75,4.4,6.53,10.25,11.92,17.55,16.15,7.37,4.3,14.27,6.28,20.7,5.95,9.9-.53,19.02-2.67,27.35-6.4,5.63-2.53,12.8-6.85,21.5-12.95,5.67-4,10.95-8.83,15.85-14.5-1.13-1.43-2.38-3.42-3.75-5.95-1.43-2.87-2.53-4.97-3.3-6.3-2.23-3.93-5.08-7.43-8.55-10.5-3.43-3.03-7.23-5.43-11.4-7.2-.83-.37-5.28-1.82-13.35-4.35Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_27_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 27 FILL">
		                    <path className={styles.cls10} d="m255.94,85.59c-3.47-6.67-7.98-11.68-13.55-15.05-5.07-3.07-11.53-5.07-19.4-6-12.23-1.47-24.53-.07-36.9,4.2-1.4,3.77-2.63,8.55-3.7,14.35-3.7,19.03-5.82,38.12-6.35,57.25-.23,7.77.23,14.2,1.4,19.3,1.63,6.93,4.65,12.23,9.05,15.9,2.83,2.37,6.95,4.38,12.35,6.05,8.07,2.53,12.52,3.98,13.35,4.35,4.17,1.77,7.97,4.17,11.4,7.2,3.47,3.07,6.32,6.57,8.55,10.5.77,1.33,1.87,3.43,3.3,6.3,1.37,2.53,2.62,4.52,3.75,5.95,13.4-15.57,22.23-35.63,26.5-60.2,1.6-9.33,2.37-18.4,2.3-27.2-.07-7.7-.72-13.63-1.95-17.8-2.6-8.7-5.97-17.07-10.1-25.1Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_28_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 28 FILL">
		                    <path className={styles.cls10} d="m158.59,82.74l-3.75,2.6c-8.77,6.27-15.23,12.12-19.4,17.55-5.43,7.17-8.6,15.4-9.5,24.7-.43,4.3.23,9.63,2,16,1,3.6,2.63,8.78,4.9,15.55,1.53,5.03,2.33,9.8,2.4,14.3,0,2.23-.37,7.17-1.1,14.8-.17,1.87-.52,4.97-1.05,9.3-.47,4.03-.73,7.48-.8,10.35,2.4-4.37,5.45-7.98,9.15-10.85,4.3-2.8,7.52-4.9,9.65-6.3,3.83-2.5,6.58-5.05,8.25-7.65,3.43-5.23,4.15-12.82,2.15-22.75-2.7-11.03-4.67-19.33-5.9-24.9-2.17-9.87-2.97-18.28-2.4-25.25.27-3.3.93-7.27,2-11.9,1.33-5.2,2.28-9.12,2.85-11.75.33-1.6.52-2.87.55-3.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_29_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 29 FILL">
		                    <path className={styles.cls14} d="m167.64,239.54l10.95,2c6.13,1.2,11.03.93,14.7-.8,2.37-1.1,4.2-2.77,5.5-5,1.37-2.37,1.72-4.72,1.05-7.05-.53-1.83-1.72-3.52-3.55-5.05-1.2-1.03-3.03-2.23-5.5-3.6-1.93,3.83-4.08,6.73-6.45,8.7-1.43,1.23-3,2.15-4.7,2.75-1.8.67-3.57.93-5.3.8-.77-.07-1.32-.03-1.65.1-.43.17-.95.72-1.55,1.65-.73,1.13-1.7,2.13-2.9,3-.8.56-1.98,1.25-3.55,2.05.28-.13.78-.11,1.5.05.37.1.85.23,1.45.4Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_30_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 30 FILL">
		                    <path className={styles.cls9} d="m172.09,208.29c.8-6.13-2.3-9.67-9.3-10.6-1.5-.23-3.3-.08-5.4.45-2,.53-4.05,1.33-6.15,2.4v.05h-.05c-2.1,1.03-3.97,2.2-5.6,3.5-1.67,1.33-2.87,2.67-3.6,4-3.4,6.1-2.35,10.67,3.15,13.7,2.27,1.27,4.97,1.95,8.1,2.05,3.17.1,6.17-.42,9-1.55h.05v-.05c2.6-1.6,4.8-3.68,6.6-6.25,1.77-2.57,2.83-5.13,3.2-7.7Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_31_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 31 FILL">
		                    <path className={styles.cls9} d="m174.09,234.34c3.13,0,5.82-.55,8.05-1.65,1.13-.5,2.15-1.12,3.05-1.85,1.6-1.2,3.2-2.97,4.8-5.3,1.9-2.77,2.93-5.27,3.1-7.5.07-.53-.08-1.03-.45-1.5-.33-.43-.78-.68-1.35-.75-.57-.03-1.05.12-1.45.45-.43.37-.67.82-.7,1.35-.13,1.5-.93,3.35-2.4,5.55-1.37,2-2.68,3.47-3.95,4.4-1.57,1.17-3.25,1.95-5.05,2.35-1.5.33-3.32.43-5.45.3l-.1-.15-.1.15h-.15l.05.15c-1.2,1.73-2.42,3.08-3.65,4.05-1.43,1.13-3.1,1.93-5,2.4-1.23.33-2.57.45-4,.35-.63-.1-1.12-.15-1.45-.15-.53,0-.95.17-1.25.5-.27.27-.42.53-.45.8-.17.57-.03,1.13.4,1.7.4.53.92.9,1.55,1.1,1.47.43,3.55.32,6.25-.35,1.13-.27,2.23-.65,3.3-1.15,2.3-1.1,4.43-2.85,6.4-5.25Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_32_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 32 FILL">
		                    <path className={styles.cls9} d="m172.79,234.09c.57.2,1.1.2,1.6,0,.53-.2.93-.62,1.2-1.25.2-.57.18-1.12-.05-1.65-.27-.57-.67-.97-1.2-1.2-1-.43-2.23-1.22-3.7-2.35-2.93-2.23-5.77-5.13-8.5-8.7-.33-.5-.8-.8-1.4-.9-.6-.07-1.13.08-1.6.45-.47.33-.73.82-.8,1.45-.1.6.03,1.13.4,1.6,3.1,4.07,6.33,7.37,9.7,9.9,1.7,1.27,3.15,2.15,4.35,2.65Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_33_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 33 FILL">
		                    <path className={styles.cls9} d="m200.44,135.54c-4.07-.1-7.57,1.13-10.5,3.7-3.2,2.77-4.55,6.27-4.05,10.5.2,1.67.65,2.97,1.35,3.9.87,1.2,1.87,1.12,3-.25.53-.63,1.03-1.75,1.5-3.35.5-1.7.93-2.83,1.3-3.4,1.5-2.4,3.8-3.78,6.9-4.15,2.63-.33,5.6.08,8.9,1.25,1.2.5,2.22.78,3.05.85.53,0,.92-.03,1.15-.1.63-.1,1.13-.38,1.5-.85,1.7-2.17.1-4.15-4.8-5.95-3.73-1.37-6.83-2.08-9.3-2.15Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_34_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 34 FILL">
		                    <path className={styles.cls9} d="m157.54,163.74c.37.07.68.05.95-.05.43-.03.82-.25,1.15-.65,1.33-1.73.02-3.37-3.95-4.9-2.97-1.17-5.48-1.82-7.55-1.95-3.27-.13-6.07.82-8.4,2.85-2.53,2.23-3.58,5.08-3.15,8.55.17,1.37.53,2.43,1.1,3.2.73,1,1.55.95,2.45-.15.43-.53.83-1.43,1.2-2.7.4-1.4.73-2.33,1-2.8,1.2-1.93,3.03-3.03,5.5-3.3,2.13-.23,4.53.17,7.2,1.2,1.23.43,2.07.67,2.5.7Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_35_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 35 FILL">
		                    <path
			                    className={styles.cls16}
			                    d="m194.24,55.84c2.23-4.07,5.7-6.75,10.4-8.05l-3.15.15c-10.27.73-22.67,4.75-37.2,12.05-16.87,8.47-28.58,18-35.15,28.6-6.87,11.17-10.87,22-12,32.5-1,9.4.25,19.55,3.75,30.45,7.13-7.03,16.18-13.52,27.15-19.45,10.87-5.9,21.98-10.37,33.35-13.4,12.03-3.23,22.7-4.38,32-3.45,10.23,1.03,17.9,4.53,23,10.5,3.9,4.03,8.05,9.32,12.45,15.85,2.5,3.7,5.93,9.02,10.3,15.95,3.67,5.63,6.68,9.57,9.05,11.8,3.07,2.9,5.93,3.97,8.6,3.2,3.03-.87,5.9-2.88,8.6-6.05,2.87-3.43,5.08-7.7,6.65-12.8,3.8-12.5,2.98-26.77-2.45-42.8-5.93-17.53-15.83-31.98-29.7-43.35-13.13-10.8-27.73-17.2-43.8-19.2,3.93,1.47,7.32,4.02,10.15,7.65,3.5,4.57,5.22,9.3,5.15,14.2-.07,4.23-1.32,7.52-3.75,9.85-2.73,2.63-6.47,3.62-11.2,2.95-4.33-.63-8.62-2.45-12.85-5.45-4.23-3.03-7.28-6.45-9.15-10.25-2.03-4.2-2.1-8.02-.2-11.45Z"
			                    style={{ fill: `${teamColors[HELMET_BG]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_36_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 36 FILL">
		                    <path
			                    className={styles.cls13}
			                    d="m193.19,53.14c-1.23,1.93-1.93,4.07-2.1,6.4-.17,2.37.23,4.8,1.2,7.3,1.87,5.03,5.35,9.17,10.45,12.4,5.03,3.3,10.23,4.77,15.6,4.4,2.67-.13,5.12-.82,7.35-2.05,1.97-1.1,3.55-2.58,4.75-4.45,1.23-1.93,1.93-4.07,2.1-6.4.17-2.37-.23-4.8-1.2-7.3-1.83-4.97-5.32-9.1-10.45-12.4-5.03-3.23-10.23-4.7-15.6-4.4-2.67.17-5.05.82-7.15,1.95-2.07,1.1-3.72,2.62-4.95,4.55m2.5,1.65c.9-1.43,2.13-2.58,3.7-3.45,1.77-1,3.8-1.57,6.1-1.7,4.63-.3,9.23,1,13.8,3.9,4.53,2.97,7.62,6.62,9.25,10.95,1.57,4.2,1.37,7.88-.6,11.05-.97,1.5-2.3,2.7-4,3.6-1.7.87-3.63,1.37-5.8,1.5-4.63.3-9.23-1-13.8-3.9-4.5-2.9-7.58-6.55-9.25-10.95-1.6-4.23-1.4-7.9.6-11Z"
			                    style={{ fill: `${teamColors[HELMET_HOLE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_37_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 37 FILL">
		                    <path
			                    className={styles.cls18}
			                    d="m173.64,55.44c-3.4,1.1-6.95,2.55-10.65,4.35-4.87,2.37-8.6,4.73-11.2,7.1-5.97,8.13-8.03,18.22-6.2,30.25,1.4,9.07,5.32,20.27,11.75,33.6l13.4-7.5c-3.73-12.2-5.25-24.75-4.55-37.65.7-12.2,3.18-22.25,7.45-30.15Z"
			                    style={{ fill: `${teamColors[HELMET_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_38_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 38 FILL">
		                    <path className={styles.cls12} d="m251.59,127.84c-1.03-.23-2.02-.12-2.95.35-.97.47-1.67,1.17-2.1,2.1l-.3.65c-14.13-14.9-25.35-22.9-33.65-24-10.9-1.37-25.13,1.07-42.7,7.3-20.7,7.33-39.18,18.08-55.45,32.25-1.03.9-1.6,2.03-1.7,3.4s.3,2.58,1.2,3.65c.9,1.03,2.03,1.6,3.4,1.7,1.4.1,2.62-.3,3.65-1.2,14.73-12.8,31.6-22.73,50.6-29.8,16.57-6.17,29.82-8.62,39.75-7.35,2.47.3,5.7,1.87,9.7,4.7,5.73,4.03,12.55,10.35,20.45,18.95-6.4,12.33-13.23,22.5-20.5,30.5-10.37,11.47-22.18,19.43-35.45,23.9-15.6-3.9-29.32-3.35-41.15,1.65-11.83,4.97-21.68,14.35-29.55,28.15-.63,1.07-.77,2.2-.4,3.4.33,1.2,1.03,2.12,2.1,2.75s2.2.78,3.4.45c1.2-.37,2.1-1.08,2.7-2.15l.15-.2c10.37,8.27,20.17,18.47,29.4,30.6-1.13.67-1.83,1.63-2.1,2.9-.27,1.23-.07,2.37.6,3.4.67,1.07,1.62,1.72,2.85,1.95,6.37,1.33,13.48,1.1,21.35-.7,7.9-1.8,16.02-5.05,24.35-9.75,5.83-3.27,12.27-7.37,19.3-12.3,9.83-6.9,16.92-12.92,21.25-18.05,5.63-6.67,11-16.92,16.1-30.75,5.1-13.87,9.35-29.73,12.75-47.6.17-.87.1-1.68-.2-2.45-4.17-10.7-9.78-16.83-16.85-18.4m-13.05,35.4c4.57-6.73,9.3-15.1,14.2-25.1,2.5,1.87,4.77,5.25,6.8,10.15-2.93,15.03-6.48,28.65-10.65,40.85-4.2,12.2-8.57,21.63-13.1,28.3l-20.65-28.55c8.57-6.73,16.37-15.28,23.4-25.65m-52.25,40.85l.75-.2c8.07-2.57,15.55-6.2,22.45-10.9l21.75,30.1c-3.6,3.77-8.8,8.12-15.6,13.05-5.37,3.87-10.92,7.53-16.65,11-8-13.27-12.23-27.62-12.7-43.05m-37.1.3c8.7-3.37,18.73-3.92,30.1-1.65.2,17.17,4.75,33.12,13.65,47.85-11.8,6.33-22.45,9.37-31.95,9.1-10.77-14.8-22.25-27.02-34.45-36.65,6.33-9.07,13.88-15.28,22.65-18.65Z"/>
		                </g>
		                <g id="FlashAICB_Assets_1_Layer_1_Clip_Group_0_0_Layer2_39_FILL" data-name="FlashAICB Assets 1 Layer 1  Clip Group  0 0 Layer2 39 FILL">
		                    <path className={styles.cls8} d="m301.64,65.54c-1.23-6.07-2.88-12.07-4.95-18-2-5.7-3.83-9.77-5.5-12.2-9.63-13.9-21.77-20.28-36.4-19.15-12.27.93-27.13,7.32-44.6,19.15-4.93,3.33-8.98,7.45-12.15,12.35-3.87,5.97-4.93,11.27-3.2,15.9,1.13,3.03,3.38,5.78,6.75,8.25,3.17,2.33,6.8,4.02,10.9,5.05,1.07.27,5.67,1.68,13.8,4.25,9.43,2.93,17.92,5.35,25.45,7.25,24.47,6.2,40.25,7.6,47.35,4.2,4.87-2.33,5.72-11.35,2.55-27.05Z"/>
		                </g>
		            </g>
		        </g>
		    </g>
		    <g className={styles.cls2}>
		        <g className={styles.cls5}>
		            <g>
		                <g className={styles.cls4}>
		                    <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_1_0_Layer2_0_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  1 0 Layer2 0 FILL">
		                        <path className={styles.cls8} d="m752.47,419.04c-36.3-4.8-80.18-7.2-131.65-7.2s-95.35,2.4-131.65,7.2c-36.37,4.8-54.55,10.62-54.55,17.45s18.18,12.6,54.55,17.4c36.33,4.8,80.22,7.2,131.65,7.2s95.32-2.4,131.65-7.2c36.37-4.8,54.55-10.6,54.55-17.4s-18.18-12.65-54.55-17.45Z"/>
		                    </g>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_1_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 1 FILL">
		                    <path className={styles.cls8} d="m514.92,309.84c-3.27,1.47-5.85,3.63-7.75,6.5-1.43,2.07-2.4,4.5-2.9,7.3,6.8,8.8,14.72,14.38,23.75,16.75,2.77.73,5.92,1.23,9.45,1.5,2.57.17,5.82.22,9.75.15,2.13,0,3.87-.65,5.2-1.95,3.7-3.57.95-7.9-8.25-13-2.6-1.43-6.28-3.2-11.05-5.3-4.23-1.9-6.68-3.07-7.35-3.5-3.5-2.3-7.12-5.12-10.85-8.45Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_2_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 2 FILL">
		                    <path className={styles.cls15} d="m505.17,299.34c-2.43-2.77-4.75-4.43-6.95-5-1.07-.27-2.02-.35-2.85-.25-2.8.43-3.47,3.37-2,8.8.9,3.43,2.22,6.75,3.95,9.95,2.13,3.93,4.45,7.53,6.95,10.8.5-2.8,1.47-5.23,2.9-7.3,1.9-2.87,4.48-5.03,7.75-6.5l-4.1-3.85c-2.4-2.9-4.28-5.12-5.65-6.65Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_3_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 3 FILL">
		                    <path className={styles.cls15} d="m660.82,324.44c.03-2.37-2.95-1.75-8.95,1.85-5.3,3.2-11.68,7.92-19.15,14.15-7.37,6.13-13.15,11.57-17.35,16.3-4.7,5.23-5.95,8.03-3.75,8.4,3.27.53,10,8.2,20.2,23,10.23,14.97,16.5,26.38,18.8,34.25-3.13,3.2-5.07,5.55-5.8,7.05-1.67,3.43-1.52,7.42.45,11.95,1.07,2.5,3.83,4.25,8.3,5.25,4.07.93,8.8,1.12,14.2.55,5.4-.57,10.13-1.75,14.2-3.55,4.47-1.93,7.22-4.23,8.25-6.9,3.17-8.03-.72-27.15-11.65-57.35-5.77-15.4-9.78-26.33-12.05-32.8-3.83-10.97-5.73-18.35-5.7-22.15Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_4_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 4 FILL">
		                    <path className={styles.cls11} d="m663.37,336.59c-.2-.47-.47-1.38-.8-2.75-.27-1.23-.47-2.22-.6-2.95-.77-1.77-3.85-1.78-9.25-.05-5.23,1.67-10.97,4.42-17.2,8.25-6.77,4.13-12.18,8.4-16.25,12.8-4.73,5.17-6.87,9.77-6.4,13.8,2.97,2.5,4.88,4.28,5.75,5.35,11.1-3.2,21.48-9.12,31.15-17.75,7.77-6.93,12.3-12.5,13.6-16.7Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_5_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 5 FILL">
		                    <path className={styles.cls15} d="m623.82,188.44c-9.93-.53-18.18-.38-24.75.45-7.6.97-11.85,2.75-12.75,5.35-2.53,7.33-5.95,17.45-10.25,30.35-4.27,11.8-9.78,24.52-16.55,38.15-7.9,15.9-13.02,26.6-15.35,32.1-5.6,13.27-8.9,24.58-9.9,33.95-2.7,25.4,11.23,39.87,41.8,43.4,8.27.97,17.27.37,27-1.8,9.63-2.13,18.93-5.55,27.9-10.25,20.23-10.6,33.3-24.05,39.2-40.35,8.03-22.1,11.65-44.4,10.85-66.9-.9-24.7-7.22-43.9-18.95-57.6-1.2-1.4-5.65-2.78-13.35-4.15-7.53-1.33-15.83-2.23-24.9-2.7Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_6_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 6 FILL">
		                    <path className={styles.cls11} d="m605.57,351.14c-1.77-8.83-4.72-12.98-8.85-12.45-4.13,7.63-6.52,13.27-7.15,16.9-1.2,6.93,1.28,12.23,7.45,15.9,2.5-.3,4.05-.52,4.65-.65,2.23-.37,3.67-.83,4.3-1.4,1.27-4.37,1.13-10.47-.4-18.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_7_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 7 FILL">
		                    <path className={styles.cls8} d="m538.62,308.44c-4.4,11.8-6.35,21.5-5.85,29.1.5,7.2,3.35,13.98,8.55,20.35.9,1.1,1.65,1.17,2.25.2.5-.77,1.05-2.55,1.65-5.35,2.23-10.63,3.9-17.6,5-20.9,1.2-3.6,1.5-7.48.9-11.65-.53-3.67-1.63-6.95-3.3-9.85-1.6-2.8-3.25-4.45-4.95-4.95-1.87-.53-3.28.48-4.25,3.05Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_8_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 8 FILL">
		                    <path className={styles.cls15} d="m590.07,329.34c-6-4.17-12.32-6.45-18.95-6.85-7.5-.47-13.7,1.77-18.6,6.7-5.73,5.77-9.07,14.82-10,27.15-.63,8.23-2,15.73-4.1,22.5-1,3.17-3.05,8.35-6.15,15.55-2.53,5.93-4.25,11.07-5.15,15.4-1.37,6.4-1.82,13.75-1.35,22.05.3,5.37,2.63,9.47,7,12.3,3.87,2.47,8.8,3.7,14.8,3.7,5.6,0,10.87-1.05,15.8-3.15,5.13-2.13,8.57-4.85,10.3-8.15,2.63-5.03,1.63-9.05-3-12.05-1.9-1.2-4-1.92-6.3-2.15-2.37-.23-4.37.08-6,.95,1.73-5.73,6.05-10.15,12.95-13.25,1.87-.83,4.68-2.08,8.45-3.75,3.13-1.63,5.62-3.55,7.45-5.75,5.03-6.03,9.15-14.48,12.35-25.35,3.7-12.63,4.23-23.4,1.6-32.3-1.5-4.93-5.2-9.45-11.1-13.55Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_9_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 9 FILL">
		                    <path className={styles.cls15} d="m572.37,216.34c-3.07-3-5.77-3.98-8.1-2.95-8.87,3.87-19.95,7.17-33.25,9.9-17.73,3.6-31.27,3.88-40.6.85-5.3-1.73-10.08-2.17-14.35-1.3-3.87.8-6.77,2.52-8.7,5.15-1.9,2.6-2.5,5.53-1.8,8.8.8,3.5,2.98,6.7,6.55,9.6,11.73,9.6,30.07,13.97,55,13.1,9.43-.33,18.77-1.4,28-3.2,8.17-1.6,14.4-3.37,18.7-5.3,4.2-1.9,6.68-5.2,7.45-9.9.7-4.03.08-8.55-1.85-13.55-1.77-4.63-4.12-8.37-7.05-11.2Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_10_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 10 FILL">
		                    <path
			                    className={styles.cls18}
			                    d="m677.17,195.79c-8.5-1.1-14.63-1.52-18.4-1.25,5.2,5.03,8.05,11.22,8.55,18.55.5,7.67-1.82,14.48-6.95,20.45,3.73.53,8.33,2.08,13.8,4.65,5.3,2.43,9.73,3.87,13.3,4.3,5.47-5.83,9.42-12.97,11.85-21.4,2.53-8.77,2.42-15.77-.35-21-5.47-1.67-12.73-3.1-21.8-4.3Z"
			                    style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_11_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 11 FILL">
		                    <path
			                    className={styles.cls16}
			                    d="m566.87,245.84c-4.5,10.13-10.52,22.47-18.05,37,12.7,13.77,30.52,23.75,53.45,29.95,22.6,6.1,45.78,7.37,69.55,3.8,3.77-8.57,6.63-20.07,8.6-34.5,1.67-12.53,2.18-22.62,1.55-30.25-.9-10.1-2.52-19.92-4.85-29.45-3.43-14-7.62-22.93-12.55-26.8.9,3.77,2.55,9.18,4.95,16.25,1.7,5.93,2.03,10.52,1,13.75-2.4-4.8-5.68-8.5-9.85-11.1-5.2-2.47-9.23-4.4-12.1-5.8-10.6,1.03-21.25.23-31.95-2.4-12.6-3.1-23.52-8.45-32.75-16.05-3.03,18.47-8.7,37-17,55.6Z"
			                    style={{ fill: `${teamColors[JERSEY_BG]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_12_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 12 FILL">
		                    <path
			                    className={styles.cls18}
			                    d="m590.67,208.69c-4.73-2.47-9.78-3.27-15.15-2.4-11.7,3.8-20.83,6.85-27.4,9.15-2.27,7.67-2.62,15.82-1.05,24.45,1.63,8.97,4.85,15.18,9.65,18.65,5.63-.9,12.77-2.83,21.4-5.8,8.1-2.77,13.78-5.12,17.05-7.05,4.57-3.87,7.28-8.37,8.15-13.5.8-4.67-.02-9.2-2.45-13.6-2.37-4.33-5.77-7.63-10.2-9.9Z"
			                    style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_13_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 13 FILL">
		                    <path
			                    className={styles.cls17}
			                    d="m554.47,213.24c-1.5.47-2.58.83-3.25,1.1-2.63,16.7-.1,31.32,7.6,43.85.67,0,1.7-.23,3.1-.7-4.1-6.33-6.77-13.12-8-20.35-1.2-7.27-1.02-15.23.55-23.9Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_14_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 14 FILL">
		                    <path
			                    className={styles.cls17}
			                    d="m561.07,211.09l-3.2,1c-2.8,17-.45,31.88,7.05,44.65,1.03-.07,2.05-.33,3.05-.8-7.7-12.73-10-27.68-6.9-44.85Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_15_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 15 FILL">
		                    <path
			                    className={styles.cls17}
			                    d="m666.07,193.24l-6.1,3.9c2.27,4.3,3.83,7.83,4.7,10.6.87,2.8,1.27,5.58,1.2,8.35-2.9-2.7-5.8-4.92-8.7-6.65-3.23-1.9-6.87-3.45-10.9-4.65l-1.05-.35-1.1.4c-8.57,3.03-18.72,3.28-30.45.75-8.63-1.83-18.85-5.42-30.65-10.75-.43.5-.82,1.65-1.15,3.45-.23,1.23-.38,2.27-.45,3.1,12.73,7.13,24.63,11.45,35.7,12.95,9.67,1.27,19.07.42,28.2-2.55,4.73,1.53,8.57,3.17,11.5,4.9,3.33,2,6.57,4.72,9.7,8.15l4.8,5.3,1.25-7.05c.3-5.13,0-9.73-.9-13.8-.87-3.7-2.73-9.05-5.6-16.05Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_16_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 16 FILL">
		                    <path
			                    className={styles.cls17}
			                    d="m583.72,278.64c-9.23-4.67-17.42-10.27-24.55-16.8-.63,1.33-1.87,3.78-3.7,7.35,7.57,6.63,16.08,12.32,25.55,17.05,9.5,4.73,19.7,8.37,30.6,10.9,12.23,2.83,24.85,4.25,37.85,4.25,9.03,0,18.33-.72,27.9-2.15.6-2.07,1.17-4.87,1.7-8.4-11.17,1.93-22.33,2.77-33.5,2.5-11.1-.27-21.82-1.6-32.15-4-10.63-2.47-20.53-6.03-29.7-10.7Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_17_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 17 FILL">
		                    <path className={styles.cls7} d="m719.62,188.59c-14.63-2.77-28.02-1.22-40.15,4.65-11,5.3-19.68,13.47-26.05,24.5-6.13,10.67-9.03,22.02-8.7,34.05.4,12.63,4.38,23.75,11.95,33.35,12.5,3.47,24.65,2.77,36.45-2.1,11.1-4.57,20.18-12.02,27.25-22.35,7.13-10.47,10.83-21.88,11.1-34.25.27-13.47-3.68-26.08-11.85-37.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_18_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 18 FILL">
		                    <path className={styles.cls20} d="m678.17,230.69c6.03-9.7,11.47-16.68,16.3-20.95l-3.3-3.75c-5.2,4.57-10.95,11.92-17.25,22.05-6.07,9.73-9.97,17.6-11.7,23.6l4.85,1.35c1.57-5.53,5.27-12.97,11.1-22.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_19_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 19 FILL">
		                    <path className={styles.cls20} d="m683.32,207.04c-.57-.4-1.2-.55-1.9-.45-.67.13-1.2.48-1.6,1.05-.37.57-.5,1.2-.4,1.9.13.67.48,1.2,1.05,1.6,1.8,1.27,4.13,3,7,5.2l4.35,3.3c.43.3.92.45,1.45.45.5,0,.97-.13,1.4-.4.3-.23.5-.43.6-.6.43-.57.6-1.2.5-1.9-.13-.67-.48-1.2-1.05-1.6l-4.2-3.2c-2.97-2.3-5.37-4.08-7.2-5.35Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_20_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 20 FILL">
		                    <path className={styles.cls20} d="m687.67,228.79c.3-.2.55-.47.75-.8.37-.6.45-1.23.25-1.9-.17-.67-.55-1.18-1.15-1.55l-4.5-2.75c-3.53-2.2-6.1-3.75-7.7-4.65-.6-.33-1.23-.4-1.9-.2-.67.17-1.17.55-1.5,1.15-.33.63-.4,1.28-.2,1.95.17.67.57,1.17,1.2,1.5,1.97,1.07,4.45,2.57,7.45,4.5l4.65,2.85c.87.5,1.75.47,2.65-.1Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_21_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 21 FILL">
		                    <path className={styles.cls20} d="m680.92,239.54c.37-.23.63-.5.8-.8.37-.6.45-1.23.25-1.9-.17-.63-.55-1.13-1.15-1.5l-4.5-2.8c-4.13-2.6-6.68-4.17-7.65-4.7-.6-.33-1.23-.4-1.9-.2-.67.17-1.17.55-1.5,1.15-.33.6-.42,1.23-.25,1.9.2.67.6,1.17,1.2,1.5.7.4,3.18,1.92,7.45,4.55,1.57,1.03,3.1,2,4.6,2.9.93.53,1.82.5,2.65-.1Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_22_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 22 FILL">
		                    <path className={styles.cls20} d="m659.32,241.14c-.3.6-.33,1.23-.1,1.9.2.63.62,1.12,1.25,1.45,1.33.63,3.9,2,7.7,4.1l4.8,2.6c.87.4,1.7.35,2.5-.15.4-.27.68-.58.85-.95.33-.63.38-1.27.15-1.9-.2-.67-.6-1.15-1.2-1.45l-4.7-2.55c-3.57-1.97-6.2-3.37-7.9-4.2-.63-.3-1.27-.33-1.9-.1-.67.2-1.15.62-1.45,1.25Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_23_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 23 FILL">
		                    <path className={styles.cls11} d="m727.72,246.94c2.73-7.27,4.07-13.4,4-18.4-5.23-6.67-12.68-11.95-22.35-15.85-9.67-3.93-17.97-5.03-24.9-3.3-8.77,2.17-14.85,5.22-18.25,9.15-2.7,3.1-3.6,6.58-2.7,10.45,1.47,6.57,5.28,10.23,11.45,11,5.17.67,9.77-.48,13.8-3.45,4.33.93,9.62,3.82,15.85,8.65.07.07,5.6,4.75,16.6,14.05,1.87-1.6,4.03-5.7,6.5-12.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_24_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 24 FILL">
		                    <path className={styles.cls15} d="m735.77,237.59c-.33-4.37-1.52-7.8-3.55-10.3-5.23-6.4-12.68-11.48-22.35-15.25-9.67-3.77-17.97-4.83-24.9-3.2-8.77,2.1-14.85,5.05-18.25,8.85-2.7,3-3.6,6.35-2.7,10.05,1.5,6.3,5.32,9.83,11.45,10.6,5.17.63,9.77-.48,13.8-3.35,4.4.9,9.7,3.6,15.9,8.1,2.57,1.83,7.83,6.08,15.8,12.75,2.83,2.37,5.52,2.78,8.05,1.25,2.23-1.37,4-3.97,5.3-7.8,1.27-3.77,1.75-7.67,1.45-11.7Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_25_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 25 FILL">
		                    <path
			                    className={styles.cls19}
			                    d="m659.87,174.49c-4.2-3.9-11.62-7.95-22.25-12.15-24.17-9.63-41.73-10.58-52.7-2.85-2.3,1.57-4.07,2.78-5.3,3.65-2.2,1.6-3.8,3-4.8,4.2-1.37,1.67-1.88,4.63-1.55,8.9.23,3.17.83,6.35,1.8,9.55,1.03,3.47,3.32,6.72,6.85,9.75,5.57,4.7,13.9,8.53,25,11.5,11.7,3.13,24.23,3.1,37.6-.1,5.43-1.3,10.05-2.97,13.85-5,3.73-1.97,5.93-3.87,6.6-5.7,3.1-8.47,1.4-15.72-5.1-21.75Z"
			                    style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_26_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 26 FILL">
		                    <path className={styles.cls8} d="m668.67,48.24c3.8-3.13,5.57-6.73,5.3-10.8-.23-3.5-1.97-7.2-5.2-11.1-11.7-14.13-24.58-21.3-38.65-21.5-14.73-.2-26.75,7.53-36.05,23.2-3.8,6.37-6.32,10.93-7.55,13.7-2.63,6-3.57,10.98-2.8,14.95.7,3.63,2.5,5.95,5.4,6.95,1.8.63,4.73.8,8.8.5,8.53-.53,19.35-1.9,32.45-4.1,20.2-3.4,32.97-7.33,38.3-11.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_27_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 27 FILL">
		                    <path className={styles.cls15} d="m705.87,78.44c-5,.57-9.52,2.02-13.55,4.35-4.23,2.9-7.4,5.07-9.5,6.5-3.8,2.57-7.22,4.13-10.25,4.7-6.2,1.1-13.47-1.15-21.8-6.75-9.13-6.73-16.05-11.73-20.75-15-8.3-5.8-15.77-9.77-22.4-11.9-3.17-1.03-7.08-1.93-11.75-2.7-5.3-.8-9.28-1.43-11.95-1.9-1.37-.23-2.62-.55-3.75-.95-9.23,6.1-17.05,12.77-23.45,20,4.03.2,8.92.9,14.65,2.1,18.87,3.87,37.3,9.23,55.3,16.1,7.27,2.77,13.03,5.68,17.3,8.75,5.77,4.13,9.5,8.93,11.2,14.4,1.1,3.53,1.4,8.12.9,13.75-.77,8.6-1.15,13.27-1.15,14,.03,4.5.8,8.92,2.3,13.25,1.5,4.33,3.63,8.3,6.4,11.9.97,1.23,2.48,3.07,4.55,5.5,1.8,2.23,3.15,4.15,4.05,5.75,6.93-2.27,13.43-5.3,19.5-9.1,8.97-5.7,15.7-10.67,20.2-14.9,6.63-6.3,12.1-13.9,16.4-22.8,2.8-5.8,3.63-12.93,2.5-21.4-1.13-8.37-3.88-15.82-8.25-22.35-2.83-4.33-8.4-8.1-16.7-11.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_28_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 28 FILL">
		                    <path className={styles.cls10} d="m643.97,101.74c-4.27-3.07-10.03-5.98-17.3-8.75-18-6.87-36.43-12.23-55.3-16.1-5.73-1.2-10.62-1.9-14.65-2.1-8.7,9.83-14.7,20.67-18,32.5-2.17,7.63-2.82,14.37-1.95,20.2.97,6.47,3.87,12.57,8.7,18.3,5.97,7,12.42,13.32,19.35,18.95,3.37,2.73,8.58,5.6,15.65,8.6,8.1,3.43,16.77,6.2,26,8.3,24.33,5.47,46.25,5.02,65.75-1.35-.9-1.6-2.25-3.52-4.05-5.75-2.07-2.43-3.58-4.27-4.55-5.5-2.77-3.6-4.9-7.57-6.4-11.9-1.5-4.33-2.27-8.75-2.3-13.25,0-.73.38-5.4,1.15-14,.5-5.63.2-10.22-.9-13.75-1.7-5.47-5.43-10.27-11.2-14.4Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_29_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 29 FILL">
		                    <path className={styles.cls10} d="m682.82,89.29c2.1-1.43,5.27-3.6,9.5-6.5,4.03-2.33,8.55-3.78,13.55-4.35-2.67-1-5.97-2.07-9.9-3.2-4.2-1.2-7.18-2.07-8.95-2.6-7.3-2.23-12-3.78-14.1-4.65-4.1-1.77-8.2-4.33-12.3-7.7-5.37-4.7-9.53-8.2-12.5-10.5-5.2-4.07-9.87-6.73-14-8-8.93-2.73-17.75-2.95-26.45-.65-6.6,1.77-14.48,5.5-23.65,11.2l-3.85,2.45c1.13.4,2.38.72,3.75.95,2.67.47,6.65,1.1,11.95,1.9,4.67.77,8.58,1.67,11.75,2.7,6.63,2.13,14.1,6.1,22.4,11.9,4.7,3.27,11.62,8.27,20.75,15,8.33,5.6,15.6,7.85,21.8,6.75,3.03-.57,6.45-2.13,10.25-4.7Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_30_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 30 FILL">
		                    <path className={styles.cls14} d="m719.17,134.14l2.35-10.85c.07-.63.13-1.15.2-1.55.13-.69.31-1.14.55-1.35-1.37,1.17-2.47,2-3.3,2.5-1.23.77-2.52,1.27-3.85,1.5-1.13.23-1.83.5-2.1.8-.2.2-.45.7-.75,1.5-1.07,3.37-3.43,5.98-7.1,7.85-2.7,1.4-6.2,2.28-10.5,2.65.3,2.77.68,4.9,1.15,6.4.73,2.3,1.85,4.05,3.35,5.25,1.87,1.53,4.17,2.12,6.9,1.75,2.6-.33,4.85-1.38,6.75-3.15,3-2.8,5.12-7.23,6.35-13.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_31_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 31 FILL">
		                    <path className={styles.cls9} d="m713.77,95.64c-.67-6.2-4.48-8.9-11.45-8.1-3.13.37-6.62,2.23-10.45,5.6h-.05v.05c-1.8,1.5-3.32,3.08-4.55,4.75-1.3,1.73-2.15,3.33-2.55,4.8-1.83,6.83.25,11.05,6.25,12.65,2.5.67,5.28.67,8.35,0,3.07-.67,5.83-1.9,8.3-3.7h.05c2.13-2.2,3.77-4.78,4.9-7.75,1.1-2.93,1.5-5.7,1.2-8.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_32_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 32 FILL">
		                    <path className={styles.cls9} d="m726.22,113.24c-.4-.63-.88-.98-1.45-1.05-.23-.03-.53,0-.9.1-.43.17-.75.5-.95,1-.1.3-.23.77-.4,1.4-.47,1.3-1.08,2.48-1.85,3.55-1.17,1.57-2.57,2.8-4.2,3.7-1.33.73-3.03,1.33-5.1,1.8l-.15-.1-.05.15-.15.05.1.15c-.7,2-1.48,3.63-2.35,4.9-1.03,1.5-2.4,2.75-4.1,3.75-1.37.83-3.23,1.48-5.6,1.95-2.6.53-4.6.57-6,.1-.53-.17-1.05-.12-1.55.15-.47.23-.78.62-.95,1.15-.17.53-.12,1.05.15,1.55.27.5.67.83,1.2,1,2.13.67,4.85.67,8.15,0,2.8-.57,5.03-1.37,6.7-2.4,1.07-.63,2.03-1.33,2.9-2.1,1.9-1.73,3.43-4.02,4.6-6.85,2.9-.8,5.32-2.08,7.25-3.85.9-.8,1.7-1.68,2.4-2.65,1.63-2.17,2.53-4.03,2.7-5.6.07-.63-.07-1.25-.4-1.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_33_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 33 FILL">
		                    <path className={styles.cls9} d="m707.02,107.14c-.6-.17-1.15-.1-1.65.2-.53.27-.87.7-1,1.3-.17.57-.1,1.12.2,1.65,2.27,3.9,3.88,7.63,4.85,11.2.47,1.8.72,3.23.75,4.3,0,.6.22,1.12.65,1.55.4.43.9.67,1.5.7.63,0,1.17-.22,1.6-.65.4-.4.6-.88.6-1.45,0-1.3-.27-2.98-.8-5.05-1.03-4.1-2.83-8.35-5.4-12.75-.3-.53-.73-.87-1.3-1Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_34_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 34 FILL">
		                    <path className={styles.cls9} d="m616.87,127.74c.2-.67.33-1.72.4-3.15.17-3.5.9-6.4,2.2-8.7,1.53-2.7,3.7-4.3,6.5-4.8.67-.13,1.9-.1,3.7.1,1.63.17,2.85.13,3.65-.1,1.7-.5,2.17-1.4,1.4-2.7-.6-1-1.63-1.9-3.1-2.7-3.73-2.07-7.5-2.15-11.3-.25-3.47,1.7-5.93,4.45-7.4,8.25-.9,2.3-1.42,5.45-1.55,9.45-.23,5.17.98,7.38,3.65,6.65.5-.13.95-.47,1.35-1l.5-1.05Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_35_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 35 FILL">
		                    <path className={styles.cls9} d="m657.37,75.19c1.2-2.17,2.92-3.43,5.15-3.8.53-.1,1.53-.07,3,.1,1.3.17,2.28.15,2.95-.05,1.37-.37,1.73-1.08,1.1-2.15-.5-.83-1.35-1.6-2.55-2.3-3.03-1.77-6.07-1.9-9.1-.4-2.8,1.37-4.77,3.6-5.9,6.7-.67,1.97-1.03,4.55-1.1,7.75-.1,4.23.92,6.07,3.05,5.5.37-.07.72-.33,1.05-.8l.4-.85c.13-.4.23-1.27.3-2.6.1-2.87.65-5.23,1.65-7.1Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_36_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 36 FILL">
		                    <path
			                    className={styles.cls16}
			                    d="m631.52,31.19c-10.13-2.97-21.68-3.42-34.65-1.35-12.33,2-25.63,9.17-39.9,21.5-12.3,10.63-20.75,20.55-25.35,29.75-.4.73-.85,1.68-1.35,2.85,2.97-3.87,6.77-6.05,11.4-6.55,3.9-.43,7.38,1.1,10.45,4.6,2.8,3.17,4.78,7.3,5.95,12.4,1.17,5.07,1.22,9.7.15,13.9-1.2,4.63-3.53,7.72-7,9.25-3.1,1.33-6.62,1.22-10.55-.35-4.53-1.8-8.23-5.2-11.1-10.2-2.3-3.97-3.37-8.07-3.2-12.3-4.33,15.63-4.03,31.58.9,47.85,5.2,17.17,14.77,31.85,28.7,44.05,12.73,11.13,25.6,17.33,38.6,18.6,5.3.53,10.08.13,14.35-1.2,3.97-1.27,6.93-3.13,8.9-5.6,1.73-2.17,1.83-5.23.3-9.2-1.13-3.03-3.6-7.32-7.4-12.85-4.73-6.73-8.33-11.95-10.8-15.65-4.33-6.57-7.62-12.43-9.85-17.6-3.57-7-3.85-15.43-.85-25.3,2.7-8.93,7.83-18.33,15.4-28.2,7.17-9.3,15.57-17.85,25.2-25.65,9.67-7.83,19.12-13.7,28.35-17.6-8.73-7.4-17.62-12.45-26.65-15.15Z"
			                    style={{ fill: `${teamColors[HELMET_BG]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_37_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 37 FILL">
		                    <path
			                    className={styles.cls13}
			                    d="m546.37,75.94c-2.23-.77-4.48-.95-6.75-.55-2.23.4-4.28,1.35-6.15,2.85-1.83,1.5-3.33,3.45-4.5,5.85-2.4,4.77-3.05,10.13-1.95,16.1,1.07,5.93,3.55,10.73,7.45,14.4,4.03,3.77,8.37,5.23,13,4.4,2.17-.4,4.13-1.28,5.9-2.65,1.9-1.5,3.48-3.52,4.75-6.05,2.4-4.77,3.05-10.13,1.95-16.1-1.07-5.93-3.55-10.73-7.45-14.4-1.93-1.83-4.02-3.12-6.25-3.85m-14.7,9.45c1-2.07,2.3-3.72,3.9-4.95,1.33-1.1,2.85-1.8,4.55-2.1,3.67-.67,7.15.55,10.45,3.65,3.37,3.17,5.55,7.42,6.55,12.75.93,5.3.37,10.05-1.7,14.25-.93,1.93-2.13,3.52-3.6,4.75-1.47,1.2-3.08,1.97-4.85,2.3-3.67.67-7.15-.55-10.45-3.65-3.37-3.17-5.55-7.42-6.55-12.75-.93-5.3-.37-10.05,1.7-14.25Z"
			                    style={{ fill: `${teamColors[HELMET_HOLE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_38_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 38 FILL">
		                    <path
			                    className={styles.cls18}
			                    d="m612.92,81.54l12.1-9.5c-9.87-11.03-18.72-18.93-26.55-23.7-10.4-6.33-20.5-8.3-30.3-5.9-3.2,1.5-6.82,4.03-10.85,7.6-3.07,2.7-5.77,5.42-8.1,8.15,8.9-.9,19.12.67,30.65,4.7,12.2,4.3,23.22,10.52,33.05,18.65Z"
			                    style={{ fill: `${teamColors[HELMET_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_39_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 39 FILL">
		                    <path className={styles.cls12} d="m731.42,69.74c-.97-.73-2.07-1.03-3.3-.9-15.77,1.97-28.22,7.47-37.35,16.5-9.17,9-14.93,21.45-17.3,37.35-9.23,10.53-21.12,18.4-35.65,23.6-10.23,3.63-22.25,6.03-36.05,7.2-4.93-10.6-8.15-19.32-9.65-26.15-1.07-4.77-1.27-8.35-.6-10.75,2.6-9.63,9.95-20.93,22.05-33.9,13.8-14.83,29.43-26.6,46.9-35.3,1.23-.63,2.07-1.6,2.5-2.9.43-1.3.33-2.57-.3-3.8-.6-1.23-1.55-2.08-2.85-2.55-1.3-.43-2.58-.33-3.85.3-19.3,9.6-36.3,22.55-51,38.85-12.5,13.83-20.2,26.05-23.1,36.65-2.23,8.07.85,21.48,9.25,40.25l-.75.05c-1.03.07-1.95.45-2.75,1.15-.77.67-1.25,1.52-1.45,2.55-1.27,7.13,2.25,14.67,10.55,22.6.6.6,1.33,1,2.2,1.2,17.8,3.7,34.08,5.85,48.85,6.45,14.77.6,26.28-.42,34.55-3.05,6.4-2.03,14.67-6.27,24.8-12.7,7.17-4.53,13.43-8.9,18.8-13.1,7.53-5.93,13.65-12.18,18.35-18.75,4.67-6.6,7.6-13.1,8.8-19.5.23-1.23,0-2.35-.7-3.35-.7-1.03-1.67-1.67-2.9-1.9-1.3-.23-2.47.05-3.5.85-7.57-13-13.23-25.97-17-38.9l.25-.05c1.23-.13,2.25-.7,3.05-1.7.77-.97,1.07-2.07.9-3.3-.17-1.23-.75-2.23-1.75-3m-35.25,23c6.43-6.77,15.07-11.35,25.9-13.75,4.27,15.07,11.13,30.35,20.6,45.85-3.3,8.8-10.17,17.47-20.6,26-10.2-13.8-23.18-24.12-38.95-30.95,2.27-11.33,6.62-20.38,13.05-27.15m-21.15,85c-7.93,1.6-18.32,2-31.15,1.2-12.9-.8-26.85-2.72-41.85-5.75-3.73-3.8-6-7.2-6.8-10.2,11.1-.7,20.65-1.87,28.65-3.5,12.27-2.53,23.15-6.45,32.65-11.75l18.5,30m6.15-50.2c.1-.1.25-.32.45-.65,14.07,6.3,25.7,15.72,34.9,28.25-5.37,3.97-10.87,7.68-16.5,11.15-7.17,4.4-13.18,7.53-18.05,9.4l-19.45-31.6c7.13-4.77,13.35-10.28,18.65-16.55Z"/>
		                </g>
		                <g id="FlashAICB_Assets_2_Layer_1_Clip_Group_0_0_Layer2_40_FILL" data-name="FlashAICB Assets 2 Layer 1  Clip Group  0 0 Layer2 40 FILL">
		                    <path className={styles.cls8} d="m532.67,77.74c-5.73,1.07-11.08,3.23-16.05,6.5-17.6,11.63-29.18,22.92-34.75,33.85-6.67,13.1-5.43,26.75,3.7,40.95,1.6,2.47,4.65,5.72,9.15,9.75,4.67,4.2,9.58,8.03,14.75,11.5,13.27,8.93,21.92,11.6,25.95,8,5.87-5.27,10.63-20.37,14.3-45.3,1.1-7.63,2.12-16.38,3.05-26.25.73-8.53,1.18-13.33,1.35-14.4.6-4.2.43-8.22-.5-12.05-.97-4.03-2.65-7.17-5.05-9.4-3.63-3.37-8.93-4.42-15.9-3.15Z"/>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	);
};