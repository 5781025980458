exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".WellnessReprocessingRequestForm__root .form-group label{width:160px}.WellnessReprocessingRequestForm__changeList{border:solid 1px #ddd;border-radius:3px;padding:0.75%;background-color:#f5f5f5;width:100%;max-height:40vh;overflow-y:auto}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/admin/widgets/WellnessReprocessingRequestForm.scss"],"names":[],"mappings":"AAAA,yDAA0C,WAAW,CAAC,6CAAY,sBAAsB,kBAAkB,cAAc,yBAAyB,WAAW,gBAAgB,eAAe,CAAC","file":"WellnessReprocessingRequestForm.scss","sourcesContent":[".root :global(.form-group) :global(label){width:160px}.changeList{border:solid 1px #ddd;border-radius:3px;padding:0.75%;background-color:#f5f5f5;width:100%;max-height:40vh;overflow-y:auto}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "WellnessReprocessingRequestForm__root",
	"changeList": "WellnessReprocessingRequestForm__changeList"
};