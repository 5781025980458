import React, { useEffect } from "react";
import PropTypes from "prop-types";
import styles from "./Downloads.scss";
import * as TileTypes from "constants/TileTypes";
import {connect} from "react-redux";
import * as ClinicActions from "actions/ClinicActions";
import { downloadClinicSupportFile } from "api/ClinicApi";
import { map, snakeCase, sortBy } from "lodash";
import GridTile from "components/common/GridTile";
import { downloadFile } from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";

function Downloads(props) {
    useEffect(() => {
        if(props.canViewSupportDownloads && !props.files.length) {
            props.getClinicSupportFiles();
        }
    }, [props.canViewSupportDownloads]);

    if (!props.canViewSupportDownloads) {
        return (
            <AccessDenied customMessage="You do not have permission to view the support downloads"/>
        );
    }

    const download = async id => {
        const res = await downloadClinicSupportFile(id);
        downloadFile(res);
    };

    const renderDownloadTiles = () => {
        if (props.files && props.files.length === 0) {
            return <div>No files available</div>;
        }

        const tiles = map(props.files, file => ({
            id: file.id,
            title: file.name,
            description: file.description,
            largeIcon: "fas fa-cloud-download",
            linkUrl: `${window.location.href}download/${snakeCase(file.name)}`, // Fake URL just for aesthetics
            type: TileTypes.EXTERNAL_LINK,
            displayOrder: file.displayOrder,
        }));

        // Since this isn't a normal link, we have to wrap and intercept the click to call the API to download the file.
        return map(tiles, (tile, i) => (
            <div
                data-testid={`download_component_tile_${tile.id}`}
                key={tile.id}
                onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    download(tile.id);
                }}
            >
                <GridTile {...tile} />
            </div>
        ));
    }

    return (
        <div data-testid="downloads_component">
            <section data-testid="downloads_component_tiles">
                <div className={styles.grid}>
                    {renderDownloadTiles()}
                </div>
            </section>
        </div>
    );
}

Downloads.defaultProps = {
    canViewSupportDownloads: false,
    files: [],
};

Downloads.propTypes = {
    canViewSupportDownloads: PropTypes.bool,
    files: PropTypes.array,
    getClinicSupportFiles: PropTypes.func.isRequired,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewSupportDownloads = userHasPermission(PermissionTypes.DOWNLOADS, UserPermissions.SUPPORT, userProfile);
        return {
            canViewSupportDownloads,
            files: sortBy(state.entities.clinicSupportFiles, "displayOrder"),
        }
    },
    (dispatch) => ({
        getClinicSupportFiles: () => dispatch(ClinicActions.getClinicSupportFiles()),
    })
)(Downloads);
