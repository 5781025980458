import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";
import styles from "./Sidebar.scss";
import * as UserActions from "actions/UserActions";
import AddProgramButton from "components/clinic/widgets/AddProgramButton";
import ClinicSelect from "components/clinic/elements/ClinicSelect";
import ClinicSettingsButton from "components/clinic/widgets/ClinicSettingsButton";
import * as ClinicData from "utils/ClinicData";
import { canUserAccessPage, PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

export function SidebarClinicInfo(props) {
    if (!props.clinicInfo) {
        return null;
    }

    const manageUsersPath = "/clinic/users";
    const canManageUsers = canUserAccessPage(props.userProfile, manageUsersPath);
    const onChangeClinic = (clinicId) => {
        props.changeClinic(clinicId);
    }

    return (
        <div
            data-testid="sidebar_clinic_info_component"
            className={styles.clinicInfo}
        >
            <div className={styles.clinicBrand}>
                    <div className={styles.clinic}>
                        <ClinicSelect
                            clinicInfo={props.clinicInfo}
                            onChangeClinic={onChangeClinic}
                            hasMultipleClinics={props.userClinics?.length > 1}
                            clinics={props.userClinics}
                        />
                    </div>
                </div>
                <div className={styles.clinicLinks}>
                    {canManageUsers && (
                        <Link
                            data-testid="sidebar_users_link"
                            to={`${props.basePath}${manageUsersPath}`}
                        >
                            {props.collapsed ? (
                                <i
                                    data-testid="sidebar_users_link_icon"
                                    className="fas fa-users"
                                />
                            ) : (
                                <span
                                    data-testid="sidebar_users_link_text"
                                    className={styles.text}
                                >
                                    Users
                                </span>
                            )}
                        </Link>
                    )}
                    {props.canViewClinicInfo && (
                        <ClinicSettingsButton
                            collapsed={props.collapsed}
                            className={styles.text}
                            clinicId={props.clinicId}
                        />
                    )}
                </div>
                <div className={styles.clinicLinks}>
                    <AddProgramButton
                        collapsed={props.collapsed}
                        clinicId={props.clinicId}
                    />
                </div>
        </div>
    );
}

SidebarClinicInfo.propTypes = {
    clinicId: PropTypes.number.isRequired,
    clinicInfo: PropTypes.object.isRequired,
    changeClinic: PropTypes.func.isRequired,
    collapsed: PropTypes.bool,
    userProfile: PropTypes.object,
    userClinics: PropTypes.arrayOf(PropTypes.object).isRequired,
    canViewClinicInfo: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const clinicInfo = ClinicData.getClinicInfo(state, ownProps.clinicId);
        //Permissions
        const canViewClinicInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_INFO, userProfile);

        return {
            userProfile,
            clinicInfo,
            userClinics: userProfile.clinics,

            //Permissions
            canViewClinicInfo,
        };
    },
    (dispatch) => ({
        changeClinic: (clinicId) => dispatch(UserActions.changeUserLocation(clinicId)),
    })
)(SidebarClinicInfo);
