import React from "react";
import PropTypes from "prop-types";
import Leaderboard from "./Leaderboard";

export default function PuppyPoolPartyLeaderboard(props) {
    return (
        <Leaderboard
            puppyData={props.data}
            currentClinicId={props.currentClinicId}
            isAnimated={props.animate}
            showAllPoints={props.snapshot}
        />
    );
}

PuppyPoolPartyLeaderboard.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        ClinicId: PropTypes.number,
        ClinicAlias: PropTypes.string,
        TotalPuppyBowlPoints: PropTypes.number,
        Place: PropTypes.number,
        PercentGrowth: PropTypes.number,
    })),
    currentClinicId: PropTypes.number,
    snapshot: PropTypes.bool,
    animate: PropTypes.bool,
};

PuppyPoolPartyLeaderboard.defaultProps = {
    animate: false,
    snapshot: false,
};
