import React  from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import NotificationList from "components/notifications/widgets/NotificationList";

export default function NotificationsDashboardWidget(props) {
    const {
        basePath = null,
        limit,
        notifications,
        onClick,
        showDismissed = false,
        unread,
    } = props;
    const viewingMessage = unread > limit ? `${limit} of ${unread}` : null;

    return (
        <>
            <div className="sectionHeader">
                <h3 className="flex-1">Notifications</h3>
                {viewingMessage && <span className="subHeader">{viewingMessage}</span>}
                {(basePath !== null) && (
                    <Link to={`${basePath}/notifications`} className="text-primary flex-1 text-right">
                        View all <i className="fas fa-chevron-right" />
                    </Link>
                )}
            </div>
            <NotificationList
                clickHandler={onClick}
                limit={limit}
                notifications={notifications}
                showDismissed={showDismissed}
            />
        </>
    )
}

NotificationsDashboardWidget.propTypes = {
    basePath: PropTypes.string,
    limit: PropTypes.number,
    notifications: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onClick: PropTypes.func,
    showDismissed: PropTypes.bool,
    unread: PropTypes.number,
};
