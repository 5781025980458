import get from "lodash/get";

export default function getIsClinicFilterDataLoaded(state) {
    const providers = get(state, "entities.providers", {});
    const activeOffers = get(state, "entities.offers", {});
    const providerReports = get(state, "entities.providerReports", {});
    const softwareVendors = get(state, "entities.softwareVendors.data", {});

    const hasProviderData = Object.keys(providers).length > 0;
    const hasActiveOffers = Object.keys(activeOffers).length > 0;
    const hasProviderReports = Object.keys(providerReports).length > 0;
    const hasSoftwareVendors = Object.keys(softwareVendors).length > 0;
    return hasProviderData && hasActiveOffers && hasProviderReports && hasSoftwareVendors;
}
