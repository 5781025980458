import filter from "lodash/filter";
import snakeCase from "lodash/snakeCase";

const ACTIVE = "active";
const PAID = "Paid";
const EXPIRED = "Expired";

export const SubscriptionStatuses = {
    ACTIVE,
    PAID,
    EXPIRED
}

// Find subscriptions that meet certain specifications
export function clinicSubscriptions(state, clinicId, providerId=null, subscriptionStatus=null) {
    if (!state || !clinicId) {
        return false;
    }

    let subscriptions = state.entities.clinicSubscriptions?.[clinicId] || {};

    // For Provider
    if(providerId) {
        subscriptions = filter(subscriptions, {providerId: providerId});
    }

    // Return Only specifiedStatus
    if(subscriptionStatus) {
        subscriptions = filter(subscriptions, s => {return snakeCase(s.paymentStatus) === snakeCase(subscriptionStatus)})
    }

    return subscriptions
}