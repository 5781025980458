import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import find from "lodash/find";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as WellnessApi from "api/WellnessApi";
import Button from "components/common/Button";
import DropdownSearch from "components/common/DropdownSearch";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as ButtonTypes from "constants/ButtonTypes";
import * as UserPermissions from "constants/UserPermissions";

function PcpPetPlanEditForm(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState(null);
    const [disabledOptions, setDisabledOptions] = useState([]);
    const [selected, setSelected] = useState(null);

    const loadPremierPetPlans = async (clinicId, onComplete) => {
        try {
            const res = await WellnessApi.loadPremierPetPlans(clinicId);
            onComplete(res.body);
        } catch (error) {
            handleErrorResponse("loading pet plans", error);
        }
    };

    useEffect(() => {
        if(props.canViewClinicWellnessPetPlans) {
            setIsLoading(true);
            loadPremierPetPlans(props.clinicId, allPlans => {
                setIsLoading(false);
                if (!!props.plan.externalPetPlanReference) {
                    setOptions(
                        map(orderBy(allPlans, ["isAssigned", "petName"], ["asc", "asc"]), p => ({
                            name: `${p.petName} / ${p.petOwnerFirstName} ${p.petOwnerLastName} - ${p.wellnessProviderId}`,
                            value: p.petPlanReference
                        }))
                    );
                    setDisabledOptions(
                        map(filter(allPlans, p => p.isAssigned), opt => opt.petPlanReference)
                    );

                    const selectedPlan = find(allPlans, p => p.petPlanReference === props.plan.externalPetPlanReference);
                    setSelected(selectedPlan.petPlanReference);
                } else {
                    const selectable = filter(allPlans, p => p.petId === props.plan.petId || !p.isAssigned);

                    setOptions(
                        map(selectable, p => ({
                            name: `${p.petName} / ${p.petOwnerFirstName} ${p.petOwnerLastName} - ${p.petPlanStatus}`,
                            value: p.petPlanReference,
                        }))
                    );
                }
            });
        }
    }, []);

    return (
        <>
            <h3 className="text-center">Please select the Pet Plan from the Available PCP Pet Plan List</h3>
            <div className="flex flex-column">
                <div className="flex margin-top-md">
                    <div style={{ width: "30%", fontWeight: 700 }}>Pet:</div>
                    <div>{props.plan.petName}</div>
                </div>
                <div className="flex margin-top-sm">
                    <div style={{ width: "30%", fontWeight: 700 }}>Client:</div>
                    <div>{props.plan.name}</div>
                </div>
                <div className="flex margin-top-sm">
                    <div style={{ width: "30%", fontWeight: 700 }}>Pet Plan:</div>
                    <div>{props.plan.planName}</div>
                </div>
                <div className="flex margin-top-sm align-center">
                    <div style={{ width: "30%", fontWeight: 700 }}>Premier Pet Plan:</div>
                    <div className={"flex-1"}>
                        {options &&
                            <DropdownSearch
                                placeholder="-- Search or Select --"
                                options={orderBy(options, o => {return includes(disabledOptions, o.value)})}
                                disabledOptions={disabledOptions}
                                name="premierPetPlan"
                                value={selected}
                                onChange={opt => setSelected(opt.value)}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className="flex justify-flex-end margin-top-md spaced-content">
                <Button
                    type={ButtonTypes.GRAY}
                    onClick={props.onCancel}
                >
                    Cancel
                </Button>
                <Button
                    disabled={!props.canEditClinicWellnessPetPlans}
                    onClick={() => props.onSave(selected)}
                >
                    Save
                </Button>
            </div>
            <SpinnerTakeover show={isLoading} />
        </>
    );
}

PcpPetPlanEditForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    clinicId: PropTypes.number.isRequired,
    plan: PropTypes.object.isRequired,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canEditClinicWellnessPetPlans = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        return {
            canViewClinicWellnessPetPlans,
            canEditClinicWellnessPetPlans,
        }
    }
)(PcpPetPlanEditForm);
