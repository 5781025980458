import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { compose } from "redux";
import * as QueryString from "query-string";
import styles from "./ClinicWellnessPlansPage.scss";
import AccessDenied from "components/common/AccessDenied";
import ClinicBase from "components/layout/ClinicBase";
import ClinicWellnessPlans from "components/wellness/widgets/ClinicWellnessPlans";
import GreenlineWellnessPlans from "components/greenlineWellness/GreenlineWellnessPlans";
import {clinicHasWellness, getClinicInfo} from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {CLINIC_TAGS} from "constants/AdminControls";
import {GREENLINE_WELLNESS, PREMIER_PET_CARE_PLAN} from "constants/ProviderIds";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

function ClinicWellnessPlansPage(props) {
    const handleSelectProvider = (providerId) => {
        props.history.push(`plans?providerId=${providerId}`)
    };

    return (
        <div>
            <ClinicBase
                collapsible
                searchConfig={SearchTypes.WELLNESS_PLANS}
                adminControls={CLINIC_TAGS}
                docTitle={"Wellness Plans"}
            >
                <div className={styles.root}>
                    <div className="topLine">
                        <h2>Wellness Plan Setup</h2>
                    </div>
                    {!(props.clinicHasWellness && props.canViewClinicWellnessPlans) ? (
                        <AccessDenied/>
                    ) : (props.selectedProvider === PREMIER_PET_CARE_PLAN) ? (
                        <ClinicWellnessPlans clinicId={props.clinicId} />
                    ) : props.selectedProvider === GREENLINE_WELLNESS ? (
                        <GreenlineWellnessPlans clinicId={props.clinicId} />
                    ) : (
                        <div className="flex flex-centered height-100-perc full-width">
                            <div>
                                <h2 className="text-center padding-bottom-md">Select Wellness Provider</h2>
                                <div className="flex height-100-perc full-width spaced-content flex-centered">
                                    <div
                                        onClick={() => handleSelectProvider(GREENLINE_WELLNESS)}
                                        className={styles.tile}
                                    >
                                        Greenline Wellness
                                    </div>
                                    <div
                                        onClick={() => handleSelectProvider(PREMIER_PET_CARE_PLAN)}
                                        className={styles.tile}
                                    >
                                        Premier Wellness
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </ClinicBase>
        </div>
    )
}

const connector = connect(
    (state, ownProps) => {
        const search = QueryString.parse(ownProps.location.search);
        const clinicInfo = getClinicInfo(state, ownProps.clinicId);
        const clinicId = clinicInfo ? clinicInfo.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const wellnessProviders = clinicInfo?.programs?.Wellness?.providers || [];
        let selectedProvider = parseInt(search?.providerId) || null;
        if (wellnessProviders.length === 1) {
            selectedProvider = wellnessProviders[0];
        }
        // Permissions
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        return {
            clinicId,
            selectedProvider,
            // Permissions
            clinicHasWellness: clinicHasWellness(state, clinicId),
            canViewClinicWellnessPlans
        }
    }
);

export default compose(
    connector,
    withRouter,
)(ClinicWellnessPlansPage);

