import React, { useMemo } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import styles from "./GamesThermometer.scss";
import map from "lodash/map";
import maxBy from "lodash/maxBy";

export default function GamesThermometer(props) {
    // Some of this data has to be made optional since this component is also used in thermometer.jsx
    const totalDoses = props.game.totalDoses || 0;
    const { tiers, scales } = props.game?.data?.options;
    const GameGoal = tiers ? maxBy(tiers, tier => tier.value).value : 0;
    const threeFourthsFirstGoal = scales ? Math.ceil(0.75*scales[0].MaxValue) : 0;
    const showAllTiers = totalDoses > threeFourthsFirstGoal;
    const lowest = showAllTiers ? threeFourthsFirstGoal : 0;
    const goalRange = GameGoal - lowest;
    const lastUpdated = props.game.reportDate ? moment(props.game.reportDate).format("MM/DD/YYYY") : null;

    const getFlyoutMessage = (index) => {

        if(index === 0) {
            // Last period
            return "Last Period";
        }

        const tier = tiers[index];
        let levelsMet = 0;
        for(let i=0;i < tiers.length; i++) {
            if(totalDoses > tiers[i].value) {
                levelsMet = i;
            }
        }

        if(totalDoses > tier.value) {
            // Goal Met !!
            return "You've earned a reward!!";
        } else if(levelsMet === index-1) {
            // How many to go
            const diff = tier.value - totalDoses;
            return `Only ${diff} more!!`
        }


        // Not close yet, tell them the prize
        return tier.label;
    }

    const tierLabelVisibility = useMemo(() => {
        if (tiers?.length) {
            const totalBarValue = tiers[tiers.length - 1].value;
            let lastTop = false;
            return map(tiers, (tier, index) => {
                const isLast = index === tiers.length - 1;
                const lastValue = index === 0 ? 0 : tiers[index - 1].value;
                const distance = Math.round(100 * ((tier.value - lastValue) / totalBarValue));
                let isTop = !isLast && (distance <= 2 || !lastTop);

                if (!isLast && isTop) {
                    const nextDistance = Math.round(100 * ((tiers[index + 1].value - tier.value) / totalBarValue));
                    if (index + 1 === tiers.length - 1 || lastTop) {
                        isTop = nextDistance <= 2
                    }
                }
                lastTop = isTop;
                return isTop
            });
        }
    }, [tiers]);

    const generateDataPoints = () => {
        const points = map(tiers, (tier, index) => {
            const pointClass = index === 0 ? styles.pointLine : styles.pointCircle;
            const isLast = index === tiers.length - 1;
            const isPrevious = index === 0;
            const xPerc = Math.min(Math.round(((tier.value - lowest) / goalRange) * 100), 100);
            const smallTier = !isPrevious && !showAllTiers && index !== 1;
            const leftAligned = isLast ? false : tiers[index+1].increasePercentage < 5;
            const topLabel = tierLabelVisibility[index];

            return (
                <div key={`point_${tier.value}`} className={classnames(styles.point, {
                    [styles.isMet]: totalDoses > tier.value,
                    [styles.end]: isLast,
                    [styles.rightFlyout]: isLast || xPerc > 75,
                    [styles.prevLine]: isPrevious,
                    [styles.smallTier]: smallTier,
                    [styles.topLabel]: topLabel,
                })} style={{left: `${xPerc}%`}}>
                    <div className={pointClass}>
                        <div className={styles.inner} />
                    </div>

                    <div className={classnames(styles.pointInfo, {
                        [styles.leftAligned]: leftAligned && false,
                    })}>
                        {smallTier ? <i className="fas fa-circle" /> : tier.displayValue }
                    </div>
                    <div className={styles.pointFlyout}>
                        { getFlyoutMessage(index, tier.value) }
                    </div>
                </div>
            );
        });

        const firstPoint = (
            <div key="current_period" className={classnames(styles.point, styles.leftFlyout, styles.current, {
                [styles.isMet]: totalDoses > 0,
            })}>
                <div className={styles.pointCircle} />
                <div className={styles.currentTotal}>
                    {totalDoses}
                </div>
                {!!lastUpdated &&
                    <div className={styles.pointFlyout}>
                        As of {lastUpdated}
                    </div>
                }
            </div>
        );

        return [firstPoint, ...points];
    }

    const overMinimum = totalDoses > lowest ? totalDoses - lowest : 0;
    const progressWidth = Math.min(Math.round(100 * (overMinimum / goalRange)), 100);

    return (
        <div className={styles.root}>
            <div className={styles.bar} />
            <div className={styles.goal} />
            <div className={styles.progress} style={{ width: `${progressWidth}%` }} />
            <div className={styles.points}>
                {generateDataPoints()}
            </div>
        </div>
    )
}

GamesThermometer.propTypes = {
    game: PropTypes.object.isRequired,
};
