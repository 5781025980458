import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as ClinicActions from "actions/ClinicActions";
import * as CouponActions from "actions/CouponActions";
import AdminBase from 'components/layout/AdminBase';
import AdminMap from 'components/admin/widgets/AdminMap';
import {intersection, keys, map, values, filter} from 'lodash';
import { InfoWindow } from '@react-google-maps/api';
import AdminClinicMarkerInfoWindow from 'components/admin/widgets/AdminClinicMarkerInfoWindow';
import AdminClinicMapTable from 'components/admin/widgets/AdminClinicMapTable';
import Modal from "components/common/Modal";
import ClinicDetails from "components/clinic/widgets/ClinicDetails";
import Button from "components/common/Button";
import { Link } from "react-router-dom";
import { getLinkLocation } from "utils/ClinicAdminLinks";
import MultiSelectDropdown from 'components/common/Dropdown/MultiSelectDropdown';
import ExportClinicData from "components/admin/widgets/ExportClinicData";
import getClinicsInMapBounds from 'utils/getClinicsInMapBounds';

function AdminClinicsMapPage(props) {
    const [providerFilter, setProviderFilter] = useState([]);
    const [infoWindowMarkerId, setInfoWindowMarkerId] = useState(null);
    const [showClinicId, setShowClinicId] = useState(null);
    const [exportClinicIds, setExportClinicIds] = useState(null);
    const [clinics, setClinics] = useState([]); // Visible in current map viewport
    const [visibleClinics, setVisibleClinics] = useState([]); // Visible in current map viewport

    useEffect(() => {
        if (!props.hasProviders) {
            props.getProviderList();
        }
    }, []);

    useEffect(() => {
        let updatedClinics = [];
        if (providerFilter && providerFilter.length > 0) {
            updatedClinics = map(props.clinics, clinic => {
                if (intersection(clinic.providerIds, providerFilter).length > 0) {
                    return { ...clinic, visible: true };
                } else {
                    return { ...clinic, visible: false };
                }
            });
        } else {
            updatedClinics = map(props.clinics, c => {
                return { ...c, visible: true };
            });
        }

        setClinics(updatedClinics);
    }, [props.clinics, providerFilter]);

    useEffect(() => {
        const visibleClinicsNow = filter(clinics, c => c.visible);
        setVisibleClinics(visibleClinicsNow);
    }, [clinics]);

    function handleProviderFilterChange(data) {
        setProviderFilter(data.value); // `data.value` will be an array of provider ids (e.g., 1 == BI)
    }

    const handleBoundsChange = bounds => {
        const providerClinics = filter(clinics, c => {return c.visible});
        setVisibleClinics(getClinicsInMapBounds(bounds, providerClinics));
    };

    const handleMarkerClick = marker => {
        setInfoWindowMarkerId(marker.id);
    };

    const handleRowClick = row => {
        setShowClinicId(row.id);
    };

    const handleCloseModal = () => {
        setShowClinicId(null);
    };

    const handleExportCsvClick = () => {
        setExportClinicIds(map(visibleClinics, c => c.id));
    };

    const renderMarkerChildren = marker => {
        if (infoWindowMarkerId === marker.id) {
            return (
                <InfoWindow>
                    <AdminClinicMarkerInfoWindow clinic={marker} />
                </InfoWindow>
            );
        }

        return null;
    };

    return (
        <AdminBase
            pageTitle="Greenline Admin Map"
            collapsible
        >
            <div className="full-width full-height">
                <div className="margin-bottom-sm">
                    {/* <Button onClick={handleManageClick}>Manage Clinics</Button> */}
                    <div className="flex align-center">
                        <div className="margin-right-md">
                            <Button onClick={handleExportCsvClick}>
                                Export to CSV
                            </Button>
                        </div>
                        <span className="text-bold margin-right-sm">Filter:</span>
                        <div className="flex-1">
                            <MultiSelectDropdown
                                disabled={!props.hasProviders}
                                options={map(props.providers, p => { return { name: p.name, value: p.id } })}
                                name="providerFilter"
                                value={providerFilter}
                                onChange={handleProviderFilterChange}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div style={{ width: "30%", height: "70vh" }}>
                        <AdminClinicMapTable
                            clinics={visibleClinics || values(props.clinics)}
                            onRowClick={handleRowClick}
                        />
                    </div>
                    <div style={{ width: "70%", height: "70vh" }}>
                        <AdminMap
                            clinics={clinics}
                            // clinics={visibleClinics}
                            onMarkerClick={handleMarkerClick}
                            markerChildren={renderMarkerChildren}
                            onBoundsChange={handleBoundsChange}
                        />
                    </div>
                </div>
            </div>
            <Modal
                small
                show={!!exportClinicIds}
                onClose={() => setExportClinicIds(null)}
            >
                <ExportClinicData
                    clinicIds={exportClinicIds}
                    onComplete={() => setExportClinicIds(null)}
                />
            </Modal>
            <Modal
                show={!!showClinicId}
                onClose={handleCloseModal}
                small
            >
                <ClinicDetails clinicId={showClinicId} />
                <div className="margin-top-md">
                    <Link to={getLinkLocation("settings", showClinicId)}>
                        <Button onClick={handleCloseModal}>Edit</Button>
                    </Link>
                </div>
            </Modal>
        </AdminBase>
    );
}

export default connect(
    (state) => {
        const providers = state.entities.providers;
        return {
            clinics: state.entities.mappedClinics,
            hasProviders: keys(providers).length > 0,
            providers,
        };
    },
    (dispatch) => ({
        setClinicsTableIds: ids => dispatch(ClinicActions.setClinicsTableIds(ids)),
        getProviderList: () => dispatch(CouponActions.getProviderList()),
    }),
)(AdminClinicsMapPage);
