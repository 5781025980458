import React, {useEffect} from "react";
import PropTypes from "prop-types";
import Button from "components/common/Button";
import GameConfigEditorRow from "components/provider/widgets/GameConfigEditorRow";
import find from "lodash/find";
import last from "lodash/last";
import map from "lodash/map";
import sumBy from "lodash/sumBy";
import toNumber from "lodash/toNumber";
import { computeEstROI, computeIncreasePercentage, computePrize } from "utils/roiCalculations";
import classnames from "classnames";
import styles from "./GameRoiGoalEditor.scss";
const MAX_LEVELS = 5;

export default function GameRoiGoalEditor(props) {
    if (!(props.products?.length && props.reviewData)) {
        return (
            <div className={styles.prizes}>
                You must select a start date and at least one product and clinic to view.
            </div>
        );
    }
    const levels = props.config?.Levels || {};
    const numberOfLevels = Object.keys(levels).length;
    const totalPWholesalePrice = sumBy(props.reviewData, "pWholesalePrice");
    const clinicHasSoldProducts = !!find(props.products, p => {
        if (props.excludeFreeDoses) {
            return !!p.pPaidDoses
        }
        return !!p.pDoses;
    });

    const handleChange = (data, index=null) => {
        props.onChange(data, index);
    }

    useEffect(() => {
        //If the review data or product is changed then recalculate the levels
        if(props.reviewData && props.products?.length) {
            const newLevels = map(levels, l => {
                if (props.config.EstROILocked && props.config.PrizeAmountLocked && props.config.IncreasePercentageLocked) {
                    return l;
                }

                let EstROI = l.EstROI;
                let IncreasePercentage = l.IncreasePercentage;
                let PrizeAmount = l.PrizeAmount;

                // CHICKEN AND EGG SITUATION HERE...
                if (!props.config.IncreasePercentageLocked && !IncreasePercentage) {
                    IncreasePercentage = computeIncreasePercentage(l, props.products);
                }

                if (!props.config.PrizeAmountLocked && !PrizeAmount) {
                    PrizeAmount = computePrize(l, props.products);
                }

                if (!props.config.EstROILocked && !EstROI) {
                    EstROI = computeEstROI(l, props.products);
                }

                return {
                    ...l,
                    PrizeAmount,
                    IncreasePercentage,
                    EstROI,
                }
            });
            handleChange({name: "Levels", value: newLevels});
        }
    }, [props.reviewData, props.products?.length]);


    if (!clinicHasSoldProducts) {
        return null;
    }

    const handleAddLevelClicked = () => {
        if (numberOfLevels < MAX_LEVELS) {
            const lastLevel = last(levels);
            const updatedLevels = [...levels];

            const IncreasePercentage = (lastLevel && lastLevel.IncreasePercentage) ? parseInt(lastLevel.IncreasePercentage) + 5 : 10;
            const PrizeAmount = (lastLevel && lastLevel.PrizeAmount) ? parseInt(lastLevel.PrizeAmount) * 1.5 : 100;

            const levelData = {
                IncreasePercentage,
                PrizeAmount
            }
            const EstROI = computeEstROI(levelData, props.products);

            updatedLevels.push({
                DisplayName: "",
                IncreasePercentage,
                PrizeAmount,
                EstROI,
                level: numberOfLevels + 1
            });
            props.onChange({name: "Levels", value: updatedLevels});
        }
    };

    const handleRemoveLevelClicked = () => {
        if (numberOfLevels > 1) {
            levels.pop();
            props.onChange({name: "Levels", value: levels});
        }
    };

    const handleRowChange = (newData) => {
        const levelIndex = newData.level - 1;
        const updatedLevels = [...levels];
        updatedLevels[levelIndex] = { ...newData };
        props.onChange({name: "Levels", value: updatedLevels});
    }

    const lock = ({name, value}) => {
        return(
            <span>
                <Button
                    text
                    onClick={() => handleChange({name, value: !value})}
                    iconOnly
                    disabled={props.readOnly}
                >
                    <i className={classnames("fas", {
                        "fa-lock": !!value,
                        "fa-lock-open": !value,
                        "text-primary": !props.readOnly,
                        "pointer": !props.readOnly,
                        "text-gray": props.readOnly,
                        "not-allowed": props.readOnly,
                    })}/>
                </Button>
            </span>
        );
    }

    const renderTable = () => (
        <div className={styles.tableView}>
            <table className={"full-width"}>
                <thead>
                <tr>
                    <th>Level</th>
                    <th>Name<br/>(optional)</th>
                    <th>Percent Increase<br/>(in doses) {
                        lock({
                            name: "IncreasePercentageLocked",
                            value: props.config.IncreasePercentageLocked
                        })}
                    </th>
                    <th>Total Cost<br/>($) {
                        lock({
                            name: "PrizeAmountLocked",
                            value: props.config.PrizeAmountLocked}
                        )}
                    </th>
                    <th>Target ROI<br/>(min 5.0) {
                        lock({
                            name: "EstROILocked",
                            value: props.config.EstROILocked
                        })}
                    </th>
                </tr>
                </thead>
                <tbody>
                {levels.length > 0 &&
                map(levels, (level, index) => (
                    <GameConfigEditorRow
                        key={`level_${index}_row`}
                        products={props.products}
                        reviewData={props.reviewData}
                        excludeFreeDoses={props.excludeFreeDoses}
                        template={props.template}
                        totalPWholesalePrice={totalPWholesalePrice}
                        data={{ level: (toNumber(index) + 1), ...level }}
                        onChange={handleRowChange}
                        readOnly={props.readOnly}
                        increasePercentageLocked={props.config.IncreasePercentageLocked}
                        prizeAmountLocked={props.config.PrizeAmountLocked}
                        estROILocked={props.config.EstROILocked}
                        isROI
                    />
                ))
                }
                </tbody>
            </table>
        </div>
    );

    const renderMobileView = () => (
        <div className={styles.mobileView}>
            <table>
                <tbody>
                {levels.length > 0 &&
                map(levels, (level, index) => (
                    <GameConfigEditorRow
                        key={`level_${index}_row`}
                        products={props.products}
                        reviewData={props.reviewData}
                        excludeFreeDoses={props.excludeFreeDoses}
                        template={props.template}
                        totalPWholesalePrice={totalPWholesalePrice}
                        data={{ level: (toNumber(index) + 1), ...level }}
                        onChange={handleRowChange}
                        readOnly={props.readOnly}
                        increasePercentageLocked={props.config.IncreasePercentageLocked}
                        prizeAmountLocked={props.config.PrizeAmountLocked}
                        estROILocked={props.config.EstROILocked}
                        isROI
                        isMobileView
                        checkIsLocked={lock}
                    />
                ))
                }
                </tbody>
            </table>
        </div>
    )

    return (
        <div className={styles.prizes}>
            <div className="full-width flex flex-column">
                {renderTable()}
                {renderMobileView()}
                <div className="flex spaced-content margin-top-sm column-to-row-sm">
                    <Button
                        disabled={levels.length === MAX_LEVELS || props.readOnly}
                        onClick={handleAddLevelClicked}
                    >
                        <i className="fas fa-plus margin-right-x-sm" /> Add Level
                    </Button>
                    <Button
                        disabled={levels.length <= 1 || props.readOnly}
                        onClick={handleRemoveLevelClicked}
                    >
                        <i className="fas fa-minus margin-right-x-sm" /> Remove Last Level
                    </Button>
                </div>
            </div>
        </div>
    );
}

GameRoiGoalEditor.propTypes = {
    products: PropTypes.arrayOf(PropTypes.object),
    reviewData: PropTypes.arrayOf(PropTypes.object),
    config: PropTypes.object,
    excludeFreeDoses: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
}
