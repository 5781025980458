import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import find from "lodash/find";
import keys from "lodash/keys";
import map from "lodash/map";
import * as ProgramApi from "api/ProgramApi";
import Modal from "components/common/Modal";
import ProgramForm from "components/programs/ProgramForm";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { handleErrorResponse } from "utils/request";
import { CANCELED, DECLINED, ELIGIBLE, ENROLLED } from "constants/ProgramStatuses";
import CancelProgramEnrollmentForm from "components/programs/CancelProgramEnrollmentForm";

export default function ProgramOptInDetailsModal(props) {
	const {
		clinicId,
		linkBase = "",
		parentProgram,
		program,
		programId,
		onAfterSave,
		onClose,
		show = false,
	} = props;
	const [loading, setLoading] = useState(false);
    const [programDetails, setProgramDetails] = useState(null);
	const [showCancelModal, setShowCancelModal] = useState(false);
	const history = useHistory();

	const handleCancelClicked = () => {
		setShowCancelModal(true);
	};

	const handleAfterCancel = () => {
		setShowCancelModal(false);
		onClose();
		history.push(`${linkBase}/programs`);
	};

	const loadProgramOptInDetails = () => {
        setLoading(true);
        if (parentProgram) {
            ProgramApi.loadClinicProgramServiceOptInDetails(clinicId, programId)
                .then((res) => {
					const optInFormConfigJson = !!res.body?.optInFormConfigJson ? JSON.parse(res.body.optInFormConfigJson) : [];
					let formFieldsJson = !!res.body?.formFieldsJson ? JSON.parse(res.body.formFieldsJson) : [];
					if (optInFormConfigJson && keys(optInFormConfigJson)?.length) {
						const jsonConfig = find(formFieldsJson, { ProgramServiceName: config.ProgramServiceName });
						formFieldsJson = map(optInFormConfigJson, config => {
							return {
								ProgramServiceName: config.ProgramServiceName,
								DoEnroll: true,
								...jsonConfig,
							}
						});
					}

					setProgramDetails({
		                ...res.body,
	                    formFieldsJson,
		                optInFormConfigJson,
	                });
					setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    handleErrorResponse("loading clinic program service opt-in details", error);
                });
        } else {
            ProgramApi.loadClinicProgramOptInDetails(clinicId, programId)
                .then((res) => {
					const optInFormConfigJson = !!res.body?.optInFormConfigJson ? JSON.parse(res.body.optInFormConfigJson) : [];
					let formFieldsJson = !!res.body?.formFieldsJson ? JSON.parse(res.body.formFieldsJson) : [];
					if (optInFormConfigJson && keys(optInFormConfigJson)?.length) {
						formFieldsJson = map(optInFormConfigJson, config => {
							const jsonConfig = find(formFieldsJson, { ProgramServiceName: config.ProgramServiceName });
							return {
								ProgramServiceName: config.ProgramServiceName,
								DoEnroll: true,
								...jsonConfig,
							}
						});
					}

                    setProgramDetails({
		                ...res.body,
	                    formFieldsJson,
		                optInFormConfigJson,
	                });
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    handleErrorResponse("loading clinic program opt-in details", error);
                });
        }
    };

	useEffect(() => {
		if (show && clinicId && programId) {
			loadProgramOptInDetails();
		}
    }, [show, clinicId, programId]);

	if (!(show && clinicId && programId)) {
		return null;
	}

	if (loading) {
		return <SpinnerTakeover show />
	}

	return (
		<>
			<Modal
				show={show}
				onClose={onClose}
				modalTitle={(
					<div className="flex spaced-content">
						<div className="flex-1">{programDetails?.title}</div>
						<div className={classnames("padding-right-sm", {
							"text-success": program.status === ENROLLED || program.status === ELIGIBLE,
							"text-danger": program.status === DECLINED || program.status === CANCELED,
						})}>{program?.status}</div>
					</div>
				)}
				mediumSmall
			>
				<ProgramForm
					program={{
						...program,
						...programDetails
					}}
					onAfterSave={onAfterSave}
					onClose={onClose}
					parentProgram={parentProgram}
					onCancel={handleCancelClicked}
				/>
			</Modal>
			<Modal
				show={showCancelModal}
				modalTitle={programDetails?.cancellationWording}
				small
				onClose={() => setShowCancelModal(false)}
			>
				<CancelProgramEnrollmentForm
					clinicId={clinicId}
					programId={program.programId}
					programServiceId={program.programServiceId}
					onClose={() => setShowCancelModal(false)}
					onSave={handleAfterCancel}
				/>
			</Modal>
		</>
	);
}

ProgramOptInDetailsModal.propTypes = {
    clinicId: PropTypes.number.isRequired,
	linkBase: PropTypes.string,
	parentProgram: PropTypes.number,
    program: PropTypes.object,
    programId: PropTypes.number,
	onAfterSave: PropTypes.func,
    onClose: PropTypes.func.isRequired,
	show: PropTypes.bool,
};
