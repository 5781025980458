import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./PuppyPoolParty.scss";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import DataTable from "components/common/datatable/DataTable";
import Image from "components/common/Image";
import Loader from "components/common/Loader";
import PuppyPoolPartyLeaderboard from "components/games/widgets/PuppyPoolPartyLeaderboard";
import { addCommasToNumber } from "utils/numeric";

const LARGE_BANNER_AD = require("../../../../../../../img/games/pool_party/pool_party_banner.jpg");

export default function PuppyPoolParty(props) {
    const [gameLoaded, setGameLoaded] = useState(false);
    const [teams, setTeams] = useState([]);

    const clinicsList = useMemo(() => {
        if (props.game) {
            return orderBy(props.game.teams, "Place");
        }
        return [];   
    }, [props.game]);

    useEffect(() => {
        if (props.game?.teams && clinicsList.length) {
            setTeams(map(props.game.teams, team => {
                return {
                    ...team,
                    PercentGrowth: team.PercentGrowth ? team.PercentGrowth : 0
                }
            }));
            setGameLoaded(true);
        }
    }, [props.game, clinicsList]);

    const gameAnimation = useMemo(() => {
        if (gameLoaded) {
            const gameKey = props.clinicId ? `game_animation_${props.game.greenlineGameId}_clinic_${props.clinicId}` :
                `game_animation_${props.game.greenlineGameId}_day_${props.game.daysInto}`;
            return (
                <PuppyPoolPartyLeaderboard
                    key={gameKey}
                    currentClinicId={props.clinicId}
                    data={teams}
                    snapshot={props.snapshot}
                    animate={props.animate}
                />
            );
        } else {
            return <Loader />;
        }
    }, [gameLoaded, props.animate, props.snapshot, props.clinicId]);

    const getConditionalRowProps = (row) => {
        if (row.clinicId === props.clinicId) {
            return {
                style: {
                    backgroundColor: 'rgb(26, 85, 173)',
                    color: '#74FCF6',
                }
            };
        }
        return {};
    };

    const COLUMNS = [
        {
            name: "Rank",
            selector: "Place",
            key: "rank",
            sortable: false,
            searchable: false,
            colSettings: { width: "25px"},
        },{
            name: "Clinic Alias",
            selector: "ClinicAlias",
            key: "clinicAlias",
            sortable: false,
            searchable: false,
            format: row => (
                <span style={{ display: "inline-block", width: "100%", textAlign: "left"}}>{row.ClinicAlias}</span>
            )
        },{
            name: "Current Doses",
            selector: "totalDoses",
            key: "currentDoses",
            sortable: false,
            searchable: false,
            colSettings: { width: "60px"},
            format: row => {
                if (props.snapshot || (row.clinicId === props.clinicId)) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            {addCommasToNumber(row.totalDoses)}
                        </div>
                    )
                } else {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            <span>--</span>
                        </div>
                    )
                }
            }
        },{
            name: "Previous Doses",
            selector: "previousDoses",
            key: "previousDoses",
            sortable: false,
            searchable: false,
            colSettings: { width: "60px"},
            format: row => {
                if (props.snapshot || (row.clinicId === props.clinicId)) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            {addCommasToNumber(row.previousDoses)}
                        </div>
                    )
                } else {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            <span>--</span>
                        </div>
                    )
                }
            }
        },{
            name: "Pool Party Bonuses",
            selector: "bonuses",
            key: "bonuses",
            sortable: false,
            searchable: false,
            colSettings: { width: "60px" },
            format: row => {
                if (props.snapshot || (row.clinicId === props.clinicId)) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            {addCommasToNumber(Math.floor(row.ExtraDoses))}
                        </div>
                    )
                } else {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            <span>--</span>
                        </div>
                    )
                }
            }
        },{
            name: "To Go",
            selector: "DosesToGo",
            key: "dosesToGo",
            sortable: false,
            searchable: false,
            colSettings: { width: "60px"},
            format: row => {
                if (row.previousDoses > row.totalDoses && (props.snapshot || row.clinicId === props.clinicId)) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            <span style={{fontSize: "0.9em"}}>{Math.ceil((row.previousDoses - row.totalDoses))}</span>
                        </div>
                    );
                } else {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            <span>--</span>
                        </div>
                    );
                }
            }
        },{
            name: "VLY",
            selector: "PercentGrowth",
            key: "PercentGrowth",
            sortable: false,
            searchable: false,
            colSettings: {width: "60px"},
            format: row => {
                let fontColor = "#BB0000";

                if (row.PercentGrowth > 0) {
                    fontColor = "rgb(49, 125, 61)";
                }

                if (row.clinicId === props.clinicId) {
                    fontColor = "#FFFFFF";
                }

                // If there were no previous doses then this should never show a number
                if (row.previousDoses === 0) {
                    return (
                        <span style={{ color: '#000', display: "inline-block", width: "100%", textAlign: "center"}}>--</span>
                    );
                }

                //  If some have been sold then show the percentage compared to last year
                if (row.PercentGrowth > -100) {
                    return (
                        <span style={{ color: fontColor, display: "inline-block", width: "100%", textAlign: "center"}}>{Math.round(row.PercentGrowth + 100)}%</span>
                    );
                } else {
                    // Otherwise display 0%
                    return (
                        <span style={{ color: fontColor, display: "inline-block", width: "100%", textAlign: "center"}}>0%</span>
                    );
                }
            }
        },
        {
            name: "VLY - Without Bonuses",
            selector: "PercentGrowthNoBonus",
            key: "growthPercentageNoBonus",
            sortable: false,
            searchable: false,
            colSettings: {width: "60px"},
            format: row => {
                if (!row.previousDoses || row.previousDoses === 0) {
                    return <span style={{ color: '#000', display: "inline-block", width: "100%", textAlign: "center"}}>--</span>;
                }
                let fontColor = "#BB0000";
                const totalWithoutBonus = row.totalDoses - row.ExtraDoses;
                let roundedPercentGrowthNoBonus = Math.round(((totalWithoutBonus - row.previousDoses)/row.previousDoses) * 100) + 100;
                // if (row.previousDoses >= row.Doses) {
                //     roundedPercentGrowthNoBonus = 0;
                // } else {
                //     roundedPercentGrowthNoBonus =
                // }

                if (roundedPercentGrowthNoBonus > 100) {
                    fontColor = "rgb(49, 125, 61)";
                }
                if (row.clinicId === props.clinicId) {
                    fontColor = "#FFFFFF";
                }
                if (isFinite(roundedPercentGrowthNoBonus) && roundedPercentGrowthNoBonus > 0) {
                    return <span style={{ color: fontColor, display: "inline-block", width: "100%", textAlign: "center"}}>{roundedPercentGrowthNoBonus}%</span>;
                } else {
                    return <span style={{ color: fontColor, display: "inline-block", width: "100%", textAlign: "center"}}>0%</span>;
                }
            }
        }
    ];

    return (
        <div className={styles.root}>
            <div className={styles.bannerAd}>
                <Image
                    src={LARGE_BANNER_AD}
                    alt="HeartGard® Plus+ and NexGard® Plus+ advertisement"
                />
            </div>
            <div className={classnames(styles.animationWrapper, {
                [styles.snapshot]: props.snapshot,
            })}>
                <div className={styles.animation}>
                    {gameAnimation}
                </div>
            </div>
            <div className="page-break"/>
            <div className={styles.instructions}>
                <span>
                    When viewing the dogs in the pool, the number of doses shown are doses of HEARTGARD® Plus (ivermectin/pyrantel) and <em>NexGard</em>® (afoxolaner) sold so far in this game.
                    The percentage compares doses sold so far in this game versus doses sold in this timeframe last year. “VLY” stands for Versus Last Year.
                    <br/><br/>
                    Anything over the 100% line is exceeding last year's numbers — which means true year-over-year growth!
                </span>
            </div>
            <div className={styles.pointsLegend}>
                <h3>Legend:</h3>
                <div className={styles.legendItems}>
                    <div className={styles.legendItem}>
                        <span>24 bonus doses for every 12 pack of HEARTGARD® Plus (ivermectin/pyrantel) and <em>NexGard</em>® (afoxolaner) sold together!</span>
                    </div>
                </div>
            </div>
            <div className={styles.rankingsTable}>
                <DataTable
                    columns={COLUMNS}
                    data={clinicsList}
                    pagination={false}
                    green
                    useColgroup
                    conditionalRowProps={getConditionalRowProps}
                />
            </div>
        </div>
    );
}

PuppyPoolParty.defaultProps = {
    clinicId: undefined,
    animate: false,
    pointValues: {},
    snapshot: false,
};

PuppyPoolParty.propTypes = {
    game: PropTypes.object.isRequired,
    clinicId: PropTypes.number,
    animate: PropTypes.bool,
    pointValues: PropTypes.object,
    snapshot: PropTypes.bool,
};
