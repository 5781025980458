import React from "react";
import styles from "./WellnessDvmCommission.scss";
import ClinicBase from "components/layout/ClinicBase";
import DvmCommissionTable from "components/wellness/widgets/DvmCommissionTable";
import {connect} from "react-redux";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import {getUserCurrentClinic} from "utils/WellnessData";

function ClinicWellnessDvmCommissionPage(props) {
    return (
        <ClinicBase pageTitle={"View DVM Commission Setup"}>
            <div className={styles.root}>
                {props.canViewClinicWellnessDvms ? (
                    <DvmCommissionTable
                        clinicId={props.clinicId}
                    />
                ) : (
                    <AccessDenied/>
                )}
            </div>
        </ClinicBase>
    );
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        return {
            clinicId: getUserCurrentClinic(state),
            canViewClinicWellnessDvms,
        }
    }
)(ClinicWellnessDvmCommissionPage);
