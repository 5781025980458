import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import PetPlanTracking from "components/wellness/widgets/PetPlanTracking";
import ProviderBase from "components/layout/ProviderBase";
import getProviderId from "utils/getProviderId";
import {getNodeId} from "utils/AdminData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as ProviderLinks from "utils/ProviderLinks";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";
import PetPlanTrackingModal from "components/layout/widgets/PetPlanTrackingModal";
import { getClinicInfo } from "utils/ClinicData";
import * as WellnessActions from "actions/WellnessActions";


function ProviderPetPlanTracking(props)  {
    const goToWellnessSettings = () => {
        props.history.push(ProviderLinks.getLinkLocation("wellness-settings", {
            providerId: props.providerId,
            nodeId: props.nodeId,
            isAdmin: props.isAdmin,
            clinicId: props.clinicId,
        }));
    }


    const clearSelectedPlan = () => {
        props.clearSelectedWellnessPlan();
    };

    return (
        <ProviderBase
            location={props.location}
            searchConfig={SearchTypes.WELLNESS_PLANS_PAGE}
            clinicId={props.clinicId}
            nodeId={props.nodeId}
            providerId={props.providerId}
            docTitle={"Clinic Pet Plan Tracking"}
        >
            <div className="full-width">
                <div className="topLine flex">
                    {!props.canViewWellnessPetPlans && (
                        <h2 className="flex-none">Pet Plan Tracking</h2>
                    )}
                    <div className="flex-1 flex justify-flex-end">
                        <Button
                            small
                            icon
                            className="margin-right-x-sm"
                            type="primary"
                            onClick={goToWellnessSettings}
                        >
                            <i className="fa fa-reply"/> Back To Clinic Menu
                        </Button>
                    </div>
                </div>
                {props.canViewWellnessPetPlans ? (
                    <PetPlanTracking
                        clinicId={props.clinicId}
                    />
                ) : (
                    <AccessDenied/>
                )}
                {props.selectedId &&
                    <PetPlanTrackingModal
                        selectedId={props.selectedId}
                        onClose={clearSelectedPlan}
                        clinicId={props.clinicInfo ? props.clinicInfo.clinicId : null }
                    />
                }
            </div>
        </ProviderBase>
    );
}

PetPlanTrackingModal.propTypes = {
    clinicId: PropTypes.number.isRequired,
    isAdminView: PropTypes.bool,
}

export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const clinicId = Number(params?.clinicId) || null;
        const userProfile = state.user.userProfile;
        const nodeId = getNodeId(state, ownProps);
        const providerId = getProviderId(params, userProfile);

        // Permissions
        const canViewWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);

        return {
            clinicInfo: getClinicInfo(state, ownProps.clinicId),
            clinicId,
            nodeId,
            providerId,
            selectedId: state.entities.wellness.selectedId,
            //Permissions
            canViewWellnessPetPlans,
        }
    },
    (dispatch) => ({
        clearSelectedWellnessPlan: () => dispatch(WellnessActions.clearSelectedWellnessPlan()),
    })
)(ProviderPetPlanTracking);
