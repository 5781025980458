import Map from "components/maps/Map";
import { map } from "lodash";
import React from "react";
import filter from "lodash/filter";

export default function ProviderClinicMap(props) {
    const markers = map(props.markers, marker => {
        if(!!marker.nodeType) {
            return {
                ...marker,
                label: (marker.stats?.clinics?.goGreen || 0).toString(),
                key: marker.nodeId
            };
        } else {
            // This is for clinics
            return {
                ...marker,
                key: marker.clinicId
            };
        }
    });

    return (
        <div className="full-width full-height"
             // Make sure that the map is visible even if there is only 1 result
            style={{minHeight: "400px"}}
        >
            <Map
                mapOptions={{
                    mapTypeControl: false,
                    streetViewControl: false
                }}
                markers={filter(markers, m => !!(m.lat && m.lng))}
                onMarkerClick={props.onMarkerClick}
                onClusterClick={props.onClusterClick}
                markerChildren={props.markerChildren}
                highlight={[props.highlightMarker]}
                hoverStart={props.hoverStart}
                hoverEnd={props.hoverEnd}
            />
        </div>
    );
}
