import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as styles from "./MappingTable.scss";
import classnames from "classnames";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import ServiceTable from "components/admin/widgets/ServiceTable";
import WellnessMapping from "components/admin/widgets/WellnessMapping";
import VaccineTable from "components/admin/widgets/VaccineTable";
import MappingTable from "./MappingTable";
import VetCheckMappingTable from "./VetCheckMappingTable";
import { PRODUCT_TYPES } from "constants/ProductTypes";
import AccessDenied from "components/common/AccessDenied";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function Mapping(props) {
    return (
        <div className={classnames(styles.root, styles.adminMapping, "flex-column flex-1")}>
            {((PRODUCT_TYPES.PHARMACY === props.map) && props.canViewProductMapping) ? (
                <MappingTable
                    key={0}
                    clinicId={props.clinicId}
                    typeId={props.map}
                />
            ) : ((PRODUCT_TYPES.FOOD === props.map) && props.canViewProductMapping) ? (
                <MappingTable
                    key={1}
                    clinicId={props.clinicId}
                    typeId={props.map}
                />
            ) : ((PRODUCT_TYPES.VACCINES === props.map) && props.canViewVaccineMapping) ? (
                <VaccineTable
                    clinicId={props.clinicId}
                    typeId={props.map}
                />
            ) : ((PRODUCT_TYPES.SERVICES === props.map) && props.canViewExamServiceMapping) ? (
                <ServiceTable
                    clinicId={props.clinicId}
                    typeId={props.map}
                />
            ) : ((PRODUCT_TYPES.VETCHECK === props.map) && props.enrolledInVetCheck && props.canViewVetCheckMapping) ? (
                <VetCheckMappingTable
                    clinicId={props.clinicId}
                    typeId={props.map}
                />
            ) : ((PRODUCT_TYPES.WELLNESS === props.map) && props.enrolledInWellness && props.canViewWellnessMapping) ? (
                <WellnessMapping
                    clinicId={props.clinicId}
                    typeId={props.map}
                />
            ) : !!props.map ? (
                <AccessDenied/>
            ) : (
                <SpinnerTakeover show/>
            )}
        </div>
    )
}

Mapping.propTypes = {
    clinicId: PropTypes.number.isRequired,
    map: PropTypes.string.isRequired,
    enrolledInWellness: PropTypes.bool,
    enrolledInVetCheck: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewProductMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);
        const canViewVaccineMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VACCINE_MAPPING, userProfile);
        const canViewExamServiceMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.EXAM_SERVICE_MAPPING, userProfile);
        const canViewWellnessMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.WELLNESS_MAPPING, userProfile);
        const canViewVetCheckMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);
        return {
            canViewProductMapping,
            canViewVaccineMapping,
            canViewExamServiceMapping,
            canViewWellnessMapping,
            canViewVetCheckMapping,
            providers: state.entities.providers,
        }
    }
)(Mapping);
