import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import * as CouponsApi from "api/CouponsApi";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {handleErrorResponse} from "utils/request";
import * as ProviderIds from "constants/ProviderIds";

export default function ProgramPreferences(props) {
    const [formData, setFormData] = useState({});
    const [loading, setLoading] = useState(false);
    const canMakeChanges = (props.canEditClinicEnrollment && formData.clinicProgramEnrollmentId);

    useEffect(() => {
        setLoading(true);
        CouponsApi.getProviderPreferences(props.providerId, props.clinicId)
            .then(res => {
                setFormData(res.body);
                setLoading(false);
            })
            .catch((error) => {
                handleErrorResponse("loading provider preferences", error);
                setLoading(false);
            });
    }, []);

    const handleChange = ({name, value}) => {
        const data = {
            ...formData,
            [name]: value,
        };
        setFormData(data);
    }

    const handleSubmit = () => {
        props.onSubmit(formData);
    }

    if (loading) {
        return <SpinnerTakeover show/>
    }

    return (
        <div className="margin-top-md">
            {props.providerId === ProviderIds.BOEHRINGER_INGELHEIM ? (
                <>
                    <h3 className="margin-bottom-x-sm">Pet Owner Email Addresses</h3>
                    <label className="flex spaced-content">
                        <CheckboxInput
                            name="clinicLevelOptIn"
                            checked={formData.clinicLevelOptIn}
                            onChange={handleChange}
                            disabled={!canMakeChanges}
                        />
                        <div>
                            Opt-in to include pet owner email addresses along with required coupon data sent to the manufacturer.
                            Pet owners may receive timely, transactional communications only – THANK YOU FOR PURCHASING.
                            WE’VE RECEIVED YOUR COUPON. WE’RE SHIPPING YOUR PRE-PAID REBATE CARD, ETC.
                            <span className="margin-left-x-sm">
                                <a
                                    href="https://gldevcdn.azureedge.net/content/examples/bi/2019%20Invalid%20Emails%20for%20Coupon%20Process%20-Generic.pdf"
                                    className="text-primary"
                                    target="_blank"
                                >
                                    Click here for an example communication.
                                </a>
                            </span>
                        </div>
                    </label>
                    <hr className="margin-top-sm margin-bottom-sm"/>
                    <h3 className="margin-bottom-x-sm">DVM/Staff Level Detail</h3>
                    <label className="flex spaced-content">
                        <CheckboxInput
                            name="agreesToStaffDataShare"
                            checked={formData.agreesToStaffDataShare}
                            onChange={handleChange}
                            disabled={!canMakeChanges}
                        />
                        <div>
                            Opt-in to provide manufacturer DVM/staff-level transaction data for the purpose of the program, category, and other business analytics.
                        </div>
                    </label>
                    <hr className="margin-top-sm margin-bottom-md"/>
                    <div>Please refer to Greenline T&Cs for more information about data usage and privacy protections.</div>
                </>
            ) : (
                <>
                    <h3 className="margin-bottom-x-sm">Email Preferences</h3>
                    <CheckboxInput
                        name="clinicLevelOptIn"
                        checked={formData.clinicLevelOptIn}
                        onChange={handleChange}
                        label="Clinic level Opt in of client emails on qualifying coupon purchases"
                        disabled={!canMakeChanges}
                    />

                    <hr className="margin-top-sm margin-bottom-md"/>

                    <h3 className="margin-bottom-x-sm">Data Share</h3>
                    <CheckboxInput
                        name="agreesToStaffDataShare"
                        checked={formData.agreesToStaffDataShare}
                        onChange={handleChange}
                        label="RX Data Share - Staff"
                        disabled={!canMakeChanges}
                    />
                    <div className="margin-top-sm">
                        Market Share of flea, tick and heartworm products for a selected timeframe, by doctor and staff members.
                    </div>

                    <hr className="margin-top-sm margin-bottom-md"/>
                </>
            )}
            <div className="flex justify-flex-end spaced-content margin-top-md">
                <Button
                    type="gray"
                    onClick={props.onCancel}
                >
                    Cancel
                </Button>
                <Button
                    onClick={handleSubmit}
                    disabled={!canMakeChanges}
                >
                    Save Changes
                </Button>
            </div>
        </div>
    );
}

ProgramPreferences.propTypes = {
    providerId: PropTypes.number.isRequired,
    clinicId: PropTypes.number.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    canEditClinicEnrollment: PropTypes.bool,
};
