import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./PuppyLeafParty.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import get from "lodash/get";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import round from "lodash/round";
import DataTable from "components/common/datatable/DataTable";
import Image from "components/common/Image";
import { LeafPartyLeaderboardLegalWording } from "components/games/elements/LegalWording";
import Loader from "components/common/Loader";
import PuppyLeafPartyLeaderboard from "components/games/widgets/PuppyLeafPartyLeaderboard";
import Tooltip from "components/common/Tooltip";
import { addCommasToNumber } from "utils/numeric";

const LARGE_BANNER_AD = require("../../../../../../../img/games/leaf_party/leaf_party_banner_lg.jpg");

const getSumOfFieldFromList = (list, fieldSelector) => {
    let sum = 0;

    list.map(item => {
        sum += get(item, fieldSelector, 0);
    });

    return sum;
};

export default function PuppyLeafParty(props) {
    const [gameLoaded, setGameLoaded] = useState(false);
    const [teams, setTeams] = useState([]);

    const dosesToPlace = (doses, place) => {
        return `${addCommasToNumber(doses)} doses behind ${place} Place.`;
    };

    const getToWinHover = (place, dosesNeeded=[]) => {
        switch (place) {
            case 1:
                return ["You're in the lead!"];
            case 2:
                return [dosesToPlace(dosesNeeded.ToFirstPlace, "First")];
            case 3:
                return [
                    dosesToPlace(dosesNeeded.ToFirstPlace, "First"),
                    dosesToPlace(dosesNeeded.ToSecondPlace, "Second"),
                ];
            default:
                return [
                    dosesToPlace(dosesNeeded.ToFirstPlace, "First"),
                    dosesToPlace(dosesNeeded.ToSecondPlace, "Second"),
                    dosesToPlace(dosesNeeded.ToThirdPlace, "Third"),
                ];
        }
    };

    const clinicsList = useMemo(() => {
        if (props.game) {
            return orderBy(map(props.game.details, c => {
                const clinicDetails = find(props.game.data.Clinics, { ClinicId: c.ClinicId }) || {};

                // Calculate the "Nexgard Plus" doses based on clinic data
                const filterNexgardPlusProductBrands = (list=[]) => filter(list, { ProductBrandId: 331, Name: "Nexgard Plus" });
                const currentDoses = clinicDetails.CurrentPeriod?.TotalReportDoses || 0;
                const previousDoses = clinicDetails.PreviousPeriod?.TotalReportDoses || 0;
                const currentPeriodNgPlusProductBrands = filterNexgardPlusProductBrands(clinicDetails.CurrentPeriod?.ProductBrands);
                const previousPeriodNgPlusProductBrands = filterNexgardPlusProductBrands(clinicDetails.PreviousPeriod?.ProductBrands);
                return {
                    ...c,
                    clinicId: c.ClinicId,
                    currentDoses,
                    previousDoses,
                    currentNgPlusDoses: getSumOfFieldFromList(currentPeriodNgPlusProductBrands, "ReportDoses"),
                    name: props.isProvider ? clinicDetails.ClinicName : c.ClinicAlias,
                    previousNgPlusDoses: getSumOfFieldFromList(previousPeriodNgPlusProductBrands, "ReportDoses"),
                    dosesToGo: Math.ceil((previousDoses - currentDoses)) || "--",
                    toWin: round(clinicDetails.DosesNeeded?.ToFirstPlace) || "--",
                    toWinHover: c.Doses ? getToWinHover(c.Place, clinicDetails.DosesNeeded) : ["You have not sold any doses yet."],
                };
            }), "Place");
        }
        return [];   
    }, [props.game, props.isProvider]);

    useEffect(() => {
        if (props.game?.details && clinicsList.length) {
            setTeams(map(props.game.details, team => {
                const clinicDetails = find(props.game.data.Clinics, { ClinicId: team.ClinicId }) || {};
                return {
                    ...team,
                    name: props.isProvider ? clinicDetails.ClinicName : team.ClinicAlias,
                    PercentGrowth: team.PercentGrowth ? team.PercentGrowth : 0
                }
            }));
            setGameLoaded(true);
        }
    }, [props.game, clinicsList]);

    const gameAnimation = useMemo(() => {
        if (gameLoaded) {
            const gameKey = props.clinicId ? `game_animation_${props.game.greenlineGameId}_clinic_${props.clinicId}` :
                `game_animation_${props.game.greenlineGameId}_day_${props.game.daysInto}`;
            return (
                <PuppyLeafPartyLeaderboard
                    key={gameKey}
                    animate={props.animate}
                    currentClinicId={props.clinicId}
                    data={teams}
                    pathToSrcFiles="/plugins/puppy_leaf_party_leaderboard/"
                    printing={props.printing}
                    showAllPoints={props.snapshot}
                />
            );
        } else {
            return <Loader />;
        }
    }, [gameLoaded, props.animate]);

    const getConditionalRowProps = (row) => {
        if (row.clinicId === props.clinicId) {
            return {
                style: {
                    backgroundColor: 'rgb(26, 85, 173)',
                    color: '#ffffff',
                }
            };
        }
        return {};
    };

    const COLUMNS = [
        {
            name: "Rank",
            selector: "Place",
            key: "rank",
            sortable: false,
            searchable: false,
            colSettings: { width: "25px"},
        },{
            name: `Clinic ${props.isProvider ? "Name" : "Alias"}`,
            selector: "name",
            key: "name",
            sortable: false,
            searchable: false,
            format: row => (
                <span style={{display: "inline-block", width: "100%", textAlign: "left"}}>{row.name}</span>
            ),
        }, {
            name: "Current Doses",
            selector: "currentDoses",
            key: "currentDoses",
            sortable: false,
            searchable: false,
            colSettings: { width: "60px"},
            format: row => {
                if (props.snapshot || (row.clinicId === props.clinicId)) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            {addCommasToNumber(round(row.currentDoses))}
                        </div>
                    )
                } else {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            <span>--</span>
                        </div>
                    )
                }
            }
        }, {
            name: "Previous Doses",
            selector: "previousDoses",
            key: "previousDoses",
            sortable: false,
            searchable: false,
            colSettings: { width: "60px"},
            format: row => {
                if (props.snapshot || (row.clinicId === props.clinicId)) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            {addCommasToNumber(round(row.previousDoses))}
                        </div>
                    )
                } else {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            <span>--</span>
                        </div>
                    )
                }
            }
        }, {
            name: "Current Doses - NG PLUS",
            selector: "currentNgPlusDoses",
            key: "currentNgPlusDoses",
            sortable: false,
            searchable: false,
            colSettings: { width: "60px"},
            format: row => {
                if (props.snapshot || (row.clinicId === props.clinicId)) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            {addCommasToNumber(round(row.currentNgPlusDoses))}
                        </div>
                    )
                } else {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            <span>--</span>
                        </div>
                    )
                }
            }
        }, {
            name: "Previous Doses - NG PLUS",
            selector: "previousNgPlusDoses",
            key: "previousNgPlusDoses",
            sortable: false,
            searchable: false,
            colSettings: { width: "60px"},
            format: row => {
                if (props.snapshot || (row.clinicId === props.clinicId)) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            {addCommasToNumber(round(row.previousNgPlusDoses))}
                        </div>
                    )
                } else {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            <span>--</span>
                        </div>
                    )
                }
            }
        }, {
            name: (
                <Tooltip
                    position="left"
                    tip="This column shows how many doses your clinic needs to sell to meet your doses sold in the previous timeframe set for this game."
                >
                    <span className="cursor-help">To Go <i className="fa fa-info-circle"/></span>
                </Tooltip>
            ),
            selector: "DosesToGo",
            key: "dosesToGo",
            sortable: false,
            searchable: false,
            colSettings: { width: "60px"},
            format: row => {
                if (row.previousDoses > row.currentDoses && (props.snapshot || row.clinicId === props.clinicId)) {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            <span style={{fontSize: "0.9em"}}>{addCommasToNumber(row.dosesToGo)}</span>
                        </div>
                    );
                } else {
                    return (
                        <div style={{ textAlign: "center", whiteSpace: "nowrap"}}>
                            <span>--</span>
                        </div>
                    );
                }
            }
        }, {
            name: "Growth Percentage",
            selector: "PercentGrowth",
            key: "PercentGrowth",
            sortable: false,
            searchable: false,
            colSettings: {width: "60px"},
            format: row => {
                let fontColor = "#BB0000";

                if (row.PercentGrowth > 0) {
                    fontColor = "rgb(49, 125, 61)";
                }

                if (row.clinicId === props.clinicId) {
                    fontColor = "#FFFFFF";
                }

                // If there were no previous doses then this should never show a number
                if (row.previousDoses === 0) {
                    return (
                        <span style={{ color: '#000', display: "inline-block", width: "100%", textAlign: "center"}}>--</span>
                    );
                }

                //  If some have been sold then show the percentage compared to last year
                if (row.PercentGrowth > -100) {
                    return (
                        <span style={{ color: fontColor, display: "inline-block", width: "100%", textAlign: "center"}}>{round(row.PercentGrowth + 100)}%</span>
                    );
                } else {
                    // Otherwise display 0%
                    return (
                        <span style={{ color: fontColor, display: "inline-block", width: "100%", textAlign: "center"}}>0%</span>
                    );
                }
            }
        }, {
            name: (
                <Tooltip
                    position="left"
                    tip="This column shows how many doses your clinic needs to sell to be in the lead for this game!"
                >
                    <span className="cursor-help">To Win <i className="fa fa-info-circle" /></span>
                </Tooltip>
            ),
            selector: "toWin",
            key: "toWin",
            sortable: false,
            searchable: false,
            colSettings: { width: "60px" },
            format: row => {
                if (props.snapshot || row.clinicId === props.clinicId) {
                    return (
                        <Tooltip
                            position="left"
                            tip={(
                                <div>
                                    {map(row.toWinHover, h => (
                                        <div>{h}</div>
                                    ))}
                                </div>
                            )}
                        >
                            <div className="text-center cursor-help">
                                {row.Place === 1 ? <i className="far fa-trophy-star"/> : addCommasToNumber(row.toWin)}
                            </div>
                        </Tooltip>
                    );
                } else return <div className="text-center">--</div>;
            },
        },
    ];

    return (
        <div className={styles.root}>
            <div className={styles.bannerAd}>
                <Image
                    src={LARGE_BANNER_AD}
                    alt="HeartGard® Plus+ and NexGard® Plus+ advertisement"
                />
            </div>
            <div className={classnames(styles.animationWrapper, {
                [styles.snapshot]: props.snapshot,
            })}>
                <div className={styles.animation}>
                    {gameAnimation}
                </div>
            </div>
            <LeafPartyLeaderboardLegalWording />
            <div className={styles.rankingsTable}>
                <DataTable
                    columns={COLUMNS}
                    data={clinicsList}
                    pagination={false}
                    green
                    useColgroup
                    conditionalRowProps={getConditionalRowProps}
                />
            </div>
        </div>
    );
}

PuppyLeafParty.defaultProps = {
    animate: false,
    clinicId: null,
    isProvider: false,
    pointValues: undefined,
    printing: false,
    snapshot: false,
}

PuppyLeafParty.propTypes = {
    game: PropTypes.object.isRequired,
    clinicId: PropTypes.number,
    animate: PropTypes.bool,
    isProvider: PropTypes.bool,
    pointValues: PropTypes.object,
    printing: PropTypes.bool,
    snapshot: PropTypes.bool,
}
