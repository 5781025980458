export const ACCOUNT = "Account";
export const CLINIC_REGISTRATION = "ClinicRegistration";
export const NOTIFICATIONS = "Notifications";
export const PAYMENT_SETTINGS = "PaymentSettings";
export const PLAN_MANAGEMENT = "PlanManagement";
export const USER_MANAGEMENT = "UserManagement";
export const NOTIFICATION_MANAGEMENT = "NotificationManagement";
export const SUPPORT = "Support";

export const PRE_AUDIT_REVIEW = "PreAuditReview";
export const WELLNESS_REPROCESS = "WellnessReprocess";
export const VOUCHER_MANAGEMENT = "VoucherManagement";
export const ROLE_MANAGEMENT = "RoleManagement";

export const MANUFACTURER_ACCOUNT_HOLD = "ManufacturerAccountHold";

export const CLINIC_INFO = "ClinicInfo";
export const CLINIC_RECORD = "ClinicRecord";
export const CLINIC_MANAGEMENT = "ClinicManagement";
export const CLINIC_BI_GAMES_DASHBOARD = "ClinicBIGamesDashboard";
export const CLINIC_COUPON_AUDIT = "ClinicCouponAudit";
export const CLINIC_COUPON_LIBRARY = "ClinicCouponLibrary";
export const CLINIC_PROGRAM_SETUP = "ClinicProgramsSetup";
export const CLINIC_ENROLLMENT = "ClinicEnrollment";
export const CLINIC_REDEMPTION_SUMMARY = "ClinicRedemptionSummary";
export const CLINIC_VETCHECK = "ClinicVetCheck";

export const CLINIC_WELLNESS_AUDIT = "ClinicWellnessAudit";
export const CLINIC_WELLNESS_DVMS = "ClinicWellnessDvms";
export const CLINIC_WELLNESS_PET_PLANS = "ClinicWellnessPetPlans";
export const CLINIC_WELLNESS_PLANS = "ClinicWellnessPlans";
export const CLINIC_PRICE_SCHEDULES = "ClinicWellnessPriceSchedules";
export const CLINICS_DRILLDOWN = "ClinicsDrilldown";
export const CLINIC_WELLNESS_PAYOUT_REPORT = 'ClinicWellnessPayoutReport';
export const CLINIC_WELLNESS_TAXES = 'ClinicWellnessTaxes';

export const CORPORATE_GROUP_CLINIC = "CorporateGroupClinic";
export const COUPON_REPROCESSING = "CouponReprocessing";
export const ELIGIBILITY_TRACKER_CLIENT = "EligibilityTrackerClient";

export const INSTANT_REBATE_INVOICE_FAILURES = "InstantRebateInvoiceFailures";
export const PROGRAM_ENROLLMENT_CLINIC = "ProgramEnrollmentClinic";
export const STRAY_COUPON_REPROCESSING = "StrayCouponReprocessing";
export const ADMIN_COUPON_PROCESSING = "AdminCouponProcessing";

export const ADMIN_PASSWORD_MANAGEMENT = "AdminPasswordManagement";

//Admin view of provider pages
export const GAMES_ADMIN = "GamesAdmin";
export const PROVIDER_ADMIN = "ProviderAdmin";

//Mapping Permissions
export const WELLNESS_MAPPING = "WellnessMapping";
export const VETCHECK_MAPPING = "VetCheckMapping";
export const PRODUCT_MAPPING = "ProductMapping";
export const VACCINE_MAPPING = "VaccineMapping";
export const EXAM_SERVICE_MAPPING = "ExamServiceMapping";

//For Providers
export const PROVIDER_WELLNESS_PLANS = "ProviderWellnessPlans";
export const PROVIDER_INFO = "ProviderInfo";
export const PROVIDER_BI_GAMES = "ProviderBIGames";
export const PROVIDER_BI_GAMES_DEMO = "ProviderBIGamesDemo";
export const PROVIDER_BI_GAMES_DRILLDOWN = "ProviderBIGamesDrilldown";
export const PROVIDER_CLINICS_OVERVIEW = "ProviderClinicsOverview";
export const PROVIDER_BI_GAMES_CLINIC_INVITES = "ProviderBIGamesClinicInvites";
export const PROVIDER_PRODUCT_TAGGING = "ProductTagging";
export const PROVIDER_PAYOUT_REPORT = "ProviderPayoutReport";
export const PROVIDER_REPORTS = "ProviderReporting";
