import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import intersection from "lodash/intersection";
import find from "lodash/find";
import map from "lodash/map";
import * as GameApi from "api/GameApi";
import MultiSelectDropdown from "components/common/Dropdown/MultiSelectDropdown";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { GROWTH, GROWTH_NGP } from "constants/GameTypes";
import * as UserPermissions from "constants/UserPermissions";

function GameTypeProductMultiSelect(props) {
    const [gameTypeProducts, setGameTypeProducts] = useState([]);
    const brandOptions = map(gameTypeProducts, product => {
        return {
            name: product.brandName,
            value: product.productBrandId
        };
    }) || [];
    const options = brandOptions || [];

    const loadProducts = async (gameType=null, period=null, categoryId=null, userId=null) => {
        try {
            const res = await GameApi.getProductsForGameTypeAndPeriod(gameType, period, categoryId, userId);
            setGameTypeProducts(res.body);
        } catch (e) {
            handleErrorResponse("loading game type products", e);
        }
    };

    useEffect(() => {
        if (props.gameType && props.categoryId && (props.canViewProviderBIGames || props.canViewProviderBIGamesDemo)) {
            loadProducts(props.gameType, props.comparisonPeriod, props.categoryId, props.userId);
        }
    }, [props.gameType, props.comparisonPeriod, props.categoryId, props.userId]);

    if (!(props.canViewProviderBIGames || props.canViewProviderBIGamesDemo)) {
        return null;
    }

    const handleChange = ({ name, value }) => {
        //    FOR PRODUCT UPDATE
        const newProducts = value.length ? map(value, v => {
            const product = find(gameTypeProducts, { productBrandId: v});
            return {
                productBrandId: v,
                brandName: product.brandName,
            };
        }) : [];
        props.onChange({ name, value: newProducts });
    }

    return (
        <>
            {(
                (props.gameType === GROWTH || props.gameType === GROWTH_NGP) &&
                (
                    !!props.value?.length &&
                    props.hasRequiredData &&
                    !intersection(props.clinicProducts, props.value)?.length
                )
            ) && (
                <div style={{
                    border: "1px solid orange",
                    borderRadius: "4px",
                    padding: "1em",
                }}>
                    <i className="fa fa-warning text-warn" /> Clinic did not sell the selected product{(props.value.length > 1) && "s"} during the timeframe.
                </div>
            )}
            <MultiSelectDropdown
                label={props.label}
                name={props.name}
                value={props.value}
                placeholder={props.placeholder}
                options={options}
                onChange={handleChange}
                disabled={props.disabled || (gameTypeProducts?.length && !options?.length)}
            />
        </>
    );
}

GameTypeProductMultiSelect.propTypes = {
    clinicProducts: null,
    disabled: false,
    hasRequiredData: false,
    label: "Product(s) Used for Game",
    placeholder: "Select Products",
    userId: null,
    value: undefined,
};

GameTypeProductMultiSelect.propTypes = {
    categoryId: PropTypes.number.isRequired,
    clinicProducts: PropTypes.arrayOf(PropTypes.number),
    comparisonPeriod: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    gameType: PropTypes.string.isRequired,
    hasRequiredData: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    userId: PropTypes.number,
    value: PropTypes.array,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewProviderBIGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewProviderBIGamesDemo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_DEMO, userProfile);
        return {
            canViewProviderBIGames,
            canViewProviderBIGamesDemo,
        }
    },
)(GameTypeProductMultiSelect);
