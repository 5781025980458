import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import * as styles from "./GoGreenForm.scss";
import map from "lodash/map";
import snakeCase from "lodash/snakeCase";
import Button from "components/common/Button";
import DateInput from "components/common/DateInput";
import Dropdown from "components/common/Dropdown";
import GoGreenRequirementRow from "components/admin/widgets/GoGreenRequirementRow";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import ToggleSwitch from "components/common/ToggleSwitch";
import {getLinkLocation} from "utils/ClinicAdminLinks";
import getLocalTimezone from "utils/getLocalTimezone";
import logger from "utils/logger";
import { BOEHRINGER_INGELHEIM } from "constants/ProviderIds";

function GoGreenForm(props) {
    const [goGreenDate, setGoGreenDate] = useState(null);
    const [clooEnrollmentTag, setClooEnrollmentTag] = useState(null);

    useEffect(() => {
        if (props.goGreenRequirements) {
            console.log(props.goGreenRequirements, "props.goGreenRequirements");
            setGoGreenDate(props.goGreenRequirements.goGreenStartDate);

            if (props.goGreenRequirements.currentOptInDropDownState?.CurrentListState) {
                setClooEnrollmentTag(props.goGreenRequirements.currentOptInDropDownState.CurrentListState);
            }
        }
    }, [props.goGreenRequirements]);
    console.log(goGreenDate, "goGreenDate");

    if (!props.goGreenRequirements){
        return <SpinnerTakeover show/>;
    }

    if (!props.provider) {
        logger.log("No Provider Found");
        return null;
    }

    const allChecks = map(props.goGreenRequirements.requirements, req => req.isValid);
    const canGoGreen = !allChecks.includes(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        props.onGoGreen(goGreenDate, clooEnrollmentTag);
    };

    const renderRequirement = (clinicId, providerId, requirement) => {
        if (!requirement) {
            return null;
        }

        const requirementId = snakeCase(requirement.name); // Snake case to avoid bugs comparing capitalization variations

        switch (requirementId) {
            case "has_synced":
                return (
                    <GoGreenRequirementRow
                        key={requirementId}
                        requirement={requirement}
                        clinicId={clinicId}
                        readOnly
                    />
                );

            case "location_id":
                return (
                    <GoGreenRequirementRow
                        key={requirementId}
                        requirement={requirement}
                        clinicId={clinicId}
                        readOnly
                        linkUrl={props.canViewClinicManagement ? getLinkLocation("providerSetup", clinicId, `?providerId=${providerId}`) : null}
                    />
                );

            case "gateway_clinic":
                return (
                    <GoGreenRequirementRow
                        key={requirementId}
                        requirement={requirement}
                        clinicId={clinicId}
                        readOnly
                    />
                );

            case "wellness_dv_ms": // Lodash must have assumed "DVMs" is camelCase and split it.
            case "wellness_dvms":
                return (
                    <GoGreenRequirementRow
                        key={requirementId}
                        requirement={requirement}
                        clinicId={clinicId}
                        readOnly
                        linkUrl={props.canViewWellnessDvms ? getLinkLocation("wellnessDVM", clinicId) : null}
                    />
                );

            case "wellness_mappings":
                return (
                    <GoGreenRequirementRow
                        key={requirementId}
                        requirement={requirement}
                        clinicId={clinicId}
                        readOnly
                        linkUrl={props.canViewWellnessMapping ? getLinkLocation("wellnessMapping", clinicId) : null}
                    />
                );
            case "vaccine_mapping_count":
                return (
                    <GoGreenRequirementRow
                        key={requirementId}
                        requirement={requirement}
                        clinicId={clinicId}
                        readOnly
                        linkUrl={props.canViewVaccineMapping ? getLinkLocation("vaccineMapping", clinicId) : null}
                    />
                );

            case "active_wellness_plans":
                return (
                    <GoGreenRequirementRow
                        key={requirementId}
                        requirement={requirement}
                        clinicId={clinicId}
                        readOnly
                        linkUrl={props.canViewClinicWellnessPlans ? getLinkLocation("wellnessPlanSetup", clinicId) : null}
                    />
                );

            case "rx_mapping_count":
                return (
                    <GoGreenRequirementRow
                        key={requirementId}
                        requirement={requirement}
                        clinicId={clinicId}
                        readOnly
                        linkUrl={props.canViewProductMapping ? getLinkLocation("pharmacyMapping", clinicId) : null}
                    />
                );

            case "pims_dvm_count":
                return (
                    <GoGreenRequirementRow
                        key={requirementId}
                        requirement={requirement}
                        clinicId={clinicId}
                        readOnly
                        linkUrl={props.canViewClinicManagement ? getLinkLocation("pimsUsers", clinicId) : null}
                    />
                );
            case "vetcheck_handout_mappings":
                return (
                    <GoGreenRequirementRow
                        key={requirementId}
                        requirement={requirement}
                        clinicId={clinicId}
                        readOnly
                        linkUrl={props.canViewVetCheckMapping ? getLinkLocation("vectCheckMapping", clinicId) : null}
                    />
                );
            case "paid_subscription":
                return (
                    <GoGreenRequirementRow
                        key={requirementId}
                        requirement={requirement}
                        clinicId={clinicId}
                        readOnly
                        // linkUrl={}
                    />
                );
        }

        logger.error(`No JSX output implemented for provider requirement '${requirement.name}'`);
        return null;
    };

    return (
        <form className={styles.root} onSubmit={handleSubmit}>
            <table className="table">
                <tbody>

                    {map(props.goGreenRequirements.requirements, req => renderRequirement(props.clinicId, props.providerId, req))}

                    <tr title="Leave blank to use today's date">
                        <td><i className="fa-2x fa fa-calendar-alt text-success" /></td>
                        <td>Go Green Date:</td>
                        <td>
                            <DateInput
                                disabled={!props.canEditClinicProgramSetup}
                                value={goGreenDate}
                                onChange={({ value }) => setGoGreenDate(value)}
                            />
                        </td>
                    </tr>
                    {(props.providerId === BOEHRINGER_INGELHEIM) && (
                        <tr>
                            <td>
                                {!!props.goGreenRequirements?.currentOptInDropDownState?.CurrentListState ? <i className="fa-2x fa fa-check text-success" /> : <i className="fa-2x fa fa-exclamation-triangle text-danger" />}
                            </td>
                            <td>BI Couponing Type:</td>
                            <td>
                                <Dropdown
                                    name="CurrentListState"
                                    value={clooEnrollmentTag}
                                    options={props.goGreenRequirements.clooOptInDropDownItems}
                                    onChange={({value}) => setClooEnrollmentTag(value)}
                                    placeholder="Select Instant Rebate Type"
                                />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            <div className="margin-top-x-sm">* If the Go Green button is disabled, please make the appropriate changes indicated above.</div>
            {!!props.provider.activationDate && (
                <div className="margin-top-md flex align-center spaced-content margin-bottom-md">
                    <div className="flex-1">Clinic was Go Green Activated on: {getLocalTimezone(props.provider.activationDate, "MM/DD/YYYY LTS")}.</div>
                    <Button
                        className="flex-none"
                        small
                        onClick={props.canEditClinicProgramSetup && props.onUndoGoGreen}
                        type="primary"
                    >
                        Deactivate Go Green
                    </Button>
                </div>
            )}

            <div className="margin-top-sm flex align-center spaced-content">
                <div className="flex-1">
                    {!!props.provider.deactivationDate && `Clinic was previously deactivated on: ${getLocalTimezone(props.provider.deactivationDate, "MM/DD/YYYY LTS")}.`}
                </div>
                <Button
                    className="flex-none"
                    onClick={props.onCancel}
                    type="gray"
                >
                    Cancel
                </Button>
                <Button
                    className="flex-none"
                    buttonType="submit"
                    disabled={!(props.canEditClinicProgramSetup && canGoGreen)}
                >
                    {!!props.provider.activationDate ? "Apply Changes" : "Go Green"}
                </Button>
            </div>
        </form>
    );
}

GoGreenForm.propTypes = {
    clinicId: PropTypes.number.isRequired,
    providerId: PropTypes.number.isRequired,
    onGoGreen: PropTypes.func.isRequired,
    onUndoGoGreen: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,

    //Permissions
    canEditClinicProgramSetup: PropTypes.bool,
    canViewClinicManagement: PropTypes.bool,
    canViewVetCheckMapping: PropTypes.bool,
    canViewProductMapping: PropTypes.bool,
    canViewClinicWellnessPlans: PropTypes.bool,
    canViewVaccineMapping: PropTypes.bool,
    canViewWellnessMapping: PropTypes.bool,
    canViewWellnessDvms: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const clinic = state.entities.clinics[ownProps.clinicId];
        return {
            provider: clinic?.activations?.[ownProps.providerId],
            goGreenRequirements: state.entities.clinicsGoGreenRequirements?.[ownProps.clinicId]?.[ownProps.providerId] || null,
        }
    }
)(GoGreenForm);