import {combineReducers} from "redux";
import moment from "moment";
import produce from "immer";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import flatten from "lodash/flatten";
import forEach from "lodash/forEach";
import get from "lodash/get";
import groupBy from "lodash/groupBy";
import includes from "lodash/includes";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import round from "lodash/round";
import split from "lodash/split";
import uniq from "lodash/uniq";
import LocalData from "utils/LocalData";
import logger from "utils/logger";
import * as ActionTypes from "constants/ActionTypes";
import * as InviteTypes from "constants/InviteTypes";
import { CANCEL, CANCELED, DECLINE, DECLINED, ENROLL, ENROLLED } from "constants/ProgramStatuses";
import { GREENLINE_WELLNESS, PREMIER_PET_CARE_PLAN } from "constants/ProviderIds";

function userManagement(state = {}, action) {
    switch (action.type) {
        case ActionTypes.USERS_LOADED:
            return map(action.users, user => {
                // There has to be an ID for the user table rows to show the disabled rows correctly.
                return {
                    ...user,
                    id: user.userId
                }
            });
        default:
            return state;
    }
}

function users(state = {}, action) {
    switch (action.type) {
        case ActionTypes.USER_LOADING:
            return {
                ...state,
                loading: true,
            }
        case ActionTypes.USER_LOADED:
            return {
                ...state,
                [action.data.id]: action.data,
                loading: false,
            }
        case ActionTypes.USERS_LOADED:
            return {
                ...keyBy(action.users, "id"),
                query: state.query,
                loading: false,
            }
        case ActionTypes.USER_CREATED:
        case ActionTypes.ADMIN_CREATED:
            return {
                ...state,
                [action.user.id]: {
                    ...action.user
                },
                loading: false,
            };
        case ActionTypes.USER_UPDATING:
            return {
                ...state,
                isUserUpdating: true,
            };
        case ActionTypes.USER_UPDATED:
            return {
                ...state,
                [action.user.id]: {
                    ...state[action.user.id],
                    ...action.user,
                },
                isUserUpdating: false,
            };
        case ActionTypes.USER_SEARCH_FILTERED:
            return {
                ...state,
                query: action.query
            };
        case ActionTypes.USER_SEARCH_CLEARED:
        case ActionTypes.CLEAR_ALL_SEARCHES:
            return {
                ...state,
                query: ""
            };
        case ActionTypes.USER_ROLES_LOADED:
            return {
                ...state,
                roleIds: action.roleIds,
            };
        default:
            return state;
    }
}

function registration(state={}, action) {
    switch (action.type) {
        case ActionTypes.VOUCHER_CODE_SUBMITTED:
            return {
                ...state,
                verifying: true,
                attempts: (state.attempts + 1) || 1,
            }
        case ActionTypes.VOUCHER_CODE_REJECTED:
            return {
                ...state,
                verifying: false,
                voucherError: true,
                locked: (state.attempts >= 5),
            }
        case ActionTypes.VOUCHER_CODE_VERIFIED:
            return {
                ...state,
                verifying: false,
                voucherError: false,
                data: action.response,
                locked: false,
            };
        case ActionTypes.VOUCHER_CODE_CLEARED:
            return {};
        case ActionTypes.CLINIC_CREATED:
        case ActionTypes.CLINIC_UPDATED:
            return {
                ...state,
                clinic: action.clinic
            };
        case ActionTypes.ADMIN_CREATED:
            return {
                ...state,
                user: action.user
            };
        case ActionTypes.PROGRAM_ADDED_TO_CLINIC:
            // For now until we know what registration does
            return state;
        default:
            return state;
    }
}

function roles(state = {}, action) {
    switch (action.type) {
        case ActionTypes.ROLES_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.ROLES_LOADED:
            return {
                ...action.data,
                providers: keyBy(action.data.providers, "id"),
                roles: keyBy(action.data.roles, "displayOrder"),
                loading: false
            }
        case ActionTypes.ROLE_CREATED:
            return {
                ...state,
                roles: {
                    ...state.roles,
                    [action.data.displayOrder]: action.data
                },
                loading: false
            }
        default:
            return state;
    }
}

function clinicDetails(state={}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_DETAILS_LOADED:
            return {
                ...state,
                [action.clinic.clinicId]: action.clinic,
            };
        case ActionTypes.CLINIC_DETAILS_UPDATED:
            return {
                ...state,
                [action.clinicDetails.clinicId]: action.clinicDetails,
            };
        case ActionTypes.CLINIC_TAG_ADDED:
            const tagsList = state[action.clinicId].tags || [];
            return  {
                ...state,
                [action.clinicId]: {
                    ...state[action.clinicId],
                    tags: [
                        ...tagsList,
                        action.results.id
                    ]
                },
            }
        case ActionTypes.INSTALL_CODE_GENERATED:
            return {
                ...state,
                [action.clinicId]: {
                    ...state[action.clinicId],
                    installationAccess: action.installCode,
                },
            }
        default:
            return state
    }
}

// TODO: remove unused fields from this and put them where they belong
function clinics(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINICS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case ActionTypes.CLINICS_LOADED:
            return keyBy(map(action.clinics, (clinic, index) => {
                return {
                    ...clinic,
                    order: index,
                    activations: {
                        ...keyBy(clinic.activations, "providerId")
                    }
                }
            }), "clinicId");

        case ActionTypes.CLINIC_CREATED:
        case ActionTypes.CLINIC_UPDATED:
                return {
                    ...state,
                    ...action.clinic
                };

        case ActionTypes.CLINIC_DELETED:
            return produce(state, (newState) => {
                delete newState[action.clinicId];
                return newState;
            });

        case ActionTypes.CLINIC_GO_GREEN_UPDATED:
            return produce(state, newState => {
                newState[action.data.clinicId].activations[action.data.providerId].activationDate = action.data.goGreenStartDate
                newState[action.data.clinicId].activations[action.data.providerId].deactivationDate = action.data.goBrownDate
            });
        case ActionTypes.CLINIC_REDEMPTION_SUMMARY_LOADED:
            return {
                ...state,
                [action.data.clinicId]: {
                    ...state[action.data.clinicId],
                    offers: keyBy(action.data.offers, "offerId"),
                }
            }
        case ActionTypes.INVOICE_LINE_ITEMS_LOADING:
            return {
                ...state,
                areLineItemsLoading: true,
            };
        case ActionTypes.INVOICE_LINE_ITEMS_LOADED:
            return produce(state, newState => {
                newState[action.clinicId].invoiceLineItems = action.data;
                newState.areLineItemsLoading = false;
            });
        // TODO: DELETE (this is for faking a subscription being added)
        case ActionTypes.CLINIC_SUBSCRIPTION_CREATED:
            return produce(state, newState => {
                newState[action.clinicId].programs.PriceWatch = {
                    enabled: true,
                    providers: [action.providerId]
                }
            });
        default:
            return state;
    }
}

function searchClinics(state=[], action) {
    switch (action.type) {
        case ActionTypes.CLINIC_SEARCH_RESULTS_LOADED:
            return action.results.data;
        case ActionTypes.CLINIC_SEARCH_CLEARED:
        case ActionTypes.CLEAR_ALL_SEARCHES:
            return [];
        default:
            return state;
    }
}

function allClinics(state = {}, action) {
    switch (action.type) {
        case ActionTypes.ALL_CLINICS_LOADED:
            return keyBy(map(action.clinics, clinic => {
                return {
                    ...clinic,
                    activations: {
                        ...keyBy(clinic.activations, "providerId")
                    }
                }
            }), "clinicId");
        default:
            return state;
    }
}

function reps(state = [], action) {
    switch (action.type) {
        case ActionTypes.CLINIC_REPS_LOADED:
            return {
                ...state,
                [action.clinicId]: action.results,
            };
        default:
            return state;
    }
}

function hospitals(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_HOSPITALS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.CLINIC_HOSPITALS_LOADED:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.clinicId]: {
                        ...keyBy(action.results, "hospitalId"),
                    },
                },
                loading: false,
            };
        case ActionTypes.CLINIC_HOSPITAL_ASSIGNED:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.hospital.gatewayClinicId]: {
                        ...state.data[action.hospital.gatewayClinicId],
                        [action.hospital.hospitalId]: action.hospital,
                    }
                },
                loading: false,
            }
        default:
            return state;
    }
}

function couponApprovalSummary(state = null, action) {
    switch (action.type) {
        case ActionTypes.COUPONS_PROCESSED:
            // Default clear approval summary
            let summary = {
                clinicId: action.clinicId
            }
            if (action?.clinicId === state?.clinicId) {
                // If the clinic hasn't changed then add to current approval summary
                summary = {
                    ...state,
                    clinicId: action.clinicId
                }
            }

            forEach(action.approvalSummary, (value, key) => {
                let newValue = 0;
                if (summary[key]) {
                    newValue = (summary[key] + value);
                    if (key === "hoursSaved" || key === "clientSavings" || key === "reimbursementValue") {
                        // round hours saved to a two-digit number
                        summary[key] = round(newValue, 2);
                    } else {
                        summary[key] = round(newValue);
                    }
                } else {
                    summary[key] = value;
                }
            });

            return summary;
        case ActionTypes.CLEAR_COUPON_APPROVAL_SUMMARY:
            return null;
        default:
            return state;
    }
}

function unprocessedCoupons(state = null, action) {
    switch (action.type) {
        case ActionTypes.UNPROCESSED_COUPONS_LOADED:
            return keyBy(action.coupons, "id");
        case ActionTypes.COUPONS_PROCESSED:
            return produce(state, (newState) => {
                forEach(action.couponList, id => {
                    delete newState[id]
                });
            });
        case ActionTypes.UNPROCESSED_COUPON_DETAILS_LOADED:
            return {
                ...state,
                [action.coupon.id]: {
                    ...state[action.coupon.id],
                    ...action.coupon,
                }
            }
        case ActionTypes.COUPONS_DECLINED:
            const withDeclined = {
                ...state
            };
            forEach(action.couponList, id => {
                delete withDeclined[id]
            })
            return withDeclined;
        case ActionTypes.UNPROCESSED_COUPON_UPDATED:
            return {
                ...state,
                [action.coupon.id]: {
                    ...state[action.coupon.id],
                    ...action.coupon,
                    requiresValidation: false,
                }
            };
        default:
            return state;
    }
}

function couponOfferHistory(state={}, action) {
    switch(action.type) {
        case ActionTypes.COUPON_OFFER_HISTORY_LOADED:
            return produce(state, newState => {
                newState[action.clinicId] = state[action.clinicId] ? state[action.clinicId] : {};
                newState[action.clinicId][action.providerId] = action.history;
            });
        default:
            return state;
    }
}

function coupons(state = {}, action) {
    switch (action.type) {
        case ActionTypes.COUPONS_LOADING:
            return {
                ...state,
                loading: true,
            }
        case ActionTypes.COUPON_CHANGE_HISTORY_LOADING:
            return {
                ...state,
                isHistoryLoading: true,
            };
        case ActionTypes.COUPONS_LOADED:
        case ActionTypes.REPROCESSING_JOBS_LOADED:
        case ActionTypes.COUPON_AUDIT_LOADED:
            return {
                all: keyBy(action.coupons, "id"),
                loading: false,
            };
        case ActionTypes.COUPON_DETAILS_LOADED:
            return {
                ...state,
                all: {
                    ...state.all,
                    [action.coupon.id]: action.coupon
                }
            }
        case ActionTypes.COUPON_CHANGE_HISTORY_LOADED:
            return {
                ...state,
                history: action.history,
                isHistoryLoading: false,
            };
        case ActionTypes.COUPON_SEARCH_FILTERED:
            return {
                ...state,
                query: action.query
            };
        case ActionTypes.COUPON_SEARCH_CLEARED:
        case ActionTypes.CLEAR_ALL_SEARCHES:
            return {
                ...state,
                query: null
            };
        case ActionTypes.COUPON_UPDATED:
            return {
                ...state,
                all: {
                    ...state.all,
                    [action.coupon.id]: action.coupon,
                }
            };
        case ActionTypes.REQUEST_ERROR:
            return {
                ...state,
                loading: false,
            }
        default:
            return state;
    }
}

function strayCouponStatuses(state=[], action) {
    switch(action.type) {
        case ActionTypes.STRAY_COUPON_STATUSES_LOADED:
            return action.data;
        default:
            return state;
    }
}

function strayCoupons(state = {}, action) {
    switch (action.type) {
        case ActionTypes.STRAY_COUPONS_CLEARED:
            return {
                resolved: {},
                ineligible: {},
                unresolved: {},
                expired: {},
            };
        case ActionTypes.STRAY_COUPONS_LOADED:
            return {
                resolved: keyBy((action.data?.resolved||[]), "invoiceId"),
                ineligible: keyBy(action.data?.ineligible||[], "invoiceId"),
                unresolved: keyBy((action.data?.unresolved||[]), "invoiceId"),
                expired: keyBy((action.data?.expired||[]), "invoiceId"),
            };
        case ActionTypes.INELIGIBLE_MARKED_ELIGIBLE:
            // TODO: This is done as a single invoice, though it's a batch call, take the first item
            return produce(state, (draftState) => {
                delete draftState.ineligible[action.invoiceIds[0]];
                return draftState;
            });
        case ActionTypes.STRAY_COUPONS_RESOLVED:
            return produce(state, (draftState) => {
                const isIneligible = !!draftState.ineligible[action.invoiceId];
                const item = isIneligible ? { ...draftState.ineligible[action.invoiceId] } :
                    { ...draftState.unresolved[action.invoiceId] };

                draftState.resolved = {
                    ...draftState.resolved,
                    [action.invoiceId]: item,
                }

                if (isIneligible) {
                    delete draftState.ineligible[action.invoiceId];
                } else {
                    delete draftState.unresolved[action.invoiceId];
                }

                return draftState;
            });
        case ActionTypes.STRAY_COUPONS_BATCH_RESOLVED:
            return produce(state, (draftState) => {
                forEach(action.invoices, invoice => {
                    if (draftState.ineligible[invoice.invoiceId]) {
                        draftState.resolved = {
                            ...draftState.resolved,
                            [invoice.invoiceId]: {
                                ...draftState.ineligible[invoice.invoiceId],
                                invoiceId: invoice.invoiceId,
                                resolvedUserInitials: invoice.userInitials,
                                resolvedDate: new Date(),
                                status: action.status || null,
                            },
                        }
                        delete draftState.ineligible[invoice.invoiceId];
                    } else if (draftState.expired[invoice.invoiceId]) {
                        draftState.resolved = {
                            ...draftState.resolved,
                            [invoice.invoiceId]: {
                                ...draftState.expired[invoice.invoiceId],
                                resolvedUserInitials: invoice.userInitials,
                                resolvedDate: new Date(),
                                status: action.status || null,
                            },
                        }
                        delete draftState.expired[invoice.invoiceId];
                    } else {
                        draftState.resolved = {
                            ...draftState.resolved,
                            [invoice.invoiceId]: {
                                ...draftState.unresolved[invoice.invoiceId],
                                resolvedUserInitials: invoice.userInitials,
                                resolvedDate: new Date(),
                                status: action.status || null,
                            },
                        }
                        delete draftState.unresolved[invoice.invoiceId];
                    }
                });
                return draftState;
            });
        case ActionTypes.STRAY_COUPON_MARK_PEND:
            return produce(state, (draftState) => {
                forEach(action.invoices, invoice => {
                    if (draftState.resolved[invoice.invoiceId]?.isIneligible) {
                        draftState.ineligible = {
                            ...draftState.ineligible,
                            [invoice.invoiceId]: {...draftState.resolved[invoice.invoiceId]},
                        }
                        delete draftState.resolved[invoice.invoiceId];
                    } else {
                        draftState.unresolved = {
                            ...draftState.unresolved,
                            [invoice.invoiceId]: {
                                ...draftState.resolved[invoice.invoiceId],
                                resolvedUserInitials: null,
                                resolvedDate: null,
                                status: action.status || null,
                            },
                        }
                        delete draftState.resolved[invoice.invoiceId];
                    }

                });
                return draftState;
            });
        case ActionTypes.STRAY_COUPONS_MARK_HIDDEN:
            return produce(state, (draftState) => {
                if(draftState.resolved[action.invoiceId]){
                    draftState.resolved[action.invoiceId].isHiddenFromClinic = action.isHidden;
                } else if(draftState.unresolved[action.invoiceId]) {
                    draftState.unresolved[action.invoiceId].isHiddenFromClinic = action.isHidden;
                } else if(draftState.ineligible[action.invoiceId]) {
                    draftState.ineligible[action.invoiceId].isHiddenFromClinic = action.isHidden;
                } else if(draftState.expired[action.invoiceId]) {
                    draftState.expired[action.invoiceId].isHiddenFromClinic = action.isHidden;
                }
                return draftState
            });
        case ActionTypes.STRAY_COUPONS_BULK_MARK_HIDDEN:
        case ActionTypes.STRAY_COUPONS_MARK_UNHIDDEN:
            const toHide = action.type === ActionTypes.STRAY_COUPONS_BULK_MARK_HIDDEN;
            return produce(state, (draftState) => {
                forEach(action.invoices, invoice => {
                    if (draftState.resolved[invoice.invoiceId]){
                        draftState.resolved[invoice.invoiceId].isHiddenFromClinic = toHide
                    } else if (draftState.unresolved[invoice.invoiceId]){
                        draftState.unresolved[invoice.invoiceId].isHiddenFromClinic = toHide
                    } else if (draftState.ineligible[invoice.invoiceId]) {
                        draftState.ineligible[invoice.invoiceId].isHiddenFromClinic = toHide
                    } else if (draftState.expired[invoice.invoiceId]) {
                        draftState.expired[invoice.invoiceId].isHiddenFromClinic = toHide
                    }
                });

                return draftState;
            });
        case ActionTypes.REPROCESSING_JOB_CREATED:
            return produce(state, (draftState) => {
                forEach(action.invoices, invoice => {
                    if (draftState.resolved?.[invoice.invoiceId]){
                        delete draftState.resolved[invoice.invoiceId];
                    } else if (draftState.unresolved?.[invoice.invoiceId]){
                        delete draftState.unresolved[invoice.invoiceId];
                    } else if (draftState.ineligible?.[invoice.invoiceId]) {
                        delete draftState.ineligible[invoice.invoiceId];
                    } else if (draftState.expired?.[invoice.invoiceId]) {
                        delete draftState.expired[invoice.invoiceId];
                    }
                });
                return draftState;
            });
        default:
            return state;
    }
}

function programOffers(state={}, action) {
    switch (action.type) {
        case ActionTypes.OFFERS_LOADED:
            return {
                ...state,
                [action.programId]: {
                    offers: keyBy(action.offers, "clogId"),
                },
            };
        case ActionTypes.CLEAR_OFFERS:
            return {};
        case ActionTypes.OFFER_OPT_IN:
            return produce(state, (draftState) => {
                const program = draftState[action.programId];
                forEach(program.offers, (offerGroup) => {
                    forEach(offerGroup.offers, (offer) => {
                        if (offer.offerId === action.offerId) {
                            offer.activationDate = moment().format();
                        }
                    });
                });
                draftState[action.programId] = program;
                return draftState;
            });
        case ActionTypes.OFFER_OPT_OUT:
            return produce(state, (draftState) => {
                const program = draftState[action.programId];
                forEach(program.offers, (offerGroup) => {
                    forEach(offerGroup.offers, (offer) => {
                        if (offer.offerId === action.offerId) {
                            offer.activationDate = null;
                        }
                    });
                });
                draftState[action.programId] = program;
                return draftState;
            });
        default:
            return state;
    }
}

function programs(state= {}, action) {
    switch (action.type) {
        case ActionTypes.PROGRAMS_LOADED:
            return keyBy(action.programs, "id");
        default:
            return state;
    }
}

function wellness(state = {}, action) {
    switch (action.type) {
        case ActionTypes.WELLNESS_PLANS_LOADED:
            return {
                ...state,
                plans: keyBy(action.plans, "id"),
            };
        case ActionTypes.WELLNESS_SEARCH_LOADING:
            return {
                ...state,
                loading: true,
            }
        case ActionTypes.WELLNESS_SEARCH_CLEARED:
            return {
                ...state,
                searchResults: null,
            };
        case ActionTypes.WELLNESS_PLAN_SELECTED:
            return {
                ...state,
                selectedId: action.planId,
                providerId: action.providerId,
            }
        case ActionTypes.WELLNESS_PREMIER_PET_PLAN_UPDATED:
            return produce(state, newState => {
                if (newState.plans?.[action.petId]) {
                    newState.plans[action.petId].externalPetPlanReference = action.externalPetPlanReference;
                }
            });
        case ActionTypes.WELLNESS_CLEAR_SELECTED_PLAN:
            return {
                ...state,
                selectedId: null,
            }
        case ActionTypes.WELLNESS_PLAN_DETAILS_LOADED:
            return produce(state, newState => {
                newState.plans[action.id].details = action.plan;
            });
        case ActionTypes.WELLNESS_SEARCH_RESULTS_LOADED:
            return {
                ...state,
                searchResults: action.results,
                loading: false,
            };
        case ActionTypes.WELLNESS_HISTORY_SEARCH_FILTERED:
            return {
                ...state,
                query: action.query
            };
        case ActionTypes.WELLNESS_HISTORY_SEARCH_CLEARED:
            return {
                ...state,
                query: null
            };
        case ActionTypes.WELLNESS_VISITS_LOADING:
            return {
                ...state,
                isWellnessLoading: true,
            };
        case ActionTypes.WELLNESS_VISITS_LOADED:
            return {
                ...state,
                visits: keyBy(action.visits, "visitId"),
                isWellnessLoading: false,
            };
        case ActionTypes.WELLNESS_AUDIT_LOADED:
            return {
                ...state,
                processedVisits: keyBy(action.visits, "visitId"),
            };
        case ActionTypes.WELLNESS_VISIT_UPDATING:
            return {
                ...state,
                isWellnessUpdating: true,
            };
        case ActionTypes.WELLNESS_VISIT_UPDATED:
            // Wellness visit approve/disapprove
            // Wellness DVm assignment
            let updatedVisits = {...state.visits};

            // Remove first to ensure removing and adding the same visit (e.g., change DVM) works correctly
            forEach(action.removalIds, id => delete updatedVisits[id]);

            // Add or update
            forEach(action.visits, v => updatedVisits[v.visitId] = v);

            // Remove any visits that have been processed
            updatedVisits = filter(updatedVisits, v => !v.userInitials || v.userInitials === "");

            return {
                ...state,
                visits: keyBy(updatedVisits, "visitId"),
                isWellnessUpdating: false,
            };
        case ActionTypes.WELLNESS_VISIT_VOIDED:
            const visits = { ...state.visits };
            delete visits[action.visitId];
            return {
                ...state,
                visits: visits,
                isWellnessUpdating: false,
            };
        case ActionTypes.CLEAR_ALL_SEARCHES:
            return {
                ...state,
                searchResults: null,
                query: null
            };
        default:
            return state;
    }
}

function notifications(state = null, action) {
    switch (action.type) {
        case ActionTypes.START_ADMIN_CLINIC_DETAILS_LOAD:
            return null;
        case ActionTypes.NOTIFICATIONS_LOADED:
            // Clinic Notifications
            return keyBy(action.notifications, "notificationId");
        case ActionTypes.PROVIDER_DASHBOARD_LOADED:
            // Provider Notifications
            return keyBy(action.data.notifications, "notificationId")
        case ActionTypes.NOTIFICATION_DISMISSED:
            return {
                ...state,
                [action.notificationId]: {
                    ...state[action.notificationId],
                    dismissed: true,
                },
            };
        case ActionTypes.NOTIFICATION_STATE_WAS_SET:
            return produce(state, newState => {
                newState[action.notification.notificationId] = action.notification;
            });
        default:
            return state;
    }
}

// Currently, reminders are the same thing as notifications, eventually we would like to have new endpoints for reminders.
function reminders(state = {}, action) {
    switch (action.type) {
        case ActionTypes.START_ADMIN_CLINIC_DETAILS_LOAD:
            return null;
        case ActionTypes.NOTIFICATIONS_LOADED:
            // Clinic Reminders
            return keyBy(
                filter(action.notifications, {
                    notificationType: "Reminder",
                    enrollmentState: "pending",
                    isVisibleInNotifications: true
                }), "notificationId"
            );
        // TODO: At the moment, nothing can be done to reminders
        // case ActionTypes.REMINDER_DISMISSED:
        //     return {
        //         ...state,
        //         [action.notificationId]: {
        //             ...state[action.notificationId],
        //             dismissed: true,
        //         },
        //     };
        // case ActionTypes.REMINDER_STATE_WAS_SET:
        //     return produce(state, newState => {
        //         newState[action.notification.notificationId] = action.notification;
        //     });
        default:
            return state;
    }
}

function dashboard(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_DASHBOARD_FULL_LOADED:
            return action.dashboard.tiles;
        case ActionTypes.CLINIC_DASHBOARD_TILES_LOADED:
            return action.tiles;
        case ActionTypes.CLINIC_DASHBOARD_TILE_ADDED:
            return {
                ...state,
                [action.tileType]: action.tiles
            };
        case ActionTypes.CLINIC_DASHBOARD_TILE_REMOVED:
            const tiles = { ...state };
            delete tiles[action.tileType];
            return {
                ...tiles,
            }
        case ActionTypes.CLINIC_DASHBOARD_TILE_DATA:
            return {
                ...state,
                [action.tileType]: {
                    ...state[action.tileType],
                    data: action.data,
                }
            }
        default:
            return state;
    }
}

function rebateTiles(state = [], action) {
    switch (action.type) {
        case ActionTypes.CLINIC_DASHBOARD_FULL_LOADED:
            return action.dashboard?.rebateTiles?.items || [];
        default:
            return state;
    }
}

function stats(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_DASHBOARD_STATS_LOADING:
            return {
                ...state,
                statsLoading: true,
            }
        case ActionTypes.CLINIC_DASHBOARD_FULL_LOADED:
            return {
                ...state,
                ...keyBy(action.dashboard.stats.items, "id"),
                statsLoading: false,
            }
        case ActionTypes.CLINIC_DASHBOARD_STATS_LOADED:
            return {
                ...state,
                ...action.stats,
                statsLoading: false,
            };
        default:
            return state;
    }
}

function clinicUsers(state={}, action) {

    switch (action.type) {
        case ActionTypes.CLINIC_USERS_LOADED:
            return {
                ...keyBy(action.clinicUsers, "userId"),
            };
        case ActionTypes.CLINIC_USER_UPDATED:
            return {
                ...state,
                [action.clinicUser.userId]: {
                    ...action.clinicUser,
                },
            };
        case ActionTypes.CLINIC_USER_CREATED:
            return {
                ...state,
                [action.clinicUser.id]: action.clinicUser,
            };
        default:
            return state;
    }
}

function games(state= null, action) {
    switch (action.type) {
        case ActionTypes.LOADING_CLINIC_GAMES:
            return null;
        case ActionTypes.CLINIC_GAMES_LOADED:
            return keyBy(action.games, "greenlineGameId");
        default:
            return state;
    }
}

function gameInvites(state= {}, action) {
    switch (action.type) {
        case ActionTypes.GAME_INVITES_LOADED:
            return {
                access: action.data.access,
                results: keyBy(action.data.result, "clinicId"),
            };
        case ActionTypes.PROVIDER_CLINIC_ALIAS_UPDATED:
            return produce(state, (newState) => {
                newState[action.clinic.clinicId] = action.clinic;
            });
        case ActionTypes.GAME_INVITE_UPDATED:
            return {
                ...state,
                results: {
                    ...state.results,
                    [action.clinicId]: {
                        ...state.results[action.clinicId],
                        expirationDate: `12/31/${action.year}`,
                        optInState: InviteTypes.PENDING,
                    },
                }
            }
        default:
            return state;
    }
}

function providerGames(state={}, action) {
    switch (action.type) {
        case ActionTypes.PROVIDER_GAMES_LOADED:
            // Use this to find the templates
            const allGames = [
                ...action.games.pendingGames,
                ...action.games.currentGames,
                ...action.games.historicalGames
            ]
            return {
                ...state,
                access: action.games.access,
                currentGames: filter(action.games.currentGames, {isComplete: false, canUseAsTemplate: false}),
                completedGames: filter(action.games.currentGames, {isComplete: true, canUseAsTemplate: false}),
                pendingGames: filter(action.games.pendingGames, {canUseAsTemplate: false}),
                historicalGames: filter(action.games.historicalGames, {canUseAsTemplate: false}),
                canUseAsTemplate: filter(allGames, {canUseAsTemplate: true}),
            }
        case ActionTypes.PROVIDER_PREVIOUS_GAMES_LOADED:
            return {
                ...state,
                previousGames: action.previousGames,
            };
        case ActionTypes.PROVIDER_GAME_TEMPLATES_LOADED:
            return {
                ...state,
                gameTemplates: action.gameTemplates
            };
        case ActionTypes.PROVIDER_CLEAR_ALL_GAMES:
            return {};
        default:
            return state;
    }
}

// Clinics that can be used in a game
function providerGamesClinics(state={}, action) {
    switch (action.type) {
        case ActionTypes.PROVIDER_BI_GAMES_CLINICS_LOADED:
            return keyBy(action.clinics, "clinicId");
        default:
            return state;
    }
}

function gameProductBrands(state={}, action) {
    switch(action.type) {
        case ActionTypes.PROVIDER_PRODUCT_BRANDS_LOADED:
            return keyBy(action.gameProductBrands, "productBrandId");
        default: return state;
    }
}

function providers(state = {}, action) {
    switch (action.type) {
        case ActionTypes.PROVIDER_LIST_LOADED:
            return {
                ...state,
                ...keyBy(action.providers, "id")
            }
        case ActionTypes.PROVIDER_LOADED:
            return {
                ...state,
                [action.provider.id]: action.provider,
            };
        case ActionTypes.PROVIDER_UPDATED:
            if(action.data.isVisible) {
                return produce(state, newState => {
                    newState[action.data.corporateGroupId].providerVisibility.push({
                        providerId: action.data.providerId,
                        isvisible: action.data.isVisible
                    });
                    newState[action.data.corporateGroupId].manufacturerParticipationList.push(action.data.providerId);
                });
            } else {
                return produce(state, newState => {
                    newState[action.data.corporateGroupId].providerVisibility = reject(newState[action.data.corporateGroupId].providerVisibility, {providerId: action.data.providerId});
                    newState[action.data.corporateGroupId].manufacturerParticipationList = reject(newState[action.data.corporateGroupId].manufacturerParticipationList, m => (m === action.data.providerId));
                });
            }
    }
    return state;
}

function recentlyImpersonatedUsers(state= {}, action) {
    switch (action.type) {
        case ActionTypes.RECENTLY_IMPERSONATED_USERS_LOADED:
            return action.users;
        case ActionTypes.RECENTLY_IMPERSONATED_USERS_UPDATED:
            return {
                ...state,
                [action.user.id] : action.user,
            }
        case ActionTypes.RECENTLY_IMPERSONATED_USER_DISMISSED:
            const recentUsers = { ...state };
            delete recentUsers[action.recentUserId];
            return {
                ...recentUsers,
            }
        default:
            return state;
    }
}

function recentlyViewedClinics(state={}, action) {
    switch (action.type) {
        case ActionTypes.RECENTLY_VIEWED_CLINICS_LOADED:
            return action.clinics;
        case ActionTypes.RECENTLY_VIEWED_CLINICS_UPDATED:
            return {
                ...state,
                [action.clinic.id] : action.clinic,
            }
        case ActionTypes.RECENTLY_VIEWED_CLINIC_DISMISSED:
            const recentClinics = { ...state };
            delete recentClinics[action.recentClinicId];
            return {
                ...recentClinics,
            }
        default:
            return state;
    }
}

function mappedClinics(state={}, action) {
    switch (action.type) {
        case ActionTypes.ADMIN_CLINIC_MAP_DETAILS_LOADED:
        case ActionTypes.PROVIDER_CLINIC_MAP_DETAILS_LOADED:
            return keyBy(action.clinicsMapDetails, "id");
        default:
            return state;
    }
}

function reprocessingJobs(state = {}, action) {
    switch (action.type) {
        case ActionTypes.REPROCESSING_JOBS_LOADED:
            return {
                all: keyBy(action.reprocessingJobs, "id"),
                query: state.query,
            };
        case ActionTypes.REPROCESSING_JOBS_SEARCH_FILTERED:
            return {
                ...state,
                query: action.query
            };
        case ActionTypes.REPROCESSING_JOBS_SEARCH_CLEARED:
        case ActionTypes.CLEAR_ALL_SEARCHES:
            return {
                ...state,
                query: ""
            };
        default:
            return state;
    }
}

function tags(state = {}, action) {
    switch (action.type) {
        case ActionTypes.TAGS_LOADED:
            return keyBy(action.results, "id");
        case ActionTypes.TAG_CREATED:
            return {
                ...state,
                [action.tag.id]: action.tag,
            };
        default:
            return state;
    }
}

function pharmacyProductTypes(state = {}, action) {
    switch (action.type) {
        case ActionTypes.PHARMACY_PRODUCTS_LOADED:
            logger.log("LOADED", action)
            return keyBy(action.data.pharmacyProductTypes, "value")
        default:
            return state;
    }
}

function pharmacyProducts(state = {}, action) {
    switch (action.type) {
        case ActionTypes.PHARMACY_PRODUCTS_LOADED:
                logger.log("LOADED", action)
            return keyBy(action.data.pharmacy, "productId")
        default:
            return state;
    }
}

function foodProductTypes(state = {}, action) {
    switch (action.type) {
        case ActionTypes.FOOD_PRODUCTS_LOADED:
            return keyBy(action.data.foodProductTypes, "value")
        default:
            return state;
    }
}

function foodProducts(state = {}, action) {
    switch (action.type) {
        case ActionTypes.FOOD_PRODUCTS_LOADED:
            return keyBy(action.data.food, "productId")
        default:
            return state;
    }
}

function vaccineTypes(state = {}, action) {
    switch (action.type) {
        case ActionTypes.VACCINES_LOADED:
            return action.data.vaccineTypes
        default:
            return state;
    }
}

function vaccines(state = {}, action) {
    switch (action.type) {
        case ActionTypes.VACCINES_LOADED:
            return keyBy(action.data.vaccines, "vaccineId")
        default:
            return state;
    }
}

function services(state = {}, action) {
    switch (action.type) {
        case ActionTypes.SERVICES_LOADED:
            return keyBy(action.data, "examServiceId")
        default:
            return state;
    }
}

function vetCheckProducts(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_VETCHECK_CONTENT_LOADED:
            return {
                ...state,
                [action.clinicId]: keyBy(action.content, "clinicHandoutId"),
            }
        case ActionTypes.CLINIC_VETCHECK_SINGLE_CONTENT_LOADED:
            const isNew = !state?.[action.clinicId]?.[action.content.clinicHandoutId];
            return {
                ...state,
                [action.clinicId]: {
                    ...state[action.clinicId],
                    [action.content.clinicHandoutId]: {
                        ...action.content,
                        isNew
                    },
                }
            }
        default:
            return state;
    }
}

function wellnessProducts(state = {}, action) {
    switch (action.type) {
        case ActionTypes.WELLNESS_LOADED:
            return keyBy(action.data, "serviceTypeId")
        default:
            return state;
    }
}

function products(state = {}, action) {
    switch (action.type) {
        case ActionTypes.PRODUCTS_LOADED:
            return keyBy(action.data, "productId")
        default:
            return state;
    }
}

function brands(state = {}, action) {
    switch (action.type) {
        case ActionTypes.BRANDS_LOADED:
            return keyBy(action.data, "productBrandId");
        default:
            return state;
    }
}

function mappingCounts(state={}, action) {
    switch (action.type) {
        // Pharmacy Mappings
        case ActionTypes.PHARMACY_MAPPINGS_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    ...state[action.clinicId],
                    totalPharmacyProducts: action.mappings.totalMappings,
                    highestPharmacyIndex: action.mappings.totalMappings,
                }
            }
        case ActionTypes.PHARMACY_MAPPING_CREATED:
            return produce(state, (newState) => {
                newState[action.clinicId] = {
                    ...state[action.clinicId],
                    totalPharmacyProducts: state[action.clinicId]?.totalPharmacyProducts ? (state[action.clinicId]?.totalPharmacyProducts + 1) : 1,
                    highestPharmacyIndex: state[action.clinicId]?.highestPharmacyIndex ? (state[action.clinicId]?.highestPharmacyIndex + 1) : 1,
                };
            });
        case ActionTypes.PHARMACY_MAPPING_DELETED:
            return produce(state, (newState) => {
                newState[action.clinicId].totalPharmacyProducts -= 1;
                return newState;
            });
        // Food Mappings
        case ActionTypes.FOOD_MAPPINGS_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    ...state[action.clinicId],
                    totalFoodMappings: action.mappings.totalMappings,
                    highestFoodIndex: action.mappings.totalMappings
                }
            }
        case ActionTypes.FOOD_MAPPING_CREATED:
            return produce(state, (newState) => {
                newState[action.clinicId] = {
                    ...state[action.clinicId],
                    totalFoodMappings: state[action.clinicId]?.totalFoodMappings ? (state[action.clinicId]?.totalFoodMappings + 1) : 1,
                    highestFoodIndex: state[action.clinicId]?.highestFoodIndex ? (state[action.clinicId]?.highestFoodIndex + 1) : 1,
                };
            });
        case ActionTypes.FOOD_MAPPING_DELETED:
            return produce(state, (newState) => {
                newState[action.clinicId].totalFoodMappings -= 1;
                return newState;
            });
        // Vaccine Mappings
        case ActionTypes.VACCINE_MAPPINGS_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    ...state[action.clinicId],
                    totalVaccineMappings: action.mappings.totalMappings,
                    highestVaccineIndex: action.mappings.totalMappings
                }
            }
        case ActionTypes.VACCINE_MAPPING_CREATED:
            return produce(state, (newState) => {
                newState[action.clinicId] = {
                    ...state[action.clinicId],
                    totalVaccineMappings: state[action.clinicId]?.totalVaccineMappings ? (state[action.clinicId]?.totalVaccineMappings + 1) : 1,
                    highestVaccineIndex: state[action.clinicId]?.highestVaccineIndex ? (state[action.clinicId]?.highestVaccineIndex + 1) : 1,
                };
            });
        case ActionTypes.VACCINE_MAPPING_DELETED:
            return produce(state, (newState) => {
                newState[action.clinicId].totalVaccineMappings -= 1;
                return newState;
            });
        // Service Mappings
        case ActionTypes.SERVICE_MAPPINGS_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    ...state[action.clinicId],
                    totalServiceMappings: action.mappings.totalMappings,
                    highestServiceIndex: action.mappings.totalMappings
                }
            }
        case ActionTypes.SERVICE_MAPPING_CREATED:
            return produce(state, (newState) => {
                newState[action.clinicId] = {
                    ...state[action.clinicId],
                    totalServiceMappings: state[action.clinicId]?.totalServiceMappings ? (state[action.clinicId]?.totalServiceMappings + 1) : 1,
                    highestServiceIndex: state[action.clinicId]?.highestServiceIndex ? (state[action.clinicId]?.highestServiceIndex + 1) : 1,
                };
            });
        case ActionTypes.SERVICE_MAPPING_DELETED:
            return produce(state, (newState) => {
                newState[action.clinicId].totalServiceMappings -= 1;
                return newState;
            });
        // VetCheck Mappings
        case ActionTypes.CLINIC_VETCHECK_MAPPINGS_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    ...state[action.clinicId],
                    totalVetCheckMappings: action.mappings.totalMappings,
                    highestVetCheckContentIndex: action.mappings.totalMappings
                }
            }
        case ActionTypes.CLINIC_VETCHECK_MAPPING_CREATED:
            return produce(state, (newState) => {
                newState[action.clinicId] = {
                    ...state[action.clinicId],
                    totalVetCheckMappings: state[action.clinicId]?.totalVetCheckMappings ? (state[action.clinicId]?.totalVetCheckMappings + 1) : 1,
                    highestVetCheckContentIndex: state[action.clinicId]?.highestVetCheckContentIndex ? (state[action.clinicId]?.highestVetCheckContentIndex + 1) : 1,
                };
            });
        case ActionTypes.CLINIC_VETCHECK_MAPPING_DELETED:
            return produce(state, (newState) => {
                newState[action.clinicId].totalVetCheckMappings -= 1;
                return newState;
            });
        default:
            return state;
    }
}

function pharmacyMappings(state={}, action) {
    switch (action.type) {
        case ActionTypes.PHARMACY_MAPPINGS_LOADED:
            return {
                ...state,
                [action.clinicId]: keyBy(action.mappings.products, "productId")
            }
        case ActionTypes.PHARMACY_MAPPING_CREATED:
            return produce(state, (newState) => {
                newState[action.clinicId] = {
                    ...state[action.clinicId],
                    [action.mapping.productId]: {
                        ...(state[action.clinicId] && state[action.clinicId][action.mapping.productId]) ? state[action.clinicId][action.mapping.productId] : {},
                        productId: action.mapping.productId.toString(),
                        mappings: {
                            ...(!!(state[action.clinicId] && state[action.clinicId]?.[action.mapping.productId] && state[action.clinicId]?.[action.mapping.productId]?.mappings) ? state[action.clinicId][action.mapping.productId].mappings : {}),
                            [action.mapping.clinicProductId]: {
                                ...action.mapping,
                                order: action.order,
                            }
                        },
                    }
                };
            });
        case ActionTypes.PHARMACY_MAPPING_UPDATED:
            return produce(state, (newState) => {
                newState[action.clinicId][action.mapping.productId].mappings[action.mapping.clinicProductId] = action.mapping;
                return newState;
            });
        case ActionTypes.PHARMACY_MAPPING_DELETED:
            return produce(state, (newState) => {
                delete newState[action.clinicId][action.productId].mappings[action.clinicProductId];
                return newState;
            });
        case ActionTypes.PHARMACY_MAPPING_CREATED_AND_DELETED:
            return produce(state, (newState) => {
                newState[action.clinicId] = {
                    ...state[action.clinicId],
                    [action.mapping.productId]: {
                        ...(state[action.clinicId] && state[action.clinicId][action.mapping.productId]) ? state[action.clinicId][action.mapping.productId] : {},
                        productId: action.mapping.productId.toString(),
                        mappings: {
                            ...(!!(state[action.clinicId] && state[action.clinicId]?.[action.mapping.productId] && state[action.clinicId]?.[action.mapping.productId]?.mappings) ? state[action.clinicId][action.mapping.productId].mappings : {}),
                            [action.mapping.clinicProductId]: action.mapping
                        },
                    }
                };
                delete newState[action.clinicId][action.productId].mappings[action.clinicProductId];
                return newState;
            });
        default:
            return state;
    }
}

function foodMappings(state={}, action) {
    switch (action.type) {
        case ActionTypes.FOOD_MAPPINGS_LOADED:
            return {
                ...state,
                [action.clinicId]: keyBy(action.mappings.products, "productId")
            }
        case ActionTypes.FOOD_MAPPING_CREATED:
            return produce(state, (newState) => {
                newState[action.clinicId] = {
                    ...state[action.clinicId],
                    [action.mapping.productId]: {
                        ...(state[action.clinicId] && state[action.clinicId][action.mapping.productId]) ? state[action.clinicId][action.mapping.productId] : {},
                        productId: action.mapping.productId.toString(),
                        mappings: {
                            ...(!!(state[action.clinicId] && state[action.clinicId]?.[action.mapping.productId] && state[action.clinicId]?.[action.mapping.productId]?.mappings) ? state[action.clinicId][action.mapping.productId].mappings : {}),
                            [action.mapping.clinicProductId]: {
                                ...action.mapping,
                                order: action.order
                            }
                        },
                    }
                };
            });
        case ActionTypes.FOOD_MAPPING_UPDATED:
            return produce(state, (newState) => {
                newState[action.clinicId][action.mapping.productId].mappings[action.mapping.clinicProductId] = action.mapping;
                return newState;
            });
        case ActionTypes.FOOD_MAPPING_DELETED:
            return produce(state, (newState) => {
                delete newState[action.clinicId][action.productId].mappings[action.clinicProductId];
                return newState;
            });
        case ActionTypes.FOOD_MAPPING_CREATED_AND_DELETED:
            return produce(state, (newState) => {
                newState[action.clinicId] = {
                    ...state[action.clinicId],
                    [action.mapping.productId]: {
                        ...(state[action.clinicId] && state[action.clinicId][action.mapping.productId]) ? state[action.clinicId][action.mapping.productId] : {},
                        productId: action.mapping.productId.toString(),
                        mappings: {
                            ...(!!(state[action.clinicId] && state[action.clinicId]?.[action.mapping.productId] && state[action.clinicId]?.[action.mapping.productId]?.mappings) ? state[action.clinicId][action.mapping.productId].mappings : {}),
                            [action.mapping.clinicProductId]: action.mapping
                        },
                    }
                };
                delete newState[action.clinicId][action.productId].mappings[action.clinicProductId];
                return newState;
            });
        default:
            return state;
    }
}

function vaccineMappings(state={}, action) {
    switch (action.type) {
        case ActionTypes.VACCINE_MAPPINGS_LOADED:
            return {
                ...state,
                [action.clinicId]: keyBy(action.mappings.vaccines, "vaccineId")
            };
        case ActionTypes.VACCINE_MAPPING_CREATED:
            return produce(state, (newState) => {
                newState[action.clinicId] = {
                    ...state[action.clinicId],
                    [action.mapping.vaccineId]: {
                        ...(state[action.clinicId]?.[action.mapping.vaccineId]) ? state[action.clinicId][action.mapping.vaccineId] : {},
                        vaccineId: action.mapping.vaccineId.toString(),
                        mappings: {
                            ...(!!(state[action.clinicId] && state[action.clinicId]?.[action.mapping.vaccineId] && state[action.clinicId]?.[action.mapping.vaccineId]?.mappings) ? state[action.clinicId][action.mapping.vaccineId].mappings : {}),
                            [action.mapping.clinicProductMappingId]: {
                                ...action.mapping,
                                order: action.order
                            }
                        },
                    }
                };
            });
        case ActionTypes.VACCINE_MAPPING_UPDATED:
            return produce(state, (newState) => {
                newState[action.clinicId][action.mapping.vaccineId].mappings[action.mapping.clinicProductMappingId] = action.mapping;
                return newState;
            });
        case ActionTypes.VACCINE_MAPPING_DELETED:
            return produce(state, (newState) => {
                delete newState[action.clinicId][action.vaccineId].mappings[action.clinicProductMappingId];
                newState[action.clinicId].totalVaccineMappings -= 1;
                return newState;
            });
        default:
            return state;
    }
}

function serviceMappings(state={}, action) {
    switch (action.type) {
        case ActionTypes.SERVICE_MAPPINGS_LOADED:
            return {
                ...state,
                [action.clinicId]: keyBy(action.mappings.services, "examServiceId")
            };
        case ActionTypes.SERVICE_MAPPING_CREATED:
            return produce(state, (newState) => {
                newState[action.clinicId] = {
                    ...state[action.clinicId],
                    [action.mapping.examServiceId]: {
                        ...(state[action.clinicId] && state[action.clinicId][action.mapping.examServiceId]) ? state[action.clinicId][action.mapping.examServiceId] : {},
                        examServiceId: action.mapping.examServiceId.toString(),
                        mappings: {
                            ...(!!state[action.clinicId]?.[action.mapping.examServiceId]?.mappings ? state[action.clinicId][action.mapping.examServiceId].mappings : {}),
                            [action.mapping.mappingId]: {
                                ...action.mapping,
                                order: action.order
                            }
                        },
                    }
                };
            });
        case ActionTypes.SERVICE_MAPPING_UPDATED:
            return produce(state, (newState) => {
                newState[action.clinicId][action.mapping.examServiceId].mappings[action.mapping.mappingId] = action.mapping;
                return newState;
            });
        case ActionTypes.SERVICE_MAPPING_DELETED:
            return produce(state, (newState) => {
                delete newState[action.clinicId][action.examServiceId].mappings[action.mappingId];
                return newState;
            });
        default:
            return state;
    }
}

function vetCheckMappings(state={}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_VETCHECK_MAPPINGS_LOADED:
            return {
                ...state,
                [action.clinicId]: keyBy(action.mappings.vetCheckContent, "clinicHandoutId")
            };
        case ActionTypes.CLINIC_VETCHECK_MAPPING_CREATED:
            return produce(state, (newState) => {
                newState[action.clinicId] = {
                    ...state[action.clinicId],
                    [action.mapping.clinicHandoutId]: {
                        ...(state[action.clinicId]?.[action.mapping.clinicHandoutId]) ? state[action.clinicId][action.mapping.clinicHandoutId] : {},
                        clinicHandoutId: action.mapping.clinicHandoutId.toString(),
                        mappings: {
                            ...(!!state[action.clinicId]?.[action.mapping.clinicHandoutId]?.mappings ? state[action.clinicId][action.mapping.clinicHandoutId].mappings : {}),
                            [action.mapping.mappingId]: {
                                ...action.mapping,
                                order: action.order
                            }
                        },
                    }
                };
            });
        case ActionTypes.CLINIC_VETCHECK_MAPPING_UPDATED:
            return produce(state, (newState) => {
                newState[action.clinicId][action.mapping.clinicHandoutId].mappings[action.mapping.mappingId] = action.mapping;
                return newState;
            });
        case ActionTypes.CLINIC_VETCHECK_MAPPING_DELETED:
            return produce(state, (newState) => {
                delete newState[action.clinicId][action.clinicHandoutId].mappings[action.mappingId];
                return newState;
            });
        default:
            return state;
    }
}

function wellnessMappings(state={}, action) {
    switch (action.type) {
        case ActionTypes.WELLNESS_MAPPINGS_LOADED:
            return {
                ...state,
                [action.clinicId]: keyBy(action.mappings, "clinicPriceScheduleId")
            }
        case ActionTypes.WELLNESS_MAPPING_CREATED:
            return produce(state, (newState) => {
                newState[action.mapping.clinicId] = {
                    ...state[action.mapping.clinicId],
                    [action.mapping.clinicPriceScheduleId]: {
                        ...!!state[action.mapping?.clinicId]?.[action.mapping?.clinicPriceScheduleId] ? state[action.mapping.clinicId][action.mapping?.clinicPriceScheduleId] : {},
                        clinicPriceScheduleId: action.mapping.clinicPriceScheduleId,
                        serviceTypes: {
                            ...!!state[action.mapping?.clinicId]?.[action.mapping?.clinicPriceScheduleId]?.serviceTypes ? state[action.mapping.clinicId][action.mapping.clinicPriceScheduleId].serviceTypes : {},
                            [action.mapping.serviceTypeId]: {
                                ...!!state[action.mapping?.clinicId]?.[action.mapping?.clinicPriceScheduleId]?.serviceTypes[action.mapping?.serviceTypeId] ? state[action.mapping.clinicId][action.mapping?.clinicPriceScheduleId].serviceTypes[action.mapping.serviceTypeId] : {},
                                serviceTypeId: action.mapping.serviceTypeId,
                                mappings: {
                                    ...!!state[action.mapping?.clinicId]?.[action.mapping?.clinicPriceScheduleId]?.serviceTypes[action.mapping?.serviceTypeId]?.mappings ? state[action.mapping.clinicId][action.mapping.clinicPriceScheduleId].serviceTypes[action.mapping.serviceTypeId].mappings : {},
                                    [action.mapping.mappingInstanceId]: action.mapping
                                }
                            }
                        }
                    }
                };
            });
        case ActionTypes.WELLNESS_MAPPING_UPDATED:
            return produce(state, (newState) => {
                if(action.oldMappingInstanceId !== action.mapping.mappingInstanceId) {
                    delete newState[action.mapping.clinicId][action.mapping.clinicPriceScheduleId].serviceTypes[action.mapping.serviceTypeId].mappings[action.oldMappingInstanceId];
                }
                newState[action.mapping.clinicId][action.mapping.clinicPriceScheduleId].serviceTypes[action.mapping.serviceTypeId].mappings[action.mapping.mappingInstanceId] = action.mapping;
                return newState;
            });
        case ActionTypes.WELLNESS_MAPPING_DELETED:
            return produce(state, (newState) => {
                delete newState[action.data.clinicId][action.data.clinicPriceScheduleId].serviceTypes[action.data.serviceTypeId].mappings[action.data.mappingInstanceId];
                return newState;
            });

        default:
            return state;
    }
}

function pimsUsers(state = { }, action) {
    switch (action.type) {
        case ActionTypes.PIMS_USERS_LOADED:
            return {
                staffTypes:action.data.staffTypes,
                users: keyBy(action.data.users, "pimsUserId"),
            };
        case ActionTypes.PIMS_USERS_UPDATING:
            return {
                ...state,
                updating: action.pimsUserId,
            }
        case ActionTypes.PIMS_USERS_UPDATED:
            return {
                ...state,
                updating: null,
            };
        default:
            return state;
    }
}

function pims(state = {}, action) {
    switch (action.type) {
        case ActionTypes.PIMS_LOADING:
            return {
                ...state,
                loading: true,
            };
        case ActionTypes.PIMS_LOADED:
            return {
                ...state,
                products: keyBy(action.results, "clinicProductId"),
                loading: false,
            };
        case ActionTypes.PIMS_CONFIGS_LOADED:
            return {
                ...state,
                config: keyBy(action.results, "softwareVendorId"),
                loading: false,
            };
        default:
            return state;
    }
}

function clinicPims(state={}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_PIMS_CONFIG_LOADED:
        case ActionTypes.CLINIC_PIMS_CONFIG_UPDATED:
            return {
                ...state,
                [action.clinicPim.clinicId]: {
                    ...action.clinicPim,
                    // installationAccess: state[action.clinicPim.clinicId]?.installationAccess,
                },
            };
        default:
            return state;
    }
}



function tokens(state = {}, action) {
    switch (action.type) {
        case ActionTypes.TOKEN_DETAILS_LOADED:
            return {
                ...state,
                [action.data.tokenId]: {
                    ...action.data,
                    invoices: keyBy(action.data.invoices, "invoiceId")
                }
            };
        case ActionTypes.TOKEN_REDEMPTION_UPDATED:
            return {
                ...state,
                [action.data.tokenId]: {
                    ...state[action.data.tokenId],
                    invoices: {
                        ...state[action.data.tokenId].invoices,
                        [action.data.invoices[0].invoiceId]: action.data.invoices[0]
                    }
                }
            }
        default:
            return state;
    }
}


function preAuditReview(state = {}, action) {
    switch (action.type) {
        case ActionTypes.COUPON_PRE_AUDIT_LOADED:
            return keyBy(map(action.issues, issue => {
                return {
                    ...issue,
                    redemptions: keyBy(issue.redemptions, "externalRedemptionIdentifierId")
                }
            }), "invoiceId");
        case ActionTypes.COUPON_PRE_AUDIT_APPROVED:
        case ActionTypes.COUPON_PRE_AUDIT_DECLINED:
            let newState = produce(state, issues => issues);
            forEach(action.items, (items, index) => {
                if (newState[index].redemptions.length > items.length) {
                    //If there are less redemptions being processed than there are total then just delete the invoices
                    forEach(items, (item) => {
                        newState = omit(newState[index].redemptions, item);
                    });
                } else {
                    //If all of the redemptions are being processed then delete the invoice
                    newState = omit(newState, index);
                }
            });
            return newState;
        case ActionTypes.PRODUCT_OVERRIDE_UPDATED:
            return keyBy(map(state, issue => {
                return {
                    ...issue,
                    reasons: map(issue.reasons, reason => {
                        if((reason.clinicId === action.data.clinicId) && (reason.productId === action.data.productId) && reason.reasonCode === action.reasonCode) {
                            return {
                                ...reason,
                                overrideApplied: true
                            }
                        } else return reason
                    })
                }
            }), "invoiceId");
        default:
            return state;
    }
}

function productOverrides(state = {}, action) {
    switch (action.type) {
        case ActionTypes.PRODUCT_OVERRIDE_UPDATE_REQUESTED:
            return {
                ...state,
                loading: true,
            }
        case ActionTypes.ALL_CLINIC_PRODUCT_OVERRIDES_LOADED:
            return produce(state, newState => {
                if (!newState.products) {
                    newState.products = {}; // Putting the list of products for a clinic here so it won't overwrite PRODUCT_OVERRIDE_LOADED data
                }
                forEach(action.data, product => {
                    if (!newState.products[action.clinicId]) {
                        newState.products[action.clinicId] = {};
                    }
                    newState.products[action.clinicId][product.productId] = product;
                });
                newState.loading = false;
            });
        case ActionTypes.PRODUCT_OVERRIDE_LOADED:
            return produce(state, newState => {
                if (!newState[action.clinicId]) {
                    newState[action.clinicId] = {};
                }
                newState[action.clinicId][action.data.productId] = action.data;
                newState[action.clinicId][action.data.productId].configs = keyBy(action.data.configs, "order");
                newState.loading = false;
            });
        //    This is not currently used. If we want to use it we will need to update it.
        // case ActionTypes.PRODUCT_OVERRIDE_UPDATED:
        //     return produce(state, newState => {
        //         newState[action.data.clinicId][action.data.productId].clinicConfig = action.data
        //         newState[action.data.clinicId][action.data.productId].clinicDosePricing = map(action.data.dosePricing, (dp, index) => {
        //             return {
        //                 ...dp,
        //                 order: (index + 1)
        //             }
        //         });
        //         newState.loading = false;
        //     });
        default:
            return state;
    }
}

function invoices(state = {}, action) {
    switch (action.type) {
        case ActionTypes.INVOICES_SEARCHED:
            return keyBy(action.data, "invoiceId");
        case ActionTypes.CLEAR_INVOICES:
            return {};
        default:
            return state;
    }
}

function genericSearch(state = null, action) {
    switch (action.type) {
        case ActionTypes.GENERIC_SEARCHED:
            return action.searchTerms
        case ActionTypes.GENERIC_SEARCH_CLEARED:
        case ActionTypes.CLEAR_ALL_SEARCHES:
            return null;
        default:
            return state;
    }
}

function searchValues(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_MANAGEMENT_SEARCH:
            return {
                ...state,
                clinicManagement: action.search
            };
        case ActionTypes.USER_MANAGEMENT_SEARCH:
            return {
                ...state,
                userManagement: action.search
            };
        case ActionTypes.CLEAR_ALL_SEARCHES:
            return {};
        default:
            return state;
    }
}

function productTags(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_PRODUCT_TAGS_LOADING:
            return {
                ...state,
                loading: true
            };
        case ActionTypes.CLINIC_PRODUCT_TAGS_LOADED:
            return {
                data: keyBy(action.data, "clinicProductId"),
                loading: false
            };
        case ActionTypes.CLINIC_PRODUCT_TAGS_UPDATED:
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.data.clinicProductId]: {
                        ...action.data,
                    },
                },
                loading: false
            }
        case ActionTypes.CLINIC_PRODUCT_TAGS_SEARCHED:
            return {
                ...state,
                query: action.search,
            }
        case ActionTypes.CLINIC_PRODUCT_TAGS_SEARCH_CLEARED:
        case ActionTypes.CLEAR_ALL_SEARCHES:
            return {
                ...state,
                query: "",
            }
        default:
            return state;
    }
}

function plans(state = null, action) {
    switch (action.type) {
        case ActionTypes.PLANS_LOADED:
            return keyBy(action.results, "planId");
        default:
            return state;
    }
}

function productAnalysis(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_PRODUCT_ANALYSIS_LOADED:
            return {
                ...state,
                [action.data.clinicProductId]: {
                    ...action.data
                },
            }
        default:
            return state;
    }
}

function providerPlans(state = {}, action) {
    switch (action.type) {
        case ActionTypes.PROVIDER_PLANS_LOADED:
            return {
                ...state,
                [action.providerId]: {
                    ...keyBy(map(action.data, d => {
                        return {
                            ...d,
                            termMonths: d.termLengthMonths,
                            serviceTypes: {
                                ...keyBy(d.serviceTypes, "planServiceTypeId"),
                            },
                            serviceCategories : uniq(flatMap(d.serviceTypes, "categoryName")),
                        }
                    }), "planId"),
                },
            }
        case ActionTypes.PROVIDER_PLAN_UPDATED:
            return {
                ...state,
                [action.providerId]: {
                    ...state[action.providerId],
                    [action.plan.planId]: {
                        ...action.plan,
                        termMonths: action.plan.termLengthMonths,
                        serviceTypes: {
                            ...keyBy(action.plan.serviceTypes, "planServiceTypeId"),
                        },
                        serviceCategories : uniq(flatMap(action.plan.serviceTypes, "categoryName")),
                    },
                }
            }
        default:
            return state;
    }
}

function vouchers(state = null, action) {
    switch (action.type) {
        case ActionTypes.VOUCHER_CODES_LOADED:
            return {
                ...state,
                [action.planId]: {
                    ...action.results,
                    data: keyBy(action.results.data, "voucherCode"),
                },
            };
        case ActionTypes.VOUCHER_CODE_UPDATED:
            const updated = state[action.planId].updated ? [...state[action.planId].updated] : [];
            if(!includes(updated, action.results.voucherCode)) {
                updated.push(action.results.voucherCode)
            }

            return {
                ...state,
                [action.planId]: {
                    ...state[action.planId],
                    data: {
                        ...state[action.planId].data,
                        [action.results.voucherCode]: {
                            ...action.results,
                        }
                    },
                    "updated": updated,
                },
            };
        case ActionTypes.VOUCHERS_GENERATED:
            return {
                ...state,
                [action.planId]: {
                    ...state[action.planId],
                    data: {
                        ...state[action.planId].data,
                        ...keyBy(action.results, "voucherCode"),
                    },
                    "generated": state[action.planId].generated ? (action.results.length + state[action.planId].generated) : action.results.length,
                },
            };
        default:
            return state;
    }
}

function taxRates(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_TAX_RATES_LOADED:
        case ActionTypes.CLINIC_TAX_RATES_UPDATED:
            return {
                ...state,
                [action.clinicId]: {
                    ...keyBy(action.data, "clinicTaxId"),
                },
            }
        default:
            return state;
    }
}

function wellnessCommissions(state = {}, action) {
    switch (action.type) {
        case ActionTypes.WELLNESS_DVM_COMMISSIONS_LOADED:
            const withId = map(action.commissions?.data, entry => {
                return {
                    ...entry,
                    id: entry.clinicDvmId,
                    history: [],
                };
            });
            return keyBy(withId, "id");

        case ActionTypes.WELLNESS_DVM_COMMISSION_HISTORY_LOADED:
            return {
                ...state,
                [action.clinicDvmId]: {
                    ...state[action.clinicDvmId],
                    history: action.history,
                }
            }
        case ActionTypes.WELLNESS_DVM_COMMISSION_HISTORIES_UPDATED:
            const clinicDvmId = action?.histories[0]?.clinicDvmId ? action.histories[0].clinicDvmId : null;
            const currentDVMHistory = clinicDvmId ? find(action.histories, a => a.clinicDvmHistoryId === state[clinicDvmId].clinicDvmHistoryId) : {};
            return {
                ...state,
                [clinicDvmId]: {
                    ...state[clinicDvmId],
                    productsCommissionPercentage: !!currentDVMHistory?.productsCommissionPercentage ? currentDVMHistory.productsCommissionPercentage : 0,
                    servicesCommissionPercentage: !!currentDVMHistory?.servicesCommissionPercentage ? currentDVMHistory.servicesCommissionPercentage : 0,
                    startDate: !!currentDVMHistory?.startDate ? currentDVMHistory.startDate : null,
                    endDate: !!currentDVMHistory?.endDate ? currentDVMHistory.endDate : null,
                    isActive: !!currentDVMHistory?.isActive ? currentDVMHistory.isActive : false,
                    history: action.histories
                }
            };
        case ActionTypes.WELLNESS_DVM_COMMISSION_ADDED:
            return produce(state, newState => {
                newState[action.commission.clinicDvmId] = {
                    history: [],
                    id: action.commission.clinicDvmId,
                    ...action.commission,
                };
            });

        case ActionTypes.WELLNESS_DVM_COMMISSION_UPDATED:
            return {
                ...state,
                [action.commission.clinicDvmId]: {
                    ...state[action.commission.clinicDvmId],
                    ...action.commission,
                }
            };
        case ActionTypes.WELLNESS_DVM_COMMISSION_DELETED:
            const data = {...state};
            delete data[action.dvmId];
            return data;
        default:
            return state;
    }
}

function offers(state = {}, action) {
    switch (action.type) {
        case ActionTypes.ALL_OFFERS_LOADED:
            return keyBy(action.data, "value");
        default:
            return state;
    }
}

function pricingSchedules(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_PRICING_SCHEDULE_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    ...keyBy(action.data, "clinicPriceScheduleId"),
                },
            };
        case ActionTypes.CLINIC_WELLNESS_PLANS_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    ...keyBy((action.wellnessPlans?.priceSchedules || []), "clinicPriceScheduleId"),
                },
            };
        case ActionTypes.CLINIC_PRICING_SCHEDULE_DELETED:
            const data = {...state};
            delete data[action.clinicId].taxRates[action.clinicPricingScheduleId];
            return data;
        default:
            return state;
    }
}

function providerReports(state = {}, action) {
    switch (action.type) {
        case ActionTypes.PROVIDER_REPORTS_LOADED:
            return keyBy(action.data, "value");
        default:
            return state;
    }
}

function redemptions(state = {}, action) {
    switch (action.type) {
        case ActionTypes.REDEMPTIONS_LOADED:
            return {
                ...state,
                [!!action.data.clinicId ? action.data.clinicId: action.clinicId]: {
                    ...cloneDeep(state[action.data.clinicId]),
                    clinicId: action.data.clinicId,
                    clinicName: action.data.clinicName,
                    year: action.data.year,
                    clinicTotals: {
                        ...state[action.data.clinicId] ? state[action.data.clinicId].clinicTotals : {},
                        [action.data.year]: {
                            ...cloneDeep(action.data.clinicTotal)
                        },
                    },
                    results: {
                        ...state[action.data.clinicId] ? state[action.data.clinicId].results : {},
                        [action.data.year]: [
                            ...cloneDeep(action.data.results)
                        ],
                    },
                    grandTotals: {
                        ...state[action.data.clinicId] ? state[action.data.clinicId].grandTotals : {},
                        [action.data.year]: {
                            ...cloneDeep(action.data.grandTotal)
                        },
                    },
                    hasNoResults: !action.data.clinicId,
                }
            }
        default:
            return state;
    }
}

function clinicViews(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_VIEWS_LOADED:
            return keyBy(action.data, "userPreferenceId");
        case ActionTypes.CLINIC_VIEW_CREATED:
            return {
                ...state,
                [action.data.userPreferenceId]: action.data,
            };
        case ActionTypes.CLINIC_VIEW_UPDATED:
            return {
                ...state,
                [action.data.userPreferenceId]: {
                    ...state[action.data.userPreferenceId],
                    ...action.data,
                },
            };
        case ActionTypes.CLINIC_VIEW_DELETED:
            const tempState = {...state};
            delete tempState[action.userPreferenceId];
            return tempState;
        default:
            return state;
    }
}

function adminTools(state={}, action) {
    switch (action.type) {
        case ActionTypes.VOUCHER_PLAN_SELECTED:
            return {
                voucherPlan: action.planId,
            };
        case ActionTypes.GENERIC_SHOW_ADD_FORM:
            return {
                addNewForm: true,
            }
        case ActionTypes.GENERIC_HIDE_ADD_FORM:
            return {};
        case ActionTypes.CLINICS_TABLE_IDS_SET:
            LocalData.setClinicsTableIds(action.ids);
            return state;
        case ActionTypes.CLINICS_TABLE_IDS_CLEARED:
            LocalData.clearClinicsTableIds();
            return state;
        default:
            return state;
    }
}

function handouts(state = {}, action) {
    switch (action.type) {
        case ActionTypes.VETCHECK_HANDOUT_SEARCH_FILTERED:
            return {
                ...state,
                query: action.query
            };
        case ActionTypes.VETCHECK_HANDOUT_SEARCH_CLEARED:
        case ActionTypes.CLEAR_ALL_SEARCHES:
            return {
                ...state,
                query: ""
            };
        default:
            return state;
    }
}

function petMarketingInfo(state = {}, action) {
    switch (action.type) {
        case ActionTypes.PET_MARKETING_INFO_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    ...state[action.clinicId],
                    ...action.petMarketingInfo,
                    data: {
                        ...keyBy(action.petMarketingInfo.data, "clientId"),
                    },
                },
            };
        case ActionTypes.PET_MARKETING_INFO_UPDATED:
            return produce(state, newState => {
                if(newState[action.clinicId]) {
                    newState[action.clinicId].data[action.clientId] = {
                        ...newState[action.clinicId]?.data[action.clientId],
                        ...action.data,
                    }
                }
                newState.loading = false;
                return newState;
            });
        case ActionTypes.PET_MARKETING_INFO_FILTER_OUT:
            return produce(state, newState => {
                delete newState[action.clinicId].data[action.clientId]
                newState.loading = false;
                return newState;
            });
        default:
            return state;
    }
}

function petOwners(state = {}, action) {
    switch (action.type) {
        case ActionTypes.VETCHECK_PET_OWNERS_LOADED_SEARCH_FILTERED:
            return {
                query: action.query,
            };
        case ActionTypes.VETCHECK_PET_OWNERS_LOADED_SEARCH_CLEARED:
        case ActionTypes.CLEAR_ALL_SEARCHES:
            return {
                query: "",
            };
        default:
            return state;
    }
}

function dvms(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_DVMS_LOADING:
            return {
                ...state,
                loading: true
            };
        case ActionTypes.CLINIC_DVMS_LOADED:
            return {
                ...state,
                ...keyBy(action.dvms, "id"),
                loading: false
            };
        default:
            return state;
    }

}

function priceHistories(state = {}, action) {
    switch (action.type) {
        case ActionTypes.PRICE_HISTORY_LOADED:
            return {
                ...state,
                [action.clinicProductId]: action.priceHistory
            };
        default:
            return state;
    }

}

function clinicWellnessPlans(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_WELLNESS_PLANS_LOADING:
            return {
                ...state,
                loading: true,
            }

        case ActionTypes.CLINIC_WELLNESS_PLANS_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    ...action.wellnessPlans,
                    houseAccounts: keyBy(action.wellnessPlans.houseAccounts, "id"),
                    plans: {
                        ...keyBy(map(action.wellnessPlans.plans, plan => {
                            return {
                                ...plan,
                                enrollmentMappings: keyBy(plan.enrollmentMappings, "id"),
                                services: {
                                    ...keyBy(map(plan.services, service => {
                                        return {
                                            ...service,
                                            enrollmentMappings: keyBy(service.enrollmentMappings, "id"),
                                        }
                                    }), "clinicPlanServiceTypeId"),
                                }
                            }
                        }), "clinicPlanInstanceId"),
                    },
                    priceSchedules: keyBy(action.wellnessPlans.priceSchedules, "clinicPriceScheduleId")
                },
                loading: false,
            };
        default:
            return state;
    }
}

function clinicWellnessPlanInstances(state = {}, action) {
    switch(action.type) {

        case ActionTypes.CLINIC_WELLNESS_PLAN_INSTANCE_ADDED:
            return produce(state, newState => {
                newState[action.clinicId].plans = {
                    ...newState[action.clinicId].plans,
                    [action.plan.clinicPlanId]: action.plan,
                }
                return newState;
            });

        case ActionTypes.CLINIC_WELLNESS_PLAN_INSTANCES_LOADING:
            return {
                ...state,
                loading: true,
            }

        case ActionTypes.CLINIC_WELLNESS_PLAN_INSTANCES_LOADED:

            const { houseAccounts, plans, ...everythingElse} = action.wellnessPlans;

            return {
                ...state,
                [action.clinicId]: {
                    ...everythingElse,
                    houseAccounts: keyBy(houseAccounts, "id"),
                    plans: keyBy(map(plans, plan => {
                        return {
                            ...plan,
                            feeToProvider: plan.providerFee,
                            ...plan.clinicPlanDto,
                            services: map(plan.services, svc => {
                                return {
                                    ...svc,
                                    planServiceTypeId: svc.clinicPlanServiceTypeId,
                                }
                            })
                        }
                    }), "clinicPlanInstanceId")
                },
                loading: false,
            }
        default:
            return state;
    }
}

function forceSearch(state = null, action) {
    switch (action.type) {
        case ActionTypes.SET_FORCE_SEARCH:
            return action.value;
        default:
            return state;
    }
}

function snapshots(state = null, action) {
    switch (action.type) {
        case ActionTypes.SNAPSHOT_LOADING:
            return {
                ...state,
                loading: true,
            }
        case ActionTypes.SNAPSHOT_LOADED:
            return {
                ...state,
                [action.snapshotId]: action.snapshot,
                loading: false,
            };
        default:
            return state;
    }
}

function gameSnapshotComparison(state = {}, action) {
    switch (action.type) {
        case ActionTypes.TWO_SNAPSHOTS_LOADED:
            return {
                ...state,
                [action.gameId]: orderBy(action.snapshots, "reportDate"),
            }
        case ActionTypes.TWO_SNAPSHOTS_LOAD_ERROR:
            // There are not two snapshots
            return {
                ...state,
                [action.gameId]: [{
                    CurrentPeriod: {
                        TotalPuppyBowlMVPs: 0,
                        TotalPuppyBowlFieldGoals: 0,
                        TotalPuppyBowlTouchdowns: 0,
                        TotalPuppyBowlPoints: 0,
                    },
                    PoolPartySummary: {
                        CurrentPeriod: {
                            Doses: 0,
                            TotalDoses: 0,
                            ExtraDoses: 0,
                        },
                    },
                }],
            }
        default:
            return state;
    }
}

function softwareVendors(state = null, action) {
    switch (action.type) {
        case ActionTypes.SOFTWARE_VENDORS_LOADING:
            return {
                ...state,
                loading: true
            };
        case ActionTypes.SOFTWARE_VENDORS_LOADED:
            return {
                data: keyBy(action.data, "softwareVendorId"),
                loading: false
            }
        default:
            return state;
    }
}

function clinicReports(state = {}, action) {
    switch (action.type) {
        case ActionTypes.CLINIC_REPORTS_LOADING:
            return {
                ...state,
                loading: true
            };
        case ActionTypes.CLINIC_REPORTS_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    ...state[action.clinicId],
                    [action.providerId]: keyBy(action.data, "providerReportId"),
                },
                loading: false
            }
        case ActionTypes.CLINIC_REPORTS_UPDATED:
            return produce(state, newState => {
                newState[action.data.clinicId][action.data.providerId][action.data.providerReportId].isActivated = action.data.isActivated;
                newState[action.data.clinicId][action.data.providerId][action.data.providerReportId].activationDate = action.data.activationDate;
                newState.loading = false;
            });
        default:
            return state;
    }
}

function cumulativeOffers(state={}, action) {
    switch (action.type) {
        case ActionTypes.CUMULATIVE_OFFER_TRACKING_OPTIONS_LOADING:
            return {
                ...state,
                optionsLoading: true,
            };
        case ActionTypes.CUMULATIVE_OFFER_TRACKING_LOADING:
            return {
                ...state,
                dataLoading: true,
            };
        case ActionTypes.CUMULATIVE_OFFER_TRACKING_OPTIONS_LOADED:
            return produce(state, newState => {
                newState[action.clinicId] = state[action.clinicId] || {};
                newState[action.clinicId].cumulativeOfferOptions = action.options;
                newState.optionsLoading = false;
            });
        case ActionTypes.CUMULATIVE_OFFER_TRACKING_LOADED:
            return produce(state, newState => {
                newState[action.clinicId] = state[action.clinicId] || {};
                newState[action.clinicId].cumulativeOfferData = action.data;
                newState.dataLoading = false;
            });
        default:
            return state;
    }
}

function providerClinicAggregates(state={}, action) {
    switch (action.type) {
        case ActionTypes.PROVIDER_CLINIC_AGGREGATES_LOADED:
            return {
                ...state,
                [action.nodeId]: groupBy(action.data, "manufacturerId")
            };
        default:
            return state;
    }
}

function providerHierarchies(state={}, action) {
    switch (action.type) {
        case ActionTypes.PROVIDER_HIERARCHY_LOADED:
            return {
                ...state,
                [action.providerId]: keyBy(map(flatten(action?.data?.hierarchyDtos), node => {
                    const name = split(node.displayName, "(");
                    return {
                        ...node,
                        displayName: name[0]
                    }
                }), "nodeId")
            }
        default:
            return state;
    }
}

function nodes(state={}, action) {
    switch(action.type) {
        case ActionTypes.NODE_LOADED:
            return {
                ...state,
                [action.node?.nodeId]: action.node
            };
        case ActionTypes.NODE_CHILDREN_LOADED:
            // When the child nodes are loaded they match the normal nodes
            // (don't have to load the current node if we store them here)
            return {
                ...state,
                ...keyBy(action.children, "nodeId")
            }
        default:
            return state;
    }
}

function nodeChildren(state={}, action) {
    switch(action.type) {
        case ActionTypes.NODE_CHILDREN_LOADED:
            return {
                ...state,
                [action.nodeId]: action.children,
            };
        default:
            return state;
    }
}

function nodeClinics(state={}, action) {
    switch(action.type) {
        case ActionTypes.PROVIDER_NODE_CLINICS_LOADED:
            return {
                ...state,
                [action.nodeId]: keyBy(action.clinics, "clinicId")
            }
        case ActionTypes.PROVIDER_CLINIC_ALIAS_UPDATED:
            return produce(state, newState => {
                newState[action.nodeId][action.clinic.clinicId] = action.clinic;
            });
        default:
            return state;
    }
}

function nodeClinicColumns(state={}, action) {
    switch(action.type) {
        case ActionTypes.PROVIDER_NODE_CLINICS_LOADED:
            return {
                ...state,
                [action.nodeId]: action.columns,
            };
        default:
            return state;
    }
}

function vetCheckAutomations(state={}, action) {
    switch(action.type) {
        case ActionTypes.VETCHECK_AUTOMATIONS_LOADED:
            return keyBy(action.automations, "mappingId");
        case ActionTypes.VETCHECK_AUTOMATION_CREATED:
            return {
                ...state,
                [action.automation.mappingId]: action.automation
            }
        case ActionTypes.VETCHECK_AUTOMATION_DELETED:
            return produce(state, (newState) => {
                delete newState[action.mappingId];
                return newState;
            });
        default:
            return state;
    }
}

function accountHolds(state={}, action) {
    switch (action.type) {
        case ActionTypes.ACCOUNT_HOLDS_LOADED:
            return {
                ...state,
                [action.clinicId]: keyBy(action.holds, "manufacturerId")
            }
        case ActionTypes.ACCOUNT_HOLD_CREATED:
            return {
                ...state,
                [action?.hold?.clinicId]: {
                    ...state?.[action?.hold?.clinicId],
                    [action?.hold?.manufacturerId]: action?.hold
                }
            }
        case ActionTypes.ACCOUNT_HOLD_RELEASED:
            return produce(state, (newState) => {
                delete newState[action.clinicId][action.manufacturerId];
                return newState;
            });
        default:
            return state;
    }
}

function clinicVetCheckStatus(state={}, action) {
    switch(action.type) {
        case ActionTypes.CLINIC_VETCHECK_STATUS_LOADED:
            return {
                ...state,
                [action.status.clinicId]: action.status
            }
        default:
            return state;
    }
}

function vetCheckContent(state={}, action) {
    switch(action.type) {
        case ActionTypes.VETCHECK_CONTENT_LOADED:
            return keyBy(action.content, "clinicHandoutId");
        case ActionTypes.VETCHECK_SINGLE_CONTENT_LOADED:
            return {
                ...state,
                [action.content.clinicHandoutId]: action.content
            };
        default:
            return state;
    }
}

function showChangeVetCheckPassword(state=false, action) {
    switch(action.type) {
        case ActionTypes.SHOW_CHANGE_VETCHECK_PASSWORD:
            return action.show;
        default:
            return state;
    }
}

function clinicSubscriptions(state={}, action) {
    switch(action.type) {
        case ActionTypes.CLINIC_SUBSCRIPTIONS_LOADED:
            return {
                ...state,
                [action.clinicId]: keyBy(action.subscriptions, "clinicSubscriptionId")
            }
        // TODO: DELETE (this is for faking a subscription being added)
        case ActionTypes.CLINIC_SUBSCRIPTION_CREATED:
            return {
                ...state,
                [action.clinicId]: {
                    [9999]: {
                        clinicId: action.clinicId,
                        clinicSubscriptionId: 9999,
                        paymentStatus: "paid",
                        providerId: action.providerId,
                        stripeCustomerId: null,
                        stripeSubscriptionId: null
                    }
                }
            }
        default:
            return state;
    }
}

function greenlineProducts(state={}, action) {
    switch(action.type) {
        case ActionTypes.GREENLINE_PRODUCTS_LOADED:
            return {
                ...state,
                ...keyBy(action.products, "productId"),
            }
        default:
            return state;
    }
}

function priceOptions(state={}, action) {
    switch(action.type) {
        case ActionTypes.PRICE_OPTIONS_LOADED:
            return {
                ...state,
                [action.productId]: keyBy(action.prices, "priceId")
            }
        default:
            return state;
    }
}

function keywords(state={}, action) {
    switch(action.type) {
        case ActionTypes.KEYWORDS_LOADED:
            return {
                ...state,
                [action.keywords.clinicId]: keyBy(action.keywords.clinicKeywords, "clinicKeywordId")
            }
        default:
            return state;
    }
}

function comparablePrices(state={}, action) {
    switch(action.type) {
        case ActionTypes.CLINIC_PRICE_COMPARISONS_LOADED:
            return mapValues(action.brands, brand => {
                return {
                    ...brand,
                    quantities: uniq(flatMap(brand.products, p => flatMap(p.prices, "quantity"))),
                    products: mapValues(brand.products, product => {
                        return{
                            ...product,
                            productFamilyId: brand.productFamilyId
                        }
                    })
                }
            });
        default:
            return state;
    }
}

function favoriteBrands(state=[], action) {
    switch(action.type) {
        case ActionTypes.BRAND_FAVORITES_LOADED:
            return action.favorites
        case ActionTypes.BRAND_FAVORITE_ADDED:
            const favs = [...state]
            favs.push(action.favorite);
            return favs;
        case ActionTypes.BRAND_FAVORITE_REMOVED:
            return reject(state, f => f === action.favorite);
        default:
            return state;
    }
}

function cameFromUserManagement(state=false, action) {
    switch(action.type) {
        case ActionTypes.CAME_FROM_USER_MANAGEMENT:
            return action.fromUserManagement
        default:
            return state;
    }
}

function lastClinicsSearchParams(state={search: null}, action) {
    switch(action.type) {
        case ActionTypes.CLINICS_LOADED:
            return {
                search: null,
                ...action.params,
                recordCount: action.recordCount,
                totalRecordCount: action.totalRecordCount,
            }
        default:
            return state;
    }
}

function lastUsersSearchParams(state={search: null}, action) {
    switch(action.type) {
        case ActionTypes.USERS_LOADED:
            return {
                search: null,
                ...action.params,
                recordCount: action.recordCount,
                totalRecordCount: action.totalRecordCount,
            }
        default:
            return state;
    }
}

function clinicsGoGreenRequirements(state={}, action) {
    switch(action.type) {
        case ActionTypes.CLINIC_GO_GREEN_REQUIREMENTS_LOADED:
            return {
                ...state,
                [action.requirements.clinicId]: {
                    ...state[action.requirements.clinicId],
                    [action.requirements.providerId]: action.requirements,
                },
            };
        default:
            return state;

    }
}

function clinicSupportFiles(state=[], action) {
    switch(action.type) {
        case ActionTypes.CLINIC_SUPPORT_FILES_LOADED:
            return keyBy(action.supportFiles, "id");
        default:
            return state;
    }
}

function clinicProviderLocations(state=[], action) {
    switch(action.type) {
        case ActionTypes.CLINIC_PROVIDER_LOCATIONS_LOADED:
            return {
                ...state,
                [action.clinicId]: keyBy(action.data, "providerId")
            }
        case ActionTypes.CLINIC_PROVIDER_LOCATION_UPDATED:
            const clinicId = action.clinicId;
            const providerId = action.providerId;

            return {
                ...state,
                [clinicId]: {
                    ...state[clinicId].providerLocations,
                    [providerId]: action.data
                }
            }
        case ActionTypes.CORPORATE_GROUP_LOCATION_ID_UPDATE:
            return {
                ...state,
                [action.data.clinicId]: {
                    ...state[action.data.clinicId],
                    [action.data.providerId]: {
                        ...state[action.data.clinicId][action.data.providerId],
                        locationId: action.data.corporateLocationIdentifier
                    }
                }
            }
        default:
            return state;
    }
}

function clinicWellnessProviders(state=[], action) {
    switch(action.type) {
        case ActionTypes.CLINIC_PROVIDER_LOCATIONS_LOADED:
            const filteredData = action.data.filter(provider => provider.providerId === GREENLINE_WELLNESS || provider.id === GREENLINE_WELLNESS || provider.providerId === PREMIER_PET_CARE_PLAN || provider.id === PREMIER_PET_CARE_PLAN);
            return {
                ...state,
                [action.clinicId]: keyBy(filteredData, "id")// "providerId")
            }
        default:
            return state;
}
}

function allNotifications(state=[], action) {
    switch(action.type) {
        case ActionTypes.ALL_NOTIFICATIONS_LOADED:
            return keyBy(action.notifications, "partnerProgramIdentifier");
        default:
            return state;
    }
}

function dismissedAlerts(state=[], action) {
    switch(action.type) {
        case ActionTypes.STORE_DISMISSED_ALERTS:
            return [...state, action.notification];
        default:
            return state;
    }
}

function wellnessDashboardTiles(state={}, action) {
    switch(action.type) {
        case ActionTypes.CLINIC_WELLNESS_DASHBOARD_TILES_LOADED:
            return {
                ...state,
                [action.clinicId]: action.data.tiles,
            };
        default:
            return state;
    }
}

function clinicPrograms(state={}, action) {
    switch(action.type) {
        case ActionTypes.CLINIC_PROGRAMS_LOADED:
            return {
                ...state,
                [action.clinicId]: keyBy(action.programs, "programId")
            };
        case ActionTypes.UPDATE_CLINIC_PROGRAM:
            let newStatus = action.program.status;
            // This makes the status match what we expect to receive from the API.
            if (newStatus === ENROLL) {
                newStatus = ENROLLED;
            } else if (newStatus === CANCEL) {
                newStatus = CANCELED;
            } else if (newStatus === DECLINE) {
                newStatus = DECLINED;
            }

            return produce(state, (newState) => {
                // Handle if the data doesn't already exist
                newState[action.program.clinicId] = newState[action.program.clinicId] || {};
                newState[action.program.clinicId][action.program.programId] = newState[action.program.clinicId][action.program.programId] || {};

                // Update the data
                newState[action.program.clinicId][action.program.programId].status = newStatus;
                newState[action.program.clinicId][action.program.programId].acceptedTerms = action.program.acceptedTerms;
                newState[action.program.clinicId][action.program.programId].signature = action.program.signature;
                newState[action.program.clinicId][action.program.programId].userActionDate = new Date();
                return newState;
            });
        default:
            return state;
    }
}

function clinicProgramDetails(state={}, action) {
    switch(action.type) {
        case ActionTypes.CLINIC_PROGRAM_DETAILS_LOADED:
            return {
                ...state,
                [action.program.clinicId]: {
                    ...state?.[action.program.clinicId],
                    [action.program.programId]: action.program,
                }
            };
        default:
            return state;
    }
}

function clinicProgramServices(state={}, action) {
    switch(action.type) {
        case ActionTypes.CLINIC_PROGRAM_SERVICES_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    [action.programId]: keyBy(action.programs, "programServiceId"),
                },
            };
        case ActionTypes.UPDATE_CLINIC_PROGRAM_SERVICE:
            let newStatus = action.programService.status;
            // This makes the status match what we expect to receive from the API.
            if (newStatus === ENROLL) {
                newStatus = ENROLLED;
            } else if (newStatus === CANCEL) {
                newStatus = CANCELED;
            } else if (newStatus === DECLINE) {
                newStatus = DECLINED;
            }

            return produce(state, (newState) => {
                // Covers cases where state is missing
                newState[action.programService.clinicId] = newState[action.programService.clinicId] || {};
                newState[action.programService.clinicId][action.programService.programId] = newState[action.programService.clinicId][action.programService.programId] || {};
                newState[action.programService.clinicId][action.programService.programId][action.programService.programServiceId] = newState[action.programService.clinicId][action.programService.programId][action.programService.programServiceId] || {};

                // Update the data
                newState[action.programService.clinicId][action.programService.programId][action.programService.programServiceId].status = newStatus;
                newState[action.programService.clinicId][action.programService.programId][action.programService.programServiceId].acceptedTerms = action.programService.acceptedTerms;
                newState[action.programService.clinicId][action.programService.programId][action.programService.programServiceId].signature = action.programService.signature;
                newState[action.programService.clinicId][action.programService.programId][action.programService.programServiceId].userActionDate = new Date();
                return newState;
            });
        default:
            return state;
    }
}

function clinicProgramServiceDetails(state={}, action) {
    switch(action.type) {
        case ActionTypes.CLINIC_PROGRAM_SERVICE_DETAILS_LOADED:
            return {
                ...state,
                [action.clinicId]: {
                    ...state?.[action.clinicId],
                    [action.programId]: {
                        ...state?.[action.clinicId]?.[action.programId],
                        [action.programService.programServiceId]: action.programService,
                    }
                },
            };
        default:
            return state;
    }
}

function  lookerReports(state={}, action) {
    switch(action.type) {
        case ActionTypes.LOOKER_REPORTS_LOADED:
            return {
                ...state,
                [action.clinicId]: keyBy(get(action.reports, "dashboards", []), "dashboardId"),
            };
        default:
            return state;
    }
}

export default combineReducers({
    accountHolds,
    adminTools,
    allClinics,
    allNotifications,
    brands,
    cameFromUserManagement,
    clinicDetails,
    clinicPims,
    clinicPrograms,
    clinicProgramDetails,
    clinicProgramServices,
    clinicProgramServiceDetails,
    clinicProviderLocations,
    clinicReports,
    clinicSubscriptions,
    clinicSupportFiles,
    clinicUsers,
    clinicVetCheckStatus,
    clinicViews,
    clinicWellnessPlans,
    clinicWellnessPlanInstances,
    clinicWellnessProviders,
    clinics,
    clinicsGoGreenRequirements,
    comparablePrices,
    couponApprovalSummary,
    coupons,
    couponOfferHistory,
    cumulativeOffers,
    dashboard,
    dismissedAlerts,
    dvms,
    favoriteBrands,
    foodMappings,
    foodProductTypes,
    foodProducts,
    forceSearch,
    gameInvites,
    gameProductBrands,
    games,
    gameSnapshotComparison,
    genericSearch,
    greenlineProducts,
    handouts,
    hospitals,
    invoices,
    keywords,
    lastClinicsSearchParams,
    lastUsersSearchParams,
    lookerReports,
    mappedClinics,
    nodes,
    nodeChildren,
    nodeClinics,
    nodeClinicColumns,
    notifications,
    offers,
    mappingCounts,
    petMarketingInfo,
    petOwners,
    pharmacyMappings,
    pharmacyProductTypes,
    pharmacyProducts,
    pims,
    pimsUsers,
    plans,
    preAuditReview,
    priceHistories,
    priceOptions,
    pricingSchedules,
    productAnalysis,
    productOverrides,
    productTags,
    products,
    programOffers,
    programs,
    providerClinicAggregates,
    providerGames,
    providerGamesClinics,
    providerHierarchies,
    providerPlans,
    providerReports,
    providers,
    rebateTiles,
    recentlyImpersonatedUsers,
    recentlyViewedClinics,
    redemptions,
    registration,
    reminders,
    reprocessingJobs,
    reps,
    roles,
    searchClinics,
    searchValues,
    serviceMappings,
    services,
    showChangeVetCheckPassword,
    snapshots,
    softwareVendors,
    stats,
    strayCouponStatuses,
    strayCoupons,
    tags,
    taxRates,
    tokens,
    unprocessedCoupons,
    userManagement,
    users,
    vaccineMappings,
    vaccineTypes,
    vaccines,
    vetCheckAutomations,
    vetCheckContent,
    vetCheckMappings,
    vetCheckProducts,
    vouchers,
    wellness,
    wellnessCommissions,
    wellnessDashboardTiles,
    wellnessMappings,
    wellnessProducts
});