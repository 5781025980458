exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PuppyPodiumRight__cls1{fill:#90999e}.PuppyPodiumRight__cls1,.PuppyPodiumRight__cls2,.PuppyPodiumRight__cls3,.PuppyPodiumRight__cls4,.PuppyPodiumRight__cls5,.PuppyPodiumRight__cls6,.PuppyPodiumRight__cls7,.PuppyPodiumRight__cls8,.PuppyPodiumRight__cls9,.PuppyPodiumRight__cls10,.PuppyPodiumRight__cls11{stroke-width:0}.PuppyPodiumRight__cls2{fill:#ffc200}.PuppyPodiumRight__cls3{fill:#0b0808}.PuppyPodiumRight__cls4{fill:#b76c00}.PuppyPodiumRight__cls5{fill:#1b75bb}.PuppyPodiumRight__cls6{fill:#9f0a2b}.PuppyPodiumRight__cls7{fill:#fff}.PuppyPodiumRight__cls8{fill:#cbc6c5}.PuppyPodiumRight__cls9{fill:#231f20}.PuppyPodiumRight__cls10{fill:#ba1a55}.PuppyPodiumRight__cls12{fill:#bfced6;stroke:#90999e;stroke-miterlimit:10}.PuppyPodiumRight__cls11{fill:#04456f}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/GoldenSummerGames/PuppyPodiumRight.scss"],"names":[],"mappings":"AAAA,wBAAM,YAAY,CAAC,0QAAoE,cAAc,CAAC,wBAAM,YAAY,CAAC,wBAAM,YAAY,CAAC,wBAAM,YAAY,CAAC,wBAAM,YAAY,CAAC,wBAAM,YAAY,CAAC,wBAAM,SAAS,CAAC,wBAAM,YAAY,CAAC,wBAAM,YAAY,CAAC,yBAAO,YAAY,CAAC,yBAAO,aAAa,eAAe,oBAAoB,CAAC,yBAAO,YAAY,CAAC","file":"PuppyPodiumRight.scss","sourcesContent":[".cls1{fill:#90999e}.cls1,.cls2,.cls3,.cls4,.cls5,.cls6,.cls7,.cls8,.cls9,.cls10,.cls11{stroke-width:0}.cls2{fill:#ffc200}.cls3{fill:#0b0808}.cls4{fill:#b76c00}.cls5{fill:#1b75bb}.cls6{fill:#9f0a2b}.cls7{fill:#fff}.cls8{fill:#cbc6c5}.cls9{fill:#231f20}.cls10{fill:#ba1a55}.cls12{fill:#bfced6;stroke:#90999e;stroke-miterlimit:10}.cls11{fill:#04456f}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cls1": "PuppyPodiumRight__cls1",
	"cls2": "PuppyPodiumRight__cls2",
	"cls3": "PuppyPodiumRight__cls3",
	"cls4": "PuppyPodiumRight__cls4",
	"cls5": "PuppyPodiumRight__cls5",
	"cls6": "PuppyPodiumRight__cls6",
	"cls7": "PuppyPodiumRight__cls7",
	"cls8": "PuppyPodiumRight__cls8",
	"cls9": "PuppyPodiumRight__cls9",
	"cls10": "PuppyPodiumRight__cls10",
	"cls11": "PuppyPodiumRight__cls11",
	"cls12": "PuppyPodiumRight__cls12"
};