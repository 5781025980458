import { find, includes } from "lodash";
import * as UserPermissions from "constants/UserPermissions";
import * as LocationTypes from "constants/LocationTypes";

export const ACCEPT = "Accept";
export const ADMIN = "Admin";
export const APPROVE = "Approve";
export const ASSIGN_HOSPITAL = "AssignHospital";
export const CANCEL = "Cancel";
export const CHANGE_DVM = "ChangeDvm";
export const CHANGE_PASSWORD = "ChangePassword";
export const CREATE = "Create";
export const DECLINE = "Decline";
export const DELETE = "Delete";
export const DOWNLOADS = "Downloads";
export const EDIT = "Edit";
export const HIDE = "Hide";
export const HISTORY = "History";
export const IMPERSONATE = "Impersonate";
export const OPT_IN = "OptIn";
export const RELEASE = "Release";
export const RESOLVE = "Resolve";
export const VIEW = "View";
export const VOID = "Void";
export const EXPORT = "Export";

export const PermissionTypes = {
    ACCEPT,
    ADMIN,
    APPROVE,
    ASSIGN_HOSPITAL,
    CANCEL,
    CHANGE_DVM,
    CHANGE_PASSWORD,
    CREATE,
    DECLINE,
    DELETE,
    DOWNLOADS,
    EDIT,
    HIDE,
    HISTORY,
    IMPERSONATE,
    OPT_IN,
    RELEASE,
    RESOLVE,
    VIEW,
    VOID,
    EXPORT
}

/// A helper to make pulling permission info from a user profile easier and safe, then returns result from canPermission()
export function userHasPermission(permissionType, userPermission, userProfile) {
    if (!userProfile) {
        return false;
    }

    const permissions = userProfile.permissions[userPermission];
    const access = permissions ? permissions.access : null;

    return canPermission(permissionType, access);
}

export function canPermission(permissionType, access) {
    if (!access) {
        return false;
    }

    return access.includes(permissionType);
}

export function userCanViewMappingPage(userProfile) {
    if (!userProfile) {
        return false;
    }

    const canViewExamServiceMappings = userHasPermission(PermissionTypes.VIEW, UserPermissions.EXAM_SERVICE_MAPPING, userProfile);
    const canViewProductMappings = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);
    const canViewVaccineMappings = userHasPermission(PermissionTypes.VIEW, UserPermissions.VACCINE_MAPPING, userProfile);
    const canViewVetCheckMappings = userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);
    const canViewWellnessMappings = userHasPermission(PermissionTypes.VIEW, UserPermissions.WELLNESS_MAPPING, userProfile);
    return (canViewExamServiceMappings || canViewProductMappings || canViewVaccineMappings || canViewVetCheckMappings || canViewWellnessMappings);

}

export function userHasRole(userProfile, rolesArr) {
    return !!find(rolesArr, (r) => userProfile.roles.includes(r));
}

export function userPrimaryLocationType(userProfile, locationTypesArr) {
    return includes(locationTypesArr, userProfile.primaryLocation.type);
}

export function userPrimaryLocationId(userProfile) {
    const primaryLocationId = userProfile?.primaryLocation?.id || null;
    return !!primaryLocationId ? Number(userProfile?.primaryLocation?.id) : null;
}

export function userCanAccessClinic(userProfile, rolesArr, clinicId) {
    const hasRole = userHasRole(userProfile, rolesArr);
    const isLocation = !!userProfile.clinics && !!userProfile.clinics.length && !!find(userProfile.clinics, cl => cl.id === clinicId);

    return hasRole || isLocation;
}

export function userHasWellnessAccess(userProfile) {
    const { permissions } = userProfile;
    return !!permissions[UserPermissions.CLINIC_WELLNESS_AUDIT] &&
        !!permissions[UserPermissions.CLINIC_WELLNESS_PET_PLANS] &&
        !!permissions[UserPermissions.CLINIC_WELLNESS_PLANS];
}

export function userHasWellnessDvmsAccess(userProfile) {
    const { permissions } = userProfile;
    return !!permissions[UserPermissions.CLINIC_WELLNESS_DVMS]
}

export function userHasCouponAccess(userProfile) {
    const { permissions } = userProfile;
    return !!permissions[UserPermissions.CLINIC_WELLNESS_AUDIT] &&
        !!permissions[UserPermissions.CLINIC_WELLNESS_DVMS] &&
        !!permissions[UserPermissions.CLINIC_WELLNESS_PET_PLANS] &&
        !!permissions[UserPermissions.CLINIC_WELLNESS_PLANS];
}

/// Wellness paths don't currently account for specific permissions (e.g., PermissionTypes.VIEW).
export function canUserAccessPage(userProfile, pagePath) {
    if (!userProfile) {
        return false;
    }

    if (!pagePath || pagePath === "/") {
        return true; // Always access your dashboard
    }

    switch (pagePath) {
        case "/notifications":
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.NOTIFICATIONS, userProfile);

        case "/coupons":
        case "/coupons/history":
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        case "/coupons/stray":
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.INSTANT_REBATE_INVOICE_FAILURES, userProfile);
        case "/coupons/library":
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);

        case "/wellness":
        case "/wellness/audit":
        case "/wellness/history":
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        case "/wellness/patients":
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        case "/wellness/commission/dvm":
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        case "/wellness/plans":
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);

        case "/games":
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        case "/vetcheck":
        case "/vetcheck/send-history":
        case "/vetcheck/handouts-sent":
        case "/vetcheck/forms-sent":
        case "/vetcheck/charts-sent":
        case "/vetcheck/opt-out":
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_VETCHECK, userProfile);
        case "/vetcheck/manage-automations":
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, userProfile);

        case "/clinic/users":
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile) ||
                userHasPermission(PermissionTypes.EDIT, UserPermissions.USER_MANAGEMENT, userProfile) ||
                userHasPermission(PermissionTypes.CREATE, UserPermissions.USER_MANAGEMENT, userProfile);

        case "/setup/payments":
            // To access the payment page, the user must have the correct permissions the primary location must be a clinic
            return userHasPermission(PermissionTypes.VIEW, UserPermissions.PAYMENT_SETTINGS, userProfile) &&
                userPrimaryLocationType(userProfile, [LocationTypes.CLINIC]);
    }

    return true;
}

export function getBasicUserPermissions(userProfile) {
    const hasClinicInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_INFO, userProfile);
    const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
    const hasClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);

    return {
        canWellness: userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile),
        canCoupons: userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile),
        canGames: userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile),
        canClinic: hasClinicInfo || canViewCorporateGroupClinic|| hasClinicManagement,
    }
}

//=--------------------------------------------------------------------------------------------------------------------
// Dev helpers
//=--------------------------------------------------------------------------------------------------------------------

export function stripUserManagementPermissions(userProfile) {
    const noUserManagement = { ...userProfile };
    const permissions = { ...userProfile.permissions };
    noUserManagement.permissions = permissions;

    // delete permissions[UserPermissions.USER_MANAGEMENT]; // Everything
    permissions[UserPermissions.USER_MANAGEMENT].access = [PermissionTypes.VIEW, PermissionTypes.EDIT, PermissionTypes.CREATE]; // Retain only specific perms

    return noUserManagement;
}

/// Returns a shallow copy of userProfile with "wellness" permissions removed (for testing).
export function stripWellnessPermissions(userProfile) {
    const noWellness = { ...userProfile };
    const permissions = { ...userProfile.permissions };
    noWellness.permissions = permissions; // Spread op is a shallow copy, and we don't want to change the source userProfile

    delete permissions[UserPermissions.CLINIC_WELLNESS_AUDIT];
    delete permissions[UserPermissions.CLINIC_WELLNESS_DVMS];
    delete permissions[UserPermissions.CLINIC_WELLNESS_PET_PLANS];
    delete permissions[UserPermissions.CLINIC_WELLNESS_PLANS];

    return noWellness;
}
