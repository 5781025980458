import React, {useMemo, useState, useEffect} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "./MappingTable.scss";
import * as MappingActions from "actions/MappingActions";
import {
    filter,
    flatMap,
    includes,
    keys,
    map,
    orderBy,
    union
} from "lodash";
import VaccineTableSection from "components/admin/widgets/VaccineTableSection";
import Dropdown from "components/common/Dropdown";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {SPECIES} from "constants/MappingTypes";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";

function VaccineTable(props) {
    if(!props.canViewVaccineMapping) {
        return <AccessDenied/>;
    }

    const [filters, setFilters] = useState({});

    useEffect(() => {
        if(props.canViewVaccineMapping) {
            if (!Object.keys(props.vaccines).length) {
                props.loadVaccines();
            }
            props.loadVaccineMappings(props.clinicId);
        }
    }, [props.clinicId]);

    const PROVIDER_OPTIONS = useMemo(() => {
        return map(props.providers, (provider) => {
            return {name: provider.name, value: provider.id};
        });
    }, [props.providers]);

    const PIMS_OPTIONS = useMemo(() => {
        return map(props.pims, (pim) => {
            return {name: `(${pim.remoteClinicProductDisplayID}) ${pim.name}`, value: pim.clinicProductId};
        })
    }, [props.pims]);

    const orderedPimsOptions = useMemo(() => {
        return orderBy(PIMS_OPTIONS, po => {return includes(props.disabledPimsOptions, po.value)});
    }, [PIMS_OPTIONS, props.disabledPimsOptions]);

    const filteredVaccines = useMemo(() => {
        if((filters.species || filters.vaccineTypes || filters.providerId || filters.vaccineTypes || props.search)) {
            return filter(props.vaccines, v => {
                if (!!filters.species && (v.speciesName !== filters.species)) {
                    return false;
                }
                if (!!filters.providerId && v.providerId !== filters.providerId) {
                    return false;
                }
                if (!!filters.vaccineTypes && !includes(v.tags, filters.vaccineTypes)) {
                    return false;
                }
                if (!!props.search) {
                    const searchTokens = props.search.toLowerCase().split(" ");
                    let keep = true
                    for (let i = 0; i < searchTokens.length; i++) {
                        const token = searchTokens[i];
                        if (!v.name || !v.name.toString().toLowerCase().includes(token)) {
                            keep = false;
                        }
                    }
                    return keep;
                }
                return true;
            });
        } else return props.vaccines;
    }, [props.vaccines, props.search, filters]);

    if (props.working || !filteredVaccines) {
        return <SpinnerTakeover show/>;
    }

    const sections = map(orderBy(filteredVaccines, ["name"]), (vaccine) => {
        return (
            <VaccineTableSection
                key={vaccine.vaccineId}
                typeId={props.typeId}
                clinicId={props.clinicId}
                vaccineId={vaccine.vaccineId}
                pimsOptions={orderedPimsOptions}
                disabledPimsOptions={props.disabledPimsOptions}
                boldPimsOptions={props.boldPimsOptions}
            />
        )
    });

    const VACCINE_TYPES = map(orderBy(props.vaccineTypes), vt => {
        return { name: vt, value: vt }
    })


    const handleChangeFilters = ({name, value}) => {
        setFilters({
            ...filters,
            [name]: value,
        })
    }

    return (
        <div className="flex flex-column height-100-perc">
            <div className={classnames(styles.mappingHeader, "flex spaced-content align-center flex-none")}>
                <div className="flex flex-wrap spaced-content align-center">
                    <h2 className="nowrap">Vaccine Mappings</h2>
                    <h3 className="nowrap">({Object.keys(props.vaccines).length} products / {props.totalMappings} mappings)</h3>
                </div>
            </div>
            <hr className="flex-none full-width"/>
            <div className={classnames(styles.dropdowns, "flex flex-none spaced-content align-bottom margin-bottom-sm margin-top-sm")}>
                <div className="flex-1">
                    <Dropdown
                        options={PROVIDER_OPTIONS}
                        label="Manufacturer"
                        value={filters.providerId}
                        name="providerId"
                        onChange={handleChangeFilters}
                        placeholder="--Select Manufacturer--"
                        fullWidth
                    />
                </div>
                <div className="flex-1">
                    <Dropdown
                        options={SPECIES}
                        label="Species"
                        value={filters.species}
                        name="species"
                        onChange={handleChangeFilters}
                        placeholder="--Select Species--"
                        fullWidth
                    />
                </div>
                <div className="flex-1">
                    <Dropdown
                        options={VACCINE_TYPES}
                        label="Type"
                        value={filters.vaccineTypes}
                        name="vaccineTypes"
                        onChange={handleChangeFilters}
                        placeholder="--Select Type--"
                        fullWidth
                    />
                </div>
            </div>
            <div className="flex-1">
                <div className={classnames(styles.overflow, "height-100-perc overflow-x-scroll full-width")}>
                    <table className="table">
                        <thead>
                            <tr className="spaced-content">
                                <th/>
                                <th>Vaccine</th>
                                <th>Type</th>
                                <th>Compliance Duration (months)</th>
                                <th/>
                                <th>Clinic Product</th>
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {sections}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

VaccineTable.propTypes = {
    clinicId: PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const totalMappings = state.entities.mappingCounts?.[ownProps.clinicId]?.totalVaccineMappings || 0;
        const vaccineMappings = state.entities.vaccineMappings?.[ownProps.clinicId] || {};
        const disabledPimsOptions = union(...map(vaccineMappings, product => map(product.mappings, "clinicProductId"))) || [];
        const pims = state.entities.pims?.products || {}
        const boldPimsOptions = flatMap(filter(pims, "productUsedRowCount"), "clinicProductId");

        //Permissions
        const canViewVaccineMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.VACCINE_MAPPING, userProfile);

        return {
            totalMappings,
            disabledPimsOptions,
            pims,
            boldPimsOptions,
            providers: orderBy(filter(state.entities.providers, "visibleInVaccineMapping"), ["displayOrder"]),
            vaccines: state.entities.vaccines,
            vaccineTypes: state.entities.vaccineTypes,
            search: state.entities.genericSearch,
            working: !!state.applicationState.working,
        //    Permissions
            canViewVaccineMapping,
        }
    },
    (dispatch) => ({
        loadVaccines: () => dispatch(MappingActions.loadVaccines()),
        loadVaccineMappings: (clinicId) => dispatch(MappingActions.loadVaccineMappings(clinicId)),
    }),
) (VaccineTable);
