import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import GreenlinePlanSetupFormContainer from "components/greenlineWellness/GreenlinePlanSetupFormContainer";
import * as ClinicActions from "actions/ClinicActions"

function AddGreenlineWellnessPlanButton(props) {
    const [showAddPlan, setShowAddPlan] = useState(false);

    if(!props.canEditClinicWellnessPlans) {
        return null;
    }

    const handleAddPlan = () => {
        setShowAddPlan(true);
    }

    const handlePlanAdded = () => {
        setShowAddPlan(false);
        props.onPlanSaved();
    }

    return (
        <>
            <div className="flex justify-flex-end">
                <Button
                    icon
                    onClick={handleAddPlan}
                    disabled={showAddPlan}
                >
                    <i className="fa fa-plus"/> Add Plan
                </Button>
            </div>
            <Modal
                show={showAddPlan}
                onClose={() => setShowAddPlan(false)}
                modalTitle="Add Plan"
                medium
            >
                <GreenlinePlanSetupFormContainer
                    clinicId={props.clinicId}
                    onAfterSave={handlePlanAdded}
                    addingPlan
                    clinicPriceScheduleId={props.clinicPriceScheduleId}
                />
            </Modal>
        </>
    );
}

AddGreenlineWellnessPlanButton.defaultProps = {
    clinicId: undefined,
    initialDate: {},
};

AddGreenlineWellnessPlanButton.propTypes = {
    clinicId: PropTypes.number,
    initialDate: PropTypes.object,
    onPlanSaved: PropTypes.func.isRequired,
    clinicPriceScheduleId: PropTypes.string.isRequired,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canEditClinicWellnessPlans = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);

        return {
            canEditClinicWellnessPlans,
        };
    }
)(AddGreenlineWellnessPlanButton);
