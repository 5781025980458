import React, { useContext, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from './ApprovalButtons.scss';
import { windowSizeContext } from "AppRoot";
import replace from "lodash/replace";
import Button from "components/common/Button";
import TextBox from "components/common/TextBox";
import * as CssStyleIds from "constants/CssStyleIds";

export default function ApprovalButtons(props) {
    const [showApprove, setShowApprove] = useState(false);
    const [showDecline, setShowDecline] = useState(false);
    const [initials, setInitials] = useState("");
    const context = useContext(windowSizeContext) || {};

    const setShowByDefault = () => {
        if (props.showApproveByDefault){
            setShowApprove(true);
            setShowDecline(false);
        } else if (props.showDeclineByDefault) {
            setShowApprove(false);
            setShowDecline(true);
        } else {
            setShowApprove(false);
            setShowDecline(false);
        }
    }

    useEffect(() => {
        setShowByDefault();
    }, [props.showApproveByDefault, props.showDeclineByDefault]);

    useEffect(() => {
        if ((showApprove || props.showApproveByDefault) && !initials?.length) {
            setInitials("GLN");
        } else if (showDecline && !initials?.length) {
            setInitials("GLN");
        }
    }, [props.showApproveByDefault, props.useFakeInitials]);

    const handleApproveClick = () => {
        setShowApprove(true);
        setShowDecline(false);
    };

    const handleDeclineClick = () => {
        setShowDecline(true);
        setShowApprove(false);
    };

    const handleClear = () => {
        setShowDecline(false);
        setShowApprove(false);
        setInitials("");
    };

    const handleSubmitClick = () => {
        if (showApprove) {
            if (props.onApprove) {
                props.onApprove(initials);
            }
        } else if (showDecline) {
            if (props.onDecline) {
                props.onDecline(initials);
            }
        }
        handleClear();
    };

    const removeSpecialCharacters = (value) => {
        // Remove special characters (only letters allowed)
        return replace(value, /[^A-Z]/ig, "");
    };

    const updateInitials = ({value}) => {
        setInitials(removeSpecialCharacters(value));
    };

    return (
        <div
            data-testid="approval_buttons_root"
            className={classnames(styles.root, {
                [styles.approving]: showApprove,
                [styles.declining]: showDecline,
                [styles.locked]: props.locked,
            })}
        >
            <div className={styles.buttonHolder}>
                <Button
                    data-testid="approval_buttons_approve_button"
                    type={CssStyleIds.SUCCESS}
                    wide={!context.isPhone}
                    className={styles.approveBtn}
                    onClick={handleApproveClick}
                    disabled={props.locked || !props.onApprove}
                >
                    Approve
                </Button>
                <Button
                    className={styles.declineBtn}
                    data-testid="approval_buttons_decline_button"
                    style={props.cannotDecline ? {backgroundColor: "rgba(0,0,0,0)", color: "rgba(0,0,0,0)", cursor: "default"} : {}}
                    type={CssStyleIds.NOTIFY}
                    wide={!context.isPhone}
                    onClick={handleDeclineClick}
                    disabled={props.locked || !props.onDecline}
                >
                    Decline
                </Button>
                <div className={styles.form}>
                    <div className="flex">
                        <i className={classnames(styles.check, "fas fa-check-circle flex-none", {
                            [styles.valid]: initials
                        })} />
                        <span className={classnames(styles.label, "flex-none")}>Initial to certify</span>
                    </div>
                    <div className={styles.initials}>
                        <TextBox
                            data-testid="approval_buttons_initials_input"
                            name="initials"
                            value={initials}
                            onChange={updateInitials}
                            placeholder={context.isPhone ? "Initial to certify" : ""}
                        />
                    </div>
                    <div className={classnames("flex-none", styles.button, {
                        [styles.valid]: initials
                    })}>
                        <Button
                            data-testid="approval_buttons_submit_button"
                            type={showDecline ? CssStyleIds.DANGER : CssStyleIds.SUCCESS}
                            onClick={handleSubmitClick}
                            disabled={!initials}
                            iconOnly={context.isPhone}
                        >
                            {context.isPhone ? (
                                <i className="fa fa-save"/>
                            ) : (
                                <span>Submit</span>
                            )}
                        </Button>
                    </div>
                </div>
            </div>
            <div className={styles.cancelHolder}>
                {(showDecline || showApprove) && (
                    <Button
                        data-testid="show_approve_or_decline_cancel_button"
                        type={CssStyleIds.GRAY}
                        large={!context.isPhone}
                        iconOnly={context.isPhone}
                        text={context.isPhone}
                        onClick={handleClear}
                    >
                        {context.isPhone ? (
                            <i className="fa fa-2x fa-times-circle"/>
                        ) : (
                            <span>Cancel</span>
                        )}
                    </Button>
                )}
            </div>
            {props.onClose && (
                <div className={styles.closeButton}>
                    <Button
                        data-testid="approval_buttons_close_button"
                        type={CssStyleIds.GRAY}
                        large
                        onClick={props.onClose}
                    >
                        Close
                    </Button>
                </div>
            )}
        </div>
    );
}

ApprovalButtons.propTypes = {
    locked: PropTypes.bool,
    onApprove: PropTypes.func,
    onDecline: PropTypes.func,
    onClose: PropTypes.func,
    cannotDecline: PropTypes.bool,
    showApproveByDefault: PropTypes.bool,
    showDeclineByDefault: PropTypes.bool,
    useFakeInitials: PropTypes.bool,
};
