import OnGardGameStampTier from "components/games/widgets/OnGardGameStampTier";
import { ON_GARD_TIERS } from "constants/OnGardGameTierData";
import { map } from "lodash";
import React from "react";
import PropTypes from "prop-types";
import styles from "./OnGardGameStamps.scss";

export default function OnGardGameStamps({ game }) {
    if (!game) {
        return <div>No data available</div>;
    }

    const currentPoints = game.details.TotalPoints;
    const tiers = ON_GARD_TIERS
    const pointIncrement = 5;

    const renderTiers = () => {
        return map(tiers, (tier, index) => {
            const startPoints = index > 0 ? tiers[index - 1].points : 0;
            const totalPoints = index > 0 ? tier.points - startPoints : tier.points;

            return (
                <div
                    key={`tier_${index + 1}`}
                    className="flex margin-top-sm margin-bottom-sm"
                >
                    <div className={styles.level}>
                        <div><h2>Level {index + 1}</h2></div>
                        <div><h3>{tier.name} Status</h3></div>
                        <div>{tier.points} Points</div>
                    </div>
                    <div className={styles.stamps}>
                        <OnGardGameStampTier
                            firstIsFree={index === 0}
                            pointIncrement={pointIncrement}
                            startPoints={startPoints}
                            totalPoints={totalPoints}
                            currentPoints={currentPoints}
                        />
                    </div>
                </div>
            );
        })
    };

    return (
        <div className="flex flex-column full-width full-height">
            <div>
                <h2>Game Progress</h2>
            </div>
            {renderTiers()}
        </div>
    );
}

OnGardGameStamps.propTypes = {
    game: PropTypes.object,
}
