import React, { useMemo } from "react";
import PropTypes from "prop-types";
import map from "lodash/map";
import round from "lodash/round";
import DataTable from "components/common/datatable/DataTable";
import Tooltip from "components/common/Tooltip";
import { addCommasToNumber } from "utils/numeric";

export default function GenericLeaderboardTable(props) {
    const columnGroupings = useMemo(() => {
        if (props.usePoints) {
            return [
                {
                    key: "emptyHeader",
                    title: "",
                    colSpan: 2,
                    styles: {
                        backgroundColor: "#CBC6C5"
                    },
                },
                {
                    key: "previousHeader",
                    title: "Previous Period",
                    colSpan: 4,
                    styles: {
                        backgroundColor: "#0C4DA2",
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontStyle: "italic"
                    },
                },
                {
                    key: "currentHeader",
                    title: "Current Period",
                    colSpan: 6,
                    styles: {
                        backgroundColor: "#1C9DFF",
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontStyle: "italic"
                    },
                }
            ];
        }
        return null;
    }, [props.game]);

    const showRowPoints = (row) => {
        return props.showAllPoints || (row.clinicId === props.clinicId);
    }

    const STANDARD_COLUMNS = [{
        name: "Rank",
        selector: "Place",
        key: "rank",
        sortable: false,
        searchable: false,
        colSettings: { width: "25px"},
    },{
        name: `Clinic ${props.isProvider ? "Name" : "Alias"}`,
        selector: "name",
        key: "name",
        sortable: false,
        searchable: false,
    }];

    const PERCENT_GROWTH_COLUMN = [{
        name: "Growth %",
        selector: "PercentGrowth",
        key: "PercentGrowth",
        sortable: false,
        searchable: false,
        colSettings: {width: "60px"},
        format: row => {
            let fontColor = "#BB0000";

            if (row.PercentGrowth > 0) {
                fontColor = "rgb(49, 125, 61)";
            }

            if (row.clinicId === props.clinicId) {
                fontColor = "#FFFFFF";
            }

            // If there were no previous doses then this should never show a number
            if (row.previousDoses === 0) {
                return (
                    <span style={{ color: '#000', display: "inline-block", width: "100%", textAlign: "center"}}>--</span>
                );
            }

            //  If some have been sold then show the percentage compared to last year
            if (row.PercentGrowth && (row.PercentGrowth > -100)) {
                return (
                    <span style={{ color: fontColor, display: "inline-block", width: "100%", textAlign: "center"}}>{round(row.PercentGrowth + 100)}%</span>
                );
            } else {
                // Otherwise display 0%
                return (
                    <span style={{ color: fontColor, display: "inline-block", width: "100%", textAlign: "center"}}>0%</span>
                );
            }
        }
    }];

    const PREVIOUS_PERIOD_COLUMNS = [{
        name: "NG Plus Doses",
        selector: "previousNgPlusDoses",
        key: "previousNgPlusDoses",
        sortable: false,
        searchable: false,
        format: row => (
            <div className="text-center no-wrap">{showRowPoints(row) ? addCommasToNumber(row.previousNgPlusDoses) : "--"}</div>
        ),
    }, {
        name: "NG Plus Points",
        selector: "previousNgPlusPoints",
        key: "previousNgPlusPoints",
        sortable: false,
        searchable: false,
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.previousNgPlusPoints) : "--"}
            </div>
        ),
    }, {
        name: "Doses",
        selector: "previousDoses",
        key: "previousDoses",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px"},
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.previousDoses) : "--"}
            </div>
        ),
    },{
        name: (<div className="text-center">Points</div>),
        selector: "previousPoints",
        key: "PreviousPoints",
        sortable: false,
        searchable: false,
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.previousPoints) : "--"}
            </div>
        ),
    }];

    const CURRENT_PERIOD_COLUMNS = [{
        name: (<div className="text-center">NG Plus Doses</div>),
        selector: "currentNgPlusDoses",
        key: "CurrentNGPlusDoses",
        sortable: false,
        searchable: false,
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.currentNgPlusDoses) : "--"}
            </div>
        ),
    }, {
        name: (<div className="text-center">NG Plus Points</div>),
        selector: "currentNgPlusPoints",
        key: "currentNgPlusPoints",
        sortable: false,
        searchable: false,
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.currentNgPlusPoints) : "--"}
            </div>
        ),
    }, {
        name: (<div className="text-center">Total Doses</div>),
        selector: "totalDoses",
        key: "TotalDoses",
        sortable: false,
        searchable: false,
        colSettings: { width: "25px"},
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.currentDoses) : "--"}
            </div>
        ),
    }, {
        name: ( <div className="text-center">Total Points</div>),
        selector: "currentPoints",
        key: "currentPoints",
        sortable: false,
        searchable: false,
        format: row => (
            <div
                className="text-center no-wrap"
                style={(row.clinicId === props.clinicId) ? {
                    color: "#fff",
                    fontWeight: "bold",
                } : {}}
            >
                {showRowPoints(row) ? addCommasToNumber(row.currentPoints) : "--"}
            </div>
        ),
    }];

    const POINTS_TO_GO_COLUMN = [{
        name: (
            <Tooltip
                position="left"
                tip="This column shows how many points your clinic needs to earn to meet your points sold in the previous timeframe set for this game."
            >
                <span className="cursor-help">To Go <i className="fa fa-info-circle"/></span>
            </Tooltip>
        ),
        selector: "pointsToGo",
        key: "pointsToGo",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px" },
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.pointsToGo) : "--"}
            </div>
        ),
    }];

    const DOSES_ONLY_COLUMNS = [{
        name: "Current Doses",
        selector: "currentDoses",
        key: "currentDoses",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px"},
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.currentDoses) : "--"}
            </div>
        ),
    }, {
        name: "Previous Doses",
        selector: "previousDoses",
        key: "previousDoses",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px"},
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.previousDoses) : "--"}
            </div>
        ),
    }];

    const DOSES_TO_GO_COLUMN = [{
        name: (
            <Tooltip
                position="left"
                tip="This column shows how many doses your clinic needs to sell to meet your doses sold in the previous timeframe set for this game."
            >
                <span className="cursor-help">To Go <i className="fa fa-info-circle"/></span>
            </Tooltip>
        ),
        selector: "DosesToGo",
        key: "dosesToGo",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px"},
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.dosesToGo) : "--"}
            </div>
        ),
    }];

    const TO_WIN_COLUMN = [{
        name: (
            <Tooltip
                position="left"
                tip="This column shows how many doses your clinic needs to sell to be in the lead for this game!"
            >
                <span className="cursor-help">To Win <i className="fa fa-info-circle" /></span>
            </Tooltip>
        ),
        selector: "toWin",
        key: "toWin",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px" },
        format: row => (
            showRowPoints(row) ? (
                <Tooltip
                    position="left"
                    tip={(
                        <div>
                            {map(row.toWinHover, h => (
                                <div>{h}</div>
                            ))}
                        </div>
                    )}
                >
                    <div className="text-center cursor-help">
                        {row.Place === 1 ? <i className="far fa-trophy-star"/> : addCommasToNumber(row.toWin)}
                    </div>
                </Tooltip>
            ) : (
                <div className="text-center">--</div>
            )
        ),
    }];

    const TableColumns = useMemo(() => {
        if (props.usePoints) {
            return [
                ...STANDARD_COLUMNS,
                ...PREVIOUS_PERIOD_COLUMNS,
                ...CURRENT_PERIOD_COLUMNS,
                ...POINTS_TO_GO_COLUMN,
                ...PERCENT_GROWTH_COLUMN,
            ];
        }
        return [
            ...STANDARD_COLUMNS,
            ...DOSES_ONLY_COLUMNS,
            ...DOSES_TO_GO_COLUMN,
            ...PERCENT_GROWTH_COLUMN,
            ...TO_WIN_COLUMN,
        ];
    }, [props.usePoints]);

    const getConditionalRowProps = (row) => {
        if (row.clinicId === props.clinicId) {
            return {
                style: {
                    backgroundColor: 'rgb(26, 85, 173)',
                    color: '#F9F9FC',
                }
            };
        }
        return {};
    };

    return (
        <DataTable
            columns={TableColumns}
            columnGroupings={columnGroupings}
            data={props.clinics}
            pagination={false}
            green
            useColgroup
            conditionalRowProps={getConditionalRowProps}
        />
    );
}

GenericLeaderboardTable.defaultProps = {
    clinics: [],
    isProvider: false,
    showAllPoints: false,
    snapshot: false,
    usePoints: false,
}

GenericLeaderboardTable.propTypes = {
    clinicId: PropTypes.number.isRequired,
    clinics: PropTypes.arrayOf(PropTypes.object),
    game: PropTypes.object.isRequired,
    isProvider: PropTypes.bool,
    showAllPoints: PropTypes.bool,
    snapshot: PropTypes.bool,
    usePoints: PropTypes.bool,
}
