import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {map, orderBy, cloneDeep, merge, reject, flatMap, uniq, uniqBy} from "lodash";
import TextBox from "components/common/TextBox";
import CheckboxInput from "components/common/CheckboxInput";
import Button from "components/common/Button";
import * as styles from "./PlanSetupForm.scss";
import classnames from "classnames";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import PlanEnrollmentMapping from "components/admin/elements/PlanEnrollmentMapping";
import PlanServices from "components/admin/widgets/PlanServices";
import ClinicPlanServices from "components/admin/widgets/ClinicPlanServices";

function PlanSetupForm(props) {
    const [formData, setFormData] = useState({})
    const lastMapping = props.clinicId ? Object.keys(props.plan?.enrollmentMappings).length ? orderBy(props.plan.enrollmentMappings, ["id"], ["desc"])[0] : {} : {};
    const nextId = lastMapping ? lastMapping.id + 1 : 0;

    const combinedData = cloneDeep(props.plan);
    merge(combinedData, formData);

    useEffect(() => {
        setFormData({});
    }, [props.plan, props.clinicId]);

    const AddRow = () => {
        const tempData = {
            ...props.plan.enrollmentMappings,
            [nextId] : {
                id: nextId,
                isNew: true,
                clinicProductId: null
            }
        }
        handleChange({ name: "enrollmentMappings", value: tempData });
    }

    const handleChange = ({name, value, service=null, serviceType=null, enrollmentMapping=null}) => {
        let newData;
        if (!!service) {
            newData = {
                ...props.plan,
                services: {
                    ...props.plan.services,
                    [service.clinicPlanServiceTypeId]: {
                        ...service
                    }
                }
            }
        } else if (!!serviceType) {
            newData = {
                ...props.plan,
                serviceTypes: {
                    ...props.plan.serviceTypes,
                    [serviceType.planServiceTypeId]: {
                        ...serviceType
                    }
                }
            }
        } else if (!!enrollmentMapping) {
            newData = {
                ...props.plan,
                enrollmentMappings: {
                    ...props.plan.enrollmentMappings,
                    [enrollmentMapping.id]: {
                        ...enrollmentMapping
                    }
                }
            }
        } else {
            newData = {
                ...props.plan,
                [name]: value
            }
        }
        props.handleChange(props.clinicId? {plan: newData} : newData);
    }

    const handleCopyToOtherPlans = () => {
        if (confirm(
            `This operation will NOT copy enrollment mappings for add-on services. Are you sure you want to copy the quantity and add-on costs from the ${props.plan.name} plan to the other plans in the ${props.plan.groupName} group?`
        )) {
            props.copy(props.plan.clinicPlanInstanceId, props.plan.clinicPriceScheduleId);
        }
    }

    const handleDeleteMapping = (enrollmentMapping) => {
        if(enrollmentMapping.isNew) {
            const newEnrollmentMappings = props.plan.enrollmentMappings;
            delete newEnrollmentMappings[enrollmentMapping.id];
            const newData = {
                ...props.plan,
                enrollmentMappings: newEnrollmentMappings
            }
            props.handleChange(props.clinicId ? {plan: newData} : newData);
        } else {
            const newEnrollmentMapping = {
                ...enrollmentMapping,
                isDeleted: true
            }
            handleChange({enrollmentMapping: newEnrollmentMapping});
        }
    }

    const displayEnrollmentMappings = () => {
        //Remove the ones that were deleted
        const enrollmentMappings = reject(props.plan.enrollmentMappings, "isDeleted");
        if (enrollmentMappings.length) {
            return (
                map(enrollmentMappings, (PEM, index) => (
                    <div className="padding-top-sm" key={index}>
                        <PlanEnrollmentMapping
                            key={PEM.id}
                            planEnrollmentMapping={PEM}
                            usedPims={props.usedPims}
                            handleChange={handleChange}
                            handleDeleteMapping={() => handleDeleteMapping(PEM)}
                            readOnly={props.readOnly}
                        />
                    </div>
                ))
            );
        } else {
            return (
                <div className="padding-top-sm">
                    <PlanEnrollmentMapping
                        planEnrollmentMapping={{}}
                        nextId={nextId}
                        usedPims={props.usedPims}
                        handleChange={handleChange}
                        readOnly={props.readOnly}
                    />
                </div>
            )
        }
    }

    return (
        <div className={classnames(styles.root, "margin-bottom-lg margin-left-lg margin-right-lg", {
            [styles.disabled]: (props.clinicId && !props.plan.isActive),
        })}>
            <div>
                {!props.clinicId && (
                    <TextBox
                        name="groupName"
                        label="Group Name"
                        value={props.plan.groupName}
                        onChange={(data) => props.readOnly ? {} : handleChange(data)}
                        disabled={props.readOnly}
                    />
                )}
                <div className="flex spaced-content align-bottom">
                    <TextBox
                        name="name"
                        label="Plan Name"
                        value={props.plan.name}
                        onChange={(data) => props.readOnly ? {} : handleChange(data)}
                        disabled={!!props.clinicId || props.readOnly}
                    />
                    {!!props.clinicId && (
                        <div className="margin-bottom-x-sm text-lg flex-none">
                            <CheckboxInput
                                // leftLabel
                                label="Is Active?"
                                name="isActive"
                                checked={props.plan.isActive}
                                onChange={handleChange}
                                disabled={props.readOnly}
                            />
                        </div>
                    )}
                </div>
                <div className="flex flex-end spaced-content">
                    <div className="flex-none">
                        <TextBox
                            name="termMonths"
                            label="Term Length"
                            value={props.plan.termMonths}
                            onChange={handleChange}
                            disabled={!!props.clinicId || props.readOnly}
                        />
                    </div>
                    <div className="margin-bottom-sm flex-1">Months</div>
                </div>
                {!props.clinicId ? (
                    <>
                        <TextBox
                            name="templateName"
                            label="templateName"
                            value={props.plan.templateName}
                            onChange={() => { }}
                            disabled
                        />
                        <TextBox
                            name="providerPlanIdentifier"
                            label="Provider Plan Id"
                            value={props.plan.providerPlanIdentifier}
                            onChange={handleChange}
                            disabled={props.readOnly}
                        />
                    </>
                ) : (
                    <>
                        <div className="flex spaced-content align-center">
                            <div className="flex-1 margin-top-sm margin-bottom-sm">
                                <label className="margin-left-sm">Plan Enrollment Mapping{(Object.keys(props.plan.enrollmentMappings).length > 1) && "s"}</label>
                                {displayEnrollmentMappings(props.plan.enrollmentMappings, "Plan Activation Mapping")}
                            </div>
                            {props.canEditClinicWellnessPlans &&
                                <div className="flex-none margin-top-md">
                                    <Button
                                        iconOnly
                                        type="success"
                                        text
                                        onClick={() => AddRow("enrollmentMappings")}
                                        disabled={props.readOnly}
                                    >
                                        <i className="fa-2x fa fa-plus-square" />
                                    </Button>
                                </div>
                            }
                        </div>
                        <div className="flex align-bottom">
                            <div className="margin-bottom-x-sm text-lg margin-right-x-sm">$</div>
                            <TextBox
                                min={0}
                                step={.01}
                                inputType="number"
                                name="monthlyCostToClient"
                                label="Monthly Cost to Client (Net)"
                                value={props.plan.monthlyCostToClient}
                                onChange={handleChange}
                                disabled={props.readOnly}
                            />
                        </div>
                        <div className="flex align-bottom">
                            <div className="margin-bottom-x-sm text-lg margin-right-x-sm">$</div>
                            <TextBox
                                min={0}
                                step={.01}
                                inputType="number"
                                name="taxAmount"
                                label="Tax Amount"
                                value={props.plan.taxAmount}
                                onChange={handleChange}
                                disabled={props.readOnly}
                            />
                        </div>
                        <div className="flex align-bottom">
                            <div className="margin-bottom-x-sm text-lg margin-right-x-sm">$</div>
                            <TextBox
                                min={0}
                                step={.01}
                                inputType="number"
                                name="providerFee"
                                // TODO: Might need to change this label...
                                label={`Fee to ${props.provider ? props.provider.name : "Provider"}`}
                                value={props.plan.providerFee}
                                onChange={handleChange}
                                disabled={props.readOnly}
                            />
                        </div>
                    </>
                )}
            </div>
            <hr className="margin-top-md margin-bottom-sm" />
            <div>
                <div className="margin-bottom-md">
                    {props.clinicId ? (
                        <ClinicPlanServices
                            services={props.plan.services}
                            handleChange={handleChange}
                            readOnly={props.readOnly}
                            usedPims={props.usedPims}
                        />
                    ) : (
                        <PlanServices
                            serviceCategories={props.plan.serviceCategories}
                            serviceTypes={props.plan.serviceTypes}
                            handleChange={handleChange}
                            readOnly={props.readOnly}
                        />
                    )}
                </div>
                {(props.canEditClinicWellnessPlans && props.clinicId && props.copy) &&
                    <div className="text-center margin-top-md">
                        <Button
                            type="primary"
                            onClick={handleCopyToOtherPlans}
                            disabled={props.readOnly}
                        >
                            Copy To Other Plans in {props.plan.groupName} Plan Group
                        </Button>
                    </div>
                }
            </div>
        </div>
    )
}

PlanSetupForm.propTypes = {
    handleChange: PropTypes.func.isRequired,
    plan: PropTypes.object.isRequired,
    copy: PropTypes.func,
    clinicId: PropTypes.number,
    readOnly: PropTypes.bool,
    usedPims: PropTypes.arrayOf(PropTypes.number)
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canEditClinicWellnessPlans = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewProductMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);
        return {
            canEditClinicWellnessPlans,
            canViewProductMapping,
        }
    }
)(PlanSetupForm);
