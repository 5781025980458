import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import find from "lodash/find";
import includes from "lodash/includes";
import map from "lodash/map";
import styles from "./DropdownSearch.scss";
import TextBox from "./TextBox";
import classnames from "classnames";

export default function DropdownSearch(props) {
    const {
        defaultSearch,
        disabled = false,
        disabledOptions = [],
        dropdownOptions = props.options || [],
        firstOption,
        hasError = false,
        hidePlaceholder = false,
        highlightedOptions = [],
        label = null,
        lastOption,
        loading = false,
        onEnter,
        onSearchChange,
        options: [],
        placeholder,
        required = false,
        statusIcon,
        toolTip,
        value = null,
    } = props;

    const [showOptions, setShowOptions] = useState(props.forceFocus);
    const [search, setSearch] = useState(null);
    const [options, setOptions] = useState(dropdownOptions);

    const getFilteredOptions = (value) => {
        if (value) {
            const searchTokens = value.toLowerCase().split(" ");
            return filter(dropdownOptions, (option) => {
                let allMatch = true;
                for (let i = 0; i < searchTokens.length; i++) {
                    const token = searchTokens[i];
                    if (!option.name || !option.name.toString().toLowerCase().includes(token)) {
                        allMatch = false;
                    }
                }
                return allMatch;
            });
        }
        return dropdownOptions;
    }

    useEffect(() => {
        if (!!defaultSearch) {
            const options = getFilteredOptions(defaultSearch);
            setOptions(options);
        } else if (!onSearchChange) {
            setOptions(dropdownOptions);
        }
    }, [dropdownOptions]);

    useEffect(() => {
        if (onSearchChange && defaultSearch) {
            onSearchChange(defaultSearch);
        }
    }, [defaultSearch]);

    const onChooseOption = (e, name, value) => {
        props.onChange({
            name: props.name,
            value: value,
        });
        if (defaultSearch) {
            const options = getFilteredOptions(defaultSearch);
            setSearch(null);
            setOptions(options);
        } else {
            setSearch(null);
        }
        handleCloseOptions();
    }

    const handleTyping = ({value}) => {
        setShowOptions(true);
        handleSearch({value});
    }

    const handleSearch = ({value}) => {
        if (onSearchChange) {
            onSearchChange(value);
            setSearch(value);
        }
        else {
            const options = getFilteredOptions(value);
            setSearch(value);
            setOptions(options);
        }
    }

    const handleShowOptions = () => {
		setShowOptions(true);
	};

	const handleCloseOptions = () => {
		setShowOptions(false);
        setSearch(null);
	};

    const selectedValueName = (!!value && !!dropdownOptions) ? (find(dropdownOptions, { value }) ? find(dropdownOptions, { value }).name : null) : null;
    const nullOptionEnabled = !includes(disabledOptions, null);
    const nullOptionBold = includes(props.boldPimsOptions, null);

    return (
        <div
            data-testid="dropdown_search_root"
            className={styles.root}
            onFocus={(data) => disabled ? {} : handleShowOptions(data)}
			onBlur={handleCloseOptions}
        >
            {showOptions ? (
                <TextBox
                    alignedSplit={props.alignedSplit}
                    autoFocus={showOptions}
                    data-testid="dropdown_search_text_box"
                    disabled={disabled}
                    dropdown
                    hasError={hasError}
                    isSearch
                    label={label}
                    loading={loading}
                    name={props.name}
                    onEnter={onEnter}
                    onChange={handleSearch}
                    placeholder={selectedValueName || placeholder}
                    required={required}
                    statusIcon={statusIcon}
                    toolTip={toolTip}
                    value={search}
                />
            ) : (
                <TextBox
                    alignedSplit={props.alignedSplit}
                    autofocus
                    data-testid="dropdown_search_text_box"
                    disabled={disabled}
                    dropdown
                    hasError={hasError}
                    isSearch={props.isSearch}
                    label={label}
                    loading={loading}
                    name={props.name}
                    onChange={handleTyping}
                    onEnter={onEnter}
                    placeholder={placeholder}
                    required={required}
                    statusIcon={statusIcon}
                    toolTip={toolTip}
                    value={selectedValueName}
                />
            )}
            {(!!showOptions && !disabled) && (
                <div className={classnames(styles.options, {
                    [styles.alignRight]: props.alignedSplit,
                })}>
                    {firstOption ? (
                        <div
                            className={classnames(styles.option, {
                                [styles.disabledOption]: !nullOptionEnabled,
                                [styles.boldOption]: nullOptionBold,
                            })}
                            onMouseDown={(e) => nullOptionEnabled ? onChooseOption(e, firstOption, null) : {}}
                        >
                            {firstOption}
                        </div>
                    ) : (placeholder && !hidePlaceholder) ? (
                        <div
                            className={classnames(styles.option, {
                                [styles.disabledOption]: !nullOptionEnabled,
                                [styles.boldOption]: nullOptionBold,
                            })}
                            onMouseDown={(e) => nullOptionEnabled ? onChooseOption(e, placeholder, null) : {}}
                        >
                            {placeholder}
                        </div>
                    ) : null}
                    {map((!!onSearchChange ? dropdownOptions : options), (option, index) => {
                        const isEnabled = !includes(disabledOptions, option.value);
                        const isBold = includes(props.boldPimsOptions, option.value);
                        const isHighlighted = includes(highlightedOptions, option.value);
                        return (
                            <div
                                data-testid={`dropdown_search_option_${option.value}`}
                                key={index}
                                className={classnames(styles.option, {
                                    [styles.disabledOption]: !isEnabled,
                                    [styles.boldOption]: isBold,
                                    [styles.highlightedOption]: isHighlighted,
                                })}
                                onMouseDown={(e) => isEnabled ? onChooseOption(e, option.name, option.value) : {}}
                            >
                                {!!option.dropdownName ? option.dropdownName : option.name}
                            </div>
                        );
                    })}
                    {!!lastOption && (
                        <div
                            className={styles.option}
                            onMouseDown={(e) => onChooseOption(e, lastOption.name, lastOption.value)}
                        >
                            {lastOption.name}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}

DropdownSearch.propTypes = {
    defaultSearch: PropTypes.string,
    disabled: PropTypes.bool,
    disabledOptions: PropTypes.array,
    firstOption: PropTypes.string,
    forceFocus: PropTypes.bool,
    hasError: PropTypes.bool,
    hidePlaceholder: PropTypes.bool,
    highlightedOptions: PropTypes.array,
    label: PropTypes.string,
    lastOption: PropTypes.object,
    loading: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onEnter: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onSearchChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.any,
    })),
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    statusIcon: PropTypes.node,
    toolTip: PropTypes.func,
    value: PropTypes.any,
};
