import React from "react";
import PropTypes from "prop-types";
import styles from "./GridTile.scss";
import classnames from "classnames";
import InformationBadge from "../InformationBadge";
import * as TileTypes from "constants/TileTypes";
import LinkWrapper from "components/common/LinkWrapper";

export default function GridTile(props) {

    return (
        <div
            data-testid="grid_tile_root"
            className={classnames(styles.root, {
                [styles.withDescription]: !!props.description,
                [styles.externalLink]: props.type === TileTypes.EXTERNAL_LINK,
                [styles.link]: props.type === TileTypes.LINK || props.type === TileTypes.VIDEO,
            })}
        >
            <div
                style={{
                    maxWidth: props.maxWidth,
                    minWidth: props.minWidth,
                }}
                className={styles.innerTile}
            >
                <LinkWrapper {...props}>
                    {props.title && (
                        <header
                            data-testid="grid_tile_title"
                            className={styles.header}
                        >
                            <div className={styles.headerInner}>
                                <div className={styles.headerTitle}>{props.title}</div>
                                {props.badge && (
                                    <InformationBadge
                                        data-testid="grid_tile_information_badge"
                                        message={props.badge}
                                    />
                                )}
                            </div>
                        </header>
                    )}
                    <div className={styles.content}>
                        {props.label && (
                            <span
                                data-testid="grid_tile_label"
                                className={styles.label}
                            >
                                {props.label}
                            </span>
                        )}
                        {props.largeIcon && (
                            <span
                                data-testid="grid_tile_large_icon"
                                className={styles.largeIcon}
                            >
                                <i className={props.largeIcon} />
                            </span>
                        )}
                        {props.largeValue && (
                            <span
                                data-testid="grid_tile_large_value"
                                className={styles.largeValue}
                            >
                                {props.largeValue}
                            </span>
                        )}
                        {props.description && (
                            <span
                                data-testid="grid_tile_description"
                                className={styles.description}
                                dangerouslySetInnerHTML={{ __html: props.description }}
                            />
                        )}
                    </div>
                </LinkWrapper>
            </div>
        </div>
    );
}

GridTile.propTypes = {
    title: PropTypes.string,
    badge: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.string,
    largeIcon: PropTypes.string,
    largeValue: PropTypes.string,
    linkUrl: PropTypes.string,
    link: PropTypes.string,
    type: PropTypes.string,
    maxWidth: PropTypes.string,
    minWidth: PropTypes.string,
};
