import React from "react";
import {
	MAIN_COLOR, MAIN_COLOR_SHADOW,
	SECONDARY_COLOR, SECONDARY_COLOR_SHADOW,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./PuppySwimming.scss";


export default function PuppySwimming(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Swim_-_Puppy"
			data-name="Swim - Puppy"
			xmlns="http://www.w3.org/2000/svg"
			// xmlns:xlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 800 300"
		>
			<g id="Puppy_Swimming02">
				<g className={styles.cls14}>
					<g>
						<path className={styles.cls11}
						      d="M539.2,232.91c18.06,7.13,42.62,18.48,26.78,35.62-15.94,17.25-54.38,8.91-73.36-3.38-8.93-5.78-3.19-23.83-3.18-28.33,0-4-1.06-12.52-4.59-14.78-14.27-9.12,1.53-19.94,16.37-7.1,10.97,9.49-4.94,25.45,10.71,21.02,12.27-3.48,15.03-7.88,27.27-3.05Z"/>
						<path className={styles.cls11}
						      d="M470.05,219.64c-.61-6.33,6.1-11.22,15-10.91,8.89.3,16.61,5.68,17.23,12.02.61,6.32-6.1,11.21-15,10.91-8.9-.31-16.62-5.68-17.23-12.01Z"/>
						<g>
							<path className={styles.cls10}
							      d="M501.56,218.8c1.21-1.7,2.86-3.22,4.75-3.99.15.2.29.4.44.61.57.78,1.12,1.57,1.7,2.35,3.33,4.46,6.41,4.91,10.66,8.2,2.47,1.92,7.92,8.31,8.05,12.77.19,6.29-6.32,5.28-9.19,4.02-6.65-2.93-13.46-10.06-18.71-17.94,0-.12,0-.25.01-.37.17-2.05,1.1-3.97,2.29-5.65Z"/>
							<path className={styles.cls8}
							      d="M492.35,201.02c2.2-1.22,4.61,1.03,6.2,3.1.39.51.77,1.04,1.16,1.55,2.24,3.01,4.4,6.08,6.6,9.13-1.89.77-3.55,2.3-4.75,3.99-1.19,1.67-2.12,3.6-2.29,5.65-.01.12,0,.25-.01.37-1.3-1.96-2.52-3.97-3.6-5.97-1.79-3.31-3.24-6.6-4.23-9.63-.08-.23-.17-.47-.24-.7-.81-2.63-1.08-6.25,1.17-7.49Z"/>
						</g>
						<ellipse
							className={styles.cls1}
							cx="608.08"
							cy="208.78"
							rx="68.33"
							ry="103"
							transform="translate(-32.39 264.87) rotate(-23.95)"
							style={{ fill: `${teamColors[MAIN_COLOR]}` }}
						/>
						<ellipse
							className={styles.cls7}
							cx="608.08"
							cy="208.78"
							rx="42.51"
							ry="64.08"
							transform="translate(-32.39 264.87) rotate(-23.95)"
							style={{ fill: `${teamColors[MAIN_COLOR_SHADOW]}` }}
						/>
						<path className={styles.cls11}
						      d="M639.87,237.99c-12.4-9.63-14.67-30.44,1.23-29.88,13.95.49,21.86,6.46,34.19,15.26,6.88,4.91,13.31-9,19.15-11.56,7.71-3.38,14.36.68,13.88,5.75-1.46,15.35-14.28,34.48-28.07,33-17.86-1.92-24.34-.13-40.37-12.57Z"/>
						<path className={styles.cls11}
						      d="M721.48,219.25c.58,6.73-6.19,12.15-15.13,12.11-8.94-.04-16.66-5.54-17.25-12.27-.58-6.72,6.19-12.15,15.13-12.1,8.94.05,16.67,5.54,17.26,12.27Z"/>
						<path className={styles.cls8}
						      d="M521.35,229.26c4.62-4.75,29.89-9.76,33.72-10.24,6.7-.82,9.25-2.96,15.43-5.6,3.39-1.45,6.45-3.81,9.39-6,3.04-2.27,6.93-4.74,9.34-7.64,1.65-1.98,2.58-4.58,4.63-6.15,1.75,1.12,2.12,3.46,3.1,5.3,5.83,10.95,20.91,14.81,32.32,14.94,5.42.06,10.84-.62,16.12-1.83.97-.22,11.35-2.56,11.32-3.45.06,1.76.28,3.47.53,5.16.61,3.97.73,9.25.6,13.47,3.37,13.15.15,27.3-11.45,38.82-14.74,14.65-36.04,14.49-55.22,13.91-8.27-.25-10.68-2.82-18.9-1.22-7.63,1.48-15.87,7.25-23.61,8.08-13.72,1.49-25.33-4.88-33-17.29-6.73-10.89-2.88-31.46,5.68-40.27Z"/>
						<path className={styles.cls11}
						      d="M537.07,272.91c6.5-3.67,21.15-7.59,28.16-8.41,10.48-1.23,21.27,3.15,31.57-.23,10.18-3.34,19.98-8.79,29-14.45,4.47-2.8,10.58-5.13,14-9.48,10.9-13.87,17.18-13.45,19.31-14.29,3.94,20.54-5.62,32.95-12.71,40-14.74,14.65-36.04,14.49-55.22,13.91-8.27-.25-10.68-2.82-18.9-1.22-7.63,1.48-19.88,9.94-29.22,8.3-17.75-3.12-11.57-10.97-5.99-14.12Z"/>
						<path className={styles.cls10}
						      d="M570.49,213.43c3.39-1.45,6.45-3.81,9.39-6,3.04-2.27,6.93-4.74,9.34-7.64,1.65-1.98,2.58-4.58,4.63-6.15,1.75,1.11,2.12,3.46,3.1,5.3,4.26,8,13.47,12.21,22.58,13.95.25,1.38.49,2.78.72,4.19,1.28,7.75,5.98,12.29,8.03,19.37.92,3.18.45,6.96-1.98,9.21-2.27,2.1-5.76,2.4-8.73,1.55-2.97-.85-5.55-2.67-8.07-4.47-11.85-8.45-15.8-8.82-30.09-9.51-9.4-.46-18.37-6.14-22.83-14.42,5.61-.94,8.24-2.94,13.92-5.37Z"/>
						<path className={styles.cls10}
						      d="M520.86,228.94c3.24-3.34,17.17-6.48,26.2-8.41.82,1.42,1.45,2.89,1.81,4.4,3.5,14.55-17.83,35.81-31.51,36.38-1.57.07-3.13-.07-4.66-.35-1.76-10.98,1.53-25.19,8.16-32.02Z"/>
						<path className={styles.cls8}
						      d="M534.5,239.14c17.47-5.41,39.71,1.67,33.32,24.11-6.43,22.59-34.97,31.11-57.46,28.72-10.57-1.12-13.61-19.82-15.63-23.83-1.8-3.57-6.61-10.69-10.77-11.11-16.85-1.7-7.64-18.48,11.4-13.73,14.07,3.51,7.09,24.94,19.04,13.91,9.37-8.65,7.52-14.18,20.09-18.07Z"/>
						<path className={styles.cls9}
						      d="M470.1,252.04c-1.4-6.21,4.66-11.89,13.53-12.7,8.86-.81,17.19,3.57,18.59,9.78,1.4,6.2-4.66,11.88-13.52,12.69-8.87.81-17.19-3.57-18.59-9.77Z"/>
						<path
							id="Tube"
							className={styles.cls1}
							d="M545.63,236.53c-23.1-51.99-13.86-106.55,20.63-121.87,19.36-8.6,35.89,15.5,39.09,34.9-16.28-4.51-27.56,16.9-12.34,61.71,12.99,38.24,40.51,56.93,60.61,40.8,5.36,15.78,17.83,41.27-3.72,50.85-34.49,15.32-81.17-14.4-104.27-66.39Z"
							style={{ fill: `${teamColors[MAIN_COLOR]}` }}
						/>
						<path className={styles.cls5}
						      d="M598.01,177.11c-3.71,2.82-7.35,9.33-7.58,13.92-.22,4.37,1.29,9.12,3,12.85,3.78,8.26,10.56,14.72,17.98,19.24,10.88,6.64,22.57,12.83,35.93,9.88,6.66-1.47,12.67-3.44,17.03-9.62,11.44-16.22-1.3-28.1-12.63-37.41-10.66-8.77-28.06-20.29-43-13.98-3.22,1.36-7.85,2.94-10.72,5.12Z"/>
						<path className={styles.cls9}
						      d="M618.9,247.73c-8.53-13.18-7.54-27.4,8.32-28.59s24.38,8.35,29.93,26.48c2.48,8.08,15.54-4.1,21.89-4.59,8.39-.64,13.33,5.38,11.2,10.01-6.44,14.01-24.86,27.83-37.39,21.88-16.23-7.71-22.93-8.16-33.96-25.19Z"/>
						<path className={styles.cls9}
						      d="M699.19,250.74c1.52,6.59-4.43,12.9-13.29,14.1-8.86,1.2-17.27-3.15-18.8-9.74-1.52-6.58,4.43-12.89,13.29-14.1,8.86-1.2,17.28,3.15,18.8,9.74Z"/>
					</g>
				</g>
				<g>
					<path className={styles.cls10}
					      d="M699.29,82.22c4.9,5.67,15.33,32.84,8.21,37.26-11.17,6.93-55.24-2.22-59.6-2.73-5.47-.64-10.78-3.3-12.68-7.27-3.1-6.46,3.79-15.72,9.04-20.08,20.36-16.93,39.58-25.08,55.03-7.18Z"/>
					<path className={styles.cls9}
					      d="M668.38,207.45c-2.58-6.52-2.93-13.89-.98-20.62,2-6.95,6.39-13.39,6.29-20.61-.14-9.84-8.38-17.46-16.27-23.34-11.97-8.93-24.67-16.89-37.93-23.77-3.38-1.76-6.91-3.45-10.54-4.68,6.73-4.24,14.18-7.46,22.01-9.91.83.58,1.73,1.08,2.6,1.5,5.64,2.73,11.53,4.99,16.76,8.47,10.95,7.3,18.03,19.29,26.43,29.23,3.93,4.66,8.9,9.07,14.88,9.67,5.96.59,11.48-2.71,17.26-4.14,3.62-.9,7.41-.96,11.11-.34,4.6,3.42,8.64,7.38,10.07,11.95,3.61,11.5,1.46,26.34-5.33,33.86-11.48,12.71-22.82,16.53-35.76,20.3-5.54,1.61-11.1,2.43-16.61,2.64-.78-3.42-2.66-6.88-3.97-10.2Z"/>
					<path className={styles.cls6}
					      d="M595.22,183.2c-3.99-5.74-7.48-11.88-10.32-18.25-4.94-11.06-2.86-20.19,3.39-30.14,5.42-8.63,12.51-15.25,20.66-20.38,3.62,1.23,7.15,2.93,10.54,4.68,13.26,6.88,25.96,14.84,37.93,23.77,7.89,5.88,16.13,13.5,16.27,23.34.1,7.23-4.28,13.67-6.29,20.61-1.94,6.73-1.59,14.11.98,20.62,1.31,3.32,3.2,6.78,3.97,10.2-36.36,1.38-70.23-24.51-77.13-34.45Z"/>
					<path className={styles.cls6}
					      d="M691.62,153.38c-5.98-.59-10.94-5-14.88-9.67-8.39-9.95-15.48-21.94-26.43-29.23-5.23-3.48-11.12-5.74-16.76-8.47-.87-.42-1.77-.92-2.6-1.5,1.14-.36,2.28-.7,3.43-1.02,13.97-3.95,26.59-5.77,39.87,2.83,6.65,4.31,11.41,13.02,15.82,19.44,5.16,7.51,9.23,9.75,17.06,14.8,3.63,2.34,8.49,5.11,12.85,8.35-3.69-.62-7.48-.56-11.11.34-5.79,1.43-11.31,4.73-17.26,4.14Z"/>
					<path
						id="Cap"
						className={styles.cls13}
						d="M594.14,181.46c13.74-22.84,29.99-41.71,49.17-51.5,18.05-9.22,31.95-9.13,43.89-9.05-6.19-19.44-28.46-29.42-60.22-16.53-58.46,23.73-46,62.94-32.83,77.09Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<circle
						className={styles.cls12}
						cx="606.32"
						cy="127.67"
						r="12.23"
						style={{ fill: `${teamColors[SECONDARY_COLOR_SHADOW]}` }}
					/>
					<g>
						<g>
							<path className={styles.cls4}
							      d="M641.51,161.8c4.2,6.37,10.5,9.62,14.07,7.26,3.57-2.36,3.06-9.44-1.15-15.82-4.2-6.37-10.5-9.62-14.07-7.26-3.57,2.36-3.06,9.45,1.15,15.82Z"/>
							<path className={styles.cls4}
							      d="M682.17,140.79c4.72,5.62,10.73,8.31,13.44,6.03,2.7-2.29,1.07-8.7-3.65-14.31-4.72-5.61-10.73-8.31-13.44-6.02-2.7,2.29-1.07,8.7,3.65,14.31Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls4}
								      d="M699.38,166.35c1.1-2.22,3.79-4.56,6.98-6.31h0s0,0,.01,0c0,0,0,0,.01,0h0c3.15-1.84,6.52-2.94,8.98-2.74,11.7.97,8.46,13.99.38,19.48h0s-.01,0-.01,0c0,0-.01,0-.02.01h0c-8.86,4.11-21.58.15-16.34-10.44Z"/>
								<path className={styles.cls4}
								      d="M727.08,182.24c-.19-.1-.42-.15-.68-.15-.86.01-1.18.84-1.65,1.42-.65.8-1.46,1.57-2.31,2.14-2.74,1.84-5.36,2.02-8.38,1.67l-.05-.12s-.06.07-.09.11c-.05,0-.09-.01-.14-.01l.05.12c-1.88,2.39-3.86,4.12-7.09,4.76-2.24.45-7.18,0-9.18-1.53-.7-.54-1.69-.39-2.2.31-.51.71-.36,1.71.35,2.25,3.09,2.35,9.16,2.63,11.7,2.12.98-.2,1.87-.47,2.68-.81,2.23-.93,3.85-2.34,5.22-3.89,2.06.12,4.2-.03,6.44-.96.81-.34,1.63-.77,2.46-1.33,1.2-.8,2.86-2.12,3.49-3.45.42-.89.26-2.22-.62-2.66Z"/>
							</g>
							<path className={styles.cls4}
							      d="M715,190.7c-.41.2-.89.23-1.35.05-.88-.34-1.34-1.35-1.02-2.24.03-.1,1.53-4.64-.06-12.98-.18-.94.43-1.85,1.35-2.01.93-.17,1.82.47,2,1.41,1.8,9.45,0,14.61-.09,14.83-.16.43-.46.76-.84.94Z"/>
						</g>
						<path className={styles.cls9}
						      d="M720.4,161.57c.16,1.81-2.3,3.52-5.49,3.8-3.2.29-5.92-.95-6.08-2.76-.16-1.81,2.3-3.52,5.49-3.8,3.19-.29,5.92.95,6.08,2.76Z"/>
						<path className={styles.cls9}
						      d="M688.87,132.82c-.62.96-2.55.82-4.3-.31s-2.68-2.83-2.05-3.79c.62-.96,2.55-.82,4.3.31,1.76,1.13,2.68,2.83,2.05,3.79Z"/>
						<path className={styles.cls9}
						      d="M650.82,153.51c-.7,1.31-3.17,1.36-5.53.12-2.36-1.25-3.71-3.32-3.02-4.63.7-1.31,3.17-1.36,5.53-.12,2.36,1.25,3.71,3.32,3.02,4.63Z"/>
						<path className={styles.cls10}
						      d="M548.79,149.21c.57,8.02,12.55,36.71,20.79,34.8,12.91-3,37.76-42.94,40.5-46.59,3.44-4.58,5.32-10.58,3.84-15.06-2.41-7.28-13.93-9.27-20.79-8.71-26.61,2.17-46.12,10.24-44.34,35.55Z"/>
					</g>
				</g>
				<g>
					<path className={styles.cls3}
					      d="M466.43,251.7c6.26,8.19,20.95,11.28,30.04,11.14,15.18,12.37,30.73,13.31,51.91,9.82-14.02,7.32-35.53,9.34-53.48-6.91-10.5-.64-26.24-4.66-28.47-14.05Z"/>
					<path className={styles.cls3}
					      d="M562.2,268.8c13.36,5.18,51.05,9.98,72.63-4.43,11.92,4.62,29.05,3.12,43.99-5.28-10.14,11.91-32.98,13.66-45.44,9.43-21.31,15.87-58.93,11.19-71.18.28Z"/>
					<path className={styles.cls3}
					      d="M631,278.21c9.93,1.26,30.67-.51,42.33-6.49-8.76,11.2-36.19,12.63-42.33,6.49Z"/>
					<path className={styles.cls3}
					      d="M572.02,282.73c9.46,3.34,19.51,6.07,32.49,3.44-9.83,6.62-28.27,4.15-32.49-3.44Z"/>
					<path className={styles.cls3}
					      d="M686.04,239.26c-1.97-6.9-1.53-20.16,3.08-19.52,5.94.82-2.33,14.37-3.08,19.52Z"/>
					<path className={styles.cls3}
					      d="M480.7,222.89c.41-7.21-7.02-18-9.73-14.22-3.15,4.39,7.69,9.44,9.73,14.22Z"/>
					<path className={styles.cls3}
					      d="M475.54,240.94c.37-6.87-7.63-17.21-10.49-13.63-3.32,4.16,8.27,9.06,10.49,13.63Z"/>
					<path className={styles.cls3}
					      d="M454.53,232.66c-2.86-3.91-11.26-8.18-12-5.55-.83,2.94,8.02,4.94,12,5.55Z"/>
					<path className={styles.cls3}
					      d="M481.75,199.2c-.3-4.83-5.07-12.96-7.11-11.14-2.28,2.03,4.09,8.48,7.11,11.14Z"/>
					<path className={styles.cls3}
					      d="M462.8,221.94c-1.72-4.28-5.58-10.94-7.85-8.93-2.53,2.24,4.13,6.17,7.85,8.93Z"/>
					<path className={styles.cls3}
					      d="M702.06,231.53c1.2-6.32,6.91-13.61,8.68-11.4,2.31,2.91-5.02,7.11-8.68,11.4Z"/>
					<path className={styles.cls3}
					      d="M693.03,257.82c16.95-6.58,18.78-13.64,13.45-13.64,1.19-2.64,0-2.59-5.38-.91,7.3-3.44,9.85-2.48,6.04.76,9.12-2.85,6.1,9.33-14.11,13.79Z"/>
					<path className={styles.cls3}
					      d="M717.21,251.71c10.43-5.04,11.24-10.3,5.91-10.3,1.19-2.64.43-4.12-4.51-3.72,4.92-1.31,8.97.33,5.16,3.57,7.11-1.57,6.56,7.56-6.56,10.45Z"/>
					<path className={styles.cls3} d="M462.62,249.9c-8.52-3.41-9.73-8-1.54-7.3-5.1,1.08-2.04,4.51,1.54,7.3Z"/>
					<path className={styles.cls3}
					      d="M713.66,233.34c4.96-7.44,15.44-9.64,15.69-5.96.36,5.28-8.78,2.03-15.69,5.96Z"/>
					<path className={styles.cls3}
					      d="M707.45,212.44c.73-5.59,3.17-11.9,5.77-10.78,2.68,1.15-3.11,6.77-5.77,10.78Z"/>
					<path className={styles.cls3}
					      d="M628.6,260.2c-15.72,15.72-59.76,10.93-71.64-.95-20.88,10.1-35.67,10.86-52.7-6.18-35.68,5.52-41.22-6.24-32.35-8.18-5.38,5.38,13.09,9,32.6,7.44,19.2,10.88,30.9,13.08,52.48,5.89,17.91,11.58,54.63,10.72,71.4,1.16,18.92,4.24,72.04-9.02,69.47-13.32,11.66,2.18-28.83,19.56-69.24,14.14Z"/>
					<path className={styles.cls3}
					      d="M480.7,222.89c.41-7.21-7.02-18-9.73-14.22-3.15,4.39,7.69,9.44,9.73,14.22Z"/>
					<path className={styles.cls3}
					      d="M475.54,240.94c.37-6.87-7.63-17.21-10.49-13.63-3.32,4.16,8.27,9.06,10.49,13.63Z"/>
					<path className={styles.cls3}
					      d="M454.53,232.66c-2.86-3.91-11.26-8.18-12-5.55-.83,2.94,8.02,4.94,12,5.55Z"/>
					<path className={styles.cls3}
					      d="M481.75,199.2c-.3-4.83-5.07-12.96-7.11-11.14-2.28,2.03,4.09,8.48,7.11,11.14Z"/>
					<path className={styles.cls3}
					      d="M462.8,221.94c-1.72-4.28-5.58-10.94-7.85-8.93-2.53,2.24,4.13,6.17,7.85,8.93Z"/>
				</g>
			</g>
			<g id="Puppy_Swimming01">
				<g className={styles.cls15}>
					<g>
						<path className={styles.cls11}
						      d="M138.54,247.28c19.41-.28,46.45.87,38.32,22.75-8.18,22.01-46.9,28.93-69.13,24.79-10.45-1.95-12.01-20.82-13.72-24.98-1.52-3.7-5.75-11.18-9.86-11.92-16.67-3.01-6.17-19.02,12.44-12.8,13.75,4.6,5.12,25.42,17.9,15.36,10.02-7.89,10.9-13.01,24.06-13.2Z"/>
						<path className={styles.cls11}
						      d="M72.29,247.01c1.28-6.23,9.13-8.92,17.55-6.01,8.41,2.91,14.2,10.32,12.92,16.56-1.28,6.22-9.13,8.92-17.55,6.01-8.42-2.91-14.21-10.32-12.93-16.56Z"/>
						<path className={styles.cls10}
						      d="M109.28,214.85c1.66-1.26,3.7-2.21,5.73-2.39.08.24.16.47.23.71.31.91.6,1.84.92,2.75,1.84,5.25,4.65,6.6,7.72,11.02,1.78,2.57,5.07,10.3,3.86,14.6-1.7,6.06-7.61,3.15-9.98,1.09-5.47-4.78-9.84-13.63-12.48-22.72.04-.12.07-.23.12-.35.78-1.9,2.24-3.46,3.88-4.7Z"/>
						<path className={styles.cls8}
						      d="M105.82,195.13c2.46-.51,4.09,2.36,4.99,4.82.22.61.43,1.22.64,1.83,1.24,3.54,2.38,7.12,3.57,10.68-2.03.17-4.07,1.13-5.73,2.39-1.64,1.24-3.1,2.8-3.88,4.7-.05.11-.08.23-.12.35-.66-2.26-1.21-4.54-1.65-6.77-.72-3.69-1.12-7.26-1.16-10.45,0-.24-.02-.5-.02-.74.01-2.75.84-6.28,3.36-6.8Z"/>
						<ellipse
							className={styles.cls1}
							cx="210.95"
							cy="208.78"
							rx="68.33"
							ry="103"
							transform="translate(-66.6 103.63) rotate(-23.95)"
							style={{ fill: `${teamColors[MAIN_COLOR]}` }}
						/>
						<ellipse
							className={styles.cls7}
							cx="210.95"
							cy="208.78"
							rx="42.51"
							ry="64.08"
							transform="translate(-66.6 103.63) rotate(-23.95)"
							style={{ fill: `${teamColors[MAIN_COLOR_SHADOW]}` }}
						/>
						<path className={styles.cls11}
						      d="M243.51,235.31c-5.93-14.54,2.51-33.69,16-25.27,11.84,7.4,15.71,16.52,21.98,30.31,3.5,7.69,16.03-1.15,22.37-.44,8.36.93,12.1,7.76,9.14,11.91-8.94,12.57-29.6,22.73-40.8,14.55-14.51-10.59-21.02-12.28-28.69-31.06Z"/>
						<path className={styles.cls11}
						      d="M325.32,255.48c-.97,6.69-8.8,10.42-17.5,8.33-8.69-2.09-14.96-9.2-13.99-15.89.97-6.68,8.8-10.41,17.49-8.33,8.7,2.09,14.96,9.2,14,15.89Z"/>
						<path className={styles.cls8}
						      d="M124.22,229.26c4.62-4.75,29.89-9.76,33.72-10.24,6.7-.82,9.25-2.96,15.43-5.6,3.39-1.45,6.45-3.81,9.39-6,3.04-2.27,6.93-4.74,9.34-7.64,1.65-1.98,2.58-4.58,4.63-6.15,1.75,1.12,2.12,3.46,3.1,5.3,5.83,10.95,20.91,14.81,32.32,14.94,5.42.06,10.84-.62,16.12-1.83.97-.22,11.35-2.56,11.32-3.45.06,1.76.28,3.47.53,5.16.61,3.97.73,9.25.6,13.47,3.37,13.15.15,27.3-11.45,38.82-14.74,14.65-36.04,14.49-55.22,13.91-8.27-.25-10.68-2.82-18.9-1.22-7.63,1.48-15.87,7.25-23.61,8.08-13.72,1.49-25.33-4.88-33-17.29-6.73-10.89-2.88-31.46,5.68-40.27Z"/>
						<path className={styles.cls11}
						      d="M139.94,272.91c6.5-3.67,21.15-7.59,28.16-8.41,10.48-1.23,21.27,3.15,31.57-.23,10.18-3.34,19.98-8.79,29-14.45,4.47-2.8,10.58-5.13,14-9.48,10.9-13.87,17.18-13.45,19.31-14.29,3.94,20.54-5.62,32.95-12.71,40-14.74,14.65-36.04,14.49-55.22,13.91-8.27-.25-10.68-2.82-18.9-1.22-7.63,1.48-19.88,9.94-29.22,8.3-17.75-3.12-11.57-10.97-5.99-14.12Z"/>
						<path className={styles.cls10}
						      d="M173.37,213.43c3.39-1.45,6.45-3.81,9.39-6,3.04-2.27,6.93-4.74,9.34-7.64,1.65-1.98,2.58-4.58,4.63-6.15,1.75,1.11,2.12,3.46,3.1,5.3,4.26,8,13.47,12.21,22.58,13.95.25,1.38.49,2.78.72,4.19,1.28,7.75,5.98,12.29,8.03,19.37.92,3.18.45,6.96-1.98,9.21-2.27,2.1-5.76,2.4-8.73,1.55-2.97-.85-5.55-2.67-8.07-4.47-11.85-8.45-15.8-8.82-30.09-9.51-9.4-.46-18.37-6.14-22.83-14.42,5.61-.94,8.24-2.94,13.92-5.37Z"/>
						<path className={styles.cls10}
						      d="M123.73,228.94c3.24-3.34,17.17-6.48,26.2-8.41.82,1.42,1.45,2.89,1.81,4.4,3.5,14.55-17.83,35.81-31.51,36.38-1.57.07-3.13-.07-4.66-.35-1.76-10.98,1.53-25.19,8.16-32.02Z"/>
						<path className={styles.cls8}
						      d="M143.04,235.37c17.7,4.6,32.89,22.32,15.64,38.04-17.36,15.82-46.11,8.04-63.97-5.83-8.4-6.52-1.14-24.01-.75-28.49.35-3.99.01-12.57-3.3-15.11-13.44-10.31,3.23-19.73,16.92-5.67,10.12,10.39-7.1,24.94,8.87,21.86,12.52-2.42,13.85-8.09,26.59-4.79Z"/>
						<path className={styles.cls9}
						      d="M76.63,222.48c-.64-6.33,6.06-11.24,14.96-10.97,8.9.27,16.63,5.62,17.27,11.95.64,6.32-6.06,11.24-14.95,10.97-8.9-.27-16.64-5.62-17.28-11.95Z"/>
						<path
							id="Tube-2"
							data-name="Tube"
							className={styles.cls1}
							d="M148.51,236.53c-23.1-51.99-13.86-106.55,20.63-121.87,19.36-8.6,35.89,15.5,39.09,34.9-16.28-4.51-27.56,16.9-12.34,61.71,12.99,38.24,40.51,56.93,60.61,40.8,5.36,15.78,17.83,41.27-3.72,50.85-34.49,15.32-81.17-14.4-104.27-66.39Z"
							style={{ fill: `${teamColors[MAIN_COLOR]}` }}
						/>
						<path className={styles.cls5}
						      d="M200.88,177.11c-3.71,2.82-7.35,9.33-7.58,13.92-.22,4.37,1.29,9.12,3,12.85,3.78,8.26,10.56,14.72,17.98,19.24,10.88,6.64,22.57,12.83,35.93,9.88,6.66-1.47,12.67-3.44,17.03-9.62,11.44-16.22-1.3-28.1-12.63-37.41-10.66-8.77-28.06-20.29-43-13.98-3.22,1.36-7.85,2.94-10.72,5.12Z"/>
						<path className={styles.cls9}
						      d="M223.89,251.57c-12.57-9.41-16.58-23.09-2.11-29.7,15.17-6.93,25.76-.62,37.26,14.46,5.13,6.72,13.15-9.24,18.94-11.9,7.65-3.51,14.37.42,13.97,5.5-1.19,15.38-13.67,34.72-27.48,33.49-17.89-1.6-24.34.3-40.59-11.85Z"/>
						<path className={styles.cls9}
						      d="M302.1,235.36c.12,6.76-7.01,11.7-15.93,11.04-8.91-.66-16.24-6.67-16.37-13.43-.12-6.75,7.01-11.7,15.92-11.04,8.92.66,16.25,6.67,16.37,13.42Z"/>
					</g>
				</g>
				<g>
					<path className={styles.cls10}
					      d="M297.54,78.54c5.29,5.31,17.64,31.66,10.85,36.58-10.65,7.71-55.25,1.73-59.64,1.53-5.5-.25-10.99-2.52-13.17-6.34-3.55-6.22,2.65-15.95,7.58-20.67,19.1-18.34,37.68-27.84,54.38-11.09Z"/>
					<path className={styles.cls9}
					      d="M275.65,205.66c-3.03-6.32-3.91-13.65-2.45-20.5,1.5-7.07,5.41-13.81,4.8-21.01-.84-9.8-9.6-16.81-17.89-22.12-12.58-8.05-25.82-15.09-39.53-21-3.5-1.51-7.14-2.95-10.84-3.92,6.41-4.71,13.61-8.45,21.25-11.46.87.52,1.8.96,2.7,1.32,5.82,2.32,11.86,4.15,17.32,7.25,11.44,6.5,19.36,17.95,28.44,27.27,4.26,4.37,9.52,8.42,15.53,8.58,5.98.16,11.25-3.52,16.92-5.36,3.55-1.15,7.33-1.48,11.05-1.13,4.83,3.08,9.14,6.74,10.9,11.2,4.42,11.22,3.34,26.17-2.9,34.15-10.55,13.5-21.58,18.11-34.22,22.8-5.41,2-10.9,3.22-16.38,3.82-1.02-3.36-3.15-6.67-4.69-9.89Z"/>
					<path className={styles.cls6}
					      d="M200.95,186.69c-4.39-5.44-8.31-11.32-11.6-17.47-5.71-10.68-4.29-19.94,1.23-30.31,4.79-8.99,11.39-16.11,19.15-21.8,3.7.97,7.34,2.41,10.84,3.92,13.72,5.92,26.95,12.95,39.53,21,8.29,5.31,17.05,12.31,17.89,22.12.62,7.2-3.3,13.94-4.8,21.01-1.46,6.85-.58,14.18,2.45,20.5,1.55,3.22,3.67,6.53,4.69,9.89-36.17,3.98-71.8-19.43-79.39-28.86Z"/>
					<path className={styles.cls6}
					      d="M294.97,150.07c-6.01-.17-11.27-4.21-15.53-8.58-9.08-9.32-17-20.78-28.44-27.27-5.46-3.1-11.5-4.93-17.32-7.25-.9-.36-1.83-.79-2.7-1.32,1.11-.44,2.22-.86,3.35-1.26,13.65-4.94,26.11-7.65,39.97-.02,6.94,3.82,12.31,12.17,17.17,18.26,5.69,7.12,9.9,9.06,18.07,13.55,3.79,2.08,8.83,4.49,13.41,7.41-3.73-.36-7.5-.03-11.05,1.13-5.67,1.84-10.94,5.53-16.92,5.36Z"/>
					<path
						id="Cap-2"
						data-name="Cap"
						className={styles.cls13}
						d="M199.74,185.04c12.07-23.76,26.94-43.75,45.37-54.88,17.35-10.48,31.22-11.39,43.13-12.16-7.56-18.95-30.49-27.31-61.25-12.19-56.62,27.84-41.39,66.07-27.25,79.23Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<circle
						className={styles.cls12}
						cx="208.05"
						cy="130.51"
						r="12.23"
						style={{ fill: `${teamColors[SECONDARY_COLOR_SHADOW]}` }}
					/>
					<g>
						<g>
							<path className={styles.cls4}
							      d="M245.59,162.04c4.65,6.06,11.16,8.85,14.56,6.24,3.39-2.61,2.37-9.64-2.27-15.7-4.65-6.06-11.16-8.85-14.56-6.24-3.39,2.61-2.37,9.64,2.27,15.7Z"/>
							<path className={styles.cls4}
							      d="M284.65,138.19c5.1,5.26,11.3,7.52,13.83,5.05,2.53-2.48.45-8.75-4.66-14.01-5.11-5.26-11.3-7.52-13.83-5.05-2.53,2.48-.45,8.75,4.66,14.01Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls4}
								      d="M303.64,162.45c.94-2.29,3.46-4.82,6.51-6.8h0s0,0,.01,0c0,0,0,0,.01,0h0c3.01-2.06,6.3-3.4,8.76-3.37,11.74.13,9.44,13.35,1.77,19.41h0s-.01,0-.01,0c0,0-.01,0-.01.01h0c-8.54,4.73-21.52,1.69-17.04-9.24Z"/>
								<path className={styles.cls4}
								      d="M332.4,176.32c-.2-.08-.43-.12-.69-.1-.86.07-1.12.92-1.54,1.53-.59.85-1.34,1.67-2.15,2.3-2.6,2.03-5.2,2.4-8.24,2.26l-.06-.12s-.05.07-.08.11c-.05,0-.09,0-.14,0l.06.12c-1.71,2.52-3.55,4.38-6.73,5.26-2.2.61-7.17.5-9.27-.87-.74-.48-1.71-.27-2.17.47-.46.74-.23,1.74.51,2.22,3.25,2.12,9.33,1.97,11.82,1.28.97-.27,1.83-.6,2.62-1,2.16-1.08,3.68-2.61,4.93-4.25,2.06-.03,4.19-.33,6.35-1.42.79-.39,1.57-.89,2.36-1.51,1.14-.89,2.7-2.31,3.24-3.69.36-.92.1-2.23-.81-2.61Z"/>
							</g>
							<path className={styles.cls4}
							      d="M320.96,185.62c-.39.23-.87.29-1.34.15-.9-.28-1.43-1.25-1.18-2.16.03-.1,1.2-4.74-.98-12.94-.24-.93.29-1.87,1.2-2.1.91-.23,1.85.34,2.1,1.26,2.47,9.29,1.03,14.57.97,14.8-.12.44-.41.79-.77,1Z"/>
						</g>
						<path className={styles.cls9}
						      d="M324.26,156.18c.29,1.8-2.04,3.67-5.21,4.19-3.17.51-5.97-.53-6.26-2.32-.29-1.8,2.04-3.67,5.21-4.19,3.17-.51,5.97.53,6.26,2.32Z"/>
						<path className={styles.cls9}
						      d="M290.76,129.76c-.55,1-2.48,1-4.32,0-1.83-1-2.87-2.63-2.32-3.63.55-1,2.48-1,4.32,0,1.83,1,2.87,2.63,2.32,3.63Z"/>
						<path className={styles.cls9}
						      d="M254.28,153.11c-.6,1.36-3.07,1.59-5.51.51-2.44-1.08-3.94-3.05-3.34-4.41.6-1.36,3.07-1.59,5.51-.51,2.44,1.08,3.94,3.05,3.34,4.41Z"/>
						<path className={styles.cls10}
						      d="M152.2,156.1c1.14,7.96,15.14,35.73,23.22,33.23,12.66-3.92,34.6-45.52,37.07-49.36,3.1-4.81,4.55-10.94,2.75-15.29-2.93-7.09-14.56-8.25-21.36-7.2-26.39,4.06-45.27,13.51-41.69,38.63Z"/>
					</g>
				</g>
				<g>
					<path className={styles.cls3}
					      d="M235.81,259.54c-20.36,15.78-61.26,13.35-75.99-.29-20.88,10.1-51.71,8.39-68.5-8.41-17.11-1.65-22.55-7.82-16.95-8.13-2.32,2.8,8.66,6.26,17.2,7.39,17.03,13.72,46.7,15.3,68.28,8.12,17.91,11.58,58.97,10.06,75.74.5,15.25,3.42,27.27,1.86,46.38-4.55.28-.09.55-.22.83-.33,17.85-1.72,37.21-9.47,33.85-13.05,12.38.74-2.66,11.68-33.86,14.16-19.52,9.9-35.82,10.18-46.98,4.6Z"/>
					<path className={styles.cls3}
					      d="M68.02,244.8c7.1,7.1,14.61,11.32,23.7,11.63,14.55,13.11,34.01,15.9,55.34,13.46-14.37,6.61-39.93,6.48-57.05-10.64-10.46-1.16-18.31-5.5-21.99-14.46Z"/>
					<path className={styles.cls3}
					      d="M164.02,268.98c13.36,5.18,50.61,11.19,72.18-3.22,11.92,4.62,33.88.85,48.82-7.55-10.14,11.91-37.71,16.11-50.17,11.89-21.31,15.87-58.58,9.79-70.83-1.12Z"/>
					<path className={styles.cls3}
					      d="M231.47,276.81c9.93,1.26,30.67-.51,42.33-6.49-8.76,11.2-36.19,12.63-42.33,6.49Z"/>
					<path className={styles.cls3}
					      d="M174.89,282.73c9.46,3.34,19.51,6.07,32.49,3.44-9.83,6.62-28.27,4.15-32.49-3.44Z"/>
					<path className={styles.cls3}
					      d="M289.5,258.21c11.64,1.06,19.92-1.14,27.58-9.87,12.05-3.94,22.64-9.26,16.18-12.83,14.36-1.42,9.8,6.75-14.63,13.77-5.15,8.83-17.9,13.96-29.13,8.92Z"/>
					<path className={styles.cls3}
					      d="M308.75,235.35c-1.97-6.9-1.53-20.16,3.08-19.52,5.94.82-2.33,14.37-3.08,19.52Z"/>
					<path className={styles.cls3}
					      d="M78.61,204.19c.41-7.21-7.02-18-9.73-14.22-3.15,4.39,7.69,9.44,9.73,14.22Z"/>
					<path className={styles.cls3}
					      d="M71.92,235.88c.37-6.87-7.63-17.21-10.49-13.63-3.32,4.16,8.27,9.06,10.49,13.63Z"/>
					<path className={styles.cls3} d="M54.6,224.55c-2.86-3.91-11.26-8.18-12-5.55-.83,2.94,8.02,4.94,12,5.55Z"/>
					<path className={styles.cls3}
					      d="M91.5,206.09c1.24-4.68-.74-13.9-3.24-12.82-2.8,1.21,1.22,9.34,3.24,12.82Z"/>
					<path className={styles.cls3}
					      d="M68.97,215.62c-1.72-4.28-5.58-10.94-7.85-8.93-2.53,2.24,4.13,6.17,7.85,8.93Z"/>
					<path className={styles.cls3}
					      d="M317.36,230.61c1.2-6.32,6.91-13.61,8.68-11.4,2.31,2.91-5.02,7.11-8.68,11.4Z"/>
					<path className={styles.cls3}
					      d="M344.15,245.69c9.84-5.55,10.24-6.84,6.4-9.82,14.49,0,3.18,9.82-6.4,9.82Z"/>
					<path className={styles.cls3}
					      d="M66.99,244.88c-6.66-2.77-7.58-6.57-1.16-6.06-4.01.93-1.64,3.76,1.16,6.06Z"/>
					<path className={styles.cls3}
					      d="M316.95,237.19c4.96-7.44,15.44-9.64,15.69-5.96.36,5.28-8.78,2.03-15.69,5.96Z"/>
					<path className={styles.cls3} d="M319.16,215.25c.73-5.59,3.17-11.9,5.77-10.78s-3.11,6.77-5.77,10.78Z"/>
				</g>
			</g>
		</svg>
	);
};