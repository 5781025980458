import React from "react";
import {
	MAIN_COLOR, SECONDARY_COLOR,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./KittyPoleVault.scss";


export default function KittyPoleVault(props){
	const teamColors = props.teamNumber ? TEAM_COLORS?.[props.teamNumber] : TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Pole_Vault_-_Kitty_-_Run"
			data-name="Pole Vault - Kitty - Run"
			// xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1600 300"
		>
		    <text className={styles.cls1} transform="translate(8.64 -11.27)"><tspan x="0" y="0">Ki</tspan><tspan className={styles.cls16} x="39.6" y="0">t</tspan><tspan x="54.86" y="0">ty </tspan><tspan className={styles.cls17} x="103.1" y="0">P</tspan><tspan x="129.12" y="0">ole </tspan><tspan className={styles.cls18} x="200.78" y="0">V</tspan><tspan x="224.45" y="0">ault</tspan></text>
		    <g id="Kitty_Vault_Run04">
		        <g id="Kitty_Vault_Run04_Lower">
					<path className={styles.cls9} d="M1247.02,286.56c0,4.66,28.56,8.44,63.8,8.44,35.24,0,63.8-3.78,63.8-8.44,0-4.66-28.56-8.45-63.8-8.45-35.24,0-63.8,3.78-63.8,8.45Z"/>
					<path className={styles.cls14} d="M1338.79,217.41c10.84,12.04,11.97,35.78-1.94,42.38-13.86,6.58-20.3,3.65-27.86,4.67,7.39,12.05-9.52,18.87-12.38,13.77-5.09-9.08-7.52-26.68,2.41-24.29,9.2,2.21,19.91-4.58,14.47-12.67-10.55-15.69,17.83-32.15,25.3-23.86Z"/>
					<path className={styles.cls14} d="M1319.57,169.77c25.08,10.47,61.28-11.62,53.08-22.52-7.69-10.24-26.25,15.18-49.14,6.57-7.8-2.93-14.14,11.7-3.94,15.96Z"/>
		            <g>
			            <path className={styles.cls11} d="M1253.73,220.29c4.49,4.91,15.88,6.08,22.28,6.81,3.7.42,8.82,1.18,12.49-.33,4.92-2.02,4.44-6.17-1.02-7.49-3.47-.84-6.59.22-10.21-.88-4.04-1.23-7.92-2.36-11.82-3.98-3.23-1.34-7.05-3.39-10.38-1.42-2.64,1.57-3.57,4.85-1.35,7.28Z"/>
			            <path className={styles.cls10} d="M1253.73,220.29c-2.22-2.43-1.29-5.71,1.35-7.28,3.32-1.98,7.14.07,10.38,1.42.04.02.09.04.13.05-1.69,1.92-3.04,4.18-3.46,6.59-.19,1.11-.13,2.37-.08,3.65-3.39-.99-6.45-2.38-8.32-4.42Z"/>
		            </g>
		            <path className={styles.cls6} d="M1310.3,243.46c-32.81,6.4-27.38-29.49-20.46-42.9,4.61-8.94-3.61-30.13,1.81-42.43,5.21-11.82,31.88-9.07,34.89-7.08,15.11,9.99,21.51,35.14,20.06,56.02-1.19,17.13-11.97,31.64-36.3,36.39Z"/>
		            <path className={styles.cls11} d="M1284.82,215.28c1.52-9.26,3.83-10.76,4.79-14.52,1.7-6.67,2.46-12.75.21-22.95,7.57,2.48,18.44,33.93,16.89,37.21-3.07,6.49-14.98,4.52-21.88.25Z"/>
                </g>
		        <path
			        id="Shirt"
			        className={styles.cls4} d="M1343.07,174.37c-.92-2.4-2.82-6.46-5.01-10.37,1.41,3.12,1.78,5.18-.51,6.05-3.17,1.2-6.8-3.27-9.39-6.98-7.08-10.15-28.34-18.49-35.34-11.5-7.2,7.2-1.7,33.06-1.7,40.77,0,6.01-2.41,10.51-3.98,13.84,15.4,9.39,39.09,8.64,59.77-.78,1.32-9.64-1.63-25.18-3.86-31.03ZM1310.21,182.26c-7.27,0-13.15-5.82-13.15-13s5.89-13,13.15-13,13.15,5.82,13.15,13-5.89,13-13.15,13Z"
			        style={{ fill: `${teamColors[MAIN_COLOR]}` }}
		        />
		        <g id="Stripes">
		            <path
			            className={styles.cls15}
			            d="M1334.68,201.36c-5.96,1.71-12,2.73-17.92,3.03-9.55.47-18.95-1.01-26.97-4.27.45-1.26.84-2.97,1-3.81,7.63,3.18,16.61,4.72,25.78,4.27,10.09-.5,20.53-3.24,30.31-7.92.09.61.31,3.19.31,4.07-4.09,1.88-8.28,3.43-12.51,4.64Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
			        <path
				        className={styles.cls15}
				        d="M1310.13,155.31c-7.78,0-14.11,6.26-14.11,13.95s6.33,13.95,14.11,13.95,14.11-6.26,14.11-13.95-6.33-13.95-14.11-13.95ZM1310.13,181.31c-6.73,0-12.2-5.4-12.2-12.04s5.47-12.04,12.2-12.04,12.2,5.4,12.2,12.04-5.47,12.04-12.2,12.04Z"
				        style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
			        />
			        <path
				        className={styles.cls15}
				        d="M1338.2,171.42c-4.53,0-9.28-4.73-10.89-6.85-.35-.47-.75-1.02-1.18-1.62-2.9-4.03-7.76-10.79-14.6-11.5l.2-1.9c7.69.81,12.86,7.99,15.95,12.29.42.59.81,1.12,1.15,1.58,1.68,2.2,5.74,5.95,9.2,6.07,1.65.06,1.14-2.88.12-5.36,3.75,4.15,2.16,7.31.06,7.3Z"
				        style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
			        />
		        </g>
		        <g id="Kitty_Vault_Run04_Mid">
		            <path className={styles.cls6} d="M1334.89,232.12c-1.11,25.03-18.71,38.61-37.97,43.17,6.88,1,9.64,7.23,6.76,10.8-1.2,1.49-17.94,1.41-19.5.1-5.16-4.35-7.44-16.5-5.26-17.66,14.79-7.89,15.65-26.38,16.43-33.1,1.42-12.23,40.12-16.42,39.54-3.32Z"/>
		            <g>
		                <g>
		                    <path className={styles.cls11} d="M1380.35,120.26c-9.7-.69-20.37.47-27.81,6.15-.37.28.14.85.51.57,7.28-5.56,17.77-6.68,27.27-6.01.48.03.52-.68.03-.71Z"/>
		                    <path className={styles.cls11} d="M1381.75,133.31c-9.68-2.11-19.32-2.79-28.81-1.22-.47.08-.3.79.17.71,9.43-1.55,19-.87,28.61,1.22.48.11.51-.61.03-.71Z"/>
		                    <path className={styles.cls11} d="M1378.91,142.03c-8.06-3.38-16.56-4.94-25.05-6.02-.49-.06-.52.65-.03.71,8.43,1.07,16.86,2.62,24.85,5.97.46.19.69-.47.23-.66Z"/>
		                </g>
		                <path className={styles.cls5} d="M1312.3,76.47c-.75,6.73-1.38,13.01-1.39,14.11-.03,2.29-.23,2.28,1.01,4.28,1.62,2.62,30.56,6.67,35.37,1.5-3.48-8.17-11.76-20.41-17.22-25.87-3.92-3.91-10.03-10.28-15.23-12.21-.35-.13-1.55,9.42-2.53,18.2Z"/>
		                <g>
		                    <path className={styles.cls6} d="M1287.61,111.92c37.69-13.74,22.79-25.4,14.45-29.85,3.17-.53,6.39-.83,9.55-.91,4.45,4.33,18.57,16.24,40.02,17.25,2.88,5.45,4.21,11.86,5.66,17.88,1.66,6.89,6.64,9.05,2.54,21.55-4.74,14.44-15.99,15.46-23.67,17.21-9.13,2.08-19.93,1.64-29.25.21-6.21-.95-17-4.91-22.06-8.89-10.45-8.22-13.71-20.19-14.83-30.29-.05-.49-.09-.98-.13-1.46,8.87-.28,17.72-2.7,17.72-2.7Z"/>
		                    <path className={styles.cls10} d="M1282.63,86.86c3.87-3.68,9.46-7.86,19.44-4.78,8.33,4.45,23.23,16.11-14.45,29.85,0,0-8.85,2.41-17.72,2.7-.81-12.2,5.15-20.54,12.74-27.76Z"/>
		                    <path className={styles.cls11} d="M1311.61,81.16c8.04-.22,15.7.94,21.25,3.3,6.25,2.65,14.69,6.86,18.21,12.93.19.33.38.67.56,1.01-21.44-1-35.56-12.92-40.02-17.25Z"/>
		                </g>
		                <path className={styles.cls7} d="M1325.59,113.71c1.22,6.01-3.48,11.51-10.51,12.29-7.03.78-13.72-3.47-14.94-9.48-1.22-6.01,3.48-11.51,10.52-12.29,7.03-.78,13.72,3.47,14.94,9.48Z"/>
		                <path className={styles.cls7} d="M1352.03,110.38c1.34,4.72-.26,8.84-3.56,9.19-3.3.35-7.06-3.19-8.4-7.91-1.34-4.72.26-8.84,3.56-9.19,3.3-.35,7.06,3.19,8.4,7.91Z"/>
		                <g>
		                    <path className={styles.cls11} d="M1296.88,133.07c9.22-2.55,19.81-3.64,28.37.02.42.18.06.79-.35.62-8.38-3.57-18.79-2.5-27.82,0-.46.13-.66-.51-.2-.63Z"/>
		                    <path className={styles.cls11} d="M1298.66,145.13c8.85-3.83,18.01-6.36,27.57-6.84.47-.02.48.65,0,.68-9.49.47-18.58,3-27.37,6.8-.44.19-.64-.45-.2-.64Z"/>
		                    <path className={styles.cls11} d="M1303.5,152.44c6.98-4.65,14.83-7.76,22.78-10.42.45-.15.66.48.2.64-7.89,2.65-15.67,5.72-22.6,10.34-.4.26-.78-.29-.38-.55Z"/>
		                </g>
		                <g>
		                    <path className={styles.cls13} d="M1323.01,113.74c1.14,5.39-.75,10.38-4.21,11.16-3.46.77-7.19-2.97-8.33-8.36-1.14-5.39.75-10.38,4.21-11.15,3.46-.77,7.19,2.97,8.33,8.36Z"/>
		                    <path className={styles.cls2} d="M1315.52,112.99c.57,2.7,2.41,4.58,4.12,4.2,1.7-.38,2.62-2.88,2.05-5.58-.57-2.7-2.41-4.59-4.12-4.21-1.7.38-2.62,2.88-2.05,5.58Z"/>
		                </g>
		                <g>
		                    <path className={styles.cls13} d="M1350.87,110.5c.9,4.51-.07,8.57-2.17,9.07-2.1.49-4.54-2.76-5.44-7.28-.9-4.51.07-8.57,2.16-9.07,2.1-.49,4.54,2.76,5.44,7.28Z"/>
		                    <path className={styles.cls2} d="M1346.23,109.56c.45,2.26,1.66,3.9,2.69,3.66,1.03-.24,1.5-2.28,1.05-4.54-.45-2.26-1.66-3.9-2.69-3.66-1.03.24-1.5,2.27-1.05,4.54Z"/>
		                </g>
	                    <path className={styles.cls11} d="M1340.99,137.82c.19.42.34.89.4,1.03.39.86.92,1.71,1.72,2.24,1.57,1.04,4.04,1.16,5.75.29,2.03-1.04,2.74-2.78,2.79-4.96.03-1.08.21-2.38-.06-3.43-.06-.24-.25-.52-.26-.76-.02-.3.53-.87.86-.8.66.14.66,1.08.66,1.6,0,.81.08,1.53.19,2.33.19,1.47.35,3.34,1.57,4.37.89.75,2.15.82,2.91-.13.39-.49.48-1.07.52-1.67.01-.17.06-1.33.43-1.29.64.07.29,1.41.23,1.78-.25,1.55-1.44,3.55-3.29,2.99-.76-.23-1.39-.82-1.87-1.43-.44-.57-1.07-1.21-1.05-1.99-.35,1.93-1.43,3.36-3.19,4.25-.78.4-1.67.62-2.59.68-2.15.13-4.06-.64-5.4-2.31-.37-.46-2.15-3.23-1.03-3.55.24-.07.49.33.69.77Z"/>
		                <path className={styles.cls8} d="M1353.74,124.78c-.83.1-1.67.4-2.58.59-1.45.32-3.78.21-4.95.94-2.81,1.74.28,4.35,2.82,5.42,2.87,1.22,8.51.91,8.74-3.28.08-1.42-.62-2.57-1.85-3.27-.75-.42-1.47-.5-2.19-.41Z"/>
		            </g>
		            <g>
		                <path className={styles.cls10} d="M1266.63,92.6c3.62,6.01,7.06,11.56,7.74,12.47,1.41,1.9,1.25,2.02,3.47,2.86,2.92,1.11,23.22-17.94,23.76-25.27-6.14-4.55-17.07-5.39-24.77-6.37-5.52-.7-14.31-2.01-19.59-.25-.35.12,4.68,8.73,9.4,16.56Z"/>
		                <path className={styles.cls8} d="M1293.42,88.16s-15.92-6.99-29.33-7.13l14.09,21.85,15.24-14.72Z"/>
		            </g>
		        </g>
		        <path
			        id="Stripe"
			        className={styles.cls15} d="M1272.29,123.71c19.79-17.85,57.68-28.27,79.68-23.82,1.69.34-4.58-11.73-10-13.45-13.99-4.45-63.32,8.11-72.27,25.75-1.33,2.62.73,13.19,2.59,11.52Z"
			        style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		        />
		        <g id="Kitty_Vault_Run04_Upper">
		            <g>
		                <path className={styles.cls3} d="M1276.85,216.84c-1.49,0-2.96-.72-3.86-2.05-1.44-2.13-.87-5.02,1.26-6.46L1545.73,25.27c2.13-1.44,5.02-.87,6.46,1.26,1.44,2.13.87,5.02-1.26,6.46l-271.48,183.06c-.8.54-1.7.8-2.6.8Z"/>
		                <rect
			                className={styles.cls15}
			                x="1305.31" y="182.31"
			                width="17.91"
			                height="9.3"
			                transform="translate(120.07 766.73) rotate(-33.99)"
		                />
		                <rect className={styles.cls12} x="1376.8" y="131.16" width="26.62" height="9.3" transform="translate(161.61 800.37) rotate(-33.99)"/>
		            </g>
		            <path className={styles.cls6} d="M1308.09,160.71c-13.14,2.14-28.26,13.75-28.74,21.58-.49,8.02,10.75,28.35,19.74,23.6,10.89-5.76-.78-15.42.23-18.85,1.03-3.49,8.82-7.62,12.73-9.1,7.79-2.96,6.95-19-3.96-17.23Z"/>
		        </g>
		    </g>
		    <g id="Kitty_Vault_Run03">
		        <g id="Kitty_Vault_Run03_Lower">
		            <path className={styles.cls9} d="M875.88,287.74c0,4.01,24.55,7.26,54.83,7.26,30.28,0,54.83-3.25,54.83-7.26,0-4.01-24.55-7.26-54.83-7.26-30.28,0-54.83,3.25-54.83,7.26Z"/>
		            <path className={styles.cls14} d="M923.66,224.04c-31.94,10.03-47.97-6.3-58.59-16.91-6.28,5.87-17.63,1.97-19.99-3.01-1.57-3.33,21.09-20.68,25.13-13.91,10.68,17.89,29.26,6.3,36.4.64,9.65-7.65,29.55,29.25,17.04,33.18Z"/>
		            <path className={styles.cls14} d="M921.58,151.49c25.08,10.47,61.28-11.62,53.08-22.52-7.69-10.24-26.25,15.18-49.14,6.57-7.8-2.93-14.14,11.7-3.94,15.96Z"/>
		            <g>
		                <path className={styles.cls11} d="M869.86,187.09c2.82,6.02,13.34,10.53,19.24,13.14,3.4,1.51,8.07,3.75,12.03,3.4,5.3-.46,6.08-4.57,1.26-7.46-3.06-1.84-6.36-1.75-9.48-3.88-3.49-2.37-6.86-4.61-10.1-7.32-2.69-2.24-5.72-5.34-9.48-4.44-2.99.71-4.85,3.57-3.46,6.55Z"/>
		                <path className={styles.cls10} d="M869.86,187.09c-1.4-2.99.47-5.84,3.46-6.55,3.76-.9,6.8,2.2,9.48,4.44.04.03.07.06.11.09-2.19,1.33-4.15,3.08-5.26,5.26-.52,1-.83,2.23-1.17,3.46-2.95-1.95-5.45-4.19-6.62-6.7Z"/>
		            </g>
		            <path className={styles.cls6} d="M923.65,226.16c-33.42,7.77-29.17-29.24-22.57-43.25,4.4-9.34-4.81-30.77.3-43.59,4.91-12.32,32.37-10.47,35.53-8.54,15.87,9.69,23.36,35.26,22.64,56.72-.59,17.62-11.11,32.9-35.9,38.66Z"/>
		            <path className={styles.cls11} d="M898.24,190.66c1.75-6.29,3.69-8.38,4.39-12.62,1.12-6.79.33-11.48-1.92-21.68,7.57,2.48,20.97,30.76,19.42,34.05-3.07,6.49-14.98,4.52-21.88.25Z"/>
		        </g>
	            <path
		            id="Shirt-2"
		            data-name="Shirt"
		            className={styles.cls4}
		            d="M954.72,154.08c-2.37-5.6-10.44-20.02-14.51-20.96,2.47,1.98,12.77,14.51,6.22,18.19-6.03,3.39-10.43-1.11-13.17-4.86-7.51-10.25-23.99-21.34-30.91-13.91-7.12,7.65-.52,33.98-.24,41.89.22,6.16-2.08,10.87-3.57,14.34,16.14,9.06,40.42,7.42,61.28-3.01,1-9.94-2.6-25.77-5.1-31.69ZM914.99,163.61c-7.45.27-13.71-5.49-13.97-12.85-.26-7.36,5.56-13.55,13.02-13.82s13.71,5.49,13.97,12.85-5.56,13.55-13.02,13.82Z"
		            style={{ fill: `${teamColors[MAIN_COLOR]}` }}
	            />
	            <g id="Stripes-2" data-name="Stripes">
		            <path
			            className={styles.cls15}
			            d="M947.11,182.08c-6.05,1.97-12.2,3.24-18.27,3.76-9.78.84-19.47-.33-27.82-3.38.42-1.31.75-3.07.88-3.95,7.94,2.98,17.21,4.23,26.61,3.43,10.33-.89,20.94-4.08,30.8-9.25.11.62.44,3.26.47,4.16-4.12,2.08-8.37,3.82-12.66,5.22Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
		            <path
			            className={styles.cls15}
			            d="M914,135.97c-7.98.29-14.24,6.94-13.96,14.83.28,7.89,7.01,14.08,14.99,13.79,7.98-.29,14.24-6.94,13.96-14.83-.28-7.89-7.01-14.08-14.99-13.79ZM914.95,162.64c-6.9.25-12.71-5.09-12.96-11.91-.24-6.81,5.17-12.56,12.07-12.8,6.9-.25,12.71,5.09,12.96,11.91.24,6.81-5.17,12.56-12.07,12.8Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
		            <path
			            className={styles.cls15}
			            d="M943.56,153.31c-4.65.17-9.69-4.51-11.43-6.63-.38-.47-.81-1.01-1.27-1.62-3.12-4.03-8.35-10.78-15.4-11.26l.13-1.95c7.92.54,13.49,7.73,16.81,12.02.46.59.87,1.12,1.24,1.58,2.26,2.76,8.47,7.88,12.19,5.08,1.08-.81,1.66-1.88,1.73-3.2.13-2.47-1.48-7.07-9.91-14.91l1.33-1.43c7.21,6.71,10.76,12.24,10.53,16.45-.1,1.89-.97,3.5-2.5,4.66-1.08.82-2.26,1.18-3.46,1.22Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
		        </g>
		        <g id="Kitty_Vault_Run03_Mid">
		            <path className={styles.cls14} d="M921.39,202.2c9.96-8.69,26.07-12.39,36.86-5.36-.25,1.14-.8,2.87-1.32,3.47-2.92,3.37-34.39,5.85-35.55,1.9Z"/>
		            <path className={styles.cls6} d="M917.83,203.95c3.69-4.49,24-13.28,37-7.5,11.14,4.96,24.88,20.39,28.85,33.07,6.99-9.4,15.29-3.69,16.08.1,1.43,6.89-9.98,17.35-19.78,17.17-5.7-7.13-39.03-24.45-56.7-20.54-18.02,3.99-11.37-15.11-5.45-22.3Z"/>
		            <g>
		                <g>
		                    <path className={styles.cls11} d="M981.03,79.49c-9.41,2.48-19.13,7.02-24.34,14.8-.26.38.41.76.67.38,5.1-7.62,14.66-12.07,23.87-14.5.47-.12.27-.81-.2-.68Z"/>
		                    <path className={styles.cls11} d="M986.58,91.39c-9.84,1.13-19.19,3.6-27.66,8.15-.42.22-.03.84.39.62,8.42-4.52,17.7-6.96,27.47-8.09.49-.06.29-.74-.2-.68Z"/>
		                    <path className={styles.cls11} d="M986.7,100.56c-8.72-.6-17.27.67-25.65,2.4-.48.1-.28.78.2.68,8.32-1.71,16.8-2.97,25.45-2.38.5.03.5-.67,0-.7Z"/>
		                </g>
		                <path className={styles.cls5} d="M902.49,60.04c1.46,6.62,2.9,12.76,3.24,13.8.71,2.17.52,2.23,2.34,3.72,2.38,1.96,31.07-3.56,33.95-10.01-5.93-6.61-17.73-15.52-24.66-18.92-4.97-2.44-12.81-6.49-18.36-6.64-.37,0,1.58,9.42,3.48,18.04Z"/>
		                <g>
		                    <path className={styles.cls6} d="M890.58,101.56c31.23-25.18,13.36-31.4,4.04-32.92,2.83-1.52,5.78-2.85,8.74-3.95,5.61,2.66,22.82,9.37,43.44,3.4,4.48,4.22,7.81,9.87,11.14,15.1,3.8,5.98,9.21,6.42,9.36,19.57.18,15.2-10.14,19.8-16.85,23.93-7.97,4.92-18.33,7.99-27.61,9.65-6.18,1.11-17.67.85-23.75-1.29-12.54-4.41-19.49-14.68-23.82-23.87-.21-.45-.4-.9-.59-1.34,8.31-3.14,15.9-8.28,15.9-8.28Z"/>
		                    <path className={styles.cls10} d="M877.76,79.45c2.48-4.74,6.41-10.5,16.85-10.81,9.32,1.52,27.19,7.74-4.04,32.92,0,0-7.6,5.14-15.9,8.28-4.71-11.28-1.76-21.1,3.09-30.39Z"/>
		                    <path className={styles.cls11} d="M903.35,64.69c7.54-2.8,15.16-4.18,21.18-3.74,6.77.49,16.12,1.75,21.41,6.36.29.25.58.51.86.78-20.62,5.98-37.83-.74-43.44-3.4Z"/>
		                </g>
		                <path className={styles.cls7} d="M927.1,90.99c3.1,5.29.42,12.02-5.98,15.03-6.4,3.01-14.11,1.15-17.2-4.14-3.1-5.29-.42-12.02,5.98-15.03,6.4-3.01,14.1-1.15,17.2,4.14Z"/>
		                <path className={styles.cls7} d="M951.04,79.29c2.79,4.04,2.61,8.45-.4,9.85-3.01,1.4-7.71-.74-10.5-4.77-2.79-4.04-2.61-8.45.4-9.85,3.01-1.4,7.71.74,10.5,4.77Z"/>
		                <g>
		                    <path className={styles.cls11} d="M906.18,118.58c7.9-5.4,17.58-9.84,26.86-9.15.45.03.32.73-.14.7-9.09-.67-18.59,3.71-26.32,8.99-.39.27-.79-.26-.4-.54Z"/>
		                    <path className={styles.cls11} d="M911.76,129.42c7.14-6.48,14.99-11.84,23.88-15.38.44-.17.67.46.23.64-8.83,3.51-16.62,8.84-23.71,15.28-.35.32-.75-.22-.4-.54Z"/>
		                    <path className={styles.cls11} d="M918.7,134.77c5.1-6.66,11.53-12.13,18.19-17.22.38-.29.78.24.4.54-6.62,5.05-12.98,10.47-18.05,17.08-.29.38-.83-.02-.54-.4Z"/>
		                </g>
		                <g>
		                    <path className={styles.cls13} d="M924.67,91.84c2.82,4.73,2.65,10.07-.38,11.92-3.03,1.85-7.76-.49-10.58-5.22-2.82-4.73-2.65-10.07.38-11.92,3.03-1.85,7.76.49,10.58,5.22Z"/>
		                    <path className={styles.cls2} d="M917.33,93.55c1.41,2.37,3.76,3.56,5.25,2.65,1.49-.91,1.55-3.57.14-5.94-1.41-2.37-3.76-3.56-5.25-2.65-1.49.91-1.55,3.57-.14,5.94Z"/>
		                </g>
		                <g>
		                    <path className={styles.cls13} d="M949.98,79.78c2.31,3.98,2.71,8.13.88,9.28-1.83,1.15-5.19-1.15-7.5-5.13-2.31-3.98-2.71-8.13-.88-9.28,1.83-1.15,5.19,1.15,7.5,5.13Z"/>
		                    <path className={styles.cls2} d="M945.29,80.39c1.16,1.99,2.83,3.16,3.73,2.59.9-.56.69-2.64-.47-4.63-1.16-1.99-2.83-3.15-3.73-2.59-.9.56-.69,2.64.47,4.63Z"/>
		                </g>
		                <path className={styles.cls11} d="M949.46,108.82c.32.34.61.73.71.84.65.69,1.42,1.32,2.35,1.56,1.82.47,4.2-.21,5.53-1.59,1.59-1.64,1.7-3.51,1.03-5.6-.32-1.03-.57-2.32-1.16-3.23-.13-.2-.4-.42-.49-.63-.12-.28.23-.99.55-1.04.67-.08.97.81,1.14,1.3.26.77.57,1.42.93,2.15.65,1.33,1.41,3.05,2.9,3.63,1.09.42,2.3.08,2.71-1.07.21-.59.11-1.16-.04-1.75-.04-.17-.38-1.28,0-1.36.63-.14.73,1.24.8,1.61.27,1.55-.22,3.82-2.15,3.89-.8.03-1.58-.33-2.23-.75-.6-.4-1.41-.8-1.64-1.54.29,1.94-.27,3.64-1.64,5.06-.61.63-1.38,1.13-2.23,1.48-2,.82-4.05.7-5.85-.45-.5-.32-3.08-2.36-2.12-3.03.21-.15.57.15.9.51Z"/>
		                <path className={styles.cls8} d="M957.32,92.36c-.75.36-1.45.91-2.25,1.4-1.27.77-3.51,1.42-4.38,2.49-2.09,2.56,1.67,4.02,4.42,4.22,3.11.23,8.35-1.89,7.21-5.93-.39-1.37-1.41-2.24-2.8-2.49-.85-.16-1.55,0-2.2.31Z"/>
		            </g>
		            <g>
		                <path className={styles.cls10} d="M864.48,90.05c5.37,4.52,10.42,8.66,11.35,9.3,1.95,1.34,1.83,1.51,4.21,1.59,3.12.11,16.18-24.48,14.32-31.59-7.28-2.32-17.9.41-25.5,1.97-5.45,1.12-14.19,2.72-18.62,6.09-.3.23,7.25,6.75,14.25,12.63Z"/>
		                <path className={styles.cls8} d="M888.4,77.19s-17.33-1.48-30.06,2.73l20.39,16.13,9.66-18.86Z"/>
		            </g>
		        </g>
		        <g id="Stripe-2" data-name="Stripe">
		            <path
			            className={styles.cls15}
			            d="M879.89,117.67c12.97-23.29,45.46-45.39,67.71-48.28,1.71-.22-8.13-9.62-13.81-9.5-14.68.31-57.31,28.13-60.07,47.71-.41,2.91,4.95,12.25,6.17,10.07Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
		        </g>
		        <g id="Kitty_Vault_Run03_Upper">
		            <g>
		                <path className={styles.cls3} d="M878.86,198.56c-1.49,0-2.96-.72-3.86-2.05-1.44-2.13-.87-5.02,1.26-6.46L1147.73,6.98c2.13-1.44,5.02-.87,6.46,1.26,1.44,2.13.87,5.02-1.26,6.46l-271.48,183.06c-.8.54-1.7.8-2.6.8Z"/>
		                <rect
			                className={styles.cls15}
			                x="907.32"
			                y="164.02"
			                width="17.91"
			                height="9.3"
			                transform="translate(62.28 541.09) rotate(-33.99)"
		                />
		                <rect className={styles.cls12} x="978.81" y="112.88" width="26.62" height="9.3" transform="translate(103.82 574.74) rotate(-33.99)"/>
		            </g>
		            <path className={styles.cls6} d="M910.09,142.43c-13.14,2.14-28.26,13.75-28.74,21.58-.49,8.02,10.75,28.35,19.74,23.6,10.89-5.76-.78-15.42.23-18.85,1.03-3.49,8.82-7.62,12.73-9.1,7.79-2.96,6.95-19-3.96-17.23Z"/>
	            </g>
		    </g>
		    <g id="Kitty_Vault_Run02">
		        <g id="Kitty_Vault_Run02_Lower">
		            <path className={styles.cls9} d="M453.61,286.56c0,4.66,28.56,8.44,63.8,8.44,35.24,0,63.8-3.78,63.8-8.44,0-4.66-28.56-8.45-63.8-8.45-35.24,0-63.8,3.78-63.8,8.45Z"/>
		            <path className={styles.cls14} d="M531.66,230.88c0,25.1-4.8,38.82-33.17,44.42,6.88,1,9.64,7.23,6.76,10.8-1.2,1.49-17.94,1.41-19.5.1-5.16-4.35-7.44-16.5-5.26-17.66,14.79-7.89,17.29-28.44,18.08-35.16,1.42-12.23,33.09-15.61,33.09-2.5Z"/>
		            <path className={styles.cls14} d="M512.98,171.9c25.08,10.47,61.28-11.62,53.08-22.52-7.69-10.24-26.25,15.18-49.14,6.57-7.8-2.93-14.14,11.7-3.94,15.96Z"/>
		            <g>
		                <path className={styles.cls11} d="M454.86,218.71c4.49,4.91,15.88,6.08,22.28,6.81,3.7.42,8.82,1.18,12.49-.33,4.92-2.02,4.44-6.17-1.02-7.49-3.47-.84-6.59.22-10.21-.88-4.04-1.23-7.92-2.36-11.82-3.98-3.23-1.34-7.05-3.39-10.38-1.42-2.64,1.57-3.57,4.85-1.35,7.28Z"/>
		                <path className={styles.cls10} d="M454.86,218.71c-2.22-2.43-1.29-5.71,1.35-7.28,3.32-1.98,7.14.07,10.38,1.42.04.02.09.04.13.05-1.69,1.92-3.04,4.18-3.46,6.59-.19,1.11-.13,2.37-.08,3.65-3.39-.99-6.45-2.38-8.32-4.42Z"/>
		            </g>
	                <path className={styles.cls6} d="M511.49,241.88c-32.81,6.4-27.38-29.49-20.46-42.9,4.61-8.94-3.61-30.13,1.81-42.43s31.88-9.07,34.89-7.08c15.11,9.99,21.51,35.14,20.06,56.02-1.19,17.13-11.97,31.64-36.3,36.39Z"/>
		            <path className={styles.cls11} d="M485.95,213.7c1.52-9.26,3.83-10.76,4.79-14.52,1.7-6.67,2.46-12.75.21-22.95,7.57,2.48,18.44,33.93,16.89,37.21-3.07,6.49-14.98,4.52-21.88.25Z"/>
		        </g>
		        <path
			        id="Shirt-3"
			        data-name="Shirt"
			        className={styles.cls4} d="M544.26,172.78c-2.11-5.53-9.46-19.85-13.4-20.92,2.33,2.02,11.93,14.57,5.42,17.93-5.99,3.09-10.29-1.33-12.65-5.19-5.36-8.76-22.61-21.62-29.61-14.62-7.2,7.2-1.7,33.06-1.7,40.77,0,6.01-2.41,10.51-3.98,13.84,15.4,9.39,39.09,8.64,59.77-.78,1.32-9.64-1.63-25.18-3.86-31.03ZM505.24,180.68c-7.27,0-13.15-5.82-13.15-13s5.89-13,13.15-13,13.15,5.82,13.15,13-5.89,13-13.15,13Z"
			        style={{ fill: `${teamColors[MAIN_COLOR]}` }}
		        />
	            <g id="Stripes-3" data-name="Stripes">
		            <path
			            className={styles.cls15}
			            d="M535.86,199.77c-5.96,1.71-12,2.73-17.92,3.03-9.55.47-18.95-1.01-26.97-4.27.45-1.26.84-2.97,1-3.81,7.63,3.18,16.61,4.72,25.78,4.27,10.09-.5,20.53-3.24,30.31-7.92.09.61.31,3.19.31,4.07-4.09,1.88-8.28,3.43-12.51,4.64Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
		            <path
			            className={styles.cls15}
			            d="M505.24,153.72c-7.78,0-14.11,6.26-14.11,13.95s6.33,13.95,14.11,13.95,14.11-6.26,14.11-13.95-6.33-13.95-14.11-13.95ZM505.24,179.72c-6.73,0-12.2-5.4-12.2-12.04s5.47-12.04,12.2-12.04,12.2,5.4,12.2,12.04-5.47,12.04-12.2,12.04Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
		            <path
			            className={styles.cls15}
			            d="M533.42,171.65c-4.53,0-9.28-4.73-10.89-6.85-.35-.47-.75-1.02-1.18-1.62-2.9-4.03-7.76-10.79-14.6-11.5l.2-1.9c7.69.81,12.86,7.99,15.95,12.29.42.59.81,1.12,1.15,1.58,2.11,2.77,7.97,7.96,11.69,5.37,1.08-.75,1.68-1.78,1.79-3.05.21-2.4-1.2-6.93-9.13-14.86l1.35-1.35c6.79,6.79,10.04,12.3,9.68,16.38-.16,1.84-1.06,3.38-2.6,4.45-1.08.76-2.24,1.07-3.41,1.07Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
		        </g>
		        <g id="Kitty_Vault_Run02_Mid">
		            <path className={styles.cls14} d="M539.45,227.04c-.51.64-1.33,1.52-2.47,2.66-2.66-.53-6.95-9.03-7.33-11.35,5.39,1.08,9.26,7.47,9.81,8.7Z"/>
		            <path className={styles.cls6} d="M531.49,219.5c12.65,11.82,12.95,37.6-1.04,44.02-13.94,6.41-21.78,1.73-29.34,2.65,7.24,12.14-9.76,18.75-12.55,13.62-4.98-9.15-7.18-26.77,2.72-24.26,9.17,2.32,20.43-3.57,11.03-12.97-13.37-13.37,21.03-30.68,29.19-23.06Z"/>
		            <g>
		                <g>
		                    <path className={styles.cls11} d="M583.52,120c-9.7-.69-20.37.47-27.81,6.15-.37.28.14.85.51.57,7.28-5.56,17.77-6.68,27.27-6.01.48.03.52-.68.03-.71Z"/>
		                    <path className={styles.cls11} d="M584.92,133.05c-9.68-2.11-19.32-2.79-28.81-1.22-.47.08-.3.79.17.71,9.43-1.55,19-.87,28.61,1.22.48.11.51-.61.03-.71Z"/>
		                    <path className={styles.cls11} d="M582.08,141.76c-8.06-3.38-16.56-4.94-25.05-6.02-.49-.06-.52.65-.03.71,8.43,1.07,16.86,2.62,24.85,5.97.46.19.69-.47.23-.66Z"/>
		                </g>
		                <path className={styles.cls5} d="M515.47,76.21c-.75,6.73-1.38,13.01-1.39,14.11-.03,2.29-.23,2.28,1.01,4.28,1.62,2.62,30.56,6.67,35.37,1.5-3.48-8.17-11.76-20.41-17.22-25.87-3.92-3.91-10.03-10.28-15.23-12.21-.35-.13-1.55,9.42-2.53,18.2Z"/>
		                <g>
		                    <path className={styles.cls6} d="M490.78,111.66c37.69-13.74,22.79-25.4,14.45-29.85,3.17-.53,6.39-.83,9.55-.91,4.45,4.33,18.57,16.24,40.02,17.25,2.88,5.45,4.21,11.86,5.66,17.88,1.66,6.89,6.64,9.05,2.54,21.55-4.74,14.44-15.99,15.46-23.67,17.21-9.13,2.08-19.93,1.64-29.25.21-6.21-.95-17-4.91-22.06-8.89-10.45-8.22-13.71-20.19-14.83-30.29-.05-.49-.09-.98-.13-1.46,8.87-.28,17.72-2.7,17.72-2.7Z"/>
		                    <path className={styles.cls10} d="M485.8,86.59c3.87-3.68,9.46-7.86,19.44-4.78,8.33,4.45,23.23,16.11-14.45,29.85,0,0-8.85,2.41-17.72,2.7-.81-12.2,5.15-20.54,12.74-27.76Z"/>
		                    <path className={styles.cls11} d="M514.78,80.89c8.04-.22,15.7.94,21.25,3.3,6.25,2.65,14.69,6.86,18.21,12.93.19.33.38.67.56,1.01-21.44-1-35.56-12.92-40.02-17.25Z"/>
		                </g>
		                <path className={styles.cls7} d="M528.76,113.45c1.22,6.01-3.48,11.51-10.51,12.29-7.03.78-13.72-3.47-14.94-9.48-1.22-6.01,3.48-11.51,10.52-12.29,7.03-.78,13.72,3.47,14.94,9.48Z"/>
		                <path className={styles.cls7} d="M555.2,110.11c1.34,4.72-.26,8.84-3.56,9.19-3.3.35-7.06-3.19-8.4-7.91-1.34-4.72.26-8.84,3.56-9.19,3.3-.35,7.06,3.19,8.4,7.91Z"/>
		                <g>
		                    <path className={styles.cls11} d="M500.05,132.8c9.22-2.55,19.81-3.64,28.37.02.42.18.06.79-.35.62-8.38-3.57-18.79-2.5-27.82,0-.46.13-.66-.51-.2-.63Z"/>
		                    <path className={styles.cls11} d="M501.83,144.87c8.85-3.83,18.01-6.36,27.57-6.84.47-.02.48.65,0,.68-9.49.47-18.58,3-27.37,6.8-.44.19-.64-.45-.2-.64Z"/>
		                    <path className={styles.cls11} d="M506.67,152.18c6.98-4.65,14.83-7.76,22.78-10.42.45-.15.66.48.2.64-7.89,2.65-15.67,5.72-22.6,10.34-.4.26-.78-.29-.38-.55Z"/>
		                </g>
		                <g>
		                    <path className={styles.cls13} d="M526.18,113.48c1.14,5.39-.75,10.38-4.21,11.16-3.46.77-7.19-2.97-8.33-8.36-1.14-5.39.75-10.38,4.21-11.15,3.46-.77,7.19,2.97,8.33,8.36Z"/>
		                    <path className={styles.cls2} d="M518.69,112.73c.57,2.7,2.41,4.58,4.12,4.2,1.7-.38,2.62-2.88,2.05-5.58-.57-2.7-2.41-4.59-4.12-4.21-1.7.38-2.62,2.88-2.05,5.58Z"/>
		                </g>
		                <g>
		                    <path className={styles.cls13} d="M554.04,110.23c.9,4.51-.07,8.57-2.17,9.07-2.1.49-4.54-2.76-5.44-7.28-.9-4.51.07-8.57,2.16-9.07,2.1-.49,4.54,2.76,5.44,7.28Z"/>
		                    <path className={styles.cls2} d="M549.4,109.3c.45,2.26,1.66,3.9,2.69,3.66,1.03-.24,1.5-2.28,1.05-4.54-.45-2.26-1.66-3.9-2.69-3.66-1.03.24-1.5,2.27-1.05,4.54Z"/>
		                </g>
	                    <path className={styles.cls11} d="M544.16,137.55c.19.42.34.89.4,1.03.39.86.92,1.71,1.72,2.24,1.57,1.04,4.04,1.16,5.75.29,2.03-1.04,2.74-2.78,2.79-4.96.03-1.08.21-2.38-.06-3.43-.06-.24-.25-.52-.26-.76-.02-.3.53-.87.86-.8.66.14.66,1.08.66,1.6,0,.81.08,1.53.19,2.33.19,1.47.35,3.34,1.57,4.37.89.75,2.15.82,2.91-.13.39-.49.48-1.07.52-1.67.01-.17.06-1.33.43-1.29.64.07.29,1.41.23,1.78-.25,1.55-1.44,3.55-3.29,2.99-.76-.23-1.39-.82-1.87-1.43-.44-.57-1.07-1.21-1.05-1.99-.35,1.93-1.43,3.36-3.19,4.25-.78.4-1.67.62-2.59.68-2.15.13-4.06-.64-5.4-2.31-.37-.46-2.15-3.23-1.03-3.55.24-.07.49.33.69.77Z"/>
		                <path className={styles.cls8} d="M556.91,124.51c-.83.1-1.67.4-2.58.59-1.45.32-3.78.21-4.95.94-2.81,1.74.28,4.35,2.82,5.42,2.87,1.22,8.51.91,8.74-3.28.08-1.42-.62-2.57-1.85-3.27-.75-.42-1.47-.5-2.19-.41Z"/>
		            </g>
		            <g>
		                <path className={styles.cls10} d="M469.8,92.34c3.62,6.01,7.06,11.56,7.74,12.47,1.41,1.9,1.25,2.02,3.47,2.86,2.92,1.11,23.22-17.94,23.76-25.27-6.14-4.55-17.07-5.39-24.77-6.37-5.52-.7-14.31-2.01-19.59-.25-.35.12,4.68,8.73,9.4,16.56Z"/>
		                <path className={styles.cls8} d="M496.59,87.89s-15.92-6.99-29.33-7.13l14.09,21.85,15.24-14.72Z"/>
		            </g>
	            </g>
		        <g id="Stripe-3" data-name="Stripe">
		            <path
			            className={styles.cls15}
			            d="M475.46,123.45c19.79-17.85,57.68-28.27,79.68-23.82,1.69.34-4.58-11.73-10-13.45-13.99-4.45-63.32,8.11-72.27,25.75-1.33,2.62.73,13.19,2.59,11.52Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
		        </g>
		        <g id="Kitty_Vault_Run02_Upper">
		            <g>
		                <path className={styles.cls3} d="M470.26,218.97c-1.49,0-2.96-.72-3.86-2.05-1.44-2.13-.87-5.02,1.26-6.46L739.13,27.4c2.13-1.44,5.02-.87,6.46,1.26,1.44,2.13.87,5.02-1.26,6.46l-271.48,183.06c-.8.54-1.7.8-2.6.8Z"/>
		                <rect
			                className={styles.cls15}
			                x="498.72"
			                y="184.43"
			                width="17.91"
			                height="9.3"
			                transform="translate(-18.96 316.14) rotate(-33.99)"
		                />
		                <rect className={styles.cls12} x="570.21" y="133.29" width="26.62" height="9.3" transform="translate(22.59 349.79) rotate(-33.99)"/>
		            </g>
		            <path className={styles.cls6} d="M501.5,162.84c-13.14,2.14-28.26,13.75-28.74,21.58-.49,8.02,10.75,28.35,19.74,23.6,10.89-5.76-.78-15.42.23-18.85,1.03-3.49,8.82-7.62,12.73-9.1,7.79-2.96,6.95-19-3.96-17.23Z"/>
		        </g>
		    </g>
		    <g id="Kitty_Vault_Run01">
		        <g id="Kitty_Vault_Run01_Lower">
		            <path className={styles.cls9} d="M54.96,287.74c0,4.01,24.55,7.26,54.83,7.26,30.28,0,54.83-3.25,54.83-7.26,0-4.01-24.55-7.26-54.83-7.26-30.28,0-54.83,3.25-54.83,7.26Z"/>
		            <path className={styles.cls14} d="M115.88,201.58c4.26-3.73,25.68-8.83,37.25-1.19,12.33,8.15,19.88,24.21,22.64,31.32,8.21-7.93,13.45-3.96,14.65-.11,1.31,4.23-12.78,19.44-19.59,18.68-4.58-9.61-28.75-25.04-46.22-25.04-19.95,0-15.55-17.69-8.72-23.65Z"/>
		            <path className={styles.cls14} d="M128.61,151.49c25.08,10.47,61.28-11.62,53.08-22.52-7.69-10.24-26.25,15.18-49.14,6.57-7.8-2.93-14.14,11.7-3.94,15.96Z"/>
		            <g>
		                <path className={styles.cls11} d="M66.48,182.71c2.3,6.24,12.4,11.63,18.06,14.72,3.26,1.79,7.72,4.42,11.69,4.41,5.32-.01,6.44-4.04,1.89-7.32-2.9-2.09-6.18-2.29-9.12-4.67-3.27-2.66-6.44-5.17-9.45-8.15-2.49-2.46-5.25-5.81-9.07-5.23-3.04.46-5.14,3.14-4,6.24Z"/>
		                <path className={styles.cls10} d="M66.48,182.71c-1.14-3.09.96-5.78,4-6.24,3.83-.58,6.59,2.77,9.07,5.23.04.03.07.07.1.1-2.29,1.14-4.39,2.72-5.69,4.79-.6.96-1.01,2.15-1.46,3.35-2.77-2.19-5.07-4.64-6.03-7.23Z"/>
		            </g>
		            <path className={styles.cls6} d="M116.87,226.46c-32.81,6.4-27.38-29.49-20.46-42.9,4.61-8.94-3.61-30.13,1.81-42.43s31.88-9.07,34.89-7.08c15.11,9.99,21.51,35.14,20.06,56.02-1.19,17.13-11.97,31.64-36.3,36.39Z"/>
		            <path className={styles.cls11} d="M93.16,192.13c1.4-5.86,3.44-8.28,4.42-12.39,4.39-18.45-1.07-31.24-.19-36.28,12.37-2.04,16.32,42.85,14.79,46.08-3.04,6.38-12.09,6.83-19.01,2.6Z"/>
		        </g>
	            <path
		            id="Shirt-4"
		            data-name="Shirt"
		            className={styles.cls4} d="M149.64,157.36c-.54-1.42-1.43-3.41-2.51-5.61-2.64,0-6.11-1.54-11.56-8.79-11.27-15.01-29.17-15.39-36.17-8.4-7.2,7.2-1.7,33.06-1.7,40.77,0,6.01-2.41,10.51-3.98,13.84,15.4,9.39,39.09,8.64,59.77-.78,1.32-9.64-1.63-25.18-3.86-31.03ZM121.97,165.25c-7.27,0-13.15-5.82-13.15-13s5.89-13,13.15-13,13.15,5.82,13.15,13-5.89,13-13.15,13Z"
		            style={{ fill: `${teamColors[MAIN_COLOR]}` }}
	            />
		        <g id="Stripes-4" data-name="Stripes">
		            <path
			            className={styles.cls15}
			            d="M141.25,184.35c-5.96,1.71-12,2.73-17.92,3.03-9.55.47-18.95-1.01-26.97-4.27.45-1.26.84-2.97,1-3.81,7.63,3.18,16.61,4.72,25.78,4.27,10.09-.5,20.53-3.24,30.31-7.92.09.61.31,3.19.31,4.07-4.09,1.88-8.28,3.43-12.51,4.64Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
		            <path
			            className={styles.cls15}
			            d="M122.27,138.46c-7.78,0-14.11,6.26-14.11,13.95s6.33,13.95,14.11,13.95,14.11-6.26,14.11-13.95-6.33-13.95-14.11-13.95ZM122.27,164.45c-6.73,0-12.2-5.4-12.2-12.04s5.47-12.04,12.2-12.04,12.2,5.4,12.2,12.04-5.47,12.04-12.2,12.04Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
		            <path
			            className={styles.cls15}
			            d="M147.86,153.26c-4.53,0-9.42-4.75-11.04-6.87-.35-.47-.75-1.02-1.18-1.62-2.9-4.03-7.76-10.79-14.6-11.5l.2-1.9c7.69.81,12.86,7.99,15.95,12.29.42.59.81,1.12,1.15,1.58,1.68,2.2,5.11,5.96,8.58,6.09.34.58.83,1.72.94,1.94Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
		        </g>
		        <g id="Kitty_Vault_Run01_Upper">
		            <path className={styles.cls6} d="M116.63,226.18c-31.94,10.03-47.97-6.3-58.59-16.91-6.28,5.87-17.63,1.97-19.99-3.01-1.57-3.33,21.09-20.68,25.13-13.91,10.68,17.89,25.78,8.08,33.18,2.78,6.92-4.96,32.76,27.11,20.25,31.04Z"/>
		            <g>
		                <path className={styles.cls3} d="M85.9,198.56c-1.49,0-2.96-.72-3.86-2.05-1.44-2.13-.87-5.02,1.26-6.46L354.77,6.98c2.13-1.44,5.02-.87,6.46,1.26,1.44,2.13.87,5.02-1.26,6.46L88.49,197.76c-.8.54-1.7.8-2.6.8Z"/>
		                <rect
			                className={styles.cls15}
			                x="114.35"
			                y="164.02"
			                width="17.91"
			                height="9.3"
			                transform="translate(-73.23 97.76) rotate(-33.99)"
		                />
		                <rect className={styles.cls12} x="185.84" y="112.88" width="26.62" height="9.3" transform="translate(-31.68 131.42) rotate(-33.99)"/>
		            </g>
		            <path className={styles.cls6} d="M117.13,142.43c-13.14,2.14-28.26,13.75-28.74,21.58-.49,8.02,10.75,28.35,19.74,23.6,10.89-5.76-.78-15.42.23-18.85,1.03-3.49,8.82-7.62,12.73-9.1,7.79-2.96,6.95-19-3.96-17.23Z"/>
		            <g>
		                <g>
		                    <path className={styles.cls11} d="M181.91,79.27c-9.29,2.88-18.81,7.83-23.68,15.83-.24.39.44.74.68.35,4.77-7.83,14.13-12.68,23.23-15.5.46-.14.24-.82-.23-.67Z"/>
		                    <path className={styles.cls11} d="M187.96,90.92c-9.78,1.55-19.02,4.42-27.29,9.32-.41.24,0,.84.42.6,8.22-4.87,17.39-7.71,27.1-9.25.49-.08.25-.75-.23-.67Z"/>
		                    <path className={styles.cls11} d="M188.48,100.07c-8.73-.22-17.22,1.41-25.53,3.49-.47.12-.25.79.23.67,8.24-2.06,16.66-3.68,25.32-3.46.5.01.47-.69-.02-.7Z"/>
		                </g>
		                <path className={styles.cls5} d="M102.61,63.17c1.74,6.55,3.44,12.62,3.83,13.65.81,2.14.62,2.21,2.49,3.62,2.46,1.86,30.89-4.88,33.5-11.44-6.21-6.35-18.37-14.75-25.44-17.85-5.07-2.22-13.08-5.94-18.62-5.85-.37,0,1.97,9.34,4.25,17.87Z"/>
		                <g>
		                    <path className={styles.cls6} d="M92.48,105.16c30.13-26.49,12.01-31.94,2.63-33.06,2.76-1.64,5.65-3.09,8.56-4.32,5.72,2.42,23.2,8.39,43.55,1.54,4.66,4.03,8.23,9.53,11.77,14.61,4.05,5.82,9.47,6.02,10.19,19.16.83,15.18-9.29,20.21-15.81,24.63-7.75,5.25-17.97,8.76-27.18,10.82-6.13,1.37-17.62,1.6-23.78-.28-12.72-3.87-20.1-13.84-24.81-22.84-.23-.44-.44-.88-.65-1.32,8.17-3.49,15.54-8.95,15.54-8.95Z"/>
		                    <path className={styles.cls10} d="M78.74,83.62c2.27-4.84,5.96-10.76,16.37-11.51,9.38,1.12,27.5,6.57-2.63,33.06,0,0-7.37,5.46-15.54,8.95-5.18-11.07-2.66-21.01,1.79-30.5Z"/>
		                    <path className={styles.cls11} d="M103.67,67.79c7.41-3.12,14.97-4.82,21-4.64,6.78.2,16.18,1.06,21.66,5.44.3.24.6.49.89.74-20.34,6.85-37.83.88-43.55-1.54Z"/>
		                </g>
		                <path className={styles.cls7} d="M128.51,93.05c3.32,5.16.93,11.99-5.34,15.27-6.27,3.28-14.04,1.75-17.36-3.41-3.32-5.16-.93-11.99,5.34-15.27,6.27-3.28,14.04-1.75,17.36,3.41Z"/>
		                <path className={styles.cls7} d="M151.94,80.34c2.96,3.92,2.97,8.33.02,9.86-2.95,1.53-7.74-.41-10.7-4.32-2.96-3.91-2.97-8.33-.02-9.86,2.95-1.53,7.74.41,10.69,4.32Z"/>
		                <g>
		                    <path className={styles.cls11} d="M108.79,121.5c7.66-5.73,17.14-10.58,26.44-10.29.45.01.35.72-.11.7-9.11-.29-18.42,4.5-25.92,10.1-.38.28-.8-.23-.42-.52Z"/>
		                    <path className={styles.cls11} d="M114.83,132.1c6.86-6.78,14.47-12.47,23.21-16.38.43-.19.69.43.25.63-8.67,3.89-16.23,9.54-23.04,16.27-.34.34-.76-.18-.42-.52Z"/>
		                    <path className={styles.cls11} d="M121.99,137.15c4.81-6.87,11-12.61,17.44-17.98.37-.31.79.21.42.52-6.39,5.33-12.53,11.02-17.3,17.84-.27.39-.83.02-.56-.37Z"/>
		                </g>
		                <g>
		                    <path className={styles.cls13} d="M126.12,94.01c3.02,4.61,3.07,9.95.13,11.92-2.94,1.98-7.78-.16-10.79-4.77-3.02-4.61-3.07-9.95-.13-11.92,2.94-1.97,7.78.16,10.79,4.77Z"/>
		                    <path className={styles.cls2} d="M118.87,96.03c1.51,2.31,3.91,3.4,5.36,2.42,1.45-.97,1.4-3.63-.11-5.94-1.51-2.31-3.91-3.4-5.36-2.42-1.45.97-1.4,3.63.12,5.94Z"/>
		                </g>
		                <g>
		                    <path className={styles.cls13} d="M150.9,80.87c2.48,3.88,3.05,8.01,1.27,9.23-1.78,1.22-5.23-.93-7.71-4.8-2.48-3.88-3.05-8.01-1.27-9.23,1.78-1.22,5.23.93,7.71,4.8Z"/>
		                    <path className={styles.cls2} d="M146.24,81.68c1.24,1.94,2.96,3.03,3.83,2.43.88-.6.58-2.67-.67-4.61-1.24-1.94-2.96-3.03-3.84-2.43-.88.6-.58,2.67.67,4.61Z"/>
		                </g>
		                <path className={styles.cls11} d="M151.62,109.91c.33.33.64.71.75.81.68.66,1.48,1.26,2.42,1.46,1.84.4,4.19-.39,5.46-1.82,1.52-1.71,1.54-3.58.8-5.63-.37-1.02-.67-2.29-1.3-3.17-.14-.2-.42-.4-.52-.61-.13-.28.18-1,.51-1.06.67-.11,1,.77,1.19,1.25.29.76.63,1.39,1.02,2.11.71,1.3,1.54,2.99,3.05,3.5,1.1.38,2.3-.01,2.67-1.18.19-.6.06-1.16-.12-1.75-.05-.16-.43-1.26-.06-1.36.62-.17.79,1.21.87,1.58.33,1.54-.05,3.83-1.98,3.98-.8.06-1.59-.26-2.26-.66-.62-.37-1.44-.74-1.7-1.47.38,1.93-.11,3.64-1.43,5.12-.58.65-1.33,1.19-2.17,1.57-1.96.9-4.01.87-5.87-.2-.51-.3-3.18-2.23-2.25-2.94.2-.15.57.13.92.47Z"/>
		                <path className={styles.cls8} d="M158.76,93.14c-.74.39-1.41.97-2.19,1.49-1.24.82-3.44,1.57-4.27,2.67-1.98,2.64,1.84,3.95,4.6,4.03,3.12.1,8.26-2.24,6.95-6.23-.44-1.35-1.51-2.17-2.91-2.37-.86-.12-1.55.07-2.19.41Z"/>
		            </g>
		            <g>
		                <path className={styles.cls10} d="M65.91,94.78c5.56,4.28,10.78,8.21,11.74,8.81,2,1.25,1.9,1.43,4.27,1.41,3.12-.02,15.12-25.15,12.96-32.17-7.38-2.01-17.86,1.17-25.39,3.05-5.4,1.35-14.06,3.32-18.35,6.88-.29.24,7.53,6.43,14.77,12.02Z"/>
		                <path className={styles.cls8} d="M89.26,80.91s-17.38-.74-29.91,4l21.06,15.25,8.85-19.25Z"/>
		            </g>
	            </g>
	            <g id="Stripe-4" data-name="Stripe">
		            <path
			            className={styles.cls15}
			            d="M82.48,121.71c11.96-23.82,43.48-47.28,65.6-51.12,1.69-.29-8.53-9.26-14.2-8.9-14.65.94-56.06,30.54-57.99,50.23-.29,2.93,5.47,12.03,6.59,9.79Z"
			            style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
		            />
		        </g>
		    </g>
		</svg>
	);
};