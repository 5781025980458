import React from "react";
import AdminBase from "components/layout/AdminBase";
import ReprocessCouponsTable from "components/admin/widgets/ReprocessCouponsTable";
import * as SearchTypes from "constants/SearchTypes";
import * as AdminControls from "constants/AdminControls";
import {connect} from "react-redux";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";

function CouponReprocessing(props) {
    return (
        <AdminBase
            pageTitle="Coupon Reprocessing"
            collapsible
            searchConfig={SearchTypes.REPROCESSING_JOBS_SEARCH}
            controls={AdminControls.REPROCESSING_JOBS}
        >
            <div className="margin-bottom-md">
                {props.canViewCouponReprocessing ? (
                    <ReprocessCouponsTable />
                ) : (
                    <AccessDenied/>
                )}
            </div>
        </AdminBase>
    )
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewCouponReprocessing = userHasPermission(PermissionTypes.VIEW, UserPermissions.COUPON_REPROCESSING, userProfile);
        return {
            canViewCouponReprocessing,
        }
    }
)(CouponReprocessing);
