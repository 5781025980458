import React from "react";
import {
	MAIN_COLOR,
	SECONDARY_COLOR,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./PuppyPodiumLeft.scss";


export default function PuppyPodiumLeft(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Podium_-_Left_-_Puppy"
			data-name="Podium - Left - Puppy"
			// xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 800 300"
		>
			<g id="Puppy_Winner_Bronze">
				<g id="Puppy_Winner_Bronze_Lower">
					<g>
						<path className={styles.cls9}
						      d="M144.43,262.58c2.17,1.79,4.46,3.46,6.91,4.93,2.94,1.76,22.37,7.19,17.07,12.76-.8.84-1.98,1.31-3.17,1.37-4.01.21-8.1.32-11.92-.51-6.3-1.37-11.24-5.14-15.13-9.75.21-1.62.74-3.19,1.6-4.58,1.11-1.8,2.75-3.27,4.63-4.22Z"/>
						<path className={styles.cls7}
						      d="M131.92,253.37c.47-.09,1.05-.07,1.77.08,3.35.72,5.76,4.74,8.09,6.85.86.78,1.75,1.54,2.65,2.28-1.89.95-3.52,2.42-4.63,4.22-.86,1.39-1.39,2.96-1.6,4.58-1.72-2.04-3.24-4.25-4.58-6.49-.94-1.57-5.75-10.73-1.7-11.53Z"/>
					</g>
					<path className={styles.cls8}
					      d="M186.14,271.62c-.1-.65-.15-1.31-.14-1.97.04-3.33,1.37-6.61,3.14-9.65,4.03-6.94,11.01-14.14,19.68-17.15,7.85-2.72,24.41-4.07,29.97,1.91.96,1.03,1.55,2.21,2,3.41,3.32,8.91-2.05,19.15-12.82,24.42,4.23,1.07,9.3,2.43,9.31,7.79,0,7.44-25.6,7.2-26.25.2-.28-2.94-.16-2.74.29-5.44-2.37,3.34-3.94,3.38-8.24,4.73s-9.38.99-12.77-1.41c-2.37-1.68-3.73-4.19-4.16-6.84Z"/>
					<path className={styles.cls8}
					      d="M258.56,211.09c-1.02.54-2.29.92-3.84,1.1-2.16.25-4.34-.23-6.25-1.2-2-1.02-3.2-3.47-5.09-4.33-1.95-.89-4.58,1.46-6.31,2.43-2.37,1.33-4.77,2.66-7.38,3.54-6,2.01-13.24,3.02-16.71-2.95-4.52-7.78-5.53-19.37-6.89-27.97-.39-2.46-.72-4.99-.22-7.44,2.24-10.98,15.39-4.37,18.69,2.28,2.28,4.6,4.07,18.86,4.57,19.1.48.23,11.03-8.02,15.98-9.13,4.95-1.11,8.79.17,11.76,4.17,3.94,5.31,8.71,16.65,1.69,20.39Z"/>
					<g>
						<path className={styles.cls7}
						      d="M199.95,268.93c9.03-.31,40.13-31.15,33.92-76.73-1.12-8.22-1.61-17.44-4.93-26.68-5.54-2.92-17.26-3.31-25.67-4.54-5.72-.84-18.6-3.22-23.64,1-4.79,4.01-12.19,15.63-12.19,24.03,0,5.84,2.94,12.49,2.16,17.89-3.36,23.43-12.55,42.07-14.89,49.96-7.18,24.27,38.6,15.3,45.23,15.07Z"/>
						<path className={styles.cls8}
						      d="M174.43,248.91c-1.39-15.17,26.07-20.24,32.57-8.68,15.96-10.97,22.62-30.61,22.9-51.41.04-2.86-.65-17.54-2.16-21.35-5.24,8.72-32.22,8.51-42.07.43,1.08-3.35,40.63-5.21,43.45-3.5,19.96,37.63-7.75,106.65-27.13,106.32-5.43-.09-26.81-13.62-27.56-21.82Z"/>
					</g>
				</g>
				<path
					id="Shirt"
					className={styles.cls2}
					d="M235.42,196.54c-.67-7.79-.75-31.82-7.23-36.1l-2.76,1.93c4.78,7,6.44,26.48-2.11,29.93-7.88,3.17-12.51-3-15.26-8-7.51-13.66-25.82-29.77-35.41-21.82-9.88,8.18-6.02,41.26-6.93,50.93-.71,7.53-4.26,12.9-6.61,16.89,18.21,13.58,43.99,15.43,71.02,6.05,4.88-14.41,5.96-31.96,5.29-39.82ZM183.1,202.3c-9.11-.85-15.81-8.85-14.97-17.85.84-9,8.91-15.61,18.03-14.75s15.81,8.85,14.97,17.85c-.84,9-8.91,15.61-18.03,14.75Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripe">
					<path
						className={styles.cls11}
						d="M218.58,194.61c-6.6,0-10.78-6.29-15.57-13.51-5.31-8-11.34-17.07-22.11-19.83l.61-2.4c11.69,3,17.99,12.49,23.56,20.86,4.84,7.29,8.67,13.05,14.53,12.35,3.13-.38,5.39-1.81,6.92-4.39,3.71-6.23,2-17.57-.4-23.63l2.3-.91c2.4,6.08,4.59,18.47.22,25.81-1.92,3.23-4.87,5.11-8.75,5.58-.45.05-.89.08-1.31.08Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M219.26,229.85c-7.68,1.44-15.36,2.01-22.83,1.69-12.03-.53-23.64-3.49-33.32-8.52.72-1.53,1.4-3.62,1.7-4.66,9.19,4.89,20.27,7.87,31.83,8.38,12.71.56,24.43-1.01,37.24-5.74.04.78-.61,3.88-.71,4.98-5.35,1.87-8.46,2.86-13.91,3.88Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M186.27,168.5c-9.76-.92-18.43,6.19-19.33,15.84-.9,9.65,6.3,18.24,16.05,19.16,9.76.91,18.43-6.19,19.33-15.84.9-9.65-6.3-18.24-16.05-19.16ZM183.22,201.1c-8.44-.79-14.66-8.21-13.88-16.54.78-8.33,8.28-14.46,16.72-13.67,8.44.79,14.66,8.21,13.88,16.54-.78,8.33-8.28,14.46-16.72,13.67Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Winner_Bronze_Mid">
					<path className={styles.cls6}
					      d="M173,147.68c-1.89,2.35-.56,8.55.34,11.39.86,2.71,4.07,8.88,19.91,12.84,15.85,3.96,34.25-2.76,35.81-7.28,4.09-11.86-7.37-16.94-17.26-20.7-9.31-3.54-23.87-7.61-32.64-1.21-1.89,1.38-4.7,3.13-6.16,4.95Z"/>
					<g>
						<path className={styles.cls10}
						      d="M172.65,162.49c7.74,7.86,41.39,18.1,50.63,15.71,5.54-4,10.04-12.03,9.83-16.08-.11-2.17-.41-3.33-3.3-3.7.03,6.19-4.71,15.5-6.77,18.39-24.89-3.2-46.28-17.87-49.03-17.27-1.3.29-1.62,2.08-1.35,2.95Z"/>
						<g>
							<circle className={styles.cls5} cx="224.77" cy="192.36" r="11.43"/>
							<ellipse className={styles.cls1} cx="222.95" cy="178.68" rx="1.37" ry="2.66"
							         transform="translate(-21.7 31.1) rotate(-7.61)"/>
							<circle className={styles.cls1} cx="225.72" cy="191.01" r="11.43"/>
						</g>
					</g>
					<path className={styles.cls7}
					      d="M214.26,231.41c-1.14.18-2.46.12-3.99-.22-2.12-.47-4.02-1.64-5.51-3.19-1.56-1.62-1.89-4.33-3.39-5.76-1.55-1.48-4.81-.12-6.76.23-2.68.48-5.38.95-8.13.92-6.33-.07-14.41-2.19-15.73-8.97-1.72-8.83-1.92-21.42-.38-29.99,1.38-7.67,23.12-16.38,25.55,2.74.65,5.09-3.74,17.68-3.35,18.07.37.38,12-2.03,17.03-1.46,5.04.58,8.25,3.05,9.74,7.8,1.98,6.31,2.76,18.59-5.09,19.81Z"/>
					<path className={styles.cls9}
					      d="M162.38,261.99c2.68,4.79,3.99,10.38,3.41,15.83-.24,2.27-.9,4.62-2.11,6.52-.45-.11-.94-.1-1.37-.26-13.02-2.03-13.36-16.86-6.54-33.32,2.58,2.32,4.92,8.21,6.61,11.23Z"/>
					<g>
						<path className={styles.cls9}
						      d="M235.91,46.84c4.9,4.43,16.95,27.09,11.19,31.72-9.03,7.27-48.58,4.01-52.45,4.03-4.86.03-9.79-1.73-11.88-4.99-3.4-5.32,1.62-14.16,5.74-18.55,16-17.01,31.93-26.21,47.39-12.21Z"/>
						<g>
							<path className={styles.cls7}
							      d="M236.64,154.51c-3.81-4.86-5.96-11.01-6.01-17.18-.05-6.37,2.04-12.92.14-19-2.58-8.28-11.46-12.65-19.61-15.64-12.36-4.54-25.1-8.08-38.03-10.57-3.3-.63-6.71-1.19-10.08-1.31,4.63-5.27,10.11-9.86,16.12-13.9.85.29,1.74.48,2.58.62,5.45.9,11,1.32,16.29,2.95,11.09,3.42,20.08,11.78,29.67,18.08,4.5,2.95,9.8,5.44,15,4.44,5.18-.99,9.02-5.17,13.55-7.83,2.84-1.67,6.03-2.67,9.3-3.07,4.74,1.74,9.15,4.07,11.51,7.57,5.94,8.82,7.85,21.9,3.99,29.95-6.52,13.62-15.14,19.69-25.13,26.13-4.28,2.75-8.78,4.84-13.38,6.4-1.51-2.7-3.98-5.15-5.92-7.62Z"/>
							<path className={styles.cls4}
							      d="M168.72,152.37c-4.81-3.85-9.31-8.17-13.31-12.84-6.95-8.11-7.48-16.35-4.7-26.33,2.42-8.65,6.75-16.03,12.34-22.41,3.37.13,6.78.68,10.08,1.31,12.93,2.49,25.67,6.03,38.03,10.57,8.14,2.99,17.02,7.36,19.61,15.64,1.9,6.08-.19,12.63-.14,19,.05,6.18,2.2,12.32,6.01,17.18,1.94,2.48,4.41,4.93,5.92,7.62-30.39,10.3-65.51-3.09-73.84-9.76Z"/>
							<path className={styles.cls4}
							      d="M242.71,102.98c-5.2,1-10.5-1.48-15-4.44-9.59-6.3-18.59-14.66-29.67-18.08-5.29-1.63-10.84-2.06-16.29-2.95-.84-.14-1.73-.33-2.58-.62.87-.59,1.75-1.16,2.64-1.72,10.82-6.85,21.03-11.55,34.41-7.62,6.7,1.97,12.91,8.14,18.25,12.46,6.25,5.05,10.25,5.92,18.14,8.23,3.66,1.07,8.46,2.19,12.96,3.83-3.28.4-6.46,1.4-9.3,3.07-4.53,2.66-8.37,6.84-13.55,7.83Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls3}
								      d="M202.47,122.67c5.15,4.33,11.29,5.5,13.72,2.6,2.43-2.89.21-8.75-4.94-13.08-5.15-4.33-11.29-5.5-13.72-2.6-2.42,2.89-.21,8.75,4.94,13.08Z"/>
								<path className={styles.cls3}
								      d="M231.57,94.71c5.4,3.56,11.16,4.33,12.87,1.72,1.71-2.61-1.28-7.62-6.67-11.18-5.4-3.56-11.16-4.33-12.87-1.72-1.71,2.61,1.28,7.62,6.67,11.18Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls3}
									      d="M252.53,111.99c.37-2.15,2.06-4.8,4.32-7.09h0s0,0,0,0c0,0,0,0,0,0h0c2.2-2.34,4.78-4.12,6.9-4.57,10.14-2.12,10.66,9.7,5.21,16.37h0s0,0,0,0c0,0,0,0-.01.01h0c-6.45,5.7-18.21,5.54-16.43-4.72Z"/>
									<path className={styles.cls3}
									      d="M279.92,118.46c-.19-.03-.39-.02-.61.05-.73.23-.79,1.01-1.04,1.61-.35.84-.84,1.69-1.42,2.39-1.86,2.24-4.02,3.05-6.66,3.51l-.07-.09s-.03.07-.05.11c-.04,0-.08.01-.12.02l.07.09c-.99,2.49-2.23,4.45-4.8,5.81-1.78.94-6.07,1.8-8.14,1.01-.73-.28-1.53.09-1.78.82-.25.73.13,1.54.86,1.82,3.2,1.21,8.4-.08,10.42-1.14.78-.41,1.46-.87,2.06-1.35,1.65-1.34,2.67-2.95,3.44-4.59,1.77-.42,3.55-1.08,5.2-2.43.6-.49,1.19-1.06,1.75-1.74.81-.98,1.89-2.51,2.09-3.79.13-.86-.34-1.94-1.19-2.09Z"/>
								</g>
								<path className={styles.cls3}
								      d="M271.84,128.65c-.29.27-.69.42-1.13.38-.83-.07-1.47-.8-1.42-1.64,0-.09.13-4.31-3.31-10.96-.39-.75-.1-1.67.63-2.04.74-.37,1.66-.06,2.04.69,3.89,7.53,3.66,12.35,3.65,12.56-.02.4-.2.76-.47,1.01Z"/>
							</g>
							<path className={styles.cls7}
							      d="M269.09,102.67c.59,1.49-1.06,3.55-3.69,4.59-2.63,1.04-5.24.68-5.83-.81-.59-1.49,1.06-3.55,3.69-4.59s5.24-.68,5.83.81Z"/>
							<path className={styles.cls7}
							      d="M235.23,86.29c-.28.97-1.95,1.34-3.72.82s-2.97-1.72-2.69-2.69c.28-.97,1.95-1.34,3.72-.82,1.77.52,2.97,1.72,2.69,2.69Z"/>
							<path className={styles.cls7}
							      d="M208.26,113.32c-.26,1.28-2.34,1.95-4.65,1.49-2.31-.46-3.97-1.88-3.71-3.16.26-1.28,2.34-1.95,4.65-1.49,2.31.46,3.97,1.88,3.71,3.16Z"/>
						</g>
					</g>
				</g>
				<g id="Stripe-2" data-name="Stripe">
					<path
						className={styles.cls11}
						d="M163.22,146.31c11.23-31.75,40.35-61.99,67.46-70.3,2.08-.64-10.18-11.49-17.24-10.13-18.21,3.49-60.73,44.23-60.03,69.21.1,3.71,8.76,14.21,9.81,11.23Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Winner_Bronze_Upper">
					<path className={styles.cls9}
					      d="M122.5,147.68c4.09,5.79,26.23,21.97,31.56,16.77,8.37-8.16,8.9-49.63,9.3-53.63.51-5.02-.82-10.41-3.98-13.11-5.14-4.39-14.74-.64-19.66,2.92-19.09,13.78-30.12,28.77-17.23,47.05Z"/>
					<path className={styles.cls7}
					      d="M157.43,261.83c3.2-8.34,9.12-15.19,17.51-20.6,7-4.51,23.15-7.27,29.33-.56,1.07,1.16,1.78,2.51,2.36,3.88,4.27,10.24.02,22.48-10.13,29.19,4.33,1.03,9.95,2.54,10.11,8.63.26,10.31-23.21,9.06-26.47,2.82-1.54-2.96-.31-5.78.61-8.59-1.7,2.72-4.11,5.11-8.25,6.91-4.13,1.8-9.24,1.63-12.87-1.01-2.54-1.84-6.48-9.5-2.2-20.68Z"/>
				</g>
			</g>
			<g id="Puppy_Winner_Bronze-2" data-name="Puppy_Winner_Bronze">
				<g id="Puppy_Winner_Bronze_Lower-2" data-name="Puppy_Winner_Bronze_Lower">
					<g>
						<path className={styles.cls9}
						      d="M543.36,262.58c2.17,1.79,4.46,3.46,6.91,4.93,2.94,1.76,22.37,7.19,17.07,12.76-.8.84-1.98,1.31-3.17,1.37-4.01.21-8.1.32-11.92-.51-6.3-1.37-11.24-5.14-15.13-9.75.21-1.62.74-3.19,1.6-4.58,1.11-1.8,2.75-3.27,4.63-4.22Z"/>
						<path className={styles.cls7}
						      d="M530.85,253.37c.47-.09,1.05-.07,1.77.08,3.35.72,5.76,4.74,8.09,6.85.86.78,1.75,1.54,2.65,2.28-1.89.95-3.52,2.42-4.63,4.22-.86,1.39-1.39,2.96-1.6,4.58-1.72-2.04-3.24-4.25-4.58-6.49-.94-1.57-5.75-10.73-1.7-11.53Z"/>
					</g>
					<path className={styles.cls8}
					      d="M585.07,271.62c-.1-.65-.15-1.31-.14-1.97.04-3.33,1.37-6.61,3.14-9.65,4.03-6.94,11.01-14.14,19.68-17.15,7.85-2.72,24.41-4.07,29.97,1.91.96,1.03,1.55,2.21,2,3.41,3.32,8.91-2.05,19.15-12.82,24.42,4.23,1.07,9.3,2.43,9.31,7.79,0,7.44-25.6,7.2-26.25.2-.28-2.94-.16-2.74.29-5.44-2.37,3.34-3.94,3.38-8.24,4.73s-9.38.99-12.77-1.41c-2.37-1.68-3.73-4.19-4.16-6.84Z"/>
					<path className={styles.cls8}
					      d="M657.49,211.09c-1.02.54-2.29.92-3.84,1.1-2.16.25-4.34-.23-6.25-1.2-2-1.02-3.2-3.47-5.09-4.33-1.95-.89-4.58,1.46-6.31,2.43-2.37,1.33-4.77,2.66-7.38,3.54-6,2.01-13.24,3.02-16.71-2.95-4.52-7.78-5.53-19.37-6.89-27.97-.39-2.46-.72-4.99-.22-7.44,2.24-10.98,15.39-4.37,18.69,2.28,2.28,4.6,4.07,18.86,4.57,19.1.48.23,11.03-8.02,15.98-9.13,4.95-1.11,8.79.17,11.76,4.17,3.94,5.31,8.71,16.65,1.69,20.39Z"/>
					<g>
						<path className={styles.cls7}
						      d="M598.88,268.93c9.03-.31,40.13-31.15,33.92-76.73-1.12-8.22-1.61-17.44-4.93-26.68-5.54-2.92-17.26-3.31-25.67-4.54-5.72-.84-18.6-3.22-23.64,1-4.79,4.01-12.19,15.63-12.19,24.03,0,5.84,2.94,12.49,2.16,17.89-3.36,23.43-12.55,42.07-14.89,49.96-7.18,24.27,38.6,15.3,45.23,15.07Z"/>
						<path className={styles.cls8}
						      d="M573.36,248.91c-1.39-15.17,26.07-20.24,32.57-8.68,15.96-10.97,22.62-30.61,22.9-51.41.04-2.86-.65-17.54-2.16-21.35-5.24,8.72-32.22,8.51-42.07.43,1.08-3.35,40.63-5.21,43.45-3.5,19.96,37.63-7.75,106.65-27.13,106.32-5.43-.09-26.81-13.62-27.56-21.82Z"/>
					</g>
				</g>
				<path
					id="Shirt-2"
					data-name="Shirt"
					className={styles.cls2}
					d="M634.35,196.54c-.67-7.79-.75-31.82-7.23-36.1l-2.76,1.93c4.78,7,6.44,26.48-2.11,29.93-7.88,3.17-12.51-3-15.26-8-7.51-13.66-25.82-29.77-35.41-21.82-9.88,8.18-6.02,41.26-6.93,50.93-.71,7.53-4.26,12.9-6.61,16.89,18.21,13.58,43.99,15.43,71.02,6.05,4.88-14.41,5.96-31.96,5.29-39.82ZM582.03,202.3c-9.11-.85-15.81-8.85-14.97-17.85.84-9,8.91-15.61,18.03-14.75s15.81,8.85,14.97,17.85c-.84,9-8.91,15.61-18.03,14.75Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes">
					<path
						className={styles.cls11}
						d="M617.51,194.61c-6.6,0-10.78-6.29-15.57-13.51-5.31-8-11.34-17.07-22.11-19.83l.61-2.4c11.69,3,17.99,12.49,23.56,20.86,4.84,7.29,8.67,13.05,14.53,12.35,3.13-.38,5.39-1.81,6.92-4.39,3.71-6.23,2-17.57-.4-23.63l2.3-.91c2.4,6.08,4.59,18.47.22,25.81-1.92,3.23-4.87,5.11-8.75,5.58-.45.05-.89.08-1.31.08Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M618.19,229.85c-7.68,1.44-15.36,2.01-22.83,1.69-12.03-.53-23.64-3.49-33.32-8.52.72-1.53,1.4-3.62,1.7-4.66,9.19,4.89,20.27,7.87,31.83,8.38,12.71.56,24.43-1.01,37.24-5.74.04.78-.61,3.88-.71,4.98-5.35,1.87-8.46,2.86-13.91,3.88Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls11}
						d="M585.2,168.5c-9.76-.92-18.43,6.19-19.33,15.84-.9,9.65,6.3,18.24,16.05,19.16,9.76.91,18.43-6.19,19.33-15.84.9-9.65-6.3-18.24-16.05-19.16ZM582.14,201.1c-8.44-.79-14.66-8.21-13.88-16.54.78-8.33,8.28-14.46,16.72-13.67,8.44.79,14.66,8.21,13.88,16.54-.78,8.33-8.28,14.46-16.72,13.67Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Winner_Bronze_Mid-2" data-name="Puppy_Winner_Bronze_Mid">
					<path className={styles.cls6}
					      d="M571.93,147.68c-1.89,2.35-.56,8.55.34,11.39.86,2.71,4.07,8.88,19.91,12.84,15.85,3.96,34.25-2.76,35.81-7.28,4.09-11.86-7.37-16.94-17.26-20.7-9.31-3.54-23.87-7.61-32.64-1.21-1.89,1.38-4.7,3.13-6.16,4.95Z"/>
					<g>
						<path className={styles.cls10}
						      d="M571.57,162.49c7.74,7.86,41.39,18.1,50.63,15.71,5.54-4,10.04-12.03,9.83-16.08-.11-2.17-.41-3.33-3.3-3.7.03,6.19-4.71,15.5-6.77,18.39-24.89-3.2-46.28-17.87-49.03-17.27-1.3.29-1.62,2.08-1.35,2.95Z"/>
						<g>
							<circle className={styles.cls5} cx="623.7" cy="192.36" r="11.43"/>
							<ellipse className={styles.cls1} cx="621.87" cy="178.68" rx="1.37" ry="2.66"
							         transform="translate(-18.19 83.93) rotate(-7.61)"/>
							<circle className={styles.cls1} cx="624.65" cy="191.01" r="11.43"/>
						</g>
					</g>
					<path className={styles.cls7}
					      d="M613.18,231.41c-1.14.18-2.46.12-3.99-.22-2.12-.47-4.02-1.64-5.51-3.19-1.56-1.62-1.89-4.33-3.39-5.76-1.55-1.48-4.81-.12-6.76.23-2.68.48-5.38.95-8.13.92-6.33-.07-14.41-2.19-15.73-8.97-1.72-8.83-1.92-21.42-.38-29.99,1.38-7.67,23.12-16.38,25.55,2.74.65,5.09-3.74,17.68-3.35,18.07.37.38,12-2.03,17.03-1.46,5.04.58,8.25,3.05,9.74,7.8,1.98,6.31,2.76,18.59-5.09,19.81Z"/>
					<path className={styles.cls9}
					      d="M561.3,261.99c2.68,4.79,3.99,10.38,3.41,15.83-.24,2.27-.9,4.62-2.11,6.52-.45-.11-.94-.1-1.37-.26-13.02-2.03-13.36-16.86-6.54-33.32,2.58,2.32,4.92,8.21,6.61,11.23Z"/>
					<g>
						<path className={styles.cls9}
						      d="M634.83,46.84c4.9,4.43,16.95,27.09,11.19,31.72-9.03,7.27-48.58,4.01-52.45,4.03-4.86.03-9.79-1.73-11.88-4.99-3.4-5.32,1.62-14.16,5.74-18.55,16-17.01,31.93-26.21,47.39-12.21Z"/>
						<g>
							<path className={styles.cls7}
							      d="M635.57,154.51c-3.81-4.86-5.96-11.01-6.01-17.18-.05-6.37,2.04-12.92.14-19-2.58-8.28-11.46-12.65-19.61-15.64-12.36-4.54-25.1-8.08-38.03-10.57-3.3-.63-6.71-1.19-10.08-1.31,4.63-5.27,10.11-9.86,16.12-13.9.85.29,1.74.48,2.58.62,5.45.9,11,1.32,16.29,2.95,11.09,3.42,20.08,11.78,29.67,18.08,4.5,2.95,9.8,5.44,15,4.44,5.18-.99,9.02-5.17,13.55-7.83,2.84-1.67,6.03-2.67,9.3-3.07,4.74,1.74,9.15,4.07,11.51,7.57,5.94,8.82,7.85,21.9,3.99,29.95-6.52,13.62-15.14,19.69-25.13,26.13-4.28,2.75-8.78,4.84-13.38,6.4-1.51-2.7-3.98-5.15-5.92-7.62Z"/>
							<path className={styles.cls4}
							      d="M567.65,152.37c-4.81-3.85-9.31-8.17-13.31-12.84-6.95-8.11-7.48-16.35-4.7-26.33,2.42-8.65,6.75-16.03,12.34-22.41,3.37.13,6.78.68,10.08,1.31,12.93,2.49,25.67,6.03,38.03,10.57,8.14,2.99,17.02,7.36,19.61,15.64,1.9,6.08-.19,12.63-.14,19,.05,6.18,2.2,12.32,6.01,17.18,1.94,2.48,4.41,4.93,5.92,7.62-30.39,10.3-65.51-3.09-73.84-9.76Z"/>
							<path className={styles.cls4}
							      d="M641.64,102.98c-5.2,1-10.5-1.48-15-4.44-9.59-6.3-18.59-14.66-29.67-18.08-5.29-1.63-10.84-2.06-16.29-2.95-.84-.14-1.73-.33-2.58-.62.87-.59,1.75-1.16,2.64-1.72,10.82-6.85,21.03-11.55,34.41-7.62,6.7,1.97,12.91,8.14,18.25,12.46,6.25,5.05,10.25,5.92,18.14,8.23,3.66,1.07,8.46,2.19,12.96,3.83-3.28.4-6.46,1.4-9.3,3.07-4.53,2.66-8.37,6.84-13.55,7.83Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls3}
								      d="M601.4,122.67c5.15,4.33,11.29,5.5,13.72,2.6,2.43-2.89.21-8.75-4.94-13.08-5.15-4.33-11.29-5.5-13.72-2.6-2.42,2.89-.21,8.75,4.94,13.08Z"/>
								<path className={styles.cls3}
								      d="M630.5,94.71c5.4,3.56,11.16,4.33,12.87,1.72,1.71-2.61-1.28-7.62-6.67-11.18-5.4-3.56-11.16-4.33-12.87-1.72-1.71,2.61,1.28,7.62,6.67,11.18Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls3}
									      d="M651.45,111.99c.37-2.15,2.06-4.8,4.32-7.09h0s0,0,0,0c0,0,0,0,0,0h0c2.2-2.34,4.78-4.12,6.9-4.57,10.14-2.12,10.66,9.7,5.21,16.37h0s0,0,0,0c0,0,0,0-.01.01h0c-6.45,5.7-18.21,5.54-16.43-4.72Z"/>
									<path className={styles.cls3}
									      d="M678.85,118.46c-.19-.03-.39-.02-.61.05-.73.23-.79,1.01-1.04,1.61-.35.84-.84,1.69-1.42,2.39-1.86,2.24-4.02,3.05-6.66,3.51l-.07-.09s-.03.07-.05.11c-.04,0-.08.01-.12.02l.07.09c-.99,2.49-2.23,4.45-4.8,5.81-1.78.94-6.07,1.8-8.14,1.01-.73-.28-1.53.09-1.78.82-.25.73.13,1.54.86,1.82,3.2,1.21,8.4-.08,10.42-1.14.78-.41,1.46-.87,2.06-1.35,1.65-1.34,2.67-2.95,3.44-4.59,1.77-.42,3.55-1.08,5.2-2.43.6-.49,1.19-1.06,1.75-1.74.81-.98,1.89-2.51,2.09-3.79.13-.86-.34-1.94-1.19-2.09Z"/>
								</g>
								<path className={styles.cls3}
								      d="M670.77,128.65c-.29.27-.69.42-1.13.38-.83-.07-1.47-.8-1.42-1.64,0-.09.13-4.31-3.31-10.96-.39-.75-.1-1.67.63-2.04.74-.37,1.66-.06,2.04.69,3.89,7.53,3.66,12.35,3.65,12.56-.02.4-.2.76-.47,1.01Z"/>
							</g>
							<path className={styles.cls7}
							      d="M668.02,102.67c.59,1.49-1.06,3.55-3.69,4.59-2.63,1.04-5.24.68-5.83-.81-.59-1.49,1.06-3.55,3.69-4.59s5.24-.68,5.83.81Z"/>
							<path className={styles.cls7}
							      d="M634.15,86.29c-.28.97-1.95,1.34-3.72.82s-2.97-1.72-2.69-2.69c.28-.97,1.95-1.34,3.72-.82,1.77.52,2.97,1.72,2.69,2.69Z"/>
							<path className={styles.cls7}
							      d="M607.19,113.32c-.26,1.28-2.34,1.95-4.65,1.49-2.31-.46-3.97-1.88-3.71-3.16.26-1.28,2.34-1.95,4.65-1.49,2.31.46,3.97,1.88,3.71,3.16Z"/>
						</g>
					</g>
				</g>
				<g id="Stripe-3" data-name="Stripe">
					<path
						className={styles.cls11}
						d="M562.15,146.31c11.23-31.75,40.35-61.99,67.46-70.3,2.08-.64-10.18-11.49-17.24-10.13-18.21,3.49-60.73,44.23-60.03,69.21.1,3.71,8.76,14.21,9.81,11.23Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Winner_Bronze_Upper-2" data-name="Puppy_Winner_Bronze_Upper">
					<path className={styles.cls9}
					      d="M521.43,147.68c4.09,5.79,26.23,21.97,31.56,16.77,8.37-8.16,8.9-49.63,9.3-53.63.51-5.02-.82-10.41-3.98-13.11-5.14-4.39-14.74-.64-19.66,2.92-19.09,13.78-30.12,28.77-17.23,47.05Z"/>
					<path className={styles.cls7}
					      d="M556.35,261.83c3.2-8.34,9.12-15.19,17.51-20.6,7-4.51,23.15-7.27,29.33-.56,1.07,1.16,1.78,2.51,2.36,3.88,4.27,10.24.02,22.48-10.13,29.19,4.33,1.03,9.95,2.54,10.11,8.63.26,10.31-23.21,9.06-26.47,2.82-1.54-2.96-.31-5.78.61-8.59-1.7,2.72-4.11,5.11-8.25,6.91-4.13,1.8-9.24,1.63-12.87-1.01-2.54-1.84-6.48-9.5-2.2-20.68Z"/>
				</g>
			</g>
		</svg>
	);
};