import React, { useState, useEffect } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./AvailableCouponOffers.scss";
import { mapProgramOffersFromServerToApp } from "data/serverMapping";
import cloneDeep from "lodash/cloneDeep";
import difference from "lodash/difference";
import filter from "lodash/filter";
import flattenDeep from "lodash/flattenDeep";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import mergeWith from "lodash/mergeWith";
import reject from "lodash/reject";
import uniq from "lodash/uniq";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import SortableDataTable from "components/common/SortableDataTable";
import Tooltip from "components/common/Tooltip";
import * as CouponsApi from "api/CouponsApi";
import * as CouponActions from "actions/CouponActions";
import getLocalTimezone from "utils/getLocalTimezone";
import {handleErrorResponse} from "utils/request";


function AvailableCouponOffers(props) {
    const TODAY = new moment();
    const [offers, setOffers] = useState({});
    const [filtersList, setFiltersList] = useState({
        useAnd: true,
        onlyVisible: true,
        onlyCurrent: true,
    });
    const [selectedTagFilters, setSelectedTagFilters] = useState([]);

    const combinedData = cloneDeep(offers);

    const custom = (objValue, srcValue) => {
      if (_.isArray(objValue)) {
          return srcValue;
      }
    }
    mergeWith(combinedData, props.formData, custom);

    useEffect(() => {
        CouponsApi.getProgramOffersForProvider(props.clinicId, props.programId)
            .then((res) => {
                setOffers(
                    keyBy(mapProgramOffersFromServerToApp(res.body), "id")
                );
            })
            .catch((error) => {
                handleErrorResponse("loading provider program offers", error);
            });
    }, [props.programId, props.clinicId]);

    const tagFilterOptions = uniq(flattenDeep(map(combinedData, data => {
        return map(data.clog.cloo.offers, offer => {
            return offer.tags;
        })
    }))).sort();

    const filteredOffers = (offers, isVisible) => {

        return filter(offers, offer => {
            let showOffer = false;
            const isCurrent = (new moment(offer.expirationDate) >= TODAY);
            if (filtersList.onlyVisible && filtersList.onlyCurrent) {
                if (filtersList.useAnd) {
                    //show if clog.cloo.isVisible and offer has not expired
                    if (isVisible && isCurrent) {
                        showOffer = true;
                    }
                } else {
                    //show if clog.cloo.isVisible or offer has not expired
                    if (isVisible || isCurrent) {
                        showOffer = true;
                    }
                }
            } else if (filtersList.onlyVisible) {
                //show if clog.cloo.isVisible
                if (isVisible) {
                    showOffer = true;
                }
            } else if (filtersList.onlyCurrent) {
                //show if offer has not expired
                if (isCurrent) {
                    showOffer = true;
                }
            } else if(!(filtersList.onlyCurrent || filtersList.onlyVisible)) {
                //Show the offers if there are no filters selected
                showOffer = true;
            }

            if (showOffer && selectedTagFilters.length) {
                if (difference(selectedTagFilters, offer.tags).length) {
                    //Row must have all selected tag filters
                    showOffer = false;
                }
            }

            return showOffer;
        });
    }

    const filteredRows = filter(combinedData, data => {
        return filteredOffers(data.clog.cloo.offers, data.clinicCanSee).length;
    })

    const handleUpdate = ({name, value}, row) => {
        const newData = {
            ...props.formData,
            [row.id]: {
                ccloId: row.id,
                id: row.id,
                clinicCanSee: !!row.clinicCanSee,
                clinicCanChange: !!row.clinicCanChange,
                isActive: !!row.isActive,
                ...props.formData[row.id],
                [name]: value,
            }
        }
        props.handleSetFormData(newData);

    }

    const renderOffersTable = (row) => {
        return (
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Offer</th>
                        <th>Type</th>
                        <th>Mfr Code</th>
                        <th>Begin</th>
                        <th>Expiration</th>
                        <th>Grace</th>
                        <th>Fire Order</th>
                        <th>Tags</th>
                    </tr>
                </thead>
                <tbody>
                    {map(filteredOffers(row.clog.cloo.offers, row.clog.cloo.isVisible), offer => (
                        <tr key={offer.id}>
                            <td>{offer.offerName}</td>
                            <td>
                                <div className={styles.offerType}>
                                    <span>{offer.offerType} Offer</span>
                                    <Tooltip
                                        position={"right"}
                                        tip={
                                            <div>
                                                {offer.isMultiRedeemablePerPatient && (<div>Multi-Redeemable Per Pet</div>)}
                                                {offer.isMultiRedeemablePerInvoice && (<div>Multi-Redeemable Per Invoice</div>)}
                                                {offer.dllName && (<div>DLL: {offer.dllName}</div>)}
                                                <div>Coupon Accessible Until: {offer.couponAcceptableUntil ? getLocalTimezone(offer.couponAcceptableUntil, "MM/DD/YYYY") : "UNKNOWN"}</div>
                                                <div>Free Doses: {offer.freeDoses}</div>
                                                {!!offer.numberOfRequiredDoses && (<div>Required Doses: {offer.numberOfRequiredDoses}</div>)}
                                                <div className="margin-top-sm">Coupon ID: {offer.couponNameId.id}</div>
                                                <div>Offer ID: {offer.id}</div>
                                            </div>
                                        }
                                    >
                                        <i className="fas fa-info-circle" />
                                    </Tooltip>
                                </div>
                            </td>
                            <td>{offer.campaignCode}</td>
                            <td>{getLocalTimezone(offer.beginDate, "MM/DD/YYYY")}</td>
                            <td>{getLocalTimezone(offer.expirationDate, "MM/DD/YYYY")}</td>
                            <td>{getLocalTimezone(offer.gracePeriod, "MM/DD/YYYY")}</td>
                            <td>{offer.fireOrder}</td>
                            <td>
                                <div className="flex flex-wrap">
                                    {map(offer.tags, (tag, index) => (
                                        <div
                                            key={index}
                                            className={styles.offerYear}
                                        >
                                            {tag}
                                        </div>
                                    ))}
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        )

    }

    const EXPANDED = {
        selector: "ccloId",
        key: "ccloId",
        format: (row) => renderOffersTable(row)
    };

    const COLUMNS = [
        {
            name: "Activated",
            selector: "isActive",
            key: "isActive",
            sortable: true,
            format: (row) => (
                <CheckboxInput
                    onChange={(data) => handleUpdate(data, row)}
                    name="isActive"
                    checked={row.isActive}
                />
            ),
        },
        {
            name: "Library Group",
            selector: "sidebarTitle",
            key: "sidebarTitle",
            sortable: true,
            searchable: true,
            format: row => (
                <div><span dangerouslySetInnerHTML={{ __html: row.clog.cloo.sidebarTitle }}/>{!row.clog.isVisible && (<span className="text-sm text-italic"> (NOT VISIBLE)</span>)} [{row.clog.id}]</div>
            ),
            sortValue: row => row.clog.cloo.sidebarTitle,
            searchValue: row => row.clog.cloo.sidebarTitle,
        },
        {
            name: "Library Title",
            selector: "clog.cloo.libraryTitle",
            key: "clog.cloo.libraryTitle",
            sortable: true,
            searchable: true,
            searchValue: row => row.clog.cloo.libraryTitle,
            format: row => (
                <div>
                    <div>
                        <span dangerouslySetInnerHTML={{ __html: row.clog.cloo.libraryTitle }}/>
                        {!row.clog.isVisible && (<span className="text-sm text-italic margin-right-x-sm"> (NOT VISIBLE)</span>)}
                        [{row.clog.cloo.id}]
                        <Tooltip
                            position="right"
                            tip={
                                <div>
                                    <div>Library Title (CLOO) isVisible: {!!row.clog.cloo.isVisible ? "YES" : "NO"}</div>
                                    <div>Library Offer Visible Dates: {!!row?.clog?.cloo?.visibleOnOrAfter ? moment(row.clog.cloo.visibleOnOrAfter).format("MM/DD/YYYY") : "Unknown"} - {!!row?.clog?.cloo?.visibleOnOrBefore ? moment(row?.clog?.cloo?.visibleOnOrBefore).format("MM/DD/YYYY") : "Unknown"}</div>
                                    <div>Section (CLOG) isVisible: {!!row.clog.isVisible ? "YES" : "NO"}</div>
                                    <div>CLOG ID: {row.clog.id}</div>
                                    <div>CLOO ID: {row.clog.cloo.id}</div>
                                </div>
                            }
                        >
                            <span className="margin-left-x-sm">
                                <i className="fa fa-info-circle"/>
                            </span>
                        </Tooltip>
                    </div>
                    <div><span dangerouslySetInnerHTML={{ __html: row.clog.cloo.shortDescription }}/></div>
                </div>
            ),
            sortValue: row => row.clog.cloo.libraryTitle,
        },
        {
            name: "Clinic Can See",
            selector: "clinicCanSee",
            key: "clinicCanSee",
            sortable: true,
            format: (row) => (
                <div className="text-center">
                    <CheckboxInput
                        onChange={(data) => handleUpdate(data, row)}
                        name="clinicCanSee"
                        checked={row.clinicCanSee}
                    />
                </div>
            ),
        },
        {
            name: "Clinic Can Change",
            selector: "clinicCanEdit",
            key: "clinicCanEdit",
            sortable: true,
            format: (row) => (
                <div className="text-center">
                    <CheckboxInput
                        onChange={(data) => handleUpdate(data, row)}
                        name="clinicCanEdit"
                        checked={row.clinicCanEdit}
                    />
                </div>
            ),
        }
    ];

    const handleSetFilter = (name) => {
        setFiltersList({
            ...filtersList,
            [name]: !filtersList[name]
        });
    }

    const handleTagFilter = (name) => {
        let newTagList = [];
        if (!!name) {
            newTagList = [...selectedTagFilters];
            if (includes(newTagList, name)) {
                newTagList = reject(newTagList, t => {return t === name});
            } else {
                newTagList.push(name)
            }
        }
        setSelectedTagFilters(newTagList);
    }

    const handleSave = () => {
        // Make an API call for each changed offer
        forEach(props.formData, offer => props.saveClinicProgramOfferSettings({
            id: offer.id,
            isActive: offer.isActive || false,
            clinicCanSee: offer.clinicCanSee || false,
            clinicCanChange: offer.clinicCanChange || false,
        }));
    }

    return (
        <div className={styles.root}>
            <h3>Available Coupon Offers</h3>
            <h5>Activate offers for the selected program - * Defaults to "ON" for new activations</h5>
            <div className={styles.filters}>
                <div className="flex align-center spaced-content margin-bottom-sm">
                    <label className="flex-none text-sm text-uppercase">Filters:</label>
                    <Button
                        type={filtersList["onlyCurrent"] ? "success" : "outline"}
                        onClick={() => handleSetFilter("onlyCurrent")}
                        small
                        noWrap
                    >
                        Only Current
                    </Button>
                    <Button
                        type={filtersList["onlyVisible"] ? "success" : "outline"}
                        onClick={() => handleSetFilter("onlyVisible")}
                        small
                        noWrap
                    >
                        Only Visible
                    </Button>
                    <Button
                        type={filtersList["useAnd"] ? "success" : "outline"}
                        onClick={() => handleSetFilter("useAnd")}
                        small
                        noWrap
                        disabled={!(filtersList["onlyVisible"] && filtersList["onlyCurrent"])}
                    >
                        Use AND
                    </Button>
                </div>
                <div className="flex spaced-content">
                    <label className="flex-none text-sm text-uppercase margin-top-x-sm">Tag Filters:</label>
                    <div className="flex flex-wrap spaced-content">
                        <div>
                            <Button
                                type={!selectedTagFilters.length ? "success" : "outline"}
                                onClick={() => handleTagFilter()}
                                small
                            >
                                All
                            </Button>
                        </div>
                        {map(tagFilterOptions, (option, index) => (
                            <div key={index}>
                                <Button
                                    type={includes(selectedTagFilters, option) ? "success" : "outline"}
                                    onClick={() => handleTagFilter(option)}
                                    small
                                >
                                    {option}
                                </Button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className={styles.offersTable}>
                <SortableDataTable
                    columns={COLUMNS}
                    expandedData={EXPANDED}
                    rawData={filteredRows}
                    striped
                    green
                    allowSearch
                />
            </div>
        </div>
    )
}

AvailableCouponOffers.propTypes = {
    clinicId: PropTypes.number,
    programId: PropTypes.number,
    formData: PropTypes.object.isRequired,
    handleSetFormData: PropTypes.func.isRequired,
};

export default connect(
    null,
    (dispatch) => ({
        saveClinicProgramOfferSettings: (offerSettings) => dispatch(CouponActions.saveClinicProgramOfferSettings(offerSettings)),
    })
)(AvailableCouponOffers);
