import React, {useMemo, useEffect} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "./MappingTable.scss";
import * as MappingActions from "actions/MappingActions";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import union from "lodash/union";
import ServiceTableSection from "components/admin/widgets/ServiceTableSection";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";

function ServiceTable(props) {
    if(!props.canViewExamServiceMapping) {
        return <AccessDenied/>;
    }

    useEffect(() => {
        if(props.canViewExamServiceMapping) {
            if (!keys(props.services).length) {
                props.loadExamServices();
            }
            props.loadExamServiceMappings(props.clinicId);
        }
    }, [props.clinicId]);

    const PIMS_OPTIONS = useMemo(() => {
        return map(props.pims, (pim) => {
            return {name: `(${pim.remoteClinicProductDisplayID}) ${pim.name}`, value: pim.clinicProductId};
        })
    }, [props.pims]);

    const orderedPimsOptions = useMemo(() => {
        return orderBy(PIMS_OPTIONS, po => {return includes(props.disabledPimsOptions, po.value)});
    }, [PIMS_OPTIONS, props.disabledPimsOptions]);

    const filteredServices = useMemo(() => {
        if(props.search) {
            return filter(props.services, s => {
                const searchTokens = props.search.toLowerCase().split(" ");
                let keep = true
                for (let i = 0; i < searchTokens.length; i++) {
                    const token = searchTokens[i];
                    if (!s.name || !s.name.toString().toLowerCase().includes(token)) {
                        keep = false;
                    }
                }
                return keep;
            });
        } else return props.services;
    }, [props.services, props.search]);

    if (props.working || !filteredServices) {
        return <SpinnerTakeover show/>;
    }

    const sections = map(orderBy(filteredServices, ["sortOrder"]), (service) => {
        return (
            <ServiceTableSection
                key={service.examServiceId}
                typeId={props.typeId}
                clinicId={props.clinicId}
                examServiceId={service.examServiceId}
                pimsOptions={orderedPimsOptions}
                disabledPimsOptions={props.disabledPimsOptions}
                boldPimsOptions={props.boldPimsOptions}
            />
        )
    });

    return (
        <div className="flex flex-column height-100-perc">
            <div className={classnames(styles.mappingHeader, "flex spaced-content align-center flex-none")}>
                <div className="flex flex-wrap spaced-content align-center">
                    <h2 className="nowrap">Exam Service Mappings</h2>
                    <h3 className="nowrap">({keys(props.services).length} products / {props.totalMappings} mappings)</h3>
                </div>
            </div>
            <hr className="flex-none full-width"/>
            <div className="flex-1">
                <div className={classnames(styles.overflow, "height-100-perc overflow-x-scroll full-width")}>
                    <table className="table">
                        <thead>
                            <tr className="spaced-content">
                                <th>Category</th>
                                <th>Service</th>
                                <th/>
                                <th/>
                                <th>Clinic Product</th>
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {sections}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

ServiceTable.propTypes = {
    clinicId: PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const totalMappings = state.entities.mappingCounts?.[ownProps.clinicId]?.totalServiceMappings;
        const serviceMappings = state.entities.serviceMappings?.[ownProps.clinicId] || {};
        const disabledPimsOptions = union(...map(serviceMappings, product => map(product.mappings, "clinicProductId"))) || [];
        const pims = state.entities.pims?.products || {}
        const boldPimsOptions = flatMap(filter(pims, "productUsedRowCount"), "clinicProductId");

        //Permissions
        const canViewExamServiceMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.EXAM_SERVICE_MAPPING, userProfile);

        return {
            boldPimsOptions,
            disabledPimsOptions,
            pims,
            totalMappings,
            providers: orderBy(filter(state.entities.providers, "visibleInServiceMapping"), ["displayOrder"]),
            search: state.entities.genericSearch,
            services: state.entities.services,
            working: !!state.applicationState.working,
            //Permissions
            canViewExamServiceMapping
        }
    },
    (dispatch) => ({
        loadExamServices: () => dispatch(MappingActions.loadExamServices()),
        loadExamServiceMappings: (clinicId) => dispatch(MappingActions.loadExamServiceMappings(clinicId)),
    }),
) (ServiceTable);
