import React, { useEffect, useState } from "react";
import Button from "components/common/Button";
import PropTypes from "prop-types";
import DateInput from "components/common/DateInput";
import moment from "moment";
import styles from "./ProviderClinicDetails.scss";
import { map, flatMap, uniq, find } from "lodash";
import {connect} from "react-redux";
import * as ProviderActions from "actions/ProviderActions";
import toast from "utils/toast";
import * as ProviderApi from "api/ProviderApi";
import {downloadFile} from "utils/request";

function ProviderClinicDetails(props) {
    const TODAY = new moment();
    const [currentMonth, setCurrentMonth] = useState(Number(TODAY.format("MM")));
    const [currentYear, setCurrentYear] = useState(Number(TODAY.format("YYYY")));
    const previousYear = currentYear - 1;
    const canGoNext = new moment(`${currentMonth}/01/${currentYear}`).add(1, 'months').isSameOrBefore(TODAY);
    const canGoPrevious = new moment(`${currentMonth}/01/${currentYear}`).subtract(1, 'months').isSameOrBefore(TODAY);

    const fetchData = (year) => {
        // TODO: Export only uses "createdX" so we updated UI to match. If export is updated, we can go back.
        //Have to load december of the next year to line up the results so "created1" == Jan, "created2" == Feb, etc.
        const searchData = {
            clinicId: props.clinicId,
            providerId: props.providerId,
            month: 12,
            year: year,
        }
        props.getClinicRedemptions(searchData);
    }

    useEffect(() => {
        if(props.clinicId && props.providerId) {
            if (currentYear && !(props.clinicRedemptions?.year && (props.clinicRedemptions?.results[currentYear] || props.clinicRedemptions?.grandTotals[currentYear] || props.clinicRedemptions?.clinicTotals[currentYear]))) {
                fetchData(currentYear);
            }
            if ((currentMonth === 1 || currentMonth === 2) && !((props.clinicRedemptions?.results && props.clinicRedemptions?.results[previousYear]) || (props.clinicRedemptions?.grandTotals && props.clinicRedemptions?.grandTotals[previousYear]) || (props.clinicRedemptions?.clinicTotals && props.clinicRedemptions?.clinicTotals[previousYear]))) {
                fetchData(previousYear);
            }
        }
    }, [props.clinicId, props.providerId, currentMonth, currentYear]);

    const handleChangeMonth = ({value}) => {
        const newDate = new moment(value)
        setCurrentMonth(newDate.month() + 1)
        setCurrentYear(newDate.year())
    }

    const handlePrevClick = () => {
        if (currentMonth <= 1) {
            setCurrentMonth(12);
            setCurrentYear(currentYear - 1);
        } else {
            setCurrentMonth(currentMonth - 1);
        }
    }
    const handleNextClick = () => {
        if (currentMonth >= 12) {
            setCurrentMonth(1);
            setCurrentYear(currentYear + 1);
        } else {
            setCurrentMonth(currentMonth + 1);
        }
    }

    const handleExportExcel = async () => {
        toast.success("Generating Export...");

        const searchData = {
            clinicId: props.clinicId,
            providerId: props.providerId,
            month: currentMonth,
            year: currentYear,
        }
        const res = await ProviderApi.downloadClinicRedemptions(searchData);
        downloadFile(res, "xlsx");
    }

    const renderHeader = (
        <div className="flex spaced-content">
            <div className={styles.datePicker}>
                <Button
                    className="margin-right-sm"
                    disabled={!canGoPrevious || !!props.clinicRedemptions?.hasNoResults}
                    onClick={handlePrevClick}
                >
                    <i className="fas fa-angle-left" />
                </Button>
                <div className="margin-top-x-sm">
                    <DateInput
                        value={`${currentMonth}/1/${currentYear}` || null}
                        name="month"
                        onChange={handleChangeMonth}
                        showDropdowns
                        showMonthYearPicker
                        maxDate={TODAY.format("MM/DD/YYYY")}
                        placeholder="Select a date"
                        dateFormat="MM/DD/YYYY"
                        disabled={props.clinicRedemptions.hasNoResults}
                    />
                </div>
                <Button
                    className="margin-left-sm"
                    disabled={!canGoNext}
                    onClick={handleNextClick}
                >
                    <i className="fas fa-angle-right" />
                </Button>
            </div>
            <div className="flex-none flex justify-flex-end">
                <Button
                    large
                    className="margin-bottom-x-sm"
                    onClick={handleExportExcel}
                    disabled={!!props.clinicRedemptions?.hasNoResults}
                    icon
                    type="primary"
                >
                    <i className="fa fa-file-excel"/> Export
                </Button>
            </div>
        </div>
    );

    const getMonth = (addToCurrentMonth=0 ) => {
        let month = currentMonth + addToCurrentMonth;
        let year = currentYear;
        //Handle if the previous months if currentMonth is 1 or 2
        if (month === 0) {
            month = 12;
            year -= 1;
        } else if (month === -1) {
            month = 11;
            year -= 1;
        }
        return new moment(`${month}/01/${year}`).format("MMM YYYY");
    }

    const getData = ({categoryId, addToCurrentMonth= 0}) => {
        let month = 12 - (currentMonth + addToCurrentMonth)
        let year = currentYear;
        // Handle if the previous months if currentMonth is 1 or 2
        if (month === 0) {
            month = "MTD";
        } else if (month === -1) {
            month = 11;
            year -= 1
        }
        // Finds the result set based on the categoryId and year
        let result;
        if (categoryId) {
            // Result for month
            const results = props.clinicRedemptions.results[year]
            result = find(results, {categoryId: categoryId})
        } else {
            // Result for total
            result = props.clinicRedemptions.clinicTotals[year]
        }
        // Gets the name of the result
        let resultName;
        resultName = `created${month}`;

        // Return the result if there is a value.
        if (result) {
            return result[resultName];
        } else {
            return null;
        }
    }

    const getTableBody = () => {
        let results = [];
        if (!!props.clinicRedemptions?.results) {
            if (!!(currentMonth <= 2 && props.clinicRedemptions?.results[previousYear])) {
                results = [
                    ...props.clinicRedemptions.results[currentYear] || [],
                    ...props.clinicRedemptions.results[previousYear] || []
                ]
            } else {
                results = props.clinicRedemptions.results[currentYear];
            }
        }

        const getCategoryName = (categoryId) => {
            const category = find(results, {categoryId: categoryId});
            return category.categoryName;
        }

        const getYTD = (categoryId) => {
            const category = find(props.clinicRedemptions.results[currentYear], {categoryId: categoryId})
            return category ? category.createdYTD : null;
        }

        const categories = uniq(flatMap(results, "categoryId"));

        if (!!categories?.length) {
            return (
                <>
                    {map(categories, (index) => (
                        <tr key={index}>
                            <td>{getCategoryName(index)}</td>
                            <td>{getData({ categoryId: index, addToCurrentMonth:-2 })}</td>
                            <td>{getData({ categoryId: index, addToCurrentMonth: -1 })}</td>
                            <td>{getData({ categoryId: index })}</td>
                            <td>{getYTD(index)}</td>
                        </tr>
                    ))}
                    {/*TOTAL ROW*/}
                    {!!(props.clinicRedemptions?.clinicTotals && props.clinicRedemptions?.clinicTotals[currentYear]) && (
                        <tr>
                            <td>TOTAL</td>
                            <td>{getData({addToCurrentMonth: -2})}</td>
                            <td>{getData({addToCurrentMonth:-1})}</td>
                            <td>{getData({})}</td>
                            <td>{props.clinicRedemptions?.clinicTotals[currentYear]?.createdYTD}</td>
                        </tr>
                    )}
                </>
            )
        } else if (!!props.clinicRedemptions?.hasNoResults) {
            return (
                <tr>
                    <td colSpan={5}
                        className="text-center text-bold text-lg"
                    >
                        No Data to Show for the Selected Clinic and Provider
                    </td>
                </tr>
            )
        }
        else {
            return (
                <tr>
                    <td colSpan={5}
                        className="text-center text-bold text-lg"
                    >
                        No Data to Show for the Selected Year
                    </td>
                </tr>
            )
        }
    }

    return (
        <div className={styles.root}>
            {renderHeader}
            {!!(props.clinicRedemptions) && (
                <table className="table table-green striped">
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>{getMonth(-2)}</th>
                            <th>{getMonth(-1)}</th>
                            <th>{getMonth()}</th>
                            <th>YTD ({currentYear})</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getTableBody()}
                    </tbody>
                </table>
            )}
            <div className=" margin-top-sm flex spaced-content justify-flex-end">
                <Button
                    onClick={props.onClose}
                >
                    Close
                </Button>
            </div>
        </div>
    );
}

ProviderClinicDetails.propTypes = {
    clinicId: PropTypes.number.isRequired,
    providerId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default connect(
    (state, ownProps) => ({
        clinicRedemptions: state.entities.redemptions[ownProps.clinicId] || {},
    }),
    (dispatch) => ({
        getClinicRedemptions: (data) => dispatch(ProviderActions.getClinicRedemptions(data)),
    }),
)(ProviderClinicDetails);
