import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./Footer.scss";
import Modal from "components/common/Modal";
import PrivacyPolicy from "components/layout/elements/PrivacyPolicy";
import TermsAndConditions from "components/layout/elements/TermsAndConditions";
import { LiveChatWidget } from "components/clinic/elements/LiveChat";

export default function Footer(props) {
    const {
        showChat = true,
        showSocial = true,
    } = props;

    const currentYear = new Date().getFullYear();
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

    return (
        <footer
            data-testid="footer_component"
            className={classnames(styles.root, "flex flex-wrap flex-centered")}
        >
            <div
                data-testid="footer_privacy_policy_btn"
                className={classnames(styles.clickable, "flex-none")}
                onClick={() => setShowPrivacyPolicy(true)}
            >
                Privacy Policy
            </div>
            <div
                data-testid="footer_terms_and_conditions_btn"
                className={classnames(styles.clickable, "flex-none")}
                onClick={() => setShowTermsAndConditions(true)}
            >
                Terms &amp; Conditions
            </div>
            <div className="margin-right-md flex-none">Copyright Greenline {currentYear}</div>
            {(showChat && !!(props.clinic || props.providerLocation)) && (
                <div
                    data-testid="footer_live_chat"
                    className="flex-none"
                >
                    <LiveChatWidget
                        clinic={props.clinic}
                        footer
                        providerLocation={props.providerLocation}
                        clinicHasPrioritySupport={props.clinicHasPrioritySupport}
                    />
                </div>
            )}
            {showSocial && (
                <div
                    data-testid="footer_social_links"
                    className="flex-none"
                >
                    <div className={classnames(styles.socialLinks, "flex flex-centered spaced-content")}>
                        <a className={styles.socialLink} href={"https://www.linkedin.com/company/greenline-pet"} target="_blank">
                            <i className="fab fa-linkedin fa-2x default"/>
                        </a>
                        <a className={styles.socialLink} href={"https://www.facebook.com/greenlinepet/"} target="_blank">
                            <i className="fab fa-facebook-square fa-2x default"/>
                        </a>
                        <a className={styles.socialLink} href={"https://www.instagram.com/greenlinepet/"} target="_blank">
                            <i className="fab fa-instagram-square fa-2x default"/>
                        </a>
                        {/*<a className={styles.socialLink} href={"https://www.youtube.com/channel/UC1cl7J8qZnP5bE1IWIEK1NA"} target="_blank">*/}
                        {/*    <i className="fab fa-youtube-square fa-2x default"/>*/}
                        {/*</a>*/}
                    </div>
                </div>
            )}
            <Modal
                data-testid="footer_privacy_policy_modal"
                show={showPrivacyPolicy}
                onClose={() => setShowPrivacyPolicy(false)}
                modalTitle="Privacy Policy"
                medium
            >
                <PrivacyPolicy />
            </Modal>
            <Modal
                data-testid="footer_terms_and_conditions_modal"
                show={showTermsAndConditions}
                onClose={() => setShowTermsAndConditions(false)}
                modalTitle="Terms and Conditions"
                medium
            >
                <TermsAndConditions />
            </Modal>
        </footer>
    );
};

Footer.propTypes = {
    clinic: PropTypes.object,
    showChat: PropTypes.bool,
    showSocial: PropTypes.bool,
    providerLocation: PropTypes.object,
    clinicHasPrioritySupport: PropTypes.bool,
};
