
import { round } from "lodash";

export function computeEstROI(levelData, products, name="", value=null) {
    let totalEarnings = 0;
    let totalWholesale = 0;
    let IncreasePercentage;
    let PrizeAmount;

    if (!(products && products.length)) return null;

    if (name === "IncreasePercentage") {
        IncreasePercentage = value;
    } else {
        IncreasePercentage = levelData.IncreasePercentage;
    }

    if (name === "PrizeAmount") {
        PrizeAmount = value;
    } else {
        PrizeAmount = levelData.PrizeAmount;
    }

    for (let p = 0; p < products.length; p++) {
        const product = products[p];
        if (product.pWholesalePrice) {
            const earnings = (product.pWholesalePrice * (IncreasePercentage / 100));
            const wholesale = (product.pWholesalePrice * (1 + (IncreasePercentage / 100)));
            totalEarnings += earnings;
            totalWholesale += wholesale;
        }
    }

    return (totalEarnings / PrizeAmount).toFixed(2);
}

export function computeIncreasePercentage(levelData, products, name="", value=null) {
    let totalPreviousWholesale = 0;
    let EstROI;
    let PrizeAmount;

    if (!(products && products.length)) return null;

    if(name === "EstROI") {
        EstROI = value;
    } else {
        EstROI = levelData.EstROI;
    }
    if (name === "PrizeAmount") {
        PrizeAmount = value;
    } else {
        PrizeAmount = levelData.PrizeAmount;
    }

    if (!products || products.length === 0) return null;

    for (let p = 0; p < products.length; p++) {
        totalPreviousWholesale += products[p].pWholesalePrice || 0;
    }

    return round(((EstROI * PrizeAmount) / totalPreviousWholesale) * 100);
}

export function computePrize(levelData, products, name="", value=null) {
    let totalPreviousWholesalePrice = 0;
    let EstROI;
    let IncreasePercentage;

    if (!products || products.length === 0) return null;

    if (name === "EstROI") {
        EstROI = value;
    } else {
        EstROI = levelData.EstROI;
    }
    if (name === "IncreasePercentage") {
        IncreasePercentage = value;
    } else {
        IncreasePercentage = levelData.IncreasePercentage;
    }

    for (let p = 0; p < products.length; p++) {
        totalPreviousWholesalePrice += products[p].pWholesalePrice || 0;
    }

    return (IncreasePercentage * totalPreviousWholesalePrice) / (100 * EstROI);
}
