import { GAME_DURATIONS } from "constants/GameDurations";

export default function getNameForGameDuration(duration) {
    switch (duration.toString()) {
        case GAME_DURATIONS.SEVEN:
            return "7 Days";
        case GAME_DURATIONS.FOURTEEN:
            return "14 Days";
        case GAME_DURATIONS.THIRTY:
            return "30 Days";
        case GAME_DURATIONS.SIXTY:
            return "60 Days";
        case GAME_DURATIONS.NINETY:
            return "90 Days";
    }

    return `${duration} Days`;
}