import React from "react";
import { createRoot } from "react-dom/client";
import "core-js/stable";
import "regenerator-runtime/runtime";
import "global.scss";
import Root from "AppRoot";
import "utils/login";
import * as Sentry from "@sentry/react";
import env from "utils/environment";

class GreenlineApp {
    constructor({rootPath, environment}) {
        env.setEnvironment(environment);
        this.rootPath = rootPath || "";
        const environmentName = env.environment.ENVIRONMENT_NAME;
        if (environmentName !== "greenline-dev") {
            Sentry.init({
                dsn: "https://1ed215243c9a44fb821f9d8d5371db1c@o973525.ingest.sentry.io/5925002",
                environment: environmentName,
                debug: false,
                attachStacktrace: true,
                ignoreErrors: [
                    "ResizeObserver loop limit exceeded",
                    "No matching state found in storage",
                    "Unauthorized",
                    "TypeError: Cannot read properties of null (reading 'zoom')",
                ],
            });
        }
    }

    render() {
        const container = document.getElementById("app");
        const root = createRoot(container);
        root.render(
            <Root
                rootPath={this.rootPath}
                environmentName={env.environment.ENVIRONMENT_NAME}
                isDemo={env.environment.IS_DEMO}
            />
        );
    }
}

__webpack_public_path__ = window.GL_ENV?.staticUrl || "";
window.GreenlineApp = GreenlineApp;
