import React from "react";
import PropTypes from "prop-types";
import {autobind} from "core-decorators";
import classnames from "classnames";
import styles from "./ToggleSwitch.scss";

class ToggleSwitch extends React.Component {
    state = {
        onOff: false,
        notSet: true,
    };
    
    updateFromProps() {
        const notSet = this.props.force === null;
        this.setState({ onOff: this.props.force, notSet });
    }

    componentDidMount() {
        if(this.props.force !== undefined) {
            this.updateFromProps();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.force !== undefined && (prevProps.force !== this.props.force)) {
            this.updateFromProps();
        }
    }

    @autobind
    handleToggleSwitch(e, newState) {
        e.stopPropagation();
        // In case it's housed inside something else with a click event

        if (this.props.disabled) {
            return;
        }

        const onOff = !newState ? !this.state.onOff : (newState === "on");

        this.props.updateState(this.props.id, onOff);
        this.setState({
            onOff,
            notSet: false,
        });
    }

    @autobind
    getStatusClass() {
        if (this.props.useNotSet && this.state.notSet) {
            return styles.toggleNotSet;
        } else if(this.state.onOff) {
            return styles.toggleOn;
        } else {
            return styles.toggleOff;
        }
    }

    @autobind
    getLabels() {
        const { labels, plusMinus } = this.props;
        const useLabels = this.props.labels && this.props.labels.length === 2;

        if(!plusMinus) {
            return { useLabels, labels };
        }

        const labelSet = [];
        labelSet.push(<i className={"fas fa-minus"} />);
        labelSet.push(<i className={"fas fa-plus"} />);
        return { useLabels: true, labels: labelSet };

    }

    render() {
        const { plusMinus } = this.props;
        const statusClass = this.getStatusClass();
        const { useLabels, labels } = this.getLabels();

        return (
            <div className={classnames(styles.root, {
                [styles.centered]: this.props.centered,
                [styles.toggleDisabled]: this.props.disabled,
                [styles.toggleError]: this.props.error,
                [styles.plusMinus]: plusMinus,
                "pointer": !this.props.disabled,
                [styles.noMargin]: this.props.noMargin,
            })}>
                {useLabels &&
                <label
                    data-testid="label-off-button"
                    className={classnames(styles.labelButton, {
                        [styles.negative]: plusMinus,
                    })}
                    onClick={(e) => this.handleToggleSwitch(e, "off")}
                >
                    {labels[0]}
                </label>
                }
                <div
                    data-testid="toggle-button"
                    className={styles.switch}
                    onClick={(e) => this.handleToggleSwitch(e, "")}
                >
                    <div className={classnames(styles.toggleBar, statusClass)}>
                        <div className={styles.handle} />
                    </div>
                </div>
                {useLabels &&
                    <label
                        data-testid="label-on-button"
                        className={classnames(styles.labelButton, {
                            [styles.positive]: plusMinus,
                        })}
                        onClick={(e) => this.handleToggleSwitch(e, "on")}
                    >
                        {labels[1]}
                    </label>
                }
            </div>
        );
    }
}

ToggleSwitch.propTypes = {
    updateState: PropTypes.func.isRequired, // A.K.A `onChange`
    id: PropTypes.string.isRequired,
    force: PropTypes.bool,
    error: PropTypes.bool,
    disabled: PropTypes.bool,
    centered: PropTypes.bool,
    useNotSet: PropTypes.bool,
    plusMinus: PropTypes.bool,
    labels: PropTypes.array,
    noMargin: PropTypes.bool,
};

export default ToggleSwitch;
