import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import map from "lodash/map";
import replace from "lodash/replace";
import uniq from "lodash/uniq";
import classnames from "classnames"
import styles from "./GameCalculations.scss";
import * as ClinicActions from "actions/ClinicActions";
import * as ProviderActions from "actions/ProviderActions";
import DeathMatchReview from "components/provider/widgets/DeathMatchReview";
import GamePrizeEditor from "components/provider/widgets/GamePrizeEditor";
import GameRoiCalculatorTable from "./GameRoiCalculatorTable";
import GameRoiGoalEditor from "components/provider/widgets/GameRoiGoalEditor";
import GameTypeProductMultiSelect from "components/provider/elements/GameTypeProductMultiSelect";
import Image from "components/common/Image";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import ToggleSwitch from "components/common/ToggleSwitch";
import { getCategoryIdForGameProductType } from "utils/getGameTypeDetails";
import { getIsChallengeMatchGame } from "utils/getIsChallengeMatchGame";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { GAME_TYPES, GAME_TYPE_CONFIGS } from "constants/GameTypes";
import * as UserPermissions from "constants/UserPermissions";
const PUPPY_BOWL_IMAGE = require("../../../../../../img/puppy_bowl_example.jpg");
const PUPPY_BOWL_CLINIC_IMAGE = require("../../../../../../img/puppy_bowl_clinic_example.jpg");
const POOL_PARTY_IMAGE = require("../../../../../../img/pool_party_example.jpg");
const POOL_PARTY_CLINIC_IMAGE = require("../../../../../../img/pool_party_clinic_example.jpg");
// TODO: Use new Leaf Party images
const LEAF_PARTY_IMAGE = require("../../../../../../img/leaf_party_leaderboard_example.jpg");
const LEAF_PARTY_CLINIC_IMAGE = require("../../../../../../img/leaf_party_clinic_example.jpg");

function GameCalculations(props) {
    const { pathname } = useLocation();
    const isDemo = includes(pathname, "/demo") && props.canViewClinicBIGamesDashboard;
    const ImageUrl = "https://glcdn.azureedge.net";
    const {data} = props;
    const {config} = data;
    const levels = config ? config.Levels : [];
    let selectedProductIds = flatMap(data.products, "productBrandId");
    const categoryId = getCategoryIdForGameProductType(data.productType);
    const [loading, setLoading] = useState(false);

    const isSeasonalGame = useMemo(() => {
        switch (props.data?.gameType || "unknown") {
            case GAME_TYPES.ON_GARD:
            case GAME_TYPES.PUPPY_BOWL:
            case GAME_TYPES.PUPPY_POOL_PARTY:
            case GAME_TYPES.LEAF_PARTY:
                return true;
            default:
                return false;
        }
    }, [props.data]);

    const builderImages = useMemo(() => {
        if (props.data?.gameType === GAME_TYPES.PUPPY_BOWL && !props.data?.gameSettings?.builderImages) {
            // use this static image
            const gameExampleImage = {
                href: null,
                src: PUPPY_BOWL_IMAGE,
                style: "height: 200px;",
            }
            const clinicExampleImage = {
                href: null,
                src: PUPPY_BOWL_CLINIC_IMAGE,
                style: "height: 200px;",
            }
            return [gameExampleImage, clinicExampleImage];
        } else if (props.data?.gameType === GAME_TYPES.PUPPY_POOL_PARTY && !props.data?.gameSettings?.builderImages) {
            const gameExampleImage = {
                href: null,
                src: POOL_PARTY_IMAGE,
                style: "height: 200px;",
            }
            const clinicExampleImage = {
                href: null,
                src: POOL_PARTY_CLINIC_IMAGE,
                style: "height: 200px;",
            }
            return [gameExampleImage, clinicExampleImage];
        } else if (props.data?.gameType === GAME_TYPES.LEAF_PARTY && !props.data?.gameSettings?.builderImages) {
            // TODO: Update to use LEAF_PARTY IMAGES
            const gameExampleImage = {
                href: null,
                src: LEAF_PARTY_IMAGE,
                style: "height: 200px;",
            }
            const clinicExampleImage = {
                href: null,
                src: LEAF_PARTY_CLINIC_IMAGE,
                style: "height: 200px;",
            }

            return [gameExampleImage, clinicExampleImage];
        }
        return props.data?.gameSettings?.builderImages;
    }, [props.data]);

    const selectedProducts = props.reviewData ? map(selectedProductIds, id => {
        const reviewData = find(props.reviewData.data, d => ((d.productBrandId === id) && !d.weightClassName));
        let product = find(props.productBrands, p => ((p.productBrandId === id)));
        if (!product) {
            product = find(props.gameProductBrands, p => ((p.productBrandId === id)));
        }

        return reviewData || {
            brandName: product.name,
            ...product,
            pPaidDoses: 0,
            pDoses: 0,
            preferredPackageSize: 0,
            preferredPackageUnit: null,
        };
    }) :  [];

    useEffect(() => {
        if (isDemo) {
            props.getDemoGameProductBrands();
        } else if (props.userId && props.data?.gameType) {
            props.getGameProductBrandsForGame(props.userId, props.data.gameType, null);
        }
    }, [isDemo, props.userId, props.data?.gameType]);

    const handleChange = ({ name, value }) => {
        if (name === "products") {
            if (!config?.ProductTypeLocked) {
                props.onChange({ [name]: value });
            }
        } else if(name === "excludeFreeDoses" || name === "includeHomeDeliveryDoses") {
            // For things not in config
            props.onChange({ [name]: value });
        } else {
            //For Everything that is inside of config
            const newConfig = {
                ...config,
                [name]: value,
            }
            props.onChange({["config"]: newConfig});
        }
    }

    const canShowEditor = useMemo(() => Object.keys(props.data||{}).length, [props.data]);

    return (
        <div className={classnames(styles.root, "full-width full-height")}>
            <div className="full-width">
                {builderImages && (
                    <div className={styles.imgContainer}>
                        {map(builderImages, (i, index) => (
                            <div
                                key={`image_${index}`}
                                className={styles.imgs}>
                                {i.href ? (
                                    <a href={replace(i.href, "{cdn}", ImageUrl)} target="_blank">
                                        <Image
                                            src={replace(i.src, "{cdn}", ImageUrl)}
                                            alt=""
                                        />
                                    </a>
                                ):(
                                    <span>
                                        <Image
                                            src={replace(i.src, "{cdn}", ImageUrl)}
                                            alt=""
                                        />
                                    </span>
                                )}
                            </div>
                        ))}
                    </div>
                )}
                {!isSeasonalGame && (
                    <GameTypeProductMultiSelect
                        label="Products"
                        name="products"
                        clinicProducts={uniq(flatMap(props.reviewData?.data, "productBrandId") || [])}
                        value={selectedProductIds}
                        gameType={props.data?.gameType}
                        comparisonPeriod={props.data?.comparisonPeriod}
                        categoryId={categoryId}
                        userId={props.userId}
                        onChange={handleChange}
                        disabled={props.readOnly || !data.productType || config?.ProductTypeLocked}
                        hasRequiredData={!!(data.startDate && data.clinics?.length)}
                        required
                    />
                )}
                <div className="flex spaced-content column-to-row-sm">
                    {!isSeasonalGame && (
                        <div className="flex align-center">
                            <div className="flex-none">
                                <ToggleSwitch
                                    id="excludeFreeDoses"
                                    useNotSet={false}
                                    updateState={() => handleChange({name: "excludeFreeDoses", value: !data.excludeFreeDoses})}
                                    force={!data.excludeFreeDoses}
                                    disabled={props.readOnly || config?.ExcludeFreeDosesLocked}
                                />
                            </div>
                            <span className="flex-1 mobile-sm">Include Free Doses {isSeasonalGame && "when computing Dispensed amounts"}</span>
                        </div>
                    )}

                    {(
                        !isSeasonalGame ||
                        props.data?.gameType === GAME_TYPES.PUPPY_POOL_PARTY ||
                        props.data?.gameType === GAME_TYPES.LEAF_PARTY
                    ) && (
                        <div className="flex align-center">
                            <div className="flex-none">
                                <ToggleSwitch
                                    id="includeHomeDeliveryDoses"
                                    useNotSet={false}
                                    updateState={() => handleChange({name: "includeHomeDeliveryDoses", value: !data.includeHomeDeliveryDoses})}
                                    force={data.includeHomeDeliveryDoses}
                                    disabled={props.readOnly || config?.IncludeHomeDeliveryDosesLocked || !config?.AllowHomeDoses}
                                />
                            </div>
                            <span className="flex-1">Include Home Delivery Doses</span>
                        </div>
                    )}
                </div>
            </div>
            {data.gameType !== GAME_TYPES.ON_GARD && (
                <div className="margin-top-sm">
                    {!canShowEditor ? (
                        <div className={styles.prizes}>
                            <h3 style={{color: "#aaa"}}>No Data Available</h3>
                        </div>
                    ) : (
                        <>
                            {(data.gameType === GAME_TYPES.GROWTH || data.gameType === GAME_TYPES.GROWTH_NGP) ? (
                                <GameRoiGoalEditor
                                    products={selectedProducts}
                                    reviewData={props.reviewData ? props.reviewData.data : []}
                                    excludeFreeDoses={data.excludeFreeDoses}
                                    config={config || {}}
                                    readOnly={props.readOnly}
                                    onChange={handleChange}
                                />
                            ) : (
                                <div className={styles.prizes}>
                                    <GamePrizeEditor
                                        products={selectedProducts}
                                        reviewData={props.reviewData ? props.reviewData.data : []}
                                        excludeFreeDoses={data.excludeFreeDoses}
                                        config={config || {}}
                                        readOnly={props.readOnly}
                                        onChange={handleChange}
                                        arePrizesOptional={!getIsChallengeMatchGame(data?.gameType)}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            )}
            <div className="full-width margin-top-md margin-bottom-sm">
                {GAME_TYPE_CONFIGS[data.gameType]?.isGrowth && (
                    <>
                        <h2>ROI Calculator</h2>
                        <div>
                            {levels ? (
                                <GameRoiCalculatorTable
                                    selectedProducts={selectedProducts}
                                    reviewData={props.reviewData}
                                    excludeFreeDoses={data.excludeFreeDoses}
                                    includeHomeDeliveryDoses={data.includeHomeDeliveryDoses}
                                    levels={levels}
                                />
                            ) : "No Data to Show"}
                        </div>
                    </>
                )}

                {(GAME_TYPE_CONFIGS[data.gameType]?.isChallenge) &&  (
                    <DeathMatchReview
                        data={data}
                        readOnly={props.readOnly}
                        reviewData={props.reviewData}
                    />
                )}
            </div>
            <SpinnerTakeover show={props.areGameProductBrandsLoading || loading} />
        </div>
    );
}

GameCalculations.propTypes = {
    userId: PropTypes.number.isRequired,
    reviewData: PropTypes.object,
    data: PropTypes.object,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        return {
            canViewClinicBIGamesDashboard,
            brands: state.entities.brands,
            products: state.entities.products,
            productBrands: state.entities.gameProductBrands,
        }
    },

    (dispatch) => ({
        getGameProductBrandsForGame: (userId, gameType, productCategoryId) => dispatch(ProviderActions.getGameProductBrandsForGame(userId, gameType, productCategoryId)),
        getDemoGameProductBrands: () => dispatch(ProviderActions.getDemoGameProductBrands()),
    }),
)(GameCalculations);
