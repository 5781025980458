import React, {useEffect, useState, useCallback} from "react";
import PropTypes from "prop-types";
import styles from "./Map.scss";
import difference from "lodash/difference";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import map from "lodash/map";
import { GOOGLE_MAPS_KEY } from "constants/ApiKeys";
import { GoogleMap, LoadScript, Marker, MarkerClusterer } from '@react-google-maps/api';

const DEFAULT_MAP_CENTER = { lat: 37.0902, lng: -95.7129 };
const DEFAULT_MAP_ZOOM = 5;
const DEFAULT_MIN_CLUSTER_SIZE = 5;
const DEFAULT_CLUSTER_MARKER_SIZE = 50;
const DEFAULT_CLUSTER_MARKER_TEXT_SIZE = 20;
const DEFAULT_CLUSTER_MARKER_TEXT_COLOR = "rgb(255, 255, 255)";
const DEFAULT_GREENLINE_BLUE_MARKER = "https://gldevcdn.azureedge.net/images/maps/BluPin2.png";

export default function Map(props) {
    const [googleMap, setGoogleMap] = useState(null);
    const [markerIds, setMarkerIds] = useState([]);
    const isGoogleMapLoaded = !!googleMap;

    useEffect(() => {
        if (isGoogleMapLoaded && props.markers && props.markers.length > 0) {
            const newMarkerIds = flatMap(props.markers, "id");
            // Prevent reloading map if the markers haven't changed
            if (!!difference(newMarkerIds, markerIds).length) {
                setMarkerIds(newMarkerIds);
                if (!props.useCustomBeginPosition) {
                    try {
                        const mapBounds = new window.google.maps.LatLngBounds();
                        forEach(props.markers, marker => {
                            if (marker.lat && marker.lng) {
                                mapBounds.extend({lat: marker.lat, lng: marker.lng});
                            }
                        });
                        googleMap.fitBounds(mapBounds);
                    } catch (e) {
                        console.error(e);
                    }
                }
            }
        }
    }, [props.markers, isGoogleMapLoaded]);

    const notifyBoundsChange = () => {
        if (props.onBoundsChange && isGoogleMapLoaded) {
            const bounds = googleMap.getBounds();
            const ne = bounds.getNorthEast();
            const sw = bounds.getSouthWest();

            props.onBoundsChange({
                northEast: { lat: ne.lat(), lng: ne.lng() },
                southWest: { lat: sw.lat(), lng: sw.lng() },
            });
        }
    };

    const handleMapLoad = useCallback(mapInstance => {
        // A React ref doesn't give us the Map instance (mapRef.current is NOT the Map instance), so we need to get it this way.
        setGoogleMap(mapInstance);
    }, []);

    const handleMarkerClick = (markerId) => {
        if (props.onMarkerClick) {
            props.onMarkerClick(markerId);
        }
    };

    const handleClusterClick = (cluster) => {
        if (props.onClusterClick) {
            props.onClusterClick(cluster);
        }
    };

    const handleZoomChange = (zoomInfo) => {
        if (props.onZoomChanged) {
            props.onZoomChanged(zoomInfo);
        }
        notifyBoundsChange();
    };

    const handleMapDragEnd = () => {
        notifyBoundsChange();
    };

    const getMarkerIcon = (marker) => {
        const highlight = includes(props.highlight, marker.id);
        if(highlight) {
            return {
                path: "M -25, 0 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0",
                fillColor: `rgb(1, 100, 95)`, //TODO: Colors
                fillOpacity: 1,
                strokeWeight: 0,
                rotation: 0
            }
        } else if(marker.icon === "circle") {
            return {
                path: "M -25, 0 a 25,25 0 1,1 50,0 a 25,25 0 1,1 -50,0",
                fillColor: `rgb(1, 100, 95)`, //TODO: Colors
                fillOpacity: 0.8,
                strokeWeight: 0,
                rotation: 0
            }
        } else if(props.markerIcon) {
            return props.markerIcon;
        }

        return DEFAULT_GREENLINE_BLUE_MARKER;
    };

    const handleHoverStart = (marker) => {
        if (props.hoverStart) {
            props.hoverStart(marker)
        }
    };

    const handleHoverEnd = (marker) => {
        if (props.hoverEnd) {
            props.hoverEnd(marker)
        }
    };

    const renderMarkers = (clusterer) => {
        if (isGoogleMapLoaded){
            return map(props.markers, marker => {
                const markerIcon = getMarkerIcon(marker);
                return (
                    <Marker
                        metaData={{id: marker.id}}
                        key={marker.key}
                        position={{
                            lat: marker.lat,
                            lng: marker.lng,
                        }}
                        clusterer={clusterer}
                        onClick={() => handleMarkerClick(marker)}
                        label={marker.label ? {
                            text: marker.label,
                            color: "white",
                            fontsize: "20px",
                            fontWeight: "bold"
                        }  : ""}
                        icon={markerIcon}
                        defaultVisible={false}
                        visible={marker.visible}
                        noRedraw
                        onMouseOver={() => handleHoverStart(marker)}
                        onMouseOut={() => handleHoverEnd(marker)}
                        title={marker.title}
                    >
                        {props.markerChildren && props.markerChildren(marker)}
                    </Marker>
                );
            });
        }
    };

    return (
        <div className={styles.root}>
            <LoadScript googleMapsApiKey={GOOGLE_MAPS_KEY}>
                <GoogleMap
                    mapContainerStyle={{ width: '100%', height: '100%' }}
                    // center={props.center || (googleMap ? googleMap.getCenter() : DEFAULT_MAP_CENTER)}
                    zoom={props.zoom || DEFAULT_MAP_ZOOM}
                    maxZoom={props.maxZoom}
                    onZoomChanged={handleZoomChange}
                    onDragEnd={handleMapDragEnd}
                    onLoad={handleMapLoad}
                    clickableIcons={!props.disableIconClick}
                    options={props.mapOptions}
                >
                    <MarkerClusterer
                        averageCenter={true}
                        minimumClusterSize={DEFAULT_MIN_CLUSTER_SIZE}
                        onClick={(cluster) => handleClusterClick(cluster)}
                        ignoreHidden={true}
                        styles={[{
                            height: props.clusterMarkerSize || DEFAULT_CLUSTER_MARKER_SIZE,
                            width: props.clusterMarkerSize || DEFAULT_CLUSTER_MARKER_SIZE,
                            textSize: props.clusterMarkerTextSize || DEFAULT_CLUSTER_MARKER_TEXT_SIZE,
                            textColor: props.clusterMakerTextColor || DEFAULT_CLUSTER_MARKER_TEXT_COLOR,
                            lineHeight: props.clusterMarkerSize || DEFAULT_CLUSTER_MARKER_TEXT_SIZE,
                        }]}
                    >
                        {(clusterer) => renderMarkers(clusterer)}
                    </MarkerClusterer>
                </GoogleMap>
            </LoadScript>
        </div>
    );
}

Map.propTypes = {
    // MAP OPTIONS
    center: PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
    }),
    zoom: PropTypes.number,
    maxZoom: PropTypes.number,
    onZoomChanged: PropTypes.func,
    mapOptions: PropTypes.object, // See Google Map Options for what can be used
    onBoundsChange: PropTypes.func, // Will receive new map bounds data { northEast: {lat, lng}, southWest: {lat, lng} }
    // CLUSTER OPTIONS
    minClusterSize: PropTypes.number,
    onClusterClick: PropTypes.func,
    clusterMarkerSize: PropTypes.number,
    clusterMakerTextColor: PropTypes.string,
    clusterMarkerTextSize: PropTypes.number,
    clusterMarkerBG: PropTypes.string,
    // MARKER OPTIONS
    markerIcon: PropTypes.string,
    onMarkerClick: PropTypes.func,
    markerChildren: PropTypes.func,
    disableIconClick: PropTypes.bool,
    markers: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.number.isRequired,
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
        title: PropTypes.string, // Rollover text
        icon: PropTypes.string, // Will default to Greenline map marker (url file path)
        label: PropTypes.string, // Adds a label to the marker
        options: PropTypes.object, // See Google Map MarkerOptions for what can be used
    })),
    highlight: PropTypes.arrayOf(PropTypes.number), // List of markers that have a highlight
    hoverStart: PropTypes.func,
    hoverEnd: PropTypes.func,

    // Prevents adjustment of the zoom/position of the map based on the markers when the map is loaded
    // the "center" and "zoom" props will be used to determine the visible portion of the map
    useCustomBeginPosition: PropTypes.bool,
}
