import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import debounce from "lodash/debounce";
import find from "lodash/find";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as ClinicApi from "api/ClinicApi";
import DropdownSearch from "components/common/DropdownSearch";
import { handleErrorResponse } from "utils/request";

export default function SearchEligibleClients(props) {
    const [eligibleClients, setEligibleClients] = useState([]);
    const [loadingEligibleClients, setLoadingEligibleClients] = useState(false);

    const handleSelectClient = ({ name, value }) => {
        // When a client is selected, send back an object with the client data and a list of patients
        const eligibleClientData = find(eligibleClients, { clientId: value });
        props.onChange({ name, value }, eligibleClientData);
    }

    const handleSearchClients = useCallback(debounce((search) => {
        setLoadingEligibleClients(true);
        ClinicApi.getEligibleClients(props.clinicId, { searchString: search })
            .then((res) => {
                setEligibleClients(res.body);
                setLoadingEligibleClients(false);
            })
            .catch((error) => {
                setLoadingEligibleClients(false);
                handleErrorResponse("loading eligible clients", error);
            });
    }, 500), []);

    const clientOptions = map(orderBy(eligibleClients, ["petOwnerFirstName", "petOwnerLastName"], ["asc", "asc"]), client => {
        return {
            dropdownName: (
                <>
                    {client.petOwnerFirstName} {client.petOwnerLastName} {!!client.petOwnerEmail ? `(${client.petOwnerEmail})` : ""}
                </>
            ),
            name: `${client.petOwnerFirstName} ${client.petOwnerLastName} ${!!client.petOwnerEmail ? `(${client.petOwnerEmail})` : ""}`,
            value: client.clientId,
        }
    });

    return (
        <DropdownSearch
            onChange={handleSelectClient}
            onSearchChange={handleSearchClients}
            name={props.name}
            value={props.value}
            options={clientOptions}
            placeholder="Search Clients"
            firstOption="Select a Client"
            loading={loadingEligibleClients}
        />
    );
}

SearchEligibleClients.defaultProps = {
    value: undefined,
    disabled: false,
};

SearchEligibleClients.propTypes = {
    clinicId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number,
    disabled: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};
