import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useHistory, withRouter } from "react-router";
import classnames from "classnames";
import styles from "./UserTable.scss";
import debounce from "lodash/debounce";
import find from "lodash/find";
import map from "lodash/map";
import * as AdminApi from "api/AdminApi";
import * as UserApi from "api/UserApi";
import * as AdminActions from "actions/AdminActions";
import * as CouponActions from "actions/CouponActions";
import * as UserActions from "actions/UserActions";
import AccessDenied from "components/common/AccessDenied";
import DataTablePagination from "components/common/datatable/DataTablePagination";
import Dropdown from "components/common/Dropdown";
import Modal from "components/common/Modal";
import MultiSelectDropdown from "components/common/Dropdown/MultiSelectDropdown";
import ResultsPerPageDropdown from "components/coupons/widgets/ResultsPerPageDropdown";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import UserCreateForm from "components/admin/forms/UserCreateForm";
import UserForm from "components/admin/forms/UserForm";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import LocalData from "utils/LocalData";
import logger from "utils/logger";
import * as ProviderLinks from "utils/ProviderLinks";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import { parseQueryString, setQueryStringParam, setQueryStringParams } from "utils/urls";
import { ROLES } from "constants/RoleTypes";
import * as UserPermissions from "constants/UserPermissions";
import * as Sentry from "@sentry/react";

const STATUS_OPTIONS = [{
    name:"All",
    value:"all"
}, {
    name:"Active",
    value:"active"
}, {
    name: "Inactive",
    value:"inactive"
}];

function UsersTable(props) {
    const history = useHistory();
    const didMountRef = useRef(false);
    const [editingUser, setEditingUser] = useState(null);
    const [loading, setLoading] = useState(false);

    const parsedFilters = parseQueryString(props.location.search);
    const status = parsedFilters?.status || "active";
    const roles = !!parsedFilters?.roles ? JSON.parse(parsedFilters.roles) : [];
    const clinicId = Number(parsedFilters.clinicId) || null;
    const providerId = Number(parsedFilters.providerId) || null;
    const limit = Number(parsedFilters?.limit) || 25;
    const offset = parsedFilters?.offset || 0;
    const orderDir = parsedFilters?.orderDir || "desc";
    const sortBy = parsedFilters?.orderBy || "userId";
    const search = parsedFilters?.search || "";
    const page = (offset / limit) + 1;
    const totalPages = props.totalRecordCount ? Math.ceil(props.totalRecordCount / limit) : 0;
    const canGoNext = (page < totalPages);
    const canGoPrevious = (page > 1);

    useEffect(() => {
        props.getProviderList();
    }, []);

    const load = useCallback(debounce((data) => {
        if (props.canViewUserManagement) {
            // Handle Search and Filters update
            const {search, clinicId, providerId, limit, roles, status, offset, orderBy, orderDir} = data;
            // Only search if there was a change
            if(
                props.lastSearch.search !== search ||
                props.lastSearch.clinicId !== clinicId ||
                props.lastSearch.providerId !== providerId ||
                props.lastSearch.limit !== limit ||
                props.lastSearch.roles !== roles ||
                props.lastSearch.status !== status ||
                props.lastSearch.offset !== offset ||
                props.lastSearch.orderBy !== orderBy ||
                props.lastSearch.orderDir !== orderDir
            ) {
                logger.log("NOT A MATCH -- Reload users")
                const searchValues = {
                    clinicId,
                    providerId,
                    limit,
                    roles,
                    status,
                    offset,
                    orderBy,
                    orderDir,
                    search,
                }

                props.loadUsersList(searchValues);
            }
        }
    }, 1500), [props.lastSearch]);

    useEffect(() => {
        // Only update the search URL after the first rendering
        if(didMountRef.current) {
            if ((parsedFilters?.search || props.search) && parsedFilters?.search !== props.search) {
                let newQs;
                if ((props.lastSearch.search || props.search) && props.lastSearch.search !== props.search) {
                    // When the search is changed, update the url and offset
                    newQs = setQueryStringParams(props.location.search, {search: props.search, offset: 0});
                    props.history.replace(`${props.location.pathname}?${newQs}`);
                } else {
                    // Update URL "search" value
                    newQs = setQueryStringParam(props.location.search, "search", props.search);
                    props.history.replace(`${props.location.pathname}?${newQs}`);
                }
            }
        } else {
            didMountRef.current = true;
        }
    }, [props.search]);

    const parseUrlAndLoad = () => {
        if(parsedFilters?.search !== props.search) {
            props.setForceSearchValue(search);
        }
        load({search, clinicId, providerId, limit, roles, status, offset, orderBy: sortBy, orderDir});
    }

    useEffect(() => {
        // If the URL query params are changed then reload the page
        parseUrlAndLoad();
    }, [props.location.search]);


    const PROVIDER_OPTIONS = map(props.providers, p => {
        return {
            name: p?.name || "Unknown",
            value: p.id
        }
    });

    const handleGoNext = () => {
        // Update the URL
        const newOffset = Number(offset) + limit;
        const newQs = setQueryStringParam(props.location.search, "offset", newOffset);
        props.history.replace(`${props.location.pathname}?${newQs}`);
    }

    const handleGoPrevious = () => {
        // Update the URL
        const newOffset = offset - limit;
        const newQs = setQueryStringParam(props.location.search, "offset", newOffset);
        props.history.replace(`${props.location.pathname}?${newQs}`);
    }

    const handleParamChanged = ({name, value}) => {
        // Update the params
        const newQs = setQueryStringParam(props.location.search, name, value);
        props.history.replace(`${props.location.pathname}?${newQs}`);
    }

    const handleRolesChanged = ({name, value}) => {
        // Update the roles param
        const newRoles = JSON.stringify(value);
        handleParamChanged({name, value:newRoles})
    };

    const handleEditUser = (e, row) => {
        e.stopPropagation();
        e.preventDefault();
        setEditingUser(row);
    };

    const handleUpdateRecentUsers = (user) => {
        props.setRecentUser({
            id: user.userId,
            username: user.userName,
            fullName: `${user.firstName} ${user.lastName}`,
            clinicId: user.clinicId,
            locationType: user.locationType,
            nodeId: user.nodeId,
            providerId: user.providerId,
            territoryCode: null,
            timeStamp: new Date()
        });
    }

    const handleImpersonate = async (e, row) => {
        if (props.canImpersonateAccount) {
            e.stopPropagation();
            e.preventDefault();
            await handleUpdateRecentUsers(row);
            Sentry.setContext("impersonating", {
                originalUserId: props.userProfile.id,
                originalUserRoles: props.userProfile.roles,
            });
            props.setUserUnknown(); // This clears the current user from state.
            // Sentry.captureMessage("Impersonation Initiated");
            AdminApi.impersonateUser(row.userName)
                .then(res => {
                    LocalData.setToken(res.body.token);
                    props.impersonateUser(res.body.token);
                    props.history.push("/");
                })
                .catch(error => {
                    handleErrorResponse("attempting to impersonate", error);
                });
        }
    }

    const getRole = (role) => {
        return find(ROLES, r => {
            if(r.value.toUpperCase() === role.toUpperCase()) {
                return r
            }
        })
    }

    const canImpersonate = (row) => {
        // TODO: lockdown impersonate or add a impersonation fail page with tht reason that the user could not be impersonated.
        if(props.canImpersonateAccount && (row.userId !== props.userProfile.id)) {
            switch(row.role) {
                // case CLINIC_ADMIN:
                // case CLINIC_OWNER:
                // case CLINIC_STAFF:
                // case CLINIC_USER:
                //     if(row.clinicId) {
                //         return true;
                //     }
                //     break;
                // case PROVIDER_ADMIN:
                // case PROVIDER_MANAGER:
                // case PROVIDER_RD:
                // case PROVIDER_DM:
                // case PROVIDER_USER:
                //     if(row.nodeId) {
                //         return true
                //     }
                //     break;
                default:
                    return true;
            }
        }
        return false;
    }

    // export const CLINIC_GUEST = "ClinicGuest";
// export const CLINIC_DEMO = "ClinicDemo";
// export const SITE_ADMIN = "superadmin";
// export const GL_ADMIN = "GLAdmin"

    const COLUMNS = [{
        name: "ID",
        selector: "userId",
        key: "userId",
        sortable: true,
    },{
        name: "First Name",
        selector: "firstName",
        key: "firstName",
        sortable: true,
        searchable: true,
    }, {
        name: "Last Name",
        selector: "lastName",
        key: "lastName",
        sortable: true,
        searchable: true,
    }, {
        name: "Username",
        selector: "userName",
        key: "userName",
        sortable: true,
        searchable: true,
    }, {
        name: "Email",
        selector: "email",
        key: "email",
        sortable: true,
        searchable: true,
    }, {
        name: "Location",
        selector: "locationName",
        key: "locationName",
        sortable: true,
        searchable: true,
        format: row => (
            <div className="nowrap">
                {!!row.locationType && `${row.locationType}:`} {row.locationName} {!!row.clinicId && `(${row.clinicId})`}
            </div>
        ),
    }, {
        name: "Role",
        selector: "role",
        key: "role",
        sortable: true,
        format: row => <div className="nowrap">{row.role ? getRole(row.role)?.name : "--"}</div>,
    }, {
        name: "Active",
        selector: "isActive",
        key: "isActive",
        format: row => (
            <div className="text-center">
                {row.isActive ? (
                    <i className="far fa-check-circle text-success"/>
                ) : (
                    <i className="far fa-times-circle text-danger"/>
                )}
            </div>
        )
    }, {
        name: "Actions",
        selector: "actions",
        key: "actions",
        format: row => (
            <div className="flex spaced-content">
                {(row.canEdit && props.canEditUserManagement) && (
                    <div
                        className="text-primary pointer"
                        onClick={(e) => {handleEditUser(e, row)}}
                        title="Edit User"
                    >
                        <i className="fa fa-pencil"/>
                    </div>
                )}
                {canImpersonate(row) && (
                    <div
                        className="text-center"
                        title="Impersonate User"
                    >
                        <a href="#"
                           title="Impersonate"
                           onClick={(e) => {handleImpersonate(e, row)}}
                           className="btn btn-text text-primary"
                        >
                            <i className="far fa-eye"/>
                        </a>
                    </div>
                )}
            </div>
        )
    }];

    const handleRowClick = async (row) => {
        await handleUpdateRecentUsers(row);
        if(row.providerId && row.nodeId){
            props.setFromUserManagement();
            history.push(ProviderLinks.getLinkLocation("dashboard", {
                providerId: row.providerId,
                nodeId: row.nodeId,
                isAdmin: true
            }));
        } else if(row.clinicId){
            props.setFromUserManagement();
            history.push(ClinicAdminLinks.getLinkLocation("dashboard", row.clinicId));
        }
    };

    const handleSort = (sort, direction) => {
        // Update the URL
        let newQs;
        newQs = setQueryStringParam(props.location.search, "offset", 0);
        props.history.replace(`${props.location.pathname}?${newQs}`);
        newQs = setQueryStringParam(props.location.search, "orderBy", sort);
        props.history.replace(`${props.location.pathname}?${newQs}`);
        newQs = setQueryStringParam(props.location.search, "orderDir", direction);
        props.history.replace(`${props.location.pathname}?${newQs}`);
    }

    const handleUpdateUser = (user) => {
        if (props.canEditUserManagement) {
            setLoading(true);
            UserApi.updateUser(user)
                .then((res) => {
                    load({search, clinicId, providerId, limit, roles, status, offset, orderBy: sortBy, orderDir});
                    toast.success("User updated successfully");
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("updating user", error);
                    setLoading(false);
                });
        }
        setEditingUser(null);
    };

    const handleCreateUser = (user) => {
        props.createUser(user);
        props.hideAddForm();
    };

    return (
        <div className={styles.root}>
            <div className="flex spaced-content">
                <div className={classnames(styles.filters, "flex-2 flex margin-bottom-sm spaced-content column-to-row-sm")}>
                    <div className="flex-1 text-sm">
                        <Dropdown
                            options={STATUS_OPTIONS}
                            label="Status"
                            value={status}
                            name="status"
                            onChange={handleParamChanged}
                            fullWidth
                        />
                    </div>
                    <div className="flex-1 text-sm">
                        <MultiSelectDropdown
                            options={ROLES}
                            label="Roles"
                            name="roles"
                            placeholder="--Select Roles--"
                            value={roles}
                            onChange={handleRolesChanged}
                            xSmall
                        />
                    </div>
                    <div className="flex-1 text-sm">
                        <Dropdown
                            label="Provider"
                            options={PROVIDER_OPTIONS}
                            name="providerId"
                            placeholder="--Select Provider--"
                            value={providerId}
                            onChange={handleParamChanged}
                        />
                    </div>
                    <div className="flex-1 text-sm">
                        <TextBox
                            label="Clinic ID"
                            value={clinicId}
                            name="clinicId"
                            onChange={handleParamChanged}
                            fullWidth
                            placeholder="Enter Clinic ID"
                        />
                    </div>
                    <div className="flex-none">
                        <ResultsPerPageDropdown
                            label="Results"
                            placeHolder="Results Per Page"
                            onChange={({value}) => handleParamChanged({name: "limit", value})}
                            value={limit}
                        />
                    </div>
                </div>
                <div className="flex-1 mobile-hide"/>
            </div>
            <SortableDataTable
                columns={COLUMNS}
                rawData={props.users}
                onClick={handleRowClick}
                allowSearch
                resultsPerPage={limit}
                hideSearchBar
                onSort={handleSort}
                getRowIsDisabled={row => !((row.providerId && row.nodeId) || row.clinicId)}
            />
            <div>
                {totalPages > 1 && (
                    <DataTablePagination
                        canGoNext={canGoNext}
                        onNext={handleGoNext}
                        canGoPrevious={canGoPrevious}
                        onPrevious={handleGoPrevious}
                        page={page}
                        totalPages={totalPages}
                        minimal={props.minimalPagination}
                    />
                )}
            </div>
            <Modal
                show={!!editingUser}
                onClose={() => setEditingUser(null)}
                small
                modalTitle="Edit User"
            >
                {props.canEditUserManagement ? (
                    <UserForm
                        initialData={editingUser}
                        onSubmit={handleUpdateUser}
                        onCancel={() => setEditingUser(null)}
                    />
                ) : (
                    <AccessDenied/>
                )}
            </Modal>
            <Modal
                show={props.addNewForm}
                onClose={() => props.hideAddForm()}
                small
                modalTitle="Create User"
            >
                {props.canCreateUserManagement ? (
                    <UserCreateForm
                        onSubmit={handleCreateUser}
                        onCancel={() => props.hideAddForm()}
                    />
                ) : (
                    <AccessDenied/>
                )}
            </Modal>
            <SpinnerTakeover show={loading}/>
        </div>
    );
}

const connector = connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const lastSearch = state.entities.lastUsersSearchParams;
        // PERMISSIONS
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);
        const canCreateUserManagement = userHasPermission(PermissionTypes.CREATE, UserPermissions.USER_MANAGEMENT, userProfile);
        const canEditUserManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.USER_MANAGEMENT, userProfile);
        const canImpersonateAccount = userHasPermission(PermissionTypes.IMPERSONATE, UserPermissions.ACCOUNT, userProfile);
        return {
            userProfile,
            lastSearch,
            addNewForm: state.entities.adminTools.addNewForm || false,
            providers: state.entities.providers,
            search: state.entities.searchValues?.userManagement || null,
            totalRecordCount: lastSearch.totalRecordCount,
            users: state.entities.userManagement,
            // PERMISSIONS
            canViewUserManagement,
            canCreateUserManagement,
            canEditUserManagement,
            canImpersonateAccount,
        }
    },
    (dispatch) => ({
        setUserUnknown: () => dispatch(UserActions.setUserUnknown()),
        setFromUserManagement: () => dispatch(AdminActions.setFromUserManagement(true)),
        createUser: (user) => dispatch(UserActions.createUser(user)),
        hideAddForm: () => dispatch(AdminActions.hideAddForm()),
        impersonateUser: (token) => dispatch(AdminActions.impersonateUser(token)),
        setRecentUser: (userInfo) => dispatch(UserActions.setRecentUser(userInfo)),
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        loadUsersList: (params) => dispatch(UserActions.loadUsersList(params)),
        setForceSearchValue: (value) => dispatch(AdminActions.setForceSearchValue(value)),
    })
);

export default compose(
    withRouter,
    connector
)(UsersTable);
