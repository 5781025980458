import * as MappingApi from "api/MappingApi";
import * as ActionTypes from "constants/ActionTypes";
import toast from "utils/toast";
import {
    mapProductOverridesFromServerToApp,
    mapVetCheckContentFromServerToApp,
    mapVetCheckSingleContentFromServerToApp,
    mapVaccinesFromServerToApp,
    mapVaccineMappingsFromServerToApp,
    mapServiceMappingsFromServerToApp,
    mapVetCheckHandoutMappingsFromServerToApp,
    mapPharmacyProductsFromServerToApp,
    mapFoodProductsFromServerToApp,
    mapProductMappingsFromServerToApp,
    mapWellnessMappingsFromServerToApp,
} from "data/serverMapping";
import {handleErrorResponse} from "utils/request";
import * as VetCheckApi from "api/VetCheckApi";
import * as ApplicationStateActions from "./ApplicationStateActions";
import {getDefaultErrorHandler} from "./base";

export function searchProducts(query) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());

        MappingApi.searchProducts(query)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.PRODUCTS_LOADED,
                    data: res.body,
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading products")(error);
            });
    }
}

export function loadWellness() {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        MappingApi.loadWellness()
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.WELLNESS_LOADED,
                    data: res.body,
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading wellness")(error);
            });
    }
}

export function loadWellnessMappings(clinicId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());

        MappingApi.loadWellnessMappings(clinicId)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.WELLNESS_MAPPINGS_LOADED,
                    mappings: mapWellnessMappingsFromServerToApp(res.body),
                    clinicId
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading wellness mappings")(error);
            });
    }
}

export function createWellnessMapping(data) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.WELLNESS_MAPPINGS_LOADING,
        });
        MappingApi.createWellnessMapping(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.WELLNESS_MAPPING_CREATED,
                    mapping: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("creating wellness mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateWellnessMapping(data) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.WELLNESS_MAPPINGS_LOADING,
        });

        MappingApi.updateWellnessMapping(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.WELLNESS_MAPPING_UPDATED,
                    mapping: res.body,
                    oldMappingInstanceId: data.mappingInstanceId,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating wellness mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function deleteWellnessMapping(mappingInstanceId, clinicId, serviceTypeId, clinicPriceScheduleId) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.WELLNESS_MAPPINGS_LOADING,
        });

        MappingApi.deleteWellnessMapping(mappingInstanceId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.WELLNESS_MAPPING_DELETED,
                    data: {
                        mappingInstanceId,
                        clinicId,
                        serviceTypeId,
                        clinicPriceScheduleId
                    },
                });
            })
            .catch((error) => {
                handleErrorResponse("deleting wellness mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function addMapping(mapping) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.MAPPING_ADDED,
            data: mapping,
        });
    }
}

export function deleteMapping(mappingId) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.MAPPING_DELETED,
            mappingId: mappingId,
        });
    }
}

export function getProductOverrides({ clinicId, productId }) {
    return (dispatch) => {
        MappingApi.getClinicProductOverrides(clinicId, productId)
            .then(res => {
                dispatch({
                    type: ActionTypes.PRODUCT_OVERRIDE_LOADED,
                    data: mapProductOverridesFromServerToApp(res.body),
                    clinicId,
                    productId,
                });
            })
            .catch(error => {
                handleErrorResponse("loading PAR product overrides for clinic", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

// The list of products a clinic can override - individual product override data is separate, see getProductOverrides().
export function getClinicOverrides(clinicId) {
    return (dispatch) => {
        MappingApi.getClinicOverrides(clinicId)
            .then(res => {
                dispatch({
                    type: ActionTypes.ALL_CLINIC_PRODUCT_OVERRIDES_LOADED,
                    data: res.body,
                    clinicId,
                });
            })
            .catch(error => {
                handleErrorResponse("loading clinic overrides", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateProductOverrides(formData, reasonCode) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.PRODUCT_OVERRIDE_UPDATE_REQUESTED,
        });

        MappingApi.updateProductOverrides(formData)
            .then((res) => {
                toast.success("Overrides updated successfully");
                dispatch({
                    type: ActionTypes.PRODUCT_OVERRIDE_UPDATED,
                    data: formData, // Data flow requires formData to come back after this, otherwise explosion (see PriceOverridesForm.jsx)
                    reasonCode: reasonCode
                });
            })
            .catch((error) => {
                handleErrorResponse("loading updating overrides", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }

    // return (dispatch) => {
    //     dispatch({
    //         type: ActionTypes.PRODUCT_OVERRIDE_UPDATE_REQUESTED,
    //     });
    //     // TODO: REMOVE - Added to give appearance of submit
    //     setTimeout(() => {
    //         toast.success("Overrides updated successfully");
    //         dispatch({
    //             type: ActionTypes.PRODUCT_OVERRIDE_UPDATED,
    //             data: data,
    //         });
    //     }, 300);
    // }
}

export function getPIMs(clinicId=null, limit) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        MappingApi.getPIMs(clinicId, limit)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.PIMS_LOADED,
                    results: res.body,
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading PIMS")(error);
            });
    }
}

// FOOD MAPPING
export function loadFoodProducts() {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        MappingApi.loadFoodProducts()
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.FOOD_PRODUCTS_LOADED,
                    data: mapFoodProductsFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "oading food products")(error);
            });
    }
}

export function loadFoodMappings(clinicId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        MappingApi.loadFoodMappings(clinicId)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.FOOD_MAPPINGS_LOADED,
                    mappings: mapProductMappingsFromServerToApp(res.body),
                    clinicId: clinicId
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading food mappings")(error);
            });
    }
}

export function createFoodMapping(data, clinicId, order=1) {
    return (dispatch) => {
        MappingApi.createFoodMapping(data, clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.FOOD_MAPPING_CREATED,
                    mapping: res.body,
                    order,
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("creating food mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateFoodMapping(data, clinicId, oldClinicProductId, order) {
    //Update only works for fields other than clinicProductId.
    if(oldClinicProductId === data.clinicProductId) {
        return (dispatch) => {
            MappingApi.updateFoodMapping(data, clinicId)
                .then((res) => {
                    dispatch({
                        type: ActionTypes.FOOD_MAPPING_UPDATED,
                        mapping: {
                            ...res.body,
                            order,
                        },
                        clinicId,
                    });
                })
                .catch((error) => {
                    handleErrorResponse("updating food mapping", error);
                    dispatch({
                        type: ActionTypes.REQUEST_ERROR,
                        error,
                    });
                });
        }
    } else {
        return (dispatch) => {
            MappingApi.createFoodMapping(data, clinicId)
                .then((res) => {
                    MappingApi.deleteFoodMapping(oldClinicProductId)
                        .then((result) => {
                            dispatch({
                                type: ActionTypes.FOOD_MAPPING_CREATED_AND_DELETED,
                                mapping: {
                                    ...res.body,
                                    order,
                                },
                                clinicId,
                                productId: data.productId,
                                clinicProductId: oldClinicProductId
                            });
                        })
                        .catch((error) => {
                            handleErrorResponse("deleting food  mapping", error);
                            dispatch({
                                type: ActionTypes.REQUEST_ERROR,
                                error,
                            });
                        });
                })
                .catch((error) => {
                    handleErrorResponse("create food mapping", error);
                    dispatch({
                        type: ActionTypes.REQUEST_ERROR,
                        error,
                    });
                });

        }
    }
}

export function deleteFoodMapping(clinicProductId, clinicId, productId) {
    return (dispatch) => {
        MappingApi.deleteFoodMapping(clinicProductId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.FOOD_MAPPING_DELETED,
                    clinicId,
                    productId,
                    clinicProductId
                });
            })
            .catch((error) => {
                handleErrorResponse("deleting food mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

// PHARMACY MAPPING
export function loadPharmacyProducts() {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        MappingApi.loadPharmacyProducts()
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.PHARMACY_PRODUCTS_LOADED,
                    data: mapPharmacyProductsFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading pharmacy products")(error);
            });
    }
}

export function loadPharmacyMappings(clinicId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());

        MappingApi.loadPharmacyMappings(clinicId)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.PHARMACY_MAPPINGS_LOADED,
                    mappings: mapProductMappingsFromServerToApp(res.body),
                    clinicId: clinicId,
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading pharmacy mappings")(error);
            });
    }
}

export function createPharmacyMapping(data, clinicId, order=1) {
    return (dispatch) => {
        MappingApi.createPharmacyMapping(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PHARMACY_MAPPING_CREATED,
                    mapping: res.body,
                    order,
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("creating pharmacy mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updatePharmacyMapping(data, clinicId, oldClinicProductId, order) {
    //Update only works for fields other than clinicProductId.
    if(oldClinicProductId === data.clinicProductId) {
        return (dispatch) => {
            MappingApi.updatePharmacyMapping(data, clinicId)
            .then((res) => {
                toast.success("Mapping Saved");
                dispatch({
                    type: ActionTypes.PHARMACY_MAPPING_UPDATED,
                    mapping: {
                        ...res.body,
                        order,
                    },
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("updating pharmacy mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
        }
    } else {
        return (dispatch) => {
            MappingApi.createPharmacyMapping(data, clinicId)
                .then((res) => {
                    MappingApi.deletePharmacyMapping(oldClinicProductId)
                        .then((results) => {
                            dispatch({
                                type: ActionTypes.PHARMACY_MAPPING_CREATED_AND_DELETED,
                                mapping: {
                                    ...res.body,
                                    order,
                                },
                                clinicId,
                                productId: data.productId,
                                clinicProductId: oldClinicProductId
                            });
                        })
                        .catch((error) => {
                            handleErrorResponse("deleting pharmacy mapping", error);
                            dispatch({
                                type: ActionTypes.REQUEST_ERROR,
                                error,
                            });
                        });
                })
                .catch((error) => {
                    handleErrorResponse("creating pharmacy mapping", error);
                    dispatch({
                        type: ActionTypes.REQUEST_ERROR,
                        error,
                    });
                });

        }
    }
}

export function deletePharmacyMapping(clinicProductId, clinicId, productId) {
    return (dispatch) => {
        MappingApi.deletePharmacyMapping(clinicProductId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PHARMACY_MAPPING_DELETED,
                    clinicId,
                    productId,
                    clinicProductId
                });
            })
            .catch((error) => {
                handleErrorResponse("deleting pharmacy mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

// Vaccine Mapping
export function loadVaccines() {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        MappingApi.loadVaccines()
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.VACCINES_LOADED,
                    data: mapVaccinesFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading vaccines")(error);
            });
    }
}

export function loadVaccineMappings(clinicId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        MappingApi.loadVaccineMappings(clinicId)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.VACCINE_MAPPINGS_LOADED,
                    mappings: mapVaccineMappingsFromServerToApp(res.body),
                    clinicId
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading vaccine mappings")(error);
            });
    }
}

export function createVaccineMapping(data, clinicId, order=1) {
    return (dispatch) => {
        MappingApi.createVaccineMapping(data, clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.VACCINE_MAPPING_CREATED,
                    mapping: res.body,
                    order,
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("creating vaccine mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateVaccineMapping(data, clinicId, order) {
    return (dispatch) => {
        MappingApi.updateVaccineMapping(data, clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.VACCINE_MAPPING_UPDATED,
                    mapping: {
                        ...res.body,
                        order,
                    },
                    clinicId,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating vaccine mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function deleteVaccineMapping(clinicProductMappingId, clinicId, vaccineId) {
    return (dispatch) => {
        MappingApi.deleteVaccineMapping(clinicProductMappingId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.VACCINE_MAPPING_DELETED,
                    clinicId,
                    vaccineId,
                    clinicProductMappingId
                });
            })
            .catch((error) => {
                handleErrorResponse("deleting vaccine mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

// Exam Service Mapping Page
export function loadExamServices() {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        MappingApi.loadExamServices()
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.SERVICES_LOADED,
                    data: res.body,
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading exam services")(error);
            });
    }
}

export function loadExamServiceMappings(clinicId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        MappingApi.loadServiceMappings(clinicId)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.SERVICE_MAPPINGS_LOADED,
                    mappings: mapServiceMappingsFromServerToApp(res.body),
                    clinicId
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading exam service mappings")(error);
            });
    }
}

export function createExamServiceMapping(data, clinicId, order=1) {
    return (dispatch) => {
        MappingApi.createExamServiceMapping(data, clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.SERVICE_MAPPING_CREATED,
                    mapping: res.body,
                    order,
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("creating exam service mappings", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateExamServiceMapping(data, clinicId, order) {
    return (dispatch) => {
        MappingApi.updateExamServiceMapping(data, clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.SERVICE_MAPPING_UPDATED,
                    mapping: {
                        ...res.body,
                        order,
                    },
                    clinicId,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating exam service mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function deleteExamServiceMapping(mappingId, clinicId, examServiceId) {
    return (dispatch) => {
        MappingApi.deleteExamServiceMapping(mappingId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.SERVICE_MAPPING_DELETED,
                    clinicId,
                    examServiceId,
                    mappingId
                });
            })
            .catch((error) => {
                handleErrorResponse("deleting exam service mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

// VetCheck Mapping Page
export function getVetCheckContent(clinicId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        VetCheckApi.getVetCheckContent(clinicId)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.CLINIC_VETCHECK_CONTENT_LOADED,
                    clinicId,
                    content: mapVetCheckContentFromServerToApp(res.body)
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading VetCheck content")(error);
            });
    }
}

export function getVetCheckContentById(clinicId, vetCheckContentId, contentType) {
    return (dispatch) => {
        VetCheckApi.getVetCheckContentById(clinicId, vetCheckContentId, contentType)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_VETCHECK_SINGLE_CONTENT_LOADED,
                    clinicId,
                    content: mapVetCheckSingleContentFromServerToApp(res.body)
                });
            })
            .catch((error) => {
                handleErrorResponse("loading VetCheck content by ID", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getVetCheckMappings(clinicId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        VetCheckApi.getVetCheckMappings(clinicId)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.CLINIC_VETCHECK_MAPPINGS_LOADED,
                    mappings: mapVetCheckHandoutMappingsFromServerToApp(res.body),
                    clinicId
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading VetCheck mappings")(error);
            });
    }
}

export function createVetCheckMapping(data, clinicId, order) {
    return (dispatch) => {
        VetCheckApi.createVetCheckMapping(data, clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_VETCHECK_MAPPING_CREATED,
                    mapping: res.body,
                    order,
                    clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("creating VetCheck handout mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateVetCheckMapping(data, clinicId, order) {
    return (dispatch) => {
        VetCheckApi.updateVetCheckMapping(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_VETCHECK_MAPPING_UPDATED,
                    mapping: {
                        ...res.body,
                        order,
                    },
                    clinicId,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating VetCheck handout mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function deleteVetCheckMapping(mappingId, contentType, clinicId, clinicHandoutId) {
    return (dispatch) => {
        VetCheckApi.deleteVetCheckMapping(mappingId, contentType)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_VETCHECK_MAPPING_DELETED,
                    clinicId,
                    clinicHandoutId,
                    mappingId
                });
            })
            .catch((error) => {
                handleErrorResponse("deleting VetCheck handout mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}
