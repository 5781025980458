import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import styles from "./ToolbarProviderSearch.scss";
import filter from "lodash/filter";
import flatten from "lodash/flatten";
import includes from "lodash/includes";
import map from "lodash/map";
import * as ProviderApi from "api/ProviderApi";
import * as AdminActions from "actions/AdminActions";
import * as ProviderActions from "actions/ProviderActions";
import * as UserActions from "actions/UserActions";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import SearchResults from "components/layout/widgets/SearchResults";
import TextBox from "components/common/TextBox";
import { getProvidersWithDashboardInfo } from "utils/AdminData";
import getProviderId from "utils/getProviderId";
import * as ProviderLinks from "utils/ProviderLinks";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as UserPermissions from "constants/UserPermissions";

function ToolbarProviderSearch(props) {
    const history = useHistory();
    const params = useParams();
    const providerId = getProviderId(params, props.userProfile);
    const provider = props.providers?.[providerId] || {};
    const providerHierarchy = props.providerHierarchies?.[providerId] || {};
    const topNodes = filter(providerHierarchy, p => !p?.parentNodeId);
    const [topLevelNodeId, setTopLevelNodeId] = useState(props.nodeId);
    const [loading, setLoading] = useState(null);
    const [disabled, setDisabled] = useState([]);
    const [search, setSearch] = useState("");
    const [showSearch, setShowSearch] = useState(false);

    useEffect(() => {
        if (!!props.nodeId) {
            let topLevelNode = providerHierarchy?.[props.nodeId];
            //Get the top level node
            if (!!topLevelNode?.parentNodeId) {
                while (!!topLevelNode?.parentNodeId) {
                    topLevelNode = providerHierarchy?.[topLevelNode?.parentNodeId];
                }
                setTopLevelNodeId(topLevelNode?.nodeId);
            } else {
                setTopLevelNodeId(props.nodeId);
            }
        }
    }, [props.nodeId]);

    const NODE_TYPE_OPTIONS = topNodes?.length ? map(topNodes, h => {
        return {name: h.nodeType, value: h.nodeId}
    }) : [];

    if(!provider?.name) {
        return null;
    }

    // Remove providers that were disabled for whatever reason
    const filteredProviders = () => {
        return filter(props.providers, p => !includes(disabled, p.id));
    }

    const getFilteredProviders = () => {
        if(search) {
            const searchTokens = search.toLowerCase().split(" ");
            return filter(filteredProviders(), (p) => {
                let allMatch = true;
                for (let i = 0; i < searchTokens.length; i++) {
                    const token = searchTokens[i];
                    if (!p.name || !p.name.toString().toLowerCase().includes(token)) {
                        if (!p.id || !p.id.toString().toLowerCase().includes(token)) {
                            if (!p.shortName || !p.shortName.toString().toLowerCase().includes(token)) {
                                allMatch = false;
                            }
                        }
                    }
                }
                return allMatch;
            });
        }
        return filteredProviders();
    }

    const toggleProviderSearch = () => {
        setShowSearch(!showSearch);
    }

    const getProviderHierarchy = async (providerId) => {
        const res = await ProviderApi.getProviderHierarchies(providerId);
        if (!!res?.body?.hierarchyDtos?.length) {
            props.hierarchyLoaded(providerId, res);
            const newTopNodes = filter(flatten(res?.body?.hierarchyDtos), h => !h.parentNodeId);
            if (!!newTopNodes?.length) {
                history.push(ProviderLinks.getLinkLocation("dashboard", {
                    providerId,
                    nodeId: newTopNodes[0]?.nodeId,
                    isAdmin: true
                }));
                toggleProviderSearch();
                setSearch("");
                setLoading(null);
            } else {
                addProviderToDisabled(providerId);
            }
        } else {
            addProviderToDisabled(providerId);
        }
    }

    const addProviderToDisabled = (providerId) => {
        const updatedDisabled = disabled;
        updatedDisabled.push(providerId);
        setDisabled(updatedDisabled);
        setLoading(null);
        const provider = props.providers?.[providerId];
        toast.warn(`(${providerId}) ${provider?.name} has no hierarchy. Please select another provider.`);
    }

    const changeProviderLocation = async (newProviderId) => {
        setLoading(newProviderId);
        // Load the provider hierarchy and get the first topLevel node

        if (props.providerHierarchies?.[newProviderId]) {
            const newTopNodes = filter(props.providerHierarchies?.[newProviderId], h => !h.parentNodeId);
            if (!!newTopNodes?.length) {
                history.push(ProviderLinks.getLinkLocation("dashboard", {
                    providerId: newProviderId,
                    nodeId: newTopNodes[0]?.nodeId,
                    isAdmin: true
                }));
                setSearch("");
                toggleProviderSearch();
                setLoading(null);
            } else {
                await getProviderHierarchy(newProviderId);
            }
        } else {
            await getProviderHierarchy(newProviderId);
        }
    }

    const handleChangeNodeType = ({value}) => {
        history.push(ProviderLinks.getLinkLocation("dashboard", {
            providerId: providerId,
            nodeId: value,
            isAdmin: true
        }));
    }

    return (
        <div className={styles.root}>
            <div className={styles.providerBox}>
                <div className={styles.searchToggle}>
                    <Button
                        onClick={toggleProviderSearch}
                        type="circleIcon"
                        iconOnly
                    >
                        {showSearch ? <i className="fas fa-times" /> : <i className="fas fa-building" />}
                    </Button>
                </div>
                <div className={styles.providerInfo} onClick={() => history.push(ProviderLinks.getLinkLocation("dashboard", { providerId: providerId, nodeId: props.nodeId, isAdmin: true }))}>
                    <h4>{provider?.name}</h4>
                </div>
                <div className={classnames(styles.providerSearchBox, {
                    [styles.showingSearch]: showSearch,
                })}>
                    <div className={styles.providerSearchBoxInner}>
                        {!!search &&
                            <SearchResults
                                results={getFilteredProviders()}
                                clickHandler={changeProviderLocation}
                                clearResults={() => setSearch("")}
                                isProviders
                                positionTop
                                loading={loading}
                                disabled={disabled}
                            />
                        }
                        <div className={styles.textBox}>
                            <TextBox
                                options={[]}
                                placeholder="Search Providers"
                                name="search"
                                value={search}
                                onChange={({value}) => setSearch(value)}
                                disabled={!!loading}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.nodeSelection}>
                {(NODE_TYPE_OPTIONS?.length > 1) && (
                    <Dropdown
                        options={NODE_TYPE_OPTIONS}
                        name="nodeType"
                        onChange={handleChangeNodeType}
                        value={topLevelNodeId}
                        dropdownDirection="up"
                    />
                )}
            </div>

        </div>
    );
}

ToolbarProviderSearch.propTypes = {
    nodeId: PropTypes.number.isRequired,
    searchConfig: PropTypes.object,
};

const connector = connect(
    (state) => {
        const userProfile = state.user.userProfile;
        // Permissions
        const canViewProviderInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_INFO, userProfile);
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);

        return {
            userProfile,
            lastUserSearch: state.entities.lastUsersSearchParams,
            providerHierarchies: state.entities.providerHierarchies,
            providers: getProvidersWithDashboardInfo(state),
            cameFromUserManagement: state.entities.cameFromUserManagement,

            // Permissions
            canViewProviderInfo,
            canViewUserManagement
        }
    },
    (dispatch) => ({
        impersonateUser: (token) => dispatch(AdminActions.impersonateUser(token)),
        setRecentUser: (userInfo) => dispatch(UserActions.setRecentUser(userInfo)),
        hierarchyLoaded: (providerId, res) => dispatch(ProviderActions.providerHierarchyLoaded(providerId, res)),
    })
);

export default compose(
    withRouter,
    connector
)(ToolbarProviderSearch);
