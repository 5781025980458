import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import SortableDataTable from "components/common/SortableDataTable";
import { formatUSD } from "utils/numeric";

export default function GreenlineWellnessPlanTable(props) {

    const COLUMNS = [{
        name: "Active",
        selector: "isActive",
        key: "isActive",
        sortable: true,
        format: row => (
            <div className="flex">
                <i className={classnames("fa-solid fa-2x", {
                    "fa-circle-check text-success": row.isActive,
                   "fa-circle-xmark text-danger": !row.isActive,
                })} />
            </div>
        )
    }, {
        name: "Plan Name",
        selector: "alias",
        key: "alias",
        sortable: true,
    }, {

        name: "Plan Type",
        selector: "groupName",
        key: "groupName",
        sortable: true,
    }, {
        name: (<div className="text-center">Weight Range</div>),
        selector: "weightRange",
        key: "weightRange",
        format: data => (
            <div className="flex flex-centered spaced-content">
                <div>{data.minWeightInPounds || 0}</div>
                <div>-</div>
                {data.maxWeightInPounds ? (
                    <div>{data.maxWeightInPounds}</div>
                ) : (
                    <div className="text-lg">&infin;</div>
                )}
            </div>
        ),
    }, {
        name: (<div className="text-center">Age Range</div>),
        selector: "ageRange",
        key: "ageRange",
        format: data => (
            <div className="flex flex-centered spaced-content">
                <div>{data.minAgeInYears || 0}</div>
                <div>-</div>
                {data.maxAgeInYears ? (
                    <div>{data.maxAgeInYears}</div>
                ) : (
                    <div className="text-lg">&infin;</div>
                )}
            </div>
        ),
    }, {
        name: (<div className="text-center">Monthly Cost</div>),
        selector: "monthlyCostToClient",
        key: "monthlyCostToClient",
        format: data => (<div className="text-center">{data.monthlyCostToClient ? formatUSD(data.monthlyCostToClient) : "-"}</div>),
    }, {
        name: (<div className="text-center">Yearly Cost</div>),
        selector: "yearlyCostToClient",
        key: "yearlyCostToClient",
        format: data => (<div className="text-center">{data.yearlyCostToClient ? formatUSD(data.yearlyCostToClient) : "-"}</div>),
    }];

    return (
        <SortableDataTable
            striped
            green
            allowSearch
            hideSearchBar
            search={props.search}
            columns={COLUMNS}
            rawData={props.data}
            onClick={props.onRowClick}
            largeRows={"lg"}
        />
    );
}

GreenlineWellnessPlanTable.defaultProps = {
    onRowClick: () => {},
    changeIsActive: () => {},
};

GreenlineWellnessPlanTable.propTypes = {
    data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.object]).isRequired,
    onRowClick: PropTypes.func,
    changeIsActive: PropTypes.func,
};
