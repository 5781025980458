import * as MarketingApi from "api/MarketingApi";
import * as ActionTypes from "constants/ActionTypes";
import {handleErrorResponse} from "utils/request";

export function getPetMarketingInfo(data) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PET_MARKETING_INFO_LOADING });

        MarketingApi.getPetMarketingInfo(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PET_MARKETING_INFO_LOADED,
                    petMarketingInfo: res.body,
                    clinicId: data.clinicId
                });
            })
            .catch((error) => {
                handleErrorResponse("loading pet marketing info", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function updatePetMarketingInfo(clinicId, clientId, data, filter) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.PET_MARKETING_INFO_LOADING });

        MarketingApi.updatePetMarketingInfo(clinicId, clientId, data)
            .then((res) => {
                const actionType = filter?.status && filter.status !== data.status ? ActionTypes.PET_MARKETING_INFO_FILTER_OUT :
                    ActionTypes.PET_MARKETING_INFO_UPDATED

                dispatch({
                    type: actionType,
                    clinicId,
                    clientId,
                    data,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating pet marketing info", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}
