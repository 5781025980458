import React, {useEffect} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as styles from "./Keywords.scss";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import Dropdown from "components/common/Dropdown";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function Keywords(props) {
    const handleAdd = ({value}) => {
        const newKeyWords = [...props.selectedKeywords];
        newKeyWords.push(value);
        props.onChange({name: props.name, value: newKeyWords});
    };

    const handleRemove = (id) => {
        const newKeyWords = reject(props.selectedKeywords, s => s === id);
        props.onChange({name: props.name, value: newKeyWords});
    };

    const OPTIONS = map(props.keywords, k => {
        return {
            name: k.keyword,
            value: k.clinicKeywordId
        };
    });

    const orderedOptions = orderBy(reject(OPTIONS, o => {return includes(props.selectedKeywords, o.value)}), ["keyword"], ["asc"]);

    return (
        <div className={styles.root}>
            <div className="flex-1" style={{minWidth: "50px"}}>
                <Dropdown
                    options={orderedOptions}
                    name="selectedKeywords"
                    value={null}
                    onChange={handleAdd}
                    placeholder="Select Keywords"
                />
            </div>
            <div className="flex flex-wrap">
                {map(props.selectedKeywords, (k, index) => {
                    const keyword = props.keywords?.[k]?.keyword;
                    if (keyword) {
                        return (
                            <div key={index} className={styles.keyword}>
                                <div className="flex-none">{keyword}</div>
                                <div className={styles.delete} onClick={() => handleRemove(k)}>
                                    <i className="fal fa-times"/>
                                </div>
                            </div>
                        );
                    } else return null;
                })}
            </div>
        </div>
    );
}

Keywords.propTypes = {
    clinicId: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    selectedKeywords: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        // Permissions
        const canEditVetCheckMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.VETCHECK_MAPPING, userProfile);
        return {
            keywords: state.entities.keywords[ownProps.clinicId],
            // Permissions
            canEditVetCheckMapping
        }
    }
)(Keywords);
