export const LightenDarkenColor = (hex, lum) => {

    // validate hex string
    let hexString = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hexString.length < 6) {
        hexString = hexString[0]+hexString[0]+hexString[1]+hexString[1]+hexString[2]+hexString[2];
    }
    const lumValue = lum/100;

    // convert to decimal and change luminosity
    let rgb = "#";
    let c;

    for (let i = 0; i < 3; i++) {
        c = parseInt(hexString.substr(i*2,2), 16);
        c = Math.round(Math.min(Math.max(0, c + (c * lumValue)), 255)).toString(16);
        rgb += ("00"+c).substr(c.length);
    }

    return rgb;
}
