import React, { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import moment from "moment";
import includes from "lodash/includes";
import * as styles from "./ProgramTile.scss";
import InformationTile from "components/common/InformationTile";
import ProgramOptInDetailsModal from "components/clinic/elements/ProgramOptInDetailsModal";
import { DANGER, GRAY, INACTIVE, SUCCESS } from "constants/CssStyleIds";
import { CANCELED, DECLINED, ELIGIBLE, ENROLLED, INELIGIBLE } from "constants/ProgramStatuses";

export default function ProgramTile(props) {
    const history = useHistory();
    const params = useParams();
    const { pathname } = useLocation();
	const clinicId = Number(params.clinicId);
	const nodeId = Number(params.nodeId);
    const isAdmin = clinicId && includes(pathname, "/admin");
    const isProvider = nodeId && includes(pathname, "/provider");
    // If is an admin then append some string to beginning of URL
	const linkBase = isAdmin ? `/admin/clinic/${clinicId}` : isProvider ? `/provider/location/${nodeId}/clinic/${clinicId}` : '';
    const [showModal, setShowModal] = useState(false);
    const tileStyle = (
        props.tile.status === CANCELED ? INACTIVE :
        props.tile.status === DECLINED ? DANGER :
        props.tile.status === ELIGIBLE ? SUCCESS :
        props.tile.status === INELIGIBLE ? GRAY :
        null
    );

    const handleTileClicked = async () => {
        if (props.tile.acceptedTerms || props.tile.status !== INELIGIBLE) {
            if (props.tile.status === ENROLLED) {
                if (props.parentProgram) {
                    history.push(`${linkBase}/programs/${props.parentProgram}/service/${props.tile.id}`);
                } else {
                    history.push(`${linkBase}/programs/${props.tile.id}`);
                }
            } else if (props.canEditProgramEnrollmentClinic) {
                setShowModal(true);
            }
        }
    };

    return (
        <>
            <div
                className={classnames(styles.tile, {
                    [styles.disabled]: !(props.tile.status === ENROLLED || props.canEditProgramEnrollmentClinic) || (props.tile.status === INELIGIBLE && !props.tile.acceptedTerms),
                })}
                style={props.style}
                onClick={handleTileClicked}
            >
                <div>
                    <InformationTile
                        title={props.tile.name}
                        // TODO: Could use the badge to indicate if there are any services that have not yet been enrolled in
                        // badge={badge}
                        footer={(
                            <div>{props.tile.status}{props.tile.userActionDate ? `: ${moment(props.tile.userActionDate).format("MM/DD/YYYY")}` : ""} </div>
                        )}
                        style={tileStyle}
                    >
                        <img
                            alt={props.tile.name}
                            src={props.tile.image}
                            title={props.tile.name}
                            style={props.imageStyle}
                        />
                    </InformationTile>
                </div>
            </div>
            <ProgramOptInDetailsModal
                key={`Tile_${props.tile.id}`}
                show={showModal}
                onClose={() => setShowModal(false)}
                program={props.tile}
                programId={props.tile.id}
                clinicId={props.clinicId}
                parentProgram={props.parentProgram}
            />
        </>
    )
}

ProgramTile.defaultProps = {
    canEditProgramEnrollmentClinic: false,
    imageStyle: {},
    parentProgram: undefined,
    style: {},
};

ProgramTile.propTypes = {
    canEditProgramEnrollmentClinic: PropTypes.bool,
    clinicId: PropTypes.number.isRequired,
    imageStyle: PropTypes.object,
    parentProgram: PropTypes.number,
    style: PropTypes.object,
    tile: PropTypes.shape({}).isRequired,
};
