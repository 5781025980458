import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";
import DataTable from "components/common/datatable/DataTable";
import {CHEWY, OUR_PRICE, PET_MEDS} from "utils/PriceWatchUtils";

function ProductPriceTable(props) {
    if(!(props.productId && props.brandId)) {
        return(
            <div>Missing Product ID and/or Brand ID</div>
        )
    }

    const getPriceDataById = (row, id) => {
        const priceData = row?.providers?.[id];
        if(!!priceData) {
            return (
                <div title={`Last Checked: ${priceData.modDate}`}>
                    {(!!priceData.productPriceURL) ? (
                        <a href={priceData.productPriceURL}
                           className="text-primary"
                           target="_blank"
                        >
                            {priceData.externalPrice}
                        </a>
                    ) : (
                        <div>
                            {priceData.externalPrice}
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }

    const COLUMNS = [{
        name: "Quantity",
        selector: "quantity",
        key: "quantity"
    }, {
        name: "Chewy",
        selector: "chewyPrice",
        key: "chewyPrice",
        format: (row) => getPriceDataById(row, CHEWY),
    }, {
        name: "PetMed",
        selector: "petMedPrice",
        key: "petMedPrice",
        format: (row) => getPriceDataById(row, PET_MEDS),
    // }, {
    //     name: "Our Price",
    //     selector: "ourPrice",
    //     key: "ourPrice",
    //     format: (row) => getPriceDataById(row, OUR_PRICE),
    }];

    return (
        <div>
            <DataTable
                columns={COLUMNS}
                data={orderBy(props.prices, "quantity")}
                pagination={false}
                green
            />
        {/*    TODO: Show applicable coupon information here*/}
        </div>
    );
}

ProductPriceTable.propTypes = {
    brandId: PropTypes.number.isRequired,
    productId: PropTypes.number.isRequired,
};

export default connect(
    (state, ownProps) => ({
        prices: state.entities.comparablePrices?.[ownProps.brandId]?.products?.[ownProps.productId]?.prices
    })
)(ProductPriceTable);
