import { times } from "lodash";
import React from "react";
import PropTypes from "prop-types";
import styles from "./OnGardGameStampTier.scss";

const FREE_STAMP_IMAGE = require("../../../../../img/ongard-free-stamp.png");
const STAMP_IMAGE = require("../../../../../img/ongard-stamp.png");

export default function OnGardGameStampTier(props) {
    const { pointIncrement, startPoints, totalPoints, currentPoints, firstIsFree } = props;
    const slotCount = totalPoints / pointIncrement;
    const tierPoints = startPoints + totalPoints;
    let points = startPoints;

    const stampRows = times(slotCount, index => {
        const stampImg = index === 0 && firstIsFree ? FREE_STAMP_IMAGE : STAMP_IMAGE;

        points += pointIncrement;

        if (points === tierPoints) {
            return (
                <div className={styles.pointSlot}>
                    <div className={styles.reward}>
                        REWARD!<br />
                        {tierPoints}
                    </div>
                </div>
            );
        }

        if (currentPoints >= points || index === 0 && firstIsFree) {
            return <div className={styles.stamp}><img src={stampImg} /></div>;
        }

        return (
            <div className={styles.pointSlot}>
                <div className={styles.points}>
                    <br />{points}<br /><br />
                </div>
            </div>
        );
    });

    return (
        <div className={styles.slots}>
            {stampRows}
        </div>
    );
}

OnGardGameStampTier.propTypes = {
    pointIncrement: PropTypes.number,
    startPoints: PropTypes.number,
    totalPoints: PropTypes.number,
    currentPoints: PropTypes.number,
    firstIsFree: PropTypes.bool,
}
