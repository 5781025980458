import React, { createContext, useEffect, useMemo, useState } from "react";
import {Route, Switch, Redirect} from "react-router";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "react-redux";
// import {hot} from "react-hot-loader";
import PropTypes from "prop-types";
import getStore from "utils/getStore";
import logger from "utils/logger";

/* Pages */
import AccessDeniedPage from "components/clinic/pages/AccessDeniedPage";
import AdminClinicsMapPage from "components/admin/pages/AdminClinicsMapPage";
import AdminClinicProductOverride from "components/admin/pages/AdminClinicProductOverride";
import AdminDownloadsPage from "components/admin/pages/AdminDownloadsPage";
import AdminInvoiceLineItemsPage from "components/admin/pages/AdminInvoiceLineItemsPage";
import AdminProviderSelection from "components/admin/pages/AdminProviderSelection";
import AdminWellnessAudit from "components/wellness/pages/AdminWellnessAudit";
import AdminWellnessDvmCommission from "components/wellness/pages/AdminWellnessDvmCommission";
import AdminWellnessPlans from "components/wellness/pages/AdminWellnessPlans";
import AdminRoleManagementPage from "components/admin/pages/AdminRoleManagementPage";
import BiSsoLogin from "components/BiSsoLogin";
import EditClinicSettings from "components/admin/pages/EditClinicSettings";
import ClinicDashboardAdminPage from "components/admin/pages/ClinicDashboardAdminPage";
import ClinicGames from "components/games/pages/ClinicGames";
import ClinicGamesDemo from "components/games/pages/ClinicGamesDemo";
import ClinicGamePage from "components/games/pages/ClinicGamePage";
import ClinicHistoricGames from "components/games/pages/ClinicHistoricGames";
import ClinicHistoricGamesDemo from "components/games/pages/ClinicHistoricGamesDemo";
import ClinicMapping from "components/admin/pages/ClinicMapping";
import ClinicMarketingTracking from "components/clinic/pages/ClinicMarketingTracking";
import ClinicPIMSUserMapping from "components/admin/pages/ClinicPIMSUserMapping";
import ClinicProductTags from "components/admin/pages/ClinicProductTags";
import ClinicProgramSelection from "components/admin/pages/ClinicProgramSelection";
import ClinicProgramSetup from "components/admin/pages/ClinicProgramSetup";
import ClinicPrograms from "components/clinic/pages/ClinicPrograms";
import ClinicProgramsDashboard from "components/admin/pages/ClinicProgramsDashboard";
import ClinicReports from "components/reports/pages/ClinicReports";
import ClinicUsers from "components/clinic/pages/ClinicUsers";
import ClinicWellnessDvmCommissionPage from "components/wellness/pages/ClinicWellnessDvmCommissionPage";
import ClinicWellnessPlansPage from "components/wellness/pages/ClinicWellnessPlansPage";
import ClinicWellnessPayoutReport from 'components/reports/pages/ClinicWellnessPayoutReport'
import Clinics from "components/admin/pages/Clinics";
import CorporateGroupAuditHistoryExportPage from "components/reports/pages/CorporateGroupAuditHistoryExportPage";
import CorporateGroupProviderDetails from "components/provider/pages/CorporateGroupProviderDetails";
import CouponHistory from "components/coupons/pages/CouponHistory";
import CouponLibrary from "components/coupons/pages/CouponLibrary";
import CouponOptIn from "components/coupons/pages/CouponOptIn";
import CouponReprocessing from "components/admin/pages/CouponReprocessing";
import CumulativeOfferTrackingPage from "components/reports/pages/CumulativeOfferTrackingPage";
import DynamicRoute from "components/DynamicRoute";
import Error404 from "components/Error404";
import GameCopyPage from "components/provider/pages/GameCopyPage";
import GamePage from "components/provider/pages/GamePage";
import Maintenance from "components/Maintenance";
import ManageInvites from "components/provider/ManageInvites";
import NotificationEdit from "components/admin/pages/NotificationEdit";
import NotificationManagement from "components/admin/pages/NotificationManagement";
import OidcPopupCallback from "components/OidcPopupCallback";
import OidcRedirectCallback from "components/OidcRedirectCallback";
import PlanSetup from "components/admin/pages/PlanSetup";
import PreAuditReview from "components/admin/pages/PreAuditReview";
import PriceWatch from "components/reports/pages/PriceWatch";
import ProfitabilityCalculator from "components/clinic/widgets/ProfitabilityCalculator";
import ProgramLandingPage from "components/programs/ProgramLandingPage";
import OpportunityCalculator from "components/clinic/widgets/OpportunityCalculator";
import EligibilityTrackerInstructionsPage from "components/clinic/pages/EligibilityTrackerInstructionsPage";
import EligibilityTrackerEligibleClientsPage from "components/clinic/pages/EligibilityTrackerEligibleClientsPage";
import ServiceLandingPage from "components/programs/ServiceLandingPage";

// PROVIDER COMPONENTS
import GamesManagement from "components/provider/GamesManagement";
import ProviderClinicGamePage from "components/provider/pages/ProviderClinicGamePage";
import ProviderClinicGames from "components/provider/pages/ProviderClinicGames";
import ProviderClinicHistoricGames from "components/provider/pages/ProviderClinicHistoricGames";
import ProviderClinicsPage from "components/provider/pages/ProviderClinicsPage";
import ProviderClinicSettings from "components/provider/pages/ProviderClinicSettings";
import ProviderCouponLibrary from "components/provider/pages/ProviderCouponLibrary";
import ProviderCouponOptInPage from "components/provider/pages/ProviderCouponOptInPage";
import ProviderHomepage from "components/provider/pages/ProviderHomepage";
import ProviderInvoiceLineItemsPage from "components/provider/pages/ProviderInvoiceLineItemsPage";
import ProviderMap from "components/provider/pages/ProviderMap";
import ProviderPetPlanTracking from "components/provider/pages/ProviderPetPlanTracking";
import ProviderProductTagging from "components/provider/pages/ProviderProductTagging";
import ProviderWellnessAudit from "components/provider/pages/ProviderWellnessAudit";
import ProviderWellnessDvmCommission from "components/provider/pages/ProviderWellnessDvmCommission";
import ProviderWellnessHistory from "components/provider/pages/ProviderWellnessHistory";
import ProviderWellnessPlanSetup from "components/provider/pages/ProviderWellnessPlanSetup";
import ProviderWellnessPlans from "components/provider/pages/ProviderWellnessPlans";
import ProviderClinicProductTags from "components/provider/pages/ProviderClinicProductTags";
import ProviderPayoutReport from "components/provider/pages/ProviderPayoutReport"
import ProviderReports from "components/reports/pages/ProviderReports";
import ResourcesPage from "components/resources/pages/ResourcesPage";
import RootContainer from "components/layout/RootContainer";
import SelectVoucherProvider from "components/admin/widgets/SelectVoucherProvider";
import StrayCoupons from "components/clinic/pages/StrayCoupons";
import SupportHome from "components/support/pages/SupportHome";
import TemplatePage from "components/provider/pages/TemplatePage";
import TourCompletePage from "./components/tour/pages/TourCompletePage";
import TourStartPage from "./components/tour/pages/TourStartPage";
import Users from "components/admin/pages/Users";
import UserTemplatePage from "components/provider/pages/TemplatePage/UserTemplatePage";
import UserPayments from "components/user/pages/UserPayments";
import VetCheck from "components/vetcheck/pages/VetCheck";
import VetCheckCharts from "components/vetcheck/pages/VetCheckCharts";
import VetCheckDashboard from "components/vetcheck/pages/VetCheckDashboard";
import VetCheckForms from "components/vetcheck/pages/VetCheckForms";
import VetCheckHandouts from "components/vetcheck/pages/VetCheckHandouts";
import VetCheckSendHistory from "components/vetcheck/pages/VetCheckSendHistory";
import VetCheckManageAutomations from "components/vetcheck/pages/VetCheckManageAutomations";
import VetCheckLoginRedirectCallback from "components/VetCheckLoginRedirectCallback";
import VetCheckOfferPage from "components/vetcheck/pages/VetCheckOfferPage";
import VetCheckOptOut from "components/vetcheck/pages/VetCheckOptOut";
import VetCheckRegisterRedirectCallback from "components/VetCheckRegisterRedirectCallback";
import VoucherManagement from "components/admin/pages/VoucherManagement";
import WellnessAudit from "components/wellness/pages/WellnessAudit";
import WellnessDvmCommission from "components/wellness/pages/WellnessDvmCommission";
import WellnessHistory from "components/wellness/pages/WellnessHistory";
import WellnessLanding from "components/greenlineWellness/pages/WellnessLanding";
import WellnessPetPlanTracking from "components/greenlineWellness/pages/PetPlanTrackingPage";
import WellnessReprocessing from "components/admin/pages/WellnessReprocessing";
import SafetyInformation from "components/games/pages/SafetyInformation";

export const windowSizeContext = createContext();

function App(props) {
    const [width, setWidth] = useState(window.innerWidth);

    const handleWindowSizeChange = () => {
        logger.log("Screen Resized", window.innerWidth);
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        // add when mounted
        logger.log("LOADED: ", window.innerWidth);
        window.addEventListener("resize", handleWindowSizeChange);

        // return function to be called when unmounted
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    const environmentName = useMemo(() => {
        return props.environmentName;
    }, [props.environmentName]);

    return (
        <windowSizeContext.Provider
            data-testid="app_root_component"
            value={{
                isQa: (environmentName === "greenline-qa"),
                isDemo: props.isDemo || (environmentName.indexOf("demo") > -1),
                isDev: (environmentName === "greenline-dev"),
                isLocal: (environmentName === "localhost"),
                isPhone: (width <= 600),
                isTabletPortrait: (width <= 900),
                isTabletLandscape: (width <= 1200),
                windowWidth: width,
            }}
        >
            <Provider store={getStore()}>
                <>
                    <BrowserRouter basename={props.rootPath}>
                        <Switch location={location}>
                            <Route exact path="/popup_callback.html" component={OidcPopupCallback} />
                            <Route exact path="/callback.html" component={OidcRedirectCallback} />
                            <Route exact path="/bissologin" component={BiSsoLogin} />
                            <Route exact path="/vetcheck/loggedin/:clinicId" component={VetCheckLoginRedirectCallback} />
                            <Route exact path="/vetcheck/registered" component={VetCheckRegisterRedirectCallback} />
                            <Route exact path="/index.html">
                                <Redirect to="/" />
                            </Route>
                            <Route path="/"
                                render={({location}) => (
                                    <>
                                        <RootContainer location={location}>
                                            <Switch location={location}>
                                                <Route
                                                    exact
                                                    path="/"
                                                    render={(locationProps) => {
                                                        return (
                                                            <DynamicRoute
                                                                {...locationProps}
                                                                key={locationProps.location.pathname}
                                                                loadComponent={() => import("components/GreenlineHomepage")}
                                                            />
                                                        )
                                                    }}
                                                />

                                                {/* ADMIN VIEW OF CLINIC PAGES */}
                                                <Route exact path="/admin/clinic/:clinicId" component={ClinicDashboardAdminPage} />
                                                <Route
                                                    exact
                                                    path="/admin/clinic/:clinicId/coupons"
                                                    render={(locationProps) => (
                                                        <DynamicRoute
                                                            {...locationProps}
                                                            key={locationProps.location.pathname}
                                                            loadComponent={() => import("components/coupons/pages/CouponAudit")}
                                                        />
                                                    )}
                                                />
                                                <Route exact path="/admin/clinic/:clinicId/coupons/history" component={CouponHistory} />
                                                <Route exact path="/admin/clinic/:clinicId/coupons/library" component={CouponLibrary} />
                                                <Route exact path="/admin/clinic/:clinicId/coupons/library/:programId" component={CouponOptIn} />
                                                <Route exact path="/admin/clinic/:clinicId/coupons/stray" component={StrayCoupons} />
                                                <Route exact path="/admin/clinic/:clinicId/games/history" component={ClinicHistoricGames} />
                                                <Route exact path="/admin/clinic/:clinicId/games/:gameId" component={ClinicGamePage} />
                                                <Route exact path="/admin/clinic/:clinicId/games" component={ClinicGames} />
                                                <Route
                                                    exact
                                                    path="/admin/clinic/:clinicId/notifications"
                                                    render={(locationProps) => (
                                                        <DynamicRoute
                                                            {...locationProps}
                                                            loadComponent={() => import("components/notifications/pages/NotificationsPage")}
                                                        />
                                                    )}
                                                />
                                                <Route exact path="/admin/clinic/:clinicId/programs" component={ClinicPrograms} />
                                                <Route exact path="/admin/clinic/:clinicId/programs/eligibility-tracker" component={EligibilityTrackerInstructionsPage} />
                                                <Route exact path="/admin/clinic/:clinicId/programs/eligibility-tracker/eligible-clients" component={EligibilityTrackerEligibleClientsPage} />
                                                <Route exact path="/admin/clinic/:clinicId/programs/:programId" component={ProgramLandingPage} />
                                                <Route exact path="/admin/clinic/:clinicId/programs/:programId/service/:serviceId" component={ServiceLandingPage} />
                                                <Route exact path="/admin/clinic/:clinicId/vetcheck" component={VetCheck} />
                                                <Route exact path="/admin/clinic/:clinicId/vetcheck/send-history" component={VetCheckSendHistory} />
                                                <Route exact path="/admin/clinic/:clinicId/vetcheck/handouts-sent" component={VetCheckHandouts} />
                                                <Route exact path="/admin/clinic/:clinicId/vetcheck/forms-sent" component={VetCheckForms} />
                                                <Route exact path="/admin/clinic/:clinicId/vetcheck/charts-sent" component={VetCheckCharts} />
                                                <Route exact path="/admin/clinic/:clinicId/vetcheck/opt-out" component={VetCheckOptOut} />
                                                <Route exact path="/admin/clinic/:clinicId/vetcheck/manage-automations" component={VetCheckManageAutomations} />
                                                <Route exact path="/admin/clinic/:clinicId/vetcheck/dashboard" component={VetCheckDashboard} />
                                                <Route exact path="/admin/clinic/:clinicId/wellness" component={WellnessLanding} />
                                                <Route exact path="/admin/clinic/:clinicId/wellness/audit/" component={WellnessAudit} />
                                                <Route exact path="/admin/clinic/:clinicId/wellness/history" component={WellnessHistory} />
                                                <Route exact path="/admin/clinic/:clinicId/wellness/commission/dvm" component={WellnessDvmCommission} />
                                                <Route exact path="/admin/clinic/:clinicId/wellness/plans" component={AdminWellnessPlans} />
                                                <Route exact path="/admin/clinic/:clinicId/wellness/patients" component={WellnessPetPlanTracking} />
                                                <Route exact path="/admin/clinic/:clinicId/clinic/users" component={ClinicUsers} />
                                                <Route exact path="/admin/clinic/:clinicId/resources/" component={ResourcesPage} />
                                                <Route exact path="/admin/clinic/:clinicId/premier/:programId" component={ResourcesPage} />
                                                <Redirect exact from="/admin/clinic/:clinicId/MarketingTracking" to="/admin/clinic/:clinicId/reports/client-campaign-tracking" />
                                                <Route exact path="/admin/clinic/:clinicId/reports/client-campaign-tracking" component={ClinicMarketingTracking} />



                                                {/* ADMIN PAGES FOR CLINIC */}
                                                <Route exact path="/admin/clinic/:clinicId/reports/cumulative-offer-tracking" component={CumulativeOfferTrackingPage} />
                                                <Redirect exact from="/admin/clinic/:clinicId/mapping" to="/admin/clinic/:clinicId/mapping/pharmacy" />
                                                <Route exact path="/admin/clinic/:clinicId/mapping/:pageName" component={ClinicMapping} />
                                                <Route exact path="/admin/clinic/:clinicId/par" component={PreAuditReview} />
                                                <Route exact path="/admin/clinic/:clinicId/pimsusers" component={ClinicPIMSUserMapping} />
                                                <Route exact path="/admin/clinic/:clinicId/products/tags" component={ClinicProductTags} />
                                                <Route exact path="/admin/clinic/:clinicId/programs-dashboard" component={ClinicProgramsDashboard} />
                                                <Route exact path="/admin/clinic/:clinicId/provider/setup" component={ClinicProgramSelection} />
                                                <Route exact path="/admin/clinic/:clinicId/provider/setup/:providerId" component={ClinicProgramSetup} />
                                                <Route exact path="/admin/clinic/:clinicId/reports" component={ClinicReports} />
                                                <Route exact path="/admin/clinic/:clinicId/reports/invoice-line-items" component={AdminInvoiceLineItemsPage} />
                                                <Route exact path="/admin/clinic/:clinicId/reports/price-watch" component={PriceWatch} />
                                                <Route exact path='/admin/clinic/:clinicId/reports/wellness-payout-report' component={ClinicWellnessPayoutReport} />
                                                <Route exact path="/admin/clinic/:clinicId/setup/wellness/commission/dvm" component={AdminWellnessDvmCommission} />
                                                <Route exact path="/admin/clinic/:clinicId/product/overrides" component={AdminClinicProductOverride} />
                                                <Route exact path="/admin/clinic/:clinicId/setup/wellness/plans" component={AdminWellnessAudit} />
                                                <Route exact path="/admin/clinic/:clinicId/settings" component={EditClinicSettings} />
                                                <Route exact path="/admin/clinic/:clinicId/calculator/opportunity" component={OpportunityCalculator} />
                                                <Route exact path="/admin/clinic/:clinicId/calculator/profitability" component={ProfitabilityCalculator} />

                                                {/* ADMIN PATHS */}
                                                <Route exact path="/admin/clinics" component={Clinics} />
                                                <Route exact path="/admin/coupons/reprocess" component={CouponReprocessing} />
                                                <Route exact path="/admin/map" component={AdminClinicsMapPage} />
                                                <Route exact path="/admin/roles" component={AdminRoleManagementPage} />
                                                <Route exact path="/admin/downloads" component={AdminDownloadsPage} />
                                                <Route exact path="/admin/notifications" component={NotificationManagement} />
                                                <Route exact path="/admin/notifications/create/:template" component={NotificationEdit} />
                                                <Route exact path="/admin/notifications/edit/:notificationId" component={NotificationEdit} />
                                                <Route exact path="/admin/users" component={Users} />
                                                <Route exact path="/admin/vouchers" component={SelectVoucherProvider} />
                                                <Route exact path="/admin/vouchers/:planId" component={VoucherManagement} />
                                                <Redirect exact from="/admin/wellness/programs" to="/admin/wellness/programs/19/plansetup" />
                                                <Route exact path="/admin/wellness/programs/:programId/plansetup" component={PlanSetup} />
                                                <Route exact path="/admin/wellness/reprocess" component={WellnessReprocessing} />

                                                 {/* ADMIN VIEW OF PROVIDER PAGES */}
                                                <Route exact path="/admin/providers" component={AdminProviderSelection} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId" component={ProviderHomepage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/clinics" component={ProviderClinicsPage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/clinic/:clinicId/library" component={ProviderCouponOptInPage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/clinic/:clinicId/products/tags" component={ProviderClinicProductTags} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/clinic/:clinicId/games/history" component={ProviderClinicHistoricGames} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/clinic/:clinicId/games/:gameId" component={ProviderClinicGamePage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/clinic/:clinicId/games" component={ProviderClinicGames} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/clinic/:clinicId" component={ProviderClinicSettings} />
                                                {/* Games */}
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/games/invites" component={ManageInvites} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/games/templates/:templateId" component={TemplatePage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/games/user-templates/:templateId" component={UserTemplatePage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/games/copy/:copyId" component={GameCopyPage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/games/:gameId" component={GamePage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/games" component={GamesManagement} />
                                                {/* Demo Games*/}
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/demo/games/invites" component={ManageInvites} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/demo/games/templates/:templateId" component={TemplatePage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/demo/games/user-templates/:templateId" component={UserTemplatePage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/demo/games/copy/:copyId" component={GameCopyPage} />
                                                <Route exact path='/admin/provider/:providerId/location/:nodeId/demo/games/clinic-games/history' component={ClinicHistoricGamesDemo} />
                                                <Route exact path='/admin/provider/:providerId/location/:nodeId/demo/games/clinic-games' component={ClinicGamesDemo} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/demo/games/:gameId" component={GamePage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/demo/games" component={GamesManagement} />
                                                {/* End Games */}
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/product-tagging" component={ProviderProductTagging} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/wellness/plansetup" component={ProviderWellnessPlanSetup} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/reports" component={ProviderReports} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/resources" component={ResourcesPage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/corporate-group/:groupId" component={CorporateGroupProviderDetails} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/reports/corp-audit-history" component={CorporateGroupAuditHistoryExportPage} />
                                                <Route exact path="/admin/provider/:providerId/location/:nodeId/map" component={ProviderMap} />
                                                <Route exact path ="/admin/provider/:providerId/location/:nodeId/reports/payout-report" component ={ProviderPayoutReport} />

                                                {/* PROVIDER VIEW OF CLINIC PAGES */}
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId" component={ClinicDashboardAdminPage} />

                                                <Route
                                                    exact
                                                    path="/provider/location/:nodeId/clinic/:clinicId/coupons"
                                                    render={(locationProps) => (
                                                        <DynamicRoute
                                                            {...locationProps}
                                                            key={locationProps.location.pathname}
                                                            loadComponent={() => import("components/coupons/pages/CouponAudit")}
                                                        />
                                                    )}
                                                />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/coupons/history" component={CouponHistory} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/coupons/library" component={CouponLibrary} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/coupons/library/:programId" component={CouponOptIn} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/coupons/stray" component={StrayCoupons} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/games/history" component={ProviderClinicHistoricGames} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/games/:gameId" component={ProviderClinicGamePage} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/games" component={ProviderClinicGames} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/products/tags" component={ProviderClinicProductTags} />
                                                <Route
                                                    exact
                                                    path="/provider/location/:nodeId/clinic/:clinicId/notifications"
                                                    render={(locationProps) => (
                                                        <DynamicRoute
                                                            {...locationProps}
                                                            loadComponent={() => import("components/notifications/pages/NotificationsPage")}
                                                        />
                                                    )}
                                                />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/programs" component={ClinicPrograms} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/programs/eligibility-tracker" component={EligibilityTrackerInstructionsPage} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/programs/eligibility-tracker/eligible-clients" component={EligibilityTrackerEligibleClientsPage} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/programs/:programId" component={ProgramLandingPage} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/programs/:programId/service/:serviceId" component={ServiceLandingPage} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/vetcheck" component={VetCheck} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/vetcheck/send-history" component={VetCheckSendHistory} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/vetcheck/handouts-sent" component={VetCheckHandouts} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/vetcheck/forms-sent" component={VetCheckForms} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/vetcheck/charts-sent" component={VetCheckCharts} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/vetcheck/opt-out" component={VetCheckOptOut} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/vetcheck/manage-automations" component={VetCheckManageAutomations} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/vetcheck/dashboard" component={VetCheckDashboard} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/wellness" component={WellnessLanding} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/wellness/audit/" component={WellnessAudit} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/wellness/history" component={WellnessHistory} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/wellness/commission/dvm" component={WellnessDvmCommission} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/wellness/plans" component={ProviderWellnessPlans} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/wellness/patients" component={WellnessPetPlanTracking} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/clinic/users" component={ClinicUsers} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/resources/" component={ResourcesPage} />
                                                <Redirect exact from="/provider/location/:nodeId/clinic/:clinicId/MarketingTracking" to="/provider/location/:nodeId/clinic/:clinicId/reports/client-campaign-tracking" />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/reports/client-campaign-tracking" component={ClinicMarketingTracking} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/premier/:programId" component={ResourcesPage} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/reports/cumulative-offer-tracking" component={CumulativeOfferTrackingPage} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/reports/price-watch" component={PriceWatch} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/reports" component={ClinicReports} />

                                                {/*Provider Version of Clinic Pages*/}
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/view/wellness/patients" component={ProviderPetPlanTracking} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/setup/wellness/commission/dvm" component={ProviderWellnessDvmCommission} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/view/wellness/audit/" component={ProviderWellnessAudit} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/view/wellness/history" component={ProviderWellnessHistory} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/view/reports/invoice-line-items" component={ProviderInvoiceLineItemsPage} />

                                                {/* PROVIDER PATHS*/}
                                                <Redirect exact from="/provider" to="/" /> {/*Direct to the Home Page*/}
                                                <Route exact path="/provider/location/:nodeId/clinics" component={ProviderClinicsPage} />
                                                <Route exact path="/provider/location/:nodeId/map" component={ProviderMap} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/library" component={ProviderCouponLibrary} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/library/:libraryProviderId" component={ProviderCouponOptInPage} />
                                                <Route exact path="/provider/location/:nodeId/clinic/:clinicId/wellness/settings" component={ProviderClinicSettings} />
                                                <Route exact path="/provider/location/:nodeId/corporate-group/:groupId" component={CorporateGroupProviderDetails} />
                                                <Route exact path="/provider/location/:nodeId/reports/corp-audit-history" component={CorporateGroupAuditHistoryExportPage} />

                                                {/* Games */}
                                                <Route exact path="/provider/location/:nodeId/games/invites" component={ManageInvites} />
                                                <Route exact path="/provider/location/:nodeId/games/templates/:templateId" component={TemplatePage} />
                                                <Route exact path="/provider/location/:nodeId/games/user-templates/:templateId" component={UserTemplatePage} />
                                                <Route exact path="/provider/location/:nodeId/games/copy/:copyId" component={GameCopyPage} />
                                                <Route exact path="/provider/location/:nodeId/games/:gameId" component={GamePage} />
                                                <Route exact path="/provider/location/:nodeId/games" component={GamesManagement} />
                                                {/* Demo Games */}
                                                <Route exact path="/provider/location/:nodeId/demo/games/invites" component={ManageInvites} />
                                                <Route exact path="/provider/location/:nodeId/demo/games/templates/:templateId" component={TemplatePage} />
                                                <Route exact path="/provider/location/:nodeId/demo/games/user-templates/:templateId" component={UserTemplatePage} />
                                                <Route exact path="/provider/location/:nodeId/demo/games/copy/:copyId" component={GameCopyPage} />
                                                <Route exact path='/provider/location/:nodeId/demo/games/clinic-games/history' component={ClinicHistoricGamesDemo} />
                                                <Route exact path='/provider/location/:nodeId/demo/games/clinic-games' component={ClinicGamesDemo} />
                                                <Route exact path="/provider/location/:nodeId/demo/games/:gameId" component={GamePage} />
                                                <Route exact path="/provider/location/:nodeId/demo/games" component={GamesManagement} />
                                                {/* End Games */}
                                                <Route exact path="/provider/location/:nodeId/wellness/plansetup" component={ProviderWellnessPlanSetup} />
                                                <Route exact path="/provider/location/:nodeId/reports" component={ProviderReports} />
                                                <Route exact path="/provider/location/:nodeId/reports/payout-report" component={ProviderPayoutReport} />
                                                <Route exact path="/provider/location/:nodeId/resources" component={ResourcesPage} />
                                                <Route exact path="/provider/location/:nodeId/product-tagging" component={ProviderProductTagging} />
                                                <Route exact path="/provider/location/:nodeId" component={ProviderHomepage} />

                                                {/* CLINIC PATHS*/}
                                                <Route
                                                    exact
                                                    path="/register"
                                                    render={(locationProps) => (
                                                        <DynamicRoute
                                                            {...locationProps}
                                                            key={locationProps.location.pathname}
                                                            loadComponent={() => import("components/user/pages/Register")}
                                                        />
                                                    )}
                                                />
                                                <Route exact path="/clinic/users" component={ClinicUsers} />
                                                <Route
                                                    exact
                                                    path="/coupons"
                                                    render={(locationProps) => (
                                                        <DynamicRoute
                                                            {...locationProps}
                                                            key={locationProps.location.pathname}
                                                            loadComponent={() => import("components/coupons/pages/CouponAudit")}
                                                        />
                                                    )}
                                                />
                                                <Route exact path="/coupons/history" component={CouponHistory} />
                                                <Route exact path="/coupons/library" component={CouponLibrary} />
                                                <Route exact path="/coupons/library/:programId" component={CouponOptIn} />
                                                <Route exact path="/coupons/stray" component={StrayCoupons} />
                                                <Route exact path="/games/history" component={ClinicHistoricGames} />
                                                <Route exact path="/games/:gameId" component={ClinicGamePage} />
                                                <Route exact path="/games" component={ClinicGames} />
                                                <Route
                                                    exact
                                                    path="/notifications"
                                                    render={(locationProps) => (
                                                        <DynamicRoute
                                                            {...locationProps}
                                                            key={locationProps.location.pathname}
                                                            loadComponent={() => import("components/notifications/pages/NotificationsPage")}
                                                        />
                                                    )}
                                                />
                                                <Route exact path="/programs" component={ClinicPrograms} />
                                                <Route exact path="/programs/eligibility-tracker" component={EligibilityTrackerInstructionsPage} />
                                                <Route exact path="/programs/eligibility-tracker/eligible-clients" component={EligibilityTrackerEligibleClientsPage} />
                                                <Route exact path="/programs/:programId" component={ProgramLandingPage} />
                                                <Route exact path="/programs/:programId/service/:serviceId" component={ServiceLandingPage} />
                                                <Route exact path="/reports/" component={ClinicReports} />
                                                <Route exact path="/reports/cumulative-offer-tracking" component={CumulativeOfferTrackingPage} />
                                                <Route exact path="/reports/payout-report" component={ProviderPayoutReport} />
                                                <Route exact path="/reports/corp-audit-history" component={CorporateGroupAuditHistoryExportPage} />
                                                <Route exact path="/reports/price-watch" component={PriceWatch} />
                                                <Route exact path='/reports/wellness-payout-report' component={ClinicWellnessPayoutReport} />
                                                <Route exact path="/setup/" component={WellnessDvmCommission} />
                                                <Route exact path="/setup/mapping" component={WellnessDvmCommission} />
                                                <Route exact path="/setup/wellness/commission/dvm" component={WellnessDvmCommission} />
                                                <Route exact path="/setup/wellness/plans" component={WellnessDvmCommission} />
                                                <Route exact path="/setup/payments" component={UserPayments} />
                                                <Route exact path="/vetcheck" component={VetCheck} />
                                                <Route exact path="/vetcheck/sign-up" component={VetCheckOfferPage} />
                                                <Route exact path="/vetcheck/send-history" component={VetCheckSendHistory} />
                                                <Route exact path="/vetcheck/handouts-sent" component={VetCheckHandouts} />
                                                <Route exact path="/vetcheck/forms-sent" component={VetCheckForms} />
                                                <Route exact path="/vetcheck/charts-sent" component={VetCheckCharts} />
                                                <Route exact path="/vetcheck/opt-out" component={VetCheckOptOut} />
                                                <Route exact path="/vetcheck/manage-automations" component={VetCheckManageAutomations} />
                                                <Route exact path="/vetcheck/dashboard" component={VetCheckDashboard} />
                                                <Route exact path="/wellness" component={WellnessLanding} />
                                                <Route exact path="/wellness/audit" component={WellnessAudit} />
                                                <Route exact path="/wellness/commission/dvm" component={ClinicWellnessDvmCommissionPage} />
                                                <Route exact path="/wellness/history" component={WellnessHistory} />
                                                <Route exact path="/wellness/patients" component={WellnessPetPlanTracking} />
                                                <Route exact path="/wellness/plans" component={ClinicWellnessPlansPage} />
                                                <Redirect exact from="/MarketingTracking" to="/reports/client-campaign-tracking" />
                                                <Route exact path="/reports/client-campaign-tracking" component={ClinicMarketingTracking} />

                                                {/* ALL USERS */}
                                                {/* <Route exact path="/user/account" component={UserHomepage} /> */}
                                                <Route exact path="/resources/" component={ResourcesPage} />
                                                <Route exact path="/premier/:programId" component={ResourcesPage} />
                                                <Route exact path="/support/" component={SupportHome} />
                                                <Route exact path="/no-access/" component={AccessDeniedPage} />
                                                <Route exact path="/maintenance/" component={Maintenance} />

                                                {/*Demo Users*/}
                                                <Route exact path="/begin-tour/" component={TourStartPage} />
                                                <Route exact path="/tour-complete/" component={TourCompletePage} />

                                                {/* ERROR HANDLING */}
                                                <Route component={Error404} />
                                            </Switch>
                                        </RootContainer>
                                    </>
                                )}
                            />
                            <Route component={Error404} />
                        </Switch>
                    </BrowserRouter>
                    <div id="modal-root"></div>
                    <div id="tooltip-root"></div>
                </>
            </Provider>
        </windowSizeContext.Provider>
    );
}

// try {
//     // Wrapped in a try catch because `DEVELOPMENT` is not defined when running UI tests
//     if (DEVELOPMENT) {
//         App = hot(module)(App);
//     }
// } catch (e) {
//     console.error(e);
// }

App.propTypes = {
    rootPath: PropTypes.string.isRequired,
    environment: PropTypes.string.isRequired,
    isDemo: PropTypes.bool,
};

export default App;
