import React, { useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ToolbarClinicSearch.scss";
import Button from "components/common/Button";
import NavSearchBox from "components/layout/widgets/NavSearchBox";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import * as SearchTypes from "constants/SearchTypes"

function ToolbarClinicSearch(props) {
    const [showClinicSearch, setShowClinicSearch] = useState(false);

    const toggleClinicSearch = () => {
        setShowClinicSearch(!showClinicSearch);
    }

    const changeClinicLocation = (newClinicId) => {
        if(props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("dashboard", newClinicId));
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("dashboard", props.nodeId, newClinicId));
        }
    }

    if(!props.clinic?.name) {
        return null;
    }

    const goToDashboard = () => {
        if(props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("dashboard", props.clinicId))
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("dashboard", props.nodeId, props.clinicId))
        }
    }

    return (
        <div className={styles.root}>
            <div className={styles.clinicBox}>
                {props.isAdmin && (
                    <div className={styles.clinicSearchToggle}>
                        <Button
                            onClick={toggleClinicSearch}
                            type="circleIcon"
                            iconOnly
                        >
                            {showClinicSearch ? <i className="fas fa-times" /> : <i className="fas fa-building" />}
                        </Button>
                    </div>
                )}
                <div className={styles.clinicInfo} onClick={goToDashboard}>
                    <h4>{props.clinic.name}</h4>
                </div>
            </div>
            {props.isAdmin && (
                <div className={classnames(styles.clinicSearchBox, {
                    [styles.showingSearch]: showClinicSearch,
                })}>
                    <div className={styles.clinicSearchBoxInner}>
                        <NavSearchBox
                            searchConfig={SearchTypes.CLINIC_INFO_SEARCH}
                            clinicId={props.clinicId}
                            resultsTop
                            onResultsClick={(value) => changeClinicLocation(value)}
                            onCancel={toggleClinicSearch}
                            autoFocus={showClinicSearch}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

ToolbarClinicSearch.propTypes = {
    clinicId: PropTypes.number,
    nodeId: PropTypes.number,
    isAdmin: PropTypes.bool,
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        return {
            userProfile,
            clinic: state.entities.clinicDetails[ownProps.clinicId],
        }
    }
);

export default compose(
    withRouter,
    connector
)(ToolbarClinicSearch);
