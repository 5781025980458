import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./StrayCoupons.scss";
import * as CouponActions from "actions/CouponActions";
import AccessDenied from "components/common/AccessDenied";
import Accordion from "components/common/Accordion";
import ClinicBase from "components/layout/ClinicBase";
import GetHelp from "components/clinic/widgets/GetHelp";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import StrayCouponsTable from "components/clinic/widgets/StrayCouponsTable";
import { getClinicInfo } from "utils/ClinicData";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import * as StrayCouponTableTypes from "constants/StrayCouponTableTypes";
import toast from "utils/toast";
import classNames from "classnames";
import Button from "components/common/Button";
import { downloadFile } from "utils/request";
import { exportStrayCouponsInfo } from "api/MarketingApi";

function StrayCoupons(props) {
    const [allSelectedRows, setAllSelectedRows] = useState([]);
    const [pendingSelected, setPendingSelected] = useState([]);
    const [ineligibleSelected, setIneligibleSelected] = useState([]);

    useEffect(() => {
        if (props.canViewInstantRebateInvoiceFailures) {
            props.getStrayCouponStatuses();
        }
    }, [props.canViewInstantRebateInvoiceFailures]);

    useEffect(() => {
        if (props.canViewInstantRebateInvoiceFailures && props.clinicId) {
            props.getStrayCoupons(props.clinicId);
        }
        return () => {
            // Clean up the subscription
            props.euthanizeTheStrays()
        };
    }, [props.clinicId, props.canViewInstantRebateInvoiceFailures]);

    const handleRowsSelected = (rows, source) => {
        if (source === "pending") {
            setPendingSelected(rows);
            setAllSelectedRows([...rows, ...ineligibleSelected]);
        } else if (source === "ineligible") {
            setIneligibleSelected(rows);
            setAllSelectedRows([...rows, ...pendingSelected]);
        }
    }

    const handleCompleteGetHelp = () => {
        setAllSelectedRows([]);
        setPendingSelected([]);
        setIneligibleSelected([]);
    }

    const handleDownloadExcel = async () => {
        try {
            const res = await exportStrayCouponsInfo({ clinicId: props.clinicId, exportType: "xlsx" });
            downloadFile(res, "xlsx");
            toast.success("File downloaded successfully");
        } catch (error) {
            toast.error("Error downloading file");
        }
    };

    return (
        <ClinicBase
            // searchConfig={SearchTypes.STRAY_COUPONS}
            clinicId={props.clinicId}
            docTitle="Stray Coupons"
            pageTitle="Stray Coupons"
        >

            <div className={styles.root}>
                {!props.canResolveInstantRebateInvoiceFailures && (
                    <GetHelp
                        selectedRowIds={allSelectedRows}
                        onComplete={handleCompleteGetHelp}
                        strayCoupons={props.strayCoupons}
                        clinicId={props.clinicId}
                    />
                )}
                {props.canViewInstantRebateInvoiceFailures ? (
                    <>
                        <div className={classNames(styles.download, "flex-none")}>
                            {props.canExportInvoiceFailures && (
                                <Button
                                    data-testid="sortable_data_table_download_button"
                                    className="margin-right-sm"
                                    onClick={handleDownloadExcel}
                                    icon
                                    small
                                >
                                    <i className="fas fa-download" />
                                    Download
                                </Button>
                            )}
                         </div>
                        <Accordion
                            theme={{
                                root: styles.accordionRoot,
                                header: styles.header,
                                collapse: styles.collapse,
                                content: styles.collapseContent,
                            }}
                        >
                            <StrayCouponsTable
                                key={StrayCouponTableTypes.INELIGIBLE}
                                tableType={StrayCouponTableTypes.INELIGIBLE}
                                clinicId={props.clinicId}
                                onRowsSelected={handleRowsSelected}
                                selectedRows={ineligibleSelected}
                            />
                            <StrayCouponsTable
                                key={StrayCouponTableTypes.PENDING_RESOLUTION}
                                tableType={StrayCouponTableTypes.PENDING_RESOLUTION}
                                clinicId={props.clinicId}
                                onRowsSelected={handleRowsSelected}
                                selectedRows={pendingSelected}
                                sectionOpenByDefault
                            />
                            <StrayCouponsTable
                                key={StrayCouponTableTypes.RESOLVED}
                                tableType={StrayCouponTableTypes.RESOLVED}
                                clinicId={props.clinicId}
                            />
                            <StrayCouponsTable
                                key={StrayCouponTableTypes.EXPIRED}
                                tableType={StrayCouponTableTypes.EXPIRED}
                                clinicId={props.clinicId}
                            />
                        </Accordion>
                    </>
                ) : (
                    <AccessDenied/>
                )}
            </div>
            <SpinnerTakeover show={props.working}/>
        </ClinicBase>
    )
}

export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinicInfo = getClinicInfo(state, adminClinicId);
        const clinicId = clinicInfo ? Number(clinicInfo.clinicId) : null;
        const userProfile = state.user.userProfile;
        const clinicHasPrioritySupport = !!clinicInfo?.prioritySupport?.length;

        //Permissions
        const canViewInstantRebateInvoiceFailures = userHasPermission(PermissionTypes.VIEW, UserPermissions.INSTANT_REBATE_INVOICE_FAILURES, userProfile);
        const canExportInvoiceFailures = userHasPermission(PermissionTypes.EXPORT, UserPermissions.INSTANT_REBATE_INVOICE_FAILURES, userProfile);

        return {
            clinicId,
            clinicInfo,
            clinicHasPrioritySupport,
            userProfile: state.user.userProfile,
            canViewInstantRebateInvoiceFailures,
            canExportInvoiceFailures,
            working: !!state.applicationState.working,
            strayCoupons: state.entities.strayCoupons,
        }
    },
    (dispatch) => ({
        getStrayCouponStatuses: () => dispatch(CouponActions.getStrayCouponStatuses()),
        getStrayCoupons: (clinicId) => dispatch(CouponActions.getStrayCoupons(clinicId)),
        euthanizeTheStrays: () => dispatch(CouponActions.clearStrayCoupons()),
    })
)(StrayCoupons);
