exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".CorporateGroupProviderDetails__root .SortableDataTable__download{margin:auto 0 -50px auto}.CorporateGroupProviderDetails__root .CorporateGroupProviderDetails__header{margin:0;text-align:center}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/provider/pages/CorporateGroupProviderDetails.scss"],"names":[],"mappings":"AAAA,kEAA4C,wBAAwB,CAAC,4EAAc,SAAS,iBAAiB,CAAC","file":"CorporateGroupProviderDetails.scss","sourcesContent":[".root :global(.SortableDataTable__download){margin:auto 0 -50px auto}.root .header{margin:0;text-align:center}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "CorporateGroupProviderDetails__root",
	"header": "CorporateGroupProviderDetails__header"
};