import React from "react";
import styles from "./GameTemplateSelectorButton.scss";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function GameTemplateSelectorButton(props) {
    return (
        <Link to={props.linkTo}>
            <div className={styles.root}>
                <div className="flex flex-column">
                    <div className={styles.title}>{props.title}</div>
                    {props.desc1 && <div className={styles.desc1}>{props.desc1}</div>}
                    {props.desc2 && <div className={styles.desc2}>{props.desc2}</div>}
                </div>
            </div>
        </Link>
    );
}

GameTemplateSelectorButton.propTypes = {
    title: PropTypes.string.isRequired,
    desc1: PropTypes.string,
    desc2: PropTypes.string,
    linkTo: PropTypes.string.isRequired,
}