import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import map from "lodash/map";
import ProviderSetupTile from "./ProviderSetupTile";
import styles from "./ProviderTiles.scss";
import PropTypes from "prop-types";
import {getProgramProviders} from "utils/AdminData";
import * as CouponActions from "actions/CouponActions";

function ProviderTiles(props) {
    useEffect(() => {
        if(!props.providers) {
            props.getProviderList();
        }
    }, []);

    useEffect(() => {
        setSelectedProviderId(props.providerId);
    }, [props.providerId]);

    const [selectedProviderId, setSelectedProviderId] = useState(null);


    const handleProviderClick = (providerId) => {
        if (props.onSelect) {
            props.onSelect(props.providers[providerId])
        }
        setSelectedProviderId(providerId);
    }

    const renderProviderTiles = () => {
        if (props.providers) {
            return map(props.providers, provider => {
                const providerDetails = props.providerLocations ? (props.providerLocations[provider.id] || {}) : {};
                return (
                    <ProviderSetupTile
                        goGreenDate={providerDetails.goGreenDate}
                        enrollmentDate={providerDetails.enrollmentDate}
                        isValidLocation={providerDetails.isValidLocation}
                        name={provider.id}
                        imageUrl={provider.mediumImageUri}
                        imageTitle={provider.name}
                        key={`providerId_${provider.id}`}
                        onClick={handleProviderClick}
                        selected={selectedProviderId === provider.id}
                        showEnrollmentInfo
                    />
                )
            });
        }

        return (
            <div className={styles.noPrograms}>
                <span>No Programs Setup</span>
            </div>
        );
    }

    return (
        <div className={styles.root}>
            <div className={styles.gridContainer}>
                <div className={styles.grid}>
                    {renderProviderTiles()}
                </div>
            </div>
        </div>
    )

}

ProviderTiles.propTypes = {
    clinicId: PropTypes.number,
    onSelect: PropTypes.func,
    providerId: PropTypes.number,
}

export default connect(
    (state, ownProps) => ({
        providerLocations: state.entities.clinicProviderLocations[ownProps.clinicId]  || {},
        providers: getProgramProviders(state),
    }),
    (dispatch) => ({
        getProviderList: () => dispatch(CouponActions.getProviderList()),
    })
)(ProviderTiles);

