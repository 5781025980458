import React, { useState } from 'react';
import styles from './PaymentOption.scss';
import classnames from "classnames";
import PropTypes from 'prop-types';
import { deletePaymentOption } from "api/StripeApi";
import AccessDenied from "components/common/AccessDenied";
import Button from 'components/common/Button';
import Modal from 'components/common/Modal';
import toast from "utils/toast";
import getCardIcon from "utils/getCardIcon";


export default function PaymentOption(props) {
    const [deleteIsOpen, setDeleteIsOpen] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);

    const confirmDelete = async () => {
        setBtnDisabled(true);

        try {
            const res = await deletePaymentOption(props.clinicId, props.card.id);

            if (res.body) {
                props.getCards();
                toast.success('Card Deleted.');
            } else {
                toast.error('Cannot delete last card while on an active subscription.');
            }
        } catch (error) {
            toast.error('Could not delete card');
        }

        setDeleteIsOpen(false);
        setBtnDisabled(false);
    }

    const cardIcon = getCardIcon(props.card.brand);

    return (
        <div className={classnames(styles.root, styles.card)}>
            <div className={styles.cardType}>
                {cardIcon}
            </div>
            <div className={styles.cardName}>
                <div>{props.card.name}</div>
                {!!props.card.isDefault && <div className={styles.isDefault}>Default</div>}
            </div>
            <div><span>Last 4</span> {props.card.last4}</div>
            <div><span>Exp.</span> {props.card.expMonth}/{props.card.expYear}</div>
            <div className={styles.removeBtn}>
                <Button
                    small
                    iconOnly
                    onClick={() => setDeleteIsOpen(true)}
                    disabled={!props.canEditPaymentSettings}
                    type="danger"
                >
                    <i className="fas fa-trash-alt" />
                </Button>
            </div>

            <Modal
                show={deleteIsOpen}
                onClose={() => setDeleteIsOpen(false)}
                small
                modalTitle="Are you sure you want to delete this card?"
            >
                {!props.canEditPaymentSettings ? (
                    <AccessDenied
                        customMessage="You do not have the correct permissions to delete a card."
                    />
                ) : (
                    <div>
                        <div className={classnames("flex align-center", styles.card)}>
                            <div className={styles.cardType}>
                                {cardIcon}
                            </div>
                            <div className={styles.cardName}>
                                <div>{props.card.name}</div>
                                {!!props.card.isDefault && <div className={styles.isDefault}>Default</div>}
                            </div>
                            <div><span>Last 4</span> {props.card.last4}</div>
                            <div><span>Exp.</span> {props.card.expMonth}/{props.card.expYear}</div>
                        </div>
                        <div className="flex spaced-content justify-flex-end border-top">
                            <Button
                                large
                                onClick={() => setDeleteIsOpen(false)}
                                disabled={btnDisabled}
                                type="gray"
                            >
                                Cancel
                            </Button>
                            <Button
                                large
                                onClick={confirmDelete}
                                disabled={btnDisabled}
                                type="danger"
                            >
                                Delete Card
                            </Button>
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    )
}

PaymentOption.propTypes = {
    clinicId: PropTypes.number.isRequired,
    card: PropTypes.object.isRequired,
    getCards: PropTypes.func.isRequired,
    canEditPaymentSettings: PropTypes.bool,
}
