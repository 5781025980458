import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import styles from "./RichText.scss";

export default function RichText(props) {
    const editorRef = useRef();

    useEffect(() => {
        if (editorRef.current) {
            const quill = editorRef.current.getEditor();
            if (quill) {
                quill.setSelection(999, 0);
            }
        }
    }, [props.focusEnd])

    const handleTextChanged = (text, delta, source) => {
        props.onChange({
            name: props.name,
            value: text,
        });
    };

    const toolbarModules = {
        toolbar: {
            container: [
                [{header: [1, 2, 3, 4, 5, 6, false]}],
                [{ 'font': [] }],
                [{ 'align': [] }],
                ["bold", "italic", "underline", "strike"],
                [{list: "ordered"}, {list: "bullet"}],
                [{ 'color': [] }, { 'background': [] }],
            ],
            handlers: {
            },
        },
        clipboard: {
            matchVisual: true,
        },
    };

    return (
        <div
            data-testid={props["data-testid"]}
            className={classnames(styles.root, {
                [styles.disabled]: props.disabled,
            })}
        >
            {props.label &&
                <div
                    data-testid="rich_text_label"
                    className="label"
                >
                    {props.label}{props.required && "*"}
                </div>
            }
            <ReactQuill
                ref={editorRef}
                autoFocus={props.autoFocus}
                onChange={handleTextChanged}
                value={props.value}
                defaultValue={""}
                modules={toolbarModules}
                className={classnames(styles.editor, {[styles.grayBorder]: props.grayBorder, [styles.noPadding]: props.noPadding})}
                placeholder={props.placeholder}
                readOnly={props.disabled}
            />
        </div>
    );
}

RichText.propTypes = {
    ["data-testid"]: PropTypes.string,
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    grayBorder: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    noPadding: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.string.isRequired,
};