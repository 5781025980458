import React, {useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "./AddVetCheckAutomation.scss";
import * as VetCheckApi from "api/VetCheckApi";
import * as VetCheckActions from "actions/VetCheckActions";
import find from "lodash/find";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import lowerCase from "lodash/lowerCase";
import map from "lodash/map";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import Image from "components/common/Image";
import Keywords from "components/vetcheck/elements/Keywords";
import Modal from "components/common/Modal";
import PIMSCodes from "components/vetcheck/elements/PIMSCodes";
import TextBox from "components/common/TextBox";
import VetCheckPlugIn from "components/vetcheck/widgets/VetCheckPlugIn";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as status from "constants/HttpResponseCodes";
import * as ModalTypes from "constants/ModalTypes";
import * as UserPermissions from "constants/UserPermissions";
import {HANDOUT, CLIENT_FORM} from "constants/VetCheckContent";
const HANDOUT_IMAGE = require("components/common/branding/vetcheck/handout_default.jpg");


const contentTypes = [HANDOUT, CLIENT_FORM]

function AddVetCheckAutomation(props) {
    const [showVetCheckContent, setShowVetCheckContent] = useState(false);
    const [vetCheckSearch, setVetCheckSearch] = useState("");
    const [selectedContent, setSelectedContent] = useState(null);
    const [pimsCodes, setPimsCodes] = useState([]);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showDoesntExistError, setShowDoesntExistError] = useState(false);
    const [contentType, setContentType] = useState(null);

    const onClose = () => {
        setShowDoesntExistError(false);
        setContentType(null);
        setSelectedContent(null);
        setPimsCodes([]);
        props.handleClose();
    };

    const handleContentSelected = (data) => {
        if (!!data?.documentType) {
            if (!includes(contentType, data.documentType)) {
                toast.warn(`Content of type ${data.documentType} cannot be automated. Please Select either a Handout or a Client Form.`);
            } else if (data.documentId) {
                setShowVetCheckContent(false);
                setVetCheckSearch(data.documentId);
                setContentType(data.documentType);
                handleGetContent(data?.documentId, data.documentType);
            }
        }
    };

    if(!props.canEditVetCheckMapping) {
        return(
            <Modal
                show={props.showNewAutomation}
                modalTitle="Add VetCheck Automation"
                onClose={onClose}
                mediumSmall
            >
                <AccessDenied/>
            </Modal>
        )
    }

    const handlePimsCodes = (selectedPims) => {
        setPimsCodes(selectedPims);
    }

    const handleKeywords = ({value}) => {
        setSelectedKeywords(value);
    }

    const handleGetContent = (search=vetCheckSearch, type=contentType) => {
        setLoading(true);
        setShowDoesntExistError(false);
        VetCheckApi.getVetCheckContentById(props.clinicId, search, type)
            .then((res) => {
                setSelectedContent(res.body);
                setVetCheckSearch("");
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setSelectedContent(null);
                if(error.status === status.HTTP_STATUS_500) {
                    // A 500 error is returned if the handout doesn't exist
                    setShowDoesntExistError(true);
                } else {
                    handleErrorResponse("loading handout by ID", error);
                }
            });
    }

    const handleRemoveHandout = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedContent(null);
    };

    const handleCreateAutomation = () => {
        if(contentType && selectedContent) {
            if((contentType === HANDOUT) && !!pimsCodes?.length) {
                forEach(pimsCodes, code => {
                    const product = find(props.pims, p => {return lowerCase(p.remoteClinicProductDisplayID) === lowerCase(code)});
                    // Get the clinicProductId for each selected code.
                    if(product) {
                        const data = {
                            clinicId: props.clinicId,
                            clinicHandoutId: selectedContent?.clinicHandoutId,
                            clinicProductId: product.clinicProductId,
                            contentType
                        }
                        props.createVetCheckMappings(data, selectedContent);
                    }
                });
                onClose();
            } else if((contentType === CLIENT_FORM) && !!selectedKeywords?.length) {
                forEach(selectedKeywords, keywordId => {
                    const data = {
                        clinicId: props.clinicId,
                        clinicHandoutId: selectedContent?.clinicHandoutId,
                        clinicKeywordId: keywordId,
                        contentType
                    };
                    props.createVetCheckMappings(data, selectedContent);
                });
                onClose();
            }
        }
    }

    const SELECT_CONTENT = (
      <div className="margin-top-lg margin-bottom-lg">
          <div className="text-xlg margin-bottom-x-sm text-center">Select Content Type</div>
          <div className="flex spaced-content flex-centered">
              {map(contentTypes, type => (
                  <Button key={type}
                      className={styles.contentType}
                      onClick={() => setContentType(type)}
                      large
                      tall
                  >
                      {type}
                  </Button>
              ))}
          </div>
      </div>
    );

    const handleShowVetCheckContent = () => {
        if (!!props.vetCheckAuthCode) {
            setShowVetCheckContent(true);
        }
    }

    const AUTOMATION_FORM = (
        <div className={styles.addVetCheck}>
            <div className="text-xlg margin-bottom-x-sm">VetCheck {contentType}</div>
            <div className="flex spaced-content">
                <div onClick={handleShowVetCheckContent}
                     className={classnames(styles.addVetCheckSquare, "flex-none flex flex-column", {
                        [styles.disabled]: !props.vetCheckAuthCode
                    })}
                >
                    <div className={classnames(styles.image, "flex-1 flex flex-centered")}>
                        {!!selectedContent ? (
                            <Image
                                src={selectedContent?.imageUrl}
                                alt={selectedContent?.title}
                                title={selectedContent?.title}
                                fallbackSrc={HANDOUT_IMAGE}
                                style={{maxWidth: "100%", overflow: "hidden"}}
                            />
                        ) : (
                            <i className="fa fa-3x fa-plus-circle text-success"/>
                        )}
                    </div>
                    <div className={classnames(styles.squareText, "flex-none flex flex-centered text-center padding-top-sm padding-bottom-sm")}>
                        {!!selectedContent ? selectedContent?.title : `Add VetCheck ${contentType}`}
                    </div>
                    {!!selectedContent && (
                        <div className={styles.remove}
                            onClick={handleRemoveHandout}
                        >
                            <i className="fa fa-times-circle text-danger"/>
                        </div>
                    )}
                </div>
                <div className="flex-1  flex flex-column justify-center">
                    <div className="flex spaced-content">
                        <div className="flex-1">
                            <TextBox
                                onChange={({value}) => setVetCheckSearch(value)}
                                name="vetCheckSearch"
                                value={vetCheckSearch}
                                placeholder={`VetCheck ${contentType} ID`}
                                loading={loading}
                                inputType="number"
                                onEnter={handleGetContent}
                            />
                        </div>
                        <div className="flex-none">
                            <Button
                                // Don't send the event to the handleGetContent function
                                onClick={() => handleGetContent()}
                                type="success"
                                icon
                                disabled={!vetCheckSearch?.length || loading}
                            >
                                <i className="fa fa-search"/> Find
                            </Button>
                        </div>
                    </div>
                    {showDoesntExistError && (
                        <div className="text-danger margin-bottom-x-sm margin-left-sm">No {contentType} with this ID exists.</div>
                    )}
                    <div>
                        <Button
                            onClick={() => setShowVetCheckContent(true)}
                            type="success"
                            large
                            wide
                            disabled={!props.vetCheckAuthCode}
                        >
                            Browse VetCheck Content
                        </Button>
                    </div>
                </div>
            </div>
            {contentType === HANDOUT && (
                <>
                    <div className="text-xlg margin-top-sm">PIMS Codes</div>
                    <PIMSCodes
                        onChange={handlePimsCodes}
                        name="pimsCodes"
                        selectedPims={pimsCodes}
                    />
                </>
            )}
            {contentType === CLIENT_FORM && (
                <>
                    <div className="text-xlg margin-top-sm">Keywords</div>
                    <Keywords
                        clinicId={props.clinicId}
                        onChange={handleKeywords}
                        name="selectedKeywords"
                        selectedKeywords={selectedKeywords}
                    />
                </>
            )}
            <div className="margin-top-md border-top flex spaced-content justify-flex-end">
                <Button onClick={onClose}
                    type="gray"
                >
                    Cancel
                </Button>
                <Button onClick={handleCreateAutomation}
                    type="success"
                    disabled={!(selectedContent && (
                        (contentType === CLIENT_FORM && selectedKeywords?.length) ||
                        (contentType === HANDOUT && pimsCodes?.length)
                    ))}
                >
                    Save
                </Button>
            </div>
        </div>
    )

    return (
        <>
            <Modal
                show={props.showNewAutomation && !showVetCheckContent}
                modalTitle="Add VetCheck Automation"
                onClose={onClose}
                mediumSmall
            >
                <div className={styles.root}>
                    {!contentType ? SELECT_CONTENT : AUTOMATION_FORM}
                </div>
            </Modal>
            <Modal
                show={props.showNewAutomation && showVetCheckContent}
                modalTitle="Select VetCheck Content"
                onClose={() => setShowVetCheckContent(false)}
                modalType={ModalTypes.FULLSCREEN}
                tall
                flexBody
            >
                <VetCheckPlugIn
                    clinicId={props.clinicId}
                    shareLinkOnly
                    onSelect={handleContentSelected}
                />
            </Modal>
        </>
    );
}

AddVetCheckAutomation.propTypes = {
    clinicId: PropTypes.number.isRequired,
    handleClose: PropTypes.func.isRequired,
    showNewAutomation: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        // Permissions
        const canEditVetCheckMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.VETCHECK_MAPPING, userProfile);
        return {
            keywords: state.entities.keywords?.[ownProps.clinicId] || [],
            pims: state.entities?.pims?.products,
            vetCheckAuthCode: state.entities.clinicVetCheckStatus[ownProps.clinicId]?.authCode || null,
            // Permissions
            canEditVetCheckMapping
        }
    },
    (dispatch) => ({
        createVetCheckMappings: (data, handoutInfo) => dispatch(VetCheckActions.createVetCheckMappings(data, handoutInfo)),
    })
)(AddVetCheckAutomation);
