import {combineReducers} from "redux";
import produce from "immer";
import logger from "utils/logger";
import * as ActionTypes from "constants/ActionTypes";


function apiUpAndRunning(state=true, action) {
    switch (action.type) {
        case ActionTypes.HEALTH_CHECK_UNSUCCESSFUL:
            return false;
        case ActionTypes.HEALTH_CHECK_SUCCESSFUL:
            return true;
        default:
            return state;
    }
}

function appErrors(state = {}, action) {
    switch (action.type) {
        case ActionTypes.REQUEST_ERROR:
            logger.error(action.error);
            const error = {
                title: "Error",
                message: "There was an unknown error.",
            };

            if (action.error && action.error.response) {
                error.title = `Error: ${action.error.message}`;
                error.message = `${action.error.response.error.message}`;
            } else if (action.error) {
                error.title = `Error: ${action.error.message}`;
            }

            return produce(state, newState => {
                newState.appError = Object.freeze(error);
            });

        case ActionTypes.APP_ERROR_CLEARED:
            return produce(state, newState => {
                newState.appError = null;
            });
        default:
            return state;
    }
}

function errorMessage(state="", action) {
    switch (action.type) {
        case ActionTypes.REQUEST_ERROR:
            logger.error(action.error);
            if ("response" in action.error) {
                return action.error.response.error.message;
            } else {
                return action.error.message;
            }
        default:
            return state;
    }
}

function hasError(state=false, action) {
    switch (action.type) {
        case ActionTypes.REQUEST_ERROR:
            return true;
        default:
            return state;
    }
}

function windowIsActive(state = true, action) {
    switch (action.type) {
        case ActionTypes.WINDOW_BLUR_EVENT:
            return false;
        case ActionTypes.WINDOW_FOCUS_EVENT:
            return true;
        default:
            return state;
    }
}

// function queryParams(state={}, action) {
//     switch (action.type) {
//         case ActionTypes.SET_QUERY_PARAMS:
//             return queryString.parse(action.queryString);
//         default:
//             return state;
//     }
// }
//
// function previousRoute(state=null, action) {
//     switch (action.type) {
//         case ActionTypes.ROUTE_CHANGED:
//             return action.route;
//         default:
//             return state;
//     }
// }
//
function working(state=0, action) {
    switch (action.type) {
        case ActionTypes.APP_WORKING_END:
            return state - 1;
        case ActionTypes.APP_WORKING_START:
            return state + 1;
        default:
            return state;

    }

}

export default combineReducers({
    apiUpAndRunning,
    appErrors,
    errorMessage,
    hasError,
    windowIsActive,
    working,
    // queryParams,
    // previousRoute,
});
