import {parse, stringify} from "query-string";
import get from "lodash/get";
import forEach from "lodash/forEach";
import logger from "utils/logger";

export function getQueryStringParam(qs, paramName) {
    return get(parse(qs), paramName);
}

export function setQueryStringParam(qs, paramName, value) {
    const params = parse(qs);
    params[paramName] = value;
    return stringify(params, {arrayFormat: 'separator', arrayFormatSeparator: '|'});
}

export function setQueryStringParams(qs, newParams={}) {
    const params = parse(qs);
    forEach(newParams, (value, name) => {
        params[name] = value;
    })

    return stringify(params, {
        arrayFormat: 'separator',
        arrayFormatSeparator: '|'
    });
}

export function parseQueryString(qs) {
    return parse(qs, {arrayFormat: 'separator', arrayFormatSeparator: '|'});
}
