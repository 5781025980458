/**
 * Returns a "response" Promise that resolves with a `body` property, after a random delay to simulate network latency.
 * Used for placeholder API calls until the real thing exists.
 */
export default function createFakeApiResponse(responseBody) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve({
                body: responseBody,
            });
        }, 250 + Math.floor(Math.random() * 2000));
    });
}
