import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import GenericAnimation from "./GenericAnimation";

export default function MultipleAnimations(props) {
	const [currentAnimation, setCurrentAnimation] = useState(0);

	const handleAnimationComplete = () => {
		if (!!props.onAnimationCompleted) {
			props.onAnimationCompleted(currentAnimation);
		}
		if (currentAnimation + 1 < props.animations.length) {
			props.onBeforeAnimation(currentAnimation + 1);
			setCurrentAnimation(currentAnimation + 1);
		} else if (props.repeat) {
			props.onBeforeAnimation(0);
			setCurrentAnimation(0);
		}
	};

	if (!props.animations?.[currentAnimation]) {
		return null;
	}

	return (
        <GenericAnimation
	        key={currentAnimation}
            {...props.animations[currentAnimation]}
            onAnimationComplete={handleAnimationComplete}
            onAnimationStarted={props.onAnimationStarted}
        />
    );
}

MultipleAnimations.defaultProps = {
	onAnimationStarted: undefined,
	onBeforeAnimation: () => {},
	repeat: false,
};

MultipleAnimations.propTypes = {
	animations: PropTypes.arrayOf(PropTypes.shape({
		altText: PropTypes.string.isRequired,
		animationDuration: PropTypes.number, // How long should the whole animation play (Repeat animation for X seconds)
		duration: PropTypes.number.isRequired, // How long does it take for the animation to play once
		frames: PropTypes.arrayOf(PropTypes.shape({
			image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // If image is different then the default
			duration: PropTypes.number, // If duration should not be an equal part of the animation
			top: PropTypes.string,
			right: PropTypes.string,
			bottom: PropTypes.string,
			left: PropTypes.string,
		})).isRequired,
		height: PropTypes.string.isRequired,
		image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
		isAnimated: PropTypes.bool,
		isTransparent: PropTypes.bool,
		lastFrame: PropTypes.number,
		left: PropTypes.string,
		top: PropTypes.string,
		width: PropTypes.string.isRequired,
	})).isRequired,
	onAnimationCompleted: PropTypes.func,
	onAnimationStarted: PropTypes.func,
	onBeforeAnimation: PropTypes.func,
	repeat: PropTypes.bool,
};
