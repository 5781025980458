import React, { useContext } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as AdminActions from "actions/AdminActions";
import {windowSizeContext} from "AppRoot";
import Button from "components/common/Button";

function ToolbarAddTool(props) {
    const context = useContext(windowSizeContext) || {};

    const handleClick = () => {
        props.showAddForm();
    }
    return (
        <div className="flex-none flex no-wrap">
            <Button
                icon
                onClick={handleClick}
                iconOnly={context.isPhone}
            >
                <i className="fas fa-plus" /><span className="mobile-hide"> {!!props.buttonLabel ? props.buttonLabel : "Add New" }</span>
            </Button>
        </div>
    )
}

ToolbarAddTool.propTypes = {
    searchConfig: PropTypes.object.isRequired,
    buttonLabel: PropTypes.string,
}

export default connect(
    null,
    (dispatch) => ({
        showAddForm: () => dispatch(AdminActions.showAddForm()),
    })
)(ToolbarAddTool);