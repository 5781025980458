import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import reject from "lodash/reject";
import Dropdown from "components/common/Dropdown";
import GreenlineWellnessPlanServices from "components/greenlineWellness/GreenlineWellnessPlanServices";
import TextBox from "components/common/TextBox";
import CheckboxInput from "components/common/CheckboxInput";
import Button from "components/common/Button";

import PlanEnrollmentMapping from "components/admin/elements/PlanEnrollmentMapping";

export default function GreenlineWellnessEnrollmentMappings(props) {
    const [showAddNew, setShowAddNew] = useState(false);

    const lastMapping = props.clinicId
        ? Object.keys(props.enrollmentMappings||{}).length
            ? orderBy(props.enrollmentMappings, ["id"], ["desc"])[0]
            : {}
        : {};
    const nextId = lastMapping ? lastMapping.id + 1 : 0;

    const handleDeleteMapping = (PEM) => {
        props.onChange({
            enrollmentMapping: {
                ...PEM,
                isDeleted: true,
            }
        });
    }

    const handleChangeToBlankMapping = (data) => {
        setShowAddNew(false);
        props.onChange(data);
    }

    const blankMapping = (
        <div className="padding-top-sm">
            <PlanEnrollmentMapping
                planEnrollmentMapping={{}}
                nextId={nextId}
                usedPims={props.usedPims}
                handleChange={handleChangeToBlankMapping}
                readOnly={props.readOnly}
            />
        </div>
    );

    //Remove the ones that were deleted
    const enrollmentMappings = useMemo(() => {
        return reject(props.enrollmentMappings, "isDeleted");
    }, [props.enrollmentMappings]);

    const displayEnrollmentMappings = (
        <div>
            {enrollmentMappings.length ? (
                map(enrollmentMappings, (PEM, index) => (
                    <div className="padding-top-sm">
                        <PlanEnrollmentMapping
                            key={PEM.id}
                            planEnrollmentMapping={PEM}
                            usedPims={props.usedPims}
                            handleChange={props.onChange}
                            handleDeleteMapping={() => handleDeleteMapping(PEM)}
                            readOnly={props.readOnly}
                        />
                    </div>
                ))
            ) : (
                blankMapping
            )}
        </div>
    );

    return (
        <div className="flex spaced-content align-center">
            <div className="flex-1 margin-top-sm margin-bottom-sm">
                <div className="flex spaced-content align-center">
                    <label className="margin-left-sm flex-1">
                        Plan Enrollment Mapping{(keys(enrollmentMappings||{}).length > 1) && "s"}
                    </label>
                    <Button
                        iconOnly
                        text
                        onClick={() => setShowAddNew(true)}
                        type="success"
                        disabled={!keys(enrollmentMappings).length || showAddNew || props.readOnly}
                    >
                        <i className="fa fa-2x fa-plus-square" />
                    </Button>
                </div>
                {displayEnrollmentMappings}
                {(keys(enrollmentMappings).length && showAddNew) ? (blankMapping) : null}
            </div>
        </div>
    )
}

GreenlineWellnessEnrollmentMappings.defaultPropTypes = {
    readOnly: false,
    enrollmentMappings: [],
}

GreenlineWellnessEnrollmentMappings.propTypes = {
    readOnly: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    clinicId: PropTypes.number.isRequired,
    usedPims: PropTypes.arrayOf(PropTypes.number),

    enrollmentMappings: PropTypes.shape([]),
};
