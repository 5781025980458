exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ProgramOfferGroup__root{width:100%}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/coupons/widgets/ProgramOfferGroup.scss"],"names":[],"mappings":"AAAA,yBAAM,UAAU,CAAC","file":"ProgramOfferGroup.scss","sourcesContent":[".root{width:100%}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "ProgramOfferGroup__root"
};