import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "../LiveChat/LiveChat.scss";
import Button from "components/common/Button/Button";

export default function FakeLiveChat(props) {
    const parentButton = "islpronto_text";
    const {
        onDismiss = () => {},
        buttonText = document.getElementById(parentButton)?.text || "Email Support",
    } = props;

    const clickButton = () => {
        onDismiss();
        document.getElementById(parentButton)?.click();
    }

    return (
        <div data-testid="fake_live_chat_component">
            <div
                data-testid="fake_live_chat_styles"
                className={classnames(styles.root, {
                    [styles.liveChatImage]: props.imageLink,
                    [styles.footer] : props.footer,

                    // Test Styles
                    "test-image-link": props.imageLink,
                    "test-footer": props.footer,
                })}
            >
                <Button
                    data-testid="fake_live_chat_component_button"
                    onClick={clickButton}
                    disabled={props.disabled}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    )
}

FakeLiveChat.propTypes = {
    onDismiss: PropTypes.func,
    disabled: PropTypes.bool,
    buttonText: PropTypes.string,
    parentButton: PropTypes.string,
};
