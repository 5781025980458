import React, {useState} from "react";
import PropTypes from "prop-types";
import CheckboxInput from "components/common/CheckboxInput";
import NotificationButtons from "components/notifications/elements/NotificationButtons";
import { DECLINED, ENROLLED } from "constants/Notifications";
import TextBox from "components/common/TextBox";
import UserInitials from "components/notifications/elements/UserInitials";
import { isEmailValid } from "utils/helperUtils";

export default function BiIRForm(props) {
    const [formData, setFormData] = useState({});
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [initials, setInitials] = useState("");

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onEnroll(initials, formData);
    };

    const handleDecline = () => {
        props.onDecline(initials);
    }

    const handleChange = ({name, value}) => {
        const newFormData = {
            ...formData,
            [name]: value
        }
        setFormData(newFormData);
    }

    const isEnrolled = props.enrollmentState === ENROLLED;
    const isDeclined = props.enrollmentState === DECLINED;
    const allRequired = formData.SignUpName && isEmailValid(formData.SignUpEmail) && formData.SignUpPhoneNumber;

    return (
        <form onSubmit={handleSubmit}>
            {isEnrolled && <div>Your clinic has opted in.</div>}
            {isDeclined && <div>Your clinic has opted out.</div>}
            {(!isEnrolled && !isDeclined) && (
                <>
                    <div className="border-bottom">
                        <div className="margin-bottom-md">
                            Boehringer Ingelheim is pleased to offer your clinic the ability to give point of sale discounts instead of having your client wait to receive a Visa&reg; prepaid card in the mail.
                            These are called Instant Rebates!
                        </div>
                        <div>Benefits of the Instant Rebate Program</div>
                        <ul>
                            <li>Instant gratification for clients as discount off at the time of purchase.</li>
                            <li>Clinic ability to offer all Boehringer Ingelheim parasiticide rebates.</li>
                            <li>Clients save money immediately when purchasing vet-recommended Boehringer Ingelheim products.</li>
                        </ul>
                        <div className="margin-top-sm">How Does it Work?</div>
                        <ul>
                            <li>GREENLINE&reg; Specialist reaches out to create Instant Rebate codes within your practice management software as well as demonstrate how they will work.</li>
                            <li>Allotted time needed for setup is on average 15 minutes</li>
                        </ul>
                        <div className="margin-top-md">If you are interested in enrolling for this program, please fill out the below form to be contacted by a Greenline&reg; Specialist.</div>
                    </div>
                    <div className="flex spaced-content flex-centered">
                        <div className="flex-1">
                            <TextBox
                                label="Name"
                                onChange={handleChange}
                                name="SignUpName"
                                value={formData.SignUpName}
                                required
                            />
                        </div>
                        <div className="flex-1">
                            <TextBox
                                label="Phone Number"
                                onChange={handleChange}
                                name="SignUpPhoneNumber"
                                value={formData.SignUpPhoneNumber}
                                required
                            />
                        </div>
                    </div>
                    <div>
                        <TextBox
                            label="Email"
                            onChange={handleChange}
                            name="SignUpEmail"
                            value={formData.SignUpEmail}
                            required
                        />
                    </div>
                    <div className="border-bottom">
                        <TextBox
                            textArea
                            label="Additional Information"
                            onChange={handleChange}
                            name="SignUpNotes"
                            value={formData.SignUpNotes}
                            required
                        />
                    </div>
                    <div className="flex spaced-content flex-centered border-bottom">
                        <div className="flex-1">
                            <UserInitials
                                onChange={({value}) => setInitials(value)}
                                value={initials}
                                disabled={!allRequired}
                            />
                        </div>
                        <div className="flex-2">
                            <CheckboxInput
                                name="isAuthorized"
                                checked={isAuthorized}
                                onChange={() => setIsAuthorized(!isAuthorized)}
                                required
                                disabled={!initials}
                                label="I authorize this service and agree to the terms above"
                            />
                        </div>
                    </div>
                    <div className="margin-top-md margin-bottom-md">
                        <NotificationButtons
                            notificationDetails={props.notificationDetails}
                            onCancel={props.onCancel}
                            onDecline={handleDecline}
                            onDismiss={props.onDismiss}
                            declineDisabled={!initials}
                            enrollDisabled={(!allRequired || !isAuthorized || !initials)}
                            isPreview={props.isPreview}
                        />
                    </div>
                    <div className="text-sm">
                        All trademarks shown are the property of their respective owners.
                        &copy;2021 Boehringer Ingelheim Animal Health USA Inc., Duluth, GA.
                        All rights reserved.
                        US-PET-0462-2021
                    </div>
                </>
            )}
        </form>
    )
}

BiIRForm.propTypes = {
    notificationDetails: PropTypes.object,
    enrollmentState: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onEnroll: PropTypes.func.isRequired,
    onDecline: PropTypes.func.isRequired,
    onDismiss: PropTypes.func,
    isPreview: PropTypes.bool
};
