import React, { useState } from "react";
import styles from "../PuppyPoolPartyCard/PuppyPoolPartyCard.scss";
import ImportantSafetyInformation from "components/common/ImportantSafetyInformation";
import Modal from "components/common/Modal";

export default function PoolPartyLegalWording(props) {
	const [showSafetyInfo, setShowSafetyInfo] = useState(false);
	const handleShowSafetyInformation = () => {
        setShowSafetyInfo(true);
    }
	return (
		<div>
			<div className={styles.tagLine}>
                Dispense HEARTGARD® Plus (ivermectin/pyrantel) <br/>
                and <em>NexGard</em>® (afoxolaner), Achieve Goals.
            </div>
            <div className={styles.viewSafetyLink} onClick={handleShowSafetyInformation}>
                Click here to view the Important Safety Information for HEARTGARD® Plus (ivermectin/pyrantel) and <em>NexGard</em>® (afoxolaner)
            </div>
			<Modal
                modalTitle="Important Safety Information"
                show={showSafetyInfo}
                onClose={() => setShowSafetyInfo(false)}
            >
                <ImportantSafetyInformation />
            </Modal>
		</div>
	);
};