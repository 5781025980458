import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Slider from "components/common/Slider";
import TextBox from "components/common/TextBox";
import {clone, cloneDeep, merge, mergeWith, round, set} from "lodash";
import { computeEstROI, computeIncreasePercentage, computePrize } from "utils/roiCalculations";
import * as styles from "./GameConfigEditorRow.scss";
import classnames from "classnames";

export default function GameConfigEditorRow(props) {
    if (!props.isROI && !props.isPrize) return null;
    // NO POINT IN CONTINUING THIS...

    const {prizeLocked} = props;
    const [formData, setFormData] = useState({});
    const increasePercentageLocked = props.increasePercentageLocked || (props.prizeAmountLocked && props.estROILocked);
    const prizeAmountLocked = props.prizeAmountLocked || (props.increasePercentageLocked && props.estROILocked);
    const estROILocked = props.estROILocked || (props.prizeAmountLocked && props.increasePercentageLocked);
    const prizeDisplayNameLabel = "Name" + props.arePrizesOptional ? " (optional)" : "";

    const combinedData = cloneDeep(props.data);

    const custom = (objValue, srcValue) => {
      if (_.isArray(objValue)) {
          return srcValue;
      }
    }

    mergeWith(combinedData, formData, custom);

    if (!combinedData) {
        return <tr/>;
    }

    const handleLevelDataChange = ({ name, value }) => {
        if (name === "IncreasePercentage") {
            // Make sure that the IncreasePercentage is always a whole number
            value = round(value);
        }
        const newFormData = {
            ...formData,
            [name]: value
        };

        if(name !== "DisplayName") {
            //Don't recalculate the ROI data when the name is changed
            if (!(estROILocked || name === "EstROI")) {
                newFormData.EstROI = computeEstROI(combinedData, props.products, name, value);
            } else if (!(increasePercentageLocked || name === "IncreasePercentage")) {
                newFormData.IncreasePercentage = computeIncreasePercentage(combinedData, props.products, name, value);
            } else if (!(prizeAmountLocked || name === "PrizeAmount")) {
                newFormData.PrizeAmount = computePrize(combinedData, props.products, name, value);
            }
        }

        setFormData(newFormData);
        const newCombinedData = cloneDeep(combinedData);
        mergeWith(newCombinedData, newFormData, custom);
        props.onChange(newCombinedData);
    }

    const handlePrizeDataChange = ({name, value}) => {
        const newFormData = {
            ...formData,
            [name]: value
        };
        setFormData(newFormData);
        const newCombinedData = {
            ...combinedData,
            ...newFormData
        }
        props.onChange(newCombinedData);
    }

    const renderPrizeRow = () => {
        if (props.isMobileView) {
            return (
                <tr>
                    <td className={styles.row}>
                        <h6>Level: {combinedData.prize}</h6>
                        <h3>Prizes</h3>
                        <TextBox
                            name="DisplayName"
                            value={combinedData.DisplayName}
                            onChange={handlePrizeDataChange}
                            disabled={prizeLocked || props.readOnly}
                            label={prizeDisplayNameLabel}
                        />
                        <TextBox
                            inputType="number"
                            name="PrizeAmount"
                            value={combinedData.PrizeAmount}
                            onChange={handlePrizeDataChange}
                            disabled={prizeLocked || props.readOnly}
                            step="any"
                            label="Prize Amount ($)"
                        />
                    </td>
                </tr>
            )
        }

        return (
            <tr>
                <td>{combinedData.prize}</td>
                <td>
                    <TextBox
                        name="DisplayName"
                        value={combinedData.DisplayName}
                        onChange={handlePrizeDataChange}
                        disabled={prizeLocked || props.readOnly}
                    />
                </td>
                <td style={{maxWidth: "100px"}}>
                    <TextBox
                        inputType="number"
                        name="PrizeAmount"
                        value={combinedData.PrizeAmount}
                        onChange={handlePrizeDataChange}
                        disabled={prizeLocked || props.readOnly}
                        step="any"
                    />
                </td>
            </tr>
        )
    }
    const allLocked = (props.IncreasePercentageLocked && props.estROILocked);
    const renderROI = () => {
       if (props.isMobileView) {
           return (
               <tr>
                   <td className={styles.row}>
                       <h6>LEVEL: {combinedData.level}</h6>
                       <TextBox
                           name="DisplayName"
                           value={combinedData.DisplayName}
                           onChange={value => handleLevelDataChange(value)}
                           disabled={props.readOnly}
                           label={prizeDisplayNameLabel}
                       />
                       <div className={styles.sliderContainer}>
                            <div>Percent Increase (in doses) {
                                props.checkIsLocked({
                                    name: "IncreasePercentageLocked",
                                    value: props.IncreasePercentageLocked
                                })}
                            </div>
                            <div className={classnames(styles.slider, "full-width flex justify-center align-center")}>
                                <span className={styles.slide}>
                                    <Slider
                                        value={combinedData.IncreasePercentage}
                                        onChange={value => handleLevelDataChange({name: "IncreasePercentage", value})}
                                        disabled={props.readOnly}
                                    />
                                </span>
                                <span className={classnames(styles.textBox)}>
                                    <TextBox
                                        inputType="number"
                                        name="IncreasePercentage"
                                        value={combinedData.IncreasePercentage}
                                        onChange={handleLevelDataChange}
                                        disabled={increasePercentageLocked || props.readOnly}
                                        min={0}
                                        step={1}
                                    />
                                </span>
                           </div>
                       </div>
                       <TextBox
                           inputType="number"
                           name="PrizeAmount"
                           value={round(combinedData.PrizeAmount, 2)}
                           onChange={handleLevelDataChange}
                           disabled={prizeAmountLocked || props.readOnly}
                           min={0}
                           step="any"
                           label="Total Cost ($)"
                       />
                       <TextBox
                           inputType="number"
                           name="EstROI"
                           value={combinedData.EstROI}
                           onChange={handleLevelDataChange}
                           disabled={estROILocked || props.readOnly}
                           min={1}
                           step="any"
                           label="Target ROI (min 5.0)"
                       />
                   </td>
               </tr>
           );
       }

       return (
            <tr>
                <td className={styles.row}>
                    {combinedData.level}
                </td>
                <td className={styles.row}>
                    <TextBox
                        name="DisplayName"
                        value={combinedData.DisplayName}
                        onChange={value => handleLevelDataChange(value)}
                        disabled={props.readOnly}
                    />
                </td>
                <td className={styles.row}>
                    <div className={classnames(styles.slider, "full-width flex justify-center align-center")}>
                        <span className={styles.slide}>
                            <Slider
                                value={combinedData.IncreasePercentage}
                                onChange={value => handleLevelDataChange({name: "IncreasePercentage", value})}
                                disabled={allLocked || props.readOnly}
                            />
                        </span>
                        <span className={classnames(styles.textBox)}>
                            <TextBox
                                inputType="number"
                                name="IncreasePercentage"
                                value={combinedData.IncreasePercentage}
                                onChange={handleLevelDataChange}
                                disabled={increasePercentageLocked || props.readOnly}
                                min={0}
                                step={1}
                            />
                        </span>
                    </div>
                </td>
                <td className={styles.row}>
                    <TextBox
                        inputType="number"
                        name="PrizeAmount"
                        value={round(combinedData.PrizeAmount, 2)}
                        onChange={handleLevelDataChange}
                        disabled={prizeAmountLocked || props.readOnly}
                        min={0}
                        step="any"
                    />
                </td>
                <td className={styles.row}>
                    <TextBox
                        inputType="number"
                        name="EstROI"
                        value={combinedData.EstROI}
                        onChange={handleLevelDataChange}
                        disabled={estROILocked || props.readOnly}
                        min={1}
                        step="any"
                    />
                </td>
            </tr>
        );
    }

    if (props.isROI) {
        return renderROI();
    }

    if (props.isPrize) {
        return renderPrizeRow();
    }
}

GameConfigEditorRow.propTypes = {
    excludeFreeDoses: PropTypes.bool,
    data: PropTypes.object, // Game goal level data to display
    onChange: PropTypes.func, // Callback will receive the updated row data object
    totalPWholesalePrice: PropTypes.number,
    products: PropTypes.arrayOf(PropTypes.object),

    //Disables Fields when lock is on
    increasePercentageLocked: PropTypes.bool,
    prizeAmountLocked: PropTypes.bool,
    estROILocked: PropTypes.bool,
    prizeLocked: PropTypes.bool,
    readOnly: PropTypes.bool,

    //Row Type - Based on Game Type
    isPrize: PropTypes.bool,
    isROI: PropTypes.bool,
    isMobileView: PropTypes.bool,
    checkIsLocked: PropTypes.func,

    arePrizesOptional: PropTypes.bool,
}
