import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "components/common/Button";
import TextBox from "components/common/TextBox";
import * as styles from "./ApproveDeclineSingleCoupon.scss";
import classnames from "classnames";
import * as CssStyleIds from "constants/CssStyleIds";
import replace from "lodash/replace";

export default function ApproveDeclineSingleCoupon(props) {
    const [initials, setInitials] = useState("");

    if(!props.coupon) {
        return null;
    }

    const handleSubmit = () => {
        props.onSubmit(initials, [props.couponId]);
    };

    const handleChange = ({value}) => {
        //Remove special characters (only letters allowed)
        const newValue =  replace(value, /[^A-Z]/ig, "");
        setInitials(newValue);
    };

    return (
        <div className="flex flex-column">
            <div>
                <div><span className="text-bold">Invoice Number:</span> {props.coupon.invoiceNumber}</div>
                <div><span className="text-bold">Invoice Date:</span> {props.coupon.invoiceDate}</div>
                <div><span className="text-bold">Last Name:</span> {props.coupon.lastName}</div>
                <div><span className="text-bold">Pet Name:</span> {props.coupon.petName}</div>
                <div><span className="text-bold">Product:</span> {props.coupon.productName}</div>
                <div><span className="text-bold">Offer:</span> {props.coupon.offerName}</div>
            </div>

            <div className={styles.root}>
                <div className={styles.buttonHolder}>
                    <div className={styles.form}>
                        <i className={classnames("fas fa-check-circle", {
                            [styles.valid]: initials
                        })} />
                        <span>Initial to certify</span>
                        <div className={styles.initials}>
                            <TextBox
                                data-testid="initials-textbox"
                                name="initials"
                                value={initials}
                                onChange={handleChange}
                            />
                        </div>
                        <Button
                            data-testid="submit-button"
                            type={props.isApprove ? CssStyleIds.SUCCESS : CssStyleIds.DANGER}
                            onClick={handleSubmit}
                            disabled={!initials}
                        >
                            {props.isApprove ? "Approve" : "Decline"}
                        </Button>
                    </div>
                    <div className={styles.cancelHolder}>
                        <Button
                            data-testid="cancel-button"
                            type={CssStyleIds.GRAY}
                            large
                            onClick={props.onClose}
                        >
                            Cancel
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

ApproveDeclineSingleCoupon.propTypes = {
    couponId: PropTypes.number.isRequired,
    coupon: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func,
    isApprove: PropTypes.bool,
};
