exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ProviderClinicDetails__root .ProviderClinicDetails__datePicker{-ms-flex:1 1 0px;flex:1 1 0;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/provider/widgets/ProviderClinicDetails.scss"],"names":[],"mappings":"AAAA,gEAAkB,iBAAiB,WAAW,oBAAoB,aAAa,sBAAsB,kBAAkB,CAAC","file":"ProviderClinicDetails.scss","sourcesContent":[".root .datePicker{-ms-flex:1 1 0px;flex:1 1 0;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "ProviderClinicDetails__root",
	"datePicker": "ProviderClinicDetails__datePicker"
};