import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {getClinicInfo} from "utils/ClinicData";

function GreenlineSupportPhoneNumber(props) {
    if(props.clinicHasPrioritySupport) {
        return (
            <a href="tel:18666089038,3"
               className="text-primary"
            >
                +1 (866) 608-9038, Option 3
            </a>
        );
    }

    return (
        <a href="tel:18666089038"
           className="text-primary"
        >
            +1 (866) 608-9038
        </a>
    );
}

GreenlineSupportPhoneNumber.propTypes = {
    clinicId: PropTypes.number.isRequired,
};

export default connect(
    (state, ownProps) => {
        const clinic = getClinicInfo(state, ownProps.clinicId);
        //Permissions
        const clinicHasPrioritySupport = !!clinic?.prioritySupport?.length;
        return {
            clinicHasPrioritySupport
        }
    }
)(GreenlineSupportPhoneNumber);
