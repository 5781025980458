export const PROVIDER_ID = "ProviderId";
export const ACTIVE = "Active";
export const CITY = "City";
export const CLINIC_ID = "ClinicId";
export const CLINIC_NAME = "ClinicName";
export const COUNTRY_CODE = "CountryCode";
export const DIRTY_REDEMPTIONS = "DirtyRedemptions";
export const GATEWAY_STATUS = "GatewayStatus";
export const ACTIVATION_DATE = "ActivationDate";
export const INSTALL_DATE = "InstallDate";
export const LAST_INVOICE = "LastInvoice";
export const MAPPED_PRODUCTS = "MappedProducts";
export const CLOO_ID = "CLOOId";
export const PAR = "PAR";
export const PENDING_APPROVAL = "PendingApproval";
export const PIMS = "PIMS";
export const PRODUCT_SYNC = "ProductSync";
export const PROVIDER_REPORT_ID = "ProviderReportId";
export const STATE = "State";
export const TAGS = "Tags";
export const TERRITORY_ID = "TerritoryID";