import React from 'react';
import Modal from 'components/common/Modal';
import PropTypes from 'prop-types';
import CardForm from "../CardForm";

export default function CardModal(props) {
    return (
        <Modal
            data-testid="card_modal_modal"
            show={props.isOpen}
            onClose={props.closeModal}
            small
            modalTitle={props.title}
        >
            <CardForm
                onCancel={props.closeModal}
                name={props.name}
                handleFormChange={props.handleFormChange}
                handleSubmit={props.handleSubmit}
                stripeError={props.stripeError}
                setStripeError={props.setStripeError}
                btnDisabled={props.btnDisabled}
            />
        </Modal>
    )
}

CardModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    title: PropTypes.string,
    handleFormChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func,
    stripeError: PropTypes.string,
    setStripeError: PropTypes.func,
    btnDisabled: PropTypes.bool,
}
