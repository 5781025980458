import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import styles from "./PetPlanTracking.scss";
import * as WellnessActions from "actions/WellnessActions";
import SortableDataTable from "components/common/SortableDataTable";
import PlanStatusFilterDropdown from "components/wellness/widgets/PlanStatusFilterDropdown";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import * as PlanStatusIds from "constants/PlanStatusIds";
import { createPlanOptionsObject } from "constants/PlanStatusIds";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import PcpPetPlanEditForm from "components/wellness/widgets/PcpPetPlanEditForm";
import Tooltip from "components/common/Tooltip";
import AccessDenied from "components/common/AccessDenied";

function PetPlanTracking(props)  {
    const [statusFilter, setStatusFilter] = useState(null);
    const [dataList, setDataList] = useState({});
    const [selectedPetPlanToEdit, setSelectedPetPlanToEdit] = useState(null)

    const handleEdit = (e, row) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.canEditClinicWellnessPetPlans) {
            setSelectedPetPlanToEdit(row);
        }
    }

    const COLUMNS = [
        {
            name: 'Client',
            selector: 'name',
            key: "name",
            sortable: true,
            searchable: true,
            format: row => <div>{row.name} <span>({row.id})</span></div>,
        }, {
           name: 'Patient Name',
            selector: 'patient',
            key: "patient",
            sortable: true,
            searchable: true,
            format: row => <div>{row.patient} <span>({row.petDisplayId})</span></div>,
        }, {
            name: 'Plan',
            selector: 'plan',
            key: "plan",
            sortable: true,
        }, {
            name: 'Plan Start Date',
            selector: 'planStartDate',
            key: "planStartDate",
            sortable: true,
            format: row => <div>{moment(row.planStartDate).format("MM/DD/YYYY")}</div>,
        }, {
            name: 'Plan Duration',
            selector: 'planDuration',
            key: "planDuration",
            sortable: true,
            format: row => <div>{moment(row.planStartDate).to(row.planEndDate, true)}</div>,
        }, {
            name: 'Last Payment',
            selector: 'lastPayment',
            key: "lastPayment",
            sortable: true,
            format: row => (
                <div className="no-wrap">
                    {moment(row.lastPayment).format('MM/DD/YYYY')} {(row.paymentStatusCode === "Payment Failed") && (<i className="fa fa-exclamation-triangle text-danger margin-left-sm" />)}
                </div>
            ),
            sortValue: row => row.lastPayment ? moment(row.lastPayment) : null,
        }, {
            name: 'Plan Status',
            selector: 'statusName',
            key: "statusName",
            sortable: true,
            format: row => {
                if (row.ppgIsCancelled) {
                    return "Cancelled";
                } else if (!row.statusName || row.statusName === "Unknown") {
                    return(
                        <Tooltip position="bottom" tip="Patient could not be matched with Premier PCP API">
                            <div>
                                {row.statusName || "Unknown"}
                            </div>
                        </Tooltip>
                    )
                } else {
                    return(
                        <div>{row.statusName}</div>
                    )
                }
            }
        }, {
            name: '',
            selector: 'edit',
            key: "edit",
            format: row => (row.isEditable && props.canEditClinicWellnessPetPlans) ? (
                <Button
                    small
                    iconOnly
                    disabled={!props.canEditClinicWellnessPetPlans}
                    onClick={e => handleEdit(e, row)}
                >
                    <i className="fa fa-pencil text-sm"/>
                </Button>
            ) : "",
        },
    ];

    const loadPetTracking = (clinicId, status=null) => {
        if(props.canViewClinicWellnessPetPlans) {
            props.getWellnessPlans(clinicId, status);
        }
    }

    useEffect(() => {
        loadPetTracking(props.clinicId, statusFilter)
    }, [props.clinicId, statusFilter]);

    useEffect(() => {
        if(props.wellness && props.wellness.searchResults) {
            setDataList(props.wellness.searchResults);
        } else if(props.wellness && props.wellness.plans) {
            setDataList(props.wellness.plans);
        }
    }, [props.wellness]);

    const selectWellnessPlan = (planData) => {
        props.selectWellnessPlan(planData.id);
    }

    const handleStatusFilterChanged = ({name, value}) => {
        setStatusFilter(value);
    }

    const handlePetPlanSelected = petPlanReference => {
        if(props.canEditClinicWellnessPetPlans) {
            props.updatePremierPetPlan(
                props.clinicId,
                selectedPetPlanToEdit.petId,
                selectedPetPlanToEdit.patientPlanEnrollmentId,
                petPlanReference,
            );
            handleClosePetPlanEdit();
        }
    }

    const handleClosePetPlanEdit = () => {
        setSelectedPetPlanToEdit(null);
    }

    const getConditionalRowProps = (row) => {
        if (row.status === 2) {
            return {
                style: {
                    backgroundColor: 'rgba(201, 0, 0, 0.15)',
                    color: 'rgb(201, 0, 0)',
                    borderColor: 'rgb(201, 0, 0)',
                }
            };
        } else if (row.status === 0) {
            return {
                style: {
                    color: '#A9A9A9',
                }
            };
        } else {
            return {};
        }
    };

    return (
        <div className={styles.root}>
            <div className={styles.mainSection}>
                <div className={styles.section}>
                    <div className={styles.sectionHeader}>
                        <h2>Pet Plan Tracking</h2>
                        <PlanStatusFilterDropdown
                            name="statusFilter"
                            value={statusFilter}
                            options={createPlanOptionsObject([PlanStatusIds.ACTIVE, PlanStatusIds.CANCELLED])}
                            onChange={handleStatusFilterChanged}
                        />
                    </div>
                    {!props.wellness.plans ? (
                        <SpinnerTakeover show/>
                    ) : !Object.keys(props.wellness.plans).length ? (
                        <div className={styles.noWellnessVisits}>
                            <span>No Pet Plans to Show Yet!</span>
                        </div>
                    ) : !Object.keys(dataList).length ? (
                        <div className={styles.noWellnessVisits}>
                            <span>No Pet Plans to show! Try Changing your Filters.</span>
                        </div>
                    ) : (
                        <SortableDataTable
                            columns={COLUMNS}
                            rawData={dataList}
                            onClick={selectWellnessPlan}
                            conditionalRowProps={getConditionalRowProps}
                            resultsPerPage={15}
                            allowSearch
                            hideSearchBar
                            search={props.search}
                        />
                    )}
                </div>
            </div>
            <Modal
                show={!!selectedPetPlanToEdit}
                modalTitle="Edit Pet Plan"
                small
                onClose={handleClosePetPlanEdit}
            >
                {props.canEditClinicWellnessPetPlans ? (
                    <PcpPetPlanEditForm
                        clinicId={props.clinicId}
                        plan={selectedPetPlanToEdit}
                        onSave={handlePetPlanSelected}
                        onClose={handleClosePetPlanEdit}
                    />
                ) : (
                    <AccessDenied/>
                )}
            </Modal>
        </div>
    );
}

PetPlanTracking.propTypes = {
    clinicId: PropTypes.number.isRequired,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canEditClinicWellnessPetPlans = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        return {
            canViewClinicWellnessPetPlans,
            canEditClinicWellnessPetPlans,
            wellness: state.entities.wellness,
            selectedId: state.entities.wellness.selectedId,
            search: state.entities.genericSearch,
        }
    },
    (dispatch) => ({
        getWellnessPlans: (clinicId, status) => dispatch(WellnessActions.getWellnessPlans(clinicId, status)),
        selectWellnessPlan: (petId) => dispatch(WellnessActions.selectWellnessPlan(petId)),
        updatePremierPetPlan: (clinicId, petId, patientPlanEnrollmentId, externalPetPlanReference) => dispatch(WellnessActions.updatePremierPetPlan(clinicId, petId, patientPlanEnrollmentId, externalPetPlanReference)),
    }),
)(PetPlanTracking);
