import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./ProviderTableAndMapContainer.scss";
import Button from "components/common/Button";
import ExpandToggleButton from "components/provider/widgets/ExpandToggleButton";
import ProviderClinicMap from "components/provider/widgets/ProviderClinicMap";
import * as OrgNodeTypes from "constants/OrgNodeTypes";

export default function ProviderTableAndMapContainer(props) {
    const [isTableExpanded, setIsTableExpanded] = useState(props.isExpanded || false);

    const handleClick = isExpanded => {
        setIsTableExpanded(isExpanded);
        if (props.onChange) {
            props.onChange(isExpanded);
        }
    }

    const backButtonText = () => {
        if(props.parentNodeType) {
            // If the parent node exists then button should say back to {parentNodeType}
            return props.parentNodeType;
        } else {
            switch (props.nodeType) {
                case OrgNodeTypes.REGION:
                case OrgNodeTypes.ABL:
                    return OrgNodeTypes.HQ; // Back to HQ
                case OrgNodeTypes.DISTRICT:
                    return OrgNodeTypes.REGION; // Back to Region
                case OrgNodeTypes.TERRITORY:
                    return OrgNodeTypes.DISTRICT; // Back to District
                case OrgNodeTypes.NHO:
                    return OrgNodeTypes.NHO_HQ; // Back to NHOHQ
                case OrgNodeTypes.KAM_DISTRICT:
                    return OrgNodeTypes.KAM_HQ; // Back to KAMHQ
                case OrgNodeTypes.KAM_TERRITORY:
                    return OrgNodeTypes.KAM_DISTRICT; // Back to KAMDistrict
                case OrgNodeTypes.MGR:
                    return OrgNodeTypes.ABL; // Back to ABL
                default:
                    return `${props.nodeType}s` // Back to {nodeType}s
            }
        }
    }

    const renderBackButton = () => {
        if (props.canGoUp) {
            return (
                <Button
                    onClick={props.onGoUp}
                    text
                    small
                >
                    <i className="fas fa-arrow-left"/>&nbsp;Back to {backButtonText()}
                </Button>
            );
        } else {
            return null; // No button for you, fish face!
        }
    };

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className="flex-1">{renderBackButton()}</div>
                <div className="flex-3 text-center">{props.canViewProviderReports ? `Provider Dashboard` : props.nodeType}</div>
            </div>
            <div className={classnames({
                [styles.content]: !isTableExpanded,
                [styles.expandedContent]: isTableExpanded,
            })}>
                <div className={classnames({
                    [styles.buttonContainer]: !isTableExpanded,
                    [styles.expandedButtonContainer]: isTableExpanded,
                })}>
                    {props.canExpand && (
                        <div className="full-width full-height" style={{ position: "relative" }}>
                            <ExpandToggleButton
                                isExpanded={isTableExpanded}
                                onClick={handleClick}
                            />
                        </div>
                    )}
                </div>
                <div className={classnames(styles.expandableTable, {
                    [styles.expanded]: isTableExpanded,
                })}>
                    {props.children} {/* Your collapsed table here */}
                </div>
                {/*{isTableExpanded ? (*/}
                {/*    <div className="full-width full-height" style={{ position: "relative", overflowY: "auto" }}>*/}
                {/*        {props.children} /!* Your expanded table here *!/*/}
                {/*    </div>*/}
                {/*) : (*/}
                {/*    <div style={{ position: "relative", width: "25%", height: "100%", overflowY: "auto" }}>*/}
                {/*        {props.children} /!* Your collapsed table here *!/*/}
                {/*    </div>*/}
                {/*)}*/}
                <div className={classnames({ 
                    [styles.map]: !isTableExpanded,
                    [styles.mapExpanded]: isTableExpanded && props.canViewProviderReports,
                    [styles.mapHidden]: isTableExpanded && !props.canViewProviderReports
                })}>
                    <ProviderClinicMap
                        markers={props.mapMarkers || []}
                        markerChildren={props.markerChildren}
                        onMarkerClick={props.onMarkerClick}
                        onClusterClick={props.onClusterClick}
                        highlightMarker={props.highlightMarker}
                        hoverStart={props.onMarkerHoverStart}
                        hoverEnd={props.onMarkerHoverEnd}
                    />
            </div>
            </div>
        </div>
    );
}

ProviderTableAndMapContainer.propTypes = {
    canExpand: PropTypes.bool,
    highlightMarker: PropTypes.number,
    mapBounds: PropTypes.object,
    nodeType: PropTypes.string,
    parentNodeType: PropTypes.string,
    onChange: PropTypes.func,
    onMarkerHoverEnd: PropTypes.func,
    onMarkerHoverStart: PropTypes.func,
    canViewProviderReports: PropTypes.bool
}

ProviderTableAndMapContainer.defaultProps = {
    canExpand: true,
    canViewProviderReports: false
}
