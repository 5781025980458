import React from "react";
import SortableDataTable from "components/common/SortableDataTable";
import * as ProviderApi from "api/ProviderApi";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import toast from "utils/toast";
import {downloadFile} from "utils/request";
import * as UserPermissions from "constants/UserPermissions";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";


function ProviderPayoutReportTable(props) {

  const handleDownloadExcel = async () => {
    if (props.canViewProviderPayoutReport) {
      toast.success("Generating Export...");
      if(props.isNHO) {
        const res = await ProviderApi.exportNHOProviderCheckSummary(props.downloadParameters);
        downloadFile(res, "xlsx");
      } else {
        const res = await ProviderApi.exportProviderCheckSummary(props.downloadParameters);
        downloadFile(res, "xlsx");
      }
    }
  };


  const COLUMN_NAMES = [
    {
      name: "Manufacturer Name",
      selector: "manufacturerName",
      key: "manufacturerName",
    },
    {
      name: "Corp Id",
      selector: "corporateGroupId",
      key: "corporateGroupId",
    },
    {
      name: "MFR Number",
      selector: "manufacturerClinicCode",
      key: "manufacturerClinicCode",
    },
    {
      name: "Clinic Name",
      selector: "clinicName",
      key: "ClinicName",
      sortable: true,
    },
    {
      name: "Redemptions",
      selector: "totalNumberOfRedemptions",
      key: "totalNumberOfRedemptions",
      total: true,
      totalClasses: "text-center",
      sortable: true,
      format: row => (<div className="text-center">{row.totalNumberOfRedemptions}</div>)
    },
    {
      name: "Rebate Totals",
      selector: "totalRebateAmount",
      key: "totalRebateAmount",
      type: "currency",
      total: true,
      totalClasses: "text-center",
      sortable: true,
      format: row => (<div className="text-center">${row.totalRebateAmount}</div>)
    },
    {
      name: "Redemption Range",
      selector: "redemptionRange",
      key: "redemptionRange",
    },
    {
      name: "Check Number",
      selector: "checkNumber",
      key: "checkNumber",
    },
  ];

  

  return (
      <SortableDataTable
        striped
        green
        hideSearchBar={false}
        title={props.title}
        downloadExcel={props.canViewProviderPayoutReport ? handleDownloadExcel : undefined}
        columns={COLUMN_NAMES}
        rawData={props.checkData}
        onClick={props.onRowClick}
        showTotals={props.checkData?.length > 1}
      />
  );
}

ProviderPayoutReportTable.propTypes = {
  canViewProviderPayoutReport: PropTypes.bool,
  downloadParameters: PropTypes.object,
  title: PropTypes.string,
  checkData: PropTypes.object,
  onClick: PropTypes.func,
  showTotals: PropTypes.bool,
  isNHO: PropTypes.bool,
};

export default connect(
  (state) => {
    const userProfile = state.user.userProfile;
    const canViewProviderPayoutReport = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_PAYOUT_REPORT, userProfile);
    return {
      canViewProviderPayoutReport,
    };
  },
)(ProviderPayoutReportTable);