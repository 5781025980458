import React, { useEffect, useRef } from "react";
import {connect} from "react-redux";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";

import CorporateGroupProductTagging from "./CorporateGroupProductTagging";
import HeadquartersProductTagging from "./HeadquartersProductTagging";
import SpinnerTakeover from "components/common/SpinnerTakeover";

import {getNodeId} from "utils/AdminData";
import getProviderId from "utils/getProviderId";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import * as UserActions from "actions/UserActions";
import ProviderBase from "components/layout/ProviderBase";
import AccessDenied from "../../../common/AccessDenied";

function ProviderProductTagging(props) {
    const wasLoggedIn = useRef(props.isLoggedIn);

    useEffect(() => {
        const shouldLoadProfile = props.isLoggedIn && !props.userProfile;
        const didReLogin = !props.isLoggedIn && wasLoggedIn;

        if (shouldLoadProfile || didReLogin) {
            props.loadCurrentUser();
            wasLoggedIn.current = true;
        }
    }, [props.isLoggedIn]);

    if(!props.nodeId) {
        return <SpinnerTakeover show/>;
    }

    return (
        <ProviderBase
            match={props.match}
            location={props.location}
            pageTitle={"Product Tagging"}
            providerId={props.providerId}
            nodeId={props.nodeId}
            collapsible={true}
        >
            <div className="flex height-100-perc">
                {props.isCorporateGroup && props.canViewProductTagging ? (
                    <CorporateGroupProductTagging
                        location={props.location}
                        providerId={props.providerId}
                        nodeId={props.nodeId}
                        match={props.match}
                    />
                ) : (
                    <div className={"flex-1 flex flex-centered flex-row height-100-perc"}>
                        <AccessDenied />
                    </div>
                )}
            </div>
            <SpinnerTakeover show={!props.userProfile || !props.node} />
        </ProviderBase>
    );
}

export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const providerId = getProviderId(params, userProfile);
        const nodeId = getNodeId(state, ownProps);
        const node = state.entities.nodes?.[nodeId];
        const corporateGroupProviderIds = flatMap(filter(state.entities.providers, {isCorporateGroup: true}), "id");
        const isCorporateGroup = !!includes(corporateGroupProviderIds, node?.providerId);
        //Permissions
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canViewProductTagging = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_PRODUCT_TAGGING, userProfile);

        return {
            userProfile,
            providerId,
            nodeId,
            node,
            isCorporateGroup,
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && providerId && nodeId),
            territoryId: params.territoryId,
            districtId: params.districtId,
            regionId: params.regionId,
            isLoggedIn: state.user.isLoggedIn,

            // Permissions
            canViewCorporateGroupClinic,
            canViewProductTagging,
        };
    },
    (dispatch) => ({
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
    }),
)(ProviderProductTagging);
