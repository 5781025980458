import React from "react";
import {connect} from "react-redux";
import styles from "./SupportHome.scss";
import { LiveChatWidget } from "components/clinic/elements/LiveChat";
import SupportBase from "components/support/pages/SupportBase";
import {getClinicInfo} from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import Downloads from "components/support/widgets/Downloads";
import Image from "components/common/Image";
import BI_PREMIER_LOGO from "components/common/branding/premier_bi_logo.png";
import * as ProviderIds from "constants/ProviderIds";
import {includes} from "lodash";
import GreenlineSupportPhoneNumber from "components/support/elements/GreenlineSupportPhoneNumber";

function SupportHome(props) {
    const handleGoToPremierPage = () => {
        //TODO: update to bring to the correct premier page
        props.history.push("/premier/1");
    }
    return (
        <SupportBase location={props.location} pageTitle={"Support Home"}>
            <div className={styles.root}>
                {!props.canViewSupport ? (
                    <AccessDenied/>
                ) : (
                    <>
                        <section>
                            <h3>Contact Us</h3>
                            <address className="flex-column">
                                {!!props.clinicInfo && (
                                    <LiveChatWidget
                                        clinic={props.clinicInfo}
                                        buttonText={"Try the Live Chat!"}
                                        clinicHasPrioritySupport={props.clinicHasPrioritySupport}
                                    />
                                )}

                                {!!props.clinicHasBIPrioritySupport ? (
                                    <div className="flex-1 flex align-center margin-bottom-sm link">
                                        {/*Premier goes to option 3 after dialing*/}
                                        <a href="tel:18666089038,3" className="flex align-center text-default">
                                            <i className="fas fa-phone-alt text-dark-green margin-right-x-sm" />
                                        </a>
                                        <GreenlineSupportPhoneNumber clinicId={props.clinicInfo?.id}/>
                                        <div className={styles.premierLogo} onClick={handleGoToPremierPage}>
                                            <Image src={BI_PREMIER_LOGO} alt="Premier by Boehringer Ingelheim logo" title="Clinic has premier access to support"/>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex-1 flex align-center margin-bottom-sm link">
                                        <a href="tel:18666089038" className="text-default"><i className="fas fa-phone-alt text-dark-green" /></a>
                                        <GreenlineSupportPhoneNumber clinicId={props.clinicInfo?.id}/>
                                    </div>
                                )}
                                <div className="flex-1">
                                    <a href="mailto:support@greenlinepet.com" className="text-default"><i className="fas fa-envelope text-dark-green" /> support@greenlinepet.com</a>
                                </div>
                            </address>
                        </section>
                        {props.canViewSupportDownloads && (
                            <>
                                <h3 className="section-title">Downloads</h3>
                                <Downloads/>
                            </>
                        )}
                    </>
                )}
            </div>
        </SupportBase>
    );
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const clinicInfo = getClinicInfo(state);
        //Permissions
        const canViewSupport = userHasPermission(PermissionTypes.VIEW, UserPermissions.SUPPORT, userProfile);
        const canViewSupportDownloads = userHasPermission(PermissionTypes.DOWNLOADS, UserPermissions.SUPPORT, userProfile);
        const clinicHasPrioritySupport = !!clinicInfo?.prioritySupport?.length;
        const clinicHasBIPrioritySupport = !!includes(clinicInfo?.prioritySupport, ProviderIds.BOEHRINGER_INGELHEIM);
        return {
            clinicInfo,
            canViewSupport,
            canViewSupportDownloads,
            clinicHasPrioritySupport,
            clinicHasBIPrioritySupport,
        }
    }
)(SupportHome);
