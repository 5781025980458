import React from "react";
import * as styles from "./PrivacyPolicy.scss";
const PDF = require("components/layout/files/greenline_llc_privacy_policy_01_01_2022.pdf");

export default function PrivacyPolicy(props) {
    return (
        <div
            data-testid="privacy_policy_component"
            className={styles.root}
        >
            <div className="flex justify-flex-end">
                <a href={PDF}
                   className="btn btn-icon btn-color-default btn-full"
                   // Sets the download name and download the file
                   download="greenline_llc_privacy_policy_01_01_2022.pdf"
                >
                    <i className="fa fa-download"/> Download Privacy Policy
                </a>
            </div>
            <h2 className="text-center">Greenline, LLC Privacy Policy</h2>
            <h3 className="text-center">Updated January  1, 2022</h3>

            <p>
                Greenline, LLC ("Greenline" "the Company") recognizes the importance of safeguarding the privacy of clients, customers, and consumers ("Pet Owners") of your Veterinary Clinic ("Clinic", "Users", or "you").
                This includes information submitted by you to permit the processing of Manufacturer Incentive Programs, electronically or otherwise.
                Greenline, through its website <a title="Greeline Pet Website" href="https://greenlinepet.com/" target="_blank">www.greenlinepet.com</a> (the "Site") and software is in the business of providing electronic solutions to expedite Manufacturers' Incentive Program(s), coupon(s), and/or rebate(s) process(es) so as to assist Users in completing the Manufacturer processes in an easier, faster, and more efficient manner for the benefit of the User(s) and the Pet Owners they serve.
            </p>
            <p>This Privacy Policy ("Policy") describes User's rights regarding Greenline's collection, use, storage, sharing, and protection of the Pet Owner's information that you have provided ("Pet Owner's Information").</p>

            <ol type="a">
                <li>Please read this Policy in its entirety to understand how Greenline will handle the privacy of Pet Owner Information that you provide through the Site with respect to your use of the Site and shall include, without limitation, all application, tools, features, services, and information on or available through the Site (all applications, features, tools, services, and information hereinafter also referred to collectively as the "Services").</li>
                <li>This Privacy Policy shall be applicable to any person or entity (the "Users") that directly or indirectly uses or accesses the Site or the Services.</li>
                <li>For purposes of this Privacy Policy, any Manufacturer or other contractually related entity are considered related third parties.</li>
            </ol>

            <ol className="inside">
                <li><b>Consent</b> &mdash; By using the Site and accessing the Services, you consent to Greenline's collection, use and sharing of the Pet Owner Information in accordance with this Policy and the Greenline Terms and Conditions, found on the Site and incorporated hereto by reference.  Greenline may use and disclose the information that you provide through the Site for purposes described in this Policy, for purposes which you may otherwise authorize, and for purposes allowed under applicable law.  If you do not wish Greenline to use or disclose information that you provide through the Site as described in this Policy, you should disconnect from, and discontinue use of, the Site.</li>
                <li><b>Collection and Use of Information</b> &mdash; Users will subscribe to Services to assist in the coupon/rebate/Incentive Program process(es) as well as, in some cases, other software services, mobile services, and / or on-screen prompting services which are reminders for pet-care product renewal or repurchase.  Greenline digitizes the Incentive Program process(es) for certain Manufacturers.  Should a Clinic choose to participate in a Manufacturer's Incentive Program(s), the Clinic will provide, and Greenline will utilize, certain data to process the Incentive Program, populate related reports, execute incentives, and communicate with Pet Owners. You acknowledge and agree that such data may be provided to the Manufacturer and other Greenline related parties for Pet Owner transaction(s) and for participation in any Manufacturer's Incentive Program.  This information typically, but not exclusively, includes Clinic name, Manufacturer Clinic identifier, Clinic address and phone, Pet Owner name, address, and email, pet name, product or brand name, product size, pack size, quantity, Incentive Program code, price, and/or proof of purchase (copy of the invoice). Throughout this Policy, we use the term "Personal Information" and/or "Proof of Purchase Data" to describe information that can be associated with a specific Pet Owner's purchase(s).  Greenline may share such information with Manufacturers provided all such Pet Owner Information is in an aggregated format and shall not contain Personal Information, outside of the Manufacturer's Rules related to Proof of Purchase Data.  Notwithstanding this paragraph, Greenline shall be permitted to share Pet Owner Information on an individual basis as necessary to participate in a Manufacturer's Incentive Program and as requested by Clinic.</li>
                <li><b>Information Related to Purchases</b> &mdash; User provides Greenline with information necessary to the provision of the Site and accessing its services ("Services").  The Proof of Purchase Data described above is used to execute the Manufacturer's Incentive Program (s), coupon, and/or rebate between Pet Owners and the Manufacturer(s) or other related parties, and for Greenline to communicate with User and Pet Owners on behalf of User.  Except as required by law, Greenline does NOT share any Personal Information with any unrelated third parties without User's consent.</li>
                <li><b>Veterinary User Portal Settings and Content</b> &mdash; User(s) will specify various preferences and settings in their Greenline Portal including usernames, passwords, and preferences related to communications delivered through or managed in the Greenline Portal.  This information is used to allow access to and use of the Services, as well as to allow customization of settings and content of the Services to suit User(s) needs.  Greenline may use the information you provide to us to communicate with you regarding the Site and Services.</li>
                <li><b>Other Information Provided to Greenline</b> &mdash; During the course of normal business or when questions or concerns may arise, you may provide other information that is necessary to or helpful in the provision and/or support of the Services.  You may provide such information to us through the Services themselves or through other means including but not limited to phone and email support.  This information may be used when providing customer support or with regard to information about other products and services or other pertinent information.  All information collected and retained by Greenline is protected and secured according to industry standards.</li>
                <li><b>Credit Card Processing</b> &mdash; You may need to pay for your use of the Services using a credit card.  Greenline uses third-party, payment card processing companies engaged by us to process payments in connection with the Sites and Services.  These third parties have committed to Greenline to process payment card information in a secure, PCI compliant manner.  Your credit card information is processed and maybe retained by these third parties; we may, in limited circumstances, store your payment card information, in which case we will do so in a PCI compliant manner.</li>
                <li><b>Retention and Modification of Information</b> &mdash; For statistical purposes, we retain Pet Owner Information for as long as the information is relevant to our business purposes, and we will retain such information until you request that we remove same by contacting us via email or USPS at Greenline, LLC, 1350 Concourse Ave., Suite 469, Memphis, TN, 38104.</li>
                <li><b>Links to other Websites</b> &mdash; The Site may contain links to other sites.  Greenline is not responsible for the privacy practice of such other sites, and therefore Greenline disclaims any liability for any third party's use of your Personal Information.  We encourage our visitors to be aware when they leave our Site to read the privacy statements of each and every site that collects personally identifiable information.  This Policy applies to information collected by the Site.  The Site uses Google Analytics, a web analysis service provided by Google Inc.  ("Google").  By using this Site you agree to the terms and conditions and privacy policy of Google Analytics, outlined at the following websites: <a title="Google Analytics Terms" href="https://marketingplatform.google.com/about/analytics/terms/us/" target="_blank">https://marketingplatform.google.com/about/analytics/terms/us/</a> and <a title="Google Privacy Policy" target="_blank" href="https://policies.google.com/privacy" >https://policies.google.com/privacy</a>.</li>
                <li><b>E-mail Communications</b> &mdash; When you send an email to us, you are communicating with Greenline electronically and consent to receive communications from Greenline electronically.  Greenline will communicate with you by email or by posting on the Site.  All agreements, notices, disclosures, and other communications that Greenline provides to you electronically satisfy any legal requirement that such communications be in writing.</li>
                <li><b>Do Not Track Disclosure</b> &mdash; Greenline stores clinic user information during the session for which you are logged in, for authentication purposes only.  The use of the Services does not allow for or involve in any way or nature the use of tracking by any third parties.  There is no log in procedure or use of the Services by Pet Owners so as to allow for tracking of Pet Owner Information, location, preferences, or any other information or data collected or transmitted by tracking devices.</li>
                <li><b>Disclosure of Information</b> &mdash; As a matter of principle and policy, Greenline does not sell, rent, or disclose Personal Information to unrelated third parties.  However, you understand and consent to the disclosure of Personal Information as described herein.</li>
                <li><b>Legal Requests</b> &mdash; Greenline may be obligated to disclose User and/or Pet Owner Information if ordered to do so by a court of law or by a duly empowered governmental agency.</li>
                <li><b>Greenline Service Providers</b> &mdash; Greenline may use consultants, contractors, or other service providers to assist in our business activities.  Such service providers shall be considered related parties and we may disclose the User and Pet Owner Information to such service providers as deemed in our judgment to be helpful.  Such service providers are generally subject to confidentiality agreements and other restrictive legal covenants as conditions of their association with Greenline that prohibit the use of your information by them for purposes other than what is intended.</li>
                <li><b>Business Transaction</b> &mdash; Should Greenline become involved in a transaction including, but not limited to, a merger, acquisition, combination or sale of any or all of its assets, the User and Pet Owner Information may be transferred, and in that case, Greenline shall require the surviving or successor company to either abide by this policy with respect to your Pet Owner Information or provide sufficient notice to you if your Pet Owner Information will be used contrary to this policy following the transaction.</li>
                <li><b>Other</b> &mdash; We may also disclose the User and Pet Owner Information if we believe it is necessary to improve the User experience, provide high-quality service, protect our rights, enforce the Terms and Conditions to which you have agreed, or resolve disputes.</li>
                <li><b>Personal Information Security</b> &mdash; Your information is stored in databases on servers located within the United States.  Greenline uses best efforts to protect your information from loss, theft, or unauthorized access.  Greenline uses encryption for data transfers across the internet; nevertheless, you acknowledge and understand that perfect security does not exist on the internet.</li>
                <li><b>Retention of Personal Information</b> &mdash; Greenline retains Pet Owner Proof of Purchase Data as long as is necessary and relevant for the operation of our Site and for delivery of Services.  In addition, Greenline may retain personal proof of purchase information from closed accounts to comply with the law, prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigation, enforce our Terms and Conditions and/or take other actions permitted by law.  Greenline is not a practice management system backup services provider, and Greenline should not be relied upon for backup services in the event that you experience a system failure or loss of data in your practice.</li>
                <li><b>California Privacy Rights</b> &mdash; Greenline abides by the California Online Privacy Protection Act and California Civil Code Section 1798.83.  These laws allow Users who have provided Pet Owner personal information to Greenline to request information about our disclosures of personal information to certain types of third parties.  To request any information regarding Greenline's compliance, please contact us at:
                    <p className={styles.addressBlock}>
                        Greenline, LLC.<br />
                        1350 Concourse Ave., Suite 469<br />
                        Memphis, TN 38104
                    </p>
                </li>
                <li><b>Disclaimer</b> &mdash; Despite the measures we take to protect your information, Greenline does not warrant that the User and Pet Owner Information will always remain private.  We may be legally obligated to disclose the information; the information may also be intercepted unlawfully by third parties and may be abused or misused.</li>
                <li><b>Notice</b> &mdash; We may amend this Privacy Policy at any time, and such amendments will be effective immediately upon being posted on our Site. Your continued use of the Site or the Services following the posting of a revised Privacy Policy means that you accept and agree to the changes.</li>
                <li><b>Questions</b> &mdash; If you have any questions or concerns about this Privacy Policy or have specific questions as to what data is being stored, please contact us at:
                    <p className={styles.addressBlock}>
                        Greenline, LLC<br />
                        1350 Concourse Ave., Suite 469<br />
                        Memphis, TN 38104
                    </p>
                </li>
            </ol>

            <p className={styles.footerText}>&copy; 2022 GREENLINE. ALL RIGHTS RESERVED</p>
        </div>
    )
}

PrivacyPolicy.propTypes = {}
