import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import styles from './PaymentsHeader.scss';
import find from "lodash/find";
import map from "lodash/map";
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import Button from 'components/common/Button';
import CardForm from "components/user/widgets/CardForm";
import Dropdown from 'components/common/Dropdown';
import {handleErrorResponse} from "utils/request";
import toast from "utils/toast";
import * as WellnessApi from "api/WellnessApi"

export default function PaymentsHeader(props) {
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [btnDisabled, disableBtn] = useState(false);
    const [name, setName] = useState('');
    const [options, setOptions] = useState([]);
    const [defaultCard, setDefaultCard] = useState('');
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        setOptions(map(props.cards, (card) => {
            if (card.id !== defaultCard && card.isDefault) {
                setDefaultCard(card.id);
            }

            return {
                name: `${card.brand.toUpperCase()} ${card.last4}`,
                value: card.id
            }
        }));

        if (props.showAddWhenNoCards) {

            if (!props.cards?.length) {
                setIsOpen(true);
            } else {
                setIsOpen(false);
            }
        }
    }, [props.cards]);

    const createPaymentOption = (tokenId) => {
        props.setIsLoading(true);
        WellnessApi.createWellnessPaymentOption(props.clinicId, tokenId, props.userId)
        .then((results) => {
            if(results.body) {
                updateDefaultCard(results.body.id)
            }
            else {
                props.paymentMethodsChanged()
            }
            
            toast.success("Card has been added.", results.body);
        })
        .catch((error) => {
            handleErrorResponse("creating payment method", error);
        })
        .finally(() => {
            props.setIsLoading(false);
        });
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        disableBtn(true);
        props.setIsLoading(true);

        const card = elements.getElement(CardElement);
        const stripeModel = { name: name }
        const result = await stripe.createToken(card, stripeModel);

        if (result.error) {
            setError(result.error.message);
        } else {
            setError(null);
            setIsOpen(false);
            try {
                if (props.onCardCreated) {
                    props.onCardCreated(result.token);
                } else {
                    await createPaymentOption(result.token.id);
                }
            } catch (error) {
                handleErrorResponse("adding payment method", error);
            }
        }

        props.setIsLoading(false);
        disableBtn(false);
    }

    const handleError = (event) => {
        if (event.error) {
            setError(event.error.message);
        } else {
            setError(null);
        }
    }

    const updateDefaultCard = (cardId) => {
        props.setIsLoading(true);
        WellnessApi.updateDefaultCard(cardId, props.clinicId, props.userId)
        .then((_) => {
            setDefaultCard(cardId);
            props.onDefaultCardUpdated(cardId);
            toast.success("Default card updated.");
        })
        .catch((error) => {
            handleErrorResponse("setting default card", error);
        })
        .finally(() => {
            props.setIsLoading(false);
        });
    }

    const handleOnChange = async (e) => {
        props.setIsLoading(true);

        try {
            updateDefaultCard(e.value)
        } catch(error) {
            handleErrorResponse("setting default card", error);
        }

        props.setIsLoading(false);
    }

    const handleUseDefaultCard = () => {
        const card = find(props.cards, {id: defaultCard});
        props.onConfirmSelection(card);
    };

    return (
        <>
            <div className={styles.rootContainer}>
                {/*<h2>Payment Options</h2>*/}
                <div className={styles.optionsContainer}>

                    <div className={styles.defaultCard}>
                        <span>Default Card:</span>
                        <div className={styles.defaultSelect}>
                            <Dropdown
                                fullWidth
                                options={options}
                                name="defaultPayment"
                                value={defaultCard}
                                onChange={handleOnChange}
                                disabled={!props.canEditPaymentSettings || !options?.length}
                            />
                        </div>
                    </div>
                    <div className={styles.addMore}>
                        <Button
                            icon
                            onClick={() => setIsOpen(true)}
                            disabled={!props.canEditPaymentSettings || isOpen}
                        >
                            <i className="fas fa-plus" /> Add a Payment Option
                        </Button>
                    </div>
                </div>
            </div>
            {isOpen && (
                <div className={styles.addCardContainer}>
                    <div className="text-center">Add a Payment Option</div>
                    <CardForm
                        isOpen={isOpen}
                        name={name}
                        handleFormChange={({name, value}) => {setName(value)}}
                        handleSubmit={handleSubmit}
                        stripeError={error}
                        setStripeError={handleError}
                        btnDisabled={btnDisabled || !props.canEditPaymentSettings}
                        addCardBtnText={props.addCardBtnText}
                        onCancel={() => setIsOpen(false)}
                    />
                </div>
            )}
            {(props.showConfirmSelection && !isOpen) && (
                <div className="flex justify-flex-end">
                    <Button
                        onClick={handleUseDefaultCard}
                        disabled={!(defaultCard && options?.length)}
                    >
                        {props.confirmSelectionBtnText || "Use Default Card"}
                    </Button>
                </div>
            )}
        </>
    )
}

PaymentsHeader.defaultProps = {
    showAddWhenNoCards: false,
    showConfirmSelection: false,
    addCardBtnText: "Submit Card",
    confirmSelectionBtnText: "Use Default Card",
    onConfirmSelection: () => {},
}

PaymentsHeader.propTypes = {
    clinicId: PropTypes.number.isRequired,
    userId: PropTypes.string.isRequired,
    cards: PropTypes.array.isRequired,
    setIsLoading: PropTypes.func,
    paymentMethodsChanged: PropTypes.func.isRequired,
    onDefaultCardUpdated: PropTypes.func,
    canEditPaymentSettings: PropTypes.bool,
    onCardCreated: PropTypes.func,
    showAddWhenNoCards: PropTypes.bool,
    showConfirmSelection: PropTypes.bool,
    addCardBtnText: PropTypes.string,
    confirmSelectionBtnText: PropTypes.string,
    onConfirmSelection: PropTypes.func,
};
