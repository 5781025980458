import React from "react";
import PropTypes from "prop-types";
import styles from "./GameCompletionDetailsOverlay.scss";
import classnames from "classnames";

export default function GameCompletionDetailsOverlay(props) {

    return (
        <div
            className={classnames(styles.root, {[styles.wide]: props.wide}, {[styles.clickable]: !!props.onClick})}
            onClick={props.onClick}
        >
            <div className={styles.containerOuter}>
                <div className={classnames(styles.containerInner, "padding-default")}>
                    <div className={styles.content}>
                        {/* Note: For content passed in you want to be green, wrap in an <em> tag */}
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    );
}

GameCompletionDetailsOverlay.propTypes = {
    wide: PropTypes.bool,
    onClick: PropTypes.func,
};
