import React from "react";
import styles from "./AdminWellnessDvmCommission.scss";
import {connect} from "react-redux";
import AccessDenied from "components/common/AccessDenied";
import AdminBase from "components/layout/AdminBase";
import DvmCommissionTable from "components/wellness/widgets/DvmCommissionTable";
import {clinicHasWellness} from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import { DVM_COMMISSIONS } from "constants/AdminControls";
import { DVM_COMMISSION_SEARCH } from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

class AdminWellnessDvmCommission extends React.Component {
    render() {
        return (
            <AdminBase
                clinicId={this.props.match.params.clinicId}
                pageTitle="DVM Commission Setup"
                searchConfig={DVM_COMMISSION_SEARCH}
                controls={DVM_COMMISSIONS}
            >
                <div className={styles.root}>
                    {!(this.props.clinicHasWellness && this.props.canViewWellnessDvms) ? (
                        <AccessDenied/>
                    ) : (
                        <DvmCommissionTable clinicId={this.props.clinicId} />
                    )}
                </div>
            </AdminBase>
        )
    }
}

export default connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const userProfile = state.user.userProfile;
        const canViewWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        return {
            clinicId,
            canViewWellnessDvms,
            clinicHasWellness: clinicHasWellness(state, clinicId),
        }
    }
)(AdminWellnessDvmCommission);
