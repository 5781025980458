import React, { useState } from "react";
import { connect } from "react-redux";
import {map, merge, reject, keyBy, filter, includes, flatMap, cloneDeep, orderBy} from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import styles from "./DvmCommissionTable.scss";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import DateInput from "components/common/DateInput";
import TextBox from "components/common/TextBox";
import * as ClinicActions from "actions/ClinicActions";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function DvmHistoryForm(props) {
    const NEW_COMMISSION = Object.freeze({
        clinicDvmId: props.clinicDvmId,
        isActive: true,
        productsCommissionPercentage: 0,
        servicesCommissionPercentage: 0,
        isDeleted: false,
        isNew: true,
    });


    const [formData, setFormData] = useState({});
    const combinedData = cloneDeep(props.histories);

    merge(combinedData, formData);


    const handleChange = ({name, value}, index) => {
        const newFormData = {
            ...formData,
            [index]: {
                ...formData[index],
                [name]: value,
            }
        }
        setFormData(newFormData);
    }

    const handleSubmit = () => {
        if(props.canEditClinicWellnessDvms) {
            const newData = map(combinedData, d => {
                return {
                    ...d,
                    clinicDvmHistoryId: d.isNew ? null : d.clinicDvmHistoryId
                }
            });

            props.batchUpdateDvmCommissionHistory(props.clinicDvmId, newData);
            props.onClose();
        }
    }

    const handleCancel = () => {
        setFormData({});
        props.onClose();
    }

    const handleAddNewHistory = () => {
        if(props.canCreateClinicWellnessDvms) {
            let nextId = 0;

            const highestDvmHistoryId = orderBy(combinedData, ["clinicDvmHistoryId"], ["desc"])[0].clinicDvmHistoryId;
            if (highestDvmHistoryId) {
                nextId = highestDvmHistoryId + 1;
            }

            const newFormData = {
                ...formData,
                [nextId]: {
                    ...NEW_COMMISSION,
                    clinicDvmHistoryId: nextId,
                }
            }
            setFormData(newFormData);
        }
    }

    const handleDelete = (clinicDvmHistoryId) => {
        if(props.canDeleteClinicWellnessDvms) {
            let newFormData = {...formData};
            if (combinedData[clinicDvmHistoryId].isNew) {
                // Remove from formData if isNew
                delete newFormData[clinicDvmHistoryId];
            } else {
                // Need to actually delete onSubmit
                newFormData = {
                    ...newFormData,
                    [clinicDvmHistoryId]: {
                        ...newFormData[clinicDvmHistoryId],
                        "isDeleted": true,
                        "isActive": false,
                    }
                }

            }
            setFormData(newFormData);
        }
    }


    if(!props.clinicDvmId) {
        return (
            <div>
                <h4>No DVM Selected</h4>
            </div>
        );
    }

    const checkValidDates = (toCheck) => {
        const checkingStartDate = toCheck.startDate ? moment(toCheck.startDate) : null;
        const checkingEndDate = toCheck.endDate ? moment(toCheck.endDate) : null;
        if (!Object.keys(combinedData).length || Object.keys(combinedData).length === 0) return false;

        // No timeframes can overlap
        if(!checkingStartDate && !checkingEndDate) return true;  // nothing set - error
        if (!!checkingStartDate && !!checkingEndDate) { // startDate and endDate exist
            if (checkingStartDate > checkingEndDate) return true; // startDate is after endDate - error
        }

        // Loop through and check them
        const matches = filter(combinedData, item => {
            if (item.isDeleted) return false;
            const iStartDate = item.startDate ? moment(item.startDate) : null;
            const iEndDate = item.endDate ? moment(item.endDate) : null;

            if(item.clinicDvmHistoryId === toCheck.clinicDvmHistoryId) return false;
            if (!iStartDate && !iEndDate) return false;

            // If it's true for one... it's true for both
            if (checkingStartDate && checkingStartDate.isBetween(iStartDate, iEndDate)) return true;
            if (checkingEndDate && checkingEndDate.isBetween(iStartDate, iEndDate)) return true;
        });

        return !!matches.length;

         // no errors
    }

    const checkDates = () => {
        const notDeleted = reject(combinedData, {isDeleted: true});
        let rows = [];
        if (notDeleted.length) {
            rows = filter(notDeleted, item => {
                return checkValidDates(item)
            });
        }
        return flatMap(rows, item => {return item.clinicDvmHistoryId});
    }

    const historyErrors = checkDates();
    const filteredData = reject(combinedData, {isDeleted: true});
    return (
        <div className="flex flex-column">
            <div className={styles.historyTable}>
                {props.canCreateClinicWellnessDvms &&
                    <div className="flex justify-flex-end margin-top-x-sm margin-bottom-sm">
                        <Button small onClick={handleAddNewHistory} icon ><i className="fas fa-plus" /> Add New</Button>
                    </div>
                }
                <table className="table margin-bottom-md">
                    <thead>
                    <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Products Commission %</th>
                        <th>Services Commission %</th>
                        <th>Is Active</th>
                        <th/>
                    </tr>
                    </thead>
                    <tbody>
                    {map(filteredData, (item, index) => {
                        const lastSchedule = filteredData[index - 1] || {};
                        const nextSchedule = filteredData[index + 1] || {};
                        return (
                            <tr key={item.clinicDvmHistoryId}>
                                <td>
                                    <DateInput
                                        name="startDate"
                                        value={item.startDate ? moment(item.startDate).format('MM/DD/YYYY') : null}
                                        dateFormat={"MM/DD/YYYY"}
                                        selectsStart
                                        endDate={item.endDate}
                                        hasError={includes(historyErrors, item.clinicDvmHistoryId)}
                                        minDate={!!lastSchedule.endDate ? moment(lastSchedule.endDate).add(1, "day").format("MM/DD/YYYY") : null}
                                        maxDate={!!item.endDate ? moment(item.endDate).subtract(1, "day").format("MM/DD/YYYY") : null}
                                        onChange={(data) => handleChange(data, item.clinicDvmHistoryId)}
                                        disabled={!props.canEditClinicWellnessDvms}
                                    />
                                </td>
                                <td>
                                    <DateInput
                                        name="endDate"
                                        value={item.endDate ? moment(item.endDate).format('MM/DD/YYYY') : null}
                                        dateFormat={"MM/DD/YYYY"}
                                        selectsEnd
                                        startDate={item.startDate}
                                        hasError={includes(historyErrors, item.clinicDvmHistoryId)}
                                        minDate={!!item.startDate ? moment(item.startDate).add(1, "day").format("MM/DD/YYYY") : null}
                                        maxDate={!!nextSchedule.startDate ? moment(nextSchedule.startDate).subtract(1, "day").format("MM/DD/YYYY") : null}
                                        onChange={(data) => handleChange(data, item.clinicDvmHistoryId)}
                                        disabled={!props.canEditClinicWellnessDvms}
                                    />
                                </td>
                                <td>
                                    <TextBox
                                        name="productsCommissionPercentage"
                                        value={item.productsCommissionPercentage}
                                        inputType={"number"}
                                        onChange={(data) => handleChange(data, item.clinicDvmHistoryId)}
                                        disabled={!props.canEditClinicWellnessDvms}
                                    />
                                </td>
                                <td>
                                    <TextBox
                                        name="servicesCommissionPercentage"
                                        value={item.servicesCommissionPercentage}
                                        inputType={"number"}
                                        onChange={(data) => handleChange(data, item.clinicDvmHistoryId)}
                                        disabled={!props.canEditClinicWellnessDvms}
                                    />
                                </td>
                                <td>
                                    <CheckboxInput
                                        name="isActive"
                                        onChange={(data) => handleChange(data, item.clinicDvmHistoryId)}
                                        checked={item.isActive}
                                        disabled={!props.canEditClinicWellnessDvms}
                                    />
                                </td>
                                {props.canDeleteClinicWellnessDvms &&
                                    <td>
                                        <Button
                                            onClick={() => handleDelete(item.clinicDvmHistoryId)}
                                            small
                                            iconOnly
                                        >
                                            <i className="fas fa-trash-alt" />
                                        </Button>
                                    </td>
                                }
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>

            <div className="flex-none text-right">
                {props.canEditClinicWellnessDvms ? (
                    <>
                        <Button
                            wide
                            className="margin-right-x-sm"
                            type="primary"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            wide
                            onClick={handleSubmit}
                            disabled={!Object.keys(formData).length || !!historyErrors.length}
                        >
                            Save
                        </Button>
                    </>
                ) : (
                     <Button wide className="margin-right-x-sm" type="primary" onClick={handleCancel}>Done</Button>
                )}
            </div>
        </div>
    );
}

DvmHistoryForm.propTypes = {
    clinicDvmId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canEditClinicWellnessDvms = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canCreateClinicWellnessDvms = userHasPermission(PermissionTypes.CREATE, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canDeleteClinicWellnessDvms = userHasPermission(PermissionTypes.DELETE, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const clinicWellness = state.entities.wellnessCommissions[ownProps.clinicDvmId] || {}
        return {
            canEditClinicWellnessDvms,
            canCreateClinicWellnessDvms,
            canDeleteClinicWellnessDvms,
            histories: clinicWellness ? keyBy(clinicWellness.history, "clinicDvmHistoryId") : {},
        };
    },
    (dispatch) => ({
        // History id comes from the DVM commission data (clinicDvmHistoryId)
        batchUpdateDvmCommissionHistory: (clinicDvmId, data) => dispatch(ClinicActions.batchUpdateCommissionHistory(clinicDvmId, data))
    })
)(DvmHistoryForm);
