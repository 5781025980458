import orderBy from "lodash/orderBy";
import reject from "lodash/reject";

export default class LocalData {
    static setToken(token) {
        localStorage.setItem("token", token);
    }

    static getToken() {
        return localStorage.getItem("token");
    }

    static clearToken() {
        localStorage.removeItem("token");
    }

    static setRememberUsername(userName) {
        localStorage.setItem("rememberUser", userName);
    }

    static getRememberUsername() {
        return localStorage.getItem("rememberUser");
    }

    static clearRememberUsername() {
        localStorage.removeItem("rememberUser");
    }

    //Recent Clinics
    static getRecentClinics() {
        // An array of recent clinics, max 10
        const recents = localStorage.getItem("recentClinics");
        if(!recents) {
            return {};
        }

        return JSON.parse(recents);
    }

    static addRecentClinic(clinic) {
        const currentRecents = JSON.parse(localStorage.getItem("recentClinics")) || {};
        if(Object.keys(currentRecents)?.length >= 25 && !currentRecents[clinic.id]) {
            //Deletes the oldest Impersonation if there are 10 or more.
            const oldestClinic = orderBy(currentRecents, ["timeStamp"], ["asc"])[0];
            delete currentRecents[oldestClinic.id]
        } else if (currentRecents[clinic.id]) {
            //Deletes old version of new impersonation to make sure the new impersonation is at the top of the list and correct
            delete currentRecents[clinic.id];
        }
        const recents = {
            [clinic.id]: clinic,
            ...currentRecents
        };

        localStorage.setItem("recentClinics", JSON.stringify(recents));
    }

    static clearRecentClinic(clinicId) {
        // remove one from the list
        const recents = JSON.parse(localStorage.getItem("recentClinics"));
        delete recents[clinicId];
        localStorage.setItem("recentClinics", JSON.stringify(recents));
    }

    static clearAllRecentClinics() {
        localStorage.removeItem("recentClinics");
    }

    //Recent Users
    static getRecentUsers() {
        // An array of recent users, max 10
        const recents = localStorage.getItem("recentUsers");
        if(!recents) {
            return {};
        }

        return JSON.parse(recents);
    }

    static addRecentUser(user) {
        const recentUsers = JSON.parse(localStorage.getItem("recentUsers")) || {};
        const newRecentUsers = {
            ...recentUsers,
            [user.id]: user,
        };

        if (Object.keys(newRecentUsers)?.length > 10) {
            //Deletes the oldest Impersonation if there are 10 or more.
            const oldestImpersonation = orderBy(newRecentUsers, ["timeStamp"], ["asc"])[0];
            delete newRecentUsers[oldestImpersonation.id]
        }

        localStorage.setItem("recentUsers", JSON.stringify(newRecentUsers));
    }

    static clearRecentUser(userId) {
        // remove one from the list
        const recents = JSON.parse(localStorage.getItem("recentUsers"));
        delete recents[userId];
        localStorage.setItem("recentUsers", JSON.stringify(recents));
    }

    static clearAllRecentUsers() {
        localStorage.removeItem("recentUsers");
    }

    static isAuthRequestInProgress() {
        const now = window.performance.now();
        const startTime = localStorage.getItem("authRequestStartTime");
        return startTime > 0 && Math.abs(now - startTime) < 30; // Disallow more than one auth/user request within 30 seconds to avoid overpopupulation.
    }

    static startAuthRequest() {
        if (!this.isAuthRequestInProgress()) {
            localStorage.setItem("authRequestStartTime", window.performance.now());
        }
    }

    static finishAuthRequest() {
        localStorage.setItem("authRequestStartTime", 0);
    }

    static getClinicsTableIds() {
        return JSON.parse(localStorage.getItem("clinicsTableIds"));
    }

    static setClinicsTableIds(ids) {
        localStorage.setItem("clinicsTableIds", JSON.stringify(ids));
    }

    static clearClinicsTableIds() {
        localStorage.setItem("clinicsTableIds", "null");
    }

    //Favorite Brands (for Price watch)
    static getFavoriteBrands() {
        // An array of favorite products (used in the Price Watch table)
        const favorites = localStorage.getItem("favoriteBrands");
        if(!favorites) {
            return [];
        }

        return JSON.parse(favorites);
    }

    static addFavoriteBrand(brandId) {
        // Add productId to the list of favorite Brands
        const favorites = JSON.parse(localStorage.getItem("favoriteBrands")) || [];
        favorites.push(brandId)

        localStorage.setItem("favoriteBrands", JSON.stringify(favorites));
    }

    static clearFavoriteBrand(brandId) {
        // remove one from the list
        const favorites = JSON.parse(localStorage.getItem("favoriteBrands"));
        const newFavorites = reject(favorites, f => f === brandId);
        localStorage.setItem("favoriteBrands", JSON.stringify(newFavorites));
    }

    static clearAllFavoriteBrands() {
        localStorage.removeItem("favoriteBrands");
    }
}
