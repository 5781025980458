import React, {useState} from "react";
import PropTypes from "prop-types";
import Button from "components/common/Button";
import TextBox from "components/common/TextBox";

export default function IncreasePriceForm(props) {
    const [increasePercentage, setIncreasePercentage] = useState(null);

    const handleCancel = () => {
        setIncreasePercentage(null);
        props.onCancel();
    }

    const handleSubmit = () => {
        setIncreasePercentage(null);
        props.onSubmit(increasePercentage);
    }

    return (
        <div data-testid="increase_price_form_component">
            <div className="flex spaced-content flex-centered">
                <TextBox
                    data-testid="increase_price_form_textBox"
                    name="increasePercentage"
                    value={increasePercentage}
                    placeholder="Enter price increase % (i.e. - 5)"
                    inputType="number"
                    onChange={({value}) => setIncreasePercentage(value)}
                />
                <div className="margin-bottom-x-sm">%</div>
            </div>

            <div className="flex spaced-content justify-flex-end">
                <Button
                    data-testid="increase_price_form_cancel_button"
                    type="gray"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button
                    data-testid="increase_price_form_submit_button"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
            </div>
        </div>
    )
}

IncreasePriceForm.propTypes = {
    onCancel:PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
};
