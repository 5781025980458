import React from "react";
import {autobind} from "core-decorators";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./CouponOptionMenu.scss";
import Button from "components/common/Button";
import logger from "utils/logger";
import * as CssStyleIds from "constants/CssStyleIds";

class CouponOptionMenu extends React.Component {

    state = {
        open: false,
    }

    @autobind
    toggleMenu(e) {
        if(!this.state.open) {
            logger.log("ADD WINDOW LISTENER");
            window.addEventListener("click", this.toggleMenu);
        } else {
            logger.log("REMOVE WINDOW LISTENER");
            window.removeEventListener("click", this.toggleMenu);
        }
        e.stopPropagation();
        this.setState({open: !this.state.open});
    }

    componentWillUnmount() {
        window.removeEventListener("click", this.toggleMenu);
    }

    render() {
        const hasOptions = this.props.canApprove || this.props.canDecline || this.props.canEdit;
        const noOp = () => {};

        if (!hasOptions) {
            return null;
        }

        return (
            <div className={classnames(styles.root,{
                [styles.menuVisible]: this.state.open,
            })}>
                <span
                    className={styles.icon}
                    onClick={this.toggleMenu}
                >
                    <i className="fal fa-ellipsis-v fa-2x" />
                </span>
                <div className={styles.flyoutContainer}>
                    <div className={styles.buttons}>
                        {this.props.canEdit && (
                            <Button onClick={this.props.onEditClick || noOp}>
                                Edit
                            </Button>
                        )}
                        {/*<Button onClick={this.props.onPdfClick || noOp}>*/}
                        {/*    Download PDF*/}
                        {/*</Button>*/}
                        {/*<Button onClick={this.props.onShareClick || noOp}>*/}
                        {/*    Share*/}
                        {/*</Button>*/}
                        {this.props.canApprove && (
                            <Button
                                type={CssStyleIds.SUCCESS}
                                onClick={this.props.onApproveClick || noOp}
                            >
                                Approve
                            </Button>
                        )}
                        {this.props.canDecline && (
                            <Button
                                type={CssStyleIds.DANGER}
                                onClick={this.props.onDeclineClick || noOp}
                            >
                                Decline
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

CouponOptionMenu.propTypes = {
    onEditClick: PropTypes.func,
    onPdfClick: PropTypes.func,
    onShareClick: PropTypes.func,
    onApproveClick: PropTypes.func,
    onDeclineClick: PropTypes.func,
    canEdit: PropTypes.bool,
    canDecline: PropTypes.bool,
    canApprove: PropTypes.bool,
};

export default CouponOptionMenu;
