import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import AdminBase from "components/layout/AdminBase";
import InvoiceLineItems from "components/admin/widgets/InvoiceLineItems";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { INVOICE_LINE_ITEMS } from "constants/AdminControls";
import * as SearchTypes from "constants/SearchTypes";
import * as UserPermissions from "constants/UserPermissions";

function AdminInvoiceLineItemsPage(props) {

    return (
        <AdminBase
            pageTitle="Clinic Invoice Line Items"
            searchConfig={SearchTypes.INVOICE_LINE_ITEM}
            controls={INVOICE_LINE_ITEMS}
            clinicId={props.clinicId}
            collapsible
        >
            <InvoiceLineItems
                clinicId={props.clinicId}
                isAdmin
            />
        </AdminBase>
    );
}

const connector = connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            clinicId,
            canViewClinicManagement
        }
    }
);

export default compose(
    withRouter,
    connector
)(AdminInvoiceLineItemsPage);
