exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ProgramTile__tile{max-width:216px;cursor:pointer}.ProgramTile__tile.ProgramTile__disabled{cursor:not-allowed}.ProgramTile__tile img{width:100%}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/programs/ProgramTile.scss"],"names":[],"mappings":"AAAA,mBAAM,gBAAgB,cAAc,CAAC,yCAAe,kBAAkB,CAAC,uBAAU,UAAU,CAAC","file":"ProgramTile.scss","sourcesContent":[".tile{max-width:216px;cursor:pointer}.tile.disabled{cursor:not-allowed}.tile img{width:100%}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"tile": "ProgramTile__tile",
	"disabled": "ProgramTile__disabled"
};