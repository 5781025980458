import React from "react";
import {
	HELMET_STRIPE,
	HELMET_HOLE,
	HELMET_BG,
	JERSEY_STRIPE,
	JERSEY_SLEEVE,
	JERSEY_BG,
	TEAM_COLORS
} from "constants/Games/PetsBowl/UniformColors";
import styles from "./KittyStretching.scss";

export default function KittyStretching(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		<svg
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 848.17 319.74"
		>
		    <g className={styles.cls2}>
		        <g className={styles.cls7}>
		            <g>
		                <g className={styles.cls4}>
		                    <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_1_0_Layer2_0_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  1 0 Layer2 0 FILL">
		                        <path className={styles.cls9} d="m400.23,281.29c0-5.23-4.93-10.23-14.8-15-9.53-4.6-23-8.68-40.4-12.25-36.8-7.5-81.23-11.25-133.3-11.25s-96.5,3.75-133.3,11.25c-17.37,3.57-30.83,7.65-40.4,12.25-9.87,4.77-14.8,9.77-14.8,15s4.93,10.22,14.8,14.95c9.57,4.63,23.03,8.72,40.4,12.25,36.8,7.5,81.23,11.25,133.3,11.25s96.5-3.75,133.3-11.25c17.37-3.53,30.83-7.62,40.4-12.25,9.87-4.73,14.8-9.72,14.8-14.95Z"/>
		                    </g>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_1_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 1 FILL">
		                    <path className={styles.cls18} d="m388.28,60.69c.37-3.07-.55-5.55-2.75-7.45-2.1-1.83-4.72-2.62-7.85-2.35-3.3.27-6.32,1.95-9.05,5.05-1,1.17-3.02,4.15-6.05,8.95-1.9,3-4.22,6.32-6.95,9.95-.77,1.03-3.25,4.25-7.45,9.65-1.6,2.07-4.17,4.3-7.7,6.7-3.73,2.53-6.32,4.62-7.75,6.25-3.6,4.13-4.62,7.62-3.05,10.45,1.6,2.83,4.97,3.63,10.1,2.4,3.33-.77,7.03-2.67,11.1-5.7,1.83-1.37,4.97-4.02,9.4-7.95,7.87-7.03,13.52-12.48,16.95-16.35,6.7-7.53,10.38-14.07,11.05-19.6Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_2_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 2 FILL">
		                    <path className={styles.cls14} d="m388.28,60.69c.37-3.07-.55-5.55-2.75-7.45-2.1-1.83-4.72-2.62-7.85-2.35-3.3.27-6.32,1.95-9.05,5.05-1,1.17-3.02,4.15-6.05,8.95l-.15.2c5.63,1.2,10.03,3.32,13.2,6.35.87.8,2.33,2.67,4.4,5.6,4.93-6.17,7.68-11.62,8.25-16.35Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_3_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 3 FILL">
		                    <path className={styles.cls13} d="m305.83,163.59c.7-4.67.83-8.45.4-11.35-1.2-7.97-6.82-11.93-16.85-11.9-4.63.03-9.52,1.5-14.65,4.4-3.63,2.03-7.78,5.08-12.45,9.15-3.37,2.9-6.4,6.82-9.1,11.75-.97,1.7-3.12,6.22-6.45,13.55-1.9,4.13-2.27,9.05-1.1,14.75.63,3.07,2.22,7.88,4.75,14.45,3.47,8.83,2.12,18.77-4.05,29.8-3.17,5.67-4.78,8.57-4.85,8.7-1.33,3.63-.57,6.28,2.3,7.95,4.43,2.6,9.27,2.6,14.5,0,3.47-1.7,7.45-4.8,11.95-9.3,5.57-5.6,10.58-11.78,15.05-18.55,3-4.6,5.6-9.13,7.8-13.6,2.6-5.23,4.82-11.4,6.65-18.5,1.23-4.77,2.57-11.23,4-19.4,1.03-5.23,1.73-9.2,2.1-11.9Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_4_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 4 FILL">
		                    <path className={styles.cls20} d="m163.33,203.14l-2.2,1.5c-5.13,3.7-10.72,8.17-16.75,13.4-3.43,3-8.77,7.82-16,14.45-4.13,3.77-7.63,7.47-10.5,11.1-4.33,5.5-6.37,9.83-6.1,13,.2,1.9,1.12,3.7,2.75,5.4,1.5,1.53,3.23,2.65,5.2,3.35,2.63.9,5.53.67,8.7-.7,2-.87,4.62-2.5,7.85-4.9,2.03-1.5,4.78-3.38,8.25-5.65,4.03-2.63,6.8-4.47,8.3-5.5,5.73-4,9.77-6.85,12.1-8.55,4.63-3.37,8.53-6.4,11.7-9.1,5.97-5.1,10.12-9.12,12.45-12.05,4.6-5.77,5.83-10.63,3.7-14.6-5.47-10.27-15.28-10.65-29.45-1.15Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_5_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 5 FILL">
		                    <path className={styles.cls20} d="m104.48,255.19c-1.1,3.53-1.05,6.7.15,9.5,1.27,2.9,3.57,4.77,6.9,5.6,3.97,1.07,8.75,1.13,14.35.2,2.43-.43,4.87-1.65,7.3-3.65,2.6-2.1,4.43-4.47,5.5-7.1,1.27-3.17,1.17-6.13-.3-8.9-1.6-2.97-4.75-4.92-9.45-5.85-3.73-.73-7.5-.75-11.3-.05-3.4.63-6.08,1.63-8.05,3-2.43,1.7-4.13,4.12-5.1,7.25Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_6_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 6 FILL">
		                    <path className={styles.cls20} d="m317.13,97.79c-4.1-.07-7.57.13-10.4.6-3.63.6-6.92,1.68-9.85,3.25-2.43,1.33-6.1,3.83-11,7.5-4.63,3.47-8.37,5.9-11.2,7.3-7.1,3.53-12.78,6.2-17.05,8-6.1,2.57-12.07,4.65-17.9,6.25-6.83,1.83-11.18,2.98-13.05,3.45-5.2,1.23-9.62,2.02-13.25,2.35-3,.13-5.25.25-6.75.35-2.67.17-4.9.5-6.7,1-1.87.53-3.87,1.57-6,3.1-2.4,1.87-4.23,3.23-5.5,4.1-10.43,7.33-19.73,14.53-27.9,21.6-3.57,2.87-6.28,5.15-8.15,6.85-3.37,3.03-5.32,5.52-5.85,7.45-.5,1.73-.28,4.27.65,7.6.53,1.83,1.3,4.25,2.3,7.25,1.87,6.9,3.68,12.25,5.45,16.05,3.27,7.13,7.5,12.47,12.7,16,4.77,3.3,11.4,4.95,19.9,4.95,6.03,0,14.48-.97,25.35-2.9,5.43-.93,13.37-2.12,23.8-3.55,9.33-1.37,17.32-2.95,23.95-4.75,18.03-4.8,32.87-9.75,44.5-14.85,15.17-6.7,27.53-14.62,37.1-23.75,7.5-7.17,12.47-15,14.9-23.5,2.8-9.67,2.35-19.38-1.35-29.15-1.67-4.37-3.95-8.82-6.85-13.35-2.27-3.5-5.25-7.53-8.95-12.1-2.47-3.07-5.93-5.1-10.4-6.1-2.63-.6-6.8-.93-12.5-1Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_7_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 7 FILL">
		                    <path className={styles.cls13} d="m357.18,159.49c-7.57,2.4-13.23,4.17-17,5.3-6.7,1.97-12.78,2.8-18.25,2.5-6.53-.37-13.6,2.13-21.2,7.5-9.53,7.57-17.83,13.45-24.9,17.65-12.63,7.47-27.15,12.85-43.55,16.15-21.43,4.33-47.2,5.42-77.3,3.25,3.27,7.13,7.5,12.47,12.7,16,4.77,3.3,11.4,4.95,19.9,4.95,6.03,0,14.48-.97,25.35-2.9,5.43-.93,13.37-2.12,23.8-3.55,9.33-1.37,17.32-2.95,23.95-4.75,18.03-4.8,32.87-9.75,44.5-14.85,15.17-6.7,27.53-14.62,37.1-23.75,7.5-7.17,12.47-15,14.9-23.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_8_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 8 FILL">
		                    <path className={styles.cls20} d="m356.48,156.44c-.83-8.03-6.28-12.22-16.35-12.55-4.63-.17-9.57,1.08-14.8,3.75-3.7,1.9-7.98,4.78-12.85,8.65-3.47,2.77-6.65,6.57-9.55,11.4-1,1.7-3.32,6.12-6.95,13.25-2.07,4.07-2.63,8.97-1.7,14.7.5,3.17,1.88,8.05,4.15,14.65,3.13,9,1.38,18.87-5.25,29.6-3.4,5.53-5.13,8.37-5.2,8.5-1.47,3.57-.8,6.27,2,8.1,4.3,2.77,9.13,2.95,14.5.55,3.53-1.57,7.62-4.52,12.25-8.85,5.77-5.3,11.03-11.27,15.8-17.9,2.87-3.9,5.67-8.33,8.4-13.3,2.8-5.13,5.27-11.2,7.4-18.2,1.43-4.83,3-11.23,4.7-19.2,1.23-5.2,2.1-9.13,2.6-11.8.87-4.63,1.15-8.42.85-11.35Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_9_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 9 FILL">
		                    <path className={styles.cls20} d="m305.63,262.49c2.33-2,3.97-4.25,4.9-6.75,1.17-3,1.05-5.82-.35-8.45-1.5-2.83-4.4-4.68-8.7-5.55-3.4-.7-6.82-.7-10.25,0-3.03.6-5.47,1.57-7.3,2.9-2.2,1.63-3.72,3.93-4.55,6.9-.97,3.37-.88,6.37.25,9,1.17,2.77,3.27,4.55,6.3,5.35,3.7,1,8.05,1.03,13.05.1,2.2-.4,4.42-1.57,6.65-3.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_10_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 10 FILL">
		                    <path className={styles.cls13} d="m258.38,259.04c2.5-1.6,4.33-3.48,5.5-5.65,1.4-2.57,1.52-5.08.35-7.55-1.27-2.63-4.02-4.5-8.25-5.6-3.3-.87-6.73-1.13-10.3-.8-3.1.3-5.62.98-7.55,2.05-2.33,1.27-4.07,3.2-5.2,5.8-1.23,2.93-1.4,5.62-.5,8.05.93,2.53,2.9,4.27,5.9,5.2,3.57,1.17,7.93,1.53,13.1,1.1,2.23-.2,4.55-1.07,6.95-2.6Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_11_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 11 FILL">
		                    <path className={styles.cls18} d="m315.38,117.79c-2.1-3.53-4.8-6.3-8.1-8.3-3.6-2.17-7.77-3.35-12.5-3.55-1.8-.03-3.47.07-5,.3-3.23,2.47-5.67,4.28-7.3,5.45-2.87,2.03-5.47,3.62-7.8,4.75-7.1,3.53-12.78,6.2-17.05,8-6.1,2.57-12.07,4.65-17.9,6.25l-1.1.3c-2.53,1.43-4.58,2.67-6.15,3.7-4.43,2.47-7.98,4.58-10.65,6.35-4.97,3.23-7.8,5.97-8.5,8.2-1.6,4.87-2.55,10.32-2.85,16.35-.43,9.83.85,19.3,3.85,28.4,3.87,11.63,9.6,17.92,17.2,18.85,6.9.8,13.93-1.15,21.1-5.85,6.17-4.1,11.57-9.57,16.2-16.4,5.03-7.47,10.05-12.85,15.05-16.15,2.2-1.47,5.55-3.13,10.05-5,4.77-1.97,8.15-3.57,10.15-4.8,7.53-4.57,12.4-11.75,14.6-21.55,2.1-9.53,1-17.97-3.3-25.3Z"/>
		                </g>
		                <g className={styles.cls3}>
		                    <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_2_0_Layer2_0_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  2 0 Layer2 0 FILL">
		                        <path className={styles.cls9} d="m278.73,136.14c.5-4.7-.35-11.53-2.55-20.5-.57.4-1.57.93-3,1.6-1.57.7-2.62,1.17-3.15,1.4-4.9,2.43-10.4,4.5-16.5,6.2-7.27,1.83-13.05,3.33-17.35,4.5-6.83,1.83-11.18,2.98-13.05,3.45-5.2,1.23-9.62,2.02-13.25,2.35-3,.17-5.25.3-6.75.4-2.7.13-4.93.45-6.7.95-1.87.53-3.87,1.57-6,3.1-2.4,1.87-4.23,3.25-5.5,4.15-10.13,7.1-19.43,14.28-27.9,21.55-1.43,1.27-4.15,3.55-8.15,6.85-3.4,3.07-5.37,5.55-5.9,7.45-.47,1.7-.23,4.23.7,7.6.53,1.83,1.3,4.25,2.3,7.25,1.7,6.57,3.52,11.93,5.45,16.1.5,1.1.53,2.37.1,3.8-.6,1.93-.9,3-.9,3.2-.03,2.2,3.67,3.97,11.1,5.3,8.8.27,16.63.13,23.5-.4,8.2-.67,16.18-1.97,23.95-3.9,6.8-1.67,14.43-4.65,22.9-8.95,8.8-4.5,15.65-9,20.55-13.5,9.33-8.57,15.97-18.2,19.9-28.9,2.67-7.17,4.73-17.52,6.2-31.05Z"/>
		                    </g>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_13_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 13 FILL">
		                    <path className={styles.cls10} d="m329.78,303.84c12.53-1.63,23.5-6.47,32.9-14.5,10.23-8.77,17.02-20.12,20.35-34.05-7.67-12.77-17.72-21.72-30.15-26.85-11.3-4.67-23.18-5.7-35.65-3.1-12.03,2.5-22.43,7.9-31.2,16.2-9.17,8.7-14.8,19.07-16.9,31.1,5.73,11.63,14.35,20.23,25.85,25.8,10.8,5.2,22.4,7,34.8,5.4Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_14_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 14 FILL">
		                    <path className={styles.cls24} d="m350.53,250.64l.6-4.95c-6.87-.83-16.18-.23-27.95,1.8-11.3,1.97-19.75,4.3-25.35,7l2.15,4.5c5.2-2.53,13.22-4.73,24.05-6.6,11.27-1.93,20.1-2.52,26.5-1.75Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_15_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 15 FILL">
		                    <path className={styles.cls24} d="m340.68,239.29c-.43.53-.6,1.13-.5,1.8.23,2.27.48,5.17.75,8.7l.45,5.45c.07.53.27,1,.6,1.4.33.37.75.62,1.25.75.23.1.53.12.9.05.67-.03,1.22-.33,1.65-.9.43-.53.62-1.13.55-1.8l-.4-5.3c-.27-3.7-.53-6.68-.8-8.95-.07-.67-.38-1.22-.95-1.65-.53-.43-1.15-.6-1.85-.5-.67.07-1.22.38-1.65.95Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_16_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 16 FILL">
		                    <path className={styles.cls24} d="m329.28,239.39c-.67.17-1.18.53-1.55,1.1-.37.6-.48,1.23-.35,1.9.5,2.27,1.03,5.12,1.6,8.55l.95,5.4c.2.97.82,1.6,1.85,1.9.33.1.7.12,1.1.05.67-.17,1.2-.53,1.6-1.1.37-.57.48-1.18.35-1.85l-.9-5.25c-.8-4.43-1.37-7.37-1.7-8.8-.13-.67-.48-1.18-1.05-1.55-.6-.37-1.23-.48-1.9-.35Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_17_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 17 FILL">
		                    <path className={styles.cls24} d="m316.53,253.09l.9,5.35c.2,1,.82,1.65,1.85,1.95.3.1.67.12,1.1.05.67-.13,1.2-.48,1.6-1.05.4-.6.53-1.23.4-1.9l-.95-5.2c-.53-3.33-1.07-6.28-1.6-8.85-.13-.67-.5-1.18-1.1-1.55-.57-.37-1.18-.48-1.85-.35-.67.17-1.18.53-1.55,1.1-.4.57-.53,1.18-.4,1.85.5,2.37,1.03,5.23,1.6,8.6Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_18_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 18 FILL">
		                    <path className={styles.cls24} d="m306.78,247.04c-.17-.67-.55-1.17-1.15-1.5-.63-.33-1.27-.4-1.9-.2-.67.17-1.18.55-1.55,1.15-.33.63-.4,1.27-.2,1.9.53,1.93,1.22,4.77,2.05,8.5l1.25,5.3c.27.93.87,1.53,1.8,1.8.43.1.85.1,1.25,0,.67-.17,1.18-.55,1.55-1.15.33-.6.42-1.23.25-1.9l-1.2-5.2c-1.03-4.4-1.75-7.3-2.15-8.7Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_19_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 19 FILL">
		                    <path className={styles.cls20} d="m239.23,208.19c-3.6-5.2-9.9-5.5-18.9-.9-5.23,2.67-11.72,7.3-19.45,13.9l-2,1.75c-4.7,4.3-9.73,9.4-15.1,15.3-3.23,3.53-7.97,8.93-14.2,16.2-3.63,4.2-6.68,8.28-9.15,12.25-3.7,5.97-5.22,10.5-4.55,13.6.37,1.87,1.48,3.53,3.35,5,1.67,1.37,3.52,2.28,5.55,2.75,2.73.57,5.58,0,8.55-1.7,1.93-1.1,4.35-3.03,7.25-5.8,1.83-1.7,4.35-3.88,7.55-6.55,3.67-3.03,6.2-5.17,7.6-6.4,5.47-4.9,9.13-8.2,11-9.9,4.2-3.9,7.72-7.37,10.55-10.4,6.3-6.73,11.47-12.98,15.5-18.75,6.83-9.9,8.98-16.68,6.45-20.35Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_20_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 20 FILL">
		                    <path className={styles.cls20} d="m163.43,263.04c-3.37-.47-6.23-.35-8.6.35-2.83.83-5.2,2.58-7.1,5.25-2.13,3-3.07,6.02-2.8,9.05.3,3.2,1.88,5.72,4.75,7.55,1.8,1.13,3.97,2.13,6.5,3,2.33.77,4.7,1.32,7.1,1.65,2.4.37,5.1-.02,8.1-1.15,3.1-1.2,5.58-2.88,7.45-5.05,2.17-2.57,3-5.4,2.5-8.5-.6-3.37-3-6.22-7.2-8.55-3.27-1.87-6.83-3.07-10.7-3.6Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_21_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 21 FILL">
		                    <path
			                    className={styles.cls23}
			                    d="m175.43,181.99c-5.47.13-10.52,2.1-15.15,5.9-3.83,3.63-9.27,8.22-16.3,13.75-6,4.7-11.68,8.93-17.05,12.7,7.37,2.73,14.65,7.07,21.85,13,6.9,5.7,12.18,11.55,15.85,17.55,4.87-4,10.7-10.03,17.5-18.1,6.2-7.37,10.37-12.95,12.5-16.75,2.67-4.37,3.37-8.85,2.1-13.45-1.2-4.23-3.78-7.73-7.75-10.5-4.07-2.83-8.58-4.2-13.55-4.1Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_22_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 22 FILL">
		                    <path
			                    className={styles.cls22}
			                    d="m138.08,212.19l-1.2,2.75c6.57,2.93,12.57,6.8,18,11.6,5.43,4.8,9.72,10.02,12.85,15.65l2.2-2.15c-3.33-5.77-7.78-11.1-13.35-16-5.6-4.9-11.77-8.85-18.5-11.85Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_23_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 23 FILL">
		                    <path
			                    className={styles.cls22}
			                    d="m144.03,207.89l-1.2,2.7c6.33,2.87,12.17,6.6,17.5,11.2,5.3,4.6,9.55,9.63,12.75,15.1l2.15-2.3c-3.4-5.57-7.82-10.68-13.25-15.35-5.5-4.7-11.48-8.48-17.95-11.35Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_24_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 24 FILL">
		                    <path
			                    className={styles.cls21}
			                    d="m217.88,157.74c1.6,6.5,1.67,13.3.2,20.4-3.47,16.77-13.62,29.87-30.45,39.3-.43.27.43,2.65,2.6,7.15,2.17,4.6,4.27,8.42,6.3,11.45-2.3-1.03-7.55-5.07-15.75-12.1-7.5-6.47-11.47-9.55-11.9-9.25-1.17.87-3.32,2.25-6.45,4.15,10,9.97,21.93,16.38,35.8,19.25,11.13,2.3,21.9,2.17,32.3-.4,7.87-1.97,19.88-4.87,36.05-8.7,13.37-3.47,23.02-6.85,28.95-10.15,7.37-16.77,8.67-35.53,3.9-56.3-4.63-20.1-13.37-35.82-26.2-47.15-8,4.5-18.67,9.08-32,13.75-13.9,4.87-24.5,9.25-31.8,13.15,4.07,3.93,6.88,9.08,8.45,15.45Z"
			                    style={{ fill: `${teamColors[JERSEY_BG]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_25_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 25 FILL">
		                    <path
			                    className={styles.cls16}
			                    d="m266.33,159.99c2.77-7.17,3.88-14.28,3.35-21.35-.5-7.27-2.7-13.52-6.6-18.75-1.53-2.1-4.58,3.03-9.15,15.4-2.53,6.83-6.08,17.1-10.65,30.8-.4,1.17-3.17,6.27-8.3,15.3-4.33,8.23-5.2,11.92-2.6,11.05,8.13-2.67,15.22-7.02,21.25-13.05,5.6-5.57,9.83-12.03,12.7-19.4Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_26_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 26 FILL">
		                    <path
			                    className={styles.cls22}
			                    d="m212.28,139.04l-4.2,3.9c7.6,8.1,11.1,17.07,10.5,26.9-.43,7.23-3.1,14.67-8,22.3-4.9,7.67-11.85,15.25-20.85,22.75l-.25.2-.2.25c-1.97,2.57-1.92,6.97.15,13.2-2.03-1.57-4.13-3.55-6.3-5.95-1.57-1.73-3.52-4.12-5.85-7.15l-4.5,3.5c3.77,4.9,7,8.62,9.7,11.15,3.9,3.67,8.05,6.35,12.45,8.05l9.3,3.65-5.95-8c-1.5-2-2.75-4.73-3.75-8.2-1-3.33-1.27-5.52-.8-6.55,9.5-8,16.85-16.15,22.05-24.45s8.03-16.43,8.5-24.4c.77-11.4-3.23-21.78-12-31.15Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_27_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 27 FILL">
		                    <path
			                    className={styles.cls22}
			                    d="m272.78,140.49c3.03,6.6,5.73,14.95,8.1,25.05,2.53,10.6,3.63,21.07,3.3,31.4-.33,10.3-2.07,19.82-5.2,28.55.5-.13,1.65-.5,3.45-1.1,1.87-.63,3.15-1.1,3.85-1.4,5.57-18.77,6.23-38.22,2-58.35-4.1-19.4-11.58-34.6-22.45-45.6-.5.43-2.35,1.52-5.55,3.25,4.93,4.73,9.1,10.8,12.5,18.2Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_28_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 28 FILL">
		                    <path
			                    className={styles.cls23}
			                    d="m247.53,203.84c-1.9-3.97-5.05-6.98-9.45-9.05-4.47-2.1-9.13-2.68-14-1.75-5.4,1.03-10.05,3.82-13.95,8.35-3.17,4.27-7.75,9.7-13.75,16.3-5.2,5.73-10.1,10.87-14.7,15.4,5.17,6.3,11.95,11.7,20.35,16.2,7.7,4.13,15.03,6.65,22,7.55,4.13-4.8,8.85-11.72,14.15-20.75,4.9-8.33,8.08-14.55,9.55-18.65,1.9-4.77,1.83-9.3-.2-13.6Z"
			                    style={{ fill: `${teamColors[JERSEY_SLEEVE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_29_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 29 FILL">
		                    <path
			                    className={styles.cls22}
			                    d="m227.28,252.69l1.95-2.6c-8.73-2-16.7-5.02-23.9-9.05-7.2-4.07-13.03-8.8-17.5-14.2l-2.1,2.15c4.6,5.47,10.5,10.25,17.7,14.35,7.23,4.13,15.18,7.25,23.85,9.35Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_30_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 30 FILL">
		                    <path
			                    className={styles.cls22}
			                    d="m231.93,246.09l1.75-2.65c-8.43-1.97-16.15-4.9-23.15-8.8-7-3.9-12.72-8.45-17.15-13.65l-2.1,2.2c4.57,5.27,10.37,9.88,17.4,13.85,7.07,3.97,14.82,6.98,23.25,9.05Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_31_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 31 FILL">
		                    <path className={styles.cls18} d="m46.23,174.74c-.47.17-.62.48-.45.95.13.47.43.62.9.45,10.27-3.43,19.92-5.43,28.95-6,10.67-.67,20.02.7,28.05,4.1.43.17.77.03,1-.4.23-.47.13-.8-.3-1-8.2-3.47-17.73-4.87-28.6-4.2-9.17.6-19.02,2.63-29.55,6.1Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_32_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 32 FILL">
		                    <path className={styles.cls18} d="m50.08,201.29c-.43.23-.58.58-.45,1.05.17.47.47.58.9.35,19.33-9.7,37.98-15.33,55.95-16.9.5-.03.75-.3.75-.8-.03-.5-.28-.73-.75-.7-18.07,1.57-36.87,7.23-56.4,17Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_33_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 33 FILL">
		                    <path className={styles.cls18} d="m107.08,193.89c.47-.17.62-.48.45-.95-.13-.47-.43-.6-.9-.4-9.27,3.63-17.27,7.18-24,10.65-8.43,4.3-15.93,8.93-22.5,13.9-.43.33-.52.68-.25,1.05.27.4.6.45,1,.15,6.53-4.93,13.98-9.53,22.35-13.8,6.7-3.43,14.65-6.97,23.85-10.6Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_34_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 34 FILL">
		                    <path className={styles.cls14} d="m158.73,53.69c-3.53-9.37-6.62-17.32-9.25-23.85-3.03-7.43-4.68-11.05-4.95-10.85-4.03,2.77-8.47,7.67-13.3,14.7-2.23,3.2-5.78,8.92-10.65,17.15-4.1,6.87-8.27,16.3-12.5,28.3-4.3,12.23-7.13,22.77-8.5,31.6,5.5,3.53,18.32,1.78,38.45-5.25,7.83-2.73,14.78-5.63,20.85-8.7,6.13-3.1,9.43-5.4,9.9-6.9.67-2.13.97-3.63.9-4.5,0-.4-.35-1.9-1.05-4.5-.73-2.63-4.03-11.7-9.9-27.2Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_35_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 35 FILL">
		                    <path className={styles.cls14} d="m158.73,53.69c-3.53-9.37-6.62-17.32-9.25-23.85-3.03-7.43-4.68-11.05-4.95-10.85-4.03,2.77-8.47,7.67-13.3,14.7-2.23,3.2-5.78,8.92-10.65,17.15-4.1,6.87-8.27,16.3-12.5,28.3-4.3,12.23-7.13,22.77-8.5,31.6,5.5,3.53,18.32,1.78,38.45-5.25,7.83-2.73,14.78-5.63,20.85-8.7,6.13-3.1,9.43-5.4,9.9-6.9.67-2.13.97-3.63.9-4.5,0-.4-.35-1.9-1.05-4.5-.73-2.63-4.03-11.7-9.9-27.2Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_36_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 36 FILL">
		                    <path className={styles.cls20} d="m237.13,110.99l-11.6.35c-35.47-3.53-54.72-11.55-57.75-24.05-2-8.1,2.68-17.33,14.05-27.7-6.27.53-12.72,1.53-19.35,3-5.8,9.47-13.13,18.28-22,26.45-14.2,13.1-30.35,22.42-48.45,27.95-1.3,5.33-2.05,11.67-2.25,19-.1,2.87-.07,9.28.1,19.25.03,2.67-.32,5.92-1.05,9.75-.8,4.37-1.25,7.42-1.35,9.15-.43,7.37,2.32,15.7,8.25,25,7.23,11.33,16.57,18.35,28,21.05,4.17.97,8.98,1.45,14.45,1.45,3.1,0,7.47-.13,13.1-.4,9-.23,18.85-1.75,29.55-4.55,9.3-2.43,18.78-5.72,28.45-9.85,6.23-2.63,13.23-6.75,21-12.35,8.27-5.93,14.32-11.4,18.15-16.4,7.5-9.8,12.27-21.15,14.3-34.05,1.53-9.97,1.43-21.08-.3-33.35-.03-.17-.18-1.15-.45-2.95-7.37,1.63-15.65,2.72-24.85,3.25Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_37_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 37 FILL">
		                    <path className={styles.cls18} d="m225.53,111.34l11.6-.35c9.2-.57,17.48-1.67,24.85-3.3-2.13-11.3-6.92-21.13-14.35-29.5-5.97-6.67-14.28-12.98-24.95-18.95-6.23-3.5-11.95-5.5-17.15-6-7.7-.73-15.6,1.38-23.7,6.35-11.37,10.37-16.05,19.6-14.05,27.7,3.03,12.5,22.28,20.52,57.75,24.05Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_38_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 38 FILL">
		                    <path className={styles.cls18} d="m140.48,89.04c8.87-8.17,16.2-16.98,22-26.45-8.03,1.83-15.53,4.25-22.5,7.25-7.07,3-13.03,6.35-17.9,10.05-7.17,5.47-12.97,10.6-17.4,15.4-6.3,6.77-10.32,13.23-12.05,19.4-.17.5-.37,1.27-.6,2.3,18.1-5.53,34.25-14.85,48.45-27.95Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_39_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 39 FILL">
		                    <path className={styles.cls9} d="m184.93,131.34l-2.3-11.35-52.35,26.7,1.75,4.05,52.9-19.4Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_40_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 40 FILL">
		                    <path className={styles.cls9} d="m105.03,154.29l.6-4.5-14.25-8.8-.3,8.5,13.95,4.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_41_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 41 FILL">
		                    <path className={styles.cls18} d="m161.73,173.24c.33.33.68.35,1.05.05,6.87-5.27,15.5-9.07,25.9-11.4,8.77-2,18.48-2.88,29.15-2.65.47,0,.72-.22.75-.65.03-.47-.2-.7-.7-.7-10.9-.23-20.82.67-29.75,2.7-10.57,2.4-19.37,6.28-26.4,11.65-.37.3-.37.63,0,1Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_42_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 42 FILL">
		                    <path className={styles.cls18} d="m161.93,183.89c.1.43.38.6.85.5,17.57-3.57,36.77-3.7,57.6-.4.47.1.72-.08.75-.55.03-.47-.18-.73-.65-.8-20.9-3.33-40.25-3.18-58.05.45-.47.07-.63.33-.5.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_43_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 43 FILL">
		                    <path className={styles.cls18} d="m163.48,191.09c-.03.47.2.72.7.75,9.63.7,18.07,1.65,25.3,2.85,9,1.5,17.22,3.55,24.65,6.15.47.17.77.03.9-.4.13-.43-.02-.73-.45-.9-7.47-2.6-15.73-4.67-24.8-6.2-7.3-1.23-15.82-2.2-25.55-2.9-.47-.03-.72.18-.75.65Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_44_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 44 FILL">
		                    <path className={styles.cls11} d="m110.18,193.19c-.83,3.43-2.08,6.67-3.75,9.7.53,1.43,2.3,1.95,5.3,1.55,3-.43,4.72-1.55,5.15-3.35-1.33-1.27-2.42-2.33-3.25-3.2-1.5-1.57-2.65-3.13-3.45-4.7Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_45_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 45 FILL">
		                    <path className={styles.cls18} d="m133.98,187.74c-.43-.03-.75.58-.95,1.85-.13.63-.23,1.38-.3,2.25-.43,2.37-1.2,4.13-2.3,5.3-1.27,1.43-2.98,2.48-5.15,3.15-2.13.67-4.15.77-6.05.3-4.07-.9-6.75-3.7-8.05-8.4-.47-1.47-.8-2.58-1-3.35-.4-1.37-.6-2.52-.6-3.45.13-.77.18-1.32.15-1.65-.07-.3-.37-.6-.9-.9-.53-.3-.95-.38-1.25-.25-.87.43-1.02,1.6-.45,3.5.3,1.2.57,2.77.8,4.7.3,2.23.43,3.85.4,4.85-.03,1.9-.47,3.43-1.3,4.6-.73,1.03-1.63,1.67-2.7,1.9-1.13.27-2.18.03-3.15-.7-.73-.53-1.35-1.53-1.85-3-.5-1.73-1.02-2.52-1.55-2.35-.5.2-.62.78-.35,1.75.07.17.33.8.8,1.9.77,1.73,1.85,3.07,3.25,4,1.63,1.1,3.23,1.18,4.8.25,1.17-.7,2.15-1.97,2.95-3.8.53-1.23.85-1.95.95-2.15.27-.9.32-1.67.15-2.3,1.63,3.63,4.45,5.9,8.45,6.8,1.73.4,3.55.42,5.45.05,4.53-.87,7.7-3.3,9.5-7.3.53-1.17.93-2.55,1.2-4.15.4-2.23.08-3.37-.95-3.4Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_46_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 46 FILL">
		                    <path className={styles.cls17} d="m101.18,170.24c-1.67.23-3.03.88-4.1,1.95-2.23,2.13-2.9,4.6-2,7.4,1.17,3.7,4,5.55,8.5,5.55,3.93-.03,7.42-1.2,10.45-3.5,2.73-2.07,4.57-4.27,5.5-6.6,1.13-2.8.23-4.65-2.7-5.55-1.07-.33-2.72-.35-4.95-.05-3.43.47-5.2.7-5.3.7-2.47-.1-4.27-.07-5.4.1Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_47_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 47 FILL">
		                    <path
			                    className={styles.cls21}
			                    d="m255.28,85.39c3.07,12.8-1.03,19.48-12.3,20.05-4.67.23-10.13-.7-16.4-2.8-5.97-2-11.8-4.78-17.5-8.35-13.07-8.13-20.22-16.25-21.45-24.35-1.43-9.63,7-16.13,25.3-19.5-13.17-4.6-26.47-6.7-39.9-6.3-20.23.6-38.6,7-55.1,19.2-10.23,7.57-17.37,16.55-21.4,26.95-3.3,8.4-4.83,18.72-4.6,30.95,8.87-4.67,19.38-8.27,31.55-10.8,12.13-2.53,24.07-3.63,35.8-3.3,12.43.37,22.97,2.33,31.6,5.9,9.53,3.93,15.88,9.5,19.05,16.7,2.57,5,5.03,11.25,7.4,18.75,1.3,4.23,3.05,10.32,5.25,18.25,1.9,6.43,3.67,11.07,5.3,13.9,2.1,3.67,4.53,5.52,7.3,5.55,3.17.07,6.48-1.05,9.95-3.35,3.73-2.43,7.08-5.88,10.05-10.35,7.23-10.87,10.57-24.75,10-41.65-.63-18.5-3.93-33.65-9.9-45.45Z"
			                    style={{ fill: `${teamColors[HELMET_BG]}` }}
		                    />
		                </g>
		                <path
			                id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_48_1_STROKES"
	                        data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 48 1 STROKES"
	                        className={styles.cls1}
	                        d="m233.98,60.49c-9.03-5.43-17.87-8.25-26.5-8.45-8.6-.17-14.45,2.33-17.55,7.5-3.13,5.17-2.6,11.5,1.6,19,4.2,7.5,10.83,13.98,19.9,19.45,9.03,5.43,17.87,8.25,26.5,8.45,8.6.17,14.45-2.33,17.55-7.5,3.13-5.17,2.6-11.5-1.6-19-4.2-7.5-10.83-13.98-19.9-19.45Z"
	                        style={{ stroke: `${teamColors[HELMET_HOLE]}` }}
		                />
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_49_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 49 FILL">
		                    <path
			                    className={styles.cls23}
			                    d="m158.63,45.49c-5.37.87-9.63,2.07-12.8,3.6-8.07,6.07-12.93,15.13-14.6,27.2-1.27,9.03-.75,20.88,1.55,35.55l15-3.35c-.07-12.73,2.1-25.18,6.5-37.35,4.2-11.5,9.47-20.42,15.8-26.75-3.57.07-7.38.43-11.45,1.1Z"
			                    style={{ fill: `${teamColors[HELMET_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_50_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 50 FILL">
		                    <path className={styles.cls15} d="m193.03,105.54c-10.03-4.47-24.37-6.23-43-5.3-21.93,1.07-42.73,6.03-62.4,14.9-1.23.57-2.1,1.5-2.6,2.8-.47,1.3-.43,2.58.1,3.85.57,1.23,1.5,2.1,2.8,2.6s2.58.47,3.85-.1c17.8-8.03,36.8-12.7,57-14,17.67-1.13,31.07.33,40.2,4.4,2.3,1,4.95,3.42,7.95,7.25,4.33,5.53,9.03,13.55,14.1,24.05-9.63,9.97-19.08,17.75-28.35,23.35-13.23,7.97-26.85,12.2-40.85,12.7-13.83-8.23-27.13-11.67-39.9-10.3-12.77,1.4-24.9,7.57-36.4,18.5-.9.83-1.37,1.88-1.4,3.15-.03,1.23.38,2.3,1.25,3.2.87.9,1.92,1.37,3.15,1.4,1.23.03,2.3-.38,3.2-1.25l.2-.15c7.53,10.9,13.98,23.48,19.35,37.75-1.3.33-2.25,1.07-2.85,2.2-.6,1.1-.72,2.25-.35,3.45.33,1.17,1.05,2.05,2.15,2.65,5.7,3.13,12.58,4.97,20.65,5.5,8.03.53,16.73-.25,26.1-2.35,6.63-1.5,13.98-3.58,22.05-6.25,11.4-3.77,19.9-7.48,25.5-11.15,7.3-4.73,15.38-13,24.25-24.8s17.52-25.78,25.95-41.95c.4-.83.55-1.63.45-2.4-.9-11.5-4.5-19-10.8-22.5-.93-.5-1.9-.67-2.9-.5-1.03.17-1.92.63-2.65,1.4l-.5.55c-9.23-18.33-17.67-29.22-25.3-32.65m-53.1,85.45l.75.05c8.37-.13,16.58-1.45,24.65-3.95l12.15,35.05c-4.53,2.57-10.77,5.23-18.7,8-6.3,2.2-12.67,4.12-19.1,5.75-3.87-15-3.78-29.97.25-44.9m32-6.25c10.13-4,20.05-9.95,29.75-17.85,6.37-5.17,13.32-11.82,20.85-19.95,1.8,2.47,3,6.37,3.6,11.7-7.1,13.53-14.43,25.55-22,36.05-7.5,10.47-14.38,18.25-20.65,23.35l-11.55-33.3m-94.7,7.25c17.27-13.67,36.05-15.1,56.35-4.3-4.73,16.57-4.97,33.17-.7,49.8-13.2,2.63-24.27,2.45-33.2-.55-5.97-17.07-13.45-32.05-22.45-44.95Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_51_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 51 FILL">
		                    <path className={styles.cls18} d="m263.93,65.29c7.47-24.97,10.87-37.47,10.2-37.5-4.9-.33-11.43.78-19.6,3.35-3.83,1.2-10.18,3.55-19.05,7.05-13.07,5.17-21.3,8.57-24.7,10.2-9.8,4.73-16.82,9.72-21.05,14.95,2.23,6.63,12.13,15.2,29.7,25.7,7.07,4.2,13.47,7.5,19.2,9.9,6,2.47,9.63,3.25,10.9,2.35,1.83-1.3,3-2.33,3.5-3.1.2-.3.83-1.73,1.9-4.3,1.03-2.63,4.03-12.17,9-28.6Z"/>
		                </g>
		                <g id="FlashAICB_Assets_6_Layer_1_Clip_Group_0_0_Layer2_52_FILL" data-name="FlashAICB Assets 6 Layer 1  Clip Group  0 0 Layer2 52 FILL">
		                    <path className={styles.cls17} d="m246.28,91.49l16.8-50.4c-11.03,2.93-23.4,8.63-37.1,17.1-4.3,2.67-8.47,5.43-12.5,8.3l-4.8,3.55,37.6,21.45Z"/>
		                </g>
		            </g>
		        </g>
		    </g>
		    <g className={styles.cls6}>
		        <g className={styles.cls5}>
		            <g>
		                <g className={styles.cls9}>
		                    <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_1_0_Layer2_0_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  1 0 Layer2 0 FILL">
		                        <path className={styles.cls12} d="m825.76,281.29c0-5.23-4.93-10.23-14.8-15-9.53-4.6-23-8.68-40.4-12.25-36.8-7.5-81.23-11.25-133.3-11.25s-96.53,3.75-133.3,11.25c-17.4,3.57-30.87,7.65-40.4,12.25-9.87,4.77-14.8,9.77-14.8,15s4.93,10.22,14.8,14.95c9.57,4.63,23.03,8.72,40.4,12.25,36.8,7.5,81.23,11.25,133.3,11.25s96.5-3.75,133.3-11.25c17.37-3.53,30.83-7.62,40.4-12.25,9.87-4.73,14.8-9.72,14.8-14.95Z"/>
		                    </g>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_1_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 1 FILL">
		                    <path className={styles.cls18} d="m790.96,229.44c2.73-.9,4.5-2.63,5.3-5.2.73-2.5.38-4.98-1.05-7.45-1.5-2.63-4.08-4.45-7.75-5.45-1.33-.37-4.62-.83-9.85-1.4-3.27-.4-6.97-1-11.1-1.8-1.13-.23-4.8-1.02-11-2.35-2.33-.5-5.2-1.73-8.6-3.7-3.57-2.03-6.32-3.32-8.25-3.85-4.87-1.33-8.2-.78-10,1.65-1.8,2.4-1.2,5.48,1.8,9.25,1.97,2.43,4.98,4.73,9.05,6.9,1.8.93,5.25,2.47,10.35,4.6,8.93,3.7,15.7,6.2,20.3,7.5,8.93,2.5,15.87,2.93,20.8,1.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_2_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 2 FILL">
		                    <path className={styles.cls14} d="m773.96,229.09c7.1,1.67,12.77,1.78,17,.35,2.73-.9,4.5-2.63,5.3-5.2.73-2.5.38-4.98-1.05-7.45-1.5-2.63-4.08-4.45-7.75-5.45-1.33-.37-4.62-.83-9.85-1.4l-.25-.05c1.13,5.13,1.02,9.6-.35,13.4-.43,1.27-1.45,3.2-3.05,5.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_3_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 3 FILL">
		                    <path className={styles.cls13} d="m523.56,238.34c-3.5.77-6.28,1.67-8.35,2.7-3.47,1.8-5.58,4.18-6.35,7.15-.47,1.83-.08,4.18,1.15,7.05.4.97,1.57,3.2,3.5,6.7,1.63,3.03,5.03,5.12,10.2,6.25,3.43.73,7.2,1,11.3.8,7.03-.3,12.3-.62,15.8-.95,5.83-.53,11.03-1.37,15.6-2.5l1.9-.5c12.13-3.7,16.67-12.42,13.6-26.15-1.47-6.4-2.5-10.05-3.1-10.95-.8-1.13-2.77-1.53-5.9-1.2-3.23.37-9.1,1.45-17.6,3.25-7.9,1.67-13.75,3.02-17.55,4.05-1.23.33-3.58,1.08-7.05,2.25-2.93.97-5.32,1.65-7.15,2.05Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_4_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 4 FILL">
		                    <path className={styles.cls13} d="m656.16,226.89c-1.97,3.63-2.67,8.05-2.1,13.25.63,5.47,2.53,9.42,5.7,11.85.9.7,2.33,1.47,4.3,2.3,2.2.93,3.68,1.63,4.45,2.1,3.23,2.03,6.25,3.57,9.05,4.6,3.23,1.17,6.55,1.8,9.95,1.9,2.83.1,7.07.03,12.7-.2,5-.03,9.18.47,12.55,1.5,1.7.5,3.95,1.27,6.75,2.3,3.33,1.27,5.57,2.08,6.7,2.45,6.53,2.17,12.6,3.23,18.2,3.2,8.77-.03,12.28-3.43,10.55-10.2-1.03-4.13-5.1-7.67-12.2-10.6-3.93-1.5-6.83-2.6-8.7-3.3-3.23-1.27-5.62-2.45-7.15-3.55-2.2-1.57-4.05-4.12-5.55-7.65-1.47-4.1-2.65-7.1-3.55-9-3.13-6.7-8.02-11.23-14.65-13.6-4.6-1.6-8.58-2.6-11.95-3-4.17-.47-8.27-.15-12.3.95-4.83,1.3-9.1,3.03-12.8,5.2-4.67,2.73-7.98,5.9-9.95,9.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_5_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 5 FILL">
		                    <path className={styles.cls19} d="m615.96,191.94c-2.4-.1-4.18-.15-5.35-.15-8.3-.03-17,.78-26.1,2.45-1.57.3-4.28.55-8.15.75-3.53.2-6.23.53-8.1,1-6.07,1.53-10.15,6.28-12.25,14.25-1.5,5.67-1.92,12.58-1.25,20.75l.15,2.05c.4,3.63,1.83,6.62,4.3,8.95,1.8,1.63,4.73,3.37,8.8,5.2,3.97,1.77,6.32,2.8,7.05,3.1,2.7,1.07,5.13,1.8,7.3,2.2,1.53.3,3.68.45,6.45.45,3.13.03,5.3.12,6.5.25l6.95.65c4.97,6.4,12.17,10.03,21.6,10.9,5.5.47,14,.28,25.5-.55,6.63-.07,14.63-.55,24-1.45,5.37-.53,13.37-1.37,24-2.5,10.83-1.03,18.15-2.28,21.95-3.75,7.1-2.73,12.38-7.95,15.85-15.65,1.83-4.1,2.98-8.7,3.45-13.8.43-4.93.2-9.95-.7-15.05-1.47-8.27-2.3-12.77-2.5-13.5-1.17-3.9-3.32-7.3-6.45-10.2-6.7-6.17-14.62-10.7-23.75-13.6-9.37-2.97-18.17-3.58-26.4-1.85-14.47,2.47-25.32,4.55-32.55,6.25-12.63,3.03-22.73,7.32-30.3,12.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_6_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 6 FILL">
		                    <path className={styles.cls13} d="m630.31,244.94c-2.37-.13-7.3-.55-14.8-1.25-1.57-.17-8.22-.53-19.95-1.1-8.2-.4-14.8-1.15-19.8-2.25-2.93-.63-6.38-1.78-10.35-3.45-4.53-1.93-8.08-3.9-10.65-5.9l.15,2.05c.4,3.63,1.83,6.62,4.3,8.95,1.8,1.63,4.73,3.37,8.8,5.2,3.97,1.77,6.32,2.8,7.05,3.1,2.7,1.07,5.13,1.8,7.3,2.2,1.53.3,3.68.45,6.45.45,3.13.03,5.3.12,6.5.25l6.95.65c4.97,6.4,12.17,10.03,21.6,10.9,5.5.47,14,.28,25.5-.55,6.63-.07,14.63-.55,24-1.45,5.37-.53,13.37-1.37,24-2.5,10.83-1.03,18.15-2.28,21.95-3.75,7.1-2.73,12.38-7.95,15.85-15.65,1.83-4.1,2.98-8.7,3.45-13.8.43-4.93.2-9.95-.7-15.05-1,.33-4.43,1.55-10.3,3.65-4.57,1.67-12.9,5.5-25,11.5-10.37,5.1-18.82,8.52-25.35,10.25-7,2.03-12.25,3.53-15.75,4.5-6.23,1.7-11.6,2.72-16.1,3.05-4.43.33-9.47.33-15.1,0Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_7_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 7 FILL">
		                    <path className={styles.cls19} d="m758.26,255.09c-1.9-1.73-4.05-2.95-6.45-3.65-2.8-.87-5.47-.8-8,.2-2.7,1.13-4.47,3.27-5.3,6.4-.63,2.5-.62,5.03.05,7.6.6,2.27,1.53,4.05,2.8,5.35,1.53,1.63,3.72,2.77,6.55,3.4,3.2.73,6.05.68,8.55-.15,2.6-.87,4.28-2.42,5.05-4.65.47-1.37.68-2.95.65-4.75,0-1.67-.2-3.3-.6-4.9-.4-1.6-1.5-3.22-3.3-4.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_8_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 8 FILL">
		                    <path className={styles.cls19} d="m529.46,245.09c-3.6-.97-7.25-1.22-10.95-.75-3.3.43-5.93,1.28-7.9,2.55-2.43,1.53-4.18,3.82-5.25,6.85-1.2,3.47-1.25,6.62-.15,9.45,1.1,3,3.25,5.02,6.45,6.05,1.97.63,4.27,1.03,6.9,1.2,2.37.17,4.72.12,7.05-.15,2.33-.3,4.73-1.37,7.2-3.2,2.6-1.93,4.47-4.17,5.6-6.7,1.37-3.1,1.37-6.05,0-8.85-1.47-3.1-4.45-5.25-8.95-6.45Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_9_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 9 FILL">
		                    <path className={styles.cls19} d="m780.46,264.99c3.9-5.3,1.77-10.98-6.4-17.05-2.1-1.57-5.58-3.8-10.45-6.7-4.2-2.5-6.87-4.28-8-5.35-2.1-2-3.7-4.98-4.8-8.95-1.03-4.6-1.9-7.98-2.6-10.15-1.57-4.93-3.4-9.33-5.5-13.2-2.87-5.2-5.8-8.57-8.8-10.1-3.37-1.73-7.23-2.17-11.6-1.3-5.2,1.5-9.33,2.48-12.4,2.95-9.5,1.53-17.58,5.53-24.25,12-7.9,7.63-9.65,15.85-5.25,24.65,3.1,6.13,8.73,11.37,16.9,15.7,7.37,3.87,14.73,6.03,22.1,6.5.57.03,3.97.02,10.2-.05,4-.07,7.35.32,10.05,1.15,3.23,1,6.97,2.47,11.2,4.4,1.43.67,5.05,2.43,10.85,5.3,3.87,1.9,7.1,3.03,9.7,3.4,4.03.6,7.05-.47,9.05-3.2Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_10_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 10 FILL">
		                    <path className={styles.cls19} d="m787.96,258.39c-1.23-2.67-2.97-5-5.2-7-2.7-2.43-5.65-3.9-8.85-4.4-3.47-.57-6.43.32-8.9,2.65-1.93,1.87-3.17,4.13-3.7,6.8-.47,2.37-.35,4.5.35,6.4.87,2.37,2.68,4.65,5.45,6.85,3.13,2.5,6.27,4.1,9.4,4.8,3.27.73,5.87.32,7.8-1.25,2.37-1.9,3.98-4.75,4.85-8.55.37-1.7-.03-3.8-1.2-6.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_11_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 11 FILL">
		                    <path className={styles.cls18} d="m678.81,172.84c-10.97,1.97-19.27,3.52-24.9,4.65-9.93,1.97-18.12,4.3-24.55,7-2.53,2.97-4.65,6.43-6.35,10.4-2.8,6.53-4.08,13.17-3.85,19.9.3,8.6,3.5,13.97,9.6,16.1,5.53,1.97,11.85,2.08,18.95.35,6.13-1.53,11.95-4.13,17.45-7.8,6.1-4.03,11.6-6.65,16.5-7.85,2.17-.53,5.35-.98,9.55-1.35,4.47-.37,7.68-.77,9.65-1.2,6.83-1.43,12.5-4.93,17-10.5,4.9-6.03,6.22-12.28,3.95-18.75l-.6-1.5c-6.43-4.23-13.37-7.2-20.8-8.9-7.7-1.8-14.9-1.98-21.6-.55Z"/>
		                </g>
		                <g className={styles.cls8}>
		                    <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_2_0_Layer2_0_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  2 0 Layer2 0 FILL">
		                        <path className={styles.cls12} d="m645.81,210.99c4.03-2.07,7.77-4.92,11.2-8.55-6.13-3.83-14.68-6.43-25.65-7.8-4.33-.33-7.6-.57-9.8-.7-3.87-.3-7.08-.45-9.65-.45-11.43-.1-20.73.13-27.9.7-11,.9-18.52,2.6-22.55,5.1-1.87,1.2-3.82,4.68-5.85,10.45-2.2,6.27-2.77,11.08-1.7,14.45.7.23,1.65,2.28,2.85,6.15,1.07,3.4,1.85,4.78,2.35,4.15,3.03-3.87,7.58-7.03,13.65-9.5,3.47-1.4,8.67-3.1,15.6-5.1h.1l.1.05c10.97.87,20.67.75,29.1-.35,10.23-1.37,19.62-4.23,28.15-8.6Z"/>
		                    </g>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_13_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 13 FILL">
		                    <path className={styles.cls10} d="m788.21,288.84c10.23-8.77,17.02-20.12,20.35-34.05-7.7-12.77-17.77-21.72-30.2-26.85-11.3-4.67-23.17-5.7-35.6-3.1-12.07,2.5-22.47,7.9-31.2,16.2-9.17,8.7-14.8,19.07-16.9,31.1,5.73,11.63,14.35,20.23,25.85,25.8,10.8,5.2,22.4,7,34.8,5.4,12.53-1.63,23.5-6.47,32.9-14.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_14_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 14 FILL">
		                    <path className={styles.cls24} d="m776.06,250.14l.6-4.95c-6.87-.83-16.18-.23-27.95,1.8-11.3,1.97-19.77,4.3-25.4,7l2.2,4.5c5.17-2.53,13.18-4.73,24.05-6.6,11.27-1.93,20.1-2.52,26.5-1.75Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_15_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 15 FILL">
		                    <path className={styles.cls24} d="m771.31,256.04c.43-.53.62-1.13.55-1.8l-.45-5.3c-.3-4.4-.55-7.38-.75-8.95-.1-.67-.42-1.22-.95-1.65-.57-.43-1.18-.6-1.85-.5-.67.07-1.22.38-1.65.95-.43.53-.62,1.13-.55,1.8.27,2.13.53,5.03.8,8.7.23,3.1.38,4.92.45,5.45.07.53.27,1,.6,1.4.33.37.75.62,1.25.75.23.1.52.12.85.05.7-.03,1.27-.33,1.7-.9Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_16_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 16 FILL">
		                    <path className={styles.cls24} d="m757.76,240.79c-.13-.67-.5-1.18-1.1-1.55-.57-.37-1.18-.48-1.85-.35-.67.17-1.18.53-1.55,1.1-.37.6-.48,1.23-.35,1.9.07.33.6,3.18,1.6,8.55l.95,5.4c.2.97.82,1.6,1.85,1.9.3.1.67.12,1.1.05.67-.17,1.2-.53,1.6-1.1.37-.57.48-1.18.35-1.85l-.95-5.25c-.6-3.7-1.15-6.63-1.65-8.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_17_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 17 FILL">
		                    <path className={styles.cls24} d="m745.36,242.94c-.13-.67-.5-1.18-1.1-1.55-.57-.37-1.18-.48-1.85-.35-.67.17-1.2.53-1.6,1.1-.37.57-.48,1.18-.35,1.85.37,1.73.88,4.6,1.55,8.6l.95,5.35c.2,1,.82,1.65,1.85,1.95.3.1.67.12,1.1.05.67-.13,1.2-.48,1.6-1.05.4-.6.52-1.23.35-1.9l-.9-5.2c-.53-3.33-1.07-6.28-1.6-8.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_18_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 18 FILL">
		                    <path className={styles.cls24} d="m731.16,245.04c-.63-.33-1.28-.4-1.95-.2-.67.17-1.17.55-1.5,1.15-.33.63-.4,1.27-.2,1.9.53,1.93,1.22,4.77,2.05,8.5l1.25,5.3c.27.93.87,1.53,1.8,1.8.43.1.85.1,1.25,0,.67-.17,1.18-.55,1.55-1.15.33-.6.42-1.23.25-1.9-.33-1.27-.73-3-1.2-5.2-1.03-4.4-1.75-7.3-2.15-8.7-.17-.67-.55-1.17-1.15-1.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_19_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 19 FILL">
		                    <path
			                    className={styles.cls21}
			                    d="m654.81,176.29c-16.33,3.5-29.58,7.98-39.75,13.45-1.27,6.57-2.4,11.28-3.4,14.15-1.63,4.83-3.88,8.68-6.75,11.55-7,7-19.63,9.62-37.9,7.85-.3-.03-1.62,2.12-3.95,6.45-2.3,4.27-3.62,6.35-3.95,6.25-.67-.2-1.48-3.1-2.45-8.7-.97-5.6-1.82-8.53-2.55-8.8-.87,3.4.32,12.8,3.55,28.2.97,4.6,8.43,9.33,22.4,14.2,12.97,4.47,23.52,6.63,31.65,6.5,9.93-.2,22.18-.82,36.75-1.85,15.73-1.1,26.58-1.75,32.55-1.95,9.53-14.43,15.28-28.98,17.25-43.65,1.93-14.73.22-30.82-5.15-48.25-10.97-.07-23.73,1.47-38.3,4.6Z"
			                    style={{ fill: `${teamColors[HELMET_HOLE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_20_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 20 FILL">
		                    <path
			                    className={styles.cls16}
			                    d="m661.86,175.39c-3.2,1.03-5.73,2.07-7.6,3.1-3.27,2.53-8.93,10.05-17,22.55-8.1,12.5-12.3,20.27-12.6,23.3-.33,3.53.85,11.38,3.55,23.55,2.77,12.53,4.87,18.17,6.3,16.9,6.67-4.27,9.32-10.58,7.95-18.95-.57-3.37-2.13-7.63-4.7-12.8,12.6-6.4,21.73-17.28,27.4-32.65,2.07-5.57,3.43-11.12,4.1-16.65.57-4.73.55-8.12-.05-10.15-2.03.3-4.48.9-7.35,1.8Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_21_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 21 FILL">
		                    <path className={styles.cls19} d="m635.46,237.69c-1-.97-3.58-1.17-7.75-.6-4.17.57-11.97,2.02-23.4,4.35-10.03,2.07-17.8,3.75-23.3,5.05-1.67.4-4.8,1.27-9.4,2.6-3.9,1.1-7.07,1.9-9.5,2.4-3.9.83-6.82,1.73-8.75,2.7-3.07,1.5-5.17,3.62-6.3,6.35-.83,2.07-1.2,4.25-1.1,6.55.13,2.53.82,4.55,2.05,6.05,2.07,2.53,6.5,4.07,13.3,4.6,4.5.37,9.47.3,14.9-.2,9.97-.97,16.93-1.7,20.9-2.2,7.8-.97,14.72-2.15,20.75-3.55l2.5-.6c16.2-4.3,22.47-12.37,18.8-24.2-1.7-5.47-2.93-8.57-3.7-9.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_22_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 22 FILL">
		                    <path className={styles.cls19} d="m570.41,277.59c3-2.03,5.22-4.45,6.65-7.25,1.73-3.33,1.93-6.67.6-10-1.43-3.6-4.63-6.23-9.6-7.9-3.93-1.33-7.97-1.87-12.1-1.6-3.67.23-6.67,1-9,2.3-2.77,1.57-4.83,4.03-6.2,7.4-1.53,3.83-1.8,7.4-.8,10.7,1.07,3.43,3.33,5.85,6.8,7.25,2.13.87,4.67,1.48,7.6,1.85,2.6.33,5.22.43,7.85.3s5.37-1.15,8.2-3.05Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_23_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 23 FILL">
		                    <path
			                    className={styles.cls23}
			                    d="m635.81,237.99c-2.3-5.17-7.55-9.92-15.75-14.25-6.63-3.53-12.03-5.5-16.2-5.9-4-.37-8.88.75-14.65,3.35-6.27,2.87-9.62,6.02-10.05,9.45-.4,3.37.37,6.48,2.3,9.35,2,3,4.57,4.57,7.7,4.7,24.7-5.77,40.25-8,46.65-6.7Z"
			                    style={{ fill: `${teamColors[JERSEY_SLEEVE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_24_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 24 FILL">
		                    <path
			                    className={styles.cls22}
			                    d="m619.56,189.79l-6-.25c-.6,13.77-6.62,22.97-18.05,27.6-4.07,1.67-9.18,2.88-15.35,3.65-6.8.6-11.63,1.05-14.5,1.35l-1.45.15-.8,1.25c-2.1,3.5-3.45,6.63-4.05,9.4-.7-3.23-.9-6.22-.6-8.95.53-2.9.93-5.1,1.2-6.6l-6.45.5c-1.07,7.93-.65,15.4,1.25,22.4.97,3.5,2.03,6.22,3.2,8.15.93-2.63,2.05-5.47,3.35-8.5,2.57-6.07,4.78-10.08,6.65-12.05,3.37-.47,8.8-1.22,16.3-2.25,6.67-1.13,12.15-2.72,16.45-4.75,11.9-5.57,18.18-15.93,18.85-31.1Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_25_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 25 FILL">
		                    <path
			                    className={styles.cls22}
			                    d="m680.61,172.29l-6.05.65c5.4,14.3,6.58,30.77,3.55,49.4-2.87,17.37-8.35,31.47-16.45,42.3l7.65-.5c8.5-12.53,13.87-27.37,16.1-44.5,2.23-17.5.63-33.28-4.8-47.35Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_26_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 26 FILL">
		                    <path className={styles.cls18} d="m447.06,192.49c-.47.1-.65.38-.55.85.07.5.33.68.8.55,10.6-2.33,20.42-3.33,29.45-3,10.67.43,19.82,2.75,27.45,6.95.4.23.75.13,1.05-.3.3-.43.23-.77-.2-1-7.8-4.3-17.13-6.67-28-7.1-9.2-.37-19.2.65-30,3.05Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_27_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 27 FILL">
		                    <path className={styles.cls18} d="m448.16,219.29c-.47.17-.67.48-.6.95.1.47.38.62.85.45,20.33-7.67,39.48-11.37,57.45-11.1.47.03.73-.2.8-.7.03-.5-.2-.75-.7-.75-18.3-.27-37.57,3.45-57.8,11.15Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_28_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 28 FILL">
		                    <path className={styles.cls18} d="m456.51,236.04c-.43.27-.55.6-.35,1,.23.43.57.52,1,.25,7-4.23,14.87-8.05,23.6-11.45,6.97-2.7,15.23-5.4,24.8-8.1.47-.13.67-.43.6-.9-.1-.47-.38-.63-.85-.5-9.53,2.67-17.87,5.38-25,8.15-8.8,3.43-16.73,7.28-23.8,11.55Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_29_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 29 FILL">
		                    <path className={styles.cls14} d="m560.71,47.54c-4.3,2.37-9.22,6.78-14.75,13.25-2.47,2.9-6.58,8.23-12.35,16-4.77,6.43-9.87,15.4-15.3,26.9-5.53,11.73-9.43,21.92-11.7,30.55,5.13,4.1,18.07,3.67,38.8-1.3,8.07-1.93,15.28-4.12,21.65-6.55,6.4-2.47,9.92-4.42,10.55-5.85.87-2.03,1.32-3.48,1.35-4.35.03-.4-.17-1.92-.6-4.55-.4-2.53-2.75-11.9-7.05-28.1-6.47-24.3-10-36.3-10.6-36Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_30_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 30 FILL">
		                    <path className={styles.cls14} d="m560.71,47.54c-4.3,2.37-9.22,6.78-14.75,13.25-2.47,2.9-6.58,8.23-12.35,16-4.77,6.43-9.87,15.4-15.3,26.9-5.53,11.73-9.43,21.92-11.7,30.55,5.13,4.1,18.07,3.67,38.8-1.3,8.07-1.93,15.28-4.12,21.65-6.55,6.4-2.47,9.92-4.42,10.55-5.85.87-2.03,1.32-3.48,1.35-4.35.03-.4-.17-1.92-.6-4.55-.4-2.53-2.75-11.9-7.05-28.1-6.47-24.3-10-36.3-10.6-36Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_31_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 31 FILL">
		                    <path className={styles.cls20} d="m668.66,150.79l-.2-2.95c-7.5.87-15.85,1.1-25.05.7l-11.55-.85c-34.93-7.13-53.27-17.07-55-29.8-1.17-8.27,4.43-16.98,16.8-26.15-6.63-.1-13.15.25-19.55,1.05-6.7,8.8-14.9,16.82-24.6,24.05-15.47,11.57-32.48,19.18-51.05,22.85-1.8,5.13-3.18,11.33-4.15,18.6-.3,2.4-.93,8.8-1.9,19.2-.23,2.67-.9,5.88-2,9.65-1.27,4.27-2.03,7.23-2.3,8.9-1.2,7.3.68,15.88,5.65,25.75,6.03,12,14.62,19.93,25.75,23.8,4.03,1.4,8.77,2.38,14.2,2.95,3.1.3,7.47.6,13.1.9,8.97.67,18.9.17,29.8-1.5,9.53-1.5,19.32-3.8,29.35-6.9,6.5-2.03,13.88-5.4,22.15-10.1,8.83-5.1,15.4-9.93,19.7-14.5,8.5-9,14.4-19.82,17.7-32.45,2.53-9.73,3.58-20.8,3.15-33.2Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_32_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 32 FILL">
		                    <path className={styles.cls18} d="m634.36,95.59c-5.87-4.13-11.37-6.72-16.5-7.75-7.57-1.5-15.63-.2-24.2,3.9-12.37,9.17-17.97,17.88-16.8,26.15,1.73,12.73,20.07,22.67,55,29.8l11.55.85c9.2.4,17.55.17,25.05-.7-.93-11.5-4.68-21.78-11.25-30.85-5.23-7.23-12.85-14.37-22.85-21.4Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_33_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 33 FILL">
		                    <path className={styles.cls18} d="m550.96,97.69c-7.3,2.27-13.57,4.98-18.8,8.15-7.67,4.73-13.97,9.25-18.9,13.55-6.97,6.1-11.62,12.13-13.95,18.1l-.85,2.2c18.57-3.67,35.58-11.28,51.05-22.85,9.7-7.23,17.9-15.25,24.6-24.05-8.2,1-15.92,2.63-23.15,4.9Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_34_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 34 FILL">
		                    <path className={styles.cls18} d="m620.11,193.99c.07-.43-.13-.68-.6-.75-10.8-1.33-20.75-1.45-29.85-.35-10.77,1.3-19.92,4.27-27.45,8.9-.4.27-.45.58-.15.95.3.4.65.47,1.05.2,7.37-4.53,16.33-7.43,26.9-8.7,8.9-1.1,18.65-1,29.25.3.5.07.78-.12.85-.55Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_35_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 35 FILL">
		                    <path className={styles.cls18} d="m620.11,218.99c.07-.43-.13-.72-.6-.85-20.37-5.47-39.62-7.32-57.75-5.55-.47.07-.67.33-.6.8.07.47.33.67.8.6,17.9-1.77,37.02.07,57.35,5.5.47.13.73-.03.8-.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_36_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 36 FILL">
		                    <path className={styles.cls18} d="m612.31,235.29c.2-.43.08-.75-.35-.95-7.13-3.37-15.15-6.27-24.05-8.7-7.07-1.93-15.43-3.77-25.1-5.5-.47-.07-.75.12-.85.55-.07.47.15.73.65.8,9.5,1.67,17.78,3.48,24.85,5.45,8.8,2.4,16.77,5.28,23.9,8.65.43.2.75.1.95-.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_37_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 37 FILL">
		                    <path className={styles.cls11} d="m508.76,217.34c-1.2,3.37-2.78,6.47-4.75,9.3.63,1.97,1.88,3.92,3.75,5.85,1.83,1.9,3.65,3.17,5.45,3.8,2,.67,3.43.32,4.3-1.05,1.03-1.57,1.12-4.42.25-8.55-1.37.1-3.05-.73-5.05-2.5-2.23-2.03-3.55-4.32-3.95-6.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_38_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 38 FILL">
		                    <path className={styles.cls18} d="m509.86,216.44c-.33-1.5-.57-2.65-.7-3.45-.23-1.37-.3-2.52-.2-3.45.23-.77.33-1.32.3-1.65-.03-.3-.28-.63-.75-1-.53-.37-.95-.48-1.25-.35-.83.33-1.12,1.48-.85,3.45.17,1.1.28,2.68.35,4.75.03,2.4,0,4.03-.1,4.9-.23,1.87-.82,3.33-1.75,4.4-.83.93-1.8,1.47-2.9,1.6-1.13.17-2.15-.17-3.05-1-.67-.63-1.18-1.7-1.55-3.2-.1-.57-.23-1.05-.4-1.45-.27-.77-.55-1.12-.85-1.05-.53.13-.72.7-.55,1.7.03.27.23.93.6,2,.57,1.8,1.5,3.23,2.8,4.3,1.53,1.27,3.12,1.52,4.75.75,1.27-.6,2.38-1.75,3.35-3.45.53-.9.92-1.6,1.15-2.1.37-.83.48-1.58.35-2.25,1.3,3.8,3.88,6.35,7.75,7.65,1.7.57,3.5.77,5.4.6,4.57-.4,7.97-2.5,10.2-6.3.63-1.03,1.18-2.38,1.65-4.05.6-2.17.38-3.32-.65-3.45-.4-.03-.78.55-1.15,1.75l-.5,2.2c-.7,2.3-1.63,3.98-2.8,5.05-1.43,1.27-3.25,2.13-5.45,2.6-2.2.43-4.23.33-6.1-.3-3.9-1.33-6.27-4.4-7.1-9.2Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_39_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 39 FILL">
		                    <path className={styles.cls12} d="m529.01,165.14c12.87,11.53,24.72,13.43,35.55,5.7,5.43-3.83,9.57-8.83,12.4-15-7.73,7.93-18.05,11.87-30.95,11.8-4-.03-8.03-.45-12.1-1.25l-4.9-1.25Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_40_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 40 FILL">
		                    <path className={styles.cls12} d="m509.16,173.19c2.77-1.8,5.17-4.75,7.2-8.85-3.63,2.57-7.4,3.83-11.3,3.8-4.63-.03-7.95-2.18-9.95-6.45-.47,3.33-.03,6.22,1.3,8.65,1.27,2.3,3.07,3.75,5.4,4.35,2.43.6,4.88.1,7.35-1.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_41_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 41 FILL">
		                    <path className={styles.cls17} d="m502.16,193.59c-1.67.07-3.1.57-4.3,1.5-2.43,1.9-3.35,4.28-2.75,7.15.77,3.83,3.4,5.97,7.9,6.4,3.93.4,7.52-.4,10.75-2.4,2.93-1.8,4.98-3.8,6.15-6,1.4-2.67.7-4.6-2.1-5.8-1.03-.43-2.7-.62-5-.55-3.27.13-5.03.18-5.3.15-2.43-.33-4.22-.48-5.35-.45Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_42_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 42 FILL">
		                    <path
			                    className={styles.cls21}
			                    d="m633.81,139.14c-5.73-2.6-11.27-5.97-16.6-10.1-12.17-9.4-18.45-18.2-18.85-26.4-.47-9.73,8.58-15.33,27.15-16.8-12.6-5.93-25.62-9.38-39.05-10.35-20.2-1.47-39.12,3.02-56.75,13.45-10.93,6.5-18.95,14.72-24.05,24.65-4.13,8.03-6.72,18.13-7.75,30.3,9.3-3.73,20.15-6.25,32.55-7.55,12.3-1.27,24.28-1.12,35.95.45,12.33,1.63,22.6,4.67,30.8,9.1,9.1,4.87,14.85,11.03,17.25,18.5,2.03,5.23,3.83,11.7,5.4,19.4.9,4.37,2.03,10.6,3.4,18.7,1.23,6.6,2.5,11.38,3.8,14.35,1.73,3.87,3.98,5.97,6.75,6.3,3.13.37,6.55-.4,10.25-2.3,3.97-2.07,7.65-5.15,11.05-9.25,8.3-10.07,13.03-23.55,14.2-40.45,1.23-18.43-.5-33.83-5.2-46.2,1.73,13.07-3.02,19.3-14.25,18.7-4.7-.23-10.05-1.73-16.05-4.5Z"
			                    style={{ fill: `${teamColors[HELMET_BG]}` }}
		                    />
		                </g>
		                <path id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_43_1_STROKES" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 43 1 STROKES" className={styles.cls1} d="m645.46,97.99c-8.47-6.37-16.97-10.07-25.5-11.1-8.53-1.07-14.62.82-18.25,5.65-3.63,4.83-3.73,11.2-.3,19.1,3.4,7.87,9.33,14.98,17.8,21.35,8.47,6.33,16.95,10.03,25.45,11.1,8.53,1.07,14.62-.82,18.25-5.65,3.63-4.87,3.75-11.23.35-19.1-3.43-7.9-9.37-15.02-17.8-21.35Z"/>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_44_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 44 FILL">
		                    <path
			                    className={styles.cls23}
			                    d="m583.51,75.44c-3.57-.3-7.4-.33-11.5-.1-5.4.33-9.75,1.1-13.05,2.3-8.63,5.2-14.42,13.72-17.35,25.55-2.17,8.9-2.85,20.73-2.05,35.5l15.25-1.8c1.23-12.67,4.67-24.83,10.3-36.5,5.3-10.97,11.43-19.28,18.4-24.95Z"
			                    style={{ fill: `${teamColors[HELMET_STRIPE]}`}}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_45_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 45 FILL">
		                    <path className={styles.cls15} d="m628.11,172.84c-.87-.57-1.82-.83-2.85-.8-1.07.07-1.98.45-2.75,1.15l-.55.5c-7.3-19.2-14.58-30.88-21.85-35.05-9.5-5.47-23.57-8.68-42.2-9.65-21.93-1.17-43.13,1.65-63.6,8.45-1.3.43-2.27,1.27-2.9,2.5-.6,1.23-.68,2.5-.25,3.8.43,1.3,1.27,2.27,2.5,2.9,1.23.6,2.5.68,3.8.25,18.5-6.13,37.88-8.82,58.15-8.05,17.7.67,30.88,3.48,39.55,8.45,2.17,1.27,4.55,3.95,7.15,8.05,3.73,5.93,7.6,14.38,11.6,25.35-10.6,8.93-20.82,15.7-30.65,20.3-13.93,6.6-27.9,9.42-41.9,8.45-12.9-9.57-25.77-14.32-38.6-14.25-12.87.03-25.57,4.92-38.1,14.65-1,.77-1.58,1.77-1.75,3-.17,1.23.13,2.35.9,3.35.77.97,1.77,1.53,3,1.7s2.35-.13,3.35-.9l.2-.15c6.43,11.73,11.57,24.9,15.4,39.5-1.3.17-2.33.8-3.1,1.9-.7,1.03-.93,2.15-.7,3.35.2,1.23.82,2.2,1.85,2.9,5.37,3.67,12.03,6.2,20,7.6,8,1.37,16.75,1.48,26.25.35,6.7-.8,14.2-2.13,22.5-4,11.73-2.6,20.58-5.43,26.55-8.5,7.73-3.97,16.62-11.37,26.65-22.2,10.03-10.83,20.07-23.85,30.1-39.05.43-.7.68-1.48.75-2.35.27-11.5-2.57-19.33-8.5-23.5m-2.9,10c1.6,2.7,2.4,6.7,2.4,12-8.47,12.73-16.98,23.93-25.55,33.6-8.53,9.6-16.18,16.63-22.95,21.1l-8.05-34.3c10.47-2.93,20.95-7.83,31.45-14.7,6.8-4.47,14.37-10.37,22.7-17.7m-149.1,29.95c9.33-5.93,18.68-8.78,28.05-8.55,9.33.27,18.8,3.62,28.4,10.05-6.4,15.93-8.32,32.4-5.75,49.4-13.37,1.33-24.35.05-32.95-3.85-4.3-17.8-10.22-33.48-17.75-47.05m62.45,5.4l.75.15c8.23.7,16.55.22,24.95-1.45l8.5,36.15c-4.73,2.1-11.2,4.12-19.4,6.05-6.37,1.47-12.9,2.72-19.6,3.75-2.3-15.4-.7-30.28,4.8-44.65Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_46_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 46 FILL">
		                    <path className={styles.cls18} d="m688.76,69.54c-4.83-.83-11.45-.38-19.85,1.35-3.77.77-10.32,2.45-19.65,5.05-13.6,3.83-22.15,6.38-25.65,7.65-10.23,3.7-17.73,7.93-22.5,12.7,1.53,6.83,10.52,16.37,26.95,28.6,6.6,4.9,12.63,8.83,18.1,11.8,5.67,3.1,9.18,4.27,10.55,3.5,1.97-1.1,3.25-2.02,3.85-2.75.27-.3,1.03-1.67,2.3-4.1,1.37-2.53,5.33-11.72,11.9-27.55,10-24.07,14.67-36.15,14-36.25Z"/>
		                </g>
		                <g id="FlashAICB_Assets_8_Layer_1_Clip_Group_0_0_Layer2_47_FILL" data-name="FlashAICB Assets 8 Layer 1  Clip Group  0 0 Layer2 47 FILL">
		                    <path className={styles.cls17} d="m624.41,101.89l-5.1,3,35.2,25.15,21.85-48.35c-11.23,1.8-24.12,6.2-38.65,13.2-4.5,2.2-8.93,4.53-13.3,7Z"/>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	);
};