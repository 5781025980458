export function clinicTestDashboard(tileTypeId, timeRange) {
    return generateChartData(timeRange);
}

function generateDays(numDays) {
    const days = [];
    for(let i=1; i <= numDays; i++) {
        days.push(i.toString());
    }
    return days;
}

function generateMonths(numMonths) {
    const thisMonth = new Date().getMonth();
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if(numMonths === 12) {
        return months;
    } else if (numMonths === 6) {
        return months.slice(thisMonth-6, 6);
    }
    return months.slice(0, thisMonth+1);
}

function randomBool() {
    return Math.random() >= 0.5;
}

function generateDaysData(numDays) {
    const processedData = [];
    const pendingData = [];
    const declinedData = [];

    let processed = 400;
    let pending = 150;
    let declined = 15;

    for(let i=1; i <= numDays; i++) {
        const newPending = Math.round(Math.random() * 30);
        pending += newPending;

        if(randomBool()) {
            // Processed coupons
            const newProcessed = Math.round(Math.random() * 50);
            processed += newProcessed;

            pending -= newProcessed;
            if(pending < 0) {
                pending = 0;
            }

            const newDeclined = Math.round(Math.random() * newProcessed);
            declined += newDeclined;
        }

        processedData.push(processed);
        pendingData.push(pending);
        declinedData.push(declined);
    }

    return [
        {
            name: "Processed",
            data: processedData,
        },
        {
            name: "Pending",
            data: pendingData,
        },
        {
            name: "Declined",
            data: declinedData
        }
    ]
}

function generateMonthsData(months) {
    const processedData = [];
    const pendingData = [];
    const declinedData = [];

    let processed = 400;
    let pending = 35;
    let declined = 15;

    for(let i=1; i <= months; i++) {
        const newPending = Math.round(Math.random() * 300);
        pending += newPending;

        if(randomBool()) {
            // Processed coupons
            const newProcessed = Math.round(Math.random() * 500);
            processed += newProcessed;

            pending -= newProcessed;
            if(pending < 0) {
                pending = 0;
            }

            const newDeclined = Math.round(Math.random() * newProcessed);
            declined += newDeclined;
        }

        processedData.push(processed);
        pendingData.push(pending);
        declinedData.push(declined);
    }

    return [
        {
            name: "Processed",
            data: processedData,
        },
        {
            name: "Pending",
            data: pendingData,
        },
        {
            name: "Declined",
            data: declinedData
        }
    ]
}


function generateChartData(timeRange) {
    switch (timeRange) {
        case "days30":
            return {
                labels: generateDays(30),
                type: "line",
                series: generateDaysData(30),
                lastUpdated: Date.now()
            };
        case "days90":
            return {
                labels: generateDays(90),
                type: "line",
                series: generateDaysData(90),
                lastUpdated: Date.now()
            };
        case "months6":
            return {
                labels: generateMonths(6),
                type: "line",
                series: generateMonthsData(6),
                lastUpdated: Date.now()
            };
        case "months12":
            return {
                labels: generateMonths(12),
                type: "line",
                series: generateMonthsData(12),
                lastUpdated: Date.now()
            };
        case "yearToDate":
            return {
                labels: generateMonths(),
                type: "line",
                series: generateMonthsData(),
                lastUpdated: Date.now()
            };
        default:
            return {
                labels: generateDays(30),
                type: "line",
                series: generateDaysData(30),
                lastUpdated: Date.now()
            };
    }
}
