export const PENDING = "Unprocessed";
export const APPROVED = "Approved";
export const DECLINED = "Declined";
export const REJECTED = "Rejected";

export const STATUSES = [PENDING, APPROVED, DECLINED, REJECTED];
export const NAMES = {
    [PENDING]: "Pending",
    [APPROVED]: "Approved",
    [DECLINED]: "Declined",
    [REJECTED]: "Rejected"
};
