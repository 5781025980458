import React, {useEffect, useState} from "react";
import {compose} from "redux";
import { connect } from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import orderBy from "lodash/orderBy";
import Button from "components/common/Button";
import SortableDataTable from "components/common/SortableDataTable";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import * as ProviderActions from "actions/ProviderActions";
import { addCommasToNumber } from "utils/numeric";

function CorpGroupClinicTaggingTable(props) {
    const [clinicList, setClinicList] = useState(null);

    useEffect(() => {
        if (props.nodeId && props.providerId && !clinicList) {
            props.getClinicsForNode(props.nodeId);
        }
    }, [props.nodeId, props.providerId]);

    useEffect(() => {
        setClinicList({
            ...(clinicList||{}),
            ...props.clinics
        });
    }, [props.clinics]);

    const handleGoToProductTagging = (e, clinicId) => {
        e.stopPropagation();
        e.preventDefault();
        if (props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("productTags", clinicId));
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("productTags", props.nodeId, clinicId));
        }
    }

    const COLUMNS = [
        {
            name: "Links",
            selector: "clinicId",
            key: "clinicId",
            colSettings: { width: "60px" },
            format: row => (
                <div className="flex flex-none">
                    {(props.canViewProductTagging) && (
                        <Button
                            type="success"
                            iconOnlya
                            text
                            onClick={(e) => handleGoToProductTagging(e, row.clinicId)}
                            title="Product Tagging"
                        >
                            <i className="fa fa-tag margin-right-sm"/>
                        </Button>
                    )}
                </div>
            ),
        },
        {
            name: "Clinic",
            selector: "clinicName",
            key: "clinicName",
            sortable: true,
            searchable: true,
            colSettings: {},
            format: row => (
                <div>
                    <div className="text-primary">{row.clinicName}</div>
                    <div style={{ fontSize: "0.7em" }}>{row.city}, {row.state}</div>
                </div>
            ),
        },
        // TODO: Show counts for {ProductsTagged} / Total Products
        {
            name: (<div className="text-center">Tagged Products</div>),
            selector: "tagCount",
            key: "tagCount",
            colSettings: {width: "160px"},
            format: row => (
                <div className="text-center">{addCommasToNumber(row.tagCount?.taggedCount || "--")} / {addCommasToNumber(row.tagCount?.totalProducts || "--")}</div>
            ),
            total: false,
        }
    ];

    return (
        <div className="height-100-perc" >
            <SortableDataTable
                changePagination
                striped
                green
                reverse
                header={<div/>}
                resultsPerPage={25}
                columns={COLUMNS}
                rawData={clinicList}
                onClick={props.onRowClick}
                allowSearch
                useColgroup
            />
        </div>
    );
}

CorpGroupClinicTaggingTable.propTypes = {
    providerId: PropTypes.number.isRequired,
    onRowClick: PropTypes.func,
    nodeId: PropTypes.number,
    isAdmin: PropTypes.bool,
}

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;

        // Permissions
        const canViewProductTagging = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_PRODUCT_TAGGING, userProfile);
        const canEditProductTags = userHasPermission(PermissionTypes.EDIT, UserPermissions.PROVIDER_PRODUCT_TAGGING, userProfile);

        return {
            userProfile,
            clinics: orderBy(state.entities.nodeClinics[ownProps.nodeId], ["name", "clinicId"], ["asc", "asc"]),
            search: state.entities.genericSearch,
            // Permissions
            canViewProductTagging,
            canEditProductTags,
        };
    },
    (dispatch) => ({
        getClinicsForNode: (nodeId) => dispatch(ProviderActions.getNodeClinics(nodeId))
    })
);

export default compose(
    withRouter,
    connector
)(CorpGroupClinicTaggingTable);
