import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {keys, map, filter, includes, flatMap, orderBy } from "lodash";
import VaccineTableRow from "components/admin/elements/VaccineTableRow";

function VaccineTableSection(props) {
    const [emptyRows, setEmptyRows] = useState([])

    const mappings = useMemo(() => {
        return props.mappings
    }, [props.mappings]);

    const clinicProductIds = flatMap(mappings, "clinicProductId");

    const handleAddBlankMapping = () => {
        const order = 99999 + emptyRows.length + 1;
        setEmptyRows([
            ...emptyRows,
            {
                order,
                index: !!emptyRows?.length ? (emptyRows.length + 1) : 1,
                clinicProductMappingId: null,
                clinicProductId: null,
            }
        ])
    }

    const handleCreateMapping = (index, clinicProductId) => {
        const newEmptyRows = map(emptyRows, e => {
            if(index === e.index) {
                return {
                    ...e,
                    clinicProductId: clinicProductId
                }
            } else {
                return e;
            }

        })
        setEmptyRows(newEmptyRows);
    }

    const handleDeleteMapping = (clinicProductMappingId) => {
        const newEmptyRows = filter(emptyRows, {clinicProductMappingId: clinicProductMappingId});
        setEmptyRows(newEmptyRows);
    }

    const row = (clinicProductMappingId, showProduct, index=null) => {
        return(
            <VaccineTableRow
                key={`${props.vaccineId}-${clinicProductMappingId}-${index}`}
                clinicId={props.clinicId}
                showProduct={showProduct}
                vaccineId={props.vaccineId}
                clinicProductMappingId={clinicProductMappingId}
                typeId={props.typeId}
                pimsOptions={props.pimsOptions}
                disabledPimsOptions={props.disabledPimsOptions}
                boldPimsOptions={props.boldPimsOptions}
                handleAddBlankMapping={handleAddBlankMapping}
                handleOnCreate={handleCreateMapping}
                handleOnDelete={handleDeleteMapping}
                index={index}
            />
        );
    }

    let firstRow = true;

    const filteredEmptyRows = filter(emptyRows, e => {
        return !includes(clinicProductIds, e.clinicProductId);
    })

    return (
        <>
            {/*Rows with mappings*/}
            {!!keys(mappings).length ? (
                map(orderBy(mappings, "order"), (mapping) => {
                    const showProduct = firstRow;
                    firstRow = false;
                    return (row(mapping.clinicProductMappingId, showProduct))
                })
            ) : (
                //Section with no mappings
                row(null, true)
            )}

            {/*Additional Empty Rows*/}
            {map(filteredEmptyRows, e => row(null, false, e.index))}
        </>
    )

}

VaccineTableSection.propTypes = {
    pimsOptions: PropTypes.array.isRequired,
    disabledPimsOptions: PropTypes.array.isRequired,
    boldPimsOptions: PropTypes.array.isRequired,
    clinicId: PropTypes.number.isRequired,
    vaccineId: PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
};

export default connect(
    (state, ownProps) => {
        return {
            mappings: state.entities.vaccineMappings?.[ownProps.clinicId]?.[ownProps.vaccineId]?.mappings || {},
        }
    },
) (VaccineTableSection);
