import React from "react";
import * as styles from "./FrequentlyAskedQuestions.scss";

export default function FrequentlyAskedQuestions(props) {
    return (
        <div
            data-testid="frequently_asked_questions_component"
            className={styles.root}
        >
            <ol>
                <li>
                    <h2>Is My Client Protected</h2>
                    <p>
                        Yes.
                        The reporting Greenline provides to manufacturers does not contain any individual clinic and/or pet information.
                    </p>
                </li>
                <li>
                    <h2>What is the Market Share data used for?</h2>
                    <p>
                        Pet health manufacturers use market share data to illuminate trends and to boost effectiveness of sales and marketing efforts.
                    </p>
                </li>
            </ol>
        </div>
    )
}
