import React from "react";
import PropTypes from "prop-types";
import produce from "immer";
import filter from "lodash/filter";
import map from "lodash/map";
import Button from "components/common/Button";
import PlanOptionDetails from "./PlanOptionDetails";

export default function PlanOptions(props) {
    const handleSelectedPlan = (plan, id) => {
        const updatedList = produce(props.plans, updatedData => {
            if(plan) {
                updatedData[id] = plan
            }
            else {
                delete updatedData[id];
            }
        });

        props.onChange({ name: "plans", value: updatedList });
    };

    const handleAddOnsSelected = (data, id) => {
        const updatedList = produce(props.addOns, updatedData => {
            if(data) {
                updatedData[id] = data
            } else {
                delete updatedData[id];
            }
        });

        props.onChange({ name: "addOns", value: updatedList });
    };

    const handleGoNext = (e) => {
        e.stopPropagation();
        e.preventDefault();
        props.onNext();
    }
    const numberOfPatients = props.patients?.length;
    const numberOfPlans = filter(props.plans, s => !!s)?.length;

    return (
        <form onSubmit={handleGoNext}>
            {map(props.patients, (patient) => {
                const id = patient.patientId || patient.remoteId;
                return (
                    <PlanOptionDetails
                        key={id}
                        patient={patient}
                        allPlans={props.allPlans}
                        plan={props.plans?.[id] || {}}
                        onChange={(data) => handleSelectedPlan(data, id)}
                        addOns={props.addOns?.[id] || {}}
                        onUpdateAddOns={(data) => handleAddOnsSelected(data, id)}
                        includeInvalid={props.includeInvalid}
                    />
                );
            })}
            <div className="flex spaced-content">
                <div className="flex-1">
                    <Button type="primary" onClick={props.onBack}>
                        Back
                    </Button>
                </div>
                <div className="flex-none">
                    <Button
                        buttonType="submit"
                        disabled={!props.isValid || numberOfPatients !== numberOfPlans}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </form>
    );
};

PlanOptions.defaultProps = {
    addOns: {},
    allPlans: {},
    errors: [],
    includeInvalid: false,
    isValid: true,
}

PlanOptions.propTypes = {
    addOns: PropTypes.object,
    allPlans: PropTypes.object,
    includeInvalid: PropTypes.bool,
    isValid: PropTypes.bool,
    onBack: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
    patients: PropTypes.arrayOf(PropTypes.object).isRequired,
    plans: PropTypes.object.isRequired,
};
