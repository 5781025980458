import React from "react";

export default function ArrowIcon(props) {
    return (
        <svg
            version="1.1"
            id="arrow"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 365 279"
        >
            <g>
                <path d="M365.59,181.34c-31.77,24.82-59.24,56.74-74.18,94.94c-16.01-37.48-46.61-66.68-78.49-91.4
                    c18.28,1.04,39.36,4.14,57.1,12.16c-4.06-25.2-11.95-49.94-23.66-72.97c-15.03-29.53-36.19-56.44-62.74-76.94
                    c-13.26-10.23-27.86-18.8-43.49-24.93c-7.82-3.07-15.88-5.52-24.13-7.21c-8.24-1.67-16.65-2.58-25.1-2.64
                    c-16.89-0.19-33.91,3.39-49.57,10.51C33.5,26.41,26.01,30.82,19,35.95C11.99,41.09,5.45,46.93-0.59,53.28
                    c5.46-6.85,11.51-13.29,18.15-19.12c6.65-5.82,13.91-11.01,21.68-15.39c7.78-4.38,16.07-7.95,24.69-10.58
                    c8.61-2.67,17.57-4.28,26.62-5.02c9.04-0.74,18.18-0.55,27.24,0.47c9.05,1.05,18.01,2.93,26.77,5.5
                    c17.53,5.16,34.2,13.08,49.61,22.94c15.41,9.87,29.58,21.68,42.32,34.89c12.75,13.22,24.07,27.84,33.85,43.5
                    c16.61,26.61,28.6,56.26,35.51,87.24C323.88,187.61,347.64,182.27,365.59,181.34z"/>
            </g>
        </svg>
    );
}
