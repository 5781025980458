import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import find from "lodash/find";
import SortableDataTable from "components/common/SortableDataTable";
import styles from "./ClinicTable.scss";
import getLocalTimezone from "utils/getLocalTimezone";
import Modal from "components/common/Modal";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import * as WellnessApi from "api/WellnessApi";
import * as AdminActions from "actions/AdminActions";
import WellnessReprocessingRequestForm from "components/admin/widgets/WellnessReprocessingRequestForm";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import WellnessReprocessingDetails from "./WellnessReprocessingDetails";

function ReprocessWellnessTable(props) {
    const [reprocessingJobs, setReprocessingJobs] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [reprocessRequestId, setReprocessRequestId] = useState(null);
    const filteredReprocessingJobs = reprocessingJobs

    useEffect(() => {
        setIsLoading(true);
        WellnessApi.loadWellnessReprocessing()
            .then(res => {
                setIsLoading(false);
                setReprocessingJobs(res.body);
                setIsLoading(false);
            })
            .catch(error => {});
    }, []);

    const handleRowClicked = (row) => {
        setReprocessRequestId(row.reprocessRequestId);
    }

    const handleAddJob = (clinicId) => {
        WellnessApi.reprocessClinicWellnessJobs(clinicId)
            .then(res => {
                setIsLoading(false);
            })
            .catch(error => {
                handleErrorResponse("reprocessing wellness jobs", error);
                setIsLoading(false);
            });
        props.hideAddForm();
    }

    const COLUMNS = [{
        name: "Request ID",
        selector: "reprocessRequestId",
        key: "reprocessRequestId",
        sortable: true,
        searchable: true,
    }, {
        name: "Clinic",
        selector: "clinicName",
        key: "clinicName",
        sortable: true,
        searchable: true,
        format: row => <div className="nowrap">{row.clinicName} ({row.clinicId})</div>
    }, {
        name: "Criteria",
        selector: "invoiceStartDate",
        key: "invoiceStartDate",
        sortable: true,
        searchable: true,
        format: row => <div>Invoice Dates: {getLocalTimezone(row.invoiceStartDate, "MM/DD/YYYY")}{row.invoiceEndDate && ` - ${getLocalTimezone(row.invoiceEndDate, "MM/DD/YYYY")}`}</div>
    }, {
        name: "Created By",
        selector: "createUser",
        key: "createUser",
        sortable: true,
        searchable: true,
        format: row => <div className="nowrap">{row.createUser}</div>
    }, {
        name: "Created",
        selector: "createDate",
        key: "createDate",
        sortable: true,
        format: row => <div className="nowrap">{row.createDate ? getLocalTimezone(row.createDate, 'MM/DD/YYYY LTS') : null}</div>,
    }, {
        name: "Processed",
        selector: "processedDate",
        key: "processedDate",
        sortable: true,
        format: row => <div className="nowrap">{row.processDate ? getLocalTimezone(row.processDate, 'MM/DD/YYYY LTS') : "Queued"}</div>,
    }];

    const getRowData = reprocessRequestId => {
        return find(filteredReprocessingJobs, entry => entry.reprocessRequestId === reprocessRequestId);
    }

    const rowData = getRowData(reprocessRequestId) || null;

    return (
        <div className={styles.root}>
            {filteredReprocessingJobs.length ? (
                <>
                    <SortableDataTable
                        allowSearch
                        hideSearchBar
                        green
                        striped
                        columns={COLUMNS}
                        rawData={filteredReprocessingJobs}
                        resultsPerPage={20}
                        onClick={handleRowClicked}
                        search={props.search}
                    />
                </>
            ) : <SpinnerTakeover show />}

            <Modal
                show={!!reprocessRequestId}
                onClose={() => setReprocessRequestId(null)}
                modalTitle={`${rowData ? rowData.clinicName : ""}`}
                mediumSmall
            >
                <WellnessReprocessingDetails wellnessReprocessing={rowData} />
            </Modal>

            <Modal
                show={props.addNewForm}
                onClose={() => props.hideAddForm()}
                modalTitle="Wellness Reprocessing Request"
                medium
            >
                {props.canEditWellnessReprocessing ? (
                    <WellnessReprocessingRequestForm
                        onSubmit={handleAddJob}
                        onCancel={() => props.hideAddForm()}
                    />
                ) : (
                    <AccessDenied/>
                )}

            </Modal>
            <SpinnerTakeover show={isLoading} />
        </div>
    );
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canEditWellnessReprocessing = userHasPermission(PermissionTypes.EDIT, UserPermissions.WELLNESS_REPROCESS, userProfile);
        return {
            canEditWellnessReprocessing,
            search: state.entities.reprocessingJobs.query,
            addNewForm: state.entities.adminTools.addNewForm || false,
        }
    },
    (dispatch) => ({
        hideAddForm: () => dispatch(AdminActions.hideAddForm()),
    })
)(ReprocessWellnessTable);
