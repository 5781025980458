import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as ClinicActions from "actions/ClinicActions";
import Button from "components/common/Button";
import PriceWatchTable from "components/reports/widgets/PriceWatchTable";
import PriceWatchTiles from "components/reports/widgets/PriceWatchTiles";

function PriceWatchDashboard(props) {
    const [showTable, setShowTable] = useState(false);

    if(!props.hasPriceWatch) {
        return null;
    }

    useEffect(() => {
        props.getExternalPrices();
        props.getFavoriteBrands();
    }, []);

    const handleClick = () => {
        setShowTable(!showTable);
    }

    return (
        <div>
            <div className="flex justify-flex-end margin-bottom-sm">
                <Button onClick={handleClick} type="primary">
                    Show {showTable ? "Tiles" : "Table"}
                </Button>
            </div>
            {showTable ? (
                <PriceWatchTable
                    clinicId={props.clinicId}
                />
            ) : (
                <PriceWatchTiles
                    clinicId={props.clinicId}
                />
            )}
        </div>
    );
}

PriceWatchTable.propTypes = {
    clinicId: PropTypes.number.isRequired,
};

export default connect(
    (state, ownProps) => {
        return {
            // Permissions
            // TODO: uncomment once the clinic permission is ready
            // hasPriceWatch: ClinicData.clinicHasPriceWatch(state, ownProps.clinicId),
            hasPriceWatch: true
        }
    },
    (dispatch) => ({
        getExternalPrices: () => dispatch(ClinicActions.getExternalPrices()),
        getFavoriteBrands: () => dispatch(ClinicActions.getFavoriteBrands()),
    })
)(PriceWatchDashboard);
