import React from "react";
import PropTypes from "prop-types";
import styles from "./ProgramsList.scss";
import map from "lodash/map";
import ProgramDashboardTile from "./ProgramDashboardTile";
import ProgramServiceDashboardTile from "./ProgramServiceDashboardTile";

export default function ProgramsList(props) {
    return (
        <div className={styles.root}>
            {!props.programs?.length && !props.programServices?.length ? (
                <div className={styles.noNotifications}>No available programs at this time.</div>
            ) : (
                <>
                    {map(props.programs, program => (
                        <ProgramDashboardTile
                            key={program.programId}
                            program={program}
                            onButtonClick={props.programClickHandler}
                        />
                    ))}
                    {map(props.programServices, programService  => (
                        <ProgramServiceDashboardTile
                            key={programService.programServiceId}
                            programService={programService}
                            onButtonClick={props.programServiceClickHandler}
                        />
                    ))}
                </>
            )}
        </div>
    );
}

ProgramsList.propTypes = {
    programClickHandler: PropTypes.func,
    programServiceClickHandler: PropTypes.func,
    programs: PropTypes.array,
    programServices: PropTypes.array,
};
