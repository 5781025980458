import React, { useState, useEffect } from "react";
import * as Sentry from "@sentry/react";
import { connect } from "react-redux";
import { compose } from "redux";
import { useHistory, withRouter } from "react-router";
import PropTypes from "prop-types";
import styles from "./AdminClinicSettingsForm.scss";
import concat from "lodash/concat";
import drop from "lodash/drop";
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import get from "lodash/get";
import includes from "lodash/includes";
import isArray from "lodash/isArray";
import isObject from "lodash/isObject";
import keyBy from "lodash/keyBy";
import map from "lodash/map";
import merge from "lodash/merge";
import mergeWith from "lodash/mergeWith";
import reject from "lodash/reject";
import set from "lodash/set";
import * as AdminApi from "api/AdminApi";
import * as ClinicApi from "api/ClinicApi";
import * as ClinicActions from "actions/ClinicActions";
import * as AdminActions from "actions/AdminActions";
import * as UserActions from "actions/UserActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import ChooseHospital from "components/admin/elements/ChooseHospital";
import ClinicTags from "components/admin/widgets/ClinicTags";
import CorporateGroupDropdown from "components/registration/elements/CorporateGroupDropdown";
import DataTable from "components/common/datatable/DataTable";
import DateInput from "components/common/DateInput";
import Dropdown from "components/common/Dropdown";
import HeroMessage from "components/notifications/elements/HeroMessage";
import InstallationCode from "components/admin/elements/InstallationCode";
import Modal from "components/common/Modal";
import PIMSFormContainer from "components/admin/forms/PIMSFormContainer";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import TextBox from "components/common/TextBox";
import Tooltip from "components/common/Tooltip";
import { daysFromNow } from "utils/DateTransformations";
import getLocalTimezone from "utils/getLocalTimezone";
import LocalData from "utils/LocalData";
import logger from "utils/logger";
import {handleErrorResponse,handleSuccessResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {countriesDropdownList} from "constants/Countries";
import {usStatesDropdownList} from "constants/USStates";
import * as UserPermissions from "constants/UserPermissions";
import * as UserRoles from "constants/UserRoles";

function AdminClinicSettingsForm(props) {
    const history = useHistory();
    const [passwordError, setPasswordError] = useState('');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({});
    const [clinicTagList, setClinicTagList] = useState([]);
    const [showConfigurePIMS, setShowConfigurePIMS] = useState(false);
    const [selectedClinicForHospitalAssignment, setSelectedClinicForHospitalAssignment] = useState(false);
    const [errors, setErrors] = useState([]);
    const requiredClinicFields = ["name", "street1", "countryCode", "city", "state", "zipCode", "contactEmail", "contactPhone"];
    const requiredBillingFields = ["billingAddress.street1", "billingAddress.countryCode", "billingAddress.city", "billingAddress.stateProvince", "billingAddress.postalCode"];

    const CLINIC_ADMIN_OPTIONS = map(props.clinicUsers, u => {return {name: `${u.firstName} ${u.lastName} (${u.userName})`, value: u.userId}});

    const combinedData = {
        ...props.clinic,
        reps: props.reps,
        newPassword: ''
    };

    const custom = (objValue, srcValue) => {
      if (isArray(objValue)) {
          let updated = map(objValue, (li, index) => {
              if(isObject(li)) {
                  return merge(li, srcValue[index]);
              } else {
                  return srcValue?.[index] || li;
              }
          });
          if(srcValue.length > objValue.length) {
              const newItems = drop(srcValue, objValue.length);
              updated = concat(updated, newItems);
          }
          return updated;
      }
    }
    mergeWith(combinedData, formData, custom);

    useEffect(() => {
        props.getProviderLocations(props.clinicId, false);
    }, []);

    useEffect(() => {
        if(props.canViewUserManagement) {
            props.getClinicUsers(props.clinicId);
        }
        if(props.canViewClinicManagement) {
            props.getClinicReps(props.clinicId);
            props.getClinicHospitals(props.clinicId);
        }
    }, [props.canViewClinicManagement, props.canViewUserManagement, props.clinicId]);

    useEffect(() => {
        if(props.clinic?.tags) {
            // Don't set tags if the tags are null
            setClinicTagList(props.clinic.tags);
        }
    }, [props.clinic]);

    const isValid = (data) => {
        const newErrors = [];
        forEach(requiredClinicFields, rf => {
            if(!get(data, rf)) {
                newErrors.push(rf);
            }
        });

        // Validate Billing Address
        if(data.billingAddress.isActive) {
            forEach(requiredBillingFields, rf => {
                if(!get(data, rf)) {
                    newErrors.push(rf);
                }
            });
        }

        //TODO: If Shipping Address is included in the future
        // Validate Shipping Address
        // if(data.shippingAddress.isActive) {
        //     forEach(requiredShippingFields, rf => {
        //         if(!get(data, rf)) {
        //             newErrors.push(rf);
        //         }
        //     });
        // }

        setErrors(newErrors);
        return !newErrors.length;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(props.canEditClinicManagement) {
            // Prevent errors (bug was making the
            if (clinicTagList?.length) {
                props.updateClinicTags(props.clinicId, clinicTagList);
            } else {
                props.updateClinicTags(props.clinicId, []);
            }

            if (isValid(combinedData)) {
                props.updateClinic({
                    ...combinedData,
                    id: props.clinicId,
                    billingAddress: {
                        ...combinedData.billingAddress,
                        createUserId: !!combinedData.clinicAddressId ? combinedData.billingAddress?.createUserId : props.userProfile.id,
                        modDate: !!formData.billingAddress ? combinedData.billingAddress?.createDate : new Date(),
                        modUserId: !!formData.billingAddress ? combinedData.billingAddress?.modUserId : props.userProfile.id,
                    },
                    shippingAddress: {
                        ...combinedData.shippingAddress,
                        createUserId: !!combinedData.clinicAddressId ? combinedData.shippingAddress?.createUserId : props.userProfile.id,
                        modDate: !!formData.shippingAddress ? combinedData.shippingAddress?.createDate : new Date(),
                        modUserId: !!formData.shippingAddress ? combinedData.shippingAddress?.modUserId : props.userProfile.id,
                    }
                });
                history.push("/admin/clinics");
            }
        }
    };

    const handleDelete = () => {
        if(props.canDeleteClinicRecord) {
            if (confirm(`Are you sure you want to Delete ${props.clinic.name} (${props.clinicId}).`)) {
                props.deleteClinic(props.clinicId);
                history.push("/admin/clinics");
            }
        }
    };

    const handleCancel = () => {
        history.push("/admin/clinics");
    };

    const handleSubmitPIMS = (data) => {
        if (props.canEditClinicManagement) {
            const newData = {
                ...data,
                config: data.config ? JSON.stringify(data.config) : ""
            }
            props.updateClinicPIMS(newData);
            if(newData.softwareVendorName) {
                props.generateInstallCode(props.clinicId);
            }
        }
        setShowConfigurePIMS(false);
    }

    const handleFormChange = ({name, value}) => {
        let newData = {
            ...formData,
        };

        if ("excludedCouponClientIdList" === name || "excludedReportingClientIdList" === name || "excludedEmailClientIdList" === name) {
            const newList = value.split(",");
            set(newData, name, newList);
        } else if ("clinicAdminUserId" === name){
            set(newData, name, value);
            const newAdminUser = find(props.clinicUsers, {userId: value});
            set(newData, "clinicAdminUserName", newAdminUser.userName);
            set(newData, "userName", newAdminUser.userName);
        } else {
            set(newData, name, value);
        }
        setFormData(newData);
    };

    const handleConfigure = () => {
        setShowConfigurePIMS(true);
    }

    const handleAddTag = (newTag) => {
        if(props.canEditClinicManagement) {
            setLoading(true);
            ClinicApi.createClinicTag(newTag)
                .then((res) => {
                    props.tagCreated(res.body);
                    handleTagClicked({name: "tags", value: res.body})
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("creating clinic tag", error);
                    logger.error(error);
                });
        }
    };

    const handleTagClicked = ({value}) => {
        let newTagList = [...clinicTagList];
        if(!!includes(clinicTagList, value.id)) {
            newTagList = reject(newTagList, t => (t === value.id));
        } else {
            newTagList.push(value.id);
        }

        setClinicTagList(newTagList)
    };

    const handleImpersonate = (row) => {
        if (props.canImpersonateAccount) {
            props.setRecentUser({
                id: row.userId,
                username: row.userName,
                fullName: `${row.firstName} ${row.lastName}`,
                clinicId: null,
                locationType: null,
                territoryCode: row.territoryCode,
                timeStamp: new Date()
            });
            Sentry.setContext("impersonating", {
                originalUserId: props.userProfile.id,
                originalUserRoles: props.userProfile.roles,
            });
            props.setUserUnknown(); // This clears the current user from state.
            // Sentry.captureMessage("Impersonation Initiated");
            AdminApi.impersonateUser(row.userName)
                .then(res => {
                    LocalData.setToken(res.body.token);
                    props.impersonateUser(res.body.token);
                    history.push("/");
                })
                .catch(error => {
                    handleErrorResponse("attempting to impersonate", error);
                    setLoading(false);
                });
        }
    }

    const handlePasswordReset = async () => {
        setPasswordError('')

        try {
            await ClinicApi.resetAdminPassword(props.clinicId, combinedData.clinicAdminUserId, combinedData.newPassword)
            handleSuccessResponse('Password Reset')
        }
        catch (e) {
            handleErrorResponse('Password Reset Failed', e)
            if(e.message === 'Bad Request') {
                setPasswordError(`Password not reset. Check complexity requirements.`)
            }
        }
    }

    const handlePasswordExtensions = async() => {
        const newExpirationDate = daysFromNow(7)

        try {
            await ClinicApi.extedAdminPasswordExpiration(props.clinicId, combinedData.clinicAdminUserId, newExpirationDate)
            handleSuccessResponse('Password Updated')
        }
        catch (e) {
            handleErrorResponse('Extend Password Validity Failed', e)
        }
    }

    const REP_COLUMNS = [{
        name: "Provider Name",
        selector: "providerName",
        key: "providerName",
    }, {
        name: "Territory Code",
        selector: "territoryCode",
        key: "territoryCode",
    }, {
        name: "Rep Name",
        selector: "firstName",
        key: "firstName",
        format: row => (
            <div className="nowrap">{row.firstName} {row.lastName}</div>
        ),
    }, {
        name: "Username",
        selector: "userName",
        key: "userName",
    }, {
        name: "ID",
        selector: "userId",
        key: "userId",
    }, {
        name: "actions",
        selector: "actions",
        key: "actions",
        format: row => (
            <Button
                title="Impersonate User"
                type="primary"
                className="margin-left-sm"
                iconOnly
                text
                onClick={() => handleImpersonate(row)}
            >
                <i className="fa fa-eye"/>
            </Button>
        ),
    }];

    const assignHospital = (updatedHospital) => {
        if(props.canAssignHospital) {
            props.assignHospital(updatedHospital);
        }
    }

    const handleAssignHospital = ({id, name}) => {
        const newClinicForHospitalAssignment = {
            ...selectedClinicForHospitalAssignment,
            targetClinicId: id,
            targetClinicName: name,
            canAssign: false,
            canUnassign: true
        };
        assignHospital(newClinicForHospitalAssignment);
        setSelectedClinicForHospitalAssignment(null);
    }
    
    const handleUnassignHospital = (hospital) => {
        const updatedHospital = {
            ...hospital,
            targetClinicId: hospital.gatewayClinicId,
            targetClinicName: hospital.gatewayClinicName,
            canAssign: true,
            canUnassign: false
        };
        assignHospital(updatedHospital);
    }

    const HOSPITAL_COLUMNS = [{
        name: (
            <div className="text-center">
                <div>Hospital ID</div>
                <div>(Remote)</div>
            </div>
        ),
        selector: "remoteClinicHospitalID",
        key: "remoteClinicHospitalID",
        format: row => (
            <div className="text-center">{row.remoteClinicHospitalID}</div>
        ),
    }, {
        name: (
            <div className="text-center">
                <div>Hospital ID</div>
                <div>(Greenline)</div>
            </div>
        ),
        selector: "hospitalId",
        key: "hospitalId",
        format: row => (
            <div className="text-center">{row.hospitalId}</div>
        ),
    }, {
        name: "Hospital Name",
        selector: "hospitalName",
        key: "hospitalName",
        format: row => (
            <div className="nowrap">{row.hospitalName}</div>
        ),
    }, {
        name: "Identifier",
        selector: "identifier",
        key: "identifier",
        format: row => (
            <div className="nowrap">{row.identifier}</div>
        ),
    }, {
        name: (<div className="text-center">Invoices</div>),
        selector: "invoiceCount",
        key: "invoiceCount",
        format: row => (
            <div className="text-center">{row.invoiceCount}</div>
        ),
    }, {
        name: (
            <div className="text-center">
                <div>Clinic ID</div>
                <div>(Assigned To)</div>
            </div>
        ),
        selector: "gatewayClinicId",
        key: "gatewayClinicId",
        format: row => (
            <div className="text-center">
                {row.gatewayClinicId === row.targetClinicId ? row.gatewayClinicId : row.targetClinicId}
            </div>
        )
    }, {
        name: "Hospital ID (Assigned To)",
        selector: "gatewayClinicName",
        key: "gatewayClinicName",
        format: row => (
            (row.gatewayClinicId === row.targetClinicId) ? (
                <Tooltip position="left" tip="Gateway Clinic">
                    <div className="no-wrap">
                        <i className="fas fa-hospitals"/> {row.targetClinicName}
                    </div>
                </Tooltip>
            ) : (
                <div className="no-wrap">{row.targetClinicName}</div>
            )
        ),
    }, {
        name: "",
        selector: "assign",
        key: "assign",
        format: row => props.canAssignHospital ? (
            <div>
                {row.canAssign && (
                    <Button onClick={() => setSelectedClinicForHospitalAssignment(row)}>
                        Assign
                    </Button>
                )}
                {row.canUnassign && (
                    <Button onClick={() => handleUnassignHospital(row)}>
                        Unassign
                    </Button>
                )}
                {!(row.canAssign || row.canUnassign) && "Data Cannot be Moved"}
            </div>
        ) : "",
    }];

    const hasHospitals = !!props.hospitals && Object.keys(props.hospitals).length;
    const hasMultipleHospitals = !!props.hospitals && Object.keys(props.hospitals).length > 1;

    return (
        <form onSubmit={handleSubmit} className={styles.root}>
            { hasMultipleHospitals && (
                <HeroMessage
                    id={"multiclinics"}
                    type={"warning"}
                    label={"THIS CLINIC MAY HAVE MULTIPLE LOCATIONS UNDER ONE DATABASE!"}
                    description={"Please check clinic section on clinic info page."}
                    medium
                />
            )}
            <div className="flex flex-column">
                <div className="flex spaced-content">
                    <div className="flex-1">
                        <TextBox
                            onChange={() => {}}
                            name="clinicId"
                            value={combinedData.clinicId}
                            label="Clinic ID"
                            disabled
                        />
                    </div>
                    {/*TODO: Update icon and text to reflect user needs*/}
                    {combinedData.gatewayClinicId && combinedData.gatewayClinicId !== combinedData.clinicId ? (
                        <div
                            title={`Clinic is a child clinic of ${props.gatewayClinicName} (${combinedData.gatewayClinicId})`}
                            className="no-wrap margin-bottom-x-sm flex-none"
                        >
                            <i className="fa-2x fas fa-clinic-medical"/>
                        </div>
                    ) : (combinedData.gatewayChildren && combinedData.gatewayChildren.length) ?  (
                        <div
                            title="Gateway clinic"
                            className="no-wrap margin-bottom-x-sm flex-none"
                        >
                            <i className="fa-2x fas fa-hospitals"/>
                        </div>
                    ) : null}
                    <div className="flex-2">
                        <TextBox
                            onChange={() => {}}
                            name="clinicGuid"
                            value={combinedData.clinicGuid}
                            label="Clinic GUID"
                            disabled
                        />
                    </div>
                </div>
                <TextBox
                    onChange={() => {}}
                    name="agentVersion"
                    value={combinedData.agentVersion && `${combinedData.agentVersion}, Installation: ${combinedData.agentInstallationDate ? combinedData.agentInstallationDate : "not yet"}`}
                    label="Agent"
                    disabled
                    // If the agent versions is older than 10.0 then mark as hasError
                    hasError={!!combinedData.agentVersion ? (Number(combinedData.agentVersion.split(".")[0]) < 10 && Number(combinedData.agentVersion.split(".")[0]) !== 1) : false}
                />
                <div className="flex spaced-content">
                    <TextBox
                        label="Due for Password Reset"
                        name="needsPasswordChange"
                        value={combinedData.needsPasswordChange ? "Yes" : "No"}
                        onChange={() => {}}
                        disabled
                        hasError={combinedData.needsPasswordChange}
                    />
                    {combinedData?.hasTokenCallback !== null && (
                        <TextBox
                            label="Received Token"
                            name="hasTokenCallback"
                            value={combinedData.hasTokenCallback ? "Yes" : "No"}
                            onChange={() => {}}
                            disabled
                            hasError={!combinedData.hasTokenCallback}
                        />
                    )}
                </div>
                <hr className="margin-top-md margin-bottom-md"/>
                <div className={"flex spaced-content flex-end"}>
                    <TextBox
                        onChange={handleFormChange}
                        name="pimsSoftware"
                        value={combinedData.pimsSoftware}
                        label="PIMS"
                        disabled
                    />
                    <div className="flex-none flex flex-end margin-bottom-xx-sm">
                        <Button
                            onClick={handleConfigure}
                            className={"margin-bottom-xx-sm"}
                            disabled={!props.canViewClinicManagement}
                        >
                            Configure
                        </Button>
                    </div>
                </div>
                <TextBox
                    label="Server Name"
                    name="lastSyncServerName"
                    value={combinedData.lastSyncServerName}
                    onChange={() => {}}
                    disabled
                />
                <div className="flex spaced-content">
                    <DateInput
                        label="Last Sync Date"
                        name="lastSyncDate"
                        value={combinedData.lastSyncDate}
                        onChange={() => {}}
                        disabled
                    />
                    <DateInput
                        label="Last Product Sync Date"
                        name="lastProductPullDate"
                        value={combinedData.lastProductPullDate}
                        onChange={() => {}}
                        disabled
                        // hasError if out of date
                        // hasError={}
                    />
                    <DateInput
                        label="Last Query Date"
                        name="lastQueryDate"
                        value={combinedData.lastQueryDate}
                        onChange={() => {}}
                        disabled
                        // hasError if out of date
                        // hasError={}
                    />
                </div>
                <hr className="margin-top-md margin-bottom-md"/>
                <InstallationCode
                    clinicId={props.clinicId}
                    value={combinedData.installationAccess?.accessCode || ""}
                    expirationDate={combinedData.installationAccess?.expirationDate}
                />

                <a
                    title="Link to Salesforce"
                    href={`https://na159.lightning.force.com/lightning/r/Account/${combinedData.salesforceAccountId}/view`}
                    target="_blank"
                    className="pointer text-primary"
                >
                    <TextBox
                        label="Salesforce ID"
                        name="salesforceAccountId"
                        value={combinedData.salesforceAccountId}
                        onChange={() => {}}
                        disabled
                        style={{cursor: "pointer", color: "#1E86C8"}}
                        labelStyle={{cursor: "pointer"}}
                    />
                </a>
            </div>
            <div className="margin-top-md margin-bottom-md">
                <ClinicTags
                    clinicId={props.clinicId}
                    clinicTagList={keyBy(map(clinicTagList, c => {return {value: c, id: c}}), "id")}
                    onTagAdd={handleAddTag}
                    onTagClicked={handleTagClicked}
                />
            </div>
            <div className="margin-bottom-md">
                <TextBox
                    onChange={handleFormChange}
                    name="name"
                    value={combinedData.name}
                    label="Name"
                    required
                    disabled={!props.canEditClinicManagement}
                />
                <TextBox
                    onChange={handleFormChange}
                    name="clinicBillingName"
                    value={combinedData.clinicBillingName}
                    label="Billing Name"
                    maxLength={48}
                />
            </div>
            <div className="flex spaced-content">
                <div className={styles.flex1}>
                    <div className="flex spaced-content align-center">
                        <h3>Street Address</h3>
                        <div>
                            <CheckboxInput
                                onChange={handleFormChange}
                                name="billingAddress.isActive"
                                checked={combinedData.billingAddress.isActive}
                                label="Different billing address?"
                                disabled={!props.canEditClinicManagement}
                            />
                            {/*<CheckboxInput*/}
                            {/*    onChange={handleFormChange}*/}
                            {/*    name="shippingAddress.isActive"*/}
                            {/*    checked={combinedData.shippingAddress.isActive}*/}
                            {/*    label="Different shipping address?"*/}
                            {/*    disabled={!props.canEditClinicManagement}*/}
                            {/*/>*/}
                        </div>
                    </div>
                    <TextBox
                        onChange={handleFormChange}
                        name="street1"
                        value={combinedData.street1}
                        label="Street 1"
                        required
                        hasError={includes(errors, "street1")}
                        disabled={!props.canEditClinicManagement}
                    />
                    <TextBox
                        onChange={handleFormChange}
                        name="street2"
                        value={combinedData.street2}
                        label="Street 2"
                        disabled={!props.canEditClinicManagement}
                    />
                    <div className="flex flex-wrap spaced-content">
                        <div className={styles.flex1}>
                            <Dropdown
                                onChange={handleFormChange}
                                name="countryCode"
                                value={combinedData.countryCode}
                                placeholder="Select a Country"
                                label="Country"
                                options={countriesDropdownList}
                                required
                                hasError={includes(errors, "countryCode")}
                                disabled={!props.canEditClinicManagement}
                            />
                        </div>
                        <div className={styles.flex1}>
                            <TextBox
                                onChange={handleFormChange}
                                name="city"
                                value={combinedData.city}
                                label="City"
                                required
                                hasError={includes(errors, "city")}
                                disabled={!props.canEditClinicManagement}
                            />
                        </div>
                        <div className={styles.flex1}>
                            <Dropdown
                                onChange={handleFormChange}
                                name="state"
                                value={combinedData.state}
                                placeholder="Select a State"
                                label="State"
                                options={usStatesDropdownList}
                                required
                                hasError={includes(errors, "state")}
                                disabled={!props.canEditClinicManagement}
                            />
                        </div>
                        <div className={styles.flex1}>
                            <TextBox
                                onChange={handleFormChange}
                                name="zipCode"
                                value={combinedData.zipCode}
                                label="Postal Code"
                                required
                                hasError={includes(errors, "zipCode")}
                                disabled={!props.canEditClinicManagement}
                            />
                        </div>
                    </div>
                </div>
                {/*<hr className="margin-top-md margin-bottom-md"/>*/}
                {/*<div className="flex spaced-content">*/}
                {combinedData.billingAddress.isActive && (
                    <div className="flex-1 border-left">
                        <h3>Billing Address</h3>
                        <TextBox
                            onChange={handleFormChange}
                            name="billingAddress.street1"
                            value={combinedData.billingAddress.street1}
                            label="Street 1"
                            required
                            hasError={includes(errors, "billingAddress.street1")}
                            disabled={!props.canEditClinicManagement}
                        />
                        <TextBox
                            onChange={handleFormChange}
                            name="billingAddress.street2"
                            value={combinedData.billingAddress.street2}
                            label="Street 2"
                            disabled={!props.canEditClinicManagement}
                        />
                        <div className="flex spaced-content flex-wrap">
                            <div className={styles.flex1}>
                                <Dropdown
                                    onChange={handleFormChange}
                                    name="billingAddress.countryCode"
                                    value={combinedData.billingAddress.countryCode}
                                    placeholder="Select a Country"
                                    label="Country"
                                    required
                                    hasError={includes(errors, "billingAddress.countryCode")}
                                    options={countriesDropdownList}
                                    disabled={!props.canEditClinicManagement}
                                />
                            </div>
                            <div className={styles.flex1}>
                                <TextBox
                                    onChange={handleFormChange}
                                    name="billingAddress.city"
                                    value={combinedData.billingAddress.city}
                                    label="City"
                                    required
                                    hasError={includes(errors, "billingAddress.city")}
                                    disabled={!props.canEditClinicManagement}
                                />
                            </div>
                            <div className={styles.flex1}>
                                <Dropdown
                                    onChange={handleFormChange}
                                    name="billingAddress.stateProvince"
                                    value={combinedData.billingAddress.stateProvince}
                                    placeholder="Select a State"
                                    label="State"
                                    required
                                    hasError={includes(errors, "billingAddress.stateProvince")}
                                    options={usStatesDropdownList}
                                    disabled={!props.canEditClinicManagement}
                                />
                            </div>
                            <div className={styles.flex1}>
                                <TextBox
                                    onChange={handleFormChange}
                                    name="billingAddress.postalCode"
                                    value={combinedData.billingAddress.postalCode}
                                    label="Postal Code"
                                    required
                                    hasError={includes(errors, "billingAddress.postalCode")}
                                    disabled={!props.canEditClinicManagement}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/*{combinedData.billingAddress.isActive && (*/}
            {/*    <hr className="margin-top-md margin-bottom-md"/>*/}
            {/*        <div className="flex-1 border-left">*/}
            {/*            <h3>Shipping Address</h3>*/}
            {/*            <TextBox*/}
            {/*                onChange={handleFormChange}*/}
            {/*                name="shippingAddress.street1"*/}
            {/*                value={combinedData.shippingAddress.street1}*/}
            {/*                label="Street 1"*/}
            {/*                required*/}
            {/*                hasError={includes(errors, "shippingAddress.street1")}*/}
            {/*                disabled={!props.canEditClinicManagement}*/}
            {/*            />*/}
            {/*            <TextBox*/}
            {/*                onChange={handleFormChange}*/}
            {/*                name="shippingAddress.street2"*/}
            {/*                value={combinedData.shippingAddress.street2}*/}
            {/*                label="Street 2"*/}
            {/*                disabled={!props.canEditClinicManagement}*/}
            {/*            />*/}
            {/*            <Dropdown*/}
            {/*                onChange={handleFormChange}*/}
            {/*                name="shippingAddress.countryCode"*/}
            {/*                value={combinedData.shippingAddress.countryCode}*/}
            {/*                placeholder="Select a Country"*/}
            {/*                label="Country"*/}
            {/*                required*/}
            {/*                hasError={includes(errors, "shippingAddress.countryCode")}*/}
            {/*                options={countriesDropdownList}*/}
            {/*                disabled={!props.canEditClinicManagement}*/}
            {/*            />*/}
            {/*            <TextBox*/}
            {/*                onChange={handleFormChange}*/}
            {/*                name="shippingAddress.city"*/}
            {/*                value={combinedData.shippingAddress.city}*/}
            {/*                label="City"*/}
            {/*                required*/}
            {/*                hasError={includes(errors, "shippingAddress.city")}*/}
            {/*                disabled={!props.canEditClinicManagement}*/}
            {/*            />*/}
            {/*            <Dropdown*/}
            {/*                onChange={handleFormChange}*/}
            {/*                name="shippingAddress.stateProvince"*/}
            {/*                value={combinedData.shippingAddress.stateProvince}*/}
            {/*                placeholder="Select a State"*/}
            {/*                label="State"*/}
            {/*                required*/}
            {/*                hasError={includes(errors, "shippingAddress.state")}*/}
            {/*                options={usStatesDropdownList}*/}
            {/*                disabled={!props.canEditClinicManagement}*/}
            {/*            />*/}
            {/*            <TextBox*/}
            {/*                onChange={handleFormChange}*/}
            {/*                name="shippingAddress.postalCode"*/}
            {/*                value={combinedData.shippingAddress.postalCode}*/}
            {/*                label="Postal Code"*/}
            {/*                required*/}
            {/*                hasError={includes(errors, "shippingAddress.postalCode")}*/}
            {/*                disabled={!props.canEditClinicManagement}*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*)}*/}
            <hr className="margin-top-md margin-bottom-md"/><div className="flex spaced-content justify-flex-end margin-top-lg">
                
            </div>
            <div className={"flex spaced-content flex-end"}>
                <TextBox 
                    label="New Password"
                    onChange={handleFormChange}
                    inputType="password"
                    name="newPassword"
                    information="Passwords must contain: 7 or more characters, an uppercase character, a lowercase character, a digit, and a non-alphanumeric character."
                    hasError={!!passwordError}
                    value={combinedData.newPassword}
                    disabled={!props.canResetPasswords}
                    autoComplete="new-password"
                />
                <div className="flex-none flex flex-end margin-bottom-xx-sm">
                    <Button
                        onClick={handlePasswordReset}
                        className="margin-bottom-x-sm"
                        disabled={ !props.canResetPasswords || !combinedData.newPassword }
                    >
                        Reset Password
                    </Button>
                </div>
                <div className="flex-none flex flex-end margin-bottom-xx-sm">
                    <Button
                        onClick={handlePasswordExtensions}
                        className="margin-bottom-x-sm"
                        disabled={ !props.canResetPasswords || !!combinedData.newPassword }
                    >
                        Extend Expiration
                    </Button>
                </div>
            </div>
            <div className="flex flex-column" hidden={!passwordError}>
                <small style={{ color: "red" }}>{passwordError}</small>
            </div>
            <div className="flex flex-wrap spaced-content">
                <div className={styles.flex1}>
                    {props.canViewUserManagement ? (
                        <Dropdown
                            label="(Designated) Clinic Admin"
                            placeholder="- Unassigned - "
                            options={CLINIC_ADMIN_OPTIONS}
                            name="clinicAdminUserId"
                            onChange={handleFormChange}
                            value={combinedData.clinicAdminUserId}
                            disabled={!(props.canViewUserManagement && props.canEditClinicManagement)}
                        />
                    ) : (
                        <TextBox
                            onChange={() => {}}
                            name="clinicAdminUserId"
                            value={`${combinedData.clinicAdminFirstName} ${combinedData.clinicAdminLastName} (${combinedData.clinicAdminUserName})`}
                            label="(Designated) Clinic Admin"
                            disabled
                        />
                    )}
                </div>
                <div className={styles.flex1}>
                    <TextBox
                        label="Clinic Admin Username"
                        name="clinicAdminUserName"
                        value={combinedData.clinicAdminUserName}
                        onChange={() => {}}
                        disabled
                        canCopy
                    />
                </div>
            </div>
            <div className="flex flex-wrap spaced-content">
                <div className={styles.flex1}>
                    <TextBox
                        onChange={handleFormChange}
                        name="contactEmail"
                        value={combinedData.contactEmail}
                        label="Clinic Contact Email"
                        required
                        disabled={!props.canEditClinicManagement}
                    />
                </div>
                <div className={styles.flex1}>
                    <TextBox
                        onChange={handleFormChange}
                        name="phone"
                        value={combinedData.contactPhone}
                        label="Clinic Contact Phone"
                        required
                        disabled={!props.canEditClinicManagement}
                    />
                </div>
            </div>
            <CorporateGroupDropdown
                label="Reported Clinic Group"
                name="corporateMembershipId"
                onChange={handleFormChange}
                value={combinedData.corporateMembershipId}
            />
            <hr className="margin-top-md margin-bottom-md"/>
            <div className="flex spaced-content flex-wrap">
                <div className={styles.flex1}>
                    <TextBox
                        onChange={() => {}}
                        name="lastProductPullDate"
                        value={combinedData.lastProductPullDate && getLocalTimezone(combinedData.lastProductPullDate)}
                        label="Last Product Pull Date"
                        disabled
                    />
                </div>
                <div className={styles.flex1}>
                    <TextBox
                        onChange={() => {}}
                        name="approximateCloudSyncTime"
                        value={combinedData.approximateCloudSyncTime && getLocalTimezone(combinedData.approximateCloudSyncTime, "MM/DD/YYYY LTS")}
                        label="Sync Time"
                        disabled
                    />
                </div>
                <div className={styles.flex1}>
                    <TextBox
                        onChange={() => {}}
                        name="lastPingDate"
                        value={combinedData.lastPingDate && getLocalTimezone(combinedData.lastPingDate)}
                        label="Last Ping Date"
                        disabled
                    />
                </div>
            </div>
            <hr className="margin-top-md margin-bottom-md"/>
            <div className="flex flex-wrap spaced-content">
                <CheckboxInput
                    onChange={handleFormChange}
                    name="isTestClinic"
                    checked={combinedData.isTestClinic}
                    label="Test Clinic?"
                    leftLabel
                    disabled={!props.canEditClinicManagement}
                />
                <CheckboxInput
                    onChange={() => {}}
                    name="isClinicSetupComplete"
                    checked={combinedData.isClinicSetupComplete}
                    label="Setup Complete?"
                    leftLabel
                    disabled
                />
                <CheckboxInput
                    onChange={handleFormChange}
                    name="isActive"
                    checked={combinedData.isActive}
                    label="Active?"
                    leftLabel
                    disabled={!props.canEditClinicManagement}
                />
            </div>
            <hr className="margin-top-md margin-bottom-md"/>
            <TextBox
                onChange={handleFormChange}
                name="excludedCouponClientIdList"
                value={combinedData.excludedCouponClientIdList}
                disabled={!props.canEditClinicManagement}
                label="Excluded Client IDs (Coupons)"
                information="Changes to this field could take a minute to propagate"
            />
            <TextBox
                onChange={handleFormChange}
                name="excludedReportingClientIdList"
                value={combinedData.excludedReportingClientIdList}
                label="Excluded Client IDs (Reporting)"
                information="Changes to this field could take a minute to propagate"
                disabled={!props.canEditClinicManagement}
            />
            <TextBox
                onChange={handleFormChange}
                name="excludedEmailClientIdList"
                value={combinedData.excludedEmailClientIdList}
                label="Excluded Client IDs (Emailing)"
                information="Changes to this field could take a minute to propagate"
                disabled={!props.canEditClinicManagement}
            />
            <hr className="margin-top-md margin-bottom-md"/>
            {props.canViewClinicManagement && (
                <>
                    <div>
                        <SpinnerTakeover show={!combinedData.reps}/>

                        {(!!combinedData.reps && !combinedData.reps.length) && (
                            <>
                                <h3>Reps</h3>
                                <div>There are no active reps for this clinic.</div>
                            </>
                        )}
                        {!!(combinedData.reps && combinedData.reps.length) && (
                            <DataTable
                                title="Reps"
                                columns={REP_COLUMNS}
                                data={combinedData.reps}
                                highlightOnHover
                                pagination={false}
                            />
                        )}
                    </div>
                    <div className="margin-top-md">
                        <CheckboxInput
                            label="Clinic Is Gateway Clinic?"
                            name="isGatewayClinic"
                            checked={combinedData.isGatewayClinic}
                            onChange={handleFormChange}
                            information="When set, this clinic is allowed to sync. And if hospitals are available, this flag cannot be modified and hospitals can be assigned to other clinics. If this clinic is enrolled into wellness, Gateway flag cannot be changed"
                            leftLabel
                            disabled={get(props.clinic, "programs.Wellness.enabled", false) || !!props.hospitals.length || !props.canEditClinicManagement}
                        />
                    </div>
                    <div className="margin-top-sm">
                        {hasHospitals ? (
                            <DataTable
                                title="Clinics"
                                columns={HOSPITAL_COLUMNS}
                                data={Object.values(props.hospitals)}
                                highlightOnHover
                            />
                        ) : (
                            <div>
                                <h3>Clinics</h3>
                                <div>Nothing to show</div>
                            </div>
                        )}
                    </div>
                </>
            )}
            <div className="flex spaced-content justify-flex-end margin-top-lg">
                <Button
                    type="gray"
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                {(!combinedData.isActive && props.canDeleteClinicRecord) && (
                    <Button
                        type="danger"
                        onClick={handleDelete}
                    >
                        Delete Clinic
                    </Button>
                )}
                <Button
                    buttonType="submit"
                    disabled={!props.canEditClinicManagement}
                >
                    Save Changes
                </Button>
            </div>
            <Modal
                show={!!selectedClinicForHospitalAssignment}
                onClose={() => setSelectedClinicForHospitalAssignment(null)}
                mediumSmall
                modalTitle="Choose Clinic"
                modalSubTitle="Please choose a Non-Gateway Clinic from the list below"
            >
                {props.canAssignHospital ? (
                    <ChooseHospital
                        onSelect={handleAssignHospital}
                    />
                ) : (
                    <AccessDenied/>
                )}
            </Modal>
            <Modal
                show={showConfigurePIMS}
                onClose={() => setShowConfigurePIMS(false)}
                mediumSmall
                modalTitle="PIMS Configuration"
            >
                {(props.canViewClinicManagement && showConfigurePIMS) ? (
                    <PIMSFormContainer
                        clinicId={props.clinicId}
                        onCancel={() => setShowConfigurePIMS(false)}
                        onSubmit={handleSubmitPIMS}
                    />
                ) : <AccessDenied/>}
            </Modal>
            <SpinnerTakeover show={loading || props.loadingHospitals}/>
        </form>
    );
}

AdminClinicSettingsForm.propTypes = {
    clinicId: PropTypes.number.isRequired,
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const hospitals = state.entities.hospitals.data || {};
        const filteredCliniUsers = filter(state.entities.clinicUsers, user => {
            return (user.role === UserRoles.CLINIC_ADMIN || user.role === UserRoles.CLINIC_OWNER)
        });
        //Permissions
        const canAssignHospital = userHasPermission(PermissionTypes.ASSIGN_HOSPITAL, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);
        const canImpersonateAccount = userHasPermission(PermissionTypes.IMPERSONATE, UserPermissions.ACCOUNT, userProfile);
        const canDeleteClinicRecord = userHasPermission(PermissionTypes.DELETE, UserPermissions.CLINIC_RECORD, userProfile);
        const canResetPasswords = userHasPermission(PermissionTypes.EDIT, UserPermissions.ADMIN_PASSWORD_MANAGEMENT, userProfile);

        return {
            userProfile,
            canAssignHospital,
            canViewClinicManagement,
            canEditClinicManagement,
            canViewUserManagement,
            canImpersonateAccount,
            canDeleteClinicRecord,
            canResetPasswords,
            tags: state.entities.tags,
            reps: state.entities.reps[ownProps.clinicId],
            hospitals: hospitals[ownProps.clinicId] || {},
            loadingHospitals: state.entities.hospitals.loading || false,
            clinic: state.entities.clinicDetails[ownProps.clinicId],
            clinicUsers: filteredCliniUsers
        }
    },
    (dispatch) => ({
        assignHospital: (data) => dispatch(ClinicActions.assignHospital(data)),
        deleteClinic: (clinicId) => dispatch(ClinicActions.deleteClinic(clinicId)),
        generateInstallCode: (clinicId) => dispatch(ClinicActions.generateInstallCode(clinicId)),
        getClinicUsers: (clinicId) => dispatch(ClinicActions.getClinicUsers(clinicId)),
        getClinicReps: (clinicId) => dispatch(ClinicActions.getClinicReps(clinicId)),
        getClinicHospitals: (clinicId) => dispatch(ClinicActions.getClinicHospitals(clinicId)),
        getProviderLocations: (clinicId, showOnlyEnrolled) => dispatch(ClinicActions.getProviderLocations(clinicId, showOnlyEnrolled)),
        impersonateUser: (token) => dispatch(AdminActions.impersonateUser(token)),
        setRecentUser: (userInfo) => dispatch(UserActions.setRecentUser(userInfo)),
        setUserUnknown: () => dispatch(UserActions.setUserUnknown()),
        tagCreated: (tag) => dispatch(ClinicActions.tagCreated(tag)),
        updateClinic: (clinicUpdate) => dispatch(ClinicActions.adminUpdateClinicSettings(clinicUpdate)),
        updateClinicPIMS: (data) => dispatch(ClinicActions.updateClinicPIMS(data)),
        updateClinicTags: (clinicId, tags) => dispatch(ClinicActions.updateClinicTags(clinicId, tags))
    })
);

export default compose(
    withRouter,
    connector
)(AdminClinicSettingsForm);
