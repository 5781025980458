import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form } from "informed";
import classnames from "classnames";
import styles from "./UserHomepage.scss";
import * as UserActions from "actions/UserActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import TextBox from "components/common/TextBox";
import env from "utils/environment";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function UserHomepage(props) {
    if (!props.canViewAccount) {
        return <AccessDenied/>;
    }

    const [data, setData] = useState({});
    const [validation, setValidation] = useState({});
    const [canSubmit, setCanSubmit] = useState(false);

    useEffect(() => {
        if (props.isLoggedIn && !props.userProfile) {
            props.loadCurrentUser();
        }
    }, []);

    useEffect(() => {
        if (props.userProfile && data.userName !== props.userProfile.userName) {
            setData({
                id: props.userProfile.id,
                userName: props.userProfile.userName,
                firstName: props.userProfile.firstName,
                lastName: props.userProfile.lastName,
                email: props.userProfile.email,
            });
            setValidation({
                // True means it IS valid
                firstName: false,
                lastName: false,
                //TODO: Enable once Email can be updated
                // email: false,
            })
        }
    }, [props.userProfile]);

    useEffect(() => {
        updateCanSubmit();
    }, [data]);

    const updateCanSubmit = () => {
        const newValidation = {
            firstName: !!data.firstName,
            lastName: !!data.lastName,
            //TODO: Enable once Email can be updated
            // email: /^\S+@\S+\.\S+$/.test(data.email), // Make sure the format is right
        }

        setValidation(newValidation);
        setCanSubmit(newValidation.firstName && newValidation.lastName);
        //TODO: Enable once Email can be updated
        // setCanSubmit(newValidation.firstName && newValidation.lastName && newValidation.email);
    };

    const handleInputChange = input => {
        const newData = { ...data, [input.name]: input.value };
        setData(newData);
    };

    const handleSubmitClick = () => {
        if (props.onSubmit) {
            props.onSubmit(data);
        }
    };

    const handleChangePasswordClick = () => {
        window.open(`${env.getValue("IDENTITY_ROOT")}/account/changepassword/`, "_blank");
    };

    const firstNameError = !validation["firstName"];
    const lastNameError = !validation["lastName"];
    const emailError = !validation["email"];

    return (
        <div className={styles.root}>
            <Form>
                <label>
                    <div className="flex">
                        <div
                            className={classnames(styles.label, "text-lg flex justify-flex-end align-center margin-right-sm margin-bottom-x-sm")}
                            style={{ width: "30%" }}
                        >
                            User Name
                            {/* <i className="fal fa-info-circle pointer" onClick={() => logger.log("qqq info tip")} /> */}
                        </div>
                        <div className="flex-1">
                            <TextBox
                                value={data.userName}
                                name="userName"
                                disabled
                            />
                        </div>
                    </div>
                </label>

                <label>
                    <div className="flex">
                        <div
                            className={classnames(
                                styles.label,
                                "text-lg flex justify-flex-end align-center margin-right-sm margin-bottom-x-sm",
                                { [styles.invalid]: firstNameError }
                            )}
                            style={{ width: "30%" }}
                        >
                            First Name
                        </div>
                        <div className="flex-1">
                            <TextBox
                                value={data.firstName}
                                name="firstName"
                                onChange={handleInputChange}
                                invalid={firstNameError}
                                customInvalidMessage="Invalid first name"
                                disabled={!props.canEditAccount}
                            />
                        </div>
                    </div>
                </label>

                <label>
                    <div className="flex">
                        <div
                            className={classnames(
                                styles.label,
                                "text-lg flex justify-flex-end align-center margin-right-sm margin-bottom-x-sm",
                                { [styles.invalid]: lastNameError }
                            )}
                            style={{ width: "30%" }}
                        >
                            Last Name
                        </div>
                        <div className="flex-1">
                            <TextBox
                                value={data.lastName}
                                name="lastName"
                                onChange={handleInputChange}
                                invalid={lastNameError}
                                customInvalidMessage="Invalid last name"
                                disabled={!props.canEditAccount}
                            />
                        </div>
                    </div>
                </label>

                <label>
                    <div className="flex">
                        <div
                            className={classnames(
                                styles.label,
                                "text-lg flex justify-flex-end align-center margin-right-sm margin-bottom-x-sm",
                                { [styles.invalid]: emailError }
                            )}
                            style={{ width: "30%" }}
                        >
                            Email
                        </div>
                        <div className="flex-1">
                            <TextBox
                                value={data.email}
                                name="email"
                                onChange={() => {}}
                                // onChange={handleInputChange}
                                invalid={emailError}
                                customInvalidMessage="Invalid email address"
                                disabled
                                // disabled={!props.canEditAccount}
                            />
                        </div>
                    </div>
                </label>

                <div className="flex-none spaced-content justify-center ">
                    {props.canEditAccount ? (
                        <Button
                            onClick={handleSubmitClick}
                            disabled={!canSubmit}
                        >
                            Save Changes
                        </Button>
                    ) : (
                        <Button
                            onClick={() => props.onClose()}
                        >
                            Close
                        </Button>
                    )}

                    {props.showCancelButton ? (
                        <Button type="gray"
                            onClick={() => props.onClose()}
                        >
                            Cancel
                        </Button>
                    ) : null}
                </div>
            </Form>
            {props.canEditPassword && props.showPassword && (
                <>
                    <div className={styles.line} />
                    <div className={styles.button}>
                        <Button
                            onClick={handleChangePasswordClick}
                            superwide
                        >
                            Change Password
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
}

UserHomepage.defaultProps = {
    showPassword: true,
    showCancelButton: false
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewAccount = userHasPermission(PermissionTypes.VIEW, UserPermissions.ACCOUNT, userProfile);
        //TODO: The permission that is needed for the API call is a UserManagement permission... Should rely on Account.Edit permission
        const canEditAccount = userHasPermission(PermissionTypes.EDIT, UserPermissions.ACCOUNT, userProfile);
        const canEditPassword = userHasPermission(PermissionTypes.CHANGE_PASSWORD, UserPermissions.ACCOUNT, userProfile);

        return{
            isLoggedIn: state.user.isLoggedIn,
            userProfile,
            canViewAccount,
            canEditAccount,
            canEditPassword,
        }
    },
    (dispatch) => ({
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
    }),
)(UserHomepage);
