export function convertCentsToDollars(cents) {
    return Math.abs(cents / 100);
}

export function convertDollarsToCents(dollars) {
    return Math.round(dollars * 100);
}

export function formatDollarsFromCents(cents) {
    return `$${convertCentsToDollars(cents).toFixed(2)}`;
}

// TODO: This function is broken but currently is not used anywhere
//     For example if you pass in 0.5 (dollars) it returns "$50"
//     Should uncomment test if this function gets fixed in the future
// export function formatDollarsToCents(dollars) {
//     return `$${convertDollarsToCents(dollars)}`;
// }

export function setFixed(value, { useCommas, decimals } = {useCommas: true, decimals: 2}) {
    const newValue = value.toFixed(decimals);

    return useCommas ? addCommasToNumber(newValue) : newValue;
}

export function formatUSD(value, { useCents, useCommas, decimals } = { useCents: true, useCommas: true, decimals: 2 }) {
    const newValue = useCents ? value.toFixed(decimals) : value;

    if (useCommas) {
        return `$${addCommasToNumber(newValue)}`
    }
    return `$${newValue}`;
}

export function formatCurrencyString(value) {
    if (!value) {
        return value;
    }

    return value.toFixed(2);
}

export function addCommasToNumber(toConvert) {
    if (toConvert) {
        const numberValue = typeof (toConvert) === "string" ? toConvert : toConvert.toString()
        if (numberValue.includes(".")) {
            const parts = numberValue.split(".")
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            parts[1] = parts[1].substr(0, 2) // Essentially toFixed(2)
            return parts.join(".")
        }
        return numberValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    } else {
        return 0;
    }
}

export function valueToNumber(value, nanValue = 0) {
    const asNumber = Number(value);
    return isNaN(asNumber) ? nanValue : asNumber;
}

export function hasValue(value) {
    if (!value) {
        return value === 0;
    }
    return true;
}

export const addOrdinalSuffixToNumber = (number) => {
    const j = number % 10;
    const k = number % 100;
    if (j == 1 && k != 11) {
        return number + "st";
    }
    if (j == 2 && k != 12) {
        return number + "nd";
    }
    if (j == 3 && k != 13) {
        return number + "rd";
    }
    return number + "th";
}