import React from 'react';
import ImportantSafetyInformation from "components/common/ImportantSafetyInformation";
import styles from "./SafetyInformation.scss";
import {CLINIC_TAGS} from "constants/AdminControls";
import { connect } from "react-redux";
import AccessDenied from "components/common/AccessDenied";
import {PermissionTypes, userHasPermission, userPrimaryLocationType} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import {getClinicInfo} from "utils/ClinicData";
import ClinicBase from "components/layout/ClinicBase";
import Button from "components/common/Button";
import * as LocationTypes from "constants/LocationTypes";
import logger from 'utils/logger';

const SafetyInformation = (props) => {

  const handleGoBackToClinicGamePage = () => {
    if (props.isAdmin) {
      props.history.push(`/admin/clinic/${props.match.params.clinicId}/games`);
    } else {
      props.history.push(`/games`);
    }
  }

  return (
    <ClinicBase
      pageTitle={'Important Safety Information'}
      clinicId={Number(props.match.params.clinicId)}
      adminControls={CLINIC_TAGS}
      collapsible
    >
      {props.canViewClinicBIGamesDashboard ? (
        <div className={styles.root}>
          <div className="flex-none">
              <Button
                  icon
                  onClick={handleGoBackToClinicGamePage}
                  type="primary"
              >
                  <i className="fa fa-chevron-left"/> Games
              </Button>
          </div>
          <div className={styles.body}>
            <ImportantSafetyInformation />
          </div>
        </div>
      ) : (
        <AccessDenied/>
      )}
    </ClinicBase>
  )
}

export default connect(
  (state) => {
      const userProfile = state.user.userProfile;
      const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
      const isAdmin = userPrimaryLocationType(userProfile, [LocationTypes.NULL]);

      return {
        canViewClinicBIGamesDashboard,
        isAdmin,
      }
  }
)(SafetyInformation);