import React from "react";
import PropTypes from "prop-types";
import Button from "components/common/Button";
import FakeLiveChat from "components/clinic/elements/FakeLiveChat";
import toast from "utils/toast";
import * as ButtonTypes from "constants/ButtonTypes";

export default function NotificationButtons(props) {
    const {
        buttonText,
        cancelledEnrolls = false,
        declineDisabled = false,
        enrollDisabled = false,
        formFieldsJson,
        initials,
        isPreview = false,
        notificationDetails,
        onCancel,
        onDecline,
        onDismiss,
        onEnroll,
        selectedOffers = [],
    } = props;

    const handleCancelledClicked = (e) => {
        if (cancelledEnrolls) {
            if (enrollDisabled) {
                return () => handleEnroll(e);
            }
        } else {
            e.stopPropagation();
            e.preventDefault();
            if (isPreview) {
                toast.success(`${notificationDetails.declined?.buttonText || "Cancelled"} Successfully`);
            } else {
                onCancel(null, null, selectedOffers);
            }
        }
    }

    const handleDecline = (e) => {
        if(!declineDisabled && onDecline) {
            e.stopPropagation();
            e.preventDefault();
            if(isPreview) {
                toast.success(`${notificationDetails.declined?.buttonText || "Declined"} Successfully`);
            } else {
                onDecline(initials);
            }
        }
    }

    const handleEnroll = (e) => {
        if(!enrollDisabled && onEnroll) {
            e.stopPropagation();
            e.preventDefault();
            if(isPreview) {
                toast.success(`${notificationDetails.enrolled?.buttonText || "Accepted"} Successfully`);
            } else {
                onEnroll(initials, formFieldsJson, selectedOffers);
            }
        }
    }

    return (
        <div className="flex spaced-content flex-centered">
            {notificationDetails?.cancelled?.supported && (
                <div className="flex-1">
                    <Button
                        onClick={handleCancelledClicked}
                        type={ButtonTypes.GRAY}
                    >
                        {notificationDetails.cancelled?.buttonText || "Cancel"}
                    </Button>
                </div>
            )}
            {notificationDetails?.liveChat?.supported && (
                <div className="flex-1">
                    <FakeLiveChat
                        onDismiss={onDismiss}
                        disabled={enrollDisabled}
                    />
                </div>
            )}
            {notificationDetails?.declined?.supported && (
                <div className="flex-none">
                    <Button
                        onClick={handleDecline}
                        type={ButtonTypes.DANGER}
                        disabled={declineDisabled}
                    >
                        {notificationDetails.declined?.buttonText || "Decline"}
                    </Button>
                </div>
            )}
            {notificationDetails?.enrolled?.supported && (
                <div className="flex-none">
                    <Button
                        onClick={handleEnroll}
                        type={ButtonTypes.SUCCESS}
                        disabled={enrollDisabled}
                        buttonType="submit"
                    >
                        {buttonText || notificationDetails.enrolled?.buttonText || "Accept"}
                    </Button>
                </div>
            )}
        </div>
    );
}

NotificationButtons.propTypes = {
    buttonText: PropTypes.string,
    // TODO: this is a temporary option
    // Allows the cancelled button to do the same thing as the enroll button
    cancelledEnrolls: PropTypes.bool,
    declineDisabled: PropTypes.bool,
    enrollDisabled: PropTypes.bool,
    formFieldsJson: PropTypes.object,
    initials: PropTypes.string,
    isPreview: PropTypes.bool,
    notificationDetails: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onDecline: PropTypes.func,
    onDismiss: PropTypes.func,
    onEnroll: PropTypes.func,
    selectedOffers: PropTypes.array,
};
