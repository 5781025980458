export const HELMET_STRIPE = "helmet_stripe";
export const HELMET_HOLE = "helmet_hole";
export const HELMET_BG = "helmet_bg";
export const JERSEY_STRIPE = "jersey_stripe";
export const JERSEY_SLEEVE = "jersey_sleeve";
export const JERSEY_BG = "jersey_bg";

export const TEAM_COLORS = [{
    [HELMET_STRIPE]: "#0978c0",
    [HELMET_HOLE]: "#dea900",
    [HELMET_BG]: "#ffc200",
    [JERSEY_STRIPE]: "#04456f",
    [JERSEY_SLEEVE]: "#0978c0",
    [JERSEY_BG]: "#ffc200",
}, {
    [HELMET_STRIPE]: "#9a05c4",
    [HELMET_HOLE]: "#320b98",
    [HELMET_BG]: "#4311c8",
    [JERSEY_STRIPE]: "#b14bce",
    [JERSEY_SLEEVE]: "#4311c8",
    [JERSEY_BG]: "#9a05c4",
}, {
    [HELMET_STRIPE]: "#ffb800",
    [HELMET_HOLE]: "#036c8a",
    [HELMET_BG]: "#249ec0",
    [JERSEY_STRIPE]: "#ffb800",
    [JERSEY_SLEEVE]: "#02556c",
    [JERSEY_BG]: "#0594bd",
}, {
    [HELMET_STRIPE]: "#ffb800",
    [HELMET_HOLE]: "#890202",
    [HELMET_BG]: "#ad0900",
    [JERSEY_STRIPE]: "#ffb800",
    [JERSEY_SLEEVE]: "#ad0900",
    [JERSEY_BG]: "#de0b00",
}, {
    [HELMET_STRIPE]: "#320b98",
    [HELMET_HOLE]: "#8405ad",
    [HELMET_BG]: "#9a05c4",
    [JERSEY_STRIPE]: "#b14bce",
    [JERSEY_SLEEVE]: "#9a05c4",
    [JERSEY_BG]: "#4311c8",
}, {
    [HELMET_STRIPE]: "#ad0900",
    [HELMET_HOLE]: "#02556c",
    [HELMET_BG]: "#036c8a",
    [JERSEY_STRIPE]: "#890202",
    [JERSEY_SLEEVE]: "#036c8a",
    [JERSEY_BG]: "#0594bd",
}, {
    [HELMET_STRIPE]: "#ad0900",
    [HELMET_HOLE]: "#dea900",
    [HELMET_BG]: "#ffd157",
    [JERSEY_STRIPE]: "#890202",
    [JERSEY_SLEEVE]: "#dea900",
    [JERSEY_BG]: "#ffc200",
}, {
    [HELMET_STRIPE]: "#ff3900",
    [HELMET_HOLE]: "#091677",
    [HELMET_BG]: "#0e1e99",
    [JERSEY_STRIPE]: "#de3200",
    [JERSEY_SLEEVE]: "#0e1e99",
    [JERSEY_BG]: "#091677",
}, {
    [HELMET_STRIPE]: "#063073",
    [HELMET_HOLE]: "#ad2f00",
    [HELMET_BG]: "#de3c00",
    [JERSEY_STRIPE]: "#093e93",
    [JERSEY_SLEEVE]: "#ad2f00",
    [JERSEY_BG]: "#ff4500",
}, {
    [HELMET_STRIPE]: "#ffca28",
    [HELMET_HOLE]: "#680cc6",
    [HELMET_BG]: "#7a2dc9",
    [JERSEY_STRIPE]: "#ffc000",
    [JERSEY_SLEEVE]: "#4e0896",
    [JERSEY_BG]: "#680cc6",
}];

export const PUPPY_PARTS_COLOR_MAPPINGS = [
    {
        svgElementId: "Helmet_Stripe",
        colorKey: HELMET_STRIPE,
    },
    {
        svgElementId: "Helmet_Hole",
        colorKey: HELMET_HOLE,
    },
    {
        svgElementId: "Helmet_BG",
        colorKey: HELMET_BG,
    },
    {
        svgElementId: "Jersey_Stripes",
        colorKey: JERSEY_STRIPE,
    },
    {
        svgElementId: "Jersey_Stripes-2",
        colorKey: JERSEY_STRIPE,
    },
    {
        svgElementId: "Jersey_Sleeve",
        colorKey: JERSEY_SLEEVE,
    },
    {
        svgElementId: "Jersey_Sleeve-2",
        colorKey: JERSEY_SLEEVE,
    },
    {
        svgElementId: "Jersey_BG",
        colorKey: JERSEY_BG,
    }
];