import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import styles from "./ProviderWellnessHistory.scss";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import ProcessedWellnessVisits from "components/wellness/widgets/ProcessedWellnessVisits";
import ProviderBase from "components/layout/ProviderBase";
import * as SearchTypes from "constants/SearchTypes";
import {getNodeId} from "utils/AdminData";
import getProviderId from "utils/getProviderId";
import * as ProviderLinks from "utils/ProviderLinks";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function ProviderWellnessHistory(props)  {
    const history = useHistory();

    const goToWellnessSettings = () => {
        history.push(ProviderLinks.getLinkLocation("wellness-settings", {
            providerId: props.providerId,
            nodeId: props.nodeId,
            isAdmin: props.isAdmin,
            clinicId: props.clinicId,
        }));
    }

    return (
        <ProviderBase
            location={props.location}
            searchConfig={SearchTypes.WELLNESS_VISITS}
            clinicId={props.clinicId}
            nodeId={props.nodeId}
            providerId={props.providerId}
            docTitle="Wellness History"
        >
            <div className={styles.root}>
                <div className="topLine flex spaced-content">
                    <div className="flex-1 flex">
                        <Button
                            small
                            icon
                            className="margin-right-x-sm"
                            type="primary"
                            onClick={goToWellnessSettings}
                        >
                            <i className="fa fa-reply"/> Back To Clinic Menu
                        </Button>
                    </div>
                </div>
                {props.canViewClinicWellnessAudit ? (
                    <ProcessedWellnessVisits clinicId={props.clinicId} />
                ) : (
                    <AccessDenied/>
                )}
            </div>
        </ProviderBase>
    )
}

export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const clinicId = Number(params?.clinicId) || null;
        const nodeId = getNodeId(state, ownProps);
        const providerId = getProviderId(params, userProfile);

        // Permissions
        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);

        return {
            clinicId,
            nodeId,
            providerId,
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && providerId && nodeId),

            // Permissions
            canViewClinicWellnessAudit
        }
    }
)(ProviderWellnessHistory);
