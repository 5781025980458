import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import styles from "./VetCheckHandouts.scss";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import VetCheckTable from "components/vetcheck/widgets/VetCheckTable";
import ClinicBase from "components/layout/ClinicBase";
import * as SearchTypes from "constants/SearchTypes";
import moment from "moment";
import DateInput from "components/common/DateInput";
import * as VetCheckApi from "api/VetCheckApi";
import {getClinicInfo} from "utils/ClinicData";
import {userHasRole} from "utils/permissions/rolesPermissions";
import {GL_ADMIN, SITE_ADMIN} from "constants/UserRoles";
import Modal from "components/common/Modal";
import {CLINIC_TAGS} from "constants/AdminControls";
import {handleErrorResponse} from "utils/request";
import VetCheckHeader from "components/vetcheck/widgets/VetCheckHeader";
import * as ModalTypes from "constants/ModalTypes";
import {HANDOUT} from "constants/VetCheckContent";

function VetCheckHandouts(props) {
    const [loading, setLoading] = useState(false);
    const [selectedHandout, setSelectedHandout] = useState(null);
    const [startDate, setStartDate] = useState(new moment().subtract(1, 'months').format("MM/DD/YYYY"));
    const [offset, setOffset] = useState(0);
    const [endDate, setEndDate] = useState(new moment().format("MM/DD/YYYY"));
    const [handouts, setHandouts] = useState([]);
    const [sortBy, setSortBy] = useState("emailSendDate");
    const [orderDir, setOrderDir] = useState("desc");
    const [totalResults, setTotalResults] = useState(0);
    const TODAY = new moment().format("MM/DD/YYYY");
    const limit = 15;

    const fetchData = () => {
        const searchData = {
            search: props.search,
            limit,
            offset,
            startDate,
            endDate,
            orderBy: sortBy,
            orderDir,
            contentType: HANDOUT
        }
        if(props.clinicId) {
            setLoading(true);
            VetCheckApi.searchContentSent(searchData, props.clinicId)
                .then((res) => {
                    setHandouts(res.body.data);
                    setTotalResults(res.body.totalRecordCount);
                    setLoading(false);
                })
                .catch((error) => {
                    handleErrorResponse("searching VetCheck handouts", error);
                    setLoading(false);
                });
        }
    }

    useEffect(() => {
        fetchData();
    }, [offset, sortBy, orderDir]);

    useEffect(() => {
        if(offset !== 0) {
            setOffset(0);
        } else {
            fetchData();
        }
    }, [props.search, startDate, endDate]);

    const handleGoNext = () => {
        const newOffset = offset + limit;
        setOffset(newOffset);
    }

    const handleGoPrevious = () => {
        const newOffset = offset - limit;
        setOffset(newOffset);
    }

    const handleSort = (sort, direction) => {
        setOffset(0);
        setSortBy(sort);
        setOrderDir(direction);
    }

    const currentPage = ((offset / limit) + 1);

    return (
        <ClinicBase
            searchConfig={SearchTypes.VETCHECK_HANDOUTS_SENT}
            clinicId={props.clinicId}
            adminControls={CLINIC_TAGS}
            docTitle={"VetCheck - Handouts Sent"}
        >
            <div className={styles.root}>
                <VetCheckHeader
                    clinicId={props.clinicId}
                    location={props.location}
                />
                <div className={styles.topLine}>
                    <h2>Handouts Sent</h2>
                    <div className="flex-1">
                        <div className="flex align-center spaced-content justify-flex-end">
                            <label>Date Sent - From: </label>
                            <div>
                                <DateInput
                                    name="startDate"
                                    value={startDate}
                                    maxDate={endDate}
                                    dateFormat={"MM/DD/YYYY"}
                                    onChange={({value}) => setStartDate(value)}
                                    placeholderText="--Select Start Date--"
                                    selectsStart
                                    showDropdowns
                                />
                            </div>
                            <label>To:</label>
                            <div>
                                <DateInput
                                    name="endDate"
                                    value={endDate}
                                    minDate={startDate}
                                    maxDate={TODAY}
                                    dateFormat={"MM/DD/YYYY"}
                                    onChange={({value}) => setEndDate(value)}
                                    placeholderText="--Select End Date--"
                                    selectsEnd
                                    showDropdowns
                                    popperPlacement="bottom-end"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {!handouts.length ? (
                    <div className={styles.noResults}>
                        <div className={styles.innerMessage}>
                            <h3>No Handouts to show</h3>
                            <p>Try change your filters to find more</p>
                        </div>
                    </div>
                ) : (
                    <VetCheckTable
                        limit={limit}
                        items={handouts}
                        itemType="Handout"
                        handleSort={handleSort}
                        handleGoNext={handleGoNext}
                        handleGoPrevious={handleGoPrevious}
                        totalResults={totalResults}
                        page={currentPage}
                        onOpenModal={(row) => setSelectedHandout(row)}
                    />
                )}
            </div>
            <SpinnerTakeover show={loading} />
            <Modal
                show={!!selectedHandout}
                onClose={() => setSelectedHandout(null)}
                modalType={ModalTypes.FULLSCREEN}
                tall
                flexRow
            >
                {!!selectedHandout && (
                    <iframe
                        src={selectedHandout?.shareLink}
                        className="vetcheck-box full-width"
                        frameBorder="0"
                    />
                )}
            </Modal>
        </ClinicBase>
    );
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const isAdmin = userHasRole(userProfile, [SITE_ADMIN, GL_ADMIN]);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        return {
            clinicId,
            isAdmin,
            search: state.entities.genericSearch,
        }
    }
)(VetCheckHandouts);
