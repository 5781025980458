import React from "react";
import PropTypes from "prop-types";

export default function InformationBadge(props) {
    return (
        <div
            data-testid={props["data-testid"]}
            className="badge"
        >
            {props.message}
        </div>
    );
}

InformationBadge.propTypes = {
    ["data-testid"]: PropTypes.string,
    message: PropTypes.string.isRequired,
};
