import * as ActionTypes from "constants/ActionTypes";


export function startWorking() {
    return {
        type: ActionTypes.APP_WORKING_START,
    };
}

export function endWorking() {
    return {
        type: ActionTypes.APP_WORKING_END,
    };
}