import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as ClinicActions from "actions/ClinicActions";
import SpinnerTakeover from "components/common/SpinnerTakeover";

function ClinicDetails(props) {
    useEffect(() => {
        if (props.clinicId && !props.clinicDetails) {
            props.getClinicById(props.clinicId);
        }
    }, [props.clinicId]);

    if (!props.clinicId) {
        return null;
    }

    const clinicDetails = props.clinicDetails;

    if (!clinicDetails) {
        return <SpinnerTakeover show />;
    }

    return (
        <div className="full-width">
            <h2>{clinicDetails.name}</h2>
            <div>{clinicDetails.street1}</div>
            <div>{clinicDetails.street2}</div>
            <div>{clinicDetails.city}, {clinicDetails.state} {clinicDetails.zipCode}</div>
        </div>
    );
}

ClinicDetails.propTypes = {
    clinicId: PropTypes.number.isRequired,
}

export default connect(
    (state, ownProps) => {
        return {
            clinicDetails: state.entities.clinicDetails[ownProps.clinicId],
        };
    },
    (dispatch) => ({
        getClinicById: clinicId => dispatch(ClinicActions.getClinicById(clinicId)),
    }),
)(ClinicDetails);
