import React, {useEffect} from "react";
import {connect} from "react-redux";
import * as ClinicActions from "actions/ClinicActions";
import AccessDenied from "components/common/AccessDenied";
import AdminBase from "components/layout/AdminBase";
import AdminClinicSettingsForm from "components/admin/forms/AdminClinicSettingsForm";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import {CLINIC_TAGS} from "constants/AdminControls";

function EditClinicSettings(props) {

    useEffect(() => {
        if(props.canViewClinicManagement) {
            props.loadClinicDetails(props.clinicId);
            props.loadTags();
        }
    }, [props.canViewClinicManagement, props.clinicId]);

    return (
        <AdminBase
            clinicId={props.clinicId}
            collapsible
            pageTitle="Edit Clinic Settings"
            controls={CLINIC_TAGS}
        >
            {props.canViewClinicManagement ? (
                <div className="margin-bottom-lg">
                    {props.clinic ? (
                        <AdminClinicSettingsForm
                            clinicId={props.clinicId}
                        />
                    ) : <SpinnerTakeover show/>}
                </div>
            ) : <AccessDenied/>}
            <SpinnerTakeover show={props.working}/>
        </AdminBase>
    )
}

export default connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            clinicId,
            canViewClinicManagement,
            clinic: state.entities.clinicDetails?.[clinicId],
            working: !!state.applicationState.working,
        }
    },
    (dispatch) => ({
        loadClinicDetails: (clinicId) => dispatch(ClinicActions.getClinicById(clinicId)),
        loadTags: () => dispatch(ClinicActions.loadTags()),
    })
)(EditClinicSettings);
