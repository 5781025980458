import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router";
import { connect } from "react-redux";
import classnames from "classnames";
import includes from "lodash/includes";
import map from "lodash/map";
import * as ProviderApi from "api/ProviderApi";
import * as ProviderActions from "actions/ProviderActions";
import AccessDenied from "components/common/AccessDenied";
import ProviderBase from "components/layout/ProviderBase";
import ProviderGamesManagementPage from "./ProviderGamesManagementPage";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { getNodeId } from "utils/AdminData";
import getProviderId from "utils/getProviderId";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import styles from "./ProviderGamesManagementPage.scss";

function GamesManagement(props) {
    const location = useLocation();
    const params = useParams();
    const nodeId = Number(params.nodeId);
    const providerId = Number(params.providerId);
    const isAdmin = !!(includes(location?.pathname, "/admin") && nodeId && providerId);
    const isDemo = includes(location?.pathname, "/demo") && props.canViewClinicBIGamesDashboard;
    const [loading, setLoading] = useState(false);
    const [nodeInfo, setNodeInfo] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showSelectUser, setShowSelectUser] = useState(false);
    const userId = (selectedUser ? selectedUser.userId : props.nodeId ? props.currentUser?.userId : null);

    const fetchOrgNode = async (nodeId) => {
        setSelectedUser(null);
        setLoading(true);

        await ProviderApi.getNode(nodeId)
            .then((res) => {
                setNodeInfo(res.body);
                if (res.body.users.length) {
                    if (res.body.users.length > 1) {
                    } else {
                        setSelectedUser(res.body.users[0]);
                    }
                }
            })
            .catch((error) => {
                handleErrorResponse("loading org node", error);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    useEffect(() => {
        if (props.currentUser && props.canViewGames) {
            if (isAdmin) {
                if (!!userId || isDemo) {
                    //If "admin view"
                    props.getProviderGames(userId, isDemo);
                }
            } else {
                //If there is NOT "admin view"
                props.getProviderGames(userId, isDemo);
            }
        }
    }, [props.currentUser, selectedUser]);

    useEffect(() => {
        if (props.canViewGames && props.nodeId) {
            fetchOrgNode(props.nodeId);
        }
    }, [props.nodeId]);

    const handleSelectUser = (user) => {
        setSelectedUser(user);
        setShowSelectUser(false);
    }

    const getPageTitle = () => {
        if (selectedUser) {
            return `${!!isDemo ? "Demo " : ""}Greenline Games™: ${selectedUser.firstName} ${selectedUser.lastName}${isAdmin ? ` (${userId})` : ""}`;
        } else {
            return `${!!isDemo ? "Demo " : ""}Greenline Games™`;
        }
    }

    return (
        <ProviderBase
            location={location}
            pageTitle={getPageTitle()}
            providerId={props.providerId}
            nodeId={props.nodeId}
        >
            {(props.canViewGames || props.canViewBiGamesDrillDown) ? (
                <>
                    {(nodeInfo?.nodeId) ? (
                        <>
                            { showSelectUser ? (
                                <div className="margin-md">
                                    <h2 className="text-center">Select a User</h2>
                                    <div className="flex justify-flex-center flex-wrap">
                                        {map(props.nodeInfo.users, user => (
                                            <div
                                                className={classnames(styles.userTiles, "flex-none flex justify-flex-center align-center margin-x-sm")}
                                                onClick={() => handleSelectUser(user)}
                                            >
                                                {user.firstName} {user.lastName}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <ProviderGamesManagementPage
                                    userId={userId}
                                    nodeInfo={nodeInfo}
                                />
                            )}
                        </>
                    ) : (
                        <SpinnerTakeover show={loading} />
                    )}
                </>
            ) : (
                <AccessDenied/>
            )}
        </ProviderBase>
    );
}
export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const currentOrgNode = state.entities.orgNode?.current;
        const currentUser = currentOrgNode?.users[0] ? currentOrgNode?.users[0] : userProfile;
        const params = ownProps?.match?.params || {};
        const providerId = getProviderId(params, userProfile);
        const nodeId = getNodeId(state, ownProps);
        // Permissions
        const canViewBiGamesDrillDown = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_DRILLDOWN, userProfile);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canAdminProviderBiGames = userHasPermission(PermissionTypes.ADMIN, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewGames = canAdminProviderBiGames || canViewProviderBiGames || canViewBiGamesDrillDown;

        return {
            nodeId,
            currentUser,
            providerId,
            // Permissions
            canViewBiGamesDrillDown,
            canViewClinicBIGamesDashboard,
            canViewGames,
        };
    },
    (dispatch) => ({
        getProviderGames: (userId, isDemo) => dispatch(ProviderActions.getProviderGames(userId, isDemo)),
    }),
)(GamesManagement);