import React from "react";
import PropTypes from "prop-types";
import styles from "./ProgramOfferGroup.scss";
import { map, orderBy } from "lodash";
import ProgramOffer from "./ProgramOffer";

export default function ProgramOfferGroup(props) {
    return (
        <div className={styles.root}>
            {map(orderBy(props.offers, "sortOrder"), (offer) => {
                const isSelected = props.selectedId === offer.offerId;
                return (
                    <ProgramOffer
                        offer={offer}
                        key={`offer_${offer.offerId}`}
                        selected={isSelected}
                        onClick={props.onClick}
                        optInChange={props.optInChange}
                        groupId={props.groupId}
                    />
                );
            })}
        </div>
    );
}

ProgramOfferGroup.propTypes = {
    offers: PropTypes.array,
    groupId: PropTypes.number,
    selectedId: PropTypes.number,
    onClick: PropTypes.func,
    optInChange: PropTypes.func,
};
