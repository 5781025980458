import * as ClinicAdminLinks from "../ClinicAdminLinks";
import * as ClinicProviderLinks from "../ClinicProviderLinks";
import * as ClinicLinks from "../ClinicLinks";

export const getClinicLinkLocation = (link, clinicId, {location, providerId, isAdmin}) => {
    if (isAdmin) {
        return ClinicAdminLinks.getLinkLocation(link, clinicId, location);
    } else if (providerId) {
        return ClinicProviderLinks.getLinkLocation(link, nodeId, clinicId, location);
    } else {
        return ClinicLinks.getLinkLocation(link, location);
    }
}

export const getClinicBasePath = (pathname, clinicId, nodeId = null) => {
    if (clinicId && pathname.indexOf("/admin/") > -1) return `/admin/clinic/${clinicId}`;
    if (clinicId && nodeId && pathname.indexOf("/provider/") > -1) return `/provider/location/${nodeId}/clinic/${clinicId}`;
    return "";
}

export const getProviderBasePath = (pathname, providerId, nodeId = null) => {
    if (providerId && pathname.indexOf("/admin/") > -1) return `/admin/provider/${providerId}/location/${nodeId}`;
    if (providerId && nodeId && pathname.indexOf("/provider/") > -1) return `/provider/location/${nodeId}`;
    return "";
}
