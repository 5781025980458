import React from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import * as styles from "./DateInput.scss";
import classnames from "classnames";


export default function DateInput(props) {
    const { popperPlacement = "top-start" } = props;

    const handleChange = (dt) => {
        const value = dt ? moment(dt).format(props.dateFormat) : null;
        props.onChange({ name: props.name, value });
    };

    const momentDate = props.value ? moment(props.value, props.dateFormat) : null;
    const value = (props.value && momentDate._isValid) ? momentDate.toDate() : null;
    const minDate = props.minDate ? moment(props.minDate, props.dateFormat).toDate() : null;
    const maxDate = props.maxDate ? moment(props.maxDate, props.dateFormat).toDate() : null;

    let extraProps = {};
    if(props.range) {
        extraProps = {
            selectsStart: props.selectsStart,
            selectsEnd: props.selectsEnd,
            startDate: props.startDate ? moment(props.startDate, props.dateFormat).toDate() : null,
            endDate: props.endDate ? moment(props.endDate, props.dateFormat).toDate() : null,
        }
    }

    if (props.showDropdowns) {
        extraProps = {
            ...extraProps,
            showMonthDropdown: true,
            showYearDropdown: true,
            scrollableYearDropdown: true,
            yearDropdownItemNumber: 15
        }
    }

    return (
        <div className={classnames(styles.root, "form-group", {
                "flex": props.aligned||props.alignedSplit,
                "has-error": props.hasError,
                "aligned split": props.alignedSplit,
                "aligned": props.aligned,
                "has-unit": !!props.icon,
            })}
            data-testid={props["data-testid"]}
        >
            {!!(props.label || props.information) && (
                <label
                    data-testid="date-picker-label"
                    htmlFor="datePicker"
                    className={classnames({
                        "pointer": !props.disabled,
                        "flex-1": props.alignedSplit,
                        "flex-none": props.aligned,
                    })}
                >
                    {!!props.information && (<i className="fa fa-info-circle margin-right-x-sm" title={props.information}/>)}
                    {props.label}
                </label>
            )}
            <div className={classnames({
                "flex-none": !props.aligned && !props.alignedSplit,
                "flex-1": props.aligned||props.alignedSplit,
            })}>
                <DatePicker
                    data-testid="date-picker"
                    required={props.required}
                    id="datePicker"
                    selected={value}
                    onChange={handleChange}
                    minDate={minDate}
                    maxDate={maxDate}
                    placeholderText={props.placeholderText}
                    // dateFormat={props.dateFormat ? props.dateFormat : props.showTimeSelect ? "MM/dd/yyyy h:mm a" : "MM/dd/yyyy"} // This blows up... everywhere
                    showTimeSelect={props.showTimeSelect}
                    popperPlacement={popperPlacement}
                    showMonthYearPicker={props.showMonthYearPicker}
                    disabled={props.disabled}
                    {...extraProps}
                    autocomplete="nope"
                />
            </div>
            {props.icon && (
                <label
                    data-testid="date-picker-icon"
                    className="unit-label"
                    htmlFor="datePicker"
                >
                    {props.icon}
                </label>
            )}
        </div>
    );
}

DateInput.propTypes = {
    aligned: PropTypes.bool,
    alignedSplit: PropTypes.bool,
    ["data-testid"]: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    dateFormat: PropTypes.string,
    showTimeSelect: PropTypes.bool,
    placeholderText: PropTypes.string,
    dateRange: PropTypes.bool,
    selectsStart: PropTypes.bool,
    selectsEnd: PropTypes.bool,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    minDate: PropTypes.string,
    maxDate: PropTypes.string,
    showDropdowns: PropTypes.bool,
    label: PropTypes.string,
    icon: PropTypes.node,
    hasError: PropTypes.bool,
    information: PropTypes.string,
    showMonthYearPicker: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    popperPlacement: PropTypes.string,
};
