import React, {useEffect, useMemo, useState} from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import env from "utils/environment";

export default function WellnessPaymentsStripe(props) {
    const STRIPE_PUBLIC_KEY = useMemo(() => env.getValue("GL_WELLNESS_PUBLIC_KEY"), []);
    const [stripePromise, setStripePromise] = useState(null);

    useEffect(() => {
        if(STRIPE_PUBLIC_KEY && !stripePromise) {
            setStripePromise(loadStripe(STRIPE_PUBLIC_KEY));
        }
    }, [STRIPE_PUBLIC_KEY]);

    return(
        <Elements stripe={stripePromise}>
            {props.children}
        </Elements>
    );
}
