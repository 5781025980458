import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import styles from "./SupportBase.scss";
import Sidebar from "components/layout/Sidebar";
import AdminSidebar from "components/layout/AdminSidebar";
import ProviderSidebar from "components/provider/widgets/ProviderSidebar";
import Footer from "components/layout/Footer";
import Toolbar from "components/common/Toolbar/Toolbar";
import Header from "components/layout/Header";
import AdminHeader from "components/layout/AdminHeader";
import ProviderHeader from "components/layout/ProviderHeader";
import {autobind} from "core-decorators";
import * as UserActions from "actions/UserActions";
import {getClinicInfo} from "utils/ClinicData";
import {userHasRole, userPrimaryLocationType} from "utils/permissions/rolesPermissions";
import * as LocationTypes from "constants/LocationTypes";
import * as UserRoles from "constants/UserRoles";

/*
This should be the root container for all Support Pages. It should handle any init() functions or other startup triggers
so that they can be managed in one place.
 */

class SupportBase extends React.Component {

    componentDidMount() {
        this.fetchData();
    }

    @autobind
    fetchData() {
        if(!this.props.userProfile){
            this.props.loadCurrentUser();
        }
    }

    @autobind
    setPageTitle(title) {
        this.setState({ pageTitle: title });
    }

    render() {
        const { userProfile } = this.props;
        return (
            <div className={styles.root}>
                {(userPrimaryLocationType(userProfile, [LocationTypes.NULL]) && !userHasRole(userProfile, [UserRoles.CLINIC_DEMO])) ? (
                    <AdminHeader pageTitle={this.props.pageTitle} />
                ) : userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]) ? (
                    <ProviderHeader pageTitle={this.props.pageTitle} />
                ) : (
                    <Header pageTitle={this.props.pageTitle} />
                )}
                <main className={styles.main}>
                    {(userPrimaryLocationType(userProfile, [LocationTypes.NULL]) && !userHasRole(userProfile, [UserRoles.CLINIC_DEMO])) ? (
                        <AdminSidebar
                            location={this.props.location}
                        />
                    ) : userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]) ? (
                        <ProviderSidebar />
                    ) : (
                        <Sidebar
                            location={this.props.location}
                        />
                    )}
                    <div className={styles.content}>
                        {this.props.children}
                        {(userPrimaryLocationType(userProfile, [LocationTypes.NULL]) && !userHasRole(userProfile, [UserRoles.CLINIC_DEMO])) ? (
                            <Toolbar/>
                        ) : userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]) ? (
                            <Footer
                                showSocial={false}
                                providerLocation={userProfile?.primaryLocation}
                            />
                        ) : (
                            <Footer />
                        )}
                    </div>
                </main>
            </div>
        )
    }
}

SupportBase.propTypes = {
    location: PropTypes.object,
    pageTitle: PropTypes.string,
};

export default connect(
    (state) => ({
        userProfile: state.user.userProfile,
        clinicInfo: getClinicInfo(state),
    }),
    (dispatch) => ({
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
    }),
)(SupportBase);
