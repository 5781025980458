exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".GoldenSummerGamesPodium__root{position:relative}.GoldenSummerGamesPodium__root .GoldenSummerGamesPodium__backgroundContainer img{max-width:100%}.GoldenSummerGamesPodium__root .GoldenSummerGamesPodium__animationContainer{position:relative}.GoldenSummerGamesPodium__root .GoldenSummerGamesPodium__centerPet,.GoldenSummerGamesPodium__root .GoldenSummerGamesPodium__leftPet,.GoldenSummerGamesPodium__root .GoldenSummerGamesPodium__rightPet{left:52%;position:absolute;top:37.5%;transform:translate(-50%, -50%)}.GoldenSummerGamesPodium__root .GoldenSummerGamesPodium__leftPet{left:27.5%;top:62%}.GoldenSummerGamesPodium__root .GoldenSummerGamesPodium__rightPet{left:72.5%;top:51%}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/GoldenSummerGames/GoldenSummerGamesPodium.scss"],"names":[],"mappings":"AAAA,+BAAM,iBAAiB,CAAC,iFAA+B,cAAc,CAAC,4EAA0B,iBAAiB,CAAC,sMAAgD,SAAS,kBAAkB,UAAU,+BAA+B,CAAC,iEAAe,WAAW,OAAO,CAAC,kEAAgB,WAAW,OAAO,CAAC","file":"GoldenSummerGamesPodium.scss","sourcesContent":[".root{position:relative}.root .backgroundContainer img{max-width:100%}.root .animationContainer{position:relative}.root .centerPet,.root .leftPet,.root .rightPet{left:52%;position:absolute;top:37.5%;transform:translate(-50%, -50%)}.root .leftPet{left:27.5%;top:62%}.root .rightPet{left:72.5%;top:51%}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "GoldenSummerGamesPodium__root",
	"backgroundContainer": "GoldenSummerGamesPodium__backgroundContainer",
	"animationContainer": "GoldenSummerGamesPodium__animationContainer",
	"centerPet": "GoldenSummerGamesPodium__centerPet",
	"leftPet": "GoldenSummerGamesPodium__leftPet",
	"rightPet": "GoldenSummerGamesPodium__rightPet"
};