import React from "react";
import {
	MAIN_COLOR,
	SECONDARY_COLOR,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./PuppyTrack.scss";


export default function PuppyTrack(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Run_-_Puppy"
			data-name="Run - Puppy"
			// xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 1600 300"
		>
			<g id="Puppy_Run04">
				<g id="Puppy_Run04_Lower">
					<path className={styles.cls5}
					      d="M1328.77,285.57c0,5.21,31.89,9.43,71.23,9.43,39.34,0,71.23-4.22,71.23-9.43,0-5.21-31.89-9.43-71.23-9.43-39.34,0-71.23,4.22-71.23,9.43Z"/>
					<path className={styles.cls8}
					      d="M1432.15,204.79c12.43,13.8,13.72,41-2.22,48.57-15.89,7.54-23.27,4.18-31.92,5.35,8.47,13.81-10.91,21.63-14.19,15.78-5.84-10.41-8.61-30.57,2.76-27.84,10.54,2.53,22.82-5.25,16.58-14.52-12.09-17.98,20.43-36.85,28.99-27.34Z"/>
					<g>
						<path className={styles.cls7}
						      d="M1355.16,215.63c2.47.25,4.97.36,7.5.23,3.02-.14,19.97-5.69,18.81,1-.18,1.01-.81,1.93-1.66,2.56-2.84,2.12-5.8,4.21-9.02,5.47-5.3,2.08-10.78,1.73-15.9.26-.64-1.29-1.02-2.71-1.07-4.15-.07-1.87.41-3.75,1.34-5.37Z"/>
						<path className={styles.cls6}
						      d="M1341.44,215c.3-.3.74-.57,1.35-.81,2.82-1.11,6.56.66,9.31,1.06,1.02.15,2.04.27,3.07.38-.92,1.63-1.4,3.5-1.34,5.37.05,1.44.43,2.86,1.07,4.15-2.27-.66-4.47-1.53-6.55-2.52-1.46-.69-9.49-5.07-6.91-7.64Z"/>
					</g>
					<path className={styles.cls6}
					      d="M1399.49,234.64c-37.6,7.33-31.38-33.8-23.45-49.16,5.28-10.24-4.13-34.53,2.08-48.62s36.53-10.39,39.98-8.11c17.32,11.45,24.65,40.27,22.99,64.2-1.37,19.64-13.72,36.26-41.6,41.7Z"/>
					<path className={styles.cls7}
					      d="M1370.01,206.78c.5-6.27,10.2,1.75,9.85,10.54-.37,9.39,1.95,16.93-.77,15.04-7.24-5.01-10.2-11.56-9.08-25.59Z"/>
				</g>
				<path id="Shirt"
				      className={styles.cls1}
				      d="M1437.05,155.46c-1.05-2.75-3.23-7.41-5.74-11.89,1.61,3.58,2.04,5.94-.58,6.93-3.63,1.38-7.81-3.73-10.76-8-4.54-6.56-32.48-21.19-40.49-13.18s-1.95,37.89-1.95,46.73c0,6.88-2.76,12.04-4.56,15.86,17.65,10.76,44.8,9.9,68.49-.9,1.51-11.05-1.86-28.86-4.42-35.56ZM1399.39,164.51c-8.33,0-15.08-6.67-15.08-14.9s6.75-14.9,15.08-14.9,15.08,6.67,15.08,14.9-6.75,14.9-15.08,14.9Z"
				      style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes">
					<path
						className={styles.cls9}
				        d="M1427.43,186.39c-6.83,1.95-13.75,3.13-20.54,3.47-10.94.54-21.71-1.15-30.91-4.89.52-1.44.96-3.4,1.14-4.37,8.74,3.65,19.03,5.41,29.55,4.89,11.56-.58,23.53-3.71,34.73-9.08.1.7.36,3.66.36,4.66-4.68,2.15-9.49,3.93-14.34,5.32Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls9}
						d="M1399.3,133.62c-8.92,0-16.17,7.17-16.17,15.99s7.25,15.99,16.17,15.99,16.17-7.17,16.17-15.99-7.25-15.99-16.17-15.99ZM1399.3,163.41c-7.71,0-13.98-6.19-13.98-13.8s6.27-13.8,13.98-13.8,13.98,6.19,13.98,13.8-6.27,13.8-13.98,13.8Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls9}
						d="M1431.46,152.09c-5.2,0-10.63-5.42-12.48-7.85-.41-.54-.86-1.16-1.36-1.86-3.32-4.62-8.89-12.36-16.73-13.18l.23-2.17c8.82.92,14.74,9.16,18.28,14.08.49.67.93,1.29,1.32,1.81,1.92,2.53,6.58,6.82,10.55,6.96,1.89.07,1.31-3.29.13-6.14,4.3,4.75,2.48,8.38.06,8.36Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Run04_Mid">
					<path className={styles.cls6}
					      d="M1392.3,150.76c-1.13,15.22,7.77,35.17,16.37,37.79,8.81,2.69,34.47-4.4,31.57-15.7-3.51-13.67-17.4-3.25-20.94-5.29-3.62-2.08-6.14-11.87-6.74-16.61-1.21-9.47-19.32-12.82-20.26-.19Z"/>
					<path className={styles.cls6}
					      d="M1427.68,221.65c-1.27,28.69-21.44,44.24-43.52,49.48,7.88,1.15,11.05,8.29,7.75,12.38-1.38,1.71-20.56,1.62-22.35.11-5.92-4.99-8.52-18.9-6.03-20.24,16.94-9.04,17.93-30.23,18.83-37.94,1.63-14.02,45.97-18.81,45.31-3.8Z"/>
					<path className={styles.cls3}
					      d="M1379.3,130.55c6.29,4.73,34.07,16.19,50.04,4.24,4.26-3.19,7.43-7.03,8.15-12.88,1.88-15.35-11.25-18.81-22.45-21.03-10.55-2.09-26.74-3.59-34.68,6.24-1.71,2.12-6.47,19.36-1.06,23.43Z"/>
					<g>
						<path className={styles.cls7}
						      d="M1444.71,34.7c3.99,4.26,12.98,25.08,7.58,28.74-8.48,5.75-43.07.04-46.48-.22-4.28-.32-8.5-2.22-10.1-5.25-2.62-4.93,2.45-12.36,6.39-15.92,15.31-13.83,30.01-20.79,42.61-7.35Z"/>
						<g>
							<path className={styles.cls6}
							      d="M1424.65,133.17c-2.21-4.99-2.72-10.72-1.42-16.02,1.34-5.47,4.54-10.62,4.24-16.25-.42-7.66-7.08-13.32-13.41-17.65-9.61-6.57-19.75-12.36-30.29-17.29-2.69-1.26-5.49-2.47-8.35-3.31,5.1-3.51,10.8-6.26,16.82-8.42.66.43,1.38.79,2.07,1.09,4.47,1.95,9.14,3.52,13.32,6.06,8.76,5.33,14.65,14.44,21.5,21.91,3.21,3.5,7.22,6.78,11.89,7.05,4.66.27,8.85-2.47,13.31-3.77,2.79-.81,5.74-.98,8.64-.62,3.69,2.52,6.96,5.47,8.22,8.98,3.17,8.84,1.98,20.46-3.07,26.53-8.53,10.26-17.23,13.59-27.19,16.94-4.26,1.43-8.57,2.25-12.85,2.59-.71-2.64-2.29-5.27-3.42-7.81Z"/>
							<path className={styles.cls4}
							      d="M1366.92,116.63c-3.29-4.34-6.2-9.01-8.62-13.88-4.2-8.45-2.87-15.63,1.68-23.57,3.94-6.89,9.25-12.27,15.43-16.53,2.86.84,5.66,2.05,8.35,3.31,10.54,4.93,20.68,10.72,30.29,17.29,6.33,4.33,12.99,9.99,13.41,17.65.31,5.62-2.9,10.78-4.24,16.25-1.3,5.3-.79,11.03,1.42,16.02,1.13,2.55,2.71,5.18,3.42,7.81-28.26,2.24-55.45-16.84-61.14-24.36Z"/>
							<path className={styles.cls4}
							      d="M1441.01,90.34c-4.67-.27-8.68-3.55-11.89-7.05-6.85-7.47-12.75-16.58-21.5-21.91-4.18-2.54-8.84-4.11-13.32-6.06-.69-.3-1.41-.66-2.07-1.09.87-.31,1.75-.62,2.64-.9,10.75-3.52,20.52-5.34,31.13.93,5.32,3.14,9.29,9.77,12.94,14.63,4.26,5.68,7.5,7.29,13.75,10.98,2.9,1.71,6.77,3.71,10.27,6.09-2.89-.37-5.84-.2-8.64.62-4.46,1.3-8.65,4.04-13.31,3.77Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls2}
								      d="M1402.27,98.49c3.48,4.83,8.48,7.16,11.19,5.2,2.7-1.95,2.08-7.45-1.4-12.28-3.47-4.83-8.48-7.16-11.19-5.2-2.7,1.95-2.08,7.45,1.4,12.28Z"/>
								<path className={styles.cls2}
								      d="M1433.26,80.84c3.85,4.22,8.62,6.13,10.65,4.26,2.03-1.87.56-6.8-3.29-11.02-3.85-4.22-8.62-6.13-10.65-4.26-2.03,1.87-.56,6.8,3.29,11.02Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls2}
									      d="M1447.47,100.19c.79-1.76,2.81-3.67,5.23-5.14h0s0,0,0,0c0,0,0,0,.01,0h0c2.39-1.53,4.98-2.5,6.9-2.42,9.14.38,7.03,10.62.92,15.16h0s0,0,0,0c0,0,0,0-.01,0h0c-6.76,3.48-16.8.81-13.05-7.6Z"/>
									<path className={styles.cls2}
									      d="M1469.54,111.67c-.15-.07-.33-.1-.53-.09-.67.04-.89.69-1.24,1.16-.48.65-1.09,1.27-1.73,1.74-2.08,1.52-4.11,1.74-6.47,1.56l-.04-.09s-.04.06-.06.09c-.04,0-.07,0-.11,0l.04.09c-1.39,1.92-2.87,3.33-5.37,3.93-1.73.42-5.59.22-7.2-.9-.57-.4-1.33-.25-1.7.31-.38.57-.22,1.35.34,1.74,2.48,1.73,7.22,1.75,9.17,1.28.76-.18,1.44-.43,2.06-.71,1.71-.79,2.92-1.95,3.94-3.19,1.61.03,3.27-.16,4.98-.95.62-.29,1.25-.65,1.88-1.12.91-.66,2.16-1.74,2.61-2.8.3-.71.13-1.74-.57-2.05Z"/>
								</g>
								<path className={styles.cls2}
								      d="M1460.41,118.64c-.31.17-.68.21-1.05.08-.7-.24-1.08-1.01-.86-1.71.02-.08,1.05-3.66-.46-10.1-.17-.73.27-1.45.98-1.61.71-.16,1.43.31,1.6,1.03,1.7,7.3.46,11.38.41,11.55-.11.34-.34.6-.62.76Z"/>
							</g>
							<path className={styles.cls6}
							      d="M1463.68,95.79c.18,1.41-1.68,2.81-4.16,3.14-2.48.33-4.64-.55-4.82-1.96-.18-1.41,1.68-2.81,4.16-3.14s4.64.55,4.82,1.96Z"/>
							<path className={styles.cls6}
							      d="M1438.22,74.42c-.45.77-1.96.72-3.36-.1s-2.17-2.12-1.72-2.88c.45-.77,1.96-.72,3.36.1,1.4.82,2.17,2.12,1.72,2.88Z"/>
							<path className={styles.cls6}
							      d="M1409.26,91.74c-.5,1.04-2.43,1.16-4.3.27-1.88-.9-3-2.47-2.5-3.51.5-1.04,2.43-1.16,4.3-.27,1.88.9,3,2.47,2.5,3.51Z"/>
						</g>
					</g>
				</g>
				<path
					id="Stripe"
					className={styles.cls9}
					d="M1363.52,110.24c16.5-24.77,47.99-44.37,73.02-45.62,1.92-.1-6.24-12.05-12.57-12.42-16.36-.96-61.61,24.73-66.42,46.28-.72,3.2,4.42,14.07,5.97,11.75Z"
					style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
				/>
				<path id="Puppy_Run04_Upper" className={styles.cls7}
				      d="M1328.16,95.16c1.63,6.05,15.08,26.2,21.1,23.5,9.42-4.23,22.46-38.48,24.01-41.67,1.95-4.01,2.49-8.89.69-12.09-2.93-5.21-12.04-5.01-17.2-3.55-20.02,5.63-33.74,14.72-28.59,33.81Z"/>
			</g>
			<g id="Puppy_Run03">
				<g id="Puppy_Run03_Lower">
					<path className={styles.cls5}
					      d="M938.79,285.57c0,4.47,27.4,8.1,61.21,8.1,33.81,0,61.21-3.63,61.21-8.1,0-4.48-27.4-8.1-61.21-8.1-33.81,0-61.21,3.63-61.21,8.1Z"/>
					<path className={styles.cls8}
					      d="M1001.65,214.86c-36.6,11.5-54.98-7.21-67.14-19.38-7.19,6.72-20.2,2.26-22.9-3.45-1.8-3.81,24.17-23.7,28.8-15.94,12.24,20.5,33.53,7.22,41.71.74,11.06-8.76,33.87,33.53,19.53,38.03Z"/>
					<g>
						<path className={styles.cls7}
						      d="M959.89,189.5c2.19,1.18,4.46,2.24,6.83,3.1,2.85,1.03,20.63,2.41,16.98,8.15-.55.87-1.49,1.47-2.51,1.73-3.44.86-6.97,1.66-10.42,1.59-5.7-.11-10.62-2.54-14.78-5.87-.1-1.44.1-2.89.6-4.24.66-1.75,1.82-3.3,3.3-4.45Z"/>
						<path className={styles.cls6}
						      d="M947.47,183.65c.39-.16.9-.24,1.55-.23,3.03.05,5.81,3.13,8.18,4.55.88.53,1.78,1.03,2.69,1.52-1.48,1.15-2.64,2.7-3.3,4.45-.51,1.35-.7,2.81-.6,4.24-1.84-1.48-3.54-3.13-5.08-4.84-1.08-1.2-6.82-8.32-3.45-9.7Z"/>
					</g>
					<path className={styles.cls8}
					      d="M1003.52,131.27c13.47,37.76,61.34,28.47,61.34,11.34,0-15.92-27.73,9.91-40.6-18.49-3.94-8.7-24.99-4.78-20.74,7.15Z"/>
					<path className={styles.cls6}
					      d="M1003.37,215.47c-37.31,8.68-32.57-32.65-25.2-48.29,4.91-10.43-5.37-34.36.33-48.67,5.48-13.75,36.14-11.69,39.67-9.54,17.72,10.82,26.08,39.36,25.28,63.33-.66,19.67-12.41,36.73-40.08,43.16Z"/>
					<path className={styles.cls7}
					      d="M972.91,188.68c.27-6.29,10.26,1.38,10.23,10.18-.04,9.4,2.56,16.85-.23,15.06-7.41-4.75-10.6-11.19-9.99-25.25Z"/>
				</g>
				<path
					id="Shirt-2"
					data-name="Shirt"
					className={styles.cls1}
					d="M1038.06,135c-2.64-6.25-11.65-22.35-16.2-23.41,2.75,2.21,14.26,16.2,6.95,20.31-6.74,3.79-11.64-1.24-14.7-5.42-8.38-11.44-26.79-23.83-34.51-15.53-7.95,8.54-.59,37.93-.27,46.77.25,6.88-2.33,12.13-3.98,16.01,18.02,10.12,45.13,8.29,68.42-3.36,1.12-11.1-2.9-28.77-5.69-35.38ZM993.7,145.64c-8.32.3-15.3-6.12-15.6-14.35-.3-8.22,6.21-15.13,14.53-15.43s15.3,6.12,15.6,14.35c.3,8.22-6.21,15.13-14.53,15.43Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-2" data-name="Stripes">
					<path
						className={styles.cls9}
						d="M1029.56,166.25c-6.76,2.2-13.63,3.62-20.4,4.2-10.92.94-21.74-.37-31.06-3.78.47-1.46.84-3.43.99-4.41,8.86,3.33,19.21,4.72,29.7,3.82,11.53-.99,23.38-4.55,34.38-10.32.13.7.49,3.64.53,4.65-4.6,2.32-9.34,4.27-14.14,5.83Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls9}
						d="M992.59,114.77c-8.91.32-15.9,7.75-15.58,16.56.32,8.81,7.82,15.72,16.73,15.4,8.91-.32,15.9-7.75,15.58-16.56-.32-8.81-7.82-15.72-16.73-15.4ZM993.66,144.55c-7.7.28-14.2-5.69-14.47-13.29s5.77-14.02,13.48-14.3,14.2,5.69,14.47,13.29-5.77,14.02-13.48,14.3Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls9}
						d="M1025.6,134.14c-5.19.19-10.82-5.03-12.76-7.4-.43-.52-.9-1.13-1.42-1.81-3.49-4.5-9.33-12.03-17.19-12.58l.15-2.18c8.84.61,15.06,8.63,18.77,13.42.51.66.97,1.25,1.39,1.76,2.53,3.08,9.46,8.79,13.61,5.67,1.2-.9,1.85-2.1,1.93-3.57.14-2.76-1.66-7.89-11.07-16.65l1.49-1.6c8.05,7.49,12.01,13.67,11.76,18.36-.11,2.11-1.08,3.91-2.8,5.2-1.21.91-2.52,1.32-3.86,1.37Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Run03_Mid">
					<path className={styles.cls8}
					      d="M1000.85,188.71c11.12-9.71,29.11-13.83,41.16-5.99-.28,1.27-.89,3.2-1.47,3.87-3.25,3.76-38.4,6.53-39.69,2.12Z"/>
					<path className={styles.cls6}
					      d="M996.87,190.67c4.12-5.01,26.8-14.83,41.31-8.37,12.43,5.54,27.77,22.76,32.21,36.92,7.8-10.5,17.07-4.12,17.95.11,1.59,7.69-11.14,19.37-22.08,19.17-6.36-7.96-43.58-27.3-63.31-22.94s-12.69-16.87-6.09-24.89Z"/>
					<path className={styles.cls3}
					      d="M981.45,112.95c6.29,4.73,34.07,16.19,50.04,4.24,4.26-3.19,7.43-7.03,8.15-12.88,1.88-15.35-11.25-18.81-22.45-21.03-10.55-2.09-26.74-3.59-34.68,6.24-1.71,2.12-6.47,19.36-1.06,23.43Z"/>
					<path className={styles.cls6}
					      d="M998.91,118.92c-34.51-20.39-62.62,18.34-52.5,30.25,9.51,11.18,13.07-25.07,41.14-11.48,8.59,4.16,22.26-12.33,11.36-18.77Z"/>
					<g>
						<path className={styles.cls7}
						      d="M1038.84,11.58c4.18,4.08,14.08,24.48,8.84,28.38-8.21,6.12-43.03,1.94-46.45,1.83-4.29-.13-8.59-1.85-10.33-4.8-2.83-4.81,1.9-12.45,5.68-16.19,14.69-14.49,29.06-22.09,42.25-9.22Z"/>
						<g>
							<path className={styles.cls6}
							      d="M1035.93,106.68c-3.21-4.42-4.9-9.92-4.74-15.37.17-5.63,2.23-11.34.75-16.77-2.01-7.4-9.7-11.55-16.8-14.46-10.77-4.42-21.89-7.97-33.23-10.59-2.89-.67-5.88-1.27-8.86-1.49,4.26-4.5,9.25-8.37,14.69-11.75.74.28,1.52.48,2.25.63,4.78.97,9.67,1.53,14.29,3.15,9.68,3.39,17.35,11.06,25.61,16.94,3.87,2.76,8.47,5.12,13.1,4.41,4.61-.71,8.14-4.27,12.23-6.47,2.56-1.38,5.41-2.16,8.32-2.41,4.13,1.69,7.95,3.89,9.92,7.07,4.95,7.98,6.2,19.6,2.54,26.58-6.2,11.82-14.02,16.89-23.05,22.24-3.87,2.29-7.91,3.99-12.03,5.21-1.25-2.43-3.34-4.67-4.98-6.93Z"/>
							<path className={styles.cls4}
							      d="M976.02,102.55c-4.12-3.56-7.95-7.52-11.33-11.77-5.87-7.39-6.07-14.69-3.28-23.4,2.42-7.56,6.49-13.94,11.64-19.38,2.97.22,5.96.82,8.86,1.49,11.34,2.62,22.47,6.17,33.23,10.59,7.09,2.91,14.79,7.06,16.8,14.46,1.48,5.44-.58,11.14-.75,16.77-.16,5.46,1.53,10.95,4.74,15.37,1.63,2.25,3.73,4.5,4.98,6.93-27.17,8.09-57.74-4.89-64.88-11.06Z"/>
							<path className={styles.cls4}
							      d="M1042.99,61.38c-4.63.71-9.23-1.66-13.1-4.41-8.26-5.88-15.93-13.56-25.61-16.94-4.62-1.62-9.51-2.18-14.29-3.15-.74-.15-1.52-.35-2.25-.63.79-.49,1.58-.97,2.39-1.44,9.78-5.69,18.95-9.5,30.64-5.59,5.86,1.96,11.13,7.61,15.7,11.6,5.35,4.66,8.86,5.57,15.74,7.87,3.19,1.07,7.4,2.21,11.32,3.81-2.91.25-5.75,1.03-8.32,2.41-4.09,2.2-7.62,5.76-12.23,6.47Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls2}
								      d="M1006.81,77.43c4.41,4,9.79,5.23,12.03,2.75,2.24-2.47.48-7.72-3.93-11.72-4.41-3.99-9.79-5.23-12.03-2.75-2.24,2.48-.48,7.72,3.93,11.72Z"/>
								<path className={styles.cls2}
								      d="M1033.43,53.7c4.65,3.32,9.71,4.19,11.31,1.94,1.6-2.25-.88-6.77-5.52-10.09-4.65-3.32-9.71-4.19-11.31-1.94-1.6,2.25.88,6.77,5.52,10.09Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls2}
									      d="M1051.36,69.66c.4-1.89,1.98-4.17,4.05-6.12h0s0,0,0,0c0,0,0,0,0,0h0c2.02-2,4.35-3.48,6.25-3.8,9.02-1.53,9.1,8.92,4.06,14.63h0s0,0,0,0c0,0,0,0,0,.01h0c-5.89,4.82-16.26,4.3-14.35-4.71Z"/>
									<path className={styles.cls2}
									      d="M1075.34,76.28c-.16-.04-.34-.03-.54.02-.65.18-.73.86-.97,1.39-.33.73-.8,1.47-1.33,2.06-1.71,1.92-3.65,2.56-6,2.88l-.06-.08s-.03.06-.05.1c-.04,0-.07,0-.11.02l.06.08c-.96,2.17-2.11,3.85-4.43,4.97-1.6.77-5.42,1.38-7.22.63-.64-.27-1.35.03-1.6.66-.25.63.06,1.36.7,1.63,2.79,1.18,7.42.21,9.24-.67.7-.34,1.32-.72,1.87-1.13,1.5-1.13,2.45-2.51,3.19-3.94,1.58-.31,3.17-.84,4.67-1.97.55-.41,1.08-.9,1.6-1.48.75-.84,1.75-2.15,1.97-3.28.15-.75-.23-1.73-.98-1.88Z"/>
								</g>
								<path className={styles.cls2}
								      d="M1067.87,85.01c-.27.23-.63.35-1.01.3-.73-.09-1.27-.76-1.2-1.49,0-.08.26-3.8-2.56-9.79-.32-.68-.04-1.48.63-1.78.67-.3,1.46,0,1.78.68,3.19,6.78,2.82,11.03,2.81,11.21-.03.35-.2.66-.45.87Z"/>
							</g>
							<path className={styles.cls6}
							      d="M1066.3,61.97c.47,1.34-1.05,3.1-3.41,3.94-2.36.84-4.65.43-5.12-.91-.47-1.34,1.05-3.1,3.41-3.94s4.65-.43,5.12.91Z"/>
							<path className={styles.cls6}
							      d="M1036.93,46.39c-.28.85-1.76,1.12-3.31.6s-2.57-1.62-2.28-2.46c.28-.85,1.76-1.12,3.31-.6,1.54.51,2.57,1.62,2.28,2.46Z"/>
							<path className={styles.cls6}
							      d="M1012.23,69.38c-.27,1.12-2.13,1.64-4.15,1.16-2.02-.48-3.44-1.79-3.17-2.91.27-1.12,2.13-1.64,4.15-1.16,2.02.48,3.44,1.79,3.17,2.91Z"/>
						</g>
					</g>
				</g>
				<path
					id="Stripe-2"
					data-name="Stripe"
					className={styles.cls9}
					d="M971.36,97.01c10.96-27.66,37.67-53.41,61.89-59.86,1.86-.49-8.61-10.48-14.89-9.52-16.2,2.48-55.08,37.05-55.29,59.13-.03,3.28,7.26,12.84,8.29,10.24Z"
					style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
				/>
				<path id="Puppy_Run03_Upper" className={styles.cls7}
				      d="M935.36,96.88c3.42,5.25,22.43,20.27,27.32,15.85,7.66-6.93,9.49-43.53,9.98-47.05.61-4.42-.38-9.22-3.08-11.71-4.4-4.05-13-1.05-17.46,1.93-17.31,11.54-27.55,24.41-16.77,40.98Z"/>
			</g>
			<g id="Puppy_Run02">
				<g id="Puppy_Run02_Lower">
					<path className={styles.cls5}
					      d="M528.77,285.57c0,5.21,31.89,9.43,71.23,9.43,39.34,0,71.23-4.22,71.23-9.43,0-5.21-31.89-9.43-71.23-9.43-39.34,0-71.23,4.22-71.23,9.43Z"/>
					<path className={styles.cls8}
					      d="M622.61,222.04c0,28.76-5.5,44.48-38.01,50.9,7.88,1.15,11.05,8.29,7.75,12.38-1.38,1.71-20.56,1.62-22.35.11-5.92-4.99-8.52-18.9-6.03-20.24,16.94-9.04,19.82-32.59,20.72-40.3,1.63-14.02,37.92-17.89,37.92-2.86Z"/>
					<g>
						<path className={styles.cls7}
						      d="M555.16,215.63c2.47.25,4.97.36,7.5.23,3.02-.14,19.97-5.69,18.81,1-.18,1.01-.81,1.93-1.66,2.56-2.84,2.12-5.8,4.21-9.02,5.47-5.3,2.08-10.78,1.73-15.9.26-.64-1.29-1.02-2.71-1.07-4.15-.07-1.87.41-3.75,1.34-5.37Z"/>
						<path className={styles.cls6}
						      d="M541.44,215c.3-.3.74-.57,1.35-.81,2.82-1.11,6.56.66,9.31,1.06,1.02.15,2.04.27,3.07.38-.92,1.63-1.4,3.5-1.34,5.37.05,1.44.43,2.86,1.07,4.15-2.27-.66-4.47-1.53-6.55-2.52-1.46-.69-9.49-5.07-6.91-7.64Z"/>
					</g>
					<path className={styles.cls6}
					      d="M599.49,234.64c-37.6,7.33-31.38-33.8-23.45-49.16,5.28-10.24-4.13-34.53,2.08-48.62s36.53-10.39,39.98-8.11c17.32,11.45,24.65,40.27,22.99,64.2-1.37,19.64-13.72,36.26-41.6,41.7Z"/>
					<path className={styles.cls7}
					      d="M570.01,206.78c.5-6.27,10.2,1.75,9.85,10.54-.37,9.39,1.95,16.93-.77,15.04-7.24-5.01-10.2-11.56-9.08-25.59Z"/>
				</g>
				<path
					id="Shirt-3"
					data-name="Shirt"
					className={styles.cls1}
					d="M637.05,155.46c-2.42-6.34-10.84-22.75-15.35-23.97,2.67,2.31,13.67,16.7,6.22,20.55-6.87,3.54-11.55-1.68-14.5-5.95-4.54-6.56-25.92-24.77-33.93-16.76s-1.95,37.89-1.95,46.73c0,6.88-2.76,12.04-4.56,15.86,17.65,10.76,44.8,9.9,68.49-.9,1.51-11.05-1.86-28.86-4.42-35.56ZM592.34,164.51c-8.33,0-15.08-6.67-15.08-14.9s6.75-14.9,15.08-14.9,15.08,6.67,15.08,14.9-6.75,14.9-15.08,14.9Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-3" data-name="Stripes">
					<path
						className={styles.cls9}
						d="M627.43,186.39c-6.83,1.95-13.75,3.13-20.54,3.47-10.94.54-21.71-1.15-30.91-4.89.52-1.44.96-3.4,1.14-4.37,8.74,3.65,19.03,5.41,29.55,4.89,11.56-.58,23.53-3.71,34.73-9.08.1.7.36,3.66.36,4.66-4.68,2.15-9.49,3.93-14.34,5.32Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls9}
						d="M592.34,133.62c-8.92,0-16.17,7.17-16.17,15.99s7.25,15.99,16.17,15.99,16.17-7.17,16.17-15.99-7.25-15.99-16.17-15.99ZM592.34,163.41c-7.71,0-13.98-6.19-13.98-13.8s6.27-13.8,13.98-13.8,13.98,6.19,13.98,13.8-6.27,13.8-13.98,13.8Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls9}
						d="M624.63,154.16c-5.2,0-10.63-5.42-12.48-7.85-.41-.54-.86-1.16-1.36-1.86-3.32-4.62-8.89-12.36-16.73-13.18l.23-2.17c8.82.92,14.74,9.16,18.28,14.08.49.67.93,1.29,1.32,1.81,2.41,3.17,9.14,9.13,13.4,6.15,1.23-.86,1.92-2.04,2.05-3.5.24-2.75-1.37-7.94-10.46-17.03l1.55-1.55c7.78,7.78,11.51,14.09,11.09,18.77-.19,2.11-1.22,3.87-2.98,5.1-1.24.87-2.57,1.23-3.91,1.23Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Run02_Mid">
					<path className={styles.cls8}
					      d="M631.54,217.65c-.59.74-1.52,1.74-2.83,3.05-3.05-.61-7.97-10.35-8.4-13.01,6.18,1.24,10.61,8.56,11.24,9.97Z"/>
					<path className={styles.cls6}
					      d="M583.12,141.56c-7.69,13.18-8.45,35.01-1.87,41.14,6.73,6.28,32.91,11.17,35.26-.25,2.85-13.83-14.2-10.56-16.49-13.95s-.31-13.36,1.23-17.89c3.06-9.04-11.73-20-18.12-9.06Z"/>
					<path className={styles.cls6}
					      d="M622.42,209c14.5,13.55,14.84,43.08-1.19,50.45-15.98,7.34-24.96,1.98-33.63,3.04,8.29,13.92-11.18,21.49-14.39,15.61-5.71-10.48-8.23-30.68,3.11-27.8,10.51,2.66,23.41-4.09,12.64-14.86-15.32-15.32,24.11-35.16,33.45-26.43Z"/>
					<path className={styles.cls3}
					      d="M579.3,130.55c6.29,4.73,34.07,16.19,50.04,4.24,4.26-3.19,7.43-7.03,8.15-12.88,1.88-15.35-11.25-18.81-22.45-21.03-10.55-2.09-26.74-3.59-34.68,6.24-1.71,2.12-6.47,19.36-1.06,23.43Z"/>
					<g>
						<path className={styles.cls7}
						      d="M644.71,34.7c3.99,4.26,12.98,25.08,7.58,28.74-8.48,5.75-43.07.04-46.48-.22-4.28-.32-8.5-2.22-10.1-5.25-2.62-4.93,2.45-12.36,6.39-15.92,15.31-13.83,30.01-20.79,42.61-7.35Z"/>
						<g>
							<path className={styles.cls6}
							      d="M624.65,133.17c-2.21-4.99-2.72-10.72-1.42-16.02,1.34-5.47,4.54-10.62,4.24-16.25-.42-7.66-7.08-13.32-13.41-17.65-9.61-6.57-19.75-12.36-30.29-17.29-2.69-1.26-5.49-2.47-8.35-3.31,5.1-3.51,10.8-6.26,16.82-8.42.66.43,1.38.79,2.07,1.09,4.47,1.95,9.14,3.52,13.32,6.06,8.76,5.33,14.65,14.44,21.5,21.91,3.21,3.5,7.22,6.78,11.89,7.05,4.66.27,8.85-2.47,13.31-3.77,2.79-.81,5.74-.98,8.64-.62,3.69,2.52,6.96,5.47,8.22,8.98,3.17,8.84,1.98,20.46-3.07,26.53-8.53,10.26-17.23,13.59-27.19,16.94-4.26,1.43-8.57,2.25-12.85,2.59-.71-2.64-2.29-5.27-3.42-7.81Z"/>
							<path className={styles.cls4}
							      d="M566.92,116.63c-3.29-4.34-6.2-9.01-8.62-13.88-4.2-8.45-2.87-15.63,1.68-23.57,3.94-6.89,9.25-12.27,15.43-16.53,2.86.84,5.66,2.05,8.35,3.31,10.54,4.93,20.68,10.72,30.29,17.29,6.33,4.33,12.99,9.99,13.41,17.65.31,5.62-2.9,10.78-4.24,16.25-1.3,5.3-.79,11.03,1.42,16.02,1.13,2.55,2.71,5.18,3.42,7.81-28.26,2.24-55.45-16.84-61.14-24.36Z"/>
							<path className={styles.cls4}
							      d="M641.01,90.34c-4.67-.27-8.68-3.55-11.89-7.05-6.85-7.47-12.75-16.58-21.5-21.91-4.18-2.54-8.84-4.11-13.32-6.06-.69-.3-1.41-.66-2.07-1.09.87-.31,1.75-.62,2.64-.9,10.75-3.52,20.52-5.34,31.13.93,5.32,3.14,9.29,9.77,12.94,14.63,4.26,5.68,7.5,7.29,13.75,10.98,2.9,1.71,6.77,3.71,10.27,6.09-2.89-.37-5.84-.2-8.64.62-4.46,1.3-8.65,4.04-13.31,3.77Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls2}
								      d="M602.27,98.49c3.48,4.83,8.48,7.16,11.19,5.2,2.7-1.95,2.08-7.45-1.4-12.28-3.47-4.83-8.48-7.16-11.19-5.2-2.7,1.95-2.08,7.45,1.4,12.28Z"/>
								<path className={styles.cls2}
								      d="M633.26,80.84c3.85,4.22,8.62,6.13,10.65,4.26,2.03-1.87.56-6.8-3.29-11.02-3.85-4.22-8.62-6.13-10.65-4.26-2.03,1.87-.56,6.8,3.29,11.02Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls2}
									      d="M647.47,100.19c.79-1.76,2.81-3.67,5.23-5.14h0s0,0,0,0c0,0,0,0,.01,0h0c2.39-1.53,4.98-2.5,6.9-2.42,9.14.38,7.03,10.62.92,15.16h0s0,0,0,0c0,0,0,0-.01,0h0c-6.76,3.48-16.8.81-13.05-7.6Z"/>
									<path className={styles.cls2}
									      d="M669.54,111.67c-.15-.07-.33-.1-.53-.09-.67.04-.89.69-1.24,1.16-.48.65-1.09,1.27-1.73,1.74-2.08,1.52-4.11,1.74-6.47,1.56l-.04-.09s-.04.06-.06.09c-.04,0-.07,0-.11,0l.04.09c-1.39,1.92-2.87,3.33-5.37,3.93-1.73.42-5.59.22-7.2-.9-.57-.4-1.33-.25-1.7.31-.38.57-.22,1.35.34,1.74,2.48,1.73,7.22,1.75,9.17,1.28.76-.18,1.44-.43,2.06-.71,1.71-.79,2.92-1.95,3.94-3.19,1.61.03,3.27-.16,4.98-.95.62-.29,1.25-.65,1.88-1.12.91-.66,2.16-1.74,2.61-2.8.3-.71.13-1.74-.57-2.05Z"/>
								</g>
								<path className={styles.cls2}
								      d="M660.41,118.64c-.31.17-.68.21-1.05.08-.7-.24-1.08-1.01-.86-1.71.02-.08,1.05-3.66-.46-10.1-.17-.73.27-1.45.98-1.61.71-.16,1.43.31,1.6,1.03,1.7,7.3.46,11.38.41,11.55-.11.34-.34.6-.62.76Z"/>
							</g>
							<path className={styles.cls6}
							      d="M663.68,95.79c.18,1.41-1.68,2.81-4.16,3.14-2.48.33-4.64-.55-4.82-1.96-.18-1.41,1.68-2.81,4.16-3.14s4.64.55,4.82,1.96Z"/>
							<path className={styles.cls6}
							      d="M638.22,74.42c-.45.77-1.96.72-3.36-.1s-2.17-2.12-1.72-2.88c.45-.77,1.96-.72,3.36.1,1.4.82,2.17,2.12,1.72,2.88Z"/>
							<path className={styles.cls6}
							      d="M609.26,91.74c-.5,1.04-2.43,1.16-4.3.27-1.88-.9-3-2.47-2.5-3.51.5-1.04,2.43-1.16,4.3-.27,1.88.9,3,2.47,2.5,3.51Z"/>
						</g>
					</g>
				</g>
				<g id="Stripe-3" data-name="Stripe">
					<path
						className={styles.cls9}
						d="M563.52,110.24c16.5-24.77,47.99-44.37,73.02-45.62,1.92-.1-6.24-12.05-12.57-12.42-16.36-.96-61.61,24.73-66.42,46.28-.72,3.2,4.42,14.07,5.97,11.75Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<path id="Puppy_Run02_Upper" className={styles.cls7}
				      d="M528.16,95.16c1.63,6.05,15.08,26.2,21.1,23.5,9.42-4.23,22.46-38.48,24.01-41.67,1.95-4.01,2.49-8.89.69-12.09-2.93-5.21-12.04-5.01-17.2-3.55-20.02,5.63-33.74,14.72-28.59,33.81Z"/>
			</g>
			<g id="Puppy_Run01">
				<g id="Puppy_Run01_Lower">
					<path className={styles.cls5}
					      d="M138.79,285.57c0,4.47,27.4,8.1,61.21,8.1,33.81,0,61.21-3.63,61.21-8.1,0-4.48-27.4-8.1-61.21-8.1-33.81,0-61.21,3.63-61.21,8.1Z"/>
					<path className={styles.cls8}
					      d="M201.88,187.11c4.89-4.27,29.43-10.12,42.68-1.36,14.13,9.34,22.79,27.74,25.94,35.89,9.41-9.08,15.42-4.54,16.79-.12,1.51,4.85-14.64,22.28-22.45,21.4-5.25-11.01-32.95-28.7-52.97-28.7-22.86,0-17.82-20.27-9.99-27.11Z"/>
					<path className={styles.cls8}
					      d="M193.98,118.53c-34.51-20.39-62.62,18.34-52.5,30.25,9.51,11.18,13.07-25.07,41.14-11.48,8.59,4.16,22.26-12.33,11.36-18.77Z"/>
					<g>
						<path className={styles.cls7}
						      d="M160.89,179.49c2.4.64,4.86,1.14,7.37,1.42,3.01.34,20.62-2.46,18.41,3.97-.33.97-1.11,1.78-2.04,2.26-3.14,1.64-6.39,3.24-9.77,3.97-5.57,1.22-10.92,0-15.74-2.27-.43-1.38-.58-2.84-.4-4.27.23-1.86,1-3.63,2.17-5.09Z"/>
						<path className={styles.cls6}
						      d="M147.45,176.7c.34-.25.82-.44,1.46-.59,2.96-.65,6.38,1.69,9.02,2.52.98.31,1.97.59,2.97.86-1.17,1.46-1.94,3.24-2.17,5.09-.18,1.43-.03,2.89.4,4.27-2.14-1.01-4.17-2.22-6.07-3.52-1.33-.91-8.57-6.51-5.61-8.63Z"/>
					</g>
					<path className={styles.cls6}
					      d="M203.02,215.61c-37.6,7.33-31.38-33.8-23.45-49.16,5.28-10.24-4.13-34.53,2.08-48.62s36.53-10.39,39.98-8.11c17.32,11.45,24.65,40.27,22.99,64.2-1.37,19.64-13.72,36.26-41.6,41.7Z"/>
					<path className={styles.cls7}
					      d="M173.54,187.74c.5-6.27,10.2,1.75,9.85,10.54-.37,9.39,1.95,16.93-.77,15.04-7.24-5.01-10.2-11.56-9.08-25.59Z"/>
				</g>
				<path
					id="Shirt-4"
					data-name="Shirt"
					className={styles.cls1}
					d="M240.57,136.43c-.62-1.63-1.63-3.91-2.88-6.43-3.03,0-7.01-1.76-13.24-10.07-12.91-17.21-33.43-17.64-41.45-9.62s-1.95,37.89-1.95,46.73c0,6.88-2.76,12.04-4.56,15.86,17.65,10.76,44.8,9.9,68.49-.9,1.51-11.05-1.86-28.86-4.42-35.56ZM208.86,145.47c-8.33,0-15.08-6.67-15.08-14.9s6.75-14.9,15.08-14.9,15.08,6.67,15.08,14.9-6.75,14.9-15.08,14.9Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-4" data-name="Stripes">
					<path
						className={styles.cls9}
						d="M230.95,167.36c-6.83,1.95-13.75,3.13-20.54,3.47-10.94.54-21.71-1.15-30.91-4.89.52-1.44.96-3.4,1.14-4.37,8.74,3.65,19.03,5.41,29.55,4.89,11.56-.58,23.53-3.71,34.73-9.08.1.7.36,3.66.36,4.66-4.68,2.15-9.49,3.93-14.34,5.32Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls9}
						d="M209.21,114.76c-8.92,0-16.17,7.17-16.17,15.99s7.25,15.99,16.17,15.99,16.17-7.17,16.17-15.99-7.25-15.99-16.17-15.99ZM209.21,144.56c-7.71,0-13.98-6.19-13.98-13.8s6.27-13.8,13.98-13.8,13.98,6.19,13.98,13.8-6.27,13.8-13.98,13.8Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls9}
						d="M238.53,131.73c-5.2,0-10.8-5.45-12.65-7.88-.41-.54-.86-1.16-1.36-1.86-3.32-4.62-8.89-12.36-16.73-13.18l.23-2.17c8.82.92,14.74,9.16,18.28,14.08.49.67.93,1.29,1.32,1.81,1.92,2.53,5.86,6.83,9.83,6.98.39.67.95,1.97,1.08,2.23Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Puppy_Run01_Mid">
					<path className={styles.cls6}
					      d="M202.74,215.29c-36.6,11.5-54.98-7.21-67.14-19.38-7.19,6.72-20.2,2.26-22.9-3.45-1.8-3.81,24.17-23.7,28.8-15.94,12.24,20.5,32.44,8.58,40.62,2.11,11.06-8.76,34.96,32.16,20.62,36.66Z"/>
					<path className={styles.cls6}
					      d="M201.53,135.8c14.7,37.3,62.24,26.45,61.68,9.33-.52-15.91-27.39,10.81-41.18-17.15-4.22-8.56-25.14-3.96-20.5,7.83Z"/>
					<path className={styles.cls3}
					      d="M182.53,112.02c6.29,4.73,34.07,16.19,50.04,4.24,4.26-3.19,7.43-7.03,8.15-12.88,1.88-15.35-11.25-18.81-22.45-21.03-10.55-2.09-26.74-3.59-34.68,6.24-1.71,2.12-6.47,19.36-1.06,23.43Z"/>
					<g>
						<path className={styles.cls7}
						      d="M237.83,11.04c4.33,3.92,14.98,23.94,9.89,28.03-7.98,6.42-42.93,3.54-46.35,3.56-4.29.03-8.65-1.52-10.5-4.41-3.01-4.7,1.43-12.51,5.08-16.39,14.13-15.03,28.22-23.16,41.87-10.79Z"/>
						<g>
							<path className={styles.cls6}
							      d="M238.48,106.19c-3.37-4.3-5.27-9.73-5.31-15.19-.04-5.63,1.8-11.41.12-16.79-2.28-7.32-10.13-11.18-17.33-13.82-10.92-4.02-22.18-7.14-33.61-9.34-2.92-.56-5.93-1.05-8.91-1.16,4.09-4.66,8.93-8.71,14.24-12.29.75.25,1.53.42,2.28.55,4.82.79,9.72,1.17,14.4,2.61,9.8,3.02,17.75,10.41,26.22,15.97,3.97,2.61,8.66,4.8,13.25,3.92,4.58-.88,7.97-4.57,11.98-6.92,2.51-1.47,5.32-2.36,8.22-2.71,4.19,1.54,8.09,3.59,10.17,6.69,5.24,7.79,6.93,19.35,3.53,26.47-5.76,12.04-13.38,17.4-22.21,23.09-3.78,2.43-7.76,4.28-11.82,5.66-1.34-2.38-3.51-4.55-5.23-6.74Z"/>
							<path className={styles.cls4}
							      d="M178.46,104.3c-4.25-3.41-8.22-7.22-11.76-11.34-6.14-7.17-6.61-14.45-4.15-23.27,2.13-7.65,5.96-14.17,10.91-19.8,2.98.11,5.99.6,8.91,1.16,11.43,2.2,22.68,5.33,33.61,9.34,7.2,2.65,15.04,6.51,17.33,13.82,1.68,5.38-.17,11.16-.12,16.79.04,5.46,1.94,10.89,5.31,15.19,1.72,2.19,3.89,4.35,5.23,6.74-26.85,9.1-57.89-2.73-65.25-8.63Z"/>
							<path className={styles.cls4}
							      d="M243.85,60.65c-4.6.88-9.28-1.31-13.25-3.92-8.47-5.57-16.43-12.95-26.22-15.97-4.68-1.44-9.58-1.82-14.4-2.61-.74-.12-1.53-.3-2.28-.55.77-.52,1.55-1.03,2.33-1.52,9.56-6.05,18.58-10.2,30.41-6.73,5.92,1.74,11.41,7.19,16.13,11.01,5.52,4.46,9.06,5.23,16.03,7.27,3.23.95,7.47,1.93,11.45,3.39-2.9.35-5.71,1.24-8.22,2.71-4,2.35-7.4,6.04-11.98,6.92Z"/>
						</g>
						<g>
							<g>
								<path className={styles.cls2}
								      d="M208.29,78.05c4.55,3.83,9.98,4.86,12.12,2.3,2.14-2.56.19-7.73-4.36-11.56-4.55-3.83-9.98-4.86-12.12-2.3-2.14,2.56-.19,7.73,4.36,11.56Z"/>
								<path className={styles.cls2}
								      d="M234,53.34c4.77,3.15,9.86,3.83,11.37,1.52,1.51-2.31-1.13-6.73-5.9-9.88-4.77-3.15-9.86-3.83-11.37-1.52-1.51,2.31,1.13,6.73,5.9,9.88Z"/>
							</g>
							<g>
								<g>
									<path className={styles.cls2}
									      d="M252.52,68.62c.33-1.9,1.82-4.24,3.81-6.27h0s0,0,0,0c0,0,0,0,0,0h0c1.94-2.07,4.22-3.64,6.1-4.04,8.96-1.87,9.42,8.57,4.6,14.47h0s0,0,0,0c0,0,0,0,0,.01h0c-5.7,5.03-16.09,4.9-14.52-4.17Z"/>
									<path className={styles.cls2}
									      d="M276.73,74.34c-.16-.03-.35-.02-.54.04-.64.2-.7.89-.92,1.42-.31.74-.74,1.5-1.25,2.11-1.64,1.98-3.55,2.7-5.89,3.1l-.06-.08s-.03.07-.04.1c-.03,0-.07.01-.11.02l.06.08c-.87,2.2-1.97,3.93-4.24,5.13-1.57.83-5.37,1.59-7.2.89-.65-.24-1.35.08-1.58.72-.23.64.11,1.36.76,1.6,2.83,1.07,7.43-.07,9.21-1.01.69-.36,1.29-.77,1.82-1.2,1.46-1.19,2.36-2.6,3.04-4.06,1.56-.37,3.13-.96,4.59-2.14.53-.43,1.05-.94,1.54-1.54.72-.86,1.67-2.21,1.84-3.35.12-.76-.3-1.72-1.05-1.85Z"/>
								</g>
								<path className={styles.cls2}
								      d="M269.59,83.34c-.26.24-.61.37-.99.34-.73-.06-1.3-.71-1.26-1.45,0-.08.12-3.8-2.92-9.68-.34-.67-.09-1.47.56-1.8.65-.33,1.46-.05,1.81.61,3.44,6.66,3.23,10.92,3.22,11.09-.02.36-.18.67-.42.89Z"/>
							</g>
							<path className={styles.cls6}
							      d="M267.16,60.38c.52,1.32-.94,3.14-3.26,4.06-2.32.92-4.63.6-5.15-.72-.52-1.32.94-3.14,3.26-4.06s4.63-.6,5.15.72Z"/>
							<path className={styles.cls6}
							      d="M237.23,45.9c-.25.86-1.72,1.18-3.28.73s-2.63-1.52-2.37-2.37c.25-.86,1.72-1.18,3.28-.73,1.56.46,2.63,1.52,2.37,2.37Z"/>
							<path className={styles.cls6}
							      d="M213.4,69.79c-.23,1.13-2.07,1.72-4.11,1.32-2.04-.41-3.51-1.66-3.28-2.79.23-1.13,2.07-1.72,4.11-1.32,2.04.41,3.51,1.66,3.28,2.79Z"/>
						</g>
					</g>
				</g>
				<g id="Stripe-4" data-name="Stripe">
					<path
						className={styles.cls9}
						d="M173.6,98.94c9.92-28.05,35.65-54.78,59.61-62.13,1.84-.56-9-10.15-15.23-8.96-16.1,3.08-53.66,39.08-53.05,61.15.09,3.28,7.74,12.56,8.67,9.93Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<path id="Puppy_Run01_Upper" className={styles.cls7}
				      d="M137.62,100.15c3.61,5.12,23.17,19.42,27.89,14.82,7.4-7.21,7.86-43.85,8.22-47.39.45-4.44-.72-9.2-3.51-11.59-4.54-3.88-13.03-.56-17.37,2.58-16.87,12.18-26.62,25.42-15.22,41.58Z"/>
			</g>
		</svg>
	);
};