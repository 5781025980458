import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import styles from "./GreenlineGameCard.scss";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import find from "lodash/find";
import * as ProviderActions from "actions/ProviderActions";
import * as ClinicApi from "api/ClinicApi";
import Button from "components/common/Button";
import CountdownTimer from "components/games/elements/CountdownTimer";
import LeaderBoard from "components/games/elements/LeaderBoard";
import PrintContentContainer from "components/common/PrintContentContainer";
import { formatUSD } from "utils/numeric";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as OrgNodeTypes from "constants/OrgNodeTypes";
import * as UserPermissions from "constants/UserPermissions";
import { DEATH_MATCH, PUPPY_POOL_PARTY, LEAF_PARTY, DEATH_MATCH_NGP } from 'constants/GameTypes';
import GameCompletionDetailsOverlay from "components/games/widgets/GameCompletionDetailsOverlay";
import { getLinkLocation } from "utils/ClinicAdminLinks";
import { addOrdinalSuffixToNumber } from "utils/numeric";

function GamesDeathMatchCard(props) {
    const [isLoadingGameEndStats, setIsLoadingGameEndStats] = useState(false);
    const [gameCompletionStats, setGameCompletionStats] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const history = useHistory();

    const { game, primaryClinicId } = props;
    // Uncomment this line when generating the email scripts
    // const teams = game.teams ? orderBy(game.teams, ["growth", "previous"], ["desc", "asc"]) : [];
    const growthLabel = useMemo(() =>
        (
            props.game?.type === PUPPY_POOL_PARTY ||
            props.game?.type === LEAF_PARTY
        ) ? "Growth % VLY": "Growth %",
    [props.game]);

    useEffect(() => {
        if (!!primaryClinicId && !game.isRunning) {
            // Only load for historic games and if the user has a primary clinic
            handleLoadGameEndStats();
        }
    }, [game.id, game.isRunning, primaryClinicId]);

    useEffect(() => {
        if(!props.clinicId && props.clinics && props.nodeId && !props.userIsRep) {
            props.getClinicsForNode(props.nodeId);
        }
    }, [props.nodeId, props.currentOrgNode]);

    const handleLoadGameEndStats = async () => {
        setIsLoadingGameEndStats(true);
        try {
            const response = await ClinicApi.getGreenlineGameEndStats(primaryClinicId, game.id);
            // Find the completion / prize data for the user's primary clinic
            const gameCompletionStatsForClinic = find(response.body.gameClinicPrizesDto, { clinicId: Number(primaryClinicId) });
            setGameCompletionStats(gameCompletionStatsForClinic);
        } catch (e) {} finally {
            setIsLoadingGameEndStats(false);
        }
    };

    // Comment out these lines (allTeams & teams) when generating the email scripts
    const teams = game.teams ? orderBy(game.teams, ["growth", "previous"], ["desc", "asc"]) : [];
    // const teams = !props.clinicId ? allTeams : allTeams.slice(0, 10); // This is a top 10, right?

    // if (props.clinicId && !find(teams, t => t.clinicId === props.clinicId)) {
    //     // Add our clinic to the end of the list since it's not in the top 10
    //     const myTeam = find(game.teams, t => t.clinicId === props.clinicId); // If there's no clinicId (impersonating), this will find nothing
    //     if (myTeam) {
    //         teams.push(myTeam);
    //     }
    // }

    // const maxValue = Math.ceil(game.data.options.maxValue / 10) * 10; // Round to nearest 10

    let visibleTeams;

    if(!showDetails && props.dashboard) {
        visibleTeams = 5;
    }

    const renderTime = () => {
        if (props.dashboard) {
            return (
                <div className={styles.timeRemaining}>
                    <CountdownTimer endDate={game.endDate}/>
                </div>
            );
        } else {

            return (
                <div className={styles.timeRemaining}>
                    <span>{game.startDate} - {game.endDate}</span>
                </div>
            );
        }
    }

    const renderLevels = () => {
        if(props.dashboard) {
            return null
        }

        return (
            <div className={styles.prizeLevels}>
                {map(game.details.Levels, level => (
                    <div
                        key={`prize_${level.Index}`}
                        className={styles.levelDetails}
                    >
                        <h5>{level.DisplayName}</h5>
                        <p>{formatUSD(level.PrizeAmount, { useCommas: true})}</p>
                    </div>
                ))}
            </div>
        )
    }

    const renderShowDetails = () => {
        if(!props.dashboard) {
            return null;
        }

        return (
            <div className={classnames(styles.details, "addToPrintHeight", {
                [styles.viewingAll]: showDetails,
            })}>
                <Button
                    onClick={() => setShowDetails(!showDetails)}
                    type={"outline"}
                    text
                ><span>Show all</span><i className={classnames("fas", {
                    "fa-chevron-down": !showDetails,
                    "fa-chevron-up": showDetails,
                })}/></Button>
            </div>
        );
    }

    const renderFooterDetails = () => {
        if (props.dashboard) {
            return null;
        }

        return (
            <div className={classnames(styles.footerDetails, "addToPrintHeight")}>
                <span>Report Date: {moment(game.reportDate).format("MM/DD/YY")}</span>
            </div>
        );
    };

    const renderCompletionDetails = () => {
        if (gameCompletionStats) {
            if (props.dashboard) {
                const { rank } = gameCompletionStats;
                const rankText = addOrdinalSuffixToNumber(rank);
                return (
                    <GameCompletionDetailsOverlay
                        wide
                        onClick={!!primaryClinicId ? () => history.push(getLinkLocation("historic-games", primaryClinicId)) : null}
                    >
                        <span>You won <em>{rankText}</em> place!</span>
                        <br />
                        <span>Click in to view your prize.</span>
                    </GameCompletionDetailsOverlay>
                );
            }

            const { doses, prizeDisplayName, prizeAmount } = gameCompletionStats;
            const wonText = prizeDisplayName || `$${prizeAmount || 0}`;
            return (
                <GameCompletionDetailsOverlay>
                    You sold <em>{doses}</em> doses and won {wonText}!
                </GameCompletionDetailsOverlay>
            );
        }

        return null;
    };

    return (
        <div
            className={classnames(styles.root, styles.deathMatch, {
                [styles.dashboard]: props.dashboard,
                [styles.historic]: !game.isCurrent,
            })}
        >
            <PrintContentContainer allowPrint={!props.dashboard && (props.game.gameType === DEATH_MATCH || props.game.gameType === DEATH_MATCH_NGP)}>
                <div id={`game-${props.game.greenlineGameId}`} className={styles.gameContainer}>
                    <h4>{game.name}</h4>
                    {renderTime()}
                    {renderLevels()}
                    <div className={classnames(styles.top10, "subtractFromPrintHeight")}>
                        <LeaderBoard
                            nodeId={props.nodeId}
                            className="addToPrintHeight"
                            game={props.game}
                            teams={teams}
                            numVisible={visibleTeams}
                            labels={["Teams", growthLabel, "Current", "Previous"]}
                            subLabels={props.game?.includeHomeDeliveryDoses ? ["", "", "(Including Home Delivery)", "(Including Home Delivery)"] : []}
                            clinicId={props.clinicId}
                            progressOnly={props.dashboard} // Don't show dose counts
                            showDosesOnlyForClinicId={props.dashboard ? undefined : props.clinicId} // Will be ignored if `progressOnly` is `true`
                            isAdminView={props.canViewProviderBiGames}
                        />
                    </div>
                    {renderShowDetails()}
                    {renderFooterDetails()}
                    {renderCompletionDetails()}
                </div>
            </PrintContentContainer>
        </div>
    )
}

GamesDeathMatchCard.propTypes = {
    game: PropTypes.object,
    dashboard: PropTypes.bool,
    clinicId: PropTypes.number,
    nodeId: PropTypes.number,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const currentOrgNode = state.entities.orgNode?.other || {};

        //    Permissions
        const canViewProviderInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_INFO, userProfile);
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        return {
            canViewProviderInfo,
            canViewProviderBiGames,
            clinics: state.entities.nodeClinics[ownProps.nodeId],
            userIsRep: ((currentOrgNode?.nodeType === OrgNodeTypes.TERRITORY) && (currentOrgNode?.nodeId === userProfile.nodeId)),
            primaryClinicId: get(userProfile, "primaryClinic.id"),
        }
    },
    (dispatch) => ({
        getClinicsForNode: (nodeId) => dispatch(ProviderActions.getNodeClinics(nodeId)),
    })
)(GamesDeathMatchCard);
