import React from "react";
import {connect} from "react-redux";
import ClinicBase from "components/layout/ClinicBase";
import CampaignTrackingTable from "components/clinic/widgets/CampaignTrackingTable";
import {getClinicInfo} from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import * as SearchTypes from "constants/SearchTypes";
import {CLINIC_TAGS} from "constants/AdminControls";

function ClinicMarketingTracking(props) {

    return (
        <ClinicBase
            clinicId={props.clinicId}
            pageTitle="Pet Owner List"
            searchConfig={SearchTypes.CAMPAIGN_TRACKING}
            adminControls={CLINIC_TAGS}
        >
            {props.canOptInCouponLibrary ? (
                <CampaignTrackingTable clinicId={props.clinicId} />
            ) : (
                <AccessDenied/>
            )}
        </ClinicBase>
    );
}


export default connect(
    (state, ownProps) => {
        const adminClinicId = Number(ownProps.match.params.clinicId);
        const clinic = getClinicInfo(state, adminClinicId);
        const clinicId = clinic ? clinic.clinicId : undefined;
        const userProfile = state.user.userProfile;
        const canOptInCouponLibrary = userHasPermission(PermissionTypes.OPT_IN, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        return {
            clinicId,
            canOptInCouponLibrary,
        }
    }
)(ClinicMarketingTracking);
