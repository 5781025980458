import React from "react";
import { connect } from "react-redux";
import ClinicBase from "components/layout/ClinicBase";
import Button from "components/common/Button";

function AccessDeniedPage(props) {
    const handleClick = () => {
        window.history.back();
    };

    return (
        <ClinicBase pageTitle="Access Denied">
            <div className="full-width margin-md">
                <Button onClick={handleClick}>
                    <i className="fas fa-reply" /> &nbsp; Go Back
                </Button>
            </div>
        </ClinicBase>
    );
}

export default connect(
    // (state) => {
    //     return {
    //         isLoggedIn: state.user.isLoggedIn,
    //         userProfile: state.user.userProfile,
    //     };
    // },
    // (dispatch) => ({
    //     loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
    // }),
)(AccessDeniedPage);
