exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".AccountHoldHistory__root .AccountHoldHistory__noBorder td{border-bottom:0}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/admin/widgets/AccountHoldHistory.scss"],"names":[],"mappings":"AAAA,2DAAmB,eAAe,CAAC","file":"AccountHoldHistory.scss","sourcesContent":[".root .noBorder td{border-bottom:0}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "AccountHoldHistory__root",
	"noBorder": "AccountHoldHistory__noBorder"
};