import React, {useMemo, useState, useEffect} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "./MappingTable.scss";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import find from "lodash/find";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import union from "lodash/union";
import * as MappingActions from "actions/MappingActions";
import AccessDenied from "components/common/AccessDenied";
import Dropdown from "components/common/Dropdown";
import MappingTableSection from "components/admin/elements/MappingTableSection";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import logger from "utils/logger";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import {CATEGORIES, SPECIES, TYPES} from "constants/MappingTypes";
import { PRODUCT_TYPES } from "constants/ProductTypes";
import * as UserPermissions from "constants/UserPermissions";

function MappingTable(props) {
    if(!props.canViewProductMapping) {
        return <AccessDenied/>;
    }

    const [filters, setFilters] = useState({});

    useEffect(() => {
        if(props.canViewProductMapping) {
            if (props.isFood) {
                if (!Object.keys(props.products).length) {
                    props.loadFoodProducts();
                }
                props.loadFoodMappings(props.clinicId);
            } else {
                if (!Object.keys(props.products).length) {
                    props.loadPharmacyProducts();
                }
                props.loadPharmacyMappings(props.clinicId);
            }
        }
    }, [props.isFood, props.clinicId]);

    const PROVIDER_OPTIONS = useMemo(() => {
        return map(props.providers, (provider) => {
            return {name: provider.name, value: provider.id};
        });
    }, [props.providers]);

    const PIMS_OPTIONS = useMemo(() => {
        return map(props.pims, (pim) => {
            return {name: `(${pim.remoteClinicProductDisplayID}) ${pim.name}`, value: pim.clinicProductId};
        })
    }, [props.pims]);

    const orderedPimsOptions = useMemo(() => {
        return orderBy(PIMS_OPTIONS, po => {return includes(props.disabledPimsOptions, po.value)});
    }, [PIMS_OPTIONS, props.disabledPimsOptions]);

    const filteredProducts = useMemo(() => {
        if((filters.species || filters.vaccineTypes || filters.providerId || filters.productTypeId ||filters.brandId || props.search)) {
            return filter(props.products, p => {
                if (!!filters.species && (p.speciesName !== filters.species)) {
                    return false;
                }
                if (!!filters.vaccineTypes && !includes(p.tags, filters.vaccineTypes)) {
                    return false;
                }
                if (!!filters.providerId && p.providerId !== filters.providerId) {
                    return false;
                }
                if (!!filters.productTypeId && p.productTypeId !== filters.productTypeId) {
                    return false;
                }
                if (!!filters.brandId && p.productBrandId !== filters.brandId) {
                    return false;
                }
                if (!!props.search) {
                    const searchTokens = props.search.toLowerCase().split(" ");
                    let keep = true
                    for (let i = 0; i < searchTokens.length; i++) {
                        const token = searchTokens[i];
                        if (!p.productName || !p.productName.toString().toLowerCase().includes(token)) {
                            keep = false;
                        }
                    }
                    return keep;
                }
                return true;
            });
        } else return props.products;
    }, [props.products, props.search, filters]);

    if (props.working || !filteredProducts) {
        return <SpinnerTakeover show/>;
    }

    const sections = map(orderBy(filteredProducts, ["sortOrder"]), (product) => {
        const type = find(TYPES, {value: product.productTypeId});
        return (
            <MappingTableSection
                key={product.productId}
                isFood={props.isFood}
                clinicId={props.clinicId}
                productId={product.productId}
                type={type?.name ? type.name : null}
                typeId={props.typeId}
                pimsOptions={orderedPimsOptions}
                disabledPimsOptions={props.disabledPimsOptions}
                boldPimsOptions={props.boldPimsOptions}
            />
        )
    });

    const brands = props.isFood ? props.foodBrands : props.pharmacyBrands;

    const TYPE_OPTIONS = orderBy(props.isFood ? props.foodProductTypes : props.pharmacyProductTypes, "name");

    const BRAND_OPTIONS = orderBy(map(brands, (brand) => {
        return {name: brand.name, value: brand.productBrandId};
    }), "name");


    const handleChangeFilters = ({name, value}) => {
        setFilters({
            ...filters,
            [name]: value,
        })
    }

    return (
        <div className="flex flex-column height-100-perc">
            <div className={classnames(styles.mappingHeader, "flex spaced-content align-center flex-none")}>
                <div className="flex flex-wrap spaced-content align-center">
                    <h2 className="nowrap">{(props.isFood) ? "Food" : "Pharmacy"} Mappings</h2>
                    <h3 className="nowrap">({Object.keys(props.products)?.length} products / {props.totalMappings} mappings)</h3>
                </div>
            </div>
            <hr className="flex-none full-width"/>
            <div className={classnames(styles.dropdowns, "flex flex-none spaced-content align-bottom margin-bottom-sm margin-top-sm")}>
                <div className="flex-1">
                    <Dropdown
                        options={PROVIDER_OPTIONS}
                        label="Manufacturer"
                        value={filters.providerId}
                        name="providerId"
                        onChange={handleChangeFilters}
                        placeholder="--Select Manufacturer--"
                        fullWidth
                    />
                </div>
                {(PRODUCT_TYPES[props.typeId] === "Pharmacy") && (
                    <div className="flex-1">
                        <Dropdown
                            options={CATEGORIES}
                            label="Category"
                            value={filters.categoryId}
                            name="categoryId"
                            onChange={handleChangeFilters}
                            placeholder="--Select Category--"
                            fullWidth
                        />
                    </div>
                )}
                <div className="flex-1">
                    <Dropdown
                        options={SPECIES}
                        label="Species"
                        value={filters.species}
                        name="species"
                        onChange={handleChangeFilters}
                        placeholder="--Select Species--"
                        fullWidth
                    />
                </div>
                <div className="flex-1">
                    <Dropdown
                        options={BRAND_OPTIONS}
                        label="Brand"
                        value={filters.brandId}
                        name="brandId"
                        onChange={handleChangeFilters}
                        placeholder="--Select Brand--"
                        fullWidth
                    />
                </div>
                <div className="flex-1">
                    <Dropdown
                        options={TYPE_OPTIONS}
                        label="Type"
                        value={filters.productTypeId}
                        name="productTypeId"
                        onChange={handleChangeFilters}
                        placeholder="--Select Type--"
                        fullWidth
                    />
                </div>
            </div>
            <div className="flex-1">
                <div className={classnames(styles.overflow, "height-100-perc overflow-x-scroll full-width")}>
                    <table className="table">
                        <thead>
                            <tr className="spaced-content">
                                <th/>
                                <th/>
                                <th>Ignore Qty</th>
                                <th>SKU Qty</th>
                                <th>Comp. Dur.</th>
                                <th>Free Dose?</th>
                                <th>Disc.?</th>
                                <th>Excl. CP</th>
                                <th/>
                                <th>My Product</th>
                                <th/>
                            </tr>
                        </thead>
                        <tbody>
                            {sections}
                        </tbody>
                    </table>
                    {/*<div className={classnames(styles.actions, "flex justify-flex-end margin-top-lg spaced-content")}>*/}
                    {/*    <Button*/}
                    {/*        onClick={() => {logger.log("Export Excel File")}}*/}
                    {/*        icon*/}
                    {/*        type="primary"*/}
                    {/*    >*/}
                    {/*        <i className="fa fa-file-excel"/> Export*/}
                    {/*    </Button>*/}
                    {/*    <Button*/}
                    {/*        onClick={handleApplyMapping}*/}
                    {/*    >*/}
                    {/*        Apply Mappings to all invoices*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}

MappingTable.propTypes = {
    clinicId: PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
};

export default connect(
    (state, ownProps) => {
        const isFood = (PRODUCT_TYPES.FOOD === ownProps.typeId);
        const totalMappings = (isFood ? state.entities.mappingCounts?.[ownProps.clinicId]?.totalFoodMappings : state.entities.mappingCounts?.[ownProps.clinicId]?.totalPharmacyProducts) || 0;
        const productMappings = (isFood ? state.entities.foodMappings?.[ownProps.clinicId] : state.entities.pharmacyMappings?.[ownProps.clinicId]) || {};
        const disabledPimsOptions = union(...map(productMappings, product => map(product.mappings, "clinicProductId"))) || [];
        const pims = state.entities.pims?.products || {}
        const boldPimsOptions = flatMap(filter(pims, "productUsedRowCount"), "clinicProductId");
        const products = isFood ? state.entities.foodProducts : state.entities.pharmacyProducts;

        //Permissions
        const userProfile = state.user.userProfile;
        const canViewProductMapping = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRODUCT_MAPPING, userProfile);
        const canEditProductMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.PRODUCT_MAPPING, userProfile);

        return {
            isFood,
            totalMappings,
            disabledPimsOptions,
            pims,
            boldPimsOptions,
            canViewProductMapping,
            canEditProductMapping,
            providers: orderBy(!isFood ? filter(state.entities.providers, "visibleInPharmacyMapping") : filter(state.entities.providers, "visibleInFoodMapping"), ["displayOrder"]),
            products: products || null,
            foodProductTypes: state.entities.foodProductTypes,
            pharmacyProductTypes:state.entities.pharmacyProductTypes,
            pharmacyBrands: filter(state.entities.brands, "visibleInPharmacyMapping"),
            foodBrands: filter(state.entities.brands, "visibleInFoodMapping"),
            search: state.entities.genericSearch,
            working: !!state.applicationState.working,
        }
    },
    (dispatch) => ({
        //FOOD
        loadFoodProducts: () => dispatch(MappingActions.loadFoodProducts()),
        loadFoodMappings: (clinicId) => dispatch(MappingActions.loadFoodMappings(clinicId)),
        //Pharmacy
        loadPharmacyProducts: () => dispatch(MappingActions.loadPharmacyProducts()),
        loadPharmacyMappings: (clinicId) => dispatch(MappingActions.loadPharmacyMappings(clinicId)),
    }),
) (MappingTable);
