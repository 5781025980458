import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as AdminActions from "actions/AdminActions";
import AdminBase from 'components/layout/AdminBase';
import {keys, map, orderBy, filter} from 'lodash';
import Dropdown from "components/common/Dropdown";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import SortableDataTable from "components/common/SortableDataTable";
import {ROLE_MANAGEMENT} from "constants/AdminControls";
import * as SearchTypes from "constants/SearchTypes";
import Modal from "components/common/Modal";
import RolePermissions from "components/admin/widgets/RolePermissions"
import AddRoleForm from "components/admin/forms/AddRoleForm";
import { PROVIDER } from "constants/RoleTypes";

function AdminClinicsMapPage(props) {
    const [roleType, setRoleType] = useState(null);
    const [showPermissions, setShowPermissions] = useState(null);

    const ROLE_TYPES = orderBy(map(props.roles.roleTypes, type => {return  {name: type, value: type }}), "name");

    useEffect(() => {
        props.loadRoles();
    }, []);

    const COLUMNS = [
        {
            name: "ID",
            selector: "id",
            key: "id",
            sortable: true,
            searchable: true,
        }, {
            name: "Name",
            selector: "name",
            key: "name",
            sortable: true,
            searchable: true,
        }, {
            name: "Role Type",
            selector: "roleType",
            key: "roleType",
            sortable: true,
            searchable: true,
        }
    ];

    const filteredRoles = orderBy(filter(props.roles.roles, role => {
        let isVisible = true;
        if (roleType) {
            isVisible = (roleType === role.roleType);
        }
        return isVisible
    }), "displayOrder");

    return (
        <AdminBase
            pageTitle="Role Management"
            collapsible
            controls={ROLE_MANAGEMENT}
            searchConfig={SearchTypes.ROLE_SEARCH}
        >
            <div className="full-width full-height margin-bottom-x-lg">
                <div className="flex spaced-content">
                    <div className="flex-2"/>
                    <div className="flex-1">
                        <Dropdown
                            options={ROLE_TYPES}
                            name="roleType"
                            value={roleType}
                            label="Role Type"
                            onChange={({value}) => setRoleType(value)}
                            placeholder="All Roles"
                        />
                    </div>
                </div>
                <div className="margin-top-md">
                    <SortableDataTable
                        striped
                        green
                        allowSearch
                        hideSearchBar
                        search={props.search}
                        columns={COLUMNS}
                        rawData={filteredRoles}
                        onClick={(row) => setShowPermissions(row)}
                    />
                </div>
                <SpinnerTakeover show={props.roles?.loading}/>
                <Modal
                    onClose={() => setShowPermissions(null)}
                    show={!!showPermissions}
                    modalTitle={`${showPermissions?.name} Permissions`}
                >
                    <RolePermissions
                        roleId={showPermissions?.id}
                        displayOrder={showPermissions?.displayOrder}
                        showProviders={showPermissions?.roleType === PROVIDER}
                        handleCancel={() => setShowPermissions(null)}
                    />
                </Modal>
                <Modal
                    show={props.addNewForm}
                    modalTitle="Add Role"
                    small
                    onClose={() => props.hideAddForm()}
                >
                    <AddRoleForm
                        handleCancel={() => props.hideAddForm()}
                    />
                </Modal>
            </div>
        </AdminBase>
    );
}

export default connect(
    (state, ownProps) => {
        return {
            roles: state.entities.roles,
            search: state.entities.genericSearch,
            addNewForm: state.entities.adminTools.addNewForm || false,
        };
    },
    (dispatch) => ({
        loadRoles: () => dispatch(AdminActions.loadRoles()),
        hideAddForm: () => dispatch(AdminActions.hideAddForm()),
    }),
)(AdminClinicsMapPage);
