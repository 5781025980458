import React, {useEffect, useMemo, useState} from "react";
import {connect} from "react-redux";
import classnames from "classnames";
import styles from "./PaymentInfo.scss";
import PropTypes from "prop-types";
import find from "lodash/find";
import map from "lodash/map";
import * as StripeApi from "api/StripeApi";
import Button from "components/common/Button";
import TextBox from "components/common/TextBox";
import Dropdown from "components/common/Dropdown";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import env from "utils/environment";
import getCardIcon from "utils/getCardIcon";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as status from "constants/HttpResponseCodes";
import * as UserPermissions from "constants/UserPermissions";
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

let productIds = ["prod_LYTcN6pp5AwZpB", "prod_LYTevc7z6v5Fnj", "prod_IEswVpbuZs7rwH"];

function PaymentInfo(props) {
    const clientId = useMemo(() => (env.getValue("IDENTITY_CLIENT_ID")), []);
    if(clientId === "oidcSpaClientProd") {
        productIds = ["prod_LYTjwl8S1bFoGM", "prod_LYTjhDfifW8qWO", "prod_IEW6U54mpGq23p"];
    }

    const [loading, setLoading] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [stripeError, setStripeError] = useState(null);
    const [showAddCard, setShowAddCard] = useState(false);
    const [cardName, setCardName] = useState("");
    const [cardList, setCardList] = useState([]);

    if(!props.clinicId) {
        return <SpinnerTakeover show/>;
    }

    const CARD_OPTIONS = map(cardList, card => {
        const cardIcon = getCardIcon(card?.brand);
        return {
            name: (
                <div className="flex spaced-content no-wrap flex-centered">
                    <div className="flex-none">{cardIcon}</div>
                    <div className="flex-1 no-wrap">
                        <div>{card.name}</div>
                        {card.isDefault && (
                            <div className="flex-1 text-x-sm">(Default)</div>
                        )}
                    </div>
                    <div className="flex-1 no-wrap"><span className="text-mid-gray">last 4:</span> {card.last4}</div>
                    <div className="flex-1 no-wrap"><span className="text-mid-gray">EXP:</span> {card.expMonth}/{card.expYear}</div>
                </div>
            ),
            value: card.id
        }
    });

    const stripe = useStripe();
    const elements = useElements();

    const getCards = () => {
        if(props.clinicId) {
            setLoading(true);
            StripeApi.getPaymentOptions(props.clinicId)
                .then((res) => {
                    setCardList(res.body);
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                    if (error.status === status.HTTP_STATUS_400) {
                        toast.warn("User is not in a clinic.");
                    } else {
                        handleErrorResponse("loading clinic's cards", error);
                    }
                });
        }
    }

    useEffect(() => {
        if(!!props.clinicId) {
            getCards();
        }
    }, [props.clinicId]);

    useEffect(() => {
        // Set the default card after cards are loaded
        if(!!cardList?.length) {
            const defaultCard = find(cardList, {isDefault: true});
            props.onChange({name: "cardId", value: defaultCard?.id});
        }
    }, [cardList]);

    if (!(cardList?.length || props.canEditPaymentSettings)){
        return (
            <div className="text-danger">You do not have the correct permissions to edit payment settings</div>
        )
    }

    const handleError = (event) => {
        if (event.error) {
            setStripeError(event.error.message);
        } else {
            setStripeError(null);
        }
    }

    const handleAddPaymentMethod = async () => {
        setBtnDisabled(true);
        setLoading(true);

        const card = elements.getElement(CardElement);
        const stripeModel = { name: cardName }
        const result = await stripe.createToken(card, stripeModel);

        if (result.error) {
            setStripeError(result.error.message);
        } else {
            setStripeError(null);
            try {
                await StripeApi.createPaymentOption({clinicId: props.clinicId, tokenId: result.token.id});
                getCards();
                toast.success("Card has been added.");
                setShowAddCard(false);
                setBtnDisabled(false);
            } catch (error) {
                handleErrorResponse("adding payment method", error);
                setBtnDisabled(false);
            }
        }
    }

    return (
        <div className={styles.root}>
            <div className="text-lg margin-top-md">Payment Information</div>
            <div>
                <div className="margin-top-sm flex flex-wrap align-bottom spaced-content">
                    <div className="flex-1" style={{minWidth: "300px"}}>
                        {!!cardList.length ? (
                            <Dropdown
                                label="Credit Card"
                                options={CARD_OPTIONS}
                                name={props.name}
                                value={props.value}
                                placeholder="Select Payment Method"
                                onChange={props.onChange}
                                hasError={props.error}
                            />
                        ) : (
                            <div className={classnames("text-lg text-mid-gray", {
                                ["text-danger"]: props.error
                            })}>
                                No Available Credit Cards
                            </div>
                        )}
                    </div>
                    {!!(!showAddCard && cardList?.length) && (
                        <div className="flex-none">
                            <Button
                                onClick={() => setShowAddCard(true)}
                                type="success"
                                disabled={btnDisabled}
                            >
                                Add a New Card
                            </Button>
                        </div>
                    )}
                </div>
                {(showAddCard || !cardList?.length) && (
                    <div className="border-top">
                        <div className="form-row">
                            <TextBox
                                id="name"
                                label="Name on Card"
                                name="cardName"
                                type="text"
                                value={cardName}
                                onChange={({value}) => setCardName(value)}
                                required
                                pattern="[A-Za-z, ]+"
                            />
                        </div>
                        <div className="form-row">
                            <label htmlFor="card-element">
                                Credit or debit card
                            </label>
                            <CardElement
                                id="card-element"
                                onChange={handleError}
                                className={styles.cardEl}
                            />
                            <div className="card-errors" role="alert">{stripeError}</div>
                        </div>
                        <div className="flex justify-flex-end spaced-content margin-top-x-sm">
                            {!!cardList?.length && (
                                <div>
                                    <Button
                                        onClick={() => setShowAddCard(false)}
                                        type="gray"
                                        small
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            )}
                            <div>
                                <Button
                                    type="success"
                                    onClick={handleAddPaymentMethod}
                                    small
                                    disabled={btnDisabled || !cardName?.length}
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                        <SpinnerTakeover show={loading}/>
                    </div>
                )}
            </div>
        </div>
    );
}

PaymentInfo.propTypes = {
    clinicId: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    error: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
            // Permissions
        const canEditPaymentSettings = userHasPermission(PermissionTypes.EDIT, UserPermissions.PAYMENT_SETTINGS, userProfile);

        return {
            //  Permissions
            canEditPaymentSettings
        }
    }
)(PaymentInfo);
