import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import classnames from "classnames";
import * as styles from "./TourStartPage.scss";
import * as TourActions from "actions/TourActions";
import * as UserActions from "actions/UserActions";
import Image from "components/common/Image";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import * as DemoTourStates from "constants/DemoTourStates";
import { BOEHRINGER_INGELHEIM, ELANCO, GREENLINE_WELLNESS, MERCK } from "constants/ProviderIds";

const GREENLINE_LOGO_LOGO = require("components/common/branding/greenline-logo-no-background.svg");
const BOEHRINGER_INGELHEIM_LOGO = "https://gldevcdn.blob.core.windows.net/images/demo/boehringer-ingelheim-logo.svg";
const MERCK_LOGO = "https://gldevcdn.blob.core.windows.net/images/demo/merck-logo.svg";
const ELANCO_LOGO = "https://gldevcdn.blob.core.windows.net/images/demo/elanco-logo.svg";
const GREENLINE_WELLNESS_LOGO = "https://gldevcdn.blob.core.windows.net/images/demo/greenline-wellness.svg";

function TourStartPage(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if(props.isLoggedIn && !props.userProfile) {
            props.loadCurrentUser();
        }
    }, [props.isLoggedIn]);

    if(!props.userProfile) {
        return <SpinnerTakeover show/>
    }

    useEffect(() => {
        //Direct back to "/" if the tour has been started
        if (!!props.tourStarted) {
            if (props.userProfile.demoProvider === GREENLINE_WELLNESS) {
                history.push("/wellness");
            } else  {
                history.push("/");
            }
        }
        setLoading(false);
    }, [props.tourStarted])

    const handleStartTour = () => {
        setLoading(true);
        props.markTourAsStarted();
    }

    return (
        <div className={styles.root}>
            <div className={classnames(styles.left, "flex-1 flex flex-column")}>
                <div className="flex-1 flex justify-center">
                    <div className={styles.glLogo}>
                        <Image src={GREENLINE_LOGO_LOGO} alt="Greenline Logo"/>
                    </div>
                </div>
                <div className="flex-1 justify-center">
                    <div className="text-center margin-top-lg">
                        Welcome to the Greenline Pet Demo Site where you will learn how to process your coupons and utilize other Greenline features.
                        Please click the Manufacturer logo to begin.
                    </div>
                </div>
            </div>
            <div className={styles.border}>
                <div/>
            </div>
            <div className={classnames(styles.right, "flex-1 flex flex-centered")}>
                <div className="flex-1">
                    <div className={styles.manufacturerLogo}  onClick={handleStartTour}>
                        {props.userProfile.demoProvider === BOEHRINGER_INGELHEIM ? (
                            <Image src={BOEHRINGER_INGELHEIM_LOGO} alt="Boehringer Ingelheim Logo"/>
                        ) : props.userProfile.demoProvider === ELANCO ? (
                            <Image src={ELANCO_LOGO} alt="Elanco Logo"/>
                        ) : props.userProfile.demoProvider === MERCK ? (
                            <Image src={MERCK_LOGO} alt="Merck Logo"/>
                        ) : props.userProfile.demoProvider === GREENLINE_WELLNESS ? (
                            <Image src={GREENLINE_WELLNESS_LOGO} alt="Greenline Wellness Logo"/>
                        ) : null}

                    </div>
                </div>
            </div>
            <SpinnerTakeover show={loading}/>
        </div>
    );
}


export default connect(
    (state) => ({
        isLoggedIn: state.user.isLoggedIn,
        userProfile: state.user.userProfile,
        clinics: state.entities.clinics,
        tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),
    }),
    (dispatch) => ({
        loadCurrentUser: () => dispatch(UserActions.loadCurrentUser()),
        markTourAsStarted: () => dispatch(TourActions.markTourAsStarted()),
    }),
)(TourStartPage);
