import React from "react";
import PropTypes from "prop-types";
import styles from "./ProgressBar.scss";
import classnames from "classnames";
import { LightenDarkenColor } from "utils/colorUtils";
import ToolTip from "components/common/Tooltip";

export default function ProgressBar(props) {

    const Colors = {
        BLUE: "#2699FB",
        GREEN: "#317D3D",
        RED: "#CC0000",
    };

    const property = props.vertical ? "height" : "width";
    let threshold = 0;

    if(props.thresholds) {
        for(let i=0; i < props.thresholds.length; i++) {
            if(props.progress > props.thresholds[i]) {
                threshold = i;
            } else {
                break;
            }
        }
    }

    let style = {
        [property]: `${Math.min(props.progress, 100)}%`,
    };

    const currentColor = props.colors ? props.colors[threshold] : Colors.GREEN;
    if(props.striped) {
        const darkerColor = LightenDarkenColor(currentColor, -20);
        style.backgroundImage = `repeating-linear-gradient(-45deg, ${currentColor}, ${currentColor} 10px, ${darkerColor} 10px, ${darkerColor} 20px)`;
    } else {
        style["backgroundColor"] = currentColor;
    }

    let labelStyle = {}
    if(property === "width" && props.progress < 35) {
        labelStyle = {
            left: style.width,
            justifyContent: "flex-start",
            boxShadow: "none",
        };
    } else if(property === "width" && props.progress >= 35) {
        labelStyle = {
            width: `${props.progress}%`,
            justifyContent: "flex-end",
            color: "#FFFFFF",
            paddingRight: "0.25em",
        };
    }

    const barLabel = props.customLabel ?  props.customLabel : `${Math.floor(props.progress)}%`;

    return (
        //Comment out the ToolTip Component when generating the email scripts
        <ToolTip
            position="top"
            tip={
                <span data-testid="progress_bar_tool_tip">
                    Previous Doses Matched
                </span>
            }
        >
            <div
                data-testid="progress_bar_component"
                className={classnames(styles.root, {
                    [styles.vertical]: props.vertical,
                    [styles.noCorners]: props.noCorners,
                    [styles.topCorners]: props.topCorners,
                    [styles.bottomCorners]: props.bottomCorners,

                    // Test styles
                    "test-vertical": props.vertical,
                    "test-no-corners": props.noCorners,
                    "test-top-corners": props.topCorners,
                    "test-bottom-corners": props.bottomCorners,
                })}
            >
                <div
                    data-testid="progress_bar"
                    className={classnames(styles.progressBar, {
                        [styles.vertical]: props.vertical,
                        [styles.reverse]: props.reverse,

                        "test-vertical": props.vertical,
                        "test-reverse": props.reverse,
                    })}
                    style={style}
                />
                {props.showLabel && (
                    <div
                        data-testid="progress_bar_label"
                        className={classnames(styles.label, {
                            [styles.overflow]: props.progress > 100,

                            "test-overflow": props.progress > 100,
                        })}
                        style={labelStyle}
                    >
                        {barLabel}
                    </div>
                )}
            </div>
        </ToolTip>
    )
}

ProgressBar.propTypes = {
    progress: PropTypes.number.isRequired,
    vertical: PropTypes.bool,
    reverse: PropTypes.bool,
    colors: PropTypes.array,
    thresholds: PropTypes.array,
    noCorners: PropTypes.bool,
    topCorners: PropTypes.bool,
    bottomCorners: PropTypes.bool,
    striped: PropTypes.bool,
    showLabel: PropTypes.bool,
    customLabel: PropTypes.string,
};
