exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PrivacyPolicy__root a{color:#4eaae4}.PrivacyPolicy__root ol{margin-bottom:2em;padding-left:1em}.PrivacyPolicy__root ol li{margin-bottom:1.25em}.PrivacyPolicy__root ol li>b{display:inline-block;margin-left:0.5em}.PrivacyPolicy__root ol li>p>span{display:inline-block;margin-right:0.5em}.PrivacyPolicy__root ol.inside{list-style-position:inside;padding-left:0}.PrivacyPolicy__root ol.inside li{text-indent:1em}.PrivacyPolicy__root ol.no-list{list-style:none}.PrivacyPolicy__root ol>p>span{display:inline-block;margin-left:0.5em}.PrivacyPolicy__root .PrivacyPolicy__addressBlock{text-indent:0;padding-left:2em}.PrivacyPolicy__root .PrivacyPolicy__footerText{margin-top:3em;font-size:1.1em;font-weight:500;text-align:center}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/layout/elements/PrivacyPolicy/PrivacyPolicy.scss"],"names":[],"mappings":"AAAA,uBAAQ,aAAa,CAAC,wBAAS,kBAAkB,gBAAgB,CAAC,2BAAY,oBAAoB,CAAC,6BAAc,qBAAqB,iBAAiB,CAAC,kCAAmB,qBAAqB,kBAAkB,CAAC,+BAAyB,2BAA2B,cAAc,CAAC,kCAA4B,eAAe,CAAC,gCAA0B,eAAe,CAAC,+BAAgB,qBAAqB,iBAAiB,CAAC,kDAAoB,cAAc,gBAAgB,CAAC,gDAAkB,eAAe,gBAAgB,gBAAgB,iBAAiB,CAAC","file":"PrivacyPolicy.scss","sourcesContent":[".root a{color:#4eaae4}.root ol{margin-bottom:2em;padding-left:1em}.root ol li{margin-bottom:1.25em}.root ol li>b{display:inline-block;margin-left:0.5em}.root ol li>p>span{display:inline-block;margin-right:0.5em}.root ol:global(.inside){list-style-position:inside;padding-left:0}.root ol:global(.inside) li{text-indent:1em}.root ol:global(.no-list){list-style:none}.root ol>p>span{display:inline-block;margin-left:0.5em}.root .addressBlock{text-indent:0;padding-left:2em}.root .footerText{margin-top:3em;font-size:1.1em;font-weight:500;text-align:center}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "PrivacyPolicy__root",
	"addressBlock": "PrivacyPolicy__addressBlock",
	"footerText": "PrivacyPolicy__footerText"
};