import React, {useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import styles from "./VetCheckTable.scss";
import SortableDataTable from "components/common/SortableDataTable";
import moment from "moment";
import * as CouponActions from "actions/CouponActions";
import Button from "../../common/Button";

function VetCheckTable(props) {
    const COLUMNS = [
        {
            name: "Product Name",
            selector: "lineItemDescription",
            key: "lineItemDescription",
            sortable: true
        }, {
            name: `${props.itemType} Name`,
            selector: "handoutTitle",
            key: "handoutTitle",
            sortable: true,
            format: row => !!row?.shareLink ? (
                <Button
                   onClick={() => props.onOpenModal(row)}
                   text
                   type="primary"
                   icon
                >
                    {!!row?.handoutTitle ? row.handoutTitle : (<i className="fa fa-link" title="View shared handout"/>)}
                </Button>
            ) : (
                <span>{row?.handoutTitle}</span>
            )
        }, {
            name: "Date Sent",
            selector: "emailSendDate",
            key: "emailSendDate",
            sortable: true,
            format: row => !!row?.emailSendDate ? moment(row?.emailSendDate).format('MM/DD/YYYY') : "Unknown",
        }, {
            name: "First Name",
            selector: "petOwnerFirstName",
            key: "petOwnerFirstName",
            sortable: true,
        }, {
            name: "Last Name",
            selector: "petOwnerLastName",
            key: "petOwnerLastName",
            sortable: true,
        }, {
            name: "Pet Name",
            selector: "petName",
            key: "petName",
            sortable: true,
        }, {
            name: "Email",
            selector: "petOwnerEmail",
            key: "petOwnerEmail",
            sortable: true,
        }
    ];

    const totalPages = Math.ceil(props.totalResults / props.limit);

    return (
        <>
            <SortableDataTable
                columns={COLUMNS}
                rawData={props.items}
                striped
                resultsPerPage={props.limit}
                onSort={props.handleSort}
            />
            <div>
                {(totalPages > 1) && (
                    <div className="flex full-width flex-centered">
                        <div className="flex-none">
                            <Button
                                disabled={props.page <= 1}
                                onClick={props.handleGoPrevious}
                            >
                                <i className="fa fa-chevron-left margin-right-sm" /> Previous
                            </Button>
                        </div>

                        <div className="flex-1 text-center">
                            Page {props.page} of {totalPages}
                        </div>
                        <div className="flex-none">
                            <Button
                                disabled={props.page >= totalPages}
                                onClick={props.handleGoNext}
                            >
                                Next <i className="fa fa-chevron-right margin-left-sm" />
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

VetCheckTable.propTypes = {
    itemType: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    handleSort: PropTypes.func.isRequired,
    handleGoNext: PropTypes.func.isRequired,
    handleGoPrevious: PropTypes.func.isRequired,
    totalResults: PropTypes.number,
    page: PropTypes.number.isRequired,
    onOpenModal: PropTypes.func.isRequired,
    limit: PropTypes.number
};

VetCheckTable.defaultProps = {
    showOptionsMenu: true,
    showStatusIcon: true,
};

export default connect(
    null,
    (dispatch) => ({
        downloadPDF: () => dispatch(CouponActions.downloadPDF()),
        downloadExcel: () => dispatch(CouponActions.downloadExcel()),
    })
)(VetCheckTable);
