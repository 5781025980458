import React, { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import Button from "components/common/Button";
import { useReactToPrint } from "react-to-print";

export default function PrintContentContainer(props) {
    const {
        allowPrint = true,
    } = props;
    const [isPrinting, setIsPrinting] = useState(false);
    const contentRef = useRef();
    const promiseResolveRef = useRef(null);

    const handleSetIsPrinting = (ip) => {
        setIsPrinting(ip);
        if (!!props.onSetIsPrinting) {
            props.onSetIsPrinting(ip);
        }
    };

    useEffect(() => {
        if (isPrinting && promiseResolveRef.current) {
            promiseResolveRef.current();
        }
    }, [isPrinting]);

    const getContentToPrint = () => {
        if (!!props.outsideContentRef) {
            return props.outsideContentRef.current;
        }

        return contentRef.current;
    };

    const handlePrint = useReactToPrint({
        content: getContentToPrint,
        onBeforeGetContent: () => {
            
            return new Promise((resolve) => {
                handleSetIsPrinting(true);
                promiseResolveRef.current = resolve;
            });
        },
        onAfterPrint: () => {
            // Reset the Promise resolve so we can print again
            promiseResolveRef.current = null;
            handleSetIsPrinting(false);
        }
    });

    return (
        <div
            data-testid="print_content_container_root"
            ref={contentRef}
        >
            {props.children}
            {(allowPrint && !isPrinting) && (
                <div className="no-print flex flex-centered margin-bottom-sm margin-top-md">
                    <Button
                        data-testid="print_content_container_print_button"
                        small
                        onClick={handlePrint}
                    >
                        <i className="fas fa-print margin-right-sm" /> PRINT
                    </Button>
                </div>
            )}
        </div>
    );
}

PrintContentContainer.propTypes = {
    onSetIsPrinting: PropTypes.func,
    allowPrint: PropTypes.bool,
    outsideContentRef: PropTypes.instanceOf(Element),
};
