import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { map } from "lodash";
import * as AdminActions from "actions/CouponActions";
import Button from "components/common/Button";
import SortableDataTable from "components/common/SortableDataTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import MultiSelectDropdown from "components/common/Dropdown/MultiSelectDropdown";
import * as CouponsApi from "api/CouponsApi";
import toast from "utils/toast";
import { downloadFile } from "utils/request";
import {getClinicInfo} from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import PropTypes from "prop-types";

const COLUMNS = [
    {
        name: "Client ID",
        selector: "remoteClientDisplayID",
        key: "remoteClientDisplayID",
        sortable: true,
    },
    {
        name: "Pet Owner",
        selector: "clientFirstName",
        key: "clientFirstName",
        sortable: true,
        format: row => <div className="no-wrap">{row.clientFirstName} {row.clientLastName}</div>
    },
    {
        name: "Email",
        selector: "clientEmail",
        key: "clientEmail",
        sortable: true,
        format: row => (<div className="no-wrap">{row.clientEmail}</div>),
    },
    {
        name: "Phone",
        selector: "clientPhone",
        key: "clientPhone",
        sortable: true,
        format: row => (<div className="no-wrap">{row.clientPhone}</div>),
    },
    {
        name: "Offer",
        selector: "groupOfferName",
        key: "groupOfferName",
        sortable: true,
    },
    {
        name: (<div className="text-center">Quantity<br/>Until<br/>Redeemable</div>),
        selector: "remainingQuantity",
        key: "remainingQuantity",
        sortable: true,
        format: row => (<div className="text-center">{row.remainingQuantity}</div>),
    },
];

function CumulativeOfferTrackingReport(props) {
    const [selectedOfferIds, setSelectedOfferIds] = useState([]);

    useEffect(() => {
        if (props.canViewClinicCouponAudit && props.clinicId && !props.offerOptions) {
            props.getOfferOptions(props.clinicId);
        }
    }, [props.clinicId]); // The clinic data must exist before we can get the options data (race condition avoidance).

    const handleOfferChanged = ({ value }) => {
        setSelectedOfferIds(value || []);
    };

    const handleCreateClicked = () => {
        if (props.canViewClinicCouponAudit && selectedOfferIds.length > 0) {
            props.getOfferData(props.clinicId, selectedOfferIds);
        }
    };

    const handleDownloadPdf = async () => {
        if(props.canViewClinicCouponAudit) {
            toast.success("Downloading incomplete cumulative offers report");
            const res = await CouponsApi.downloadIncompleteCumulativeOffers(props.clinicId, selectedOfferIds, {exportType: "xlsx"});
            downloadFile(res, "pdf");
        }
    };

    const canShowTable = !!props.offerData && props.offerData.length > 0;

    // Transform server data into dropdown options.
    const options = map(props.offerOptions, offer => {
        return {
            name: offer.groupOfferName,
            value: offer.groupOfferId,
        };
    });

    return (
        <Fragment>
            <div className="margin-bottom-lg">
                <div className="flex flex-column">
                    <div className="flex justify-center full-width">
                        <div style={{ width: "30%" }}>
                            <MultiSelectDropdown
                                placeholder="--Select--"
                                name="offerIds"
                                value={selectedOfferIds}
                                options={options}
                                onChange={handleOfferChanged}
                            />
                        </div>
                        <div className="margin-left-lg">
                            <Button
                                onClick={handleCreateClicked}
                                disabled={!props.canViewClinicCouponAudit}
                            >
                                Run Report
                            </Button>
                        </div>
                    </div>
                    {!canShowTable &&
                    <div className="flex justify-center margin-top-md">
                        <span style={{ color: "#ccc", fontSize: "1.4em" }}>Select one or more offers to see a report.</span>
                    </div>
                    }
                    {canShowTable &&
                    <div className="flex justify-center margin-top-md">
                        <SortableDataTable
                            columns={COLUMNS}
                            rawData={props.offerData}
                            downloadPDF={props.canViewClinicCouponAudit ? handleDownloadPdf : null}
                            striped
                            green
                        />
                    </div>
                    }
                </div>
            </div>
            <SpinnerTakeover show={props.dataLoading || props.optionsLoading} />
        </Fragment>
    );
}

CumulativeOfferTrackingReport.propTypes = {
    clinicId: PropTypes.number.isRequired,
}

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;

        //Permissions
        const canViewClinicCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);

        return {
            canViewClinicCouponAudit,
            offerOptions: state.entities.cumulativeOffers[ownProps.clinicId]?.cumulativeOfferOptions || null,
            offerData: state.entities.cumulativeOffers[ownProps.clinicId]?.cumulativeOfferData || null,
            dataLoading: state.entities.cumulativeOffers?.dataLoading || false,
            optionsLoading: state.entities.cumulativeOffers?.optionsLoading || false,
        }
    },

    (dispatch) => ({
        getOfferOptions: (clinicId) => dispatch(AdminActions.getCumulativeOfferTrackingOptions(clinicId)),
        getOfferData: (clinicId, offerIds) => dispatch(AdminActions.getCumulativeOfferTrackingData(clinicId, offerIds)),
    })
);

export default compose(
    withRouter,
    connector
)(CumulativeOfferTrackingReport);
