import React from "react";
import TourCompletion from "components/tour/widgets/TourCompletion";
import Image from "components/common/Image";
import {BOEHRINGER_INGELHEIM, ELANCO, MERCK} from "constants/ProviderIds";
import {
    TOUR_COMPLETE_TRANSITION,
    TOUR_COUPON_HISTORY_PART_1,
    TOUR_COUPON_HISTORY_PART_2,
    TOUR_COUPON_HISTORY_TRANSITION,
    TOUR_COUPON_LIBRARY_PART_1,
    TOUR_COUPON_LIBRARY_PART_2,
    TOUR_COUPON_LIBRARY_PART_3,
    TOUR_COUPON_LIBRARY_TRANSITION,
    TOUR_COUPON_PROCESSING_TRANSITION,
    TOUR_DASHBOARD,
    TOUR_EDIT_COUPON_MODAL,
    TOUR_GAMES_PART_1,
    TOUR_GAMES_PART_2,
    TOUR_GAMES_PART_3,
    TOUR_GAMES_TRANSITION,
    TOUR_NOTIFICATION_TRANSITION,
    TOUR_NOTIFICATIONS_PART_1,
    TOUR_NOTIFICATIONS_PART_2,
    TOUR_PENDING_COUPONS_PART_1,
    TOUR_PENDING_COUPONS_PART_2,
    TOUR_PENDING_COUPONS_PART_3,
    TOUR_PET_PLAN_TRACKING_PART_1,
    TOUR_PET_PLAN_TRACKING_PART_2,
    TOUR_PET_PLAN_TRACKING_TRANSITION,
    TOUR_PLAN_ENROLLMENT_PART_1,
    TOUR_PLAN_ENROLLMENT_PART_2,
    TOUR_PLAN_ENROLLMENT_TRANSITION,
    TOUR_PROGRAM_OPT_IN_PART_1,
    TOUR_PROGRAM_OPT_IN_PART_2,
    TOUR_PROGRAM_OPT_IN_PART_3,
    TOUR_PROGRAM_OPT_IN_PART_4,
    TOUR_PROGRAM_OPT_IN_TRANSITION,
    TOUR_REPORTS,
    TOUR_REPORTS_TRANSITION,
    TOUR_RESOURCES,
    TOUR_RESOURCES_TRANSITION,
    TOUR_WELLNESS_AUDIT_PART_1,
    TOUR_WELLNESS_AUDIT_PART_2,
    TOUR_WELLNESS_AUDIT_TRANSITION,
    TOUR_WELLNESS_DASHBOARD,
    TOUR_WELLNESS_HISTORY_PART_1,
    TOUR_WELLNESS_HISTORY_PART_2,
    TOUR_WELLNESS_HISTORY_TRANSITION
} from "../../utils/demoTourHelpers";
const LAPSED_USER_OPT_IN = "https://gldevcdn.blob.core.windows.net/images/demo/lapsed_user_opt_in.gif";
const WELLNESS_HISTORY = "https://gldevcdn.blob.core.windows.net/images/tour/wellness_history.gif";
const WELLNESS_PAYOUT_REPORT = "https://gldevcdn.blob.core.windows.net/images/tour/wellness_payout_report.gif";



/*
* The tour is split into multiple sections.
* tourSection: Which section to retrieve
*/


const BI_EXPORT_GIF = "https://gldevcdn.blob.core.windows.net/images/demo/bi_coupon_history_export_demo.gif";
const ELANCO_EXPORT_GIF = "https://gldevcdn.blob.core.windows.net/images/demo/bi_coupon_history_export_demo.gif"; // TODO - need this file
const MERCK_EXPORT_GIF = "https://gldevcdn.blob.core.windows.net/images/demo/merck_coupon_history_export_demo.gif";

export const getFreeTrialTourSteps = (tourSection, providerId=null) => {

    switch(tourSection) {
        case TOUR_DASHBOARD:
            return [{
                target: '.ClinicBase__content',
                icon: "far fa-tachometer-fast",
                title: "Dashboard",
                stepText: "Step 1 of 5",
                content: "This will be your clinic’s dashboard. Here you will see an overview of your clinic’s reimbursements and client savings.",
                placement: "left",
                toolTipWidth: "200px",
                disableBeacon: true
            }, {
                target: '.tile_RedemptionsSubmitted',
                icon: "far fa-tachometer-fast",
                title: "Dashboard", stepText: "Step 2 of 5",
                content:  "This is where you will see your clinics coupon count that you have submitted through your portal since your clinic has been live with Greenline.",
                disableBeacon: true
            }, {
                target: '.tile_RedemptionsReimbursementValue',
                icon: "far fa-tachometer-fast",
                title: "Dashboard",
                stepText: "Step 3 of 5",
                content: 'This is where you will see an estimate of the total reimbursement value your clinic has received.',
                disableBeacon: true
            }, {
                target: '.tile_RedemptionsClientSavings',
                icon: "far fa-tachometer-fast",
                title: "Dashboard",
                stepText: "Step 4 of 5",
                content: 'This is where you will see an estimate of the total amount your clients have saved using rebates.',
                disableBeacon: true
            }, {
                target: '.tile_RedemptionsFreeDoses',
                icon: "far fa-tachometer-fast",
                title: "Dashboard",
                stepText: "Step 5 of 5",
                content: 'This is where you will see an estimate of the total number of free doses both given to clients and redeemed by the clinic.',
                disableBeacon: true
            }];
        case TOUR_NOTIFICATION_TRANSITION:
            return [{
                target: 'body',
                title: "Greenline Demo Tour",
                content: (<TourCompletion currentTourSection={TOUR_NOTIFICATION_TRANSITION}/>),
                placement: "center",
                showResumeTour: true,
                disableBeacon: true,
            }];
        case TOUR_NOTIFICATIONS_PART_1:
            return [
                {
                    target: '#topNotification',
                    icon: "far fa-bell",
                    title: "Notifications",
                    stepText: "Step 1 of 3",
                    content: "This section will show you a summary of any new notifications you have.",
                    disableBeacon: true
                }, {
                    target: '#navItem_notifications',
                    icon: "far fa-bell",
                    title: "Notifications",
                    stepText: "Step 2 of 3",
                    content: 'Click here to access all of your notifications.',
                    disableOverlayClose: false,
                    disableBeacon: true
            }];
        case TOUR_NOTIFICATIONS_PART_2:
            return [{
                target: '.ClinicBase__content',
                icon: "far fa-bell",
                title: "Notifications",
                stepText: "Step 3 of 3",
                content: "This is where you will see notifications of any offer changes or new Greenline programs available to your clinic.",
                placement: "left",
                toolTipWidth: "200px",
                disableBeacon: true
            }];
        case TOUR_COUPON_PROCESSING_TRANSITION:
            return [{
                target: 'body',
                title: "Greenline Demo Tour",
                content: (<TourCompletion currentTourSection={TOUR_COUPON_PROCESSING_TRANSITION}/>),
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_PENDING_COUPONS_PART_1:
            return [{
                target: '#navItem_coupons',
                icon: "far fa-ticket",
                title: "Coupons: Processing",
                content: "Click here to access your Pending Coupons, Coupon History, or Coupon Library.",
                disableOverlayClose: false,
                disableBeacon: true
            }];
        case TOUR_PENDING_COUPONS_PART_2:
            return [{
                target: 'body',
                icon: "far fa-ticket",
                title: "Coupons: Processing",
                stepText: "Step 1 of 23",
                content: "This is where you will process your pending coupons for reimbursement from the manufacturer.",
                placement: "center",
                disableBeacon: true,
            }, {
                target: '.pendingCouponsFirstInvoice a',
                icon: "far fa-ticket",
                title: "Coupons: Processing",
                stepText: "Step 2 of 23",
                content: 'The numbers in blue are links to PDF invoices. Click "Next" to view a sample invoice.',
                waitTimeBeforeContinue: 300,
                disableBeacon: true
            }, {
                target: '#exampleInvoice',
                icon: "far fa-ticket",
                title: "Coupons: Processing",
                stepText: "Step 3 of 23",
                content: "This is an example invoice replication; this will be pulled from your practice management system to help you reconcile and evaluate invoices.",
                disableBeacon: true
            }, {
                target: '.pendingCouponsRowRequiresValidation .fa-exclamation-triangle',
                icon: "far fa-ticket",
                stepText: "Step 4 of 23",
                title: "Coupons: Processing",
                content: 'Before you approve your coupons, be sure to check for and edit coupons that need to be adjusted first.',
                disableScrolling: false,
                disableOverlayClose: false,
                placement: "top",
                disableBeacon: true
            }];
        case TOUR_EDIT_COUPON_MODAL:
            return [{
                target: '#demoEditCouponModal',
                icon: "far fa-ticket",
                stepText: "Step 5 of 23",
                title: "Coupons: Processing",
                content: 'The sections highlighted in red show you missing coupon information. This information comes directly from your practice management system. All fields need to be completed in order to process your coupon. Click ‘Next’ to edit a practice coupon.',
                toolTipWidth: "250px",
                placement: "left",
                disableScrolling: false,
                disableBeacon: true
            }, {
                target: '#demoEditCouponModal .address1',
                icon: "far fa-ticket",
                stepText: "Step 6 of 23",
                title: "Coupons: Processing",
                content: 'The address is missing from this coupon. To continue, the address needs to be added.',
                disableScrolling: false,
                spotlightClicks: true,
                disableBeacon: true
            }, {
                target: '#demoEditCouponModal .city',
                icon: "far fa-ticket",
                stepText: "Step 7 of 23",
                title: "Coupons: Processing",
                content: 'The city is missing from this coupon. To continue, the city needs to be added.',
                disableScrolling: false,
                spotlightClicks: true,
                disableBeacon: true
            }, {
                target: '#demoEditCouponModal .state',
                icon: "far fa-ticket",
                stepText: "Step 8 of 23",
                title: "Coupons: Processing",
                content: 'The state is missing from this coupon. To continue, the state needs to be added.',
                disableScrolling: false,
                spotlightClicks: true,
                disableBeacon: true
            }, {
                target: '#demoEditCouponModal .postalCode',
                icon: "far fa-ticket",
                stepText: "Step 9 of 23",
                title: "Coupons: Processing",
                content: 'The zip code is missing from this coupon. To continue, the state zip code to be added.',
                disableScrolling: false,
                spotlightClicks: true,
                disableBeacon: true
            }, {
                target: '#demoEditCouponModal .tour-fix-coupon-submit-btn',
                icon: "far fa-ticket",
                stepText: "Step 10 of 23",
                title: "Coupons: Processing",
                content: 'Be sure to hit submit after entering your changes.',
                disableOverlayClose: false,
                disableScrolling: false,
                disableBeacon: true
            }];
        case TOUR_PENDING_COUPONS_PART_3:
            return [{
                target: '.pendingCouponsRowRequiresValidation',
                icon: "far fa-ticket",
                stepText: "Step 11 of 23",
                title: "Coupons: Processing",
                content: 'Your changes were saved. Now, this coupon can now be processed.',
                placement: "top",
                disableBeacon: true,
                scrollToStep: true,
            }, {
                target: '#tour_bottom_approval_buttons .ApprovalButtons__declineBtn',
                icon: "far fa-ticket",
                stepText: "Step 12 of 23",
                title: "Coupons: Processing",
                content: 'Should you need to decline a coupon (example; A client returns a purchased product), click the check box next to the coupon you want to decline. Now click the highlighted red “Decline” button.',
                waitTimeBeforeContinue: 300,
                disableOverlayClose: false,
                disableBeacon: true,
                scrollToStep: true,
            }, {
                target: '#tour_bottom_approval_buttons .ApprovalButtons__form .ApprovalButtons__initials',
                icon: "far fa-ticket",
                stepText: "Step 13 of 23",
                title: "Coupons: Processing",
                content: 'Enter your initials and click "Next."',
                spotlightClicks: true,
                disableBeacon: true,
                scrollToStep: true,
            }, {
                target: '#tour_bottom_approval_buttons .ApprovalButtons__form button',
                icon: "far fa-ticket",
                stepText: "Step 14 of 23",
                title: "Coupons: Processing",
                content: 'Click "Submit."',
                disableOverlayClose: false,
                disableBeacon: true,
                scrollToStep: true,
            }, {
                target: 'body',
                icon: "far fa-ticket",
                stepText: "Step 15 of 23",
                title: "Coupons: Processing",
                content: 'Coupon successfully declined',
                placement: "center",
                disableScrolling: false,
                disableBeacon: true
            }, {
                target: '#couponHistoryShortcuts',
                icon: "far fa-ticket",
                stepText: "Step 16 of 23",
                title: "Coupons: Processing",
                content: 'View coupon processing history or previously declined coupons at the bottom of the page.',
                placement: "top",
                disableScrolling: false,
                disableBeacon: true
            }, {
                target: '#couponHistoryLink',
                icon: "far fa-ticket",
                stepText: "Step 17 of 23",
                title: "Coupons: Processing",
                content: 'Your coupon processing history will show you updated stats every time you process coupons.',
                placement: "top-start",
                disableBeacon: true
            }, {
                target: '#declinedCouponsLink',
                icon: "far fa-ticket",
                stepText: "Step 18 of 23",
                title: "Coupons: Processing",
                content: 'Previously declined coupons will allow you to later process any coupons that were declined.',
                placement: "top-end",
                disableBeacon: true
            }, {
                target: 'table thead th input',
                icon: "far fa-ticket",
                stepText: "Step 19 of 23",
                title: "Coupons: Processing",
                content: 'When you’re ready to process the remaining coupons, click this checkbox to auto select all coupons to be approved.',
                disableOverlayClose: false,
                disableBeacon: true
            }, {
                target: '#tour_bottom_approval_buttons .ApprovalButtons__approveBtn',
                icon: "far fa-ticket",
                stepText: "Step 20 of 23",
                title: "Coupons: Processing",
                content: 'Once your coupons are selected, click the highlighted Green button to approve.',
                waitTimeBeforeContinue: 300,
                disableOverlayClose: false,
                disableBeacon: true
            }, {
                target: '#tour_bottom_approval_buttons .ApprovalButtons__form .ApprovalButtons__initials',
                icon: "far fa-ticket",
                stepText: "Step 21 of 23",
                title: "Coupons: Processing",
                content: 'Enter your initials and click "Next."',
                spotlightClicks: true,
                disableBeacon: true
            }, {
                target: '#tour_bottom_approval_buttons .ApprovalButtons__form button',
                icon: "far fa-ticket",
                stepText: "Step 22 of 23",
                title: "Coupons: Processing",
                content: 'Click "Submit."',
                waitTimeBeforeContinue: 300,
                disableOverlayClose: false,
                disableScrolling: true,
                disableBeacon: true
            }, {
                target: '.RunningTotals__root',
                icon: "far fa-ticket",
                stepText: "Step 23 of 23",
                title: "Coupons: Processing",
                content: "Now that you have approved your coupons, you can see a summary of those approved.",
                disableScrolling: true,
                disableBeacon: true
            }];
        case TOUR_COUPON_HISTORY_TRANSITION:
            return [{
                target: 'body',
                title: "Greenline Demo Tour",
                content: (<TourCompletion currentTourSection={TOUR_COUPON_HISTORY_TRANSITION}/>),
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_COUPON_HISTORY_PART_1:
            return [{
                target: '#navItem_coupon_history',
                icon: "far fa-ticket",
                title: "Coupons: History",
                content: "View all recently approved and declined coupons on the coupon history page.",
                disableOverlayClose: false,
                disableBeacon: true
            }];
        case TOUR_COUPON_HISTORY_PART_2:
            return [{
                target: '.CouponHistory__root table',
                icon: "far fa-ticket",
                stepText: "Step 1 of 4",
                title: "Coupons: History",
                content: 'These are the coupons that you have processed in the last 90 days.',
                placement: "center",
                disableBeacon: true
            }, {
                target: '.CouponHistory__topLine',
                icon: "far fa-ticket",
                stepText: "Step 2 of 4",
                title: "Coupons: History",
                content: 'If you want to view a different set of coupons, you can adjust the parameters at the top of this page.',
                disableScrolling: true,
                disableBeacon: true
            }, {
                target: '.SortableDataTable__download',
                icon: "far fa-ticket",
                stepText: "Step 3 of 4",
                title: "Coupons: History",
                content: "Click the green arrow to export your coupon history to Excel. This spreadsheet will provide you with detailed information about your coupons and reimbursements.",
                disableScrolling: true,
                placement: "right",
                disableOverlayClose: false,
                disableBeacon: true
            }, {
                target: 'body',
                icon: "far fa-ticket",
                stepText: "Step 4 of 4",
                title: "Coupons: History",
                content: (
                    <div>
                        <div className="margin-bottom-sm">Here is an example of what the exports will look like.</div>
                        <div className="full-width">
                            {providerId === BOEHRINGER_INGELHEIM ? (
                                <Image
                                    src={BI_EXPORT_GIF}
                                    style={{maxWidth: "100%"}}
                                    alt="Boehringer Ingelheim example coupon history export"
                                />
                            ) : providerId === ELANCO ? (
                                <Image
                                    src={ELANCO_EXPORT_GIF}
                                    style={{maxWidth: "100%"}}
                                    alt="Elanco example coupon history export"
                                />
                            ) : providerId === MERCK ? (
                                <Image
                                    src={MERCK_EXPORT_GIF}
                                    style={{maxWidth: "100%"}}
                                    alt="Merck example coupon history export"
                                />
                            ) : null}
                        </div>
                    </div>
                ),
                toolTipWidth: "700px",
                disableScrolling: true,
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_COUPON_LIBRARY_TRANSITION:
            return [{
                target: 'body',
                title: "Greenline Demo Tour",
                content: (<TourCompletion currentTourSection={TOUR_COUPON_LIBRARY_TRANSITION}/>),
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_COUPON_LIBRARY_PART_1:
            return [{
                target: '#navItem_coupon_library',
                icon: "far fa-ticket",
                title: "Coupons: Library",
                content: 'Click here to view all current offers in which your clinic is participating.',
                disableOverlayClose: false,
                disableBeacon: true
            }];
        case TOUR_COUPON_LIBRARY_PART_2:
            return [{
                target: '.CouponLibrary__grid .ProgramTile__root',
                icon: "far fa-ticket",
                title: "Coupons: Library",
                stepText: "Step 1 of 4",
                content: 'Click on the manufacturer tile to see current offers.',
                disableOverlayClose: false,
                disableBeacon: true
            }];
        case TOUR_COUPON_LIBRARY_PART_3:
            return [{
                target: '#CouponOptInOffersList',
                icon: "far fa-ticket",
                title: "Coupons: Library",
                stepText: "Step 2 of 4",
                content: 'Click on an individual offer for offer details.',
                placement: "right",
                disableOverlayClose: false,
                disableBeacon: false,
                waitTimeBeforeContinue: 300,
            }, {
                target: '.CouponOptInOffers__offerDetails',
                icon: "far fa-ticket",
                title: "Coupons: Library",
                stepText: "Step 3 of 4",
                content: 'This where you can read more details about a specific offer.',
                placement: "left",
                disableBeacon: false
            }, {
                target: '.SelectedOffer__offerActions',
                icon: "far fa-ticket",
                title: "Coupons: Library",
                stepText: "Step 4 of 4",
                content: 'Depending on your clinic’s products, you will be able to opt-in or opt-out of active offers.',
                disableOverlayClose: false,
                disableBeacon: true
            }];
        case TOUR_WELLNESS_DASHBOARD:
            return [{
                target: '.ClinicBase__content',
                icon: "far fa-tachometer-fast",
                title: "Dashboard",
                stepText: "Step 1 of 1",
                content: "This will be your clinic’s dashboard. Here you will see an overview of your clinic's total number of pets on a plan, how many pets were added this month, total number of failed payments for the month, and the total over all plan value.",
                placement: "left",
                toolTipWidth: "200px",
                disableBeacon: true
            }];
        case TOUR_PLAN_ENROLLMENT_TRANSITION:
            return [{
                target: 'body',
                title: "Greenline Demo Tour",
                content: (<TourCompletion currentTourSection={TOUR_PLAN_ENROLLMENT_TRANSITION}/>),
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_PLAN_ENROLLMENT_PART_1:
            return [{
                target: '.WellnessLanding__wellnessButtons button',
                icon: "far fa-user-plus",
                title: "Plan Enrollment",
                stepText: "Step 1 of 5",
                content: (
                    <div>
                        <div>This button is what you will click on when a client would like to enroll their pet on a wellness plan.</div>
                        <div className="text-sm text-italic padding-top-sm">*The New Plan Enrollment button can be found on other Wellness Pages throughout the Greenline site.</div>
                    </div>
                ),
                placement: "left",
                disableBeacon: true
            }];
        case TOUR_PLAN_ENROLLMENT_PART_2:
            return [{
                target: '.Modal__modalBody',
                icon: "far fa-user-plus",
                title: "Plan Enrollment",
                stepText: "Step 2 of 5",
                content: "This is where you can search for an existing client or patient within your practice management system, or you can add a first time client that wants to get enrolled with a plan.",
                placement: "left",
                disableBeacon: true,
                toolTipWidth: "350px",
            }, {
                target: '.Modal__modalBody',
                icon: "far fa-user-plus",
                title: "Plan Enrollment",
                stepText: "Step 3 of 5",
                content: "This is where you will select the client and pets that would like to enroll in a plan. The edit button next to the client name will allow you to update the client's phone number, address, and email. If the client has a new pet that is not already in your practice management system, you can add the pet directly on this page for them to enroll in a plan.",
                placement: "left",
                disableBeacon: true,
                toolTipWidth: "350px",
            }, {
                target: '.Modal__modalBody',
                icon: "far fa-user-plus",
                title: "Plan Enrollment",
                stepText: "Step 4 of 5",
                content: "Greenline will automatically put the pet on a plan based on the plan setup your clinic has, we can do this if plans are based on weight or age. The clinic does have the final say on what plan the patient is put on, and can change that plan by using the drop-down button.",
                placement: "left",
                disableBeacon: true,
                toolTipWidth: "350px",
            }, {
                target: '.Modal__modalBody',
                icon: "far fa-user-plus",
                title: "Plan Enrollment",
                stepText: "Step 5 of 5",
                content: "This page is going to take you to the payment section. You will then choose if the client would like to pay monthly or all upfront annually. Once the card information is put in, you will select the subscribe button to enroll the client.",
                placement: "left",
                disableBeacon: true,
                toolTipWidth: "350px",
            }];
        case TOUR_WELLNESS_AUDIT_TRANSITION:
            return [{
                target: 'body',
                title: "Greenline Demo Tour",
                content: (<TourCompletion currentTourSection={TOUR_WELLNESS_AUDIT_TRANSITION}/>),
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_WELLNESS_AUDIT_PART_1:
            return [{
                target: '#navItem_wellness_audit',
                icon: "far fa-paw-simple",
                title: "Wellness Audit",
                content: "Click here to access your Wellness Audit to review visits and DVM commission.",
                placement: "right",
                disableBeacon: true
            }];
        case TOUR_WELLNESS_AUDIT_PART_2:
            return [{
                target: 'body',
                icon: "far fa-paw-simple",
                title: "Wellness Audit",
                stepText: "Step 1 of 5",
                content: "This is where you will review pending wellness visits and approve the DVM commissions.",
                placement: "center",
                disableBeacon: true
            }, {
                target: '.PDF_LINK_724320194',
                icon: "far fa-paw-simple",
                title: "Wellness Audit",
                stepText: "Step 2 of 5",
                content: 'The numbers in blue are links to PDF invoices. Click "Next" to view sample invoices.',
                placement: "right",
                disableBeacon: true
            }, {
                target: 'body',
                icon: "far fa-paw-simple",
                title: "Wellness Audit",
                stepText: "Step 3 of 5",
                content: (
                    <div>
                        <div className="padding-bottom-sm">This is an example invoice replication; this will be pulled from your practice management system to help you reconcile and evaluate invoices.</div>
                        <iframe
                            src={"https://gldevcdn.blob.core.windows.net/images/tour/wellness_invoice_example.pdf"}
                            style={{ height: "500px", width: "100%" }}
                        />
                    </div>
                ),
                placement: "center",
                disableBeacon: true
            }, {
                target: '.ClinicBase__content',
                icon: "far fa-paw-simple",
                title: "Wellness Audit",
                stepText: "Step 4 of 5",
                content: "Once the wellness visits have been reviewed and are good to be approved, you will select the check box next to each visit or select under the DVM name.",
                placement: "left",
                toolTipWidth: "200px",
                disableBeacon: true
            }, {
                target: '.ClinicBase__content',
                icon: "far fa-paw-simple",
                title: "Wellness Audit",
                stepText: "Step 5 of 5",
                content: "Once your wellness visits are selected, click the highlighted green approve button. Enter your initials and click the submit button.",
                placement: "left",
                toolTipWidth: "200px",
                disableBeacon: true
            }];
        case TOUR_WELLNESS_HISTORY_TRANSITION:
            return [{
                target: 'body',
                title: "Greenline Demo Tour",
                content: (<TourCompletion currentTourSection={TOUR_WELLNESS_HISTORY_TRANSITION}/>),
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_WELLNESS_HISTORY_PART_1:
            return [{
                target: '#navItem_wellness_history',
                icon: "far fa-paw-simple",
                title: "Wellness History",
                content: "View all recently approved wellness visits on the wellness history page.",
                placement: "right",
                disableBeacon: true
            }];
        case TOUR_WELLNESS_HISTORY_PART_2:
            return [{
                target: '.UnprocessedWellnessVisits__root .TimeFilterDropdown__root',
                icon: "far fa-paw-simple",
                title: "Wellness History",
                stepText: "Step 1 of 2",
                content: "If you want to view a different set of visits, you can adjust the parameters at the top of this page.",
                placement: "bottom",
                disableBeacon: true
            //     TODO: the Wellness history download button is hidden for the wellness demo so we cannot reach this step
            // }, {
                // target: '.UnprocessedWellnessVisits__root .SortableDataTable__download',
                // icon: "far fa-paw-simple",
                // title: "Wellness History",
                // stepText: "Step 2 of 3",
                // content: "Click on the green arrow to export your wellness history to Excel. This spreadsheet will provide you with detailed information about each visit broken down by DVM and will give the commission and revenue totals.",
                // placement: "right",
                // disableBeacon: true
            }, {
                target: 'body',
                icon: "far fa-paw-simple",
                title: "Wellness History",
                stepText: "Step 2 of 2",
                content: (
                    <div className="full-width">
                        <div className="padding-bottom-sm">Here is an example of what the exports will look like.</div>
                        <div className="full-width">
                            <Image
                                src={WELLNESS_HISTORY}
                                style={{
                                    maxWidth: "600px",
                                    width: "100%",
                                }}
                                alt="Wellness history export video"
                            />
                        </div>
                    </div>
                ),
                toolTipWidth: "650px",
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_PET_PLAN_TRACKING_TRANSITION:
            return [{
                target: 'body',
                title: "Greenline Demo Tour",
                content: (<TourCompletion currentTourSection={TOUR_PET_PLAN_TRACKING_TRANSITION}/>),
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_PET_PLAN_TRACKING_PART_1:
            return [{
                target: '#navItem_pet_plan_tracking',
                icon: "far fa-paw-simple",
                title: "Pet Plan Tracking",
                content: "Click here to view the pets you have on a plan along with their status and view the usage for each pet.",
                placement: "right",
                disableBeacon: true
            }];
        case TOUR_PET_PLAN_TRACKING_PART_2:
            return [{
                target: '.ClinicBase__content',
                icon: "far fa-paw-simple",
                title: (
                    <span className="text-sm">Pet Plan Tracking</span>
                ),
                stepText: "Step 1 of 2",
                content: "Here you can see the client's name, pet's name, which plan they are enrolled in, when the plan started, how long they have been on a plan, their last payment date, and their status.",
                placement: "left",
                toolTipWidth: "200px",
                disableBeacon: true
            }, {
                target: '#petPlanTrackingModal',
                icon: "far fa-paw-simple",
                title: "Pet Plan Tracking",
                stepText: "Step 2 of 2",
                content: "By clicking on the client's name it will bring up their usage report. Here is also where you can update a client's payment and cancel a plan if needed.",
                placement: "left",
                disableBeacon: true,
            }];
        case TOUR_GAMES_TRANSITION:
            return [{
                target: 'body',
                title: "Greenline Demo Tour",
                content: (<TourCompletion currentTourSection={TOUR_GAMES_TRANSITION}/>),
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_PROGRAM_OPT_IN_TRANSITION:
            return [{
                target: 'body',
                title: "Greenline Demo Tour",
                content: (<TourCompletion currentTourSection={TOUR_PROGRAM_OPT_IN_TRANSITION}/>),
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_GAMES_PART_1:
            return [{
                target: '#navItem_dashboard',
                icon: "far fa-trophy-alt",
                title: "Greenline Games",
                content: "Click here to view Greenline Games.",
                disableOverlayClose: false,
                disableBeacon: true
            }];
        case TOUR_GAMES_PART_2:
            return [{
                target: '.GamesDashboardWidget__root',
                icon: "far fa-trophy-alt",
                title: "Greenline Games",
                stepText: "Step 1 of 3",
                content: 'Once enrolled in Greenline, you will have an option to participate in Greenline Games set up by your BI representative. Here you will see a summary of any games in which your clinic is participating.',
                disableBeacon: true
            }, {
                target: '#navItem_games',
                icon: "far fa-trophy-alt",
                title: "Greenline Games",
                stepText: "Step 2 of 3",
                content: 'Click here to view your current Greenline Games.',
                disableOverlayClose: false,
                disableBeacon: true
            }];
        case TOUR_GAMES_PART_3:
            return [{
                target: '#tour_historic_games_btn',
                icon: "far fa-trophy-alt",
                title: "Greenline Games",
                stepText: "Step 3 of 3",
                content: 'Click here to see your historic games. Here you will find completed games that are at least 30 days past end date.',
                disableOverlayClose: false,
                placement: "bottom",
                disableBeacon: true
            }];
        case TOUR_PROGRAM_OPT_IN_PART_1:
            return [{
                target: '#navItem_dashboard',
                icon: "far fa-envelope",
                title: "Program Opt-In",
                content: "Click here to view Program Opt-In.",
                disableOverlayClose: false,
                disableBeacon: true
            }];
        case TOUR_PROGRAM_OPT_IN_PART_2:
            return [{
                target: '.NotificationList__root',
                icon: "far fa-envelope",
                title: "Program Opt-In",
                stepText: "Step 1 of 4",
                content: "Once enrolled in Greenline you will have the opportunity to opt-in to additional programs provided by the manufacturer. Each program will have its own opt-in form that must be completed to participate. Here you will see the opt-in notification for the program.",
                placement: "left",
                disableBeacon: true
            }, {
                target: 'body',
                icon: "far fa-envelope",
                stepText: "Step 2 of 4",
                title: "Program Opt-In",
                content: (
                    <div>
                        <div className="margin-bottom-sm">Once the opt-in form is signed and completed check the “Available Programs” you would like to participate in.</div>
                        <div className="margin-bottom-sm">Complete the opt-in process by clicking on “Accept Program Terms.</div>
                        <div className="full-width flex flex-centered">
                            {providerId === MERCK ? (
                                <Image
                                    src={LAPSED_USER_OPT_IN}
                                    style={{
                                        maxWidth: "350px",
                                        width: "100%",
                                    }}
                                    alt="Lapsed User Opt-In video"
                                />
                            ) : null}
                        </div>
                    </div>
                ),
                toolTipWidth: "700px",
                disableScrolling: false,
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_PROGRAM_OPT_IN_PART_3:
            return [{
                target: 'body',
                icon: "far fa-envelope",
                title: "Program Opt-In",
                stepText: "Step 3 of 4",
                content: "You can access and view previously completed opt-in notifications here in the Notification Center.",
                disableOverlayClose: false,
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_PROGRAM_OPT_IN_PART_4:
            return [{
                target: 'body',
                icon: "far fa-envelope",
                title: "Program Opt-In",
                stepText: "Step 4 of 4",
                content: "Click on Coupon Library to view your program opt-in status.",
                disableOverlayClose: false,
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_REPORTS_TRANSITION:
            return [{
                target: 'body',
                title: "Greenline Demo Tour",
                content: (<TourCompletion currentTourSection={TOUR_REPORTS_TRANSITION}/>),
                disableOverlayClose: false,
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_REPORTS:
            return [{
                target: '#wellnessPayoutReportTile',
                icon: "far fa-chart-line",
                title: "Wellness Payout Report",
                stepText: "Step 1 of 2",
                content: "Click here to view the monthly pay out for the clinic and review the status report for each pet on plan.",
                disableOverlayClose: false,
                placement: "left",
                disableBeacon: true
            }, {
                target: 'body',
                icon: "far fa-chart-line",
                title: "Wellness Payout Report",
                stepText: "Step 2 of 2",
                content: (
                    <div className="full-width">
                        <div className="padding-bottom-sm">Here is an example of what the wellness payout report will look like.</div>
                        <div className="full-width">
                            <Image
                                src={WELLNESS_PAYOUT_REPORT}
                                style={{
                                    maxWidth: "600px",
                                    width: "100%",
                                }}
                                alt="Wellness payout report video"
                            />
                        </div>
                    </div>
                ),
                disableOverlayClose: false,
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_RESOURCES_TRANSITION:
            return [{
                target: 'body',
                title: "Greenline Demo Tour",
                content: (<TourCompletion currentTourSection={TOUR_RESOURCES_TRANSITION}/>),
                disableOverlayClose: false,
                placement: "center",
                disableBeacon: true
            }];
        case TOUR_RESOURCES:
            return [{
                target: '#navItem_resources',
                icon: "far fa-books",
                title: "Resources",
                content: 'Click here for helpful Greenline resources including how to contact us.',
                disableOverlayClose: false,
                disableBeacon: true
            }];
        case TOUR_COMPLETE_TRANSITION:
            return [{
                target: '.Header__searchContainer',
                title: "Greenline Demo Tour",
                content: (
                    <div>
                        <TourCompletion currentTourSection={TOUR_COMPLETE_TRANSITION}/>
                        <hr/>
                        <div>You can go back to a section of the tour, or click "Complete Tour" to finish and continue to Registration.</div>
                    </div>
                ),
                placement: "bottom",
                placementBeacon: "bottom-start",
                nextButtonText: "Complete Tour",
            }];
        default:
            return null;
    }
};
