import React, {useState} from "react";
import PropTypes from "prop-types";
import styles from "./ProgramWelcomeForm.scss";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import FrequentlyAskedQuestions from "components/layout/elements/FrequentlyAskedQuestions";
import Modal from "components/common/Modal";
import PrivacyPolicy from "components/layout/elements/PrivacyPolicy";
import RadioButtons from "components/common/RadioButtons";
import TermsAndConditions from "components/layout/elements/TermsAndConditions";
import * as CssStyleIds from "constants/CssStyleIds";
import * as ProviderIds from "constants/ProviderIds";

export default function ProgramWelcomeForm(props) {
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
    const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
    const [showFrequentlyAskedQuestions, setShowFrequentlyAskedQuestions] = useState(false);
    const [agreeClinicShare, setAgreeClinicShare] = useState(true);
    const [agreeStaffShare, setAgreeStaffShare] = useState(true);
    const [agreeToTermsAndConditions, setAgreeToTermsAndConditions] = useState(false);

    if(!props.initialData) {
        return null;
    }

    const handleAccept = () => {
        const enrollData = {
            planId: props.initialData.planId,
            providerId: props.initialData.providerId,
            voucherCode: props.voucherCode,
            acceptsGreenlineTerms: agreeToTermsAndConditions,
            acceptsProviderTerms: agreeToTermsAndConditions,
            agreesToClinicDataShare: agreeClinicShare,
            agreesToStaffDataShare: agreeStaffShare,
            isInstantRebateOptin: true
        }
        if (agreeToTermsAndConditions) {
            props.onSubmit(enrollData);
        }
    }

    const handleDecline = () => {
        props.onCancel();
    }

    const handleShowPrivacyPolicy = () => {
        setShowPrivacyPolicy(true);
    }

    const handleShowFrequentlyAskedQuestions = () => {
        setShowFrequentlyAskedQuestions(true);
    }

    const handleShowInstantRebateFAQ = () => {
        window.open("https://glprodcdnst.blob.core.windows.net/content/resources/Instant_Rebate_FAQ_Self_Enroll.pdf");
    }

    return (
        <div
            data-testid="program_welcome_form_component"
            className={styles.root}
        >
            <div>
                <img src={props.initialData.imageUri} alt={props.initialData.header}/>
            </div>
            <div className="margin-top-md">
                <span dangerouslySetInnerHTML={{ __html: props.initialData.message }}/></div>
            <hr className="margin-top-md margin-bottom-md"/>
            <div className="flex spaced-content">
                <div className="flex-3 flex-column spaced-content">
                    <div className="margin-bottom-lg">
                        <CheckboxInput
                            data-testid="program_welcome_form_clinic_rx_share_checkbox"
                            name="rxClinic"
                            onChange={() => setAgreeClinicShare(!agreeClinicShare)}
                            label="Rx Data Share - Clinic"
                            checked={true}
                            disabled
                        />
                        <div>Market Share of flea, tick and heartworm products for a selected timeframe.</div>
                    </div>
                    <div>
                        <CheckboxInput
                            data-testid="program_welcome_form_staff_rx_share_checkbox"
                            name="rxStaff"
                            onChange={() => setAgreeStaffShare(!agreeStaffShare)}
                            label="Rx Data Share - Staff"
                            checked={agreeStaffShare}
                        />
                        <div>Market Share of flea, tick and heartworm products for a selected timeframe, by doctor and staff members.</div>
                    </div>
                </div>
                <div className="flex-column flex-1 spaced-content">
                    <div className="margin-bottom-x-sm">
                        <Button
                            data-testid="program_welcome_form_show_privacy_policy_button"
                            text
                            type="primary"
                            onClick={handleShowPrivacyPolicy}
                        >
                            Greenline Data Privacy Policy
                        </Button>
                    </div>
                    <div>
                        <Button
                            data-testid="program_welcome_form_show_frequently_asked_questions_button"
                            text
                            type="primary"
                            onClick={handleShowFrequentlyAskedQuestions}
                        >
                            FAQ's
                        </Button>
                    </div>
                </div>
            </div>
            {(Number(props.initialData.providerId) === ProviderIds.BOEHRINGER_INGELHEIM ) && (
                <div data-testid="program_welcome_form_rebate_options">
                    <hr className="margin-top-md margin-bottom-md"/>
                    <div className="flex spaced-content">
                        <div className="flex-3">Boehringer Ingelheim <b className="text-bold"> Instant Rebate Program</b></div>
                        <div className="flex-1">
                            <Button
                                data-testid="program_welcome_form_instant_rebate_faq_button"
                                text
                                type="primary"
                                onClick={handleShowInstantRebateFAQ}
                            >
                                Instant Rebate FAQ
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            <hr className="margin-top-md margin-bottom-md"/>
            <div className="flex spaced-content">
                <label className="flex">
                    <CheckboxInput
                        data-testid="program_welcome_form_terms_and_conditions_checkbox"
                        name="acceptsTermsAndConditions"
                        onChange={() => setAgreeToTermsAndConditions(!agreeToTermsAndConditions)}
                        checked={agreeToTermsAndConditions}
                    />
                    <div>I accept the <span
                        data-testid="program_welcome_form_show_terms_and_conditions_button"
                        className="text-primary pointer" onClick={() => setShowTermsAndConditions(true)}
                    >terms and conditions.</span>*</div>
                </label>
            </div>
            <div className="flex align-center justify-flex-end spaced-content margin-top-lg">
                <Button
                    data-testid="program_welcome_form_decline_button"
                    type={!!props.creating ? CssStyleIds.PRIMARY : CssStyleIds.NOTIFY}
                    wide
                    large
                    icon={!!props.creating}
                    onClick={handleDecline}
                >
                    {props.creating ? (<span><i className="fas fa-reply"/> Back</span>) : "Decline"}
                </Button>
                <Button
                    data-testid="program_welcome_form_accept_button"
                    type={CssStyleIds.SUCCESS}
                    wide
                    large
                    onClick={handleAccept}
                    disabled={!agreeToTermsAndConditions || props.termsAccepted}
                >
                    Accept
                </Button>
            </div>
            <Modal
                data-testid="program_welcome_form_privacy_policy_modal"
                show={showPrivacyPolicy}
                onClose={() => setShowPrivacyPolicy(false)}
                modalTitle="Privacy Policy"
                medium
            >
                <PrivacyPolicy/>
            </Modal>
            <Modal
                data-testid="program_welcome_form_terms_and_conditions_modal"
                show={showTermsAndConditions}
                onClose={() => setShowTermsAndConditions(false)}
                modalTitle="Terms and Conditions"
                medium
            >
                <TermsAndConditions/>
            </Modal>
            <Modal
                data-testid="program_welcome_form_frequently_asked_questions_modal"
                show={showFrequentlyAskedQuestions}
                onClose={() => setShowFrequentlyAskedQuestions(false)}
                modalTitle="Frequently Asked Questions"
                medium
            >
                <FrequentlyAskedQuestions/>
            </Modal>
        </div>
    );
}

ProgramWelcomeForm.propTypes = {
    initialData: PropTypes.object,
    creating: PropTypes.bool,
    voucherCode: PropTypes.string,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    submitLabel: PropTypes.string,
    termsAccepted: PropTypes.bool,
};
