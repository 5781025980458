import React from "react";
import {
	MAIN_COLOR,
	SECONDARY_COLOR,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./KittyPodiumCenter.scss";


export default function KittyPodiumCenter(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg id="Winning_-_Kitty" data-name="Winning - Kitty" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 800 300">
			<defs>
				<style>

				</style>
			</defs>
			<g id="Kitty_Winner_Gold02">
				<g id="Kitty_Winner_Gold02_Lower">
					<path className={styles.cls14}
					      d="M580.53,269.13c-.12-.74-.17-1.49-.16-2.24.05-3.78,1.56-7.51,3.57-10.96,4.57-7.88,12.5-16.05,22.34-19.47,8.92-3.09,27.71-4.62,34.02,2.17,1.09,1.17,1.76,2.51,2.27,3.87,3.77,10.11-2.33,21.74-14.55,27.72,4.8,1.21,10.56,2.76,10.57,8.84,0,8.44-29.06,8.18-29.8.23-.31-3.34-.18-3.11.32-6.17-2.69,3.79-4.47,3.83-9.35,5.37s-10.65,1.12-14.5-1.6c-2.69-1.91-4.24-4.76-4.72-7.77Z"/>
					<g>
						<path className={styles.cls11}
						      d="M507.38,248.68c3.24,8.97,17.67,16.8,25.74,21.3,4.66,2.6,11.03,6.42,16.73,6.44,7.63.04,9.28-5.73,2.78-10.48-4.13-3.03-8.85-3.34-13.03-6.79-4.67-3.85-9.19-7.49-13.46-11.78-3.54-3.56-7.47-8.38-12.96-7.6-4.36.63-7.4,4.46-5.8,8.9Z"/>
						<path className={styles.cls9}
						      d="M507.38,248.68c-1.6-4.45,1.44-8.28,5.8-8.9,5.49-.79,9.42,4.04,12.96,7.6.05.05.1.1.15.14-3.3,1.61-6.33,3.86-8.21,6.81-.87,1.37-1.48,3.07-2.12,4.79-3.95-3.18-7.23-6.71-8.57-10.43Z"/>
					</g>
					<g>
						<path className={styles.cls13}
						      d="M596.31,266.07c10.25-.35,45.56-35.37,38.51-87.11-1.27-9.33-1.83-19.8-5.59-30.29-6.29-3.31-19.6-3.75-29.14-5.16-6.49-.96-21.12-3.65-26.84,1.13-5.43,4.55-13.14,18.69-13.14,28.22s2.07,18.85.4,27.3c-4.5,22.84-13.34,40.7-15.55,48.79-4.66,17.04-.37,31.82,8.09,33.53,14.47-7.44,38.55-16.26,43.26-16.42Z"/>
						<path className={styles.cls14}
						      d="M567.34,243.35c-1.58-17.22,29.6-22.98,36.98-9.85,18.12-12.46,25.68-34.75,26-58.36.04-3.25-.25-18.2-1.96-22.52-9.69,7.42-51.11,7.67-48.24-1.22,1.22-3.8,46.13-5.91,49.33-3.97,22.66,42.72-8.8,121.07-30.8,120.7-6.16-.1-30.44-15.46-31.29-24.77Z"/>
					</g>
					<path className={styles.cls11}
					      d="M547.78,240.91c1-3.1,5.16-13.54,6.16-16.63,3.02-9.4,33.12,6.62,30.76,11.27-5.57,10.98-27.01,11.49-36.92,5.36Z"/>
					<path className={styles.cls14}
					      d="M703.04,91.67c5.63,15.97-22.87,19.77-24.69,21.18-8.59,6.62-16.21,36.42-48.87,61.59-2.36,1.82-31.05-8.57-8.36-31.23,15.85-15.83,32.58-27.43,50.44-46.7,10.97-11.83,29.97-9.12,31.48-4.84Z"/>
				</g>
				<path
					id="Shirt"
					className={styles.cls2}
					d="M636.48,183.9c-.76-8.84-.85-36.12-8.21-40.98l-3.13,2.2c5.43,7.94,7.31,30.07-2.4,33.97-8.94,3.6-14.2-3.41-17.32-9.08-8.53-15.5-29.31-33.8-40.2-24.77-11.21,9.29-6.83,46.84-7.86,57.82-.8,8.55-4.83,14.64-7.51,19.17,20.67,15.42,49.94,17.52,80.63,6.86,5.54-16.36,6.77-36.28,6.01-45.2ZM577.09,190.44c-10.34-.97-17.95-10.04-16.99-20.26.96-10.22,10.12-17.72,20.46-16.75,10.34.97,17.95,10.04,16.99,20.26-.96,10.22-10.12,17.72-20.46,16.75Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes">
					<path
						className={styles.cls16}
						d="M617.36,181.7c-7.49,0-12.24-7.15-17.68-15.34-6.03-9.08-12.87-19.38-25.1-22.51l.7-2.72c13.27,3.4,20.43,14.18,26.74,23.68,5.5,8.28,9.84,14.81,16.49,14.02,3.55-.43,6.12-2.06,7.86-4.98,4.21-7.07,2.27-19.94-.45-26.83l2.61-1.03c2.73,6.91,5.21,20.97.25,29.3-2.18,3.67-5.53,5.8-9.93,6.33-.51.06-1.01.09-1.49.09Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls16}
						d="M618.13,221.71c-8.71,1.63-17.44,2.29-25.92,1.91-13.66-.6-26.84-3.96-37.83-9.67.82-1.73,1.59-4.11,1.93-5.29,10.43,5.55,23.01,8.94,36.14,9.52,14.43.63,27.73-1.15,42.28-6.52.05.88-.7,4.41-.81,5.66-6.07,2.13-9.6,3.24-15.79,4.4Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls16}
						d="M580.68,152.07c-11.08-1.04-20.92,7.03-21.95,17.98-1.03,10.95,7.15,20.71,18.22,21.75,11.08,1.04,20.92-7.03,21.95-17.98,1.03-10.95-7.15-20.71-18.22-21.75ZM577.21,189.08c-9.58-.9-16.65-9.32-15.76-18.78.89-9.46,9.4-16.42,18.98-15.52,9.58.9,16.65,9.32,15.76,18.78-.89,9.45-9.4,16.42-18.98,15.52Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Winner_Gold02_Mid">
					<path className={styles.cls13}
					      d="M547.93,258.02c3.63-9.47,10.36-17.25,19.87-23.38,7.95-5.12,26.28-8.26,33.3-.64,1.21,1.31,2.03,2.85,2.68,4.41,4.85,11.63.03,25.53-11.5,33.13,4.91,1.17,11.3,2.89,11.47,9.8.29,11.7-26.35,10.29-30.05,3.2-1.75-3.36-.35-6.56.69-9.75-1.93,3.08-4.67,5.8-9.36,7.84-4.69,2.05-10.48,1.85-14.61-1.14-2.89-2.09-7.36-10.78-2.5-23.48Z"/>
					<path className={styles.cls7}
					      d="M565.61,128.43c-2.14,2.67-.64,9.7.39,12.93.97,3.08,4.62,10.08,22.61,14.58,17.99,4.5,38.88-3.13,40.65-8.26,4.64-13.47-8.36-19.24-19.59-23.5-10.57-4.01-27.1-8.64-37.06-1.37-2.14,1.56-5.33,3.56-6.99,5.62Z"/>
					<g>
						<path className={styles.cls15}
						      d="M563.6,146.84c4.42,7.47,48.6,18.95,59.09,16.24,6.29-4.54,11.39-13.66,11.15-18.26-.13-2.46-.46-3.77-3.75-4.2.04,7.03-5.35,17.59-7.68,20.87-9.83.59-46.16-16.13-55.23-17.55-1.57-.25-2.68,1.28-3.59,2.89Z"/>
						<g>
							<circle className={styles.cls3} cx="624.39" cy="179.16" r="12.98"/>
							<ellipse className={styles.cls1} cx="622.31" cy="163.63" rx="1.56" ry="3.02"
							         transform="translate(-16.19 83.86) rotate(-7.61)"/>
							<circle className={styles.cls1} cx="625.47" cy="177.62" r="12.98"/>
						</g>
					</g>
					<g>
						<g>
							<path className={styles.cls11}
							      d="M682.9,50.76c-12.81,5.53-25.63,14.06-31.34,26.21-.28.59.74.98,1.02.39,5.6-11.9,18.21-20.25,30.75-25.67.63-.27.21-1.2-.43-.92Z"/>
							<path className={styles.cls11}
							      d="M693.31,66.45c-13.71,3.71-26.44,9.22-37.49,17.49-.54.41.14,1.2.69.79,10.97-8.21,23.61-13.66,37.23-17.35.68-.18.25-1.11-.43-.93Z"/>
							<path className={styles.cls11}
							      d="M695.46,79.42c-12.49,1.02-24.35,4.66-35.87,8.89-.66.24-.23,1.17.43.93,11.44-4.2,23.19-7.81,35.58-8.83.71-.06.57-1.05-.14-.99Z"/>
						</g>
						<path className={styles.cls4}
						      d="M567.34,39.99c3.49,9.07,6.85,17.47,7.55,18.88,1.48,2.93,1.22,3.05,4.11,4.78,3.8,2.27,43.31-11.71,46.01-21.47-9.82-8.1-28.47-18.21-39.02-21.55-7.57-2.39-19.56-6.46-27.45-5.48-.53.07,4.25,13.02,8.8,24.84Z"/>
						<g>
							<path className={styles.cls5}
							      d="M559.34,101.43c38.89-42.4,12.22-47.4-1.33-47.55,3.69-2.77,7.58-5.27,11.55-7.47,8.53,2.57,34.38,8.4,62.34-4.49,7.26,5.03,13.2,12.32,19.03,19.02,6.67,7.67,14.43,7.13,17.47,25.75,3.51,21.51-10.14,30.25-18.76,37.55-10.25,8.68-24.29,15.26-37.09,19.6-8.53,2.89-24.88,4.99-33.96,3.26-18.73-3.56-30.79-16.64-38.89-28.75-.39-.59-.77-1.18-1.13-1.78,11.11-6.23,20.78-15.15,20.78-15.15Z"/>
							<path className={styles.cls9}
							      d="M536.43,72.81c2.5-7.25,6.84-16.26,21.58-18.93,13.55.15,40.22,5.15,1.33,47.55,0,0-9.67,8.92-20.78,15.15-9.09-14.99-7.02-29.55-2.13-43.77Z"/>
							<path className={styles.cls11}
							      d="M569.56,46.41c10.09-5.59,20.61-9.18,29.23-9.84,9.71-.75,23.24-.97,31.72,4.43.47.3.93.61,1.38.92-27.96,12.89-53.81,7.06-62.34,4.49Z"/>
						</g>
						<g>
							<path className={styles.cls11}
							      d="M585.12,122.22c10.05-9.34,22.82-17.72,36.13-18.73.65-.05.6.97-.04,1.02-13.03.99-25.57,9.24-35.41,18.39-.5.46-1.18-.21-.68-.67Z"/>
							<path className={styles.cls11}
							      d="M595.36,136.4c8.74-10.73,18.73-20,30.57-26.93.58-.34,1.05.51.46.86-11.77,6.87-21.67,16.09-30.35,26.74-.43.53-1.11-.15-.68-.67Z"/>
							<path className={styles.cls11}
							      d="M606.35,142.51c5.81-10.54,13.75-19.67,22.11-28.32.48-.49,1.15.18.68.67-8.3,8.58-16.17,17.64-21.94,28.09-.33.6-1.18.15-.85-.45Z"/>
						</g>
						<path className={styles.cls6}
						      d="M627.28,69.99c-.14.01-.28.01-.42,0-1.06-.13-1.81-1.1-1.68-2.16.55-4.39,3.07-9.82,7.45-12.06,2.69-1.38,5.6-1.29,8.41.25.94.51,1.28,1.69.77,2.62-.51.94-1.68,1.28-2.62.77-1.69-.92-3.25-.99-4.8-.2-2.62,1.34-4.88,5.17-5.37,9.1-.11.92-.85,1.6-1.73,1.68Z"/>
						<path className={styles.cls6}
						      d="M586.77,101.93c-.09,0-.18.01-.27,0-1.07-.04-1.89-.94-1.85-2.01.34-8.11,4.03-14.95,10.12-18.77,4.91-3.08,10.76-3.65,15.65-1.53.98.42,1.43,1.56,1,2.54-.42.98-1.56,1.43-2.54,1-3.72-1.61-8.22-1.14-12.06,1.26-5,3.13-8.03,8.84-8.31,15.66-.04.98-.8,1.75-1.74,1.84Z"/>
						<path className={styles.cls10}
						      d="M645.86,101.45c4.75,2.17,7.65.59,10.11-1.3,3.29-2.53,3.07-6.86,2.48-10.07,1.54,1.8,4.44,3.72,6.44,3.14,1.1,2.01,1.57,16.61-8.07,18.78-9.33,2.1-11.18-8.73-10.96-10.55Z"/>
						<path className={styles.cls11}
						      d="M644.41,99.12c.52.41,1.02.91,1.19,1.04,1.07.83,2.3,1.56,3.67,1.71,2.69.28,5.91-1.19,7.51-3.43,1.9-2.67,1.65-5.34.27-8.16-.68-1.39-1.3-3.17-2.34-4.33-.23-.26-.66-.5-.83-.79-.23-.37.11-1.46.56-1.59.93-.26,1.55.94,1.89,1.6.54,1.03,1.11,1.89,1.78,2.85,1.21,1.75,2.66,4.03,4.89,4.53,1.63.37,3.28-.37,3.62-2.1.17-.88-.1-1.67-.44-2.47-.1-.23-.81-1.73-.3-1.93.86-.33,1.31,1.6,1.48,2.12.71,2.14.51,5.47-2.21,5.98-1.13.21-2.31-.12-3.32-.59-.94-.43-2.17-.83-2.65-1.83.83,2.69.4,5.22-1.25,7.52-.73,1.02-1.72,1.9-2.85,2.57-2.65,1.59-5.59,1.86-8.4.62-.78-.34-4.87-2.69-3.65-3.85.26-.25.84.09,1.38.53Z"/>
						<path className={styles.cls8}
						      d="M652.02,74.09c-.99.67-1.86,1.6-2.89,2.46-1.64,1.36-4.67,2.77-5.68,4.47-2.42,4.07,3.23,5.35,7.18,5.04,4.46-.34,11.44-4.47,8.96-9.95-.84-1.86-2.49-2.87-4.51-2.94-1.24-.04-2.2.34-3.06.92Z"/>
					</g>
					<g>
						<path className={styles.cls9}
						      d="M519.86,90.71c8.59,5.25,16.63,10.05,18.09,10.76,3.05,1.48,2.92,1.74,6.31,1.35,4.45-.51,17.7-38.18,13.54-47.87-10.83-1.73-25.29,4.41-35.74,8.26-7.49,2.76-19.54,6.9-25.11,12.63-.37.38,11.72,8.02,22.91,14.87Z"/>
						<path className={styles.cls8} d="M551.03,67.34s-24.89,1.62-42.04,10.31l32.38,18.51,9.66-28.81Z"/>
					</g>
					<g>
						<polygon className={styles.cls12}
						         points="677.92 94.5 680.35 87.12 705.07 89.2 700.67 102.59 677.92 94.5"/>
						<polygon className={styles.cls12}
						         points="675.94 79.12 672.32 72.5 692.51 57.66 699.07 69.66 675.94 79.12"/>
						<polygon className={styles.cls12}
						         points="666.73 64.33 659.54 61.16 669.31 39.41 682.34 45.14 666.73 64.33"/>
					</g>
				</g>
				<g id="Stripes-2" data-name="Stripes">
					<path
						className={styles.cls16}
						d="M547.63,126.57c13.4-35.8,54.74-74.11,85.69-82.98,2.37-.68-13.58-11.9-21.62-10.51-20.75,3.59-75.25,52.17-74.98,80.54.04,4.22,9.65,16.31,10.9,12.95Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<path id="Kitty_Winner_Gold02_Upper" className={styles.cls13}
				      d="M495.04,113.72c-1.26,8,8.06,15.96,25.68,18.92,19.03,15.92,36.6,58.46,64.82,53.65,7.57-1.29,5.61-26.17-2.29-29.1-15.38-5.71-36.86-20.38-53.75-35.47-12.02-15.57-33.06-16.91-34.46-8Z"/>
			</g>
			<g id="Kitty_Winner_Gold01">
				<g id="Kitty_Winner_Gold01_Lower">
					<path className={styles.cls14}
					      d="M176.52,269.13c-.12-.74-.17-1.49-.16-2.24.05-3.78,1.56-7.51,3.57-10.96,4.57-7.88,12.5-16.05,22.34-19.47,8.92-3.09,27.71-4.62,34.02,2.17,1.09,1.17,1.76,2.51,2.27,3.87,3.77,10.11-2.33,21.74-14.55,27.72,4.8,1.21,10.56,2.76,10.57,8.84,0,8.44-29.06,8.18-29.8.23-.31-3.34-.18-3.11.32-6.17-2.69,3.79-4.47,3.83-9.35,5.37s-10.65,1.12-14.5-1.6c-2.69-1.91-4.24-4.76-4.72-7.77Z"/>
					<g>
						<path className={styles.cls11}
						      d="M103.37,248.68c3.24,8.97,17.67,16.8,25.74,21.3,4.66,2.6,11.03,6.42,16.73,6.44,7.63.04,9.28-5.73,2.78-10.48-4.13-3.03-8.85-3.34-13.03-6.79-4.67-3.85-9.19-7.49-13.46-11.78-3.54-3.56-7.47-8.38-12.96-7.6-4.36.63-7.4,4.46-5.8,8.9Z"/>
						<path className={styles.cls9}
						      d="M103.37,248.68c-1.6-4.45,1.44-8.28,5.8-8.9,5.49-.79,9.42,4.04,12.96,7.6.05.05.1.1.15.14-3.3,1.61-6.33,3.86-8.21,6.81-.87,1.37-1.48,3.07-2.12,4.79-3.95-3.18-7.23-6.71-8.57-10.43Z"/>
					</g>
					<path className={styles.cls14}
					      d="M259.38,55.48c12.21,11.74-11.55,27.94-12.55,30.01-4.7,9.77,1.87,39.82-16,76.98-1.29,2.69-31.59,6.29-21.5-24.14,7.05-21.26,16.78-39.14,24.09-64.38,4.49-15.5,22.68-21.61,25.96-18.46Z"/>
					<path className={styles.cls5}
					      d="M192.31,266.07c10.25-.35,45.56-35.37,38.51-87.11-1.27-9.33-1.83-19.8-5.59-30.29-6.29-3.31-19.6-3.75-29.14-5.16-6.49-.96-21.12-3.65-26.84,1.13-5.43,4.55-13.14,18.69-13.14,28.22s2.07,18.85.4,27.3c-4.5,22.84-13.34,40.7-15.55,48.79-4.66,17.04-.37,31.82,8.09,33.53,14.47-7.44,38.55-16.26,43.26-16.42Z"/>
					<path className={styles.cls14}
					      d="M163.33,243.35c-1.58-17.22,29.6-22.98,36.98-9.85,18.12-12.46,25.68-34.75,26-58.36.04-3.25-.25-18.2-1.96-22.52-9.69,7.42-51.11,7.67-48.24-1.22,1.22-3.8,46.13-5.91,49.33-3.97,22.66,42.72-8.8,121.07-30.8,120.7-6.16-.1-30.44-15.46-31.29-24.77Z"/>
					<path className={styles.cls11}
					      d="M143.77,240.91c1-3.1,5.16-13.54,6.16-16.63,3.02-9.4,33.12,6.62,30.76,11.27-5.57,10.98-27.01,11.49-36.92,5.36Z"/>
				</g>
				<path
					id="Shirt-2"
					data-name="Shirt"
					className={styles.cls2}
					d="M232.57,183.9c-.76-8.84-.85-36.12-8.21-40.98l-3.13,2.2c5.43,7.94,7.31,30.07-2.4,33.97-8.94,3.6-14.2-3.41-17.32-9.08-8.53-15.5-29.31-33.8-40.2-24.77-11.21,9.29-6.83,46.84-7.86,57.82-.8,8.55-4.83,14.64-7.51,19.17,20.67,15.42,49.94,17.52,80.63,6.86,5.54-16.36,6.77-36.28,6.01-45.2ZM173.18,190.44c-10.34-.97-17.95-10.04-16.99-20.26.96-10.22,10.12-17.72,20.46-16.75,10.34.97,17.95,10.04,16.99,20.26-.96,10.22-10.12,17.72-20.46,16.75Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-3" data-name="Stripes">
					<path
						className={styles.cls16}
						d="M177.05,152.07c-11.08-1.04-20.92,7.03-21.95,17.98-1.03,10.95,7.15,20.71,18.22,21.75,11.08,1.04,20.92-7.03,21.95-17.98,1.03-10.95-7.15-20.71-18.22-21.75ZM173.58,189.08c-9.58-.9-16.65-9.32-15.76-18.78.89-9.46,9.4-16.42,18.98-15.52s16.65,9.32,15.76,18.78c-.89,9.45-9.4,16.42-18.98,15.52Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls16}
						d="M214.23,221.71c-8.71,1.63-17.44,2.29-25.92,1.91-13.66-.6-26.84-3.96-37.83-9.67.82-1.73,1.59-4.11,1.93-5.29,10.43,5.55,23.01,8.94,36.14,9.52,14.43.63,27.73-1.15,42.28-6.52.05.88-.7,4.41-.81,5.66-6.07,2.13-9.6,3.24-15.79,4.4Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls16}
						d="M213.73,181.7c-7.49,0-12.24-7.15-17.68-15.34-6.03-9.08-12.87-19.38-25.1-22.51l.7-2.72c13.27,3.4,20.43,14.18,26.74,23.68,5.5,8.28,9.84,14.81,16.49,14.02,3.55-.43,6.12-2.06,7.86-4.98,4.21-7.07,2.27-19.94-.45-26.83l2.61-1.03c2.73,6.91,5.21,20.97.25,29.3-2.18,3.67-5.53,5.8-9.93,6.33-.51.06-1.01.09-1.49.09Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Winner_Gold01_Mid">
					<path className={styles.cls5}
					      d="M143.93,258.02c3.63-9.47,10.36-17.25,19.87-23.38,7.95-5.12,26.28-8.26,33.3-.64,1.21,1.31,2.03,2.85,2.68,4.41,4.85,11.63.03,25.53-11.5,33.13,4.91,1.17,11.3,2.89,11.47,9.8.29,11.7-26.35,10.29-30.05,3.2-1.75-3.36-.35-6.56.69-9.75-1.93,3.08-4.67,5.8-9.36,7.84-4.69,2.05-10.48,1.85-14.61-1.14-2.89-2.09-7.36-10.78-2.5-23.48Z"/>
					<path className={styles.cls7}
					      d="M161.11,128.44c-2.14,2.67-.64,9.7.39,12.93.97,3.08,4.62,10.08,22.61,14.58,17.99,4.5,38.88-3.13,40.65-8.26,4.64-13.47-8.36-19.24-19.59-23.5-10.57-4.01-27.1-8.64-37.06-1.37-2.14,1.56-5.33,3.56-6.99,5.62Z"/>
					<g>
						<path className={styles.cls15}
						      d="M172.16,150.52c4.42,7.47,35.53,14.06,46.02,11.34,6.29-4.54,11.39-13.66,11.15-18.26-.13-2.46-.46-3.77-3.75-4.2.04,7.03-5.35,17.59-7.68,20.87-9.83.59-28.78-10.12-37.89-11.2-2.09-.25-5.13-.78-7.85,1.45Z"/>
						<g>
							<circle className={styles.cls3} cx="219.88" cy="177.94" r="12.98"/>
							<ellipse className={styles.cls1} cx="217.81" cy="162.41" rx="1.56" ry="3.02"
							         transform="translate(-19.59 30.28) rotate(-7.61)"/>
							<circle className={styles.cls1} cx="220.96" cy="176.41" r="12.98"/>
						</g>
					</g>
					<g>
						<g>
							<path className={styles.cls11}
							      d="M289.23,68.9c-13.43,3.78-27.27,10.53-34.55,21.81-.36.55.61,1.07.96.52,7.13-11.05,20.74-17.65,33.89-21.36.67-.19.37-1.16-.3-.97Z"/>
							<path className={styles.cls11}
							      d="M297.47,85.83c-14.09,1.86-27.43,5.63-39.48,12.35-.59.33-.02,1.21.58.88,11.97-6.68,25.22-10.41,39.2-12.26.7-.09.39-1.07-.3-.97Z"/>
							<path className={styles.cls11}
							      d="M297.87,98.97c-12.51-.65-24.75,1.38-36.73,4.05-.69.15-.38,1.13.3.98,11.89-2.65,24.02-4.66,36.44-4.02.71.04.7-.97,0-1Z"/>
						</g>
						<path className={styles.cls4}
						      d="M176.13,42.87c2.26,9.45,4.46,18.23,4.98,19.72,1.08,3.1.8,3.19,3.44,5.28,3.47,2.75,44.48-5.85,48.45-15.16-8.66-9.33-25.79-21.83-35.81-26.54-7.18-3.38-18.53-9-26.48-9.08-.53,0,2.48,13.47,5.42,25.79Z"/>
						<g>
							<path className={styles.cls5}
							      d="M160.04,102.71c44.18-36.86,18.41-45.35,5-47.31,4.02-2.25,8.22-4.22,12.44-5.87,8.11,3.68,32.96,12.89,62.38,3.83,6.53,5.95,11.44,13.96,16.33,21.38,5.59,8.49,13.36,8.98,13.9,27.85.62,21.79-14.07,28.64-23.59,34.73-11.31,7.24-26.1,11.9-39.37,14.5-8.84,1.74-25.32,1.64-34.09-1.28-18.1-6.02-28.31-20.59-34.73-33.67-.31-.64-.6-1.27-.88-1.91,11.84-4.7,22.61-12.25,22.61-12.25Z"/>
							<path className={styles.cls9}
							      d="M141.13,71.3c3.44-6.85,8.94-15.21,23.9-15.9,13.41,1.95,39.18,10.45-5,47.31,0,0-10.77,7.56-22.61,12.25-7.02-16.07-3.04-30.22,3.71-43.66Z"/>
							<path className={styles.cls11}
							      d="M177.47,49.53c10.74-4.2,21.64-6.36,30.28-5.87,9.72.54,23.16,2.12,30.85,8.61.42.36.84.72,1.25,1.1-29.42,9.06-54.27-.15-62.38-3.83Z"/>
						</g>
						<g>
							<path className={styles.cls11}
							      d="M182.82,126.74c11.2-7.93,24.97-14.53,38.3-13.77.65.04.47,1.04-.18,1-13.05-.75-26.57,5.76-37.54,13.52-.56.39-1.14-.36-.58-.76Z"/>
							<path className={styles.cls11}
							      d="M191.09,142.16c10.09-9.47,21.22-17.34,33.88-22.63.62-.26.97.65.34.91-12.58,5.25-23.62,13.07-33.64,22.47-.5.47-1.08-.29-.58-.76Z"/>
							<path className={styles.cls11}
							      d="M201.17,149.66c7.16-9.67,16.24-17.67,25.68-25.13.54-.43,1.12.33.58.76-9.37,7.4-18.37,15.33-25.47,24.93-.41.55-1.19,0-.78-.56Z"/>
						</g>
						<path className={styles.cls6}
						      d="M231.55,80.57c-.14,0-.28-.03-.42-.06-1.03-.27-1.65-1.33-1.37-2.36,1.13-4.28,4.34-9.32,8.98-10.96,2.85-1.01,5.72-.53,8.3,1.36.86.63,1.04,1.84.41,2.7-.63.86-1.84,1.04-2.7.41-1.55-1.14-3.09-1.41-4.73-.84-2.78.98-5.52,4.47-6.53,8.31-.24.89-1.06,1.47-1.94,1.44Z"/>
						<path className={styles.cls6}
						      d="M187.16,106.85c-.09,0-.18-.01-.26-.03-1.05-.19-1.75-1.19-1.56-2.24,1.41-7.99,5.98-14.28,12.53-17.26,5.28-2.4,11.15-2.19,15.71.56.91.55,1.21,1.74.66,2.65-.55.91-1.74,1.21-2.65.66-3.47-2.09-8-2.23-12.12-.35-5.37,2.44-9.13,7.69-10.32,14.41-.17.96-1.03,1.63-1.97,1.59Z"/>
						<path className={styles.cls10}
						      d="M245.78,114.22c4.42,2.78,7.51,1.6,10.19.06,3.6-2.07,3.95-6.39,3.79-9.65,1.28,1.99,3.91,4.28,5.97,3.96.82,2.14-.65,16.67-10.49,17.54-9.52.84-9.92-10.14-9.46-11.91Z"/>
						<path className={styles.cls11}
						      d="M244.66,111.71c.47.48.89,1.04,1.04,1.19.95.97,2.07,1.85,3.41,2.18,2.63.64,6.02-.4,7.9-2.41,2.24-2.39,2.35-5.08,1.35-8.05-.49-1.47-.87-3.31-1.75-4.6-.2-.29-.59-.59-.72-.9-.17-.4.3-1.43.77-1.5.96-.14,1.41,1.14,1.66,1.84.39,1.09.85,2.02,1.38,3.06.97,1.9,2.1,4.34,4.25,5.14,1.57.58,3.3.07,3.87-1.6.29-.85.12-1.67-.1-2.51-.06-.24-.57-1.83-.04-1.95.89-.22,1.08,1.76,1.18,2.29.42,2.22-.22,5.49-2.99,5.63-1.14.06-2.27-.43-3.22-1.03-.87-.55-2.04-1.11-2.39-2.17.47,2.78-.3,5.22-2.24,7.29-.86.92-1.95,1.65-3.16,2.17-2.84,1.22-5.79,1.1-8.41-.5-.73-.44-4.47-3.31-3.11-4.3.29-.21.82.2,1.3.71Z"/>
						<path className={styles.cls8}
						      d="M255.53,87.92c-1.07.53-2.06,1.34-3.19,2.05-1.81,1.13-4.99,2.13-6.22,3.67-2.94,3.72,2.5,5.73,6.44,5.95,4.46.26,11.93-2.91,10.2-8.67-.59-1.95-2.08-3.17-4.08-3.51-1.22-.21-2.22.04-3.15.5Z"/>
					</g>
					<g>
						<path className={styles.cls9}
						      d="M122.33,86.83c7.81,6.35,15.15,12.17,16.5,13.07,2.83,1.87,2.67,2.12,6.07,2.18,4.48.08,22.62-35.49,19.78-45.65-10.5-3.16-25.66,1.01-36.52,3.44-7.79,1.74-20.28,4.24-26.56,9.18-.42.33,10.55,9.5,20.73,17.78Z"/>
						<path className={styles.cls8} d="M156.33,67.81s-24.89-1.7-43.04,4.63l29.63,22.64,13.41-27.27Z"/>
					</g>
				</g>
				<g id="Stripe">
					<path
						className={styles.cls16}
						d="M145.09,126.06c18.04-33.71,64.1-66.18,95.95-70.86,2.44-.36-11.88-13.6-20.03-13.29-21.04.8-81.51,41.71-85.01,69.86-.52,4.19,7.39,17.45,9.09,14.29Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Winner_Gold01_Upper">
					<path className={styles.cls14}
					      d="M154.88,111.05c.97-1.37,2.05-2.64,3.2-3.8,2.07,6.62,16.19,39.83,19.42,44.41-2.14-.43-5.62-1.39-7.66-2.75-1.42-3.43-13.61-34.6-14.97-37.87Z"/>
					<path className={styles.cls5}
					      d="M128.4,72.39c-5.35,6.08-1.75,17.79,11.55,29.74,7.54,23.63-.42,68.97,25.98,80.03,7.09,2.97,18.76-19.09,13.66-25.79-9.93-13.07-20.2-36.96-26.38-58.75-1.8-19.59-18.85-31.99-24.81-25.22Z"/>
				</g>
			</g>
		</svg>
	);
};