import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Collapse } from "react-collapse";
import classnames from "classnames";
import Button from "./Button";

export default function AccordionSection(props) {
    const {
        id: sectionId,
        theme = {}
    } = props;

    const handleFooterButtonClicked = (e) => {
        if(props.footer.buttonNotClose && props.isOpen) {
            e.stopPropagation();
            e.preventDefault();
        }
        props.footer.onButtonClick();
    }

    return (
        <Fragment key={sectionId}>
            <div
                data-testid="accordion_section_header"
                className={classnames(props.theme?.header, "flex align-center")}
                onClick={() => props.onClick(sectionId)}
            >
                <h3>{props.header || "Section"} </h3>
                {props.headerSubtext}
                <i className={classnames("fas fa-angle-up text-lg text-success margin-right-x-sm margin-left-x-sm", { ["fa-rotate-180"]: props.isOpen })} />
            </div>
            <Collapse
                isOpened={props.isOpen}
                theme={props.theme}
            >
                {props.children}
            </Collapse>
            {props.footer && (
                <div
                    data-testid="accordion_section_footer"
                    className={props.theme?.footer}
                    onClick={() => props.onClick(sectionId)}
                >
                    <Button
                        data-testid="accordion_section_footer_button"
                        type={props.footer.buttonType}
                        text={props.footer.textButton}
                        onClick={handleFooterButtonClicked}
                    >
                        <i className={classnames(props.footer.buttonIcon, "margin-right-x-sm")}/> {props.footer.buttonText}
                    </Button>
                    <div>
                        {props.footer.footerSubtext}
                    </div>
                </div>
            )}
        </Fragment>
    );
}

AccordionSection.propTypes = {
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // String or JSX
    headerSubtext: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // String or JSX
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isOpen: PropTypes.bool,
    theme: PropTypes.object,
    footer: PropTypes.object, // String or JSX
    onClick: PropTypes.func.isRequired,
};
