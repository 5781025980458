export const BOEHRINGER_INGELHEIM = 1;
export const ABBOTT = 2;
export const BAYER = 3;
export const CEVA = 5;
export const ELANCO = 6;
export const HILLS = 7;
export const MERCK = 8;
export const NOVARTIS = 9;
export const NUTRIMAX = 10;
export const PURINA = 11;
export const VIRBAC = 12;
export const ZOETIS = 13;
export const ROYAL_CANIN = 14;
// Wellness Plans
export const PREMIER_PET_CARE_PLAN = 19;
export const GREENLINE_WELLNESS = 69;
export const WELLNESS = PREMIER_PET_CARE_PLAN || GREENLINE_WELLNESS; // 19 or 69;

export const GREENLINE = 16;
export const NATIONAL_VETERINARY_ASSOCIATES = 17;
export const SOUTHERN_VETERINARY_PARTNERS = 18;
export const PET_VET_CARE_CENTERS = 20;
export const VERTICAL_VET = 21;
export const GENERIC = 22;
export const VETCOR = 23;
export const DURVET = 24;
export const HUVEPHARMA = 25;
export const VETALYTIX = 26;
export const NEOTECH = 27;
export const T_CYTE = 28;
export const VETCHECK = 29;
export const VETPACK = 31;
export const KYNETEC = 37;
export const BLUE_RIVER = 38;
export const COMMUNITY_VET_PARTNERS = 39;
export const PENNY_PAWS = 40;
export const INNOVETIVE_PETCARE = 41;
export const VETS_PETS = 42;
export const PETIQ = 44;
export const MISSION_VETERINARY_PARTNERS = 45;
export const PETCHECK = 46;
export const AZ_PET_VET = 47;
export const PETCO = 48;
export const RAREBREED_VETERINARY_PARTNERS = 49;
export const GLN = 50;
export const PRICE_WATCH = 51;
export const VETERINARY_UNITED = 52;

export const PROVIDER_ID_LIST = [
    ABBOTT,
    AZ_PET_VET,
    BAYER,
    BLUE_RIVER,
    BOEHRINGER_INGELHEIM,
    CEVA,
    COMMUNITY_VET_PARTNERS,
    DURVET,
    ELANCO,
    GENERIC,
    GLN,
    GREENLINE,
    HILLS,
    HUVEPHARMA,
    INNOVETIVE_PETCARE,
    KYNETEC,
    MERCK,
    MISSION_VETERINARY_PARTNERS,
    NEOTECH,
    NOVARTIS,
    NATIONAL_VETERINARY_ASSOCIATES,
    NUTRIMAX,
    PENNY_PAWS,
    PET_VET_CARE_CENTERS,
    PETCHECK,
    PETCO,
    PETIQ,
    PREMIER_PET_CARE_PLAN,
    PRICE_WATCH,
    PURINA,
    RAREBREED_VETERINARY_PARTNERS,
    ROYAL_CANIN,
    SOUTHERN_VETERINARY_PARTNERS,
    T_CYTE,
    VERTICAL_VET,
    VETALYTIX,
    VETCHECK,
    VETCOR,
    VETERINARY_UNITED,
    VETPACK,
    VETS_PETS,
    VIRBAC,
    WELLNESS,
    ZOETIS
];