import React, { useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import find from "lodash/find";
import map from "lodash/map";
import merge from "lodash/merge";
import * as ClinicActions from "actions/ClinicActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import DateInput from "components/common/DateInput";
import DropdownSearch from "components/common/DropdownSearch";
import TextBox from "components/common/TextBox";
import logger from "utils/logger";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

const EMPTY_COMMISSION = Object.freeze({
    "clinicDvmId": null,
    "clinicDvmHistoryId": null,
    "pimsUserId": null,
    "firstName": "",
    "lastName": "",
    "startDate": null,
    "endDate": null,
    "isActive": true,
    "lastApprovalDate": null,
    "productsCommissionPercentage": 0,
    "servicesCommissionPercentage": 0,
    "providerDvmIdentifier": "",
    "mappingInitials": "",
    "isDeleted": false
});

function DvmCommissionForm(props) {
    const [formData, setFormData] = useState({});
    const [dvmSearch, setDvmSearch] = useState("");

    const combinedData = cloneDeep(props.creating ? EMPTY_COMMISSION : props.commission);
    merge(combinedData, formData);
    logger.log(combinedData, "DATA")

    const filteredDvms = filter(props.dvmUsers, pimsUser => {
        return pimsUser.firstName.toLowerCase().includes(dvmSearch) || pimsUser.lastName.toLowerCase().includes(dvmSearch);
    });

    function getFullName(dvm) {
        if (!dvm) {
            return "";
        }
        return `${dvm.firstName} ${dvm.lastName} ${dvm.pimsUserId && `[${dvm.pimsUserId}]`}`;
    }

    const dvmDropdownOptions = map(filteredDvms, pimsUser => {
        return { name: getFullName(pimsUser), value: pimsUser.pimsUserId };
    });

    const handleChange = ({name, value}) => {
        const newFormData = {
            ...formData,
            [name]: value,
        }
        setFormData(newFormData);
    }

    const handleSubmit = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(props.canEditClinicWellnessDvms && !props.creating) {
            props.updateClinicDvmCommission(combinedData);
        } else if(props.canCreateClinicWellnessDvms && props.creating) {
            const data = {
                ...combinedData,
                clinicId: props.clinicId
            }
            props.createClinicDvmCommission(data);
        }
        props.onClose();
    }

    const handleDelete = (dvmId) => {
        if(props.canDeleteClinicWellnessDvms) {
            if (confirm(`Deleting this DVM will also remove all Commissions History. Are you sure you want to delete the DVM?`)) {
                props.onDelete(dvmId);
            }
        }
    }

    const handleCancel = () => {
        setFormData({});
        props.onClose();
    }

    const handleFilterSearch = (query) => {
        setDvmSearch(query.toLowerCase());
    };

    const handleChooseDvm = ({ name, value }) => {
        const dvm = find(props.dvmUsers,{pimsUserId: value});
        if (dvm) {
            const newFormData = {
                ...formData,
                firstName: dvm.firstName,
                lastName: dvm.lastName,
                id: value,
                pimsUserId: value,
                providerDvmIdentifier: dvm.providerDvmIdentifier,
            };

            setFormData(newFormData);
        } else {
            logger.warn(`PIMS user not found: ${name}=${value}`);
        }
    };

    const canChange = props.canCreateClinicWellnessDvms || props.canEditClinicWellnessDvms;
    const canSave = (
        (props.canCreateClinicWellnessDvms || props.canEditClinicWellnessDvms) &&
        Object.keys(formData).length &&
        combinedData.pimsUserId &&
        combinedData.productsCommissionPercentage &&
        combinedData.servicesCommissionPercentage &&
        combinedData.startDate
    );

    if(!props.canViewClinicWellnessDvms) {
        return <AccessDenied/>;
    }

    return (
       <form onSubmit={handleSubmit}>
           <div className="flex flex-column border-bottom">
                <div className="margin-bottom-x-sm">
                    {!props.creating ? (
                        //This field is not editable as far as I have seen
                        // If it is editable then we need to use the Dropdown Search below
                        <TextBox
                            label="Provider Clinic Identifier:"
                            name="pimsUserId"
                            value={getFullName(combinedData)}
                            onChange={() => {}}
                            alignedSplit
                            disabled
                        />
                    ) : (
                        <DropdownSearch
                            fullWidth
                            label="Provider Clinic Identifier:"
                            placeholder="- Select PIMS User -"
                            options={dvmDropdownOptions}
                            name="pimsUserId"
                            value={combinedData.pimsUserId}
                            onSearchChange={handleFilterSearch}
                            disabled={!(props.canCreateClinicWellnessDvms && props.creating)}
                            onChange={handleChooseDvm}
                            alignedSplit
                            required
                            isSearch
                        />
                    )}
                </div>
                <TextBox
                    label="Employee ID"
                    name="providerDvmIdentifier"
                    value={combinedData.providerDvmIdentifier}
                    onChange={handleChange}
                    alignedSplit
                    disabled={!canChange}
                />

                <TextBox
                    label="Products Commission:"
                    name="productsCommissionPercentage"
                    unit="%"
                    value={combinedData.productsCommissionPercentage}
                    onChange={handleChange}
                    alignedSplit
                    disabled={!canChange}
                    required
                />
                <TextBox
                    label="Services Commission:"
                    name="servicesCommissionPercentage"
                    unit="%"
                    value={combinedData.servicesCommissionPercentage}
                    onChange={handleChange}
                    alignedSplit
                    disabled={!canChange}
                    required
                />

                <DateInput
                    label="Commission Start Date:"
                    name="startDate"
                    value={combinedData.startDate ? moment(combinedData.startDate).format("MM/DD/YYYY") : null}
                    dateFormat={"MM/DD/YYYY"}
                    onChange={handleChange}
                    disabled={!(props.canCreateClinicWellnessDvms && props.creating)}
                    alignedSplit
                    required
                />
                <TextBox
                    label="Initials:"
                    name="mappingInitials"
                    value={combinedData.mappingInitials}
                    onChange={handleChange}
                    alignedSplit
                    disabled={!canChange}
                />
            </div>
            <div className="flex flex-none text-right">
                {canChange ? (
                    <>
                        <div className="flex-none">
                            {(!props.creating && props.canDeleteClinicWellnessDvms) && (
                                <Button
                                    iconOnly
                                    className="margin-right-x-sm"
                                    type="danger"

                                    onClick={handleDelete}
                                >
                                    <i className="fa fa-trash-alt"/>
                                </Button>
                            )}
                        </div>
                        <div className="flex-1">
                            <Button
                                wide
                                className="margin-right-x-sm"
                                type="primary"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button
                                wide
                                buttonType="submit"
                                disabled={!canSave}
                            >
                                Save
                            </Button>
                        </div>
                    </>
                ) : (
                    <Button
                        wide
                        className="margin-right-x-sm"
                        type="primary"
                        onClick={handleCancel}
                    >
                        Done
                    </Button>
                )}
            </div>
        </form>
    );
}

DvmCommissionForm.propTypes = {
    selectedDvmId: PropTypes.number,
    onClose: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
    creating: PropTypes.bool,
    clinicId: PropTypes.number,
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canEditClinicWellnessDvms = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canCreateClinicWellnessDvms = userHasPermission(PermissionTypes.CREATE, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canDeleteClinicWellnessDvms = userHasPermission(PermissionTypes.DELETE, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        return {
            canViewClinicWellnessDvms,
            canEditClinicWellnessDvms,
            canCreateClinicWellnessDvms,
            canDeleteClinicWellnessDvms,
            commission: state.entities.wellnessCommissions[ownProps.selectedDvmId] || {},
            //TODO: Should all PIMS be options or only certain StaffTypes?
            dvmUsers: state.entities.pimsUsers.users,
        };
    },
    (dispatch) => ({
        createClinicDvmCommission: (data) => dispatch(ClinicActions.addClinicDvmCommission(data)),
        updateClinicDvmCommission: (update) => dispatch(ClinicActions.updateClinicDvmCommission(update)),
    })
)(DvmCommissionForm);
