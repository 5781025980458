import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import classnames from "classnames";
import * as styles from "./TourCompletion.scss";
import * as CouponActions from "actions/CouponActions";
import * as TourActions from "actions/TourActions";
import { getNextTourSection } from "utils/demoTourHelpers";
import {
    TOUR_COMPLETE_TRANSITION,
    TOUR_COUPON_HISTORY_PART_1,
    TOUR_COUPON_HISTORY_PART_2,
    TOUR_COUPON_HISTORY_TRANSITION,
    TOUR_COUPON_LIBRARY_PART_1,
    TOUR_COUPON_LIBRARY_PART_2,
    TOUR_COUPON_LIBRARY_PART_3,
    TOUR_COUPON_LIBRARY_TRANSITION,
    TOUR_COUPON_PROCESSING_TRANSITION,
    TOUR_DASHBOARD,
    TOUR_EDIT_COUPON_MODAL,
    TOUR_GAMES_PART_1,
    TOUR_GAMES_PART_2,
    TOUR_GAMES_PART_3,
    TOUR_GAMES_TRANSITION,
    TOUR_NOTIFICATION_TRANSITION,
    TOUR_NOTIFICATIONS_PART_1,
    TOUR_NOTIFICATIONS_PART_2,
    TOUR_PENDING_COUPONS_PART_1,
    TOUR_PENDING_COUPONS_PART_2,
    TOUR_PENDING_COUPONS_PART_3,
    TOUR_PET_PLAN_TRACKING_PART_1,
    TOUR_PET_PLAN_TRACKING_PART_2,
    TOUR_PET_PLAN_TRACKING_TRANSITION,
    TOUR_PLAN_ENROLLMENT_PART_1,
    TOUR_PLAN_ENROLLMENT_PART_2,
    TOUR_PLAN_ENROLLMENT_TRANSITION,
    TOUR_PROGRAM_OPT_IN_PART_1,
    TOUR_PROGRAM_OPT_IN_PART_2,
    TOUR_PROGRAM_OPT_IN_PART_3,
    TOUR_PROGRAM_OPT_IN_PART_4,
    TOUR_PROGRAM_OPT_IN_TRANSITION,
    TOUR_REPORTS,
    TOUR_REPORTS_TRANSITION,
    TOUR_RESOURCES,
    TOUR_RESOURCES_TRANSITION,
    TOUR_WELLNESS_DASHBOARD,
    TOUR_WELLNESS_AUDIT_PART_1,
    TOUR_WELLNESS_AUDIT_PART_2,
    TOUR_WELLNESS_AUDIT_TRANSITION,
    TOUR_WELLNESS_HISTORY_PART_1,
    TOUR_WELLNESS_HISTORY_PART_2,
    TOUR_WELLNESS_HISTORY_TRANSITION,
} from "utils/demoTourHelpers";
import { GREENLINE_WELLNESS, MERCK } from "constants/ProviderIds";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import * as ClinicData from "utils/ClinicData";

const DASHBOARD = "DASHBOARD";
const NOTIFICATIONS = "NOTIFICATIONS";
const PLAN_ENROLLMENT = "PLAN_ENROLLMENT";
const COUPONS = "COUPONS";
const COUPON_PROCESSING = "COUPON_PROCESSING";
const COUPON_HISTORY = "COUPON_HISTORY";
const COUPON_LIBRARY = "COUPON_LIBRARY";
const WELLNESS_DASHBOARD = "WELLNESS_DASHBOARD";
const WELLNESS = "WELLNESS";
const WELLNESS_AUDIT = "WELLNESS_AUDIT";
const WELLNESS_HISTORY = "WELLNESS_HISTORY";
const PET_PLAN_TRACKING = "PET_PLAN_TRACKING";
const GREENLINE_GAMES = "GREENLINE_GAMES";
const PROGRAM_OPT_IN = "PROGRAM_OPT_IN";
const REPORTS = "REPORTS";
const RESOURCES = "RESOURCES";

function TourCompletion(props) {
    const [nextTourSection, setNextTourSection] = useState(null);

    useEffect(() => {
        if (props.userProfile) {
            const next = getNextTourSection(props.currentTourSection, props.completedTourSections, props.userProfile);
            setNextTourSection(next);
        }
    }, [props.userProfile, props.completedTourSections]);

    const SECTIONS = [{
        id: DASHBOARD,
        name: "Dashboard",
        icon: "fa-tachometer-fast",
        started: TOUR_DASHBOARD,
        complete: TOUR_DASHBOARD,
        configSections: [
            TOUR_DASHBOARD,
            TOUR_NOTIFICATION_TRANSITION
        ]
    }, {
        id: WELLNESS_DASHBOARD,
        name: "Dashboard",
        icon: "fa-tachometer-fast",
        started: TOUR_WELLNESS_DASHBOARD,
        complete: TOUR_WELLNESS_DASHBOARD,
        configSections: [
            TOUR_WELLNESS_DASHBOARD,
            TOUR_PLAN_ENROLLMENT_TRANSITION,
        ]
    }, {
        id: NOTIFICATIONS,
        name: "Notifications",
        icon: "fa-bell",
        started: TOUR_NOTIFICATIONS_PART_1,
        complete: TOUR_NOTIFICATIONS_PART_2,
        configSections: [
            TOUR_NOTIFICATIONS_PART_1,
            TOUR_NOTIFICATIONS_PART_2,
            TOUR_COUPON_PROCESSING_TRANSITION
        ]
    }, {
        id: PLAN_ENROLLMENT,
        name: "Plan Enrollment",
        icon: "fa-user-plus",
        started: TOUR_PLAN_ENROLLMENT_PART_1,
        complete: TOUR_PLAN_ENROLLMENT_PART_2,
        configSections: [
            TOUR_PLAN_ENROLLMENT_PART_1,
            TOUR_PLAN_ENROLLMENT_PART_2,
            TOUR_WELLNESS_AUDIT_TRANSITION
        ]
    }, {
        id: COUPONS,
        name: "Coupons",
        icon: "fa-ticket",
        started: TOUR_PENDING_COUPONS_PART_1,
        complete: TOUR_COUPON_LIBRARY_PART_3,
        configSections: [
            TOUR_PENDING_COUPONS_PART_2,
            TOUR_PENDING_COUPONS_PART_3,
            TOUR_EDIT_COUPON_MODAL,
            TOUR_PENDING_COUPONS_PART_3,
            TOUR_COUPON_HISTORY_TRANSITION,
            TOUR_COUPON_HISTORY_PART_1,
            TOUR_COUPON_HISTORY_PART_2,
            TOUR_COUPON_LIBRARY_TRANSITION,
            TOUR_COUPON_LIBRARY_PART_1,
            TOUR_COUPON_LIBRARY_PART_2,
            TOUR_COUPON_LIBRARY_PART_3,
            TOUR_GAMES_TRANSITION,
            TOUR_PROGRAM_OPT_IN_TRANSITION
        ],
        subSections: [{
            id: COUPON_PROCESSING,
            name: "Processing",
            started: TOUR_PENDING_COUPONS_PART_1,
            complete: TOUR_PENDING_COUPONS_PART_3,
            configSections: [
                TOUR_PENDING_COUPONS_PART_2,
                TOUR_PENDING_COUPONS_PART_3,
                TOUR_EDIT_COUPON_MODAL,
                TOUR_PENDING_COUPONS_PART_3,
                TOUR_COUPON_HISTORY_TRANSITION
            ],
        }, {
            id: COUPON_HISTORY,
            name: "History",
            started: TOUR_COUPON_HISTORY_PART_1,
            complete: TOUR_COUPON_HISTORY_PART_2,
            configSections: [
                TOUR_COUPON_HISTORY_PART_2,
                TOUR_COUPON_LIBRARY_TRANSITION
            ],
        }, {
            id: COUPON_LIBRARY,
            name: "Library",
            started: TOUR_COUPON_LIBRARY_PART_1,
            complete: TOUR_COUPON_LIBRARY_PART_2,
            configSections: [
                TOUR_COUPON_LIBRARY_PART_1,
                TOUR_COUPON_LIBRARY_PART_2,
                TOUR_GAMES_TRANSITION,
                TOUR_PROGRAM_OPT_IN_TRANSITION
            ],
        }]
    }, {
        id: WELLNESS,
        name: "Wellness",
        icon: "fa-paw-simple",
        started: TOUR_WELLNESS_AUDIT_PART_1,
        complete: TOUR_PET_PLAN_TRACKING_PART_2,
        configSections: [
            TOUR_WELLNESS_AUDIT_PART_1,
            TOUR_WELLNESS_AUDIT_PART_2,
            TOUR_WELLNESS_HISTORY_TRANSITION,
            TOUR_WELLNESS_HISTORY_PART_1,
            TOUR_WELLNESS_HISTORY_PART_2,
            TOUR_PET_PLAN_TRACKING_TRANSITION,
            TOUR_PET_PLAN_TRACKING_PART_1,
            TOUR_PET_PLAN_TRACKING_PART_2,
            TOUR_REPORTS_TRANSITION,
        ],
        subSections: [{
            id: WELLNESS_AUDIT,
            name: "Wellness Audit",
            started: TOUR_WELLNESS_AUDIT_PART_1,
            complete: TOUR_WELLNESS_AUDIT_PART_2,
            configSections: [
                TOUR_WELLNESS_AUDIT_PART_1,
                TOUR_WELLNESS_AUDIT_PART_2,
                TOUR_WELLNESS_HISTORY_TRANSITION,
            ],
        }, {
            id: WELLNESS_HISTORY,
                name: "Wellness History",
            started: TOUR_WELLNESS_HISTORY_PART_1,
            complete: TOUR_WELLNESS_HISTORY_PART_2,
            configSections: [
                TOUR_WELLNESS_HISTORY_PART_1,
                TOUR_WELLNESS_HISTORY_PART_2,
                TOUR_PET_PLAN_TRACKING_TRANSITION
            ]
        }, {
            id: PET_PLAN_TRACKING,
            name: "Pet Plan Tracking",
            started: TOUR_PET_PLAN_TRACKING_PART_1,
            complete: TOUR_PET_PLAN_TRACKING_PART_2,
            configSections: [
                TOUR_PET_PLAN_TRACKING_PART_1,
                TOUR_PET_PLAN_TRACKING_PART_2,
                TOUR_REPORTS_TRANSITION,
            ]
        }],
    }, {
        id: GREENLINE_GAMES,
        name: "Games",
        icon: "fa-trophy-alt",
        started: TOUR_GAMES_PART_1,
        complete: TOUR_GAMES_PART_3,
        configSections: [
            TOUR_GAMES_PART_2,
            TOUR_GAMES_PART_3,
            TOUR_RESOURCES_TRANSITION
        ]
    }, {
        id: PROGRAM_OPT_IN,
        name: "Program Opt-In",
        icon: "fa-envelope",
        started: TOUR_PROGRAM_OPT_IN_PART_1,
        complete: TOUR_PROGRAM_OPT_IN_PART_4,
        configSections: [
            TOUR_PROGRAM_OPT_IN_PART_1,
            TOUR_PROGRAM_OPT_IN_PART_2,
            TOUR_PROGRAM_OPT_IN_PART_3,
            TOUR_PROGRAM_OPT_IN_PART_4,
            TOUR_RESOURCES_TRANSITION
        ]
    }, {
        id: REPORTS,
        name: "Reports",
        icon: "fa-chart-line",
        started: TOUR_REPORTS,
        complete: TOUR_REPORTS,
        configSections: [
            TOUR_REPORTS,
            TOUR_RESOURCES_TRANSITION
        ]
    }, {
        id: RESOURCES,
        name: "Resources",
        icon: "fa-books",
        started: TOUR_RESOURCES,
        complete: TOUR_RESOURCES,
        configSections: [
            TOUR_RESOURCES,
            TOUR_COMPLETE_TRANSITION,
        ]
    }];

    // Filter the sections based on permissions and such
    const filteredSections = filter(SECTIONS, (section) => {
        switch (section.id) {
            case "DASHBOARD":
                if (props.userProfile.demoProvider !== GREENLINE_WELLNESS) return true;
                break;
            case "WELLNESS_DASHBOARD":
            case "WELLNESS":
                if (props.userProfile.demoProvider === GREENLINE_WELLNESS) return true;
                break;
            case "NOTIFICATIONS":
                if (props.canViewNotifications) return true;
                break;
            case "PLAN_ENROLLMENT":
                if (props.canViewClinicWellnessPlans) return true;
                break;
            case "GREENLINE_GAMES":
                if (props.canViewClinicBiGamesDashboard) return true;
                break;
            case "COUPONS":
                if (props.hasCoupons && (props.canViewCouponAudit || props.canViewCouponLibrary || props.canViewInstantRebateInvoiceFailures)) return true;
                break;
            case "PROGRAM_OPT_IN":
                if (props.userProfile.demoProvider === MERCK && props.hasCoupons && (props.canViewCouponAudit || props.canViewCouponLibrary || props.canViewInstantRebateInvoiceFailures)) return true;
                break;

            case "REPORTS":
                if (props.userProfile.demoProvider === GREENLINE_WELLNESS) return true;
                break;
            case "RESOURCES":
            default:
                return true
        }
        return false;
    });

    const handleRestartSection = (e, section) => {
        e.stopPropagation();
        e.preventDefault();
        if (getSectionComplete(section.complete)) {
            // Reset all related tour section.
            props.restartSections(section.configSections);

            // Switch to the correct page to jump back into the tour
            let goTo;
            switch (section.id) {
                case "WELLNESS_DASHBOARD":
                case "WELLNESS":
                case "PLAN_ENROLLMENT":
                    goTo = "/wellness";
                    break
                case "WELLNESS_AUDIT":
                    goTo = "/wellness/audit";
                    break
                case "WELLNESS_HISTORY":
                    goTo = "/wellness/history";
                    break
                case "PET_PLAN_TRACKING":
                    goTo = "/wellness/patients";
                    break
                case "COUPONS":
                case "COUPON_PROCESSING":
                    props.clearCouponSummary();
                    goTo = "/coupons";
                    break;
                case "COUPON_HISTORY":
                    goTo = "/coupons/history";
                    break;
                case "COUPON_LIBRARY":
                    goTo = "/coupons/library";
                    break;
                case "REPORTS":
                    goTo = "/reports";
                    break;
                case "RESOURCES":
                    goTo = "/resources";
                    break;
                default:
                    goTo = "/";
            }

            props.history.push(goTo);
        }
    }

    const getSectionComplete = (section) => {
        return !!includes(props.completedTourSections, section);
    }

    const getSectionCompleteIndicator = (section) => {
        const sectionComplete = getSectionComplete(section.complete);
        const isNextSection = (section.started === nextTourSection) || !!filter(section.subSections, {started: nextTourSection})?.length;

        return (
            <li key={section.id} onClick={(e) => handleRestartSection(e, section)}>
                <div>
                    <div className={classnames("flex spaced-content", {
                        "text-success pointer": sectionComplete,
                        "text-dark-green text-bold": isNextSection
                    })}>
                        <div>
                            <i className={classnames("far", {
                                "fa-circle": !sectionComplete,
                                "fa-check pointer": sectionComplete,
                            })}/>
                        </div>
                        <div>
                            <i className={classnames(section.icon, "far margin-right-xx-sm")}/>
                            {section.name}
                        </div>
                    </div>
                    <div className="margin-sm text-sm">
                        <ul>
                            {map(section.subSections, (subSec, i) => {
                                const subSecComplete = getSectionComplete(subSec.complete);
                                const isNextSubSection = (subSec.started === nextTourSection);
                                return (
                                    <li key={i}
                                        className={classnames({
                                            "text-success pointer": subSecComplete,
                                            "text-dark-green text-bold": isNextSubSection
                                        })}
                                        onClick={(e) => handleRestartSection(e, subSec, i)}
                                    >
                                        <div className="flex spaced-content">
                                            <div>
                                                {subSecComplete ? (
                                                    <i className="far fa-check pointer"/>
                                                ) : (
                                                    <i className="far fa-circle"/>

                                                )}
                                            </div>
                                            <div>{subSec.name}</div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                </div>
            </li>
        );
    }

    return (
        <div className={styles.root}>
            <ol>
                {map(filteredSections, (section) => {
                    return getSectionCompleteIndicator(section);
                })}
            </ol>
        </div>
    );
}

TourCompletion.propTypes = {
    currentTourSection: PropTypes.string.isRequired,
}

const connector = connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const clinic = ClinicData.getClinicInfo(state);
        const clinicId = clinic ? clinic.clinicId : undefined;
        // Permissions
        const canViewClinicBiGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canViewCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canViewInstantRebateInvoiceFailures = userHasPermission(PermissionTypes.VIEW, UserPermissions.INSTANT_REBATE_INVOICE_FAILURES, userProfile);
        const canViewNotifications = userHasPermission(PermissionTypes.VIEW, UserPermissions.NOTIFICATIONS, userProfile);
        return {
            userProfile,
            hasCoupons: ClinicData.clinicHasCoupons(state, clinicId),
            completedTourSections: state.demo.completedTourSections,
            // Permissions
            canViewClinicBiGamesDashboard,
            canViewClinicWellnessPlans,
            canViewCouponLibrary,
            canViewCouponAudit,
            canViewInstantRebateInvoiceFailures,
            canViewNotifications,
        }
    },
    (dispatch) => ({
        restartSections: (sections) => dispatch(TourActions.restartSections(sections)),
        clearCouponSummary: () => dispatch(CouponActions.clearCouponSummary())
    })
);

export default compose(
    withRouter,
    connector
)(TourCompletion);
