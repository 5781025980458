import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {produce} from "immer";
import { orderBy } from "lodash";
import styles from "./ClinicPIMSUserMapping.scss";
import AdminBase from "components/layout/AdminBase";
import Dropdown from "components/common/Dropdown";
import SortableDataTable from "components/common/SortableDataTable";
import * as ClinicActions from "actions/ClinicActions";
import * as SearchTypes from "constants/SearchTypes";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import {PIMS_USERS} from "constants/AdminControls";


function ClinicPIMSUserMapping(props) {
    const [userData, setUserData] = useState([]);

    useEffect(() => {
        if(props.canViewClinicManagement) {
            props.loadClinicPIMSUsers(props.clinicId);
        }
    }, [props.clinicId, props.canViewClinicManagement]);

    useEffect(() => {
        setUserData(props.users);
    }, [props.users]);


    const COLUMNS = [
        {
            name: "Last Name",
            selector: "lastName",
            key: "lastName",
            searchable: true,
            sortable: true,
        },
        {
            name: "First Name",
            selector: "firstName",
            key: "firstName",
            searchable: true,
            sortable: true,
        },
        {
            name: "Staff Type",
            selector: "staffTypeId",
            key: "staffTypeId",
            sortable: true,
            format: (row) => renderStaffTypeDropdown(row),
        },
    ];


    const renderStaffTypeDropdown = (row) => {
        const updateIcon =  row.updated && props.updating === row.id ? "fa-spinner-third fa-spin" : "fa-check-circle";
        return (
            <div className={styles.staffDropown}>
                <Dropdown
                    disabled={!props.canEditClinicManagement}
                    name={`userStaffType_${row.id}`}
                    onChange={(data) => handleChangeUser(row.id, data)}
                    placeholder={""}
                    options={props.staffTypes}
                    value={row.staffTypeId}
                    showClear
                    fullWidth
                />
                <span className={styles.update}>{row.updated && <i className={`text-dark-green fas ${updateIcon}`} />}</span>
            </div>
        );
    }

    const handleChangeUser = (rowId, {value}) => {
        if (props.canEditClinicManagement) {
            const update = produce(userData, (userUpdates) => {
                userUpdates[rowId] = {
                    ...userUpdates[rowId],
                    staffTypeId: value,
                    updated: true,
                }
                return userUpdates
            });

            setUserData(update);
            props.updateClinicPIMSUser({clinicId: props.clinicId, staffTypeId: value, pimsUserId: rowId});
        }
    }

    return (
        <AdminBase
            pageTitle="Manage PIMS Users"
            searchConfig={SearchTypes.PIMS_USER_SEARCH}
            clinicId={props.clinicId}
            controls={PIMS_USERS}
            collapsible
        >
            {props.canViewClinicManagement ? (
                <div className={styles.root}>
                    <SortableDataTable
                        green
                        striped
                        columns={COLUMNS}
                        rawData={orderBy(userData, "lastName")}
                        allowSearch
                        search={props.searchTerms}
                        hideSearchBar
                    />
                </div>
            ) : (
                <AccessDenied/>
            )}
        </AdminBase>
    )
}

export default connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        const { users, staffTypes, updating } = state.entities.pimsUsers;
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canEditClinicManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            clinicId,
            users,
            staffTypes,
            updating,
            canViewClinicManagement,
            canEditClinicManagement,
            searchTerms: state.entities.genericSearch,
        }
    },
    (dispatch) => ({
        loadClinicPIMSUsers: (clinicId) => dispatch(ClinicActions.loadClinicPIMSUsers(clinicId)),
        updateClinicPIMSUser: (data) => dispatch(ClinicActions.updateClinicPIMSUser(data)),
    })
)(ClinicPIMSUserMapping);
