import React  from "react";
import { connect } from "react-redux";
import SortableDataTable from "components/common/SortableDataTable";
import PropTypes from "prop-types";
import moment from "moment";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";

function CouponChangeHistoryTable(props) {
    const COLUMNS = [
        {
            name: "Manufacturer",
            selector: "providerId",
            key: "providerId",
            searchable: true,
            sortable: true,
            format: row => (
                <div>
                    {(props.providers && props.providers[row.providerId]) ? props.providers[row.providerId].name : row.providerId}
                </div>
            ),
        },
        {
            name: "Coupon Name",
            selector: "couponName",
            key: "couponName",
            searchable: true,
            sortable: true,
            format: row => (
                <div dangerouslySetInnerHTML={{ __html: row.couponName }} />
            ),
        },
        {
            name: "Change Date and Time",
            selector: "changeDate",
            key: "changeDate",
            sortable: true,
            format: row => (
                <div className="no-wrap">
                    {!!row.changeDate ? moment(row.changeDate).format('MM/DD/YYYY, LTS') : ""}
                </div>
            ),
        },
        {
            name: "Username",
            selector: "userName",
            key: "userName",
            sortable: true,
            format: row => (
                <div className="no-wrap">
                    {row.userName}
                </div>
            ),
        },
        {
            name: "Off/On",
            selector: "isOptedIn",
            key: "isOptedIn",
            sortable: true,
            format: row => row.isOptedIn ? "On" : "Off"
        },
    ];

    if(!props.canViewCouponLibraryHistory) {
        return <AccessDenied/>;
    }

    return (
        <div className="full-width">
            <SortableDataTable
                columns={COLUMNS}
                rawData={props.offerHistory}
                green
                striped
            />
        </div>
    );
}

CouponChangeHistoryTable.propTypes = {
    clinicId: PropTypes.number.isRequired,
    providerId: PropTypes.number.isRequired,
}

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canViewCouponLibraryHistory = userHasPermission(PermissionTypes.HISTORY, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const clinicCouponOfferHistories = state.entities.couponOfferHistory[ownProps.clinicId] ? state.entities.couponOfferHistory[ownProps.clinicId] : {};
        return {
            canViewCouponLibraryHistory,
            offerHistory: (clinicCouponOfferHistories && clinicCouponOfferHistories[ownProps.providerId]) ? clinicCouponOfferHistories[ownProps.providerId] : null,
            providers: state.entities.providers,
        }
    }
)(CouponChangeHistoryTable);
