import React, { useState } from "react";
import styles from "./ExpandToggleButton.scss";

export default function ExpandToggleButton(props) {
    const [isExpanded, setIsExpanded] = useState(props.isExpanded || false);

    const handleClick = () => {
        const isExpandedNow = !isExpanded;
        setIsExpanded(isExpandedNow);
        if (props.onClick) {
            props.onClick(isExpandedNow);
        }
    };

    const toolTip = isExpanded ? "Show Map" : "Show All Columns";

    return (
        <div
            className={styles.root}
            title={toolTip}
            onClick={handleClick}
        >
            <span className={styles.icon}>
                {isExpanded && <i className="fas fa-chevron-circle-left" />}
                {!isExpanded && <i className="fas fa-chevron-circle-right" />}
            </span>
        </div>
    )
}
