import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import * as styles from "components/admin/widgets/MappingTable.scss";
import map from "lodash/map";
import includes from "lodash/includes";
import reject from "lodash/reject";
import Button from "components/common/Button";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import PriceHistoryPopup from "components/admin/widgets/PriceHistoryPopup";
import Modal from "components/common/Modal";
import * as MappingActions from "actions/MappingActions";
import MappingPimsDropdownSearch from "components/admin/elements/MappingPimsDropdownSearch";

function ServiceTableRow(props) {
    const [deleting, setDeleting] = useState(false);
    const [showPricingHistory, setShowPricingHistory] = useState(false);

    const rowData = useMemo(() => {
        return {
            clinicId: props.clinicId,
            ...props.service,
            ...props.mapping,
        };
    }, [props.clinicId, props.mappingId])

    const [formData, setFormData] = useState({});

    const combinedData = {
        ...rowData,
        ...formData,
    }
    const saved = combinedData.clinicProductId ? !!rowData.mappingId : true;

    const handleChange = ({name, value}) => {
        if(props.canEditExamServiceMapping) {
            const newFormData = {
                ...formData,
                [name]: value,
            }
            const newCombinedData = {
                ...combinedData,
                ...newFormData,
            }
            setFormData(newFormData);
            if (props.mappingId) {
                if(props.canEditExamServiceMapping) {
                    const allData = {
                        ...combinedData,
                        ...newCombinedData
                    }
                    props.updateExamServiceMapping(allData, props.clinicId, combinedData.order);
                }
            } else if (newFormData.clinicProductId) {
                if(!!props.index) {
                    props.handleOnCreate(props.index, newFormData.clinicProductId);
                }
                // CREATE
                if(props.canEditExamServiceMapping) {
                    props.createExamServiceMapping(newCombinedData, props.clinicId, (props.highestServiceIndex + 1));
                }
            }
        }
    }

    //TODO: Make this work
    const handleTagClicked = (tag) => {
        let newTags = formData.selectedTags || [];
        if (includes(combinedData.selectedTags, tag)) {
            newTags = reject(newTags, t => {return t === tag});
        } else {
            newTags.push(tag);
        }
        handleChange({name:"selectedTags", value: newTags})
    };

    const handleOnDelete = () => {
        //Show the deleting spinner icon
        setDeleting(true);
        if(props.mappingId) {
            //Delete any related "blank mappings" that may be lingering from a create.
            props.handleOnDelete(props.mappingId);
        }
    }

    return (
        <tr className={classnames(styles.root,"spaced-content", {
                [styles.firstRow]: props.showProduct,
                [styles.deleting]: deleting
            })}
        >
            <td>
                {props.showProduct && (
                    <div className="nowrap">
                        {combinedData.category}
                    </div>
                )}
            </td>
            <td>
                {props.showProduct && (
                    <div className="no-wrap">
                        {combinedData.serviceName}
                    </div>
                )}
            </td>
            <td>
                <div className="flex-1 text-sm flex flex-wrap spaced-content">
                    {map(combinedData.availableTags, (tag, index) => (
                        <Button
                            className={styles.tags}
                            disabled={!props.canEditExamServiceMapping || deleting}
                            key={index}
                            type={!includes(combinedData.selectedTags, tag) ? "gray" : "primary"}
                            onClick={() => handleTagClicked(tag)}
                            small
                        >
                            {tag}
                        </Button>
                    ))}
                </div>
            </td>
            <td>
                {!!combinedData.clinicProductId && (
                    <Button
                        text
                        small
                        type="primary"
                        onClick={() => setShowPricingHistory(true)}
                        className="nowrap"
                        disabled={deleting}
                    >
                        Price History
                    </Button>
                )}
            </td>
            <td>
                <div className={styles.myProduct}>
                    <MappingPimsDropdownSearch
                        typeId={props.typeId}
                        mappingId={props.mappingId}
                        clinicProductId={combinedData.clinicProductId}
                        clinicId={props.clinicId}
                        productId={props.examServiceId}
                        handleChange={handleChange}
                        handleDeleting={handleOnDelete}
                        pimsOptions={props.pimsOptions}
                        disabledPimsOptions={props.disabledPimsOptions}
                        boldPimsOptions={props.boldPimsOptions}
                        disabled={!props.canEditExamServiceMapping || deleting}
                    />
                </div>
            </td>
            <td>
                {(saved && !deleting) ? (
                    <Button
                        iconOnly
                        text
                        type="success"
                        onClick={props.handleAddBlankMapping}
                        disabled={!props.canEditExamServiceMapping}
                    >
                        <i className="fa fa-2x fa-plus-square"/>
                    </Button>
                ) : (
                    <div className="text-center">
                        <i className="text-success fa fa-spinner-third fa-spin"/>
                    </div>
                )}
                <Modal
                    mediumSmall
                    show={showPricingHistory}
                    onClose={() => setShowPricingHistory(false)}
                    modalTitle={`#${props.mapping.clinicProductId} - 6 Month Price History`}
                >
                    <PriceHistoryPopup
                        clinicId={props.clinicId}
                        clinicProductId={props.mapping.clinicProductId}
                    />
                </Modal>
            </td>
        </tr>
    )
}

ServiceTableRow.propTypes = {
    clinicId: PropTypes.number.isRequired,
    examServiceId: PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
    pimsOptions: PropTypes.array.isRequired,
    disabledPimsOptions: PropTypes.array.isRequired,
    boldPimsOptions: PropTypes.array.isRequired,
    handleOnCreate: PropTypes.func,
    handleOnDelete: PropTypes.func,
    mappingId: PropTypes.number,
    index: PropTypes.number,
    showProduct: PropTypes.bool,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canEditExamServiceMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.EXAM_SERVICE_MAPPING, userProfile);
        return {
            canEditExamServiceMapping,
            providers: state.entities.providers,
            service: state.entities.services[ownProps.examServiceId],
            mapping: state.entities.serviceMappings[ownProps.clinicId]?.[ownProps.examServiceId]?.mappings?.[ownProps.mappingId] || {},
            highestServiceIndex: state.entities.mappingCounts?.[ownProps.clinicId]?.highestServiceIndex || 0,
        }
    }, (dispatch) => ({
        createExamServiceMapping: (data, clinicId, order) => dispatch(MappingActions.createExamServiceMapping(data, clinicId, order)),
        updateExamServiceMapping: (data, clinicId, order) => dispatch(MappingActions.updateExamServiceMapping(data, clinicId, order)),
    }),
)(ServiceTableRow);
