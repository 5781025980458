import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import styles from "./PetPlanTrackingModal.scss";
import classnames from "classnames";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import moment from "moment";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import InvoiceDetails from "components/common/InvoiceDetails";
import Modal from "components/common/Modal";
import OpenInvoicePDFLinkNewTab from "components/common/OpenInvoicePDFLinkNewTab";
import * as UserPermissions from "constants/UserPermissions";
import * as WellnessActions from "actions/WellnessActions";
import * as ServiceType from "constants/WellnessPlanServiceTypeIds";
import getPlanStatus from "utils/getPlanStatus";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";

function PetPlanTrackingModal(props) {
    const [invoiceId, setInvoiceId] = useState(null);
    const [showInvoiceDetails, setShowInvoiceDetails] = useState(false);

    const fetchData = () => {
        if (props.canViewClinicWellnessPetPlans) {
            if (!props.wellness.plans) {
                props.getWellnessPlans(props.clinicId);
                return;
            }
            const selectedId = props.selectedId;
            if (!props.wellness.plans[selectedId].details) {
                props.getWellnessPlanDetails(selectedId);
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, [props.clinicId, props.selectedId, props.wellness.plans]);

    const renderPlanInfo = (plan) => {
        return (
            <>
                <div className={classnames( styles.headerInfo, "flex-1 no-wrap")}>
                    <span className={styles.label}>
                        Patient:
                    </span>
                    <span className={styles.data}>
                        {plan.patient}
                    </span>
                    <span className={styles.patientId}>
                        ({plan.id})
                    </span>
                </div>
                <div className="flex flex-1">
                    <div className={classnames( styles.headerInfo, "flex-1 no-wrap")}>
                        <span className={styles.label}>
                            Owner:
                        </span>
                        <span className={styles.data}>
                         {plan.name}
                        </span>
                    </div>
                    <div className={classnames( styles.headerInfo, "flex-1 no-wrap")}>
                        <span className={styles.label}>
                            Plan:
                        </span>
                        <span className={styles.data}>
                            {plan.plan}
                        </span>
                    </div>
                </div>
            </>
        )
    }

    const renderPlanUsage = (details) => {
        if (!details || !details.services) {
            return null;
        }

        const services = groupBy(details.services, "serviceType");

        const row  = (list) => {
            return map(list, (data) => (
                <tr key={`usage_${data.serviceName}`}>
                    <td>
                        {data.serviceName}
                    </td>
                    <td className="text-center">
                        {data.isUnlimited ? (<i className="far fa-infinity"/>) : data.usesRemaining === 0 ? "None" : data.usesRemaining}
                    </td>
                    <td className="text-center">
                        {data.lastInvoiceDate ? moment(data.lastInvoiceDate).format("MM/DD/YYYY") : ""}
                    </td>
                    <td className="text-center">
                        <OpenInvoicePDFLinkNewTab
                            className="text-primary"
                            invoiceNumber={data.invoiceNumber}
                            invoiceId={data.lastInvoiceId}
                            extendedInvoiceNumber={data.lastExtendedInvoiceNumber}
                        />
                    </td>
                    <td>
                        {data.lastPurchasedProductName}
                    </td>
                    <td>
                        {!!data.nextDispenseDate && moment(data.nextDispenseDate).format("MM/DD/YYYY")}
                    </td>
                </tr>
            ));
        }

        const getServiceIcon = (type) => {
            switch (type) {
                case ServiceType.SERVICES:
                    return <i className="fas fa-user-md"/>;
                case ServiceType.PRODUCTS:
                    return <i className="fas fa-box-open"/>;
                case ServiceType.ADD_ONS:
                    return <i className="fas fa-cart-plus"/>;
                case ServiceType.SPECIAL:
                    return <i className="fas fa-sparkles"/>;
                default:
                    return <i/>;

            }
        };

        const customSort = [ServiceType.SERVICES, ServiceType.PRODUCTS, ServiceType.ADD_ONS, ServiceType.SPECIAL]

        return (
            <div>
                <table>
                    {map(customSort, (type) => (
                        <Fragment key={type}>
                            <thead>
                                <tr>
                                    <th className={styles.firstTH}>
                                        {getServiceIcon(type)}
                                        {type}
                                    </th>
                                    <th className="text-center">Uses Remaining</th>
                                    <th className="text-center">Last Invoice Date</th>
                                    <th className="text-center">Last Invoice</th>
                                    <th>Last Item Purchased</th>
                                    <th>Next Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {row(services[type])}
                            </tbody>
                        </Fragment>
                    ))}
                </table>
            </div>
        )
    }

    const renderPlanDetails = () => {
        const selectedId = props.selectedId;

        if (!selectedId || !props.wellness.plans) {
            return <>Loading Details...</>
        }

        const plan = props.wellness.plans[selectedId];

        if (!plan) {
            return <>Loading Details...</>
        }

        return (
            <>
                <div className={classnames(styles.modalHeader)}>
                    <div className="flex">
                        <i className="fa fa-notes-medical fa-2x" />
                        <div className="text-xlg">Wellness Plan Details</div>
                    </div>
                    <div className="flex flex-wrap">
                        {renderPlanInfo(plan)}
                    </div>
                </div>
                <div className={classnames(styles.planStatus, {
                    [styles.grey]: plan.status === 0,
                    [styles.active]: plan.status === 1,
                    [styles.danger]: plan.status === 2,
                })}>
                    <div className={styles.status}>
                        <span className="text-uppercase">Status:</span> {getPlanStatus(plan.status)}
                    </div>
                    <div>
                        <span>Last Payment</span> {moment(plan.lastPayment).format("MM/DD/YYYY")}
                    </div>
                </div>
                <div className={styles.usage}>
                    {renderPlanUsage(plan.details)}
                </div>
            </>
        );
    }

    const handleClose = () => {
        if (props.onClose) {
            props.onClose();
        }
    }

    const handleCloseDetails = () => {
        setInvoiceId(null);
        setShowInvoiceDetails(false);
    }

    return (
        <div>
            <Modal
                show={!showInvoiceDetails}
                onClose={handleClose}
            >
                <div className={styles.plan}>
                    {!props.canViewClinicWellnessPetPlans ? (
                        <div>
                            <div className="text-xlg">Wellness Plan Details</div>
                            <AccessDenied/>
                        </div>
                    ) : (
                        renderPlanDetails()
                    )}
                </div>
                <div className="flex">
                    <div className="flex-1" />
                    <Button
                        className="flex-none"
                        type="gray"
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
            <Modal
                show={showInvoiceDetails}
                onClose={handleCloseDetails}
                modalTitle="Invoice"
                icon="fa-file-invoice-dollar"
                mediumSmall
            >
                <InvoiceDetails
                    invoiceId={invoiceId}
                    handleClose={handleCloseDetails}
                />
            </Modal>
        </div>
    );
}

PetPlanTrackingModal.propTypes = {}

export default connect(
    (state, ) => {
        const userProfile = state.user.userProfile;
        const canViewClinicWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        return {
            canViewClinicWellnessPetPlans,
            wellness: state.entities.wellness,
            selectedId: state.entities.wellness.selectedId,
        }
    },
    (dispatch) => ({
        getWellnessPlans: (clinicId) => dispatch(WellnessActions.getWellnessPlans(clinicId)),
        getWellnessPlanDetails: (id) => dispatch(WellnessActions.getWellnessPlanDetails(id)),
    }),
)(PetPlanTrackingModal);
