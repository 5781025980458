import React from "react";
import {Link} from "react-router-dom";
import classnames from "classnames";
import PropTypes from "prop-types";
import styles from "./PendingCouponsModal.scss";
import Modal from "components/common/Modal";
import * as ModalTypes from "constants/ModalTypes"
import UnprocessedCoupons from "./UnprocessedCoupons";

export default class PendingCouponsModal extends React.Component {

    render() {
        return (
            <Modal
                show={this.props.show}
                onClose={this.props.onClose}
                modalType={ModalTypes.FULLSCREEN}
            >
                <div className={styles.root}>
                    <UnprocessedCoupons isModal clinicId={this.props.clinicId}/>
                </div>
            </Modal>
        );
    }
}

PendingCouponsModal.propTypes = {
    clinicId: PropTypes.number,
}
