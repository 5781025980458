import {combineReducers} from "redux";
import * as ActionTypes from "constants/ActionTypes";
import * as DemoTourStates from "constants/DemoTourStates";
import {cloneDeep, forEach, pullAll, uniq} from "lodash";
import produce from "immer";

function demoTourState(state=DemoTourStates.NOT_STARTED, action) {
    switch (action.type) {
        case ActionTypes.TOUR_RE_STARTED:
            return DemoTourStates.NOT_STARTED;
        case ActionTypes.TOUR_STARTED:
            return DemoTourStates.STARTED;
        case ActionTypes.TOUR_COMPLETE:
            return DemoTourStates.COMPLETE;
        case ActionTypes.TOUR_CLOSED:
            return DemoTourStates.CLOSED;
        default:
            return state;
    }
}

// Have to start with TOUR_DASHBOARD and TOUR_NOTIFICATIONS_PART_1 included so they will be skipped unless needed
function completedTourSections(state=[], action) {
    switch (action.type) {
        case ActionTypes.TOUR_SECTION_COMPLETED:
            const allSections = cloneDeep(state);
            allSections.push(action.tourSection);
            return allSections;
        case ActionTypes.TOUR_SECTIONS_RE_STARTED:
            const sections = cloneDeep(state);
            pullAll(sections, action.sections);
            return sections;
        case ActionTypes.TOUR_RE_STARTED:
            return [];
        default:
            return state;
    }
}

function completedTourSteps(state={}, action) {
    switch(action.type) {
        case ActionTypes.TOUR_STEP_COMPLETE:
            const completedSectionSteps = state[action.section] || [];
            completedSectionSteps.push(action.step);
            return {
                ...state,
                [action.section]: uniq(completedSectionSteps)
            }
        case ActionTypes.TOUR_SECTIONS_RE_STARTED:
            return produce(state, (newState) => {
                forEach(action.sections, section => {
                    delete newState[section];
                })
                return newState;
            });
        case ActionTypes.TOUR_RE_STARTED:
            return {};
        default:
            return state;
    }
}

export default combineReducers({
    demoTourState,
    completedTourSections,
    completedTourSteps
});