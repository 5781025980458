import React from "react";
import {connect} from "react-redux";
import includes from "lodash/includes";
import ProviderBase from "components/layout/ProviderBase";
import {getNodeId} from "utils/AdminData";
import getProviderId from "utils/getProviderId";
import filter from "lodash/filter";
import flatMap from "lodash/flatMap";
import CorporateGroupReports from 'components/reports/pages/CorporateGroupReports';
import ManufacturerReports from 'components/reports/pages/ManufacturerReports';

function ProviderReports(props) {
    const renderReportsPage = () => {
        if (props.isCorporateGroup) {
            return <CorporateGroupReports path={props.location.pathname} providerId={props.providerId} nodeId={props.nodeId} />
        } else {
            return <ManufacturerReports path={props.location.pathname} providerId={props.providerId} nodeId={props.nodeId} />
        }
    }

    return (
        <ProviderBase
            match={props.match}
            location={props.location}
            pageTitle={"Reports"}
            providerId={props.providerId}
            nodeId={props.nodeId}
            collapsible={props.canViewNotifications}
        >
            {renderReportsPage()}
        </ProviderBase>
    );
}
export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const providerId = getProviderId(params, userProfile);
        const nodeId = getNodeId(state, ownProps);
        const node = state.entities.nodes?.[nodeId];
        const corporateGroupProviderIds = flatMap(filter(state.entities.providers, {isCorporateGroup: true}), "id");
        const isCorporateGroup = !!includes(corporateGroupProviderIds, node?.providerId);

        return {
            providerId,
            nodeId,
            isCorporateGroup,
        };
    }
)(ProviderReports);
