import moment from "moment";
import map from "lodash/map";
import { getClinicInfo } from "api/ClinicApi";
import { isDemoApi } from "api/getApiRoot";
import * as ProviderApi from "api/ProviderApi";
import * as UserApi from "api/UserApi";
import * as VetCheckApi from "api/VetCheckApi";
import LocalData from "utils/LocalData";
import logger from "utils/logger";
import {getUser, popupToLogin, redirectToLogin} from "utils/login";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as ActionTypes from "constants/ActionTypes";
import * as UserPermissions from "constants/UserPermissions";
import {
    mapSingleClinicFromServerToApp,
    mapUserProfileFromServerToApp
} from "data/serverMapping";
import * as Sentry from "@sentry/react";
import * as ApplicationStateActions from "./ApplicationStateActions";
import {getDefaultErrorHandler} from "./base";

export function checkForToken() {
    return (dispatch) => {
        getUser()
            .then((user) => {
                if (user) {
                    logger.log("User Token Expires At: ", moment.unix(user.expires_at).toDate())
                    const timedOut = moment.unix(user.expires_at).isBefore(moment());
                    const demoUser = isDemoApi() ? user.profile : null;

                    if (timedOut) {
                        LocalData.clearToken();
                        dispatch(loginUserRedirect());
                    } else {
                        LocalData.setToken(user.access_token);
                        dispatch({
                            type: ActionTypes.USER_LOGGED_IN,
                            token: user.access_token,
                            userEmail: user.profile.email,
                        });
                        if(demoUser) {
                            dispatch({
                                type: ActionTypes.DEMO_USER_LOGGED_IN,
                                user: demoUser,
                            });
                        }
                        dispatch(loadCurrentUser(demoUser));
                    }
                } else {
                    dispatch(loginUserRedirect());
                }
            })
            .catch((err) => {
                // TODO redirect or something
                handleErrorResponse("loading user", err);
                dispatch(loginUserRedirect());
            });
    }
}

function loginUserRedirect() {
    return () => {
        (async () => {
            await redirectToLogin();
        })();
    }
}

export function loginUserPopup(onComplete = null) {
    return (dispatch) => {
        (async () => {
            try {
                const user = await popupToLogin();
                window.location.reload();
                if (onComplete) {
                    onComplete(user);
                }
                // LocalData.setToken(user.access_token);
                // dispatch({
                //     type: ActionTypes.USER_LOGGED_IN,
                //     token: user.access_token,
                // });
                // dispatch(loadCurrentUser());
            } catch (e) {
                logger.error("Error logging in");
            }
        })();
    }
}

export function setUserUnknown() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CURRENT_USER_UNKNOWN,
        });
    }
}

export function loadCurrentUser(demoUser) {
    return (dispatch) => {
        UserApi.loadUserInfo(demoUser)
            .then((res) => {
                logger.log("LOAD USER");
                if (res.body) {
                    const user = mapUserProfileFromServerToApp(res.body);
                    Sentry.setUser({ userId: user.id, roles: user.roles });
                    // Sentry.captureMessage("User Loaded");

                    if (user?.canClinic && user?.clinics?.length) {
                        map(user.clinics, clinic => {
                            getClinicInfo(clinic.id)
                                .then((res) => {
                                    if (res.body) {
                                        dispatch({
                                            type: ActionTypes.CLINIC_DETAILS_LOADED,
                                            clinic: mapSingleClinicFromServerToApp(res.body)
                                        });
                                    }
                                })
                                .catch((error) => {
                                    handleErrorResponse("loading clinic details", error);
                                })
                                .finally(() => {
                                    // Putting the dispatch after the clinic has been loaded makes it so we no longer see the permissions error
                                    dispatch({
                                        type: ActionTypes.CURRENT_USER_LOADED,
                                        user,
                                    });
                                })
                        })
                    } else {
                        // Dispatch here so users without clinics still work correctly
                        dispatch({
                            type: ActionTypes.CURRENT_USER_LOADED,
                            user,
                        });
                    }

                    if (user?.primaryLocation?.type === "Provider") {
                        ProviderApi.getNode()
                            .then((res) => {
                                dispatch({
                                    type: ActionTypes.CURRENT_USER_NODE_ID,
                                    nodeId: res?.body?.nodeId
                                });
                                dispatch({
                                    type: ActionTypes.NODE_LOADED,
                                    node: res?.body
                                });
                            })
                            .catch((error) => {
                                handleErrorResponse("loading node", error);
                            });
                    }

                    if ((
                        userHasPermission(PermissionTypes.VIEW, UserPermissions.VETCHECK_MAPPING, user) ||
                        userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_VETCHECK, user)
                    ) && !!user?.clinics?.length
                    ){
                        map(user.clinics, clinic => {
                            // Checking for clinic.id because of the registration page.
                            // I guess that there is a clinic being returned but the clinic doesn't have an ID yet
                            if(clinic.id) {
                                VetCheckApi.getVetCheckStatus(clinic.id)
                                    .then((results) => {
                                        dispatch({
                                            type: ActionTypes.CLINIC_VETCHECK_STATUS_LOADED,
                                            status: {
                                                ...results.body,
                                                clinicId: clinic.id
                                            }

                                        });
                                    })
                                    .catch((error) => {
                                        handleErrorResponse("loading vetcheck status", error);
                                    });
                            }
                        })
                    }
                }
            })
            .catch((error) => {
                handleErrorResponse("loading user details", error);
            });
    };
}

export function currentUserLoaded(data) {
    return (dispatch) => {
        const user = mapUserProfileFromServerToApp(data);
        Sentry.setUser({userId: user.id, roles: user.roles});
        // Sentry.captureMessage("User Loaded");
        dispatch({
            type: ActionTypes.CURRENT_USER_LOADED,
            user,
        });
    };
}

export function changeUserLocation(clinicId) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.USER_LOCATION_CHANGED,
            clinicId,
        });
    }
}

export function resetUserLocation() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.USER_LOCATION_CHANGED,
            clinicId: null,
        });
    }
}

export function getRecentUsers() {
    return (dispatch) => {
        const users = LocalData.getRecentUsers();
        dispatch({
            type: ActionTypes.RECENTLY_IMPERSONATED_USERS_LOADED,
            users: users,
        });
    };
}

export function setRecentUser(user) {
    return (dispatch) => {
        LocalData.addRecentUser(user);
        dispatch({
            type: ActionTypes.RECENTLY_IMPERSONATED_USERS_UPDATED,
            user,
        });
    }
}

export function dismissRecentUser(userId) {
    return (dispatch) => {
        LocalData.clearRecentUser(userId);
        dispatch({
            type: ActionTypes.RECENTLY_IMPERSONATED_USER_DISMISSED,
            recentUserId: userId,
        });
    };
}

export function passwordChangeClicked() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.PASSWORD_RESET_CLICKED,
        });
    };
}

export function requestPasswordReset(emailAddress) {
    return (dispatch) => {
        UserApi.resetPassword(emailAddress)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.USER_RESET_REQUESTED,
                        message: res.body,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("requesting password reset", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function createNewPassword(data) {
    // data = userId, password
    return (dispatch) => {
        UserApi.updatePassword(data)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.USER_RESET_COMPLETE,
                        message: res.body,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("updating password", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}


export function verifyUserByHashKey(hash) {
    // data = userId, password
    return (dispatch) => {
        UserApi.verifyUserByHashKey(hash)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.USER_VERIFY_COMPLETE,
                        data: res.body,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("verifying user", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function showCreateForm() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.USERS_SHOW_CREATE_FORM,
        });
    }
}

export function hideCreateForm() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.USERS_HIDE_CREATE_FORM,
        });
    }
}

export function createUser(data) {
    return (dispatch) => {
        UserApi.createUser(data)
            .then((res) => {
                // toast.success("User created successfully");
                if (res.body) {
                    dispatch({
                        type: ActionTypes.USER_CREATED,
                        user: res.body,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("creating user", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function loadUsersList(searchParams) {
    const params = searchParams ? { ...searchParams } : { limit: 100, offset: 0, orderBy: "clinicId", orderDir: "DESC" }
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        UserApi.searchUsers(params)
            .then((res) => {
                if (res.body) {
                    dispatch(ApplicationStateActions.endWorking());
                    const { data, ...rest} = res.body;
                    if (res.body) {
                        dispatch({
                            type: ActionTypes.USERS_LOADED,
                            users: data,
                            ...rest,
                            params: params,
                        });
                    }
                }
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading clinics")(error);
            });
    }
}
export function searchUsers(query) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.USER_SEARCH_FILTERED,
            query,
        })
    }
}

export function clearUsersSearch() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.USER_SEARCH_CLEARED,
        })
    }
}

export function searchUserManagement(searchValue) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.USER_MANAGEMENT_SEARCH,
            search: searchValue,
        });
    };
}

// Non-admins can do this (account settings dropdown)
export function updateUserSelf(data) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.CURRENT_USER_UPDATING });

        UserApi.updateUserSelf(data)
            .then((res) => {
                // We can't use mapUserProfileFromServerToApp() here since we don't receive the entire userProfile in the response
                const userUpdate = {
                    ...data,
                    name: `${data.firstName} ${data.lastName}`,
                };
                toast.success("User updated successfully");
                dispatch({
                    type: ActionTypes.CURRENT_USER_UPDATED,
                    userUpdate,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating profile", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
                dispatch({
                    type: ActionTypes.CURRENT_USER_UPDATED,
                });
            });
    }
}

export function getClinicUserRoleIds() {
    return (dispatch) => {
        UserApi.getClinicUserRoleIds()
            .then((res) => {
                dispatch({
                    type: ActionTypes.USER_ROLES_LOADED,
                    roleIds: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("loading clinic user roles", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function healthCheck() {
    return (dispatch) => {
        UserApi.healthCheck()
            .then((res) => {
                dispatch({
                    type: ActionTypes.HEALTH_CHECK_SUCCESSFUL,
                });
            })
            .catch((error) => {
                // handleErrorResponse("the API Appears to be offline", error);
                dispatch({
                    type: ActionTypes.HEALTH_CHECK_UNSUCCESSFUL,
                });
            });
    }
}

export function setWindowVisibility(inFocus) {
    const type = inFocus ? ActionTypes.WINDOW_FOCUS_EVENT : ActionTypes.WINDOW_BLUR_EVENT;
    return (dispatch) => {
        dispatch({
            type,
        })
    }
}

export function clearAllSearches() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLEAR_ALL_SEARCHES,
        })
    }
}
