import React from 'react';

export default function getCardIcon(brand) {
    let cardIcon;

    switch (brand) {
        case "Visa":
            cardIcon = <i className="fab fa-cc-visa"></i>;
            break;
        case "Discover":
            cardIcon = <i className="fab fa-cc-discover"></i>;
            break;
        case "MasterCard":
            cardIcon = <i className="fab fa-cc-mastercard"></i>;
            break;
        case "American Express":
            cardIcon = <i className="fab fa-cc-amex"></i>;
            break;
        case "Diners Club":
            cardIcon = <i className="fab fa-cc-diners-club"></i>;
            break;
        case "Paypal":
            cardIcon = <i className="fab fa-cc-paypal"></i>;
            break;
        case "Stripe":
            cardIcon = <i className="fab fa-cc-stripe"></i>;
            break;
        default:
            cardIcon = brand;
            break;
    }

    return cardIcon;
}