// Adobe sign base path
export const ADOBE_SIGN_BASE_PATH = "https://na4.documents.adobe.com";

// Notification States
export const CANCELED = "canceled";
export const DECLINED = "declined";
export const DISMISSED = "dismissed";
export const ENROLLED = "enrolled";
export const PENDING = "pending";

export const MESSAGE  = "message";
export const OFFER  = "offer";
export const SUCCESS = "success";
export const WARN = "warning";

// Notification Types
export const BI_ET_NON_CC_OPTIN = "BIETNONCCOptin";
export const BI_GAMES = "BIGames";
export const BI_IR_FORM = "BIIRForm";
export const DEMO_LAPSED_USER_OPT_IN = "demoLapsedUserOptIn";
export const GAMES_DISCLAIMER = "GamesDisclaimer";
export const GREENLINE_GIVING = "GreenlineGiving";
export const LAPSED_USER_OPT_IN  = "LapsedUserOptIn";
export const ADOBE_ONLY = "AdobeOnly";
export const ORAVET_2022  = "oravet2022";
export const PUPPY_GRADUATION_OPT_IN = "PuppyGraduationOptIn";
export const VETCHECK = "VetCheck";
export const CLINIC_INFORMATION_UPDATE = "ClinicInformationUpdate";
export const SYNC_TRIGGER = "SyncTrigger";
export const MERCK_ADOBE_ONLY = "MerckAdobeOnly";
export const ZOETIS_PARASITICIDE_PILOT = 'ZoetisParasiticidePilot';
export const SYNC_TRIGGER_CLOO = "SyncTriggerClooEnrollment";

export const ALL_NOTIFICATION_TYPES = [
    BI_ET_NON_CC_OPTIN,
    BI_GAMES,
    BI_IR_FORM,
    GAMES_DISCLAIMER,
    GREENLINE_GIVING,
    LAPSED_USER_OPT_IN,
    ORAVET_2022,
    PUPPY_GRADUATION_OPT_IN,
    SYNC_TRIGGER,
    VETCHECK,
    CLINIC_INFORMATION_UPDATE,
    MERCK_ADOBE_ONLY,
    ADOBE_ONLY,
    ZOETIS_PARASITICIDE_PILOT,
    SYNC_TRIGGER_CLOO,
];

export const AVAILABLE_NOTIFICATION_TYPES = [
    SYNC_TRIGGER,
];

export const STATUS_COLOR_CLASS = {
    [DECLINED]: "text-danger",
    [ENROLLED]: "text-success",
    [PENDING]: "",
    [DISMISSED]: "",
    [CANCELED]: "",
}
