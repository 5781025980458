import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./CouponStatsTile.scss";

export default function CouponStatsTile(props) {

    return (<div className={styles.root}>
        <div
            className={styles.innerTile}
        >
            <div className={styles.content}>
                {props.largeValue && <span className={styles.largeValue}>{props.largeValue}</span>}
                {props.label && <span className={styles.label}>{props.label}</span>}
                {props.largeIcon && <span className={styles.largeIcon}><i className={props.largeIcon} /></span>}
            </div>
        </div>
    </div>)
}

CouponStatsTile.propTypes = {
    label: PropTypes.string,
    largeIcon: PropTypes.string,
    largeValue: PropTypes.string,
};

