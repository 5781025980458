import React from "react";
import PropTypes from "prop-types";
import SortableDataTable from "components/common/SortableDataTable";

const MINIMAL_COLUMNS = [
    {
        name: "Clinic",
        selector: "name",
        key: "name",
        format: row => (
            <div>
                <div>{row.name}</div>
                <div style={{ fontSize: "0.7em" }}>{row.city}, {row.state}</div>
            </div>
        ),
    },
    {
        name: "Clinic ID",
        selector: "id",
        key: "id",
    }
];

// Currently, the admin map table doesn't expand
const ALL_COLUMNS = [
    ...MINIMAL_COLUMNS,
];

export default function AdminClinicMapTable(props) {
    return (
        <SortableDataTable
            striped
            green
            hideSearchBar
            title={props.title}
            resultsPerPage={props.resultsPerPage || 10}
            // downloadPDF={props.downloadPDF}
            columns={props.showAllColumns ? ALL_COLUMNS : MINIMAL_COLUMNS}
            rawData={props.clinics}
            onClick={props.onRowClick}
        />
    );
}

AdminClinicMapTable.propTypes = {
    clinics: PropTypes.array,
    resultsPerPage: PropTypes.number,
    showAllColumns: PropTypes.bool,
}

AdminClinicMapTable.defaultProps = {
    showAllColumns: true,
}
