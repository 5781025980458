exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".PuppySwimming__cls1{fill:#e7eb17}.PuppySwimming__cls1,.PuppySwimming__cls2,.PuppySwimming__cls3,.PuppySwimming__cls4,.PuppySwimming__cls5,.PuppySwimming__cls6,.PuppySwimming__cls7,.PuppySwimming__cls8,.PuppySwimming__cls9,.PuppySwimming__cls10,.PuppySwimming__cls11,.PuppySwimming__cls12,.PuppySwimming__cls13{stroke-width:0}.PuppySwimming__cls14{clip-path:url(#clippath)}.PuppySwimming__cls2{fill:none}.PuppySwimming__cls3{fill:#a6d7d6}.PuppySwimming__cls4{fill:#0b0808}.PuppySwimming__cls5{fill:#1b75bc}.PuppySwimming__cls6{fill:#b76c00}.PuppySwimming__cls15{clip-path:url(#clippath-1)}.PuppySwimming__cls7{fill:#d6d91c}.PuppySwimming__cls8{fill:#fefefe}.PuppySwimming__cls9{fill:#fff}.PuppySwimming__cls10{fill:#231f20}.PuppySwimming__cls11{fill:#ccc7c6}.PuppySwimming__cls12{fill:#033557}.PuppySwimming__cls13{fill:#04456f}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/GoldenSummerGames/PuppySwimming.scss"],"names":[],"mappings":"AAAA,qBAAM,YAAY,CAAC,qRAAkF,cAAc,CAAC,sBAAO,wBAAwB,CAAC,qBAAM,SAAS,CAAC,qBAAM,YAAY,CAAC,qBAAM,YAAY,CAAC,qBAAM,YAAY,CAAC,qBAAM,YAAY,CAAC,sBAAO,0BAA0B,CAAC,qBAAM,YAAY,CAAC,qBAAM,YAAY,CAAC,qBAAM,SAAS,CAAC,sBAAO,YAAY,CAAC,sBAAO,YAAY,CAAC,sBAAO,YAAY,CAAC,sBAAO,YAAY,CAAC","file":"PuppySwimming.scss","sourcesContent":[".cls1{fill:#e7eb17}.cls1,.cls2,.cls3,.cls4,.cls5,.cls6,.cls7,.cls8,.cls9,.cls10,.cls11,.cls12,.cls13{stroke-width:0}.cls14{clip-path:url(#clippath)}.cls2{fill:none}.cls3{fill:#a6d7d6}.cls4{fill:#0b0808}.cls5{fill:#1b75bc}.cls6{fill:#b76c00}.cls15{clip-path:url(#clippath-1)}.cls7{fill:#d6d91c}.cls8{fill:#fefefe}.cls9{fill:#fff}.cls10{fill:#231f20}.cls11{fill:#ccc7c6}.cls12{fill:#033557}.cls13{fill:#04456f}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cls1": "PuppySwimming__cls1",
	"cls2": "PuppySwimming__cls2",
	"cls3": "PuppySwimming__cls3",
	"cls4": "PuppySwimming__cls4",
	"cls5": "PuppySwimming__cls5",
	"cls6": "PuppySwimming__cls6",
	"cls7": "PuppySwimming__cls7",
	"cls8": "PuppySwimming__cls8",
	"cls9": "PuppySwimming__cls9",
	"cls10": "PuppySwimming__cls10",
	"cls11": "PuppySwimming__cls11",
	"cls12": "PuppySwimming__cls12",
	"cls13": "PuppySwimming__cls13",
	"cls14": "PuppySwimming__cls14",
	"cls15": "PuppySwimming__cls15"
};