import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import produce from "immer";
import { forEach, map, sortBy } from "lodash";
import Button from "components/common/Button";
import TextBox from "components/common/TextBox";
import * as ClinicApi from "api/ClinicApi";
import styles from "./OnGardTaskCountForm.scss";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import CheckboxInput from "components/common/CheckboxInput";
import AccessDenied from "components/common/AccessDenied";
import BiProductDisclosures from "components/common/BiProductDisclosures";
import {handleErrorResponse} from "utils/request";

export default function OnGardTaskCountForm(props) {
    const [formData, setFormData] = useState(null);
    const [tasks, setTasks] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const readOnly = !props.canEditClinicBiGamesDashboard;

    useEffect(() => {
        if (props.canViewClinicBIGamesDashboard && props.game) {
            fetchTaskData();
        }
    }, []);

    useEffect(() => {
        if (props.game && tasks) {
            const initialFormData = {
                tasks: {},
                initials: "",
            };
            forEach(tasks, t => {
                initialFormData.tasks[t.onGardTaskId] = {
                    onGardTaskId: t.onGardTaskId,
                    totalCount: t.totalCount || 0,
                };
            });
            setFormData(initialFormData);
        }
    }, [tasks]);

    const fetchTaskData = async () => {
        try {
            setIsLoading(true);
            const res = await ClinicApi.getOnGardGameTasks(props.clinicId, props.game.greenlineGameId);
            setTasks(res.body);
            setIsLoading(false);
        } catch (error) {
            handleErrorResponse("loading game tasks", error);
            setIsLoading(false);
        }
    }

    const handleCountChange = (task, changeData) => {
        if (changeData.value >= 0) {
            setFormData(produce(formData, updatedFormData => {
                updatedFormData.tasks[task.onGardTaskId].totalCount = Number(changeData.value);
            }));
        }
    };

    const handleTaskCountClick = (task, changeAmount) => {
        const newCount = Math.max(0, formData.tasks[task.onGardTaskId].totalCount + changeAmount);
        handleCountChange(task, { value: newCount });
    };

    const handleTaskCheckClick = task => {
        const newCount = formData.tasks[task.onGardTaskId].totalCount === 0 ? 1 : 0;
        handleCountChange(task, { value: newCount });
    };

    const handleInitialsChanged = changeData => {
        setFormData(produce(formData, update => {
            update.initials = changeData.value;
        }));
    };

    const handleSubmit = () => {
        if(props.canEditClinicBiGamesDashboard) {
            // Transform the form data into server payload format
            const data = {
                ...formData,
                tasks: map(formData.tasks, t => t), // To array instead of dictionary
            };

            props.onSubmit(data);
        }
    }

    if(!props.canViewClinicBIGamesDashboard) {
        return <AccessDenied/>;
    }

    return (
        <div className="full-width full-height">
            {/*<div className="flex flex-column align-center full-width">*/}
            {/*    <div>*/}
            {/*        <h2>OPERATION: ON GARD Clinic Sign-off</h2>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*        Please acknowledge completion of the following tasks*/}
            {/*   </div>*/}
            {/*</div>*/}
            <div
                className="full-width margin-top-md border-bottom"
                // style={{ borderTop: "1px solid #ddd", borderBottom: "1px solid #ddd" }}
            >
                {formData &&
                    <table className="table full-width">
                        <thead>
                            <tr>
                                <th>Times<br/>Completed</th>
                                <th>Task</th>
                                <th>Points</th>
                            </tr>
                        </thead>
                        <tbody>
                        {map(sortBy(tasks, "DisplayOrder"), task => {
                            const formTask = formData.tasks[task.onGardTaskId];
                            let points = formTask.totalCount * task.pointValue;
                            let totalCount = formTask.totalCount;
                            if(!task.requiresClinicSignOff) {
                                points = task.totalPoints;
                                totalCount = !!task.pointValue && !!task.totalPoints ? task.totalPoints / task.pointValue : "N/A";
                            }
                            // TODO: Not a number input but the value and the +/- change the value
                            return (

                                <tr>
                                    <td className={styles.taskCount}>
                                        {task.requiresClinicSignOff ? (
                                            task.maxCount === 1 ? (
                                                <CheckboxInput
                                                    name="taskCount"
                                                    checked={formTask.totalCount > 0}
                                                    onChange={() => handleTaskCheckClick(task)}
                                                    disabled={readOnly}
                                                />
                                            ) : (
                                                <div className="flex spaced-content align-center">
                                                    <Button
                                                        type="success"
                                                        text
                                                        iconOnly
                                                        disabled={formTask.totalCount <= 0 || readOnly}
                                                        onClick={() => handleTaskCountClick(task, -1)}
                                                    >
                                                        <i className="fa fa-minus-circle"/>
                                                    </Button>
                                                    <div>{formTask.totalCount}</div>
                                                    <Button
                                                        type="success"
                                                        text
                                                        iconOnly
                                                        disabled={formTask.totalCount >= task.maxCount || readOnly}
                                                        onClick={() => handleTaskCountClick(task, 1)}
                                                    >
                                                        <i className="fa fa-plus-circle"/>
                                                    </Button>

                                                </div>
                                            )
                                        ) : (
                                            <div>{totalCount}</div>
                                        )}
                                    </td>
                                    <td><span dangerouslySetInnerHTML={{ __html: task.description }} /></td>
                                    <td>{points}</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                }
            </div>
            <div className="flex flex-column align-center margin-top-md">
                <div className="flex">
                    <div className="flex align-center text-nowrap text-bold margin-right-sm">Your Initials:</div>
                    <div className="flex align-center">
                        <TextBox
                            name="initials"
                            value={formData ? formData.initials : ""}
                            onChange={handleInitialsChanged}
                            disabled={readOnly}
                        />
                    </div>
                </div>
                <div className="flex full-width margin-top-md">
                    <div className="text-nowrap">* Your rep will be notified once submitted</div>
                    <div className="flex justify-flex-end full-width">
                        <Button
                            className="margin-right-md"
                            onClick={handleSubmit}
                            disabled={!formData || !formData.initials || readOnly}
                        >
                            Submit
                        </Button>
                        {props.onCancel && (
                            <Button onClick={props.onCancel}>
                                Cancel
                            </Button>
                        )}
                    </div>
                </div>
            </div>
            <div className="text-x-sm border-top margin-bottom-sm">
                <BiProductDisclosures/>
            </div>
            <SpinnerTakeover show={isLoading} />
        </div>
    );
}

OnGardTaskCountForm.propTypes = {
    clinicId: PropTypes.number.isRequired,
    game: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    canViewClinicBIGamesDashboard: PropTypes.bool,
    canEditClinicBiGamesDashboard: PropTypes.bool,
}
