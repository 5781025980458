import React from "react";
import PropTypes from "prop-types";
import styles from "./CorporateGroupFilterDropdown.scss";
import Dropdown from "components/common/Dropdown";
import orderBy from "lodash/orderBy";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";

export default function CorporateGroupFilterDropdown(props) {
	const CORPORATE_GROUP_OPTIONS = orderBy(uniqBy(map(props.clinics, (clinic) => {
		return {
			name: clinic.corporateGroupName || clinic.accountNumber || "UNKNOWN",
			value: clinic.accountNumber || "UNKNOWN"
		};
	}), "value"), "name");

	const handleChanged = (value) => {
		if (props.onChange) {
			props.onChange(value);
		}
	};

	return (
		<div className={`${styles.root} margin-right-sm`}>
			<Dropdown
				label={props.label}
				name={props.name}
				value={props.value}
				options={CORPORATE_GROUP_OPTIONS}
				onChange={handleChanged}
				placeholder={props.placeholder}
				small={props.small}
				wide={props.wide}
				fullWidth={props.fullWidth}
				alignedLeft
			/>
		</div>
	);
}

CorporateGroupFilterDropdown.defaultProps = {
	fullWidth: false,
	onChange: undefined,
	placeholder: "-- Select Corporate Group --",
	small: false,
	value: null,
	wide: false,
	label: "Corporate Group"
}

CorporateGroupFilterDropdown.propTypes = {
	fullWidth: PropTypes.bool,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func,
	placeholder: PropTypes.string,
	small: PropTypes.bool,
	value: PropTypes.string,
	wide: PropTypes.bool,
	clinics: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
}
