import React from "react";
import {compose} from "redux";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import styles from "./TourToolBar.scss";
import * as TourActions from "actions/TourActions";
import * as CouponActions from "actions/CouponActions";
import Button from "components/common/Button";
import TourProgressBar from "components/tour/elements/TourProgressBar";
import * as DemoTourStates from "constants/DemoTourStates";
import { sendEvent } from "utils/Analytics";

function TourToolBar(props) {
    const handleRegister = () => {
        sendEvent("click", { value: "click_register_button" });
        const callback = "?ReturnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3DoidcSpaClientProd%26redirect_uri%3Dhttps%253A%252F%252Fgreenlinepet.com%252Fcallback.html";
        setTimeout(() => {
            window.open(`https://identity.greenlinepet.com/Registration/Form${callback}`, "_self");
        }, 250);
    }

    const handleRestartTour = () => {
        props.clearCouponSummary();
        props.restartTour();
        props.history.push("/begin-tour");
    }

    const handleEndTour = () => {
        props.clearCouponSummary();
        props.markTourAsClosed();
    }

    return (
        <div className={styles.root}>
            <div className="flex-1 flex spaced-content">
                <Button onClick={handleRegister}>
                    Register Now
                </Button>
                <div className={styles.progressBar}>
                    <TourProgressBar/>
                </div>
                {/*Show restart button if the tour is running or some tour sections have been completed */}
                {(props.tourStarted || props.completedTourSections?.length) ? (
                    <Button onClick={handleRestartTour} icon >
                        <i className="fa fa-undo-alt"/> Restart Tour
                    </Button>
                ) : (
                    <Button onClick={handleRestartTour} >
                        Start Tour
                    </Button>
                )}
            </div>
            <div className="flex-none">
                {props.tourStarted && (
                    <Button
                        onClick={handleEndTour}
                        className={styles.invisible}
                    >
                        Skip Tour
                    </Button>
                )}
            </div>
        </div>
    );
}

TourToolBar.propTypes = {
    clinicId: PropTypes.number,
};

const connector = connect(
    (state, ownProps) => {
        return {
            clinic: state.entities.clinics[ownProps.clinicId],
            tourStarted: (state.demo.demoTourState === DemoTourStates.STARTED),
            completedTourSections: state.demo.completedTourSections,
        }
    },
    (dispatch) => ({
        clearCouponSummary: () => dispatch(CouponActions.clearCouponSummary()),
        restartTour: () => dispatch(TourActions.restartTour()),
        markTourAsClosed: () => dispatch(TourActions.markTourAsClosed()),
    })
);

export default compose(
    withRouter,
    connector
)(TourToolBar);
