import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import SortableDataTable from "components/common/SortableDataTable";
import * as CouponActions from "actions/CouponActions";
import ToggleSwitch from "components/common/ToggleSwitch";
import * as ClinicData from "../../../utils/ClinicData";
import {PermissionTypes, userHasPermission} from "../../../utils/permissions/rolesPermissions";
import * as UserPermissions from "../../../constants/UserPermissions";
import {includes} from "lodash";
import * as ProviderIds from "../../../constants/ProviderIds";

function PetOwnerOptOutTable(props) {

    const handleOptIn = (isOptIn, petOwnerId) => {
        props.onOptIn(isOptIn, petOwnerId);
    }

    const COLUMNS = [
        {
            name: "Receive VetCheck Content?",
            selector: "optOut",
            key: "optOut",
            sortable: true,
            format: (row) => (
                <ToggleSwitch
                    key={row.petOwnerId}
                    id="optOut"
                    updateState={(name, value) => handleOptIn(value, row.petOwnerId)}
                    force={!row.optOut}
                    labels={["Opt-Out", "Opt-In"]}
                    disabled={!props.canEditClinicVetCheck}
                />
            ),
        }, {
            name: "First Name",
            selector: "petOwnerFirstName",
            key: "petOwnerFirstName",
            sortable: true,
        }, {
            name: "Last Name",
            selector: "petOwnerLastName",
            key: "petOwnerLastName",
            sortable: true,
        }, {
            name: "Email",
            selector: "petOwnerEmail",
            key: "petOwnerEmail",
            sortable: true,
        }
    ];

    return (
        <SortableDataTable
            columns={COLUMNS}
            rawData={props.petOwners}
            striped
            resultsPerPage={props.resultsPerPage}
            onSort={props.onSort}
        />
    );
}

PetOwnerOptOutTable.propTypes = {
    petOwners: PropTypes.arrayOf(PropTypes.object).isRequired,
    resultsPerPage: PropTypes.number.isRequired,
    onOptIn: PropTypes.func,
    onSort: PropTypes.func
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        //Permissions
        const canEditClinicVetCheck = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_VETCHECK, userProfile);

        return {
            //Permissions
            canEditClinicVetCheck
        };
    },
    (dispatch) => ({
        downloadPDF: () => dispatch(CouponActions.downloadPDF()),
        downloadExcel: () => dispatch(CouponActions.downloadExcel()),
    })
)(PetOwnerOptOutTable);
