import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

export default function LinkWrapper(props) {
    if (props.linkUrl) {
        return (
            <a
                data-testid="link_wrapper_a"
                href={props.linkUrl}
                title={props.title}
                target={props.isExternal ? "_blank" : "_self"}
            >
                {props.children}
            </a>
        );
    } else if (props.link) {
        return (
            <Link
                data-testid="link_wrapper_link"
                to={props.link}
            >
                {props.children}
            </Link>
        )
    } else {
        return props.children;
    }
};

LinkWrapper.propTypes = {
    link: PropTypes.string,
    linkUrl: PropTypes.string,
    title: PropTypes.string,
    isExternal: PropTypes.bool,
    children: PropTypes.node,
};
