import {GAME_TYPES} from "./GameTypes";

const FAKE_ON_GARD_DATA = {
    addOnConfig: [
        {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: null,
                        id: "input1",
                        maxLength: 2,
                        maxValue: 12,
                        minValue: 1,
                        pattern: "[1-9]|1[0-2]",
                        required: true,
                    }, {
                        defaultValue: null,
                        id: "input2",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    },
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Prescribe {input1} doses of heartworm preventive to {input2} dogs",
            displayGroup: "Greenline",
            displayOrder: 1,
            onGardTaskId: 22,
            pointValue: 5
        }, {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: null,
                        id: "input1",
                        maxLength: 2,
                        maxValue: 12,
                        minValue: 1,
                        pattern: "[1-9]|1[0-2]",
                        required: true,
                    }, {
                        defaultValue: null,
                        id: "input2",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    },
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Prescribe {input1} doses of flea and tick protection to {input2} dogs",
            displayGroup: "Greenline",
            displayOrder: 2,
            onGardTaskId: 23,
            pointValue: 5
        }, {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: null,
                        id: "input1",
                        maxLength: 2,
                        maxValue: 12,
                        minValue: 1,
                        pattern: "[1-9]|1[0-2]",
                        required: true,
                    }, {
                        defaultValue: null,
                        id: "maxpergame",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    },
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Test {input1} dogs for heartworm disease or Lyme disease.  Max: {maxpergame}",
            displayGroup: "Clinic",
            displayOrder: 3,
            onGardTaskId: 24,
            pointValue: 5
        }, {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: null,
                        id: "maxpergame",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    },
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Help a pet owner sign up for monthly reminders at HEARTGARD.com or NexGardForDogs.com.  Max: {maxpergame}",
            displayGroup: "Clinic",
            displayOrder: 4,
            onGardTaskId: 25,
            pointValue: 5
        }, {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: null,
                        id: "input1",
                        maxLength: 2,
                        maxValue: 12,
                        minValue: 1,
                        pattern: "[1-9]|1[0-2]",
                        required: true,
                    }, {
                        defaultValue: null,
                        id: "input2",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    },
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Prescribe {input1} doses of heartworm preventative and flea and tick protection to {input2} dogs",
            displayGroup: "Greenline",
            displayOrder: 10,
            onGardTaskId: 26,
            pointValue: 10
        }, {
            configuration: null,
            description: "Help a pet owner redeem a 12-dose coupon offer for NexGard or HEARTGARD Plus",
            displayGroup: "Greenline",
            displayOrder: 11,
            onGardTaskId: 27,
            pointValue: 10
        }, {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: 5,
                        id: "maxpergame",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    },
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Complete the CE course “4 Realities Exposed: A Visual Tour of Heartworm Disease” at HEARTGARDClinic.com.  Max: {maxpergame}",
            displayGroup: "Clinic",
            displayOrder: 12,
            onGardTaskId: 28,
            pointValue: 10
        }, {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: 5,
                        id: "maxpergame",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    },
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Help a new dog owner sign up for Healthy Habits text and/or email reminders at HealthyHabitsForDogs.com.  Max: {maxpergame}",
            displayGroup: "Clinic",
            displayOrder: 13,
            onGardTaskId: 29,
            pointValue: 10
        }, {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: 5,
                        id: "maxpergame",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    },
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Use the free resources on HEARTGARDClinic.com or NexGardClinic.com to educate pet owners on heartworms, fleas, and ticks.  Max: {maxpergame}",
            displayGroup: "Clinic",
            displayOrder: 14,
            onGardTaskId: 30,
            pointValue: 10
        }, {
            configuration: null,
            description: "Sign up for a free Promoboxx® account with your Boehringer Ingelheim Representative",
            displayGroup: "Clinic",
            displayOrder: 20,
            onGardTaskId: 31,
            pointValue: 20
        }, {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: 5,
                        id: "maxpergame",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    },
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Post one of our Heartworm Awareness Month or Lyme Disease Awareness Month assets from Promoboxx® to your clinic social media.  Max: {maxpergame}",
            displayGroup: "Clinic",
            displayOrder: 21,
            onGardTaskId: 32,
            pointValue: 20
        }, {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: 5,
                        id: "maxpergame",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    },
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Take an in-clinic picture featuring a patient and their prescribed packs of either heartworm preventive or flea and tick protection and upload to social media.  Max: {maxpergame}",
            displayGroup: "Clinic",
            displayOrder: 22,
            onGardTaskId: 33,
            pointValue: 20
        }, {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: 5,
                        id: "maxpergame",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    },
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Help a pet owner get signed up for AutoShip using your clinic’s online pharmacy.  Max: {maxpergame}",
            displayGroup: "Clinic",
            displayOrder: 23,
            onGardTaskId: 34,
            pointValue: 20
        }, {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: 5,
                        id: "maxpergame",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    },
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Set up a virtual Lunch and Learn with your Boehringer Ingelheim Representative.  Max: {maxpergame}",
            displayGroup: "Clinic",
            displayOrder: 30,
            onGardTaskId: 35,
            pointValue: 25
        }, {
            configuration: null,
            description: "Meet with your Boehringer Ingelheim Representative about our Pawru™ platform",
            displayGroup: "Clinic",
            displayOrder: 31,
            onGardTaskId: 36,
            pointValue: 25
        }, {
            configuration: {
                canBeDisabled: false,
                defaultIsDisabledValue: false,
                defaultRepInputAmount1: null,
                defaultRepInputAmount2: null,
                repInputs: [
                    {
                        defaultValue: null,
                        id: "input1",
                        maxLength: 3,
                        maxValue: 999,
                        minValue: 1,
                        pattern: "[1-9][0-9]{0,2}",
                        required: true,
                    }
                ],
                repRequiresRepInputAmount1: null,
                repRequiresRepInputAmount2: null,

            },
            description: "Help clients redeem {input1} HEARTGARD Plus and NexGard combo rebates",
            displayGroup: "Greenline",
            displayOrder: 32,
            onGardTaskId: 37,
            pointValue: 25
        },
    ],
    config: {
        Tasks: [
            {
                isEnabled: true,
                OnGardTaskId: 22,
                RepInputAmount: null
            }, {
                isEnabled: true,
                OnGardTaskId: 23,
                RepInputAmount: null
            }, {
                isEnabled: true,
                OnGardTaskId: 24,
                RepInputAmount: null,
                MaxPerGame: 5
            }, {
                isEnabled: true,
                OnGardTaskId: 26,
                RepInputAmount: null
            }, {
                isEnabled: true,
                OnGardTaskId: 37,
                RepInputAmount: null
            }
        ]
    },
    gameSettings: {
        builderImages: [
            {
                href: "{cdn}/games/assets/ongard/2021OperationOnGardSpring.pdf",
                src: "{cdn}/games/assets/ongard/ongard2021spring-earnyourpoints.png",
                style: "height: 200px;"
            }, {
                href: "{cdn}/games/assets/ongard/2021OperationOnGardSpring.pdf",
                src: "{cdn}/games/assets/ongard/ongard2021spring-poster.png",
                style: "height: 200px;",
            }
        ],
        snapshotImage: "/games/assets/ongard/ongard2021spring-poster.png"
    }
}


const EMPTY_GAME = {
    addOnConfig: null,
    canUseAsTemplate: false,
    clinics: [],
    comparisonPeriod: "",
    config: {
        EstROILocked: false,
        IncreasePercentageLocked: false,
        MaxClinics: 10,
        MinClinic: 1,
        PrizeAmountLocked: false,
        Levels: [],
        ComparisonPeriodLocked: false,
        DurationDaysLocked: false,
        ExcludeFreeDosesLocked: false,
        IncludeHomeDeliveryDosesLocked: false,
        GameNameLocked: false,
        GameTypeLocked: false,
        Overrides: null,
        PrizeAmounts: [],
        PrizeLocked: false,
        Prizes: null,
        ProductBrandsLocked: false,
        ProductTypeLocked: false,
        RoundsLocked: false,
        StartDateLocked: false,
    },
    copiedFromGreenlineGameId: null,
    description: null,
    durationDays: null,
    endDate: null,
    excludeFreeDoses: false,
    includeHomeDeliveryDoses: false,
    gameSettings: {},
    gameState: "Draft",
    gameType: "",
    gameTypeVersion: null,
    globalId: "",
    greenlineGameId: 0, //REQUIRED
    greenlineGameTemplateId: null,
    hasCompleted: false,
    hasStarted: false,
    isTemplate: false,
    name: "",
    newGameState: "Draft",
    originalStartDate: null,
    ownerUserId: null, //REQUIRED
    productType: "",
    products: [],
    providerId: null, //REQUIRED
    reportEmailAddress: null,
    rounds: null,
    startDate: null,
}

export const EMPTY_GROWTH_DOSES = {
    ...EMPTY_GAME,
    comparisonPeriod: "YearOverYear",
    config: {
        ...EMPTY_GAME.config,
        gameTypeLocked: true,
        Levels: [
            {
                EstROI: null,
                IncreasePercentage: 10,
                PrizeAmount: 100
            }, {
                EstROI: null,
                IncreasePercentage: 20,
                PrizeAmount: 200
            }, {
                EstROI: null,
                IncreasePercentage: 30,
                PrizeAmount: 300
            },
        ]
    },
    durationDays: 90,
    gameType: GAME_TYPES.GROWTH,
    productType: "Parasiticide",
}

export const EMPTY_CHALLENGE_MATCH = {
    ...EMPTY_GAME,
    comparisonPeriod: "YearOverYear",
    config: {
        ...EMPTY_GAME.config,
        gameTypeLocked: true,
        PrizeAmounts: [
            {DisplayName: "1st Place", PrizeAmount: null}
        ],
    },
    durationDays: 90,
    gameType: GAME_TYPES.DEATH_MATCH,
    productType: "Parasiticide",
}

//TODO: I don't think we will need onGard once we have a real template...
export const EMPTY_ON_GARD = {
    ...EMPTY_GAME,
    ...FAKE_ON_GARD_DATA,
    comparisonPeriod: "YearOverYear",
    config: {
        ...EMPTY_GAME.config,
        ComparisonPeriodLocked: true,
        ExcludeFreeDosesLocked: true,
        GameTypeLocked: true,
        MaxClinics: 1,
        ProductBrandsLocked: true,
        ProductTypeLocked: true,
        RoundsLocked: true,
        Tasks: [
            ...FAKE_ON_GARD_DATA.config.Tasks
        ]
    },
    durationDays: 30,
    gameSettings: {
        ...FAKE_ON_GARD_DATA.gameSettings
    },
    gameType: GAME_TYPES.ON_GARD,
    gameTypeVersion: "2021Spring",
    productType: "Parasiticide",
    providerId: 1
}