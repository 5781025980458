import React from "react";
import PropTypes from "prop-types";
import styles from "./RebateGrid.scss";
import map from "lodash/map";
import DashboardTile from "components/dashboard/widgets/DashboardTile";

export default function RebateGrid(props) {
    const renderTile = (tile) => {
        return (
            <DashboardTile
                key={tile.id}
                title={tile.offerDescription}
                largeValue={`${tile.currentCount}/${tile.maxCount}`}
                label={tile.tileDescription || null}
                type={tile.type}
                // timeRange={currentRange}
                hasDataSet={tile.hasDataSet}
            />

        );
    }

    return (
        <>
            <div className="sectionHeader">
                <h3>{props.title}</h3>
            </div>
            <div className={styles.root}>
                {map(props.tiles, tile => (renderTile(tile)))}
            </div>
        </>
    );
}

RebateGrid.propTypes = {
    tiles: PropTypes.arrayOf(PropTypes.object),
    handlePeriodChanged: PropTypes.func,
    title: PropTypes.string,
};

