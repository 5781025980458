import * as NotificationsApi from "api/NotificationsApi";
import * as ActionTypes from "constants/ActionTypes";
import {handleErrorResponse} from "utils/request";
import toast from "utils/toast";
import {
    mapAllNotificationsFromServerToApp,
    mapNotificationFromAppToServer
} from "data/serverMapping";

export function loadNotifications(clinicId = null) {
    return (dispatch) => {
        NotificationsApi.getNotifications(clinicId)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.NOTIFICATIONS_LOADED,
                        notifications: res.body,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading notifications", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function dismissNotification(notificationId = null) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.NOTIFICATION_DISMISSED,
            notificationId: notificationId,
        });
    };
}

// Any state other than "pending" means the notification is "dismissed".
export function setNotificationState(id, clinicId=null, newState, userInitials = null, formFieldsJson = null) {
    return (dispatch) => {
        NotificationsApi.setNotificationState(id, clinicId, { newState, userInitials, formFieldsJson })
            .then((res) => {
                dispatch({
                    type: ActionTypes.NOTIFICATION_STATE_WAS_SET,
                    notification: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating notification", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function setProviderNotificationState(id, providerId=null, newState, userInitials = null, formFieldsJson = null) {
    return (dispatch) => {
        NotificationsApi.setProviderNotificationState(id, providerId, { newState, userInitials, formFieldsJson })
            .then((res) => {
                dispatch({
                    type: ActionTypes.NOTIFICATION_STATE_WAS_SET,
                    notification: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating notification", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getAllNotifications() {
    return (dispatch) => {
        NotificationsApi.getAllNotifications()
            .then((res) => {
                dispatch({
                    type: ActionTypes.ALL_NOTIFICATIONS_LOADED,
                    notifications: mapAllNotificationsFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                handleErrorResponse("loading all notification", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function createNotification(notification) {
    return (dispatch) => {
        NotificationsApi.createNotification(mapNotificationFromAppToServer(notification))
            .then((res) => {
                dispatch({
                    type: ActionTypes.NOTIFICATION_CREATED,
                    notification: res.body,
                });
            })
            .catch((error) => {
                handleErrorResponse("creating notification", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function storeDismissedAlerts(notification) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.STORE_DISMISSED_ALERTS,
            notification: notification,
        });
    };
}

export function notifyGreenline(notification) {
    return (dispatch) => {
        NotificationsApi.notifyGreenline(notification)
            .then((res) => {
                toast.success("Greenline has been notified.")
            })
            .catch((error) => {
                handleErrorResponse("notifying Greenline", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}