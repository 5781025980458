import React, {useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import classnames from "classnames";
import styles from "./TimeFilterDropdown.scss";
import DateInput from "components/common/DateInput";
import Dropdown from "components/common/Dropdown";
import "react-datepicker/dist/react-datepicker.css";

import * as TimeFilterId from "constants/TimeFilterIds";

const OPTIONS = Object.freeze([
    { name: "Last 7 Days", value: TimeFilterId.LAST_WEEK },
    { name: "Month to Date", value: TimeFilterId.MTD },
    { name: "Last 30 Days", value: TimeFilterId.LAST_30_DAYS},
    { name: "Last 90 Days", value: TimeFilterId.LAST_90_DAYS },
    { name: "Year to Date", value: TimeFilterId.YTD },
    { name: "All", value: TimeFilterId.ALL },
    { name: "Custom Range", value: TimeFilterId.CUSTOM },
]);

const MIN_DATE = "2013/01/01";
const TODAY = new moment().format("YYYY/MM/DD");

export default function TimeFilterDropdown(props) {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const isCustom = props.value === TimeFilterId.CUSTOM;

    const handleChanged = (selection) => {
        if(!!props.onChange) {
            props.onChange({name: props.name, value:selection.value});
        }
    };

    const onSelectEndDate = ({value}) => {
        if(!!startDate) {
            // Everything is set... fire off submit
            setEndDate(value);
            submitDateRange(startDate, value);
        }

        // No end date, just set the state
        setEndDate(value);
    }

    const onSelectStartDate = ({value}) => {
        const startDate = new moment(value);
        if(!!endDate) {
            const isAfterEnd = startDate.isAfter(endDate);
            if(isAfterEnd) {
                // not a valid range, clear the end date
                setStartDate(value);
                setEndDate(null);
                return;
            }

            // Everything is set... fire off submit
            setStartDate(value);
            submitDateRange(value, endDate);
        }

        // No end date, just set the state
        setStartDate(value);
    }

    const submitDateRange = (start, end) => {
        if(props.onRangeChange) {
            props.onRangeChange({startDate: start, endDate: end});
        }
    }

    return (
        <div className={classnames(styles.root, {
            [styles.dateRange] : isCustom,
        })}>

            <Dropdown
                name="timeFilter"
                value={props.value}
                options={OPTIONS}
                onChange={handleChanged}
                small
                fullWidth
            />
            {isCustom &&
            <div className={styles.datePickers}>
                <div className={styles.datePicker}>
                    <DateInput
                        value={startDate}
                        dateFormat={"YYYY-MM-DD"}
                        onChange={onSelectStartDate}
                        placeholderText="Start Date"
                        dateRange
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                        minDate={MIN_DATE}
                        maxDate={TODAY}
                        showDropdowns
                    />
                </div>
                <div className={styles.datePicker}>
                    <DateInput
                        value={endDate}
                        dateFormat={"YYYY-MM-DD"}
                        onChange={onSelectEndDate}
                        placeholderText="End Date"
                        dateRange
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate || MIN_DATE}
                        maxDate={TODAY}
                        showDropdowns
                    />
                </div>
            </div>
            }

        </div>
    );
}

TimeFilterDropdown.propTypes = {
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onRangeChange: PropTypes.func,
}
