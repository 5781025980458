import React, { useState } from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import styles from "./ClinicSettingsForm.scss";
import merge from "lodash/merge";
import set from "lodash/set";
import Button from "components/common/Button";
import CheckboxInput from "components/common/CheckboxInput";
import Dropdown from "components/common/Dropdown";
import TextBox from "components/common/TextBox";
import * as ClinicData from "utils/ClinicData";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import { countriesDropdownList } from "constants/Countries";
import { usStatesDropdownList } from "constants/USStates";
import * as UserPermissions from "constants/UserPermissions";
import isArray from "lodash/isArray";
import map from "lodash/map";
import isObject from "lodash/isObject";
import drop from "lodash/drop";
import concat from "lodash/concat";
import mergeWith from "lodash/mergeWith";

function ClinicSettingsForm(props) {
    const [formData, setFormData] = useState({});
    const canEdit = props.canEditClinicInfo;

    const combinedData = {
        ...props.clinicInfo,
        billingAddress: {
            ...props.clinicInfo.billingAddress,
            createUserId: props.clinicInfo.billingAddress?.createUserId || props.userProfile.id,
        },
        shippingAddress: {
            ...props.clinicInfo.shippingAddress,
            createUserId: props.clinicInfo.shippingAddress?.createUserId || props.userProfile.id,
        },
    };

    const custom = (objValue, srcValue) => {
      if (isArray(objValue)) {
          let updated = map(objValue, (li, index) => {
              if(isObject(li)) {
                  return merge(li, srcValue[index]);
              } else {
                  return srcValue?.[index] || li;
              }
          });
          if(srcValue.length > objValue.length) {
              const newItems = drop(srcValue, objValue.length);
              updated = concat(updated, newItems);
          }
          return updated;
      }
    }
    mergeWith(combinedData, formData, custom);

    const handleFormChange = ({ name, value }) => {
        const newFormData = {...formData};
        set(newFormData, name, value);
        setFormData(newFormData);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const newData = {
            ...combinedData,
            billingAddress: {
                ...combinedData.billingAddress,
                modDate: !!formData.billingAddress ? combinedData.billingAddress?.createDate : new Date(),
                modUserId: !!formData.billingAddress ? combinedData.billingAddress?.modUserId : props.userProfile.id,
            },
            shippingAddress: {
                ...combinedData.shippingAddress,
                modDate: !!formData.shippingAddress ? combinedData.shippingAddress?.createDate : new Date(),
                modUserId: !!formData.shippingAddress ? combinedData.shippingAddress?.modUserId : props.userProfile.id,
            }
        };
        props.onSubmit(newData);
    };

    return (
        <form
            data-testid="clinic_settings_form_component"
            onSubmit={handleSubmit}
            className={styles.root}
        >
            <TextBox
                onChange={handleFormChange}
                name="name"
                value={combinedData.name}
                label="Name"
                required
                disabled
            />
            <TextBox
                onChange={handleFormChange}
                name="contactEmail"
                value={combinedData.contactEmail}
                label="Contact Email"
                disabled={!canEdit}
            />
            <TextBox
                onChange={handleFormChange}
                name="contactPhone"
                value={combinedData.contactPhone}
                label="Contact Phone"
                disabled={!canEdit}
            />
            <hr className="margin-top-sm margin-bottom-sm"/>
            <div className="margin-top-sm">
                <div className="flex spaced-content align-center">
                    <h3 className="flex-1">Street Address</h3>
                    <div className="flex-none">
                        <CheckboxInput
                            onChange={handleFormChange}
                            name="billingAddress.isActive"
                            checked={combinedData.billingAddress.isActive}
                            label="Different billing address?"
                            disabled={!canEdit}
                        />
                        {/*<CheckboxInput*/}
                        {/*    onChange={handleFormChange}*/}
                        {/*    name="shippingAddress.isActive"*/}
                        {/*    checked={combinedData.shippingAddress.isActive}*/}
                        {/*    label="Different shipping address?"*/}
                        {/*    disabled={!canEdit}*/}
                        {/*/>*/}
                    </div>
                </div>
                <TextBox
                    onChange={handleFormChange}
                    name="street1"
                    value={combinedData.street1}
                    label="Street 1"
                    required
                    disabled={!canEdit}
                />
                <TextBox
                    onChange={handleFormChange}
                    name="street2"
                    value={combinedData.street2}
                    label="Street 2"
                    disabled={!canEdit}
                />
                <div className="flex spaced-content">
                    <div className="flex-1">
                        <TextBox
                            onChange={handleFormChange}
                            name="city"
                            value={combinedData.city}
                            label="City"
                            disabled
                        />
                    </div>
                    <div className="flex-1">
                        <Dropdown
                            onChange={handleFormChange}
                            name="countryCode"
                            value={combinedData.countryCode}
                            placeholder="Select a Country"
                            label="Country"
                            options={countriesDropdownList}
                            disabled
                        />
                    </div>
                </div>
                <div className="flex spaced-content">
                    <div className="flex-2">
                        <Dropdown
                            onChange={handleFormChange}
                            name="state"
                            value={combinedData.state}
                            placeholder="Select a State"
                            label="State"
                            options={usStatesDropdownList}
                            required
                            disabled
                        />
                    </div>
                    <div className="flex-1">
                        <TextBox
                            onChange={handleFormChange}
                            name="zipCode"
                            value={combinedData.zipCode}
                            label="Postal Code"
                            required
                            disabled={!canEdit}
                        />
                    </div>
                </div>
                <hr className="margin-top-sm margin-bottom-sm"/>
            </div>
            {combinedData.billingAddress.isActive && (
                <div className="flex-1">
                    <h3>Billing Address</h3>
                    <TextBox
                        onChange={handleFormChange}
                        name="billingAddress.street1"
                        value={combinedData.billingAddress.street1}
                        label="Street 1"
                        required
                        disabled={!canEdit}
                    />
                    <TextBox
                        onChange={handleFormChange}
                        name="billingAddress.street2"
                        value={combinedData.billingAddress.street2}
                        label="Street 2"
                        disabled={!canEdit}
                    />
                    <div className="flex spaced-content">
                        <div className="flex-1">
                            <TextBox
                                onChange={handleFormChange}
                                name="billingAddress.city"
                                value={combinedData.billingAddress.city}
                                label="City"
                                required
                                disabled={!canEdit}
                            />
                        </div>
                        <div className="flex-1">
                            <Dropdown
                                onChange={handleFormChange}
                                name="billingAddress.countryCode"
                                value={combinedData.billingAddress.countryCode}
                                placeholder="Select a Country"
                                label="Country"
                                required
                                options={countriesDropdownList}
                                disabled={!canEdit}
                            />
                        </div>
                    </div>
                    <div className="flex spaced-content">
                        <div className="flex-2">
                            <Dropdown
                                onChange={handleFormChange}
                                name="billingAddress.stateProvince"
                                value={combinedData.billingAddress.stateProvince}
                                placeholder="Select a State"
                                label="State"
                                required
                                options={usStatesDropdownList}
                                disabled={!canEdit}
                            />
                        </div>
                        <div className="flex-1">
                            <TextBox
                                onChange={handleFormChange}
                                name="billingAddress.postalCode"
                                value={combinedData.billingAddress.postalCode}
                                label="Postal Code"
                                required
                                disabled={!canEdit}
                            />
                        </div>
                    </div>
                    <hr className="margin-top-sm margin-bottom-sm"/>
                </div>
            )}
            {/*{combinedData.shippingAddress.isActive && (*/}
            {/*    <div className="flex-1">*/}
            {/*        <h3>Shipping Address</h3>*/}
            {/*        <TextBox*/}
            {/*            onChange={handleFormChange}*/}
            {/*            name="shippingAddress.street1"*/}
            {/*            value={combinedData.shippingAddress.street1}*/}
            {/*            label="Street 1"*/}
            {/*            required*/}
            {/*            disabled={!canEdit}*/}
            {/*        />*/}
            {/*        <TextBox*/}
            {/*            onChange={handleFormChange}*/}
            {/*            name="shippingAddress.street2"*/}
            {/*            value={combinedData.shippingAddress.street2}*/}
            {/*            label="Street 2"*/}
            {/*            disabled={!canEdit}*/}
            {/*        />*/}
            {/*        <div className="flex spaced-content">*/}
            {/*            <div className="flex-1">*/}
            {/*                <TextBox*/}
            {/*                    onChange={handleFormChange}*/}
            {/*                    name="shippingAddress.city"*/}
            {/*                    value={combinedData.shippingAddress.city}*/}
            {/*                    label="City"*/}
            {/*                    required*/}
            {/*                    disabled={!canEdit}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div className="flex-1">*/}
            {/*                <Dropdown*/}
            {/*                    onChange={handleFormChange}*/}
            {/*                    name="shippingAddress.countryCode"*/}
            {/*                    value={combinedData.shippingAddress.countryCode}*/}
            {/*                    placeholder="Select a Country"*/}
            {/*                    label="Country"*/}
            {/*                    required*/}
            {/*                    options={countriesDropdownList}*/}
            {/*                    disabled={!canEdit}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <div className="flex spaced-content">*/}
            {/*            <div className="flex-2">*/}
            {/*                <Dropdown*/}
            {/*                    onChange={handleFormChange}*/}
            {/*                    name="shippingAddress.stateProvince"*/}
            {/*                    value={combinedData.shippingAddress.stateProvince}*/}
            {/*                    placeholder="Select a State"*/}
            {/*                    label="State"*/}
            {/*                    required*/}
            {/*                    options={usStatesDropdownList}*/}
            {/*                    disabled={!canEdit}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*            <div className="flex-1">*/}
            {/*                <TextBox*/}
            {/*                    onChange={handleFormChange}*/}
            {/*                    name="shippingAddress.postalCode"*/}
            {/*                    value={combinedData.shippingAddress.postalCode}*/}
            {/*                    label="Postal Code"*/}
            {/*                    required*/}
            {/*                    disabled={!canEdit}*/}
            {/*                />*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        <hr className="margin-top-sm margin-bottom-sm"/>*/}
            {/*    </div>*/}
            {/*)}*/}
            <TextBox
                onChange={handleFormChange}
                name="clinicWebsiteUrl"
                value={combinedData.clinicWebsiteUrl}
                label="Website"
                disabled={!canEdit}
            />
            <TextBox
                onChange={handleFormChange}
                name="instagramUrl"
                value={combinedData.instagramUrl}
                label="Instagram"
                disabled={!canEdit}
            />
            <TextBox
                onChange={handleFormChange}
                name="facebookUrl"
                value={combinedData.facebookUrl}
                label="Facebook"
                disabled={!canEdit}
            />
            <TextBox
                onChange={handleFormChange}
                name="linkedInUrl"
                value={combinedData.linkedInUrl}
                label="LinkedIn"
                disabled={!canEdit}
            />
            <div className="flex">
                <div className="flex-1" />
                <div className="flex-none spaced-content">
                    <Button
                        type="gray"
                        onClick={props.onCancel}
                    >
                        {canEdit ? "Cancel" : "Close"}
                    </Button>
                    {canEdit && (
                        <Button
                            buttonType="submit"
                            disabled={!canEdit}
                        >
                            Save Changes
                        </Button>
                    )}

                </div>
            </div>
        </form>
    );
}

ClinicSettingsForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    clinicId: PropTypes.number,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        //    Permissions
        const canEditClinicInfo = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_INFO, userProfile);
        return {
            userProfile,
            clinicInfo: ClinicData.getClinicInfo(state, ownProps.clinicId),
        //    Permissions
            canEditClinicInfo,
        }
    }
)(ClinicSettingsForm);
