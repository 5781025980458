import React, { useState } from "react";
import PropTypes from "prop-types";
import logger from "utils/logger";

/// Simple wrapper for `img` which provides an optional fallback image in case the requested one doesn't load.
export default function Image(props) {
    const [imageUrl, setImageUrl] = useState(props.src || props.fallbackSrc);

    const handleError = e => {
        if (props.fallbackSrc) {
            setImageUrl(props.fallbackSrc);
        } else {
            logger.warn(`No fallback image for '${props.alt || props.title || props.src}'`);
        }
    };

    return (
        <img
            className={props.className}
            style={props.style}
            src={imageUrl}
            alt={props.alt}
            title={props.title}
            onLoad={props.onLoad}
            onError={props.onError || handleError}
        />
    );
}

Image.propTypes = {
    src:PropTypes.string,
    style: PropTypes.shape({}),
    alt: PropTypes.string,
    title: PropTypes.string,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    fallbackSrc: PropTypes.string, // ?? I guess URL strings or base64 strings are the main src "types"?
}
