import React, {useState} from 'react';
import { connect } from 'react-redux';
import { map, orderBy, filter } from 'lodash';
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import PropTypes from "prop-types";
import Dropdown from "components/common/Dropdown";
import Button from "components/common/Button";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import * as CssStyleIds from "constants/CssStyleIds";
import TextBox from "components/common/TextBox";
import classnames from "classnames";
import * as AdminActions from "actions/AdminActions";

function AddRoleForm(props) {
    const [formData, setFormData] = useState({});
    const [roleType, setRoleType] = useState(null);
    const [copy, setCopy] = useState(null);
    const [loading, setLoading] = useState(false);

    const ROLE_TYPES = orderBy(map(props.roles?.roleTypes, type => {return  {name: type, value: type }}), "name");
    const ROLE_OPTIONS = orderBy(map(props.roles?.roles, role => {return  {name: role.name , value: role.id, roleType: role.roleType}}), "name") || [];

    if (!props.canEditRoleManagement) {
        return <AccessDenied/>;
    }

    const handleSave = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const newData = {
            ...formData,
            id: roleId,
            copyFromRole: copy
        }

        props.createRole(newData);
        props.handleCancel();
    }

    const handleChange = ({name, value}) => {
        const newFormData = {
            ...formData,
            [name]: value
        }
        if (name === "roleType" && !roleType) {
            setCopy(null);
        }
        setFormData(newFormData);
    }

    const handleChangeCopyRoleType = ({value}) => {
        if (value !== roleType) {
            setRoleType(value);
            setCopy(null);
        }
    }

    const handleCancel = (e) => {
        e.stopPropagation();
        e.preventDefault();
        props.handleCancel();
    }

    const filteredRoleOptions = (roleType || formData.roleType) ? filter(ROLE_OPTIONS, {roleType: roleType || formData.roleType}) : ROLE_OPTIONS

    const roleId = formData.roleType ? `${formData.roleType}${!!formData.name ? formData.name : ""}` : "";
    const roleExists = !!filter(ROLE_OPTIONS, {value: roleId})?.length;
    const missingValues = !(formData.roleType && formData.name && copy);

    return (
        <div>
            <form onSubmit={handleSave}>
                <Dropdown
                    label="Role Type"
                    options={ROLE_TYPES}
                    name="roleType"
                    value={formData.roleType}
                    onChange={handleChange}
                    placeholder="Select Role Type"
                    required
                />
                <TextBox
                    label="Name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Role Name"
                    hasError={roleExists}
                    required
                />
                <div className={classnames("margin-top-x-sm", {
                    "text-danger": roleExists
                })}
                >
                    <b>Role ID:</b> {roleId} {roleExists && (<span>- This Role ID Already Exists</span>)}
                </div>
                <div className="text-sm margin-bottom-sm">Role ID is created by combining the Role Type with the Name</div>
                <hr/>
                <Dropdown
                    label="Role Type"
                    options={ROLE_TYPES}
                    name="roleType"
                    value={roleType || formData.roleType}
                    onChange={handleChangeCopyRoleType}
                    placeholder="Select Role Type"
                    required
                />
                <Dropdown
                    label="Copy Permissions From"
                    options={filteredRoleOptions}
                    name="copy"
                    value={copy}
                    onChange={({value}) => setCopy(value)}
                    placeholder="Select a Role to Copy Permissions From"
                    required
                />
                <div className="flex spaced-content justify-flex-end margin-top-md border-top">
                    <Button
                        type={CssStyleIds.GRAY}
                        onClick={handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        buttonType="submit"
                        disabled={roleExists || missingValues}
                    >
                        Submit Changes
                    </Button>
                </div>
            </form>
            <SpinnerTakeover show={loading}/>
        </div>
    );
}

AddRoleForm.propTypes = {
    handleCancel : PropTypes.func
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canEditRoleManagement = userHasPermission(PermissionTypes.EDIT, UserPermissions.ROLE_MANAGEMENT, userProfile);
        return {
            canEditRoleManagement,
            roles: state.entities.roles,
        };
    },
    (dispatch) => ({
        createRole: (data) => dispatch(AdminActions.createRole(data)),
    }),
)(AddRoleForm);
