import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import produce from "immer";
import moment from "moment";
import styles from "./PlanOptionDetails.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import includes from "lodash/includes";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import CheckboxInput from "components/common/CheckboxInput";
import Dropdown from "components/common/Dropdown";
import { formatUSD } from "utils/numeric";

export default function PlanOptionDetails(props) {
    const [planOptions, setPlanOptions] = useState([])
    const TODAY = new moment();
    const planAddOnIds = map(props.addOns, (add, index) => index);

    useEffect(() => {
        setPlanOptions(map(filter(props.allPlans.plans, p => {
            const priceSchedule = props.allPlans.priceSchedules[p.clinicPriceScheduleId];
            return p.isActive && TODAY.isBetween(priceSchedule.startDate, priceSchedule.endDate);
        }), type => {
            const name = type.clinicPlanDto?.alias || type.name || "(Unknown)"
            return {
                name,
                value: type.clinicPlanInstanceId}
        }))
    }, props.allPlans)

    const renderServicesByType = (services, type) => {
        const filteredServices = orderBy(filter(services, (service) => {
            return includes(type, service.serviceCategoryName) && (service.isUnlimited || service.maxQuantityAllowedInTerm > 0);
        }), "serviceTypeName");

        if (!filteredServices?.length) {
            return  "None Selected";
        }

        return map(filteredServices, (service, index) => (
            <div
                key={`${props.patient.patientId}-${service.serviceTypeId}-${index}`}
                className={"margin-left-sm"}
            >
                {service.serviceTypeName} ({service.isUnlimited ? "Unlimited" : service.maxQuantityAllowedInTerm})
            </div>
        ));
    };

    const renderDisplayedPlanAddOns = (services) => {
        const displayedPlanAddons = filter(services, (service) => {
            return service.serviceCategoryName === 'Add-On Plans' && keys(service.enrollmentMappings)?.length;
        });

        if (!displayedPlanAddons?.length) {
            return "None Selected";
        }

        return map(displayedPlanAddons, (addOn, index) => {
            return (
                <CheckboxInput
                    key={`${props.patient.patientId}-${addOn.serviceTypeId}-${index}`}
                    label={`${addOn.serviceTypeName} - ${formatUSD(addOn.monthlyAddOnCostToClient || 0)}`}
                    name={addOn.clinicPlanServiceTypeId.toString()}
                    checked={includes(planAddOnIds, addOn.clinicPlanServiceTypeId.toString())}
                    onChange={handleAddOnsChanged}
                    large
                />
            );
        });
    };

    const renderPatientPlans = () => {
        return (
            <div className={styles.planOption}>
                <h2 className="flex-1">Pet Name: {props.patient.patientName}</h2>
                <div>
                    <Dropdown
                        options={planOptions}
                        name="plan"
                        value={props.plan?.clinicPlanInstanceId}
                        onChange={handleChangePlan}
                        placeholder="Select Plan"
                        required
                        large
                        disabled={false}
                    />
                    { !!props.plan?.clinicPlanInstanceId && (
                        <>
                            <div className="flex spaced-content">
                                <div className="flex-1">
                                    <h3 className="margin-bottom-x-sm margin-top-sm">Services</h3>
                                    <div className="margin-top-x-sm">
                                        {renderServicesByType(props.plan?.services, ["Services", "Special"])}
                                    </div>
                                </div>
                                <div className="flex-1">
                                    <h3 className="margin-bottom-x-sm margin-top-sm">Products</h3>
                                    <div className="margin-top-x-sm">
                                        {renderServicesByType(props.plan?.services, ["Products"])}
                                    </div>
                                </div>
                            </div>
                            <h3 className="margin-bottom-x-sm margin-top-sm">Add-Ons Selection</h3>
                            {renderDisplayedPlanAddOns(props.plan?.services)}
                        </>
                    )}
                </div>
            </div>
        )
    };

    const handleChangePlan = ({name, value}) => {
        const newPlan = find(props.allPlans.plans, {'clinicPlanInstanceId': value});

        props.onUpdateAddOns(null);
        props.onChange(newPlan);
    };

    const handleAddOnsChanged = ({name, value}) => {
        if (value === true) {
            props.onUpdateAddOns(produce(props.addOns, (draft) => {
                draft[name] = props.plan?.services[name];
                return draft;
            }));
        } else {
            props.onUpdateAddOns(produce(props.addOns, (draft) => {
                delete draft[name];
                return draft;
            }));
        }
    };

    return (
        <div className={styles.plans}>
            {renderPatientPlans()}
        </div>
    );
}

PlanOptionDetails.defaultProps = {
    includeInvalid: false,
};

PlanOptionDetails.propTypes = {
    allPlans: PropTypes.object.isRequired,
    addOnsSelected: PropTypes.object,
    patient: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    plan: PropTypes.object.isRequired,
    addOns: PropTypes.object.isRequired,
    onUpdateAddOns: PropTypes.func.isRequired,
    includeInvalid: PropTypes.bool,
};
