import React, { useEffect } from "react";
import PropTypes from "prop-types";

function PuppyBowlClinicScore(props) {

    const loadHtml = () => ({
        __html: `
            <iframe
                id="puppy-bowl-points-gained-iframe"
                src="${props.pathToSrcFiles}index.html"
                width="100%"
                height="100%"
                style="border-width: 0; padding: 0;"
                
            />
        `
    });

    const waitForIframeHtmlToLoad = () => {
        const intervalId = setInterval(() => {
            if (!!window.PuppyBowlPointsGained) {
                // Js has loaded
                new window.PuppyBowlPointsGained(props.data);
                clearInterval(intervalId);
            }
        }, 200);
    };

    useEffect(() => {
        waitForIframeHtmlToLoad();
    }, []);

    return (
        <div dangerouslySetInnerHTML={loadHtml()} />
    );
}

PuppyBowlClinicScore.propTypes = {
    data: PropTypes.shape({
        totalPoints: PropTypes.number,
        touchdowns: PropTypes.number,
        fieldGoals: PropTypes.number,
        mvps: PropTypes.number,
        pointValues: PropTypes.shape({
            mvp: PropTypes.number,
            touchdown: PropTypes.number,
            fieldGoal: PropTypes.number
        }),
    }),
    pathToSrcFiles: PropTypes.string,
};

PuppyBowlClinicScore.defaultProps = {
    pathToSrcFiles: "puppy_bowl_leaderboard/",
};

export default PuppyBowlClinicScore;
