import React from "react";
import styles from "./SpinnerTakeover.scss";
import classnames from "classnames";
import { Oval } from "react-loader-spinner";
import PropTypes from "prop-types";

// See https://github.com/mhnpd/react-loader-spinner

export default function SpinnerTakeover(props) {
    if(!props.show) {
        return null;
    }

    return (
        <div
            data-testid="spinner_takeover_component"
            className={classnames(styles.root, {
                [styles.show]: props.show,
                [styles.onTop]: props.onTop,
            })}
        >
            <Oval
                visible
                width={props.width || 150}
                height={props.height || 150}
                color={props.color || "green"}
                timeout={props.timeout || 0}
            />
        </div>
    );
}

SpinnerTakeover.propTypes = {
    show: PropTypes.bool,
    onTop: PropTypes.bool,
    type: PropTypes.number,
    width: PropTypes.number,
    color: PropTypes.string,
    timeout: PropTypes.number,
}
