import React from "react";
import {
	HELMET_STRIPE,
	HELMET_HOLE,
	HELMET_BG,
	JERSEY_STRIPE,
	JERSEY_SLEEVE,
	JERSEY_BG,
	TEAM_COLORS
} from "constants/Games/PetsBowl/UniformColors";
import styles from "./KittyRunning.scss";


export default function KittyRunning(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Layer_1"
			data-name="Layer 1"
			xmlns="http://www.w3.org/2000/svg"
			// xmlns:xlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 980.49 342.45"
		>
		    <g>
		        <g className={styles.cls22}>
		            <g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_21_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  21 0 Layer2 0 FILL">
		                    <path className={styles.cls39} d="m562.62,166.8c-5-3.43-8.15-5.47-9.45-6.1-3.87-1.87-7.42-2.27-10.65-1.2-3.07,1.03-5.22,2.88-6.45,5.55-1.27,2.77-1.07,5.57.6,8.4,2.93,5.1,9.15,9.92,18.65,14.45,4.87,2.33,12.48,5.27,22.85,8.8,5.8,1.97,9.88,3.23,12.25,3.8,5.1,1.23,9.38,1.55,12.85.95,5.37-.97,8.2-3.15,8.5-6.55.27-3.4-2.17-6.35-7.3-8.85-2.03-1-5.33-1.95-9.9-2.85-4.37-.9-7.73-2.02-10.1-3.35-5.83-3.23-9.53-5.32-11.1-6.25-4.1-2.37-7.68-4.63-10.75-6.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_21_0_Layer2_1_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  21 0 Layer2 1 FILL">
		                    <path className={styles.cls35} d="m551.32,185.85c.73-3.73,1.32-6.15,1.75-7.25,1.77-4.3,5.02-8.17,9.75-11.6l-.2-.2c-5-3.43-8.15-5.47-9.45-6.1-3.87-1.87-7.42-2.27-10.65-1.2-3.07,1.03-5.22,2.88-6.45,5.55-1.27,2.77-1.07,5.57.6,8.4,2.53,4.4,7.42,8.53,14.65,12.4Z"/>
		                </g>
		                <g className={styles.cls27}>
		                    <g className={styles.cls46}>
		                        <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_0_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  0 0 Layer2 0 FILL">
		                            <path className={styles.cls32} d="m818.72,327.1c0-4.27-11.32-7.9-33.95-10.9-22.67-3-50.02-4.5-82.05-4.5s-59.37,1.5-82,4.5c-22.67,3.03-34,6.67-34,10.9s11.33,7.85,34,10.85c22.67,3,50,4.5,82,4.5s59.38-1.5,82.05-4.5c22.63-3,33.95-6.62,33.95-10.85Z"/>
		                        </g>
		                    </g>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_21_0_Layer2_3_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  21 0 Layer2 3 FILL">
		                    <path className={styles.cls38} d="m778.77,250.3c2.1-4.17,3.38-6.75,3.85-7.75,2.87-6.37,3.78-11.7,2.75-16-1.5-6.17-5.43-9.48-11.8-9.95-5.67-.43-11,1.27-16,5.1-2.57,1.93-4.82,4.95-6.75,9.05l-4.85,10.25c-5.23,7.93-10.08,16.23-14.55,24.9-8.83,17.17-12.25,29.2-10.25,36.1,2.63,8.97,6.78,12.85,12.45,11.65,4.73-.97,10.35-5.38,16.85-13.25,5.23-6.33,10.48-14.18,15.75-23.55,4.53-8.03,7.62-14.43,9.25-19.2.63-1.83,1.73-4.28,3.3-7.35Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_21_0_Layer2_4_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  21 0 Layer2 4 FILL">
		                    <path className={styles.cls38} d="m677.97,244.65c-.33-4.63-1.18-8.68-2.55-12.15-3.5-8.93-6.62-15.63-9.35-20.1-5-8.07-10.37-12.52-16.1-13.35-7.9-1.2-14.35.38-19.35,4.75-4.3,3.8-7.27,9.43-8.9,16.9-1.47,6.57-1.75,13.68-.85,21.35.83,7.27,2.5,13.4,5,18.4,1.9,3.87,5.78,7.85,11.65,11.95,7.6,5.3,12.12,8.68,13.55,10.15.5.5,2.83,2.97,7,7.4,4.9,5.17,9.02,9.02,12.35,11.55,5.3,4.03,10.65,6.53,16.05,7.5,4.17.77,7.48.55,9.95-.65,2.97-1.47,4.57-4.32,4.8-8.55.23-3.9-1.23-7.47-4.4-10.7-4.17-3.4-7.2-6-9.1-7.8l-1.55-1.65c-4.83-5.53-7.43-13.2-7.8-23-.07-5.3-.2-9.3-.4-12Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_21_0_Layer2_5_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  21 0 Layer2 5 FILL">
		                    <path className={styles.cls41} d="m767.42,196.85c-8.27-11.67-19.17-19.93-32.7-24.8-8.97-3.23-22.67-5.72-41.1-7.45-8.07-.77-16.65-.53-25.75.7-5.23.7-13.72,2.28-25.45,4.75-14.83,3.2-26.65,8.53-35.45,16-10.3,8.73-16.18,20.15-17.65,34.25-.6,5.67.08,11.3,2.05,16.9,1.9,5.33,4.75,10.1,8.55,14.3,3.87,4.2,8.25,7.33,13.15,9.4,5.23,2.17,10.53,2.88,15.9,2.15,24.43-3.3,47.02-5.3,67.75-6,3.53-.1,12.67.47,27.4,1.7,11.9,1,21.02.88,27.35-.35,1.3-.27,2.53-.57,3.7-.9,5.53-1.63,10-4.97,13.4-10,3.07-4.53,5.03-10.05,5.9-16.55.83-6.1.62-12.25-.65-18.45-1.3-6.2-3.43-11.42-6.4-15.65Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_21_0_Layer2_6_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  21 0 Layer2 6 FILL">
		                    <path className={styles.cls34} d="m767.87,248.45c3.03-4.1,5.1-9.13,6.2-15.1l-10.85,4.9c-4.13,1.97-8.63,3.28-13.5,3.95-2.23.3-6.97.6-14.2.9-8.93.53-15.68.88-20.25,1.05-7.97.3-14.75.2-20.35-.3-3.57-.3-9.52-1.2-17.85-2.7-7.63-1.33-13.67-2.12-18.1-2.35-4.83-.27-10.73.08-17.7,1.05-7.77,1.23-13.6,2.07-17.5,2.5-10.03,1.13-20.45.35-31.25-2.35,3.27,7.5,8.18,13.42,14.75,17.75,6.87,4.53,14.1,6.3,21.7,5.3,24.43-3.3,47.02-5.3,67.75-6,3.53-.1,12.67.47,27.4,1.7,11.9,1,21.02.88,27.35-.35,1.3-.27,2.53-.57,3.7-.9,5.17-1.53,9.4-4.55,12.7-9.05Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_21_0_Layer2_7_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  21 0 Layer2 7 FILL">
		                    <path className={styles.cls39} d="m729.32,191.4c-.4-.97-1.57-4.35-3.5-10.15-1.97-5.93-3.5-10.25-4.6-12.95-6.63-1.37-15.83-2.6-27.6-3.7-8.07-.77-16.65-.53-25.75.7-5.23.7-13.72,2.28-25.45,4.75-9.5,2.07-17.85,5.03-25.05,8.9-3.93,2.63-6.15,5.93-6.65,9.9-.43,3.5.47,7.22,2.7,11.15,2.03,3.6,4.85,6.82,8.45,9.65,3.57,2.8,7.12,4.58,10.65,5.35,2.3.5,7.35.73,15.15.7,7.67-.07,12.7.2,15.1.8,7.6,1.93,13.87,4.6,18.8,8,5.9,4.03,12.88,6.97,20.95,8.8,8.6,2,15.78,2.03,21.55.1,7.53-2.5,11.15-8.8,10.85-18.9-.2-6.9-2.07-14.6-5.6-23.1Z"/>
		                </g>
		                <g className={styles.cls24}>
		                    <g className={styles.cls19}>
		                        <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_1_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  1 0 Layer2 0 FILL">
		                            <path className={styles.cls32} d="m773.12,209.6c-1.33-5-3.23-9.25-5.7-12.75-7.67-10.83-17.53-18.7-29.6-23.6-8.83-3.6-21.1-6.23-36.8-7.9,2,7.4,4.57,13.95,7.7,19.65,3.73,6.73,8.45,12.62,14.15,17.65,4.73,4.2,10.75,8.17,18.05,11.9,7.27,3.7,13.92,6.2,19.95,7.5,5.4,1.17,10.08,2.1,14.05,2.8.1-5.27-.5-10.35-1.8-15.25Z"/>
		                        </g>
		                    </g>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_21_0_Layer2_9_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  21 0 Layer2 9 FILL">
		                    <path className={styles.cls40} d="m755.27,250.75c3.17-5.8,5.57-11.08,7.2-15.85,2.47-7.07,3.13-12.6,2-16.6-1.43-5.23-5.28-7.48-11.55-6.75-4.73.57-9.82,2.55-15.25,5.95-8.77,5.47-17.4,14.08-25.9,25.85-6.53,9.07-12.72,19.62-18.55,31.65-1.2,2.53-3.12,5.82-5.75,9.85-3.03,4.63-5.05,7.87-6.05,9.7-2.67,4.93-4.4,8.92-5.2,11.95-1.3,5-.83,9.08,1.4,12.25,3.07,4.33,6.82,7.45,11.25,9.35,4.9,2.07,9.68,2.15,14.35.25,1.5-.6,2.7-1.43,3.6-2.5,1.03-1.2,1.45-2.47,1.25-3.8-.07-.37-.37-1.2-.9-2.5-1.93-4.93.22-10.87,6.45-17.8,3.4-3.77,8.4-8.57,15-14.4,10.67-11.4,19.55-23.6,26.65-36.6Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_21_0_Layer2_10_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  21 0 Layer2 10 FILL">
		                    <path className={styles.cls40} d="m634.77,189.3c-9.17-3.57-17.23-3.67-24.2-.3-6.03,2.9-10.92,8.2-14.65,15.9-3.3,6.77-5.32,14.5-6.05,23.2-.73,8.2-.2,15.4,1.6,21.6,1.37,4.8,5.07,10.27,11.1,16.4,7.83,8,12.43,13.02,13.8,15.05.23.37,2.43,3.73,6.6,10.1,4.6,7.07,8.58,12.45,11.95,16.15,5.37,5.93,11.15,10.2,17.35,12.8,4.8,2.03,8.82,2.75,12.05,2.15,3.9-.73,6.5-3.4,7.8-8,1.2-4.17.28-8.5-2.75-13-4.17-4.93-7.17-8.65-9-11.15-.5-.63-1-1.37-1.5-2.2-2.7-4.5-4.27-9.22-4.7-14.15-.33-3.63-.05-8.07.85-13.3,1.2-5.87,2-10.3,2.4-13.3.7-5.17.65-9.85-.15-14.05-2.07-10.8-4.2-19.02-6.4-24.65-4.07-10.27-9.43-16.68-16.1-19.25Z"/>
		                </g>
		            </g>
		        </g>
		        <g className={styles.cls23}>
		            <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_20_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  20 0 Layer2 0 FILL">
		                <path
			                className={styles.cls45}
			                d="m785.97,239.45c-9.17,2.33-19.07,5.93-29.7,10.8-2.2,1-3.5,1.27-3.9.8-.37-.43-.38-2.15-.05-5.15-4.43,2.07-8.6,5.55-12.5,10.45-3.8,4.7-6.37,9.47-7.7,14.3,2.83,4.13,7.72,7.97,14.65,11.5,8.2,4.17,15.47,5.43,21.8,3.8,1.13-.77,3.88-6.5,8.25-17.2,4.9-12,7.95-21.77,9.15-29.3Z"
			                style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                />
		            </g>
		        </g>
		        <g className={styles.cls26}>
		            <g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_19_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  19 0 Layer2 0 FILL">
		                    <path
			                    className={styles.cls44}
			                    d="m734.22,264.35l-2.1,2.15c6.27,6.03,12.52,10.4,18.75,13.1,6.27,2.7,12.78,3.82,19.55,3.35.13-.23.38-.77.75-1.6.37-.83.57-1.33.6-1.5-6.67.67-13.05-.27-19.15-2.8-6.13-2.53-12.27-6.77-18.4-12.7Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_19_0_Layer2_1_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  19 0 Layer2 1 FILL">
		                    <path
			                    className={styles.cls44}
			                    d="m736.52,257.9l-2.1,2.15c6.33,6.1,12.65,10.48,18.95,13.15,6.33,2.7,12.93,3.78,19.8,3.25.3-.57.72-1.57,1.25-3-6.73.8-13.18-.08-19.35-2.65-6.07-2.53-12.25-6.83-18.55-12.9Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		            </g>
		        </g>
		        <g className={styles.cls25}>
		            <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_18_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  18 0 Layer2 0 FILL">
		                <path
			                className={styles.cls43}
			                d="m715.97,186.05c-1.67-6.67-1.6-12.82.2-18.45-11.27-4.2-23.98-5.7-38.15-4.5-9.53.83-21,3.13-34.4,6.9-5.5,17.9-6.13,34.67-1.9,50.3,4.4,16.33,13.9,30.55,28.5,42.65,9.63-3,20.7-4.18,33.2-3.55,11.77.63,25.13,2.9,40.1,6.8,8.77,2.27,17.87-.28,27.3-7.65,8.9-7,15.58-16.17,20.05-27.5l-5.6-.55c-.63-.07-2.13,2.08-4.5,6.45-2.97,5.53-5.17,9.22-6.6,11.05.4-3.83.48-8.4.25-13.7-.23-5.23-.62-7.87-1.15-7.9-20.23-.47-36.07-7.62-47.5-21.45-4.83-5.83-8.1-12.13-9.8-18.9Z"
			                style={{ fill: `${teamColors[JERSEY_BG]}` }}
		                />
		            </g>
		        </g>
		        <g className={styles.cls29}>
		            <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_17_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  17 0 Layer2 0 FILL">
		                <path
			                className={styles.cls45}
			                d="m757.82,243.8c-.63-5.17-2.72-9.63-6.25-13.4-3.47-3.73-7.48-5.9-12.05-6.5-4.93-.63-9.48.77-13.65,4.2-3.6,2.77-8.77,7.92-15.5,15.45-7.3,8.2-12.68,15.15-16.15,20.85,1.83,8.2,6.53,15.73,14.1,22.6,7.43,6.73,15.17,10.72,23.2,11.95,3.13-6.17,6.68-12.68,10.65-19.55,4.7-8.17,8.65-14.5,11.85-19,3.27-5.37,4.53-10.9,3.8-16.6Z"
			                style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                />
		            </g>
		        </g>
		        <g className={styles.cls28}>
		            <g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_16_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  16 0 Layer2 0 FILL">
		                    <path
			                    className={styles.cls44}
			                    d="m710.02,281.65c6,5.5,13.72,10.1,23.15,13.8.2-.17.72-1.12,1.55-2.85-9.43-3.63-17.05-8.12-22.85-13.45-5.97-5.5-10.48-12.38-13.55-20.65l-2.2,2.85c3.3,8,7.93,14.77,13.9,20.3Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_16_0_Layer2_1_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  16 0 Layer2 1 FILL">
		                    <path
			                    className={styles.cls44}
			                    d="m713.57,276c5.7,5.4,13.28,9.95,22.75,13.65.13-.1.43-.53.9-1.3.47-.8.77-1.27.9-1.4-9.77-3.67-17.42-8.15-22.95-13.45-5.43-5.2-9.7-12.02-12.8-20.45-.07.13-.8,1.05-2.2,2.75,3.37,8.2,7.83,14.93,13.4,20.2Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_16_0_Layer2_2_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  16 0 Layer2 2 FILL">
		                    <path
			                    className={styles.cls44}
			                    d="m661.42,165.55c-2.47.4-4.55.87-6.25,1.4-5.03,15.47-5.08,32.2-.15,50.2,4.73,17.2,12.82,31.72,24.25,43.55.83-.17,3.23-.5,7.2-1-10.47-9.27-18.45-22.93-23.95-41-5.97-19.7-6.33-37.42-1.1-53.15Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <path
			                id="FlashAICB_Assets_0_Layer_1_Clip_Group_16_0_Layer2_3_1_STROKES"
			                data-name="FlashAICB Assets 0 Layer 1  Clip Group  16 0 Layer2 3 1 STROKES"
	                        className={styles.cls2}
	                        d="m714.82,166.7c-4.03,15-1.17,27.9,8.6,38.7,9.6,10.6,24.95,18.13,46.05,22.6,1.9.87,3.35,3.67,4.35,8.4.87,4.23,1.07,7.83.6,10.8,2.97-3.4,5.28-7.5,6.95-12.3,1.23-3.57,2.38-8.53,3.45-14.9"
			                style={{ stroke: `${teamColors[JERSEY_STRIPE]}` }}
		                />
		            </g>
		        </g>
		        <g className={styles.cls30}>
		            <g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 0 FILL">
		                    <path className={styles.cls39} d="m877.17,175.85c7.03-5.13,15.82-8.6,26.35-10.4,8.93-1.5,18.8-1.77,29.6-.8.47.07.73-.15.8-.65.03-.47-.2-.73-.7-.8-11.03-1-21.08-.73-30.15.8-10.77,1.83-19.73,5.38-26.9,10.65-.37.3-.38.65-.05,1.05.33.37.68.42,1.05.15Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_1_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 1 FILL">
		                    <path className={styles.cls39} d="m876.22,187.15c.1.47.38.67.85.6,17.9-2.6,37.37-1.4,58.4,3.6.47.13.72-.05.75-.55.03-.47-.18-.77-.65-.9-21.3-5.03-40.9-6.23-58.8-3.6-.47.07-.65.35-.55.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_2_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 2 FILL">
		                    <path className={styles.cls39} d="m878.42,195.8c9.77,1.43,18.3,3.05,25.6,4.85,9.1,2.23,17.4,5,24.9,8.3.47.2.78.08.95-.35.17-.47.02-.8-.45-1-7.53-3.33-15.88-6.13-25.05-8.4-7.43-1.8-16.05-3.42-25.85-4.85-.5-.07-.77.13-.8.6-.03.5.2.78.7.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_3_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 3 FILL">
		                    <path className={styles.cls33} d="m818.17,45.35c-6.33-5.73-11.77-9.48-16.3-11.25-.67-.27-2.63,12.08-5.9,37.05-2.2,16.87-3.33,26.45-3.4,28.75-.1,2.67-.1,4.2,0,4.6.13.87.77,2.25,1.9,4.15.8,1.37,4.53,2.85,11.2,4.45,6.63,1.6,14.08,2.83,22.35,3.7,21.2,2.27,34.07,1.03,38.6-3.7-3.37-8.3-8.53-17.9-15.5-28.8-6.9-10.7-13.12-18.93-18.65-24.7-6.63-6.87-11.4-11.62-14.3-14.25Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_4_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 4 FILL">
		                    <path className={styles.cls41} d="m881.92,135.4c-1.87-7.1-4.03-13.08-6.5-17.95-18.9-1.27-36.75-6.63-53.55-16.1-10.57-5.93-19.73-12.83-27.5-20.7-6.93.1-13.43.6-19.5,1.5,13.4,7.5,20.07,15.42,20,23.75-.1,12.87-17,25.08-50.7,36.65-3.03.77-6.82,1.55-11.35,2.35-9.07,1.57-17.38,2.38-24.95,2.45l.2,3c1.13,12.33,3.6,23.17,7.4,32.5,4.9,12.13,12.13,22.12,21.7,29.95,4.87,3.97,12.02,7.9,21.45,11.8,8.8,3.63,16.55,6.03,23.25,7.2,10.33,1.8,20.32,2.82,29.95,3.05,11.03.27,20.97-.52,29.8-2.35,2.63-.53,6.92-1.4,12.85-2.6,5.33-1.27,9.9-2.85,13.7-4.75,10.53-5.23,18.02-14.18,22.45-26.85,3.63-10.43,4.42-19.18,2.35-26.25-.5-1.63-1.65-4.48-3.45-8.55-1.6-3.6-2.68-6.7-3.25-9.3-2.27-10.17-3.72-16.43-4.35-18.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_5_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 5 FILL">
		                    <path className={styles.cls35} d="m774.87,82.15c-9.03-2.97-17.2-3.22-24.5-.75-5,1.67-10.13,4.93-15.4,9.8-9,8.23-15.63,16.28-19.9,24.15-5.33,9.83-7.73,20.5-7.2,32,7.57-.07,15.88-.88,24.95-2.45,4.53-.8,8.32-1.58,11.35-2.35,33.7-11.57,50.6-23.78,50.7-36.65.07-8.33-6.6-16.25-20-23.75Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_6_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 6 FILL">
		                    <path className={styles.cls39} d="m875.42,117.45l-1.1-2.1c-3.1-5.57-8.5-10.93-16.2-16.1-5.47-3.67-12.3-7.33-20.5-11-5.6-2.5-12.15-4.42-19.65-5.75-7.47-1.27-15.33-1.88-23.6-1.85,7.77,7.87,16.93,14.77,27.5,20.7,16.8,9.47,34.65,14.83,53.55,16.1Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_7_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 7 FILL">
		                    <path className={styles.cls42} d="m774.27,135.95c-4.27,4.83-5.83,10.33-4.7,16.5,1.13,6.17,4.63,11.2,10.5,15.1,5.9,3.9,12.45,5.52,19.65,4.85,7.17-.67,12.9-3.42,17.2-8.25,4.3-4.83,5.88-10.32,4.75-16.45-1.13-6.17-4.65-11.18-10.55-15.05-5.9-3.9-12.43-5.53-19.6-4.9-7.2.67-12.95,3.4-17.25,8.2Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_8_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 8 FILL">
		                    <path className={styles.cls42} d="m875.77,141.9c-1.27-4.83-3.55-8.85-6.85-12.05-3.3-3.23-6.63-4.7-10-4.4-3.37.3-5.8,2.27-7.3,5.9-1.47,3.63-1.57,7.87-.3,12.7,1.27,4.87,3.55,8.9,6.85,12.1,3.27,3.23,6.58,4.7,9.95,4.4,3.4-.3,5.85-2.27,7.35-5.9,1.47-3.63,1.57-7.88.3-12.75Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_9_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 9 FILL">
		                    <path className={styles.cls39} d="m762.27,186.1c-.47.13-.63.42-.5.85.13.43.43.58.9.45,10.33-2.67,19.98-4.03,28.95-4.1,10.67-.1,19.95,1.63,27.85,5.2.43.17.77.05,1-.35.27-.43.18-.73-.25-.9-8.07-3.63-17.53-5.4-28.4-5.3-9.17.07-19.02,1.45-29.55,4.15Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_10_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 10 FILL">
		                    <path className={styles.cls39} d="m821.97,199.3c.5-.03.75-.27.75-.7,0-.47-.23-.7-.7-.7-18.03.57-36.9,4.87-56.6,12.9-.47.17-.62.48-.45.95.13.43.42.55.85.35,19.5-7.97,38.22-12.23,56.15-12.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_11_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 11 FILL">
		                    <path className={styles.cls39} d="m798.37,215.4c6.77-2.83,14.75-5.68,23.95-8.55.47-.13.63-.43.5-.9-.1-.43-.38-.58-.85-.45-9.33,2.93-17.4,5.83-24.2,8.7-8.5,3.57-16.08,7.47-22.75,11.7-.4.27-.47.6-.2,1,.23.37.55.42.95.15,6.63-4.23,14.17-8.12,22.6-11.65Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_12_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 12 FILL">
		                    <path className={styles.cls32} d="m816.37,147.65c-1.03-5.53-3.18-10-6.45-13.4-3.27-3.4-6.67-4.73-10.2-4s-6.18,3.3-7.95,7.7c-1.77,4.4-2.13,9.37-1.1,14.9,1.07,5.53,3.23,10,6.5,13.4,3.23,3.4,6.63,4.73,10.2,4,3.57-.7,6.22-3.27,7.95-7.7,1.77-4.4,2.12-9.37,1.05-14.9Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_13_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 13 FILL">
		                    <path className={styles.cls40} d="m801.62,138.35c-.87,2.23-1.03,4.73-.5,7.5.53,2.77,1.6,5,3.2,6.7,1.63,1.7,3.32,2.37,5.05,2,1.73-.33,3.03-1.62,3.9-3.85.87-2.2,1.03-4.68.5-7.45-.53-2.77-1.6-5-3.2-6.7s-3.28-2.38-5.05-2.05c-1.73.37-3.03,1.65-3.9,3.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_14_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 14 FILL">
		                    <path className={styles.cls32} d="m873.42,142.1c-.83-4.63-2.32-8.42-4.45-11.35-2.1-2.9-4.23-4.13-6.4-3.7-2.17.47-3.7,2.5-4.6,6.1-.93,3.6-.98,7.72-.15,12.35.83,4.63,2.3,8.42,4.4,11.35,2.13,2.93,4.27,4.17,6.4,3.7,2.17-.47,3.72-2.5,4.65-6.1.93-3.6.98-7.72.15-12.35Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_15_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 15 FILL">
		                    <path className={styles.cls40} d="m871.62,138.35c-.4-2.3-1.12-4.18-2.15-5.65-1.07-1.5-2.13-2.13-3.2-1.9-1.07.23-1.82,1.25-2.25,3.05-.47,1.8-.48,3.87-.05,6.2.4,2.3,1.12,4.18,2.15,5.65,1.07,1.5,2.13,2.13,3.2,1.9,1.07-.23,1.82-1.25,2.25-3.05.47-1.8.48-3.87.05-6.2Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_16_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 16 FILL">
		                    <path className={styles.cls39} d="m876.57,188.2c0-.9-.05-1.55-.15-1.95-.17-.77-.55-1.22-1.15-1.35-.33-.07-.72.1-1.15.5-.43.4-.65.77-.65,1.1.03.33.22.85.55,1.55.2.9.27,2.07.2,3.5-.03.8-.12,1.97-.25,3.5-.17,4.87-2.12,8.22-5.85,10.05-1.77.87-3.77,1.22-6,1.05-2.27-.17-4.18-.8-5.75-1.9-1.3-.9-2.45-2.43-3.45-4.6l-.75-2.15c-.5-1.13-.97-1.67-1.4-1.6-.97.27-1.02,1.45-.15,3.55.6,1.43,1.3,2.68,2.1,3.75,2.7,3.5,6.35,5.15,10.95,4.95,1.9-.07,3.67-.5,5.3-1.3,3.67-1.77,5.9-4.62,6.7-8.55-.03.67.18,1.4.65,2.2.3.47.77,1.1,1.4,1.9,1.13,1.57,2.38,2.57,3.75,3,1.73.57,3.28.12,4.65-1.35,1.13-1.23,1.87-2.77,2.2-4.6.23-1.17.35-1.85.35-2.05.07-1-.18-1.55-.75-1.65-.33-.03-.58.37-.75,1.2-.1.4-.17.88-.2,1.45-.13,1.5-.52,2.63-1.15,3.4-.77.93-1.73,1.38-2.9,1.35-1.1,0-2.12-.4-3.05-1.2-1.07-.93-1.83-2.33-2.3-4.2-.23-.83-.48-2.43-.75-4.8-.2-2.17-.28-3.75-.25-4.75Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_15_0_Layer2_17_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  15 0 Layer2 17 FILL">
		                    <path className={styles.cls37} d="m883.17,172.3c-1.4-.77-2.88-1.07-4.45-.9-.67.07-1.53.23-2.6.5-1.43.33-2.33.53-2.7.6-.27.07-2.03.25-5.3.55-2.27.23-3.88.63-4.85,1.2-2.63,1.57-3.08,3.57-1.35,6,1.43,2.03,3.73,3.75,6.9,5.15,3.43,1.57,7.08,1.92,10.95,1.05,4.4-1.03,6.75-3.5,7.05-7.4.2-2.9-1.02-5.15-3.65-6.75Z"/>
		                </g>
		            </g>
		        </g>
		        <g className={styles.cls31}>
		            <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_14_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  14 0 Layer2 0 FILL">
		                <path
			                className={styles.cls43}
			                d="m864.92,92.5c-6.33-9.2-15.35-16.32-27.05-21.35-18.83-8.1-38.17-10.12-58-6.05-13.13,2.7-25.6,7.78-37.4,15.25,19.5-.7,29.75,3.82,30.75,13.55.43,4.07-.85,8.73-3.85,14-2.73,4.87-6.6,9.73-11.6,14.6-4.87,4.73-10.07,8.75-15.6,12.05-5.77,3.43-11.03,5.57-15.8,6.4-11.43,1.93-17.13-3.68-17.1-16.85-3.1,13-2.85,28.5.75,46.5,3.33,16.6,9.77,29.37,19.3,38.3,3.9,3.67,7.95,6.25,12.15,7.75,3.9,1.43,7.38,1.77,10.45,1,2.7-.67,4.65-3.03,5.85-7.1.93-3.13,1.58-8.05,1.95-14.75.33-8.2.67-14.53,1-19,.53-7.83,1.48-14.47,2.85-19.9,1.43-7.73,6.33-14.6,14.7-20.6,7.57-5.47,17.37-9.8,29.4-13,11.33-3.03,23.2-4.72,35.6-5.05,12.43-.33,23.5.77,33.2,3.3-2.57-11.93-6.42-21.62-11.55-29.05Z"
			                style={{ fill: `${teamColors[HELMET_BG]}`}}
		                />
		            </g>
		        </g>
		        <g className={styles.cls18}>
		            <path
			            id="FlashAICB_Assets_0_Layer_1_Clip_Group_13_0_Layer2_0_1_STROKES"
			            data-name="FlashAICB Assets 0 Layer 1  Clip Group  13 0 Layer2 0 1 STROKES"
	                    className={styles.cls1}
	                    d="m724.27,95c7.57-7.37,15.52-12.13,23.85-14.3,8.33-2.17,14.62-1.08,18.85,3.25,4.2,4.33,5.12,10.63,2.75,18.9-2.37,8.3-7.33,16.12-14.9,23.45-7.57,7.37-15.52,12.13-23.85,14.3-8.33,2.17-14.6,1.08-18.8-3.25-4.23-4.33-5.17-10.63-2.8-18.9,2.37-8.27,7.33-16.08,14.9-23.45Z"
	                    style={{ stroke: `${teamColors[HELMET_HOLE]}` }}
		            />
		        </g>
		        <g className={styles.cls16}>
		            <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_12_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  12 0 Layer2 0 FILL">
		                <path
			                className={styles.cls45}
			                d="m807.47,63.7c-3.43-.77-7.85-.97-13.25-.6-4.07.3-7.87.83-11.4,1.6,7.63,4.7,14.8,12.17,21.5,22.4,7.07,10.83,12.02,22.45,14.85,34.85l15.35-.2c-1.07-14.73-3.27-26.38-6.6-34.95-4.4-11.33-11.22-19.03-20.45-23.1Z"
			                style={{ fill: `${teamColors[HELMET_STRIPE]}` }}
		                />
		            </g>
		        </g>
		        <g className={styles.cls13}>
		            <g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_11_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  11 0 Layer2 0 FILL">
		                    <path className={styles.cls36} d="m882.47,116.8c-.8-1.17-1.87-1.88-3.2-2.15-21.17-4.1-42.55-4.17-64.15-.2-18.33,3.37-31.88,8.37-40.65,15-6.67,5.1-12.38,17.63-17.15,37.6l-.65-.4c-.83-.6-1.78-.87-2.85-.8-1.03.07-1.93.45-2.7,1.15-5.37,4.9-7.17,13.03-5.4,24.4.17.87.52,1.62,1.05,2.25,11.87,13.77,23.48,25.38,34.85,34.85,11.33,9.43,21.08,15.63,29.25,18.6,6.33,2.3,15.48,3.98,27.45,5.05,8.47.73,16.08,1.07,22.85,1,9.6-.1,18.25-1.33,25.95-3.7,7.73-2.37,14.02-5.73,18.85-10.1.93-.83,1.43-1.87,1.5-3.1.07-1.23-.32-2.32-1.15-3.25-.83-1-1.93-1.5-3.3-1.5,1.93-14.97,5.33-28.68,10.2-41.15l.2.1c1.07.63,2.2.8,3.4.5,1.2-.33,2.12-1.03,2.75-2.1.63-1.07.8-2.2.5-3.4-.33-1.2-1.03-2.12-2.1-2.75-13.7-8.03-26.92-11.25-39.65-9.65-12.77,1.57-24.92,7.93-36.45,19.1-13.77,2.73-28,1.73-42.7-3-10.33-3.3-21.32-8.7-32.95-16.2,2.53-11.4,5.28-20.27,8.25-26.6,2.03-4.43,4.05-7.4,6.05-8.9,7.97-6.07,20.68-10.57,38.15-13.5,19.97-3.37,39.52-3.18,58.65.55,1.37.27,2.63.02,3.8-.75,1.13-.77,1.83-1.83,2.1-3.2.27-1.37.02-2.62-.75-3.75m-4.4,65.4c9.23-1.43,18.87.18,28.9,4.85-5.77,14.57-9.62,30.88-11.55,48.95-8.1,5-18.82,7.7-32.15,8.1.3-17.23-3.73-33.33-12.1-48.3,8.7-7.63,17.67-12.17,26.9-13.6m-122.85-5.7c9.27,6.23,17.55,11.12,24.85,14.65,11.27,5.47,22.3,8.98,33.1,10.55l-3.6,35.05c-7.3-3.53-15.8-9.52-25.5-17.95-9.73-8.47-19.62-18.48-29.65-30.05-.63-5.27-.37-9.35.8-12.25m89.8,24.2l.7-.25c7.33,13.6,10.85,28.15,10.55,43.65-6.63-.13-13.27-.53-19.9-1.2-8.37-.87-15.05-2.03-20.05-3.5l3.8-36.95c8.23.63,16.53.05,24.9-1.75Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_11_0_Layer2_1_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  11 0 Layer2 1 FILL">
		                    <path className={styles.cls35} d="m744.12,77.95c-3.6-.8-12.42-2.23-26.45-4.3-9.53-1.4-16.23-2.25-20.1-2.55-8.57-.63-15.2-.22-19.9,1.25-.63.2,5.55,11.58,18.55,34.15,8.7,15.03,13.82,23.63,15.35,25.8,1.63,2.27,2.58,3.52,2.85,3.75.67.67,2.03,1.42,4.1,2.25,1.43.57,4.78-1.05,10.05-4.85,5.03-3.63,10.5-8.3,16.4-14,14.7-14.27,22.38-24.88,23.05-31.85-5.33-4.1-13.3-7.32-23.9-9.65Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_11_0_Layer2_2_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  11 0 Layer2 2 FILL">
		                    <path className={styles.cls37} d="m751.12,98.5c-5.37-2.47-11.88-4.98-19.55-7.55-15.3-5.1-28.65-7.82-40.05-8.15l27.9,45.15,31.7-29.45Z"/>
		                </g>
		            </g>
		        </g>
		    </g>
		    <g>
		        <g className={styles.cls11}>
		            <g>
		                <g className={styles.cls10}>
		                    <g className={styles.cls20}>
		                        <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_2_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  2 0 Layer2 0 FILL">
		                            <path className={styles.cls32} d="m335.51,327.1c0-4.23-11.33-7.85-34-10.85-22.67-3-50-4.5-82-4.5s-59.38,1.5-82.05,4.5c-22.67,3-34,6.62-34,10.85s11.33,7.85,34,10.85c22.63,3,49.98,4.5,82.05,4.5,32.03,0,59.37-1.5,82-4.5,22.67-3,34-6.62,34-10.85Z"/>
		                        </g>
		                    </g>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_1_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 1 FILL">
		                    <path className={styles.cls39} d="m67.66,147.5c-4.07-1.37-7.65-1.32-10.75.15-2.9,1.4-4.78,3.5-5.65,6.3-.9,2.9-.37,5.65,1.6,8.25,3.57,4.7,10.32,8.72,20.25,12.05,5.13,1.73,13.07,3.72,23.8,5.95,6.1,1.27,10.3,2.03,12.6,2.3,5.2.6,9.48.38,12.85-.65,5.23-1.6,7.78-4.12,7.65-7.55-.13-3.43-2.92-6.07-8.35-7.9-2.13-.73-5.53-1.27-10.2-1.6-4.4-.33-7.87-1.02-10.4-2.05-6-2.47-9.92-4.08-11.75-4.85-4.37-1.9-8.22-3.72-11.55-5.45-5.2-2.77-8.57-4.42-10.1-4.95Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_2_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 2 FILL">
		                    <path className={styles.cls35} d="m78.01,152.6l-.25-.15c-5.2-2.77-8.57-4.42-10.1-4.95-4.07-1.37-7.65-1.32-10.75.15-2.9,1.4-4.78,3.5-5.65,6.3-.9,2.9-.37,5.65,1.6,8.25,3.03,4,8.4,7.52,16.1,10.55.13-3.47.4-5.93.8-7.4,1.2-4.4,3.95-8.65,8.25-12.75Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_3_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 3 FILL">
		                    <path className={styles.cls34} d="m186.21,197.55c-6.67-.63-13.73-.05-21.2,1.75-7.07,1.73-12.92,4.12-17.55,7.15-3.57,2.37-7.02,6.68-10.35,12.95-4.3,8.13-7.1,13-8.4,14.6l-6.45,7.8c-4.53,5.5-7.83,10.02-9.9,13.55.53,3.53,1.7,6.62,3.5,9.25,2.13,3.1,4.95,5.03,8.45,5.8,2.8.6,6.15.47,10.05-.4l1.4-1.75c4.9-5.43,12.15-8.92,21.75-10.45,2.6-.4,6.53-1.02,11.8-1.85,4.53-.87,8.43-2.18,11.7-3.95,8.47-4.6,14.68-8.48,18.65-11.65,7.37-5.87,11.13-11.7,11.3-17.5.2-7.93-2.15-14.1-7.05-18.5-4.27-3.8-10.17-6.07-17.7-6.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_4_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 4 FILL">
		                    <path className={styles.cls34} d="m106.91,272.05c-.27,4.2.35,7.45,1.85,9.75,1.8,2.77,4.8,4.02,9,3.75,3.87-.27,7.22-2.15,10.05-5.65,2.87-4.53,5.07-7.83,6.6-9.9-3.93.87-7.3,1-10.1.4-3.5-.77-6.32-2.7-8.45-5.8-1.8-2.63-2.97-5.72-3.5-9.25-3.33,5.7-5.15,11.27-5.45,16.7Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_5_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 5 FILL">
		                    <path className={styles.cls34} d="m297.01,202.1c-6.13-2.87-11.35-3.85-15.65-2.95-6.2,1.27-9.72,4.87-10.55,10.8-.73,5.33.65,10.4,4.15,15.2,1.77,2.47,4.62,4.65,8.55,6.55,6.43,3.1,9.73,4.7,9.9,4.8.27.17,2.77,1.78,7.5,4.85,5.7,3.57,11.13,6.72,16.3,9.45,16.5,8.73,28.23,12.23,35.2,10.5,9-2.23,13.08-6.03,12.25-11.4-.7-4.5-4.75-9.88-12.15-16.15-5.97-5.07-13.43-10.2-22.4-15.4-7.7-4.43-13.87-7.48-18.5-9.15-1.77-.63-4.13-1.72-7.1-3.25-4.03-2.1-6.53-3.38-7.5-3.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_6_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 6 FILL">
		                    <path className={styles.cls41} d="m290.61,196.5c-1.3-6.2-3.43-11.42-6.4-15.65-8.27-11.67-19.18-19.93-32.75-24.8-8.97-3.2-22.65-5.68-41.05-7.45-8.1-.77-16.7-.53-25.8.7-5.37.7-13.83,2.3-25.4,4.8-14.83,3.17-26.67,8.48-35.5,15.95-10.3,8.73-16.18,20.15-17.65,34.25-.6,5.67.1,11.3,2.1,16.9,1.87,5.33,4.72,10.1,8.55,14.3,3.83,4.2,8.22,7.33,13.15,9.4,5.2,2.17,10.48,2.88,15.85,2.15,25.1-3.33,47.7-5.32,67.8-5.95,3.6-.13,12.72.42,27.35,1.65,11.9,1,21.02.88,27.35-.35,1.3-.27,2.53-.57,3.7-.9,5.53-1.63,10-4.97,13.4-10,3.07-4.53,5.05-10.05,5.95-16.55.83-6.1.62-12.25-.65-18.45Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_7_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 7 FILL">
		                    <path className={styles.cls34} d="m370.86,247c-2.3-2.77-5.03-4.52-8.2-5.25-2.6-.6-5.73-.53-9.4.2-4.13.8-7.9,2.3-11.3,4.5-4.33,2.77-6.68,5.98-7.05,9.65-.33,3.33.83,6.33,3.5,9,2.2,2.2,5.05,3.85,8.55,4.95,3.33,1,6.3,1.23,8.9.7,5.9-1.23,10.68-3.18,14.35-5.85,2.97-2.17,4.47-4.95,4.5-8.35.03-3.27-1.25-6.45-3.85-9.55Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_8_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 8 FILL">
		                    <path className={styles.cls34} d="m211.66,227.85c-3.53-.3-9.47-1.2-17.8-2.7-7.63-1.33-13.67-2.12-18.1-2.35-4.83-.27-10.73.08-17.7,1.05-7.77,1.23-13.62,2.07-17.55,2.5-9.9,1.17-20.32.38-31.25-2.35,3.3,7.5,8.23,13.42,14.8,17.75,6.83,4.53,14.05,6.3,21.65,5.3,25.1-3.33,47.7-5.32,67.8-5.95,3.6-.13,12.72.42,27.35,1.65,11.9,1,21.02.88,27.35-.35,1.2-.23,2.43-.53,3.7-.9,5.17-1.53,9.42-4.55,12.75-9.05,3.03-4.1,5.1-9.13,6.2-15.1-4.9,2.17-8.52,3.8-10.85,4.9-4.17,1.97-8.68,3.28-13.55,3.95-2.23.3-6.95.6-14.15.9-8.93.53-15.68.88-20.25,1.05-8,.3-14.8.2-20.4-.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_9_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 9 FILL">
		                    <path className={styles.cls41} d="m247.41,197.8c-6.57-3.43-11.9-4.9-16-4.4-5.37.63-8.15,4.08-8.35,10.35-.13,4.73,1.07,10.02,3.6,15.85,4.03,9.43,11.2,19.2,21.5,29.3,8,7.83,17.45,15.47,28.35,22.9,1.43,1,4.57,3.62,9.4,7.85,4.77,4.17,8.6,7.22,11.5,9.15,9.7,6.5,17.05,6.55,22.05.15,5.1-6.57,4.17-14.83-2.8-24.8-2.33-3.33-5.58-7.15-9.75-11.45-4.33-4.37-7.23-7.37-8.7-9l-17.4-19.1c-6.77-7.13-13.07-12.92-18.9-17.35-5.17-3.93-10-7.08-14.5-9.45Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_10_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 10 FILL">
		                    <path className={styles.cls40} d="m324.96,267.95c-2.97-2.27-6.1-3.38-9.4-3.35-2.7.03-5.73.88-9.1,2.55-3.8,1.87-7.08,4.38-9.85,7.55-3.5,4.03-4.95,8-4.35,11.9.57,3.57,2.47,6.42,5.7,8.55,2.73,1.73,5.95,2.73,9.65,3,3.53.23,6.48-.27,8.85-1.5,2.4-1.2,4.67-2.62,6.8-4.25,2.37-1.8,4.27-3.63,5.7-5.5,2.33-3.03,3.08-6.33,2.25-9.9-.83-3.43-2.92-6.45-6.25-9.05Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_11_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 11 FILL">
		                    <path className={styles.cls41} d="m156.71,245.4c7.33-5.87,11.08-11.7,11.25-17.5.2-7.93-2.15-14.1-7.05-18.5-4.23-3.8-10.13-6.07-17.7-6.8-6.63-.63-13.7-.05-21.2,1.75-7.07,1.73-12.92,4.12-17.55,7.15-3.57,2.37-7.02,6.68-10.35,12.95-4.33,8.17-7.12,13.03-8.35,14.6-1.37,1.7-3.53,4.3-6.5,7.8-4.53,5.5-7.82,10.02-9.85,13.55.47,3.5,1.62,6.58,3.45,9.25,2.17,3.1,5,5.03,8.5,5.8,2.8.6,6.15.47,10.05-.4.6-.8,1.07-1.38,1.4-1.75,4.87-5.43,12.12-8.92,21.75-10.45,2.6-.4,6.53-1.02,11.8-1.85,4.53-.87,8.43-2.18,11.7-3.95,8.5-4.63,14.72-8.52,18.65-11.65Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_12_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 12 FILL">
		                    <path className={styles.cls40} d="m63.91,277.1c-.5,9.47,3.12,13.97,10.85,13.5,3.87-.27,7.22-2.15,10.05-5.65,2.87-4.53,5.07-7.83,6.6-9.9-3.9.87-7.25,1-10.05.4-3.5-.77-6.33-2.7-8.5-5.8-1.83-2.67-2.98-5.75-3.45-9.25-3.37,5.7-5.2,11.27-5.5,16.7Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_10_0_Layer2_13_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  10 0 Layer2 13 FILL">
		                    <path className={styles.cls39} d="m251.71,198.5c-.2-6.93-2.08-14.63-5.65-23.1-.43-1-1.6-4.38-3.5-10.15-1.97-5.97-3.48-10.27-4.55-12.9-6.03-1.27-15.23-2.52-27.6-3.75-8.1-.77-16.7-.53-25.8.7-5.37.7-13.83,2.3-25.4,4.8-9.53,2-17.88,4.97-25.05,8.9-2.67,8-2.57,15.58.3,22.75,2.93,7.33,7.87,11.75,14.8,13.25,2.3.5,7.37.73,15.2.7,7.63-.07,12.67.22,15.1.85,7.47,1.87,13.73,4.52,18.8,7.95,5.9,4.03,12.88,6.97,20.95,8.8,8.57,2,15.73,2.03,21.5.1,7.57-2.53,11.2-8.83,10.9-18.9Z"/>
		                </g>
		                <g className={styles.cls7}>
		                    <g className={styles.cls21}>
		                        <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_3_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  3 0 Layer2 0 FILL">
		                            <path
			                            className={styles.cls32}
			                            d="m289.91,193.6c-1.33-4.97-3.23-9.22-5.7-12.75-7.67-10.83-17.53-18.7-29.6-23.6-8.87-3.6-21.15-6.23-36.85-7.9,2,7.4,4.57,13.95,7.7,19.65,3.73,6.73,8.45,12.62,14.15,17.65,4.73,4.2,10.75,8.17,18.05,11.9,7.27,3.7,13.92,6.2,19.95,7.5,5.57,1.2,10.27,2.13,14.1,2.8.1-5.23-.5-10.32-1.8-15.25Z"
			                            style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                            />
		                        </g>
		                    </g>
		                </g>
		            </g>
		        </g>
		        <g className={styles.cls17}>
		            <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_9_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  9 0 Layer2 0 FILL">
		                <path
			                className={styles.cls45}
			                d="m298.56,243.4c4.1-2.8,7.9-7.7,11.4-14.7,4.1-8.23,5.3-15.53,3.6-21.9-.43-.6-2.67-1.72-6.7-3.35-8.47-3.43-13.6-5.53-15.4-6.3,3.77,14.33,3.35,22.98-1.25,25.95-1.73,1.1-4.27,1.57-7.6,1.4-4.03-.5-7.03-.85-9-1.05,2.13,4.4,5.67,8.55,10.6,12.45,4.77,3.73,9.55,6.23,14.35,7.5Z"
			                style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                />
		            </g>
		        </g>
		        <g className={styles.cls8}>
		            <g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_8_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  8 0 Layer2 0 FILL">
		                    <path
			                    className={styles.cls44}
			                    d="m294.46,243.45c5.97-6.3,10.25-12.58,12.85-18.85,2.63-6.3,3.7-12.83,3.2-19.6-1.77-.77-2.82-1.2-3.15-1.3.73,6.6-.13,12.98-2.6,19.15-2.43,6.13-6.6,12.32-12.5,18.55l2.2,2.05Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_8_0_Layer2_1_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  8 0 Layer2 1 FILL">
		                    <path
			                    className={styles.cls44}
			                    d="m300.96,222.15c2.6-6.37,3.62-12.98,3.05-19.85l-3-1.2c.83,6.73,0,13.2-2.5,19.4-2.5,6.1-6.75,12.32-12.75,18.65l2.2,2.1c6.03-6.4,10.37-12.77,13-19.1Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		            </g>
		        </g>
		        <g className={styles.cls9}>
		            <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_7_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  7 0 Layer2 0 FILL">
		                <path
			                className={styles.cls43}
			                d="m233.31,180.05c-6.8-8.73-8.27-18.38-4.4-28.95-17.93-3.67-40.28-4.03-67.05-1.1-5.53,18.03-6.63,34.95-3.3,50.75,3.53,16.8,12.17,32.87,25.9,48.2,10.63-3.33,22.18-5,34.65-5,13.9-.03,27.63,2.05,41.2,6.25,6.5,2,13.3.75,20.4-3.75,6.63-4.23,10.52-9.05,11.65-14.45.87-4.27.12-9.4-2.25-15.4-2.77-5.77-4.35-9.37-4.75-10.8-25.83-5.77-43.18-14.35-52.05-25.75Z"
			                style={{ fill: `${teamColors[JERSEY_BG]}` }}
		                />
		            </g>
		        </g>
		        <g className={styles.cls12}>
		            <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_6_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  6 0 Layer2 0 FILL">
		                <path
			                className={styles.cls45}
			                d="m269.71,213.15c-4.8-4-10.08-6.08-15.85-6.25-5.17-.13-9.88,1.27-14.15,4.2-4.2,2.87-6.95,6.52-8.25,10.95-1.37,4.8-.67,9.5,2.1,14.1,2.2,4,6.55,9.88,13.05,17.65,7.03,8.43,13.1,14.78,18.2,19.05,8.37-.57,16.52-4.1,24.45-10.6,7.77-6.33,12.85-13.38,15.25-21.15-5.57-3.97-11.5-8.45-17.8-13.45-7.33-5.8-13-10.63-17-14.5Z"
			                style={{ fill: `${teamColors[JERSEY_SLEEVE]}` }}
		                />
		            </g>
		        </g>
		        <g className={styles.cls14}>
		            <g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_5_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  5 0 Layer2 0 FILL">
		                    <path
			                    className={styles.cls44}
			                    d="m301.26,238.95c-.13-.2-.98-.87-2.55-2-4.97,8.8-10.53,15.67-16.7,20.6-6.33,5.07-13.82,8.52-22.45,10.35l2.55,2.65c8.4-2.1,15.77-5.68,22.1-10.75,6.37-5.13,12.05-12.08,17.05-20.85Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_5_0_Layer2_1_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  5 0 Layer2 1 FILL">
		                    <path
			                    className={styles.cls44}
			                    d="m296.01,235c-.07-.17-.43-.53-1.1-1.1-.73-.6-1.15-.97-1.25-1.1-5.1,9.1-10.68,15.98-16.75,20.65-5.93,4.63-13.3,7.87-22.1,9.7l2.35,2.55c8.6-2.1,15.93-5.52,22-10.25,6.13-4.83,11.75-11.65,16.85-20.45Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_5_0_Layer2_2_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  5 0 Layer2 2 FILL">
		                    <path
			                    className={styles.cls44}
			                    d="m250.01,192.1c-14.77-9.37-22.47-21.1-23.1-35.2l-5.95.3c.7,15.53,8.65,28.43,23.85,38.7,11.47,7.77,24.05,12.63,37.75,14.6,1.83,1.97,4.05,5.98,6.65,12.05,1.3,3.03,2.42,5.87,3.35,8.5,1.17-1.93,2.23-4.65,3.2-8.15,1.93-7.03,2.35-14.5,1.25-22.4l-6.45-.55c.27,1.5.67,3.7,1.2,6.6.3,2.77.1,5.75-.6,8.95-.63-2.93-2-6.07-4.1-9.4l-.75-1.2-1.45-.15c-12.03-1.3-23.65-5.52-34.85-12.65Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_5_0_Layer2_3_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  5 0 Layer2 3 FILL">
		                    <path
			                    className={styles.cls44}
			                    d="m178.31,148.2l-6.35.55c-5.13,15.77-5.5,33.17-1.1,52.2,4.33,18.77,12.08,33.92,23.25,45.45l7.2-1.2c-4.83-4.27-9.27-10.17-13.3-17.7-4.03-7.47-7.23-15.7-9.6-24.7-5.37-20.4-5.4-38.6-.1-54.6Z"
			                    style={{ fill: `${teamColors[JERSEY_STRIPE]}` }}
		                    />
		                </g>
		            </g>
		        </g>
		        <g className={styles.cls15}>
		            <g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 0 FILL">
		                    <path className={styles.cls39} d="m439.46,139.4c.03-.5-.18-.78-.65-.85-11-1.43-21.05-1.55-30.15-.35-10.83,1.4-19.93,4.58-27.3,9.55-.4.27-.43.6-.1,1,.33.4.68.47,1.05.2,7.23-4.87,16.15-7.98,26.75-9.35,8.97-1.13,18.83-1,29.6.4.47.07.73-.13.8-.6Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_1_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 1 FILL">
		                    <path className={styles.cls39} d="m380.86,160.2c.1.5.38.72.85.65,18-1.9,37.4.08,58.2,5.95.47.13.73-.05.8-.55.03-.47-.18-.77-.65-.9-20.9-5.9-40.43-7.9-58.6-6-.47.07-.67.35-.6.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_2_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 2 FILL">
		                    <path className={styles.cls39} d="m382.71,168.9c9.7,1.83,18.17,3.8,25.4,5.9,9.03,2.6,17.22,5.7,24.55,9.3.47.23.8.12,1-.35.17-.43.02-.77-.45-1-7.37-3.6-15.6-6.72-24.7-9.35-7.23-2.1-15.77-4.08-25.6-5.95-.5-.07-.78.13-.85.6-.03.5.18.78.65.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_3_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 3 FILL">
		                    <path className={styles.cls33} d="m328.61,16.15c-6.1-5.97-11.4-9.93-15.9-11.9-.63-.27-3.08,12-7.35,36.8-2.87,16.7-4.38,26.23-4.55,28.6-.2,2.67-.27,4.2-.2,4.6.1.9.68,2.32,1.75,4.25.73,1.37,4.42,3,11.05,4.9,6.53,1.87,13.92,3.4,22.15,4.6,21.07,3.07,33.97,2.35,38.7-2.15-3-8.4-7.78-18.2-14.35-29.4-6.4-10.97-12.27-19.45-17.6-25.45-6.33-7.17-10.9-12.12-13.7-14.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_4_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 4 FILL">
		                    <path className={styles.cls41} d="m398.21,145.75c-.43-1.67-1.47-4.57-3.1-8.7-1.43-3.63-2.38-6.77-2.85-9.4-1.87-10.3-3.05-16.6-3.55-18.9-1.6-7.17-3.53-13.23-5.8-18.2-18.83-2-36.47-8.08-52.9-18.25-10.27-6.33-19.13-13.58-26.6-21.75-6.4-.23-12.93,0-19.6.7,13.13,8,19.5,16.18,19.1,24.55-.63,12.83-18.02,24.35-52.15,34.55-3.07.63-6.88,1.25-11.45,1.85-9.13,1.2-17.48,1.7-25.05,1.5l.1,3c.63,12.37,2.65,23.28,6.05,32.75,4.43,12.33,11.27,22.6,20.5,30.8,4.73,4.17,11.72,8.38,20.95,12.65,8.67,4,16.32,6.72,22.95,8.15,22.87,4.83,42.75,5.87,59.65,3.1,2.67-.43,6.98-1.13,12.95-2.1,5.4-1.03,10.03-2.43,13.9-4.2,10.73-4.8,18.57-13.45,23.5-25.95,4.07-10.27,5.2-18.98,3.4-26.15Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_5_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 5 FILL">
		                    <path className={styles.cls35} d="m283.81,51.25c-8.9-3.33-17.05-3.92-24.45-1.75-5,1.47-10.25,4.52-15.75,9.15-9.33,7.87-16.28,15.65-20.85,23.35-5.73,9.6-8.57,20.17-8.5,31.7,7.57.2,15.92-.3,25.05-1.5,4.57-.6,8.38-1.22,11.45-1.85,34.13-10.2,51.52-21.72,52.15-34.55.4-8.37-5.97-16.55-19.1-24.55Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_6_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 6 FILL">
		                    <path className={styles.cls39} d="m382.91,90.55l-1.05-2.15c-2.87-5.7-8.03-11.28-15.5-16.75-5.27-3.83-11.95-7.78-20.05-11.85-5.5-2.73-11.98-4.9-19.45-6.5-7.4-1.57-15.22-2.48-23.45-2.75,7.47,8.17,16.33,15.42,26.6,21.75,16.43,10.17,34.07,16.25,52.9,18.25Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_7_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 7 FILL">
		                    <path className={styles.cls42} d="m318.01,103.1c-5.7-4.1-12.17-5.98-19.4-5.65-7.2.37-13.03,2.88-17.5,7.55-4.47,4.63-6.27,10.05-5.4,16.25s4.17,11.37,9.9,15.5c5.73,4.1,12.2,5.97,19.4,5.6,7.23-.33,13.08-2.83,17.55-7.5,4.47-4.63,6.27-10.05,5.4-16.25-.9-6.2-4.22-11.37-9.95-15.5Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_8_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 8 FILL">
		                    <path className={styles.cls42} d="m381.41,127.7c1.63-3.57,1.92-7.8.85-12.7-1.07-4.9-3.18-9.02-6.35-12.35-3.17-3.33-6.43-4.93-9.8-4.8-3.4.17-5.92,2.03-7.55,5.6s-1.92,7.8-.85,12.7c1.07,4.9,3.18,9.03,6.35,12.4,3.17,3.33,6.45,4.92,9.85,4.75,3.37-.13,5.87-2,7.5-5.6Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_9_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 9 FILL">
		                    <path className={styles.cls39} d="m267.06,154.6c-.47.1-.65.37-.55.8.13.43.43.6.9.5,10.43-2.23,20.13-3.22,29.1-2.95,10.63.33,19.83,2.43,27.6,6.3.43.2.78.1,1.05-.3.27-.4.18-.7-.25-.9-7.93-3.97-17.32-6.12-28.15-6.45-9.13-.3-19.03.7-29.7,3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_10_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 10 FILL">
		                    <path className={styles.cls39} d="m269.26,179.4c-.47.17-.63.47-.5.9.1.43.38.57.85.4,19.63-7.17,38.5-10.68,56.6-10.55.5,0,.77-.22.8-.65,0-.47-.23-.7-.7-.7-18.17-.2-37.18,3.33-57.05,10.6Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_11_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 11 FILL">
		                    <path className={styles.cls39} d="m278.26,194.85c-.43.27-.53.6-.3,1,.23.4.57.47,1,.2,6.73-3.97,14.4-7.55,23-10.75,6.77-2.5,14.87-5.03,24.3-7.6.47-.13.65-.42.55-.85-.1-.43-.38-.58-.85-.45-9.4,2.53-17.57,5.08-24.5,7.65-8.63,3.23-16.37,6.83-23.2,10.8Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_12_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 12 FILL">
		                    <path className={styles.cls32} d="m322.71,118.35c-.83-5.57-2.82-10.12-5.95-13.65-3.13-3.5-6.48-4.97-10.05-4.4-3.57.57-6.32,3.02-8.25,7.35-1.93,4.33-2.48,9.28-1.65,14.85.83,5.57,2.8,10.12,5.9,13.65,3.13,3.53,6.48,5,10.05,4.4,3.57-.57,6.33-3.02,8.3-7.35,1.93-4.33,2.48-9.28,1.65-14.85Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_13_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 13 FILL">
		                    <path className={styles.cls40} d="m308.31,108.45c-.97,2.17-1.23,4.65-.8,7.45.43,2.8,1.42,5.08,2.95,6.85,1.53,1.77,3.18,2.5,4.95,2.2,1.73-.27,3.08-1.48,4.05-3.65.93-2.2,1.2-4.68.8-7.45-.43-2.8-1.42-5.08-2.95-6.85-1.53-1.77-3.18-2.52-4.95-2.25-1.77.3-3.12,1.53-4.05,3.7Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_14_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 14 FILL">
		                    <path className={styles.cls32} d="m379.86,115.1c-.63-4.67-1.95-8.5-3.95-11.5-2-3.03-4.08-4.37-6.25-4-2.17.37-3.78,2.33-4.85,5.9-1.07,3.57-1.28,7.68-.65,12.35.67,4.67,1.98,8.5,3.95,11.5,2,3,4.08,4.32,6.25,3.95s3.78-2.33,4.85-5.9c1.1-3.53,1.32-7.63.65-12.3Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_15_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 15 FILL">
		                    <path className={styles.cls40} d="m378.26,111.3c-.33-2.33-.98-4.27-1.95-5.8-1-1.5-2.03-2.17-3.1-2-1.07.17-1.87,1.15-2.4,2.95-.53,1.8-.63,3.85-.3,6.15.33,2.33,1,4.27,2,5.8.97,1.53,1.98,2.2,3.05,2,1.07-.17,1.87-1.15,2.4-2.95.53-1.77.63-3.82.3-6.15Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_16_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 16 FILL">
		                    <path className={styles.cls39} d="m381.21,161.25c.1-2-.28-3.12-1.15-3.35-.33-.07-.72.1-1.15.5-.47.37-.7.72-.7,1.05,0,.3.15.82.45,1.55.17.9.18,2.07.05,3.5-.07.8-.18,1.97-.35,3.5-.43,4.87-2.53,8.13-6.3,9.8-1.8.77-3.82,1.03-6.05.8-2.23-.23-4.12-.93-5.65-2.1-1.37-1-2.43-2.58-3.2-4.75-.27-.83-.5-1.55-.7-2.15-.43-1.2-.88-1.75-1.35-1.65-1,.2-1.08,1.37-.25,3.5.6,1.6,1.25,2.88,1.95,3.85,2.5,3.6,6.07,5.4,10.7,5.4,1.97,0,3.75-.37,5.35-1.1,3.77-1.63,6.12-4.38,7.05-8.25-.07.67.13,1.4.6,2.2.1.23.53.88,1.3,1.95,1.13,1.63,2.33,2.68,3.6,3.15,1.7.63,3.27.25,4.7-1.15,1.2-1.2,2-2.72,2.4-4.55.27-1.27.4-1.95.4-2.05.07-.97-.17-1.5-.7-1.6-.4-.07-.73.78-1,2.55-.23,1.53-.67,2.65-1.3,3.35-.8.9-1.78,1.32-2.95,1.25-1.1-.03-2.1-.47-3-1.3-1.03-.97-1.75-2.38-2.15-4.25-.17-.93-.33-2.57-.5-4.9-.17-1.7-.2-3.28-.1-4.75Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_4_0_Layer2_17_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  4 0 Layer2 17 FILL">
		                    <path className={styles.cls37} d="m388.41,145.65c-1.33-.83-2.8-1.2-4.4-1.1-.67.03-1.53.17-2.6.4-1.53.3-2.45.47-2.75.5-.4.07-2.17.18-5.3.35-2.3.13-3.93.45-4.9.95-2.67,1.47-3.18,3.47-1.55,6,1.37,2.07,3.58,3.87,6.65,5.4,3.4,1.7,7.03,2.18,10.9,1.45,4.43-.83,6.88-3.18,7.35-7.05.3-2.93-.83-5.23-3.4-6.9Z"/>
		                </g>
		            </g>
		        </g>
		        <g className={styles.cls3}>
		            <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_3_0_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  3 0 0 Layer2 0 FILL">
		                <path
			                className={styles.cls43}
	                        d="m373.41,65.15c-5.97-9.43-14.68-16.9-26.15-22.4-18.5-8.83-37.75-11.62-57.75-8.35-13.2,2.17-25.85,6.75-37.95,13.75,19.5.07,29.57,4.98,30.2,14.75.23,4.07-1.23,8.68-4.4,13.85-2.97,4.73-7.03,9.43-12.2,14.1-5.07,4.53-10.43,8.33-16.1,11.4-5.9,3.23-11.25,5.15-16.05,5.75-11.5,1.5-16.97-4.33-16.4-17.5-3.63,12.8-4,28.3-1.1,46.5,2.67,16.73,8.58,29.75,17.75,39.05,3.73,3.8,7.67,6.55,11.8,8.25,3.83,1.57,7.3,2.03,10.4,1.4,2.73-.57,4.78-2.87,6.15-6.9,1.07-3.07,1.92-7.93,2.55-14.6.67-8.2,1.23-14.52,1.7-18.95.9-7.83,2.13-14.43,3.7-19.8,1.73-7.67,6.9-14.33,15.5-20,7.8-5.13,17.77-9.07,29.9-11.8,11.47-2.57,23.4-3.77,35.8-3.6,12.43.17,23.45,1.7,33.05,4.6-2.13-12.03-5.6-21.87-10.4-29.5Z"
			                style={{ fill: `${teamColors[HELMET_BG]}` }}
		                />
		            </g>
		        </g>
		        <g className={styles.cls4}>
		            <path
			            id="FlashAICB_Assets_0_Layer_1_Clip_Group_2_0_0_Layer2_0_1_STROKES"
			            data-name="FlashAICB Assets 0 Layer 1  Clip Group  2 0 0 Layer2 0 1 STROKES"
	                    className={styles.cls1}
			            d="m232.81,62c7.83-7.03,15.97-11.47,24.4-13.3,8.37-1.8,14.58-.47,18.65,4,4.03,4.5,4.72,10.83,2.05,19-2.7,8.17-7.98,15.78-15.85,22.85-7.87,7.07-16,11.52-24.4,13.35-8.4,1.8-14.63.45-18.7-4.05-4.03-4.47-4.7-10.78-2-18.95,2.7-8.2,7.98-15.83,15.85-22.9Z"
			            style={{ stroke: `${teamColors[HELMET_HOLE]}` }}
		            />
		        </g>
		        <g className={styles.cls5}>
		            <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_1_0_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  1 0 0 Layer2 0 FILL">
		                <path
			                className={styles.cls45}
			                d="m317.16,34.1c-3.43-.9-7.85-1.27-13.25-1.1-4.1.1-7.92.47-11.45,1.1,7.43,5,14.28,12.75,20.55,23.25,6.67,11.1,11.17,22.9,13.5,35.4l15.35.45c-.47-14.77-2.2-26.5-5.2-35.2-3.93-11.5-10.43-19.47-19.5-23.9Z"
			                style={{ fill: `${teamColors[HELMET_STRIPE]}` }}
		                />
		            </g>
		        </g>
		        <g className={styles.cls6}>
		            <g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_22_0_Layer2_0_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  22 0 Layer2 0 FILL">
		                    <path className={styles.cls36} d="m389.91,90.15c-.73-1.17-1.77-1.92-3.1-2.25-20.97-4.97-42.33-5.9-64.1-2.8-18.47,2.67-32.18,7.13-41.15,13.4-6.87,4.73-13.08,17.02-18.65,36.85l-.6-.45c-.83-.63-1.78-.93-2.85-.9-1.03.03-1.95.38-2.75,1.05-5.53,4.67-7.67,12.72-6.4,24.15.1.77.42,1.53.95,2.3,11.3,14.23,22.43,26.3,33.4,36.2,10.97,9.9,20.48,16.5,28.55,19.8,6.2,2.53,15.27,4.57,27.2,6.1,8.4,1.1,16,1.75,22.8,1.95,9.57.27,18.25-.63,26.05-2.7,7.8-2.07,14.22-5.17,19.25-9.3.97-.8,1.52-1.82,1.65-3.05.1-1.23-.23-2.33-1-3.3-.87-1-1.95-1.53-3.25-1.6,2.53-14.93,6.47-28.52,11.8-40.75l.25.15c1.07.67,2.2.87,3.4.6,1.2-.23,2.13-.88,2.8-1.95.67-1.03.87-2.17.6-3.4-.23-1.23-.88-2.18-1.95-2.85-13.37-8.57-26.45-12.3-39.25-11.2-12.8,1.07-25.2,6.95-37.2,17.65-13.87,2.2-28.03.63-42.5-4.7-10.2-3.77-20.97-9.62-32.3-17.55,2.97-11.27,6.07-20.02,9.3-26.25,2.2-4.33,4.33-7.22,6.4-8.65,8.2-5.73,21.08-9.72,38.65-11.95,20.1-2.57,39.63-1.6,58.6,2.9,1.37.3,2.63.08,3.8-.65s1.92-1.77,2.25-3.1c.3-1.33.08-2.58-.65-3.75m-6.95,65.2c9.27-1.07,18.83.93,28.7,6-6.37,14.33-10.88,30.48-13.55,48.45-8.23,4.63-19.05,6.9-32.45,6.8,1-17.2-2.38-33.45-10.15-48.75,9-7.27,18.15-11.43,27.45-12.5m-95.4,32.75c-9.37-8.87-18.83-19.27-28.4-31.2-.47-5.33-.03-9.38,1.3-12.15,8.9,6.53,16.98,11.75,24.25,15.65,11.03,5.9,21.9,9.85,32.6,11.85l-5,34.85c-7.13-3.83-15.38-10.17-24.75-19m61.6-15.6l.75-.2c6.8,13.87,9.72,28.53,8.75,44-6.67-.4-13.28-1.07-19.85-2-8.3-1.2-14.92-2.63-19.85-4.3l5.25-36.75c8.27.93,16.58.68,24.95-.75Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_22_0_Layer2_1_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  22 0 Layer2 1 FILL">
		                    <path className={styles.cls35} d="m253.26,45.75c-3.43-.9-12.17-2.68-26.2-5.35-9.4-1.77-16.08-2.87-20.05-3.3-8.5-.97-15.13-.82-19.9.45-.33.07,1.37,3.83,5.1,11.3,3.3,6.63,7.33,14.48,12.1,23.55,7.93,15.17,12.68,23.97,14.25,26.4,1.53,2.3,2.43,3.6,2.7,3.9.63.63,1.97,1.42,4,2.35,1.43.63,4.85-.83,10.25-4.4,5.2-3.43,10.85-7.88,16.95-13.35,15.3-13.67,23.4-23.97,24.3-30.9-5.13-4.3-12.97-7.85-23.5-10.65Z"/>
		                </g>
		                <g id="FlashAICB_Assets_0_Layer_1_Clip_Group_22_0_Layer2_2_FILL" data-name="FlashAICB Assets 0 Layer 1  Clip Group  22 0 Layer2 2 FILL">
		                    <path className={styles.cls37} d="m226.61,94.75l32.85-28.15c-5.27-2.7-11.67-5.48-19.2-8.35-15.1-5.7-28.35-8.95-39.75-9.75l26.1,46.25Z"/>
		                </g>
		            </g>
		        </g>
		    </g>
		</svg>
	);
};