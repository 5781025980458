import {getDefaultErrorHandler} from "base";
import * as AdminApi from "api/AdminApi";
import * as ApplicationStateActions from "actions/ApplicationStateActions";
import {loadCurrentUser} from "actions/UserActions";
import LocalData from "utils/LocalData";
import {handleErrorResponse} from "utils/request";
import toast from "utils/toast";
import * as ActionTypes from "constants/ActionTypes";
import {mapGoGreenRequirementsFromServerToApp} from "data/serverMapping";
import * as Sentry from "@sentry/react";

export function setFromUserManagement(fromUserManagement=false) {
    return (dispatch) => {
        dispatch({
            fromUserManagement,
            type: ActionTypes.CAME_FROM_USER_MANAGEMENT
        })
    };
}

export function loadUserById(userId) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.USER_LOADING })

        AdminApi.loadUserById(userId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.USER_LOADED,
                    data: res.body,
                })
            })
            .catch((error) => {
                handleErrorResponse("loading user", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function loadRoles() {
    return (dispatch) => {
        dispatch({ type: ActionTypes.ROLES_LOADING })

        AdminApi.getRoles()
            .then((res) => {
                dispatch({
                    type: ActionTypes.ROLES_LOADED,
                    data: res.body,
                })
            })
            .catch((error) => {
                handleErrorResponse("loading user roles", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function createRole(data) {
    return (dispatch) => {
        dispatch({ type: ActionTypes.ROLES_LOADING })

        AdminApi.createRole(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.ROLE_CREATED,
                    data: res.body,
                })
            })
            .catch((error) => {
                handleErrorResponse("creating role", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function performGenericSearch(searchTerms) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.GENERIC_SEARCHED,
            searchTerms,
        })
    }
}

export function clearGenericSearch() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.GENERIC_SEARCH_CLEARED,
        })
    }
}

export function getPlans() {
    return (dispatch) => {
        AdminApi.getPlans()
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.PLANS_LOADED,
                        results: res.body,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading plans", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function searchVouchers(searchParams) {
    return (dispatch) => {
        AdminApi.searchVouchers(searchParams)
            .then((res) => {
                if (res.body) {
                    dispatch({
                        type: ActionTypes.VOUCHER_CODES_LOADED,
                        results: res.body,
                        planId: searchParams.planId,
                    });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading vouchers", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function selectVoucherPlan(planId) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.VOUCHER_PLAN_SELECTED,
            planId
        })
    }
}

export function updateVoucher(voucher, planId) {
    return (dispatch) => {
        AdminApi.updateVoucher(voucher)
            .then((res) => {
                dispatch({
                    type: ActionTypes.VOUCHER_CODE_UPDATED,
                    results: voucher,
                    planId: planId,
                });
            })
            .catch((error) => {
                handleErrorResponse("updating voucher", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function generateVouchers(data) {
    return (dispatch) => {
        AdminApi.generateVouchers(data)
            .then((res) => {
                dispatch({
                    type: ActionTypes.VOUCHERS_GENERATED,
                    results: res.body,
                    planId: data.planId,
                });
            })
            .catch((error) => {
                handleErrorResponse("generating voucher", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getProviderPlans(providerId) {
    return (dispatch) => {
        AdminApi.getProviderPlans(providerId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.PROVIDER_PLANS_LOADED,
                    data: res.body,
                    providerId: providerId
                });
            })
            .catch((error) => {
                handleErrorResponse("loading provider plans", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function updateProviderPlan(providerId, data) {
    return (dispatch) => {
        AdminApi.updateProviderPlan(providerId, data)
            .then((res) => {
                toast.success(`Plan Updated Successfully: ${res.body.name}`);
                dispatch({
                    type: ActionTypes.PROVIDER_PLAN_UPDATED,
                    plan: res.body,
                    providerId: providerId
                });
            })
            .catch((error) => {
                handleErrorResponse("updating provider plan", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function showAddForm() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.GENERIC_SHOW_ADD_FORM,
        })
    }
}

export function hideAddForm() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.GENERIC_HIDE_ADD_FORM,
        })
    }
}

export function getClinicGoGreenRequirements(clinicId, providerId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        AdminApi.getClinicGoGreenRequirements(clinicId, providerId)
            .then(res => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.CLINIC_GO_GREEN_REQUIREMENTS_LOADED,
                    requirements: mapGoGreenRequirementsFromServerToApp(res.body),
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading clinic go green requirements")(error);
            });
    };
}

export function goGreen(clinicId, providerId, startDate, clooEnrollmentTag=null) {
    const goGreenStartDate = startDate ? startDate : new Date();

    return (dispatch) => {
        AdminApi.goGreen(clinicId, providerId, goGreenStartDate, clooEnrollmentTag)
            .then(res => {
                dispatch({
                    type: ActionTypes.CLINIC_GO_GREEN_UPDATED,
                    data: {
                        clinicId,
                        providerId,
                        goGreenStartDate
                    },
                });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic's go green status", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function goBrown(clinicId, providerId) {
    const goBrownDate = new Date();

    return (dispatch) => {
        AdminApi.goBrown(clinicId, providerId, goBrownDate)
            .then(res => {
                dispatch({
                    type: ActionTypes.CLINIC_GO_GREEN_UPDATED,
                    data: {
                        clinicId,
                        providerId,
                        goBrownDate
                    },
                });
            })
            .catch((error) => {
                handleErrorResponse("updating clinic's go brown status", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function impersonateUser(token) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.IMPERSONATE_USER,
            token: token,
        })

        dispatch(loadCurrentUser());
    };
}

export function revertToSelf() {
    Sentry.setContext("impersonating", null);
    // Sentry.captureMessage("Impersonation Cleared");
    return (dispatch) => {
        AdminApi.revertToSelf()
            .then(res => {
                LocalData.setToken(res.body.token);
                    dispatch({
                        type: ActionTypes.REVERT_TO_SELF,
                        token: res.body.token,
                    });
                    dispatch(loadCurrentUser());
            })
            .catch((error) => {
                handleErrorResponse("reverting to self", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getPriceHistory(clinicId, clinicProductId) {
    return (dispatch) => {
        AdminApi.getPriceHistory(clinicId, clinicProductId)
            .then(res => {
                dispatch({
                    type: ActionTypes.PRICE_HISTORY_LOADED,
                    priceHistory: res.body,
                    clinicProductId: clinicProductId
                })
            })
            .catch((error) => {
                handleErrorResponse("loading price history", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function getProviderReports() {
    return (dispatch) => {
        AdminApi.getProviderReports()
            .then(res => {
                dispatch({
                    type: ActionTypes.PROVIDER_REPORTS_LOADED,
                    data: res.body,
                })
            })
            .catch((error) => {
                handleErrorResponse("loading provider reports", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function setForceSearchValue(value) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.SET_FORCE_SEARCH,
            value: value
        })
    };
}

export function getProviderAccountHolds(clinicId) {
    return (dispatch) => {
        AdminApi.getProviderAccountHolds(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.ACCOUNT_HOLDS_LOADED,
                    clinicId,
                    holds: res.body
                });
            })
            .catch((error) => {
                handleErrorResponse("clinic account holds", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function createAccountHold(holdInfo) {
    return (dispatch) => {
        AdminApi.createAccountHold(holdInfo)
            .then(() => {
                dispatch({
                    type: ActionTypes.ACCOUNT_HOLD_CREATED,
                    hold: holdInfo
                });
            })
            .catch((error) => {
                handleErrorResponse("releasing account hold", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function releaseAccountHold(clinicId, manufacturerId, form) {
    return (dispatch) => {
        AdminApi.releaseAccountHold(clinicId, manufacturerId, form)
            .then(() => {
                dispatch({
                    type: ActionTypes.ACCOUNT_HOLD_RELEASED,
                    clinicId,
                    manufacturerId
                });
            })
            .catch((error) => {
                handleErrorResponse("releasing account hold", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}
