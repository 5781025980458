import React from "react";
import PropTypes from "prop-types";
import styles from "./GoldenSummerGamesPodium.scss";
import map from "lodash/map";
import take from "lodash/take";
import Image from "components/common/Image";

const Podium = require("./images/Podium@1.5x.png");

// Kitty Sprites
import KittyPodiumCenter from "./KittyPodiumCenter";
import KittyPodiumLeft from "./KittyPodiumLeft";
import KittyPodiumRight from "./KittyPodiumRight";

// Puppy Sprites
import PuppyPodiumCenter from "./PuppyPodiumCenter";
import PuppyPodiumLeft from "./PuppyPodiumLeft";
import PuppyPodiumRight from "./PuppyPodiumRight";
import GenericAnimation from "../../../elements/GenericGame/GenericAnimation";

const SECOND = 1000;
const MINUTE = SECOND * 60;

const animationDuration = 10 * SECOND;
const duration_2 = SECOND / 3;
const duration_4 = SECOND / 1.5;

export default function GoldenSummerGamesPodium(props) {
	console.log(props.data, "data");

    const CENTER_PET_FRAMES = [{
        top: 0,
        left: 0
    }, {
        top: 0,
        left: "-100%"
    }];

    const RIGHT_PET_FRAMES = [{
        top: 0,
        left: 0
    }];

    const LEFT_PET_FRAMES = [{
        top: 0,
        left: 0
    }];

    return (
        <div className={styles.root}>
            <div className={styles.backgroundContainer}>
                <Image src={Podium} alt="Podium" />
            </div>
            {map(take(props.data, 3), (row, index) => {
                switch (index) {
                    case 0:
                        return (
                            <div
                                className={styles.centerPet}
                                key={row.ClinicId}
                            >
                                <div className={styles.animationContainer}>
                                    {row.AvatarType === "cat" ? (
                                        <GenericAnimation
                                            altText="Winning Kitty"
                                            animationDuration={animationDuration}
                                            duration={SECOND}
                                            frames={CENTER_PET_FRAMES}
                                            image={<KittyPodiumCenter teamNumber={row.Place} />}
                                            isAnimated={true}
                                            width="24.2em"
                                            height="18em"
                                        />
                                    ) : (
                                        <GenericAnimation
                                            altText="Winning Puppy"
                                            animationDuration={animationDuration}
                                            duration={SECOND}
                                            frames={CENTER_PET_FRAMES}
                                            image={<PuppyPodiumCenter teamNumber={row.Place} />}
                                            isAnimated={true}
                                            width="23.5em"
                                            height="18em"
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    case 1:
                        return (
                            <div
                                className={styles.rightPet}
                                key={row.ClinicId}
                            >
                                <div className={styles.animationContainer}>
                                    {props.AvatarType === "cat" ? (
                                        <GenericAnimation
                                            altText="Second Place Puppy"
                                            image={<KittyPodiumRight teamNumber={row.Place} />}
                                            isAnimated={false}
                                            duration={0}
                                            frames={RIGHT_PET_FRAMES}
                                            width="20em"
                                            height="16em"
                                        />
                                    ) : (
                                        <GenericAnimation
                                            altText="Second Place Puppy"
                                            image={<PuppyPodiumRight teamNumber={row.Place} />}
                                            isAnimated={false}
                                            duration={0}
                                            frames={RIGHT_PET_FRAMES}
                                            width="20em"
                                            height="16em"
                                        />
                                    )}
                                </div>
                            </div>
                        );
                    case 2:
                        return (
                            <div
                                className={styles.leftPet}
                                key={row.ClinicId}
                            >
                                <div className={styles.animationContainer}>
                                    {(props.AvatarType === "cat") ? (
                                        <GenericAnimation
                                            altText="Third Place Puppy"
                                            image={<KittyPodiumLeft teamNumber={row.Place} />}
                                            isAnimated={false}
                                            duration={0}
                                            frames={LEFT_PET_FRAMES}
                                            width="20em"
                                            height="16em"
                                        />
                                    ) : (
                                        <GenericAnimation
                                            altText="Third Place Puppy"
                                            image={<PuppyPodiumLeft teamNumber={row.Place} />}
                                            isAnimated={false}
                                            duration={0}
                                            frames={LEFT_PET_FRAMES}
                                            width="20em"
                                            height="16em"
                                        />
                                    )}
                                </div>
                            </div>
                        );
                }
            })}
        </div>
    );
}

GoldenSummerGamesPodium.defaultProps = {
    data: [],
};

GoldenSummerGamesPodium.propTypes = {
    data: PropTypes.array,
};
