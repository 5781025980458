import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./WellnessReprocessingRequestForm.scss";
import debounce from "lodash/debounce";
import find from "lodash/find";
import map from "lodash/map";
import * as ClinicApi from "api/ClinicApi";
import * as WellnessApi from "api/WellnessApi";
import Button from "components/common/Button";
import DropdownSearch from "components/common/DropdownSearch";
import Modal from "components/common/Modal";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import getLocalTimezone from "utils/getLocalTimezone";
import {handleErrorResponse} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import { USStates } from "constants/USStates";

function WellnessReprocessingRequestForm(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingClinics, setLoadingClinics] = useState(false);
    const [showConfirmAddJob, setShowConfirmAddJob] = useState(false);
    const [selectedClinicId, setSelectedClinicId] = useState(null);
    const [processData, setProcessData] = useState(null); // Reprocessing data from the server about a specific clinic
    const [clinics, setClinics] = useState([]);
    const clinic = clinics && find(clinics, {clinicId: !!props.clinicId ? props.clinicId : selectedClinicId});

    const searchClinics = (searchTerm) => {
        setLoadingClinics(true);
        ClinicApi.searchClinics({search:searchTerm, offset:0, limit:100})
            .then((res) => {
                if (res.body) {
                    setClinics(res.body.data);
                    setLoadingClinics(false);
                }
            })
            .catch((error) => {
                handleErrorResponse("searching clinics", error);
                setLoadingClinics(false);
            });
    }

    const handleClinicSearch = useCallback(debounce((value) => {
        if (props.canViewClinicManagement) {
            searchClinics(value);
        }
    }, 500), []);

    const handleClinicChange = ({name, value}) => {
        setSelectedClinicId(value);
        fetchReprocessingData(value);
    };

    const fetchReprocessingData = clinicId => {
        setProcessData(null);
        setIsLoading(true);
        WellnessApi.getClinicWellnessReprocessingData(clinicId)
            .then(res => {
                setProcessData(res.body);
                setIsLoading(false);
            })
            .catch(error => {
                handleErrorResponse("loading wellness reprocessing data", error);
                setIsLoading(false);
            });
    }

    const handleAddJobClicked = (e) => {
        setShowConfirmAddJob(true);
    }

    const handleSubmit = () => {
        props.onSubmit(props.clinicId ? props.clinicId : selectedClinicId);
    };

    const CLINIC_OPTIONS = map(clinics, (clinic) => {
        return {
            name: `(${clinic.clinicId}) ${clinic.clinicName} - ${clinic.city}, ${USStates[clinic.state]}`,
            value: clinic.clinicId,
        };
    });

    return (
        <>
            <div className={styles.root}>
                <div className="flex margin-top-sm">
                    {props.clinicId ? (
                        <div>Clinic: {clinic.clinicName} ({props.clinicId})</div>
                    ) : (
                            <div className="flex-1">
                                <DropdownSearch
                                    name="clinicId"
                                    onChange={handleClinicChange}
                                    value={selectedClinicId}
                                    options={CLINIC_OPTIONS}
                                    label="Search Clinics"
                                    onSearchChange={handleClinicSearch}
                                    loading={loadingClinics}
                                />
                            </div>
                        )}
                </div>
                <hr className="margin-top-sm margin-bottom-sm" />
                <div className="flex spaced-content align-bottom">

                    {!!(processData && clinic) &&
                        <div className="flex flex-column" style={{ width: "100%" }}>
                            <div className="flex flex-row">
                                <div className="flex-row margin-right-x-sm text-bold">
                                    <div>Clinic:</div>
                                    <div>Activation Date:</div>
                                    <div>Wellness Start Date:</div>
                                    <div>Current Pricing Schedule:</div>
                                    <div>Reprocess Invoices starting on:</div>
                                </div>
                                <div>
                                    <div>{clinic.clinicName}</div>
                                    <div>{getLocalTimezone(processData.activationDate, 'MM/DD/YYYY')}</div>
                                    <div>{getLocalTimezone(processData.wellnessStartDate, 'MM/DD/YYYY')}</div>
                                    <div>{getLocalTimezone(processData.scheduleStartDate, 'MM/DD/YYYY')} - {getLocalTimezone(processData.scheduleEndDate, 'MM/DD/YYYY')}</div>
                                    <div>{getLocalTimezone(processData.invoiceStartDate, 'MM/DD/YYYY')}</div>
                                </div>
                            </div>
                            <div className="margin-top-sm">
                                <h3>
                                    {(!processData || !processData.items || processData.items.length === 0) && <span>Change History</span>}
                                    {processData && processData.items && processData.items.length > 0 && <span>Found {processData.items.length} changes. Last process date: {getLocalTimezone(processData.lastReprocessDate, 'MM/DD/YYYY LTS')}</span>}
                                </h3>
                                <div className={styles.changeList}>
                                    {(!processData || !processData.items || processData.items.length === 0) && <div>No data available.</div>}
                                    {processData &&
                                        <ul>
                                            {map(processData.items, (change, index) => <li key={index}>
                                                {change.results.message}
                                                <span className="text-sm margin-left-x-sm">By: {change.createUser} on {getLocalTimezone(change.createDate, "MM/DD/YYYY")}</span>
                                            </li>)}
                                        </ul>
                                    }
                                </div>
                            </div>
                        </div>
                    }

                </div>
                <div className="flex margin-top-md">
                    <div className="flex-1" />
                    <div className="flex-none spaced-content">
                        <Button
                            type="gray"
                            onClick={props.onCancel}
                        >
                            Cancel
                    </Button>
                        <Button
                            disabled={!clinic}
                            onClick={handleAddJobClicked}
                        >
                            Reprocess Clinic
                    </Button>
                    </div>
                </div>
            </div>

            <Modal
                show={showConfirmAddJob}
                modalTitle="Are you sure?"
                small
                onClose={() => setShowConfirmAddJob(false)}
            >
                {!!clinic &&
                    <div>
                        <p>This will create a new reprocessing job for {clinic.clinicName}.</p>
                        <div className="flex justify-around">
                            <Button
                                type="gray"
                                onClick={() => setShowConfirmAddJob(false)}
                            >
                                <i className="far fa-times" /> &nbsp; Cancel
                        </Button>
                            <Button onClick={handleSubmit}>
                                <i className="far fa-check" /> &nbsp; Add Job
                        </Button>
                        </div>
                    </div>
                }
            </Modal>

            <SpinnerTakeover show={isLoading} />
        </>
    );
}

WellnessReprocessingRequestForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    clinicId: PropTypes.number,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
        }
    }
)(WellnessReprocessingRequestForm);
