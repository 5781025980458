import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import map from "lodash/map";
import { getCorporateGroups, getProviders } from "api/ProviderApi";
import DropdownSearch from "components/common/DropdownSearch";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function CorporateGroupDropdown(props) {
	const {
		disabled = false,
		hasError = false,
		label = "Corporate Group",
		name,
		onChange,
		placeholder = "-- Select Corporate Group --",
		required = false,
		value,
	} = props;
	const [corporateGroups, setCorporateGroups] = useState([]);
	const [loading, setLoading] = useState(false);

	const fetchData = async () => {
		setLoading(true);
		if (props.canViewClinicRegistration) {
			getCorporateGroups()
				.then((res) => {
					setCorporateGroups(res.body);
				})
				.catch((err) => {
					handleErrorResponse("loading corporate groups", err);
				})
				.finally(() => {
					setLoading(false);
				})
		} else {
			getProviders()
				.then((res) => {
					setCorporateGroups(filter(res.body, { isCorporateGroup: true }));
				})
				.catch((err) => {
					handleErrorResponse("loading corporate groups", err);
				})
				.finally(() => {
					setLoading(false);
				})
		}
	};

	useEffect(() => {
		if (!corporateGroups?.length) {
			fetchData();
		}
    }, []);

	const OPTIONS = useMemo(() => map(corporateGroups, cg => {
		let abbreviation = cg.shortName;
		let id = cg.id;
		let name = cg.name;
		if (props.canViewClinicRegistration) {
			abbreviation = cg.corporateGroupAbbreviation;
			id = cg.corporateMembershipId;
			name = cg.corporateGroupName;
		}

		return {
			name: `${!!abbreviation ? `(${abbreviation}) ` : " "}${name}`,
			value: id,
		}
	}), [corporateGroups]);

	if (loading) {
		return <SpinnerTakeover show />;
	}

    return (
        <DropdownSearch
            disabled={disabled}
            hasError={hasError}
            label={label}
            name={name}
            onChange={onChange}
            options={OPTIONS}
            placeholder={placeholder}
            required={required}
            value={value}
        />
    );
}

CorporateGroupDropdown.propTypes = {
    disabled: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	value: PropTypes.number,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicRegistration = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_REGISTRATION, userProfile);

        return {
            userProfile,
            canViewClinicRegistration,
        }
    }
)(CorporateGroupDropdown);