export const CLINIC = "Clinic";
export const MFR = "MFR";
export const CORPORATE_GROUP = "CorporateGroup";
export const WELLNESS = "Wellness";
export const GREENLINE_STAFF = "GL";

export const USER_TYPE_NAMES = {
    [CLINIC]: "Clinic",
    [MFR]: "Manufacturer",
    [CORPORATE_GROUP]: "Corporate Group",
    [WELLNESS]: "Wellness",
    [GREENLINE_STAFF]: "Greenline Staff",
}