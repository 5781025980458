import { map, values } from "lodash";

export const Countries = {
    "US": "United States",
    "CA": "Canada",
}

export const countryNames = values(Countries);



export const countriesList = [
    {
        "name": "United States",
        "abbr": "US"
    },
    {
        "name": "Canada",
        "abbr": "CA"
    },
];

export const countriesDropdownList = map(countriesList, country => (
    { name: country.name, value: country.abbr }
));
