export const MAIN_COLOR = "main_color";
export const SECONDARY_COLOR = "secondary_color";
export const MAIN_COLOR_SHADOW = "main_color_shadow";
export const SECONDARY_COLOR_SHADOW = "secondary_color_shadow";
export const THIRD_COLOR = "third_color";

export const TEAM_COLORS = [{
    [MAIN_COLOR]: "#0978c0",
    [SECONDARY_COLOR_SHADOW]: "#dea900",
    [SECONDARY_COLOR]: "#ffc200",
    [MAIN_COLOR_SHADOW]: "#04456f",
}, {
    [MAIN_COLOR]: "#9a05c4",
    [SECONDARY_COLOR_SHADOW]: "#320b98",
    [SECONDARY_COLOR]: "#4311c8",
    [MAIN_COLOR_SHADOW]: "#b14bce",
}, {
    [MAIN_COLOR]: "#ffb800",
    [SECONDARY_COLOR_SHADOW]: "#036c8a",
    [SECONDARY_COLOR]: "#249ec0",
    [MAIN_COLOR_SHADOW]: "#dea900",
}, {
    [MAIN_COLOR]: "#ffb800",
    [SECONDARY_COLOR_SHADOW]: "#890202",
    [SECONDARY_COLOR]: "#ad0900",
    [MAIN_COLOR_SHADOW]: "#dea900",
}, {
    [MAIN_COLOR]: "#320b98",
    [SECONDARY_COLOR]: "#8405ad",
    [SECONDARY_COLOR_SHADOW]: "#b14bce",
    [MAIN_COLOR_SHADOW]: "#4311c8",
}, {
    [MAIN_COLOR]: "#ad0900",
    [SECONDARY_COLOR_SHADOW]: "#02556c",
    [SECONDARY_COLOR]: "#036c8a",
    [MAIN_COLOR_SHADOW]: "#890202",
}, {
    [MAIN_COLOR]: "#ad0900",
    [SECONDARY_COLOR_SHADOW]: "#dea900",
    [SECONDARY_COLOR]: "#ffd157",
    [MAIN_COLOR_SHADOW]: "#890202",
}, {
    [MAIN_COLOR]: "#ff3900",
    [SECONDARY_COLOR_SHADOW]: "#091677",
    [SECONDARY_COLOR]: "#0e1e99",
    [MAIN_COLOR_SHADOW]: "#de3200",
}, {
    [MAIN_COLOR]: "#063073",
    [SECONDARY_COLOR_SHADOW]: "#ad2f00",
    [SECONDARY_COLOR]: "#de3c00",
    [MAIN_COLOR_SHADOW]: "#093e93",
}, {
    [MAIN_COLOR]: "#ffca28",
    [SECONDARY_COLOR_SHADOW]: "#680cc6",
    [SECONDARY_COLOR]: "#7a2dc9",
    [MAIN_COLOR_SHADOW]: "#ffc000",
}];

export const PUPPY_PARTS_COLOR_MAPPINGS = [
    {
        svgElementId: "Color_1",
        colorKey: MAIN_COLOR,
    },
    // {
    //     svgElementId: "Helmet_Hole",
    //     colorKey: HELMET_HOLE,
    // },
    // {
    //     svgElementId: "Helmet_BG",
    //     colorKey: HELMET_BG,
    // },
    // {
    //     svgElementId: "Jersey_Stripes",
    //     colorKey: JERSEY_STRIPE,
    // },
    // {
    //     svgElementId: "Jersey_Stripes-2",
    //     colorKey: JERSEY_STRIPE,
    // },
    // {
    //     svgElementId: "Jersey_Sleeve",
    //     colorKey: JERSEY_SLEEVE,
    // },
    // {
    //     svgElementId: "Jersey_Sleeve-2",
    //     colorKey: JERSEY_SLEEVE,
    // },
    // {
    //     svgElementId: "Jersey_BG",
    //     colorKey: JERSEY_BG,
    // }
];