import React from "react";
import { connect } from "react-redux";
import ClinicDashboard from "components/clinic/pages/ClinicDashboard";

function ClinicDashboardAdminPage(props) {
    return (
        <ClinicDashboard
            clinicId={props.clinicId}
            location={props.location}
        />
    );
}

export default connect(
    (state, ownProps) => {
        const clinicId = Number(ownProps.match.params.clinicId);
        return {
            clinicId,
        };
    }
)(ClinicDashboardAdminPage);
