import { NHO, NHO_HQ } from "constants/OrgNodeTypes";
import { PROVIDER_LINKS } from "constants/SidebarIds";

export function getLinkIsVisible(link, props, sideBarType="clinic") {
    // Relies on Props coming in from Base component
    switch(sideBarType) {
        case "admin":
            return adminSidebarRules(link, props);
        case "provider":
            return providerSidebarRules(link, props);
        default: // "clinic"
            return clinicSidebarRules(link, props);
    }
}


export function clinicSidebarRules(link, props) {
    switch (link.id) {
        case "dashboard":
        case "reports":
            return true;
        case "coupons":
            return !!(props.hasCoupons && (props.canViewCouponAudit || props.canViewCouponLibrary || props.canViewInstantRebateInvoiceFailures));
        case "programs":
            return props.canViewProgramEnrollmentClinic;
        case "wellness":
            return !!((props.hasWellness || props.clinicHasGreenlineWellness) && (props.canViewWellnessAudit || props.canViewWellnessPetPlans || props.canViewWellnessDvms || props.canViewClinicWellnessPlans));
        case "games":
            return !!(props.hasGames && props.canViewClinicBIGamesDashboard);
        case "vetcheck":
            //TODO: make sure that the clinic has a valid vetcheck GUID
            return !!(props.hasVetCheck && props.canViewClinicVetCheck);
        case "notifications":
            return props.canViewNotifications;
        case "bi-premier":
            return props.clinicHasBIPrioritySupport;
        case "resources":
            return !props.clinicHasBIPrioritySupport;
        default:
            return false;
    }
}


export function providerSidebarRules(link, props) {
    switch (link.id) {
        case PROVIDER_LINKS.GAMES:
            return props.canViewGames;
        case PROVIDER_LINKS.DEMO_GAMES:
            return props.canViewGamesDemo;
        case PROVIDER_LINKS.TERRITORY_GAMES:
            return props.canViewProviderBiGamesDrillDown && props.onClinicLevel && props.providerId && props.territoryId;
        case PROVIDER_LINKS.PRODUCT_TAGGING:
            return props.isCorporateGroup && props.canViewProductTagging;
        case PROVIDER_LINKS.CLINICS:
            return !(props.isCorporateGroup || props.node?.nodeType === NHO_HQ || props.node?.nodeType === NHO);
        case PROVIDER_LINKS.MAP:
            return false;
        case PROVIDER_LINKS.REPORTS:
            return props.isCorporateGroup || props.canViewProviderReports;
        case PROVIDER_LINKS.WELLNESS_PLAN_SETUP:
            return false;
        default:
            return true;
    }
}


function adminSidebarRules(linkId, props) {
    console.info("adminSidebarRules");
    return true;
}
