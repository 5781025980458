import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as ClinicActions from "actions/ClinicActions";
import { keys, map } from "lodash";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import pluralizeWord from "utils/pluralizeWord";
import Button from "components/common/Button";
import { ExportToCsv } from "export-to-csv";
import moment from "moment";
import {PermissionTypes, userHasPermission} from "../../../utils/permissions/rolesPermissions";
import * as UserPermissions from "../../../constants/UserPermissions";

function ExportClinicData(props) {
    useEffect(() => {
        if (props.canViewClinicManagement && !Object.keys(props.clinics)?.length) {
            props.getAllClinics();
        }
    }, [props.canViewClinicManagement]);

    const handleClick = () => {
        const data = map(props.clinicIds, id => {
            const clinic = props.clinics[id];
            if (clinic) {
                return {
                    id,
                    name: clinic.clinicName,
                    address1: clinic.address1 || "",
                    address2: clinic.address2 || "",
                    city: clinic.city,
                    state: clinic.state,
                    zip: clinic.zipCode || "",
                };
            } else {
                return {
                    id,
                    name: `Unknown Clinic ${id}`,
                };
            }
        });

        const date = moment(Date.now()).format("YYYY_MM_DD");

        const options = {
            filename: `${date}_clinics_info`,
            showLabels: true,
            useBom: false,
            useKeysAsHeaders: true,
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(data);

        if (props.onComplete) {
            props.onComplete();
        }
    };

    const count = props.clinicIds.length;

    return (
        <div className="full-width full-height">
            <div className="margin-bottom-sm">
                Export {count.toLocaleString()} clinic {pluralizeWord(count, "address", "addresses")}?
            </div>
            <Button onClick={handleClick}>Export Now</Button>
            <SpinnerTakeover show={keys(props.clinics).length === 0} />
        </div>
    );
}

ExportClinicData.propTypes = {
    clinicIds: PropTypes.arrayOf(PropTypes.number).isRequired,
    onComplete: PropTypes.func, // So you know when the data has been exported
}

ExportClinicData.defaultProps = {
    clinicIds: [],
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        return {
            canViewClinicManagement,
            clinics: state.entities.allClinics,
        };
    },
    (dispatch) => ({
        getAllClinics: () => dispatch(ClinicActions.getAllClinics()),
    }),
)(ExportClinicData);
