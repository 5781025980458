import { map } from "lodash";
import * as FilterTypes from "constants/FilterTypes";

const OPTIONS_TYPES = {
    MULTISELECT: "multiSelect",
    DROPDOWN: "dropdown"
}

const INPUT_TYPES = {
    STRING: "string",
    NUMBER: "number",
    DATE: "date",
    SELECT: "select",
    DOUBLE_SELECT: "doubleSelect",
}

export const EQUALITIES = {
    isAny: { name: "are", value: "is any"},
    isNotAny: { name: "are not", value: "is not any" },
    is: { name: "is", value: "=="},
    isNot: { name: "is not", value: "!="},
    greaterEqual: { name: "is greater than or equal to", value: ">="},
    lesserEqual: { name: "is less than or equal to", value: "<="},
    contains: { name: "contains", value: "contains"},
    notContains: { name: "does not contain", value: "!contains" },
    startsWith: { name: "starts with", value: "startswith" },
    notStartsWith: { name: "does not start with", value: "!startswith" },
    endsWith: { name: "ends with", value: "endswith" },
    notEndsWith: { name: "does not end with", value: "!endswith" },
}

const activationsLevel2Options = [
    { value:"Enrolled", name: "Enrolled" },
    { value:"GoGreen", name: "Go Green" },
    { value:"GoBrown", name: "Go Brown" },
];

const activeStatusOptions = [
    { value:"Active", name: "Active" },
    { value:"Inactive", name: "Inactive" },
    { value:"Not Registered", name: "Not Registered" },
    { value:"Is Test Clinic", name: "Is Test Clinic" },
];

const GATEWAY_OPTIONS = [
    { value: "Gateway", name: "Gateway" },
    { value: "GatewayHasChild", name: "Gateway (has Child)" },
    { value: "Child", name: "Child" },
    { value: "Unknown", name: "Unknown" },
];

const COUNTRY_OPTIONS = [
    { name: "United State", value: "US"},
    { name: "Canada", value: "CA"}
];


export const FILTERS = {
    [FilterTypes.PROVIDER_ID]: {
        id: FilterTypes.PROVIDER_ID,
        label: "Activations",
        type: INPUT_TYPES.DOUBLE_SELECT,
        equalityOptions: [ EQUALITIES.isAny, EQUALITIES.isNotAny],
        level2: {
            options: activationsLevel2Options,
            type: OPTIONS_TYPES.DROPDOWN,
        }
    },
    [FilterTypes.ACTIVE]: {
        id: FilterTypes.ACTIVE,
        label: "Active Status",
        type: INPUT_TYPES.SELECT,
        equalityOptions: [EQUALITIES.is, EQUALITIES.isNot],
        level1: {
            type: OPTIONS_TYPES.MULTISELECT,
            options: activeStatusOptions,
        },
    },
    [FilterTypes.CITY]: {
        id: FilterTypes.CITY,
        label: "City",
        type: INPUT_TYPES.STRING,
        equalityOptions: [
            EQUALITIES.contains,
            EQUALITIES.notContains,
            EQUALITIES.startsWith,
            EQUALITIES.notStartsWith,
            EQUALITIES.endsWith,
            EQUALITIES.notEndsWith
        ],
    },
    [FilterTypes.CLINIC_ID]: {
        id: FilterTypes.CLINIC_ID,
        label: "Clinic ID",
        type: INPUT_TYPES.NUMBER,
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.greaterEqual,
            EQUALITIES.lesserEqual
        ],
    },
    [FilterTypes.CLINIC_NAME]: {
        id: FilterTypes.CLINIC_NAME,
        label: "Clinic Name",
        type: INPUT_TYPES.STRING,
        equalityOptions: [
            EQUALITIES.contains,
            EQUALITIES.notContains,
            EQUALITIES.startsWith,
            EQUALITIES.notStartsWith,
            EQUALITIES.endsWith,
            EQUALITIES.notEndsWith
        ],
    },
    [FilterTypes.COUNTRY_CODE]: {
        id: FilterTypes.COUNTRY_CODE,
        label: "Country",
        type: INPUT_TYPES.SELECT,
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.isNot,
        ],
        level1: {
            type: OPTIONS_TYPES.DROPDOWN,
            options: COUNTRY_OPTIONS,
        }
    },
    [FilterTypes.DIRTY_REDEMPTIONS]: {
        id: FilterTypes.DIRTY_REDEMPTIONS,
        label: "Dirty Redemptions",
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.greaterEqual,
            EQUALITIES.lesserEqual
        ],
        type: INPUT_TYPES.NUMBER,
    },
    [FilterTypes.GATEWAY_STATUS]: {
        id: FilterTypes.GATEWAY_STATUS,
        label: "Gateway Status",
        type: INPUT_TYPES.SELECT,
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.isNot,
        ],
        level1: {
            type: OPTIONS_TYPES.MULTISELECT,
            options: GATEWAY_OPTIONS,
        }
    },
    [FilterTypes.ACTIVATION_DATE]: {
        id: FilterTypes.ACTIVATION_DATE,
        label: "GoGreen Date",
        type: INPUT_TYPES.DATE,
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.isNot,
            EQUALITIES.greaterEqual,
            EQUALITIES.lesserEqual
        ],
    },
    [FilterTypes.INSTALL_DATE]: {
        id: FilterTypes.INSTALL_DATE,
        label: "Install Date",
        type: INPUT_TYPES.DATE,
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.isNot,
            EQUALITIES.greaterEqual,
            EQUALITIES.lesserEqual
        ],
    },
    [FilterTypes.LAST_INVOICE]: {
        id: FilterTypes.LAST_INVOICE,
        label: "Last Invoice",
        type: INPUT_TYPES.DATE,
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.isNot,
            EQUALITIES.greaterEqual,
            EQUALITIES.lesserEqual
        ],
    },
    [FilterTypes.MAPPED_PRODUCTS]: {
        id: FilterTypes.MAPPED_PRODUCTS,
        label: "Mapped Products",
        type: INPUT_TYPES.NUMBER,
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.greaterEqual,
            EQUALITIES.lesserEqual
        ],
    },
    [FilterTypes.CLOO_ID]: {
        id: FilterTypes.CLOO_ID,
        label: "Offers Activated",
        type: INPUT_TYPES.SELECT,
        equalityOptions: [
            EQUALITIES.isAny,
            EQUALITIES.isNotAny,
        ],
        level1: {
            type: OPTIONS_TYPES.MULTISELECT,
        }
    },
    [FilterTypes.PAR]: {
        id: FilterTypes.PAR,
        label: "PAR",
        type: INPUT_TYPES.NUMBER,
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.greaterEqual,
            EQUALITIES.lesserEqual
        ],
    },
    [FilterTypes.PENDING_APPROVAL]: {
        id: FilterTypes.PENDING_APPROVAL,
        label: "Pending Approval",
        type: INPUT_TYPES.NUMBER,
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.greaterEqual,
            EQUALITIES.lesserEqual
        ],
    },
    [FilterTypes.PIMS]: {
        id: FilterTypes.PIMS,
        label: "PIMS",
        type: INPUT_TYPES.SELECT,
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.isNot,
        ],
        level1: {
            type: OPTIONS_TYPES.MULTISELECT,
        }
    },
    [FilterTypes.PRODUCT_SYNC]: {
        id: FilterTypes.PRODUCT_SYNC,
        label: "Product Sync",
        type: INPUT_TYPES.DATE,
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.isNot,
            EQUALITIES.greaterEqual,
            EQUALITIES.lesserEqual
        ],
    },
    [FilterTypes.PROVIDER_REPORT_ID]: {
        id: FilterTypes.PROVIDER_REPORT_ID,
        label: "Reports Activated",
        type: INPUT_TYPES.SELECT,
        equalityOptions: [
            EQUALITIES.isAny,
            EQUALITIES.isNotAny,
        ],
        level1: {
            type: OPTIONS_TYPES.MULTISELECT,
        }
    },
    [FilterTypes.STATE]: {
        id: FilterTypes.STATE,
        label: "State",
        type: INPUT_TYPES.STRING,
        equalityOptions: [
            EQUALITIES.contains,
            EQUALITIES.notContains,
            EQUALITIES.startsWith,
            EQUALITIES.notStartsWith,
            EQUALITIES.endsWith,
            EQUALITIES.notEndsWith
        ],
    },
    [FilterTypes.TAGS]: {
        id: FilterTypes.TAGS,
        label: "Tags",
        type: INPUT_TYPES.STRING,
        equalityOptions: [
            EQUALITIES.isAny,
            EQUALITIES.isNotAny,
        ],
    },
    [FilterTypes.TERRITORY_ID]: {
        id: FilterTypes.TERRITORY_ID,
        label: "Territory",
        type: INPUT_TYPES.STRING,
        equalityOptions: [
            EQUALITIES.is,
            EQUALITIES.isNot,
            EQUALITIES.startsWith,
            EQUALITIES.notStartsWith,
        ],
    },
}

export const FILTERS_OPTIONS = map(FILTERS, filter => ({ name: filter.label, value: filter.id }));

