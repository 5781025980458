exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".CorporateGroupFilterDropdown__root{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;margin-bottom:20px}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/provider/widgets/CorporateGroupFilterDropdown.scss"],"names":[],"mappings":"AAAA,oCAAM,oBAAoB,aAAa,uBAAuB,mBAAmB,sBAAsB,mBAAmB,kBAAkB,CAAC","file":"CorporateGroupFilterDropdown.scss","sourcesContent":[".root{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;margin-bottom:20px}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "CorporateGroupFilterDropdown__root"
};