import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./PriceWatchTiles.scss"
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as ClinicActions from "actions/ClinicActions";
import Button from "components/common/Button";
import Modal from "components/common/Modal";
import ProductPriceTable from "components/reports/elements/ProductPriceTable";
import SpinnerTakeover from "components/common/SpinnerTakeover";

function PriceWatchTiles(props) {
    const [showAllBrands, setShowAllBrands] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        if(!props.favoriteBrandIds?.length){
            setShowAllBrands(true);
        }
    }, [props.favoriteBrandIds]);

    const handleShowAll = () => {
        setShowAllBrands(!showAllBrands);
    };

    const handleSetFavorite = (brandId, isFavorite) => {
        if(isFavorite) {
            props.removeFavoriteBrand(brandId);
        } else {
            props.addFavoriteBrand(brandId);
        }
    };

    const handleSelectProduct = (product) => {
        setSelectedProduct(product);
    }

    const tiles = (brands, isFavorite) => {
        return map(brands, brand => {
                return(
                    <div className={styles.tile}>
                        <div
                            onClick={() => handleSetFavorite(brand.productFamilyId, isFavorite)}
                            className={styles.icon}
                            title={isFavorite ? "Click to remove this brand from your favorites." : "Click to add this brand to your favorites."}
                        >
                            <i className={classnames("fa-star text-yellow", {
                                "far": !isFavorite,
                                "fas": isFavorite
                            })}
                            />
                        </div>
                        <div className={styles.title}>{brand.productFamilyName}</div>
                        {map(brand.products, product => (
                            <div
                                key={product.productId}
                                onClick={() => handleSelectProduct(product)}
                                className={styles.product}
                            >
                                <span>{product.productName}</span>
                            </div>
                        ))}
                    </div>
                );
        });
    };

    return (
        <div className={styles.root}>
            {!!props.favoriteBrands?.length ? (
                <div className={styles.tiles}>
                    {tiles(props.favoriteBrands, true)}
                </div>
            ) : (
                <div className="margin-top-md text-lg text-center text-gray">
                    <div className="margin-bottom-x-sm">
                        <i className="fa fa-2x fa-star"/>
                    </div>
                    <div>Add brands to your favorites for easy access by clicking the star next to the brand name.</div>
                </div>
            )}
            <hr className="margin-bottom-md margin-top-md"/>
            <div className="flex flex-centered margin-top-md margin-bottom-md">
                <Button
                    onClick={handleShowAll}
                    type="primary"
                    text
                    icon
                >
                    <i className={classnames("fa", {
                        "fa-chevron-up": showAllBrands,
                        "fa-chevron-down": !showAllBrands,
                    })}/> {showAllBrands ? "Show favorite brands only" : "Show all brands"}
                </Button>
            </div>
            {showAllBrands && (
                <div className={styles.tiles}>
                    {tiles(props.otherBrands, false)}
                </div>
            )}
            <Modal
                show={!!selectedProduct}
                modalTitle={selectedProduct?.productName}
                onClose={() => setSelectedProduct(null)}
                small
            >
                {!!selectedProduct?.productId && (
                    <ProductPriceTable
                        brandId={selectedProduct.productFamilyId}
                        productId={selectedProduct.productId}
                    />
                )}
            </Modal>
        </div>
    );
}

PriceWatchTiles.propTypes = {
    clinicId: PropTypes.number.isRequired,
};

export default connect(
    (state) => {
        const favoriteBrandIds = state.entities.favoriteBrands;
        return {
            favoriteBrandIds,
            favoriteBrands: orderBy(filter(state.entities.comparablePrices, b => {return !!includes(favoriteBrandIds, b.productFamilyId)}), "name"),
            otherBrands: orderBy(filter(state.entities.comparablePrices, b => {return !includes(favoriteBrandIds, b.productFamilyId)}), "name"),
        }
    },
    (dispatch) => ({
        addFavoriteBrand: (brandId) => dispatch(ClinicActions.addFavoriteBrand(brandId)),
        removeFavoriteBrand: (brandId) => dispatch(ClinicActions.removeFavoriteBrand(brandId)),
    })
)(PriceWatchTiles);
