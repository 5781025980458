import React, {useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as WellnessActions from "actions/WellnessActions";
import PcpPetPlanEditForm from "components/wellness/widgets/PcpPetPlanEditForm";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";

function PcpPetPlanEditFormContainer(props) {
    const [loading, setLoading] = useState(false);

    const handleSave = async (externalPetPlanReference) => {
        setLoading(true);
        await props.updatePremierPetPlan(
            props.clinicId,
            props.plan.petId,
            props.plan.patientPlanEnrollmentId,
            externalPetPlanReference,
        );
        setLoading(false);
        props.onClose();
    };

    return (
        <div className="full-width full-height">
            <PcpPetPlanEditForm
                clinicId={props.clinicId}
                plan={props.plan}
                onCancel={props.onClose}
                onSave={handleSave}
            />
            <SpinnerTakeover show={loading}/>
        </div>
    );
}

PcpPetPlanEditFormContainer.propTypes = {
    clinicId: PropTypes.number.isRequired,
    plan: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canEditClinicWellnessPetPlans = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        return {
            canEditClinicWellnessPetPlans,
        }
    },
    (dispatch) => ({
        updatePremierPetPlan: (clinicId, petId, patientPlanEnrollmentId, externalPetPlanReference) => dispatch(WellnessActions.updatePremierPetPlan(clinicId, petId, patientPlanEnrollmentId, externalPetPlanReference)),
    }),
)(PcpPetPlanEditFormContainer);