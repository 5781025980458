import request from "utils/request";
import getApiRoot from "./getApiRoot";

export function getEnrollmentStates() {
    return request()
        .get(`${getApiRoot()}/v1/services/marketing/enrollmentstates`)
        .send();
}

export function getPetMarketingInfo(data) {
    return request()
        .post(`${getApiRoot()}/v2/services/marketing/clinics/${data.clinicId}/clients/search`)
        .send(data);
}

export function updatePetMarketingInfo(clinicId, clientId, data) {
    return request()
        .put(`${getApiRoot()}/v2/services/marketing/clinics/${clinicId}/clients/${clientId}`)
        .send(data);
}

export function exportPetMarketingInfo(data) {
    return request()
        .post(`${getApiRoot()}/v2/services/marketing/clinics/${data.clinicId}/clients/export`)
        .responseType("blob")
        .send(data);
}

export function exportStrayCouponsInfo(data) {
    return request()
        .post(`${getApiRoot()}/v1/services/coupons/clinicredemptions/instantrebateinvoicefailures/export/${data.clinicId}`)
        .responseType("blob")
        .send(data);
}