exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".KittyPodiumLeft__cls1{fill:#bf9430;stroke:#8a6b22;stroke-miterlimit:10}.KittyPodiumLeft__cls2{fill:#ffc200}.KittyPodiumLeft__cls2,.KittyPodiumLeft__cls3,.KittyPodiumLeft__cls4,.KittyPodiumLeft__cls5,.KittyPodiumLeft__cls6,.KittyPodiumLeft__cls7,.KittyPodiumLeft__cls8,.KittyPodiumLeft__cls9,.KittyPodiumLeft__cls10,.KittyPodiumLeft__cls11,.KittyPodiumLeft__cls12,.KittyPodiumLeft__cls13,.KittyPodiumLeft__cls14{stroke-width:0}.KittyPodiumLeft__cls3{fill:#232021}.KittyPodiumLeft__cls4{fill:#fffaff}.KittyPodiumLeft__cls5{fill:#8a6b22}.KittyPodiumLeft__cls6{fill:#e5d11a}.KittyPodiumLeft__cls7{fill:#c47258}.KittyPodiumLeft__cls8{fill:#e2ae1a}.KittyPodiumLeft__cls9{fill:#343031}.KittyPodiumLeft__cls10{fill:#fff}.KittyPodiumLeft__cls11{fill:#cbc6c5}.KittyPodiumLeft__cls12{fill:#231f20}.KittyPodiumLeft__cls13{fill:#ba1a55}.KittyPodiumLeft__cls14{fill:#04456f}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/pages/ClinicGamePage/GoldenSummerGames/KittyPodiumLeft.scss"],"names":[],"mappings":"AAAA,uBAAM,aAAa,eAAe,oBAAoB,CAAC,uBAAM,YAAY,CAAC,gTAAmF,cAAc,CAAC,uBAAM,YAAY,CAAC,uBAAM,YAAY,CAAC,uBAAM,YAAY,CAAC,uBAAM,YAAY,CAAC,uBAAM,YAAY,CAAC,uBAAM,YAAY,CAAC,uBAAM,YAAY,CAAC,wBAAO,SAAS,CAAC,wBAAO,YAAY,CAAC,wBAAO,YAAY,CAAC,wBAAO,YAAY,CAAC,wBAAO,YAAY,CAAC","file":"KittyPodiumLeft.scss","sourcesContent":[".cls1{fill:#bf9430;stroke:#8a6b22;stroke-miterlimit:10}.cls2{fill:#ffc200}.cls2,.cls3,.cls4,.cls5,.cls6,.cls7,.cls8,.cls9,.cls10,.cls11,.cls12,.cls13,.cls14{stroke-width:0}.cls3{fill:#232021}.cls4{fill:#fffaff}.cls5{fill:#8a6b22}.cls6{fill:#e5d11a}.cls7{fill:#c47258}.cls8{fill:#e2ae1a}.cls9{fill:#343031}.cls10{fill:#fff}.cls11{fill:#cbc6c5}.cls12{fill:#231f20}.cls13{fill:#ba1a55}.cls14{fill:#04456f}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cls1": "KittyPodiumLeft__cls1",
	"cls2": "KittyPodiumLeft__cls2",
	"cls3": "KittyPodiumLeft__cls3",
	"cls4": "KittyPodiumLeft__cls4",
	"cls5": "KittyPodiumLeft__cls5",
	"cls6": "KittyPodiumLeft__cls6",
	"cls7": "KittyPodiumLeft__cls7",
	"cls8": "KittyPodiumLeft__cls8",
	"cls9": "KittyPodiumLeft__cls9",
	"cls10": "KittyPodiumLeft__cls10",
	"cls11": "KittyPodiumLeft__cls11",
	"cls12": "KittyPodiumLeft__cls12",
	"cls13": "KittyPodiumLeft__cls13",
	"cls14": "KittyPodiumLeft__cls14"
};