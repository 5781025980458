import React from "react";
import PropTypes from "prop-types";
import replace from "lodash/replace";
import TextBox from "components/common/TextBox";
import { RIGHT } from "constants/TooltipPositionIds";

export default function UserInitials(props) {
    const {
        alignedLeft = false,
        disabled = false,
        information = "Only letters are allowed",
        informationPosition = RIGHT,
        label = "Your Initials",
        name = "initials",
        onChange,
        placeholder = "e.g. JS",
        required = true,
        small = false,
        value,
    } = props;

    const handleChangeInitials = ({name, value}) => {
        //Remove special characters (only letters allowed)
        const newValue = replace(value, /[^A-Z]/ig, "");
        onChange({ name, value: newValue });
    };

    return (
        <TextBox
            alignedLeft={alignedLeft}
            disabled={disabled}
            information={information}
            informationPosition={informationPosition}
            label={label}
            name={name}
            onChange={handleChangeInitials}
            placeholder={placeholder}
            required={required}
            small={small}
            value={value}
        />
    );
}

UserInitials.propTypes = {
    alignedLeft: PropTypes.bool,
    disabled: PropTypes.bool,
    information: PropTypes.string,
    informationPosition: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    small: PropTypes.bool,
    value: PropTypes.string,
};
