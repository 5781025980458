import React, {useEffect, useMemo, useState} from "react";
import AdminBase from "components/layout/AdminBase";
import {connect} from "react-redux";
import moment from "moment";
import styles from "./NotificationManagement.scss";
import filter from "lodash/filter";
import keys from "lodash/keys";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import uniqBy from "lodash/uniqBy";
import * as AdminActions from "actions/AdminActions";
import * as CouponActions from "actions/CouponActions";
import * as NotificationsActions from "actions/NotificationsActions";
import Button from "components/common/Button";
import DateInput from "components/common/DateInput";
import DataTable from "components/common/datatable/DataTable";
import Dropdown from "components/common/Dropdown";
import Modal from "components/common/Modal";
import NotificationModal from "components/notifications/widgets/NotificationModal";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as SearchTypes from "constants/SearchTypes";
import * as AdminControls from "constants/AdminControls";
import * as UserPermissions from "constants/UserPermissions";

function NotificationManagement(props) {
    const [notificationPreview, setNotificationPreview] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [programDateFilter, setProgramDateFilter] = useState(null);
    const [notificationTypeFilter, setNotificationTypeFilter] = useState(false);
    const [providerFilter, setProviderFilter] = useState(null);

    const filteredData = useMemo(() => {
        let newData = Object.values(props.notifications);
        if(!!dateFilter) {
            const selectedDate = new moment(dateFilter);
            newData = filter(newData, d => {
                // Don't show if there is no start or end date
                let shouldShow = !!(d.optinStartDate || d.optinExpirationDate);
                if(shouldShow && !!d.optinStartDate) {
                    // Don't show if the start date is after the selected date
                    shouldShow = moment(d.optinStartDate).isBefore(selectedDate);
                }
                if(shouldShow && !!d.optinExpirationDate) {
                    // Don't show if the end date is before the selected date
                    shouldShow = moment(d.optinExpirationDate).isAfter(selectedDate);
                }
                return shouldShow;
            });
        }
        if(!!programDateFilter) {
            const selectedDate = new moment(programDateFilter);
            newData = filter(newData, d => {
                // Don't show if there is no start or end date
                let shouldShow = !!(d.programStartDate || d.programExpirationDate);
                if(shouldShow && !!d.programStartDate) {
                    // Don't show if the start date is after the selected date
                    shouldShow = moment(d.programStartDate).isBefore(selectedDate);
                }
                if(shouldShow && !!d.programExpirationDate) {
                    // Don't show if the end date is before the selected date
                    shouldShow = moment(d.programExpirationDate).isAfter(selectedDate);
                }
                return shouldShow;
            });
        }
        if(!!notificationTypeFilter) {
            newData = filter(newData, {notificationType: notificationTypeFilter});
        }
        if(!!providerFilter) {
            newData = filter(newData, {providerId: providerFilter});
        }
        return orderBy(newData, ["optinStartDate", "optinExpirationDate"], ["desc", "desc"]);
    }, [props.notifications, dateFilter, programDateFilter, notificationTypeFilter, providerFilter]);

    const NOTIFICATION_TYPES = useMemo(() => {
        return orderBy(uniqBy(map(filter(props.notifications, n => n.notificationType !== null), notification => {
            return {name: notification.notificationType, value: notification.notificationType};
        }), "value"), "value");
    }, [props.notifications]);

    const NOTIFICATION_TEMPLATES = [{
        name: "General Update Template",
        value: "GENERAL_UPDATE_TEMPLATE"
    }, {
        name: "New Offer Template",
        value: "NEW_OFFER_TEMPLATE"
    }, {
        name: "Offer Change Over Template",
        value: "OFFER_CHANGE_OVER_TEMPLATE"
    }, {
        name: "Offer Expiration Template",
        value: "OFFER_EXPIRATION_TEMPLATE"
    }, {
        name: "Program Opt-In Template",
        value: "PROGRAM_OPT_IN_TEMPLATE"
    }, {
        name: "Contact Update Notification",
        value: "CONTACT_UPDATE_NOTIFICATION"
    }, {
        name: "Custom Notification",
        value: "CUSTOM_NOTIFICATION_TEMPLATE"
    }];

    const PROVIDER_OPTIONS = map(props.providers, p => {
        return {
            name: p.name,
            value: p.id
        }
    })

    useEffect(() => {
        if(!keys(props.providers)?.length) {
            props.getProviderList()
        }
        props.getAllNotifications();
    }, []);


    const handleCreateNotification = (template) => {
        props.hideAddForm();
        props.history.push(`/admin/notifications/create/${template}`);
    }

    const handleRowClicked = (row) => {
        props.history.push(`/admin/notifications/edit/${row.partnerProgramIdentifier}`);
    }

    const handlePreviewNotification = (e, row) => {
        e.stopPropagation();
        e.preventDefault();
        setNotificationPreview(row);
    }

    const COLUMNS = [{
        name: "Name",
        selector: "partnerProgramName",
        key: "partnerProgramName",
        searchable: true,
        sortable: true,
    }, {
        name: "ID",
        selector: "partnerProgramIdentifier",
        key: "partnerProgramIdentifier",
        sortable: true,
    }, {
        name: "Type",
        selector: "notificationType",
        key: "notificationType",
        sortable: true,
    }, {
        name: "Provider",
        selector: "providerId",
        key: "providerId",
        sortable: true,
        format: row => {
            const provider = props.providers[row.providerId];
            if(provider) {
                return (
                    <div className="no-wrap">{provider.name}</div>
                )
            } else return (<div/>)

        },
    }, {
        name: "Opt-in Start Date",
        selector: "optinStartDate",
        key: "optinStartDate",
        sortable: true,
        format: row => row.optinStartDate ?  new moment(row.optinStartDate).format("MM/DD/YYYY") : "Unknown",
    }, {
        name: "Opt-in Expiration Date",
        selector: "optinExpirationDate",
        key: "optinExpirationDate",
        sortable: true,
        format: row => row.optinExpirationDate ? new moment(row.optinExpirationDate).format("MM/DD/YYYY") : "Unknown",
    }, {
        name: "Program Start Date",
        selector: "programStartDate",
        key: "programStartDate",
        sortable: true,
        format: row => row.programStartDate ? new moment(row.programStartDate).format("MM/DD/YYYY") : "Unknown",
    }, {
        name: "Program Expiration Date",
        selector: "programExpirationDate",
        key: "programExpirationDate",
        sortable: true,
        format: row => row.programExpirationDate ? new moment(row.programExpirationDate).format("MM/DD/YYYY") : "Unknown",
    }, {
        name: "Short Description",
        selector: "shortDescription",
        key: "shortDescription",
        sortable: true,
        format: row => (<div style={{width: "250px"}}>{row.shortDescription}</div>)
    }, {
        name: "Alert",
        selector: "isAlert",
        key: "isAlert",
        sortable: true,
        format: row => {
            if(row.isAlert) {
                return <i className="fa fa-check-circle text-success"/>
            } else return <i className="fa fa-times-circle text-danger"/>
        },
    }, {
        name: "Auto",
        selector: "autoRegisterOnLogIn",
        key: "autoRegisterOnLogIn",
        sortable: true,
        format: row => {
            if(row.autoRegisterOnLogIn) {
                return <i className="fa fa-check-circle text-success"/>
            } else return <i className="fa fa-times-circle text-danger"/>
        },
    }, {
        name: "Requires Initials",
        selector: "requiresInitials",
        key: "requiresInitials",
        sortable: true,
        format: row => {
            if(row.requiresInitials) {
                return <i className="fa fa-check-circle text-success"/>
            } else return <i className="fa fa-times-circle text-danger"/>
        },
    }, {
        name: (<div className="text-center">More Info/<br/>Show Current Status</div>),
        selector: "moreInfoShowCurrentStatus",
        key: "moreInfoShowCurrentStatus",
        sortable: true,
        format: row => {
            if(row.moreInfoShowCurrentStatus) {
                return (
                    <div className="text-center">
                        <i className="fa fa-check-circle text-success"/>
                    </div>
                );
            } else return (
                <div className="text-center">
                    <i className="fa fa-times-circle text-danger"/>
                </div>
            );
        }
    }, {
        name: "Target Window",
        selector: "moreInfoTargetWindow",
        key: "moreInfoTargetWindow",
    }, {
        name: "View Override",
        selector: "moreInfoViewOverride",
        key: "moreInfoViewOverride",
    }, {
        name: "",
        selector: "actions",
        key: "actions",
        format: row => (
            <div>
                <div>
                    <Button
                        onClick={(e) => handlePreviewNotification(e, row)}
                        text
                        type="primary"
                    >
                        <i className="fa fa-bell"/>
                    </Button>
                </div>
            </div>
        )
    }];

    return (
        <AdminBase
            searchConfig={SearchTypes.NOTIFICATION_MANAGEMENT}
            collapsible
            controls={AdminControls.NOTIFICATION_MANAGEMENT}
            pageTitle="Notification Management"
            overflowHidden
        >
            <div className={styles.root}>
                <div className="flex spaced-content flex-none">
                    <div className="flex-1 flex spaced-content">
                        <div className="flex-none text-lg">Filters:</div>
                        <div className="flex-1">
                            <Dropdown
                                onChange={({value}) => setProviderFilter(value)}
                                name="providerFilter"
                                value={providerFilter}
                                placeholder="Select Provider"
                                options={PROVIDER_OPTIONS}
                            />
                        </div>
                        <div className="flex-1">
                            <Dropdown
                                onChange={({value}) => setNotificationTypeFilter(value)}
                                name="providerFilter"
                                value={notificationTypeFilter}
                                placeholder="Select Notification Type"
                                options={NOTIFICATION_TYPES}
                            />
                        </div>
                        <div className="flex-1">
                            <DateInput
                                onChange={({value}) => setDateFilter(value)}
                                name="dateFilter"
                                value={dateFilter}
                                showDropdowns
                                aligned
                                placeholderText="Opt-In Visible Date"
                            />
                        </div>
                        <div className="flex-1">
                            <DateInput
                                onChange={({value}) => setProgramDateFilter(value)}
                                name="programDateFilter"
                                value={programDateFilter}
                                showDropdowns
                                aligned
                                placeholderText="Program Active Date"
                            />
                        </div>
                    </div>
                </div>
                {/*// TODO: Display the notifications in either a table or list of some sort.*/}
                {/*//  Should have a button that allows the person to preview the notification in a modal.*/}
                <DataTable
                    // title={}
                    columns={COLUMNS}
                    data={filteredData}
                    pagination={false}
                    onRowClicked={handleRowClicked}
                    allowSearch
                    hideSearchBar
                    search={props.search}
                    scrollRows
                />
            </div>
            <Modal
                show={props.addNewForm}
                onClose={props.hideAddForm}
                modalTitle="Select Notification Template"
                mediumSmall
            >
                <div className={styles.grid}>
                    {map(NOTIFICATION_TEMPLATES, type => (
                        <div
                            className={styles.tile}
                            key={type.value}
                            onClick={() => handleCreateNotification(type.value)}
                        >
                            {type.name}
                        </div>
                    ))}
                </div>
            </Modal>
            <NotificationModal
                clinicId={null}
                isPreview
                notification={notificationPreview}
                show={!!notificationPreview}
                onClose={() => setNotificationPreview(null)}
            />
        </AdminBase>
    )
}

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const canViewNotificationManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.NOTIFICATION_MANAGEMENT, userProfile);
        return {
            canViewNotificationManagement,
            addNewForm: state.entities.adminTools.addNewForm || false,
            notifications: state.entities.allNotifications,
            providers: state.entities.providers,
            search: state.entities.genericSearch || ""
        }
    },
    (dispatch) => ({
        getProviderList: () => dispatch(CouponActions.getProviderList()),
        getAllNotifications: () => dispatch(NotificationsActions.getAllNotifications()),
        hideAddForm: () => dispatch(AdminActions.hideAddForm()),
    })
)(NotificationManagement);
