import * as PlanStatusIds from "constants/PlanStatusIds";

export default function getPlanStatus(status) {
    switch(status) {
        case 0:
            return PlanStatusIds.EXPIRED;
        case 1:
            return PlanStatusIds.ACTIVE;
        case 2:
            return PlanStatusIds.PAST_DUE;
        case 3:
            return PlanStatusIds.CANCELLED;
        default:
            return "Unknown";
    }
}

