import React, { useContext, useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { useHistory, withRouter } from "react-router";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import classnames from "classnames";
import styles from "./ClinicInfoHeader.scss";
import find from "lodash/find";
import map from "lodash/map";
import * as AdminApi from "api/AdminApi";
import * as AdminActions from "actions/AdminActions";
import * as ClinicActions from "actions/ClinicActions";
import * as UserActions from "actions/UserActions";
import { windowSizeContext } from "AppRoot";
import Button from "components/common/Button";
import NavSearchBox from "components/layout/widgets/NavSearchBox";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import LocalData from "utils/LocalData";
import * as ProviderLinks from "utils/ProviderLinks";
import { handleErrorResponse } from "utils/request";
import { setQueryStringParams } from "utils/urls";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import * as SearchTypes from "constants/SearchTypes"
import * as UserPermissions from "constants/UserPermissions";

const DEFAULT_CLINIC_FILTERS = "filters=%5B%7B%22order%22%3A0%2C%22id%22%3A%22Active%22%2C%22isComplete%22%3Atrue%2C%22equality%22%3A%22%3D%3D%22%2C%22level1Values%22%3A%5B%7B%22value%22%3A%22Active%22%7D%5D%7D%5D&orderBy=id&orderDir=desc";

export function ClinicInfoHeader(props) {
    const history = useHistory();
    useEffect(() => {
        if(props.canViewClinicManagement && !(props.clinic)) {
            props.getClinicById(props.clinicId);
        }
    }, [props.canViewClinicManagement, props.clinicId]);

    const [showClinicSearch, setShowClinicSearch] = useState(false);
    const [showSearch, setShowSearch] = useState(false);
    const context = useContext(windowSizeContext) || {};

    const ADMIN_ICON_BUTTONS = [
        {
            link: "settings",
            icon: "edit",
            description: "Edit Clinic Settings"
        }, {
            // TODO: Need to hide the impersonate icon if the user doesn't have access to any impersonate or if clinic cannot be impersonated...
            link: "impersonate",
            icon: "eye",
            description: "Impersonate Clinic Admin"
        }, {
            link: "programsSelection",
            icon: "cog",
            description: "Provider Setup"
        }, {
            // TODO: Need to hide the mapping icon if the user doesn't have access to any mapping info
            link: "pharmacyMapping",
            icon: "barcode",
            description: "Mapping"
        }, {
            link: "programsDashboard",
            icon: "user-md",
            description: "Programs Dashboard"
        }, {
            link: "productTags",
            icon: "tags",
            description: "Clinic Product Tags"
        }, {
            link: "pimsUsers",
            icon: "user-circle",
            description: "Manage PIMS Users"
        }, {
            link: "invoiceLineItems",
            icon: "file-invoice-dollar",
            description: "Clinic Invoice Line Items"
        }, {
            link: "par",
            icon: "circle-exclamation-check",
            description: "(PAR) Pre-Audit Review"
        }
    ];

    const PROVIDER_ICON_BUTTONS = [
        {
            link: "couponHistory",
            icon: "ticket",
            description: "Coupon History"
        }, {
            link: "couponLibrary",
            icon: "cut",
            description: "Coupon Library"
        }
    ];

    const shouldDisable = (link) => {
        const clinic = props.clinic;
        if (link === "impersonate"){
            //If there is a clinic Admin then the impersonate button is enabled
            return props.canImpersonateAccount && !(clinic.clinicAdminUserName && clinic.clinicAdminUserId && ((clinic.clinicAdminFirstName && clinic.clinicAdminLastName) || clinic.clinicAdminFullName))
        } else if (link === "pharmacyMapping"){
            return !props.canViewMapping;
        } else {
            let disabled = false;
            if(link === "couponHistory") {
                disabled = !props.canViewCouponAudit;
            } else if(link === "par") {
                disabled = !props.canViewPreAuditReview;
            }
            if(props.isAdmin) {
                return disabled || ClinicAdminLinks.getLocationLinkMatch(link, props.clinicId, props.location.pathname);
            } else {
                return disabled || ClinicProviderLinks.getLocationLinkMatch(link, props.nodeId, props.clinicId, props.location.pathname);
            }

        }

    }
    const goToLink = (link) => {
        if (link === "impersonate" && props.clinic) {
            if(props.canImpersonateAccount) {
                props.setRecentUser({
                    id: props.clinic.clinicAdminUserId,
                    username: props.clinic.clinicAdminUserName,
                    fullName: `${props.clinic.clinicAdminFirstName} ${props.clinic.clinicAdminLastName}`,
                    clinicId: props.clinic.clinicId,
                    locationType: null,
                    territoryCode: null,
                    timeStamp: new Date()
                });
                Sentry.setContext("impersonating", {
                    originalUserId: props.userProfile.id,
                    originalUserRoles: props.userProfile.roles,
                });
                props.setUnknownUser(); // This clears the current user from state.
                // Sentry.captureMessage("Impersonation Initiated");
                AdminApi.impersonateUser(props.clinic.clinicAdminUserName)
                    .then(res => {
                        LocalData.setToken(res.body.token);
                        props.impersonateUser(res.body.token);
                        props.history.push("/");
                    })
                    .catch(error => {
                        handleErrorResponse("attempting to impersonate", error);
                    });
            }
        } else {
            if(props.isAdmin) {
                props.history.push(ClinicAdminLinks.getLinkLocation(link, props.clinicId));
            } else {
                props.history.push(ClinicProviderLinks.getLinkLocation(link, props.nodeId, props.clinicId));
            }
        }
    }

    const toggleClinicSearch = () => {
        setShowClinicSearch(!showClinicSearch);
    }

    const toggleSearch = () => {
        setShowSearch(!showSearch);
    }

    const changeClinicLocation = (newClinicId) => {
        if(props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("dashboard", newClinicId));
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("dashboard", props.nodeId, newClinicId));
        }
    }

    const renderIconButton = ({link, icon, description=""}) => (
        <Button
            key={link}
            type="primary"
            text
            iconOnly
            disabled={shouldDisable(link)}
            onClick={() => goToLink(link)}
            title={description}
        >
            <i className={`fas fa-${icon}`}/>
        </Button>
    );

    if(!props.clinic?.name) {
        return null;
    }

    const goToDashboard = () => {
        if(props.isAdmin) {
            props.history.push(ClinicAdminLinks.getLinkLocation("dashboard", props.clinicId))
        } else {
            props.history.push(ClinicProviderLinks.getLinkLocation("dashboard", props.nodeId, props.clinicId))
        }
    }

    const goToClinicManagement = () => {
        // Go back to the last clinics search
        if(props.lastClinicSearch) {
            const filters = JSON.stringify(props.lastClinicSearch.filters)
            const newQs = setQueryStringParams("", {
                filters,
                search: props.lastClinicSearch.search || null,
                limit: props.lastClinicSearch.limit || 25,
                offset: props.lastClinicSearch.offset || 0,
                orderBy: props.lastClinicSearch.orderBy || "id",
                orderDir: props.lastClinicSearch.orderDir || "desc"
            });
            props.history.push(`/admin/clinics?${newQs}`);
        } else {
            props.history.push(`/admin/clinics?${DEFAULT_CLINIC_FILTERS}`);
        }
    }

    const goToUserManagement = () => {
        if(props.canViewUserManagement) {
            // Go back to the last clinics search
            if (props.lastUserSearch) {
                const roles = JSON.stringify(props.lastUserSearch.roles)
                const newQs = setQueryStringParams("", {
                    roles,
                    search: props.lastUserSearch.search || null,
                    limit: props.lastUserSearch.limit || 25,
                    providerId: props.lastUserSearch.providerId || null,
                    clinicId: Number(props.lastUserSearch.clinicId) || null,
                    status: props.lastUserSearch.status || "active",
                    offset: props.lastUserSearch.offset || 0,
                    orderBy: props.lastUserSearch.orderBy || "id",
                    orderDir: props.lastUserSearch.orderDir || "desc"
                });
                props.history.push(`/admin/users?${newQs}`);
            } else {
                props.history.push(`/admin/users`);
            }
        }
    }

    const goToCorporateGroupDashboard = () => {
        let link;
        // Go back to the last clinics search
        if (props.nodeId === props.userProfile?.nodeId) {
            link = ProviderLinks.getLinkLocation("dashboard", {
                providerId: props.providerId
            });
        } else {
            link = ProviderLinks.getLinkLocation("dashboard", {
                providerId: props.providerId,
                nodeId: props.nodeId
            });
        }
        history.push(link);
    }

    return (
        <div
            data-testid="clinic-info-header-component"
            className={styles.root}
        >
            {(props.cameFromUserManagement && props.canViewUserManagement) && (
                <div className="padding-left-sm margin-right-x-sm">
                    <Button
                        small
                        type="primary"
                        onClick={goToUserManagement}
                        icon
                        iconOnly={context.isPhone}
                    >
                        {context.isPhone ? (
                            <span>
                                <i className="fa fa-chevron-left"/>
                                <i className="fa fa-users"/>
                            </span>
                        ) : (
                            <><i className="fas fa-chevron-left" /> User Management</>
                        )}
                    </Button>
                </div>
            )}
            {props.isAdmin? (
                <div className="padding-left-sm">
                    <Button
                        small
                        type="primary"
                        onClick={goToClinicManagement}
                        icon={context.isPhone || !props.cameFromUserManagement}
                        iconOnly={context.isPhone}
                    >
                        {!props.cameFromUserManagement && (
                            <i className="fas fa-chevron-left"/>
                        )}
                        {(context.isPhone && props.cameFromUserManagement) ? (
                            <span>
                                <i className="fa fa-chevron-left"/>
                                <i className="fa fa-building"/>
                            </span>
                        ) : (
                            <span className="mobile-hide"> Clinics</span>
                        )}
                    </Button>
                </div>
            ) : (
                <div className="padding-left-sm margin-right-x-sm">
                    <Button
                        small
                        type="primary"
                        onClick={goToCorporateGroupDashboard}
                        icon={!props.cameFromUserManagement}
                        iconOnly={context.isPhone}
                    >
                        <i className="fas fa-chevron-left" /><span className="mobile-hide"> Dashboard</span>
                    </Button>
                </div>
            )}
            <div className={styles.clinicBox}>
                {props.isAdmin && (
                    <div className={styles.clinicSearchToggle}>
                        <Button
                            onClick={toggleClinicSearch}
                            type="circleIcon"
                            iconOnly
                        >
                            {showClinicSearch ? <i className="fas fa-times" /> : <i className="fas fa-building" />}
                        </Button>
                    </div>
                )}
                <div className={styles.clinicInfo} onClick={goToDashboard}>
                    <h4 className={styles.clinicName}>{props.clinic.name}</h4>
                </div>
                {props.isAdmin && (
                    <div className={classnames(styles.clinicSearchBox, {
                        [styles.showingSearch]: showClinicSearch,
                    })}>
                        <div className={styles.clinicSearchBoxInner}>
                            <NavSearchBox
                                searchConfig={SearchTypes.CLINIC_INFO_SEARCH}
                                clinicId={props.clinicId}
                                resultsTop
                                onResultsClick={(value) => changeClinicLocation(value)}
                                onCancel={toggleClinicSearch}
                                autoFocus={showClinicSearch}
                            />
                        </div>
                    </div>
                )}
            </div>
            {props.isAdmin ? (
                <div className={styles.iconButtons}>
                    {map(ADMIN_ICON_BUTTONS, button => renderIconButton(button))}
                </div>
            ) : (
                <div className={styles.iconButtons}>
                    {map(PROVIDER_ICON_BUTTONS, button => renderIconButton(button))}
                </div>
            )}

        </div>
    );
}

ClinicInfoHeader.propTypes = {
    clinicId: PropTypes.number,
    nodeId: PropTypes.number,
    searchConfig: PropTypes.object,
    isAdmin: PropTypes.bool,
    clinic: PropTypes.object,
    userProfile: PropTypes.object,
    favoriteClinicView: PropTypes.object,
    lastClinicManagementViewId: PropTypes.number,
    canViewClinicManagement: PropTypes.bool,
    canImpersonateAccount: PropTypes.bool,
    canViewCouponAudit: PropTypes.bool,
    canViewMapping: PropTypes.bool,
    cameFromUserManagement: PropTypes.bool,
    setUserUnknown: PropTypes.func,
    getClinicById: PropTypes.func,
    impersonateUser: PropTypes.func,
    setRecentUser: PropTypes.func,
};

const connector = connect(
    (state, ownProps) => {
        // Permissions
        const userProfile = state.user.userProfile;
        const canViewClinicManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_MANAGEMENT, userProfile);
        const canImpersonateAccount = userHasPermission(PermissionTypes.IMPERSONATE, UserPermissions.ACCOUNT, userProfile);
        const canViewCouponAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_AUDIT, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canViewPreAuditReview = userHasPermission(PermissionTypes.VIEW, UserPermissions.PRE_AUDIT_REVIEW, userProfile);
        const canViewUserManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.USER_MANAGEMENT, userProfile);
        return {
            userProfile,
            lastClinicSearch: state.entities.lastClinicsSearchParams,
            lastUserSearch: state.entities.lastUsersSearchParams,
            canViewCouponAudit: canViewCouponAudit || canViewCorporateGroupClinic,
            clinic: state.entities.clinicDetails[ownProps.clinicId],
            favoriteClinicView: find(state.entities.clinicViews, "isFavorite"),
            cameFromUserManagement: state.entities.cameFromUserManagement,
            // Permissions
            canViewClinicManagement,
            canImpersonateAccount,
            canViewPreAuditReview,
            canViewUserManagement
        }
    },
    (dispatch) => ({
        setUnknownUser: () => dispatch(UserActions.setUserUnknown()),
        getClinicById: (clinicId) => dispatch(ClinicActions.getClinicById(clinicId)),
        impersonateUser: (token) => dispatch(AdminActions.impersonateUser(token)),
        setRecentUser: (userInfo) => dispatch(UserActions.setRecentUser(userInfo)),
    })
);

export default compose(
    withRouter,
    connector
)(ClinicInfoHeader);
