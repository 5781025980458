import React from "react";
import styles from "./Slider.scss";
import PropTypes from "prop-types";
import classnames from 'classnames';

export default function Slider(props) {
    const {
        minValue = 0,
        maxValue = 100,
        value = null,
    } = props;

    const handleInput = (e) => {
        if(!props.disabled) {
            const newValue = e.target.value;

            if (!!props.onChange) {
                props.onChange(newValue);
            }
        }
    }

    return (
        <div
            data-testid="slider_component"
            className={classnames(styles.root, {
                [styles.disabled]: props.disabled,
            })}
        >
            <input
                data-testid="slider_input"
                className={styles.slider}
                type="range"
                min={minValue}
                max={maxValue}
                value={value || minValue}
                onInput={handleInput}
                disabled={props.disabled}
                onChange={handleInput}
            />
        </div>
    );
};

Slider.propTypes = {
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    value: PropTypes.number, // Current value to use
    onChange: PropTypes.func, // Sends the new value within the specified range
    disabled: PropTypes.bool,
};
