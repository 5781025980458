import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./GenericGameCard.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import get from "lodash/get";
import { getLast2Snapshots } from "actions/ProviderActions";
import Image from "components/common/Image";
import Loader from "components/common/Loader";
import MultipleAnimations from "components/games/elements/GenericGame/MultipleAnimations";
import { PermissionTypes, userHasPermission, userPrimaryLocationType } from "utils/permissions/rolesPermissions";
import * as LocationTypes from "constants/LocationTypes";
import * as UserPermissions from "constants/UserPermissions";

function GenericGameCard(props) {
    const history = useHistory();
    const params = useParams();
    const nodeId = Number(params?.nodeId) || null;
    const [gameLoaded, setGameLoaded] = useState(false);
    const [restartCounter, setRestartCounter] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [showRestart, setShowRestart] = useState(false);
    const { LegalWording } = props;

    useEffect(() => {
        if (props.game?.greenlineGameId && props.game?.daysFromStart !== 0) {
            props.getSnapshots(props.game.greenlineGameId, props.game.ownerUserId, props.clinicId, false);
        }
    }, [props.game]);

    const handleDashboardClick = () => {
        if (props.isAdmin) {
            history.push(`/admin/clinic/${props.clinicId}/games/${props.game.greenlineGameId}`);
        } else if(props.isProvider) {
            history.push(`/provider/location/${nodeId}/clinic/${props.clinicId}/games/${props.game.greenlineGameId}`);
        } else {
            history.push(`/games/${props.game.greenlineGameId}`);
        }
    }

    const handleRestart = () => {
        setShowRestart(false);
        setShowScore(false);
        const nextRestart = restartCounter + 1;
        setRestartCounter(nextRestart);
    };

    const gameData = useMemo(() => {
        if (props.game?.daysFromStart === 0) {
            // Return kickoff
            return {
                totalDoses: 0,
                totalPoints: 0,
                newDoses: 0,
                newPoints: 0,
                bonus: 0,
                bonusPoints: 0,
                firstDay: true,
            }
        }
        if (props.game?.data.Clinics && props.clinicId && !!props.gameSnapshotComparison) {
            if (!props.gameSnapshotComparison.length) {
                // Unlikely, but just in caseprops.gameSnapshotComparison
                return {
                    totalDoses: 0,
                    totalPoints: 0,
                    newDoses: 0,
                    newPoints: 0,
                    bonus: 0,
                    bonusPoints: 0,
                    firstDay: false,
                }
            }

            let today;
            let yesterday;
            if (props.gameSnapshotComparison.length === 1) {
                // Handle when it's the first day, all 0s for Yesterday
                yesterday = props.gameSnapshotComparison[0];
                today = find(props.game.data.Clinics, clinic => clinic.ClinicId === props.clinicId);
            } else {
                yesterday = find(props.gameSnapshotComparison[0].data.Clinics, clinic => clinic.ClinicId === props.clinicId);
                today = find(props.gameSnapshotComparison[1].data.Clinics, clinic => clinic.ClinicId === props.clinicId);
            }

            const todayTotalDoses = get(today, "CurrentPeriod.TotalReportDoses", 0);
            const yesterdayTotalDoses = get(yesterday, "CurrentPeriod.TotalReportDoses", 0);
            const newDoses = todayTotalDoses - yesterdayTotalDoses;

            const todayTotalPoints = get(today, "CurrentPeriod.TotalReportPoints", 0);
            const yesterdayTotalPoints = get(yesterday, "CurrentPeriod.TotalReportPoints", 0);
            const newPoints = todayTotalPoints - yesterdayTotalPoints;

            const todayExtraDoses = get(today, "CurrentPeriod.ExtraDoses", 0);
            const yesterdayExtraDoses = get(yesterday, "CurrentPeriod.ExtraDoses", 0);
            const bonus = todayExtraDoses - yesterdayExtraDoses;

            const todayExtraPoints = get(today, "CurrentPeriod.ExtraPoints", 0);
            const yesterdayExtraPoints = get(yesterday, "CurrentPeriod.ExtraPoints", 0);
            const bonusPoints = todayExtraPoints - yesterdayExtraPoints;

            return {
                totalDoses: todayTotalDoses,
                totalPoints: todayTotalPoints,
                newDoses: newDoses,
                newPoints: newPoints,
                bonus: bonus,
                bonusPoints: bonusPoints,
                firstDay: false,
            };
        }
        return null;
    }, [props.game, props.clinicId, props.gameSnapshotComparison]);

    useEffect(() => {
        if (props.game?.details && (props.gameSnapshotComparison || props.game?.daysFromStart === 0) && !!gameData) {
            setGameLoaded(true);
        }
    }, [props.game, props.gameSnapshotComparison, gameData]);

    const getAnimations = (game) => {
        if (!!props.getAnimationIndex) {
            // This is used to get an animation based on a certain piece of data. It returns the index of the animation.
            const index = props.getAnimationIndex(game);
            if (!props.animationOptions?.[index]) {
                console.error("The index for this animation doesn't exist");
                return props.animationOptions[0];
            }
            return props.animationOptions[index];
        } else {
            // By default, return only the first animation
            return 0;
        }
    };

    const filteredAnimations = useMemo(() => {
        const animations = getAnimations(props.game);
        return filter(animations.animations, a => {
            let canView = a.canView || false;
            if (!!a.getCanView) {
                canView = a.getCanView(props.game, props.gameSnapshotComparison);
            }
            return canView;
        });
    }, [props.animationOptions, props.game, props.gameSnapshotComparison]);

    const handleBeforeAnimation = (index) => {
        const animation = filteredAnimations[index];
        if (animation) {
            setShowScore(animation.showScore);
        }
    };

    const handleAnimationComplete = (index) => {
        if ((index + 1) === filteredAnimations?.length) {
            setShowRestart(true);
        }
    }

    return (
        <div
            key={`game_${props.game.greenlineGameId}`}
            className={classnames(styles.root, {
                [styles.clickable]: props.dashboard,
                [styles.historic]: !props.game.isCurrent,
            })}
        >
            <div id={`game_${props.game.greenlineGameId}`} className={styles.gameContainer}>
                <div className={styles.banner}>
                    <Image
                        src={props.bannerImage}
                        alt={props.bannerImageAltText}
                    />
                </div>
                <div
                    className={classnames(styles.animation, {
                        [styles.animate]: !props.animateAcrossScreen && props.points ? (gameData?.newPoints > 0) : (gameData?.newDoses > 0),
                        [styles.animateAcrossScreen]: (props.animateAcrossScreen && !showRestart),
                        [styles.animateDoses]: (showScore && !showRestart),
                        [styles.hideDoses]: (!showScore),
                    })}
                    style={{ backgroundColor: `${props.backgroundColor}` }}
                >
                    {props.backgroundImage && (
                        <Image
                            alt={props.bannerImageAltText}
                            className={styles.backgroundImage}
                            src={props.backgroundImage}
                        />
                    )}
                    {props.points ? (
                        <div className={styles.score} style={props.scoreStyles}>
                            <div className={styles.newPoints}>+{gameData?.newPoints || 0} POINT{Number(gameData?.newPoints || 0) === 1 ? "" : "S"}</div>
                            <div className={styles.doses}>
                                <div>POINTS <i className={classnames("fa fa-paw", styles.paw)}/></div>
                                <div className="text-lg">{gameData?.totalPoints || 0}</div>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.score} style={props.scoreStyles}>
                            <div className={styles.newDoses}>+{gameData?.newDoses || 0} DOSE{Number(gameData?.newDoses || 0) === 1 ? "" : "S"}</div>
                            <div className={styles.doses}>
                                <div>DOSES <i className={classnames("fa fa-paw", styles.paw)}/></div>
                                <div className="text-lg">{gameData?.totalDoses || 0}</div>
                            </div>
                        </div>
                    )}
                    <div
                        className={styles.animationContainer}
                        style={props.animationContainerStyles}
                    >
                        {gameLoaded ? (
                            <MultipleAnimations
                                key={restartCounter}
                                animations={filteredAnimations}
                                onAnimationCompleted={handleAnimationComplete}
                                onBeforeAnimation={handleBeforeAnimation}
                            />
                        ) : (
                            <Loader />
                        )}
                    </div>
                    {(showRestart) && (
                        <div
                            className={styles.restart}
                            onClick={handleRestart}
                        >
                            <i className="fa fa-rotate-right"/>
                        </div>
                    )}
                </div>
                <div
                    className={styles.gameDetails}
                >
                    <div className={styles.dates}>
                        {props.game.startDate} - {props.game.endDate}
                    </div>
                    {!!LegalWording && (
                        <LegalWording />
                    )}
                    <div className={styles.viewLeaderboardLink} onClick={handleDashboardClick}>
                        View the Leaderboard <i className={"fa-solid fa-chevron-right"} />
                    </div>
                </div>
            </div>
        </div>
    );
}

GenericGameCard.defaultProps = {
    animateAcrossScreen: false,
    animationContainerStyles: {},
    animationOptions: [],
    backgroundColor: "white",
    bannerImage: undefined,
    bannerImageAltText: "",
    clinicId: null,
    dashboard: false,
    game: {},
    getAnimationIndex: undefined,
    legalWording: undefined,
    points: false,
    style: {},
};

GenericGameCard.propTypes = {
    animateAcrossScreen: PropTypes.bool,
    animationContainerStyles: PropTypes.object,
    animationOptions: PropTypes.array,
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    backgroundImageAltText: PropTypes.string,
    bannerImage: PropTypes.string,
    bannerImageAltText: PropTypes.string,
    clinicId: PropTypes.number,
    dashboard: PropTypes.bool,
    game: PropTypes.object,
    getAnimationIndex: PropTypes.func,
    legalWording: PropTypes.node,
    points: PropTypes.bool, // If this is true, the game uses points otherwise, it uses doses.
    style: PropTypes.object,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const isAdmin = userPrimaryLocationType(userProfile, [LocationTypes.NULL]);
        const isProvider = userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canEditClinicBiGamesDashboard = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            isAdmin,
            isProvider,
            canViewClinicBIGamesDashboard,
            canEditClinicBiGamesDashboard,
            gameSnapshotComparison: state.entities.gameSnapshotComparison[ownProps.game?.greenlineGameId],
        }
    },
    (dispatch) => ({
        getSnapshots: (gameId, userId, clinicId, isDemo) => dispatch(getLast2Snapshots(gameId, userId, clinicId, isDemo)),
    })
)(GenericGameCard);
