import React from "react";
import PropTypes from "prop-types";
import styles from "./PuppyBowlSnapshot.scss";
import PuppyBowlGame from "components/games/pages/ClinicGamePage/PuppyBowlGame";

export default function PuppyBowlSnapshot(props) {
    const {
        animate = false,
        clinicId,
        game,
    } = props;

    const gameKey = `game_${game.greenlineGameId}_day_${game.daysInto}`;

    return (
        <div className={styles.root}>
            <PuppyBowlGame
                clinicId={clinicId}
                game={game}
                snapshot
                animate={animate}
                key={gameKey}
            />
        </div>
    );
}

PuppyBowlSnapshot.propTypes = {
    game: PropTypes.object,
    userId: PropTypes.number,
    dashboard: PropTypes.bool,
    clinicId: PropTypes.number,
    nodeId: PropTypes.number,
    animate: PropTypes.bool,
};
