// Default
export const CLINIC = "Clinic";
export const DISTRICT = "District";
export const DIVISION = "Division";
export const GROUP = "Group";
export const HQ = "HQ";
export const REGION = "Region";
export const TERRITORY = "Territory";

// BI
export const NHO_HQ = "NHO HQ";
export const NHO = "NHO";
export const CORPORATE_ACCOUNT = "CorpAcct";

// Elanco
export const KAM_HQ = "KAMHQ";
export const KAM_DISTRICT = "KAMDistrict";
export const KAM_TERRITORY = "KAMTerritory";

// Merck
export const ABL = "ABL";
export const MGR = "MGR";

