import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import * as styles from "./VoucherManagement.scss"
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import * as AdminActions from "actions/AdminActions";
import { windowSizeContext } from "AppRoot";
import AccessDenied from "components/common/AccessDenied";
import AdminBase from "components/layout/AdminBase";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import VoucherTable from "components/admin/widgets/VoucherTable";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as WindowUtils from "utils/windowUtils";
import * as SearchTypes from "constants/SearchTypes";
import * as AdminControls from "constants/AdminControls";
import * as UserPermissions from "constants/UserPermissions";

function VoucherManagement(props) {
    const context = useContext(windowSizeContext) || {};

    useEffect(() => {
        if(props.canViewVoucherManagement) {
            props.getPlans();
        }
    }, []);

    useEffect(() => {
        props.selectVoucherPlan(props.planId);
        if(!!props.planId) {
            props.searchVouchers({search: "", planId: props.planId, limit: 9999, offset: 0});
        }
    }, [props.planId]);

    const PLAN_OPTIONS = map(orderBy(props.plans, ["planName"]), (plan) => {
        return {name: plan.planName, value: plan.planId};
    });

    const handleSelectProvider = ({value}) => {
        props.history.push(`/admin/vouchers/${value}/`);
    }

    return (
        <AdminBase
            pageTitle={"Voucher Management"}
            collapsible
            controls={AdminControls.VOUCHER_MANAGEMENT}
            searchConfig={SearchTypes.VOUCHER_MANAGEMENT}
        >
            {props.canViewVoucherManagement ? (
                <div className={styles.root}>
                    {!!(props.planVouchers) && (
                        <>
                            <div className={styles.programSelect}>
                                <h3 className="margin-right-sm margin-left-sm flex-none mobile-hide">Selected Program</h3>
                                <div className="full-width">
                                    <Dropdown
                                        label={context.isPhone ? "Selected Program" : ""}
                                        options={PLAN_OPTIONS}
                                        name="planId"
                                        fullWidth
                                        onChange={handleSelectProvider}
                                        value={props.planId}
                                        placeholder="--Select a Program--"
                                    />
                                </div>
                                <div className="flex-1 flex-column text-right">
                                    {(props.planVouchers) && (
                                        <div className="flex-1 flex-column text-right">
                                            {props.planVouchers.updated?.length && (
                                                <div>
                                                    ({props.planVouchers.updated.length} Record{(props.planVouchers.updated.length > 1) && "s"} Updated)
                                                </div>
                                            )}
                                            {(props.planVouchers.generated) && (
                                                <>
                                                    <span className={"text-dark-green margin-right-x-sm"}>Generated {props.planVouchers.generated} {props.plan.defaultVoucherCodePrefix} Voucher Code{(props.planVouchers.generated > 1) && "s"} </span>
                                                    <Button
                                                        small
                                                        onClick={() => WindowUtils.scrollToBottom()}
                                                    >
                                                        View
                                                    </Button>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>

                            {props.plan && (
                                <VoucherTable
                                    planId={props.planId}
                                    search={props.search}
                                />
                            )}
                        </>
                    )}
                </div>
            ) : (
                <AccessDenied/>
            )}
        </AdminBase>
    )
}


export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const planId = Number(ownProps.match.params.planId);
        const canViewVoucherManagement = userHasPermission(PermissionTypes.VIEW, UserPermissions.VOUCHER_MANAGEMENT, userProfile);
        return {
            planId,
            plans: state.entities.plans,
            plan: state.entities.plan?.[planId] || {},
            planVouchers: state.entities.vouchers?.[planId],
            search: state.entities.genericSearch,
            canViewVoucherManagement,
        }
    },
    (dispatch) => ({
        getPlans: () => dispatch(AdminActions.getPlans()),
        selectVoucherPlan: (planId) => dispatch(AdminActions.selectVoucherPlan(planId)),
        searchVouchers: (searchParams) => dispatch(AdminActions.searchVouchers(searchParams)),
    })
)(VoucherManagement);
