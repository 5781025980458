import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Dropdown from "components/common/Dropdown";
import moment from "moment";
import InvoiceList from "components/admin/widgets/InvoiceList";
import * as CouponActions from "actions/CouponActions";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import * as UserPermissions from "constants/UserPermissions";
import AccessDenied from "components/common/AccessDenied";
import { keys } from "lodash";

function TokenViewer(props) {
    const [organize, setOrganize] = useState("all");
    const invoices = props.token ? props.token.invoices : [];

    const OPTIONS = [
        {name: "All Processed, By Invoice", value: "all"},
        {name: "Current, By Invoice (Audit)", value: "audit"},
        {name: "By Status (list)", value: "list"},
    ];

    useEffect(() => {
        if(props.canEditCouponReprocessing) {
            props.loadTokenDetails(props.tokenId);
        }
    }, [props.tokenId, props.canEditCouponReprocessing]);

    const handleOrganizeChanged = ({name, value}) => {
      setOrganize(value);
    };

    const handleRedemptionUpdate = (data) => {
        if(props.canEditCouponReprocessing) {
            props.updateRedemption({ ...data, tokenId: props.tokenId })
        }
    }

    if (!props.canEditCouponReprocessing) {
        return <AccessDenied/>;
    }

    return (
        <div>
            <div className="flex-column">
                {props.token && (
                    <div className="flex margin-top-sm margin-bottom-sm spaced-content flex-wrap">
                        <div>
                            Token State: {props.token.tokenState}
                        </div>
                        <div>
                            Clinic: {props.token.clinicName} ({props.token.clinicId})
                        </div>
                        <div>
                            Invoices: {props.token.numberOfInvoices}
                        </div>
                        <div>
                            Redemptions: {props.token.numberOfRedemptions}
                        </div>
                        <div>
                            Created: {moment(props.token.jobCreateDate).format('MM/DD/YYYY')}
                        </div>
                        {props.token.jobStartDate && (
                            <div>
                                Started: {moment(props.token.jobStartDate).format('MM/DD/YYYY')}
                            </div>
                        )}
                        {props.token.jobCompleteDate && (
                            <div>
                                Ended: {moment(props.token.jobCompleteDate).format('MM/DD/YYYY')}
                            </div>
                        )}
                    </div>
                )}
                <hr/>
            {props.token ? keys(props.token.invoices).length ? (
                <>
                    <div className="margin-bottom-sm margin-top-md">
                        <Dropdown
                            label="Organize Results"
                            options={OPTIONS}
                            name="organize"
                            value={organize}
                            onChange={handleOrganizeChanged}

                        />
                    </div>
                    <InvoiceList
                        invoices={invoices}
                        list={organize === "list"}
                        current={organize === "audit"}
                        onRedemptionUpdate={handleRedemptionUpdate}
                        canEditCouponReprocessing={props.canEditCouponReprocessing}
                    />
                </>
            ) : (
                <h3 className="text-center margin-top-md margin-bottom-sm">No Invoices</h3>
            ) : (<SpinnerTakeover show/>)}
            </div>
        </div>
    );
}

TokenViewer.propTypes = {
    tokenId: PropTypes.number.isRequired,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canEditCouponReprocessing = userHasPermission(PermissionTypes.EDIT, UserPermissions.COUPON_REPROCESSING, userProfile);
        return {
            canEditCouponReprocessing,
            token: state.entities.tokens[ownProps.tokenId],
        }
    },
    (dispatch) => ({
        loadTokenDetails: (tokenId) => dispatch(CouponActions.getTokenById(tokenId)),
        updateRedemption: (data) => dispatch(CouponActions.updateRedemption(data)),
    })

)(TokenViewer);
