export const getLinkLocation = (link, nodeId, clinicId, locationSearch=null) => {
    const providerClinicRoot = `/provider/location/${nodeId}/clinic/${clinicId}`;

    switch (link) {
        case "dashboard":
            return `${providerClinicRoot}`;
        case "couponLibrary":
            return `${providerClinicRoot}/coupons/library`;
        case "libraryProgram":
            return `${providerClinicRoot}/coupons/library/${locationSearch}`;
        case "couponHistory":
            return `${providerClinicRoot}/coupons/history`;
        case "wellnessPlanSetup":
            return `${providerClinicRoot}/wellness/plans`;
        case "petPlanTracking":
            return `${providerClinicRoot}/wellness/patients`;
        case "programs":
            return `${providerClinicRoot}/programs`;
        case "providerPetPlanTracking":
            return `${providerClinicRoot}/view/wellness/patients`;
        case "wellnessDVM":
            return `${providerClinicRoot}/wellness/commission/dvm`;
        case "providerWellnessDVM":
            return `${providerClinicRoot}/setup/wellness/commission/dvm`;
        case "wellnessAudit":
            return `${providerClinicRoot}/wellness/audit`;
        case "providerWellnessAudit":
            return `${providerClinicRoot}/view/wellness/audit`;
        case "wellnessHistory":
            return `${providerClinicRoot}/wellness/history`;
        case "providerWellnessHistory":
            return `${providerClinicRoot}/view/wellness/history`;
        case "invoiceLineItems":
            return `${providerClinicRoot}/reports/invoice-line-items`;
        case "providerInvoiceLineItems":
            return `${providerClinicRoot}/view/reports/invoice-line-items`;
        case "games":
            return `${providerClinicRoot}/games`;
        case "premier":
            return `${providerClinicRoot}/premier/${locationSearch}`;
        case "productTags":
            return `${providerClinicRoot}/products/tags`;
        case "vetcheck":
            return `${providerClinicRoot}/vetcheck`;
        case "vetcheck_handouts_sent":
            return `${providerClinicRoot}/vetcheck/handouts-sent`;
        case "vetcheck_forms_sent":
            return `${providerClinicRoot}/vetcheck/forms-sent`;
        case "vetcheck_charts_sent":
            return `${providerClinicRoot}/vetcheck/charts-sent`;
        case "vetcheck_opt_out":
            return `${providerClinicRoot}/vetcheck/opt-out`;
        case "vetcheck_manage_automations":
            return `${providerClinicRoot}/vetcheck/manage-automations`;
        case "vetcheck_dashboard":
            return `${providerClinicRoot}/vetcheck/dashboard`;
        default:
            return "";
    }
}

export const getLocationLinkMatch = (link, node, clinic, path) => {
    return getLinkLocation(link, node, clinic) === path;
}


