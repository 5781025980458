import * as ActionTypes from "constants/ActionTypes";
import * as VetCheckApi from "api/VetCheckApi";
import {handleErrorResponse} from "utils/request";
import * as MappingApi from "../api/MappingApi";
import {
    mapVetCheckAutomationsFromServerToApp,
} from "data/serverMapping";
import includes from "lodash/includes";
import toLower from "lodash/toLower";
import words from "lodash/words";
import {contentTypes} from "../constants/VetCheckContent";
import * as ApplicationStateActions from "./ApplicationStateActions";
import {getDefaultErrorHandler} from "./base";


export function searchVetCheckHandouts(query) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.VETCHECK_HANDOUT_SEARCH_FILTERED,
            query,
        })
    }
}

export function clearVetCheckHandoutsSearch(query) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.VETCHECK_HANDOUT_SEARCH_CLEARED,
            query,
        })
    }
}

export function searchVetCheckPetOwners(query) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.VETCHECK_PET_OWNERS_LOADED_SEARCH_FILTERED,
            query,
        })
    }
}

export function clearVetCheckPetaOwnersSearch(query) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.VETCHECK_PET_OWNERS_LOADED_SEARCH_CLEARED,
            query,
        })
    }
}

export function getPetOwners(clinicId, options) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.VETCHECK_PET_OWNERS_LOADING,
        });

        VetCheckApi.getPetOwners(clinicId, options)
            .then((res) => {
                    dispatch({
                        type: ActionTypes.VETCHECK_PET_OWNERS_LOADED,
                        handouts: res.body,
                    });
            })
            .catch((error) => {
                handleErrorResponse("loading pet owners", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function loadVetCheckManagement(clinicId) {
    return (dispatch) => {
        VetCheckApi.getVetCheckMappings(clinicId)
            .then((results) => {
                if(results?.body?.length) {
                    VetCheckApi.getVetCheckContent(clinicId)
                        .then((res) => {
                            dispatch({
                                type: ActionTypes.VETCHECK_AUTOMATIONS_LOADED,
                                automations: mapVetCheckAutomationsFromServerToApp(results.body, res.body),
                            });
                        })
                        .catch((error) => {
                            handleErrorResponse("loading handouts", error);
                            dispatch({
                                type: ActionTypes.REQUEST_ERROR,
                                error,
                            });
                        });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading vetcheck mappings", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function deleteVetCheckAutomation(mappingId, contentType) {
    return (dispatch) => {
        VetCheckApi.deleteVetCheckMapping(mappingId, contentType)
            .then((res) => {
                dispatch({
                    type: ActionTypes.VETCHECK_AUTOMATION_DELETED,
                    mappingId
                });
            })
            .catch((error) => {
                handleErrorResponse("deleting VetCheck automation", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}


export function loadVetCheckHandouts(clinicId) {
    return (dispatch) => {
        VetCheckApi.getVetCheckMappings(clinicId)
            .then((results) => {
                if(results?.body?.length) {
                    MappingApi.loadVetCheckHandouts(clinicId)
                        .then((res) => {
                            dispatch({
                                type: ActionTypes.VETCHECK_AUTOMATIONS_LOADED,
                                automations: mapVetCheckAutomationsFromServerToApp(results.body, res.body),
                            });
                        })
                        .catch((error) => {
                            handleErrorResponse("loading handouts", error);
                            dispatch({
                                type: ActionTypes.REQUEST_ERROR,
                                error,
                            });
                        });
                }
            })
            .catch((error) => {
                handleErrorResponse("loading vetcheck mappings", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    };
}

export function vetCheckSingleContentLoaded(content, clinicId) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.VETCHECK_SINGLE_CONTENT_LOADED,
            content,
            clinicId
        });
    };
}


// NEW VETCHECK ACTIONS
export function vetCheckRegister(clinicId, clinicInfo) {
    return (dispatch) => {
        VetCheckApi.vetCheckRegister(clinicId, clinicInfo)
            .then((res) => {
                dispatch({
                    type: ActionTypes.CLINIC_REGISTERED_FOR_VETCHECK,
                    vetcheckInfo: res.body
                });
            })
            .catch((error) => {
                handleErrorResponse("registering for VetCheck", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function getVetCheckContent(clinicId) {
    return (dispatch) => {
        dispatch(ApplicationStateActions.startWorking());
        VetCheckApi.getVetCheckContent(clinicId)
            .then((res) => {
                dispatch(ApplicationStateActions.endWorking());
                dispatch({
                    type: ActionTypes.VETCHECK_CONTENT_LOADED,
                    clinicId,
                    content: res.body
                });
            })
            .catch((error) => {
                getDefaultErrorHandler(dispatch, "loading VetCheck content")(error);
            });
    }
}

export function createVetCheckMappings(mapping, handoutInfo) {
    return (dispatch) => {
        VetCheckApi.createVetCheckMapping(mapping)
            .then((res) => {
                dispatch({
                    type: ActionTypes.VETCHECK_AUTOMATION_CREATED,
                    automation: {
                        ...handoutInfo,
                        ...res.body
                    }
                });
            })
            .catch((error) => {
                handleErrorResponse("creating VetCheck mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}

export function vetCheckStatusLoaded(status) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.CLINIC_VETCHECK_STATUS_LOADED,
            status: {
                ...status,
                clinicId: status?.clinicId
            }

        });
    }
}

export function setShowChangeVetCheckPassword(show) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.SHOW_CHANGE_VETCHECK_PASSWORD,
            show,
        })
    }
}

export function getKeywords(clinicId) {
    return (dispatch) => {
        VetCheckApi.getKeywords(clinicId)
            .then((res) => {
                dispatch({
                    type: ActionTypes.KEYWORDS_LOADED,
                    keywords: res.body
                });
            })
            .catch((error) => {
                handleErrorResponse("creating VetCheck mapping", error);
                dispatch({
                    type: ActionTypes.REQUEST_ERROR,
                    error,
                });
            });
    }
}