import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import styles from "./CouponAccordionList.scss";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import CouponTable from "./CouponTable";
import Accordion from "components/common/Accordion";
import AccordionSection from "components/common/AccordionSection";
import Button from "components/common/Button";
import Tooltip from "components/common/Tooltip";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { APPROVED } from "constants/CouponStatusIds";
import * as UserPermissions from "constants/UserPermissions";

function CouponAccordionList(props) {
    let firstVendorId = null;
    const sectionJsx = map(props.providerList, provider => {
        let vendorCoupons = orderBy(filter(props.coupons, coupon => {
            const couponVendor = coupon.vendorId;
            return couponVendor === provider.id;
        }), ["invoiceDate", "invoiceNumber"], ["desc", "desc"]);

        //Mark the approved coupons as requiresValidation, so they won't receive the checkbox
        vendorCoupons = map(vendorCoupons, coupon => {
            if (coupon.status === APPROVED) {
                coupon = {
                    ...coupon,
                    requiresValidation: true,
                }
            }
            return coupon;
        });

        const selectedRowIds = props.selectedRows ? props.selectedRows[provider.id] || [] : [];

        if (vendorCoupons.length === 0) {
            return null;
        }

        if (!firstVendorId){
            firstVendorId = provider.id;
        }

        const pendingMessage = (
            <div className={styles.pending}>
                <div>
                    {!!(props.manualPagination && props.manualPagination[provider.id]) ? props.manualPagination[provider.id].totalRecordCount : vendorCoupons.length}
                    {props.historical ? " processed" : " pending"}
                </div>
            </div>
        );

        const isApprovable = !!includes(props.clinicDetails?.approvableProviders, provider.id) || props.canEditAdminCouponProcessing;

        const sectionHeader = (
            <div className="flex flex-centered spaced-content">
                {!isApprovable && (
                    <div className="flex-none">
                        <Tooltip position="right" tip="There is a problem with your connection to this provider. Please contact Greenline for additional information.">
                            <i className="fas fa-exclamation-circle text-danger"/>
                        </Tooltip>
                    </div>
                )}
                <div className="flex-1">
                    <img src={provider.mediumImageUri} alt={provider.name} />
                </div>
            </div>
        );
        const pagination = props.manualPagination ? props.manualPagination[provider.id] : null;

        return (
            <AccordionSection
                key={provider.id}
                id={provider.id}
                header={sectionHeader}
                headerSubtext={pendingMessage}
                onClick={() => {}}
            >
                <CouponTable
                    coupons={vendorCoupons}
                    showOptionsMenu={props.showOptionsMenu}
                    showStatusIcon={props.showStatusIcon}
                    showVendorLogos={props.showVendorLogos}
                    onRowSelectChange={props.onRowSelectChange ? (data) => props.onRowSelectChange(provider.id, data) : null}
                    onEditClick={props.onEditClick}
                    // onPdfClick={props.onPdfClick}
                    onShareClick={props.onShareClick}
                    onApproveClick={props.onApproveClick}
                    onDeclineClick={props.onDeclineClick}
                    historical={props.historical}
                    canUndoDeclined={props.canUndoDeclined}
                    selectedRowIds={selectedRowIds}
                    downloadOptions={props.downloadOptions}
                    resultsPerPage={props.resultsPerPage}
                    canSelect={!!props.onRowSelectChange}
                    canViewClinicCouponAudit={props.canViewClinicCouponAudit}
                    canEditClinicCouponAudit={props.canEditClinicCouponAudit && isApprovable}
                    // handleSort={(sort, direction) => props.handleSort ? props.handleSort(sort, direction, provider.id) : {}}
                />
                {pagination &&
                    <div className="full-width flex align-center justify-around">
                    <Button
                        onClick={() => props.onManualPreviousButtonClick(provider.id)}
                        disabled={pagination.page <= 1}
                    >
                        PREV
                    </Button>
                    <span>Page {pagination.page} / {pagination.totalPages}</span>
                    <Button
                        onClick={() => props.onManualNextButtonClick(provider.id)}
                        disabled={pagination.page >= pagination.totalPages}
                    >
                        NEXT
                    </Button>
                    </div>
                }
            </AccordionSection>
        );
    });

    return (
        <Accordion
            theme={{
                root: styles.root,
                header: styles.header,
                collapse: styles.collapse,
                content: styles.collapseContent,
            }}
            defaultOpen={[firstVendorId]}
            onChange={props.onSelect ? props.onSelect : () => {}}
        >
            {sectionJsx}
        </Accordion>
    );
}

CouponAccordionList.propTypes = {
    clinicId: PropTypes.number.isRequired,
    coupons: PropTypes.arrayOf(PropTypes.object),
    providerList: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        shortName: PropTypes.string,
        displayOrder: PropTypes.number,
        smallImageUri: PropTypes.string,
        mediumImageUri: PropTypes.string,
    }),
    onSelect: PropTypes.func,
    showLogo: PropTypes.bool,
    historical: PropTypes.bool,
    resultsPerPage: PropTypes.number,
    downloadOptions: PropTypes.shape({
        period: PropTypes.string.isRequired,
        clinicId: PropTypes.string.isRequired,
        startDate: PropTypes.string,
        endDate: PropTypes.string,
    }),
    canViewClinicCouponAudit: PropTypes.bool,
    canEditClinicCouponAudit: PropTypes.bool,

    // Row display options
    showOptionsMenu: PropTypes.bool,
    showStatusIcon: PropTypes.bool,
    showVendorLogos: PropTypes.bool,

    // Row menu click handlers
    onEditClick: PropTypes.func,
    onRowSelectChange: PropTypes.func,
    onPdfClick: PropTypes.func,
    onShareClick: PropTypes.func,
    onApproveClick: PropTypes.func,
    onDeclineClick: PropTypes.func,

    // "Manual" table pagination (e.g., by server API calls)
    // handleSort:PropTypes.func,
    onManualNextButtonClick: PropTypes.func,
    onManualPreviousButtonClick: PropTypes.func,
    manualPagination: PropTypes.object, // Dictionary with provider id as key, value = { page: number, totalPages: number }
};

CouponAccordionList.defaultProps = {
    showLogo: true,
    showOptionsMenu: true,
    showStatusIcon: true,
    showVendorLogos: false,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const canEditAdminCouponProcessing = userHasPermission(PermissionTypes.EDIT, UserPermissions.ADMIN_COUPON_PROCESSING, userProfile);
        return {
            canEditAdminCouponProcessing,
            clinicDetails: state.entities.clinicDetails[ownProps.clinicId],
        }
    },
)(CouponAccordionList);
