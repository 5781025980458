import React from "react";
import PropTypes from "prop-types";
import ceil from "lodash/ceil";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import reject from "lodash/reject";
import round from "lodash/round";
import SortableDataTable from "components/common/SortableDataTable";
import Tooltip from "components/common/Tooltip";

export default function GameRoiCalculatorTable(props) {
    const tableRows = [];
    for (let i = 0; i < props.levels.length; i++) {
        forEach(filter(props.selectedProducts, "clinicId"), product => {
            tableRows.push({
                product,
                level: { ...props.levels[i] },
            });
        });
    }
    forEach(reject(props.selectedProducts, "clinicId"), product => {
        tableRows.push({
            product,
            level: {
                EstROI: null,
                IncreasePercentage: null,
                PrizeAmount: null
            }
        });
    });

    const getTotalDoses = (row) => {
        let doses = 0;
        if (props.excludeFreeDoses) {
            if(!!row.product?.pPaidDoses) {
                doses = round(row.product.pPaidDoses)
            }
        } else {
            //Includes Free Doses
            if(!!row.product?.pDoses) {
                doses = round(row.product.pDoses)
            }
        }
        if (props.includeHomeDeliveryDoses) {
            if(!!row?.product?.homePDoses) {
                doses += row.product.homePDoses
            }
        }
        return doses;
    };

    const getDoses = (row) => {
        let doses = 0;
        if (props.excludeFreeDoses) {
            if(!!row.product?.pPaidDoses) {
                doses = round(row.product.pPaidDoses)
            }
        } else {
            //Includes Free Doses
            if(!!row.product?.pDoses) {
                doses = round(row.product.pDoses)
            }
        }

        return doses;
    };

    const getProjectedDoses = (row) => {
        const doses = getTotalDoses(row);
        const increasePercentage = row.level.IncreasePercentage / 100;
        const increase = doses * increasePercentage;
        return doses + increase;
    };

    const getProjectedPackIncrease = (row) => {
        const projectedDoses = getProjectedDoses(row);
        const previousDoses = getTotalDoses(row);
        const increase = projectedDoses - previousDoses;
        return ceil(increase / row.product.preferredPackageSize);
    }

    const HOME_DELIVERY_COLUMNS = [
        {
            name: (<div className="text-center">Home Delivery<br/>Estimated<br/>Doses</div>),
            selector: "product.homePDoses",
            key: "product.homePDoses",
            format: row => {
                return (
                    <div className="text-center">
                        {!!row.product?.homePDoses ? round(row.product.homePDoses) : 0}
                    </div>
                )
            },
        }, {
            name: (<div className="text-center">In Clinic<br/>Doses</div>),
            selector: "inClinicDoses",
            key: "inClinicDoses",
            format: row => {
                return (
                    <div className="text-center">
                        {round(getDoses(row))}
                    </div>
                )
            },
        }
    ];

    const COLUMNS = [
        {
            name: "% Inc.",
            selector: "IncreasePercentage",
            key: "IncreasePercentage",
            format: row => !!row.level?.IncreasePercentage ? round(row.level.IncreasePercentage) : (
                <Tooltip
                    position="right"
                    tip={`${props.reviewData?.data?.[0]?.clinicName || "Clinic"} did not sell ${row.product.brandName} during the timeframe.`}
                >
                    <i className="fa fa-warning text-warn" />
                </Tooltip>
            )
        }, {
            name: "Product",
            selector: "brandName",
            key: "brandName",
            format: row => (
                <span className="nowrap">
                    {row.product.brandName}
                </span>
            ),
        }, {
            name: (<div className="text-center">Projected<br/>Doses</div>),
            selector: "projectedDoses",
            key: "projectedDoses",
            format: row => {
                const projectedDoses = getProjectedDoses(row);
                return (
                    <div className="text-center">
                        {!!projectedDoses ? round(projectedDoses) : 0}
                    </div>
                )
            },
        }, {
            name: (<div className="text-center">Previous<br/>Doses</div>),
            selector: "previousDoses",
            key: "previousDoses",
            format: row => (
                <div className="text-center">
                    {getTotalDoses(row)}
                </div>
            ),
        },
        ...props.includeHomeDeliveryDoses ? HOME_DELIVERY_COLUMNS : [],
        {
            name: (<div className="text-center">Projected<br/>Pack<br/>Increase</div>),
            selector: "projectedPackIncrease",
            key: "projectedPackIncrease",
            format: row => {
                const projectPackIncrease = getProjectedPackIncrease(row)
                if(!projectPackIncrease) {
                    return (
                        <div className="text-center">0</div>
                    );
                }
                return (
                    <div className="text-center">
                        {projectPackIncrease} ({row.product.preferredPackageSize}{row.product.preferredPackageUnit})
                    </div>
                );
            },
        }, {
            name: (<div className="text-center">Pets<br/>Protected</div>),
            selector: "petsProtectedCount",
            key: "petsProtectedCount",
            format: row => {
                const projectedPackIncrease = getProjectedPackIncrease(row);
                return (
                    <div className="text-center">
                        {!!projectedPackIncrease ? Math.ceil(projectedPackIncrease / 2) : 0}
                    </div>
                )
            },
        },
    ];

    return (
        <div className="full-width full-height margin-top-sm">
            {!props.selectedProducts &&
                <div className="flex justify-center">
                    <h3 style={{ color: "#aaa" }}>No Data Available</h3>
                </div>
            }
            {props.selectedProducts &&
                <div style={{ borderRadius: "4px", overflow: "hidden" }}>
                    <SortableDataTable
                        columns={COLUMNS}
                        rawData={tableRows}
                    />
                </div>
            }
        </div>
    );
}

GameRoiCalculatorTable.propTypes = {
    levels: PropTypes.arrayOf(PropTypes.object), // Probably reqiured, but none of this is final yet
    selectedProducts: PropTypes.array.isRequired,
    reviewData: PropTypes.object.isRequired,
    excludeFreeDoses: PropTypes.bool,
    includeHomeDeliveryDoses: PropTypes.bool,
}
