import React from "react";
import classnames from "classnames";
import map from "lodash/map";
import replace from "lodash/replace";
import styles from "./PointsLegend.scss";

export default function PointsLegend(props) {
    const getStyledBrandName = (words) => {
        let updatedString = replace(words, "Nexgard Plus", "<i>NexGard®</i> PLUS");
        updatedString = replace(updatedString, "Nexgard", "<i>NexGard®</i>");
        updatedString = replace(updatedString, "Heartgard", "HEARTGARD®");
        updatedString = replace(updatedString, "Combo", "COMBO");
        return <span dangerouslySetInnerHTML={{ __html: updatedString }}/>;
    };

    return (
        <div className={classnames(styles.root, "flex spaced-content align-center")}>
            <h4 className={styles.title}>Points Legend</h4>
            <ul className="flex spaced-content-sm flex-wrap flex-1">
                {map(props.legend, l => (
                    <li
                        className="flex-none"
                        key={l.ProductBrandName}
                    >
                        <b>{getStyledBrandName(l.ProductBrandName)}</b> 1 dose = {l.Multiplier} points
                    </li>
                ))}
            </ul>
        </div>
    )

}
