import React, {useMemo, useState} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import produce from "immer";
import classnames from "classnames";
import * as styles from "./MappingTable.scss";
import cloneDeep from "lodash/cloneDeep";
import filter from "lodash/filter";
import find from "lodash/find";
import findLastKey from "lodash/findLastKey";
import flatMap from "lodash/flatMap";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import keyBy from "lodash/keyBy";
import keys from "lodash/keys";
import map from "lodash/map";
import mergeWith from "lodash/mergeWith";
import orderBy from "lodash/orderBy";
import round from "lodash/round";
import union from "lodash/union";
import * as MappingApi from "api/MappingApi";
import * as ClinicActions from "actions/ClinicActions";
import * as MappingActions from "actions/MappingActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import Dropdown from "components/common/Dropdown";
import IncreasePriceForm from "components/admin/forms/IncreasePriceForm";
import Modal from "components/common/Modal";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import WellnessTableRow from "components/admin/elements/WellnessTableRow";
import {downloadFile} from "utils/request";
import {PermissionTypes, userHasPermission} from "utils/permissions/rolesPermissions";
import toast from "utils/toast";
import * as MappingTypes from "constants/MappingTypes";
import * as UserPermissions from "constants/UserPermissions";
import WellnessProvidersFilterDropdown from "components/wellness/widgets/WellnessProvidersFilterDropdown";

function WellnessTable(props) {
    const [filters, setFilters] = useState({search: props.search});
    const [formData, setFormData] = useState({});
    const [showPriceIncrease, setShowPriceIncrease] = useState(false);

    const combinedData = props.mappings ? cloneDeep(props.mappings) : {};

    const custom = (objValue, srcValue) => {
      if (_.isArray(objValue)) {
          return srcValue;
      }
    };
    mergeWith(combinedData, formData, custom);

    const PIMS_OPTIONS = useMemo(() => {
        return map(props.pims, (pim) => {
            return {name: `(${pim.remoteClinicProductDisplayID}) ${pim.name}`, value: pim.clinicProductId};
        })
    }, [props.pims]);

    const orderedPimsOptions = useMemo(() => {
        return orderBy(PIMS_OPTIONS, po => {return includes(props.disabledPimsOptions, po.value)});
    }, [PIMS_OPTIONS, props.disabledPimsOptions]);

    const handleChangeFilters = ({name, value}) => {
        setFilters({
            ...filters,
            [name]: value,
        })
    }

    const handleRowChange = (updatedRow) => {
        if (!updatedRow.mappingInstanceId) {
            updatedRow.mappingInstanceId = 1;
            updatedRow.isNew = true;
        }
        const newFormData = {
            ...formData,
            [updatedRow.serviceTypeId]: {
                ...formData[updatedRow.serviceTypeId],
                serviceTypeId: updatedRow.serviceTypeId,
                mappings: {
                    ...formData[updatedRow.serviceTypeId]?.mappings || {},
                    [updatedRow.mappingInstanceId]: {
                        ...updatedRow,
                        clinicId: props.clinicId,
                        clinicPriceScheduleId: props.pricingScheduleId,
                        hasChanges: true,
                    }
                }
            }
        }
        setFormData(newFormData);
    }

    const handleAddBlankMapping = (serviceTypeId, isTaxExempt=false, data = null) => {
        const product = find(props.products, {serviceTypeId: serviceTypeId})

        const lastKey = Number(findLastKey(combinedData[serviceTypeId]?.mappings))
        const nextMappingId = lastKey ? (lastKey + 1) : 1;

        const newFormData = {
            ...formData,
            [serviceTypeId]: {
                serviceTypeId: serviceTypeId,
                ...formData[serviceTypeId],
                mappings: {
                    ...formData[serviceTypeId]?.mappings,
                    [nextMappingId]: {
                        mappingInstanceId: nextMappingId,
                        isNew: true,
                        hasChanges: true,
                        clinicId: props.clinicId,
                        clinicPriceScheduleId: props.pricingScheduleId,
                        clinicProductId: null,
                        commissionablePrice: null,
                        complianceDuration: null,
                        isTaxExempt: isTaxExempt,
                        serviceTypeId: product.serviceTypeId,
                        ...data,
                    },
                }
            }
        };
        setFormData(newFormData);
    }

    const handleUpdateMapping = (data) => {
        props.updateWellnessMapping(data);
    };

    const handleClearChange = (row) => {
        if(row) {
            const newData = produce(formData, newFormData => {
                if(newFormData[row.serviceTypeId]?.mappings) {
                    delete newFormData[row.serviceTypeId].mappings[row.mappingInstanceId];
                }
            });
            setFormData(newData);
        } else {
            //Clear ALL formData
            setFormData({});
        }
    }

    const handleCreateMapping = (data) => {
        const newData = {
            ...data,
            mappingInstanceId: null,
            clinicId: props.clinicId,
            commissionablePrice: data.commissionablePrice ? Number(data.commissionablePrice) : null,
            complianceDuration: data.complianceDuration ? Number(data.complianceDuration) : null,
            clinicPriceScheduleId: props.pricingScheduleId,
        }
        props.createWellnessMapping(newData);
        //Removes the temporary mapping as the new mapping is being created
        handleClearChange(data);
    };

const row = (rowData, index, showCompDur=false, isFirstRow=false) => {
        return (
            <WellnessTableRow
                key={index}
                clinicId={props.clinicId}
                rowData={rowData}
                index={index}
                pimsOptions={orderedPimsOptions}
                boldPimsOptions={props.boldPimsOptions}
                disabledPimsOptions={props.disabledPimsOptions}
                handleAddBlankMapping={handleAddBlankMapping}
                handleUpdateMapping={handleUpdateMapping}
                handleCreateMapping={handleCreateMapping}
                handleChange={handleRowChange}
                onClearChanges={handleClearChange}
                showComDur={showCompDur}
                isFirstRow={isFirstRow}
                typeId={props.typeId}
            />
        );
    };

    const filteredProducts = filter(props.products, s => {
        if (!!filters.serviceCategoryName && s.serviceCategoryName !== filters.serviceCategoryName || !!filters.providerId && s.providerId !== filters.providerId ) {
            return false;
        }
        if(props.search){
            const searchTokens = props.search.toLowerCase().split(" ");
            let keep = true
            for (let i = 0; i < searchTokens.length; i++) {
                const token = searchTokens[i];
                if (!s.serviceTypeName || !s.serviceTypeName.toString().toLowerCase().includes(token)) {
                    keep = false;
                }
            }
            return keep;
        }
        return true;
    });

    const sections = map(filteredProducts, (product) => {
        let firstRow = true;
        let mappings = combinedData ? combinedData[product.serviceTypeId]?.mappings : [];
        const showCompDur = (product.serviceCategoryName === MappingTypes.PRODUCTS);

        return (
            <>
                {/*Rows with mappings*/}
                {!!keys(mappings).length ? (
                    map(orderBy(mappings, "order"), (mapping) => {
                        const showProduct = firstRow;
                        firstRow = false;
                        const rowData = {
                            ...mapping,
                            ...product,
                        };
                        return (row(rowData, `${props.pricingScheduleId}-${product.serviceTypeId}-${mapping.mappingInstanceId}`, showCompDur, showProduct))
                    })
                ) : (
                    //Section with no mappings
                    row({...product, mappingInstanceId: null}, `${props.pricingScheduleId}-${product.serviceTypeId}-null`, showCompDur, firstRow )

                )}
            </>
        );
    });

    const increasePrice = (oldPrice, increasePercentage) => {
        oldPrice = Number(oldPrice);
        increasePercentage = Number(increasePercentage);
        return round((((oldPrice/100) * increasePercentage) + oldPrice), 2);
    }

    const handleIncreasePrices = (increasePercentage) => {
        const newFormData = {
            ...formData,
            ...keyBy(map(combinedData, serviceType => {
                return {
                    ...formData[serviceType.serviceTypeId],
                    serviceTypeId: serviceType.serviceTypeId,
                    mappings: {
                        ...formData[serviceType]?.mappings,
                        ...keyBy(map(filter(serviceType.mappings, "commissionablePrice"), m => {
                            return {
                                ...formData[serviceType]?.mappings[m.mappingInstanceId],
                                mappingInstanceId: m.mappingInstanceId,
                                commissionablePrice: increasePrice(m.commissionablePrice, increasePercentage),
                                hasChanges: true
                            }
                        }), "mappingInstanceId")
                    }
                }
            }), "serviceTypeId")
        }
        setFormData(newFormData);
    }

    const handleExportExcel = async () => {
        toast.success("Generating Export...");

        const res = await MappingApi.downloadWellnessMappings(props.pricingScheduleId);
        downloadFile(res, "xlsx");
    }

    const handleSaveAll = () => {
        forEach(combinedData, serviceType => {
            forEach(filter(serviceType.mappings, "hasChanges"), mapping => {
                const newData = {
                    mappingInstanceId: null,
                    clinicId: props.clinicId,
                    commissionablePrice: mapping.commissionablePrice ? Number(mapping.commissionablePrice) : null,
                    complianceDuration: mapping.complianceDuration ? Number(mapping.complianceDuration) : null,
                    clinicPriceScheduleId: props.pricingScheduleId,
                    ...mapping,
                }
                // Need to make sure that rows have their required fields before saving
                if(newData.clinicProductId) {
                    if (newData.isNew) {
                        props.createWellnessMapping(newData);
                    } else {
                        props.updateWellnessMapping(newData);
                    }
                }
            })
        });
        setFormData({});
    }

    if(!props.canViewWellnessMapping) {
        return (
            <AccessDenied/>
        )
    }

    return (
        <div className="flex flex-column height-100-perc">
            <hr className="flex-none full-width"/>
            <div className="flex-none flex spaced-content align-center">
                <div className="flex-1 flex flex-wrap spaced-content align-center">
                    <h2 className="nowrap">Wellness Mappings</h2>
                    <h3 className="nowrap">({props.products.length} products / {props.totalMappings} mappings)</h3>
                </div>
                <div className="flex-1 flex spaced-content">
                    <div className="flex-1">
                        <WellnessProvidersFilterDropdown
                            label="Manufacturer"
                            value={filters.providerId}
                            name="providerId"
                            onChange={handleChangeFilters}
                            placeholder="--Select Manufacturer--"
                            fullWidth
                        />
                    </div>
                    <div className="flex-1">
                        <div className={classnames(styles.mdDropdowns)}>
                            <Dropdown
                                options={MappingTypes.WELLNESS_CATEGORIES}
                                label="Category"
                                value={filters.serviceCategoryName}
                                name="serviceCategoryName"
                                onChange={handleChangeFilters}
                                placeholder="--Select Category--"
                                fullWidth
                            />
                        </div>
                    </div>
                    <div className="flex-none">
                        <Button
                            large
                            onClick={handleExportExcel}
                            icon
                            type="primary"
                            disabled={!props.pricingScheduleId}
                        >
                            <i className="fa fa-file-excel"/> Export
                        </Button>
                    </div>
                </div>
            </div>
            <hr className="flex-none full-width"/>
            {props.pricingScheduleId ? (
                <>
                    <div className="flex-1">
                        {(props.products) ? (
                            <div className={classnames(styles.overflow, "height-100-perc overflow-x-scroll full-width")}>
                                <table className="table">
                                    <thead>
                                        <tr className="spaced-content">
                                            <th/>
                                            <th>Product</th>
                                            <th>Commissionable Amount</th>
                                            <th>Comp. Duration</th>
                                            <th>Tax Exempt?</th>
                                            <th>Clinic Product</th>
                                            <th/>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {!!sections ? sections : (
                                        <tr>
                                            <td>
                                                <SpinnerTakeover show/>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                                <div className={classnames(styles.actions, "flex justify-flex-end margin-top-lg spaced-content")}>
                                    <Button
                                        large
                                        onClick={() => setShowPriceIncrease(true)}
                                    >
                                        Increase Prices
                                    </Button>
                                </div>
                                <div className={styles.save}>
                                    <Button
                                        onClick={handleSaveAll}
                                        disabled={!props.canEditWellnessMapping}
                                        iconOnly
                                        large
                                    >
                                        <i className="far fa-save"/>
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <SpinnerTakeover show/>
                        )}
                    </div>
                    <Modal
                        show={showPriceIncrease}
                        onClose={() => setShowPriceIncrease(false)}
                        small
                        modalTitle="Increase All Prices"
                    >
                        <IncreasePriceForm
                            onCancel={() => setShowPriceIncrease(false)}
                            onSubmit={handleIncreasePrices}
                        />
                    </Modal>
                </>
            ) : (
                <div className="text-lg text-center margin-top-lg">
                    You must Select a Pricing Schedule to View Wellness Mappings
                </div>
            )}
        </div>
    )
}

WellnessTable.propTypes = {
    clinicId: PropTypes.number.isRequired,
    typeId: PropTypes.string.isRequired,
    pricingScheduleId: PropTypes.number,
    clinicTaxId: PropTypes.number,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const products = orderBy(state.entities.wellnessProducts, ["serviceTypeName"]);
        const wellnessMappings = state.entities.wellnessMappings[ownProps.clinicId] || {};
        const mappings = ownProps.pricingScheduleId ? wellnessMappings[ownProps.pricingScheduleId]?.serviceTypes : {};
        const disabledPimsOptions = union(...map(mappings, product => map(product.mappings, "clinicProductId"))) || [];
        const pims = state.entities.pims?.products || {}
        const boldPimsOptions = flatMap(filter(pims, "productUsedRowCount"), "clinicProductId");

        //Permissions
        const canViewClinicWellnessPriceSchedules = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_PRICE_SCHEDULES, userProfile);
        const canEditClinicWellnessPriceSchedules = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_PRICE_SCHEDULES, userProfile);
        const canViewWellnessMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.WELLNESS_MAPPING, userProfile);
        const canEditWellnessMapping = userHasPermission(PermissionTypes.EDIT, UserPermissions.WELLNESS_MAPPING, userProfile);

        return {
            products,
            mappings,
            pims,
            disabledPimsOptions,
            boldPimsOptions,
            canViewClinicWellnessPriceSchedules,
            canEditClinicWellnessPriceSchedules,
            canViewWellnessMapping,
            canEditWellnessMapping,
            search: state.entities.genericSearch,
            totalMappings: ownProps.pricingScheduleId ? wellnessMappings[ownProps.pricingScheduleId]?.totalMappings : 0,
        }
    },
    (dispatch) => ({
        loadWellness: () => dispatch(MappingActions.loadWellness()),
        loadWellnessMappings: (clinicId) => dispatch(MappingActions.loadWellnessMappings(clinicId)),
        createWellnessMapping: (data) => dispatch(MappingActions.createWellnessMapping(data)),
        updateWellnessMapping: (data) => dispatch(MappingActions.updateWellnessMapping(data)),
        loadTaxRates: (clinicId) => dispatch(ClinicActions.loadTaxRates(clinicId)),
        loadPricingSchedule: (clinicId) => dispatch(ClinicActions.loadPricingSchedule(clinicId)),
        batchUpdateClinicTax: (data) => dispatch(ClinicActions.batchUpdateClinicTax(data)),
    }),
)(WellnessTable);
