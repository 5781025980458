import React from "react";
import {
	MAIN_COLOR,
	SECONDARY_COLOR,
	TEAM_COLORS
} from "constants/Games/UniformColors";
import styles from "./KittyPodiumRight.scss";


export default function KittyPodiumRight(props){
	const teamColors = TEAM_COLORS?.[props.teamNumber] || TEAM_COLORS[0];
	return (
		// <?xml version="1.0" encoding="UTF-8"?>
		<svg
			id="Podium_-_Right_-_Kitty"
			data-name="Podium - Right - Kitty"
			// xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 800 300"
		>
			<g id="Kitty_Winner_Silver">
				<g id="Kitty_Winner_Silver_Lower">
					<path className={styles.cls10}
					      d="M242.68,267.81c.11-.71.17-1.43.16-2.14-.04-3.62-1.49-7.19-3.42-10.49-4.38-7.54-11.97-15.37-21.4-18.64-8.54-2.96-26.54-4.42-32.58,2.08-1.04,1.12-1.69,2.4-2.17,3.7-3.61,9.68,2.23,20.81,13.94,26.54-4.6,1.16-10.11,2.64-10.12,8.47,0,8.08,27.83,7.83,28.54.22.3-3.2.17-2.98-.31-5.91,2.58,3.63,4.28,3.67,8.95,5.14s10.2,1.08,13.89-1.53c2.58-1.83,4.06-4.56,4.52-7.44Z"/>
					<path className={styles.cls10}
					      d="M165.84,281.23c-6.2-14.98,11.73-14.72,12.54-16.78,3.82-9.66-7.96-36.73-1.38-77.02.46-2.82,39.41-5.45,31.87,24.31-5.27,20.79-13.46,38.51-18.57,63.15-2,9.66-23.53,8.58-24.46,6.33Z"/>
					<g>
						<path className={styles.cls8}
						      d="M302.04,253.29c-4.04,8.19-18.62,14.09-26.78,17.51-4.72,1.97-11.18,4.92-16.61,4.34-7.27-.78-8.22-6.44-1.52-10.27,4.26-2.44,8.78-2.23,13.13-5.06,4.86-3.16,9.55-6.14,14.07-9.77,3.75-3.01,8-7.18,13.15-5.84,4.08,1.06,6.56,5.03,4.56,9.09Z"/>
						<path className={styles.cls7}
						      d="M302.04,253.29c2-4.06-.48-8.03-4.56-9.09-5.14-1.34-9.39,2.83-13.15,5.84-.05.04-.1.08-.16.12,2.97,1.88,5.61,4.35,7.08,7.36.68,1.39,1.08,3.08,1.51,4.78,4.1-2.6,7.6-5.61,9.28-9.01Z"/>
					</g>
					<path className={styles.cls4}
					      d="M204.86,261.86c-7.83-34.04-35.43-37.18-33.87-66.59,2-37.64,51.09-36.08,72.04-11.34,15.97,18.86,34.1,42.84,19.33,86.66-7.68,22.79-52.75,11.94-57.51-8.74Z"/>
					<path className={styles.cls8}
					      d="M267.46,247.72c.43-3.35.5-7.43.25-9.83-.99-9.4-32.37,4.97-30.24,9.48,4.21,8.92,20.5,6.21,29.99.34Z"/>
				</g>
				<path
					id="Shirt"
					className={styles.cls2}
					d="M171.62,210.63c-3.96-9.97-1.27-25.74,6.57-35.28l3.13,1.59c-3.9,8.35-6.94,21.81,2.84,23.99,9.01,2.01,13.23-6.1,15.29-11.94,5.65-15.97,18.1-21.56,30.39-15.85,19.22,8.94,39.71,46.19,39.71,63.42-17.13,17.78-40.86,16.3-71.51,11-6.51-16.4-21.86-25.48-26.41-36.94ZM228.47,216.5c9.62-2.52,15.4-12.28,12.91-21.79-2.49-9.51-12.32-15.17-21.94-12.65-9.62,2.52-15.4,12.28-12.91,21.79,2.49,9.51,12.32,15.17,21.94,12.65Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes">
					<path
						className={styles.cls13}
						d="M186.43,202.6c7.15-.52,11.19-7.67,15.82-15.87,5.13-9.09,10.95-19.4,22.41-23.24l-.85-2.55c-12.44,4.17-18.53,14.95-23.9,24.47-4.68,8.29-8.37,14.83-14.78,14.53-3.42-.17-4.99-.72-6.19-4.21-2.55-7.45,1.58-16.42,5.82-22.77l-2.57-.81c-3.49,6.33-9.12,16.59-5.45,25.11,1.55,3.61,4.02,5.16,8.26,5.36.49.02.97.02,1.43-.02Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls13}
						d="M212.31,249.13c8.47-.59,16.72-2.09,24.48-4.48,12.51-3.85,22.55-9.8,31.35-17.75-.3-1.51-1.23-4.19-1.68-5.56-5.9,4.8-19.18,14.61-31.2,18.31-13.22,4.07-24.71,5.96-39.48,4.5.17.83,1.64,3.49,2.04,4.62,6.14.51,8.48.78,14.49.36Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls13}
						d="M222.58,180.26c10.61-.99,20.03,6.73,21.02,17.22.98,10.49-6.84,19.83-17.45,20.82-10.61.99-20.03-6.73-21.02-17.22-.98-10.49,6.84-19.83,17.45-20.82ZM225.9,215.7c9.17-.86,15.94-8.93,15.09-17.98-.85-9.05-9-15.72-18.17-14.86-9.17.86-15.94,8.93-15.09,17.98.85,9.05,9,15.72,18.17,14.86Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Winner_Silver_Mid">
					<path className={styles.cls4}
					      d="M170.93,283.32c-6.77-14.73,16.76-15.51,17.82-17.45,4.96-9.12,3.15-43.25,20.12-74.46,6.56-12.06,39.57-5.45,25.91,22.04-9.59,19.29-27.39,46.44-35.69,66.6-3.35,8.13-26.88,6.07-28.16,3.27Z"/>
					<g>
						<path className={styles.cls12}
						      d="M220.18,175.19c-4.94,9.84-31.22,20.01-41.27,17.41-4.29-4.85-3.35-12.49-2.31-15.98.68-2.26,1.7-1.26,2.81-.64-1.79,5.97-2.47,11.97-.24,15.11,9.41.56,30.47-15.98,39.06-17.86,1.75-.38,1.13,1.05,1.94,1.96Z"/>
						<g>
							<circle className={styles.cls1} cx="177.29" cy="208" r="12.43"/>
							<ellipse className={styles.cls14} cx="179.27" cy="193.13" rx="2.89" ry="1.49"
							         transform="translate(-35.89 345.24) rotate(-82.39)"/>
							<circle className={styles.cls14} cx="176.26" cy="206.53" r="12.43"/>
						</g>
					</g>
					<path className={styles.cls10}
					      d="M224.93,231.62c-6.25-2.09-19.7-3.08-25.67,4.09-6.89,8.27-5.16,22.94,6.69,32.59,11.1,9.04,18-34.96,18-34.96.33-.54.98-1.6.98-1.72Z"/>
					<path className={styles.cls4}
					      d="M253.3,259.38c-3.47-9.07-9.92-16.51-19.03-22.39-7.61-4.91-25.17-7.91-31.88-.61-1.16,1.26-1.94,2.73-2.56,4.22-4.64,11.13-.02,24.44,11.01,31.73-4.7,1.12-10.82,2.77-10.99,9.38-.28,11.21,25.23,9.85,28.77,3.07,1.68-3.21.33-6.28-.66-9.34,1.85,2.95,5.14,3.87,9.64,5.83,4.49,1.96,12.19.33,15-1.77,4.53-3.38,5.37-7.97.71-20.13Z"/>
					<g>
						<g>
							<path className={styles.cls8}
							      d="M104.35,109.06c13.12,2.52,26.87,7.83,34.73,18,.39.5-.49,1.07-.88.58-7.7-9.96-21.22-15.16-34.07-17.63-.65-.12-.45-1.08.21-.95Z"/>
							<path className={styles.cls8}
							      d="M97.87,125.89c13.59.63,26.63,3.14,38.67,8.58.59.27.12,1.15-.48.88-11.96-5.4-24.9-7.88-38.4-8.51-.67-.03-.46-.98.21-.95Z"/>
							<path className={styles.cls8}
							      d="M98.55,138.46c11.89-1.63,23.72-.69,35.37.88.67.09.46,1.04-.21.96-11.56-1.56-23.3-2.5-35.09-.88-.68.09-.75-.87-.07-.96Z"/>
						</g>
						<path className={styles.cls3}
						      d="M210.15,75.04c-1.39,9.2-2.78,17.75-3.15,19.21-.77,3.05-.5,3.11-2.85,5.32-3.08,2.91-42.91-1.97-47.46-10.53,7.51-9.61,22.84-22.92,32.01-28.23,6.58-3.81,16.95-10.1,24.53-10.81.51-.05-1.28,13.05-3.08,25.04Z"/>
						<g>
							<path className={styles.cls4}
							      d="M230.36,130.82c-45.15-31.58-21.25-41.78-8.61-44.73-4.02-1.82-8.18-3.36-12.34-4.59-7.44,4.17-30.4,14.98-59.21,8.72-5.75,6.21-9.78,14.25-13.84,21.73-4.64,8.56-12.02,9.66-11,27.7,1.18,20.84,15.75,26.18,25.32,31.22,11.38,5.99,25.87,9.23,38.74,10.64,8.58.94,24.29-.5,32.42-3.99,16.78-7.21,25.34-21.94,30.4-34.94.25-.63.47-1.26.68-1.89-11.68-3.52-22.56-9.86-22.56-9.86Z"/>
							<path className={styles.cls7}
							      d="M245.85,99.32c-3.84-6.26-9.77-13.78-24.1-13.23-12.63,2.95-36.53,13.15,8.61,44.73,0,0,10.89,6.34,22.56,9.86,5.39-15.9.44-29.08-7.08-41.36Z"/>
							<path className={styles.cls8}
							      d="M209.41,81.5c-10.59-3.14-21.17-4.31-29.37-3.15-9.23,1.31-21.93,3.91-28.74,10.72-.37.37-.74.76-1.1,1.15,28.81,6.26,51.76-4.55,59.21-8.72Z"/>
						</g>
						<path className={styles.cls5}
						      d="M179.33,119.76c-3.75,7.54.55,16.51,9.6,20.03,9.05,3.52,19.44.26,23.18-7.29,3.75-7.54-.55-16.51-9.6-20.03-9.05-3.52-19.43-.26-23.18,7.29Z"/>
						<path className={styles.cls5}
						      d="M145.43,105.97c-3.45,5.79-2.79,11.82,1.46,13.46,4.26,1.64,10.5-1.73,13.94-7.52,3.44-5.79,2.79-11.82-1.47-13.46-4.26-1.64-10.5,1.73-13.94,7.52Z"/>
						<g>
							<path className={styles.cls8}
							      d="M210.58,155.61c-11.33-6.65-25-11.84-37.66-10.02-.62.09-.36,1.03.25.94,12.39-1.78,25.82,3.34,36.91,9.85.56.33,1.06-.44.49-.77Z"/>
							<path className={styles.cls8}
							      d="M203.94,170.98c-10.39-8.22-21.65-14.82-34.16-18.84-.62-.2-.87.69-.25.89,12.43,3.99,23.6,10.55,33.92,18.71.51.41,1.01-.37.5-.77Z"/>
							<path className={styles.cls8}
							      d="M194.93,178.97c-7.61-8.65-16.93-15.54-26.54-21.89-.55-.36-1.04.41-.49.77,9.54,6.3,18.77,13.14,26.33,21.72.43.49,1.14-.1.7-.6Z"/>
						</g>
						<g>
							<path className={styles.cls11}
							      d="M182.74,120.71c-3.42,6.75-2.68,14.05,1.63,16.29,4.32,2.25,10.59-1.4,14.01-8.15,3.42-6.75,2.68-14.04-1.63-16.29-4.32-2.25-10.59,1.4-14.01,8.15Z"/>
							<path className={styles.cls9}
							      d="M192.95,122.36c-1.71,3.38-4.82,5.23-6.95,4.12-2.13-1.11-2.46-4.75-.75-8.13,1.71-3.38,4.82-5.23,6.95-4.12,2.13,1.11,2.46,4.75.75,8.13Z"/>
						</g>
						<g>
							<path className={styles.cls11}
							      d="M146.92,106.54c-2.8,5.67-2.95,11.4-.33,12.8,2.61,1.4,7-2.06,9.79-7.73,2.8-5.67,2.95-11.4.34-12.8-2.61-1.4-7,2.06-9.8,7.73Z"/>
							<path className={styles.cls9}
							      d="M153.41,106.94c-1.4,2.84-3.58,4.59-4.86,3.9-1.29-.69-1.19-3.55.21-6.39,1.4-2.84,3.58-4.59,4.87-3.9,1.29.69,1.19,3.55-.21,6.39Z"/>
						</g>
						<path className={styles.cls8}
						      d="M150.36,146.29c-.4.49-.77,1.06-.9,1.22-.83,1-1.83,1.94-3.08,2.36-2.45.82-5.77.11-7.73-1.65-2.33-2.1-2.65-4.65-1.94-7.57.35-1.44.56-3.23,1.29-4.53.16-.29.51-.61.62-.92.13-.4-.4-1.34-.86-1.37-.93-.05-1.25,1.2-1.44,1.89-.29,1.08-.65,2-1.07,3.03-.77,1.89-1.65,4.31-3.63,5.25-1.45.68-3.14.33-3.82-1.21-.34-.79-.25-1.58-.1-2.4.04-.23.4-1.79-.12-1.87-.87-.14-.89,1.77-.94,2.28-.22,2.15.66,5.22,3.31,5.13,1.1-.03,2.13-.59,2.98-1.24.79-.6,1.85-1.23,2.1-2.26-.22,2.69.71,4.96,2.73,6.77.9.8,2,1.42,3.19,1.82,2.81.94,5.61.58,7.98-1.16.66-.48,4-3.52,2.62-4.35-.3-.18-.76.26-1.18.78Z"/>
						<path className={styles.cls6}
						      d="M138.05,124.47c1.07.42,2.07,1.11,3.21,1.7,1.82.94,4.94,1.62,6.23,3,3.11,3.31-1.92,5.67-5.66,6.2-4.24.61-11.62-1.8-10.44-7.44.4-1.91,1.73-3.19,3.61-3.68,1.15-.3,2.12-.14,3.05.22Z"/>
					</g>
					<g>
						<path className={styles.cls7}
						      d="M265.05,112.62c-6.94,6.69-13.46,12.84-14.68,13.81-2.54,2.02-2.37,2.24-5.61,2.57-4.27.44-24.46-32.03-22.58-41.94,9.76-3.86,24.56-1.12,35.13.31,7.57,1.02,19.7,2.4,26.09,6.6.43.28-9.3,9.92-18.34,18.65Z"/>
						<path className={styles.cls6} d="M231.07,97.23s23.6-3.65,41.44.92l-26.43,24.01-15.01-24.93Z"/>
					</g>
				</g>
				<g id="Stripe">
					<path
						className={styles.cls13}
						d="M246.52,151.9c-19.95-30.69-66.54-57.94-97.3-59.82-2.36-.14,10.23-13.94,18.03-14.31,20.14-.95,81.16,33.18,86.78,59.75.84,3.95-5.64,17.25-7.51,14.37Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
			</g>
			<g id="Kitty_Winner_Silver-2" data-name="Kitty_Winner_Silver">
				<g id="Kitty_Winner_Silver_Lower-2" data-name="Kitty_Winner_Silver_Lower">
					<path className={styles.cls10}
					      d="M642.68,267.81c.11-.71.17-1.43.16-2.14-.04-3.62-1.49-7.19-3.42-10.49-4.38-7.54-11.97-15.37-21.4-18.64-8.54-2.96-26.54-4.42-32.58,2.08-1.04,1.12-1.69,2.4-2.17,3.7-3.61,9.68,2.23,20.81,13.94,26.54-4.6,1.16-10.11,2.64-10.12,8.47,0,8.08,27.83,7.83,28.54.22.3-3.2.17-2.98-.31-5.91,2.58,3.63,4.28,3.67,8.95,5.14s10.2,1.08,13.89-1.53c2.58-1.83,4.06-4.56,4.52-7.44Z"/>
					<path className={styles.cls10}
					      d="M565.84,281.23c-6.2-14.98,11.73-14.72,12.54-16.78,3.82-9.66-7.96-36.73-1.38-77.02.46-2.82,39.41-5.45,31.87,24.31-5.27,20.79-13.46,38.51-18.57,63.15-2,9.66-23.53,8.58-24.46,6.33Z"/>
					<g>
						<path className={styles.cls8}
						      d="M702.04,253.29c-4.04,8.19-18.62,14.09-26.78,17.51-4.72,1.97-11.18,4.92-16.61,4.34-7.27-.78-8.22-6.44-1.52-10.27,4.26-2.44,8.78-2.23,13.13-5.06,4.86-3.16,9.55-6.14,14.07-9.77,3.75-3.01,8-7.18,13.15-5.84,4.08,1.06,6.56,5.03,4.56,9.09Z"/>
						<path className={styles.cls7}
						      d="M702.04,253.29c2-4.06-.48-8.03-4.56-9.09-5.14-1.34-9.39,2.83-13.15,5.84-.05.04-.1.08-.16.12,2.97,1.88,5.61,4.35,7.08,7.36.68,1.39,1.08,3.08,1.51,4.78,4.1-2.6,7.6-5.61,9.28-9.01Z"/>
					</g>
					<path className={styles.cls4}
					      d="M604.86,261.86c-7.83-34.04-35.43-37.18-33.87-66.59,2-37.64,51.09-36.08,72.04-11.34,15.97,18.86,34.1,42.84,19.33,86.66-7.68,22.79-52.75,11.94-57.51-8.74Z"/>
					<path className={styles.cls8}
					      d="M667.46,247.72c.43-3.35.5-7.43.25-9.83-.99-9.4-32.37,4.97-30.24,9.48,4.21,8.92,20.5,6.21,29.99.34Z"/>
				</g>
				<path
					id="Shirt-2"
					data-name="Shirt"
					className={styles.cls2}
					d="M571.62,210.63c-3.96-9.97-1.27-25.74,6.57-35.28l3.13,1.59c-3.9,8.35-6.94,21.81,2.84,23.99,9.01,2.01,13.23-6.1,15.29-11.94,5.65-15.97,18.1-21.56,30.39-15.85,19.22,8.94,39.71,46.19,39.71,63.42-17.13,17.78-40.86,16.3-71.51,11-6.51-16.4-21.86-25.48-26.41-36.94ZM628.47,216.5c9.62-2.52,15.4-12.28,12.91-21.79-2.49-9.51-12.32-15.17-21.94-12.65-9.62,2.52-15.4,12.28-12.91,21.79,2.49,9.51,12.32,15.17,21.94,12.65Z"
					style={{ fill: `${teamColors[MAIN_COLOR]}` }}
				/>
				<g id="Stripes-2" data-name="Stripes">
					<path
						className={styles.cls13}
						d="M586.43,202.6c7.15-.52,11.19-7.67,15.82-15.87,5.13-9.09,10.95-19.4,22.41-23.24l-.85-2.55c-12.44,4.17-18.53,14.95-23.9,24.47-4.68,8.29-8.37,14.83-14.78,14.53-3.42-.17-4.99-.72-6.19-4.21-2.55-7.45,1.58-16.42,5.82-22.77l-2.57-.81c-3.49,6.33-9.12,16.59-5.45,25.11,1.55,3.61,4.02,5.16,8.26,5.36.49.02.97.02,1.43-.02Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls13}
						d="M612.31,249.13c8.47-.59,16.72-2.09,24.48-4.48,12.51-3.85,22.55-9.8,31.35-17.75-.3-1.51-1.23-4.19-1.68-5.56-5.9,4.8-19.18,14.61-31.2,18.31-13.22,4.07-24.71,5.96-39.48,4.5.17.83,1.64,3.49,2.04,4.62,6.14.51,8.48.78,14.49.36Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
					<path
						className={styles.cls13}
						d="M622.58,180.26c10.61-.99,20.03,6.73,21.02,17.22.98,10.49-6.84,19.83-17.45,20.82-10.61.99-20.03-6.73-21.02-17.22-.98-10.49,6.84-19.83,17.45-20.82ZM625.9,215.7c9.17-.86,15.94-8.93,15.09-17.98-.85-9.05-9-15.72-18.17-14.86-9.17.86-15.94,8.93-15.09,17.98.85,9.05,9,15.72,18.17,14.86Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
				<g id="Kitty_Winner_Silver_Mid-2" data-name="Kitty_Winner_Silver_Mid">
					<path className={styles.cls4}
					      d="M570.93,283.32c-6.77-14.73,16.76-15.51,17.82-17.45,4.96-9.12,3.15-43.25,20.12-74.46,6.56-12.06,39.57-5.45,25.91,22.04-9.59,19.29-27.39,46.44-35.69,66.6-3.35,8.13-26.88,6.07-28.16,3.27Z"/>
					<g>
						<path className={styles.cls12}
						      d="M620.18,175.19c-4.94,9.84-31.22,20.01-41.27,17.41-4.29-4.85-3.35-12.49-2.31-15.98.68-2.26,1.7-1.26,2.81-.64-1.79,5.97-2.47,11.97-.24,15.11,9.41.56,30.47-15.98,39.06-17.86,1.75-.38,1.13,1.05,1.94,1.96Z"/>
						<g>
							<circle className={styles.cls1} cx="577.29" cy="208" r="12.43"/>
							<ellipse className={styles.cls14} cx="579.27" cy="193.13" rx="2.89" ry="1.49"
							         transform="translate(311.13 741.72) rotate(-82.39)"/>
							<circle className={styles.cls14} cx="576.26" cy="206.53" r="12.43"/>
						</g>
					</g>
					<path className={styles.cls10}
					      d="M624.93,231.62c-6.25-2.09-19.7-3.08-25.67,4.09-6.89,8.27-5.16,22.94,6.69,32.59,11.1,9.04,18-34.96,18-34.96.33-.54.98-1.6.98-1.72Z"/>
					<path className={styles.cls4}
					      d="M653.3,259.38c-3.47-9.07-9.92-16.51-19.03-22.39-7.61-4.91-25.17-7.91-31.88-.61-1.16,1.26-1.94,2.73-2.56,4.22-4.64,11.13-.02,24.44,11.01,31.73-4.7,1.12-10.82,2.77-10.99,9.38-.28,11.21,25.23,9.85,28.77,3.07,1.68-3.21.33-6.28-.66-9.34,1.85,2.95,5.14,3.87,9.64,5.83,4.49,1.96,12.19.33,15-1.77,4.53-3.38,5.37-7.97.71-20.13Z"/>
					<g>
						<g>
							<path className={styles.cls8}
							      d="M504.35,109.06c13.12,2.52,26.87,7.83,34.73,18,.39.5-.49,1.07-.88.58-7.7-9.96-21.22-15.16-34.07-17.63-.65-.12-.45-1.08.21-.95Z"/>
							<path className={styles.cls8}
							      d="M497.87,125.89c13.59.63,26.63,3.14,38.67,8.58.59.27.12,1.15-.48.88-11.96-5.4-24.9-7.88-38.4-8.51-.67-.03-.46-.98.21-.95Z"/>
							<path className={styles.cls8}
							      d="M498.55,138.46c11.89-1.63,23.72-.69,35.37.88.67.09.46,1.04-.21.96-11.56-1.56-23.3-2.5-35.09-.88-.68.09-.75-.87-.07-.96Z"/>
						</g>
						<path className={styles.cls3}
						      d="M610.15,75.04c-1.39,9.2-2.78,17.75-3.15,19.21-.77,3.05-.5,3.11-2.85,5.32-3.08,2.91-42.91-1.97-47.46-10.53,7.51-9.61,22.84-22.92,32.01-28.23,6.58-3.81,16.95-10.1,24.53-10.81.51-.05-1.28,13.05-3.08,25.04Z"/>
						<g>
							<path className={styles.cls4}
							      d="M630.36,130.82c-45.15-31.58-21.25-41.78-8.61-44.73-4.02-1.82-8.18-3.36-12.34-4.59-7.44,4.17-30.4,14.98-59.21,8.72-5.75,6.21-9.78,14.25-13.84,21.73-4.64,8.56-12.02,9.66-11,27.7,1.18,20.84,15.75,26.18,25.32,31.22,11.38,5.99,25.87,9.23,38.74,10.64,8.58.94,24.29-.5,32.42-3.99,16.78-7.21,25.34-21.94,30.4-34.94.25-.63.47-1.26.68-1.89-11.68-3.52-22.56-9.86-22.56-9.86Z"/>
							<path className={styles.cls7}
							      d="M645.85,99.32c-3.84-6.26-9.77-13.78-24.1-13.23-12.63,2.95-36.53,13.15,8.61,44.73,0,0,10.89,6.34,22.56,9.86,5.39-15.9.44-29.08-7.08-41.36Z"/>
							<path className={styles.cls8}
							      d="M609.41,81.5c-10.59-3.14-21.17-4.31-29.37-3.15-9.23,1.31-21.93,3.91-28.74,10.72-.37.37-.74.76-1.1,1.15,28.81,6.26,51.76-4.55,59.21-8.72Z"/>
						</g>
						<path className={styles.cls5}
						      d="M579.33,119.76c-3.75,7.54.55,16.51,9.6,20.03,9.05,3.52,19.44.26,23.18-7.29,3.75-7.54-.55-16.51-9.6-20.03-9.05-3.52-19.43-.26-23.18,7.29Z"/>
						<path className={styles.cls5}
						      d="M545.43,105.97c-3.45,5.79-2.79,11.82,1.46,13.46,4.26,1.64,10.5-1.73,13.94-7.52,3.44-5.79,2.79-11.82-1.47-13.46-4.26-1.64-10.5,1.73-13.94,7.52Z"/>
						<g>
							<path className={styles.cls8}
							      d="M610.58,155.61c-11.33-6.65-25-11.84-37.66-10.02-.62.09-.36,1.03.25.94,12.39-1.78,25.82,3.34,36.91,9.85.56.33,1.06-.44.49-.77Z"/>
							<path className={styles.cls8}
							      d="M603.94,170.98c-10.39-8.22-21.65-14.82-34.16-18.84-.62-.2-.87.69-.25.89,12.43,3.99,23.6,10.55,33.92,18.71.51.41,1.01-.37.5-.77Z"/>
							<path className={styles.cls8}
							      d="M594.93,178.97c-7.61-8.65-16.93-15.54-26.54-21.89-.55-.36-1.04.41-.49.77,9.54,6.3,18.77,13.14,26.33,21.72.43.49,1.14-.1.7-.6Z"/>
						</g>
						<g>
							<path className={styles.cls11}
							      d="M582.74,120.71c-3.42,6.75-2.68,14.05,1.63,16.29,4.32,2.25,10.59-1.4,14.01-8.15,3.42-6.75,2.68-14.04-1.63-16.29-4.32-2.25-10.59,1.4-14.01,8.15Z"/>
							<path className={styles.cls9}
							      d="M592.95,122.36c-1.71,3.38-4.82,5.23-6.95,4.12-2.13-1.11-2.46-4.75-.75-8.13,1.71-3.38,4.82-5.23,6.95-4.12,2.13,1.11,2.46,4.75.75,8.13Z"/>
						</g>
						<g>
							<path className={styles.cls11}
							      d="M546.92,106.54c-2.8,5.67-2.95,11.4-.33,12.8,2.61,1.4,7-2.06,9.79-7.73,2.8-5.67,2.95-11.4.34-12.8-2.61-1.4-7,2.06-9.8,7.73Z"/>
							<path className={styles.cls9}
							      d="M553.41,106.94c-1.4,2.84-3.58,4.59-4.86,3.9-1.29-.69-1.19-3.55.21-6.39,1.4-2.84,3.58-4.59,4.87-3.9,1.29.69,1.19,3.55-.21,6.39Z"/>
						</g>
						<path className={styles.cls8}
						      d="M550.36,146.29c-.4.49-.77,1.06-.9,1.22-.83,1-1.83,1.94-3.08,2.36-2.45.82-5.77.11-7.73-1.65-2.33-2.1-2.65-4.65-1.94-7.57.35-1.44.56-3.23,1.29-4.53.16-.29.51-.61.62-.92.13-.4-.4-1.34-.86-1.37-.93-.05-1.25,1.2-1.44,1.89-.29,1.08-.65,2-1.07,3.03-.77,1.89-1.65,4.31-3.63,5.25-1.45.68-3.14.33-3.82-1.21-.34-.79-.25-1.58-.1-2.4.04-.23.4-1.79-.12-1.87-.87-.14-.89,1.77-.94,2.28-.22,2.15.66,5.22,3.31,5.13,1.1-.03,2.13-.59,2.98-1.24.79-.6,1.85-1.23,2.1-2.26-.22,2.69.71,4.96,2.73,6.77.9.8,2,1.42,3.19,1.82,2.81.94,5.61.58,7.98-1.16.66-.48,4-3.52,2.62-4.35-.3-.18-.76.26-1.18.78Z"/>
						<path className={styles.cls6}
						      d="M538.05,124.47c1.07.42,2.07,1.11,3.21,1.7,1.82.94,4.94,1.62,6.23,3,3.11,3.31-1.92,5.67-5.66,6.2-4.24.61-11.62-1.8-10.44-7.44.4-1.91,1.73-3.19,3.61-3.68,1.15-.3,2.12-.14,3.05.22Z"/>
					</g>
					<g>
						<path className={styles.cls7}
						      d="M665.05,112.62c-6.94,6.69-13.46,12.84-14.68,13.81-2.54,2.02-2.37,2.24-5.61,2.57-4.27.44-24.46-32.03-22.58-41.94,9.76-3.86,24.56-1.12,35.13.31,7.57,1.02,19.7,2.4,26.09,6.6.43.28-9.3,9.92-18.34,18.65Z"/>
						<path className={styles.cls6} d="M631.07,97.23s23.6-3.65,41.44.92l-26.43,24.01-15.01-24.93Z"/>
					</g>
				</g>
				<g id="Stripe-2" data-name="Stripe">
					<path
						className={styles.cls13}
						d="M646.52,151.9c-19.95-30.69-66.54-57.94-97.3-59.82-2.36-.14,10.23-13.94,18.03-14.31,20.14-.95,81.16,33.18,86.78,59.75.84,3.95-5.64,17.25-7.51,14.37Z"
						style={{ fill: `${teamColors[SECONDARY_COLOR]}` }}
					/>
				</g>
			</g>
		</svg>
	);
};