import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import {windowSizeContext} from "AppRoot";
import { InfoWindow } from "@react-google-maps/api";
import styles from "./ProviderDashboard.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import get from "lodash/get";
import upperFirst from "lodash/upperFirst";
import * as ProviderActions from "actions/ProviderActions";
import ClinicMarkerInfoWindow from "components/provider/widgets/ClinicMarkerInfoWindow";
import NotificationModal from "components/notifications/widgets/NotificationModal";
import NotificationsDashboardWidget from "components/dashboard/widgets/NotificationsDashboardWidget";
import ProviderBase from "components/layout/ProviderBase";
import ProviderClinicDetailsModal from "components/provider/widgets/ProviderClinicDetailsModal";
import ProviderClinicTable from "components/provider/widgets/ProviderClinicTable";
import ProviderLocationTable from "components/provider/widgets/ProviderLocationTable";
import ProviderTableAndMapContainer from "components/provider/widgets/ProviderTableAndMapContainer";
import ProviderTitleBar from "components/provider/widgets/ProviderTitleBar";
import ProviderTileGrid from "components/provider/widgets/ProviderTileGrid";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import * as ClinicAdminLinks from "utils/ClinicAdminLinks";
import { getUnreadNotificationCount } from "utils/ClinicData";
import * as ClinicProviderLinks from "utils/ClinicProviderLinks";
import * as ProviderLinks from "utils/ProviderLinks";
import { PermissionTypes, userHasPermission } from "utils/permissions/rolesPermissions";
import { PENDING } from "constants/Notifications";
import { CLINIC, DISTRICT, HQ, REGION, TERRITORY } from "constants/OrgNodeTypes";
import { PREMIER_PET_CARE_PLAN } from "constants/ProviderIds";
import * as UserPermissions from "constants/UserPermissions";
import * as UserRoles from "constants/UserRoles";
import CorporateGroupFilterDropdown from "components/provider/widgets/CorporateGroupFilterDropdown";
import * as OrgNodeTypes from "constants/OrgNodeTypes";

function ProviderDashboard(props) {
    const [showAllColumns, setShowAllColumns] = useState(false);
    const [clinicDetailsId, setClinicDetailsId] = useState(null);
    const [infoWindowMarkerId, setInfoWindowMarkerId] = useState(null);
    const [selectedNotificationId, setSelectedNotificationId] = useState(null);
    const [hovering, setHovering] = useState(null);
    const [corporateGroupFilter, setCorporateGroupFilter] = useState(null);

    const windowContext = useContext(windowSizeContext) || {};

    const filteredClinics = useMemo(() => {
        if(!!corporateGroupFilter) {
            if(corporateGroupFilter === "UNKNOWN") {
                return filter(props.clinics, {accountNumber: null});
            }
            return filter(props.clinics, {accountNumber: corporateGroupFilter});
        } else {
            return props.clinics;
        }
        
    }, [props.clinics, corporateGroupFilter]);

    const WELLNESS_TILES = [
        {
            id: "activeClinics",
            title: "Total Active Clinics",
            value: get(props.node, "stats.clinics.goGreen", 0),
            link: "clinics",
        }
    ]


    const TILES = [
        ...WELLNESS_TILES,
        {
            id: "totalPendingCoupons",
            title: "Total Pending Coupons",
            value: get(props.node, "stats.coupons.pending", 0),
        }, {
            id: "couponsOnly",
            title: "Coupons Only",
            value: get(props.node, "stats.clinics.couponsOnly", 0) || get(props.node, "stats.clinics.goGreen", 0),
        }, {
            id: "generatedMtd",
            title: "Generated MTD",
            value: get(props.node, "stats.coupons.mtd.created", 0),
        }, {
            id: "generatedYtd",
            title: "Generated YTD",
            value: get(props.node, "stats.coupons.ytd.created", 0),
        },
    ];

    useEffect(() => {
        if(!!props.nodeOverview) {
            //Load the clinics if needed
            if (props.useClinics) {
                if (!props.clinics) {
                    props.getNodeClinics(props.nodeId);
                }
            } else {
                //Only load the child nodeIds if there are child nodes
                if (!!props.nodeOverview?.childNodeIds?.length) {
                    if (!props.nodeChildren) {
                        props.getChildNodes(props.nodeId);
                    }
                }
            }
        }
    }, [props.nodeOverview]);

    useEffect(() => {
        if(props.canViewNotifications && !props.isAdmin) {
            props.getProviderDashboard();
        }
    }, [props.providerId]);

    useEffect(() => {
        const alerts = filter(props.notifications, n => (n.isAlert && !n.dismissed && n.enrollmentState === PENDING));
        if(!!alerts.length) {
            setSelectedNotificationId(alerts[0].notificationId);
        }
    }, [props.notifications]);

    const getMapMarkerDataSource = () => {
        if(props.useClinics) {
            return filteredClinics;
        }
        return props.nodeChildren;
    }

    const getPageTitle = () => {
        if (!props.node) {
            return "";
        }

        switch (props.node?.nodeType) {
            case HQ:
            case REGION:
            case DISTRICT:
            case TERRITORY:
                return props.node?.name;
            default:
                return `${props.userProfile ? props.userProfile.name : ""} | ${upperFirst(props.node?.nodeType)}`
        }
    };

    const goToNode = (nodeId) => {
        // if (props.canDrillDownClinics) {
            if (nodeId === props.userProfile?.nodeId) {
                // Send user to "/" instead of long url if it is their own node
                props.history.push(ProviderLinks.getLinkLocation("dashboard", {
                    providerId: props.providerId,
                    isAdmin: props.isAdmin,
                }));
            } else {
                props.history.push(ProviderLinks.getLinkLocation("dashboard", {
                    providerId: props.providerId,
                    nodeId,
                    isAdmin: props.isAdmin
                }));
            }
        // }
    };

    const goToClinic = (clinicId) => {
        // if (props.canDrillDownClinics) {
            if (props.isAdmin) {
                props.history.push(ClinicAdminLinks.getLinkLocation("dashboard", clinicId));
            } else {
                props.history.push(ClinicProviderLinks.getLinkLocation("dashboard", props.nodeId, clinicId));
            }
        // }
    };

    const handleRedemptionsIconClicked = (row) => {
        setClinicDetailsId(row.clinicId); // Shows clinic redemption summary popup
        if (props.childNodeType === CLINIC) {
            if (props.canViewWellnessSettings) {
                if (props.isAdmin) {
                    props.history.push(ClinicAdminLinks.getLinkLocation("programsDashboard", row.clinicId));
                } else {
                    props.history.push(ClinicProviderLinks.getLinkLocation("settings", props.nodeId, row.clinicId));
                }
            } else if (props.canViewRedemptionSummary) {
                handleRedemptionsIconClicked(row)
            }
        } else {
            goToNode(row.nodeId);
        }
    }
    const handleTableRowClick = row => {
        if(!!row.nodeId) {
            goToNode(row.nodeId);
        } else if(!!row.clinicId){
            if(props.canViewClinicInfo || props.canViewCorporateGroupClinic) {
                goToClinic(row.clinicId);
            }
        }
    };

    const handleHoverStart = (row) => {
        // For now, don't show hover for clinics
        if(!row.clinicId) {
            setHovering(row.id);
        }
    }

    const handleHoverEnd = () => {
        setHovering(null);
    }

    const handleMarkerClick = marker => {
       if(props.useClinics) {
           setInfoWindowMarkerId(marker.clinicId);
       } else {
            goToNode(marker.nodeId);
       }
    };

    const handleGoUp = () => {
        goToNode(props.parentNode?.nodeId)
    };

    // This is what's rendered (if anything) when you click a marker
    const renderMarkerChildren = marker => {
        if (props.useClinics && marker.clinicId === infoWindowMarkerId) {
            const clinic = find(filteredClinics, clinic => clinic.clinicId === marker.clinicId) || null;
            return (
                <InfoWindow>
                    <ClinicMarkerInfoWindow
                        providerId={props.providerId}
                        nodeId={props.nodeId}
                        showCouponLibraryLink={props.columns.showCouponLibraryLink || false}
                        showAccountNumber={props.columns.showAccountNumber || false}
                        clinic={clinic}
                        onRedemptionsClick={() => setClinicDetailsId(marker.clinicId)} // Show clinic redemptions popup
                        isAdmin={props.isAdmin}
                    />
                </InfoWindow>
            );
        }

        return null;
    };

    const handleNotificationClicked = (id) => {
        setSelectedNotificationId(id);
    }

    const handleNotificationModalClose = () => {
        setSelectedNotificationId(null);
    }

    const pageTitle = getPageTitle(props.node);
    const mapMarkers = getMapMarkerDataSource();
    const selectedNotification = selectedNotificationId ? find(props.notifications, {notificationId: selectedNotificationId}) : null;
    const showGamesColumns = !!filter(props.nodeHierarchy[props.providerId], {hasGames: true, parentNodeId: props.nodeId})?.length; // Shouw the games columns if one of the child nodes has games

    return (
        <ProviderBase
            match={props.match}
            location={props.location}
            // searchConfig={SearchTypes.CLINIC_SEARCH} // TODO: Could vary based on current org node type
            pageTitle={pageTitle}
            providerId={props.providerId}
            nodeId={props.nodeId}
            collapsible={props.canViewNotifications && !props.isAdmin}
        >
            <div
                data-testid="headquarters_dashboard_component"
                className={styles.root}
            >
                <div className={styles.mainSection}>
                    <ProviderTitleBar
                        nodeType={props.node?.nodeType}
                        voucherCode={props.node ? props.node?.voucherCode : null}
                    />
                    <div className={styles.dashboardContent}>
                        <div className={styles.leftContent}>
                            <div>
                                <ProviderTileGrid
                                    isAdmin={props.isAdmin}
                                    nodeId={props.nodeId}
                                    providerId={props.providerId}
                                    tiles={props.wellnessOnly ? WELLNESS_TILES : TILES}
                                />
                                {props.node?.nodeType === OrgNodeTypes.NHO && (
                                    <CorporateGroupFilterDropdown 
                                        name="Corporate Groups Filter"
                                        value={corporateGroupFilter}
                                        onChange={({value}) => setCorporateGroupFilter(value)}
                                        small
                                        clinics={props.clinics}
                                    />
                                )}          
                            </div>
                            <div className={styles.providerMap}>
                                <ProviderTableAndMapContainer
                                    canExpand={!props.wellnessOnly}
                                    // Can go back if there is no parent node or if the current node is the user's node
                                    canGoUp={!!props.parentNode?.nodeId && (props.nodeId !== props.userProfile.nodeId)}
                                    highlightMarker={hovering}
                                    mapMarkers={mapMarkers}
                                    markerChildren={renderMarkerChildren}
                                    parentNodeType={props.parentNode?.nodeType}
                                    nodeType={props.node?.nodeType}
                                    onChange={isExpanded => setShowAllColumns(isExpanded)}
                                    onGoUp={handleGoUp}
                                    onMarkerClick={handleMarkerClick}
                                    onMarkerHoverEnd={handleHoverEnd}
                                    onMarkerHoverStart={handleHoverStart}
                                    providerId={props.providerId}
                                    canViewProviderReports={props.canViewProviderReports}
                                >
                                    {(props.useClinics) ? (
                                        <ProviderClinicTable
                                            columns={props.columns}
                                            hasGames={props.nodeOverview?.hasGames}
                                            isAdmin={props.isAdmin}
                                            nodeId={props.nodeId}
                                            nodeType={props.node?.nodeType}
                                            providerId={props.providerId}
                                            onRedemptionsIconClicked={handleRedemptionsIconClicked}
                                            onRowClick={handleTableRowClick}
                                            rawData={filteredClinics}
                                            showAllColumns={!props.wellnessOnly ? showAllColumns : false}
                                        />
                                    ) : (
                                        <ProviderLocationTable
                                            hovering={hovering}
                                            isAdmin={props.isAdmin}
                                            locations={props.nodeChildren}
                                            onRowClick={handleTableRowClick}
                                            onRowHoverEnd={handleHoverEnd}
                                            onRowHoverStart={handleHoverStart}
                                            providerId={props.providerId}
                                            resultsPerPage={250}
                                            showAllColumns={showAllColumns}
                                            showGamesColumns={showGamesColumns}
                                        />
                                    )}
                                </ProviderTableAndMapContainer>
                            </div>
                        </div>
                        {/*Hide the notification from Admins since they can't load a provider's notifications anyway*/}
                        {(props.canViewNotifications && !props.isAdmin) && (
                            <div className={styles.notificationWrapper}>
                                <NotificationsDashboardWidget
                                    limit={10}
                                    notifications={props.notifications}
                                    unread={props.notificationTotal}
                                    onClick={handleNotificationClicked}
                                    showDismissed
                                />
                            </div>
                        )}
                    </div>
                </div>
                <ProviderClinicDetailsModal
                    show={!!clinicDetailsId}
                    providerId={Number(props.providerId)}
                    clinicId={clinicDetailsId || 0}
                    onClose={() => setClinicDetailsId(null)}
                />
                <NotificationModal
                    providerId={props.providerId}
                    notification={selectedNotification}
                    show={!!selectedNotification}
                    canAcceptNotifications={props.canAcceptNotifications}
                    canDeclineNotifications={props.canDeclineNotifications}
                    onClose={handleNotificationModalClose}
                    isDemo
                />
                <SpinnerTakeover show={props.loading} />
            </div>
        </ProviderBase>
    );
}

ProviderDashboard.propTypes = {
    location: PropTypes.object.isRequired,
    userRole: PropTypes.oneOf([UserRoles.PROVIDER_ADMIN, UserRoles.PROVIDER_MANAGER, UserRoles.PROVIDER_RD, UserRoles.PROVIDER_DM, UserRoles.PROVIDER_USER]),
    providerId: PropTypes.number,
    nodeId: PropTypes.number
};

const connector = connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const node = state.entities.nodes[ownProps.nodeId] || null;
        const nodeOverview = state.entities.providerHierarchies?.[ownProps.providerId]?.[ownProps.nodeId];
        const useClinics = !!nodeOverview?.hasClinics;
        const parentNode = !!nodeOverview?.parentNodeId ? state.entities.providerHierarchies?.[ownProps.providerId]?.[nodeOverview?.parentNodeId] : {};
        const childNodeType = !!nodeOverview?.childNodeIds?.length ? state.entities.providerHierarchies?.[ownProps.providerId]?.[nodeOverview?.childNodeIds[0]]?.nodeType : null;

        // Check User for Wellness Permissions
        const canViewProviderInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_INFO, userProfile);
        const canViewCouponLibrary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_COUPON_LIBRARY, userProfile);
        const canDrillDownClinics = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINICS_DRILLDOWN, userProfile);
        const canViewRedemptionSummary = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_REDEMPTION_SUMMARY, userProfile);
        const wellnessOnly = (node?.providerId === PREMIER_PET_CARE_PLAN);
        const canViewClinicInfo = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_INFO, userProfile);
        const canViewCorporateGroupClinic = userHasPermission(PermissionTypes.VIEW, UserPermissions.CORPORATE_GROUP_CLINIC, userProfile);
        const canViewNotifications = userHasPermission(PermissionTypes.VIEW, UserPermissions.NOTIFICATIONS, userProfile);
        const canAcceptNotifications = userHasPermission(PermissionTypes.ACCEPT, UserPermissions.NOTIFICATIONS, userProfile);
        const canDeclineNotifications = userHasPermission(PermissionTypes.DECLINE, UserPermissions.NOTIFICATIONS, userProfile);
        const canViewClinicWellnessAudit = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_AUDIT, userProfile);
        const canViewClinicWellnessPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PLANS, userProfile);
        const canViewClinicWellnessPetPlans = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_PET_PLANS, userProfile);
        const canViewWellnessDvms = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_WELLNESS_DVMS, userProfile);
        const canEditCouponReprocessing = userHasPermission(PermissionTypes.EDIT, UserPermissions.COUPON_REPROCESSING, userProfile);
        // If User has one of the Wellness Permissions then give them the settings permission
        const canViewWellnessSettings =  canViewClinicWellnessAudit || canViewClinicWellnessPlans || canViewClinicWellnessPetPlans || canViewWellnessDvms || canEditCouponReprocessing;
        // Check permissions to view BI games drilldown for looker dashboards
        const canViewProviderReports = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_REPORTS, userProfile);
        return {
            userProfile,
            wellnessOnly,
            canViewClinicInfo,
            canViewCorporateGroupClinic,
            nodeOverview,
            childNodeType,
            useClinics,
            node,
            parentNode,
            isAdmin: !!(ownProps?.location?.pathname?.includes("/admin") && ownProps.providerId && ownProps.nodeId),
            nodeChildren: state.entities.nodeChildren[ownProps.nodeId] || null,
            nodeHierarchy: state.entities.providerHierarchies,
            clinics: state.entities.nodeClinics[ownProps.nodeId] || null,
            columns: state.entities.nodeClinicColumns[ownProps.nodeId] || {},
            notifications: state.entities.notifications,
            notificationTotal: getUnreadNotificationCount(state),
            loading: state.entities?.nodes.loading || state.entities.nodeChildren?.loading,
        //    Permissions
            canViewNotifications,
            canAcceptNotifications,
            canDeclineNotifications,
            canViewProviderInfo,
            canViewCouponLibrary,
            canDrillDownClinics,
            canViewRedemptionSummary,
            canViewWellnessSettings,
            canViewProviderReports
        };
    },

    (dispatch) => ({
        getProviderDashboard: () => dispatch(ProviderActions.getProviderDashboard()),
        getChildNodes: (nodeId) => dispatch(ProviderActions.getChildNodes(nodeId)),
        getNodeClinics: (nodeId) => dispatch(ProviderActions.getNodeClinics(nodeId)),
    }),
);

export default compose(
    withRouter,
    connector
)(ProviderDashboard);
